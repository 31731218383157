import React, { FC, useEffect, useState } from 'react'
import { cnSearch, mapDispatchToProps, mapStateToProps } from './Search.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Search.scss'
import { SearchInput } from 'uielements/src';
// import { Button } from 'uielements/src';

export const SearchPresenter: FC<any> = ({ className }) => {

    return <div className={cnSearch({}, [className])}>
        <SearchInput value={''} className={cnSearch('Input')} onChange={()=>{}} />
    </div>
}

export const Search = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SearchPresenter));
