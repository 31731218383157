/**
 * @packageDocumentation
 * @module AboutWidget
 */
import { bindActionCreators } from 'redux';
import { getUser } from 'utils/src/CommonRedux/users/actions';
import { ReactNode } from 'react';

export interface IAboutProps extends IBaseAboutProps, IAboutStateProps, ReturnType<typeof mapDispatchToProps> {

}

export interface IBaseAboutProps {
    tag?: React.ComponentType<any> | string;
    uid: string;
    showControls?: boolean;
}

export interface IAboutStateProps {
    user?: any
}

export const mapStateToProps = (state: any, props: IBaseAboutProps) => {
    let user: any = null;
    if (props.uid in state.users.users) {
        user = state.users.users[props.uid]
    } else if (props.uid in state.users.alias) {
        user = state.users.users[state.users.alias[props.uid]]
    }
    return { user }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    getUser
}, dispatch);