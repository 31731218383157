import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';


export namespace SettingsCalendarMiniWidget {


    export interface Settings { [s: string]: any; }
    export interface Props extends IClassNameProps {
        widgetContext?: any;
        settings?: Settings | null;
        isEdit?:boolean;
    }
}

export const cnCalendarMini = cn('CalendarMini');
