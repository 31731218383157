import React, { FC, useState } from 'react'
import { IAddPhotoProps, mapDispatchToProps, mapStateToProps } from './AddPhoto.index'
import { connect } from 'react-redux';
import { Button, Icon } from 'uielements/src'
import { InputLayout } from 'blocks/PryanikyForms/InputLayout/InputLayout'
import './AddPhoto.scss'
export const AddPhoto_: FC<IAddPhotoProps> = ({ uploadPhotos, aid, gid }) => {
    const onChange = (e: any) => {
        uploadPhotos(aid, gid, e.target.files);
    }
    return <div className={'AddPhoto'}>
        <InputLayout icon={<Icon icon={'plus'}/>}>
            <input type='file' multiple onChange={onChange} />
        </InputLayout>
    </div>
}

export const AddPhoto = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddPhoto_)
