import Component, { Presenter } from './component';
import {
    News,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = News.ReplyService.IOwnProps;
export type IComponentPresenterProps = News.ReplyService.IPresenterProps;


export default Component