import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

///////////

export type IListPlaceholderProps = IListPlaceholderOwnProps

export interface IListPlaceholderState { }

export interface IListPlaceholderOwnProps extends IClassNameProps {
    itemsCount: number;
    isFinished: boolean;
    isLoading: boolean;
    children?: React.ReactNode;
    titleEmpty?: React.ReactNode;
    textEmpty?: React.ReactNode;
    imgEmpty?: string;
    textLoad?: React.ReactNode;
    imgLoad?: string;
    onlyEmpty?: boolean;
}

export const cnClassName = cn('LMSListPlaceholder');