import React from 'react';
import {cnScrollBar, IScrollBarProps, IScrollBarState} from './MountainRating-ScrollBar.index'
import { Icon } from 'uielements/src/Icon/Icon';
import './MountainRating-ScrollBar.scss';

export class ScrollBar extends React.Component<IScrollBarProps, IScrollBarState> {
  public state = {
    dragStart: undefined,
  };
  private barRef = React.createRef<HTMLDivElement>();
  private buttonInterval: NodeJS.Timeout;
  public render() {
    const { itemsPerPage, currentItem, totalItems, width } = this.props;
    const barWidth = (itemsPerPage / (totalItems > itemsPerPage ? totalItems : itemsPerPage)) * 100 + '%';
    const barOffset = (currentItem / totalItems) * 100 + '%';
    return (
      <div className={cnScrollBar()} style={{ width }}>
        <button className={cnScrollBar('Button')} data-direction="left" onMouseDown={this.handleButtonPressed}>
          <Icon icon="chevron-left" className={cnScrollBar('Icon')} />
        </button>
        <div className={cnScrollBar('Bar')} ref={this.barRef}>
          <div
            className={cnScrollBar('Slider')}
            onMouseDown={this.handleMouseDown}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleMouseMove}
            onTouchEnd={this.handleTouchEnd}
            style={{ width: barWidth, left: barOffset }}
          />
        </div>
        <button className={cnScrollBar('Button')} data-direction="right" onMouseDown={this.handleButtonPressed}>
          <Icon icon="chevron-right" className={cnScrollBar('Icon')} />
        </button>
      </div>
    );
  }
  private handleTouchStart = (e: React.TouchEvent) => {
    this.setState({ dragStart: e.changedTouches[0].clientX });
  };
  private handleTouchEnd = () => this.setState({ dragStart: undefined });
  private handleButtonPressed = (e: React.MouseEvent<HTMLButtonElement>) => {
    const direction = e.currentTarget.dataset.direction;
    if (direction !== 'left' && direction !== 'right') return;
    const scroll = () => this.props.onScroll({ direction, value: 1 });
    scroll();
    this.buttonInterval = setInterval(scroll, 50);
    document.addEventListener('mouseup', this.handleButtonReleased);
  };
  private handleButtonReleased = () => clearInterval(this.buttonInterval);
  private handleMouseDown = (e: React.MouseEvent) => {
    this.setState({ dragStart: e.clientX });
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  };
  private handleMouseMove = (e: MouseEvent | React.TouchEvent) => {
    if (this.state.dragStart === void 0 || !this.barRef.current) return;
    const isTouch = e.type === 'touchmove';
    if (!isTouch) e.preventDefault();
    // @ts-ignore
    const clientX = isTouch ? e.changedTouches[0].clientX : e.clientX;
    const diff = clientX - (this.state.dragStart || 0);
    const switchWidth = this.barRef.current.clientWidth / this.props.totalItems;
    if (!(Math.abs(diff) > switchWidth)) return;
    else {
      this.setState({ dragStart: clientX });
      const value = Math.round(Math.abs(diff / switchWidth));
      const direction = diff > 0 ? 'right' : 'left';
      this.props.onScroll({ direction, value });
    }
  };
  private handleMouseUp = () => {
    this.setState({ dragStart: undefined });
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  };
}
