import React, { useState } from 'react'
import './QuizFinish.scss'
import { cnQuizFinish, IQuizFinish, mapStateToProps, mapDispatchToProps } from './QuizFinish.index'
import { Button, Avatar } from 'uielements/src'
import { connect } from 'react-redux'
// import { PostContentRender } from '../../PostContentRender/PostContentRender'
import { publishResults } from 'utils/src/requests/requests.quiz'
import { toast } from 'react-toastify'
import i18n from 'localizations/i18n'
import MDRender from 'uielements/src/CommonmarkRender'
import { Translate } from 'localizations/Translate';
import { Box } from 'muicomponents/src'
import {
  ErrorOutline
} from 'muicomponents/src/Icons/Icons';
import { styled } from 'muicomponents/src/mui/system';

export const ErrorOutlineIcon = styled(ErrorOutline)({
  fontSize: 18.33,
  color: "#fff"

});
const QuizFinishPresenter = ({ resetQuiz, rewardIsPosted, result, currencyNames }: IQuizFinish) => {

  const [showButton, setShowButton] = useState(true)
  const [showWarningText, setWarningText] = useState(false)


  const onPublishResult = () => {
    setShowButton(false)
    publishResults(result.userAnswerLogId)
      .r
      .then((r: any) => {
        if (r.error_code === 0) {
          toast.success('Вы поделились результатом прохождения викторины')
          setWarningText(true)
        } else if (r.error_code === 2000) {
          toast.error('Ошибка! Вы уже поделились результатом прохождения викторины')
        } else {
          toast.error('Ошибка')
        }
      })
  }

  return (
    <>
      {result && <div className={cnQuizFinish()}>
        <div className={cnQuizFinish('Text')}>
          <MDRender source={result.text || ''} textCut={false} />
        </div>

        {result.badges.length > 0 &&
          result.badges.map((badge: any) => {
            if (!badge.isActive) return <></>;
            return (
              <div>
                <div className={cnQuizFinish('Badge')} key={badge.id}>
                  <div>Вы получили бейдж {badge.displayName}</div>
                  <div className={cnQuizFinish('Badge-Description')}>
                    <Avatar size={128} imgUrl={badge.iconUrl} name={badge.displayName} />
                    <span>{badge.description}</span>
                  </div>
                </div>
                {
                  (result.rewardType === "Badge" && result.allowPostNews) &&
                  <div className={cnQuizFinish('Badge-RewardTypeBadge')}>
                    <Translate i18nKey='pryaniky.quiz.result.badge' values={{ text: badge.displayName }} /></div>
                }
              </div>
            )
          })
        }


        <div className={cnQuizFinish('Buttons')}>
          {!result.allowRetryQuiz && result.allowPostNews &&
            <div className={cnQuizFinish('LastAttempt')}><span>Внимание!</span> Это была ваша последняя попытка, не забудьте поделиться результатом</div>}
          {
            (result.rewardType === "Currency" && result.allowPostNews) && <div className={cnQuizFinish('Badge-RewardTypeCurrency')}><Translate i18nKey='pryaniky.quiz.result.currency' values={{ text: currencyNames.currencyNameNominativePlural }} /></div>
          }

          {result.allowPostNews && showButton && result.rewardType !== "Currency" && <Button
            type='button'
            main
            onClick={() => onPublishResult()}
          >
            {i18n.t('pryaniky.quiz.saveReward')}
          </Button>}

          {result.allowPostNews && showButton && result.rewardType === "Currency" && <Button
            type='button'
            main
            onClick={() => onPublishResult()}
          >
            <Translate i18nKey='pryaniky.quiz.saveReward.currency' values={{ text: currencyNames.currencyNameNominativePlural }} />
          </Button>}

          {((showWarningText || rewardIsPosted) && result.allowRetryQuiz) &&
            // {(showWarningText && result.allowRetryQuiz) &&
            <Box className={cnQuizFinish('Warning')}>
              <ErrorOutlineIcon />
              <span>{i18n.t('pryaniky.quiz.retry.warning')}</span>
            </Box>
          }

          {result.allowRetryQuiz && <Button
            className={cnQuizFinish('allowRetryQuiz')}
            type='rlink'
            noBorder
            onClick={() => resetQuiz()}
          >
            {i18n.t('pryaniky.quiz.restart')}
          </Button>}


        </div>
      </div>}
    </>
  )
}

export const QuizFinish = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuizFinishPresenter)