import { Translate } from 'localizations/Translate'
import i from 'localizations/i18n'
import React, { FC } from 'react'
import { IThanksCounterProps } from './ThanksCounter.index'
import './ThanksCounter.scss'

export const ThanksCounter: FC<IThanksCounterProps & any> = ({ thanksCount, available, thanksForAll }) => {
    return (
        <div className={'ThanksCounter'}>
            <div title={i.t("pryaniky.createPost.thanks.counter.current_value")} className={'ThanksCounter-Item'}>
                <Translate i18nKey='pryaniky.createPost.thanks.counter.writeOff' />
                : <span className={'ThanksCounter-Value'}>{thanksCount}</span>
            </div>
            <div className={'ThanksCounter-Item'}>
                <Translate i18nKey='pryaniky.createPost.thanks.counter.isLeft' />
    : <>
                    <span title={i.t("pryaniky.createPost.thanks.counter.available_value")} className={'ThanksCounter-Value'}>{available}</span>
                    &nbsp;
    <span title={i.t("pryaniky.createPost.thanks.counter.all_value")}>({i.t("pryaniky.createPost.thanks.counter.of_all", { value: thanksForAll })})</span>
                </>
            </div>
        </div>
    )
}