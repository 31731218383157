import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from '../../../src/redux/connector';
import { RouteComponentProps } from 'react-router';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface INotificationsSettingsProps extends IClassNameProps, INotificationsSettingsStateProps, IDispatchProps, RouteComponentProps<{id:string}> {
  tag?: 'div';
}

export interface INotificationsSettingsState {
  text?: string;
  settings: any;
  section: string;
  currentGroup: any;
  groups: any;
}

export interface INotificationsSettingsStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  authUser: getAuthUser(state)
})

export const cnNotificationsSettings = cn('NotificationsSettings');