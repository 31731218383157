import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Button, Spinner, CheckboxInput } from 'uielements/src'
import {
    SortableContainer,
    SortableElement,
} from 'react-sortable-hoc';
import { arrayMove } from 'utils/src/utils'
import Item from './Item'
import Search from './Filters/Search'
// import ListPlaceholder from '../../components/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import AddMaterials from '../../dialogs/AddMaterials'
import { useIsLoading, useIsError, useLazyExecutSaga } from 'utils/src/CommonRedux/LoadIndicator'
import actions from '../../redux/actionsTypes/TracksManage';
import ListPlaceholder from 'uielements/src/ListPlaceholder'
import { xor } from 'lodash'
import {
    setFilesPositions
} from '../../redux/actions/TracksManage'

const LoadButton = withLoadSpin(Button)
const SortableItem = SortableElement(Item);

const Sortable = SortableContainer(({ children }: any) => {
    return <div>{children}</div>;
});

const filters = [
    'pages',
    'quizzes',
    'courses',
    'news',
]


export const Presenter: FC<IPresenterProps> = ({
    className,
    loadFiles,
    keys,
    values,
    count,
    isFinished,
    positionIsChanged,
    reset,
    match,
    moveFile
}) => {
    const [activeFiters, setActiveFilter] = useState<string[]>([])
    const isLoading = useIsLoading([actions.LOAD_FILES])
    const { errors, isFailed } = useIsError([actions.LOAD_FILES])

    const {
        dispatchAction: savePositions,
        isLoading: positionsSaving,
        isFailed: failSavePositions
    } = useLazyExecutSaga(() => setFilesPositions({ trackId: match.params.id, positions: keys }), [match.params.id, keys])

    const loadMore = () => loadFiles({ tags: [match.params.id], count, skipCount: keys.length, ltFilters: activeFiters })

    useEffect(() => {
        reset('files')
        // loadMore()
    }, [match.params.id, activeFiters])

    const triggerRef = useInfiniteScroll({
        loadMore,
        isLoading,
        isFinished,
    });
    async function add() {
        try {
            await AddMaterials({ trackId: match.params.id })
        } catch (e) {

        }
    }

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        moveFile({
            newIndex,
            oldIndex,
            trackId: match.params.id
        })
    };

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Section')}>
            <div className={cnClassName('SearchBox')}>
                <div className={cnClassName('Functions')}>
                    <Button
                        main
                        className={cnClassName('AddButton')}
                        onClick={add}>{tTr('add.files')}</Button>

                    {positionIsChanged && <LoadButton
                        isLoading={positionsSaving}
                        main
                        className={cnClassName('AddButton')}
                        onClick={savePositions}>{tTr('savePositions')}</LoadButton>}
                </div>
                {/* <Search search={''} /> */}


                <div className={cnClassName('Categories')}>
                    <span>{tTr('header.catigories')}</span>
                    {filters.map((filter) => {
                        return (
                            <CheckboxInput
                                checked={activeFiters.includes(filter)}
                                text={tTr('filter.' + filter)}
                                onChange={() => setActiveFilter(xor(activeFiters, [filter]))}
                            />
                        )
                    })}
                </div>
            </div>

            <div className={cnClassName('List')}>
                <ListPlaceholder
                    className={cnClassName('Placeholder')}
                    isFinished={isFinished}
                    isLoading={isLoading}
                    isError={isFailed}
                    itemsCount={keys.length}
                    titleEmpty={tTr('trackfiles.list.empty.title.1')}
                    textEmpty={tTr('trackfiles.list.empty.text.1')}
                    textLoad={tTr('trackfiles.list.load.text.1')}
                >
                    <Sortable onSortEnd={onSortEnd} useDragHandle>
                        {keys.map((id: string, i: number) =>
                            <SortableItem disableSort={positionsSaving} key={id} index={i} value={values[id]} />
                        )}
                    </Sortable>
                    {!isFinished && (
                        <LoadButton
                            forwardedRef={triggerRef}
                            isLoading={isLoading}
                            className={cnClassName('Trigger')}
                            onClick={isLoading ? void 0 : loadMore}>
                            {tTr('trackfiles.list.load.more.1')}
                        </LoadButton>
                    )}
                </ListPlaceholder>

            </div>
        </div>


    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
