import { IClassNameProps } from '@bem-react/core';
import { cnReply } from '../Reply.index';
import { SReplyMobileModel } from 'blocks/Replyes/Replyes.index';
import { IDispatchProps } from 'redux/connector';
import {
  updateReply
} from 'News/redux/actions'
export interface IEditProps extends IDispatchNews, IClassNameProps {
  tag?: 'div';
  newsId: string;
  comment: SReplyMobileModel;
  disableEdit: () => void;
  withRating?: boolean
}

export interface IEditState {
  comment: SReplyMobileModel;
  validFile: boolean;
  isValid: boolean;
}

export interface IEditStateProps {
}

export const mapStateToProps = (state: any) => ({
})

export interface IDispatchNews {
  updateReply: typeof updateReply;
}

export const mapDispatchToProps = {
  updateReply
};

export const cnEdit = (...data: any) => {
  let name = 'Edit';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnReply(name, ...data)
}