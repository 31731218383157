import { IStateType as IState } from 'redux/store';
import { bindActionCreators, Action, Dispatch } from "redux";
import { cn } from '@bem-react/classname';
// import { INewsViewType } from '../../../redux/actionsTypes/News';
// import { setNewViewType } from '../../../redux/actions/News';
// import {
//     getViewType
// } from 'News/redux/saga/selectors'
// import {
//     changeField
// } from 'News/redux/actions'

export interface IWikiViewTypeSelectorProps extends IWikiViewTypeSelectorOwnProps, IMapWikiViewTypeSelectorStateToProps, IMapWikiViewTypeSelectorActionsToProps { }

export interface IWikiViewTypeSelectorOwnProps {
    viewType?: string,
    
}

export const mapWikiViewTypeSelectorStateToProps = (state: IState) => ({
    // viewType: getViewType(state as any)//'card'//state.news.newsViewType as INewsViewType
});

export type IMapWikiViewTypeSelectorStateToProps = ReturnType<typeof mapWikiViewTypeSelectorStateToProps>

export const mapWikiViewTypeSelectorActionsToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    // changeField
}, dispatch);

export type IMapWikiViewTypeSelectorActionsToProps = ReturnType<typeof mapWikiViewTypeSelectorActionsToProps>

export const cnWikiViewTypeSelector = cn('WikiViewTypeSelector');