import { ACTION } from 'utils/src/CommonRedux/actionsTypes';


let actions: {
    //saga
    REWARD_REPLY: 'REWARD_REPLY', //назначить награду за комментарий
    DISTRIBUTE_COMPETITION: 'DISTRIBUTE_COMPETITION', // завершить конкурс
    CANCLE_COMPETITION: 'CANCLE_COMPETITION', // отменить конкурс
    REWARD_ALL: 'REWARD_ALL', // распределить весь фонд поровну на все комментарии
    
    //reducer
    CHANGE_FORM_TEXT: 'CHANGE_FORM_TEXT', // изменить текст завершения конкурса

    CHANGE_FORM: 'CHANGE_FORM', // изменить объект завершения
    CHANGE_REWARD_REPLY: 'CHANGE_REWARD_REPLY', // изменить награду за комментарий
    PATH_REWARD: 'PATH_REWARD',// изменить объект наград для указанной новости
    CANCLE_ALL_REWARD: 'CANCLE_ALL_REWARD', // отменить все награды

    //common
} = {
    //saga
    REWARD_REPLY: 'REWARD_REPLY',
    DISTRIBUTE_COMPETITION: 'DISTRIBUTE_COMPETITION', // завершить конкурс
    CANCLE_COMPETITION: 'CANCLE_COMPETITION', // отменить конкурс
    REWARD_ALL: 'REWARD_ALL', // распределить весь фонд поровну на все комментарии

    //reducer
    CHANGE_FORM_TEXT: 'CHANGE_FORM_TEXT', // изменить текст завершения конкурса

    CHANGE_FORM: 'CHANGE_FORM', // изменить объект завершения
    CHANGE_REWARD_REPLY: 'CHANGE_REWARD_REPLY', // изменить награду за комментарий
    PATH_REWARD: 'PATH_REWARD',// изменить объект наград для указанной новости
    CANCLE_ALL_REWARD: 'CANCLE_ALL_REWARD', // отменить все награды

    //common
}


export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, 'NEWS_CREATIVETASKS') }), {} as typeof actions);