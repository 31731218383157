import { createPortal } from 'react-dom';
import { IPopoverProps, cnPopover } from './Popover.index';
import { getOffsetSum, noop } from 'utils/src/utils';
import * as React from 'react';
import './Popover.scss';
import { useClickOutside } from 'utils/src/hooks';
import { useDialogContext } from 'utils/src/DialogCreator'

let popoverRoot: HTMLElement | null = null;

// let selfRef: HTMLDivElement | null = null;

const getCoords = (elem: HTMLElement) => {
  const box = elem.getBoundingClientRect();
  return Object.assign(getOffsetSum(elem), {
    h: box.height,
    wTop: box.top,
    wLeft: box.left,
    width: box.width,
    height: box.height,
  });
};

export const Popover: React.FC<IPopoverProps> = ({
  children,
  anchor,
  clickInside = noop,
  clickOutside = noop,
  className
}) => {
  const { isDialog, paperRef } = useDialogContext()

  const renderToDialog = isDialog /*&& dialogProps.scroll === 'body'*/ && paperRef.current

  const selfRef = React.useRef<HTMLDivElement>(null);

  useClickOutside(selfRef, () => clickOutside(null), undefined, () => clickInside(null));

  
  if (renderToDialog) popoverRoot = paperRef.current
  if (!renderToDialog) popoverRoot = document.getElementById('Layout') || document.body;
  // if (!popoverRoot) popoverRoot = document.getElementById('Layout') as HTMLDivElement || document.body;
  if (!popoverRoot || !anchor) return <span>fail</span>;

  const cord = getCoords(anchor);
  const paperCord = renderToDialog ? getCoords(paperRef.current!) : {
    h: 0,
    left: 0,
    top: 0,
    wLeft: 0,
    wTop: 0,
    width: 0,
  };
  const position = {
    left: (cord.left - paperCord.left) + (cord.width / 2),
    top: (cord.top - paperCord.top) + (cord.height / 2),
    zIndex: isDialog ? 2000 : undefined
  };
  return createPortal(
    <div ref={selfRef} style={position} className={cnPopover("", {className})}>
      {children}
    </div>,
    popoverRoot
  );
  /*return <div >
            {children}
        </div>;*/
};
