import React from 'react';


import { cnWidget, cnWidgetMui, IWidgetProps } from '../Widget.index';

import './Widget_type_quests.scss';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { SecretSantaWidgetList } from 'blocks/SecretSantaListWidget/SecretSantaListWidget';
import { IWidgetTypeSecretSantaListWidgetProps } from './Widget_type_secret_santa_list_widget.index';

const type = 'secretSantaWidgetList';

export default class WidgetTypeSecretSantaListWidgetPresenter extends WidgetPresenter<IWidgetTypeSecretSantaListWidgetProps> {

  public render() {
    if (!this.props.widget) return null
    const subtype = utils.widgetSubtype(this.props.widget.type);
    return <SecretSantaWidgetList {...this.props} className={cnWidgetMui({type, subtype})} settings={this.props.widget.settings} tag={widgets.components.common} />
  }
}