import Component, { Presenter } from './component';
import {
    News,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = News.CommentsView.IOwnProps;
export type IComponentPresenterProps = News.CommentsView.IPresenterProps;


export default Component