import actions from '../actionsTypes/LMS';
import { setCancleSessionPayloadType } from '../actions/LMS'

const zeroId = "00000000-0000-0000-0000-000000000000";

const defaultUnitLog = {
    unitLogId: zeroId,// unitLogId
    isCompleted: false,
    isCompletedSuccess: false,
    isCompletedOnTime: false,
    isSkipped: false,
    attemptsCount: 0,
    likesCount: 0,
    commentsCount: 0,
    learningSession: null,
    id: zeroId, //unit id
    name: "default name",
    description: "default description",
    unitType: "wiki",// "wiki" or "test"
    appPageId: 0, // хз, number
    navigatePageName: "courses/def6dcdf-5e0f-46b6-ba6a-1d155456cd6c/637896c2-cab6-4952-9d62-97cabbda605a", // хз что это
    allowSkip: true,
    allowBack: false,
    skipUnitType: 0,
    courseId: zeroId, // course id
    isDeleted: false,
    position: 0,
    maxAttemptsCount: 0,
    maxTime: 0,
    scoreCountMode: 0,
    passingMinScore: 0,
    isHidden: false,
    isLastUnit: false,
    newsUid: null,
}

const defaultSessionLog = {
    id: zeroId,
    userId: zeroId,
    eMail: null,
    isCompleted: true,
    isMyCourse: false,
    completeBeforeDate: null,
    startDate: "0000-00-00T00:00:00.0",
    shouldCompleteInDays: null,
    lastViewedUnit: zeroId,
    isSuccessful: true,
    isCompletedOnTime: true,
    sessionId: zeroId,
    completePercentage: 0,
    status: "complete",
}

type ANY_OBJECT = { [key: string]: any };

interface LMSState {
    courses: {
        entities: {
            values: ANY_OBJECT
        },
        result: string[],
        isLoading: boolean,
        isFinished: boolean
    },
    categories: {
        entities: { values: ANY_OBJECT },
        result: string[],
    },
    unitsLogs: ANY_OBJECT,
    sessions: ANY_OBJECT,
    sessionsLogs: ANY_OBJECT,
    units: ANY_OBJECT,
    sessionResults: ANY_OBJECT,
    userSessionsResults: ANY_OBJECT,
    unitsResults: ANY_OBJECT,
    questionsResults: ANY_OBJECT,
    timers: ANY_OBJECT,
    sessionTimer: ANY_OBJECT,
    count: number,
    saveInProcessing: boolean,
    loadUnitsLogsInProcessing: boolean,
    statistic: ANY_OBJECT,
    
}
const defaultLMS: any = {
    courses: {
        entities: { values: {} },
        result: [],
        isLoading: false,
        isFinished: false
    },
    categories: {
        entities: { values: {} },
        result: [],
    },
    unitsLogs: {},
    sessions: {},
    sessionsLogs: {},
    units: {},
    sessionResults: {},
    userSessionsResults: {},
    unitsResults: {},
    questionsResults: {},
    timers: {},
    sessionTimer: {
        timerId: 0,
        cancleTimerId: 0,
        sid: zeroId,
        completeBeforeDate: ''
    },
    count: 4,
    saveInProcessing: false,
    loadUnitsLogsInProcessing: false,
    statistic: {
        // studentcoursesstatus: [],
        // studentcoursesstatusMandatory: []
    }
};


export function lmsReducer(state = defaultLMS, action: any) {
    switch (action.type) {
        case actions.SET_COURSES_STATUS: {
            const filed = action.payload.isMandatory ? 'studentcoursesstatusMandatory' : 'studentcoursesstatus'
            const uid = action.payload.uid || 'current'
            return {
                ...state,
                statistic: {
                    ...state.statistic,
                    [uid]: {
                        ...state.statistic[uid],
                        [filed]: action.payload.data
                    }
                }
            }
        }
        case actions.START_LOAD_UNITS_LOGS: {
            return {
                ...state,
                loadUnitsLogsInProcessing: true
            }
        }
        case actions.COMPLETE_LOAD_UNITS_LOGS: {
            return {
                ...state,
                loadUnitsLogsInProcessing: false
            }
        }
        case actions.SET_SESSIONS_LOGS:
            return (() => {
                return {
                    ...state,
                    sessionsLogs: {
                        ...state.sessionsLogs,
                        ...action.payload
                    }
                }
            })()
        case actions.UPDATE_UNIT_LOG:
            return (() => {
                return {
                    ...state,
                    unitsLogs: {
                        ...state.unitsLogs,
                        [action.uid]: {
                            ...state.unitsLogs[action.uid],
                            ...action.payload
                        }

                    }
                }
            })()
        case actions.SET_USER_SESSION_RESULT: {
            return {
                ...state,
                userSessionsResults: {
                    ...state.userSessionsResults,
                    [action.payload.sid]: action.payload.result
                }
            }
        }
        case actions.USE_ATTEMPT_AFTER_COMPLETE:
            return (() => {
                return {
                    ...state
                }
            })()
        case actions.SET_UNITS_LOGS:
            return (() => {
                return {
                    ...state,
                    unitsLogs: {
                        ...state.unitsLogs,
                        ...action.payload
                    }
                }
            })()
        case actions.SET_SAVE_PROCESSING_TRUE:
            return {
                ...state,
                saveInProcessing: true
            }
        case actions.SET_SAVE_PROCESSING_FALSE:
            return {
                ...state,
                saveInProcessing: false
            }
        case actions.READ_CATEGORIES:
            return {
                ...state,
                categories: {
                    ...action.payload
                }
            };
        case actions.READ_COURSES:
            let isFinished = false;
            isFinished = action.payload.result.length < state.count;
            if (action.payload.clear) isFinished = false;
            return {
                ...state,
                courses: {
                    entities: {
                        values: action.payload.clear ?{
                            ...action.payload.entities.values
                            // [action.payload.id]: action.payload
                        }: {
                            ...state.courses.entities.values,
                            ...action.payload.entities.values
                            // [action.payload.id]: action.payload
                        }
                    },
                    result: action.payload.clear ? action.payload.result : [...state.courses.result.filter((val: string) => action.payload.result.indexOf(val) === -1), ...action.payload.result],
                    // ...action.payload,
                    isLoading: false,
                    isFinished
                },
                sessions: {
                    ...state.sessions,
                    ...action.payload.sessions
                }
            };
        case actions.REMOVE_SKELETS:
            return {
                ...state,
                courses: {
                    ...state.courses,
                    result: state.courses.result.filter((val: string) => val !== 'skelet'),
                }
            };
        case actions.SET_LOADING:
            return {
                ...state,
                courses: {
                    ...state.courses,
                    isLoading: true
                }
            }
        case actions.READ_SINGLE_COURSE: {
            const withError = action.payload.error_code && action.payload.error_code !== 0
            let result = !state.courses.result.find((val: string) => val === action.payload.id) ? [action.payload.id, ...state.courses.result] : state.courses.result
            result = withError ? [] : result
            return {
                ...state,
                courses: {
                    entities: {
                        values: {
                            ...state.courses.entities.values,
                            [action.payload.id]: action.payload
                        }
                    },
                    result
                },
                sessions: {
                    ...state.sessions,
                    ...action.payload.sessions
                },
                sessionsLogs: {
                    ...state.sessionsLogs,
                    ...action.payload.sessions
                },
            };
        }
        case actions.READ_UNITS_CONTENT: {
            return {
                ...state,
                units: {
                    ...state.units,
                    [(action.payload.unitVersion ? (action.payload.id + '_' + action.payload.unitVersion) : action.payload.id)]: action.payload
                }
            };
        }
        case actions.READ_SESSION_RESULT:
            return {
                ...state,
                sessionResults: {
                    ...state.sessionResults,
                    [action.payload.sessionId]: action.payload.result
                }
            };
        case actions.SET_LIKE_COURSE:
            const currentCourse = state.courses.entities.values[action.payload.cid]
            const isLiked = !currentCourse.isLiked
            const likesCount = isLiked ? currentCourse.likesCount + 1 : currentCourse.likesCount - 1
            return (() => {
                return {
                    ...state,
                    courses: {
                        ...state.courses,
                        entities: {
                            values: {
                                ...state.courses.entities.values,
                                [action.payload.cid]: {
                                    ...state.courses.entities.values[action.payload.cid],
                                    likesCount,
                                    isLiked
                                }
                            }
                        }
                    }
                }
            })()
        case actions.TOGGLE_SESSION: {
            const currentCourse = state.courses.entities.values[action.payload.courseId]
            const courseCompletedUnits = action.payload.resetProgress ? 0 : currentCourse.courseCompletedUnits
            const courseProgressPersentage = action.payload.resetProgress ? 0 : currentCourse.courseProgressPersentage
            return {
                ...state,
                courses: {
                    ...state.courses,
                    entities: {
                        values: {
                            ...state.courses.entities.values,
                            [action.payload.courseId]: {
                                ...currentCourse,
                                courseSessionId: action.payload.sessionId,
                                courseProgressPersentage,
                                courseCompletedUnits,
                                view: undefined,
                                lastCompletedSession: undefined
                            }
                        }
                    }
                },
                unitsLogs: {},
                sessionsLogs: {},
                units: {},
                sessionResults: {},
                userSessionsResults: {},
                unitsResults: {},
                questionsResults: {},
                timers: {},
                sessionTimer: {
                    timerId: 0,
                    cancleTimerId: 0,
                    sid: zeroId,
                    completeBeforeDate: ''
                },
            }
        }
        case actions.SET_SESSION_COMPLETE_MONATORY: {
            const { sid } = action.payload
            return {
                ...state,
                sessionsLogs: {
                    ...state.sessionsLogs,
                    [sid]: {
                        ...state.sessionsLogs[sid],
                        log: {
                            ...state.sessionsLogs[sid].log,
                            // lastViewedUnit: zeroId,
                            isMandatoryPartComplete: true,
                        }
                    }
                },
            }
        }
        case actions.CANCLE_SESSION: {
            const { courseId, absolute, sid } = action.payload as setCancleSessionPayloadType

            const currentCourse = state.courses.entities.values[courseId]

            return {
                ...state,
                sessionsLogs: {
                    ...state.sessionsLogs,
                    [sid]: {
                        ...state.sessionsLogs[sid],
                        log: {
                            ...state.sessionsLogs[sid].log,
                            lastViewedUnit: zeroId,
                            isCompleted: true,
                            isAbsolutelyCompleted: absolute
                        }
                    }
                },
                courses: {
                    ...state.courses,
                    entities: {
                        values: {
                            ...state.courses.entities.values,
                            [courseId]: {
                                ...currentCourse,
                                courseSessionId: zeroId,
                                courseCompletedUnits: currentCourse.courseUnits.length,
                                view: 'result',
                            }
                        }
                    }
                }
            }
        }
        case actions.SET_VIEW_SESSION_RESULT:
            return (() => {
                return {
                    ...state
                }
            })()
        case actions.SET_NEXT_UNIT:
            return (() => {
                const cid = action.payload.courseId
                const currentCourse = state.courses.entities.values[cid]
                const sid = currentCourse.courseSessionId
                const currentSession = state.sessionsLogs[sid]
                const currentUid = currentSession.log.lastViewedUnit || zeroId
                const currentUnitIndex = currentUid === zeroId ? 0 : currentCourse.courseUnits.findIndex((val: any) => val.id === currentUid)

                const notCompletedUnits = currentCourse.courseUnits.filter((unit: any) => {
                    if (!state.unitsLogs[unit.id]) return true
                    else if (!state.unitsLogs[unit.id].isCompleted) return true
                    else return false
                })
                const isLastUnit = notCompletedUnits.length === 0//currentCourse.courseUnits[currentUnitIndex !== -1 ? currentUnitIndex : 0].isLastUnit

                let nextIdx = isLastUnit ? currentUnitIndex : currentUnitIndex + 1

                if (currentSession.nextSkipedUnitId && currentSession.nextSkipedUnitId !== zeroId) {
                    nextIdx = currentCourse.courseUnits.findIndex((val: any) => val.id === currentSession.nextSkipedUnitId)
                }

                const skipedUnits = currentSession.skipedUnits ? currentSession.skipedUnits.filter((val: string) => val !== currentUid) : []


                const completedCount = currentCourse.courseUnits.reduce((accumulator: number, unit: any) => accumulator + (state.unitsLogs[unit.id].isCompleted ? 1 : 0), 0);

                const isAllComleted = currentCourse.courseUnits
                    .filter((unit: any) => unit.id !== currentUid)
                    .every((unit: any) => state.unitsLogs[unit.id].isCompleted)
                const isCompleted = isAllComleted //&& (!currentSession.skipedUnits || currentSession.skipedUnits.length === 0)

                const nextUnitId = isCompleted ? zeroId : currentCourse.courseUnits[nextIdx].id

                const courseCompletedUnits = (state.unitsLogs[currentUid].unitType !== 'test' && !state.unitsLogs[currentUid].isCompleted) ? (completedCount + 1) : completedCount

                let view = 'result';
                if (!isCompleted) {
                    // if (currentCourse.courseUnits[currentUnitIndex].isLastUnit) view = 'skiped';
                    if (isLastUnit) view = 'skiped';
                    else if (currentCourse.courseUnits[nextIdx].unitType === 'page') view = 'page';
                    else if (currentCourse.courseUnits[nextIdx].unitType === 'wiki') view = 'wiki';
                    else if (currentCourse.courseUnits[nextIdx].unitType === 'test') view = 'test';
                }
                if (isCompleted) {
                    clearInterval(state.sessionTimer.timerId);
                    clearTimeout(state.sessionTimer.cancleTimerId)
                }
                const courseSessionId = isCompleted ? zeroId : currentCourse.courseSessionId
                const lastCompletedSession = isCompleted ? currentCourse.courseSessionId : undefined
                return {
                    ...state,
                    sessionsLogs: {
                        ...state.sessionsLogs,
                        [sid]: {
                            ...state.sessionsLogs[sid],
                            log: {
                                ...state.sessionsLogs[sid].log,
                                lastViewedUnit: nextUnitId,
                                isCompleted
                            },
                            skipedUnits,
                            reTest: currentSession.reTest ? skipedUnits.length !== 0 : false
                        }
                    },
                    unitsLogs: {
                        ...state.unitsLogs,
                        [currentUid]: {
                            ...state.unitsLogs[currentUid],
                            isSkipped: false,
                            isCompleted: true
                        }
                    },
                    courses: {
                        ...state.courses,
                        entities: {
                            values: {
                                ...state.courses.entities.values,
                                [cid]: {
                                    ...currentCourse,
                                    courseSessionId,
                                    lastCompletedSession,
                                    // courseUnits: [...courseUnits],
                                    courseCompletedUnits,
                                    view,
                                    normalSessions: {
                                        ...currentCourse.normalSessions,
                                        [sid]: {
                                            ...currentSession,
                                            log: {
                                                ...currentSession.log,
                                                lastViewedUnit: nextUnitId,
                                                isCompleted
                                            },
                                            skipedUnits,
                                            reTest: currentSession.reTest ? skipedUnits.length !== 0 : false
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })()
        case actions.SKIP_TEST:
            return (() => {
                const uid = action.payload.uid;
                const cid = state.units[uid].courseId
                const sid = state.courses.entities.values[cid].courseSessionId
                const currentCourse = state.courses.entities.values[cid];
                const currentSession = state.sessionsLogs[sid]

                const currentUid = currentSession.log.lastViewedUnit || zeroId
                const currentUnitIndex = currentUid === zeroId ? 0 : currentCourse.courseUnits.findIndex((val: any) => val.id === currentUid)

                const notCompletedUnits = currentCourse.courseUnits.filter((unit: any) => {
                    if (!state.unitsLogs[unit.id]) return true
                    else if (!state.unitsLogs[unit.id].isCompleted) return true
                    else return false
                })
                const isLastUnit = notCompletedUnits.length === 1

                // const isLastUnit = currentCourse.courseUnits[currentUnitIndex].isLastUnit
                const nextIdx = isLastUnit ? currentUnitIndex : currentUnitIndex + 1

                const completedCount = currentCourse.courseUnits.reduce((accumulator: number, unit: any) => accumulator + (state.unitsLogs[unit.id].isCompleted ? 1 : 0), 0);

                const isAllComleted = currentCourse.courseUnits
                    // .filter((unit: any) => unit.id !== currentUid)
                    .every((unit: any) => state.unitsLogs[unit.id].isCompleted)
                const isCompleted = isAllComleted

                const nextUnitId = isCompleted ? zeroId : currentCourse.courseUnits[nextIdx].id

                const courseCompletedUnits = (state.unitsLogs[currentUid].unitType !== 'test' && !state.unitsLogs[currentUid].isCompleted) ? (completedCount + 1) : completedCount

                // const currentUnit = state.unitsLogs[currentUid]
                const lastSkipedUnits = currentSession.skipedUnits || []
                const skipedUnits = [...lastSkipedUnits, currentUid]

                let view = 'result';
                if (!isCompleted) {
                    // if (currentUnit.isLastUnit) view = 'skiped';
                    // if (currentCourse.courseUnits[currentUnitIndex].isLastUnit) view = 'skiped';
                    if (isLastUnit) view = 'skiped';
                    else if (currentCourse.courseUnits[nextIdx].unitType === 'page') view = 'page';
                    else if (currentCourse.courseUnits[nextIdx].unitType === 'wiki') view = 'wiki';
                    else if (currentCourse.courseUnits[nextIdx].unitType === 'test') view = 'test';
                }
                return {
                    ...state,
                    unitsLogs: {
                        ...state.unitsLogs,
                        [uid]: {
                            ...state.unitsLogs[uid],
                            isSkipped: true,
                            unitLogId: action.payload.unitLogId
                        }
                    },
                    sessionsLogs: {
                        ...state.sessionsLogs,
                        [sid]: {
                            ...state.sessionsLogs[sid],
                            log: {
                                ...state.sessionsLogs[sid].log,
                                lastViewedUnit: nextUnitId,
                            },
                            skipedUnits
                        }
                    },
                    courses: {
                        ...state.courses,
                        entities: {
                            values: {
                                ...state.courses.entities.values,
                                [cid]: {
                                    ...state.courses.entities.values[cid],
                                    // courseUnits: [...courseUnits],
                                    courseCompletedUnits,
                                    view,
                                    normalSessions: {
                                        ...state.courses.entities.values[cid].normalSessions,
                                        [sid]: {
                                            ...state.courses.entities.values[cid].normalSessions[sid],
                                            // log: {
                                            //     ...state.courses.entities.values[cid].normalSessions[sid].log,
                                            //     lastViewedUnit: nextUnitId,
                                            //     // isCompleted
                                            // },
                                            skipedUnits
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })()
        case actions.SET_CURRENT_UNIT: {
            const { sid, uid, isSkipped, view, cid } = action.payload
            return {
                ...state,
                sessionsLogs: {
                    ...state.sessionsLogs,
                    [sid]: {
                        ...state.sessionsLogs[sid],
                        log: {
                            ...state.sessionsLogs[sid].log,
                            lastViewedUnit: uid,
                        },
                        reTest: isSkipped
                    }
                },
                courses: {
                    ...state.courses,
                    entities: {
                        values: {
                            ...state.courses.entities.values,
                            [cid]: {
                                ...state.courses.entities.values[cid],
                                view,
                                normalSessions: {
                                    ...state.courses.entities.values[cid].normalSessions,
                                    [sid]: {
                                        ...state.courses.entities.values[cid].normalSessions[sid],
                                        reTest: isSkipped,
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        /*case actions.JUMP_TO_UNIT:
            return (() => {
                const uid = action.payload.unitId
                const cid = action.payload.courseId

                const currentCourse = state.courses.entities.values[cid];
                const sid = state.courses.entities.values[cid].courseSessionId
                const targetIndex = currentCourse.courseUnits.findIndex((unit: any) => unit.id === uid)
                const prevUnit = (targetIndex > 0) ? state.unitsLogs[currentCourse.courseUnits[targetIndex - 1].id] : {}
                let prevIsAccepted = targetIndex === 0;
                prevIsAccepted = prevIsAccepted ? prevIsAccepted : (targetIndex > 0) && (prevUnit.isSkipped || prevUnit.isCompleted);
                const targetUnit = state.unitsLogs[uid];//.courseUnits.find((val: any) => val.id === uid)

                if (!prevIsAccepted) {
                    if (targetUnit.unitType === 'page' && !targetUnit.isCompleted) return state;
                    else if (targetUnit.unitType === 'wiki' && !targetUnit.isCompleted) return state;
                    else if (targetUnit.unitType === 'test' && !targetUnit.isSkipped && !targetUnit.isCompleted) return state;
                }
                // if ((targetUnit.unitType !== 'page' || !targetUnit.isSkipped) && !targetUnit.isCompleted) return state;

                let view = 'result';
                if (targetUnit.unitType === 'page') view = 'page';
                else if (targetUnit.unitType === 'wiki') view = 'wiki';
                else if (targetUnit.unitType === 'test') view = 'test';


                return {
                    ...state,
                    sessionsLogs: {
                        ...state.sessionsLogs,
                        [sid]: {
                            ...state.sessionsLogs[sid],
                            log: {
                                ...state.sessionsLogs[sid].log,
                                lastViewedUnit: uid,
                            },
                            reTest: targetUnit.isSkipped
                        }
                    },
                    courses: {
                        ...state.courses,
                        entities: {
                            values: {
                                ...state.courses.entities.values,
                                [cid]: {
                                    ...state.courses.entities.values[cid],
                                    view,
                                    normalSessions: {
                                        ...state.courses.entities.values[cid].normalSessions,
                                        [sid]: {
                                            ...state.courses.entities.values[cid].normalSessions[sid],
                                            reTest: targetUnit.isSkipped,
                                            // log: {
                                            //     ...state.courses.entities.values[cid].normalSessions[sid].log,
                                            //     lastViewedUnit: uid,
                                            // }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })()*/
        case actions.SET_UNIT_RESULT:
            return (() => {
                return {
                    ...state,
                    unitsResults: {
                        ...state.unitsResults,
                        [action.payload.ulid]: action.payload.data
                    }
                }
            })()
        /*case 'SET_SESSION_RESULT':
            return (() => {
                return {
                    ...state,
                    sessionResults: {
                        ...state.sessionResults,
                        [action.payload.sid]: action.payload.data
                    }
                }
            })()*/
        case actions.REMOVE_UNIT_RESULT:
            return (() => {
                return {
                    ...state,
                    unitsResults: {
                        ...state.unitsResults,
                        [action.payload.uid]: undefined
                    }
                }
            })()
        case actions.USE_TEST_ATTEMPTS:
            return (() => {
                const uid = action.payload.uid
                const cid = state.units[uid].courseId
                // const courseUnits = state.courses.entities.values[cid].courseUnits.map((val: any) => {
                //     if (val.id === uid)
                //         return {
                //             ...val,
                //             isCompleted: false,
                //             attemptsCount: val.attemptsCount + 1
                //         }
                //     return val;
                // })
                const resetQuestions: any = {}
                state.units[uid].questions.forEach((qid: string) => {
                    const resetAnswers: any = {}
                    // state.units[uid].normalQuestions[qid].normalAnswers
                    state.units[uid].normalQuestions[qid].answers.forEach((aid: string) => {
                        resetAnswers[aid] = {
                            ...state.units[uid].normalQuestions[qid].normalAnswers[aid],
                            value: ''
                        }
                    })
                    resetQuestions[qid] = {
                        ...state.units[action.payload.uid].normalQuestions[qid],
                        selectedAnswers: [],
                        normalAnswers: resetAnswers
                    }
                })
                return {
                    ...state,
                    courses: {
                        ...state.courses,
                        entities: {
                            values: {
                                ...state.courses.entities.values,
                                [cid]: {
                                    ...state.courses.entities.values[cid],
                                    view: 'test'
                                }
                            }
                        }
                    },
                    unitsLogs: {
                        ...state.unitsLogs,
                        [uid]: {
                            ...state.unitsLogs[action.payload.uid],
                            isCompleted: false,
                            attemptsCount: state.unitsLogs[action.payload.uid].attemptsCount + 1,
                            unitLogId: action.payload.newUnitLogId
                        }
                    },
                    units: {
                        ...state.units,
                        [uid]: {
                            ...state.units[uid],
                            currentQuestion: zeroId,
                            isCompleted: false,
                            reQuestion: false,
                            normalQuestions: resetQuestions
                        }
                    },
                }
            })()
        case actions.SET_CURRENT_QUESTION: {
            const { uid, qid } = action.payload
            return {
                ...state,
                units: {
                    ...state.units,
                    [uid]: {
                        ...state.units[uid],
                        currentQuestion: qid
                    }
                }
            }
        }
        case actions.SET_NEXT_QUESTION:
            return (() => {
                const uid = action.payload.uid

                const currentQuestionId = state.units[uid].currentQuestion
                let nextIndex = state.units[uid].questions.indexOf(currentQuestionId) + 1;
                let skipedQuestions = state.units[uid].skipedQuestions ? state.units[uid].skipedQuestions : []
                skipedQuestions = skipedQuestions.filter((val: string) => val !== currentQuestionId)
                let isCompleted = (state.units[uid].questions.length <= nextIndex) && (skipedQuestions.length === 0);
                let nextQuestionId = isCompleted ? zeroId : state.units[uid].questions[nextIndex]
                let isLastQuestion = (state.units[uid].questions.length - 1 === nextIndex) && skipedQuestions.length === 0

                if (state.units[uid].normalQuestions[currentQuestionId] && state.units[uid].reQuestion) {
                    nextIndex = skipedQuestions.indexOf(currentQuestionId) + 1
                    nextQuestionId = skipedQuestions.length === 0 ? zeroId : skipedQuestions[nextIndex]
                    isCompleted = zeroId === nextQuestionId
                }

                return {
                    ...state,
                    units: {
                        ...state.units,
                        [uid]: {
                            ...state.units[uid],
                            currentQuestion: nextQuestionId,
                            normalQuestions: {
                                ...state.units[uid].normalQuestions,
                                [nextQuestionId]: {
                                    ...state.units[uid].normalQuestions[nextQuestionId],
                                    isLastQuestion
                                }
                            },
                            skipedQuestions,
                            // isCompleted,
                            // reQuestion: false
                        }
                    },
                    timers: {
                        ...state.timers,
                        [uid]: {
                            ...state.timers[uid],
                            // completeTime: uid.completeTime,
                            questions: {
                                ...state.timers[uid].questions,
                                // [currentQuestionId]: {
                                //     ...state.timers[uid].questions[currentQuestionId],
                                //     // complete: 123
                                // },
                                [nextQuestionId]: {
                                    ...state.timers[uid].questions[nextQuestionId],
                                    begin: action.payload.begineTime,
                                }
                            }
                        }
                    }
                };
            })()
        case actions.SET_QUESTION_COMPLETE: {
            const { uid, qid } = action.payload
            return {
                ...state,
                units: {
                    ...state.units,
                    [uid]: {
                        ...state.units[uid],
                        normalQuestions: {
                            ...state.units[uid].normalQuestions,
                            [qid]: {
                                ...state.units[uid].normalQuestions[qid],
                                isCompleted: true,
                                skiped: false
                            }
                        },
                    }
                }
            }
        }
        case 'deprecated'://actions.SET_QUESTION_COMPLETE:
            return (() => {
                const uid = action.payload.uid
                const qid = action.payload.qid
                return {
                    ...state,
                    units: {
                        ...state.units,
                        [uid]: {
                            ...state.units[uid],
                            normalQuestions: {
                                ...state.units[uid].normalQuestions,
                                [qid]: {
                                    ...state.units[uid].normalQuestions[qid],
                                    isCompleted: true
                                }
                            },
                        }
                    },
                    timers: {
                        ...state.timers,
                        [uid]: {
                            ...state.timers[uid],
                            // completeTime: action.payload.completeTime,
                            questions: {
                                ...state.timers[uid].questions,
                                [qid]: {
                                    // begin: 0,
                                    ...state.timers[uid].questions[qid],
                                    complete: action.payload.completeTime
                                }
                            }
                        }
                    }
                }
            })()
        case actions.SET_COMPLETED_TEST: {
            const { uid } = action.payload
            return {
                ...state,
                unitsLogs: {
                    ...state.unitsLogs,
                    [uid]: {
                        ...state.unitsLogs[uid],
                        isSkipped: false,
                        isCompleted: true
                    }
                }
            }
        }
        case actions.ADD_ANSWER_FILE: {
            const { uid, aid, file } = action.payload
            const currentQuestion = state.units[uid].normalQuestions[state.units[uid].currentQuestion]
            return {
                ...state,
                units: {
                    ...state.units,
                    [uid]: {
                        ...state.units[uid],
                        normalQuestions: {
                            ...state.units[uid].normalQuestions,
                            [currentQuestion.id]: {
                                ...state.units[uid].normalQuestions[currentQuestion.id],
                                normalAnswers: {
                                    ...state.units[uid].normalQuestions[currentQuestion.id].normalAnswers,
                                    [aid]: {
                                        ...state.units[uid].normalQuestions[currentQuestion.id].normalAnswers[aid],
                                        files: file
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        case 'depreceted'://actions.COMPLETE_TEST:
            return (() => {
                clearInterval(state.timers[action.payload.uid].timerId);
                clearTimeout(state.timers[action.payload.uid].timeoutId)
                clearTimeout(state.timers[action.payload.uid].toastTimeoutId)

                const currentUid = action.payload.uid
                const cid = state.units[currentUid].courseId
                const currentCourse = state.courses.entities.values[cid]
                const sid = currentCourse.courseSessionId
                const currentSession = state.sessionsLogs[sid]
                const qid = state.units[action.payload.uid].currentQuestion

                // const currentUnitIndex = currentUid === zeroId ? 0 : currentCourse.courseUnits.findIndex((val: any) => val.id === currentUid)

                const courseCompletedUnits = currentCourse.courseUnits.reduce((accumulator: number, unit: any) => {
                    return accumulator + (state.unitsLogs[unit.id].isCompleted ? 1 : 0)
                }, 0)

                let nextSkipedUnitId = zeroId

                // const courseUnits = currentCourse.courseUnits.map((val: any) => {
                //     if (val.id === currentUid)
                //         return {
                //             ...val,
                //             isSkipped: false,
                //             isCompleted: true
                //         }
                //     return val;
                // })

                if (currentSession.reTest && currentSession.skipedUnits) {
                    const indexInSkiped = currentSession.skipedUnits.indexOf(currentUid)
                    const isLastSkiped = currentSession.skipedUnits.length - 1 === indexInSkiped
                    const nextSkipedIndex = isLastSkiped ? 0 : indexInSkiped + 1
                    nextSkipedUnitId = currentSession.skipedUnits[nextSkipedIndex]
                }

                const skipedUnits = currentSession.skipedUnits ? currentSession.skipedUnits.filter((val: string) => val !== currentUid) : []
                return {
                    ...state,
                    unitsLogs: {
                        ...state.unitsLogs,
                        [action.payload.uid]: {
                            ...state.unitsLogs[action.payload.uid],
                            isSkipped: false,
                            isCompleted: true
                        }
                    },
                    sessionsLogs: {
                        ...state.sessionsLogs,
                        [sid]: {
                            ...currentSession,
                            /*log: {
                                ...currentSession.log,
                                lastViewedUnit: nextUnitId,
                                isCompleted
                            },*/
                            skipedUnits,
                            nextSkipedUnitId,
                            reTest: currentSession.reTest ? skipedUnits.length !== 0 : false
                        }
                    },
                    /*courses: {
                        ...state.courses,
                        entities: {
                            values: {
                                ...state.courses.entities.values,
                                [cid]: {
                                    ...currentCourse,
                                    courseCompletedUnits: courseCompletedUnits + 1,
                                }
                            }
                        }
                    },*/
                    /*units: {
                        ...state.units,
                        [action.payload.uid]: {
                            ...state.units[action.payload.uid],
                            currentQuestion: zeroId,
                            isCompleted: true,
                            skipedQuestions: undefined,
                            reQuestion: false
                        }
                    },*/
                    /*timers: {
                        ...state.timers,
                        [action.payload.uid]: {
                            ...state.timers[action.payload.uid],
                            completeTime: action.payload.completeTime,
                            questions: {
                                ...state.timers[action.payload.uid].questions,
                                [qid]: {
                                    // begin: 0,
                                    ...state.timers[action.payload.uid].questions[qid],
                                    complete: action.payload.completeTime
                                }
                            }
                        }
                    }*/
                }
            })()
        case actions.JUMP_TO_QUESTION:
            return {
                ...state,
                unitsLogs: {
                    ...state.unitsLogs,
                    [action.payload.uid]: {
                        ...state.unitsLogs[action.payload.uid],
                        // isSkipped: false,
                        isCompleted: false
                    }
                },
                units: {
                    ...state.units,
                    [action.payload.uid]: {
                        ...state.units[action.payload.uid],
                        currentQuestion: action.payload.qid,
                        isCompleted: false,
                        reQuestion: true
                    }
                },
                timers: {
                    ...state.timers,
                    [action.payload.uid]: {
                        ...state.timers[action.payload.uid],
                        questions: {
                            ...state.timers[action.payload.uid].questions,
                            [action.payload.qid]: {
                                ...state.timers[action.payload.uid].questions[action.payload.qid],
                                begin: action.payload.begineTime,
                            }
                        }
                    }
                }
            }
        case actions.SKIP_QUESTION: {
            const { uid, qid } = action.payload
            return {
                ...state,
                units: {
                    ...state.units,
                    [uid]: {
                        ...state.units[uid],
                        currentQuestion: qid,
                        normalQuestions: {
                            ...state.units[uid].normalQuestions,
                            [qid]: {
                                ...state.units[uid].normalQuestions[qid],
                                skiped: true
                            }
                        }
                    }
                },
            }
        }
        case 'deprecated'://actions.SKIP_QUESTION:
            return (() => {
                const currentQuestionId = state.units[action.payload.uid].currentQuestion
                let nextIndex = state.units[action.payload.uid].questions.indexOf(currentQuestionId) + 1;
                const skipedQuestions = !state.units[action.payload.uid].skipedQuestions ? [state.units[action.payload.uid].currentQuestion]
                    : [...state.units[action.payload.uid].skipedQuestions.filter((val: string) => val !== currentQuestionId), currentQuestionId]
                let isCompleted = (state.units[action.payload.uid].questions.length <= nextIndex) && (skipedQuestions.length === 0);
                let nextQuestionId = isCompleted ? zeroId : state.units[action.payload.uid].questions[nextIndex]

                if (state.units[action.payload.uid].normalQuestions[currentQuestionId] && state.units[action.payload.uid].reQuestion) {
                    nextIndex = skipedQuestions.indexOf(currentQuestionId) + 1
                    nextQuestionId = skipedQuestions.length === 0 ? zeroId : skipedQuestions[nextIndex]
                    isCompleted = zeroId === nextQuestionId
                }

                return {
                    ...state,
                    unitsLogs: {
                        ...state.unitsLogs,
                        [action.payload.uid]: {
                            ...state.unitsLogs[action.payload.uid],
                            isCompleted
                        }
                    },
                    units: {
                        ...state.units,
                        [action.payload.uid]: {
                            ...state.units[action.payload.uid],
                            currentQuestion: nextQuestionId,
                            isCompleted,
                            skipedQuestions,
                            normalQuestions: {
                                ...state.units[action.payload.uid].normalQuestions,
                                [currentQuestionId]: {
                                    ...state.units[action.payload.uid].normalQuestions[currentQuestionId],
                                    skiped: true
                                }
                            }
                        }
                    },
                    timers: {
                        ...state.timers,
                        [action.payload.uid]: {
                            ...state.timers[action.payload.uid],
                            // completeTime: uid.completeTime,
                            questions: {
                                ...state.timers[action.payload.uid].questions,
                                // [currentQuestionId]: {
                                //     ...state.timers[uid].questions[currentQuestionId],
                                //     // complete: 123
                                // },
                                [nextQuestionId]: {
                                    ...state.timers[action.payload.uid].questions[nextQuestionId],
                                    begin: action.payload.begineTime,
                                }
                            }
                        }
                    }
                };
            })()
        case actions.START_QUESTION:
            return (() => {
                const lastIndex = state.units[action.payload.unitId].questions.length - 1;
                const lastQuestionId = state.units[action.payload.unitId].questions[lastIndex]
                const currentQuestion = state.units[action.payload.unitId].questions[0]
                return {
                    ...state,
                    units: {
                        ...state.units,
                        [action.payload.unitId]: {
                            ...state.units[action.payload.unitId],
                            currentQuestion,
                            normalQuestions: {
                                ...state.units[action.payload.unitId].normalQuestions,
                                [lastQuestionId]: {
                                    ...state.units[action.payload.unitId].normalQuestions[lastQuestionId],
                                    isLastQuestion: true
                                }
                            },
                        }
                    },
                    timers: {
                        ...state.timers,
                        [action.payload.unitId]: {
                            unitId: action.payload.unitId,
                            time: state.units[action.payload.unitId].maxTime,
                            timerId: action.payload.timerId,
                            startTime: action.payload.startTime,
                            timeCount: action.payload.timeCount,
                            toastTimeoutId: action.payload.toastTimeoutId,
                            completeTime: -1,
                            // remainingTime: action.payload.remainingTime
                            questions: {
                                ...(state.timers[action.payload.unitId] ? state.timers[action.payload.unitId].questions : {}),
                                [currentQuestion]: {
                                    begin: action.payload.startTime,
                                    complete: -1
                                }
                            }
                        }
                    }
                };
            })()
        case actions.SET_SESSION_TIMEOUT:
            return (() => {
                return {
                    ...state,
                    sessionTimer: {
                        // timerId: 0,
                        // cancleTimerId: 0,
                        // sid: zeroId,
                        // completeBeforeDate: '',
                        ...action.payload
                    }
                }
            })()
        case actions.CHANGE_TIME:
            return {
                ...state,
                timers: {
                    ...state.timers,
                    [action.payload]: {
                        ...state.timers[action.payload],
                        time: state.timers[action.payload].time - 1,
                        // remainingTime: action.payload.remainingTime
                    }
                }
            }
        case actions.SELECT_ANSWER:
            const currentQuestion = state.units[action.payload.uid].normalQuestions[state.units[action.payload.uid].currentQuestion]
            const selected = currentQuestion.selectedAnswers ? currentQuestion.selectedAnswers : []
            let selectedAnswers: string[] = []
            if (currentQuestion.qtype === 1)
                selectedAnswers = !selected.includes(action.payload.aid) ? [...selected, action.payload.aid] : selected.filter((val: any) => action.payload.aid !== val);
            else if (currentQuestion.qtype === 0)
                selectedAnswers = [action.payload.aid]
            else if (currentQuestion.qtype === 2)
                selectedAnswers = [action.payload.aid]
            return {
                ...state,
                units: {
                    ...state.units,
                    [action.payload.uid]: {
                        ...state.units[action.payload.uid],
                        normalQuestions: {
                            ...state.units[action.payload.uid].normalQuestions,
                            [currentQuestion.id]: {
                                ...state.units[action.payload.uid].normalQuestions[currentQuestion.id],
                                selectedAnswers,
                                normalAnswers: {
                                    ...state.units[action.payload.uid].normalQuestions[currentQuestion.id].normalAnswers,
                                    [action.payload.aid]: {
                                        ...state.units[action.payload.uid].normalQuestions[currentQuestion.id].normalAnswers[action.payload.aid],
                                        value: action.payload.value
                                    }
                                }
                            }
                        }
                    }
                }
            };

        default:
            return state;
    }
}
