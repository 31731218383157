import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeInfoProps } from './Widget_type_info.index';
import './Widget_type_avatarBlock.scss';
import { default as WidgetPresenter } from '../Widget';
import { widgets } from "i.widgets";
import { AvatarBlock } from 'blocks/InfoBlock/UserNew/Avatar';

const types = {
  common: AvatarBlock,
}

const type = 'avatarBlock';


export default class WidgetTypeInfoPresenter extends WidgetPresenter<IWidgetTypeInfoProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    const Tag = widgets.components.common
    return <Tag {...this.props} className={cnWidget({ type, subtype })}>
      <Component
        {...this.props}
        className={cnWidget({ type, subtype })}
        settings={this.props.widget.settings}
        context={this.props.wcontext}
        tag={widgets.components.common} />
    </Tag>
  }

}

