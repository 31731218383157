import { cn } from '@bem-react/classname';
import { IDialog } from '../../DialogWrapper.index';

export interface INewsLikersProps extends INewsLikersOwnProps { }

export interface INewsLikersOwnProps extends IDialog {
  nId: string;
  status: string;
}

export const cnNewsLikers = cn('NewsLikers');