const ImageRegexp = /^!\[([^\]]*)]\s*\(([^)"]+)( "([^)"]+)")?\)/

export const imageBlock = (remarkable: any) => {
    remarkable.block.ruler.before('paragraph', 'image', (state: any, startLine: any, endLine: any, silent: any) => {
        const pos = state.bMarks[startLine] + state.tShift[startLine]
        const max = state.eMarks[startLine]

        if (pos >= max) {
            return false
        }
        if (!state.src) {
            return false
        }
        if (state.src[pos] !== '!') {
            return false
        }

        var match = ImageRegexp.exec(state.src.slice(pos))
        if (!match) {
            return false
        }
        // in silent mode it shouldn't output any tokens or modify pending
        if (!silent) {
            state.tokens.push({
                type: 'image_open',
                src: match[2],
                alt: match[1],
                lines: [startLine, state.line],
                level: state.level,
                // content: 'p image_open'
            })


            state.tokens.push({
                type: 'inline',
                content: match[4] || ' ',
                level: state.level + 1,
                lines: [startLine, state.line],
                children: [
                    {
                        src: match[2],
                        level: 0,
                        title: match[1],
                        type: "image_open",
                        // content: 'c image_open'
                    },
                    {
                        src: match[2],
                        level: 0,
                        title: match[1],
                        type: "text",
                        content: ' '
                    },
                    {
                        src: match[2],
                        level: 0,
                        title: match[1],
                        type: "image_close",
                        // content: 'c image_close'
                    }]
            })

            state.tokens.push({
                type: 'image_close',
                level: state.level,
                // content: 'p image_close'
            })
        }

        state.line = startLine + 1

        return true
    })
}

const LinkRegexp = /\[(.+)\]\(([^")]+)("(.+)")?\)/

export const linkBlock = (remarkable: any) => {
    remarkable.block.ruler.before('paragraph', 'links', (state: any, startLine: any, endLine: any, silent: any) => {
        const pos = state.bMarks[startLine] + state.tShift[startLine]
        const max = state.eMarks[startLine]

        if (pos >= max) {
            return false
        }
        if (!state.src) {
            return false
        }
        if (state.src[pos+1] !== '!') {
            return false
        }

        var match = LinkRegexp.exec(state.src.slice(pos))
        if (!match) {
            return false
        }
        if (match[1] && match[1].toLowerCase().includes('pryanikyvideo')) {}
        else return false;
        // in silent mode it shouldn't output any tokens or modify pending
        if (!silent) {
            state.tokens.push({
                type: 'video_open',
                src: match[2],
                alt: match[1],
                title: match[4],
                lines: [startLine, state.line],
                level: state.level,
                // content: match[1]
                // content: ' '
            })


            state.tokens.push({
                type: 'inline',
                // content: match[4] || ' ',
                content: '',
                level: state.level + 1,
                lines: [startLine, state.line],
                children: [
                    {
                        src: match[2],
                        level: 0,
                        title: match[4],
                        alt: match[1],
                        type: "video_open",
                        // content: match[1]
                        // content: ' '
                    },
                    {
                        src: match[2],
                        level: 0,
                        title: match[1],
                        type: "text",
                        content: ' '//match[1]
                    },
                    {
                        src: match[2],
                        level: 0,
                        title: match[4],
                        alt: match[1],
                        type: "video_close",
                        // content: match[1]
                        // content: ' '
                    }]
            })

            state.tokens.push({
                type: 'video_close',
                level: state.level,
                // content: ' '
            })
        }

        state.line = startLine + 1
        return true
    })
}


export const underline = (remarkable: any) => {
    remarkable.inline.ruler.before('emphasis', 'underline', (state: any, startLine: any, endLine: any, silent: any) => {
        let startCount,
            count,
            found,
            oldCount,
            newCount,
            stack,
            res,
            max = state.posMax,
            start = state.pos,
            marker = state.src.charCodeAt(start);
        
        if (marker !== 0x7E /* ~ */ ) { return false; }
        if (silent) { return false; } // don't run any pairs in validation mode

        res = scanDelims(state, start);
        startCount = res.delims;
        if (!res.can_open) {
            state.pos += startCount;
            if (!silent) { state.pending += state.src.slice(start, state.pos); }
            return true;
        }

        if (state.level >= state.options.maxNesting) { return false; }

        state.pos = start + startCount;
        stack = [startCount] as any;
        while (state.pos < max) {
            if (state.src.charCodeAt(state.pos) === marker) {
                res = scanDelims(state, state.pos);
                count = res.delims;
                if (res.can_close) {
                    oldCount = stack.pop();
                    newCount = count;

                    while (oldCount !== newCount) {
                        if (newCount < oldCount) {
                            stack.push(oldCount - newCount);
                            break;
                        }

                        // assert(newCount > oldCount)
                        newCount -= oldCount;

                        if (stack.length === 0) { break; }
                        state.pos += oldCount;
                        oldCount = stack.pop();
                    }

                    if (stack.length === 0) {
                        startCount = oldCount;
                        found = true;
                        break;
                    }
                    state.pos += count;
                    continue;
                }

                if (res.can_open) { stack.push(count); }
                state.pos += count;
                continue;
            }

            state.parser.skipToken(state);
        }

        if (!found) {
            // parser failed to find ending tag, so it's not valid emphasis
            state.pos = start;
            return false;
        }

        // found!
        state.posMax = state.pos;
        state.pos = start + startCount;
        if (!silent) {
            if (startCount === 2 || startCount === 3) {
                state.push({ type: 'underline_open', level: state.level++ });
            }

            state.parser.tokenize(state);

            if (startCount === 2 || startCount === 3) {
                state.push({ type: 'underline_close', level: --state.level });
            }
        }

        state.pos = state.posMax + startCount;
        state.posMax = max;
        return true;
    })
}

// UNDERLINE
var UNDERLINE_RE = /<u>(.+)<\/u>/g;

// Process *this* and _that_

function isAlphaNum(code: any) {
    return (code >= 0x30 /* 0 */ && code <= 0x39 /* 9 */) ||
        (code >= 0x41 /* A */ && code <= 0x5A /* Z */) ||
        (code >= 0x61 /* a */ && code <= 0x7A /* z */);
}

// parse sequence of emphasis markers,
// "start" should point at a valid marker
function scanDelims(state: any, start: any) {
    let pos = start, lastChar, nextChar, count,
        can_open = true,
        can_close = true,
        max = state.posMax,
        marker = state.src.charCodeAt(start);

    lastChar = start > 0 ? state.src.charCodeAt(start - 1) : -1;

    while (pos < max && state.src.charCodeAt(pos) === marker) { pos++; }
    if (pos >= max) { can_open = false; }
    count = pos - start;

    if (count >= 4) {
        // sequence of four or more unescaped markers can't start/end an emphasis
        can_open = can_close = false;
    } else {
        nextChar = pos < max ? state.src.charCodeAt(pos) : -1;

        // check whitespace conditions
        if (nextChar === 0x20 || nextChar === 0x0A) { can_open = false; }
        if (lastChar === 0x20 || lastChar === 0x0A) { can_close = false; }

        if (marker === 0x5F /* _ */) {
            // check if we aren't inside the word
            if (isAlphaNum(lastChar)) { can_open = false; }
            if (isAlphaNum(nextChar)) { can_close = false; }
        }
    }

    return {
        can_open: can_open,
        can_close: can_close,
        delims: count
    };
}
