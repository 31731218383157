import { IKPIReducer } from '../reducer/types'
import { pick, Many, PropertyPath } from 'lodash'
import { IKPIItem } from '../../types'
/**
 * 
 * declarate store selectors
 */
export type stateType = { kpiReducer: IKPIReducer }//затычка

/**
 * получить корневое состояние kpi
 * @param state 
 */
export function getKPI<T extends IKPIReducer>(state: stateType) {
    return state.kpiReducer as T;
}

/**
 * вернёт указаные параметры
 * @param id
 * @param params
 */
export function getKPIParams<T extends IKPIReducer, U extends keyof T>(...params: Array<Many<U>>) {
    return (state: stateType) => {
        const kpi = getKPI(state) as T
        return pick(kpi, ...params);
    }
}
const emptyArray: any[] = []
/**
 * вернёт массив ид для пользователя
 * @param userId 
 */
export const getKeysForUser = (userId: string) =>
    (state: stateType) => {
        const { keys } = getKPIParams('keys')(state)
        return keys[userId] || emptyArray
    }

/**
 * 
 * @param id 
 */
export const getItemById = (id: string) =>
    (state: stateType) => {
        const { values } = getKPIParams('values')(state)
        return values[id]
    }

/**
 * вернёт указаные параметры экземпляра kpi
 * @param id 
 * @param params 
 */
export function getItemParamsById(id: string, ...params: Array<Many<keyof IKPIItem>>) {
    return (state: stateType) => {
        const item = getItemById(id)(state)
        return pick(item, ...params);
    }
}
