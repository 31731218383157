import React, { FC, ReactNode, ComponentType, useState, useEffect } from 'react'
import { cn } from '@bem-react/classname';
// import * as utils from 'utils/src/utils';
import ReactDOM from 'react-dom';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { store } from 'redux/store';
import { history } from 'redux/browserHistory';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
export interface IBodyProps<R = {}> {
    handleClose: () => void
    handleAccept: (value: R) => void
}

export interface IModalProps<R = {}> {
    rootEl: HTMLDivElement
    reject: () => void
    resolve: (value: R) => void
    containerProps: TDialogProps
}

export interface TDialogProps extends Omit<DialogProps, 'onClose' | 'open'> {
    fullScreenBreakpoints?: number | "md" | "xs" | "sm" | "lg" | "xl"
}

export const TransitionComponent = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export const defaultDialogProps: TDialogProps = {
    fullWidth: true,
    maxWidth: 'md',
    fullScreenBreakpoints: 'sm',
    TransitionComponent
}

let rootEl: HTMLElement | null = null;
export function createModal<R = {}, P = {}>(Component: ComponentType<P & IBodyProps<R>>, dialogProps: TDialogProps = defaultDialogProps) {
    rootEl = rootEl || document.getElementById('RootModalContainer');

    if (!rootEl) {
        rootEl = document.createElement('div');
        rootEl.setAttribute('id', 'RootModalContainer');
        document.body.appendChild(rootEl);
        ReactDOM.render(<Provider store={store} >
            <ConnectedRouter history={history}>
            </ConnectedRouter>
        </Provider>, rootEl);
    }
    const Modal: FC<P & IModalProps<R>> = function ({
        reject,
        resolve,
        containerProps,
        ...props
    }) {
        const { fullScreenBreakpoints, ...dialogProps } = containerProps
        const [open, setOpen] = React.useState(true);
        useEffect(() => setOpen(true), [resolve])
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down(fullScreenBreakpoints || 'sm'));
        const handleClose = () => {
            setOpen(false);
            reject()
        };
        const handleAccept = (value: any) => {
            setOpen(false);
            resolve(value)
        }
        return <Dialog
            fullScreen={fullScreen}
            {...dialogProps}
            open={open}
            onClose={handleClose}
        >
            <Component
                {...props as any}
                handleClose={handleClose}
                handleAccept={handleAccept}
            />
        </Dialog>

    }
    return function (props: Omit<P, keyof IBodyProps>, containerProps: TDialogProps = dialogProps) {
        return new Promise<R>((resolve, reject) => {
            store && ReactDOM.render(<Provider store={store} >
                <ConnectedRouter history={history}>
                    <Modal {...props as any} containerProps={containerProps} resolve={resolve} reject={reject} rootEl={rootEl} />
                </ConnectedRouter>
            </Provider>, rootEl);
        })
    }
}

type TChildrenArg<R> = { handleAccept: (value: R) => void, handleClose: () => void }
export interface IBodyRender<R> extends IBodyProps<R> {
    Component: FC<TChildrenArg<R>>
}

export function createModalRender<R = {}>() {
    const BodyRender: FC<IBodyRender<R>> = ({ handleClose, handleAccept, Component }) => <Component {...{ handleAccept, handleClose }} />

    const modal = createModal<R, IBodyRender<R>>(BodyRender)

    return (Component: FC<TChildrenArg<R>>) => modal({ Component })
}