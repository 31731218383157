import { ReactNode } from 'react';
import { RepostNews } from './Repost.type'
import {
    loadNewsById
} from 'News/redux/actions'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { bindActionCreators } from 'redux';
import { NewsContextProps } from 'News/contexts/news'
import {
    getNewsById,
    getNewsParamsById
} from 'News/redux/saga/selectors'

export interface INewsPropsType extends NewsContextProps {

}

export type TStateProps = ReturnType<typeof mapStateToProps>

export type TDispatchProps = ReturnType<typeof mapDispatchToProps>
export interface INewsStateType {
}
const newsParams = [
    'text',
    'id',
    'parentGuid',
    'parentNews'
] as const

export const mapStateToProps = (state: any, { newsId }: INewsPropsType) => {
    const params = getNewsParamsById<RepostNews, typeof newsParams[number]>(newsId, newsParams)(state)
    return {
        ...params,
        userData: getCurrentUser(state),
        repost: getNewsById(params.parentGuid)(state)
    }
}


export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadNewsById
}, dispatch);
