import React, { FC, useEffect } from 'react'
import { IReasonSelectorProps } from './ReasonSelector.index'
import './ReasonSelector.scss'
import { CheckboxInput } from 'uielements/src';

export const itemNotInArray = (items: any[], value: string) => !items.find((u: any) => u.value === value);

export const toggleItemInArray = (items: any[], item: any) =>
    itemNotInArray(items, item.value) ? addItemToArray(items, item) : removeItemFromArray(items, item);

export const addItemToArray = (items: any[], item: any) => itemNotInArray(items, item.value) ? [item, ...items] : [...items];

export const removeItemFromArray = (items: any[], item: any) => items.filter((u: any) => u.value !== item.value)


export const ReasonSelector: FC<IReasonSelectorProps> = ({ reasons, selectedReasons, maxReasonsCount, onChange}) => {
    const onChangeItem = (item: any) => () => {
        onChange(toggleItemInArray(selectedReasons, item))
    }
    const single = reasons.length === 1 && reasons[0].options.length === 1
    useEffect(() => {
        if (single) {
            onChange(reasons[0].options);
        }
    }, [])
    return (
        <div className={'ReasonSelector'}>
            {reasons.map((cat, i) => (
                <div className={'ReasonSelector-Category'} key={i}>
                    <div className={'ReasonSelector-CategoryName'} children={cat.label + ':'} />
                    {cat.options.map((reason: any, idx: number) => (
                        <CheckboxInput
                            disabled={single || maxReasonsCount && itemNotInArray(selectedReasons, reason.value)}
                            key={idx}
                            onChange={onChangeItem(reason)}
                            checked={!itemNotInArray(selectedReasons, reason.value)}
                            text={reason.label}
                            value={reason.value}
                        />
                    ))}
                </div>
            ))}
        </div>
    )
}