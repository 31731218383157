import React, { FC } from 'react'
import { IItemProps, cnItem } from './Item.index'
import './Item.scss'
import { 
    getNotModerateTextAnswersCountInUnit, 
    getRigthPercent, 
    isSuccessUnitResult, 
    getTestResultText 
} from 'LMSModule/utils/result.utils'

export const ItemPresenter: FC<IItemProps & any> = ({
    className,
    unitResult,
    unit
}) => {
    const notModerateCount = getNotModerateTextAnswersCountInUnit(unitResult)
    const rigthPercent = getRigthPercent(unitResult.rightAnswersCount, unitResult.questionsCount, notModerateCount)
    const right = isSuccessUnitResult(rigthPercent, unit.passingMinScore)
    const wait = unitResult.waitModerationCount !== 0
    const info = notModerateCount === unitResult.questionsCount
    const notStarted = unitResult.results.length === 0

    return <li className={cnItem({}, [className])}>
        <div className={cnItem('QuestionIcon', { right, info, wait, noMandatory: (!right && unit.skipUnitType === 1) })}></div>
        <div className={cnItem('QuestionContent')}>
            <div className={cnItem('QuestionText')}>{unit.name}</div>
            <div className={cnItem('Answers')}>
                {getTestResultText(wait, right, info, notStarted, unit.skipUnitType)}
            </div>
        </div>
    </li>
}


export const Item = ItemPresenter;