/**
 * @packageDocumentation
 * @module Widget_Settings_list
 */
import { JFdictionary } from "../dictionary";

const relationsArray = [
  'users',
  'groups'
]

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    groups: {
      type: types.boolean,
      relations: relationsArray.filter((val) => val !== 'groups'),
      require: true
    },
    users: {
      type: types.boolean,
      relations: relationsArray.filter((val) => val !== 'users'),
      require: true
    },
    groupsTree: {
      type: types.boolean
    },
    badges: {
      type: types.boolean
    },
    selectedUsers: {
      type: types.object
    },
    selectedGroups: {
      type: types.object
    },
    selectedBadges: {
      type: types.object
    },
    name: {
      type: types.string,
      minLength: 1
    },
  }
}
