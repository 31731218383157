import Component, { Presenter } from './component';
import {
    News,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = News.DialogView.IOwnProps;
export type IComponentPresenterProps = News.DialogView.IPresenterProps;
export type IBaseDialogViewProps = News.DialogView.IBaseDialogViewProps;


export default Component