import {
    takeEvery,
    takeLeading,
    takeLatest,
    throttle,
    cancel,
    fork
} from 'redux-saga/effects';
import {
    call,
    select,
    put
} from 'utils/src/saga.effects';

import { routerActions, getLocation } from 'connected-react-router';

import {
    API,
} from 'utils/src/utils'

import {
    getCourseById,
    getCourseUnitIndexById,
    getUnitLogByUnitId,
    getCourseUnits,
    getSessionLogBySessionId,
    getLastViewedUnitId,
    getCourseUnitById,
    getSaveInProcessing,
    getFirstUnstartedSession,
    getUnstartedSessions,
    getCompletedSessions,
    getUnitContentById,
    getUnitQuestionById,
    getCompletedUnits,
    getNotCompletedUnits,
    getCourseLastUnit,
    getCurrentSessionLog,
    getSelectedAnswersIds,
    getAnswerById,
    getAnswersMap,
    getCurrentQuestionId,
    getNextQuestionId,
    getSkipedQuestionIds,
    getCompletedUnitQuestions,
    getQuestionIds,
    getNextNotCompletedQuestionId,
    getNotCompletedMondatoryUnits,
    getCourseFirstUnit
} from './selectors'

import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

import actions from '../../actionsTypes/LMS';


import {
    closeSessionActionType,
    setCancleSession,
    closeOnMandatoryActionType,
    exitFromSession,
    joinToSessionActionType,
    toggleSession,
    setUnitLogs,
    setSessionsLogs,
    loadCourseActionType,
    readCourse,
    transitionToUnitActionType,
    setCurrentUnit,
    saveUnitResultActionType,
    setSaveProcessing,
    updateUnitLog,
    _saveUnitResult,
    joinToSession,
    saveQuestionCompleteActionType,
    loadUserResultActionType,
    setUserSessionResult,
    transitionToNextUnitActionType,
    loadloadCoursesStatusActionType,
    setCoursesStatus,
    scormUnitCompleteActionType,
    scormUnitSuccessStatusActionType,
    scormUnitSaveParamActionType,
    loadFullUnitActionType,
    readUnit,
    transitionToQuestionActionType,
    confirmQuestionActionType,
    saveQuestionComplete,
    setCurrentQuestion,
    setQuestionComplete,
    setUnitTestCompleted,
    saveUnitTestCompletedActionType,
    skipQuestionActionType,
    setSkipQuestion,
    transitionToQuestion,
    saveUnitTestCompleted,
    setSessionCompletMondatory,
    loadUserResult,
    uploadAnswerFileActionType,
    addAnswerFile,
    scormUnitComplete,
    closeSession
} from '../../actions/LMS'

import {
    loadUnitsLogs,
    setFullPlayedCourse,
    loadAllScormParams
} from '../../actions/COMMON'


import {
    buildFlatUnitsLogs,
    buildFlatCourseSessions,
    isNextUnit,
    isAllowTransitionToUnit,
    selectView,
    isAllowTransitionFromUnit,
    buildFlatUnitResults
} from './utils'

import {
    answerQuestionModelCreator
} from 'LMSModule/utils/dataCreators/answerResultCreator'

import uuid from 'uuid';

import { CacheHelper } from 'utils/src/CacheHelper'

import {
    coursesSchema,
    denormalizeData,
    normalizeData,
    singleCourseSchema,
    testResultShema,
    testSchema,
    unitsLogsSchema
} from 'LMSModule/utils/normalize';

import {
    handleLoadAllScormParams,
    handleLoadUnitsLogs,
    handleSetFullPlayedCourse,

} from '../COMMON'

import queryString from 'query-string';


const zeroId = "00000000-0000-0000-0000-000000000000";

/**
 * принудительное, абсолютное завершение сессии.
 * Лишает всех оставшихся попыток.
 * @param action 
 */
const handleCloseSession = function* handleCloseSession(action: closeSessionActionType) {
    try {
        const { sid, courseId } = action.payload
        yield* call(API.lms.sessionComplete, sid);

        yield* put(routerActions.push(`?tab=results&sid=${sid}`))

        return yield* put(setCancleSession(courseId, sid, true))
    } catch (error) {
        console.warn(error)
    }
}

/**
 * закончена обязательная часть курса
 * @param action 
 */
const handleCloseOnMandatoryComplete = function* handleCloseOnMandatoryComplete(action: closeOnMandatoryActionType) {
    try {
        const { sid, courseId } = action.payload

        yield* put(routerActions.push(`?tab=results&sid=${sid}`))

        return yield* put(exitFromSession(sid, courseId))
    } catch (error) {
        console.warn(error)
    }
}

/**
 * активирует сессию для пользователя
 * @param action 
 */
const handleJoinToSession = function* handleJoinToSession(action: joinToSessionActionType) {
    try {
        const { cid, sid } = action.payload
        const request = yield* call(API.lms.sessionJoin, sid, true) as any;
        // @ts-ignore
        const result = yield request.r
        if (result && result.error_code !== 0) {
            switch (result.error_code) {
                case 15006: {
                    // toast.error(`У Вас уже есть активный поток`)
                    console.warn(`sessionJoin, err: 15006, server text:`, result.error_text)
                }
                    break;
                case 15010: {
                    // toast.error(`Поток находится в архиве`)
                    console.warn(`sessionJoin, err: 15010, server text:`, result.error_text)
                }
                    break;

                default: {
                    // toast.error('sessionJoin, unknown error:', d.error_code)
                    console.warn(`sessionJoin, err: ${result.error_code}, server text:`, result.error_text)
                }
            }
        } else if (result && result.error_code === 0 && result.data) {
            yield* put(toggleSession(cid, sid))
            yield* call(handleLoadUnitsLogs, loadUnitsLogs(sid))
            yield* put(routerActions.push(`?tab=studying`))
        } else {
            // toast.error('sessionJoin, internal error');
            console.warn(`sessionJoin, internal error`)
        }
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * получает курс по ид
 * @param action 
 */
const handleLoadSingleCourse = function* handleLoadSingleCourse(action: loadCourseActionType) {
    try {
        const { cid, isArchieved } = action.payload

        const request = yield* call(API.lms.getCourseById, cid, { isArchieved })
        // @ts-ignore
        const result = yield request.r

        if (result && result.error_code !== 0) {
            let message = 'Неизвестная ошибка'
            switch (result.error_code) {
                case 15011: {
                    message = 'Этот курс Вам недоступен.'
                    break;
                }
                case 15002: {
                    message = 'Курс не найден.'
                    break;
                }
                default: {
                    message = 'Неизвестная ошибка: #' + result.error_code
                    // toast.error('loadCourse, unknown error:', d.error_code)
                    console.warn(`loadCourse, err: ${result.error_code}, server text:`, result.error_text)
                }

            }
            yield* put(readCourse({ error_text: message, error_code: result.error_code || 1, id: cid }))
        } else if (result && result.error_code === 0 && result.data) {
            yield* call(handleSetFullPlayedCourse, setFullPlayedCourse(cid, result.data))
        } else {
            yield* put(readCourse({ error_text: 'Сервер не отвечает. Проверьте соединение и перезагрузите страницу.', error_code: 1, id: cid }))
            // toast.error('loadCourse, internal error');
            console.warn(`loadCourse, internal error`, result)
        }
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * переход к юниту
 * @param action 
 */
const handleTransitionToUnit = function* handleTransitionToUnit(action: transitionToUnitActionType) {
    try {
        const { courseId, unitId } = action.payload
        const currentCourse = yield* select(getCourseById(courseId))
        const sid = currentCourse.courseSessionId
        // const firstUnstartedSession = yield* select(getFirstUnstartedSession(courseId))


        // если сессия не выбрана
        if (sid === zeroId) {
            const unstartedSessions = yield* select(getUnstartedSessions(courseId))
            const completedSessions = yield* select(getCompletedSessions(courseId))

            if (completedSessions.length > 0) {//если тесть завершенные сессии, то отправляем на первую завершенную
                yield* put(routerActions.push(`?tab=results&sid=${completedSessions[0].id}&unit=${unitId}`))
                return 0;
            } else if (unstartedSessions.length === 1) {// есть только одна доступная, неначатая сессия, то присоеденяемся к ней
                yield* call(handleJoinToSession, joinToSession(unstartedSessions[0].id, courseId))
            } else if (unstartedSessions.length > 1) {// доступных, неначатых сессий более 1-й
                console.warn('handleTransitionToUnit:', 'session not selected.', 'allow sessions:', unstartedSessions)
                return 0;
            } else if (unstartedSessions.length === 0) {// нет достпных, неначатых сессий
                console.warn('handleTransitionToUnit:', 'session not selected.', 'not allow sessions')
                return 0;
            }
        }

        // получение данных
        const targetIndex = yield* select(getCourseUnitIndexById(unitId, courseId))
        const courseUnits = yield* select(getCourseUnits(courseId))
        const currentUnitId = yield* select(getLastViewedUnitId(sid))
        let currentUnitLog = yield* select(getUnitLogByUnitId(currentUnitId))
        let currentUnit = yield* select(getCourseUnitById(courseId, currentUnitId))
        if (!currentUnitLog) {
            const firstUnit = yield* select(getCourseFirstUnit(courseId))
            currentUnitLog = yield* select(getUnitLogByUnitId(firstUnit.id))
            currentUnit = yield* select(getCourseUnitById(courseId, firstUnit.id))
        }
        const targetUnit = yield* select(getCourseUnitById(courseId, unitId));
        const targetUnitLog = yield* select(getUnitLogByUnitId(unitId))

        // целевой юнит является следующим
        const isNext = isNextUnit(currentUnitId, unitId, courseUnits)

        // ищем предыдущий юнит
        const prevUnitId = (targetIndex > 0) ? courseUnits[targetIndex - 1].id : zeroId
        const prevUnitLog = prevUnitId !== zeroId ? yield* select(getUnitLogByUnitId(prevUnitId)) : {}

        //разрешено ли перемещаться к выбраному юниту?
        const allowToTarget = isAllowTransitionToUnit(targetIndex, prevUnitLog, targetUnitLog, isNext)

        //разрешеноли перемащаться от текущего юнита
        const allowFromCurrent = isAllowTransitionFromUnit(currentUnit, currentUnitLog, targetUnit)

        // разрешеноли перемещение
        const isAllowTransition = allowToTarget && allowFromCurrent

        // требуется выполнить сохранение текущего юнита, если: он не завершен и является уроком
        const isNeedSaveCurrent = !currentUnitLog.isCompleted && currentUnit.unitType === 'wiki'

        // если требуется сохранить текущий юнит и переход разрешен, это не переход к тому же юниту - сохраняем
        if (isNeedSaveCurrent && isAllowTransition && unitId !== currentUnitId) {
            // const forkSave = 
            yield fork(handleSaveUnitResult, _saveUnitResult({
                courseId,
                courseSessionId: sid,
                unitId: currentUnitId
            }))
            // yield cancel(forkSave) 
        }

        // выходим, если переход заперщен
        if (!isAllowTransition) {
            return 0;
        }

        const view = selectView(false, targetUnit.unitType, false);

        // меняем состояние
        yield* put(setCurrentUnit({ view, sid, isSkipped: targetUnitLog.isSkipped, cid: courseId, uid: unitId }));

        // получаем текущий урл
        const loacation = yield* select(getLocation)
        // адрес прохождения курса
        // const path = `/lms/test/${courseId}`
        const path = `?tab=studying`
        //проверяем локацию, если текущий path не совпадает с path прохождения курса, то переходим на прохождение.
        // проверка нужна что бы не флудить в history
        if (loacation.search !== path) {
            yield* put(routerActions.push(path))
        }
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

const handleTransitionToNextUnit = function* handleTransitionToNextUnit(action: transitionToNextUnitActionType) {
    try {
        const { cid } = action.payload

        const currentCourse = yield* select(getCourseById(cid))
        const sid = currentCourse.courseSessionId

        // получение данных
        const courseUnits = yield* select(getCourseUnits(cid))
        let currentUnitId = yield* select(getLastViewedUnitId(sid))
        let currentUnitLog = yield* select(getUnitLogByUnitId(currentUnitId))
        let currentUnit = yield* select(getCourseUnitById(cid, currentUnitId))
        if (!currentUnitLog) {
            const firstUnit = yield* select(getCourseFirstUnit(cid))
            currentUnitId = firstUnit.id
            currentUnitLog = yield* select(getUnitLogByUnitId(firstUnit.id))
            currentUnit = yield* select(getCourseUnitById(cid, firstUnit.id))
        }
        const currentIndex = yield* select(getCourseUnitIndexById(currentUnitId, cid))
        const lastUnit = yield* select(getCourseLastUnit(cid))

        // требуется выполнить сохранение текущего юнита, если: он не завершен и является уроком или скормом
        let isNeedSaveCurrent = !currentUnitLog.isCompleted && (currentUnit.unitType === 'wiki');

        if (currentUnit.unitType === 'scorm' && !currentUnitLog.isCompleted) {
            isNeedSaveCurrent = currentUnitLog.scormCMI ? currentUnitLog.scormCMI['cmi.completion_status'] === 'completed' : false
        }

        // если требуется сохранить текущий юнит 
        if (isNeedSaveCurrent) {
            yield* call(handleSaveUnitResult, _saveUnitResult({
                courseId: cid,
                courseSessionId: sid,
                unitId: currentUnitId
            }))
        }

        // текущий юнит является полседним в курсе?
        const isLastUnit = currentUnit.id === lastUnit.id

        // получаем не пройденые юниты
        const notCompletedUnits = yield* select(getNotCompletedUnits(cid))

        // завершены все юниты?
        const isAllCompleted = notCompletedUnits.length === 0

        if (isAllCompleted) {//все юниты завершены? Отправляемся на результаты
            yield* put(routerActions.push(`?tab=results&sid=${sid}`))
        }

        // индекс юнита к которому нужно перейти
        const targetIndex = isLastUnit ? yield* select(getCourseUnitIndexById(notCompletedUnits[0].id, cid)) : currentIndex + 1
        // юнит к которому нужно перейти
        const targetUnit = courseUnits[targetIndex]
        // лог юнита к которому нужно перейти
        const targetUnitLog = yield* select(getUnitLogByUnitId(targetUnit.id))
        // выбираем представление длля следующего юнита
        const view = selectView(isAllCompleted, targetUnit.unitType, isLastUnit);

        // меняем состояние
        yield* put(setCurrentUnit({ view, sid, isSkipped: targetUnitLog.isSkipped, cid: cid, uid: targetUnit.id }));

        return 0;
    } catch (error) {
        console.warn(error)
    }
}

const handleSendUnitResult = function* handleSendUnitResult(data: any) {
    try {
        const request = yield* call(API.lms.saveUnitResult, data)
        // @ts-ignore
        const result = yield request.r

        if (result && result.error_code !== 0) {
            switch (result.error_code) {
                default: {
                    console.warn(`handleSendUnitResult, err: ${result.error_code}, server text:`, result.error_text)
                }
            }
        } else if (result && result.error_code === 0 && result.data) {
            return true
        } else {
            console.warn(`handleSendUnitResult, internal error`, result)
        }
        return false;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * 
 * @param action 
 */
const handleSaveUnitResult = function* handleSaveUnitResult(action: saveUnitResultActionType) {
    try {
        const { data, next, send } = action.payload
        const saveInProcessing = yield* select(getSaveInProcessing)


        if (saveInProcessing) return 0;

        yield* put(setSaveProcessing(true))

        const unitLog = yield* select(getUnitLogByUnitId(data.unitId))

        const unitLogId = unitLog.unitLogId === zeroId ? uuid() : unitLog.unitLogId;

        yield* put(updateUnitLog(data.unitId, {
            unitLogId,
            isSkipped: false,
            isCompleted: true
        }))

        const nd = { ...data, id: unitLogId }

        yield* call(handleSendUnitResult, nd)

        const unit = yield* select(getUnitContentById(data.unitId))
        const currentSession = yield* select(getCurrentSessionLog(unit.courseId))

        const notCompleted = yield* select(getNotCompletedMondatoryUnits(unit.courseId))
        const notCompletedAll = yield* select(getNotCompletedUnits(unit.courseId))

        if (notCompleted.length === 0)
            yield* put(setSessionCompletMondatory(currentSession.id))
        if (notCompletedAll.length === 0)
            yield* put(closeSession(currentSession.id, unit.courseId))


        yield* put(setSaveProcessing(false))

        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * 
 * @param action 
 */
const handleSaveQuestionComplete = function* handleSaveQuestionComplete(action: saveQuestionCompleteActionType) {
    try {
        const { uid, qid } = action.payload
        // const completeTime = (new Date).getTime()
        const model = answerQuestionModelCreator()

        const selected = yield* select(getSelectedAnswersIds(uid, qid))

        const answersMap = yield* select(getAnswersMap(uid, qid))

        const currentQuestion = yield* select(getUnitQuestionById(uid, qid))
        const currentUnit = yield* select(getUnitContentById(uid))

        // const skipedQuestionsIds = yield* select(getSkipedQuestionIds(uid))
        const completedCount = yield* select(getCompletedUnitQuestions(uid))

        const questionsIds = yield* select(getQuestionIds(uid))

        if (completedCount === questionsIds.length) {
            yield* call(handleSaveUnitTestCompleted, saveUnitTestCompleted(uid))
        }

        if (currentQuestion.isCompleted) {
            yield* call(handleTransitionToQuestion, transitionToQuestion(uid))
            return 0;
        }

        model.answers = selected.map((answerId: string) => ({
            answerId,
            value: answersMap[answerId].value,
            files: answersMap[answerId].files
        }))
        model.qid = qid

        const request = yield* call(API.lms.setQuestionAnswer, model)
        // @ts-ignore
        const result = yield request.r
        if (result && result.error_code !== 0) {
            switch (result.error_code) {
                default: {
                    // toast.error('setQuestionAnswer, unknown error:', d.error_code)
                    console.warn(`setQuestionAnswer, err: ${result.error_code}, server text:`, result.error_text)
                }
            }
        } else if (result && result.error_code === 0 && result.data) {

        } else {
            // toast.error('setQuestionAnswer, internal error');
            console.warn(`setQuestionAnswer, internal error`)
        }

        yield* put(setQuestionComplete(uid, qid))

        if (!currentUnit.highlightCorrectness) {
            if (completedCount === questionsIds.length - 1) {
                yield* call(handleSaveUnitTestCompleted, saveUnitTestCompleted(uid))
                return 0;
            }
            yield* call(handleTransitionToQuestion, transitionToQuestion(uid))

        }

        // dispatch(setQuestionComplete({ qid, uid, completeTime }))
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * 
 * @param action 
 */
const handleLoadUserResult = function* handleLoadUserResult(action: loadUserResultActionType) {
    try {
        const { cid, sid } = action.payload
        const currentUser = yield* select(getAuthUser)

        const request = yield* call(API.lms.getUserResults, cid, currentUser.baseData.id, sid)
        // @ts-ignore
        const result = yield request.r

        if (result && result.error_code !== 0) {
            switch (result.error_code) {
                default: {
                    // toast.error('getUserResults, unknown error:', d.error_code)
                    console.warn(`getUserResults, err: ${result.error_code}, server text:`, result.error_text)
                }
            }
        } else if (result && result.error_code === 0 && result.data) {
            const r = buildFlatUnitResults(result)
            yield* put(setUserSessionResult(sid, r))
        } else {
            // toast.error('getUserResults, internal error');
            console.warn(`getUserResults, internal error`)
        }

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * 
 * @param isMandatory 
 * @param userid 
 */
const handleLoadStatCoursesStatus = function* handleLoadStatCoursesStatus(isMandatory: boolean, userid?: string) {
    try {
        // ключ для кеша
        const cacheKey = `studentcoursesstatus?isMandatory=${isMandatory}&userid=${userid || 'current'}`

        try {
            // запрашиваем из кеша
            const fromCache = yield* call(CacheHelper.get, 'lms_stats', cacheKey)
            // если есть в кеше, то кидаем в стор
            if (fromCache) yield* put(setCoursesStatus(fromCache.data, isMandatory, userid))
        } catch (error) {
            console.warn('postTypes read cache', error)
        }

        const request = yield* call(API.lms.getStudentCoursesStatus, isMandatory, userid)
        // @ts-ignore
        const result = yield request.r

        if (result.error_code === 0) {
            yield* put(setCoursesStatus(result.data, isMandatory, userid))
            // обновляем кеш
            yield* call(CacheHelper.set, 'lms_stats', cacheKey, result)
        }
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * 
 * @param action 
 */
const handleLoadStatCoursesStatusAll = function* handleLoadStatCoursesStatusAll(action: loadloadCoursesStatusActionType) {
    try {
        const { userid } = action.payload
        yield fork(handleLoadStatCoursesStatus, false, userid)
        yield fork(handleLoadStatCoursesStatus, true, userid)

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * 
 * @param action 
 */
const handleScormUnitComplete = function* handleScormUnitComplete(action: scormUnitCompleteActionType) {
    try {
        const { cid, uid } = action.payload

        const unitLog = yield* select(getUnitLogByUnitId(uid))
        const currentCourse = yield* select(getCourseById(cid))
        const currentSid = currentCourse.courseSessionId

        const unitLogId = unitLog.unitLogId === zeroId ? uuid() : unitLog.unitLogId;

        const isCompleted = unitLog.scormCMI ? unitLog.scormCMI['cmi.completion_status'] === 'completed' : false

        const data = { ...unitLog, id: unitLogId, isCompleted, unitId: uid, courseSessionId: currentSid }

        yield* put(updateUnitLog(uid, { ...data }))

        let isCompletedScorm = data.scormCMI["cmi.success_status"] && data.scormCMI["cmi.success_status"] !== "unknown";
        isCompletedScorm = data.scormCMI['cmi.completion_status'] && data.scormCMI['cmi.completion_status'] === 'completed'
        if (isCompletedScorm) {
            // сохраняем изменения на сервер
            yield* call(handleSendUnitResult, data)
        }

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}


/**
 * завершение scorm курса
 * @param action 
 */
const handleScormSuccessStatus = function* handleScormSuccessStatus(action: scormUnitSuccessStatusActionType) {
    try {
        const { cid, uid } = action.payload

        const unitLog = yield* select(getUnitLogByUnitId(uid))
        const currentCourse = yield* select(getCourseById(cid))
        const currentSid = currentCourse.courseSessionId

        // если лога нету, то создаём новый гуид. Иначе используем существующий
        const unitLogId = unitLog.unitLogId === zeroId ? uuid() : unitLog.unitLogId;

        const isCompletedSuccess = unitLog.scormCMI ? unitLog.scormCMI['cmi.success_status'] === 'passed' : false
        // изменяем лог
        const data = { ...unitLog, unitLogId, isCompletedSuccess, unitId: uid, courseSessionId: currentSid }

        // отпраавляем изменения в редакс
        yield* put(updateUnitLog(uid, data))

        let isCompletedScorm = data.scormCMI["cmi.success_status"] && data.scormCMI["cmi.success_status"] !== "unknown";
        isCompletedScorm = data.scormCMI['cmi.completion_status'] && data.scormCMI['cmi.completion_status'] === 'completed'
        if (isCompletedScorm) {
            // сохраняем изменения на сервер
            yield* call(handleSendUnitResult, data)
        }

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * сохранение параметров скорм юнита
 * @param action 
 */
const handleSaveScormParam = function* handleSaveScormParam(action: scormUnitSaveParamActionType) {
    try {
        const { cid, logId, skey, svalue, unitId } = action.payload

        const sessionLog = yield* select(getCurrentSessionLog(cid))
        const unitLog = yield* select(getUnitLogByUnitId(unitId))

        const data = { ...unitLog, scormCMI: { ...(unitLog.scormCMI || {}), [skey]: svalue } }

        // отпраавляем изменения в редакс
        yield* put(updateUnitLog(unitId, data))

        yield* put(scormUnitComplete(cid, unitId))

        const request = yield* call(API.lms.saveScormSetParam, { logId: sessionLog.log.id, skey, svalue, unitId })
        // @ts-ignore
        const result = yield request.r

        if (result && result.error_code === 0) {

        } else {
            console.warn(`handleSaveScormParam, internal error`, result)
        }

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}


const handleLoadFullUnit = function* handleLoadFullUnit(action: loadFullUnitActionType) {
    try {
        const { uid, version } = action.payload

        const request = yield* call(API.lms.getClientUnitContent, uid, version)
        // @ts-ignore
        const result = yield request.r

        if (result && result.error_code !== 0) {
            switch (result.error_code) {
                default: {
                    console.warn(`handleLoadFullUnit, err: ${result.error_code}, server text:`, result.error_text)
                }
            }
        } else if (result && result.error_code === 0 && result.data) {
            if (
                result.data.unitType === 'test' ||
                result.data.unitType === 'task'
            ) {
                result.data.questions = result.data.questions.map((val: any) => ({ ...val, selectedAnswers: [] }))
                const normalQuestions = normalizeData(result.data.questions, testSchema)

                yield* put(readUnit({
                    ...result.data,
                    questions: normalQuestions.result,
                    normalQuestions: normalQuestions.entities.values,
                    currentQuestion: zeroId,
                    unitVersion: version
                }))
            } else if (result.data.unitType === 'scorm') {
                const location = yield* select(getLocation)

                const requestParams = queryString.parse(location.search)

                yield* call(handleLoadAllScormParams, loadAllScormParams(result.data.courseId, uid, requestParams.sid as string))

                yield* put(readUnit({ ...result.data, unitVersion: version }))
            } else {
                yield* put(readUnit({ ...result.data, unitVersion: version }))
            }

        } else {
            console.warn(`handleLoadFullUnit, internal error`, result)
        }

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}


/**
 * 
 * @param action 
 */
const handleTransitionToQuestion = function* handleTransitionToQuestion(action: transitionToQuestionActionType) {
    try {
        const { uid, qid } = action.payload

        const currentQid = yield* select(getCurrentQuestionId(uid))

        const targetQid = qid || (yield* select(getNextNotCompletedQuestionId(uid, currentQid)))

        yield* put(setCurrentQuestion(uid, targetQid))

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}


/**
 * 
 * @param action 
 */
const handleConfirmQuestion = function* handleConfirmQuestion(action: confirmQuestionActionType) {
    try {
        const { qid, uid } = action.payload

        yield* call(handleSaveQuestionComplete, saveQuestionComplete(qid, uid))

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}


/**
 * 
 * @param action 
 */
const handleSaveUnitTestCompleted = function* handleSaveUnitTestCompleted(action: saveUnitTestCompletedActionType) {
    try {
        const { uid } = action.payload

        const saveInProcessing = yield* select(getSaveInProcessing)

        if (saveInProcessing) return 0;

        yield* put(setSaveProcessing(true))

        const unitLog = yield* select(getUnitLogByUnitId(uid))
        const unit = yield* select(getUnitContentById(uid))
        const currentSession = yield* select(getCurrentSessionLog(unit.courseId))

        const toSend: any = {
            courseSessionId: currentSession.id,
            id: unitLog.unitLogId,
            unitId: uid,
            isCompleted: true,
            results: unit.questions.map((val: any) => {
                const selected = unit.normalQuestions[val].selectedAnswers || []
                return {
                    qid: val,
                    time: 0,
                    answers: selected.map((answerId: string) => ({
                        answerId,
                        value: unit.normalQuestions[val].normalAnswers[answerId].value,
                        files: unit.normalQuestions[val].normalAnswers[answerId].files
                    }))
                }
            })
        }
        yield* call(handleSendUnitResult, toSend)

        yield* call(handleLoadUserResult, loadUserResult(unit.courseId, currentSession.id))

        yield* put(updateUnitLog(uid, {
            unitLogId: unitLog.unitLogId,
            isSkipped: false,
            isCompleted: true
        }))

        const notCompleted = yield* select(getNotCompletedMondatoryUnits(unit.courseId))
        const notCompletedAll = yield* select(getNotCompletedUnits(unit.courseId))

        if (notCompleted.length === 0)
            yield* put(setSessionCompletMondatory(currentSession.id))
        if (notCompletedAll.length === 0) {
            yield* put(routerActions.push(`?tab=results&sid=${currentSession.id}`))

            yield* put(setCancleSession(unit.courseId, currentSession.id, true))
            // yield* put(closeSession(currentSession.id, unit.courseId))
        }


        yield* put(setSaveProcessing(false))

        return 0;
    } catch (error) {
        console.warn(error)
    }
}


/**
 * 
 * @param action 
 */
const handleSkipQuestion = function* handleSkipQuestion(action: skipQuestionActionType) {
    try {
        const { qid, uid } = action.payload

        yield* put(setSkipQuestion(uid, qid))

        yield* call(handleTransitionToQuestion, transitionToQuestion(uid))

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}


const handleUploadAnswerFile = function* handleUploadAnswerFile(action: uploadAnswerFileActionType) {
    try {
        const { aid, file, uid } = action.payload
        yield;
        // const result = yield* call(API.files.fetchUpload, attaches)
        return yield* put(addAnswerFile(action.payload))
    } catch (error) {
        console.warn(error)
    }
}

/**
 * module root saga
 */
const root = function* root() {
    yield takeLeading(actions.CLOSE_SESSION, handleCloseSession)
    yield takeLeading(actions.CLOSE_SESSION_ON_COMPLETE_MONDATORY, handleCloseOnMandatoryComplete)
    yield takeLeading(actions.JOIN_TO_SESSION, handleJoinToSession)
    yield takeLeading(actions.LOAD_SINGLE_COURSE, handleLoadSingleCourse)
    yield takeLeading(actions.TRANSITION_TO_UNIT, handleTransitionToUnit)
    yield takeLeading(actions.TRANSITION_TO_NEXT_UNIT, handleTransitionToNextUnit)
    yield takeLeading(actions.SAVE_UNIT_RESULT, handleSaveUnitResult)

    yield takeLeading(actions.SAVE_QUESTION_COMPLETE, handleSaveQuestionComplete)
    yield takeLeading(actions.TRANSITION_TO_QUESTION, handleTransitionToQuestion)
    yield takeLeading(actions.CONFIRM_QUESTION, handleConfirmQuestion)
    yield takeLeading(actions.SKIP_QUESTION, handleSkipQuestion)

    yield takeLeading(actions.LOAD_USER_RESULTS, handleLoadUserResult)
    yield takeLeading(actions.LOAD_COURSES_STATUS, handleLoadStatCoursesStatusAll)
    yield takeLeading(actions.SCORM_UNIT_COMPLETE, handleScormUnitComplete)
    yield takeLeading(actions.SCORM_UNIT_SUCCESS_STATUS, handleScormSuccessStatus)
    yield takeLeading(actions.LOAD_UNIT_CONTENT, handleLoadFullUnit)
    yield takeLeading(actions.SAVE_UNIT_TEST_COMPLETED, handleSaveUnitTestCompleted)
    yield takeLeading(actions.UPLOAD_ANSWER_FILE, handleUploadAnswerFile)

    yield takeEvery(actions.SAVE_SCORM_PARAM, handleSaveScormParam)
};

/**
 * export root saga
 */
export default root;