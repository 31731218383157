import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    unitTimer: any
    unitContent: any
    sId: string
    unit: any
    unitResult: any
    disableActions?: boolean
    attemptAction: any
    detailsRender?: (question: any) => React.ReactNode;
    isAbsolutelyCompleted?: boolean;
    attempt: any;
    cangeAttempt: any;
    notCompletedUnits: any[];
    fullscreen?: boolean
    actions?: React.ReactNode
}

export const cnClassName = cn('TestResult');