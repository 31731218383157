import { IWidget, widgets, IColumn } from "../i.widgets";

export function getWidgetsFromStructure(widget: IWidget, parentWidgetId?: string) {
  let rez: IWidget[] = [];
  if (!widget.relations) widget.relations = [parentWidgetId || 'common', widget.id];
  if (!parentWidgetId && widget.relations[0] !== 'common') widget.relations.splice(0, 0, 'common');
  if (parentWidgetId && widget.relations[0] !== parentWidgetId) widget.relations.splice(0, 0, parentWidgetId);
  if (widget.relations[1] !== widget.id) widget.relations.splice(1, 0, widget.id);
  rez.push(widget)
  if (widget.type === widgets.types.layout || widget.type === widgets.types.tabs) {
    widget.data.forEach((column: IColumn) => {
      (column.items as IWidget<any>[]).forEach((cwidget: IWidget) => {
        const widgs = getWidgetsFromStructure(cwidget, widget.id)
        rez = [...rez, ...widgs];
      })
    });
  }
  return rez;
}

export function prepareWidgetToRequest(structure: {[s: string]: any}) {
  const widgets = getWidgetsFromStructure(structure.layout);
  widgets.forEach((widget: IWidget) => {
    if (widget.relations[0] === 'common') widget.relations.splice(0, 1);
  })
}