import React from 'react';
import { wrapDialogToRedux } from '../../DialogWrapper';
import { IGroupUsersDialogProps, cnGroupUsersDialog } from './GroupUsers.index';
import i18n from '../../../../localizations/i18n';
import {
  CheckboxInput,
  Dialog,
  // DialogTitle,
  Input,
  Textarea,
  DialogText,
  Radio,
  // DialogActions,
  Button,
  cnDialog,
} from 'uielements/src';
import { List } from '../../../List/_type/users/List_type_users';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle/DialogTitle'
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions/DialogActions'
const prpWeelChair: any = {};

class GroupUsersDialogPresenter extends React.Component<IGroupUsersDialogProps> {

  /**
   * render
   */
  public render() {
    const { id, data: { gId }, onChange, onClose } = this.props;
    return (
      <React.Fragment>
        <DialogTitle onClose={onClose}>{i18n.t('pryaniky.dialogs.group.users.title')}</DialogTitle>
        <List id={'groupUsers-' + id} context={{ gId }} {...prpWeelChair} />
        <DialogActions
          closeText={i18n.t('pryaniky.shop.cancel')}
          onClose={onClose} />
        {/* <Button onClick={onClose} padding="lg">
            {i18n.t('pryaniky.shop.cancel')}
          </Button> */}
        {/* </DialogActions> */}
      </React.Fragment>
    )
  }
}

// const GroupUsersDialogPresenter: React.FC<IGroupUsersDialogProps> = ({id,  data: { gId }, onChange, onClose }) => {
//   return (
//     <React.Fragment>
//       <DialogTitle onClose={onClose}>{i18n.t('pryaniky.dialogs.group.users.title')}</DialogTitle>
//       <List id={'groupUsers-' + id} context={{ gId }} {...prpWeelChair}  />
//       <DialogActions>
//         <Button onClick={onClose} padding="lg">
//           {i18n.t('pryaniky.shop.cancel')}
//         </Button>
//       </DialogActions>
//     </React.Fragment>
//   )
// }

export const GroupUsersDialog = wrapDialogToRedux<IGroupUsersDialogProps>(GroupUsersDialogPresenter)