import * as React from 'react';
// import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';
import { ActionButtons} from 'blocks/ActionButtons/ActionButtons'
import { withBemMod } from '@bem-react/core';

import { cnCountSlider } from '../CountSlider.index';
import { ICountSliderTypeModalProps } from './CountSlider_type_modal.index';
import { ICountSliderProps } from '../CountSlider.index';
import './CountSlider_type_modal.scss';
import {Button} from 'uielements/src/Button/Button';

export const withCountSliderTypeModal = withBemMod<ICountSliderTypeModalProps, ICountSliderProps>(
  cnCountSlider(),
  { type: 'modal' }, 
  (Presenter) => (
    ({ saveBtn = () => {/**/ }, closeBtn = () => {/* */}, ...props}) => (
      <div className={'ModalCountSlider ' + props.className}>
        <Presenter {...props} className="CountSlider_type_modal" />
        <div className='ModalCountSlider-Footer'>
          <ActionButtons save={saveBtn} cancle={closeBtn}/>
        </div>
      </div>
    )
  )
);