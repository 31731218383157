import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { cnUnit, ICourseProps, mapDispatchToProps, mapStateToProps } from './Course.index';
import './Course.scss';
import { Lesson } from '../Parts/Lesson/Lesson'
import { Exam } from '../Exam/Exam'
import TaskUnit from '../TaskUnit'
import { Button, ErrorMsg } from 'uielements/src'
import { LessonsList } from '../LessonsList/LessonsList'
import { ListSkipedTests } from '../ListSkipedTests/ListSkipedTests'
import { Redirect } from 'react-router-dom'
import { withPreloader } from '../Preloader/Preloader'
import { CourseLayout } from '../../components/CourseLayout/CourseLayout'
import { Filter } from '../../Skelets/Filter/Filter'
import { Unit } from '../../Skelets/Unit/Unit'
import { CoursePassing } from '../../Skelets/CoursePassing/CoursePassing'
import CompleteBox from '../ManualCompleteSection'
import { tS } from 'LMSModule/utils/i18n.adaptor'
import { ScormLoader } from '../ScormUnit/loader'
const zeroId = "00000000-0000-0000-0000-000000000000";

export const CoursePresenter: React.FC<ICourseProps & any> = ({
  course
}) => {

  const [fullscreen, setFullscreen] = useState(false)
  const view = course.view
  useEffect(() => {
    view === 'result' && setFullscreen(false)
  }, [view])

  const unitCount = course.courseUnits.length

  return <CourseLayout
    fullscreen={fullscreen}
    allowFullscreen={true}//view !== 'result'}
    setFullscreen={setFullscreen}
    classNames={[]}
    middle={<CompleteBox cid={course.id} sid={course.courseSessionId} />}
    aside={unitCount > 1 && <ConectedSelectorLoader course={course} sid={course.lastCompletedSession || course.courseSessionId} courseId={course.id} />}
  >
    <ConectedSessionLoader
      fullscreen={fullscreen}
      setFullscreen={setFullscreen}
      course={course}
      sid={course.lastCompletedSession || course.courseSessionId} />
  </CourseLayout>
};

const CourseErrorHandler: FC<any> = (props) => {
  const { course } = props
  if (course.courseSessionId === zeroId && !course.lastCompletedSession) return <div>
    <ErrorMsg>
      <span>{tS('noSession.1')} </span>
      <Button theme={'unstyled'} type={'rlink'} href={`/lms/${course.id}`}>{tS('select.2')}</Button>
    </ErrorMsg>
  </div>
  return <CoursePresenter {...props} />
}
const WithPreloader = withPreloader(CourseErrorHandler, ['course'], [], <CoursePassing />)

export const Course = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithPreloader);


const getLastViewedUnitFromSessionLog = (sessionLog: any) => sessionLog.log.lastViewedUnit

const getCurrentUnitFromCourse = (course: any, sessionLog: any) => {
  const lastViewedUnit = getLastViewedUnitFromSessionLog(sessionLog)
  const currentUnit = course.courseUnits.find((unit: any) => unit.id === lastViewedUnit) || course.courseUnits[0];
  return currentUnit
}


const mapProps = (state: any, props: any) => {
  const currentSession = state.LMS.sessionsLogs[props.sid]
  const currentUnit = currentSession ? getCurrentUnitFromCourse(props.course, currentSession) : undefined;

  return ({
    currentUnit,
    currentUid: currentUnit ? currentUnit.id : undefined,
    units: props.course.courseUnits
  })
}
/////////////////////////////
const SelectorWithPreloader = withPreloader(LessonsList, ['units', 'currentUid'], [], <Filter />)
export const ConectedSelectorLoader = connect(
  mapProps,
  mapDispatchToProps
)(SelectorWithPreloader);

///////////////////////////////////////////////////////
const ContentSelector: FC<any> = ({ course, currentUnit, currentSession, closeSession, fullscreen }) => {
  let view = currentUnit.unitType
  if (course.view) view = course.view
  return <>
    {(view === 'page') &&
      <>
        <div>unsupported unit type</div>
      </>
    }
    {(view === 'wiki') && <Lesson fullscreen={fullscreen} course={course} unit={currentUnit} sId={currentSession.id} />}
    {(view === 'scorm') && <ScormLoader fullscreen={fullscreen} course={course} unit={currentUnit} sId={currentSession.id} />}
    {(view === 'test') && <Exam fullscreen={fullscreen} course={course} unit={currentUnit} sId={currentSession.id} />}
    {(view === 'task') && <TaskUnit fullscreen={fullscreen} course={course} unit={currentUnit} sId={currentSession.id} />}
    {(view === 'skiped') && <ListSkipedTests fullscreen={fullscreen} cid={course.id} sid={currentSession.id} />}
    {(view === 'result') && <Redirect to={`/LMS/${course.id}?tab=results&sid=${currentSession.id}`} />}
  </>
}

const ContentWithPreloader = withPreloader(ContentSelector, ['course', 'currentUnit', 'currentSession'], [], <Unit />)

export const ConectedSessionLoader = connect(
  (state: any, props: any) => {
    const currentSession = state.LMS.sessionsLogs[props.sid]
    const currentUnit = currentSession ? getCurrentUnitFromCourse(props.course, currentSession) : undefined;
    
    return ({
      currentUnit,
      currentSession
    })
  },
  mapDispatchToProps
)(ContentWithPreloader);


