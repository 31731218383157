import React, { useEffect, FC, ComponentType } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
    loadUserResult
} from "../../redux/actions/LMS";
import {
    getUserSessionsResultsById,
} from '../../redux/sagas/LMS/selectors'

export function withResultLoader<P = any>(Component: ComponentType<P>) {
    const Loader: FC<any> = (props) => {
        const sid = props.sid || props.sId
        const uid = props.uid || props.unit?.id
        const cid = props.cid || props.course?.id
        const dispatch = useDispatch()
        const result = useSelector(getUserSessionsResultsById(sid))

        useEffect(() => {
            dispatch(loadUserResult(cid, sid))
        }, [uid, cid, sid])


        return <Component {...props} result={result} />

    }
    return Loader
}

export default withResultLoader