import React, { FC, useEffect, useRef, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'

import ScormAPI2004 from 'LMSModule/utils/scormAPI/scormAPI2004/scormAPI2004'

const jsonFormatter = (e: any) => {};
const getCompletionStatus = (e: any) => {};
const getSuccessStatus = ()=> {};

const API: any = {};

// (window as any).API_1484_11 = new ScormAPI2004()

export const Presenter: FC<IPresenterProps> = ({
    className,
    name,
    rawState,
    fullscreen
}) => {
    const [nextFn, setNextFn] = useState({ fn: () => {  } })
    const ref = useRef<HTMLIFrameElement>(null)

    const info = rawState.blocks[0].text.split('|')

    useEffect(() => {
        // if (ref.current && ref.current.contentWindow) {
        //     const el: any = ref.current.contentWindow.document.getElementById(info[1])
        //     if (el && el.getCore) {
        //         const core = el.getCore()
        //         setNextFn({ fn: () => core.gotoNextSlide(el) })
        //     }
        // }
    }, [])


    const next = () => {
        if (ref.current && ref.current.contentWindow) {
            ref.current.contentWindow.postMessage('nextSlide', 'http://localhost:8088')

        }
    }

    return <div className={cnClassName({ fullscreen}, [className])}>
        {/* <button onClick={next}>next</button> */}
        <iframe ref={ref} src={info[0]} width={'800px'} height={`${(info[2] || 700)}px`} className={cnClassName('Frame')}></iframe>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
