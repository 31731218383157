import { IIdeaFieldProps } from '../FieldProps';
import React from 'react';
import { Button } from "uielements/src/Button/Button";
import './Tags.scss'

export const Tags: React.FC<IIdeaFieldProps> = (props) => {
  return (
    <div>
      {props.field.tags &&
        props.field.tags.map((val: any) => (
          <Button type="link" href={"tag/" + val.id}>
            {"#" + val.displayName}
          </Button>
        ))}
    </div>
  );
}