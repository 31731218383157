import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
} from 'News/redux/actions'

import {
} from 'News/redux/reducer/types'
import {
    getNewsById
} from 'News/redux/saga/selectors'
import { NewsContextProps } from 'News/contexts/news'
import {

} from 'News/redux/actions'
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace Creativetasks {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace CountSlider {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps, NewsContextProps {
            setValue: (value: number) => void
            value: number
            count: number
            accepted: (() => void) | undefined
            close: () => void
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>
        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = Creativetasks.CountSlider.IOwnProps

export const mapStateToProps = (state: any, { newsId }: IOwnProps) => ({
    fund: (getNewsById(newsId)(state) as any).creativeTask.fund as number
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({

}, dispatch);

////////////

export const cnClassName = cn('CTCountSlider');