import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    unitResult: any;
    sid: string;
    unit: any;
    unitContent: any;
    detailsRender?: (question: any) => React.ReactNode;
    attempt: any;
    cangeAttempt: any;
    isAbsolutelyCompleted?: boolean;
}

export const cnClassName = cn('');