import {
    takeLeading
} from 'redux-saga/effects';
import actions from '../../actionsTypes/News';

import handleContextActionSubscribe from './handleContextActionSubscribe'
import handleContextActionHistory from './handleContextActionHistory'
import handleContextActionRepost from './handleContextActionRepost'
import handleContextActionTranslate from './handleContextActionTranslate'
import handleContextActionCopylink from './handleContextActionCopylink'
import handleContextActionAddToGroupAnnouncement from './handleContextActionAddToGroupAnnouncement'
import handleContextActionApprove from './handleContextActionApprove'
import handleContextActionFavorites from './handleContextActionFavorites'
import handleContextActionAddToAnnouncement from './handleContextActionAddToAnnouncement'
import handleContextActionCancelThanks from './handleContextActionCancelThanks'
import handleContextActionHide from './handleContextActionHide'
import handleContextActionEdit from './handleContextActionEdit'
import handleContextActionDisableComments from './handleContextActionDisableComments'
import handleContextActionSpam from './handleContextActionSpam'
import handleContextActionMove from './handleContextActionMove'
import handleContextActionDelete from './handleContextActionDelete'
import handleContextActionDownloadParticipantList from './handleContextActionDownloadParticipantList'
import handleContextActionRemovePreview from './handleContextActionRemovePreview'
import handleContextActionDownloadThreadAttachments from './handleContextActionDownloadThreadAttachments'
import handleContextActionDownloadComments from './handleContextActionDownloadComments'
import handleContextActionShowFeedbackContact from './handleContextActionShowFeedbackContact'
import handleContextActionGoToModeration from './handleContextActionGoToModeration'
import handleContextActionRejectAchievement from './handleContextActionRejectAchievement'
import handleContextActionSubmitForCorrection from './handleContextActionSubmitForCorrection'
import handleContextActionRedirectSuggestNews from './handleContextActionRedirectSuggestNews'

const root = function* root() {
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionSubscribe)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionHistory)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionRepost)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionTranslate)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionCopylink)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionAddToGroupAnnouncement)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionApprove)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionFavorites)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionAddToAnnouncement)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionCancelThanks)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionHide)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionEdit)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionDisableComments)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionSpam)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionMove)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionDelete)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionDownloadParticipantList)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionRemovePreview)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionDownloadThreadAttachments)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionDownloadComments)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionShowFeedbackContact)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionGoToModeration)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionRejectAchievement)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionSubmitForCorrection)
    yield takeLeading(actions.CONTEXT_ACTION, handleContextActionRedirectSuggestNews)
};

/**
 * export root saga
 */
export default root;