import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IColumn, IWidget } from '../../i.widgets';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { changeIsFirstLogonOnServer } from 'utils/src/CommonRedux/users/actions';
import {
  updateContext,
  removeWidget,
  updateWidget,
  addWidget,
  dragWidget,
  setStructureFromWidget,
  addTabToWidget,
  changeWidgetSettings,
  changeWidgetsViewType,
  setDraggingElem
} from '../../redux/actions/Widgets';
import { IStateType as IState } from 'redux/store';

const name = 'TabsControl';

export interface ITabsControlProps extends IClassNameProps, ITabsControlOwnProps, ImapTabsControlStateToProps, ImapTabsControlActionToProps {

}

export interface ITabsControlOwnProps {
  context: {[s: string]: any};
  tag?: React.ComponentType<any>;
  widget: IWidget<IColumn | Record<string, any>>,
  edit?: boolean;
}

export const mapTabsControlStateToProps = (state: IState) => ({
  // authUserId: state.store.authUid
});

export type ImapTabsControlStateToProps = ReturnType<typeof mapTabsControlStateToProps>;

export const mapTabsControlActionToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  updateContext,
  changeIsFirstLogonOnServer
}, dispatch);

export type ImapTabsControlActionToProps = ReturnType<typeof mapTabsControlActionToProps>;

// export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
//   getOpenQuiz,
//   resetQuiz
// }, dispatch);

export const cnTabsControl = cn(name);