import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { withRouter } from "react-router"
import WidgetPresenter from '../Widget';
import { withBemMod } from '@bem-react/core';
import { Photoalbums } from 'blocks/Photoalbums/Photoalbums'
import { cnWidget } from '../Widget.index';
import { IWidgetTypeAlbumProps } from './Widget_type_album.index';
import './Widget_type_album.scss';
import { RouteComponentProps } from 'react-router';
import { AlbumView } from 'blocks/Photoalbums/AlbumView/AlbumView'
import { PhotoView } from 'blocks/Photoalbums/PhotoView/PhotoView'
import { widgets } from 'i.widgets';
import queryString from 'query-string';
const type = 'album';

class WidgetTypeAlbumsPresenter extends WidgetPresenter<IWidgetTypeAlbumProps & RouteComponentProps<{ fname: string, pid: string, aid: string }>> {
  public render() {
  
    if (!this.props.widget) return null;

    const requestParams = queryString.parse(this.props.location.search)
    if (this.props.match.params.aid) return (
      <widgets.components.common { ...this.props }>
        <AlbumView aid={this.props.match.params.aid} gid={this.props.wcontext.gId} />
         {
          requestParams.fid ? <PhotoView
            gid={this.props.wcontext.gId} 
            aid={requestParams.aid as string}
            id={requestParams.fid as string}
            fname={requestParams.fin as string}
            open={true}
            onClose={() => { this.props.history.push(`/group/${this.props.wcontext.gId}/albums/${this.props.match.params.aid}/`)}} /> : null
        } 
      </widgets.components.common>
    ) 
    if ( this.props.widget?.settings?.photoView) return (
      <widgets.components.common {...this.props}>
        <AlbumView aid={this.props.match.params.aid || this.props.wcontext.gId} gid={this.props.wcontext.gId} photoView={this.props.wcontext.photoView} />
        {
          requestParams.fid ? <PhotoView
            gid={this.props.wcontext.gId}
            aid={requestParams.aid as string}
            id={requestParams.fid as string}
            fname={requestParams.fin as string}
            open={true}
            onClose={() => { this.props.history.push(`?album=view`) }} /> : null
        } 
      </widgets.components.common>
    )
    return (
      <widgets.components.common {...this.props}>
        <Photoalbums gid={this.props.wcontext.gId}/>
      </widgets.components.common>
    );
  }
}

export default withRouter(WidgetTypeAlbumsPresenter)