import React, { FC, memo, useMemo, useState } from 'react';
import { IActionsBoxProps, mapStateToProps, mapDispatchToProps, className } from './ActionsBox.index'
import { connect } from 'react-redux';
import { Translate } from 'localizations/Translate';

import Dropdown from 'uielements/src/MaterialElements/Dropdown/Dropdown';
import { UserList } from 'uielements/src/UserList/UserList';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import { changeStatusModal } from 'blocks/Workflows/Dialogs/ChangeStatus/ChangeStatus';
import { changeResponsibleStatusModal } from 'blocks/Workflows/Dialogs/ChangeResponsibleStatus/ChangeResponsibleStatus';
import './ActionsBox.scss'


const maxVisibleResponsible = 2;

export const Presenter: FC<IActionsBoxProps> = ({
  newsId,
  userData,
  currentStatus = {},
  workflowId,
  statuses,
  responsibleForStatus,
  allowChangeStatus,
  getUserStatuses,
  changeStatus,
  getStatusFields,
  pathNews,
  changeResponsible
}) => {

  const [showStatusTooltipp, setShowStatusTooltip] = useState(false);

  const statusesItems = useMemo(() => statuses?.map((el: any) => ({ title: el.name, value: el.id, disabled: el.isDisabled })), [statuses?.length]);

  const getStatuses = !statuses?.length ? () => getUserStatuses({ newsId, workflowId }) : undefined;

  const onShowChangeFields = (statusId: string) => {
    changeStatusModal({
      newsId,
      statusId,
      data: undefined,
      loading: false,
      status: undefined,
      onChangeFields: (statusFields: any) => pathNews({ id: newsId, statusFields })
    }).then((data) => {
      changeStatus({ newsId, statusId, data })
    }).catch((...args) => console.error('close', args))
      .finally(() => {
        pathNews({ id: newsId, statusFields: undefined })
      });
  }

  const onSelectStatus = (statusId: string) => {
    const status = statuses?.find((status: any) => status.id === statusId);
    if (status?.hasFields) { //  || status?.isAllowChangeResponsibleUser
      onShowChangeFields(statusId); 
      getStatusFields({ newsId, workflowId, statusId });
    } else {
      changeStatus({ newsId, statusId })
    }
  }


  const changeResponsibleStatus = () => {
    changeResponsibleStatusModal({
      newsId,
      data: responsibleForStatus,
      currentStatus
    }).then((data) => {
      changeResponsible({ newsId, users: data as any[] })
    }).catch((...args) => console.error('close', args))
  }

  return <div className={className()}>
    <div className={className('Status')}>
      <div className={className('Status-Title')}>
        <span><Translate i18nKey='pryaniky.post.workflows.status' /></span>
        {
          (currentStatus?.description && responsibleForStatus.some((user: any) => user.id === userData?.baseData?.id)) &&
          <Tooltip title={currentStatus.description} placement="top" arrow>
            <HelpIcon color={'action'} />
          </Tooltip>
        }
      </div>

      <Tooltip open={showStatusTooltipp} title={currentStatus?.name?.length > 20 ? currentStatus?.name : ''} >
        <Dropdown
          className={className('Status-Select', ['Button_main'])}
          variant='outlined'
          items={statusesItems?.length ? statusesItems : [{ title: currentStatus?.name, value: currentStatus?.id }]}
          value={currentStatus?.id}
          onChange={(statusId: string) => onSelectStatus(statusId)}
          onFocus={getStatuses}
          onMouseover={() => setShowStatusTooltip(true)}
          onOpen={() => setShowStatusTooltip(false)}
          disabled={!allowChangeStatus}
        />
      </Tooltip>
      
    </div>

    <div className={className('Responsible')}>

      <span onClick={currentStatus?.isAllowChangeResponsibleUser ? changeResponsibleStatus : undefined}>
        <Translate i18nKey='pryaniky.post.workflows.responsible' /> {currentStatus?.isAllowChangeResponsibleUser && <AddCircleOutlineIcon />}
      </span>

      {
        responsibleForStatus?.length ? <UserList
          cut={false}
          action={responsibleForStatus?.length > maxVisibleResponsible}
          data={responsibleForStatus || []}
          actionCallback={() => { }} // users modal
          actionBtnContent={`+ ${responsibleForStatus?.length - maxVisibleResponsible}`}
          maxVisible={maxVisibleResponsible}
        /> :
          <div className={className('Responsible-Empty')}>
            <Translate i18nKey='pryaniky.post.workflows.responsible.empty' />
          </div>
      }

    </div>
  </div>

}

export const ActionsBox = connect(
  mapStateToProps, mapDispatchToProps
)(memo(Presenter))
