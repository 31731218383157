import React, { useEffect } from 'react'
import { Menu as MenuMui } from 'muicomponents/src/Menu';
import { IconButton, IconMui, CircleIndicator } from '../Header.styled';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { IMenuProps } from './Menu.index';
import { PaperProps, MenuListProps } from '@mui/material';
import { cn } from '@bem-react/classname';

export const cnHeaderMenu = cn('HeaderMenu');

const defaultPaperProps: Partial<PaperProps> = {
  sx: {
    width: 450,
    maxHeight: 420,
    top: '66px !important'
  }
};

const defaultMenuListProps: Partial<MenuListProps> = {
  sx: {
    paddingBottom: '0 !important',
    width: '100% !important'
  }
}

export const Menu:React.FC<IMenuProps> = (props) => {
  const {children, notificationCount, menuIcon, tooltipTitle, getNotificationCount, resetNotificationCount } = props;
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (getNotificationCount) getNotificationCount();
  }, []);

  useEffect(() => {
    if (open && resetNotificationCount) resetNotificationCount();
  }, [open]);


  return (
    <>
      <IconButton className={cnHeaderMenu("Icon")} onClick={handleClick}>
        <Tooltip title={tooltipTitle}>
          <IconMui as={menuIcon as any} />
        </Tooltip>
        { notificationCount !== 0 && 
          <CircleIndicator sx={{backgroundColor: '#e31b0c'}}>
            {notificationCount}
          </CircleIndicator> }
      </IconButton>

      <MenuMui
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        disableScrollLock
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={defaultPaperProps}
        MenuListProps={defaultMenuListProps}
      >
        {children}
      </MenuMui>

    </>
  )
}