import { Characteristics as CharacteristicsTypes } from 'utils/src/requests/models/api.shop'
import { createMdLinks } from 'utils/src/utils'
/**
 * убирает невыбраные теги, оставив только выбраные.
 * если ни один тег не выбран, то массив tags будет пуст.
 * применяется для отправки заказа.
 * @param characteristics 
 * @return {CharacteristicsTypes.IItem[]} имеющий только выбраные теги
 */
export const pickSelected = (characteristics: CharacteristicsTypes.IItem[]) => {
    return characteristics.map(value => ({ ...value, tags: value.tags.filter(tag => tag.isSelected) }))
}


/**
 * подсчёт недостающей валюты
 * @param thanksPrice цена
 * @param myThanksCount доступные пользователю валюта
 */
export const countMissingThanks = (thanksPrice: number, myThanksCount: number) => {
    return thanksPrice - myThanksCount;
}


/**
 * разделяет строку с ценой
 * @param thanksPriceTxt 
 */
export const getPriceData = (thanksPriceTxt: string) => {
    const [price, priceCurrency] = thanksPriceTxt.split(' ');
    return {
        price,
        priceCurrency
    }
}


/**
 * проверяет выбран ли таг с указаным id
 * @param characteristics список характеристик
 * @param id ид тега, который нужно проверить
 * @returns bool true выбран, иначе false
 */
export const isSelectedTag = (characteristics: CharacteristicsTypes.IItem[], id: string) => {
    return characteristics.some(char => char.tags.some(tag => tag.id === id && tag.isSelected))
}


/**
 * устанавливает isSelected тагам из списка характеристик origin в соответствие со списком selected.
 * Полезно при получение с сервера обновлённого списка характеристик
 * @param origin исходный масси
 * @param selected массив с выбраными тагами
 * @returns новый массив, аналогичный origin с добавлением isSelected к тегам
 */
export const setSelectedTags = (origin: CharacteristicsTypes.IItem[], selected: CharacteristicsTypes.IItem[]) => {
    return origin.map(item => {
        const tags = item.tags.map(tag => ({ ...tag, isSelected: isSelectedTag(selected, tag.id) }))
        return { ...item, tags }
    })
}


/**
 * снимает выделение со всех характеристик
 * @param characteristics 
 * @returns 
 */
export const setUnselectedAll = (characteristics: CharacteristicsTypes.IItem[]) => {
    return characteristics.map(item => ({ ...item, tags: item.tags.map(tag => ({ ...tag, isSelected: false })) }))
}



/**
 * преобразует простые ссылки в маркдаун.
 * опасно, скорее всего взорвется если будет ссылка в markdown
 * @param text 
 */
export const changeLinks = createMdLinks