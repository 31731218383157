import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { loadCourses, sendLikeCourse } from "../../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
export interface ICoursesProps extends IClassNameProps, ICoursesStateProps, IDispatchProps, RouteComponentProps, ICoursesDispatchProps {
    tag?: 'div';
    params?: any
}

export interface ICoursesState {
    courses: any[];
    isFinished: boolean;
}

export interface ICoursesStateProps {
    store: any
    courses: any
    cIds: string[]
    // isFinished: boolean
    isLoading: boolean
    isFinished: boolean
    isEnabled?: boolean
}

export const mapStateToProps = (state: any) => ({
    store: state.store,
    courses: state.LMS.courses.entities.values,
    cIds: state.LMS.courses.result,
    isLoading: state.LMS.courses.isLoading,
    isFinished: state.LMS.courses.isFinished,
    isEnabled: state.store.appSettings.enabledModules.lms
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadCourses, sendLikeCourse
}, dispatch);

export type ICoursesDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnCourses = cn('CoursesMy');