import { Avatar } from 'muicomponents/src/Avatar';
import React, { useEffect, useState } from 'react';
import { IHeaderBadgesProps } from './HeaderBadges.index';
import { HeaderTop, HeaderPaper, HeaderFilters, HeaderActions, HeaderFiltersConteiner } from './HeaderBadges.styled';
import { Translate } from 'localization';
import avatarIcon from './avatar.svg';
import { Box, Typography } from 'muicomponents/src';
import { IconButton } from 'muicomponents/src/IconButton';
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput';
import { useDebouncedQueryState } from 'utils/src/hooks';
import { FilterAlt, GridView, List } from 'muicomponents/src/Icons';
import { PropoverFilters } from '../PopoverFilters/PopoverFilters';

export const HeaderBadges: React.FC<IHeaderBadgesProps> = ({ viewType, setViewType, setCheckedCategories }) => {
    const [search, searchQuery, setSearch] = useDebouncedQueryState('search');
    const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);
    const [LabelWidth, setLabelWidth] = useState<number>(100);

    useEffect(() => {
        const onChangeResize = (e: Event) => {
            if (window.innerWidth > 720 && window.innerWidth <= 750) {
                setLabelWidth(Math.max((window.innerWidth / 750) * 100 - 15, 0));
            } else {
                setLabelWidth(100);
            }
            setInnerWidth(window.innerWidth);
        };
        window.addEventListener('resize', onChangeResize);
        return () => {
            window.removeEventListener('resize', onChangeResize);
        };
    }, []);

    const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null);

    const filterItems = (
        <>
            <HeaderActions>
                <IconButton onClick={(event) => setPopoverAnchor(event.currentTarget)} color={'primary'}>
                    <FilterAlt style={{ fontSize: '32px' }} />
                </IconButton>

                <IconButton onClick={() => setViewType('grid')} color={viewType === 'grid' ? 'primary' : 'default'}>
                    <GridView style={{ fontSize: '32px' }} />
                </IconButton>

                <IconButton onClick={() => setViewType('row')} color={viewType === 'row' ? 'primary' : 'default'}>
                    <List style={{ fontSize: '32px' }} />
                </IconButton>
            </HeaderActions>

            <PropoverFilters
                popoverAnchor={popoverAnchor}
                setPopoverAnchor={setPopoverAnchor}
                setCheckedCategories={setCheckedCategories}
            />
        </>
    );
    const searchInput = (
        <SearchInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            label={
                <Box sx={{ width: '100%' }}>
                    <Typography
                        sx={{
                            color: '#000000DE',
                            overflow: 'hidden',
                            width: `${LabelWidth}%`,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {Translate.t({ i18nKey: 'pryaniky.list.badges.search' })}
                    </Typography>
                </Box>
            }
        />
    );
    return (
        <HeaderPaper>
            <HeaderTop>
                <Avatar src={avatarIcon} sx={{ width: 64, height: 64 }} />
                <Typography sx={{ fontWeight: 600, fontSize: 20 }}>
                    <Translate i18nKey={'badges'} />
                </Typography>
            </HeaderTop>
            {innerWidth <= 440 ? (
                <HeaderFiltersConteiner>
                    {searchInput}
                    <HeaderFilters>{filterItems}</HeaderFilters>
                </HeaderFiltersConteiner>
            ) : (
                <HeaderFilters>
                    {searchInput}
                    {filterItems}
                </HeaderFilters>
            )}
        </HeaderPaper>
    );
};
