/**
 * @packageDocumentation
 * @module Widget_Settings_ratingmini
 */
import data from './data';
import uischema from './uischema';
import schema, { prepareData } from './schema';

export default {
  data,
  schema,
  uischema,
  prepareData
}