import React, { createContext, ComponentType, FC, memo, useContext } from 'react';
import { IBaseNews, createDefaultNews } from '../types/baseNews'
import { keys, pick } from 'lodash'
export interface NewsContextProps<N = IBaseNews> {
    isSingle: boolean;
    isRepost: boolean;
    toggleEdit: (enable: boolean) => void
    edit: boolean;
    ViewComponent: ComponentType<{}> | undefined
    virtualize: boolean
    newsId: string
    viewClassName: string | undefined;
    additional?: { [s: string]: any };
    disableEdit?: boolean
}


const defaultContext: NewsContextProps = {
    isSingle: false,
    isRepost: false,
    toggleEdit: () => { },
    edit: false,
    virtualize: false,
    ViewComponent: undefined,
    newsId: '',
    viewClassName: undefined,
    additional: undefined,
}

export const NewsContext = createContext<NewsContextProps>(defaultContext)

export function useNewsContext(deps: (keyof NewsContextProps)[] = keys(defaultContext) as any) {
    return pick(useContext(NewsContext), deps);
}

export function withNewsContext<P extends NewsContextProps>(Component: ComponentType<P>, deps: (keyof NewsContextProps)[] = keys(defaultContext) as any) {
    const Wrapped: FC<Omit<P, keyof NewsContextProps>> = (props) => { 
        const value = useNewsContext(deps)//pick(useContext(NewsContext), deps);
        return <Component {...{ ...props as any, ...value }} />
    }
    return memo(Wrapped)
}