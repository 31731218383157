import i18n from 'localizations/i18n';
import { TDialogProps } from 'utils/src/DialogCreator'
import { IBaseUser } from 'uielements/src/UsersList/UsersList.index'
import { getEventPartipants } from 'utils/src/requests/requests.news'
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { dialog } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem'

const createEventParticipantsDialog = () => {
  return ({
    newsId,
    status,
  }: {
    newsId: string;
    status: string;
  }, containerProps?: TDialogProps) =>
    dialog({
      method: async function (skipCount, { count, search }) {
        const response = await getEventPartipants(newsId, status, {
          search,
          skipCount,
          count
        })
        const result = await response.r as { data: IBaseUser[] } & IBasicResponse
        if (result.error_code !== 0) throw result
        return result.data.map((item) => ({ ...item, url: `/user/${item.id}` }))
      },

      ItemComponent: UsersListItem,
      listBodyProps: {
        header: i18n.t(`pryaniky.modal.event.title.${status}`)
      }
    }, containerProps)
}

export default createEventParticipantsDialog()