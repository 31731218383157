import * as React from 'react';
import { IEditorTypeEditProps } from './edit.index';
import { Editor } from '../../Editor'
import { ThanksValidator, thanksEditValidate } from '../../../Thanks.validate'
import { ThanksNews } from '../../../Thanks.type'
import { baseValidate, Rule } from '../../../../Base.validate'
import { nameCount } from 'utils/src/utils'

export const EditorTypeEdit: React.FC<IEditorTypeEditProps> = ({ data, onChange, typeParams }) => {
    let localData = { ...data } as ThanksNews;;

    const onChangeText = (text: string) => {
        localData.text = text;
        const validThank: Rule[] = [
            {
                type: 'string',
                method: 'STR_MIN',
                field: 'text',
                value: typeParams?.thanksRenderParams?.minTextLength || 5,
                errorI18nKey: 'pryaniky.validate.news.thanks.text.min',
                errorVariablesI18nKey: {
                    count: 5,
                    variable: nameCount(5, 'pryaniky.validate.string.variable')
                }
            }
        ]
        const vb = new ThanksValidator();
        vb.addData(localData)
        vb.setRules([...thanksEditValidate
            .filter(v => !(v.field === 'text' && v.method === 'STR_MIN')),
        ...validThank])

        onChange(localData, vb.validation().length === 0)
    }

    return <Editor text={localData.text} onChangeText={onChangeText} />

}