import { cn } from '@bem-react/classname';
import { IDialog } from '../../DialogWrapper.index';

export interface ISkillParticipantsProps extends ISkillParticipantsOwnProps { }

export interface ISkillParticipantsOwnProps extends IDialog {
  tId: string;
  userid: string
}

export const cnSkillParticipants = cn('SkillParticipants');