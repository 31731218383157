import React, { FC } from 'react'
import { Shop } from './Value.interfaces'
import { withComponentEnjector } from 'utils/src/ComponentInjector';
import { useRefedCallback } from 'utils/src/hooks';
import { Box } from 'muicomponents/src';
type IPresenterProps = Shop.CharacteristicsValue.IPresenterProps

const Noop: FC<any> = () => <div>not rendered</div>

export const Presenter: FC<IPresenterProps> = ({
    value,
    type,
    getComponent,
    onClick,
    readonly
}) => {
    
    const Component = getComponent(type) || Noop

    const onSelect = useRefedCallback((ref) => () => {
        const { readonly, value } = ref.current
        if (readonly) return;
        if (value.isDisabled) return
        onClick({ ...value, isSelected: !value.isSelected })
    }, { readonly, value })

    return <Box sx={{ mr: 1, }}  
        onClick={onSelect} >
        <Component value={value} />
    </Box >
}

const rez = withComponentEnjector(Presenter, ['shopMui_characteristics_types']) as any;

export default rez
