import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
  UPDATE_UNREAD_COUNT: '',
  UPDATE_LIST: '',
  UPDATE_LIST_AND_COUNT: '',
  CHANGE_STATUS: '',
  CHANGE_URL: '',
  MUTE_UNREAD: '',
  UPDATE_USERS: '',
  CHANGE_UID: '',
  UPDATE_CONFIG: '',
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.CHAT)}), {} as typeof actions);