// import { IActionsProps } from './Action.index'
// import React, { memo } from 'react'
// import './Action.scss';

// export const Actions: React.FC<IActionsProps> = memo(({ children, className }) => {
//     return (
//         <div className={'Actions ' + className}>
//             {children}
//         </div>);
// })


export { Actions } from 'uielements/src/NewsParts/Actions/Actions'