import { createContext, useCallback, useContext, useRef } from 'react';
import { TWidget } from 'utils/src/BaseTypes/widgets.types';
import { sendSignal as sendSignalGlobal } from 'utils/src/signals';
import { WidgetsSignalsTypes } from './LayoutHorizontal/Position/Position.hooks';

export type WidgetContext = {
    widgetId: string | null;
    context: {[key: string]: any};
};

const initialWidgetContextState: WidgetContext = {
    widgetId: null,
    context: {}
}

export const WidgetContext = createContext(initialWidgetContextState);

export const sendWidgetSignal = (widgetId: TWidget['id'], type: WidgetsSignalsTypes) => {
    if(widgetId) sendSignalGlobal(`${widgetId}/${type}`, [`${widgetId}/${type}`]);
};

export const useWidgetContext = () => {
    const value = useContext(WidgetContext);

    const sendWidgetSignal = useCallback((type: WidgetsSignalsTypes) => {
        if(value.widgetId) sendSignalGlobal(`${value.widgetId}/${type}`, [`${value.widgetId}/${type}`]);
    }, [value.widgetId]);

    return {
        value,
        sendWidgetSignal
    };
};