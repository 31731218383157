import actions from 'redux/actionsTypes/Titles'

const defaultTitle = {
  groupTitle: '',
  userTitle: '',
  newsTitle: '',
}

export function titlesReducer(state = defaultTitle, action: any) {
  switch (action.type) {
    case actions.GET_GROUP_TITLE:
      return {
        ...state,
        groupTitle: action.payload,
      };
    case actions.GET_USER_TITLE:
      return {
        ...state,
        userTitle: action.payload,
      };
    case actions.GET_NEWS_TITLE:
      return {
        ...state,
        newsTitle: action.payload,
      };
    default: return state
  }
}

