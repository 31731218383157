import React, { FC, useState } from 'react'
import * as utils from 'utils/src/utils';
import { toast } from 'react-toastify';
import { BadgeWidgetMui } from './BadgeWidgetNew/BadgeWidgetMui';
import { IBadgeItem } from './BadgeWidgetNew/BadgesList/BadgesTooltipList.index';
import { IBadgeWidgetProps } from './BadgeWidgetNew/BadgeWidgetMui.index';
import { PaperContainer } from './styled';


export const BadgeWidgetMUIContainer: FC<IBadgeWidgetProps> = (props) => {

    const Tag = props.tag || 'div'
    const uid = props.context.uId
    const isAdmin = props.authUser.baseData.isAdmin
    const [badges, setBadges] = useState<{ [s: string]: any }>({})
    const [sortedBadges, setSortedBadges] = useState<IBadgeItem[]>([])
    const [loading, setLoading] = useState(false)
    const [catsList, setCatsList] = useState<string[]>([])
    const [cats, setCats] = useState<{ [s: string]: any }>({})
    const [badgesList, setBadgesList] = useState<any[]>([])
    const [sortedBadgesList, setSortedBadgesList] = useState<any[]>([])

    const loadBadges = () => {
        setLoading(true)
        utils.API.badges.byUser({ uid }).r.then((d: any) => {
            if (!d.data || d.error_code !== 0) {
                setLoading(false)
                return;
            }
            const catsList = Object.keys(d.data)
            const cats: any = {};
            const badgesList: any[] = [];
            const badges: any = {};

            catsList.forEach((val: any) => cats[val] = d.data[val].map((v: any) => {
                badges[v.badgeUid] = v;
                badgesList.push(v.badgeUid)
                return v.badgeUid;
            }));
            const sortedBadgesList = badgesList.sort((a: string, b: string) => {
                if ((new Date(badges[a].recieved)).getTime() < (new Date(badges[b].recieved)).getTime()) {
                    return 1;
                }
                if ((new Date(badges[a].recieved)).getTime() > (new Date(badges[b].recieved)).getTime()) {
                    return -1;
                }
                return 0;
            })
            const sortedBadges = sortedBadgesList.map(badgeId => {
                return badges[badgeId]
            })
            setSortedBadges(sortedBadges)
            setCatsList(catsList)
            setCats(cats)
            setBadges(badges)
            setBadgesList(badgesList)
            setSortedBadgesList(sortedBadgesList)
            setLoading(false)

        })
    }

    const removeBadge = (uid: string, bid: string) => {
        const currentBadge = badges[bid]
        if (currentBadge) {
            const dicrimentedBadge = { ...currentBadge, count: currentBadge.count - 1 }
            if (dicrimentedBadge.count <= 0) {
                const withOutBid = sortedBadgesList.filter((id: string) => id !== bid)
                const badgesListwithOutBid = badgesList.filter((id: string) => id !== bid)
                const catsWithOutBid = { ...cats }
                catsList.forEach((cat: string) => {
                    catsWithOutBid[cat] = cats[cat].filter((id: string) => id !== bid)
                })
                setSortedBadgesList(withOutBid)
                setBadgesList(badgesListwithOutBid)
                setCats(catsWithOutBid)
                const sortedWithoutBid = sortedBadges.filter((badge: any) => badge.badgeUid !== bid)
                setSortedBadges(sortedWithoutBid)
            } else {
                const dicrementedBadgeIndex = sortedBadges.findIndex((badge: any) => badge.badgeUid === bid)
                if (dicrementedBadgeIndex || dicrementedBadgeIndex === 0) {
                    const newArr = [...sortedBadges]
                    newArr[dicrementedBadgeIndex] = dicrimentedBadge
                    setSortedBadges(newArr)
                }
            }

            setBadges({ ...badges, [bid]: dicrimentedBadge })
            utils.API.badges.removeBadgeFromUser({ uid, bid }).r.then((d: any) => {
                if (d && d.error_code !== 0) {
                    console.warn('badge delete fail:', d)
                    toast.error('Ошибка при удалении бейджа, возможно изменения не были сохранены')
                }
            })
        }
    }


    return <Tag {...props} >
        <PaperContainer>
            <BadgeWidgetMui
                {...props}
                isLoading={loading}
                badges={sortedBadges}
                uid={uid}
                removeBadge={isAdmin && removeBadge}
                loadBadges={loadBadges} />
        </PaperContainer>
    </Tag>
}