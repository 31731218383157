import React, { FC, useState, ReactNode } from 'react'
import { Button, CheckboxInput, Icon, SplitButton } from 'uielements/src'
import { cn } from '@bem-react/classname';
import './ItemLayout.scss'
import ContextMenuComponent from 'uielements/src/ContextMenu/ContextMenu'

export const cnItemLayout = cn('ItemLayout');

export interface IPresenterProps {
    className?: string;
    showChekbox?: boolean
    icon: string
    selected: boolean
    onSelect: () => void
    nameNode: ReactNode
    dataNode: ReactNode
    dateNode: ReactNode
    actions: any[]
    showActions: boolean
    primaryAction: ReactNode
    clickItem?: () => void
}

const Presenter: FC<IPresenterProps> = ({
    showChekbox = false,
    icon = '',
    selected,
    onSelect,
    nameNode,
    dataNode,
    dateNode,
    showActions,
    actions,
    primaryAction,
    clickItem,
    className
}) => {
    const [menuOpen, setMenuOpen] = useState(false)
    return <div className={cnItemLayout({ open: menuOpen, pointer: !!clickItem }, [className])} onClick={() => clickItem && clickItem()}>
        <div className={cnItemLayout('Select')} onClick={(e) => { e.stopPropagation(); }}>
            {(showChekbox) && <CheckboxInput className={cnItemLayout('Checkbox')} checked={selected} onChange={onSelect} />}
        </div>
        <div className={cnItemLayout('Icon')}>
            <img className={cnItemLayout('Img')} src={icon} />
        </div>
        <div className={cnItemLayout('Name')}>
            {nameNode}
        </div>
        <div className={cnItemLayout('Data')}>
            {dataNode}
        </div>
        <div className={cnItemLayout('Date')}>
            {dateNode}
        </div>
        <div className={cnItemLayout('Action')} onClick={(e) => { e.stopPropagation(); }}>
            {showActions &&
                <ContextMenuComponent
                    actions={actions}
                />
                // <SplitButton isOpen={setMenuOpen} className={cnItemLayout('ActionMenu')}
                //     button={primaryAction}
                //     options={actions} />
            }
        </div>
    </div>
}
export default Presenter