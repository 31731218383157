import {
    takeEvery,
    takeLeading,
    takeLatest,
    throttle,
    cancel,
    fork
} from 'redux-saga/effects';
import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';


import { routerActions, getLocation } from 'connected-react-router';

import {
    API,
    objectArrayToMap,
    arrayMove
} from 'utils/src/utils'

import {
    ILearningTrack,
    ILearningFile
} from 'utils/src/requests/models/api.learningTracks'

import * as SELNews from 'News/redux/saga/selectors'
import * as ACTNews from 'News/redux/actions'

import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

import actions from '../../actionsTypes/TracksManage';

import newsAction from 'News/redux/actionsTypes/News'

import {
    loadTracksListActionType,
    createTrackActionType,
    setTracksList,
    addFileActionType,
    loadFilesActionType,
    setFilesList,
    changeField,
    setUsersList,
    loadUsersActionType,
    addUsersToTrackActionType,
    moveFileActionType,
    addFile,
    deleteTrack,
    removeTrackActionType,
    prependFilesList,
    loadTrackByIdActionType,
    changeFieldActionType,
    updateTrackActionType,
    chnageTrack,
    removeFilesActionType,
    deleteFiles,
    removeUsersActionType,
    deleteUsers,
    loadUsersStatActionType,
    loadMaterialStatActionType,
    setUsersStat,
    setMaterialStat,
    setFilesPositionsActionType
} from '../../actions/TracksManage'

import {
    getFilesIds,
    getTrackById
} from './selectors'

import {
    AContextAction
} from 'News/redux/actions/types';

import uuid from 'uuid';

import {
    IBaseNews
} from 'News/types/baseNews'

import addNewsModal, { IAddNewsToTrackResult } from '../../../dialogs/AddNewsToTrack'


import queryString from 'query-string';
import { withSagaIndicator, withProgressIndicator } from 'utils/src/CommonRedux/LoadIndicator'


const zeroId = "00000000-0000-0000-0000-000000000000";

/**
 * 
 * @param action 
 */
export const handleLoadTracksList = function* handleLoadTracksList(action: loadTracksListActionType): any {
    try {
        const { clean } = action.payload
        yield* put(changeField({
            field: 'isLoading',
            value: true,
            root: 'tracks'
        }))

        const request = yield* call(API.learningTracks.getManageList, action.payload)
        const result = yield request.r

        if (result.error_code === 0) {
            const data = objectArrayToMap<ILearningTrack, ILearningTrack>(result.data, 'tagId')
            yield* put(setTracksList({ data, keys: Object.keys(data) }, false, clean))
        }
    } catch (error) {
        console.warn(error)
    }

    yield* put(changeField({
        field: 'isLoading',
        value: false,
        root: 'tracks'
    }))
    yield;
    return 0;
}


/**
 * 
 * @param action 
 */
export const handleLoadTrackById = withProgressIndicator(function* handleLoadTrackById(action: loadTrackByIdActionType): any {
    try {
        const { id } = action.payload

        const request = yield* call(API.learningTracks.getTrackById, id)
        const result = yield request.r

        if (result.error_code === 0) {

            const data = objectArrayToMap<ILearningTrack, ILearningTrack>([result.data], 'id')

            yield* put(setTracksList({ data, keys: Object.keys(data) }))
        }
    } catch (error) {
        console.warn(error)
    }

    yield;
    return 0;
})


export const handleRemoveFiles = withProgressIndicator(function* (action: removeFilesActionType): any {

    const request = yield* call(API.learningTracks.deleteFiles, action.payload)
    const result = yield request.r
    if (result.error_code === 0) {
        yield* put(deleteFiles(action.payload))
    } else {
        throw new Error('deleteFiles_error')
    }

    yield;
    return 0;
})


export const handleRemoveUsers = withProgressIndicator(function* (action: removeUsersActionType): any {
    const { tagName, users } = action.payload
    const request = yield* call(API.learningTracks.deleteUsers, tagName, users)
    const result = yield request.r
    if (result.error_code === 0) {
        yield* put(deleteUsers(users))
    } else {
        throw new Error('deleteUsers_error')
    }

    yield;
    return 0;
})


/**
 * 
 * @param action 
 */
export const handleCreateTrack = withProgressIndicator(function* handleCreateTrack(action: createTrackActionType): any {
    try {
        const { } = action.payload

        const request = yield* call(API.learningTracks.setCreateTrack, action.payload)
        const result = yield request.r

        if (result.error_code === 0) {
            const data = objectArrayToMap<ILearningTrack, ILearningTrack>(result.data, 'tagId')
            yield* put(setTracksList({ data, keys: Object.keys(data) }, true) )
            return result.data[0].tagId
        } else throw result
    } catch (error) {
        console.warn(error)
        throw error
    }

    yield;
    return 0;
})


/**
 * 
 * @param action 
 */
export const handleAddFile = withProgressIndicator(function* handleAddFile(action: addFileActionType): any {
    const { tagId, uid, type } = action.payload

    const request = yield* call(API.learningTracks.addFileToTrack, tagId, uid, type)
    const result = yield request.r


    if (result.error_code === 0) {
        const values = objectArrayToMap<ILearningFile, ILearningFile>([result.data], 'id')
        const keys = Object.keys(values)
        yield* put(prependFilesList({ keys, values, trackId: tagId }))
    }

    yield;
    return 0;
})



/**
 * 
 * @param action 
 */
export const handleLoadFiles = withProgressIndicator(function* handleLoadFiles(action: loadFilesActionType): any {
    // try {
    const { tags = [] } = action.payload
    // yield* put(changeField({
    //     field: 'isLoading',
    //     value: true,
    //     root: 'files'
    // }))
    const request = yield* call(API.learningTracks.getTrackFiles, action.payload)
    const result = yield request.r

    if (result.error_code === 0) {

        const values = objectArrayToMap<ILearningFile, ILearningFile>(result.data, 'id')
        const keys = Object.keys(values)
        yield* put(setFilesList({ keys, values, trackId: tags.join('_') }))
    } else {
        throw new Error('getTrackFiles_error')
    }
    // } catch (error) {
    // console.warn(error)
    // }
    // yield* put(changeField({
    //     field: 'isLoading',
    //     value: false,
    //     root: 'files'
    // }))
    yield;
    return 0;
})


/**
 * 
 * @param action 
 */
export const handleLoadUsers = function* handleLoadUsers(action: loadUsersActionType): any {
    try {
        const { tagId } = action.payload
        yield* put(changeField({
            field: 'isLoading',
            value: true,
            root: 'users'
        }))
        const request = yield* call(action.groups ? API.learningTracks.getTrackGroups : API.learningTracks.getTrackUsers, action.payload)
        const result = yield request.r

        if (result.error_code === 0) {
            yield* put(setUsersList({ users: result.data, trackId: tagId }))
        }
    } catch (error) {
        console.warn(error)
    }
    yield* put(changeField({
        field: 'isLoading',
        value: false,
        root: 'users'
    }))
    yield;
    return 0;
}



export const handleAddUsers = function* handleAddUsers(action: addUsersToTrackActionType): any {
    try {
        const { trackId, userIds, users } = action.payload

        const request = yield* call(API.learningTracks.setSubscribeUserToTrack, trackId, users)
        const result = yield request.r
        if (result.error_code === 0) {
            yield* put(setUsersList({ users, trackId }))
        }

    } catch (error) {
        console.warn(error)
    }
    yield;
    return 0;
}


export const handleMoveFile = function* handleMoveFile({ payload }: moveFileActionType): any {
    try {
        const { newIndex, oldIndex, trackId } = payload
        const ids = yield* select(getFilesIds)

        const moved: string[] = arrayMove(ids, oldIndex, newIndex)

        yield* put(changeField({
            field: 'keys',
            value: moved,
            root: 'files'
        }))
        yield* put(changeField({
            field: 'positionIsChanged',
            value: true,
            root: 'files'
        }))

        // const request = yield* call(API.learningTracks.setPositions, trackId, moved)
        // const result = yield request.r

    } catch (error) {
        console.warn(error)
    }
    yield;
    return 0;
}

export const handleSetFilesPositions = withProgressIndicator(function* ({ payload }: setFilesPositionsActionType) {
    const { trackId, positions } = payload
    const request: any = yield* call(API.learningTracks.setPositions, trackId, positions)
    // @ts-ignore
        const result: any = yield request.r;
        if (result.error_code === 0) {
            yield* put(changeField({
                field: 'positionIsChanged',
                value: false,
                root: 'files'
            }))
        } else throw new Error('error setPositions')
})


export const handleContextActionAddNews = function* handleContextActionAddNews({ payload }: AContextAction) {
    const { action, id } = payload
    if (action.includes('lms.addToLearningTrack')) {
        try {
            const news: IBaseNews = yield* select(SELNews.getNewsById(id))

            const result: IAddNewsToTrackResult = yield* call(addNewsModal, {
                newsId: id,
                tags: news.tags || [],
                header: news.header,
                text: news.text
            })

            yield* put(addFile({ tagId: result.tagName, uid: result.newsUid, type: 'News' }))
        } catch (error) {
        }
    }
    yield;

    return 0;
}


export const handleRemoveTrack = function* handleRemoveTrack(action: removeTrackActionType): any {
    try {
        const { id } = action.payload

        const request = yield* call(API.learningTracks.removeTrack, id)
        const result = yield request.r
        if (result.error_code === 0) {
            yield* put(deleteTrack({ id }))
        }

    } catch (error) {
        console.warn(error)
    }
    yield;
    return 0;
}


export const handleUpdateTrack = function* handleUpdateTrack(action: updateTrackActionType): any {
    try {
        yield* put(chnageTrack(action.payload))

        const request = yield* call(API.learningTracks.setCreateTrack, {
            ...action.payload,
            tagName: action.payload.displayName,
            tagId: action.payload.id
        }, true)
        const result = yield request.r

        if (result.error_code === 0) {
            console.log('handleUpdateTrack', result)
        }
    } catch (error) {
        console.warn(error)
    }
    yield;
    return 0;
}



export const handleloadUsersStat = withProgressIndicator(function* handleloadUsersStat(action: loadUsersStatActionType): any {
    const { count, skipCount, tagId, search, clean = false } = action.payload
    const request = yield* call(API.learningTracks.getUsersStatistic, tagId, count, clean ? 0 : skipCount, search)
    const result = yield request.r

    if (result.error_code === 0) {
        console.log('handleUpdateTrack', result)
        yield* put(setUsersStat({ tagId: action.payload.tagId, users: result.data.users, clean }))
    } else throw new Error('user statistic error')

})


export const handleLoadMaterialStat = withProgressIndicator(function* handleLoadMaterialStat(action: loadMaterialStatActionType): any {
    const { count, skipCount, tagId, search, clean = false } = action.payload

    const request = yield* call(API.learningTracks.getMaterialStatistic, tagId, count, clean ? 0 : skipCount, search)
    const result = yield request.r

    if (result.error_code === 0) {
        console.log('handleUpdateTrack', result)
        yield* put(setMaterialStat({ tagId, materials: result.data, clean }))
    }

})


/**
 * module root saga
 */
const root = function* root() {
    yield takeLeading(actions.LOAD_TRACKS, handleLoadTracksList)
    yield takeLeading(actions.CREATE_TRACK, handleCreateTrack)
    yield takeLeading(actions.ADD_FILE, handleAddFile)
    yield takeLeading(actions.LOAD_FILES, handleLoadFiles)
    yield takeLeading(actions.LOAD_USERS, handleLoadUsers)
    yield takeLeading(actions.ADD_USERS_TO_TRACK, handleAddUsers)
    yield takeLeading(actions.MOVE_FILE, handleMoveFile)
    yield takeLeading(actions.REMOVE_TRACK, handleRemoveTrack)
    yield takeLeading(actions.LOAD_TRACK_BY_ID, handleLoadTrackById)
    yield takeLeading(actions.UPDATE_TRACK, handleUpdateTrack)
    yield takeLeading(actions.REMOVE_FILE, handleRemoveFiles)
    yield takeLeading(actions.REMOVE_USERS, handleRemoveUsers)
    yield takeLeading(actions.LOAD_USERS_STAT, handleloadUsersStat)
    yield takeLeading(actions.LOAD_MATERIAL_STAT, handleLoadMaterialStat)
    yield takeLeading(actions.SET_FILES_POSITIONS, handleSetFilesPositions)


    yield takeLeading(newsAction.CONTEXT_ACTION, handleContextActionAddNews)
};

/**
 * export root saga
 */
export default root;