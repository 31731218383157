import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { withRouter } from "react-router"
import WidgetPresenter from '../Widget';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeCardProps } from './Widget_type_card.index';
import './Widget_type_card.scss';
import { RouteComponentProps } from 'react-router';
import { widgets } from 'i.widgets';
import queryString from 'query-string';
import { Card } from 'blocks/Card/Card';

const type = 'Card';

class WidgetTypeCardPresenter extends WidgetPresenter<IWidgetTypeCardProps & RouteComponentProps<{ fname: string, pid: string, aid: string }>> {
  public render() {
  
    if (!this.props.widget) return null;
    
    return (
      <widgets.components.common { ...this.props } className={cnWidget({ type })}>
        <Card />
      </widgets.components.common>
    )
  }
}

export default withRouter(WidgetTypeCardPresenter)