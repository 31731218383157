import { useDefaultStaticStates } from "muicomponents/src/ItemsListDialog/defaultListState";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useDSStorage } from "redux/dataStorage/hooks";
import { checkResponseStatus, ResponseError } from "utils/src";
import { Vacancy, VacancyFilter } from "utils/src/BaseTypes/vacancy";
import { useReffedState } from "utils/src/hooks";
import { getSuggestedVacancyUsers } from "utils/src/requests/admin.vacancies";

const listCount = 10;

export const useSuggestedVacancyUserList = (id: Vacancy['id']) => {

    const { addItems } = useDSStorage('vacanciesSuggests');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [finished, setFinished] = useState(false);
    const {search, debouncedSearch, changeSearch} = useDefaultStaticStates();
    const searchRef = useRef(search);
    searchRef.current = search;
    const [list, setList, listRef] = useReffedState<Vacancy['id'][]>([]);

    const getList = useCallback(async function(searchChanged?: boolean) {
        try {
            setLoading(true);
            setError(false);
            const response = await getSuggestedVacancyUsers({
                id,
                count: listCount,
                skipCount: searchChanged ? 0 : listRef.current.length,
                search: searchRef.current
            });
            if(checkResponseStatus(response)) {
                const data = response.data.map(user => {
                    return {
                        id: user.id,
                        item: user
                    };
                });
                addItems(data);
                if(searchChanged) {
                    setList(data.map(r => r.id));
                } else {
                    setList([ ...listRef.current, ...data.map(r => r.id) ]);
                }
                if(response.data.length < listCount) {
                    setFinished(true);
                }
            } else {
                throw new ResponseError(response)
            }
        } catch(e) {
            if(e instanceof ResponseError) {
                setError(true);
            } else {
                console.error('get suggested users error', e);
            }
        } finally {
            setLoading(false);
        }
    }, []);
    
    useLayoutEffect(() => {
        getList(true);
    }, [id, debouncedSearch]);


    return {
        loading,
        error,
        finished,
        list,
        search,
        changeSearch,
        getList
    };
};