import * as React from 'react';
import * as ReactDOM from 'react-dom';

let intId: any;
let doc: any;
// let head: any;
// let body: any;

export class Frame extends React.Component<any, any>{
  private _el: HTMLIFrameElement | null;
  private doc: any; 
  private self: this = this;

  public componentDidMount() {
    this.renderFrameContents();
  }

  public render() {
    return <iframe width="100%" ref={(e) => this._el = e} />;
  }

  public renderIframe() {
    return (
      <iframe ref={(e) => this._el = e} onLoad={() => {console.log('LOAD');}} />
    )
  }

  public renderFrameContents = () => {
    let node: any = this._el;
    if (node) node = node.contentWindow;
    if (node) doc = node.document;
    if (!doc) return;

    if (doc.readyState === 'complete') {
      clearInterval(intId);
      let root = doc.getElementById('pryaniky-root-frame');
      if (!root) {
        const div = doc.createElement('div');
        div.setAttribute('id', 'pryaniky-root-frame');
        doc.body.append(div);
        root = div;
      }
      ReactDOM.render(
        <div>
          &shy;{this.props.head}
          {this.props.children}
        </div>
        , root);
    } else {
      intId = setInterval(this.renderFrameContents, 0);
    }
  }
}

class Hello extends React.Component<any, any>{
  constructor(props: any) {
    super(props);
    this.state = {
      show: false
    }
  }

  public render() {
    const classes = this.state.show ? '' : 'u-isHidden';
    return (
      <Frame head={
        <link type='text/css' rel='stylesheet' href='https://rawgit.com/ryanseddon/9d4448489145f8b21909/raw/a43ca3ab17ff0059efdd554940cdb5570b93c8a2/styles.css' />
      }>
        <div onClick={this.toggleState}>
          <div className={classes}>this.state.show is true and now I'm visible</div>
          Hello {this.props.name}
        </div>
      </Frame>
    );
  }

  public toggleState = () => {
    this.setState({ show: !this.state.show });
  }
}

export default function renderInFrame() {
  if (document.getElementById('pryaniky-root-frame')) ReactDOM.render(<Hello name="World" />, document.getElementById('pryaniky-root-frame')); 
}

// var Frame = React.createClass({
//   render: function () {
//     return <iframe />;
//   },
//   componentDidMount: function () {
//     this.renderFrameContents();
//   },
//   renderFrameContents: function () {
//     var doc = this.getDOMNode().contentWindow.document;

//     if (doc.readyState === 'complete') {
//       var contents = (
//         <div>
//           &shy;{this.props.head}
//           {this.props.children}
//         </div>
//       );

//       React.renderComponent(contents, doc.body);
//     } else {
//       setTimeout(this.renderFrameContents, 0);
//     }
//   },
//   componentDidUpdate: function () {
//     this.renderFrameContents();
//   },
//   componentWillUnmount: function () {
//     React.unmountComponentAtNode(this.getDOMNode().contentWindow.document.body);
//   }
// });

// var Hello = React.createClass({
//   getInitialState: function () {
//     return { show: false };
//   },
//   toggleState: function () {
//     this.setState({ show: !this.state.show });
//   },
//   render: function () {
//     classes = this.state.show ? '' : 'u-isHidden';

//     return (
//       <Frame head={
//         <link type='text/css' rel='stylesheet' href='https://rawgit.com/ryanseddon/9d4448489145f8b21909/raw/a43ca3ab17ff0059efdd554940cdb5570b93c8a2/styles.css' />
//       }>
//         <div onClick={this.toggleState}>
//           <div className={classes}>this.state.show is true and now I'm visible</div>
//           Hello {this.props.name}
//         </div>
//       </Frame>
//     );
//   }
// });

// React.renderComponent(<Hello name="World" />, document.body);