import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from '../../../../src/redux/connector';
import { RouteComponentProps } from 'react-router';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { IStateType as IState } from 'redux/store';


export interface INotificationsSettingsNetworkGroupProps extends INotificationsSettingsNetworkGroupOwnProps, IClassNameProps, RouteComponentProps, INotificationsSettingsNetworkGroupStateProps {}

export interface INotificationsSettingsNetworkGroupOwnProps {
  tag?: 'div';
  // userId?: string;
}

export interface INotificationsSettingsNetworkGroupState {
  groups?: any;
  networks?: any;
  showNetworksGroup?: any
  notificationTemplate?: any
  notificationNetworkTemplate?: any
  networksData?: any
  
}

export interface IGroupItem {
  name?: string
  imgUrl?: string
  key?: string
  networkId?: string
  parentNetworkId?: string
  currentNetworkId?: string
}

export interface INetworkItem {
  name: string
  groups?: any
}

export const mapStateToProps = (state: IState, props: INotificationsSettingsNetworkGroupOwnProps) => ({
  parentNetworkId: state.store.appSettings && state.store.appSettings.parentNetwork  && state.store.appSettings.parentNetwork.id,
  currentNetworkId: state.store.appSettings && state.store.appSettings.appId,
  networks: getAuthUser(state).baseData.networks,
  userId: getAuthUser(state).baseData.id,
})

export type INotificationsSettingsNetworkGroupStateProps = ReturnType<typeof mapStateToProps>

export const cnNotificationsSettingsNetworkGroup = cn('NotificationsSettingsNetworkGroup');