import { IQuestStepPropsType, IQuestStepStateType, mapStateToProps, TStateProps } from './QuestStep.index'
import React, { memo, FC } from 'react';
import { connect } from 'react-redux';
import './QuestStep.scss'
import { withNewsContext } from 'News/contexts/news'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import ContentLayout from 'News/components/ContentLayout'
import TextRender from 'News/containers/TextRender'

export const QuestStepPresenter: FC<IQuestStepPropsType & TStateProps> = ({ isSingle, text }) => <NewsView className={'type-queststeps'}>
    <ContentLayout>
        <TextRender />
    </ContentLayout>
</NewsView>

export const QuestStep = withNewsContext(connect(
    mapStateToProps
)(memo(QuestStepPresenter)));

export const initQuestStep = () => ComponentInjector.getInstance().addNode('news_types', <QuestStep />, 'queststeps')