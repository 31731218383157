import { BaseNews } from '../../BaseType'
import { ThanksNews, RewardReason } from '../Thanks.type'
import { IClassNameProps } from '@bem-react/core';
import { Rule } from '../../Base.validate';
import { IStateType as IState } from 'redux/store';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';

export interface IEditorProps extends IClassNameProps, IMapThanksEditorStateToProps {
    onChangeUsers?: (values: any[]) => void;
    onChangeReason?: (values: any) => void;
    onChangeText?: (text: string) => void
    onChangeThanks?: (val: number) => void;
    reasons?: any[];
    // thanksCount?: number;
    text?: any;
    users?: any;
    groups?: any[];
    excludeUsers?: any[];
    maxUsers?: number;
    validation?: Rule[];
    // maxThanksValue?: number;
    maxThanksArgumentsCount?: number;
    // thanksForAll?: number;
    disabledVariants?: any[];

    disableDown?: boolean
    disableUp?: boolean
    thanksCount?: number
    available?: number
    fullCount?: number
    fullCurrency?: number
    maxPerUser?: number
    maxThanks?: number
}

export const mapThanksEditorStateToProps = (state: IState) => ({
    isAdmin: getCurrentUser(state).baseData.isAdmin
})

export type IMapThanksEditorStateToProps = ReturnType<typeof mapThanksEditorStateToProps>