export const iconsPack = [
  'ad',
  'address-book',
  'address-card',
  'adjust',
  'air-freshener',
  'alarm-clock',
  'award',
  'bell',
  'books',
  'book-stack',
  'cake',
  'calendar',
  'clip',
  'check-circle',
  'clock',
  'coin-laying',
  'coins-stack',
  'coin-standing',
  'cross-mark',
  'cup',
  'download',
  'edit',
  'envelope',
  'eye',
  'eye-crossed',
  'finish',
  'flag',
  'give',
  'hash-tag',
  'heart',
  'heart-fill',
  'home',
  'idea',
  'ignots',
  'key',
  'likers',
  'location',
  'lock',
  'medal',
  'message',
  'money',
  'money-bag',
  'qusetion',
  'rocket',
  'search',
  'shop',
  'smile',
  'tag',
  // 'triangle-down',
  // 'triangle-up',
  'user',
  'user-add',
  'user-group',
  'wallet',
  'wiki',
  'achievement',
  'arrow-left',
  'arrow-right',
  'at-sign',
  'alicorn',
  'align-justify',
  'arrow-alt-square-down',
  'arrow-alt-to-bottom',
  'arrow-circle-up',
  'arrow-alt-up',
  'at',
  'atlas',
  'atom',
  'badge-check',
  'badge-dollar',
  'badge-percent',
  'balance-scale',
  'balance-scale-left',
  'ballot-check',
  'ball-pile',
  'bookmark',
  'ban',
  'building',
  'bullhorn',
  'bullseye',
  'bullseye-arrow',
  'bullseye-pointer',
  'burn',
  'business-time',
  'calculator-alt',
  'calendar-alt',
  'calendar-check',
  'calendar-star',
  'camera-alt',
  'cat',
  'clipboard',
  'clipboard-check',
  'clipboard-list',
  'clipboard-list-check',
  'clipboard-prescription',
  'clipboard-user',
  'fa-clock',
  'coffee',
  'coffee-togo',
  'cogs',
  'coins',
  'comment-check',
  'comment-dollar',
  'comment-dots',
  'comment-edit',
  'comment-exclamation',
  'comment-lines',
  'comment-medical',
  'comment-minus',
  'comment-plus',
  'comments',
  'comments-alt',
  'comments-alt-dollar',
  'comments-dollar',
  'laugh-wink',
  'layer-group',
  'lightbulb-on',
  'signal-alt',
  'tablet-android-alt',
  'shopping-basket',
  "car",
  "car-side",
  "chalkboard-teacher",
  "chart-line",
  "chart-pie-alt",
  "paper-plane",
  "phone",
  "video",
  "film",
  "university",
  "user-circle",
  "users",
  "user-graduate",
  "utensils",
  "boxes",
  "box-heart",
  "caret-circle-right",
  "chart-bar",
  "cloud",
  "cloud-download",
  "cloud-upload",
  "code",
  "cocktail",
  "crown",
  "cubes",
  "database",
  "fighter-jet",
  "gingerbread-man",
  "graduation-cap",
  "hand-holding-heart",
  "hotel",
  "image",
  "map-marked",
  "trophy",
  "trophy-alt",
  "fa-search",

]

export default iconsPack;