import { INotifiSettings, INetworks } from './interfaces';
import { INotifiSettingsData } from 'utils/src/requests/models/api.notifications';
import {
  SET_NOTIFI_SETTINGS,
  CLEAR_NOTIFI_SETTINGS,
  SET_NOTIFI_SETTINGS_NETWORKS,
  SET_NOTIFI_TEMPLATES,
  CHANGE_NOTIFI_SETTINGS_GROUP_TEMPLATE,
  CHANGE_NOTIFI_SETTINGS_NETWORKS
} from './actions';
import { INotifiSettingsActions } from './actions.interfaces';

const baseState: INotifiSettings = {
  settings: {} as INotifiSettingsData,
  networks: {} as INetworks,
  templates: []
}

export const notifiSettingsReducer = (state = baseState, action: INotifiSettingsActions): INotifiSettings => {
  switch (action.type) {

    case SET_NOTIFI_SETTINGS: {
      return {
        ...state,
        settings: action.payload
      }
    }
      
    case CLEAR_NOTIFI_SETTINGS: {
      return {
        ...state,
        settings: baseState.settings
      }
    }
      
    case SET_NOTIFI_SETTINGS_NETWORKS: {
      return {
        ...state,
        networks: action.payload
      }
    }

    case CHANGE_NOTIFI_SETTINGS_NETWORKS: {
      const { nid, data } = action.payload;
      return {
        ...state,
        networks: {
          ...state.networks,
          [nid]: {
            ...state.networks[nid],
            ...data
          }
        }
      }
    }

    case CHANGE_NOTIFI_SETTINGS_GROUP_TEMPLATE: {
      const { nid, gid, selectedTemplateName } = action.payload;
      return {
        ...state,
        networks: {
          ...state.networks,
          [nid]: {
            ...state.networks[nid],
            groups: {
              ...state.networks[nid].groups,
              [gid]: {
                ...state.networks[nid].groups[gid],
                selectedNotifyTemplate: selectedTemplateName
              }
            }
          }
        }
      }
    }
      
    case SET_NOTIFI_TEMPLATES: {
      return {
        ...state,
        templates: action.payload
      }
    }

    default:
      return state
  }
}
