import React, { FC, memo, useState, useCallback, useMemo } from 'react';
import { cnClassName, mapDispatchToProps, mapStateToProps, News } from './interfaces';
import { connect } from 'react-redux';
import './style.scss';
import { withNewsContext } from '../../contexts/news';
import { Actions } from 'blocks/NewsView/Actions/Actions';
import { Like } from 'blocks/NewsView/Actions/Like/Like';
import { Thank } from 'blocks/NewsView/Actions/Thank/Thank';
// import likersModal from 'blocks/Dialogs/News/Likers/NewsLikers';
import ActionButton from './ActionButton';
import SlaveAction from './SlaveAction';
import { withComponentEnjector } from 'utils/src/ComponentInjector';
import ActionsLayout from '../../components/ActionsLayout';
import { Icon } from 'uielements/src/Icon/Icon';
import { Button } from 'uielements/src/Button/Button';
import { viewWithK } from 'utils/src/utils.numbers';

type IPresenterProps = News.NewsActions.IPresenterProps;
type IOwnProps = News.NewsActions.IOwnProps;
type IDispatchProps = News.NewsActions.IDispatchProps;
type IStateProps = News.NewsActions.IStateProps;
type IState = News.NewsActions.IState;

const noopObj: any = {};
export const Presenter: FC<IPresenterProps> = ({
    className,
    // likeCount,
    // liked,
    id,
    // allowThanksReply,
    // allowReply,
    sendLikeNews,
    enabledThanks,
    // toggleThank,
    statistics,
    actionButtons,
    getComponent,
    disabledAction = [],
    // injectionComponents
}) => {
    // let allowThank = allowThanksReply;
    // allowThank = !allowReply ? false : allowThank

    // const onLike = useCallback(() => sendLikeNews({
    //     id, likeCount, liked
    // }), [id, likeCount, liked])

    // const buttonProps = useMemo(() => ({ 'data-liked': liked }), [liked])

    // const onThank = useCallback(() => toggleThank(true), [])
    const buttons = actionButtons.filter((value) => !disabledAction.includes(value.action.type));
    const leftActions = buttons.filter((value) => value.layout === 'left');
    const rightActions = buttons.filter((value) => value.layout === 'right');
    const webCountViewed = useMemo(() => {
        const channelName = 'web';
        const countItem = statistics?.viewed.find((c) => c.name === channelName);
        return countItem?.count;
    }, [statistics]);

    return (
        <ActionsLayout
            slave={<SlaveAction actions={rightActions} />}
            info={
                webCountViewed ? (
                    <Button theme="unstyled" className={'NewsActions-EyeBtn'}>
                        <Icon icon={'fa-eye'} className={'NewsActions-EyeBtnIcon'} /> {viewWithK(webCountViewed)}
                    </Button>
                ) : undefined
            }
        >
            {leftActions.map((action) => {
                const Component = getComponent(action.action.type) || ActionButton;
                return <Component key={action.action.type} action={action} />;
            })}
            {/* <Like
            likeCount={likeCount}
            liked={liked}
            buttonProps={buttonProps}
            onClick={onLike}
            newsId={id}
            className={'Action'}
        />
        {enabledThanks && allowThank && <Thank onClick={onThank} />} */}
        </ActionsLayout>
    );
};

export const Connected = connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withComponentEnjector<IPresenterProps>(memo(Presenter), ['news_actions']));
export default withNewsContext(Connected);
