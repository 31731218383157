import { IAdditionalContentRenderProps, cnAdditionalContentRender } from './AdditionalContentRender.index';
import * as React from 'react'
import './AdditionalContentRender.scss'
import { IdeaFieldView } from '../Fields/view/Field';
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { Icon } from 'uielements/src';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

export const AdditionalContentRender: React.FC<IAdditionalContentRenderProps> = ({ data }) => {
    return (<div>
        {
            data.map((field, i) => {
                return (
                    <div key={i}>
                        {field.v.findIndex((i: any) => i.value !== "" && i.value !== "[]" || i.field.defaultValue !== "") !== -1 &&
                            // item.field.fieldType === "MatrixOnePerRow" && item.value === "" ||
                            // item.field.fieldType === "Multi" && item.value === "" ||
                            // item.field.fieldType === "Single" && item.value === "" ||
                            <h4 className={cnAdditionalContentRender('CatName')}>{field.k}</h4>}
                        {field.v.map((item) => {
                            return (item.field.displayMode !== "hidden" ?
                                item.field.fieldType === "DateTime" && item.value === "" ||
                                    item.field.fieldType === "String" && item.value === "" ||
                                    item.field.fieldType === "Int" && item.value === "" ||
                                    item.field.fieldType === "Textarea" && item.value === "" ||
                                    item.value === "[]" ? null :
                                    <div key={item.fieldId} className={cnAdditionalContentRender('Item')}>
                                        {item.field.displayMode === "value" ? undefined : <h5 className={cnAdditionalContentRender('Name')}>{item.field.name}</h5>}
                                        <div className={cnAdditionalContentRender('Field')}>
                                            <div className={cnAdditionalContentRender('Value')}><IdeaFieldView field={item} /></div>
                                            {
                                                item.field.description &&
                                                // <HelpTooltip className={cnAdditionalContentRender('HelpTooltip')} help={item.field.description}>
                                                <Tooltip
                                                    className={cnAdditionalContentRender('HelpTooltip')}
                                                    title={item.field.description}
                                                >
                                                    <span> <Icon icon={'question-circle'} /></span>
                                                </Tooltip>
                                            }
                                        </div>
                                    </div> : undefined
                            )
                        })}


                    </div>
                )
            })
        }
    </div>);
}

// item.field.fieldType === "Multi" && item.value === "" ||
//                                     item.field.fieldType === "Single" && item.value === "" ||
//                                     item.field.fieldType === "DateTime" && item.value === "" ||
//                                     item.field.fieldType === "String" && item.value === "" ||
//                                     item.field.fieldType === "Int" && item.value === "" ||
//                                     item.field.fieldType === "MatrixOnePerRow" && item.value === "" ||
//                                     item.field.fieldType === "Textarea" && item.value === "" ||
//                                     item.value === "[]" ? null : 