import React, {createRef, FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { Widget } from 'Widgets_v2/Widget/Widget';
import { PositionBox, PositionRemoveButton, ShowFullButton } from './Position.styled';
import { DeleteForever, Add } from 'muicomponents/src/Icons';
import { cnWidgetPosition, PositionProps } from './Position.index';
import { Collapse } from 'muicomponents/src/Transitions/Transitions';
import { DialogWidgetAdd } from 'blocks/Dialogs/Widgets/AddNew/WidgetsAddNew';
import { CustomSettings } from 'utils/src';
import { Translate } from 'localizations/Translate';
import { usePositionSignals } from './Position.hooks';
import './Position.scss';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { baseCollapsedSize } from '../WidgetLayoutHorizontal.constants';

const maxHeightCkeckTimes =  2;

const timeoutCollapse = 300;

export const Position: FC<PositionProps> = ({
    collapsedSize = baseCollapsedSize,
    sectionId,
    widgetId,
    addWidgetHandler,
    remove,
    disableCollapse
}) => {

    const disableGlobalCollapse = useMemo(() => {
        return widgetId ? ((window as any).layoutHorizontalNotCollapsibleWidgets as string[])?.includes(widgetId) : false;
    }, [widgetId]);

    const ref = useRef<HTMLDivElement>();
    const intervalRef = useRef<ReturnType<typeof setInterval>>();

    const view = useMemo(() => {
        if(widgetId) return <Widget id={widgetId} className={cnWidgetPosition('Item')} />;
        return (
            <PositionBox
                className={cnWidgetPosition('Item', {}, ['Widget'])}
                onClick={() => DialogWidgetAdd({ columnId: sectionId }).then((newWidget) => addWidgetHandler(newWidget, -1))}
            >
                <Add />
                <PositionRemoveButton onClick={(e) => {
                    e.stopPropagation();
                    remove();
                }}>
                    <DeleteForever />
                </PositionRemoveButton>
            </PositionBox>
        )
    }, [ widgetId ]); 

    const [ show, setShow ] = useState(disableCollapse || disableGlobalCollapse || false);

    const [ showMoreButton, setShowMoreButton ] = useState(false);
    const showMoreButtonRef = useRef(showMoreButton);
    showMoreButtonRef.current = showMoreButton;

    /**
     * signals from others blocks by widget id
     */
    const signalsState = usePositionSignals(widgetId);

    /**
     * if data update
     */
    useDidUpdateEffect(() => {
        recalculateShowMoreButton(ref.current, true);
    }, [signalsState.dataUpdate]);

    /**
     * if block have edit state and user change it
     */
    useDidUpdateEffect(() => {
        if(disableCollapse || disableGlobalCollapse) return ;
        setShow(signalsState.edit);
        recalculateShowMoreButton(ref.current);
        if(signalsState.edit) {
            setTimeout(() => {
                setShowMoreButton(false);
            }, timeoutCollapse);
        }
    }, [signalsState.edit]);

    /**
     * if block have open state and user change it
     */
    useDidUpdateEffect(() => {
        if(disableCollapse || disableGlobalCollapse) return ;
        setShow(signalsState.open);
        recalculateShowMoreButton(ref.current);
        if(signalsState.open) {
            setTimeout(() => {
                setShowMoreButton(false);
            }, timeoutCollapse);
        }
    }, [signalsState.open]);

    useDidUpdateEffect(() => {
        if(disableCollapse || disableGlobalCollapse) return ;
        const prepairedCollapsedSize = typeof collapsedSize === 'string' ? undefined : collapsedSize;
        recalculateShowMoreButton(ref.current, true, prepairedCollapsedSize);
    }, [collapsedSize]);

    const recalculateShowMoreButton = useCallback((collepseRef: (typeof ref)['current'], withHide?: boolean, baseHeight?: number) => {
        if(intervalRef.current) clearInterval(intervalRef.current);
        let checkTimes = 0;
        const checkHeight = () => {
            if(checkTimes >= maxHeightCkeckTimes && intervalRef.current) {
                clearInterval(intervalRef.current);
            };
            checkTimes++;
            if(!widgetId) checkTimes = maxHeightCkeckTimes;
            if(!collepseRef || (!withHide && showMoreButtonRef.current)) return;
            const toHeigth = baseHeight || collepseRef.clientHeight;
            let itemsHeight = 0;
            collepseRef.querySelectorAll('.Widget').forEach(el => {
                itemsHeight += el.clientHeight;
            });
            if(itemsHeight > toHeigth) {
                setShowMoreButton(true);
                showMoreButtonRef.current = true;
            } else {
                setShow(true);
                if(withHide) {
                    setShowMoreButton(false);
                    showMoreButtonRef.current = false;
                }
            }
        };
        checkHeight();
        intervalRef.current = setInterval(checkHeight, 1000);
    }, []);


    useEffect(() => {
        if(!widgetId) {
            setShowMoreButton(false);
            showMoreButtonRef.current = false;
        }
        recalculateShowMoreButton(ref.current);
    }, [ widgetId, ref.current ]);

    // const [additionalClasses, setAdditionalClasses] = useState('WidgetMui_box_shadow');
    // const additionalClassesTimeout = useRef<ReturnType<typeof setTimeout>>();

    // useDidUpdateEffect(() => {
    //     if(additionalClassesTimeout.current) {
    //         clearTimeout(additionalClassesTimeout.current);
    //     }
    //     additionalClassesTimeout.current = setTimeout(() => {
    //         if(show) {
    //             setAdditionalClasses('');
    //         } else {
    //             setAdditionalClasses('WidgetMui_box_shadow');
    //         }
    //     }, timeoutCollapse);
    // }, [show]);

    return (
        <Collapse
            ref={ref}
            in={show}
            className={cnWidgetPosition({}, [show ? '' : 'WidgetMui_box_shadow'])}
            collapsedSize={collapsedSize}
            timeout={timeoutCollapse}
            sx={{
                position: 'relative',
                borderRadius: '8px',
                ['& .MuiCollapse-wrapper']: {
                    height: !show && !showMoreButton ? '100%' : undefined
                },
                ['& .Widget_type_blocks .Blocks .MuiButtonBase-root']: {
                    maxHeight: `${collapsedSize}px`
                }
            }}
        >
            {
                !disableCollapse && !disableGlobalCollapse && showMoreButton && 
                <ShowFullButton
                    variant={'text'}
                    onClick={() => setShow(!show)}
                >
                    <Translate i18nKey={`pryaniky.widget.position.${show ? 'hide' : 'show'}`} />
                </ShowFullButton>
            }
            {view}
        </Collapse>
    );
};