import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';
import { mapDispatchToProps } from "../../redux/connector";

// import i18n from '../../localizations/i18n';

import { cnBackground, IBackgroundProps, IBackgroundState, mapStateToProps } from './Background.index';

import './Background.scss';
import background from './background.png';
import * as utils from 'utils/src/utils';

export class BackgroundPresenter extends React.Component<IBackgroundProps, IBackgroundState> {
  public el: HTMLElement | null;
  public state = {
    back: background,
    backColor: 'EBEFF2',
  };


  public componentDidMount() {
    this.setBackColor();
  }
  public componentWillUnmount() {
    document.body.style.backgroundColor = 'transparent';
  }
  public componentWillReceiveProps(np: any) {
    // this.setBackColor();
  }

  public render() {
    const { className = '', auth, children, backgroundUrl } = this.props;
    return (
      <div
        ref={el => this.el = el}
        style={{ backgroundImage: `url(${backgroundUrl ? backgroundUrl : ''})` }}
        className={cnBackground({ noAuth: !auth }, [className])}>
        {children}
      </div>
    )
  }

  private setBackColor = () => {
    if (!this.props.auth) {
      document.body.style.backgroundColor = '#EBEFF2';
    } else {
      let backColor = Number(this.state.backColor).toString(16);
      if (backColor.length === 3) {
        // duplicate each char
        backColor = backColor.split('').map(char => char + char).join('')
      } else if (backColor.length < 6) {
        // add trainling zeros
        backColor = Array.from({ length: 6 - backColor.length }, _ => '0').join('') + backColor;
      }
      document.body.style.backgroundColor = '#' + backColor;
    }
  }

}

export const Background = connect(
  mapStateToProps,
  mapDispatchToProps({})
)(BackgroundPresenter)