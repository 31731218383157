import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IStateType as IState } from '../../../redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { RouteComponentProps } from 'react-router'
import { InputProps as StandardInputProps } from '@mui/material/Input'

export interface IHeaderLeaderboardProps extends IHeaderLeaderboardOwnProps, IHeaderLeaderboardStateProps, IHeaderLeaderboardDispatchProps {
    ratingValueToHeader: any;
    context:any;
    widgetId: string;
    orderValueHeader: boolean;
    changeOrder: (value:boolean)=>void;
    searchValueHeader?: string;
    changeSearch:StandardInputProps['onChange']
   
}

export interface IHeaderLeaderboardOwnProps{
    
}

export type IHeaderLeaderboardStateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: IState) => {
    return {
        curUser: getAuthUser(state).baseData,
    };
};

export type IHeaderLeaderboardDispatchProps = ReturnType<typeof mapDispatchToProps>

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
}, dispatch)


export const cnHeaderLeaderboard = cn('HeaderLeaderboardMui');
