import React, { FC, useEffect, useState, useMemo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMSTO
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { Body } from '../../../../../components/Body/Body'
import { Select } from 'uielements/src'
import { createAttemptOptions, sortAttempt, cretateAnswersMap, useSetAttepmt } from 'LMSModule/utils/result.utils'
import { AnswerList } from '../../../../../LMSTeacherOffice/AnswerList/AnswerList'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'



type IPresenterProps = LMSTO.StudentResultsTest.IPresenterProps
type IOwnProps = LMSTO.StudentResultsTest.IOwnProps
type IDispatchProps = LMSTO.StudentResultsTest.IDispatchProps
type IStateProps = LMSTO.StudentResultsTest.IStateProps
type IState = LMSTO.StudentResultsTest.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    unit,
    sid,
    cid,
    userId,
    unitContent
}) => {
    const sortedResults = sortAttempt(unit.results)
    const options = createAttemptOptions(sortedResults.length)
    const [attempt, setAttempt] = useSetAttepmt(options, [sid, unit.id])

    if (!sortedResults[attempt.value]) {
        return <div className={cnClassName({}, [className])} >
            <h3>{unit.name}</h3>
            <Select options={options} selected={attempt} onChange={setAttempt} />
        </div>
    }

    const attemptData = sortedResults[attempt.value];

    const [resultAnswers, resultAnswersMap] = cretateAnswersMap(attemptData.attempt)

    const detailsRender = (question: any) => {
        return <AnswerList hideModeration={false} courseId={cid} listContainer={question.id} filters={{
            'filter.sessionIds': [sid],
            'filter.filterByUserIds': [userId],
            'filter.filterByQuestionIds': [question.id],
            'filter.isNeedModeration': true,
            'filter.filterByStatus': ['All']
        }} />
    };

    return <div className={cnClassName({}, [className])}>
        <h3 className={cnClassName('Title')}>{unit.name}</h3>
        <Body
            // unitTimer={unitTimer}
            resultAnswersMap={resultAnswersMap}
            resultAnswers={resultAnswers}
            rightAnswersCount={unit.rightAnswersCount}
            questionsCount={unit.questionsCount}
            normalQuestions={unitContent.normalQuestions}
            questions={unitContent.questions}
            attemptNumber={sortedResults.length}
            unitContent={unitContent}
            attemptMinScore={attemptData.qualifyAnswerPercentage}
            attemptDate={attemptData.timeStamp}

            moderatedAnswersCount={unit.moderatedAnswersCount}
            waitModerationCount={unit.waitModerationCount}

            attemptsList={options}
            selectedAttempt={attempt}
            cangeAttempt={setAttempt}

            detailsRender={detailsRender}
        />
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
