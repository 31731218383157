import { zeroId } from 'LMSModule/utils/utils.lms'
import { EditorState } from 'draft-js';

export interface IQuestiononModel {
    answers: any[],
    id: string,
    text: string,
    allowFreeAnswer: boolean,
    allowSkip: boolean,
    isOnModeration: boolean,
    tags: string,
    isNeedModeration: boolean,
    randomizeAnswerPositions: boolean,
    header: string,
    description: string,
    score: number,
    qtype: number,
    allowAttach: boolean,
    draftState: EditorState,
    isNew?: boolean
}

export const questionModelCreator = (id = zeroId): IQuestiononModel => (
    {
        "answers": [],
        "id": id,
        "text": "",
        "allowFreeAnswer": false,
        "allowSkip": false,
        "isOnModeration": false,
        "tags": "",
        isNeedModeration: false,
        "randomizeAnswerPositions": false,
        "header": "",
        "description": "",
        "score": 1,
        "qtype": 2,
        allowAttach: false,
        draftState: EditorState.createEmpty(),
        isNew: true
    }
)