import { NValidate } from 'utils/src/validate_v2';
import { NNews } from './News.interface';

export const NewsValidateRules: NValidate.ValidateRulesObject<NNews.Create> = {
    text: {
        notNull: {
            value: true
        },
        min: {
            value: 0,
            // errorMessageKey: ''
        }
    },
};