import React, { FC, useEffect, useState } from 'react'
import { cnLMSTeacherOffice, mapDispatchToProps, mapStateToProps } from './LMSTeacherOffice.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { Button } from 'uielements/src';
import { TeacherProfile } from '../TeacherProfile/TeacherProfile'
import { CoursesList } from '../CoursesList/CoursesList'
import './LMSTeacherOffice.scss'
import { Port } from '../Tabs/Port/Port'
import Moderation from '../Moderation'
import UnitsComments from '../Comments'
import Tracks from '../TracksList'
import StatisticsFull from '../StatisticsFull'

export const LMSTeacherOfficePresenter: FC<any> = ({ className, loadCoursesByCurrentUser, courses, results }) => {
    const [moderationCid, setModerationCid] = useState<string>('')

    if (!courses) return <div>no courses</div>

    const tabs = {
        default: <CoursesList />,
        courses: <CoursesList />,
        comments: <UnitsComments />,
        // tracks: <Tracks />,
        moderation: <Moderation courseId={moderationCid === '' ? undefined : moderationCid} setModerationCid={setModerationCid} />,
        statistics: <StatisticsFull />,
    }

    return <div className={cnLMSTeacherOffice({}, ['Page', className])}>
        <TeacherProfile />

        <Port tabs={tabs} />
    </div>
}

export const LMSTeacherOffice = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LMSTeacherOfficePresenter));
