import React, { FC, useState } from 'react'
import { IProps, mapDispatchToProps, mapStateToProps, cnHeader } from './Header.index'
import { connect } from 'react-redux';
import './Header.scss'
import { Button, CheckboxInput, Icon, SplitButton } from 'uielements/src'
import { Button as MuiButton } from 'muicomponents/src/Button/Button'
import * as utilsProj from 'utils.project/utils.project';
import i18n from 'localizations/i18n';
import { SearchInput } from 'uielements/src';
import { CreateFolder } from './CreateFolder/CreateFolder'
import { OptionContent } from 'uielements/src/Dropdown/Dropdown'
import {  useDebounce } from 'utils/src/hooks'
export const Header_: FC<IProps> = ({
    gid,
    loadList,
    className,
    openFileWindow,
    selectAllFiles,
    selected,
    count,
    deleteFileMultiple,
    showControls,
    createFolder,
    currentFolder,
    moveFile,
    showMove,
    filters
}) => {
    const selectAll = () => selectAllFiles(gid)
    const selectedAll = selected.length === count;

    const action = (value: string) => loadList(gid, count, { ...filters, search: value }, currentFolder)
    const load = useDebounce(action, 500, [count, currentFolder, filters]);

    const deleteSelected = () => {
        utilsProj.confirm({ text: i18n.t('pryaniky.files.view.multipledelete', { oldData: selected.length.toString() }), onConfirm: () => deleteFileMultiple(gid, selected) })
    }

    const onChangeSearch = (value: string) => {
        load(value)
    }
    const actions: any[] = [
        {
            title: <OptionContent icon={'arrow-alt-right'} text={i18n.t('pryaniky.files.move.here')} />,
            buttonProps: { onClick: () => moveFile(gid, currentFolder, true) },

        },
        {
            title: <OptionContent icon={'arrow-alt-right'} text={i18n.t('pryaniky.files.move')} />,
            buttonProps: { onClick: showMove },

        },
        {
            title: <OptionContent icon={'trash'} text={i18n.t('pryaniky.files.view.delete')} />,
            buttonProps: { onClick: deleteSelected },

        }
    ];
    return <div className={cnHeader({}, [className])}>
        <div className={cnHeader('SearchBox')}>
            <SearchInput throttle={-1} value={filters.search} className={cnHeader('Search')} onChange={onChangeSearch} />
        </div>
        {showControls && <div className={cnHeader('Actions')}>
            <div className={cnHeader('SelectsBox')}>
                <CheckboxInput className={cnHeader('Checkbox')} checked={selectedAll} onChange={selectAll}>{i18n.t('pryaniky.files.view.selectall')}</CheckboxInput>
                {selected.length !== 0 && <>
                    <SplitButton className={cnHeader('ActionMenu')}
                        button={
                            <Button theme={'unstyled_center'} className={cnHeader('ActionBtn')} >
                                {i18n.t('pryaniky.files.view.selectcount', { oldData: selected.length.toString() })}
                            </Button>
                        }
                        options={actions} />
                </>}
                <CreateFolder className={cnHeader('CreateFolder')} gid={gid} />
                <MuiButton className={cnHeader('UplBtn')} variant={'contained'} color={'primary'} onClick={openFileWindow}>{i18n.t('pryaniky.files.upload')}<Icon icon={'cloud-upload'} /></MuiButton>
            </div>

            <div className={cnHeader('CreateBox')}>
                {/* <Button theme={'unstyled_center'} onClick={openFileWindow}><Icon icon={'plus'} /></Button> */}
            </div>
        </div>}
    </div>
}

export const Header = connect(
    mapStateToProps,
    mapDispatchToProps
)(Header_)