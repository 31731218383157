import { connect } from 'react-redux';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps } from '../../redux/connector';
import { cnJsonForms, IJsonFormsProps, IJsonFormsState, mapStateToProps } from './JsonForms.index';
import { JsonFormsDispatch, JsonFormsReduxContext } from '@jsonforms/react';
import structures from './structures';
import './JsonForms.scss';

const loadingRedirectIgnore = ['login', 'logout', 'forgot', 'resetpassword'];

export class JsonFormsPresenter extends React.Component<any, any> {

  constructor(props: IJsonFormsProps) {
    super(props);
  }

  public render() {
    return (
      <JsonFormsReduxContext>
        <JsonFormsDispatch />
      </JsonFormsReduxContext>
    )
  }
}

// export const JsonForms = connect(
//   // mapStateToProps,

//   mapDispatchToProps({})
// )(JsonFormsPresenter);
