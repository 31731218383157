import { IBaseNews, createDefaultNews } from 'News/types/baseNews'

export interface Answer {
    id: string;
    text: string;
    answeredByMe: boolean;
    count: number;
    position: number;
    participants: any[];
}

export interface Poll {
    freeAnswer: boolean;
    showFullStat: boolean;
    disableRevote: boolean;
    answerVariants: number;
    showResultsBeforeVote: boolean;
    answers: Answer[];
    maxCount: number;
    showParticipants: boolean;
    expires: string;
    votersCount: number;
}

export interface PollNews extends IBaseNews {
    poll: Poll;
    newstype: 7;
}

export const defaultAnswer: Answer = {
    id: '',
    text: '',
    answeredByMe: false,
    count: 0,
    position: 0,
    participants: []
}
export const defaultDate = new Date();
// currentDate.setMonth((currentDate.getMonth() + 1))
defaultDate.setDate((defaultDate.getDate() + 14))

export const defaultPoll: Poll = {
    showResultsBeforeVote: true,
    freeAnswer: false,
    showFullStat: false,
    disableRevote: false,
    answerVariants: 100,
    answers: [],
    maxCount: 0,
    showParticipants: true,
    expires: defaultDate.toISOString(),
    votersCount: 0
}

export const defaultPollNews: PollNews = {
    ...createDefaultNews(),
    poll: defaultPoll,
    expires: defaultDate.toISOString(),
    newstype: 7
}