import { INewsReducer, INewsCreator } from './types'
import { createDefaultNews, createDefaultCreateNews } from '../../types/baseNews'
export const defaultCreator: INewsCreator = {
    currentNews: createDefaultCreateNews(),
    clearEditor: false,
    isValid: true,
    validFile: true,
    errors: {}
}

export const defaultBanners = {
    readed: [],
    closed: []
}

/*
    heights: {},
    currnetRendered: {}
    */

export const defaultNews: INewsReducer = {
    values: {},
    groupedKeys: {},
    edit: [],
    updateErrors: {},
    commentsValues: {},
    commentsKeys: [],
    commentsForms: {},
    // count: 10,
    // isFinished: false,
    // isLoading: false,
    groupsStates: {
        all: {
            count: 10,
            isFinished: false,
            isLoading: false
        }
    },
    newsCreator: defaultCreator,
    viewType: 'full',
    currentGroup: 'dash',
    sending: false,
    commentsLoading: [],
    commentsSending: [],
    statuses: {},
    virtualize: {
        //group_key: {
        //      heights: {},
        //      currnetRendered: {} 
        //}
    },
    readed: [],
    activeStatuses: {},
    banners: defaultBanners,
    quantity_search_result: {}
    // serviceStatuses: {}
}