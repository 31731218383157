import {
    saveSkipTest,
    saveStartUnitResult,
} from "../../redux/actions/LMS";
import {
    getSkipedQuestionIds,
} from '../../redux/sagas/LMS/selectors'
import { selectTestState } from '../selectTestState'
// import React, { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

export const useExam = (sid: string, uid: string, log: any, content: any) => {
    const listSkiped = useSelector(getSkipedQuestionIds(uid))
    const dispatch = useDispatch()


    const start = () => {
        dispatch(saveStartUnitResult({
            courseSessionId: sid,
            unitId: uid,
        }))
    }
    const skip = () => {
        dispatch(saveSkipTest(uid, sid))
    }

    const view = selectTestState(log, content, listSkiped.length === 0)

    return {
        start,
        skip,
        view
    }
}