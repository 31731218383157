import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IStateType } from '../../redux/store';
import { bindActionCreators, Action, Dispatch } from 'redux';
import { getUserById } from 'utils/src/CommonRedux/users/selectors';
import { changeUser, updateUser } from 'utils/src/CommonRedux/users/actions';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';


export namespace IUserfieldsWidget {

  export interface Props extends OwnProps, IClassNameProps, StateProps, ActionsProps { }

  export interface OwnProps {
    // type: string; // тип поля для отображения
    context: {[s: string]: any}; // контекст из виджета
    fieldsIds: string[];
    // fieldsSet: 'userHeadFields' | 'userInfoFields'; // имя переменной где лежат поля пользователя с которыми будет происходить работа
  }

  export type StateProps = ReturnType<typeof mapUserfieldsWidgetStateToProps>

  export type ActionsProps = ReturnType<typeof mapUserfieldsWidgetActionToProps>

}

export const mapUserfieldsWidgetStateToProps = (state: IStateType, props: IUserfieldsWidget.OwnProps) => ({
  userId: props.context?.uId || state.store.authUid,
  user: getUserById(props.context?.uId || state.store.authUid)(state),
  authUser: getAuthUser(state),
});

export const mapUserfieldsWidgetActionToProps = (d: Dispatch<Action>) => bindActionCreators({
  changeUser,
  updateUser
}, d);

export const cnUserfieldsWidgetStyle = cn('UserfieldsWidgetStyle');