import React, { FC, useEffect, useState } from 'react'
import { IListSkipedTestsProps, mapDispatchToProps, mapStateToProps, cnListSkipedTests } from './ListSkipedTests.index'
import './ListSkipedTests.scss'
import { connect } from 'react-redux';
import { Button } from 'uielements/src'
import { Test } from './Test/Test'
import { tS } from 'LMSModule/utils/i18n.adaptor'

const ListSkipedTestsPresenter: FC<any> = ({ skiped, units, cid, jumpToUnit, closeSessionOnCompleteMondatory, sid }) => {
    const allowComplite = skiped.reduce((acc: boolean, val: any) => (val.skipUnitType === 1 ? acc : false), true)

    return <div className={cnListSkipedTests()}>
        <h3 className={cnListSkipedTests('Header')}>{tS('notComplete.1')}</h3>
        <div className={cnListSkipedTests('List')}>
            {skiped.map((u: any) => <Test sid={sid} unit={units[u.id]} uid={u.id} />)}
        </div>
        <div className={cnListSkipedTests('Actions')}>
            <Button
                disabled={!allowComplite}
                className={cnListSkipedTests('Control')}
                onClick={() => closeSessionOnCompleteMondatory(sid, cid)}>{tS('gotoResults.1')}</Button>
            <Button
                main
                className={cnListSkipedTests('Control')}
                onClick={() => { jumpToUnit(skiped[0].id, cid) }}>{tS('start.2')}</Button>
        </div>
    </div>
}

export const ListSkipedTests = connect(
    mapStateToProps,
    mapDispatchToProps
)(ListSkipedTestsPresenter);