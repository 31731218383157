import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'src/utils/utils';
// import i18n from '../../localizations/i18n';
import {Avatar} from 'uielements/src/index'
import MDReander from 'uielements/src/CommonmarkRender'
import { IChatToastProps, cnChatToast } from './ChatToast.index';
import './ChatToast.scss';


export class ChatToastPresenter extends React.Component<IChatToastProps> {
  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', children, className = '', avatar,msg,title } = this.props;
    return (
      <TagName className={cnChatToast({}, [customClassNames, className])}>
        <Avatar className={cnChatToast('Avatar')} imgUrl={avatar} name={title} size={40}/>
        <div className={cnChatToast('Content')}>
          <div className={cnChatToast('Title')}>{title}</div>
          <div className={cnChatToast('Message')}><MDReander type={'inline'} source={msg} /></div>
        </div>
        {children}
      </TagName>
    )
  }
}


export const ChatToast = compose(
  // withBemMod(cnChatToast(), {})
  
)(ChatToastPresenter)