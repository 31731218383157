import React, { FC } from 'react'
import { cn } from '@bem-react/classname';
// import * as utils from 'utils/src/utils';
import { Spinner } from 'uielements/src'
import { IButtonProps } from 'uielements/src/Button/Button.index'
import './style.scss'
export const cnClassName = cn('ButtonWithSpin');

interface IWithLoadSpin {
    isLoading?: boolean
    spinnerSize?: number
}

function withLoadSpin<T extends IButtonProps>(Component: React.ComponentType<T>): FC<IWithLoadSpin & T> {
    return ({ children, className, spinnerSize = 15, isLoading = false, disabled, ...props }) => {

        return <Component
            {...props as T}
            className={cnClassName({}, [className])}
            disabled={disabled || isLoading}
        >
            {isLoading && <Spinner fontSize={spinnerSize} />}
            {children}
        </Component>
    }
}
export default withLoadSpin