import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import { setModerationFilters } from '../../redux/actions/LMSTeacherOffice'

///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    courseId?: string;
    hardFilters?: any;
    setModerationCid?: (cid: string) => void;
    allowFilters?: {
        Sessions: boolean
        Users: boolean,
        Status: boolean,
        Questions: boolean
    }
}

export interface IStateProps {
    // units: any
    // questions: any
    // answers: any
    // users: any
    // answersIds: string[]
    // isLoading: boolean,
    // isFinished: boolean
    filters: any;
    sessionsIds: any[];
    normalSessions: any;

    questionsIds: any[];
    questions: any;
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////


export const mapStateToProps = (state: any, props: IOwnProps) => ({
    filters: state.LMSTO.moderation.filters,
    
    sessionsIds: (props.courseId && state.LMSTO.courses.values[props.courseId] ? state.LMSTO.courses.values[props.courseId].activeSessions : []),
    normalSessions: state.LMSTO.sessions,

    questionsIds: Object.keys(state.LMSTO.moderation.questions),
    questions: state.LMSTO.moderation.questions,
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    // loadModerationAnswers
    setModerationFilters
}, dispatch);

////////////

export const cnClassName = cn('LMSTOModeration');