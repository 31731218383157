import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ILMSBadgeItem } from 'utils/src/requests/requests.lms'
///////////
export declare namespace StatisticsFull {
    export namespace GameListItem {
        export type IPresenterProps = IOwnProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            item: ILMSBadgeItem
        }

    }
}
////////////

type IOwnProps = StatisticsFull.GameListItem.IOwnProps

////////////

export const cnClassName = cn('LMSTOStatGameListItem');