import React, { FC, useEffect } from 'react'
import { cnCourseView, mapDispatchToProps, mapStateToProps } from './CourseView.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './CourseView.scss'
import { CourseInfo } from '../CourseInfo/CourseInfo'
import { AnswerList } from '../AnswerList/AnswerList'
import Moderation from '../Moderation'
import { Port } from '../Tabs/Port/Port'
import { SessionsList } from '../SessionsList/SessionsList'
import { StudentList } from '../StudentList/StudentList'
import { StudentResults } from '../StudentResults/Decorator'
import { Comments } from '../../LMS/Comments/Comments'
import { CourseStatistics } from '../Statistics/CourseStatistics/CourseStatistics'
import { CourseEditor } from '../CourseEditor/CourseEditor'
import LinearProgress from '@material-ui/core/LinearProgress';
import UnitsComments from '../Comments'

const CommentWithLayout: FC<any> = ({ newsUid, className }) => {
    return <div className={cnCourseView('Comments', [className])}>
        <div className={cnCourseView('Section')}>
            <div className={cnCourseView('Header')}>
                {/* <Search className={cnStudentList('Search')} /> */}
            </div>
            <Comments nid={newsUid} />
        </div>
    </div>
}

export const CourseViewPresenter: FC<any> = ({ className, match, course, loadCourseById, createSession }) => {
    useEffect(() => {
        (!course || course.source !== 'loadCourseById') && loadCourseById(match.params.id, !!course)
    }, [match.params.id])
    if (!course) return <div className={cnCourseView({}, ['Page', className])}>
        <LinearProgress className={cnCourseView('Progress')} />
    </div>

    const tabs = {
        answers: <Moderation courseId={course.id} />,
        sessions: <SessionsList cid={course.id} />,
        users: <StudentList cid={course.id} />,
        student: <StudentResults cid={course.id} />,
        default: <StudentList cid={course.id} />,
        discussion: <CommentWithLayout newsUid={course.newsUid} />,
        statistic: <CourseStatistics cid={course.id} />,
        edit: <CourseEditor cid={course.id} course={course} />,
        comments: <UnitsComments cid={course.id} course={course}/>
    }

    return <div className={cnCourseView({}, ['Page', className])}>
        <CourseInfo cid={course.id} />

        <Port tabs={tabs} />
    </div>
}

export const CourseView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CourseViewPresenter));
