// import { IClassNameProps } from '@bem-react/core'
import { cn } from '@bem-react/classname';
import { IDispatchProps, IStateProps } from '../../redux/connector';
import { RouteComponentProps } from 'react-router';
import { IStateType as IState } from 'redux/store';
import { setAuthUser, getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { changeUser } from 'utils/src/CommonRedux/users/actions';
import { rootTypeAction } from 'redux/actions'
import { updateContext, setContext } from 'redux/actions/Widgets';
import { loadCompanyPostTypes, getAllowGroupsTransfer } from 'redux/actions/AppSettings';
import { i18n_check_language_namespace, i18n_set_language } from 'utils/src/CommonRedux/i18n/actions';
import { loadVirtUsers } from 'utils/src/CommonRedux/users/actions';
import { getUserHieararchyFromServer } from "redux/sagas/Hierarchy/actions";
import { loadingSetStatus } from '../../redux/loading/actions';
import {
  initBegin,
  initCompleted,
  initFromCache,
  initFromCacheFail,
  initFromServer,
  layoutInit,
  layoutMount
} from 'utils/src/CommonRedux/LifeCycol/actions'
import {
  getLifeCycol
} from 'utils/src/CommonRedux/LifeCycol/selectors'
import { getContextById } from 'redux/sagas/context/selectors';
import { setFilters } from 'redux/search/actions';
/**
 * Global layout
 */

const qwe = () => { }

export interface ILayoutProps extends ILayoutStateProps, IDispatchProps, RouteComponentProps, ILayoutActionsToProps { }

export interface ILayoutState {
  placeholder?: string;
}

// export interface ILayoutActionsToProps { }

type ILayoutActionsToProps = typeof LayoutActionsToProps;

export const LayoutActionsToProps = {
  rootTypeAction,
  setAuthUser,
  changeUser,
  updateContext,
  setContext,
  // loadCompanyPostTypes,
  // loadVirtUsers,
  // getAllowGroupsTransfer,
  i18n_set_language,
  i18n_check_language_namespace,
  // getUserHieararchyFromServer,
  loadingSetStatus,

  /* life cycol */
  initBegin,
  initCompleted,
  initFromCache,
  initFromCacheFail,
  initFromServer,
  layoutInit,
  layoutMount,
  setFilters
}

export const mapStateToProps = (state: IState) => ({
  // lng: state.i18n.language,
  authUserNew: getAuthUser(state),
  auth: state.store.auth,
  edit: state.store.edit,
  loading: state.store.loading,
  homePage: state.store.appSettings?.homePage,
  analytics: state.store.appSettings && state.store.appSettings.analytics || {},
  lifeCycol: getLifeCycol(state),
  openedMenu: state.store.appDesign?.showMenuOpened,
  onPremisesMultitenancy: state.store.appSettings?.onPremisesMultitenancy,
  commonContext: getContextById('common')(state)
});

export type ILayoutStateProps = ReturnType<typeof mapStateToProps>;

export const cnLayout = cn('Layout');