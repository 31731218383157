import { IBaseNews, createDefaultNews } from 'News/types/baseNews'
export interface CreativeTask {
    title: string;
    endDateTime: string;
    fund: number;
    state: string;
}

export interface CreativeTaskNews extends IBaseNews {
    creativeTask: CreativeTask,
    newstype: 6,
    expires: string,
}


export type RewardReplyes = {
    [replyId: string]: number
};//Map<string, number>;

export const defaultDate = new Date();
// currentDate.setMonth((currentDate.getMonth() + 1))
defaultDate.setDate((defaultDate.getDate() + 14))

export const defaultCreativeTask: CreativeTask = {
    title: '',
    endDateTime: defaultDate.toISOString(),
    fund: 1,
    state: 'going',
}

export const defaultCreativeTaskNews: CreativeTaskNews = {
    ...createDefaultNews(),
    thanksCount: 1,
    creativeTask: defaultCreativeTask,
    newstype: 6,
    expires: defaultDate.toISOString(),
}