import { connect } from 'react-redux';
import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'src/utils/utils';
import { mapDispatchToProps, IDispatchProps } from "../../../redux/connector";
// import i18n from '../../localizations/i18n';
import { Bable } from '../../Bable/Bable';
import { mapStateToProps, IChatCounterStateProps, cnChatCounter, IChatCounterProps, IChatCounterState } from './ChatCounter.index';
import './ChatCounter.scss';


export class ChatCounterPresenter extends React.Component<IChatCounterProps, IChatCounterState> {
  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', children, className = '', count } = this.props;
    return (
      <TagName className={cnChatCounter({}, [customClassNames, className])}>
        {count !== 0 && <Bable>{count}</Bable>}
        {children}
      </TagName>
    )
  }
}


export const ChatCounter = connect<IChatCounterStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps({})
  )(compose(
  //withBemMod(cnChatCounter(), {})
)(ChatCounterPresenter))