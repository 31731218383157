import React, { FC, useEffect, useState } from 'react'
import { cnClassName, ITracksHeaderProps } from './TracksHeader.index'
import './TracksHeader.scss'
import { Button } from 'uielements/src'
import { ButtonBox } from 'uielements/src/ButtonBox/ButtonBox'
import lms_header from 'assets/jpg/lms_header.jpg';
import { tC } from 'LMSModule/utils/i18n.adaptor'
import { Avatar } from 'uielements/src/Avatar/Avatar';


export const TracksHeader: FC<ITracksHeaderProps> = ({ className, showSwitch = false, currentUrl = 'LearningTracks' }) => {

    return <div className={cnClassName({ }, [className])}>
        <div className={cnClassName('Section')}>
            <Avatar imgUrl={lms_header} size={64} className={cnClassName('Avatar')} />
            <div className={cnClassName('Info')}>
                <h3 className={cnClassName('Title')}>{tC('TracksHeader.title.1')}</h3>

                {showSwitch && <ButtonBox orientation={'horizontal'} className={cnClassName('Actions')}>
                    <Button
                        main={currentUrl === 'LearningTracks'}
                        type='rlink'
                        href={'/LearningTracks'}
                        title={tC('header.LearningTracks.2')} >{tC('header.LearningTracks.1')}</Button>
                    <Button
                        main={currentUrl === 'TracksManage'}
                        type='rlink'
                        href={'/TracksManage'}
                        title={tC('header.TracksManage.2')} >{tC('header.TracksManage.1')}</Button>
                </ButtonBox>}
            </div>
        </div>
    </div>
}

