import React, { FC } from 'react'
import { IActionsProps, cnActions, mapDispatchToProps, mapStateToProps } from './Actions.index'
import { Button } from 'uielements/src'
import './Actions.scss'
import { connect } from 'react-redux';
import { withLoad } from '../../../LMS/ButtonWithLoad/ButtonWithLoad'
import { tS } from 'LMSModule/utils/i18n.adaptor'

const ButtonL = withLoad(Button)

export const ActionsPresenter: FC<IActionsProps> = ({ 
    className, 
    newTestAttempts, 
    attemptAction, 
    sid, 
    unit, 
    hideAttempt, 
    isLastUnit = false,
    transitionToNextUnit
}) => {
    const next = () => transitionToNextUnit(unit.courseId)

    const useAttempt = () => attemptAction ? attemptAction() : newTestAttempts(unit.id, sid)
    return <div className={cnActions({}, [className])}>
        {!hideAttempt && <ButtonL
            className={cnActions('Btn', { type: 'again' })}
            onClick={useAttempt}
            theme={'unstyled_center'}>{tS('attempt.1')}</ButtonL>}
        {!attemptAction && <ButtonL
            className={cnActions('Btn', { type: 'complite' })}
            main
            onClick={next}>{isLastUnit ? tS('complete.4') : tS('complete.5')}</ButtonL>}
    </div>
}


export const Actions = connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionsPresenter);