import { defaultNews } from 'News/redux/reducer/default'
import { TReducerWithIdeas } from './reducer.d'
// import { TNewsReducerActions } from 'News/redux/actions/types'
import { TNewsReducerActions } from './actions.d'
import actions from './actionsType'
import { omit } from 'lodash'

const ideasDefaultNews: TReducerWithIdeas = {
    ...defaultNews,
}

export const ideasSubReducer = (state = ideasDefaultNews, action: TNewsReducerActions): TReducerWithIdeas => {
    switch (action.type) {

        default:
            return state;
    }
}