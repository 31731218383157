import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMSTO
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Masonry, Button, Icon } from 'uielements/src';
// import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
// import { SearchInput } from 'uielements/src';
// import queryString from 'query-string';
// import { CreateCourseDialog } from '../../../../dialogs/CreateCourse/CreateCourse'
// import ListPlaceholder from '../../../../components/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu';
import trackAddModal from '../../../../dialogs/AddCourseToTrack'
const LoadButton = withLoadSpin(Button)

type IPresenterProps = LMSTO.TOCoursesCardListItem.IPresenterProps
type IOwnProps = LMSTO.TOCoursesCardListItem.IOwnProps
type IDispatchProps = LMSTO.TOCoursesCardListItem.IDispatchProps
type IStateProps = LMSTO.TOCoursesCardListItem.IStateProps
type IState = LMSTO.TOCoursesCardListItem.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    course,
    addFile,
    learningtracks
}) => {
    const onAdd = () => {
        trackAddModal({ data: course })
            .then(result => {
                addFile({
                    tagId: result.tid,
                    type: 'LMSCources',
                    uid: result.cid
                })
            })
            .catch(e => {

            })
    }
    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('ImgBox')}>
            {learningtracks && <PopupMenu
                noFixed={true}
                noPadding={true}
                className={cnClassName('ActionsMenu')}
                btnRender={(toggleOpen: any, ref: any, isOpen: boolean) =>
                    <Button forwardedRef={ref} onClick={toggleOpen} className={cnClassName('ActionsToggle', { open: isOpen })} >
                        <Icon icon={'ellipsis-v'} className={cnClassName('ActionsIcon')} />
                    </Button>
                }
            >
                <ul className={cnClassName('ActionsList')}>
                    <li className={cnClassName('ActionsItem')}>
                        <Button theme={'unstyled'} onClick={onAdd}>
                            {tT('course_list.card.item.addtotrack.1')}
                        </Button>
                    </li>

                </ul>
            </PopupMenu>}
            <img src={course.largeImgUrl} className={cnClassName('Image')} />
        </div>
        <div className={cnClassName('Info')}>
            <h3 className={cnClassName('Title')}>{course.name}</h3>

            {/* <div className={cnItem('Section')}>
                <div className={cnItem('UnitCount')}>{courseunitCount} уроков</div>
                {session && <Deadlines session={session} className={cnItem('Deadlines')} />}
            </div>

            <Progress className={cnItem('Progress')} currentStep={course.courseCompletedUnits} totalSteps={course.unitsCount} />

            <AuthorsList authors={course.authors} className={cnItem('AuthorsList')} limit={5} /> */}
            {/* {course.authors.map((user: any) => <Admin className={cnItem('Admin')} admin={user} />)} */}
        </div>

        <div className={cnClassName('ActionBox')}>
            <Button className={cnClassName('Start')} href={`/TeacherOffice/Course/${course.id}`} type="rlink" main padding="md">
                {tT('course_list.card.item.open.1')}
            </Button>
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
