import { createContext, useContext } from "react";
import { GUID_EMPTY } from "utils/src/constants.prn";
import { getTasks } from "utils/src/requests/requests.tasks";


export const tasksContextDefaultValue: Pick<Awaited<ReturnType<typeof getTasks>>['data'], 'allowAdd' | 'columns'> & {
    userId: string;
} = {
    userId: GUID_EMPTY,
    allowAdd: false,
    columns: []
}
export const TasksContext = createContext(tasksContextDefaultValue);

export const useTasksListContext = () => {
    return useContext(TasksContext);
}