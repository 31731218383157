import { INoticesPropsType, mapStateToProps } from './Notices.index'
import React, { memo, FC } from 'react';
import { connect } from 'react-redux';
import './Notices.scss'
import { withNewsContext } from 'News/contexts/news'
import TextRender from 'News/containers/TextRender'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import ContentLayout from 'News/components/ContentLayout'


export const NoticesPresenter: FC<INoticesPropsType> = ({ }) => <NewsView className={'type-22'}>
    <ContentLayout>
        <TextRender />
    </ContentLayout>
</NewsView>

export const Notices = withNewsContext(connect(
    mapStateToProps
)(memo(NoticesPresenter)));

export const initNotices = () => {
    ComponentInjector.getInstance().addNode('news_types', <Notices />, 'notices')
}