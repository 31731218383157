import React, { FC } from 'react'
import { Skeleton } from 'uielements/src'
import './CourseHeader.scss'
import { cn } from '@bem-react/classname';
export const cnCourseHeader = cn('SkeletCourseHeader');


export const CourseHeader: FC<any> = ({ className }) => {
    return <div className={cnCourseHeader({}, [ className])}>
        <div className="CourseHeader Course-Header">
            <div className="CourseHeader-ImageBox">
                <Skeleton shape="circle" size={'xxxl'} />
                <div className="Action-Like LikeAction CourseHeader-Like">
                    <Skeleton shape="line" size={"lg"} style={{ width: '100px' }} />
                </div>
            </div>
            <div className="CourseHeader-DescBox">
                <div className="CourseHeader-Title">
                    <Skeleton shape="line" size={"lg"} style={{ width: '40%' }} />
                </div>
                <div className="CourseHeader-Description">
                    <Skeleton shape="line" size={"sm"} style={{ width: '80%' }} />
                    <Skeleton shape="line" size={"sm"} style={{ width: '60%' }} />
                    <Skeleton shape="line" size={"sm"} style={{ width: '70%' }} />
                </div>
                <div className="CourseHeader-Progress">
                    <div className="CourseProgress">
                        <div className="CourseProgress-Bar">
                            <Skeleton shape="line" size={"sm"} style={{ width: '100%' }} />
                        </div>
                    </div>
                </div>
                <div className="CourseHeader-Authors">
                    <div className="AuthorsList CourseHeader-AuthorsList">
                        <div className="AuthorsList-Wrapper">
                            <div className="AuthorItem AuthorsList-Author" style={{ zIndex: 0 }}>
                                <Skeleton shape="circle" size={'md'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

