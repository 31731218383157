import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    isExpand?: boolean,
    answersTexts?: string | string[],
    text: string,
    isRight?: boolean,
    isInfo?: boolean,
    isWait?: boolean
    helpText?: string | boolean
    children?: React.ReactNode
}

export const cnClassName = cn('ResultQuestionItem');