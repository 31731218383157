import CurrencyTooltip, { Presenter as CurrencyTooltipPresenter } from './CurrencyTooltip';
import {
    IOwnProps,
    IPresenterProps,
    cnCurrencyTooltip,
    ICollection,
    IItem
} from './CurrencyTooltip.index';


export {
    cnCurrencyTooltip,
    CurrencyTooltipPresenter
}

export type ICurrencyItem = IItem
export type ICurrencyCollection = ICollection
export type ICurrencyTooltipProps = IOwnProps;
export type ICurrencyTooltipPresenterProps = IPresenterProps;


export default CurrencyTooltip