import { connect } from 'react-redux';
import React, { useEffect, FC, ComponentType } from 'react';
import {
    loadNewsById
} from '../redux/actions'
import {
    getNewsById
} from '../redux/saga/selectors'
import { bindActionCreators, Dispatch, Action } from 'redux';

export const Preloader: FC<{}> = ({ }) => null

export interface INewsIdProps { id: string }

export function withNewsLoader<T extends INewsIdProps = INewsIdProps>(Component: ComponentType<T>, PreloaderComponent: ComponentType<{}> = Preloader) {

    type StateProps = ReturnType<typeof mapStateToProps>
    type DispatchProps = ReturnType<typeof mapDispatchToProps>
    type WrapperProps = T & StateProps & DispatchProps

    const Wrapper: FC<WrapperProps> = ({ news, loadNewsById, ...props }) => {
        useEffect(() => {
            if (!news) loadNewsById({ id: props.id })
        }, [props.id])
        if (!news) return <PreloaderComponent />
        return <Component {...props as any} />
    }

    const mapStateToProps = (state: any, { id }: T) => ({
        news: getNewsById(id)(state)
    })
    const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: T) => bindActionCreators({
        loadNewsById
    }, dispatch);

    return connect<StateProps, DispatchProps, T>(mapStateToProps, mapDispatchToProps)(Wrapper as any)
}


export default withNewsLoader