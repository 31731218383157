import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IChatToastProps extends IClassNameProps{
  tag?: 'div';
  msg: string;
  avatar: string;
  title: string;
}

export const cnChatToast = cn('ChatToast');