import React, { FC, memo } from 'react'
import {
    cnClassName,
    News
} from './interfaces'
import './style.scss'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Icon } from 'uielements/src/Icon/Icon';
import LoadButton from 'uielements/src/MaterialElements/ProgressButton'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Typography } from 'muicomponents/src'
import { CustomSettings } from 'utils/src';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { Box } from 'muicomponents/src'
const useMoexDesign = CustomSettings.useMoexDesign();

type IPresenterProps = News.TimelineHeader.IPresenterProps

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            minWidth: '32px',
            textAlign: 'center',
            borderRadius: '0',
            cursor: 'pointer',
            padding: '0'
        }
    }),
);


export const Presenter: FC<IPresenterProps> = ({
    className,
    value,
    children,
    isLoading,
    next,
    previous,
    disableNext,
    disablePrev,
    disableNavigation = false,
    totalCount,
    splitter = '/'
}) => {
    const classes = useStyles()
    return <div className={cnClassName({}, [className])}>
        {useMoexDesign ? <Tooltip title={children && children?.toString()?.length > 15 ? children?.toString() : ""}>
            <span>
                <Typography variant="subtitle1" sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }} className={cnClassName('Title')}>
                    {children}
                </Typography>
            </span>
        </Tooltip> :
            <div className={cnClassName('Title')}>
                {children}
            </div>
        }
        {!disableNavigation && <Box
            className={cnClassName('Navigation')}
            sx={{
                ".MuiButton-root:hover": {
                    background: "inherit"
                },
                ".MuiButton-root": {
                    minWidth: "24px",
                    width: "24px",
                    padding: "0px !important"
                }
            }}>
            <LoadButton disabled={disablePrev} className={cnClassName('Button', [classes.root])} onClick={previous}>
                {/* <Icon icon={'chevron-left'} /> */}
                <KeyboardArrowLeftIcon color="primary" />
            </LoadButton>
            <Box className={cnClassName('Indicator')} >
                {value}
                <span>{splitter}{totalCount}</span>
            </Box>
            <LoadButton disabled={disableNext} isLoading={isLoading} className={cnClassName('Button', [classes.root])} onClick={next}>
                {/* <Icon icon={'chevron-right'} /> */}
                <KeyboardArrowRightIcon color="primary" />
            </LoadButton>
        </Box>}
    </div >
}

export default memo(Presenter);
