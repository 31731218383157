import React from 'react';
import './Widget_type_shopMui.scss';
import { IWidgetProps } from '../Widget.index';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { cnWidget } from '../Widget.index';
import { ShopListMui } from 'blocks/ShopMui/ShopList/ShopListMui';
// import { FiltersTypeShopMui } from 'blocks/Filters/_type/Filters_type_shopMui/Filters_type_shopMui';
import { ShopMuiMiniWidget } from 'blocks/ShopMui/Widget/ShopMuiMiniWidget';

const type = 'shopMui';

export default class WidgetTypeQuestsPresenter extends WidgetPresenter<IWidgetProps> {

  public render() {
    const isMini = this.props.widget.settings?.mini
    if (!this.props.widget) return null;
    const tag = widgets.components.common
    const Tag = tag || 'div'
    return <Tag {...this.props} className={cnWidget({ type })} >
      {
        isMini ?
          <ShopMuiMiniWidget authUser={this.props.authUser} />
          :
          <>
            {/* <FiltersTypeShopMui /> */}
            <ShopListMui />
          </>
      }
    </Tag>
  }
}