import React, { useState, FC, useMemo, useEffect, SyntheticEvent, memo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getShopCharasteristics, setShopFilters } from 'redux/shop/actions';
import { IStateType } from 'redux/store';
import { CountBadgeStyles, FakeBtnBox, FilterBtnSkeleton, FilterBtnText, ItemBox, SearchMenuBox } from "../Filters_type_shopMui.styled";
import { CleaningServices as CleaningServicesIcon, FilterAlt as FilterAltIcon } from 'muicomponents/src/Icons';
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput';
import { Box, Button, Chip, TextField, TextFieldSelect, Typography } from 'muicomponents/src';
import { Menu } from 'muicomponents/src/Menu';
import MenuItem from '@mui/material/MenuItem';
import { i18n } from 'localization';
import { Characteristics } from 'utils/src/requests/models/api.shop';
import { Checkbox } from 'muicomponents/src/Checkbox';
import { IChosenCharacteristic } from 'redux/shop/interfaces';
import { CharItemBox, CharsBox, TagsBox } from './Filters.styled';
import PriceFilter from './PriceFilter';
import Badge from '@mui/material/Badge';
import { cnFiltersMui } from '../Filters_type_shopMui.index';
import ColorCharacteristic from 'blocks/ShopMui/Characteristics/valueTypes/Color/Color';
import { TValue } from 'utils/src';
import { useDidUpdateEffect, useReffedState } from 'utils/src/hooks';
import { Translate } from 'localizations/Translate';
import { xor, fromPairs, toPairs } from 'lodash';

export const Filters: FC = () => {

    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClickMenuBtn = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const chars = useSelector((s: IStateType) => s.shop.categoryProducts?.shopCharacteristics)

    useEffect(() => {
        !chars && dispatch(getShopCharasteristics())
    }, [dispatch])

    const chosen = useSelector((s: IStateType) => s.shop.chosenFilters)
    const chosenRef = useRef(chosen);
    chosenRef.current = chosen;
    const [localChosenChars, setLocalChosenChars, localChosenCharsRef] = useReffedState<{ [key: string]: { id: string, displayName: string, color: string, isDisabled: boolean, productsUsedCount?: number }[] }>(chosen.characteristics)

    const chooseChar = (char: IChosenCharacteristic) => {
        const newObj = { ...localChosenChars, [char.id]: char.tags }
        setLocalChosenChars(newObj)
    }

    const [MinPrice, setMinPrice, MinPriceRef] = useReffedState<number | undefined>(chosen.MinPrice)
    const [MaxPrice, setMaxPrice, MaxPriceRef] = useReffedState<number | undefined>(chosen.MaxPrice)
    const [localAllowToMeByPrice, setAllowToMeByPrice, localAllowToMeByPriceRef] = useReffedState(chosen.AllowToMeByPrice)
    
    const allowToMeByPriceCount = useSelector((s: IStateType) => s.shop.categoryProducts?.allowToMeByPriceCount)
    
    useEffect(() => {
        if (chosen.MinPrice === undefined) setMinPrice(undefined)
        if (chosen.MaxPrice === undefined) setMaxPrice(undefined)
        if (!chosen.AllowToMeByPrice) setAllowToMeByPrice(false)
    }, [chosen.MinPrice, chosen.MaxPrice, chosen.AllowToMeByPrice]);

    /**
     * set current filter state if setted value if not applied
     * @todo think by refactoring O(n^3)
     */
    useDidUpdateEffect(() => {
        if(open) return;
        const chosen = chosenRef.current.characteristics || {};
        const choosenMap = new Map<keyof typeof chosen, TValue<typeof chosen>>();
        Object.typedKeys(chosen).forEach(key => {
            choosenMap.set(key, chosen[key]);
        });
        if(
            // if chaged MaxPrice
            chosenRef.current.MaxPrice !== MaxPriceRef.current
            // if chaged MinPrice
            || chosenRef.current.MinPrice !== MinPriceRef.current
            // if chaged AllowToMeByPrice
            || chosenRef.current.AllowToMeByPrice !== localAllowToMeByPriceRef.current
            // if chaged change selected tags
            || Object.typedKeys(localChosenCharsRef.current).reduce((a, key) => {
                // pass true if found differece
                if(a) return a;
                const localChosenCharsItemIds = localChosenCharsRef.current[key].map(el2 => el2.id);
                if(
                    !choosenMap.has(key)
                    || choosenMap.get(key)?.length !== localChosenCharsRef.current[key].length
                    || !!choosenMap.get(key)?.filter(el => !localChosenCharsItemIds.includes(el.id)).length
                ) return true;
                return a;
            }, false)
        ) {
            setMinPrice(chosenRef.current.MaxPrice);
            setMaxPrice(chosenRef.current.MinPrice);
            setAllowToMeByPrice(chosenRef.current.AllowToMeByPrice);
            setLocalChosenChars(chosenRef.current.characteristics);
        }
    }, [open]);

    const handleClearCharFilters = useCallback(() => {
        setMinPrice(undefined);
        setMaxPrice(undefined);
        setAllowToMeByPrice(false);
        setLocalChosenChars({
            ...fromPairs(toPairs(localChosenCharsRef.current).map(([key, _]) => [key, []]))
        });
    }, []);

    const handleChangeCharFilters = useCallback(() => {
        dispatch(setShopFilters({
            key: 'characteristics',
            value: localChosenChars
        }))
        dispatch(setShopFilters({
            key: 'MaxPrice',
            value: MaxPrice
        }))
        dispatch(setShopFilters({
            key: 'MinPrice',
            value: MinPrice
        }))
        dispatch(setShopFilters({
            key: 'AllowToMeByPrice',
            value: localAllowToMeByPrice
        }))
        handleClose()
    }, [dispatch, localChosenChars, MaxPrice, MinPrice, localAllowToMeByPrice])

    const enableApplyButton = useMemo(() => {
        const chosen = chosenRef.current.characteristics || {};
        const choosenMap = new Map<keyof typeof chosen, TValue<typeof chosen>>();
        Object.typedKeys(chosen).forEach(key => {
            choosenMap.set(key, chosen[key]);
        });
        // if chaged MaxPrice
        return chosenRef.current.MaxPrice !== MaxPrice
            // if chaged MinPrice
            || chosenRef.current.MinPrice !== MinPrice
            // if chaged AllowToMeByPrice
            || chosenRef.current.AllowToMeByPrice !== localAllowToMeByPrice
            // if chaged change selected tags
            || Object.typedKeys(localChosenChars).reduce((a, key) => {
                // pass true if found differece
                if(a) return a;
                const localChosenCharsItemIds = localChosenChars[key].map(el2 => el2.id);
                if(
                    !choosenMap.has(key)
                    || choosenMap.get(key)?.length !== localChosenChars[key].length
                    || !!choosenMap.get(key)?.filter(el => !localChosenCharsItemIds.includes(el.id)).length
                ) return true;
                return a;
            }, false);
    }, [open, localChosenChars, MaxPrice, MinPrice, localAllowToMeByPrice]);

    const chosenAmount = useMemo(() => {
        let res = Object.keys(chosen.characteristics).reduce((acc, el) => acc + chosen.characteristics[el].length, 0)
        if (MinPrice !== undefined || MaxPrice !== undefined ) res ++
        if (localAllowToMeByPrice) res ++
        return res
    }, [chosen.characteristics, MinPrice, MaxPrice, localAllowToMeByPrice])

    const showCharacteristics = useMemo(() => {
        return !!chars?.length;
    }, [chars]);

    if (!chars) return <FilterBtnSkeleton />

    return <ItemBox>
        <Badge badgeContent={chosenAmount}
            sx={CountBadgeStyles}
            invisible={!chosenAmount || open}
            color='error'
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <Button
                id="open-filters-button"
                aria-controls={open ? 'open-filters-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                size='small'
                className={cnFiltersMui('filtersButton')}
                startIcon={<FilterAltIcon htmlColor='GrayText' sx={{ mr: 1, flexShrink: 1, }} />}
                onClick={handleClickMenuBtn} >
                
                <FakeBtnBox>
                    <FilterBtnText variant='body2' color='ActiveCaption' textTransform='none' noWrap >
                        {i18n.t("filters")}
                    </FilterBtnText>
                </FakeBtnBox>
            </Button>
        </Badge>

        <Menu open={open}
            anchorEl={anchorEl}
            id="open-filters-menu"
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'open-filters-button',
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            anchorReference='anchorEl'
            PaperProps={{ sx: { overflow: 'hidden', padding: '16px', width: '600px' } }} >

            <Box>

                <PriceFilter minPrice={MinPrice} setMinPrice={setMinPrice}
                    maxPrice={MaxPrice} setMaxPrice={setMaxPrice}
                    AllowToMeByPrice={localAllowToMeByPrice} setAllowToMeByPrice={setAllowToMeByPrice}
                    allowToMeByPriceCount={allowToMeByPriceCount}
                />

                {
                    showCharacteristics
                    &&
                    <>
                        <Typography variant='subtitle1'>{i18n.t('pryaniky.shop.tab.characteristics')}:</Typography>
                        <CharsBox>
                            {chars.map((char, i) => {
                                const isInChosen = char.id in localChosenChars
                                return <CharItem key={i} char={char}
                                    isChosen={isInChosen}
                                    chooseChar={chooseChar}
                                    initialTags={isInChosen ? localChosenChars[char.id] : undefined}
                                />
                            })}
                        </CharsBox>
                    </>
                }
            </Box>

            <Box sx={{ padding: 0, marginTop: '16px', display: 'flex', gap: '16px' }}>
                <Button
                    variant='outlined'
                    size='small'
                    onClick={handleClearCharFilters}
                    startIcon={<CleaningServicesIcon fontSize={'small'} />}
                >
                    <Translate i18nKey={'pryaniky.filter.feed.clear'} />
                </Button>
                <Button variant='contained'
                    disabled={!enableApplyButton}
                        // {!Object.keys(localChosenChars).length && !localAllowToMeByPrice && MinPrice === undefined && MaxPrice === undefined }
                    size='small'
                    onClick={handleChangeCharFilters} >
                    {i18n.t('pryaniky.filter.feed.use')}
                </Button>
            </Box>
        </Menu>
    </ItemBox>
}


interface ICharItemProps {
    char: Characteristics.IItem
    chooseChar: (arg: IChosenCharacteristic) => void
    isChosen: boolean
    initialTags?: { id: string, displayName: string, color: string, isDisabled: boolean, productsUsedCount?: number }[]
}

const CharItem: FC<ICharItemProps> = memo(({ char, chooseChar, isChosen, initialTags }: ICharItemProps) => {

    const initialTagsRef = useRef(initialTags);
    initialTagsRef.current = initialTags;

    const [selectOpen, setSelectOpen] = useState<boolean>()
    const [checkedTags, setCheckedTags, checkedTagsRef] = useReffedState<{ id: string, displayName: string, color: string, isDisabled: boolean, productsUsedCount?: number }[]>(initialTags || []);
    useDidUpdateEffect(() => {
        if(!!xor(initialTags?.map(el => el.id), checkedTagsRef.current.map(el => el.id)).length) {
            setCheckedTags(initialTags || []);
        }
    }, [initialTags]);
    const handleSetTags = (tagId: string, checked: boolean, displayName: string, color: string, isDisabled: boolean, productsUsedCount?: number) => {
        if (!checked) {
            const tagIsSelected = checkedTags.map(el => el.id).includes(tagId);
            if(tagIsSelected) {
                setCheckedTags(checkedTags.filter(tag => tag.id !== tagId));
            } else {
                setCheckedTags([...checkedTags, { id: tagId, displayName, color, isDisabled, productsUsedCount }]);
            }
        } else {
            const newArr = checkedTags.filter(tag => tag.id !== tagId);
            setCheckedTags(newArr);
            chooseChar({ id: char.id, tags: newArr });
        }
    }

    const enableApplyButton = useMemo(() => {
        const checkedIds = checkedTags.map(el => el.id);
        const initialIds = initialTagsRef.current?.map(el => el.id) || [];
        return checkedTags.length !== initialIds.length
            || !!checkedIds.filter(id => !initialIds.includes(id)).length
    }, [selectOpen, checkedTags]);

    useDidUpdateEffect(() => {
        const checkedIds = checkedTags.map(el => el.id);
        const initialIds = initialTagsRef.current?.map(el => el.id) || [];
        if(checkedTags.length !== initialIds.length
            || !!checkedIds.filter(id => !initialIds.includes(id)).length)
            setCheckedTags(initialTagsRef.current || []);
    }, [selectOpen]);

    const [searchTagValue, setSearchTagValue] = useState('')
    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTagValue(e.currentTarget.value)
    }

    const handleClose = (e: SyntheticEvent) => {
        e.stopPropagation()
        chooseChar({ id: char.id, tags: checkedTags });
        setSelectOpen(false)
    }

    const searchedTags = useMemo(() => {
        let filteredTags = []
        if (!searchTagValue) return char.tags
        else {
            filteredTags = char.tags.filter(tag => tag.displayName.toLowerCase().indexOf(searchTagValue.toLowerCase()) !== -1)
        }
        return filteredTags
    }, [searchTagValue, char.tags]);

    const isColor = useMemo(() => {
        return char.type === 'ShopCharacteristicsColor';
    }, [char]);

    if (!char.tags.length) return null

    return <CharItemBox>

        <TextField
            fullWidth
            select
            onClick={() => setSelectOpen(!selectOpen)}
            SelectProps={{
                SelectDisplayProps: {
                    style: { width: '110%', position: 'relative', left: '-20px', /* border: '2px solid green' */ }
                },
                MenuProps: {
                    open: selectOpen,
                    onClose: () => setSelectOpen(false),
                }
            }}
            label={char.displayName}
            focused={selectOpen}
            suggesterValue={
                !!checkedTags.length
                ?
                <Box sx={{ position: 'absolute', zIndex: 2, }}>
                    {
                        checkedTags.map(tag => (
                            <Chip key={tag.id}
                                sx={{ marginRight: '8px', }}
                                label={
                                    isColor
                                    ? <ColorCharacteristic value={tag} size={'small'} />
                                    : tag.displayName
                                }
                                onDelete={() => handleSetTags(tag.id, true, tag.displayName, tag.color, tag.isDisabled, tag.productsUsedCount)}
                            />
                        ))
                    }
                </Box>
                : undefined
            }
        >
            {char.tags.length > 8 &&
                <SearchMenuBox onClick={(e) => e.stopPropagation()}>
                    <SearchInput value={searchTagValue} onChange={onSearchChange} />
                </SearchMenuBox>
            }

            <TagsBox>
                {searchedTags.map((tag) => {
                    const isChecked = Object.values(checkedTags).findIndex(elem => elem.id === tag.id) !== -1
                    return <MenuItem key={tag.id} sx={{ padding: 0 }}
                        onClick={(e) => {
                            e.stopPropagation()
                            handleSetTags(tag.id, false, tag.displayName, tag.color, tag.isDisabled, tag.productsUsedCount)
                        }
                        }>
                        <Checkbox checked={isChecked} />
                        {
                            isColor
                            ? <ColorCharacteristic value={tag} size={'small'} />
                            : tag.displayName
                        }
                        {
                            !!tag.productsUsedCount &&
                            <>
                                &nbsp;
                                <Typography color='GrayText'>{tag.productsUsedCount}</Typography>
                            </>
                        }
                    </MenuItem>
                })}
            </TagsBox>
            <MenuItem>
                <Button variant='contained' size='small' onClick={handleClose} disabled={!enableApplyButton} >
                    {i18n.t('pryaniky.filter.feed.use')}
                </Button>
            </MenuItem>
        </TextField>

    </CharItemBox>
})