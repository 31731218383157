import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    isLoading?: boolean;
    search: string;
    onChange: (value: string) => void
}

export interface IStateProps {
    
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IOwnProps) => ({
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({

}, dispatch);

////////////

export const cnClassName = cn('TOTrackSearch');