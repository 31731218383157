// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import { StickyTable } from 'uielements/src/StickyTable/StickyTable'
import { Checkbox } from 'uielements/src'
import { Attachments } from 'uielements/src/Attachments/Attachments'
import { PryanikyVideo } from 'uielements/src/PryanikyVideo/PryanikyVideo'
import { Preview } from 'uielements/src/Preview/Preview'
export const Warning: React.FC<any> = ({ children, titleText }) => {
    return (
        <div className={'Warning'}>
            <div className={'Warning-Title'}>{titleText}</div>
            <div className={'Warning-Message'}>{children}</div>
        </div>
    )
}

const ItemRender: React.FC<any> = ({ tag: Tag, ...props }) => <Tag {...props} />

const HtmlRender: React.FC<any> = ({ tag: Tag, html, ...props }) => <Tag {...props} dangerouslySetInnerHTML={{ __html: html }} />

const BlockRender: React.FC<any> = ({ tag: Tag, children, ...props }) => <Tag {...props} >{children}</Tag>

export const converter = (json: any) => {
    let jsx: React.ReactNodeArray = [];
    json.blocks.forEach((block: any) => {
        switch (block.type) {
            case 'header':
                jsx.push(<HtmlRender tag={`h${block.data.level}`} className={`Wiki-Header_${block.data.level}`} html={block.data.text} />);
                break;
            case 'paragraph':
                jsx.push(<HtmlRender tag={`p`} className={`Wiki-Paragraph`} html={block.data.text} />);
                break;
            case 'delimiter':
                jsx.push(<ItemRender tag={`hr`} className={'Wiki-Delimiter'} />);
                break;
            case 'image':
                jsx.push(<ItemRender className={'Wiki-Img'} tag={`img`} src={block.data.file.url} title={block.data.caption} />);
                break;
            case 'list':
                const listType = block.data.style === 'ordered' ? `ol` : `ul`;
                jsx.push(
                    <BlockRender tag={listType} className={`Wiki-List_${listType}`}>
                        {block.data.items.map((value: string) => <HtmlRender tag={`li`} className={`Wiki-ListItem`} html={value} />)}
                    </BlockRender>
                );
                break;
            case "table":
                const preperedTableContent = block.data.content.map((row: any) => ({ cells: row.map((cell: any) => cell.replace(/<[^>]*>?/gm, '')) }))
                jsx.push(<StickyTable rows={preperedTableContent} columnsHeaders={[]} rowsHeaders={[]} />);
                break;
            case 'link':
                jsx.push(<BlockRender className={`Wiki-Link`} tag={`a`} href={block.data.link}>{block.data.link}</BlockRender>);
                break;
            case 'embed':
                if (block.data.service === 'youtube' || block.data.service === "vimeo") {
                    jsx.push(<PryanikyVideo type={block.data.service} dataUrl={block.data.embed} previewUrl={block.data.source} />)
                } else jsx.push(<BlockRender className={`Wiki-Embed`} tag={`iframe`} src={block.data.source} width={block.data.width} height={block.data.height} ></BlockRender>);
                break;
            case 'quote':
                jsx.push(<BlockRender className={`Wiki-Blockquote`} tag={`blockquote`}>{block.data.text}</BlockRender>);
                break;
            case 'checklist':
                jsx.push(
                    <BlockRender tag={`ul`} className={`Wiki-List_checklist`}>
                        {block.data.items.map((value: any) => <BlockRender className={`Wiki-ListItem`} tag={`li`}>
                            <Checkbox className={`Wiki-Checkbox`} checked={value.checked} /> {value.text}
                        </BlockRender>)}
                    </BlockRender>
                );
                break;
            case 'raw':
                jsx.push(<BlockRender className={`Wiki-Code`} tag={`code`}>{block.data.html}</BlockRender>);
                break;
            case 'youtube':
                jsx.push(<PryanikyVideo type={block.data.type} dataUrl={block.data.dataUrl} previewUrl={block.data.previewUrl} />);
                break;
            case 'file':

                if (!block.data.file) jsx.push(<div>wrong file!</div>);
                else jsx.push(<Attachments disabledType={[]} state={'view'} files={[block.data.file]} onDelete={() => { }} />);
                break;
            case 'video':

                if (!block.data.file) jsx.push(<div>wrong file!</div>);
                else jsx.push(<Preview files={[block.data.file]} />);
                break;
            case 'warning':
                jsx.push(<Warning titleText={block.data.title}>{block.data.message}</Warning>)
                break;
            default:
                console.log('Unknown block type', block.type);
                break;
        }
        // document.getElementById('content').innerHTML = html;
    });
    return jsx;
}