import { INewsPropsType, mapStateToProps, TStateProps } from './News.index'
import React, { FC, memo } from 'react';
import { connect } from 'react-redux';
import './News.scss'
import { withNewsContext } from 'News/contexts/news'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import TextRender from 'News/containers/TextRender'
import ContentLayout from 'News/components/ContentLayout'


export const NewsPresenter: FC<INewsPropsType & TStateProps> = ({ }) => {
    return <NewsView className={'type-5'}>
        <ContentLayout>
            <TextRender />
        </ContentLayout>
    </NewsView>
}

export const News = withNewsContext<INewsPropsType>(connect(
    mapStateToProps
)(memo(NewsPresenter)), ['newsId']);


export const initNews = () => {
    ComponentInjector.getInstance().addNode('news_types', <News />, 'news')
    ComponentInjector.getInstance().addNode('news_types', <News />, 'quizresult')
}