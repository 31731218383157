import Component, { Presenter } from './Value';
import { Shop } from './Value.interfaces';


export { Presenter }

export type IComponentProps = Shop.CharacteristicsValue.IOwnProps;
export type IComponentPresenterProps = Shop.CharacteristicsValue.IPresenterProps;


export default Component