import * as React from 'react';
import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';

import { withBemMod } from '@bem-react/core';
import { ListItemSkeleton } from 'blocks/Skeletons/Lists/ListItem-Skeleton'
import { cnBody } from '../Modal-Body.index';
import { IBodyTypeGroupListProps, IBodyTypeGroupListState } from './Modal-Body_type_groupList.index';
import './Modal-Body_type_groupList.scss';
import { Button, CheckboxInput, Radio, SearchInput, Avatar } from 'uielements/src';


export const withBodyTypeGroupList = withBemMod<any>(
    cnBody(),
    { type: 'groupList' },
    (Presenter) => (
        class extends React.Component<IBodyTypeGroupListProps, IBodyTypeGroupListState> {
            public el: HTMLElement | null;
            private query: string = '';

            constructor(props: IBodyTypeGroupListProps) {
                super(props);
                this.props.parent.state.data.selectedGroup = this.props.parent.props.group || { pkid: -1, name: 'Основная лента' };
                this.state = {
                    groups: [],
                    isLoading: false,
                    query: '',
                    loadFull: false
                }
            }

            public componentDidMount(): void {
                this.loadMore();
            }

            public render() {
                const { groups } = this.state;
                const selectedGroup = this.props.parent.state.data.selectedGroup;
                const avatarStyle = (e: any) => {
                    return {
                        backgroundColor: '#' + i18n.t('name_colors.' + e.name.substr(0, 1).toUpperCase())
                    }
                };
                const avatarChar = (e: any) => e.name.substr(0, 1).toUpperCase();
                return (
                    <div className={cnBody({}, this.props.className && this.props.className.split(' '))}>
                        <div className={'Modal-Body_type_groupList-Search-Box'}>
                            <SearchInput onChange={this.onSubmit} />
                        </div>

                        <div className={'Modal-Body_type_groupList-Data-Wrap'}>
                            <ul className={'Modal-Body_type_groupList-List'}>
                                <li onClick={this.onGroupClick.bind(this, { pkid: -1, name: 'Основная лента' })}
                                    className={'Modal-Body_type_groupList-List-Item' + (selectedGroup.pkid === -1 ? ' Modal-Body_type_groupList-List-Item_selected' : '')}
                                >
                                    <Radio checked={selectedGroup.pkid === -1} />
                                    <div className='Modal-Body_type_groupList-List-Item-Data'>Переместить в основную ленту</div>
                                </li>
                                {
                                    groups.map((e: any) => {
                                        const isSelected = selectedGroup.pkid === e.pkid;
                                        return (<li onClick={this.onGroupClick.bind(this, e)}
                                            className={'Modal-Body_type_groupList-List-Item' + (isSelected ? ' Modal-Body_type_groupList-List-Item_selected' : '')}
                                            key={e.pkid}>
                                            <Radio checked={isSelected} onClick={(ev) => { this.onGroupClick.bind(this, e);  ev.preventDefault(); ev.stopPropagation()}}/>
                                            <div className='Modal-Body_type_groupList-List-Item-Img'>
                                                <Avatar size={48} imgId={e.imgId48x48} imgUrl={e.imgUrl48x48} name={e.name} />
                                            </div>
                                            <div className='Modal-Body_type_groupList-List-Item-Data'>
                                                <Button theme={'unstyled'} className='Modal-Body_type_groupList-List-Item-Data-Name'>{e.name}</Button>
                                            </div>

                                        </li>)
                                    })
                                }
                            </ul>
                            {
                                this.state.isLoading ? <div>
                                    {(new Array(15)).fill(0).map(() => <ListItemSkeleton />)}
                                </div> :
                                    !this.state.loadFull &&
                                    <Button onClick={this.loadMore} className={'Modal-Body_type_groupList-Button_more'}>{i18n.t('more')}</Button>
                            }
                        </div>
                    </div>
                )
            }

            private onGroupClick = (e: any) => {
                this.props.parent.setState((p: any) => {
                    if (p.data.selectedGroup.pkid !== e.pkid) {
                        p.data.selectedGroup = e;
                    } else {
                        p.data.selectedGroup = { pkid: -1 };
                    }
                    return p;
                }, () => this.setState({}));
            };

            private onSubmit = (value: string) => {
                this.query = value;
                this.setState({ groups: [] }, () => { this.loadMore() })
            };

            private onChange = (value: string) => {
                //
            };

            private loadMore = () => {
                if (this.state.isLoading) return false;

                this.setState({ isLoading: true });

                const opt: any = {};
                opt.search = this.query;
                opt.excludeSystemGroups = true
                // if (this.props.parent.props.selected) opt.excludeGroup = this.props.parent.props.selected.gid;
                utils.API.groups.list(this.state.groups.length, 20, opt)
                    .r
                    .then((d: any) => {
                        if (d.error_code === 0 && d.data !== null) {
                            this.setState((s: IBodyTypeGroupListState) => {
                                s.groups = [...s.groups, ...d.data];
                                return s;
                            });
                        }
                        this.setState({
                            isLoading: false,
                            loadFull: d.isFinished
                        });
                    });
                return true;
            };
        }
    )
);
