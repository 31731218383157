import React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget, IWidgetProps } from '../Widget.index';
import './Widget_type_journal.scss';
import WidgetPresenter from '../Widget';
import { widgets } from "../../../i.widgets";
import { Journal } from '../../Journal/Journal';
import { IWidgetTypeJournalProps } from './Widget_type_journal.index'

const type = 'journal';

const types = {
    common: require('blocks/Journal/Journal').Journal,
}

export default class WidgetTypeJournalPresenter extends WidgetPresenter<IWidgetTypeJournalProps> {
   
    public render() {
        if (!this.props.widget) return null;
        const subtype = utils.widgetSubtype(this.props.widget.type);
        const context = { ...this.props.wcontext, wId: this.props.widget.id, ...this.props.widget.settings };
        const Component = this.getComponent(types);
        return <Component {...this.props} className={cnWidget({ type, subtype })} context={context} tag={widgets.components.common} />
    }


}