/**
 * проверяет, есть ли пользователь в массиве
 * 
 * @param arrayOfUsers - aмассив с пользователями
 * @param userId - ид пользователя
 * @returns boolean
 */
export const userInArray = (arrayOfUsers: any[], userId: string) => !arrayOfUsers.find((u: any) => u.id === userId);


/**
 * добавляет пользователя если его нету, а если есть то удаляет
 * 
 * @param arrayOfUsers - массив с пользователями
 * @param user - необходимый пользователь
 * @returns - новый массив
 */
export const toggleUserInArray = (arrayOfUsers: any[], user: any) =>
    userInArray(arrayOfUsers, user.id) ? addUserToArray(arrayOfUsers, user) : removeUserFromArray(arrayOfUsers, user);


/**
 * добавляет пользователя в массив, если его там нет. Иначе вернёт тот же массив
 * 
 * @param arrayOfUsers - массив, в который нужно добавить пользователя
 * @param user - пользователь
 * @returns - вернёт новый массив с добавленым в начало пользователем. Если пользователь уже есть, то всё равно вернёт новый массив с элементами старого
 */
export const addUserToArray = (arrayOfUsers: any[], user: any) => userInArray(arrayOfUsers, user.id) ? [user, ...arrayOfUsers] : [...arrayOfUsers];


/**
 * удаляет пользователя из массива
 * 
 * @param arrayOfUsers - массив из которого нужно удалить
 * @param user - пользователь
 * @returns - новый массив, без указаного пользователя. Если пользователя небыло, то массив всё равно будет новый
 */
export const removeUserFromArray = (arrayOfUsers: any[], user: any) => arrayOfUsers.filter((u: any) => u.id !== user.id)


