import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import React, { useEffect } from 'react';
import { mapDispatchToProps } from '../../redux/connector';
import { useClickOutside } from 'utils/src/hooks';
import i18n from 'localizations/i18n';
import { Transition } from 'react-transition-group';

import { cnCountSlider, ICountSliderProps, mapStateToProps } from './CountSlider.index';
import { Icon } from 'uielements/src/Icon/Icon';
import { ThanksInput } from 'blocks/PryanikyForms/ThanksInput/ThanksInput'
import './CountSlider.scss';
import { withCountSliderTypeModal } from './_type/CountSlider_type_modal';
import { Translate } from 'localizations/Translate';

export const CountSliderPresenter: React.FC<ICountSliderProps> = ({
  icon = 'give',
  onChange,
  className,
  thanksForAll,
  maxCount = thanksForAll,
  minValue = 1,
  currencyName,
  currencyNamePlural,
  children,
  value,
  type,
}) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [focused, setFocused] = React.useState(false);

  useClickOutside(wrapperRef, () => setFocused(false));

  useEffect(() => {
    document.addEventListener('keydown', blurOnEsc);
    return () => {
      document.removeEventListener('keydown', blurOnEsc);
    };
  }, []);

  const blurOnEsc = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      setFocused(false);
      inputRef.current && inputRef.current.blur();
    }
  };

  const [inputWidth, setInputWidth] = React.useState(value.toString().length + 1);

  const focusOnClick = () => {
    if (inputRef.current) inputRef.current.focus();
    setFocused(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
   //const handleChange = (value: number) => {
    let count = e.currentTarget.value;
    //let count = value;
    if (+count > maxCount) count = maxCount.toString();
    // setInputWidth(count.length + 1);
    onChange(Number.parseInt(count));
    //onChange(count);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (+e.currentTarget.value < minValue) onChange(minValue);
  };

  return (
    <div ref={wrapperRef} onClick={focusOnClick} className={cnCountSlider({}, [className])}>
      {minValue > maxCount ? (
        <div className={cnCountSlider('NotEnought')}>
          <Translate i18nKey={'pryaniky.not.enought.currency'} />
        </div>
      ) : (
          <>
            <div className={cnCountSlider('Inner')}>
              <ThanksInput 
              min={minValue} 
              max={maxCount} 
              value={value} 
              onChange={onChange} 
              currencyNamePlural={currencyNamePlural} 
              currencyName={currencyName} 
              icon={<Icon icon={icon} className={cnCountSlider('Icon')} />}
              />
            </div>
            <Transition in={focused || type === 'modal'} unmountOnExit timeout={300}>
              {state => (
                <div className={cnCountSlider('Range', { [state]: true, type })}>
                  <b className={cnCountSlider('Min', { active: value >= minValue })}>{value}</b>
                  <input
                    type="range"
                    className={cnCountSlider('RangeInput')}
                    min={minValue}
                    max={maxCount}
                    onChange={handleChange}
                    value={value}
                  />
                  <b className={cnCountSlider('Max', { active: value >= maxCount })}>{maxCount}</b>
                </div>
              )}
            </Transition>
          </>
        )}
      {children}
    </div>
  );
};

export const CountSlider = connect(
  mapStateToProps,
  mapDispatchToProps({})
)(compose(withCountSliderTypeModal)(CountSliderPresenter));
