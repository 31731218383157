import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { loadCourseStudents, loadUsersInCourse } from '../../redux/actions/LMSTeacherOffice'

export interface IStudentListProps extends IClassNameProps, IStudentListStateProps, IDispatchProps, RouteComponentProps, IStudentListDispatchProps {
    tag?: 'div';
}

export interface IStudentListState {

}

export interface IStudentListStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    studentesIds: state.LMSTO.students.ids,
    isFinished: state.LMSTO.students.isFinished,
    isLoading: state.LMSTO.students.isLoading,
    filters: state.LMSTO.students.filters,
    course: state.LMSTO.courses.values[props.cid],
    // units: state.LMSTO.moderation.units,
    // ids: state.LMSTO.moderation.results
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadCourseStudents,
    loadUsersInCourse
}, dispatch);

export type IStudentListDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnStudentList = cn('TOStudentList');