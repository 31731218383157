import React, { FC, useEffect, useState, useCallback, useRef, useMemo, MutableRefObject, useLayoutEffect } from 'react'


export const resizeObserverHook =(children: any[]) =>{
    const minItemWidth = 230;
    if (!children) return null;
    const ref = useRef<HTMLDivElement>();

    const [page, setPage] = useState(0);
    const pageRef = useRef(page);
    pageRef.current = page;
    console.log("ref.current", ref.current)
    const [count, setCount] = useState(children.length);
    const countRef = useRef(count);
    countRef.current = count;

    const calculateItemsCount = useCallback(() => {
        console.log("RUN");
        if (!ref.current) return;
        const gap = Number(
            window.getComputedStyle(ref.current).gap.replace("px", "")
        );
        let currentItemsMinWidth = ref.current.clientWidth;
        for (let item of Array.from(ref.current.children)) {
            if (currentItemsMinWidth > item.clientWidth) {
                currentItemsMinWidth = item.clientWidth;
            }
        }
        const itemsForView = Math.floor(ref.current.clientWidth / minItemWidth);
        if (countRef.current !== itemsForView) {
            setCount(itemsForView);
            if (itemsForView >= children.length) {
                setPage(0);
            } else if (pageRef.current > children.length - itemsForView) {
                setPage(children.length - itemsForView);
            }
        }
    }, [children]);

    const observer = useMemo(() => {
        return new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === ref.current) {
                    calculateItemsCount();
                }
            }
        });
    }, [children]);

    useEffect(() => {
        calculateItemsCount();
        if (ref.current) {
            observer.observe(ref.current!);
        }

        return () => {
            // observer.unobserve(ref.current!);
        };
    }, [ref.current, children]);

    const prepairedArr = useMemo(() => {
        return children.slice(0 + page, count + page);
    }, [count, page, children]);
    console.log("prepairedArr", prepairedArr)

    const backBlock = useMemo(() => {
        return 0 === page;
    }, [count, page]);

    const forwardBlock = useMemo(() => {
        return count + page === children.length;
    }, [count, page, children]);

    useEffect(() => {
        console.log("count", count);
    }, [count, children]);

    return {
        count,
        page,
        prepairedArr,
        backBlock,
        forwardBlock,
        ref
    }
}