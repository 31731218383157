import React from "react";
import { cnWidget, IWidgetProps } from "../Widget.index";
import * as utils from "utils/src/utils";
import WidgetPresenter from "../Widget";
import { widgets } from "../../../../src/i.widgets";
import { MindMap } from "../../MindMap/MindMap";



const type = "mindMap";

export default class MindMapPresenter extends WidgetPresenter<IWidgetProps> {
  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const saveData = (d: any) => {
      this.props.widget.data = d
      this.props.updateWidget({
        ...this.props.widget,
        // HTMLWidgetContentEdit: false
      });
    }
    const saveWidgetData = () => { this.props.setStructureFromWidget(this.props.widget.id) }
    // const Component = this.getComponent(types);
    return (
      <MindMap
        {...this.props}
        className={cnWidget({ type, subtype })}
        contexts={this.relations.concat(this.props.widget.id)}
        // saveWidgetData={saveWidgetData}
        // updateWidget={this.props.updateWidget}
        onChange={saveData}
        saveData={saveWidgetData}
        data={this.props.widget.data || undefined}
        tag={widgets.components.common}
      />
    );
  }
}
