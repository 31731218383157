import React, { FC } from 'react';
import { IEditorProps } from './Editor.index'
import './Editor.scss'
// import { Button, Input, Icon, Dialog, DialogTitle, CustomScrollbars, DialogContent, CheckboxInput } from 'uielements/src';
import { ReasonSelector } from 'blocks/PryanikyForms/ReasonSelector/ReasonSelector'
import { ThanksInput2 } from 'blocks/PryanikyForms/ThanksInput2/ThanksInput2'
import { ThanksCounter } from 'blocks/PryanikyForms/ThanksCounter/ThanksCounter'
// import { ActionButtons } from 'blocks/ActionButtons/ActionButtons'
import { PryanikyEditorContexted } from 'blocks/PryanikyEditor/_PryanikyEditor'
import i18n from 'localizations/i18n';
import { getReasons } from '../../Thanks/Thanks.sides';

export const Editor: FC<IEditorProps & any> = ({
    reasons: selectedReasons,
    onChangeReason,
    // maxThanks,
    // minThanks,
    onChangeThanks,
    // thanksCount,
    // usersCount,
    validation,
    onChangeText,
    text,
    // users,
    // validMsg = [],
    // maxThanksValue = 0,
    maxThanksArgumentsCount = 0,
    // thanksForAll = 0


    disableDown = false,
    disableUp = false,
    thanksCount = 0,
    available = 0,
    // fullCount = 0,
    fullCurrency = 0,
    // maxPerUser = 0,
    // maxThanks = 0,
}) => {
    // const [showDialog, setShowDialog] = React.useState(false);
    const [reason, setReasons] = React.useState<any[]>([])

    React.useEffect(() => {
        if (reason.length < 1) {
            getReasons((data) => {
                /** если происходит первая загрузка ценностей и ценность всего одна то сразу ее выбираем */
                onChangeReason && data.length === 1 && (data[0] as any).options.length === 1 && onChangeReason((data[0] as any).options);
                setReasons(data);
            });
        }
    }, []);

    // const onCloseDialog = () => setShowDialog(false);
    // const openDialog = () => setShowDialog(true);
    // const maxThanks = users.length === 0 ? CURRENT_USER_THANKS : (users.length * MAX_THANKS_FOR_USER) > CURRENT_USER_THANKS ? CURRENT_USER_THANKS : (users.length * MAX_THANKS_FOR_USER);
    // const maxThanks = users.length === 0 ? thanksForAll : (users.length * maxThanksValue) > thanksForAll ? thanksForAll : (users.length * maxThanksValue);

    
    // const minThanks = users.length;
    // const usersCount = users.length;

    // const thankPerUser = usersCount === 0 ? thanksCount : thanksCount / usersCount;
    // const minPerUser = usersCount === 0 ? 1 : minThanks / usersCount;
    // const maxPerUser = usersCount === 0 ? maxThanksValue : maxThanks / usersCount;
    // const disableUp = (thankPerUser >= maxPerUser) || ((maxThanks - thanksCount) < usersCount);
    // const disableDown = thankPerUser <= minPerUser;
    // const available = usersCount === 0 ? thanksForAll : maxThanks - thanksCount;

    const maxReasonsCount = maxThanksArgumentsCount <= selectedReasons.length;
    // const validation = validMsg.filter((value: any) => (value.field === 'rewardReasons') || (value.field === 'thanksCount'));

    /*const onChangeItem = (item: any) => () => {
        onChangeReason(toggleItemInArray(selectedReasons, item))
    }*/
    // const onChange = (value: number) => {
    //     // const value = e.target.value;
    //     // if (value < minPerUser) return onChangeThanks(0);
    //     // if (value >= maxPerUser) return onChangeThanks(maxPerUser);
    //     onChangeThanks(Math.ceil(value) * (usersCount === 0 ? 1 : usersCount))
    // }
    const textValidation = validation.find((val: any) => val.field === 'text' && val.method === 'STR_MIN')
    return (
        <div className={'ThanksUserEditor'}>

            <ThanksInput2 onChange={onChangeThanks} disableDown={disableDown} disableUp={disableUp} thankPerUser={thanksCount} />

            <ThanksCounter thanksForAll={fullCurrency} thanksCount={thanksCount} available={available} />

            <PryanikyEditorContexted
                // getClassRef={refGetter}
                //ref={forwardRef as any}
                // users={true}
                // tags={true}
                onChange={onChangeText}
                // className={''}
                // icon={'edit'}
                value={text}
                disableToolbar={true}
                placeholder={i18n.t('pryaniky.createPost.thanks.text')}
            />
            {textValidation && text &&
                <div className={'EditorThanks-MinLength'}>{i18n.t('pryaniky.createPost.thanks.MinLength', { need: textValidation.value, current: textValidation.value - text.getCurrentContent().getPlainText().length })}</div>
            }
            <ReasonSelector maxReasonsCount={maxReasonsCount} onChange={onChangeReason} selectedReasons={selectedReasons} reasons={reason} />

            {/* <ActionButtons
                className={"ThanksSelector-Actions"}
                // saveDisabled={validation.length !== 0} 
                save={onCloseDialog}
                saveText={'Отправить'}
                cancle={onCloseDialog}
                cancleText={'Отменить'}
            /> */}
        </div>
    )
}