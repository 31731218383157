import React, { useState, useEffect, useRef, useMemo, FC } from 'react';
import { IFiltersProps, IFiltersState } from "../Filters.index";
import { Translate } from 'localizations/Translate';
import { defaultTimelineFilter as filterAll, myTimelineFilter } from 'utils/src/constants.prn'
import { IBaseTimlineFilterResponse, ITimlineFilterIncommingData } from 'utils/src/requests/models/admin.baseAdmin'
import * as utils from 'utils/src/utils';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { useSelector } from 'react-redux';

export interface IFilterItem {
    displayName: string
    enable: boolean
    id: string
    moduleEnable: boolean
    position: number
}

export interface IItemsViewDat {
    isAdmin: any;
    isSubnetworks: boolean | null;
    enableAutoRegistration: boolean;
}

export interface ITimlineFilterData {
    id: string;
    type: string;
    title: string;
    value: string;
    adminOnly: boolean;
    multiSelectable: boolean;
    isActive: boolean;
}
// подготовленный объект только категорий. он не учавствует в изменении контекста на пряму, но активирует работу чекбоксов.
export const onlyCat = {
    id: 'categories',
    type: 'newsTypes',
    title: Translate.t({ i18nKey: 'pryaniky.filter.feed.categories' }),
    value: 'categories',
    adminOnly: false,
    multiSelectable: false,
    isActive: false,
}


export const useTimelineFilter = ({
    context,
    // props,
    changeContextv3,
    relations
}: {
    context: IFiltersProps['context']
    relations: IFiltersProps['relations']
    changeContextv3: IFiltersProps['changeContextv3']
    // props: Pick<IFiltersProps, 'relations' | 'changeContextv3'>
}) => {

    const currentUser = useSelector(getCurrentUser)

    const defaultFilter = currentUser?.profileData?.showMyNewsByDefault ? myTimelineFilter : filterAll

    // state part для множественного фильтра
    const [timlineData, setTimlineData] = useState<ITimlineFilterData[]>([]);

    // state Part для обычного одиночного фильтра публикаций
    const [defaultTimelineData, setDefaultTimelineData] = useState<ITimlineFilterData[]>([]);

    // массив куда приходят одиночные категории() id: 'categories' запускает чекбоксы множественного фильтра
    const [others, setOthers] = useState<ITimlineFilterData[]>([]);

    // QUESTION типизировать возможно вобще убрать
    // массив с выбраными чекбоксами множественных типов публикаци, отправится в контест, когда будет нажата кнопка применить
    const [categoriesArr, setCategoriesArr] = useState<ITimlineFilterData[]>([]);

    // QUESTION обудить актыальность этого стэйта, можнт будет правильнее использовать useRef как компоненетную переменную
    // флаг дающий разрешение отправку в контекст множественных фильтров.
    // const [letUseMultiValues, setLetUseMultiValues] = useState<boolean>(false);

    // скрывет\показывает все типы публикаций
    const [typesListShown, setTypesListShown] = useState<boolean>(false);

    useEffect(() => {
        getAvaliablePostTypes();
    }, []);

    useEffect(() => {
        defaultTimelineData.push(onlyCat);
        const savedFilters = (window as any)?.localStorage.getItem('timlineFilters-' + context.wId);

        if (savedFilters && savedFilters !== 'undefined') {
            const parsed = JSON.parse(savedFilters);
            if (timlineData.findIndex((el: any) => el.id === parsed[0]?.id) !== -1) {
                // if (timlineData.map(i=>i.id).includes(parsed[0].id)) {
                setOthers([onlyCat]);
                parsed.map((savedEl: any) => setTimlineData((prevState) => prevState.map((item: any) => item.id === savedEl?.id ?
                    { ...item, isActive: savedEl?.isActive } :
                    item)));
            } else {
                change(parsed)
            }
        }
    }, [defaultTimelineData]);

    const letUseMultiValues = useRef<boolean>(false);

    useEffect(() => {

        const activeTypes = JSON.stringify(context.filter);
        localStorage.setItem('timlineFilters-' + context.wId, activeTypes);

    }, [context.filter]);

    // функция удаления чипа с выбраным типом публикции
    const handleDelete = (id: string) => {
        // если приходит запрос на удаление одиночного вильтра то мы просто заменяем его на все
        if (defaultTimelineData.map(el => el.id).includes(id)) {
            change([defaultFilter])
            setCategoriesArr([])
        } else {
            // QUESTION тут сначала фильтруется массив context?.filter. Потом проверяется длинна не отфильтрованного массива, а не измененного context?.filter, надо проверить правильность работы
            // в любом другом случае мы в начале фильтруем контекст убирая из него выбранный для удаления массив
            const arr = context?.filter?.filter((el: any) => el.id !== id);
            // и проверяем укак будет изменен контекст, если в контексте еще остались элементы из множественных типов (то засовываем в него отфильтрованный массив)
            // если не осталось(то изменяем контекст на all)
            context?.filter?.length > 1 ? change(arr) : change([defaultFilter])

            // снимаем чекбоксы при удаление челез чипы.
            setTimlineData((prevState) => prevState.map((item: any) => item.id === id ? { ...item, isActive: false } : item));
        }
    }

    //  useEffect создающий массив с чекнутыми множественными типами публикаций этот масив мы зальем в контекст при нажатии на кнопку "применить".
    useEffect(() => {
        setCategoriesArr(timlineData.filter(el => el.isActive))
    }, [timlineData]);

    // функция кнопки применить, которая будет менять контекст в функции change когда setLetUseMultiValues станет true. 
    const useMultyValues = () => {
        letUseMultiValues.current = true
        change(categoriesArr)
    }

    const getAvaliablePostTypes = () => utils.API.baseAdmin.getEvaliblePostTypesForFilter(context.groupId).r.then((d) => {
        if (utils.checkResponseStatus(d)) {
            // QUESTION проверить типы
            let dataRespons = d.data?.data ? d.data.data : d.data;
            const respData: ITimlineFilterData[] = dataRespons.map((el: ITimlineFilterIncommingData) => ({
                id: el.id,
                type: "newsTypes",
                title: el.displayName,
                value: el.id,
                adminOnly: el.id === "planned" ? true : false,
                multiSelectable: el.multiSelectable,
                isActive: false,
            }));

            setTimlineData(respData.filter((el: ITimlineFilterData) => el.multiSelectable === true));
            setDefaultTimelineData(respData.filter((el: ITimlineFilterData) => el.multiSelectable === false));

        }

    })


    const change = (selectedEl: ITimlineFilterData[]) => {

        // изменяем одиночный тип с "Только категории" на другой выбранный (чтобы функция отработала по всем необходимым условиям)
        if (defaultTimelineData.map(el => el.id).includes(selectedEl[0]?.id)) {
            setOthers(selectedEl)
            // снимаем чекбоксы 
            setTimlineData((prevState) => prevState.map((item) => item.isActive ? { ...item, isActive: false } : item))

        }
        // это проверка для типа публикации "Только категории". когда она включается чекбоксы становятся активны, и можно начинать выбирать мультифильтры.
        //  при выбори этой кнопки не меняется контекст если letUseMultiValues false. 
        if (selectedEl[0]?.id === "categories" && !letUseMultiValues.current) {
            setOthers(selectedEl)
        } else {
            letUseMultiValues.current = false;
            const type = selectedEl.reduce((acc: any, curr: any) => {
                return curr.type
            }, "")
            changeContextv3(relations.reduce((acc: any, conID: any) => ({
                ...acc,
                [conID]: {

                    filter: selectedEl,
                    [type]: selectedEl.map((el: ITimlineFilterData) => el.value).join(",")
                }

            }), {} as { [s: string]: any }))
        }
    }

    return {
        typesListShown,
        setTypesListShown,
        handleDelete,
        defaultTimelineData,
        change,
        others,
        timlineData,
        setTimlineData,
        setOthers,
        categoriesArr,
        useMultyValues,
        defaultFilter
    }

}