import { IClassNameProps } from '@bem-react/core';
import { cnReplyes, SReplyMobileModel } from '../Replyes.index';
import { ThanksInfo } from '../../NewsTypes/Thanks/Editor/containers/reply/reply.types'
import { match } from 'react-router';

export interface IFormDataType {
  text: string;
  user: any;
  newsID: string;
}

export interface IReplyFormProps extends IClassNameProps {
  data: IFormDataType;
  isThank: boolean;
  newsAuthorId: string;
  thankUsers: any[];
  newComment: (newComment: SReplyMobileModel) => void;
  toggleThank: () => void;
}

export interface IReplyFormState {
  text: string;
  open: boolean;
  isValidData: boolean;
  attachments: any[];
  // fund: number;
  // companyReasons: any[];
  // selectedReasons: number[];
  selectedUsers: any[];
  thanksInfo: ThanksInfo;
}

export const cnReplyForm = (...data: any) => {
  let name = 'ReplyForm';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnReplyes(name, ...data)
}