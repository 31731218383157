import { IListProps } from '../../List.index';

import { IDispatchProps } from '../../../../redux/connector';

export interface IDefaultRequestOption {
  search: string;
}

export interface IListUsersDefaultRequestProps extends IDefaultRequestOption {
  catFilter: string;
  excludeGroup: number;
  rid: string;
  gid: number;
  extended: boolean;
  count: number;
  skipCount: number;
}

// export interface IListUsersDefaultProps {
//   requestOptions: IListUsersDefaultRequestProps;
// }

export interface IListTypeOrdersProps extends IListProps, IDispatchProps, ReturnType<typeof mapStateToProps> {
  requestOptions: any;
  tag?: 'div';
}

// export interface IListTypeUsersState {
//   text?: string;
// }

// export interface IListStateProps {
//   store: any
// }

export const mapStateToProps = (state: any) => ({
  currencyNameSingle: state.store.appSettings.currencyNameNominativeSingular,
  currencyNamePlural: state.store.appSettings.currencyNameGenitivePlural,
  currencyNameSemiPlural: state.store.appSettings.currencyNameGenitiveSingular,
});
