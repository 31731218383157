
export interface ITagBlockProps {
  context: TContextTagBlock,
  id: string
};

export type TContextTagBlock = {
  __parent: string,
  appId: string,
  currentPageId: string,
  currentUserId: string,
  page: string,
  tId: string,
  tagUid: string,
  url: string
}