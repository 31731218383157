import { generateWidget, generateColumn, widgets, IWidget, IColumn, IWidgetProps } from '../i.widgets'
import ideasLogo from 'assets/png/Ideas.png'
import pollsLogo from 'assets/png/Polls.png'
import eventsLogo from 'assets/png/Events.png'
import questsLogo from 'assets/png/Quests.png'

import i18n from '../localizations/i18n'



export const pageHeader = {
    achievements: {
        imgSrc: ideasLogo,
        imgSize: 64,
        title: i18n.t('pryaniky.infoBlock.achievements.title')
    },
    ideas: {
        imgSrc: ideasLogo,
        imgSize: 64,
        title: i18n.t('pryaniky.infoBlock.ideas.title')
    },
    polls: {
        imgSrc: pollsLogo,
        imgSize: 64,
        title: i18n.t('pryaniky.infoBlock.polls.title')
    },
    creativetasks: { 
        imgSrc: ideasLogo, 
        imgSize: 64, 
        title: i18n.t('pryaniky.infoBlock.creativetasks.title') 
    },

    events: { 
        imgSrc: eventsLogo, 
        imgSize: 64, 
        title: i18n.t('pryaniky.infoBlock.events.title') 
    },

    notices: { 
        imgSrc: ideasLogo, 
        imgSize: 64, 
        title: i18n.t('pryaniky.infoBlock.notices.title') 
    },

    quests: {
        imgSrc: questsLogo,
        imgSize: 64,
        title: i18n.t('pryaniky.quests.page.title'),
    }

}