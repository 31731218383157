import React, { FC, useState } from 'react'
import { IChangeMailProps } from './ChangeMail.index'
import * as utils from 'utils/src/utils'
import i18n from 'localizations/i18n';
import { useLazyQueryEx } from 'utils/src/hooks'
import { createModal, defaultDialogProps } from 'utils/src/DialogCreator';
import { DialogBody } from 'muicomponents/src/DialogParts/DialogBody/DialogBody'
import { TextField } from 'muicomponents/src/TextField/TextField'
import { AlertMessage } from 'muicomponents/src/AlertMessage/AlertMessage'


async function changeMailPromise(userId: string, email: string) {
	const response = await utils.API.users.changeMail(userId, email)
	return await response.r
}

const ChangeMailPresenter: FC<IChangeMailProps> = ({
	user,
	handleAccept,
	handleClose
}) => {
	const { id, eMail = '' } = user

	const [newMail, setNewMail] = useState(eMail)

	const { send, isLoading, error: { isError, errorInfo } } = useLazyQueryEx(changeMailPromise, { allowUndefinedData: true })

	async function changeMail() {
		try {
			await send(id, newMail)
			handleAccept({ ...user, eMail: newMail })
		} catch (error) {
			console.error('ERROR: ', error)
		}
	}

	return <DialogBody
		header={i18n.t('pryaniky.profile.changeMail.modal.change')}
		onClose={handleClose}
		isLoading={isLoading}
		onAccept={changeMail}
	>
		{isError && <AlertMessage severity='error' className={'ChangeEmail-Msg'}>{i18n.t('pryaniky.changeemail.error.' + errorInfo.err.error_code)}</AlertMessage>}

		<TextField
			sx={{ marginTop: '12px' }}
			fullWidth
			className={'ChangeMail-Input'}
			value={newMail}
			onChange={(e) => setNewMail(e.target.value)}
			label={i18n.t('pryaniky.profile.changeMail.modal.newEmail')} />

	</DialogBody>
}

export const сhangeMail = createModal(ChangeMailPresenter)