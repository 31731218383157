import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMS
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Button, Spinner } from 'uielements/src'
import PieChart, { LabelProps, PieChartData } from 'react-minimal-pie-chart';
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip'
import { tS, Ts } from 'LMSModule/utils/i18n.adaptor'
import { ButtonBox } from 'uielements/src/ButtonBox/ButtonBox'

type IOwnProps = LMS.CourseStatusesWidget.IOwnProps
type IPresenterProps = LMS.CourseStatusesWidget.IPresenterProps
type IStateProps = LMS.CourseStatusesWidget.IStateProps
type IDispatchProps = LMS.CourseStatusesWidget.IDispatchProps
type IState = LMS.CourseStatusesWidget.IState

const noopItem: PieChartData = {
    color: '#ccc',
    value: 1,
    title: 'noop',
    key: 'noop',
}

export const Presenter: FC<IPresenterProps> = ({
    className,
    stat,
    statMondatory,
    loadCoursesStatus,
    userid
}) => {
    const [isActive, setActive] = useState(true)
    useEffect(() => {
        loadCoursesStatus(userid)
    }, [])
    const data: PieChartData[] = stat.active.map((val: any) => ({
        ...val,
        title: val.label
    }))
    const archiveData: PieChartData[] = stat.archive.map((val: any) => ({
        ...val,
        title: val.label
    }))
    const dataMondatory: PieChartData[] = statMondatory.active.map((val: any) => ({
        ...val,
        title: val.label
    }))
    const archiveDataMondatory: PieChartData[] = statMondatory.archive.map((val: any) => ({
        ...val,
        title: val.label
    }))

    const summuryNo = data.map(v => v.value).reduce((p, c) => p + c, 0)
    const summuryArchiveNo = archiveData.map(v => v.value).reduce((p, c) => p + c, 0)
    if (summuryNo === 0) data.push(noopItem)
    const summuryMondatory = dataMondatory.map(v => v.value).reduce((p, c) => p + c, 0)
    const summuryArchiveMondatory = archiveDataMondatory.map(v => v.value).reduce((p, c) => p + c, 0)
    if (summuryMondatory === 0) dataMondatory.push(noopItem)

    const resultedNo = isActive ? summuryNo : summuryArchiveNo

    const summary = summuryNo + summuryMondatory
    // if (summary === 0) return null
    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('SelectorBox')}>
            <ButtonBox orientation={'horizontal'} className={cnClassName('Type')}>
                <Button main={isActive} onClick={() => setActive(true)} title={tS('TeacherOffice.title.1')} >{tS('widget.stats.type.active.1')}</Button>
                <Button main={!isActive} onClick={() => setActive(false)} title={tS('LMS.title.1')} >{tS('widget.stats.type.archive.1')}</Button>
            </ButtonBox>
        </div>
        <div className={cnClassName('Content')}>
            <div className={cnClassName('Box')}>
                <div className={cnClassName('SummaryAnswers')}>{tS('widget.stats.not_mondatory.1')}</div>
                <div className={cnClassName('Data')}>
                    <div className={cnClassName('Summary')}>
                        <table className={cnClassName('Table')}>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className={cnClassName('SummaryTitle')}>{tS('widget.stats.label.all.1')}</div>
                                    </td>
                                    <td>
                                        <div className={cnClassName('Value')} style={{ color: '#F0A70A' }}>
                                            {/* <HelpTooltip help={<div>
                                            <span>{tS('widget.stats.in_archive.1')}</span>: <b>{summuryArchiveNo}</b>
                                        </div>}> */}
                                            <b>{resultedNo}</b>
                                            {/* </HelpTooltip> */}
                                        </div>
                                    </td>
                                </tr>

                                {(isActive ? data : archiveData).map((val: any, i: number) => val.key === 'noop' ? null : <tr key={val.key}>
                                    <td>
                                        <div className={cnClassName('SummaryTitle')}>{tS('widget.stats.label.' + val.key)}</div>
                                    </td>
                                    <td>
                                        <div className={cnClassName('Value')} style={{ color: val.color }}>
                                            {/* <HelpTooltip help={<div>
                                            <span>{tS('widget.stats.in_archive.1')}</span>: <b>{archiveData[i].value}</b>
                                        </div>}> */}
                                            <b>{val.value}</b>
                                            {/* </HelpTooltip> */}
                                        </div>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>

                    <PieChart
                        className={cnClassName('Chart')}
                        data={isActive ? data : archiveData}
                        lineWidth={43}
                        labelPosition={0}

                        label={(val: LabelProps) => isActive ? stat?.appointed?.active : stat?.appointed?.archive}
                        labelStyle={{
                            fontSize: '25px',
                            // fontFamily: 'sans-serif',
                            fill: '#F0A70A',
                        }}
                    />
                </div>
            </div>

            <div className={cnClassName('Box')}>
                <div className={cnClassName('SummaryAnswers')}>{tS('widget.stats.mondatory.1')}</div>
                <div className={cnClassName('Data')}>
                    <div className={cnClassName('Summary')}>
                        <table className={cnClassName('Table')}>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className={cnClassName('SummaryTitle')}>{tS('widget.stats.label.all.2')}</div>
                                    </td>
                                    <td>
                                        <div className={cnClassName('Value')} style={{ color: '#F0A70A' }}>
                                            {/* <HelpTooltip help={<div>
                                            <span>{tS('widget.stats.in_archive.1')}</span>: <b>{summuryArchiveMondatory}</b>
                                        </div>}> */}
                                            <b>{summuryMondatory}</b>
                                            {/* </HelpTooltip> */}
                                        </div>
                                    </td>
                                </tr>
                                {(isActive ? dataMondatory : archiveDataMondatory).map((val: any, i: number) => val.key === 'noop' ? null : <tr key={val.key}>
                                    <td>
                                        <div className={cnClassName('SummaryTitle')}>{tS('widget.stats.label.' + val.key)}</div>
                                    </td>
                                    <td>
                                        <div className={cnClassName('Value')} style={{ color: val.color }}>
                                            {/* <HelpTooltip help={<div>
                                            <span>{tS('widget.stats.in_archive.1')}</span>: <b>{archiveDataMondatory[i]?.value}</b>
                                        </div>}> */}
                                            <b>{val.value}</b>
                                            {/* </HelpTooltip> */}
                                        </div>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>

                    <PieChart
                        className={cnClassName('Chart')}
                        data={isActive ? dataMondatory : archiveDataMondatory}
                        lineWidth={43}
                        labelPosition={0}
                        label={(val: LabelProps) => isActive ? statMondatory?.started?.active : statMondatory?.started?.archive}
                        labelStyle={{
                            fontSize: '25px',
                            // fontFamily: 'sans-serif',
                            fill: '#F0A70A',
                        }}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
