import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    left?: React.ReactNode;
    right?: React.ReactNode;
    unitIndex: number;
    unitsCount: number;
}

export const cnClassName = cn('LMSLessonNavigation');