import actions from 'redux/actionsTypes/Skills';
import { ISkill, ISkillCollection } from '../sagas/Skills/types'
/********************************************************/
/******************** to saga ***************************/
/********************************************************/


/************************* LOAD_USER_SKILLS *******************************/
export type loadUserSkillsPayloadType = { userId: string }
export type loadUserSkillsActionType = { type: string, payload: loadUserSkillsPayloadType }
/**
 * получить с сервера навыки пользователя
 * * @param userId
 */
export const loadUserSkills = (userId: string): loadUserSkillsActionType => ({
    type: actions.LOAD_USER_SKILLS,
    payload: {
        userId
    }
})


/************************* CREATE_USER_SKILL *******************************/
export type createUserSkillPayloadType = { userId: string, skill: ISkill }
export type createUserSkillActionType = { type: string, payload: createUserSkillPayloadType }
/**
 * создать скилл для пользователя
 * * @param userId
 */
export const createUserSkill = (userId: string, skill: ISkill): createUserSkillActionType => ({
    type: actions.CREATE_USER_SKILL,
    payload: {
        userId,
        skill
    }
})


/************************* CONFIRM_SKILL *******************************/
export type confirmSkillPayloadType = { userId: string, skillId: string, confirmedByMe: boolean }
export type confirmSkillActionType = { type: string, payload: confirmSkillPayloadType }
/**
 * подтвердить скилл
 * * @param userId
 */
export const confirmSkill = (userId: string, skillId: string, confirmedByMe: boolean): confirmSkillActionType => ({
    type: actions.CONFIRM_SKILL,
    payload: {
        userId,
        skillId,
        confirmedByMe
    }
})


/************************* DELETE_SKILL *******************************/
export type deleteSkillPayloadType = { userId: string, skillId: string }
export type deleteSkillActionType = { type: string, payload: deleteSkillPayloadType }
/**
 * удалить скилл
 * * @param userId
 */
export const deleteSkill = (userId: string, skillId: string): deleteSkillActionType => ({
    type: actions.DELETE_SKILL,
    payload: {
        userId,
        skillId
    }
})




/***********************************************************/
/******************** to reducer ***************************/
/***********************************************************/


/************************ ADD_USER_SKILLS ********************************/
export type addUserSkillsPayloadType = { userId: string, skills: ISkillCollection }
export type addUserSkillsActionType = { type: string, payload: addUserSkillsPayloadType }
/**
 * поместить в стор навыки пользователя
 * @param skills
 * @param userId
 */
export const addUserSkills = (userId: string, skills: ISkillCollection): addUserSkillsActionType => ({
    type: actions.ADD_USER_SKILLS,
    payload: { userId, skills }
})


/************************ UPDATE_SKILL ********************************/
export type updateSkillPayloadType = { userId: string, skill: ISkill }
export type updateSkillActionType = { type: string, payload: updateSkillPayloadType }
/**
 * изменить confirmedByMe
 * @param confirmedByMe
 * @param userId
 */
export const updateSkill = (userId: string, skill: ISkill): updateSkillActionType => ({
    type: actions.UPDATE_SKILL,
    payload: { userId, skill }
})


/************************ UPDATE_SKILL ********************************/
export type deleteUserSkillPayloadType = { userId: string, skillId: string }
export type deleteUserSkillActionType = { type: string, payload: deleteUserSkillPayloadType }
/**
 * изменить confirmedByMe
 * @param confirmedByMe
 * @param userId
 */
export const deleteUserSkill = (userId: string, skillId: string): deleteUserSkillActionType => ({
    type: actions.DELETE_USER_SKILL,
    payload: { userId, skillId }
})


/************************ SET_LOADING ********************************/
export type setLoadingPayloadType = { isLoading: boolean }
export type setLoadingActionType = { type: string, payload: setLoadingPayloadType }
/**
 * изменить isLoading
 * @param isLoading
 */
export const setLoading = (isLoading: boolean): setLoadingActionType => ({
    type: actions.SET_LOADING,
    payload: { isLoading }
})


/************************ SET_FINISHED ********************************/
export type setFinishedPayloadType = { isFinished: boolean }
export type setFinishedActionType = { type: string, payload: setFinishedPayloadType }
/**
 * изменить isLoading
 * @param isFinished
 */
export const setFinished = (isFinished: boolean): setFinishedActionType => ({
    type: actions.SET_FINISHED,
    payload: { isFinished }
})
