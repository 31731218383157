import actions from '../actionsTypes/TracksUse';
import { CreateTrackParams } from 'utils/src/requests/models/api.learningTracks'
import { ITrack, ILearningFile, GetManageListParams, GetTrackFilesParams, GetTrackUsersParams } from 'utils/src/requests/models/api.learningTracks'

const zeroId = "00000000-0000-0000-0000-000000000000";

//////////////////////////////////////////////////////////////////////////////////////////////////////

/********************************************************/
/******************** to saga ***************************/
/********************************************************/

/******************************* LOAD_TRACKS *******************************/
export type loadTracksListPayloadType = GetManageListParams & {}
export type loadTracksListActionType = { type: string, payload: loadTracksListPayloadType }
/**
 * получить список планов для управления
 */
export const loadTracksList = (payload: loadTracksListPayloadType = {}): loadTracksListActionType => ({
    type: actions.LOAD_TRACKS,
    payload
})

/******************************* LOAD_FILES *******************************/
export type loadFilesPayloadType = GetTrackFilesParams
export type loadFilesActionType = { type: string, payload: loadFilesPayloadType }
/**
 * добавить новость в учбный план
 */
export const loadFiles = (payload: loadFilesPayloadType): loadFilesActionType => ({
    type: actions.LOAD_FILES,
    payload
})


/******************************* LOAD_FILES *******************************/
export type sendNewsLearnedPayload = { id: string, isComplete: boolean }
export type sendNewsLearnedType = { type: typeof actions.News_LEARNED, payload: sendNewsLearnedPayload }
/**
 * 
 */
export const sendNewsLearned = (payload: sendNewsLearnedPayload): sendNewsLearnedType => ({
    type: actions.News_LEARNED,
    payload
})


/********************************************************/
/******************** to reducer ************************/
/********************************************************/

/******************************* SET_TRACKS *******************************/
export type setTracksListPayloadType = { data: { [key: string]: ITrack }, keys: string[] }
export type setTracksListActionType = { type: string, payload: setTracksListPayloadType }
/**
 * добавить список треков в стор
 */
export const setTracksList = (payload: setTracksListPayloadType): setTracksListActionType => ({
    type: actions.SET_TRACKS,
    payload
})

/******************************* SET_FILES *******************************/
export type setFilesListPayloadType = { values: { [key: string]: ILearningFile }, keys: string[], trackId: string }
export type setFilesListActionType = { type: string, payload: setFilesListPayloadType }
/**
 * добавить список материалов в треке
 */
export const setFilesList = (payload: setFilesListPayloadType): setFilesListActionType => ({
    type: actions.SET_FILES,
    payload
})


export type changeFieldPayloadType = { root: string, field: string, value: any }
export type changeFieldActionType = { type: string, payload: changeFieldPayloadType }
/**
 * 
 */
export const changeField = (payload: changeFieldPayloadType): changeFieldActionType => ({
    type: actions.CHANGE_FIELD,
    payload
})


/******************************* RESET *******************************/
export type resetPayloadType = string
export type resetActionType = { type: string, payload: resetPayloadType }
/**
 * 
 */
export const reset = (payload: string): resetActionType => ({
    type: actions.RESET,
    payload
})