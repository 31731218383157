import { set_readed } from '../../../redux/actions/Notification';
import { bindActionCreators } from 'redux';
import { cn } from '@bem-react/classname';
import { IStateType as IState } from 'redux/store';
import { RouteComponentProps } from 'react-router';

export interface INotificationItemProps extends INotificationItemOwnProps, IDispatchPropsNotificationItem, IStateToPropsNotificationItem {}

export interface INotificationItemOwnProps extends RouteComponentProps {
    id: number;
}

export type IDispatchPropsNotificationItem = ReturnType<typeof mapDispatchToProps>
export type IStateToPropsNotificationItem = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: IState, props: INotificationItemOwnProps) => ({
    item: state.notifications.notifications[props.id],
    // notifications: state.notifications,
    // resetCount: state.changeVm
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    set_readed,
}, dispatch);

export const cnNotifications = cn('Notifications');