import * as utils from 'utils/src/utils';
import { ThanksNews, RewardReason } from './Thanks.type'

export const getReasons = (result: (reasons: RewardReason[]) => void) => {
    utils.API.system.reasons()
        .r
        .then((d: any) => {
            if (utils.checkResponseStatus(d)) {
                const arr: RewardReason[] = d.data.map((g: any) => {
                    return {
                        label: g.name,
                        options: g.reasons.map((r: any) => {
                            return { value: r.id, label: r.name };
                        })
                    }
                })
                result(arr)
            } else {
                // toast.error('something wrong');
            }
        });
}