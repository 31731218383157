import React, { FC, useState, useRef } from 'react'
import { ISelectProps, cnSelect } from './SelectMenu.index'
import { Saggester } from '../Saggester/Saggester'
import './SelectMenu.scss'
import { InputLayout } from '../InputLayout/InputLayout'

const ListItem: FC<any> = ({ item, onClick}) => {
    return <div onClick={onClick(item)}>
        {item.title}
    </div>
}

export const SelectMenu: FC<ISelectProps> = (
    {
        className,
        onChange, value, variants, icon, placeholder,
        ...props
    }) => {
    const [open, setOpen] = useState(false);
    const [focused, setFocused] = useState(false);
    const [focusIndex, setFocusIndex] = useState(0);

    const onToggleItem = (item: any) => {
        onChange(item)
        setOpen(false)
    }


    // const handleKeyDown = (e: any) => {
    //     switch (e.keyCode) {
    //         // case 39: // ArrowRight
    //         case 38: // ArrowUp
    //             // this.nextStep();
    //             setFocusIndex(focusIndex > 0 ? focusIndex - 1 : filteredValues.length - 1)
    //             e.preventDefault();
    //             // e.stopPropagation();
    //             break;
    //         // case 37: // ArrowLeft
    //         case 40: // ArrowDown
    //             // this.prevStep();
    //             setFocusIndex(focusIndex < filteredValues.length - 1 ? focusIndex + 1 : 0)
    //             e.preventDefault();
    //             e.stopPropagation();
    //             break;
    //         case 13: // enter
    //             onToggleItem(filteredValues[focusIndex])
    //             // e.preventDefault();
    //             break;
    //         default:
    //             return false;
    //     }
    //     return false;
    // };


    if (open) {
        // document.addEventListener('keydown', handleKeyDown, { once: true })
    }

    return (
        <div className={cnSelect({}, [className])}>
            <InputLayout
                action={<button onClick={() => setOpen(true)}>o</button>}
                focus={focused}
                suggester={(open) && <Saggester clickOutside={() => setOpen(false)} onSelect={onToggleItem} className={cnSelect('Suggester')}>
                    {(onClick: (id: any) => () => void) => variants.map((val: any) => <ListItem key={val} item={val} onClick={onClick} />)}
                </Saggester>}
                icon={icon}
            >
                <div className={cnSelect('WorkElements')} onClick={() => { setOpen(!open);}}>
                    {!value && <div className={cnSelect('Placeholder')}>{placeholder}</div>}
                    {value && <ListItem item={value} onClick={()=>{}} /> }
                    {/* {variants.map((val: any) => <ListItem key={val} title={val.title}/>)} */}
                </div>
            </InputLayout>
        </div>
    )
}