import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMSTO
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Masonry, Button } from 'uielements/src';
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import { SearchInput } from 'uielements/src';
import queryString from 'query-string';
import { CreateCourseDialog } from '../../../dialogs/CreateCourse/CreateCourse'
import ListPlaceholder from '../../../components/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import Item from './Item'

const LoadButton = withLoadSpin(Button)

type IPresenterProps = LMSTO.TOCoursesList.IPresenterProps
type IOwnProps = LMSTO.TOCoursesList.IOwnProps
type IDispatchProps = LMSTO.TOCoursesList.IDispatchProps
type IStateProps = LMSTO.TOCoursesList.IStateProps
type IState = LMSTO.TOCoursesList.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    isLoading,
    isFinished,
    results,
    loadMore,
    setShowCreate,
    onChangeSearch,
    search
}) => {
    const triggerRef = useInfiniteScroll({
        loadMore,
        isLoading,
        isFinished,
    });
    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('SearchBox')}>
            <SearchInput loading={isLoading} value={search} className={cnClassName('SearchInput')} onChange={onChangeSearch} />
            <Button main className={cnClassName('CreateBtn')} onClick={() => setShowCreate(true)}>Создать курс</Button>
        </div>

        <ListPlaceholder
            className={cnClassName('Placeholder')}
            isFinished={isFinished}
            isLoading={isLoading}
            itemsCount={results.length}
            titleEmpty={'Курсы отсутствуют'}
            textEmpty={search ? `Не найдены курсы по запросу "${search}"` : "Вы можете создать новый курс"}
            textLoad={"Подождите немного"}
        // onlyEmpty
        >

            <div  className={cnClassName('Items')}>
                {results.map((cid: string) => <Item key={cid} cid={cid} />)}
            </div>
            {!isFinished && (
                <LoadButton
                    forwardedRef={triggerRef}
                    isLoading={isLoading}
                    className={cnClassName('Trigger')}
                    onClick={isLoading ? void 0 : loadMore}>
                    Еще
                </LoadButton>
            )}
        </ListPlaceholder>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
