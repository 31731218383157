import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    loadUnitsComments,
    changeUnitsReply
} from '../../redux/actions/LMSTeacherOffice'
import {
    getUnitsRelpys
} from '../../redux/sagas/LMSTO/selectors'
import {
    sendLikeReply
} from 'News/redux/actions'
import {
    getCommentsKeys,
    getNewsById
} from 'News/redux/saga/selectors'
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace LMSTO {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Comments {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            cid?: string;
            type?: 'user' | 'admin'
            course?: any
        }

        export interface IStateProps {
            keys: string[];
            values: any;
            requestOptions: any;
            isLoading: boolean;
            isFinished: boolean;
            course?: any;
            commentsKeys: string[]
        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = LMSTO.Comments.IOwnProps

const emptyArray: string[] = []

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    ...getUnitsRelpys(state),
    // newsIds: state.news.newsIds,
    // news: state.news.news,
    // requestOptions: state.news.requestOptions,
    // isLoading: state.news.isLoading,
    // isFinished: state.news.isFinished,
    commentsKeys: getNewsById(props.cid || 'allunits')(state)?.comments || emptyArray,
    course: props.cid ? state.LMSTO.courses.values[props.cid] : undefined,
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    sendLikeReply,
    loadUnitsComments,
    changeUnitsReply
}, dispatch);

////////////

export const cnClassName = cn('LMSTOComments');