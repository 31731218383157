import React, { FC } from "react";
import { SvgIcon } from "@material-ui/core";


export const AskingHand: FC = () => {

    return <SvgIcon>
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16 5C16 6.65685 14.6569 8 13 8C11.3431 8 10 6.65685 10 5C10 3.34315 11.3431 2 13 2C14.6569 2 16 3.34315 16 5ZM5 11H1V22H5V11ZM20 17H13L10.91 16.27L11.24 15.33L13 16H15.82C16.47 16 17 15.47 17 14.82C17 14.33 16.69 13.89 16.23 13.71L8.97 11H7V20.02L14 22L22.01 19C22 17.9 21.11 17 20 17ZM19 13C20.6569 13 22 11.6569 22 10C22 8.34315 20.6569 7 19 7C17.3431 7 16 8.34315 16 10C16 11.6569 17.3431 13 19 13Z" />
        </svg>
    </SvgIcon>
}