import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import Header from '../../Header'
import SummaryInfo from '../../SummaryInfo'
import Settings from '../Settings'
import { withIndicator } from 'utils/src/CommonRedux/LoadIndicator'
import actionsTypes from '../../../../redux/actionsTypes/LMSTO'
import { Button, Avatar } from 'uielements/src';
import List from './List'
import { pick } from 'lodash'
import { UserMention } from 'uielements/src/UserMention/UserMention';
import { tSt } from 'LMSModule/utils/i18n.adaptor'

type IPresenterProps = StatisticsFull.User.IPresenterProps
type IOwnProps = StatisticsFull.User.IOwnProps
type IDispatchProps = StatisticsFull.User.IDispatchProps
type IStateProps = StatisticsFull.User.IStateProps
type IState = StatisticsFull.User.IState

const bc = [
    {
        id: 'root',
        link: '/TeacherOffice?tab=statistics',
        text: tSt('bc.statistics'),//'Статистика',
        type: 'link'
    },
    {
        id: 'users',
        link: '/TeacherOffice/statistics/type/users',
        text: tSt('bc.users'),//'Пользователи',
        type: 'link'
    },
]

export const Presenter: FC<IPresenterProps> = ({
    className,
    match,
    settings,
    getUserStatInfoInfo,
    user,
    isLoading
}) => {
    useEffect(() => {
        getUserStatInfoInfo({ userId: match.params.id, params: pick(settings, 'countArchive', 'isMandatory', 'endDate', 'startDate') })
    }, [match.params.id, settings])


    if (!user) return null
    const {
        pieChart = [],
        complete,
        failed,
        inProgress,
        total,
        trainingLevel,
        user: userData
    } = user

    const breadcrumbs = [
        ...bc,
        {
            id: userData.id,
            link: `/TeacherOffice/statistics/user/${userData.id}`,
            text: userData.displayName,
            type: 'text'
        }
    ]

    return <div className={cnClassName({}, [className, 'Page'])} >
        <Header breadcrumbs={breadcrumbs} className={cnClassName('Top')} />

        <div className={cnClassName('Profile')}>
            <Settings disableGroups disableCourses className={cnClassName('Settings')} />
            <div className={cnClassName('Info')}>
                <Avatar
                    shape="square"
                    className={cnClassName('Avatar')}
                    imgId={userData.imgId}
                    imgUrl={userData.imgUrl}
                    name={userData.displayName}
                    size={146}
                />
                <div className={cnClassName('UserData')}>
                    <div className={cnClassName('DisplayName')}>
                        <UserMention className={cnClassName('UserMention')} name={userData.displayName} id={userData.id} />
                        {/* {userData.displayName} */}
                    </div>
                    <div className={cnClassName('Position')}>{userData.position}</div>
                    <div className={cnClassName('Division')}>{userData.division}</div>
                </div>
                <SummaryInfo
                    // isLoading={isLoading}
                    className={cnClassName('SummaryInfo')}
                    {...{
                        pieChart,
                        complete,
                        failed,
                        inProgress,
                        total,
                        trainingLevel
                    }}
                />
            </div>
        </div>

        <div className={cnClassName('Courses')}>
            <List userGUID={match.params.id} />
        </div>
    </div>
}

export default withIndicator(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter))), actionsTypes.GET_USER_STAT_INFO)();
