import * as React from 'react';
import { IEditorTypeTasksProps } from './news.index';
import { ITasksNews, defaultTasksNews, defaultTasksNewsTask, generateTaskFormItem } from '../../../Tasks.type'
import { BaseValidator, baseValidate } from '../../../../Base.validate'
import { Editor } from '../../Editor'
import * as utils from 'utils/src/utils';
import { v1 as uuid } from "uuid";
import { EditorState } from 'draft-js';

const valid = (localData: any) => {
  const bv = new BaseValidator<any>();
  bv.setRules(baseValidate);
  bv.addData(localData);
  return bv.validation().length === 0
}

export const EditorTypeNews: React.FC<IEditorTypeTasksProps> = ({ onChange, data }) => {
  let localData = data as ITasksNews;
  if (data.newstype !== 8) {
    localData = { ...utils.cloneObject(defaultTasksNews), text: EditorState.createEmpty() };
  } // else localData.uevent.startDateTime = new Date(localData.uevent.startDateTime);
  const onChangeTitle = (e: any) => {
    localData.header = e.target.value;
    localData.task.title = e.target.value;
    onChange(localData, valid(localData));
  }
  const onChangeText = (text: string) => {
    localData.text = text;
    onChange(localData, valid(localData));
  }
  const addNewAnswer = (fieldType: string | number) => {
    localData.forms.push(generateTaskFormItem({ fieldType }));
    onChange(localData, valid(localData));
  };
  const changeAnswerText = (index: number, value: any) => {
    localData.forms[index].name = value.target.value;
    onChange(localData, valid(localData));
  }
  const removeAnswer = (index: number) => {
    localData.forms.splice(index, 1);
    onChange(localData, valid(localData));
  }
  const toggleRequireAttach = (index: number) => {
    localData.forms[index].requireAttach = !localData.forms[index].requireAttach;
    onChange(localData, valid(localData));
  }
  const toggleRequireComment = (index: number) => {
    localData.forms[index].requireComment = !localData.forms[index].requireComment;
    onChange(localData, valid(localData));
  }
  const onChangeUsers = (users: any[]) => {
    localData.users = users;
    onChange(localData, valid(localData));
  }
  const onChangeFiledFiles = (index: number, files: any[]) => {
    localData.forms[index].attachments = files;
    onChange(localData, valid(localData));
  }
  return <Editor
    onChangeTitle={onChangeTitle}
    onChangeText={onChangeText}
    addNewAnswer={addNewAnswer}
    changeAnswerText={changeAnswerText}
    removeAnswer={removeAnswer}
    onChangeFiledFiles={onChangeFiledFiles}
    toggleRequireAttach={toggleRequireAttach}
    toggleRequireComment={toggleRequireComment}
    onChangeUsers={onChangeUsers}
    data={localData} />
}