/**
 * @packageDocumentation
 * @module Widget_Settings_timeline
 */
import { JFdictionary } from "../dictionary";

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.layout,
      direction: 'horizontal',
      elements: [
        {
          type: renderers.checkbox,
          label: "Показывать фильтр",
          name: "showFilter",
          scope: "#/properties/showFilter"
        }
      ]
    },
    {
      type: renderers.layout,
      direction: 'horizontal',
      elements: [
        {
          type: renderers.radio,
          label: "Основная лента",
          name: "mainTimeline",
          scope: "#/properties/mainTimeline"
        },
        {
          type: renderers.radio,
          label: "Лента группы",
          name: "groupsTimeline",
          scope: "#/properties/groupsTimeline"
        },
        {
          type: renderers.radio,
          label: "Лента пользователя",
          name: "usersTimeline",
          scope: "#/properties/usersTimeline"
        },
        {
          type: renderers.radio,
          label: "Лента тегов",
          name: "tagsTimeline",
          scope: "#/properties/tagsTimeline"
        }
      ]
    },
    {
      type: renderers.layout,
      rule: {
        effect: "SHOW",
        condition: {
          type: "LEAF",
          scope: "#/properties/usersTimeline",
          expectedValue: true
        }
      },
      elements: [
        {
          type: renderers.usersInput,
          scope: "#/properties/selectedUsers"
        }
      ]
    },
    {
      type: renderers.layout,
      rule: {
        effect: "SHOW",
        condition: {
          type: "LEAF",
          scope: "#/properties/tagsTimeline",
          expectedValue: true
        }
      },
      elements: [
        {
          type: renderers.tagsInput,
          scope: "#/properties/selectedTags"
        },
      ]
    },
    {
      type: renderers.layout,
      rule: {
        effect: "SHOW",
        condition: {
          type: "LEAF",
          scope: "#/properties/groupsTimeline",
          expectedValue: true
        }
      },
      elements: [
        {
          type: renderers.groupsInput,
          scope: "#/properties/selectedGroups"
        }
      ]
    },
    {
      type: renderers.select,
      scope: "#/properties/selectedNews"
    }

  ]
}