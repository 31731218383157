import React, { useState, useEffect } from 'react'
import { IAnswerTypeSort, cnAnswerTypeSort } from './AnswerTypeSort.index'
import './AnswerTypeSort.scss'
import { connect } from 'react-redux'
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip'
import { Box } from 'muicomponents/src/Box/Box'

const SortableItem = SortableElement(({ children }: any) => <div>{children}</div>);
const DragHandle = SortableHandle(() => <IconButton><DragIndicatorIcon color="primary" /></IconButton>);

export const AnswerTypeSortPresenter = ({
    item,
    index,
}: IAnswerTypeSort) => {

    const name = item.text.split(' ').map((el: string) => el.length > 100 ? el.slice(0, 100) + "..." : el).join(" ")
    
    const titleLength = item.text.slice(0, 100).indexOf(" ") === -1 ? 100 : undefined;

    return (
        <SortableItem index={index}>
            <Box className={cnAnswerTypeSort("Item")} sx={{
                marginTop: "8px",
                display: "flex",
                alignItems: "flex-start"
            }}>
                <DragHandle />
                <Tooltip title={titleLength && (titleLength < item.text.length) ? item.text : ""}>
                    <Box sx={{ padding: "10px 0" }}>{name}</Box>
                </Tooltip>
            </Box >
        </SortableItem >
    );
};

export const AnswerTypeSort = AnswerTypeSortPresenter;