import { createStyles, makeStyles, Theme, createTheme } from '@material-ui/core/styles';
export const myTheme = createTheme({
  overrides: {
    MuiInput: {
      root: {
        fontSize: 'inherit',
      },
      input: {
        font: '24px'
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 4,
        marginBottom: 4
      }
    },
    MuiButtonBase: {
      root:{
        margin: undefined
      }
    }
  }
});
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      flex: '1 1 100%',
      paddingBottom: '12px',
      paddingTop: '12px'
      // margin: theme.spacing(1),
      // minWidth: 120,
    },
    formControlVisibilityType: {
      flex: '1 1 100%',
      display: 'flex',
    },
    formLabel: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 13
    },
    formControlName: {
      fontSize: '24px'
    },
    formControlBase: {
      flex: '1 1 100%',
      display: 'flex',
      flexDirection: 'row',
      // margin: theme.spacing(1),
    },
    formInput: {
      flex: '33%'
    },
    confirmBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      borderTop: '1px solid #D7D7D7'
    },
    confirmBtn: {
      marginLeft: '10px',
      marginTop: '20px'
    },
    typesSelect: {
      whiteSpace: 'normal'
    },
    peopleAutoIncludeActions: {
      display: 'flex',
      paddingTop: 6,
      gap: 8
      // justifyContent: 'space-between'
    },
    peopleAutoIncludeBtn: {
      marginRight: 14
      // justifyContent: 'space-between'
    }
  }),
);