import { Idea, BaseIdea } from './Ideas/IdeaType'
import { IFeedbackInfo } from './Feedback/Feedback.type';
import { CreativeTask } from './Creativetasks/CreativetasksType'
import { Uevent } from './Events/EventsType'
import { Poll } from './Polls/Polls.types'
import { Badge } from './Badges/Badges.type'
import { RewardReason, Thanks } from './Thanks/Thanks.type'
import { ReplyType, createDefaultReply } from './Reply/Reply.type'
import { Group } from 'News/types/baseNews'

export interface BaseNews {
    id: string;
    text: any;
    isShowOnTop: boolean;
    isSubscribed: boolean;
    liked: boolean;
    isNeedApprove: boolean;
    // isFavorite: boolean;
    likeText: string;
    likeCount: number;
    linkPreviewImgUrl?: string;
    date: Date;
    expires?: Date;
    thanksCount: number;
    newstype: number;
    attachments?: any;
    user: User;
    users?: User[];
    groups?: Group[];
    badge?: Badge;
    poll?: Poll;
    uevent?: Uevent;
    thanks?: Thanks;
    rewardReasons?: RewardReason[];
    creativeTask?: CreativeTask;
    idea?: BaseIdea;
    feedback?: IFeedbackInfo;
    allowReply: boolean;
    commentsCount: number;
    systemNewsActionHtml: string;
    header: string;
    tags?: any;
    comments?: any;
    group?: Group;
    ts: number;
    actions: string[];
    newComment: ReplyType;
    newsTypeMain?: string;
    isAnonimous?: boolean;
    parentGuid?: string;
    translatedTo?: string;
    allowThanksReply?: boolean;
    changeDate?: Date;
    beginValidation?: boolean;
    normalComments?: any;
    fromCache?: boolean;
}
export interface User {
    id: string;
    displayName: string;
    eMail: string;
    imgId: string;
    sex: string;
    imgUrl: string;
    position: string;
    division: string;
    phone?: any;
    userPhotoUrl?: string;
    isDeleted?: boolean;
}

// export interface Group {
//     pkid: number;
//     name: string;
//     alias?: string;
//     imgId48x48?: string;
//     imgId198x198?: string;
//     imgUrl198x198?: string;
//     imgUrl48x48?: string;
//     imgId?: string;
//     imgUrl?: string;
//     isSystemGroup?: boolean;
//     description?: string;
//     isHidden?: boolean;
//     isDeleted?: boolean;
//     isArchived?: boolean;
//     visibilityType?: number;
//     childGroupCount?: number;
//     displayName?: number;
// }


export const defaultGroup: Group = {
    "pkid": -1,
    "name": "Основная лента",
    "alias": "",
    "imgId48x48": "00000000-0000-0000-0000-000000000000",
    "imgId198x198": "00000000-0000-0000-0000-000000000000",
    "imgUrl198x198": "00000000-0000-0000-0000-000000000000",
    "imgUrl48x48": "00000000-0000-0000-0000-000000000000",
    "isSystemGroup": false,
    "description": "",
    "isHidden": false,
    "isDeleted": false,
    "isArchived": false,
    "visibilityType": 0,
    "childGroupCount": 0
}

export const defaultUser: User = {
    "id": "",
    "displayName": "",
    "eMail": "",
    "imgId": "",
    "sex": "",
    "imgUrl": "",
    "position": "",
    "division": "",
    "phone": null
}

/**
 * default object template of BaseNews
 */
export const defaultBaseNews: BaseNews = {
    "id": "",
    "text": "",
    "isShowOnTop": false,
    "isSubscribed": true,
    // "isFavorite": false,
    "liked": false,
    "isNeedApprove": false,
    "likeText": "",
    "likeCount": 0,
    "date": new Date(),
    ts: Date.now(),
    "thanksCount": 0,
    "newstype": -1,
    "user": defaultUser,
    "allowReply": true,
    "commentsCount": 0,
    "systemNewsActionHtml": "",
    "header": "",
    "actions": [],
    group: defaultGroup,
    newComment: createDefaultReply()
}

