import actions from 'redux/actionsTypes/Skills'

const defaultSkills: any = {
    users: {

    },
    isLoading: false,
    isFinished: false
}

export function skillsReducer(state = defaultSkills, action: any) {
    switch (action.type) {
        case actions.ADD_USER_SKILLS: {
            const { userId, skills } = action.payload
            return {
                ...state,
                users: {
                    ...state.users,
                    [userId]: {
                        ...skills,
                        ...(state.users[userId] || {}),
                    }
                }
            }
        }

        case actions.UPDATE_SKILL: {
            const { userId, skill } = action.payload
            return {
                ...state,
                users: {
                    ...state.users,
                    [userId]: {
                        ...state.users[userId],
                        [skill.tag.id]: skill
                    }
                }
            }
        }

        case actions.DELETE_USER_SKILL: {
            const { userId, skillId } = action.payload
            return {
                ...state,
                users: {
                    ...state.users,
                    [userId]: {
                        ...state.users[userId],
                        [skillId]: undefined
                    }
                }
            }
        }

        case actions.SET_LOADING: {
            const { isLoading } = action.payload
            return {
                ...state,
                isLoading
            }
        }

        case actions.SET_FINISHED: {
            const { isFinished } = action.payload
            return {
                ...state,
                isFinished
            }
        }

        default: return state
    }
}

