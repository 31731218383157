import React, { FC, useEffect, memo, useState } from 'react'
import {
    cnClassName,
    Skills
} from './interfaces'
import './style.scss'
// import { Button } from 'uielements/src'
// import { ButtonBox } from 'uielements/src/ButtonBox/ButtonBox'
import i18n from 'localizations/i18n'
import { Button } from 'muicomponents/src/Button/Button'
import ButtonGroup from '@mui/material/ButtonGroup';

type IPresenterProps = Skills.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    create,
    setCreate,
    onCancle,
    onComplete,
    showControls,
    withError
}) => {
    useEffect(() => {
    }, [])

    const onCreate = () => setCreate(!create)

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Title')}>
            <h4 className={cnClassName('Header')}>{i18n.t("pryaniky.skills.header.1")}</h4>
        </div>
        {showControls &&
            <div className={cnClassName('CreateBox')}>
                {create ?
                    <ButtonGroup variant="contained" >
                        <Button size={'small'} className={cnClassName('Btn')} disabled={withError} variant={'contained'} onClick={onComplete}>{i18n.t("pryaniky.skills.save.on.1")}</Button>
                        <Button size={'small'} className={cnClassName('Btn')} variant={'outlined'} onClick={onCancle}>{i18n.t("pryaniky.skills.save.off.1")}</Button>
                    </ButtonGroup> :
                    <Button size={'small'} variant={'contained'} className={cnClassName('CreateBtn')} onClick={onCreate}>{i18n.t("pryaniky.skills.add.1")}</Button>}
            </div>
        }
    </div>
}

export default memo(Presenter);
