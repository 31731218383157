import { IClassNameProps } from "@bem-react/core";
import { IStateType as IState } from 'redux/store';
import { setContext, updateContext } from 'redux/actions/Widgets';
import { bindActionCreators } from 'redux';
import { Dispatch } from 'redux';

export interface INoticesProps extends IClassNameProps, INoticesOwnProps, INoticesStateToProps, INoticesActionsToProps { }

export interface INoticesOwnProps { }

export interface INoticesState {}

export type INoticesStateToProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: IState, props: INoticesOwnProps) => ({})

export type INoticesActionsToProps = ReturnType<typeof NoticesActions>

export const NoticesActions = (dispatch: Dispatch) => bindActionCreators({ 
  setContext, 
  updateContext 
}, dispatch);