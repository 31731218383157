
import React, { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
    loadTrackById
} from '../../redux/actions/TracksManage'
import actionsTypes from '../../redux/actionsTypes/TracksManage'
import { getTrackById } from '../../redux/sagas/TracksManage/selectors'
import { useIsLoading, useIsError } from 'utils/src/CommonRedux/LoadIndicator'

export const useLoadTrackByID = (id: string) => {
    const action = loadTrackById({ id })
    const isLoading = useIsLoading([action.type])
    const track = useSelector(getTrackById(id))
    const [img, setImg] = useState<any>({
        imgId: track?.imgId,
        imgUrl: track?.imgUrl
    })
    const [name, setName] = useState("")
    const { isFailed, errors } = useIsError([action.type])
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(action)
    }, [id])
    useEffect(() => {
        setName(track?.displayName || '')
    }, [track?.displayName])
    useEffect(() => {
        setImg({
            imgId: track?.imgId,
            imgUrl: track?.imgUrl
        })
    }, [track?.imgId, track?.imgUrl])

    return [
        isLoading,
        track,
        { isFailed, errors },
        { img, setImg, name, setName }
    ] as const
}