import { IListViewIdeasFieldsRenderProps, cnListViewIdeasFieldsRender } from './ListViewIdeasFieldsRender.index';
import * as React from 'react'
import './ListViewIdeasFieldsRender.scss'
import { IdeaFieldView } from '../../Fields/view/Field';
import { Button } from 'uielements/src';


export const ListViewIdeasFieldsRender: React.FC<IListViewIdeasFieldsRenderProps> = ({ data }) => {
    return (<div>
        {
            data.map((field, i) => {
                return (
                    <div key={i}>
                        {field.v.map((item) => {
                            return (
                                <div key={item.fieldId} className={cnListViewIdeasFieldsRender('Item')}>
                                    {/* {item.field.displayMode === "value" ? undefined :} */}

                                    {item.field.showOnCardView === true && item.value !== "" ? <div className={cnListViewIdeasFieldsRender('Field')}>
                                        <div className={cnListViewIdeasFieldsRender('Name')}>{item.field.name + ": "}<span className={cnListViewIdeasFieldsRender('Value')}>{item.value}</span></div>

                                    </div> : undefined}
                                </div>
                            )
                        })}


                    </div>
                )
            })
        }
    </div>);
}
