import { cn } from '@bem-react/classname';
import { IStateType as IState } from 'redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { IBodyProps } from 'utils/src/DialogCreator';

export interface IGroupInviteProps extends IGroupInviteOwnProps, IGroupInviteStateToProps {

}

export interface IGroupInviteOwnProps extends IBodyProps{
  gid: any;
  // isShown: boolean;
  // onClose: () => void;
  className?: string;
  isAdmin?: boolean;
}

export type IGroupInviteStateToProps = ReturnType<typeof mapGroupInviteStateToProps>

export const mapGroupInviteStateToProps = (state: IState, props: IGroupInviteOwnProps) => ({
  networks: getAuthUser(state).baseData.networks,
  // isAdmin: getAuthUser(state).baseData.isAdmin
});

export const cnGroupInvite = cn('GroupInvite')