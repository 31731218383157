import React, { FC, useRef } from 'react';
import { IInputLayout } from './InputLayout.index'
import './InputLayout.css'

export const _InputLayout: FC<IInputLayout> = ({
    icon, 
    children, 
    action, 
    suggester, 
    suggesterRender,
    className, 
    focus = false, 
    ...props }, ref) => {
    const suggesterRef = useRef<HTMLDivElement>(null);
    return (
        <div className={`InputLayout${className ? ' ' + className : ''}`}>
            <div className={`InputLayout-WorkZone${focus ? ' InputLayout-WorkZone__focus' : ''}`}>
                <div className='InputLayout-IconZone'>{icon}</div>
                <div ref={ref} className='InputLayout-EditZone'>{children}</div>
                <div className='InputLayout-ActionZone'>{action}</div>
            </div>
            <div ref={suggesterRef} className='InputLayout-Suggester'>{suggesterRender ? suggesterRender(suggesterRef) : suggester}</div>
        </div>
    )
}

export const InputLayout = React.forwardRef(_InputLayout);