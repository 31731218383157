import React, { FC, memo, useMemo } from 'react'
import {
    cnClassName,
    ITaskResultProps
} from './interfaces'
import './style.scss'
import { withPreloader } from '../Preloader/Preloader'
import { TestInfo as Skelet } from '../../Skelets/TestInfo/TestInfo'
import { withResultLoader } from 'LMSModule/utils/HOCs/withResultLoader'
import { withUnitContentLoader } from 'LMSModule/utils/HOCs/withUnitContentLoader'
import { useExamResults } from 'LMSModule/utils/hooks/useExamResults'
import TastResult from '../../components/TaskResult'
import { Actions } from './../TestResult/Actions/Actions'
import {
    isHideAttempt,
    isLastUnit
} from 'LMSModule/utils/result.utils'

export const Presenter: FC<ITaskResultProps> = ({
    className,
    children,
    cid,
    result,
    unit,
    sid,
    unitContent,
    attemptAction,
    ...props
}) => {
    const {
        attempts: { attempt, cangeAttempt },
        detailsRender,
        unitResult,
        version,
        isAbsolutelyCompleted,
        notCompletedUnits,
        additionalAttempts
    } = useExamResults(sid, unit.id, result.user.id, cid)
    return <>
        <TastResult
            {...props}
            attempt={attempt}
            cangeAttempt={cangeAttempt}
            detailsRender={detailsRender}
            isAbsolutelyCompleted={false}
            unitContent={unitContent}
            unitResult={unitResult}
            // detailsRender={detailsRender}
            // attempt={attempt}
            actions={<Actions
                // className={cnClassName('Actions')}
                attemptAction={attemptAction}
                hideAttempt={isHideAttempt(unitResult.results, unit.maxAttemptsCount, isAbsolutelyCompleted, additionalAttempts)}
                sid={sid}
                unit={unit}
                isLastUnit={isLastUnit(notCompletedUnits)} />}
        // cangeAttempt={cangeAttempt}
        // unitResult={unitResult}
        // version={version} 
        />
    </>
}

const WithPreloader = withPreloader(memo(Presenter), ['result', 'unitContent'], [], <Skelet />)

export const WithUnitContentLoader = withUnitContentLoader(withResultLoader(WithPreloader));

export default memo(WithUnitContentLoader);
