import React, { useState, FC } from 'react'
import { CalendarEventLayout } from 'muicomponents/src/CalendarEventLayout/CalendarEventLayout'
import CakeIcon from '@mui/icons-material/Cake';
import { styled } from '@mui/material/styles';
import AccessAlarms from '@mui/icons-material/AccessAlarms';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip'
import Typography from '@mui/material/Typography'
import { Link } from 'muicomponents/src/Link/Link'

// const Icon: any = styled(CakeIcon)({
//     color: '#C344BE'
// })
export const BirthdayEvent: FC<any> = ({ event }) => {
    return <>
        <CakeIcon sx={{ color: `color-mix(in srgb, ${event.eventColor} 25%, white) !important`, marginRight: '8px' }} />
        <Tooltip overflowOnly title={`${event.user.displayName}`}>
            <Typography
                sx={{ fontSize: '14px', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '22px' }}
            ><Link sx={{ color: `color-mix(in srgb, ${event.eventColor} 25%, white) !important`, textDecoration: 'none' }} href={event.url}>{event.user.displayName}</Link></Typography>
        </Tooltip>
    </>
}