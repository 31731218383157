import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Button, Spinner, SplitButton, Icon } from 'uielements/src'
import Search from './Filters/Search'
import UsersList from './UsersList'
import MaterialsList from './MaterialsList'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import { IButtonProps } from 'uielements/src/Button/Button.index'
import withConfirmDialog, { IWithLoadSpin } from 'blocks/HOCs/Button/withConfirmDialog'
import CourseTabs, { ITabType } from '../../components/Tabs'

const LoadButton = withLoadSpin(Button)
const ConfirmBtn = withConfirmDialog<IButtonProps>(Button)

export const Presenter: FC<IPresenterProps> = ({
    className,
    loadUsersStat,
    loadMaterialStat,
    count,
    isFinished,
    isLoading,
    values,
    keys,
    match
}) => {
    const TABS = [
        { value: 'users', content: tTr('student_list.tabs.users.1') },
        { value: 'groups', content: tTr('student_list.tabs.materials.1') }
    ]
    const [tab, setTab] = useState<ITabType>(TABS[0]);
    const [search, setSearch] = useState('')


    useEffect(() => {
        setSearch('')
    }, [tab])


    return <div className={cnClassName({}, [className])}>
        {/* <div className={cnClassName('Aside')}>
            aside
        </div> */}
        <div className={cnClassName('Section')}>
            <div className={cnClassName('SearchBox')}>
                {/* <div className={cnClassName('ActionsBox')}>
                    <Checkbox
                        className={cnClassName('Select')}
                        checked={selected.length === keys.length}
                        onChange={onSelectAll}
                        name="selectAllItem"
                        color="primary"
                    />
                    <SplitButton<IWithLoadSpin & IButtonProps> isOpen={() => { }} className={cnClassName('ActionMenu')}
                        // button={<Button target={'_blank'} theme={'unstyled_center'} className={cnClassName('ActionBtn')}>
                        //     <Icon icon={'cog'} />
                        // </Button>}
                        ItemComponent={ConfirmBtn}
                        options={actions} />
                </div> */}
                {tab.value === 'users' && <Search key={tab.value} className={cnClassName('Search')} isLoading={isLoading} search={search} onChange={setSearch} />}
            </div>

            <div className={cnClassName('List')}>
                <CourseTabs className={cnClassName('Tabs')} onChange={setTab} selected={tab} tabs={TABS} />

                {tab.value === 'users' ? <UsersList search={search} /> : <MaterialsList />}
            </div>
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
