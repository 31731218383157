import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'



///////////
export declare namespace StatisticsFull {
    export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

    export interface IState { }

    export interface IOwnProps extends IClassNameProps {

    }

    export type IStateProps = ReturnType<typeof mapStateToProps>

    export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
}
////////////

type IOwnProps = StatisticsFull.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    currentUser: getCurrentUser(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({

}, dispatch);

////////////

export const cnClassName = cn('LMSTOStatisticsFull');