import React, { FC, useMemo, useState } from 'react';
import { cnSurveyFinish, SurveyFinishProps } from './SurveyFinish.index';
import MDRender from 'uielements/src/CommonmarkRender';
import { Button } from 'muicomponents/src';
import { useSurveyFinish } from './SurveyFinish.hooks';
import { SurveyFinishBadgeBox, SurveyFinishBadgeButtonsBox, SurveyFinishBadgeDescriptionBox, SurveyFinishBadgeDescriptionTypography, SurveyFinishBox, SurveyFinishTextTypography, SurveyFinishWarningTypography } from './SurveyFinish.styled';
import { Avatar } from 'muicomponents/src/Avatar';
import { Translate } from 'localizations/Translate';
import { publishSurveyResult } from 'utils/src/requests/admin.surveys';
import { toast } from 'react-toastify';
import { IRequest } from 'utils/src/requests/service';
import { useSurveyStart } from '../SurveyStart/SurveyStart.hooks';
import { LoadingButton } from 'muicomponents/src';

let request: IRequest;

export const SurveyFinish: FC<SurveyFinishProps> = ({
  preview
}) => {

  const {
    result,
    adminResult,
    dataSurveyPostNews,
    allowMultiAttempts,
    surveySessionId
  } = useSurveyFinish();

  const showBadges = useMemo(() => {
    return !!adminResult.badges?.length;
  }, [adminResult.badges?.length]);


  const [showButton, setShowButton] = useState(true);
  const [showWarning, setShowWarning] = useState(false);

  const onPublishResult = () => {
    setShowButton(false);

    publishSurveyResult(result.userAnswerLogId)
      .r
      .then((r: any) => {
        if (r.error_code === 0) {
          toast.success('Вы поделились результатом прохождения опроса')
        } else if (r.error_code === 2000) {
          toast.error('Ошибка! Вы уже поделились результатом прохождения опроса')
        } else {
          toast.error('Ошибка')
        }
      });
  };
  const {
    loading,
    startSurvey
  } = useSurveyStart({preview});

  if (!result && !adminResult) return null;

  return (
    <SurveyFinishBox className={cnSurveyFinish()}>
      <SurveyFinishTextTypography className={cnSurveyFinish('Text')}>
        <MDRender source={adminResult?.text || result?.text || ''} textCut={false} />
      </SurveyFinishTextTypography>

      {
        allowMultiAttempts &&   <LoadingButton
        className={cnSurveyFinish('SurveyStartBTN')}
        variant={'contained'}
        loading={loading}
        onClick={startSurvey}
      >
        <Translate i18nKey={'pryaniky.survey.oneMoreTime'} />
      </LoadingButton>
        
       
      }
      {
        showBadges &&
        // вынесла так, т.к. локалхост ломится в дев, а на деве 500 при прохождении а починить надо в релиз. 
        adminResult.badges?.length > 0 &&
        adminResult?.badges?.map((badge: any) => {
          if (!badge.isActive) return null;
          return (
            <SurveyFinishBadgeBox
              key={badge.id}
              className={cnSurveyFinish('Badge')}
            >
              <div>Вы получили бейдж {badge.displayName}</div>
              <SurveyFinishBadgeDescriptionBox className={cnSurveyFinish('Badge-Description')}>
                <Avatar
                  src={badge.iconUrl}
                  alt={badge.displayName}
                  sx={{
                    width: '128px',
                    height: '128px'
                  }}
                />
                <SurveyFinishBadgeDescriptionTypography>
                  {badge.description}
                </SurveyFinishBadgeDescriptionTypography>
              </SurveyFinishBadgeDescriptionBox>
            </SurveyFinishBadgeBox>
          )
        })
      }
      <SurveyFinishBadgeButtonsBox className={cnSurveyFinish('Buttons')}>
        {
          dataSurveyPostNews &&
          showButton &&
          <Button
            variant='contained'
            onClick={() => {
              preview ? setShowWarning(!showWarning) : onPublishResult()
            }}
          >
            <Translate i18nKey={'pryaniky.survey.saveReward'} />
          </Button>
        }
        {
          showWarning &&
          <SurveyFinishWarningTypography className={cnSurveyFinish('Warning')}>
            <Translate i18nKey={'pryaniky.survey.saveReward.isNotPosible'} />
          </SurveyFinishWarningTypography>
        }
      </SurveyFinishBadgeButtonsBox>
    </SurveyFinishBox>
  );
};