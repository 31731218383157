import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ConnectedRouter } from 'connected-react-router';
import { store } from './redux/store';
import { history } from './redux/browserHistory';
// import { Integrate } from 'blocks/Integrate/Integrate';
import { WIntegrate } from 'blocks/WIntegrate/WIntegrate';
import { generateWidget } from 'i.widgets';
import { addWidget } from 'redux/actions/Widgets';
import { Frame } from 'render.in.iframe';
import { IWIntegarteWidgetItem } from 'blocks/WIntegrate/WIntegrate.index';
import { setCookie } from 'utils/src/utils';

const attributes = ['pwidget-name'];
let authAttrs = ['.PRYANIKYAC', 'authUid', 'authAt'];

const auth = () => {
  const cookie: any = document.cookie.split(';').reduce((acc, cur) => {
    const [name, value] = cur.split('=');
    return { ...acc, [name]: value }
  }, {});
  return cookie['.PRYANIKYAC'] && cookie['authUid'] && cookie['authAt'];
}

const ckechModalRoot = () => {
  const modalRoot = document.getElementById('RootModal-l1');
  if (!modalRoot) {
    const div = document.createElement('div');
    div.setAttribute('id', 'RootModal-l1');
    document.body.append(div);
  }
}

export default function render() {
  const root = document.getElementById('pryaniky-widget-root');
  if (!root) return;
  if (!auth()) {
    Array.from(root.attributes).forEach(el => {
      const index = authAttrs.map(el => el.toLowerCase()).indexOf(el.nodeName.toLowerCase());
      if (index !== -1) {
        const name = authAttrs[index];
        setCookie({ name, value: root.getAttribute(name), props: { domain: window.location.hostname } });
        // document.cookie = `${name}=${root.getAttribute(name)};domain=${window.location.hostname};path=/; samesite=None; secure`;
      }
    });
    console.log('DIV_WIDGETS. INIT. auth: false');
  } else {
    console.log('DIV_WIDGETS. INIT. auth: true');
  }

  ckechModalRoot();

  const elems = Array.from(document.getElementsByClassName('pryaniky-div-widget')).filter(el => !el.getAttribute('inited'));
  console.log('DIV_WIDGETS. elems:', elems);

  const widgets = elems.map((el): IWIntegarteWidgetItem | null => {
    console.log('DIV_WIDGETS. el:', el);
    const nameAttr = el.getAttribute('pwidget-name');
    if (!nameAttr) return null;
    const widget = generateWidget({
      type: nameAttr
    });
    // el.id = 'pryaniky-widget-' + widget.id;
    return {
      to: el,
      widget
    }
    // ReactDOM.render(
    //   <DragDropContextProvider backend={HTML5Backend}>
    //     <Provider store={store}>
    //       <ConnectedRouter history={history}>
    //         <Integrate data={{}} widget={widget} />
    //       </ConnectedRouter>
    //     </Provider>
    //   </DragDropContextProvider>,
    //   el
    // );
    // el.setAttribute('inited', 'true');
  });

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <WIntegrate widgets={widgets} />
      </ConnectedRouter>
    </Provider>
    , root);

}