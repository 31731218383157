import { IStateType } from 'redux/store';

const enable = {
    social: false
};

/**
 * возвращает состояние флагов аналитики
 * @param { IStateType } state
 */
export const getAnalytics = (state: IStateType) => state.store.appSettings?.analytics || enable;