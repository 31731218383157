import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { setContext } from 'redux/actions/Widgets';

export interface ITagProps extends IClassNameProps, IDispatchProps, IActionsDispatch, ReturnType<typeof mapStateToProps>, RouteComponentProps<{id: string}> { }

export interface ITagState {
  text?: string;
}

export const mapStateToProps = (state: any) => ({
  context: state.store.context,
})

export interface IActionsDispatch {
  setContext?: any;
}

export const mapActionsToProps: IActionsDispatch = {
  setContext
};

export const cnTag = cn('Tag');