import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IStateType } from 'redux/store';

export interface IStatisticProps extends IClassNameProps, IStatisticOwnProps, IMapStateToProps {}

export interface IStatisticOwnProps {
  tag?: React.ComponentType | 'div';
  context: any;
  data: IStatisticData;
  edit: boolean;
}

interface IStatisticData {
  chart: string;
  datesType: string;
  module: string;
  statistic: string;

  beginDate: Date | undefined;
  endDate: Date | undefined;
  maxItems: number | undefined;
  quests: any[] | undefined;
  quizzes: any[] | undefined;
  achievementGoals: any[] | undefined;
  workflows: any[] | undefined;
  postTypes: any[] | undefined;
  [s: string]: any;
}

export const mapStateToProps = (state: IStateType) => {
  return {
    
  }
}

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

export const cnStatistic = cn('StatisticWidget');