import React, { FC, useState, useEffect } from 'react';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { cnWorkflowsChangeStatus, IWorkflowsChangeStatusLoaderProps, IWorkflowsChangeStatusProps, mapStateToProps } from './ChangeStatus.index';
// import {
//   DialogTitle,
//   DialogActions,
//   DialogContent,
// } from 'uielements/src';
// import { Button } from 'uielements/src/MaterialElements/Button/Button';
import { Translate } from 'localizations/Translate';
import Progress from 'uielements/src/MaterialElements/Progress';
import FieldRender from 'uielements/src/FieldRender'
import './ChangeStatus.scss';
import { connect } from 'react-redux';
import { DialogBody } from 'muicomponents/src/DialogParts/DialogBody/DialogBody'
import { workflows } from 'utils/src/requests/requests.news';
import { useQueryEx, useDependedState } from 'utils/src/hooks'
import { WorkflowsValidator, workflowsValidate } from 'blocks/NewsTypes/Workflows/Workflows.validate';


const bv = new WorkflowsValidator();
bv.setRules(workflowsValidate);

const WorkflowsChangeStatusPresenter: React.FC<IWorkflowsChangeStatusProps> = ({
  handleClose,
  handleAccept,
  onChangeFields,

  loading,
  data,
  status
}) => {

  const [validationResults, setValidationResults] = useState<any>([]);

  useEffect(() => {
    if (data?.length) setValidationResults(bv.workflowsAdditionalFieldsValuesValidate(data));
  }, [data?.length])

  const onChange = (fields: any) => {
    onChangeFields(fields)
    setValidationResults(bv.workflowsAdditionalFieldsValuesValidate(fields));
  };

  let disableSave = loading
  disableSave = validationResults.length > 0 || disableSave
  return <DialogBody
    acceptText={<Translate i18nKey='save' />}
    closeText={<Translate i18nKey='cancel' />}
    isLoading={loading}
    header={<Translate i18nKey={`pryaniky.post.workflows.changeToStatus`} values={{ status: status?.name }} />}
    onAccept={() => handleAccept(data || {})}
    onClose={handleClose}
  >
    {loading ?
      <Progress size={42} /> :
      <div>
        {data?.length && <FieldRender onChange={onChange} fields={data} />}
      </div>
    }
  </DialogBody>

}

const DataLoader: FC<IWorkflowsChangeStatusLoaderProps> = ({
  newsId,
  statusId,
  ...props
}) => {
  const { isLoading, result } = useQueryEx(() => workflows.getStatusFields(newsId, statusId))
  const [data, setData] = useDependedState((result as any)?.additionalFieldsValues, [(result as any)?.additionalFieldsValues])
  return <WorkflowsChangeStatusPresenter
    {...props}
    loading={isLoading}
    data={data}
    newsId={newsId}
    statusId={statusId}
    onChangeFields={setData}
  />
}

export const changeStatusModalNoRedux = createModal(DataLoader, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  TransitionComponent: TransitionComponent,
  scroll: 'body'
});

export const changeStatusModal = createModal(connect(mapStateToProps, undefined)(WorkflowsChangeStatusPresenter), {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  TransitionComponent: TransitionComponent,
  // className: 'Pane BackdropOff Right',
  scroll: 'body'
});