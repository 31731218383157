import React, { useEffect } from 'react'
import { IEditorProps } from './Editor.index'
import './Editor.scss';
import FieldRender from 'uielements/src/FieldRender'
import { withEditorContext } from '../../../EditorForm/EditorForm'
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import i18n from 'localization/src/i18n';


const Wc = withEditorContext(({ onFocus }: any) => {
    onFocus()
    return <></>
})
export const Editor: React.FC<IEditorProps> = ({
    children,
    data,
    onChangeText,
    onChangeHeader,
    allowRichEdit,
    // draftState,
    changeAdditional,
    additionalFields = [],
    setAutoShort = (state: boolean) => { },
    autoShort = true,
    postType,
}) => {


    const isDeleted = postType?.isDeleted;

    let currentFields = additionalFields;
    // editor preview
    if (window.location.href.indexOf('settingsPreview=true') !== -1) {
        let dt = localStorage.getItem('settingsPreview');
        if (dt) currentFields = JSON.parse(dt) as any;
    } else {
        let dt = localStorage.getItem('settingsPreview');
        if (dt) localStorage.removeItem('settingsPreview');
    }

    const fields = currentFields.reduce((acc, category: any) => ([...acc, {
        ...category,
        fields: category.fields
            .filter((field: any) => !field.defaultFieldName)
            .map((field: any) => {
                if (field.type === 'Photo') field.actionText = i18n.t('pryaniky.post.create.photo')
                if (field.type === 'Photo') field.actionText = i18n.t('pryaniky.post.create.files')
                return field
            })
    }]), [])


    if (isDeleted) return <div className={'Editor EditorWorkflows'}>
        <span className="EditorWorkflows-Deleted">
            {i18n.t('pryaniky.post.workflows.deleted')}
        </span>
    </div>
    
   
    return <div className={'Editor EditorWorkflows'}>
        <Wc />
        <FieldRender fields={fields} onChange={changeAdditional} />
        {children}
    </div>
}