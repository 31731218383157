import React, { ComponentProps } from 'react';
import * as utils from 'utils/src/utils';

// import { withBemMod } from '@bem-react/core';

import { cnWidget } from '../Widget.index';
import { IWidgetTypeTabsProps } from './Widget_type_tabs.index';
import './Widget_type_tabs.scss';
// import { Tabs } from 'blocks/Tabs/Tabs';
import { default as WidgetPresenter } from '../Widget';
import { widgets } from "i.widgets";
import { Tabs as TabsMui } from '../../TabsMui';
import { PreviewParams } from 'utils/src/PreviewParams';

const types = {
  common: require('blocks/Tabs/Tabs').Tabs,
}

const type = 'tabs'

export default class WidgetTypeTabsPresenter extends WidgetPresenter<IWidgetTypeTabsProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    if(PreviewParams.inst.getShowModule('Mui5Tabs')) {
      return (
        <widgets.components.common {...this.props} className={cnWidget({type, subtype})}>
          <TabsMui
            tabs={(this.props.widget.data || []) as unknown as ComponentProps<typeof TabsMui>['tabs']}
            context={this.props.wcontext}
            updateContext={(context) => {
              this.props.updateContext(this.props.widget.id, context)
            }}
          />
        </widgets.components.common>
      )
    }
    return <Component {...this.props} className={cnWidget({type, subtype})} tabs={this.props.widget.data || []} context={this.props.wcontext} tag={widgets.components.common} />
  }

}

// export const withWidgetTypeTabs = withBemMod<IWidgetTypeTabsProps>(
//   cnWidget(),
//   { type: 'tabs' }, 
//   (Presenter) => (
//     (props) => {
//       // delete props.pageData.view;
//       return (
//         <Presenter {...props} children={
//           <Tabs tabs={props.widget.data || []} pageData={props.pageData} />
//         }/>
//       )
//     }
//   )
// );