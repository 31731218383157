import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext } from '../../contexts/news'
import MDRender from 'uielements/src/CommonmarkRender'
// import MDRender from 'muicomponents/src/CommonmarkRender'

type IPresenterProps = News.TextRender.IPresenterProps
type IOwnProps = News.TextRender.IOwnProps
type IDispatchProps = News.TextRender.IDispatchProps
type IStateProps = News.TextRender.IStateProps
type IState = News.TextRender.IState


export const Presenter: FC<IPresenterProps> = ({
    isSingle,
    text,
    defaultTextTruncateLength,
    newsId,
    newsViewFull
}) => {
    let maxCharCount = 250
    // maxCharOffset = 100
    if (defaultTextTruncateLength > 0) {
        maxCharCount = defaultTextTruncateLength
    }

    return <MDRender textCut={!isSingle} maxCharCount={maxCharCount} source={text} onOpen={() => newsViewFull(newsId)} />
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)), ['isSingle', 'newsId']);
