import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from 'redux/connector';
import { IContext } from 'store/vm';

export interface IUsersProps extends IClassNameProps, IUsersStateProps, IDispatchProps {
  tag?: 'div';
}

export interface IUsersState {
  text?: string;
}

export interface IUsersStateProps {
  store: any,
  context: IContext;
  structure: any;
}

export const mapStateToProps = (state: any) => ({
  store: state.store,
  context: state.store.context,
  structure: state.store.structure
})

export const cnUsers = cn('Users');