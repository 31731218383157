import React, { FC, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { IChangeNetworkProps, cnChangeNetwork, mapChangeNetworkStateToProps } from './ChangeNetwork.index';
import './ChangeNetwork.scss';
import { createModal, defaultDialogProps } from 'utils/src/DialogCreator';
import { 
    FormControl,
    InputLabel,
    Select,
    CircularProgress,
    MenuItem,
    Chip
} from '@material-ui/core';
import {
    Cancel as CancelIcon
} from '@material-ui/icons';
import { checkResponseStatus } from 'utils/src/utils';
import {
    getUserNetworks,
    setUserNetworks
} from 'utils/src/requests/admin.subnetworks';
import { NSubnetworksAPI } from 'utils/src/requests/models/admin.subnetworks';
import { Translate } from 'localizations/Translate';
import Body from 'uielements/src/MaterialElements/DialogParts/DialogBody';
import { toast } from 'react-toastify';

const roleToEmployee = 'Employee';

const ChangeNetworkPresenter: FC<IChangeNetworkProps> = ({
    id: userId,
    networkSettings,
    handleAccept: handleAcceptProps,
    handleClose,
}) => {

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ networks, setNetworks ] = useState<NSubnetworksAPI.NetworkWithRoles[]>([]);
    const [ isSaving,  setIsSaving ] = useState<boolean>(false); 

    const isMulti = useMemo(() => {
        return !networkSettings?.oneUserInOneNetwork
    }, [ networkSettings?.oneUserInOneNetwork ]);

    useEffect(() => {
        setIsLoading(true);
        getUserNetworks({ userId }).then(d => {
            if(checkResponseStatus(d)) {
                setNetworks(d.data);
                setIsLoading(false);
            }
        });
    }, []);

    const onChange: Parameters<typeof Select>['0']['onChange'] = (e) => {
        const selectedId = e.target.value;
        if(isMulti) {
            setNetworks(networks.map(el => {
                return {
                    ...el,
                    roles: el.id === selectedId ? [...(el.roles || []), roleToEmployee] : el.roles
                };
            }))
        } else {
            setNetworks(networks.map(el => {
                const rolesWithoutEmployee = (el.roles?.filter(r => r !== roleToEmployee) || null)
                return {
                    ...el,
                    roles: el.id === selectedId ? [...(rolesWithoutEmployee || []), roleToEmployee] : rolesWithoutEmployee
                };
            }));
        }
    }

    const onRemoveNetwork = (id: NSubnetworksAPI.NetworkWithRoles['id']) => {
        const prepairedNetwroks = networks.map(el => {
            return {
                ...el,
                roles: el.id === id ? (el.roles?.filter(r => r !== roleToEmployee) || null) : el.roles
            };
        });
        if(prepairedNetwroks.filter(e => e.roles?.includes(roleToEmployee)).length) {
            setNetworks(prepairedNetwroks);
        }
    }

    const handleAccept: Parameters<typeof Body>['0']['onAccept'] = () => {
        setIsSaving(true);
        setUserNetworks({
            userId,
            networks
        }).then(d => {
            if(checkResponseStatus(d)) {
                toast.success(<Translate i18nKey='saved' />);
                handleAcceptProps({});
            } else {
                toast.error(<Translate i18nKey='error' />);
            }
        }).catch(() => {
            toast.error(<Translate i18nKey='error' />);
        });
    }

    return (
        <Body
            className={cnChangeNetwork()}
            header={
                <Translate i18nKey={`pryaniky.user.network.change.title${isMulti ? '.multi' : ''}`} />
            }
            acceptText={Translate.t({ i18nKey: "pryaniky.user.network.change.save" })}
            closeText={Translate.t({ i18nKey: "pryaniky.user.network.change.cancel" })}
            onClose={() => handleClose()}
            onAccept={handleAccept}
        >
            <div className={cnChangeNetwork('Body', { isLoading })}>
                {
                    isLoading ?
                    <CircularProgress size={20} /> :
                    <FormControl
                        fullWidth
                        className={cnChangeNetwork('Selector')}>
                        <InputLabel htmlFor="networks">
                            <Translate i18nKey={`pryaniky.user.network.change.select.title${isMulti ? '.multi' : ''}`} />
                        </InputLabel>
                        <Select
                            value={networks.filter(e => e.roles?.includes(roleToEmployee))}
                            onChange={onChange}
                            inputProps={{
                                id: 'networks',
                            }}
                            renderValue={(value) => {
                                const selected = value as typeof networks;
                                return (
                                    <div className={cnChangeNetwork('Chips')}>
                                        {selected.map((el) => (
                                            <Chip
                                                key={el.id}
                                                label={el.title}
                                                deleteIcon={
                                                    <CancelIcon
                                                        onMouseDown={(event) => event.stopPropagation()}
                                                    />
                                                }
                                                onDelete={(e) => {
                                                    onRemoveNetwork(el.id);
                                                }}
                                            />
                                        ))}
                                    </div>
                                )
                            }}
                            >
                            {
                                networks.filter(e => !e.roles?.includes(roleToEmployee)).map(el => (
                                    <MenuItem key={el.id} value={el.id}>
                                        {el.title}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                }
            </div>
        </Body>
    )
}

export const ChangeUserNetwork = createModal(
    connect(
        mapChangeNetworkStateToProps
    )(ChangeNetworkPresenter), {
    ...defaultDialogProps,
    fullScreen: false,
    maxWidth: "md"
});