import React, { FC } from 'react'
import { Button, Icon } from 'uielements/src'
import { cn } from '@bem-react/classname';
import Layout from '../ItemLayout/ItemLayout'

export const cnFileItem = cn('FolderItem');

export interface IPresenterProps {
    showControls?: boolean;
    name: string;
    previewUrl: string;
    displayUserName: string;
    onSelect: any;
    selected: boolean;
    date: any;
    fid: string;
    urlname: string;
    actions: any[];
}

const Presenter: FC<IPresenterProps> = ({
    showControls = false,
    name,
    previewUrl,
    displayUserName,
    onSelect,
    selected,
    date,
    fid,
    urlname,
    actions
}) => {
    return <Layout
        showActions={true}
        primaryAction={<Button target={'_blank'} type='link' theme={'unstyled_center'} className={cnFileItem('ActionBtn')} href={`/ru/Data/v3/Attachment/${fid}/${urlname}`}>
            <Icon icon={'file-download'} />
        </Button>}
        actions={actions}
        dataNode={<div>{displayUserName}</div>}
        dateNode={<div>{(new Date(date)).toLocaleString()}</div>}
        icon={previewUrl}
        nameNode={<div>{name}</div>}
        onSelect={onSelect}
        selected={selected}
        showChekbox={showControls}
    />
}
export default Presenter