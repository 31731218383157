import { IPageCommentsProps, cnPageComments, IPageCommentsState, mapStateToProps, mapDispatchToProps } from './PageComments.index';
import React, { FC, useEffect } from 'react';
import './PageComments.scss'
import { Button, Icon, CustomScrollbars } from 'uielements/src';
import i18n from '../../../localizations/i18n';
import { CommentsLayout } from 'blocks/NewsTypes/common/CommentsLayout/CommentsLayout';
import { ReplyEditor } from 'blocks/ReplyEditor/ReplyEditor';
import * as utils from 'utils/src/utils';
import { ReplySkeleton } from 'blocks/Skeletons/Replys/Reply-Skeleton';
import { replyWithNewsContext as Reply } from '../../Reply/Reply';
import { Comments } from 'LMSModule/LMS/Comments/Comments';
import { connect, useDispatch, useSelector } from 'react-redux';
import NewsComments from 'News/containers/NewsComments';
import { NewsContext, NewsContextProps } from 'News/contexts/news';
import { setNewsList } from 'News/redux/actions';
import { normalizeNews } from 'News/redux/saga/utils';
import { getNewsById } from 'News/redux/saga/selectors';

const CommentsWrapper: FC<{news: any}> = ({
    news: newsProps
}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setNewsList({ ...normalizeNews([newsProps]), group: 'wiki' }));
    }, []);

    const news = useSelector(getNewsById(newsProps.id));
    
    const context = React.useMemo<NewsContextProps>(() => {
        return {
            isSingle: false,
            isRepost: false,
            toggleEdit: (enable: boolean) => {},
            edit: false,
            ViewComponent: undefined,
            virtualize: false,
            newsId: newsProps.id,
            viewClassName: '',
            disableEdit: false
        }
    }, [])

    if(!news) return null;

    return (
        <NewsContext.Provider value={context}>
            <div className={cnPageComments("Comments")}>
                <NewsComments />
            </div>
        </NewsContext.Provider>
    );
};

class PageCommentsPresenter extends React.Component<IPageCommentsProps, IPageCommentsState > {
    public el: React.RefObject<HTMLDivElement> | null = React.createRef<HTMLDivElement>();
    public bodyContentRendererRef: React.RefObject<React.ComponentType> | null = React.createRef<React.ComponentType>();
    private _viewed: boolean = false;
    private platform: string;
    private mobile: boolean;
    private isInFrame =
        window.location.pathname.includes('/wv/') ||
        window.location.pathname.includes('/widgets/') ||
        window.location.pathname.includes('/Widgets/');
  // private i18n: any;
    // public state = {
    //     thank: false,
    //     edit: false,
    //     liked: false,
    //     additionalReplyActions: undefined,
    //     additionalReplyForm: () => ''
    // };

    constructor(props: IPageCommentsProps) {
        super(props);
        this.state = {
            w: '100%'
        }
    }

    // const[fw, setfw] = React.useState('100%');

    private setFooterWidthWrap = () => {
        this.setFooterWidth(this.props.parentRefEl)
    }
    private setFooterWidth = (el: HTMLElement | undefined) => {
        // el && (ref = el);
        el && this.setState({ w: `${el.clientWidth}px` });
    }

    // React.useEffect(() => {
    //     setFooterWidth(parentElement);
    //     window.addEventListener('resize', () => setFooterWidth(parentElement))
    //     return () => {
    //         window.removeEventListener('resize', () => setFooterWidth(parentElement))
    //     }
    // }, [parentElement]);

    public componentDidMount() {
        if (this.props.parentRefEl) {
            this.setFooterWidth(this.props.parentRefEl);
            window.addEventListener('resize', this.setFooterWidthWrap)
        }
        // if (this.props.data) {
        //     const news = utils.clone(this.props.data);
        //     news.newsTypeMain = utils.newNewsType(news.newstype);
        //     const norm = normalizeData([news]);
        //     this.props.addNews({
        //         news: norm.entities.news,
        //         commentForms: {},
        //         newsIds: []
        //     });
        // }
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.setFooterWidthWrap)
    }

    public render() {
        // const { additionalReplyActions, additionalReplyForm, edit, liked: likedState } = this.state;
        const { id } = this.props.data;
        const { onClose, currentUser} = this.props;
        const comments = this.props.data.comments; // toSReplyMobileModel(this.state.data.comments);
        const customClassNames = this.platform + ' ';

        if (!this.props.data) return null;
        return (   
            <div className={cnPageComments("CommentsLayout")} style={{width: this.state.w }} >

                <div className={cnPageComments("CommentsLayout-Data")}>
                <div className={cnPageComments("Title")} >
                    <h3>{i18n.t("pryaniky.comment.title")}</h3>
                    <Button theme="unstyled" onClick={onClose}>
                        <Icon className={cnPageComments("Cross")} icon="cross-mark" />
                    </Button>
                </div> 

                <CustomScrollbars style={{ width: "100%", height: "100%", borderRadius: "8px" }}>
                    <div
                        id={cnPageComments()}
                        className={cnPageComments(
                            {
                                wv: this.isInFrame,
                                mobile: this.mobile,
                                auth: true
                            },
                            [customClassNames]
                        )}
                    >
                        <CommentsWrapper news={this.props.data} />
                        {/* <Comments className={cnPageComments("Comments")} nid={id}/>                   */}
                   </div>
                 </CustomScrollbars>
                </div>
            </div>
         
                );
    }
    // private loadAllReplyes = () => {
    //     this.props.loadCommentRequest(this.props.data.id, this.props.data.commentsCount - this.props.data.comments.length)
    // }
}

export const PageComments = connect(
    mapStateToProps,
    mapDispatchToProps
)(PageCommentsPresenter)