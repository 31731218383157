import { defaultNews } from 'News/redux/reducer/default'
import { TNewsReducerActions } from 'News/redux/actions/types'
import actions from './actionsType'

export const workflowsSubReducer = (state = defaultNews, action: TNewsReducerActions) => {
    
    switch (action.type) {

        default:
            return state;
    }
}  