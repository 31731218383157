import { zeroId } from 'LMSModule/utils/utils.lms'

export const answerQuestionModelCreator = ({ qid = zeroId, answerId = zeroId } = {}) => ({
    qid,
    "time": 0,
    "answers": [
        {
            answerId,
            "value": ""
        }
    ]
})