import {
  IHierarchyActions,  
  IGetUsersHierarchyFromServerPayload  
} from "./actions.interfaces";

import {
  IUserHierarchyData,
} from 'utils/src/requests/models/admin.userSettings'




// hierarchy actions
export const USERS_GET_USERS_HIERARCHY = 'USERS_GET_USERS_HIERARCHY'
export const USERS_SET_USERS_HIERARCHY = 'USERS_SET_USERS_HIERARCHY'


//Users Hierarchy actions

/**
 * get user hierarchy from server
 * @param { IGetUsersHierarchyFromServerPayload } payload 
 */
export function getUserHieararchyFromServer(payload: IGetUsersHierarchyFromServerPayload): IHierarchyActions {
  return {
    type: USERS_GET_USERS_HIERARCHY,
    payload
  }
}

/**
* set user hierarchy to redux
* @param { IUserHierarchyData[] } payload 
*/
export function setUserHieararchyToRedux(payload: IUserHierarchyData[]): IHierarchyActions {
  return {
    type: USERS_SET_USERS_HIERARCHY,
    payload
  }
}

