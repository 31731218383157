import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { SearchInput } from 'uielements/src';
import { Button, Avatar } from 'uielements/src';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import { AuthorsList } from '../../../../../components/AuthorsList/AuthorsList';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

type IPresenterProps = StatisticsFull.CourseListItem.IPresenterProps
type IOwnProps = StatisticsFull.CourseListItem.IOwnProps
type IDispatchProps = StatisticsFull.CourseListItem.IDispatchProps
type IStateProps = StatisticsFull.CourseListItem.IStateProps
type IState = StatisticsFull.CourseListItem.IState

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export const Presenter: FC<IPresenterProps> = ({
    className,
    courseSessions,
    name,
    id,
    largeImgId,
    largeImgUrl,
    authors
    // courseProgressPersentage,
}) => {
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    useEffect(() => {

    }, [])

    const { notStartedUsers, inProgressUsers, completeUsers, overdueUsers, totalUserCount } = (courseSessions || []).reduce((curr: any, acc: any) => {
        return {
            notStartedUsers: acc.notStartedUsers + curr.notStartedUsers,
            inProgressUsers: acc.inProgressUsers + curr.inProgressUsers,
            completeUsers: acc.completeUsers + curr.completeUsers,
            overdueUsers: acc.overdueUsers + curr.overdueUsers,
            totalUserCount: acc.totalUserCount + curr.totalUserCount,
        }
    }, { notStartedUsers: 0, inProgressUsers: 0, completeUsers: 0, overdueUsers: 0, totalUserCount: 0 })
    const isSingle = (courseSessions || []).length <= 1
    return <>
        <TableRow className={classes.root}>
            <TableCell>
                {!isSingle && <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>}
            </TableCell>
            <TableCell>
                <Avatar className={cnClassName('Avatar')} size={32} name={name} imgId={largeImgId} imgUrl={largeImgUrl} />
            </TableCell>
            <TableCell>
                <Button theme={'unstyled'} href={`/TeacherOffice/Course/${id}?tab=statistic`} type={'rlink'}>{name}</Button>
            </TableCell>
            <TableCell align={'center'}>
                <AuthorsList size={32} authors={authors} className={cnClassName('AuthorsList')} limit={5} fixedToolTip={true} />
            </TableCell>
            <TableCell>{totalUserCount}</TableCell>
            <TableCell>{notStartedUsers}</TableCell>
            <TableCell>{inProgressUsers}</TableCell>
            <TableCell>{completeUsers}</TableCell>
            <TableCell>{overdueUsers}</TableCell>
        </TableRow>
        {!isSingle && <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                            Потоки
              </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Поток</TableCell>
                                    <TableCell>Всего участников</TableCell>
                                    <TableCell>Не приступили</TableCell>
                                    <TableCell>В процессе</TableCell>
                                    <TableCell>Завершили успешно</TableCell>
                                    <TableCell>Завершили неуспешно</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(courseSessions || []).map((session: any) => {
                                    return <TableRow key={session.id}>
                                        <TableCell>{session.name}</TableCell>
                                        <TableCell>{session.totalUserCount}</TableCell>
                                        <TableCell>{session.notStartedUsers}</TableCell>
                                        <TableCell>{session.inProgressUsers}</TableCell>
                                        <TableCell>{session.completeUsers}</TableCell>
                                        <TableCell>{session.overdueUsers}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>}
    </>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));
