import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    rawState: any
    name: string
    fullscreen: boolean;
}

export const cnClassName = cn('LMSLesson');