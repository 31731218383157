import { ListItemText, styled } from '@mui/material';
import { Avatar } from 'muicomponents/src/Avatar';
import { ListItem } from 'muicomponents/src/ListItem/ListItem';
import { SplitButton } from 'muicomponents/src/SplitButton/SplitButton';
import { Typography } from 'muicomponents/src/'

export const StyledListItemText = styled(ListItemText)({
  justifyContent: "space-between",
  padding: 0,
})
export const StyledSplitButton = styled(SplitButton)({
  width: "32px",
  height: "32px",
  ".MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary": {
    backgroundColor: "#fff",
    borderRadius: "50%",
    minWidth: "32px",
    "&:hover": {
      borderRadius: "50%",
      backgroundColor: "#fff!important",
    }
  }
})

export const StyledAvatar = styled(Avatar)({
  width: '64px',
  height: '64px',
})

export const StyledListItem = styled(ListItem)({
  height: '80px',
  // overflow: 'hidden',
})

export const ItemDescription = styled(Typography)({
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  display: '-webkit-box',
  fontSize: 14,
  lineHeight: '18px',
  // whiteSpace:"wrap"
}) as typeof Typography;

export const ItemName = styled(Typography)({
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  display: '-webkit-box',
  fontSize: 14,
  lineHeight: '18px',
  // whiteSpace:"wrap"
}) as typeof Typography;