import { connect } from 'react-redux';
import * as React from 'react';
import { mapDispatchToProps } from "../../redux/connector";
import * as utils from 'utils/src/utils';

import { cnTag, ITagProps, ITagState, mapStateToProps, mapActionsToProps } from './Tag.index';
import queryString from 'query-string';
import './Tag.scss';
import { Page } from '../Page/Page';
import { TagBlock } from 'blocks/TagBlock/TagBlock';
import { pageNames } from 'redux/pageNames';

export class TagPresenter extends React.Component<ITagProps, ITagState> {
  public utils: any = utils;
  public el: HTMLElement | null;

  constructor(props: ITagProps) {
    super(props);
    // this.props.context.common.tId = this.props.match.params.id
    this.state = {};
  }

  public componentDidMount() {
    // if (this.props.setContext) this.props.setContext({
    //   paths: ['common'],
    //   innerPath: 'tId',
    //   value: this.props.match.params.id
    // })
  }

  public componentWillUnmount() {
    // this.props.context.common = {};
  }

  public componentDidUpdate(prevProps: ITagProps) {
    // const { gId } = queryString.parse(this.props.location.search)
    // if (this.props !== prevProps) {
    //   if (this.props.setContext) {
    //     this.props.setContext({
    //       paths: ['common'],
    //       innerPath: 'tId',
    //       value: this.props.match.params.id
    //     })
    //     if (gId) this.props.setContext({
    //       paths: ['common'],
    //       innerPath: 'gId',
    //       value: gId
    //     })
    //   }
    // }
  }

  public render() {
    return (
      <Page
        className={cnTag({ id: this.props.match.params.id })}
        page={{
          name: pageNames.tag + '.' + this.props.match.params.id,
        }} />
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const Tag = connect(
  mapStateToProps,
  mapDispatchToProps(
    { ...mapActionsToProps }
  )
)(TagPresenter)
