import React, { FC } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box } from 'muicomponents/src';


export const WidgetTypeSkeletonMUI:FC = () => {

    return <Box sx={{ m: 0, p: 2, pt: 0, mb: 2}}>
        <Skeleton variant="text" height={42} width={'80%'} sx={{ m: 0, p: 0, }} />
        <Skeleton variant="text" height={22} />
        <Skeleton variant="text" height={22} />
        <Skeleton variant="text" height={22} />
        <Skeleton variant="text" height={22} />
    </Box>
};