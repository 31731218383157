import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDSItem } from "redux/dataStorage/hooks";
import { checkResponseStatus, convertUserToShortUser, ResponseError } from "utils/src";
import { Vacancy } from "utils/src/BaseTypes/vacancy";
import { getVacancyById, vacancyUserRequestAction } from "utils/src/requests/admin.vacancies";
import { Translate } from 'localizations/Translate';
import { useDispatch, useSelector } from "react-redux";
import { DSSetItem } from "redux/dataStorage/actions";
import { v1 as uuid } from 'uuid';
import { useHistory } from "react-router";
import { getVacancyMock } from "utils/src/mock/vacancies.mock";
import { useMockParam } from "utils/src/mock/hooks";
import { vacancyI18nKey } from "localization";
import moment from "moment";
import { getCurrentUser } from "utils/src/CommonRedux/base/selectors";
import { CommentDialog } from "muicomponents/src";

export const useVacancyData = (id: Vacancy['id']) => {

    const dispatch = useDispatch();

    const currentUser = useSelector(getCurrentUser);
    const currentUserRef = useRef(currentUser);
    currentUserRef.current = currentUser;

    const {
        item,
        changeItem,
        isEdited
    } = useDSItem('vacancies', id);
    const itemRef = useRef(item);
    itemRef.current = item;

    const currentUserRequestSended = useMemo(() => {
        if(!item) return false;
        return item.requests.map(el => el.user.id).includes(currentUser.baseData.id);
    }, [item?.requests]);

    const [ requestSending, setRequestSending ] = useState(false);
    const sendUserRequestForVacancy = useCallback(async function() {
        try {
            setRequestSending(true);
            const { comment } = await CommentDialog({
                header: <Translate i18nKey={'pryaniky.vacancy.request.comment.header'} />,
                acceptButtonText: <Translate i18nKey={'send'} />,
            });
            const request: NonNullable<typeof item>['requests'][number] = {
                timeStamp: moment().toISOString(),
                hrNote: '',
                comment,
                id: uuid(),
                user: convertUserToShortUser(currentUserRef.current),
                status: 'Apply'
            };
            const response = await vacancyUserRequestAction({
                id,
                action: 'Apply',
                model: request
            });
            if(checkResponseStatus(response)) {
                changeItem({
                    requests: [
                        ...itemRef.current?.requests || [],
                        request
                    ]
                });
                toast.success(<Translate i18nKey={'pryaniky.vacancy.request.success'} />)
            } else {
                throw new ResponseError(response);
            }
        } catch (e) {
            if(
                (e as any).action !== 1&& (e as any).text !== "handle close modal"
            ) {
                toast.error(<Translate i18nKey={'pryaniky.vacancy.request.error'} />)
                console.error(e);
            }
        } finally {
            setRequestSending(false);
        }
    }, []);

    return {
        item,
        currentUserRequestSended,
        changeItem,
        isEdited,
        requestSending,
        sendUserRequestForVacancy
    };
};

export const useVacancy = (id: Vacancy['id']) => {

    const useMock = useMockParam();

    const dispatch = useDispatch();
    const history = useHistory();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ isSaving, setIsSaving ] = useState(false);

    const {
        item,
        changeItem,
        isEdited,
        sendUserRequestForVacancy,
        currentUserRequestSended
    } = useVacancyData(id);

    useEffect(() => {
        if(!item && id && id !== 'empty') {
            setIsLoading(true);
            if(useMock) {
                const data = getVacancyMock();
                dispatch(DSSetItem({ storage: "vacancies", id: id, item: { ...data, id } }));
                setIsLoading(false);
                return ;   
            }
            getVacancyById(id).then(d => {
                if(checkResponseStatus(d)) {
                    dispatch(DSSetItem({ storage: "vacancies", id: d.data.id, item: d.data }));
                } else {
                    toast.error(<Translate i18nKey={vacancyI18nKey('error')} />);
                }
                setIsLoading(false);
            });
        }
    }, []);

    return {
        item,
        changeItem,
        isEdited,
        isSaving,
        isLoading,
        sendUserRequestForVacancy,
        currentUserRequestSended
    };
};