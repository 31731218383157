import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { loadCoursesByCurrentUser } from '../../redux/actions/LMSTeacherOffice'
export interface ILMSTeacherOfficeProps extends IClassNameProps, ILMSTeacherOfficeStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
}

export interface ILMSTeacherOfficeState {

}

export interface ILMSTeacherOfficeStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    courses: state.LMSTO.courses.values,
    results: state.LMSTO.courses.results
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadCoursesByCurrentUser
}, dispatch);

export const cnLMSTeacherOffice = cn('LMSTeacherOffice');