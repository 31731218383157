import React from 'react';
import i18n from '../../../localizations/i18n';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { InputMention } from '../../InputMention/InputMention';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';

// RatingsInputControl
export const ratingsInput = (props: any) => {
  if (!props.visible) return null;
  const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  return <InputMention
    type='ratings'
    value={props.data[get_variable_name(props.uischema.scope)]}
    placeholder={schema.placeholder}
    noMulti={true}
    additionalRequestOpts={{ showtype: 'all' }}
    onChange={(selected: any) => {
      props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => selected))
    }} />
}
// end RatingsInputControl