import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ElementType } from 'react'


export interface IBableProps extends IClassNameProps
 {
  tag: ElementType | string;
}




export const cnBable = cn('Bable');