/**
 * @packageDocumentation
 * @module Widget_Settings_sliderlink
 */
import { JFdictionary } from "../dictionary";
import { GUID_EMPTY } from "utils/src/constants.prn";
import { ISliderItemData } from "utils/src/requests/models/api.slider";

const { renderers } = JFdictionary;

export interface ISliderLinkItem extends ISliderItemData {
  imgName: string;
  deleted: boolean;
}

export const emptyItem: ISliderLinkItem = {
  imgId: GUID_EMPTY,
  imgUrl: '',
  href: '',
  imgName: '',
  deleted: false,
  openInNewTab: false,
  inactive: false
}

export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.slider,
      scope: "#/properties/slider"
    },
  ]
}