import React from 'react'
import i18n from 'localizations/i18n'
import { connect } from 'react-redux'
import { mapDispatchToProps, IDispatchProps } from 'redux/connector'
import {
  DialogTitle,
  DialogActions,
  Button,
  cnDialog,
  Input,
  CheckboxInput,
  Select,
  Radio
} from 'uielements/src';
import { InputMention } from 'blocks/InputMention/InputMention';
import { toast } from 'react-toastify';
import { wrapDialogToRedux } from 'blocks/Dialogs/DialogWrapper';
import { IItemEditDialogStateProps, IItemEditDialogOwnProps, IItemEditDialogState, mapStateToProps, cnItemEdit } from './ItemEdit.index';
import { store } from 'redux/store';
import { modalChangeDate } from 'redux/actions/Modals';

import './ItemEdit.scss'
import { Translate } from 'localizations/Translate';

export class ItemEditDialogPresenter extends React.Component<any, IItemEditDialogState> {


  private dropdown = [
    {
      title: 'dropdown',
      value: 'dropdown',
    },
    {
      title: 'link',
      value: 'link',
    },
  ]

  constructor(props: any) {
    super(props);

    this.state = {
      selected: this.dropdown[0],
      onMobile: false,
    }
  }

  public render() {

    const { props } = this;
    const { onClose, data, onConfirm, onClick, type } = props;

    let modalData = {
      title: '',
      buttonText: '',
      isBody: false
    }

    if (type === 'edit') {
      modalData.title = i18n.t('pryaniky.menu.modal.change.title')
      modalData.buttonText = i18n.t('pryaniky.menu.modal.change.ok')
      modalData.isBody = true
    }
    else if (type === 'delete') {
      modalData.title = i18n.t('pryaniky.menu.modal.delete.title', { oldData: data.title })
      modalData.buttonText = i18n.t('pryaniky.menu.modal.delete.ok')
      modalData.isBody = false
    }
    else if (type === 'create') {
      modalData.title = i18n.t('pryaniky.menu.modal.add.title')
      modalData.buttonText = i18n.t('pryaniky.menu.modal.add.ok')
      modalData.isBody = true
      this.props.data.renderType = this.state.selected.value
    }
    else if (type === 'reset') {
      modalData.title = i18n.t('pryaniky.menu.modal.reset.title')
      modalData.buttonText = i18n.t('pryaniky.menu.modal.reset.ok')
      modalData.isBody = false
    }
    else {
      return;
    }

    return (
      <div className={cnItemEdit()}>
        <div className={cnItemEdit('Header')}>
          <DialogTitle onClose={onClose}>{modalData.title}
          </DialogTitle>
        </div>
        {
          modalData.isBody ?
            <div className={cnItemEdit('Body')}>
              <Input
                placeholder={i18n.t('pryaniky.menu.modal.change.itemName')}
                icon="edit"
                onChange={this.onTitleChange}
                className={cnDialog('Input')}
                value={data.title}
              />
              {type === 'create' &&
              // <div>
              //   <Radio checked={data.renderType === 'link'} onChange={this.onRenderTypeChange('link')} text={i18n.t('pryaniky.menu.modal.add.type.link')} />
              //   <Radio checked={data.renderType === 'dropdown'} onChange={this.onRenderTypeChange('dropdown')} text={i18n.t('pryaniky.menu.modal.add.type.dropdown')} />
              // </div>
                <Select
                  className={cnItemEdit('Select')}
                  selected={this.state.selected}
                  options={this.dropdown.map(el => ({ ...el, title: Translate.t({ i18nKey: `pryaniky.menu.modal.add.type.${el.title}` })}))}
                  onChange={this.handleChangeSelect}
                  noDefault={true}
                />
              }
              {
                data.renderType === 'link' &&
                <Input
                  placeholder={i18n.t('pryaniky.menu.modal.change.link')}
                  icon="link"
                  onChange={this.onLinkChange}
                  className={cnDialog('Input')}
                  value={data.url}
                />
              }
              {/* <IconsSelector title={i18n.t('pryaniky.menu.modal.change.icon') + ':'} active={data.icon} onChange={this.onIconChange} /> */}
              <CheckboxInput
                onChange={this.showOnMobile}
                checked={data.showOnMobile}
                text={i18n.t('pryaniky.menu.modal.change.showOnMobile')}
              />
              <CheckboxInput
                onChange={this.openInNewTab}
                checked={data.openInNewTab}
                text={i18n.t('pryaniky.menu.modal.change.openInNewTab')}
              />
            </div>
            : null
        }
        <DialogActions>
          <Button main onClick={onConfirm || onClick} padding="lg">
            {modalData.buttonText}
          </Button>
          <Button onClick={onClose} padding="lg">
            {i18n.t('pryaniky.menu.modal.cancel.title')}
          </Button>
        </DialogActions>
      </div>
    )
  }

  private handleChangeSelect = (selected: any) => {
    this.setState({ ...this.state, selected })

    this.props.data.renderType = selected.value;
  }

  // private onRenderTypeChange = (renderType: 'link' | 'dropdown') => () => store.dispatch(modalChangeDate({ id: this.props.id || '', data: { renderType } }))

  private onTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => store.dispatch(modalChangeDate({ id: this.props.id || '', data: { title: e.target.value } }))

  private onIconChange = (selected: string) => store.dispatch(modalChangeDate({ id: this.props.id || '', data: { icon: selected } }))

  private onLinkChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => store.dispatch(modalChangeDate({ id: this.props.id || '', data: { url: e.target.value } }))

  private showOnMobile = () => store.dispatch(modalChangeDate({ id: this.props.id || '', data: { showOnMobile: !this.props.data.showOnMobile } }))

  private openInNewTab = () => store.dispatch(modalChangeDate({ id: this.props.id || '', data: { openInNewTab: !this.props.data.openInNewTab } }))

}


export const ItemEditDialog = wrapDialogToRedux<IItemEditDialogOwnProps>(
  connect<IItemEditDialogStateProps, IDispatchProps, IItemEditDialogOwnProps>(
    mapStateToProps,
    mapDispatchToProps({})
  )(ItemEditDialogPresenter)
)