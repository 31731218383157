import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ISelectorUnit } from './item'
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    units: ISelectorUnit[];
    active: string[]
    onSelect: (unit: ISelectorUnit) => void
}

////////////
////////////

export const cnClassName = cn('LMSTaskSelector');