import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
// import { } from 'redux/actions/LMSTeacherOffice'

export interface IPortProps extends IClassNameProps, IPortStateProps, IDispatchProps, RouteComponentProps, IPortDispatchProps {
    tag?: 'div';
}

export interface IPortState {

}

export interface IPortStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    // course: state.LMSTO.courses.values[props.cid],
    // units: state.LMSTO.moderation.units,
    // ids: state.LMSTO.moderation.results
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export type IPortDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnPort = cn('CourseTabsPort');