import { denormalize, normalize, schema } from 'normalizr';

export const coursesSchema = () => {
    const sessions = new schema.Entity('normalSessions', {}, {
        idAttribute: 'id', processStrategy: (value, parent, key) => {
            return { ...value };
        }
    });

    const courses = new schema.Entity('values', {}, {
        idAttribute: 'id',
        processStrategy: (value, parent, key) => {
            const normalizedData = normalize(value.courseSessions || [], [sessions]);
            return {
                ...value,
                normalSessions: normalizedData.entities.normalSessions,
                courseSessions: normalizedData.result
            };
        }
    });
    return [courses];
}


export const testSchema = () => {
    const answers = new schema.Entity('normalAnswers', {}, {
        idAttribute: 'id', processStrategy: (value, parent, key) => {
            return { ...value };
        }
    });

    const questions = new schema.Entity('values', {}, {
        idAttribute: 'id',
        processStrategy: (value, parent, key) => {
            const normalizedData = normalize(value.answers || [], [answers]);
            return {
                ...value,
                normalAnswers: normalizedData.entities.normalAnswers,
                answers: normalizedData.result
            };
        }
    });
    return [questions];
}
export const testResultShema = () => {
    const answers = new schema.Entity('normalAnswers', {}, {
        idAttribute: 'variantId', processStrategy: (value, parent, key) => {
            return { ...value };
        }
    });
    return [answers];
}
export const singleCourseSchema = () => {
    const sessions = new schema.Entity('normalSessions', {}, {
        idAttribute: 'id', processStrategy: (value, parent, key) => {
            return { ...value };
        }
    });
    return { sessions }
}

export const unitsLogsSchema = () => {
    const logs = new schema.Entity('normalLogs', {}, {
        idAttribute: 'id', processStrategy: (value, parent, key) => {
            return { ...value };
        }
    });
    return [logs]
}

export const normalizeData = (data: any, schema: any = coursesSchema) => {
    const normalizedData = normalize(data, schema());
    return normalizedData;
}

export const denormalizeData = (fields: any, schema: any = coursesSchema) => {
    const denormalizedData = denormalize(fields.result, schema(), fields.entities);
    return denormalizedData;
}