import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    rightCount: number;
    wrongCount: number;
    waitModerationCount: number;
}

export const cnClassName = cn('LMSCourseSummary');