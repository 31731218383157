// import './InfoBlock-Default.scss';
import * as React from 'react';
import { Avatar } from 'uielements/src';
import { cnInfoBlock } from '../InfoBlock.index';

interface IDefaultInfoBlockProps {
  imgSrc: string;
  title?: string;
  text?: React.ReactNode;
  className?: string;
  imgSize?: number;
}
export const DefaultInfoBlock: React.FC<IDefaultInfoBlockProps> = ({ imgSrc, imgSize = 180, title, text }) => {
  return (
    <div className={cnInfoBlock('Inner')}>
      <Avatar imgUrl={imgSrc} size={imgSize} className={cnInfoBlock('Avatar')} />
      <div className={cnInfoBlock('Info')}>
        <h3 className={cnInfoBlock('Title')}>{title}</h3>
        <div className={cnInfoBlock('Text')}>{text}</div>
      </div>
    </div>
  );
};
