import { IStageBarProps, cnStageBar } from './StageBar.index';
import * as React from 'react'
import './StageBar.scss'
import { ButtonBox } from "uielements/src";

const _StageBar: React.FC<IStageBarProps> = ({ stage, date, children, controls, inRepost = false }, ref) =>
    <div className={cnStageBar('Wrapper', { inRepost })}>
        <div ref={ref} className={cnStageBar()}>
            <div className={cnStageBar('Top')}>
                <div className={cnStageBar('Data')}>
                    <div className={cnStageBar('Primary')}>{stage}</div>
                    <div className={cnStageBar('Second')}>{date}</div>
                </div>
                <div className={cnStageBar('Controls')}>
                    <ButtonBox className={cnStageBar('ButtonBox')}>
                        {controls}
                    </ButtonBox>
                </div>
            </div>
            <div className={cnStageBar('Content')}>{children}</div>
        </div>
    </div>;

export const StageBar = React.forwardRef(_StageBar);