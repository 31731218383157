import { Box } from 'muicomponents/src/Box';
import { Button } from 'muicomponents/src/Button/Button';
import { IconButton as IconButtonMui } from 'muicomponents/src/IconButton';
import { styled } from 'muicomponents/src/mui/system';

export const HeaderWrapper = styled('header')({
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 130,
    backgroundColor: '#fff',
    height: 72,
    lineHeight: '56px',
    boxSizing: 'border-box',
    padding: '0 24px',
});

export const HeaderItemBox = styled(Box)({
    lineHeight: 'initial',
}) as typeof Box;

export const HeaderItemFlexBox = styled(HeaderItemBox)({
    display: 'flex',
    alignItems: 'center',
    columnGap: 15,
}) as typeof HeaderItemBox;

export const ButtonMenuToggle = styled(IconButtonMui)({
    '@media (min-width: 768px)': {
        display: 'none !important',
    },
}) as any as typeof IconButtonMui;

export const LogoImg = styled('img')({
    display: 'block',
    objectFit: 'contain',
    maxWidth: 157,
    minWidth: 50,
    maxHeight: 50,
});

export const HeaderButtonsBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
}) as typeof Box;

export const IconButton = styled(IconButtonMui)({
    position: 'relative',
    borderRadius: '4px !important',
    width: 40,
    height: 40,
    background: '#EEEEEE !important',
}) as any as typeof IconButtonMui;

export const CircleIndicator = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: -7,
    right: -8,
    borderRadius: 19,
    border: '2px solid #fff',
    height: 19,
    boxSizing: 'border-box',
    fontSize: 11,
    color: '#fff',
    padding: '0 4px',
});

export const AvatarButton = styled(Button)({
    position: 'relative',
    padding: '0 !important',
    minWidth: 'auto !important',
    '&:hover': {
        backgroundColor: 'white',
    },
}) as typeof Button;

export const AvatarImageBox = styled(Box)({
    position: 'relative',
    width: 40,
    height: 40,
}) as typeof Box;

export const AvatarImage = styled('img')({
    width: '100%',
    borderRadius: 4,
    objectFit: 'cover',
    height: '100%',
});

export const IconMui = styled('div')({
    color: '#fff',
});

export const AvatarDropdownIconMui = styled('div')({
    position: 'absolute',
    top: '60%',
    left: '60%',
    width: 20,
    height: 20,
    color: '#0066CC',
    background: '#EEEEEE !important',
    border: '1px solid #fff',
    borderRadius: 50,
});

export const PupupLayoutNotifications = styled(Box)({
    width: 450,
    maxHeight: 500,
    overflowY: 'auto',
}) as typeof Box;
