import React from 'react';
import { mapStateToProps, mapDispatchToProps, ICurrencyTimelineProps, cnCurrencyTimeline } from './CurrencyTimeline.index';
import { connect } from 'react-redux';
import NewsItem from 'News/containers/NewsItem';
import TimelineView from 'News/containers/TimelineView';
import { CurrencyTimelineItem as ShopItem } from 'blocks/Shop/CurrencyTimelineItem/CurrencyTimelineItem';
import { CurrencyTimelineCancelItem as ShopCancelItem } from 'blocks/Shop/CurrencyTimelineCancelItem/CurrencyTimelineCancelItem';
import { Translate } from 'localizations/Translate';
import { ListLite } from 'uielements/src';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import { withRouter } from 'react-router-dom';
import './CurrencyTimeline.scss';
import { WalletsTimelineItem } from 'blocks/Wallets/TimelineItem/TimelineItem';




const CurrencyTimelineP: React.FC<ICurrencyTimelineProps> = ({
  getCurrencyTimeline,
  changeCurrencyTimelineContext,
  resetDataCurrecyTimelline,
  userId,
  data,
  loading,
  finished,
  context,
  userSex,
  // currentUserId
}) => {

  if (!userSex) return null;
  const currentUserId = userId
  React.useEffect(() => {
    userId && resetDataCurrecyTimelline();
  }, [userId]);

  const count = (data?.length || 0) + 20;

  return (
    <div className={cnCurrencyTimeline()}>
      <div className={cnCurrencyTimeline('Filters', ['Widget'])}>
        <span><Translate i18nKey='pryaniky.timeline.show' /></span>
        <Checkbox
          value={context.earned}
          onChange={earned => changeCurrencyTimelineContext({ earned })}
          label={Translate.t({ i18nKey: `pryaniky.timeline.earned.${userSex}` })}
          size='small'
        />
        <Checkbox
          value={context.spent}
          onChange={spent => changeCurrencyTimelineContext({ spent })}
          label={Translate.t({ i18nKey: `pryaniky.timeline.spent.${userSex}` })}
          size='small'
        />
        <Checkbox
          value={context.given}
          onChange={given => changeCurrencyTimelineContext({ given })}
          label={Translate.t({ i18nKey: `pryaniky.timeline.given.${userSex}` })}
          size='small'
        />
      </div>

      <ListLite
        isFinished={finished}
        isLoading={loading}
        skeleton='post'
        loadMore={() => {
          getCurrencyTimeline({ skipCount: data?.length ? count - 20 : 0, count, context, currentUserId })
        }}
      >

        {data.map((el) => {
          const timelineInfo = {
            value: el.value,
            transferDate: el.transferDate,
            isDeleted: Boolean(el.isOrderDeleted),
            data: el.commonProduct,
            transactionModelType: el.transactionModelType,
            transactionType: el.transactionType,
          };
          if(el.news) return <NewsItem id={el.news.id} ViewComponent={TimelineView} additional={timelineInfo} />
          switch(el.transactionModelType) {
            case 'DepositPayout':
            case 'DepositClosed':
            case 'Deposit': {
              return <WalletsTimelineItem data={timelineInfo} userSex={userSex} />;
            }
            case 'ShopItem': {
              return <ShopItem data={timelineInfo} userSex={userSex} />;
            }
            case 'CancelOrder': {
              return <ShopCancelItem data={timelineInfo} userSex={userSex}  />;
            }
            default: {
              return null;
            }
          }
        })}

      </ListLite>

    </div>
  )
}

export const CurrencyTimeline = withRouter(connect(mapStateToProps, mapDispatchToProps)(CurrencyTimelineP));