import React from 'react';
import { createModal, LeftDirectionTransitionComponent  } from 'utils/src/DialogCreator';
import { cnWorkflowsKanbanEdit, IWorkflowsKanbanEditProps } from './Edit.index';
import {
  DialogTitle,
  DialogActions,
  DialogContent,
} from 'uielements/src';
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import { Translate } from 'localizations/Translate';
import './Edit.scss';
import NewsItem from 'News/containers/NewsItem';

const WorkflowsKanbanEditPresenter: React.FC<IWorkflowsKanbanEditProps> = ({ handleClose, handleAccept, newsId, isRepost }) => {

  return <div className={cnWorkflowsKanbanEdit({}, ['CustomStyle'])}>
    <DialogTitle onClose={handleClose}>
      <h4 className="my-1">
        <Translate i18nKey={`pryaniky.post.workflows.edit`} />
      </h4>
    </DialogTitle>
    
    <DialogContent className={cnWorkflowsKanbanEdit('Body')}>
      <NewsItem single={true} id={newsId} repost={isRepost}/>
    </DialogContent>

    <DialogActions>
      <Button color='primary' onClick={handleClose}>
        <Translate i18nKey='cancel' />
      </Button>
    </DialogActions>
  </div>
}

export const editNewsModal = createModal(WorkflowsKanbanEditPresenter, {
  maxWidth: 'md',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  TransitionComponent: LeftDirectionTransitionComponent,
  className: 'Pane BackdropOff Right',
  scroll: 'body'
});