import * as React from 'react';
import * as utils from 'utils/src/utils';

// import { withBemMod } from '@bem-react/core';

import { cnWidget } from '../Widget.index';
import { IWidgetTypeTimelineCreatorProps } from './Widget_type_timelineCreator.index';
import { TimelineCreator } from '../../Timeline/TimelineCreator';
import { widgets } from "i.widgets";
import WidgetPresenter from '../Widget';

// import './Widget_type_timeline.scss';

const type = 'timelineCreator';

const createToDayDate = () => {
  const beginDatei = new Date()
  // beginDatei.setUTCHours(0, 0, 0, 0)
  beginDatei.setHours(0, 0, 0, 0)
  const beginDate = beginDatei.toISOString()

  const endDatei = new Date()
  endDatei.setHours(23, 59, 59, 0)
  // endDatei.setUTCHours(23, 59, 59, 0)
  const endDate = endDatei.toISOString()
  return {
    beginDate,
    endDate
  }
}
export default class WidgetTypeTimelineCreatorPresenter extends WidgetPresenter<IWidgetTypeTimelineCreatorProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    let additionalContext: Record<string, any> = {};
    if (this.props.widget.settings?.dateNow) {
      additionalContext = { ...additionalContext, ...createToDayDate() }
    }
    
    const context = { ...this.props.wcontext, wId: this.props.widget.id, ...this.props.widget.settings, ...additionalContext };
    // дополнительный типы оберток для каждой новости, можно постореть в news/containers/newsitem объект individualViewType
    const { timelineType } = this.props.widget.settings || {};
    return <TimelineCreator {...this.props} hideNews={this.props.edit} context={context} className={cnWidget({ type, subtype, timelineType })} tag={widgets.components.common} />
  }

}

// const type = 'timeline';

// export const withWidgetTypeTimeline = withBemMod<IWidgetTypeTimelineProps>(
//   cnWidget(),
//   { type }, 
//   (Presenter) => (
//     (props) => {
//       // const data = props.pageData.timeline;
//       // delete props.pageData.timeline;
//       return (
//       <Presenter {...props}>
//       </Presenter>
//     )}
//   )
// );