import { connect } from 'react-redux';
import React, { useCallback, memo } from 'react';
import { mapStateToProps, cnLikeAction, ILikeActionProps, mapDispatchToProps } from './LikeAction.index';
import './LikeAction.scss';
import { Like } from '../../../../NewsView/Actions/Like/Like'

export const LikeActionPresenter: React.FC<ILikeActionProps & any> = ({ likesCount, isLiked, sendLikeNews, className, nid, noLoad }) => {
    const onLike = useCallback(() => sendLikeNews({ id: nid, likeCount: likesCount, liked: isLiked }), [nid, likesCount, isLiked])
    if (noLoad) return null;
    return (
        <Like
            likeCount={likesCount}
            liked={isLiked}
            newsId={nid}
            onClick={onLike}
            className={cnLikeAction({}, [className])}
        />
    );
};

export const LikeAction = connect(
    mapStateToProps,
    mapDispatchToProps
)(memo(LikeActionPresenter));
