import MindElixir, { E } from "mind-elixir";
import * as React from 'react';
import { v1 as uuid } from 'uuid';
import "./MindMap.scss";
import { cnMindMap, IMindeMapProps, mapStateToProps, mapDispatchToProps } from "./MindMap.index";
import Button from 'uielements/src/MaterialElements/Button/Button';
import { Translate } from 'localizations/Translate';
import { connect } from 'react-redux'

//1. переделать в функциональный компонент.+ 
//2. сохранить логику инициализации по id ( private InitMindMap)?+
//3.добавить уникальные id вместо el: "#prn-map",+
//4. в пропсах data это объект с данными для отображения    data: this.itemsObject || MindElixir.new("MindMap"),+
//5. добавить пропсу onChange для передачи данных ( вместо localStorage.setItem("items", JSON.stringify(this.mind.getAllData()));)+
// 6. притянуть сохранение структуры виджета




let mind: any = null;
let time: any;

const MindMapPresenter: React.FC<IMindeMapProps> = ({
  tag: Tag = 'div',
  data,
  onChange,
  saveData,
  isAdmin,
  widget,
  // saveWidgetData,
  // updateWidget,
  ...props }) => {

  // const itemsObject = localStorage.getItem('items') ? JSON.parse(localStorage.getItem('items') || '') : undefined;

  const id = React.useRef('b' + uuid());

  React.useEffect(() => {
    initMindMap();
  }, [id, widget.isEditable]);

  // const savePage = () => {
  //   const mindData = mind.getAllData()
  //   updateWidget({
  //     ...props.widget,
  //     data: {
  //       ...props.widget.data,
  //       mindData
  //     }
  //   });
  //   saveWidgetData();
  // }
  const initMindMap = () => {
    // time = setInterval(() => {
    if (document.getElementById(id.current)) {
      clearInterval(time);
      mind = new MindElixir({
        // el:"#prn-map",
        el: `#${id.current}`,
        direction: MindElixir.LEFT,
        // create new map data
        // data: MindElixir.new("new topic"),
        // or set as data that is return from `.getAllData()`
        data: data || MindElixir.new("MindMap"),
        draggable: widget.isEditable, // default true
        contextMenu: widget.isEditable, // default true
        toolBar: true, // default true
        nodeMenu: widget.isEditable, // default true
        keypress: true // default true

      });
      mind.init();
      // mind.bus.addListener('operation', (operation:any) => {
      //   onChange(mind.getAllData())
      // })
    }
    // }, 100);
  }
  // const saveToLocal = () => {
  //   localStorage.setItem("items", JSON.stringify(mind.getAllData()))
  // }
  return (
    <Tag {...props} className={cnMindMap()}>
      <div className={cnMindMap('Map')} id={id.current}></div>
      <div className={cnMindMap('Actions')}>
        {widget.isEditable && <Button
          className={cnMindMap('Actions-SaveMindMap')}
          variant={'contained'}
          color={'primary'}
          size={'small'}
          onClick={() => {
            onChange(mind.getAllData())
            saveData()
          }}>

          <Translate i18nKey={'pryaniky.mind.map.save'} />

        </Button>}
      </div>
    </Tag>
  )
}

export const MindMap = connect(
  mapStateToProps,
  mapDispatchToProps
)(MindMapPresenter)

// export class MindMap extends React.Component<any> {
//   // public itemsObject =  undefined;
//   public itemsObject = localStorage.getItem('items') ? JSON.parse(localStorage.getItem('items') || '') : undefined;
//   private mind: any = null;
//   private time: any;

//   public componentDidMount() {
//     this.InitMindMap();
//     // this.itemsObject =  localStorage.getItem('items') ? JSON.parse(localStorage.getItem('items') || '') : undefined;
//   }

//   public componentWillUnmount(){
//     localStorage.setItem("items", JSON.stringify(this.mind.getAllData()));
//   }

//   private InitMindMap = () => {
//     this.time = setInterval(() => {

//       if (document.getElementById('prn-map')) {
//         clearInterval(this.time);
//         this.mind = new MindElixir({
//           el: "#prn-map",
//           direction: MindElixir.LEFT,
//           // create new map data
//           // data: MindElixir.new("new topic"),
//           // or set as data that is return from `.getAllData()`
//           data: this.itemsObject || MindElixir.new("MindMap"),
//           draggable: true, // default true
//           contextMenu: true, // default true
//           toolBar: true, // default true
//           nodeMenu: true, // default true
//           keypress: true // default true
//         });
//         this.mind.init();
//       }
//     }, 100);
//   }

//   public render() {
//     const { tag: Tag = 'div' } = this.props;
//     return (
//       <Tag {...this.props} className={cnMindMap()}>
//         <div className={cnMindMap('Map')} id="prn-map"></div>
//         <button onClick={() => localStorage.clear()}>
//           data
//         </button>
//       </Tag>
//     );
//   }
// }

// // onClick={()=>{localStorage.clear()}>

// export default MindMap;

// see src/example.js 