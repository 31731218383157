import React, { FC } from 'react';
import EditorUtils from 'draft-js-plugins-utils';
import DefaultLink from './components/Link';
// import LinkButton from './components/LinkButton';
import linkStrategy, { matchesEntityType } from './linkStrategy';
import { defaultTheme } from './theme';
import { renderToggler, renderFilter, renderChip, renderItem } from 'blocks/PryanikyForms/renderers'
import { items, itemChip } from 'blocks/PrnUserInput/renders'
import { Saggester } from 'blocks/PryanikyForms/Saggester/Saggester'
import { Action } from './components/Action/Action'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { NodeBulder } from 'uielements/src/CommonmarkRender'
import { mdToRaw, rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
import * as utils from 'utils/src/utils'
import './theme.scss'
const DefaultMentionSelectSuggesion: FC<any> = (props) => {
  const top: any = props.store.getPortalClientRect().y
  return <div className='MentionSelectSuggesion'>
    {props.open && <Saggester
      clickOutside={() => props.setOpen(false)}
      onSelect={(item: any) => { props.store.onToggleItem(item); props.setOpen(false) }}
      top={top}
      anchor={{current: props.store.getAnchor()}}
      className={'Select-Suggester MentionSelect-Suggester'}>
      {(onClick: (id: any) => () => void) => items(props.store.users, onClick)}
    </Saggester>}
  </div>
}

const SearchMention = (getEditorState: any, setEditorState: any) => () => {
  const state = getEditorState() as EditorState
  const md = draftToMarkdown(convertToRaw(state.getCurrentContent()), rawToMd)

  // state.getCurrentContent().getBlocksAsArray()

  utils.API.system.findUserMantion({ text: md }).r.then((d: any) => {
 
    let newText = d.data.text;//.replace('/User/', '');

    d.data.userMatches.forEach((item: any) => {
      newText = newText.replace(new RegExp("( |^)" + item.text, 'mg'),
        ` [${item.text}](#select-mention "{\\"text\\": \\"${item.text}\\", \\"array\\": ${JSON.stringify(item.users).replace(/["]/g, '\\"')}}")`)
    })
    const nb = new NodeBulder(newText || '')
    setEditorState(EditorState.createWithContent(convertFromRaw(nb.getDraftRaw())));
  })
}

export default (config: any) => {
  const { theme = defaultTheme, placeholder, Link, linkTarget, setOpen, onChangeState } = config;

  let clientRect: any = {}
  let offsetTop: number = 0;
  let anchor: any = null;

  const store = {
    getPortalClientRect: () => clientRect,
    getOffsetTop: () => offsetTop,
    getAnchor: () => anchor,
    updatePortalClientRect: (rect: any, offset: number, el: any) => {
      clientRect = rect;
      offsetTop = offset;
      anchor = el
    },
    onToggleItem: (user: any) => { },
    users: [],
    getEditorState: undefined,
    setEditorState: undefined,
  };

  const DecoratedDefaultLink = (props: any) => (
    <DefaultLink {...props} store={store} onChangeState={onChangeState} className={theme.link} target={linkTarget} setOpen={setOpen} />
  );

  const DecoratedAction = (props: any) => (
    <Action onClick={SearchMention(store.getEditorState, store.setEditorState)} />
  )

  const DecoratedMentionSelectSuggesion = (props: any) => <DefaultMentionSelectSuggesion {...props} onToggleItem={store.onToggleItem} setOpen={setOpen} store={store} />;

  return {
    MentionSelectSuggesion: DecoratedMentionSelectSuggesion,
    SearchAction: DecoratedAction,
    initialize: ({ getEditorState, setEditorState }: any) => {
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
    },

    decorators: [
      {
        strategy: linkStrategy,
        matchesEntityType,
        component: Link || DecoratedDefaultLink,
      },
    ],
  };
};
