import React, { FC, memo, useState, useCallback } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withReplyContext } from '../../contexts/reply'
import { withNewsContext } from '../../contexts/news'
import Rating from 'uielements/src/MaterialElements/RatingWithValue'

type IPresenterProps = News.ReplyRating.IPresenterProps
type IOwnProps = News.ReplyRating.IOwnProps
type IDispatchProps = News.ReplyRating.IDispatchProps
type IStateProps = News.ReplyRating.IStateProps
type IState = News.ReplyRating.IState


const noopFn = () => { }
const noopArr: any[] = []

export const Presenter: FC<IPresenterProps> = ({
    className,
    reply,
}) => {
    const { contentRating } = reply

    return (contentRating || -1) > -1 ? <div className={cnClassName({}, [className])}>
        <Rating
            readOnly
            value={contentRating}
        />
    </div> : null
}

export default withReplyContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));
