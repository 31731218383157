import { cn } from '@bem-react/classname';
import { IBodyProps } from 'utils/src/DialogCreator';
import { bindActionCreators, Action, Dispatch } from "redux"

export interface IImageCropperProps extends IBodyProps, IImageCropperOwnProps, IImageCropperDispatchProps, IImageCropperStateProps {
  // data:any;
  // onConfirm?: (...args: any) => void;

  editOpen?: (value:boolean)=>void

   onLoaded: (file: any) => void;
  // file:any
}

export interface IImageCropperOwnProps extends IBodyProps {}

export interface IImageCropperState {
  fileData?: any; 
  cropUrl?: string;
  file: any,
  crop: {
    x: number;
    y: number;
    aspect: number;
    width: number;
    height?: number;
  }
}
export type IImageCropperDispatchProps = ReturnType<typeof mapImageCropperDispatchToProps>

export const mapImageCropperDispatchToProps = (dispatch: Dispatch) => bindActionCreators({

}, dispatch);

export type IImageCropperStateProps = ReturnType<typeof mapImageCropperStateToProps>;

export const mapImageCropperStateToProps = () => ({});
export const cnImageCropper = cn('ImageCropper');