import Component, { Presenter } from './component';
import {
    IDialogBodyOwnProps,
    IDialogBodyPresenterProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = IDialogBodyOwnProps;
export type IComponentPresenterProps = IDialogBodyPresenterProps;


export default Component