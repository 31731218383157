import { ICommentsLayoutProps } from './CommentsLayout.index';
import * as React from 'react'
import './CommentsLayout.scss'

export const CommentsLayout: React.FC<ICommentsLayoutProps> = ({ more, form, children }) => {
    return (
        <div className="CommentsLayout">
            <div className={"CommentsLayout-More"}>{more}</div>
            <div className={'CommentsLayout-Comments'}>
                {children}
            </div>
            <div className={"CommentsLayout-Form"}>{form}</div>
        </div>
    );
}