import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { PropsWithRef } from 'react';
import { NPagesMenuMui } from '../PagesMenuMui.index';

export const cnPagesMenuMuiBar = cn('PagesMenuMuiBar');

export namespace NPagesMenuMuiBar {

  export interface Props extends PropsWithRef<IClassNameProps>, Pick<NPagesMenuMui.Props, 'stayOpened'> {
    id: string;
    path: string[];
    zIndex?: number;
    opened?: boolean;
    items: any[]
    pageInfo: { [key: string]: any }
    onClose: () => void;
    editOpen: (value: boolean) => void
    /**
    * флаг отвечающий является ли пользователь Модератором Базы знаний
    */
    isWkilistModerator: boolean
  }
}
