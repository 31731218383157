import { NValidate,ValidateRulesObject, validate_v2 } from 'utils/src/validate_v2';
import { SettingsWikiActueleArticlesWidgetWidget } from '../WikiActueleArticlesWidget.index';
import React, { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from "react";


export const validateField = (field: Partial<SettingsWikiActueleArticlesWidgetWidget.Settings>) => {
    let fullRules = ValidateFieldsObject;
    
    return validate_v2(field, fullRules);
};

export const ValidateFieldsObject: ValidateRulesObject<SettingsWikiActueleArticlesWidgetWidget.Settings> = {
    collection: {
        notNull: {
            value: true
        },
    },
};


const actualArticlesListErrorsBaseState: {
    collection: {[key: string]: any}|undefined;
   
} = {
    collection: undefined,

};

type ActualArticlesListErrorsState = {
    state: typeof actualArticlesListErrorsBaseState,
    setState: (newData: Partial<typeof actualArticlesListErrorsBaseState>) => void;
};



const ActualArticlesListErrors = createContext<ActualArticlesListErrorsState>({
    state: actualArticlesListErrorsBaseState,
    setState: () => {}
});

export const useActualArticlesListErrors = () => {
    return useContext(ActualArticlesListErrors);
};

export const ActualArticlesListErrorsProvider: FC<{}> = ({
    children
}) => {

    const [state, setS] = useState(actualArticlesListErrorsBaseState);
    const stateRef = useRef(state);
    stateRef.current = state;

    useEffect(() => {
        console.log('state', state);
    }, [state]);

    const setState = useCallback((newData: Partial<typeof state>) => {
        setS({
            ...stateRef.current,
            ...newData
        });
    }, [state, children]);

    return (
        <ActualArticlesListErrors.Provider value={{
            state,
            setState
        }}>
            {children}
        </ActualArticlesListErrors.Provider>
    );
};