import React from 'react';
import { IColumnChangerProps, cnColumnChanger } from './ColumnChanger.index';
import './ColumnChanger.scss';

class ColumnChangerPresenter extends React.Component<IColumnChangerProps>{
  private startPosDiff: number = 0;
  private el = React.createRef<HTMLDivElement>();
  private sizer = React.createRef<HTMLDivElement>();
  
  constructor(props: IColumnChangerProps) {
    super(props);
  }

  /**
   * render
   */
  public render() {
    const { onMove, ...htmlProps } = this.props;
    return (
      <div ref={this.el} className={cnColumnChanger()} style={{ minWidth: '2%' }} {...htmlProps} >
        <div ref={this.sizer} onMouseDown={this.mouseDown}>
          <div />
        </div>
      </div>
    )
  }


  private mouseDown = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
    const { current: sizerContainer } = this.el;
    if (!sizerContainer) return null;
    this.startPosDiff = ev.clientX - sizerContainer.getBoundingClientRect().left;

    document.addEventListener('mousemove', this.mouseMove, false);
    document.addEventListener('mouseup', this.mouseUp, false);
  }

  private mouseUp = (ev: any) => {
    ev.stopPropagation();
    ev.preventDefault();

    document.removeEventListener('mousemove', this.mouseMove, false);
    document.removeEventListener('mouseup', this.mouseUp, false);
  }

  private mouseMove = (ev: any) => {
    ev.stopPropagation();
    ev.preventDefault();
    const { current: sizerContainer } = this.el;
    const container = (sizerContainer || { parentElement: null }).parentElement;
    const { current: sizer } = this.sizer;
    if (!container || !sizerContainer || !sizer) return null;

    const containerRect = container.getBoundingClientRect();

    const sizerContainetLeftPosition = (ev.clientX - this.startPosDiff) - containerRect.left;

    const sizerContainetRightPosition = sizerContainetLeftPosition + sizerContainer.clientWidth;

    let cursorByContainetPosition = sizerContainetLeftPosition;

    if (cursorByContainetPosition <= 0) cursorByContainetPosition = 0;
    if (sizerContainetRightPosition >= containerRect.width) cursorByContainetPosition = containerRect.width - sizerContainer.clientWidth;

    this.props.onMove(cursorByContainetPosition);

    // if (this.changeContainer && this.currentPushedButton) {
    //   const containerRect = this.changeContainer.getBoundingClientRect();
    //   let twoCollumnWidth = 0;
    //   const buttonIndex = Number(this.currentPushedButton.id.split('-')[1]);
    //   const halfButtonWidth = this.currentPushedButton.clientWidth / 2;

    //   let position = ev.clientX - containerRect.left - (halfButtonWidth * buttonIndex);
    //   if (buttonIndex > 1) position -= (halfButtonWidth * (buttonIndex - 1));
    //   if (position < 0) position = 0;
    //   if (position > containerRect.width) position = containerRect.width;
    //   position = (position * 100) / containerRect.width;
    //   if (position < 30) position = 30;
    //   if (position > 70) position = 70;

    //   if (this.state.layout.data) {
    //     this.state.layout.data.forEach((col: IColumn, i: number) => {
    //       if (col.styles) {
    //         if (buttonIndex > 1 && i < buttonIndex - 1) position -= parseFloat(col.styles.width + '');
    //         if (
    //           i === buttonIndex ||
    //           i === buttonIndex - 1
    //         ) {
    //           twoCollumnWidth += parseFloat(col.styles.width + '');
    //         }
    //       }
    //     })
    //   }

    //   let berofeButtonColumnWidth = position;
    //   let afterButtonColumnWidth = twoCollumnWidth - position;

    //   if (berofeButtonColumnWidth < 30) {
    //     berofeButtonColumnWidth = 30;
    //     afterButtonColumnWidth = twoCollumnWidth - 30;
    //   }

    //   if (twoCollumnWidth - berofeButtonColumnWidth < 30) {
    //     berofeButtonColumnWidth = twoCollumnWidth - 30;
    //   }

    //   if (afterButtonColumnWidth < 30) {
    //     afterButtonColumnWidth = 30;
    //   }

    //   const layout = this.state.layout;
    //   if (this.state.layout.data) {
    //     layout.data[buttonIndex - 1].styles = {
    //       width: berofeButtonColumnWidth + '%'
    //     };
    //     layout.data[buttonIndex].styles = {
    //       width: afterButtonColumnWidth + '%'
    //     };
    //   }
    //   this.setState({ layout });
    // }
  }
}

export const ColumnChanger = ColumnChangerPresenter