import { IInfoProps } from '../AbstractInfoElement.index';

import { IDispatchProps } from '../../../../redux/connector';

export interface IUserTypeStoreProps extends IInfoProps, IUserStateProps, IDispatchProps {
  id: string;
}

export interface IUserTypeStoreState {
  text?: string;
}

export interface IUserStateProps {
  // store: any
  widgets: any,
}

export const mapStateToProps = (state: any) => ({
  widgets: state.widgets.widgets,
})

// export interface IActionsDispatch {
//   setContext?: any;
// }

// export const mapActionsToProps: IActionsDispatch = {
//   setContext
// };