import React, { useEffect, FC } from 'react';
import { IUnitResultProps } from './UnitResult.index';
import './UnitResult.scss';
import { withPreloader } from '../../Preloader/Preloader'
import { Unit as Skelet } from '../../../Skelets/Unit/Unit'
import { withUnitContentLoader } from 'LMSModule/utils/HOCs/withUnitContentLoader'
import { TestResult } from '../../TestResult/TestResult';
import TaskResult from '../../TaskResult';
import { Lesson } from '../../Parts/Lesson/Lesson';
import { ScormLoader } from '../../ScormUnit/loader';
import { useResultNav } from 'LMSModule/utils/hooks/useResultNav'

export const UnitResultPresenter: FC<IUnitResultProps & any> = ({sid, uid, course}) => {

    const unit = course.courseUnits.find((unit: any) => unit.id === uid)

    const { attemptAction, next, prev } = useResultNav(course, sid, uid)

    if (unit.unitType === 'test') return (<TestResult
        disableActions={false}
        next={next}
        prev={prev}
        attemptAction={attemptAction}
        course={course}
        unit={unit}
        sId={sid} />);
    if (unit.unitType === 'task') return (<TaskResult
        disableActions={false}
        next={next}
        prev={prev}
        attemptAction={attemptAction}
        course={course}
        unit={unit}
        cid={course.id}
        sid={sid} />);
    else if (unit.unitType === 'wiki') return (<Lesson
        disableActions={true}
        next={next}
        prev={prev}
        course={course}
        unit={unit}
        sId={sid} />)
    else if (unit.unitType === 'scorm') return (<ScormLoader
        forceResult={true}
        course={course}
        unit={unit}
        sId={sid} />)
    else return <div>ERROR: unknown unit type.</div>
};

const WithLoader = withPreloader(UnitResultPresenter, ['unitContent'], [], <Skelet />)

export const UnitResult = withUnitContentLoader(WithLoader);
