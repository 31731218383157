import React, { FC, useState } from 'react';
import { wrapDialogToRedux } from '../DialogWrapper';
import { ICommentsProps, ICommentsState } from './Comments.index';
import { Textarea, Input, Button } from 'uielements/src';
import { store } from '../../../redux/store';
import { modalChangeDate } from '../../../redux/actions/Modals';
import i18n from '../../../localizations/i18n';
import { Translate } from 'localizations/Translate';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { connect } from "react-redux";

const CommentsPresenter: FC<ICommentsProps> = ({
  handleAccept,
  handleClose
}) => {
  const [data, setData] = useState({
    attendance: "",
    content: "",
    createTarget: ""
  })




  // const stringValueChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => setState({ [e.currentTarget.name]: e.currentTarget.value });

  const onChangeTarget = (e: any) => setData({ ...data, createTarget: e.currentTarget.value });
  const onChangeContent = (e: any) => setData({ ...data, content: e.currentTarget.value });
  const onChangeAttendance = (e: any) => setData({ ...data, attendance: e.currentTarget.value });

  return (
    <React.Fragment>
      <DialogTitle onClose={handleClose}>
        <Translate i18nKey={'pryaniky.dialogs.comment.title'} />
      </DialogTitle>
      <DialogContent>
        <Textarea name={'createTarget'} value={data.createTarget || ''} onChange={onChangeTarget} placeholder={i18n.t('pryaniky.subnetworks.dialog.placeholder.commentMoveGroup.createTarget')} />
        <Input name={'content'} value={data.content || ''} onChange={onChangeContent} placeholder={i18n.t('pryaniky.subnetworks.dialog.placeholder.commentMoveGroup.content')} />
        <Input name={'attendance'} value={data.attendance || ''} onChange={onChangeAttendance} placeholder={i18n.t('pryaniky.subnetworks.dialog.placeholder.commentMoveGroup.attendance')} />
      </DialogContent>
      <DialogActions
        acceptText={i18n.t('pryaniky.subnetworks.dialog.ok')}
        closeText={i18n.t('pryaniky.cancel.dialog')}
        // disableAccept={strengthPass.score === 0 || newPassword !== conPassword}
        onAccept={() => handleAccept(data)}
        onClose={handleClose} />
    </React.Fragment>
  );
}

// export const Comments = connect<ICommentsProps, ICommentsmapStateProps,ICommentsmapDispatchProps>(
//    mapStateToProps,
//    mapDispatchToProps
//    )(CommentsPresenter)

export const DialogComments = createModal(CommentsPresenter, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: '#fff',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});

// class CommnetsPresenter extends React.Component<ICommentsProps, ICommentsState>{

//   /**
//    * render
//    */
//   public render() {
//     const { onClose, onConfirm, data } = this.props
//     return (
//       <>
//         <DialogTitle onClose={onClose}>
//           <Translate i18nKey={'pryaniky.dialogs.comment.title'} />
//         </DialogTitle>
//         <DialogContent>
//           <Textarea name={'createTarget'} value={data.createTarget || ''} onChange={this.onChange} placeholder={i18n.t('pryaniky.subnetworks.dialog.placeholder.commentMoveGroup.createTarget')} />
//           <Input name={'content'} value={data.content || ''} onChange={this.onChange} placeholder={i18n.t('pryaniky.subnetworks.dialog.placeholder.commentMoveGroup.content')} />
//           <Input name={'attendance'} value={data.attendance || ''} onChange={this.onChange} placeholder={i18n.t('pryaniky.subnetworks.dialog.placeholder.commentMoveGroup.attendance')} />
//         </DialogContent>
//         <DialogActions
//           acceptText={i18n.t('pryaniky.subnetworks.dialog.ok')}
//           closeText={i18n.t('cancel')}
//           // disableAccept={strengthPass.score === 0 || newPassword !== conPassword}
//           onAccept={onConfirm}
//           onClose={onClose} />
//         {/* <DialogActions>
//           <Button main onClick={onConfirm} padding="lg">
//             <Translate i18nKey={'pryaniky.subnetworks.dialog.ok'} />
//           </Button>
//           <Button onClick={onClose} padding="lg">
//             <Translate i18nKey={'cancel'} />
//           </Button>
//         </DialogActions> */}
//       </>
//     )
//   }

//   /**
//    * onChange
//    */
//   public onChange = (ev: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     store.dispatch(modalChangeDate({
//       id: this.props.id || '',
//       data: {
//         ...this.props.data,
//         [ev.currentTarget.name]: ev.currentTarget.value
//       }
//     }));
//   }
// }

