/**
 * @packageDocumentation
 * @module Widget_Settings_quests
 */
import { JFdictionary } from "../dictionary";
import i18n from "localizations/i18n";

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    text: {
      type: types.string,
      placeholder: i18n.t('pryaniky.widgets.settings.birthdays.placeholder.text'),
    },
    showImg: {
      type: types.boolean
    },
    hideIfNoData: {
      type: types.boolean
    },
    usersCount: {
      type: types.number,
      min: 1,
      placeholder: i18n.t('pryaniky.widgets.settings.placeholder.usersCountLoad'),
    },
    banner: {
      type: types.object,
      // require: true
    },
   
  }
}
