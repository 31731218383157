import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    SUPPORT
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Button as DefaultButton } from 'uielements/src'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import withConfirmDialog from 'blocks/HOCs/Button/withConfirmDialog'
import * as utils from 'utils/src/utils'
import { Services } from 'utils/src/debug';

const Button = withConfirmDialog(withLoadSpin(DefaultButton))

type IOwnProps = SUPPORT.IOwnProps
type IPresenterProps = SUPPORT.IPresenterProps
type IStateProps = SUPPORT.IStateProps
type IDispatchProps = SUPPORT.IDispatchProps
type IState = SUPPORT.IState

const excludedNames: (keyof typeof Services['prototype'])[] = [
    'type_API_server'
];

export const Presenter: FC<IPresenterProps> = ({
    className,
    currentUser,
}) => {
    
    const [render, setRender] = useState(false);

    const [load, setLoad] = useState(false)
    const [command, setCommand] = useState<string | null>(null)


    const redisflus = () => {
        setLoad(true)
        setCommand(`https://${utils.API.base.domain}/clientHome/redisflus`)
        //fetch(`https://${utils.API.base.domain}/clientHome/redisflus`).then(() => setRfLoad(false)).catch(() => setRfLoad(false))
    }

    const completeCommnad = () => {
        setLoad(false)
        setCommand(null)
    }



    return <div className={cnClassName({}, [className])}>
        <h4>SUPPORT TOOLS</h4>
        {command && <iframe
            src={command}
            width={'100px'}
            height={'30px'}
            onLoad={completeCommnad}
        />}
        <div>
            <Button
                onConfirm={redisflus}
                isLoading={load}>clientHome/redisflus</Button>
        </div>

        {
            Object.entries(Object.getOwnPropertyDescriptors(Services.prototype))
            .filter(([key, descriptor]) => typeof descriptor.set === 'function')
            .map(([key]) => key)
            .filter(el => !excludedNames.includes(el as any))
            .map((k) => {
                const key = k as keyof typeof Services['prototype'];
                return (
                    <div>
                        <DefaultButton
                            onClick={() => {
                                (window.PRN_SERVICE as any)[key] = !window.PRN_SERVICE[key];
                                setRender(!render);
                            }}
                        >
                            {key} {window.PRN_SERVICE[key] ? 'ON' : 'OFF'}
                        </DefaultButton>
                    </div>
                )
            })
        }
        <div>
            <DefaultButton
                onClick={() => {
                    let expires: Date | undefined = undefined;
                    if(utils.getCookie('showMeNewDesign') === 'alfa') {
                        expires = new Date(0);
                    }
                    utils.setCookie({
                        name: 'showMeNewDesign',
                        value: 'alfa',
                        props: {
                            expires
                        }
                    })
                    setRender(!render);
                }}
            >
                showMeNewDesign alfa - {utils.getCookie('showMeNewDesign') === 'alfa' ? 'ON' : 'OFF' }
            </DefaultButton>
        </div>
        <div>
            <DefaultButton
                onClick={() => {
                    let expires: Date | undefined = undefined;
                    if(utils.getCookie('showMeNewDesign') === 'beta') {
                        expires = new Date(0);
                    }
                    utils.setCookie({
                        name: 'showMeNewDesign',
                        value: 'beta',
                        props: {
                            expires
                        }
                    })
                    setRender(!render);
                }}
            >
                showMeNewDesign beta - {utils.getCookie('showMeNewDesign') === 'beta' ? 'ON' : 'OFF' }
            </DefaultButton>
        </div>

        {/* <div>
            <Button
                onConfirm={() => window.PRN_SERVICE.dev_features = !window.PRN_SERVICE.dev_features}
            >dev_features {window.PRN_SERVICE.dev_features ? 'ON' : 'OFF'}</Button>
        </div>

        <div>
            <Button
                onConfirm={() => window.PRN_SERVICE.show_i18n_keys = !window.PRN_SERVICE.show_i18n_keys}
            >show i18n keys {window.PRN_SERVICE.show_i18n_keys ? 'ON' : 'OFF'}</Button>
        </div>

        <div>
            <Button
                onConfirm={() => window.PRN_SERVICE.client_widegts = !window.PRN_SERVICE.client_widegts}
            >client_widegts {window.PRN_SERVICE.client_widegts ? 'ON' : 'OFF'}</Button>
        </div> */}
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
