import { AccessPolicyType } from 'utils/src';
import { WidgetsTypes } from 'utils/src/widgets/types';
import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import { groupTabs } from './__tabs';

interface IGroup {
  info: string;
  notInGroup: string;
  tabs: string;
  timeline: string;
  listGroupUsersSelector: string;
}

export const group = (props: { [s: string]: any }): IWidget<IColumn[]> => {
  const ids = guids<IGroup>(['info', 'notInGroup', 'listGroupUsersSelector', 'tabs', 'timeline']);
  return generateWidget<IColumn[]>({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            id: ids.info,
            type: widgets.types.info,
          }),
          generateWidget({
            id: ids.notInGroup,
            isHidden: true,
            accessPolicy: {
              policyType: AccessPolicyType['Groups.HideForGroupMember']
            },
            data: "<p>Вы не участник данной группы</p>",
            type: WidgetsTypes.html,
          }),
          generateWidget<IColumn[]>({
            type: widgets.types.tabs,
            accessPolicy: {
              policyType: AccessPolicyType['Groups.ShowForGroupMemberIfGroupClosed']
            },
            data: [
              groupTabs.feed(),
              groupTabs.users(),
              groupTabs.wikilist(props?.tabs?.wikilist),
              groupTabs.imageGallery(props?.tabs?.imageGallery),
              groupTabs.videoGallery(props?.tabs?.videoGallery)
            ],
          }),
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  });
}