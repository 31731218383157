import React from 'react';
import { Sortable } from 'uielements/src/Sortable/Sortable';
import { getItems } from 'uielements/src/Sortable/Sortable.index';
import './Sortable.scss'

export const SortablePage = () => {


  const [items1, setItems1] = React.useState(getItems());

  const [items2, setItems2] = React.useState(getItems());

  return (
    <div className={'sss'}>
      <Sortable collection={'division'} horizontal items={items1} onDrop={setItems1} />
      <Sortable collection={'division'} horizontal items={items2} onDrop={setItems2} />
    </div>
  )
}