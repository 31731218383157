import React, {
    FC,
    useEffect,
    useMemo,
    useState,
    memo,
    useCallback,
    useRef
} from 'react'
import { useTimelineLoader } from 'News/utils/hooks'
import { INewsListRequestOpts } from 'utils/src/requests/models/api.news'
import { useIsLoading, useIsError } from 'utils/src/CommonRedux/LoadIndicator'

export const useTimelineSearchLoader = (params: INewsListRequestOpts, keys: string[], customCount?: number, isSearchPage?: boolean) => {
    // console.log("useTimelineSearchLoader", isSearchPage)
    const [isFinishedState, setIsFinishedState] = useState(params.count === 3)
    const { isFailed, isLoading, loadMore, } = useTimelineLoader(params, keys, params.count, undefined, true)



    return {
        isLoading,
        isFailed,
        loadMore, 
        isFinishedState
    }

}
