import { CreativeTaskNews } from './CreativetasksType'
import { Rule, BaseValidator, validateString, validateNumber, validateDate } from '../Base.validate'
export const creativeTaskValidate: Rule[] = [
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'creativeTask.title',
        value: 1,
        message: 'Введите название конкурса'
    },
    /*{
        type: 'string',
        method: 'STR_MAX',
        field: 'creativeTask.title',
        value: 40,
        message: 'big title'
    },*/
    {
        type: 'number',
        method: 'NUM_MIN',
        field: 'creativeTask.fund',
        value: 1,
        message: 'Недостаточно вложений'
    },
    // {
    //     type: 'number',
    //     method: 'NUM_MAX',
    //     field: 'creativeTask.fund',
    //     value: 100,
    //     message: 'Слишком много вложений'
    // },
]

export const creativeTaskEditValidate: Rule[] = [
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'creativeTask.title',
        value: 1,
        message: 'Введите название конкурса'
    },
    {
        type: 'date',
        method: 'DATE_MIN',
        field: 'expires',
        value: Date.now(),
        message: 'Событие неможет быть в прошлом'
    },
    {
        type: 'date',
        method: 'DATE_MIN',
        field: 'creativeTask.endDateTime',
        value: Date.now(),
        message: 'Событие неможет быть в прошлом'
    },
    /*{
        type: 'string',
        method: 'STR_MAX',
        field: 'creativeTask.title',
        value: 40,
        message: 'big title'
    }*/
]

export class CreativeTaskNewsValidator extends BaseValidator<any> {
    protected otherValidate = (data: any, rule: Rule) => {
        switch (rule.field) {
            case 'creativeTask.title':
                return validateString(data.creativeTask.title, rule);
            case 'creativeTask.fund':
                return validateNumber(data.creativeTask.fund, rule);
            case 'creativeTask.endDateTime':
                return validateDate(data.creativeTask.endDateTime, rule);
        }
        return true;
    }
}
