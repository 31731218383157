// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
// import i18n from '../../localizations/i18n';

import { cnTimepicker, ITimepickerProps, ITimepickerState } from './Timepicker.index';

import './Timepicker.scss';
import { Icon } from 'uielements/src/Icon/Icon';
import { Button } from 'uielements/src/Button/Button';

export class TimepickerPresenter extends React.Component<ITimepickerProps, ITimepickerState> {
  public el: HTMLElement | null;
  public minuteStep: number;
  private timer: any = 0;

  constructor(props: ITimepickerProps) {
    super(props);
    this.functionBind = this.functionBind.bind(this);
    this.functionBind();
    this.state = {
      focus: false
    };

    this.minuteStep = this.props.minuteStep || 15;
  }

  public render() {
    return (
      <div ref={el => this.el = el} className={cnTimepicker({}, [this.props.className])}>
        <Icon icon='clock' className={cnTimepicker('Icon')} />
        <div className={cnTimepicker('Inner')}> 
          <input 
            className={cnTimepicker('Input')}
            onFocus={this.onFocus} 
            onBlur={this.onBlur} 
            onChange={this.fakeChange}
            onKeyDown={this.onKeyDown} 
            value={this.props.value}
            />
          <div className={cnTimepicker('ButtonWrapper', {top: true, onTop: this.state.focus})}>
            <Button data-idx={0} onClick={this.upButtonClick} noBorderRadius={true} noPadding={true} children={
              <Icon icon='chevron-up' />
            } />
            <Button data-idx={1} onClick={this.upButtonClick} noBorderRadius={true} noPadding={true} children={
              <Icon icon='chevron-up' />
            } />
          </div>
          <div className={cnTimepicker('ButtonWrapper', {bottom: true, onBottom: this.state.focus})}>
            <Button data-idx={0} onClick={this.downButtonClick} noBorderRadius={true} noPadding={true} children={
              <Icon icon='chevron-down' />
            } />
            <Button data-idx={1} onClick={this.downButtonClick} noBorderRadius={true} noPadding={true} children={
              <Icon icon='chevron-down' />
            } />
          </div>
        </div>
        {this.props.children}
      </div>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  public fakeChange(ev: any) {return ev;}

  private functionBind() {
    this.fakeChange = this.fakeChange;
    this.upButtonClick = this.upButtonClick.bind(this);
    this.downButtonClick = this.downButtonClick.bind(this);
    this.onInputChandge = this.onInputChandge.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.checkTrueTime = this.checkTrueTime.bind(this);
  }

  private upButtonClick(e: any) {
    const data = e.currentTarget.getAttribute('data-idx');
    this.setState({focus: true});
    let time: any[] | string = this.props.value.split(':');
    if(data === '0') {
      time[data] = Number(time[data]) + 1;
      if(time[data] > 23) time[data] = '0';
      if(time[data].toString().length < 2) time[data] = "0" + time[data].toString();
    }
    if(data === '1') {
      time[data] = Number(time[data]) + this.minuteStep;
      if(time[data] >= 60) time[data] = 0 + (time[data] - 60);
      if(time[data].toString().length < 2) time[data] = "0" + time[data].toString();
    }
    time = time[0] + ':' + time[1];
    this.props.onChange(time);
    if(this.timer !== 0) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(this.onBlur, 2000);
    e.preventDefault();
  }

  private downButtonClick(e: any) {
    const data = e.currentTarget.getAttribute('data-idx');
    this.setState({focus: true});
    let time: any[] | string = this.props.value.split(':');
    if(data === '0') {
      time[data] = Number(time[data]) - 1;
      if(time[data] < 0) time[data] = '23';
      if(time[data].toString().length < 2) time[data] = "0" + time[data].toString();
    }
    if(data === '1') {
      if(Number(time[data]) >= this.minuteStep) {
        time[data] = Number(time[data]) - this.minuteStep
      } else
      if(Number(time[data]) < this.minuteStep) {
        time[data] = 60 + (Number(time[data]) - this.minuteStep)
      }
      if(time[data].toString().length < 2) time[data] = "0" + time[data].toString();
    }
    time = time[0] + ':' + time[1];
    this.props.onChange(time);
    if(this.timer !== 0) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(this.onBlur, 2000);
    e.preventDefault();
  }

  private onInputChandge(target: any, value: any, action: string, cursorPos: number, key: string) {
    let lpos = cursorPos;
    const selecionRange = target.selectionEnd - target.selectionStart;
    let l, r;
    if (action === 'write') {
      if(selecionRange > 0) {
        if(selecionRange === 2) {
          if(target.selectionStart === 3) {
            value = value.substring(0, target.selectionStart);
            value += key;
            lpos = target.selectionEnd;
          } else if (target.selectionEnd === 2) {
            value = value.substring(target.selectionEnd, value.length);
            value = key + value;
            lpos = target.selectionStart + 1;
          }
        } else if (selecionRange === value.length) {
          value = '' + key;
          lpos = value.length;
        }
      } else
      if(cursorPos === value.length) {
        if (value.length === 5) return false;
        value += key;
        lpos = value.length;
      } else {
        if (value.length === 5) {
          l = value.substring(0, cursorPos) + key;
          r = value.substring(cursorPos + 1, value.length);
          const lMatch = l.match(/\d/g);
          if(lMatch) {
            if((l.length > 1 && lMatch.length === 2 && value[l.length-1] === ':') &&
              (r[0] !== ':')) {
              value = l + ':' + r;
              lpos = l.length + 1;
            } else if ((l.length > 1 && lMatch.length === 2 && value[l.length-1] !== ':') &&
                      (r[0] === ':')) {
              value = l + r;
              lpos = l.length + 1;
            } else {
              value = l + r;
              lpos = l.length;
            }
          }
        } else {
          l = value.substring(0, cursorPos) + key;
          r = value.substring(cursorPos, value.length);
          const lMatch = l.match(/\d/g);
          if(lMatch) {
            if(l.length > 1 && lMatch.length === 2 && value[l.length-1] !== ':') {
              value = l + ':' + r;
              lpos = l.length + 1;
            } else if (l.length > 1 && lMatch.length === 2 && value[l.length-1] === ':') {
              value = l + r;
              lpos = l.length + 1;
            } else {
              value = l + r;
              lpos = l.length;
            }
          }
        }
      }
      value = this.checkTrueTime(value, false);
    }
    if (action === 'remove') {
      if(selecionRange > 0) {
        if(selecionRange === 2) {
          if(target.selectionStart === 3) {
            value = value.substring(0, target.selectionStart);
            lpos = target.selectionEnd;
          } else if (target.selectionEnd === 2) {
            value = value.substring(target.selectionEnd, value.length);
            lpos = target.selectionStart;
          }
        } else if (selecionRange === value.length) {
          value = ':';
        }
      } else
      if (key === 'Backspace') {
        if(cursorPos === value.length) {
          if (value[value.length - 1] === ':') {
            value = value.substring(0, value.length - 2) + ':';
            lpos = value.length - 1;
          } else {
            value = value.substring(0, value.length - 1);
            lpos = value.length;
          }
        } else {
          l = value.substring(0, cursorPos - 1);
          r = value.substring(cursorPos, value.length);
          lpos = l.length;
          if (value[cursorPos - 1] === ':') {
            l = value.substring(0, cursorPos - 2) + ':';
            lpos = l.length - 1;
          }
          value = l + r;
        }
      } 
      else if (key === "Delete") {
        if(cursorPos === value.length) {
          lpos = value.length;
        } else {
          l = value.substring(0, cursorPos);
          r = value.substring(cursorPos + 1, value.length);
          if (value[cursorPos] === ':') {
            l = value.substring(0, cursorPos + 1);
            r = value.substring(cursorPos + 2, value.length);
          }
          value = l + r;
          lpos = l.length;
        }
      }
      if (lpos < 0) lpos = 0;
    } 
    if(action === 'arrows') {
      value = value.split(':');
      if (value.lngth === 1) value.push('00'); 
      if (cursorPos <= 2) {
        if(key === 'ArrowUp') {
          value[0] = Number(value[0]) + 1;
        } else if (key === 'ArrowDown') {
          value[0] = Number(value[0]) - 1;
        }
        if (Number(value[0]) > 23) {
          value[0] = '0';
        } else if (Number(value[0]) < 0) {
          value[0] = '23';
        }
        if (value[0].toString().length === 1) value[0] = '0' + value[0].toString();
      } else if (cursorPos >= 3) {
        if(key === 'ArrowUp') {
          value[1] = Number(value[1]) + this.minuteStep;
        } else if (key === 'ArrowDown') {
          value[1] = Number(value[1]) - this.minuteStep;
        }
        if (Number(value[1]) > 59) {
          value[1] = ((value[1] - 60) + 0).toString();
        } else if (Number(value[1]) < 0) {
          value[1] = (60 - this.minuteStep).toString();
        }
        if (value[1].toString().length === 1) value[1] = '0' + value[1].toString();
      }
      value = value[0].toString() + ":" + value[1].toString();
      lpos = cursorPos;
    }
    this.props.onChange(value);
    setTimeout(() => {
      target.selectionStart = lpos;
      target.selectionEnd = lpos;
    }, 1);
    return false;
  }

  private onFocus() {
    this.setState({focus: true});
  }

  private onBlur() {
    const cheked = this.checkTrueTime(this.props.value, true);
    if (cheked !== this.props.value) {
      this.props.onChange(cheked);
    }
    this.setState({focus: false});
  }

  private onKeyDown(e: any) {
    e.stopPropagation();
    const value = e.target.value;
    const target = e.target;
    const cursorPos = e.target.selectionStart;
    let action = 'null';
    if(Number(e.key).toString() !== 'NaN') {
      action = 'write';
      // if (value.length === 5 && selecionRange === 0) return false;
      this.onInputChandge(target, value, action, cursorPos, e.key);
    } else if (e.key === 'Backspace' || e.key === 'Delete') {
      action = 'remove';
      this.onInputChandge(target, value, action, cursorPos, e.key);
    } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      action = 'arrows';
      this.onInputChandge(target, value, action, cursorPos, e.key);
    }
    return false;
  }

  private checkTrueTime(value: string, blur: boolean) {
    const time: any[] = value.split(':');
    if(time.length === 1) {
      time.push('');
    }
    if(Number(time[0]) > 23) time[0] = '23';
    if(Number(time[1]) > 59) time[1] = '59';
    if (blur) {
      if(time[0].length === 1) time[0] = '0' + time[0];
      if(time[1].length === 1) time[1] = '0' + time[1];
    }
    value = time[0] + ':' + time[1];
    return value;
  }

}


export const Timepicker = TimepickerPresenter;
  //   compose(
  //     // withBemMod(cnTimepicker(), {})
  //     )(
  //       TimepickerPresenter
  //   )
  // )
