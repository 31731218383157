import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from 'redux/connector';

export interface ITimePicker2Props extends IClassNameProps, ITimePicker2StateProps, IDispatchProps {
  tag?: 'div';
  value: Date;
  disabled?: boolean
  onChange(date: Date): void;
}

export interface ITimePicker2State {
  text?: string;
  isFocus: boolean;
}

export interface ITimePicker2StateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})

export const cnTimePicker2 = cn('TimePicker2');