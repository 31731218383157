import React, { FC } from 'react'
import { cnSessions, mapDispatchToProps, mapStateToProps } from './Sessions.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Sessions.scss'
import { SearchSelectize } from 'uielements/src';
import withSessionSuggester from 'blocks/HOCs/SearchSelectize/withSessionSuggester'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
const SessionSelectize = withSessionSuggester(SearchSelectize)

export const SessionsPresenter: FC<any> = ({
    className,
    sessionsIds = [],
    normalSessions,
    archiveSessions = [],
    cid,
    setStudentsFilters,
    currentFilters
}) => {
    const tabData: { [key: string]: any[] } = { active: sessionsIds, archive: archiveSessions }
    const elements = tabData.active.map((id: string) => {
        return {
            value: id,
            title: normalSessions[id].name,
            id: id
        }
    })
    return <div className={cnSessions({}, [className])}>
        <SessionSelectize
            onClick={(value) => { setStudentsFilters({ 'filter.sessionIds': value.map((val: any) => val.id) }) }}
            courseId={cid}
            name={tT('student_list.filters.sessions.1')}
            cut={10}
            defaultElements={elements}
        />
    </div>
}

export const Sessions = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SessionsPresenter));
