import { IAdditionalFieldsRenderProps, cnAdditionalFieldsRender } from './AdditionalFieldsRender.index';
import * as React from 'react'
import './AdditionalFieldsRender.scss'
import { IdeaField } from '../Fields/Field';
import { IdeaFields } from '../Fields/Types'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip'
// import { Tooltip } from 'uielements/src/Tooltip/Tooltip';
import { CollapseBox } from 'uielements/src/CollapseBox/CollapseBox';
import { Icon } from 'uielements/src/Icon/Icon'
import Tooltip from 'uielements/src/MaterialElements/Tooltip';


export const AdditionalFieldsRender: React.FC<IAdditionalFieldsRenderProps> = ({ data, onChange }) => {
    const onChangeExpertFields = (field: IdeaFields) => {
        onChange(field);
    }

    const ref = React.useRef<HTMLDivElement>(null);
    // const noExpertField: any = data?.entities?.fields &&  Object.values(data?.entities?.fields).find((el: any) => el.field.fieldType === 'UserNotExpert');
    const noExpertField: any = React.useMemo(() => data?.entities?.fields && Object.values(data.entities.fields).find((el: any) => el.field.fieldType === 'UserNotExpert'), [])

    const isNotExpert = (data?.entities?.fields && noExpertField) && (data.entities.fields[noExpertField.fieldId].field.fieldType === 'UserNotExpert' && data.entities.fields[noExpertField.fieldId].value === 'true')

    return (
        <div className={cnAdditionalFieldsRender()}>
            {
                data.result.map((catID: any) => {
                    if (catID === "") {
                        return (
                            <div className={cnAdditionalFieldsRender('Category')} key={catID}>
                                {data.entities.categorys[catID].v.map((fieldId: any) => {
                                    const { field } = data.entities.fields[fieldId];
                                    if (isNotExpert && field.fieldType !== 'UserNotExpert') return <></>;
                                    const noTooltip = field.fieldType !== "Textarea" && field.fieldType !== "String";
                                    const isMandatory = field.isMandatory ? field.name + ' *' : field.name;
                                    return (
                                        <div key={fieldId} className={cnAdditionalFieldsRender('Item')}>
                                            {
                                                field.fieldType !== "Textarea" &&
                                                field.fieldType !== "String" &&
                                                field.fieldType !== "Tags" &&
                                                field.fieldType !== "Bool" &&
                                                <h5 className={cnAdditionalFieldsRender('Name')}>
                                                    {isMandatory}
                                                    {/* <HelpTooltip help={field.description}>{field.name}</HelpTooltip> */}
                                                </h5>
                                            }

                                            {
                                                noTooltip ? <div className={cnAdditionalFieldsRender('Field')}>
                                                    <IdeaField field={data.entities.fields[fieldId]} onChange={onChangeExpertFields} />
                                                    {
                                                        field.description &&

                                                        <Tooltip title={field.description}>
                                                            <div className={cnAdditionalFieldsRender('Field-Help')}>
                                                                <Icon className={cnAdditionalFieldsRender('CollapseBox-Toggle-Icon')} icon={'question-circle'} />
                                                            </div>

                                                        </Tooltip>

                                                    }
                                                </div> : <IdeaField field={data.entities.fields[fieldId]} onChange={onChangeExpertFields} />
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    } else {
                        // COMMON-9373
                        const catWithTypeUserNoExpert = data.entities.categorys[catID].v.filter((fieldId: any) => data.entities.fields[fieldId].field.fieldType === "UserNotExpert").length > 0
                        if (!catWithTypeUserNoExpert && isNotExpert) return <></>
                        return (
                            <div className={cnAdditionalFieldsRender('Category')} key={catID}>
                                <CollapseBox className={cnAdditionalFieldsRender('CollapseBox')} headerRender={(open: boolean) =>
                                    <div className={cnAdditionalFieldsRender('CollapseBox-Toggle')} >
                                        <h4 className={cnAdditionalFieldsRender('CollapseBox-Toggle-Text')}>{catID}</h4>
                                        <Icon className={cnAdditionalFieldsRender('CollapseBox-Toggle-Icon')} icon={open ? 'chevron-down' : 'chevron-left'} />
                                    </div>}>

                                    {data.entities.categorys[catID].v.map((fieldId: any) => {

                                        const { field } = data.entities.fields[fieldId];
                                        if (isNotExpert && field.fieldType !== 'UserNotExpert') return <></>;

                                        const noTooltip = field.fieldType !== "Textarea" && field.fieldType !== "String";
                                        const isMandatory = field.isMandatory ? field.name + ' *' : field.name;
                                        return (
                                            <div key={fieldId} className={cnAdditionalFieldsRender('Item')}>
                                                {
                                                    field.fieldType !== "Textarea" &&
                                                    field.fieldType !== "String" &&
                                                    field.fieldType !== "Bool" &&
                                                    field.fieldType !== "Multi" &&
                                                    field.fieldType !== "Single" &&
                                                    field.fieldType !== "Tags" &&
                                                    <h5 className={cnAdditionalFieldsRender('Name')}>
                                                        {isMandatory}
                                                    </h5>
                                                }
                                                {
                                                    noTooltip ?
                                                        <div className={cnAdditionalFieldsRender('Field')}>
                                                            <IdeaField field={data.entities.fields[fieldId]} onChange={onChangeExpertFields} />
                                                            {
                                                                field.description &&
                                                                // <HelpTooltip help={field.description}>
                                                                <Tooltip
                                                                    title={field.description}
                                                                >

                                                                    <span><Icon className={cnAdditionalFieldsRender('CollapseBox-Toggle-Icon')} icon={'question-circle'} /></span>
                                                                </Tooltip>
                                                            }
                                                        </div> : <IdeaField field={data.entities.fields[fieldId]} onChange={onChangeExpertFields} />
                                                }

                                            </div>
                                        )
                                    })}
                                </CollapseBox>
                            </div>
                        )
                    }
                })
            }
        </div>
    );
}