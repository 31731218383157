import constants from '../constants'

export declare namespace BaseAPITypes {
    export type CMIErrorCodeType = string | number | null
    export type CMIElementType = string | null
    export type ListenerCallbackType = (CMIElement: CMIElementType, value: any) => void
    export type ListenerType = {
        functionName: string,
        CMIElement: CMIElementType,
        callback: ListenerCallbackType
    }
}

class BaseAPI {

    /**
     * 
     */
    public apiLogLevel = constants.LOG_LEVEL_DEBUG

    /**
     * 
     */
    public listenerArray: BaseAPITypes.ListenerType[] = [];

    /**
     * 
     */
    public currentState = constants.STATE_NOT_INITIALIZED;

    /**
     * 
     */
    public lastErrorCode: string | number = "0";

    /**
     * Logging for all SCORM actions
     *
     * @param functionName
     * @param CMIElement
     * @param logMessage
     * @param messageLevel
     */
    public apiLog(functionName: string, CMIElement: BaseAPITypes.CMIElementType, logMessage: string, messageLevel: number) {
        logMessage = formatMessage(functionName, CMIElement, logMessage);

        if (messageLevel >= this.apiLogLevel) {
            switch (messageLevel) {
                case constants.LOG_LEVEL_ERROR:
                    console.error(logMessage);
                    break;
                case constants.LOG_LEVEL_WARNING:
                    console.warn(logMessage);
                    break;
                case constants.LOG_LEVEL_INFO:
                    console.info(logMessage);
                    break;
            }
        }
    }


    /**
     * Clears the last SCORM error code on success
     */
    public clearSCORMError(success: string) {
        if (success !== constants.SCORM_FALSE) {
            this.lastErrorCode = "0";
        }
    }

    /**
     * Returns the message that corresponds to errrorNumber
     * APIs that inherit BaseAPI should override this function
     */
    public getLmsErrorMessageDetails(_errorNumber: BaseAPITypes.CMIErrorCodeType, _detail?: any) {
        return "No error";
    }


    /**
     * Returns true if the API's current state is STATE_INITIALIZED
     */
    public isInitialized() {
        return this.currentState === constants.STATE_INITIALIZED;
    }


    /**
     * Returns true if the API's current state is STATE_NOT_INITIALIZED
     */
    public isNotInitialized() {
        return this.currentState === constants.STATE_NOT_INITIALIZED;
    }


    /**
     * Returns true if the API's current state is STATE_TERMINATED
     */
    public isTerminated() {
        return this.currentState === constants.STATE_TERMINATED;
    }

    /**
     * Provides a mechanism for attaching to a specific SCORM event
     *
     * @param listenerString
     * @param callback
     */
    public onListener(listenerString: string, callback: BaseAPITypes.ListenerCallbackType) {
        if (!callback) return;

        let listenerFunctions = listenerString.split(" ");
        for (var i = 0; i < listenerFunctions.length; i++) {
            var listenerSplit = listenerFunctions[i].split(".");
            if (listenerSplit.length === 0) return;

            var functionName = listenerSplit[0];

            var CMIElement = null;
            if (listenerSplit.length > 1) {
                CMIElement = listenerString.replace(functionName + ".", "");
            }

            this.listenerArray.push({
                functionName: functionName,
                CMIElement: CMIElement,
                callback: callback
            });
        }
    }

    /**
     * Processes any 'on' listeners that have been created
     *
     * @param functionName
     * @param CMIElement
     * @param value
     */
    public processListeners(functionName: string, CMIElement: BaseAPITypes.CMIElementType = null, value?: any) {
        for (var i = 0; i < this.listenerArray.length; i++) {
            const listener = this.listenerArray[i];
            const functionsMatch = listener.functionName === functionName;
            const listenerHasCMIElement = !!listener.CMIElement;
            const CMIElementsMatch = listener.CMIElement === CMIElement;

            if (functionsMatch && (!listenerHasCMIElement || CMIElementsMatch)) {
                listener.callback(CMIElement, value);
            }
        }
    }


    /**
     * Reset the API to its initial state
     */
    public reset() {
        // Internal State
        this.currentState = constants.STATE_NOT_INITIALIZED;
        this.lastErrorCode = "0";

        // Utility Functions
        this.apiLogLevel = constants.LOG_LEVEL_ERROR;
        this.listenerArray = [];
    }


    /**
     * Throws a SCORM error
     *
     * @param errorNumber
     * @param message
     */
    public throwSCORMError(errorNumber: any, message?: string) {
        if (!message) {
            message = this.getLmsErrorMessageDetails(errorNumber);
        }

        this.apiLog("throwSCORMError", null, errorNumber + ": " + message, constants.LOG_LEVEL_ERROR);

        this.lastErrorCode = String(errorNumber);
    }

}




/**
 * Formats the SCORM messages for easy reading
 *
 * @param functionName
 * @param CMIElement
 * @param message
 * @returns {string}
 */
export function formatMessage(functionName: string, CMIElement: BaseAPITypes.CMIElementType, message: string) {
    const baseLength = 20;
    let messageString = "";

    messageString += functionName;

    let fillChars = baseLength - messageString.length;

    for (let i = 0; i < fillChars; i++) {
        messageString += " ";
    }

    messageString += ": ";

    if (CMIElement) {
        const CMIElementBaseLength = 70;

        messageString += CMIElement;

        fillChars = CMIElementBaseLength - messageString.length;

        for (let j = 0; j < fillChars; j++) {
            messageString += " ";
        }
    }

    if (message) {
        messageString += message;
    }

    return messageString;
}




export default BaseAPI
