import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment'
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import './CalendarMini.scss';
import { makeStyles } from '@material-ui/core/styles';
import { Moment } from "moment";
import { useClendarEvents } from "./CalendarMini.hooks";
import { TValue } from "utils/src";
import { useHistory } from "react-router";
import urls from "routes/urls";
import { Box, Popover } from '@material-ui/core';
import { StyledBox } from "./CalendarMini.styled";
import { SettingsCalendarMiniWidget, cnCalendarMini } from './CalendarMini.index'
import { initWidgetAddition, initWidgetSettings } from './CalendarMiniSettingsDialog/CalendarMiniSettingsDialog'

initWidgetAddition();
initWidgetSettings();

const useStyles = makeStyles({
    root: {
        display: 'block',
        position: 'relative',
    },
    dots: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    dot: {
        backgroundColor: "black",
        width: 7,
        borderRadius: '50%',
        boxSizing: 'border-box',
        border: '1px solid #d3d9e0',
        margin: 1,
        '&:before': {
            content: '""',
            float: 'left',
            paddingTop: '100%',
            display: 'block'
        }
    },
    item: {
        padding: '4px 8px',
        borderRadius: 4
    }
});

const DayEvents: FC<{
    date: string;
    events?: TValue<ReturnType<typeof useClendarEvents>['events']>
}> = ({
    date,
    events = [],
    children
}) => {

        const classes = useStyles();

        const history = useHistory();

        const [tooltipOpen, setTooltipOpen] = useState(false);

        return (
            <Tooltip
                open={tooltipOpen}
                onOpen={() => events.length && setTooltipOpen(true)}
                onClose={() => setTooltipOpen(false)}
                arrow
                title={
                    events.map(event => {
                        return (
                            <Box
                                className={classes.item}
                                key={event.id}
                                sx={{
                                    bgcolor: event.color
                                }}
                            >
                                {event.displayName}
                            </Box>
                        )
                    })
                }
            >
                <div
                    className={cnCalendarMini('Day', [classes.root])}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        const prepairedDate = new Date(date).toISOString();
                        history.push(urls.calendar + `?defaultDate=${prepairedDate}&defaultView=day`);
                    }}
                >
                    {children}
                    <div className={cnCalendarMini('Dots', [classes.dots])}>
                        {
                            events.map(event => {
                                return (
                                    <div style={{ backgroundColor: event.color || undefined }} className={cnCalendarMini('Dot', [classes.dot])} />
                                )
                            })
                        }
                    </div>
                </div>
            </Tooltip>
        )
    }

export const CalendarMini: FC<SettingsCalendarMiniWidget.Props> = ({
    widgetContext,
    settings
}) => {
    const date = useMemo(() => new Date(), []);

    const {
        events,
        setDates
    } = useClendarEvents(date, widgetContext.gId, settings?.calendar?.map((el:any)=>el.id) );

    return (
        <StyledBox>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    className={cnCalendarMini()}
                    variant="static"
                    openTo="date"
                    value={date}
                    onChange={() => { }}
                    onMonthChange={(date) => {
                        if (!date) return;
                        const dt = date as any as Moment;
                        setDates({ start: dt.startOf('month').toISOString(), end: dt.endOf('month').toISOString() });
                    }}
                    renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                        const date = (day as any as Moment)?.format('yyyy-MM-DD[T00:00:00Z]');
                        const evts = events[date];
                        return isInCurrentMonth ? <DayEvents date={date} events={evts}>{dayComponent}</DayEvents> : dayComponent;
                    }}
                    disableToolbar={true}
                />
            </MuiPickersUtilsProvider>
        </StyledBox>
    )
}