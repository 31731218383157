import Component, { Presenter } from './component';
import {
    Shop,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = Shop.OrderBlock.IOwnProps;
export type IComponentPresenterProps = Shop.OrderBlock.IPresenterProps;


export default Component