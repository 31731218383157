import * as utils from 'utils/src/utils';
import { IRequest } from 'utils/src/requests/service';

let request: IRequest;

export const searchUsers = (
    value: string,
    callback: (users: any, error: boolean, status?: string) => void,
    excludeMe: boolean = false,
    type: string = 'users',
    network?: string,
    skipCount?: number,
    count: number = 10
) => {
    if (request) request.ac.abort();
    let lType = type
    let my = false
    if (type === 'mygroups') {
        lType = 'groups'
        my = true
    }
    request = utils.API.suggesters.search(lType, value.trim(), -1, excludeMe, { network, count, skipCount, my });
    request.r.then((d: any) => {
        if (d && d.error_code === 0 && d.data) {
            callback(d.data, false, 'success');
        } else if (d && d.dom_error === 'AbortError') {
            callback([], false, 'AbortError');
        }
        else callback([], true, 'fail');
    });
}
