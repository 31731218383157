import React, { FC, useMemo } from 'react';
import PurseImg from 'assets/svg/purse.svg';
import { cn } from '@bem-react/classname';
import { Translate } from 'localizations/Translate';
import { useSelector } from 'react-redux';
import { getCurrencyNameCase } from 'utils/src/utils';
import { getCurrencyNames } from 'utils/src/CommonRedux/base/selectors';
import './TimelineItem.scss';
import { AccountBalanceWalletOutlined } from 'muicomponents/src/Icons';
import { formatDateSTD } from 'utils/src/utils.dates';


const cnCTWalletsItem = cn('CTWalletsItem');

type WalletsTimelineItemProps = {
    userSex: string;
    data: {
      isDeleted: boolean;
      value: number;
      transferDate: string;
      transactionModelType: string;
      transactionType: string;
    }
};


export const WalletsTimelineItem: FC<WalletsTimelineItemProps> = ({
    userSex,
    data
}) => {

    const {
        isDeleted,
        value,
        transferDate,
        transactionModelType,
        transactionType
    } = data;

    const currencyNames = useSelector(getCurrencyNames);

    const isPositive = transactionModelType !== 'Deposit';

    const texts = useMemo(() => {
        switch (transactionModelType) {
            case 'DepositClosed': {
                return {
                    header: 'pryaniky.wallets.timeline.close.force',
                    text: 'pryaniky.wallets.timeline.close.text'
                };
            };
            case 'DepositPayout': {
                return {
                    header: 'pryaniky.wallets.timeline.close.periorend',
                    text: 'pryaniky.wallets.timeline.close.text'
                };
            };
            case 'Deposit': {
                return {
                    header: 'pryaniky.wallets.timeline.open',
                    text: 'pryaniky.wallets.timeline.open.text'
                };
            };
            default: {
                return {
                    header: '',
                    text: ''
                };
            }
        };
    }, [transactionModelType]);

    if(isDeleted) return null;

    return (
        <div className={cnCTWalletsItem('', ['Widget'])}>
          <div className={cnCTWalletsItem('Header')}>
            <span><Translate i18nKey={texts.header} /></span>
            <div className={cnCTWalletsItem('Header-Info', { isPositive })}>
              <img src={PurseImg} />
              <span>{value}</span>
            </div>
            <span>{currencyNames[getCurrencyNameCase(value, true) as keyof typeof currencyNames]}</span>
          </div>
    
          <div className={cnCTWalletsItem('Content')}>

            <AccountBalanceWalletOutlined className={cnCTWalletsItem('Icon', { isPositive })} sx={{ fontSize: '180px' }} />
    
            <div>
              <div>
                <span><Translate i18nKey={texts.text} /></span>
                <div>{formatDateSTD(transferDate, true)}</div>
              </div>
            </div>
          </div>
        </div>
    );
};