const YOUTUBEMATCH_URL = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const VIMEOMATCH_URL = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/; // eslint-disable-line no-useless-escape
const VIDEOFILE_URL = /https?:\/\/.(mp4|avi|mov|wmv)/; // eslint-disable-line no-useless-escape
export default {
  isYoutube: (url: any) => YOUTUBEMATCH_URL.test(url) && !url.includes('list='),
  isVimeo: (url: any) => VIMEOMATCH_URL.test(url),
  isVideoFile: (url: any) => VIDEOFILE_URL.test(url),
  getYoutubeSrc: (url: any) => {
    const id = url && url.match(YOUTUBEMATCH_URL)[1];
    return {
      srcID: id,
      srcType: 'youtube',
      url,
    };
  },
  getVimeoSrc: (url: any) => {
    const id = url.match(VIMEOMATCH_URL)[3];
    return {
      srcID: id,
      srcType: 'vimeo',
      url,
    };
  },
  getVimeoThumbnailId: (url: string) => {
    const id = url.replace('https://i.vimeocdn.com/video/', '').split('_')[0]
    return id;
  },
  getVideoFileThumbnailId: (url: string) => {
    const id = '/ru/Data/v3/Video/Preview?url='+url; //Пробуем сгенерировтаь preview На сервере
    return id;
  }
};
