import * as React from 'react';
// import { defaultBaseNews } from '../../../../BaseType'
import { IEditorTypeEditProps } from './edit.index';
// import { IdeaNews } from '../../../IdeaType'
import {EditorTypeNews} from '../news/news'

export const EditorTypeEdit: React.FC<IEditorTypeEditProps> = (props) => {

    return <EditorTypeNews {...props}/>
}
