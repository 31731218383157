
import { ThanksNews, RewardReason } from './Thanks.type'
import { User } from '../BaseType'
import { Rule, BaseValidator, validateArray, validateNumber } from '../Base.validate'
import { nameCount } from 'utils/src/utils'
export const thanksValidate: Rule[] = [
    {
        type: 'array',
        method: 'ARR_MIN',
        field: 'rewardReasons',
        value: 1,
        errorI18nKey: 'pryaniky.validate.news.thanks.rewardReasons.min',
        // message: 'Нужно выбрать хотя бы одну причину для благодарности'
    },
    // {
    //     type: 'array',
    //     method: 'ARR_MAX',
    //     field: 'rewardReasons',
    //     value: 4,
    //     errorI18nKey: 'pryaniky.validate.news.thanks.rewardReasons.max',
    //     errorVariablesI18nKey: {
    //         count: 4
    //     }
    //     // message: 'Слишком много причин для благодарности, максимум 4'
    // },
    // {
    //     type: 'array',
    //     method: 'ARR_MIN',
    //     field: 'users',
    //     value: 1,
    //     message: 'Нужно выбрать кого поблагодарить'
    // },
    /*{
        type: 'array',
        method: 'ARR_MAX',
        field: 'users',
        value: 4,
        message: 'big users'
    },*/
    {
        type: 'number',
        method: 'NUM_MIN',
        field: 'thanksCount',
        value: 1,
        // message: 'Нужно вложить больше',
        errorI18nKey: 'pryaniky.validate.news.thanks.thanksCount.min',
    },
    {
        type: 'number',
        method: 'NUM_MIN',
        field: 'thanksForAll',
        value: 1,
        // message: 'У Вас недостаточно средств',
        errorI18nKey: 'pryaniky.validate.news.thanks.thanksForAll.min',
        major: true,
    },
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'text',
        value: 5,
        errorI18nKey: 'pryaniky.validate.news.thanks.text.min',
        errorVariablesI18nKey: {
            count: 5,
            variable: nameCount(5, 'pryaniky.validate.string.variable')
        }
    },
    /*{
        type: 'number',
        method: 'NUM_MAX',
        field: 'thanksCount',
        value: 20,
        message: 'big thanksCount'
    },*/
]

export const thanksEditValidate: Rule[] = [
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'text',
        value: 5,
        errorI18nKey: 'pryaniky.validate.news.thanks.text.min',
        errorVariablesI18nKey: {
            count: 5,
            variable: nameCount(5, 'pryaniky.validate.string.variable')
        }
        // message: 'Добавить текст благодарности, не короче 5 символов'
    },
    // {
    //     type: 'string',
    //     method: 'STR_MAX',
    //     field: 'text',
    //     value: 250,
    //     message: 'Слишком длинный текст'
    // },
]
export class ThanksValidator extends BaseValidator<ThanksNews> {
    protected otherValidate = (data: ThanksNews, rule: Rule) => {
        switch (rule.field) {
            case 'rewardReasons':
                return validateArray<RewardReason>(data.rewardReasons, rule);
            case 'users':
                return validateArray<User>(data.users, rule);
            case 'thanksCount':
                return validateNumber(data.thanksCount, rule);
            case 'thanksForAll':
                return validateNumber(data.thanksForAll || 0, rule);
        }
        return true;
    }
}
