import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ReactNode } from 'react';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface User {
    id: string;
    displayName: string;
    eMail: string;
    imgId: string;
    sex: string;
    imgUrl: string;
    position: string;
    division: string;
    phone?: any;
}
export interface SReplyMobileModel {
    id: string;
    text: any;
    header: string;
    liked: boolean;
    likeCount: number;
    date: Date;
    changeDate: Date;
    user: any;
    attachments?: any;
    parentCommentId?: any;
    actions: string[];
    type: string;
    ratingEnabled?: boolean
    thanksInfo?: {
        users: any[],
        thanksReasonIds: number[],
        thanksCount: number
    }
}
export interface IReplyesProps extends IClassNameProps, ReturnType<typeof mapStateToProps> {
    rewards?: boolean;
    fund?: number;
    comments: SReplyMobileModel[];
    newsID: string;
    commentsCount: number;
    thank: boolean;
    newsAuthorId: string;
    thankUsers: any[];
    disable: boolean;
    additionalReplyActions?: (commentID: string, user: any) => ReactNode;
    additionalReplyForm?: () => ReactNode;
    pushReply?: (r: (r: SReplyMobileModel) => void) => void;
    toggleThank: (thank: boolean) => void;
    changeCreativeTaskState: (state: string) => void;
}

export interface IReplyesState {
    text?: string;
    comments: SReplyMobileModel[];
    isLoading: boolean;
    thanksReasonIds: number[];
    rewardedComments: Map<string, number>;
    fund: number;
}

export const mapStateToProps = (state: any) => ({
    userData: getAuthUser(state).baseData,
})

export const cnReplyes = cn('Replyes');

export const toSReplyMobileModel = (data: any[]) => {
    const temp: SReplyMobileModel[] = [];
    if (!data) return temp;
    data.forEach((value: any) => {
        temp.push({
            id: value.id,
            text: value.text,
            header: value.header || '',
            liked: value.liked,
            likeCount: value.likeCount,
            date: value.date,
            changeDate: value.date,
            user: value.user,
            attachments: value.attachments,
            parentCommentId: value.parentCommentId,
            actions: value.actions,
            type: value.type || 'default'
        });
    });
    return temp;
};