import { IBaseNews } from '../types/baseNews'
import { get } from 'lodash'

export type Validate = Rule[];

export type TValidator = <T>(value: number, data: number | string | boolean | T[]) => boolean;

export interface Rule {
    type: 'string' | 'number' | 'array' | 'date' | 'draft',
    method: string,
    field: string,
    value: number,
    message?: string,
    errorI18nKey?: string;
    errorVariablesI18nKey?: {
        [key: string]: string | number;
    }
    major?: boolean
}

export type TRules = { [key: string]: Rule }

export const baseValidate: TRules = {
    'string/STR_MIN/text': {
        type: 'string',
        method: 'STR_MIN',
        field: 'text',
        value: 3,
        errorI18nKey: 'pryaniky.validate.news.base.text.min'
    },
    'string/STR_MIN/header': {
        type: 'string',
        method: 'STR_MIN',
        field: 'header',
        value: 0,
        errorI18nKey: 'pryaniky.validate.news.base.header.min'
    },
}
export type ValidateMethodString = (value: number, data: string) => boolean
export type ValidateMethodNumber = (value: number, data: number) => boolean
export type ValidateMethodArray = <T>(value: number, data: T[]) => boolean
export type ValidateMethodDate = (value: number, data: Date | string) => boolean

export type ValidateMethodsString = Map<string, ValidateMethodString>;
export type ValidateMethodsNumber = Map<string, ValidateMethodNumber>;
export type ValidateMethodsArray = Map<string, ValidateMethodArray>;
export type ValidateMethodsDate = Map<string, ValidateMethodDate>;


export const validateStringLength: ValidateMethodsString = new Map<string, ValidateMethodString>(Object.entries({
    STR_MIN: (value: number, data: string) => data.trim().length >= value,
    STR_MAX: (value: number, data: string) => data.trim().length <= value,
}))

export const validateNumberValue: ValidateMethodsNumber = new Map<string, ValidateMethodNumber>(Object.entries({
    NUM_MIN: (value: number, data: number) => data >= value,
    NUM_MAX: (value: number, data: number) => data <= value,
}))

export const validateArrayLength: ValidateMethodsArray = new Map<string, ValidateMethodArray>(Object.entries({
    ARR_MIN: <T>(value: number, data: T[]) => data.length >= value,
    ARR_MAX: <T>(value: number, data: T[]) => data.length <= value,
}))

export const validateDateMN: ValidateMethodsDate = new Map<string, ValidateMethodDate>(Object.entries({
    DATE_MIN: (value: number, data: Date | string) => {
        let date = new Date()
        if (data instanceof Date) {
            date = data
        } else {
            date = new Date(data)
        }
        return date.getTime() >= value
    },
    DATE_MAX: (value: number, data: Date | string) => {
        let date = new Date()
        if (data instanceof Date) {
            date = data
        } else {
            date = new Date(data)
        }
        return date.getTime() <= value
    }
}))

export const validateDraftJs = (data: any, rule: Rule) =>
    validateString(data.getCurrentContent().getPlainText(), rule);

export const validateString = (data: string, rule: Rule) => {
    const method = validateStringLength.get(rule.method)
    if (method) {
        if (!method(rule.value, data)) return false;
    }
    return true
}

export const validateNumber = (data: number, rule: Rule) => {
    const method = validateNumberValue.get(rule.method)
    if (method) {
        if (!method(rule.value, data)) return false;
    }
    return true
}

export const validateArray = <T>(data: T[], rule: Rule) => {
    const method = validateArrayLength.get(rule.method)
    if (method) {
        if (!method<T>(rule.value, data)) return false;
    }
    return true
}

export const validateDate = (data: Date, rule: Rule) => {
    const method = validateDateMN.get(rule.method)
    if (method) {
        if (!method(rule.value, data)) return false;
    }
    return true
}

export class Validator<T extends IBaseNews = IBaseNews> {
    private rules: TRules = {};
    private fields: string[] = [];
    private useFields: boolean = false;
    public data: T;

    public constructor() {
        this.validation = this.validation.bind(this);
    }

    public setUseField = (isUse: boolean = true) => this.useFields = true

    public addData = (data: T) => {
        this.data = data
    }

    public setRules = (rules: TRules) => {
        this.rules = rules
    }

    public addField = (fields: string[]) => {
        this.fields = fields
    }

    public validation() {
        let invalide: Rule[] = []

        for (const rule in this.rules) {
            if (!this.fields.includes(this.rules[rule].field) && this.useFields) continue;
            const value = get(this.data, [this.rules[rule].field])
            if (!value) {
                invalide.push(this.rules[rule])
                continue;
            }
            switch (this.rules[rule].type) {
                case 'string':
                    !validateString(value, this.rules[rule]) && invalide.push(this.rules[rule])
                    break;
                case 'number':
                    !validateNumber(value, this.rules[rule]) && invalide.push(this.rules[rule])
                    break;
                case 'date':
                    !validateDate(value, this.rules[rule]) && invalide.push(this.rules[rule])
                    break;
                case 'array':
                    !validateArray(value, this.rules[rule]) && invalide.push(this.rules[rule])
                    break;
                case 'draft':
                    !validateDraftJs(value, this.rules[rule]) && invalide.push(this.rules[rule])
                    break;
                default:
                    !this.otherValidate(value, this.rules[rule]) && invalide.push(this.rules[rule]);
            }
        }
        const major = invalide.find(v => v.major)
        if (major) invalide = [major]
        return invalide;
    }

    protected otherValidate = (value: any, rule: Rule) => {
        return false
    }
}

export default Validator