import { connect } from 'react-redux';
import { store } from '../../redux/store';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps } from '@jsonforms/core';
import { JFdictionary } from 'blocks/WSettings/structures/dictionary';
import { IRenderer } from './Controls/utils';

import { control } from './Controls/Control';
import { date } from './Controls/Date';
import { dateTime } from './Controls/DateTime';
import { time } from './Controls/Time';
import { radio } from './Controls/Radio';
import { checkbox } from './Controls/Checkbox';
import { usersInput } from './Controls/UserInput';
import { groupsInput } from './Controls/GroupInput';
import { newsInput } from './Controls/NewsInput';
import { ratingsInput } from './Controls/RatingInput';
import { number } from './Controls/NumberInput';
import { input } from './Controls/Input';
import { tagsInput } from './Controls/TagsInput';
import { select } from './Controls/Select';
import { text } from './Controls/Text';
import { table } from './Controls/Table';
import { sliderItem } from './Controls/SliderItem';
import { button } from './Controls/Button';
import { slider } from './Controls/Slider';
import { virtualUser } from './Controls/VirtualUser';
import { tagsColleactionsInput } from './Controls/TagsCollectionsInput';
import { suggester, SuggesterHierarchy } from './Controls/Suggester';
import { usersListBlock } from './Controls/UsersListBlock';
import { statistic } from './Controls/Statistic';
import { statisticFilters } from './Controls/StatisticFilters';
import { widgetUserfields } from './Controls/WidgetUserfields';
import {widgetIcon} from './Controls/ImageLoader'
import { loader } from './Controls/Loader';
import { textInfo } from './Controls/TextInfo';

([
  {
    name: 'control',
    c: control
  },
  {
    name: 'date',
    c: date
  },
  {
    name: 'dateTime',
    c: dateTime
  },
  {
    name: 'time',
    c: time
  },
  {
    name: 'radio',
    c: radio
  },
  {
    name: 'checkbox',
    c: checkbox
  },
  {
    name: 'usersInput',
    c: usersInput
  },
  {
    name: 'groupsInput',
    c: groupsInput
  },
  {
    name: 'newsInput',
    c: newsInput
  },
  {
    name: 'ratingsInput',
    c: ratingsInput
  },
  {
    name: 'number',
    c: number
  },
  {
    name: 'input',
    c: input
  },
  {
    name: 'loader',
    c: loader
  },
  {
    name: 'tagsInput',
    c: tagsInput
  },
  {
    name: 'select',
    c: select
  },
  {
    name: 'text',
    c: text
  },
  {
    name: 'textInfo',
    c: textInfo
  },
  {
    name: 'table',
    c: table
  },
  {
    name: 'sliderItem',
    c: sliderItem
  },
  {
    name: 'button',
    c: button
  },
  {
    name: 'slider',
    c: slider
  },
  {
    name: 'virtualUser',
    c: virtualUser
  },
  {
    name: 'tagsColleactionsInput',
    c: tagsColleactionsInput
  },
  {
    name: 'suggester',
    c: suggester
  },
  {
    name: 'usersListBlock',
    c: usersListBlock
  },
  {
    name: 'statistic',
    c: statistic
  },
  {
    name: 'statisticFilters',
    c: statisticFilters
  },
  {
    name: 'widgetUserfields',
    c: widgetUserfields
  },
  {
    name: 'widgetIcon',
    c: widgetIcon
  },
  {
    name: 'suggesterHierarchy',
    c: SuggesterHierarchy
  }
] as any[]).map((el): IRenderer => {
  if (!(JFdictionary.renderers as any)[el.name]) return { 
    error: true,
    name: el.name,
    renderer: undefined,
    tester: undefined,
  };
  return {
    name: el.name,
    renderer: connect(mapStateToLayoutProps)(el.c),
    tester: rankWith(1000, uiTypeIs((JFdictionary.renderers as any)[el.name])),
  }
}).forEach((renderer) => {
  if (renderer.error) console.error(`PRYANIKY JSONFORMS RENDERER REGISTER ERROR: renderer with name "${renderer.name}" does not exsist in JFdictionary.renderers`);
  if (!renderer.error)
    store.dispatch(
      Actions.registerRenderer(
        renderer.tester,
        renderer.renderer
      )
    );
});