import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    sendLikeNews
} from '../../../redux/actions'

import {
    IActionButtons
} from '../../../types/baseNews'
import {
    getNewsParamsById
} from '../../../redux/saga/selectors'
import { NewsContextProps } from '../../../contexts/news'
import { AnyAction } from 'redux'
// import {  } from 'models/lib/api.learningTracks'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsActions {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & NewsContextProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            actions: IActionButtons[]
            children?: React.ReactNode
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.NewsActions.IOwnProps

export const mapStateToProps = (state: any, { newsId }: IOwnProps & NewsContextProps) => ({
    enabledThanks: (state.store.appSettings?.enabledModules?.thanks as boolean),
    ...getNewsParamsById(newsId,
        'id',
        'learningTrack'
        // 'actionButtons'
    )(state)
})


export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps & NewsContextProps) => bindActionCreators({
    sendAction: (action: AnyAction) => ({
        ...action,
        payload: {
            ...action.payload,
            id: ownProps.newsId
        }
    })
}, dispatch);

////////////

export const cnClassName = cn('SlaveAction');