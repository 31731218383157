import { cn } from '@bem-react/classname'

export const cnGroupItem = cn('GroupItem')

export interface IGroupItem {
  item: any
  imgUrl: string
  networkId: string
  userId: string
  notificationsTemplate: any
}

