import { cn } from '@bem-react/classname';

export interface ICalendarModalProps {
  onClose: void
  onShown: void
  users: ICalendarModalUsers[]
}

export interface ICalendarModalUsers {
  userId: string
  displayName: string
  status: string
  imgUrl: string
}


export const cnCalendarModal = cn('CalendarModal');