import React, { Component, useState } from 'react';
import { StyleButton } from '../StyleButton/StyleButton'
import { DropdownSelect } from '../DropdownSelect/DropdownSelect'
import './BlockStyleControls.scss'

const BLOCK_TYPES = [
    { label: 'Заголовок H1', style: 'header-one' },
    { label: 'Заголовок H2', style: 'header-two' },
    { label: 'Заголовок H3', style: 'header-three' },
    { label: 'Заголовок H4', style: 'header-four' },
    { label: 'Заголовок H5', style: 'header-five' },
    { label: 'Заголовок H6', style: 'header-six' },
];

export const BlockStyleControls = (props: any) => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
    const currentType = BLOCK_TYPES.find((val) => val.style === blockType)
    const label = currentType ? currentType.label : 'H';
    return (
        <div className="RichEditor-controls">
            <DropdownSelect
                button={(open, toggle) => <StyleButton
                    label={<i className="Icon icon-font" />}
                    onToggle={toggle}
                    active={open}
                // style={type.style}
                />}
            >
                {(open, close) =>
                    <ul className={'Headers-List'} onClick={close}>
                        <li className={'Headers-ListItem'}>
                            <button className={'Headers-Btn'} onMouseDown={() => props.onToggle(currentType)}>
                                Обычный текст
                        </button>
                        </li>
                        {BLOCK_TYPES.map((type) =>
                            <li className={'Headers-ListItem' + (type.style === blockType ? ' Headers-ListItem_active' : '')}>
                                <button className={'Headers-Btn ' + type.style} onMouseDown={() => props.onToggle(type.style)}>
                                    {type.label}
                                </button>
                                {/* <StyleButton
                                key={type.label}
                                active={type.style === blockType}
                                label={type.label}
                                onToggle={props.onToggle}
                                style={type.style}
                            /> */}
                            </li>
                        )}
                    </ul>
                }
            </DropdownSelect>


        </div>
    );
};
