import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IPriorityNavTab {
  id: string;
  title: string | any;
  [s: string]: any;
}

export interface IPriorityNavProps extends IClassNameProps {
  elements: IPriorityNavTab[];
  activeElemId?: number | string;
  switchSort?: boolean;
  onChange?: (el: any) => void;
}

export type IPriorityNavItem = IPriorityNavTab & {width: number};

export interface IPriorityNavState {
  maxCount: number;
}

export const cnPriorityNav = cn('PriorityNav');
