import { bindActionCreators, Action, Dispatch } from "redux"
import { IBodyProps } from 'utils/src/DialogCreator';
import { IDialog } from "../DialogWrapper.index";
import { getAuthUser } from "utils/src/CommonRedux/base/actions";

export type IWithQRDialogStateProps = ReturnType<typeof withQRDialogMapStateToProps>;

export interface IWithQRDialogProps extends IBodyProps, IWithQRDialogOwnProps, IWithQRDialogDispatchProps, IWithQRDialogStateProps { }

export interface IWithQRDialogOwnProps {}

export interface IWithQRDialogState {
  data?: any;
  isShownQRLogin: boolean;
  phone: {
    value: string,
    error: string,
    valid: boolean
  };
  // error?:  boolean | string;
  sendOnPhone?: boolean;
  timer: boolean
}

export const withQRDialogMapStateToProps = (state: any, props: IWithQRDialogOwnProps) => {
  return {};
};

export type IWithQRDialogDispatchProps = ReturnType<typeof mapWithQRDialogDispatchToProps>

export const mapWithQRDialogDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
   
}, dispatch)
