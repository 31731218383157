import {
    setMeeting
} from '../redux/actions'
import { participantAction } from '../redux/actions'
import { bindActionCreators } from 'redux';
import { cn } from '@bem-react/classname';
export interface IEventActionProps extends ReturnType<typeof mapDispatchToProps> {
    eventStatus: string;
    eventId: string;
    eventState?: string;
    onChanged?: (status: string) => void
    startDateTime?: Date;
    duration?: number;
    eventTitle?: string;
    eventText?: string;
    disallowParticipate?: boolean
    enableWaitList?: boolean
    eventLocation?: string
    /**
     * all places in event is full  
     * eventParticipantsCount === maxParticipantsCount
     */
    allPlacesIsFull?: boolean;
    meeting?: {
        domain: string;
        meetId: string;
        online: boolean;
        url: string;
    }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    participantAction,
    setMeeting
}, dispatch);

export const cnClassName = cn('EventAction');