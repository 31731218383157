import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    loadComments,
    toggleThank,
} from '../../redux/actions'

import {
} from '../../redux/reducer/types'
import {
    isLoadingComments,
    isSendingComment,
    getNewsParamsById,
    isThankReplyEnabled,
} from '../../redux/saga/selectors'
import { NewsContextProps } from '../../contexts/news'
import { IComponentAcceptor } from 'utils/src/ComponentInjector'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsComments {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & IComponentAcceptor

        export interface IState { }

        export interface IOwnProps extends IClassNameProps, NewsContextProps {

        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.NewsComments.IOwnProps

export const mapStateToProps = (state: any, { newsId }: IOwnProps) => ({
    isLoading: isLoadingComments(newsId)(state),
    isSending: isSendingComment(newsId)(state),
    thankReplyEnabled: isThankReplyEnabled(newsId)(state),
    ...getNewsParamsById(newsId, 
        'user',
        'comments',
        'commentsCount',
        'newstype',
        'id',
        'allowReply',
        'thanks',
        'contentRating'
        )(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadComments,
    toggleThank,
}, dispatch);

////////////

export const cnClassName = cn('NewsComments');