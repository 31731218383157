import {
    takeEvery,
    // put,
    takeLeading,
    takeLatest,
    throttle,
    fork,
    all,
} from 'redux-saga/effects';
import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';

import {
    buildTreedTestUnit,
    checkQuestionsAnswers,
    buildFlatTestUnit,
    buildSourcesWikiUnit
} from './utils'

import { answerModelCreator } from 'LMSModule/utils/dataCreators/answerCreator'
import { shortUnitModelCreator } from 'LMSModule/utils/dataCreators/unitCreator'

import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

import uuid from 'uuid/v1';

import actions from '../../actionsTypes/LMSTO';

import {
    getAnswersByQuestionId,
    getCourseById,
    getUnitsListByCourse,
    getUnitById,
    getQuestionByUnitId,
    getAnswersMap,
    getQuestionsMap,
    getAnswerById,
    getQuestionById,
    getUnitIndexById,
    getStudents,
    getUnitsRelpys,
    getUserStatCoursesListParams
} from './selectors'

import {
    setRightAnswerPayloadType,
    changeUnitPositionType,
    setRadioAnswer,
    updateCourse,
    addCourse,
    setCategories,
    throttledSendCourseToServer,
    updateUnit,
    setUnitModifed,
    loadStudentSessionsType,
    setUserSessions,
    addQuestions,
    addAnswers,
    updateQuestion,
    setUnitError,
    updateAnswer,
    convertLegacyPageActionType,
    createUnit,
    loadUnitActionType,
    addUnit,
    loadUnit,
    saveUnit,
    deleteUnit,
    changeUnitPosition,
    deleteQuestionActionType,
    deleteAnswerActionType,
    setAnswerError,
    loadUsersInCourseActionType,
    setUsersInCourse,
    setStudentsLoad,
    loadUnitVersionsActionType,
    setUnitVersions,
    loadUnitVersions,
    allowPlayCourseActionType,
    loadStudentsStatusActionType,
    loadTestResultsActionType,
    setStudentsStatus,
    loadUnitsAttandanceActionType,
    setUnitsAttandance,
    loadStatCommentsCountActionType,
    setStatCommentsCount,
    loadUnitsCommentsActionType,
    setAllPeply,
    changeUnitsReply,
    getUserscoursesinfoheadActionType,
    getUserscoursesinfoActionType,
    pathUserscoursesinfohead,
    addUserscoursesinfolist,
    getUserStatInfoInfoActionType,
    setUserStatInfoInfo,
    getUserStatInfoListActionType,
    addUserStatCoursesList,
    loadBadgesActionType,
    setBadgesList,
    addCoursesStatsList,
    setTestResults
} from '../../actions/LMSTeacherOffice'

import {
    setFullPlayedCourse
} from '../../actions/COMMON'

import { handleSetFullPlayedCourse } from '../COMMON'

import { routerActions } from 'connected-react-router';

import {
    API,
    objectArrayToMap,
    arrayMove,
} from 'utils/src/utils'


import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';

import { answerValidator } from '../../../LMSTeacherOffice/UnitEdit/unitValidation'

import { withSagaIndicator, withProgressIndicator } from 'utils/src/CommonRedux/LoadIndicator'

import {
    addComments
} from 'News/redux/actions'

import queryString from 'query-string';

import { isEqual } from 'lodash'

import { UserProgress } from '../../../LMSTeacherOffice/StatisticsFull/types'
import utils from 'blocks/PryanikyEditor/VideoPlugin/video/utils';

const zeroId = "00000000-0000-0000-0000-000000000000";

type defaultActionType = { type: string, payload: any }

/**
 * 
 * declarate sagas
 */
/**
 * переключает верный ответ, устанавливая его единственным верным в вопросе
 * @param action 
 */
const handleChangeAnswerRadio = function* handleChangeAnswerRadio(action: { type: string, payload: setRightAnswerPayloadType }) {
    try {
        const { qid, aid, uid } = action.payload
        //получаем массив ответов для текущего вопроса
        const answers = yield* select(getAnswersByQuestionId(qid))
        //получаем массив вопросов для текущего юнита
        const questions = yield* select(getQuestionByUnitId(uid))
        // получаем коллекцию всех ответов
        const allAnswers = yield* select(getAnswersMap)
        // устанавливаем признаки верности у ответов текущего вопроса
        const answersMap = objectArrayToMap(answers.map((answer: any) => ({ ...answer, isRightAnswer: answer.id === aid })), 'id')
        // проверяем ответы на корректность
        const errors = checkQuestionsAnswers(questions, { ...allAnswers, ...answersMap })
        // отправляем событие об ошибкав в юните
        yield* put(setUnitError(uid, { questionsAnswers: errors }))
        return yield* put(setRadioAnswer(answersMap, uid));
    } catch (e) {
        console.warn('handleChangeAnswerRadio', e)
    }
};

/**
 * переключает верность ответа
 * @param action 
 */
const handleChangeAnswerCheck = function* handleChangeAnswerCheck(action: { type: string, payload: setRightAnswerPayloadType }) {
    try {
        const { qid, aid, uid } = action.payload
        //получаем массив вопросов для текущего юнита
        const questions = yield* select(getQuestionByUnitId(uid))
        //получаем объект указанного ответа
        const answer = yield* select(getAnswerById(aid))
        //меняем признак верности
        const updatedAnswer = { ...answer, isRightAnswer: !answer.isRightAnswer }
        // получаем коллекцию всех ответов
        const answers = yield* select(getAnswersMap)
        // проверяем вопросы
        const errors = checkQuestionsAnswers(questions, { ...answers, [aid]: updatedAnswer })
        // отправляем событие об ошибкав в юните
        yield* put(setUnitError(uid, { questionsAnswers: errors }))
        return yield* put(updateAnswer(updatedAnswer, uid))
    } catch (error) {
        console.warn('handleChangeAnswerCheck', error)
    }
}

/**
 * перемещает юниты в списке юнитов(course.courseUnits) курсов и сохроняет курс с задержкой
 * @param action 
 */
const handleChangeUnitPosition = function* handleChangeUnitPosition(action: { type: string, payload: changeUnitPositionType }) {
    try {
        const { cid, newIndex, oldIndex, noTrot } = action.payload;
        const course = yield* select(getCourseById(cid))
        const courseUnits = arrayMove(course.courseUnits, oldIndex, newIndex)
        const updatedCourse = { ...course, courseUnits }
        yield* put(updateCourse(updatedCourse));
        if (noTrot) {
            return yield* call(handleSaveCourse, { type: '', payload: updatedCourse })
        }
        return yield* put(throttledSendCourseToServer(updatedCourse))

    } catch (e) {
        console.warn('handleChangeUnitPosition', e)
    }
}

/**
 * сохраняет переданый ей курс
 * @param action 
 */
const handleSaveCourse = function* handleSaveCourse(action: defaultActionType) {
    try {
        yield* put({ type: actions.REQUEST_BEGIN, payload: 'handleSaveCourse' })
        const request = yield* call(API.lms.saveCourse, action.payload)
        // @ts-ignore
        const result = yield request.r
        if (result.error_code === 0) {
            return yield* put({ type: actions.REQUEST_COMPLETE, payload: 'handleSaveCourse' })
        }
        else return yield* put({ type: actions.REQUEST_ERROR, payload: 'handleSaveCourse' })
    } catch (e) {
        console.warn('handleSaveCourse', e)
    }
}

/**
 * создание курса
 * @param action 
 */
const handleCreateCourse = function* handleCreateCourse(action: defaultActionType) {
    try {
        yield* put({ type: actions.REQUEST_BEGIN, payload: 'createCourse' })
        const request = yield* call(API.lms.saveCourse, action.payload)
        // @ts-ignore
        const result = yield request.r
        if (result.error_code === 0) {
            yield* put({ type: actions.REQUEST_COMPLETE, payload: 'createCourse' })
            yield* put(addCourse(action.payload))
            return yield* put(routerActions.push(`/TeacherOffice/Course/${action.payload.id}?tab=edit`))
        }
        else return yield* put({ type: actions.REQUEST_ERROR, payload: 'createCourse' })
    } catch (e) {
        console.warn('handleCreateCourse', e)
    }
}

/**
 * создание через сохранение юнита
 * @param action 
 */
const handleCreateUnit = function* handleCreateUnit(action: defaultActionType) {
    try {
        const { payload } = action;
        const course = yield* select(getCourseById(payload.courseId))
        const updatedCourse = { ...course, courseUnits: [...course.courseUnits, action.payload] }

        yield* call(handleSaveCourse, { type: '', payload: updatedCourse })
        yield* put(updateCourse(updatedCourse));
        return yield* put(routerActions.push(`?tab=edit&unitId=${payload.id}`))
    } catch (e) {

    }
}

/**
 * удаляет юнит через сохранение курса
 * @param action 
 */
const handleDeleteUnit = function* handleDeleteUnit(action: defaultActionType) {
    try {
        const { payload } = action;
        const course = yield* select(getCourseById(payload.courseId))
        const unitsList = yield* select(getUnitsListByCourse(payload.courseId))
        const listWithOutCurrent = unitsList.filter((unit: any) => unit.id !== payload.id)
        const updatedCourse = { ...course, courseUnits: listWithOutCurrent }
        yield* call(handleSaveCourse, { type: '', payload: updatedCourse })
        return yield* put(updateCourse(updatedCourse));
    } catch (e) {

    }
}

/**
 * получает с сервера категории курсов
 */
const handleLoadCategories = function* handleLoadCategories() {
    try {
        yield* put({ type: actions.REQUEST_BEGIN, payload: 'getCategories' })
        const request = yield* call(API.lms.getCategories)
        // @ts-ignore
        const result = yield request.r
        if (result.error_code === 0) {
            yield* put({ type: actions.REQUEST_COMPLETE, payload: 'getCategories' })
            return yield* put(setCategories(result.data))
        }
        else return yield* put({ type: actions.REQUEST_ERROR, payload: 'getCategories' })
    } catch (e) {
        console.error('handleLoadCategories', e)
    }
}

//удалить
const handleSaveLesson = function* handleSaveLesson(action: defaultActionType) {
    try {
        const { name, ...other } = action.payload
        yield* put({ type: actions.REQUEST_BEGIN, payload: 'saveLesson' })
        const request = yield* call(API.pages.setWidget, name, other)
        // @ts-ignore
        const result = yield request.r
        return yield* put({ type: actions.REQUEST_COMPLETE, payload: 'saveLesson' })
    } catch (e) {
        return yield* put({ type: actions.REQUEST_ERROR, payload: 'saveLesson' })
    }
}


/**
 * выполняет проверку вопросов в указаном юните
 * @param uid id юнита для проверки
 */
const handleCheckQuestionsErrors = function* handleCheckQuestionsErrors(questions: any[]) {
    try {
        // получаем коллекцию всех ответов
        const answers = yield* select(getAnswersMap)
        // проверяем вопросы
        const errors = checkQuestionsAnswers(questions, answers)

        return errors
    } catch (error) {
        console.warn('handleCheckQuestionsErrors', error)
    }
}

/**
 * сохраняет указаный юнит на сервер
 * @param action 
 */
const handleSaveUnit = function* handleSaveUnit(action: defaultActionType) {
    try {
        let unit = yield* select(getUnitById(action.payload))
        if (
            unit.unitType === 'test' ||// если это тест
            unit.unitType === 'task'// если это таск
        ) {
            const questions = yield* select(getQuestionsMap)
            const answers = yield* select(getAnswersMap)
            //получаем массив вопросов для текущего юнита
            const questionsForUnit = yield* select(getQuestionByUnitId(unit.id))
            // проверяем вопросы
            const errors = yield* call(handleCheckQuestionsErrors, questionsForUnit) as any;
            // в том случае, если есть ошибки, кидаем экшен и выходим
            if (errors.length !== 0) return yield* put(setUnitError(unit.id, { questionsAnswers: errors }))
            // преоброзуем коллекции в исходное древо
            unit = buildTreedTestUnit(unit, answers, questions)
        } else if (unit.unitType === 'wiki') {// если это урок
            // обновляем юнит, удаляем стейт драфта
            unit = buildSourcesWikiUnit(unit)
        } else if (unit.unitType === 'scorm') {

        }
        yield* put({ type: actions.REQUEST_BEGIN, payload: 'saveUnit' })
        const request = yield* call((unit.unitType === 'scorm' ? API.lms.saveScormUnit : API.lms.saveUnit), unit)
        // @ts-ignore
        const result = yield request.r
        if (result.error_code === 0) {
            yield (put(setUnitModifed(unit.id, false)))
            return yield* put({ type: actions.REQUEST_COMPLETE, payload: 'saveUnit' })
        } else return yield* put({ type: actions.REQUEST_ERROR, payload: { type: 'saveUnit', msg: 'Сервер недоступен.' } })
    } catch (error) {
        console.warn('handleSaveUnit', error)
        return yield* put({ type: actions.REQUEST_ERROR, payload: { type: 'saveUnit', msg: 'Сервер недоступен.' } })
    }
}

/**
 * загружает список сессий для указанного пользователя
 * @param action 
 */
const handleLoadStudentSessions = function* handleLoadStudentSessions(action: { type: string, payload: loadStudentSessionsType }) {
    try {
        const { archive, cid, uid } = action.payload
        yield* put({ type: actions.REQUEST_BEGIN, payload: 'loadStudentSessions' })
        const request = yield* call(API.lms.getSessionForUser, cid, uid, archive)
        // @ts-ignore
        const result = yield request.r
        if (result.error_code === 0) {
            yield* put(setUserSessions(cid, uid, result.data))
            return yield* put({ type: actions.REQUEST_COMPLETE, payload: 'loadStudentSessions' })
        } else return yield* put({ type: actions.REQUEST_ERROR, payload: 'loadStudentSessions' })
    } catch (error) {

    }
}

/**
 * создаёт новый вопрос, проводит валидацию всех вопросов, включая новый.
 * @param action 
 */
const handleCreateQuestion = function* handleCreateQuestion(action: defaultActionType) {
    try {
        const { uid, question } = action.payload
        //получаем массив вопросов для текущего юнита
        const questionsForUnit = yield* select(getQuestionByUnitId(uid))
        // проверяем вопросы
        const errors = yield* call(handleCheckQuestionsErrors, [...questionsForUnit, question])
        // отправляем событие об ошибкав в юните
        yield* put(setUnitError(uid, { questionsAnswers: errors }))
        return yield* put(addQuestions({ [question.id]: question }))
    } catch (error) {

    }
}

/**
 * создаёт новый вариант ответа на вопрос
 * @param action 
 */
const handleCreateAnswer = function* handleCreateAnswer(action: defaultActionType) {
    try {
        const { qid, uid, aid = uuid(), isRightAnswer = false } = action.payload
        //создаём модель вопроса
        const answer = answerModelCreator(aid)

        answer.isRightAnswer = isRightAnswer;
        // получаем текущий вопрос
        const question = yield* select(getQuestionById(qid))
        // обновляем список вопросов у ответа
        const updatedQuestion = { ...question, answers: [...question.answers, answer.id] }

        yield* put(addAnswers({ [answer.id]: answer }))

        yield* put(setAnswerError(uid, qid, aid, answerValidator('draftState', answer.draftState)))

        return yield* put(updateQuestion(updatedQuestion, uid))
    } catch (error) {

    }
}

/**
 * меняеет тип ответа на вопрос
 * @param action 
 */
const handleChangeQuestionType = function* handleChangeQuestionType(action: defaultActionType) {
    try {
        const { uid, qid, qtype } = action.payload
        // получаем текущий вопрос
        const question = yield* select(getQuestionById(qid))
        //текущие варианты ответов
        const currentAnswers = question.answers;
        //обновляем вопрос
        const updatedQuestion = { ...question, qtype, answers: [], isNeedModeration: qtype === 2 }
        // если выбраный тип это тест, то создаём сразу один пустой вариант ответа
        if (qtype === 0 || qtype === 1) {
            const aid = uuid()
            yield* call(handleCreateAnswer, { type: '', payload: { qid, uid, aid, isRightAnswer: true } })
            updatedQuestion.answers = [aid]
        }
        // удаляем ошибки в текущих вариантах ответов, что бы застряли
        for (const aid of currentAnswers) {
            yield* put(setAnswerError(uid, qid, aid, null))
        }
        //получаем массив вопросов для текущего юнита
        const questionsForUnit = yield* select(getQuestionByUnitId(uid))
        //обновляем текущий вопрос в массиве все вопросов юнита
        const toChek = questionsForUnit.map((q: any) => q.id === qid ? updatedQuestion : q)
        // проверяем вопросы
        const errors = yield* call(handleCheckQuestionsErrors, toChek)
        // отправляем событие об ошибкав в юните
        yield* put(setUnitError(uid, { questionsAnswers: errors }))
        return yield* put(updateQuestion(updatedQuestion, uid))
    } catch (error) {

    }
}

/**
 * преобразует старый юнит  типа page в новый типа wiki путём создания нового юнита с типом wiki
 * и контентом созданым на основе старого юнита
 * @param action 
 */
const handleConvertLegacyUnit = function* handleConvertLegacyUnit(action: convertLegacyPageActionType) {
    try {
        const { uid, cid, draftState } = action.payload
        const legacyUnit = yield* select(getUnitById(uid))

        const legacyUnitIndex = yield* select(getUnitIndexById(uid, cid))

        let newShorUnit = shortUnitModelCreator(uuid())
        newShorUnit.name = legacyUnit.name
        newShorUnit.unitType = 'wiki'
        newShorUnit.courseId = cid

        yield* call(handleCreateUnit, createUnit(newShorUnit))

        yield* call(handleLoadUnit, loadUnit(newShorUnit.id))

        const unit = yield* select(getUnitById(newShorUnit.id))

        const unitIndex = yield* select(getUnitIndexById(unit.id, cid))

        yield* call(handleChangeUnitPosition, changeUnitPosition({ cid, newIndex: legacyUnitIndex, oldIndex: unitIndex, noTrot: true }))

        yield* put(updateUnit({ ...unit, draft: draftState }))

        yield* call(handleSaveUnit, saveUnit(unit.id))

        yield* call(handleDeleteUnit, deleteUnit(legacyUnit))

        return yield* put(routerActions.push(`/TeacherOffice/Course/${cid}?tab=edit&unitId=${unit.id}`))
    } catch (error) {
        console.warn(error)
    }
}

/**
 * загружает с сервера полные данные указаного юнита
 * @param action 
 */
const handleLoadUnit = function* handleLoadUnit(action: loadUnitActionType) {
    try {
        const {
            uid, //ид юнита.
            version
        } = action.payload
        yield* put({ type: actions.REQUEST_BEGIN, payload: 'handleLoadUnit' })

        const request = yield* call(API.lms.getAdminUnitContent, uid, version)
        // @ts-ignore
        const result = yield request.r
        if (result.error_code !== 0) {
            return yield* put({
                type: actions.REQUEST_ERROR, payload: { type: 'handleLoadUnit', msg: 'Сервер недоступен.' }
            })
        }

        if (result.data.unitType === 'test' || result.data.unitType === 'task') {//требуется проверить!
            const { answers, questions, unit } = buildFlatTestUnit(result.data)
            yield all([
                put(addAnswers(answers)),
                put(addQuestions(questions)),
                put(addUnit(unit)),
            ])
            //получение списка версий
            yield* put(loadUnitVersions(uid))
        } else if (result.data.unitType === 'wiki') {
            let unit = { ...result.data }
            let state = EditorState.createEmpty()
            if (unit.wiki.data.blocks) {
                state = EditorState.createWithContent(convertFromRaw(unit.wiki.data))
            }
            unit = { ...unit, draft: state }
            yield* put(addUnit(unit))
        }
        else {
            yield* put(addUnit(result.data))
        }
        // сброс ошибок юнита
        yield* put(setUnitError(uid, null))

        return yield* put({ type: actions.REQUEST_COMPLETE, payload: 'handleLoadUnit' })

    } catch (error) {
        console.warn(error)
        return yield* put({ type: actions.REQUEST_ERROR, payload: { type: 'handleLoadUnit', msg: 'Сервер недоступен.' } })
    }
}

/**
 * удаляет вопрос
 * @param action 
 */
const handleDeleteQuestion = function* handleDeleteQuestion(action: deleteQuestionActionType) {
    try {
        const { qid, uid } = action.payload
        //получаем юнит
        const unit = yield* select(getUnitById(uid))

        const questionsAnswers: any[] = yield* select(getAnswersByQuestionId(qid))
        //вносим изменения в юнит, удаляем вопрос
        const updatedUnit = { ...unit, questions: unit.questions.filter((id: string) => id !== qid) }
        // отправляем изменения в redux
        yield* put(updateUnit(updatedUnit))
        //удаляем варианты
        for (const answer of questionsAnswers) {
            yield* put(setAnswerError(uid, qid, answer.id, null))
        }
        //получаем вопросы для юнита, после применения изменений
        const questionsForUnit = yield* select(getQuestionByUnitId(unit.id))
        // проверяем вопросы
        const errors = yield* call(handleCheckQuestionsErrors, questionsForUnit)
        // отправляем событие об ошибкав в юните
        yield* put(setUnitError(uid, { questionsAnswers: errors }))
    } catch (error) {
        console.warn(error)
    }
}

/**
 * удаляет вариант ответа
 * @param action 
 */
const handleDeleteAnswer = function* handleDeleteAnswer(action: deleteAnswerActionType) {
    try {
        const { qid, uid, aid } = action.payload
        // получает объект вопроса
        const question = yield* select(getQuestionById(qid))
        // обновляем вопрос
        const updatedQuestion = { ...question, answers: question.answers.filter((id: string) => id !== aid) }
        // вносим изменения в стор
        yield* put(updateQuestion(updatedQuestion, uid))
        //получаем вопросы для юнита, после применения изменений
        const questionsForUnit = yield* select(getQuestionByUnitId(uid))
        // проверяем вопросы
        const errors = yield* call(handleCheckQuestionsErrors, questionsForUnit)
        // отправляем событие об ошибкав в юните
        yield* put(setUnitError(uid, { questionsAnswers: errors }))

        yield* put(setAnswerError(uid, qid, aid, null))
    } catch (error) {
        console.warn(error)
    }
}

/**
 * получение списка пользователей которым доступен курс
 * @param action 
 */
const handleLoadStudentsInCourse = function* handleLoadStudensInCourse(action: loadUsersInCourseActionType): any {
    try {
        const { cid, clean, params } = action.payload

        const students = yield* select(getStudents)
        const { ids = [], count } = students
        const skipCount = ids.length

        const opts = {
            ...params,
            'filter.count': count,
            'filter.skipCount': skipCount,
            // 'filter.getDeleted': false
        }
        if (clean) {
            opts['filter.skipCount'] = 0
        }
        yield* put(setUsersInCourse(cid, [], {}, clean))
        yield* put(setStudentsLoad())

        const request = yield* call(API.lms.getUsersInCourse, cid, opts)
        const result = yield request.r

        if (result.error_code === 0) {
            const map = objectArrayToMap(result.data, 'userId')
            yield* put(setUsersInCourse(cid, Object.keys(map), map, clean))
        }
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * загружает список версий для юнита
 * @param action 
 */
const handleLoadUnitVersions = function* handleLoadUnitVersions(action: loadUnitVersionsActionType) {
    try {
        const { uid } = action.payload

        const request = yield* call(API.lms.getUnitVersions, uid)
        // @ts-ignore
        const result = yield request.r

        if (result.error_code === 0) {
            yield* put(setUnitVersions(uid, result.data))
        }

        return 0
    } catch (error) {
        console.warn(error)
    }
}

/**
 * 
 * @param action 
 */
const handleAllowPlayCourse = function* handleAllowPlayCourse(action: allowPlayCourseActionType) {
    try {
        const { cid } = action.payload

        yield* put({ type: actions.SET_ALLOW_PLAY, payload: { cid, isLoading: true, isAllow: false, isTested: false } })

        const request = yield* call(API.lms.getCourseById, cid)
        // @ts-ignore
        const result = yield request.r

        yield* put({ type: actions.SET_ALLOW_PLAY, payload: { cid, isLoading: false } })

        if (result.error_code === 0) {
            yield* call(handleSetFullPlayedCourse, setFullPlayedCourse(cid, result.data))
            yield* put({ type: actions.SET_ALLOW_PLAY, payload: { cid, isAllow: true, isTested: true } })
            yield* put(routerActions.push('/LMS/' + cid))
        } else {
            yield* put({ type: actions.SET_ALLOW_PLAY, payload: { cid, isAllow: false, isTested: true } })
        }

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

const handleLoadStudentsStatus = function* handleLoadStudentsStatus(action: loadStudentsStatusActionType) {
    try {
        const { cid, sid } = action.payload

        const request = yield* call(API.lms.getCourseStatStudentsStatus, cid, sid)
        // @ts-ignore
        const result = yield request.r

        if (result.error_code === 0) {
            yield* put(setStudentsStatus(result.data))
        }

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

const handleLoadTestResults = withProgressIndicator(function* (action: loadTestResultsActionType): any {
    try {
        const { sid } = action.payload

        const request = yield* call(API.lms.getTestResults, sid)
        const result = yield request.r

        if (result.error_code === 0) {
            yield* put(setTestResults(result.data))
        }

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
})

const handleLoadUnitsAttandance = function* handleLoadUnitsAttandance(action: loadUnitsAttandanceActionType) {
    try {
        const { cid, sid } = action.payload

        const request = yield* call(API.lms.getCourseStatUnitsAttendance, cid, sid)
        // @ts-ignore
        const result = yield request.r

        if (result.error_code === 0) {
            yield* put(setUnitsAttandance(result.data))
        }
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

const handleLoadStatCommentsCount = function* handleLoadStatCommentsCount(action: loadStatCommentsCountActionType) {
    try {
        const { cid, sid } = action.payload

        const request: any = yield* call(API.lms.getCourseStatCommentsCount, cid, sid)
        // @ts-ignore
        const result: any = yield request.r

        if (result.error_code === 0) {
            yield* put(setStatCommentsCount(result.data))
        }
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * получение ленты комментариев к юнитам
 * @param action 
 */
const handleLoadUnitsComments = function* handleLoadUnitsComments(action: loadUnitsCommentsActionType) {
    try {
        const { type, params = {} } = action.payload

        const count = 10



        // опции ленты новостей
        let untitsReplys: any = yield* select(getUnitsRelpys)
        const requestOptions = untitsReplys.requestOptions
        if (!isEqual(requestOptions, params)) {
            yield* put(changeUnitsReply({ key: 'keys', value: [] }))
            yield* put(changeUnitsReply({ key: 'isFinished', value: false }))
        }
        yield* put(changeUnitsReply({
            key: 'requestOptions',
            value: {
                ...params
            }
        }))
        // const news = yield* select(state => state.news.news)
        untitsReplys = yield* select(getUnitsRelpys)
        const commentsCount = untitsReplys.keys.length

        const api: any = type === 'admin' ? API.lms.getUnitComments : API.lms.getUnitCommentsUser;

        yield* put(changeUnitsReply({
            key: 'isLoading',
            value: true
        }))

        // получаем ленту комментарием к юнитам
        const request: any = yield* call(api, {
            "filter.CourseId": params['filter.CourseId'],
            "filter.Count": count,
            'filter.AuthorId': requestOptions['filter.AuthorId'] || undefined,
            "filter.SkipCount": commentsCount,
            'filter.Search': requestOptions['filter.Search'] || undefined,
            'filter.UnitId': requestOptions['filter.UnitId'] || undefined,
            ...params
        })
        // @ts-ignore
        const result: any = yield request.r

        if (result.error_code === 0) {

            // делает из приходящей субстанции новости-рыбы, для работы как со стандартными новостями
            const values = objectArrayToMap(result.data, 'comment.id')
            const keys = Object.keys(values)

            yield* put(addComments({
                commentsValues: objectArrayToMap(result.data, 'comment.id', (value) => value.comment),
                commentsKeys: keys,
                id: params['filter.CourseId'] || 'allunits'
            }))
            yield* put(setAllPeply({
                keys,
                values,
                isFinished: keys.length < count
            }))
        }

    } catch (error) {
        console.warn(error)
    }

    yield* put(changeUnitsReply({
        key: 'isLoading',
        value: false
    }))

    return 0;
}


const handleGetUserscoursesinfohead = withSagaIndicator(function* ({ payload }: getUserscoursesinfoheadActionType) {
    const request: any = yield* call(API.lms.getUserscoursesinfohead, payload)
    const result: any = yield request.r
    if (result.error_code === 0) {
        yield* put(pathUserscoursesinfohead(result.data))
    } else throw new Error('requestError');
}, actions.GET_USERS_COURSES_INFO_HEAD)


const handleGetUserscoursesinfo = withSagaIndicator(function* ({ payload, clean }: getUserscoursesinfoActionType) {
    const request: any = yield* call(API.lms.getUserscoursesinfo, { ...payload, skipCount: clean ? 0 : payload.skipCount })
    const result: any = yield request.r
    if (result.error_code === 0) {
        type TValues = { [key: string]: UserProgress }
        const values = (result.data as UserProgress[]).reduce<TValues>((acc, val) => ({ ...acc, [val.user.id]: val }), {})
        yield* put(addUserscoursesinfolist({ values, keys: Object.keys(values), clean }))
    } else throw new Error('requestError');
}, actions.GET_USERS_COURSES_INFO)

const handleGetUserStatInfo = withSagaIndicator(function* ({ payload }: getUserStatInfoInfoActionType) {
    const request: any = yield* call(API.lms.getStatisticUserHead, payload.userId, payload.params)
    const result: any = yield request.r
    if (result.error_code === 0) {
        yield* put(setUserStatInfoInfo(result.data))
    } else throw new Error('requestError');
}, actions.GET_USER_STAT_INFO)

const handleGetUserStatList = withSagaIndicator(function* ({ payload, clean }: getUserStatInfoListActionType) {
    const { params, userId, count, skipCount } = payload
    const current: any = yield* select(getUserStatCoursesListParams<any, any>('userId'))
    if (userId !== current.userId) {
        yield* put(addUserStatCoursesList({ values: {}, keys: [], clean, userId }))
    }
    const request: any = yield* call(API.lms.getStatisticUserList, userId, skipCount, count, params)
    const result: any = yield request.r
    if (result.error_code === 0) {
        type TValues = { [key: string]: any }
        const values = (result.data as any[]).reduce<TValues>((acc, val) => ({ ...acc, [val.courseId + '_' + val.sessionId]: val }), {})
        yield* put(addUserStatCoursesList({ values, keys: Object.keys(values), clean, userId }))
    } else throw new Error('requestError');
}, actions.GET_USER_STAT_LIST)



const handleLoadBadges = withSagaIndicator(function* ({ }: loadBadgesActionType): any {

    const authUser = yield* select(getAuthUser)
    const isAdmin = authUser.baseData.isAdmin
    const badgeRequest = yield* call(API.badges.getBadgeList, (isAdmin ? undefined : 'mymanual'))
    const result = yield badgeRequest.r

    if (result.error_code === 0) {
        yield* put(setBadgesList(result.data))
    } else throw new Error('requestError');
}, actions.LOAD_BADGES)


/**
 * 
 * @param action 
 */
export const handleGetCoursesStatsList = withProgressIndicator(function* ({ payload, clean }: any): any {
    const { } = payload
    const request = yield* call(API.lms.getManagedByMe, { ...payload, skipCount: clean ? 0 : payload.skipCount, GetStatistics: true })
    const result = yield request.r

    if (result.error_code === 0) {
        const values = objectArrayToMap(result.data, 'id')
        yield* put(addCoursesStatsList({ values, keys: Object.keys(values), clean }))
    } else {
        throw new Error('handleGetCoursesStatsList_error')
    }
    yield;
    return 0;
})


/**
 * module root saga
 */
const root = function* root() {
    yield takeEvery(actions.CHANGE_RADIO_ANSWERS, handleChangeAnswerRadio);
    yield takeEvery(actions.CHANGE_UNIT_POSITION, handleChangeUnitPosition);
    yield takeLeading(actions.CREATE_COURSE, handleCreateCourse)
    yield takeLatest(actions.LOAD_CATEGORIES, handleLoadCategories)
    yield takeLeading(actions.SAVE_LESSON, handleSaveLesson)
    yield throttle(10000, actions.THROTTLED_SEND_COURSE_TO_SERVER, handleSaveCourse)
    yield takeLatest(actions.CREATE_UNIT, handleCreateUnit)
    yield takeLeading(actions.DELETE_UNIT, handleDeleteUnit)
    yield takeLeading(actions.SAVE_UNIT, handleSaveUnit)
    yield takeLatest(actions.LOAD_STUDENTS_SESSIONS, handleLoadStudentSessions)
    yield takeEvery(actions.CREATE_QUESTION, handleCreateQuestion);
    yield takeEvery(actions.CREATE_ANSWER, handleCreateAnswer);
    yield takeEvery(actions.CHANGE_QUESTIONS_TYPE, handleChangeQuestionType);
    yield takeEvery(actions.CHANGE_CHECK_ANSWERS, handleChangeAnswerCheck);
    yield takeLeading(actions.CONVERT_LEGACY_PAGE, handleConvertLegacyUnit)
    yield takeLeading(actions.DELETE_QUESTION, handleDeleteQuestion)
    yield takeLeading(actions.DELETE_ANSWER, handleDeleteAnswer)
    yield takeLeading(actions.RESET_UNIT_CHANGE, handleLoadUnit)
    yield takeLeading(actions.LOAD_UNIT_CONTENT, handleLoadUnit)
    yield takeLeading(actions.LOAD_UNIT_VERSIONS, handleLoadUnitVersions)
    yield takeLatest(actions.LOAD_USERS_IN_COURSE, handleLoadStudentsInCourse)
    yield takeLeading(actions.ALLOW_PLAY_COURSE, handleAllowPlayCourse)
    yield takeLeading(actions.LOAD_STUDENTS_STATUS, handleLoadStudentsStatus)
    yield takeLeading(actions.LOAD_TEST_RESULTS, handleLoadTestResults)
    yield takeLeading(actions.LOAD_UNITS_ATTENDANCE, handleLoadUnitsAttandance)
    yield takeLeading(actions.LOAD_STAT_COMMENTS_COUNT, handleLoadStatCommentsCount)
    yield takeLeading(actions.LOAD_UNIT_COMMENTS, handleLoadUnitsComments)
    yield takeLeading(actions.GET_USERS_COURSES_INFO_HEAD, handleGetUserscoursesinfohead)
    yield takeLeading(actions.GET_USERS_COURSES_INFO, handleGetUserscoursesinfo)
    yield takeLeading(actions.GET_USER_STAT_INFO, handleGetUserStatInfo)
    yield takeLeading(actions.GET_USER_STAT_LIST, handleGetUserStatList)
    yield takeLeading(actions.LOAD_BADGES, handleLoadBadges)
    yield takeLeading(actions.GET_COURSES_STATS_LIST, handleGetCoursesStatsList)

};

/**
 * export root saga
 */
export default root;