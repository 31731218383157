import { Box, styled } from "muicomponents/src";
import { Button } from 'muicomponents/src/Button/Button'




export const WrapBox = styled(Box)({
    display:'flex',
    justifyContent: 'space-between',
}) as typeof Box

export const OrderBtn = styled(Button)({
    textAlign: 'center',
    '&:nth-child(2)': {
        marginLeft: '8px',
    }
}) as typeof Button