import React, { useCallback } from 'react';
import { Button } from 'muicomponents/src';
import { Translate } from 'localization/src/Translate';
import { IHeaderProps, cnHeader } from '../Header.index';
import { IRenderEditSwitcherOwnProps, IRenderEditSwitcherProps, mapDispatchToProps, mapStateToProps, TEditDispathProps, TEditStateProps } from './RenderEditSwitcher.index';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { connect } from 'react-redux';
import { IStateType } from 'redux/store';
import { IconButton, IconMui } from '../Header.styled';
import { ButtonEdit } from './RenderEditSwitcher.styled';
import { Settings, Videocam as VideocamIcon, Check as CheckIcon, Close as CloseIcon } from 'muicomponents/src/Icons';


export const RenderEditSwitcherPresenter: React.FC<IRenderEditSwitcherProps> = ({ edit, isAdmin, oldMenuEditState, oldMenuEdit, oldMenuSetData, oldMenuUnsave, changeVm, isMobile}) => {

  const editToggle = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    changeVm('', { edit: !edit, editTypes: '', saving: event.currentTarget.name === 'save' });
  };

  const CompleteEdit = useCallback(() => (
    <>
      <ButtonEdit
        name="save"
        className={cnHeader("Button", { editToggle: true, rightSide: true })}
        startIcon={<CheckIcon />}
        variant="contained"
        isMobile={isMobile}
        onClick={(e) => {
          if (edit) editToggle(e);
          if (oldMenuEditState) oldMenuSetData();
        }}
      >
        <Tooltip
          className={'InfoIcon'}
          title={ <Translate i18nKey={'pryaniky.edit.save'} />}>
          <div>
            {isMobile && <Translate i18nKey='pryaniky.default.save'/>}
          </div>
        </Tooltip>
      </ButtonEdit>

      <ButtonEdit
        name="unsave"
        className={cnHeader("Button", { editToggle: true, rightSide: true })}
        startIcon={<CloseIcon />}
        variant="contained"
        isMobile={isMobile}
        onClick={(e) => {
          if (edit) editToggle(e);
          if (oldMenuEditState) oldMenuUnsave('restore');
        }}
      >
        <Tooltip
          className={'InfoIcon'}
          title={ <Translate i18nKey={'pryaniky.edit.close'} /> }>
          <div>
            {isMobile && <Translate i18nKey='pryaniky.default.cancel'/>}
          </div>
        </Tooltip>
      </ButtonEdit>
    </>
  ), [edit, editToggle]);


  return (edit || oldMenuEditState) ? <CompleteEdit /> : (
    <IconButton
      className={cnHeader("Button", { rightSide: true, editType: true })}
      onClick={(event) => {
        editToggle(event);
        oldMenuEdit(false);
      }}
    >
      <Tooltip title={ <Translate i18nKey={'pryaniky.edit.title'} /> }>
        <IconMui as={Settings} />
      </Tooltip>
    </IconButton>
  )
}

export const RenderEditSwitcher = connect<TEditStateProps, TEditDispathProps, IRenderEditSwitcherOwnProps, IStateType>(
  mapStateToProps,
  mapDispatchToProps
)(RenderEditSwitcherPresenter)