import React from 'react';
import { Skeleton } from 'uielements/src';
import { cn } from '@bem-react/classname';
import './Product-Skeleton.scss';
import { IClassNameProps } from '@bem-react/core';

const cnProductSkeleton = cn('ProductSkeleton');

export const ProductSkeleton: React.FC<IClassNameProps> = ({ className }) => (
  <div className={cnProductSkeleton({}, [className])}>
    <div className={cnProductSkeleton('Image')} />
    <div className={cnProductSkeleton('Info')}>
      <Skeleton shape="line" size="lg" />
      <Skeleton shape="line" size="xs" style={{ width: '80%' }} />
      <Skeleton shape="line" size="xs" style={{ width: '60%' }} />
      <Skeleton shape="line" size="xs" style={{ width: '40%' }} />
    </div>
    <div className={cnProductSkeleton('Footer')} />
  </div>
);
