import { IClassNameProps } from '@bem-react/core';
import React from 'react';
import { IMenuAPI } from 'utils/src/requests/models/api.menu';
import { cnLeftMenuItem } from './../LeftMenuItem.index';
import './label.scss';

interface ILeftMenuItemLabelProps extends React.PropsWithChildren<IClassNameProps> {
  tag?: React.ComponentType<any> | string;
  // tagProps?: Record<string, any>;
  renderType: IMenuAPI.ItemRenderTypes;
  blockType: IMenuAPI.ItemBlockTypes;
  notViewedCount: IMenuAPI.Item['notViewedCount'];
}

export const LeftMenuItemLabel: React.FC<ILeftMenuItemLabelProps> = ({
  tag: Tag = 'div',
  className,
  renderType: rt,
  blockType: bt,
  notViewedCount,
  children,
  ...props
}) => {

  const showNotification = notViewedCount > 0;

  return (
    <Tag className={cnLeftMenuItem('Label', { rt, bt }, [className])}  {...(props || {})}>
      {children}
      <div className={cnLeftMenuItem('System')}>
        {
          showNotification &&
          <div className={cnLeftMenuItem('NotificationDot')} />
        }
      </div>
    </Tag>
  )
}