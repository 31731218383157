import { ChatList } from 'blocks/Chat/ChatList/ChatList';
import { MENU_HIDE_BREAKPOINT } from 'blocks/LeftMenuMui/LeftMenu.index';
import { Notifications } from 'blocks/Notifications/Notifications';
import { Translate } from 'localizations/Translate';
import { Box } from 'muicomponents/src';
import { Button } from 'muicomponents/src/Button/Button';
import { useNodeMediaQuery } from 'muicomponents/src/ContainerResizeWatcher/ContainerResizeWatcher';
import {
    ChatBubbleOutline,
    Home as HomeIcon,
    Menu as MenuIcon,
    NotificationsNone,
    Search as SearchIcon,
} from 'muicomponents/src/Icons';
import { Link } from 'muicomponents/src/Link/Link';
import { SuggesterQuick } from 'muicomponents/src/SuggesterQuick';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useEnvironmentContex, withEnvironmentContext } from 'utils/src/EnvironmentContex';
import { cnHeader, IHeaderProps, mapDispatchToProps, mapStateToProps } from './Header.index';
import {
    HeaderButtonsBox,
    HeaderItemBox,
    HeaderItemFlexBox,
    HeaderWrapper,
    IconButton,
    LogoImg,
} from './Header.styled';
import { Menu } from './Menu/Menu';
import { RenderEditSwitcher } from './RenderEditSwitcher/RenderEditSwitcher';
import { UserMenu } from './UserMenu/UserMenu';

export const HeaderPresenter: React.FC<IHeaderProps> = (props) => {
    const {
        isAdmin,
        chatEnable,
        isEditable,
        homePage,
        titleLogo,
        showLogoInHeader = false,
        menuHide,
        leftMenuSetReducerField,
        className,
        menuHidden,
        audioPlayList,
        children,
        lifeCycleStructureFromServer,
        activePage,
        isMobileOS,
        authUser,
        notificationCount,
        nameNetwork,
        edit,
        messagesCount,
        getNotificationCount,
        resetNotificationCount,
        filters,
        history,
    } = props;
    const [previewLogo, setPreviewLogo] = useState<string>('');
    const [showSuggester, setShowSuggester] = useState<boolean>(true);
    // console.log("showLogoInHeader", showLogoInHeader, "previewLogo", previewLogo, "titleLogo", titleLogo)

    const [ref, point700] = useNodeMediaQuery({ minWidth: 665 });
    let [refForMenu, menuPoint] = useNodeMediaQuery({ minWidth: MENU_HIDE_BREAKPOINT });

    const environment = useEnvironmentContex();

    if (environment.isTablet || environment.isMobileOS) {
        menuPoint = false;
    }

    useEffect(() => {
        // console.log("window.location.href.indexOf('stylePreview=true') !== -1", window.location.href.indexOf('stylePreview=true') !== -1)
        if (window.location.href.indexOf('stylePreview=true') !== -1) {
            // console.log("window.location.href.indexOf('stylePreview=true') !== -1", window.location.href.indexOf('stylePreview=true') !== -1)
            const dt = localStorage.getItem('stylePreview');
            if (dt) {
                const { mobileImageUrl } = JSON.parse(dt) as any;
                setPreviewLogo(mobileImageUrl);
            }
        }
    }, []);

    useEffect(() => {
        if (point700) {
            setShowSuggester(true);
        } else {
            setShowSuggester(false);
        }
    }, [point700]);

    // console.log("showLogoInHeader", showLogoInHeader, "previewLogo", previewLogo, "titleLogo", titleLogo)
    const GoHomeComponent = useMemo(() => {
        // show home icon if viewport size is small or not showLogoInHeader or not define previewLogo and titleLogo
        if (!point700 || !showLogoInHeader || (!previewLogo && !titleLogo)) {
            return (
                <IconButton onClick={() => history.push(homePage || '/dash')}>
                    <HomeIcon className={cnHeader('IconHome CustomStyle primaryColor3-text')} />
                </IconButton>
            );
        }

        // экран большой есть чекбокc showLogoInHeader true, есть картинка, - картинка
        if (showLogoInHeader && (previewLogo || titleLogo)) {
            return (
                <Button
                    href={homePage || '/dash'}
                    children={
                        <LogoImg
                            className={cnHeader('TitleLogo')}
                            src={previewLogo || titleLogo?.replace(/\/\//g, '/').replace(':/', '://')}
                        />
                    }
                />
            );
        }

        return null;
    }, [previewLogo, point700, showLogoInHeader, titleLogo]);

    return (
        <HeaderWrapper
            className={cnHeader('')}
            ref={(el) => {
                (ref as any).current = el;
                (refForMenu as any).current = el;
            }}
            sx={{ columnGap: point700 ? '20px' : '15px', padding: point700 ? '0 24px' : '0 8px' }}
        >
            <HeaderItemFlexBox>
                {!menuPoint && (
                    <IconButton
                        id={'DASHUPMENUSCROLLER'}
                        onClick={() => {
                            leftMenuSetReducerField({ field: 'hide', value: !menuHide });
                            leftMenuSetReducerField({ field: 'opened', value: menuHide });
                            if (menuHide) {
                                leftMenuSetReducerField({ field: 'bars', value: ['main'] });
                            }
                        }}
                    >
                        <MenuIcon className={cnHeader('MenuIcon CustomStyle primaryColor3-text')} />
                    </IconButton>
                )}

                {GoHomeComponent}
                {!point700 && (
                    <IconButton onClick={() => setShowSuggester((prev) => !prev)}>
                        <SearchIcon className={cnHeader('IconHome CustomStyle primaryColor3-text')} />
                    </IconButton>
                )}
            </HeaderItemFlexBox>

            {showSuggester && (
                <HeaderItemFlexBox
                    sx={{
                        flexGrow: point700 ? 0 : 1,
                        width: point700 ? '614px!important' : '270px!important',
                        '.MuiAutocomplete-popperDisablePortal': {
                            width: point700 ? '614px!important' : '270px!important',
                        },
                    }}
                >
                    {!edit && (
                        <SuggesterQuick
                            filters={filters}
                            sx={{ width: point700 ? '100%!important' : '270px !important' }}
                            listBoxWidth={point700 ? 700 : 350}
                        />
                    )}
                </HeaderItemFlexBox>
            )}

            {
                <HeaderItemBox>
                    <HeaderButtonsBox sx={{ columnGap: point700 ? '20px' : '15px' }}>
                        {((lifeCycleStructureFromServer && isEditable) || isAdmin) &&
                            activePage !== 'statistics' &&
                            !isMobileOS && <RenderEditSwitcher isMobile={point700} />}

                        {!edit && (
                            <>
                                {chatEnable && (
                                    <Menu
                                        notificationCount={messagesCount}
                                        tooltipTitle={<Translate i18nKey="pryaniky.default.menu.messages" />}
                                        menuIcon={ChatBubbleOutline}
                                        children={
                                            <div>
                                                <ChatList />
                                                <Box className="ChatList_Buttons">
                                                    <Link className="Button-LikeRead" href="/messages">
                                                        <Translate i18nKey="pryaniky.header.open.chat" />
                                                    </Link>
                                                </Box>
                                            </div>
                                        }
                                    />
                                )}
                                <Menu
                                    notificationCount={notificationCount}
                                    tooltipTitle={<Translate i18nKey="notifications" />}
                                    menuIcon={NotificationsNone}
                                    getNotificationCount={getNotificationCount}
                                    resetNotificationCount={resetNotificationCount}
                                    children={<Notifications />}
                                />

                                <UserMenu user={authUser} nameNetwork={nameNetwork} />
                            </>
                        )}
                    </HeaderButtonsBox>

                    {children}
                </HeaderItemBox>
            }
        </HeaderWrapper>
    );
};

export const Header = connect(mapStateToProps, mapDispatchToProps)(withEnvironmentContext(withRouter(HeaderPresenter)));
