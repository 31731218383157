import { cn } from '@bem-react/classname';
import { IBaseNews } from 'News/types/baseNews';

export interface ICardProps extends ICardOwnProps, IBaseNews {

  [key: string]: any;
}

interface ICardOwnProps {

}


export const className = cn('KanbanCard');