import React, { FC, RefForwardingComponent, useEffect, useMemo, useRef } from 'react';
import { NLeftMenuBar, cnLeftMenuBar } from './LeftMenuBar.index';
import ReactSortable from 'react-sortablejs';
import { serviceElementId, useLeftMenuBar } from '../LeftMenu.hooks';
import { LeftMenuItem } from '../Item/LeftMenuItem';
import { useDidUpdateEffect } from 'utils/src/hooks';
import './LeftMenuBar.scss';
import { childrenFixIconBlocks as blocksWithoutAdding, mainBarId } from '../redux/constants';

import { Box } from 'muicomponents/src/Box/Box';
import { LeftMenuSearch } from '../Search/LeftMenuSearch';
import { MenuEdit } from '../Edit';
import { menuBarTimeout, minimalMenuWidth } from '../LeftMenu.constants';
import { useEnvironmentContex } from 'utils/src/EnvironmentContex';
import { ItemBlockType } from 'utils/src/requests/models/api.menu';
import { cnLeftMenuItem } from '../Item/LeftMenuItem.index';
// import { MenuList } from 'muicomponents/src/Menu';

const LeftMenuBarPresenter: RefForwardingComponent<any, NLeftMenuBar.Props> = ({
    id,
    path,
    zIndex,
    stayOpened,
    ...props
}, ref) => {

    const environment = useEnvironmentContex();
    
    const containerRef = useRef<HTMLDivElement>(null);
    const sortableRef = useRef<any>();

    const {
        item,
        items,
        opened,
        changeItems,
        edit,
        addItem
    } = useLeftMenuBar(id);

    const barGroupName = useMemo(() => {
        switch(item?.blockType) {
            case ItemBlockType.favourites:
                return item?.blockType
            default:
                return 'menuBar'
        }
    }, [item?.blockType]);

    const itemHandler = useMemo(() => {
        if(item?.blockType === ItemBlockType.favourites && environment.isTouchScreen) {
            return `.${cnLeftMenuItem('Handler')}`;
        }
        return;
    }, [item?.blockType, environment.isTouchScreen])

    useDidUpdateEffect(() => {
        if(!sortableRef.current) return;
        // change disabled option at sortablejs to init DND
        sortableRef.current.option(
            'disabled',
            item?.blockType === ItemBlockType.favourites
                ? edit
                    ? true
                    : false
                : !edit
        );
    }, [edit, item?.blockType]);

    useEffect(() => {
        if(!sortableRef.current) return;
        // change ашдеук option at sortablejs to init DND if no items
        sortableRef.current.option('filter', !items.length ? '.nofilter' : `.LeftMenuItemMui_fixed`);
    }, [items.length]);

    const disableInsertElement = !!item?.blockType && blocksWithoutAdding.includes(item?.blockType);

    const isMainBar = useMemo(() => id === mainBarId, [id]);

    useEffect(() => {
        // scrool inti view if bar is overflow by viewport
        setTimeout(() => {
            containerRef.current?.scrollIntoView({
                behavior: 'smooth',
                inline: 'end'
            });
        }, menuBarTimeout );
    }, []);

    return (
        // TODO change to box
        <div
            ref={(el) => {
                (containerRef as any)!.current = el;
                if(typeof ref === 'function') {
                    ref(el)
                } else {
                    (ref as any)!.current = el
                }
            }}
            className={cnLeftMenuBar({ id, stayOpened, opened }, [props.className, 'primaryColor1-bg'])}
        >
            {
                isMainBar && <LeftMenuSearch />
            }
            <ReactSortable
                className={cnLeftMenuBar('Sortable')}
                bar-id={id}
                ref={(refNode: any) => {
                    // define ref to change 'disabled' state because in option on props change not work
                    sortableRef.current = refNode?.sortable;
                }}
                options={{
                    group: barGroupName,
                    animation: 200,
                    onStart: (ev: any) => {
                        //   dispatch(setDraggingElem((ev.item && ev.item.getAttribute('data-id')) || undefined));
                        //   dispatch(changeWidgetsViewType('small'));
                    },
                    onEnd: (ev: any) => {
                        //   dispatch(setDraggingElem());
                        //   dispatch(changeWidgetsViewType('full'));
                    },
                    onAdd: () => {
                        //   dispatch(setDraggingElem());
                        //   dispatch(changeWidgetsViewType('full'));
                    },
                    onMove: (ev: any) => {
                        // ignore move if dragged element with same id or related element fixed
                        const targetBarNoItems = ev.to.children.length === 1 && ev.to.lastChild?.classList.contains('LeftMenuItemMui_fixed');
                        return ev.dragged.getAttribute('data-id') !== ev.to.getAttribute('bar-id') && (targetBarNoItems || !ev.related.className.includes('LeftMenuItemMui_fixed'))
                    },
                    handle: itemHandler,
                    filter: `.LeftMenuItemMui_fixed`,
                    disabled: item?.blockType === 'favs'
                        ? edit
                            ? true
                            : false
                        : !edit
                }}
                onChange={(newItems: string[]) => {
                    changeItems(newItems.filter(el => el !== serviceElementId));
                }}
            >
                {
                    items.map(id => {
                        return (
                            <LeftMenuItem key={id} id={id} path={path} stayOpened={stayOpened} inFavouritesBar={item?.blockType === 'favs'} />
                        )
                    })
                }
                {
                    edit && !disableInsertElement &&
                    <LeftMenuItem key={`${serviceElementId}-${id}`} id={serviceElementId} path={path} onClick={addItem} />
                }
            </ReactSortable>
            {
                isMainBar && <MenuEdit />
            }
        </div>
    )
}

export const LeftMenuBar = React.forwardRef(LeftMenuBarPresenter);