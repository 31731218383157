import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import uuid from 'uuid';
import * as utils from 'utils/src/utils';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Files from './Files'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: theme.spacing(1),

        },
        button: {
            position: 'relative',
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    }),
);

export const Presenter: FC<IPresenterProps> = ({
    className,
    onChange,
    value
}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false)
    // const [success, setSuccess] = useState<boolean>(false);

    const fileLoad = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;
        for (const file of e.target.files) {
            (file as any).id = uuid.v1()
            utils.API.files.upload(file, {
                loadStart: () => {
                    setLoading(true)
                },
                loadProgress: () => { },
                loadFinish: (response, file) => {
                    setLoading(false)
                    for (const file of response.data) {
                        onChange([...value, file])
                    }
                    
                }
            });
        }
    }
    return <>
        <input
            // accept="image/*"
            style={{ display: 'none' }}
            id="contained-button-file"
            // multiple
            type="file"
            onChange={fileLoad}
        />
        <label htmlFor="contained-button-file" className={className}>
            <Button
                disableElevation
                startIcon={<CloudUploadIcon />}
                variant="contained"
                color="primary"
                component="span"
                className={classes.button}
                disabled={loading}
            >Загрузить
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
        </label>
    </>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
