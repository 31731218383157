import * as React from 'react';
// import * as utils from 'utils/src/utils';


import { withBemMod } from '@bem-react/core';

import { cnInfoBlock } from '../AbstractInfoElement.index';
import { IUserTypeStoreProps } from './InfoBlock-Element_type_store.index';
import './InfoBlock-Element_type_store.scss';

// IUserTypeStoreProps
export const withElementTypeStore = withBemMod<any>(
  cnInfoBlock(),
  { type: 'store' },
  (Presenter) => (
    (props: IUserTypeStoreProps) => {
      const setData = (data: any) => props.changeVm(`widgets.${props.id}.data`, data);
      return (
        <Presenter {...props} data={props.widgets[props.id].data} setData={setData} />
      )
    }
  )
);