import { IDialog } from "../../DialogWrapper.index";
import { cn } from '@bem-react/classname';
import { IStateType as IState } from 'redux/store';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { getActiveStructure, getActiveStructureWidgets } from 'redux/actions/Widgets';
import { IComponentAcceptor } from 'utils/src/ComponentInjector'
import { IWSettingsOnlyProps } from '../../../WSettings/WSettings.index';

export interface IWidgetsAddProps extends IWidgetsAddOwnProps, IWidgetsAddStateProps, IWidgetsAddActionsToProps { }

export interface IWidgetsAddOwnProps extends IDialog, IComponentAcceptor<IWSettingsOnlyProps> { }

export interface IWidgetToSelect {
  schema: string;
  title: string;
  type: string;
  id: string;
  settings?: {[key: string]: any};
}

export interface IWidgetsAddState {
  selected?: string;
  typeSelector?: boolean;
  isValid?: boolean;
  widgetsList: IWidgetToSelect[];
}

export type IWidgetsAddStateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: IState) => {
  return{
    tariff: state.store.appSettings && state.store.appSettings.tariff,
    enabledModules: state.store.appSettings && state.store.appSettings.enabledModules
  }
};

export const WidgetsAddMapActionstoProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  getActiveStructure,
  getActiveStructureWidgets
}, dispatch);

export type IWidgetsAddActionsToProps = ReturnType<typeof WidgetsAddMapActionstoProps>

export const cnWidgetsAdd = cn('WidgetsAdd');