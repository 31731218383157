import { ICurrencyTimeline } from "./interfaces"
import {
  RESET_DATA_CURRENCY_TIMELINE,
  SET_CURRENCY_TIMELINE,
  SET_CURRENCY_TIMELINE_LOADING_FINISHED,
  CHANGE_CURRENCY_TIMELINE_CONTEXT
} from "./actions"
import { ICurrencyTimelineActions } from './actions.interfaces'

const baseState: ICurrencyTimeline = {
  data: [],
  loading: false,
  finished: false,
  context: {
    earned: true,
    given: true,
    spent: true
  }
}

export const currencyTimelineReducer = (state = baseState, action: ICurrencyTimelineActions): ICurrencyTimeline => {
  switch (action.type) {

    case RESET_DATA_CURRENCY_TIMELINE: {
      return {
        ...state,
        finished: false,
        loading: false,
        data: []
      }
    }

    case SET_CURRENCY_TIMELINE: {
      return {
        ...state,
        data: [
          ...state.data,
          ...action.payload
        ]
      }
    }

    case SET_CURRENCY_TIMELINE_LOADING_FINISHED: {
      return {
        ...state,
        ...action.payload
      }
    }

    case CHANGE_CURRENCY_TIMELINE_CONTEXT: {
      return {
        ...state,
        context: {
          ...state.context,
          ...action.payload
        },
        data: [],
        loading: false,
        finished: false
      }
    }

    default:
      return state
  }
}
