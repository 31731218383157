import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IBodyProps } from 'utils/src/DialogCreator';

export interface IRejectAchivmentProps extends IClassNameProps, IBodyProps<{ comment: string, needSendNotification:boolean }> {
  tag?: 'div';
}

export interface IRejectAchivmentState { }

export interface IRejectAchivmentStateProps { }


export const cnRejectAchivmentDialog = cn('RejectAchivmentDialog');