import {
    IJournalDataActions,
    IGetJournalDataFromServerPayload
} from "./actions.interfaces";

import { IJournalData } from 'utils/src/requests/models/admin.journal'

//chat settings
export const JOURNAL_GET_JOURNAL_DATA = 'JOURNAL_GET_JOURNAL_DATA'
export const JOURNAL_SET_JOURNAL_DATA = 'JOURNAL_SET_JOURNAL_DATA'
export const JOURNAL_TOGGLE = 'JOURNAL_TOGGLE'


// getJournalDataFromServer
/*****************************************/
/*************** to reducer **************/
/*****************************************/


/**
* set JournalData to redux
* @param { IChatData } payload 
*/
export function setJournalDataToRedux(payload: IJournalData[]): IJournalDataActions {
    return {
        type: JOURNAL_SET_JOURNAL_DATA,
        payload
    }
}
/**
* get JournalData from server
*/
export function getJournalDataFromServer(payload: IGetJournalDataFromServerPayload): IJournalDataActions {
    return {
        type: JOURNAL_GET_JOURNAL_DATA,
        payload
    }
}


/**
 * set toggling variables
 * @param { 'isLoading' | 'isFinished' } variable
 * @param { boolean, undefined } value
 */
export function journalToggle(variable: 'isLoading' | 'isFinished', value?: boolean) {
    return {
        type: JOURNAL_TOGGLE,
        payload: {
           variable,
           value
        }
    }
}

