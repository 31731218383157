import React, { FC } from 'react'
import { IBodyProps, cnBody } from './Body.index'
import './Body.scss'
import { QuestionList } from '..//QuestionList/QuestionList'
import { SummaryTable } from '../SummaryTable/SummaryTable'
import { Chart } from '../Chart/Chart'
import TestInfoList from '../TestInfoList'
import { Select } from 'uielements/src'
import { tC } from 'LMSModule/utils/i18n.adaptor'
import { isAttmptsAvailable, isAllowShow } from 'LMSModule/utils/result.utils'
import TestSummary from '../TestSummary'

type TPassingScoreSuccess = 'wait' | 'success' | 'fail'



export const Body: FC<IBodyProps & any> = ({
    className,
    normalQuestions,
    questions,
    unitTimer,
    rightAnswersCount,
    attemptNumber,
    questionsCount,
    resultAnswersMap,
    resultAnswers,
    unitContent,
    waitModerationCount = 0,
    moderatedAnswersCount = 0,
    notModerateAnswersCount = 0,
    attemptsList,
    selectedAttempt,
    cangeAttempt,
    detailsRender,
    attemptMinScore,
    attemptDate,
    highlightCorrectness = true,
    isAbsolutelyCompleted
}) => {
    const waitModerationPercent = Math.round((waitModerationCount / attemptNumber) / questionsCount * 100)
    const rightPercent = Math.round((rightAnswersCount/* - notModerateAnswersCount*/) / questionsCount * 100)
    const wrongPercent = 100 - (rightPercent + waitModerationPercent)
    // const summatyQuestions = attemptNumber * questionsCount

    let passingScoreSuccess: TPassingScoreSuccess = ((100 - unitContent.passingMinScore) >= wrongPercent) ? 'success' : 'fail'
    passingScoreSuccess = waitModerationCount === 0 ? passingScoreSuccess : 'wait'
    const attemptsSuccess = passingScoreSuccess ? true : (attemptNumber < unitContent.maxAttemptsCount)

    const attmptsAvailable = isAttmptsAvailable(attemptNumber, unitContent.maxAttemptsCount)

    const allowShow = isAllowShow(questions, normalQuestions, highlightCorrectness, attmptsAvailable, isAbsolutelyCompleted)

    return <div className={cnBody({}, [className])}>
        {questionsCount !== 0 ? <div className={cnBody('Summary')}>
            <div className={cnBody('SummaryColumn')}>

                <TestInfoList
                    unitSkipType={unitContent.skipUnitType}
                    passingScoreSuccess={passingScoreSuccess}
                    attemptsSuccess={attemptsSuccess}
                    scoreCountMode={unitContent.scoreCountMode}
                    passingMinScore={attemptMinScore || unitContent.passingMinScore}
                    attemptsCount={attemptNumber}
                    maxAttemptsCount={unitContent.maxAttemptsCount}
                    // questionsCount={unitContent.questions.length}
                    questionsCount={questionsCount}
                    attemptDate={attemptDate}
                />

            </div>

            <TestSummary
                className={cnBody('SummaryColumn')}
                rightPercent={rightPercent}
                wrongPercent={wrongPercent}
                moderatedAnswersCount={moderatedAnswersCount}
                waitModerationCount={waitModerationCount}
            />


        </div> : <h3 className={cnBody('Saved')}>{tC('answersSaved.1')}</h3>}
        {allowShow &&
            <>
                <Select className={cnBody('Select')} options={attemptsList} selected={selectedAttempt} onChange={cangeAttempt} />

                <QuestionList
                    allowShow={allowShow}
                    attmptsAvailable={attmptsAvailable}
                    detailsRender={detailsRender}
                    highlightCorrectness={highlightCorrectness}
                    className={cnBody('QuestionList')}
                    normalQuestions={normalQuestions}
                    questionsTimers={unitTimer && unitTimer.questions}
                    resultAnswersMap={resultAnswersMap}
                    resultAnswers={resultAnswers}
                    questions={questions} />
            </>
        }
    </div>
}