import React, { FC, useState } from 'react';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import './Item.scss';
import { IItemProps, cnAuthorItem } from './Item.index'

export const AuthorItem: FC<IItemProps> = ({ className, author, size = 56, fixedToolTip }) => {
    const [zIndex, setZIndex] = useState(0)
    const mouseEnther = () => setZIndex(1)
    const mouseLeave = () => setZIndex(0)
    return (
        <div className={cnAuthorItem({}, [className])} style={{zIndex}} onMouseEnter={mouseEnther} onMouseLeave={mouseLeave}>
            <UserMention id={author.id} name={author.displayName} 
                fixed={fixedToolTip}
            avatar={{ size, imgUrl: author.userPhotoUrl || author.imgUrl }} 
            className={cnAuthorItem('Avatar')} />
        </div>
    );
};
