import React, { FC } from 'react'
import { ITestResultProps, mapDispatchToProps, mapStateToProps, cnTestResult } from './TestResult.index'
import { connect } from 'react-redux';
import './TestResult.scss'
import { withPreloader } from '../Preloader/Preloader'
import { TestResult as Skelet } from '../../Skelets/TestResults/TestResults'
import TestResultPresenter from '../../components/TestResult'
import { Actions } from './Actions/Actions'
import { withResultLoader } from 'LMSModule/utils/HOCs/withResultLoader'
import { withUnitContentLoader } from 'LMSModule/utils/HOCs/withUnitContentLoader'
import { useExamResults } from 'LMSModule/utils/hooks/useExamResults'
import {
    isHideAttempt,
    isLastUnit
} from 'LMSModule/utils/result.utils'
import { tS } from 'LMSModule/utils/i18n.adaptor'
import LessonNavigation from '../../components/LessonNavigation'
import { Button } from 'uielements/src'
import { getUnitPosition } from '../../utils/common.utils'
import { withLoad } from '../ButtonWithLoad/ButtonWithLoad'

export const UnitContentLoader = TestResultPresenter
///////////////////////////////////////////

const ButtonL = withLoad(Button)


const ResultLoader: FC<any> = (props) => {
    const { result, sId, unit, course, attemptAction, next, prev } = props
    const {
        attempts: { attempt, cangeAttempt },
        detailsRender,
        unitResult,
        version,
        isAbsolutelyCompleted,
        notCompletedUnits,
        additionalAttempts
    } = useExamResults(sId, unit.id, result.user.id, course.id)
    const { isFirst, isLast, unitIndex } = getUnitPosition(course.courseUnits, unit.id)

    return <UnitContentLoader
        {...props}
        detailsRender={detailsRender}
        attempt={attempt}
        actions={<>
            <Actions
                // className={cnClassName('Actions')}
                attemptAction={attemptAction}
                hideAttempt={isHideAttempt(unitResult.results, unit.maxAttemptsCount, isAbsolutelyCompleted, additionalAttempts)}
                sid={sId}
                unit={unit}
                isLastUnit={isLastUnit(notCompletedUnits)} />

            <LessonNavigation
                className={cnTestResult('Navigation', {})}
                left={isFirst ? undefined : <ButtonL
                    className={cnTestResult('NavBtn')}
                    theme={'unstyled_center'}
                    onClick={prev}>{tS('prev.1')}</ButtonL>}
                right={<ButtonL
                    className={cnTestResult('NavBtn')}
                    theme={'unstyled_center'}
                    onClick={next}>{isLast ? (isAbsolutelyCompleted ? tS('toresults.1') : tS('complete.1')) : tS('next.1')}</ButtonL>}
                unitIndex={unitIndex}
                unitsCount={course.unitsCount}
            />
        </>}
        cangeAttempt={cangeAttempt}
        unitResult={unitResult}
        version={version} />

}

const WithResultLoader = withUnitContentLoader(withResultLoader(withPreloader(ResultLoader, ['result', 'unitContent'], [], <Skelet />)))


export const TestResult = connect(
    mapStateToProps,
    mapDispatchToProps
)(WithResultLoader);