import React from 'react';
import { IBlock, cnCurrencyTimeline, mapStateToProps, mapDispatchToProps } from './LearningBlock.index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { List } from 'blocks/List/_type/quests/List_type_quests';



const LearningBlockP: React.FC<any> = (props) => {

  // const count = (data?.length || 0) + 20;

  return (
    <div className={cnCurrencyTimeline()}>
      <List uid={''} {...props}  />
    </div>
  )
}

export const LearningBlock = withRouter(connect(mapStateToProps, mapDispatchToProps)(LearningBlockP));