import { IDispatchProps } from '../../../src/redux/connector';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';
import { loadList } from "../../redux/actions/Photoalbums";
import { AlbumType } from './Album/Album.type';
import { getGroupByPKID } from 'redux/sagas/Groups/selectors'
export interface IPhotoalbumsProps extends IPhotoalbumsStateProps, IBasePhotoalbumsProps, ReturnType<typeof mapDispatchToProps> {

}

export interface IBasePhotoalbumsProps {
    gid: number;
}

export interface IPhotoalbumsStateProps {
    albums: AlbumType[],
    count: number;
    isFinished: boolean;
    isLoading: boolean;
    showControls?: boolean;
}
const noopArray: any[] = []
export const mapStateToProps = (state: any, props: IBasePhotoalbumsProps) => {
    let albums = noopArray
    let count = 0;
    let isFinished = false;
    let isLoading = false;
    let showControls = false;
    if (props.gid in state.photoalbums.groups) {
        albums = state.photoalbums.groups[props.gid].albums.map((id: string) => state.photoalbums.albums[id])
        count = albums.length;
        isFinished = state.photoalbums.groups[props.gid].isFinished;
        isLoading = state.photoalbums.groups[props.gid].isLoading;
        showControls = (props.gid in state.groups.groups) ? getGroupByPKID(props.gid)(state).isGroupAdmin : showControls
    }
    return { albums, count, isFinished, isLoading, showControls }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadList
}, dispatch);