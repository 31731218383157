import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Button, Spinner } from 'uielements/src'
import TrackInfo from '../TrackInfo'
import { Port } from '../Tabs/Port/Port'
import TrackFiles from '../TrackFiles'
import TrackUsers from '../TrackUsers'
import TracksStatistic from '../TracksStatistic'

export const Presenter: FC<IPresenterProps> = ({
    className,
}) => {
    useEffect(() => {
    }, [])

    const tabs = {
        default: <TrackFiles />,
        files: <TrackFiles />,
        users: <TrackUsers />,
        statistic: <TracksStatistic />,
        settings: <div>settings</div>,
    }

    return <div className={cnClassName({}, ['Page', className])}>
        <TrackInfo />

        <Port tabs={tabs} />
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
