import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from "../../../redux/connector";
// import i18n from '../../../localizations/i18n';
import { AbstractInfoItem } from '../AbstarctInfoElement/AbstractInfoElement';
import { mapStateToProps, IBadgeStateProps, cnBadge, IBadgeProps, IBadgeState, cnInfo } from './InfoBlock-Badge.index';
import './InfoBlock-Badge.scss';
import { withElementTypeState } from '../AbstarctInfoElement/_type/InfoBlock-Element_type_state';
import { withElementTypeStore } from '../AbstarctInfoElement/_type/InfoBlock-Element_type_store';
import * as React from 'react';
// import { Button } from 'uielements/src/Button/Button';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { Layout } from '../Layout/InfoBlock-Layout';
import { CacheHelper } from 'utils/src/CacheHelper'
import MDRender from 'uielements/src/CommonmarkRender';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { getFormattedCurrencyName } from 'utils.project/utils.project';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn'
interface IssueCurrencyTooltipProps {
  issueCurrency: { key: number, value: string }[]
  currencyFormats: any
}

const IssueCurrencyTooltip = ({ issueCurrency, currencyFormats }: IssueCurrencyTooltipProps) => {

  let visibleGiveCurrencyRule = issueCurrency?.length > 1 ?
    issueCurrency.find((rule: { key: number, value: string }) => rule.value === "Ручная выдача")
    || issueCurrency.find((rule: { key: number, value: string }) => rule.value.includes("Aвтоматическая выдача через рейтинг"))
    || issueCurrency.find((rule: { key: number, value: string }) => rule.value.includes("Автоматическая выдача за курс"))
    || issueCurrency.find((rule: { key: number, value: string }) => rule.value.includes("Автоматическая выдача за викторину"))
    : issueCurrency[0]

  if (!visibleGiveCurrencyRule) return null

  const visibleGiveCurrencyRuleIndex = issueCurrency.indexOf(visibleGiveCurrencyRule)

  return <div>
    <Tooltip
      title={issueCurrency.length > 1 ?
        issueCurrency.map((variant: { key: number, value: string }, i: number) => {
          if (visibleGiveCurrencyRuleIndex !== i) {
            return <div key={i}>
              <span>{variant.key} </span>
              <span>{getFormattedCurrencyName(currencyFormats, variant.key, 'nominative')}: </span>
              <span>{variant.value}</span>
            </div>
          } else return null
        })
        : ''}>
      <div className={cnBadge('Currency', ['primaryColor3-text'])}>
        {visibleGiveCurrencyRule.key} {getFormattedCurrencyName(currencyFormats, visibleGiveCurrencyRule.key, 'nominative')}
      </div>
    </Tooltip>
  </div>
}


export class BadgePresenter extends AbstractInfoItem<IBadgeProps, IBadgeState> {

  protected renderChildren() {
    const { data, currencyFormats } = this.props

    const issueCurrency = data.issueCurrency


    return (
      <Layout
        className={cnBadge()}
        left={
          <div className={'InfoBlock-Img'}>
            <Avatar
              className={cnBadge('Img')}
              imgUrl={ data.iconUrl || BaseRequests.getUrl(defaultBadgeIconUrl)}
              name={data.name}
              size={180}
              shape={'square'}
            />
          </div>
        }
        right={
          <div className={cnBadge('Wrap')}>
            <div className={cnBadge('Data')}>
              <div className={cnInfo('DataName')}>
                {data.name}
              </div>

              {issueCurrency &&
                <IssueCurrencyTooltip issueCurrency={issueCurrency} currencyFormats={currencyFormats} />
              }

              <div className={cnBadge('DataDescription')}>
                {
                  data.howToReceiveDescription ?
                    <MDRender source={data.howToReceiveDescription} /> :
                    data.description
                }
              </div>
            </div>

          </div>
        } />
    );
  }

  protected actionGetData = () => {
    let ignoreCache = false;
    CacheHelper.get('badge', `badgeinfo?bid=${this.props.badgeId}`)
      .then((value) => value && !ignoreCache && this.props.setData(utils.clone(value)))
      .catch(e => console.warn('CacheHelper, badge:', e))

    utils.API.badges.byId(this.props.badgeId)
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          ignoreCache = true;
          CacheHelper.set('badge', `badgeinfo?bid=${this.props.badgeId}`, utils.clone(d.data))
            .then((value) => console.log(value))
            .catch(e => console.warn('CacheHelper, badge:', e))

          this.props.setData(d.data);
        } else {
          // toast.error('something wrong')
        }
      });
  }
}


export const Badge = connect<IBadgeStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(compose(
  withElementTypeState,
  withElementTypeStore
)(BadgePresenter))