import { styled } from 'muicomponents/src/mui/system';
import { Button } from 'muicomponents/src/Button/Button';

export const StyledImg = styled('img')({
    width: 48,
    pointerEvents: 'none'
});

const styles = {
    gap: 8
};

export const StyledButton = styled(Button)({
    ...styles
}) as typeof Button;

export const StyledDiv = styled('div')({
    ...styles
});