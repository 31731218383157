import React, { Component } from 'react'
import { DropdownSelect } from '../DropdownSelect/DropdownSelect'
import { StyleButton } from '../StyleButton/StyleButton'
import { SketchPicker } from 'react-color';
import { debounce } from 'utils/src/utils'
export interface IAddButtonProps {
    // editorState: any;
    // onChange: any;
    // modifier: any;
}

export default class ColorPicker extends Component<any> {
    // Start the popover closed
    state = {
        url: '',
        open: false,
        load: false
    };

    private dOnChange = debounce(this.props.onToggle, 200)
    private color = {
        hex: '#333',
        rgb: {
            r: 51,
            g: 51,
            b: 51,
            a: 1,
        },
        hsl: {
            h: 0,
            s: 0,
            l: .20,
            a: 1,
        },
    }
    changeUrl = (evt: any) => {
        this.setState({ url: evt.target.value });
    };

    toggle = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    handleChange = (color: any) => {
        // const selection = this.props.editorState.getSelection();
        // const style = this.props.editorState
        // .getCurrentContent()
        // .getBlockForKey(selection.getStartKey())
        // .getStyle();
        const { editorState } = this.props;
        // const selection = editorState.getSelection();

        /*const blockType = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();*/

        // const currentType = 'textCenter';//blockType === 'textCenter' ? null : { label: 'цвет', style: 'textCen ter', type: 'textCenter'};//BLOCK_TYPES.find((val) => val.style === blockType)
        this.color = color;
        this.dOnChange(`COLORED_${color.hex}`)//rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`)

        // color = {
        //   hex: '#333',
        //   rgb: {
        //     r: 51,
        //     g: 51,
        //     b: 51,
        //     a: 1,
        //   },
        //   hsl: {
        //     h: 0,
        //     s: 0,
        //     l: .20,
        //     a: 1,
        //   },
        // }
    }

    render() {
        const currentStyle = this.props.editorState.getCurrentInlineStyle();
        const sColor = currentStyle.toArray().find((c: string) => c.startsWith(`COLORED_`))
        const selectedColor = sColor ? sColor.replace('COLORED_', '') : '#000'
        return (
            <div className={'addVideo'}>
                <DropdownSelect
                    button={(open, toggle) => <StyleButton
                        active={open}
                        label={<span style={{ borderBottom: `4px solid ${selectedColor}`, padding: '0 2px' }}>A</span>}//<i className="Icon Icon_square-full" style={{color: selectedColor}} />}
                        // onToggle={this.toggle}
                        onToggle={toggle}
                        // style={''}
                    />}
                >
                    {(open, close) =>
                        <div className={'SelectColor'}>
                            <SketchPicker color={this.color.hex} disableAlpha={true} onChange={this.handleChange} />
                        </div>
                    }
                </DropdownSelect>
            </div>
        );
    }
}
