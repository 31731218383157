/**
 * @packageDocumentation
 * @module AboutWidget
 */
import { bindActionCreators } from 'redux';
import { updateUser } from 'utils/src/CommonRedux/users/actions';

export interface IAboutEditProps extends IBaseAboutEditProps, IAboutEditStateProps, ReturnType<typeof mapDispatchToProps> {

}

export interface IBaseAboutEditProps {
    uid: string;
    showControls?: boolean;
    endEdit?: () => void;
}

export interface IAboutEditStateProps {
    user?: any
}

export const mapStateToProps = (state: any, props: IBaseAboutEditProps) => {
    let user: any = null;
    if (props.uid in state.users.users) {
        user = state.users.users[props.uid]
    } else if (props.uid in state.users.alias) {
        user = state.users.users[state.users.alias[props.uid]]
    }
    return { user }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    updateUser
}, dispatch);