import { Box, styled, Typography } from "muicomponents/src";

export const QuestionBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 4
}) as typeof Box;

export const QuestionTitle = styled(Typography)({
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '160%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'flex',
    alignItems: 'top',
    gap: 8
}) as typeof Typography;

export const QuestionDescription = styled(Typography)({
    display: 'flex',
    flexDirection: 'column'
}) as typeof Typography;

export const QuestionMultiInfo = styled(Typography)({
    
}) as typeof Typography;

export const RequiredBox = styled('span')({
    fontSize: '1.5em',
    color: '#f00'
});