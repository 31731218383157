import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { transitionToUnit } from "../../../redux/actions/LMS";
import { routerActions } from 'connected-react-router';


export const mapStateToProps = (state: any, props: any) => ({
    isComplited: state.LMS.unitsLogs[props.unit.id] ? state.LMS.unitsLogs[props.unit.id].isCompleted : false,
    isSkipped: state.LMS.unitsLogs[props.unit.id] ? state.LMS.unitsLogs[props.unit.id].isSkipped : false,
    loadUnitsLogsInProcessing: state.LMS.loadUnitsLogsInProcessing
    // cIds: state.LMS.categories.result,
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    transitionToUnit,
    push: routerActions.push
}, dispatch);

export const cnUnitItem = cn('UnitItem');