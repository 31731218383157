import React, { FC } from 'react';
// import { Box, Tabs, Tab, Card, CardContent, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './QuickLinks.scss';
import { NQuickLinks, cnQuickLinks } from './QuickLinks.index';
import { Button } from 'muicomponents/src';
import { initWidgetAddition, initWidgetSettings } from './QuickLinksSettings/QuickLinksSettings';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { Tabs as MuiTabs } from '@mui/material'
import { Tab as MuiTab } from '@mui/material'
import { styled } from 'muicomponents/src/mui/system';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';

initWidgetAddition();
initWidgetSettings();

export const StyledTabs = styled(MuiTabs)({
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0.4px",
    textAlign: "center",
    position: 'relative',
    gap: "8px",
    ".MuiTabs-flexContainer": {
        gap: "8px",
    },
    ".MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit": {
        padding: 0,
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.4px",
        textAlign: "center",
        opacity: 1,
        minWidth: "fit-content"
    },
    ".MuiTabScrollButton-root": {
        opacity: "1"
    },
    [`& .MuiTabs-scrollButtons`]: {
        position: 'absolute',
        top: 0,
        height: '100%',
        background: '#ffffffb5',
        zIndex: 1,
        border: "1px solid #0000000f",

        "&.Mui-disabled": {
            display: 'none'
        },
        "&:first-child": {
            left: 0,
            borderRadius: " 4px 0px 0px 4px ",
        },
        "&:last-child": {
            right: 0,
            borderRadius: "0px 4px 4px 0px",
        }
    },
    [`& .MuiTabs-scroller`]: {
        // borderLeft: "1px solid #0000004D",
        // borderRight: "1px solid #0000004D"
    },
});
const gap = 4;

const useTabsStyles = makeStyles({
    flexContainer: {
        gap: `${gap}px`
    }
});

const useTabStyles = makeStyles({
    root: {
        flex: 1,
        maxWidth: 'none',
        padding: 0,
        minWidth: `calc(16.73% - ${gap}px)`,
        opacity: 1,
        maxHeight: '267px',
        "&:before": {
            content: '""',
            float: 'left',
            display: 'block',
            paddingBottom: '100%'
        },
    },
    wrapper: {
        display: 'block',
        height: '100%'
    }
});

const Item: FC<NQuickLinks.Item> = ({
    id,
    imgUrl,
    text,
    url,
    showLinkText,
    openInNewTab
}) => {

    return (
        <MuiTab
            disableRipple
            label={
                <Card variant="outlined"
                    sx={{
                        border: "none",
                        ".MuiCardContent-root:last-child": {
                            marginBottom: 0
                        }
                    }}>
                    <Button
                        //  type={'link'} 
                        // theme={'unstyled'} 
                        href={url}
                        target={openInNewTab ? "_blank" : ""}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            textTransform: "initial",
                            background: "#F3F4F6",
                            opacity: 1,
                            padding: "8px 12px",
                            gap: showLinkText ? "8px" : "",
                            minWidth: showLinkText ? "64px" : "fit-content"
                        }}>
                        <Tooltip title={showLinkText ? "" : text}>
                            <span>  <CardMedia
                                image={imgUrl}
                                sx={{
                                    width: "24px",
                                    height: "24px",
                                }}
                            />
                            </span>
                        </Tooltip>
                        <CardContent sx={{ padding: 0 }}>
                            {showLinkText && <Tooltip title={text.length > 30 ? text : ""}>
                                <span>
                                    {text}
                                </span>
                            </Tooltip>}
                        </CardContent>
                    </Button>
                </Card>
            }
        />
    )
}

export const QuickLinks: FC<NQuickLinks.Props> = ({
    className,
    data
}) => {

    // console.log("Data", data)

    return (
        <StyledTabs
            variant="scrollable"
            allowScrollButtonsMobile 
            scrollButtons={true}
        >
            {
                data.map(block => {
                    return (
                        <Item key={block.id} {...block} />
                    )
                })
            }
        </StyledTabs>

    );
}