import React, { FC, useMemo, useState } from 'react';
import { IShopItem } from 'utils/src/requests/models/api.shop';
import i18n from 'localizations/i18n';
import { useDispatch, useSelector, } from 'react-redux';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { toast } from 'react-toastify';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
// import { IStoreAppSetting } from 'models/store/appSettings';
import { openShopMakeOrder } from 'blocks/Dialogs/Shop/ShopMakeOrder/ShopMakeOrder';
import { currentUserMinusMyThanksCount } from 'utils/src/CommonRedux/users/actions';
import { Link } from 'muicomponents/src/Link/Link'
import { IStateType } from 'redux/store';
import { Box, Paper, Typography } from 'muicomponents/src';
// import { Grid } from 'muicomponents/src/Grid';
import { AvailableBox, StarBox, StyledAvatar } from './Shop-Item.styled';
// import { PhotoSkeleton } from './PhotoSkeleton';
import OrderBlock from '../OrderBlock/OrderBlock';
import StarIcon from '@mui/icons-material/Star';
import { PriceElem } from './PriceElem';
// import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip'
import { CardItemLayout } from 'muicomponents/src/ItemsListDialog/CardItem/CardItem'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getCurrencyNames } from 'utils/src/CommonRedux/base/selectors';
import { Translate } from 'localization';
import { isNumber } from 'lodash'
import { Chip } from 'muicomponents/src/Chip/Chip'
import placeholderImg from '../placeholder.svg'
import { cn } from '@bem-react/classname';
import { useShopListContext } from '../ShopList/ShopListMui.context';
export interface IShopItemProps {
  data: IShopItem;
  // myThanksCount: number
  // currencyFormats: IStoreAppSetting
}

export const cnClassName = cn('MUIShopItem');
const GiftMark = ({ toColumn, maxWidth }: { maxWidth?: string | number, toColumn: boolean }) => <Chip
  label={<Translate i18nKey={`pryaniky.shop.isGift`} />}
  className={cnClassName("CanBeGiftedChip")}
  variant='filled'
  sx={{
    position: "absolute",
    color: "#fff",
    background: "rgba(245, 0, 87, 1)",
    top: toColumn ? (isNumber(maxWidth) ? maxWidth * 0.735812133072407 - 40 : 188 - 40) : 'auto',
    left: "8px"
  }}
/>

export const ShopItem: FC<IShopItemProps> = (props: IShopItemProps) => {
  

  const listContext = useShopListContext();

  const { data } = props;
  const currencyFormats = useSelector(getCurrencyNames)
  const myThanksCount = useSelector(getAuthUser).extData.mythanksCount
  const dispatch = useDispatch()
  // const [imageLoaded, setImageLoaded] = useState(false)

  // const [showDialog, setShowDialog] = useState(false)
  //const [presentFor, setPresentFor] = useState()
  //const [comment, setComment] = useState('')

  const maxOrdersCount = useSelector((s: IStateType) => s.shop.categoryProducts.maxOrdersCount)
  const maxOrdersPeriod = useSelector((s: IStateType) => s.shop.categoryProducts.maxOrdersPeriod)
  const ordersCount = useSelector((s: IStateType) => s.shop.categoryProducts.ordersCount) || 0
  const additionalInformationBeforeOrder = useSelector((s: IStateType) => s.shop.categoryProducts.additionalInformationBeforeOrder)
  const isHidePricesAndButtonBuy = useSelector((s: IStateType) => s.shop.categoryProducts.isHidePricesAndButtonBuy)
  let isHideButtonBuy = useSelector((s: IStateType) => s.shop.categoryProducts.isHideButtonBuy)
  isHideButtonBuy = isHideButtonBuy ? isHideButtonBuy : (ordersCount >= maxOrdersCount && maxOrdersPeriod !== 'unlimited')

  const clearShopProduct = () => {
    dispatch(clearShopProduct())
  }
  const clearShopCatProducts = () => {
    dispatch(clearShopCatProducts())
  }

  const orderItem = (data: any) => {
    const { id, count, thanksPrice, ...params } = data;
    utils.API.shop.orderV4(
      data.id,
      data.count,
      params
    )
      .r.then((response: IBasicResponse) => {
        if (utils.checkResponseStatus(response)) {
          dispatch(currentUserMinusMyThanksCount(data.thanksPrice * data.count))
          // setShowDialog(false)
          toast.success(i18n.t('your order is successfully placed'));
        } else {
          toast.error(response.error_text);
        }
      });
  }

  const { characteristics = [], thanksPrice, minVirtCurrencyPayment, availableCount, isUnlimitedProduct } = data

  const contentRating = data?.news?.contentRating

  // если цена зависит от характеристик: сравниваем userThanksCount с минимальной ценой на товар, а не с общей ценой thanksPrice и выводим "От"
  const priceDependsOnChars = characteristics.some(char => char.usedForPrice) && minVirtCurrencyPayment > -1
  const fromPriceText = priceDependsOnChars ? i18n.t('from.price') : null;
  const price = priceDependsOnChars ? minVirtCurrencyPayment : thanksPrice
  const priceCurrencyText = utilsProj.getFormattedCurrencyName(currencyFormats, price, !characteristics.length ? 'nominative' : 'genitive');

  // для товаров которых нет в наличии: при поиске по товарам их все равно видно, и для отображения корректной надписи о наличии проверям это
  const productIsAvailable = isUnlimitedProduct ? true : !!availableCount;

  const useCountSelector = useMemo(() => {
    return data.externalServiceType !== 'Giftery' && !data.isVirtualProduct;
  }, [data.externalServiceType]);

  const maxForCountSelector = useMemo(() => {
    return !isUnlimitedProduct
      ? availableCount
      : 9999;
  }, [isUnlimitedProduct, availableCount]);

  if (ordersCount === undefined) return null
  const mainImg = data.mainImgId === "00000000-0000-0000-0000-000000000000" ? placeholderImg : data.mainImgUrl
  return <CardItemLayout
    alt={data.name}
    image={mainImg}//{data.mainImgUrl}
    // isGift={data.isGift}
    header={<Link
      sx={{ textDecoration: 'none' }}
      className={cnClassName("LinkToProduct")}
      to={`/shop/product/${data.id}`}>{data.name}</Link>}
    CardActionAreaProps={{
      LinkComponent: Link,
      to: `/shop/product/${data.id}`
    }}
    MarksComponent={data.isGift ? GiftMark : undefined}
    actions={
      // <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '8px', flex: '1' }}>
      !isHideButtonBuy &&
      <Box sx={{ flex: 1 }}>
        <OrderBlock
          {...{
            characteristics,
            currencyFormats,
            id: data.id,
            maxOrdersCount,
            maxOrdersPeriod,
            myThanksCount,
            ordersCount,

            // TODO:
            // ой-ой... вот это плохо. Запрос заказа нужно переместить в диалог. 
            // А иначе, пользователь такой, заполнил форму, всё написал, нажал заказать, диалог закрылся, форма оичстилась. 
            // И тут бум ему по щам - ОШИБКА. А форма та уже очистилась, не вернуть
            // та же история на странице товара
            showDialog: () => openShopMakeOrder({
              data,
              preselectedUsersId: listContext.userId,
              additionalInformationBeforeOrder,
              withCount: useCountSelector,
              maxCount: maxForCountSelector,
            }).then((value) => orderItem(value)).catch(() => { }),
            //

            thanksPrice: data.thanksPrice,
            disableOrder: characteristics.length === 0,
            productIsAvailable,
            isGift: data.isGift
          }}
        />
      </Box>

      // </Box>
    }
  >
    {!isHidePricesAndButtonBuy &&
      <PriceElem fromPriceText={fromPriceText} price={price} priceCurrencyText={priceCurrencyText} />}

    <AvailableBox className={'ShopItem-AvaliableBox'}>
      {data.allowContentRating && contentRating.rating > 0 &&
        <StarBox className={'ShopItem-Rating'}>
          <StarIcon htmlColor='#FFB400' sx={{ height: '25px', width: '25px' }} />
          <Typography variant='subtitle2' color='gray'>{contentRating.rating}</Typography>
        </StarBox>
      }
      <Typography className={'ShopItem-Avaliable'} variant='subtitle2' fontWeight='600'>{i18n.t('pryaniky.uielements.materialelements.TransferList.availableTitle')}: &nbsp; </Typography>
      <Typography className={'ShopItem-AvaliableValue'} variant='subtitle2' color='gray'>{data.isUnlimitedProduct ? i18n.t('pryaniky.shop.unlimited') : availableCount + i18n.t("pryaniky.shop.pc")}</Typography>
    </AvailableBox>

  </CardItemLayout>

}



