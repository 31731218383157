import { IIdeaFieldProps } from '../FieldProps';
import React from 'react';
import { Bable } from '../../../../../Bable/Bable'

export const Multi: React.FC<IIdeaFieldProps> = ({ field }) => {
    return (
        <div>
            {
                field.field.answers.map(answer => 
                     field.values.indexOf(answer.id) !== -1 ? <Bable key={answer.id}>{answer.text}</Bable> : undefined
                
                    // <label><input type="checkbox" checked={field.values.indexOf(answer.id) !== -1} value={answer.id} />{answer.text}</label>
                )
            }
        </div>
    );
}