import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IStateType as IState } from 'redux/store';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { getNotifiSettingsNetworks, getMotifiTemplatesSelector } from 'redux/notifiSettings/selectors';
import { changeNotifiSettingsNetworkData, getNotifiSettings, clearNotifiSettings, changeNotifiSettingsNetworkTemplate } from 'redux/notifiSettings/actions';

export interface INotifiSettingsNeworksProps extends IMapStateToProps, IMapDispatchToProps, WithTranslation, RouteComponentProps<IParams> { }

export const mapStateToProps = (state: IState, props: RouteComponentProps<IParams>) => {
 
  return {
    networks: getNotifiSettingsNetworks(state),
    networksIds: Object.keys(getNotifiSettingsNetworks(state)),
    templates: getMotifiTemplatesSelector(state),
    parentNetwork: state.store?.appSettings?.parentNetwork
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  changeNotifiSettingsNetworkData,
  getNotifiSettings,
  clearNotifiSettings,
  changeNotifiSettingsNetworkTemplate
}, dispatch);

export interface IParams {
  tab: 'common' | 'groups' | 'group' | 'network';
  uid: string;
  gid?: string;
  nid?: string;
}

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type IMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export const cnNotifiSettingsNeworks = cn('NotifiSettingsNeworks');
