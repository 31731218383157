import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    createAnswer,
    updateQuestion,
    changeQuestionType,
    setUnitError,
    setQuestionError,
    deleteAnswer
} from '../../../redux/actions/LMSTeacherOffice'
import { getQuestionAnswersErrors, getQuestionsErrors, getAnswersErrorsByQuestion } from '../selectors'
///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    qid: string;
    uid: string;
    number: number;
    deleteQuestion: (qid: string) => void
    children?: React.ReactNode
    questionTypes: {
        value: number,
        title: React.ReactNode,// 'Один вариант из списка',
        id: number,
    }[]
    // onChange: (question: any) => void
}

export interface IStateProps {
    question: any;
    withAnswerError: boolean;
    errors: any;
    answersErrors: any[];
    maxFileSize?: number;
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    question: state.LMSTO.questions[props.qid],
    withAnswerError: getQuestionAnswersErrors(state, props.uid, props.qid),
    errors: getQuestionsErrors(state, props.qid),
    answersErrors: getAnswersErrorsByQuestion(state, props.qid),
    maxFileSize: state.store.appSettings.maxFileSizeLimit,
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    createAnswer,
    updateQuestion,
    setQuestionError: (errors: any) => setQuestionError(ownProps.uid, ownProps.qid, errors),
    changeQuestionType,
    deleteAnswer
}, dispatch);

////////////

export const cnClassName = cn('TOCEQuestionEdit');