/**
 * @packageDocumentation
 * @module Widget_Settings
 */
export const JFdictionary = {
  formats: {
    date: 'date'
  },
  types: {
    object: 'object',
    boolean: 'boolean',
    string: 'string',
    number: 'number',
    integer: 'integer',
    array: 'array'
  },
  renderers: {
    control: 'Control',
    layout: 'Layout',
    radio: 'Radio',
    checkbox: 'Checkbox',
    usersInput: 'UsersInput',
    groupsInput: 'GroupsInput',
    tagsInput: 'TagsInput',
    newsInput: 'NewsInput',
    ratingsInput: 'RatingsInput',
    number: 'Number',
    date: 'Date',
    dateTime: 'DateTime',
    time: 'Time',
    select: 'Select',
    text: 'Text',
    table: 'Table',
    sliderItem: 'SliderItem',
    slider: 'Slider',
    input: 'Input',
    loader: 'Loader',
    textInfo: 'TextInfo',
    button: 'Button',
    virtualUser: 'VirtualUser',
    tagsColleactionsInput: 'TagsColleactionsInput',
    suggester: 'Suggester',
    suggesterHierarchy: 'SuggesterHierarchy',
    usersListBlock: 'UsersListBlock',
    statistic: 'Statistic',
    statisticFilters: 'StatisticFilters',
    widgetUserfields: 'WidgetUserfields',
    widgetIcon:"widgetIcon"
  }
}