import * as React from 'react';
import { IEditorTypeEditProps } from './edit.index';
import { UeventNews, defaultUeventNews } from '../../../EventsType'
import { EventsValidator } from '../../../Events.validate'
import { baseValidate } from '../../../../Base.validate'
import { Editor } from '../../Editor';
import { Rule } from '../../../../Base.validate'

const maxParticipantsCountValidateRule = {
    type: 'number',
    method: 'NUM_MIN',
    field: 'uevent.maxParticipantsCount',
    value: 0,
    errorI18nKey: 'pryaniky.validate.news.uevent.maxParticipantsCount.min',
}

export let ueventValidate: Rule[] = [
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'uevent.title',
        value: 1,
        errorI18nKey: 'pryaniky.validate.news.uevent.title.min',
        // message: 'small title'
    },
    // {
    //     type: 'string',
    //     method: 'STR_MIN',
    //     field: 'text',
    //     value: 3,
    //     errorI18nKey: 'pryaniky.validate.news.base.text.min'
    // },
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'header',
        value: 0,
        errorI18nKey: 'pryaniky.validate.news.base.header.min'
    },
    {
        type: 'string',
        method: 'STR_MAX',
        field: 'uevent.eventLocation',
        value: 180,
        errorI18nKey: 'pryaniky.validate.news.uevent.eventLocation.max',
        // message: 'big eventLocation'
    },
    // maxParticipantsCountValidateRule
]

export const EditorTypeEditShvabe: React.FC<IEditorTypeEditProps> = ({ onChange: cbChange, data }) => {
    let localData = { ...data } as any;;

    React.useEffect(() => {
        // if users already regiter users to event
        if(localData.uevent.maxParticipantsCount && localData.uevent.eventParticipantsCount) {
            // if rule not added
            if(!ueventValidate.filter(el => el.field === 'uevent.maxParticipantsCount' && el.method === 'NUM_MIN').length) ueventValidate.push(maxParticipantsCountValidateRule);
            // set rule value by already regiter users to event count
            ueventValidate.forEach(el => {
                if(el.field === 'uevent.maxParticipantsCount' && el.method === 'NUM_MIN') {
                    el.value = localData.uevent.eventParticipantsCount;
                };
            });
        }
    }, []);

    const onChange = (data: any) => {
        const vb = new EventsValidator();
        vb.addData(data)
        vb.setRules(ueventValidate)
        const validated = vb.validation();
        cbChange(data, validated.length === 0, validated)
    }

    const onChangeTitle = (e: any) => {
        localData.header = e.target.value;
        localData.uevent.title = e.target.value;
        onChange(localData);
    }
    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData);
    }
    const expiresChange = (date: Date) => {
        localData.uevent.startDateTime = date;
        localData.expires = date;
        onChange(localData);
    }
    const durationChange = (selected: any) => {
        localData.uevent.duration = selected.value;
        onChange(localData);
    }
    const onChangeLocation = (e: any) => {
        localData.uevent.eventLocation = e.target.value;
        onChange(localData);
    }
    const onChangeMaxPartisipantsCount = (count: number | null) => {
        localData.uevent.maxParticipantsCount = count;
        if(
            localData.uevent.maxParticipantsCount === null ||
            localData.uevent.maxParticipantsCount === 0
            ) {
            ueventValidate = ueventValidate.filter(el => el.field !== 'uevent.maxParticipantsCount');
        } else {
            // if rule not added
            if(!ueventValidate.filter(el => el.field === 'uevent.maxParticipantsCount' && el.method === 'NUM_MIN').length) ueventValidate.push(maxParticipantsCountValidateRule);
            // set rule value by already regiter users to event count
            ueventValidate.forEach(el => {
                if(el.field === 'uevent.maxParticipantsCount' && el.method === 'NUM_MIN') {
                    el.value = localData.uevent.eventParticipantsCount;
                };
            });
        }
        onChange(localData);
    }
    return <Editor
        durationChange={durationChange}
        expiresChange={expiresChange}
        onChangeLocation={onChangeLocation}
        onChangeText={onChangeText}
        onChangeTitle={onChangeTitle}
        data={localData}
        onChangeMaxPartisipantsCount={onChangeMaxPartisipantsCount}
        />
}
