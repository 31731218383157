import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget } from '../Widget.index';
import { IUserGroupsNewProps } from './Widget_type_userGroupsNew.index';
import { default as WidgetPresenter } from '../Widget';
import { widgets } from "i.widgets";
import { UserGroupsNew } from 'blocks/UserGroupsNew/UserGroupsNew';
import './Widget_type_userGroupsNew.scss';


const types = {
  common: UserGroupsNew,
}

const type = 'userGroupsNew';

export default class Widget_type_userGroupsNew extends WidgetPresenter<IUserGroupsNewProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    return <Component {...this.props} className={cnWidget({type, subtype})} settings={this.props.widget.settings} context={this.props.wcontext} tag={widgets.components.common} />
  }
}
