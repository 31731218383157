/**
 * @packageDocumentation
 * @module Widget_Settings_info
 */
import { v1 as uuid } from 'uuid';

export default {
  list: [
    {
      title: '',
      deleted: false,
      list: [],
      id: uuid()
    }
  ],
}