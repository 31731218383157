import * as React from 'react';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { IWidgetTypeContextProps } from './Widget_type_context.index';
import './Widget_type_courses_my.scss';
import { widgets } from 'i.widgets';
import { store } from '../../../redux/store';
import actions from '../../../redux/actionsTypes/Widgets';
const type = 'context';

class WidgetTypeCoursesMyPresenter extends WidgetPresenter<IWidgetTypeContextProps> {
  private int: any;

  public componentDidMount() {
  // @ts-ignore
    if (super.componentDidMount) super.componentDidMount();
    this.contextView();
  }

  public componentWillUnmount() {
    super.componentWillUnmount();
    clearInterval(this.int);
  }

  private contextView = () => {
    this.int = setInterval(() => {
      utils.API.system.getVisibleContext('dash')
      .r
      .then((d: any) => {
        if(utils.checkResponseStatus(d)) {
          store.dispatch({ type: actions.UPDATE_CONTEXT, payload: { id: 'common', context : { uId: d.data.userId }}});
        }
      })
    }, 1500);
  }

  public render() {
    if (!this.props.edit) return null;
    return (
      <widgets.components.common {...this.props}>
        context:
        <pre children={JSON.stringify(this.props.wcontext, undefined, 2)} />
      </widgets.components.common>
    );
  }
}

export default WidgetTypeCoursesMyPresenter