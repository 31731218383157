import React, { FC, useState, useEffect, useRef, useContext, useMemo, useLayoutEffect, ComponentProps } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { cnNewsTypeThanks } from './Thanks.index';
import './Thanks.scss';
import {
    TextField,
    UsersSuggester as UsersSuggesterC,
    ReasonsSuggester as ReasonsSuggesterC,
    Divider,
    Box
} from 'muicomponents/src';
import { DraftInput as DraftInputC, useDraftEdit } from 'muicomponents/src/DraftInput/DraftInput';
import { Translate } from 'localizations/Translate';
import { ComponentInjector } from 'utils/src/ComponentInjector';

import { useDidUpdateEffect } from 'utils/src/hooks';
import { PrepareErrorText } from 'News/utils/utils.validate';
import { ThanksValidateRules } from './Thanks.validate';

import { useThanksChange, useThanksEdit } from './Thanks.hooks';

import { GiveMoney } from 'muicomponents/src/Icons';
import { TooltipNews } from 'muicomponents/src/TooltipNews/TooltipNews';
import { NewsCreateContext } from 'News/creator/Creator/Creator.constants';
import { useDraftEditorStyle } from 'News/creator/hooks';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { Tooltip } from 'muicomponents/src/Tooltip'
import { memoizedComponent } from 'News/creator/utils.sender';
import { NumberTextField as NumberTextFieldC } from 'muicomponents/src/TextField/TextField';

const prepareI18nKey = (key: string) => `pryaniky.news.create.thanks.${key}`;

const UsersSuggester = memoizedComponent(UsersSuggesterC);
const ReasonsSuggester = memoizedComponent(ReasonsSuggesterC);
const DraftInput = memoizedComponent(DraftInputC);
const NumberTextField = memoizedComponent(NumberTextFieldC);

const NewsTypeThanksPresenter: FC<{}> = ({
}) => {

    const { creatorId } = useContext(NewsCreateContext);

    const {
        data,
        errors,
        thanksParams,
        maxThanksValue,
        maxThanksArgumentsCount,
        clearEditor,
        isAdmin,
        changeCreator,
        changeNews,
        formSettings
    } = useThanksEdit(creatorId);

    const errorsRef = useRef(errors);
    useEffect(() => {
        errorsRef.current = errors;
    }, [errors]);

    const {
        text: currentNewsText,
        draftRawState,
        users,
        groups,
        thanksCount,
        rewardReasons,
    } = data;

    const textRef = useRef(currentNewsText);
    textRef.current = currentNewsText;

    const user = useSelector(getCurrentUser, shallowEqual);

    const {
        allThanksCount,
        thanksToUse,
        // avaliableThanksCount,
        selectedCollection,
        collectionsWithWallet
    } = useThanksChange({
        user,
        users,
        groups,
        thanksCount,
        rewardReasons
    });

    const collectionsRef = useRef({ selectedCollection, collectionsWithWallet });
    collectionsRef.current = { selectedCollection, collectionsWithWallet };

    // update text length validation rules
    useEffect(() => {
        if (thanksParams?.minTextLength) {
            ThanksValidateRules.text!.min!.value = thanksParams?.minTextLength;
        }
    }, [thanksParams]);

    // обновляем правила валидации для количества валюты
    ThanksValidateRules.thanksCount = {
        ...ThanksValidateRules.thanksCount,
        max: {
            value: !maxThanksValue ? allThanksCount : (maxThanksValue > allThanksCount ? allThanksCount : maxThanksValue)
        }
    };

    // обновляем правила валидации для количества ценностей
    ThanksValidateRules.rewardReasons = {
        ...ThanksValidateRules.rewardReasons,
        max: {
            value: maxThanksArgumentsCount
        }
    };

    const {
        state,
        draftState,
        setDraftState
    } = useDraftEdit(draftRawState, clearEditor);

    useDidUpdateEffect(() => {
        // because text editor push additional state change when state is empty externally
        if(!textRef.current && !state.text) return;
        changeNews({
            text: state.text,
            draftRawState: state.raw
        });
    }, [state]);

    useLayoutEffect(() => {
        if (users.length && !groups.length) {
            ThanksValidateRules.users!.notNull!.value = true;
            ThanksValidateRules.groups!.notNull!.value = false;
        }
        if (!users.length && groups.length) {
            ThanksValidateRules.users!.notNull!.value = false;
            ThanksValidateRules.groups!.notNull!.value = true;
        }
    }, []);

    useDidUpdateEffect(() => {
        if (!users.length && !groups.length) {
            ThanksValidateRules.users!.notNull!.value = true;
            ThanksValidateRules.groups!.notNull!.value = true;
        }
    }, [users, groups]);

    const suggesterValues = useMemo(() => {
        return [...users, ...groups];
    }, [users, groups]);

    const draftCommonProps = useDraftEditorStyle();

    const UsersSuggesterProps = useMemo<Partial<ComponentProps<typeof UsersSuggester>>>(() => ({
        requestAdditionalParams: { showsubuser: true },
        TextFieldProps: {
            label: <Translate i18nKey={prepareI18nKey('users')} />,
            error: Boolean(errors.users) && Boolean(errors.groups),
            helperText: (Boolean(errors.users) || Boolean(errors.groups)) && PrepareErrorText(errors.users || errors.groups)
        },
        onChange: (_, users) => {
            if (Array.isArray(users)) {
                const usersList = users.filter(el => el.type === 'user');
                const groupsList = users.filter(el => el.type === 'group');
                if (
                    !usersList.length && !groupsList.length ||
                    usersList.length && groupsList.length
                ) {
                    ThanksValidateRules.users!.notNull!.value = true;
                    ThanksValidateRules.groups!.notNull!.value = true;
                }
                if (usersList.length && !groupsList.length) {
                    ThanksValidateRules.users!.notNull!.value = true;
                    ThanksValidateRules.groups!.notNull!.value = false;
                }
                if (!usersList.length && groupsList.length) {
                    ThanksValidateRules.users!.notNull!.value = false;
                    ThanksValidateRules.groups!.notNull!.value = true;
                }
                changeNews({
                    users: (usersList || []) as any,
                    groups: (groupsList || []) as any
                });
            }
        }
    }), [errors.users, errors.groups]);

    const DraftInputProps = useMemo<Partial<ComponentProps<typeof DraftInput>>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('text')} />
    }), []);

    const thanksCountProps = useMemo<Partial<ComponentProps<typeof NumberTextField>>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('thanksCount')} />,
        InputProps: {
            startAdornment: <GiveMoney />
        },
        onChange: (event) => {
            changeNews({ thanksCount: event.target.value });
        },
        error: Boolean(errors.thanksCount),
        helperText: <>
            {
                Boolean(errors.thanksCount) &&
                PrepareErrorText(errors.thanksCount) ||
                <>
                    <Translate i18nKey={prepareI18nKey(`thanksCount.helperText`)} count={allThanksCount} />
                    {!!thanksToUse && <><br /><Translate i18nKey={prepareI18nKey(`thanksCount.helperTextWillUse`)} count={thanksToUse} /></>}
                </>
            }
        </>
    }), [errors.thanksCount, thanksToUse]);

    const ReasonsSuggesterProps = useMemo<Partial<ComponentProps<typeof ReasonsSuggester>>>(() => ({
        TextFieldProps: {
            label: <Translate i18nKey={prepareI18nKey('rewardReasons')} />,
            error: Boolean(errors.rewardReasons),
            helperText: Boolean(errors.rewardReasons) && PrepareErrorText(errors.rewardReasons)
        },
        onChange: (_, rewardReasons) => changeNews({ rewardReasons: (rewardReasons || []) as any }),
        getOptionDisabled: (option) => {
            if (!collectionsRef.current.selectedCollection) return false;
            if (collectionsRef.current.selectedCollection === 'all') return collectionsRef.current.collectionsWithWallet.includes((option as any).category);
            return collectionsRef.current.selectedCollection !== (option as any).category;
        }        
    }), [errors.rewardReasons]);

    return (
        <>
            {thanksParams && thanksParams.showTooltip &&
                <TooltipNews newsParams={thanksParams} title={thanksParams.tooltipHeader} />
            }

            {formSettings?.thanks?.subHeaderContent}

            {!formSettings?.thanks?.hideUsersSelect && <UsersSuggester
                test-id={'timeline-create-thanks-users'}
                {...UsersSuggesterProps}
                isAdmin={isAdmin}
                withCheckboxes
                multiple
                // TODO check types
                value={suggesterValues as any}
                saveSearchOnBlur
            />}
            <DraftInput
                test-id={'timeline-create-thanks-text'}
                {...DraftInputProps}
                {...draftCommonProps}
                disableToolbar={!thanksParams?.allowRichEdit}
                value={draftState}
                onChange={setDraftState}
                error={Boolean(errors.text)}
                helperText={Boolean(errors.text) && PrepareErrorText(errors.text as any)}
                clearValue={clearEditor}
            />
            <Divider />
            <NumberTextField
                test-id={'timeline-create-thanks-thanksCount'}
                {...thanksCountProps}
                value={thanksCount || ''}
                hideApperance
                fullWidth
            />
            <ReasonsSuggester
                test-id={'timeline-create-thanks-rewardReasons'}
                {...ReasonsSuggesterProps}
                multiple
                // TODO check types
                value={(rewardReasons || []) as any}
            />
        </>
    )
}

export const NewsTypeThanks = NewsTypeThanksPresenter;

export const initNewsThanksCreator = () => ComponentInjector.getInstance().addNode('news_creator_mui5', <NewsTypeThanks />, 'thanks')