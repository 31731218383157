import { INewsPropsType, mapStateToProps, TStateProps } from './LMSCources.index'
import React, { FC, memo } from 'react';
import { connect, useSelector, shallowEqual } from 'react-redux';
import './LMSCources.scss'
import { withNewsContext } from 'News/contexts/news'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import TextRender from 'News/containers/TextRender'
import ContentLayout from 'News/components/ContentLayout'
import {
    getNewsById,
    getNewsParamsById
} from 'News/redux/saga/selectors'
import { LMSCourcesNews } from './LMSCources.type'
import { LinkLayout } from 'blocks/NewsTypes/common/LinkLayout/LinkLayout';
import ItemView from '../ItemView'


const newsParams = [
    'id',
    'learningTrack'
] as const
export const LMSCourcesPresenter: FC<INewsPropsType & TStateProps> = ({ newsId }) => {
    const params = useSelector(getNewsParamsById<LMSCourcesNews, typeof newsParams[number]>(newsId, newsParams), shallowEqual)

    return <NewsView className={'type-5'} ViewRender={ItemView}>
        <ContentLayout>
            {/* <div>{params.learningTrack.name}</div> */}
            {/* {params.learningTrack.imgUrl && <img src={params.learningTrack.imgUrl}/>} */}
        </ContentLayout>
        {params.learningTrack.imgUrl && <LinkLayout linkPreviewImgUrl={params.learningTrack.imgUrl} systemNewsActionHtml={params.learningTrack.name} />}
    </NewsView>
}

export const LMSCources = withNewsContext<INewsPropsType>(connect(
    mapStateToProps
)(memo(LMSCourcesPresenter)), ['newsId']);


export const initLMSCources = () => {
    ComponentInjector.getInstance().addNode('news_types', <LMSCources />, 'LMSLearningTrack.LMSCources')
}