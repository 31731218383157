import { isEqual } from "lodash";
import React, { ComponentProps, FC, memo, useCallback, useMemo, useRef } from "react";
import { usePollAnswers } from "./Polls.hooks";
import { NPolls } from './Polls.interface';
import { TextField as TextFieldC } from 'muicomponents/src/TextField';
import { memoizedComponent } from "News/creator/utils.sender";
import { PrepareErrorText } from 'News/utils/utils.validate';
import { Translate } from 'localizations/Translate';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { AddCircle, Close } from 'muicomponents/src/Icons';
import { Button } from 'muicomponents/src/Button/Button';
import {
    styled
} from 'muicomponents/src';
import { useDidUpdateEffect } from "utils/src/hooks";
import { NewsChangeReasons } from "News/creator/redux/actions.types.newsCreator";

const prepareI18nKey = (key: string) => `pryaniky.news.create.polls.${key}`;

const TextField = memoizedComponent(TextFieldC);

const StyledAddAnswerButtonC = styled(Button)(({
    color
}) => ({
    padding: '13px 15px',
    justifyContent: 'start',
    color: color ? undefined : 'rgba(0, 0, 0, 0.6)',
    borderColor: color ? undefined : 'rgba(0, 0, 0, 0.23)',
    textTransform: 'none',
})) as typeof Button;

const StyledAddCircle = styled(AddCircle)({
    opacity: .54,
    color: '#000',
    fontSize: '1.5rem !important'
});

const StyledAddAnswerButton = memoizedComponent(StyledAddAnswerButtonC);

const PollsAnswerPr: FC<{
    id: string
    idx: number,
    value: string;
    error: any,
    onChange: (answerId: string, value: string) => void;
    removeAnswer: (answerId: string) => void;
}> = ({
    id,
    idx,
    value,
    error,
    onChange,
    removeAnswer
}) => {

    const onChangeHandle = useCallback<NonNullable<ComponentProps<typeof TextField>['onChange']>>((e) => {
        const value = e.target.value;
        onChange(id, value);
    }, [id]);

    const InputProps = useMemo(() => ({
        endAdornment: <IconButton
            onClick={() => {
                removeAnswer(id);
            }}
        >
            <Close />
        </IconButton>
    }), [id]);

    return (
        <TextField
            key={id}
            label={<Translate i18nKey={prepareI18nKey('answers.item')} count={idx + 1} />}
            error={Boolean(error?.text)}
            helperText={Boolean(error?.text) && PrepareErrorText(error.text)}
            value={value}
            fullWidth
            multiline
            onChange={onChangeHandle}
            InputProps={InputProps}
        />
    )
}

const PollsAnswer = memoizedComponent(PollsAnswerPr);

const PollsAnswersPr: FC<{
    answers: NPolls.Create['poll']['answers'];
    errors: any;
    updateAnswersToModel: (answers: NPolls.Create['poll']['answers'], changeReasons?: NewsChangeReasons) => void;
}> = ({
    answers: answersProps,
    errors,
    updateAnswersToModel
}) => {


    const {
        answersIds,
        answers,
        answersRef,
        answerChangeReason,
        setAnwsers,
        setAnswer,
        addAnswer,
        removeAnswer
    } = usePollAnswers(answersProps);
    const answersIdsRef = useRef(answersIds);
    answersIdsRef.current = answersIds;

    const dropAnswers = useRef(false);

    useDidUpdateEffect(() => {
        if(!answersProps.length) {
            dropAnswers.current = true;
            setAnwsers({});
        }
    }, [ answersProps ]);

    useDidUpdateEffect(() => {
        // don't set redux when drop answers data
        if(dropAnswers.current) {
            dropAnswers.current = false;
            return ;
        }
        updateAnswersToModel(answersIdsRef.current.map(id => answersRef.current[id]), answerChangeReason.current);
        // changeAnswerText();
    }, [ answers ]);

    const changeAnswer = useCallback<ComponentProps<typeof PollsAnswer>['onChange']>((id, value) => {
        const currentAnswer = answersRef.current[id];
        setAnswer({
            ...currentAnswer,
            text: value
        });
    }, []);

    const addAnswerIcon = useMemo(() => <StyledAddCircle />, []);

    return (
        <>
            {
                answersIds.map((id, idx, arr) => {
                    const answer = answers[id];
                    return (
                        <PollsAnswer
                            key={answer.id}
                            id={answer.id}
                            idx={idx}
                            error={errors[`pollAnswer.${id}`]}
                            value={answer.text}
                            onChange={changeAnswer}
                            removeAnswer={removeAnswer}
                        />
                    );
                })
            }
            <StyledAddAnswerButton
                fullWidth
                variant={'outlined'}
                color={Boolean((errors.poll as any)?.answers) && 'error' || undefined}
                startIcon={addAnswerIcon}
                onClick={addAnswer}
            >
                <Translate i18nKey={prepareI18nKey('answers.add')} />
            </StyledAddAnswerButton>
        </>
    );
};

export const PollsAnswers = memo(PollsAnswersPr, isEqual);