
import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IStateType } from 'redux/store';
import { 
    getOrgchartDataFromServer, 
    deleteStructureDataOrgchart,
    orgchartToggleDraggable,
    orgchartDragPosition,
    changeStructureDataOrgchart,
    saveStructureDataOrgchart,
    addSubUnit,
    orgchartSaveUsersInUnitAfterDrag,
    orgchartSaveAfterUnitPositionDrag,
    setOrgchartDraggingUnit,
    setOrgchartDraggingUnitToPosition,
    editSubUnit,

} from '../../../redux/sagas/orgchart/actions'
import { getOrgchartState,
    //  getOrgchartChangedUnitSettings, 
    } from '../../../redux/sagas/orgchart/selectors'
import { forLibPrefix } from '../../../redux/sagas/orgchart/constants'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';


export interface ICustomNodeProps extends IClassNameProps, ICustomNodeStateProps, ICustomNodeDispatchProps, ICustomNodeOwnProps { }

export type ICustomNodeStateProps = ReturnType<typeof mapCustomNodeStateToProps>;

export interface ICustomNodeOwnProps {
    nodeData: any;
}

export const mapCustomNodeStateToProps = (state: IStateType, props: ICustomNodeOwnProps) => {
    // const list = state.lists['alone'];
    const id = props.nodeData.id.replace(forLibPrefix, "");
    return {
        data: getOrgchartState(state).units[id],
        positions: getOrgchartState(state).positions,   
        draggingUnit: getOrgchartState(state).draggingUnit,
        // draggable: getOrgchartState(state).draggable,
        editable: getAuthUser(state).baseData.isAdmin && state.store.edit && state.store.editType === '',
        filterShowOnlyOpenVacancy: state.orgchart.showOnlyOpenVacancy

    };
};

export type ICustomNodeDispatchProps = ReturnType<typeof mapCustomNodeDispatchToProps> 

export const mapCustomNodeDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    getOrgchartDataFromServer,
    deleteStructureDataOrgchart,
    orgchartToggleDraggable,
    orgchartDragPosition,
    changeStructureDataOrgchart,
    saveStructureDataOrgchart,
    addSubUnit,
    orgchartSaveUsersInUnitAfterDrag,
    orgchartSaveAfterUnitPositionDrag,
    setOrgchartDraggingUnit,
    setOrgchartDraggingUnitToPosition,
    editSubUnit
}, dispatch)


export const cnCustomNode = cn('CustomNode');