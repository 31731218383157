import React from 'react';
import { IIdeaFieldProps } from './FieldProps';
import { Bool } from './Bool/Bool';
import { TextArea } from './Textarea/Textarea';
import { String } from './String/String';
import { Int } from './Int/Int';
import { UniqueIdentifier } from './UniqueIdentifier/UniqueIdentifier'
import { Tags } from './Tags/Tags';
import { Single } from './Single/Single';
import { Multi } from './Multi/Multi';
import { DateTime } from './DateTime/DateTime';
import { Date } from './Date/Date';
import { MatrixOnePerRow } from './MatrixOnePerRow/MatrixOnePerRow';
import { OfferResponsibleForStatusUsers } from './OfferResponsibleForStatusUsers/OfferResponsibleForStatusUsers';
import { ResponsibleForStatusUser } from './ResponsibleForStatusUser/ResponsibleForStatusUser';
import './Field.scss';

export const IdeaFieldView: React.FC<IIdeaFieldProps> = (props) => {
    switch (props.field.field.fieldType) {
        case 'Bool':
        case 'UserNotExpert':
            return <Bool {...props} />;
        case 'Textarea':
            return <TextArea {...props} />;
        case 'String':
            return <String {...props} />;
        case 'Int':
            return <Int {...props} />;
        case 'UniqueIdentifier':
            return <UniqueIdentifier {...props} />;
        case 'Tags':
            return <Tags {...props} />;
        case 'Single':
            return <Single {...props} />;
        case 'Multi':
            return <Multi {...props} />;
        case 'DateTime':
            return <DateTime {...props} />;
        case 'Date':
            return <Date {...props} />;
        case 'MatrixOnePerRow':
            return <MatrixOnePerRow {...props} />;
        case 'OfferResponsibleForStatusUsers':
            return <OfferResponsibleForStatusUsers {...props} />;
        case 'ResponsibleForStatusUser':
            return <ResponsibleForStatusUser {...props} />;

        default:
            return <span>Unknown field type: [{props.field.field.fieldType}]</span>;
    }
}