import React, { FC } from 'react'
import { IEditorTypeUserProps, mapStateToProps } from './user.index'

import * as utils from 'utils/src/utils';
import { ThanksNews, defaultThanksNews } from '../../../../Thanks/Thanks.type'
import { Editor } from '../../Editor'
import { ThanksValidator, thanksValidate } from '../../../../Thanks/Thanks.validate'
import { baseValidate, Rule } from '../../../../Base.validate'
import { EditorState, convertToRaw } from 'draft-js';
import { changeUsersModifier, changeReasonModifier } from '../../../../Thanks/Editor/modifiers'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { nameCount } from 'utils/src/utils'
import {
    useChangeCurrency,
    useChangeReasons,
    useLoadReasons,
    useChangeThanksUsers
} from '../../../../Thanks/hooks'

const vb = new ThanksValidator();

export const _EditorTypeUser: React.FC<IEditorTypeUserProps> = ({
    onChange: cbChange,
    data,
    maxThanksValue = 0,
    maxThanksArgumentsCount = 0,
    // thanksForAll = 0,
    typeParams
}) => {
    let localData = data as ThanksNews;
    const {
        onChangeCurrency,
        disableDown,
        disableUp,
        thanksCount,
        available,
        fullCount,
        fullCurrency,
        maxPerUser,
        maxThanks
    } = useChangeCurrency(localData as any, onChange as any)    

    if (data.newstype !== 4) {
        localData = {
            ...utils.cloneObject(defaultThanksNews), text: EditorState.createEmpty(), thanksForAll: fullCurrency
        }
    }

    const onChangeProcess = (data: any) => {
        // const maxThanks = (data.users.length * maxThanksValue) > thanksForAll ? thanksForAll : (data.users.length * maxThanksValue);
        const validThank: Rule[] = [
            // {
            //     type: 'number',
            //     method: 'NUM_MIN',
            //     field: 'thanksCount',
            //     value: 1,
            //     message: 'Недостаточно вложений'
            // },
            {
                type: 'number',
                method: 'NUM_MAX',
                field: 'thanksCount',
                value: data.users.length === 0 ? maxThanksValue : maxPerUser,//maxThanks,
                errorI18nKey: 'pryaniky.validate.news.thanks.thanksCount.max',
                // message: 'Вложено слишком много'
            },
            {
                type: 'array',
                method: 'ARR_MAX',
                field: 'rewardReasons',
                value: maxThanksArgumentsCount || 4,
                errorI18nKey: 'pryaniky.validate.news.thanks.rewardReasons.max',
                errorVariablesI18nKey: {
                    count: maxThanksArgumentsCount
                }
                // message: 'Слишком много причин для благодарности, максимум 4'
            },
            {
                type: 'array',
                method: 'ARR_MAX',
                field: 'users',
                value: (fullCurrency / data.thanksCount),//((data.users && data.users.length !== 0) ? data.users.length : 1),
                errorI18nKey: 'pryaniky.validate.news.thanks.users.max',
                // message: 'Выбранно слишком много пользователей'
            },
            {
                type: 'array',
                method: 'ARR_MIN',
                field: 'users',
                value: 1,
                errorI18nKey: 'pryaniky.validate.news.thanks.users.min',
                // message: 'Нужно выбрать кого поблагодарить'
            },
            {
                type: 'string',
                method: 'STR_MIN',
                field: 'text',
                value: typeParams?.thanksRenderParams?.minTextLength || 5,
                errorI18nKey: 'pryaniky.validate.news.thanks.text.min',
                errorVariablesI18nKey: {
                    count: typeParams?.thanksRenderParams?.minTextLength || 5,
                    variable: nameCount(typeParams?.thanksRenderParams?.minTextLength || 5, 'pryaniky.validate.string.variable')
                }
            }
        ]
        data.thanksForAll = fullCurrency
        data.usersCount = data.users.length

        // vb.setUseField()
        // vb.addField(iniciators)
        vb.addData(data)
        vb.setRules([...thanksValidate
            .filter(v => !(v.field === 'rewardReasons' && v.method === 'ARR_MAX'))
            .filter(v => !(v.field === 'text' && v.method === 'STR_MIN')),
        ...validThank])
        return {
            data,
            vb
        }
    }

    function onChange(v: ThanksNews) {
        const { data, vb } = onChangeProcess(v)
        cbChange({ ...data }, vb.validation().length === 0, vb.validation())
    }

    

    const onChangeUsers = (values: any) => {
        onChange(changeUsersModifier(values, localData));
    }
    const onChangeReason = (values: any) => {
        onChange(changeReasonModifier(values, localData));
    }

    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData)
    }

    // const onChangeThanks = (val: number) => {
    //     localData.thanksCount = val
    //     onChange(localData)
    // }
    return <Editor
        disableDown={disableDown}
        disableUp={disableUp}
        thanksCount={thanksCount}
        available={available}
        fullCount={fullCount}
        fullCurrency={fullCurrency}
        maxPerUser={maxPerUser}
        maxThanks={maxThanks}

        // maxThanksValue={maxThanksValue}
        maxThanksArgumentsCount={maxThanksArgumentsCount}
        // thanksForAll={thanksForAll}
        // maxUsers={thanksForAll}
        validation={vb.validation()}
        text={localData.text}
        reasons={localData.rewardReasons.map((v: any) => ({ value: v.id, label: v.name }))}
        // thanksCount={localData.thanksCount}
        users={localData.users}
        onChangeReason={onChangeReason}
        onChangeText={onChangeText}
        onChangeThanks={onChangeCurrency}//onChangeThanks}
        onChangeUsers={onChangeUsers} />
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch);
export const EditorTypeUser = connect<any, any>(
    mapStateToProps
)(_EditorTypeUser) as any;