import * as utils from 'utils/src/utils';
import { toast } from 'react-toastify';
import actions from '../actionsTypes/LMS';
import { action } from 'utils/src/requests/requests.news';
import uuid from 'uuid';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { routerActions } from 'connected-react-router';
import {
    coursesSchema,
    denormalizeData,
    normalizeData,
    singleCourseSchema,
    testResultShema,
    testSchema,
    unitsLogsSchema
} from 'LMSModule/utils/normalize';

const zeroId = "00000000-0000-0000-0000-000000000000";


//////////////////////////////////////////////////////////////////////////////////////////////////////

/********************************************************/
/******************** to saga ***************************/
/********************************************************/

/******************************* CLOSE_SESSION *******************************/
export type closeSessionPayloadType = { sid: string, courseId: string }
export type closeSessionActionType = { type: string, payload: closeSessionPayloadType }
/**
 * завершение сессии
 * @param sid 
 */
export const closeSession = (sid: string, courseId: string): closeSessionActionType => ({
    type: actions.CLOSE_SESSION,
    payload: { sid, courseId }
})


/******************************** TRANSITION_TO_UNIT *************************/
export type transitionToUnitPayloadType = { unitId: string, courseId: string }
export type transitionToUnitActionType = { type: string, payload: transitionToUnitPayloadType }
/**
 * новый экшен для перехода к юниту
 */
export const transitionToUnit = (unitId: string, courseId: string): transitionToUnitActionType => ({
    type: actions.TRANSITION_TO_UNIT,
    payload: { unitId, courseId }
})


/******************************** CLOSE_SESSION_ON_COMPLETE_MONDATORY *************************/
export type closeOnMandatoryPayloadType = { sid: string, courseId: string }
export type closeOnMandatoryActionType = { type: string, payload: closeOnMandatoryPayloadType }
/**
 * выходит из потока, если все обязательные юниты завершены
 */
export const closeSessionOnCompleteMondatory = (sid: string, courseId: string): closeOnMandatoryActionType => ({
    type: actions.CLOSE_SESSION_ON_COMPLETE_MONDATORY,
    payload: { sid, courseId }
})


/******************************** JOIN_TO_SESSION *************************/
export type joinToSessionPayloadType = { sid: string, cid: string }
export type joinToSessionActionType = { type: string, payload: joinToSessionPayloadType }
/**
 * активирует сессию для пользователя
 */
export const joinToSession = (sid: string, cid: string): joinToSessionActionType => ({
    type: actions.JOIN_TO_SESSION,
    payload: { sid, cid }
})


/******************************** LOAD_SINGLE_COURSE *************************/
export type loadCoursePayloadType = { cid: string, isArchieved?: boolean }
export type loadCourseActionType = { type: string, payload: loadCoursePayloadType }
/**
 * загружает указаный курс
 */
export const loadCourse = (cid: string, isArchieved: boolean = false): loadCourseActionType => ({
    type: actions.LOAD_SINGLE_COURSE,
    payload: { cid, isArchieved }
})


/******************************** SAVE_UNIT_RESULT *************************/
export type saveUnitResultPayloadType = { data: any, next: boolean, send: boolean }
export type saveUnitResultActionType = { type: string, payload: saveUnitResultPayloadType }
/**
 * 
 */
export const _saveUnitResult = (data: any, next: boolean = true, send: boolean = true): saveUnitResultActionType => ({
    type: actions.SAVE_UNIT_RESULT,
    payload: { data, next, send }
})


/******************************** SAVE_QUESTION_COMPLETE *************************/
export type saveQuestionCompletePayloadType = { qid: string, uid: string }
export type saveQuestionCompleteActionType = { type: string, payload: saveQuestionCompletePayloadType }
/**
 * 
 */
export const saveQuestionComplete = (qid: string, uid: string): saveQuestionCompleteActionType => ({
    type: actions.SAVE_QUESTION_COMPLETE,
    payload: { qid, uid }
})


/******************************** LOAD_USER_RESULTS *************************/
export type loadUserResultPayloadType = { cid: string, sid: string }
export type loadUserResultActionType = { type: string, payload: loadUserResultPayloadType }
/**
 * получить результаты юзера в указаной сессии, указаного курса
 */
export const loadUserResult = (cid: string, sid: string): loadUserResultActionType => ({
    type: actions.LOAD_USER_RESULTS,
    payload: { cid, sid }
})


/******************************** LOAD_USER_RESULTS *************************/
export type transitionToNextUnitPayloadType = { cid: string }
export type transitionToNextUnitActionType = { type: string, payload: transitionToNextUnitPayloadType }

export const transitionToNextUnit = (cid: string): transitionToNextUnitActionType => ({
    type: actions.TRANSITION_TO_NEXT_UNIT,
    payload: {
        cid
    }
})


/******************************** LOAD_COURSES_STATUS *************************/
export type loadloadCoursesStatusPayloadType = { userid?: string }
export type loadloadCoursesStatusActionType = { type: string, payload: loadloadCoursesStatusPayloadType }
/**
 * 
 */
export const loadCoursesStatus = (userid?: string): loadloadCoursesStatusActionType => ({
    type: actions.LOAD_COURSES_STATUS,
    payload: { userid }
})


/******************************** SCORM_UNIT_COMPLETE *************************/
export type scormUnitCompletePayloadType = { cid: string, uid: string }
export type scormUnitCompleteActionType = { type: string, payload: scormUnitCompletePayloadType }
/**
 * 
 */
export const scormUnitComplete = (cid: string, uid: string): scormUnitCompleteActionType => ({
    type: actions.SCORM_UNIT_COMPLETE,
    payload: { cid, uid }
})




/******************************** SAVE_SCORM_PARAM *************************/
export type scormUnitSaveParamPayloadType = {
    cid: string,
    skey: string,
    svalue: string,
    unitId: string,
    logId: string
}
export type scormUnitSaveParamActionType = { type: string, payload: scormUnitSaveParamPayloadType }
/**
 * 
 */
export const scormUnitSaveParam = (
    cid: string,
    skey: string,
    svalue: string,
    unitId: string,
    logId: string
): scormUnitSaveParamActionType => ({
    type: actions.SAVE_SCORM_PARAM,
    payload: { cid, skey, svalue, unitId, logId }
})


/******************************** SCORM_UNIT_SUCCESS_STATUS *************************/
export type scormUnitSuccessStatusPayloadType = { cid: string, uid: string }
export type scormUnitSuccessStatusActionType = { type: string, payload: scormUnitSuccessStatusPayloadType }
/**
 * 
 */
export const scormUnitSuccessStatus = (cid: string, uid: string): scormUnitSuccessStatusActionType => ({
    type: actions.SCORM_UNIT_SUCCESS_STATUS,
    payload: { cid, uid }
})


/******************************** LOAD_UNIT_CONTENT *************************/
export type loadFullUnitPayloadType = { uid: string, version?: string }
export type loadFullUnitActionType = { type: string, payload: loadFullUnitPayloadType }
/**
 * 
 */
export const loadFullUnit = (uid: string, version?: string): loadFullUnitActionType => ({
    type: actions.LOAD_UNIT_CONTENT,
    payload: { uid, version }
})


/******************************** TRANSITION_TO_QUESTION *************************/
export type transitionToQuestionPayloadType = { uid: string, qid?: string }
export type transitionToQuestionActionType = { type: string, payload: transitionToQuestionPayloadType }
/**
 * @param qid если не передать передать, то будет переход к следующему
 */
export const transitionToQuestion = (uid: string, qid?: string): transitionToQuestionActionType => ({
    type: actions.TRANSITION_TO_QUESTION,
    payload: { uid, qid }
})


/******************************** CONFIRM_QUESTION *************************/
export type confirmQuestionPayloadType = { qid: string, uid: string }
export type confirmQuestionActionType = { type: string, payload: confirmQuestionPayloadType }
/**
 * 
 */
export const confirmQuestion = (qid: string, uid: string): confirmQuestionActionType => ({
    type: actions.CONFIRM_QUESTION,
    payload: { qid, uid }
})


/******************************** SAVE_UNIT_TEST_COMPLETED *************************/
export type saveUnitTestCompletedPayloadType = { uid: string }
export type saveUnitTestCompletedActionType = { type: string, payload: saveUnitTestCompletedPayloadType }
/**
 * 
 */
export const saveUnitTestCompleted = (uid: string): saveUnitTestCompletedActionType => ({
    type: actions.SAVE_UNIT_TEST_COMPLETED,
    payload: { uid }
})


/*********************** SKIP_QUESTION ***********************************/
export type skipQuestionPayloadType = { uid: string, qid: string }
export type skipQuestionActionType = { type: string, payload: skipQuestionPayloadType }
/**
 *
 */
export const skipQuestion = (uid: string, qid: string): skipQuestionActionType => ({
    type: actions.SKIP_QUESTION,
    payload: { uid, qid }
})


/******************************** UPLOAD_ANSWER_FILE *************************/
export type uploadAnswerFilePayloadType = { aid: string, uid: string, file: any }
export type uploadAnswerFileActionType = { type: string, payload: uploadAnswerFilePayloadType }
/**
 * 
 */
export const uploadAnswerFile = (payload: uploadAnswerFilePayloadType): uploadAnswerFileActionType => ({
    type: actions.UPLOAD_ANSWER_FILE,
    payload
})






/***********************************************************/
/******************** to reducer ***************************/
/***********************************************************/


/******************************** EXIT_FROM_SESSION *************************/
export type exitFromSessionPayloadType = { sid: string, courseId: string }
export type exitFromSessionActionType = { type: string, payload: closeOnMandatoryPayloadType }
/**
 * переходим на просмотр результатов
 */
export const exitFromSession = (sid: string, courseId: string): exitFromSessionActionType => ({
    type: actions.EXIT_FROM_SESSION,
    payload: { sid, courseId }
})

/******************************** SET_CURRENT_UNIT *************************/
export type setCurrentUnitPayloadType = { sid: string, uid: string, isSkipped: boolean, view: string, cid: string }
export type setCurrentUnitActionType = { type: string, payload: setCurrentUnitPayloadType }
/**
 * пустановить текущий юнит.
 */
export const setCurrentUnit = (payload: setCurrentUnitPayloadType): setCurrentUnitActionType => ({
    type: actions.SET_CURRENT_UNIT,
    payload
})

/*********************** SET_COURSES_STATUS ***********************************/
export type setCoursesStatusPayloadType = { data: any[], isMandatory: boolean, uid?: string }
export type setCoursesStatusActionType = { type: string, payload: setCoursesStatusPayloadType }
/**
 * 
 */
export const setCoursesStatus = (data: any[], isMandatory: boolean, uid?: string): setCoursesStatusActionType => ({
    type: actions.SET_COURSES_STATUS,
    payload: {
        data, isMandatory, uid
    }
})

/*********************** READ_UNITS_CONTENT ***********************************/
export type readUnitPayloadType = { payload: any }
export type readUnitActionType = { type: string, payload: readUnitPayloadType }
/**
 *
 */
export const readUnit = (payload: any): readUnitActionType => ({
    type: actions.READ_UNITS_CONTENT,
    payload
})


/*********************** SET_CURRENT_QUESTION ***********************************/
export type setCurrentQuestionPayloadType = { uid: string, qid: string }
export type setCurrentQuestionActionType = { type: string, payload: setCurrentQuestionPayloadType }
/**
 *
 */
export const setCurrentQuestion = (uid: string, qid: string): setCurrentQuestionActionType => ({
    type: actions.SET_CURRENT_QUESTION,
    payload: {
        uid,
        qid
    }
})


/*********************** SET_QUESTION_COMPLETE ***********************************/
export type setQuestionCompletePayloadType = { uid: string, qid: string }
export type setQuestionCompleteActionType = { type: string, payload: setQuestionCompletePayloadType }
/**
 *
 */
export const setQuestionComplete = (uid: string, qid: string): setQuestionCompleteActionType => ({
    type: actions.SET_QUESTION_COMPLETE,
    payload: {
        uid,
        qid
    }
})


/*********************** SET_COMPLETED_TEST ***********************************/
export type setUnitTestCompletedPayloadType = { uid: string }
export type setUnitTestCompletedActionType = { type: string, payload: setUnitTestCompletedPayloadType }
/**
 *
 */
export const setUnitTestCompleted = (uid: string): setUnitTestCompletedActionType => ({
    type: actions.SET_COMPLETED_TEST,
    payload: {
        uid
    }
})


/*********************** SET_SKIP_QUESTION ***********************************/
export type setSkipQuestionPayloadType = { uid: string, qid: string }
export type setSkipQuestionActionType = { type: string, payload: setSkipQuestionPayloadType }
/**
 *
 */
export const setSkipQuestion = (uid: string, qid: string): setSkipQuestionActionType => ({
    type: actions.SET_SKIP_QUESTION,
    payload: { uid, qid }
})


/*********************** SET_SESSION_COMPLETE ***********************************/
export type setSessionCompletePayloadType = { sid: string }
export type setSessionCompletActionType = { type: string, payload: setSessionCompletePayloadType }
/**
 *
 */
export const setSessionCompletMondatory = (sid: string): setSessionCompletActionType => ({
    type: actions.SET_SESSION_COMPLETE_MONATORY,
    payload: { sid }
})


/******************************** ADD_ANSWER_FILE *************************/
export type addAnswerFilePayloadType = { aid: string, uid: string, file: any }
export type addAnswerFileActionType = { type: string, payload: addAnswerFilePayloadType }
/**
 * 
 */
export const addAnswerFile = (payload: addAnswerFilePayloadType): addAnswerFileActionType => ({
    type: actions.ADD_ANSWER_FILE,
    payload
})








export const readCategories = (payload: any) => ({
    type: actions.READ_CATEGORIES,
    payload
})

export const readCourses = (payload: any) => ({
    type: actions.READ_COURSES,
    payload
})

export const readCourse = (payload: any) => ({
    type: actions.READ_SINGLE_COURSE,
    payload
})



export const readResult = (sessionId: string, result: any) => ({
    type: actions.READ_SESSION_RESULT,
    payload: { sessionId, result }
})

export const setNextUnit = (payload: any) => ({
    type: actions.SET_NEXT_UNIT,
    payload
})

export const setSkipTest = (uid: string, unitLogId: string) => ({
    type: actions.SKIP_TEST,
    payload: {
        uid,
        unitLogId
    }
})

export const setSaveProcessing = (processing = false) => ({
    type: processing ? actions.SET_SAVE_PROCESSING_TRUE : actions.SET_SAVE_PROCESSING_FALSE
})

export const setNextQuestion = (payload: any) => ({
    type: actions.SET_NEXT_QUESTION,
    payload
})

// export const setQuestionComplete = (payload: any) => ({
//     type: actions.SET_QUESTION_COMPLETE,
//     payload
// })

// export const completeTest = (unitId: any, completeTime: any) => ({
//     type: actions.COMPLETE_TEST,
//     payload: {
//         uid: unitId,
//         completeTime
//     }
// })

/*export const _jumpToUnit = (unitId: any, courseId: any) => ({
    type: actions.JUMP_TO_UNIT,
    payload: {
        unitId,
        courseId
    }
})*/

export const startQuestion = (unitId: string, timerId: any, startTime: any, timeCount: any, timeoutId: any, toastTimeoutId: any) => ({
    type: actions.START_QUESTION,
    payload: {
        unitId,
        timerId,
        startTime,
        timeCount,
        timeoutId,
        toastTimeoutId
    }
})

export const setTestAttempts = (uid: string, newUnitLogId: string) => ({
    type: actions.USE_TEST_ATTEMPTS,
    payload: {
        uid,
        newUnitLogId
    }
})

export const changeTime = (unitId: string) => ({
    type: actions.CHANGE_TIME,
    payload: unitId
})

// export const skipQuestion = (uid: string, begineTime: any) => ({
//     type: actions.SKIP_QUESTION,
//     payload: { uid, begineTime }
// })

// export const jumpToQuestion = (uid: string, qid: string) => {
//     const begineTime = (new Date).getTime()
//     return {
//         type: actions.JUMP_TO_QUESTION,
//         payload: {
//             uid, qid, begineTime
//         }
//     }
// }

export const selectAnswer = ({ uid, aid, value = '' }: any) => ({
    type: actions.SELECT_ANSWER,
    payload: {
        uid, aid, value
    }
})

export const toggleSession = (courseId: string, sessionId: string, resetProgress: boolean = false) => ({
    type: actions.TOGGLE_SESSION,
    payload: {
        courseId, sessionId, resetProgress
    }
})

export const setUnitResult = (uid: string, ulid: string, data: any) => ({
    type: actions.SET_UNIT_RESULT,
    payload: {
        uid, ulid, data
    }
})

export const removeUnitResult = (uid: string) => ({
    type: actions.REMOVE_UNIT_RESULT,
    payload: {
        uid
    }
})

export const setLikeCourse = (cid: string) => ({
    type: actions.SET_LIKE_COURSE,
    payload: {
        cid
    }
})

export const setSessionTineout = (payload: any) => ({
    type: actions.SET_SESSION_TIMEOUT,
    payload
})


/************************************ CANCLE_SESSION ***************************************/
export type setCancleSessionPayloadType = { courseId: string, sid: string, absolute: boolean }
export type setCancleSessionActionType = { type: string, payload: setCancleSessionPayloadType }
/** 
 * закрытие сессии
*/
export const setCancleSession = (courseId: string, sid: string, absolute: boolean = false): setCancleSessionActionType => ({
    type: actions.CANCLE_SESSION,
    payload: {
        courseId, absolute, sid
    }
})

export const setViewSessionResult = (cid: string, sid: string) => ({
    type: actions.SET_VIEW_SESSION_RESULT,
    payload: {
        cid, sid
    }
})

export const setUnitLogs = (payload: any) => ({
    type: actions.SET_UNITS_LOGS,
    payload: payload
})

export const setSessionsLogs = (payload: any) => ({
    type: actions.SET_SESSIONS_LOGS,
    payload: payload
})

export const setUserSessionResult = (sid: string, result: any) => ({
    type: actions.SET_USER_SESSION_RESULT,
    payload: {
        sid, result
    }
})

export const updateUnitLog = (uid: string, payload: any) => ({
    type: actions.UPDATE_UNIT_LOG,
    payload: payload,
    uid
})

/////////////////////////////////////////////////////

/***********************************************************/
/******************** thanks ***************************/
/***********************************************************/


export const toPrevUnit = (cid: string, uid: string) => {
    return (dispatch: any, getState: any) => {
        const store: any = getState()
        const courseUnits = store.LMS.courses.entities.values[cid].courseUnits
        const targetUnitIndex = courseUnits.findIndex((unit: any) => uid === unit.id) - 1
        if (targetUnitIndex < 0) return;
        dispatch(transitionToUnit(courseUnits[targetUnitIndex].id, cid))
    };
}

export const loadCategories = () => {
    return (dispatch: any) => {
        const request = utils.API.lms.getMyCategories()
        request.r.then((d: any) => {
            if (d && d.error_code !== 0 && d.data) {
                switch (d.error_code) {
                    default: {
                        toast.error('loadCategories, unknown error:', d.error_code)
                        console.warn(`loadCategories, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0) {
                dispatch(readCategories({ ...normalizeData(d.data) }))
            } else {
                toast.error('loadCategories, internal error');
                console.warn(`loadCategories, internal error`)
            }
        });
    };
}


let loadCoursesRequest: any = null
export const loadCourses = (params: any, clear: boolean = false) => {
    return (dispatch: any, getState: any) => {
        // const request = utils.API.lms.getCoursesByCategory(cid)
        const store: any = getState()
        if (loadCoursesRequest) loadCoursesRequest.ac.abort();
        const skipCount = store.LMS.courses.result/*.filter((val: string) => val !== 'skelet')*/.length
        // const skeletCount = store.LMS.courses.result.filter((val: string) => val === 'skelet').length
        const paramWithCount = {
            ...params,
            count: params.count | store.LMS.count,
            skipCount: 0
        }
        if (!clear) {
            paramWithCount.count = params.count | store.LMS.count;
            paramWithCount.skipCount = skipCount
        }
        dispatch({ type: actions.SET_LOADING })
        // if (skipCount === 0) {
        //     dispatch(readCourses({ entities: { values: { skelet: { id: 'skelet' } } }, result: (new Array(4)).fill('skelet'), clear, sessions: {} }))
        // }
        const trimedSearch = (paramWithCount.search || '').trim()

        loadCoursesRequest = trimedSearch ?
            utils.API.lms.getCourseSearch(trimedSearch, { ...paramWithCount, search: undefined, mode: 0 }) :
            utils.API.lms.getMyCourses({ ...paramWithCount, search: undefined })

        loadCoursesRequest.r.then((d: any) => {
            dispatch({ type: actions.REMOVE_SKELETS })
            if (d && d.error_code && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        // toast.error('loadCourses, unknown error:', d.error_code)
                        // console.warn(`loadCourses, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {
                const norm: any = normalizeData(d.data)
                let sessions: any = {};
                norm.result.forEach((cid: string) => {
                    sessions = { ...sessions, ...norm.entities.values[cid].normalSessions }
                })
                dispatch(readCourses({ ...normalizeData(d.data), clear, sessions }))
            } else if (d && d.dom_error === 'AbortError') {
            } else {
                // toast.error('loadCourses, internal error');
                // console.warn(`loadCourses, internal error`)
            }
        });
    };
}

export const openCourse = (cid: string) => {
    return (dispatch: any, getState: any) => {
        if (loadCoursesRequest) loadCoursesRequest.ac.abort();
    }
}

/**
 * возвращает число завершенных юнитов
 * @param courseUnits массив коротких моделей юнитов
 * @param unitsLogs коллекция логов юнитов
 */
const getCompletedUnitsCount = (courseUnits: any[], unitsLogs: any) =>
    courseUnits.reduce((accumulator: number, unit: any) => accumulator + (unitsLogs[unit.id].isCompleted ? 1 : 0), 0);

export const rAttemptAfterComplete = (cid: string, sid: string, uid: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(toggleSession(cid, sid, false))
        utils.API.lms.getSession(sid).r.then((d: any) => {
            if (d && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        toast.error('rAttemptAfterComplete, unknown error:', d.error_code)
                        console.warn(`rAttemptAfterComplete, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {
                const normUnits = normalizeData(d.data.units, unitsLogsSchema)

                dispatch(setSessionsLogs({ [sid]: d.data.session }))
                dispatch(setUnitLogs(normUnits.entities.normalLogs))
                // dispatch(jumpToUnit(uid, cid))
                dispatch(transitionToUnit(uid, cid))
                // dispatch(setTestAttempts(uid, uuid()))
                // dispatch(routerActions.push(`/lms/test/${cid}`))
            } else {
                toast.error('rAttemptAfterComplete, internal error');
                console.warn(`rAttemptAfterComplete, internal error`)
            }

        })
    }
}

export const jumpToUnitResult = (cid: string, sid: string, uid: string | false) => {
    return (dispatch: any, getState: any) => {
        dispatch(routerActions.push(`?tab=results&sid=${sid}&unit=${uid ? uid : 'result'}`))
    }
}


export const sendLikeCourse = (cid: string) => {
    return (dispatch: any, getState: any) => {

        const store = getState();
        const newsId = store.LMS.courses.entities.values[cid].newsUid
        const liked = store.LMS.courses.entities.values[cid].isLiked
        dispatch(setLikeCourse(cid))
        utils.API.news.action(newsId, 22, !liked)
            .r
            .then((d: any) => {
                if (d && d.error_code !== 0) {
                    switch (d.error_code) {
                        default: {
                            toast.error('sendLikeCourse, unknown error:', d.error_code)
                            console.warn(`sendLikeCourse, err: ${d.error_code}, server text:`, d.error_text)
                        }
                    }
                } else if (d && d.error_code === 0 && d.data) {

                } else {
                    toast.error('sendLikeCourse, internal error');
                    console.warn(`sendLikeCourse, internal error`)
                }
            })
    }
}

export const DEPRECATED_loadUnitContent = (sid: string, uid: string, onlyLoad = false, version?: string) => {
    return (dispatch: any, getState: any) => {
        const store = getState();

        const request = utils.API.lms.getUnitContent(uid, version)
        request.r.then((d: any) => {
            if (d && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        toast.error('getUnitContent, unknown error:', d.error_code)
                        console.warn(`getUnitContent, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {
                if (d.data.unitType === 'test') {
                    d.data.questions = d.data.questions.map((val: any) => ({ ...val, selectedAnswers: [] }))
                    // d.data.isCompleted = false // <-- debug only
                    const normalQuestions = normalizeData(d.data.questions, testSchema)

                    dispatch(readUnit({
                        ...d.data,
                        questions: normalQuestions.result,
                        normalQuestions: normalQuestions.entities.values,
                        currentQuestion: zeroId,
                        unitVersion: version
                    }))
                    if (d.data.isCompleted && !onlyLoad) {
                        const lsID = d.data.learningSession.id
                        loadUnitResult(lsID, d.data.courseId, sid)(dispatch, getState)
                    }
                } else {
                    dispatch(readUnit({ ...d.data, unitVersion: version }))
                }
            } else {
                toast.error('getUnitContent, internal error');
                console.warn(`getUnitContent, internal error`)
            }
        });
    };
}

export const newTestAttempts = (uid: string, sid: string) => {
    return (dispatch: any, getState: any) => {
        const request = utils.API.lms.getUnitContent(uid)
        request.r.then((d: any) => {
            if (d && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        toast.error('getUnitContent, unknown error:', d.error_code)
                        console.warn(`getUnitContent, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {
                if (d.data.unitType === 'test') {
                    d.data.questions = d.data.questions.map((val: any) => ({ ...val, selectedAnswers: [] }))
                    // d.data.isCompleted = false // <-- debug only
                    const normalQuestions = normalizeData(d.data.questions, testSchema)
                    const newUnitLogId = uuid()
                    dispatch(readUnit({ ...d.data, questions: normalQuestions.result, normalQuestions: normalQuestions.entities.values, currentQuestion: zeroId }))
                    dispatch(setTestAttempts(uid, newUnitLogId))
                }
            } else {
                toast.error('getUnitContent, internal error');
                console.warn(`getUnitContent, internal error`)
            }
        });
    }
}


export const saveStartUnitResult = (data: any) => {
    return (dispatch: any, getState: any) => {
        const store: any = getState()
        if (store.LMS.saveInProcessing) return;
        // const nd = { ...data }
        dispatch(setSaveProcessing(true))
        const logID = store.LMS.sessionsLogs[data.courseSessionId].log.id;
        const unitLogId = store.LMS.unitsLogs[data.unitId].unitLogId === zeroId ? uuid() : store.LMS.unitsLogs[data.unitId].unitLogId;
        dispatch(updateUnitLog(data.unitId, { unitLogId }))
        const request = utils.API.lms.setStartTest(unitLogId, logID, data.unitId)
        request.r.then((d: any) => {
            dispatch(setSaveProcessing(false))
            if (d && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        toast.error('setStartTest, unknown error:', d.error_code)
                        console.warn(`setStartTest, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {
                let timerId: any = -1
                let timeoutId: any = -1
                let toastTimeoutId: any = -1
                const timeCount = store.LMS.units[data.unitId].maxTime * 1000;
                // if (store.LMS.units[data.unitId].maxTime !== 0) {
                if (false) {
                    timerId = setInterval(() => {
                        dispatch(changeTime(data.unitId))
                    }, 1000);
                    timeoutId = setTimeout(() => {
                        // const completeTime = (new Date).getTime()
                        // dispatch(completeTest(data.unitId, completeTime))
                        // sendCompleteUnitTest(data.unitId)(dispatch, getState)
                    }, timeCount);
                    const toastTime = timeCount > 60000 ? timeCount - 60000 : timeCount - 30000
                    toastTimeoutId = setTimeout(() => {
                        timeCount > 60000 ? toast.info('Осталось менее минуты!') : toast.info('Осталось менее 30 секунд!')
                    }, toastTime)
                }
                dispatch(startQuestion(data.unitId, timerId, (new Date()).getTime(), timeCount, timeoutId, toastTimeoutId))

            } else {
                toast.error('setStartTest, internal error');
                console.warn(`setStartTest, internal error`)
            }
        });
    };
}

export const sendQuestionComplete = (qid: string, uid: string) => {
    return (dispatch: any, getState: any) => {
        const store: any = getState()
        const completeTime = (new Date).getTime()
        const model = utils.API.lms.answerQuestionModelCreator()
        const timeForQuestion = completeTime - store.LMS.timers[uid].questions[qid].begin

        const unit = store.LMS.units[uid]
        // const lsID = unit.learningSession.id

        const selected = unit.normalQuestions[qid].selectedAnswers || []

        model.answers = selected.map((answerId: string) => ({
            answerId,
            value: unit.normalQuestions[qid].normalAnswers[answerId].value
        }))
        model.qid = qid
        model.time = timeForQuestion


        utils.API.lms.setQuestionAnswer(model).r.then((d: any) => {
            if (d && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        toast.error('setQuestionAnswer, unknown error:', d.error_code)
                        console.warn(`setQuestionAnswer, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {

            } else {
                toast.error('setQuestionAnswer, internal error');
                console.warn(`setQuestionAnswer, internal error`)
            }
        })
        // dispatch(setQuestionComplete({ qid, uid, completeTime }))
    }
}

// export const sendSkipQuestion = (uid: string) => {
//     return (dispatch: any, getState: any) => {
//         const begineTime = (new Date).getTime()
//         dispatch(skipQuestion(uid, begineTime))
//     }
// }

export const _sendNextQuestion = (question: any, uid: string, complete: boolean = false) => {
    return (dispatch: any, getState: any) => {
        const begineTime = (new Date).getTime()
        const store: any = getState()
        const qid = question.id
        const unitContent = store.LMS.units[uid]

        const emptySkiped = unitContent.skipedQuestions ? unitContent.skipedQuestions.length === 0 : true;
        const isReQuestion = unitContent.reQuestion
        const isLastSkiped = !emptySkiped ?
            unitContent.skipedQuestions.length === 1
            /*&& (unitContent.skipedQuestions.indexOf(question.id) !== -1)*/ : false
        const isLast = isReQuestion ? isLastSkiped : (question.isLastQuestion && emptySkiped)
        if (isLast) {
            sendCompleteTest(uid, question, unitContent.courseId, true)(dispatch, getState)
            return;
        }

        if (complete) {
            sendQuestionComplete(qid, uid)(dispatch, getState)
        }
        //dispatch(setQuestionComplete({ qid, uid, completeTime: begineTime }))
        dispatch(setNextQuestion({ qid, uid, begineTime }))
    }
}

export const loadUnitResult = (unitLogId: string, cid: string, sid: string) => {
    return (dispatch: any, getState: any) => {
        const currentUser = getAuthUser(getState())
        utils.API.lms.getUserResults(cid, currentUser.baseData.id, sid).r.then((d: any) => {
            if (d && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        toast.error('getUserResults, unknown error:', d.error_code)
                        console.warn(`getUserResults, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {
                const unitsResult: any = {};
                d.data[0].session.unitResults.forEach((val: any) => {
                    unitsResult[val.unitId] = val;
                })
                const result = { ...d.data[0], unitsResult }
                dispatch(setUserSessionResult(sid, result))
            } else {
                toast.error('getUserResults, internal error');
                console.warn(`getUserResults, internal error`)
            }
        })
    }
}

export const _sendCompleteUnitTest = (uid: string) => {
    return (dispatch: any, getState: any) => {
        const store: any = getState()

        dispatch(setSaveProcessing(true))
        dispatch(removeUnitResult(uid))
        // const answers: any[] = [];

        const unit = store.LMS.units[uid]
        const timer = store.LMS.timers[uid]
        const remTime = timer ? (timer.completeTime - timer.startTime) : 0
        const sId = store.LMS.courses.entities.values[unit.courseId].courseSessionId
        const lsID = store.LMS.unitsLogs[uid].unitLogId
        const cid = store.LMS.units[uid].courseId
        const toSend: any = {
            courseSessionId: sId,
            id: lsID,
            unitId: uid,
            isCompleted: true,
            results: []
        }
        const completeTime = (new Date).getTime()

        const nd = unit.questions.map((val: any) => {
            const selected = unit.normalQuestions[val].selectedAnswers || []

            return {
                qid: val,
                time: remTime,
                answers: selected.map((answerId: string) => ({
                    answerId,
                    value: unit.normalQuestions[val].normalAnswers[answerId].value
                }))
            }
        })
        toSend.results = nd;

        const request = utils.API.lms.saveUnitResult(toSend)
        request.r.then((d: any) => {
            if (d && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        toast.error('sendCompleteUnitTest, unknown error:', d.error_code)
                        console.warn(`sendCompleteUnitTest, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {
                const currentUser = getAuthUser(getState())
                utils.API.lms.getUserResults(cid, currentUser.baseData.id, sId).r.then((d: any) => {
                    if (d && d.error_code !== 0) {
                        switch (d.error_code) {
                            default: {
                                toast.error('getUserResults, unknown error:', d.error_code)
                                console.warn(`getUserResults, err: ${d.error_code}, server text:`, d.error_text)
                            }
                        }
                    } else if (d && d.error_code === 0 && d.data) {
                        const unitsResult: any = {};
                        d.data[0].session.unitResults.forEach((val: any) => {
                            unitsResult[val.unitId] = val;
                        })
                        const result = { ...d.data[0], unitsResult }
                        dispatch(setUserSessionResult(sId, result))
                        // dispatch(completeTest(uid, completeTime))
                        dispatch(setSaveProcessing(false))
                    } else {
                        toast.error('getUserResults, internal error');
                        console.warn(`getUserResults, internal error`)
                    }

                })
            } else {
                toast.error('sendCompleteUnitTest, internal error');
                console.warn(`sendCompleteUnitTest, internal error`)
            }
        });
    };
}
export const sendCompleteTest = (uid: any, question: any, cid: any, complete: boolean = false) => {
    return (dispatch: any, getState: any) => {
        const store: any = getState()
        if (store.LMS.saveInProcessing) return;
        const qid = question.id
        if (complete) sendQuestionComplete(qid, uid)(dispatch, getState)

        // sendCompleteUnitTest(uid)(dispatch, getState)
    };
}


//POST /ru/Data/v3/LMS/Course/Session/Unit/{logId}/{logUnitId}/Skip
export const saveSkipTest = (uid: string, sid: string) => {
    return (dispatch: any, getState: any) => {
        const store: any = getState()
        if (store.LMS.saveInProcessing) return;
        dispatch(setSaveProcessing(true))
        const logID = store.LMS.sessionsLogs[sid].log.id;
        // const unitLogId = store.LMS.unitsLogs[uid].unitLogId === zeroId ? uuid() : store.LMS.unitsLogs[uid].unitLogId;
        utils.API.lms.setSkipTest(logID, uid).r.then((d: any) => {
            dispatch(setSaveProcessing(false))
            // dispatch(setSkipTest(uid))
            if (d && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        toast.error('saveSkipTest, unknown error:', d.error_code)
                        console.warn(`saveSkipTest, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {
                const unitLogId = d.data
                dispatch(setSkipTest(uid, unitLogId))
            } else {
                toast.error('saveSkipTest, internal error');
                console.warn(`saveSkipTest, internal error`)
            }
        })
    }
}

/**
 * @deprecated используется только в lesson.tsx
 * @param data 
 * @param next 
 * @param send 
 */
export const saveUnitResult = (data: any, next: boolean = true, send: boolean = true) => {
    return (dispatch: any, getState: any) => {
        const store: any = getState()
        if (store.LMS.saveInProcessing) return;
        dispatch(setSaveProcessing(true))
        const unitLogId = store.LMS.unitsLogs[data.unitId].unitLogId === zeroId ? uuid() : store.LMS.unitsLogs[data.unitId].unitLogId;
        dispatch(updateUnitLog(data.unitId, { unitLogId }))
        const nd = { ...data, id: unitLogId }
        next && dispatch(setNextUnit({ courseId: data.courseId, }))
        if (send) {
            const request = utils.API.lms.saveUnitResult(nd)
            request.r.then((d: any) => {
                dispatch(setSaveProcessing(false))
                if (d && d.error_code !== 0) {
                    switch (d.error_code) {
                        default: {
                            toast.error('saveUnitResult, unknown error:', d.error_code)
                            console.warn(`saveUnitResult, err: ${d.error_code}, server text:`, d.error_text)
                        }
                    }
                } else if (d && d.error_code === 0 && d.data) {
                } else {
                    toast.error('saveUnitResult, internal error');
                    console.warn(`saveUnitResult, internal error`)
                }
            });
        } else {
            dispatch(setSaveProcessing(false))
        }
    };
}

export const sendResult = (uid: any, sId: any, cid: any) => {
    return (dispatch: any, getState: any) => {
        const store: any = getState()
        if (store.LMS.saveInProcessing) return;
        dispatch(setSaveProcessing(true))

        // const answers: any[] = [];
        // const lodId = store.LMS.courses.entities.values[cid].normalSessions[sId].log.id
        const lsID = store.LMS.units[uid].learningSession.id
        const toSend: any = {
            courseSessionId: sId,
            id: lsID,
            unitId: uid,
            results: []
        }
        const nd = store.LMS.units[uid].questions.map((val: any) => {
            const selected = store.LMS.units[uid].normalQuestions[val].selectedAnswers || []

            return {
                qid: val,
                time: 0,
                answers: selected.map((answerId: string) => ({
                    answerId,
                    value: store.LMS.units[uid].normalQuestions[val].normalAnswers[answerId].value
                }))
            }
        })
        toSend.results = nd;
        const request = utils.API.lms.saveUnitResult(toSend)
        request.r.then((d: any) => {
            dispatch(setSaveProcessing(false))
            if (d && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        toast.error('sendResult, unknown error:', d.error_code)
                        console.warn(`sendResult, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {
                dispatch(loadUnitResult('', cid, sId))
            } else {
                toast.error('sendResult, internal error');
                console.warn(`sendResult, internal error`)
            }

        });
    };
}

export const compliteSession = (sid: string) => {
    return (dispatch: any) => {
        const request = utils.API.lms.sessionComplete(sid)
        request.r.then((d: any) => {
            if (d && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        toast.error('compliteSession, unknown error:', d.error_code)
                        console.warn(`compliteSession, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {
            } else {
                toast.error('compliteSession, internal error');
                console.warn(`compliteSession, internal error`)
            }
        });
    }
}
