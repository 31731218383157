
// import React, { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
    addFile
} from '../../redux/actions/TracksManage'
import actionsTypes from '../../redux/actionsTypes/TracksManage'
import { useIsLoading, useIsError } from 'utils/src/CommonRedux/LoadIndicator'

export const useAddFile = (tagId: string, type: string) => {
    const isLoading = useIsLoading([actionsTypes.ADD_FILE])
    const { isFailed, errors } = useIsError([actionsTypes.ADD_FILE])
    const dispatch = useDispatch()

    const onAddFile = (uid: string) => {
        dispatch(addFile({
            tagId,
            type,
            uid
        }))
    }

    return [
        isLoading,
        onAddFile,
        { isFailed, errors }
    ] as const
}