import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IUnitResultProps extends IClassNameProps  {
    tag?: 'div';
    uid: string;
    sid: string;
    course: any;
}

export const cnUnitResult = cn('LMSUnitResult');