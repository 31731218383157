import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    setHeight,
    setRendered,
    setNewsIsReaded
} from '../../redux/actions'

import {
} from '../../redux/reducer/types'
import {
    getNewsHeight,
    isNeedRendered,
    getNewsIsReaded
} from '../../redux/saga/selectors'
import { NewsContextProps } from '../../contexts/news'
import { IComponentAcceptor } from 'utils/src/ComponentInjector'
import React, { createContext, ComponentType, FC, memo, useContext } from 'react';

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsView {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & IComponentAcceptor

        export interface IState { }

        export interface IOwnProps extends IClassNameProps, NewsContextProps {
            children?: React.ReactNode
            ViewRender?: ComponentType<{}> | undefined
            viewRenderProps?: any
        }

        export interface IStateProps {
            height?: number
            needRendered: boolean
            readed: boolean
        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.NewsView.IOwnProps

export const mapStateToProps = (state: any, {newsId}: IOwnProps) => ({
    height: getNewsHeight(newsId)(state),
    needRendered: isNeedRendered(newsId)(state),
    readed: getNewsIsReaded(state).includes(newsId)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    setHeight,
    setRendered,
    setNewsIsReaded
}, dispatch);

////////////

export const cnClassName = cn('NewsView');