interface IModelClientServerDiff {
  gId: string;
  uId: string;
  nId: string;
  bId: string;
  rId: string;
  cId: string;
  aId: string;
  [s: string]: any
}

export const modelClientServerDiff: IModelClientServerDiff = {
  gId: 'groupId', // group id
  uId: 'userId', // user id
  nId: 'newsId', // news id
  bId: 'badgeId', // badge id
  rId: 'ratingId', // rating id
  cId: 'replyId', // comment id
  aId: 'answerId', // answer id
}

export const modelServerClientDiff: {[s: string]: any} = Object.keys(modelClientServerDiff).reduce((acc, key) => Object.assign(acc, { [(modelClientServerDiff as { [s: string]: any })[key]]: key }), {});

export interface IWidgetSettings {
  gId?: number; // group id
  uId?: string; // user id
  nId?: string; // news id
  bId?: string; // badge id
  rId?: string; // rating id
  filter?: {[s: string]: any};
  [s: string]: any;
}