import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, cnNetworkSelector, INetworkSelectorProps, mapDispatchToProps } from './NetworkSelector.index';
import { Item } from './Item/Item';

export const NetworkSelectorPresenter: React.FC<INetworkSelectorProps> = ({ className, networks, baseNetwork, currentNetworkId }) => {
    return (
        <ul className={cnNetworkSelector({}, [className])} style={{
            listStyle: 'none',
            padding: 0
        }}>
            {networks.map((network) => (
                <Item
                    key={network.id}
                    network={network} 
                    isBase={baseNetwork.id === network.id} 
                    isCurrent={currentNetworkId === network.id}
                />
            ))}
        </ul>
    );
};

export const NetworkSelector = connect(
    mapStateToProps,
    mapDispatchToProps
)(NetworkSelectorPresenter);
