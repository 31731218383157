import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
// import { saveSkipTest } from "../../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
export interface ITestListProps extends IClassNameProps {
    units: any[]
    unitsResult: any
}

export const cnTestList = cn('ResultTestList');