import * as React from 'react';
import { IEditorTypeEditProps, mapStateToProps } from './edit.index';
import { RepostNews } from '../../../Repost.type'
import { Editor } from '../../Editor'
import { BaseValidator, baseValidate } from '../../../../Base.validate'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

export const _EditorTypeEdit: React.FC<IEditorTypeEditProps & any> = ({ data, onChange: cbChange, userData }) => {
    let localData = { ...data };

    const onChange = (data: any) => {
        const vb = new BaseValidator<any>()
        vb.addData(data)
        vb.setRules(baseValidate)
        cbChange(data, vb.validation().length === 0)
    }

    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData);
    }
    const onChangeGroup = (group: any[]) => {
        localData.group = group.length === 0 ? undefined : {
            pkid: group[0].id,
            imgId: group[0].imgId,
            imgUrl: group[0].imgUrl,
            name: group[0].displayName,
            displayName: group[0].displayName
        };;
        onChange(localData);
    }

    return <Editor
        networks={userData.baseData.networks}
        data={localData}
        // onChangeNetwork=
        // onChangeGroup={onChangeGroup}
        onChangeText={onChangeText}
    />

}
export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch);
export const EditorTypeEdit = connect<any, any>(
    mapStateToProps,
    mapDispatchToProps
)(_EditorTypeEdit) as any;