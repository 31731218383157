import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    IPresenterProps
} from './interfaces'
import './style.scss'
import { Header } from '../Header/Header'
import BodyRenderer from '../TestResultBody'
import { tC } from 'LMSModule/utils/i18n.adaptor'
import { QuestionList } from '../QuestionList/QuestionList'
import { isAttmptsAvailable, isAllowShow } from 'LMSModule/utils/result.utils'
import {
    createAttemptOptions,
    cretateAnswersMap,
    useSetAttepmt,
    getNotModerateTextAnswersCount,
    resultsAvaibled,
    getAttepmtQuestionsIds
} from 'LMSModule/utils/result.utils'
import { Select } from 'uielements/src'

export const Presenter: FC<IPresenterProps> = ({
    className,
    // unitTimer,
    unitContent,
    // sId,
    // unit,
    unitResult,
    disableActions = false,
    isAbsolutelyCompleted = false,
    attempt,
    cangeAttempt,
    fullscreen = false,
    detailsRender,
    actions
}) => {
    // const remainingTime = unitTimer && new Date(unitTimer.completeTime - unitTimer.startTime)
    const attemptNumber = unitResult.results.length
    const [resultAnswers, resultAnswersMap] = cretateAnswersMap(unitResult.results[attempt.value].attempt)
    const options = createAttemptOptions(unitResult.results.length)
    const attmptsAvailable = isAttmptsAvailable(attemptNumber, unitContent.maxAttemptsCount)
    const noResult = resultsAvaibled(unitResult, attempt)
    const questions = getAttepmtQuestionsIds(unitResult, attempt)
    const allowShow = isAllowShow(questions, unitContent.normalQuestions, unitContent.highlightCorrectness, attmptsAvailable, isAbsolutelyCompleted)
    
    return <div className={cnClassName({ fullscreen })}>
        <Header
            className={cnClassName('Header')}
            // titleText={'TaskResult'}
            // attemptNumber={unitResult.results.length}
            // maxAttemptsCount={unit.maxAttemptsCount}
            // remainingTime={remainingTime} 
            />

        <>
            <Select className={cnClassName('Select')} options={options} selected={attempt} onChange={cangeAttempt} />

            <QuestionList
                allowShow={allowShow}
                attmptsAvailable={attmptsAvailable}
                detailsRender={detailsRender}
                highlightCorrectness={unitContent.highlightCorrectness}
                className={cnClassName('QuestionList')}
                normalQuestions={unitContent.normalQuestions}
                // questionsTimers={unitTimer && unitTimer.questions}
                resultAnswersMap={resultAnswersMap}
                resultAnswers={resultAnswers}
                questions={questions} />
        </>

        {(!disableActions) && actions}
    </div>
}

export default memo(Presenter);
