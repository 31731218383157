import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { joinToSession } from "../../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
import { getSessionStatus, ESessionStatus} from '../../../redux/sagas/LMS/selectors'
export interface ITestProps extends IClassNameProps, IThreadStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
    // sId: string;
    // unit: any;
    // save: (answer: any) => void
    // unitContent: any
}

export interface ITestState {
    // courses: ICourse[];
    // isFinished: boolean;
}

export interface IThreadStateProps {
    // store: any
    // unitContent: any
    // question: any
}

export const mapStateToProps = (state: any, props: any) => ({
    unitsLogs: state.LMS.unitsLogs,
    units: state.LMS.courses.entities.values[props.cid].courseUnits,
    status: getSessionStatus(props.cid, props.sid)(state)
    // store: state.store,
    // unitContent: state.LMS.units[props.unit.id],
    // question: state.LMS.units[props.unit.id].normalQuestions && state.LMS.units[props.unit.id].normalQuestions[props.qId]
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    joinToSession
}, dispatch);

export const cnThread = cn('Thread');