import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { changeSlide, incrementSlideItem, sliderInit, mouseOver } from 'redux/actions/Slider'
import { bindActionCreators } from 'redux'
import { IDispatchProps } from '../../redux/connector';
import { IStateType as IState } from 'redux/store';
import { ISliderItemData } from 'utils/src/requests/models/api.slider'

export interface ISliderLinkProps extends IClassNameProps, IDispatchProps, ISliderLinkStateProps, ISliderLinkDispatchProps, ISliderItemData {
  tag?: React.ComponentType | 'div';
  context: any;
  wdata: ISliderItemData[];
}

export type ISliderLinkStateProps = ReturnType<typeof mapStateToProps>
export type ISliderLinkDispatchProps = ReturnType<typeof mapDispatchToProps>

export interface ISliderLinkState {
  text?: string;
}

export interface ISliderLinkOwnProps {}

export const mapStateToProps = (state: IState, props: any) => {
  const data = state.sliderLink[props.id || 'alone'];
  return {
    data: data && data.data as ISliderItemData[] || [], 
    imageNum: data && data.imageNum || 0,
    id: props.id as string,
    isMouseOver: data && data.mouseOver
    // mouseOver: data && data.mouseOver as boolean 
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  changeSlide,
  incrementSlideItem,
  sliderInit,
  mouseOver,
}, dispatch);

export const cnSliderLink = cn('SliderLink');