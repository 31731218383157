import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
  contextAction
} from '../../redux/actions'
import {
} from '../../redux/reducer/types'
import {
    getNewsParamsById
} from '../../redux/saga/selectors'
import { NewsContextProps } from '../../contexts/news'
import { IComponentAcceptor } from 'utils/src/ComponentInjector'
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace ContextMenu {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps, NewsContextProps, IComponentAcceptor {
            showAlways?: boolean

        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.ContextMenu.IOwnProps

const newsParams = [
    'id',
    'actions'
] as const

export const mapStateToProps = (state: any, { newsId }: IOwnProps) => ({
    ...getNewsParamsById(newsId, newsParams)(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    contextAction
}, dispatch);

////////////

export const cnClassName = cn('NewsServiceActions');