import React, { FC, useState, useEffect, createRef } from 'react';
import { IBadgeWidgetProps } from './BadgeWidget.index'
import './BadgeWidget.scss'
// import { Badge } from './Badge/Badge'
import { BadgeIcon } from 'uielements/src/BadgeIcon/BadgeIcon'
import { Button, Spinner } from 'uielements'
// import { Button, Spinner } from 'uielements/src'
import { ListPlaceholder } from 'uielements/src';
import feedPlaceholder from 'assets/svg/feedPlaceholder.svg';
import { Translate } from 'localizations/Translate';
import { useHistory } from "react-router-dom";
import { cn } from '@bem-react/classname';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import noData from 'assets/svg/NoData.svg'
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { sendSignal } from 'utils/src/signals';
import { useDidUpdateEffect } from 'utils/src/hooks';

const cnBadgeWidget = cn('BadgeWidget');

export const BadgeWidget: FC<IBadgeWidgetProps> = (props) => {
    const { uid, isLoading, isComplite, loadBadges, cats, catsList, badges, sortedBadgesList, removeBadge, settings, tag: Tag = 'div', widgetId } = props;
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const createClickBadge = (bid: string) => () => {
        history.push("/badge/" + bid);
    }

    useDidUpdateEffect(() => {
        if(widgetId) {
            sendSignal(`${widgetId}/open`, [`${widgetId}/open`, open])
        }
    }, [open]);

    const lastRef = createRef<HTMLDivElement>();

    const [countLastInline, setCountLastInline] = useState(sortedBadgesList.length);

    useEffect(() => {
        setCountLastInline(sortedBadgesList.length);
    }, [sortedBadgesList.length]);

    const resizeRecount = () => {
        if (!lastRef.current) return;
        const width = lastRef.current.clientWidth;
        const itemWidth = lastRef.current.children[0]?.clientWidth || 0;
        if (!itemWidth) return;
        const count = Math.floor(width / itemWidth);
        if (count !== countLastInline && count <= sortedBadgesList.length) setCountLastInline(count);
    };

    useEffect(() => {
        resizeRecount();
        window.addEventListener("resize", resizeRecount, false);
        return () => {
            window.removeEventListener("resize", resizeRecount, false);
        }
    }, [lastRef]);

    useEffect(() => {
        // !isComplite && 
        loadBadges(uid)
    }, [uid]);

    if (isLoading) return <Tag className={'BadgeWidget'}><Spinner /></Tag>

    const remove = (bid: string) => {
        removeBadge && removeBadge(uid, bid)
    }
    if (settings?.hideIfNoData && sortedBadgesList.length === 0) return <Tag {...props} hide={props.edit} className={'BadgeWidget'}/>
    // if (settings?.hideIfNoData && sortedBadgesList.length === 0) return <Tag {...props} hide={true} className={'BadgeWidget'}/>
    if (!settings?.hideIfNoData && sortedBadgesList.length === 0) return <Tag {...props} className={'BadgeWidget'}>

        <div className={'BadgeWidget-noData'}>
            <h4 className={'BadgeWidget-Header-Title'}>
                <Translate i18nKey={'pryaniky.badge.widget.nobadges'} />
            </h4>
            <Avatar precentage={100} shape={'rectangle'}
                imgUrl={noData}
            />
            <div className={'BadgeWidget-Header-Text'}>
                <Translate i18nKey={'pryaniky.placeholder.widget.nodata'} />
            </div>
        </div>
    </Tag>

    return <Tag {...props} className={cnBadgeWidget()}>
        <div className={cnBadgeWidget('Bages')}>
            <div className={cnBadgeWidget('Latest')}>
                <h4 className={cnBadgeWidget('LatestTitle')}>
                    <Translate i18nKey={'pryaniky.badge.widget.latestTitle'} />
                </h4>
                <div ref={lastRef} className={cnBadgeWidget('LatestList')}>
                    {sortedBadgesList.filter((_, idx) => idx < countLastInline).map(value => <BadgeIcon
                        className={cnBadgeWidget('BadgeIcon')}
                        key={value}
                        onClick={createClickBadge(badges[value].badgeUid)}
                        badgeImgUrl={badges[value].badgeImgUrl}
                        badgeUid={badges[value].badgeUid}
                        description={badges[value].description}
                        imgId={badges[value].imgId}
                        name={badges[value].name}
                        count={badges[value].count}
                        showName
                        removeBadge={removeBadge && remove}
                    />)}
                </div>
            </div>
            {open && <div className={cnBadgeWidget('All')}>
                {catsList.map(catId => <div className={cnBadgeWidget('List')}>
                    {catId === ''
                        ? <h4 className={cnBadgeWidget('CatTitle')}>
                            <Translate i18nKey={'pryaniky.badge.widget.catTitle'} />
                        </h4>
                        : <h4 className={cnBadgeWidget('CatTitle')}>{catId}</h4>}
                    <div className={cnBadgeWidget('CatBadges')}>{cats[catId].map((value: any) => <BadgeIcon
                        className={cnBadgeWidget('BadgeIcon')}
                        key={value}
                        onClick={createClickBadge(badges[value].badgeUid)}
                        badgeImgUrl={badges[value].badgeImgUrl}
                        badgeUid={badges[value].badgeUid}
                        description={badges[value].description}
                        imgId={badges[value].imgId}
                        name={badges[value].name}
                        count={badges[value].count}
                        showName
                        removeBadge={removeBadge && remove}
                    />
                    )}</div>
                </div>)}
            </div>}
        </div>
        <div className={cnBadgeWidget('ShowBox')}>
            <Button
                className={cnBadgeWidget('ShowBtn')}
                main
                onClick={() => {
                    setOpen(!open);
                }}>
                {
                    open ? <ChevronLeft /> : <ChevronRight />
                    // <Translate i18nKey={'pryaniky.badge.widget.hide'} /> :
                    // <Translate i18nKey={'pryaniky.badge.widget.show'} />
                }
            </Button>
        </div>
    </Tag>
}