import React, { FC, useState, useEffect } from 'react'
import { ISingleAutocompleteProps } from 'uielements/src/MaterialElements/SingleAutocomplete';
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
import CourseTabs, { ITabType } from '../../../../LMSModule/components/Tabs'
import * as utils from 'utils/src/utils';
import { useDebounce } from 'utils/src/hooks'
import { cn } from '@bem-react/classname';
import './style.scss'

const cnSessionSelectize = cn('SessionSelectize');

interface IWithSessionProps {
    label?: string
    courseId: string;
    defaultElements?: ISelectizeItem[]
    onSelect: (item: ISelectizeItem | null) => void
    archived?: boolean
    error?: boolean
}

const noopArray: ISelectizeItem[] = []

const withSessionSuggester = (Component: React.ComponentType<ISingleAutocompleteProps>): FC<IWithSessionProps> =>
    ({ courseId, defaultElements = noopArray, onSelect, label, archived = false, error = false }) => {
        const [query, setQuery] = useState<string>('')
        const [elements, setElements] = useState<ISelectizeItem[]>(defaultElements)
        const [loading, setLoading] = useState<boolean>(false)

        useEffect(() => {
            elements.length === 0 && setElements(defaultElements)
        }, [defaultElements])

        const sendRequest = (value: string) => {
            setQuery(value)
            // if (value === '') return;
            setLoading(true)
            const request = utils.API.lms.sessionSuggester(courseId, value, archived);
            request.r.then((d: any) => {
                if (utils.checkResponseStatus(d)) {
                    setElements(d.data.map((item: any) => ({
                        value: item.id,
                        title: item.displayName,
                        id: item.id
                    })));
                }
                setLoading(false)
            });
        }

        const changeQuery = useDebounce(sendRequest, 1000, [courseId, archived])

        return <Component
            label={label}
            loading={loading}
            onChangeValue={changeQuery}
            options={elements}
            onSelect={onSelect}
            error={error}
        />
    }

export default withSessionSuggester