import { cnInfoBlock } from '../InfoBlock.index';

import { IInfoProps } from '../AbstarctInfoElement/AbstractInfoElement.index';

export interface IRatingProps extends IInfoProps {
  rId: 'string';
}

export interface IRatingState {
  text?: string;
}

export interface IRatingStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})

export const cnRating = (...data: any) => {
  let name = 'Rating';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnInfoBlock(name, ...data)
}

export const cnInfo = cnInfoBlock;