import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
// import { loadCourses, sendLikeCourse } from "../../../redux/actions/LMS"
import {
    sendLikeNews
} from 'News/redux/actions'
import {
    getNewsParamsById,
    getNewsById
} from 'News/redux/saga/selectors'
import { RouteComponentProps } from 'react-router';
export interface ILikeActionProps extends IClassNameProps, ILikeActionStateProps, IDispatchProps, RouteComponentProps, ILikeActionDispatchProps {
    tag?: 'div';
    nid: string;
}

export interface ILikeActionState {
}

export interface ILikeActionStateProps {

}

export const mapStateToProps = (state: any, props: any) => {
    if (!getNewsById(props.nid)(state))
        return {
            likesCount: 0,
            isLiked: false,
            noLoad: true
        }
    const params = getNewsParamsById(props.nid, 'likeCount', 'liked')(state)
    return {
        likesCount: params.likeCount,//state.news.news[props.nid].likeCount,
        isLiked: params.liked,//state.news.news[props.nid].liked,
        noLoad: false
    }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    // loadCourses, 
    sendLikeNews
}, dispatch);

export type ILikeActionDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnLikeAction = cn('LikeAction');