import React, { useEffect, useState } from 'react';
import './Widget_type_hierarchyNew.scss';
import * as utils from 'utils/src/utils';
import { useSelector } from 'react-redux';
import i18n from 'localizations/i18n';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { Paper, Typography } from 'muicomponents/src';
import { AvatarsTooltipList } from 'muicomponents/src/AvatarsTooltipList/AvatarsTooltipList';
import { IUsersHierarchyWidgetProps, cnHierarchyWidgetNew } from './Widget_type_hierarchyNew.index';
import { WidgetTypeSkeletonMUI } from 'blocks/Widget/_type/Widget_type_skeletonMUI';
import { Translate } from 'localization';
import { PaperBox } from 'blocks/InfoBlock/UserNew/Avatar/styled';
import CardMedia from '@mui/material/CardMedia';
import EmptyWidget from 'assets/svg/EmptyWidget.svg';
import * as utilsProj from 'utils.project/utils.project';
import { Structure } from './Components/Structure';
import { ContentItemsList } from './Components/ContentItemList';

export const HierarchyWidgetNew = ({ countOfVisibleItemsInWidget = 2, ...props }: IUsersHierarchyWidgetProps) => {
    const { context, settings, tag: TagName = 'div' } = props;
    const authUser: any = useSelector(getAuthUser);

    const uId = context.uId || authUser.profileData.userId;
    const headOrSub = settings?.headusers ? 'headusers' : 'subusers';
    const head = settings?.headusers;
    const hierarchyName = settings?.hierarchy.name;

    const [items, setItems] = useState<any>([]);
    const [title, setTitle] = useState(i18n.t('pryaniky.user.settings.hierarchy.' + (head ? 'Head' : 'Sub')));
    const [loading, setLoading] = useState(false);

    const getData = (uId: string) => {
        settings?.hierarchy && setLoading(true);
        utils.API.hierarchy
            .getHierarchyByUserForWidget(0, 20, { selectedHeadUser: uId, hierarchyName, catfilter: headOrSub }) // { selectedHeadUser: uId, hierarchyName: this.props.settings?.hierarchy.name, catfilter: this.headOrSub }
            .r.then((d: any) => {
                if (utils.checkResponseStatus(d)) {
                    const items = d.data.map((user: { [s: string]: any }) => {
                        user.title = user.displayName;
                        user.url = '/user/' + user.id;
                        if (utils.isEmptyGuid(user.imgId)) user.imgUrl = user.userPhotoUrl;
                        return user;
                    });
                    setItems(items);
                    if (head) {
                        setTitle(
                            settings?.hierarchy.headname
                                ? settings?.hierarchy.headname
                                : i18n.t('pryaniky.hierarchy.head')
                        );
                    } else {
                        setTitle(
                            settings?.hierarchy.subname
                                ? settings?.hierarchy.subname
                                : i18n.t('pryaniky.hierarchy.bottom')
                        );
                    }
                    setLoading(false);
                } else if (d.error_code === 21002) {
                    setLoading(false);
                }
            });
    };

    useEffect(() => {
        getData(uId);
    }, [uId]);

    const onDelete = (item: any) => {
        const topId = head ? item.id : context.uId || context.currentUserId;
        const bottomId = head ? context.uId || context.currentUserId : item.id;
        utilsProj.confirm({
            text: i18n.t('pryaniky.hierarchy.confirm.delete'),
            onConfirm: () => {
                utils.API.hierarchy.changeHierarchyAction(settings?.hierarchy.name, topId, bottomId, false);
                setItems(items.filter((val: any) => val.id !== item.id));
            },
        });
    };

    if (!settings || !hierarchyName) return null;
    if (loading) return <WidgetTypeSkeletonMUI />;
    if ((!items || !items.length) && !settings?.hideIfNoData && !authUser.baseData.isAdmin)
        return (
            <TagName {...props} className={cnHierarchyWidgetNew({})}>
                <PaperBox sx={{ margin: 0 }}>
                    <Typography fontWeight="700" className={cnHierarchyWidgetNew('Title')}>
                        {title}
                    </Typography>
                    <CardMedia
                        component="svg"
                        className={cnHierarchyWidgetNew('EmptyList-Avatar')}
                        sx={{
                            width: '100%',
                            height: '200px',
                            marginBottom: '12px',
                        }}
                        image={EmptyWidget}
                    />
                    <Typography>
                        <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
                    </Typography>
                </PaperBox>
            </TagName>
        );
    if ((!items || !items.length) && settings?.hideIfNoData && !authUser.baseData.isAdmin)
        return (
            <TagName
                {...{ ...props, isHidden: items.length === 0 || !items.length }}
                className={cnHierarchyWidgetNew({})}
            ></TagName>
        );
    return (
        <TagName {...props} className={cnHierarchyWidgetNew({})}>
            <Paper sx={{ padding: '24px' }}>
                <Structure {...props} items={items} setItems={setItems} title={title}>
                    {items.length > 0 && settings.hierarcheListView ? (
                        <ContentItemsList
                            {...props}
                            items={items}
                            setItems={setItems}
                            onDelete={onDelete}
                            countOfVisibleItemsInWidget={countOfVisibleItemsInWidget}
                            title={title}
                        />
                    ) : (
                        <AvatarsTooltipList
                            items={items}
                            count={6}
                            size={40}
                            onDelete={authUser.baseData.isAdmin && hierarchyName ? onDelete : undefined}
                        />
                    )}
                </Structure>
            </Paper>
        </TagName>
    );
};
