import { likeWikiRequest } from "../../../../redux/actions/WikiList";
import { bindActionCreators } from 'redux';
import { IWikiItem } from 'utils/src/requests/models/api.wikilist';
import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import {
    getNewsById
} from 'News/redux/saga/selectors'
import { PageModelList, PageTypes } from "utils/src";
import { ContextMenu } from '../../ContextMenu/ContextMenu';
import { ComponentProps } from "react";

export interface IWikiItemProps extends IItemStateProps, IWikiItemOwnProps, IWikiDispatchProps, Pick<ComponentProps<typeof ContextMenu>, 'updateItemList' | 'removeItems'> {
    id?: string;
    type?: string;
    url?: string;
    title?: string;
    moderators?: { [s: string]: any }[];
    authors?: { [s: string]: any }[];
    tags?: { [s: string]: any }[];
    titleImageId?: string;
    titleImageUrl?: string;
    newsId?: string;
    isEditable:boolean;
    likeCount?: number;
    liked?: boolean;
    showControls: boolean;
    context?: any;
    onItemLinkClick?: () => void;
    // likeNewsRequest?: (value: any) => void;
};

export interface IItemStateProps extends IWikiStateToProps {
    isAdmin: boolean,
 };

export type IWikiDispatchProps = ReturnType<typeof mapDispatchToProps>;

export interface IWikiItemOwnProps extends IClassNameProps {
    tag?: string | React.ComponentType<any>;
    likeCount?: number,
    liked?: boolean,
    data: PageModelList;
    changeItem?: (item: any)=>void
    parentFolderModerators?: any[]
    parentFolderViewers?: any[]
};

export interface IWikiItemState {
    liked: boolean,
};

export type IWikiStateToProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    likeWikiRequest,
}, dispatch); 

export const mapStateToProps = (state: any, props: any) => ({

});


export const cnItem = cn('WikiItemNew');
