import React, { FC, memo } from 'react'
import { cnClassName, Shop } from './Characteristics.interfaces'
import Item from './Item/Item.index'
import { init } from './valueTypes'
import LinearProgress from '@material-ui/core/LinearProgress';
import { useRefedCallback } from 'utils/src/hooks'
import { Box } from 'muicomponents/src';
type IPresenterProps = Shop.Characteristics.IPresenterProps

init()

/**
 * устанавливает isLastSelected в состояние false.
 * Если изменение требуется, то создаёт новый объект. Если не требуется, то вернёт исходный
 * @param characteristic 
 * @returns 
 */
const disableIsLastSelected = (characteristic: Shop.Characteristics.IItem) =>
    characteristic.isLastSelected ? { ...characteristic, isLastSelected: false } : characteristic

export const Presenter: FC<IPresenterProps> = ({
    className,
    characteristics,
    onChange,
    readonly,
    isLoading,
    inline,
    showSelectedTitle
}) => {
    const onClick = useRefedCallback((depsRef) => (value: Shop.Characteristics.IItem) => {
        const { readonly, onChange, isLoading, characteristics } = depsRef.current
        if (isLoading) return;
        if (!onChange) return;
        if (readonly) return;

        const updated = characteristics.map(characteristic => characteristic.id === value.id ? value : disableIsLastSelected(characteristic))
        onChange(updated)
    }, { characteristics, readonly, isLoading, onChange })

    return <Box className={cnClassName({ isLoading, readonly }, [className])} >
        {isLoading && <LinearProgress />}
        <Box className={cnClassName('List', { inline })} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {characteristics.map(item => <Item showSelectedTitle={showSelectedTitle}
                readonly={readonly}
                key={item.id}
                item={item} onClick={onClick} />)}
        </Box>
    </Box >
}

export const Characteristics = memo(Presenter);
