import React, { FC } from 'react';
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput';
import { InputProps as StandardInputProps } from '@mui/material/Input';
import { i18n } from "localization";
import { Box } from 'muicomponents/src';


interface ISearchBarProps {
  onSearchChange: StandardInputProps['onChange']
  value: string
}

const SearchBar: FC<ISearchBarProps> = ({ onSearchChange, value }: ISearchBarProps) => {


  return <>
    <Box sx={{width: '100%'}}>
      <SearchInput onChange={onSearchChange}
        value={value}
        label={null}
        placeholder={i18n.t('pryaniky.list.badges.search')}
      />
    </Box>
  </>
};

export default SearchBar
