import React, { FC } from 'react';
import { cnLessonsList, mapDispatchToProps, mapStateToProps } from './LessonsList.index'
import './LessonsList.scss'
import { Selectize, Button } from 'uielements/src';
import { connect } from 'react-redux';
import TaskSelector from '../../components/TaskSelector'
import { tS } from 'LMSModule/utils/i18n.adaptor'
import { createNavItem } from 'LMSModule/utils/common.utils'


export const LessonsListPresenter: FC<any> = ({ units, currentUid, className, jumpToUnit, courseId, logs }) => {
    const elements = createNavItem(units, logs, currentUid)

    const jump = (value: any) => jumpToUnit(value.id, courseId)

    return <div className={cnLessonsList({}, [className])}>
        <h4 className={cnLessonsList('Title')}>{tS('navigation.1')}</h4>
        <TaskSelector
            className={cnLessonsList('UnitSelector')}
            onSelect={jump}
            units={elements}
            active={[currentUid]}
        />
    </div>
}

export const LessonsList = connect(
    mapStateToProps,
    mapDispatchToProps
)(LessonsListPresenter);