import { IIdeaFieldProps } from '../FieldProps';
import React from 'react';
import { InputMention } from 'blocks/InputMention/InputMention';
import { UsersSuggester } from 'muicomponents/src/Suggester/type/UsersSuggester'
export const OfferResponsibleForStatusUsers: React.FC<IIdeaFieldProps> = ({ field, onChange = () => { } }) => {
    const changeUser = (value: any) => {
        const usersArray = [value]
        field.users = usersArray;
        onChange(field);
    }
    return (
        <div>
            {/* <UsersSuggester
                onChange={changeUser}
                multiple={false}
                value={field?.users}
                placeholder={field.field.description}
            /> */}
            <InputMention
                onChange={changeUser}
                type={'users'}
                noMulti={true}
                // className={cnReplyForm('UserInput')}
                icon='user-add'
                value={field?.users[0]}
                placeholder={field.field.description} />
        </div>
    );
}

