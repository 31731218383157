import React, { FC, memo, useMemo } from 'react'
import {
    cnClassName,
    IPresenterProps
} from './interfaces'
import './style.scss'


export const Presenter: FC<IPresenterProps> = ({
    className,
    left,
    right,
    unitIndex,
    unitsCount
}) => {

    return <div className={cnClassName({}, [className])}>
        {!left ? <div className={cnClassName('Spacer')}/> : left}

        <div className={cnClassName('Number')}>{unitIndex + 1}/{unitsCount}</div>

        {!right ? <div className={cnClassName('Spacer')} /> : right}
    </div>
}

export default memo(Presenter);
