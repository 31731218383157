import { IHeaderLayoutProps, cnHeaderLayout } from './HeaderLayout.index';
import * as React from 'react'
import './HeaderLayout.scss'
import { withHeaderLayoutTypeComment } from './_type/comment/comment'
import { withHeaderLayoutTypePost } from './_type/post/post'
import { composeU } from '@bem-react/core';


export const HeaderLayoutPresenter: React.FC<IHeaderLayoutProps> = ({ left, right, children, bottom, className, compact }) => {
    return (
        <div className={cnHeaderLayout({ compact }, [className])}>
            {left && <div className={cnHeaderLayout("Left")}>{left}</div>}
            <div className={cnHeaderLayout('Content')}>
                <div className={cnHeaderLayout('Top')}>{children}</div>
                {bottom && <div className={cnHeaderLayout('Bottom')}>{bottom}</div>}
            </div>
            {right && <div className={cnHeaderLayout("Right")}>{right}</div>}
        </div>
    );
}

export const HeaderLayout = composeU(
    withHeaderLayoutTypeComment,
    withHeaderLayoutTypePost
)(HeaderLayoutPresenter);