import { NDataStorageActions } from "./actions.interfaces";
import { DS_CHANGE_ITEM, DS_REMOVE_ITEM, DS_SET_ITEM, DS_SET_ITEMS } from "./actions.types";
import { defaultDataStorageReducer } from "./constants";
import { NDataStorage } from "./interfaces";

export const dataStorageReducer = (state: NDataStorage.Reducer = defaultDataStorageReducer, action: NDataStorageActions.Actions): typeof state => {
    switch (action.type) {
        case DS_SET_ITEM:
            return {
                ...state,
                [action.payload.storage]: {
                    ...state[action.payload.storage],
                    [action.payload.id]: action.payload.item
                }
            };
        case DS_SET_ITEMS:
            return {
                ...state,
                [action.payload.storage]: {
                    ...state[action.payload.storage],
                    ...action.payload.items.reduce((a, c) => ({ ...a, [c.id]: c.item }), {})
                }
            };
        case DS_CHANGE_ITEM:
            let newItem;
            switch (typeof action.payload.item) {
                case 'string':
                case 'number':
                case 'boolean':
                    newItem = action.payload.item
                    break;
                default:
                    newItem = {
                        ...state[action.payload.storage][action.payload.id] as any,
                        ...action.payload.item as any
                    }
                    break;
            }
            return {
                ...state,
                [action.payload.storage]: {
                    ...state[action.payload.storage],
                    [action.payload.id]: newItem
                }
            };
        case DS_REMOVE_ITEM:
            const keysToStay = Object.typedKeys(state[action.payload.storage] || {}).filter(el => el !== action.payload.id);
            return {
                ...state,
                [action.payload.storage]: {
                    ...state[action.payload.storage],
                    ...keysToStay.reduce((a, key) => ({ ...a, [key]: (state[action.payload.storage] || {})[key] }), {} as typeof state[keyof typeof state])
                }
            };
        default:
            return state;
    }
}