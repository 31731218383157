import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { changeActiveSurveyIdx, getMySurveysFromServer} from 'redux/sagas/Surveys/actions'
import { getSurveyWidgetLoading, getSurveyWidgetData} from 'redux/sagas/Surveys/selectors'

export interface ISurveyWidget extends ISurveyWidgetStateProps, ISurveyWidgetDispatchProps {
  tag: any
  settings: any
  className?: string
  store?: any
  changeVm?: any
}

export const mapStateToProps = (state: IState) => {
  
  return {
    // dataLength: state.quiz.quizWidget.quizzesData.length,
    // activQuizIdx: state.quiz.quizWidget.activeQuizIdx,
    // edit: state.store.edit
    dataLength: state.survey.surveyWidget.surveyData.length,
    activSurveyIdx: state.survey.surveyWidget.activeSurveyIdx,
    edit: state.store.edit,
    isLoading:state.survey.surveyWidget.isLoadingSurveys,
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  // getQuiz,
  changeActiveSurveyIdx,
  getMySurveysFromServer
}, dispatch);

export type ISurveyWidgetStateProps = ReturnType<typeof mapStateToProps>
export type ISurveyWidgetDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnSurveyWidget = cn('SurveyWidget')