/**
 * @packageDocumentation
 * @module Widget_Settings_info
 */
import { JFdictionary } from "../dictionary";
import { Translate } from '../../../../localizations/Translate';

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  // direction: ,
  elements: [
    {
      type: renderers.checkbox,
      label: Translate.t({ i18nKey: 'pryaniky.widgets.settings.license.isRequired' }),
      scope: "#/properties/isRequired"
    },
    {
      type: renderers.checkbox,
      label: Translate.t({ i18nKey: 'pryaniky.widgets.settings.license.mobileLicense' }),
      scope: "#/properties/mobileLicense",
      rule: {
        effect: "SHOW",
        condition: {
          type: "LEAF",
          scope: "#/properties/showMobileLicense",
          expectedValue: 0
        }
      },
    },
    {
      type: renderers.input,
      scope: "#/properties/okText"
    }
  ]
}