/**
 * @packageDocumentation
 * @module Widget_Settings_info
 */
import { Translate } from "localizations/Translate";
import { JFdictionary } from "../dictionary";

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  // direction: ,
  elements: [
    {
      type: renderers.usersListBlock,
      scope: "#/properties/list"
    }
  ]
}