import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import i18n from '../localizations/i18n';
import { CustomSettings } from 'utils/src';
import { PreviewParams } from 'utils/src/PreviewParams';

interface IRatings {
  list: string;
  filters: string;
}

export const rating = (): IWidget<IColumn[]> => {
  const ids = guids<IRatings>(['list', 'filters']);
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.list + '/leaderboard',
            styles: {
              width: '100%',
            },
          }),
        ],
      }),
    ],
  });
}