import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { getClientStructure, updateContext } from 'redux/actions/Widgets'
import { getPageStructure } from 'blocks/Page/Page.index';

export interface IWidgetsProps extends IClassNameProps, RouteComponentProps<IWidgetsUrlParams>, IDispatchProps, IActionsWidgets, IWidgetsStateProps {
  tag?: string | React.ComponentType<any>;
}

export interface IWidgetsUrlParams {
  type: string;
  id?: string;
  subtype?: string;
}

export interface IWidgetsState {
  text?: string;
}

export interface IActionsWidgets {
  getClientStructure?: any;
  updateContext? :any;
}

export const mapActionsToPropsPage = {
  getClientStructure,
  updateContext,
};

export interface IWidgetsStateProps {
  // structure: { [s: string]: any };
  layout: { [s: string]: any };
}

export const mapStateToProps = (state: any, props: IWidgetsProps) => ({
  // structure: state.widgets.pages[state.widgets.structures['widgets' + (props.match.params.id ? '/' + props.match.params.id : '')]],
  layout: state.widgets.pages[state.widgets.structures['widgets' + (props.match.params.id ? '/' + props.match.params.id : '')]] ? state.widgets.widgets[state.widgets.pages[state.widgets.structures['widgets' + (props.match.params.id ? '/' + props.match.params.id : '')]].layout] : undefined,
  // structure: getPageStructure(state.widgets.structures, 'widgets' + (props.match.params.id ? '/' + props.match.params.id : '')),
})

export const cnWidgets = cn('Widgets');