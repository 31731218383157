import {
    takeEvery,
    put,
    takeLeading,
    takeLatest,
    throttle,
    fork
} from 'redux-saga/effects';
import {
    call,
    select,
} from 'utils/src/saga.effects';

import {
    API,
    checkResponseStatus,
    objectArrayToMap
} from 'utils/src/utils'

import {
    loadUserSkillsActionType,
    addUserSkills,
    createUserSkillActionType,
    updateSkill,
    confirmSkillActionType,
    deleteSkillActionType,
    deleteUserSkill,
    setFinished,
    setLoading
} from 'redux/actions/Skills'

import {
    getUserSkillById
} from './selectors'

import actions from 'redux/actionsTypes/Skills';

import { getAuthUser } from 'utils/src/CommonRedux/base/actions'

import { CacheHelper } from 'utils/src/CacheHelper'
import { ISkill, IUserShort, ISkillCollection } from './types'
import {
    // toggleUserInArray,
    removeUserFromArray,
    addUserToArray
} from './utils'
import { v1 as uuid } from 'uuid'

/**
 * получает с сервера список всех доступных в компании типов публикаций
 * @param action 
 */
const handleLoadUserSkills = function* handleLoadUserSkills(action: loadUserSkillsActionType) {
    try {
        const { userId } = action.payload

        yield put(setLoading(true))
        yield put(setFinished(false))

        const result = (yield* call(API.endorsements.getUser, userId)) as any;

        const replaced: ISkill[] = result.data.map((val: ISkill) => ({ ...val, tag: { ...val.tag, displayName: val.tag.displayName.replace(/_/gm, ' ') } }))

        const skills: ISkillCollection = objectArrayToMap<ISkill, ISkill>(replaced, 'tag.id')

        yield put(addUserSkills(userId, skills))

        yield put(setLoading(false))
        yield put(setFinished(true))

        return 0;
    } catch (error) {
        console.warn(error)
    }
}


const handleDeleteSkill = function* handleDeleteSkill(action: deleteSkillActionType) {
    try {
        const { userId, skillId } = action.payload
        const skill: ISkill = yield* select(getUserSkillById(userId, skillId))

        yield put(deleteUserSkill(userId, skillId))
        const replaced = skill.tag.displayName.replace(/\s/gm, '_')
        if (skill.confirmCount === 0) {
            const result = yield* call(API.endorsements.setCancel, replaced, userId)
        } else {
            const result = yield* call(API.endorsements.removeEndorse, replaced, userId)
        }

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}


const handleCreateUserSkill = function* handleCreateUserSkill(action: createUserSkillActionType) {
    try {
        const { userId, skill } = action.payload
        const currentUser = yield* select(getAuthUser)

        yield put(setLoading(true))
        const replaced = skill.tag.displayName.replace(/\s/gm, '_')
        const result = yield* call(API.endorsements.setEndorse, replaced, userId)
        const skills: ISkillCollection = {
            [skill.tag.id]: {
                ...skill,
                confirmedBy: [currentUser.baseData],
                confirmCount: 1,
                confirmedByMe: true
            }
        }
        yield put(addUserSkills(userId, skills))
        yield put(setLoading(false))
        return 0;
    } catch (error) {
        console.warn(error)
    }
}


const handleConfirmSkill = function* handleConfirmSkill(action: confirmSkillActionType) {
    try {
        const { userId, confirmedByMe, skillId } = action.payload

        const currentUser = yield* select(getAuthUser)
        const skill: ISkill = yield* select(getUserSkillById(userId, skillId))

        const confirmedBy = confirmedByMe ? addUserToArray(skill.confirmedBy, currentUser.baseData) : removeUserFromArray(skill.confirmedBy, currentUser.baseData)
        const confirmCount = (confirmedByMe ? 1 : -1) + skill.confirmCount

        if (!((confirmCount === 0 && skill.confirmCount === 1) || (confirmCount === 1 && skill.confirmCount === 0))) {
            const method: any = confirmedByMe ? API.endorsements.setEndorse : API.endorsements.setCancel
            const replaced = skill.tag.displayName.replace(/\s/gm, '_')
            const result = yield* call(method, replaced, userId, !confirmedByMe)
        }


        yield put(updateSkill(userId, {
            ...skill,
            confirmedByMe,
            confirmCount,
            confirmedBy
        }))

        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * module root saga
 */
const root = function* root() {
    yield takeLeading(actions.LOAD_USER_SKILLS, handleLoadUserSkills)
    yield takeEvery(actions.CREATE_USER_SKILL, handleCreateUserSkill)
    yield takeLeading(actions.CONFIRM_SKILL, handleConfirmSkill)
    yield takeEvery(actions.DELETE_SKILL, handleDeleteSkill)
};

/**
 * export root saga
 */
export default root;