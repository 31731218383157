import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ComponentType } from 'react'
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    
} from '../../redux/actions'
import {
} from '../../redux/reducer/types'
import {
    getCommentById
} from '../../redux/saga/selectors'
import { IReplyModel } from '../../types/reply'
import { IComponentAcceptor } from 'utils/src/ComponentInjector'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace ReplyItem {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & IComponentAcceptor

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            id: string
            newsId: string
            withRating?: boolean
        }

        export interface IStateProps {
            reply: IReplyModel
        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.ReplyItem.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    reply: getCommentById(props.id)(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    
}, dispatch);

////////////

export const cnClassName = cn('NewsReplyItem');