import { IStateType as IState } from 'redux/store';
import { updateContext } from '../../redux/actions/Widgets';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';

export interface IWelcomeProps extends ImapWelcomeStateToProps, ImapWelcomeActionToProps {

}

export const mapWelcomeStateToProps = (state: IState) => ({
  authUserId: state.store.authUid,
  context: state.widgets.contexts.common,
})

export type ImapWelcomeStateToProps = ReturnType<typeof mapWelcomeStateToProps>;

export const mapWelcomeActionToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  updateContext
}, dispatch);

export type ImapWelcomeActionToProps = ReturnType<typeof mapWelcomeActionToProps>;