import { IClassNameProps } from '@bem-react/core';
import { cnShop } from '../Shop.index';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { bindActionCreators, Dispatch } from 'redux';
import { IStateType as IState } from 'redux/store';
import actions from 'redux/actionsTypes/Widgets';
import { IShopFilter } from 'utils/src/requests/models/api.shop';
import { RouteComponentProps } from 'react-router';

// import { IHeaderProps } from "blocks/List/Header/List-Header.index";

export interface IShopHeaderProps extends IClassNameProps, IShopHeaderMapStateProps, IShopHeaderMapDispatchProps, RouteComponentProps {
  tag?: string | React.ComponentType<any>;
  placeholder?: string;
  searchFunction(text: string): void;
  filters: IShopFilter[];
  onFilter: any;
}

export interface IShopFilterPresent extends IShopFilter {
  value: number | string;
}

type IShopHeaderMapStateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: IState) => ({
  myThanksCount: getAuthUser(state).extData.mythanksCount
})

type IShopHeaderMapDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  changeContextv3: (payload: Array<{ [s: string]: any }>) =>
    ({ type: actions.UPDATE_CONTEXTS, payload }),
}, dispatch)

export const cnHeader = (...data: any) => {
  let name = 'Header';
  if (typeof (data[0]) === 'string') {
    name += '-' + data[0];
    data = data.slice(1);
  }
  return cnShop(name, ...data)
}