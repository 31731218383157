import React, { FC, useEffect } from 'react'
import { ItemsList } from "muicomponents/src/ItemsListDialog/ItemsListDialog";
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody';
import { WikiListItem } from 'muicomponents/src/ItemsListDialog/WikiListItem/WikiListItem';
import { defaultMiddleware, useDefaultStaticStates } from 'muicomponents/src/ItemsListDialog/defaultListState';
import { connect, useSelector } from 'react-redux';
import { mapStateToProps, mapDispatchToProps, TListStateProps, TMapDispatchProps, IListProps, getQuerySearchSelector, getWikiFilter, getAdditionalFilterByName } from '../../index';
import { IStateType as IState } from 'redux/store';
import { useDidUpdateEffect, useScrollListState, useSearchParams } from 'utils/src/hooks';
import { getWikiPages, getWikiPagesV4 } from 'utils/src/requests/requests.wikilist';
import { TWikiListResponse } from './List_wiki.index';
import { Translate } from 'localizations/Translate';
import { NothingFoundText } from '../NotingFoundText'


let wikiSearchRequest: {[contentType: string]: ReturnType<typeof getWikiPagesV4>} = {};

/**
 * список работает тольок на странице поиска
 * обратите внимание на использование useSearchParams
 */
export const ListWikiPresenter: FC<IListProps & { onOldSearchPage: boolean }> = ({ onOldSearchPage, context, afterStateChangedCallback, afterGetDataCallback }) => {
  return (
    <ItemsList
      BodyComponent={ItemsListBody}
      ItemComponent={WikiListItem}
      listHeaderProps={{ disableSearch: true, disableActionsButton: true }}
      listBodyProps={{ disableBorder: true, disablePadding: true, joinBody: true }}
      listComponentProps={{
        textEmpty: !context.search ? <Translate i18nKey='pryaniky.timeline.notFound.no.searchQuery.text' /> : <NothingFoundText />,
      }}
      listStateHook={({ defaultSelected }, middlewareParams) => {

        const {
          changeSearch,
          onChangeTab,
        } = useDefaultStaticStates(middlewareParams.tabsValue || middlewareParams.tabs?.[0]?.id)// состояние для поиска и табов

        // данный параметр изменяется только на странице поиска
        let [{ search: querySearch = '' }] = useSearchParams() as any as [{ search: string | undefined }];

        const wikiFilter = useSelector(getAdditionalFilterByName('pages'));
// console.log('wikiFilter', wikiFilter)
        if(onOldSearchPage) {
          querySearch = context.search
        }

        // получаем список вики-страниц
        const state = useScrollListState(async function (skipCount, count) {
          if (!querySearch) return [];
          const contentType = context.contentType || 'all'

          if(wikiSearchRequest[contentType]) wikiSearchRequest[contentType].ac.abort();
          const additionalParams: {[key: string]: any} = {};
          // add custom top filters if not set contentType from top
          if(!context.contentType) {
            additionalParams.authors = wikiFilter?.author?.id || '';
            additionalParams.catFilter = wikiFilter?.categoryType?.id === "all" ? undefined : wikiFilter?.categoryType?.id as any;
            additionalParams.order = wikiFilter?.order;
            additionalParams.sort = wikiFilter?.sort;
            additionalParams.type = wikiFilter?.materialType?.value || 'all';
          }

          wikiSearchRequest[contentType] = getWikiPagesV4('all', {
            search: querySearch,
            skipCount,
            count: context.count || count,
            type: contentType,
            ...additionalParams,
            searchOnlyTitle: wikiFilter?.searchOnlyTitle,
            isSearchPageQuery: true
          })

          const result = await wikiSearchRequest[contentType].r as any as TWikiListResponse;

          if (result.error_code !== 0) throw result

          afterGetDataCallback?.(result)

          return result.data;
        }, [querySearch, wikiFilter, context.count], {
          selectableSingle: middlewareParams.selectableSingle
        }, true)

        useDidUpdateEffect(() => {
          afterStateChangedCallback?.(state);
        }, [state]);

        return {
          middlewareParams: {
            ...middlewareParams,
            itemMiddleware(props) {
              return {
                ...props,
                contentCenter: true,
                avatarWidth: 64
              }
              // return {
              //   ...props,
              //   // contentCenter: true,
              //   // avatarWidth: 64
              // }
            }
          },
          state,
          defaultSelected,
          middleware: defaultMiddleware,
          search: querySearch,
          onChangeTab,
          changeSearch,
        }
      }}
    />
  )
}

export const List = connect<TListStateProps, TMapDispatchProps, IListProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(ListWikiPresenter);