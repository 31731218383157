import React, { FC } from 'react'
import { connect } from 'react-redux';
import { cnTimer, mapStateToProps, mapDispatchToProps } from './Timer.index'
import './Timer.scss'

export const TimerPresenter: FC<any> = ({ timer, className }) => {
    const remainingTime = new Date(timer.startTime + timer.timeCount - (new Date).getTime())
    return <div className={cnTimer({}, [className])}>{remainingTime.toLocaleTimeString('en-US', { hour12: false, timeZone: 'UTC' })}</div>
}


export const Timer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TimerPresenter);
