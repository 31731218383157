import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

export type INewsViewType = 'common' | 'card';

export const NEWS_SERVER_VALIDATE = 'NEWS_SERVER_VALIDATE';

let actions = {
  //saga
  LOAD_NEWS: 'LOAD_NEWS', // загрузить новости
  LOAD_FULL_REPLY: 'LOAD_FULL_REPLY', // загрузить комментарий целиков
  LIKE_NEWS: 'LIKE_NEWS', // лайкнуть новость
  DELETE_NEWS: 'DELETE_NEWS', // удалить новость
  HIDE_NEWS: 'HIDE_NEWS', // скрыть новость
  ADD_TO_ANNOUNCEMENT: 'ADD_TO_ANNOUNCEMENT', // добавить нововсть в аннонсы
  UPDATE_NEWS: 'UPDATE_NEWS', // изменить новость
  LOAD_NEWS_BY_ID: 'LOAD_NEWS_BY_ID', // загузить указанную новость
  ADD_INVEST: 'ADD_INVEST', // инвестировать в новость
  MOVE_TO_GROUP: 'MOVE_TO_GROUP', // переместить новость в группу
  SEND_NEWS: 'SEND_NEWS', // опубликовать новость
  CONTEXT_ACTION: 'CONTEXT_ACTION', // действие контекстного меню
  CONTEXT_ACTION_FEEDBACK_CONTACT: 'CONTEXT_ACTION_FEEDBACK_CONTACT', // действие показать контакт оставившего отзыв
  CONTEXT_ACTION_CANCLE: 'CONTEXT_ACTION_CANCLE', // действие отменино 

  LOAD_COMMENTS: 'LOAD_COMMENTS', // загрузить все комментарии
  SEND_REPLY: 'SEND_REPLY', // отправить комментарий
  DELETE_REPLY: 'DELETE_REPLY',// удалить комментарий
  LIKE_REPLY: 'LIKE_REPLY', // лайкнуть комментарий
  UPDATE_REPLY: 'UPDATE_REPLY', // изменить комментарий
  INSERT_MENTION: 'INSERT_MENTION', // добавить упомянание
  

  //reducer
  SET_NEWS: 'SET_NEWS', //
  UPGRADE_NEWS: 'UPGRADE_NEWS', // перезаписывает новость с комментариями
  PATH_NEWS: 'PATH_NEWS', // изменить свойства нововсти
  UNSET_NEWS: 'UNSET_NEWS', // убрать объект новости
  TOGGLE_EDIT: 'TOGGLE_EDIT', //переключает состояние редактирования
  SET_UPDATE_ERROR: 'SET_UPDATE_ERROR', // установить ошибку редактиования
  PREPEND_NEWS: 'PREPEND_NEWS', // добавить новости в начало ленты
  APPEND_NEWS: 'APPEND_NEWS', // добавить нововсти в конец ленты и опеделить окончание(isFinished)
  SET_HEIGHTS: 'SET_HEIGHTS', // установить высоту новости
  SET_CURRENT_RENDERED: 'SET_CURRENT_RENDERED', // отрисованные новости в данный момент

  CHANGE_REPLY_FORM: 'CHANGE_REPLY_FORM', // изменить новый комментарий
  ADD_COMMENTS: 'ADD_COMMENTS', // добавить комментарии к новости
  PREPEND_COMMENTS: 'PREPEND_COMMENTS', // добавить комментарии в начало
  UNSET_REPLY: 'UNSET_REPLY', // убрать комментарий
  PATH_REPLY: 'PATH_REPLY', // изменить свойства комментария
  PATH_CREATOR: 'PATH_CREATOR', // изменить свойства формы публикации
  PATH_CREATOR_NEWS: 'PATH_CREATOR_NEWS', // изменить текущию создоваемую новость
  VALIDATE_CREATOR_NEWS: 'VALIDATE_CREATOR_NEWS', // валидировать текущую создаваемую новость
  TOGGLE_COMMENT_SENDING: 'TOGGLE_COMMENT_SENDING', // изменить состояние отправки комментария
  TOGGLE_COMMENT_LOADING: 'TOGGLE_COMMENT_LOADING', // изменить состояние загрузки комментариев
  TOGGLE_THANK: 'TOGGLE_THANK',
  CHANGE_STATUS_FIELD: 'CHANGE_STATUS_FIELD', // изменяет указаное свойство получения ленты
  REJECT_ACHIEVMENT: 'REJECT_ACHIEVMENT', // отклоняет достижение находящееся на модерации
  SUBMIT_FOR_CORRECTION: 'SUBMIT_FOR_CORRECTION', // отправляет достижени находящееся на модерации на доработку  
  //common
  CHANGE_FIELD: 'CHANGE_FIELD', // изменяет указаное свойство
  RESET: 'RESET', // сбрасывает указаное свойство

  SET_READED: 'SET_READED',
  NEWS_VIEW_FULL: 'NEWS_VIEW_FULL',
  REPLY_VIEW_FULL: 'REPLY_VIEW_FULL',

  // workflows
  LOAD_WORKFLOW_STATUSES: 'LOAD_WORKFLOW_STATUSES',

  ACTIVE_NEWS: 'ACTIVE_NEWS',

  BANNERS_SET_READED: 'BANNERS_SET_READED',
  BANNERS_CLEAR_READED: 'BANNERS_CLEAR_READED',
  BANNERS_SET_CLOSED: 'BANNERS_SET_CLOSED',
  BANNERS_CLEAR_CLOSED: 'BANNERS_CLEAR_CLOSED',
} as const

// function ACTION<T, P>(action: T, reducerPrefix?: P) {
//    return typeof `PRN_${reducerPrefix}_${action}`;
// }


export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.NEWS) }), {} as typeof actions);