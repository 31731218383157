import Component, { Presenter } from './component';
import {
    IAddMaterialsOwnProps,
    IAddMaterialsPresenterProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = IAddMaterialsOwnProps;
export type IComponentPresenterProps = IAddMaterialsPresenterProps;


export default Component