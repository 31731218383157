import { Translate } from 'localizations/Translate';
import React, { FC } from 'react';
import { Avatar, Button } from 'uielements';
import { NBadgesListItem, cnBadgesListItem, mapBadgesListItemStatetoProps } from './BadgesItem.index';
import './BadgesItem.scss';
import { ChevronRight } from '@material-ui/icons';
import { connect } from 'react-redux';
import { getFormattedCurrencyName } from 'utils.project/utils.project';
import MDRender from 'uielements/src/CommonmarkRender';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn'

const getI18nKey = (key: string) => `pryaniky.badges.list.item.${key}`;

const BadgesListItemPr: FC<NBadgesListItem.Props> = ({
    data,
    currencyFormats
}) => {

    const {
        badgeUid,
        pkid,
        iconUrl,
        name,
        totalCount,
        issueCurrency,
        description,
        howToReceiveDescription
    } = data;

    //const currencyTotal = issueCurrency.reduce((a, it) => a + it.key, 0);
    const visibleCurrency = issueCurrency[0]?.key

    const currencyNameText = getFormattedCurrencyName(currencyFormats, visibleCurrency, 'nominative');

    return (
        <div className={cnBadgesListItem()}>
            <div className={cnBadgesListItem('Data')}>
                <Button className={cnBadgesListItem('Avatar')} theme={'unstyled'} type='rlink' href={`/badge/${badgeUid}`}>
                    <Avatar imgUrl={iconUrl || BaseRequests.getUrl(defaultBadgeIconUrl)} imgId={"123123123123"} name={name} size={94} badge={totalCount ? { text: totalCount } : undefined} />
                </Button>
                <h4 className={cnBadgesListItem('Name')}>
                    <Button className={cnBadgesListItem('Avatar')} theme={'unstyled'} type='rlink' href={`/badge/${badgeUid}`}>
                        {name}
                    </Button>
                </h4>
                <Tooltip title={issueCurrency.map(el => <div>{el.key} {getFormattedCurrencyName(currencyFormats, el.key, 'nominative')}: {el.value}</div>)}>
                    <div className={cnBadgesListItem('Currency', [ 'primaryColor3-text' ])}>
                        {visibleCurrency ? <>{visibleCurrency} {currencyNameText}</> : <></>}
                    </div>
                </Tooltip>
                <div className={cnBadgesListItem('Description')}>
                    {
                        howToReceiveDescription ?
                        <MDRender textCut={true} maxCharCount={60} maxParagraphCount={3} source={howToReceiveDescription} /> :
                        description
                    }
                </div>
                <Button className={cnBadgesListItem('More')} theme={'unstyled'} type='rlink' href={`/badge/${badgeUid}`}>
                    <Translate i18nKey={getI18nKey('more')} />
                    <ChevronRight />
                </Button>
            </div>
        </div>
    )
};

export const BadgesListItem = connect(
    mapBadgesListItemStatetoProps
)(BadgesListItemPr);
