import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import * as SEL from '../../../../../../redux/sagas/LMSTO/selectors'
import { UserProgress } from '../../../../types'
///////////
export declare namespace StatisticsFull {
    export namespace UsersListItem {
        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            id: string
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////

type IOwnProps = StatisticsFull.UsersListItem.IOwnProps

export const mapStateToProps = (state: any, { id }: IOwnProps) => ({
    ...(SEL.getUserStatCoursesListValueById(id)(state) as any)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({

}, dispatch);

////////////

export const cnClassName = cn('LMSTOStatUserCoursesListItem');