import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { ItemsList } from "muicomponents/src/ItemsListDialog/ItemsListDialog";
import { ItemsListBody } from "muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody";
import { useDefaultStaticStates } from "muicomponents/src/ItemsListDialog/defaultListState";
import { useDidUpdateEffect, useScrollListState, useSearchParams } from "utils/src/hooks";
import { list } from 'utils/src/requests/requests.users';
import { search } from 'utils/src/requests/requests.suggesters';
import { mapFromUserList } from "muicomponents/src/FieldRender/mapUserListFields";
import { UsersListItem } from "muicomponents/src/ItemsListDialog/UserListItem/UserListItem";
import { defaultMiddleware } from 'muicomponents/src/ItemsListDialog/defaultListState'
import { connect, useSelector } from "react-redux";
import { mapStateToProps, mapDispatchToProps, TListStateProps, TMapDispatchProps, IListProps, getQuerySearchSelector } from '../../index';
import { IStateType as IState } from 'redux/store';
import { TUserItemType, TUsersListResponse } from './List_users.index';
import { Translate } from 'localizations/Translate';
import { NothingFoundText} from '../NotingFoundText'
import { getAppSettingsValueByKeys } from "utils/src/CommonRedux/base/selectors";
import { v4 as uuid} from 'uuid';


let usersSearchRequest: ReturnType<typeof search>;

/**
 * список работает тольок на странице поиска
 * обратите внимание на использование useSearchParams
 */
export const ListUsersPresenter: FC<IListProps> = ({ context, afterStateChangedCallback, afterGetDataCallback, wcontext, authUser, uIsAdmin }) => {

  const userAdditionalFieldsSettings = useSelector(getAppSettingsValueByKeys('userProfileAdditionlFields'));

  const userAdditionalFieldsDict = useMemo(() => {
    return userAdditionalFieldsSettings.userProfileAdditionlFields?.reduce((a: any, el: any) => ({ ...a, [el.id]: el}), {} as any)
  }, [userAdditionalFieldsSettings.userProfileAdditionlFields]);
  const userAdditionalFieldsDictRef = useRef(userAdditionalFieldsDict);
  userAdditionalFieldsDictRef.current = userAdditionalFieldsDict;


  return (
    <ItemsList<TUserItemType>
      disableQR
      listHeaderProps={{ disableSearch: true, disableActionsButton: true }}
      listBodyProps={{ joinBody: true, disableBorder: true, disablePadding: true}}
      BodyComponent={ItemsListBody}
      ItemComponent={UsersListItem}
      listComponentProps={{
        textEmpty: !context.search ? <Translate i18nKey='pryaniky.timeline.notFound.no.searchQuery.text' /> : <NothingFoundText/>,
      }}
      listStateHook={({ defaultSelected }, middlewareParams) => {
        const {
          changeSearch,
          onChangeTab
        } = useDefaultStaticStates(middlewareParams.tabsValue || middlewareParams.tabs?.[0]?.id)// состояние для поиска и табов

        // данный параметр изменяется только на странице поиска
        const [{ search: querySearch = '' }] = useSearchParams() as any as [{ search: string | undefined }];

        // получаем список пользователей и достаём запиханное туда колличество пользователей
        const state = useScrollListState(async function (skipCount, count) {

          if (!querySearch) return [];

          if(usersSearchRequest) usersSearchRequest.ac.abort();

          usersSearchRequest = search(
            "users",
            querySearch,
            -1,
            false,
            {
              count: context.count || count,
              skipCount: skipCount,
              isSearchPageQuery: context.isSearchPageQuery
            }
          )

          const result = await usersSearchRequest.r as any as TUsersListResponse

          if (result.error_code !== 0) throw result;

          afterGetDataCallback?.(result)

          return result.data.map((item) => ({
            ...item,
            url: `/user/${item.id}`,
            actions: (item.actions || []),
            fields: [{
              id: uuid(),
              title: "",
              description: "",
              isCollapsed: false,
              fields: item.additionalFields.filter(el => !!userAdditionalFieldsDictRef.current[el.id]).map(el => ({ ...userAdditionalFieldsDictRef.current[el.id], ...el})) || []
            }]
          })) as TUserItemType[]
        }, [querySearch], {
          selectableSingle: middlewareParams.selectableSingle
        }, true)

        useDidUpdateEffect(() => {
          afterStateChangedCallback?.(state);
        }, [state]);

        return {
          middlewareParams: {
            ...middlewareParams,
            itemMiddleware(props) {
              return {
                ...props,
                contentCenter: true,
              }
            }
          },
          state,
          defaultSelected,
          middleware: defaultMiddleware,
          search: querySearch,
          onChangeTab,
          changeSearch
        }
      }}
    />
  );
}

export const List = connect<TListStateProps, TMapDispatchProps, IListProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(ListUsersPresenter);