import { clone } from 'utils/src/utils';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { NLeftMenuReducer } from './interfaces';
import { baseLeftMenuReducer } from './constants';
import * as LMActions from './actions.types';
import { union } from 'lodash';
import { NLeftMenuActions } from './actions.intrefaces';

export const reducerLeftMenu = (state: NLeftMenuReducer.Reducer = baseLeftMenuReducer, action: NLeftMenuActions.Actions): NLeftMenuReducer.Reducer => {
  switch (action.type) {
    
    case LMActions.LM_SET_BARS: {
        return {
            ...state,
            bars: action.payload
        }
    }

    case LMActions.LM_SET_BAR_ITEMS:
    case LMActions.LM_SORT_FAVOURITE: {
        const items = !Array.isArray(action.payload) ? [action.payload] : action.payload;
        return {
            ...state,
            barsItems: {
                ...state.barsItems,
                ...items.reduce((a, item) => ({ ...a,  [item.bar]: item.items }), {} as typeof state.barsItems)
                // [action.payload.bar]: action.payload.items
            }
        }
    }

    case LMActions.LM_SET_EDIT: {
        return {
            ...state,
            edit: action.payload,
            opened: action.payload ? true : state.opened
        }
    }

    case LMActions.LM_SET_REDUCER_FIELD: {
        return {
            ...state,
            [action.payload.field]: action.payload.value
        }
    }

    case LMActions.LM_REMOVE_ITEM: {
        let closeBarsIfOpened = state.bars;
        if(closeBarsIfOpened.includes(action.payload.item)) {
            const indexToRemove = closeBarsIfOpened.findIndex((e) => e === action.payload.item);
            if(indexToRemove !== -1) {
                closeBarsIfOpened = closeBarsIfOpened.slice(0, indexToRemove);
            }
        }
        return {
            ...state,
            bars: closeBarsIfOpened,
            barsItems: {
                ...state.barsItems,
                [action.payload.fromBar]: state.barsItems[action.payload.fromBar].filter(el => el !== action.payload.item)
            }
        }
    }

    case LMActions.LM_TOGGLE_FAVOURITE: {
        if(!state.favouriteItemId) return state;
        return {
            ...state,
            barsItems: {
                ...state.barsItems,
                [state.favouriteItemId]: state.barsItems[state.favouriteItemId].includes(action.payload)
                    ? state.barsItems[state.favouriteItemId].filter(el => el !== action.payload)
                    : [...state.barsItems[state.favouriteItemId], action.payload]
            }
        };
    }

    default:
        return state
    }
}