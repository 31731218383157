import { baseAdventCalendarReducer } from "./constants";
import { NAdventCalendar } from "./types";

type BaseReducer = { adventCalendar: NAdventCalendar.Reducer };

export const getAdventCalendarState = <S extends BaseReducer = BaseReducer> (state: S) => state.adventCalendar || baseAdventCalendarReducer;

type ReducerSelectorParameters = Parameters<typeof getAdventCalendarState>;

export const getAdventCalendarExample = <Key extends keyof NAdventCalendar.Reducer> (key: Key) => (...args: ReducerSelectorParameters) => getAdventCalendarState(...args)[key];

export const getAdventCalendarExampleDay = <Key extends keyof NAdventCalendar.Reducer[keyof NAdventCalendar.Reducer]> (day: Key) => (...params: Parameters<typeof getAdventCalendarExample>) => (...args: ReducerSelectorParameters) => getAdventCalendarExample(...params)(...args)[day];