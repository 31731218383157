import React, { FC, memo, useCallback, useState } from 'react'
import {
    cnClassName,
    IPresenterProps
} from './interfaces'
import './style.scss'
import { Icon } from 'uielements/src'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip'
import { tC } from 'LMSModule/utils/i18n.adaptor'
import MDRender from 'uielements/src/CommonmarkRender'
import { isArray } from 'lodash'

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    isExpand = false,
    answersTexts = tC('notAnswers.1'),
    text,
    isRight = false,
    isInfo = false,
    isWait = false,
    helpText
}) => {
    const [open, setOpen] = useState(false)

    const showDetails = children && open && isExpand
    // const answersT = answersTexts.replaceAll('\n', ', ')

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('QuestionSummary', { isExpand })} onClick={() => setOpen(!open)}>
            <HelpTooltip help={helpText}>
                <div className={cnClassName('QuestionIcon', { right: isRight, info: isInfo, wait: isWait })}></div>
            </HelpTooltip>
            <div className={cnClassName('QuestionContent')}>
                <div className={cnClassName('QuestionText')}>{text}</div>
                <div className={cnClassName('Answers')}>
                    {/* {answersT} */}
                    {isArray(answersTexts) ?
                        answersTexts.map(a => <div className={cnClassName('Answer')}><MDRender
                            source={a}
                            textCut={false}
                        /></div>) :
                        <MDRender
                            source={answersTexts}
                            textCut={false}
                        />}

                </div>
            </div>
            {isExpand &&
                <div className={cnClassName('Open')}>
                    <Icon className={cnClassName('OpenIcon')} icon={open ? 'angle-down' : 'angle-left'} />
                </div>
            }
        </div>
        {showDetails &&
            <div className={cnClassName('QuestionDetails')}>
                {children}
            </div>
        }
    </div>
}

export default memo(Presenter);
