import { Box } from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';

export const TagTabsInner = styled(Box)({
  width: '100%'
}) as typeof Box;

export const TabsBox = styled(Box)({
  borderBottom: '1px solid #E0E0E0', 
  marginBottom: 24
}) as typeof Box;
