import { IDispatchProps } from 'redux/connector';
import { IDialog } from 'blocks/Dialogs/DialogWrapper.index';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { IBodyProps } from 'utils/src/DialogCreator';

export type IMakeOrderDialogStateProps = ReturnType<typeof mapStateToProps>;

export interface IMakeOrderDialogProps extends IMakeOrderDialogOwnProps, IMakeOrderDialogStateProps {
  data?: any
  id?:any
  additionalInformationBeforeOrder?:string

}

export interface IMakeOrderDialogOwnProps extends IBodyProps { }

// export interface IMakeOrderDialogState {
//   data?: any;
//   comment: string;
//   presentFor?: any;
// }

export const mapStateToProps = (state: any, props: IMakeOrderDialogOwnProps) => {
  return {
    myThanksCount: getAuthUser(state).extData.mythanksCount || 0,
    currencyFormats: state.store.appSettings,
  }
};