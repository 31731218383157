import {User,  defaultUser, defaultGroup} from '../BaseType'
import { IBaseNews, createDefaultNews, Group } from 'News/types/baseNews'
export interface BadgesNews extends IBaseNews{
    newstype: 0;
    linkPreviewImgUrl: string;
}


export const defaultBadgesNews: BadgesNews = {
    ...createDefaultNews(),
    linkPreviewImgUrl:"",
    newstype: 0,
    user: defaultUser,
    systemNewsActionHtml:"",
    header:"",
};


export interface Badge {
    id: number;
    displayName: string;
    iconUrl: string;
    hide: boolean;
}

export interface BadgesNewsCreate extends BadgesNews{
    // allowReply: boolean;
    users: User[];
    // text: string;
    badge: Badge;
    // group: Group;
}

export const defaultBadge: Badge = {
    "id":-1,
    "displayName":"",
    "iconUrl":"",
    "hide":false
}

export const defaultBadgesNewsCreate: BadgesNewsCreate = {
    ...defaultBadgesNews,
    "allowReply":true,
    "users": [],
    "text":"",
    "badge":defaultBadge,
    "group":defaultGroup
}
