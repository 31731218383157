import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { setAnswerId, setOtherAnswerId, setAnswer as setAnswerAction } from 'redux/sagas/Surveys/actions'
import { SSurveyAnswerVariant, SSurveyQuestion } from 'utils/src/BaseTypes/survey';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveySelectorDataByKey, getSurveyStateByKey } from 'redux/sagas/Surveys/selectors';
import { useCallback, useRef } from 'react';

export interface IAnswerItem extends IAnswerItemStateProps, IAnswerItemDispatchProps {
  item: SSurveyAnswerVariant; 
  highlightCorrectness?: boolean
  questionId: SSurveyQuestion['id'];
  qtype: SSurveyQuestion['qtype'];
  sliderSymbols : string;
  allowAttachments:boolean;
  disableMoreAnswers?: boolean;
}

export const useAnswer = (questionId: SSurveyQuestion['id']) => {

  const dispatch = useDispatch();

  const data = useSelector(getSurveyStateByKey('surveyPassingAnswers'));
  const answer = data.surveyPassingAnswers[questionId] || {
    answersIds: [],
    attachments: [],
    isChecked: true,
    isFree: false,
    text: ''
  };
  const answerRef = useRef(answer);
  answerRef.current = answer;

  const setAnswer = useCallback((newData: Partial<typeof answer>) => {
    dispatch(setAnswerAction({
      questionId,
      answerValue: {
        ...answerRef.current,
        ...newData
      }
    }))
  }, [questionId]);

  const {
    answersIds,
    attachments,
    text,
    numericAnswer,
    isFree,
  } = answer;

  const textAnswerData = useSelector(getSurveySelectorDataByKey(
    'textAnswers',
    'attachments',
    'numericAnswer',
    'answerDate'
  ));

  return {
    answersIds,
    attachments,
    text,
    numericAnswer,
    isFree,
    setAnswer
  }
};

export const mapStateToProps = (state: IState) => {

  return {
    answersIds: state.survey.survey.answersIds,
    answered: state.survey.survey.answered,
    surveyType: state.survey.survey.openSurveyData.surveyType,
    textAnswer: state.survey.survey.textAnswers,
    fileAnswer: state.survey.survey.attachments,
    numericAnswer: state.survey.survey.numericAnswer,
    answerDate: state.survey.survey.answerDate
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  setAnswerId,
  setOtherAnswerId
}, dispatch);

export type IAnswerItemStateProps = ReturnType<typeof mapStateToProps>
export type IAnswerItemDispatchProps = ReturnType<typeof mapDispatchToProps>


export const cnAnswerItem = cn('SurveyAnswerItem')