// import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { RouteComponentProps } from 'react-router-dom';
import { IContext } from 'store/vm';
import { setContext } from 'redux/actions/Widgets';
// import { IRatingUserInfo, IRatingValue } from 'utils/src/requests/models/api.ratings';

export interface IRatingProps extends IBadgeStateProps, IActionsDispatch, RouteComponentProps<IRatingParams> {
  className?: string;
  store?: any;
  changeVm?: any;
}

export interface IRatingParams {
  id: string;
}

export interface IRatingState {
  text?: string;
  // loading: boolean;
  // fullList: boolean;
  // users: IRatingUserInfo[];
  // usersReversed: boolean;
  // rating: IRatingValue | null;
  // searchQuery: string;
}

export interface IBadgeStateProps {
  // store: any;
  // context: IContext;
  edit: boolean;
}

export const mapStateToProps = (state: any) => ({
  // store: state.store,
  // context: state.store.context,
  edit: state.edit
})

export interface IActionsDispatch {
  setContext?: any;
}

export const mapActionsToProps: IActionsDispatch = {
  setContext
};

export interface IBadgeQueryOptions {
  skipCount: number;
  count: number;
  eme?: boolean;
  search?: string;
}

export const cnRating = cn('Rating');
