import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { loadCategories, loadBadges } from '../../../redux/actions/LMSTeacherOffice'
///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    course: any;
    updateCourse: (course: any) => void
    isCreate?: boolean
}

export interface IStateProps {
    categories: any;
    catIds: string[];
    fetchStatus: string;
    badgesList: any[]
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    categories: state.LMSTO.categories.entities.values,
    badgesList: state.LMSTO.badgesList,
    catIds: state.LMSTO.categories.result,
    fetchStatus: state.LMSTO.requestsActivity.getCategories
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadCategories,
    loadBadges
}, dispatch);

////////////

export const cnClassName = cn('TOCECourseEditor');