import { likeWikiRequest } from "../../../redux/actions/WikiList";
import { bindActionCreators } from 'redux';
import { IWikiItem } from 'utils/src/requests/models/api.wikilist';
import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import {
    getNewsById
} from 'News/redux/saga/selectors'

export interface IWikiItemProps extends IItemStateProps, IWikiItemOwnProps, IWikiDispatchProps {
    id?: string;
    type?: string;
    url?: string;
    title?: string;
    moderators?: { [s: string]: any }[];
    authors?: { [s: string]: any }[];
    tags?: { [s: string]: any }[];
    titleImageId?: string;
    titleImageUrl?: string;
    newsId?: string;
    likeCount?: number;
    liked?: boolean;
    data: any;
    showControls: boolean;
    context?: any;
    // likeNewsRequest?: (value: any) => void;
};

export interface IItemStateProps extends IWikiStateToProps {
    isAdmin: boolean,
    news: any
 };

export type IWikiDispatchProps = ReturnType<typeof mapDispatchToProps>;

export interface IWikiItemOwnProps extends IClassNameProps {
    tag?: string | React.ComponentType<any>;
    likeCount?: number,
    liked?: boolean,
    data: IWikiItem;
    changeItem?: (item: any)=>void
};

export interface IWikiItemState {
    showDialog: boolean;
    liked: boolean,
};

export type IWikiStateToProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    likeWikiRequest,
}, dispatch); 

export const mapStateToProps = (state: any, props: any) => ({
    news: getNewsById(props.data.news)(state)//state.news.news[props.data.news],
    
    // isAdmin: getAuthUser(state).baseData.isAdmin
});


export const cnItem = cn('WikiItem');
