export const controlStyle = {
    margin: '12px 0'
}

export const peperStyle = {
    margin: '0 12px'
}

export const controlLabelStyle = {
    margin: '12px 0',
    marginLeft: '-6px'
}