import * as React from 'react';
import * as utils from 'utils/src/utils';


// import { withBemMod } from '@bem-react/core';

// import { cnWidget } from '../Widget.index';
// import { IWidgetTypeWikiProps } from './Widget_type_wiki.index';
import './Widget_type_wiki.scss';
import { cnWidget, cnWidgetMui, IWidgetProps } from '../Widget.index';

import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";

import { SecretsWidget } from 'blocks/SecretsWidget/SecretsWidget'

const types = {
  common: require('blocks/SecretsWidget/SecretsWidget').SecretsWidget,
}

const type = 'secretwidget';



export default class WidgetTypeSecretsWidgetPresenter extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    return <Component {...this.props} 
      updateWidget={this.props.updateWidget}
      editToggle={this.props.widget.HTMLWidgetContentEdit}
      className={cnWidgetMui({ type, subtype })}
      context={this.props.wcontext}
      tag={widgets.components.common} />
    // return <Component
    //   {...this.props}
    //   editToggle={this.props.widget.HTMLWidgetContentEdit}
    //   saveWidgetData={saveWidgetData}
    //   updateWidget={this.props.updateWidget}
    //   saveData={saveData}
    //   data={this.props.widget.data || undefined}
    //   className={cnWidget({ type, subtype })}
    //   context={this.props.wcontext}
    //   tag={widgets.components.common}
    // />
  }

}