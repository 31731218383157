import React, { FC, useEffect, memo, useCallback, ComponentProps, useMemo, useRef } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './WorkflowTable.index'
import { connect } from 'react-redux';
// import './style.scss'
import { Button } from 'uielements/src';
import ListPlaceholder from 'uielements/src/ListPlaceholder'
import { Icon } from 'uielements/src/Icon/Icon';
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import { withRouter } from 'react-router-dom'
import NewsItem from '../NewsItem'
import queryString from 'query-string';
import ShortView from '../ShortView'
import { PostSkeleton } from 'blocks/Skeletons/Posts/Post-Skeleton';
import i18n from 'localizations/i18n';
import { useIsLoading, useIsError } from 'utils/src/CommonRedux/LoadIndicator'
import actTypes from '../../redux/actionsTypes/News'
import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg'
import { TViewType } from 'News/redux/reducer/types';
import MiniView from 'News/containers/MiniView';
import ContentView from 'News/containers/ContentView';
import HeadView from 'News/containers/HeadView';
import { keys } from 'lodash';
import { useTimelineLoader } from 'News/utils/hooks'
import { WorkflowGrid } from 'muicomponents/src/WorkflowGrid/WorkflowGrid'

type IPresenterProps = News.WorkflowTable.IPresenterProps
type IOwnProps = News.WorkflowTable.IOwnProps
type IDispatchProps = News.WorkflowTable.IDispatchProps
type IStateProps = News.WorkflowTable.IStateProps
type IState = News.WorkflowTable.IState

const LoadButton = withLoadSpin(Button)

let enableVirtual = window.location.hostname.includes('azurewebsites.net')
enableVirtual = enableVirtual ? enableVirtual : window.location.hostname.includes('localhost')
enableVirtual = false

const viewTypes: { [s in TViewType]?: React.ComponentType<{}> } = {
    short: ShortView,
    mini: MiniView,
    content: ContentView,
    head: HeadView
}

export const viewTypesExluded = ['full', 'short', 'head'] as const;

export const Presenter: FC<IPresenterProps> = ({
    className,
    count,
    loadNewsList,
    isFinished,
    keys,
    values,
    params = { newsTypes: 'all' },
    viewType,
    sending,
    settings,
    // active,
    setActiveNews,
    changeStatusField
}) => {
    const workflowId = params.newsTypes!;
    const container = useRef<HTMLDivElement>(null)
    // if (settings?.viewType) viewType = settings?.viewType;
    const rect = container.current?.getBoundingClientRect()
     let height = window.innerHeight - (rect?.top || 0) - 48 
    height = height < 500 ? 500 : height

    /*const { isFailed, isLoading, loadMore } = useTimelineLoader(params, keys, params.count)
    const result = keys.map(key => values[key])
    const triggerRef = useInfiniteScroll({
        loadMore,
        isLoading,
        isFinished
    }, { rootMargin: (window.innerHeight * 1) + 'px', threshold: 0 }); */

    return <div ref={container}  className={cnClassName({}, [className])}>
        <WorkflowGrid
            height={height}
            workflowId={workflowId}
            // result={result}
            // loadMore={loadMore}
            // isLoading={isLoading}
        />
    </div>;
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));