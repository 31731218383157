import React, { useState, FC, useMemo, useRef } from 'react';
import { IShopFilter } from 'utils/src/requests/models/api.shop';
import { Box, Button, Typography } from 'muicomponents/src';
import { Checkbox } from 'muicomponents/src/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { IStateType } from 'redux/store';
import { setShopFilters } from 'redux/shop/actions';
import { CatsListBox, CountBadgeStyles, FakeBtnBox, FilterBtnText, ItemBox, SearchMenuBox } from '../Filters_type_shopMui.styled';
import GridViewIcon from '@mui/icons-material/GridView';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu } from 'muicomponents/src/Menu';
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput';
import { i18n } from 'localization';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import { cnFiltersMui } from '../Filters_type_shopMui.index';

interface ICategoryOptionProps {
  callBack: () => void
  checked: boolean
  category: IShopFilter
}
export const CategoryOption: FC<ICategoryOptionProps> = ({ category, callBack, checked }: ICategoryOptionProps) => {

  const boldStyles = {fontWeight: '600'}
  const showCount = useMemo(() => !!category.count, [category.count]);

  return <Box display='flex' alignItems='center'>
    <Checkbox checked={checked} onChange={callBack} />
    <Typography sx={category.additionalFields ? boldStyles : {}} >
      {category.name}
    </Typography>&nbsp;
    {
      showCount &&
      <Typography color='GrayText' >
        {category.count} 
      </Typography>
    }
  </Box>
}

interface ICategoryFilterProps {
  categories: IShopFilter[]
}

export const CategoryFilter: FC<ICategoryFilterProps> = ({ categories }: ICategoryFilterProps) => {

  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickMenuBtn = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [searchCatValue, setSearchCatValue] = useState('')
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCatValue(e.currentTarget.value)
  }

  const chosenCatFilters = useSelector((s: IStateType) => s.shop.chosenFilters.catFilters)
  const chosenCatFiltersRef = useRef(chosenCatFilters);
  chosenCatFiltersRef.current = chosenCatFilters;
  const [checkedFilters, setCheckedFilters] = useState<number[]>(chosenCatFilters)
  const handleClickFilter = (filters: number[]) => {
    setCheckedFilters(filters)
  }

  const handleChangeFilters = () => {
    dispatch(setShopFilters({
      key: 'catFilters',
      value: checkedFilters
    }))
    handleClose()
  }

  const enableApplyButton = useMemo(() => {
    const checkedIds = checkedFilters;
    const initialIds = chosenCatFiltersRef.current;
    return checkedIds.length !== initialIds.length
      || !!checkedIds.filter(id => !initialIds.includes(id)).length;
  }, [open, checkedFilters]);

  /* const sortedCategories = useMemo(() => {  // оказывается сортировка по полю isShowOnTop происходит на беке
    if (!categories) return []
    let sortedCats = categories.sort((a, b) => {
      if (a.isShowOnTop || b.isShowOnTop) return 1
      return a.name > b.name ? 1 : -1
    })
    return sortedCats
  }, [categories]) */

  const searchedCategories = useMemo(() => {
    if (!categories) return []
    let filteredCats: IShopFilter[] = []
    if (!searchCatValue) {
      return categories
    } else if (searchCatValue && categories) {
      filteredCats = categories.filter(category => category.name.toLowerCase().indexOf(searchCatValue.toLowerCase()) !== -1)
    }
    return filteredCats
  }, [searchCatValue, categories, categories?.length])

  return <ItemBox >
    <Badge badgeContent={chosenCatFilters.length}
      sx={CountBadgeStyles}
      invisible={!chosenCatFilters.length || open}
      color='error'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        size='small'
        onClick={handleClickMenuBtn}
        className={cnFiltersMui('categoryButton')}
        endIcon={<ArrowDropDownIcon htmlColor='GrayText' sx={{ flexShrink: 1 }} />}
        startIcon={<GridViewIcon htmlColor='GrayText' sx={{ mr: 1, flexShrink: 1, }} />}
      >

        
        <FakeBtnBox>
          <FilterBtnText variant='body2' color='ActiveCaption' textTransform='none' noWrap >
            {i18n.t("pryaniky.list.shop.header.categories")}
          </FilterBtnText>
        </FakeBtnBox>

      </Button>
    </Badge>

    <Menu open={open}
      anchorEl={anchorEl}
      id="demo-customized-menu"
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'demo-customized-button',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      anchorReference='anchorEl'
      PaperProps={{ sx: { overflow: 'hidden' } }} >
      <SearchMenuBox>
        <SearchInput value={searchCatValue} onChange={onSearchChange} />
      </SearchMenuBox>
      <CatsListBox>
        {searchedCategories.map((cat) => {
          const isChecked = checkedFilters.includes(cat.id)
          const newChosenCats = isChecked ? checkedFilters.filter(id => id !== cat.id) : [...checkedFilters, cat.id]
          return <MenuItem key={cat.id} sx={{ padding: 0 }} onClick={() => handleClickFilter(newChosenCats)} >
            <CategoryOption checked={isChecked}
              category={cat}
              callBack={() => handleClickFilter(newChosenCats)} />
          </MenuItem>
        })}
      </CatsListBox>

      <MenuItem>
        <Button disabled={!enableApplyButton}
          variant='contained' size='small' onClick={handleChangeFilters} >
          {i18n.t('pryaniky.filter.feed.use')}
        </Button>
      </MenuItem>
    </Menu>
  </ItemBox>
}