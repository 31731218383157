import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets'
import { guids } from "./index"
import i18n from '../localizations/i18n'
import ideasLogo from 'assets/png/Ideas.png'
import { pageHeader } from './pageHeaders'
// interface IEvents {
//   events: string;
//   filters: string;
// }

export const notices = (): IWidget<IColumn[]> => {
  
  // const ids = guids<IEvents>(['events', 'filters']);

  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.info,
            data: pageHeader.notices,
          }),

          generateWidget({
            type: widgets.types.layout,
            data: [
              generateColumn({
                items: [
                  generateWidget({
                    type: widgets.types.timeline,
                  }),
                ],
                styles: {
                  width: '72%',
                },
              }),
            ],
          }),
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  })
}