import * as React from 'react';
import * as utils from 'utils/src/utils';

import './Widget_type_editorjs.scss';
import { cnWidget, IWidgetProps } from '../Widget.index';

import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";

const types = {
  common: require('blocks/Wiki/WikiEditorJs').Wiki,
}

const type = 'wiki';



export default class WidgetTypeWikiPresenter extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    const saveData = (d: any) => {
    }
    const saveWidgetData = () => { this.props.setStructureFromWidget(this.props.widget.id) }
    return <Component {...this.props} saveWidgetData={saveWidgetData} saveData={saveData} data={this.props.widget.data || undefined} className={cnWidget({ type, subtype })} context={this.props.wcontext} tag={widgets.components.common} />
  }

}

