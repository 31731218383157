import { ICreativetasksendPropsType, ICreativetasksendStateType, mapStateToProps, TStateProps } from './Creativetasksend.index'
import React, { FC, memo } from 'react';
import { connect } from 'react-redux';
import './Creativetasksend.scss'
import { } from './Utils'
import { withNewsContext } from 'News/contexts/news'
import TextRender from 'News/containers/TextRender'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import ContentLayout from 'News/components/ContentLayout'


const CreativetasksendPresenter: FC<ICreativetasksendPropsType & TStateProps> = ({ isSingle, text }) => <NewsView className={'type-13'}>
    <ContentLayout>
        <TextRender />
    </ContentLayout>
</NewsView>

export const Creativetasksend = withNewsContext(connect(
    mapStateToProps
)(memo(CreativetasksendPresenter)));


export const initCreativetasksend = () => {
    ComponentInjector.getInstance().addNode('news_types', <Creativetasksend />, 'creativetaskwinners')
    ComponentInjector.getInstance().addNode('news_types', <Creativetasksend />, 'creativetaskcanceled')
}