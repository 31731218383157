import { createDefaultPostNews } from '../../baseNews';
import { NCreativeTasks } from './CreativeTasks.interface';
const createBaseExpires = () => {
    const currentDate = new Date()
    currentDate.setUTCHours(0)
    currentDate.setUTCMinutes(0)
    currentDate.setUTCSeconds(0)
    currentDate.setUTCMilliseconds(0)

    currentDate.setUTCDate(currentDate.getUTCDate() + 1)
    return currentDate
}
const baseExpires = createBaseExpires().toISOString()//new Date().toISOString();

export const baseCreativeTasks: NCreativeTasks.Create = ({
    ...createDefaultPostNews(),
    newstype: 6,
    componentRenderName: 'creativetasks',
    expires: baseExpires,
    creativeTask: {
        endDateTime: baseExpires,
        fund: 0,
        state: 'Going',
        title: ''
    }
})