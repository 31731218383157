import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from 'redux/connector';
// import { IContext } from 'store/vm';
import { RouteComponentProps } from 'react-router';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface IInfoBlockProps extends IClassNameProps, IInfoBlockStateProps, IDispatchProps, RouteComponentProps {
  tag: React.ComponentType<any> | string;
  'data-id': string;
  widget?: Record<string, any>;
  context: {[s: string]: any};
  settings: {[s: string]: any} | null;
  // IContext
}


export interface IInfoBlockState {
  text?: string;
  edit: boolean;
}

export interface IInfoBlockStateProps {
  edit: boolean;
  authUser: {[s: string]: any};
  // widgets: { [s: string]: any };
  // context: {[s: string]: any};
}
// export type IInfoBlockStateProps = ReturnType<typeof mapInfoBlockStateToProps>

export const mapInfoBlockStateToProps = (state: any, props: IInfoBlockProps) => ({
  edit: state.store.edit,
  authUser: getAuthUser(state),
})

export const cnInfoBlock = cn('InfoBlock');