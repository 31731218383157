import React, { FC, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { cnResults, IResultsProps, mapDispatchToProps, mapStateToProps } from './Results.index';
import './Results.scss';
import { SessionResult } from '../SessionResult/SessionResult'
import { UnitResult } from './UnitResult/UnitResult'
import { UnitList } from './UnitList/UnitList'
import queryString from 'query-string';
import { withPreloader } from '../Preloader/Preloader'
import { CourseLayout } from '../../components/CourseLayout/CourseLayout'
import { Unit as Skelet } from '../../Skelets/Unit/Unit'
import { CoursePassing } from '../../Skelets/CoursePassing/CoursePassing'
import CompleteBox from '../ManualCompleteSection'
import { withRouter } from 'react-router-dom'
import { tS } from 'LMSModule/utils/i18n.adaptor'
import {
    getUnitResultsById,
    getSessionById
} from '../../redux/sagas/LMS/selectors'
import { withResultLoader } from 'LMSModule/utils/HOCs/withResultLoader'
import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg'
const viewSelector = (requestParams: any, unit: any) => {
    let view = 'session'
    if (requestParams.unit === 'result' || !requestParams.unit) view = 'session'
    else if (requestParams.unit && unit) view = 'unit'
    else if (!unit) view = 'no'
    return view
}


const useResultViewSelector = (requestParams: any, sid: string) => {
    const unitResult = useSelector(getUnitResultsById(sid, requestParams.unit));

    return viewSelector(requestParams, unitResult)
}

const ContentSelector: FC<any> = ({ requestParams, cid, sid, course, result }) => {
    const view = useResultViewSelector(requestParams, sid)

    return <>
        {view === 'session' && <SessionResult units={course.courseUnits} cid={cid} sId={sid} result={result} />}
        {view === 'unit' && <UnitResult course={course} sid={sid} uid={requestParams.unit} />}
        {view === 'no' && <div>{tS('noResult.1')}</div>}
    </>
}
const SelectorWithLoader = withPreloader(ContentSelector, ['result'], [], <Skelet />)

const SelectorLoader = withResultLoader(SelectorWithLoader)

export const ConnectedContentSelector = SelectorLoader;


//////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////
export const ResultsPresenter: React.FC<IResultsProps & any> = ({
    course,
    match,
    location,
    defaultSid
}) => {
    const [fullscreen, setFullscreen] = useState(false)
    const requestParams = queryString.parse(location.search)
    const sid = requestParams.sid || defaultSid
    const session = useSelector(getSessionById(sid));
    if (!session) {
        return <CourseLayout
            fullscreen={false}
            allowFullscreen={false}
            setFullscreen={setFullscreen}
            // course={course}
            classNames={[]}
        >
            <ErrorMsg type='warning'>
                Сессия "{sid}" не найдена.
            </ErrorMsg>
        </CourseLayout>
    }
    return <CourseLayout
        fullscreen={fullscreen}
        allowFullscreen={true}
        setFullscreen={setFullscreen}
        // course={course}
        classNames={[]}
        middle={<CompleteBox cid={course.id} sid={sid as string} />}
        aside={
            <div className={cnResults('Aside')}>
                <UnitList cid={course.id} sid={sid} activeId={requestParams.unit} />
            </div>
        }
    >
        <ConnectedContentSelector requestParams={requestParams} cid={course.id} sid={sid} course={course} />
    </CourseLayout>
};

const WithLoader = withPreloader(withRouter(ResultsPresenter), ['course'], [], <CoursePassing />)

export const Results = WithLoader
