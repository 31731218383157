import { GUID_EMPTY } from 'utils/src/constants.prn';
import { NLeftMenuReducer } from './redux/interfaces';

export function getParentChain(items: { [key: NLeftMenuReducer.Item['id']]: NLeftMenuReducer.Item }, item: NLeftMenuReducer.Item) {
    let parent = item.parentId;
    const rezultArray: typeof parent[] = [];
    while(parent && parent !== GUID_EMPTY) {
        rezultArray.push(parent);
        parent = items[parent]?.parentId;
    };
    return rezultArray;
};