import React from 'react';
import { cn } from '@bem-react/classname';
import i18n from 'localizations/i18n';
import { Icon } from 'uielements/src/Icon/Icon';

const cnCourseSteps = cn('CourseSteps');

interface IProps {
  steps: any[];
}

export const Steps: React.FC<IProps> = ({ steps }) => {
  return (
    <ul className={cnCourseSteps()}>
      <h5 className={cnCourseSteps('Title')}>{i18n.t('study_step')}</h5>
      {steps.map(({ isHidden, name, type, ...step }) => (
        <li key={name} className={cnCourseSteps('Item', { muted: isHidden })}>
          {`${name} ${type === 'test' ? i18n.t('attempts') + step.attemptsCount + '/' + step.maxAttemptsCount : ''}`}
          {type === 'test' && isHidden && <Icon icon="clock" />}
          {type === 'test' && step.isCompleted && <Icon icon="check" />}
        </li>
      ))}
    </ul>
  );
};
