import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { getQuiz, changeActiveQuizIdx } from 'redux/actions/Quiz'

export interface IQuizWidget extends IQuizWidgetStateProps, IQuizWidgetDispatchProps {
  tag: any
  settings: any
  className?: string
  store?: any
  changeVm?: any
  context?:{[key:string]: any}
}

export const mapStateToProps = (state: IState) => {
  
  return {
    dataLength: state.quiz.quizWidget.quizzesData.length,
    activQuizIdx: state.quiz.quizWidget.activeQuizIdx,
    edit: state.store.edit
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getQuiz,
  changeActiveQuizIdx,
}, dispatch);

export type IQuizWidgetStateProps = ReturnType<typeof mapStateToProps>
export type IQuizWidgetDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnQuizWidget = cn('QuizWidget')
export const cnClassNameNoData = cn('QuizWidgetNoData')