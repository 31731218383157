import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IStateType as IState } from 'redux/store';
import { IStoreAppSetting } from 'models/store/appSettings';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { IQuestGoal } from 'utils/src/requests/models/api.quests';

import { bindActionCreators, Action, Dispatch } from 'redux';
import { approveQuestGoal } from 'redux/quests/actions';


export type IMapStateToProps = ReturnType<typeof mapStateToProps>;
export type IMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
export interface IGoalItemProps extends IMapStateToProps, IMapDispatchToProps {
  data: IQuestGoal;
  type: string;
  questId: string;
  listId?: string;
}

export const mapStateToProps = (state: IState) => ({
  currencyFormats: state.store.appSettings as IStoreAppSetting,
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  approveQuestGoal,
}, dispatch);



export const cnGoalItem = cn('GoalItem');