import { IIdeaFieldProps } from '../FieldProps';
import React from 'react';
import { UserList } from 'uielements/src/UserList/UserList';

export const ResponsibleForStatusUser: React.FC<IIdeaFieldProps> = ({ field}) => {
    return (
        <div>
            ResponsibleForStatusUser
            {/*<UserList cut={false} action={false} activeAction={false} data={f.expertAnswers} />*/}
        </div>
    );
}