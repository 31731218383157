import * as types from './actions.d' 
import actions from './actionsType'


export const loadReasons: types.CLoadReasons = () => ({
    type: actions.LOAD_REASONS
})

export const setReasons: types.CSetReasons = (payload) => ({
    type: actions.SET_REASONS,
    payload
})