import React from 'react';
import { Skeleton } from 'uielements/src';
import './Reply-Skeleton.scss';

export const ReplySkeleton = () => (
    <div className="ReplySkeleton">
        <div className="ReplySkeleton-Header" style={{ alignItems: 'flex-start'}}>
            <Skeleton shape="circle" style={{margin: 0}} />
            <div style={{ flexGrow: 1, marginLeft: 16, display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end'}}>
                <Skeleton shape="line" style={{ width: '20%', marginTop: 0,marginBottom: 0 }} />
                <Skeleton size="xs" shape="line" style={{ width: '10%', height: '8px', marginLeft: '8px', marginTop: 0, marginBottom: 0 }} />
                <div style={{ flex: '1 1 100%' }}>
                    <Skeleton size="xs" shape="line" style={{ width: '60%', height: '8px', flex: 1 }} />
                    <Skeleton size="xs" shape="line" style={{ width: '80%', height: '8px', flex: 1 }} />
                </div>  
            </div>
        </div>

    </div>
);
