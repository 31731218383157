import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';

export interface IExamProps extends IClassNameProps, IDispatchProps,
    RouteComponentProps {
    tag?: 'div';
    sId: string;
}


export const cnExam = cn('Exam');