import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
  SET_DATA: '',
  ADD_DATA: '',
  ADD_HEADER: '',
  REMOVE_DATA: '',
  CLEAR_DATA: '',
  KILL: '',
  CHECK_ITEM: '',
  CHECK_ALL_ITEMS: '',
  CHANGE_ITEM: '',
  ADD_SUBITEMS: '',
  REMOVE_SUBITEMS: '',
  TOGGLE: '',
  SET_ADDITIONAL_DATA: '',
  CHANGE_ROOT_EL_COUNT: '',
  CLEAR_SELECTED_ITEMS: '',
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.LIST)}), {} as typeof actions);