import { bindActionCreators } from 'redux';
import { getAlbum, deletePhotoMultiple, selectAllPhoto, uploadPhotos, getPhoto } from "../../../redux/actions/Photoalbums";
// import { AlbumType } from '../Album/Album.type';
// import { IAttachmentType } from 'blocks/Preview/Preview.index'
import { getGroupByPKID } from 'redux/sagas/Groups/selectors'
export interface IAlbumViewProps extends IBaseAlbumProps, IAlbumStateProps, ReturnType<typeof mapDispatchToProps> {

}

export interface IBaseAlbumProps {
    aid: string;
    gid: any;
    photoView?: boolean
}

export interface IAlbumStateProps {
    pids: any,
    count: number;
    selected: string[];
    isFinished: boolean;
    isLoading: boolean;
    showControls?: boolean;
    // name: string;
}
const noopArray: any[] = []
export const mapStateToProps = (state: any, props: IBaseAlbumProps) => {
    let pids: any[] = noopArray
    let count = 0;
    let selected: string[] = noopArray;
    let isFinished: boolean = false;
    let isLoading: boolean = false;
    // let name = '';
    let showControls = false;
    if (props.aid && props.aid in state.photoalbums.photos) {
        pids = state.photoalbums.photos[props.aid].result;
        count = pids.length;
        selected = state.photoalbums.photos[props.aid].selected;
        isFinished = state.photoalbums.photos[props.aid].isFinished;
        isLoading = state.photoalbums.photos[props.aid].isLoading;
        // name = state.photoalbums.albums[props.aid].name
        showControls = (props.gid in state.groups.groups) ? getGroupByPKID(props.gid)(state).isGroupAdmin : showControls
    }
    return { pids, count, selected, isFinished, isLoading, showControls/*name, album: state.photoalbums.albums[props.aid]*/ }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    getPhoto,
    getAlbum,
    deletePhotoMultiple,
    selectAllPhoto,
    uploadPhotos
}, dispatch);