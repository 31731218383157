/**
 * @packageDocumentation
 * @module Filter_type_groups
 */
import React, { ComponentProps, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FiltersPresenter } from '../Filters';
import { IFiltersStateProps, IFiltersDispatchProps, IFiltersProps, mapStateToProps, mapDispatchToProps, cnFilters } from '../Filters.index';
import { IStateType as IState } from 'redux/store';
import { connect } from 'react-redux';
import { Translate } from 'localizations/Translate';
import { withRouter } from 'react-router';
import { FilterTypeAchievementsProps, TAchievementsfilterValue, TFilterTypeAchievementsState } from './Filters_type_achievements.index';
import { FilterTypeAchievementsActionsBox, FilterTypeAchievementsBox } from './Filters_type_achievements.styled';
import { AchievementsGroupsSuggester, AchievementsSuggester, UsersSuggester } from 'muicomponents/src/Suggester';
import { Checkbox } from 'muicomponents/src/Checkbox';
import { FormControlLabel } from 'muicomponents/src/FormControl';
import { Typography } from 'muicomponents/src/Typography';
import { TAchievementsSuggesterItem } from 'utils/src/BaseTypes/suggesters';
import { Button } from 'muicomponents/src';
import { CleaningServices } from 'muicomponents/src/Icons';
import { useDidUpdateEffect } from 'utils/src/hooks';

const i18nKey = (string: string) => `pryaniky.filter.achievements.${string}`;

const localStorageKey = 'FiltersTypeAchievements';

const filterBaseValue: TFilterTypeAchievementsState = {
    selectedAuthors: null,
    onModeration: false,
    achievementsGroups: null,
    achievements: null
}

const useValueWithLocalStorage = () => {
    let localStorageValue = useMemo(() => {
        let rez = filterBaseValue;
        const value = localStorage.getItem(localStorageKey);
        if(!value) return rez;
        try {
            rez = JSON.parse(value);
        } catch (error) {
            console.error('error', error);
        } finally {
            return rez;
        }
    }, []);

    const setLocalStorageValue = (value: TFilterTypeAchievementsState) => {
        localStorage.setItem(localStorageKey, JSON.stringify(value));
    };

    const clearLocalStorageValue = () => {
        localStorage.removeItem(localStorageKey);
    };

    return {
        localStorageValue,
        setLocalStorageValue,
        clearLocalStorageValue
    };    
};

const FiltersTypeAchievementsPr: FC<FilterTypeAchievementsProps> = ({
    onChange
}) => {

    const {
        localStorageValue,
        setLocalStorageValue,
        clearLocalStorageValue
    } = useValueWithLocalStorage();

    const [state, setStateValue] = useState<TFilterTypeAchievementsState>(localStorageValue);
    const stateRef = useRef(state);
    stateRef.current = state;

    const [edited, setEdited] = useState(false);

    const setState = useCallback((value: Partial<typeof state>) => {
        setStateValue({
            ...stateRef.current,
            ...value
        });
        setEdited(true);
    }, []);

    const achievementsGroupsParams = useMemo(() => {
        const { achievementsGroups } = state;
        if(!achievementsGroups) return undefined;
        const value = Array.isArray(achievementsGroups) ? achievementsGroups : [achievementsGroups];
        const splitedData = value.map(el => el.id.split('/'));
        const rez = {
            ratingPkids: splitedData.map(el => el[0]),
            questIds: splitedData.map(el => el[1])
        };
        if(Array.isArray(stateRef.current.achievements)) {
            setState({
                achievements: stateRef.current.achievements.filter(el => {
                    const item = el as any as TAchievementsSuggesterItem;
                    return rez.ratingPkids?.includes(item.ratingId.toString()) && rez.questIds?.includes(item.questId);
                })
            });
        } else {
            const item = stateRef.current.achievements as any as TAchievementsSuggesterItem;
            const newAchievements = rez.ratingPkids?.includes(item?.ratingId.toString()) && rez.questIds?.includes(item?.questId) ? item : null;
            setState({
                achievements: newAchievements as typeof stateRef.current.achievements
            });
        }
        return rez;
    }, [state.achievementsGroups]);

    const filterValue = useMemo(() => {
        const { onModeration, achievements, selectedAuthors } = state;
        const rez: TAchievementsfilterValue = {};
        rez.moderation = onModeration || undefined;
        if(achievements) {
            const achievementsValue = Array.isArray(achievements) ? achievements : [achievements];
            rez.typePkids = achievementsValue?.length ? achievementsValue?.map(el => el.id) : undefined; 
        }
        if(achievementsGroupsParams && achievementsGroupsParams.questIds) {
            rez.typeGroupIds = achievementsGroupsParams.questIds;
        }
        if(selectedAuthors) {
            const selectedAuthorsValue = Array.isArray(selectedAuthors) ? selectedAuthors : [selectedAuthors];
            rez.authorIds = selectedAuthorsValue?.length ? selectedAuthorsValue?.map(el => el.id) : undefined;
        }
        return rez;
    }, [state.onModeration, state.achievements, achievementsGroupsParams, state.selectedAuthors]);

    const applyFilter = () => {
        setLocalStorageValue(state);
        onChange(filterValue);
        setEdited(false);
    };

    useEffect(() => {
        if(filterValue.authorIds || filterValue.moderation || filterValue.typeGroupIds || filterValue.typePkids) {
            applyFilter();
        }
    }, []);

    const clearFilter = () => {
        setStateValue(filterBaseValue);
        clearLocalStorageValue();
        onChange({
            authorIds: undefined,
            moderation: undefined,
            typeGroupIds: undefined,
            typePkids: undefined
        });
        setEdited(false);
    };

    return (
        <FilterTypeAchievementsBox>
            <Typography
                variant={'h6'}
                sx={{
                    fontWeight: 'bold'
                }}
            >
                <Translate i18nKey={'filter settings'} />
            </Typography>
            <FormControlLabel
                checked={state.onModeration}
                control={<Checkbox />}
                label={<Translate i18nKey={'on moderation'} />}
                onChange={(e) => {
                    const value = (e.target as HTMLInputElement).checked;
                    setState({
                        onModeration: value
                    });
                }}
            />
            <AchievementsGroupsSuggester
                value={state.achievementsGroups}
                multiple
                onChange={(e, value) => {
                    setState({
                        achievementsGroups: value
                    });
                }}
                TextFieldProps={{
                    placeholder: Translate.t({ i18nKey: i18nKey('placeholder.achievementsGroups') })
                }}
                withCheckboxes
            />
            <AchievementsSuggester
                value={state.achievements}
                multiple
                onChange={(e, value) => {
                    setState({
                        achievements: value
                    });
                }}
                requestAdditionalParams={achievementsGroupsParams}
                TextFieldProps={{
                    placeholder: Translate.t({ i18nKey: i18nKey('placeholder.achievements') })
                }}
                withCheckboxes
            />
            <UsersSuggester
                value={state.selectedAuthors}
                multiple
                onChange={(e, value) => {
                    setState({
                        selectedAuthors: value
                    });
                }}
                TextFieldProps={{
                    label: <Translate i18nKey={i18nKey('authors')} />
                }}
                requestAdditionalParams={{
                    excludeMe: false
                }}
                withCheckboxes
            />
            <FilterTypeAchievementsActionsBox>
                <Button
                    disabled={!edited}
                    variant='contained'
                    onClick={applyFilter}
                >
                    <Translate i18nKey={'apply'} />
                </Button>
                <Button
                    variant='outlined'
                    onClick={clearFilter}
                    startIcon={<CleaningServices />}
                >
                    <Translate i18nKey={'clear filter'} />
                </Button>
            </FilterTypeAchievementsActionsBox>
        </FilterTypeAchievementsBox>
    );
};

class FiltersTypeAchievementsPresenter extends FiltersPresenter {

    public get contextVariables() {
        return this.props.relations.filter(el => el !== (this.props as any).widget?.id) || ['common']
    };
    
    public onChange: FilterTypeAchievementsProps['onChange'] = (params) => {
        this.props.changeContextv3(this.contextVariables.reduce((acc: any, conID) => {
          acc[conID] = params;
          return acc
        }, {} as {[s: string]: any}))
    };

    /**
     * render - it is render)
     */
    public render() {
        const { tag: Tag = 'div', className } = this.props;
        return (
            <Tag tag='div' {...this.props} className={cnFilters({}, [className])} >
                <FiltersTypeAchievementsPr onChange={this.onChange} />
            </Tag>
        )
    };
};


/**
 * connected to redux
 */
export const FiltersTypeAchievements = withRouter(
  connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersProps, IState>(
    mapStateToProps,
    mapDispatchToProps
  )(FiltersTypeAchievementsPresenter)
);