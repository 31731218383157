import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    IDialogContentPresenterProps
} from './interfaces'
import './style.scss'
import DialogContent from '@material-ui/core/DialogContent';

export const Presenter: FC<IDialogContentPresenterProps> = ({ children, className, ...other }) => {
    return <DialogContent dividers className={cnClassName({}, [className])}>
            {children}
    </DialogContent>
}

export default memo(Presenter);
