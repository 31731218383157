import { Box, BoxProps, styled } from '@material-ui/core';
import React, { forwardRef } from 'react';
import { CustomSettings } from 'utils/src';
import { styled as muiStyled } from 'muicomponents/src/mui/system';
import { Button } from 'muicomponents/src/Button/Button';
import { ButtonGroup } from 'muicomponents/src/ButtonGroup/ButtonGroup';

export const gapRows = CustomSettings.baseGapBetweenWidgets() || 24;

// export const gapColumns = CustomSettings.baseGapBetweenColumns() || 0;

const BoxLayoutSyled = styled(Box)({
    display: 'flex',
    margin: '0 -1%'
    // gap: gapColumns + '%'
}) as typeof Box;

export const BoxLayout = forwardRef<HTMLElement, BoxProps>((props, ref) => {
    return <BoxLayoutSyled {...{ ...props, ref }} />
});

export const ResizeHandlerBox = styled(Box)({
    width: 2,
    border: '1px dashed',
    boxSizing: 'border-box',
    margin: '0 -1px',
    zIndex: 1
}) as typeof Box;

export const ResizeHandler = styled('div')({
    height: '100%',
    cursor: 'col-resize',
    margin: '0 -4px'
});

const ColumnStyles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    boxSizing: 'border-box',
    padding: '0px 1%',
    // flex: 1,
    gap: gapRows,
    '& > .Widget': {
        margin: 0
    }
} as const;

const BoxColumnStyled = styled(Box)({
    ...ColumnStyles,
    '&:empty': {
        display: 'none'
    }
});

// export const SortableLayout = styled(ReactSortable)(ColumnStyles);

export const BoxColumn = forwardRef<HTMLElement, BoxProps>((props, ref) => {
    return <BoxColumnStyled {...{ ...props, ref }} />
});

export const ColumnActions = muiStyled(ButtonGroup)({
    background: '#fff'
}) as typeof ButtonGroup;

export const ActionButton = muiStyled(Button)({
}) as typeof Button;

