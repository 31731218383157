import { API, checkResponseStatus, TValue } from "utils/src";
import pages from 'pageStructures';
import { defaultPage, widgetTypesForManipulationWithChildWidgets } from "./constants";
import { TPage } from "utils/src/BaseTypes/pages.types";
import { NWidgets } from "./redux/types";
import { TWidgetsUnion } from "utils/src/BaseTypes/widgets.types";
import { checkTypeToCanManipulateWithChildWidgets, prepareContextFromSettings } from "./utils";
import { IResponseWithData } from "utils/src/requests/models/api.base";
import { cloneDeep, fromPairs, keyBy } from "lodash";
import { TWidgetsUnionSimplified } from "./types";
import { WidgetsTypes } from "utils/src/widgets/types";

const prepareStructure = () => {

};

export const preparePageName = (name: string) => name.split('.').join('/');

/**
 * generate base structure by pages structures
 * @param pageName 
 * @param params 
 * @param fixedPageName 
 * @returns 
 */
const generateStructure = (pageName: string, params: any, fixedPageName?: string ): TPage => {
    const layout = pages[fixedPageName || pageName]
    return {
        ...defaultPage,
        url: '/' + pageName,
        layout: layout ? layout(params) : pages.common()
    }
};

/**
 * get all wodgets recursively from page
 * @param widget 
 * @param parentWidgetId 
 * @returns 
 */
const getWidgets = (widget: TWidgetsUnion, parentWidgetId?: string) => {
    let widgets: TWidgetsUnion[] = [];
    if (!widget.relations) widget.relations = [parentWidgetId || 'common', widget.id];
    if (!parentWidgetId && widget.relations[0] !== 'common') widget.relations.splice(0, 0, 'common');
    if (parentWidgetId && widget.relations[0] !== parentWidgetId) widget.relations.splice(0, 0, parentWidgetId);
    if (widget.relations[1] !== widget.id) widget.relations.splice(1, 0, widget.id);
    widgets.push(widget);
    if(checkTypeToCanManipulateWithChildWidgets(widget.type, true)) {
        widget.data?.forEach(column => {
            column.items.forEach(widg => {
                const widgs = getWidgets(widg, widget.id);
                widgets = [ ...widgets, ...widgs ];
            })
        });
    }
    return widgets;
};

const setBaseSettings = (widget: TWidgetsUnionSimplified) => {
    if (widget.type === WidgetsTypes.timeline && (widget as any).newsType) {
        return {
            newsTypes: (widget as any).newsType,
        };
    } else if (widget.type === WidgetsTypes.filters) {
        return {
            newsTypes: 'all',
        };
    }
    return {};
};

/**
 * 
 * @param page 
 * @returns 
 */
export const prepareStructureToRedux = (page: TPage): {
    widgets: NWidgets.Reducer['widgets'],
    page: TValue<NWidgets.Reducer['pages']>,
    contexts: NWidgets.Reducer['contexts'],
    structure: TValue<NWidgets.Reducer['structures']>
} => {

    const contexts: NWidgets.Reducer['contexts'] = {
        common: {
            __parent: ''
        }
    };

    const widgetsArray: TWidgetsUnionSimplified[] = getWidgets(page.layout).map(widget => {
        if(checkTypeToCanManipulateWithChildWidgets(widget.type, true)) {
            return {
                ...widget,
                data: (widget.data || []).map(col => {
                    return {
                        ...col,
                        items: (col.items || []).map(el => el.id)
                    }
                })
            } as TWidgetsUnionSimplified;
        }
        return widget as TWidgetsUnionSimplified;
    });

    widgetsArray.forEach(widget => {
        contexts[widget.id] = {
            __parent: widget.relations[0],
            ...setBaseSettings(widget),
            ...prepareContextFromSettings(widget.settings)
        }
    });

    const prepairedPage = {
        ...page,
        layout: page.layout.id
    };

    contexts.common = {
        ...contexts.common,
        ...prepairedPage.context
    };

    return {
        contexts,
        page: prepairedPage,
        structure: prepairedPage.id,
        widgets: fromPairs(widgetsArray.map(el => [el.id, el]))
    };
};

/**
 * 
 * @param pageName - page name
 * @param contextParams - parameters to request
 * @param cb - callback after success get data
 */
export const getPage = async function (pageName: string, contextParams: any = {}, cb?: any) {
    // prepare page name if it forward as old variant
    const pn = preparePageName(pageName);
    const response = await API.pages.get(pn).r as IResponseWithData<TPage>;
    if(checkResponseStatus(response)) {
        return response.data
    } else {
        if(response.error_code === 2007) {
            return generateStructure(pageName, contextParams, 'forbidden2007');
        } else {
            return generateStructure(pageName, contextParams);
        }
    };
};



// function upStructure(this: { widgets: IdefaultWidegtsWidgets, types: WidgetTypes }, widget: IWidget<any>) {
//     if (
//         widget &&
//         (
//             widget.type === this.types.layout ||
//             widget.type === this.types.layout + '/horizontal' ||
//             widget.type === this.types.tabs ||
//             widget.type === this.types.tabsControl ||
//             widget.type === this.types.grid
//         )
//     ) {
//         widget.data.forEach((col: IColumn) => {
//             col.items = (col.items as string[]).map((id) => upStructure.call(this, utils.clone(this.widgets[id])))
//         });
//     }
//     if (widget.relations[0] = 'common') widget.relations.splice(0, 1);
//     return widget;
// }

/**
 * 
 * besause types don't forward currect we typecast "as TWidgetsUnion"
 * @param widgetId 
 * @param widgets 
 * @returns 
 */
const unsimplifyWidget = (widgetId: TWidgetsUnionSimplified['id'], widgets: NWidgets.Reducer['widgets']): TWidgetsUnion => {
    let widget = cloneDeep(widgets[widgetId]);
    if(checkTypeToCanManipulateWithChildWidgets(widget.type, true)) {
        return {
            ...widget,
            data: widget.data?.map(column => {
                return {
                    ...column,
                    items: column.items.map(id => {
                        return unsimplifyWidget(id, widgets)
                    })
                }
            }) || null
        } as TWidgetsUnion;
    }
    return widget as TWidgetsUnion;
};

export const preparePage = function(
    pageName: NWidgets.Reducer['active'],
    widgets: NWidgets.Reducer['widgets'],
    pages: NWidgets.Reducer['pages'],
    structures: NWidgets.Reducer['structures']
) {
    const pageId = structures[pageName];
    const simplifiedPage = cloneDeep(pages[pageId]);
    const page: TPage = {
        ...simplifiedPage,
        layout: unsimplifyWidget(simplifiedPage.layout, widgets)
    };
    return page;
};

export const setPage = async function(page: TPage) {

};