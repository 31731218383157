import React, {FC} from 'react';
import { IItemChipProps} from './ItemChip.index'
import './ItemChip.css'

export const ItemChip: FC<IItemChipProps & any> = ({ children, remove }) => {
    return (
        <div className='ItemChip'>
            <div className='ItemChip-Content'>{children}</div>
            <div className='ItemChip-CloseBox'><span className='ItemChip-Close' onClick={remove}>X</span></div>
        </div>
    )
}
