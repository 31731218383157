import { clone } from 'utils/src/utils';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { LEFTMENU_ADD_ITEM, LEFTMENU_UPDATE_STRUCTURE, LEFTMENU_DELETE_ITEM, LEFTMENU_INIT_DATA, LEFTMENU_MOVE_ITEM, LEFTMENU_SERVICE_SET_EDIT, LEFTMENU_SERVICE_SET_EXPANDED, LEFTMENU_SERVICE_SET_EXPANDED_ALL, LEFTMENU_SERVICE_SET_HIDDEN, LEFTMENU_SERVICE_SET_OPENED, LEFTMENU_UNSAVE_DATA, LEFTMENU_UPDATE_ITEM, LEFTMENU_UPDATE_ITEMS } from './actions';
import { IMenu, IMenuActions } from './interfaces';
import { baseReducer, baseMenuItem, baseStructureItem } from './constants';
import { keyBy, union } from 'lodash';
import { ItemBlockType, ItemRenderType } from 'utils/src/requests/models/api.menu';

export const reducerMenu = (state: IMenu.Reducer = baseReducer, action: IMenuActions.Actions): IMenu.Reducer => {
  switch (action.type) {

    case LEFTMENU_INIT_DATA: {
      return {
        ...state,
        structure: action.payload.structure,
        items: action.payload.items
      }
    }

    case LEFTMENU_UPDATE_STRUCTURE: {
      return {
        ...state,
        structure: action.payload,
      }
    }

    case LEFTMENU_UNSAVE_DATA: {
      if (action.payload === 'set') return {
        ...state,
        service: {
          ...state.service,
          edit: false,
          backupStructure: clone(state.structure)
        }
      }
      if (action.payload === 'restore') return {
        ...state,
        service: {
          ...state.service,
          // if edit is finished then all expanded closing
          expanded: [],
          opened: false,
          edit: false
        },
        structure: clone(state.service.backupStructure)
      }
      return state
    }

    case LEFTMENU_ADD_ITEM: {
      const { path, type, toItem } = action.payload;
      const expanded = state.service.expanded.includes(toItem);
      const item: IMenu.StructureItem = {
        id: action.payload.item.id,
        blockType: action.payload.item.blockType || ItemBlockType.created,
        childrens: action.payload.item.renderType === ItemRenderType.dropdown ? [] : null
      };
      // insert item into opened dropdown
      if (type === 'after' && expanded) {
        path.splice(path.length, 0, toItem);
      }
      const addEl = (els: IMenu.Structure) => {
        if (!path.length) {
          els = [...els];
          if (type === 'before') {
            els.splice(els.findIndex(el => el.id === toItem), 0, item);
          }
          if (type === 'after') {
            els.splice(els.findIndex(el => el.id === toItem) + 1, 0, item);
          }
        } else {
          const ids = path.splice(0, 1);
          els = els.map(el => {
            if (ids.includes(el.id)) return { ...el, childrens: addEl(el.childrens || []) };
            return el;
          });
        }
        return els;
      }
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.item.id]: {
            ...baseMenuItem,
            ...action.payload.item
          }
        },
        structure: addEl(state.structure)
      }
    }

    case LEFTMENU_UPDATE_ITEM: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: {
            ...state.items[action.payload.id],
            ...action.payload,
          }
        }
      }
    }

    case LEFTMENU_UPDATE_ITEMS: {
      const stateItems = Object.values(state.items);
      const changedItems = keyBy(action.payload, 'id');
      const changedStateItems = stateItems.map(item => {
        if(changedItems[item.id]) {
          return {
            ...item,
            ...changedItems[item.id]
          };
        }
        return item;
      });
      return {
        ...state,
        items: keyBy(changedStateItems, 'id')
      }
    }

    case LEFTMENU_DELETE_ITEM: {
      const { path, id } = action.payload;
      const cutEl = (els: IMenu.Structure): IMenu.Structure => {
        if (!path.length) return els.filter(el => el.id !== id);
        const ids = path.splice(0, 1);
        return els.map(el => {
          if (ids.includes(el.id)) return { ...el, childrens: cutEl(el.childrens || []) };
          return el;
        });
      }
      return {
        ...state,
        structure: cutEl(state.structure)
      }
    }

    case LEFTMENU_SERVICE_SET_EXPANDED: {
      if (state.service.edit) {
        const toggleItem = action.payload[action.payload.length - 1];
        if (!toggleItem) return state;
        return {
          ...state,
          service: {
            ...state.service,
            expanded: state.service.expanded.includes(toggleItem) ? state.service.expanded.filter(e => e !== toggleItem) : union([...state.service.expanded, ...action.payload])
          }
        }
      }
      return {
        ...state,
        service: {
          ...state.service,
          expanded: action.payload
        }
      }
    }

    case LEFTMENU_SERVICE_SET_EXPANDED_ALL: {
      const getExpanded = (acc: string[], c: IMenu.StructureItem): string[] => [...acc, ...(c.childrens?.reduce(getExpanded, []) || [])]
      return {
        ...state,
        service: {
          ...state.service,
          expanded: action.payload ? state.structure.reduce(getExpanded, []) : []
        }
      }
    }

    case LEFTMENU_MOVE_ITEM: {
      if (action.payload.id === action.payload.moveTo) return state;
      let structure = clone(state.structure);
      const expanded = state.service.expanded.includes(action.payload.moveTo);
      const pathFrom: string[] = [];
      const pathTo: string[] = [];
      const findPath = (el: IMenu.StructureItem, parents: string[]) => {
        if (el.id === action.payload.id && parents.length) pathFrom.push(...parents);
        if (el.id === action.payload.moveTo && parents.length) pathTo.push(...parents);
        if (el.childrens) el.childrens.forEach(i => findPath(i, [...parents, el.id]));
      }
      structure.forEach(el => findPath(el, []));
      const findEl = (a: IMenu.StructureItem, c: IMenu.StructureItem): IMenu.StructureItem => {
        if (a.id !== GUID_EMPTY) return a;
        if (c.id === action.payload.id) {
          return { ...a, ...c };
        } else
          if (c.childrens) {
            return { ...a, ...c.childrens.reduce(findEl, baseStructureItem) };
          }
        return a;
      }
      const item = structure.reduce(findEl, baseStructureItem);
      const prepareStructure = (type: 'from' | 'to', arr: string[]) => (str: IMenu.Structure): IMenu.Structure => {
        if (!arr.length) {
          switch (type) {
            case 'from':
              return str.filter(el => el.id !== action.payload.id);
            case 'to':
              if (action.payload.type === 'before') str.splice(str.findIndex(el => el.id === action.payload.moveTo), 0, item);
              if (action.payload.type === 'after') str.splice(str.findIndex(el => el.id === action.payload.moveTo) + 1, 0, item);
              return [...str]
          }
        }
        const ids = arr.splice(0, 1);
        return str.map(el => {
          if (ids && ids.includes(el.id) && el.childrens) return { ...el, childrens: prepareStructure(type, arr)(el.childrens) }
          return el;
        });
      }
      // move item into opened dropdown
      if (action.payload.type === 'after' && expanded) {
        pathTo.splice(pathTo.length, 0, action.payload.moveTo);
      }
      structure = prepareStructure('from', pathFrom)(structure);
      structure = prepareStructure('to', pathTo)(structure);
      return {
        ...state,
        structure
      }
    }

    case LEFTMENU_SERVICE_SET_OPENED: {
      return {
        ...state,
        service: {
          ...state.service,
          opened: typeof action.payload === 'boolean' ? action.payload : !state.service.opened
        }
      }
    }

    case LEFTMENU_SERVICE_SET_HIDDEN: {
      return {
        ...state,
        service: {
          ...state.service,
          hidden: typeof action.payload === 'boolean' ? action.payload : !state.service.hidden
        }
      }
    }

    case LEFTMENU_SERVICE_SET_EDIT: {
      const value = typeof action.payload === 'boolean' ? action.payload : !state.service.edit;
      return {
        ...state,
        service: {
          ...state.service,
          edit: value,
          hidden: typeof action.payload === 'boolean' && action.payload && state.service.hidden ? false : state.service.hidden,
          opened: typeof action.payload === 'boolean' && action.payload && !state.service.opened ? true : false
        }
      }
    }

    default:
      return state
  }
}