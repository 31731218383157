import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IVirtualUserItemData, IVirtualUserData } from 'utils/src/requests/models/api.virtualuser';

export interface IVirtualUserProps extends IClassNameProps, IVirtualUserItemData, IVirtualUserOwnProps {}

export interface IVirtualUserOwnProps {
  tag?: React.ComponentType | 'div';
  context: any;
  data: IVirtualUserData;
}

export const cnVirtualUser = cn('VirtualUser');