import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace LMSTO {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace TOCoursesListItem {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            cid: string
        }

        export interface IStateProps {
            course: any
        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = LMSTO.TOCoursesListItem.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    course: state.LMSTO.courses.values[props.cid],
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    
}, dispatch);

////////////

export const cnClassName = cn('TOCoursesListItem');