import * as React from 'react';
import { CheckboxInput } from 'uielements/src';
import { Radio } from 'uielements/src/Radio/Radio';
import { Textarea } from 'uielements/src/Textarea/Textarea';
import { Input } from 'uielements/src/Input/Input';
import InputMask from 'react-input-mask';

import { IOptionalFieldsProps } from './Invite-OptionalFields.index';
import { cnInvite, phoneMask } from '../Invite.index';

import './Invite-OptionalFields.scss';

export class OptionalFieldsPresenter extends React.Component<IOptionalFieldsProps> {
  public el: HTMLElement | null;

  constructor(props: IOptionalFieldsProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {};
  }

  public render() {
    const { fields, labels, fieldTypes, icons, onChange } = this.props;
    return (
      <section className={cnInvite('OptionalFields')}>
        {Object.keys(fields).map(field => {
          switch (fieldTypes[field]) {
            case 'checkbox':
              return (
                <CheckboxInput
                  key={field}
                  name={field}
                  onChange={this.handleChange}
                  checked={!!fields[field].value}
                  text={labels[field]}
                />
              );
            case 'radio':
              return (
                <Radio
                  key={field}
                  name="IsMan"
                  value={field}
                  onChange={this.handleRadioChange}
                  checked={!!fields[field].value}
                  text={labels[field]}
                />
              );
            case 'password':
              return (
                <Input
                  key={field}
                  icon={icons[field]}
                  type="password"
                  onChange={this.handlePasswordChange}
                  invalid={!fields[field].valid}
                  value={fields[field].value + ''}
                  name={field}
                  placeholder={labels[field]}
                />
              );
            case 'phone':
              return (
                <InputMask mask={phoneMask} key={field} value={fields[field].value + ''} onChange={onChange}>
                  {(inputProps: any) => (
                    <Input
                      {...inputProps}
                      icon={icons[field]}
                      name="Phone"
                      placeholder={labels.Phone}
                      invalid={!fields.Phone.valid}
                    />
                  )}
                </InputMask>
              );
            case 'textarea':
              return (
                <Textarea
                  key={field}
                  icon={icons[field]}
                  placeholder={labels[field]}
                  onChange={onChange}
                  invalid={!fields[field].valid}
                  value={fields[field].value + ''}
                  name={field}
                />
              );
            case 'text':
              return (
                <Input
                  key={field}
                  icon={icons[field]}
                  placeholder={labels[field]}
                  onChange={onChange}
                  invalid={!fields[field].valid}
                  value={fields[field].value + ''}
                  name={field}
                />
              );
            default:
              return null;
          }
        })}
      </section>
    );
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, type} = e.currentTarget
    let value: string | boolean = e.currentTarget.value
    if (type === 'checkbox') value = e.currentTarget.checked;
    const valid = this.props.validate(name, String(value));
    this.props.setField(name, { value, valid });
  };

  private handleRadioChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if(e) {
      const {value, checked} = e.currentTarget;
      const oppositeValue = value === 'IsMale' ? 'IsFemale' : 'IsMale';
      this.props.setField(value, {value: checked, valid: true})
      this.props.setField(oppositeValue, {value: !checked, valid: true})
    }
  }

  private handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const { fields, validate, setField } = this.props;
    let valid = validate(name, value);
    const oppositeValue = name === 'Password' ? fields.PasswordConfirmation.value : fields.Password.value;
    if (oppositeValue !== '' && oppositeValue !== value) valid = false;
    setField(name, { value, valid });
  };

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}
}

export const OptionalFields = OptionalFieldsPresenter;
