import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
  GOT_INFO: '',
  GET_GROUP_NAME: '',
  LOAD_GROUP: '',//получение группы с сервера
  ADD_GROUP: '',// добавление группы с стор
  UPDATE_GROUP: '',// обновляет данные группы в сторе
  CHAGE_MY_IN_GROUP: '',// переводит состояние текущего юзера в указаной группе
  SET_NOTIFICATE_TEMPLATE: '',// устанавливает шаблон уведомлений для группы текущему пользователю
  ADD_SETTINGS: '',// добавляем в стор настройки группы
  LOAD_SETTINGS: '',// получает с сервера настройки группы
  UPDATE_SETTINGS: '',// обновляет данные настроек группы в сторе
  APPLY_NOTIFICATIONS_TEMPLATE: '',// применяет шаблон уведомлений ко всем пользователям в группе
  TEST_AUTO_INCLUDE: '',// проверка выражения автодобавления в группу
  SET_AUTO_INCLUDE: '',// выполнить автовключение пользователей в группу по текущему условию
  SEND_INTERCOMPANY_REQUEST: '',// подать заявку на перевод указаной группы в формат межсетевой
  CANCLE_INTERCOMPANY_REQUEST: '',// отменить заявку на перевод группы в формат межсетевой
  CANCLE_EDIT: '', // отменить редактирование
  APPLY_EDIT: '', // применить редактирование
  LOAD_GROUP_REQUESTS: '', // получить с сервера списоз заявок в группу
  APPROVE_REQUESTS: '', // подтвердить или отклонить заявки на всупление в группу
  REMOVE_GROUP: '', // удаляет группу из стора
  REMOVE_SETTINGS: '', // удаляет настройки из стора
  UPLOAD_IMPORT_USERS_FILE: '', // загрузить файл импорта пользователей в группу,
  SET_PROCESSING: '', // устанавливает отформацию о выполнение запроса
  JOIN_USER_TO_GROUP: 'JOIN_USER_TO_GROUP', // отправить запрос на присоединение пользователя к группе
  SELECT_TAGS_AUTO: '', // подобрать теги автоматически
  CHECK_GROUP_CONTEXT: '' // recalc gloabal page context for group
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.GROUPS) }), {} as typeof actions);