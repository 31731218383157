import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { saveSkipTest } from "../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
import {
    getUnitLogByUnitId,
} from '../../redux/sagas/LMS/selectors'
export interface ITestInfoProps extends IClassNameProps, IDispatchProps, RouteComponentProps {
    tag?: 'div';
    isStart?: boolean;
    passingScoreSuccess?: boolean
    attemptsSuccess?: boolean
}

export const cnTestInfo = cn('TestInfo');