/**
 * @packageDocumentation
 * @module List_type_users_leaderboard
 */
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from '../../../../redux/connector';

import { mapStateToProps, IListStateProps, IListProps, cnList, mapActionsToProps } from './../../List.index';
import { avalibleFilters } from "./List_type_users.index";

import './List_type_users_leaderboard.scss';
import { ListLite, Select } from 'uielements/src';
import i18n from 'localizations/i18n';
import { Item } from '../../Item/List-Item';
import { Header } from '../../Header/List-Header';
import { ListUsersPresenter } from './List_type_users';
import { Item as RatingItem } from 'uielements/src/RatingItem/Rating-Item';
import { RatingWithPosition } from 'uielements/src/RatingItem/variants/ItemWithPosition/Rating-Item';
import { TableItem } from '../../../Rating/TableItem/Rating-Item';
import { Level as RatingLevel } from '../../../Rating/Level/Level';
import { ILeaderboardResponse } from 'utils/src/requests/models/api.ratings';
import { IStateType as IState } from 'redux/store';
import { toggle } from 'utils/src/CommonRedux/base/actions';
function isElementInViewport(el: any) {
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const isIE = /*@cc_on!@*/false || !!(document as any).documentMode;
export class ListUsersLeaderboardPresenter extends ListUsersPresenter {
  public static defaultProps: any = {
    requestOptions: {
      rid: '',
      skipCount: 0,
      count: 20,
    },
  };

  private observer: any = null;

  public classMods = {
    leaderboard: true,
  };

  constructor(props: any) {
    super(props);
    (this.state as any).order = 'DESC';
    this.state = {
      ...this.state,
      myIsVisible: false,
      myPos: 2
    }
  }

  public componentDidMount() {
    super.componentDidMount()
    this.observer = new IntersectionObserver(this.callback, {
      rootMargin: '200px',
      threshold: 0.01,
      // ...options
    });
  }

  private callback = (entries: any[]) => {
    entries.forEach(entry => {
      if (entry.rootBounds)
        this.setState({
          isVisible: entry.intersectionRatio !== 0, myPos: entry.intersectionRatio === 0 ? ((entry.rootBounds.height / 2) > entry.boundingClientRect.top ? 0 : 2) : 1
        })
    });
  }

  public componentWillUnmount() {
    super.componentWillUnmount()
  }

  // TODO move to redux

  public getData = () => {
    const { requestOptions, context, edit } = this.props;
    const { clearData, isLoading } = this.props;
    const { clearDataFromList, getRatingUsers, toggle } = this.props;
    const id = this.listId;
    if (edit || isLoading || !this.mounted) return;
    const opts = Object.assign({}, requestOptions);
    opts.reverse = this.state.order === 'ASC';

    Object.keys(context).forEach((cKey: string) => {
      if (avalibleFilters[cKey]) opts[avalibleFilters[cKey]] = context[cKey];
    });


    if (this.searchString) opts.search = this.searchString;
    if (clearData) {
      clearDataFromList({ id, data: null });
    }
    toggle({
      id,
      data: {
        isLoading: true,
        isFinished: false
      }
    });
    getRatingUsers.call(this, id, opts);

  };

  private setMePostition = (uid: string) => {
    if (this.props.additionalParams.currentUser && (this.props.additionalParams.currentUser.id === uid)) return (r: any) => {
      if (r && this.observer) this.observer.observe(r);
    }
  }

  public renderChildren = () => {
    const { data, isFinished, isLoading, additionalParams } = this.props;
    // const { isFinished, isLoading } = this.state;
    // const data: any[] = this.state[this.dataVariable];
    let options = [{ title: i18n.t('pryaniky.list.ratings.order.sortDesc'), value: 'DESC' }, { title: i18n.t('pryaniky.list.ratings.order.sortAsc'), value: 'ASC' }];
    if(additionalParams.rating?.reverseOrder) options = [{ title: i18n.t('pryaniky.list.ratings.order.sortAsc'), value: 'DESC' }, { title: i18n.t('pryaniky.list.ratings.order.sortDesc'), value: 'ASC' }];
    let lastLevel: string = '__no_level__'

    const ItemRender = additionalParams.rating?.ratingItemRender === 'withDivision' ? RatingWithPosition : (isIE ? TableItem : RatingItem)
    return (
      <React.Fragment>
        <Header
          type={'common'}
          className="rounded-top"
          search={{
            placeholder: i18n.t('pryaniky.list.leaderboard.search'),
            onChange: this.search,
          }}
        />
        <div className={cnList('SortSelection')}>
          <h4 className="my-0">{i18n.t('pryaniky.list.ratings.sortBy')}</h4>
          <Select options={options} onChange={this.handleSortOrderChange} selected={options.find(el => el.value === this.state.order)} noDefault />
        </div>
        <ListLite
          className={'rounded-bottom' + (isIE ? ' LeaderboardTable' : '')}
          tag={isIE ? 'table' : undefined}
          isFinished={isFinished}
          isLoading={isLoading}
          loadMore={this.getData}
          skeleton="leaderboard">
          {
            additionalParams.rating && additionalParams.rating.ratingType.toLowerCase() === "agregate" &&
            <ItemRender key={additionalParams.rating.id} tag={Item} data={{
              idx: -1,
              id: additionalParams.rating.id,
              alias: '',
              value: additionalParams.rating.value,
              color: additionalParams.rating.color,
              curValue: additionalParams.rating.curValue,
              position: '',
              division: '',
              displayName: i18n.t('pryaniky.rating.collectivevalue'),
              imgId: additionalParams.rating.imgId,//'',
              imgUrl: additionalParams.rating.imgUrl,//'',
              percantage: additionalParams.rating.percantage,
              level: '',
            }} rating={additionalParams.rating} color={additionalParams.rating.color} maxValue={additionalParams.rating.maxValue} />
          }
          {!isIE && ((additionalParams.rating && additionalParams.currentUser && this.state.myPos === 0) && <ItemRender
            className={'MyItem myInTop'}
            rating={additionalParams.rating}
            tag={Item} data={additionalParams.currentUser}
            color={additionalParams.currentUser.level ? additionalParams.currentUser.level.color : additionalParams.rating.color}
            maxValue={additionalParams.rating.maxValue} />)}

          {data.map((elId, i) => {
            const el = this.props.getListItem(this.id, elId);
            el.idx = i;
            let showLevel = false
            if (el.level) {
              if (lastLevel !== el.level.name) {
                lastLevel = el.level.name;
                showLevel = true;
              }
              else {
                showLevel = false;
              }
            }
            return (
              <>
                {showLevel && <RatingLevel className={cnList('LeaderboardLevel')} key={lastLevel} data={el} />}

                <ItemRender key={el.id} setMePostition={this.setMePostition(el.id)} rating={additionalParams.rating} tag={Item} data={el} color={el.level ? el.level.color : additionalParams.rating.color} maxValue={additionalParams.rating.maxValue} />
              </>
            );
          })}

          {!isIE && ((additionalParams.rating && additionalParams.currentUser && this.state.myPos === 2) && <ItemRender
            className={'MyItem'}
            rating={additionalParams.rating}
            tag={Item} data={additionalParams.currentUser}
            color={additionalParams.currentUser.level ? additionalParams.currentUser.level.color : additionalParams.rating.color}
            maxValue={additionalParams.rating.maxValue} />)}
        </ListLite>
      </React.Fragment>
    );
  };

  private handleSortOrderChange = (option: any) => {
    const { toggle } = this.props;
    toggle({
      id: this.id,
      data: {
        hideData: false,
        clearData: true,
        isFinished: false
      }
    });
    this.setState({ order: option.value }, () => {
      setTimeout(() => this.getData(), 10);
    });
  }
}

export const List = connect<IListStateProps, IDispatchProps, IListProps, IState>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
)(ListUsersLeaderboardPresenter);
