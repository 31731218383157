import { IThankProps } from './Thank.index'
import React, { memo } from 'react'
import './Thank.scss';
import i18n from '../../../../localizations/i18n';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon'

export const Thank: React.FC<IThankProps> = memo(({ onClick }) => {
    return (
        <div className={'Action Actions-Thank'}>
            <Button theme='unstyled' onClick={onClick}><Icon icon='give' />{i18n.t('give_currency')}</Button>
        </div>);
})
