import React, { ComponentProps } from 'react';
import { withBemMod } from '@bem-react/core';

import { cnWidget, IWidgetProps } from '../Widget.index';
import { IWidgetTypeBlocksProps } from './Widget_type_blocks.index';
import './Widget_type_blocks.scss';
import WidgetPresenter from '../Widget';
import { widgets } from "../../../i.widgets";
import * as utils from 'utils';
import { Blocks } from '../../Blocks/Blocks';

const type = 'blocks';

const datas = new Array(6).fill(0).map((_, idx) => ({
  id: (idx + 1).toString(),
  imgUrl: 'http://storge.pic2.me/c/1360x800/639/55510c2d0710e.jpg',
  text: `text ${idx + 1}`,
  url: '//vk.com'
}))

export default class WidgetTypeBlocksPresenter extends WidgetPresenter<IWidgetTypeBlocksProps> {
  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const BaseWidget = widgets.components.common;
    const data: ComponentProps<typeof Blocks>['data'] = this.props.widget.settings?.items || datas;
    return ( 
      <BaseWidget {...this.props} className={cnWidget({ type, subtype })}>
        <Blocks data={data} />
      </BaseWidget>
    );
  }
}