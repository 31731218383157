/**
 * @packageDocumentation
 * @module Widget_Settings_info
 */
export default {
  type: '',
  selectedUsers: [],
  selectedGroups: [],
  selectedBadges: [],
  selectedRatings: [],
}