import { cn } from '@bem-react/classname';

export interface ISelectProps {
    onChange: (value: any) => void;
    value: any;

    variants: any[];

    className?: string
    
    placeholder?: string;


    children?: React.ReactNode;    
    
    icon?: React.ReactNode;
}

export const cnSelect = cn('PrnSelectMenu')