import actions from 'redux/actionsTypes/Files';

const defaultFiles: any = {
    files: {},
    filesIds: [],
    currentFolder: undefined,
    moveDialog: {
        folders: [],
        currentFolder: "00000000-0000-0000-0000-000000000000"
    },
    filters: {
        search: ''
    },
    groups: {}/* {files:[], selected: []}*/
};

export function filesReducer(state = defaultFiles, action: any) {
    switch (action.type) {
        case actions.TRUNCATE_FOLDERS:// payload: 
            return {
                ...state,
                folders: []
            };
        case actions.READ_FOLDERS:// payload: 
            return {
                ...state,
                moveDialog: {
                    folders: [...state.folders, ...action.payload.folders],
                    currentFolder: action.payload.currentFolder
                }
            };
        case actions.CREATE_FILE:// payload: {file, gid(group id)}
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.payload.file.id]: action.payload.file
                },
                groups: {
                    ...state.groups,
                    [action.payload.gid]: {
                        ...state.groups[action.payload.gid],
                        files: [action.payload.file.id, ...state.groups[action.payload.gid].files]
                    }
                }
            };
        case actions.SET_FOLDER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    search: ''
                },
                groups: {
                    ...state.groups,
                    [action.payload.gid]: {
                        selected: [],
                        ...state.groups[action.payload.gid],
                        currentFolder: action.payload.folderId,
                        files: [],
                        isFinished: false,
                        isLoading: false
                    }
                }
            }
        case actions.DELETE_FILE:// payload: {fid(file id), gid(group id)}
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.payload.fid]: undefined
                },
                groups: {
                    ...state.groups,
                    [action.payload.gid]: {
                        ...state.groups[action.payload.gid],
                        files: state.groups[action.payload.gid].files.filter((val: string) => val !== action.payload.fid),
                        selected: state.groups[action.payload.gid].selected.filter((val: string) => val !== action.payload.fid),
                    }
                }
            };
        case actions.CHANGE_FILE:// payload: {fid(file id), file}
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.payload.fid]: {
                        ...state.files[action.payload.fid],
                        ...action.payload.file
                    }
                },
            }
        case actions.SELECT_FILE:// payload: {fid(file id), gid(group id)}
            return (() => {
                return {
                    ...state,
                    groups: {
                        ...state.groups,
                        [action.payload.gid]: {
                            ...state.groups[action.payload.gid],
                            selected: !state.groups[action.payload.gid].selected.find((fid: string) => fid === action.payload.fid)
                                ? (!state.files[action.payload.fid].isBack ? [...state.groups[action.payload.gid].selected, action.payload.fid] : state.groups[action.payload.gid].selected)
                                : state.groups[action.payload.gid].selected.filter((fid: string) => fid !== action.payload.fid)
                        }
                    }
                }
            })()
        case actions.SET_MOVE_FILES:
            return (() => {
                const filesWithoutSelected = state.groups[action.payload.gid].files.filter((f: string) => !state.groups[action.payload.gid].selected.includes(f))
                let files = [...filesWithoutSelected, ...state.groups[action.payload.gid].selected]
                if (action.payload.folderId !== state.groups[action.payload.gid].currentFolder) {
                    files = filesWithoutSelected
                }
                return {
                    ...state,
                    groups: {
                        ...state.groups,
                        [action.payload.gid]: {
                            ...state.groups[action.payload.gid],
                            files,
                            selected: []
                        }
                    }
                }
            })()
        case actions.SELECT_ALL_FILES:// payload = gid
            return (() => {
                const filesWithoutForward = state.groups[action.payload].files.filter((fid: string) => !state.files[fid].isBack)
                return {
                    ...state,
                    groups: {
                        ...state.groups,
                        [action.payload]: {
                            ...state.groups[action.payload],
                            selected: filesWithoutForward.length === state.groups[action.payload].selected.length
                                ? [] : [...filesWithoutForward]
                        }
                    }
                }
            })()
        case actions.IS_LOADING_GROUP_FILES:
            return {
                ...state,
                groups: {
                    ...state.groups,
                    [action.payload.gid]: {
                        files: [],
                        selected: [],
                        isFinished: false,
                        ...state.groups[action.payload.gid],
                        isLoading: true
                    }
                }
            }
        case actions.READ_GROUP_FILES://payload: {files, gid}
            return {
                ...state,
                files: {
                    ...state.files,
                    ...action.payload.files
                },
                filters: {
                    ...state.filters,
                    ...action.payload.filters
                },
                groups: {
                    ...state.groups,
                    [action.payload.gid]: {
                        ...state.groups[action.payload.gid],
                        selected: [],
                        ...state.groups[action.payload.gid],
                        ...action.payload.group,
                        files: [
                            ...state.groups[action.payload.gid].files,
                            ...action.payload.group.files.filter((f: string) => !state.groups[action.payload.gid].files.includes(f))
                        ]
                    }
                }
            };
        case actions.TRUNCATE_GROUP_FILES:
            return {
                ...state,
                groups: {
                    ...state.groups,
                    [action.payload]: {
                        ...state.groups[action.payload],
                        files: [],
                        selected: [],
                    }
                }
            }

        default:
            return state;
    }
}
