import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    deleteReply
} from '../../redux/actions'

import {
} from '../../redux/reducer/types'
import {
} from '../../redux/saga/selectors'
import { NewsContextProps } from '../../contexts/news'
import { ReplyContextProps } from '../../contexts/reply'
import {

} from 'News/redux/actions'
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace ReplyService {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & ReplyContextProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {

        }

        export interface IStateProps {
        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.ReplyService.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    deleteReply
}, dispatch);

////////////

export const cnClassName = cn('ReplyService');