import { IClassNameProps } from '@bem-react/core';
import { cnReply } from '../Reply.index';

import { match } from 'react-router';

export interface IHeaderDataType {
  id: string;
  text: string;
  user: any;
  date: string;
  actions: string[];
  timeSplitter: string;
  newsID: string;
}

export interface IHeaderProps extends IClassNameProps {
  data: IHeaderDataType;
}

export const cnHeader = (...data: any) => {
  let name = 'Header';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnReply(name, ...data)
}