import * as React from 'react';
import { IEditorTypeEditProps } from './edit.index';
import { Editor } from '../../Editor'
import { baseValidate, BaseValidator } from '../../../Reply.validate'

export const EditorTypeEdit: React.FC<IEditorTypeEditProps> = ({ data, onChange }) => {
    let localData = { ...data };

    const onChangeText = (text: string) => {
        localData = { ...localData, text }
        const vb = new BaseValidator();
        vb.addData(localData)
        vb.setRules(baseValidate)
        onChange(localData, vb.validation().length === 0)
    }

    return <Editor data={localData} onChangeText={onChangeText} />

}