import { Reducer, AnyAction } from 'redux';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import { AdvernCalendarReducer } from './reducer';

const moduleName = 'adventCalendar';

const reducerMap = { [moduleName]: AdvernCalendarReducer };

type IAdventCalendarModule = {[key in keyof typeof reducerMap ]: ReturnType<typeof reducerMap[key]> };

export function getAdventCalendarModule(): ISagaModule<IAdventCalendarModule>  {
    return {
        id: moduleName,
        reducerMap: reducerMap as {[s in keyof IAdventCalendarModule]: Reducer<IAdventCalendarModule[s], AnyAction>},
        sagas: [
            
        ],
    };
}