import { keyBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWidgetById } from "Widgets_v2/selectors";
import { AdventCalendarDaysMock, enumerateDaysBetweenDates, generateDay } from "./AdventCalendar.mock";
import { changeAdventCalendarDay, setAdventCalendar } from "./redux/actions";
import { NAdventCalendarActions } from "./redux/actions.types";
import { getAdventCalendarExample, getAdventCalendarExampleDay } from "./redux/selectors";
import { NAdventCalendarReducer } from "./redux/types";
import { AdventCalendar } from 'utils/src/BaseTypes/adventCalendar.types';
import { CalendarSettings } from "./CalendarSettings/CalendarSettings";
import { changeWidgetSettings } from '../../redux/actions/Widgets';

export const useAdventCalendarDayByWidget = () => {

};

export const useAdventCalendarDay = (calendarId: keyof NAdventCalendarReducer.Reducer, date: NAdventCalendarReducer.Day['date']) => {

    const dispatch = useDispatch();
    
    const reducerDay = useSelector(getAdventCalendarExampleDay(date)(calendarId)) || undefined;

    const widget = useSelector(getWidgetById(calendarId.toString()));
    const calendarSavedDays = widget.settings?.calendarDays as NAdventCalendarReducer.Example | undefined;
    const widgetDay = calendarSavedDays ? calendarSavedDays[date] : undefined;

    const day = useMemo(() => {
        if(!reducerDay) return undefined;
        return {
            ...reducerDay,
            ...widgetDay
        }
    }, [reducerDay, widgetDay]);

    const changeDay = (item: ReturnType<typeof changeAdventCalendarDay>['payload']['item']) => {
        dispatch(changeAdventCalendarDay({
            id: calendarId,
            item
        }));
        dispatch(changeWidgetSettings({
            wId: calendarId.toString(),
            settings: {
                calendarDays: {
                    ...calendarSavedDays,
                    [item.date]: item
                }
            }
        }));
    };

    return {
        day,
        changeDay
    };
};

/**
 * 
 * @param id - widget id
 * @returns 
 */
export const useAdventCalendarByWidgets = (id: string) => {

    const dispatch = useDispatch();

    const [ loading, setLoading ] = useState(false);

    const widget = useSelector(getWidgetById(id));

    const calendar = widget.settings?.calendar as AdventCalendar | undefined;

    const calendarName = calendar?.name;
    
    // const calendarSavedDays = widget.settings?.calendarDays as NAdventCalendarReducer.Example | undefined;

    const calendarDays = useSelector(getAdventCalendarExample(id));

    useEffect(() => {
        if(!calendarDays && calendar) {
            const days = enumerateDaysBetweenDates(calendar.dateFrom, calendar.dateTo).map(el => generateDay(el.toISOString()));
            dispatch(setAdventCalendar({
                id,
                items: keyBy(days, 'date')
            }));
        }
    }, [ calendarDays, calendar ]);

    const change = () => {
        CalendarSettings({ calendar })
        .then(({ editedCalendar }) => {
            dispatch(changeWidgetSettings({
                wId: id,
                settings: {
                    calendar: editedCalendar
                }
            }));
        })
        .catch(() => {})
    }

    return {
        loading,
        calendar: calendarDays,
        calendarName,
        change
    };
};

export const useAdventCalendar = (id: keyof NAdventCalendarReducer.Reducer) => {

    const dispatch = useDispatch();

    const [ loading, setLoading ] = useState(false);

    const calendar = useSelector(getAdventCalendarExample(id));

    useEffect(() => {
        setLoading(true);
        if(id === 'test') {
            setTimeout(() => {
                dispatch(setAdventCalendar({
                    id,
                    items: keyBy(AdventCalendarDaysMock, 'date')
                }))
                setLoading(false);
            }, 3000);
        }
    }, []);

    return {
        loading,
        calendar
    };
};