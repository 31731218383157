import { IStateType } from 'redux/store';

// получение текущего статуса 
export const selectCurrentStatus = (newsId: string) => (state: IStateType) => state.newsReducer.values[newsId]?.additionalFields?.currentStatus
// получение статусов заявки
export const selectUserStatuses = (newsId: string) => (state: IStateType) => state.newsReducer.values[newsId].userStatuses
// получение статуса, по id
export const selectStatus = (newsId: string, statusId: string) => (state: IStateType) => selectUserStatuses(newsId)(state)?.find((status: any) => status.id === statusId)
// получение полей, выбранного для перевода статуса
export const selectStatusFields = (newsId: string) => (state: IStateType) => state.newsReducer.values[newsId].statusFields

export const selectStatusFieldsLoading = (newsId: string) => (state: IStateType) => state.newsReducer.values[newsId].statusFieldsLoading
// получение ответсвенных за этап
export const selectResponsibleForStatus = (newsId: string) => (state: IStateType) => state.newsReducer.values[newsId]?.additionalFields?.responsibleForStatus
// можно ли менять статус
export const selectAllowchangeStatus = (newsId: string) => (state: IStateType) => state.newsReducer.values[newsId]?.additionalFields?.showChangeStatusButton;