import * as utils from 'utils/src/utils'
import { IStateType as IState } from 'redux/store';
import actions from 'redux/actionsTypes/WikiList';
import { IWikiListState } from '../reducers/WikiList';
import { IListTypeWikiProps  } from "blocks/WikiListNew/List_type_wiki.index";
import { IListState } from 'blocks/List/List.index';
import { listDataVariable, addDataToList, toggle } from './Lists';
import { IWikiItem } from "utils/src/requests/models/api.wikilist";
import { IListWikiResponse } from "utils/src/requests/models/api.base";
import {
    sendLikeNews,
    setNewsList
} from 'News/redux/actions'
import {
    normalizeNews
} from 'News/redux/saga/utils'
import {
    getNewsById
} from 'News/redux/saga/selectors'
import { createDefaultNews } from 'News/types/baseNews'
import { createDefaultReply } from '../../blocks/NewsTypes/Reply/Reply.type';
import { EditorState, convertToRaw } from 'draft-js';

export const get_wiki_list = (payload: IWikiListState) => ({
    type: actions.GET_WIKI_LIST,
    payload
})
export const set_wiki_page = (payload: IWikiListState) => ({
    type: actions.SET_WIKI_PAGE,
    payload
})

export const update_wiki = (payload: any) => ({
    type: actions.UPDATE_WIKI,
    payload
})

// export function getWikiList(this: ListWikiPresenter, collectionAlias: string, opts: any) {
export function getWikiList(this:any, collectionAlias: string, opts: any) {
    return (dispatch: any, getState: () => IState) => {
        const listState = getState().lists[this.id];
        opts.skipCount = listState[listDataVariable(listState)].length;
        utils.API.wikilist.getWikiPages(collectionAlias, opts)
            .r
            .then((d) => {
                if (utils.checkResponseStatus(d)) {
                    const news: any[] = []
                    const noopNews = createDefaultNews()
                    noopNews.id = 'noop'
                    noopNews.newstype = -1
                    const wikiItems = d.data.map(item => {
                        if (!item.news) {
                            news.push(noopNews)
                        } else {
                            news.push(item.news)
                        }
                        return { ...item, news: item.news?.id || noopNews.id }
                    })
                    const newNews = news.map((elem: any) => {
                        return { ...elem, newsTypeMain: utils.newNewsType(elem.newstype) }
                    });
                    const { commentsKeys, keys, commentsValues, values } = normalizeNews(newNews)
                    // const norm = normalizeData(newNews)
                    // const forms: any = {};
                    // const res = norm.result.map((id: any) => {
                    //     const postDate = new Date(norm.entities.news[id].date);
                    //     const ts = postDate.getTime();
                    //     norm.entities.news[id].ts = ts;
                    //     forms[id] = { ...createDefaultReply(), text: EditorState.createEmpty() }
                    //     return id
                    // })
                    dispatch(setNewsList({
                        commentsKeys,
                        commentsValues,
                        keys,
                        values,
                        group: 'wikilist'
                    }))
                    // dispatch(addNews({
                    //     news: norm.entities.news,
                    //     // comments: norm.entities.comments,
                    //     commentForms: forms,
                    //     newsIds: []
                    // }))
                    dispatch(addDataToList({ id: this.listId, data: wikiItems }));
                    dispatch(toggle({
                        id: this.listId, data: {
                            isLoading: false, clearData: false, isFinished: d.isFinished || wikiItems.length === 0
                            //  d.isFinished 
                        }
                    }));
                }
            })
    }
}
export const likeWikiRequest = (newsId: string, likeCount: number, liked: boolean) => {

    return (dispatch: any, getState: any) => {
        dispatch(sendLikeNews({ id: newsId, likeCount, liked }))
    };
}
