import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { getBadgesList } from 'utils/src/requests/requests.badges';

export const cnBadgesList = cn('BadgesList');

export namespace NBadgesList {

    export interface Props extends IClassNameProps {

    }

    export interface State {
        list: Awaited<ReturnType<typeof getBadgesList>>['data'];
    }
}