import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    IDialogActionsOwnProps,
    IDialogActionsPresenterProps
} from './interfaces'
import './style.scss'
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export const Presenter: FC<IDialogActionsPresenterProps> = ({ children, onClose, onAccept, disableAccept, className, ...other }) => {
    return <DialogActions className={cnClassName({}, [className])}>
        <Button
            // autoFocus
            disableElevation
            variant={'outlined'}
            color={'default'}
            size={'small'}
            onClick={onClose}>
            Disagree
          </Button>
        <Button
            disableElevation
            variant={'contained'}
            color={'primary'}
            onClick={onAccept}
            size={'small'}
            disabled={disableAccept}
            // autoFocus
            >
            Agree
          </Button>
    </DialogActions>
}

export default memo(Presenter);
