import { Box } from 'muicomponents/src'
import { styled } from 'muicomponents/src/mui/system';
import { Paper } from 'muicomponents/src/Paper';
import { List, ListItemButton } from 'muicomponents/src/ListItem/ListItem';
import { LinearProgress } from "muicomponents/src/LinearProgress";
import { Avatar } from "muicomponents/src/Avatar/Avatar";

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  // height: theme.spacing(21),
  overflow: 'hidden',
})) as typeof Paper

export const BorderLinearProgress = styled(LinearProgress)({
  marginTop: '8px',
  height: 10,
  borderRadius: 5,
}) as typeof LinearProgress

export const BadgeAvatar = styled(Box)({
  position: 'absolute',
  top: '24px',
  // padding: '4px',
  boxSizing: 'border-box',
  zIndex: 1,
  width: '30px',
  height: '30px',
  border: "1px solid #A9A9A9",
  borderRadius: "50%",
  backgroundSize: "145%",
  backgroundPositionX: "center",
  backgroundPositionY: "center",

}) as typeof Avatar

export const ProgressBox = styled(Box)({
  position: 'relative',
  height: "30px",
  margin: "24px 0",

}) as typeof Box