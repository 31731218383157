import React, { useState } from 'react';
// import * as utils from 'utils/src/utils';


import { withBemMod } from '@bem-react/core';

import { cnInfoBlock } from '../AbstractInfoElement.index';
import { IUserTypeStateProps } from './InfoBlock-Element_type_state.index';
import './InfoBlock-Element_type_state.scss';

// IUserTypeStateProps
export const withElementTypeState = withBemMod<any>(
  cnInfoBlock(),
  { type: 'state' },
  (Presenter) => (
    (props: IUserTypeStateProps) => {
      const [data, setData] = useState(props.data);
      return (
        <Presenter {...props} data={data} setData={setData} />
      )
    }
  )
);