
import React, { FC } from 'react'
import { Skeleton } from 'uielements/src'
import './SessionList.scss'
import { cn } from '@bem-react/classname';
export const cnSessionList = cn('SkeletSessionList');

export const SessionList: FC<any> = ({ className }) => {
    return (
        <div className={cnSessionList({}, [className])}>
            <div className="Thread">
                <div className="Deadlines Thread-Deadlines">
                    <Skeleton shape="line" size={"sm"} style={{ width: '150px' }} />
                </div>
                <div className="Thread-Name" title="as">
                    <Skeleton shape="line" size={"sm"} style={{ width: '300px' }} />
                </div>
                <Skeleton shape="line" size={"sm"} style={{ width: '130px' }} />
            </div>
        </div>
    )
}