import {
  ITranslateOwnProps,
  ITranslateProps,
  ImapTranslateStatetoProps,
  Translate as TranslatePresenter,
  mapTranslateStatetoProps,
} from 'localization/src/Translate'
import { connect } from 'react-redux';

export type {
  ITranslateOwnProps,
  ITranslateProps,
  ImapTranslateStatetoProps,
}

export {
  TranslatePresenter,
  mapTranslateStatetoProps,
}

export const Translate = connect(
  mapTranslateStatetoProps
)(TranslatePresenter);