import React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget, cnWidgetMui } from '../Widget.index';
import './Widget_type_shop.scss';
import { IWidgetProps } from '../Widget.index';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { ShopWidget } from 'blocks/Shop/Widget/Shop-Widget';

const type = 'shop';

export default class WidgetTypeQuestsPresenter extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const Component = ShopWidget
    const subtype = utils.widgetSubtype(this.props.widget.type);
    return <Component {...this.props} className={cnWidgetMui({ type, subtype })}
      settings={this.props.widget.settings}
      contexts={this.relations.concat(this.props.widget.id)}
      tag={widgets.components.common} />
  }
}