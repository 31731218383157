import { ReactNode } from 'react';
import { BaseNews } from '../NewsTypes/BaseType'
// import { createNewsRequest } from "../../redux/actions/News";
import {
    sendNews
} from 'News/redux/actions'
import { bindActionCreators } from 'redux'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { getCompanyPostTypeById } from 'redux/sagas/AppSettings/selectors'
import { Rule } from '../NewsTypes/Base.validate'
import {
    updateNews,
    setUpdateError
} from 'News/redux/actions'
import {
    getUpdateErrorById,
    getNewsById,
} from 'News/redux/saga/selectors'
import { IBaseNews } from 'News/types/baseNews'
export interface INewsEditPropsType extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
    type: any;
    newsId: string
    defaultData?: IBaseNews
    cancleEdit: () => void;
    compliteEdit: (post: any) => void;
    create?: boolean;
    successMsg?: string;
    failMsg?: string;
    disableAttach?: boolean;
}

export interface INewsEditStateType {
    data: IBaseNews;
    isValid: boolean;
    validFile: boolean;

    postErrorText: string;
    postErrorCode: number;
    validationErrors: Rule[];
}

export const mapStateToProps = (state: any, props: any) => ({
    userData: getCurrentUser(state),
    maxFileSize: state.store.appSettings.maxFileSizeLimit,
    denyMIME: state.store.appSettings.denyMIME,
    postTypeParams: getCompanyPostTypeById(props.type)(state),
    updateError: getUpdateErrorById(props.newsId)(state),
    data: getNewsById(props.newsId)(state),
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    sendNews,
    updateNews,
    setUpdateError
}, dispatch);


