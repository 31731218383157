import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace LMSTO {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace StudentResultsTask {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            unit: any
            sid: string
            cid: string
            userId: string
            unitContent: any 
        }

        export interface IStateProps {

        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = LMSTO.StudentResultsTask.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({

})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({

}, dispatch);

////////////

export const cnClassName = cn('TOStudentResultsTask');