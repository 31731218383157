import React from 'react';
import { Translate } from "localizations/Translate";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { checkResponseStatus } from "utils/src";
import { NewsAttachV3 } from "utils/src/BaseTypes/attachements.types";
import { SFileModelV4WithHistory } from "utils/src/BaseTypes/files.types";
import { getFileV4Versions } from "utils/src/requests/requests.files";

export const useAttachementHistory = (fileId: NewsAttachV3['id']) => {

    const [ loading, setLoading ] = useState(false);

    const [ data, setData ] = useState<SFileModelV4WithHistory | null>(null);

    useEffect(() => {
        setLoading(true);
        getFileV4Versions(fileId).then(d => {
            if(checkResponseStatus(d)) {
                setData(d.data);
            } else {
                toast.error(<Translate i18nKey={'file history load fail'} />);
            }
            setLoading(false);
        });
    }, []);

    return {
        loading,
        data
    };
};