import React, { ComponentProps, useMemo } from 'react'
import { SearchTitle } from 'blocks/SearchMui/SearchMui.styled'
import { AllResultButton, HeaderBox } from 'muicomponents/src/SuggesterQuick/Groups/Groups.styled'
import { ArrowForward } from 'muicomponents/src/Icons';
import { Box } from 'muicomponents/src';
import { Link } from 'muicomponents/src/Link/Link';
import { Translate } from 'localization';
import { ISuggesterQuickProps } from "muicomponents/src/SuggesterQuick/SuggesterQuick.index";
import { useParams, useHistory, withRouter } from 'react-router-dom';
import { cnSearchMui } from '../SearchMui.index'
import urls from 'routes/urls';

export const SearchList: React.FC<{
  title?: React.ReactNode,
  showAllButton?: boolean,
  value?: string;
  sx?: ComponentProps<typeof Box>['sx'],
  searchQuery?: string;
  hasMore?: { count: number, hasManyResult: boolean }
}> = ({
  title,
  children,
  showAllButton,
  value,
  sx,
  searchQuery,
  hasMore
}) => {

    const history = useHistory();

    const toPageAllResults = () => {
      if(!value) return ;
      const urlString = value === 'users'
        ? urls.users
        : `/search/${value}`;
      history.push(`${urlString}${searchQuery ? `?search=${searchQuery}` : ""}`)
    };
    
    const toAllResultsButtonText = useMemo(() => {
      return <>
        {hasMore?.hasManyResult ?
          <Translate i18nKey={'pryaniky.header.suggester.groups.more.then.ten'} /> : (hasMore?.count && hasMore?.count > 0) ?
            <Translate i18nKey={'pryaniky.header.suggester.groups.more'} values={{ count: hasMore?.count }} /> : <Translate i18nKey={'pryaniky.header.suggester.groups.showAll'} />}
      </>

    }, [hasMore, hasMore?.hasManyResult, hasMore?.count])

    return (
      <Box
        className={cnSearchMui('SearchList', [value])}
        sx={{
          ...sx,
          marginBottom: "32px",
          ".MuiTypography-root.MuiTypography-inherit.MuiLink-root": {
            fontSize: "16px"
          },
          "&:last-child": {
            marginBottom: "0"
          },
          ".MuiPaper-root.MuiPaper-elevation": {
            boxShadow: "none",
            paddingTop: 0,

            ".ItemsListDialog-Head": {
              display: "none"
            },
            ".ItemsListDialog-Body ": {
              paddingLeft: 0,
              paddingRight: 0,
            }
          },
        }
        }>
        <HeaderBox sx={{ padding: 0 }}>
          {title && <Link sx={{ cursor: "pointer" }} onClick={toPageAllResults}>
            <SearchTitle>
              {title}
            </SearchTitle>
          </Link>}
          {showAllButton && <AllResultButton
            endIcon={<ArrowForward />}
            onClick={toPageAllResults}
          >

            {toAllResultsButtonText}

            {/* <Translate i18nKey={'pryaniky.header.suggester.groups.showAll'} /> */}
          </AllResultButton>}
        </HeaderBox>
        {children}
      </Box>
    )
  }