import React, { FC, useEffect, useState, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    Skills
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { createDefaultSkill } from 'redux/sagas/Skills/types'
import Header from './Header'
import List from './List'
import Create from './Create'
import { Participants } from 'blocks/Dialogs/Skills/Participants/Participants'

type IOwnProps = Skills.IOwnProps
type IPresenterProps = Skills.IPresenterProps
type IStateProps = Skills.IStateProps
type IDispatchProps = Skills.IDispatchProps
type IState = Skills.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    userid: ruserid,
    loadUserSkills,
    skills,
    createUserSkill,
    confirmSkill,
    currentUser,
    deleteSkill,
    isFinished,
    isLoading
}) => {
    const userid = ruserid || currentUser.baseData.id
    const [create, setCreate] = useState(false)
    const [title, setTitle] = useState('')
    const [show, setShow] = useState<string | null>(null)
    const withError = (Boolean(title)) && !/^[\s0-9A-Za-zА-Яа-яЁё_]+$/gi.test(title)
    useEffect(() => {
        loadUserSkills(userid)
    }, [userid])

    const skillsArray = Object.values(skills || {}).filter(v => v)

    const onColmleteCreate = () => {
        if (withError) return;
        if (!title) {
            setCreate(false)
            return;
        }
        const skill = createDefaultSkill()
        skill.tag.displayName = title
        setTitle('')
        setCreate(false)
        createUserSkill(userid, skill)
    }

    let showControls = currentUser.baseData.id === userid
    showControls = currentUser.baseData.isAdmin ? true : showControls;

    let showCreate = !isLoading

    return <div className={cnClassName({}, [className])}>
        <Participants data={false} isShown={!!show} tId={show || ''} userid={userid} onClose={() => setShow(null)} />
        <Header
            withError={withError}
            showControls={showCreate}
            onCancle={() => setCreate(false)}
            onComplete={onColmleteCreate}
            setCreate={setCreate}
            create={create}
        />
        {(showCreate && create) && <Create withError={withError} currentUser={currentUser} title={title} setTitle={setTitle} onComplete={onColmleteCreate} />}
        <List
            create={create}
            isLoading={isLoading}
            isFinished={isFinished}
            setShow={setShow}
            showControls={showControls}
            deleteSkill={deleteSkill}
            skills={skillsArray}
            confirmSkill={confirmSkill}
            userid={userid}
        />
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));
