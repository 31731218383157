import React, { FC, memo } from 'react'
import {
    cnClassName,
    IMiniPlaceholderProps
} from './MiniPlaceholder.index'
import './MiniPlaceholder.scss'
import { Avatar } from 'uielements/src/Avatar/Avatar'
import { UserMention } from 'uielements/src/UserMention/UserMention'
import i18n from 'localizations/i18n'


export const MiniPlaceholder: FC<IMiniPlaceholderProps> = memo(({
    className,
    children
}) => {

    return <div className={cnClassName({}, [className])}>
        {children}
    </div >
})

