import React, { FC, useEffect } from 'react'
import { IBadgeProps, mapDispatchToProps, mapStateToProps, cnBadge } from './Badge.index'
import { Button, Avatar } from 'uielements/src'
import { connect } from 'react-redux';
import './Badge.scss'


export const BadgePresenter: FC<IBadgeProps & any> = ({ className }) => {

    return <div className={cnBadge({}, [className])}>
        <h4 className={cnBadge('Title')}>gift</h4>
        <div className={cnBadge('Row')}>
            <div className={cnBadge('ColLeft')}>
                <Avatar size={128} className={cnBadge('Img')} name={'name'} />
            </div>
            <div className={cnBadge('ColRight')}>
                <h3 className={cnBadge('Name')}>name</h3>
                <p className={cnBadge('Description')}>description</p>
                <Button theme={'unstyled'} type={'link'} className={cnBadge('Link')} href={'#'}>view</Button>
            </div>
        </div>
    </div>
}


export const Badge = connect(
    mapStateToProps,
    mapDispatchToProps
)(BadgePresenter);