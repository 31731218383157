import React, { ComponentProps, FC, useMemo } from 'react';
import { SliderLinkProps, cnSliderLink } from './SliderLink.index';
import { IStateType } from 'redux/store';
import { useSelector } from 'react-redux';
import { StyledImageSlider } from './SliderLink.styled';
import { CustomSettings } from 'utils/src';

export const SliderLink: FC<SliderLinkProps> = ({
    id,
    tag: Tag = 'div',
    ...props
}) => {

    const items = useSelector((state: IStateType) => {
        return state.sliderLink[id]?.data
    });

    const images = useMemo<ComponentProps<typeof StyledImageSlider>['images']>(() => {
        return items?.map(item => {
            return {
                src: item.imgUrl,
                link: item.href,
                openInNewTab: item.openInNewTab,
                inactive: item.inactive
            };
        }).filter(v => !v.inactive);
    }, [items]);

    const customTimeout = useMemo(() => CustomSettings.sliderTimeout(), []);

    if(!images) return (
        <Tag {...props} className={cnSliderLink()} />
    );

    return (
        <Tag {...props} className={cnSliderLink()}>
            <StyledImageSlider
                images={images}
                timeout={customTimeout}
            />
        </Tag >
    );
};