import { textTransform } from '@mui/system';
import { styled, Box, Typography, Paper } from 'muicomponents/src'
import { ItemsListHeader } from 'muicomponents/src/ItemsListDialog/ItemsListHeader/ItemsListHeader'
import { UsersSuggester as UsersSuggesterMui, BaseSuggester as BaseSuggesterMui } from 'muicomponents/src/Suggester';

const defaultSuggesterStyle = {
    '& .MuiInputBase-root': {
        paddingRight: '39px !important'
    }
}
export const UsersSuggester = styled(UsersSuggesterMui)({
    ...defaultSuggesterStyle
}) as typeof UsersSuggesterMui;

export const SearchWithFiltersBox = styled(Box)({
    '.ItemsListHeader-SearchBox': {
        columnGap: "inherit",
        marginBottom: 0,
        gap: "24px",
        ".MuiFormControl-root.MuiTextField-root": {
            marginBottom: 0,
        },
    },
    ".ItemsListHeader-HeaderBottomContent": {
        paddingBottom: 0,
        ".MuiBadge-root.BaseBadge-root":{
            width: "100%",
        },
        ".MuiInputBase-root.MuiOutlinedInput-root": {
            height: "40px",
            padding: "3px 8px",
            fontSize: "16px",
            textTransform: "inherit",
        },
        ".MuiButtonBase-root.MuiButton-root:not(.PreListFiltersNew-CleanButton)": {
            height: "40px",
            padding: "3px 8px",
            fontSize: "16px",
            textTransform: "inherit",
            justifyContent: "space-between",
            width: "100%",
        },
        ".PreListFiltersNew-SortFiltersSelector ": {
            ".MuiButtonBase-root.MuiButton-root": {
                height: "40px",
                padding: "3px 8px",
                fontSize: "16px",
                textTransform: "inherit",
                justifyContent: "start",
                width: "100%",
                ".MuiButton-endIcon": {
                    position: "absolute",
                    right: "8px"
                }
            }
        },
    }
}) as typeof Box;

