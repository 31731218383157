import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    content: string;
    header: React.ReactNode;
    hideContent?: boolean;
    isOldVersion?: boolean;
    versionDate?: string;
}

export const cnClassName = cn('LMSQuestion');