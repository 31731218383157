import li18n from 'localization/src/i18n'

(window as any).currentNamespace = 'default';

export default li18n

// import i18next, { TOptions, Callback } from 'i18next';
// import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import { initReactI18next } from 'react-i18next';
// import { store } from 'redux/store';

// const lng = localStorage.getItem('i18nextLng') || 'ru';

// (window as any).currentNamespace = 'default';

// //.com/ru/Data/v3/locale/en.json тут получать локали.
// // урл для получения с сервера переводов https://rftest.pryaniky.com/ru/data/v3/locale/en.json

// i18next
//   // .use(Cache)
//   .use(LanguageDetector)
//   // .use(Backend)
//   .use(initReactI18next) // if not using I18nextProvider
//   .init({
//     lng,
//     defaultNS: 'default',
//     // backend: {
//       // for all available options read the backend's repository readme file
//       // loadPath: '/localizations/{{lng}}.json',
//       // addPath: 'localizations/{{lng}}',
//       // loadPath: '/ru/Data/v3/locale/{{lng}}.json',
//       // addPath: 'ru/Data/v3/locale/{{lng}}.json',
//       // loadPath: '/public/locale/{{lng}}.json',
//       // addPath: 'public/locale/{{lng}}',
//     // },
//     debug: false,
//     fallbackLng: ['ru'],
//     fallbackNS: 'default',
//     keySeparator: false,

//     interpolation: {
//       escapeValue: false, // not needed for react!!
//     },

//     // react i18next special options (optional)
//     react: {
//       bindI18n: 'languageChanged loaded',
//       bindStore: 'added removed',
//       nsMode: 'default',
//       // wait: false,
//       wait: true,
//       useSuspense: false
//     },
//     resources: {
//       ru: { 
//         default: require('./default.ru.json')
//       },
//       // en: { translation: require('./en.json') },
//       // ru: require('%PUBLIC_URL%/localizations/ru.json'),
//       // en: { translation: require('./en.json') },
//       // spa: { translation: require('./spa.json') },
//     },
//   });

// const translateOrFallback = (request: string, upper: 'all' | boolean = true, dataInText: string = '', options: TOptions | string = {}) => {
//   let rez = i18next.t(request, typeof options === 'string' ? options : { defaultValue: request, ...options }) || request;
//   if(document.cookie.includes('show_i18n_keys=true')) return request;
//   if (dataInText) {
//     rez = rez.replace('%DATA%', dataInText);
//   }

//   if (upper === 'all') {
//     return rez.toUpperCase();
//   } else if (upper) {
//     return rez.charAt(0).toUpperCase() + rez.slice(1);
//   }

//   return rez;
// };

// function translate(key: string, options: TOptions | string = {}) {
//   if (document.cookie.includes('show_i18n_keys=true')) return key;
//   return i18next.t(key, { defaultValue: typeof options === 'string' ? options : key, ns: (window as any).currentNamespace, ...(typeof options === 'string' ? {} : options) });
// }

// export default {
//   ...i18next,
//   setDefaultNamespace: i18next.setDefaultNamespace,
//   // i18n,
//   changeLanguage: (lng: string, callback?: Callback | undefined) => i18next.changeLanguage(lng, callback),
//   translateOrFallback,
//   tf: translateOrFallback,
//   t: translate
// };
