import React from 'react';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { cnWidget, IWidgetProps } from '../Widget.index';
import { widgets } from "i.widgets";
import { VirtualUser } from '../../VirtualUser/VirtualUser';

const type = 'virtualUser';

export default class WidgetTypeVirtualUser extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    return (
      <VirtualUser {...this.props} id={this.props.widget.id} data={this.props.widget.settings?.virtualUser} context={this.props.wcontext} className={cnWidget({ type, subtype })} tag={widgets.components.common} text='' />
    );
  }
}