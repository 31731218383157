import { IdeaFields, Field } from './Fields/Types'
// import { BaseNews, defaultBaseNews } from '../BaseType'
import { IBaseNews, createDefaultNews } from 'News/types/baseNews'
export interface BaseIdeaNews extends IBaseNews {
    newstype: 21;
    isAnonimous?: boolean;
    allowAnonimous: boolean;

}
export interface IdeaNews extends BaseIdeaNews {
    idea: Idea;
    allowAnonimous: boolean;
}

export interface IdeaNewsCreate extends BaseIdeaNews {
    idea: IdeaCreate;
    postParams?: any;
}
export interface BaseIdea {
    additionalFieldsValues: AdditionalFieldsValue[];
    isAnonimous?: boolean;
    postParams?: any;
}
export interface Idea extends BaseIdea {
    currentStatus: Status | null;
    activeExpertiseStatus?: any;
    responsibleForStatusUser?: any;
    responsibleForStatusView: any[] | null;
    showExpertiseResultButton: boolean;
    showExpertiseButton: boolean;
    showFinalizeExpertiseButton: boolean;
    showChangeStatusButton: boolean;
    ideaExperts: any[] | null;
    ideaParticipants: any[] | null;
    ideaInvestors: any[] | null;
    ideaHeads: any[] | null;
    scoring: any[];
    ideaUniqueIdentifier: string | null;
    statusChangeTarget: StatusChangeTarget | null;
    postParams?: any;
    isAnonimous?: boolean;
    isIdeaModerator: boolean;
    isFundDistributed?: boolean
}
export interface IdeaCreate extends BaseIdea {
    currentStatus?: Status;
    activeExpertiseStatus?: any;
    responsibleForStatusUser?: any;
    responsibleForStatusView?: any[];
    showExpertiseResultButton?: boolean;
    showExpertiseButton?: boolean;
    showFinalizeExpertiseButton?: boolean;
    showChangeStatusButton?: boolean;
    ideaExperts?: any[];
    ideaParticipants?: any[];
    ideaInvestors?: any[];
    ideaHeads?: any[];
    scoring?: any[];
    ideaUniqueIdentifier?: string;
    statusChangeTarget?: StatusChangeTarget;
    postParams?: any;
}

export interface Status {
    isDistributeFund: boolean;
    isAllowInvestment: boolean;
    isExpertEvaluation: boolean;
    createProjectGroup: boolean;
    archiveProjectGroup: boolean;
    allowEditByAuthor: boolean;
    requireSetResponsibleForStatus: boolean;
    distributeMultiplicator: number;
    fields: Field[];
    permissions: any[];
    workflows: Workflow[];
    userCanMoveFromStatus: boolean;
    userCanMoveToStatus: boolean;
    targetStatuses?: any;
    responsibleGroupId: number;
    responsibleGroup: ResponsibleGroup;
    responsibleRoleType: string;
    statusChangeDate?: any;
    isStatusHiddenIfNoAccess: boolean;
    isParallelProcessingStatus: boolean;
    startParallelProcessingStatusProcessing?: any;
    endParallelProcessingStatusProcessing?: any;
    scoringName: string;
    scoringFormula?: any;
    id: string;
    name: string;
    description: string;
    role?: any;
}

export interface AdditionalFieldsValue {
    k: string;
    v: IdeaFields[];
}

export interface StatusChangeTarget {
    statuses: Status[];
}

export interface Group {
    pkid: number;
    groupUid: string;
    name: string;
    alias?: any;
    systemGroupName?: any;
    description?: any;
    isSystemGroup: boolean;
    isHidden: boolean;
    isDeleted: boolean;
    isMeInGroup: boolean;
    isOfficialGroup: boolean;
    isRequestAccessSent: boolean;
    isArchived: boolean;
    visibilityType: number;
    childGroupCount: number;
    imgId48x48: string;
    imgId198x198: string;
    imgUrl48x48?: any;
    imgUrl198x198?: any;
    actions: string[];
    groupType?: any;
    isContentDisabled: boolean;
}

export interface Workflow {
    id: string;
    nextStatusId: string;
    investThanksCount: number;
    daysCount: number;
    investorsCount: number;
    repeatAfterDays: number;
    isIncludeInExpireDigest: boolean;
    expression: string;
    nextStatus?: any;
    messageTemplateName?: any;
    waction: string;
    groupId: number;
    roleType: string;
    group: Group;
}

export interface Status {
    isDistributeFund: boolean;
    isAllowInvestment: boolean;
    isExpertEvaluation: boolean;
    createProjectGroup: boolean;
    archiveProjectGroup: boolean;
    allowEditByAuthor: boolean;
    requireSetResponsibleForStatus: boolean;
    distributeMultiplicator: number;
    fields: Field[];
    permissions: any[];
    workflows: Workflow[];
    userCanMoveFromStatus: boolean;
    userCanMoveToStatus: boolean;
    targetStatuses?: any;
    responsibleGroupId: number;
    responsibleGroup: ResponsibleGroup;
    responsibleRoleType: string;
    statusChangeDate?: any;
    isStatusHiddenIfNoAccess: boolean;
    isParallelProcessingStatus: boolean;
    startParallelProcessingStatusProcessing?: any;
    endParallelProcessingStatusProcessing?: any;
    scoringName: string;
    scoringFormula?: any;
    id: string;
    name: string;
    description: string;
    role?: any;
}

export interface ResponsibleGroup {
    pkid: number;
    groupUid: string;
    name: string;
    alias?: any;
    systemGroupName?: any;
    description?: any;
    isSystemGroup: boolean;
    isHidden: boolean;
    isDeleted: boolean;
    isMeInGroup: boolean;
    isOfficialGroup: boolean;
    isRequestAccessSent: boolean;
    isArchived: boolean;
    visibilityType: number;
    childGroupCount: number;
    imgId48x48: string;
    imgId198x198: string;
    imgUrl48x48?: any;
    imgUrl198x198?: any;
    actions: string[];
    groupType?: any;
    isContentDisabled: boolean;
}


export const defaultResponsibleGroup: ResponsibleGroup = {
    pkid: 0,
    groupUid: '',
    name: '',
    isSystemGroup: false,
    isHidden: false,
    isDeleted: false,
    isMeInGroup: false,
    isOfficialGroup: false,
    isRequestAccessSent: false,
    isArchived: false,
    visibilityType: 0,
    childGroupCount: 0,
    imgId48x48: '',
    imgId198x198: '',
    actions: [],
    isContentDisabled: false,
}

export const defaultStatus: Status ={ 
    isDistributeFund: false,
    isAllowInvestment: false,
    isExpertEvaluation: false,
    createProjectGroup: false,
    archiveProjectGroup: false,
    allowEditByAuthor: false,
    requireSetResponsibleForStatus: false,
    distributeMultiplicator: 0,
    fields: [],
    permissions: [],
    workflows: [],
    userCanMoveFromStatus: false,
    userCanMoveToStatus: false,
    responsibleGroupId: 0,
    responsibleGroup: defaultResponsibleGroup,
    responsibleRoleType: '',
    isStatusHiddenIfNoAccess: false,
    isParallelProcessingStatus: false,
    scoringName: '',
    id: '',
    name: '',
    description: '',
}

export const defaultStatusChangeTarget: StatusChangeTarget ={ 
    statuses: []
}

export const defaultIdea: Idea = {
    additionalFieldsValues: [],
    currentStatus: defaultStatus,
    responsibleForStatusView: [],
    showExpertiseResultButton: false,
    showExpertiseButton: false,
    showFinalizeExpertiseButton: false,
    showChangeStatusButton: false,
    ideaExperts: [],
    ideaParticipants: [],
    ideaInvestors: [],
    ideaHeads: [],
    scoring: [],
    ideaUniqueIdentifier: '',
    statusChangeTarget: defaultStatusChangeTarget,
    isIdeaModerator: false
}
export const defaultIdeaCreate: IdeaCreate = {
    additionalFieldsValues: []
}

export const defaultIdeaNews: IdeaNews = {
    ...createDefaultNews(),
    newstype: 21,
    idea: defaultIdea,
    allowAnonimous: false,
}

export const defaultIdeaNewsCreate: IdeaNewsCreate = {
    ...createDefaultNews(),
    newstype: 21,
    idea: defaultIdeaCreate,
    allowAnonimous: false,
}