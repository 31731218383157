import { NDataStorage } from "./interfaces";
// import { base } from './pad.types';

export const defaultDataStorageReducer: NDataStorage.Reducer = {
    empty: {},
    leftMenu: {},
    vacancies: {},
    vacanciesShort: {},
    vacanciesRequests: {},
    vacanciesSuggests: {}
};