import React, { FC } from 'react'
import { ITestInfoProps, cnTestInfo } from './TestInfo.index'
import './TestInfo.scss'
import TestInfoLayout from '../TestInfoLayout'
import TestInfoList from '../../components/TestInfoList'

export const TestInfoPresenter: FC<ITestInfoProps & any> = ({ unitContent, start, skip, log, fullscreen }) => {
    const attemptsCount = log.attemptsCount === 0 ? 1 : log.attemptsCount
    return <TestInfoLayout
        description={unitContent.description}
        name={unitContent.name}
        skip={skip}
        start={start}
        allowSkip={unitContent.allowSkip}
        fullscreen={fullscreen}
    >
            <TestInfoList
                isStart={true}
                unitSkipType={unitContent.skipUnitType}
                scoreCountMode={unitContent.scoreCountMode}
                passingMinScore={unitContent.passingMinScore}
                attemptsCount={attemptsCount}
                maxAttemptsCount={unitContent.maxAttemptsCount}
                questionsCount={unitContent.questions.length}
            />
    </TestInfoLayout>
}

export const TestInfo = TestInfoPresenter;