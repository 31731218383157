import { ITitlesSetItemActionPayload, ITitlesActions } from './actions.interfaces';

export const TITLES_SET = 'TITLES_SET';
export const TITLES_SET_ITEM = 'TITLES_SET_ITEM';

export function titlesSet(payload: string[]): ITitlesActions {
  return {
    type: TITLES_SET,
    payload
  }
}

export function titlesSetItem(payload: ITitlesSetItemActionPayload): ITitlesActions {
  return {
    type: TITLES_SET_ITEM,
    payload
  }
}