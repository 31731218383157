import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { IStateType } from 'redux/store';
import { Action, Dispatch, bindActionCreators } from 'redux';
import { getMenuItemById, getMenuService } from '../redux/selectors';
import { menuAddItem, menuSetExpanded, menuMoveItem, menuOpen, menuUpdateItem, menuDeleteItem, menuSetExpandedAll, menuHidden } from '../redux/actions';
import { IMenu } from '../redux/interfaces';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { DragObjectWithType } from 'react-dnd';

export const cnLeftMenuItem = cn('LeftMenuItem');

export interface ILeftMenuItemProps extends IClassNameProps, React.PropsWithChildren<ILeftMenuItemOwnProps>, ImapLeftMenuItemStateToProps, ImapLeftMenuItemActionsToProps {};

export interface ILeftMenuItemOwnProps {
  id: string;
  path: string[];
  isFirst: boolean;
  active?: boolean;
};

// export const getAuthUserData = (state: IStateType) => ({
//   userPhotoUrl: getAuthUser(state).baseData.userPhotoUrl as string,
//   imgId: getAuthUser(state).baseData.imgId as string,
//   userName: getAuthUser(state).baseData.displayName as string,
//   userId: getAuthUser(state).baseData.id as string,
// });

export const mapLeftMenuItemStateToProps = (state: IStateType, props: ILeftMenuItemOwnProps) => ({
  data: getMenuItemById(props.id)(state),
  parentData: getMenuItemById(props.path[props.path.length - 1] || GUID_EMPTY)(state),
  expanded: getMenuService(state).expanded.includes(props.id),
  edit: getMenuService(state).edit,
  authUserData: getAuthUser(state).baseData,
  opened: getMenuService(state).opened, // состояние открытого меню
  openedMenu: state.store.appDesign?.showMenuOpened // настройка Постоянно открытого меню
});

export type ImapLeftMenuItemStateToProps = ReturnType<typeof mapLeftMenuItemStateToProps>;

export const mapLeftMenuItemActionsToProps = (d: Dispatch<Action>) => bindActionCreators({
  menuAddItem,
  menuOpen,
  menuHidden,
  menuUpdateItem,
  menuSetExpanded,
  menuMoveItem,
  menuDeleteItem,
  menuSetExpandedAll
}, d);

export type ImapLeftMenuItemActionsToProps = ReturnType<typeof mapLeftMenuItemActionsToProps>;

export interface ILeftMenuItemLabelProps {
  data: IMenu.Item;
  edit: boolean;
  expanded: boolean;
  authUserData: ReturnType<typeof getAuthUser>['baseData'];
  parentData: IMenu.Item;
  onClick?: (event: any) => void;
}

export interface IDraggingItem extends DragObjectWithType {
  id: string;
  path: string[];
  renderType: string;
}