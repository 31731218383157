import React, { FC, useEffect, useMemo, useState, memo, useCallback, useRef, RefObject } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import { Attachments } from 'uielements/src/Attachments/Attachments'
import { AttachmentUploader } from 'uielements/src/AttachmentUploader/AttachmentUploader'
import { Button, ErrorMsg } from 'uielements/src'
import withHelpTooltip from 'blocks/HOCs/Button/withHelpTooltip'

const SendButton = withHelpTooltip(Button)

type IPresenterProps = News.NewsCreator.IPresenterProps
type IOwnProps = News.NewsCreator.IOwnProps
type IDispatchProps = News.NewsCreator.IDispatchProps
type IStateProps = News.NewsCreator.IStateProps
type IState = News.NewsCreator.IState


export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    // pathCreator,
    // currentType,
    // getNode,
    attachments = [],
    denyMIME,
    maxFileSize,
    onSubmit,
    pathCreatorNews,
    actions
}) => {
    const self = useRef<HTMLDivElement>(null)

    const onChange = useCallback((attachments: any, validate: boolean) => {
        pathCreatorNews({ attachments })
    }, [])

    const onDelete = useCallback((file: any) => {
        pathCreatorNews({ attachments: attachments.filter((a: any) => a.id !== file.id) })
    }, [attachments])

    return <div ref={self} className={cnClassName({}, [className])}>
        {children}
        <Attachments className={cnClassName('Attachments')} files={attachments} onDelete={onDelete} />

        {self.current && <AttachmentUploader
            elem={self.current}
            denyMIME={denyMIME}
            maxFileSize={maxFileSize}
            onError={() => { }}
            attachments={attachments}
            reference={() => { }}
            onChange={onChange} />}

        <div className={cnClassName('Actions')}>
            <div className={cnClassName('FormActions')}>
                {actions}
            </div>
            <div className={cnClassName('SendActions')}>
                <SendButton
                    main
                    className={'ActionButtons-SaveBtn'}
                    // disableHelp={!isDisabled}
                    tooltipNoPadding
                    showDelay={300}
                    // help={<div>
                    //     {actionsOpen && validationErrors.map((err, idx) => <ErrorMsg key={idx} type={'info'} className={cnEditorForm('ErrorBox')}>{err.errorI18nKey ? <Translate i18nKey={err.errorI18nKey} variablesI18nKeys={err.errorVariablesI18nKey} /> : err.message}</ErrorMsg>)}
                    // </div>}
                    // disabled={isDisabled}
                    onClick={onSubmit}>send</SendButton>
            </div>
        </div>

    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withComponentEnjector<IPresenterProps>(memo(Presenter), ['news_editor']));
