import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Button, Spinner, Icon } from 'uielements/src'
import { Tabs } from '../Tabs/Tabs'
import { Avatar } from 'uielements/src/Avatar/Avatar';
import headerImg from './image.png'
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import { useLoadTrackByID } from 'LMSModule/utils/hooks/useLoadTrackByID'
import { ImgLoader } from 'blocks/ImgLoader/ImgLoader';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import ButtonM from '@material-ui/core/Button';
// const FormControl = styled(FormControlO)(controlStyle)

export const Presenter: FC<IPresenterProps> = ({
    className,
    match,
    updateTrack
}) => {
    const [isLoading, track, { }, { setName, name, img, setImg }] = useLoadTrackByID(match.params.id)
    const [edit, setEdit] = useState(false)

    const tabs = [
        {
            value: 'files',
            title: tTr('tab.files'),
            id: 'files'
        }, {
            value: 'users',
            title: tTr('tab.users'),
            id: 'users'
        }, {
            value: 'statistic',
            title: tTr('tab.statistic'),
            id: 'statistic'
        }
    ];

    const changeEdit = () => {
        setEdit(!edit)
    }

    const onSave = () => {
        updateTrack({
            ...track,
            displayName: name,
            imgId: img?.imgId,
            imgUrl: img?.imgUrl
        })
        setEdit(false)
    }

    const onLoadEnd = (file: any) => {
        setImg({
            imgId: file?.id,
            imgUrl: file?.originalUrl
        })
    }



    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('RightActions')}>
            <Button
                className={cnClassName('BackLink')}
                theme={'unstyled_center'}
                type='rlink'
                href={'/TracksManage'}><Icon icon={'arrow-left'} />{tTr('track_info.back')}</Button>
            <Button
                className={cnClassName('EditBtn')}
                onClick={changeEdit}
            // theme={'unstyled_center'}
            ><Icon icon={'edit'} /></Button>
        </div>
        <div className={cnClassName('Content')}>
            {(!isLoading && track) && <>{edit ?
                <ImgLoader className={cnClassName('AvatarEdit')} type={'profileEdit'} previewUrl={img?.imgUrl} onLoadEnd={onLoadEnd} /> :
                <Avatar imgUrl={track.imgUrl || headerImg} size={64} className={cnClassName('Avatar')} />}</>}
            <div className={cnClassName('Section')}>

                <div className={cnClassName('Info')}>
                    {edit ? <FormControl fullWidth>
                        <TextField
                            label={tTr('track_edit.track.name.1')}
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                        />
                    </FormControl> :
                        <h3 className={cnClassName('Title')}>{(!isLoading && track) && track.displayName}</h3>}
                </div>

                {edit && <div className={cnClassName('EditActions')}>
                    <ButtonM
                        variant={'contained'}
                        color={'primary'}
                        onClick={onSave}
                        size={'small'}
                        disableElevation>{tTr('track_edit.edit.save.1')}</ButtonM>
                    <ButtonM
                        variant={'outlined'}
                        color={'default'}
                        onClick={() => setEdit(false)}
                        size={"small"}
                        disableElevation>{tTr('track_edit.edit.cancle.1')}</ButtonM>
                </div>}
            </div>
        </div>
        <div className={cnClassName('Nav')}>
            <div className={cnClassName('Noop')}></div>
            <Tabs tabs={tabs} defaultVal={'files'} />
            <div className={cnClassName('Noop')}></div>
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
