import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import addUserModal from '../../dialogs/SubscribeUser'
import { tTr, } from 'LMSModule/utils/i18n.adaptor'

import { ItemsList } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem'
import { GroupListItem } from 'muicomponents/src/ItemsListDialog/GroupListItem/GroupListItem'
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody'
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { IBaseUser } from 'uielements/src/UsersList/UsersList.index'
import { API } from 'utils/src/utils'
import { useParams } from 'react-router'
import { Button } from 'muicomponents/src/Button/Button'
import { removeUsers } from '../../redux/actions/TracksManage'
import { confirm } from 'muicomponents/src/Confirm/Confirm'

async function gerUsersPromise(
    tagId: string,
    count: number,
    skipCount: number,
    search?: string
) {
    const response = await API.learningTracks.getTrackUsers({
        tagId,
        count,
        skipCount,
        search
    })
    const result = await response.r as any as { data: IBaseUser[] } & IBasicResponse
    return result.data.map((item) => ({ ...item, url: `/user/${item.id}`, type: 'user' }))
}

async function gerGroupsPromise(
    tagId: string,
    count: number,
    skipCount: number,
    search?: string
) {
    const response = await API.learningTracks.getTrackGroups({
        tagId,
        count,
        skipCount,
        search
    })
    const result = await response.r as any as { data: IBaseUser[] } & IBasicResponse
    return result.data.map((item) => ({ ...item, url: `/group/${item.id}`, type: 'group' }))
}

export const Presenter: FC<IPresenterProps> = ({ className }) => {
    const { id } = useParams<{ id: string }>()
    const dispatch = useDispatch()

    return <div className={cnClassName({}, [className])}>
        <ItemsList<IBaseUser & { url: string, type: string }>
            BodyComponent={ItemsListBody}
            method={async function (skipCount, { count, search, tabsValue }) {
                return await tabsValue === 'users' ? gerUsersPromise(id, count, skipCount, search) : gerGroupsPromise(id, count, skipCount, search)
            }}
            middleware={(params) => ({
                ...params,
                ItemComponent: params.tabsValue === 'users' ? UsersListItem : GroupListItem
            })}
            tabsValue='users'
            tabs={[
                {
                    id: 'users',
                    label: tTr('student_list.tabs.users.1')
                },
                {
                    id: 'groups',
                    label: tTr('student_list.tabs.groups.1')
                }
            ]}
            itemClickAction={async function (option, item, actions) {
                try {
                    if (option.id === 'delete') {
                        const response = await confirm({
                            acceptText: tTr('item.file.context.delete.confirmBtnText'),
                        })
                        if (response === 'confirm') {
                            dispatch(removeUsers({ tagName: id, users: [item] }))
                            actions.removeItems([item])
                        }
                    }
                } catch (error) {

                }
            }}
            itemOptions={[
                {
                    content: tTr('item.file.context.delete'),
                    id: 'delete'
                }
            ]}
            listHeaderProps={{
                SearchActions: <Button
                    sx={{ whiteSpace: 'nowrap', marginLeft: '12px' }}
                    variant='contained'
                    className={cnClassName('AddButton')}
                    onClick={() => {
                        addUserModal({
                            trackId: id
                        }).then(({ users }) => {

                        }).catch((e) => {

                        })
                    }}
                >{tTr('add.users')}</Button>
            }}
        />
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
