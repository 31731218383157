import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    scormUnitComplete,
    scormUnitSuccessStatus,
} from '../../redux/actions/LMS'
import {
    loadAllScormParams
} from '../../redux/actions/COMMON'
import ScormAPI2004 from 'LMSModule/utils/scormAPI/scormAPI2004/scormAPI2004'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
///////////

export type IScormUnitPresenterProps = IScormUnitOwnProps & IScormUnitStateProps & IDispatchProps

export interface IScormUnitState { }

export interface IScormUnitOwnProps extends IClassNameProps {
    unitContent: any;
    name: string;
    saveParam: any;
    unitLog: any;
    scormCMI: ScormAPI2004;
    fullscreen?: boolean;
}

export type IScormUnitStateProps = ReturnType<typeof mapStateToProps>

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IScormUnitOwnProps) => ({
    currentUser: getCurrentUser(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IScormUnitOwnProps) => bindActionCreators({
    scormUnitComplete,
    scormUnitSuccessStatus,
    loadAllScormParams
}, dispatch);

////////////

export const cnClassName = cn('LMSScormUnit');