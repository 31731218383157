import React, { FC, useEffect, useState } from 'react'
import { cnSessionsList, mapDispatchToProps, mapStateToProps } from './SessionsList.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './SessionsList.scss'
import { Button } from 'uielements/src';
import { Item } from './Item/Item'
import { Search } from '../Search/Search'
import { DialogCreateSession } from '../../dialogs/CreateSession/CreateSession'
import CourseTabs, { ITabType } from '../../components/Tabs'
import { withPreloader } from '../../LMS/Preloader/Preloader'
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import ListPlaceholder from '../../components/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

const LoadButton = withLoadSpin(Button)

const List: FC<any> = ({ tab, archiveSessions, activeSessions, cid }) => {
    return <div className={cnSessionsList('List')}>
        {tab === 'active' && activeSessions.map((sid: string) => <Item key={sid} cid={cid} sid={sid} className={cnSessionsList('Item')} />)}
        {tab === 'archive' && archiveSessions.map((sid: string) => <Item disableActions key={sid} cid={cid} sid={sid} className={cnSessionsList('Item')} />)}
    </div>
}
const WL = withPreloader(List, ['archiveSessions', 'activeSessions'])
const Loader: FC<any> = (props) => {
    const {
        cid,
        tab,
        loadCourseSessions,
        archiveSessionsFinished,
        activeSessionsFinished,
        isLoading,
        activeSessions = [],
        archiveSessions = []
    } = props

    const isFinished = tab === 'archive' ? archiveSessionsFinished : activeSessionsFinished
    const loadMore = () => loadCourseSessions(cid, tab === 'archive')
    const triggerRef = useInfiniteScroll({
        loadMore,
        isLoading,
        isFinished,
    });
    return <ListPlaceholder
        className={cnSessionsList('Placeholder')}
        isFinished={isFinished}
        isLoading={isLoading}
        itemsCount={tab === 'active' ? activeSessions.length : archiveSessions.length}
        titleEmpty={tT('sessions_list.empty.title.1')}
        textEmpty={tab === 'active' ? tT('sessions_list.empty.text.1') : tT('sessions_list.empty.text.archive.1')}
        textLoad={tT('sessions_list.load.text.1')}
    >
        <WL {...props} />
        {!isFinished && (
            <LoadButton
                forwardedRef={triggerRef}
                isLoading={isLoading}
                className={cnSessionsList('Trigger')}
                onClick={isLoading ? void 0 : loadMore}>
                {tT('sessions_list.load.more.1')}
            </LoadButton>
        )}
    </ListPlaceholder>
}
export const WSL = connect(
    mapStateToProps,
    mapDispatchToProps
)(Loader);

export const SessionsListPresenter: FC<any> = ({ className, cid }) => {
    const TABS = [
        { value: 'active', content: tT('sessions_list.tabs.active.1') },
        { value: 'archive', content: tT('sessions_list.tabs.archive.1') }
    ]
    const [tab, setTab] = useState<ITabType>(TABS[0]);

    const selectTab = (tab: ITabType) => setTab(tab)
    const [openDialog, setOpenDialog] = useState(false)
    return <div className={cnSessionsList({}, [className])}>
        <div className={cnSessionsList('Header')}>
            {/* <Search className={cnSessionsList('Search')} /> */}

            <Button main className={cnSessionsList('CreateSession')} onClick={() => DialogCreateSession({cid}).then(() => { }).catch(() => { })}>{tT('sessions_list.create.1')}</Button>

            {/* <CreateSessionDialog cid={cid} isShown={openDialog} onClose={() => setOpenDialog(false)} /> */}
        </div>

        <CourseTabs className={cnSessionsList('Tabs')} onChange={selectTab} selected={tab} tabs={TABS} />

        <WSL {...{ tab: tab.value, cid }} />

    </div>
}


export const SessionsList = withRouter(SessionsListPresenter);
