import React, { useState, useEffect } from 'react';
import { IIdeaFieldProps } from './FieldProps';
import { Bool } from './Bool/Bool';
import { TextArea } from './Textarea/Textarea';
import { String } from './String/String';
import { Int } from './Int/Int';
import { UniqueIdentifier } from './UniqueIdentifier/UniqueIdentifier'
import { Tags } from './Tags/Tags';
import { Single } from './Single/Single';
import { Multi } from './Multi/Multi';
import { DateTime } from './DateTime/DateTime';
import { MatrixOnePerRow } from './MatrixOnePerRow/MatrixOnePerRow';
import { OfferResponsibleForStatusUsers } from './OfferResponsibleForStatusUsers/OfferResponsibleForStatusUsers';
import { ResponsibleForStatusUser } from './ResponsibleForStatusUser/ResponsibleForStatusUser';
import { cloneObject } from 'utils/src/utils';
import { IdeaFields} from './Types';

export const IdeaField: React.FC<IIdeaFieldProps> = (props) => {
    const [field, setField] = useState(() => cloneObject(props.field));

    useEffect(() => {
        setField(cloneObject(props.field))
    }, [props.field])

    const fieldChanget = (fields: IdeaFields) => {
         setField(fields);
         props.onChange && props.onChange(fields);
    }

    switch (props.field.field.fieldType) {
        case 'Bool':
        case 'UserNotExpert':
            return <Bool field={field} onChange={fieldChanget} />;
        case 'Textarea':
            return <TextArea field={field} onChange={fieldChanget} />;
        case 'String':
            return <String field={field} onChange={fieldChanget} />;
        case 'Int':
            return <Int field={field} onChange={fieldChanget} />;
        case 'UniqueIdentifier':
            return <UniqueIdentifier field={field} onChange={fieldChanget} />;
        case 'Tags':
            return <Tags field={field} onChange={fieldChanget} />;
        case 'Single':
            return <Single field={field} onChange={fieldChanget} />;
        case 'Multi':
            return <Multi field={field} onChange={fieldChanget} />;
        case 'Date':
            return <DateTime field={field} onChange={fieldChanget} />;
        case 'DateTime':
            return <DateTime field={field} onChange={fieldChanget} />;
        case 'MatrixOnePerRow':
            return <MatrixOnePerRow field={field} onChange={fieldChanget} />;
        case 'OfferResponsibleForStatusUsers':
            return <OfferResponsibleForStatusUsers field={field} onChange={fieldChanget} />;
        case 'ResponsibleForStatusUser':
            return <ResponsibleForStatusUser field={field} onChange={fieldChanget} />;
            
        default:
            return <span>Unknown field type: [{props.field.field.fieldType}]</span>;
    }
}