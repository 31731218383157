import { NumberTextField } from 'muicomponents/src/TextField/TextField';
import React, { ComponentProps, FC } from 'react';

export const CountSelector: FC<ComponentProps<typeof NumberTextField>> = ({
    ...props
}) => {

  return (
    <NumberTextField
      {...props}
      className={`ShopCountSelector${props.className ? ` ${props.className}` : ''}`}
      withControls
      useCircleIcons
      hideApperance
      textFieldSx={{
        "input": {
          textAlign: 'center'
        },
        maxWidth: '60px',
        minWidth: '60px',
        ['& input']: {
          paddingLeft: '0px',
          paddingRight: '0px',
        },
        ...props.textFieldSx
      }}
      inputProps={{
        min: 0,
        step: 1,
        ...props.inputProps
      }}
    />
  );
};