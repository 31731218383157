import Component, { Presenter } from './component';
import {
    KPI,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = KPI.TableRow.IOwnProps;
export type IComponentPresenterProps = KPI.TableRow.IPresenterProps;


export default Component