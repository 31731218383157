import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { NewsContextProps } from '../../contexts/news'
import {
    getNewsParamsById
} from '../../redux/saga/selectors'
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace ShortView {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends NewsContextProps {

        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.ShortView.IOwnProps

const newsParams = [
    'text',
    'id',
    'additionalFields'
] as const

export const mapStateToProps = (state: any, { newsId }: IOwnProps) => ({
    ...getNewsParamsById(newsId, newsParams)(state)
})
export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({

}, dispatch);

////////////

export const cnClassName = cn('NewsShortView');