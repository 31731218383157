import React, { FC, useEffect, ComponentProps, useContext, useMemo, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { cnNewsTypeBadges } from './Badges.index';
import './Badges.scss';
import { UsersSuggester as UsersSuggesterC, BadgeSelector } from 'muicomponents/src';
import { DraftInput as DraftInputC, useDraftEdit } from 'muicomponents/src/DraftInput/DraftInput';
import { Translate } from 'localizations/Translate';
import { ComponentInjector } from 'utils/src/ComponentInjector';

import { useDidUpdateEffect } from 'utils/src/hooks';
import { PrepareErrorText } from 'News/utils/utils.validate';

import MDRender from 'uielements/src/CommonmarkRender'
import { NewsCreateContext } from 'News/creator/Creator/Creator.constants';
import { useBadgesEdit } from './Badges.hooks';
import { BadgesValidateRules } from './Badges.validate';
import { useDraftEditorStyle } from 'News/creator/hooks';
import { memoizedComponent } from 'News/creator/utils.sender';

const UsersSuggester = memoizedComponent(UsersSuggesterC);
const DraftInput = memoizedComponent(DraftInputC);

const prepareI18nKey = (key: string) => `pryaniky.news.create.badges.${key}`;

const NewsTypeBadgesPresenter: FC<{}> = ({
}) => {

    const { creatorId } = useContext(NewsCreateContext);

    const {
        data,
        isAdmin,
        errors,
        clearEditor,
        badgesParams,
        changeNews
    } = useBadgesEdit(creatorId);

    const {
        text: currentNewsText,
        draftRawState,
        users,
        groups,
        badge
    } = data;

    const textRef = useRef(currentNewsText);
    textRef.current = currentNewsText;

    const {
        state,
        draftState,
        setDraftState
    } = useDraftEdit(draftRawState, clearEditor);

    useDidUpdateEffect(() => {
        // because text editor push additional state change when state is empty externally
        if(!textRef.current && !state.text) return;
        changeNews({
            text: state.text,
            draftRawState: state.raw
        });
    }, [state]);

    const usersChange = useCallback<NonNullable<ComponentProps<typeof UsersSuggester>['onChange']>>((e, users) => {
        // when we have ticket to multiselect users then uncomment next line & to comment line under then;
        // changeNews({ users: (users || []) as any });
        
        if(Array.isArray(users)) {
            const usersList = users?.filter(el => el.type === 'user') || [];
            const groupsList = users?.filter(el => el.type === 'group') || [];
            if(!usersList.length && !groupsList.length) {
                BadgesValidateRules.users!.notNull!.value = true;
                BadgesValidateRules.groups!.notNull!.value = true;
            }
            if(usersList.length && !groupsList.length) {
                BadgesValidateRules.groups!.notNull!.value = false;
            }
            if(!usersList.length && groupsList.length) {
                BadgesValidateRules.users!.notNull!.value = false;
            }
            changeNews({
                users: (usersList || []) as any,
                groups: (groupsList || []) as any
            });
        }
    }, []);

    const suggesterValues = useMemo(() => {
        return [ ...users, ...groups ];
    }, [ users, groups ]);

    const draftCommonProps = useDraftEditorStyle();

    const UsersSuggesterProps = useMemo<Partial<ComponentProps<typeof UsersSuggester>>>(() => ({
        TextFieldProps: {
            label: <Translate i18nKey={prepareI18nKey('users')} />,
            error: Boolean(errors.users),
            helperText: Boolean(errors.users) && PrepareErrorText(errors.users)
        },

    }), [errors.users]);

    const DraftInputProps = useMemo<Partial<ComponentProps<typeof DraftInput>>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('text')} />
    }), []);

    const badgeSelect = useCallback<ComponentProps<typeof BadgeSelector>['onChange']>((value) => changeNews({ badge: value }), []);

    const BadgeSelectorProps = useMemo<Partial<ComponentProps<typeof BadgeSelector>>>(() => ({
        error: Boolean(errors.badge),
        helperText: Boolean(errors.badge) && PrepareErrorText(errors.badge) || <Translate i18nKey={prepareI18nKey('badge.helperText')} />
    }), [errors.badge]);

    return (
        <>
            <UsersSuggester
                test-id={'timeline-create-badges-users'}
                {...UsersSuggesterProps}
                isAdmin={isAdmin}
                withCheckboxes
                multiple
                value={suggesterValues as any}
                onChange={usersChange}
                saveSearchOnBlur
            />
            <DraftInput
                test-id={'timeline-create-badges-text'}
                {...DraftInputProps}
                {...draftCommonProps}
                disableToolbar={!badgesParams?.allowRichEdit}
                value={draftState}
                onChange={setDraftState}
                error={
                    Boolean(errors.text)
                }
                helperText={
                    // TODO check types in BaseNews.text
                    Boolean(errors.text) && PrepareErrorText(errors.text as any)
                }
                clearValue={clearEditor}
            />
            <BadgeSelector
                test-id={'timeline-create-badges-badge'}
                {...BadgeSelectorProps}
                fullWidth
                value={badge}
                onChange={badgeSelect}
                list={badgesParams?.badges}
            />
        </>
    )
}

export const NewsTypeBadges = NewsTypeBadgesPresenter;

export const initNewsBadgesCreator = () => ComponentInjector.getInstance().addNode('news_creator_mui5', <NewsTypeBadges />, 'badges');