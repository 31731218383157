import {
    getNews
} from 'News/redux/saga/selectors'
import { TReducerWithCreativetasks } from './reducer.d'
import {
    createDefaultNews
} from 'News/types/baseNews'

type stateType = { newsReducer: TReducerWithCreativetasks }

export const getDistributeForms = (state: stateType) => getNews<TReducerWithCreativetasks>(state).CTDistributeForms


export const getDistributeFormById = (newsId: string) => (state: stateType) => getDistributeForms(state)?.[newsId] || createDefaultNews()


export const getRewardedComments = (state: stateType) => getNews<TReducerWithCreativetasks>(state).СTRewardedComments || {}


export const getRewardedCommentsForNews = (newsId: string) => (state: stateType) => getRewardedComments(state)[newsId] || {}


export const getCommentReward = (newsId: string, replyId: string) => (state: stateType) => getRewardedCommentsForNews(newsId)(state)[replyId] || 0