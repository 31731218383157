import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';
import { AnyAction } from 'redux';

export interface IStoreToggle extends AnyAction {
  payload: {
    variable: string;
    value?: boolean;
  }
}

let actions = {
  CHANGE_SLIDE: '',
  CHANGE_SLIDE_TIMER: '',
  INCREMENT_SLIDE_ITEM: '',
  INIT: '',
  MOUSE_OVER: '',
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.SLIDER) }), {} as typeof actions);