import { IStateType } from 'redux/store';

export const getMenuState = (state: IStateType) => state.menu;

export const getMenuStrusture = (state: IStateType) => getMenuState(state).structure;

export const getMenuItems = (state: IStateType) => getMenuState(state).items;

export const getMenuItemById = (id: string) => (state: IStateType) => getMenuItems(state)[id];

export const getMenuService = (state: IStateType) => getMenuState(state).service;

export const getMenuLoadingState = (state: IStateType) => getMenuService(state).loading;