import { IClassNameProps } from '@bem-react/core';
import { cnInfoBlock } from '../InfoBlock.index';

import { IDispatchProps } from '../../../redux/connector';

export interface ILayoutProps extends IClassNameProps, ILayoutStateProps, IDispatchProps {
  left: JSX.Element | string;
  right: JSX.Element | string;
  editBtn: JSX.Element | undefined;
}

export interface ILayoutState {
  text?: string;
}

export interface ILayoutStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})

export const cnLayout = (...data: any) => {
  let name = 'Layout';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnInfoBlock(name, ...data)
}