import timeline from './timeline.png';
import photo from './photo.png';
import pages from './pages.png';
import colleagues from './colleagues.png';
import attachments from './attachments.png';

export default {
    timeline,
    photo,
    pages,
    colleagues,
    attachments
}