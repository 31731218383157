import * as React from 'react';
import './Widget_type_lastUserPost.scss';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeInfoProps } from './Widget_type_infoData.index';
import { default as WidgetPresenter } from '../Widget';
import { widgets } from "i.widgets";
import { LastUserPostWidget } from 'blocks/LastUserPostWidget/LastUserPostWidget';

const types = {
  common: LastUserPostWidget,
}

const type = 'lastUserPost';

export default class LastUserPostPresenter extends WidgetPresenter<IWidgetTypeInfoProps> {

  public render() {
    if (!this.props.widget) return null;
    const Component = this.getComponent(types);
    return <Component {...this.props}
      settings={this.props.widget.settings}
      context={this.props.wcontext}
      tag={widgets.components.common}
      className={cnWidget({ type })}
    />
  }
}
