import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { IBodyProps } from 'utils/src/DialogCreator';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IStateType } from '../../../redux/store';
import {secretSantaJoinInfoAction, secretSantaUnJoinAction, getMyInfo, isUserParticipateAction} from '../../../redux/sagas/SecretSanta/actions'
import {getSecretSantaJoinInfo, getSecretSantaWidgetSettings} from '../../../redux/sagas/SecretSanta/selectors'

export const cnSecretSantaDialog = cn('SecretSantaDialog');

export interface ISecretSantaDialogData {
 
}

export interface ISecretSantaDialogProps extends IBodyProps<ISecretSantaDialogData>, IClassNameProps, ISecretSantaDialogOwnProps,ISecretSantaStateProps, ISecretSantaDispatchProps{}

export interface ISecretSantaDialogOwnProps {
  isPartitipant:boolean;
  id:string;
}

export const mapSecretSantaStateToProps = (state: IStateType, props: ISecretSantaDialogOwnProps) => {
    // const list = state.lists['alone'];
    return {
       joinInfo: getSecretSantaJoinInfo(props.id)(state),
       secretSantaData: getSecretSantaWidgetSettings(props.id)(state),
    };
};
export type ISecretSantaStateProps = ReturnType<typeof mapSecretSantaStateToProps>;
export type ISecretSantaDispatchProps = ReturnType<typeof mapSecretSantaDispatchToProps>

export const mapSecretSantaDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    secretSantaJoinInfoAction,
    secretSantaUnJoinAction,
    getMyInfo,
    isUserParticipateAction
}, dispatch)

