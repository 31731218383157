import React, { FC, memo } from 'react'
import {
    cnClassName,
    News
} from './interfaces'
import './style.scss'

type IPresenterProps = News.ContentLayout.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    children
}) => {
    return <div className={cnClassName({}, [className])}>
        {children}
    </div >
}

export default memo(Presenter);
