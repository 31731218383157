import React, { FC, useState, useMemo } from 'react';
import { ISkillsDialogCreateProps, cnSkillsDialogCreateDialog } from './CreateDialog.intex';
import i18n from 'localizations/i18n';
import { Translate } from 'localizations/Translate';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle';
import { DialogContent } from 'muicomponents/src/DialogParts/DialogContent';
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { Box, TextField } from 'muicomponents/src'
import FormControl from '@mui/material/FormControl';
import * as utils from 'utils/src/utils';
import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import { useResizeHandler } from 'utils/src/hooks'
import SoloAutocomplete from '@mui/material/Autocomplete';
import { IItem } from 'uielements/src/MaterialElements/withSuggester'
import { Tag } from 'muicomponents/src/Icons'
import { SkillsSuggester } from 'muicomponents/src/Suggester/type/SkillsSuggester';
import { SelectableObject } from 'utils/src/BaseTypes/selectableObject.types';

const FormControlWithHelp = fieldWithHelp(FormControl)
const SkillsDialogCreatePresenter: FC<ISkillsDialogCreateProps> = ({
    handleAccept,
    handleClose,
    currentUser,
    onComplete,
    // setTitle,
    // title,


}) => {
    const [elements, setElements] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showActionButton, setShowBtn] = useState(false)
    // const [title, setTitle] = useState("")
    const [title, setTitle] = useState<SelectableObject | null>(null)
    const withError = useMemo(() => {
        return (Boolean(title?.displayName)) && !/^[\s0-9A-Za-zА-Яа-яЁё_]+$/gi.test(title?.displayName || "")
    }, [title])

    // const onChangeText = (
    //     event: React.ChangeEvent<{}>,
    //     value: string = ''
    // ) => {
    //     if (!event) return;
    //     setTitle(value)
    //     setLoading(true)
    //     const suggesterRequest = utils.API.suggesters.endorse(value)

    //     suggesterRequest.r.then((d: any) => {
    //         setLoading(false)
    //         if (utils.checkResponseStatus(d)) {
    //             setElements(d.data.map((item: any): IItem => item.displayName.replace(/_/gm, ' ')));
    //         }
    //     });
    // }

    const ref = useResizeHandler((el) => {
        if (el.clientWidth < 500) {
            setShowBtn(false)
        } else {
            setShowBtn(true)
        }
    })

    return (
        <React.Fragment>
            <DialogTitle onClose={handleClose}>
                <Translate i18nKey='pryaniky.skills.create.dialog.title' />
            </DialogTitle>
            <DialogContent sx={{ padding: "5px 24px 0 24px" }}>
                {/* <form ref={ref as any} className={cnSkillsDialogCreateDialog("CreateSkillForm")} onSubmit={complete}> */}
                <Box ref={ref as any} sx={{ display: "flex", alignItems: "center", width: "100%" }}>

                    <Box sx={{ width: "100%" }} className={cnSkillsDialogCreateDialog('InputBox')}>
                        <FormControlWithHelp fullWidth help={i18n.t("pryaniky.skills.create.input.help.1")} >
                            {/* <SoloAutocomplete
                                freeSolo
                                onInputChange={onChangeText}
                                loading={!withError && loading}
                                filterOptions={(items) => items}
                                value={title}
                                options={withError ? [] : elements}
                                renderInput={(params: any) => <TextField
                                    {...params}
                                    error={withError}
                                    InputProps={{
                                        startAdornment: <Tag />
                                    }}
                                    variant={"outlined"}
                                    helperText={withError && i18n.t("pryaniky.skills.create.input.error.1")}
                                    label={i18n.t("pryaniky.skills.create.input.1")} />}
                            /> */}
                            <SkillsSuggester
                                TextFieldProps={{
                                    label: <Translate i18nKey={"pryaniky.skills.create.input.1"} />,
                                    error: withError,
                                    helperText: withError && i18n.t("pryaniky.skills.create.input.error.1")
                                }}
                                value={title}
                                onChange={(e, value) => {
                                    if (!Array.isArray(value)) {
                                        setTitle(value)
                                    }
                                }}
                                createItems={{
                                    allow: true,

                                }}
                            />
                        </FormControlWithHelp>
                    </Box>
                </Box>
                {/* </form> */}

            </DialogContent>
            <DialogActions
                acceptText={i18n.t('pryaniky.subnetworks.dialog.item.confirm')}
                closeText={i18n.t('pryaniky.cancel.dialog')}
                onAccept={() => handleAccept({ title })}
                onClose={handleClose}
                disableAccept={withError} />
        </React.Fragment>
    );
}


export const DialogCreate = createModal(SkillsDialogCreatePresenter, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});
