import React, { FC } from 'react'
import { IThanksInput2Props } from './ThanksInput2.index'
import './ThanksInput2.scss'
import { Button, Input, Icon, Dialog, DialogTitle, CustomScrollbars, DialogContent, CheckboxInput } from 'uielements/src';

export const ThanksInput2: FC<IThanksInput2Props> = ({ onChange, thankPerUser, disableDown, disableUp }) => {
    return (
        <div className={'ThanksInput2'}>
            <Button
                data-direction="down"
                onClick={(e: any) => onChange(thankPerUser - 1)}
                className={'Button-Down Input-Btn'}
                main
                children={<Icon icon={'chevron-down'} />}
                disabled={disableDown}
            />
            <Input
                placeholder={'0'}
                className={'Currency-Input'}
                onChange={(e: any) => onChange(e.target.value)}
                type="number"
                value={thankPerUser <= 0 ? '' : thankPerUser} />
            <Button
                data-direction="up"
                onClick={(e: any) => onChange(thankPerUser + 1)}
                className={'Button-Up Input-Btn'}
                main
                children={<Icon icon={'chevron-up'} />}
                disabled={disableUp}
            />
        </div>
    )
}