import moment from 'moment';
import { NValidate } from 'utils/src/validate_v2';
import { NCreativeTasks } from './CreativeTasks.interface';

const startCurrentMinute = moment().seconds(0).milliseconds(0).toDate();

export const CreativeTasksValidateRules: NValidate.ValidateRulesObject<Omit<NCreativeTasks.Create, 'expires'> & { expires: Date }> = {
    header: {
        min: {
            value: 3
        }
    },
    text: {
        notNull: {
            value: true
        },
        min: {
            value: 0
        }
    },
    thanksCount: {
        min: {
            value: 1
        }
    },
    expires: {
        min: {
            value: startCurrentMinute
        }
    }
};