// import actions from 'redux/actionsTypes/AppSettings'
import { ILoadingReducer, LOADING_SET_STATUS, ILoadingActions } from './actions'

const defaultLoadingReducer: ILoadingReducer = {
  status: ''
}

export function loadingReducer(state = defaultLoadingReducer, action: ILoadingActions ): ILoadingReducer {
  switch (action.type) {
    case LOADING_SET_STATUS: {
      return {
        ...state,
        status: action.payload
      }
    }
    default: {
      return state
    }
  }
}

