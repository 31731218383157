import React from 'react';
import { BarStackHorizontal } from '@vx/shape';
import { SeriesPoint } from '@vx/shape/lib/types';
import { Group } from '@vx/group';
import { AxisBottom, AxisLeft } from '@vx/axis';
// import cityTemperature, { CityTemperature } from '@vx/mock-data/lib/mocks/cityTemperature';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { timeParse, timeFormat } from 'd3-time-format';
import { withTooltip, Tooltip } from '@vx/tooltip';
import { WithTooltipProvidedProps } from '@vx/tooltip/lib/enhancers/withTooltip';
import { LegendOrdinal } from '@vx/legend';
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

type CityName = 'completedNotSuccess' | 'completedSuccess' | 'notCompleted' | 'notStarted';

type TooltipData = {
    bar: SeriesPoint<any>;
    key: CityName;
    index: number;
    height: number;
    width: number;
    x: number;
    y: number;
    color: string;
};

export type BarStackHorizontalProps = {
    width: number;
    height: number;
    values: any[];
    margin?: { top: number; right: number; bottom: number; left: number };
    events?: boolean;
};

const purple1 = '#6c5efb';
const purple2 = '#c998ff';
const purple3 = '#a44afe';
const purple4 = '#1441fe';
const background = '#eaedff';
const defaultMargin = { top: 40, left: 50, right: 40, bottom: 100 };
const tooltipStyles = {
    // ...defaultStyles,
    minWidth: 60,
    backgroundColor: 'rgba(0,0,0,0.9)',
    color: 'white',
};


const keys = ['completedNotSuccess', 'completedSuccess', 'notCompleted', 'notStarted'] as CityName[];//Object.keys(data[0]).filter(d => d !== 'date') as CityName[];



let tooltipTimeout: number;

export default withTooltip<BarStackHorizontalProps, TooltipData>(
    ({
        values,
        width,
        height,
        events = false,
        margin = defaultMargin,
        tooltipOpen,
        tooltipLeft,
        tooltipTop,
        tooltipData,
        hideTooltip,
        showTooltip,
    }: BarStackHorizontalProps & WithTooltipProvidedProps<TooltipData>) => {
        // bounds
        const xMax = width - margin.left - margin.right;
        const yMax = height - margin.top - margin.bottom;

        const data = values;

        const temperatureTotals = data.reduce((allTotals, currentDate) => {
            const totalTemperature = keys.reduce((dailyTotal, k) => {
                dailyTotal += Number(currentDate[k]);
                return dailyTotal;
            }, 0);
            allTotals.push(totalTemperature);
            return allTotals;
        }, [] as number[]);
        
        const formatDate = (date: string) => date;

        // accessors
        const getDate = (d: any) => d.unitName;

        // scales
        const temperatureScale = scaleLinear<number>({
            domain: [0, Math.max(...temperatureTotals)],
            nice: true,
        });
        const dateScale = scaleBand<string>({
            domain: data.map(getDate),
            padding: 0.2,
        });
        const colorScale = scaleOrdinal<string, string>({
            domain: keys,//.map(k => tT(`statistics.test_results.type.${k}`)),
            range: [purple1, purple2, purple3, purple4],
        });

        temperatureScale.rangeRound([0, xMax]);
        dateScale.rangeRound([yMax, 0]);

        return width < 10 ? null : (
            <div>
                <svg width={width} height={height}>
                    <rect width={width} height={height} fill={background} rx={14} />
                    <Group top={margin.top} left={margin.left}>
                        <BarStackHorizontal<any, CityName>
                            data={data}
                            keys={keys}
                            height={yMax}
                            y={getDate}
                            xScale={temperatureScale}
                            yScale={dateScale}
                            color={colorScale}
                        >
                            {barStacks =>
                                barStacks.map(barStack =>
                                    barStack.bars.map(bar => (
                                        <rect
                                            key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                                            x={bar.x}
                                            y={bar.y}
                                            width={bar.width}
                                            height={bar.height}
                                            fill={bar.color}
                                            onClick={() => {
                                                if (events) alert(`clicked: ${JSON.stringify(bar)}`);
                                            }}
                                            onMouseLeave={() => {
                                                tooltipTimeout = window.setTimeout(() => {
                                                    hideTooltip();
                                                }, 300);
                                            }}
                                            onMouseMove={() => {
                                                if (tooltipTimeout) clearTimeout(tooltipTimeout);
                                                const top = bar.y + margin.top;
                                                const left = bar.x + bar.width + margin.left;
                                                showTooltip({
                                                    tooltipData: bar,
                                                    tooltipTop: top,
                                                    tooltipLeft: left,
                                                });
                                            }}
                                        />
                                    )),
                                )
                            }
                        </BarStackHorizontal>
                        <AxisLeft
                            hideAxisLine
                            hideTicks
                            scale={dateScale}
                            tickFormat={formatDate}
                            stroke={purple3}
                            tickStroke={purple3}
                            tickLabelProps={() => ({
                                fill: '#ffffff',
                                fontSize: 11,
                                textAnchor: 'start',
                                dy: '0.33em',
                                dx: '12px'
                            })}
                        />
                        <AxisBottom
                            top={yMax}
                            scale={temperatureScale}
                            stroke={purple3}
                            tickStroke={purple3}
                            tickLabelProps={() => ({
                                fill: purple3,
                                fontSize: 11,
                                textAnchor: 'middle',
                            })}
                        />
                    </Group>
                </svg>
                <div
                    style={{
                        position: 'absolute',
                        top: margin.top / 2 - 10,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '14px',
                    }}
                >
                    <LegendOrdinal labelFormat={(d) => tT(`statistics.test_results.type.${d}`)} scale={colorScale} direction="row" labelMargin="0 15px 0 0" />
                </div>
                {tooltipOpen && tooltipData && (
                    <Tooltip top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
                        <div style={{ color: colorScale(tooltipData.key) }}>
                            <strong>{tT(`statistics.test_results.type.${tooltipData.key}`)}</strong>
                        </div>
                        <div>{tT(`statistics.test_results.userscount`)}: {tooltipData.bar.data[tooltipData.key]}</div>
                        <div>
                            <small>{getDate(tooltipData.bar.data)}</small>
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    },
);