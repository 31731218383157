import React from 'react';
import { cnMountainRating } from '../MountainRating.index';

interface IScaleProps {
  maxValue: number;
  rowHeight: number;
  width: number;
}

export const Scale: React.FC<IScaleProps> = ({ rowHeight, maxValue, width }) => {
  const interval = Math.round(maxValue / 5);
  const points: number[] = Array.from({ length: 6 }, (_, i) => interval * i).reverse();
  return (
    <div className={cnMountainRating('Grid')} style={{ paddingTop: 2 * rowHeight, width }}>
      {points.map(point => (
        <div
          key={point}
          className={cnMountainRating('GridRow')}
          style={{
            height: rowHeight,
          }}>
          <span className={cnMountainRating('GridPoint')}>{point}</span>
        </div>
      ))}
    </div>
  );
};
