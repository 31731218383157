import React, { FC, useEffect, useState, useMemo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMSTO
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { Body } from '../../../../../components/Body/Body'
import { createAttemptOptions, cretateAnswersMap, useSetAttepmt } from 'LMSModule/utils/result.utils'
import { AnswerList } from '../../../../../LMSTeacherOffice/AnswerList/AnswerList'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { isEmpty } from 'lodash'
import ScormResultRender from '../../../../../LMS/ScormResult/component'

type IPresenterProps = LMSTO.StudentResultsScorm.IPresenterProps
type IOwnProps = LMSTO.StudentResultsScorm.IOwnProps
type IDispatchProps = LMSTO.StudentResultsScorm.IDispatchProps
type IStateProps = LMSTO.StudentResultsScorm.IStateProps
type IState = LMSTO.StudentResultsScorm.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    unit,
    sid,
    cid,
    loadAllScormParams,
    loadUnitsLogs,
    unitContent,
    sessionLog,
    unitLog
}) => {
    useEffect(() => {
        isEmpty(sessionLog) && loadUnitsLogs(sid);
        (unit?.unitId && sessionLog?.log?.id) && loadAllScormParams(cid, unit.unitId, sid, sessionLog.log.id)

    }, [sessionLog?.log?.id, unit?.unitId])

    return <div className={cnClassName({}, [className])}>
        {unitLog?.scormCMI ? <ScormResultRender
            unitContent={unitContent}
            scormCMI={unitLog.scormCMI}
        /> : ''}
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
