import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from 'redux/connector';
import { updateContext } from 'redux/actions/Widgets';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface IVirtcurrencyProps extends IClassNameProps, IActionsDispatch, IVirtcurrencyStateProps {
  tag?: 'div';
  context: { [s: string]: any };
}

export type IVirtcurrencyStateProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: any) => ({
  authUser: getAuthUser(state),
  mythanksCount: getAuthUser(state).extData.mythanksCount,
  thanksForAll: getAuthUser(state).extData.thanksForAll,
  additionalColor1: state.store.appDesign?.colors.additionalColor1,
})

export interface IActionsDispatch {
  updateContext?: any;
}

export const mapActionsToProps: IActionsDispatch = {
  updateContext
};

export const cnVirtcurrency = cn('Virtcurrency');
export const cnVirtcurrencyNew = cn('VirtcurrencyNew');