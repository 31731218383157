import React, { FC, memo, useState } from 'react'
import { IActionsProps, cnClassName } from './Actions.index'
import { Button, Input, Icon, Dialog, DialogTitle, CustomScrollbars, DialogContent, CheckboxInput, HelpTooltip } from 'uielements/src';
import i18n from 'localizations/i18n'
import './Actions.scss'
import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { useCurrentUserBase } from 'utils/src/CommonRedux/users/hooks';
import ruLocale from "date-fns/locale/ru";
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

export const Actions: FC<IActionsProps & any> = memo(({
    onMouseEnter,
    onMouseLeave,
    actionBtns,
    mentionsButtons,
    openFileWindow,
    complie,
    publishAt,
    onChangePublishAt,
    onChangeGroup,
    group,
    activePostId
}) => {
    const currentUserBase = useCurrentUserBase()
    const currentDate = new Date
    const [isOpenGroups, setOpenGroups] = useState(false)
    // const [dateState, setDateState] = useState(new Date)
    const onChangeDateTime = (date: any) => {
        date as Date
        // setDateState(date)
        onChangePublishAt && onChangePublishAt(date.toISOString())
    }
    const onChangeGroupCallback = (groups: any[]) => {
        onChangeGroup(groups ? groups[groups.length - 1] : null)
        setOpenGroups(false)
    }
    return (
        <div className={'EditActions'}>
            <div className={'EditActions-EditActions'} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                {openFileWindow && <Button theme={'unstyled'}
                    className={'EditActions-EditButton'}
                    onClick={openFileWindow}>
                    {<Tooltip title={i18n.t('pryaniky.createPost.actions.addFile')} ><span><Icon icon='clip' /></span></Tooltip>}
                </Button>}
                {actionBtns}
                {mentionsButtons &&
                    <>

                        <Button theme={'unstyled'}
                            className={'EditActions-EditButton'}
                            onClick={mentionsButtons(' @')}>
                            {<Tooltip title={i18n.t('pryaniky.createPost.actions.mention')} ><span><Icon icon='at-sign' /></span></Tooltip>}
                        </Button>


                        <Button theme={'unstyled'}
                            className={'EditActions-EditButton'}
                            onClick={mentionsButtons(' #')}>
                            {<Tooltip title={i18n.t('pryaniky.createPost.actions.tag')} >
                                <span><Icon icon='hashtag' /></span>
                            </Tooltip>}
                        </Button>

                    </>}
                {onChangePublishAt &&
                    <PopupMenu
                        noFixed={true}
                        allowInsideClick
                        allowOutsideClick
                        className={'EditActions-EditButton'}
                        horizontal={'auto'}
                        vertical={'auto'}
                        btnContent={
                            <Tooltip title={i18n.t('pryaniky.createPost.actions.publishAt')} >
                                <span><Icon icon='clock' /></span>
                            </Tooltip>
                        }
                        renderChild={(isOpen, close) =>
                            <div>
                                <Button onClick={close}>
                                    <Icon icon={'times'} />
                                </Button>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                                    <DateTimePicker
                                        variant="static"
                                        // variant="inline"
                                        minDate={currentDate}
                                        label="Basic example"
                                        value={publishAt || currentDate}
                                        ampm={false}
                                        onChange={onChangeDateTime}
                                    />
                                </MuiPickersUtilsProvider>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={Boolean(publishAt)}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => { onChangePublishAt && onChangePublishAt(checked ? currentDate : null) }}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={i18n.t('pryaniky.createPost.actions.publishAt.switch')}
                                />
                            </div>
                        }
                    />

                }

                {onChangeGroup && <Button theme={'unstyled'}
                    className={cnClassName('EditButton', { isOpenGroups })}
                    onClick={() => setOpenGroups(!isOpenGroups)}>
                    <Tooltip title={i18n.t('pryaniky.createPost.actions.selectGroup')} >
                        <span><Icon icon={group ? 'users-medical' : 'users'} className={group ? 'EditActions-Active' : undefined} /></span>
                    </Tooltip>
                </Button>}
                {/* <PopupMenu
                        noFixed={true}
                        allowInsideClick
                        // allowOutsideClick
                        className={'EditActions-EditButton'}
                        horizontal={'auto'}
                        btnContent={
                            <Icon icon={group ? 'users-medical' : 'users'} className={group ? 'EditActions-Active' : undefined} />
                        }
                        renderChild={(isOpen, close) =>
                            <div className={'EditActions-SelectGroup'}>
                                <Autocomplete
                                    // multiple={false}
                                    additionalRequestProps={{
                                        userId: currentUserBase.id,
                                        typeNews: activePostId
                                    }}
                                    value={group ? [group] : []}
                                    onChange={onChangeGroupCallback}
                                    type={'getGroupsWhereUserIsMemberGroup'}
                                />
                            </div>
                        }
                    /> */}


                {isOpenGroups && <div className={'EditActions-SelectGroup'}>
                    <Autocomplete
                        // multiple={false}
                        label={i18n.t('pryaniky.createPost.actions.selectgroup.label')}
                        variant={'outlined'}
                        additionalRequestProps={{
                            userId: currentUserBase.id,
                            typeNews: activePostId
                        }}
                        value={group ? [group] : []}
                        onChange={onChangeGroupCallback}
                        type={'getGroupsWhereUserIsMemberGroup'}
                    />
                </div>}

            </div>
            <div className={'EditActions-SendActions'}>
                {complie}
            </div>

        </div >
    )
})