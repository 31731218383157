import React, { FC } from 'react'
import './CourseHeader.scss'
import { cn } from '@bem-react/classname';
import { Admin } from '../Admin/Admin';
import { Avatar } from 'uielements/src'
import { Progress } from '../Progress/Progress'
import { Button, Icon } from 'uielements/src'
import { AuthorsList } from '../AuthorsList/AuthorsList';
import { LikeAction } from '../../LMS/LikeAction/LikeAction'
import { Tabs } from '../../LMSTeacherOffice/Tabs/Tabs'
import { ButtonBox } from 'uielements/src/ButtonBox/ButtonBox'
import { tC } from 'LMSModule/utils/i18n.adaptor'

const cnCourseHeader = cn('CourseHeader');

export const CourseHeader: FC<any> = ({ course, className, tabs, defaultVal, enableTabs = false, allowEdit = false }) => {
    return <div className={cnCourseHeader({}, [className])}>
        <div className={cnCourseHeader('TopActions')}>
            <div className={cnCourseHeader('RightActions')}>
                <Button
                    className={cnCourseHeader('BackLink')}
                    theme={'unstyled_center'}
                    type='rlink'
                    href={'/LMS'}>
                    <Icon icon={'arrow-left'} />{tC('returnToList.1')}
                    </Button>
            </div>
            <div className={cnCourseHeader('LeftActions')}>
                {allowEdit && <ButtonBox orientation={'horizontal'} className={cnCourseHeader('Actions')}>
                    <Button
                        type='rlink'
                        href={'/TeacherOffice/Course/' + course.id}
                        title={tC('edit.1')} >
                        <Icon icon={'edit'} />
                    </Button>
                </ButtonBox>}
            </div>
        </div>

        <div className={cnCourseHeader('Section')}>
            <div className={cnCourseHeader('ImageBox')}>
                <Avatar size={178} /*imgId={course.}*/ imgUrl={course.largeImgUrl} />
                <LikeAction {...{ likesCount: course.likesCount, isLiked: course.isLiked }}
                    cid={course.id}
                    nId={course.newsUid}
                    className={cnCourseHeader('Like')} />
            </div>
            <div className={cnCourseHeader('DescBox')}>
                <div className={cnCourseHeader('Title')}>
                    <h3 className={cnCourseHeader('Name')}>{course.name}</h3>
                </div>
                <div className={cnCourseHeader('Description')}>
                    {course.description}
                </div>
                <div className={cnCourseHeader('Progress')}>
                    {!course.full ? <div></div> : <Progress currentStep={course.courseCompletedUnits} totalSteps={course.unitsCount} />}
                </div>
                <div className={cnCourseHeader('Authors')}>
                    <AuthorsList limit={11} more authors={course.authors} className={cnCourseHeader('AuthorsList')} />
                </div>
            </div>
        </div>

        {enableTabs && <div className={cnCourseHeader('Nav')}>
            <div className={cnCourseHeader('Noop')}></div>
            <Tabs tabs={tabs} defaultVal={defaultVal} />
            <div className={cnCourseHeader('Noop')}></div>
        </div>}
    </div>
}