import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { setStartedQuiz } from 'redux/actions/Quiz'

export interface IQuizStart extends IQuizStartStateProps, IQuizStartDispatchProps {
  quizId: string
} 

export const mapStateToProps = (state: IState) => {
  return {
    text: state.quiz.quiz.openQuizData.text,
    rewardIsPosted: state.quiz.quiz.rewardIsPosted
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  setStartedQuiz
}, dispatch);

export type IQuizStartStateProps = ReturnType<typeof mapStateToProps>
export type IQuizStartDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnQuizStart = cn('QuizStart')