import React, { FC, useEffect, memo } from 'react'
import { xorBy } from 'lodash'
import { FieldItem } from './FieldItem/FieldItem'
import { IFieldSelectorProps, cnClassName } from './FieldSelector.index'
import './FieldSelector.scss'
export const FieldSelector: FC<IFieldSelectorProps> = ({ fields, selected, onChange }) => {
    const onSelect = (field: any) => {
        onChange(xorBy(selected, [field], 'id'))
    }
    return <div className={cnClassName({}, [])}>
        {fields.map((field: any) => <FieldItem
            onSelect={onSelect}
            field={field}
            selected={selected?.find((v: any) => v.id === field.id)} />)}
    </div>
}