import { IClassNameProps } from '@bem-react/core';
import { cnImgLoader } from '../ImgLoader.index';

export interface IPreviewProps extends IClassNameProps {
  tag?: 'div';
  imgUrl?: string;
}

export interface IPreviewState {
  text?: string;
}

export const cnPreview = (...data: any) => {
  let name = 'Preview';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnImgLoader(name, ...data)
}