import { IOrgchartData, IUnitsData, IPositionElement } from 'utils/src/requests/models/admin.orgchart';
import { ITreeElement } from'./interfaces';
import { ISelectableObject } from "utils/src/requests/models/api.base";

import {
    IOrgchartDataActions,
    ISetStuctureOrgchartDataToReduxPayload,
    IChangePositionOrgchartDataPayload,
    IAddPositionToUnitPayload,
    IDeletePositionFromUnitPayload,
    IAddLeaderPositionToUnitPayload,
    IorgchartToggleDraggablePayload,
    IorgchartDragPositionPayload,
    IAddSubUnitToUnitPayload,
    IEditSubUnitToUnit,
    IEditUnitToUnitPayload,
    IOrgchartSaveAfterUnitDragPayload,
    IOrgchartSaveUsersInUnitAfterDragPayload,
    ISetOrgchartDraggingUnit,
    ISetDraggingUnitToPosition,
    ISetUnitByTagIdPayload,
    IGetUnitByTagIdPayload,
    ISaveStructureDataOrgchartPayload
} from './actions.interfaces'

export const ORGCHART_GET_ORGCHART_DATA = "ORGCHART_GET_ORGCHART_DATA"
export const SET_ORGCHART_LOADING = "SET_ORGCHART_LOADING"
export const SET_STRUCTURE_ORGCHART_TO_REDUX = "SET_STRUCTURE_ORGCHART_TO_REDUX"
export const CHANGE_STRUCTURE_ORGCHART_DATA = "CHANGE_STRUCTURE_ORGCHART_DATA"
export const SAVE_STRUCTURE_ORGCHART_DATA = "SAVE_STRUCTURE_ORGCHART_DATA"
export const DELETE_STRUCTURE_ORGCHART_DATA = "DELETE_STRUCTURE_ORGCHART_DATA"
export const CREATE_NEW_POSITION = "CREATE_NEW_POSITION"
export const ADD_NEW_POSITION_TO_UNIT = "ADD_NEW_POSITION_TO_UNIT"
export const ADD_NEW_LEADER_POSITION_TO_UNIT = "ADD_NEW_LEADER_POSITION_TO_UNIT"
export const SAVE_EDIT_POSITION_TO_UNIT = "SAVE_EDIT_POSITION_TO_UNIT"
export const SAVE_EDIT_LEADER_POSITION_TO_UNIT = "SAVE_EDIT_LEADER_POSITION_TO_UNIT"
export const CHANGE_POSITION_ORGCHART_DATA = "CHANGE_POSITION_ORGCHART_DATA"
export const DELETE_POSITION_FROMUNIT_DATA ="DELETE_POSITION_FROMUNIT_DATA"
export const ORGCHART_ADD_SUB_UNIT = "ORGCHART_ADD_SUB_UNIT"
export const ORGCHART_EDIT_UNIT = "ORGCHART_EDIT_UNIT"
export const NEW_POSITION_DATA_KILL_AFTER_SAVE = "NEW_POSITION_DATA_KILL_AFTER_SAVE"
export const NEW_UNIT_DATA_KILL_AFTER_SAVE = "NEW_UNIT_DATA_KILL_AFTER_SAVE"

export const ORGCHART_TOGGLE_DRAGGABLE = "ORGCHART_TOGGLE_DRAGGABLE"
export const ORGCHART_DRAG_POSITION = "ORGCHART_DRAG_POSITION"

export const ORGCHART_SET_DRAGGING_UNIT = 'ORGCHART_SET_DRAGGING_UNIT';
export const ORGCHART_SET_DRAGGING_UNIT_TO_POSITION = 'ORGCHART_SET_DRAGGING_UNIT_POSITION';

// СОХРАНЯЕТ  изменения в древе после перетаскивания юнитов 
export const ORGCHART_SAVE_UNIT_POSITION_AFTER_DRAG = "ORGCHART_SAVE_UNIT_POSITION_AFTER_DRAG"

// СОХРАНЯЕТ  изменения в древе после перетаскивания юзеров 
export const ORGCHART_SAVE_USERS_IN_UNIT_AFTER_DRAG = "ORGCHART_SAVE_USERS_IN_UNIT_AFTER_DRAG"


// для поиска и отрисовки структуры от другого юнита 

export const ORGCHART_CHANGE_SELECT = "ORGCHART_CHANGE_SELECT"

// для включения фильтра showOnlyOpenVacancy
export const ORGCHART_SHOW_ONLY_OPEN_VACANCY = "ORGCHART_SHOW_ONLY_OPEN_VACANCY"

// для добавления юнита выбранного через саджестер тегов запрос через сагу
export const  GET_UNIT_BY_TAG_ID= "GET_UNIT_BY_TAG_ID"
// запись в редакс
export const SET_UNIT_BY_TAG_ID = "SET_UNIT_BY_TAG_ID"



/**
* get OrgchartData from server
*/
export function getOrgchartDataFromServer(payload:string): IOrgchartDataActions {
    return {
        type: ORGCHART_GET_ORGCHART_DATA,
        payload
    }
}

/**
* delete StructureOrgchartData 
* удаляет юнит по id
* @param { string } payload
*/
export function deleteStructureDataOrgchart(payload: string): IOrgchartDataActions {
    return {
        type: DELETE_STRUCTURE_ORGCHART_DATA,
        payload
    }
}

// getJournalDataFromServer
/*****************************************/
/*************** to reducer **************/
/*****************************************/

/**
* set StructureOrgchartData to redux
* @param { IChatData } payload 
*/
export function setStuctureOrgchartDataToRedux(payload: ISetStuctureOrgchartDataToReduxPayload): IOrgchartDataActions {
    return {
        type: SET_STRUCTURE_ORGCHART_TO_REDUX,
        payload
    }
}

export function setOrgchartLoading(payload:boolean): IOrgchartDataActions{
    return{
        type: SET_ORGCHART_LOADING,
        payload
    }
}
/**
* change StructureOrgchartData to redux
* @param { IUnitsData } payload
*/
export function changeStructureDataOrgchart(payload: IUnitsData): IOrgchartDataActions {
    const res: any = {
        type: CHANGE_STRUCTURE_ORGCHART_DATA,
        payload
    }

    return res;
}
/**
* change position Orgchart Data to redux
* @param { IChangePositionOrgchartDataPayload } payload
*/
export function changePositionDataOrgchart(payload: IChangePositionOrgchartDataPayload): IOrgchartDataActions {
    return {
        type: CHANGE_POSITION_ORGCHART_DATA,
        payload
    }
}
/**
* delete user from position, and then position from unit Orgchart Data to redux
* @param { IDeletePositionFromUnitPayload } payload
*/
export function deletePositionFromUnit(payload: IDeletePositionFromUnitPayload): IOrgchartDataActions {
    return {
        type: DELETE_POSITION_FROMUNIT_DATA,
        payload
    }
}


/**
 * Add NEW LEADER POSITION 
 * @param { IAddLeaderPositionToUnitPayload} payload
 */
export const addNewLeaderPositions = (payload: IAddLeaderPositionToUnitPayload): IOrgchartDataActions => ({
    type: ADD_NEW_LEADER_POSITION_TO_UNIT,
    payload
})

/**
 * Add NEW POSITION
 * @param { IAddPositionToUnitPayload} payload
 */
export const addNewPositions = (payload: IAddPositionToUnitPayload): IOrgchartDataActions => ({
    type: ADD_NEW_POSITION_TO_UNIT,
    payload
})
/**
 * Add Sub Unit
 * @param { IAddSubUnitToUnitPayload} payload
 */
export const addSubUnit = (payload?:IAddSubUnitToUnitPayload): IOrgchartDataActions => ({
    type: ORGCHART_ADD_SUB_UNIT,
    payload
})
/**
 * Edit Sub Unit
 * @param { IEditUnitToUnitPayload} payload
 */
export const editSubUnit = (payload:IEditUnitToUnitPayload): IOrgchartDataActions => ({
    type: ORGCHART_EDIT_UNIT,
    payload
})



/**
 * saving POSITION edit
 * @param { IAddPositionToUnitPayload} payload
 */
export const saveEditNewPositions = (payload: IAddPositionToUnitPayload): IOrgchartDataActions => ({
    type: SAVE_EDIT_POSITION_TO_UNIT,
    payload
})
/**
 * saving leader POSITION edit
 * @param { IAddLeaderPositionToUnitPayload} payload
 */
export const saveLeaderEditNewPositions = (payload: IAddLeaderPositionToUnitPayload): IOrgchartDataActions => ({
    type: SAVE_EDIT_LEADER_POSITION_TO_UNIT,
    payload
})

/**
* save StructureOrgchartData
*/
export function saveStructureDataOrgchart(payload: ISaveStructureDataOrgchartPayload): IOrgchartDataActions {
    return {
        type: SAVE_STRUCTURE_ORGCHART_DATA,
        payload
    }
}
// export function saveStructureDataOrgchart(payload: string): IOrgchartDataActions {
//     return {
//         type: SAVE_STRUCTURE_ORGCHART_DATA,
//         payload
//     }
// }

/**
* для очищения массива новосозданных и отредактированых позиций после сохранения юнита
*/
export function newPositionDataKill(): IOrgchartDataActions {
    return {
        type: NEW_POSITION_DATA_KILL_AFTER_SAVE,

    }
}
/**
* для очищения initUnitData после сохранения юнита
*/
export function newUnitDataKill(): IOrgchartDataActions {
    return {
        type: NEW_UNIT_DATA_KILL_AFTER_SAVE,

    }
}

export function orgchartToggleDraggable(payload: IorgchartToggleDraggablePayload): IOrgchartDataActions {
    return {
        type: ORGCHART_TOGGLE_DRAGGABLE,
        payload
    }
}

export function orgchartDragPosition(payload: IorgchartDragPositionPayload): IOrgchartDataActions {
    return {
        type: ORGCHART_DRAG_POSITION,
        payload
    }
}

/**
 * сохраняет структуру после перетаскивания юнита
 * @param {IUnitsData} payload
 */
export function orgchartSaveAfterUnitPositionDrag(payload: IOrgchartSaveAfterUnitDragPayload): IOrgchartDataActions{
    return {
        type: ORGCHART_SAVE_UNIT_POSITION_AFTER_DRAG,
        payload
    }
}

/**
 * сохраняет unit после перетаскивания позиции 
 * @param { IOrgchartSaveUsersInUnitAfterDragPayload} payload
 */

export function orgchartSaveUsersInUnitAfterDrag(payload: IOrgchartSaveUsersInUnitAfterDragPayload): IOrgchartDataActions{
    return{
        type: ORGCHART_SAVE_USERS_IN_UNIT_AFTER_DRAG,
        payload
    }
}

export function changeSelect(payload: ISelectableObject): IOrgchartDataActions{
    return{
        type: ORGCHART_CHANGE_SELECT,
        payload
    }
}

export function setOrgchartDraggingUnit(payload: ISetOrgchartDraggingUnit['payload']): IOrgchartDataActions{
    return {
        type: ORGCHART_SET_DRAGGING_UNIT,
        payload
    }
}

export function setOrgchartDraggingUnitToPosition(payload: ISetDraggingUnitToPosition['payload']): IOrgchartDataActions {
    return {
        type: ORGCHART_SET_DRAGGING_UNIT_TO_POSITION,
        payload
    }
}

// включает чекбокс для отображения только открытых вакансий
export function showOnlyOpenVacancyAction(payload: boolean): IOrgchartDataActions {
    return {
        type: ORGCHART_SHOW_ONLY_OPEN_VACANCY,
        payload
    }
}


// для добавления юнита выбранного через саджестер тегов запрос через сагу
export function getUnitByTagId(payload: IGetUnitByTagIdPayload): IOrgchartDataActions {
    return {
        type: GET_UNIT_BY_TAG_ID,
        payload
    }
}
// записывает полученый юнит в редакс
export function setUnitByTagId(payload: ISetUnitByTagIdPayload): IOrgchartDataActions {
    return {
        type: SET_UNIT_BY_TAG_ID,
        payload
    }
}