import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from 'redux/connector';

export interface IInfoBaseProps {
  type: string;
  edit: string;
  id: string;
}

export interface IInfoProps extends IInfoBaseProps, IClassNameProps, IDispatchProps {
  data: any;
  setData: (data: any) => void;
  children: (data:any) => JSX.Element;
  editEnd: () => void;
  togleEdit: () => void;
}

export interface IUserState {
  text?: string;
}

// export interface IUserStateProps {
//   store: any
// }

// export const mapStateToProps = (state: any) => ({
//   store: state.store
// })
export const cnItem = (...data: any) => {
  let name = 'Item';
  if (typeof (data[0]) === 'string') {
    name += '-' + data[0];
    data = data.slice(1);
  }
  return cnInfoBlock(name, ...data)
}
export const cnInfoBlock = cn('InfoBlock');