/**
 * 
 * declarate store selectors
 */
type stateType = any//затычка

/**
 * достаёт достаёт колекцию групп
 * @param state 
 */
export const getGroups = (state: stateType) =>
    state.groups.groups;

/**
 * достаёт коллекцию настроек групп
 * @param state 
 */
export const getSettings = (state: stateType) =>
    state.groups.settings

/**
 * получает настройки для группы с указаным PKID
 * @param PKID 
 */
export const getSettingsByPKID = (PKID: string | number) =>
    (state: stateType) =>
        getSettings(state)[PKID]

/**
 * достаёт группу по pkid
 * @param PKID 
 */
export const getGroupByPKID = (PKID: string | number) =>
    (state: stateType) =>
        getGroups(state)[PKID]

/**
 * является ли текущий юзер админом указаной группы
 * @param PKID 
 */
export const getIsGroupAdminByPKID = (PKID: string | number) =>
    (state: stateType) => {
        const group = getGroupByPKID(PKID)(state)
        return group ? group.isGroupAdmin : false
    }