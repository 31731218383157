import React, { FC, useEffect, useRef, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IScormUnitPresenterProps,
    IScormUnitOwnProps,
    IDispatchProps,
    IScormUnitStateProps,
    IScormUnitState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import * as utils from 'utils/src/utils';
import ScormAPI2004 from 'LMSModule/utils/scormAPI/scormAPI2004/scormAPI2004'
import ScormAPI12 from 'LMSModule/utils/scormAPI/scormAPI12/scormAPI12'


export const Presenter: FC<IScormUnitPresenterProps> = ({
    className,
    name,
    unitContent,
    scormUnitComplete,
    scormUnitSuccessStatus,
    saveParam,
    unitLog,
    scormCMI,
    fullscreen,
    currentUser
}) => {
    const ref = useRef<HTMLIFrameElement>(null)

    useEffect(() => {
        let api: ScormAPI12 | ScormAPI2004 = unitContent.scormFileVersion === "scorm12" ? new ScormAPI12() : new ScormAPI2004()
        const apiKEY = unitContent.scormFileVersion === "scorm12" ? 'API' : 'API_1484_11';
        if (unitContent.scormFileVersion === "scorm2004") {
            api = api as ScormAPI2004;
            api.setCMIValue('cmi.learner_id', currentUser.baseData.id);
            api.setCMIValue('cmi.learner_name', currentUser.baseData.displayName);
            api.Initialize()
            Object.keys(scormCMI).forEach(key => { (api as ScormAPI2004).SetValue(key, unitLog.scormCMI[key]) })
            api.onListener('SetValue', (CMIElement, value) => {
                saveParam(CMIElement, value);
                CMIElement === 'cmi.success_status' && scormUnitSuccessStatus(unitContent.courseId, unitContent.id);
                CMIElement === 'cmi.completion_status' && scormUnitComplete(unitContent.courseId, unitContent.id);
            })
            api.currentState = 0;
        } else if (unitContent.scormFileVersion === "scorm12") {
            api = api as ScormAPI12;
            api.LMSInitialize()
            Object.keys(scormCMI).forEach(key => { (api as ScormAPI12).LMSSetValue(key, unitLog.scormCMI[key]) })
            api.onListener('LMSSetValue', (CMIElement, value) => {
                if (CMIElement === 'cmi.core.lesson_status' && (value === 'passed' || value === 'completed')) {
                    saveParam('cmi.completion_status', 'completed');
                }
                saveParam(CMIElement, value);
                CMIElement === 'cmi.core.lesson_status' && scormUnitSuccessStatus(unitContent.courseId, unitContent.id);

                // CMIElement === 'cmi.core.lesson_status' && scormUnitComplete(unitContent.courseId, unitContent.id);
            })
            api.currentState = 0;
        }
        (window as any)[apiKEY] = api

    }, [unitContent.id, fullscreen])


    const onLoadFrame = (event: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
        event.currentTarget.style.height = event.currentTarget.contentWindow?.document.documentElement.scrollHeight + 'px'
    }

    const { scormUnitWidth, scormUnitHeight } = unitContent

    return <div className={cnClassName({ fullscreen }, [className])}>
        <iframe
            key={`${unitContent.id}_${fullscreen ? 'f' : 'n'}`}
            // onLoad={onLoadFrame}
            ref={ref}
            src={`${utils.API.base.requestDomain}/ru/Data/v3/LMS/Course/Unit/${unitContent.id}/Content/${unitContent.scormFileId}/${unitContent.scormIndexUrl || ''}`}
            width={fullscreen ? '100%' : `${scormUnitWidth === -1 ? 100 : scormUnitWidth}%`}

            // height={'auto'}
            height={fullscreen ? '100%' : `${(scormUnitHeight)}px`}
            className={cnClassName('Frame')}></iframe>
    </div>
}

export default connect<IScormUnitStateProps, IDispatchProps, IScormUnitOwnProps, IScormUnitState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
