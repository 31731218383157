
interface IStringObj { [s: string]: string };

type IMESSAGES_TYPES = typeof M_TYPES & IStringObj;

const M_TYPES = {
  init: 'init',
  get: 'get',
  set: 'set',
  getInit: 'get_init',
  setInit: 'set_init',
  register: 'register',
  initBaseStore: 'init_base_store',
  getBaseStore: 'get_base_store',
  setBaseStore: 'set_base_store',
  setHeight: 'set_height'
}

export const MESSAGES_TYPES: IMESSAGES_TYPES = M_TYPES;