import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from 'redux/connector';
import { IWidgetProps } from '../Widget/Widget.index';
import { IListItem } from '../ListRound/ListRound.index';
import { IContext } from 'store/vm';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface IUsersWidgetProps extends IClassNameProps, IDispatchProps, IWidgetProps {
  tag?: React.ComponentType | 'div';
  context: { [s: string]: any };
  settings?: Record<string, any>;
  data?: any[];
  uid:string
}

export interface IUsersWidgetState {
  text?: string;
  items: IListItem[];
  noData?: boolean;
  title: string;
  numCount?: any;
  toggle?: boolean;
  dialog?: string;
}

export interface IUsersWidgetStateProps {
  // store: any;
  // context: IContext ;
}

export const mapStateToProps = (state: any) => ({
  // store: state.store,
  // context: state.store.context,
  
  uid: getAuthUser(state).baseData.id,
})

export const cnUsersWidget = cn('UsersWidget');
export const cnUsersWidgetHierarchy= cn('UsersWidgetHierarchy');
export const cnUsersWidgetCollegues= cn('UsersWidgetCollegues');