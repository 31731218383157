import React, { FC } from 'react'
import { 
    LMSTO,
    mapDispatchToProps,
    mapStateToProps,
    cnClassName 
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import withCourseSuggester from 'blocks/HOCs/SearchSelectize/withCourseSuggester'
import { SearchSelectize } from 'uielements/src';
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

const CourseSelectize = withCourseSuggester(SearchSelectize)


type IPresenterProps = LMSTO.CommentsLent.CoursesFilter.IPresenterProps
type IStateProps = LMSTO.CommentsLent.CoursesFilter.IStateProps
type IDispatchProps = LMSTO.CommentsLent.CoursesFilter.IDispatchProps
type IOwnProps = LMSTO.CommentsLent.CoursesFilter.IOwnProps
type IState = LMSTO.CommentsLent.CoursesFilter.IState

const d: any[] = []
export const Presenter: FC<IPresenterProps> = ({ className, currentData = [], setData }) => {
    const onClick = (items: any[]) => {
        setData(items.map((val: any) => val.id))
    }
    return <div className={cnClassName({}, [className])}>
        <CourseSelectize
            defaultElements={d}
            onClick={onClick}
            name={tT('comments.filters.courses.1')}
        />
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
        mapStateToProps,
        mapDispatchToProps
    )(Presenter);
