import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IBaseNews } from '../../types/baseNews'
///////////

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsUrlSelector {

        export type IPresenterProps = IOwnProps

        export interface IOwnProps extends IClassNameProps {
            onSelected?: (news?: IBaseNews | null) => void
        }
    }
}
export const cnClassName = cn('NewsUrlSelector');