import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from 'redux/connector';

export interface INewsEditProps extends IClassNameProps, INewsEditStateProps, IDispatchProps {
  tag?: 'div';
  post: any;
  disableEdit: () => void;
  editeComplite: (post: any) => void;
}

export interface INewsEditState {
  text?: string;
  post: any;
}

export interface INewsEditStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})

export const cnNewsEdit = cn('NewsEdit');