import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnImageLoader, IImageLoaderProps, IImageLoaderState } from './ImageLoader.index';
import { ImageLoaderPreview } from "./Preview/ImageLoader-Preview";

import './ImageLoader.scss';
import { Button, Icon } from 'uielements/src';
import { Dropzone } from 'uielements/src/Dropzone/Dropzone';
import { toast } from 'react-toastify';
import { ImageCropper } from 'blocks/Dialogs/ImageCropper/ImageCropper';
import { DialogCropper } from 'muicomponents/src/CropperDialog';

const isIE = /*@cc_on!@*/false || !!(document as any).documentMode;
export class ImageLoader extends React.Component<IImageLoaderProps, IImageLoaderState> {
  public dropzone: any;

  constructor(props: IImageLoaderProps) {
    super(props);
    this.state = {
      loadingPropgress: 0,
      loadingStatus: 'unstart',
      cropper: false
    };
  }

  public render() {
    const { state, props } = this;
    const { tag: TagName = 'div', className, cropper: useCropper, title, description, children, squarePreview, cropperProps, startPreview, dialog } = props;
    const { loadingStatus, fileUrl, cropper, file } = state;
    return (
      <TagName className={cnImageLoader({}, [className])}>
        {
          (useCropper && !isIE) &&
          <ImageCropper
            data={{ 
              file,
              ...cropperProps
             }}
            isShown={cropper}
            onConfirm={this.onCroped}
            onClose={this.cropperHide}
          />
        }
        <Button 
          theme={'unstyled'} 
          className={cnImageLoader('Block', { preview: true })} 
          onClick={!dialog ? 
            this.openDropzone : 
            () => DialogCropper({})
              .then((value) => {
                this.setState({ fileUrl: value.previewUrl })
                this.props.onLoaded(value);
              })
              .catch(() => {})
          }>
          <ImageLoaderPreview square={squarePreview} previewUrl={fileUrl || startPreview} />
          {
            loadingStatus !== 'unstart' &&
            <div style={this.getRoundProgress()} className={cnImageLoader('Progress', { circle: !squarePreview })}>
              {
                loadingStatus === 'finish' &&
                <Icon 
                  className={cnImageLoader('Progress-Icon', { success: true })} 
                  icon={'fa-check-circle'}
                  />
              }
              {
                loadingStatus === 'error' &&
                <Icon
                  className={cnImageLoader('Progress-Icon', { error: true })}
                  icon={'times-circle'}
                />
              }
            </div>
          }
        </Button>
        <div className={cnImageLoader('Block')}>
          {
            title && <div className={cnImageLoader('Title')} children={title} />
          }
          {
            description && <div className={cnImageLoader('Description')} children={description} />
          }
          {
            children
          }
        </div>
        <Dropzone
          reference={this.addDZtoref}
          onDrop={this.fileDrop}
          hoverElem={this}
        />
      </TagName>
    )
  }

  private getRoundProgress = (): React.CSSProperties => ({ borderWidth: `${50 - (this.state.loadingPropgress / 2)}px` });

  private cropperShow = () => this.setState({ cropper: true });

  private cropperHide = () => this.setState({ cropper: false });

  private addDZtoref = (el: any) => this.dropzone = el;

  private openDropzone = () => this.dropzone.open();

  private onCroped = (file: File, additionalData: any) => {
    this.setState({ fileUrl: URL.createObjectURL(file) }, this.cropperHide);
    this.fileLoad(file, additionalData);
  }

  private fileLoadStart = (event: any, file: any) => {
    if(this.props.onLoadStart) this.props.onLoadStart(file);
    this.setState({ loadingStatus: 'progress' });
  }

  private flieLoadError = (event: any, file: any) => {
    this.setState({ loadingStatus: 'error' });
    setTimeout(() => {
      this.setState({ 
        loadingStatus: 'unstart', 
        loadingPropgress: 0 
      });
    }, 2000);
  }

  private fileLoadProgress = (event: any, file: any) => {
    this.setState({ loadingPropgress: Math.ceil((event.loaded * 100) / event.total)});
  }

  private fileLoadFinish = (response: any, file: any) => {
    if (utils.checkResponseStatus(response)) {
      this.setState({ loadingStatus: 'finish' });
      setTimeout(() => {
        this.setState({
          loadingStatus: 'unstart',
          loadingPropgress: 0
        });
      }, 2000);
      this.props.onLoaded(response.data[0]);
    } else {
      toast.error('file loading fail');
    }
  }

  private fileDrop = (files: File[]) => {
    const [ file ] = files;
    if (!file) return;
    if (this.props.cropper && !isIE) {
      this.setState({ file }, this.cropperShow);
    } else {
      this.setState({ fileUrl: URL.createObjectURL(file) });
      this.fileLoad(file);
    }
  }

  private fileLoad = (file: File, additionalData: any = {}) => {
    utils.API.files.upload(file, {
      loadStart: (event: any, file: any) => {
        file.width = additionalData.width;
        file.height = additionalData.height;
        file.aspect = additionalData.aspect;
        this.fileLoadStart(event, file)
      },
      loadError: this.flieLoadError,
      loadProgress: this.fileLoadProgress,
      loadFinish: this.fileLoadFinish
    });
  }

}