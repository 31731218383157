import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IState {}

export interface IOwnProps extends IClassNameProps { 
    title?: string;
    icon: string;
    value: string | number;
}

export const cnCurrencyTooltipItem = cn('CurrencyTooltipItem');