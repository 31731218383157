import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { NewsContextProps } from 'News/contexts/news'
import {
    getNewsParamsById
} from 'News/redux/saga/selectors'
import {LongreadNews} from './Longread.type'
export interface ILongreadPropsType extends NewsContextProps {}
export type TStateProps = ReturnType<typeof mapStateToProps>

const newsParams = [
    'id',
    'fromCache',
    'text',
    'additionalFields'
] as const

export const mapStateToProps = (state: any, { newsId }: ILongreadPropsType) => ({
    userData: getCurrentUser(state),
    ...getNewsParamsById<LongreadNews, typeof newsParams[number]>(newsId, newsParams)(state)
})