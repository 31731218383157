import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';

export namespace ISwipeMenu {

  export interface Props extends IClassNameProps {
    active: number;
    count: number;
  }
}

export const cnSwipeMenu = cn('SwipeMenu');