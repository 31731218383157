import React, { useRef, useState, useEffect, useMemo, createRef, useCallback, ComponentProps } from "react";
import { SelectableObject } from 'utils/src/BaseTypes/selectableObject.types';
import { SFileModel } from 'utils/src/BaseTypes/files.types';
import { useDraftState } from 'muicomponents/src/DraftComponents/utils/useDraftState';
import { ItemType, useDebounce, useFileUpload, useFileUploader, fileReaderPromise } from 'utils/src/hooks';
import { convertToRaw } from 'draft-js';
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { rawToMd } from 'uielements/src/PryanikyEditorV1/converter/convertorConfigs';
import { TemplateCard } from "./Templates.rb";
import { v1 as uuid } from 'uuid';
import { sendCard } from "utils/src/requests/requests.cards";
import { checkResponseStatus } from "utils/src";
import { toast } from "react-toastify";
import { Translate } from "localizations/Translate";
import { useSelector } from 'react-redux';
import { getAppSettings, moduleIsEnabled } from "utils/src/CommonRedux/base/selectors";
import { TEditor } from "muicomponents/src/GraphicEditor";
import BaseRequests from "utils/src/requests/requests.base";
import { getBusinessCardsList } from "utils/src/requests/requests.businesscards";

export const useCard = () => {
    const cardsIsEnabled = useSelector(moduleIsEnabled('postcards'))

    const useEditorV2 = useMemo(() => {
        return (window as any).useGraphicEditor || cardsIsEnabled;
    }, [cardsIsEnabled]);

    const frameId = useMemo(() => `CardEditor-${uuid()}`, []);

    const newUsers = useRef<SelectableObject['id'][]>([]);

    const [ sending, setSending ] = useState(false);

    const [ file, setFile ] = useState<ItemType | null>(null);

    const {
        siteURL
    } = useSelector(getAppSettings);

    const fileUpdate: Parameters<typeof useFileUpload>['0'] = (oldItem, newItem) => {
        setFile(newItem);
    };

    const changeFile = (file: File) => {
        fileReaderPromise(file)
        .then(d => {
            setFile(d || null);
        })
        .catch();
    };

    const {
        startUpload,
        isLoading: isFileLoading
    } = useFileUpload(fileUpdate, {});

    const editorRef = createRef<TEditor>();

    useEffect(() => {
        const domain = window.location.origin;
        const noTemplatesItem = 'no templates';
        const dafaultUrl = '/Content/cards/templates.json';
        const companyUrl = `/Content/cards/${siteURL}/templates.json`;
        const params = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'   
            }
        };
        async function getTemplates () {

            let rezult: any[] = [];

            const error404 = Error('404');

            if((window as any).useGraphicEditor || useEditorV2) {
                const response = await getBusinessCardsList({
                    skipCount: 0,
                    count: 40,
                    listName: 'cards'
                });
                if(checkResponseStatus(response)) {
                    rezult = response.data.map(el => el.template);
                }
            } else {
                rezult = await fetch(`${companyUrl}`, params)
                .then(d => {
                    try {
                        if(d.status === 404) {
                            throw error404;
                        }
                        return d.text();
                    } catch (error) {
                        throw error;
                    }
                })
                .then((text) => {
                    const reg = new RegExp(`/Content/${siteURL}/cards`, 'g');
                    const json = JSON.parse(text.replace(reg , `${domain}/Content/${siteURL}/cards`)) || [];
                    return json;
                })
                .catch((e) => {
                    if(e.message === '404') {
                        console.log(`no templates fo company ${siteURL}`);
                        return [noTemplatesItem];
                    }
                    console.log(`get templates error ${e}`);  
                    return [];
                });
    
                if(rezult[0] === noTemplatesItem) {
                    rezult = await fetch(`${dafaultUrl}`, params)
                    .then(d => {
                        try {
                            return d.text();
                        } catch (error) {
                            throw error;
                        }
                    })
                    .then((text) => {
                        const json = JSON.parse(text.replace(/\/Content\/cards/g , `${domain}/Content/cards`)) || [];
                        return json;
                    })
                    .catch((e) => {
                        console.log(`get templates error ${e}`);  
                        return [];
                    });
                }
            }

            return rezult;
        };
        editorRef.current?.extendTemplates(getTemplates);
    }, []);

    const fileProgress = useMemo(() => {
        return Math.round((file?.progress?.progress || 0) / (file?.progress?.total || 1) * 100);
    }, [file?.progress?.progress]);

    const [ users, setUsers ] = useState<SelectableObject[]>([]);

    const [ text, setText ] = useState('');

    const [ draftState, setDraftState ] = useDraftState(text);

    const chageText = useDebounce((st: typeof draftState) => {
        const text = draftToMarkdown(convertToRaw(st.getCurrentContent()), rawToMd);
        setText(text);
    }, 500, []); 
  
    useEffect(() => {
        chageText && chageText(draftState);
    }, [draftState]);

    const send = async function () {
        let prepairedFile = file;
        setSending(true);
        if(!prepairedFile) {
            console.log('editorRef.current', editorRef.current);
            const fileObject = await editorRef.current?.getImage();
            if(fileObject) prepairedFile = await fileReaderPromise(fileObject);
            // const fileObject = await postPromise(frameId, 'getCurrentImage', undefined, 'giveCurrentImage');
        }
        if(!prepairedFile) {
            setSending(false);
            toast.error(<Translate i18nKey='pryaniky.card.send.emptyfile' />);
            return;
        }
        const externalMails = users.filter(el => newUsers.current.includes(el.id)).map(el => el.displayName);
        const internalUsers = users.filter(el => !newUsers.current.includes(el.id));
        let fileResponse = (prepairedFile.response?.data || [])[0];
        if(!fileResponse) {
            const uploaded = (await startUpload([prepairedFile]))?.[0];
            fileResponse = (uploaded?.response?.data || [])[0];
        }
        if(fileResponse) {
            const sendResponse = await sendCard({
                users: internalUsers,
                emails: externalMails,
                text: text,
                file: fileResponse as any,
            });
            if(checkResponseStatus(sendResponse)) {
                toast.success(<Translate i18nKey='pryaniky.card.send.success' />);
            } else {
                toast.error(<Translate i18nKey='pryaniky.card.send.error' />);
            }
        } else {

        }
        setSending(false);
    };

    return {
        users,
        setUsers,
        newUsers,
        draftState,
        setDraftState,
        file,
        changeFile,
        fileProgress,
        isFileLoading,
        send,
        sending,
        frameId,
        editorRef
    };
};