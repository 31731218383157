/**
 * @packageDocumentation
 * @module Widget_Settings_sliderlink
 */
import { JFdictionary } from "../dictionary";

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    slider: {
      type: types.object,
      require: true
    },
  }
}
