/**
 * @packageDocumentation
 * @module Widget_Settings_users_group
 */
import { JFdictionary } from "../dictionary";
import i18n from "localizations/i18n";

const relationsArray = [
  'group',
  'users'
]

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    name: {
      type: types.string,
      placeholder: i18n.t('pryaniky.widgets.settings.placeholder.name'),
      
    },
    countView: {
      type: types.number,
      placeholder: i18n.t('pryaniky.widgets.settings.placeholder.usersCountView'),
    },
    group: {
      type: types.object,
      placeholder: i18n.t('pryaniky.widgets.settings.placeholder.selectGroup'),
      props: {
        additionalRequestOpts: {
          excludeSystemGroups: 'true'
        }
      },
      require: true
    },
    users: {
      type: types.array,
      placeholder: i18n.t('pryaniky.widgets.settings.usersGroup.placeholder.selectUsersPositions'),
      props: {
        noMulti: false
      },
      dataRelations: [{
        from: 'group.pkid',
        to: 'groupid'
      }]
    },
    hideIfNoData: {
      type: types.boolean
    },
  }
}
