import { createContext } from 'react';

export interface IReplyesContext {
    textareaMentions: HTMLInputElement | null;

    setTextarea:(element: HTMLInputElement) => void;

    appendFoo: (t: string) => void;

    setRewardComment: (id: string, count: number) => void;

    setFund: (f: number) => void;

    // fund: number;
    
    rewardedComments: Map<string, number>;
}

export const defaultReplyesContext = {
    textareaMentions: null,
    appendFoo: (t: string) => { /* */ },
    setTextarea: (element: HTMLInputElement) => { /* */ },
    setRewardComment: (id: string, count: number) => { /* */ },
    setFund: (f: number) => { /* */ },
    rewardedComments: new Map<string, number>(),
    // fund: 0
}

export const ReplyesContext = createContext<IReplyesContext>(defaultReplyesContext);
