import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { pageHeader } from './pageHeaders';

export const workflows = (): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          // GW
          generateWidget({
            type: widgets.types.filters + "/workflowsBar",
          }),
          generateWidget({
            type: widgets.types.layout,
            data: [
              generateColumn({
                items: [
                  generateWidget({
                    type: widgets.types.timeline,
                  }),
                ],
                styles: {
                  width: '72%',
                },
              }),
            ],
          }),
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  })
}