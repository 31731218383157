import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Selectize } from 'uielements/src';
import { mapStateToProps, ICategoriesStateProps, cnCategories, ICategoriesProps, mapDispatchToProps } from './Categories.index';
import { linkCreator, zeroId } from 'LMSModule/utils/utils.lms'
import './Categories.scss';
import { tS } from 'LMSModule/utils/i18n.adaptor'

export const CategoriesPresenter: React.FC<ICategoriesProps & any> = ({
    tag: Tag = 'div', loadCategories, categories, cIds, activeId = [zeroId], className }) => {
    useEffect(() => void loadCategories(), []);
    const elements = cIds.map((id: string) => {
        return {
            value: categories[id].id,
            title: id === zeroId ? tS('cat.all.1') : categories[id].name,
            id: categories[id].id
        }
    })

    return (
        <Tag className={cnCategories({}, [className])}>
            <Selectize
                // key={i} 
                onClick={(value) => {  }}
                active={activeId}
                rLink={linkCreator('cat', zeroId)}
                name={tS('categories.1')}
                elements={elements}
                multiple={true}
                children={[]} />
        </Tag>
    );
};

export const Categories = connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoriesPresenter);
