import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { sendApproveModeration } from '../../redux/actions/LMSTeacherOffice'

export interface IAnswerProps extends IClassNameProps, IAnswerStateProps, IDispatchProps, RouteComponentProps, IAnswerDispatchProps {
    tag?: 'div';
    answer: any;
    hideModeration?: boolean;
}

export interface IAnswerState {

}

export interface IAnswerStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    answer: state.LMSTO.moderation.answers[props.aid],
    user: state.LMSTO.users[state.LMSTO.moderation.answers[props.aid].userId],
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    sendApproveModeration
}, dispatch);

export type IAnswerDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnAnswer = cn('Answer');