import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { getOpenQuiz } from 'redux/actions/Quiz'

export interface ISurveyWidgetItem extends ISurveyWidgetStateProps, ISurveyWidgetDispatchProps {
  settings?: {
    hideIfNoData?: boolean,
    showDesc?: boolean,
  }
}

export const mapStateToProps = (state: IState) => {
  return {
    // data: state.quiz.quizWidget.quizzesData[state.quiz.quizWidget.activeQuizIdx],
    data: state.survey.surveyWidget.surveyData[state.survey.surveyWidget.activeSurveyIdx],
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getOpenQuiz
}, dispatch);

export type ISurveyWidgetStateProps = ReturnType<typeof mapStateToProps>
export type ISurveyWidgetDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnSurveyWidgetItem = cn('SurveyWidgetItem')