import React from 'react'
import queryString from 'query-string';
import { Us, tU, tC, Tc  } from './i18n.adaptor'
export const zeroId = "00000000-0000-0000-0000-000000000000";

export const createLinkObject = (
    isActive: boolean,
    el: any,
    acti: any,
    multiple: boolean,
    paramVar: string,
    search: string,
    zeroVal: string
) => {
    if (el.id === zeroVal) return {
        ...queryString.parse(search),
        [paramVar]: undefined
    }
    if (!multiple) return {
        ...queryString.parse(search),
        [paramVar]: (isActive ? undefined : [el.id])
    }

    let oLink: any = isActive ? { [paramVar]: acti.filter((val: any) => val !== el.id) }
        : { [paramVar]: [...acti, el.id] }

    oLink = {
        ...queryString.parse(search),
        [paramVar]: oLink[paramVar].filter((val: any) => val !== zeroId)
    }

    return oLink
}
export const linkCreator = (paramKey: string, zeroVal: string) =>
    (isActive: boolean, el: any, acti: any, multiple: boolean) =>
        (location: any) =>
            ({
                ...location, search: '?' + queryString.stringify(
                    createLinkObject(isActive, el, acti, multiple, paramKey, location.search, zeroVal)
                )
            })

export const isRightAnswer = (keys: string[], answers: any, selectedAnswers: string[]) =>
    selectedAnswers.length > 0 ? keys.every((aid: string) =>
        (answers[aid] && answers[aid].isRightAnswer) ? selectedAnswers.includes(aid) : !selectedAnswers.includes(aid)) : false


export const isWaitModeration = (answers: any[], resultAnswersMap: any) =>
    answers.some((aid: string) =>
        resultAnswersMap[aid] ? resultAnswersMap[aid].moderationStatus === 'WaitModeration' : false);

export const getAnswersText = (answers: any[], resultAnswersMap: any, answersMap: any) =>
    answers.filter((aid: string) =>
        resultAnswersMap[aid] ? true : false).map((aid: string) =>
            (resultAnswersMap[aid].userAnswerText !== "" ? resultAnswersMap[aid].userAnswerText : answersMap[aid].text))

export const generateHelpText = (wait: boolean, qtype: number, isNeedModeration: boolean) => {
    let helpText = undefined;
    helpText = wait && tU('testResultText.wait.1')
    helpText = (qtype === 2 && !isNeedModeration) ? tU('testResultText.info.1') : helpText
    return helpText
}

/**
 * получить юнит по его id из массива юнитов. null если ненайден.
 * @param units 
 * @param id 
 */
export const getUnitById = (units: any[], id: string) =>
    units.find((unit: any) => unit.id === id)


/**
 * проверяет массив логов юнитов, закончены ли все обязательные юниты.
 * @param unitsLogs 
 */
export const isCompleteMondatory = (logs: any, units: any[]) =>
    units.every((current: any) => {
        if (current.unitType === 'test' && current.skipUnitType === 1) {
            return true
        } else if (logs[current.id] && logs[current.id].isCompleted) {
            return true
        } else {
            return false
        }
    })

