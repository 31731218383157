import React, { FC, useEffect, useCallback } from 'react'
import { connect, useSelector } from 'react-redux';
import { createAttemptOptions, cretateAnswersMap, useSetAttepmt, getNotModerateTextAnswersCount } from '../result.utils'
import {
    getUnitResultsById,
    getIsAbsolutelyCompleted,
    getNotCompletedUnits,
    getAdditionalAttempts
} from '../../redux/sagas/LMS/selectors'
import { useAnswersRender } from './useAnswersRender'

export const getAttemptVersion = (results: any, attempt: number) => {
    if (results.length === 0) return false
    const result = results[attempt]
    if (result.attempt.length > 0) {
        return result.attempt[0].timeStamp
    }
    return false
}

export const useExamResults = (sid: string, unitId: string, userId: string, cid: string) => {
    const isAbsolutelyCompleted = useSelector(getIsAbsolutelyCompleted(sid))
    const additionalAttempts = useSelector(getAdditionalAttempts(sid))
    const notCompletedUnits = useSelector(getNotCompletedUnits(cid))
    const unitResult = useSelector(getUnitResultsById(sid, unitId));

    const options = createAttemptOptions(unitResult?.results?.length || 0)

    const [attempt, cangeAttempt] = useSetAttepmt(options, [sid, unitId])

    const version = getAttemptVersion(unitResult.results || [], attempt.value)

    const detailsRender = useAnswersRender(userId, sid, cid);

    return {
        unitResult,
        options,
        attempts: { attempt, cangeAttempt },
        version,
        isAbsolutelyCompleted,
        additionalAttempts,
        notCompletedUnits,
        detailsRender
    }
}