import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IStateType as IState } from 'redux/store';
import { bindActionCreators } from 'redux'
import { getQuery } from 'redux/search/actions'

export interface ISuggestersProps extends IClassNameProps, ISuggestersDispatchProps{
  tag?: 'div';
  className?: string;
  store?: any;
  changeVm?: any;
  history: any;
  location: any
  match: any;
  staticContext: any;
}

export interface ISuggestersState  {
  text?: string;
  open: boolean;
  data: any;
  users: any[];
  groups: any[];
  tags: any[];
  news: any[];
  pages: any[];
  activeTypes: string[];
  query: string;
  isRequest: number;
}

export const mapStateToProps = (state: IState) => {}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getQuery,
}, dispatch);

export type ISuggestersStateProps = ReturnType<typeof mapStateToProps>
export type ISuggestersDispatchProps = ReturnType<typeof mapDispatchToProps>


export const cnSuggesters = cn('Suggesters');