import Component, { Presenter } from './Item';
import { Shop } from './Item.interfaces';


export { Presenter }

export type IComponentProps = Shop.CharacteristicsItem.IOwnProps;
export type IComponentPresenterProps = Shop.CharacteristicsItem.IPresenterProps;


export default Component