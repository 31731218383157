import { IUsersWidgetProps } from '../UsersWidget.index';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { IDispatchProps } from 'redux/connector';

export interface IUsersWidgetTypeHierarchyProps extends IUsersWidgetProps, IUsersWidgetStateProps, IDispatchProps {
  tag?: React.ComponentType | 'div';
}

export interface IUsersWidgetTypeHierarchyState {
  text?: string;
}

export interface IUsersWidgetStateProps {
  store: any,
  userId: string
}

export const mapStateToProps = (state: any) => ({
  store: state.store,
  userId: getAuthUser(state).profileData.userId
})