import React, { FC } from 'react'
import './Preloader.scss'
import { cnPreloader } from './Preloader.index'
import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg'
import { tS } from 'LMSModule/utils/i18n.adaptor'
import LinearProgress from '@material-ui/core/LinearProgress';

function hoc<T = any>(
    Component: React.ComponentType<T>,
    propsKeyList: string[] = [],
    classNames: string[] = [],
    placeholder?: React.ReactNode
): FC<any> {
    return (props: any) => {
        let em = false;
        propsKeyList.forEach(key => {
            if (!props[key]) em = true;
        })
        if (em) return (placeholder ? <>{placeholder}</> : <div className={cnPreloader({}, classNames)}>
            {/* {tS('loading.2')} */}
            <LinearProgress className={cnPreloader('Progress')} />
            {/* <Component {...props} /> */}
        </div>)
        return <Component {...props} />
    }
}

function errorHoc<T = any>(
    Component: React.ComponentType<T>,
    classNames: string[] = [],
    propsKey: string
): FC<any> {
    return (props: any) => {

        if (props[propsKey].error_code && props[propsKey].error_code !== 0) return <div className={cnPreloader({}, classNames)}>
            <ErrorMsg>{props[propsKey].error_text}</ErrorMsg>
        </div>
        return <Component {...props} />
    }
}

export const withError = errorHoc

export const withPreloader = hoc

