import { put, takeLeading, takeEvery } from 'redux-saga/effects';
import {
    call,
    select,
} from 'utils/src/saga.effects';
import i18n from '../../../localizations/i18n';
import { checkResponseStatus, getErrorText } from 'utils/src/utils'

import {
    ORGCHART_GET_ORGCHART_DATA,
    SAVE_STRUCTURE_ORGCHART_DATA,
    DELETE_STRUCTURE_ORGCHART_DATA,
    setStuctureOrgchartDataToRedux,
    ORGCHART_SAVE_UNIT_POSITION_AFTER_DRAG,
    ORGCHART_SAVE_USERS_IN_UNIT_AFTER_DRAG,
    orgchartDragPosition,
    newPositionDataKill,
    newUnitDataKill,
    ORGCHART_CHANGE_SELECT,
    ORGCHART_SET_DRAGGING_UNIT_TO_POSITION,
    GET_UNIT_BY_TAG_ID,
    setUnitByTagId,
    setOrgchartLoading
} from './actions'

import {
    ISaveStructureDataOrgchart,
    IGetOrgchartDataFromServerAction,
    IDeleteStructureDataOrgchart,
    IOrgchartSaveUsersInUnitAfterDrag,
    IOrgchartSaveAfterUnitPositionDrag,
    IGetUnitByTagIdAction
} from "./actions.interfaces"

import {
    IOrgchartDataResponse,
    IOrgchartData,
    IUnitsData,
    IPositionElement,
    IOrgchartUnitResponse,
    IOrgchartPositionResponse,
    IOrgchartPositionInUnitEditResponse,
    IUnitByTagDataResponse
} from 'utils/src/requests/models/admin.orgchart'

import {
    ITreeElement,
    IOrgchartDataSettings
} from './interfaces'

import {
    getOrgchart,
    saveOrgchartUnit,
    deleteOrgchartUnit,
    savePositioninUnitAfterDrugg,
    saveOrgchartUnitWithPositionChanges,
    getUnitFromHiddenList
} from 'utils/src/requests/admin.orgchart'

import {
    // getOrgchartChangedUnitSettings as getOrgchartChangedUnitSettingsSelector,
    getOrgchartChangedUnit,
    getOrgchartPosition,
    getOrgchartNewPositionsState,
    getSelect,
    getOrgchartState,
    getOrgchartNewUnit
} from './selectors'

import { sub, format } from 'date-fns';
import { toast } from 'react-toastify';
import { GUID_EMPTY } from 'utils/src/constants.prn'
import { IBasicResponse, } from 'utils/src/requests/models/api.base';
import { forLibPrefix } from './constants'
import { ISelectableObject } from "utils/src/requests/models/api.base";

const fillLacksPositions = (nullIndexes: number[], lacksPositions: number[]) => (el: ITreeElement, idx: number) => {
    const lacksIndex = nullIndexes.indexOf(idx);
    if (el.position === 0 && lacksIndex !== -1) {
        return {
            ...el,
            position: lacksPositions[lacksIndex]
        }
    }
    return el
}

const sortByPosition = (a: ITreeElement, b: ITreeElement) => {
    if (a.position < b.position) {
        return -1;
    }
    if (a.position > b.position) {
        return 1;
    }
    return 0;
}

function* getOrgchartDataFromServer(action: IGetOrgchartDataFromServerAction) {
    try {
        // const listData = yield* select(getOrgchartDataSelector);

        const selectExist = yield* select(getSelect);
        const response: IOrgchartDataResponse = yield* call(getOrgchart, selectExist?.id)
        if (checkResponseStatus(response)) {
            if (response.data.units.length===0) {
                yield put(setOrgchartLoading(false))
            } else {

                let headUnit = GUID_EMPTY;
                const positions = response.data.positions.reduce((acc, position) => {
                    return { ...acc, [position.id]: position };
                }, {} as { [key: string]: IPositionElement });

                const units = response.data.units.reduce((acc, unit) => {
                    if (selectExist !== null) headUnit = selectExist.id;
                    else if (unit.parent === null) headUnit = unit.id;
                    return { ...acc, [unit.id]: unit };
                }, {} as { [key: string]: IUnitsData });

                const structureItems = response.data.connections.reduce((acc, connection) => {
                    return { ...acc, [connection.parentId]: [...(acc[connection.parentId] || []), connection.childId] };
                }, {} as { [key: string]: string[] });

                const CreateStructure = (ids: string[]): ITreeElement[] => {
                    const arr = ids.map((id, idx) => ({ id: forLibPrefix + id, position: units[headUnit].position, ...(structureItems[id] ? { children: CreateStructure(structureItems[id]) } : {}) }));
                    const nullIndexes = arr.map(e => e.position).indexOfAll(0).filter((el, idx) => idx !== 0);
                    const lacksPositions = new Array(arr.length).fill(0).map((_, i) => i).filter(e => !arr.map(i => i.position).includes(e));
                    return arr
                        .map(fillLacksPositions(nullIndexes, lacksPositions))
                        .sort(sortByPosition)
                };

                const structure: ITreeElement = { id: forLibPrefix + headUnit, position: units[headUnit].position, ...(structureItems[headUnit] ? { children: CreateStructure(structureItems[headUnit]) } : {}) };
                

                yield put(setStuctureOrgchartDataToRedux({
                    structure,
                    positions,
                    units,
                    isFinished: true,
                    isLoading: false
                }))
            }

        } else {
            toast.error(i18n.t('pryaniky.list.journal.error.text'));
        }

    } catch (e) {
        console.warn('get Orgchart Data error', e);
    }
}

function* saveOrgchartSaga(action: ISaveStructureDataOrgchart) {
    const { thisIsMerge } = action.payload
    try {
        const unit = yield* select(getOrgchartNewUnit)
        if(!unit) return ;
        let positions: IPositionElement[] = yield* select(getOrgchartNewPositionsState)

        const response: IOrgchartPositionInUnitEditResponse = yield* call(saveOrgchartUnitWithPositionChanges, { unit, positions }, thisIsMerge)
        if (checkResponseStatus(response)) {
            toast.success(i18n.t('pryaniky.administration.toast.success'))
            yield put(newPositionDataKill());
            yield put(newUnitDataKill());
        } else {
            toast.error(getErrorText(response))
        }

    } catch (e) {
        console.warn('save orgchart Saga error', e);
    }
}

// сохранение после перетаскивания позиции(юзера) между юнитами 
function* savePositioninUnitAfterDrug(action: IOrgchartSaveUsersInUnitAfterDrag) {
    try {
        const positionData = yield* select(getOrgchartPosition, action.payload.positionId)

        const response: IOrgchartPositionResponse = yield* call(savePositioninUnitAfterDrugg, positionData, action.payload.toRootPosition)
        if (checkResponseStatus(response)) {
            toast.success(i18n.t('pryaniky.administration.toast.success'))
        } else {
            toast.error(getErrorText(response))
        }
    } catch (e) {
        console.warn('save Position in Unit After Drug Saga error', e);
    }
}
// Сохраняет после перетаскивания юнита с места на место
function* saveAfterUnitDrag(action: IOrgchartSaveAfterUnitPositionDrag) {
    try {
        const unit: IUnitsData = yield* select(getOrgchartChangedUnit, action.payload.id)
        const positions: IPositionElement[] = yield* select(getOrgchartNewPositionsState)

        const response: IBasicResponse = yield* call(saveOrgchartUnitWithPositionChanges, { unit, positions })
        if (checkResponseStatus(response)) {
            toast.success(i18n.t('pryaniky.administration.toast.success'))
        } else {
            toast.error(getErrorText(response))
        }

    } catch (e) {
        console.warn('save unit  After Drug Saga error', e);
    }
}


// удаляет юнит по id
function* deleteOrgchartSaga(action: IDeleteStructureDataOrgchart) {
    try {
        const response: IBasicResponse = yield* call(deleteOrgchartUnit, action.payload)
        if (checkResponseStatus(response)) {
            toast.success(i18n.t('pryaniky.administration.toast.success'))
        } else {
            toast.error(getErrorText(response))
        }
    } catch (e) {
        console.warn('delete Orgchart Saga', e);
    }
}


// получает Подразделение по id тега
function* getUnitByTagIdFromHiddenList(action: IGetUnitByTagIdAction) {
    try {
        const { tagId, unitForEdit } = action.payload
        const response: IUnitByTagDataResponse = yield* call(getUnitFromHiddenList, tagId)
        if (checkResponseStatus(response)) {
            toast.success(i18n.t('pryaniky.administration.toast.success'))
            const positions = response.data.positions;
            const unit = response.data.unit;
            if (unitForEdit) {
                yield put(setUnitByTagId({
                    unit: {
                        ...unitForEdit,
                        displayName: unit.displayName,
                        id: unitForEdit.id,
                        isNeedModeration: unitForEdit.isNeedModeration,
                        isDeleted: unitForEdit.isDeleted,
                        parent: unitForEdit.parent,
                        positionIds: [...unitForEdit.positionIds, ...(unit.positionIds || [])],
                        rootPositionIds: [...unitForEdit.rootPositionIds, ...(unit.rootPositionIds || [])]
                    },
                    positions
                }));
            } else {
                yield put(setUnitByTagId({ positions, unit }));
            }


        } else {
            toast.error(getErrorText(response))
        }
    } catch (e) {
        console.warn('delete Orgchart Saga', e);
    }
}


const Orgchart = function* Orgchart() {
    yield takeLeading([ORGCHART_GET_ORGCHART_DATA, ORGCHART_CHANGE_SELECT], getOrgchartDataFromServer)
    yield takeLeading(SAVE_STRUCTURE_ORGCHART_DATA, saveOrgchartSaga)
    yield takeLeading(DELETE_STRUCTURE_ORGCHART_DATA, deleteOrgchartSaga)
    yield takeLeading(ORGCHART_SAVE_USERS_IN_UNIT_AFTER_DRAG, savePositioninUnitAfterDrug)
    yield takeLeading(ORGCHART_SAVE_UNIT_POSITION_AFTER_DRAG, saveAfterUnitDrag)
    yield takeLeading(GET_UNIT_BY_TAG_ID, getUnitByTagIdFromHiddenList)
}

export default Orgchart;