import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators } from 'redux';
import { IStateType as IState } from 'redux/store';
import { WithTranslation } from 'react-i18next';
import { getNotifiSettingsSelector } from 'redux/notifiSettings/selectors';
import { setNotifiSettings, saveNotifiSettings, getNotifiTemplates, clearNotifiSettings } from 'redux/notifiSettings/actions';
import { getUser } from 'utils/src/CommonRedux/users/actions';
import { RouteComponentProps } from 'react-router-dom';

export interface INotifiSettingsProps extends IMapStateToProps, IMapDispatchToProps, WithTranslation, RouteComponentProps<IParams>, INotifiSettingsOwnProps {}

interface INotifiSettingsOwnProps extends IClassNameProps {
  tag?: React.ComponentType<any>;
}

export const mapStateToProps = (state: IState, props: RouteComponentProps<IParams>) => {
  const { gid, nid = '', uid } = props.match.params;
  
  return {
    settings: getNotifiSettingsSelector(state),
    usersIds: state.users.usersIds,
    networkName: nid && state.notifiSettings.networks[nid]?.title,
    groupName: gid && state.notifiSettings.networks[nid]?.groups[gid]?.name,
    isManyNetworks: Object.keys(state.notifiSettings.networks).length > 1,
    authUid: state.store.authUid,
    user: state.users.users[uid]
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  setNotifiSettings,
  saveNotifiSettings,
  getNotifiTemplates,
  clearNotifiSettings,
  getUser
}, dispatch);

export interface IParams {
  tab: 'common' | 'networks' | 'group' | 'network';
  uid: string;
  gid?: string;
  nid?: string;
}

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type IMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export const cnNotifiSettings = cn('NotifiSettings');
