import { IBadgesPropsType, IBadgesStateType, mapStateToProps, TStateProps } from './Badges.index';
import React, { FC, memo } from 'react';
import { connect } from 'react-redux';
import './Badges.scss'
import { withNewsContext } from 'News/contexts/news'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import ContentLayout from 'News/components/ContentLayout'
import TextRender from 'News/containers/TextRender'


export const BadgesPresenter: FC<IBadgesPropsType & TStateProps> = ({ text, isSingle }) => <NewsView className={'type-0'}>
    <ContentLayout>
        <TextRender />
    </ContentLayout>
</NewsView>

export const Badges = withNewsContext<IBadgesPropsType>(connect(
    mapStateToProps
)(memo(BadgesPresenter)));


export const initBadges = () => {
    ComponentInjector.getInstance().addNode('news_types', <Badges />, 'badges');
}