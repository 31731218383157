import * as React from 'react';
import { IEditorTypeEditProps } from './edit.index';
import { CreativeTaskNews } from '../../../CreativetasksType'
import { Editor } from '../../Editor'
import { CreativeTaskNewsValidator, creativeTaskEditValidate } from '../../../CreativetasksType.validate'
import { baseValidate } from '../../../../Base.validate'

export const EditorTypeEdit: React.FC<IEditorTypeEditProps> = ({ onChange: cbChange, data }) => {
    const localData = data as any;

    const onChange = (data: any) => {
        const vb = new CreativeTaskNewsValidator();
        vb.addData(data)
        let rules = creativeTaskEditValidate
        if (data.creativeTask.state === 'Canceled') {
            rules = creativeTaskEditValidate.filter(rule => rule.field !== 'expires').filter(rule => rule.field !== 'creativeTask.endDateTime')
        }
        vb.setRules([...rules, ...baseValidate])
        cbChange(data, vb.validation().length === 0, vb.validation())
    }

    const onChangeTitle = (e: any) => {
        localData.header = e.target.value;
        localData.creativeTask.title = e.target.value;
        onChange(localData);
    }
    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData);
    }
    const endDateChange = (date: Date) => {
        localData.creativeTask.endDateTime = date;
        localData.expires = date;
        onChange(localData);
    }
    return <Editor
        endDateChange={endDateChange}
        onChangeText={onChangeText}
        onChangeTitle={onChangeTitle}
        data={localData} />
}
