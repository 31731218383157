import { styled } from "muicomponents/src/mui/system";
import { MenuItem } from 'muicomponents/src/Menu';
import { Box } from 'muicomponents/src/Box'; 
import { Icon } from '@mui/material'; 

export const ItemBox = styled(MenuItem)({
  padding: '12px 16px !important',
  width: '100%',
  justifyContent: 'space-between !important',
  alignItems: 'center !important',
  columnGap: 10,
  borderBottom: '1px solid #c4c4c4 !important',
  fontSize: 14,
  whiteSpace: 'normal',
  "&:last-child": {
    border: 'none !important'
  }
}) as typeof MenuItem;

export const ItemFlex = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  columnGap: 16
}) as typeof Box;

export const ItemIconsBox = styled(Box)({
  display: 'flex',
  columnGap: 5
}) as typeof Box;

export const ItemIcon = styled(Icon)({
  color: '#757575'
});