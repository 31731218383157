import { IClassNameProps } from '@bem-react/core';
import { cnNewsEdit } from '../NewsEdit.index';

import { IDispatchProps } from 'redux/connector';

export interface IBodyProps extends IClassNameProps, IBodyStateProps, IDispatchProps {
  tag?: 'div';
  type?: string;
  post: any;
  onChangeText: (text: string) => void;
  onChangePost: (post: any) => void;
  children: (param: any) => React.ReactElement | React.ReactNode
}

export interface IBodyState {
  text?: string;
}

export interface IBodyStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})

export const cnBody = (...data: any) => {
  let name = 'Body';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnNewsEdit(name, ...data)
}