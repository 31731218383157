
import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import {SettingsArticlesWidget } from '../WikiArticlesWidget.index';
import { IBodyProps } from 'utils/src/DialogCreator'
export const cnSettingsArticlesDialog = cn('SettingsArticlesDialog');

export namespace NSettingsArticlesDialog {

    export interface Props extends IClassNameProps, IBodyProps<any> {
        data?: SettingsArticlesWidget.Settings 
        onChange: (value: { [key: string]: any }) => void;
    }
}
