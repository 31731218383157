import { ISkill, ISkillCollection } from './types'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
/**
 * 
 * declarate store selectors
 */
type stateType = any//затычка

/**
 * возвращает объект skills
 * @param state 
 */
export const getSkills = (state: stateType) => state.skills

/**
 * возвращает коллекцию юзеров со скилами
 * @param state 
 */
export const getSkillsUsers = (state: stateType) => getSkills(state).users

/**
 * получает список коллекцию скилов для пользователя
 * @param userId 
 */
export const getSkillsForUser = (userId?: string) => (state: stateType): ISkillCollection => {
    const id = userId || getCurrentUser(state).baseData.id
    return getSkillsUsers(state)[id]
}

/**
 * вернёт скилл пользователя по его id
 * @param userId 
 * @param skillId 
 */
export const getUserSkillById = (userId: string, skillId: string) => (state: stateType): ISkill => getSkillsForUser(userId)(state)[skillId]
