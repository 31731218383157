import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    getUserStatInfoList,
    pathFullStatisticSetting
} from '../../../../../redux/actions/LMSTeacherOffice'
import { ILoadingProps } from 'utils/src/CommonRedux/LoadIndicator'
import * as SEL from '../../../../../redux/sagas/LMSTO/selectors'

///////////
export declare namespace StatisticsFull {
    export namespace UsersList {
        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps, ILoadingProps {
            userGUID: string
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////

type IOwnProps = StatisticsFull.UsersList.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    settings: SEL.getFullStatisticSettings(state),
    ...(SEL.getUserStatCoursesListParams<any, any>('keys', 'isFinished', 'count', 'userId')(state) as { keys: string[], isFinished: boolean, count: number, userId: string })
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    getUserStatInfoList,
    pathFullStatisticSetting
}, dispatch);

////////////

export const cnClassName = cn('LMSTOStatUserCoursesList');