import actions from '../actionsTypes/TracksUse';
import {
    ITrack,
    ILearningTrack,
    ILearningFile,
    ILearningUser
} from 'utils/src/requests/models/api.learningTracks'
import { union } from 'lodash'
export interface ILearningTracksReducer {
    tracks: ILearningTracks
    files: ITrackFiles
}

export interface ILearningTracks {
    data: { [key: string]: ITrack },
    keys: string[]
    isLoading: boolean
    isFinished: boolean
    count: number
}

export const defaultTracks = {
    data: {},
    keys: [],
    isLoading: false,
    isFinished: false,
    count: 10
}

export interface ITrackFiles {
    values: { [key: string]: ILearningFile }
    keys: string[]
    isLoading: boolean
    isFinished: boolean
    count: number
}

export const defaultFiles = {
    values: {},
    keys: [],
    isLoading: false,
    isFinished: false,
    count: 10
}

const defaultLearningTracks: ILearningTracksReducer = {
    tracks: defaultTracks,
    files: defaultFiles
}

export function learningTrackUseReducer(state = defaultLearningTracks, action: any) {
    switch (action.type) {
        case actions.SET_TRACKS: {
            const { data, keys } = action.payload
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    data: { ...state.tracks.data, ...data },
                    keys: union(state.tracks.keys, keys),
                    isFinished: keys.length < state.tracks.count
                }
            }
        }

        case actions.SET_FILES: {
            const { values, keys, trackId } = action.payload
            return {
                ...state,
                files: {
                    ...state.files,
                    values: { ...state.files.values, ...values },
                    keys: union(state.files.keys, keys),
                    isFinished: keys.length < state.files.count
                }
            }
        }

        case actions.CHANGE_FIELD: {
            const { root, field, value } = action.payload as { root: keyof ILearningTracksReducer, field: string, value: any }
            return {
                ...state,
                [root]: {
                    ...state[root],
                    [field]: value
                }
            }
        }

        case actions.RESET: {
            const root: keyof ILearningTracksReducer = action.payload
            return {
                ...state,
                [root]: defaultLearningTracks[root]
            }
        }

        default: return state
    }
}

