/**
 * @packageDocumentation
 * @module List_type_users
 */
import { IListProps } from '../../List.index';
import { filters } from 'utils/src/filters.prn';

import { IDispatchProps } from '../../../../redux/connector';

export interface IDefaultRequestOption {
  search: string,
}

export interface IListUsersSearchDefaultRequestProps extends IDefaultRequestOption {
  catFilter: string,
  excludeGroup: number,
  rid: string,
  gid: number,
  extended: boolean,
  count: number,
  skipCount: number
}

export interface IListTypeUsersSearchProps extends IListProps, IDispatchProps {
  requestOptions: any;
  tag?: 'div';
}

const fltrs = {
  ...filters,
 
  search: 'search', // search query
  network: 'network'
}

export type IFLTRS = typeof fltrs & { [s: string]: string };

export const avalibleFilters = Object.keys(fltrs).reduce((acc, cur) => acc[cur] === '' ? { ...acc, [cur]: cur } : acc, fltrs as IFLTRS);