import { IdeaNewsCreate, AdditionalFieldsValue } from './IdeaType'
import { Row } from './Fields/Types'
import { Rule, BaseValidator, validateArray, validateString, validateNumber,  } from '../Base.validate'
import { IdeaFields } from './Fields/Types';
import { GUID_EMPTY } from 'utils/src/constants.prn';
export const ideasValidate: Rule[] = [
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'text',
        value: 5,
        message: 'small text'
    },
    /*{
        type: 'string',
        method: 'STR_MAX',
        field: 'text',
        value: 450,
        message: 'big text'
    },*/
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'header',
        value: 3,
        message: 'small header'
    },
    /*{
        type: 'string',
        method: 'STR_MAX',
        field: 'header',
        value: 30,
        message: 'big header'
    },*/
]

const additionalFiledsTypesValueRules: { [s: string]: Rule } = {
    'Tags': {
        type: 'array',
        method: 'ARR_MIN',
        field: '', // для данного случая не обязателен
        value: 1,
        message: 'select one or more'
    },
    'Textarea': {
        type: 'string',
        method: 'STR_MIN',
        field: '', // для данного случая не обязателен
        value: 5,
        message: 'input 5 or more symbols'
    },
    'MatrixOnePerRow': {
        type: 'araay',
        method: 'NOT_NULL_GUID',
        field: '', // для данного случая не обязателен
        value: 0,
        message: 'answer to all rows'
    },
    "DateTime":{
        type: 'string',
        method: 'STR_MIN',
        field: '', // для данного случая не обязателен
        value: 1,
        message: 'choose date'
    },
    "Int":{
        type: 'string',
        method: 'STR_MIN',
        field: '', // для данного случая не обязателен
        value: 1,
        message: 'input number'
    },
    "Single":{
        type: 'string',
        method: 'STR_MIN',
        field: '', // для данного случая не обязателен
        value: 1,
        message: 'select one'
    },
    "Multi":{
        type: 'array',
        method: 'ARR_MIN',
        field: '', // для данного случая не обязателен
        value: 1,
        message: 'select one or more'
    },
    "String":{
        type: 'string',
        method: 'STR_MIN',
        field: '', // для данного случая не обязателен
        value: 1,
        message: 'input 5 or more symbols'
    },
    "StringEmail": {
        type: 'string',
        method: 'IS_EMAIL',
        field: '', // для данного случая не обязателен
        value: 1,
        message: 'enter correct email'
    },
    "ResponsibleForStatusUser": {
        type: 'array',
        method: 'ARR_MIN',
        field: '', // для данного случая не обязателен
        value: 1,
        message: 'select one or more'
    },
    "OfferResponsibleForStatusUsers": {
        type: 'array',
        method: 'ARR_MIN',
        field: '', // для данного случая не обязателен
        value: 1,
        message: 'select one or more'
    }
}

function matrixRowsValidate(data: Row[], rule: Rule) {
    return data.reduce((a, c) => !a || c.selectedAnswer === GUID_EMPTY ? false : a, true);
}

export class IdeasValidator extends BaseValidator<any> {

    public validation() {
        const baseValidate = super.validation();
        const adiditionalFieldsValidate = this.ideasAdditionalFieldsValuesValidate(this.data.idea.additionalFieldsValues);
        const invalide: Rule[] = [...baseValidate, ...adiditionalFieldsValidate];
        return window.SERVICE__DISABLE_VALIDATE ? [] : invalide;
    }

    public ideasAdditionalFieldsValuesValidate = (fieldsValues: AdditionalFieldsValue[]) => {
        const invalide: Rule[] = [];
        const v = fieldsValues.map(k => k.v).reduce((a, c) => [...a, ...c], [] as IdeaFields[]).filter(i => i.field.isMandatory);
        v.forEach(fileld => {
            switch (fileld.field.fieldType) {
                case 'Tags': {
                    !validateArray((fileld.tags || []), additionalFiledsTypesValueRules[fileld.field.fieldType]) && invalide.push(additionalFiledsTypesValueRules[fileld.field.fieldType]);
                    break;
                }
                case 'Textarea': {
                    !validateString((fileld.value || ''), additionalFiledsTypesValueRules[fileld.field.fieldType]) && invalide.push(additionalFiledsTypesValueRules[fileld.field.fieldType]);
                    break;
                }
                case 'MatrixOnePerRow': {
                    !matrixRowsValidate(fileld.field.rows, additionalFiledsTypesValueRules[fileld.field.fieldType]) && invalide.push(additionalFiledsTypesValueRules[fileld.field.fieldType]);
                    break;
                }
                case "DateTime":{
                // const compareDates = new Date(fileld.value).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);                    
                    !validateString(( fileld.value || ""), additionalFiledsTypesValueRules[fileld.field.fieldType]) && invalide.push(additionalFiledsTypesValueRules[fileld.field.fieldType]);
                    break;
                }
                case "Int":{
                    !validateString((fileld.value || ''), additionalFiledsTypesValueRules[fileld.field.fieldType]) && invalide.push(additionalFiledsTypesValueRules[fileld.field.fieldType]);
                    break;
                }
                case "Single":{
                    !validateString((fileld.value || ''), additionalFiledsTypesValueRules[fileld.field.fieldType]) && invalide.push(additionalFiledsTypesValueRules[fileld.field.fieldType]);
                    break;
                }
                case 'Multi': {
                    !validateArray((fileld.values || []), additionalFiledsTypesValueRules[fileld.field.fieldType]) && invalide.push(additionalFiledsTypesValueRules[fileld.field.fieldType]);
                    break;
                }
                case 'String': {
                    switch (fileld.field.mask) {
                        case 'email': {
                            !validateString((fileld.value || ''), additionalFiledsTypesValueRules['StringEmail']) && invalide.push(additionalFiledsTypesValueRules['StringEmail']);
                            break;
                        }
                        default: {
                            !validateString((fileld.value || ''), additionalFiledsTypesValueRules[fileld.field.fieldType]) && invalide.push(additionalFiledsTypesValueRules[fileld.field.fieldType]);
                            break;
                        }
                    }   
                    
                }
                case 'ResponsibleForStatusUser':
                case 'OfferResponsibleForStatusUsers': {
                    !validateArray((fileld.users || []), additionalFiledsTypesValueRules[fileld.field.fieldType]) && invalide.push(additionalFiledsTypesValueRules[fileld.field.fieldType]);
                    break;
                }

            }
        })
        return window.SERVICE__DISABLE_VALIDATE ? [] : invalide;
    }
}
