import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { EditorState } from 'draft-js';

export type FormErrorType = { [key: string]: any }

export type ValidationFunctionType = (value: any) => FormErrorType | null

export const validationFunctions: { [key: string]: ValidationFunctionType} = {
    maxAttemptsCount: (value: number) => {
        if (value < 1) return { maxAttemptsCount: [tT('unit_validations.maxAttemptsCount.1')] }
        return { maxAttemptsCount: undefined }
    },
    passingMinScore: (value: number) => {
        if (value < 0) return { passingMinScore: [tT('unit_validations.passingMinScore.1')] }
        if (value > 100) return { passingMinScore: [tT('unit_validations.passingMinScore.2')] }
        return { passingMinScore: undefined }
    },
    name: (value: string) => {
        if (value.length === 0) return { name: [tT('unit_validations.name.1')] }
        if (value.length > 100) return { name: [tT('unit_validations.name.2')] }
        return { name: undefined}
    }
}

export const validator = (key: string, value: any) => {
    if (validationFunctions[key]) return validationFunctions[key](value)
    return null
}

export const validationFunctionsQ: { [key: string]: ValidationFunctionType } = {
    header: (value: string) => {
        if (value.length === 0) return { header: [tT('unit_validations.header.1')] }
        if (value.length > 100) return { header: [tT('unit_validations.header.2')] }
        return { header: undefined }
    }
}

export const questionValidator = (key: string, value: any) => {
    if (validationFunctionsQ[key]) return validationFunctionsQ[key](value)
    return null
}

export const validationFunctionsA: { [key: string]: ValidationFunctionType } = {
    text: (value: string) => {
        if (value.length === 0) return { text: [tT('unit_validations.text.1')] }
        if (value.length > 1000) return { text: [tT('unit_validations.text.2')] }
        return { text: undefined }
    },
    draftState: (value: EditorState) => {
        if (value.getCurrentContent().getPlainText().length === 0) return { draftState: [tT('unit_validations.text.1')] }
        if (value.getCurrentContent().getPlainText().length > 10000) return { draftState: [tT('unit_validations.text.2')] }
        return { draftState: undefined }
    }
}

/**
 * выполняет проверку варианта ответа
 * @param key 
 * @param value 
 */
export const answerValidator = (key: string, value: any) => {
    if (validationFunctionsA[key]) return validationFunctionsA[key](value)
    return null
}

export const fieldWithError = (field?: any[]) => field && (field.length > 0)
// export const draftFieldWithError = (field?: any[]) => field && (field.length > 0)