import { NValidate } from 'utils/src/validate_v2';
import { NIdeas } from './Ideas.interface';

export const IdeasValidateRules: NValidate.ValidateRulesObject<NIdeas.Create> = {
    text: {
        notNull: {
            value: true
        },
        min: {
            value: 5
        }
    },
    header: {
        notNull: {
            value: true
        },
        min: {
            value: 3
        }
    }
};