import {
    VacanciesActions,
    VACANCIES_APPEND_TO_LIST,
    VACANCIES_PREPEND_TO_LIST,
    VACANCIES_SET_LIST
} from "./actions.vacancies.types";

type AF<Type extends VacanciesActions.Actions['type']> = VacanciesActions.ActionFunction<Type>;

export const VacanciesSetList: AF<'VACANCIES_SET_LIST'> = (payload) => ({
    type: VACANCIES_SET_LIST,
    payload
});

export const VacanciesAppendToList: AF<'VACANCIES_APPEND_TO_LIST'> = (payload) => ({
    type: VACANCIES_APPEND_TO_LIST,
    payload
});

export const VacanciesPrependToList: AF<'VACANCIES_PREPEND_TO_LIST'> = (payload) => ({
    type: VACANCIES_PREPEND_TO_LIST,
    payload
});