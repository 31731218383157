import React, { FC, useEffect, useState } from 'react'
import { cnDeleted, mapDispatchToProps, mapStateToProps } from './Deleted.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Deleted.scss'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const DeletedPresenter: FC<any> = ({ className, setStudentsFilters, currentFilters }) => {
    const elements = [
        {
            value: 'all',
            title: tT('student_list.filters.status.all.1'),
            id: 'all'
        }, {
            value: 'absolutely',
            title: tT('student_list.filters.status.absolutely.1'),
            id: 'absolutely'
        }, {
            value: 'complete',
            title: tT('student_list.filters.status.complete.1'),
            id: 'complete'
        }, {
            value: 'inprogress',
            title: tT('student_list.filters.status.inprogress.1'),
            id: 'inprogress'
        }
    ];
    return <div className={cnDeleted({}, [className])}>
        {/* <h4 className={cnDeleted("Name")}>{tT('student_list.filters.progress.1')}</h4> */}
        <div className={cnDeleted("Box")}>
            <FormControlLabel
                control={
                    <Switch
                        checked={currentFilters}
                        onChange={() => { setStudentsFilters({ 'filter.getDeleted': !currentFilters }) }}
                        name="getDeleted"
                        color="primary"
                    />
                }
                label={tT('student_list.filters.getDeleted.1')}
            />
        </div>
        {/* <Selectize
            // key={i} 
            onClick={(value) => { setStudentsFilters({ 'filter.status': value.value }) }}
            active={currentFilters}
            // rLink={linkCreator('cat', zeroId)}
            name={tT('student_list.filters.status.1')}
            elements={elements}
            // multiple={true}
            children={[]} /> */}
    </div>
}

export const Deleted = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DeletedPresenter));
