import { IListProps } from '../../List.index';

import { IDispatchProps } from '../../../../redux/connector';
import { filters } from 'utils/src/filters.prn';

export interface IDefaultRequestOption {
  search: string,
}

export interface IListUsersDefaultRequestProps extends IDefaultRequestOption {
  catFilter: string,
  excludeGroup: number,
  rid: string,
  gid: number,
  extended: boolean,
  count: number,
  skipCount: number
}

// export interface IListUsersDefaultProps {
//   requestOptions: IListUsersDefaultRequestProps;
// }

export interface IListTypeShopProps extends IListProps, IDispatchProps {
  requestOptions: any;
  tag?: 'div';
}

// export interface IListTypeUsersState {
//   text?: string;
// }

// export interface IListStateProps {
//   store: any
// }

// export const mapStateToProps = (state: any) => ({
//   store: state.store
// })

const fltrs = {
  ...filters,
  shopCatFilter: 'catFilter',
  // type: 'catFilter', // type
  // gId: 'gid', // group id
  // groupPKID: 'gid', // group id
  // nId: 'nId', // news id
  // aId: 'aId', // poll answer id
  // bId: 'bid', // badge id
  // rId: 'rid', // rating id
  // cId: 'rId', // comment id
  // status: 'status', // event status
}

export type IFLTRS = typeof fltrs & { [s: string]: string };

export const avalibleFiltersShop = Object.keys(fltrs).reduce((acc, cur) => acc[cur] === '' ? { ...acc, [cur]: cur } : acc, fltrs as IFLTRS);