// import { IClassNameProps } from '@bem-react/core';
// import { cn } from '@bem-react/classname';
// export interface IItemProps extends IClassNameProps{
//     tag?: 'div';
// }

// export interface IItemState {

// }

// export const cnAuthorItem = cn('AuthorItem');


export { cnAuthorItem } from 'uielements/src/WikiParts/WikiAuthorList/AutorItem/AuthorItem.index'