import {
  INotifiSettingsActions,
  IGetAndSaveNotifiSettingsPayload,
  IGetNotifiSettingsNetworkGroupsPayload,
  IChangeNotifiSettingsNetworkTemplatePayload,
  IChangeNotifiSettingsNetworkPayload,
  IChangeNotifiSettingsGroupTemplatePayload
} from './actions.interfaces';
import { INotifiSettingsData, INetworkItem } from 'utils/src/requests/models/api.notifications';
import { ITemplate, INetworks } from './interfaces';

export const GET_NOTIFI_SETTINGS = 'GET_NOTIFI_SETTINGS';
export const SET_NOTIFI_SETTINGS = 'SET_NOTIFI_SETTINGS';
export const SAVE_NOTIFI_SETTINGS = 'SAVE_NOTIFI_SETTINGS';
export const CLEAR_NOTIFI_SETTINGS = 'CLEAR_NOTIFI_SETTINGS';
export const SET_NOTIFI_SETTINGS_NETWORKS = 'SET_NOTIFI_SETTINGS_NETWORKS';
export const CHANGE_NOTIFI_SETTINGS_NETWORKS = 'CHANGE_NOTIFI_SETTINGS_NETWORKS';
export const GET_NOTIFI_SETTINGS_NETWORK_GROUPS = 'GET_NOTIFI_SETTINGS_NETWORK_GROUPS';
export const SET_NOTIFI_SETTINGS_NETWORK_GROUPS = 'SET_NOTIFI_SETTINGS_NETWORK_GROUPS';
export const CHANGE_NOTIFI_SETTINGS_NETWORK_TEMPLATE = 'CHANGE_NOTIFI_SETTINGS_NETWORK_TEMPLATE';
export const CHANGE_NOTIFI_SETTINGS_GROUP_TEMPLATE = 'CHANGE_NOTIFI_SETTINGS_GROUP_TEMPLATE';

export const GET_NOTIFI_TEMPLATES = 'GET_NOTIFI_TEMPLATES';
export const SET_NOTIFI_TEMPLATES = 'SET_NOTIFI_TEMPLATES';



/***************************************************
 ********************** to saga ********************
 **************************************************/

export const getNotifiSettings = (payload: IGetAndSaveNotifiSettingsPayload): INotifiSettingsActions => ({
  type: GET_NOTIFI_SETTINGS,
  payload
})

export const saveNotifiSettings = (payload: IGetAndSaveNotifiSettingsPayload): INotifiSettingsActions => ({
  type: SAVE_NOTIFI_SETTINGS,
  payload
})

export const getNotifiTemplates = (): INotifiSettingsActions => ({
  type: GET_NOTIFI_TEMPLATES
})

export const getNotifiSettingsNetworkGroups = (payload: IGetNotifiSettingsNetworkGroupsPayload): INotifiSettingsActions => ({
  type: GET_NOTIFI_SETTINGS_NETWORK_GROUPS,
  payload
})

export const changeNotifiSettingsNetworkTemplate = (payload: IChangeNotifiSettingsNetworkTemplatePayload): INotifiSettingsActions => ({
  type: CHANGE_NOTIFI_SETTINGS_NETWORK_TEMPLATE,
  payload
})

export const changeNotifiSettingsGroupTemplate = (payload: IChangeNotifiSettingsGroupTemplatePayload): INotifiSettingsActions => ({
  type: CHANGE_NOTIFI_SETTINGS_GROUP_TEMPLATE,
  payload
})

/***************************************************
 ******************** to reducer *******************
 **************************************************/

export const setNotifiSettings = (payload: INotifiSettingsData): INotifiSettingsActions => ({
  type: SET_NOTIFI_SETTINGS,
  payload
})

export const clearNotifiSettings = (): INotifiSettingsActions => ({
  type: CLEAR_NOTIFI_SETTINGS
})

export const setNotifiSettingsNetworks = (payload: INetworks): INotifiSettingsActions => ({
  type: SET_NOTIFI_SETTINGS_NETWORKS,
  payload
})

export const setNotifiTemplates = (payload: ITemplate[]): INotifiSettingsActions => ({
  type: SET_NOTIFI_TEMPLATES,
  payload
})

export const changeNotifiSettingsNetworkData = (payload: IChangeNotifiSettingsNetworkPayload): INotifiSettingsActions => ({
  type: CHANGE_NOTIFI_SETTINGS_NETWORKS,
  payload
})
