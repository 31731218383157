import React, { FC, memo } from 'react'
import { cn } from '@bem-react/classname';
import { ComponentInjector } from 'utils/src/ComponentInjector'
import * as BaseInterface from '../interfaces'
import { Button } from 'muicomponents/src';
import { useSelector } from 'react-redux';
import { getAppDesignColors } from 'utils/src/CommonRedux/base/selectors';
import { hexToRgb } from '@material-ui/core';

export const cnClassName = cn('CharacteristicsValueTCommon');

type IPresenterProps = BaseInterface.Shop.CharacteristicsValueTBase.IPresenterProps

export const Presenter: FC<IPresenterProps> = memo(({
    value,
    size = 'small',
    sx
}) => {
    const { displayName, isSelected, isDisabled } = value

    const bgColor = useSelector(getAppDesignColors).primaryColor3

    const rgb = hexToRgb(bgColor)
    const rgba = rgb.slice(0, -1) + ', 0.2)'
    
    return <Button
        variant='outlined'
        disabled={isDisabled}
        size={size}
        className={cnClassName('', { isSelected, isDisabled }, ['CommonProductCharBtn'])}
        sx={{
            ...sx,
            minWidth: '30px',
            background: isSelected ? rgba : undefined,
            whiteSpace: 'nowrap'
        }}
    >
        {displayName}
    </Button >
})

export const initCommon = () => ComponentInjector.getInstance().addComponent('shopMui_characteristics_types', Presenter, 'ShopCharacteristics')

export default Presenter;
