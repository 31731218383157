export interface ThanksInfo {
    users: any[];
    thanksReasonIds: string[];
    thanksCount: number;
}

export const defaultThanksInfo: ThanksInfo = {
    users: [],
    thanksReasonIds: [],
    thanksCount: 0,
}