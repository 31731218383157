import { IIdeaFieldProps } from '../FieldProps';
import React, { useState } from 'react';
import { CheckboxInput } from 'uielements/src';
import './Bool.scss';
import { cn } from "@bem-react/classname";

export const cnIdeaField = cn("IdeaField");

export const Bool: React.FC<IIdeaFieldProps> = ({ field, onChange = () => { } }) => {
    // const [value, setValue] = useState(field.value);
    const onChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        field.value = field.value === 'true' ? 'false' : 'true';
        // setValue(field.value)
        onChange(field);
    }
    const isChecked = field.value === 'true' ? true : false;
    return <CheckboxInput className={cnIdeaField("Bool")} checked={isChecked} text={field.field.name} onChange={onChecked} />;
}