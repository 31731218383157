
import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IStateType } from 'redux/store';
import { getOrgchartPositions, getOrgchartChangedPosition } from '../../../../redux/sagas/orgchart/selectors'
import { deletePositionFromUnit, changePositionDataOrgchart } from '../../../../redux/sagas/orgchart/actions'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';


export interface ICustomNodePositionsProps extends IClassNameProps, ICustomNodePositionsStateProps, ICustomNodePositionsDispatchProps, ICustomNodePositionsOwnProps { }

export type ICustomNodePositionsStateProps = ReturnType<typeof mapCustomNodePositionsStateToProps>;

export interface ICustomNodePositionsOwnProps {
    id: string;
    removePosition?: (id: string) => void;
    editPositions?: boolean;
    onClickEdit?: (id?: string) => void;
    unitId: string;
    getLast?: boolean;
    howManyLeft?: number;
    showMore?: (cut:number) => void;

}

export const mapCustomNodePositionsStateToProps = (state: IStateType, props: ICustomNodePositionsOwnProps) => {
  
    return {
        uIsAdmin: getAuthUser(state).baseData.isAdmin,
        data: getOrgchartPositions(props.id)(state),
        // data: getOrgchartChangedPosition(props.id)(state),
        dataEdit:  getOrgchartChangedPosition(props.id)(state),
        filterShowOnlyOpenVacancy: state.orgchart.showOnlyOpenVacancy
    };
};

export type ICustomNodePositionsDispatchProps = ReturnType<typeof mapCustomNodePositionsDispatchToProps>

export const mapCustomNodePositionsDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    deletePositionFromUnit,
    changePositionDataOrgchart
}, dispatch)


export const cnCustomNodePositions = cn('CustomNodePositions');
