import actions from 'redux/actionsTypes/Slider';
import { ISliderItemData } from 'utils/src/requests/models/api.slider'

export interface ISliderState {
  data: ISliderItemData[],
  imageNum: number | null,
  mouseOver: boolean,
  id?: string,
}

export interface ISliders {
  [s: string]: ISliderState
}

const defaultSlider: ISliderState = {
    data: [],
    imageNum: null,
    mouseOver: false,
};

export function sliderLinkReducer(state: ISliders = {}, action: any) {

  switch (action.type) {
    case actions.INIT: {
      let { id, imageNum, data: d } = action.payload;
      return {
        ...state,
        [id]: {
          data: d || defaultSlider.data,
          imageNum,
        }
      }
    }

    case actions.CHANGE_SLIDE: {
      let { id, imageNum } = action.payload;
      let item = state[id];

      if (!item) item = defaultSlider;
      return {
        ...state,
        [id]: {
          ...state[id],
          imageNum: action.payload.imageNum < state[action.payload.id].data.length && action.payload.imageNum >= 0  ? imageNum : 0,
          
        }
      }
    }
    // case actions.CHANGE_SLIDE_TIMER: {
    //   let { id, imageNum } = action.payload;
    //   let item = state[id];

    //   if (!item) item = defaultSlider;
    //   return {
    //     ...state,
    //     [id]: {
    //       data: state.data,
    //       imageNum
    //     }
    //   }
    // }
    case actions.INCREMENT_SLIDE_ITEM: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          imageNum: (action.payload.imageNum + 1) % state[action.payload.id].data.length
        }
      }
    }
    case actions.MOUSE_OVER: {
      const { id, mouseOver } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          mouseOver,
        }
      }
    }
    default:
      return state
  }
}