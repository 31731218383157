import React, { FC, useContext, useRef, useMemo, ComponentProps } from 'react';
import { cnNewsTypeNews } from './News.index';
import './News.scss';
import { DraftInput, useDraftEdit } from 'muicomponents/src/DraftInput/DraftInput';
import { Translate } from 'localizations/Translate';
import { ComponentInjector } from 'utils/src/ComponentInjector';

import { useDebounce, useDidUpdateEffect } from 'utils/src/hooks';
import { PrepareErrorText } from 'News/utils/utils.validate';
import { NewsCreateContext } from 'News/creator/Creator/Creator.constants';
import { useNewsEdit } from './News.hooks';
import { useDraftEditorStyle } from 'News/creator/hooks';

const prepareI18nKey = (key: string) => `pryaniky.news.create.news.${key}`;

const NewsTypeNewsPresenter: FC<{}> = ({
}) => {

    const { creatorId } = useContext(NewsCreateContext);

    const {
        data,
        renderParams,
        errors,
        clearEditor,
        changeNews
    } = useNewsEdit(creatorId);

    const {
        text: currentNewsText,
        draftRawState
    } = data;

    const textRef = useRef(currentNewsText);
    textRef.current = currentNewsText;

    const {
        state,
        draftState,
        setDraftState
    } = useDraftEdit(draftRawState, clearEditor);

    useDidUpdateEffect(() => {
        // because text editor push additional state change when state is empty externally
        if(!textRef.current && !state.text) return;
        changeNews({
            text: state.text,
            draftRawState: state.raw
        });
    }, [state]);

    const draftCommonProps = useDraftEditorStyle();

    const DraftInputProps = useMemo<Partial<ComponentProps<typeof DraftInput>>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('text')} />
    }), []);

    return (
        <>
            <DraftInput
                test-id={'timeline-create-news-text'}
                {...DraftInputProps}
                {...draftCommonProps}
                disableToolbar={!renderParams?.allowRichEdit}
                value={draftState}
                onChange={setDraftState}
                error={
                    Boolean(errors.text)
                }
                helperText={
                    // TODO check types in BaseNews.text
                    Boolean(errors.text) && PrepareErrorText(errors.text as any)
                }
                clearValue={clearEditor}
            />
        </>
    )
}

export const NewsTypeNews = NewsTypeNewsPresenter;

export const initNewsNewsCreator = () => ComponentInjector.getInstance().addNode('news_creator_mui5', <NewsTypeNews />, 'news');