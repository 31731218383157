import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IStateType } from 'redux/store';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { menuDeleteItem } from '../redux/actions';
import { getMenuItemById } from '../redux/selectors';

export const cnLeftMenuRemover = cn('LeftMenuRemover');

export interface ILeftMenuRemoverProps extends ILeftMenuRemoverOwnProps, IClassNameProps {

};

export interface ILeftMenuRemoverOwnProps {

};

export interface ILeftMenuRemoverState {
  id: string;
  path: string[];
}



export interface IRemoverConfirmTitleProps extends IRemoverConfirmTitleOwnProps, ImapRemoverConfirmTitleStateToProps, ImapRemoverConfirmTitleActionToProps {

};

export interface IRemoverConfirmTitleOwnProps {
  id: string;
  path: string[];
  cb: () => void;
};

export const mapRemoverConfirmTitleStateToProps = (state: IStateType, props: IRemoverConfirmTitleOwnProps) => ({
  data: getMenuItemById(props.id)(state)
});

export type ImapRemoverConfirmTitleStateToProps = ReturnType<typeof mapRemoverConfirmTitleStateToProps>;

export const mapRemoverConfirmTitleActionToProps = (d: Dispatch<Action>) => bindActionCreators({
  menuDeleteItem
}, d);

export type ImapRemoverConfirmTitleActionToProps = ReturnType<typeof mapRemoverConfirmTitleActionToProps>;