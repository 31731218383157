import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
// import { saveSkipTest } from "../../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
export interface IQuestionItemProps extends IClassNameProps {
    className?: string;
    qid: string;
    question: string;
    text: string;
    questionTimer?: any;
    resultAnswersMap: { [key: string]: any };
    resultAnswers: string[];
    answers: string[];
    answersMap: { [key: string]: any };
    qtype: number;
    isNeedModeration: boolean;
    detailsRender?: (question: any) => React.ReactNode;
}

export interface IQuestionItemState {

}

export const cnQuestionItem = cn('ResultQuestionItem');