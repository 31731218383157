import { Reducer } from "redux";
import { initialWidgetReducerState } from "./constants";
import { NWidgets } from "./types";
import {
    WIDGETS_SET_DATA,
    WIDGETS_UPDATE_DATA,
    WIDGETS_GET_DATA,
    WIDGETS_ADD_WIDGET,
    WIDGETS_REMOVE_WIDGET,
    WIDGETS_DRAG_WIDGET,
    WIDGETS_SET_WIDGET_SETTINGS,
    WIDGETS_UPDATE_WIDGET_SETTINGS,
    WIDGETS_SET_ACTIVE_PAGE,
    WIDGETS_SET_VIEW_TYPE,
    WIDGETS_UPDATE_WIDGET,
    WIDGETS_SET_CONTEXT,
    WIDGETS_UPDATE_CONTEXT,
    WIDGETS_SET_CONTEXTS,
    WIDGETS_UPDATE_CONTEXTS
} from './actions';
import { NWidgetsActions } from "./actions.types";
import { getWidgetById } from "./selectors";
import { cloneDeep, fromPairs } from "lodash";
import { TWidgetsTypeToDragOthersWidget, TWidgetsUnionSimplified } from "Widgets_v2/types";
import { prepareContextFromSettings } from "../utils";

export const widgetsReducer: Reducer<NWidgets.Reducer, NWidgetsActions.Actions> = (state = initialWidgetReducerState, action) => {
    switch(action.type) {

        case WIDGETS_SET_DATA: {
            return {
                ...state,
                ...action.payload,
                active: action.payload.active || ''
            };
        };

        case WIDGETS_UPDATE_DATA: {
            return {
                ...state,
                active: action.payload.active || '',
                contexts: {
                    ...state.contexts,
                    ...action.payload.contexts
                },
                pages: {
                    ...state.pages,
                    ...action.payload.pages
                },
                structures: {
                    ...state.structures,
                    ...action.payload.structures
                },
                widgets: {
                    ...state.widgets,
                    ...action.payload.widgets
                },
            }
        };

        case WIDGETS_ADD_WIDGET: {

            const parentWidget = cloneDeep(getWidgetById<TWidgetsTypeToDragOthersWidget>(action.payload.toWidget)({ widgets2: state }));

            parentWidget.data = parentWidget.data?.map(column => {
                if(column.id === action.payload.toColumn) {
                    return {
                        ...column,
                        items: [ action.payload.widget.id, ...column.items ]
                    };
                }
                return column;
            }) || null;

            let relations = cloneDeep(action.payload.widget.relations);
            if (!relations) relations = ['common', action.payload.widget.id];
            if (relations[0] === action.payload.widget.id) relations.splice(0, 0, 'common');
            if (relations[1] !== action.payload.widget.id) relations.splice(1, 0, action.payload.widget.id);

            const newWidgetContext: NWidgets.WidgetContext = {
                __parent: parentWidget.id,
                ...prepareContextFromSettings(action.payload.widget.settings)
            };

            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    [action.payload.widget.id]: newWidgetContext
                },
                widgets: {
                    ...state.widgets,
                    [parentWidget.id]: parentWidget,
                    [action.payload.widget.id]: {
                        ...action.payload.widget,
                        relations
                    }
                }
            };
        };

        case WIDGETS_REMOVE_WIDGET: {

            const widget = cloneDeep(getWidgetById<TWidgetsTypeToDragOthersWidget>(action.payload.fromWidget)({ widgets2: state }));

            widget.data = widget.data?.map(column => {
                if(column.id === action.payload.fromColumn) {
                    return {
                        ...column,
                        items: column.items.filter(id => id !== action.payload.widgetId)
                    };
                }
                return column;
            }) || null;

            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    [widget.id]: widget
                }
            };
        };

        case WIDGETS_DRAG_WIDGET: {

            const widget = cloneDeep(getWidgetById<TWidgetsTypeToDragOthersWidget>(action.payload.inWidget)({ widgets2: state }));
            
            widget.data = widget.data?.map(column => {
                if(column.id === action.payload.inColumn) {
                    return {
                        ...column,
                        items: action.payload.sortedArray
                    };
                }
                return column;
            }) || null;

            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    [widget.id]: widget
                }
            };
        };

        case WIDGETS_SET_WIDGET_SETTINGS:
        case WIDGETS_UPDATE_WIDGET_SETTINGS: {

            const widget = cloneDeep(getWidgetById(action.payload.widgetId)({ widgets2: state }));

            if(action.type === WIDGETS_SET_WIDGET_SETTINGS) {
                widget.settings = action.payload.settings;
            } else {
                widget.settings = widget.settings 
                    ? {
                        ...widget.settings,
                        ...action.payload.settings
                    }
                    : action.payload.settings;
            }
            
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    [widget.id]: widget
                }
            };
        };

        case WIDGETS_SET_ACTIVE_PAGE: {
            return {
                ...state,
                active: action.payload
            };
        };

        case WIDGETS_SET_VIEW_TYPE: {
            return {
                ...state,
                fiewType: action.payload
            };
        };

        case WIDGETS_UPDATE_WIDGET: {

            let widget = cloneDeep(getWidgetById(action.payload.id)({ widgets2: state }));

            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    [action.payload.id]: {
                        ...widget,
                        ...action.payload as typeof widget
                    }
                } 
            };
        };

        case WIDGETS_SET_CONTEXT: {
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    [action.payload.key]: action.payload.value
                }
            };
        };

        case WIDGETS_SET_CONTEXTS: {
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    ...fromPairs(action.payload.map(el => [ el.key, el.value ]))
                }
            };
        };

        case WIDGETS_UPDATE_CONTEXT: {
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    [action.payload.key]: {
                        ...state.contexts[action.payload.key],
                        ...action.payload.value
                    }
                }
            };
        };

        case WIDGETS_UPDATE_CONTEXTS: {
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    ...fromPairs(action.payload.map(el => [ el.key, {
                        ...state.contexts[el.key],
                        ...el.value
                    } ]))
                }
            };
        };

        default: {
            return state;
        };

    };
};