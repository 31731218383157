import React, { FC, useEffect, useState } from 'react'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import { withNewsContext, NewsContextProps } from 'News/contexts/news'
import addNewsModal from '../dialogs/AddNewsToTrack'
import addWikiModal from '../dialogs/AddWikiToTrack'
import { Button, Spinner } from 'uielements/src'
import { IWikiItemActionProps } from 'blocks/WIKIList/ContextMenu/ContextMenu.index'
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux'
import {
    addFile,
    addFilePayloadType
} from '../redux/actions/TracksManage'
import { tTr } from 'LMSModule/utils/i18n.adaptor'

/**
 * экшен добавления новости к плану
 */
const NewsAction = withNewsContext((props: NewsContextProps) => {
    // const add = () => {
    //     addNewsModal({
    //         newsId: props.newsId
    //     }).then((result) => {

    //     }).catch(() => {

    //     })
    // }
    const add = () => { }
    return <Button theme="unstyled" className={'service-tolearn'} onClick={add}>Add to track</Button>
})

/**
 * экшен добавления вики страницы из списка вики страниц
 * @param props 
 */
const WikiListItemAction: FC<IWikiItemActionProps & any> = ({ data, addFile }) => {
    const add = () => {
        addWikiModal({
            data
        }).then((result) => {
            const payload: addFilePayloadType = {
                tagId: result.tid,
                type: 'PagesV2',
                uid: result.wid
            }
            addFile(payload)
        }).catch(() => {

        })
    }
    return <Button theme="unstyled" className={'service-tolearn'} onClick={add}>
        {tTr('wiki_list.action.addtotrack.1')}
    </Button>
}
export const mapStateToProps = (state: any, props: any) => ({

})

export const mapDispatchToProps = (dispatch: any, ownProps: any) => bindActionCreators({
    addFile
}, dispatch);
const ConnectedWikiListItemAction = connect<any, any, any, any>(
    mapStateToProps,
    mapDispatchToProps
)(WikiListItemAction);


export default (learningtracks: boolean) => {
    // ComponentInjector.getInstance().addNode('news_service_actions', <NewsAction />, 'lms.addToLearningTrack')
    learningtracks &&
        ComponentInjector.getInstance().addComponent('wiki_list_item', ConnectedWikiListItemAction, 'add-wiki-to-learning-track')
}

