import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
// получаем массив строк с сылками крошек
const getCrumbs = (): string[] => {
    return JSON.parse((window as any)?.localStorage.getItem('BreadCrumbs')) || [];
}
// получаем массив объектов для отображения
const getCrumbsOBJ = (): any[] => {
    return JSON.parse((window as any)?.localStorage.getItem('BreadCrumbsObj')) || []
}
// флаг определяющий начинать или не начинать записывать крошки
const getStartCollectCrumbsFlag = (): any[] => {
    return JSON.parse((window as any)?.localStorage.getItem('CollectCrumbs')) || false
}
export const testCrumbs = (layoutWikilist: any) => {

    const history = useHistory();
    const historyRef = useRef(history);
    useEffect(() => {
        historyRef.current = history;
    }, [history]);
    const oldPathname = useRef(history.location.pathname)

    const initCrumbs = useCallback(() => {
        const lsCrumbs = getCrumbsOBJ();

        return lsCrumbs;
    }, []);

    let collectCrumbsFlag = getStartCollectCrumbsFlag()

    const [crumbs, setCrumbs] = useState<{
        name: string;
        alias: string;
    }[]>([]);

    const crumbsRef = useRef(crumbs);

    useEffect(() => {
        crumbsRef.current = crumbs;
    }, [crumbs]);

    useEffect(() => {
        if (historyRef.current.location.pathname === '/wikilist' || historyRef.current.location.pathname === '/wikilist?coll=true') {
            // если мы находимся в корнивом викисписке, то очищаем крошки и устанавливаем флаг CollectCrumbs начала записи крошек на тру

            localStorage.setItem("CollectCrumbs", JSON.stringify(true));
            localStorage.removeItem("BreadCrumbs");
            localStorage.removeItem("BreadCrumbsObj");
            localStorage.removeItem("lastLocationHref");
        } else {
            // если мы где то глубоко в списке то инициируем получение массива объектов крошек для отображения и записываем их в стейт который бцудем отдавать
            setCrumbs(initCrumbs());
        }
    }, []);


    const addCrumbs = useCallback(() => {

        if (
            historyRef.current.location.pathname !== '/wikilist'
            && historyRef.current.location.pathname.startsWith('/wikilist')
        ) {
            const lsCrumbs = getCrumbs();
            const lsCrumbsWithNames = getCrumbsOBJ();

            const includedIndex = lsCrumbs.findIndex(el => el === historyRef.current.location.pathname);

            if (
                historyRef.current.action === 'PUSH'
                && includedIndex !== -1
                && includedIndex !== lsCrumbs.length - 1
            ) {
                const newCrumbs = [...lsCrumbs.filter((el, idx) => idx <= includedIndex)];

                const crumbsObjectsFilter = [...lsCrumbsWithNames.filter((el, idx) => idx <= includedIndex)];
                setCrumbs(crumbsObjectsFilter);
                localStorage.setItem("BreadCrumbs", JSON.stringify(newCrumbs));
                localStorage.setItem("BreadCrumbsObj", JSON.stringify(crumbsObjectsFilter));
                return;
            }
            if (lsCrumbs?.find(el => el === historyRef.current.location.pathname)) return;
            // let newCrumbs = [...lsCrumbs.map((el, idx) => ({ name: el, alias: el }))];
            // const newCrumbs = [...lsCrumbs.map((el, idx) => ({ name: el, alias: el })), { name: historyRef.current.location.pathname , alias: historyRef.current.location.pathname } ];

            // ищем последнюю добавленную ссылку и приводим ее к нужному виду
            let string2 = lsCrumbs[lsCrumbs?.length - 1]?.replace("/wikilist/", "")

            // ищем в коллекциях страницы объект с с хэтой ссылкой
            let findCollObj = layoutWikilist.collections?.find((el: any) => el.alias === string2)

            // это переменная в которую мы будем записывать объекты и передавать их потом для отображения и сохранять в сторедж
            let crumbsWithNames: any = [];
            // проверяем включена ли запись крошек. и если включена то заполняем объект для начала главной папкой
            if (collectCrumbsFlag) crumbsWithNames = [{ name: "dash", alias: "dash" }];
            //    если в коллекции страницы был найден подходящий объект и его еще нет в крошках из локалсторежда то записываем в нашу переменную crumbsWithNames
            if (findCollObj && !lsCrumbsWithNames?.map((el) => el.alias)?.includes(findCollObj.alias)) {
                crumbsWithNames = [...lsCrumbsWithNames, findCollObj]
            }

            // если был выполнен переход по прямой ссылке, то мы очищаем переменную и завершаем запись крошек установкой флага на false
            if (historyRef.current.action === 'POP') {
                crumbsWithNames = []
                localStorage.setItem("CollectCrumbs", JSON.stringify(false));
            }

            // if (crumbsWithNames.length > 0) {
            // записываем получившийся массив в стейт который будет отдаваться для отображения
            setCrumbs(crumbsWithNames);
            // записываем получившийся массив в локал сторедж
            localStorage.setItem("BreadCrumbsObj", JSON.stringify(crumbsWithNames));
            // }

            // записываем в локалсторедж массив строк из ссылок + новая ссылка
            localStorage.setItem("BreadCrumbs", JSON.stringify([...lsCrumbs, historyRef.current.location.pathname]));

            oldPathname.current = historyRef.current.location.pathname;
        }
    }, []);

    const removeCrumbs = useCallback(() => {
        const lsCrumbs = getCrumbs();
        if (historyRef.current.action === 'POP' && lsCrumbs.includes(oldPathname.current)) {
            const newCrumbs = lsCrumbs.filter(el => el !== oldPathname.current);
            localStorage.setItem("BreadCrumbs", JSON.stringify(newCrumbs));
            localStorage.setItem("CollectCrumbs", JSON.stringify(false));

            oldPathname.current = historyRef.current.location.pathname;
        }
    }, []);

    useEffect(() => {
        if (layoutWikilist) {
            // если layoutWikilist пришел, то мы находимся в викилисте
            addCrumbs();
        } else {
            // если не пришел, то мы гуляем по проэкту вне викилиста а значит нужно 
            // очистить наши локалстореджи
            localStorage.setItem("BreadCrumbs", JSON.stringify([]));
            localStorage.setItem("BreadCrumbsObj", JSON.stringify([]));
            // и остановить запись хлебных крошек.
            localStorage.setItem("CollectCrumbs", JSON.stringify(false));
        }

        return () => {
            removeCrumbs();
        };
    }, []);

    return crumbs;
};

// Ирма toDo
// hook historyRef =>current =>action
// перенести свои условия +
// и добавить условия зачистки сторежа при уходе с вики листа не возвращаясь на главную. +
// и добавить зачистку при переходе по прямой ссылке из викилиста в викилист когда крошки уже были. +