import React, { FC } from 'react'
import {
    cnCurrencyTooltipItem,
    IOwnProps,
} from './item.index'
import './item.scss'
import { Icon } from 'uielements/src';
import { Bable } from 'blocks/Bable/Bable'

const Item: FC<IOwnProps> = ({ className, value, title, icon }) => {
    return <div className={cnCurrencyTooltipItem({}, [className])}>
        <div className={cnCurrencyTooltipItem('Left')}>
            <Bable className={cnCurrencyTooltipItem('Buble')}>
                <Icon icon={icon} className={cnCurrencyTooltipItem('Icon')} />
            </Bable>
        </div>
        <div className={cnCurrencyTooltipItem('Right')}>
            <div className={cnCurrencyTooltipItem('Name')}>{title}</div>
            <span className={cnCurrencyTooltipItem('Value')}>{value}</span>
        </div>
    </div>
}

export default Item;
