import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';


import { muteUnreadThunk } from '../../../src/redux/actions/Chat';

export interface IChatListProps extends IClassNameProps, IChatListStateProps, IDispatchProps {
  tag?: 'div';
}

export interface IChatListState {

}

export interface IChatListStateProps {
  list: any[];
  chatUrl: string;
  users: any[];
}

export interface IDispatchProps {
  mute: ()=>void
}

export const mapDispatchToProps: IDispatchProps = {
  mute: muteUnreadThunk
}

export const mapStateToProps = (state: any) => ({
  list: state.chat.channelsList,
  chatUrl:  state.chat.chatUrl,
  users: state.chat.users
})

export const cnChatList = cn('ChatList');