import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IBanner } from '../interfaces'
import {
    getNewsBannersStateByKey
} from 'News/redux/saga/selectors';
import {
    newsBannersSetReaded,
    newsBannersSetClosed
} from 'News/redux/actions';
import { bindActionCreators, Dispatch, Action } from 'redux';

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsBannerItem {

        export type IPresenterProps = IOwnProps & IClassNameProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

        export interface IState { }

        export interface IOwnProps {
            banner: IBanner;
        }
    }
}
////////////

export const mapStateToProps = (state: any, { banner }: News.NewsBannerItem.IOwnProps) => ({
    readed: getNewsBannersStateByKey('readed')(state).includes(banner.id)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: News.NewsBannerItem.IOwnProps) => bindActionCreators({
    newsBannersSetReaded,
    newsBannersSetClosed
}, dispatch);

export const cnClassName = cn('NewsBannerItem');