import React, { FC, useState, createContext, useContext, useEffect, useMemo, useRef, useCallback } from 'react'

const defaultCatRender = (cat: string, values: any, FieldRender: React.ComponentType<any>) => {
    return <>
        <div>{cat}</div>
        <div>{values.map((v: any) => <FieldRender field={v} />)}</div>
    </>
}
const defaultFieldRender = (field: any) => {
    return <>
    </>
}

const defaultContext: any = {
    categotyRender: defaultCatRender,
    fieldRender: defaultFieldRender
}

export const Context = createContext<any>(defaultContext)

export const FieldRender: FC<any> = ({ field }) => {
    const { fieldRender } = useContext(Context)
    return <>{fieldRender(field)}</>
}



export const CatRender: FC<any> = ({ cat, values }) => {
    const { categotyRender } = useContext(Context)
    return <>
        {categotyRender(cat, values, FieldRender)}
    </>
}

export const FieldsRender: FC<any> = ({ fields = [], categotyRender = defaultCatRender, fieldRender = defaultFieldRender }) => {

    const fieldsCategoryes = useMemo(() => (fields as any[]).reduce<any>((a: any, c: any) => {
        const cat = c.field.category
        a[cat] = [...(a[cat] || []), c]
        return a
    }, {}), [fields])
    const categoryesKeys = Object.keys(fieldsCategoryes).sort().filter((cat: string) => cat !== 'default.fields');

    const context = useMemo<any>(() => {
        return {
            categotyRender,
            fieldRender
        }
    }, [categotyRender, fieldRender])

    return <>
        <Context.Provider value={context}>
            {categoryesKeys.map((cat: string) =>
                <CatRender
                    cat={cat}
                    values={fields.filter((v: any) => v.field.category === cat)} />)}
        </Context.Provider>
    </>
}