import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IUnitType } from 'LMSModule/utils/common.utils'
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    unit: IUnitType;
    active: boolean;
    itemClick: (unit: IUnitType) => void
}

export const cnClassName = cn('LMSTaskSelectorItem');