import * as React from 'react';
import * as utils from 'utils/src/utils';

import WidgetPresenter from '../Widget';
import { cnWidget, IWidgetProps } from '../Widget.index';
import { widgets } from "i.widgets";

import './Widget_type_calendar.scss';
// import { NewsView } from 'blocks/NewsView/NewsView';
import { Calendar } from 'blocks/Calendar/CalendarWidget'

const type = 'calendarWidget';

export default class WidgetTypeCalendarPresenter extends WidgetPresenter<IWidgetProps> {
  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    return (
      <widgets.components.common {...this.props} className={cnWidget({ type })}>
        <Calendar {...this.props} context={this.props.wcontext} className={cnWidget({ type, subtype })}  settings={this.props.widget.settings}/>
      </widgets.components.common>
    );
  }
}