import {
    takeEvery,
    put,
    select,
    takeLeading,
    takeLatest,
    call,
    throttle,
    fork
} from 'redux-saga/effects';

import actions from './actionsType'

import {
    pathNews
} from 'News/redux/actions'

import {
    getNewsById
} from 'News/redux/saga/selectors'

import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'

import * as AT from './actions.d'

import {
    API,
    getCookie
} from 'utils/src/utils'

import { removeUserFromArray, addUserToArray, toggleUserInArray, userInArray } from 'blocks/NewsTypes/NewsUtils'
import { withProgressIndicator } from 'utils/src/CommonRedux/LoadIndicator'

import { toast } from 'react-toastify';

const handleParticipantAction = withProgressIndicator(function* handleParticipantAction({ payload }: AT.AParticipantAction): any {
    try {
        const { id, status } = payload

        const news = yield select(getNewsById(id));
        const user = yield select(getCurrentUser)
        const userData = user.baseData
        if (!news) {
            const request = yield call(API.news.action, id, 11, 'event_status;' + status + ';' + getCookie('authUid'))
            const result = yield request.r
            return;
        }
        const uevent = news.uevent;
        const { eventParticipants, eventMaybePar, eventNotGoers, eventWaitList, eventStatus, eventParticipantsCount, eventNotGoersCount, eventMaybePartCount, eventWaitListCount } = uevent
        if (status === eventStatus) return;
        const values: any = {
            'going': {
                list: eventParticipants || [],
                count: eventParticipantsCount,
                countStr: 'eventParticipantsCount',
                listStr: 'eventParticipants',
            },
            'maybe': {
                list: eventMaybePar || [],
                count: eventMaybePartCount,
                countStr: 'eventMaybePartCount',
                listStr: 'eventMaybePar',
            },
            'no': {
                list: eventNotGoers || [],
                count: eventNotGoersCount,
                countStr: 'eventNotGoersCount',
                listStr: 'eventNotGoers',
            },
            'waitlist': {
                list: eventWaitList || [],
                count: eventWaitListCount,
                countStr: 'eventWaitListCount',
                listStr: 'eventWaitList',
            },
        }
        let last: any = {}
        if (eventStatus !== '' && values[eventStatus]) {
            last = {
                [values[eventStatus].listStr]: toggleUserInArray(values[eventStatus].list, userData),
                [values[eventStatus].countStr]: values[eventStatus].count !== 0 ? values[eventStatus].count - 1 : values[eventStatus].count,
            }
        }

        

        const request = yield call(API.news.action, id, 11, 'event_status;' + status + ';' + getCookie('authUid'))
        /**
         * @todo https://tracker.yandex.ru/COMMON-10420
         * add check by answer and change news if apticipate action is successed
         */
        const result = yield request.r
        if(result.error_code === 0) {
            yield put(pathNews({
                id,
                news,
                uevent: {
                    //...result.data.uevent,
                    ...uevent,
                    eventStatus: result.data.uevent.eventStatus,
                    ...last,
                    [values[result.data.uevent.eventStatus].listStr]: toggleUserInArray(values[result.data.uevent.eventStatus].list, userData),
                    [values[result.data.uevent.eventStatus].countStr]: userInArray(values[result.data.uevent.eventStatus].list, userData) ? values[result.data.uevent.eventStatus].count + 1 : values[result.data.uevent.eventStatus].count,
                }
            }))
            if(result.data.uevent.eventStatus === 'waitlist') {
                toast.success(result.error_text);
            }
        } else {
            toast.error(result.error_text);
        }

    } catch (error) {
        console.warn(error)
    }
    yield;
    return 0;
})


const handleLoadParticipant = function* handleLoadParticipant({ payload }: AT.ALoadParticipant): any {
    try {
        const { status, id } = payload

        const news = yield select(getNewsById(id));
        if (!news) return;
        const uevent = news.uevent;

        const user = yield select(getCurrentUser)
        const userData = user.baseData

        const { eventParticipants, eventMaybePar, eventWaitList, eventNotGoers, eventStatus, eventParticipantsCount, eventNotGoersCount, eventMaybePartCount,eventWaitListCount } = uevent
        const values: any = {
            'going': {
                list: eventParticipants || [],
                count: eventParticipantsCount,
                countStr: 'eventParticipantsCount',
                listStr: 'eventParticipants',
            },
            'maybe': {
                list: eventMaybePar || [],
                count: eventMaybePartCount,
                countStr: 'eventMaybePartCount',
                listStr: 'eventMaybePar',
            },
            'no': {
                list: eventNotGoers || [],
                count: eventNotGoersCount,
                countStr: 'eventNotGoersCount',
                listStr: 'eventNotGoers',
            },
            'waitlist': {
                list: eventWaitList || [],
                count: eventWaitListCount,
                countStr: 'eventWaitListCount',
                listStr: 'eventWaitList',
            },
        }
        if (!(status in values)) return;

        const request = yield call(API.news.getEventPartipants, id, status, { skipCount: 0, count: 10 })
        const result = yield request.r
        if (result && result.data) {
            yield put(pathNews({
                ...news,
                uevent: {
                    ...uevent,
                    [values[status].listStr]: status === eventStatus ? [userData, ...removeUserFromArray(result.data, userData)] : result.data
                }
            }))
        }
    } catch (error) {
        console.warn(error)
    }
    yield;
    return 0;
}

/**
 * module root saga
 */
const root = function* root() {
    yield takeLeading(actions.EVENT_PARTICIPANT, handleParticipantAction)
    yield takeLeading(actions.LOAD_PARTICIPANT, handleLoadParticipant)
};

/**
 * export root saga
 */
export default root;