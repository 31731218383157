import React from 'react';
import { cnWidget, IWidgetProps } from '../../Widget.index';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../../Widget';
import { widgets } from "i.widgets";
import { WikiActueleArticlesWidgetPreseter } from 'blocks/WikiActueleArticlesWidget/WikiActueleArticlesWidget';
import { IWidgetTypeWikiActueleArticlesProps } from './Widget_Type_Wiki_Actuele_Articles.index';
import './Widget_Type_Wiki_Actuele_Articles.scss';

const type = 'wikiActueleArticles';

export default class WidgetTypeWikiActueleArticlesPresenter extends WidgetPresenter<IWidgetTypeWikiActueleArticlesProps> {

    public render() {
        if (!this.props.widget) return null
        const subtype = utils.widgetSubtype(this.props.widget.type);
        const Tag = widgets.components.common
        
        return <Tag {...this.props} className={cnWidget({ type, subtype })}>
            <WikiActueleArticlesWidgetPreseter {...this.props} isEdit={this.props.edit} settings={this.props.widget.settings as any}  />
        </Tag>
    }
}