import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { setStudentsFilters } from '../../../../redux/actions/LMSTeacherOffice'
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace LMSTO {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace StudentsList {
        // eslint-disable-next-line @typescript-eslint/no-namespace
        export namespace UnitsFilter {

            export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

            export interface IState { }

            export interface IOwnProps extends IClassNameProps {
                cid: string
            }

            export interface IStateProps {
                currentFilters: string[];
                units: any[]
            }

            export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

            ////////////
        }
    }
}
////////////
export const mapStateToProps = (state: any, props: LMSTO.StudentsList.UnitsFilter.IOwnProps) => ({
    currentFilters: state.LMSTO.students.filters['filter.completedUnits'],
    units: state.LMSTO.courses.values[props.cid].courseUnits
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: LMSTO.StudentsList.UnitsFilter.IOwnProps) => bindActionCreators({
    setStudentsFilters
}, dispatch);

export const cnClassName = cn('SessionsSearch');
// export default LMSTO