import {ComponentType, memo, ComponentProps} from 'react';
import {isEqual} from 'lodash';

export const memoizedComponent = <Component extends ComponentType<any>, Props extends ComponentProps<Component>> (Component: ComponentType<Props>, debug?: boolean) => memo(Component, (pp, np) => {
    const rez = isEqual(pp, np);
    if(debug) {
        console.log('rez', rez);
        console.log('pp', pp);
        console.log('np', np);
    }
    return rez;
});