import React, { FC } from 'react'
import { IChartProps, cnChart } from './Chart.index'
import './Chart.scss'
import PieChart from 'react-minimal-pie-chart';
import { tC } from 'LMSModule/utils/i18n.adaptor'

export const ChartPresenter: FC<IChartProps> = ({
    className,
    rightPercent,
    wrongPercent,
    waitModerationCount = 0,
    moderatedAnswersCount = 0,
    showAll = false
}) => {

    let data = [
        {
            color: '#89ca5e',
            title: tC('chart.rightPercent.1'),
            value: rightPercent
        },
        {
            color: '#f7434c',
            title: tC('chart.wrongPercent.1'),
            value: wrongPercent
        }
    ]
    if (waitModerationCount !== 0 && !showAll) {
        data = [
            {
                color: '#194776',
                title: tC('chart.waitModerationCount.1'),
                value: waitModerationCount
            },
            {
                color: '#6fb19d',
                title: tC('chart.moderatedAnswersCount.1'),
                value: moderatedAnswersCount
            }
        ]
    } else if (showAll) {
        data.push({
            color: '#194776',
            title: tC('chart.waitModerationCount.1'),
            value: waitModerationCount
        });
        data.push({
            color: '#6fb19d',
            title: tC('chart.moderatedAnswersCount.1'),
            value: moderatedAnswersCount
        })
    }
    return <PieChart
        className={cnChart({}, [className])}
        animate={true}
        animationDuration={500}
        animationEasing="ease-out"
        cx={50}
        cy={50}
        data={data}
        label={false}
        labelPosition={50}
        lengthAngle={360}
        lineWidth={43}
        onClick={undefined}
        onMouseOut={undefined}
        onMouseOver={undefined}
        paddingAngle={0}
        radius={50}
        rounded={false}
        startAngle={0}
        viewBoxSize={[
            140,
            140
        ]}
    />
}

export const Chart = ChartPresenter