import { NAdventCalendar, NAdventCalendarReducer } from "./types";
import { AdventCalendar } from 'utils/src/BaseTypes/adventCalendar.types';
import { v1 as uuid } from 'uuid';

export const baseCalendar: AdventCalendar = {
    id: '',
    name: '',
    dateFrom: '',
    dateTo: '',
    isDeleted: false
};

export const getBaseCalendar = (): typeof baseCalendar => ({
    ...baseCalendar,
    id: uuid()
});

export const baseAdventCalendarReducer: NAdventCalendar.Reducer = {};