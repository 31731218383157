import React, { FC, useEffect, memo } from 'react';
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces';
import { connect } from 'react-redux';
import './style.scss'
import { Button } from 'uielements/src';
import MDRender from 'uielements/src/CommonmarkRender';
import i18n from 'localizations/i18n';
import { withNewsContext } from '../../contexts/news'
import NewsHeader from '../NewsHeader';
import NewsActions from '../NewsActions';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { ViewComponent } from 'uielements/src/FieldRender';
import { Translate } from 'localizations/Translate';

type IPresenterProps = News.ShortView.IPresenterProps
type IOwnProps = News.ShortView.IOwnProps
type IDispatchProps = News.ShortView.IDispatchProps
type IStateProps = News.ShortView.IStateProps
type IState = News.ShortView.IState


export const Presenter: FC<IPresenterProps> = ({
    children,
    id,
    text,
    additionalFields,
}) => {

    let fields: any[] = [];
    if (additionalFields) additionalFields.additionalFieldsValues.forEach((category) => fields = [...fields, ...category.fields.filter(field => (field.showOnCardView && field.data))]);
    const commonCategory = [{ id: GUID_EMPTY, description: '', isCollapsed: 'false', title: '', fields }];


    return <div className={cnClassName({}, [])}>

        <div className={'NewsLayout'}>
            <NewsHeader />

            <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", margin: '24px' }}>
                <span style={{ color: "#999", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    <Translate i18nKey='pryaniky.post.shortview.description' />: </span>
                <MDRender source={text} type={'textOnly'} />
            </div>

            {!!fields.length && <div style={{ padding: '0 24px 24px 24px' }}>
                <ViewComponent
                fields={commonCategory as any}
                />
            </div>}

            <Button style={{ marginLeft: '24px' }} type="rlink" noBackground padding="md" href={"/news/" + id}>{i18n.t('pryaniky.ideas.more.btn')}</Button>

            <NewsActions disabledAction={['PRN_NEWS_TOGGLE_THANK_REPLY']} />
        </div>
        {/* <NewsComments /> */}
    </div>
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));
