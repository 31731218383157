import React, { createContext, useContext } from "react";


export const defaultContext = {
    zoom: 0.75
}

export const OrgContext = createContext(defaultContext)

export function useOrgContext() {
    return useContext(OrgContext);
}