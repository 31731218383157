import * as React from 'react';
import { connect } from 'react-redux';
import { cnSecretSantaPart, ISecretSantaPartProps, ISecretSantaPartDispatchProps, ISecretSantaPartStateProps, mapDispatchToProps, mapStateToProps, ISecretSantaPartState } from './SecretSantaPage.index';
import "./SecretSantaPage.scss";
import i18n from 'localizations/i18n';
import { Avatar, Button, ListLite, Loading } from "uielements/src";
import * as utils from 'utils/src/utils';
import StubListPlaceholder from '../../assets/svg/listPlaceholder.svg'
import MDRender from 'uielements/src/CommonmarkRender'
import { Translate } from 'localizations/Translate';
import { Header } from 'blocks/List/Header/List-Header';
import { secretSantaDialog } from '../Dialogs/SecretSantaDialog/SecretSantaParticipate';
import { DistributeTimer } from '../SecretSantaWidget/DistributeTimer/DistributeTimer';
import * as utilsProject from 'utils.project/utils.project'
import BaseRequests from 'utils/src/requests/requests.base';
import { ListItemSkeleton } from 'blocks/Skeletons/Lists/ListItem-Skeleton';
import { Button as MuiButton } from 'uielements/src/MaterialElements/Button/Button';
import { withRouter } from 'react-router-dom'

let interval: NodeJS.Timeout;

export class SecretSantaPartPresenter extends React.Component<ISecretSantaPartProps, ISecretSantaPartState> {
    public type = 'secretsanta';
    constructor(props: ISecretSantaPartProps) {
        super(props);
        // this.functionBind = this.functionBind.bind(this);
        // this.functionBind();
        this.state = {
            searchText: "",
            skipCount: 0,
            distibuted: null,
            teaserEnded: false,
            registrEnded: false,
            updateData: false,
            showUser: false,
            peopleCount: ""
        };
    }

    public componentDidMount() {
        // this.props.getSecretSantaWidgetSettingsAction({})
        this.props.mainSecretSantaSettingsAction(this.props.match.params.id)
    }


    public search = (text: string) => {
        this.props.SSPastisipantsListToggle({ data: { variable: 'isLoading', value: true }, id: this.props.match.params.id });
        this.props.setSSParticipantsListToRedux({ data: [], id: this.props.match.params.id });
        this.setState({ searchText: text });
        // this.getData()

    };

    public getData = () => {
        const count = 10;
        const skipCount = this.props.data?.length || this.state.skipCount;
        if (this.props.secretSantaSettings) {
            this.props.getSecretSantaParticipantsList({
                queryString: {
                    drawId: this.props.secretSantaSettings.id,
                    search: this.state.searchText,
                    count,
                    skipCount,
                }
            });
        }
        // this.changeDefaultViewType();
    };

    public componentDidUpdate(
        prevProps: Readonly<ISecretSantaPartProps>,
        prevState: Readonly<ISecretSantaPartState>,
        snapshot?: any) {
        if (prevState.searchText !== this.state.searchText) this.getData();
        // if (prevProps. !== this.props.searchText) this.getData();

        // это проверка на то что юзер не пришел ранее, если изначально его не было, то запускаем запрос юзера
        // if (prevState.distibuted !== this.state.distibuted &&
        if (this.props.getParticipate &&
            this.props.secretSantaSettings?.state === "Distributed" &&
            !this.props.userForPresent &&
            !this.props.isPresentSendData &&
            (this.props.secretSantaSettings && this.props.secretSantaSettings.id)) {
            interval = setInterval(() => this.props.getUserToGivePresent({
                queryString: {
                    drawId: this.props.secretSantaSettings && this.props.secretSantaSettings.id,

                }
            }), 60000);
        }

        if (prevState.updateData !== this.state.updateData) {
            if (this.state.updateData === false) {
                return;
            }
            setTimeout(() => {
                this.props.mainSecretSantaSettingsAction(this.props.match.params.id);
                this.setState({ updateData: false })
            }, 6000);
        }
        // останавливаем отправку запроса на получения юзера(getUserToGivePresent) если юзер получен this.props.isDistributLoading становится false
        if (prevProps.isDistributLoading !== this.props.isDistributLoading && this.props.isDistributLoading === false) {
            clearInterval(interval);
        }


    }

    public render() {
        const customClassNames = '';
        const { tag: TagName = 'div',
            children,
            className = '',
            data,
            isFinished,
            isLoading,
            context,
            secretSantaSettings,
            getParticipate,
            userForPresent,
            isDistributLoading,
            isPresentSendData,
            allSecretSantaSettings,
            noRight,
            getSecretSantaWidgetSettingsAction,
            getSecretSantaParticipantsList,
            secretSantaJoinAction,
            isUserParticipateAction,
            getUserToGivePresent,
            presentSentAction,
            secretSantaUnJoinAction,
            match,
            ...props } = this.props;
        const { searchText } = this.state
        const count = 10
        const skipCount = data?.length || 0
        if (noRight === 27009) return <div className={cnSecretSantaPart("stab")} >
            {/* {isFinished && data.length === 0 && <div className={cnSecretSantaPart("stab")} > */}
            <img src={StubListPlaceholder} alt="" />
            <h1> {i18n.t('pryaniky.secret.santa.no.rules.title')}</h1>
            <p>{i18n.t('pryaniky.secret.santa.no.rules.text')}</p>
        </div>;

        if (secretSantaSettings && !secretSantaSettings.isActive && (secretSantaSettings.state === 'Teaser' || secretSantaSettings.state === 'Participation')) return <div className={cnSecretSantaPart("stab")} >
            {/* {isFinished && data.length === 0 && <div className={cnSecretSantaPart("stab")} > */}
            <img src={StubListPlaceholder} alt="" />
            <h1> {i18n.t('pryaniky.secret.santa.not.active.title')}</h1>
            <p>{i18n.t('pryaniky.secret.santa.not.active.text')}</p>
        </div>;
        if (secretSantaSettings && secretSantaSettings.state === "Closed") return <div className={cnSecretSantaPart("stab")} >
            {/* {isFinished && data.length === 0 && <div className={cnSecretSantaPart("stab")} > */}
            <img src={StubListPlaceholder} alt="" />
            <h1> {i18n.t('pryaniky.secret.santa.closed.title')}</h1>
            <p>{i18n.t('pryaniky.secret.santa.closed.text')}</p>
        </div>;
        //     if(secretSantaSettings!.state === "Closed" ) return <TagName {...props} className={cnSecretSantaPart({}, [customClassNames, className])}  >
        //     <div className={cnSecretSantaPart("stab")}>
        //         <img src={StubListPlaceholder} alt="" />
        //         <h1> {i18n.t('pryaniky.list.secret.santa.no.santa.title')}</h1>
        //     </div>
        // </TagName> 
        // if (!data) return null;
        const msg = userForPresent && userForPresent.descriptionGift.deliveryComment.replace(/\<br\/?\>/g, '') || "";
        const descS = secretSantaSettings && secretSantaSettings.description.replace(/\<br\/?\>/g, '') || "";

        // const { pathname } = useLocation();
        // переменная для определения равильной формы слова 
        let people = "";
        if (this.props.secretSantaSettings && this.props.secretSantaSettings.participantsCount) {
            const participantsCount = this.props.secretSantaSettings?.participantsCount;
            if (participantsCount > 21) {
                const wordLength = participantsCount.toString().length
                const getLastNumber = Number(participantsCount.toString()[wordLength - 1])
                if (getLastNumber > 4 || getLastNumber === 0 || getLastNumber === 1) {
                    people = "plural";
                } else if (getLastNumber > 1 && getLastNumber < 5) {
                    people = "akk"
                }
            } else if ((participantsCount > 4 && participantsCount < 21) || participantsCount === 0 || participantsCount === 1) {
                people = "plural"
            } else if (participantsCount > 1 && participantsCount < 5) {
                people = "akk"
            }
        }
        // if (!allSecretSantaSettings) return null;
        return (
            secretSantaSettings ? <TagName {...props} >
                {/* className={cnSecretSantaPart({}, [customClassNames, className])} */}


                {secretSantaSettings && secretSantaSettings.id !== "" && <div className={cnSecretSantaPart("Header-Content")}>
                    <div className={cnSecretSantaPart('Header-Title')}>
                        <Avatar size={120} imgUrl={secretSantaSettings.iconUrl ? secretSantaSettings.iconUrl : BaseRequests.getUrl(`/Content/img/design/adminbase/secretSanta.svg`)} />
                        <div className={cnSecretSantaPart('Header-Title-Info')}>
                            <div className={cnSecretSantaPart('Header-Title-Info-Name')}>
                                {secretSantaSettings.displayName}
                            </div>
                            <div className={cnSecretSantaPart('Header-Title-Info-Description')}>
                                {/* {secretSantaSettings.description} */}
                                <MDRender source={descS || ''} />
                            </div>
                            {/* сообжение о том, что жеребъевка окончена появляется после того как юзер получен от сервера */}

                            {(secretSantaSettings.state === 'Distributed' && !this.state.showUser && !isDistributLoading && getParticipate) &&
                                <div className={cnSecretSantaPart('AdditionalInfo-DestributionOver')}>
                                    <Translate i18nKey='pryaniky.secret.santa.widget.distributionDate.over' />
                                </div>}
                            {(secretSantaSettings.state === 'Distributed' && isPresentSendData && isDistributLoading && getParticipate) &&
                                <div className={cnSecretSantaPart('AdditionalInfo-DestributionOver')}>
                                    <Translate i18nKey='pryaniky.secret.santa.widget.distributionDate.over.thaks' />
                                </div>}

                            {/* лоадинг до того как юзре будет действительно получен, должен срабатиывать в случае задержки от сервера */}
                            {(secretSantaSettings.state === 'Distributed' && !isPresentSendData && isDistributLoading && getParticipate) &&
                                <div className={cnSecretSantaPart('AdditionalInfo-DestributionOver-Loading')}>
                                    <Loading text={i18n.t("pryaniky.secret.santa.widget.distributionDate.over.loading")} />
                                </div>}

                            {this.state.showUser && <div className={cnSecretSantaPart('Header-TitlePresent')}>
                                <span><Translate i18nKey='pryaniky.secret.santa.widget.you.are.santa.for' /></span>
                            </div>}
                            {/* ТАЙМЕР */}
                            {(secretSantaSettings.state === 'Teaser' || secretSantaSettings.state === 'Participation' || secretSantaSettings.state === 'WaitingDistribution' || secretSantaSettings.state === "Distributed") && <div className={cnSecretSantaPart('Header-Title-Info-Description')}>

                                <span className={cnSecretSantaPart('Header-Title-Info-Description-Span')}>

                                    {secretSantaSettings.state === 'Teaser' && <Translate i18nKey='pryaniky.secret.santa.widget.teaser' />}
                                    {secretSantaSettings.state === 'Participation' && <Translate i18nKey='pryaniky.secret.santa.widget.participation' />}
                                    {secretSantaSettings.state === 'WaitingDistribution' && <Translate i18nKey='pryaniky.secret.santa.widget.waitingDistribution' />}

                                </span>
                                {this.state.updateData === true && <Loading text={" ."} />}
                                {secretSantaSettings.state === 'Teaser' && <DistributeTimer
                                    shouldByDistribute={this.isDistribute}
                                    distibDate={secretSantaSettings.startRegistrationDate} />}
                                {secretSantaSettings.state === 'Participation' && <DistributeTimer
                                    shouldByDistribute={this.isDistribute}
                                    distibDate={secretSantaSettings.endRegistrationDate} />}
                                {secretSantaSettings.state === 'WaitingDistribution' && <DistributeTimer
                                    shouldByDistribute={this.isDistribute}
                                    distibDate={secretSantaSettings.distributionDate} />}



                            </div>}

                            {(secretSantaSettings.state === 'Participation' || secretSantaSettings.state === 'WaitingDistribution') && <div className={cnSecretSantaPart('Header-Title-Info-Description')}>
                                <span className={cnSecretSantaPart('Header-Title-Info-Description-Span')}>
                                    <Translate i18nKey='pryaniky.secret.santa.widget.participantsCount' />
                                </span>
                                <span className={cnSecretSantaPart('Header-Title-Info-Description-participantsCount')}>
                                    {secretSantaSettings.participantsCount}
                                    {people === "plural" && <Translate i18nKey='pryaniky.secret.santa.widget.participants.plural' />}
                                    {people === "akk" && <Translate i18nKey='pryaniky.secret.santa.widget.participants.akk' />}
                                </span>
                            </div>}
                            {/* {(secretSantaSettings.state === 'Teaser' || secretSantaSettings.state === 'Participation')&& <div className={cnSecretSantaPart('Header-Title-Info-Description')}>
                                {/* <span> <Translate i18nKey='pryaniky.secret.santa.widget.distributionDate' /></span> {data.distributionDate} */}
                            {/* <span className={cnSecretSantaPart('Header-Title-Info-Description-Span')}>
                                    <Translate i18nKey='pryaniky.secret.santa.widget.distributionDate' />
                                </span>
                                {secretSantaSettings.distributionDate && <DistributeTimer shouldByDistribute={this.isDistribute} distibDate={secretSantaSettings.distributionDate} />}
                            // </div>} */}

                            {/* { secretSantaSettings.state === 'Participation' && <div className={cnSecretSantaPart('Header-Title-Info-Description')}>
                                <span className={cnSecretSantaPart('Header-Title-Info-Description-Span')}>
                                    <Translate i18nKey='pryaniky.secret.santa.widget.participantsCount' />
                                </span>
                                <span className={cnSecretSantaPart('Header-Title-Info-Description-participantsCount')}>
                                    {secretSantaSettings.participantsCount}
                                    {people === "plural" && <Translate i18nKey='pryaniky.secret.santa.widget.participants.plural' />}
                                    {people === "akk" && <Translate i18nKey='pryaniky.secret.santa.widget.participants.akk' />}
                                </span>
                            </div>} */}


                            {/* НАЧАЛО данные юзера которому дарят подарок */}
                            {this.state.showUser && !isPresentSendData && userForPresent && <div className={cnSecretSantaPart('UserAdditionalInfo')}>
                                <div className={cnSecretSantaPart('UserAdditionalInfo-Description')}>
                                    <Button
                                        type='rlink'
                                        theme="unstyled"
                                        href={'/user/' + userForPresent.user.id}
                                        target="_blank"
                                        className={cnSecretSantaPart('UserAdditionalInfo-Avatar')}>
                                        <Avatar size={32} shape={'circle'}
                                            imgUrl={userForPresent.user.userPhotoUrl}
                                        />
                                        <span>{userForPresent.user.displayName}</span>
                                    </Button>
                                </div>
                                <div className={cnSecretSantaPart('UserAdditionalInfo-Description')}>
                                    {userForPresent.descriptionGift.deliveryComment && <>
                                        <MDRender source={`“${msg}”`} />
                                    </>}

                                </div>
                                <div className={cnSecretSantaPart('UserAdditionalInfo-Info')}>
                                    <span> <Translate i18nKey='pryaniky.secret.santa.widget.deliveryAddress' /></span> <p>{userForPresent.descriptionGift.deliveryAddress}</p>
                                </div>

                                <div className={cnSecretSantaPart('UserAdditionalInfo-Info')}>
                                    <span> <Translate i18nKey='pryaniky.secret.santa.widget.deliveryPhone' /></span> <p>{userForPresent.descriptionGift.deliveryPhone}</p>
                                </div>
                            </div>}

                            {/* КОНЕц данные юзера которому дарят подарок */}


                            <div className={cnSecretSantaPart("ButtonBox")}>
                                {(secretSantaSettings.state === 'Distributed' && !this.state.showUser && !isDistributLoading && getParticipate) &&
                                    <MuiButton className={cnSecretSantaPart("SaveButton-Submit")}
                                        variant={'contained'}
                                        color={'primary'}
                                        size={'medium'}
                                        onClick={() => this.setShowUser()}>
                                        {i18n.t("pryaniky.secret.santa.widget.get.user.to.give.a.present")}
                                    </MuiButton>}
                                {(secretSantaSettings.state === 'Distributed' && this.state.showUser && !isPresentSendData) &&
                                    <MuiButton className={cnSecretSantaPart("SaveButton-Submit")}
                                        variant={'contained'}
                                        color={'primary'}
                                        size={'medium'} onClick={() => {
                                            utilsProject.confirm({
                                                text: i18n.t('pryaniky.secret.santa.widget.widget.would.hidden'),
                                                title: i18n.t('pryaniky.secret.santa.widget.widget.would.hidden.title'),
                                                onConfirm: () => {
                                                    presentSentAction(this.props.match.params.id)
                                                }
                                            })
                                        }}>
                                        {i18n.t("pryaniky.secret.santa.widget.present.send")}
                                    </MuiButton>}

                                {secretSantaSettings.state === 'Participation' && getParticipate !== null && !getParticipate && <MuiButton
                                    className={cnSecretSantaPart("SaveButton-Submit")}
                                    variant={'contained'}
                                    color={'primary'}
                                    size={'medium'}
                                    onClick={() => secretSantaDialog({ isPartitipant: false, id: this.props.match.params.id }).then(() => {
                                        secretSantaJoinAction(this.props.match.params.id)
                                    }).catch(() => { })

                                    }>
                                    {i18n.t("pryaniky.secret.santa.widget.save")}
                                </MuiButton>}
                                {secretSantaSettings.state === 'Participation' && getParticipate && <MuiButton
                                    className={cnSecretSantaPart("SaveButton-Submit")}
                                    variant={'contained'}
                                    color={'primary'}
                                    size={'medium'}
                                    onClick={() => secretSantaDialog({ isPartitipant: getParticipate, id: this.props.match.params.id }).then(() => {
                                        secretSantaJoinAction(this.props.match.params.id)
                                    }).catch(() => { })

                                    }>
                                    {i18n.t("pryaniky.secret.santa.widget.edit")}
                                </MuiButton>}
                                {/* кнопка отмены участия в санте */}
                                {secretSantaSettings.state === 'Participation' && getParticipate && <MuiButton
                                    className={cnSecretSantaPart('SaveButton-Unparticipate')}
                                    // variant={'outlined'}
                                    color={'default'}
                                    size={'medium'}
                                    // onClick={() => {
                                    //     secretSantaUnJoinAction()
                                    // }}
                                    onClick={() => {
                                        utilsProject.confirm({
                                            text: i18n.t('pryaniky.secret.santa.widget.unjoin.text'),
                                            title: i18n.t('pryaniky.secret.santa.widget.unjoin.title'),
                                            onConfirm: () => {
                                                secretSantaUnJoinAction(this.props.match.params.id)
                                            }
                                        })
                                    }}
                                >
                                    <Translate i18nKey='pryaniky.secret.santa.unparticipate' />
                                </MuiButton>}

                            </div>
                        </div>
                    </div>

                </div>}
                <div className={cnSecretSantaPart("Search")}>
                    {secretSantaSettings && secretSantaSettings.id !== "" && <Header
                        className={cnSecretSantaPart("Header-Search")}
                        type={'common'}
                        search={{
                            placeholder: i18n.t('pryaniky.list.secret.santa.search'),
                            onChange: this.search
                        }}
                    />}
                </div>


                {secretSantaSettings && secretSantaSettings.id !== "" && <ListLite className={cnSecretSantaPart()}
                    loadMore={this.getData}
                    isLoading={isLoading || false}
                    isFinished={isFinished || false}>
                    {/* this.getSecretSantaPart */}
                    {data.map((item: any, idx: number) =>
                        <div key={idx} className={cnSecretSantaPart("ListItem")}>

                            <div className={cnSecretSantaPart('ListItem-Image')}>
                                {/* <Button noBackground noBorder noPadding noAdminBasename type='rlink' href={`/user/${item.user.id}`}> */}
                                <Button key={0} theme="unstyled" type="rlink" href={'/user/' + item.user.id}>
                                    <Avatar className={cnSecretSantaPart('ListItem-Image-Icon')} size={56} imgUrl={item.user.imgUrl} imgId={item.user.imgId} name={item.user.displayName} />
                                </Button>

                                <div className={cnSecretSantaPart('ListItem-Content')} >

                                    {/* <Button className={cnSecretSantaPart('ListItem-Content-UserName')} noBackground noBorder noPadding noAdminBasename type='rlink' href={`/user/${item.user.id}`}> */}
                                    <Button key={0} className={cnSecretSantaPart('ListItem-Content-UserName')} theme="unstyled" type="rlink" href={'/user/' + item.user.id}>
                                        {item.user.displayName}
                                    </Button>
                                    {item.user.position !== '' && <div>
                                        {item.user.position}
                                    </div>}
                                    {item.user.division !== "" && <div>
                                        {item.user.division}
                                    </div>}
                                </div>
                            </div>

                        </div>
                    )}

                </ListLite>}
                {isFinished && data.length === 0 && <div className={cnSecretSantaPart("stab")} >
                    {/* {isFinished && data.length === 0 && <div className={cnSecretSantaPart("stab")} > */}
                    <img src={StubListPlaceholder} alt="" />
                    <h1> {i18n.t('pryaniky.list.secret.santa.no.list.participants.title')}</h1>
                    <p>{i18n.t('pryaniky.list.secret.santa.no.list.participants.text')}</p>
                </div>}
            </TagName> : <TagName {...props} className={cnSecretSantaPart({}, [customClassNames, className])}  >
                {(new Array(8)).fill(0).map(() => <ListItemSkeleton />)}
            </TagName>
        )
    }


    // private getSecretSantaPart = () => this.props.getSecretSantaPartDataFromServer({objectId: this.props.secretSantaId })
    protected searchChange = (text: string) => this.setState({ searchText: text });
    protected setShowUser = () => this.setState({ showUser: true });

    protected getUTCDateString = (dateString: string): string => {
        if (dateString === null) return '';
        if (dateString[dateString.length - 1] === 'z' || dateString[dateString.length - 1] === 'Z') return dateString;
        return dateString += 'z';
    }

    protected isDistribute = () => {
        const { secretSantaSettings } = this.props
        if (secretSantaSettings) {
            // let now = new Date()
            var today = new Date().valueOf()
            // var other = new Date(this.getUTCDateString(this.props.secretSantaSettings.distributionDate)).valueOf()
            // var other = new Date(this.getUTCDateString(secretSantaSettings.state === 'Teaser' ? secretSantaSettings.startRegistrationDate : secretSantaSettings.state === "Participation" ? secretSantaSettings.endRegistrationDate : secretSantaSettings.distributionDate)).valueOf()
            var distribution = new Date(this.getUTCDateString(secretSantaSettings.distributionDate)).valueOf();
            var teaserEnd = new Date(this.getUTCDateString(secretSantaSettings.startRegistrationDate)).valueOf();
            var registrationEnd = new Date(this.getUTCDateString(secretSantaSettings.endRegistrationDate)).valueOf();


            if ((teaserEnd < today) && secretSantaSettings.state === "Teaser") {
                this.setState({ updateData: true })
            }
            if ((registrationEnd < today) && secretSantaSettings.state === "Participation") {
                this.setState({ updateData: true })
            }
            if ((distribution < today) && secretSantaSettings.state === "WaitingDistribution") {
                this.setState({ updateData: true })

                //  !userForPresent нужно для того что бы проверить, не пришел ли юзер сразу с первым запросом, тогда лоадинг можно не перезапускать, и просто вывести юзера
                // с первым запросом юзер мог придти если страница перезапускалась после распределения 
                // !this.props.userForPresent && this.props.setUserDistributedToggleAction({ data: { variable: "isDistributLoading", value: true }, id: this.props.match.params.id })

            }
            if ((distribution < today) && secretSantaSettings.state === "Distributed") {
                // this.setState({ updateData: true })
                this.setState({ distibuted: true })

                //  !userForPresent нужно для того что бы проверить, не пришел ли юзер сразу с первым запросом, тогда лоадинг можно не перезапускать, и просто вывести юзера
                // с первым запросом юзер мог придти если страница перезапускалась после распределения 
                !this.props.userForPresent && this.props.setUserDistributedToggleAction({ data: { variable: "isDistributLoading", value: true }, id: this.props.match.params.id })

            }
        };
    }


}

export const SecretSantaPart = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecretSantaPartPresenter))


