import { ToolbarProps } from 'react-big-calendar'
import { cn } from '@bem-react/classname'
import { IStateType as IState } from 'redux/store';
import { bindActionCreators } from 'redux'
import { setCalendarCurrentDate, setCalendarCurrentView } from 'redux/calendar/actions'

export interface IToolbarProps extends ToolbarProps, ICuctomToolbarStateProps, ICuctomToolbarDispatchProps {
  context: any
  disableSearchParams?: boolean
}

export const mapStateToProps = (state: IState) => {
  return {
    loading: state.calendar.loading,
    currentDate: state.calendar.currentDate
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  setCalendarCurrentDate,
  setCalendarCurrentView,
}, dispatch);

export type ICuctomToolbarStateProps = ReturnType<typeof mapStateToProps>
export type ICuctomToolbarDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnCustomToolbar = cn('CustomToolbar')

