import { IButtonPopoverProps } from './ButtonPopover.index'
import * as React from 'react'
import { Button } from 'uielements/src/Button/Button';
import { Popover } from '../Popover/Popover'
import './ButtonPopover.scss';
import withLoadSpin from 'uielements/src/HOCs/Button/withLoadSpin'
const LoadButton = withLoadSpin(Button)
const buttonRender = (open: any, openSupport: any, label: any, isLoading: boolean = false) => {
    return <LoadButton theme='support' isLoading={isLoading} disabled={open} onClick={openSupport}>{label}</LoadButton>
}

export const ButtonPopover: React.FC<IButtonPopoverProps> = ({
    className,
    isLoading = false,
    button = buttonRender, children, anchor, label, accepted: acd = () => { }, openAction: action = () => { }, closeAction }) => {
    const [open, setOpen] = React.useState(false);
    const openSupport = () => {
        action && action();
        setOpen(true);
    }
    const close = () => {
        closeAction && closeAction();
        setOpen(false);
    }
    const onAccepted = () => {
        close()
        acd();
    }
    return (
        <>
            {button(open, openSupport, label, isLoading)}
            {/* <Button theme='support' disabled={open} onClick={openSupport}>{label}</Button> */}
            {(open && !isLoading) && <Popover clickOutside={close} className={className} anchor={anchor.current}>{children(close, onAccepted)}</Popover>}
        </>
    );
}