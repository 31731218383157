import { cn } from '@bem-react/classname';
import { IBodyProps } from 'utils/src/DialogCreator';

export const cnWorkflowsChangeResponsibleStatus = cn('WorkflowsChangeResponsibleStatus');

export interface IWorkflowsChangeResponsibleStatusProps extends IBodyProps, IWorkflowsChangeResponsibleStatusOwnProps, IMapStateToProps { }

interface IWorkflowsChangeResponsibleStatusOwnProps {
  newsId: string;
  data: any[];
  currentStatus: any;
}

export const mapStateToProps = (state: any, {newsId}: IWorkflowsChangeResponsibleStatusOwnProps) => ({

})

type IMapStateToProps = ReturnType<typeof mapStateToProps>;