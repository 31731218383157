import React from 'react';
import i18n from '../../../localizations/i18n';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';

// GlobalControl
export const control = (props: any) => {
  const { data, uischema, schema, path, visible, dispatch } = props as ControlProps & DispatchProp;
  if (!visible) return null;
  const variableSchema = (uischema.scope as string).substr(2).split('/').reduce((acc: any, cur) => { return acc[cur] }, schema);
  // let variableUISchema = uischema;
  const variable = (uischema.scope as string).substr(2).split('/')[(uischema.scope as string).substr(2).split('/').length - 1];
  // const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  if (variableSchema.type === 'boolean') {
    return <UIElements.CheckboxInput onChange={(ev) => dispatch(Actions.update(variable, (oldData) => ev.currentTarget.checked))} text={(uischema.label || '').toString()} checked={data[variable]} />
  }
  if (variableSchema.type === 'string') {
    return <UIElements.Input onChange={(ev) => dispatch(Actions.update(variable, (oldData) => ev.currentTarget.value))} placeholder={(uischema.label || '').toString()} defaultValue={data[variable]} />
  }
  return <div children={['UNKNOWN CONTROL ;)']} />
}
// end GlobalControl