import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
  loadKPIForUser,
  createKPIItem
} from '../../redux/actions'
import {
} from '../../redux/reducer/types'
import {
    getKPIParams,
    getKeysForUser
} from '../../redux/saga/selectors'


///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace KPI {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Main {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps {
            userId: string
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = KPI.Main.IOwnProps


export const mapStateToProps = (state: any, { userId }: IOwnProps) => ({
    ...getKPIParams(['values', 'isLoading', 'allowAdd'])(state),
    keys: getKeysForUser(userId)(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadKPIForUser,
    createKPIItem
}, dispatch);

////////////

export const cnClassName = cn('KPIMain');