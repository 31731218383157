import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'

import User from '../Users'
import Courses from '../Courses'
import Game from '../Game'

type IPresenterProps = StatisticsFull.StatView.IPresenterProps
type IOwnProps = StatisticsFull.StatView.IOwnProps
type IDispatchProps = StatisticsFull.StatView.IDispatchProps
type IStateProps = StatisticsFull.StatView.IStateProps
type IState = StatisticsFull.StatView.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    match
}) => {
    const screens: any = {
        users: <User />,
        courses: <Courses />,
        game: <Game />,
    }
    return <div className={cnClassName({}, [className])}>
        {screens[match.params.id]}
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));
