import { IDialog } from "../../DialogWrapper.index";
import { cn } from '@bem-react/classname';
import { IComponentAcceptor } from 'utils/src/ComponentInjector'
import { IWSettingsOnlyProps } from '../../../WSettings/WSettings.index';

export interface IWidgetsSettingsProps extends IDialog, IComponentAcceptor<IWSettingsOnlyProps> { }

export interface IWidgetsSettingsState {
  isValid: boolean;
}

export const cnWidgetsSettings = cn('WidgetsSettings');