import { INoticesPropsType, mapStateToProps, TStateProps } from '../Notices.index'
import React, { memo, FC } from 'react';
import { connect } from 'react-redux';
import '../Notices.scss'
import { withNewsContext } from 'News/contexts/news'
import TextRender from 'News/containers/TextRender'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import ContentLayout from 'News/components/ContentLayout'
import MDRender from 'uielements/src/CommonmarkRender';

export const NoticesPresenter: FC<INoticesPropsType & TStateProps> = ({ text }) => <NewsView className={'type-22'}>
    <ContentLayout>
        <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            <TextRender />
        </div>
    </ContentLayout>
</NewsView>

export const Notices = withNewsContext(connect(
    mapStateToProps
)(memo(NoticesPresenter)));

export const extendsNotices = () => {
    // ComponentInjector.getInstance().extendNode('news_types', <Notices />, 'notices', 'mini')
    // ComponentInjector.getInstance().extendNode('news_types', <Notices />, 'notices', 'content')
}