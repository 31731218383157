import { IWorkflowsPropsType, mapStateToProps, TStateProps, classname } from './Workflows.index'
import React, { FC, memo, useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import './Workflows.scss'
import { withNewsContext } from 'News/contexts/news'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import TextRender from 'News/containers/TextRender'
import ContentLayout from 'News/components/ContentLayout'
import { ViewComponent, IAdditionalField, prepareFromServer, prepareToServer } from 'uielements/src/FieldRender'
import { Button } from 'uielements/src'
import i18n from 'localizations/i18n';
import MDRender from 'uielements/src/CommonmarkRender'
import { ActionsBox } from './ActionsBox/ActionsBox';

import { useParams } from 'react-router-dom';

export const WorkflowsPresenter: FC<IWorkflowsPropsType & TStateProps> = ({ additionalFields, isSingle, text, newsId, renderParams, workflowId}) => {
   
    const [full, setFull] = useState(isSingle)
    const clearFields = additionalFields?.additionalFieldsValues?.reduce((acc: any, category: any) => [...acc, { ...category, fields: category.fields.filter((field: any) => !field.defaultFieldName) }], [])
   
    const fields = useMemo(() => prepareFromServer(clearFields || []), [additionalFields?.additionalFieldsValues])
    const changeFull = useCallback(() => setFull(!full), [full])
    return <NewsView className={classname('', ['type-5'])}>
        <ContentLayout className={classname('Content')}>
            
            <MDRender className={classname('Description')} textCut={false} source={text} />

            <ViewComponent className={classname('Fields')} fields={fields} /> 

            <ActionsBox newsId={newsId} workflowId={workflowId || ''} />

        </ContentLayout>
    </NewsView>
}

export const Workflows = withNewsContext<IWorkflowsPropsType>(connect(
    mapStateToProps
)(memo(WorkflowsPresenter)), ['newsId', 'isSingle']);


export const initWorkflows = () => ComponentInjector.getInstance().addNode('news_types', <Workflows />, 'workflow')