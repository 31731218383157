import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';

export const cnQuickLinks = cn('QuickLinks');

export namespace NQuickLinks {

    export interface Item {
        id: string;
        file: { [ s:string ]: any } | null;
        imgUrl: string;
        text: string;
        showLinkText: boolean;
        url: string;
        openInNewTab: boolean
    }

    export interface Props extends IClassNameProps {
        data: Item[];
    }
}