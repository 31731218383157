import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { Skeleton } from 'uielements/src';
import i18n from 'localizations/i18n';

import { cnVirtcurrency, cnVirtcurrencyNew, IVirtcurrencyProps, mapStateToProps, mapActionsToProps } from './Virtcurrency.index';
import './Virtcurrency.scss';
import { Icon } from 'uielements/src/Icon/Icon';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import IconMUI from '@material-ui/core/Icon';
import GiveMoney from './GiveMoney.svg'
import { Wallets } from 'blocks/Wallets/Wallets';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { Box, Button as MuiButton, Typography } from 'muicomponents/src'
import CanGive from './CanGive.svg';
import CanShop from './CanShop.svg';
import Team from './Team.svg';
import { CustomSettings } from 'utils/src';
import { TypographyText, TypographyCurrency, ContentBox } from './style'
import CardMedia from '@mui/material/CardMedia';
import { getUserById } from 'utils/src/CommonRedux/users/selectors';
import { useDispatch, useSelector } from 'react-redux';

const useMoexDesign = CustomSettings.useMoexDesign();

// export const VirtcurrencyPresenter: React.FC<IVirtcurrencyProps> = ({
//   mythanksCount: myThanks,
//   thanksForAll,
//   tag: Tag = 'div'
// }) => (
//   <Tag>
//     <div className={cnVirtcurrency('Block')}>
//       <h4 className={cnVirtcurrency('Header')}>{i18n.t('pryaniky.widgets.virtcurrency.forSpend')}</h4>
//       <Icon icon="wallet" className={cnVirtcurrency('Icon')} />
//       <span className={cnVirtcurrency('Count')}>{myThanks}</span>
//     </div>
//     <div className={cnVirtcurrency('Block')}>
//       <h4 className={cnVirtcurrency('Header')}>{i18n.t('pryaniky.widgets.virtcurrency.forGive')}</h4>
//       <Icon icon="give" className={cnVirtcurrency('Icon')} />
//       <span className={cnVirtcurrency('Count')}>{thanksForAll}</span>
//     </div>
//   </Tag>
// );

export class VirtcurrencyPresenter extends React.Component<IVirtcurrencyProps, {}> {
  private hideForAll: boolean = false;

  constructor(props: IVirtcurrencyProps) {
    super(props);
    const { context } = this.props;
    this.hideForAll = context.fontsize === 'hideForAll';
  }

  public componentDidMount() {
    const { context } = this.props;
    if ((context.integrated || useMoexDesign) && context.uId) {
      this.props.updateContext('common', {
        mythanksCount: -1,
        thanksForAll: -1,
        thanksForSubordinate: -1,
      });

      utils.API.users.byId(context.uId, { fullData: true }).r.then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          this.props.updateContext('common', {
            mythanksCount: d.data.extData.mythanksCount,
            thanksForAll: d.data.extData.thanksForAll,
            thanksForSubordinate: d.data.extData.thanksForSubordinate,
          });
        } else {
          // toast.error('something wrong')
        }
      });
    }

  }

  public render() {
    const customClassNames = '';
    const {
      tag: TagName = 'div',
      children,
      className = '',
      authUser,
      mythanksCount,
      thanksForAll,
      context,
      additionalColor1
    } = this.props;
    const additionalProps: { [s: string]: any } = {
      hide: false,
    };

    // const user = useSelector(getUserById(authUser.baseData.id === context.uId ? authUser.baseData.id : context.uId, true))
    const collection = authUser.extData.thanksForAllByCollection || {};
    const collectionThanksForSubordinate = authUser.extData.thanksForSubordinate;
    const collectionKeys = Object.keys(collection)
    const props = { ...this.props };
    const hideForAll = context.fontsize === 'hideForAll' // магия для интеграции


    const userControl = authUser.baseData.isAdmin || !context.uId || authUser.baseData.id === context.uId || decodeURI(context.uId).replace(' ', '\\').split('\\').map(el => el.toLowerCase()).includes(authUser.baseData.externalUserId.toLowerCase())

  
    if (!authUser) return null;

    if (useMoexDesign) {
      return <TagName {...props} {...additionalProps} className={cnVirtcurrencyNew({}, [customClassNames, className])}>
        <Typography
          variant="subtitle1"
          className={cnVirtcurrencyNew('Name')}
          children={i18n.t('pryaniky.widgets.virtcurrency.my.mems')} />

        <ContentBox sx={{ paddingBottom: "8px" }} className={cnVirtcurrencyNew('ContentBox')}>

          {userControl && (
            <Box className={cnVirtcurrencyNew('Block')}>


              <Box className={cnVirtcurrencyNew('Content')}>
                <CardMedia
                  component="svg"
                  className={cnVirtcurrencyNew("CardArticleItem-Avatar")}
                  image={CanGive}
                />
                <Box className={cnVirtcurrencyNew('Text')}  >
                  <TypographyText
                    variant="caption"
                    className={cnVirtcurrencyNew('Text-Count')}
                    children={i18n.t('pryaniky.widgets.virtcurrency.forGive.mems')}
                  />
                  <TypographyCurrency
                    variant="h5"
                    className={cnVirtcurrencyNew('Text-Count2', { loading: context.mythanksCount === -1 })}
                  >
                    {typeof context.thanksForAll === "number" ? (
                      context.thanksForAll >= 0 ? (
                        context.thanksForAll
                      ) : (
                        <Skeleton size="md" shape="line" style={{ width: '100%' }} />
                      )
                    ) : (
                      thanksForAll
                    )}
                  </TypographyCurrency>
                </Box>
              </Box>

            </Box>
          )}
          {userControl && (
            <Box className={cnVirtcurrencyNew('Block')}>
              <Box className={cnVirtcurrencyNew('Content')}>
                <CardMedia
                  component="svg"
                  className={cnVirtcurrencyNew("CardArticleItem-Avatar")}
                  image={Team}
                />
                <Box className={cnVirtcurrencyNew('Text')}  >
                  <TypographyText
                    variant="caption"
                    className={cnVirtcurrencyNew('Text-Count')}
                    children={i18n.t('pryaniky.widgets.virtcurrency.forGive.Team.mems')}
                  />
                  <TypographyCurrency
                    variant="h5"
                    className={cnVirtcurrencyNew('Text-Count2', { loading: context.mythanksCount === -1 })}
                  >
                    {typeof context.thanksForSubordinate === "number" ? (
                      context.thanksForSubordinate >= 0 ? (
                        context.thanksForSubordinate
                      ) : (
                        <Skeleton size="md" shape="line" style={{ width: '100%' }} />
                      )
                    ) : (
                      collectionThanksForSubordinate
                    )}
                  </TypographyCurrency>
                </Box>
              </Box>

            </Box>
          )}

        </ContentBox>

        <ContentBox className={cnVirtcurrencyNew('ContentBox')}>
          {
            !hideForAll &&
            <Box className={cnVirtcurrencyNew('Block')}>
              {/* <h4 className={cnVirtcurrencyNew('Header')}>{i18n.t('pryaniky.widgets.virtcurrency.forGive')}</h4> */}
              <Box className={cnVirtcurrencyNew('Content')}>

                <CardMedia
                  component="svg"
                  className={cnVirtcurrencyNew("CardArticleItem-Avatar")}
                  image={CanShop}

                />
                <Box className={cnVirtcurrencyNew('Text')} >
                  <TypographyText
                    variant="caption"
                    className={cnVirtcurrencyNew('Text-Count')}
                    children={i18n.t('pryaniky.widgets.virtcurrency.forSpend.mems')}
                  />

                  <TypographyCurrency
                    variant="h5"
                    className={cnVirtcurrencyNew('Text-Count2', { loading: context.mythanksCount === -1 })}
                  >
                    {typeof context.mythanksCount === "number" ? (
                      context.mythanksCount >= 0 ? (
                        context.mythanksCount
                      ) : (
                        <Skeleton size="md" shape="line" style={{ width: '100%' }} />
                      )
                    ) : (
                      mythanksCount
                    )}
                  </TypographyCurrency>
                </Box>
              </Box>
            </Box>
          }

          <MuiButton
            color='primary'
            className={cnVirtcurrencyNew('Title-GoToShopBTN')}
            variant={'contained'}
            href={`/shop`}
          >
            {i18n.t('pryaniky.widgets.virtcurrency.shop')}
          </MuiButton>
        </ContentBox>


      </TagName>
    }
    return (
      <TagName {...props} {...additionalProps} className={cnVirtcurrency({}, [customClassNames, className])}>
        {(authUser.baseData.isAdmin || !context.uId || authUser.baseData.id === context.uId || decodeURI(context.uId).replace(' ', '\\').split('\\').map(el => el.toLowerCase()).includes(authUser.baseData.externalUserId.toLowerCase())) && (
          <div className={cnVirtcurrency('Block')}>
            <h4 className={cnVirtcurrency('Header')}>{i18n.t('pryaniky.widgets.virtcurrency.my.finances')}</h4>
            <div className={cnVirtcurrency('Content')}>
              {/* <Icon icon="wallet" className={cnVirtcurrency('Icon')} /> */}
              <IconMUI color={"action"} className={cnVirtcurrency('Icon')} > <AccountBalanceWalletIcon color={"action"} /> </IconMUI>
              <div className={cnVirtcurrency('Text')}>
                <span className={cnVirtcurrency('Text-Count')}>{i18n.t('pryaniky.widgets.virtcurrency.forSpend')}</span>
                <span className={cnVirtcurrency('Text-Count2', { loading: context.mythanksCount === -1 })}>
                  {context.mythanksCount ? (
                    context.mythanksCount >= 0 ? (
                      context.mythanksCount
                    ) : (
                      <Skeleton size="md" shape="line" style={{ width: '100%' }} />
                    )
                  ) : (
                    mythanksCount
                  )}
                </span>
              </div>
            </div>
          </div>
        )}
        {
          !hideForAll &&
          <div className={cnVirtcurrency('Block')}>
            {/* <h4 className={cnVirtcurrency('Header')}>{i18n.t('pryaniky.widgets.virtcurrency.forGive')}</h4> */}
            <div className={cnVirtcurrency('Content')}>
              <IconMUI color={"action"} className={cnVirtcurrency('Icon')} >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 5C16 6.65685 14.6569 8 13 8C11.3431 8 10 6.65685 10 5C10 3.34315 11.3431 2 13 2C14.6569 2 16 3.34315 16 5ZM5 11H1V22H5V11ZM20 17H13L10.91 16.27L11.24 15.33L13 16H15.82C16.47 16 17 15.47 17 14.82C17 14.33 16.69 13.89 16.23 13.71L8.97 11H7V20.02L14 22L22.01 19C22 17.9 21.11 17 20 17ZM19 13C20.6569 13 22 11.6569 22 10C22 8.34315 20.6569 7 19 7C17.3431 7 16 8.34315 16 10C16 11.6569 17.3431 13 19 13Z"
                    fill={additionalColor1 || "black"}
                    fill-opacity={additionalColor1 ? "1" : "0.54"} />
                </svg>
              </IconMUI>
              {/* <IconMUI color={"action"} className={cnVirtcurrency('Icon')} > <img src={GiveMoney} alt="" /></IconMUI> */}
              <div className={cnVirtcurrency('Text')} >
                <span className={cnVirtcurrency('Text-Count')}>{i18n.t('pryaniky.widgets.virtcurrency.forGive')}</span>
                <span className={cnVirtcurrency('Text-Count2', { loading: context.thanksForAll === -1 })}>
                  {context.thanksForAll ? (
                    context.thanksForAll >= 0 ? (
                      context.thanksForAll
                    ) : (
                      <Skeleton size="md" shape="line" style={{ width: '100%' }} />
                    )
                  ) : (
                    thanksForAll
                  )}
                </span>
              </div>
            </div>
          </div>
        }

        {collectionKeys.map((key: string) => <div className={cnVirtcurrency('Block')}>
          {/* <h4 className={cnVirtcurrency('Header')}></h4> */}
          <div className={cnVirtcurrency('Content')}>
            <IconMUI color={"action"} className={cnVirtcurrency('IconColl')} > <Icon icon="gem" /> </IconMUI >
            <div className={cnVirtcurrency('Text')}>
              <span className={cnVirtcurrency('Text-Count')}>{collection[key].name}</span>
              <span className={cnVirtcurrency('Text-Count2')}>
                {collection[key].value}
              </span>
            </div>
          </div>
        </div>)}
        <Wallets uid={authUser.baseData.id} />
        {children}
      </TagName>
    );
  }
}

export const Virtcurrency = connect(mapStateToProps, mapActionsToProps)(VirtcurrencyPresenter);