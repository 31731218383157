import React from 'react';
import { cnMountainRating } from '../MountainRating.index';
import background from '../background.jpg';
import backgroundSmall from '../background-small.jpg';

export const Background: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const handleLoad = (e: any) => {
    if (e.target.getAttribute('src') === backgroundSmall) {
      e.target.setAttribute('src', background);
    } else {
      setLoading(false);
    }
  };
  return <img src={backgroundSmall} className={cnMountainRating('Background', { loading })} onLoad={handleLoad} />;
};
