import Component, { Presenter } from './component';
import {
    IMeetingViewOwnProps,
    IMeetingViewPresenterProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = IMeetingViewOwnProps;
export type IComponentPresenterProps = IMeetingViewPresenterProps;


export default Component