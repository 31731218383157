import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';
import { AnyAction } from 'redux';

// export interface IStoreToggle extends AnyAction {
//   payload: {
//     variable: string;
//     value?: boolean;
//   }
// }

let actions = {
  GET_QUERY: '',
  SET_ACTIVE_TAB: ''
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.SEARCH) }), {} as typeof actions);