import { cn } from '@bem-react/classname';
import { IMountainRatingUserInfo } from '../MountainRating.index';

export const cnBar = cn('Bar');

export interface IBarProps {
  data?: IMountainRatingUserInfo;
  width: number;
  isMe: boolean;
  containerHeight: number;
  paddingTop: number;
  type?: 'Common' | 'Group';
  minHeight: number;
}
