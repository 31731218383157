import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type ITestInfoLayoutProps = ITestInfoLayoutOwnProps

export interface ITestInfoLayoutState { }

export interface ITestInfoLayoutOwnProps extends IClassNameProps {
    name: string,
    description: string,
    fullscreen?: boolean,
    start: () => void,
    allowSkip?: boolean,
    skip: () => void
    children?: React.ReactNode
    unitType?: 'test' | 'task'
}

export const cnClassName = cn('LMSTestInfoLayout');