import React, { useState, useRef, useEffect } from 'react';
import { IEditorTypeNewsProps } from './news.index';
import { LongreadNews, defaultLongreadNews } from '../../../Longread.type'
import { BaseValidator, baseValidate } from '../../../../Base.validate'
import { Editor } from '../../Editor'
import * as utils from 'utils/src/utils';
import { EditorState, RawDraftContentState, convertToRaw, convertFromRaw } from 'draft-js';
import { useDebounce } from 'utils/src/hooks'
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
// import draftToMarkdown from 'uielements/src/PryanikyEditorV1/converter/draft-to-md'
import { rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
import { IField } from 'uielements/src/FieldRender/interfaces'
import {
    prepareFromServer
} from 'uielements/src/FieldRender'
import { NodeBulder } from 'uielements/src/CommonmarkRender'

const createShortDraft = (state: EditorState) => {
    const draftState = convertToRaw(state.getCurrentContent())
    const text = draftToMarkdown(draftState, rawToMd);
    const NB = new NodeBulder(text || '')
    const newRaw = NB.getDraftRaw()
    return EditorState.createWithContent(convertFromRaw(newRaw))
}

export const EditorTypeNews: React.FC<IEditorTypeNewsProps> = ({ onChange, data, types }) => {
    const [autoShort, setAutoShort] = useState(true)
    const autoShortRef = useRef(autoShort)
    autoShortRef.current = autoShort
    let localData = data as any;
    // if (data.newstype !== 40) {

    // }
    useEffect(() => {
        localData = {
            ...utils.cloneObject(defaultLongreadNews),
            newstype: types.idNumber,
            text: EditorState.createEmpty(),
            additionalFields: {
                additionalFieldsValues: prepareFromServer(types.additionalFields)
            }
        };
        onChange(localData, false);

    }, [types.idNumber !== data.newstype])
    

    const onChangeText = (text: string) => {
        localData.text = text;
        const bv = new BaseValidator<any>()
        bv.setRules(baseValidate)
        bv.addData(localData)
        onChange(localData, bv.validation().length === 0);
    }

    const onChangeMain = (text: string) => {
        // setAutoShort(false)
        onChangeText(text)
    }

    const changeMd = useDebounce((raw: EditorState) => {
        // const text = draftToMarkdown(raw, rawToMd);
        onChangeText(createShortDraft(raw) as any)
    }, 600)

    const onChangeDraft = (fields: IField.AdditionalField[]) => {
        // types
        localData.additionalFields.additionalFieldsValues = fields
        const emptyRaw = EditorState.createEmpty()

        const content = fields.reduce((ac, c) => ac ? ac : c.fields.reduce((af, f) => f.isBaseField && f.name === 'Text' && !af ? f.data : af, null), null)
        autoShortRef.current && changeMd(content || emptyRaw)
        // // localData.text = uuid();
        onChange(localData, true);
    }

    const toggleAutoShort = () => setAutoShort(!autoShort)

    if (types.idNumber !== data.newstype) return null
    
    return <Editor
        key={types.idNumber}
        additionalFields={localData.additionalFields.additionalFieldsValues}
        changeAdditional={onChangeDraft}
        // draftState={localData.additionalFields[0]?.fields[0]?.draft || EditorState.createEmpty()}
        allowRichEdit={types.newsRenderParams?.allowRichEdit}
        onChangeText={onChangeMain}
        autoShort={autoShort}
        setAutoShort={setAutoShort}
        data={localData} />
}