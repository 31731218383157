import { ICommentProps, mapDispatchToProps } from './StageChangeForm.index'
import * as React from 'react'
import './StageChangeForm'
// import { loadFields } from '../IdeasSides'
import { StageComment } from '../StageComment/StageComment';
import { AdditionalFieldsRender } from '../AdditionalFieldsRender/AdditionalFieldsRender';
import { ActionButtons } from '../../../ActionButtons/ActionButtons'
import { connect } from 'react-redux';
import { saveStatus } from '../redux/actions'
import { useLazyExecutSaga } from 'utils/src/CommonRedux/LoadIndicator'
import { useQuery } from 'utils/src/hooks'
import {
    API
} from 'utils/src/utils'
import { denormalize, normalize, schema } from 'normalizr';
import { statusFieldsExtractor } from '../Utils'

const CommentPresenter: React.FC<ICommentProps> = ({ ideaId, status, save, cancle, pathNews, addComments, upgradeNews }) => {
    const [comment, setComment] = React.useState('');
    const { dispatchAction } = useLazyExecutSaga(() => saveStatus({ comment, ideaId, statusID: status.id }), [comment])
    const onSave = () => {
        dispatchAction()
        save();
    }

    return <div className={'Comment'}>
        <div>
            <StageComment value={comment} onChange={setComment} />
        </div>
        <ActionButtons save={onSave} cancle={cancle} />
    </div>
}

const checkIsEmptyFiled = (field: any) => {
    if (field.field.fieldType === "ResponsibleForStatusUser") {
        return (field.users || []).length === 0
    } if (field.field.fieldType === "OfferResponsibleForStatusUsers") {
        return (field.users || []) === 0
    } if (field.field.fieldType === "UserNotExpert") {
        return field.value !== "true"
    } if (field.field.fieldType === "Tags") {
        return (field.tags || []) === 0
    } if (field.field.fieldType === "Textarea") {
        return !field.value
    } if (field.field.fieldType === "Multi") {
        return (field.values || []) === 0
    } if (field.field.fieldType === "Single") {
        return !field.value
    } if (field.field.fieldType === "Date") {
        return !field.value
    } if (field.field.fieldType === "DateTime") {
        return !field.value
    } if (field.field.fieldType === "Int") {
        return !field.value
    } if (field.field.fieldType === "String") {
        return !field.value
    } if (field.field.fieldType === "MatrixOnePerRow") {
        if (!field.rowValues) return true
        return Object.values(field.rowValues).length !== field.field.rows.length
    }
    return false
}


const CommentWithAdditionalPresenter: React.FC<ICommentProps> = ({ ideaId, status, save, cancle, pathNews, addComments, upgradeNews }) => {

    const [comment, setComment] = React.useState('');
    const [
        isLoading,
        fields,
        isError,
        count,
        setFields,
    ] = useQuery(async function () {
        return await (await API.news.ideas.setStatus(ideaId, status.id)).r
    }, [], statusFieldsExtractor, { entities: {}, result: [] })

    const { dispatchAction } = useLazyExecutSaga(() => saveStatus({ comment, ideaId, statusID: status.id, fields }), [comment, fields])

    const onSave = () => {
        dispatchAction()
        save();
    }

    const onChange = (field: any) => {
        setFields((s: any) => {
            s.entities.fields[field.fieldId] = field;
            return { ...s };
        })
    }

    let disableSave = isLoading;
    disableSave = disableSave || Object.values(fields.entities.fields || {}).filter((v) => v.field.isMandatory).some((v) => checkIsEmptyFiled(v))

    return <div className={'CommentWithAdditional'}>
        <div><StageComment value={comment} onChange={setComment} /></div>
        <div><AdditionalFieldsRender data={fields} onChange={onChange} /></div>
        <ActionButtons saveDisabled={disableSave} save={onSave} cancle={cancle} />
    </div>
}


export const Comment = connect(undefined, mapDispatchToProps)(CommentPresenter);
export const CommentWithAdditional = connect(undefined, mapDispatchToProps)(CommentWithAdditionalPresenter);