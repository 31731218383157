import React, { FC, useState, useEffect } from 'react'
import {
    cnClassName,
    IAddMaterialsOwnProps,
    IAddMaterialsResult,
    IAddMaterialsPresenterProps,
    IAddMaterialsStateProps,
    IAddMaterialsDispatchProps,
    mapDispatchToProps,
    mapStateToProps
} from './interfaces'
import './style.scss'
import { createModal, TransitionComponent } from '../../dialogs/modal'
import DialogBody from '../../dialogs/modal/components/DialogBody'
import SingleAutocomplete from 'uielements/src/MaterialElements/SingleAutocomplete'
import withTrackSuggester from 'blocks/HOCs/SingleAutocomplete/withTrackSuggester'
import { connect } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    Button,
    Icon,
    CheckboxInput,
    Textarea, Spinner
} from 'uielements/src';
import Tabs, { ITabType } from 'uielements/src/TabsV2'
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import TextField from '@material-ui/core/TextField';
import { useAddFile } from 'LMSModule/utils/hooks/useAddFile'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import NewsUrlSelector from 'News/components/NewsUrlSelector/NewsUrlSelector'
import { PageFinder } from 'uielements/src/WikiParts/WikiFinder/WikiFinder'


const LoadBtn = withLoadSpin(Button)
const TrackSelect = withTrackSuggester(SingleAutocomplete)


export const Presenter: FC<IAddMaterialsPresenterProps> = ({
    handleAccept,
    handleClose,
    className,
    trackId,
    ...other
}) => {
    const tabs: ITabType[] = [
        {
            content: tTr('dialog.add_materials.tab.news'),//'Новости',
            value: 'News'
        },
        {
            content: tTr('dialog.add_materials.tab.courses'),//'Курсы',
            value: 'LMSCources'
        },
        {
            content: tTr('dialog.add_materials.tab.pages'),//'Страницы',
            value: 'PagesV2'
        },
        {
            content: tTr('dialog.add_materials.tab.viktorina'),//'Викторина',
            value: 'Quizzes'
        }

    ]
    const [selectedTab, setTab] = useState(tabs[0])
    const [currentItem, changeCurrentItem] = useState<any>(null)
    const [currentUid, setCurrentUid] = useState<string | null | undefined>(null)
    const [isLoading, addFile] = useAddFile(trackId, selectedTab.value)

    const accept = () => {
        currentUid && addFile(currentUid)
        handleAccept({
        })
    }

    return <div>
        <DialogTitle onClose={handleClose}>
            <h4 className="my-1">{tTr('dialog.add_materials.title')}</h4>
        </DialogTitle>
        <div className={cnClassName({}, [className])}>

            <Tabs
                className={cnClassName('Tabs')}
                onChange={(tab) => {
                    setCurrentUid(null)
                    changeCurrentItem(null)
                    setTab(tab)
                }}
                selected={selectedTab}
                tabs={tabs}
            />

            {selectedTab.value === 'Quizzes' && <FormControl fullWidth>
                <Autocomplete
                    // disabled={isLoading}
                    // allowCreate={!field.selectOnlyFromCollection}
                    // fullWidth
                    type={'quizzes'}
                    // col={'profile'}
                    // fieldId={fieldId}
                    multiple={false}
                    label={tTr('dialog.add_materials.tab.viktorina')}
                    value={currentItem}
                    onChange={(item: any) => {
                        setCurrentUid(item?.id)
                        changeCurrentItem(item)
                    }}
                />

            </FormControl>}

            {selectedTab.value === 'PagesV2' && <PageFinder
                currentItem={currentItem}
                changeCurrentItem={(item: any) => {
                    setCurrentUid(item?.id)
                    changeCurrentItem(item)
                }}
            />}

            {selectedTab.value === 'LMSCources' && <FormControl fullWidth>
                <Autocomplete
                    // disabled={isLoading}
                    // allowCreate={!field.selectOnlyFromCollection}
                    // fullWidth
                    type={'courses'}
                    // col={'profile'}
                    // fieldId={fieldId}
                    multiple={false}
                    label={tTr('dialog.add_materials.tab.courses')}
                    value={currentItem}
                    onChange={(item: any) => {
                        setCurrentUid(item?.id)
                        changeCurrentItem(item)
                    }}
                />

            </FormControl>}

            {selectedTab.value === 'News' && <NewsUrlSelector onSelected={(news) => setCurrentUid(news?.id)} />}

        </div>
        <DialogActions>
            <LoadBtn main isLoading={isLoading} disabled={!currentUid} onClick={accept}>{tTr('dialog.add_user.confirm')}</LoadBtn>
            <Button onClick={handleClose}>
                {tTr('dialog.add_user.close')}
            </Button>
        </DialogActions>
    </div>
}
const Connected = connect<IAddMaterialsStateProps, IAddMaterialsDispatchProps, IAddMaterialsOwnProps, any>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);

export default createModal<IAddMaterialsResult, IAddMaterialsOwnProps>(Connected, {
    maxWidth: 'sm',
    PaperProps: {
        style: {
            backgroundColor: 'transparent',
            overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});
