import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { setModerationFilters } from '../../../../redux/actions/LMSTeacherOffice'

export interface ISessionsProps extends IClassNameProps, ISessionsStateProps, IDispatchProps, RouteComponentProps, ISessionsDispatchProps {
    tag?: 'div';
    setModerationFilters: any;
    sessionsIds: any[];
    normalSessions: any;
    currentFilters: any;
}

export interface ISessionsState {

}

export interface ISessionsStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    // sessionsIds: state.LMSTO.courses.values[props.cid].activeSessions,
    // normalSessions: state.LMSTO.sessions,
    // currentFilters: state.LMSTO.moderation.filters['filter.sessionIds']
    // units: state.LMSTO.moderation.units,
    // ids: state.LMSTO.moderation.results
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    //setModerationFilters
}, dispatch);

export type ISessionsDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnSessions = cn('SessionsFilter');