import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { IStateType } from '../../../../redux/store';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { IBodyProps } from 'utils/src/DialogCreator';
import {
  getNewsById,
  getNewsParamsById,
} from '../../../../News/redux/saga/selectors';
import { withNewsContext, NewsContextProps } from '../../../../News/contexts/news';
import { News } from 'News/containers/DialogView/interfaces'
import { ReactNode } from 'react';

export const mapVoteFullViewStateToProps = (state: IStateType, { newsId }: IVoteFullView.OwnProps) => {
  return {
    ...getNewsParamsById(newsId, ['id', 'user', 'additionalFields'])(state),
    workflowId: getNewsById(newsId)(state)?.additionalFields?.formId,
  };
};

// ({

// });

export const mapVoteFullViewActionToProps = (d: Dispatch<Action>) => bindActionCreators({

}, d);

export namespace IVoteFullView {

  export type ImapVoteFullViewStateToProps = ReturnType<typeof mapVoteFullViewStateToProps>;

  export type ImapVoteFullViewActionToProps = ReturnType<typeof mapVoteFullViewActionToProps>;

  export interface BodyProps {
  };

  export interface OwnProps extends NewsContextProps, News.DialogView.IBaseDialogViewProps {//IBodyProps<BodyProps> {
    children?: ReactNode
    newsId: string;
  };

  export interface Props extends IClassNameProps, OwnProps, ImapVoteFullViewStateToProps, ImapVoteFullViewActionToProps { };
}

export const cnVoteFullView = cn('VoteFullView');