import actions from 'redux/actionsTypes/Search';

export const getQuery = (payload: string) => {
  return {
    type: actions.GET_QUERY,
    payload
  }
}

export const setActiveTab = (payload: string) => {
  return {
    type : actions.SET_ACTIVE_TAB,
    payload
  }
}