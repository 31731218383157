import { IFiltersProps, IFiltersState, IFiltersDispatchProps, IFilterContext } from "../Filters.index";
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
import { mapStateToProps } from '../Filters.index';
import { IStateType as IState } from 'redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { IClassNameProps } from "@bem-react/core";
import { RouteComponentProps } from 'react-router';

export interface IFiltersSubnetworksProps extends IClassNameProps, IFiltersDispatchProps, IFiltersSubnetworksStateToProps, RouteComponentProps<IFiltersSubnetworksRouteProps> {
  tag?: 'div';
  data: any[];
  context: IFilterContext;
  relations: string[];
}

export interface IFiltersSubnetworksRouteProps {

}

export interface IFiltersSubnetworksState extends IFiltersState {
  data: ISelectizeItem[];
}

export type IFiltersSubnetworksStateToProps = ReturnType<typeof mapFiltersSubnetworksStateToProps>

export const mapFiltersSubnetworksStateToProps = (state: IState, props: IFiltersProps) => {
  return {
    // store: state.store
    networks: getAuthUser(state).baseData.networks,
    baseNetwork: getAuthUser(state).baseData.baseNetwork,
    parentNetwork: state.store.appSettings?.parentNetwork,
    currentNetworkName: state.store.appSettings?.siteURL,
    // @ts-ignore
    edit: state.store.edit,
    ...mapStateToProps(state, props)
    // context: prepareContext<IFilterContext>(state.widgets.context, props.contexts),
  }
};