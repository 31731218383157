import { IClassNameProps } from '@bem-react/core';
import { cnUser } from '../User.index';

export interface IStatusProps extends IClassNameProps {
  data: string;
  save(value: string, close: () => void): void;
}

export interface IStatusState {
  text?: string;
  height: number;
  value: string;
}

export const cnStatus = (...data: any) => {
  let name = 'Status';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnUser(name, ...data)
}