
import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import * as ACT from '../../../actions';
import * as SEL from '../../selectors'

const handleContextActionEdit = function* handleContextActionEdit({ payload }: AT.AContextAction) {
    const { action, id } = payload
    if (action === 'edit') {
        try {
            const news = yield* select(SEL.getNewsById(id))
            if (!news.translatedTo) {
                yield* put(ACT.toggleEdit({ id }))
            } else {
                yield* put(ACT.contextActionCancle({
                    id,
                    action
                }))
            }
        } catch (error) {

        }
    }
    yield;

    return 0;
}

export default handleContextActionEdit