const zeroId = "00000000-0000-0000-0000-000000000000";

/**
 * состояния теста
 */
export enum ETestStates {
    /**
     * не начат
     */
    NOT_STARTED = 0,

    /**
     * в процессе прохождения
     */
    PROCESSING = 1,

    /**
     * есть пропущеные вопросы
     */
    SKIPPED_LIST = 2,

    /**
     * выполнен
     */
    RESULT = 3
}

/**
 * тест неначат
 * @param log 
 * @param unitContent 
 * @param emptySkiped 
 */
export const isNotStarted = (log: any, unitContent: any, emptySkiped: boolean) =>
    !log.isCompleted && (!unitContent.currentQuestion || unitContent.currentQuestion === zeroId) && emptySkiped

/**
 * тест в процесса прохождения
 * @param log 
 * @param unitContent 
 */
export const isProcessing = (log: any, unitContent: any) =>
    !log.isCompleted && unitContent.currentQuestion && unitContent.currentQuestion !== zeroId

/**
 * список пропущеных вопросов
 * @param log 
 * @param unitContent 
 * @param emptySkiped 
 */
export const isSkippedList = (log: any, unitContent: any, emptySkiped: boolean) =>
    !log.isCompleted && (!unitContent.currentQuestion || unitContent.currentQuestion === zeroId) && !emptySkiped


/**
 * результаты теста
 * @param log 
 * @param emptySkiped 
 */
export const isResult = (log: any, emptySkiped: boolean) => log.isCompleted && emptySkiped

/**
 * определяет состояние теста
 * @param log 
 * @param unitContent 
 * @param emptySkiped 
 */
export const selectTestState = (log: any, unitContent: any, emptySkiped: boolean) => {
    if (isNotStarted(log, unitContent, emptySkiped)) return ETestStates.NOT_STARTED
    else if (isProcessing(log, unitContent)) return ETestStates.PROCESSING
    else if (isSkippedList(log, unitContent, emptySkiped)) return ETestStates.SKIPPED_LIST
    else if (isResult(log, emptySkiped)) return ETestStates.RESULT
    return ETestStates.NOT_STARTED
}