import { NDataStorageActions } from './actions.interfaces';
import * as DSActTypes from './actions.types';
import { NDataStorage } from './interfaces';


/**
 * data storage reducer action to set or rewrite item without merge data
 * @returns 
 */
 export const DSSetItem = <SN extends NDataStorage.StorageName> (payload: NDataStorageActions.SetItem<SN>['payload']): NDataStorageActions.Actions => ({
    type: DSActTypes.DS_SET_ITEM,
    payload
});


/**
 * data storage reducer action to set or rewrite array of items without merge data
 * @returns 
 */
export const DSSetItems = <SN extends NDataStorage.StorageName> (payload: NDataStorageActions.SetItems<SN>['payload']): NDataStorageActions.Actions => ({
    type: DSActTypes.DS_SET_ITEMS,
    payload
});


/**
 * data storage reducer action to change item with merge data
 * @returns 
 */
export const DSChangeItem = <SN extends NDataStorage.StorageName> (payload: NDataStorageActions.ChangeItem<SN>['payload']): NDataStorageActions.Actions => ({
    type: DSActTypes.DS_CHANGE_ITEM,
    payload
});


/**
 * data storage action to remove item
 * @param payload 
 * @returns 
 */
export const DSRemoveItem = <SN extends NDataStorage.StorageName> (payload: NDataStorageActions.RemoveItem<SN>['payload']): NDataStorageActions.Actions => ({
    type: DSActTypes.DS_REMOVE_ITEM,
    payload
});