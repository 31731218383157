import React, { FC, memo, useMemo } from 'react'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import * as BaseInterface from '../interfaces'
import { Tooltip } from 'muicomponents/src/Tooltip';
import { Box } from 'muicomponents/src';
import { getAppDesignColors } from 'utils/src/CommonRedux/base/selectors';
import { useSelector } from 'react-redux';
import { i18n } from 'localization';


type IPresenterProps = BaseInterface.Shop.CharacteristicsValueTBase.IPresenterProps

function hexToRgb(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

const changeSelectedColor = (color: string) => {
    const rgb = hexToRgb(color)
    const r = (rgb?.r || 0)
    const g = (rgb?.g || 0)
    const b = (rgb?.b || 0)
    const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b
    const num = brightness > 127 ? 80 : 255
    return `rgb(${Math.abs(num)}, ${Math.abs(num)}, ${Math.abs(num)})`
}

export const ColorCharacteristic: FC<IPresenterProps> = memo(({
    className,
    value,
    size = 'medium',
    sx
}) => {
    const { color, isSelected = false, isDisabled } = value

    const inversedRgbSrting = changeSelectedColor(color)

    const bgColor = useSelector(getAppDesignColors).primaryColor3

    const style = useMemo(() => {
        let sideSize = 27;
        let borderSize = 3;
        if(size === 'small') {
            sideSize = 20;
            borderSize = 2;
        }
        return {
            backgroundColor: color, 
            border: `${borderSize}px solid`,
            borderColor: isSelected ? bgColor : '#c9c9c9',
            width: `${sideSize}px`,
            height: `${sideSize}px`,
            borderRadius: '4px',
        };
    }, [color, isSelected, bgColor, size]);
    
    return <Tooltip title={!isDisabled || isSelected ? value.displayName : i18n.t('pryaniky.shop.oreder.error.')} >
        <Box 
            sx={{
                ...sx,
                ...style,
                filter: isDisabled && !isSelected ? 'grayscale(100%)' : undefined
            }}
        />
    </Tooltip>
})

export const initColor = () => ComponentInjector.getInstance().addComponent('shopMui_characteristics_types', ColorCharacteristic, 'ShopCharacteristicsColor')


export default ColorCharacteristic;
