import { styled } from 'muicomponents/src/mui/system';
import { MenuItem } from 'muicomponents/src/Menu'
import {
    Divider,

} from 'muicomponents/src';
const top = 56;

export const MenuItemBox = styled(MenuItem)({
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "143%",
    letterSpacing: "0.15px",
    color: "#000000",
    textTransform: "inherit",
    justifyContent: "left",
    padding: "14px 24px",
    display: "block"
});


export const CustomDivider = styled(Divider)({
    margin: '0px 0!important',
    marginLeft: "0",
    borderColor: " rgba(0, 0, 0, 0.42)"
});




