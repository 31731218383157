import { IEditorProps } from './Editor.index'
import * as React from 'react'
import './Editor.scss';
// import { TextareaMentionsEditor } from '../../../TextareaMentions/TextareaMentions'
import i18n from 'localizations/i18n';
// import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'
import PryanikyEditor from 'uielements/src/PryanikyEditorV1'
import { allEnabledPreset, markdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap'
import FieldRender from 'uielements/src/FieldRender'
import { withEditorContext } from '../../../EditorForm/EditorForm'
import { CheckboxInput, Icon } from 'uielements/src';
import { DateTimeInput } from 'blocks/PryanikyForms/DateTimeInput/DateTimeInput'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { Translate } from 'localizations/Translate';


const Wc = withEditorContext(({ onFocus }: any) => {
    onFocus()
    return <></>
})
export const Editor: React.FC<IEditorProps> = ({
    children,
    data,
    onChangeText,
    allowRichEdit,
    // draftState,
    changeAdditional,
    additionalFields = [],
    setAutoShort = (state: boolean) => { },
    autoShort = true,
    ...props }) => {
    return <div className={'Editor EditorLongread'}>
        <PryanikyEditor
            // getClassRef={refGetter}
            //ref={forwardRef as any}
            // users={true}
            // tags={true}
            className={'EditorLongread-Short'}
            onFocus={() => setAutoShort(false)}
            enableModules={{ ...markdownPreset }}
            onChange={(state) => onChangeText(state as any)}
                // onChangeText(state.getCurrentContent())}
            // className={''}
            // icon={'edit'}
            value={data.text}
            disableToolbar={!allowRichEdit}
            placeholder={i18n.t('pryaniky.createPost.longread.placeholder.short')}
        />
        <Wc />

        <FieldRender fields={additionalFields} onChange={changeAdditional} />

        <CheckboxInput
            onChange={() => setAutoShort(!autoShort)}
            checked={autoShort}
            padding="0"
        >
            <Translate i18nKey='pryaniky.createPost.longread.autoShort' />
            <HelpTooltip help={i18n.t('pryaniky.createPost.longread.autoShort.help')}>
                <Icon icon="question-circle"></Icon>
            </HelpTooltip>
        </CheckboxInput>

        {/* <PryanikyEditor
            maxFileSize={100}
            enableModules={{ ...allEnabledPreset, attachment: true }}
            toolBarStyle={{ top: 108 }}
            value={draftState}
            setActionBtn={(v: any) => { }}
            onChange={changeDraft}
        /> */}
        {children}
    </div>
}