import { WikilistSettings } from "blocks/Widget/_type/Widget_type_list";

export const preapreCollectionAliasByContext = (context: {[key: string]: any}) => {
    return context.gId
        ? context.collectionAlias || `group/${context.gId}`
        : !context.coll
        ? "all"
        : context.collectionAlias
        ? context.collectionAlias
        : "dash";
};


export const getWikilistSettings = (params: Partial<WikilistSettings>): WikilistSettings => {
    return {
        folder: null,
        ...params,
    };
}