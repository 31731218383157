import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
export interface IHeaderProps extends IClassNameProps {
    tag?: 'div';
    className?: string;
    attemptNumber?: any;
    maxAttemptsCount?: any;
    remainingTime?: any;
    titleText?: string
}

export interface IHeaderState {

}

export const cnHeader = cn('ResultHeader');