import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

///////////

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace Skills {
    // eslint-disable-next-line @typescript-eslint/no-namespace

    export type IPresenterProps = IOwnProps

    export interface IState { }

    export interface IOwnProps extends IClassNameProps {
        create: boolean,
        showControls: boolean;
        onComplete: () => void;
        onCancle: () => void;
        setCreate: (create: boolean) => void
        withError: boolean;
    }
}

////////////

export const cnClassName = cn('SkillsHeader');