// import { IDispatchProps } from 'redux/connector';

export interface IBodyTypeCompetitionsProps { // extends IBodyProps, IBodyStateProps, IDispatchProps {
  tag?: 'div';
}

export interface IBodyTypeCompetitionsState {
  text?: string;
}

export interface IBodyStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})