import { CreativeTaskNews } from '../CreativetasksType'
import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
export interface IEditorProps extends IClassNameProps {
    data: any;
    endDateChange: (date: Date) => void;
    onChangeTitle: (e: any) => void;
    onChangeText: (text: string) => void
    onChangeThanks?: (val: number) => void;
}

export const cnEditor = cn('EditorCreativetasks');