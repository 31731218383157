import Component, { Presenter } from './component';
import {
    IUnitsAttandanceOwnProps,
    IUnitsAttandanceProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = IUnitsAttandanceOwnProps;
export type IComponentPresenterProps = IUnitsAttandanceProps;


export default Component