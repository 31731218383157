import React, { FC, memo, useCallback, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'

import FormControlO from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { styled } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';
import { arrayMove, arrayMoveMutate } from 'utils/src/utils'
import AnswerInput from '../AnswerInput'
import { controlStyle, peperStyle, controlLabelStyle } from './style'
import { markdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap'
import PryanikyEditor from 'uielements/src/PryanikyEditorV1'
import { questionValidator, fieldWithError } from '../unitValidation'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import QuestionLayout from '../QuestionLayout'
import { videoUrls } from 'utils/src/videoUrls';
import { VideoButton } from 'uielements/src/Dialog/VideoDilog/VideoButton';

const FormControl = styled(FormControlO)(controlStyle)



export const Presenter: FC<IPresenterProps> = ({
    className,
    question,
    createAnswer,
    updateQuestion,
    deleteQuestion,
    changeQuestionType,
    setQuestionError,
    number,
    uid,
    withAnswerError,
    errors,
    deleteAnswer: deleteAnswerAction,
    answersErrors,
    maxFileSize,
    children,
    questionTypes
}) => {
    const updateQ = useCallback((payload: any) => updateQuestion({ id: question.id, ...payload }, uid), [question])
    const changeText = (property: string) => (e: any) => {
        setQuestionError(questionValidator(property, e.target.value))
        updateQ({ [property]: e.target.value })
    }
    const changeObject = useCallback((property: string) => (val: any) => {
        updateQ({ [property]: val })
    }, [question])
    const changeType = (event: React.ChangeEvent<{ value: unknown }>) => {
        changeQuestionType(event.target.value as number, question.id, uid)
    }

    let withErrors = withAnswerError
    withErrors = Object.values(errors).filter((v: any) => (typeof v !== 'undefined')).length !== 0 ? true : withErrors
    withErrors = answersErrors.length !== 0 ? true : withErrors

    return <QuestionLayout
        answersErrors={answersErrors}
        deleteQuestion={deleteQuestion}
        errors={errors}
        header={question.header}
        number={number}
        qid={question.id}
        uid={uid}
        withAnswerError={withAnswerError}
        isNew={question.isNew}
    >
        <FormControl fullWidth>
            <TextField
                label={tT('question_edit.header.1')}
                error={fieldWithError(errors['header'])}
                onChange={changeText('header')}
                value={question.header}
            />
        </FormControl>

        <FormControl fullWidth>
            {/* <TextField
                            label="Текст вопроса"
                            multiline
                            rows="4"
                            onChange={changeText('text')}
                            value={question.text}
                        /> */}
            <PryanikyEditor
                maxFileSize={maxFileSize}
                enableModules={markdownPreset}
                toolBarStyle={{ top: 108 }}
                value={question.draftState}
                setActionBtn={(v: any) => { }}
                onChange={changeObject('draftState')}
            />
        </FormControl>

        {/* <FormControlLabelHelped
                        control={
                            <Checkbox
                                checked={question.allowSkip}
                                onChange={changeCheck('allowSkip')}
                                name="allowSkip"
                                color="primary"
                            />
                        }
                        label={'Разрешить пропускать вопрос'}
                        help={'Студент сможет закончить тест, не отвечая на указанный вопрос'}
                    /> */}

        {questionTypes.length > 1 && <FormControl fullWidth>
            <InputLabel id={`${question.id}-qtype-select-label`}>{tT('question_edit.qtype.1')}</InputLabel>
            <Select
                labelId={`${question.id}-qtype-select-label`}
                id={`${question.id}-qtype-select`}
                value={question.qtype}
                onChange={changeType}
            >
                {questionTypes.map(item => <MenuItem key={item.value} value={item.value}>{item.title}</MenuItem>)}
            </Select>
            <span >
                {tT('question_edit.qtype.1.help')}
                {question.qtype === 0 && <Tt i18nKey={'question_edit.questionTypes.0.1'} />}
                {question.qtype === 1 && <Tt i18nKey={'question_edit.questionTypes.1.1'} />}
                {question.qtype === 2 && <Tt i18nKey={'question_edit.questionTypes.2.1'} />}
                {tT('question_edit.qtype.2.help')}
                {question.qtype === 0 && <VideoButton url={videoUrls.lms.testQuestionVariant} type={'vimeo'} />}
                {question.qtype === 1 && <VideoButton url={videoUrls.lms.testQuestionManyAnsvers} type={'vimeo'} />}
                {question.qtype === 2 && <VideoButton url={videoUrls.lms.testText} type={'vimeo'} />}
            </span>
        
        </FormControl>

        }
   
        {children}

    </QuestionLayout>

}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
