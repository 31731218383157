import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { loadTestResults } from '../../../redux/actions/LMSTeacherOffice'
import { getTestResults } from '../../../redux/sagas/LMSTO/selectors'

export interface ITestResultsProps extends IClassNameProps, ITestResultsStateProps, IDispatchProps, RouteComponentProps, ITestResultsDispatchProps {
    tag?: 'div';
    cid: string
}

export interface ITestResultsState {

}

export interface ITestResultsStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    results: getTestResults(state)
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadTestResults
}, dispatch);

export type ITestResultsDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnClassName = cn('TOTestResults');