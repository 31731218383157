import * as React from 'react';
import { IImageLoaderPreviewProps, IImageLoaderPreviewState, cnImageLoaderPreview } from "./ImageLoader-Preview.index";
import { Icon } from 'uielements/src';

import './ImageLoader-Preview.scss';

export const ImageLoaderPreview: React.FC<IImageLoaderPreviewProps> = ({ className, previewUrl, square }) => {
  return <div className={cnImageLoaderPreview({ empty: !previewUrl, circle: !square }, [className])} style={{ backgroundImage: `url("${previewUrl}")` }}>
    {
      !previewUrl &&
      < Icon className={cnImageLoaderPreview('Icon')} icon={'plus'} />
    }
  </div>
}