/**
 * @packageDocumentation
 * @module AboutWidget
 */
import React, { FC } from 'react'
import { IAboutViewProps, mapStateToProps, mapDispatchToProps } from './View.index'
import { connect } from 'react-redux';
// import { PostContentRender } from 'blocks/PostContentRender/PostContentRender';
import { Attachments } from 'uielements/src/Attachments/Attachments'
import { Translate } from 'localizations/Translate';
import MDRender from 'uielements/src/CommonmarkRender'

export const AboutView_: FC<IAboutViewProps> = ({ user }) => {

    return <div className={'AboutView'}>
        {user.profileData.about === "" ?
            <div className={'AboutView-NoData'}><Translate i18nKey={'pryaniky.placeholder.widget.nodata'} /></div> :
            // <PostContentRender data={user.profileData.about} />
            <MDRender textCut={true} source={user.profileData.about} />
            }

        {user.profileData.userHeadFields.result.map((fieldId: any) => {
            const { field, value, tags, files } = user.profileData.userHeadFields.entities.fields[fieldId];
            if (!field) return null;
            if (field.fieldType === 'Files' && files.length !== 0) {
                return <div className={'AboutWidget-AttachSection'}>
                    <div className={'AboutWidget-Header'}>
                        <h4 className={'AboutWidget-Title'}>{field.name}</h4>
                    </div>
                    <Attachments className={'AboutWidget-Attachments'} files={files} state={'view'} onDelete={() => { }} disabledType={[]} />
                </div>
            }
        })}
    </div>
}

export const AboutView = connect(
    mapStateToProps,
    mapDispatchToProps
)(AboutView_)