import { Box, Typography, FormControlLabel } from 'muicomponents/src'
import { styled } from 'muicomponents/src/mui/system';
import { PageCollectionSuggester as PageCollectionSuggesterMui, BaseSuggester as BaseSuggesterMui } from 'muicomponents/src/Suggester';
import { Tabs as MuiTabs } from 'muicomponents/src/Tabs/Tabs'
import { BaseListItem } from 'muicomponents/src/ItemsListDialog/BaseListItem/BaseListItem'

// const defaultSuggesterStyle = {
//   '& .MuiInputBase-root': {
//     paddingRight: '39px !important'
//   }
// }
export const WidgetDialogBox = styled(Box)({
  display: 'flex',
  flexDirection: "column",
  gap: "16px",
  padding: '24px'
})