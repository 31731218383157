import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import {
  getNewsBannersStateByKey
} from 'News/redux/saga/selectors'

export interface IBannersProps extends IClassNameProps, IBannersOwnProps, ReturnType<typeof mapBannersMapStateToProps> {

}

export interface IBannersOwnProps {
  widgetId: string;
  settings?: {[key:string]:any}
}

export const mapBannersMapStateToProps = (state: any, props: IBannersOwnProps) => ({
  closed: getNewsBannersStateByKey('closed')(state)
})

export const cnBanners = cn('Banners');
export const cnClassNameNoData = cn('BannersNoData');