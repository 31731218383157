import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IBodyProps } from 'utils/src/DialogCreator';

export interface ISubmitForCorrectionProps extends IClassNameProps, IBodyProps<{ comment: string, needSendNotification:boolean}> {
  tag?: 'div';
}

export interface ISubmitForCorrectionState { }

export interface ISubmitForCorrectionStateProps { }


export const cnSubmitForCorrectionDialog = cn('SubmitForCorrectionDialog');