import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IBanner } from '../../interfaces'



///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsBannerTypeOther {

        export type IPresenterProps = IOwnProps & IClassNameProps

        export interface IState { }

        export interface IOwnProps {
            banner: IBanner
        }
    }
}
////////////


////////////

export const cnClassName = cn('NewsBannerTypeOther');