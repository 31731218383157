import React, { useContext } from 'react';
import i18n from '../../../localizations/i18n';
import Suggester from 'uielements/src/MaterialElements/Autocomplete';
// import * as PryanikyForms from '../../PryanikyForms';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
// import { DispatchProp } from 'react-redux';
// import { connect } from 'react-redux';
// import { JFdictionary } from "../../WSettings/structures/dictionary";
import { Translate } from '../../../localizations/Translate';
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';
import { FormControl } from '@material-ui/core';
import { CollectionsHierarchySuggester, GroupsSuggester, IconsSuggester } from 'muicomponents/src/Suggester';
import { ISelectableObject } from 'utils/src/requests/models/api.base';
import { SettingsContext } from 'blocks/Dialogs/Widgets/Settings/WidgetsSettings.context';

export const SuggesterHierarchy = (props: any) => {
  const settingsContext = useContext(SettingsContext);
  if (!props.visible) return null;
  return <CollectionsHierarchySuggester
    value={props.data[get_variable_name(props.uischema.scope)]}
    onChange={(e, value) => {
      props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
        return value
      }))
    }}
    requestAdditionalParams={{
      gId: settingsContext?.groupId
    }}
  />
};

// SuggesterControl
export const suggester = (props: any) => {
  if (!props.visible) return null;
  // const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  return  <GroupsSuggester
  TextFieldProps={{
      fullWidth: true,
      label: <Translate i18nKey={'pryaniky.leftMenu.modal.change.groups'} />,
      helperText: <Translate i18nKey={'pryaniky.leftMenu.modal.change.groups.helperText'} />
  }}
  multiple={false} 
   // TODO check types
  value={props.data[get_variable_name(props.uischema.scope)]}
  // TODO check types
  onChange={(e, value) => {
          props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
            return value
          }))
        }}
/>
  // <Suggester
  //     className={'JF-Suggester'}
  //     // disabled={isLoading}
  //     // allowCreate={!field.selectOnlyFromCollection}
  //     // fullWidth
  //       style={{
          
  //       }}
  //     type={props.uischema.componentType || 'users'}
  //     label={<Translate i18nKey={props.uischema.title} />}
  //     value={props.data[get_variable_name(props.uischema.scope)]}
  //     onChange={(items: any[]) => {
  //       props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
  //         return items
  //       }))
  //     }}
  //     {...props.uischema.componentProps}
  //   />
}
// end TableControl