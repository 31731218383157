import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from 'redux/connector';

export interface IWikiTPProps extends IClassNameProps, IWikiTPStateProps, IDispatchProps {
  tag?: 'div';
}

export interface IWikiTPState {
  text?: string;
}

export interface IWikiTPStateProps {
  store: any;
  structures: any;
}

export const mapStateToProps = (state: any) => ({
  store: state.store,
  structures: state.widgets.structures
})

export const cnWikiTP = cn('WikiTP');