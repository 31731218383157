import { cn } from '@bem-react/classname'

export const cnNetworkItem = cn('NetworkItem')

export interface INetworkItem {
  item: any
  href: string
  notificationsTemplate: any
  userId: string
}

