import moment from "moment";
import { baseModels } from "News/creator/Creator/Creator.hooks";
import { NewsCreatorButton } from "News/creator/Creator/CreatorButton/CreatorButton";
import { ComponentProps, useMemo } from "react";
import { useSelector } from "react-redux";
import { getCompanyPostTypes, getCurrentAllowPostTypes } from "redux/sagas/AppSettings/selectors";
import { getGroupByPKID } from "redux/sagas/Groups/selectors";
import { ObjectDate } from "utils/src";

export const useCanCalendarCreateEvent = (context: any) => {

    const globalPostTypes = useSelector(getCompanyPostTypes);
    const contextPostTypes = useSelector(getCurrentAllowPostTypes(context.gId, context.workflowId));

    // check by group data if have group data
    const groupData = useSelector(getGroupByPKID(context.gId || ''));
    if(groupData) {
      return groupData.enabledNewsTypes.includes('events');
    }
    
    return (context.gId || context.workflowId ? contextPostTypes : globalPostTypes).find((el: any) => el.componentRenderName === 'events')?.enabled;
};

export const useCalenderNewsCreatorOptions = (dateString?: ObjectDate) => {
    return useMemo<ComponentProps<typeof NewsCreatorButton>['creatorOptions']>(() => {
        if(dateString) {
            const date = moment(dateString);
            if(moment().startOf('day').diff(date, 'days') === 0) {
              const now = moment();
              date.hours(now.hours());
              date.minutes(now.minutes());
            } else {
              date.hours(9);
            }
            return {
              formSettings: {
                root: {
                  hideGroupSelector: true
                }
              },
              models: {
                events: {
                  ...baseModels.events,
                  expires: date.toISOString(),
                  uevent: {
                    ...baseModels.events.uevent,
                    startDateTime: date.toISOString()
                  }
                }
              }
            }
        }
        return {
          formSettings: {
            root: {
              hideGroupSelector: true
            }
          }
        };
      }, [dateString]);
};