import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
// import { loadParticipantList } from "../../../../redux/actions/News";
import { loadParticipant } from '../redux/actions'
export interface IParticipantsBoxProps extends IClassNameProps, IParticipantsBoxStateProps, IDispatchProps, ReturnType<typeof mapDispatchToProps> {
    eventParticipants?: any[];
    eventMaybePar?: any[];
    eventNotGoers?: any[];
    eventStatus: string;
    eventParticipantsCount: number;
    currentUser: any;
    eventId: string;
    eventNotGoersCount: number;
    eventMaybePartCount: number;
    changeParticipants: (array: any[]) => void
}

export interface IParticipantsBoxStateProps {

}

export const mapStateToProps = (state: any) => ({

})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadParticipant
}, dispatch);


export const cnParticipantsBox = cn('ParticipantsBox')