import React from 'react';
import { createPortal } from 'react-dom';
import WidgetPresenter from '../Widget';
import { IWidgetTypeReactionsProps } from "./Widget_type_reactions.index";
import { widgets } from "../../../i.widgets";
import i18n from '../../../localizations/i18n';

import { Reactions } from 'blocks/Reactions/Reactions'

export default class WidgetTypeReactionsPresenter extends WidgetPresenter<IWidgetTypeReactionsProps> {

  public render() {
    if (!this.props.widget) return null;

    // if ((this.props.edit && !(window as any).PRN_SERVICE.dev_features) || (!this.state.isShown && !this.props.edit)) return null;
    const reactions = createPortal(<Reactions />, document.getElementById('Layout') as HTMLDivElement);
    return (
      <>
        {
          this.props.edit ?
            <widgets.components.common {...this.props}>
              {i18n.t('pryaniky.widgets.create.name.pryaniky/topbutton')}
              {reactions}
            </widgets.components.common>
            :
            reactions
        }
      </>
    )

    // (

    //   <Button children={<Icon icon='chevron-top' />} />
    // );
  }
}