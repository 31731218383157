import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from "../../../redux/connector";
// import i18n from '../../../localizations/i18n';

import { mapStateToProps, IRatingStateProps, cnRating, IRatingProps, IRatingState, cnInfo } from './infoBlock-Rating.index';
import './infoBlock-Rating.scss';
import { withElementTypeState } from '../AbstarctInfoElement/_type/InfoBlock-Element_type_state';
import { withElementTypeStore } from '../AbstarctInfoElement/_type/InfoBlock-Element_type_store';
import { AbstractInfoItem } from '../AbstarctInfoElement/AbstractInfoElement';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { Layout } from '../Layout/InfoBlock-Layout';
import MDRender from 'uielements/src/CommonmarkRender'

export class RatingPresenter extends AbstractInfoItem<IRatingProps, IRatingState> {

  protected renderChildren() {
    const { data } = this.props
    return (
      <Layout
        left={
          <div className={'InfoBlock-Img'}>
            <Avatar
              className={cnRating('Img')}
              imgId={data.imgId}
              imgUrl={data.imgUrl}
              name={data.name}
              size={180}
              shape={'square'}
            />
          </div>
        }
        right={
          <div className={cnRating('Data')}>
            <div className={cnInfo('DataName')}>{data.name}</div>
            <div className={cnRating('DataDescription')} >
              <MDRender source={data.desc} />
            </div>
          </div>
        } />
    );
  }

  protected actionGetData = () => {
    utils.API.ratings.leaderboard({ rid: this.props.rId })
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          this.props.setData(d.data.rating);
        } else {
          // toast.error('something wrong')
        }
      });
  }
}


export const Rating = connect<IRatingStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(compose(
  withElementTypeState,
  withElementTypeStore
)(RatingPresenter))