import React, { FC, useState, useEffect, useRef, ComponentProps } from 'react';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { SettingsArticlesWidget } from '../WikiArticlesWidget.index';
import {
    cnSettingsArticlesDialog,
    NSettingsArticlesDialog
} from './SettingsDialog.index'

import {
    Button,
    Box,
    Checkbox,
    FormControlLabel,
    TextField,
    PageCollectionSuggester
} from 'muicomponents/src';
import { Translate } from 'localizations/Translate';
import { NumberTextField } from 'muicomponents/src/TextField/TextField'
import { WidgetDialogBox, PageCollection, FormControlLabelStyled } from '../WikiArticlesWidget.styled'
import { useDidUpdateEffect } from 'utils/src/hooks';
import { validateField, useArticlesListErrors, ArticlesListErrorsProvider } from './Validate'
import { useFieldHelperText } from 'muicomponents/src/FieldRender/FieldRender.hooks';
import { FormControl } from 'muicomponents/src/FormControl';
import { Select } from 'muicomponents/src/Select/Select';
import InputLabel from '@mui/material/InputLabel';

const sortOption = [
    { value: 'changedate', title: Translate.t({ i18nKey: 'pryaniky.widgets.article.settings.select.changedate' }) },
    { value: 'creationdate', title: Translate.t({ i18nKey: 'pryaniky.widgets.article.settings.select.creationdate' }), },

];

export const WikiArticles: FC<NSettingsArticlesDialog.Props> = ({
    onChange,
    data
}) => {

    const [state, setState] = useState<SettingsArticlesWidget.Settings>(data || {
        hideIfNoData: false,
        count: 10,
        collection: null,
        name: "",
        sort: "changedate"
    });

    const {
        state: errors,
        setState: setErrors
    } = useArticlesListErrors();

    const errorsRef = useRef(errors);
    errorsRef.current = errors;

    useEffect(() => {
        onChange({
            // дата для обновления при изменении настроек
            data: {
                hideIfNoData: state.hideIfNoData,
                name: state.name,
                count: state.count,
                collection: state.collection,
                rubric: state.rubric,
                sort: state.sort
            },
            // это при добавллении виджет
            settings: {
                hideIfNoData: state.hideIfNoData,
                name: state.name,
                count: state.count,
                collection: state.collection,
                sort: state.sort
            }
        })
        setErrors({
            ...validateField({ collection: state.collection })
        });
    }, [state]);



    const changeHideIfNoData = () => setState({ ...state, hideIfNoData: !state.hideIfNoData })
    const changeName = (event: React.ChangeEvent<{ value: unknown }>) => setState({ ...state, name: event.target.value as string })

    const changeCount = (event: React.ChangeEvent<{ value: unknown }>) => {
        setState({
            ...state,
            count: event.target.value as string
        })
    }

    const HelperText = useFieldHelperText("", errorsRef.current?.collection);

    return (<WidgetDialogBox className={cnSettingsArticlesDialog("")}>

        <TextField
            autoComplete="off"

            fullWidth
            label={<Translate i18nKey='pryaniky.widgets.article.settings.name' />}

            variant='outlined'
            value={state.name}
            onChange={changeName} />

        <FormControl
            variant='outlined'
            fullWidth className={cnSettingsArticlesDialog("PageCollectionSuggester")}
            error={!!errorsRef.current?.collection}
        >
            <PageCollectionSuggester
                TextFieldProps={{
                    label: Translate.t({ i18nKey: 'pryaniky.widgets.article.settings.collection' }),
                    error: !!errorsRef.current?.collection

                }}

                value={state.collection as any}
                onChange={(event, value: any) => {
                    setState({ ...state, collection: value });
                }
                }
            />
            {HelperText}
        </FormControl>
        {/* <PageCollectionSuggester
            TextFieldProps={{
                label: Translate.t({ i18nKey: 'pryaniky.widgets.article.settings.collection' }),
            }}
            value={state.collection as any}
            onChange={(event, value: any) => setState({ ...state, collection: value })}
        /> */}
        {/* при его обновлении стирались все остальные внесенные данные, из-за спешки, я решила использовать обычое поле типа намбер */}
        {/* <NumberTextField
            autoComplete="off"
            hideApperance
            inputProps={{
                min: 1,
                max: 10
            }}
            fullWidth
            label={<Translate i18nKey='pryaniky.widgets.article.settings.count' />}
            helperText={<Translate i18nKey='pryaniky.widgets.article.settings.count.help' />}
            variant='outlined'
            value={state.count}
            onChange={changeCount}
        /> */}
        <TextField
            InputProps={{
                inputProps: {
                    min: 1,
                    max: 10
                }
            }}
            label={<Translate i18nKey='pryaniky.widgets.article.settings.count' />}
            helperText={<Translate i18nKey='pryaniky.widgets.article.settings.count.help' />}
            type={'number'}
            value={state.count}
            onChange={changeCount}
        />
        <FormControl className={cnSettingsArticlesDialog("Content-ItemSelect")} sx={{ margin: "8px 0 0 0", width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">{Translate.t({ i18nKey: 'pryaniky.widgets.article.settings.select.sort' })}</InputLabel>

            <Select
                labelId="demo-simple-select-helper-label"
                options={sortOption}
                label={Translate.t({ i18nKey: 'pryaniky.widgets.article.settings.select.sort' })}
                value={sortOption.find((el) => state.sort === "changedate" ? el.value === "changedate" : el.value === "creationdate")?.value}
                onChange={(event) => {
                    setState({ ...state, sort: event.target.value as string })
                }}
                MenuProps={{ disablePortal: true }}
            />
        </FormControl>
        <FormControlLabelStyled
            control={
                <Checkbox
                    checked={state.hideIfNoData}
                    onChange={changeHideIfNoData}
                    name="Shuffle"
                    color="primary"
                />
            }
            label={<Translate i18nKey='pryaniky.widgets.settings.type.shop.noData' />}
        />


    </WidgetDialogBox>)
}
export const WikArticlesPresenterWithContext: FC<ComponentProps<typeof WikiArticles>> = ({
    ...props
}) => {

    return (
        <ArticlesListErrorsProvider>
            <WikiArticles {...props} />
        </ArticlesListErrorsProvider>
    );
};


export const initWidgetAddition = () => ComponentInjector.getInstance().addComponent('WAddition', WikArticlesPresenterWithContext, 'pryaniky/wikiArticles')
export const initWidgetSettings = () => ComponentInjector.getInstance().addComponent('WSettings', WikArticlesPresenterWithContext, 'pryaniky/wikiArticles')

