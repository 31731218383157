import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { 
    loadFullUnit, 
    saveUnitResult, 
    toPrevUnit,
    transitionToNextUnit
} from "../../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
export interface ILessonProps extends IClassNameProps, ILessonStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
    sId: string;
    disableActions?: boolean;
    // unitContent: any
}

export interface ILessonState {
    // courses: ICourse[];
    // isFinished: boolean;
}

export interface ILessonStateProps {
    unitContent: any
}

export const mapStateToProps = (state: any, props: any) => ({
    unitContent: state.LMS.units[props.unit.id],
    log: state.LMS.unitsLogs[props.unit.id]
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadFullUnit,
    saveUnitResult,
    toPrevUnit,
    transitionToNextUnit
}, dispatch);

export const cnLesson = cn('Lesson');