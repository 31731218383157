import React, { FC } from "react";

export const DeviceInfo: FC<{}> = ({}) => {
    
    return (
        <div className="Widget" style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            gridGap: '16px',
            padding: '16px'
        }}>
            <div>
                {`User agent: ${window.navigator.userAgent} (window.navigator.userAgent)`}
            </div>
            <div>
                {`Browser window: ${window.innerWidth}x${window.innerHeight} px (zoom ${Math.round((window.outerWidth/window.innerWidth)*100)}% ?) (window.innerWidth x window.innerHeight)`}
            </div>
            <div>
                {`System screen: ${window.screen.width}x${window.screen.height} px (window.screen.width x window.screen.height)`}
            </div>
            <div>
                {`Device pixel ratio: ${window.devicePixelRatio} (window.devicePixelRatio)`}
            </div>
            <div>
                {`Physical resolution: ${Math.round(window.screen.width*window.devicePixelRatio)}x${Math.round(window.screen.height*window.devicePixelRatio)} px) (window.screen.width*window.devicePixelRatio x window.screen.height*window.devicePixelRatio)`}
            </div>
        </div>
    );
};