import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps, cnSliderLink, ISliderLinkProps } from './SliderLink.index'
import { Button } from 'uielements/src';
import './SliderLink.scss'
import { SliderLinkItem } from './SliderLinkItem/SliderLinkItem'
import { getCookie } from 'utils/src/utils';
import { domainForAuthAdd } from 'utils/src/debug';
import { Icon } from 'uielements/src'

const linkTypesIndexOfUrl = [
  'http',
  '//'
]

const SliderLinkPresenter: React.FC<ISliderLinkProps> = props => {
  // useEffect(() => { 
  //   if (!props.data.length) props.sliderInit({ id: props.id || 'alone', imageNum: 0,  })
  // }, [])
  const [height, setHeight] = React.useState(0);
  const ref = React.createRef<HTMLImageElement>();

  const changeSlide = (e: any, imageNum: number) => {
    e.preventDefault()
    props.changeSlide({ id: props.id, imageNum })
  }

  const onImgLoad = (ev: React.SyntheticEvent<HTMLImageElement>) => height === 0 && setHeight((ev.currentTarget.height * 100) / ev.currentTarget.width);

  useEffect(() => {
    const timer = setTimeout(() => {
      props.incrementSlideItem({ id: props.id && props.id, imageNum: props.imageNum })
    }, 10000);
    return () => clearTimeout(timer);
  }, [props.imageNum]);

  const { id, imageNum, data: _data, tag: Tag = 'div', isMouseOver } = props;

  const data = _data.filter(v => !v.inactive)

  let currentIndex = imageNum

  const active = data[imageNum];
  return (
    <Tag {...props} className={cnSliderLink()} >
      {data.length > 0 &&
        <div onMouseOver={(e) => props.mouseOver({ id, mouseOver: true })} onMouseLeave={() => props.mouseOver({ id, mouseOver: false })}>

        {data.length > 1 && <div onClick={(e: any) => changeSlide(e, --currentIndex)} className={cnSliderLink('Prev')}><Icon icon='angle-left' /></div>}
          <div className={cnSliderLink('Item')} style={{ paddingTop: `${height}%` }} >
            {

              data.map((img, idx, mass) => {
                let type = linkTypesIndexOfUrl.reduce((acc, cur) => acc || img.href.indexOf(cur) >= 0, false) ? 'link' : 'rlink'
                return <SliderLinkItem type={type} href={img.href} openInNewTab={img.openInNewTab} active={active} onLoad={idx === 0 ? onImgLoad : undefined} className={cnSliderLink(`Item_${idx}`)} idx={idx} imageNum={imageNum} imgUrl={img.imgUrl + (domainForAuthAdd.reduce((acc, curr) => acc || window.location.hostname.indexOf(curr) >= 0, false) ? `?auth=${getCookie('authAt')}` : '')} length={mass.length} edit={false} />
              }
              )
            }
          </div>
          {
          data.length > 1 &&
            <div className={cnSliderLink('Nav')}>
              {data.map((item, idx) => {
                return (
                  <div onClick={(e: any) => changeSlide(e, idx)} className={idx !== imageNum ? cnSliderLink('Nav-Item') : `${cnSliderLink('Nav-Item')} ${cnSliderLink('Nav-Item-Active')}`}></div>
                )
              })}
            </div>
          }
        {data.length > 1 && <div onClick={(e: any) => changeSlide(e, ++currentIndex)} className={cnSliderLink('Next')}><Icon icon='angle-right' /></div>}
        </div>
      }
    </Tag>
  )
}


export const SliderLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(SliderLinkPresenter)