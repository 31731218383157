import {
  IQuestsActions,
  IGetAvailableQuestsPayload,
  ISetAvailavleQuestsPayload,
  IApproveQuestGoalPayload,
  IChangeQuestPayload
} from './actions.interfaces';
import { IQuestData } from 'utils/src/requests/models/api.quests';

export const QUESTS_GET_AVAILABLE_QUESTS = 'QUESTS_GET_AVAILABLE_QUESTS';
export const QUESTS_SET_AVAILABLE_QUESTS = 'QUESTS_SET_AVAILABLE_QUESTS';
export const QUESTS_APPROVE_QUEST_GOAL = 'QUESTS_APPROVE_QUEST_GOAL';
export const QUESTS_CHANGE_QUEST = 'QUESTS_CHANGE_QUEST';


/*****************************
*********** to saga **********
*****************************/

export const getAvailableQuests = (payload: IGetAvailableQuestsPayload): IQuestsActions => ({
  type: QUESTS_GET_AVAILABLE_QUESTS,
  payload
})

export const approveQuestGoal = (payload: IApproveQuestGoalPayload): IQuestsActions => ({
  type: QUESTS_APPROVE_QUEST_GOAL,
  payload
})

/*****************************
********** to reducer ********
*****************************/

export const setAvailableQuests = (payload: ISetAvailavleQuestsPayload): IQuestsActions => ({
  type: QUESTS_SET_AVAILABLE_QUESTS,
  payload
})

export const changeQuest = (payload: IChangeQuestPayload): IQuestsActions => ({
  type: QUESTS_CHANGE_QUEST,
  payload
})
