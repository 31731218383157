import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { setSurveyAnswersIds } from 'redux/sagas/Surveys/actions'
import {  getSurveysAnswersItemSelector} from 'redux/sagas/Surveys/selectors'
import { SSurveyAnswerVariant } from 'utils/src/BaseTypes/survey';

export interface IAnswerTypeSort extends IAnswerTypeSortOwnProps {
  item: SSurveyAnswerVariant; 
}

export interface IAnswerTypeSortOwnProps {
  index: number;
  qtype: string;
}


export const cnAnswerTypeSort = cn('AnswerTypeSort')