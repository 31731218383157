import * as types from './actions.d'
import actions from './actionsType'

// to saga
export const toggleParticipants: types.CToggleParticipantsAction = (payload) => ({
    type: actions.TOGGLE_PARTICIPATS,
    payload
})


export const toggleHeads: types.CToggleHeadsAction = (payload) => ({
    type: actions.TOGGLE_HEAD,
    payload
})

export const setExperts: types.CSetExpertsAction = (payload) => ({
    type: actions.SAVE_EXPERTS,
    payload
})

export const saveStatus: types.CSaveStatusAction = (payload) => ({
    type: actions.SAVE_STATUS,
    payload
})


// export const loadUsers: types.CLoadUsersAction = (payload) => ({
//     type: actions.LOAD_EXPERTISE_RESULT,
//     payload
// })

// export const loadExpertResults: types.CLoadExpertResultsAction = (payload) => ({
//     type: actions.LOAD_EXPERTISE_RESULT,
//     payload
// })