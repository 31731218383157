import React from 'react'
import { cnQuizWidgetItem, IQuizWidgetItem, mapStateToProps, mapDispatchToProps } from './QuizWidgetItem.index'
import { Avatar } from 'uielements/src'
import i18n from 'localizations/i18n'
import { connect } from 'react-redux'
import CommonmarkRender from 'uielements/src/CommonmarkRender'
import './QuizWidgetItem.scss'
import { Link } from 'muicomponents/src/Link/Link'
import { Button } from 'muicomponents/src/Button/Button'

const QuizWidgetItemPresenter = ({ data }: IQuizWidgetItem) => {

  return (
    <>
      {data && <div className={cnQuizWidgetItem()}>
        <div className={cnQuizWidgetItem('Title')}>
          {data.iconImgUrl && 
            <Avatar  precentage={100} shape={'rectangle'} className={cnQuizWidgetItem('Title-Image')} imgUrl={data.iconImgUrl}/>}
    
          <span className={cnQuizWidgetItem('Title-Text')}>{data.name}</span>
        </div>
        <span className={cnQuizWidgetItem('Text')}><CommonmarkRender source={data.textShort}/></span>
        <Button to={`/quiz/go/${data.id}`} variant={'contained'} color={'primary'} children={i18n.t('pryaniky.quizwidget.go.To.Quiz')} />
        {/* <Button href={`/quiz/go/${data.id}`} type='rlink' main>{i18n.t('pryaniky.quizwidget.goToQuiz')}</Button> */}
      </div>}
    </>
  )
}

export const QuizWidgetItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuizWidgetItemPresenter)