import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { 
    loadFullUnit, 
    // sendNextQuestion, 
    confirmQuestion,
    saveQuestionComplete,
    transitionToQuestion,

    selectAnswer, 
    setNextUnit,
    // sendSkipQuestion, 
    skipQuestion, 
    uploadAnswerFile, 
    sendQuestionComplete 
} from "../../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
import { getCompletedUnitQuestions} from '../../../redux/sagas/LMS/selectors'
export interface ITestProps extends IClassNameProps, ITestStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
    sId: string;
    unit: any;
    save: (answer: any) => void
    // unitContent: any
}

export interface ITestState {
    // courses: ICourse[];
    // isFinished: boolean;
}

export interface ITestStateProps {
    unitContent: any
    question: any
    completedQuestionCount: number
}

export const mapStateToProps = (state: any, props: any) => ({
    unitContent: state.LMS.units[props.unit.id],
    question: state.LMS.units[props.unit.id].normalQuestions && state.LMS.units[props.unit.id].normalQuestions[props.qId],
    questionCount: state.LMS.units[props.unit.id].questions.length,
    completedQuestionCount: getCompletedUnitQuestions(props.unit.id)(state)
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadFullUnit,
    // sendNextQuestion,
    confirmQuestion,
    saveQuestionComplete,
    transitionToQuestion,

    selectAnswer,
    setNextUnit,
    skipQuestion,
    // sendSkipQuestion,
    uploadAnswerFile,

    sendQuestionComplete
}, dispatch);

export const cnLesson = cn('Test');