import React, { FC, useEffect, useState } from 'react'
import { cnDisapprove, mapDispatchToProps, mapStateToProps } from './Disapprove.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Disapprove.scss'
import { Button } from 'uielements/src';
import { tT } from 'LMSModule/utils/i18n.adaptor'

export const DisapprovePresenter: FC<any> = ({ className, status, aid, sendApproveModeration }) => {

    return <Button
            className={cnDisapprove({ status: status !== 'Disapproved' ? 'n' : 'y' }, ['Action', className])}
            onClick={() => sendApproveModeration(aid, false, 'false')}>
        {tT('answer.disapprove.1')}
        </Button>
}

export const Disapprove = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DisapprovePresenter));
