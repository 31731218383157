import React, { FC, memo, useMemo } from 'react'
import {
    cnClassName,
    ITaskUnitProps
} from './interfaces'
import './style.scss'
import { withPreloader } from '../Preloader/Preloader'
import { TestInfo as Skelet } from '../../Skelets/TestInfo/TestInfo'
import { withUnitContentLoader } from 'LMSModule/utils/HOCs/withUnitContentLoader'
import { ETestStates } from 'LMSModule/utils/selectTestState'
import { useExam } from 'LMSModule/utils/hooks/useExam'
import { Test } from '../Parts/Test/Test'
import { TestResult } from '../TestResult/TestResult'
import { ListSkiped } from '../ListSkiped/ListSkiped'
import TaskResult from '../TaskResult'
import TaskInfo from '../TaskInfo'

export const Presenter: FC<ITaskUnitProps> = ({
    className,
    children,
    course,
    unit,
    log,
    sId,
    unitContent,
    fullscreen
}) => {
    const { skip, start, view } = useExam(sId, unitContent.id, log, unitContent)
    return <>
        {view === ETestStates.NOT_STARTED && <TaskInfo fullscreen={fullscreen} unitContent={unitContent} start={start} skip={skip} />}
        {view === ETestStates.PROCESSING && <Test fullscreen={fullscreen} course={course} unit={unit} sId={sId} qId={unitContent.currentQuestion} />}
        {view === ETestStates.SKIPPED_LIST && <ListSkiped fullscreen={fullscreen} uid={unit.id} />}
        {view === ETestStates.RESULT && <TaskResult cid={course.id} fullscreen={fullscreen} course={course} unit={unit} sid={sId} ulid={log.unitLogId} />}

    </>
}

const WithPreloader = withPreloader(memo(Presenter), ['unitContent', 'log'], [], <Skelet />)

export const WithUnitContentLoader = withUnitContentLoader(WithPreloader);

export default memo(WithUnitContentLoader);
