import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { RouteComponentProps } from 'react-router';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { addToAdditionalMenu, removeFromAdditionalMenu } from 'utils/src/CommonRedux/base/actions';

export interface IPagesProps extends IClassNameProps, IPagesStateProps, RouteComponentProps<any>, IPagesActionsToProps {
  tag?: 'div';
}

export interface IPagesState {
  text?: string;
  dt?: any;
  editShow: boolean;
  showComments: boolean;
}

export type IPagesStateProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: any) => ({
  authUser: getAuthUser(state),
  getLoadedPage: (name: string) => state.widgets.pages[state.widgets.structures[name]],
  isAdmin: getAuthUser(state).baseData.isAdmin
  // currentUser: getAuthUser(state).baseData,
})

export const PagesActionsToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  addToAdditionalMenu,
  removeFromAdditionalMenu,
}, dispatch);

export type IPagesActionsToProps = ReturnType<typeof PagesActionsToProps>

export const cnPages = cn('Pages');