import React, { PropsWithChildren, useState } from 'react'
import { ArrowRight } from 'muicomponents/src/Icons';
import { ListItemIcon } from 'muicomponents/src/ListItem/ListItem';
import { Menu } from 'muicomponents/src/Menu';
import { MenuItem, MenuItemFlex, MenuItemTextBox, MenuTextCrop } from '../UserMenu.styled';
import { IUserNestedMenu } from './UserNestedMenu.index';

export const UserNestedMenu: React.FC<PropsWithChildren<IUserNestedMenu>> = ({ children, icon, text, title }) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <MenuItem onClick={handleClick}>
        <ListItemIcon>
         {icon}
        </ListItemIcon>

        <MenuItemFlex>
          <MenuItemTextBox>
            <div>{title}</div>
            <MenuTextCrop>{text}</MenuTextCrop>
          </MenuItemTextBox>
          <ArrowRight />
        </MenuItemFlex>
      </MenuItem>
      
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        MenuListProps={{
          sx: {
            width: '100% !important'
          }
        }}
        PaperProps={{
          sx: {
            width: 410,
            maxHeight: 300,
          }
        }}
      >
        {children}
      </Menu>
    </>
  )
}