import React from 'react';
import { withBemMod } from '@bem-react/core';
import './comment.scss'

import { IHeaderLayoutProps, cnHeaderLayout } from '../../HeaderLayout.index';

export interface IHeaderLayoutTypeCommentProps {

}

export const withHeaderLayoutTypeComment = withBemMod<IHeaderLayoutTypeCommentProps, IHeaderLayoutProps>(cnHeaderLayout(), { type: 'comment' }, (Presenter) => (
    (props) => (
        <Presenter {...props} />
    )
));