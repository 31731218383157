import { useDispatch } from 'react-redux';
import { rAttemptAfterComplete, jumpToUnitResult } from "../../redux/actions/LMS";

export const getNexnUnitId = (courseUnits: any[], currentUnitId: string) => {
    const currentIndex = courseUnits.findIndex((unit: any) => unit.id === currentUnitId)
    try {
        if (courseUnits.length >= currentIndex + 1) return courseUnits[currentIndex + 1].id
    } catch (error) {
        return false
    }
    return false

}

export const getPrevUnitId = (courseUnits: any[], currentUnitId: string) => {
    const currentIndex = courseUnits.findIndex((unit: any) => unit.id === currentUnitId)
    try {
        if (courseUnits.length > 0) return courseUnits[currentIndex - 1].id
    } catch (error) {
        return false
    }
    return false

}

export const useResultNav = (course: any, sid: string, uid: string) => {
    const dispatch = useDispatch()
    const attemptAction = () => dispatch(rAttemptAfterComplete(course.id, sid, uid))
    const next = () => dispatch(jumpToUnitResult(course.id, sid, getNexnUnitId(course.courseUnits, uid) || 'result'))
    const prev = () => dispatch(jumpToUnitResult(course.id, sid, getPrevUnitId(course.courseUnits, uid)))

    return {
        attemptAction,
        next,
        prev
    }
}