import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
    // saga
    LOAD_TRACKS: 'LOAD_TRACKS', // получить список учебных планов
    LOAD_FILES: 'LOAD_FILES', // получить список материалов в плане
    News_LEARNED: 'News_LEARNED',
    NEWS_LEARNED: 'NEWS_LEARNED',
    LMSCources_LEARNED: 'LMSCources_LEARNED',
    Quizzes_LEARNED: 'Quizzes_LEARNED',
    PagesV2_LEARNED: 'PagesV2_LEARNED',

    //reducer
    SET_TRACKS: 'SET_TRACKS', //добавить список треков в стор
    SET_FILES: 'SET_FILES', // добавить список материалов в треке

    CHANGE_FIELD: 'CHANGE_FIELD', // изменить указаное поле
    RESET: 'RESET', // сбрасывает указаный объект
} as const

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.TRACKS_USE) }), {} as typeof actions);