import React, { FC, useState } from 'react'
import { IChangePasswordProps } from './ChangePassword.index'
import './ChangePassword.scss'
import { Button, Input, ErrorMsg, Dialog, DialogTitle, CustomScrollbars, DialogContent, CheckboxInput, Spinner } from 'uielements/src';
import { ActionButtons } from 'blocks/ActionButtons/ActionButtons'
import { PasswordInput } from 'blocks/PryanikyForms/PasswordInput/PasswordInput'
import * as utils from 'utils/src/utils'
import i18n from 'localizations/i18n';
import { toast } from 'react-toastify';
import { Translate } from 'localizations/Translate';

/**
 * @deprecated - use src/Dialogs/ChangePassword
 */
export const ChangePassword: FC<IChangePasswordProps> = ({ openBtn, userId, currentUser }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [conPassword, setConPassword] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [request, setRequest] = useState(false)

    const onCloseDialog = () => setShowDialog(false);
    const openDialog = () => setShowDialog(true);

    let isReset = currentUser.baseData.isAdmin ? (userId !== currentUser.baseData.id) : false

    let wrongPassword = newPassword === ''
    wrongPassword = wrongPassword ? true : newPassword !== conPassword;
    wrongPassword = !isReset ? (wrongPassword ? true : oldPassword === '') : wrongPassword;
    wrongPassword = wrongPassword ? true : request;


    const clearFields = () => {
        setOldPassword('')
        setNewPassword('')
        setConPassword('')
        setErrorMsg('')

    }

    const changePwd = () => {
        setRequest(true)
        utils.API.system.pwdChange({ oldPassword, newPassword, auth: utils.getCookie('authAt') }).r.then((d: any) => {
            if (d.error_code !== 0) {
                setErrorMsg(i18n.t('pryaniky.changepassword.error.' + d.error_code))
            } else {
                clearFields()
                toast.success(i18n.t('pryaniky.changepassword.error.' + d.error_code))
                onCloseDialog()
            }
            setRequest(false)
        })
    }

    const resetPwd = () => {
        setRequest(true)
        utils.API.system.pwdReset({ actUserId: userId, newPassword, auth: utils.getCookie('authAt') }).r.then((d: any) => {
            if (d.error_code !== 0) {
                setErrorMsg(i18n.t('pryaniky.changepassword.error.' + d.error_code))
            } else {
                clearFields()
                toast.success(i18n.t('pryaniky.changepassword.error.' + d.error_code))
                onCloseDialog()
            }
            setRequest(false)
        })
    }

    const save = () => {
        isReset ? resetPwd() : changePwd()
    }

    const close = () => {
        clearFields()
        onCloseDialog()
    }

    return <>
        {openBtn ? openBtn(openDialog) : <Button onClick={openDialog}>
            <Translate i18nKey={'pryaniky.changepassword'} />
        </Button>}
        <div className={'ChangePassword'}>
            <Dialog onClose={onCloseDialog} isShown={showDialog}>
                <DialogTitle onClose={onCloseDialog}>
                    <Translate i18nKey={'pryaniky.dialog.changepassword.title'} />
                </DialogTitle>

                <DialogContent>
                    {request && <Spinner />}
                    {errorMsg !== '' && <ErrorMsg className={'ChangePasssword-Msg'}>{errorMsg}</ErrorMsg>}

                    {!isReset && <PasswordInput className={'ChangePasssword-Input'} value={oldPassword} onChange={setOldPassword} placeholder={Translate.t({ i18nKey: 'pryaniky.dialog.changepassword.oldPassword' })} />}

                    <PasswordInput className={'ChangePasssword-Input'} value={newPassword} onChange={setNewPassword} placeholder={Translate.t({ i18nKey: 'pryaniky.dialog.changepassword.newPassword' })} />
                    <PasswordInput className={'ChangePasssword-Input'} value={conPassword} onChange={setConPassword} placeholder={Translate.t({ i18nKey: 'pryaniky.dialog.changepassword.newPassword' })} />

                </DialogContent>

                <ActionButtons className={"ChangePassword-Actions"} saveDisabled={wrongPassword} save={save} cancle={close} />
            </Dialog>
        </div>
    </>
}