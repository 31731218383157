import React, { FC, useState, createRef } from 'react'
import { IThanksSelectorProps } from './ThanksSelector.index'
import { InputLayout } from '../InputLayout/InputLayout'
import { LineInput } from '../LineInput/LineInput'
import { Button, Input, Icon, Dialog, DialogTitle, CustomScrollbars, DialogContent, CheckboxInput } from 'uielements/src';
import { CountSlider } from 'blocks/CountSlider/CountSlider'
import './ThanksSelector.scss'
import { Popover } from 'blocks/NewsTypes/common/Popover/Popover'
import i18n from 'localizations/i18n';
import { connect } from 'react-redux';
import { ThanksInput } from '../ThanksInput/ThanksInput'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';

const ref = createRef<HTMLElement>();
// const inputRef = createRef<HTMLInputElement>();

export const _ThanksSelector: FC<IThanksSelectorProps & any> = ({ onChange, value, min, max, currencyNamePlural = 'Пряники', currencyName = 'Пряник' }) => {
    const [open, setOpen] = useState(false);
    // const [focused, setFocused] = useState(false);

    // const onChangeValue = (e: any) => {
    //     if (e.target.value === '') return onChange('')
    //     if (e.target.value < min) return onChange(min)
    //     if (e.target.value > max) return onChange(max)
    //     onChange(e.target.value)
    // }
    return <div className={'ThanksSelector'}>
        <ThanksInput {...{
            onChange,
            value,
            min,
            max,
            currencyNamePlural,
            currencyName,
            icon: <Button noPadding={true} onClick={() => setOpen(true)}><Icon icon='give' /></Button>
        }} ref={ref}/>
        {open && <Popover anchor={ref.current}>{
            <CountSlider
                onChange={onChange}
                icon={'give'}
                type='modal'
                className={'EditorCreativetasks-CountSlider'}
                value={value}
                closeBtn={() => { setOpen(false) }}
                saveBtn={() => { setOpen(false) }}
            />
        }</Popover>}
    </div>
}

export const ThanksSelector = connect(
    (state: any) => ({
        currencyName: state.store.appSettings.currencyNameNominativeSingular,
        currencyNamePlural: state.store.appSettings.currencyNameGenitivePlural,
        max: getCurrentUser(state).extData.thanksForAll,
    })
)(_ThanksSelector);