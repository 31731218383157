import * as React from 'react';
import { cnSkillParticipants, ISkillParticipantsProps, ISkillParticipantsOwnProps } from './Participants.index';
import { List } from 'blocks/List/_type/users/List_type_users_search';
import { wrapDialogToRedux } from '../../DialogWrapper';
import i18n from 'localizations/i18n';
import {
  DialogTitle,
} from 'uielements/src';

import "./Participants.scss";

const AnyList = List as any
class ParticipantsPresenter extends React.Component<ISkillParticipantsProps, any> {
  public render() {
    const { tId, onClose, userid } = this.props;
    return (
      <>
        <DialogTitle onClose={onClose}>{i18n.t('pryaniky.modal.skills.title')}</DialogTitle>
        <AnyList className={cnSkillParticipants('List')} id={tId} context={{}} requestOptions={{ tagid: tId, showcontext: 'endorsements', userid}} />
      </>
    )
  }
}

export const Participants = wrapDialogToRedux<ISkillParticipantsOwnProps>(ParticipantsPresenter)