import Component, { Presenter } from './component';
import {
    News,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = News.NewsActions.IOwnProps;
export type IComponentPresenterProps = News.NewsActions.IPresenterProps;


export default Component