import { cn } from '@bem-react/classname';
import { IShopItemTimeline } from 'utils/src/requests/models/api.shop';
import { bindActionCreators, Dispatch, Action } from 'redux';

export interface IShopItemProps extends IShopItemOwnProps, IStateProps, IDispatchProps {}

interface IShopItemOwnProps {
  userSex: string;
  data: {
    isDeleted: boolean;
    value: number;
    transferDate: string;
    data: IShopItemTimeline | null;
    transactionModelType: string;
    transactionType: string;
  }
}

export type IStateProps = ReturnType<typeof mapStateToProps>

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

export const mapStateToProps = (state: any) => ({
  currencyNames: {
    currencyNameAccusativeSingular: state.store.appSettings.currencyNameAccusativeSingular,
    currencyNameGenitiveSingular: state.store.appSettings.currencyNameGenitiveSingular,
    currencyNameGenitivePlural: state.store.appSettings.currencyNameGenitivePlural
  } as { [s: string]: string },
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({

}, dispatch);

export const cnCTShopItem = cn('CTShopItem');