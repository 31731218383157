import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from '../../../src/redux/connector';

export interface INotificationCounerProps extends IClassNameProps, INotificationCounerStateProps, IDispatchProps {
  tag?: 'div';
}

export interface INotificationCounerState {
  text?: string;
}

export interface INotificationCounerStateProps {
  notificationCount: number
}

export const mapStateToProps = (state: any) => ({
  notificationCount: state.store.notificationCount
})

export const cnNotificationCouner = cn('NotificationCouner');