import { ITopBadgeProps } from './TopBadge.index'
import React, { memo } from 'react'
import './TopBadge.scss';

export const TopBadge: React.FC<ITopBadgeProps> = memo(({ left, value, right, imgClass }) => {
    return <div className={'TopBadge'}>
        <div className="TopBadge-LeftText">{left}</div>
        <div className='TopBadge-ValueText'>
            <div className={'TopBadge-ImgBox ' + imgClass}>
                <div className='TopBadge-ValueContent'>{value}</div>
            </div>
        </div>
        <div className="TopBadge-RightText">{right}</div>
    </div>
})