import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { NQuickLinks } from '../QuickLinks.index';

export const cnQuickLinksSettings = cn('QuickLinksSettings');

export namespace NQuickLinksSettings {

    export interface Props extends IClassNameProps {
        data?: {
            items?: NQuickLinks.Item[];
        }
        onChange: (value: {[key: string]: any}) => void;
    }
}