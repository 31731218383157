import React from 'react';
import { AbstractList } from '../../List';
import { mapDispatchToProps, IDispatchProps } from '../../../../redux/connector';
import { IListTypeRegistrationsProps, avalibleFilters } from './List_type_registrations.index';
import { mapStateToProps, IListStateProps, IListProps, IListState, cnList, mapActionsToProps } from './../../List.index';
import { IStateType as IState } from 'redux/store';
import { Header } from 'blocks/List/Header/List-Header';
import { connect } from 'react-redux';
import { Translate } from '../../../../localizations/Translate';
import { ListLite, Avatar, Button, Loading } from 'uielements/src';
import { Item } from 'blocks/List/Item/List-Item';

export class ListRegistrationsPresenter extends AbstractList<IListTypeRegistrationsProps, IListState> {
  public static defaultProps = {
    requestOptions: {
      search: '',
    },
  };

  public type = 'registrations';

  constructor(props: IListTypeRegistrationsProps) {
    super(props);
    this.prepareData = this.prepareData.bind(this);
  }



  public getData = () => {
    const { requestOptions, context, edit } = this.props;
    const { clearData, isLoading } = this.props;
    const { clearDataFromList, getUsers, toggle } = this.props;
    const id = this.listId;
    if (edit || isLoading || !this.mounted) return;
    const opts = Object.assign({}, requestOptions);

    Object.keys(context).forEach((cKey: string) => {
      if (avalibleFilters[cKey]) opts[avalibleFilters[cKey]] = context[cKey];
      if (cKey === 'tId') opts['showcontext'] = 'bytag'
    });

    // if (context.filter) {
    //   if (context.filter.type === 'type') opts.catFilter = context.filter.value;
    //   if (context.filter.type === 'group') opts.gid = context.filter.id;
    // }
    // if (context.networkFilter) opts.networkFilter = context.networkFilter;
    // if (context.gId) opts.gid = context.gId;

    if (this.searchString) opts.search = this.searchString;
    if (clearData) {
      clearDataFromList({ id, data: null });
    }
    toggle({
      id,
      data: {
        isLoading: true,
        isFinished: false
      }
    });
    // getUsers.call(this, id, opts);
  };

  public prepareData() {
    
  }


  public renderChildren = () => {
    // const { isFinished, isLoading, selected } = this.state;
    const { data, isFinished, isLoading, hideData } = this.props;
    // const data: any[] = this.state[this.dataVariable];
    return (
      <React.Fragment>
        <Header
          className={'rounded-top'}
          type={'common'}
          search={{
            placeholder: Translate.t({ i18nKey: 'pryaniky.list.users.search' }),
            onChange: () => {},
          }}
        />
        <ListLite className={'rounded-bottom'} isFinished={isFinished} isLoading={isLoading} loadMore={this.getData} noSkeletons={isFinished}>
          {data.map(el => (
            <Item
              lId={this.listId}
              iId={el}
              type={'common'}
              key={el}
              data_key={el}
            />
          ))}
        </ListLite>
      </React.Fragment>
    );
  };
}

export const List = connect<IListStateProps, IDispatchProps, IListProps, IState>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
)(ListRegistrationsPresenter);