import { NDataStorage } from "./interfaces";


/**
 * get data storage reducer current state
 * @param state 
 * @returns 
 */
export const getDSReducer = <S extends { dataStorage: NDataStorage.Reducer } = { dataStorage: NDataStorage.Reducer }> (state: S) => state.dataStorage;


/**
 * get data storage store by defined in ReducerTypeDad store name
 * @param storeName 
 * @returns 
 */
export const getDSStoreByName = <SN extends keyof ReturnType<typeof getDSReducer>> (storeName: SN) => (...args: Parameters<typeof getDSReducer>) => getDSReducer(...args)[storeName];


/**
 * get data storage item from storage by name in defined in keys at ReducerTypeDad with type ReducerTypeDad[key]
 * @param storeName 
 * @param id 
 * @returns 
 */
export const getDSStoreItemByNameAndId = <SN extends keyof ReturnType<typeof getDSReducer>> (storeName: SN, id: keyof NDataStorage.Reducer[SN]) => (...args: Parameters<typeof getDSReducer>) => getDSStoreByName(storeName)(...args)[id];

