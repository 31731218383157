import * as React from 'react';
import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';

import { withBemMod } from '@bem-react/core';

import { cnBody } from '../Modal-Body.index';
import { IBodyTypeLayoutWidthProps, IBodyTypeLayoutWidthState } from './Modal-Body_type_layoutWidth.index';
import './Modal-Body_type_layoutWidth.scss';
import { cn } from '@bem-react/classname';
import { Icon } from 'uielements/src/Icon/Icon';
import { IColumn } from 'i.widgets';
import { NumberTextField } from 'muicomponents/src/TextField/TextField';
import { cloneDeep } from 'lodash';

const cnWidthChanger = cn('WidthChanger');

const minColumnWidth = 15;

class NewBody extends React.Component<IBodyTypeLayoutWidthProps, IBodyTypeLayoutWidthState> {
  public utils: any = utils;
  public el: HTMLElement | null;
  public changeContainer: HTMLElement | null;
  public currentPushedButton: any;

  constructor(props: IBodyTypeLayoutWidthProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();

    const layout = this.props.parent.state.data.layout;

    this.state = {
      inputs: layout.data.reduce((a: any, c: IColumn, idx: number) => ({
        ...a,
        [idx]: Number(c.styles?.width?.toString().replace('%', '') || 0).toFixed(2)
      }), {}),
      layout
    };
  }

  // public componentDidMount() {}

  public changeInput: React.ComponentProps<typeof NumberTextField>['onChange'] = (e) => {
    const currentIndexId = e.currentTarget.id;
    let currentNewValue = e.target.value;
    const currentItemIndex = Number(currentIndexId.split('-')[1]);

    const layout = cloneDeep(this.state.layout);
    if(layout.data && currentNewValue) {
      const сlosestItemIndex = layout.data[currentItemIndex + 1] ? currentItemIndex + 1 : currentItemIndex - 1;

      const currentIndexValue = Number(layout.data[currentItemIndex].styles?.width?.toString().replace('%', '') || 0);
      const closestIndexValue = Number(layout.data[сlosestItemIndex].styles?.width?.toString().replace('%', '') || 0);

      const bothMaxValue = currentIndexValue + closestIndexValue;
      const maxCurrentValue = bothMaxValue - minColumnWidth;
      
      if(currentNewValue < minColumnWidth) {
        currentNewValue = minColumnWidth;
      }

      if(currentNewValue > maxCurrentValue) {
        currentNewValue = maxCurrentValue;
      }

      let closesNewValue = bothMaxValue - currentNewValue;

      layout.data[currentItemIndex].styles = {
        width: currentNewValue.toFixed(2) + '%'
      };
      layout.data[сlosestItemIndex].styles = {
        width: closesNewValue.toFixed(2) + '%'
      };

      this.setState({
        inputs: {
          ...this.state.inputs,
          [currentItemIndex]: e.target.value,
          [сlosestItemIndex]: closesNewValue.toFixed(2)
        },
        layout
      });
      this.props.parent.state.data.layout = layout;
    } else {
      this.setState({
        inputs: {
          ...this.state.inputs,
          [currentItemIndex]: e.target.value
        }
      });
    }
  }

  public render() {
    const customClassNames = '';
    const { children, className = '', tag: TagName = 'div' } = this.props;
    const { layout } = this.state;
    return (
      <TagName ref={el => this.el = el} className={cnBody({}, [className, customClassNames])}>
        <div className={cnWidthChanger()} ref={el => this.changeContainer = el}>
          {layout.data && layout.data.map((column: IColumn, i: number, arr: IColumn[]) => {
            const widthText = Number(column.styles?.width?.toString().replace('%', '') || 0).toFixed(2);
            const view = [
              <div key={column.id} className={cnWidthChanger('Column')} style={column.styles}>
                {/* <span />
                <span>
                  {widthText} %
                </span> */}
                <NumberTextField
                  size='small'
                  hideApperance
                  variant='standard'
                  value={this.state.inputs[i]}
                  allowFloatingPoint
                  onBlur={() => {
                    this.setState((prevState) => {
                      return {
                        ...prevState,
                        inputs: {
                          ...prevState.inputs,
                          [i]: widthText
                        }
                      };
                    })
                  }}
                  onChange={this.changeInput}
                  inputProps={{
                    id: `input-${i}`,
                    style: {
                      textAlign: 'center'
                    }
                  }}
                />
                %
              </div>
            ];
            if(i !== 0) view.unshift(<div onMouseDown={this.mouseDown} key={'b' + i} id={'button-' + i} className={cnWidthChanger('Dragger')} children={<Icon icon={'grip-lines-vertical'} />} />);
            return view
          })}
        </div>
        {children}
      </TagName>
    )
  }

  private mouseDown = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
    this.currentPushedButton = ev.currentTarget;
    
    document.addEventListener('mousemove', this.mouseMove, false);
    document.addEventListener('mouseup', this.mouseUp, false);
  }

  private mouseUp = (ev: any) => {
    ev.stopPropagation();
    ev.preventDefault();
    this.currentPushedButton = undefined;

    document.removeEventListener('mousemove', this.mouseMove, false);
    document.removeEventListener('mouseup', this.mouseUp, false);
  }

  private mouseMove = (ev: any) => {
    ev.stopPropagation();
    ev.preventDefault();

    if(this.changeContainer && this.currentPushedButton) {
      const containerRect = this.changeContainer.getBoundingClientRect();
      let twoCollumnWidth = 0;
      const buttonIndex = Number(this.currentPushedButton.id.split('-')[1]);
      const halfButtonWidth = this.currentPushedButton.clientWidth / 2;

      let position = ev.clientX - containerRect.left - (halfButtonWidth * buttonIndex);
      if(buttonIndex > 1) position -= (halfButtonWidth * (buttonIndex - 1));
      if(position < 0) position = 0;
      if(position > containerRect.width) position = containerRect.width;
      position = (position * 100) / containerRect.width;
      if(position < minColumnWidth) position = minColumnWidth;
      if(position > (100 - minColumnWidth)) position = 100 - minColumnWidth;

      if(this.state.layout.data) {
        this.state.layout.data.forEach((col: IColumn, i: number) => {
          if(col.styles) {
            if(buttonIndex > 1 && i < buttonIndex - 1) position -= parseFloat(col.styles.width + '');
            if(
              i === buttonIndex || 
              i === buttonIndex - 1
              ) {
              twoCollumnWidth += parseFloat(col.styles.width + '');
            }
          }
        })
      }

      let berofeButtonColumnWidth = position;
      let afterButtonColumnWidth = twoCollumnWidth - position;

      if(berofeButtonColumnWidth < minColumnWidth) {
        berofeButtonColumnWidth = minColumnWidth;
        afterButtonColumnWidth = twoCollumnWidth - minColumnWidth;
      }

      if(twoCollumnWidth - berofeButtonColumnWidth < minColumnWidth) {
        berofeButtonColumnWidth = twoCollumnWidth - minColumnWidth;
      }
      
      if(afterButtonColumnWidth < minColumnWidth) {
        afterButtonColumnWidth = minColumnWidth;
      }

      const layout = this.state.layout;
      if(this.state.layout.data) {
        layout.data[buttonIndex - 1].styles = {
          width: berofeButtonColumnWidth.toFixed(2) + '%'
        };
        layout.data[buttonIndex].styles = {
          width: afterButtonColumnWidth.toFixed(2) + '%'
        };
      }
      this.setState((prevState) => ({
        ...prevState,
        inputs: {
          ...prevState.inputs,
          [buttonIndex - 1]: berofeButtonColumnWidth.toFixed(2),
          [buttonIndex]: afterButtonColumnWidth.toFixed(2)
        },
        layout
      }));
    }

  }
}


export const withBodyTypeLayoutWidth = withBemMod<any>(
  cnBody(),
  { type: 'layoutWidth' }, 
  (Presenter) => (
    (props: IBodyTypeLayoutWidthProps) => (
      <NewBody {...props}/>
    )
  )
);