import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    IPresenterProps
} from './interfaces'
import './style.scss'
import { SummaryTable } from '../SummaryTable/SummaryTable'
import { Chart } from '../Chart/Chart'

export const Presenter: FC<IPresenterProps> = ({
    className,
    rightPercent,
    wrongPercent,
    waitModerationCount = 0,
    moderatedAnswersCount = 0,
}) => {
    return <div className={cnClassName({}, [className])}>

        <SummaryTable
            className={cnClassName('SummaryTable')}
            rightPercent={rightPercent}
            wrongPercent={wrongPercent}
            moderatedAnswersCount={moderatedAnswersCount}
            waitModerationCount={waitModerationCount} />

        <Chart
            className={cnClassName('Chart')}
            rightPercent={rightPercent}
            wrongPercent={wrongPercent}
            moderatedAnswersCount={moderatedAnswersCount}
            waitModerationCount={waitModerationCount} />

    </div>
}

export default memo(Presenter);
