import React, { FC } from 'react'
import { ISummaryTableProps, cnSummaryTable } from './SummaryTable.index'
import './SummaryTable.scss'
import { tC } from 'LMSModule/utils/i18n.adaptor'

export const SummaryTablePresenter: FC<ISummaryTableProps> = ({ className, rightPercent, wrongPercent, waitModerationCount = 0, moderatedAnswersCount = 0 }) => {

    return <table className={cnSummaryTable({}, [className])}>
        <tbody>
            {waitModerationCount === 0 && <>
                <tr>
                    <td>
                        <div className={cnSummaryTable('SummaryAnswers')}>{tC('answers.1')}</div>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <div className={cnSummaryTable('SummaryTitle')}>{tC('success.2')}</div>
                    </td>
                    <td>
                        <div className={cnSummaryTable('Value', { type: 'success' })}><b>{rightPercent}</b>%</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className={cnSummaryTable('SummaryTitle')}>{tC('wrong.2')}</div>
                    </td>
                    <td>
                        <div className={cnSummaryTable('Value', { type: 'wrong' })}><b>{wrongPercent}</b>%</div>
                    </td>
                </tr>
            </>}
            {waitModerationCount !== 0 && <>
                <tr>
                    <td>
                        <div className={cnSummaryTable('SummaryTitle')}>{tC('moderated.1')}</div>
                    </td>
                    <td>
                        <div className={cnSummaryTable('Value', { type: 'moderated' })}><b>{moderatedAnswersCount}</b></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className={cnSummaryTable('SummaryTitle')}>{tC('wait.2')}</div>
                    </td>
                    <td>
                        <div className={cnSummaryTable('Value', { type: 'wait' })}><b>{waitModerationCount}</b></div>
                    </td>
                </tr>
            </>}
        </tbody>
    </table>
}


export const SummaryTable = SummaryTablePresenter;