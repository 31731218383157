import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generateCssFromMainColor } from 'utils.project/utils.project';
import { checkResponseStatus, getCookie } from 'utils/src';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { getUserById } from 'utils/src/CommonRedux/users/selectors';
import { byId as groupById } from 'utils/src/requests/requests.groups';
import { byId as userById, list as usersList } from 'utils/src/requests/requests.users';
import {
    GetActions,
    GetCurrentFrameActions,
    GetCustomActions,
    PostActions,
    PostCurrentFrameActions,
    PostCustomActions
} from './actions';
import { PostDataToFrame } from './ExternalActions.hooks';
import { ExternalDialog, ExternalDialogProps } from './ExternalDialog';
import { useEditorActions } from './ExternalEditor';
import { v1 as uuid } from 'uuid';
import { useQueuedState } from 'utils/src/hooks';
import { getWidgetContextById } from 'Widgets_v2/selectors';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
// import {
//     Frames
// } from './ExternalActions.index';

type Frames = {
    [id: string]: {
        widgetId: string;
        node: HTMLElement | null;
    }
}

const dataLog = (...args: Parameters<typeof console.log>) => {
    if(!(window as any).externalActionsLog) return ;
    console.log(...args);
}

const errorLog = (action: string, e: any) => {
    if(!(window as any).externalActionsLog) return ;
    console.error(`external actions error at ${action} with error: ${e}`);
}

type HistoryAction = {
    action?: 'push' | 'replace',
    href: string
};

export const useExternalActions = () => {

    const history = useHistory();

    const frames = useRef<Frames>({});
    const dialogsInitData = useRef<{[key: string]: ExternalDialogProps['initData']}>({});

    const contexts = useSelector((state: any) => state.widgets.contexts);
    const contextsRef = useRef(contexts);
    contextsRef.current = contexts;

    const currentUser = useSelector(getCurrentUser);
    const companySettings = useSelector((state: any) => state.store.appSettings);
    const appDesign = useSelector((state: any) => state.store.appDesign);
    const designCssString = useMemo(() => {
        let str = generateCssFromMainColor(appDesign.colors);
        str += appDesign.customCss;
        return str;
    }, [appDesign]);

    const getUserByIdData = useCallback(async function({ id }: {id: string}) {
        const response = await userById(id).r;
        if(checkResponseStatus(response)) {
            return response.data
        } else {
            return { error: true }
        }
    }, []);

    const getGroupByIdData = useCallback(async function({ id }: {id: string}) {
        const response = await groupById(id).r;
        if(checkResponseStatus(response)) {
            return response.data
        } else {
            return { error: true }
        }
    }, []);
    
    const registerResponse = useCallback((data: any, frame: any) => {
        const node = document.getElementById(frame);
        const widgetId = (node?.closest('.Widget') || node?.closest('.WidgetMui'))?.getAttribute('data-id') || '';
        frames.current[frame] = {
            widgetId,
            node
        };
        dataLog('frame inited');
        const rez: any = { inited: true };
        if(dialogsInitData.current[frame]) {
            rez.initData = dialogsInitData.current[frame];
        }
        return rez;
    }, []);

    const init = PostDataToFrame(GetActions.registerFrame, PostActions.postRegisterFinish, registerResponse);
    useEffect(() => {
        init()
        .then((d) => {
        })
        .catch((e) => {
            errorLog('postRegisterFinish', e);
        });
    }, [init]);

    const getCompanySettingsPromise = PostDataToFrame(GetActions.getCompanySettings, PostActions.postCompanySettings, companySettings);
    useEffect(() => {
        getCompanySettingsPromise()
        .then((d) => {
            dataLog('company settings sended');
        })
        .catch((e) => {
            errorLog('postCompanySettings', e);
        });
    }, [getCompanySettingsPromise]);

    const getCurrentUserPromise = PostDataToFrame(GetActions.getCurrentUser, PostActions.postCurrentUser, currentUser);
    useEffect(() => {
        getCurrentUserPromise()
        .then((d) => {
            dataLog('current user sended');
        })
        .catch((e) => {
            errorLog('postCurrentUser', e);
        });
    }, [getCurrentUserPromise]);

    const getUserByIdPromise = PostDataToFrame(GetActions.getUserById, PostActions.postUserById, getUserByIdData);
    useEffect(() => {
        getUserByIdPromise()
        .then((d) => {
            dataLog('user sended');
        })
        .catch((e) => {
            errorLog('postUserById', e);
        });
    }, [getUserByIdPromise]);

    const getGroupByIdPromise = PostDataToFrame(GetActions.getGroupById, PostActions.postGroupById, getGroupByIdData);
    useEffect(() => {
        getGroupByIdPromise()
        .then((d) => {
            dataLog('group sended');
        })
        .catch((e) => {
            errorLog('postGroupById', e);
        });
    }, [getGroupByIdPromise]);
    
    const getCompanyStylesPromise = PostDataToFrame(GetActions.getCompanyStyles, PostActions.postCompanyStyles, designCssString);
    useEffect(() => {
        getCompanyStylesPromise()
        .then((d) => {
            dataLog('styles sended');
        })
        .catch((e) => {
            errorLog('postCompanyStyles', e);
        });
    }, [getCompanyStylesPromise]);

    const getAuth = useCallback(async function() {
        return getCookie('auth');
    }, []);
    const getAuthPromise = PostDataToFrame(GetCustomActions.getAuth, PostCustomActions.postAuth, getAuth);
    useEffect(() => {
        getAuthPromise()
        .then((d) => {
            dataLog('auth sended');
        })
        .catch((e) => {
            errorLog('getAuthPromise', e);
        });
    }, []);

    const openDialog = useCallback(async function(params: ExternalDialogProps) {
        try {
            const id = `dialogFrame-${uuid()}`;
            if(params.initData) {
                dialogsInitData.current[id] = params.initData;
            }
            const dialogRezult = await ExternalDialog({
                id,
                ...params
            });
            if(dialogsInitData.current[id]) {
                delete dialogsInitData.current[id];
            }
            return dialogRezult;
        } catch (error) {
            console.error(error);
        }
    }, []);

    const openDialogPromise = PostDataToFrame(GetActions.showDialog, PostActions.postResultDialog, openDialog);
    useEffect(() => {
        openDialogPromise()
        .then((d) => {
            dataLog('dialog worked full');
        })
        .catch((e) => {
            errorLog('openDialogPromise', e);
        });
    }, [openDialogPromise]);

    const setFrameHeight = useCallback(async function(params: { height: number }, frameId: string) {
        try {
            dataLog('params', params);
            dataLog('frame', frames.current[frameId]);
            frames.current[frameId]?.node?.setAttribute('height', `${params.height}px`)
            dataLog('height setted');
        } catch (error) {
            console.error(error);
        }
    }, []);

    const setFrameHeightPromise = PostDataToFrame(GetActions.setFrameHeight, PostActions.setFrameHeightFinish, setFrameHeight);
    useEffect(() => {
        setFrameHeightPromise()
        .then((d) => {
        })
        .catch((e) => {
            errorLog('setFrameHeightPromise', e);
        });
    }, [setFrameHeightPromise]);


    const getGroupMembers = useCallback(async function({
        id,
        count,
        skipCount,
        search
    }: {
        id: string,
        count: number,
        skipCount?: number,
        search?: string
    }) {
        const response = await usersList({
            count,
            skipCount,
            gid: id,
            search
        }).r;
        if(checkResponseStatus(response)) {
            return response.data
        } else {
            return { error: true }
        }
    }, []);

    const getGroupMembersByGroupIdPromise = PostDataToFrame(GetActions.getGroupMembersByGroupId, PostActions.postGroupMembersByGroupId, getGroupMembers);
    useEffect(() => {
        getGroupMembersByGroupIdPromise()
        .then((d) => {
            dataLog('group members sended');
        })
        .catch((e) => {
            errorLog('getGroupMembers', e);
        });
    }, [getGroupMembersByGroupIdPromise]);

    const getContext = useCallback((data: any, frame: any) => {
        let id = frame;
        if(frame instanceof HTMLElement) {
            id = frame.getAttribute('id');
        }
        if(!frames.current[id].widgetId) return null;
        let rez: any = {};
        const contexts: any[] = [];
        let itemContext = contextsRef.current[frames.current[id].widgetId];
        while(itemContext.__parent) {
            contexts.push(itemContext);
            itemContext = contextsRef.current[itemContext.__parent];
        }
        contexts.push(itemContext);
        let item = contexts.pop();
        while(item) {
            rez = {
                ...rez,
                ...item
            }
            item = contexts.pop()
        }
        return rez;
    }, []);
    const getContextPromise = PostDataToFrame(GetActions.getContext, PostActions.postContext, getContext);
    useEffect(() => {
        getContextPromise()
        .then((d) => {
            dataLog('context sended');
        })
        .catch((e) => {
            errorLog('get context', e);
        });
    }, [getContextPromise]);

    const pushHistory = useCallback((data: HistoryAction, frame: any) => {
        let {
            action,
            href
        } = data;
        if(!action) {
            action = 'push';
        }
        if(!href) return `History action with href="${href} is wrong`;
        history[action](href);
    }, []);
    const pushHistoryPromise = PostDataToFrame(GetActions.history, PostActions.historyResponse, pushHistory);
    useEffect(() => {
        pushHistoryPromise()
        .then((d) => {
            dataLog('push history success');
        })
        .catch((e) => {
            errorLog('push history context', e);
        });
    }, [pushHistoryPromise]);

    const toastHandler = useCallback((data: {
        action: 'success' | 'info' | 'warn' | 'error',
        view: any
    }) => {
        toast[data.action](data.view);
    }, []);
    const toastHandlerPromise = PostDataToFrame(GetActions.toast, PostActions.toast, toastHandler);
    useEffect(() => {
        toastHandlerPromise()
        .then((d) => {
            dataLog('toast history success');
        })
        .catch((e) => {
            errorLog('push history context', e);
        });
    }, [toastHandlerPromise]);

};

export const ExternalActions: FC<{}> = ({

}) => {

    useExternalActions();

    useEditorActions();

    return null;
};