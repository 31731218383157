import { useSOCOrgchartUnitsTreeManager } from "./Filters_type_socogchartunits.hooks";

export const getReqursiveSOCOrgchartUnitsChildrensIds = (els: ReturnType<typeof useSOCOrgchartUnitsTreeManager>['items']) => {
    const rez: (typeof els)[number]['id'][] = [];
    els.forEach(el => {
        rez.push(el.id);
        if(el.childrens) {
            rez.push(...getReqursiveSOCOrgchartUnitsChildrensIds(el.childrens))
        }
    })
    return rez;
};