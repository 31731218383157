import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
// import { } from 'redux/actions/LMSTeacherOffice'

export interface ISearchProps extends IClassNameProps, ISearchStateProps, IDispatchProps, RouteComponentProps, ISearchDispatchProps {
    tag?: 'div';
}

export interface ISearchState {

}

export interface ISearchStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    // course: state.LMSTO.courses.values[props.cid],
    // units: state.LMSTO.moderation.units,
    // ids: state.LMSTO.moderation.results
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export type ISearchDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnSearch = cn('TOSearch');