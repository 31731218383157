import { ManifestType } from '../types'
import img from './university.svg'

export const manifest: ManifestType = {
    id: 'game',
    name: 'game',
    title: 'Геймификация',
    imgUrl: img,
    disabled: false
}

export default manifest