import React from 'react';
import { Box, Button, styled } from 'muicomponents/src';
import { IContentItemListProps, cnHierarchyWidgetNew } from '../Widget_type_hierarchyNew.index';
import { Translate } from 'localization';
import { Structure } from './Structure';
import { openDialogShowAll } from './DialogBodyShowAll';
import { ContentItem } from './ContentItem';

const ButtonNoTransform = styled(Button)({
    textTransform: 'none',
    width: 'fit-content',
});

export const ContentItemsList = ({
    items,
    setItems,
    title,
    onDelete,
    countOfVisibleItemsInWidget,
    ...props
}: IContentItemListProps) => {
    const { settings } = props;
    const hierarchyName = settings?.hierarchy.name;

    const itemsInContent: JSX.Element[] = [];
    if (Array.isArray(items)) {
        for (let index = 0; index < Math.min(items.length, countOfVisibleItemsInWidget); index++) {
            itemsInContent.push(<ContentItem item={items[index]} hierarchyName={hierarchyName} onDelete={onDelete} />);
        }
    }

    const itemsInDialog = Array.isArray(items)
        ? items.map((item) => {
              return <ContentItem item={item} hierarchyName={hierarchyName} onDelete={onDelete} />;
          })
        : [];

    return (
        <Box className={cnHierarchyWidgetNew('Content')} sx={{ display: 'flex', gap: '18px', flexDirection: 'column' }}>
            {itemsInContent}
            {items.length > countOfVisibleItemsInWidget && (
                <ButtonNoTransform
                    variant="text"
                    onClick={() => {
                        openDialogShowAll({
                            children: (
                                <Structure {...props} items={items} setItems={setItems} title={title}>
                                    {itemsInDialog.length > 0 && (
                                        <Box
                                            className={cnHierarchyWidgetNew('Content')}
                                            sx={{
                                                display: 'flex',
                                                gap: '18px',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {itemsInDialog}
                                        </Box>
                                    )}
                                </Structure>
                            ),
                        });
                    }}
                >
                    <Translate i18nKey={'pryaniky.widgets.users.participants.moex.more'} />
                </ButtonNoTransform>
            )}
        </Box>
    );
};
