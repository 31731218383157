import { generateWidget, generateColumn, widgets as allWidgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import i18n from '../localizations/i18n';
import { widgets } from './widgets';

export const welcome = (props: { type: string; subtype: string }): IWidget<IColumn[]> => {
  return generateWidget({
    type: allWidgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: allWidgets.types.tabsControl,
            data: [
              generateColumn({
                name: 'license',
                items: [
                  generateWidget({
                    type: allWidgets.types.layout,
                    data: [
                      generateColumn({
                        items: [
                          generateWidget({
                            type: allWidgets.types.license,
                            settings: {
                              okText: '',
                              isRequired: false,
                              mobileLicense: false
                            }
                          })
                        ]
                      })
                    ]
                  })
                ]
              }),
              generateColumn({
                name: 'userInfo',
                items: [
                  generateWidget({
                    type: allWidgets.types.layout,
                    data: [
                      generateColumn({
                        items: [
                          generateWidget({
                            type: allWidgets.types.info
                          })
                        ]
                      })
                    ]
                  })
                ]
              }),
              generateColumn({
                name: 'info',
                items: [
                  generateWidget({
                    type: allWidgets.types.layout,
                    data: [
                      generateColumn({
                        items: [
                          generateWidget({
                            type: allWidgets.types.wiki
                          })
                        ]
                      })
                    ]
                  })
                ]
              })
            ]
          })
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  });
}