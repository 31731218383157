import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    sendLikeNews
} from '../../redux/actions'

import {
} from '../../redux/reducer/types'
import {
    getNewsById
} from '../../redux/saga/selectors'
import { NewsContextProps } from '../../contexts/news'
import { ReplyContextProps } from '../../contexts/reply'
import {
    sendLikeReply,
    insertMention
} from 'News/redux/actions'
import { IComponentAcceptor } from 'utils/src/ComponentInjector'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace ReplyActions {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & IComponentAcceptor

        export interface IState { }

        export interface IOwnProps extends IClassNameProps, ReplyContextProps {

        }

        export interface IStateProps {
            allowReply: boolean
        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.ReplyActions.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    allowReply: getNewsById(props.newsId)(state).allowReply
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    insertMention,
    sendLikeReply
}, dispatch);

////////////

export const cnClassName = cn('ReplyActions');