import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { RouteComponentProps } from 'react-router';
import { IDispatchProps } from 'redux/connector';
import { setContext } from 'redux/actions/Widgets';
// import { getNewsByIdRequest } from 'redux/actions/News';

export interface INewsProps extends IClassNameProps, RouteComponentProps<{
  id: string
}>, IDispatchProps, INewsStateProps, IActionsDispatch {}

export interface INewsState {
  text?: string;
}

export interface IActionsDispatch {
  setContext: any;
}

export const mapActionsToProps: IActionsDispatch = {
  setContext
};

export interface INewsStateProps {
  // context: { [s: string]: any };
  // news: { [s: string]: any };
}

export const mapStateToProps = (state: any) => ({
  // context: state.widgets.contexts,
  // news: state.news.news
})

export const cnNews = cn('News');