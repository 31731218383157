import React, { FC, memo, useCallback, useRef } from 'react'
import {
    cnClassName,
    Shop
} from './interfaces'
import './style.scss'
import { Translate } from 'localizations/Translate';
// import { Button, HelpTooltip } from 'uielements/src';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { orderIsDenied } from '../validation'
import i18n from 'localizations/i18n';
import * as utilsProj from 'utils.project/utils.project';
import { countMissingThanks } from '../utils'
import { Button } from 'muicomponents/src/Button/Button'

type IPresenterProps = Shop.OrderBlock.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    disableOrder,
    characteristics,
    maxOrdersCount,
    maxOrdersPeriod,
    ordersCount,
    thanksPrice,
    myThanksCount,
    id,
    showDialog,
    currencyFormats,
    productIsAvailable
}) => {

    const isNotCanBuy = orderIsDenied({ characteristics, maxOrdersCount, maxOrdersPeriod, ordersCount })
    const missingThanks = countMissingThanks(thanksPrice, myThanksCount)

    return <div className={cnClassName({}, [className])}>
        {!productIsAvailable ?
            <span className={cnClassName('NotAvailable')}>
                {i18n.t("pryaniky.shop.product.isOver")}
            </span>
            :
            <>
                {disableOrder ? <>
                    {missingThanks > 0 ? (
                        <>
                            <Translate i18nKey='missing' /> <b className={cnClassName('MissingValue')}>{missingThanks}</b> {utilsProj.getFormattedCurrencyName(currencyFormats, missingThanks, 'genitive')}
                        </>
                    ) : (
                        <Tooltip
                            className={'InfoIcon'}
                            title={isNotCanBuy || ''}
                        >
                            <span>
                                <Button variant={'contained'} className={cnClassName('Order')} disabled={Boolean(isNotCanBuy)} onClick={showDialog}>
                                    {i18n.t('pryaniky.shop.order')}
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                </>
                    :
                    <Button variant={'contained'} className={cnClassName('Order')} to={`/shop/product/${id}`}>
                        {i18n.t('pryaniky.shop.item.open')}
                    </Button>
                }
            </>
        }
    </div >
}

export default memo(Presenter);
