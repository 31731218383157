import { createDefaultPostNews } from '../../baseNews';
import { NIdeas } from './Ideas.interface';

export const baseIdeas: NIdeas.Create = {
    ...createDefaultPostNews(),
    newstype: 21,
    componentRenderName: 'ideas',
    idea: {
        additionalFieldsValues: [],
        additionalFieldsValuesV2: []
    }
};