/**
 * @packageDocumentation
 * @module Widget_Settings_sliderlink
 */
import { JFdictionary } from "../dictionary";
import { GUID_EMPTY } from "utils/src/constants.prn";
import { IVirtualUserItemData } from "utils/src/requests/models/api.virtualuser";
import { v1 as uuid } from 'uuid';
import { Translate } from './../../../../localizations/Translate'

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.input,
      scope: "#/properties/src"
    },
    {
      type: renderers.number,
      scope: "#/properties/height"
    },
    {
      type: renderers.checkbox,
      label: Translate.t({ i18nKey: 'pryaniky.widgets.settings.placeholder.wihtAuth' }),
      scope: "#/properties/withAuth"
    }
  ]
}