import { Answer } from './Polls.types'
import uuid from 'uuid/v1';
import * as utils from 'utils/src/utils';

export const changePoll = (newsId: string, answers: Answer[]) => {
    const data = {
        newsUid: newsId,
        data: answers
    }
    utils.API.news.addPollAnswer(data).r
        .then((d: any) => {
            if (d.error_code === 0) {

                //toast.success(d.error_text);
            } else {
                //toast.error(d.error_text);
            }
        });
}