import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext } from '../../contexts/news'
import NewsComments from '../NewsComments'
import NewsActions from '../NewsActions'

type IPresenterProps = News.CommentsView.IPresenterProps
type IOwnProps = News.CommentsView.IOwnProps
type IDispatchProps = News.CommentsView.IDispatchProps
type IStateProps = News.CommentsView.IStateProps
type IState = News.CommentsView.IState


export const Presenter: FC<IPresenterProps> = ({}) => {
    return <NewsComments className={cnClassName({}, [])} />
}

export const CommentsWithActions = withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(({ }) => {
    return <div>
        <NewsActions />
        <NewsComments className={cnClassName({}, [])} />
    </div>
})), []);


export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)), []);
