import React, { useEffect } from 'react';
import { ICurrencyTimelineProps } from './CurrencyTimelineNew.index';
import { useDispatch, useSelector } from 'react-redux';
import { ListLite } from 'uielements/src';
import { IStateType as IState } from 'redux/store';
import { getCurrencyTimeline, changeCurrencyTimelineContext, resetDataCurrecyTimelline } from 'redux/currencyTimeline/actions';
import { getUserById } from 'utils/src/CommonRedux/users/selectors';
import { ICurrencyTimelineContext } from 'redux/currencyTimeline/interfaces';
import { PaperBox } from 'blocks/InfoBlock/UserNew/Avatar/styled';
import { TimelineFilters } from './TimelineFilters/TimelineFilters';
import { TimelineItemNew } from './Item/CurrencyTimelineItemNew';
import { Timeline } from '@material-ui/lab';
import { UserRatingsSkeleton } from 'blocks/UserRatings/UserRatingsSkeleton';


export const CurrencyTimeLineNew: React.FC<ICurrencyTimelineProps> = ({ currentUserId }) => {

  const currencyTimeline = useSelector((state: IState) => state.currencyTimeline)
  const context: ICurrencyTimelineContext = currencyTimeline.context

  const userSex = useSelector(getUserById(currentUserId))?.baseData.sex
  const finished = currencyTimeline.finished
  const loading = currencyTimeline.loading
  const data = currencyTimeline.data

  const dispatch = useDispatch()
  const getCurrencyTimelineData = (payload: { skipCount: number, count: number, context: any, currentUserId: string }) => {
    dispatch(getCurrencyTimeline(payload))
  }
  const changeCurrTimelineContext = (payload: Partial<ICurrencyTimelineContext>) => {
    dispatch(changeCurrencyTimelineContext(payload))
  }
  const resetDataCurrencyTimeline = () => dispatch(resetDataCurrecyTimelline())
  const count = (data?.length || 0) + 20;

  useEffect(() => {
    resetDataCurrencyTimeline()
  }, [currentUserId]);

  if (!userSex) return <UserRatingsSkeleton />


  return <>

    <TimelineFilters
      context={context}
      userSex={userSex}
      changeCurrTimelineContext={changeCurrTimelineContext} />

    <PaperBox>

      <ListLite
        isFinished={finished}
        isLoading={loading}
        skeleton='post'
        loadMore={() => {
          getCurrencyTimelineData({ skipCount: data?.length ? count - 20 : 0, count, context, currentUserId })
        }}
      >

        <Timeline>
          {data.map((el, i) => {
            const timelineInfo = {
              value: el.value,
              transferDate: el.transferDate,
              isDeleted: Boolean(el.isOrderDeleted),
              productData: el.commonProduct,
              transactionModelType: el.transactionModelType,
              transactionType: el.transactionType,
              newsItem: el.news
            };
            return <TimelineItemNew key={i} data={timelineInfo} userSex={userSex} />
          })}
        </Timeline>

      </ListLite>

    </PaperBox>
  </>
}
