/**
 * @packageDocumentation
 * @module Widget_Settings_ratingmini
 */
import { JFdictionary } from "../dictionary";
import i18n from '../../../../localizations/i18n';

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.ratingsInput,
      scope: "#/properties/rating"
    },
    {
      type: renderers.checkbox,
      label: i18n.t('pryaniky.widgets.settings.type.shop.noData'),
      scope: "#/properties/hideIfNoData"
    },
  ]
}