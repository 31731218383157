import { getQuestionById } from '../../redux/sagas/LMSTO/selectors'

export const getQuestionAnswersErrors = (state: any, uid: string, qid: string) => {
    if (state.LMSTO.unitErrors[uid] && state.LMSTO.unitErrors[uid].questionsAnswers) {
        return state.LMSTO.unitErrors[uid].questionsAnswers.includes(qid)
    }
    return false
}

export const getErrors = (state: any, uid: string) => {
    if (state.LMSTO.unitErrors[uid]) {
        return state.LMSTO.unitErrors[uid]
    }
    return {}
}

export const getQuestionsErrors = (state: any, qid: string) => {
    if (state.LMSTO.questionErrors[qid]) {
        return state.LMSTO.questionErrors[qid]
    }
    return {}
}

export const getAnswersErrors = (state: any, aid: string) => {
    if (state.LMSTO.answerErrors[aid]) {
        return state.LMSTO.answerErrors[aid]
    }
    return {}
}

export const getAnswersErrorsByQuestion = (state: any, qid: string) => {
    const aids: string[] = getQuestionById(qid)(state).answers
    const errors = aids.map(aid =>
        getAnswersErrors(state, aid)).filter((item: any) =>
            Object.values(item).filter((v: any) =>
                !!v).length !== 0)
    return errors
}