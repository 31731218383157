import { INewsPropsType, mapStateToProps, mapReplyActionsToProps } from './Reply.index'
import React, { FC, memo } from 'react';
import { connect } from 'react-redux';
import './Reply.scss'
import MDRender from 'uielements/src/CommonmarkRender'


export const ReplyPresenter: FC<INewsPropsType> = ({
    id,
    text,
    type,
    header,
    replyViewFull,
    disableCut = false
}) => {
    return (
        <>
            {/* {type === 'thank' && <MDRender textCut={false} source={header} onOpen={() => replyViewFull(id)} />} */}
            <MDRender textCut={!disableCut} source={text} onOpen={() => replyViewFull(id)} />
        </>
    )
}

export const Reply = connect(
    mapStateToProps,
    mapReplyActionsToProps
)(memo(ReplyPresenter));
