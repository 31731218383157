import React, { FC, useEffect, useState } from 'react';
import { setCookie } from 'utils/src';

export const DebugAuth: FC<any> = () => {

    const [ dt, setDt ] = useState({
        company: '',
        api: ''
    });

    const {
        company,
        api
    } = dt;

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if(event.data.event === 'debugAuth') {
                const data = event.data.data;
                data && Object.keys(data).forEach((key) => {
                    setCookie({ name: key, value: data[key] })
                });
                window.location.reload();
            }
        });
        const company = prompt('Введите имя компании для авторизации:');
        const api = prompt('Введите сервер для отладки("prerelease" или "v5"):', 'prerelease');
        if(company && api) {
            setDt({
                company,
                api
            });
            if(api !== 'v5') {
                window.PRN_SERVICE.type_API_server = api;
            } else {
                window.PRN_SERVICE.type_API_server = '';
            }
        } else {
            console.error(`DEBUG AUTH ERROR: company is "${company}", api is "${api}"`);
        }
    }, []);

    if(!company || !api) {
        return null;
    }

    return (
        <iframe src={`https://${company}.${api}.pryaniky.com/login?client=localhost`} style={{
            width: '100%',
            height: '100vh'
        }} />
    )
}