/**
 * @packageDocumentation
 * @module News_type_tasks_Task_type_DocumentReadBlock-Item
 */
import React from 'react';
import { IDReadItemProps, IDReadItemState, cnDReadItem } from "./TaskDocumentReadBlock-Item.index";
import { Button, Icon } from 'uielements/src';
import "./TaskDocumentReadBlock-Item.scss";

class DReadItemPresenter extends React.Component<IDReadItemProps, IDReadItemState> {

  /**
   * render
   */
  public render() {
    return (
      <div className={cnDReadItem()} id={this.props.data.id} >
        <div className={cnDReadItem('Name')} children={<>
          <Icon icon={'file-alt'} />
          {this.props.data.name}
        </>} />
        {
          this.props.onRemove && 
          <Button theme='unstyled' className={cnDReadItem('Remove')} onClick={this.props.onRemove.bind(null, this.props.data.id)} children={<Icon icon='times' />} />
        }
          <div className={cnDReadItem('Progress', { success: this.props.data.status === 'success', error: this.props.data.status === 'error' })} style={{width: `${this.props.data.progress}%`}} />
      </div>
    )
  }
}

export const DReadItem = DReadItemPresenter