/**
 * @packageDocumentation
 * @module Filter_type_groups
 */
import React from 'react';
import { connect } from "react-redux";
import i18n from "../../../localizations/i18n";
import { IStateType as IState } from 'redux/store';
import { FiltersPresenter } from "../Filters";
import { cnFilters, IFiltersProps, mapStateToProps, mapDispatchToProps, IFiltersStateProps, IFiltersDispatchProps } from "../Filters.index";
import { Selectize } from "uielements/src";
import { Translate } from 'localizations/Translate';
import { withRouter } from 'react-router';
import * as utils from 'utils/src/utils';

/**
 * dv - default values of this filters if dv[key] === 'undefined' default is 'all'
 */
const dv: Record<string, string> = {
  groupsOrder: ''
}

/**
 * h - filters header object
 */
const h: Record<string, string> = {
  groupsListFilter: 'pryaniky.filter.groups.title',
  groupsOrder: 'pryaniky.filter.sort.title',
  filterTagId: "pryaniky.filter.categories.title"
}

/**
 * f - data to this filters
 */
const f: any[][] = [ // all filters
  [
    { id: 'all', title: 'all', value: 'all' }, // Все группы
    { id: 'my', title: 'my', value: 'my' }, // Мои группы
    // { id: 'open', title: 'open', value: 'open' }, // Открытые
    // { id: 'closed', title: 'closed', value: 'closed' }, // Закрытые
    // { id: 'active', title: 'active', value: 'active' }, // Активные
    { id: 'archived', title: 'archived', value: 'archived' }, // Архивные
    // { id: 'creativetasks', title: 'competitions', value: 'creativetasks' },
    // { id: 'onModeration', title: 'moderation', value: 'approve' },
    { id: 'system', title: 'system', value: 'system', isAdmin: true },
  ],
  // [
  //   { id: 'system', title: 'system', type: 'groupListFilter', value: 'system' },
  // ],
  [
    { id: 'official', title: 'official', type: 'groupsAddFilter', value: 'official' }, // Официальные
    { id: 'nonofficial', title: 'nonofficial', type: 'groupsAddFilter', value: 'nonofficial' }, // Неофициальные
  ],
  [ // sorting filters
    { id: 'alphabetical', title: 'alphabetical', type: 'groupsOrder', value: '' }, // По алфавиту(дефолтное)
    { id: 'usersCount', title: 'usersCount', type: 'groupsOrder', value: 'usercount' }, // По числу участников
    { id: 'newsCount', title: 'newsCount', type: 'groupsOrder', value: 'newscount' }, // По числу публикаций
    { id: 'createDate', title: 'createDate', type: 'groupsOrder', value: 'new' }, // По дате создания
    { id: 'activityDate', title: 'activityDate', type: 'groupsOrder', value: 'activity' }, // По дате последней активности
  ]
]

type TFilterData = {
  id: any;
  type: any;
  title: string;
  value: any;
  isAdmin?: any;
}

/**
 * FiltersTypeGroupsPresenter - groups list filter
 */
export class FiltersTypeGroupsPresenter extends FiltersPresenter {


  public defaulValues = {

    groupsOrder: '',
    groupsListFilter: 'all',
    filterTagId: "",
  }


  public componentDidMount() {
    this.getCategories();
  }

  private getCategories = () => {
    utils.API.tags.listSearch(0, 20,{showType: "usedgroups"})
      .r
      .then((d: any) => utils.checkResponseStatus(d) &&
        this.data.push([{ id: "", displayName: i18n.t(`pryaniky.filter.groups.allCategories`), value: "", type: 'filterTagId' }, ...d.data].map((el: any) => ({
          id: el.id.toString(),
          title: el.displayName,
          value: el.value === "" ? el.value : el.id,
          type: 'filterTagId'
        })))
        // f.concat(d.data).map((el:any) => ({ id: el.id.toString(), title: el.displayName? el.displayName: "Все", value: el.id, type: 'catFilter' }))

      )
  }

  /**
   * prepare filters to render data like {}[][]
   */
  public data: TFilterData[][] = f.map(fl => fl.map(el => ({
    id: el.id,
    type: el.type || 'groupsListFilter',
    title: i18n.t(`pryaniky.filter.groups.${el.title}`),
    value: el.value,
    isAdmin: el.isAdmin
  })));


  /**
   * render - it is render)
   */
  public render() {
    
    const { tag: Tag = 'div', context, className, authUser } = this.props;
    // console.log('authUser', authUser.baseData.isAdmin)

    const data = this.data.map(cat => cat.filter(item => authUser.baseData.isAdmin ? true : !item.isAdmin))
    return (
      <Tag tag='div' {...this.props} className={cnFilters({}, [className])} >
        {
          data.map((fl, idx) => {
            const type = fl[0] && fl[0].type;
            let active = this.getActive(idx);
            // if (active === 'all' && dv[type] !== undefined) active = dv[type];
            return <div key={idx} className={cnFilters('Block')} >
              {type && h[type] && <h4 className={cnFilters('Subtitle')}>
                <Translate i18nKey={h[type]} />:
              </h4>}
              <Selectize onClick={this.handleChange} elements={fl} active={active} />
            </div>
          })
        }
      </Tag>
    )
  }
}

/**
 * connected to redux
 */
export const FiltersTypeGroups = withRouter(
  connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersProps, IState>(
    mapStateToProps,
    mapDispatchToProps
  )(FiltersTypeGroupsPresenter)
);


