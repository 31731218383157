import React, { ComponentProps, FC, memo, useCallback } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Rating, { RatingProps } from '@material-ui/lab/Rating';
import { Box } from 'muicomponents/src';
import { cn } from '@bem-react/classname'
// import { withStyles } from '@material-ui/core/styles';
import { IconButton } from 'muicomponents/src/IconButton';
import { Close as CloseIcon } from 'muicomponents/src/Icons';
import { Divider, Typography } from 'muicomponents/src';
import { i18n } from 'localization';

export const isIE = /*@cc_on!@*/false || !!(document as any).documentMode;

export const cnClassName = cn('RatingWithValue')


export interface IRatingWithValueProps extends RatingProps {
    boxProps?: ComponentProps<typeof Box>
    wrapperClassName?: string
    ratingCount: number
    rating: number
}

// const useStyles = makeStyles({
//     root: {
//         width: 200,
//         display: 'flex',
//         alignItems: 'center',
//     },
// });

const defaultBoxPops = {
    ml: 2
}

// const StyledRating = withStyles({
//     iconFilled: {
//         color: '#FFB400',
//     },
//     iconHover: {
//         color: '#FFB400',
//     },
// })(Rating);

export const RatingShopMui: FC<IRatingWithValueProps> = memo(({
    className,
    wrapperClassName,
    onChangeActive,
    value,
    precision = 0.5,
    boxProps = defaultBoxPops,
    onChange,
    readOnly,
    ratingCount,
    rating,
    ...props
}) => {
    const [hover, setHover] = React.useState(-1);
    // const classes = useStyles();

    const onCA = useCallback((event: React.ChangeEvent<{}>, value: number) => {
        onChangeActive && onChangeActive(event, value)
        setHover(value);
    }, [onChangeActive])
    const onCancle = (event: React.ChangeEvent<{}>) => {
        onChange && onChange(event, -1)
    }

    return <Box className={cnClassName({}, [wrapperClassName])} sx={{ display: 'flex', alignItems: 'center' }} >
            <Rating
                {...props}
                className={cnClassName('RatingMui', [className])}
                value={value}
                precision={isIE ? 1 : precision}
                onChangeActive={onCA}
                onChange={onChange}
                readOnly={readOnly}
                size="large"
            />
            {!readOnly && <>
                {(value && value > 0) ? <IconButton
                    className={cnClassName('Cancle', ['primaryColor3-text'])}
                    onClick={onCancle}
                    color="primary"
                    aria-label="upload picture"
                    component="span">
                    <CloseIcon />
                </IconButton> : null}
            </>}
            <Box {...boxProps}>{hover !== -1 ? hover : ((value && value < 0) ? null : value)}</Box>

            <Box sx={{ ml: 3 }} >
                {
                    rating === -1
                        ? <Typography variant='body2' color='GrayText' noWrap >{i18n.t('pryaniky.shop.product.not_votes')}</Typography>
                        : <Typography variant='body2' color='GrayText' noWrap >{i18n.t('pryaniky.shop.product.vote')}: {ratingCount}</Typography>
                }
            </Box>

        </Box>
})

