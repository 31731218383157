import React, { FC, memo, useState, useCallback } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withReplyContext } from '../../contexts/reply'
import { ServiceActionsReply } from 'blocks/NewsView/ServiceActions/Actions'
import ContextMenuComponent from 'uielements/src/ContextMenu/ContextMenu'
import i18n from 'localizations/i18n'

type IPresenterProps = News.ReplyService.IPresenterProps
type IOwnProps = News.ReplyService.IOwnProps
type IDispatchProps = News.ReplyService.IDispatchProps
type IStateProps = News.ReplyService.IStateProps
type IState = News.ReplyService.IState


export const Presenter: FC<IPresenterProps> = ({
    className,
    replyId,
    newsId,
    reply,
    deleteReply,
    setEdit,
    replyEdit
}) => {
    const { actions, type } = reply
    const disabledActions: string[] = [
        'newslink'
    ];
    const replyAction = useCallback((action: string) => {
        const SAW = new ServiceActionsReply({
            enableEdit: () => setEdit(!replyEdit),
            id: replyId,
            actions: actions,
            newsID: newsId,
            removeReply: (id) => deleteReply({ newsId, replyId: id }),
            type: type
        })
        SAW.callAction(action);
    }, [actions])
    const actionsWithContent = actions.filter(action => !disabledActions.includes(action)).map(action => ({
        content: i18n.t('pryaniky.post.actions.' + action),
        action
    }))
    return <ContextMenuComponent
        className={cnClassName({ }, [])}
        actions={actionsWithContent}
        onClick={replyAction}
    />
}

export default withReplyContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));

