import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { PropsWithChildren } from 'react';
import { TCreatorOptions } from '../Creator/Creator.hooks';
// import { AnyAction, bindActionCreators, Dispatch } from 'redux';
// import { ISelectableObject } from 'utils/src/requests/models/api.base';
// import { IStateType } from '../../../redux/store';
// import { getCreatorNewsParams, getCreatorParams } from '../../redux/saga/selectors';
// import { pathCreator, pathCreatorNews, validateCreatorNews, sendNews } from '../../redux/actions';
// import { getCurrentUserIsAdmin } from 'utils/src/CommonRedux/base/selectors';

export const cnNewsSender = cn('NewsSender');

export namespace NNewsSender {

    export interface Props extends PropsWithChildren<IClassNameProps>, OwnProps {
        creatorOptions?: TCreatorOptions;
        disableSend?: boolean;
    };

    export interface OwnProps {

    };
};

// export const mapNewsSenderStateToProps = (state: IStateType, props: NNewsSender.OwnProps) => ({
//     isAdmin: getCurrentUserIsAdmin(state),
//     ...getCreatorParams('isValid', 'errors')(state),
//     ...getCreatorNewsParams('group', 'publishAt', 'attachments', 'newstype')(state)
// });

// export const mapNewsSenderActionsToProps = (d: Dispatch<AnyAction>) => bindActionCreators({
//     pathCreator,
//     pathCreatorNews,
//     validateCreatorNews,
//     sendNews
// }, d);