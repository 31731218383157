import { IEditorProps, mapEditorStateToProps, IMapEditorStateToProps } from './Editor.index'
import * as React from 'react'
import './Editor.scss';
import { Input, Icon, Button } from 'uielements/src';
import i18n from '../../../../localizations/i18n';
import { TimePicker2 } from '../../../TimePicker2/TimePicker2';
import { Select } from 'uielements/src/Select/Select';
import { createDurationOptions, findSelected } from '../Utils'
import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'
import { DateTimeInput } from 'blocks/PryanikyForms/DateTimeInput/DateTimeInput'
import { PrnUserInput } from 'blocks/PrnUserInput/PrnUserInput'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { CheckboxInput } from 'uielements/src';
import { Translate } from 'localizations/Translate';
import { connect } from 'react-redux';
import { getCalendarCalFiltersRequest } from 'utils/src/requests/requests.calendar'
import { useQueryEx } from 'utils/src/hooks'
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { TextField } from 'muicomponents/src/TextField';
import { IconButton } from 'muicomponents/src/IconButton';
import { Close } from 'muicomponents/src/Icons';

export const EditorPresenter: React.FC<IEditorProps & IMapEditorStateToProps> = ({
    children,
    onChangeVideoConference,
    allowCreateVideoConference,
    data,
    onChangeLocation,
    durationChange,
    onChangeUsers,
    expiresChange,
    onChangeText,
    onChangeTitle,
    onChangeIsHidden,
    onChangeEnableWaitList,
    calendarChange,
    onChangeAllowParticipate,
    onChangeMaxPartisipantsCount,
    isAdmin,
    groupId
}) => {
    const { users = [], groups = [] } = data
    const usergroups = [...users, ...groups]

    const { isLoading, result } = useQueryEx(() => getCalendarCalFiltersRequest({ includeBirthdays: false, gId: groupId }), [])
    const calendarOptions = (result || []).map((value) => ({ title: value.displayName, value: value }))
    const commonCalendar = (result || []).find((value) => value.name === 'common')
    const groupCalendar = (result || []).find((value) => `${value.groupId}` === `${groupId}`)
    const selectedCalendar = data.uevent.calendar ?
        ({ title: data.uevent.calendar.displayName as string, value: (data.uevent.calendar as typeof groupCalendar)! }) :
        (groupCalendar ? { title: groupCalendar.displayName, value: groupCalendar } :
            (commonCalendar ? { title: commonCalendar.displayName, value: commonCalendar } : undefined))

    React.useEffect(() => {
        if (result && selectedCalendar && calendarChange) {
            calendarChange({ title: selectedCalendar.title, value: selectedCalendar.value })
        }
    }, [result])


    return <div className={'Editor EventEditor'}>

        {/* {isLoading && <div className='EventEditor-Preloader'></div>} */}

        {calendarChange && <div className={'EventEditor-SelectCalendarBox'}>
            <Select
                noDefault
                disabled={isLoading}
                className={'EventEditor-SelectCalendar'}
                onChange={calendarChange}
                options={calendarOptions}
                icon={'calendar'}
                selected={selectedCalendar}//{findSelected(data.uevent.duration)}
            />
            <Tooltip placement="right" title={i18n.t('pryaniky.news.create.events.calendar.help')}>
                <span> <Icon icon="question-circle"></Icon></span>
            </Tooltip>
        </div>}

        <Input onChange={onChangeTitle}
            className={'EventEditor-Title'}
            value={data.header}
            icon='flag'
            placeholder={i18n.t('pryaniky.createPost.event.name')} />

        {onChangeUsers && <PrnUserInput
            className={'EventEdit-Users'}
            excludeMe={true}
            placeholder={Translate.t({ i18nKey: 'pryaniky.createPost.event.participants' })}
            excludeUsers={[]}
            onChange={onChangeUsers}
            type={isAdmin ? 'usersgroups' : 'users'}
            value={usergroups} />}

        <div className={'EventEdit-Expires'}>
            <DateTimeInput
                minDate={new Date()}
                className={'EventEdit-ExpiresItem EventEdit-Datapicker'}
                value={new Date(data.uevent.startDateTime)}
                onChange={expiresChange}
                icon={(onClick) => <Button {...{ onClick }} ><Icon icon='calendar' /></Button>}
            />
            <TimePicker2
                className={'EventEdit-ExpiresItem EventEdit-Timepicker'}
                disabled={data.uevent.duration > 720}
                onChange={expiresChange}
                value={new Date(data.uevent.startDateTime)} />
            <Select
                className={'EventEdit-ExpiresItem EventEdit-Select'}
                onChange={durationChange}
                options={createDurationOptions()}
                icon={'clock'}
                selected={findSelected(data.uevent.duration)} />
        </div>

        <Input
            onChange={onChangeLocation}
            value={data.uevent.eventLocation}
            icon='location'
            placeholder={i18n.t('pryaniky.createPost.event.location')} />

        <div className={'EventEdit-MaxParticipantsCount EventEdit-Block'}>
            <Translate i18nKey={'pryaniky.createPost.event.maxParticipantsCount'} />
            <TextField
                disabled={data.uevent.disallowParticipate}
                size={'small'}
                className={'EventEditor-MaxParticipantsCount'}
                value={data.uevent.maxParticipantsCount || ''}
                onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, '');
                    let count: number | null = Number(value);
                    if(isNaN(count) || count < 1) count = null;
                    onChangeMaxPartisipantsCount(count);
                }}
                InputProps={{
                    endAdornment: <IconButton
                        onClick={() => {
                            onChangeMaxPartisipantsCount(null);
                        }}
                    >
                        <Close />
                    </IconButton>
                }}
            />
        </div>

        <PryanikyEditor
            onChange={onChangeText}
            value={data.text}
            disableToolbar={true}
            placeholder={i18n.t('pryaniky.createPost.event.description')} />

        <div className={'EventEdit-Checkboxes'}>
            {(allowCreateVideoConference && onChangeVideoConference) && <CheckboxInput
                onChange={() => onChangeVideoConference(!(data.uevent.meeting ? data.uevent.meeting.online : false))}
                checked={data.uevent.meeting ? data.uevent.meeting.online : false}
                padding="0"
                children={<HelpTooltip help={i18n.t("Видеоконференция")}>
                    <Icon icon="question-circle"></Icon>
                </HelpTooltip>}
                text={"Видеоконференция"}
            />}

            {onChangeAllowParticipate && <CheckboxInput
                onChange={onChangeAllowParticipate}
                checked={data.uevent.disallowParticipate}
                padding="0"
            >
                <Translate i18nKey='pryaniky.createPost.event.disallowParticipate' />
                <Tooltip placement="right" title={i18n.t('pryaniky.createPost.event.disallowParticipate.help')}>
                    <span> <Icon icon="question-circle"></Icon></span>
                </Tooltip>
            </CheckboxInput>}

            <CheckboxInput
                onChange={onChangeIsHidden}
                checked={data.ishidden}
                padding="0"
            >
                <Translate i18nKey='pryaniky.createPost.event.ishidden' />
                <Tooltip placement="right" title={i18n.t('pryaniky.createPost.event.ishidden.help')}>
                    <span> <Icon icon="question-circle"></Icon></span>
                </Tooltip>
            </CheckboxInput>

            <CheckboxInput
                onChange={onChangeEnableWaitList}
                disabled={(data.uevent.maxParticipantsCount||0)<=0}
                checked={data.uevent.enableWaitList}
                padding="0"
            >
                <Translate i18nKey='pryaniky.news.create.events.enableWaitList' />
                <Tooltip placement="right" title={i18n.t('pryaniky.news.create.events.enableWaitList.help')}>
                    <span> <Icon icon="question-circle"></Icon></span>
                </Tooltip>
            </CheckboxInput>
        </div>
        {children}
    </div>
}

export const Editor = connect(mapEditorStateToProps)(EditorPresenter);