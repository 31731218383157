import React, { FC, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import './NameForm.scss'
import { Button, CheckboxInput, Spinner, Icon } from 'uielements/src'
import { TextInput } from 'blocks/PryanikyForms'
import { cn } from '@bem-react/classname';
import { Button as MuiButton } from 'muicomponents/src/Button/Button'

export interface IProps {
    className?: string;
    setFolderName: (val: string) => void;
    cancel: () => void;
    folderName: string;
}

export const cnNameForm = cn('NameForm');

export const NameForm: FC<IProps> = ({
    className,
    folderName,
    setFolderName,
    cancel,
}) => {
    const [name, setName] = useState<string>(folderName)
    const [disable, setDisable] = useState<boolean>(false)

    const changeName = (str: string) => {
        if (str === '..') setDisable(true)
        else setDisable(false)
        setName(str)
    }

    return <div className={cnNameForm({}, [className])} onClick={(e) => { e.stopPropagation(); }}>
        <TextInput ref={(ref: HTMLInputElement | null) => ref && ref.focus()} className={cnNameForm('Input')} onChange={changeName} value={name} />
        <MuiButton disabled={disable} className={cnNameForm('Btn', { apply: true })} variant={'contained'} color={'primary'} onClick={() => setFolderName(name)}><Icon icon={'check'} /></MuiButton>
        <MuiButton className={cnNameForm('Btn', { cancle: true })} onClick={cancel}><Icon icon={'times'} /></MuiButton>
    </div>
}