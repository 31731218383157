import React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget, cnWidgetMui, IWidgetProps } from '../Widget.index';
import './Widget_type_ratings.scss';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";

const types = {
  common: require('blocks/RatingsWidget/RatingsWidget').RatingsWidget,
  mountain: require('blocks/MountainRating/MountainRating').MountainRating,
}

const type = 'ratings';

export default class WidgetTypeRatingsPresenter extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    return <Component {...this.props} className={cnWidgetMui({ type, subtype })} settings={this.props.widget?.settings} context={this.props.wcontext} tag={widgets.components.common} />
  }

}