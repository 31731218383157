import { IClassNameProps } from '@bem-react/core';
import { cnNewsView } from '../NewsView.index';

import { match } from 'react-router';

export interface IStageBarProps extends IClassNameProps {
  tag?: 'div';
  className?: string;
  store?: any;
  changeVm?: any;
  match?: match<{}>;

  date?: string;
  stage?: JSX.Element;
}

export interface IStageBarState {
  text?: string;
}

export const cnStageBar = (...data: any) => {
  let name = 'StageBar';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnNewsView(name, ...data)
}