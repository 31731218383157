import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
export interface IAuthorsListProps extends IClassNameProps {
    tag?: 'div';
    size?: number;
    more?: boolean;
    authors: any[];
    limit?: number;
    fixedToolTip?: boolean
}

export interface IAuthorsListState {

}

export const cnAuthorsList = cn('AuthorsList');