import Component, { Presenter } from './component';
import {
    Skills,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = Skills.IOwnProps;
export type IComponentPresenterProps = Skills.IPresenterProps;


export default Component