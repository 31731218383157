import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { PropsWithChildren } from 'react';
import { TWidgetsUnionSimplified } from 'Widgets_v2/types';

export const cnWidgetv2 = cn('Widgetv2');

export namespace NWidgetMenu {

    export interface Props extends IClassNameProps {
        widget: TWidgetsUnionSimplified,
    }
}

export namespace NWidget {

    export type Props = PropsWithChildren<OwnProps>;

    interface OwnProps extends IClassNameProps {
        id: string;
        // TODO WTF ?! cut it
        hide?: boolean;
        // TODO WTF ?! cut it
        isHidden?: boolean;
    }
}