import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { ReactNode } from 'react';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { IBodyProps } from 'utils/src/DialogCreator';

export const cnCommentDialog = cn('CommentDialog');

export interface ICommentDialogProps extends IBodyProps<{ comment: string, sendNotify?: boolean }>, ICommentDialogOwnProps, IClassNameProps, ImapCommentDialogStateToProps, ImapCommentDialogActionsToProps {

}

export interface ICommentDialogOwnProps {
  okBntText?: string | ReactNode;
  cnclBntText?: string | ReactNode;
  title?: string | ReactNode;
  action?: string;
}

export const mapCommentDialogStateToProps = () => ({

});

export type ImapCommentDialogStateToProps = ReturnType<typeof mapCommentDialogStateToProps>;

export const mapCommentDialogActionsToProps = (d: Dispatch<Action>) => bindActionCreators({

}, d);

export type ImapCommentDialogActionsToProps = ReturnType<typeof mapCommentDialogActionsToProps>;