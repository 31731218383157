import { useContextFiltersChange } from "blocks/Filters/Filters.hooks";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { checkResponseStatus, TValue } from "utils/src";
import { SOCUnitWithChildrenShort } from "utils/src/BaseTypes/units.types";
import { getOrgchartUnitsTree } from "utils/src/requests/requests.orgchart";
import { getReqursiveSOCOrgchartUnitsChildrensIds } from "./Filters_type_socogchartunits.utils";

const SOCOrgchartUnitsTreeEventName = "unittreerecieved";

export class SOCOrgchartUnitsTreeManager {
    private static singleton: SOCOrgchartUnitsTreeManager | null = null;

    _tree: SOCUnitWithChildrenShort[] = [];
    _loading = false;
    _loaded = false;

    public static get inst() {
        if(!SOCOrgchartUnitsTreeManager.singleton) {
            SOCOrgchartUnitsTreeManager.singleton = new SOCOrgchartUnitsTreeManager();
        }
        return SOCOrgchartUnitsTreeManager.singleton;
    }

    public set loading(value: typeof this._loading) {
        this._loading = value;
    }

    public get loading() {
        return this._loading;
    }

    public get loaded() {
        return this._loaded;
    }

    public get tree() {
        return this._tree;
    }

    private async _getUnitsTree() {
    }

    public async getUnitsTree() {
        if(this.loading) return ;
        this.loading = true;
        const response = await getOrgchartUnitsTree();
        if(checkResponseStatus(response)) {
            this._loaded = true;
            this._tree = !Array.isArray(response.data) ? [response.data] : response.data;
            window.dispatchEvent(new CustomEvent(SOCOrgchartUnitsTreeEventName));
        }
        this.loading = false;
        return true;
    }

    public async treeAsync() {
        const self = this;
        return new Promise<typeof self._tree>(async function(res) {
            if(self.loaded) {
                res(self._tree)
                return;
            }
            const rez = await self.getUnitsTree();
            if(!rez) {
                window.addEventListener(SOCOrgchartUnitsTreeEventName, (e) => {
                    res(self._tree)
                });
            } else {
                res(self._tree)
            }
        });
    }

    public async getTreeItemByItemId(id: (typeof this.tree)[number]['id']) {
        const tree = await this.treeAsync();
        const searchArray = [...tree];
        let found: (typeof tree)[number] | undefined;
        let item = searchArray.pop();
        while (!found && item) {
            if (item.childrens?.length) {
                searchArray.push(...item.childrens);
            }
            if (item.id === id) {
                found = item;
                break;
            }
            item = searchArray.pop();
        }
        return found;
    }

    public async getTreeItemIdWithChildrenIdsByItemId(id: (typeof this.tree)[number]['id']) {
        const found = await this.getTreeItemByItemId(id);
        if(!found) return [];
        return [found.id, ...getReqursiveSOCOrgchartUnitsChildrensIds(found.childrens || [])];
    }
};

export const useSOCOrgchartUnitsTreeManager = () => {

    const [loading, setLoading] = useState(SOCOrgchartUnitsTreeManager.inst.loading);
    const [items, setItems] = useState<SOCOrgchartUnitsTreeManager['tree']>(SOCOrgchartUnitsTreeManager.inst.tree);

    useLayoutEffect(() => {
        window.addEventListener(SOCOrgchartUnitsTreeEventName, (e) => {
            setLoading(false);
            setItems(SOCOrgchartUnitsTreeManager.inst.tree);
        });
        if(!SOCOrgchartUnitsTreeManager.inst.loaded) {
            setLoading(true);
            SOCOrgchartUnitsTreeManager.inst.getUnitsTree();
        }
    }, []);

    return {
        loading,
        items
    };
};