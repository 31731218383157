import { styled } from 'muicomponents/src/mui/system';
import { Box } from 'muicomponents/src/Box/Box';
import { Paper } from 'muicomponents/src/Paper';
import { List, ListItemButton } from 'muicomponents/src/ListItem/ListItem';
import { MaximizeTwoTone } from 'muicomponents/src/Icons';



export const HistoryBox = styled(Paper)({
    margin: "0 16px",
    boxShadow: "none",
    height: "100%",
    maxWidth: "296px",
    // display: 'flex',
    // flexDirection: 'column',
    // maxHeight: '50vh'
});




export const HistoryList = styled(List)({
    paddingTop: "0",
});