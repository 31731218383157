import { cnInfoBlock } from '../../InfoBlock.index';
import { IInfoProps } from '../../AbstarctInfoElement/AbstractInfoElement.index';
import { updateContext } from 'redux/actions/Widgets';
import { getUser, setStatus, setUserStatus, changeUser, updateUser } from 'utils/src/CommonRedux/users/actions'
import { bindActionCreators } from 'redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { i18n_set_language } from 'utils/src/CommonRedux/i18n/actions';
import { NSubnetworksSettingsAPI } from 'utils/src/requests/models/admin.subnetworks';

export interface IUserProps extends IBaseUserProps, IUserStateProps, ReturnType<typeof mapDispatchToProps> {//, IActionsDispatch {

}

export interface IBaseUserProps {
    userId: string;
    togleEdit: () => void;
    editEnd: (callback?: () => void) => void
}

export interface IUserState {
    text?: string;
}

export interface IUserStateProps {
    // store: any,
    user: any,
    authUser: any,
    appSettings: any,
    networksSettings: NSubnetworksSettingsAPI.AppSettings | undefined
}
const emptyObj: any = {}

export const mapStateToProps = (state: any, props: IBaseUserProps) => {
    let user = emptyObj;
    if (props.userId in state.users.users) {
        user = state.users.users[props.userId]
    } else if (props.userId in state.users.alias) {
        user = state.users.users[state.users.alias[props.userId]]
    }
    return {
        appSettings: state.store.appSettings,
        networksSettings: state.store.appSettings?.networkSettings as NSubnetworksSettingsAPI.AppSettings | undefined,
        // store: {
            // ...state.store,
            authUser: getAuthUser(state),
        // },
        user
    }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    updateContext,
    getUser,
    setStatus,
    setUserStatus,
    changeUser,
    updateUser,
    i18n_set_language
}, dispatch);

export const cnUser = (...data: any) => {
    let name = 'User';
    if (typeof (data[0]) === 'string') {
        name += '-' + data[0];
        data = data.slice(1);
    }
    return cnInfoBlock(name, ...data)
}

export const cnInfo = cnInfoBlock;
