import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IStateType } from 'redux/store';
import {
    getOrgchartDataFromServer,
    addSubUnit,
    saveStructureDataOrgchart,
    changeSelect,
    showOnlyOpenVacancyAction
} from '../../redux/sagas/orgchart/actions'
import { getOrgchartState } from '../../redux/sagas/orgchart/selectors'
import { ISearchInputProps } from 'uielements/src/SearchInput/SearchInput';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';


export type IOrgchartViewTypes = 'vertical' | 'horizontal' | 'tree';

export interface IOrgchartProps extends IClassNameProps, IOrgchartStateProps, IOrgchartDispatchProps {
    data: any;
}

export interface IOrgchartState {
    text?: string;
    news: any[];
    request: boolean;
    fullList: boolean;
}

export type IOrgchartStateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: IStateType) => {
    return {
        isFinished: getOrgchartState(state).isFinished,
        isLoading: getOrgchartState(state).isLoading,
        data: getOrgchartState(state).elementStructure,
        uIsAdmin: getAuthUser(state).baseData.isAdmin,
        filterShowOnlyOpenVacancy: state.orgchart.showOnlyOpenVacancy,
        editable: getAuthUser(state).baseData.isAdmin && state.store.edit && state.store.editType === '',
    };
};

export type IOrgchartDispatchProps = ReturnType<typeof mapDispatchToProps>

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    getOrgchartDataFromServer,
    addSubUnit,
    saveStructureDataOrgchart,
    changeSelect,
    showOnlyOpenVacancyAction
}, dispatch)


export const cnOrgchart = cn('Orgchart');
