/**
 * @packageDocumentation
 * @module Widget_Settings_users_group
 */
import { JFdictionary } from "../dictionary";

const relationsArray = [
  'group',
  'users'
]

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    fieldsIds: {
      type: types.object,
    }
  }
}
