import React, { FC, useState } from 'react'
import './Year.css'
import { Button, Icon } from 'uielements/src'


const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];


export const cellRender = (date: Date, select: (date: number) => void, month: number, minDate?: Date) => {
    // if (!month) return '';
    const item = new Date(date);
    item.setUTCMonth(month)
    
    let className = 'CellItem CellItem_year'
    if (date.getUTCMonth() === month) className += ' selected-date';
    const currentDate = new Date();
    if (month === currentDate.getUTCMonth() && date.getUTCFullYear() === currentDate.getUTCFullYear()) className += ' current-date';
    let disabled = false;
    if (minDate) {
        const cMinDate = new Date(minDate)
        cMinDate.setUTCDate(1);
        disabled = minDate ? cMinDate.getTime() > item.getTime() : false
    }
    if (disabled) className += ' disabled-date';
    return (<div className={className} onClick={() => !disabled && select(month)}>{monthNames[month]}</div>)
}

export const Year: FC<any> = ({ className, jumpTo, prev, next, up: upper, date, minDate, ...props }) => {
    const year = [
        [0, 1, 2, 3],
        [4, 5, 6, 7],
        [8, 9, 10, 11],
    ]
    return (
        <div className={className}>
            <div className='BtnBox'>

                <Button theme={'unstyled'} onClick={prev}><Icon icon='arrow-alt-left' /></Button>

                <Button className={'Calendar-Title'} theme={'unstyled'} onClick={upper}>{date.getUTCFullYear()} г.</Button>

                <Button theme={'unstyled'} onClick={next}><Icon icon='arrow-alt-right' /></Button>

            </div>

            <table>
                <tbody>
                    {year.map((val: any, i: number) => <tr key={i}>
                        {val.map((v: any, i: number) => <td key={i}>
                            {cellRender(date, jumpTo, v, minDate)}
                        </td>)}
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}