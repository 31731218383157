import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IImgLoaderProps extends IClassNameProps {
  tag?: 'div';
  type: string;
  header?: string;
  text?: string;
  previewUrl?: string;
  onLoadEnd(loadedImg: {[s: string]: any}): void;
}

export interface IImgLoaderState {
  text?: string;
  file?: {[s: string]: any};
}

export const cnImgLoader = cn('ImgLoader');