import * as React from 'react';
import * as utils from 'utils/src/utils';
import { IBodyProps } from '../NewsEdit-Body.index';
import i18n from 'localizations/i18n';
import { withBemMod } from '@bem-react/core';
import { cnBody } from '../NewsEdit-Body.index';
import { IBodyTypeEventsProps } from './NewsEdit-Body_type_events.index';
import './NewsEdit-Body_type_events.scss';
import { EditorTypeEdit } from '../../../NewsTypes/Events/Editor/containers/edit/edit'

/*TODO: move me to utils... Please! */
// const createDurationOptions = () => utils.eventDurationsVariants.map((e: any) => {
//   if (e.value < 60) e.title = e.value + ' ' + i18n.t('pryaniky.inputs.time.minute', {count: e.value});
//   else if (e.value >= 60 && e.value <= 720) {
//     const value = e.value / 60;
//     e.title = value + ' ' + i18n.t('pryaniky.inputs.time.hour', {count: value});
//   }
//   else if (e.value > 720) e.title = i18n.t('pryaniky.inputs.time.allDay');
//   return e;
// });

export const withBodyTypeEvents = withBemMod<IBodyTypeEventsProps, IBodyProps>(
  cnBody(),
  { type: 'events' },
  (Presenter) => (
    (props) =>{
      const newPost: any = utils.cloneObject(props.post);
      return <EditorTypeEdit data={newPost} onChange={props.onChangePost} />/*
      const startDate = new Date(newPost.uevent.startDateTime);

      const findSelected = () => {
        const arr = createDurationOptions();
        return arr.find((v: any) => {
          return props.post.uevent.duration === v.value
        }) || arr[0];
      }
      const onChangeTitle= (e: any) => {
        newPost.uevent.title = e.target.value;
        newPost.header = e.target.value;
        props.onChangePost(newPost);
      }
      const expiresChange = (date: Date) => {
        newPost.uevent.startDateTime = date.toISOString();
        props.onChangePost(newPost);
      }
      const durationChange = (selected: any) => {
        newPost.uevent.duration = selected.value;
        props.onChangePost(newPost);
      }
      const onChangeLocation = (e: any) => {
        newPost.uevent.eventLocation = e.target.value;
        props.onChangePost(newPost);
      }
      return (
        <Presenter {...props}>
          {({ text, onChangeText }) =>
            <React.Fragment>
              <Input onChange={onChangeTitle}
                // className={this.props.className} 
                value={props.post.uevent.title}
                icon='flag'
                placeholder={i18n.t('placeholders.input.name')} />

              <div className={'EventEdit-Expires'}>
                <Datepicker className={'EventEdit-ExpiresItem EventEdit-Datapicker'} onChange={expiresChange} locale={i18n.language} value={startDate} />
                <TimePicker2 className={'EventEdit-ExpiresItem EventEdit-Timepicker'} onChange={expiresChange} value={startDate} />
                <Select className={'EventEdit-ExpiresItem EventEdit-Select'} width={64} onChange={durationChange} options={createDurationOptions()} icon={'clock'} selected={findSelected()} />
              </div>

              <Input onChange={onChangeLocation} 
              // className={this.props.className} 
                value={props.post.uevent.eventLocation} icon='location' placeholder={i18n.t('placeholders.input.location')} />

              <TextareaMentions
                // getClassRef={this.getTextareaMentions} 
                users={true}
                tags={true}
                onChange={onChangeText}
                className={'EventEdit-TextareaMentions'} 
                icon={'edit'}
                value={text}
                placeholder={i18n.t('placeholders.textarea.news')} />
            </React.Fragment>
          }
        </Presenter>
      )*/
    }
  )
);