import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { MentionsInputProps } from 'react-mentions';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface ITextareaMentionsProps extends IClassNameProps, ReturnType<typeof mapStateToProps>, Omit<MentionsInputProps, 'children'> {
  icon?: any;
  users: boolean;
  tags: boolean;
  value: string;
  placeholder?: string;
  onChange(data: any): void;
}

export interface ITextareaMentionsState {
  text?: string;
  loading: boolean;
}

export const mapStateToProps  = (state: any) => ({
  isAdmin: getAuthUser(state).baseData.isAdmin
})

export const cnTextareaMentions = cn('TextareaMentions');