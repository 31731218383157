import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from '../../../src/redux/connector';

export interface IChatCounterProps extends IClassNameProps, IChatCounterStateProps, IDispatchProps {
  tag?: 'div';
}

export interface IChatCounterState {
  text?: string;
}

export interface IChatCounterStateProps {
  count: number
}

export const mapStateToProps = (state: any) => ({
  count: state.chat.chatNewChannelCount
})

export const cnChatCounter = cn('ChatCounter');