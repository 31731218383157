import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
export interface IBodyProps extends IClassNameProps {
    unitTimer: any;
    resultAnswersMap: { [key: string]: any };
    resultAnswers: string[];
    rightAnswersCount: number;
    questionsCount: number;
    normalQuestions: any;
    questions: string[];
    waitModerationCount?: number;
    moderatedAnswersCount?: number;
    notModerateAnswersCount?: number;
    
    highlightCorrectness?: boolean;

    attemptsList: any[];
    selectedAttempt: any;
    cangeAttempt: any;

    detailsRender: (question: any) => React.ReactNode;

    isAbsolutelyCompleted?: boolean;
}

export const cnBody = cn('ResultBody');