import { IAchievementsSelectorProps, cnAchievementsSelector } from './AchievementsSelector.index';
import * as React from 'react';
import './AchievementsSelector.scss';
import i18n from 'localizations/i18n';
import { Button, Dialog, DialogContent, DialogTitle, SearchInput, ListPlaceholder, Icon } from 'uielements/src';
import listPlaceholder from 'assets/svg/listPlaceholder.svg';
import { PostContentRender } from 'blocks/PostContentRender/PostContentRender';
import MDRender from 'uielements/src/CommonmarkRender'

export const AchievementsSelector: React.FC<IAchievementsSelectorProps> = ({
  children,
  selectedAchievement,
  achievementList,
  onChange,
}) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [achievements, setAchievemnts] = React.useState(achievementList.achievementsRenderParams.achievements);

  const closeDialog = () => {
    setShowDialog(false);
    setAchievemnts(achievementList.achievementsRenderParams.achievements);
  }
  const openDialog = () => setShowDialog(true);

  const searchAchievement = (search: string) => {
    setAchievemnts(
      achievementList.achievementsRenderParams.achievements.reduce((acc: any[], item: any) => {
        const goals = item.goals.filter((goal: any) => goal.name.toLowerCase().includes(search.toLowerCase()));
        if (goals.length) acc.push({ ...item, goals });
        return acc;
      }, [])
    );
  };

  const selectAchievement = (achievement: any) => () => {
    onChange(achievement);
    closeDialog();
  };

  return (
    <div className={'AchievementsSelector'}>
      <Dialog isShown={showDialog} onClose={closeDialog}>
        <DialogTitle onClose={closeDialog}>
          <h4 className="my-0">{i18n.t('pryaniky.createPost.achievement.modal.title')}</h4>
          <SearchInput
            className={cnAchievementsSelector('Search')}
            onChange={searchAchievement}
            throttle={0}
          />
        </DialogTitle>
        <DialogContent className={cnAchievementsSelector('Items')}>
          {achievements.length ? (
            achievements.map((item: any) => (
              <div key={item.id} className={cnAchievementsSelector('Item')}>
                {/* {item.goals.length !== 1 && <h4 className={cnAchievementsSelector('Item-Title')}>{item.name}</h4>} */}
                <h4 className={cnAchievementsSelector('Item-Title')}>{item.name}</h4>
                <ul className={cnAchievementsSelector('Goals')}>
                  {item.goals.map((goal: any, i: number) => (
                    <li key={goal.id} className={cnAchievementsSelector('Goal', { disabled: !goal.allowReportByUser }/*, { zero: item.goals.length === 1 }*/)} onClick={selectAchievement(goal)}>
                      <Icon icon="award" />{goal.name}
                      {/* {item.goals.length === 1 && <h4 className={cnAchievementsSelector('Item-Title')}><Icon icon="award" />{goal.name}</h4>}
                      {(item.goals.length !== 1) && <><Icon icon="award" />{goal.name}</>} */}
                    </li>
                  ))}
                </ul>
              </div>
            ))
          ) : (
              <div className={cnAchievementsSelector('Placeholder')}>
                <img src={listPlaceholder} className={cnAchievementsSelector('Placeholder-Image')} />
                <h4 className={cnAchievementsSelector('Placeholder-Title')}>{i18n.t('Nothing found')}</h4>
              </div>
            )}
        </DialogContent>
      </Dialog>
      <Button onClick={openDialog} className={cnAchievementsSelector('SelectButton', ['Button_achievement'])} theme="unstyled">
        <Icon icon="award" />
        <div>
          {selectedAchievement.id !== -1 ? selectedAchievement.name : i18n.t('pryaniky.createPost.achievement.select')}
        </div>
      </Button>
      {selectedAchievement.id !== -1 && <div className={cnAchievementsSelector('SelectedDesc')}>
        {/* <PostContentRender data={selectedAchievement.popupdesc} /> */}
        <MDRender textCut={true} maxCharCount={150} source={selectedAchievement.popupdesc}/>
      </div>}
      {children}
    </div>
  );
};
