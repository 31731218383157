import { Box, styled, /* Checkbox */ } from "muicomponents/src";
import Checkbox from '@mui/material/Checkbox';

export const CharsBox = styled(Box)({
    overflowY: 'auto',
    maxHeight: '272px',
}) as typeof Box

export const CharItemBox = styled(Box)({
    paddingTop: '12px',
    '&:not(:first-child)': {

    },
}) as typeof Box

export const TagsBox = styled(Box)({
    maxHeight: '272px',
    overflowY: 'auto',
    width: '560px',
}) as typeof Box

export const StyledCheckbox = styled(Checkbox)({
    '&.Mui-disabled svg': {
        color: 'gray',
    },
}) as typeof Checkbox



