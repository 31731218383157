import { ChatService } from '../../services/chat/ChatService'
import actions from 'redux/actionsTypes/Chat';

export const updateCount = (payload: any) => ({
    type: actions.UPDATE_UNREAD_COUNT,
    payload
})
export const updateList = (payload: any) => ({
    type: actions.UPDATE_LIST,
    payload
})
export const updateListAndCount = (payload: any) => ({
    type: actions.UPDATE_LIST_AND_COUNT,
    payload
})
export const changeStatus = (payload: any) => ({
    type: actions.CHANGE_STATUS,
    payload
})
export const changeUrl = (payload: any) => ({
    type: actions.CHANGE_URL,
    payload
})
export const muteUnread = (payload: any) => ({
    type: actions.MUTE_UNREAD,
    payload
})
export const updateUsers = (payload: any) => ({
    type: actions.UPDATE_USERS,
    payload
})
export const changeUid = (payload: any) => ({
    type: actions.CHANGE_UID,
    payload
})
export const updateConfig = (payload: any) => ({
    type: actions.UPDATE_CONFIG,
    payload
})

export const muteUnreadThunk = () => {
    return (dispatch: any) => {
        ChatService.init().muteUnread()
        dispatch(muteUnread({}))
    };
}
