import {User, BaseNews, defaultBaseNews,  defaultUser, defaultGroup} from '../BaseType'
import { IBaseNews, createDefaultNews } from 'News/types/baseNews'

export interface NoticesNews extends IBaseNews{
    newstype: 22;
}

export const defaultNoticesNews: NoticesNews = {
    ...createDefaultNews(),
    newstype: 22
}