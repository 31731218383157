import { IStateType } from 'redux/store';
import { IOrgchartDataSettings } from './interfaces'

/**
 * get cuurent orgchart state
 * @param { IState } state
 */
export const getOrgchartState = (state: IStateType) => state.orgchart
export const getOrgchartChangedUnit = (state: IStateType, id: string) => state.orgchart.units[id]
export const getOrgchartNewUnit = (state: IStateType) => state.orgchart.initUnitsData

export const getParentUnit = (id: string) => (state: IStateType) => getOrgchartState(state).units[id];

export const getOrgchartPositions = (id: string) => (state: IStateType) => getOrgchartState(state).positions[id];

export const getOrgchartPosition = (state: IStateType, id: string) => state.orgchart.positions[id]

export const getOrgchartChangedPosition = (id: string) => (state: IStateType) => {
    const { initPositionData } = getOrgchartState(state);
    if (initPositionData) {
        return initPositionData[id]
    } return undefined
}
export const getOrgchartNewPositionsState = (state: IStateType) => state.orgchart.newPositionsData
export const getSelect = (state: IStateType) => state.orgchart.select
export const getShowOnlyOpenVacancy = (state: IStateType) => state.orgchart.showOnlyOpenVacancy
// export const getNewPosition = (state: IStateType) => state.orgchart.newPositionsCreate.positions