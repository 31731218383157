import { ACTION } from 'utils/src/CommonRedux/actionsTypes';


let actions: {
    //saga
    CHANGE_ANSWER: 'CHANGE_ANSWER',
    //reducer


    //common
} = {
    //saga
    CHANGE_ANSWER: 'CHANGE_ANSWER',

    //reducer

    //common
}


export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, 'NEWS_EVENT') }), {} as typeof actions);