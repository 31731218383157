import React from 'react';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { cnWidget, cnWidgetMui, IWidgetProps } from '../Widget.index';
import { widgets } from "i.widgets";
import { TagsCloud } from '../../TagsCloud/TagsCloud';

const type = 'tagsCloud';

export default class WidgetTypeTagsCloud extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
 
    return (
      <TagsCloud {...this.props as any} 
      id={this.props.widget.id} 
      data={this.props.widget.settings?.tagsCloud} 
      context={this.props.wcontext} 
      className={cnWidgetMui({ type, subtype })} 
      tag={widgets.components.common} 
      edit={this.props.edit} />
    );
  }
}