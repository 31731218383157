import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ISkill } from 'redux/sagas/Skills/types'

///////////

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace Skills {
    // eslint-disable-next-line @typescript-eslint/no-namespace

    export type IPresenterProps = IOwnProps

    export interface IState { }

    export interface IOwnProps extends IClassNameProps {
        setTitle: (titile: string) => void;
        onComplete: () => void;
        title: string;
        currentUser: any;
        withError: boolean;
    }
}

////////////

export const cnClassName = cn('SkillsCreator');