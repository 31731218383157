import { IListProps, IListStateProps } from "blocks/List/List.index";
import { updateContext } from "../../../../redux/actions/Widgets";
import { cn } from '@bem-react/classname';
import { filters } from 'utils/src/filters.prn';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';


export interface IListQuestsDefaultRequestProps {
  search: string;
  extended: boolean;
  catFilter: "all" | "waiting" | "approved" | "deleted" |string;
  uid: string;
  count: number;
  [s: string]: any;
}

export interface IListQuestsDefaultProps {
  requestOptions: IListQuestsDefaultRequestProps;
}

export interface IListTypeQuestsProps extends IListProps, IListStateProps, IListQuestsDefaultProps, IListTypeQuestsStateProps {
  tag?: 'div';
}



export type IListTypeQuestsStateProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: any) => ({
  isAdmin: getAuthUser(state).baseData.isAdmin

})
export const mapActionsToProps = {
  updateContext
};

export const cnQuestModerate = cn('QuestModerate');

const fltrs = {
  ...filters,
  catFilter: 'catFilter',
  isAchievements: 'isAchievements',
  tab: 'tab'
}

export type IFLTRS = typeof fltrs & { [s: string]: string };

export const avalibleFiltersQuestModerate = Object.keys(fltrs).reduce((acc, cur) => acc[cur] === '' ? { ...acc, [cur]: cur } : acc, fltrs as IFLTRS);