import React from 'react';
import { Actions } from '@jsonforms/core';
import { get_variable_name } from './utils';
import i18n from 'localization/src/i18n';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';

import { statisticFilterTypes } from '../../Statistic/constants';

export const statisticFilters = (props: any) => {
  if (!props.visible) return null;
  const data = props.data[get_variable_name(props.uischema.scope)];

  const onChange = (filter: any) => {
    props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
      const isExists = (oldData || []).some((el: any) => el.type === filter.type);
      return isExists ?
        oldData.filter((el: any) => el.type !== filter.type) :
        [...oldData, filter];
    }))
  }

  return <div style={{ padding: '24px' }}>
    {statisticFilterTypes.map((filter) => <Checkbox
      value={data.some((el: any) => el.type === filter.type)}
      label={i18n.t(`pryaniky.statistics.filter.widget.${filter.type}`)}
      onChange={() => onChange(filter)}
    />)}
  </div>
}
