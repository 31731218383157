import * as React from 'react';
import * as utils from 'utils/src/utils';
import * as ReactDOM from 'react-dom';
// import i18n from '../../localizations/i18n';

import { cnModal, IModalProps, IModalState } from './Modal.index';
import { Header } from "./Header/Modal-Header";
import { Body } from "./Body/Modal-Body";
import { Footer } from "./Footer/Modal-Footer";

import './Modal.scss';

export class ModalPresenter extends React.Component<IModalProps, IModalState> {

  public static getDerivedStateFromProps(props: IModalProps, state: IModalState) {
    if (!utils.compareData(props.data, state.data)) {
      return { data: props.data }
    }
    return null;
  }

  public utils: any = utils;
  public el: HTMLElement | null;
  public modal: HTMLElement | null;
  public state = {
    init: false,
    data: this.props.data,
    dataIsValid: true
  };

  public componentDidMount() {
    setTimeout(() => {
      this.setState({ init: true });
    }, 10);
    // this.setMargin();
    this.validateData();
    document.addEventListener('keydown', this.handleKeyDown);
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  public render() {
    const { className = '', header, type, noFooter, okBtnAction, okBtnText, cancelBtnAction, cancelBtnText, data, ...bodyProps } = this.props;
    return (
      <div ref={el => this.el = el}
        onClick={this.unmountModal}
        className={cnModal({ init: this.state.init }, [className])}>
        <div ref={el => this.modal = el} className={cnModal('Window', { init: this.state.init })}>
          <Header text={this.props.header || 'working on modal'} border={type !== 'confirm'} closeFunction={this.closeModal} />
          {
            type !== 'confirm' && <Body type={type} parent={this} {...bodyProps} />
          }
          {
            !noFooter && <Footer border={type !== 'confirm'} okBtnText={okBtnText} dataIsValid={this.state.dataIsValid} okBtnAction={this.okBtnAction} cancelBtnText={cancelBtnText} cancelBtnAction={this.cancelBtnAction} />
          }
        </div>
      </div>
    )
  }

  private handleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 27) this.closeModal();
  }

  private validateData = () => {
    if (this.props.okBtnValidate) this.setState({ dataIsValid: this.props.okBtnValidate(this.state.data) });
  }

  // private setMargin() {
  //   if(this.modal) this.modal.style.marginTop = '-' + (this.modal.offsetHeight / 2) + 'px';
  // }

  private closeModal = (ev?: Event) => {
    if (ev) {
      ev.stopPropagation();
      ev.preventDefault();
    }
    this.setState({ init: false });
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(this.props.root.elem)
      setTimeout(() => {
        this.props.root.remove();
      }, 10);
    }, 250);
  }

  private unmountModal = (ev: React.MouseEvent) => {
    if (this.props.disableCloseOnClickOutside) return;
    const coincidence = ev.target === this.el;
    if (coincidence) this.setState({ init: false });
    setTimeout(() => {
      if (coincidence) {
        ReactDOM.unmountComponentAtNode(this.props.root.elem);
        setTimeout(() => {
          this.props.root.remove();
        }, 10);
      }
    }, 250);
  }

  private okBtnAction = (ev: Event) => {
    if (this.props.okBtnAction) this.props.okBtnAction(this.state.data);
    this.closeModal(ev);
  }

  private cancelBtnAction = (ev: Event) => {
    this.closeModal(ev);
    if (this.props.cancelBtnAction) this.props.cancelBtnAction();
  }

}


export const Modal = ModalPresenter; 