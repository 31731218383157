import { connect, useSelector } from 'react-redux';
import React from 'react';
import { getAuthCookies, deleteAllCookies, API, setCookie } from 'utils/src/utils';
import { mapDispatchToProps } from '../../redux/connector';
import {ChatService} from 'services/chat/ChatService'
import { ILogoutProps } from './Logout.index';

import './Logout.scss';
import { IStateType as IState } from 'redux/store';
import { getAppSettings } from 'utils/src/CommonRedux/base/selectors';

const cookiesToDelete = ['authUid', 'authAt', '.PRYANIKYAC'];

const domainsToDelete = ['{{0}}.pryaniky.com', '.{{0}}.pryaniky.com', '{{0}}.app.pryaniky.com', '.{{0}}.app.pryaniky.com'];

const fillDomain = (pattern: string, data: string[]) => data.reduce((p, d, idx) => p.replace(`{{${idx}}}`, d), pattern);

export const LogoutPresenter: React.FC<ILogoutProps> = ({ changeVm, companyName, cb }) => {
  // const companyName = getCookie('companyName');
  const pastDate = new Date(0).toUTCString();
  // document.body.classList.remove(companyName || "testtesttest");

  let { logoutUrl: logoutUrlRedirect } = useSelector(getAppSettings);

  const cookieIsClear = false;

  API.system.logoff().r.then(() => {
    const { authAt, authUid } = getAuthCookies();
    if (authAt || authUid) {
      cookiesToDelete.forEach(cookie => {
        domainsToDelete.forEach(domain => {
          // const cc = `${cookie}=; domain=${fillDomain(domain, [(companyName || "testtesttest")])} path=/; expires=${pastDate};samesite=None; secure`;
          setCookie({ name: cookie, value: '', props: { expires: pastDate } });
          // document.cookie = cc;
        });
      });
    }

    ChatService.init().logOut();

    if (cb) cb();

    if (window.location.hostname.indexOf('localhost') !== -1) {
      const { authAt, authUid } = getAuthCookies();
      deleteAllCookies();
      if(logoutUrlRedirect) {
        console.log(`logoutUrlRedirect is not empty, at pro will redirect to "${logoutUrlRedirect}"`);
      }
      if (authAt) window.location.reload();
    } else {
      if(logoutUrlRedirect) {
        window.location.assign(logoutUrlRedirect);
      } else {
        window.location.reload();
      }
    }
  }).catch(e => {
      // deleteAllCookies();
  });
  
  return null;
};

export const Logout = connect(
  (state: IState) => ({
    siteUrl: state.store.appSettings && state.store.appSettings.siteURL || '',
  }),
  mapDispatchToProps({})
)(LogoutPresenter);
