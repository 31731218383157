import React from 'react';
import { Box, Button, Paper, styled, Typography } from "muicomponents/src";
import Skeleton from '@mui/material/Skeleton';


export const RightBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
}) as typeof Box

export const DownBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  overflowX: 'hidden',
  paddingBottom: '16px',
  width: '100%',
  '@media screen and (max-width: 600px)': {
    flexWrap: 'wrap',
  }
}) as typeof Box

export const ItemBox = styled(Box)({
  '&:not(:last-child)': {
    marginRight: '8px',
  },
  minWidth: '10px',
  whiteSpace: 'nowrap',
  display: 'flex',
  '@media screen and (max-width: 600px)': {
    marginBottom: '8px',
    marginTop: '8px',
  }
}) as typeof Box

export const FakeBtnBox = styled(Box)({
  overflowX: 'hidden',
  flexShrink: 2,
  minWidth: '5px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
}) as typeof Box

export const CountBadgeStyles = {
  /* overflowX: 'hidden',
  overflowY: 'visible', */
  flexShrink: 2,
  minWidth: '5px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
}

export const FilterBtnText = styled(Typography)({
  textTransform: 'capitalize',
  textOverflow: 'ellipsis',
}) as typeof Typography

export const PaperContainer = styled(Paper)({
  marginBottom: '24px', padding: '16px',
  paddingBottom: '0',
  boxSizing: 'border-box',
}) as typeof Paper

export const UpperBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
}) as typeof Box

export const SearchMenuBox = styled(Box)({
  margin: '8px',
}) as typeof Box

export const CatsListBox = styled(Box)({
  overflow: 'auto',
  maxHeight: '252px',
}) as typeof Box

export const FiltersTypeShopSkeleton = () => {
  return <Box sx={{ m: 0, p: 2, pt: 0, mb: 2 }}>
    <Box display='flex' alignItems='center'>
      <Skeleton variant="text" height={70} width={'80%'} sx={{ m: 0, p: 0, mr: 3 }} />
      <Skeleton variant="circular" height={40} width={40} sx={{}} />
    </Box>
    <Box display='flex' alignItems='center'>
      <Skeleton variant="text" height={60} sx={{ flex: 1, mr: 1 }} />
      <Skeleton variant="text" height={60} sx={{ flex: 1, mr: 1 }} />
      <Skeleton variant="text" height={60} sx={{ flex: 1, mr: 1 }} />
      <Skeleton variant="text" height={60} sx={{ flex: 1, mr: 1 }} />
      <Skeleton variant="text" height={60} sx={{ flex: 1, mr: 1 }} />
    </Box>
  </Box>
}


export const FilterBtnSkeleton = () => {
  return <Button size='small' sx={{ mr: 1, padding: 0 }} >
    <Skeleton variant='rectangular'
      sx={{ width: '100%', height: '31px', borderRadius: '4px', margin: 0, padding: 0, }}
    />
  </Button>

}




