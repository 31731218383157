import React, { useEffect, FC, ComponentType } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
    loadFullUnit,
} from "../../redux/actions/LMS";
import {
    getUnitLogByUnitId,
    getUnitContentById
} from '../../redux/sagas/LMS/selectors'
import { isEmpty } from 'lodash'

export function withUnitContentLoader<P = any>(Component: ComponentType<P>) {
    const Loader: FC<any> = (props) => {
        const { version } = props
        const sid = props.sid || props.sId
        const uid = props.uid || props.unit?.id
        const dispatch = useDispatch()
        const log = useSelector(getUnitLogByUnitId(uid))
        const unitContent = useSelector(getUnitContentById(uid, version))

        useEffect(() => {
            isEmpty(unitContent) && dispatch(loadFullUnit(uid, version))
        }, [sid, uid, version]);

        // if (!log) return <div>no log!!!</div>

        return <Component {...props} log={log} unitContent={isEmpty(unitContent) ? undefined : unitContent} />
    }
    return Loader
}

export default withUnitContentLoader