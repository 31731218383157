/**
 * @packageDocumentation
 * @module ListHeader
 */
import { IHeaderProps } from '../List-Header.index';

import { IDispatchProps } from '../../../../redux/connector';

export interface IHeaderTypeCommonProps extends IHeaderProps, IHeaderStateProps, IDispatchProps {
  tag?: 'div';
  children?: any;
}

export interface IHeaderTypeCommonState {
  // text?: string;
}

export interface IHeaderStateProps {
  // store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})