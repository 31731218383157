import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { ReplyContextProps } from '../../contexts/reply'
import { NewsContextProps } from '../../contexts/news'
import {
    deleteReply,
    sendLikeReply,
    insertMention,
    loadFullReplt
} from 'News/redux/actions'
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace ReplyView {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & ReplyContextProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            children?: React.ReactNode
        }

        export interface IStateProps {
 
        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.ReplyView.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({

}, dispatch);

////////////

export const cnClassName = cn('NewsReplyView');