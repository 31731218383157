import React, { FC, memo, useState, useCallback, useMemo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext, NewsContextProps } from '../../../contexts/news'
import { Actions } from 'blocks/NewsView/Actions/Actions';
import { Like } from 'blocks/NewsView/Actions/Like/Like';
import { Thank } from 'blocks/NewsView/Actions/Thank/Thank';
import likersModal from 'blocks/Dialogs/News/Likers/NewsLikers';
import { Button, Icon } from 'uielements/src'
import { Bable } from 'blocks/Bable/Bable'

type IPresenterProps = News.NewsActions.IPresenterProps
type IOwnProps = News.NewsActions.IOwnProps
type IDispatchProps = News.NewsActions.IDispatchProps
type IStateProps = News.NewsActions.IStateProps
type IState = News.NewsActions.IState

const noopObj: any = {}
export const Presenter: FC<IPresenterProps> = ({
    className,
    id,
    sendAction,
    action,
    children
}) => {
    const onClick = useCallback(() => sendAction(action.action), [id, action.action])

    return <div className={cnClassName({}, [className])}>
        {children || <Button theme='unstyled' onClick={onClick}>
            <Icon icon={action.icon} />
            {action.action.type}
        </Button>}
        {/* {
            likeCount !== 0 &&
            <Button theme='unstyled' className={'BtnLikeNum'} onClick={onOpen}>
                <Bable>{likeCount}</Bable>
            </Button>
        } */}
    </div>
}
export const Connected = connect<IStateProps, IDispatchProps, IOwnProps & NewsContextProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter))
export default withNewsContext(Connected, ['newsId']);

