import React, { FC, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import { createDefaultModel } from '../News.type'
import NewsEditor from 'News/containers/NewsEditor'
import PryanikyEditor from 'uielements/src/PryanikyEditorV1'
import { markdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap'
import MentionAction from 'News/components/MentionDrop'
import TagDrop from 'News/components/TagDrop'
import MentionFind from 'News/components/MentionFind'
import {
    useBaseEdits
} from 'News/utils/hooks'


type IPresenterProps = News.NewsCreator.IPresenterProps
type IOwnProps = News.NewsCreator.IOwnProps
type IDispatchProps = News.NewsCreator.IDispatchProps
type IStateProps = News.NewsCreator.IStateProps
type IState = News.NewsCreator.IState

const toolBarStyle = { top: 55 }
const noopFn = () => { }



export const Presenter: FC<IPresenterProps> = ({
    className,
}) => {
    const {
        draftState,
        onFind,
        onMention,
        onSubmit,
        setDraft
    } = useBaseEdits(createDefaultModel())

    return <NewsEditor
        actions={
            <>
                <MentionAction value={null} onChange={onMention} />
                <TagDrop value={null} onChange={onMention} />
                <MentionFind key={'onFocusShort'} onClick={onFind} />
            </>
        }
        onSubmit={onSubmit}
        className={cnClassName({}, [className])}
    >
        {draftState && <PryanikyEditor
            maxFileSize={100}
            enableModules={markdownPreset}
            toolBarStyle={toolBarStyle}
            value={draftState}
            setActionBtn={noopFn}
            onChange={setDraft}
        />}
    </NewsEditor>
}

export const Connected = connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));

export const initNewsNews = () => ComponentInjector.getInstance().addNode('news_creator', <Connected />, 'news')