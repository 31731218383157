import React, { FC, createRef, useEffect, useState } from 'react'
import { cnCourseInfo, mapDispatchToProps, mapStateToProps } from './CourseInfo.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './CourseInfo.scss'
import { Avatar, Button, Icon } from 'uielements/src'
// import { Button } from 'uielements/src';
import { Tabs } from '../Tabs/Tabs'
import Editor from '../CourseEditor/Course'
import { ImgLoader } from 'blocks/ImgLoader/ImgLoader';
import ButtonM from '@material-ui/core/Button';
import { ButtonBox } from 'uielements/src/ButtonBox/ButtonBox'
import withHelpTooltip from 'blocks/HOCs/Button/withHelpTooltip'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { DialogCreateSession } from '../../dialogs/CreateSession/CreateSession'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { ImageLoader} from 'uielements/src/index';
import { Translate } from 'localizations/Translate';

const BtnWithTool = withLoadSpin(withHelpTooltip(Button))

export const CourseInfoPresenter: FC<any> = ({
    className,
    course,
    saveCourse,
    updateCourse,
    allowPlayCourse,
    allowPlay
}) => {
    const [edit, setEdit] = useState(false)
    const [show, setShow] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const onSave = () => {
        setEdit(false)
        saveCourse(course.id)
    }

    const loadBackgroundRef = createRef<ImageLoader>();
    const loadIcondRef = createRef<ImageLoader>();

    const onLoadEnd = (img: any) => updateCourse({ ...course, largeImgUrl: img.previewUrl, largeImgId: img.id, imgId: img.id })

    const showSessionTool = allowPlay.isTested && !allowPlay.isAllow && allowPlay.cid === course.id

    useEffect(() => {
        !show && setShow(showSessionTool)
    }, [showSessionTool])

    const onClickCreate = (e: any) => {
        setShow(false)
        e.stopPropagation()
        DialogCreateSession({cid:course.id, fromPreview:true}).then(() => { }).catch(()=>{})
    }

    return <div className={cnCourseInfo({}, [className])}>
        <div className={cnCourseInfo('TopActions')}>
            <div className={cnCourseInfo('RightActions')}>
                <Button
                    className={cnCourseInfo('BackLink')}
                    theme={'unstyled_center'}
                    type='rlink'
                    href={'/TeacherOffice'}><Icon icon={'arrow-left'} />{tT('course_info.back.1')}</Button>
            </div>
            <div className={cnCourseInfo('LeftActions')}>
                <ButtonBox orientation={'horizontal'} className={cnCourseInfo('Actions')}>
                    <Button onClick={() => setEdit(true)}
                    // title={tT('course_info.edit.title.1')} 
                    >
                        <Tooltip title={tT('course_info.edit.title.1')} >
                            <span><Icon icon={'edit'} /></span>
                        </Tooltip>
                    </Button>
                    <BtnWithTool
                        isLoading={allowPlay.isLoading}
                        disableHelp={!showSessionTool}
                        hideDelay={650}
                        manual={show}
                        manualOpen={show}
                        onMouseLeave={() => setShow(false)}
                        helpHtml={<div>
                            <div>{tT('course_info.not_available_sessions.1')}</div>
                            <Button onClick={onClickCreate}>{tT('course_info.create_session.1')}</Button>
                        </div>}
                        onClick={() => allowPlayCourse(course.id)}
                    // title={tT('course_info.view.1')} 
                    >
                        <Tooltip title={tT('course_info.view.1')} >
                            <span><Icon icon={'eye'} /></span>
                        </Tooltip>
                    </BtnWithTool>
                </ButtonBox>
                {/* <CreateSessionDialog fromPreview={true} cid={course.id} isShown={openDialog} onClose={() => setOpenDialog(false)} /> */}
            </div>
        </div>

        <div className={cnCourseInfo('Content')}>
            <div className={cnCourseInfo('ImageBox')}>
                {edit ?
                       <ImageLoader
                       className={cnCourseInfo('ImageBox-BackgroundImg')}
                       ref={loadIcondRef}
                       fileId={course.largeImgUrl}
                       onLoaded={onLoadEnd}
                      
                       defaultUrl={course.largeImgUrl}
                       crop={{ 
                         aspect: 16/10,
                         width: 240,
                         height: 150}}
                         children={
                            <>                             
                              <Button   className={cnCourseInfo('ImageBox-BackgroundImg-downloadBTN')} onClick={() => loadIcondRef.current && loadIcondRef.current.open()}>
                                <Translate i18nKey='pryaniky.quizzes.quiz.upload' />
                              </Button>
                            </>
                          }
                       />:
                    // <ImgLoader 
                    // type={'profileEdit'} 
                    // previewUrl={course.largeImgUrl} 
                    // crop={{ 
                    //     aspect: 16/10,
                    //     width: 240,
                    //     height: 150}}
                    // onLoadEnd={onLoadEnd} /> :

                    <Avatar size={178} /*imgId={course.}*/ imgUrl={course.largeImgUrl} />
                }
            </div>
            <div className={cnCourseInfo('DescBox')}>
                {edit ?
                    <>
                        <Editor updateCourse={updateCourse} course={course} />
                        {/* <FormControl > */}
                        <ButtonM
                            variant={'contained'}
                            color={'primary'}
                            onClick={onSave}
                            size={'small'}
                            disableElevation>{tT('course_info.edit.save.1')}</ButtonM>
                        <ButtonM
                            variant={'outlined'}
                            color={'default'}
                            onClick={() => setEdit(false)}
                            size={"small"}
                            disableElevation>{tT('course_info.edit.cancle.1')}</ButtonM>
                        {/* </FormControl> */}
                    </> :
                    <>
                        <div className={cnCourseInfo('Title')}>
                            <h3 className={cnCourseInfo('Name')}>{course.name}</h3>
                            <div className={cnCourseInfo('Actions')}>
                            </div>
                        </div>
                        <div className={cnCourseInfo('Description')}>
                            {course.description}
                        </div>
                    </>
                }
            </div>
        </div>
        <div className={cnCourseInfo('Nav')}>
            <div className={cnCourseInfo('Noop')}></div>
            <Tabs />
            <div className={cnCourseInfo('Noop')}></div>
        </div>
    </div>
}

export const CourseInfo = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CourseInfoPresenter));
