import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';

export const cnSettingsArticles = cn('SettingsArticlesWidget');

export namespace SettingsArticlesWidget {


    export interface Settings { [s: string]: any; }
    export interface Props extends IClassNameProps {
        settings: Settings | null,
        isEdit:boolean
    }
}
