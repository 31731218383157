import React, { FC, useState, useEffect } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'

import FormControlO from '@material-ui/core/FormControl';
import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import Button from '@material-ui/core/Button';
import PaperO from '@material-ui/core/Paper';
import { controlStyle, peperStyle, controlLabelStyle } from './style'
// import { PryanikyEditor } from 'blocks/PryanikyEditor/_PryanikyEditor'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { styled } from '@material-ui/core/styles';

import { markdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap'
import PryanikyEditor from 'uielements/src/PryanikyEditorV1'
import { mdToRaw, rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
// import { draftToMarkdown, markdownToDraft } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';

// const TextFieldHelped = fieldWithHelp(TextField)
const Paper = styled(PaperO)(peperStyle)
const FormControlLabelHelped = styled(fieldWithHelp(FormControlLabel))(controlLabelStyle)
const FormControlHelped = styled(fieldWithHelp(FormControlO))(controlStyle)
const FormControl = styled(FormControlO)(controlStyle)
// import i18n from 'localizations/i18n';


export const Presenter: FC<IPresenterProps> = ({ className, unit, updateUnit, saveLesson, convertLegacyPage }) => {
    const content = unit.content.blocks.maincontent.markdownContent !== "" ? unit.content.blocks.maincontent.markdownContent : unit.content.blocks.maincontent.content
    // const s = EditorState.createWithContent(convertFromRaw(markdownToDraft(content, mdToRaw)))

    // const [state, setState] = useState<any>(s)
    useEffect(() => {
        // setState(s)
    }, [unit])

    return <div className={cnClassName({}, [className])}>
        <Paper elevation={0} square>
            {/* <Button variant={"contained"} color={'primary'} onClick={() => convertLegacyPage(unit.id, unit.courseId, state)}>Конверитировать</Button> */}
            <div>
                Может показаться, что после нажатия "Конверитировать" ни чего не происходит, однако это не так. <br/>
                Конвертирование будет завершено когда этот юнит пропадёт из списка. В какой то момент их там окажется 2. <br />
                При этом переход на сконвертированый юнит будет произведен автоматически.<br /><br />
                НЕ НУЖНО НИ ЧЕГО НАЖИМАТЬ ДО ТЕХ ПОР, ПОКА НЕ ИСЧЕЗНЕТ ЭТА НАДПИСЬ!!!<br /><br />
                Контент можно редактировать до нажатия кнопки "Конверитировать", всё изменения внесеные после её нажатия не перенесутся.<br />
                Контент здесь виден таким, какой он будет.

                </div>
            <h2>{unit.name}</h2>
            {/* <FormControl fullWidth >
                <PryanikyEditor
                    enableModules={markdownPreset}
                    toolBarStyle={{ top: 108 }}
                    value={state}
                    setActionBtn={(v: any) => { }}
                    onChange={setState}
                />
            </FormControl> */}
        </Paper>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
