// import { User, BaseNews, defaultBaseNews, defaultUser, defaultGroup, Group } from '../BaseType'
import { IBaseNews, createDefaultNews } from 'News/types/baseNews'
export interface WelcomesNews extends IBaseNews {
    newstype: 17;
}



export const defaultWelcomesNews: WelcomesNews = {
    ...createDefaultNews(),
    newstype: 17,
}

