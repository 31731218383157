import { ReactNode } from 'react';
import { PollNews } from './Polls.types'
import { changeAnswer } from './redux/actions'
import {
    updateNews,
    pathNews
} from 'News/redux/actions'
import { bindActionCreators } from 'redux';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { NewsContextProps } from 'News/contexts/news'
import {
    getNewsParamsById,
    getNewsById
} from 'News/redux/saga/selectors'
export interface IPollsPropsType extends NewsContextProps {
}
export type TStateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
export interface IPollsStateType {
    completed: boolean;
}

const newsParams = [
    'id',
    'poll',
    'fromCache'
] as const

export const mapStateToProps = (state: any, { newsId }: IPollsPropsType) => ({
    news: getNewsById(newsId)(state),
    userData: getCurrentUser(state),
    ...getNewsParamsById<PollNews, typeof newsParams[number]>(newsId, newsParams)(state)
})


export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    changeAnswer,
    updateNews,
    pathNews
}, dispatch);