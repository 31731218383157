import BaseApi from '../baseAPI/baseAPI'

export declare namespace ADLTypes {
    export type Request = "continue" | "previous" | "choice" | "jump" | "exit" | "exitAll" | "abandon" | "abandonAll" | "_none_";
    export type Continue = "true" | "false" | "unknown";
    export type Previous = "true" | "false" | "unknown";
}


/**
 * Determine if the choice request is valid
 */
function adlNavRequestValidChoice(_target: any) {
    return "unknown";
}

/**
 * Determine if the jump request is valid
 */
function adlNavRequestValidJump(_target: any) {
    return "unknown";
}

class RequestValid {
    /**
     * 
     */
    private API: BaseApi;

    /**
     * Allowed values: "true", "false", "unknown"
     */
    private _continue: ADLTypes.Continue = "unknown";

    /**
     * Allowed values: "true", "false", "unknown"
     */
    private _previous: ADLTypes.Previous =  "unknown";


    constructor(API: BaseApi) {
        this.API = API
    }

    public get continue() { 
        return this._continue; 
    }

    public set continue(_) { 
        this.API.throwSCORMError(404); 
    }


    public get previous() { 
        return this._previous; 
    }

    public set previous(_) { 
        this.API.throwSCORMError(404)
    }

    public choice = {
        _isTargetValid: adlNavRequestValidChoice
    }

    public jump = {
        _isTargetValid: adlNavRequestValidJump
    }
}


class Nav {
    /**
     * Allowed values: "continue", "previous", "choice", "jump", "exit", "exitAll", "abandon", "abandonAll", "_none_"
     */
    private _request: ADLTypes.Request = "_none_"

    public request_valid: RequestValid
    
    constructor(API: BaseApi) {
        this.request_valid = new RequestValid(API)
    }

    get request() { return this._request; }
    set request(request) { this._request = request; }
}

/**
 * Adl data model
 */
class ADL {
    public nav: Nav;

    constructor(API: BaseApi) {
        this.nav = new Nav(API)
    }
}


export default ADL