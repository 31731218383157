import { IFreeAnswerProps } from './FreeAnswer.index'
import React, { memo, useCallback } from 'react'
import './FreeAnswer.scss';
import i18n from '../../../../localizations/i18n';
import { AnswerLayout } from '../AnswerLayout/AnswerLayout'
import { Button } from 'uielements/src/Button/Button'
import { Icon } from 'uielements/src/Icon/Icon'

export const FreeAnswer: React.FC<IFreeAnswerProps> = memo(({ children }) => {
    const [open, setOpen] = React.useState(false);
    const onClose = useCallback(() => setOpen(false), []);
    const onOpen = useCallback(() => setOpen(true), []);
    return <AnswerLayout>
        {
            !open
                ? <Button theme="unstyled" className="AnswerAdd" onClick={onOpen}>
                    <Icon icon='plus' />{i18n.t('add_anwer')}
                </Button>
                : children(onClose)
        }
    </AnswerLayout>
})