import React, { useState, useEffect, useMemo } from 'react'
import { IAnswerItem, cnAnswerItem, mapStateToProps, mapDispatchToProps, useAnswer } from './AnswerItem.index'
import './AnswerItem.scss'
import { connect } from 'react-redux'
// import { PostContentRender } from '../../../PostContentRender/PostContentRender';
// import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from 'muicomponents/src/Checkbox/Checkbox';
import { Radio } from 'muicomponents/src/Radio/Radio';
// import Radio from 'uielements/src/MaterialElements/Radio';
import Input from 'uielements/src/MaterialElements/Input/Input';
import { Translate } from 'localizations/Translate';
import { Attachments } from 'uielements/src/MaterialElements/Attachments/Attachments';
import { Icon } from 'uielements/src';
// import DatePicker from 'uielements/src/MaterialElements/DatePicker/DatePicker';
import { DatePicker } from 'muicomponents/src/DatePicker/DatePicker';
import TimePicker from 'uielements/src/MaterialElements/TimePicker/TimePicker';
import i18n from 'localizations/i18n';
import { Slider } from 'muicomponents/src/Slider/Slider';
import { useDebounce, useDidUpdateEffect } from 'utils/src/hooks';
import { FilesUploader } from 'muicomponents/src/FilesUploader/FilesUploader'
import { useFileUploader, mapFromAttachmentsList, IAttachmentType } from 'utils/src/hooks'
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip'
import { styled } from 'muicomponents/src/mui/system';
import Rating from '@mui/material/Rating';
import { NumberTextField, TextField } from 'muicomponents/src/TextField/TextField';
import { Box, Divider } from 'muicomponents/src'
import {
    Star, StarBorder
} from 'muicomponents/src/Icons/Icons';
import MDRender from 'uielements/src/CommonmarkRender';
import FormHelperText from '@mui/material/FormHelperText';

export const SliderCustom = styled(Slider)({
    height: 10,
    padding: "13px 0px 13px 12px",
    // '.MuiSlider-markLabel': {
    //     fontSize: 16,
    //     color: "#000"
    // },

    ".MuiSlider-thumb": {
        height: 28,
        width: 28,
        margin: "0 5px",
    },
    '& .MuiSlider-mark': {
        backgroundColor: 'currentColor',
        height: 12,
        width: 3,
        margin: "0 5px",
        '&.MuiSlider-markActive': {
            opacity: 0.38,
            backgroundColor: '#c8def4',
        },
    },
    '& .MuiSlider-markLabel': {
        margin: "0 5px",
    }

}) as typeof Slider;



export const AnswerItemPresenter = ({
    item,
    sliderSymbols,
    answered,
    allowAttachments,
    qtype,
    questionId,
    disableMoreAnswers,
}: IAnswerItem) => {

    const {
        answersIds,
        text,
        numericAnswer,
        isFree,
        setAnswer
    } = useAnswer(questionId);

    let cls;

    const {
        isLoading,
        files,
        onFileChange,
        removeFile,
        uploadDisabled
    } = useFileUploader({
        uploadOnAdd: true,
        maxFilesCount: 1
    });

    useDidUpdateEffect(() => {
        if (qtype === 'Text' && allowAttachments) {
            if (!files.length) {
                setAnswer({
                    attachments: []
                })
            }
            const file = files[0];
            if (!file || !file.response?.data[0]) return;
            setAnswer({
                attachments: [file.response?.data[0]]
            });
        };
    }, [files, qtype, allowAttachments]);

    const answerDateTime = useMemo(() => {
        if ((qtype === 'Date' || qtype === 'Time') && text) {
            return new Date(text);
        }
        return "";
    }, [text]);

    const getMarksArr = (min: number, max: number) => {
        if (min > max) {
            return [...Array(min)].map((el, ind) => ind + 1).filter(el => el >= max).reverse()
        } else {

            return [...Array(max + 1)].map((el, ind) => ind).filter(el => el >= min)
        }
    }

    const marks = getMarksArr(item.numericMin || 0, item.numericMax || 0).map((el) => {
        return {
            value: el,
            label: el.toString(),
        }
    })

    const titleLength = item.text.slice(0, 70).indexOf(" ") === -1 ? 100 : undefined

    const name = item.text.split(' ').map((el: string) => el.length > 100 ? el.slice(0, 100) + "..." : el).join(" ")


    return (
        <Box className={cnAnswerItem('')}>
            {(qtype === 'Single' || qtype === 'SingleSlot') &&
                <Box>
                    <Tooltip title={titleLength && (titleLength < item.text.length) ? item.text : ""}>
                        <span>
                            <FormControlLabel sx={{
                                alignItems: "flex-start"
                            }}
                                control={<Radio
                                    onChange={() => {
                                        setAnswer({
                                            answersIds: [item.id],
                                            isFree: item.isFree
                                        });
                                    }}
                                    checked={answersIds[0] === item.id}
                                    // label={name}
                                    disabled={answered || item.isDisabled}
                                    size={'medium'}
                                    className={cls}
                                />}
                                label={name} />
                            {item.description && <Box className={cnAnswerItem('Description')} sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "32px"
                            }}>
                                <FormHelperText>
                                    <MDRender source={item.description || ''} textCut={false} />
                                </FormHelperText>

                            </Box>}
                            {/* <Radio

                                onChange={() => {
                                    setAnswer({
                                        answersIds: [item.id],
                                        isFree: item.isFree
                                    });
                                }}
                                checked={answersIds[0] === item.id}
                                label={<Box>
                                    {name}
                                    <FormHelperText> <MDRender source={item.description || ''} textCut={false} type={'inline'} /></FormHelperText>
                                </Box>}
                                // label={name}
                                disabled={answered}
                                size={'medium'}
                                className={cls}

                            /> */}

                            {(item.isFree && answersIds[0] === item.id) && < TextField
                                multiline
                                autoComplete="off"
                                suggesterValue={false}
                                label={Translate.t({ i18nKey: 'pryaniky.administration.surveys.item.other' })}

                                style={{ width: "100%" }}
                                variant='outlined'
                                value={text}
                                onChange={(e) => {
                                    setAnswer({
                                        text: e.currentTarget.value
                                    });
                                }}

                            />}

                        </span>
                    </Tooltip>
                </Box>
            }
            {
                qtype === 'Multi' &&
                <Box>
                    <Tooltip title={titleLength && (titleLength < item.text.length) ? item.text : ""}>
                        <span>
                            <FormControlLabel sx={{
                                alignItems: "flex-start"
                            }} control={<Checkbox
                                value={answersIds.includes(item.id)}
                                onChange={() => {
                                    const newAnswers = answersIds.includes(item.id) ? answersIds.filter(el => el !== item.id) : [...answersIds, item.id];
                                    setAnswer({
                                        answersIds: newAnswers,
                                        isFree: item.isFree && isFree
                                            ? false
                                            : !item.isFree && isFree
                                                ? isFree
                                                : item.isFree
                                    });
                                }}
                                disabled={answersIds.includes(item.id) ? false : disableMoreAnswers}
                                //    label={name}
                                size='medium'
                                className={cls}
                            />}
                                label={name} />
                            {item.description && <Box
                                className={cnAnswerItem('Description')}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginLeft: "32px"
                                }}>
                                {<FormHelperText> <MDRender source={item.description || ''} textCut={false} type={'inline'} /></FormHelperText>}
                            </Box>}
                            {/* <Checkbox
                                value={answersIds.includes(item.id)}
                                onChange={() => {
                                    const newAnswers = answersIds.includes(item.id) ? answersIds.filter(el => el !== item.id) : [...answersIds, item.id];
                                    setAnswer({
                                        answersIds: newAnswers,
                                        isFree: item.isFree && isFree
                                            ? false
                                            : !item.isFree && isFree
                                                ? isFree
                                                : item.isFree
                                    });
                                }}
                                disable={answersIds.includes(item.id) ? false : disableMoreAnswers}
                                label={name}
                                size='medium'
                                className={cls}
                                color={"action"}
        

                            /> */}
                            {(item.isFree && answersIds.includes(item.id)) && < TextField
                                multiline
                                autoComplete="off"
                                suggesterValue={false}
                                label={Translate.t({ i18nKey: 'pryaniky.administration.surveys.item.other' })}

                                style={{ width: "100%" }}
                                variant='outlined'
                                value={text}
                                onChange={(e) => {
                                    setAnswer({
                                        text: e.currentTarget.value
                                    });
                                }}

                            />}

                        </span>

                    </Tooltip>
                </Box>
            }

            {
                qtype === 'Text' && <>
                    <Input
                        type={'text'}
                        value={text}
                        onChange={(value) => {
                            setAnswer({
                                answersIds: [item.id],
                                text: value
                            });
                        }}
                        variant="outlined"
                        multiline
                        maxRows={4}
                        placeholder={Translate.t({ i18nKey: 'pryaniky.survey.answer.type.text' })}
                    />
                    {allowAttachments &&
                        <>
                            <Divider orientation='horizontal' sx={{ marginTop: '8px' }} />
                            <FilesUploader
                                files={files}
                                multiple={false}
                                selectButtonProps={{
                                    children: i18n.t("pryaniky.survey.answer.item.selectFile"),
                                    variant: 'outlined'
                                }}
                                disabled={uploadDisabled}
                                className={cnAnswerItem('File')}
                                // inputAccept="image/jpeg,image/png"
                                onFileChange={onFileChange}
                                removeFile={removeFile}
                                boxSx={{
                                    marginTop: files.length ? '8px' : undefined
                                }}
                            />
                        </>
                    }
                </>
            }
            {
                qtype === 'Time' &&
                <>
                    <DatePicker
                        onChange={(val: string | null, date: Date | null) => {
                            val && setAnswer({
                                answersIds: [item.id],
                                text: val
                            });
                        }}
                        type={'time'}
                        InputProps={{ autoComplete: "off" }}
                        value={answerDateTime}
                        label={i18n.t("pryaniky.administration.surveys.item.time")}
                    />
                </>
            }
            {
                qtype === 'Date' &&
                <>
                    <DatePicker
                        onChange={(val: string | null, date: Date | null) => {
                            // добавляю три часа к дате, дабы результат в статистике приходил корректно а не скидывал на прошлый день выбранную дату.COMMON-9578
                            val && setAnswer({
                                answersIds: [item.id],
                                text: new Date(Date.parse(val) + 3 * 60 * 60 * 1000).toISOString()
                            });
                        }}
                        InputProps={{ autoComplete: "off" }}
                        type={'date'}
                        value={answerDateTime}
                        label={i18n.t("pryaniky.administration.surveys.item.date")}
                    />
                </>
            }
            {
                qtype === 'Numeric' &&
                <Box sx={{
                    "input::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0
                    },
                    "input::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0
                    }
                }}>
                    <NumberTextField
                        autoComplete="off"
                        inputProps={{
                            min: 0
                        }}
                        fullWidth
                        label={Translate.t({ i18nKey: 'pryaniky.administration.surveys.item.numeric' })}
                        helperText={i18n.t('pryaniky.administration.surveys.item.numeric.help')}
                        variant='outlined'
                        value={numericAnswer}
                        onChange={(e) => {
                            setAnswer({
                                answersIds: [item.id],
                                numericAnswer: e.target.value
                            });
                        }}
                    />
                </Box>
            }
            {
                qtype === "Slider" && sliderSymbols === "" &&
                <Box className={cnAnswerItem('Slider')}>
                    <SliderCustom
                        min={item.numericMin}
                        max={item.numericMax}
                        defaultValue={item.numericMin}
                        value={numericAnswer}
                        size={"medium"}
                        marks={marks}
                        onChange={(event, numericAnswer) => {
                            if (!Array.isArray(numericAnswer)) {
                                setAnswer({
                                    answersIds: [item.id],
                                    numericAnswer
                                });
                            }
                        }}
                    />
                </Box>
            }
            {
                qtype === "Slider" && sliderSymbols === "stars" &&
                <Box className={cnAnswerItem('Slider')}>
                    <Rating name="half-rating"
                        sx={{ padding: "13px 0px 13px 0" }}
                        defaultValue={0}
                        size="large"
                        precision={1}
                        max={item.numericMax}
                        icon={<Star fontSize="inherit" sx={{ fontSize: "48px" }} />}
                        value={!numericAnswer ? 0 : numericAnswer}
                        emptyIcon={<Star fontSize="inherit" sx={{ fontSize: "48px" }} />}
                        onChange={(event, numericAnswer) => {
                            if (numericAnswer) {
                                setAnswer({
                                    answersIds: [item.id],
                                    numericAnswer
                                });
                            }
                        }} />
                </Box>
            }

        </Box >
    )
}

export const AnswerItem = connect(
    mapStateToProps,
    mapDispatchToProps)
    (AnswerItemPresenter)