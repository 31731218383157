import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from '../../../../src/redux/connector';
import { RouteComponentProps } from 'react-router';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface INotificationsSettingsGroupProps extends IClassNameProps, RouteComponentProps, INotificationsSettingsGroupStateProps {
  tag?: 'div';
  userId?: string;
  networks?: any
}

export interface INotificationsSettingsGroupState {
  text?: string;
  settings?: any;
  section?: string;
  currentElement?: any;
  groups?: any;
  type: string;
}

// export interface INotificationsSettingsGroupStateProps {
//   store?: any
// }

export const mapStateToProps = (state: any) => {
  
  return {
    baseNetwork: getAuthUser(state).baseData.baseNetwork.id
  }
}

export type INotificationsSettingsGroupStateProps = ReturnType<typeof mapStateToProps>

export const cnNotificationsSettingsGroup = cn('NotificationsSettingsGroup');