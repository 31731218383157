import { IListViewIdeasPropsType, IListViewIdeasStateType, mapStateToProps } from './ListViewIdeas.index'
import * as React from 'react';
import { PostContentRender } from '../../../PostContentRender/PostContentRender';
// import { AdditionalFieldsRender } from './AdditionalFieldsRender/AdditionalFieldsRender';
import { ListViewIdeasFieldsRender } from '../AdditionalContentRender/ListViewIdeasFieldsRender/ListViewIdeasFieldsRender';
import { cloneObject } from 'utils/src/utils';
import { connect } from 'react-redux';
import { Button } from 'uielements/src';
import i18n from 'localizations/i18n';

export class ListViewIdeasPresenter extends React.Component<IListViewIdeasPropsType, IListViewIdeasStateType>  {

    private stageContentRef = React.createRef<HTMLElement>();

    constructor(props: IListViewIdeasPropsType) {
        super(props)

        this.state = {
            data: cloneObject(props.data)
        }
    }

  

    public render() {
        const { data } = this.state;
        const { text, idea, thanksCount, id } = data;
        const { additionalFieldsValues,  } = idea;
        return (
            <React.Fragment>
               
                {this.props.presentRender(
                    <React.Fragment>
                        <div  style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}> <span style={{ color: "#999", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"  }}>Описание:</span>{" " +text}</div>
                        {/* <PostContentRender data={text} /> */}

                        <ListViewIdeasFieldsRender data={additionalFieldsValues} />
                        <Button style={{ marginTop: "20px" }} type="rlink" target="_blank" noBackground padding="md" href={"/news/" + id}>{i18n.t('pryaniky.ideas.more.btn')}</Button>


                    </React.Fragment>)}

            </React.Fragment>
        )
    }



}

export const ListViewIdeas = connect(
    mapStateToProps
)(ListViewIdeasPresenter);