import React, { FC, useMemo } from 'react';
import { TabButtonProps } from './TabButton.index';
import { Icon } from 'uielements/src';
import { cnTabs } from '../Tabs.index';
import { StyledImg, StyledButton, StyledDiv } from './TabButton.styled';

export const TabButton: FC<TabButtonProps> = ({
    disableNavigate,
    icon,
    iconFile,
    children,
    ...props
}) => {

    const iconRender = useMemo(() => {
        if(iconFile) {
            return (
                <StyledImg className={cnTabs('Button-Icon')} src={iconFile.originalUrl} alt='tabImg' />
            );
        }
        if(icon) {
            return (
                <Icon icon={icon} />
            );
        }
        return null;
    }, [ icon, iconFile ]);

    if(disableNavigate) return (
        <StyledDiv {...props as any}>
            {iconRender}
            {children}
        </StyledDiv>
    );

    return (
        <StyledButton {...props}>
            {iconRender}
            {children}
        </StyledButton>
    );
};