import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps, } from 'react-router';
import { ManifestType } from '../types'
import { routerActions } from 'connected-react-router';
const { push } = routerActions

///////////
export declare namespace StatisticsFull {
    export namespace Tile {
        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            value: ManifestType
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////

type IOwnProps = StatisticsFull.Tile.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({

})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    push
}, dispatch);

////////////

export const cnClassName = cn('LMSTOStatFullTile');