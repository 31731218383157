/**
 * @packageDocumentation
 * @module AboutWidget
 */
import React, { FC, useState, useRef } from 'react'
import { IAboutEditProps, mapDispatchToProps, mapStateToProps } from './Edit.index'
import { PryanikyEditor } from 'blocks/PryanikyEditor/_PryanikyEditor'
import { connect } from 'react-redux';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { mdToRaw, rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { ActionButtons } from 'blocks/ActionButtons/ActionButtons';
import './Edit.scss'
import { NodeBulder } from 'uielements/src/CommonmarkRender'
import { AttachmentUploader } from 'uielements/src/AttachmentUploader/AttachmentUploader'
import Uploader from './Uploader'
export const AboutEdit_: FC<IAboutEditProps> = ({ user, updateUser, uid, endEdit = () => { } }) => {
    const [value, setValue] = useState<any>(() => {
        const nb = new NodeBulder(user.profileData.about || '')
        return EditorState.createWithContent(convertFromRaw(nb.getDraftRaw()))
    });
    const [profileData, setprofileData] = useState<any>(user.profileData);
    
    const save = () => {
        const text = draftToMarkdown(convertToRaw(value.getCurrentContent()), rawToMd);
        updateUser(uid, { ...user, profileData: { ...user.profileData, ...profileData, about: text } })
        endEdit();
    }
    const cancle = () => {
        endEdit();
    }
    const onChangeFiles = (fieldId: string, property: string) => (value: any) => {
        const n = {
            userHeadFields: {
                ...profileData.userHeadFields,
                entities: {
                    ...profileData.userHeadFields.entities,
                    fields: 'fields' in profileData.userHeadFields.entities ? {
                        ...profileData.userHeadFields.entities.fields,
                        [fieldId]: fieldId in profileData.userHeadFields.entities.fields ? {
                            ...profileData.userHeadFields.entities.fields[fieldId],    
                            [property]: value
                        } : undefined
                    } : undefined
                }
            },
            userInfoFields: {
                ...user.profileData.userInfoFields,
                entities: {
                    ...profileData.userInfoFields.entities,
                    fields: 'fields' in user.profileData.userInfoFields.entities ? {
                        ...profileData.userInfoFields.entities.fields,
                        [fieldId]: fieldId in profileData.userInfoFields.entities.fields ? {
                            ...profileData.userInfoFields.entities.fields[fieldId],
                            [property]: value
                        } : undefined
                    } : undefined
                }
            }
        }
        setprofileData({ ...profileData, ...n})
    }
    return <div className={'AboutEdit'}>
        <PryanikyEditor
            value={value}
            setActionBtn={(v: any) => { }}
            onChange={setValue}
        />
        {profileData.userHeadFields.result.map((fieldId: any) => {
            const { field, value, tags, files } = profileData.userHeadFields.entities.fields[fieldId];
            if (field.fieldType === 'Files') {
                return <Uploader name={field.name} files={files} onChange={onChangeFiles(fieldId, 'files')} />
            }
        })}

        <ActionButtons save={save} cancle={cancle} />
    </div>
}

export const AboutEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(AboutEdit_)