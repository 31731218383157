import React, { FC, useState, useEffect } from 'react'
import { IItemProps, cnItem, mapDispatchToProps, mapStateToProps } from './Item.index'
import './Item.scss'
import { connect } from 'react-redux';

import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import TestResult from './Test'
import TaskResult from './Task'
import ScormResult from './Scorm'
import Lesson from '../../../../components/Lesson'

export const ItemPresenter: FC<IItemProps> = ({ className, unit, unitContent, sid, cid, userId }) => {
    let unitView = <div></div>
    unitView = unit.unitType === 'test' ? <TestResult key={unit.id + cid + sid} cid={cid} sid={sid} unit={unit} unitContent={unitContent} userId={userId} /> : unitView
    unitView = unit.unitType === 'task' ? <TaskResult key={unit.id + cid + sid} cid={cid} sid={sid} unit={unit} unitContent={unitContent} userId={userId} /> : unitView
    unitView = unit.unitType === 'scorm' ? <ScormResult key={unit.id + cid + sid} cid={cid} sid={sid} unit={unit} unitContent={unitContent} userId={userId} /> : unitView
    unitView = unit.unitType === 'wiki' ? <Lesson rawState={unitContent.wiki.data} fullscreen={false} name={unitContent.name} /> : unitView
    return <div className={cnItem({}, [className])}>
        {unitView}
    </div>
}

export const Loader: FC<any> = (props) => {
    const { unit, unitContent, sid, loadFullUnit } = props
    // if (!unit.results) return null;
    if (unit.results && unit.results.length === 0) return <h3>{tT('student_results.not_completed.1')}</h3>;
    if (/*(unit.results && unit.results.length !== 0) && */!unitContent) {
        loadFullUnit(unit.unitId)
        return <div>{tT('student_results.load.1')}</div>
    }
    return <ItemPresenter {...props} />
}
export const Item = connect(
    mapStateToProps,
    mapDispatchToProps
)(Loader);