
import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { SettingsWikiActueleArticlesWidgetWidget} from '../WikiActueleArticlesWidget.index';
import { IBodyProps } from 'utils/src/DialogCreator'
export const cnSettingsWikiActueleArticlesDialog = cn('SettingsWikiActueleArticlesDialog');

export namespace NSettingsWikiActueleArticlesDialog {

    export interface Props extends IClassNameProps, IBodyProps<any> {
        // settings?: SettingsWikiActueleArticlesWidgetWidget.Settings 
        onChange: (value: { [key: string]: any }) => void;
        data:SettingsWikiActueleArticlesWidgetWidget.Settings 
    }
}
