import { createDefaultPostNews } from '../../baseNews';
import { NPolls } from './Polls.interface';

const baseExpires = new Date().toISOString();

export const baseAnswer: NPolls.Create['poll']['answers'][number] = {
    answeredByMe: false,
    count: 0,
    id: '',
    participants: [],
    position: 0,
    text: ''
}

export const basePolls: NPolls.Create = ({
    ...createDefaultPostNews(),
    newstype: 7,
    componentRenderName: 'polls',
    expires: baseExpires,
    poll: {
        answers: [],
        answerVariants: 100,
        freeAnswer: false,
        disableRevote: false,
        showResultsBeforeVote: false,
        showParticipants: true,
        expires: baseExpires,
        maxCount: 0,
        showFullStat: false,
        votersCount: 0
    }
})