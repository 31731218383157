import moment from "moment";
import { useEffect, useState } from "react"
import { checkResponseStatus } from "utils/src";
import { getCalendarEventsShortRequest } from "utils/src/requests/requests.calendar";

let calRequest: ReturnType<typeof getCalendarEventsShortRequest> | undefined;


/**
 * 
 * @returns офсет часового пояса в формате -03:00|12:00|02:40|-11:00 и так далее
 */
const getFormatedOffset = () => {
    const d = new Date()
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)

    const o = d.getTimezoneOffset()
    d.setMinutes(o * -1)

    const h = [0, 0].map((v, i) => d.getHours().toString()[i] || 0).reverse().join('')
    const m = [0, 0].map((v, i) => d.getMinutes().toString()[i] || 0).reverse().join('')
    const s = o < 0 ? '' : '-'

    const r = s + h + ':' + m
    return r
}
export const useClendarEvents = (date: Date, gid?: number, cIds?: string[]) => {
    const [events, setEvents] = useState<Awaited<ReturnType<typeof getCalendarEventsShortRequest>['r']>['data']['events']>({});
    const [dates, setDates] = useState({
        start: moment(date).startOf('month').toISOString(),
        end: moment(date).endOf('month').toISOString()
    });

    useEffect(() => {
        async function fetchData() {
            if (calRequest) calRequest.ac.abort();
            calRequest = getCalendarEventsShortRequest({
                start: dates.start, end: dates.end, gid: (cIds && cIds?.length > 0) ? undefined : gid,
                cIds,
                timeOffset: getFormatedOffset()
            });
            const response = await calRequest.r;
            if (checkResponseStatus(response)) {
                setEvents(response.data.events);
            } else {
                throw new Error(`Get calendar short events response - error_code = ${response.error_code}`);
            }
        }
        fetchData()
            .catch(console.error);;
    }, [dates]);

    return {
        events,
        setDates
    }
}