import { connect } from 'react-redux';
import React, { useEffect } from 'react';
// import i18n from 'localizations/i18n';
import { Masonry, Button } from 'uielements/src';
import { Item } from './Item/Item';
import { mapStateToProps, ICoursesStateProps, cnCourses, cnClassNameNoData, ICoursesProps, mapDispatchToProps } from './CourseMyNew.index';
import './CourseMyNew.scss';
import { tS } from 'LMSModule/utils/i18n.adaptor'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import noDataImage from 'assets/svg/NoData.svg'
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { useWidgetContext } from 'Widgets_v2/context';
import { useDidUpdateEffect } from 'utils/src/hooks';
import CircularProgress from '@mui/material/CircularProgress'
export const CourseMyNewPresenter: React.FC<ICoursesProps & any> = ({
    className,
    loadCourses,
    courses,
    cIds,
    isLoading,
    location,
    sendLikeCourse,
    isFinished,
    isEnabled = false,
    params = {},
    settings,
    ...data
}) => {
    const prms = { count: 3, isMandatory: true, Shuffle: true, status: 'NotStarted', clear: true, ...params }
    useEffect(() => {
        isEnabled && loadCourses(prms, true)
    }, []);

    // useEffect(() => {
    //     return () => isEnabled && loadCourses(prms)
    // }, []);
    const {
        sendWidgetSignal
    } = useWidgetContext();

    const [currentIndex, setCurrentIndex] = React.useState(0);
    const courseIds = Object.keys(courses || {})
    const currentCourse = courses[courseIds[currentIndex]];
    const numOfCourse = cIds.length;

    useDidUpdateEffect(() => {
        sendWidgetSignal('dataUpdate');
    }, [currentIndex, isLoading, cIds.length]);

    const decrementIndex = () => currentIndex > 0 && setCurrentIndex(currentIndex - 1);
    const incrementIndex = () => currentIndex < numOfCourse - 1 && setCurrentIndex(currentIndex + 1);

    const additionalProps: { [s: string]: any } = {
        hide: false
    }

    if (settings?.hideIfNoData && cIds.length === 0 || cIds[0] === 'skelet') return null;
    if (!settings?.hideIfNoData && (cIds.length === 0 || cIds[0] === 'skelet')) return <div   {...data} className={cnClassNameNoData({}, [className])}>
        <div className={cnClassNameNoData('Header-Title')} children={tS('courses.2')} />
        <div className={cnClassNameNoData('Header-noData')}> <Avatar precentage={100} shape={'rectangle'}
            imgUrl={noDataImage}
        />
        </div>
        <span className={cnClassNameNoData('Header-Title-Text')}> {tS('noData.text')} </span>
    </div>;
    return (
        isLoading ? <div className={cnCourses({}, [className])}>
            <div className={cnCourses('Header', [className])}>
                <h4 className={cnCourses('Header-Title')}>{tS('courses.1')}</h4>
            </div>
            <div className={cnCourses('Loading-Box')}>
                <CircularProgress />
            </div>
        </div> :
            <div className={cnCourses({}, [className])}>
                <div className={cnCourses('Header', [className])}>
                    <h4 className={cnCourses('Header-Title')}>{tS('courses.1')}</h4>
                    <div className={cnCourses('Header-Pagination')}>
                        <button
                            className={cnCourses('Header-PaginationControl')}
                            disabled={currentIndex === 0}
                            onClick={decrementIndex}
                        >
                            {/* <Icon icon="chevron-left" /> */}
                            <KeyboardArrowLeftIcon color="primary" />

                        </button>
                        {currentIndex + 1}
                        <span>
                            {`/${numOfCourse}`}
                        </span>
                        <button
                            className={cnCourses('Header-PaginationControl')}
                            onClick={incrementIndex}
                            disabled={currentIndex === numOfCourse - 1}
                        >
                            {/* <Icon icon="chevron-right" /> */}
                            <KeyboardArrowRightIcon color="primary" />

                        </button>
                    </div>
                </div>
                <Item
                    className={cnCourses('Item')} mini={true} course={currentCourse} key={currentIndex} sendLikeCourse={sendLikeCourse} />
                {/* {cIds.slice(0, prms.count).map((cId: any) => (
                <Item
                    className={cnCourses('Item')} mini={true} course={courses[cId]} key={cId} sendLikeCourse={sendLikeCourse} />
            ))}
            {(!isFinished || cIds.length > prms.count) && <Button
                main
                type={'rlink'}
                href={'/lms?isMandatory=true'}
                className={cnCourses('MoreBtn')}>{tS('viewAll.1')}</Button>} */}
            </div>
    );
};

export const CourseMyNew = connect(
    mapStateToProps,
    mapDispatchToProps
)(CourseMyNewPresenter);
