import React from 'react';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { cnWidget, IWidgetProps } from '../Widget.index';
import { widgets } from "i.widgets";
// import { Presents } from '../../Presents/Presents';
import { Presents } from '../../PresentsNew/Presents';

const type = 'tagsCloud';

export default class WidgetTypeTagsCloud extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    
    return (
      <Presents {...this.props} id={this.props.widget.id} context={this.props.wcontext} className={cnWidget({ type, subtype })} tag={widgets.components.common} edit={this.props.edit} />
    );
  }
}