import React, { FC, useState } from 'react';
import './AuthorsList.scss';
import { IAuthorsListProps, cnAuthorsList } from './AuthorsList.index'
import { AuthorItem } from './Item/Item'
import { More } from './More/More'

export const AuthorsList: FC<IAuthorsListProps> = ({ className, authors, limit = -1, more = false, size, fixedToolTip }) => {
    const [show, setShow] = useState(false)

    let shortList = limit !== -1 ? authors.slice(0, limit) : authors
    if (show) shortList = authors
    const showButton = authors.length > limit
    return (
        <div className={cnAuthorsList({}, [className])}>
            <div className={cnAuthorsList('Wrapper')}>
                {shortList.map((author: any) => <AuthorItem
                    size={size}
                    key={author.id}
                    fixedToolTip={fixedToolTip}
                    className={cnAuthorsList('Author')}
                    author={author} />)}
                {(more && showButton) && <More className={cnAuthorsList('More')} onClick={() => setShow(!show)} />}
            </div>
        </div>
    );
};
