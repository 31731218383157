import { NetworkSelector } from 'blocks/NetworkSelector/NetworkSelector';
import { VirtUserSelector } from 'blocks/VirtUserSelector/VirtUserSelector';
import { Translate } from 'localization';
import {
    ConnectWithoutContact,
    ExitToApp,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Settings,
    TagFaces,
} from 'muicomponents/src/Icons';
import { ListItemIcon } from 'muicomponents/src/ListItem/ListItem';
import { Menu } from 'muicomponents/src/Menu';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IStateType } from 'redux/store';
import { mainUrls } from 'utils/src';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { loadVirtUsers } from 'utils/src/CommonRedux/users/actions';
import { AvatarButton, AvatarDropdownIconMui, AvatarImage, AvatarImageBox } from '../Header.styled';
import { IUserMenuProps } from './UserMenu.index';
import { MenuItem } from './UserMenu.styled';
import { UserNestedMenu } from './UserNestedMenu/UserNestedMenu';

// const userStatus = ['В сети', 'Не беспокоить', 'Отсутствую'];

const useVirtUsers = () => {
    const dispatch = useDispatch();
  
    useLayoutEffect(() => {
      dispatch(loadVirtUsers());
    }, [])
  
    const virtUsers = useSelector((state: IStateType) => state.users.virtUsers);
  
    return virtUsers;
  };

export const UserMenu: React.FC<IUserMenuProps> = ({ user, nameNetwork }) => {
    const networks = useSelector((state) => getAuthUser(state).baseData.networks);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // const [activeStatus, setActiveStatus] = useState<number>(0);
    const history = useHistory();
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const virtUsers = useVirtUsers();

    return (
        <>
            <AvatarButton onClick={handleClick}>
                <AvatarImageBox>
                    <AvatarImage src={user.baseData.userPhotoUrl} />
                    {/* Будущий функционал с выбором статуса профиля */}
                    {/* <CircleIndicator sx={{bacgroundColor: '#4CAF50'}} /> */}
                </AvatarImageBox>
                <AvatarDropdownIconMui as={open ? KeyboardArrowUp : KeyboardArrowDown} />
            </AvatarButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    sx: {
                        width: 262,
                        top: '66px !important',
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        history.push(`/user/${user.extData.userId}`);
                    }}
                >
                    <ListItemIcon>
                        <Settings />
                    </ListItemIcon>
                    <Translate i18nKey={'profile.my'} />
                </MenuItem>

                {virtUsers.length > 1 && (
                <UserNestedMenu
                    title={<Translate i18nKey={'pryaniky.header.menu.item.changeUser'} />}
                    text={user.baseData.displayName}
                    icon={<TagFaces />}
                >
                    <VirtUserSelector />
                </UserNestedMenu>
                )}

                {networks.length > 1 && (
                    <UserNestedMenu
                        title={<Translate i18nKey={'pryaniky.header.menu.item.changeNetwok'} />}
                        text={nameNetwork}
                        icon={<ConnectWithoutContact />}
                    >
                        <NetworkSelector />
                    </UserNestedMenu>
                )}

                {/* Будущий функционал с выбором статуса профиля */}
                {/* {
          userStatus.map((status, index) => 
            <UserStatusItem 
              title={status} 
              index={index} 
              key={index} 
              setActive={setActiveStatus} 
              active={activeStatus} 
            />)
        } */}
                <MenuItem onClick={() => history.push(mainUrls.logout)}>
                    {/* <MenuItem onClick={() => history.push('/logout')}> */}
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    <Translate i18nKey={'pryaniky.header.menu.item.logout'} />
                </MenuItem>
            </Menu>
        </>
    );
};
