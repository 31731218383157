import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    loadTracksList,
    createTrack,
    selectTrack,
    removeTrack
} from '../../redux/actions/TracksManage'
import {
    ILearningTracks
} from '../../redux/reducers/TracksManage'
import {
    getTracksObject,
    // getTracksSelected
} from '../../redux/sagas/TracksManage/selectors'
import { ILearningTrack } from 'utils/src/requests/models/api.learningTracks'
///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {

}

export interface IStateProps extends ILearningTracks {

}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////


export const mapStateToProps = (state: any, props: IOwnProps) => ({
    ...getTracksObject(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadTracksList,
    createTrack,
    selectTrack,
    removeTrack
}, dispatch);

////////////

export const cnClassName = cn('LMSTOTracks');