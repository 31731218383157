import { ICalendar, ICalendarEvents } from "./interfaces"
import {
  CALENDAR_SET_EVENTS,
  CALENDAR_SET_LOADING,
  CALENDAR_SET_CAL_FILTERS,
  CALENDAR_SET_CURRENT_DATE,
  CALENDAR_SET_CURRENT_VIEW,
  CALENDAR_UPDATE_EVENT,
  CALENDAR_SET_WORKFLOW_FILTERS,
  CALENDAR_CLEAR_WORKFLOW_FILTERS
} from "./actions"
import { ICalendarActions } from './action.interfaces'

const baseState: ICalendar = {
  events: {},
  dates: {},
  data: [],
  calendarEvents: [],
  currentDate: new Date().toISOString(),
  currentView: 'month',
  calendars: [],
  calendarsIds: [],
  workflow: {
    statuses: [],
    tags: [],
  },
  loading: false,
}

export const calendarResucer = (state = baseState, action: ICalendarActions): ICalendar => {
  switch (action.type) {

    case CALENDAR_SET_EVENTS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case CALENDAR_SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }

    case CALENDAR_SET_CAL_FILTERS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case CALENDAR_SET_CURRENT_DATE: {
      return {
        ...state,
        currentDate: action.payload
      }
    }

    case CALENDAR_SET_CURRENT_VIEW: {
      return {
        ...state,
        currentView: action.payload
      }
    }

    case CALENDAR_UPDATE_EVENT: {
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.id]: {
            ...state.events[action.payload.id],
            ...action.payload
          }
        }
      }
    }
      
    case CALENDAR_SET_WORKFLOW_FILTERS: {
      return {
        ...state,
        workflow: action.payload
      }
    }
      
    case CALENDAR_CLEAR_WORKFLOW_FILTERS: {
      return {
        ...state,
        workflow: baseState.workflow
      }
    }

    default:
      return state
  }
}
