import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import { removeFiles } from '../../../redux/actions/TracksManage'
import {
    ILearningTrack,
    ILearningFile
} from 'utils/src/requests/models/api.learningTracks'
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace LerningTrack {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace ListItem {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            value: ILearningFile
            disableSort?: boolean
        }

        export interface IStateProps {

        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = LerningTrack.ListItem.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({

})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    removeFiles
}, dispatch);

////////////

export const cnClassName = cn('LTLerningTrackListItem');