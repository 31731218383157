import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IDialog } from '../DialogWrapper.index';
import { bindActionCreators } from 'redux';
// import { IDispatchProps } from '../../../redux/connector';
import { IBodyProps } from 'utils/src/DialogCreator';

export interface ICommentsProps extends IClassNameProps, IBodyProps  {
  tag?: 'div';
}

export interface ICommentsState {}

export interface ICommentsStateProps {}

// export type ICommentsmapDispatchProps = ReturnType<typeof mapDispatchToProps>;
// export type ICommentsmapStateProps = ReturnType<typeof mapStateToProps>;
// export const mapStateToProps = (state: any, props: any) => ({

// })

// export const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);
// export const mapStateToProps = (state: any, props: any) => ({
//   currentFolder: state.files.moveDialog.folders.find((f: any) => f.id === state.files.moveDialog.currentFolder),
//   selectedCount: state.files.groups[props.gid] ? state.files.groups[props.gid].selected.length : 0
// })

// export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
//   moveFile
// }, dispatch);

export const cnMoveFiles = cn('MoveFiles');