import React, { Component } from 'react';
import './styles.css';
import { StyleButton } from '../StyleButton/StyleButton'
import uuid from 'uuid';
import * as utils from 'utils/src/utils';
import videoUtils from '../VideoPlugin/video/utils'
import { DropdownSelect } from '../DropdownSelect/DropdownSelect'
export interface IAddButtonProps {
  editorState: any;
  onChange: any;
  modifier: any;
}

export default class VideoAdd extends Component<IAddButtonProps> {
  // Start the popover closed
  state = {
    url: '',
    open: false,
    load: false,
    linkError: false
  };

  addVideo = (close: () => void) => () => {
    this.setState({ linkError: false })
    if (videoUtils.isYoutube(this.state.url)) {
      const youtube = videoUtils.getYoutubeSrc(this.state.url)
      const previewUrl = `https://www.youtube.com/embed/${youtube.srcID}?showinfo=0`
      this.insertVideo(this.state.url, false, previewUrl, youtube.srcID)
      close()
    } else if (videoUtils.isVimeo(this.state.url)) {
      const vimeo = videoUtils.getVimeoSrc(this.state.url)
      fetch(`https://vimeo.com/api/oembed.json?url=${this.state.url}`)
        .then(response => response.json())
        .then((value: any) => {
          const thumbnailId = videoUtils.getVimeoThumbnailId(value.thumbnail_url)
          const previewUrl = `https://i.vimeocdn.com/video/${thumbnailId}_1280.png`
          this.insertVideo(this.state.url, false, previewUrl, vimeo.srcID)
          close()
        }).catch((e) => {
          this.insertVideo(this.state.url, false, undefined, vimeo.srcID)
        })
    }
    else this.setState({ linkError: true })
  }

  changeUrl = (evt: any) => {
    this.setState({ url: evt.target.value });
  };

  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  insertVideo = (src: string, isPryaniky: boolean = false, previewUrl: string = '', id?: string, name?: string) => {
    const { editorState, onChange, modifier } = this.props;
    onChange(modifier(editorState, { src, isPryaniky, previewUrl, id, name }));
  }

  loadFinish = (response: { [s: string]: any }, file: any) => {
    this.setState({ load: false })
    for (const file of response.data) {
      this.insertVideo(file.originalUrl, true, file.previewUrl, file.id, file.name)
    }

  }

  loadStart = () => {
    this.setState({ load: true })
  }

  changeFile = (e: any) => {
    for (const file of e.target.files) {
      file.id = uuid.v1();
      utils.API.files.upload(file, { loadStart: this.loadStart, loadProgress: () => { }, loadFinish: this.loadFinish });
    }
  }

  render() {
    return (
      <div className={'addVideo'}>
        <DropdownSelect
          button={(open, toggle) => <StyleButton
            active={open}
            label={<i className="Icon icon-video-camera" />}
            // onToggle={this.toggle}
            onToggle={toggle}
          // style={''}
          />}
        >
          {(open, close) => !this.state.load ? <React.Fragment>
            <div className={'addImage-byUrl'}>
              <input
                type="text"
                placeholder="Сcылка на YouTube/Vimeo…"
                onChange={this.changeUrl}
                className={'addVideoInput'}
                value={this.state.url}
              />
              <button type="button" onClick={this.addVideo(close)} className={'addVideoConfirmButton'}>Добавить</button>
            </div>

            {this.state.linkError && <div className={'addVideo-Error'}>Неверная ссылка</div>}

            <div className={'addImage-byFile'}>
              <input type={'file'} onChange={this.changeFile} /*accept={'image/png, image/gif, image/jpeg, image/jpg, image/bmp, image/x-icon'}*/ />
            </div></React.Fragment> : 'load'}


        </DropdownSelect>
      </div>
    );
  }
}
