import React, { useState, memo } from 'react';
import { IEditorTypeNewsProps, mapStateToProps, mapTanksNewsActionsToProps } from './news.index';
import * as utils from 'utils/src/utils';
import { ThanksNews, defaultThanksNews } from '../../../Thanks.type'
import { Editor } from '../../Editor'
import { ThanksValidator, thanksValidate } from '../../../Thanks.validate'
import { Rule } from '../../../../Base.validate'
import { EditorState } from 'draft-js';
import { changeUsersModifier, changeReasonModifier } from '../../modifiers'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { nameCount } from 'utils/src/utils'
import {
    useChangeCurrency,
    useChangeReasons,
    useLoadReasons,
    useChangeThanksUsers
} from '../../../hooks'

const vb = new ThanksValidator();

export const _EditorTypeNews: React.FC<IEditorTypeNewsProps & any> = ({
    onChange: cbChange,
    data,
    maxThanksValue = 0,
    maxThanksArgumentsCount = 0,
    // thanksForAll = 0,
    types,
    // userData
    // newsServerValidate
}) => {
    let localData = data as ThanksNews;
    const {
        onChangeCurrency,
        disableDown,
        disableUp,
        thanksCount,
        available,
        fullCount,
        fullCurrency,
        maxPerUser,
        maxThanks
    } = useChangeCurrency(localData as any)
    
    if (data.newstype !== 4) {
        localData = {
            ...utils.cloneObject(defaultThanksNews), text: EditorState.createEmpty(), thanksForAll: fullCurrency,
        }
    }
    const [iniciators, setIniciator] = useState<string[]>(['thanksForAll'])

    

    // const [users, setUsers, maxAllowUsers, usersCount] = useChangeThanksUsers(data)


    const onChange = (data: ThanksNews, inciator: string) => {
        let membersCount = data.users.length
        membersCount += (data.groups || []).reduce((acc: number, cur: any) => {
            return acc + cur.membersCount
        }, 0)

        // const maxThanks = (membersCount * maxThanksValue) > thanksForAll ? thanksForAll : (membersCount * maxThanksValue);
        const validThank: Rule[] = [
            // {
            //     type: 'number',
            //     method: 'NUM_MIN',
            //     field: 'thanksCount',
            //     value: 1,
            //     message: 'Недостаточно вложений'
            // },
            {
                type: 'number',
                method: 'NUM_MAX',
                field: 'thanksCount',
                value: membersCount === 0 ? maxThanksValue : maxPerUser,//maxThanks,
                errorI18nKey: 'pryaniky.validate.news.thanks.thanksCount.max',
                // message: 'Вложено слишком много'
            },
            {
                type: 'array',
                method: 'ARR_MAX',
                field: 'rewardReasons',
                value: maxThanksArgumentsCount || 4,
                errorI18nKey: 'pryaniky.validate.news.thanks.rewardReasons.max',
                errorVariablesI18nKey: {
                    count: maxThanksArgumentsCount
                }
                // message: 'Слишком много причин для благодарности, максимум 4'
            },
            {
                type: 'array',
                method: 'ARR_MAX',
                field: 'users',
                value: (fullCurrency / data.thanksCount),//((data.users && data.users.length !== 0) ? data.users.length : 1),
                errorI18nKey: 'pryaniky.validate.news.thanks.users.max',
                // message: 'Выбранно слишком много пользователей'
            },
            {
                type: 'array',
                method: 'ARR_MIN',
                field: 'users',
                value: (data.groups?.length || 0) > 0 ? 0 : 1,
                errorI18nKey: 'pryaniky.validate.news.thanks.users.min',
                // message: 'Нужно выбрать кого поблагодарить'
            },
            {
                type: 'string',
                method: 'STR_MIN',
                field: 'text',
                value: types?.thanksRenderParams?.minTextLength || 5,
                errorI18nKey: 'pryaniky.validate.news.thanks.text.min',
                errorVariablesI18nKey: {
                    count: types?.thanksRenderParams?.minTextLength || 5,
                    variable: nameCount(types?.thanksRenderParams?.minTextLength || 5, 'pryaniky.validate.string.variable')
                }
            }
        ]
        data.thanksForAll = fullCurrency//thanksForAll
        data.usersCount = membersCount

        // vb.setUseField()
        // vb.addField(iniciators)
        vb.addData(data)
        vb.setRules([...thanksValidate
            .filter(v => !(v.field === 'rewardReasons' && v.method === 'ARR_MAX'))
            .filter(v => !(v.field === 'text' && v.method === 'STR_MIN')),
        ...validThank])
        cbChange({ ...data }, vb.validation().length === 0, vb.validation())

        // setIniciator(iniciators.includes(inciator) ? iniciators : [...iniciators, inciator])
    }

    const onChangeUsers = (values: any) => {
        const data = changeUsersModifier(values, localData);
        // newsServerValidate({ news: data });
        onChange(data, 'users');
    }
    const onChangeReason = (values: any) => {
        onChange(changeReasonModifier(values, localData), 'rewardReasons');
    }

    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData, 'text')
    }

    // const onChangeThanks = (val: number) => {
    //     localData.thanksCount = val
    //     onChange(localData, 'thanksCount')
    // }
    return <Editor
        disableDown={disableDown}
        disableUp={disableUp}
        thanksCount={thanksCount}
        available={available}
        fullCount={fullCount}
        fullCurrency={fullCurrency}
        maxPerUser={maxPerUser}
        maxThanks={maxThanks}

        // maxThanksValue={maxThanksValue}
        maxThanksArgumentsCount={maxThanksArgumentsCount}
        // thanksForAll={thanksForAll}
        maxUsers={Math.floor(fullCurrency / localData.thanksCount)}//((localData.users && localData.users.length !== 0) ? localData.users.length : 1)}
        validation={vb.validation()}
        text={localData.text}
        reasons={localData.rewardReasons.map((v: any) => ({ value: v.id, label: v.name }))}
        // thanksCount={localData.thanksCount}
        users={localData.users}
        groups={localData.groups}
        onChangeReason={onChangeReason}
        onChangeText={onChangeText}
        onChangeThanks={onChangeCurrency}//onChangeThanks}
        onChangeUsers={onChangeUsers} />
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch);
export const EditorTypeNews = connect<any, any>(
    mapStateToProps,
    mapTanksNewsActionsToProps
)(memo(_EditorTypeNews)) as any;