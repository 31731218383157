import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
// import { RouteComponentProps } from 'react-router';
import { IDispatchProps } from 'redux/connector';
// import { setContext } from 'redux/actions/Widgets';
// import { getNewsByIdRequest } from 'redux/actions/News';
import {
  loadNewsById
} from 'News/redux/actions'

import {
  getNewsById
} from 'News/redux/saga/selectors'

export interface INewsWidgetProps extends IClassNameProps, IDispatchProps, INewsWidgetStateProps, IActionsDispatch {
  tag: any;
  newsId: string;
}

export interface INewsWidgetState {
  text?: string;
}

export interface IActionsDispatch {
  // setContext: any;
  loadNewsById: typeof loadNewsById;
}

export const mapActionsToProps: IActionsDispatch = {
  // setContext,
  loadNewsById
};

export interface INewsWidgetStateProps {
  news?: any//{ [s: string]: any };
}

export const mapStateToProps = (state: any, props: any) => ({
  news: getNewsById(props.newsId)(state)//state.news.news
})

export const cnNewsWidget = cn('NewsWidget');