import { NValidate } from 'utils/src/validate_v2';
import { NBadges } from './Badges.interface';

export const BadgesValidateRules: NValidate.ValidateRulesObject<NBadges.Create> = {
    users: {
        notNull: {
            value: true,
            errorMessageKey: 'pryaniky.news.validate.usersSuggesterWithBlur.error'
        }
    },
    groups: {
        notNull: {
            value: true,
            errorMessageKey: 'pryaniky.news.validate.usersSuggesterWithBlur.error'
        }
    },
    badge: {
        notNull: {
            value: true
        }
    },
    text: {
        notNull: {
            value: true
        },
        min: {
            value: 0,
            // errorMessageKey: ''
        }
    },
};