import { IClassNameProps } from "@bem-react/core";
import { Ref } from "react";
import { WidgetLayoutHorizantalSettings } from "utils/src/BaseTypes/widgets.settings";
import { TColumnSimplified } from "Widgets_v2/types";

export namespace NRow {

    export interface Props extends IClassNameProps {
        widgetId: string;
        row: TColumnSimplified;
        addNextColumn: () => void;
        removeColumn: () => void;
        addWidgetHandler: (widget: {[key: string]: any}, positionIndex: number) => void;
        section: WidgetLayoutHorizantalSettings['sections'][keyof WidgetLayoutHorizantalSettings['sections']];
        sectionBaseHeight?: NonNullable<WidgetLayoutHorizantalSettings['sectionsBaseHeight']>[keyof WidgetLayoutHorizantalSettings['sectionsBaseHeight']];
        changeSection: (id: keyof WidgetLayoutHorizantalSettings['sections'], section: Props['section']) => void;
        changeSectionBaseHeight: (id: keyof NonNullable<WidgetLayoutHorizantalSettings['sectionsBaseHeight']>, newHeight: Props['sectionBaseHeight']) => void;
        onDraged: (items: string[]) => void;
        ref?: Ref<HTMLDivElement>;
        isLastSection: boolean;
    }
}