import { IIdeaFieldProps } from '../FieldProps';
import React from 'react';

export const OfferResponsibleForStatusUsers: React.FC<IIdeaFieldProps> = ({ field }) => {

    return (
        <div>
            OfferResponsibleForStatusUsers
        </div>
    );
}

