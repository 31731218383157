import React, { FC, useEffect } from 'react'
import {
    cnClassName,
    IPresenterProps,
    IState
} from './interfaces'
import './style.scss'
import { Avatar, Icon } from 'uielements/src'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip'

export const Presenter: FC<IPresenterProps> = ({
    className,
    unit,
    active,
    itemClick
}) => {
    const click = () => itemClick(unit)
    return <div className={cnClassName({ active }, [className, 'Selectize-Button', active ? 'Selectize-Button_active' : undefined])} onClick={click}>
        <Avatar imgUrl={unit.typeImage} className={cnClassName('TypeIcon')} shape={"square"} noBorderRadius size={40} />
        <div className={cnClassName('Content')}>
            <HelpTooltip help={unit.title} tag={'div'} className={cnClassName('Title')} showDelay={1000} >
                {unit.title}
            </HelpTooltip>
            <div className={cnClassName('UnitType')}>{[unit.unitType, unit.skipType]}</div>
        </div>
        <div className={cnClassName('Status')}>
            <Icon className={cnClassName('StatusIcon', { color: unit.iconColor })} icon={unit.icon} />
        </div>
    </div >
}

export default Presenter;
