import { useChangeCreator } from "News/creator/hooks";
import { newsCreatorItemNewsChange } from "News/creator/redux/actions.newsCreator";
import { getNewsCreateItemFields, getNewsCreateItemNewsFields } from "News/creator/redux/selectors.newsCreate";
import { NewsCreateReducer } from "News/creator/redux/types.newsCreator";
import { useDispatch, useSelector } from "react-redux";

export const useWorkflowsEdit = (id: keyof NewsCreateReducer) => {

    const dispatch = useDispatch();

    const data = useSelector(getNewsCreateItemNewsFields(['header', 'text', 'additionalFields'])(id));

    const creatorFields = useSelector(getNewsCreateItemFields(['errors', 'clearEditor'])(id));

    const changeCreator = useChangeCreator(id);

    const changeNews = (news: Partial<typeof data>) => {
        dispatch(newsCreatorItemNewsChange({ id, news }));
    };

    return {
        data,
        ...creatorFields,
        changeNews,
        changeCreator
    };
};