import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { INewsTimelineInfo } from '../../types/baseNews';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { getUserByIdOrAlias, getIdByAlias } from 'utils/src/CommonRedux/users/selectors'
///////////

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace TimelineInfo {

        export type IPresenterProps = IOwnProps & IState & IStateProps & IDispatchProps

        export interface IState { }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

        export interface IOwnProps extends IClassNameProps {
            timelineInfo: INewsTimelineInfo;
            userId: string;
        }
    }
}
export const cnClassName = cn('NewsTimelineInfo');

export const mapStateToProps = (state: any, { userId }: News.TimelineInfo.IOwnProps ) => ({
    // currencyNames: {
        currencyNameAccusativeSingular: state.store.appSettings.currencyNameAccusativeSingular,
        currencyNameGenitiveSingular: state.store.appSettings.currencyNameGenitiveSingular,
        currencyNameGenitivePlural: state.store.appSettings.currencyNameGenitivePlural,
    // } as { [s: string]: string },
    // userSex: state.users.users[userId].baseData.sex
    userSex: getUserByIdOrAlias(userId)(state)?.baseData?.sex,
})


export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({

}, dispatch);