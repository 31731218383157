import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IBodyProps } from '../modal'
import { BaseNews } from 'blocks/NewsTypes/BaseType'
import { bindActionCreators, Dispatch, Action } from 'redux';
import { IWikiItemActionProps } from 'blocks/WIKIList/ContextMenu/ContextMenu.index'
import {
    addFile
} from '../../redux/actions/TracksManage'

///////////

export type IAddWikiToTrackPresenterProps = IAddWikiToTrackOwnProps & IAddWikiToTrackStateProps & IAddWikiToTrackDispatchProps

export interface IAddWikiToTrackState { }

export interface IAddWikiToTrackOwnProps extends IClassNameProps, IBodyProps<IAddWikiToTrackResult> {
    data: IWikiItemActionProps
}

export interface IAddWikiToTrackResult {
    wid: string
    tid: string
}

export interface IAddWikiToTrackStateProps {
}

export type IAddWikiToTrackDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////


export const mapStateToProps = (state: any, props: IAddWikiToTrackOwnProps) => ({

})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IAddWikiToTrackOwnProps) => bindActionCreators({
    // addFile
}, dispatch);

export const cnClassName = cn('LTAddWikiToTrack');