import { QuestStepNewsCreate } from './QuestStep.type'
import { Rule, BaseValidator, validateArray, validateString, validateNumber } from '../Base.validate'
export const achievementValidate: Rule[] = [
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'text',
        value: 1,
        message: 'small text'
    },
    /*{
        type: 'string',
        method: 'STR_MAX',
        field: 'text',
        value: 250,
        message: 'big text'
    },*/
    {
        type: 'number',
        method: 'NUM_MIN',
        field: 'achievement.id',
        value: 0,
        message: 'no select achievement'
    },
]


export class QuestStepValidator extends BaseValidator<any> {
    protected otherValidate = (data: any, rule: Rule) => {
        switch (rule.field) {
            // case 'text':
            //     return validateString(data.text, rule);
            // case 'achievement.id':
            //     return validateNumber(data.achievement.id, rule);
        }
        return true;
    }
}
