import { IDistributeRenderProps } from './DistributeRender.index';
import * as React from 'react'
import './DistributeRender.scss'
import { getInvestors, distributeIdea, searchInvestors } from '../IdeasSides'
import { ActionButtons } from '../../../ActionButtons/ActionButtons'
import { RewardUsers } from '../Ideas.index';
import { Button } from 'uielements/src/Button/Button';
// import { UserSelector } from '../UserSelector/UserSelector';
import { DistributeUserList } from '../DistributeUserList/DistributeUserList';
import { StageComment } from '../StageComment/StageComment';
import { ButtonBox } from 'uielements/src';
// import { getInvestors, searchInvestors } from '../IdeasSides'
import { FieldsHeader } from '../FieldsHeader/FieldsHeader';
import i18n from 'localizations/i18n';
import { connect } from 'react-redux';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { toast } from 'react-toastify';


export const _DistributeRender: React.FC<IDistributeRenderProps> = (
    { 
        ideaId, 
        statusId, 
        cancle, 
        save, 
        fund, 
        distributeMultiplicator, 
        max = 0, 
        currencyNamePlural = 'Пряники', 
        currencyName = 'Пряник' 
    }) => {
    const [awardFund, setAwardFund] = React.useState(distributeMultiplicator * fund);
    const [commment, setComment] = React.useState('');
    const [investors, setInvestors] = React.useState<any>({ isLoad: true, data: false });
    const [search, setSearch] = React.useState<any>({ data: [] });

    if (!investors.data) {
        setInvestors({ isLoad: true, data: true })
        getInvestors(ideaId, (invUsers: any) => setInvestors(invUsers))
    }
    const onSave = () => {
        const users: RewardUsers[] = investors.data.map((user: any) => ({ id: user.id, value: user.awards }));
        distributeIdea(ideaId, statusId, commment, users, (d: any) => {
            if (d && d.error_code === 0) {
                save();
            } else {
                console.error(d)
                d?.error_text && toast.error(d.error_text);
            }
            
        });
        
    }
    const calcRemain = () => {
        let awardsSum = 0;
        if (investors.isLoad) return awardFund;
        investors.data.forEach((u: any) => {
            awardsSum += u.awards ? u.awards : 0;
            return u;
        })
        const remain = awardFund - awardsSum;
        if (remain <= 0) return 0;
        return remain;
    }

    const removeInvestor = (user: any) =>
        () => setInvestors({ data: investors.data.filter((u: any) => user.id !== u.id) });

    const searchInvestor = (val: string) => {
        searchInvestors(ideaId, val, (invUsers: any) =>
            setSearch({
                data: invUsers.data.filter((u: any) =>
                    investors.data.find((us: any) =>
                        us.id === u.id) === undefined)
            }));
    }

    

    const distribute = () => {
        // const investorInvested = investors.data.map((user: any) => Math.floor(user.invested / fund * awardFund))
        // const awards = Math.floor(awardFund / investors.data.length);
        // const awards = investors.data.map((user:any) => Math.floor(user.invested / fund * awardFund));
        setInvestors({ data: investors.data.map((u: any) => ({ ...u, awards: Math.floor(u.invested / fund * awardFund) })) });
    }

    const changeAward = (user: any) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const arr = investors.data.map((u: any) => {
                if (u.id === user.id) {
                    const val = parseInt(event.target.value||"0");
                    if ((calcRemain() + (u.awards||0)) - val >= 0) {
                        u.awards = val > 0 ? val : 0;
                    }else{
                        u.awards = calcRemain() + (u.awards || 0);
                    }
                }
                return u;
            })
            setInvestors({ data: arr })
        }

    const changeAwardFund = (event: React.ChangeEvent<HTMLInputElement>) =>
        setAwardFund(parseInt(event.target.value));

    const addInvestor = (user: any) =>
        () => {
            setSearch({
                data: search.data.filter((u: any) => user.id !== u.id)
            });
            setInvestors({ data: [...investors.data, user] })
        }

    return (
        <div className={'DistributeRender'}>
            <FieldsHeader>{i18n.t('pryaniky.idea.distribute.title')}: </FieldsHeader>

            <div className={'DistributeRender-Distribute'}>
                <div className={'DistributeRender-Fund'}>{i18n.t('pryaniky.idea.distribute.fund')}: {fund} {fund !== 1 ? currencyNamePlural : currencyName}</div>
                <div className={'DistributeRender-FundSize'}>{i18n.t('pryaniky.idea.distribute.fund.size')}:
                    <ButtonBox><input className={'DistributeRender-FundInput'} type='number' value={awardFund} onChange={changeAwardFund} />
                        <Button theme='support' onClick={distribute}>{i18n.t('pryaniky.idea.distribute')}</Button></ButtonBox>
                </div>
                <div className={'DistributeRender-Remain'}>{i18n.t('pryaniky.idea.distribute.remain')}: <span>{calcRemain()}</span></div>
            </div>

            {!investors.isLoad ? <DistributeUserList changeAward={changeAward} removeInvestor={removeInvestor} addInvestor={addInvestor} search={search.data} onSearch={searchInvestor} data={investors.data} /> : <div>load</div>}

            <StageComment value={commment} onChange={setComment} />

            <ActionButtons save={onSave} cancle={cancle} />
        </div >
    );
}

export const DistributeRender = connect(
    (state: any) => ({
        currencyName: state.store.appSettings.currencyNameNominativeSingular,
        currencyNamePlural: state.store.appSettings.currencyNameGenitivePlural,
        max: getCurrentUser(state).extData.thanksForAll,
    })
)(_DistributeRender);