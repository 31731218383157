import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IBodyProps } from 'utils/src/DialogCreator'

///////////

export type IMoveToGroupPresenterProps = IMoveToGroupOwnProps

export interface IMoveToGroupOwnProps extends IClassNameProps, IBodyProps<IMoveToGroupResult> {

}

export interface IMoveToGroupResult {
    group: any
}


export const cnClassName = cn('LTMoveToGroup');