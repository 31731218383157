import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext } from '../../contexts/news'
import { Button } from 'uielements/src';
import MDRender from 'uielements/src/CommonmarkRender'
import MiniHeader from '../MiniHeader'
import { LinkFilter } from '../../utils/utils'
import openNews from 'blocks/Dialogs/News/DialogNewsView/DialogNewsView'
import i18n from 'localizations/i18n'

type IPresenterProps = News.ContentView.IPresenterProps
type IOwnProps = News.ContentView.IOwnProps
type IDispatchProps = News.ContentView.IDispatchProps
type IStateProps = News.ContentView.IStateProps
type IState = News.ContentView.IState


export const Presenter: FC<IPresenterProps> = ({
    className,
    id,
    text,
    header,
    openBtnText,
    headerMdText
}) => {
    async function open() {
        try {
            const result = openNews({ newsId: id })
        } catch (e) { }
    }
    // const title = removeMdLink(header)
    const lheader = headerMdText || header
    return <div className={cnClassName({}, [className])}>
        <LinkFilter action={open}>
            <MiniHeader authorOnly onClick={open} className={cnClassName('Header')} disableTextCut />

            <div className={cnClassName('Content')}>
                {lheader.trim() !== '' && <div className={cnClassName('Title')}>
                    <MDRender
                        // disableEmojiOne
                        className={cnClassName('TitleRender')}
                        type={'inline'}
                        disableShowMoreBtn
                        textCut={false}
                        // maxCharCount={200}
                        source={lheader}
                    />
                </div>}
                <MDRender
                    disableEmojiOne
                    className={cnClassName('Render')}
                    type={'textOnly'}
                    disableShowMoreBtn
                    textCut={true}
                    maxCharCount={350}
                    source={text}
                />
                <div className={cnClassName('Footer')}>
                    <Button
                        theme={'unstyled'}
                        className={cnClassName('OpenBtn', ['primaryColor3-text'])}
                        onClick={open}>{openBtnText || i18n.t('pryaniky.timeline.news.readfull')}</Button>
                </div>
            </div>
        </LinkFilter>

    </div>
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));
