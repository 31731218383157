import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { mapDispatchToProps, IDispatchProps } from 'redux/connector'
import { Page } from 'blocks/Page/Page'
import i18n from 'localizations/i18n'
import { setContext } from 'redux/actions/Widgets'
import { pageNames } from 'redux/pageNames'

interface IEventsProps extends IActionsDispatch, IDispatchProps { }
interface IActionsDispatch {
  setContext: any
}

export const EventsPresenter: React.FC<IEventsProps> = props => {
  useEffect(() => {
    props.setContext({
      paths: ['common'],
      innerPath: '',
      value: {
        newsTypes: 'events',
        oneNewsType: 'events'
      },
    })
  }, [])

  return <Page page={{ name: pageNames.events, title: i18n.t('events'), }} />
}

export const Events = connect(
  null,
  mapDispatchToProps({ setContext })
)(EventsPresenter)

