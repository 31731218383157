import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";

import i18n from 'localizations/i18n';
import * as utils from 'utils/src/utils';
import { mapStateToProps, IWikiStateProps, cnWiki, IWikiProps } from './Wiki.index';
import './Wiki.scss';
import './OD.scss';
import { Button, Icon } from 'uielements/src';
import { Prompt } from 'react-router-dom'
import { Comments } from '../../LMSModule/LMS/Comments/Comments'
import { LikeAction } from './Likes/Likes'
import { Actions } from 'blocks/NewsView/Actions/Actions'
import PryanikyEditor, { StyleButton } from 'uielements/src/PryanikyEditorV1'
import { convertFromRawWithTable, convertToRawWithTable } from 'uielements/src/PryanikyEditorV1/converter/raw'
import { EditorState, convertToRaw, convertFromRaw, ContentState, Modifier, SelectionState } from 'draft-js';
import { rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { allEnabledPreset } from 'uielements/src/PryanikyEditorV1/modulesMap';
import withConfirmDialog from 'blocks/HOCs/Button/withConfirmDialog'
import CompoundDecorator from 'uielements/src/PryanikyEditorV1/CompoundDecorator';
import MDNodeBulder from 'uielements/src/CommonmarkRender/MDNodeBulder'
import { isString } from 'lodash';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { sendSignal } from 'utils/src/signals';
import { CustomSettings } from 'utils/src/CustomSettings'
const ConfirmBtn = withConfirmDialog(Button)

const createFromMd = (md: string, decorators: any) => {
  const nb = new MDNodeBulder(md)
  nb.getDraftRaw()
  return EditorState.createWithContent(convertFromRaw(nb.getDraftRaw()), decorators)
}


const createState = (data: any, HTMLWidgetContentEdit: boolean) => {
  const decorators = CompoundDecorator(undefined, !HTMLWidgetContentEdit)//HTMLWidgetContentEdit ? undefined : CompoundDecorator()
  return (data && data.blocks) ?
    (
      data.blocks.length === 1 && data.blocks[0].text === '' && isString(data.markdownText) && data.markdownText !== '' ?
        createFromMd(data.markdownText, decorators) :
        EditorState.createWithContent(convertFromRawWithTable(data, decorators), decorators)
      //EditorState.createWithContent(convertFromRaw(data), HTMLWidgetContentEdit ? undefined : CompoundDecorator())
    ) :
    EditorState.createEmpty()
}

let megaEditCrutch = false;

export class WikiPresenter extends React.PureComponent<IWikiProps, any> {

  private disableRemoveEdit: boolean = false;

  public inputReference: any = React.createRef();

  constructor(props: IWikiProps) {
    super(props);

    this.state = {
      // data: (this.props.data && this.props.data.blocks) ? EditorState.createWithContent(convertFromRaw(this.props.data), this.props.widget.HTMLWidgetContentEdit ? undefined : CompoundDecorator()) : EditorState.createEmpty(),
      data: createState(this.props.data, this.props.widget.HTMLWidgetContentEdit),
      edited: false,
      saveLock: false
    };
  }


  /**
   * componentDidUpdate
   */
  public componentDidUpdate(pp: IWikiProps, pstate: any) {
    // (window as any).wikiEditorModules = { fonts: false }
    if (pp.data !== this.props.data && !this.props.widget.HTMLWidgetContentEdit) {
      this.setState({
        // data: (this.props.data && this.props.data.blocks) ? EditorState.createWithContent(convertFromRaw(this.props.data), this.props.widget.HTMLWidgetContentEdit ? undefined : CompoundDecorator()) : EditorState.createEmpty()
        data: createState(this.props.data, this.props.widget.HTMLWidgetContentEdit)
      })
      this.sendEditSignal(this.props.widget.HTMLWidgetContentEdit);
      return;
    }
    if (this.props.widget.HTMLWidgetContentEdit && (this.props.widget.HTMLWidgetContentEdit !== pp.widget.HTMLWidgetContentEdit)) {
      this.setState({
        // data: (this.props.data && this.props.data.blocks) ? EditorState.createWithContent(convertFromRaw(this.props.data), this.props.widget.HTMLWidgetContentEdit ? undefined : CompoundDecorator()) : EditorState.createEmpty()
        data: createState(this.props.data, this.props.widget.HTMLWidgetContentEdit)
      })
      this.sendEditSignal(this.props.widget.HTMLWidgetContentEdit);
      window.addEventListener('beforeunload', this.beforeUnload);
      return;
    }
    if (this.props.widget.HTMLWidgetContentEdit !== pp.widget.HTMLWidgetContentEdit) {
      this.sendEditSignal(this.props.widget.HTMLWidgetContentEdit);
      window.removeEventListener('beforeunload', this.beforeUnload)
      return;
    }
    // check unset edit state at check button on top of the page
    if (!this.disableRemoveEdit && !this.props.edit && this.props.widget.HTMLWidgetContentEdit) {
      this.setEdit(false)();
      this.sendEditSignal(false);
      return;
    }
  }

  private beforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = i18n.t('pryaniky.wiki.beforeUnload')
  }

  public componentDidMount() {
    if (this.props.edit && megaEditCrutch) {
      this.onClickCancle();
    };
  }

  public componentWillUnmount() {
  }

  public sendEditSignal(value: boolean) {
    if (this.props.widgetId) {
      sendSignal(`${this.props.widgetId}/edit`, [`${this.props.widgetId}/edit`, value]);
    }
  }

  public render() {
    const customClassNames = this.props.edit ? 'edit' : 'no_edit';
    const { tag: TagName = 'div', children, edit, className = '' } = this.props;

    if (!this.props.widget.HTMLWidgetContentEdit) {
      return (

        <TagName {...this.props} className={cnWiki({}, [customClassNames, className])}>
          {this.props.widget.isEditable && !edit ? <>{<Button className={cnWiki('EditBtn')} onClick={() => {
            megaEditCrutch = true;
            this.disableRemoveEdit = true;
            this.setEdit(true)();
            this.sendEditSignal(true);
          }} >
            <Icon icon={'edit'} />
          </Button>}</> : null}

          <PryanikyEditor
            maxFileSize={this.props.maxFileSize}
            key={'no_edit_with_state'}
            enableModules={{ ...allEnabledPreset, secrets: true, ...(CustomSettings.getValue('wikiEditorModules') || {}) }}
            // onChange={this.onChange}
            onChange={() => { }}
            enabledAnvanced={true}
            disableToolbar={true}
            setActionBtn={() => { }}
            value={this.state.data}
            viewOnly={true} />

          {(this.props.data && utils.isValidGuid(this.props.data.newsUid)) &&
            <Actions className={cnWiki('Actions')}>
              <LikeAction className={cnWiki('Likes')} nid={this.props.data.newsUid} />
            </Actions>}
          {utils.isValidGuid(this.props.widget.newsUid) && <Comments className={cnWiki('Comments')} nid={this.props.widget.newsUid} />}
        </TagName>

      )
    }

    return (
      <TagName {...this.props} className={cnWiki({}, [customClassNames, className])}>
        <input type="file" hidden ref={this.inputReference} onChange={this.fileUploadInputChange} />
        <Prompt when={true} message={i18n.t('pryaniky.wiki.beforeUnload')} />
        <PryanikyEditor
          maxFileSize={this.props.maxFileSize}
          key={'edit'}
          enableModules={{ ...allEnabledPreset, secrets: true, ...(CustomSettings.getValue('wikiEditorModules') || {}) }}
          enabledAnvanced={true}
          value={this.state.data}
          setActionBtn={(v: any) => { }}
          onChange={this.onChange}
          // autoFocus={true}
          saveLock={(saveLock: boolean) => this.setState({ saveLock })}
          presetTextColors={this.props.textEditorColors}
          additionalControls={
            <>
              <StyleButton title={'Скачать RAW'} onToggle={this.generateFile} label={<Icon icon={'fa-download'} />} />
              <StyleButton title={'Загрузить RAW'} onToggle={this.fileUploadAction} label={<Icon icon={'upload'} />} />
              <div className={'Delimiter'}></div>
              <StyleButton disabled={this.state.saveLock} title={'Сохранить'} onToggle={this.savePage} label={<Icon icon={'save'} />} />
              <ConfirmBtn
                disabled={this.state.saveLock}
                theme={'unstyled'}
                className={'PrnEditor-StyleButton'}
                // title={'Закрыть'}
                confirmTitle={'Завершить редактирование?'}
                onClick={this.onClickCancle}
                onConfirm={this.cancleEdit}
                onAccept={() => {
                  this.savePage();
                  this.setEdit(false)()
                  this.sendEditSignal(false);
                }}
                acceptBtnText="Сохранить изменения"
                confirmBtnText="Не сохранять"
              >
                <Tooltip title={'Закрыть'} >
                  <span>
                    <Icon icon={'times'} />
                  </span>
                </Tooltip>
              </ConfirmBtn>
            </>}
        />

        {children}

      </TagName>
    )
  }

  public fileUploadAction = () => {
    this.inputReference.current.click();
  }

  public fileUploadInputChange = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = (evt: any) => {
      if (evt.target && evt.target.result) {
        const content: string = evt.target.result as string
        try {
          const rawDraft = JSON.parse(content)

          const draftState = EditorState.createWithContent(convertFromRawWithTable(rawDraft))

          this.setState({ data: draftState })
        } catch (error) {

        }
      }

    }
    reader.onerror = (evt) => {
    }
  }


  public generateFile = () => {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(convertToRawWithTable(this.state.data.getCurrentContent()))));
    element.setAttribute('download', 'raw');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element)
  }

  // public setEditorState = (state: any) => this.setState({ data: state })
  // public getEditorState = () => this.state.data

  public onChange = (state: any) => {
    this.setState({ data: state, edited: true })
  }

  public onClickCancle = () => {
    if (!this.props.widget.HTMLWidgetContentEdit) return true;
    this.disableRemoveEdit = false;
    megaEditCrutch = false;
    const currentData = JSON.stringify(convertToRawWithTable(this.state.data.getCurrentContent()))
    const defaultData = JSON.stringify({ ...this.props.data, markdownText: undefined })
    if (currentData === defaultData) {
      this.cancleEdit()
      return true
    }
    return false;
  }

  public cancleEdit = () => {
    this.setState({
      // data: (this.props.data && this.props.data.blocks) ? EditorState.createWithContent(convertFromRaw(this.props.data), this.props.widget.HTMLWidgetContentEdit ? undefined : CompoundDecorator()) : EditorState.createEmpty()
      data: createState(this.props.data, false),
    })
    this.setEdit(false)()
    this.sendEditSignal(false);
  }

  public setEdit = (HTMLWidgetContentEdit: boolean) => () => {
    if (!HTMLWidgetContentEdit) {
      this.disableRemoveEdit = false;
      megaEditCrutch = false;
    }
    this.setState({
      data: createState(this.props.data, HTMLWidgetContentEdit)
    }, () => {
      this.props.updateWidget({
        ...this.props.widget,
        HTMLWidgetContentEdit
      });
    })
  }

  public savePage = () => {
    const draftRaw = convertToRawWithTable(this.state.data.getCurrentContent())
    //конвертируем рав в мапкдаун
    const markdownText = draftToMarkdown(draftRaw, rawToMd);
    this.setState({ edited: false }, () => {
      this.props.updateWidget({
        ...this.props.widget,
        data: {
          ...this.props.widget.data,
          ...draftRaw,
          markdownText
        }
      });
      this.props.saveWidgetData();
    })

  }



}


export const Wiki = connect<IWikiStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(WikiPresenter)
