import React, { useCallback } from 'react';
import './LeftMenu.scss';
import { ILeftMenuProps, cnLeftMenu, mapLeftMenuStateToProps, mapLeftMenuActionsToProps } from './LeftMenu.index';
import { connect } from 'react-redux';
import { IMenu } from './redux/interfaces';
import { LeftMenuItem } from './Item/LeftMenuItem';
import TreeView from '@material-ui/lab/TreeView';
import { MENU_BREAKPOINT } from 'utils/src/utils';
import { Remover } from './Remover/LeftMenuRemover';

(window as any).leftMenuLevels = 2;

/**
 * @deprecated
 * @param param0 
 * @returns 
 */
const LeftMenuPresenter: React.FC<ILeftMenuProps> = ({ className, data, expanded, opened, openedMenu, edit, hidden, menuOpen, menuHidden, menuGetData, menuSetExpandedAll }) => {

  const ref = React.useRef<HTMLDivElement>(null);

  const checkWidth = () => {
    if (document.body.clientWidth < MENU_BREAKPOINT) {
      menuHidden(true);
    }
    else {
      menuHidden(false);
    }
  }

  const clickListener = (e: MouseEvent) => {
    const target = e.target as HTMLDivElement;
    if (!openedMenu && !edit && opened && ref.current && !ref.current.contains(target) && !target.closest('.LeftMenuItemChange')) {
      menuOpen(false);
      menuSetExpandedAll(false);
    }
    if (!hidden && document.body.clientWidth < MENU_BREAKPOINT && ref.current && !ref.current.contains(target) && !target.closest('.LeftMenuItemChange') && !target.closest('#DASHUPMENUSCROLLER')) {
      menuHidden(true);
    }
  };

  React.useEffect(() => {
    if (!data.length) menuGetData();
    checkWidth();
    window.addEventListener('resize', checkWidth);
    return () => {
      window.removeEventListener('resize', checkWidth);
    };
  }, []);

  React.useEffect(() => {
    document.addEventListener('click', clickListener);
    return () => {
      document.removeEventListener('click', clickListener);
    }
  }, [opened, edit]);

  const renderItem = (path: string[]) =>
    (el: IMenu.StructureItem, idx: number) => {
      return <LeftMenuItem active={el.active} key={el.id} isFirst={idx === 0} path={path} id={el.id}>
        {el.childrens?.map(renderItem([...path, el.id]))}
      </LeftMenuItem>
    }

  return (
    <div className={cnLeftMenu('Layout', { edit })}>
      <div ref={ref} className={cnLeftMenu({ hide: !openedMenu && !opened, hidden }, [className])} onClick={() => !openedMenu && !opened && menuOpen(true)}>
        <TreeView
          className={cnLeftMenu('Inner')}
          expanded={expanded}>
          {data.map(renderItem([]))}
        </TreeView>
      </div>
      <div className={cnLeftMenu('Editor')}>
        <div className={cnLeftMenu('Editor-Inner')}>
          <Remover />
        </div>
      </div>
    </div>
  )
}

/**
 * @deprecated
 * @param param0 
 * @returns 
 */
export const LeftMenu = connect(
  mapLeftMenuStateToProps,
  mapLeftMenuActionsToProps
)(LeftMenuPresenter);