import * as React from 'react';
import { IEditorFormProps, IEditorFormState, cnEditorForm } from './EditorForm.index'
import i18n from '../../localizations/i18n'
import './EditorForm.scss';
import './EditorForm.ie.scss';
import { AttachmentUploader } from 'uielements/src/AttachmentUploader/AttachmentUploader'
import { Button, ErrorMsg } from 'uielements/src'
import { Attachments } from 'uielements/src/Attachments/Attachments'
import { withNewsContext } from '../NewsView/NewsView.context'
import { EditorState } from 'draft-js';
import { API } from 'utils/src/utils'
import { Actions } from './Actions/Actions'
import { ActionButtons } from 'blocks/ActionButtons/ActionButtons'
import ErrorsLog from 'uielements/src/ErrorsLog'
import withHelpTooltip from 'blocks/HOCs/Button/withHelpTooltip'
import withLoadSpin from 'uielements/src/HOCs/Button/withLoadSpin'
import { Translate } from 'localizations/Translate';

const SendButton = withLoadSpin(withHelpTooltip(Button))

const EditorContext = React.createContext({
    ref: React.createRef<any>(),
    onChange: (val: any) => { },
    value: EditorState.createEmpty(),
    refGetter: (ref: any) => { },
    setActionBtn: (actionBtns: React.ReactNode) => { },
    onFocus: () => { },
    onBlur: () => { },
    autoFocus: true,
    maxFileSize: undefined,
    setError: (isError: boolean, errorText: string = '') => { },
    locker: (lock: boolean) => {}
});

export const withEditorContext = (Component: any) =>
    (props: any) => <EditorContext.Consumer>
        {
            value => {
                return <Component {...props}
                    maxFileSize={value.maxFileSize}
                    setError={value.setError}
                    autoFocus={value.autoFocus}
                    onFocus={value.onFocus}
                    onBlur={value.onBlur}
                    ref={value.refGetter}
                    locker={value.locker}
                    setActionBtn={value.setActionBtn} />
            }
        }
    </EditorContext.Consumer>

const noopArr: any[] = []

export class EditorForm extends React.PureComponent<IEditorFormProps, IEditorFormState> {
    private EditorContext: any;
    private uploaderReference: any;
    private actionsHover = false;
    private ref: any = null;

    constructor(props: IEditorFormProps) {
        super(props);

        this.state = {
            actionBtns: undefined,
            isOpen: false,
            ref: null,
            errorText: '',
            isError: false,
            uploadErrorText: undefined,
            hasFocus: false,
            lockFromEditor: false
        }
        this.EditorContext = {
            ref: React.createRef<any>(),
            onChange: (val: any) => { },
            value: EditorState.createEmpty(),
            refGetter: (ref: any) => {
                this.props.textAreaRefGetter && this.props.textAreaRefGetter(ref)
                this.EditorContext.ref = ref;
            },
            setActionBtn: (actionBtns: React.ReactNode) => {
                this.setState({ actionBtns })
            },
            setError: (isError: boolean, errorText: string = '') => {
                this.setState({ isError, errorText })
            },
            maxFileSize: props.maxFileSize,
            autoFocus: this.af,
            onFocus: () => { this.setState({ isOpen: true, hasFocus: true }) },
            onBlur: () => {
                this.setState({ hasFocus: false })
                // ;(!this.hasText() && !this.actionsHover) && this.setState({ isOpen: false }) 
            },
            locker: (lock: boolean) => {
                this.setState({ lockFromEditor: lock })
            }
        }
    }

    private af = () => {
        return this.props.autoFocus
    }

    public componentDidUpdate(nextProps: any) {
        if (!this.state.hasFocus && !this.hasText() && !this.actionsHover) {
            this.setState({ isOpen: false })
        }
    }

    private onAttachError = (e: string) => this.setState({ uploadErrorText: e })
    private onHoverActions = () => { this.actionsHover = true }
    private onLeaveActions = () => { this.actionsHover = false }

    public render() {
        const {
            children,
            complie,
            isValid,
            hideActions,
            disableAttach,
            validationErrors = noopArr,
            save,
            cancle,
            saveText = i18n.t('pryaniky.createPost.submit'),
            postErrorCode = 0,
            postErrorText = '',
            denyMIME,
            maxFileSize,
            onChangePublishAt,
            publishAt,
            onChangeGroup,
            group,
            activePostId,
            className
        } = this.props
        let actionsOpen = hideActions ? this.state.isOpen : true;

        actionsOpen = actionsOpen ? true : this.props.files.length > 0;
        actionsOpen = actionsOpen ? true : this.hasText()
        actionsOpen = actionsOpen ? true : this.state.hasFocus

        const isDisabled: boolean = this.state.isError || (isValid ? true : false) || this.state.lockFromEditor;
        return (
            <div ref={(e: any) => this.ref = e} className={'EditorForm' + (actionsOpen ? ' EditorForm_open' : ' EditorForm_close') + `${className ? ` ${className}` : ''}`}>
                <EditorContext.Provider value={this.EditorContext}>
                    {children}
                </EditorContext.Provider>

                {!disableAttach && <Attachments className={''} files={this.props.files} onDelete={this.onFileDelete} />}

                {!disableAttach && (this.ref && <AttachmentUploader
                    elem={this.ref}
                    denyMIME={denyMIME}
                    maxFileSize={maxFileSize}
                    onError={this.onAttachError}
                    attachments={this.props.files}
                    reference={this.setUploaderReference}
                    onChange={this.props.onChangeFile} />)}

                <ErrorsLog className={cnEditorForm('ErrorsLog')} error={this.state.uploadErrorText} />
                {this.state.errorText !== '' && <ErrorMsg className={cnEditorForm('ErrorBox')}>{this.state.errorText}</ErrorMsg>}
                {(postErrorText !== '' && postErrorCode !== 0) && <ErrorMsg className={cnEditorForm('ErrorBox')}>
                    {i18n.t('pryaniky.createPost.send.error_code.' + postErrorCode, { defaultValue: postErrorText })}
                    {/* {postErrorText} */}
                </ErrorMsg>}

                {actionsOpen &&
                    <Actions
                        activePostId={activePostId}
                        group={group}
                        onChangeGroup={onChangeGroup}
                        publishAt={publishAt}
                        onChangePublishAt={onChangePublishAt}
                        onMouseEnter={this.onHoverActions} onMouseLeave={this.onLeaveActions}
                        openFileWindow={!disableAttach ? this.openFileWindow : undefined}
                        actionBtns={this.EditorContext?.ref?.insertText && this.state.actionBtns}
                        mentionsButtons={this.EditorContext?.ref?.insertText && this.mentionsButtons}
                        complie={complie ?
                            complie(isDisabled) :
                            <ActionButtons cancle={cancle} >
                                <SendButton
                                    main
                                    isLoading={this.props.isSending}
                                    className={'ActionButtons-SaveBtn'}
                                    disableHelp={!isDisabled}
                                    tooltipNoPadding
                                    showDelay={300}
                                    help={<div>
                                        {actionsOpen && validationErrors.map((err, idx) => <ErrorMsg key={idx} type={'info'} className={cnEditorForm('ErrorBox')}>{err.errorI18nKey ? <Translate i18nKey={err.errorI18nKey} variablesI18nKeys={err.errorVariablesI18nKey} /> : err.message}</ErrorMsg>)}
                                    </div>}
                                    disabled={isDisabled}
                                    onClick={save}>{saveText}</SendButton>
                            </ActionButtons>}
                    />}
            </div>
        )
    }

    private hasText = () => {
        if (this.EditorContext.ref?.hasText && this.EditorContext.ref.hasText()) {
            return true//this.EditorContext.ref.hasText();
        } else if (this.props.newsHeader && this.props.newsHeader !== '') {
            return true
        }
        return false;
    }

    private onFileDelete = (elem: any) => {
        const copyFile = [...this.props.files];
        copyFile.splice(this.props.files.indexOf(elem), 1)
        this.props.onChangeFile(copyFile, true)
        API.files.remove(elem.id);
    }

    private openFileWindow = () => this.uploaderReference.open()

    private setUploaderReference = (ref: any) => this.uploaderReference = ref;

    private mentionsButtons = (val: string) => (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (this.EditorContext?.ref?.insertText) {
            this.EditorContext.ref.insertText(val);
        }
    }
}

export const EditorWithNewsContext = withNewsContext(EditorForm);