import { IStateType } from "redux/store";
import { Omit } from "utils/src";
import { TWidget } from "utils/src/BaseTypes/widgets.types";
import { WidgetsTypes } from "utils/src/widgets/types";
import { TSimplifiedWidgetByType } from "Widgets_v2/types";
import { NWidgets } from "./types";

type Store = { widgets2: NWidgets.Reducer };


/**
 * get widget store current value
 * @param store 
 * @returns 
 */
export const getWidgetsStore = (store: Store) => store.widgets2;

type GetWidgetsStoreArgs = Parameters<typeof getWidgetsStore>;
type GetWidgetsStoreRezult = ReturnType<typeof getWidgetsStore>;


/**
 * get widget store field value
 * @param fileldName 
 * @returns 
 */
export const getWidgetsField = <Field extends keyof ReturnType<typeof getWidgetsStore>> (fileldName: Field) => (...args: GetWidgetsStoreArgs) => {
    return getWidgetsStore(...args)[fileldName];
};


/**
 * get simplify widget by widget id
 * @param id 
 * @returns 
 */
export const getWidgetById = <Type extends keyof typeof WidgetsTypes> (id: TWidget['id']) => (...args: GetWidgetsStoreArgs) => {
    return getWidgetsField('widgets')(...args)[id] as TSimplifiedWidgetByType<Type>;
};


/**
 * get widgets store object by storeName and id  
 * exlude widgets and active fields  
 * to get widget by id use `getWidgetById`
 * to get active use `getWidgetsField`
 * @param storeName 
 * @param id 
 * @returns 
 */
export const getWidgetStoreObjectById = <Store extends keyof Omit<GetWidgetsStoreRezult, 'widgets' | 'active'>> (storeName: Store, id: keyof GetWidgetsStoreRezult[Store]) => (...args: GetWidgetsStoreArgs) => {
    return getWidgetsField(storeName)(...args)[id];
};
