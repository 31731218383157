import React from 'react';
import './Level.scss';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { cnRating } from '../Rating.index';
import { IRatingUserInfo } from 'utils/src/requests/models/api.ratings';
import { ProgressBar } from 'uielements/src/ProgressBar/ProgressBar';
import { Button } from 'uielements/src/Button/Button';
import { formatNumber } from 'utils/src/utils';


interface IExtRatingUserInfo extends IRatingUserInfo {
    idx: number;
    color: string;
    logo?: string;
}

export interface IItemProps {
    tag?: React.ComponentType<any> | string;
    data: IExtRatingUserInfo;
    color: string;
    maxValue: number;
    rating: any;
}

export const Level: React.FC<IItemProps& any> = ({ data, tag: TagName = 'div', color, maxValue, rating }) => {
    const [carMoved, setCarMoved] = React.useState(false);
    React.useEffect(() => void setTimeout(() => setCarMoved(true), 1000), []);
    return (
        <TagName className={cnRating('Level')}>
            <Avatar
                name={data.level.name}
                imgId={data.level.imgId}
                imgUrl={data.level.imgUrl}
                size={56}
                className={cnRating('LevelImg')}
            />
            <div className={cnRating('LevelData')}>
                <div className={cnRating('LevelName')}>{data.level.name}</div>
                <div className={cnRating('LevelDesc')}>{data.level.description}</div>
            </div>
        </TagName>
    );
};
