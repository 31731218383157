import React, { FC, useState } from 'react';
import { Box, Button, Typography } from 'muicomponents/src';
import { Avatar } from 'muicomponents/src/Avatar';
import withConfirmDialog from 'muicomponents/src/Button/HOCs/withConfirmDialog';
import { IItemProps } from '../BadgesTooltipList.index';
import { DeleteRound, ItemBox, StyledBadge, TooltipTitleBox } from './../../../styled';
import { LightMentionTooltip } from 'muicomponents/src/Tooltip';
import { Link } from 'muicomponents/src/Link/Link';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton } from 'muicomponents/src/IconButton';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn'
import { background } from 'uielements/src/Chart';

const ConfirmBtn = withConfirmDialog(Button)

export const BadgeItemMui: FC<IItemProps> = ({ item, onRemove, /* onDelete, */ size }: IItemProps) => {

    const [isDeleteActive, setDeleteActive] = useState(false)

    return <LightMentionTooltip title={
        <Link href={item.url}>
            <TooltipTitleBox>
                <Avatar src={item.badgeImgUrl || BaseRequests.getUrl(defaultBadgeIconUrl)} sx={{ mr: 1 }} />
                <Box>
                    <Typography color='InfoText' variant='subtitle1' >{item.name}</Typography>
                    <Typography fontSize='small' color='GrayText'>{item.description}</Typography>
                </Box>
            </TooltipTitleBox>
        </Link>
    }
    >
        <ItemBox key={item.badgeUid}
            onMouseLeave={() => setDeleteActive(false)}
            onMouseEnter={() => setDeleteActive(true)} >
            {
                <Box>
                    <Button 
                    variant='text' 
                    sx={{ p: 0, 
                    '&:hover':{
                        borderRadius:"50%",
                        background:"inherit!important"
                    }}} 
                    href={`/badge/${item.badgeUid}`} >
                        <StyledBadge badgeContent={item.count} color="primary" invisible={item.count === 1}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}>
                            <Avatar src={item.badgeImgUrl || BaseRequests.getUrl(defaultBadgeIconUrl)} alt={item.name} sx={{ width: `${size}px`, height: `${size}px` }} />
                        </StyledBadge>
                    </Button>
                </Box>
            }
            {
                onRemove && isDeleteActive &&
                <ConfirmBtn onConfirm={() => onRemove(item.badgeUid)}
                    size='small'
                    sx={{
                        padding: 0,
                        position: 'absolute',
                        top: '-12px',
                        right: '-20px',
                        zIndex: 1,
                        margin: 0,
                        "&:hover":{
                            background:"inherit!important"
                        }
                    }}>
                    <DeleteRound>
                        <DeleteOutlineIcon
                            sx={{
                                fontSize: size === 140 ? "1.5rem" : size === 64 ? "1.4rem" : "1.3rem",
                                margin: 0,
                            }}
                            className='CustomStyle primaryColor3-text' />
                    </DeleteRound>
                </ConfirmBtn>
            }
        </ItemBox>
    </LightMentionTooltip>

}

