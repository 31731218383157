import { Button } from 'muicomponents/src'
import React, { Fragment, useMemo, useState } from 'react'
import { FakeBtnBox, FilterBtnText, ItemBox } from '../Filters_type_shopMui.styled'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { IStateType } from 'redux/store';
import { ShopSortType, OrderType } from 'utils/src/requests/models/api.base';
import { Box, Typography } from 'muicomponents/src';
import { Menu } from 'muicomponents/src/Menu';
import { i18n } from 'localization';
import { IShopSortings } from 'redux/shop/interfaces';
import CheckIcon from '@mui/icons-material/Check';
import { setShopFilters } from 'redux/shop/actions';
import { StyledMenuItem } from './Sortings.styled';
import { cnFiltersMui } from '../Filters_type_shopMui.index';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const Sorting = () => {

    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClickMenuBtn = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const globalSorts = useSelector((s: IStateType) => s.shop.chosenFilters.sorting)

    const initialSorts: IShopSortings = {
        OrderByPrice: '',
        // OrderByRating: '', убираем сортировку по рейтингу по тикету COMMON-11020
        OrderByPopular: '',
        OrderByCreateAt: '',
    }

    const sorts = useMemo(() => {
        return Object.keys(globalSorts).sort();
    }, [Object.keys(globalSorts).toString()]);

    const chosenSort = sorts.find((field) => globalSorts[field as keyof typeof globalSorts] !== '')

    const setSort = (key: ShopSortType, dir = 'desc') => {
        if (key === 'OrderByPrice') {
            let newValue = dir ? dir : 'asc'
            dispatch(setShopFilters({
                key: 'sorting',
                value: { ...initialSorts, OrderByPrice: chosenSort === key && globalSorts[key] === dir ? '' : newValue as OrderType }
            }))
        } else {
            dispatch(setShopFilters({
                key: 'sorting',
                value: { ...initialSorts, [key]: chosenSort === key ? '' : 'desc' }
            }))
        }
        handleClose()
    }

    const sortKey = chosenSort + '_' + globalSorts[(chosenSort as keyof IShopSortings || '')] || ''
    return (
        <ItemBox>
            <Button variant='outlined' size='small'
                id="open-sortings-button"
                aria-haspopup="true"
                onClick={handleClickMenuBtn}
                className={cnFiltersMui('sortButton')}
                endIcon={<ArrowDropDownIcon htmlColor='GrayText' sx={{ flexShrink: 1 }} />}
                startIcon={{
                    "OrderByPrice_asc": <ArrowUpwardIcon htmlColor='GrayText' sx={{ mr: 1, flexShrink: 1, }} />,
                    "OrderByPrice_desc": <ArrowDownwardIcon htmlColor='GrayText' sx={{ mr: 1, flexShrink: 1, }} />
                }[sortKey]}
            >

                {/* <ImportExportIcon htmlColor='GrayText' sx={{ mr: 1, flexShrink: 1, }} /> */}
                <FakeBtnBox>
                    <FilterBtnText color='ActiveCaption' noWrap variant='body2'>
                        {i18n.t(`pryaniky.shop.sort${chosenSort ? `.${chosenSort}` : ''}`)}
                    </FilterBtnText>
                </FakeBtnBox>
            </Button>

            <Menu open={open}
                anchorEl={anchorEl}
                id="open-sortings-menu"
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'open-sortings-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{ sx: { width: '309px' } }} >

                <Box>
                    {sorts.map((sort: string, i) => {
                        const value = globalSorts[sort as keyof typeof globalSorts]
                        if (sort === 'OrderByPrice') {
                            return <Fragment key={i}>

                                <StyledMenuItem
                                    onClick={() => setSort(sort, 'asc')}>
                                    <Typography>
                                        {i18n.t(`pryaniky.shop.sort.${sort}`)} {i18n.t(`pryaniky.shop.sort.ascending`)}
                                    </Typography>
                                    {value === 'asc' && <CheckIcon htmlColor='GrayText' />}
                                </StyledMenuItem>

                                <StyledMenuItem
                                    onClick={() => setSort(sort)}>
                                    <Typography>
                                        {i18n.t(`pryaniky.shop.sort.${sort}`)} {i18n.t(`pryaniky.shop.sort.descending`)}
                                    </Typography>
                                    {value === 'desc' && <CheckIcon htmlColor='GrayText' />}
                                </StyledMenuItem>
                            </Fragment>

                        } else {
                            return <StyledMenuItem key={i}
                                onClick={() => setSort(sort as keyof typeof globalSorts)}>
                                <Typography>
                                    {i18n.t(`pryaniky.shop.sort.${sort}`)}
                                </Typography>
                                {value !== '' && <CheckIcon htmlColor='GrayText' />}
                            </StyledMenuItem>
                        }

                    })}
                </Box>
            </Menu>
        </ItemBox>
    )
}

export default Sorting