import { Box, Button } from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';

export const PositionBox = styled(Box)({
    height: '100%',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}) as typeof Box;

export const PositionRemoveButton = styled(IconButton)({
    position: 'absolute',
    top: 0,
    right: 0
});

export const ShowFullButton = styled(Button)({
    width: '100%',
    position: 'absolute',
    bottom: 0,
    padding: 0,
    zIndex: 15,
    background: 'rgb(255 255 255 / 75%)'
}) as typeof Button;