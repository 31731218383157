import React, { FC, useMemo } from 'react'
import StringType from '../types/String/String'
import DateType from '../types/Date/Date'
import Interests from '../types/Interests/Interests'
import StringTelefon from '../types/String_telefon/String'
import StringEmail from '../types/String_email/String'
import Tags from '../types/Tags/Tags'
import Textarea from '../types/Textarea/Textarea'
import DateTime from '../types/DateTime/DateTime'


export const FieldsRender: FC<any> = ({ user, fieldsSet, fieldId, onChangeFields }) => {
    const dataset = fieldsSet === 'userListFields' ? user.baseData[fieldsSet].entities.fields[fieldId] : user.profileData[fieldsSet].entities.fields[fieldId]
    const { field, value, tags, files } = dataset;
    if (field.displayMode === 'system' || field.isHidden) {
        return <></>
    }

    if (field.fieldType === 'String' && field.mask === "email") {
        return <StringEmail onChangeFields={onChangeFields} fieldsSet={fieldsSet} field={field} fieldId={fieldId} value={value} tags={tags} />
    }
    if (field.fieldType === 'String' && field.mask === "telefon") {
        return <StringTelefon onChangeFields={onChangeFields} fieldsSet={fieldsSet} field={field} fieldId={fieldId} value={value} tags={tags} />
    }
    if (field.fieldType === 'String' || field.fieldType === 'AdditionalUserId') {
        return <StringType onChangeFields={onChangeFields} fieldsSet={fieldsSet} field={field} fieldId={fieldId} value={value} tags={tags} />
    }

    if (field.fieldType === 'Textarea') {
        return <Textarea onChangeFields={onChangeFields} fieldsSet={fieldsSet} field={field} fieldId={fieldId} value={value} tags={tags} />
    }

    if (field.fieldType === 'Date') {
        return <DateType onChangeFields={onChangeFields} fieldsSet={fieldsSet} field={field} fieldId={fieldId} value={value} tags={tags} />
    }
    if (field.fieldType === 'DateTime') {
        return <DateTime onChangeFields={onChangeFields} fieldsSet={fieldsSet} field={field} fieldId={fieldId} value={value} tags={tags} />
    }

    if (field.fieldType === 'Tags') {
        return <Tags onChangeFields={onChangeFields} fieldsSet={fieldsSet} field={field} fieldId={fieldId} value={value} tags={tags} />
    }
    if (field.fieldType === 'Interests') {
        return <Interests onChangeFields={onChangeFields} fieldsSet={fieldsSet} field={field} fieldId={fieldId} value={value} tags={tags} />
    }
    
    
    
    return <></>

}

export default FieldsRender