import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";
import i18n from 'localizations/i18n';
import { mapStateToProps, IGroupsWidgetStateProps, cnGroupsWidget, IGroupsWidgetProps, IGroupsWidgetState } from './GroupsWidget.index';
import './GroupsWidget.scss';
import { cnWidget } from '../Widget/Widget.index';
import { ListRound } from '../ListRound/ListRound';


export class GroupsWidgetPresenter extends React.Component<IGroupsWidgetProps, IGroupsWidgetState> {
  // public utils: any = utils;
  public el: HTMLElement | null;

  constructor(props: IGroupsWidgetProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {
      items: [],
      noData: false,
    };
  }

  public componentDidMount() {
    this.getData(this.props);
  }
  // public componentWillUnmount() {}
  public componentWillReceiveProps(np: IGroupsWidgetProps) {
    if (!utils.compareData(this.props.context.uId, np.context.uId)) {
      this.getData(np);
    }
  }

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', children, className = '', widget} = this.props;
    const { noData, items } = this.state;
    const additionalProps: {[s: string]: any} = {
      hide: noData
    }
    return (
      <TagName {...this.props} {...additionalProps} className={cnGroupsWidget({}, [customClassNames, className])}>
        <h3 className={cnWidget('Name')} children={i18n.t('groups')} />
        <ListRound items={items} cut={true} size={56} />
        {children}
      </TagName>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

  private getData = (props: IGroupsWidgetProps) => {
    if (props.context.uId) {
      utils.API.groups.byUser(props.context.uId, 'my')
      .r
      .then((d: any) => {
        if(utils.checkResponseStatus(d)) {
          const items = d.data.map((group: {[s: string]: any}) => {
            group.id = group.pkid;
            group.url = '/group/' + group.pkid;
            group.title = group.name;
            if(!utils.isEmptyGuid(group.imgId48x48)) group.imgUrl = group.imgUrl48x48;
            return group;
          });
          this.setState({
            items,
            noData: items.length === 0
          });
        }
      });
    }
  }

}


export const GroupsWidget = connect<IGroupsWidgetStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps({})
  )(GroupsWidgetPresenter)
//   compose(
//   withBemMod(cnGroupsWidget(), {})
// )(GroupsWidgetPresenter))
