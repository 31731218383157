import React, { ReactText, FC } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'uielements/src';
import './ItemList.scss';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { Actions } from '../../NewsView/Actions/Actions';
import { Like } from '../../NewsView/Actions/Like/Like'
import i18n from '../../../localizations/i18n';

import {
    IWikiItemListProps,
    IWikiItemListState,
    mapStateToProps,
    mapDispatchToProps,
    cnItem
} from './ItemList.index';
import { WikiListItemRender } from 'uielements/src/WikiParts/ItemList/ItemList'
import { ContextMenu } from '../ContextMenu/ContextMenu'


export class WikiItemListPresenter extends React.Component<IWikiItemListProps, IWikiItemListState>{
    constructor(props: any) {
        super(props);
        this.state = {
            showDialog: false,
            liked: false,

        };
    }

    public render() {
        const { tag: TagName = 'div', className = '', data, changeItem, showControls, context } = this.props;

        const { likeCount = 0, liked = false } = this.props.news;


        const outLink = (data.url.indexOf('http') === 0 || data.url.indexOf('//') === 0) && !data.url.includes(window.location.hostname);

        let href = outLink || data.url[0] === "/" ? data.url : "/" + data.url;

        if (!outLink && data.url.includes(window.location.hostname)) href = href.replace(href.substr(0, href.indexOf(window.location.hostname) + window.location.hostname.length), '');

        const isImg = data.titleImageId === GUID_EMPTY || data.titleImageId === null

        let linkType: string | undefined = outLink ? "link" : "rlink"
        linkType = href ? linkType : undefined

        return (
            <>
                {/* <WikiEditDialog onConfirm={this.props.changeItem} context={context} isShown={this.state.showDialog} onClose={this.closeDialog} data={this.props.data} id={this.props.data.id} /> */}

                <WikiListItemRender
                    className={className}
                    data={data}
                    outLink={outLink}
                    href={href}
                    isImg={isImg}
                    showControls={showControls}
                    Actions={<Actions>
                        <Like
                            newsId={this.props.news.id}
                            likeCount={likeCount}
                            liked={liked}
                            onClick={this.changeLike}
                            className={'Action'} />
                    </Actions>}
                    ActionList={

                        <ContextMenu
                            {...this.props}
                            isAdmin={showControls}
                            onEdit={() => {}}
                            btnRender={(toggleOpen: any, ref: any, isOpen: boolean) =>
                                <>
                                    {href &&
                                        <Button className={cnItem(showControls ? 'ReadAndEdit' : 'OnlyRead')} type={linkType} main padding="md" href={href}>
                                            <span> {i18n.t('pryaniky.wikilist.item.read')}</span>
                                        </Button>
                                    }

                                    {showControls || data.editable ? <Button forwardedRef={ref} onClick={toggleOpen} noBorder main className={cnItem('ActionsToggle', { open: isOpen })} >
                                        <Icon icon={'chevron-down'} className={cnItem('ActionsIcon')} />
                                    </Button> : undefined}
                                </>
                            }
                        />

                    }
                />


            </>
        )
    }
    private closeDialog = () => this.setState({ showDialog: false });
    private changeLike = (likeCount: number, liked: boolean) => {

        this.props.likeWikiRequest(this.props.news.id, likeCount, liked)


    }

}


export const ItemList = connect(
    mapStateToProps,
    mapDispatchToProps
)(WikiItemListPresenter)