import React from 'react';
import { cnWidget, IWidgetProps } from '../../Widget.index';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../../Widget';
import { widgets } from "i.widgets";
import { WikiArticlesPreseter } from 'blocks/WikiArticlesWidget/WikiArticlesWidget';
import { IWidgetTypeWikiArticlesProps } from './Widget_Type_Wiki_Articles.index';
import './Widget_Type_Wiki_Articles.scss';

const type = 'wikiArticles';

export default class WidgetTypeWikiArticlesPresenter extends WidgetPresenter<IWidgetTypeWikiArticlesProps> {

    public render() {
        if (!this.props.widget) return null
        const subtype = utils.widgetSubtype(this.props.widget.type);
        const Tag = widgets.components.common
        return <Tag {...this.props} className={cnWidget({ type, subtype })}>
            <WikiArticlesPreseter {...this.props} isEdit={this.props.edit} settings={this.props.widget.settings}  />
        </Tag>
    }
}