import { WidgetTypeSkeleton } from "blocks/Widget/_type/Widget_type_skeleton";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { capitalize } from "utils/src";
import { WidgetsTypes } from "utils/src/widgets/types";
import { Widget } from "Widgets_v2/Widget";
import { usePage } from "./Page.hooks";
import { cnPage, PageProps } from './Page.index';
import { PageBox } from "./Page.styled";
import { Prompt } from 'react-router';
import { Beforeunload } from 'react-beforeunload';
import { Translate } from 'localizations/Translate';

export const Page: FC<PageProps> = ({
    className,
    page,
    cbAfterGetData
}) => {

    const {
        loading,
        data,
        dataOld
    } = usePage(page, cbAfterGetData);

    const toggleFullWidth = useCallback((set: boolean) => {
        const layoutDOM = document.getElementById('Layout');
        const elem = Array.from(layoutDOM?.children || []).find((el) => el.classList.contains('Layout-Inner'));
        if(!layoutDOM || !elem) return;
        if(set) {
            elem.classList.add('FullWidth');
        } else {
            elem.classList.remove('FullWidth');
        }
    }, []);

    /**
     * if layout change and in layout settings setted fullWidth or layout type equals grid then add FullWidth to Layout-Inner
     */
    useEffect(() => {
        if(!data.layout) return;
        const layoutDOM = document.getElementById('Layout');
        if(!layoutDOM) return;
        if(
            data.layout.type === WidgetsTypes.grid ||
            data.layout.settings?.fullWidth
        ) {
            toggleFullWidth(true);
        } else {
            toggleFullWidth(false);
        }
    }, [data.layout]);

    /**
     * if component unmount and layout.type !== grid then remove FullWidth class
     */
    useEffect(() => {
        return () => {
            if(!data.layout) return;
            if(data.layout.type !== WidgetsTypes.grid) {
                toggleFullWidth(false);
            }
        }
    }, []);

    const type = useMemo(() => {
        return capitalize(page.name).replace(/\./gi, '-');
    }, [page.name]);

    const view = useMemo(() => {
        if(!data.layout) return <WidgetTypeSkeleton />
        return <Widget id={data.layout.id} />
    }, [data.layout]);

    return (
        <PageBox className={cnPage({ type }, [className])}>
            {data.edit && <Beforeunload onBeforeunload={() => Translate.t({ i18nKey: 'pryaniky.beforeunload.text' })} />}
            <Prompt
                when={data.edit}
                message={Translate.t({ i18nKey: 'pryaniky.beforeunload.text' })}
            />
            {view}
        </PageBox>
    );
};