import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps } from "../../redux/connector";
// import * as widgets from "../../widgetsUtils";

import { cnNews, INewsProps, INewsState, mapActionsToProps, mapStateToProps } from './News.index';

import './News.scss';
// import { Widgets } from '../Widgets/Widgets';
import { Page } from '../Page/Page';
import { pageNames } from 'redux/pageNames';

export class NewsPresenter extends React.Component<INewsProps, INewsState> {
  public utils = utils;
  public el: HTMLElement | null;
  private mounted = false;

  public componentDidMount() {
    // this.mounted = true;
    // this.props.setContext({
    //   paths: ['common'],
    //   innerPath: '',
    //   value: {
    //     nId: this.props.match.params.id
    //   }
    // })
  }
  // public componentWillUnmount() {}
  public componentDidUpdate(prevProps: INewsProps) {
    // if (this.props.match.params.id !== prevProps.match.params.id) {
    //   this.props.setContext({
    //     paths: ['common'],
    //     innerPath: '',
    //     value: {
    //       nId: this.props.match.params.id
    //     }
    //   })
    // }
  }

  public componentWillUnmount() {
    // this.props.setContext({
    //   paths: ['common'],
    //   innerPath: '',
    //   value: {}
    // })
    this.mounted = false;
  }

  public render() {
    return (
      <Page 
        className={cnNews({id: this.props.match.params.id})}
        page={{
          name: pageNames.news,
          params: {
            nId: this.props.match.params.id
          }
        }} />
    )
  }

}


export const News = connect(
  mapStateToProps,
  mapDispatchToProps({ ...mapActionsToProps })
)(compose()(NewsPresenter))