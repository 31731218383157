import React, { FC, useEffect, useState } from 'react'
import { cnCoursesList, mapDispatchToProps, mapStateToProps } from './CoursesList.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './CoursesList.scss'
import { Item } from './Item/Item'
import { Masonry, Button } from 'uielements/src';
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import { SearchInput } from 'uielements/src';
import queryString from 'query-string';
import { CreateCourseDialog } from '../../dialogs/CreateCourse/CreateCourse'
import ListPlaceholder from '../../components/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import CardList from './Card'
// import List from './List'

const LoadButton = withLoadSpin(Button)

export const CoursesListPresenter: FC<any> = ({
    className,
    loadCoursesByCurrentUser,
    results,
    isLoading,
    isFinished,
    history,
    location,
    user
}) => {
    const requestParams = queryString.parse(location.search)
    const [show, setShow] = useState(false)
    useEffect(() => {
        loadCoursesByCurrentUser({ ...requestParams }, true)
    }, [location.search])
    const loadMore = () => loadCoursesByCurrentUser(requestParams)
    // const triggerRef = useInfiniteScroll({
    //     loadMore,
    //     isLoading,
    //     isFinished,
    // });
    const onChange = (value: string) => {
        history.replace({
            ...history.location,
            search: queryString.stringify({ ...queryString.parse(history.location.search), search: value !== '' ? value : undefined })
        })
    }
    const onChangeArchive = (isArchived: boolean) => {
        history.replace({
            ...history.location,
            search: queryString.stringify({ ...queryString.parse(history.location.search), isArchived: isArchived ? true : undefined })
        })
    }
    return <div className={cnCoursesList({}, [className])}>
        {/* <CreateCourseDialog isShown={show} onClose={() => setShow(false)} author={user.baseData} /> */}
        <CardList
            results={results}
            loadMore={loadMore}
            onChangeSearch={onChange}
            setShowCreate={setShow}
            onChangeArchive={onChangeArchive}
            isArchived={requestParams.isArchived === 'true'}
            isFinished={isFinished}
            isLoading={isLoading}
            search={(requestParams.search) as (string | undefined)}
        />
    </div>
}

export const CoursesList = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CoursesListPresenter));
