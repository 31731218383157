import * as utils from "utils/src/utils";
import { v1 as uuid } from 'uuid';
import { IWidget, widgets, IColumn } from "i.widgets";
import { IResponseWithData, IListResponseWithData, IListShopResponse } from "utils/src/requests/models/api.base";
import { IStateType as IState } from 'redux/store';
import { IList } from "redux/reducers/Lists";
import { ListGroupsPresenter } from "blocks/List/_type/groups/List_type_groups";
import { ListGroupsTreePresenter } from "blocks/List/_type/groups/List_type_groups_tree";
import { ListUsersPresenter } from "blocks/List/_type/users/List_type_users";
import { ListBadgesPresenter } from "blocks/List/_type/badges/List_type_badges"
import { ListUsersLikersPresenter } from "blocks/List/_type/users/List_type_users_likers";
// import { ListUsersBadgePresenter } from "blocks/List/_type/users/List_type_users_badge";
import { ListUsersAnswerParticipantsPresenter } from "blocks/List/_type/users/List_type_users_answerParticipants";
import { getProducts } from "utils/src/requests/requests.shop";
import { ListShopPresenter } from "blocks/List/_type/shop/List_type_shop";
import { IShopItem, IShopOrder } from "utils/src/requests/models/api.shop";
import { ListOrdersPresenter } from "blocks/List/_type/shop/List_type_orders";
import { ListQuestsPresenter } from "blocks/List/_type/quests/List_type_quests_moderate";
import { ListQuestsPresenter as ListQuests } from "blocks/List/_type/quests/List_type_quests";
import { ListUsersLeaderboardPresenter } from 'blocks/List/_type/users/List_type_users_leaderboard';
import actions from 'redux/actionsTypes/Lists';
import { REDUSERS_NAMES } from 'utils/src/constants.prn';


export const listDataVariable = (list: IList) => list.hideData || list.filteredData.length || list.search ? 'filteredData' : 'data';

export interface IListActionsPayload<T> {
  id: string;
  data: T;
  [s: string]: any;
}

export const setDataToList = (payload: IListActionsPayload<any>) => ({
  type: actions.SET_DATA,
  payload
})

export const addDataToList = (payload: IListActionsPayload<any>) => ({
  type: actions.ADD_DATA,
  payload
})

export const removeDataFromList = (payload: IListActionsPayload<string[]>) => ({
  type: actions.REMOVE_DATA,
  payload
})

export const clearDataFromList = (payload: IListActionsPayload<any>) => ({
  type: actions.CLEAR_DATA,
  payload
})

export const killList = (payload: IListActionsPayload<undefined>) => ({
  type: actions.KILL,
  payload
})

export const checkListItem = (payload: IListActionsPayload<string>) => ({
  type: actions.CHECK_ITEM,
  payload
})

export const checkAllListItem = (payload: IListActionsPayload<any>) => ({
  type: actions.CHECK_ALL_ITEMS,
  payload
})

export const toggle = (payload: IListActionsPayload<{ isLoading?: boolean, isFinished?: boolean, clearData?: boolean, hideData?: boolean }>) => ({
  type: actions.TOGGLE,
  payload
})

export const setAdditionalData = (payload: IListActionsPayload<any>) => ({
  type: actions.SET_ADDITIONAL_DATA,
  payload
})

export const changeRootElCount = (payload: IListActionsPayload<number>) => ({
  type: actions.CHANGE_ROOT_EL_COUNT,
  payload
})

export const toggleIsFinished = (payload: IListActionsPayload<boolean>) => ({
  type: actions.TOGGLE,
  payload: {
    ...payload,
    variable: 'isFinished'
  }
})

export const toggleIsLoading = (payload: IListActionsPayload<boolean>) => ({
  type: actions.TOGGLE,
  payload: {
    ...payload,
    variable: 'isLoading'
  }
})

export const toggleClearData = (payload: IListActionsPayload<boolean>) => ({
  type: actions.TOGGLE,
  payload: {
    ...payload,
    variable: 'clearData'
  }
})

export const toggleHideData = (payload: IListActionsPayload<boolean>) => ({
  type: actions.TOGGLE,
  payload: {
    ...payload,
    variable: 'hideData'
  }
})

export const addSubitemsToList = (payload: IListActionsPayload<{ parentId: string, items: any[] }>) => ({
  type: actions.ADD_SUBITEMS,
  payload
})

export const removeSubitemsFromList = (payload: IListActionsPayload<string>) => ({
  type: actions.REMOVE_SUBITEMS,
  payload
})

export const changeListItem = (payload: IListActionsPayload<any>) => ({
  type: actions.CHANGE_ITEM,
  payload
})

export const clearSelectedListItems = (payload: IListActionsPayload<null>) => ({
  type: actions.CLEAR_SELECTED_ITEMS,
  payload
})

export const getListItem = (lId: string, id: string): any => (dispatch: any, getState: () => IState) => getState().lists[lId].items[id]

export function getGroups(this: ListGroupsPresenter, id: string, opts: any): any {
  return (dispatch: any, getState: () => IState) => {
    const listState = getState().lists[id];
    utils.API.groups.list(listState[listDataVariable(listState)].length, 20, opts)
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          d.data.forEach(this.prepareData);
          d.data.forEach((e: any) => e.disableChangeJoinStatus = d.disableChangeJoinStatus)
          dispatch(addDataToList({ id: this.listId, data: d.data }))
          dispatch(setAdditionalData({ id: this.listId, data: { count: d.totalCount } }));
          dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: d.isFinished } }));
        };
      });
  }
}

export function getGroupsTree(this: ListGroupsTreePresenter, id: string, aopts: any, opts: any): any {
  return (dispatch: any, getState: () => IState) => {
    const listState = getState().lists[id];
    opts.showtype = 'root';
    if (aopts.pkid) {
      opts.showtype = 'child';
      opts.selgroups = aopts.pkid;
    }
    if (!aopts.sublevel) aopts.sublevel = 1;
    opts.skipCount = opts.showtype === 'child' || listState.clearData ? 0 : listState.rootLevelElCounts || 0;
    opts.count = opts.showtype === 'child' ? 0 : 20;
    if (opts.groupListFilter) delete opts.groupListFilter;

    utils.API.groups.getGroupsTree(opts)
      .r
      .then((d) => {
        if (utils.checkResponseStatus(d)) {
          d.data.forEach((el: any) => {
            el.treeData = {
              parentGroupId: aopts.pkid,
              sublevel: aopts.sublevel
            }
            this.prepareData(el)
          });
          if (aopts.sublevel > 1) {
            dispatch(addSubitemsToList({ id: this.listId, data: { parentId: aopts.pkid, items: d.data } }));
            listState.items[aopts.pkid].tree_view.sublevelLoading = false;
            listState.items[aopts.pkid].tree_view.sublevelLoaded = true;
            this.prepareData(listState.items[aopts.pkid]);
            dispatch(changeListItem({ id: this.listId, data: { item: aopts.pkid, data: listState.items[aopts.pkid] } }));
          } else {
            dispatch(addDataToList({ id: this.listId, data: d.data }));
            dispatch(changeRootElCount({ id: this.listId, data: listState.rootLevelElCounts + opts.count }));
            // this.rootLevelElCounts += opts.count;
          }
          dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: aopts.pkid && aopts.sublevel === 1 ? true : d.isFinished } }));
        }
      });
  }
}

export function getUsers(this: ListUsersPresenter, id: string, opts: any): any {
  return (dispatch: any, getState: () => IState) => {
    const listState = getState().lists[id];
    opts.skipCount = listState[listDataVariable(listState)].length;
    utils.API.users.list(opts)
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          d.data.forEach(this.prepareData);
          dispatch(addDataToList({ id: this.listId, data: d.data }));
          dispatch(setAdditionalData({ id: this.listId, data: { count: d.companyUsersCount || d.totalUserCount } }));
          dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: d.isFinished } }));
        };
      });
  }
}

export function getRegistrationRequests(this: ListUsersPresenter, id: string, opts: any): any {
  return (dispatch: any, getState: () => IState) => {
    const listState = getState().lists[id];
    delete opts.count;
    delete opts.skipCount;
    const count = 20;
    utils.API.administration.registrations.getList(listState[listDataVariable(listState)].length, count, opts)
      // .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          d.data.forEach((e: any) => {
            let actionsIsNull = false
            if (!e.actions) {
              e.actions = [];
              actionsIsNull = true;
            }
            if (!actionsIsNull) {
              if (e.actions.includes('accessrequest.approve') && e.requestStatus === 'WatingMailApprove') e.actions.splice(e.actions.findIndex((el: any) => el === 'accessrequest.approve'), 1);
              if (!e.actions.includes('accessrequest.disapprove')) e.actions.push('accessrequest.disapprove');
            }
          });
          d.data.forEach(this.prepareData);
          dispatch(addDataToList({ id: this.listId, data: d.data }));
          dispatch(setAdditionalData({ id: this.listId, data: { count: d.companyUsersCount || d.totalUserCount } }));
          dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: d.data.length < count } }));
        };
      });
  }
}

export function getQuests(this: ListQuestsPresenter, id: string, opts: any): any {
  return (dispatch: any, getState: () => IState) => {
    const listState = getState().lists[id];
    opts.skipCount = listState[listDataVariable(listState)].length;
    utils.API.questsmoderate.list(opts)
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          d.data.forEach(this.prepareData);
          dispatch(addDataToList({ id: this.listId, data: d.data }));
          // TODO  d.isFinished
          dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: d.data.length === 0 } }));
        }
      });
  }
}

export function getQuestsList(this: ListQuests, id: string, opts: any): any {
  return (dispatch: any, getState: () => IState) => {
    const listState = getState().lists[id];
    const { count, skipCount, filter = 'all' } = opts;
    const uid = this.props.authUser.baseData.id;

    utils.API.quests.getQuestsListRequest(skipCount, count, { filter, uid })
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          // d.data.forEach(this.prepareData);
          dispatch(addDataToList({ id: this.listId, data: d.data }));
          dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: true || d.data.length === 0 } }));
        }
      });
  }
}

export function getLikers(this: ListUsersLikersPresenter, id: string, opts: any): any {
  return (dispatch: any, getState: () => IState) => {
    const listState = getState().lists[id];
    opts.skipCount = listState[listDataVariable(listState)].length;
    let api: any = utils.API.news;
    if (opts.rId) api = utils.API.reply;
    api.likers(opts)
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          d.data.forEach(this.prepareData);
          dispatch(addDataToList({ id: this.listId, data: d.data }))
          dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: d.isFinished } }));
        };
      });
  }
}

// export function getBadgeUsers(this: ListUsersBadgePresenter, id: string, opts: any): any {
//   return (dispatch: any, getState: () => IState) => {
//     const listState = getState().lists[id];
//     opts.skipCount = listState[listDataVariable(listState)].length;
//     utils.API.badges.usersList(opts)
//       .r
//       .then((d: any) => {
//         if (utils.checkResponseStatus(d)) {
//           d.data.forEach(this.prepareData);
//           dispatch(addDataToList({ id: this.listId, data: d.data }))
//           dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: d.isFinished } }));
//         };
//       });
//   }
// }

export function getPollAnswerParticipants(this: ListUsersAnswerParticipantsPresenter, id: string, opts: any): any {
  return (dispatch: any, getState: () => IState) => {
    const listState = getState().lists[id];
    opts.skipCount = listState[listDataVariable(listState)].length;
    utils.API.news.getPollAnswerParticipants(opts)
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          d.data.forEach(this.prepareData);
          dispatch(addDataToList({ id: this.listId, data: d.data }))
          dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: d.isFinished } }));
        };
      });
  }
}
export function getEventParticipants(this: ListUsersAnswerParticipantsPresenter, id: string, opts: any): any {
  return (dispatch: any, getState: () => IState) => {
    const listState = getState().lists[id];
    opts.skipCount = listState[listDataVariable(listState)].length;
    utils.API.news.getEventPartipants(opts.nId, opts.status, { ...opts, nId: undefined, status: undefined })
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          d.data.forEach(this.prepareData);
          dispatch(addDataToList({ id: this.listId, data: d.data }))
          dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: d.isFinished } }));
        };
      });
  }
}

let shopProductsRequest: ReturnType<typeof utils.API.shop.getProducts> | undefined;
export function getShopProducts(this: ListShopPresenter, id: string, opts: any) {
  return (dispatch: any, getState: () => IState) => {
    const listState = getState().lists[id];
    opts.skipCount = listState[listDataVariable(listState)].length;
    if (shopProductsRequest) {
      shopProductsRequest.ac.abort();
    }
    shopProductsRequest = utils.API.shop.getProducts(opts);
    shopProductsRequest?.r
      .then((d) => {
        if (utils.checkResponseStatus(d)) {
          dispatch(addDataToList({ id: this.listId, data: d.data }))
          dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: d.isFinished } }));
          dispatch(setAdditionalData({
            id: this.id,
            data: {
              isClosed: d.isShopClosed,
              closedText: d.shopClosedText,
              filters: d.filters,
              isHidePricesAndButtonBuy: d.isHidePricesAndButtonBuy,
              isHideButtonBuy: d.isHideButtonBuy,
              ordersCount: d.ordersCount,
              maxOrdersCount: d.maxOrdersCount,
              maxOrdersPeriod: d.maxOrdersPeriod,
              additionalInformationBeforeOrder: d.additionalInformationBeforeOrder,
            }
          }));
        }
        // shopProductsRequest = undefined;
      })
  }
}

// export function getShopProducts(this: ListShopPresenter, id: string, opts: any) {
//   return (dispatch: any, getState: () => IState) => {
//     const listState = getState().lists[id];
//     opts.skipCount = listState[listDataVariable(listState)].length;
//     utils.API.shop.getProducts(opts)
//       .r
//       .then((d) => {
//         if (utils.checkResponseStatus(d)) {
//           dispatch(addDataToList({ id: this.listId, data: d.data }))
//           dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: d.isFinished } }));
//           dispatch(setAdditionalData({ 
//             id: this.id, 
//             data: { 
//               isClosed: d.isShopClosed, 
//               closedText: d.shopClosedText, 
//               filters: d.filters, 
//               isHidePricesAndButtonBuy: d.isHidePricesAndButtonBuy,
//               isHideButtonBuy: d.isHideButtonBuy,
//               ordersCount: d.ordersCount, 
//               maxOrdersCount: d.maxOrdersCount, 
//               maxOrdersPeriod: d.maxOrdersPeriod 
//             } 
//           }));
//         }
//       })
//   }
// }

export function getOrders(this: ListOrdersPresenter, id: string, opts: any) {
  return (dispatch: any, getState: () => IState) => {
    const listState = getState().lists[id];
    opts.skipCount = listState[listDataVariable(listState)].length;
    utils.API.shop.getOrders(opts).r.then((response) => {
      if (!this.mounted) return;
      if (utils.checkResponseStatus(response)) {
        response.data.forEach(this.prepareData);
        dispatch(addDataToList({ id, data: response.data }))
        dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: response.isFinished } }));
      }
    });
  }
}

export function getRatingUsers(this: ListUsersLeaderboardPresenter, id: string, opts: any) {
  return (dispatch: any, getState: () => IState) => {
    const listState = getState().lists[id];
    opts.skipCount = listState[listDataVariable(listState)].length;
    utils.API.ratings.leaderboard(opts).r.then((response: IListResponseWithData<{ [s: string]: any }>) => {
      if (!this.mounted) return;
      if (utils.checkResponseStatus(response)) {
        // response.data.forEach(this.prepareData);
        dispatch(setAdditionalData({ id: this.id, data: { rating: response.data.rating, currentUser: response.data.myRatingValue ? response.data.myRatingValue : (listState.additionalParams ? listState.additionalParams.currentUser : undefined) } }));
        dispatch(addDataToList({ id, data: response.data.users }))
        dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: response.isFinished } }));
      }
    });
  }
}

export function getBadges(this: ListBadgesPresenter, id: string, opts: any): any {
  return (dispatch: any, getState: () => IState) => {
    const listState = getState().lists[id];
    opts.skipCount = listState[listDataVariable(listState)].length;
    utils.API.badges.getBadges(opts)
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          const data = d.data.map((item: any) => {
            return {
              id: item.badgeUid,
              name: item.name,
              description: item.description,
              imgUrl: item.iconUrl,
            }
          })
          data.forEach(this.prepareData);
          dispatch(addDataToList({ id: this.listId, data: data }))
          dispatch(toggle({ id: this.listId, data: { isLoading: false, clearData: false, isFinished: d.isFinished } }));
        };
      });
  }
}

