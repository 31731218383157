import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { moveFile } from 'redux/actions/Files'

export interface IMoveFilesProps extends IClassNameProps, IMoveFilesStateProps, IDispatchProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
}

export interface IMoveFilesState {

}

export interface IMoveFilesStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    currentFolder: state.files.moveDialog.folders.find((f: any) => f.id === state.files.moveDialog.currentFolder),
    selectedCount: state.files.groups[props.gid] ? state.files.groups[props.gid].selected.length : 0
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    moveFile
}, dispatch);

export const cnMoveFiles = cn('MoveFiles');