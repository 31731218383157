import React, { useMemo } from 'react'
import { Button } from 'muicomponents/src'
import { FakeBtnBox, FilterBtnText, ItemBox } from '../Filters_type_shopMui.styled'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { i18n } from 'localization';
import { IStateType } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setShopFilters } from 'redux/shop/actions';
import { cnFiltersMui } from '../Filters_type_shopMui.index';
import Badge from '@mui/material/Badge';

const iconStyle = { mr: 1, flexShrink: 1, }

const AddToFavourite = () => {

    const dispatch = useDispatch()

    const areFavouritesChosen = useSelector((s: IStateType) => s.shop.chosenFilters.MyFavorite)
    const myFavouritesCount = useSelector((s: IStateType) => s.shop.categoryProducts.myFavoritesCount)
    const showMyFavouritesCount = useMemo(() => !!myFavouritesCount, [myFavouritesCount]);

    const handleChange = () => {
        dispatch(setShopFilters({
            key: 'MyFavorite',
            value: !areFavouritesChosen
        }))
    }

    return (
        <ItemBox>
            <Badge
                badgeContent=" "
                invisible={!areFavouritesChosen}
                color="error"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}>
                <Button variant='outlined' size='small'
                    className={cnFiltersMui('favouritesButton')}
                    onClick={handleChange}
                    startIcon={areFavouritesChosen ?
                        <BookmarkIcon sx={iconStyle} htmlColor='GrayText' />
                        : <BookmarkBorderIcon sx={iconStyle} htmlColor='GrayText' />
                    }
                >


                    <FakeBtnBox>
                        <FilterBtnText color='ActiveCaption' noWrap variant='body2'>
                            {i18n.t("pryaniky.filter.feed.favorites")}
                            {
                                showMyFavouritesCount
                                &&
                                <>
                                    &nbsp;
                                    {showMyFavouritesCount && myFavouritesCount}
                                </>
                            }
                        </FilterBtnText>
                    </FakeBtnBox>
                </Button>
            </Badge>
        </ItemBox>
    )
}

export default AddToFavourite