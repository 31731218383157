import React from 'react';


import { cnWidget, cnWidgetMui, IWidgetProps } from '../Widget.index';

import './Widget_type_quests.scss';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { SurveyWidget } from 'blocks/SurveyWidget/SurveyWidget'
import { IWidgetTypeSurveyProps } from './Widget_type_survey.index'

const type = 'survey';

export default class WidgetTypeQuizPresenter extends WidgetPresenter<IWidgetTypeSurveyProps> {

  public render() {
    if (!this.props.widget) return null
    const subtype = utils.widgetSubtype(this.props.widget.type);
    return <SurveyWidget {...this.props} className={cnWidgetMui({type, subtype})} settings={this.props.widget.settings} tag={widgets.components.common} />
  }

}