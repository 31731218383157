import { Box, styled } from "muicomponents/src";
import MenuItem from '@mui/material/MenuItem';


export const StyledMenuItem = styled(MenuItem)({
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between'
}) as typeof MenuItem

/* export const CharItemBox = styled(Box)({
    paddingTop: '12px',
    '&:not(:first-child)': {

    },
}) as typeof Box

export const TagsBox = styled(Box)({
    //border: '3px solid pink',
    maxHeight: '272px',
    overflowY: 'auto',
    width: '560px',
}) as typeof Box */



