import * as React from 'react';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { withBemMod } from '@bem-react/core';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { Layout } from '../../Layout/InfoBlock-Layout';
import { cnGroup, cnInfo } from '../InfoBlock-Group.index';
import { IGroupEditFalseProps } from './InfoBlock-Group_edit_false.index';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import { Button } from 'uielements/src/Button/Button';
import Button from '@material-ui/core/Button';
import './InfoBlock-Group_edit_false.scss';
import { Icon, Button as PryanikyButton, SplitButton } from 'uielements/src';
import i18n from 'localizations/i18n';
import { toast } from 'react-toastify';
import { ApproveDialog } from '../ApproveDialog/ApproveDialog';
import { ButtonBox } from 'uielements/src'
import { Translate } from 'localizations/Translate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionBtn: {
      width: '100%',
      marginTop: 8,
    },
  })
);


// IGroupEditFalseProps
export const withGroupEditFalse = withBemMod<any>(
  cnGroup(),
  { edit: 'false' },
  Presenter => ({ data, ...props }: IGroupEditFalseProps) => {
    const classes = useStyles();
    const [showDialog, setShowDialog] = React.useState(false);

    const onCloseDialog = () => setShowDialog(false);
    const openDialog = () => setShowDialog(true);

    const handleAction = () => {
      if (data.isMeInGroup) {
        utilsProj.confirm({
          text: i18n.t('pryaniky.confirm.leaveGroup', { name: data.name }),
          onConfirm: makeJoinReqeust,
        });
      } else {
        makeJoinReqeust();
      }
    };
    const makeJoinReqeust = () => {
      const cloneData: any = utils.cloneObject(data || {});
      let join = false;
      if (cloneData.visibilityType === 1) {
        cloneData.isRequestAccessSent = !cloneData.isRequestAccessSent;
        join = cloneData.isRequestAccessSent;
        if (props.store.authUser.baseData.isAdmin) {
          cloneData.isMeInGroup = cloneData.isRequestAccessSent;
          join = cloneData.isMeInGroup;
        }
      } else {
        cloneData.isMeInGroup = !cloneData.isMeInGroup;
        join = cloneData.isMeInGroup;
      }
      props.setData(cloneData);
      // utils.API.groups.action('join', data.pkid, { join: !data.isMeInGroup }).r.then((d: any) => {
      // const join = (cloneData.visibilityType === 1 && props.store.authUser.baseData.isAdmin) ? !data.isMeInGroup : !cloneData.isRequestAccessSent
      utils.API.groups.join(data.pkid, props.store.authUser.baseData.id, join).r.then((d: any) => {
        if (!d) toast.error(i18n.t('pryaniky.toast.error.server'));
        else if (d.error_code !== 0) toast.error(d.error_text);
        /*else if (cloneData.visibilityType === 1) {
          cloneData.isRequestAccessSent = !cloneData.isRequestAccessSent;
          if (props.store.authUser.baseData.isAdmin) cloneData.isMeInGroup = cloneData.isRequestAccessSent;
        } else {
          cloneData.isMeInGroup = !cloneData.isMeInGroup;
        }*/
        // props.setData(cloneData);
      });
    };

    const nitifiAction = (template: "all" | "common" | "none") => () => {
      const cloneData: any = utils.cloneObject(data || {});
      cloneData.selectedNotifyTemplate = template;
      props.setData(cloneData);
      utils.API.notifications.settings.setGroupTemplate(data.pkid, props.store.authUser.baseData.id, template).r.then((d: any) => {
        toast.success(i18n.t('pryaniky.notifications.settings.template.toast.' + template))
      })
    }

    return (
      <Presenter {...props} data={data}>
        {(d: any) => {
          let actionButtonText = i18n.t('pryaniky.group.actions.join');
          const notifiOptions: any[] = [
            {
              title: i18n.t('pryaniky.notifications.settings.template.none'),//'Отключить все',
              buttonProps: { onClick: nitifiAction('none') },
              isActive: d.selectedNotifyTemplate === 'none'
            }, {
              title: i18n.t('pryaniky.notifications.settings.template.common'),//'Как в ленте',
              buttonProps: { onClick: nitifiAction('common') },
              isActive: d.selectedNotifyTemplate === 'common'
            }, {
              title: i18n.t('pryaniky.notifications.settings.template.all'),//'Включить все',
              buttonProps: { onClick: nitifiAction('all') },
              isActive: d.selectedNotifyTemplate === 'all'
            }
          ];
          if (!props.store.authUser.baseData.isAdmin && d.visibilityType === 1)
            actionButtonText = i18n.t(
              'pryaniky.group.actions.' + (d.isRequestAccessSent ? 'requestSent' : 'sendRequest')
            );
          if (d.isMeInGroup) actionButtonText = i18n.t('pryaniky.group.actions.leave');
          return (
            <Layout
              /* editBtn={
                data.isGroupAdmin && (
                  <Button id="toggle-group-edit" onClick={props.togleEdit}>
                    <Icon icon={'edit'} />
                  </Button>
                )
              } */
              left={
                <div className={'InfoBlock-Img'}>
                  <Avatar
                    className={cnGroup('Img')}
                    imgUrl={!utils.isEmptyGuid(d.imgId198x198) ? d.imgUrl : undefined}
                    name={d.name}
                    size={180}
                    noBorderRadius
                  />
                </div>
              }
              right={
                <div className={cnGroup('Data')}>
                  <div className={cnInfo('DataName')}>{d.name}</div>
                  {data.isOfficialGroup && (
                    <h5 className={cnGroup('Label', ['my-0'])}>{i18n.t('pryaniky.group.official')}</h5>
                  )}
                  <div className={cnGroup('DataDescription', ['my-1']) + ' ' + cnInfo('GrayText')}>{d.description}</div>
                  <div className={cnGroup('Actions')}>
                  {
                    !data.disableChangeJoinStatus &&
                    <SplitButton button={
                      <>
                        {!data.isOfficialGroup && (
                          <PryanikyButton
                            tag={'a'}
                            theme={'unstyled_center'}
                            id="group-action-btn"
                            onClick={handleAction}
                            disabled={d.visibilityType === 1 && d.isReqeustAccessSent}>
                            {actionButtonText}
                          </PryanikyButton>)}

                        {(data.requestsCount > 0 && data.isGroupAdmin) &&
                          <>
                            <PryanikyButton tag={'a'} theme={'unstyled_center'} onClick={openDialog}>
                              <Translate i18nKey='pryaniky.group.settings.joinRequests' /> ({data.requestsCount})
                            </PryanikyButton>
                            <ApproveDialog onCloseDialog={onCloseDialog} showDialog={showDialog} pkid={data.pkid} />
                          </>}

                        {d.isMeInGroup && 
                        <PryanikyButton
                          theme={'unstyled_center'}
                          type={'rlink'}
                          href={`/notifications/settings?gid=${data.pkid}&section=groups`}
                        >
                          <Translate i18nKey='pryaniky.group.settings.notify' />
                        </PryanikyButton>}

                      </>}
                    options={d.isMeInGroup ? notifiOptions : []} />
                  }

                  </div>
                  {
                    !d.isMeInGroup && data.visibilityType === 1 &&
                    <div className={cnGroup('InfoText')}>
                      {
                        data.isOfficialGroup ?
                        i18n.t('pryaniky.group.info.isOfficial') :
                        i18n.t('pryaniky.group.info.isClosed')
                      }
                    </div>
                  }

                </div>
              }
            />
          );
        }}
      </Presenter>
    );
  }
);
