import { Box, styled } from "muicomponents/src";
import { Avatar } from "muicomponents/src/Avatar";


export const StyledAvatar = styled(Avatar)({
    borderRadius: '4px 4px 0 0',
    height: '158px', // в макете
    //height: '255px',  // для objectFit: 'contain' в .MuiAvatar-img и квадратности изображения
    width: '100%',
    '.MuiAvatar-root>img': {
        display: 'block',
        minWidth: '100%',
        minHeight: '100%',
        //backgroundSize: '70%',
    },
    '.MuiAvatar-img': {
        minWidth: '100%',
        minHeight: '100%',
        objectFit: 'cover',        
        //objectFit: 'contain',        
    },
    '& img': {
        //border: '1px solid red',

    }
}) as typeof Avatar


export const AvailableBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0',
    //border: '1px solid green',

}) as typeof Box

export const StarBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
    //border: '1px solid green',

}) as typeof Box