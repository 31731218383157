export const response = {
	"error_code": 0,
	"error_text": "OK",
	"data": {
		"showExpertiseButton": false,
		"isExpertEvaluation": false,
		"expertises": [
			{
				"isCompleted": false,
				"expStatus": "completed",
				"timestamp": "2023-06-20T12:21:06.061Z",
				"value": 0,
				"user": {
					"status": "NotSet",
					"alias": "iskander",
					"position": "",
					"positionTags": null,
					"division": "Тестирую оргстр",
					"divisionTags": null,
					"phone": "+7(213)2132131",
					"eMail": "ia@pryaniky.ru",
					"isFirstLogon": false,
					"imgUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/54a53a6c-9e45-40d4-9ce8-8caf0a0a14c1.png?v=3565c223-00f0-4241-87a0-73e875d7dba2",
					"isMan": true,
					"isDeleted": false,
					"isAdmin": false,
					"birthday": "1980-06-15T00:00:00.000Z",
					"baseNetwork": {
						"id": "4b923f14-0aa0-4f27-9baf-90a1d625462c",
						"name": "ideasperi",
						"title": "IDEASPERI",
						"isHidden": false
					},
					"statusText": "",
					"sex": "m",
					"userPhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/96m96/54a53a6c-9e45-40d4-9ce8-8caf0a0a14c1.png?v=3565c223-00f0-4241-87a0-73e875d7dba2",
					"userLargePhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/54a53a6c-9e45-40d4-9ce8-8caf0a0a14c1.png?v=3565c223-00f0-4241-87a0-73e875d7dba2",
					"id": "54a53a6c-9e45-40d4-9ce8-8caf0a0a14c1",
					"displayName": "Искандер",
					"type": "user",
					"imgId": "3565c223-00f0-4241-87a0-73e875d7dba2"
				},
				"field": null
			},
			{
				"isCompleted": false,
				"expStatus": "completed",
				"timestamp": "2023-06-20T12:21:06.094Z",
				"value": 0,
				"user": {
					"status": "NotSet",
					"alias": "petr.petrov",
					"position": "",
					"positionTags": null,
					"division": "НАЧАЛЬНИКИ",
					"divisionTags": null,
					"phone": "",
					"eMail": "at@pryaniky.ru",
					"isFirstLogon": false,
					"imgUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/9c9e8d06-e39d-48c9-8126-8dcafecc07f0.png?v=d34030cd-119e-4021-9b58-ba5292f57e98",
					"isMan": true,
					"isDeleted": false,
					"isAdmin": false,
					"birthday": "2023-06-15T00:00:00.000Z",
					"baseNetwork": {
						"id": "4b923f14-0aa0-4f27-9baf-90a1d625462c",
						"name": "ideasperi",
						"title": "IDEASPERI",
						"isHidden": false
					},
					"statusText": "",
					"sex": "m",
					"userPhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/96m96/9c9e8d06-e39d-48c9-8126-8dcafecc07f0.png?v=d34030cd-119e-4021-9b58-ba5292f57e98",
					"userLargePhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/9c9e8d06-e39d-48c9-8126-8dcafecc07f0.png?v=d34030cd-119e-4021-9b58-ba5292f57e98",
					"id": "9c9e8d06-e39d-48c9-8126-8dcafecc07f0",
					"displayName": "Петр Петрович Петров",
					"type": "user",
					"imgId": "d34030cd-119e-4021-9b58-ba5292f57e98"
				},
				"field": null
			},
			{
				"isCompleted": false,
				"expStatus": "",
				"timestamp": "2023-06-20T12:21:06.125Z",
				"value": 0,
				"user": {
					"status": "NotSet",
					"alias": "ppetrova",
					"position": "Бухгалтер по оплате счетов",
					"positionTags": null,
					"division": "отдел3",
					"divisionTags": null,
					"phone": "",
					"eMail": "at+wq1@pryaniky.ru",
					"isFirstLogon": false,
					"imgUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/a048d21e-190f-41f9-a0e5-7bac74ff44dc.png?v=edd1ccef-9200-4893-bc90-a2208d008672",
					"isMan": false,
					"isDeleted": false,
					"isAdmin": false,
					"birthday": "2023-06-15T00:00:00.000Z",
					"baseNetwork": {
						"id": "4b923f14-0aa0-4f27-9baf-90a1d625462c",
						"name": "ideasperi",
						"title": "IDEASPERI",
						"isHidden": false
					},
					"statusText": "",
					"sex": "f",
					"userPhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/96m96/a048d21e-190f-41f9-a0e5-7bac74ff44dc.png?v=edd1ccef-9200-4893-bc90-a2208d008672",
					"userLargePhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/a048d21e-190f-41f9-a0e5-7bac74ff44dc.png?v=edd1ccef-9200-4893-bc90-a2208d008672",
					"id": "a048d21e-190f-41f9-a0e5-7bac74ff44dc",
					"displayName": "Петра Петровна Петрова",
					"type": "user",
					"imgId": "edd1ccef-9200-4893-bc90-a2208d008672"
				},
				"field": null
			},
			{
				"isCompleted": false,
				"expStatus": "completed",
				"timestamp": "2023-06-20T15:44:18.259Z",
				"value": 0,
				"user": {
					"status": "NotSet",
					"alias": "km",
					"position": "HR",
					"positionTags": null,
					"division": "Отдел по обслуживанию ключевых клиентов",
					"divisionTags": null,
					"phone": "+7(999)8887766",
					"eMail": "km@pryaniky.ru",
					"isFirstLogon": false,
					"imgUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/512cfceb-ece9-44eb-bbbe-ebb4f521ec11.png?v=707d96bd-855d-48a2-98bb-41e0d7b3d5b2",
					"isMan": false,
					"isDeleted": false,
					"isAdmin": false,
					"birthday": "2001-06-15T00:00:00.000Z",
					"baseNetwork": {
						"id": "4b923f14-0aa0-4f27-9baf-90a1d625462c",
						"name": "ideasperi",
						"title": "IDEASPERI",
						"isHidden": false
					},
					"statusText": "я крут",
					"sex": "f",
					"userPhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/96m96/512cfceb-ece9-44eb-bbbe-ebb4f521ec11.png?v=707d96bd-855d-48a2-98bb-41e0d7b3d5b2",
					"userLargePhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/512cfceb-ece9-44eb-bbbe-ebb4f521ec11.png?v=707d96bd-855d-48a2-98bb-41e0d7b3d5b2",
					"id": "512cfceb-ece9-44eb-bbbe-ebb4f521ec11",
					"displayName": "Karina Админовна Admin",
					"type": "user",
					"imgId": "707d96bd-855d-48a2-98bb-41e0d7b3d5b2"
				},
				"field": null
			}
		],
		"fields": [
			{
				"category": "",
				"fields": [
					{
						"field": {
							"id": "977caad0-d452-11ed-aff0-6bb67b74da62",
							"propertyName": null,
							"name": "Тип выбрать ответственного за этап",
							"description": "",
							"fieldType": "ResponsibleForStatusUser",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 7,
							"defaultValue": null,
							"mask": null,
							"category": "",
							"displayMode": "",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [],
							"rows": []
						},
						"expertAnswers": [
							[
								{
									"status": "NotSet",
									"alias": "begemot",
									"position": "",
									"positionTags": null,
									"division": "Обособленное подразделение в г. Нижнем Новгороде",
									"divisionTags": null,
									"phone": "",
									"eMail": "als+hippo@pryaniky.ru",
									"isFirstLogon": false,
									"imgUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"isMan": false,
									"isDeleted": false,
									"isAdmin": false,
									"birthday": "2023-07-03T00:00:00.000Z",
									"baseNetwork": {
										"id": "4b923f14-0aa0-4f27-9baf-90a1d625462c",
										"name": "ideasperi",
										"title": "IDEASPERI",
										"isHidden": false
									},
									"statusText": "",
									"sex": "f",
									"userPhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/96m96/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"userLargePhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"id": "418984d2-20cd-431f-a9cd-5c8564bc478d",
									"displayName": "Бегемот",
									"type": "user",
									"imgId": "586c7b04-df20-4d05-ac15-0cf798e7175f"
								}
							],
							[
								{
									"status": "NotSet",
									"alias": "begemot",
									"position": "",
									"positionTags": null,
									"division": "Обособленное подразделение в г. Нижнем Новгороде",
									"divisionTags": null,
									"phone": "",
									"eMail": "als+hippo@pryaniky.ru",
									"isFirstLogon": false,
									"imgUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"isMan": false,
									"isDeleted": false,
									"isAdmin": false,
									"birthday": "2023-07-03T00:00:00.000Z",
									"baseNetwork": {
										"id": "4b923f14-0aa0-4f27-9baf-90a1d625462c",
										"name": "ideasperi",
										"title": "IDEASPERI",
										"isHidden": false
									},
									"statusText": "",
									"sex": "f",
									"userPhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/96m96/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"userLargePhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"id": "418984d2-20cd-431f-a9cd-5c8564bc478d",
									"displayName": "Бегемот",
									"type": "user",
									"imgId": "586c7b04-df20-4d05-ac15-0cf798e7175f"
								}
							],
							[],
							[
								{
									"status": "NotSet",
									"alias": "iskander",
									"position": "",
									"positionTags": null,
									"division": "",
									"divisionTags": null,
									"phone": "",
									"eMail": "ia@pryaniky.ru",
									"isFirstLogon": false,
									"imgUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/NoPhoto/250m250/d88013a8-2d38-43ba-8b78-9d2220d555b4.png?v=00000000-0000-0000-0000-000000000000",
									"isMan": true,
									"isDeleted": false,
									"isAdmin": false,
									"birthday": "1900-07-03T00:00:00.000Z",
									"baseNetwork": {
										"id": "4b923f14-0aa0-4f27-9baf-90a1d625462c",
										"name": "ideasperi",
										"title": "IDEASPERI",
										"isHidden": false
									},
									"statusText": "все ок",
									"sex": "m",
									"userPhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/NoPhoto/96m96/d88013a8-2d38-43ba-8b78-9d2220d555b4.png",
									"userLargePhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/NoPhoto/250m250/d88013a8-2d38-43ba-8b78-9d2220d555b4.png",
									"id": "d88013a8-2d38-43ba-8b78-9d2220d555b4",
									"displayName": "Искандер",
									"type": "user",
									"imgId": "00000000-0000-0000-0000-000000000000"
								},
								{
									"status": "NotSet",
									"alias": "begemot",
									"position": "",
									"positionTags": null,
									"division": "Обособленное подразделение в г. Нижнем Новгороде",
									"divisionTags": null,
									"phone": "",
									"eMail": "als+hippo@pryaniky.ru",
									"isFirstLogon": false,
									"imgUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"isMan": false,
									"isDeleted": false,
									"isAdmin": false,
									"birthday": "2023-07-03T00:00:00.000Z",
									"baseNetwork": {
										"id": "4b923f14-0aa0-4f27-9baf-90a1d625462c",
										"name": "ideasperi",
										"title": "IDEASPERI",
										"isHidden": false
									},
									"statusText": "",
									"sex": "f",
									"userPhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/96m96/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"userLargePhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"id": "418984d2-20cd-431f-a9cd-5c8564bc478d",
									"displayName": "Бегемот",
									"type": "user",
									"imgId": "586c7b04-df20-4d05-ac15-0cf798e7175f"
								}
							]
						]
					},
					{
						"field": {
							"id": "c7b55530-d452-11ed-aff0-6bb67b74da62",
							"propertyName": null,
							"name": "Число",
							"description": "",
							"fieldType": "Int",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 8,
							"defaultValue": null,
							"mask": null,
							"category": "",
							"displayMode": "",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [],
							"rows": []
						},
						"expertAnswers": [
							[
								"123123"
							],
							[
								"123123123"
							],
							[
								null
							],
							[
								""
							]
						]
					},
					{
						"field": {
							"id": "d1033ad0-d452-11ed-aff0-6bb67b74da62",
							"propertyName": null,
							"name": "Дата время",
							"description": "",
							"fieldType": "DateTime",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 9,
							"defaultValue": null,
							"mask": null,
							"category": "",
							"displayMode": "",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [],
							"rows": []
						},
						"expertAnswers": [
							[
								"2023-06-21T10:04:00.000Z"
							],
							[
                                "2023-06-21T10:04:00.000Z"
							],
							[
								null
							],
							[
								""
							]
						]
					},
					{
						"field": {
							"id": "db7bbdc0-d452-11ed-aff0-6bb67b74da62",
							"propertyName": null,
							"name": "Флаг обычный",
							"description": "",
							"fieldType": "Bool",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 10,
							"defaultValue": null,
							"mask": null,
							"category": "",
							"displayMode": "",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [],
							"rows": []
						},
						"expertAnswers": [
							[
								"false"
							],
							[
								"true"
							],
							[
								null
							],
							[
								"false"
							]
						]
					}
				]
			},
			{
				"category": "Причина отклонения идеи",
				"fields": [
					{
						"field": {
							"id": "357be3c9-d236-4f26-986c-5b4a0ef25588",
							"propertyName": null,
							"name": "Я отклоняю эту идею, потому что...",
							"description": "",
							"fieldType": "Single",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 1,
							"defaultValue": null,
							"mask": null,
							"category": "Причина отклонения идеи",
							"displayMode": "value",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [
								{
									"id": "81113965-46da-40aa-9da1-b938f2026324",
									"text": "Уже реализована",
									"description": "",
									"position": 0,
									"selected": false,
									"rowId": null,
									"scoringValue": 0.00
								},
								{
									"id": "6f16a254-1b77-4f86-b4b2-0d1a7808709d",
									"text": "Уже в работе",
									"description": "",
									"position": 1,
									"selected": false,
									"rowId": null,
									"scoringValue": 0.00
								},
								{
									"id": "b1fe1665-e7f5-4137-b39d-a0d4a0a78dd1",
									"text": "Противоречит законодательству, внутренним требованиям, ценностям банка",
									"description": "",
									"position": 2,
									"selected": false,
									"rowId": null,
									"scoringValue": 0.00
								}
							],
							"rows": []
						},
						"expertAnswers": [
							[
								"True",
								"False",
								"False"
							],
							[
								"False",
								"False",
								"False"
							],
							[
								"False",
								"False",
								"False"
							],
							[
								"False",
								"False",
								"False"
							]
						]
					},
					{
						"field": {
							"id": "8839230d-0eba-4932-a883-09cfa699aa31",
							"propertyName": null,
							"name": "Комментарий",
							"description": "",
							"fieldType": "Textarea",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 2,
							"defaultValue": null,
							"mask": null,
							"category": "Причина отклонения идеи",
							"displayMode": "",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [],
							"rows": []
						},
						"expertAnswers": [
							[
								"123123123"
							],
							[
								"12312323"
							],
							[
								null
							],
							[
								""
							]
						]
					}
				]
			},
			{
				"category": "Оценка полезности",
				"fields": [
					{
						"field": {
							"id": "aa97df42-df32-4ebf-88f3-9889a8b3d9e3",
							"propertyName": null,
							"name": "Оценка полезности",
							"description": "",
							"fieldType": "MatrixOnePerRow",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 3,
							"defaultValue": null,
							"mask": null,
							"category": "Оценка полезности",
							"displayMode": "value",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [
								{
									"id": "34af0781-53c7-46ff-9e95-12416e7d5ee3",
									"text": "0 (нет)",
									"description": "",
									"position": 0,
									"selected": false,
									"rowId": null,
									"scoringValue": 0.00
								},
								{
									"id": "db3a9401-951d-401b-a590-00989503fbfe",
									"text": "1 (незначительное)",
									"description": "",
									"position": 1,
									"selected": false,
									"rowId": null,
									"scoringValue": 0.00
								},
								{
									"id": "8965b4e0-69ef-4aa6-918c-7fdaaf4434c0",
									"text": "2 (значимое)",
									"description": "",
									"position": 2,
									"selected": false,
									"rowId": null,
									"scoringValue": 0.00
								}
							],
							"rows": [
								{
									"id": "d10754e2-2e71-470c-a0ef-ab52217f8529",
									"name": "Финансовое влияние",
									"selectedAnswer": "00000000-0000-0000-0000-000000000000",
									"avg": 2.0
								},
								{
									"id": "b1585e89-a9c9-445d-ae3a-607904c255df",
									"name": "Увеличение клиентской базы",
									"selectedAnswer": "00000000-0000-0000-0000-000000000000",
									"avg": 2.0
								},
								{
									"id": "419d687b-6837-4138-87a3-5fca05f3774a",
									"name": "Положительное влияние на клиентов",
									"selectedAnswer": "00000000-0000-0000-0000-000000000000",
									"avg": 2.0
								},
								{
									"id": "6124513d-d889-4582-aaed-597cbf44459c",
									"name": "Положительное влияние на сотрудников",
									"selectedAnswer": "00000000-0000-0000-0000-000000000000",
									"avg": 2.0
								}
							]
						},
						"expertAnswers": [
							[
								"2",
								"2",
								"-",
								"-",
								"2"
							],
							[
								"2",
								"2",
								"-",
								"-",
								"2"
							],
							[
								"2",
								"2",
								"-",
								"-",
								"2"
							],
							[
								"2",
								"2",
								"-",
								"-",
								"2"
							]
						]
					},
					{
						"field": {
							"id": "aaf9d38c-df22-41fd-bc33-fca3994d9e69",
							"propertyName": null,
							"name": "Комментарий",
							"description": "",
							"fieldType": "Textarea",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 4,
							"defaultValue": null,
							"mask": null,
							"category": "Оценка полезности",
							"displayMode": "",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [],
							"rows": []
						},
						"expertAnswers": [
							[
								"123123"
							],
							[
								""
							],
							[
								null
							],
							[
								""
							]
						]
					}
				]
			},
			{
				"category": "Оценка усилий",
				"fields": [
					{
						"field": {
							"id": "afadfdf8-62e1-452c-b074-7280a636d38a",
							"propertyName": null,
							"name": "Оценка усилий",
							"description": "",
							"fieldType": "MatrixOnePerRow",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 5,
							"defaultValue": null,
							"mask": null,
							"category": "Оценка усилий",
							"displayMode": "value",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [
								{
									"id": "fd32e847-fe81-45ca-91c8-f401cf5277a4",
									"text": "0 (нет)",
									"description": "",
									"position": 0,
									"selected": false,
									"rowId": null,
									"scoringValue": 0.00
								},
								{
									"id": "4f03a154-a37a-4400-8047-2df1a62a8a24",
									"text": "1 (незначительное)",
									"description": "",
									"position": 1,
									"selected": false,
									"rowId": null,
									"scoringValue": 0.00
								},
								{
									"id": "ab83b871-eb8c-4a6e-873f-23e50f58acc5",
									"text": "2 (значимое)",
									"description": "",
									"position": 2,
									"selected": false,
									"rowId": null,
									"scoringValue": 0.00
								}
							],
							"rows": [
								{
									"id": "62867c6c-8310-4e34-b744-2a75cdc8eb84",
									"name": "Требуется IT доработка",
									"selectedAnswer": "00000000-0000-0000-0000-000000000000",
									"avg": 2.0
								},
								{
									"id": "b83e76a3-6930-4d9d-9258-50f6046e03f5",
									"name": "Требуется изменение процессов/продуктов",
									"selectedAnswer": "00000000-0000-0000-0000-000000000000",
									"avg": 2.0
								},
								{
									"id": "74fc6be5-26fc-44d8-afb3-5a54e97f4446",
									"name": "требуется IT-доработка",
									"selectedAnswer": "00000000-0000-0000-0000-000000000000",
									"avg": 2.0
								},
								{
									"id": "e474309c-ca80-4a03-933c-a0a1f9736211",
									"name": "Требуются человеческие ресурсы",
									"selectedAnswer": "00000000-0000-0000-0000-000000000000",
									"avg": 2.0
								},
								{
									"id": "ea866841-7ef8-4094-82ae-2e5cffe51aee",
									"name": "требуется изменение процессов\\ продуктов",
									"selectedAnswer": "00000000-0000-0000-0000-000000000000",
									"avg": 2.0
								},
								{
									"id": "50b1e5b6-edf2-4597-837b-4edeba46d7d6",
									"name": "Недополученная прибыль/убытки/потенциальные риски",
									"selectedAnswer": "00000000-0000-0000-0000-000000000000",
									"avg": 2.0
								}
							]
						},
						"expertAnswers": [
							[
								"2",
								"2",
								"-",
								"-",
								"2"
							],
							[
								"2",
								"2",
								"-",
								"-",
								"2"
							],
							[
								"2",
								"2",
								"-",
								"-",
								"2"
							],
							[
								"2",
								"2",
								"-",
								"-",
								"2"
							],
							[
								"2",
								"2",
								"-",
								"-",
								"2"
							],
							[
								"2",
								"2",
								"-",
								"-",
								"2"
							]
						]
					},
					{
						"field": {
							"id": "3a445709-2b7e-4039-b273-159044cf9265",
							"propertyName": null,
							"name": "Комментарий",
							"description": "",
							"fieldType": "String",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 6,
							"defaultValue": null,
							"mask": null,
							"category": "Оценка усилий",
							"displayMode": "value",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [],
							"rows": []
						},
						"expertAnswers": [
							[
								"123123123"
							],
							[
								""
							],
							[
								null
							],
							[
								""
							]
						]
					}
				]
			},
			{
				"category": "чайка",
				"fields": [
					{
						"field": {
							"id": "ed7f67b0-d452-11ed-aff0-6bb67b74da62",
							"propertyName": null,
							"name": "Множественный выбор",
							"description": "",
							"fieldType": "Multi",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 11,
							"defaultValue": null,
							"mask": null,
							"category": "чайка",
							"displayMode": "",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [
								{
									"id": "30bddc21-7e38-4d05-9de3-ee032988baa4",
									"text": "Вариант1",
									"description": "",
									"position": 0,
									"selected": false,
									"rowId": null,
									"scoringValue": 0.00
								},
								{
									"id": "4c2fd886-0a70-4096-93a5-76f9cd3ca76f",
									"text": "Вариант2",
									"description": "",
									"position": 1,
									"selected": false,
									"rowId": null,
									"scoringValue": 0.00
								},
								{
									"id": "14a829a3-e008-4896-a4f6-350ab3648d81",
									"text": "Вариант3",
									"description": "",
									"position": 2,
									"selected": false,
									"rowId": null,
									"scoringValue": 0.00
								},
								{
									"id": "06f86186-e621-4840-b1db-10f965f06184",
									"text": "Вариант4",
									"description": "",
									"position": 3,
									"selected": false,
									"rowId": null,
									"scoringValue": 0.00
								}
							],
							"rows": []
						},
						"expertAnswers": [
							[
								"True",
								"True",
								"True",
								"True"
							],
							[
								"False",
								"False",
								"False",
								"False"
							],
							[
								"False",
								"False",
								"False",
								"False"
							],
							[
								"False",
								"True",
								"True",
								"False"
							]
						]
					},
					{
						"field": {
							"id": "1e42ecf0-d453-11ed-aff0-6bb67b74da62",
							"propertyName": null,
							"name": "Теги",
							"description": "",
							"fieldType": "Tags",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 12,
							"defaultValue": null,
							"mask": null,
							"category": "чайка",
							"displayMode": "",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [],
							"rows": []
						},
						"expertAnswers": []
					},
					{
						"field": {
							"id": "3b497f80-d453-11ed-aff0-6bb67b74da62",
							"propertyName": null,
							"name": "Флаг потенциальные участники",
							"description": "",
							"fieldType": "Bool",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 13,
							"defaultValue": null,
							"mask": null,
							"category": "чайка",
							"displayMode": "",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [],
							"rows": []
						},
						"expertAnswers": [
							[
								"true"
							],
							[
								"true"
							],
							[
								null
							],
							[
								null
							]
						]
					}
				]
			},
			{
				"category": " Идея в зоне ответственности моего руководителя",
				"fields": [
					{
						"field": {
							"id": "70a22468-8f71-47c4-8c83-4ab6cf334751",
							"propertyName": null,
							"name": "Выбранный руководитель",
							"description": "",
							"fieldType": "OfferResponsibleForStatusUsers",
							"isMandatory": false,
							"isHidden": false,
							"visibility": null,
							"isReadOnly": false,
							"showOnIdeaCardView": false,
							"maxLength": 0,
							"position": 14,
							"defaultValue": null,
							"mask": null,
							"category": " Идея в зоне ответственности моего руководителя",
							"displayMode": "",
							"additionalParam1": null,
							"linkedFieldStatus": null,
							"disableExpression": null,
							"selectOnlyFromCollection": false,
							"linkedCollection": null,
							"answers": [],
							"rows": []
						},
						"expertAnswers": [
							[
								{
									"status": "NotSet",
									"alias": "begemot",
									"position": "",
									"positionTags": null,
									"division": "Обособленное подразделение в г. Нижнем Новгороде",
									"divisionTags": null,
									"phone": "",
									"eMail": "als+hippo@pryaniky.ru",
									"isFirstLogon": false,
									"imgUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"isMan": false,
									"isDeleted": false,
									"isAdmin": false,
									"birthday": "2023-07-03T00:00:00.000Z",
									"baseNetwork": {
										"id": "4b923f14-0aa0-4f27-9baf-90a1d625462c",
										"name": "ideasperi",
										"title": "IDEASPERI",
										"isHidden": false
									},
									"statusText": "",
									"sex": "f",
									"userPhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/96m96/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"userLargePhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"id": "418984d2-20cd-431f-a9cd-5c8564bc478d",
									"displayName": "Бегемот",
									"type": "user",
									"imgId": "586c7b04-df20-4d05-ac15-0cf798e7175f"
								}
							],
							[
								{
									"status": "NotSet",
									"alias": "begemot",
									"position": "",
									"positionTags": null,
									"division": "Обособленное подразделение в г. Нижнем Новгороде",
									"divisionTags": null,
									"phone": "",
									"eMail": "als+hippo@pryaniky.ru",
									"isFirstLogon": false,
									"imgUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"isMan": false,
									"isDeleted": false,
									"isAdmin": false,
									"birthday": "2023-07-03T00:00:00.000Z",
									"baseNetwork": {
										"id": "4b923f14-0aa0-4f27-9baf-90a1d625462c",
										"name": "ideasperi",
										"title": "IDEASPERI",
										"isHidden": false
									},
									"statusText": "",
									"sex": "f",
									"userPhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/96m96/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"userLargePhotoUrl": "https://ideasperi-rc.v5-stage.pryaniky.com/Img/UserProfiles/Preview/250m250/418984d2-20cd-431f-a9cd-5c8564bc478d.png?v=586c7b04-df20-4d05-ac15-0cf798e7175f",
									"id": "418984d2-20cd-431f-a9cd-5c8564bc478d",
									"displayName": "Бегемот",
									"type": "user",
									"imgId": "586c7b04-df20-4d05-ac15-0cf798e7175f"
								}
							],
							[],
							[]
						]
					}
				]
			}
		]
	}
}