import React, { useState, FC } from 'react'
import { CalendarEventLayout } from 'muicomponents/src/CalendarEventLayout/CalendarEventLayout'
import { useQueryEx, useLazyQueryEx } from 'utils/src/hooks'
import { formatDateSTD } from 'utils/src/utils.dates'
import ViewComponent from 'muicomponents/src/FieldRender/viewComponent'
import AccessAlarms from '@mui/icons-material/AccessAlarms';
import { styled } from '@mui/material/styles';
import { workflows } from 'utils/src/requests/requests.news';
import { changeStatusModalNoRedux } from 'blocks/Workflows/Dialogs/ChangeStatus/ChangeStatus';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getCurrentUserIsAdmin } from 'utils/src/CommonRedux/base/selectors';
import { useDispatch, useSelector } from 'react-redux';
const Icon: any = styled(AccessAlarms)({
    color: '#C344BE'
})

export const WorkflowsEvent: FC<any> = ({ event, updateCalendarEvent }) => {
    const { send, isLoading: isSending } = useLazyQueryEx(workflows.changeStatus)
    const { isLoading, result } = useQueryEx(() => workflows.getUserStatuses(event.newsUid), [event.newsUid])
    const isAdmin = useSelector(getCurrentUserIsAdmin)
    const menuOptions = result?.map((item: any) => ({ content: item.name, id: item.id }))

    async function setStatus(statusId: string, newStatus: any, data?: any) {
        await send(event.newsUid, statusId, data)
        updateCalendarEvent({
            id: event.id,
            data: {
                ...event.data,
                currentStatus: {
                    ...newStatus,
                    changeDate: new Date().toISOString()
                },
            },

        })
    }

    async function changeStatus(statusId: string) {
        const newStatus = result?.find((item: any) => item.id === statusId)
        if (newStatus?.hasFields) {
            changeStatusModalNoRedux({
                newsId: event.newsUid,
                statusId,
                status: newStatus
            }).then((data) => {
                setStatus(statusId, newStatus, { additionalFieldsValues: data })
            }).catch((...args) => console.error('close', args))
        } else {
            await setStatus(statusId, newStatus)
        }
    }

    let enableAction = Boolean(menuOptions)
    enableAction = isAdmin ? enableAction : false
    return <CalendarEventLayout
        Icon={Icon}
        headerText={event.title}
        users={[event.user]}
        statusText={event.data.currentStatus.name}
        statusDate={formatDateSTD(event.data.currentStatus.changeDate, false)}
        link={event.url}
        menuOptions={menuOptions}
        enableAction={enableAction}
        menuIsLoading={isLoading || isSending}
        handleMenuItemClick={(e, item) => { changeStatus(item.id) }}
        // details={[

        // ]}
        secondary={<ViewComponent fieldsKeys={['fields_render_mui_view_calendar']} hideCategory fields={event.data.additionalFieldsValues} />}
    />
}