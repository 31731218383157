// import Immutable from 'seamless-immutable';
import actions from 'redux/actionsTypes/Chat';

const defaultChat: any = {
    chatNewChannelCount: 0,
    channelsList: [],
    chatStatus: 'offline',
    chatUrl: '',
    users: {},
    currentUid: '',
    config: {
        domain: 'chatUrl',                // домен чата
        useSSL: true,
        token: 'authData.token',        // токен авторизации
        useLocalStorage: true,        // использовать или нет локальное хранилище
        debugLevel: 0                 // 0 - дебаг выключен, 1 - дебаг включен
    }
};
export function chatReducer(state = defaultChat, action: any) {
    switch (action.type) {
        case actions.UPDATE_UNREAD_COUNT:
            return {
                ...state,
                chatNewChannelCount: action.payload
            };
        case actions.UPDATE_LIST:
            return {
                ...state,
                channelsList: action.payload
            };
        case actions.CHANGE_STATUS:
            return {
                ...state,
                chatStatus: action.payload
            };
        case actions.UPDATE_LIST_AND_COUNT:
            return {
                ...state,
                ...action.payload
            };
        case actions.MUTE_UNREAD:
            return {
                ...state,
                chatNewChannelCount: 0
            };
        case actions.CHANGE_URL:
            return {
                ...state,
                chatUrl: action.payload
            };
        case actions.UPDATE_USERS:
            return {
                ...state,
                users: {
                    ...state.users,
                    ...action.payload
                }
            };
        case actions.CHANGE_UID:
            return {
                ...state,
                currentUid: action.payload
            };
        case actions.UPDATE_CONFIG:
            return {
                ...state,
                config: { ...state.config, ...action.payload }
            }

        default:
            return state;
    }
}
