import { IDialog } from "../../DialogWrapper.index";
import { cn } from "@bem-react/classname";
import { IDispatchProps } from "../../../../redux/connector";
import { bindActionCreators, Action, Dispatch } from "redux"
import { IBodyProps } from 'utils/src/DialogCreator';
import actions from 'utils/src/CommonRedux/base/actionsTypes';
import { useFileUploader } from "utils/src/useFileUploader";

export interface IWikiCreateNewProps extends IWikiCreateNewOwnProps, IBodyProps, IWikiCreateNewDispatchProps, IWikiCreateNewStateProps {
    // isShown: boolean;
    // onClose: () => void;
    data: any;
    className?: string;
    onChange?: (field: any) => void;
    showInMainManu?: boolean;
    allowComments?: boolean;
    type?: string;
    parentFolderModerators?: any[];
    parentFolderViewers?: any[];
    isAdmin:boolean;
    allowFileTypes?: Parameters<typeof useFileUploader>['0']['allowTypes'];

}
export interface IWikiCreateNewOwnProps { };

export type IWikiCreateNewDispatchProps = ReturnType<typeof mapWikiCreateNewDispatchToProps>

export const mapWikiCreateNewDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    changeVm: (propPath: string, value: any) => dispatch({
        type: actions.CHANGE_STATE, payload: { propPath, value }
    })
}, dispatch);

export type IWikiCreateNewStateProps = ReturnType<typeof mapWikiCreateNewStateToProps>;

export const mapWikiCreateNewStateToProps = () => ({});

export const cnWikiCreateNewDialog = cn("WikiCreateDialogNew");