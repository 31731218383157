import React from 'react';
import { withBemMod } from '@bem-react/core';
import './post.scss'

import { IHeaderLayoutProps, cnHeaderLayout } from '../../HeaderLayout.index';

export interface IHeaderLayoutTypePostProps {

}

export const withHeaderLayoutTypePost = withBemMod<IHeaderLayoutTypePostProps, IHeaderLayoutProps>(cnHeaderLayout(), { type: 'post' }, (Presenter) => (
    (props) => (
        <Presenter {...props} />
    )
));