import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { Rule } from 'blocks/NewsTypes/Base.validate'

export interface IEditorFormProps extends IClassNameProps {
    newsHeader?: string
    // disabled: boolean;
    onChangeFile: (files: any[], validate: boolean) => void;
    files: any[];
    complie?: (isValid: boolean) => JSX.Element

    textAreaRefGetter?: (ref: any) => void;
    textAreaValue?: string;
    changeTextArea?: (cb: (val: string) => void) => void;
    hideActions?: boolean;
    autoFocus?: boolean;
    disableAttach?: boolean;

    isValid?: boolean;
    errorMsg?: string;

    // actions buttons
    save: () => void;
    saveText?: string;
    saveDisabled?: boolean;
    cancle?: () => void;
    cancleText?: string;

    postErrorText?: string;
    postErrorCode?: number;

    validationErrors?: Rule[]

    maxFileSize?: number;
    denyMIME?: string[];

    onChangePublishAt?: (date: string) => void
    publishAt?: string | null

    onChangeGroup?: (group: any) => void
    group?: any

    activePostId?: any

    isSending?: boolean
}


export interface IEditorFormState {
    actionBtns: React.ReactNode
    isOpen: boolean,
    ref: any;
    errorText: string;
    isError: boolean;
    uploadErrorText?: string;
    hasFocus: boolean
    lockFromEditor: boolean
}

export const cnEditorForm = cn('EditorForm');