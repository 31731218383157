import React, { FC, useMemo } from 'react'
import { Box, TextField, Typography } from "muicomponents/src";
import { i18n } from 'localization';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { StyledCheckbox } from './Filters.styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import { NumberTextField } from 'muicomponents/src/TextField/TextField';

interface IPriceFilterProps {
    setMinPrice: (arg: number | undefined) => void
    minPrice?: number
    maxPrice?: number
    setMaxPrice: (arg: number | undefined) => void
    AllowToMeByPrice: boolean
    setAllowToMeByPrice: (arg: boolean) => void
    allowToMeByPriceCount?: number
}

const PriceFilter: FC<IPriceFilterProps> = ({
    minPrice,
    maxPrice,
    setMinPrice,
    setMaxPrice,
    AllowToMeByPrice,
    setAllowToMeByPrice,
    allowToMeByPriceCount,
}: IPriceFilterProps) => {

    const inpProps = { startAdornment: <AccountBalanceWalletIcon sx={{ mr: '8px' }} />, inputProps: { min: 0 } };
    const checkBoxDisabled = minPrice !== undefined || maxPrice !== undefined;
    const showAllowToMeByPriceCount = useMemo(() => !!allowToMeByPriceCount, [allowToMeByPriceCount]);

    return (
        <Box mb='16px'>
            <Typography variant='subtitle1'>{i18n.t("pryaniky.shop.price")}</Typography>
            <Box display='flex' mt='8px' sx={{ gap: '16px' }} >
                <NumberTextField
                    label={i18n.t("from.price")}
                    onChange={(e) => {
                        setMinPrice(e.target.value);
                    }}
                    value={minPrice}
                    tabIndex={0}
                    InputProps={inpProps}
                    disabled={AllowToMeByPrice}
                    sx={{
                        flex: 1
                    }}
                />
                <NumberTextField
                    label={i18n.t("upTo.price")}
                    onChange={(e) => {
                        setMaxPrice(e.target.value);
                    }}
                    value={maxPrice}
                    tabIndex={0}
                    InputProps={inpProps}
                    disabled={AllowToMeByPrice}
                    sx={{
                        flex: 1
                    }}
                />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }} >
                <FormControlLabel
                    label={i18n.t('pryaniky.shop.viewAvailableByPrice')}
                    color={checkBoxDisabled ? 'gray' : 'inherit'}
                    control={<StyledCheckbox checked={AllowToMeByPrice}
                        onChange={() => setAllowToMeByPrice(!AllowToMeByPrice)}
                        disabled={checkBoxDisabled} />} />
                {/* <Typography color={checkBoxDisabled ? 'gray' : 'inherit'}>{i18n.t('pryaniky.shop.viewAvailableByPrice')}&nbsp;</Typography> */}
                {
                    showAllowToMeByPriceCount
                    &&
                    <Typography color='GrayText'>{allowToMeByPriceCount}</Typography>
                }
            </Box>

        </Box>
    )
}

export default PriceFilter