import { FNewsPostType, PostRenderParams } from 'utils/src/BaseTypes/news.types';

export namespace NEvents {

    export type RenderParams = PostRenderParams & {
        allowCreateVideoConference: boolean;
    };

    export interface Create extends FNewsPostType<'events'> {
        newstype: 23;
    }
}