import { IClassNameProps } from '@bem-react/core';
import { IWidgetProps } from '../Widget/Widget.index';
import { cn } from '@bem-react/classname'
export interface IUserBadgesProps extends IClassNameProps, IWidgetProps {
  tag?: 'div';
  context: {[s: string]: any};
}

export interface IBadge {
  id: number
  badgeImgUrl: string
  badgeUid: string
  collectionId: number
  count: number
  description: string
  imgId: string
  isHidden: boolean
  issuedByUserId: string
  largeImgId: string
  name: string
  recieved: string
  url: string
}
export const cnProfileBadgeTabList = cn('ProfileBadgeTabList')

