import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box } from 'muicomponents/src';


export const UserRatingsSkeleton = () => {

    const Item = () => {
        return <>
            <Box display='flex' alignItems='center' mb={4} >
                <Skeleton variant="circular" height={40} width={40} sx={{ mr: 2 }} />
                <Box width={'68%'}>
                    <Skeleton variant="text" height={20} width={'100%'} sx={{ borderRadius: '8px' }} />
                    <Skeleton variant="text" height={20} width={'100%'} sx={{ borderRadius: '8px' }} />
                </Box>
            </Box>
            <Skeleton variant="text" height={20} sx={{ borderRadius: '8px', mt: 1, mb: 2 }} />
        </>
    }

    return <Box sx={{p: 2, pt: 0}}>
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
    </Box>
};