import React, { FC, useState } from 'react'
import { IReasonThanksSelectorProps } from './ReasonThanksSelector.index'
import './ReasonThanksSelector.scss'
import { Button, Input, Icon, Dialog, DialogTitle, CustomScrollbars, DialogContent, CheckboxInput } from 'uielements/src';
import { InputLayout } from '../InputLayout/InputLayout'
import { ActionButtons } from 'blocks/ActionButtons/ActionButtons'
// import { MAX_THANKS_ARGUMENTS, CURRENT_USER_THANKS, MAX_THANKS_FOR_USER } from 'blocks/NewsTypes/Thanks/Utils'
import { ReasonSelector } from '../ReasonSelector/ReasonSelector'
import { ThanksInput2 } from '../ThanksInput2/ThanksInput2'
import i18n from 'localizations/i18n';
import { ThanksCounter } from '../ThanksCounter/ThanksCounter'
import { Translate } from 'localizations/Translate';

export const ReasonThanksSelector: FC<IReasonThanksSelectorProps> = (
    {
        reason,
        selectedReasons,
        onChangeReason,
        // maxThanks,
        // minThanks,
        onChangeThanks,
        // thanksCount,
        usersCount,
        validMsg = [],
        // maxThanksValue = 0,
        maxThanksArgumentsCount = 0,
        // thanksForAll = 0,

        disableDown = false,
        disableUp = false,
        thanksCount = 0,
        available = 0,
        fullCount = 0,
        fullCurrency = 0,
        maxThanks = 0,
        maxPerUser = 0
    }) => {
    const [showDialog, setShowDialog] = React.useState(false);

    const onCloseDialog = () => setShowDialog(false);
    const openDialog = () => setShowDialog(true);

    // const thankPerUser = /*usersCount === 0 ?*/ thanksCount;// : thanksCount / usersCount;
    // const fullCount = thanksCount * usersCount;
    // const minPerUser = usersCount === 0 ? 1 : minThanks / usersCount;
    // const maxPerUser = Math.ceil(usersCount === 0 ? maxThanksValue : Math.min(maxThanksValue, maxThanks / usersCount));
    // const disableUp = (thankPerUser >= maxPerUser) || ((maxThanks - fullCount) < usersCount);
    // const disableDown = thankPerUser <= minPerUser;
    // const available = usersCount === 0 ? thanksForAll : maxThanks - fullCount;

    const maxReasonsCount = maxThanksArgumentsCount <= selectedReasons.length;
    const validation = validMsg.filter(value => (value.field === 'rewardReasons') || (value.field === 'thanksCount'));

    /*const onChangeItem = (item: any) => () => {
        onChangeReason(toggleItemInArray(selectedReasons, item))
    }*/
    // const onChange = (value: number) => {
    //     // const value = e.target.value;
    //     // if (value < minPerUser) return onChangeThanks(0);
    //     // if (value >= maxPerUser) return onChangeThanks(maxPerUser /* (usersCount === 0 ? 1 : usersCount)*/);
    //     onChangeThanks(value /* (usersCount === 0 ? 1 : usersCount)*/)
    // }
    return <div className={'ReasonThanksSelector'}>
        <InputLayout
            // action={}
            focus={showDialog}
            icon={<span className={'ReasonThanksSelector-Icon'}><Icon icon='give' /></span>}
        >
            <div onClick={openDialog} className={'ReasonThanksSelector-Opn'}>
                {usersCount > 0 ?
                    <Translate i18nKey={'pryaniky.createPost.thanks.amount.count'} values={{ thanksCount, fullCount }} />
                    :
                    <Translate i18nKey={'pryaniky.createPost.thanks.amount.null'} />
                }
            </div>
        </InputLayout>
        <Dialog onClose={onCloseDialog} isShown={showDialog}>
            <DialogTitle onClose={onCloseDialog}>
                <span className={'ReasonThanksSelector-Title'}>
                    <Translate i18nKey='pryaniky.createPost.thanks.dialog.title' />
                </span>
                {maxThanks > maxPerUser && <span className={'ReasonThanksSelector-Max'}>
                    <Translate i18nKey='pryaniky.createPost.thanks.dialog.maxCount' values={{ count: maxPerUser }} />
                </span>}
            </DialogTitle>

            <ThanksInput2 onChange={onChangeThanks} disableDown={disableDown} disableUp={disableUp} thankPerUser={thanksCount} />

            <ThanksCounter thanksForAll={fullCurrency} thanksCount={fullCount} available={available} />

            <div>
                <ReasonSelector maxReasonsCount={maxReasonsCount} onChange={onChangeReason} selectedReasons={selectedReasons} reasons={reason} />
            </div>

            <ActionButtons className={"ThanksSelector-Actions"} saveDisabled={validation.length !== 0} save={onCloseDialog} cancle={onCloseDialog} />
        </Dialog>
    </div>
}