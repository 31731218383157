import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import {  } from "../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
export interface ITimerProps extends IClassNameProps, ITimerStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
    unitId: string;
}

export interface ITimerState {
}

export interface ITimerStateProps {
    timer: any
    // cIds: string[]
}

export const mapStateToProps = (state: any, props: any) => ({
    // courses: state.LMS.courses.entities.values,
    timer: state.LMS.timers[props.unitId],
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export const cnTimer = cn('Timer');