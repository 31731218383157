import { bindActionCreators } from 'redux';
import { uploadPhotos } from "../../../redux/actions/Photoalbums";

export interface IAddPhotoProps extends IBaseAlbumProps, IAlbumStateProps, ReturnType<typeof mapDispatchToProps> {

}

export interface IBaseAlbumProps {
    aid: string;
    gid: number;
}

export interface IAlbumStateProps {

}

export const mapStateToProps = (state: any) => {
    return {  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    uploadPhotos
}, dispatch);