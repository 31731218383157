import { IClassNameProps } from "@bem-react/core";
import { cn } from "@bem-react/classname";

export interface IImageLoaderProps extends IClassNameProps {
  tag?: string | React.ComponentType<any>;
  cropper?: boolean;
  cropperProps?: {
    aspect: any;
    width?: number;
    height?: number;
  };
  title?: string;
  description?: string;
  squarePreview?: boolean;
  onLoadStart?: (file: any) => void;
  onLoaded: (file: any) => void;
  startPreview?: string;
  dialog?: boolean
}

export interface IImageLoaderState {
  file?: File;
  aspect?: number;
  fileUrl?: string;
  loadingPropgress: number;
  loadingStatus: 'unstart' | 'progress' | 'finish' | 'error';
  cropper: boolean;
}

export const cnImageLoader = cn('ImageLoader');