import React, { useState, useEffect, useCallback } from 'react';
import { cnMood, cnMoodEdit, IMoodProps } from './Mood.index';
import { i18n } from 'localization/src/i18n';
import Button from 'uielements/src/MaterialElements/Button/Button';
import Slider from 'uielements/src/MaterialElements/Slider';
import './Mood.scss';
import { toast } from 'react-toastify';
import { useDebounce } from 'utils/src/hooks';

import { getMoodRequest, setMoodRequest } from 'utils/src/requests/requests.mood';

import stub from 'assets/smiles/preview.png';
import { getErrorText } from 'utils/src/utils';
import cruel from 'assets/smiles/cruel.png';
import angry from 'assets/smiles/angry.png';
import normal from 'assets/smiles/normal.png';
import smile from 'assets/smiles/smile.png';
import happy from 'assets/smiles/happy.png';


export const Mood: React.FC<IMoodProps> = (props) => {

  const { tag: Tag = 'div', edit } = props;

  const [mark, setMark] = useState<number | number[] | undefined>(0);
  const [isAnswered, setIsAnswered] = useState<boolean>(true);
  const handleChange = useDebounce((value) => setMark(value), 10, [mark]);

  const markImages: {[key: string]: any} = {
    "1": { img: cruel, label: i18n.t('pryaniky.mood.label.cruel') },
    "2": { img: angry, label: i18n.t('pryaniky.mood.label.angry') },
    "3": { img: normal, label: i18n.t('pryaniky.mood.label.normal') },
    "4": {img: smile, label: i18n.t('pryaniky.mood.label.smile') },
    "5": {img: happy, label: i18n.t('pryaniky.mood.label.happy') },
  }

  const getMark = useCallback(() => {
    getMoodRequest().r.then(res => {
      if (res.data.levelMood > 0) setMark(res.data.levelMood);
      setIsAnswered(res.data.todayVoting);
    })
  }, []);

  const saveMark = useCallback(() => {
    if (typeof mark !== 'number') return;
    setMoodRequest(mark).r.then(res => {
      if (res.error_code === 0) setIsAnswered(true);
      else toast.error(getErrorText(res));
    })
  }, [mark])



  useEffect(() => {
    getMark();
  }, [])

  if (edit) return <Tag {...props} className={cnMoodEdit()} />

  return (
    <Tag {...props} className={cnMood({}, [props.className])} >
      <div className={cnMood('Title')}>
        <span>
          {i18n.t('pryaniky.mood.title')}
        </span>
        <br />
        {/* <span>
          {i18n.t('pryaniky.mood.help')}
        </span> */}
      </div>
      <div className={cnMood('Mark')}>
        {
          mark ?
            <div className={cnMood('Mark-View')}>
              <img src={markImages[mark.toString()].img} />
              <span>{markImages[mark.toString()].label}</span>
            </div> :
            <div className={cnMood('Mark-StubBox')}> 
              <img className={cnMood('Mark-Stub')} src={stub} />
              <span>
                {i18n.t('pryaniky.mood.help')}
              </span>
            </div>
        }
        <div className={cnMood('Mark-Slider')}>
          <Slider disabled={isAnswered}  min={1} max={Object.keys(markImages).length} marks color={"primary"} value={mark} size={4} onChange={handleChange} />
          {/* <span className={cnMood('Mark-Number')}>{mark}</span> */}
        </div>

      </div>
      <div className={cnMood('Send')}>
        {
          isAnswered ?
            <div>
              {i18n.t('pryaniky.mood.sended')}
            </div> :
            <Button disable={mark === 0} onClick={saveMark}>
              {i18n.t('pryaniky.mood.send')}
            </Button>
        }
      </div>
    </Tag>
  )
}
