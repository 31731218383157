import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import i18n from '../localizations/i18n';

export const tagBlock = (): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            // id: ids.info,
            type: widgets.types.tagBlock,
          }),
        ],
        styles: {
          width: '72%',
        },
      }),
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.filters + '/subnetworks',
            relations: [],
            data: [],
          }),
        ],
        styles: {
          width: '28%',
        },
      }),
    ],
  });
}