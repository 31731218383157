import Component, { Presenter } from './component';
import {
    IVersionsListOwnProps,
    IVersionsListPresenterProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = IVersionsListOwnProps;
export type IComponentPresenterProps = IVersionsListPresenterProps;


export default Component