import React, { FC } from 'react'
import { Skeleton } from 'uielements/src'
import './Course.scss'
import { cn } from '@bem-react/classname';
import { CourseHeader } from '../CourseHeader/CourseHeader'

export const cnCourse = cn('SkeletCourse');

export const Course: FC<any> = ({ className }) => {

    return <div className={cnCourse({}, ['Page', className])}>
        <CourseHeader />

        <div className="Course-Content">
            <div className="Course-Threads">
                <h3 className="Course-ThreadsTitle"><Skeleton shape="line" size={"lg"} style={{ width: '100px' }} /></h3>
                <div className="SessionList Course-ThreadsList">
                    <div className="Thread">
                        <div className="Deadlines Thread-Deadlines">
                            <Skeleton shape="line" size={"sm"} style={{ width: '150px' }} />
                        </div>
                        <div className="Thread-Name" title="as">
                            <Skeleton shape="line" size={"sm"} style={{ width: '300px' }} />
                        </div>
                        <Skeleton shape="line" size={"sm"} style={{ width: '130px' }} />
                    </div>
                </div>
            </div>
            <div className="LMSCourseTabs Course-Tabs">
                <Skeleton shape="line" size={"xl"} style={{ width: '250px', marginLeft: '24px' }} />
            </div>
            <div className="Course-Search">
                <div className="SearchInput Course-SearchInput">
                    <input className="SearchInput-Input" value="" />
                    <button className="Button Button_theme_unstyled SearchInput-Button SearchInput-Button_role_submit" title="поиск">
                        <i className="Icon Icon_search"></i></button>
                </div>
            </div>
            <div className="Course-Units">
                <h3 className="Course-UnitsCount"><Skeleton shape="line" size={"lg"} style={{ width: '100px' }} /></h3>
                <ul className="UnitsList">
                    <li className="UnitItem">
                        <div className="UnitItem-Data">
                            <div className="UnitItem-Name"><Skeleton shape="line" size={"sm"} style={{ width: '300px' }} /></div>
                            <div className="UnitItem-Type"><Skeleton shape="line" size={"sm"} style={{ width: '130px' }} /></div>
                        </div>
                    </li>
                    <li className="UnitItem">
                        <div className="UnitItem-Data">
                            <div className="UnitItem-Name"><Skeleton shape="line" size={"sm"} style={{ width: '320px' }} /></div>
                            <div className="UnitItem-Type"><Skeleton shape="line" size={"sm"} style={{ width: '130px' }} /></div>
                        </div>
                    </li>
                    <li className="UnitItem">
                        <div className="UnitItem-Data">
                            <div className="UnitItem-Name"><Skeleton shape="line" size={"sm"} style={{ width: '230px' }} /></div>
                            <div className="UnitItem-Type"><Skeleton shape="line" size={"sm"} style={{ width: '130px' }} /></div>
                        </div>
                    </li>
                    <li className="UnitItem">
                        <div className="UnitItem-Data">
                            <div className="UnitItem-Name"><Skeleton shape="line" size={"sm"} style={{ width: '350px' }} /></div>
                            <div className="UnitItem-Type"><Skeleton shape="line" size={"sm"} style={{ width: '130px' }} /></div>
                        </div>
                    </li>
                    <li className="UnitItem">
                        <div className="UnitItem-Data">
                            <div className="UnitItem-Name"><Skeleton shape="line" size={"sm"} style={{ width: '300px' }} /></div>
                            <div className="UnitItem-Type"><Skeleton shape="line" size={"sm"} style={{ width: '130px' }} /></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
}

