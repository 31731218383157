import { IEditorProps, cnEditor } from './Editor.index'
import * as React from 'react'
import './Editor.scss';
import './Editor.ie.scss';
import { CountSlider } from '../../../CountSlider/CountSlider'
import { Input, Icon, Button } from 'uielements/src';
import i18n from 'localizations/i18n';
import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'
import { DateTimeInput } from 'blocks/PryanikyForms/DateTimeInput/DateTimeInput'
import { Popover } from 'blocks/NewsTypes/common/Popover/Popover';
import { ThanksSelector } from 'blocks/PryanikyForms/ThanksSelector/ThanksSelector'
import moment from 'moment'

const ref = React.createRef<HTMLElement>();

export const Editor: React.FC<IEditorProps> = ({ children, data, endDateChange, onChangeText, onChangeTitle, onChangeThanks }) => {
    const [open, setOpen] = React.useState(false)

    return <div className={cnEditor({}, ['Editor'])}>
        <Input onChange={onChangeTitle}
            className={'EditorCreativetasks-Title NewsEdit-Body_type_competitions-Input-Title'}
            value={data.creativeTask.title}
            icon='flag'
            placeholder={i18n.t('pryaniky.createPost.competition.name')} />

        <div className={cnEditor('HContainer')}>
            {onChangeThanks && <ThanksSelector min={1} onChange={onChangeThanks} value={data.creativeTask.fund} />}
            
            {data.creativeTask.state !== 'Canceled' &&
                <DateTimeInput
                    minDate={new Date()}
                    className={cnEditor('Datepicker', ['NewsEdit-Body_type_competitions-ExpiresBox'])}
                    value={new Date(data.creativeTask.endDateTime)}
                    onChange={endDateChange}
                    // icon={() => <b>Финал:</b>}
                    icon={(onClick) => <Button {...{ onClick }} ><Icon icon='calendar' /></Button>}
                />
            }

            {/* <DateTimeInput
                minDate={new Date()}
                className={cnEditor('Datepicker', ['NewsEdit-Body_type_competitions-ExpiresBox'])}
                value={new Date(data.creativeTask.endDateTime)}
                onChange={endDateChange}
                icon={() => <b>Итоги:</b>}
                action={(isOpen, onClick) => <Icon {...{ onClick }} icon='calendar' />}
            /> */}

            {/*<Datepicker className={'EditorCreativetasks-Datepicker NewsEdit-Body_type_competitions-ExpiresBox'} onChange={endDateChange} locale={i18n.language} value={new Date(data.creativeTask.endDateTime)} />*/}

            {/*onChangeThanks && <CountSlider
            onChange={onChangeThanks}
            icon={'give'}
            className={'EditorCreativetasks-CountSlider'}
            value={data.creativeTask.fund}
        />*/}

        </div>
        <PryanikyEditor
            // getClassRef={refGetter}
            // users={true}
            // tags={true}
            onChange={onChangeText}
            // className={''}
            // icon={'edit'}
            value={data.text}
            disableToolbar={true}
            placeholder={i18n.t('pryaniky.createPost.competition.description')} />
        {children}
    </div>
}