import { bindActionCreators, Action, Dispatch } from "redux"
import { IBodyProps } from 'utils/src/DialogCreator';
import { cn } from '@bem-react/classname';
import { IComponentAcceptor } from 'utils/src/ComponentInjector'
import { IWSettingsOnlyProps } from '../../../WSettings/WSettings.index';
import {  addWidget } from 'redux/actions/Widgets';
import { modalChangeDate } from 'redux/actions/Modals';
import { getActiveStructure, getActiveStructureWidgets } from 'redux/actions/Widgets';

export type IWidgetAddDialogStateProps = ReturnType<typeof WidgetAddDialogMapStateToProps>;

export interface IWidgetAddDialogProps extends  IWidgetAddDialogOwnProps, IComponentAcceptor<IWSettingsOnlyProps>, IWidgetAddDialogDispatchProps, IWidgetAddDialogStateProps {
    addToGrid?: any;
    columnId?: any;
}


export interface IWidgetAddDialogOwnProps extends IBodyProps {
    widgetId?: string | number;
}
// extends IComponentAcceptor<IWSettingsOnlyProps> {



export interface IWidgetToSelect {
    schema: string;
    title: string;
    description:string;
    type: string;
    tags: string[];
    img?: string;
    id: string;
    settings?: {[key: string]: any};
}

export interface IWidgetAddDialogState {
    data?: any;

}
const dialogId = "WidgetAddDialogId";
export const WidgetAddDialogMapStateToProps = (state: any, props: IWidgetAddDialogOwnProps) => {
    return {
        id: dialogId,
        data: (state.modals[dialogId] || {}).data || {},
        enabledModules: state.store.appSettings && state.store.appSettings.enabledModules
    };
};

export type IWidgetAddDialogDispatchProps = ReturnType<typeof mapWidgetAddDialogDispatchToProps>

export const mapWidgetAddDialogDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    addWidget,
    modalChangeDate,
    getActiveStructure,
    getActiveStructureWidgets
}, dispatch)


export const cnWidgetAddDialog = cn('WidgetAddDialog');