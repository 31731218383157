import React, { FC, useEffect, memo } from 'react'
import { IFieldItemProps, cnClassName } from './FieldItem.index'
import './FieldItem.scss'
export const FieldItem: FC<IFieldItemProps> = ({ onSelect, field, selected }) => {
    const onClick = () => {
        onSelect(field)
    }
    return <div className={cnClassName({}, [])} onClick={onClick} style={{ backgroundColor: selected ? '#ccc' : 'transparent' }}>
        {field.name}
    </div>
}
