import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

// import { IDispatchProps } from '../../../../src/redux/connector';
// import { RouteComponentProps } from 'react-router';

export interface INotificationsSettingsCommonProps extends IClassNameProps, INotificationsSettingsCommonState, INotificationsSettingsCommonStateProps {
  tag?: 'div';
  userId?: string;
}

export interface INotificationsSettingsCommonState {
  text?: string;
  settings?: any;
  section?: string;
  currentGroup?: any;
  groups?: any;
}

export interface INotificationsSettingsCommonStateProps {
  store?: any
}


export const mapStateToProps = (state: any) => ({
  store: {
    ...state.store,
    authUser: getAuthUser(state)
  }
})


export const cnNotificationsSettingsCommon = cn('NotificationsSettingsCommon');