import { IBaseNews } from '../../types/baseNews'
import { objectArrayToMap } from 'utils/src/utils'

/**
 * нормальизует комментарии
 * @param comments 
 */
export const normalizeReplys = (comments: any[]) => {
    const editRemoved = comments.map((value: any) => ({
        ...value,
        // actions: value.showLoadMore ? value.actions.map((action: string) => action === "edit" ? 'loadFull' : action) : value.actions 
        actions: value.showLoadMore ? value.actions.filter((action: string) => action !== "edit") : value.actions
    }))
    const commentsValues = objectArrayToMap(editRemoved, 'id')
    const commentsKeys = Object.keys(commentsValues)
    return {
        commentsValues,
        commentsKeys,
    }
}

/**
 * нормализует новости, достаёт все комментарии
 * @param data 
 */
export const normalizeNews = (data: IBaseNews[]) => {
    const comments = data.reduce((acc: any[] = [], cur: IBaseNews) => {
        return [...acc, ...(cur.comments || [])]
    }, [])
    const values = objectArrayToMap<IBaseNews, IBaseNews>(data, 'id', (val) => ({ ...val, comments: (val.comments || []).map((v: any) => v.id) }))
    const keys = Object.keys(values)

    return {
        ...normalizeReplys(comments),
        values,
        keys
    }
}

/**
 * изменяет экшен новости, on/off
 * @param actions 
 * @param actionId 
 */
export const toggleAction = (actions: string[], actionId: string) => {
    const [actionType = actionId, state = ''] = actionId.split('.')
    return actions.map(action => {
        if (action.includes(actionType)) {
            let wo = action.replace(actionType, '')
            return actionType + (wo.includes('on') ? wo.replace('on', 'off') : wo.replace('off', 'on'))
        }
        return action
    })
}