import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IStateType } from '../../../../redux/store';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { IBaseNews } from '../../../types/baseNews';
import {
  getNewsById,
  getNewsParamsById,
} from '../../../redux/saga/selectors';
import { NewsContextProps } from '../../../contexts/news';

export const mapVoteItemStateToProps = (state: any, { newsId }: IVoteItem.OwnProps) => ({
  ...getNewsParamsById(newsId, ['id', 'user', 'additionalFields'])(state),
  workflowId: getNewsById(newsId)(state)?.additionalFields?.formId,
});

export const mapVoteItemActionsToProps = (d: Dispatch<Action>) => bindActionCreators({

}, d);

export namespace IVoteItem {

  export type ImapVoteItemStateToProps = ReturnType<typeof mapVoteItemStateToProps>;

  export type ImapVoteItemActionsToProps = ReturnType<typeof mapVoteItemActionsToProps>;

  export interface OwnProps extends IClassNameProps, NewsContextProps {
    children?: React.ReactNode
    // newsId: IBaseNews['id'];
  }

  export interface Props extends OwnProps, ImapVoteItemStateToProps, ImapVoteItemActionsToProps { }
}

export const cnVoteItem = cn('VoteItem');