import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets'

export const statistics = (): IWidget<IColumn[]> => {

  return generateWidget({
    type: widgets.types.layout,
    settings: {
      fullWidth: true
    },
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.statisticFilters,
            settings: {
              data: [
                {type: "dates"},
                {type: 'groupBy'},
                {type: "activityVirtualUsers"}
              ]
            }
          }),
          generateWidget({
            type: widgets.types.statistic,
            settings: {
              data: {
                chart: "barGroups",
                datesType: "month",
                maxItems: "7",
                module: "activity",
                postTypes: [],
                statistic: "visits",
                title: "Динамика посещаемости"
              }
            }
          }),
          generateWidget({
            type: widgets.types.statistic,
            settings: {
              data: {
                chart: "barGroups",
                datesType: "month",
                maxItems: "7",
                module: "activity",
                postTypes: [
                  {
                    "id": "thanks",
                    "idNumber": 4,
                    "displayName": "Благодарности",
                    "componentRenderName": "thanks",
                    "category": "publications",
                    "enabled": true,
                    "allowed": true,
                    "renderParams": {
                      "minTextLength": 10,
                      "defaultReasons": [],
                      "allowRichEdit": true,
                      "richEditAllowedMarks": null,
                      "allowNewsHeader": false,
                      "formId": "00000000-0000-0000-0000-000000000000",
                      "vPosition": 0,
                      "defaultViewType": "Feed"
                    },
                    "additionalFields": null,
                    "ideaRenderParams": null,
                    "badgeRenderParams": null,
                    "eventRenderParams": null,
                    "newsRenderParams": null,
                    "thanksRenderParams": {
                      "minTextLength": 10,
                      "defaultReasons": [],
                      "allowRichEdit": true,
                      "richEditAllowedMarks": null,
                      "allowNewsHeader": false,
                      "formId": "00000000-0000-0000-0000-000000000000",
                      "vPosition": 0,
                      "defaultViewType": "Feed"
                    },
                    "pollRenderParams": null,
                    "actions": [
                      "attach",
                      "file",
                      "markusers",
                      "mentionuser",
                      "mentiontag"
                    ],
                    "achievementsRenderParams": null,
                    "questsRenderParams": null
                  },
                  {
                    "id": "news",
                    "idNumber": 5,
                    "displayName": "Новости",
                    "componentRenderName": "news",
                    "category": "publications",
                    "enabled": true,
                    "allowed": true,
                    "renderParams": {
                      "allowRichEdit": true,
                      "richEditAllowedMarks": null,
                      "allowNewsHeader": true,
                      "formId": "00000000-0000-0000-0000-000000000000",
                      "vPosition": 0,
                      "defaultViewType": "Feed"
                    },
                    "additionalFields": null,
                    "ideaRenderParams": null,
                    "badgeRenderParams": null,
                    "eventRenderParams": null,
                    "newsRenderParams": {
                      "allowRichEdit": true,
                      "richEditAllowedMarks": null,
                      "allowNewsHeader": true,
                      "formId": "00000000-0000-0000-0000-000000000000",
                      "vPosition": 0,
                      "defaultViewType": "Feed"
                    },
                    "thanksRenderParams": null,
                    "pollRenderParams": null,
                    "actions": [
                      "attach",
                      "file",
                      "markusers",
                      "mentionuser",
                      "mentiontag"
                    ],
                    "achievementsRenderParams": null,
                    "questsRenderParams": null
                  },
                  {
                    "id": "polls",
                    "idNumber": 7,
                    "displayName": "Опросы",
                    "componentRenderName": "polls",
                    "category": "publications",
                    "enabled": true,
                    "allowed": true,
                    "renderParams": {
                      "allowRichEdit": true,
                      "richEditAllowedMarks": null,
                      "allowNewsHeader": false,
                      "formId": "00000000-0000-0000-0000-000000000000",
                      "vPosition": 0,
                      "defaultViewType": "Feed"
                    },
                    "additionalFields": null,
                    "ideaRenderParams": null,
                    "badgeRenderParams": null,
                    "eventRenderParams": null,
                    "newsRenderParams": null,
                    "thanksRenderParams": null,
                    "pollRenderParams": {
                      "allowRichEdit": true,
                      "richEditAllowedMarks": null,
                      "allowNewsHeader": false,
                      "formId": "00000000-0000-0000-0000-000000000000",
                      "vPosition": 0,
                      "defaultViewType": "Feed"
                    },
                    "actions": [
                      "attach",
                      "file",
                      "markusers",
                      "mentionuser",
                      "mentiontag"
                    ],
                    "achievementsRenderParams": null,
                    "questsRenderParams": null
                  },
                  {
                    "id": "creativetasks",
                    "idNumber": 6,
                    "displayName": "Конкурсы",
                    "componentRenderName": "creativetasks",
                    "category": "publications",
                    "enabled": true,
                    "allowed": true,
                    "renderParams": {
                      "allowRichEdit": true,
                      "richEditAllowedMarks": null,
                      "allowNewsHeader": false,
                      "formId": "00000000-0000-0000-0000-000000000000",
                      "vPosition": 0,
                      "defaultViewType": "Feed"
                    },
                    "additionalFields": null,
                    "ideaRenderParams": null,
                    "badgeRenderParams": null,
                    "eventRenderParams": null,
                    "newsRenderParams": null,
                    "thanksRenderParams": null,
                    "pollRenderParams": null,
                    "actions": [
                      "attach",
                      "file",
                      "markusers",
                      "mentionuser",
                      "mentiontag"
                    ],
                    "achievementsRenderParams": null,
                    "questsRenderParams": null
                  },
                  {
                    "id": "ideas",
                    "idNumber": 21,
                    "displayName": "Идеи",
                    "componentRenderName": "ideas",
                    "category": "publications",
                    "enabled": true,
                    "allowed": true,
                    "renderParams": {
                      "allowRichEdit": true,
                      "richEditAllowedMarks": null,
                      "allowNewsHeader": true,
                      "formId": "00000000-0000-0000-0000-000000000000",
                      "vPosition": 0,
                      "defaultViewType": "Feed"
                    },
                    "additionalFields": null,
                    "ideaRenderParams": {
                      "currentStatus": {
                        "id": "3766d042-a1b1-4e4f-b6ad-d7f9e47cc7f9",
                        "name": "Инвестирование",
                        "description": "",
                        "category": "",
                        "role": null
                      },
                      "ideaName": null,
                      "ideaId": "00000000-0000-0000-0000-000000000000",
                      "additionalFieldsValues": [
                        {
                          "id": "1ed6f2ea-f70e-4a30-a248-ff959cab6972",
                          "title": "",
                          "description": "",
                          "isCollapsed": false,
                          "fields": [
                            {
                              "data": null,
                              "id": "dfb34b30-c0d7-4d4f-abe5-3930f390c5e8",
                              "name": "Какой результат мы получим?",
                              "defaultFieldName": null,
                              "description": "",
                              "position": 0,
                              "fieldForStatusId": null,
                              "categoryId": "1ed6f2ea-f70e-4a30-a248-ff959cab6972",
                              "categoryName": "",
                              "type": "Textarea",
                              "fieldType": "Textarea",
                              "mask": "",
                              "showOnCardView": false,
                              "defaultValue": "",
                              "disableExpression": "",
                              "isMandatory": true,
                              "isHidden": false,
                              "isBaseField": false,
                              "visibility": null,
                              "isReadOnly": false,
                              "maxLength": 0,
                              "displayMode": "",
                              "linkedFieldStatus": null,
                              "selectOnlyFromCollection": false,
                              "linkedCollection": null,
                              "defaultData": null,
                              "settings": null,
                              "useInCalendar": false,
                              "calendarDateType": null,
                              "defaultFieldNameType": "Default"
                            },
                            {
                              "data": [
                                {
                                  "id": "7fd42fc0-07da-11ec-9d8f-2f34a913a26a",
                                  "text": "Увеличит продажи лицензий",
                                  "description": "",
                                  "position": 0,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "84dfd7d0-07da-11ec-9d8f-2f34a913a26a",
                                  "text": "Увеличит продажи консалта",
                                  "description": "",
                                  "position": 1,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "87c607d0-07da-11ec-9d8f-2f34a913a26a",
                                  "text": "Увеличит продажи технических работ",
                                  "description": "",
                                  "position": 2,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "8fcdfff0-07da-11ec-9d8f-2f34a913a26a",
                                  "text": "Увеличит продажи технического сопровождения",
                                  "description": "",
                                  "position": 3,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "94bbe6d0-07da-11ec-9d8f-2f34a913a26a",
                                  "text": "Увеличит продажи коммуникационного сопровождения",
                                  "description": "",
                                  "position": 4,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "9c1f6190-07da-11ec-9d8f-2f34a913a26a",
                                  "text": "Увеличит продажи чего-то еще",
                                  "description": "",
                                  "position": 5,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                }
                              ],
                              "answers": [
                                {
                                  "id": "7fd42fc0-07da-11ec-9d8f-2f34a913a26a",
                                  "text": "Увеличит продажи лицензий",
                                  "description": "",
                                  "position": 0,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "84dfd7d0-07da-11ec-9d8f-2f34a913a26a",
                                  "text": "Увеличит продажи консалта",
                                  "description": "",
                                  "position": 1,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "87c607d0-07da-11ec-9d8f-2f34a913a26a",
                                  "text": "Увеличит продажи технических работ",
                                  "description": "",
                                  "position": 2,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "8fcdfff0-07da-11ec-9d8f-2f34a913a26a",
                                  "text": "Увеличит продажи технического сопровождения",
                                  "description": "",
                                  "position": 3,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "94bbe6d0-07da-11ec-9d8f-2f34a913a26a",
                                  "text": "Увеличит продажи коммуникационного сопровождения",
                                  "description": "",
                                  "position": 4,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "9c1f6190-07da-11ec-9d8f-2f34a913a26a",
                                  "text": "Увеличит продажи чего-то еще",
                                  "description": "",
                                  "position": 5,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                }
                              ],
                              "id": "75687e10-07da-11ec-9d8f-2f34a913a26a",
                              "name": "За счет чего это поможет нам увеличить доход?",
                              "defaultFieldName": null,
                              "description": null,
                              "position": 1,
                              "fieldForStatusId": null,
                              "categoryId": "1ed6f2ea-f70e-4a30-a248-ff959cab6972",
                              "categoryName": "",
                              "type": "Multi",
                              "fieldType": "Multi",
                              "mask": null,
                              "showOnCardView": false,
                              "defaultValue": null,
                              "disableExpression": null,
                              "isMandatory": true,
                              "isHidden": false,
                              "isBaseField": false,
                              "visibility": null,
                              "isReadOnly": false,
                              "maxLength": 0,
                              "displayMode": "",
                              "linkedFieldStatus": null,
                              "selectOnlyFromCollection": false,
                              "linkedCollection": null,
                              "defaultData": null,
                              "settings": null,
                              "useInCalendar": false,
                              "calendarDateType": null,
                              "defaultFieldNameType": "Default"
                            },
                            {
                              "data": [
                                {
                                  "id": "8084c98f-fa5c-4978-ad9c-39ef83aed683",
                                  "displayName": "1_привлечение_клиента",
                                  "type": "tag",
                                  "imgId": "00000000-0000-0000-0000-000000000000",
                                  "imgUrl": null
                                },
                                {
                                  "id": "33887275-d143-4215-b3d7-9969eff313d6",
                                  "displayName": "2_активация_клиента",
                                  "type": "tag",
                                  "imgId": "00000000-0000-0000-0000-000000000000",
                                  "imgUrl": null
                                },
                                {
                                  "id": "dc8e1869-9a55-4591-9792-b617480d162a",
                                  "displayName": "4_удержание_клиента",
                                  "type": "tag",
                                  "imgId": "00000000-0000-0000-0000-000000000000",
                                  "imgUrl": null
                                },
                                {
                                  "id": "a4e9e403-88e7-43ce-9f65-b66f93bfea9b",
                                  "displayName": "3_получение_оплаты",
                                  "type": "tag",
                                  "imgId": "00000000-0000-0000-0000-000000000000",
                                  "imgUrl": null
                                },
                                {
                                  "id": "73952f4a-0cdd-4cb1-8677-f287dae92a86",
                                  "displayName": "5_распространение_и_рекомендации",
                                  "type": "tag",
                                  "imgId": "00000000-0000-0000-0000-000000000000",
                                  "imgUrl": null
                                }
                              ],
                              "id": "1184d480-042d-11ec-b242-1bcb095f378a",
                              "name": "Этап работы с клиентом",
                              "defaultFieldName": null,
                              "description": null,
                              "position": 2,
                              "fieldForStatusId": null,
                              "categoryId": "1ed6f2ea-f70e-4a30-a248-ff959cab6972",
                              "categoryName": "",
                              "type": "Tags",
                              "fieldType": "Tags",
                              "mask": null,
                              "showOnCardView": false,
                              "defaultValue": null,
                              "disableExpression": null,
                              "isMandatory": true,
                              "isHidden": false,
                              "isBaseField": false,
                              "visibility": null,
                              "isReadOnly": false,
                              "maxLength": 0,
                              "displayMode": "",
                              "linkedFieldStatus": null,
                              "selectOnlyFromCollection": true,
                              "linkedCollection": null,
                              "defaultData": null,
                              "settings": null,
                              "useInCalendar": false,
                              "calendarDateType": null,
                              "defaultFieldNameType": "Default"
                            },
                            {
                              "data": null,
                              "id": "c6ae5260-098b-11ec-afbb-ab6d4b286395",
                              "name": "Какие есть риски?",
                              "defaultFieldName": null,
                              "description": "",
                              "position": 3,
                              "fieldForStatusId": null,
                              "categoryId": "1ed6f2ea-f70e-4a30-a248-ff959cab6972",
                              "categoryName": "",
                              "type": "Textarea",
                              "fieldType": "Textarea",
                              "mask": "",
                              "showOnCardView": false,
                              "defaultValue": "",
                              "disableExpression": "",
                              "isMandatory": true,
                              "isHidden": false,
                              "isBaseField": false,
                              "visibility": null,
                              "isReadOnly": false,
                              "maxLength": 0,
                              "displayMode": "",
                              "linkedFieldStatus": null,
                              "selectOnlyFromCollection": false,
                              "linkedCollection": null,
                              "defaultData": null,
                              "settings": null,
                              "useInCalendar": false,
                              "calendarDateType": null,
                              "defaultFieldNameType": "Default"
                            }
                          ]
                        },
                        {
                          "id": "5d2176d0-0984-11ec-a435-d7140b862b20",
                          "title": "Проверка гипотезы",
                          "description": "",
                          "isCollapsed": false,
                          "fields": [
                            {
                              "data": null,
                              "id": "7457d8d0-0984-11ec-a435-d7140b862b20",
                              "name": "Какие ресурсы потребуются, чтобы проверить полезность идеи?",
                              "defaultFieldName": null,
                              "description": "",
                              "position": 4,
                              "fieldForStatusId": null,
                              "categoryId": "5d2176d0-0984-11ec-a435-d7140b862b20",
                              "categoryName": "Проверка гипотезы",
                              "type": "Textarea",
                              "fieldType": "Textarea",
                              "mask": "",
                              "showOnCardView": false,
                              "defaultValue": "",
                              "disableExpression": "",
                              "isMandatory": true,
                              "isHidden": false,
                              "isBaseField": false,
                              "visibility": null,
                              "isReadOnly": false,
                              "maxLength": 0,
                              "displayMode": "",
                              "linkedFieldStatus": null,
                              "selectOnlyFromCollection": false,
                              "linkedCollection": null,
                              "defaultData": null,
                              "settings": null,
                              "useInCalendar": false,
                              "calendarDateType": null,
                              "defaultFieldNameType": "Default"
                            },
                            {
                              "data": [
                                {
                                  "id": "c39bc6b0-0a45-11ec-a55b-752faf1d2b0b",
                                  "text": "До 2х недель",
                                  "description": "",
                                  "position": 0,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "c6f5c720-0a45-11ec-a55b-752faf1d2b0b",
                                  "text": "2-4 недели",
                                  "description": "",
                                  "position": 1,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "c962cf80-0a45-11ec-a55b-752faf1d2b0b",
                                  "text": "До 2х месяцев",
                                  "description": "",
                                  "position": 2,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "d05a4200-0a45-11ec-a55b-752faf1d2b0b",
                                  "text": "Больше 2х месяцев",
                                  "description": "",
                                  "position": 3,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                }
                              ],
                              "answers": [
                                {
                                  "id": "c39bc6b0-0a45-11ec-a55b-752faf1d2b0b",
                                  "text": "До 2х недель",
                                  "description": "",
                                  "position": 0,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "c6f5c720-0a45-11ec-a55b-752faf1d2b0b",
                                  "text": "2-4 недели",
                                  "description": "",
                                  "position": 1,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "c962cf80-0a45-11ec-a55b-752faf1d2b0b",
                                  "text": "До 2х месяцев",
                                  "description": "",
                                  "position": 2,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "d05a4200-0a45-11ec-a55b-752faf1d2b0b",
                                  "text": "Больше 2х месяцев",
                                  "description": "",
                                  "position": 3,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                }
                              ],
                              "id": "b558ce40-0a45-11ec-a55b-752faf1d2b0b",
                              "name": "Сколько времени потребуется на подготовку проверки гипотезы?",
                              "defaultFieldName": null,
                              "description": null,
                              "position": 5,
                              "fieldForStatusId": null,
                              "categoryId": "5d2176d0-0984-11ec-a435-d7140b862b20",
                              "categoryName": "Проверка гипотезы",
                              "type": "Single",
                              "fieldType": "Single",
                              "mask": null,
                              "showOnCardView": false,
                              "defaultValue": null,
                              "disableExpression": null,
                              "isMandatory": true,
                              "isHidden": false,
                              "isBaseField": false,
                              "visibility": null,
                              "isReadOnly": false,
                              "maxLength": 0,
                              "displayMode": "",
                              "linkedFieldStatus": null,
                              "selectOnlyFromCollection": false,
                              "linkedCollection": null,
                              "defaultData": null,
                              "settings": null,
                              "useInCalendar": false,
                              "calendarDateType": null,
                              "defaultFieldNameType": "Default"
                            },
                            {
                              "data": [
                                {
                                  "id": "92797850-0984-11ec-a435-d7140b862b20",
                                  "text": "До 2х недель",
                                  "description": "",
                                  "position": 0,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "94ca1f10-0984-11ec-a435-d7140b862b20",
                                  "text": "2-4 недели",
                                  "description": "",
                                  "position": 1,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "9682a480-0984-11ec-a435-d7140b862b20",
                                  "text": "До 2х месяцев",
                                  "description": "",
                                  "position": 2,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "9bceff10-0984-11ec-a435-d7140b862b20",
                                  "text": "Больше 2х месяцев",
                                  "description": "",
                                  "position": 3,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                }
                              ],
                              "answers": [
                                {
                                  "id": "92797850-0984-11ec-a435-d7140b862b20",
                                  "text": "До 2х недель",
                                  "description": "",
                                  "position": 0,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "94ca1f10-0984-11ec-a435-d7140b862b20",
                                  "text": "2-4 недели",
                                  "description": "",
                                  "position": 1,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "9682a480-0984-11ec-a435-d7140b862b20",
                                  "text": "До 2х месяцев",
                                  "description": "",
                                  "position": 2,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                },
                                {
                                  "id": "9bceff10-0984-11ec-a435-d7140b862b20",
                                  "text": "Больше 2х месяцев",
                                  "description": "",
                                  "position": 3,
                                  "selected": false,
                                  "rowId": null,
                                  "scoringValue": 0
                                }
                              ],
                              "id": "825786b0-0984-11ec-a435-d7140b862b20",
                              "name": "Сколько времени потребуется непосредственно на проверку гипотезы - на получение результатов?",
                              "defaultFieldName": null,
                              "description": null,
                              "position": 6,
                              "fieldForStatusId": null,
                              "categoryId": "5d2176d0-0984-11ec-a435-d7140b862b20",
                              "categoryName": "Проверка гипотезы",
                              "type": "Single",
                              "fieldType": "Single",
                              "mask": null,
                              "showOnCardView": false,
                              "defaultValue": null,
                              "disableExpression": null,
                              "isMandatory": true,
                              "isHidden": false,
                              "isBaseField": false,
                              "visibility": null,
                              "isReadOnly": false,
                              "maxLength": 0,
                              "displayMode": "",
                              "linkedFieldStatus": null,
                              "selectOnlyFromCollection": false,
                              "linkedCollection": null,
                              "defaultData": null,
                              "settings": null,
                              "useInCalendar": false,
                              "calendarDateType": null,
                              "defaultFieldNameType": "Default"
                            },
                            {
                              "data": null,
                              "id": "b859f900-0984-11ec-a435-d7140b862b20",
                              "name": "По каким критериям мы будем оценивать результат проверки гипотезы?",
                              "defaultFieldName": null,
                              "description": "",
                              "position": 7,
                              "fieldForStatusId": null,
                              "categoryId": "5d2176d0-0984-11ec-a435-d7140b862b20",
                              "categoryName": "Проверка гипотезы",
                              "type": "Textarea",
                              "fieldType": "Textarea",
                              "mask": "",
                              "showOnCardView": false,
                              "defaultValue": "",
                              "disableExpression": "",
                              "isMandatory": true,
                              "isHidden": false,
                              "isBaseField": false,
                              "visibility": null,
                              "isReadOnly": false,
                              "maxLength": 0,
                              "displayMode": "",
                              "linkedFieldStatus": null,
                              "selectOnlyFromCollection": false,
                              "linkedCollection": null,
                              "defaultData": null,
                              "settings": null,
                              "useInCalendar": false,
                              "calendarDateType": null,
                              "defaultFieldNameType": "Default"
                            }
                          ]
                        }
                      ],
                      "responsibleForStatus": null,
                      "showChangeStatusButton": false,
                      "comment": null
                    },
                    "badgeRenderParams": null,
                    "eventRenderParams": null,
                    "newsRenderParams": null,
                    "thanksRenderParams": null,
                    "pollRenderParams": null,
                    "actions": [
                      "attach",
                      "file",
                      "markusers",
                      "mentionuser",
                      "mentiontag"
                    ],
                    "achievementsRenderParams": null,
                    "questsRenderParams": null
                  },
                  {
                    "id": "notices",
                    "idNumber": 22,
                    "displayName": "Объявления",
                    "componentRenderName": "notices",
                    "category": "publications",
                    "enabled": true,
                    "allowed": true,
                    "renderParams": {
                      "allowRichEdit": true,
                      "richEditAllowedMarks": null,
                      "allowNewsHeader": false,
                      "formId": "00000000-0000-0000-0000-000000000000",
                      "vPosition": 0,
                      "defaultViewType": "Feed"
                    },
                    "additionalFields": null,
                    "ideaRenderParams": null,
                    "badgeRenderParams": null,
                    "eventRenderParams": null,
                    "newsRenderParams": null,
                    "thanksRenderParams": null,
                    "pollRenderParams": null,
                    "actions": [
                      "attach",
                      "file",
                      "markusers",
                      "mentionuser",
                      "mentiontag"
                    ],
                    "achievementsRenderParams": null,
                    "questsRenderParams": null
                  },
                  {
                    "id": "events",
                    "idNumber": 23,
                    "displayName": "События",
                    "componentRenderName": "events",
                    "category": "publications",
                    "enabled": true,
                    "allowed": true,
                    "renderParams": {
                      "allowCreateVideoConference": false,
                      "allowRichEdit": true,
                      "richEditAllowedMarks": null,
                      "allowNewsHeader": true,
                      "formId": "00000000-0000-0000-0000-000000000000",
                      "vPosition": 0,
                      "defaultViewType": "Feed"
                    },
                    "additionalFields": null,
                    "ideaRenderParams": null,
                    "badgeRenderParams": null,
                    "eventRenderParams": {
                      "allowCreateVideoConference": false,
                      "allowRichEdit": true,
                      "richEditAllowedMarks": null,
                      "allowNewsHeader": true,
                      "formId": "00000000-0000-0000-0000-000000000000",
                      "vPosition": 0,
                      "defaultViewType": "Feed"
                    },
                    "newsRenderParams": null,
                    "thanksRenderParams": null,
                    "pollRenderParams": null,
                    "actions": [
                      "attach",
                      "file",
                      "markusers",
                      "mentionuser",
                      "mentiontag"
                    ],
                    "achievementsRenderParams": null,
                    "questsRenderParams": null
                  },
                  {
                    "id": "badges",
                    "idNumber": 0,
                    "displayName": "Бейджи",
                    "componentRenderName": "badges",
                    "category": "publications",
                    "enabled": true,
                    "allowed": true,
                    "renderParams": {
                      "badges": [
                        {
                          "id": 7071,
                          "displayName": "Пчелки в \"Согазе\"",
                          "description": "3-хмесячный соревновательный проект для сотрудников контакт-центра про ПЧЕЛ:)",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/16f73320-3392-42de-acb1-da8dfa9f817e.png",
                          "badgeUid": "af44da93-7ec6-4e45-ba33-9ef3c6bf0ebc",
                          "isActive": true
                        },
                        {
                          "id": 7072,
                          "displayName": "КСС Intercomm для Custis",
                          "description": "КСС с геймификацией для очень въедливого клиента - IT-компании Custis",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/1a727b51-356f-45a6-8b88-269fa964dfd2.png",
                          "badgeUid": "1b994e53-43fc-4426-8f89-8c257632fcd0",
                          "isActive": true
                        },
                        {
                          "id": 7073,
                          "displayName": "Проект Cesar Space",
                          "description": "Краткосрочный проект для \"Цезарь сателлит\" с экспертной поддержкой",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/785205d6-e55e-4a21-b686-f48c03d14267.png",
                          "badgeUid": "411f93c7-548c-466b-baa7-ca750a98722e",
                          "isActive": true
                        },
                        {
                          "id": 9157,
                          "displayName": "Генератор идей",
                          "description": "",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/c96f59fc-ff8a-4b61-a7f4-c00dd54d8c20.png",
                          "badgeUid": "87f75245-939a-4312-8bc0-28e36379e2be",
                          "isActive": true
                        },
                        {
                          "id": 9357,
                          "displayName": "Соучастник",
                          "description": "",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/d4bd15be-5f9e-4a6b-90b5-48b8dcfc3038.png",
                          "badgeUid": "7630a50b-1f78-4a92-af25-7bc1171a4835",
                          "isActive": true
                        },
                        {
                          "id": 9363,
                          "displayName": "Инвестор",
                          "description": "",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/436d444a-2442-453a-8d56-4463d0c5c028.png",
                          "badgeUid": "ae4fa945-9d2a-4e0e-bfe3-6bb1f7352eee",
                          "isActive": true
                        },
                        {
                          "id": 4912,
                          "displayName": "Выполнен личный план продаж за 2018 год",
                          "description": "Вот:)",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/81b4f417-891c-45a6-959b-7825bb0b3af7.png",
                          "badgeUid": "6f54f024-c9e0-4ba0-90c6-a21f64278717",
                          "isActive": true
                        },
                        {
                          "id": 4622,
                          "displayName": "Консалтинг для \"ОТС\"",
                          "description": "Корпоративная геймифицированная сеть KOMANZA",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/f2c860e7-bd63-4b97-af96-b77eec6ecca3.png",
                          "badgeUid": "ec4858d8-1693-49e6-b755-81f5f0cede55",
                          "isActive": true
                        },
                        {
                          "id": 4621,
                          "displayName": "Сделка с ГК \"Сапсан\"",
                          "description": "Нет, не поезд. Девелоперы из ближнего Подмосковья",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/d2fae59b-d163-41ce-a5c3-c14284f9d526.png",
                          "badgeUid": "35c7aa17-7ed6-45bd-8f67-80cb8a23415e",
                          "isActive": true
                        },
                        {
                          "id": 4281,
                          "displayName": "Корпоративная социальная сеть для \"Росбанка\"",
                          "description": "9500 человек: социалка, SG-coins, бейджики - все, как мы любим)",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/433a238d-d863-4a85-a2cc-ee489c5ef95f.png",
                          "badgeUid": "fa4006d5-0f50-4e42-8828-627028e18fd2",
                          "isActive": true
                        },
                        {
                          "id": 4138,
                          "displayName": "\"Игра престолов\" в ДКБ",
                          "description": "Внутрибанковское соревнование в метафоре известного сериала",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/4631becc-c433-49c1-aaad-a8d8709d4e13.png",
                          "badgeUid": "55eed2a7-94c5-42fe-9543-b41d832bcf33",
                          "isActive": true
                        },
                        {
                          "id": 3135,
                          "displayName": "СанТехБейдж!",
                          "description": "За упертость в борьбе с катаклизмом!",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/c89cca7f-6178-4819-b403-c1685a199df3.png",
                          "badgeUid": "91a3cb5e-c3a0-409b-97eb-f36083df6073",
                          "isActive": true
                        },
                        {
                          "id": 1434,
                          "displayName": "Хакер Linkedin",
                          "description": "За суперхак линка с просмотром контактов: порекомендовать кого-то другу, после чего тебе открываются контакты этого кого-то. ",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/d51327eb-1add-42da-b434-b866dcc86fad.png",
                          "badgeUid": "eb31cad5-4eb7-4eee-a276-4db58494c51f",
                          "isActive": true
                        },
                        {
                          "id": 1334,
                          "displayName": "Тролль произвольного уровня",
                          "description": "За троллинг, тонкий и не очень :-р",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/c25b5a3f-cb25-4547-b375-6ef0ca99925f.png",
                          "badgeUid": "b36a19c0-f8cf-4541-ac70-fe5039e8676a",
                          "isActive": true
                        },
                        {
                          "id": 1508,
                          "displayName": "Cамомимимишность",
                          "description": "Ну, вы поняли :)",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/9382ebee-9914-4fa2-af21-94dcbfc67f0e.png",
                          "badgeUid": "ee09f62c-9560-4aac-bf16-68f140f130c3",
                          "isActive": true
                        },
                        {
                          "id": 3069,
                          "displayName": "Не болтай!",
                          "description": "Когда совсем сил больше нет и поработать так охота в тишине, что только бейджик и спасает (хокку).",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/0efc8662-5eb3-4555-a6f8-3530449f9532.png",
                          "badgeUid": "bc116f83-265d-43a7-9a8b-7b6eeb1ad2ff",
                          "isActive": true
                        },
                        {
                          "id": 3348,
                          "displayName": "Белоснежчатость",
                          "description": "",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/29146f4e-ef5b-4af9-a8b6-4638c4ee35fd.png",
                          "badgeUid": "febe030f-a9a7-4379-84b6-d1a700bf7a23",
                          "isActive": true
                        },
                        {
                          "id": 4596,
                          "displayName": "Талант тестировщика",
                          "description": "За сложный найденный баг. Пусть даже случайно.",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/0e05e982-5a74-46e1-b68c-0b327cd5cc75.png",
                          "badgeUid": "b98e4586-43f4-4749-b5c7-c1e5782624de",
                          "isActive": true
                        }
                      ],
                      "allowRichEdit": true,
                      "richEditAllowedMarks": null,
                      "allowNewsHeader": false,
                      "formId": "00000000-0000-0000-0000-000000000000",
                      "vPosition": 0,
                      "defaultViewType": "Feed"
                    },
                    "additionalFields": null,
                    "ideaRenderParams": null,
                    "badgeRenderParams": {
                      "badges": [
                        {
                          "id": 7071,
                          "displayName": "Пчелки в \"Согазе\"",
                          "description": "3-хмесячный соревновательный проект для сотрудников контакт-центра про ПЧЕЛ:)",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/16f73320-3392-42de-acb1-da8dfa9f817e.png",
                          "badgeUid": "af44da93-7ec6-4e45-ba33-9ef3c6bf0ebc",
                          "isActive": true
                        },
                        {
                          "id": 7072,
                          "displayName": "КСС Intercomm для Custis",
                          "description": "КСС с геймификацией для очень въедливого клиента - IT-компании Custis",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/1a727b51-356f-45a6-8b88-269fa964dfd2.png",
                          "badgeUid": "1b994e53-43fc-4426-8f89-8c257632fcd0",
                          "isActive": true
                        },
                        {
                          "id": 7073,
                          "displayName": "Проект Cesar Space",
                          "description": "Краткосрочный проект для \"Цезарь сателлит\" с экспертной поддержкой",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/785205d6-e55e-4a21-b686-f48c03d14267.png",
                          "badgeUid": "411f93c7-548c-466b-baa7-ca750a98722e",
                          "isActive": true
                        },
                        {
                          "id": 9157,
                          "displayName": "Генератор идей",
                          "description": "",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/c96f59fc-ff8a-4b61-a7f4-c00dd54d8c20.png",
                          "badgeUid": "87f75245-939a-4312-8bc0-28e36379e2be",
                          "isActive": true
                        },
                        {
                          "id": 9357,
                          "displayName": "Соучастник",
                          "description": "",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/d4bd15be-5f9e-4a6b-90b5-48b8dcfc3038.png",
                          "badgeUid": "7630a50b-1f78-4a92-af25-7bc1171a4835",
                          "isActive": true
                        },
                        {
                          "id": 9363,
                          "displayName": "Инвестор",
                          "description": "",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/436d444a-2442-453a-8d56-4463d0c5c028.png",
                          "badgeUid": "ae4fa945-9d2a-4e0e-bfe3-6bb1f7352eee",
                          "isActive": true
                        },
                        {
                          "id": 4912,
                          "displayName": "Выполнен личный план продаж за 2018 год",
                          "description": "Вот:)",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/81b4f417-891c-45a6-959b-7825bb0b3af7.png",
                          "badgeUid": "6f54f024-c9e0-4ba0-90c6-a21f64278717",
                          "isActive": true
                        },
                        {
                          "id": 4622,
                          "displayName": "Консалтинг для \"ОТС\"",
                          "description": "Корпоративная геймифицированная сеть KOMANZA",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/f2c860e7-bd63-4b97-af96-b77eec6ecca3.png",
                          "badgeUid": "ec4858d8-1693-49e6-b755-81f5f0cede55",
                          "isActive": true
                        },
                        {
                          "id": 4621,
                          "displayName": "Сделка с ГК \"Сапсан\"",
                          "description": "Нет, не поезд. Девелоперы из ближнего Подмосковья",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/d2fae59b-d163-41ce-a5c3-c14284f9d526.png",
                          "badgeUid": "35c7aa17-7ed6-45bd-8f67-80cb8a23415e",
                          "isActive": true
                        },
                        {
                          "id": 4281,
                          "displayName": "Корпоративная социальная сеть для \"Росбанка\"",
                          "description": "9500 человек: социалка, SG-coins, бейджики - все, как мы любим)",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/433a238d-d863-4a85-a2cc-ee489c5ef95f.png",
                          "badgeUid": "fa4006d5-0f50-4e42-8828-627028e18fd2",
                          "isActive": true
                        },
                        {
                          "id": 4138,
                          "displayName": "\"Игра престолов\" в ДКБ",
                          "description": "Внутрибанковское соревнование в метафоре известного сериала",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/4631becc-c433-49c1-aaad-a8d8709d4e13.png",
                          "badgeUid": "55eed2a7-94c5-42fe-9543-b41d832bcf33",
                          "isActive": true
                        },
                        {
                          "id": 3135,
                          "displayName": "СанТехБейдж!",
                          "description": "За упертость в борьбе с катаклизмом!",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/c89cca7f-6178-4819-b403-c1685a199df3.png",
                          "badgeUid": "91a3cb5e-c3a0-409b-97eb-f36083df6073",
                          "isActive": true
                        },
                        {
                          "id": 1434,
                          "displayName": "Хакер Linkedin",
                          "description": "За суперхак линка с просмотром контактов: порекомендовать кого-то другу, после чего тебе открываются контакты этого кого-то. ",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/d51327eb-1add-42da-b434-b866dcc86fad.png",
                          "badgeUid": "eb31cad5-4eb7-4eee-a276-4db58494c51f",
                          "isActive": true
                        },
                        {
                          "id": 1334,
                          "displayName": "Тролль произвольного уровня",
                          "description": "За троллинг, тонкий и не очень :-р",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/c25b5a3f-cb25-4547-b375-6ef0ca99925f.png",
                          "badgeUid": "b36a19c0-f8cf-4541-ac70-fe5039e8676a",
                          "isActive": true
                        },
                        {
                          "id": 1508,
                          "displayName": "Cамомимимишность",
                          "description": "Ну, вы поняли :)",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/9382ebee-9914-4fa2-af21-94dcbfc67f0e.png",
                          "badgeUid": "ee09f62c-9560-4aac-bf16-68f140f130c3",
                          "isActive": true
                        },
                        {
                          "id": 3069,
                          "displayName": "Не болтай!",
                          "description": "Когда совсем сил больше нет и поработать так охота в тишине, что только бейджик и спасает (хокку).",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/0efc8662-5eb3-4555-a6f8-3530449f9532.png",
                          "badgeUid": "bc116f83-265d-43a7-9a8b-7b6eeb1ad2ff",
                          "isActive": true
                        },
                        {
                          "id": 3348,
                          "displayName": "Белоснежчатость",
                          "description": "",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/29146f4e-ef5b-4af9-a8b6-4638c4ee35fd.png",
                          "badgeUid": "febe030f-a9a7-4379-84b6-d1a700bf7a23",
                          "isActive": true
                        },
                        {
                          "id": 4596,
                          "displayName": "Талант тестировщика",
                          "description": "За сложный найденный баг. Пусть даже случайно.",
                          "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/0e05e982-5a74-46e1-b68c-0b327cd5cc75.png",
                          "badgeUid": "b98e4586-43f4-4749-b5c7-c1e5782624de",
                          "isActive": true
                        }
                      ],
                      "allowRichEdit": true,
                      "richEditAllowedMarks": null,
                      "allowNewsHeader": false,
                      "formId": "00000000-0000-0000-0000-000000000000",
                      "vPosition": 0,
                      "defaultViewType": "Feed"
                    },
                    "eventRenderParams": null,
                    "newsRenderParams": null,
                    "thanksRenderParams": null,
                    "pollRenderParams": null,
                    "actions": [
                      "attach",
                      "file",
                      "markusers",
                      "mentionuser",
                      "mentiontag"
                    ],
                    "achievementsRenderParams": null,
                    "questsRenderParams": null
                  }
                ],
                statistic: "users",
                title: "Динамика активности пользователей"
              }
            }
          }),
          generateWidget({
            type: widgets.types.layout,
            data: [
              generateColumn({
                styles: {width: "33.333333333333336%"},
                items: [
                  generateWidget({
                    type: widgets.types.statistic,
                    settings: {
                      data: {
                        chart: "barGroups",
                        datesType: "month",
                        maxItems: "7",
                        module: "activity",
                        postTypes: [
                          {
                            "id": "news",
                            "idNumber": 5,
                            "displayName": "Новости",
                            "componentRenderName": "news",
                            "category": "publications",
                            "enabled": true,
                            "allowed": true,
                            "renderParams": {
                              "allowRichEdit": true,
                              "richEditAllowedMarks": null,
                              "allowNewsHeader": true,
                              "formId": "00000000-0000-0000-0000-000000000000",
                              "vPosition": 0,
                              "defaultViewType": "Feed"
                            },
                            "additionalFields": null,
                            "ideaRenderParams": null,
                            "badgeRenderParams": null,
                            "eventRenderParams": null,
                            "newsRenderParams": {
                              "allowRichEdit": true,
                              "richEditAllowedMarks": null,
                              "allowNewsHeader": true,
                              "formId": "00000000-0000-0000-0000-000000000000",
                              "vPosition": 0,
                              "defaultViewType": "Feed"
                            },
                            "thanksRenderParams": null,
                            "pollRenderParams": null,
                            "actions": [
                              "attach",
                              "file",
                              "markusers",
                              "mentionuser",
                              "mentiontag"
                            ],
                            "achievementsRenderParams": null,
                            "questsRenderParams": null
                          }
                        ],
                        statistic: "users",
                        title: "Динамика активности пользователей"
                      }
                    }
                  }),
                  generateWidget({
                    type: widgets.types.statistic,
                    settings: {
                      data: {
                        chart: "barGroups",
                        datesType: "month",
                        maxItems: "7",
                        module: "activity",
                        postTypes: [
                          {
                            "id": "polls",
                            "idNumber": 7,
                            "displayName": "Опросы",
                            "componentRenderName": "polls",
                            "category": "publications",
                            "enabled": true,
                            "allowed": true,
                            "renderParams": {
                              "allowRichEdit": true,
                              "richEditAllowedMarks": null,
                              "allowNewsHeader": false,
                              "formId": "00000000-0000-0000-0000-000000000000",
                              "vPosition": 0,
                              "defaultViewType": "Feed"
                            },
                            "additionalFields": null,
                            "ideaRenderParams": null,
                            "badgeRenderParams": null,
                            "eventRenderParams": null,
                            "newsRenderParams": null,
                            "thanksRenderParams": null,
                            "pollRenderParams": {
                              "allowRichEdit": true,
                              "richEditAllowedMarks": null,
                              "allowNewsHeader": false,
                              "formId": "00000000-0000-0000-0000-000000000000",
                              "vPosition": 0,
                              "defaultViewType": "Feed"
                            },
                            "actions": [
                              "attach",
                              "file",
                              "markusers",
                              "mentionuser",
                              "mentiontag"
                            ],
                            "achievementsRenderParams": null,
                            "questsRenderParams": null
                          }
                        ],
                        statistic: "users",
                        title: "Динамика активности пользователей"
                      }
                    }
                  }),
                  generateWidget({
                    type: widgets.types.statistic,
                    settings: {
                      data: {
                        chart: "barGroups",
                        datesType: "month",
                        maxItems: "7",
                        module: "activity",
                        postTypes: [
                          {
                            "id": "ideas",
                            "idNumber": 21,
                            "displayName": "Идеи",
                            "componentRenderName": "ideas",
                            "category": "publications",
                            "enabled": true,
                            "allowed": true,
                            "renderParams": {
                              "allowRichEdit": true,
                              "richEditAllowedMarks": null,
                              "allowNewsHeader": true,
                              "formId": "00000000-0000-0000-0000-000000000000",
                              "vPosition": 0,
                              "defaultViewType": "Feed"
                            },
                            "additionalFields": null,
                            "ideaRenderParams": {},
                            "badgeRenderParams": null,
                            "eventRenderParams": null,
                            "newsRenderParams": null,
                            "thanksRenderParams": null,
                            "pollRenderParams": null,
                            "actions": [
                              "attach",
                              "file",
                              "markusers",
                              "mentionuser",
                              "mentiontag"
                            ],
                            "achievementsRenderParams": null,
                            "questsRenderParams": null
                          }
                        ],
                        statistic: "users",
                        title: "Динамика активности пользователей"
                      }
                    }
                  }),
                  generateWidget({
                    type: widgets.types.statistic,
                    settings: {
                      data: {
                        chart: "barGroups",
                        datesType: "month",
                        maxItems: "7",
                        module: "activity",
                        postTypes: [
                          {
                            "id": "notices",
                            "idNumber": 22,
                            "displayName": "Объявления",
                            "componentRenderName": "notices",
                            "category": "publications",
                            "enabled": true,
                            "allowed": true,
                            "renderParams": {
                              "allowRichEdit": true,
                              "richEditAllowedMarks": null,
                              "allowNewsHeader": false,
                              "formId": "00000000-0000-0000-0000-000000000000",
                              "vPosition": 0,
                              "defaultViewType": "Feed"
                            },
                            "additionalFields": null,
                            "ideaRenderParams": null,
                            "badgeRenderParams": null,
                            "eventRenderParams": null,
                            "newsRenderParams": null,
                            "thanksRenderParams": null,
                            "pollRenderParams": null,
                            "actions": [
                              "attach",
                              "file",
                              "markusers",
                              "mentionuser",
                              "mentiontag"
                            ],
                            "achievementsRenderParams": null,
                            "questsRenderParams": null
                          }
                        ],
                        statistic: "users",
                        title: "Динамика активности пользователей"
                      }
                    }
                  }),
                  generateWidget({
                    type: widgets.types.statistic,
                    settings: {
                      data: {
                        chart: "barGroups",
                        datesType: "month",
                        maxItems: "7",
                        module: "activity",
                        postTypes: [
                          {
                            "id": "events",
                            "idNumber": 23,
                            "displayName": "События",
                            "componentRenderName": "events",
                            "category": "publications",
                            "enabled": true,
                            "allowed": true,
                            "renderParams": {
                              "allowCreateVideoConference": false,
                              "allowRichEdit": true,
                              "richEditAllowedMarks": null,
                              "allowNewsHeader": true,
                              "formId": "00000000-0000-0000-0000-000000000000",
                              "vPosition": 0,
                              "defaultViewType": "Feed"
                            },
                            "additionalFields": null,
                            "ideaRenderParams": null,
                            "badgeRenderParams": null,
                            "eventRenderParams": {
                              "allowCreateVideoConference": false,
                              "allowRichEdit": true,
                              "richEditAllowedMarks": null,
                              "allowNewsHeader": true,
                              "formId": "00000000-0000-0000-0000-000000000000",
                              "vPosition": 0,
                              "defaultViewType": "Feed"
                            },
                            "newsRenderParams": null,
                            "thanksRenderParams": null,
                            "pollRenderParams": null,
                            "actions": [
                              "attach",
                              "file",
                              "markusers",
                              "mentionuser",
                              "mentiontag"
                            ],
                            "achievementsRenderParams": null,
                            "questsRenderParams": null
                          }
                        ],
                        statistic: "users",
                        title: "Динамика активности пользователей"
                      }
                    }
                  }),
                  generateWidget({
                    type: widgets.types.statistic,
                    settings: {
                      data: {
                        chart: "barGroups",
                        datesType: "month",
                        maxItems: "7",
                        module: "activity",
                        postTypes: [
                          {
                            "id": "badges",
                            "idNumber": 0,
                            "displayName": "Бейджи",
                            "componentRenderName": "badges",
                            "category": "publications",
                            "enabled": true,
                            "allowed": true,
                            "renderParams": {
                              "badges": [
                                {
                                  "id": 7071,
                                  "displayName": "Пчелки в \"Согазе\"",
                                  "description": "3-хмесячный соревновательный проект для сотрудников контакт-центра про ПЧЕЛ:)",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/16f73320-3392-42de-acb1-da8dfa9f817e.png",
                                  "badgeUid": "af44da93-7ec6-4e45-ba33-9ef3c6bf0ebc",
                                  "isActive": true
                                },
                                {
                                  "id": 7072,
                                  "displayName": "КСС Intercomm для Custis",
                                  "description": "КСС с геймификацией для очень въедливого клиента - IT-компании Custis",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/1a727b51-356f-45a6-8b88-269fa964dfd2.png",
                                  "badgeUid": "1b994e53-43fc-4426-8f89-8c257632fcd0",
                                  "isActive": true
                                },
                                {
                                  "id": 7073,
                                  "displayName": "Проект Cesar Space",
                                  "description": "Краткосрочный проект для \"Цезарь сателлит\" с экспертной поддержкой",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/785205d6-e55e-4a21-b686-f48c03d14267.png",
                                  "badgeUid": "411f93c7-548c-466b-baa7-ca750a98722e",
                                  "isActive": true
                                },
                                {
                                  "id": 9157,
                                  "displayName": "Генератор идей",
                                  "description": "",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/c96f59fc-ff8a-4b61-a7f4-c00dd54d8c20.png",
                                  "badgeUid": "87f75245-939a-4312-8bc0-28e36379e2be",
                                  "isActive": true
                                },
                                {
                                  "id": 9357,
                                  "displayName": "Соучастник",
                                  "description": "",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/d4bd15be-5f9e-4a6b-90b5-48b8dcfc3038.png",
                                  "badgeUid": "7630a50b-1f78-4a92-af25-7bc1171a4835",
                                  "isActive": true
                                },
                                {
                                  "id": 9363,
                                  "displayName": "Инвестор",
                                  "description": "",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/436d444a-2442-453a-8d56-4463d0c5c028.png",
                                  "badgeUid": "ae4fa945-9d2a-4e0e-bfe3-6bb1f7352eee",
                                  "isActive": true
                                },
                                {
                                  "id": 4912,
                                  "displayName": "Выполнен личный план продаж за 2018 год",
                                  "description": "Вот:)",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/81b4f417-891c-45a6-959b-7825bb0b3af7.png",
                                  "badgeUid": "6f54f024-c9e0-4ba0-90c6-a21f64278717",
                                  "isActive": true
                                },
                                {
                                  "id": 4622,
                                  "displayName": "Консалтинг для \"ОТС\"",
                                  "description": "Корпоративная геймифицированная сеть KOMANZA",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/f2c860e7-bd63-4b97-af96-b77eec6ecca3.png",
                                  "badgeUid": "ec4858d8-1693-49e6-b755-81f5f0cede55",
                                  "isActive": true
                                },
                                {
                                  "id": 4621,
                                  "displayName": "Сделка с ГК \"Сапсан\"",
                                  "description": "Нет, не поезд. Девелоперы из ближнего Подмосковья",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/d2fae59b-d163-41ce-a5c3-c14284f9d526.png",
                                  "badgeUid": "35c7aa17-7ed6-45bd-8f67-80cb8a23415e",
                                  "isActive": true
                                },
                                {
                                  "id": 4281,
                                  "displayName": "Корпоративная социальная сеть для \"Росбанка\"",
                                  "description": "9500 человек: социалка, SG-coins, бейджики - все, как мы любим)",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/433a238d-d863-4a85-a2cc-ee489c5ef95f.png",
                                  "badgeUid": "fa4006d5-0f50-4e42-8828-627028e18fd2",
                                  "isActive": true
                                },
                                {
                                  "id": 4138,
                                  "displayName": "\"Игра престолов\" в ДКБ",
                                  "description": "Внутрибанковское соревнование в метафоре известного сериала",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/4631becc-c433-49c1-aaad-a8d8709d4e13.png",
                                  "badgeUid": "55eed2a7-94c5-42fe-9543-b41d832bcf33",
                                  "isActive": true
                                },
                                {
                                  "id": 3135,
                                  "displayName": "СанТехБейдж!",
                                  "description": "За упертость в борьбе с катаклизмом!",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/c89cca7f-6178-4819-b403-c1685a199df3.png",
                                  "badgeUid": "91a3cb5e-c3a0-409b-97eb-f36083df6073",
                                  "isActive": true
                                },
                                {
                                  "id": 1434,
                                  "displayName": "Хакер Linkedin",
                                  "description": "За суперхак линка с просмотром контактов: порекомендовать кого-то другу, после чего тебе открываются контакты этого кого-то. ",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/d51327eb-1add-42da-b434-b866dcc86fad.png",
                                  "badgeUid": "eb31cad5-4eb7-4eee-a276-4db58494c51f",
                                  "isActive": true
                                },
                                {
                                  "id": 1334,
                                  "displayName": "Тролль произвольного уровня",
                                  "description": "За троллинг, тонкий и не очень :-р",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/c25b5a3f-cb25-4547-b375-6ef0ca99925f.png",
                                  "badgeUid": "b36a19c0-f8cf-4541-ac70-fe5039e8676a",
                                  "isActive": true
                                },
                                {
                                  "id": 1508,
                                  "displayName": "Cамомимимишность",
                                  "description": "Ну, вы поняли :)",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/9382ebee-9914-4fa2-af21-94dcbfc67f0e.png",
                                  "badgeUid": "ee09f62c-9560-4aac-bf16-68f140f130c3",
                                  "isActive": true
                                },
                                {
                                  "id": 3069,
                                  "displayName": "Не болтай!",
                                  "description": "Когда совсем сил больше нет и поработать так охота в тишине, что только бейджик и спасает (хокку).",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/0efc8662-5eb3-4555-a6f8-3530449f9532.png",
                                  "badgeUid": "bc116f83-265d-43a7-9a8b-7b6eeb1ad2ff",
                                  "isActive": true
                                },
                                {
                                  "id": 3348,
                                  "displayName": "Белоснежчатость",
                                  "description": "",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/29146f4e-ef5b-4af9-a8b6-4638c4ee35fd.png",
                                  "badgeUid": "febe030f-a9a7-4379-84b6-d1a700bf7a23",
                                  "isActive": true
                                },
                                {
                                  "id": 4596,
                                  "displayName": "Талант тестировщика",
                                  "description": "За сложный найденный баг. Пусть даже случайно.",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/0e05e982-5a74-46e1-b68c-0b327cd5cc75.png",
                                  "badgeUid": "b98e4586-43f4-4749-b5c7-c1e5782624de",
                                  "isActive": true
                                }
                              ],
                              "allowRichEdit": true,
                              "richEditAllowedMarks": null,
                              "allowNewsHeader": false,
                              "formId": "00000000-0000-0000-0000-000000000000",
                              "vPosition": 0,
                              "defaultViewType": "Feed"
                            },
                            "additionalFields": null,
                            "ideaRenderParams": null,
                            "badgeRenderParams": {
                              "badges": [
                                {
                                  "id": 7071,
                                  "displayName": "Пчелки в \"Согазе\"",
                                  "description": "3-хмесячный соревновательный проект для сотрудников контакт-центра про ПЧЕЛ:)",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/16f73320-3392-42de-acb1-da8dfa9f817e.png",
                                  "badgeUid": "af44da93-7ec6-4e45-ba33-9ef3c6bf0ebc",
                                  "isActive": true
                                },
                                {
                                  "id": 7072,
                                  "displayName": "КСС Intercomm для Custis",
                                  "description": "КСС с геймификацией для очень въедливого клиента - IT-компании Custis",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/1a727b51-356f-45a6-8b88-269fa964dfd2.png",
                                  "badgeUid": "1b994e53-43fc-4426-8f89-8c257632fcd0",
                                  "isActive": true
                                },
                                {
                                  "id": 7073,
                                  "displayName": "Проект Cesar Space",
                                  "description": "Краткосрочный проект для \"Цезарь сателлит\" с экспертной поддержкой",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/785205d6-e55e-4a21-b686-f48c03d14267.png",
                                  "badgeUid": "411f93c7-548c-466b-baa7-ca750a98722e",
                                  "isActive": true
                                },
                                {
                                  "id": 9157,
                                  "displayName": "Генератор идей",
                                  "description": "",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/c96f59fc-ff8a-4b61-a7f4-c00dd54d8c20.png",
                                  "badgeUid": "87f75245-939a-4312-8bc0-28e36379e2be",
                                  "isActive": true
                                },
                                {
                                  "id": 9357,
                                  "displayName": "Соучастник",
                                  "description": "",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/d4bd15be-5f9e-4a6b-90b5-48b8dcfc3038.png",
                                  "badgeUid": "7630a50b-1f78-4a92-af25-7bc1171a4835",
                                  "isActive": true
                                },
                                {
                                  "id": 9363,
                                  "displayName": "Инвестор",
                                  "description": "",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/436d444a-2442-453a-8d56-4463d0c5c028.png",
                                  "badgeUid": "ae4fa945-9d2a-4e0e-bfe3-6bb1f7352eee",
                                  "isActive": true
                                },
                                {
                                  "id": 4912,
                                  "displayName": "Выполнен личный план продаж за 2018 год",
                                  "description": "Вот:)",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/81b4f417-891c-45a6-959b-7825bb0b3af7.png",
                                  "badgeUid": "6f54f024-c9e0-4ba0-90c6-a21f64278717",
                                  "isActive": true
                                },
                                {
                                  "id": 4622,
                                  "displayName": "Консалтинг для \"ОТС\"",
                                  "description": "Корпоративная геймифицированная сеть KOMANZA",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/f2c860e7-bd63-4b97-af96-b77eec6ecca3.png",
                                  "badgeUid": "ec4858d8-1693-49e6-b755-81f5f0cede55",
                                  "isActive": true
                                },
                                {
                                  "id": 4621,
                                  "displayName": "Сделка с ГК \"Сапсан\"",
                                  "description": "Нет, не поезд. Девелоперы из ближнего Подмосковья",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/d2fae59b-d163-41ce-a5c3-c14284f9d526.png",
                                  "badgeUid": "35c7aa17-7ed6-45bd-8f67-80cb8a23415e",
                                  "isActive": true
                                },
                                {
                                  "id": 4281,
                                  "displayName": "Корпоративная социальная сеть для \"Росбанка\"",
                                  "description": "9500 человек: социалка, SG-coins, бейджики - все, как мы любим)",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/433a238d-d863-4a85-a2cc-ee489c5ef95f.png",
                                  "badgeUid": "fa4006d5-0f50-4e42-8828-627028e18fd2",
                                  "isActive": true
                                },
                                {
                                  "id": 4138,
                                  "displayName": "\"Игра престолов\" в ДКБ",
                                  "description": "Внутрибанковское соревнование в метафоре известного сериала",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/4631becc-c433-49c1-aaad-a8d8709d4e13.png",
                                  "badgeUid": "55eed2a7-94c5-42fe-9543-b41d832bcf33",
                                  "isActive": true
                                },
                                {
                                  "id": 3135,
                                  "displayName": "СанТехБейдж!",
                                  "description": "За упертость в борьбе с катаклизмом!",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/c89cca7f-6178-4819-b403-c1685a199df3.png",
                                  "badgeUid": "91a3cb5e-c3a0-409b-97eb-f36083df6073",
                                  "isActive": true
                                },
                                {
                                  "id": 1434,
                                  "displayName": "Хакер Linkedin",
                                  "description": "За суперхак линка с просмотром контактов: порекомендовать кого-то другу, после чего тебе открываются контакты этого кого-то. ",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/d51327eb-1add-42da-b434-b866dcc86fad.png",
                                  "badgeUid": "eb31cad5-4eb7-4eee-a276-4db58494c51f",
                                  "isActive": true
                                },
                                {
                                  "id": 1334,
                                  "displayName": "Тролль произвольного уровня",
                                  "description": "За троллинг, тонкий и не очень :-р",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/c25b5a3f-cb25-4547-b375-6ef0ca99925f.png",
                                  "badgeUid": "b36a19c0-f8cf-4541-ac70-fe5039e8676a",
                                  "isActive": true
                                },
                                {
                                  "id": 1508,
                                  "displayName": "Cамомимимишность",
                                  "description": "Ну, вы поняли :)",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/9382ebee-9914-4fa2-af21-94dcbfc67f0e.png",
                                  "badgeUid": "ee09f62c-9560-4aac-bf16-68f140f130c3",
                                  "isActive": true
                                },
                                {
                                  "id": 3069,
                                  "displayName": "Не болтай!",
                                  "description": "Когда совсем сил больше нет и поработать так охота в тишине, что только бейджик и спасает (хокку).",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/0efc8662-5eb3-4555-a6f8-3530449f9532.png",
                                  "badgeUid": "bc116f83-265d-43a7-9a8b-7b6eeb1ad2ff",
                                  "isActive": true
                                },
                                {
                                  "id": 3348,
                                  "displayName": "Белоснежчатость",
                                  "description": "",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/29146f4e-ef5b-4af9-a8b6-4638c4ee35fd.png",
                                  "badgeUid": "febe030f-a9a7-4379-84b6-d1a700bf7a23",
                                  "isActive": true
                                },
                                {
                                  "id": 4596,
                                  "displayName": "Талант тестировщика",
                                  "description": "За сложный найденный баг. Пусть даже случайно.",
                                  "iconUrl": "https://dreamteam.app.pryaniky.com/Img/Attachments/Preview/180m180/0e05e982-5a74-46e1-b68c-0b327cd5cc75.png",
                                  "badgeUid": "b98e4586-43f4-4749-b5c7-c1e5782624de",
                                  "isActive": true
                                }
                              ],
                              "allowRichEdit": true,
                              "richEditAllowedMarks": null,
                              "allowNewsHeader": false,
                              "formId": "00000000-0000-0000-0000-000000000000",
                              "vPosition": 0,
                              "defaultViewType": "Feed"
                            },
                            "eventRenderParams": null,
                            "newsRenderParams": null,
                            "thanksRenderParams": null,
                            "pollRenderParams": null,
                            "actions": [
                              "attach",
                              "file",
                              "markusers",
                              "mentionuser",
                              "mentiontag"
                            ],
                            "achievementsRenderParams": null,
                            "questsRenderParams": null
                          }
                        ],
                        statistic: "users",
                        title: "Динамика активности пользователей"
                      }
                    }
                  }),
                  generateWidget({
                    type: widgets.types.statistic,
                    settings: {
                      data: {
                        chart: "barGroups",
                        datesType: "month",
                        maxItems: "7",
                        module: "activity",
                        postTypes: [
                          {
                            "id": "creativetasks",
                            "idNumber": 6,
                            "displayName": "Конкурсы",
                            "componentRenderName": "creativetasks",
                            "category": "publications",
                            "enabled": true,
                            "allowed": true,
                            "renderParams": {
                              "allowRichEdit": true,
                              "richEditAllowedMarks": null,
                              "allowNewsHeader": false,
                              "formId": "00000000-0000-0000-0000-000000000000",
                              "vPosition": 0,
                              "defaultViewType": "Feed"
                            },
                            "additionalFields": null,
                            "ideaRenderParams": null,
                            "badgeRenderParams": null,
                            "eventRenderParams": null,
                            "newsRenderParams": null,
                            "thanksRenderParams": null,
                            "pollRenderParams": null,
                            "actions": [
                              "attach",
                              "file",
                              "markusers",
                              "mentionuser",
                              "mentiontag"
                            ],
                            "achievementsRenderParams": null,
                            "questsRenderParams": null
                          }
                        ],
                        statistic: "users",
                        title: "Динамика активности пользователей"
                      }
                    }
                  })
                ]
              }),
              generateColumn({
                styles: {width: "33.333333333333336%"},
                items: [
                  generateWidget({
                    type: widgets.types.statistic,
                    settings: {
                      data: {
                        chart: "barGroups",
                        datesType: "month",
                        maxItems: "7",
                        module: "virtualcurrency",
                        statistic: "usingdynamics",
                        title: "Динамика использования виртуальной валюты"
                      }
                    }
                  }),
                  generateWidget({
                    type: widgets.types.statistic,
                    settings: {
                      data: {
                        chart: "barGroups",
                        datesType: "month",
                        maxItems: "7",
                        module: "virtualcurrency",
                        statistic: "emissiondynamics",
                        title: "Динамика эмиссии виртуальной валюты"
                      }
                    }
                  }),
                  generateWidget({
                    type: widgets.types.statistic,
                    settings: {
                      data: {
                        chart: "barGroups",
                        datesType: "month",
                        maxItems: "7",
                        module: "virtualcurrency",
                        statistic: "expirationdynamics",
                        title: "Динамика сгорания виртуальной валюты"
                      }
                    }
                  })
                ]
              }),
              generateColumn({
                styles: {width: "33.333333333333336%"},
                items: [
                  generateWidget({
                    type: widgets.types.statistic,
                    settings: {
                      data: {
                        chart: "barGroups",
                        datesType: "month",
                        maxItems: "7",
                        module: "virtualcurrency",
                        statistic: "ordersdynamic",
                        title: "Динамика покупок в штуках"
                      }
                    }
                  }),
                  generateWidget({
                    type: widgets.types.statistic,
                    settings: {
                      data: {
                        chart: "barGroups",
                        datesType: "month",
                        maxItems: "7",
                        module: "virtualcurrency",
                        statistic: "ordersdynamicpryaniks",
                        title: "Динамика покупок в виртуальной валюте"
                      }
                    }
                  })
                ]
              })
            ]
          })
        ]
      })
    ],
  })
}