import React, { FC, memo, useCallback, useRef } from 'react'
import {
    //cnClassName,
    Shop
} from './Item.interfaces'
import Value from '../Value/Value.index'
import * as BaseInterface from '../Characteristics.interfaces'
import { useRefedCallback } from 'utils/src/hooks'
import { Box, Typography } from 'muicomponents/src'
type IPresenterProps = Shop.CharacteristicsItem.IPresenterProps

const disableIsSelected = (tag: BaseInterface.Shop.Characteristics.IValue) =>
    tag.isSelected ? { ...tag, isSelected: false } : tag

export const Presenter: FC<IPresenterProps> = ({
    className,
    item,
    onClick,
    readonly,
    showSelectedTitle
}) => {
    const { displayName, tags, type, description } = item

    const onSelect = useRefedCallback((ref) => (value: BaseInterface.Shop.Characteristics.IValue) => {
        const updatedTags = ref.current.tags.map(tag => tag.id === value.id ? value : disableIsSelected(tag))
        onClick({ ...ref.current, tags: updatedTags, isLastSelected: true })
    }, item)

    const selectedItem = tags.find(value => value.isSelected)

    const showTitle = Boolean(showSelectedTitle && selectedItem)
    
    return <Box sx={{display: 'flex', alignItems: 'center' }}>
        <Box>
            <Typography variant='subtitle2' fontSize='13px' fontWeight='600' sx={{mr: 1}} >
                {displayName}: {showTitle && <Typography>{selectedItem?.displayName}</Typography>}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', rowGap: '8px' }} >
            {tags.map(value => <Value
                readonly={readonly}
                type={type}
                key={value.id}
                onClick={onSelect}
                value={value} />)}
        </Box>
    </Box >
}

export default memo(Presenter);
