import React, { useLayoutEffect } from 'react';
// import { List } from "../List/_type/quests/List_type_quests_moderate";
// import { IQuestModerate, IQuestModerateProps, cnList_type_quests_moderate } from "./QuestsModerate.index";
import { Page } from 'blocks/Page/Page';
import { cnWikilist, IWikilistRouteProps } from './WIKIList.index';
import { withRouter, useParams, useHistory, generatePath } from 'react-router';
import { pageNames } from 'redux/pageNames';
import { mainUrls } from 'utils/src';

export const wikilist = () => {

    const history = useHistory();

    const { collectionAlias } = useParams<IWikilistRouteProps>();

    useLayoutEffect(() => {
        // collection alias is important param? but some clients have it is system
        // redirect by default collection if not collectionAlias
        if(!collectionAlias) {
            history.replace(generatePath(mainUrls.wikilist.collectionAlias, { collectionAlias: 'dash' }));
        }
    }, [collectionAlias]);

    // not render without collectionAlias
    if(!collectionAlias) return null;
    
    return (
        <Page page={{ name: `${pageNames.wikilist}${collectionAlias ? `.${collectionAlias}` : ''}` }} className={cnWikilist()} />
    )

}


