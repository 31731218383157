import React, { FC, memo, useCallback, useRef, useEffect } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    makeMapStateToProps,
    Shop
} from './interfaces'
import './style.scss'
import { Icon, Masonry, ListPlaceholder } from 'uielements/src';
import { connect } from 'react-redux';
import { Translate } from 'localizations/Translate';
import { Item } from '../Item/Shop-Item';
import { SHOP_GET_SHOP_CAT_PRODUCTS } from 'redux/shop/actions'
import { withIndicator } from 'utils/src/CommonRedux/LoadIndicator'
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button } from 'muicomponents/src/Button/Button'

type IOwnProps = Shop.CurrentCategory.IOwnProps
type IDispatchProps = Shop.CurrentCategory.IDispatchProps
type IStateProps = Shop.CurrentCategory.IStateProps
type IState = Shop.CurrentCategory.IState
type IPresenterProps = Shop.CurrentCategory.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    isLoading,
    items,
    categories,
    isHidePricesAndButtonBuy,
    isHideButtonBuy,
    maxOrdersCount,
    maxOrdersPeriod,
    ordersCount,
    additionalInformationBeforeOrder,
    getShopProducts,
    clearShopCatProducts,

}) => {
    // загрузка товаров той же категории
    useEffect(() => {
        if (categories?.length) getShopProducts({ skipCount: 0, count: 10, catFilter: categories[0].id });
        else if (categories?.length === 0) clearShopCatProducts()
    }, [categories?.length])


    return <div className={cnClassName({}, [className])}>
        {isLoading && <LinearProgress className={cnClassName('Progress')} />}

        <Masonry breakPoints={[800, 550, 350]} className={cnClassName('List')}>
            {items?.length && items.map((item: any, idx: number) =>
                idx <= 7 &&
                <Item
                    className={cnClassName('Item')}
                    isHidePricesAndButtonBuy={isHidePricesAndButtonBuy}
                    isHideButtonBuy={isHideButtonBuy}
                    data={item as any}
                    maxOrdersCount={maxOrdersCount}
                    maxOrdersPeriod={maxOrdersPeriod}
                    ordersCount={ordersCount}
                    additionalInformationBeforeOrder={additionalInformationBeforeOrder}
                />
            )}

        </Masonry>
        {(items?.length && categories.length) > 8 &&
            <Button
                // type='rlink'
                // noBackground
                // noBorder
                // noPadding
                to={`/shop?filter=${categories.length && categories[0].id}`}
                className={cnClassName('ShowMore')}
            >
                <Translate i18nKey='pryaniky.shop.product.showMore' values={{ category: categories[0].name.toLowerCase() }} />
            </Button>
        }
    </div >
}

export default withIndicator(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    makeMapStateToProps,
    mapDispatchToProps
)(memo(Presenter)), SHOP_GET_SHOP_CAT_PRODUCTS)();
