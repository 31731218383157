import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { RouteComponentProps } from 'react-router';
import { INetworkItem } from 'utils/src/requests/models/api.notifications';

export interface IItemProps extends IClassNameProps, RouteComponentProps {
    network: INetworkItem;
    isBase?: boolean;
    isCurrent: boolean
}

export const cnItem = cn('NetworksItem');