import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
    LOAD_UNITS_LOGS: '', // получам логи юнитов с сервера
    LOAD_ALL_SCORM_PARAMS: '', // загрузить все параметры scorm юнита

    SET_FULL_PLAYED_COURSE: ''// закинуть в редакс модель курса для прохождения
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.LMS) }), {} as typeof actions);