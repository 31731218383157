/**
 * @packageDocumentation
 * @module Widget_Settings_sliderlink
 */
import { GUID_EMPTY } from "utils/src/constants.prn";

export default {
  src: '',
  height: 150,
  withAuth: false
}