import React, { Component } from 'react';
import ReactDOM from 'react-dom';

const getDisplayName = (WrappedComponent: any) => {
    const component = WrappedComponent.WrappedComponent || WrappedComponent;
    return component.displayName || component.name || 'Component';
};

const round = (x: any, steps: any) => Math.ceil(x / steps) * steps;

export default ({ config, store }: any) => (WrappedComponent: any) =>
    class ImgAltDecorator extends Component<any> {
        static displayName = `Alt(${getDisplayName(WrappedComponent)})`;
        static WrappedComponent = WrappedComponent.WrappedComponent || WrappedComponent;
        static defaultProps = {
            // horizontal: 'relative',
            // vertical: false,
            // resizeSteps: 1,
            ...config,
        };
        state: any = {
            // hoverPosition: {},
            clicked: false,
            alt: '',
            url: ''
        };

        public wrapper: any;
        public elem: any

        public componentDidMount = () => {

        }

        setEntityData = (data: any) => {
            // this.props.blockProps.setResizeData(data);
        };

        // used to save the hoverPosition so it can be leveraged to determine if a
        // drag should happen on mousedown
        mouseLeave = () => {
        };

        // used to save the hoverPosition so it can be leveraged to determine if a
        // drag should happen on mousedown
        mouseMove = (evt: any) => {
        };

        // Handle mousedown for resizing
        mouseDown = (index: any) => {
            store.elem = this.wrapper
            store.visible = true;
            store.setLink = this.props.blockProps.setLink
            store.link = this.props.blockProps
            store.currentIndex = index//event.target.getAttribute('data-index') || 0
            // this.setState({ clicked: !this.state.clicked })
            this.props.blockProps.setFocusToBlock()
            // store.setEditorState
        };

        private onChangeUrl = (ev: any) => {

            // ev.preventDefault();
            // ev.stopPropagation()
            // this.setState({ url: ev.target.value })
        }

        public render = () => {
            const {
                // blockProps,
                // vertical,
                // horizontal,
                style,
                // using destructuring to make sure unused props are not passed down to the block
                // resizeSteps, // eslint-disable-line no-unused-vars
                // ...elementProps
                // blockProps
            } = this.props;
            const { clicked } = this.state;
            // const { isTop, isLeft, isRight, isBottom } = hoverPosition;


            const interactionProps = {
                onMouseDown: (arg: any) => {
                    this.props.onClick(arg)
                    // blockProps.onClick(arg)
                    this.mouseDown(arg)
                },
                /*onMouseMove: (arg: any) => {
                    elementProps.onMouseDown(arg)
                    this.mouseMove(arg)
                },
                onMouseLeave: (arg: any) => {
                    elementProps.onMouseDown(arg)
                    this.mouseLeave()
                },*/
            };

            // const blockNode: any = ReactDOM.findDOMNode(this);
            let rect = null
            if (this.wrapper) {
                // rect = this.wrapper.getBoundingClientRect();
                store.rect = rect
                // store.elem = this.wrapper
                // store.positions = { position: 'absolute', top: rect.top, left: rect.left }
            }
            if (this.wrapper === store.elem) store.link = this.props.blockProps
            return (

                <WrappedComponent
                    {...this.props}
                    ref={(element: any) => {
                        this.wrapper = element;
                    }}
                    // blockProps={blockProps}
                    ImdData={this.mouseDown}
                    style={style}
                // style={{ width: '100%', height: 'auto', display: 'block' }}
                />
            );
        }
    };