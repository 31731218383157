import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Button, Avatar } from 'uielements/src'
import lms_header from 'assets/jpg/lms_header.jpg';
import { ButtonBox } from 'uielements/src/ButtonBox/ButtonBox'

type IPresenterProps = StatisticsFull.Header.IPresenterProps
type IOwnProps = StatisticsFull.Header.IOwnProps
type IDispatchProps = StatisticsFull.Header.IDispatchProps
type IStateProps = StatisticsFull.Header.IStateProps
type IState = StatisticsFull.Header.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    breadcrumbs
}) => {
    return <div className={cnClassName({}, [className])} >
        <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbs.map((item) => item.type === 'link' ?
                <Button key={item.id} theme={'unstyled'} type={'rlink'} href={item.link}>{item.text}</Button> :
                <Typography key={item.id} color="textPrimary">{item.text}</Typography>
            )}
            {/* <Button theme={'unstyled'} type={'rlink'} href={'/TeacherOffice?tab=statistics'}>{'Статистика'}</Button>
            <Typography color="textPrimary">Пользователи</Typography> */}
            {/* <Button disabled={true} theme={'unstyled'} type={'rlink'} href={'/TeacherOffice/statistics/users'}>{'Пользователи'}</Button> */}
        </Breadcrumbs>
        <div className={cnClassName('Content')}>
            <Avatar imgUrl={lms_header} size={64} className={cnClassName('Avatar')} />
            <div className={cnClassName('Info')}>
                <h3 className={cnClassName('Title')}>Статистика</h3>

                {<ButtonBox orientation={'horizontal'} className={cnClassName('Actions')}>
                    <Button main type='rlink' href={'/TeacherOffice'} title={'Управление'} >Управление</Button>
                    <Button type='rlink' href={'/LMS'} title={'Обучение'} >Обучение</Button>
                </ButtonBox>}
            </div>
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
