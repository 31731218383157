import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { mapDispatchToProps, IDispatchProps } from 'redux/connector'
import { Page } from 'blocks/Page/Page'
import i18n from 'localizations/i18n'
import { setContext } from 'redux/actions/Widgets'
import { pageNames } from 'redux/pageNames'

interface IIdeasProps extends IActionsDispatch, IDispatchProps {}

interface IActionsDispatch {
  setContext: any
}

export const IdeasPresenter: React.FC<IIdeasProps> = props => {
  // useEffect(() => {
  //   props.setContext({
  //     paths: ['common'],
  //     innerPath: '',
  //     value: {
  //       newsType: 'ideas',
  //       oneNewsType: 'ideas'
  //     },
  //   })
  //   return () => {
  //     props.setContext({
  //       paths: ['common'],
  //       innerPath: '',
  //       value: {
  //         newsType: 'all',
  //         oneNewsType: undefined
  //       },
  //     })
  //   }
  // }, [])

  return (
    <Page
      className="Ideas"
      page={{
        name: pageNames.ideas,
        title: i18n.t('ideas'),
      }}
    />
  )
}

export const Ideas = connect(
  null,
  mapDispatchToProps({ setContext })
)(IdeasPresenter)
