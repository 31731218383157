import { pick } from 'lodash';
import { IStateType as IState } from 'redux/store';

export const getSurveyState = (state: IState) => state.survey;

export const getSurveySelector = (state: IState) => getSurveyState(state).survey;
export const getSurveyWidgetData = (state: IState) => getSurveyState(state).surveyWidget.surveyData;
export const getSurveyWidgetLoading = (state: IState) => getSurveyState(state).surveyWidget.isLoadingSurveys;

export const getSurveysQuestionsItemSelector = (id: string) => (state: IState) => getSurveyState(state).survey.questionsResults.questions[id];
export const getSurveysAnswersItemSelector = (questionId: string, answerId: string) => (state: IState) => getSurveyState(state).survey.questionsResults.questions[questionId].newAnswers[answerId]; 

export const getSurveySelectorDataByKey = <Keys extends (keyof ReturnType<typeof getSurveySelector>)[]> (...keys: Keys) => (...args: Parameters<typeof getSurveySelector>) => {
    return pick(getSurveySelector(...args), ...keys) as any as Pick<ReturnType<typeof getSurveySelector>, typeof keys[number]>;
};

export const getSurveyStateByKey = <Keys extends (keyof ReturnType<typeof getSurveyState>)[]> (...keys: Keys) => (...args: Parameters<typeof getSurveyState>) => {
    return pick(getSurveyState(...args), ...keys) as any as Pick<ReturnType<typeof getSurveyState>, typeof keys[number]>;
};