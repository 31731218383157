import React from 'react';
import { cn } from '@bem-react/classname';
import './Progress.scss';

const cnCourseProgress = cn('CourseProgress');

interface IProps {
  totalSteps: number;
  currentStep: number;
  className?: string;
}

export const Progress: React.FC<IProps> = ({ totalSteps, currentStep, className }) => {
  const percent = Math.floor((currentStep / totalSteps) * 100);
  return (
    <div className={cnCourseProgress({}, [className])} title={`${currentStep}/${totalSteps}`}>
      <div className={cnCourseProgress('Bar')}>
        {(new Array<string>(totalSteps)).fill('').map((val, i) => <div key={i} className={cnCourseProgress('Item', { complite: i < currentStep })}></div>)}
      </div>
      <div className={cnCourseProgress('Value')}>{`${percent > 100 ? 100 : percent}%`}</div>
    </div>
  );
};
