import { BaseNews } from './BaseType'
import { isEmail } from 'utils/src/utils'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { rawToMd } from '../PryanikyEditor/convertorConfigs'

export type Validate = Rule[];

export type Validator = <T>(value: number, data: number | string | boolean | T[]) => boolean;
export interface Rule {
    type: string,
    method: string,
    field: string,
    value: number,
    message?: string,
    errorI18nKey?: string;
    errorVariablesI18nKey?: {
        [key: string]: string | number;
    }
    major?: boolean
}


export const baseValidate: Rule[] = [
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'text',
        value: 3,
        errorI18nKey: 'pryaniky.validate.news.base.text.min'
    },
    /*{
        type: 'string',
        method: 'STR_MAX',
        field: 'text',
        value: 2500000,
        errorI18nKey: 'pryaniky.validate.news.base.text.max'
    },*/
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'header',
        value: 0,
        errorI18nKey: 'pryaniky.validate.news.base.header.min'
    },
    /*{
        type: 'string',
        method: 'STR_MAX',
        field: 'header',
        value: 50,
        errorI18nKey: 'pryaniky.validate.news.base.header.max'
    }*/
]
export type ValidateMethodString = (value: number, data: string) => boolean
export type ValidateMethodNumber = (value: number, data: number) => boolean
export type ValidateMethodArray = <T>(value: number, data: T[]) => boolean
export type ValidateMethodDate = (value: number, data: Date | string) => boolean

export type ValidateMethodsString = Map<string, ValidateMethodString>;
export type ValidateMethodsNumber = Map<string, ValidateMethodNumber>;
export type ValidateMethodsArray = Map<string, ValidateMethodArray>;
export type ValidateMethodsDate = Map<string, ValidateMethodDate>;


export const validateStringLength: ValidateMethodsString = new Map<string, ValidateMethodString>(Object.entries({
    STR_MIN: (value: number, data: string) => data.trim().length >= value,
    STR_MAX: (value: number, data: string) => data.trim().length <= value,
    IS_EMAIL: (value: number, data: string) => isEmail(data),
}))

export const validateNumberValue: ValidateMethodsNumber = new Map<string, ValidateMethodNumber>(Object.entries({
    NUM_MIN: (value: number, data: number) => data >= value,
    NUM_MAX: (value: number, data: number) => data <= value,
}))

export const validateArrayLength: ValidateMethodsArray = new Map<string, ValidateMethodArray>(Object.entries({
    ARR_MIN: <T>(value: number, data: T[]) => data.length >= value,
    ARR_MAX: <T>(value: number, data: T[]) => data.length <= value,
}))

export const validateDateMN: ValidateMethodsDate = new Map<string, ValidateMethodDate>(Object.entries({
    DATE_MIN: (value: number, data: Date | string) => {
        let date = new Date()
        if (data instanceof Date) {
            date = data
        } else {
            date = new Date(data)
        }
        return date.getTime() >= value
    },
    DATE_MAX: (value: number, data: Date | string) => {
        let date = new Date()
        if (data instanceof Date) {
            date = data
        } else {
            date = new Date(data)
        }
        return date.getTime() <= value
    }
}))

export const validatePostText = (data: any, rule: Rule) => validateString(draftToMarkdown(convertToRaw(data.getCurrentContent()), rawToMd), rule)
//validateString(data.getCurrentContent().getPlainText(), rule);

export const validateString = (data: string, rule: Rule) => {
    const method = validateStringLength.get(rule.method)
    if (method) {
        if (!method(rule.value, data)) return false;
    }
    return true
}

export const validateNumber = (data: number, rule: Rule) => {
    const method = validateNumberValue.get(rule.method)
    if (method) {
        if (!method(rule.value, data)) return false;
    }
    return true
}

export const validateArray = <T>(data: T[], rule: Rule) => {
    const method = validateArrayLength.get(rule.method)
    if (method) {
        if (!method<T>(rule.value, data)) return false;
    }
    return true
}

export const validateDate = (data: Date, rule: Rule) => {
    const method = validateDateMN.get(rule.method)
    if (method) {
        if (!method(rule.value, data)) return false;
    }
    return true
}

export class BaseValidator<T extends BaseNews> {
    private rules: Rule[] = [];
    private fields: string[] = [];
    private useFields: boolean = false;
    // private validators: Validator[] = [];
    public data: T;

    public constructor() {
        this.validation = this.validation.bind(this);
    }

    /*public validate = () => {

    }

    public addValidator = () => {

    }
*/
    public setUseField = (isUse: boolean = true) => this.useFields = true

    public addData = (data: T) => {
        this.data = data
    }

    public setRules (rules: Rule[]) {
        this.rules = rules
    }

    public addField = (fields: string[]) => {
        this.fields = fields
    }

    public validation() {
        let invalide: Rule[] = []
        for (const rule of this.rules) {
            if (!this.fields.includes(rule.field) && this.useFields) continue;
            switch (rule.field) {
                case 'text':
                    !this.validateText(this.data, rule) && invalide.push(rule);
                    break;
                case 'header':
                    !this.validateHeader(this.data, rule) && invalide.push(rule);
                    break;
                default:
                    !this.otherValidate(this.data, rule) && invalide.push(rule);
            }
        }
        const major = invalide.find(v => v.major)
        if (major) invalide = [major]
        return window.SERVICE__DISABLE_VALIDATE ? [] : invalide;
    }

    private validateText = (data: BaseNews, rule: Rule) => {
        return validatePostText(data.text, rule)
    }

    private validateHeader = (data: BaseNews, rule: Rule) => {
        return validateString(data.header, rule)
    }


    protected otherValidate = (data: T, rule: Rule) => {
        return false
    }
}
