import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getSurveySelectorDataByKey, getSurveyStateByKey } from "redux/sagas/Surveys/selectors";
import { SSurvey } from "utils/src/BaseTypes/survey";
import { getOpenSurvey as getOpenSurveyAction } from 'redux/sagas/Surveys/actions';
import { useCallback } from "react";

export const useSurvey = (id: SSurvey['id']) => {

    const dispatch = useDispatch();

    const data = useSelector(getSurveySelectorDataByKey(
        'loading',
        'finished',
        'started',
        'openSurveyData',
        'isShowResult',
        'showEachQuestionOnOnePage'
    ));

    const {
        openSurveyData,
        ...others
    } = data;

    const {
        isActive,
        iconImgUrl,
        name,
        text,
        isCompletedByMe,
        highlightCorrectness,
        showEachQuestionOnOnePage,
    } = openSurveyData || {};

    const getOpenSurvey = useCallback((...args: Parameters<typeof getOpenSurveyAction>) => {
        dispatch(getOpenSurveyAction(...args));
    }, [id]);

    return {
        ...others,
        isActive,
        iconImgUrl,
        name,
        text,
        isCompletedByMe,
        highlightCorrectness,
        showEachQuestionOnOnePage,
        getOpenSurvey
    };
};



export const processShowBar = (id: SSurvey['id']) => {

    const [questionsCount, setQuestionCount] = useState(0)
    // весь список вопросов
    const data = useSelector(getSurveyStateByKey('surveyPassing'));
 
       const value =data.surveyPassing?.questions[data.surveyPassing?.questions.length-1].questionCompletedPercent;
    
    return value
}