export const LM_MOVE_ITEM = 'LM_CHANGE_ITEM';
export const LM_SET_BAR_ITEMS = 'LM_SET_BAR_ITEMS';
export const LM_SET_BARS = 'LM_SET_BARS';
export const LM_SET_BARS_PATH = 'LM_SET_BARS_PATH';
export const LM_SET_EDIT = 'LM_SET_EDIT';
export const LM_SET_REDUCER_FIELD = 'LM_SET_REDUCER_FIELD';
export const LM_TOGGLE_FAVOURITE = 'LM_TOGGLE_FAVOURITE';
export const LM_REMOVE_ITEM = 'LM_REMOVE_ITEM';
export const LM_SORT_FAVOURITE = 'LM_SORT_FAVOURITE';

