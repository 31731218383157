import React from 'react';
// import { InputMention } from '../../InputMention/InputMention';
// import RadioGroup from 'uielements/src/MaterialElements/RadioGroup';
import { Actions } from '@jsonforms/core';
import { get_variable_name, get_variable_schema_data } from './utils';
import i18n from 'localization/src/i18n';
import Dropdown from 'uielements/src/MaterialElements/Dropdown/Dropdown';
import Suggest from 'uielements/src/MaterialElements/Autocomplete';
import DateRange from 'uielements/src/MaterialElements/DateRange/DateRange';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import Input from 'uielements/src/MaterialElements/Input/Input';
import { AchievementsSelector } from 'uielements/src/AchievementSelector/AchievementSelector';

import { statisticModulesItems, statisticItems, statisticFilters, statisticsViewItems } from '../../Statistic/constants';


const datesTypes = [
  { title: 'Неделю', value: 'week' },
  { title: 'Месяц', value: 'month' },
  { title: 'Произвольный интервал', value: 'custom' },
]

export const statistic = (props: any) => {
  if (!props.visible) return null;
  const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  // const data = props.data
  const data = props.data[get_variable_name(props.uischema.scope)];

  const onChange = (field: string, value: any) => {
    props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
      oldData[field] = value;
      return { ...oldData };
    }))
  }

  const onChangeModule = (value: string) => {
    onChange('module', value);
    onChange('statistic', undefined);
    onChange('chart', undefined);
    onChange('title', undefined);
    onChange('beginDate', undefined);
    onChange('endDate', undefined);
    onChange('quests', undefined);
    onChange('quizzes', undefined);
    onChange('badges', undefined);
    onChange('workflows', undefined);
    onChange('postTypes', undefined);
    onChange('activityVirtualUsers', undefined);
    onChange('onlyModeration', undefined);
  }

  const onChangeStatistic = (value: string) => {
    const chart = statisticsViewItems[data.module][value][0].value;
    onChange('statistic', value);
    onChange('chart', chart);
    onChange('title', i18n.t(`pryaniky.statistics.title.${data.module}.${value}`))
  }

  const onChangeDates = (dates: { startDate?: Date; endDate?: Date }) => {
    if (dates.startDate) onChange('beginDate', dates.startDate);
    if (dates.endDate) onChange('endDate', dates.endDate);
  }

  const filters = statisticFilters?.[data?.module]?.[data?.statistic];

  const chartTypes = statisticsViewItems[data?.module]?.[data?.statistic] || [];
  const showChartTypes = data.module && data.statistic && chartTypes.length > 1;


  return <div className={'StatisticControl'} style={{ padding: '24px' }}>

      <Dropdown
        value={data.module}
        items={statisticModulesItems}
        onChange={onChangeModule}
        label={'Выберите модуль статистики'}
        required
      />

      {data.module && <Dropdown
        value={data.statistic}
        items={statisticItems[data?.module]}
        onChange={onChangeStatistic}
        label={'Выберите статистику'}
        required
      />}

      {data.statistic && <Input
        value={data.title}
        onChange={(value: string) => onChange('title', value)}
        placeholder={i18n.t('Название')}
        required
      />}

      {showChartTypes && <Dropdown
        value={data.chart}
        items={chartTypes}
        onChange={(value: string) => onChange('chart', value)}
        label={'Выберите отображение статистики'}
        required
      />}

    {filters && filters.map((filter) => {
      switch (filter.type) {
        case 'dates': {
          return <>
            <Dropdown
              value={data.datesType}
              items={datesTypes}
              onChange={(value: string) => onChange('datesType', value)}
              label={i18n.t('pryaniky.statistics.filter.datesType')}
            />
            {
              data.datesType === 'custom' &&
              <DateRange
                startDate={data.beginDate || null}
                endDate={data.endDate || null}
                onChange={onChangeDates}
                required
              />
            }
          </>
        }

        case 'achievementGoals': {
          return <AchievementsSelector
            goals={data[filter.type]}
            onChange={(value) => onChange(filter.type, value)}
            required
          />
        }

        case 'quests':
        case 'quizzes':
        case 'badges':
        case 'workflows':
        case 'postTypes': {
          return <Suggest
            value={data[filter.type] || []}
            type={filter.type}
            multiple={true}
            onChange={(value: any) => onChange(filter.type, value ? filter.multiple ? value : [value[value.length - 1]] : [])}
            label={i18n.t(`pryaniky.statistics.filter.${filter.type}`)}
            required={filter.required}
          />
        }
        case 'activityVirtualUsers':
        case 'onlyModeration': {
          return <Checkbox
            value={data[filter.type]}
            onChange={value => onChange(filter.type, value)}
            label={i18n.t(`pryaniky.statistics.filter.${filter.type}`)}
          />
        }

        case 'maxItems': {
          return <Input
            value={data.maxItems}
            type='number'
            onChange={value => onChange('maxItems', value)}
            placeholder={i18n.t('pryaniky.statistics.filter.maxItems')}
          />
        }

        default:
          return <></>;
      }
    })
    }

  </div>
}
