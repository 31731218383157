import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { INetworkItem } from 'utils/src/requests/models/api.notifications';

export interface INetworkSelectorProps extends IClassNameProps, INetworkSelectorStateProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
}

export interface INetworkSelectorState {

}

export type TBaseNetwork = Omit<INetworkItem, 'roles' | 'notificationTemplate'>
export interface INetworkSelectorStateProps {
    networks: INetworkItem[]
    baseNetwork: TBaseNetwork,
    currentNetworkId: string
}

export const mapStateToProps = (state: any) => ({
    networks: getAuthUser(state).baseData.networks,
    baseNetwork: getAuthUser(state).baseData.baseNetwork,
    currentNetworkId: state.store.appSettings.appId as string
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export const cnNetworkSelector = cn('NetworkSelector');