import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { sendSessionStart, sendSessionStop, actionSesstionArchive } from '../../../redux/actions/LMSTeacherOffice'

export interface IItemProps extends IClassNameProps, IItemStateProps, IDispatchProps, RouteComponentProps, IItemDispatchProps {
    tag?: 'div';
    disableActions?: boolean
}

export interface IItemState {

}

export interface IItemStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    session: state.LMSTO.sessions[props.sid],
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    sendSessionStart,
    sendSessionStop,
    actionSesstionArchive
}, dispatch);

export type IItemDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnItem = cn('TOSessionsItem');