import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext } from '../../contexts/news'

import { Attachments } from 'uielements/src/Attachments/Attachments';
import { Preview } from 'uielements/src/Preview/Preview';
import * as utils from 'utils/src/utils';
import { LinkLayout } from 'blocks/NewsTypes/common/LinkLayout/LinkLayout';
import { NewsEdit } from 'blocks/NewsEdit_new/NewsEdit'

import NewsHeader from '../NewsHeader'
import NewsActions from '../NewsActions'
import NewsComments from '../NewsComments'
import ActionHeader from '../../components/ActionHeader'


type IPresenterProps = News.MiniView.IPresenterProps
type IOwnProps = News.MiniView.IOwnProps
type IDispatchProps = News.MiniView.IDispatchProps
type IStateProps = News.MiniView.IStateProps
type IState = News.MiniView.IState

const noopFn = () => { }
const noopArray: any[] = []

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    edit,
    toggleEdit,
    id,
    newstype,
    componentRenderName,
    actionHeader
}) => {


    const cancleEdit = useCallback(() => toggleEdit(false), [])

    const editType = componentRenderName === "workflow" ? "workflow" : utils.newNewsType(newstype)

    return <div className={cnClassName({ actionHeader: Boolean(actionHeader)}, [className])}>
        {actionHeader && <ActionHeader actionHeader={actionHeader}/>}
        <div className={'NewsLayout'}>
            <NewsHeader />

            {edit
                ? <NewsEdit cancleEdit={cancleEdit} compliteEdit={noopFn} newsId={id} type={editType}/>
                : <div className={cnClassName('Content')}>
                    {children}
                </div>
            }

            <NewsActions />
        </div>
        {/* <NewsComments /> */}
    </div>
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));
