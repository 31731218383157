import { INewsPropsType, mapStateToProps, TStateProps } from './PagesV2.index'
import React, { FC, memo } from 'react';
import { connect, useSelector, shallowEqual } from 'react-redux';
import './PagesV2.scss'
import { withNewsContext } from 'News/contexts/news'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import TextRender from 'News/containers/TextRender'
import ContentLayout from 'News/components/ContentLayout'
import {
    getNewsById,
    getNewsParamsById
} from 'News/redux/saga/selectors'
import { PagesV2News } from './PagesV2.type'
import { LinkLayout } from 'blocks/NewsTypes/common/LinkLayout/LinkLayout';
import ItemView from '../ItemView'

const newsParams = [
    'id',
    'learningTrack'
] as const

export const PagesV2Presenter: FC<INewsPropsType & TStateProps> = ({ newsId }) => {
    const params = useSelector(getNewsParamsById<PagesV2News, typeof newsParams[number]>(newsId, newsParams), shallowEqual)

    return <NewsView className={'type-5'} ViewRender={ItemView}>
        <ContentLayout>
            {!params.learningTrack.imgUrl && <div>{params.learningTrack.name}</div>}
            {/* {params.learningTrack.imgUrl && <img src={params.learningTrack.imgUrl} />} */}
        </ContentLayout>
        {params.learningTrack.imgUrl && <LinkLayout linkPreviewImgUrl={params.learningTrack.imgUrl} systemNewsActionHtml={params.learningTrack.name} />}
    </NewsView>
}

export const PagesV2 = withNewsContext<INewsPropsType>(connect(
    mapStateToProps
)(memo(PagesV2Presenter)), ['newsId']);


export const initPagesV2 = () => {
    ComponentInjector.getInstance().addNode('news_types', <PagesV2 />, 'LMSLearningTrack.PagesV2')
}