import * as React from 'react';
import { IEditorTypeEditProps } from './edit.index';
import { UeventNews, defaultUeventNews } from '../../../EventsType'
import { EventsValidator, ueventValidate } from '../../../Events.validate'
import { baseValidate } from '../../../../Base.validate'
import { Editor } from '../../Editor';
import { EditorTypeEditShvabe } from '../../../EditorShvabe/containers/edit/edit'
const shvabe = true


export const EditorTypeEdit: React.FC<IEditorTypeEditProps> = shvabe ? EditorTypeEditShvabe : ({ onChange: cbChange, data }) => {
    let localData = { ...data } as any;;

    const onChange = (data: any) => {
        const vb = new EventsValidator();
        vb.addData(data)
        vb.setRules([...ueventValidate, ...baseValidate])
        cbChange(data, vb.validation().length === 0, vb.validation())
    }

    const onChangeTitle = (e: any) => {
        localData.header = e.target.value;
        localData.uevent.title = e.target.value;
        onChange(localData);
    }
    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData);
    }
    const expiresChange = (date: Date) => {
        localData.uevent.startDateTime = date;
        localData.expires = date;
        onChange(localData);
    }
    const durationChange = (selected: any) => {
        localData.uevent.duration = selected.value;
        onChange(localData);
    }
    const onChangeLocation = (e: any) => {
        localData.uevent.eventLocation = e.target.value;
        onChange(localData);
    }
    return <Editor
        durationChange={durationChange}
        expiresChange={expiresChange}
        onChangeLocation={onChangeLocation}
        onChangeText={onChangeText}
        onChangeTitle={onChangeTitle}
        data={localData} />
}
