import { useSelector } from 'react-redux';
import { ChosenFiltersType } from 'redux/shop/interfaces';
import { IStateType } from 'redux/store';
import { IListRequestOptions } from 'utils/src/requests/models/api.base';
import { flatMapDeep } from 'lodash';
import { selectChoosenFilters } from 'redux/shop/selectors';


export const useShopRequestOptions = (
  count: number,
  skipCount: number
) => {

  const chosenFilters = useSelector(selectChoosenFilters)

  const chosenCharFilters = chosenFilters.characteristics
  const CharFilters = flatMapDeep(Object.keys(chosenCharFilters).map(charId => chosenCharFilters[charId]));

  const requestOptions: IListRequestOptions = {
    skipCount,
    count,
    search: chosenFilters.search,
    CatFilterIds: chosenFilters.catFilters,
    CharFilters,
    MinPrice: chosenFilters.MinPrice,
    MaxPrice: chosenFilters.MaxPrice,
    AllowToMeByPrice: chosenFilters.AllowToMeByPrice,
    MyFavorite: chosenFilters.MyFavorite,
    //OrderByRating: chosenFilters.sorting.OrderByRating,
    OrderByPopular: chosenFilters.sorting.OrderByPopular,
    OrderByCreateAt: chosenFilters.sorting.OrderByCreateAt,
    OrderByPrice: chosenFilters.sorting.OrderByPrice,
  }

  return { requestOptions, chosenFilters }

}

