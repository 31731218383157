import React, { useMemo } from 'react'
import { IField } from 'uielements/src/FieldRender/interfaces'
import { prepareFromServer } from 'uielements/src/FieldRender/prepare'
import {
    getFieldByProperty,
    removeFieldsByPropery,
} from 'uielements/src/FieldRender/utils'
import { cutText } from 'utils/src/utils'

// вытаскивает первое поле типа фото в отдельную переменную, сделано для вида голосование для заявок 
export function usePreperedPhoto(additionalFieldsValues: IField.AdditionalField[] = []) {
    return useMemo(() => {
        const clearFields = removeFieldsByPropery(additionalFieldsValues, (field: any) => !field.defaultFieldName)
        const fields = prepareFromServer(clearFields)
        const image = getFieldByProperty(fields, { property: 'type', value: 'Photo' })
        const withOutFirstImg = removeFieldsByPropery(fields, { property: 'id', value: image?.id })
        return [image, withOutFirstImg] as const;
    }, [additionalFieldsValues])
}

export const useCutTextarea = (additionalFieldsValues: IField.AdditionalField[] = []) =>
    useMemo(() =>
        additionalFieldsValues.map(value => ({
            ...value, fields: value.fields.map(field =>
                field.type === 'Textarea' ?
                    { ...field, data: field.data ? cutText(field.data, 100).split('\n').slice(0, 2).join('\n') : null} :
                    field
            )
        }))
        , [additionalFieldsValues])
