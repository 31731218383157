import React from 'react';


import { cnWidget, IWidgetProps } from '../Widget.index';

import './Widget_type_quests.scss';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { SecretSantaPart } from '../../SecretSantaPage/SecretSantaPage'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';

// import { SecretSantaWidgetPage } from '../../SecretSantaPage/SecretSantaWidgetPage'
import { IWidgetTypeSecretSantaPageProps } from './Widget_type_secret_santa_page.index'

const type = 'secretSantaPage';

export default class WidgetTypeSecretSantaPresenter extends WidgetPresenter<IWidgetTypeSecretSantaPageProps> {

  public render() {
    if (!this.props.widget) return null
    const subtype = utils.widgetSubtype(this.props.widget.type);
    return <SecretSantaPart {...this.props} className={cnWidget({type, subtype})}  settings={this.props.wcontext} tag={widgets.components.common} />
  }

}
// export const WidgetTypeSecretSanta = connect()(withRouter(WidgetTypeSecretSantaPresenter))