import { IFiltersProps, IFiltersState } from "../../Filters.index";
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index'
import { cn } from '@bem-react/classname';


export interface IFiltersShopProps extends IFiltersProps {}

export interface IFiltersShopState extends IFiltersState {
  isClosed: boolean;
  data: ISelectizeItem[];
}

export const cnFiltersMui = cn('FiltersMui');
