import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMSTO
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Masonry, Button } from 'uielements/src';
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import { SearchInput } from 'uielements/src';
import queryString from 'query-string';
import { DialogCreateCourse } from '../../../dialogs/CreateCourse/CreateCourse'
import ListPlaceholder from '../../../components/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import Item from './Item'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { Toggle } from 'uielements/src/Toggle/Toggle'

const LoadButton = withLoadSpin(Button)

type IPresenterProps = LMSTO.TOCoursesCardList.IPresenterProps
type IOwnProps = LMSTO.TOCoursesCardList.IOwnProps
type IDispatchProps = LMSTO.TOCoursesCardList.IDispatchProps
type IStateProps = LMSTO.TOCoursesCardList.IStateProps
type IState = LMSTO.TOCoursesCardList.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    isLoading,
    isFinished,
    results,
    loadMore,
    setShowCreate,
    onChangeSearch,
    onChangeArchive,
    isArchived = false,
    search
}) => {
    const triggerRef = useInfiniteScroll({
        loadMore,
        isLoading,
        isFinished,
    });
    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('ToolBox')}>
            <div className={cnClassName('SearchBox')}>
                <SearchInput loading={isLoading} value={search} className={cnClassName('SearchInput')} onChange={onChangeSearch} />
                <Button main className={cnClassName('CreateBtn')} onClick={() => DialogCreateCourse({}).then(() => {}).catch(() => { })}>{tT('course_list.card.create_course.1')}</Button>
            </div>
            <div className={cnClassName('ParamsBox')}>
                <Toggle className={cnClassName('ArchiveToggle')} id={'isArchived'} checked={isArchived} onChange={() => onChangeArchive(!isArchived)} />
                <label className={cnClassName('ArchiveLabel')} htmlFor={'isArchived'}>Архив</label>
            </div>
        </div>

        <ListPlaceholder
            className={cnClassName('Placeholder')}
            isFinished={isFinished}
            isLoading={isLoading}
            itemsCount={results.length}
            titleEmpty={tT('course_list.card.empty.title.1')}
            textEmpty={search ? tT('course_list.card.empty.text.search.1', { search }) : tT('course_list.card.empty.text.1')}
            textLoad={tT('course_list.card.load.text.1')}
        // onlyEmpty
        >

            <Masonry breakPoints={[50, 50]} className={cnClassName('Items')}>
                {results.map((cid: string) => <Item key={cid} cid={cid} />)}
            </Masonry>
            {!isFinished && (
                <LoadButton
                    forwardedRef={triggerRef}
                    isLoading={isLoading}
                    className={cnClassName('Trigger')}
                    onClick={isLoading ? void 0 : loadMore}>
                    {tT('course_list.card.load.more.1')}
                </LoadButton>
            )}
        </ListPlaceholder>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
