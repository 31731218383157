import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { withIndicator } from 'utils/src/CommonRedux/LoadIndicator'
import actionsTypes from '../../../../redux/actionsTypes/LMSTO'
import SummaryInfo from '../../SummaryInfo'
import Settings from '../Settings'

type IPresenterProps = StatisticsFull.UsersSummary.IPresenterProps
type IOwnProps = StatisticsFull.UsersSummary.IOwnProps
type IDispatchProps = StatisticsFull.UsersSummary.IDispatchProps
type IStateProps = StatisticsFull.UsersSummary.IStateProps
type IState = StatisticsFull.UsersSummary.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    getUserscoursesinfohead,
    isLoading,
    pieChart,
    complete,
    failed,
    inProgress,
    total,
    trainingLevel,
    countArchive,
    endDate,
    isMandatory,
    startDate,
    courses,
    groups
}) => {

    useEffect(() => {
        getUserscoursesinfohead({ countArchive, endDate, isMandatory, startDate, courses, groups })
    }, [countArchive, endDate, isMandatory, startDate, courses, groups])

    return <div className={cnClassName({}, [className])}>
        <Settings className={cnClassName('Settings')} />

        <SummaryInfo
            className={cnClassName('Information')}
            {...{
                pieChart,
                complete,
                failed,
                inProgress,
                total,
                trainingLevel
            }}
        />
    </div>
}

export default withIndicator(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter))), actionsTypes.GET_USERS_COURSES_INFO_HEAD)();
