import * as utils from 'utils/src/utils';
import { ExpertiseResults, RewardUsers } from './Ideas.index'
import { AdditionalFieldsValue } from './IdeaType'
import { denormalize, normalize, schema } from 'normalizr';
import { cloneObject } from 'utils/src/utils';
import { toast } from 'react-toastify';

const normalizeData = (data: any) => {
    const clone = cloneObject(data);

    const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
    const categorys = new schema.Entity('categorys', { v: [field] }, { idAttribute: 'k' });

    const normalizedData = normalize(clone.categorys, [categorys]);
    return normalizedData;
}

// export const loadUsers = (
//     ideaId: string,
//     statusID: string,
//     callback: (expertArray: any[]) => void,
//     errCb: () => void = () => { console.error('idea|getExpertiseResult|no_response') }
// ) => {
//     utils.API.news.ideas.getExpertiseResult(ideaId, statusID)
//         .r
//         .then((d: any) => {
//             if (d && d.data) {
//                 const expertArray = d.data.expertises.map((ex: any) => {
//                     return ex.user;
//                 })
//                 callback(expertArray);
//             }
//             else errCb();
//         });
// }

// export const setExperts = (ideaId: string, newExperts: string[] = [], oldExperts: string[] = []) => {
//     oldExperts.forEach(element => {
//         const el = newExperts.findIndex(val => {
//             return val === element;
//         })
//         if (el === -1) {
//             utils.API.news.ideas.setExpert(ideaId, false, element)
//         }
//     });
//     newExperts.forEach(element => {
//         const el = oldExperts.findIndex(val => {
//             return val === element;
//         })
//         if (el === -1) {
//             utils.API.news.ideas.setExpert(ideaId, true, element)
//         }
//     });
// }

// export const _applyStatus = (ideaId: string, statusID: string, comment: string, fields: any, callback: (data: any) => void) => {
    
//     const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
//     const categorys = new schema.Entity('categorys', { v: [field] }, { idAttribute: 'k' });

//     const denormalizedData = denormalize(fields.result, [categorys], fields.entities);

//     const opt = { comment, fields: denormalizedData }
//     utils.API.news.ideas._setIdeaStatus(ideaId, statusID, opt)
//         .r
//         .then((d: any) => { 
//             if (d.error_code === 0){
//                 toast.success('Этап изменён!');
//             } else {
//                 toast.error('Ошибка! Этап не изменён.');
//             }
            
            
//             callback(d.data)
//         });

    
// }

// export const loadFields = (
//     ideaId: string,
//     statusID: string,
//     callback: (fields: any) => void,
//     errCb: () => void = () => { console.error('idea|getExpertiseResult|no_response') }
// ) => {
//     utils.API.news.ideas.setStatus(ideaId, statusID)
//         .r
//         .then((d: any) => {
//             if (d && d.data) {
//                 const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
//                 const categorys = new schema.Entity('categorys', { v: [field] }, { idAttribute: 'k' });

//                 const normalizedData = normalize(d.data.fields, [categorys]);
//                 callback(normalizedData);
//             } else errCb()
//         });
// }
// export const saveIdeaEvaluation = (ideaId: string, statusID: string, fields: any) => {
//     const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
//     const categorys = new schema.Entity('categorys', { v: [field] }, { idAttribute: 'k' });

//     const denormalizedData = denormalize(fields.result, [categorys], fields.entities);

//     utils.API.news.ideas.setIdeaExpertise(ideaId, statusID, { fields: denormalizedData })
//         .r
//         .then((d: any) => {
//         });
// }

// export const expertResults = (
//     ideaId: string,
//     statusID: string,
//     callback: (results: ExpertiseResults) => void,
//     errCb: () => void = () => { console.error('idea|expertResults|no_response') }
// ) => {
//     utils.API.news.ideas.getExpertiseResult(ideaId, statusID)
//         .r
//         .then((d: any) => {
//             if (d && d.data) callback(d.data);
//             else errCb();
//             /*this.setState((s: IBodyTypeIdeasState) => {
//                 s.expertResults = d.data;
//                 return s;
//             })*/
//         });
// }

export const getInvestors = (newsuid: string, callback: (investors: any) => void) => {
    utils.API.users.list({ skipCount: 0, count: 100, newsuid, distributefund: true, invest: true }).r.then((d) => {

        callback(d);
    })
}

export const searchInvestors = (newsuid: string, search: string, callback: (investors: any) => void) => {
    utils.API.users.list({ skipCount: 0, count: 100, newsuid, search, distributefund: true }).r.then((d) => {
        if (!d.data) callback({ data: [] });
        else callback(d);
    })
}

export const distributeIdea = (ideaId: string, statusId: string, comment: string, data: RewardUsers[], callback: (d: any) => void) => {
    utils.API.news.ideas.distribute(ideaId, {
        statusId,
        comment,
        data,
    }).r.then((d) => {
        callback(d);
    })

}

// export const actionToggleParticipants = (ideaId: string, set: boolean) => {
//     utils.API.news.ideas.setParticipate(ideaId, 'user', set)
//         .r
//         .then(d => {
//         });
// }

// export const actionToggleHeads = (ideaId: string, set: boolean) => {
//     utils.API.news.ideas.setParticipate(ideaId, 'head', set)
//         .r
//         .then(d => {
//         });
// }