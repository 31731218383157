import { IFiltersState } from "../Filters.index";
import { IClassNameProps } from '@bem-react/core'
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index'
import { Dispatch } from 'redux'
import actions from '../../../redux/actionsTypes/Widgets';
import { cn } from "@bem-react/classname";
import { IFiltersProps } from "../Filters.index";
import { IStateType as IState } from 'redux/store';
import { getCurrentUser } from "utils/src/CommonRedux/base/selectors";

export interface IFiltersWikiOwnProps extends IFiltersProps{};


// export interface IFiltersProps extends IClassNameProps, IFiltersStateProps, IFiltersDispatchProps {
//     tag?: 'div'
//     data: any[]
//     context: IFilterContext
//     relations: string[]
// }

export interface IFiltersStateProps {
    edit: boolean;
    enabledModules: any;
}

export type IFiltersDispatchProps = ReturnType<typeof mapDispatchToProps>

export interface IFilterContext {
    filter?: Record<string, any>
}
export interface IFiltersWikiState extends IFiltersState {
    data: ISelectizeItem[];
    authors: string[]|undefined,
    sortFilters: any[],
    collectionUrl:string,
    // viewType: number,
}

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    changeContext: (paths: string[], innerPath: string, value: any) =>
        dispatch({ type: 'CHANGE_CONTEXT', payload: { paths, innerPath, value } }),
    changeContextv3: (payload: Array<{ [s: string]: any }>) =>
        dispatch({ type: actions.UPDATE_CONTEXTS, payload }),
})


export const cnFilters = cn('Filters')