import { connect } from 'react-redux';
import * as React from 'react';
import { mapDispatchToProps, IDispatchProps } from '../../redux/connector';
import { mapStateToProps, IBadgeStateProps, mapActionsToProps, cnBadge, IBadgeProps } from './Badge.index';
import './Badge.scss';
import { Page } from '../Page/Page';
import { noop } from 'utils/src/utils';
import { pageNames } from 'redux/pageNames';

export const BadgePresenter: React.FC<IBadgeProps> = ({ setContext = noop, match }) => {
  // React.useEffect(() => {
  //   setContext({
  //     paths: ['common'],
  //     innerPath: 'bId',
  //     value: match.params.id,
  //   });
  //   return () => {
  //     setContext({
  //       paths: ['common'],
  //       innerPath: '',
  //       value: {},
  //     });
  //   };
  // }, []);
  return <Page className={cnBadge({ id: match.params.id })} page={{ name: `${pageNames.badge}.${match.params.id}` }} />;
};

export const Badge = connect<IBadgeStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({ ...mapActionsToProps })
)(BadgePresenter);
