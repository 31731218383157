import { cnInfoBlock } from '../InfoBlock.index';

import { IInfoProps } from '../AbstarctInfoElement/AbstractInfoElement.index';
import { bindActionCreators } from 'redux';
import { getCurrentUserIsAdmin } from 'utils/src/CommonRedux/base/selectors';

export interface ITagProps extends ITagOwnProps, IInfoProps, TMapStateToProps, TMapDispatchToProps {}

export interface ITagOwnProps {
    tId: string;
}

export const mapStateToProps = (state: any) => ({
    isEditable: state.store.isEditable as boolean,
    isAdmin: getCurrentUserIsAdmin(state)
})

export type TMapStateToProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export type TMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export const cnInfo = cnInfoBlock;