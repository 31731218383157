import React, { FC, useEffect, useState } from 'react'
import { cnStatus, mapDispatchToProps, mapStateToProps } from './Status.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Status.scss'
// import { Button } from 'uielements/src';
import { Selectize } from 'uielements/src';
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'


export const StatusPresenter: FC<any> = ({ className, setModerationFilters, currentFilters }) => {
    const elements = [
        {
            value: 'All',
            title: tT('moderation.filters.status.All.1'),
            id: 'All'
        },
        {
            value: 'WaitModeration',
            title: tT('moderation.filters.status.WaitModeration.1'),
            id: 'WaitModeration'
        }, {
            value: 'Approved',
            title: tT('moderation.filters.status.Approved.1'),
            id: 'Approved'
        }, {
            value: 'Disapproved',
            title: tT('moderation.filters.status.Disapproved.1'),
            id: 'Disapproved'
        },
        {
            value: 'NotModerated',
            title: tT('moderation.filters.status.NotModerated.1'),
            id: 'NotModerated'
        },
    ];
    const onSelect = (value: ISelectizeItem) => {
        setModerationFilters({
            'filter.filterByStatus': [value.value],
            // 'filter.isNeedModeration': value.value !== 'NotModerated' && value.value !== 'All'
        })
    }

    return <div className={cnStatus({}, [className])}>
        {/* Sessions filter */}
        <Selectize
            // key={i} 
            onClick={onSelect}
            active={currentFilters}
            // rLink={linkCreator('cat', zeroId)}
            name={tT('moderation.filters.statuses.1')}
            elements={elements}
            // multiple={true}
            children={[]} />
        {/* {sessionsIds.map((sid: string) => normalSessions[sid].name)} */}
    </div>
}

export const Status = StatusPresenter/*connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(StatusPresenter));*/
