import { NValidate } from 'utils/src/validate_v2';
import { NThanks } from './Thanks.interface';

export const ThanksValidateRules: NValidate.ValidateRulesObject<NThanks.Create> = {
    users: {
        notNull: {
            value: true,
            errorMessageKey: 'pryaniky.news.validate.usersSuggesterWithBlur.error'        }
    },
    groups: {
        notNull: {
            value: true,
            errorMessageKey: 'pryaniky.news.validate.usersSuggesterWithBlur.error'
        }
    },
    text: {
        notNull: {
            value: true
        },
        min: {
            value: 5
        }
    },
    rewardReasons: {
        min: {
            value: 1
        }
    },
    thanksCount: {
        min: {
            value: 1
        }
    },
};