import * as React from 'react';
import { IEditorTypeNewsProps } from './news.index';
import { AchievementsNews, defaultAchievementsNewsCreate, AchievementsNewsCreate } from '../../../Achievements.type'
import { AchievementsValidator, achievementValidate } from '../../../Achievements.validate'
import { Editor } from '../../Editor'
import { v1 as uuid } from 'uuid';
import * as utils from 'utils/src/utils';
import { EditorState, convertToRaw } from 'draft-js';

export const EditorTypeNews: React.FC<IEditorTypeNewsProps> = ({ onChange: cbChange, data, types }) => {
    let localData = data as any;
    if (data.newstype !== 19) {
        localData = { ...utils.cloneObject(defaultAchievementsNewsCreate), text: EditorState.createEmpty() };
    } // else localData.uevent.startDateTime = new Date(localData.uevent.startDateTime);

    const onChange = (data: any) => {
        const vb = new AchievementsValidator();
        vb.addData(data)
        vb.setRules(achievementValidate)
        cbChange(data, vb.validation().length === 0 && (data.achievement && data.achievement.allowReportByUser), vb.validation())
    }

    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData);
    }
    const onChangeAchievement = (data: any) => {
        localData.achievement = data;
        onChange(localData)
    }
    return <Editor
        achievementList={types}
        onChangeAchievement={onChangeAchievement}
        onChangeText={onChangeText}
        data={localData} />
}
