import React, { FC, useEffect, useMemo, useState, memo, useCallback, useRef } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import FieldRender, { IAdditionalField, prepareFromServer, prepareToServer, IField, changeField, getFieldById, getFieldByName } from 'uielements/src/FieldRender'
import { createDefaultWorkflowsNews, WorkflowsNews } from '../Workflows.type'
import NewsEditor from 'News/containers/NewsEditor'
import { useDebounce } from 'utils/src/hooks'
import { EditorState, RawDraftContentState, convertToRaw, convertFromRaw, Modifier } from 'draft-js';
import uuid from 'uuid/v1';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
import { NodeBulder } from 'uielements/src/CommonmarkRender'
import PryanikyEditor from 'uielements/src/PryanikyEditorV1'
import { findMentions } from 'uielements/src/PryanikyEditorV1/MentionSelectPlugin/modifiers/findMentions'
import { markdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap'
import * as utils from 'utils/src/utils'
import {
    useFindMention,
    useAddMention,
    useDraftState,
    useChangeField,
    useSubmit,
    useFieldFindMention,
    useEditDraft,
    useCreateModel,
    useBaseEdits
} from 'News/utils/hooks'
import {
    getFieldsValues
} from 'News/utils/utils'
import MentionAction from 'News/components/MentionDrop'

type IPresenterProps = News.NewsCreator.IPresenterProps
type IOwnProps = News.NewsCreator.IOwnProps
type IDispatchProps = News.NewsCreator.IDispatchProps
type IStateProps = News.NewsCreator.IStateProps
type IState = News.NewsCreator.IState

const toolBarStyle = { top: 55 }
const noopFn = () => { }

const createShortDraft = (state: EditorState) => {
    const text = draftToMarkdown(convertToRaw(state.getCurrentContent()), rawToMd);
    const NB = new NodeBulder(text || '')
    return EditorState.createWithContent(convertFromRaw(NB.getDraftRaw()))
}


// const getFieldsValues = (currentNews: WorkflowsNews) => currentNews.additionalFields.additionalFieldsValues

export const Presenter: FC<IPresenterProps> = ({
    className,
    // currentNews
}) => {
    const [autoShort, setAutoShort] = useState(true)

    const fieldChanged = useDebounce((currentNews: WorkflowsNews) => {
        if (autoShortRef.current) {
            const emptyState = EditorState.createEmpty()
            const fields = getFieldsValues(currentNews)
            const state = getFieldByName<EditorState>(fields || [], 'Text')?.data || emptyState
            // fields.reduce((ac, c) => ac ? ac : c.fields.reduce((af, f) => f.isBaseField && f.name === 'Text' && !af ? f.data : af, null), null) || emptyState

            const shortState = createShortDraft(state)

            setDraft(shortState)
        }
    }, 1600, [])

    const {
        draftState,
        onFind,
        onMention,
        onSubmit,
        setDraft,
        onChangeFields,
        fieldsValues
    } = useBaseEdits<WorkflowsNews>(createDefaultWorkflowsNews(), {
        fieldChanged
    })
    
    // const fieldsValues = getFieldsValues(currentNews)
    const [actions, setActions] = useState<React.ReactNode>(<></>)

    const autoShortRef = useRef(autoShort)
    autoShortRef.current = autoShort

    const [findUsersField] = useFieldFindMention(fieldsValues || [], onChangeFields)

    const onFocusShort = useCallback(() => {
        setAutoShort(false)
        setActions(<>
            <MentionAction value={null} onChange={onMention} />
            <button key={'onFocusShort'} onClick={onFind}>{'find-short'}</button>
        </>)
    }, [draftState])

    const onFocusField = useCallback((field: IField) => {
        setActions(<button key={'onFocusField'} onClick={findUsersField(field)}>{'find-field'}</button>)
    }, [])

    const onToggleShort = useCallback(() => setAutoShort(!autoShortRef.current), [])

    return <NewsEditor
        actions={<>
            {actions}
        </>}
        onSubmit={onSubmit}
        className={cnClassName({}, [className])}>
        <div>
            <FieldRender 
                onFocus={onFocusField}
                fields={fieldsValues || []}
                onChange={onChangeFields} />
        </div>
        <div>
            <FormControlLabel
                control={<Switch checked={autoShort} onChange={onToggleShort} name="checkedA" />}
                label="autoShort"
            />
        </div>
        <div>
            <PryanikyEditor
                onFocus={onFocusShort}
                maxFileSize={100}
                enableModules={markdownPreset}
                toolBarStyle={toolBarStyle}
                value={draftState}
                setActionBtn={noopFn}
                onChange={setDraft}
            />
        </div>

    </NewsEditor>
}

export const Connected = connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));

export const initNewsWorkflows = () => ComponentInjector.getInstance().addNode('news_creator', <Connected />, 'workflow')