/**
 * @packageDocumentation
 * @module Widget_Settings_list
 */
import { JFdictionary } from "../dictionary";

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.layout,
      direction: 'horizontal',
      elements: [
        {
          type: renderers.radio,
          label: "групп",
          name: "groups",
          scope: "#/properties/groups"
          //TODO ZENKIN move relations to schema
        },
        {
          type: renderers.radio,
          label: "коллег",
          name: "users",
          scope: "#/properties/users"
        }
      ]
    },
    {
      type: renderers.layout,
      rule: {
        effect: "SHOW",
        condition: {
          type: "LEAF",
          scope: "#/properties/users",
          expectedValue: true
        }
      },
      elements: [
        // {
        //   type: renderers.groupsInput,
        //   scope: "#/properties/selectedBadges"
        // },
        // {
        //   type: renderers.groupsInput,
        //   scope: "#/properties/selectedGroups"
        // }
      ]
    },
    {
      type: renderers.layout,
      rule: {
        effect: "SHOW",
        condition: {
          type: "LEAF",
          scope: "#/properties/groups",
          expectedValue: true
        }
      },
      elements: [
        // {
        //   type: renderers.usersInput,
        //   scope: "#/properties/selectedUsers"
        // },
        {
          type: renderers.checkbox,
          label: "использовать древовидное отображение",
          scope: "#/properties/groupsTree"
        }
      ]
    }
  ]
}