import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IVersionsListPresenterProps = IVersionsListOwnProps

export interface IVersionsListState { }

export interface IVersionsListOwnProps extends IClassNameProps {
    versions: { [key: number]: string },
    selectedVersion: string,
    onSelect: (version?: string) => void,
    modifed: boolean
}

export const cnClassName = cn('LMSVersionsList');