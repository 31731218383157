import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import List from './List' 
import { tSt } from 'LMSModule/utils/i18n.adaptor'
import Header from '../Header'

type IPresenterProps = StatisticsFull.Courses.IPresenterProps
type IOwnProps = StatisticsFull.Courses.IOwnProps
type IDispatchProps = StatisticsFull.Courses.IDispatchProps
type IStateProps = StatisticsFull.Courses.IStateProps
type IState = StatisticsFull.Courses.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
}) => {
    const bc = [
        {
            id: 'root',
            link: '/TeacherOffice?tab=statistics',
            text: tSt('bc.statistics'),//'Статистика',
            type: 'link'
        },
        {
            id: 'courses',
            link: '/TeacherOffice/statistics/type/users',
            text: tSt('bc.courses'),//'Пользователи',
            type: 'text'
        },
    ]
    return <div className={cnClassName({}, [className, 'Page'])}>

        <Header breadcrumbs={bc} className={cnClassName('Top')} />

        <div className={cnClassName('Content')}>
            {/* <div className={cnClassName('Aside')}>
                <CourseSelectize
                    multiSelect
                    onClick={changeCourse}
                    name={'Курсы'}
                />

                <UserGroupSelectize
                    type={'groups'}
                    onClick={changeGroup}
                    name={'Группы'}
                />
            </div> */}

            <div className={cnClassName('Section')}>
                {/* <Summary className={cnClassName('Summary')} /> */}
                <List className={cnClassName('List')} />
            </div>
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));
