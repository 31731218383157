import React, { FC, useState } from 'react';
import { NBadgesList } from './Badges.index';
import { ItemList } from './ItemList/ItemList';
import { Box, Paper } from 'muicomponents/src';
import { BadgeCategoryBox, BadgeCategoryTitle, BadgesGridItemsBox } from './Badges.styled';
import { HeaderBadges } from './HeaderBadges/HeaderBadges';
import { useDebouncedQueryState, useScrollListState } from 'utils/src/hooks';
import { getBadgesList } from 'utils/src/requests/requests.badges';
import { useListCardResize } from 'muicomponents/src/InfinityList/hook';
import { LinearProgress } from 'muicomponents/src/LinearProgress';
import InfinityList from 'muicomponents/src/InfinityList/InfinityList';
import { i18n, Translate } from 'localization';
import BadgesListEmpty from 'assets/png/BadgesListEmpty.png';
import { ItemGrid } from './ItemGrid/ItemGrid';

const BadgesPresenter: FC<NBadgesList.Props> = ({ filter }) => {
    const [search, searchQuery, setSearch] = useDebouncedQueryState('search');
    const [viewType, setViewType] = useState<'grid' | 'row'>('grid');
    const [checkedCategories, setCheckedCategories] = useState<string[]>([]);

    const { count, ref: listRef } = useListCardResize(4, 250);

    const {
        isFinished,
        isLoading,
        itemsCount,
        loadMore,
        values,
        triggerRef,
        error: { withError },
    } = useScrollListState(
        async function (skipCount, count) {
            return (await getBadgesList(searchQuery as string, { filter: checkedCategories }, skipCount, count)).data;
        },
        [searchQuery, checkedCategories]
    );
    return (
        <Box
            sx={{
                maxWidth: '100%',
            }}
        >
            <HeaderBadges viewType={viewType} setViewType={setViewType} setCheckedCategories={setCheckedCategories} />

            {isLoading && <LinearProgress />}

            <InfinityList
                triggerRef={triggerRef}
                isFinished={isFinished}
                isLoading={isLoading}
                itemsCount={itemsCount}
                isError={withError}
                loadMore={loadMore}
                textEmpty={
                    <>
                        <b>
                            {searchQuery
                                ? Translate.t({ i18nKey: 'pryaniky.badge.empty.title.search' })
                                : Translate.t({ i18nKey: 'pryaniky.badge.empty.title' })}
                        </b>
                        <br />
                        {searchQuery
                            ? i18n.t('pryaniky.badge.empty.search.text', { query: searchQuery })
                            : Translate.t({ i18nKey: 'pryaniky.badge.empty.title' })}
                    </>
                }
                imgEmpty={BadgesListEmpty}
            >
                {viewType === 'row' && (
                    <Paper sx={{ padding: '24px' }}>
                        {values &&
                            values.map(({ id, displayName, items }) => (
                                <BadgeCategoryBox key={id}>
                                    {displayName && <BadgeCategoryTitle>{displayName}</BadgeCategoryTitle>}
                                    {items.map((badge) => (
                                        <ItemList data={badge} key={badge.badgeUid} />
                                    ))}
                                </BadgeCategoryBox>
                            ))}
                    </Paper>
                )}

                {viewType === 'grid' &&
                    values &&
                    values.map(({ id, displayName, items }) => (
                        <BadgeCategoryBox key={id} isGrid>
                            {displayName && <BadgeCategoryTitle>{displayName}</BadgeCategoryTitle>}
                            <BadgesGridItemsBox ref={listRef} countColumns={count}>
                                {items.map((badge) => (
                                    <ItemGrid data={badge} key={badge.badgeUid} countColumns={count} />
                                ))}
                            </BadgesGridItemsBox>
                        </BadgeCategoryBox>
                    ))}
            </InfinityList>
        </Box>
    );
};

export const Badges = BadgesPresenter;
