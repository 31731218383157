import React from 'react';
import {
  cnUserfieldsWidgetStyle,
  IUserfieldsWidget,
  mapUserfieldsWidgetStateToProps,
  mapUserfieldsWidgetActionToProps
} from './Userfields.index';
import './Userfields.scss';
import { connect } from 'react-redux';
import CatRender from 'blocks/InfoBlock/User/_edit/FieldsRender/CatRender/CatRender'
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import { Icon } from 'uielements';
import { FieldsRender } from '../InfoBlock/User/FieldsRender';
import { cnUser, cnInfo } from '../InfoBlock/User/InfoBlock-User.index';
import { Translate } from 'localizations/Translate';
import { Button as PrnBtn } from 'uielements/src/Button/Button';
import MDRender from 'uielements/src/CommonmarkRender';
import moment from 'moment';
import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';
import { Link } from 'muicomponents/src/Link/Link'
import { isValidDate } from 'utils/src/utils.dates';

const UserFieldsPresenter: React.FC<IUserfieldsWidget.Props> = ({
  userId,
  user,
  fieldsIds,
  changeUser,
  updateUser,
  authUser
}) => {

  const allowEdit = () => {
    if (authUser.baseData.isAdmin) return true;
    return (user?.baseData?.id === authUser.baseData.id);
  }

  const [edit, setEdit] = React.useState(false);

  const save = () => {
    updateUser(userId, user);
    setEdit(false);
  };

  const unchagedData = React.useMemo(() => user, []);

  const cancel = () => {
    changeUser(userId, {
      ...unchagedData,
    });
    setEdit(false);
  };

  const onChangeFields = (fieldId: string, property: string, fieldsSet: string) => (value: any) => {
    changeUser(userId, {
      ...user,
      baseData: {
        ...user.baseData,
        userListFields: {
          ...user.baseData.userListFields,
          entities: {
            ...user.baseData.userListFields.entities,
            fields: 'fields' in user.baseData.userListFields.entities ? {
              ...user.baseData.userListFields.entities.fields,
              [fieldId]: fieldId in user.baseData.userListFields.entities.fields ? {
                ...user.baseData.userListFields.entities.fields[fieldId],
                [property]: value
              } : undefined
            } : undefined
          }
        },
        userListFieldsOriginal: user.baseData.userListFieldsOriginal?.map((el: any) => {
          if (el.fieldId === fieldId) {
            return {
              ...el,
              [property]: value
            }
          }
          return el;
        })
      },
      profileData: {
        ...user.profileData,
        userInfoFields: {
          ...user.profileData.userInfoFields,
          entities: {
            ...user.profileData.userInfoFields.entities,
            fields: 'fields' in user.profileData.userInfoFields.entities ? {
              ...user.profileData.userInfoFields.entities.fields,
              [fieldId]: fieldId in user.profileData.userInfoFields.entities.fields ? {
                ...user.profileData.userInfoFields.entities.fields[fieldId],
                [property]: value
              } : undefined
            } : undefined
          },
          userInfoFieldsOriginal: user.profileData.userInfoFieldsOriginal?.map((el: any) => {
            if (el.fieldId === fieldId) {
              return {
                ...el,
                [property]: value
              }
            }
            return el;
          })
        },
        userHeadFields: {
          ...user.profileData.userHeadFields,
          entities: {
            ...user.profileData.userHeadFields.entities,
            fields: 'fields' in user.profileData.userHeadFields.entities ? {
              ...user.profileData.userHeadFields.entities.fields,
              [fieldId]: fieldId in user.profileData.userHeadFields.entities.fields ? {
                ...user.profileData.userHeadFields.entities.fields[fieldId],
                [property]: value
              } : undefined
            } : undefined
          },
          userHeadFieldsOriginal: user.profileData.userHeadFieldsOriginal?.map((el: any) => {
            if (el.fieldId === fieldId) {
              return {
                ...el,
                [property]: value
              }
            }
            return el;
          })
        }
      }
    });
  };

  if (!user || !fieldsIds || !fieldsIds.length) return null;

  return (
    <div className={cnUserfieldsWidgetStyle()}>

      <div className={cnUserfieldsWidgetStyle('Header')}>
        <h4 className={cnUserfieldsWidgetStyle('Header-Title')}>{i18n.t('pryaniky.user.fields.title')}</h4>
      </div>
      <div className={cnUserfieldsWidgetStyle('Fields')}>
        {
          !edit ?
            <div className={cnUserfieldsWidgetStyle('Fields')}>
              <colgroup>
                <col span={1} className={cnUserfieldsWidgetStyle('Col1')} />
                <col span={1} className={cnUserfieldsWidgetStyle('Col2')} />
              </colgroup>
              <div>
                <FieldsRender
                  categotyRender={(cat: string, fields: any, FieldRender: any) => <>
                    <div className={cnUserfieldsWidgetStyle('Fields-Item')}>
                      <div className={cnUserfieldsWidgetStyle('CatText')}>
                        <h4>{cat}</h4>
                      </div>
                      <div className={cnUserfieldsWidgetStyle('BlackText')}></div>
                    </div>
                    {fields.map((v: any) => <FieldRender field={v} />)}
                  </>
                  }
                  fieldRender={(val: any) => {
                    const { field, value, tags, files, fieldId } = val;
                    if (!field) return null;
                    if (field.fieldType === 'Files') {
                      return null;
                    }
                    if (field.fieldType === 'Tags') {
                      if (tags.length === 0) return null;
                      return (
                        <div key={fieldId} className={cnUserfieldsWidgetStyle('Fields-Item')}>
                          <div className={cnUserfieldsWidgetStyle('GrayText')}>
                            <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                          </div>
                          <div >{tags.map((tag: any, i: number) => {
                            const tagLink = field.additionalParam1 ? (field.additionalParam1 as string).replace('{id}', tag.id) : `/tag/${tag.id}`
                            return <>
                              <PrnBtn className={cnUserfieldsWidgetStyle('TagLink')} theme={'unstyled'} key={tag.id} type="rlink" href={tagLink}>
                                {tag.displayName}
                              </PrnBtn>{(i < (tags.length - 1)) && <span className={cnUserfieldsWidgetStyle('Seporator')}>,</span>}</>
                          })}</div>
                        </div>
                      )
                    }
                    if (field.fieldType === 'Interests') {
                      if (tags.length === 0) return null;
                      return (
                        <div key={fieldId} className={cnUserfieldsWidgetStyle('Fields-Item')}>
                          <div className={cnUserfieldsWidgetStyle('GrayText')}>
                            <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                          </div>
                          <div>{tags.map((tag: any, i: number) => {
                            const tagLink = field.additionalParam1 ? (field.additionalParam1 as string).replace('{id}', tag.id) : `/tag/${tag.id}/people?collectionType=interests`
                            return <>
                              <PrnBtn className={cnUserfieldsWidgetStyle('TagLink')} theme={'unstyled'} key={tag.id} type="rlink" href={tagLink}>
                                {tag.displayName}
                              </PrnBtn>{(i < (tags.length - 1)) && <span className={cnUserfieldsWidgetStyle('Seporator')}>,</span>}</>
                          })}</div>
                        </div>
                      )
                    }
                    if (field.fieldType === 'String' && field.mask === "email") {
                      if (!value) return null
                      return (
                        <div key={fieldId} className={cnUserfieldsWidgetStyle('Fields-Item')}>
                          <div className={cnUserfieldsWidgetStyle('GrayText')}>
                            <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                          </div>
                          <div className={cnUserfieldsWidgetStyle('BlackText')}>
                            <PrnBtn theme={'unstyled'} {...utils.generateLinkData(value)}>{value}</PrnBtn>
                          </div>
                        </div>
                      )
                    }
                    if (field.fieldType === 'String') {
                      if (!value) return null
                      return (
                        <div key={fieldId} className={cnUserfieldsWidgetStyle('Fields-Item')}>
                          <div className={cnUserfieldsWidgetStyle('GrayText')}>
                            <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                          </div>
                          <div className={cnUserfieldsWidgetStyle('BlackText')}>
                            {value.includes("http") ? <Link to={value} >
                              {value}
                            </Link> : <span>{value}</span>}
                          </div>
                        </div>
                      )
                    }
                    if (field.fieldType === 'String' && field.mask === "telefon") {
                      if (!value || value === "+") return null
                      return (
                        <div key={fieldId} className={cnUserfieldsWidgetStyle('Fields-Item')}>
                          <div className={cnUserfieldsWidgetStyle('GrayText')}>
                            <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                          </div>
                          <div className={cnUserfieldsWidgetStyle('BlackText')}>
                            {value}
                          </div>
                        </div>
                      )
                    }
                    if (field.fieldType === 'Textarea') {
                      if (!value) return null
                      return (
                        <div key={fieldId} className={cnUserfieldsWidgetStyle('Fields-Item')}>
                          <div className={cnUserfieldsWidgetStyle('GrayText')}>
                            <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                          </div>
                          <div className={cnUserfieldsWidgetStyle('BlackText')}><MDRender source={value} /></div>
                        </div>
                      )
                    }


                    if (field.fieldType === 'DateTime') {
                      // const defaultMask = "dd/MM/yyyy"
                      if (!value) return null
                      if (!isValidDate(moment(value).toDate())) return null;
                      return <div key={fieldId} className={cnUserfieldsWidgetStyle('Fields-Item')}>
                        <div className={cnUserfieldsWidgetStyle('GrayText')}>
                          <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                        </div>
                        <div className={cnUserfieldsWidgetStyle('BlackText')}>{moment(value).format('L LT')}</div>
                      </div>;
                    }
                    if (field.fieldType === 'Date') {
                      // const defaultMask = "dd/MM/yyyy"
                      if (!value) return null
                      if (!isValidDate(moment(value).toDate())) return null;
                      return <div key={fieldId} className={cnUserfieldsWidgetStyle('Fields-Item')}>
                        <div className={cnUserfieldsWidgetStyle('GrayText')}>
                          <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                        </div>
                        <div className={cnUserfieldsWidgetStyle('BlackText')}>{moment(value).format('L')}</div>
                      </div>;
                    }
                    if (field.fieldType === 'AdditionalUserId' && !authUser.baseData.isAdmin) {
                      return null;
                    }
                    if (!value) return null;
                    return (
                      <div key={fieldId} className={cnUserfieldsWidgetStyle('Fields-Item')}>
                        <div className={cnUserfieldsWidgetStyle('GrayText')}>
                          <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                        </div>
                        <div className={cnUserfieldsWidgetStyle('BlackText')}>{value}</div>
                      </div>
                    );
                  }}
                  fields={user.baseData.userListFieldsOriginal.filter((el: any) => fieldsIds.includes(el.fieldId))} />
              </div>
              <div className={cnUserfieldsWidgetStyle('EditBtns')}>
                {/* {
                  edit && */}
                {/* // (
                    //   <>
                    //     <Button color={'primary'} onClick={save} variant={"contained"}>
                    //       <Icon icon={'check'} />
                    //     </Button>
                    //     <Button onClick={cancel} variant={"outlined"}>
                    //       <Icon icon={'times'} />
                    //     </Button>
                    //   </>
                    // )
                    // : */}
                <Button onClick={() => setEdit(true)} color="primary" variant={"contained"}>
                  <Translate i18nKey={`pryaniky.user.fields.edit`} />
                </Button>
                {/* } */}
              </div>
            </div>
            :
            <div className={cnUserfieldsWidgetStyle('EditContent')}>
              <div className={cnUserfieldsWidgetStyle('EditContent')}>
                <CatRender
                  onChangeFields={onChangeFields}
                  fieldsCategoryes={{
                    "undefined": fieldsIds
                  }}
                  fieldsSet={'userListFields'}
                  user={user}
                  allowEdit={allowEdit}
                />
              </div>
              <div>
                <Button color={'primary'} onClick={save} variant={"contained"}>
                  <Icon icon={'check'} />
                </Button>
                <Button onClick={cancel} variant={"outlined"}>
                  <Icon icon={'times'} />
                </Button>
              </div>
            </div>
        }
      </div>
    </div>
  )
}

export const UserFields = connect(
  mapUserfieldsWidgetStateToProps,
  mapUserfieldsWidgetActionToProps
)(UserFieldsPresenter)