import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { Characteristics as CharacteristicsTypes, IShopFilter } from 'utils/src/requests/models/api.shop'
// import { IStoreAppSetting } from 'models/store/appSettings';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { getShopCategoryProducts } from '../../../redux/shop/selectors';
import {
    getShopProducts,
    clearShopCatProducts
} from '../../../redux/shop/actions';
import { createSelector } from "reselect";
///////////

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace Shop {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace CurrentCategory {
        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export type IStateProps = ReturnType<ReturnType<typeof makeMapStateToProps>>
        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

        export interface IOwnProps extends IClassNameProps {
            isLoading?: boolean
            id: string
            categories: IShopFilter[]
            isHidePricesAndButtonBuy: boolean
            isHideButtonBuy: boolean
            additionalInformationBeforeOrder:string
            maxOrdersCount: number
            maxOrdersPeriod: string
            ordersCount: number
        }
    }
}

type IOwnProps = Shop.CurrentCategory.IOwnProps

const emptyArray: any = []


const getItems = (state: any, { id }: IOwnProps) =>
    getShopCategoryProducts(state)?.data || emptyArray

const makeGetProducts = () =>
    createSelector(
        [getItems, (state: any, { id }) => id],
        (items, id) => items.filter(product => product.id.toString() !== id)
    );

export const makeMapStateToProps = () => {
    const getProducts = makeGetProducts()
    return (state: any, props: IOwnProps) => ({
        items: getProducts(state, props)
    });
}


export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    getShopProducts,
    clearShopCatProducts
}, dispatch);
export const cnClassName = cn('CurrentCategory');