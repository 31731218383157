import {
  IShopActions,
  IShopSetShopItemPayload,
  IShopMakeShopOrderPayload,
  IShopSetShopProductsPayload,
  IShopSetShopLoadingPayload,
  IShopPathOpenProductPayload,
  IShopCheckCharacteristics,
  IShopSetShopFiltersPayload
} from './actions.interfaces';

import {
  Characteristics
} from 'utils/src/requests/models/api.shop';

export const SHOP_GET_SHOP_PRODUCT = 'SHOP_GET_SHOP_PRODUCT';
export const SHOP_SET_SHOP_PRODUCT = 'SHOP_SET_SHOP_PRODUCT';
export const SHOP_CLEAR_SHOP_PRODUCT = 'SHOP_CLEAR_SHOP_PRODUCT';
export const SHOP_MAKE_SHOP_ORDER = 'SHOP_MAKE_SHOP_ORDER';
export const SHOP_GET_SHOP_CAT_PRODUCTS = 'SHOP_GET_SHOP_CAT_PRODUCTS';
export const SHOP_SET_SHOP_CAT_PRODUCTS = 'SHOP_SET_SHOP_CAT_PRODUCTS';
export const SHOP_SET_SHOP_LOADING = 'SHOP_SET_SHOP_LOADING';
export const SHOP_CLEAR_SHOP_CAT_PRODUCTS = 'SHOP_CLEAR_SHOP_CAT_PRODUCTS';
export const SHOP_PATH_OPEN_PRODUCT = 'SHOP_PATH_OPEN_PRODUCT';
export const SHOP_CHECK_CHARACTERISTICS = 'SHOP_CHECK_CHARACTERISTICS';
export const SHOP_SET_SHOP_CHOSEN_FILTERS = 'SHOP_SET_SHOP_CHOSEN_FILTERS';
export const SHOP_CLEAR_SHOP_FILTERS = 'SHOP_CLEAR_SHOP_FILTERS'
export const SHOP_GET_SHOP_CHARACTERISTICS = 'SHOP_GET_SHOP_CHARACTERISTICS'


// export const SHOP_ = 'SHOP_';
// export const SHOP_ = 'SHOP_';
// export const SHOP_ = 'SHOP_';

/*****************************************/
/*************** to saga *****************/
/*****************************************/


/**
 * get shop item by id from server
 * @param { number | string } payload
 */
export const getShopProduct = (payload: number | string): IShopActions => ({
  type: SHOP_GET_SHOP_PRODUCT,
  payload
});

/**
 * make shop order
 * @param { IShopMakeShopOrderPayload } payload
 */
export const makeShopOrder = (payload: IShopMakeShopOrderPayload): IShopActions => ({
  type: SHOP_MAKE_SHOP_ORDER,
  payload
})

/**
 * get shop products 
 * @param { any } payload opts
 */
export const getShopProducts = (payload: any): IShopActions => ({
  type: SHOP_GET_SHOP_CAT_PRODUCTS,
  payload
})

/**
 * get shop characteristics 
 * 
 */
export const getShopCharasteristics = (): IShopActions => ({
  type: SHOP_GET_SHOP_CHARACTERISTICS,
})

/*****************************************/
/*************** to reducer **************/
/*****************************************/

/**
 * set shop item to redux
 * @param { IShopSetShopItemPayload } payload
 */
export const setShopProduct = (payload: IShopSetShopItemPayload): IShopActions => ({
  type: SHOP_SET_SHOP_PRODUCT,
  payload
});

/**
 * set shop products to redux
 * @param { IShopSetShopOrdersPayload } payload
 */
export const setShopProducts = (payload: IShopSetShopProductsPayload): IShopActions => ({
  type: SHOP_SET_SHOP_CAT_PRODUCTS,
  payload
})

/**
 * set shop filters & other query params to redux
 * @param { IShopSetShopFiltersPayload } payload
 */
export const setShopFilters = (payload: IShopSetShopFiltersPayload | IShopSetShopFiltersPayload[]): IShopActions => ({
  type: SHOP_SET_SHOP_CHOSEN_FILTERS,
  payload
})

/**
 * set shop loading & finished
 * @param { IShopSetShopLoadingPayload } payload
 */
export const setShopLoading = (payload: IShopSetShopLoadingPayload) => ({
  type: SHOP_SET_SHOP_LOADING,
  payload
})

/**
 * 
 * @param payload 
 */
export const pathOpenProduct = (payload: IShopPathOpenProductPayload) => ({
  type: SHOP_PATH_OPEN_PRODUCT,
  payload
})


/**
 * 
 * @param payload 
 */
export const checkCharacteristics = (id: string | number, characteristics: Characteristics.IItem[]): IShopCheckCharacteristics => ({
  type: SHOP_CHECK_CHARACTERISTICS,
  payload: {
    id,
    characteristics
  }
})

/**
 * clear open shop product
 */
export const clearShopProduct = () => ({
  type: SHOP_CLEAR_SHOP_PRODUCT
})


export const clearShopCatProducts = () => ({
  type: SHOP_CLEAR_SHOP_CAT_PRODUCTS
})

/**
 * clear filters and categoties 
 */
export const resetFilters = () => ({
  type: SHOP_CLEAR_SHOP_FILTERS
})


