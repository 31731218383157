import Component, { Presenter } from './component';
import {
    News,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = News.TimelineHeader.IOwnProps;
export type IComponentPresenterProps = News.TimelineHeader.IPresenterProps;


export default Component