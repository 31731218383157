import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { IBodyProps } from 'utils/src/DialogCreator';

export const cnChangePassword = cn('ChangePassword');

export interface IChangePasswordData {
  oldPassword: string;
  newPassword: string;
  conPassword: string;
}

export interface IChangePasswordProps extends IBodyProps<IChangePasswordData>, IClassNameProps, IChangePasswordOwnProps {}

export interface IChangePasswordOwnProps {
  userId: string;
  currentUser: any;
}