import Component, { Presenter } from './component';
import {
    LMS,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = LMS.ScormResult.IOwnProps;
export type IComponentPresenterProps = LMS.ScormResult.IPresenterProps;

export default Component