/**
 * @packageDocumentation
 * @module Widget_Settings_tagsCloud
 */
import { JFdictionary } from "../dictionary";

const { types } = JFdictionary;
export default {
  type: types.object,
  properties: {
    tagsCloud: {
      type: types.object,
      require: true,
    },
  }
}
