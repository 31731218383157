import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { saveCourse, createUnit } from '../../redux/actions/LMSTeacherOffice'
export interface ICourseEditorProps extends IClassNameProps, ICourseEditorStateProps, IDispatchProps, RouteComponentProps, ICourseEditorDispatchProps {
    tag?: 'div';
}

export interface ICourseEditorState {
    cid: string
}

export interface ICourseEditorStateProps {
    activeSessions?: any[]
}

export const mapStateToProps = (state: any, props: any) => ({
    activeSessions: state.LMSTO.courses.values[props.cid].activeSessions,
    isSaving: state.LMSTO.requestsActivity.handleSaveCourse === 'PENDING',
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    saveCourse,
    createUnit
}, dispatch);

export type ICourseEditorDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnCourseEditor = cn('CourseEditor');