import React from 'react';
import * as utils from 'utils/src/utils';

import { cnWidget, cnWidgetMui, IWidgetProps } from '../Widget.index';

import './Widget_type_virtcurrency.scss';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";

const types = {
  common: require('blocks/Virtcurrency/Virtcurrency').Virtcurrency,
}

const type = 'virtcurrency';

export default class WidgetTypeVirtcurrencyPresenter extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    return <Component {...this.props} className={cnWidgetMui({type, subtype})} context={this.props.wcontext} tag={widgets.components.common} />
  }

}