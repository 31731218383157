import * as React from 'react';
import { IInfoProps, cnItem, cnInfoBlock } from './AbstractInfoElement.index';
import { Skeleton } from 'uielements/src';

export abstract class AbstractInfoItem<P extends IInfoProps, S> extends React.Component<P, S> {
  public static defaultProps = {
    children: (data: any) => <div>NoRender</div>,
    setData: (data: any) => {
      /**/
    },
    data: false,
    edit: 'false',
    type: 'state',
  };

  protected abstract actionGetData: () => void;

  public componentDidMount() {
    if (!this.props.data) this.actionGetData();
  }

  public render() {
    if (!this.props.data) {
      return (
        <div className={cnInfoBlock('Skeleton')}>
          <Skeleton shape="circle" size="xxxl" />
          <div className={cnInfoBlock('Info')}>
            <Skeleton shape="line" size="xl" style={{ width: '60%' }} spacing="lg" />
            <Skeleton shape="line" spacing="lg" />
            <Skeleton shape="line" style={{ width: '80%' }} spacing="lg" />
          </div>
        </div>
      );
    }
    return this.renderChildren();
  }

  protected renderChildren() {
    const { className, data, children } = this.props;
    return <div className={cnItem({}, [className])}>{(children as any)(data)}</div>;
  }
}
