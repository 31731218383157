import React, { FC, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext } from 'News/contexts/news'

import { Attachments } from 'uielements/src/Attachments/Attachments';
import { Preview } from 'uielements/src/Preview/Preview';
import { Button } from 'uielements/src';
import * as utils from 'utils/src/utils';
import { LinkLayout } from 'blocks/NewsTypes/common/LinkLayout/LinkLayout';
import { NewsEdit } from 'blocks/NewsEdit_new/NewsEdit'

import NewsHeader from 'News/containers/NewsHeader'
import NewsActions from 'News/containers/NewsActions'
import NewsComments from 'News/containers/NewsComments'


type IPresenterProps = News.FullView.IPresenterProps
type IOwnProps = News.FullView.IOwnProps
type IDispatchProps = News.FullView.IDispatchProps
type IStateProps = News.FullView.IStateProps
type IState = News.FullView.IState


export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    edit,
    attachments,
    id,
    linkPreviewImgUrl,
    systemNewsActionHtml,
    toggleEdit
}) => {

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Appointed')}>
            кто то вам что то назначил
            </div>
        <div className={'NewsLayout'}>
            <NewsHeader />

            <div className={cnClassName('Content')}>
                {children}
            </div>


            {!edit && <LinkLayout {...{ linkPreviewImgUrl, systemNewsActionHtml, badgeId: '' }} />}

            {!edit && <Preview className={cnClassName('Preview', ['horizontalPadding'])} newsid={id} files={attachments ? attachments : []} />}

            {!edit && <Attachments className={cnClassName('Attachments', ['horizontalPadding'])} files={attachments ? attachments : []} onDelete={() => { }} state='view' />}

            <div className={cnClassName('Actions')}>
                <NewsActions className={cnClassName('NewsActions')} />

                <Button main className={cnClassName('ItemAction')}>Что то сделать</Button>
            </div>
        </div>
        <NewsComments />
    </div>
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));
