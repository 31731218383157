import React, { FC } from 'react';
import './Item.scss'
import { Avatar, Button, Icon } from 'uielements/src';
import { UserList } from 'uielements/src/UserList/UserList';

export const Item: FC<any> = ({ data, style, onCloseSuggester }) => {
    return <Button onClick={onCloseSuggester} style={style} theme={'unstyled'} type={'rlink'} href={data.link} className={'Item'}>
        <div className={'Item-ImgBox'}>
            <Avatar imgUrl={data.imgUrl} size={40} />
        </div>
        <div className={'Item-DataBox'}>
            <div className={'Item-Title'}><Icon className={'Item-Icon'} icon={data.icon} />{data.title}</div>
            <div className={'Item-Desc'}>{data.description}</div>
            {data.baseNetwork && <div className={'Item-Network'}><Icon className={'Item-NetworkIcon'} icon={'chart-network'} />{data.baseNetwork.title}</div>}
        </div>
        {data.endorsements && <UserList
            className={'Item-UserList'}
            cut={false}
            autoHide={false}
            underNext={true}
            size={24}
            fixed
            action={false}//{bar.bar.data.value > 2}
            actionBtnContent={<span className={'Item-UserListValue'}>+{data.endorsements.length}</span>}
            data={data.endorsements.slice(0, 3)}
            actionCallback={() => { }} />}
    </Button>
} 