/**
 * @packageDocumentation
 * @module List_type_badges_search
 */
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from '../../../../redux/connector';
import { mapStateToProps, IListStateProps, IListProps, IListState, cnList, mapActionsToProps } from './../../List.index';
import { IListTypeBadgesSearchProps, avalibleFilters } from './List_type_badges_search.index';
import './List_type_badges_search.scss';
import { AbstractList } from '../../List';
import { IListResponseWithData } from 'utils/src/requests/models/api.base';
import { ListLite, Avatar, Button, Loading } from 'uielements/src';
import { Item } from 'blocks/List/Item/List-Item';
import { IStateType as IState } from 'redux/store';
import { TypedListPlaceholder } from 'uielements/src';

export class ListBadgesPresenter extends AbstractList<IListTypeBadgesSearchProps, IListState> {
  public static defaultProps = {
    requestOptions: {
      search: '',
      catFilter: '',
      extended: true,
      count: 20,
      skipCount: 0,
      network: '',
    },
  };

  public type = 'badges';

  constructor(props: IListTypeBadgesSearchProps) {
    super(props);
    this.prepareData = this.prepareData.bind(this);
  }

  public componentDidMount() {
    this.mounted = true;
    this.getData();
  }

  public search = (text: string) => {
    const { toggle } = this.props;
    this.searchString = text;
    toggle({
      id: this.listId,
      data: {
        hideData: text.length !== 0,
        clearData: true,
        isFinished: false
      }
    });
    this.getData();
  };

  public getData = () => {
    const { requestOptions, context, edit } = this.props;
    const { clearData, isLoading } = this.props;
    const { clearDataFromList, getBadges, toggle } = this.props;
    const id = this.listId;
    if (edit || isLoading || !this.mounted) return;
    const opts = Object.assign({}, requestOptions);

    Object.keys(context).forEach((cKey: string) => {
      if (avalibleFilters[cKey]) opts[avalibleFilters[cKey]] = context[cKey];
    });

    if (this.searchString) opts.search = this.searchString;
    if (clearData) {
      clearDataFromList({ id, data: null });
    }
    toggle({
      id,
      data: {
        isLoading: true,
        isFinished: false
      }
    });
    getBadges.call(this, id, opts);
  };

  public componentDidUpdate(pp: IListTypeBadgesSearchProps) {
    const { context: c, toggle, isLoading } = this.props;
    const { context: pc } = pp;
    if (
      Object.keys(avalibleFilters).filter((fk) => c[fk] !== pc[fk] ? true : false).length

    ) {
      toggle({
        id: this.listId,
        data: {
          hideData: true,
          clearData: true,
          isFinished: false,
        }
      });

      setTimeout(() => this.getData(), 10);
    }
  }

  public prepareData(el: any) {
    el.view_data = {
      avatar: <Button theme='unstyled' type="rlink" href={'/badge/' + el.id}>
        <Avatar imgUrl={el.imgUrl} imgId={el.id} size={56} name={el.name} />
      </Button>,
      info: [
        [
          <Button
            className="BadgeName"
            key={0}
            noBorder
            noPadding
            noBackground
            type="rlink"
            href={'/badge/' + el.id}
            children={el.name}
          />,
          el.position,
          el.division,
        ],
        [
          <Button
            className="BadgeName"
            key={0}
            noBorder
            noPadding
            noBackground
            type="rlink"
            href={'/badge/' + el.id}
            children={el.description}
          />,
        ],
      ],
    };
  }

  public renderChildren = () => {
    // const { isFinished, isLoading, hideData } = this.state;
    const { data, isFinished, isLoading, hideData } = this.props;
    // const data: any[] = this.state[this.dataVariable];
    return (
      <>
        {
          data.length !== 0 && <ListLite className={'rounded-bottom'} isFinished={isFinished} isLoading={isLoading} loadMore={this.getData} noSkeletons={isFinished}>
            {data.map(el => (
              <Item
                lId={this.listId}
                iId={el}
                type={'common'}
                key={el}
                data_key={el}
              />
            ))}
          </ListLite>
        }
        {(!isLoading && data.length === 0) && <TypedListPlaceholder type='search' />}
      </>
    );
  };
}

export const List = connect<IListStateProps, IDispatchProps, IListProps, IState>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
)(ListBadgesPresenter);
