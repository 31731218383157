import React, { FC, useState, forwardRef } from 'react';
import { ITextInputProps } from './TextInput.index'
import './TextInput.css'
import { InputLayout } from '../InputLayout/InputLayout'
import { LineInput } from '../LineInput/LineInput'


export const TextInput_: FC<ITextInputProps & any> = ({ onChange, value, icon, action, placeholder, className, ...props }, forwardRef) => {
    const [focused, setFocused] = useState(false);

    const onChangeValue = (e: any) => {
        onChange(e.target.value)
    }
    return <div className={className}>
        <InputLayout
            action={action}
            focus={focused}
            icon={icon}
        >
            <LineInput
                {...props}
                placeholder={placeholder}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onChange={onChangeValue}
                value={value}
                ref={forwardRef}
                 />
        </InputLayout>
    </div>
}

export const TextInput = forwardRef(TextInput_);