import React, { FC, useState, useCallback, useMemo } from 'react';
import { cnClassName, IPageCommentsWidgetProps } from './WidgetPageComment.index';
// import './WidgetPageComment.scss';
import { i18n } from "localization";
// import { CommentsRender } from 'News/containers/CommentsRender'
import NewsItem from 'News/containers/NewsItem'

export const PageCommentsWidget: FC<IPageCommentsWidgetProps & any> = ({
  className,
  tag: TagName = 'div',
  widget,
  edit,
  ...props
}) => {

  const updateComponent = useMemo(() => {
    return <NewsItem id={props.wcontext.currentPageId || props.wcontext.newsUid} className={cnClassName('News')} /*ViewComponent={CommentsWithActions}*/ />
  }, [props.wcontext.currentPageId || props.wcontext.newsUid])

  return <TagName {...props} className={cnClassName({}, [className])}>
    {(props.wcontext.currentPageId || props.wcontext.newsUid) && updateComponent}
  </TagName>;
};