import * as React from 'react';
import { IEditorTypeEditProps } from './edit.index';
import { BadgesNewsCreate } from '../../../Badges.type'
import { Editor } from '../../Editor'
import { BadgeValidator, badgeEditValidate } from '../../../Badges.validate'
export const EditorTypeEdit: React.FC<IEditorTypeEditProps> = ({ onChange, data }) => {
    const localData = data as any;
    
    const onChangeText = (text: string) => {
        localData.text = text;
        // (localData as any).badge = undefined
        
        const vb = new BadgeValidator();
        vb.addData(localData)
        vb.setRules(badgeEditValidate)
        onChange(localData, vb.validation().length === 0);
    }
    return <Editor
        onChangeText={onChangeText}
        data={localData} />
}
