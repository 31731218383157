import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import {
     transitionToUnit,
     closeSessionOnCompleteMondatory
    } from "../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';

export interface IListSkipedTestsProps extends IClassNameProps, IListSkipedTestsStateProps, IDispatchProps,
    RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
    cid: string
    // uid: string;
    // unitContent: any
}

export interface IListSkipedTestsState {
    // courses: ICourse[];
    // isFinished: boolean;
}

export interface IListSkipedTestsStateProps {
    // store: any
    // unitContent: any
    units: any
}

export const mapStateToProps = (state: any, props: any) => ({
    // store: state.store,
    units: state.LMS.units,
    // unitContent: state.LMS.units[props.uid],
    skiped: state.LMS.courses.entities.values[props.cid].courseUnits.filter((unit: any) => !state.LMS.unitsLogs[unit.id].isCompleted),
    // questions: state.LMS.units[props.uid].normalQuestions
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    jumpToUnit: transitionToUnit,
    closeSessionOnCompleteMondatory
    // loadUnitContent,
    // saveStartUnitResult,
    // startQuestion,
    // setNextQuestion,
    // jumpToQuestion,
    // completeTest
}, dispatch);

export const cnListSkipedTests = cn('ListSkipedTests');