import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeInfoProps } from './Widget_type_infoData.index';
import { default as WidgetPresenter } from '../Widget';
import { widgets } from "i.widgets";
import { GroupsWidgetNew } from 'blocks/GroupsWidgetNew/GroupsWidgetNew';

const types = {
  common: GroupsWidgetNew,
}

const type = 'groupsNew';

export default class WidgetTypeInfoPresenter extends WidgetPresenter<IWidgetTypeInfoProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    const Tag = widgets.components.common
    return <Tag {...this.props} className={cnWidget({ type, subtype })}>
      <Component
        {...this.props}
        settings={this.props.widget.settings}
        context={this.props.wcontext}
        tag={widgets.components.common} />
    </Tag>
  }
}
