import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
import { cn } from '@bem-react/classname'
import { NewsContextProps } from 'News/contexts/news'
import {
    getNewsParamsById,
    getNewsById
} from 'News/redux/saga/selectors'
import { WorkflowsNews } from './Workflows.type'
export interface IWorkflowsPropsType extends NewsContextProps {}
export type TStateProps = ReturnType<typeof mapStateToProps>

const newsParams = [
    'id',
    'fromCache',
    'text',
    'additionalFields',
    'renderParams'
] as const

export const mapStateToProps = (state: any, { newsId }: IWorkflowsPropsType) => ({
    userData: getCurrentUser(state),
    workflowId: getNewsById(newsId)(state)?.additionalFields?.formId,
    ...getNewsParamsById<WorkflowsNews, typeof newsParams[number]>(newsId, newsParams)(state)
})

export const classname = cn('WorkflowsLayout')
