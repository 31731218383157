import React, { useState } from 'react';
import { cnVirtualUser, IVirtualUserProps } from './VirtualUser.index';
import { shuffle } from 'utils/src/utils';
import BubbleImg from 'assets/png/Buble.png';
import { useOutsideClick } from 'utils/src/hooks';
import './VirtualUser.scss';

export const VirtualUser: React.FC<IVirtualUserProps> = (props) => {

  const { tag: Tag = 'div', data } = props;
  const [currenIdx, setCurrentIdx] = useState<number>(-1);
  const ref = React.useRef<HTMLDivElement>(null);

  let elements = data.elements;
  if (data.isShuffle) elements = shuffle(elements);

  const onClick = () => {
    if (currenIdx + 1 === elements.length) setCurrentIdx(-1);
    else setCurrentIdx(cur => ++cur)
  }

  useOutsideClick(ref, () => setCurrentIdx(-1));

  const getFontSize = (textLength: number): number => {
    let size = 7;
    if (textLength <= 10) size = 42;
    else if (textLength <= 20) size = 28;
    else if (textLength <= 40) size = 23;
    else if (textLength <= 90) size = 18;
    else if (textLength <= 180) size = 13;
    else if (textLength <= 360) size = 9;
    return size
  }
  
  return (
    <Tag {...props} className={cnVirtualUser('', { png: data.imgUrl?.substr(-1)?.toLowerCase() === 'png' })} >
      
      <div ref={ref}>
        <img onClick={onClick} className={cnVirtualUser('Image')} src={data.imgUrl} />

        {
          currenIdx > -1 ?
            <div onClick={onClick} className={cnVirtualUser('Tooltip')}>
              <div className={cnVirtualUser('Tooltip-Inner')}>
                <img src={BubbleImg} className={cnVirtualUser('Tooltip-Image')} />
                <div className={cnVirtualUser('Tooltip-Text')} style={{ fontSize: getFontSize(elements[currenIdx].text.length) }}>{elements[currenIdx].text}</div>
              </div>
            </div> :
            <div onClick={onClick} className={cnVirtualUser('StartText')}>{data.startText}</div>
        }
      </div>
    </Tag>
  )
}
