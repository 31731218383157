import { IIdeaFieldProps } from '../FieldProps';
import React, { useState } from 'react';
import { Input } from "uielements/src/Input/Input";
import i18n from 'localizations/i18n';
import { cn } from "@bem-react/classname";
import './Int.scss';

export const cnInt= cn("Int");
export const Int: React.FC<IIdeaFieldProps> = ({icon, field, onChange = () => { } }) => {
    // const [value, setValue] = useState(field.value);
    const [text, setText] = React.useState({ value: '', valid: true, error: i18n.t('') });

    const onEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
        field.value = event.target.value;
        // setValue(field.value);
        event.target.onfocus = () => {
         setText({ value: field.value, valid: true, error: '' });
        }
        event.target.onblur = () => {
            if (field.field.isMandatory && field.value === '') setText({ value: field.value, valid: false, error: i18n.t('поле не должно быть пустым') });
            else setText({ value: field.value, valid: true, error: '' });
        }
        onChange(field);
        
    } 
    // const onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     event.target.onblur = () => {
    //         if (field.field.isMandatory && field.value === '') setText({ value: field.value, valid: false, error: i18n.t('поле не должно быть пустым') });
    //         else setText({ value: field.value, valid: true, error: '' });
    //     }
    //     onEdit(event);
    // }
    return (
        <Input  className={cnInt("Input")} invalid={!text.valid} errorMessage={text.error} required  icon={"sort-numeric-up"} type="number" value={field.value} onChange={onEdit} /> 
    )
}