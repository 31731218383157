import { BaseNews, User, defaultBaseNews } from '../BaseType'
import { IBaseNews, createDefaultNews } from 'News/types/baseNews'
export interface IThanksNews extends IBaseNews {
    newstype: 4;
    thanks: Thanks;
    rewardReasons: RewardReason[];
    users: User[];
    groups?: any[];
    thanksForAll?: number;
    usersCount?: number;
}


export interface Thanks {
    participants: User[];
}

export interface ThanksNews extends BaseNews {
    newstype: 4;
    thanks: Thanks;
    rewardReasons: RewardReason[];
    users: User[];
    groups?: any[];
    thanksForAll?: number;
    usersCount?: number;
}


export interface RewardReason {
    id: string;
    name: string;
    selected: boolean;
}


export const defaultThanks: Thanks = {
    participants: []
}

export const defaultRewardReason: RewardReason = {
    id: "",
    name: "",
    selected: false
}

export const defaultThanksNews: ThanksNews = {
    ...defaultBaseNews,
    newstype: 4,
    thanksCount: 1,
    thanks: defaultThanks,
    users: [],
    groups: [],
    rewardReasons: [],
    thanksForAll: 0,
    usersCount: 0
}

export const createDefaultModel = (): IThanksNews => ({
    ...createDefaultNews(),
    newstype: 4,
    thanksCount: 1,
    thanks: defaultThanks,
    users: [],
    groups: [],
    rewardReasons: [],
    thanksForAll: 0,
    usersCount: 0

})