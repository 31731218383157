import React, { FC, useMemo } from 'react'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import i18n from 'localizations/i18n';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';
import { Translate } from 'localizations/Translate';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noEditblField: {
            textDecoration: "none",
            margin: "25px 0 0 0",
            borderBottom: "1px solid #999",
            width: "100%"
        },
        noEditblFieldSpan: {
            color: "#999",
            fontSize: "13px"
        },
        noEditblFieldP: {
            margin: "0"
        },
        formControl: {
            flex: '1 1 100%',
            marginTop: '16px',
        },
    })
);


export const Bool: FC<any> = ({ field, fieldId, value, onChangeFields, fieldsSet }) => {
    const classes = useStyles();
    
    return (
        field && field.isReadOnly ?
            <HelpTooltip help={i18n.t('pryaniky.profile.only.Admin.helptooltip')} className={classes.noEditblField}>
                <span className={classes.noEditblFieldSpan}>
                    <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                </span>
                <p className={classes.noEditblFieldP}>{value}</p>
            </HelpTooltip> : <FormControlLabel key={fieldId} id={fieldId} control={<Checkbox value={value} onChange={e => onChangeFields(fieldId, 'value', fieldsSet)(e.currentTarget.value)} disabled={field.isReadOnly} /> } label={<Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />} className={classes.formControl}/>
    );

}

export default Bool