// import {User, BaseNews, defaultBaseNews} from '../BaseType'
import { IBaseNews, createDefaultNews } from 'News/types/baseNews'

export interface Uevent {
    title: string;
    startDateTime: string;
    duration: number;
    eventStatus: string;
    eventLocation: string;
    eventParticipants: any[];
    eventMaybePar?: any[];
    eventNotGoers?: any[];
    eventWaitList?: any[];
    eventParticipantsCount: number;
    eventNotGoersCount: number;
    eventMaybePartCount: number;
    eventWaitListCount: number;
    
    state?: string;
    disallowParticipate?: boolean;
    enableWaitList?: boolean;
    maxParticipantsCount: number | null;
    meeting?: { 
        domain: string;
        meetId: string;
        url: string;
        online: boolean 
    }
}

const currentDateTime = new Date();
currentDateTime.setHours(12, 0);
export interface UeventNews extends IBaseNews {
    uevent: Uevent;
    newstype: 23;
    expires: string
    users: any[];
}



export const defaultUevent: Uevent = {
    title: '',
    startDateTime: currentDateTime.toISOString(),
    duration: 15,
    eventStatus: '',
    eventLocation: '',
    eventParticipants: [],
    eventParticipantsCount: 0,
    eventNotGoersCount: 0,
    eventMaybePartCount: 0,
    eventWaitListCount: 0,
    maxParticipantsCount: null,
    meeting: { 
        domain: '',
        meetId: '',
        url: '',
        online: false 
    }
    // state: '',
}

export const defaultUeventNews: UeventNews = {
    ...createDefaultNews(),
    newstype: 23,
    expires: currentDateTime.toISOString(),
    uevent: defaultUevent,
    users: []
}

export const createDefaultModel = (): UeventNews => ({
    ...createDefaultNews(),
    newstype: 23,
    expires: currentDateTime.toISOString(),
    uevent: defaultUevent,
    users: []

})