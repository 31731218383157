import React from 'react';
import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';
import { Translate } from 'localizations/Translate';

const _makeDefaultStartTime = () => {
    const currentTime = (new Date()).getHours();
    if (currentTime > 23) return 3;
    if (currentTime > 20) return 23;
    if (currentTime > 17) return 21;
    if (currentTime > 14) return 18;
    if (currentTime > 11) return 15;
    if (currentTime > 8) return 12;
    if (currentTime > 5) return 9;
    return 6
}

export const makeDefaultStartTime = () => {
    const currentDate = new Date();
    currentDate.setHours(_makeDefaultStartTime())
    currentDate.setMinutes(0)
    currentDate.setDate((new Date()).getDate() + 1)
    return currentDate;
}
export const makeDuration = (duration: number) => {
    let rez;
    const hours = Math.floor(duration / 60);
    const minutes = duration - (hours * 60);
    rez = (hours > 0 ? hours + ' ' + i18n.t('pryaniky.inputs.time.hour', { count: hours }) + ' ' : '')
        + (minutes > 0 ? minutes + ' ' + i18n.t('pryaniky.inputs.time.minute', { count: minutes }) : '');
    if (hours === 24) rez = i18n.t('pryaniky.post.event.allDay');
    return rez;
}

export const makeDate = {
    date: (startDateTime: Date) => {
        const date = new Date(startDateTime);
        return date.toLocaleString(i18n.language, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    },

    dateNoWeekday: (startDateTime: Date) => {
        const date = new Date(startDateTime);
        return date.toLocaleString(i18n.language, { year: 'numeric', month: 'long', day: 'numeric' }).replace('г.', '').trim();
    },

    weekday: (startDateTime: Date) => {
        const date = new Date(startDateTime);
        return date.toLocaleString(i18n.language, { weekday: 'long' });
    },

    time: (startDateTime: Date) => {
        const date = new Date(startDateTime);
        return date.toLocaleString(i18n.language, { minute: 'numeric', hour: 'numeric', hour12: false });
    },

    day: (startDateTime: Date) => {
        const date = new Date(startDateTime);
        return date.toLocaleString(i18n.language, { day: 'numeric' });
    },

    month: (startDateTime: Date) => {
        const date = new Date(startDateTime);
        return String(date.toLocaleString(i18n.language, { month: 'long', day: 'numeric' }).replace(/[\d]+/, ''));
        // .match(/[^\s\d]+/))
    }
}


export const removeMarkDownLink = utils.removeMdLink///(str: string) => str.replace(/\[([^[\]]*?)\]\(.*?\)|^\[*?\]\(.*?\)/gi, '$1');


const eventDurationsVariants = [
    15,
    30,
    45,
    60,//1
    90,
    120,//2
    150,
    180,//3
    210,
    240,//4
    300,//5
    360,//6
    420,//7
    480,//8
    540,//9
    600,//10
    660,//11
    720,//12
    1440,//13
]

export const createDurationOptions = () => eventDurationsVariants.map((value) => {
    const e = { value, title: <></> }
    if (e.value < 60) e.title = <>{e.value} <Translate i18nKey={'pryaniky.inputs.time.minute'} count={e.value} /></>
    else if (e.value >= 60 && e.value <= 720) {
        const value = e.value / 60;
        e.title = <>{value} <Translate i18nKey={'pryaniky.inputs.time.hour'} count={value} /></>
    }
    else if (e.value > 720) e.title = <Translate i18nKey={'pryaniky.inputs.time.allDay'} />;
    return e;
});


export const findSelected = (duration: number) => {
    const arr = createDurationOptions();
    return arr.find((v: any) => {
        return duration === v.value
    }) || arr[0];
}