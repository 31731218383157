import React, { FC, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { skipTypes, сountModes } from '../constants'
import { connect } from 'react-redux';
import './style.scss'
import QuestionEditor from '../TaskQuestionEditor'

import FormControlO from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import PaperO from '@material-ui/core/Paper';
import { arrayMove } from 'utils/src/utils'
import uuid from 'uuid/v1';

import { validator, fieldWithError } from '../unitValidation'

import { controlStyle, peperStyle, controlLabelStyle } from './style'

import { questionModelCreator } from 'LMSModule/utils/dataCreators/questionCreator'
import {
    SortableContainer,
    SortableElement,
} from 'react-sortable-hoc';

import VersionsList from '../../../components/VersionsList'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

const Paper = styled(PaperO)(peperStyle)
const FormControlLabelHelped = styled(fieldWithHelp(FormControlLabel))(controlLabelStyle)
const FormControlHelped = styled(fieldWithHelp(FormControlO))(controlStyle)
const FormControl = styled(FormControlO)(controlStyle)

const SortableItem = SortableElement(QuestionEditor);

const Sortable = SortableContainer(({ children }: any) => {
    return <div>{children}</div>;
});

export const Presenter: FC<IPresenterProps> = ({
    className,
    unit,
    onChange,
    setUnitError,
    errors,
    createQuestion,
    updateUnit,
    deleteQuestion: deleteQuestionAction,
    versions = [],
    loadUnit,
    changeLocation
}) => {
    const changeText = (property: string) => (e: any) => {
        setUnitError(validator(property, e.target.value))
        updateUnit({ ...unit, [property]: e.target.value })
    }
    const changeSelect = (property: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
        updateUnit({ ...unit, [property]: event.target.value })
    }
    const changeCheck = (property: string) => () => {
        updateUnit({ ...unit, [property]: !unit[property] })
    }
    const addQuestion = () => {
        const question = questionModelCreator(uuid())
        question.header = tT('test_edit.question.header.1', { value: unit.questions.length + 1})
        createQuestion(question, unit.id)
        updateUnit({ ...unit, questions: [...unit.questions, question.id] })
    }
    const deleteQuestion = (qid: string) => {
        deleteQuestionAction(unit.id, qid)
        // updateUnit({ ...unit, questions: unit.questions.filter((id: string) => id !== qid) })
    }
    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        updateUnit({ ...unit, questions: arrayMove(unit.questions, oldIndex, newIndex) });
    };
    const onSelectVersion = (version?: string) => {
        version ? changeLocation(`?tab=edit&unitId=${unit.id}&unitVersion=${version}`) : changeLocation(`?tab=edit&unitId=${unit.id}`)
        // loadUnit(unit.id, version)
    }
    return <div className={cnClassName({}, [className])}>
        {/* <h3 className={cnClassName('Title')}>{unit.name}</h3> */}
        <VersionsList versions={versions} selectedVersion={unit.versionDate} onSelect={onSelectVersion} modifed={unit.modifed} />
        <Paper elevation={0} square>

            <FormControl fullWidth >
                <TextField
                    label={tT('test_edit.name.1')}
                    InputProps={{ inputProps: { minlength: 1, maxlength: 100 } }}
                    error={fieldWithError(errors['name'])}
                    value={unit.name}
                    onChange={changeText('name')}
                />
            </FormControl>

            <FormControl fullWidth >
                <TextField
                    label={tT('test_edit.description.1')}
                    multiline
                    value={unit.description}
                    onChange={changeText('description')}
                />
            </FormControl>

            {/* <FormControlHelped fullWidth help={
                unit.highlightCorrectness ? tT('test_edit.maxAttemptsCount.help.highlight.1') :
                    tT('test_edit.maxAttemptsCount.help.1')}>
                <TextField
                    label={tT('test_edit.maxAttemptsCount.1')}
                    disabled={unit.highlightCorrectness}
                    InputProps={{ inputProps: { min: 1 } }}
                    type={'number'}
                    error={fieldWithError(errors['maxAttemptsCount'])}
                    value={unit.maxAttemptsCount}
                    onChange={changeText('maxAttemptsCount')}
                />
            </FormControlHelped> */}

            {/* <FormControlHelped fullWidth help={tT('test_edit.passingMinScore.help.1')}>
                <TextField
                    label={tT('test_edit.passingMinScore.1')}
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    type={'number'}
                    error={fieldWithError(errors['passingMinScore'])}
                    value={unit.passingMinScore}
                    onChange={changeText('passingMinScore')}
                />
            </FormControlHelped> */}

            {/* <FormControlHelped fullWidth help={tT('test_edit.scoreCountMode.help.1')}>
                <InputLabel id="scoreCountMode-select-label">{tT('test_edit.scoreCountMode.1')}</InputLabel>
                <Select
                    labelId="scoreCountMode-select-label"
                    id="scoreCountMode-select"
                    value={unit.scoreCountMode}
                    onChange={changeSelect('scoreCountMode')}
                >
                    {сountModes.map(item => <MenuItem value={item.value}>{item.title}</MenuItem>)}
                </Select>
            </FormControlHelped> */}

            <FormControlHelped fullWidth help={tT('test_edit.skipUnitType.help.1')}>
                <InputLabel id="skipUnitType-select-label">{tT('test_edit.skipUnitType.1')}</InputLabel>
                <Select
                    labelId="skipUnitType-select-label"
                    id="skipUnitType-select"
                    value={unit.skipUnitType}
                    onChange={changeSelect('skipUnitType')}
                >
                    {skipTypes.map(item => <MenuItem value={item.value}>{item.title}</MenuItem>)}
                </Select>
            </FormControlHelped>


            <div>
                <FormControlLabelHelped
                    control={
                        <Checkbox
                            checked={unit.randomizeMarkedQuestions}
                            onChange={changeCheck('randomizeMarkedQuestions')}
                            name="randomizeMarkedQuestions"
                            color="primary"
                        />
                    }
                    label={tT('test_edit.randomizeMarkedQuestions.1')}
                    help={tT('test_edit.randomizeMarkedQuestions.help.1')}
                />
            </div>
        </Paper>
        <Sortable onSortEnd={onSortEnd} useDragHandle>
            {unit.questions.map((qid: string, i: number) =>
                <SortableItem key={qid} index={i} qid={qid} uid={unit.id} deleteQuestion={deleteQuestion} number={i + 1} />
            )}
        </Sortable>
        <Paper elevation={0} square>
            <FormControl >
                <Button variant={'contained'} color={'primary'} onClick={addQuestion} size={'medium'} disableElevation>{tT('test_edit.add.1')}</Button>
            </FormControl>
        </Paper>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
