import Component, { Presenter } from './component';
import {
    ITaskUnitOwnProps,
    ITaskUnitProps,
    cnClassName
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = ITaskUnitOwnProps;
export type IComponentPresenterProps = ITaskUnitProps;


export default Component