import { styled } from "muicomponents/src/mui/system";
import { MenuItem } from 'muicomponents/src/Menu';
import { MenuTextCrop } from "blocks/HeaderMui/UserMenu/UserMenu.styled";

export const ListItemBox = styled(MenuItem)({
  padding: '12px !important',
  width: '100%',
  justifyContent: 'space-between !important',
  alignItems: 'center !important',
  columnGap: 10,
  borderBottom: '1px solid #c4c4c4 !important',
  fontSize: 14,
  whiteSpace: 'normal',
  "&:last-of-type": {
    border: 'none !important'
  }
}) as typeof MenuItem;

export const TextCrop = styled(MenuTextCrop)() as typeof MenuTextCrop;