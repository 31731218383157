import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import { loadCoursesStatus } from '../../redux/actions/LMS'
import { getStudentCoursesStatus } from '../../redux/sagas/LMS/selectors'

///////////

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace LMS {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace CourseStatusesWidget {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            userid?: string
        }

        export interface IStateProps {
            stat: any;
            statMondatory: any;
        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}

////////////

type IOwnProps = LMS.CourseStatusesWidget.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    statMondatory: getStudentCoursesStatus(true, props.userid)(state),
    stat: getStudentCoursesStatus(false, props.userid)(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadCoursesStatus
}, dispatch);

////////////

export const cnClassName = cn('CourseStatusesWidget');