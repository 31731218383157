import React from 'react';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { cnWidget, IWidgetProps } from '../Widget.index';
import { widgets } from "i.widgets";
import { SliderLink } from '../../SliderLink/SliderLink';
import { SliderLink as SliderLinkMui } from '../../SliderLinkMui/SliderLink';
import { changeSlide, incrementSlideItem, sliderInit } from 'redux/actions/Slider'
import { store } from 'redux/store';
import { ISliderLinkItem } from 'blocks/WSettings/structures/sliderlink/uischema';
import { ISliderItemData } from 'utils/src/requests/models/api.slider';

const type = 'sliderlink';

export default class WidgetTypeSliderLink extends WidgetPresenter<IWidgetProps> {

  public componentDidMount() {
    super.componentDidMount();
    this.setSliderData();
  }

  public componentDidUpdate(pp: IWidgetProps) {
    if(!utils.compareData(this.props.widget.settings, pp.widget.settings)) this.setSliderData();
  }

  public setSliderData = () => {
    const { elements = [] } = (this.props.widget.settings && this.props.widget.settings.slider) || {};
    store.dispatch(sliderInit({ id: this.props.widget.id, imageNum: 0, data: ((elements as ISliderLinkItem[]).length && (elements as ISliderLinkItem[])) || (this.props.widget.data as ISliderLinkItem[]) }));
  }

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    return (
      <SliderLinkMui {...this.props} id={this.props.widget.id} className={cnWidget({ type, subtype })} tag={widgets.components.common} />
      // <SliderLink {...this.props} id={this.props.widget.id} context={this.props.wcontext} className={cnWidget({ type, subtype })} tag={widgets.components.common} />
    );
  }
}