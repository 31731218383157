import Box from '@mui/material/Box'
import { styled } from '@mui/system';
import { Avatar } from 'muicomponents/src/Avatar/Avatar';

 export const AvatarCustom = styled(Avatar)({
    width: 160, 
    height: 160, 
    marginRight: "16px"
});

// const SearchBox = styled(Box)<{ largeMarginBottom?: boolean }>(({ largeMarginBottom }) => ({
//     display: 'flex',
//     marginBottom: largeMarginBottom ? '21px' : '8px',
// }));
export const SearchBox = styled(Box) ({
    display: "flex", 
    alignItems: "center", 
    marginBottom: "24px"
});
export const InfoBox = styled(Box) ({
    display: "flex", 
    alignItems: "center", 
    marginBottom: "24px" 
});
