import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { NewsContextProps } from 'News/contexts/news'
import { IBaseNews } from 'News/types/baseNews'
import {
    getNewsParamsById, getNewsBannersStateByKey
} from 'News/redux/saga/selectors'
export interface IBanner {
    id: string
    title: string | null
    text: string | null
    badgeImgUrl: string | null;
    imgUrl: string
    buttonText: string
    action: string
    url: string | null
    type: 'group' | 'news' | 'banner'
    subtitle: string | null
    objectId: string
    actionData: null
    participants: any[] | null
    participantsCount: number
    user?: any | null
}

export interface IBannerNews extends IBaseNews {
    banners: IBanner[]
}

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsBanner {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends NewsContextProps<IBannerNews> {
            count?: number; // количество элементов в строке
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.NewsBanner.IOwnProps

const newsParams = [
    'id',
    'banners',
] as const

export const mapStateToProps = (state: any, { newsId }: IOwnProps) => ({
    ...getNewsParamsById<IBannerNews, typeof newsParams[number]>(newsId, newsParams)(state),
    closed: getNewsBannersStateByKey('closed')(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    
}, dispatch);

////////////

export const cnClassName = cn('NewsBanner');