/**
 * @packageDocumentation
 * @module List_type_group_invite_selector
 */
import * as React from 'react';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from 'redux/connector';

import { mapStateToProps, IListStateProps, IListProps, mapActionsToProps } from './../../List.index';

import './List_type_users.scss';
import { Header } from 'blocks/List/Header/List-Header';
import { ListLite } from 'uielements/src';
import i18n from 'localizations/i18n';
import { Item } from 'blocks/List/Item/List-Item';
import { ListUsersPresenter } from './List_type_users';
import { toast } from 'react-toastify';
// import { InviteUploadDialog } from 'blocks/Dialogs/InviteUpload/InviteUpload';
// import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { IStateType as IState } from 'redux/store';
import { Translate } from 'localizations/Translate';

export class ListUsersSelectorPresenter extends ListUsersPresenter {
  public prepareData(el: any) {
    super.prepareData(el);
    el.checked = false;
    // const notNoopAction = (a: string) => a !== '-';
    el.actions_data = {
      
      options: [
        {
          title: <Translate i18nKey={'pryaniky.list.users.actions.invite'} />,
          buttonProps: {
            id: 'USERACTIONBUTTON',
            title: 'inviteUserToGroup',
            // confirmText: ,
            onClick: () => {
              const onConfirm = () => utils.API.groups.inviteUsers(this.props.requestOptions.excludeGroup, [el.id]).r.then((d: any) => {
                if (utils.checkResponseStatus(d)) {
                  this.props.removeDataFromList({ id: this.listId, data: [el.id] })
                  toast.success(<Translate i18nKey={'pryaniky.list.users.actions.invitetogroup.success'} />)
                }
                else toast.error('error')
              })
              utilsProj.confirm({
                text: i18n.t('pryaniky.list.groups.users.confirm.invite', { count: 1 }),
                onConfirm,
              });
            },
          }
        }
      ]
    };
  }

  public itemAction = (action: string, uId: string) => {
    const onConfirm = () => {
      utils.API.users.action(action, [uId]).r.then((d: any) => {
        if (!this.mounted) return;
        if (!d) toast.error(<Translate i18nKey={'pryaniky.toast.error.server'} />);
        else if (d.error_code) toast.error(d.error_text);
        else {
          toast.success(<Translate i18nKey={`pryaniky.list.users.actions.${action}Success`} count={1} />);
          if (action === 'delete') this.props.removeDataFromList({id: this.listId, data: [uId]})
        }
      });
    };
    utilsProj.confirm({
      text: <Translate i18nKey={`pryaniky.list.users.confirm.${action}`} count={1} />,
      onConfirm,
    });
  };

  public batchAction = (action: 'delete' | 'invite', ids: string[]) => {
    utilsProj.confirm({
      text: i18n.t('pryaniky.list.groups.users.confirm.' + action, { count: ids.length }),
      onConfirm: () => {
        utils.API.groups.inviteUsers(this.props.requestOptions.excludeGroup, ids).r.then((d: any) => {
          if (utils.checkResponseStatus(d)) {
            this.props.removeDataFromList({ id: this.listId, data: ids })
            toast.success(i18n.t('pryaniky.list.users.actions.multi.invitetogroup.success'))
          }
          else toast.error('error')
        })
      },
    });
  };

  public renderChildren = () => {
    // const { isFinished, isLoading, selected } = this.state;
    const { data, isFinished, isLoading, selected } = this.props;
    // const data: any[] = this.state[this.dataVariable];
    const selectedIds: string[] = selected.map(item => item);
    return (
      <React.Fragment>
        
        <Header
          className={'rounded-top'}
          type={'common'}
          search={{
            placeholder: Translate.t({i18nKey: 'pryaniky.list.users.search'}),
            onChange: this.search,
          }}
          /*actions={{
            options: [
              {
                title: 'Загрузить',//i18n.t('pryaniky.list.users.actions.invite'),
                buttonProps: {
                  onClick: () => this.setState({ showDialog: true }),
                },
              },
            ],
          }}*/
          counter={{
            all: {
              title: <Translate i18nKey={'pryaniky.list.users.title'} />,
              count: data.length,
              action: () => this.props.checkAllListItem({ id: this.listId, data: null }),
            },
            selected: {
              title: <Translate i18nKey={'pryaniky.list.selected'} values={{ count: selected.length }} />,
              count: selected.length,
              action: {
                options: [
                  {
                    title: <Translate i18nKey={'pryaniky.list.users.actions.invite'} />,
                    buttonProps: {
                      onClick: this.batchAction.bind(this, 'invite', selectedIds),
                      main: true,
                    },
                  },
                ],
              },
            },
          }}
        />
        <ListLite className={'rounded-bottom'} isFinished={isFinished} isLoading={isLoading} loadMore={this.getData}>
          {data.map(el => (
            <Item
              lId={this.listId}
              iId={el}
              type={'common'}
              key={el}
              data_key={el}
            />
          ))}
        </ListLite>
      </React.Fragment>
    );
  };

  protected closeDialog = () => this.setState({ showDialog: false });
}

export const List = connect<IListStateProps, IDispatchProps, IListProps, IState>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
)(ListUsersSelectorPresenter);
