import React, { FC, useState, useEffect } from 'react';
import { IOrdersListProps } from './OrdersList.index';
import { ListHeader } from 'muicomponents/src/ListHeader/ListHeader'
import { OrderListItem } from 'muicomponents/src/OrderListItem/OrderListItem'
import { InfinityList } from 'muicomponents/src/InfinityList/InfinityList'
import { useScrollListState, useDebouncedQueryState } from 'utils/src/hooks'
import { getOrders } from 'utils/src/requests/requests.shop'
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { i18n } from "localization";
import { useLazyQueryEx } from 'utils/src/hooks'
import { orderCancel } from 'utils/src/requests/requests.shop'

async function getOrdersPromise(opt: any) {
  const response = await getOrders(opt)
  return await response.r
}
async function orderCancelPromise(id: number) {
  const response = await orderCancel(id)
  return await response.r
}
const currencyNameSelector = (state: any) => {
  return {
    currencyNameNominativeSingular: state.store.appSettings.currencyNameNominativeSingular,
    currencyNameGenitivePlural: state.store.appSettings.currencyNameGenitivePlural,
    currencyNameGenitiveSingular: state.store.appSettings.currencyNameGenitiveSingular,
  }
}
const method = (search?: string) => async function (skipCount: number, count: number) {
  return (await getOrdersPromise({ skipCount, count, search })).data
}


export const OrdersList: FC<IOrdersListProps> = ({ }) => {
  const [search, searchQuery, setSearch] = useDebouncedQueryState('search')

  const {
    isFinished,
    isLoading,
    loadMore,
    triggerRef,
    values,
    error: {
      withError
    },
    actions: {
      updateItem
    }
  } = useScrollListState(method(searchQuery as string), [searchQuery])

  const { send, isLoading: idCanceling } = useLazyQueryEx((id: number) => orderCancelPromise(id))

  /**
   * @todo добавить сообщение в случае ошибки
   * @param item 
   */
  async function onCancle(item: typeof values[number]) {
    try {
      await send(item.id)
      updateItem(item, {
        ...item,
        status: 'Cancelled'
      })
    } catch (e) {
      console.error(e)
    }
  }

  const currencyNames = useSelector(currencyNameSelector)

  return <Box>
    <ListHeader searchValue={search} onChangeSearch={(e) => { setSearch(e.target.value) }} />

    <Paper
      component="div"
      sx={{ marginTop: '16px', padding: '8px 0px' }}
    >
      <InfinityList
        isError={withError}
        triggerRef={triggerRef}
        isFinished={isFinished}
        isLoading={isLoading}
        itemsCount={values.length}
        titleEmpty={i18n.t('pryaniky.OrdersList.titleEmpty')}
        textEmpty={(searchQuery && searchQuery !== '') ? i18n.t('pryaniky.OrdersList.textEmpty', { query: searchQuery }) : undefined}
        loadMore={loadMore}>
        {values.map((item) => <>
          <OrderListItem
            key={item.id}
            value={item as any}
            currencyNames={currencyNames}
            cancleProcessing={idCanceling}
            cancleOrder={onCancle}
          />
          <Divider variant="inset" sx={{ marginLeft: '0px' }} />
        </>)}
      </InfinityList>
    </Paper>
  </Box>
}
