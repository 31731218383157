import { defaultNews } from 'News/redux/reducer/default'
import { TReducerWithEvent } from './reducer.d'
// import { TNewsReducerActions } from 'News/redux/actions/types'
import { TNewsReducerActions } from './actions.d'
import actions from './actionsType'

const eventDefaultNews: TReducerWithEvent = {
    ...defaultNews,
    meeting: undefined
}

export const eventsSubReducer = (state = eventDefaultNews, action: TNewsReducerActions) => {
    switch (action.type) {
        case actions.SET_MEETING: {
            const { meeting } = action.payload
            return {
                ...state,
                meeting
            }
        }
        default:
            return state;
    }
}