import React, { FC, memo, useState, useCallback, useMemo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext, NewsContextProps } from '../../../../contexts/news'
// import { Actions } from 'blocks/NewsView/Actions/Actions';
// import { Like } from 'blocks/NewsView/Actions/Like/Like';
// import { Thank } from 'blocks/NewsView/Actions/Thank/Thank';
// import likersModal from 'blocks/Dialogs/News/Likers/NewsLikers';
import { Button, Icon } from 'uielements/src'
// import { Bable } from 'uielements/lib/Bable/Bable'
import ActionButton from '../../ActionButton'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import i18n from 'localizations/i18n';

type IPresenterProps = News.NewsActions.IPresenterProps
type IOwnProps = News.NewsActions.IOwnProps
type IDispatchProps = News.NewsActions.IDispatchProps
type IStateProps = News.NewsActions.IStateProps
type IState = News.NewsActions.IState

const isLikedClass = (liked: boolean) => liked ? 'heart-fill' : 'heart';
const noopObj: any = {}

export const Presenter: FC<IPresenterProps> = ({
    className,
    id,
    toggleThank,
    action,
    allowReply,
    allowThanksReply,
    enabledThanks
}) => {
    let allowThank = allowThanksReply;
    allowThank = !allowReply ? false : allowThank

    const onThank = useCallback(() => toggleThank({ id, value: true }), [])

    return <ActionButton className={cnClassName('Like', [className])} action={action}>
        <Button disabled={(!enabledThanks && allowThank)} theme='unstyled' onClick={onThank}>
            {window.PARAMS_change_news_give_icon ? <div className={cnClassName('customIcon', ['give'])} /> : <Icon icon='give' />}
            {i18n.t('give_currency')}
        </Button>
    </ActionButton>
}
export const Connected = connect<IStateProps, IDispatchProps, IOwnProps & NewsContextProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter))

export const Component = withNewsContext(Connected, ['newsId'])
export default () => ComponentInjector.getInstance().addComponent('news_actions', Component, 'PRN_NEWS_TOGGLE_THANK_REPLY')

