import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
// import { ICourse } from '../mock';
// import { loadCategories } from "../../../redux/actions/LMS";

export interface IButtonWithLoadProps extends IClassNameProps, IButtonWithLoadStateProps, IDispatchProps, IButtonWithLoadDispatchProps {

}

export interface IButtonWithLoadStateProps {
}

export const mapStateToProps = (state: any) => ({
    isLoad: state.LMS.saveInProcessing
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export type IButtonWithLoadDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnButtonWithLoad = cn('ButtonWithLoad');