import * as React from "react";
import * as utils from "utils/src/utils";

// import { withBemMod } from '@bem-react/core';

import { cnWidget } from "../Widget.index";
import { IWidgetTypeRenderProps } from "./Widget_type_render.index";
import "./Widget_type_tabs.scss";
// import { Tabs } from 'blocks/Tabs/Tabs';
import { default as WidgetPresenter } from "../Widget";
import { widgets } from "i.widgets";

const type = "render";

export default class WidgetTypeRenderPresenter extends WidgetPresenter<IWidgetTypeRenderProps> {
  public render() {
    if (!this.props.widget) return null;
    const Component = widgets.components.common;
    return (
      <Component
        {...this.props}
        className={cnWidget({ type })}
        children={this.props.widget.data}
        context={this.props.wcontext}
      />
    );
  }
}
