import { IModalActionPayload } from "../reducers/Modals";
import { IStateType as IState } from 'redux/store';
import actions from 'redux/actionsTypes/Modals';

export const modalInit = (payload: IModalActionPayload) => ({
  type: actions.INIT,
  payload
})

export const modalKill = (payload: IModalActionPayload) => ({
  type: actions.KILL,
  payload
})

export const modalShowToggle = (payload: IModalActionPayload) => ({
  type: actions.SHOW_TOGGLE,
  payload
})

export const modalSetData = (payload: IModalActionPayload) => ({
  type: actions.SET_DATA,
  payload
})

export const modalChangeDate = (payload: IModalActionPayload) => ({
  type: actions.CHANGE_DATA,
  payload
})

export const modalChangeData = modalChangeDate;

export function getIsShown(id: string): any {
  return (dispatch: any, getState: () => IState) => {
    return getState().modals[id].isShown;
  }
}

export interface IDialogsGetIntecompanyGroupRequestsAction {
  type: typeof actions.GET_INTERCOMPANY_GROUPS_REQUESTS;
  payload: IModalActionPayload
}

export function dialogsGetIntecompanyGroupRequests(payload: IModalActionPayload): IModalsActions {
  return { type: actions.GET_INTERCOMPANY_GROUPS_REQUESTS, payload }
}

export interface IDialogsConfirmIntercompanyGroupsRequestsActionPayload extends IModalActionPayload {
  groupId: string;
  comment: string;
}
export interface IDialogsConfirmIntercompanyGroupsRequestsAction {
  type: typeof actions.CONFIRM_INTERCOMPANY_GROUPS_REQUESTS;
  payload: IDialogsConfirmIntercompanyGroupsRequestsActionPayload
}

export function confirmIntercompanyGroupRequest(payload: IDialogsConfirmIntercompanyGroupsRequestsActionPayload): IModalsActions {
  return { type: actions.CONFIRM_INTERCOMPANY_GROUPS_REQUESTS, payload }
}

export interface IDialogsDeclineIntercompanyGroupsRequestsActionPayload extends IModalActionPayload {
  groupId: string;
  comment: string;
}
export interface IDialogsDeclineIntercompanyGroupsRequestsAction {
  type: typeof actions.DECLINE_INTERCOMPANY_GROUPS_REQUESTS;
  payload: IDialogsDeclineIntercompanyGroupsRequestsActionPayload
}

export function declineIntercompanyGroupRequest(payload: IDialogsDeclineIntercompanyGroupsRequestsActionPayload): IModalsActions {
  return { type: actions.DECLINE_INTERCOMPANY_GROUPS_REQUESTS, payload }
}

export type IModalsActions = IDialogsGetIntecompanyGroupRequestsAction | IDialogsConfirmIntercompanyGroupsRequestsAction | IDialogsDeclineIntercompanyGroupsRequestsAction;