import React from 'react'
import { EditorState } from 'draft-js';
import createDecorator from './createDecorator';
import { createPortal } from 'react-dom'
import { Dialog, Button } from 'uielements/src'
import './DialogStyle.scss'
const createSetData = (
    contentBlock: any,
    { getEditorState, setEditorState }: any
) => (data: any) => {
    const entityKey = contentBlock.getEntityAt(0);
    if (entityKey) {
        const editorState = getEditorState();
        const contentState = editorState.getCurrentContent();
        contentState.mergeEntityData(entityKey, { ...data });
        setEditorState(
            EditorState.forceSelection(editorState, editorState.getSelection())
        );
    }
};

export default (config: any) => {
    const store: any = {
        getEditorRef: undefined,
        getReadOnly: undefined,
        getEditorState: undefined,
        setEditorState: undefined,
        visible: false,
        rect: undefined,
        positions: undefined,
        elem: undefined,
        setLink: undefined,
        currentIndex: 0,
        data: { link: '', alt: '', src: '' },
        link: { resizeData: { images: [] } },
        blockProps: undefined
    };

    const DecoratedImgAltTool = (props: any) => {
        store.data.link = store.link.resizeData.images[store.currentIndex] ? store.link.resizeData.images[store.currentIndex].link : ''
        store.data.alt = store.link.resizeData.images[store.currentIndex] ? store.link.resizeData.images[store.currentIndex].alt : ''
        store.data.src = store.link.resizeData.images[store.currentIndex] ? store.link.resizeData.images[store.currentIndex].src : ''
        const [linkValue, setLink] = React.useState(store.data.link)
        const [altValue, setAlt] = React.useState(store.data.alt)
        React.useEffect(() => {
            if (store.link.resizeData.images[store.currentIndex]) {
                setLink(store.link.resizeData.images[store.currentIndex].link)
                setAlt(store.link.resizeData.images[store.currentIndex].alt)
            }
        }, [store.data.src, store.currentIndex])

        if (!store.link.resizeData) return null;

        const onChangeLink = (e: any) => {
            setLink(e.target.value)
            e.preventDefault(); e.stopPropagation()
        }
        const onChangeAlt = (e: any) => {
            setAlt(e.target.value)
            e.preventDefault(); e.stopPropagation()
        }
        const saveValues = () => {
            const newImages = [...store.link.resizeData.images]
            newImages[store.currentIndex].link = linkValue
            newImages[store.currentIndex].alt = altValue
            store.link.setLink && store.link.setLink({ ...store.link, resizeData: { ...store.link.resizeData, images: newImages } })
        }
        //return store.visible && store.elem ? createPortal(
        return store.visible ? <div
            onClick={(ev: any) => { store.visible = false; store.link.setFocusToBlock() }}
            className={'ImgData'}>
            <div
                className={'ImgDataLayout'}>
                <div className={'ImgData-Item'}>
                    <div>Ссылка</div>
                    <input
                        value={linkValue}
                        onChange={onChangeLink}
                        onClick={(ev: any) => { ev.preventDefault(); ev.stopPropagation() }} />
                </div>
                <div className={'ImgData-Item'}>
                    <div>Подсказка</div>
                    <input
                        value={altValue}
                        onChange={onChangeAlt}
                        onClick={(ev: any) => { ev.preventDefault(); ev.stopPropagation() }} />
                </div>
                <div className={'ImgData-Item'}>
                    <Button main onClick={saveValues}>Сохранить</Button>
                </div>
                
            </div>
        </div> : <div></div>
    }
    return {
        initialize: ({
            getEditorRef,
            getReadOnly,
            getEditorState,
            setEditorState,
        }: any) => {
            store.getReadOnly = getReadOnly;
            store.getEditorRef = getEditorRef;
            store.getEditorState = getEditorState;
            store.setEditorState = setEditorState;
        },
        decorator: createDecorator({ config, store }),
        blockRendererFn: (contentBlock: any, { getEditorState, setEditorState }: any) => {
            const entityKey = contentBlock.getEntityAt(0);
            const contentState = getEditorState().getCurrentContent();
            const data = entityKey
                ? contentState.getEntity(entityKey).data
                : {};
            store.setLink = createSetData(contentBlock, {
                getEditorState,
                setEditorState,
            })
            store.link = {
                ...(store.link ? store.link : {}),
                ...data
            }

            return {
                props: {
                    alt: data.alt || '',
                    link: data.link || '',
                    setLink: store.setLink,
                },
            };
        },
        ImgAltTool: DecoratedImgAltTool
    };
};