import React from 'react';
import { Skeleton } from 'uielements/src';
import { cn } from '@bem-react/classname';
import './Orgchart-Skeleton.scss';
import { IClassNameProps } from '@bem-react/core';

const cnOrgchartSkeleton = cn('OrgchartSkeleton');

export const OrgchartSkeleton: React.FC<IClassNameProps> = ({ className }) => (
    <div className={cnOrgchartSkeleton({}, [className])}>
        <div className={cnOrgchartSkeleton('Image')} >
            <div className={cnOrgchartSkeleton('MainUnit')}>
                <Skeleton shape="line" size="lg" />
                <Skeleton shape="line" size="xs" style={{ width: '80%' }} />
                <Skeleton shape="line" size="xs" style={{ width: '60%' }} />
                <Skeleton shape="line" size="xs" style={{ width: '40%' }} />

            </div>
            <div className={cnOrgchartSkeleton('ChildrenUnits')}>
                <div className={cnOrgchartSkeleton('Unit1')}>
                    <Skeleton shape="line" size="lg" />
                    <Skeleton shape="line" size="xs" style={{ width: '80%' }} />
                    <Skeleton shape="line" size="xs" style={{ width: '60%' }} />
                    <Skeleton shape="line" size="xs" style={{ width: '40%' }} />
                </div>
                <div className={cnOrgchartSkeleton('Unit2')}>
                    <Skeleton shape="line" size="lg" />
                    <Skeleton shape="line" size="xs" style={{ width: '80%' }} />
                    <Skeleton shape="line" size="xs" style={{ width: '60%' }} />
                    <Skeleton shape="line" size="xs" style={{ width: '40%' }} />
                </div>

            </div>
            <div className={cnOrgchartSkeleton('ChildrenUnits2')}>
                <div className={cnOrgchartSkeleton('ChildrenUnits2-Unit1')}>
                    <Skeleton shape="line" size="lg" />
                    <Skeleton shape="line" size="xs" style={{ width: '80%' }} />
                    <Skeleton shape="line" size="xs" style={{ width: '60%' }} />
                    <Skeleton shape="line" size="xs" style={{ width: '40%' }} />
                </div>
                <div className={cnOrgchartSkeleton('ChildrenUnits2-Unit2')}>
                    <Skeleton shape="line" size="lg" />
                    <Skeleton shape="line" size="xs" style={{ width: '80%' }} />
                    <Skeleton shape="line" size="xs" style={{ width: '60%' }} />
                    <Skeleton shape="line" size="xs" style={{ width: '40%' }} />
                </div>

            </div>
        </div>

    </div>
);