import React, { FC } from 'react'
import { IShowMoreEventsDialogProps, cnCalendarModalEvents } from './ShowMoreEventsDialog.index'
import './ShowMoreEventsDialog.scss'
import i18n from 'localizations/i18n'
import { Translate } from 'localizations/Translate'
import { EventItem } from '../EventItem/EventItem'
import { createModal, IBodyProps } from 'utils/src/DialogCreator'
import { DialogBody } from 'muicomponents/src/DialogParts/DialogBody/DialogBody'


const EventsDialog: FC<{
  events: any
  date: Date
} & IBodyProps<{}>> = ({ handleClose, date, events }) => {
  const sortEvents = events.sort((a: any, b: any) => new Date(a.start) > new Date(b.start) ? 1 : -1)
  return <DialogBody
    header={date.toLocaleString('ru', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })}
    closeText={<Translate i18nKey={'pryaniky.calendar.modal.close'} />}
    onClose={handleClose}
  >
    {sortEvents.map((item: any, i: any) => {
      return <EventItem id={item.id} key={item.id} />
    })}
  </DialogBody>
}

export const openEventsDialog = createModal(EventsDialog)