import React, { ReactNode } from 'react';
import { ListItemIcon } from 'muicomponents/src/ListItem/ListItem';
import { MenuItem, MenuItemFlex } from '../UserMenu.styled';
import { Check } from 'muicomponents/src/Icons';
import { Box, styled } from 'muicomponents/src';

type TUserStatusItemProps = {
  title: string | ReactNode,
  index: number,
  setActive: React.Dispatch<React.SetStateAction<number>>,
  active: number
}

type ICircleBoxProps = {
  colorStatus: string
}

const CircleBox = styled(Box)<ICircleBoxProps>(({theme, colorStatus}) => ({
  width: 20,
  height: 20,
  borderRadius: '50%',
  backgroundColor: colorStatus
}))


export const UserStatusItem: React.FC<TUserStatusItemProps> = ({title, index, active, setActive}) => {

  const handleClick = () => {
    setActive(index);
  }
  
  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <CircleBox 
          colorStatus={
            title === 'В сети' ? '#4CAF50' : 
            title === 'Не беспокоить' ? '#FFB400' : 
            title === 'Отсутствую' ? '#F44336' : ''
          }/>
      </ListItemIcon>
      <MenuItemFlex>
        {title}
        {
          active === index && <Check /> 
        }
      </MenuItemFlex>
    </MenuItem>
  )
}
