import React, { FC } from 'react'
import { Skeleton } from 'uielements/src'
import './Lesson.scss'
import { cn } from '@bem-react/classname';
export const cnLesson = cn('SkeletLesson');

export const Lesson: FC<any> = ({ className }) => {
    return <div className={cnLesson({}, [className])}>
        <Skeleton shape="line" size="lg" style={{ width: '60%' }} />

        <Skeleton shape="line" size={"sm"} style={{ width: '40%' }} />
        <Skeleton shape="line" size={"sm"} style={{ width: '80%' }} />
        <Skeleton shape="line" size={"sm"} style={{ width: '70%' }} />
        <Skeleton shape="line" size={"sm"} style={{ width: '90%' }} />
        <Skeleton shape="line" size={"sm"} style={{ width: '95%' }} />
        <Skeleton shape="line" size={"sm"} style={{ width: '70%' }} />
        <Skeleton shape="line" size={"sm"} style={{ width: '85%' }} />
        <Skeleton shape="line" size={"sm"} style={{ width: '90%' }} />
        <Skeleton shape="line" size={"sm"} style={{ width: '85%' }} />
    </div>
}

