import { IBodyProps } from '../Modal-Body.index';

import { IDispatchProps } from 'redux/connector';
import { cn } from '@bem-react/classname';
import { IWidget } from 'i.widgets';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
// import { IWidget } from 'i.widgets';

export interface IBodyTypeAddWidgetProps extends IBodyProps, IBodyStateProps, IDispatchProps {
  tag?: 'div';
}

export interface IWidgetToSelect {
  checked?: boolean;
  schema: string;
  title: string;
  type: string;
  id: string;
}

export interface IBodyTypeAddWidgetState {
  text?: string;
  w: IWidgetToSelect[];
  selected?: IWidgetToSelect;
  type?: string;
}

export type IBodyStateProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: any) => ({
  authUser: getAuthUser(state),
})

export const cnWidget = cn('Widget');