import React, { FC, useEffect, useMemo, useState, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { NewsContext, NewsContextProps } from '../../contexts/news'
import { withComponentEnjector, useInjector } from 'utils/src/ComponentInjector'
import ErrorBoundary from 'utils/src/ErrorBoundary'
import { useCurrentUserBase } from 'utils/src/CommonRedux/users/hooks'
type IPresenterProps = News.NewsItem.IPresenterProps
type IOwnProps = News.NewsItem.IOwnProps
type IDispatchProps = News.NewsItem.IDispatchProps
type IStateProps = News.NewsItem.IStateProps
type IState = News.NewsItem.IState


export const Presenter: FC<IPresenterProps> = ({
    className,
    id,
    // getNode,
    edit,
    toggleEdit,
    // advancedNodeKey,
    ViewComponent,
    children,
    newstype,
    componentRenderName,
    single = false,
    virtualize = false,
    repost = false,
    additional,
    nodeKeys = ['news_types'],
    additionalFields
}) => {
    const { isAdmin } = useCurrentUserBase()
    useEffect(() => {

    }, [])

    const { getNodeById } = useInjector(nodeKeys)

    const context = useMemo<NewsContextProps>(() => {
        return {
            isSingle: single,
            isRepost: repost,
            toggleEdit: (enable: boolean) => toggleEdit({ id, enable }),
            edit,
            ViewComponent,
            virtualize,
            newsId: id,
            viewClassName: className,
            additional,
            disableEdit: isAdmin || (additionalFields?.currentStatus?.allowEditByAuthor || false)
        }
    }, [edit, ViewComponent, single, className, id])

    return <ErrorBoundary>
        <NewsContext.Provider value={context}>
            {getNodeById(`${componentRenderName}`)}
        </NewsContext.Provider>
    </ErrorBoundary>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withComponentEnjector<IPresenterProps>(memo(Presenter), ['news_types']));
