import { useDispatch, useSelector } from "react-redux";
import { newsCreatorItemNewsChange } from "../../redux/actions.newsCreator";
import { getNewsCreateItemFields, getNewsCreateItemNewsFields } from "../../redux/selectors.newsCreate";
import { NewsCreateReducer } from "../../redux/types.newsCreator";
import { NewsCreateContext } from "News/creator/Creator/Creator.constants";
import { useContext } from "react";
import { NNotices } from "./Notices.interface";
import { getAllowPostTypeById } from "redux/sagas/AppSettings/selectors";

export const useNoticesEdit = (id: keyof NewsCreateReducer) => {

    const dispatch = useDispatch();
    
    const { groupId, workflowId } = useContext(NewsCreateContext);

    const data = useSelector(getNewsCreateItemNewsFields(['text', 'header', 'draftRawState'])(id));

    const creatorFields = useSelector(getNewsCreateItemFields(['errors', 'clearEditor', 'selectedNews'])(id));

    const renderParams = useSelector(getAllowPostTypeById(creatorFields.selectedNews || '', groupId, workflowId))?.renderParams as NNotices.RenderParams | undefined;

    const changeNews = (news: Partial<typeof data>) => {
        dispatch(newsCreatorItemNewsChange({ id, news }));
    };

    return {
        data,
        renderParams,
        ...creatorFields,
        changeNews
    };
};