import { initNews } from 'blocks/NewsTypes/News/News'
import { initCommentsOnly } from 'blocks/NewsTypes/CommentsOnly/News'
import { initBadges } from 'blocks/NewsTypes/Badges/Badges'
import { initNotices } from 'blocks/NewsTypes/Notices/Notices'
import { initCreativetasks } from 'blocks/NewsTypes/Creativetasks/Creativetasks'
import { initThanks } from 'blocks/NewsTypes/Thanks/Thanks'
import { initCreativetasksend } from 'blocks/NewsTypes/Creativetasksend/Creativetasksend'
import { initAchievements } from 'blocks/NewsTypes/Achievements/Achievements'
import { initEvents } from 'blocks/NewsTypes/Events/Events'
import { initPolls } from 'blocks/NewsTypes/Polls/Polls'
import { initIdeas } from 'blocks/NewsTypes/Ideas/Ideas'
import { initWelcomes } from 'blocks/NewsTypes/Welcomes/Welcomes'
import { initIdeasend } from 'blocks/NewsTypes/Ideasend/Ideasend'
import { initTasks } from 'blocks/NewsTypes/Tasks/Tasks'
import { initRemoved } from 'blocks/NewsTypes/Removed/Removed'
import { initRepost } from 'blocks/NewsTypes/Repost/Repost'
import { initLongread } from 'blocks/NewsTypes/Longread/Longread'
import { initWorkflows } from 'blocks/NewsTypes/Workflows/Workflows'
import initLongreadCreator from 'blocks/NewsTypes/Longread/_Creator'
import initNewsCreator from 'blocks/NewsTypes/News/Creator'
import initThanksCreator from 'blocks/NewsTypes/Thanks/Creator'
import initEventsCreator from 'blocks/NewsTypes/Events/Creator'
import initBanner from 'blocks/NewsTypes/Banner'
import { initPagesV2 } from 'blocks/NewsTypes/LMSLearningTrack/PagesV2/PagesV2'
import { initNews as initLMSNews } from 'blocks/NewsTypes/LMSLearningTrack/News/News'
import { initQuizzes } from 'blocks/NewsTypes/LMSLearningTrack/Quizzes/Quizzes'
import { initLMSCources } from 'blocks/NewsTypes/LMSLearningTrack/LMSCources/LMSCources'
import initWorkflowsCreator from 'blocks/NewsTypes/Workflows/_Creator'
import { initQuestStep } from 'blocks/NewsTypes/QuestStep/QuestStep'

import { getLifeCycol } from 'utils/src/CommonRedux/LifeCycol/selectors'

import initActionLike from '../containers/NewsActions/BaseActions/ActionLike'
import initActionThank from '../containers/NewsActions/BaseActions/ActionThank'


// mui initers start
// import { initNewsThanksCreator } from '../types/Thanks';
// import { initNewsNewsCreator } from '../types/News';
// import { initNewsAchievementsCreator } from '../types/Achievements';
// import { initNewsBadgesCreator } from '../types/Badges';
// import { initNewsNoticesCreator } from '../types/Notices';
// import { initNewsPollsCreator } from '../types/Polls';
// import { initNewsCreativeTasksCreator } from '../types/CreativeTasks';
// import { initNewsEventsCreator } from '../types/Events';
// import { initNewsIdeasCreator } from '../types/Ideas';
// import { initNewsWorkflowsCreator } from '../types/Workflows';
// mui initers end

// mui hooks initers start
import { initNewsThanksCreator } from '../creator/types/Thanks';
import { initNewsNewsCreator } from '../creator/types/News';
import { initNewsAchievementsCreator } from '../creator/types/Achievements';
import { initNewsAchievementsForCreator } from '../creator/types/AchievementsFor';
import { initNewsBadgesCreator } from '../creator/types/Badges';
import { initNewsNoticesCreator } from '../creator/types/Notices';
import { initNewsPollsCreator } from '../creator/types/Polls';
import { initNewsCreativeTasksCreator } from '../creator/types/CreativeTasks';
import { initNewsEventsCreator } from '../creator/types/Events';
import { initNewsIdeasCreator } from '../creator/types/Ideas';
import { initNewsWorkflowsCreator } from '../creator/types/Workflows';
// mui hooks initers end


export default (store: any) => {
    let unsubscribe = () => { }
    unsubscribe = store.subscribe(() => {
        const INIT_COMPLETED = getLifeCycol(store.getState()).INIT_COMPLETED
        if (INIT_COMPLETED) {
            unsubscribe()

            initNews()
            initBadges()
            initNotices()
            initCreativetasks()
            initThanks()
            initCreativetasksend()
            initAchievements()
            initEvents()
            initPolls()
            initIdeas()
            initWelcomes()
            initIdeasend()
            initTasks()
            initRemoved()
            initRepost()
            initLongread()
            initCommentsOnly()
            initWorkflows()
            initPagesV2()
            initLMSNews()
            initQuizzes()
            initLMSCources()
            initQuestStep()

            initLongreadCreator()
            initNewsCreator()
            initThanksCreator()
            initEventsCreator()
            initWorkflowsCreator()

            initBanner()

            initActionLike()
            initActionThank()
            
            /**
             * init news types creators
             */
            initNewsThanksCreator()
            initNewsNewsCreator()
            initNewsAchievementsCreator()
            initNewsBadgesCreator()
            initNewsNoticesCreator()
            initNewsPollsCreator()
            initNewsCreativeTasksCreator()
            initNewsEventsCreator()
            initNewsIdeasCreator()
            initNewsWorkflowsCreator()

            initNewsAchievementsForCreator()
            /**
             * end init news types creators
             */
        }
    })
}


