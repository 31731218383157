import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import { mapDispatchToProps } from "../../../redux/connector";
// import i18n from '../../../localizations/i18n';
import { Slider } from 'uielements/src/Slider/Slider';
import { cnSlider, ISliderProps, ISliderState } from './CountSlider-Slider.index';

import './CountSlider-Slider.scss';

export class SliderPresenter extends React.Component<ISliderProps, ISliderState> {
  public el: HTMLElement | null;
  // public bar: HTMLElement | null;
  // public clicked: boolean;

  constructor(props: ISliderProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    // let position = ((this.props.value || 0) * 100) / (this.props.maxCount || 100);
    // if(position < 0) position = 0;
    // if(position > 100) position = 100;
    // this.state = {
      // position
    // };
    // this.clicked = false;
  }

  public render() {
    const customClassNames = '';
    const TagName = this.props.tag ? this.props.tag : 'div';
    return (
      <TagName ref={el => this.el = el} className={cnSlider({}, (this.props.className ? this.props.className.split(' ') : []).concat(customClassNames.split(' ')))}>
        <div className={cnSlider('Counts', { min: true })} children={this.props.value || 0} />
        <Slider className={cnSlider('Progress')} min={this.props.minCount} value={this.props.value} max={this.props.maxCount} valueChanged={this.props.onSliderMove} step={this.props.step} />
        {/*<div ref={(c) => {this.bar = c}} className={cnSlider('Bar')}>
          <div onMouseDown={this.mouseDown} style={{left: 'calc(' + this.state.position + '% - 10px)'}} className={cnSlider('Bar-Dot', {withStyles: !this.props.dot})} children={this.props.dot} />
    </div>*/}
        <div className={cnSlider('Counts', {max: true})} children={this.props.maxCount || 100} />
        {this.props.children}
      </TagName>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {
  //   this.mouseDown = this.mouseDown.bind(this);
  //   this.mouseUp = this.mouseUp.bind(this);
  //   this.mouseMove = this.mouseMove.bind(this);
  // }

  // private mouseDown(ev: React.MouseEvent) {
  //   ev.stopPropagation();
  //   ev.preventDefault();
  //   this.clicked = true;
    
  //   document.addEventListener('mousemove', this.mouseMove, false);
  //   document.addEventListener('mouseup', this.mouseUp, false);
  // }

  // private mouseUp(ev: any) {
  //   ev.stopPropagation();
  //   ev.preventDefault();
  //   this.clicked = false;

  //   document.removeEventListener('mousemove', this.mouseMove, false);
  //   document.addEventListener('mouseup', this.mouseUp, false);
  // }

  // private mouseMove(ev: any) {
  //   ev.stopPropagation();
  //   ev.preventDefault();

  //   if(this.bar) {
  //     // const sliderRect = this.bar.getBoundingClientRect();
  //     // let position = ev.clientX - sliderRect.left;
  //     // if(position < 0) position = 0;
  //     // if(position > sliderRect.width) position = sliderRect.width;
  //     // position = (position * 100) / sliderRect.width;
  //     // this.setState({position});
  //     // this.props.onSliderMove(position);
  //   }

  // }

}


export const SliderElem = connect(
  null,
  mapDispatchToProps(
    {}
  )
  )(SliderPresenter)
  //   compose(
  //     withBemMod(cnSlider(), {})
  //     )(
  //       SliderPresenter
  //   )
  // )
