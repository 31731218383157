import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { RouteComponentProps } from 'react-router';
import { IVisitUser } from 'blocks/VirtUserSelector/VirtUserSelector.index';
import { IUser } from 'utils/src/requests/models/api.users';

export interface IItemProps extends IClassNameProps, RouteComponentProps {
    user: IVisitUser,
    currentUser: IUser['user']
}

export const cnItem = cn('VirtUserItem');