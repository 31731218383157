import * as React from 'react';
import * as utils from 'utils/src/utils';
import { IBodyProps } from '../NewsEdit-Body.index';
import i18n from 'localizations/i18n';
import { withBemMod } from '@bem-react/core';
import { TextareaMentions } from 'blocks/TextareaMentions/TextareaMentions';
import { cnBody } from '../NewsEdit-Body.index';
import { IBodyTypeCompetitionsProps } from './NewsEdit-Body_type_competitions.index';
import { Input } from 'uielements/src/Input/Input';
import './NewsEdit-Body_type_competitions.scss';
import { EditorTypeEdit } from '../../../NewsTypes/Creativetasks/Editor/containers/edit/edit'
export const withBodyTypeCompetitions = withBemMod<IBodyTypeCompetitionsProps, IBodyProps>(
  cnBody(),
  { type: 'competitions' },
  (Presenter) => (
    (props) => {
      const newPost: any = utils.cloneObject(props.post);
      return <EditorTypeEdit data={newPost} onChange={props.onChangePost} />
      /*const endDateTime = new Date(newPost.creativeTask.endDateTime);

      const onChangeTitle = (e: any) => {
        newPost.creativeTask.title = e.target.value;
        newPost.header = e.target.value;
        props.onChangePost(newPost);
      }
      const endDateChange = (date: Date) => {
        newPost.creativeTask.endDateTime = date.toISOString();
        // newPost.expires = date.toISOString();
        props.onChangePost(newPost);
      }
      return (
        <Presenter {...props}>
          {({ text, onChangeText }) =>
            <React.Fragment>
              <Input onChange={onChangeTitle}
                className={'NewsEdit-Body_type_competitions-Input-Title'} 
                value={props.post.creativeTask.title}
                icon='flag'
                placeholder={i18n.t('placeholders.input.name')} />
              
                <Datepicker className={'NewsEdit-Body_type_competitions-ExpiresBox'} onChange={endDateChange} locale={i18n.language} value={endDateTime} />
              
              <TextareaMentions
                // getClassRef={this.getTextareaMentions} 
                users={true}
                tags={true}
                onChange={onChangeText}
                className={'NewsEdit-Body_type_competitions-Input-Text'} 
                icon={'edit'}
                value={text}
                placeholder={i18n.t('placeholders.textarea.news')} />
            </React.Fragment>
          }
        </Presenter>
      )*/
    }
  )
);