import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IBodyProps } from '../modal'
import { bindActionCreators, Dispatch, Action } from 'redux';

///////////

export type IAddMaterialsPresenterProps = IAddMaterialsOwnProps & IAddMaterialsStateProps & IAddMaterialsDispatchProps

export interface IAddMaterialsState { }

export interface IAddMaterialsOwnProps extends IClassNameProps, IBodyProps<IAddMaterialsResult> {
    trackId: string
}

export interface IAddMaterialsResult {
}

export interface IAddMaterialsStateProps {
}

export type IAddMaterialsDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////


export const mapStateToProps = (state: any, props: IAddMaterialsOwnProps) => ({

})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IAddMaterialsOwnProps) => bindActionCreators({

}, dispatch);

export const cnClassName = cn('LTAddMaterials');