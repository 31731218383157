import { IMenu, IMenuActions } from './interfaces';

export const LEFTMENU_GET_DATA = 'LEFTMENU_GET_DATA';
export const LEFTMENU_SET_DATA = 'LEFTMENU_SET_DATA';
export const LEFTMENU_RESET_DATA = 'LEFTMENU_RESET_DATA';
export const LEFTMENU_INIT_DATA = 'LEFTMENU_INIT_DATA';
export const LEFTMENU_UNSAVE_DATA = 'LEFTMENU_UNSAVE_DATA';
export const LEFTMENU_UPDATE_STRUCTURE = 'LEFTMENU_UPDATE_STRUCTURE';
export const LEFTMENU_ADD_ITEM = 'LEFTMENU_ADD_ITEM';
export const LEFTMENU_UPDATE_ITEM = 'LEFTMENU_UPDATE_ITEM';
export const LEFTMENU_UPDATE_ITEMS = 'LEFTMENU_UPDATE_ITEMS';
export const LEFTMENU_DELETE_ITEM = 'LEFTMENU_DELETE_ITEM';
export const LEFTMENU_SERVICE_SET_EXPANDED = 'LEFTMENU_SERVICE_SET_EXPANDED';
export const LEFTMENU_SERVICE_SET_EXPANDED_ALL = 'LEFTMENU_SERVICE_SET_EXPANDED_ALL';
export const LEFTMENU_MOVE_ITEM = 'LEFTMENU_MOVE_ITEM';
export const LEFTMENU_SERVICE_SET_OPENED = 'LEFTMENU_SERVICE_SET_OPENED';
export const LEFTMENU_SERVICE_SET_HIDDEN = 'LEFTMENU_SERVICE_SET_HIDDEN';
export const LEFTMENU_SERVICE_SET_EDIT = 'LEFTMENU_SERVICE_SET_EDIT';

export const menuGetData = (): IMenuActions.Actions => ({
  type: LEFTMENU_GET_DATA
});

export const menuSetData = (): IMenuActions.Actions => ({
  type: LEFTMENU_SET_DATA
});

export const menuResetData = (): IMenuActions.Actions => ({
  type: LEFTMENU_RESET_DATA
});

export const menuInitData = (payload: IMenuActions.InitDataPayload): IMenuActions.Actions => ({
  type: LEFTMENU_INIT_DATA,
  payload
});

export const menuUnsave = (payload: IMenuActions.UnsaveDataPayload): IMenuActions.Actions => ({
  type: LEFTMENU_UNSAVE_DATA,
  payload
})

export const menuUpdateStructure = (payload: IMenu.Structure): IMenuActions.Actions => ({
  type: LEFTMENU_UPDATE_STRUCTURE,
  payload
});

export const menuAddItem = (payload: IMenuActions.AddItemPayload): IMenuActions.Actions => ({
  type: LEFTMENU_ADD_ITEM,
  payload
});

export const menuUpdateItem = (payload: IMenu.PartialMenuItemWithId): IMenuActions.Actions => ({
  type: LEFTMENU_UPDATE_ITEM,
  payload
});

export const menuUpdateItems = (payload: IMenuActions.UpdateItems['payload']): IMenuActions.Actions => ({
  type: LEFTMENU_UPDATE_ITEMS,
  payload
});

export const menuDeleteItem = (payload: IMenuActions.DeleteItemPayload): IMenuActions.Actions => ({
  type: LEFTMENU_DELETE_ITEM,
  payload
});

export const menuSetExpanded = (payload: string[]): IMenuActions.Actions => ({
  type: LEFTMENU_SERVICE_SET_EXPANDED,
  payload
})

export const menuSetExpandedAll = (payload: boolean): IMenuActions.Actions => ({
  type: LEFTMENU_SERVICE_SET_EXPANDED_ALL,
  payload
})

export const menuMoveItem = (payload: IMenuActions.MoveItemPayload): IMenuActions.Actions => ({
  type: LEFTMENU_MOVE_ITEM,
  payload
})

export const menuOpen = (payload?: boolean): IMenuActions.Actions => ({
  type: LEFTMENU_SERVICE_SET_OPENED,
  payload
})

export const menuHidden = (payload?: boolean): IMenuActions.Actions => ({
  type: LEFTMENU_SERVICE_SET_HIDDEN,
  payload
})

export const menuEdit = (payload?: boolean): IMenuActions.Actions => ({
  type: LEFTMENU_SERVICE_SET_EDIT,
  payload
})