import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import AsyncSelect from 'react-select/async';
// import i18n from '../../localizations/i18n';

import { cnInputMention, IInputMentionProps, IInputMentionState } from './InputMention.index';
import { Icon } from 'uielements/src/Icon/Icon';
import { Avatar } from 'uielements/src/Avatar/Avatar';

import './InputMention.scss';
import { StylesConfig } from 'react-select/src/styles';
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
// import 'react-select/dist/react-select.css';

export class InputMentionPresenter extends React.Component<IInputMentionProps, IInputMentionState> {
  public static defaultProps = {
    values: [],
    excludeMe: false
  }
  public el: HTMLElement | null;
  public request: any;

  constructor(props: IInputMentionProps) {
    super(props);
    this.state = {
      valuesOnLoading: false,
      options: [],
      values: [],
    };
  }

  public render() {
    const { icon, tag: TagName = 'div', noMulti, className, menuPosition, value, required, errorMessage, invalid, ...inputMentionProps } = this.props;
    if (inputMentionProps.placeholder && required) inputMentionProps.placeholder += ' *';
    const styles: /*StylesConfig*/any = {};
    if (menuPosition) styles.menu = (base: any) => ({ ...base, top: 'auto', bottom: '100%' });
    return (
      <TagName
        ref={el => (this.el = el)}
        onClick={this.onClick}
        className={cnInputMention({noIcon: !icon }, [className])}
      >
        {typeof icon === 'string' ? <Icon icon={icon} className={cnInputMention('Icon')} /> : icon}
        <AsyncSelect
          isMulti={!noMulti}
          // cacheOptions
          loadOptions={this.props.type === 'ratings' ? this.searchRating :  this.searchUsers}
          styles={styles}
          onInputChange={this.onInputChange}
          placeholder={inputMentionProps.placeholder}
          value={!Array.isArray(value) ?
            this.transformValue(value) : value.map(this.transformValue)}
          // noOptionsMessage={this.t('texts.no_search_results')}
          onChange={this.onChange}
          isClearable
          defaultOptions
        />
        {errorMessage && invalid && <HelpTooltip className={cnInputMention('HelpTooltip')} help={errorMessage}> <Icon icon="exclamation-circle" className={cnInputMention('ErrorIcon')} /></HelpTooltip>}
        {this.props.children}
      </TagName>
    );
  }

  private transformValue = (e: any) => {
    if (!e) return null;
    if (!e.data) return { id: e.id, data: e, value: e.id, label: this.itemRender(e.userPhotoUrl, e.displayName || e.name, e.imgId48x48) }
    return e;
  }

  private onClick = (ev: React.ChangeEvent<any>) => {
    if (this.props.onClick) this.props.onClick(ev);
  }

  private onInputChange = (value: string) => {
    if (value.length > 0) {
      this.setState(
        {
          valuesOnLoading: true,
        }
        // , () => {
        //   this.searchUsers(value);
        // }
      );
    }
  }

  private onChange = (selectedOption: any) => {
    if (!this.props.onChange) return;
    if (!selectedOption) return this.props.onChange(void 0);
    const options = Array.isArray(selectedOption) ? selectedOption.map(option => option.data) : (selectedOption.data);
    this.props.onChange(options);
    // this.setState((p: IInputMentionState) => {
    //   p.values = selectedOption;
    //   return p;
    // }, () => {
    //   // if(this.props.onChange && this.props.variable) this.props.onChange(this.state[this.props.variable]);
    //   if(this.props.onChange) this.props.onChange(this.state.values);
    // });
  }

  private itemRender = (avatar: string | undefined, name: string, imgId?: string) => {
    return ((
      <div className={cnInputMention('Selected')}>
        {this.props.type ==="pagecollections"?  <Icon className="SelectedIcon" icon={'folder'} /> :<Avatar
          className={cnInputMention('Selected-Icon')}
          imgUrl={avatar}
          name={name}
          imgId={imgId}
          size={24}
        />}
        <span title={name} className={cnInputMention('Selected-Name')}>{name}</span>
      </div>
    ))
  }

  private searchUsers = (value: string, callback: any) => {
    if (this.request) this.request.ac.abort();
    // if (!value) {
    //   callback([])
    //   return;
    // }
    this.request = utils.API.suggesters.search(this.props.type, value, -1, this.props.excludeMe, this.props.additionalRequestOpts);
    this.request.r.then((d: any) => {
      if (utils.checkResponseStatus(d)) {
        callback(d.data.map((el: any) => ({
          id: el.id,
          value: el.id,
          data: el,
          label: this.itemRender(el.userPhotoUrl || el.imgUrl, el.displayName || el.name, el.imgId),
        })));
      }
    });
  }

  private searchRating = (value: string, callback: any) => {
    if (this.request) this.request.ac.abort();
    this.request = utils.API.ratings.list({ search: value, ...this.props.additionalRequestOpts })
    this.request.r.then((d: any) => {
      if (utils.checkResponseStatus(d)) {
        callback(d.data.map((el: any) => {
          if(!el.id) el.id = el.pkid;
          return {
            id: el.id,
            value: el.id,
            data: el,
            label: this.itemRender(undefined, el.name, el.imgId48x48),
          }
        }));
      }
    });
  }
}

export const InputMention = InputMentionPresenter;
// compose(withBemMod(cnInputMention(), {}))(InputMentionPresenter));
