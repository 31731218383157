import * as React from 'react';
// import * as utils from 'src/utils/utils';

import { IImgLoaderProps } from '../ImgLoader.index';
import { withBemMod } from '@bem-react/core';

import { cnImgLoader } from '../ImgLoader.index';
import { IImgLoaderTypeProfileEditProps } from './ImgLoader_type_profileEdit.index';
import './ImgLoader_type_profileEdit.scss';


export const withImgLoaderTypeProfileEdit = withBemMod<any>(
  cnImgLoader(),
  { type: 'profileEdit' },
  (Presenter) => (
    (props: IImgLoaderTypeProfileEditProps & IImgLoaderProps) => (
      <Presenter {...props} />
    )
  )
);