import React from 'react';
import * as utils from 'utils/src/utils';

import { cnWidget } from '../Widget.index';

import './Widget_type_list.scss';
import { default as WidgetPresenter } from '../Widget';

import { widgets } from "i.widgets";
import { IWidgetTypeChartProps } from './Widget_type_chart.index';

const types = {
  common: require('uielements/src/Charts/_type/chart_type_line').ChartLine,
  area: require('uielements/src/Charts/_type/chart_type_area').CharArea,
  bagel: require('uielements/src/Charts/_type/chart_type_bagel').ChartBagel,
  bars: require('uielements/src/Charts/_type/chart_type_bars').ChartBars,
  line: require('uielements/src/Charts/_type/chart_type_line').ChartLine,
  pie: require('uielements/src/Charts/_type/chart_type_pie').ChartPie,

}

const type = 'chart';

const margin = {
  top: 50,
  left: 50,
  bottom: 50,
  right: 50,
}

export default class WidgetTypeChartPresenter extends WidgetPresenter<IWidgetTypeChartProps> {

  public state: any = {
    ...this.state,
    chartData: []
  }

  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     chartData: [[]]
  //   }
  // } 

  

  dataChart = []
  componentDidMount() {
    fetch(this.props.widget.link)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({ chartData: [data]})
      });

  }
  
  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    let Component = this.getComponent(types);
    // debugger;
    return (
      <>
      {this.state.chartData.length > 0 &&
      <Component 
        {...this.props}
        className={cnWidget({ type, subtype })}
        context={this.props.wcontext}
        id={this.props.widget.id}
        tag={widgets.components.common}
        margin={margin}
        height={400}
        series={this.state.chartData}
      />}
      </>
    )
  }

}