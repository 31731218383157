/**
 * @packageDocumentation
 * @module Widget_Settings_common
 */
import { JFdictionary } from "../dictionary";

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {}
}
