import React, { FC, useEffect, useState, useMemo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LerningTrack
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
// import { Button, Spinner } from 'uielements/src'
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';
import { Button, Avatar, SplitButton } from 'uielements/src'
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { removeMdLink, generateLinkData } from 'utils/src/utils'
import { usePlainText } from 'uielements/src/CommonmarkRender'
// import MDNodeBulder from 'uielements/src/CommonmarkRender/MDNodeBulder'
import { cutText } from 'LMSModule/utils/common.utils'
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import headerImg from './image.png'
import { ISplitButtonOption } from 'uielements/src/SplitButton/SplitButton.index'
import { IButtonProps } from 'uielements/src/Button/Button.index'
import withConfirmDialog, { IWithLoadSpin } from 'blocks/HOCs/Button/withConfirmDialog'

const ConfirmBtn = withConfirmDialog<IButtonProps>(Button)

const DragHandle = SortableHandle(({ disabled }: { disabled?: boolean }) => <IconButton disabled={disabled} size={'small'}><DragIndicatorIcon /></IconButton>);


type IPresenterProps = LerningTrack.ListItem.IPresenterProps
type IOwnProps = LerningTrack.ListItem.IOwnProps
type IDispatchProps = LerningTrack.ListItem.IDispatchProps
type IStateProps = LerningTrack.ListItem.IStateProps
type IState = LerningTrack.ListItem.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    value,
    removeFiles,
    disableSort
}) => {
    const mdtext = (value.newstype as any) === 38 ? (value as any).learningTrack.name : value.text
    const [shortText, fullText] = usePlainText(mdtext, 30)
    // const shortText = useMemo<string>(() => {
    //     const nb = new MDNodeBulder((value.newstype as any) === 38 ? (value as any).learningTrack.name : value.text)
    //     return cutText(nb.getPlainText().join(' '), 30)
    // }, [])

    let link = `/news/${value.learningTrack?.objectId}`
    link = value.learningTrack?.type === "LMSCources" ? `/TeacherOffice/Course/${value.learningTrack?.objectId}` : link
    link = value.learningTrack?.type === "PagesV2" ? `${value.learningTrack?.url}` : link
    link = value.learningTrack?.type === "Quizzes" ? `/quiz/go/${value.learningTrack?.objectId}` : link
    const linkProps = generateLinkData(link)

    const remove = () => {
        removeFiles([value.id])
    }


    const actions: ISplitButtonOption<IWithLoadSpin & IButtonProps>[] = [
        {
            title: tTr('item.file.context.delete'),
            buttonProps: {
                onClick: (e: any) => false,
                confirmBtnText: tTr('item.file.context.delete.confirmBtnText'),
                confirmTitle: tTr('item.file.context.delete.confirmTitle'),
                onConfirm: remove
            },
        },
        // {
        //     title: tTr('item.context.users'),
        //     buttonProps: {
        //         type: 'rlink',
        //         href: `/TrackManage/${data.tagId}?tab=users`
        //     },
        // },
        // {
        //     title: tTr('item.context.settings'),
        //     buttonProps: {
        //         type: 'rlink',
        //         href: `/TrackManage/${data.tagId}?tab=settings`
        //     },
        // }
    ];

    return <div className={cnClassName({}, [className])}>
        <div>
            <Avatar size={64} name={fullText} imgUrl={value.learningTrack?.imgUrl || headerImg} />
        </div>
        <div className={cnClassName('Content')}>
            <div>
                <Button className={cnClassName('Title')} title={fullText} theme={'unstyled'} {...linkProps}>{shortText}</Button>
            </div>
            <div className={cnClassName('TypeBox')}>
                <span className={cnClassName('Type')}>{tTr('trackfiles.list.item.type.1')}</span>
                <span className={cnClassName('TypeValue')}>{tTr('trackfiles.list.item.type.publication.' + ((value.newstype as any) === 38 ? (value as any).learningTrack.type : value.newstype))}</span>
            </div>
        </div>
        <div className={cnClassName('Actions')}>
            <SplitButton<IWithLoadSpin & IButtonProps> isOpen={() => { }} className={cnClassName('ActionMenu')}
                button={<DragHandle disabled={disableSort} />}
                ItemComponent={ConfirmBtn}
                options={actions} />
        </div>
        {/* <div className={cnClassName('Drag')}>
            <DragHandle />
        </div> */}
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
