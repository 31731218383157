import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { loadCourses, sendLikeCourse } from "../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
export interface ILikeActionProps extends IClassNameProps, ILikeActionStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
}

export interface ILikeActionState {
    cid: string;
}

export interface ILikeActionStateProps {
    // store: any
    // courses: any
    // cIds: string[]
    // isFinished: boolean
    // isLoading: boolean
    // isFinished: boolean
}

export const mapStateToProps = (state: any) => ({
    // store: state.store,
    // courses: state.LMS.courses.entities.values,
    // cIds: state.LMS.courses.result,
    // isLoading: state.LMS.courses.isLoading,
    // isFinished: state.LMS.courses.isFinished,
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    // loadCourses, 
    sendLikeCourse
}, dispatch);

export const cnLikeAction = cn('LikeAction');