import { ReactNode } from 'react';
import { RewardReplyes, CreativeTaskNews } from './CreativetasksType'
import {
    updateNews,
    pathNews
} from 'News/redux/actions'
import {
    getNewsParamsById
} from 'News/redux/saga/selectors'
import { bindActionCreators } from 'redux';
import { NewsContextProps } from 'News/contexts/news'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';

export interface ICreativetasksPropsType extends NewsContextProps {
}

export type TStateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export interface ICreativetasksStateType {
}

const newsParams = [
    'creativeTask', 
    'text', 
    'id',
    'user'
] as const

export const mapStateToProps = (state: any, { newsId }: ICreativetasksPropsType) => ({
    userData: getCurrentUser(state),
    currencyName: state.store.appSettings.currencyNameNominativeSingular,
    currencyNamePlural: state.store.appSettings.currencyNameGenitivePlural,
    ...getNewsParamsById<CreativeTaskNews, typeof newsParams[number]>(newsId, newsParams)(state)
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    updateNews,
    pathNews
}, dispatch);