import Component, { Presenter } from './component';
import {
    News,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = News.ContentView.IOwnProps;
export type IComponentPresenterProps = News.ContentView.IPresenterProps;


export default Component