import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IStateType as IState } from 'redux/store';
import { IDispatchProps } from '../../redux/connector';
import { loadData } from 'redux/actions/Notification'
import { StepContent } from '@material-ui/core';
import { set_readed_all } from 'redux/actions/Notification';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface INotificationsItem {
  id: number;
  newsDate: string;
  newsUid: string;
  msg: string;
  notViewed: boolean
  eventType: number,
  objId: string,
  objType: 0,
 
}

export interface INotificationsProps extends IClassNameProps, INotificationsStateProps, INotificationsOwnProps, INotificationsActionsToProps { }

export interface INotificationsOwnProps {}

export interface INotificationsState {
  text?: string;

}

export type INotificationsActionsToProps = ReturnType<typeof mapDispatchToProps>

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  loadData,
  set_readed_all
}, dispatch);

export type INotificationsStateProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: IState) => ({
  dataArray: state.notifications.data,
  notificationCount: state.store.notificationCount,
  isLoading: state.notifications.isLoading,
  currentUserId: getAuthUser(state).baseData.id as string
})

export const cnNotifications = cn('Notifications');