import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { sendApproveModeration } from '../../../../redux/actions/LMSTeacherOffice'

export interface IDisapproveProps extends IClassNameProps, IDisapproveStateProps, IDispatchProps, RouteComponentProps, IDisapproveDispatchProps {
    tag?: 'div';
}

export interface IDisapproveState {

}

export interface IDisapproveStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    status: state.LMSTO.moderation.answers[props.aid].moderationStatus,
    // courses: state.LMSTO.courses.values,
    // results: state.LMSTO.courses.results
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    sendApproveModeration
}, dispatch);

export type IDisapproveDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnDisapprove = cn('DisapproveAction');