import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import {
    loadGroup,
    checkGroupContext,
    changeMyInGroup,
    setNotificationTemplate
} from 'redux/actions/Groups';
import { getGroupByPKID } from 'redux/sagas/Groups/selectors'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getStructure } from 'redux/actions/Widgets'
import { routerActions } from 'connected-react-router';

export interface IGroupProps extends IClassNameProps, IGroupStateProps, IDispatchProps, RouteComponentProps, IGroupDispatchProps {
    tag?: 'div';
    groupId: string | number
}

export interface IGroupState {

}

export interface IGroupStateProps {
    group: any;
    authUser: any;
    appId?: string;
}

export const mapStateToProps = (state: any, props: any) => ({
    group: getGroupByPKID(props.groupId)(state),
    authUser: getAuthUser(state),
    appId: state.store?.appSettings?.appId
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadGroup,
    checkGroupContext,
    changeMyInGroup,
    setNotificationTemplate,
    changeLocation: (path: string) => routerActions.push(path),
    getStructure
}, dispatch);

export type IGroupDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnGroup = cn('InfoBlock-Group');