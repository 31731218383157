import React, { useEffect, useState, useRef } from 'react';
import {
  // Dialog,
  // DialogContent,
  // DialogTitle,
  SearchInput,
  Avatar,
  // Checkbox,
  // Spinner,
  Button
} from 'uielements/src';
import { cn } from '@bem-react/classname';
import './UserInputDialog.scss';
// import { ActionButtons } from 'blocks/ActionButtons/ActionButtons';
// import { API, checkResponseStatus } from 'utils/src/utils';
import { IUserListData } from 'utils/src/requests/models/api.users';
import Tabs, { ITabType } from 'uielements/src/TabsV2'
import { NEW__useInfiniteScroll as useInfiniteScroll, useScrollListState } from 'utils/src/hooks'
// import { searchUsers } from './PrnUserInput.sides'
import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';
// import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg'
// import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
// import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
// import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
// import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { createModal, TransitionComponent, IBodyProps } from 'utils/src/DialogCreator'
import { Translate } from 'localizations/Translate';
import { connect } from 'react-redux';
// import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import { InfinityList, ListHeader, ItemLayout, InfinityListItem } from 'uielements/src/InfinityList'
import DialogBody from 'uielements/src/MaterialElements/DialogParts/DialogBody';
import Divider from '@material-ui/core/Divider';
import { UserMention } from 'uielements/src/UserMention/UserMention'
import { isEqual } from 'lodash'

// const LoadButton = withLoadSpin(Button)
interface IUserInputDialogProps extends IBodyProps<{ result: any[] }> {
  network?: string;
  selected: any[];
  excludeMe?: boolean;
  excludeVariants?: any[];
  disabledVariants?: any[];
  showGroups?: boolean;
}

const cnUserInputDialog = cn('UserInputDialog');
type SearchParams = {
  value: string,
  excludeMe: boolean,
  type: string,
  network?: string,
  count: number
}
async function searchUsers(
  skipCount: number,
  {
    count,
    excludeMe,
    type,
    value,
    network
  }: SearchParams
) {
  const request = await utils.API.suggesters.search(type, value.trim(), -1, excludeMe, { network, count, skipCount });
  const response = await request.r
  return response.data
}


export const UserInputDialog: React.FC<IUserInputDialogProps> = ({
  selected,
  handleAccept,
  handleClose,
  excludeMe = false,
  network,
  excludeVariants = [],
  disabledVariants = [],
  showGroups = false
}) => {
  const TABS: ITabType[] = [
    {
      content: i18n.t('pryaniky.createPost.UserInputDialog.tabs.users'),
      value: 'users',
    },
  ]
  if (showGroups) TABS.push({
    content: i18n.t('pryaniky.createPost.UserInputDialog.tabs.groups'),
    value: 'groups',
  })


  const [search, setSearch] = useState('');
  const [tab, setTab] = useState(TABS[0]);
  const state = useScrollListState(
    (skipCount, count) => searchUsers(skipCount, {
      value: search,
      count,
      excludeMe,
      type: tab.value,
      network
    }),
    [search, tab.value, excludeMe, network],
    { defaultSelected: selected, count: 10, comparator: (a, b) => (a.id || a.pkid) === (b.id || b.pkid) }
  )

  useEffect(() => {
    setSearch('')
  }, [tab.value])

  const items = state.values.filter((val: any) => !excludeVariants.find((v: any) => v.id === val.id)).map((user: any) => {
    const isDisabled = disabledVariants.find((v: any) => v.id === user.id)
    return <>
      <InfinityListItem
        key={user.id}
        checkbox={true}
        onChangeCheckbox={() => !isDisabled && state.select.togleSelect(user)}
        checked={state.select.isSelected(user)}
        listItemAvatar={<Avatar imgId={user.id} imgUrl={user.imgUrl} size={56} name={user.displayName} />}
        primaryText={user.type === 'group' ? <h4 className={cnUserInputDialog('Name')}>{user.displayName}</h4> : <UserMention id={user.id} name={user.displayName} />}
        secondaryText={
          <>
            {!isDisabled ? <>
              <div>{user.position}</div>
              <div>{user.division}</div>
            </> :
              <div className={cnUserInputDialog('DisabledUser')}>{i18n.t('pryaniky.createPost.thanks.disabled_user')}</div>
            }
          </>
        }
      >
        {user.type === 'user' && <div className={('Contacts')}>
          <div><Button theme={'unstyled'} type={'link'} href={`mailto:${user.eMail}`}>{user.eMail}</Button></div>
          <div><Button theme={'unstyled'} type={'link'} href={`tel:${user.phone}`}>{user.phone}</Button></div>
        </div>}
      </InfinityListItem>
      <Divider variant={'fullWidth'} component={'div'} />
    </>
  });


  return <DialogBody
    onClose={handleClose}
    onAccept={() => handleAccept({ result: state.select.selected })}
    acceptText={<Translate i18nKey={'save'} />}
    closeText={<Translate i18nKey={'pryaniky.cancel.dialog'} />}
    disableTypography
    header={
      <div>
        <SearchInput className={cnUserInputDialog('Search')} value={search} placeholder={i18n.t('pryaniky.createPost.thanks.title')} onChange={setSearch} throttle={300} />
        <Tabs className={cnUserInputDialog('Tabs')} tabs={TABS} selected={tab} onChange={setTab} />
      </div>
    }
  >
    <InfinityList
      {...state}
      disableBorder
    >
      {items}
    </InfinityList>
  </DialogBody>
};

export const UserInputDialogPresenter = connect()(UserInputDialog);


export const DialogUserInput = createModal(UserInputDialogPresenter, {
  maxWidth: 'md',
  fullWidth: true,
  PaperProps: {
    style: {
      // backgroundColor: 'transparent',
      // overflowY: 'hidden'
    }
  },
  TransitionComponent,
  scroll: 'paper'
});