/**
 * @packageDocumentation
 * @module Widget_Settings_tagsCloud
 */

export default {
  tagsCloud: {
    collection: undefined,
    color: 'black',
  }
}