import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ReactNode, Dispatch } from 'react';
// import { likeNewsRequest, loadCommentRequest, updateNews } from "../../../redux/actions/News";
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
// import { addNews } from 'redux/actions/News';
// import { likeNewsRequest, deleteNewsRequest, loadCommentRequest, updateNews } from "../../redux/actions/News";



export const cnPageComments = cn("PageMenuComments");

export interface IPageCommentsProps extends ReturnType<typeof mapDispatchToProps>, IClassNameProps, ReturnType<typeof mapStateToProps> {
    isShown: boolean;
    onClose: () => void;
    parentRefEl?: HTMLElement;
    className?: string;
    onChange?: (field: any) => void;
    data: { [s: string]: any };
    newsId?: string;
    style?: any;
}

export interface IPageCommentsState {
    text?: string;
    w: string | number;
    // data: {[s: string]: any};
    // thank: boolean;
    // liked: boolean;
    // edit: boolean;
    // additionalReplyActions?: (commentID: string, user: any) => ReactNode;
    // additionalReplyForm: () => ReactNode;
}

export const mapStateToProps = (state: any, props: any) => ({
    currentUser: getAuthUser(state).baseData,
    // comments: state.news.comments
    // data: state.news.news[props.newsId],
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    // loadCommentRequest,
    // addNews
}, dispatch);