import React, { FC, ReactNode, useMemo } from 'react';
import { VacancyProps } from '../../Vacancy.index';
import { Translate } from 'localizations/Translate';
import { Box, TagChip } from 'muicomponents/src';
import { useVacancy, useVacancyData } from '../../Vacancy.hooks';
import { ActionsBox, DataBox, HeadersBox, TitlesBox, ValueBox } from '../../Vacancy.styled';
import { LoadingButton } from 'muicomponents/src/LoadingButton/LoadingButton';
import { vacanciesI18nKey } from 'localization';
import { useParams } from 'react-router';
import CommonmarkRender from 'uielements/src/CommonmarkRender'
import moment from 'moment';
import { Tooltip } from 'muicomponents/src/Tooltip';

const vacanciesI18nKeyFields = (key: string) => vacanciesI18nKey(`fields.${key}`);
const vacanciesI18nKeyField = (key: string) => vacanciesI18nKey(`field.${key}`);

function addSpacesByThousands(data: string | number) {
    const nStr = data + '';
    const x = nStr.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2');
    }
    return x1 + x2;
}

const salaryValueCheck = (dt: string) => {
    if(dt === '') {
        return null;
    } else {
        return Number(dt);
    }
};

export const VacancyMainTab: FC<VacancyProps> = ({
    id: paramId
}) => {
    
    let {
        id
    } = useParams<{id: string}>();

    if(paramId) id = paramId;

    const {
        item,
        requestSending,
        sendUserRequestForVacancy,
        currentUserRequestSended
    } = useVacancyData(id);

    const salaryCurency = useMemo(() => {
        switch (item?.salaryCurrency) {
            case 'RUB':
                return <Translate i18nKey={vacanciesI18nKeyField(`salary.currency.${item?.salaryCurrency}`)} />
            default:
                return item?.salaryCurrency;
        }
        return item?.salaryCurrency
    }, [item?.salaryCurrency]);
    
    const viewDate = useMemo(() => {
        if(!item?.changeDate && !item?.creationDate) return null;
        const dates = [moment(item?.changeDate || 0).toDate().getTime(), moment(item?.creationDate || 0).toDate().getTime()];
        const hightestDate = Math.max(...dates);
        const withEdit = dates.findIndex((el) => el === hightestDate) === 0;
        return <>{withEdit && <><Translate i18nKey={'edited'} />: </>}{moment(withEdit ? item?.changeDate : item?.creationDate).format('L HH:mm')}</>
    }, [item?.changeDate, item?.creationDate]);

    const salaryView = useMemo(() => {
        if(!item || !item.minSalary && !item.maxSalary) {
            return null;
        }
        const view: ReactNode[] = [];
        if(item.minSalary) {
            view.push(<><Translate i18nKey={vacanciesI18nKeyField('salary.from')} />&nbsp;{item.minSalary}&nbsp;</>);
        }
        if(item.maxSalary) {
            view.push(<><Translate i18nKey={vacanciesI18nKeyField('salary.to')} />&nbsp;{item.maxSalary}&nbsp;</>);
        }
        view.push(salaryCurency);
        return view;
    }, [item?.minSalary, item?.maxSalary, salaryCurency]);

    if(!id) {
      console.error(`widget vacancy doesn't have require param "id", check settings or url params`);
      return null;
    }

    if(!item) return null;

    return (
        <>
            <HeadersBox>
                <Box sx={{
                    textTransform: 'lowercase'
                }}>
                    {salaryView}
                </Box>
            </HeadersBox>
            <DataBox>
                <TitlesBox>
                    <Translate i18nKey={vacanciesI18nKeyField('code')} />
                </TitlesBox>
                <ValueBox>
                    {item.code}
                </ValueBox>
            </DataBox>
            <DataBox>
                <TitlesBox>
                    <Translate i18nKey={vacanciesI18nKeyField('description')} />
                </TitlesBox>
                <ValueBox>
                    <CommonmarkRender source={item.description || ''} />
                    {/* {item.description} */}
                </ValueBox>
            </DataBox>
            {
                item.unit &&
                <DataBox>
                    <TitlesBox>
                        <Translate i18nKey={vacanciesI18nKeyField('unit')} />
                    </TitlesBox>
                    <ValueBox>
                        {item.unit?.displayName}
                    </ValueBox>
                </DataBox>
            }
            {
                item.positionProfile &&
                <DataBox>
                    <TitlesBox>
                        <Translate i18nKey={vacanciesI18nKeyField('positionProfile')} />
                    </TitlesBox>
                    <ValueBox>
                        {item.positionProfile?.displayName}
                    </ValueBox>
                </DataBox>
            }
            {
                item.skills &&
                <DataBox>
                    <TitlesBox>
                        <Translate i18nKey={vacanciesI18nKeyField('skills')} />
                    </TitlesBox>
                    <ValueBox>
                        {item.skills.map(skill => {
                            return <TagChip data={skill} />
                        })}
                    </ValueBox>
                </DataBox>
            }
            {
                item.tags &&
                <DataBox>
                    <TitlesBox>
                        <Translate i18nKey={vacanciesI18nKeyField('tags')} />
                    </TitlesBox>
                    <ValueBox>
                        {item.tags.map(tag => {
                            return <TagChip data={tag} />
                        })}
                    </ValueBox>
                </DataBox>
            }
            <DataBox>
                <TitlesBox>
                    <Translate i18nKey={vacanciesI18nKeyField('workSchedule')} />
                </TitlesBox>
                <ValueBox>
                    <Translate i18nKey={vacanciesI18nKeyField(`schedule.${item.workSchedule}`)} />
                </ValueBox>
            </DataBox>

            <HeadersBox>
                <Translate i18nKey={vacanciesI18nKeyFields('second')} />
            </HeadersBox>
            <DataBox>
                <TitlesBox>
                    <Translate i18nKey={vacanciesI18nKeyField('location')} />
                </TitlesBox>
                <ValueBox>
                    {item.location}
                </ValueBox>
            </DataBox>
            <DataBox>
                <TitlesBox>
                    <Translate i18nKey={vacanciesI18nKeyField('employmentType')} />
                </TitlesBox>
                <ValueBox>
                    <Translate i18nKey={vacanciesI18nKeyField(`employmentType.${item.employmentType}`)} />
                </ValueBox>
            </DataBox>

            <ActionsBox>
                <Tooltip
                    open={currentUserRequestSended ? undefined : false}
                    title={!currentUserRequestSended ? '' : <Translate i18nKey='pryaniky.vacancy.request.sended' />}
                >
                    <span>
                        <LoadingButton
                            variant={'contained'}
                            disabled={currentUserRequestSended}
                            loading={requestSending}
                            onClick={() => {
                                sendUserRequestForVacancy();
                            }}
                        >
                            <Translate i18nKey={'pryaniky.vacancy.request.send'} />
                        </LoadingButton>
                    </span>
                </Tooltip>
            </ActionsBox>
        </>
    );
};