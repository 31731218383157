import React, { FC, memo, useCallback, useState } from 'react'
import {
    cnClassName,
    IPresenterProps
} from './interfaces'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { tC } from 'LMSModule/utils/i18n.adaptor'

export const Presenter: FC<IPresenterProps & IconButtonProps> = ({
    onClick,
    onMouseLeave,
    onMouseEnter,
    onConfirm,
    ...props
}) => {
    const [active, setActive] = useState(false)
    const [tid, setTid] = useState<any>(null)
    const click = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation()
        onClick && onClick(event)
        if (tid) {
            clearTimeout(tid)
            setTid(null)
        }
        
        if (active) {
            onConfirm && onConfirm()
            setActive(false)
        } else {
            setActive(true)
        }
    }

    const leave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onMouseLeave && onMouseLeave(event)
        if (active) {
            setTid(setTimeout(() => {
                setActive(false)
                setTid(null)
            }, 1000));
        }
    }

    const enter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onMouseEnter && onMouseEnter(event)
        if(tid) {
            clearTimeout(tid)
            setTid(null)
        }
    }

    const buttonProps = props as IconButtonProps
    return <Tooltip open={active} title={tC('pressAgain.1')} arrow>
        <IconButton
            {...buttonProps}
            onClick={click}
            color={active ? 'secondary' : 'default'}
            onMouseLeave={leave}
            onMouseEnter={enter}
        >
            <DeleteIcon />
        </IconButton>
    </Tooltip>
}

export default memo(Presenter);
