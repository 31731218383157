import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { Characteristics as CharacteristicsTypes } from 'utils/src/requests/models/api.shop'
import { IStoreAppSetting } from 'models/store/appSettings';
///////////

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace Shop {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace OrderBlock {

        export type IPresenterProps = IOwnProps

        export interface IOwnProps extends IClassNameProps {
            children?: React.ReactNode
            disableOrder?: boolean
            maxOrdersCount: number
            ordersCount: number
            maxOrdersPeriod: string
            characteristics: CharacteristicsTypes.IItem[]
            thanksPrice: number
            myThanksCount: number
            id: string | number
            showDialog: () => void
            currencyFormats: IStoreAppSetting
            productIsAvailable: boolean
        }
    }
}
export const cnClassName = cn('OrderBlock');