import BaseApi from '../baseAPI/baseAPI'



export class ObjectivesObject {
    /**
         * 
         */
    private API: BaseApi;

    private jsonString = true

    constructor(API: BaseApi) {
        this.API = API
        this.score = new Score(API)
    }

    _id: ""
    get id() { return this._id; }
    set id(id) { this._id = id; }

    _status: ""
    get status() { return this._status; }
    set status(status) { this._status = status; }

    score: Score

    toJSON: () => {}
}


export class InteractionsCorrectResponsesObject {
    /**
         * 
         */
    private API: BaseApi;

    private jsonString = true

    constructor(API: BaseApi) {
        this.API = API
    }

    _pattern: any = ""
    get pattern() { return (!this.jsonString) ? this.API.throwSCORMError(404) : this._pattern; }
    set pattern(pattern) { this._pattern = pattern; }

    toJSON: () => {}
}


export class InteractionsObjectivesObject {
    /**
         * 
         */
    private API: BaseApi;

    private jsonString = true

    constructor(API: BaseApi) {
        this.API = API
    }

    _id: any = ""
    get id() { return (!this.jsonString) ? this.API.throwSCORMError(404) : this._id; }
    set id(id) { this._id = id; }

    toJSON: () => {}
}


export class CorrectResponses {
    /**
     * 
     */
    private API: BaseApi;

    private jsonString = true

    constructor(API: BaseApi) {
        this.API = API
    }

    childArray: InteractionsCorrectResponsesObject[] = []
    get _count() { return this.childArray.length; }
    set _count(_count) { this.API.throwSCORMError(402); }

    toJSON: () => {}
}


export class InteractionsObject {
    /**
     * 
     */
    private API: BaseApi;

    private jsonString = true

    constructor(API: BaseApi) {
        this.API = API
        this.objectives = new Objectives(API);
        this.correct_responses = new CorrectResponses(API)
    }

    _id: any = ""
    get id() { return (!this.jsonString) ? this.API.throwSCORMError(404) : this._id; }
    set id(id) { this._id = id; }

    _time: any = ""
    get time() { return (!this.jsonString) ? this.API.throwSCORMError(404) : this._time; }
    set time(time) { this._time = time; }

    _type: any = ""
    get type() { return (!this.jsonString) ? this.API.throwSCORMError(404) : this._type; }
    set type(type) { this._type = type; }

    _weighting: any = ""
    get weighting() { return (!this.jsonString) ? this.API.throwSCORMError(404) : this._weighting; }
    set weighting(weighting) { this._weighting = weighting; }

    _student_response: any = ""
    get student_response() { return (!this.jsonString) ? this.API.throwSCORMError(404) : this._student_response; }
    set student_response(student_response) { this._student_response = student_response; }

    _result: any = ""
    get result() { return (!this.jsonString) ? this.API.throwSCORMError(404) : this._result; }
    set result(result) { this._result = result; }

    _latency: any = ""
    get latency() { return (!this.jsonString) ? this.API.throwSCORMError(404) : this._latency; }
    set latency(latency) { this._latency = latency; }

    _description: any = ""
    get description() { return (!this.jsonString) ? this.API.throwSCORMError(404) : this._description; }
    set description(description) { this._description = description; }

    objectives: Objectives;

    correct_responses: CorrectResponses;

    toJSON: () => {}

}


export class Interactions {
    /**
     * 
     */
    private API: BaseApi;

    private jsonString = true

    constructor(API: BaseApi) {
        this.API = API

    }

    __children: "id,objectives,time,type,correct_responses,weighting,student_response,result,latency"
    get _children() { return this.__children; }
    set _children(_children) { this.API.throwSCORMError(402); }

    childArray: InteractionsObject[] = []
    get _count() { return this.childArray.length; }
    set _count(_count) { this.API.throwSCORMError(402); }

    toJSON: () => {}
}



export class StudentPreference {
    /**
     * 
     */
    private API: BaseApi;

    private jsonString = true

    constructor(API: BaseApi) {
        this.API = API

    }

    __children: "audio,language,speed,text"
    get _children() { return this.__children; }
    set _children(_children) { this.API.throwSCORMError(402); }

    _audio: ""
    get audio() { return this._audio; }
    set audio(audio) { this._audio = audio; }

    _language: ""
    get language() { return this._language; }
    set language(language) { this._language = language; }

    _speed: ""
    get speed() { return this._speed; }
    set speed(speed) { this._speed = speed; }

    _text: ""
    get text() { return this._text; }
    set text(text) { this._text = text; }

    toJSON: () => {}
}



export class StudentData {
    /**
     * 
     */
    private API: BaseApi;

    private jsonString = true

    constructor(API: BaseApi) {
        this.API = API

    }

    __children: "mastery_score,max_time_allowed,time_limit_action"
    get _children() { return this.__children; }
    set _children(_children) { this.API.throwSCORMError(402); }

    _mastery_score: ""
    get mastery_score() { return this._mastery_score; }
    set mastery_score(mastery_score) { this.API.isNotInitialized() ? this._mastery_score = mastery_score : this.API.throwSCORMError(403); }

    _max_time_allowed: ""
    get max_time_allowed() { return this._max_time_allowed; }
    set max_time_allowed(max_time_allowed) { this.API.isNotInitialized() ? this._max_time_allowed = max_time_allowed : this.API.throwSCORMError(403); }

    _time_limit_action: ""
    get time_limit_action() { return this._time_limit_action; }
    set time_limit_action(time_limit_action) { this.API.isNotInitialized() ? this._time_limit_action = time_limit_action : this.API.throwSCORMError(403); }

    toJSON = () => { }
}



export class Objectives {
    /**
     * 
     */
    private API: BaseApi;

    private jsonString = true

    constructor(API: BaseApi) {
        this.API = API

    }

    __children: "id,score,status"
    get _children() { return this.__children; }
    set _children(_children) { this.API.throwSCORMError(402); }

    childArray: any[] = []
    get _count() { return this.childArray.length; }
    set _count(_count) { this.API.throwSCORMError(402); }

    toJSON: () => {}
}



export class Score {
    /**
     * 
     */
    private API: BaseApi;

    private jsonString = true

    constructor(API: BaseApi) {
        this.API = API

    }

    __children: "raw,min,max"
    get _children() { return this.__children; }
    set _children(_children) { this.API.throwSCORMError(402); }

    _raw: ""
    get raw() { return this._raw; }
    set raw(raw) { this._raw = raw; }

    _min: ""
    get min() { return this._min; }
    set min(min) { this._min = min; }

    _max: "100"
    get max() { return this._max; }
    set max(max) { this._max = max; }

    toJSON: () => {}
}


export class Core {
    /**
     * 
     */
    private API: BaseApi;

    private jsonString = true

    constructor(API: BaseApi) {
        this.API = API
        this.score = new Score(API)
    }

    __children: any = "student_id,student_name,lesson_location,credit,lesson_status,entry,score,total_time,lesson_mode,exit,session_time"
    get _children() { return this.__children; }
    set _children(_children) { this.API.throwSCORMError(402); }

    _student_id: any = ""
    get student_id() { return this._student_id; }
    set student_id(student_id) { this.API.isNotInitialized() ? this._student_id = student_id : this.API.throwSCORMError(403); }

    _student_name: any = ""
    get student_name() { return this._student_name; }
    set student_name(student_name) { this.API.isNotInitialized() ? this._student_name = student_name : this.API.throwSCORMError(403); }

    _lesson_location: any = ""
    get lesson_location() { return this._lesson_location; }
    set lesson_location(lesson_location) { this._lesson_location = lesson_location; }

    _credit: any = ""
    get credit() { return this._credit; }
    set credit(credit) { this.API.isNotInitialized() ? this._credit = credit : this.API.throwSCORMError(403); }

    _lesson_status: any = ""
    get lesson_status() { return this._lesson_status; }
    set lesson_status(lesson_status) { this._lesson_status = lesson_status; }

    _entry: any = ""
    get entry() { return this._entry; }
    set entry(entry) { this.API.isNotInitialized() ? this._entry = entry : this.API.throwSCORMError(403); }

    _total_time: any = ""
    get total_time() { return this._total_time; }
    set total_time(total_time) { this.API.isNotInitialized() ? this._total_time = total_time : this.API.throwSCORMError(403); }

    _lesson_mode: any = "normal"
    get lesson_mode() { return this._lesson_mode; }
    set lesson_mode(lesson_mode) { this.API.isNotInitialized() ? this._lesson_mode = lesson_mode : this.API.throwSCORMError(403); }

    _exit: any = ""
    get exit() { return (!this.jsonString) ? this.API.throwSCORMError(404) : this._exit; }
    set exit(exit) { this._exit = exit; }

    _session_time: any = ""
    get session_time() { return (!this.jsonString) ? this.API.throwSCORMError(404) : this._session_time; }
    set session_time(session_time) { this._session_time = session_time; }

    score: Score

}

/**
 * CMI data model
 */
class CMI {
    /**
     * 
     */
    private API: BaseApi;

    /**
     * SPM 4000 characters
     */
    private _launch_data: string = ""

    /**
     * SPM 64000 characters
     */
    private _suspend_data: string = ""

    /**
     * 
     */
    public comments: string = "";

    /**
     * 
     */
    public comments_from_lms: string = ""

    /**
     * 
     */
    public interactions: Interactions

    /**
     * 
     */
    public student_data: StudentData

    /**
     * 
     */
    public student_preference: StudentPreference

    /**
     * 
     */
    public objectives: Objectives

    /**
     * 
     */
    public core: Core

    /**
     * 
     */
    public toJSON = () => { }//jsonFormatter(this)

    /**
     * 
     * @param API 
     */
    constructor(API: BaseApi) {
        this.API = API
        this.core = new Core(API)
        // this.comments_from_learner = new CommentsFromLearner(this.API)
        // this.comments_from_lms = new CommentsFromLms(this.API)
        this.interactions = new Interactions(this.API)
        this.student_preference = new StudentPreference(this.API)
        this.student_data = new StudentData(this.API)
        this.objectives = new Objectives(this.API)
        // this.score = new Score(this.API)
    }


    private jsonString = true


    public get launch_data() {
        return this._launch_data;
    }

    public set launch_data(launch_data) {
        this.API.isNotInitialized() ? this._launch_data = launch_data : this.API.throwSCORMError(404);
    }

    public get suspend_data() {
        return this._suspend_data;
    }

    public set suspend_data(suspend_data) {
        this._suspend_data = suspend_data;
    }

}


export default CMI;