import { cloneDeep } from 'lodash';
import { FBaseNews, FBasePostNews, FNewsType } from 'utils/src/BaseTypes/news.types';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { RawDraftContentState } from 'draft-js';

const baseDate = new Date().toISOString();
const baseExpires = new Date().toISOString();

/**
 * base additional fields object to types 'workflow' & 'vote'
 */
export const baseAdditionalFields: FBaseNews['additionalFields'] = {
    additionalFieldsValues: null,
    comment: '',
    responsibleForStatus: [],
    showChangeStatusButton: false,
    currentStatus: null,
    formId: GUID_EMPTY
};

/**
 * base poll object to type 'polls'
 */
export const basePoll: FBaseNews['poll'] = {
    answers: [],
    answerVariants: 100,
    freeAnswer: false,
    disableRevote: false,
    showResultsBeforeVote: false,
    showParticipants: true,
    expires: baseExpires,
    maxCount: 0,
    showFullStat: false,
    votersCount: 0,
};


/**
 * base thanks object to type 'thanks'
 */
export const baseThanks: FBaseNews['thanks'] = {
    participants: []
};

/**
 * base creativeTask object to type 'creativetasks'
 */
export const baseCreativeTasks: FBaseNews['creativeTask'] = {
    endDateTime: baseExpires,
    fund: 0,
    state: 'Going',
    title: ''
};

/**
 * base meeting object in uevent object to type 'events'
 */
export const baseMeeting: NonNullable<FBaseNews['uevent']>['meeting'] = {
    domain: '',
    meetId: '',
    online: false,
    url: ''
} ;

/**
 * base uevent object to type 'events'
 */
export const baseUevent: FBaseNews['uevent'] = {
    calendar: null,
    duration: 15,
    eventLocation: '',
    eventMaybePartCount: 0,
    eventNotGoersCount: 0,
    eventWaitListCount: 0,
    eventParticipants: [],
    eventParticipantsCount: 0,
    eventStatus: 'NotStarted',
    meeting: baseMeeting,
    startDateTime: baseExpires,
    title: '',
    disallowParticipate: false,
    enableWaitList: false,
    maxParticipantsCount: null
};

/**
 * base news
 */
export const baseNews: FBaseNews = {
    achievement: null,
    actionButtons: [],
    actions: [],
    additionalFields: null,
    allowReply: true,
    allowThanksReply: true,
    changeDate: '',
    commentsCount: 0,
    componentRenderName: 'news',
    creativeTask: null,
    date: baseDate,
    header: '',
    id: '',
    isDraft: false,
    isNeedApprove: false,
    isShowOnTop: false,
    isSubscribed: false,
    language: 'ru',
    likeCount: 0,
    liked: false,
    likeText: '',
    newstype: 5,
    originLanguage: 'ru',
    parentGuid: GUID_EMPTY,
    parentNews: null,
    permaLink: '',
    poll: null,
    publishAt: '',
    statistics: null,
    actionHeader: null,
    attachments: null,
    badge: null,
    banners: null,
    commentDisabled: false,
    comments: null,
    contentRating: null,
    expires: null,
    feedback: null,
    group: null,
    learningTrack: null,
    linkPreviewImgUrl: null,
    quests: null,
    quizes: null,
    systemNewsActionHtml: '',
    tags: null,
    text: '',
    thanks: null,
    thanksCount: 0,
    uevent: null,
    user: null,
    userStatuses: null,
    idea: null,
    view: {
        expandImages: false,
        isFavorites: false
    }
};

export const emptyDraftRawState: RawDraftContentState = {
    blocks: [],
    entityMap: {}
};

export const basePostNews: FBasePostNews & {
    /**
     * draft state to creator to save editor view and convert to text before send news
     */
    draftRawState: RawDraftContentState;
} = {
    ...baseNews,
    groups: [],
    users: [],
    rewardReasons: [],
    draftRawState: emptyDraftRawState
};

export const defaultGroup: FBaseNews['group'] = {
    "pkid": -1,
    "name": "Основная лента",
    "alias": "",
    "imgId48x48": GUID_EMPTY,
    "imgId198x198": GUID_EMPTY,
    "imgUrl48x48": GUID_EMPTY,
    "isSystemGroup": false,
    "description": "",
    "isHidden": false,
    "isDeleted": false,
    "isArchived": false,
    "visibilityType": 0,
    "childGroupCount": 0,
    actions: [],
    defaultNotifyTemplate: '',
    descriptionPlainText: '',
    displayName: 'Основная лента',
    groupType: '',
    id: GUID_EMPTY,
    isContentDisabled: false,
    isMeInGroup: true,
    isOfficialGroup: true,
    isRequestAccessSent: false,
    membersCount: 0,
    roorIdGroup: -1,
    showGroupInSubnetworks: false,
    systemGroupName: '',
    type: 'group',
    url: ''
};



/**
 * default object template of BaseNews
 */
 export const createDefaultNews = () => cloneDeep(baseNews);

 /**
  * default object template of PostNews
  */
  export const createDefaultPostNews = () => cloneDeep(basePostNews);