import { ReactNode } from 'react';
import { QuestStepNews } from './QuestStep.type'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { NewsContextProps } from 'News/contexts/news'
import {
    getNewsParamsById
} from 'News/redux/saga/selectors'
export interface IQuestStepPropsType extends NewsContextProps {

}
export type TStateProps = ReturnType<typeof mapStateToProps>
export interface IQuestStepStateType {
}

const newsParams = [
    'text',
] as const

export const mapStateToProps = (state: any, { newsId }: IQuestStepPropsType) => ({
    userData: getCurrentUser(state),
    ...getNewsParamsById<QuestStepNews, typeof newsParams[number]>(newsId, newsParams)(state)
})
