import { Achievement } from '../../Achievements.type';
import { cn } from '@bem-react/classname';

export interface IAchievementsSelectorProps {
  selectedAchievement: Achievement;
  achievementList: any;
  onChange: (data: any) => void;
}

export const cnAchievementsSelector = cn('AchievementsSelector');
