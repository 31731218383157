import React, { FC } from 'react';
import './SessionList.scss';
import { Thread } from '../Parts/Thread/Thread'
import { cnSessionList } from './SessionList.index'
const zeroId = "00000000-0000-0000-0000-000000000000";
// const filterCb = (sessions: any) => (val: string) => {
//     if (sessions[val].startType === 0) return true;
//     const endTime = (new Date(sessions[val].endDate)).getTime()
//     const currentTime = (new Date()).getTime()

//     return endTime > currentTime
// }

export const SessionList: FC<any> = ({ course, className }) => {
    const isActivedSession = zeroId !== course.courseSessionId
    return <div className={cnSessionList({}, [className])}>
        {course.courseSessions/*.filter(filterCb(course.normalSessions))*/.map((threadId: any) => <Thread
            sid={threadId}
            isActivedSession={isActivedSession}
            current={course.courseSessionId === threadId}
            session={course.normalSessions[threadId]}
            cid={course.id} key={threadId} name={course.normalSessions[threadId].name}
            startType={course.normalSessions[threadId].startType}
            start={course.normalSessions[threadId].beginDate}
            end={course.normalSessions[threadId].endDate} />)}
    </div>
}