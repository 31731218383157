import { IIdeaFieldProps } from '../FieldProps';
import React, { useState } from 'react';
// import { cloneObject } from 'utils/src/utils';
import { Matrix } from '../../../../Matrix/Matrix';
import i18n from 'localizations/i18n';

export const MatrixOnePerRow: React.FC<IIdeaFieldProps> = ({ field, onChange = () => { } }) => {
    const [text, setText] = React.useState({ value: '', valid: true, error: i18n.t('') });

    // const [value, setValue] = useState(() => cloneObject(field))
    const onChecked = (rId: string, aId: string) => {
        // if (field.field.isReadOnly) return;
        field.rowValues[rId] = [aId];

        field.field.rows.forEach(item => {
            if(item.id === rId) {
                item.selectedAnswer = aId;
            }
        });
        const allAnswersSelected = field.field.rows.length === Object.values(field.rowValues).length
        if (field.field.isMandatory && !allAnswersSelected ) setText({ value: field.value, valid: false, error: i18n.t('Необходимо отметить все поля матрицы') });
        else setText({ value: field.value, valid: true, error: '' });
    
        onChange(field);
    }
    return (<Matrix invalid={!text.valid} errorMessage={text.error} required={field.field.isMandatory} data={field.field} onChange={onChecked}/>);
}