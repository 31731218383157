import { bindActionCreators } from 'redux';
import { createAlbum } from "../../../redux/actions/Photoalbums";

export interface ICreateAlbumProps extends IBaseCreateAlbumProps, ReturnType<typeof mapDispatchToProps>  {

}

export interface IBaseCreateAlbumProps {
    gid: number;
}

export const mapStateToProps = (state: any, props: IBaseCreateAlbumProps) => {
    return { }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    createAlbum
}, dispatch)