import { Box } from 'muicomponents/src/Box/Box'
import { styled } from 'muicomponents/src/mui/system';


export const AdditionalBoxeis = styled(Box)({
    background: "#FFFFFF",
    padding: "16px",
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between"
});
