import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Icon } from 'uielements/src'
import './style.scss'

type IPresenterProps = StatisticsFull.Tile.IPresenterProps
type IOwnProps = StatisticsFull.Tile.IOwnProps
type IDispatchProps = StatisticsFull.Tile.IDispatchProps
type IStateProps = StatisticsFull.Tile.IStateProps
type IState = StatisticsFull.Tile.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    value,
    push
}) => {
    const { id, name, imgUrl, title, disabled } = value
    const onClick = () => !disabled && push(`TeacherOffice/statistics/type/${name}`)
    return <div className={cnClassName({ disabled }, [className])} onClick={onClick}>
        {disabled && <div className={cnClassName('Disabled')}>
            <Icon className={cnClassName('Icon')} icon={'lock-alt'} />
        </div>}
        <div className={cnClassName('ImgBox')}>{imgUrl && <img className={cnClassName('Img')} src={imgUrl} />}</div>
        <div className={cnClassName('Header')}>{title}</div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));
