import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    getUserscoursesinfohead,
    pathFullStatisticSetting
} from '../../../../redux/actions/LMSTeacherOffice'
import * as SEL from '../../../../redux/sagas/LMSTO/selectors'
import { ILoadingProps } from 'utils/src/CommonRedux/LoadIndicator'
import { pick } from 'lodash'
///////////
export declare namespace StatisticsFull {
    export namespace UsersSummary {
        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps, ILoadingProps {

        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////

type IOwnProps = StatisticsFull.UsersSummary.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    ...SEL.getUserscoursesinfohead(state),
    ...pick(SEL.getFullStatisticSettings(state), 'countArchive', 'isMandatory', 'endDate', 'startDate', 'groups', 'courses')
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    getUserscoursesinfohead,
    pathFullStatisticSetting
}, dispatch);

////////////

export const cnClassName = cn('LMSTOStatUsersSummary');