import React from 'react';
import WidgetPresenter from '../Widget';
import { IWidgetTypeNotifiSettingsProps } from './Widget_type_notifiSettings.index';
import './Widget_type_notifiSettings.scss';
import { NotifiSettings } from '../../NotifiSettings/NotifiSettings';
import { widgets } from "i.widgets";
import { cnWidget } from '../Widget.index';

const type = 'notifiSettings';
class WidgetTypeNotifiSettings extends WidgetPresenter<IWidgetTypeNotifiSettingsProps> {

  public render() {
    // if (!this.props.widget) return null;
    return (
      <NotifiSettings {...this.props} className={cnWidget( {type} )} tag={widgets.components.common}/>
    );
  }
}

export default WidgetTypeNotifiSettings;