import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    tabs: ITabsType[];
    selected: ITabsType;
    onChange: (tab: ITabsType) => void;
}

export interface ITabsType {
    value: string,
    content: React.ReactNode
}

export const cnClassName = cn('LMSCourseTabs');