import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';
import i18n from 'localizations/i18n';
// import { toast } from 'react-toastify';
import { cnActions, IActionsProps, IActionsState } from './NewsView-Actions.index';
import './NewsView-Actions.scss';
import * as utilsDep from 'utils.project/utils.project';
import * as utils from 'utils/src/utils';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
// import uuid from 'uuid/v1';
// import { SReplyMobileModel } from 'blocks/Replyes/Replyes.index';
import { Bable } from 'blocks/Bable/Bable';
export class ActionsPresenter extends React.Component<IActionsProps, IActionsState> {
  // public utils: any = utils;
  public el: HTMLElement | null;

  public thanksReasonIds: number[] = [];
  public thanksReasonNames: string[] = [];

  private sendind: boolean = false;

  constructor(props: IActionsProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {
      liked: this.props.liked,
      likeCount: this.props.likeCount,
      thanksCount: 1
    };
  }

  public render() {
    const customClassNames = 'horizontalPadding';
    const { thank } = this.props;
    const { liked, likeCount } = this.state;
    const isLikedClass = () => {
      return liked ? 'heart-fill' : 'heart';
    }
    return (
      <div ref={el => this.el = el} className={cnActions({}, [customClassNames, this.props.className])}>
        <Button theme='unstyled' onClick={this.actionLike}><Icon icon={isLikedClass()} />{i18n.t('pryaniky.post.actions.like')}</Button>
        {likeCount !== 0 && <Button theme='unstyled' className={cnActions('BtnLikeNum')} onClick={this.actionLikedBy}
        ><Bable>{likeCount}</Bable></Button>}
        {thank && <Button theme='unstyled' onClick={this.props.thankAct}><Icon icon='give' />{i18n.t('pryaniky.post.actions.thank')}</Button>}
        {this.props.children}
      </div>
    )
  }

  private actionLikedBy = () => {
    const invite = (data: { [s: string]: any }) => {
      // 
    }
    utilsDep.modal({
      header: i18n.t('pryaniky.modal.likers.title'),
      type: 'likers',
      data: {
        nId: this.props.id
      },
      okBtnAction: invite,
      okBtnText: i18n.t('ok')
    })
  }
  /*
    private actionGift = () => {
      this.thanksReasonIds = [];
      this.thanksReasonNames = [];
  
      const okBtnValidate = (data: any) => {
        let validReason = false;
        data.companyReasons && data.companyReasons.forEach((cat: any) => {
          cat.reasons.forEach((reason: any) => {
            if (reason.selected) {
              validReason = true;
              if (this.thanksReasonIds.indexOf(reason.id) === -1) {
                this.thanksReasonIds.push(reason.id);
                this.thanksReasonNames.push(reason.name);
              }
            }
          })
        })
        if (data.count > 0 && validReason) return true;
        return false;
      }
  
      const okBtnAction = (data: any) => {
        const reply: SReplyMobileModel = {
          id: uuid(),
          // text: `[${this.props.user.displayName}](/User/${this.props.user.id}) за ` + this.thanksReasonNames.join(', '),
          text: '',
          header: '',
          liked: false,
          date: new Date(),
          actions: [],
          likeCount: 0,
          parentCommentId: null,
          user: this.props.store.authUser.baseData,
          attachments: [],
          thanksInfo: {
            users: [this.props.user.id],
            thanksReasonIds: this.thanksReasonIds,
            thanksCount: data.count
          }
        }
        utils.API.reply.post(this.props.id, reply)
          .r
          .then((d: any) => {
            if (d && d.error_code === 0) {
              this.props.thanksReply && this.props.thanksReply(d.data);
  
              toast.success('success');
  
            }
            else toast.error('error');
          });
      }
  
      utilsDep.modal({
        type: 'thanks',
        header: 'thanks',
        data: {
          count: this.state.thanksCount,
          selectedUserCount: 1,
          // companyReasons: this.state.companyReasons
        },
        okBtnText: i18n.t('save'),
        okBtnAction,
        okBtnValidate
      })
    }
  */
  private actionLike = () => {
    if (this.sendind) return;
    this.setState((s: IActionsState) => {
      s.liked = !s.liked;
      s.likeCount += !s.liked ? -1 : +1;
      return s;
    }, () => this.likeRequest(this.state.liked));
  }

  private likeRequest(liked: boolean) {
    this.sendind = true;
    utils.API.news.action(this.props.id, 22, liked)
      .r
      .then((d: any) => {
        this.sendind = false;
        if (d.error_code === 0 && d.error_text === 'OK') {
          /* this.setState((s: IActionsState) => {
            s.likeCount = d.likeCount; 
            return s;
          }); */
        }
      })
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const Actions = compose(
  // withBemMod(cnActions(), {})
)(ActionsPresenter)