import React, { FC, memo, useCallback, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { questionTypes } from './constants'
import { connect } from 'react-redux';
import './style.scss'

import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import { controlStyle, peperStyle, controlLabelStyle } from './style'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import QuestionEditor from '../QuestionEditor'

const FormControlLabelHelped = styled(fieldWithHelp(FormControlLabel))(controlLabelStyle)


export const Presenter: FC<IPresenterProps> = ({
    className,
    question,
    createAnswer,
    updateQuestion,
    deleteQuestion,
    changeQuestionType,
    setQuestionError,
    number,
    uid,
    withAnswerError,
    errors,
    deleteAnswer: deleteAnswerAction,
    answersErrors,
    maxFileSize
}) => {
    const updateQ = useCallback((payload: any) => updateQuestion({ id: question.id, ...payload }, uid), [question])

    const changeCheck = (property: string) => () => {
        updateQ({ [property]: !question[property] })
    }

    let withErrors = withAnswerError
    withErrors = Object.values(errors).filter((v: any) => (typeof v !== 'undefined')).length !== 0 ? true : withErrors
    withErrors = answersErrors.length !== 0 ? true : withErrors

    return <QuestionEditor
        deleteQuestion={deleteQuestion}
        number={number}
        qid={question.id}
        uid={uid}
        questionTypes={questionTypes}
    >

        {question.qtype === 2 &&
            <div>
                <Typography>{tT('question_edit.qtype.2.label.1')}</Typography>
                <div>
                    <FormControlLabelHelped
                        control={
                            <Checkbox
                                checked={question.isNeedModeration}
                                onChange={changeCheck('isNeedModeration')}
                                name="needModerationQuestions"
                                color="primary"
                            />
                        }
                        label={tT('question_edit.isNeedModeration.1')}
                        help={tT('question_edit.isNeedModeration.help.1')}
                    />
                </div>
                <div>
                    <FormControlLabelHelped
                        control={
                            <Checkbox
                                checked={question.allowAttach}
                                onChange={changeCheck('allowAttach')}
                                name="allowAttach"
                                color="primary"
                            />
                        }
                        label={tT('question_edit.allowAttach.1')}
                        help={tT('question_edit.allowAttach.help.1')}
                    />
                </div>
            </div>
        }
    </QuestionEditor>

}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
