import { compose } from '@bem-react/core';
import * as React from 'react';
import { cnBable, IBableProps } from './Bable.index';
// import './Bable.scss';
/**
 * 
 * 
 * 
 * не используй этот!
 * бери из uielements!
 * 
 * 
 * 
 * 
 */
export const Bable = compose(
  // withBemMod(cnBable(), {})
  )(class extends React.Component<IBableProps> {
    public static defaultProps = {
      tag: 'span'
    }

    public render() {
      const customClassNames = '';
      const { children, className = '' } = this.props;
      return (
        <this.props.tag className={cnBable({}, [customClassNames, className])}>
          {children}
        </this.props.tag>
      )
    }
  })