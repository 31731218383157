import React, { FC, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import NewsItem from '../NewsItem'
import CommentsView from '../CommentsView'
import withNewsLoader from '../../HOCs/withNewsLoader'

type IPresenterProps = News.CommentsRender.IPresenterProps
type IOwnProps = News.CommentsRender.IOwnProps
type IDispatchProps = News.CommentsRender.IDispatchProps
type IStateProps = News.CommentsRender.IStateProps
type IState = News.CommentsRender.IState


export const Presenter: FC<IPresenterProps> = ({ className, id }) => {
    return <div className={cnClassName({}, [className])}>
        <NewsItem id={id} className={cnClassName('News')} ViewComponent={CommentsView} />
    </div>
}

export const CommentsRender = connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));

export const WithLoader = withNewsLoader(CommentsRender)

export default WithLoader