import React from 'react';
import './Widget_type_commonmark.scss';
import WidgetPresenter from '../../Widget';
import { cnWidgetMui, IWidgetProps } from '../../Widget.index';
import { widgets } from '../../../../i.widgets';
import { Commonmark } from 'blocks/Commonmark';

const type = 'commonmark';

export default class WidgetTypeMarkdownPresenter extends WidgetPresenter<IWidgetProps> {
  public render() {
    if (!this.props.widget) return null;
    const saveWidgetData = (d: string) => {
      this.props.updateWidget({
        ...this.props.widget,
        settings: {
          data: d
        }
      });
      this.props.setStructureFromWidget(this.props.widget.id);
    }
    return (
      <widgets.components.common {...this.props} className={cnWidgetMui({ type })}>
        <Commonmark data={this.props.widget.settings?.data as any as string || ''} saveEdited={saveWidgetData} />
      </widgets.components.common>
    );
  }
}
