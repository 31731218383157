import { Box, styled } from "muicomponents/src";
import { ItemBox, ItemGridCell } from "../TasksList.styled";
import { cnTaskListItemCell } from "./TasksListItem.index"; 

export const StyledItemBox = styled(ItemBox)({
    alignItems: 'flex-start',
    [`& .${cnTaskListItemCell()}:last-of-type`]: {
        flexBasis: 80,
        minWidth: 80,
    }
}) as typeof ItemBox;

export const DispalyNameBox = styled(Box)({
    display: 'flex',
    gap: 8,
    gridGap: 8
}) as typeof Box;

export const ExpiredBox = styled(Box)(({
    color
}) => {
    return {
        color: color === 'error' ? '#FF0508' : undefined
    }
}) as typeof Box;