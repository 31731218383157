import { put, takeLeading, takeEvery, takeLatest, all, delay } from 'redux-saga/effects';
import { select, call } from 'utils/src/saga.effects';
import i18n from 'localizations/i18n';

import {
  setSurvey,
  setOpenSurvey,
  setLoadingSurvey,
  setIsAnswered,
  setUserResult,
  setIsShowResult,
  setAnswerId,
  isLoadingSurveys,
  setSurveyQuestionsResults,
  setActiveQuestion,
  setFinishedSurvey,
  setSurveyStateFieldByKey,
  changeActiveSurveyIdx
} from './actions';

import * as types from './actions.interfaces';
import {
  GET_SURVEY,
  GET_OPEN_SURVEY,
  SEND_ANSWER,
  SURVEY_START,
  SURVEY_SEND_ANSWERS
} from './actions.types'

import { IGetSurveysListResponse, IGetSurveyDataForSurveyPageResponse, ISurveysResponse, ISurveysAnswerData } from 'utils/src/requests/models/admin.surveys';

import { getMySurveysListRequest, getOpenSurvey, passingSurvey, passingSurveyPreview } from 'utils/src/requests/admin.surveys';

import { getSurveySelector, getSurveySelectorDataByKey, getSurveyStateByKey, getSurveyWidgetData } from './selectors';
import { ISurveyQuestions, ISurveyResults, ISurveyQuestionsResults } from './interfaces';

import { v1 as uuid } from 'uuid';

import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { checkResponseStatus, getErrorText } from 'utils/src/utils';
import { toast } from 'react-toastify';
import { IResponseWithData } from 'utils/src/requests/models/api.base';
import { } from './interfaces';
import { surveyModel } from "./constants";
import { sendAnswers, startSurveyPassing } from 'utils/src';
import { SSurveyAnswerBlockByPage } from 'utils/src/BaseTypes/survey';
import { validateAnswers } from './utils.surveys';

function* getMySurveys(action: types.AGetSurvey) {
  try {
    const { skipCount, count } = action.payload;
    const surveyWidgetState: any = yield* select(getSurveyWidgetData)
    yield put(isLoadingSurveys(true))
    const response: IGetSurveysListResponse = yield call(getMySurveysListRequest, skipCount, count);

    if (checkResponseStatus(response)) {
      yield put(setSurvey([...surveyWidgetState, ...response.data.map((el) => el)]))
      yield put(isLoadingSurveys(false))
    } else {
      toast.error(getErrorText(response));
      yield put(isLoadingSurveys(false))
    }
  } catch (e) {
    console.error('get surveys list error', e);
  }
}




function* getOpenSurveysSaga(action: types.AGetOpenSurvey) {

  try {
    yield put(setLoadingSurvey(true))
    const response: IGetSurveyDataForSurveyPageResponse = yield call(getOpenSurvey, action.payload.id, action.payload.context);
    if (checkResponseStatus(response)) {
      yield put(setOpenSurvey(response.data))
      const questionsList = response.data.questions || [];
      const resultsList = [];
      const questions: ISurveyQuestions = {};
      const results: ISurveyResults = {};

      const questionsIds: string[] = questionsList.map((el: any) => el.id);
      const resultsIds: string[] = [];


      questionsList.forEach((el: any) => {
        const newAnswers: { [s: string]: ISurveysAnswerData } = {};
        el.answers.forEach((answer: any) => newAnswers[answer.id] = answer);
        // debugger;
        const newAnswersIds = el.answers.map((el: any) => el.id);
        questions[el.id] = { ...el, expanded: false, newAnswers, newAnswersIds };
      });
      yield put(setSurveyQuestionsResults({ questions, questionsIds, results, resultsIds }));
      // yield put(setOpenSurvey(surveyModel))
      yield put(setLoadingSurvey(false))
    } else {
      // yield put(setOpenSurvey(response.data||[]))
      yield put(setLoadingSurvey(false))
      if (response.error_code === 2000) {
        toast.error(i18n.t('pryaniky.error.survey.error_code.2000'));
      } else {
        toast.error(getErrorText(response));
      }


    }
  } catch (e) {
    console.error('get surveys list error', e);
  }
}

/**
 * @deprecated
 * @param action 
 */
function* sendAnswerSaga(action: types.ASendAnswer) {
  try {

    yield put(setIsAnswered(true))
    const surveyState = yield* select(getSurveySelector)

    const currentQuestion = surveyState.openSurveyData.questions.find((el: any) => el.id === surveyState.activeQuistionId);
    const answersQuestionHasIsFreeAnswer = currentQuestion?.answers?.find((el: any) => el.isFree)?.id;


    const data = {
      id: currentQuestion.id, // quistionId
      surveyId: surveyState.openSurveyData.id, // surveyId
      // preview: action.payload.preview, //усли этот флаг trueто не должна идти запись в статистику
      model: {
        id: currentQuestion.id, //questionid
        isSkipped: action.payload.isSkipped,
        answers: currentQuestion.qtype === "Sorting" ?
          surveyState.questionsResults.questions[currentQuestion.id].newAnswersIds.map((id: string) => ({
            id,
            isChecked: true,
            text: "",
            attachments: [],
            numericAnswer: null,
            isFree: surveyState.isFree
            // text: surveyState.answerDate ? surveyState.answerDate : ""
          })) : currentQuestion.qtype === "MatrixSingle" ?
            currentQuestion.answers : currentQuestion.qtype === "Multi" ? surveyState.answersIds.map((id: string) => ({
              id,
              isChecked: true,
              text: id === answersQuestionHasIsFreeAnswer ? surveyState.textAnswers : "",
              attachments: surveyState.attachments ? surveyState.attachments : [],
              numericAnswer: surveyState.numericAnswer ? parseInt(surveyState.numericAnswer) : null,
              isFree: id === answersQuestionHasIsFreeAnswer ? surveyState.isFree : false
              // text: surveyState.answerDate ? surveyState.answerDate : ""
            })) : surveyState.answersIds.map((id: string) => ({
              id,
              isChecked: true,
              text: surveyState.textAnswers ? surveyState.textAnswers : surveyState.answerDate ? surveyState.answerDate : "",
              attachments: surveyState.attachments ? surveyState.attachments : [],
              numericAnswer: surveyState.numericAnswer ? parseInt(surveyState.numericAnswer) : null,
              isFree: surveyState.isFree
              // text: surveyState.answerDate ? surveyState.answerDate : ""
            })),
        rows: surveyState.rows
      }
    }
    let response: ISurveysResponse;
    if (action.payload.preview) {
      response = yield call(passingSurveyPreview, action.payload.id, action.payload.preview, data);
    } else {
      response = yield call(passingSurvey, action.payload.id, data);
    }
    if (checkResponseStatus(response)) {

      if (!response.data.nextQuestion) {
        // finish survey if don't have next question
        yield put(setUserResult(response.data));
        yield put(setIsShowResult(true));
        yield put(setFinishedSurvey());
      } else {
        // set next active question
        yield put(setActiveQuestion(response.data.nextQuestion.id));
        // setUserResult(response.data))
      }

    } else {
      toast.error(getErrorText(response));
    }
  } catch (e) {
    console.error('get surveys list error', e);
  }
}

function* startSurvey(action: ReturnType<types.CStartSurvey>) {
  try {
    yield put(setSurveyStateFieldByKey({
      key: 'surveyStartLoading',
      value: true
    }));
    const currentSurvey = yield* select(getSurveySelector);
    const data = yield* select(getSurveySelectorDataByKey('openSurveyData'));
    const currentSurveyId = data.openSurveyData?.id;
    if (!currentSurveyId) return;
    const response = yield* call(startSurveyPassing, { id: currentSurveyId, preview: action.payload.preview || undefined });

    if (checkResponseStatus(response)) {
      yield put(setSurveyStateFieldByKey({
        key: 'surveyByPages',
        value: response.data
      }));

      if (response.data.pages[0]) {
        yield put(setSurveyStateFieldByKey({
          key: 'surveyPassing',
          value: response.data.pages[0]
        }));
      }
      yield put(setSurveyStateFieldByKey({
        key: 'survey',
        value: {
          ...currentSurvey,
          started: true,
          result:{},
          finished:false
          
        }
      }));
    } else {
      console.error('start survey response error', response);
    }

  } catch (e) {
    console.error('start survey error', e);
  } finally {
    yield put(setSurveyStateFieldByKey({
      key: 'surveyStartLoading',
      value: false
    }));
  }
};

function* sendAnswersSaga(action: ReturnType<types.CSendAnswersSurvey>) {
  try {
    yield put(setSurveyStateFieldByKey({
      key: 'surveyPassingLoading',
      value: true
    }));

    const data = yield* select(getSurveyStateByKey('surveyByPages', 'surveyPassing', 'surveyPassingAnswers', 'firstQuestionError'));
    if (!data.surveyPassing || !data.surveyByPages) return;
    const currentPageId = data.surveyPassing.id;
    const currentSurveyId = data.surveyByPages.id;

    const answers = data.surveyPassingAnswers;

    let firstQuestionError: typeof data.firstQuestionError = null;

    data.surveyPassing.questions.forEach(question => {
      const questionIsValid = validateAnswers(question, answers);
      if (!questionIsValid && !firstQuestionError) firstQuestionError = question.id;
    });

    if (firstQuestionError) {
      yield put(setSurveyStateFieldByKey({
        key: 'firstQuestionError',
        value: firstQuestionError
      }));
      return;
    }

    const requestData: SSurveyAnswerBlockByPage = {
      id: currentPageId,
      surveyId: currentSurveyId,
      model: {
        ...data.surveyPassing,
        questions: data.surveyPassing.questions.map(question => {
          return {
            ...question,
            isSkipped: action.payload.skipAll || !answers[question.id],
            rows: answers[question.id]?.rows || [],
            answers: action.payload.skipAll
              ? question.answers
              : question.qtype === "MatrixSingle"
                ? question.answers
                : question.qtype === "Sorting"
                  ? answers[question.id]?.answersIds.map(answerId => {
                    const answer = question.answers.find(el => el.id === answerId);
                    if (!answer) return null;
                    return {
                      ...answer,
                      isChecked: true,
                      attachments: [],
                      isFree: answers[question.id].isFree
                    }
                  }) as typeof question.answers
                  : answers[question.id]?.answersIds.map(answerId => {
                    const answer = question.answers.find(el => el.id === answerId);
                    if (!answer) return null;
                    return {
                      ...answer,
                      isChecked: true,
                      attachments: answers[question.id].attachments,
                      isFree: answer.isFree,
                      numericAnswer: answers[question.id].numericAnswer,
                      text: answer.isFree ? (answers[question.id].text ? answers[question.id].text : "Другое") : answers[question.id].text,
                    }
                  }) as typeof question.answers
          }
        })
      }
    };

    const response = yield* call(sendAnswers, {
      preview: action.payload.preview,
      ...requestData
    });

    if (checkResponseStatus(response)) {
      yield put(setSurveyStateFieldByKey({
        key: 'firstQuestionError',
        value: null
      }));
      if (response.data.nextPage) {
        yield put(setSurveyStateFieldByKey({
          key: 'surveyPassing',
          value: response.data.nextPage
        }));
      }
      if (response.data.result) {
        const currentSurvey = yield* select(getSurveySelector);
        // if maxAttemptCount === 1 then survey have only one try to passing & we need to remove this suvrey from surveyWidget if it in that array
        if(currentSurvey.openSurveyData.maxAttemptCount === 1) {
          const widgetArray = yield* select(getSurveyWidgetData);
          yield put(changeActiveSurveyIdx(0));
          yield put(setSurvey(widgetArray.filter(el => el.id !== currentSurvey.openSurveyData?.id)));
        }
        yield put(setSurveyStateFieldByKey({
          key: 'survey',
          value: {
            ...currentSurvey,
            result: response.data.result,
            started: false,
            finished: true,
            isShowResult: true
          }
        }));
        yield put(setSurveyStateFieldByKey({
          key: 'surveyPassingAnswers',
          value: {}
        }));
       
      }
    } else {
      toast.error(getErrorText(response));
    }
  } catch (e) {
    console.error('send answers survey error', e);
  } finally { 
    yield put(setSurveyStateFieldByKey({
      key: 'surveyPassingLoading',
      value: false
    }));
  }
};

const surveys = function* surveys() {
  yield takeLatest(GET_SURVEY, getMySurveys);
  yield takeLatest(GET_OPEN_SURVEY, getOpenSurveysSaga);
  yield takeLatest(SEND_ANSWER, sendAnswerSaga);

  yield takeLatest(SURVEY_START, startSurvey);
  yield takeLatest(SURVEY_SEND_ANSWERS, sendAnswersSaga);

}

export default surveys;