import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';

export const cnBlocks = cn('Blocks');

export namespace NBlocks {

    export interface Item {
        id: string;
        file: { [ s:string ]: any } | null;
        imgUrl: string;
        text: string;
        url: string;
    }

    export interface Props extends IClassNameProps {
        data: Item[];
    }
}