import * as React from 'react';
import { StickyTable } from 'uielements/src/StickyTable/StickyTable';
import { Button, Checkbox } from 'uielements/src';
import i18n from '../../../localizations/i18n';
import queryString from 'query-string';
import { API } from 'utils/src/utils';
import { toast } from 'react-toastify';
import './NotificationsSettings_Group.scss';
import { cnNotificationsSettingsGroup, INotificationsSettingsGroupProps, INotificationsSettingsGroupState, mapStateToProps } from './NotificationsSettings_Group.index';
import { connect } from 'react-redux'
import * as utils from 'utils/src/utils';
import { withRouter } from 'react-router-dom'

export class NotificationsSettingsGroup extends React.Component<
  INotificationsSettingsGroupProps,
  INotificationsSettingsGroupState
  > {

  constructor(props: INotificationsSettingsGroupProps) {
    super(props);
    this.state = {
      type: 'groups',
      currentElement: null,
      settings: {
        g: [],
        destinations: []
      }
    }
  }
  public componentDidMount() {

    const type = queryString.parse(this.props.location.search).networkId && 'network'    

    if (type === 'network') {
      const networks: any = {}
      this.props.networks.map((element: any) => {
        networks[element.id] = element
      })

      const currentElementId = queryString.parse(this.props.location.search).networkId || '-1';
      const currentElement = networks[`${currentElementId}`]

      this.setState({
        type,
        currentElement
      }, () => {
          this.loadSettings(currentElementId, type)
      })
    } 
  }

  public render() {

    const { settings, currentElement } = this.state;

    const type = queryString.parse(this.props.location.search).gid && 'groups'
    if (type === 'groups' && !currentElement) {
      const currentElementId = queryString.parse(this.props.location.search).gid || '-1'
      API.groups.byId(currentElementId.toString()).r.then((d: any) => {
        if (d.error_code === 0) {
          this.loadSettings(currentElementId, type)
          this.setState({ currentElement: d.data });
        }
      })
    }

    const key = 'g'//type === 'groups' ? 'nu' : 'g' // хз почему, но сервер теперь только g возворащает
    const tdata = !settings[key] ? [] : settings[key]
    
    return <div className="NotificationsBox">
      <div className="NotificationsSettings-layout systemBorderColorGray systemBorder">
        <h2 className={cnNotificationsSettingsGroup("Title")}>
          {type === 'groups' ? i18n.t("pryaniky.notifications.settings.settings.for.group") : 'Настройки для сети: '}
          <span className={cnNotificationsSettingsGroup("GroupName")}>{currentElement && currentElement.name}</span>
        </h2>
      </div>
      {tdata.map((item: any, index: number) => (
        <div className="NotificationsSettings-layout systemBorderColorGray systemBorder " key={index}>
          <h2>{item.displayName}</h2>
          <div className="NotificationsSettings-wrapper">
            <StickyTable
              columnsHeaders={settings.destinations.map((v: any) => v.value)}
              rowsHeaders={item.n.map((v: any) => v.displayName)}
              rows={this.prepareRows(item)}
            />
            <Button className={cnNotificationsSettingsGroup("saveButton")} onClick={this.saveSettings}>
              {i18n.t("save")}
            </Button>
          </div>
        </div>
      ))}
    </div>
  }

  private loadSettings = (id: any, type: string,) => {
    // USERID
    const userId = this.props.userId || utils.getCookie('authUid');
    const request = type === 'groups' ? API.notifications.settings.get(userId, id) : API.notifications.settings.get(userId, 0, id.toString())
    request.r.then(d => {
      this.setState({ settings: d.data }, () => {

      });
    });
  };

  private prepareRows = (item: any) => {
    return item.n.map((v: any) => ({
      cells: v.values.map((s: any, i: number) => (
        <Checkbox
          key={i}
          checked={s.value}
          onClick={() => {
            s.value = !s.value;
            this.setState({});
          }}
        />
      ))
    }));
  };

  private transformDataToOldGroup(data: any, uid: string) {
    // USERID
    return {
      uid: uid,
      groupId: data.groupId,
      g1: {
        groupId: data.groupId,
        displayName: data.displayName,
        selectedTemplateName: "custom", // data.selectedTemplateName,//"custom",
        nu: data.nu
      },
      auth: data.auth
    };
  }

  private saveSettings = () => {
    const { currentElement, settings, type } = this.state;
    // USERID
    const id = this.props.userId || utils.getCookie('authUid');
   
    if ( type === 'groups' ) {
      const network = queryString.parse(this.props.location.search).network || '0'
      
      API.notifications.settings
        .setGroup(currentElement.pkid, id, this.transformDataToOldGroup(settings, id), network.toString()).r.then((d: any) => {
          if (d.error_code === 0) {
            toast.success(i18n.t("success"));
          } else {
            toast.error(i18n.t("server error"));
          }
        });
    } else if (type === 'network'){
      const isUserNetwork = currentElement.id === this.props.baseNetwork
     
      utils.API.notifications.settings.setNetworkTemplate('0', id, currentElement.id, {
        networkTemplate: isUserNetwork ? 'common' : 'custom',
        g: settings.g})
        .r.then((d: any) => {
        if (d.error_code === 0) {
          toast.success(i18n.t('success'))
        } else {
          toast.error(i18n.t('server error'))
        }
      })
    }
  };
}
export default connect(mapStateToProps)(withRouter(NotificationsSettingsGroup)) 