import Component, { Presenter } from './component';
import {
    IDialogTitleOwnProps,
    IDialogTitlePresenterProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = IDialogTitleOwnProps;
export type IComponentPresenterProps = IDialogTitlePresenterProps;


export default Component