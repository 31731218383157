import { cn } from '@bem-react/classname';

export interface IShowMoreEventsDialogProps {
  onClose: any
  onShown: any
  events: any
  date: Date
  isShown: boolean
}

export const cnCalendarModalEvents = cn('CalendarModalEvents');