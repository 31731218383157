import React, { FC, useEffect, useState } from 'react'
import { cnClassName, mapDispatchToProps, mapStateToProps } from './TestResults.index'
import { withRouter } from 'react-router-dom'
import { connect, useSelector } from 'react-redux';
import './TestResults.scss'
import { Selectize } from 'uielements/src';
import StudentsStatus from '../StudentsStatus'
import UnitsAttandance from '../UnitsAttandance'
import CommentsCount from '../CommentsCount'
import AllReports from '../AllReports'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import FormControl from '@material-ui/core/FormControl';
import Tabs, { ITabType } from 'uielements/src/TabsV2'
import SingleAutocomplete from 'uielements/src/MaterialElements/SingleAutocomplete'
import withSessionSuggester from 'blocks/HOCs/SingleAutocomplete/withSessionSuggester'
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
import Graph from './graph'
import { getSessionIdIfSingle, getSessionById } from 'LMSModule/redux/sagas/LMSTO/selectors'

const SessionSelect = withSessionSuggester(SingleAutocomplete)

export const TestResultsPresenter: FC<any> = ({ className, cid, loadTestResults, results }) => {
    const TABS: ITabType[] = [
        { value: 'active', content: tT('statistics.course_statistics.tabs.active.1') },
        { value: 'archive', content: tT('statistics.course_statistics.tabs.archive.1') }
    ]

    const defaultSid = useSelector(getSessionIdIfSingle(cid))


    const [sid, setSid] = useState<string | undefined>(undefined)

    useEffect(() => {
        (defaultSid && !sid) && setSid(defaultSid)
    }, [defaultSid])


    const [isArchived, setArchived] = useState(false)
    const changeSelect = (item: ISelectizeItem | null) => {
        if (!item) setSid(undefined)
        else setSid(item.id)
    }
    useEffect(() => {
        sid && loadTestResults(sid)
    }, [sid])
    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Control')}>
            <Tabs
                className={cnClassName('Tabs')}
                size={'small'}
                onChange={(tab) => { tab.value === 'active' ? setArchived(false) : setArchived(true) }}
                selected={isArchived ? TABS[1] : TABS[0]}
                tabs={TABS}
            />
            <div className={cnClassName('Sessions')}>
                <FormControl fullWidth>
                    <SessionSelect
                        archived={isArchived}
                        onSelect={changeSelect}
                        label={tT('statistics.course_statistics.select.session.1')}
                        courseId={cid}
                    />
                </FormControl>
                {/* <IconButton
                    title={tT('statistics.reports.title.download.1')}
                    href={`https://${API.base.domain}/ru/Data/v3/statistics/lms/studentsstatusexcel?camelCase=true&courseId=${cid}${sid ? '&sessionId=' + sid : ''}`}
                >
                    <DescriptionIcon />
                </IconButton> */}
            </div>

        </div>

        <div className={cnClassName('Data')}>
            {!sid ? <div className={cnClassName('NoSession')}>{tT('statistics.test_results.session_not_selected')}</div> : <Graph width={770} height={40 * results.length + 140} values={results} />}
        </div>

    </div>
}

export const TestResults = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TestResultsPresenter));
