import { NoticesNews } from './Notices.type'
import { Rule, BaseValidator, validateArray, validateString, validateNumber } from '../Base.validate'
import { nameCount } from 'utils/src/utils'

export const noticesValidate: Rule[] = [
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'text',
        value: 5,
        message: 'small text',
        errorI18nKey: 'pryaniky.validate.news.announcement.text.min',
        errorVariablesI18nKey: {
            count: 5,
            variable: nameCount(5, 'pryaniky.validate.string.variable')
        }
    },
    /*{
        type: 'string',
        method: 'STR_MAX',
        field: 'text',
        value: 250000,
        message: 'big text'
    },*/
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'header',
        value: 3,
        message: 'small header',
        errorI18nKey: 'pryaniky.validate.news.announcement.header.min',
        errorVariablesI18nKey: {
            count: 3,
            variable: nameCount(3, 'pryaniky.validate.string.variable')
        }
    },
    /*{
        type: 'string',
        method: 'STR_MAX',
        field: 'header',
        value: 30,
        message: 'big header'
    },*/
]


export class NoticesValidator extends BaseValidator<any> {
}
