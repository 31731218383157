import { IBodyProps } from '../Modal-Body.index';
import { IStateType as IState } from 'redux/store';
import { IThanksReasonGroup } from 'utils/src/requests/models/api.system';
import { IDispatchProps } from 'redux/connector';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface IBodyTypeThanksProps extends IBodyProps, IDispatchProps, ReturnType<typeof mapStateToProps> {}

export const mapStateToProps = (state: IState) => ({
  thanksForAll: getAuthUser(state).extData.thanksForAll as number,
  thanksReasons: state.store.thanksReasons as IThanksReasonGroup[],
});

export interface IBodyTypeThanksState {
  count: number;
}
