import React, { FC, memo } from 'react'
import {
    cnClassName,
    News
} from './interfaces'
import './style.scss'
import { Icon, HelpTooltip, Button } from 'uielements/src'
import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu';
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import FormControl from '@material-ui/core/FormControl';
import i18n from 'localizations/i18n'

type IPresenterProps = News.MentionFind.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    onClick
}) => {
    return <HelpTooltip
        showOnHowerHelp
        hideDelay={300}
        showDelay={150}
        inlineBlock
        className={'EditActions-Helptooltip'}
        help={i18n.t('pryaniky.createPost.actions.mention')}>
        <Button theme={'unstyled'}
            className={cnClassName({}, [className])}
            onClick={onClick}>
            {<Icon icon='user-tag' />}
        </Button>
    </HelpTooltip>
}

export default memo(Presenter);
