import {
  takeEvery,
  put,
  takeLeading,
  takeLatest,
  throttle,
  fork
} from 'redux-saga/effects';
import {
    call,
    select,
} from 'utils/src/saga.effects';

import actions from 'redux/actionsTypes/Modals';
import { modalInit, modalChangeData, IDialogsGetIntecompanyGroupRequestsAction, IDialogsConfirmIntercompanyGroupsRequestsAction, IDialogsDeclineIntercompanyGroupsRequestsAction } from '../actions/Modals';
import { API } from 'utils/src/utils';
import { IResponseWithData } from 'utils/src/requests/models/api.base';
import { IGroupIntercompanyRequest } from 'utils/src/requests/models/api.intepcompany';
import { getModalDataById } from './selectors';
import { IStateType } from 'redux/store';

function* getIntercompanyGroupsRequests(action: IDialogsGetIntecompanyGroupRequestsAction) {
  try {
    const modalState = yield* select((state: IStateType) => state.modals[action.payload.id]);
    if(!modalState) yield put(modalInit({ id: action.payload.id }));
    yield put(modalChangeData({ id: action.payload.id, data: { loading: true } }));
    const requests: IResponseWithData<IGroupIntercompanyRequest[]> = yield* call(API.intercomany.getIntercompanyGroupRequests);
    yield put(modalChangeData({ id: action.payload.id, data: { list: requests.data, loading: false } }));
    return ;
  } catch (error) {
    console.error('get intercompany group requests error', error);
  }
}

function* confirmIntercompanyGroupsRequests(action: IDialogsConfirmIntercompanyGroupsRequestsAction) {
  try {
    yield* call(API.intercomany.confirmIntercompanyGroupRequest, { id: action.payload.groupId, comment: action.payload.comment });
    const data: { list: IGroupIntercompanyRequest[] } = yield* select(getModalDataById(action.payload.id))
    yield put(modalChangeData({ 
      id: action.payload.id, 
      data: { 
        list: data.list.map((el) => el.group.pkid.toString() === action.payload.groupId ? { ...el, status: 'RequestApproved' } : el ) 
      } 
    }));
    return ;
  } catch (error) {
    console.error('accept intercompany group requests error', error);
  }
}

function* declineIntercompanyGroupsRequests(action: IDialogsDeclineIntercompanyGroupsRequestsAction) {
  try {
    yield* call(API.intercomany.declineIntercompanyGroupRequest, { id: action.payload.groupId, comment: action.payload.comment });
    const data: { list: IGroupIntercompanyRequest[] } = yield* select(getModalDataById(action.payload.id))
    yield put(modalChangeData({
      id: action.payload.id,
      data: {
        list: data.list.map((el) => el.group.pkid.toString() === action.payload.groupId ? { ...el, status: 'RequestDeclined' } : el)
      }
    }));
    return ;
  } catch (error) {
    console.error('accept intercompany group requests error', error);
  }
}

/**
 * module root saga
 */
const root = function* root() {
  yield takeEvery(actions.GET_INTERCOMPANY_GROUPS_REQUESTS, getIntercompanyGroupsRequests)
  yield takeEvery(actions.CONFIRM_INTERCOMPANY_GROUPS_REQUESTS, confirmIntercompanyGroupsRequests)
  yield takeEvery(actions.DECLINE_INTERCOMPANY_GROUPS_REQUESTS, declineIntercompanyGroupsRequests)
};

/**
 * export root saga
 */
export default root;