import Component, { ColorCharacteristic, initColor } from './Color';
import * as BaseInterface from '../interfaces'

export {
    ColorCharacteristic,
    initColor
}

export type IComponentPresenterProps = BaseInterface.Shop.CharacteristicsValueTBase.IPresenterProps;


export default Component