import Component, { Presenter } from './component';
import {
    IOwnProps,
    IPresenterProps,
    cnClassName,
} from './interfaces';
import { IUnitType } from 'LMSModule/utils/common.utils'

export {
    cnClassName,
    Presenter,
}

export type IComponentProps = IOwnProps;
export type IComponentPresenterProps = IPresenterProps;
export type ISelectorUnit = IUnitType;


export default Component