import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Button, Spinner, SplitButton, Icon } from 'uielements/src'
import Item from './Item'
import ListPlaceholder from '../../../components/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import Checkbox from '@material-ui/core/Checkbox';
import { ISplitButtonOption } from 'uielements/src/SplitButton/SplitButton.index'
import { IButtonProps } from 'uielements/src/Button/Button.index'
import withConfirmDialog, { IWithLoadSpin } from 'blocks/HOCs/Button/withConfirmDialog'
import CourseTabs, { ITabType } from '../../../components/Tabs'
import { useScrollList } from 'LMSModule/utils/hooks'

const LoadButton = withLoadSpin(Button)
const ConfirmBtn = withConfirmDialog<IButtonProps>(Button)

export const Presenter: FC<IPresenterProps> = ({
    className,
    loadUsersStat,
    count,
    isFinished,
    isLoading,
    values,
    keys,
    match,
    search
}) => {
    // const [create, setCreate] = useState(false)
    // const [search, setSearch] = useState('')
    // useEffect(() => {
    //     loadUsersStat({ tagId: match.params.id })
    // }, [])
    const { loadMore, triggerRef } = useScrollList(
        isLoading,
        isFinished,
        false,
        [search],
        (deps) => (clean = false) => {
            loadUsersStat({
                tagId: deps.current.tagId,
                skipCount: deps.current.keys.length,
                count: deps.current.count,
                search: deps.current.search,
                clean
                // params: pick(deps.current.settings, 'countArchive', 'isMandatory', 'endDate', 'startDate')
            })
        },
        { keys, count, tagId: match.params.id, search }
    )
    // useEffect(() => {
    //     loadTracksList({ search,clean: true })
    // }, [search])
    // const loadMore = () => loadTracksList({ count, skipCount: keys.length })
    // const triggerRef = useInfiniteScroll({
    //     loadMore,
    //     isLoading,
    //     isFinished,
    // });

    return <div className={cnClassName({}, [className])}>
        <ListPlaceholder
            className={cnClassName('Placeholder')}
            isFinished={isFinished}
            isLoading={isLoading}
            itemsCount={keys.length}
            titleEmpty={tTr('tracks.stat.users.empty.title')}
            textEmpty={tTr('tracks.stat.users.empty.text')}
            textLoad={tTr('tracks.stat.users.load.text')}
        >
            {keys.map(id => <Item data={values[id]} />)}
            
            {!isFinished && (
                <LoadButton
                    forwardedRef={triggerRef}
                    isLoading={isLoading}
                    className={cnClassName('Trigger')}
                    onClick={isLoading ? void 0 : loadMore}>
                    {tTr('tracks.more.triger')}
                </LoadButton>
            )}
        </ListPlaceholder>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
