import { BaseNews } from '../../../../BaseType'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
export interface IEditorTypeNewsProps {
    data: any;
    types?: any;
    onChange: (data: any, validate: boolean) => void;
}

export const mapStateToProps = (state: any) => ({
    userData: getCurrentUser(state),
})