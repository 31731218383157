import { getLifeCycol } from 'utils/src/CommonRedux/LifeCycol/selectors'



export default (store: any) => {
    let unsubscribe = () => { }
    unsubscribe = store.subscribe(() => {
        const INIT_COMPLETED = getLifeCycol(store.getState()).INIT_COMPLETED
        if (INIT_COMPLETED) {
            unsubscribe()
        }

    })
}


