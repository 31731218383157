import { styled } from "muicomponents/src/mui/system";
import { Box } from "muicomponents/src";
import { TFlexSettingsBox } from "./Notifications";

export const MenuLoadingBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: '12px 0'
}) as typeof Box;

export const MenuButtonBox = styled(Box)<TFlexSettingsBox>(({position = 'static', padding}) => ({
  position: position,
  bottom: 0,
  left: 0,
  right: 0,
  borderTop: position === 'sticky' ? '1px solid #c4c4c4' : 'none',
  backgroundColor: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
})) as typeof Box;

export const MenuNotNotificationBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  columnGap: 14,
  color: '#666666'
}) as typeof Box;