import React from 'react';
import { cnHierarchyWidgetNew, DialogBodyShowAllProps } from '../Widget_type_hierarchyNew.index';
import { DialogBody } from 'muicomponents/src/DialogParts';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { Box } from 'muicomponents/src';
import { Translate } from 'localization';

const DialogBodyShowAll = ({ handleClose, children }: DialogBodyShowAllProps) => {
    return (
        <DialogBody
            className={cnHierarchyWidgetNew('DialogBodyShowAll')}
            closeText={<Translate i18nKey="close" />}
            onClose={handleClose}
            contentProps={{
                sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                },
            }}
        >
            <Box className={cnHierarchyWidgetNew('DialogBodyShowAll')} sx={{ width: '100%' }}>
                {children}
            </Box>
        </DialogBody>
    );
};

export const openDialogShowAll = createModal(DialogBodyShowAll, {
    maxWidth: 'sm',
    fullWidth: false,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
        },
    },
    TransitionComponent,
    scroll: 'body',
});
