import { INewsPropsType, mapStateToProps, TStateProps } from './News.index'
import React, { FC, memo } from 'react';
import { connect } from 'react-redux';
import './News.scss'
import { withNewsContext } from 'News/contexts/news'
import { ComponentInjector } from 'utils/src/ComponentInjector'
// import CommentsView from 'News/containers/CommentsView'
import CommentsView, { CommentsWithActions } from 'News/containers/CommentsView/component'
// import TextRender from 'News/containers/TextRender'
// import ContentLayout from 'News/components/ContentLayout'


export const CommnetsOnlyPresenter: FC<INewsPropsType & TStateProps> = ({ }) => {
    return <CommentsView />
}

export const CommnetsOnly = withNewsContext<INewsPropsType>(connect(
    mapStateToProps
)(memo(CommnetsOnlyPresenter)), ['newsId']);

export const ActionsCommnetsOnly = withNewsContext<INewsPropsType>(connect(
    mapStateToProps
)(memo((props) => {
    return <CommentsWithActions />
})), ['newsId']);


export const initCommentsOnly = () => {
    ComponentInjector.getInstance().addNode('news_types', <CommnetsOnly />, 'shopitemnews')
    ComponentInjector.getInstance().addNode('news_types', <ActionsCommnetsOnly />, 'wikipagenews')
}