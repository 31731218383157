import React, { FC } from 'react';
import './Thread.scss'
import { Deadlines } from '../../../components/Deadlines/Deadlines'
import { Button, Icon } from 'uielements/src'
import { mapDispatchToProps, mapStateToProps, ITestProps, cnThread } from './Thread.index'
import { connect } from 'react-redux';
import { isCompleteMondatory } from 'LMSModule/utils/utils.lms'
import { ButtonBox } from 'uielements/src/ButtonBox/ButtonBox'
import { tS } from 'LMSModule/utils/i18n.adaptor'
import { ESessionStatus } from '../../../redux/sagas/LMS/selectors'




export const ThreadPresenter: FC<any> = ({
    session,
    cid,
    current,
    joinToSession,
    sid,
    name,
    unitsLogs,
    units,
    isActivedSession,
    status
}) => {
    const resultLink = `?tab=results&sid=${sid}`//`/lms/result/${cid}/${sid}`
    const testLink = `?tab=studying`//`/lms/test/${cid}`

    const completeMondatory = isCompleteMondatory(unitsLogs, units)
    status = status as ESessionStatus;
    if (session.isArchived)
        return <div className={cnThread()}>
            <Deadlines session={session} className={cnThread('Deadlines')} />
            <div className={cnThread('Name')} title={name}>{name}{session.isPreview && <Icon icon={'flask'} />}</div>
            <Button
                className={cnThread('Btn', { type: 'result' })}
                main
                type={'rlink'}
                href={resultLink}>{tS('thread.results.1')}</Button>
        </div>
    return <div className={cnThread()}>
        <Deadlines session={session} className={cnThread('Deadlines')} />
        <div className={cnThread('Name')} title={name}>{name}{session.isPreview && <Icon icon={'flask'} />}</div>
        <ButtonBox orientation={'horizontal'} className={cnThread('Actions')}>
            {/* завершена обязательная часть */}
            {(ESessionStatus.COMPLETED_ALL === status) && <>
                <Button
                    className={cnThread('Btn', { type: 'result' })}
                    type={'rlink'}
                    href={resultLink}>{tS('thread.results.2')}</Button>
            </>}

            {/* обязательные выполнены, но ещё остались не пройденые */}
            {(ESessionStatus.COMPLETE_MONDATORY === status) && <>
                <Button
                    className={cnThread('Btn', { type: 'start' })}
                    main
                    type={'rlink'}
                    href={testLink}>
                    {tS('thread.start.1')}
                </Button>
                <Button
                    className={cnThread('Btn', { type: 'result' })}
                    type={'rlink'}
                    href={resultLink}>{tS('thread.results.2')}</Button>
            </>}

            {/* завершено вообще всё, что только можно */}
            {(ESessionStatus.ABSOLUTE_COMLETE === status) &&
                <Button
                    className={cnThread('Btn', { type: 'result' })}
                    main
                    type={'rlink'}
                    href={resultLink}>{tS('thread.results.3')}</Button>}

            {/* join выполнен. но ещё ни чего не сделано */}
            {(ESessionStatus.STARTED === status) &&
                <Button
                    className={cnThread('Btn', { type: 'start' })}
                    main
                    type={'rlink'}
                    href={testLink}>
                    {tS('thread.start.1')}
                </Button>}

            {/* приступил и что то уже сделал */}
            {(ESessionStatus.PROGRESS === status) &&
                <Button
                    className={cnThread('Btn', { type: 'start' })}
                    main
                    type={'rlink'}
                    href={testLink}>
                    {tS('thread.start.1')}
                </Button>}

            {/* ещё не выполнено join. */}
            {(ESessionStatus.NOT_STARTED === status) &&
                <Button
                    main
                    className={cnThread('Btn', { type: 'join' })}
                    // theme={'unstyled_center'}
                    onClick={() => { joinToSession(sid, cid) }} >{tS('thread.start.2')}</Button>}

            {/* недоступно, активна другая сессия */}
            {(ESessionStatus.BLOCKED === status) && <Button
                // theme={'unstyled_center'}
                title={tS('noJoin.title.1')}
                className={cnThread('Btn', { type: 'join' })}>{tS('thread.noJoin.1')}</Button>}

            {/* {(session.log && session.log.isCompleted) &&
                <Button
                    className={cnThread('Btn', { type: 'result' })}
                    main
                    type={'rlink'}
                    href={resultLink}>{tS('thread.results.2')}</Button>}

            {(completeMondatory && ((session.log && !session.log.isCompleted))) &&
                <Button
                    className={cnThread('Btn', { type: 'result' })}
                    type={'rlink'}
                    href={resultLink}>{tS('thread.results.3')}</Button>}

            {current &&
                <Button
                    className={cnThread('Btn', { type: 'start' })}
                    main
                    type={'rlink'}
                    href={testLink}>
                {(session.log && session.log.completePercentage !== 0) ? tS('thread.start.1') : tS('thread.start.2')}
                </Button>}

            {(!isActivedSession && !current && !(session.log && session.log.isCompleted)) &&
                <Button
                    main
                    className={cnThread('Btn', { type: 'join' })}
                    theme={'unstyled_center'}
                onClick={() => { joinToSession(sid, cid) }} >{tS('thread.join.1')}</Button>}

            {(isActivedSession && !current && !session.log) && <Button
                theme={'unstyled_center'}
                title={tS('noJoin.title.1')}
                className={cnThread('Btn', { type: 'join' })}>{tS('thread.noJoin.1')}</Button>} */}
        </ButtonBox>
    </div>
}

export const Thread = connect(
    mapStateToProps,
    mapDispatchToProps
)(ThreadPresenter);
