import React, { memo, useCallback, useRef } from 'react';
import { IEditorTypeNewsProps } from './news.index';
import { ReplyType, createDefaultReply } from '../../../Reply.type'
import { Editor } from '../../Editor'
import { baseValidate, BaseValidator } from '../../../Reply.validate'
import { EditorState } from 'draft-js';
// import { createDefaultRating } from 'News/types/reply'

export const EditorTypeNews: React.FC<IEditorTypeNewsProps> = memo(({ onChange, data, types, avatarSize = 40, send }) => {
    let localData = data as ReplyType;
    if (data.thanksInfo) {
        localData = {
            ...createDefaultReply(), 
            text: EditorState.createEmpty()
        }
    }
    const dref = useRef(localData)
    dref.current = localData
    // if (data.newstype !== 5) {
    // localData = utils.cloneObject(defaultReply);
    // } // else localData.uevent.startDateTime = new Date(localData.uevent.startDateTime);

    const onChangeData = useCallback((data: ReplyType) => {
        const vb = new BaseValidator();
        vb.addData(data)
        vb.setRules(baseValidate)
        onChange(data, vb.validation().length === 0)
    }, [])

    const onChangeText = useCallback((text: any) => {
        dref.current = { ...dref.current, text }
        onChangeData(dref.current)
    }, [])


    const onChangeRating = useCallback((event: React.ChangeEvent<{}>, value: number | null) => {
        dref.current = {
            ...dref.current,
            contentRating: value || 0
            // {
            //     enabled: dref.current.contentRating?.enabled || false,
            //     rating: value || 0
            // }
        }
        onChangeData(dref.current)
    }, [])

    return <Editor
        onChangeRating={onChangeRating}
        send={send}
        onChangeText={onChangeText}
        avatarSize={avatarSize}
        data={dref.current} />
})