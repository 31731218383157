import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IStateType as IState } from 'redux/store';
import moment from 'moment';

export interface IEventListProps extends IEventsListStateProps {
  groupId?: string | number;
  workflowId?: string;
}

export const cnEventsList = cn('EventsList');

export type IEventsListStateProps = ReturnType<typeof mapStateToProps>



export const mapStateToProps = (state: IState) =>  {
  const view = state.calendar.currentView
  const date = state.calendar.currentDate
  const data = state.calendar.data
  return {
    data,
    view,
    date
  }
}

export interface IEventActionProps extends ReturnType<typeof mapDispatchToProps> {
  eventStatus: string;
  eventId: string;
  eventState?: string;
  onChanged: (status: string) => void
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

