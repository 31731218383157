import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from "../../../redux/connector";
// import i18n from '../../../localizations/i18n';
import { AbstractInfoItem } from '../AbstarctInfoElement/AbstractInfoElement';
import { mapStateToProps, IBadgeStateProps, cnBadges, IBadgeProps, IBadgeState, cnInfo } from './InfoBlock-Badges.index';
import './InfoBlock-Badges.scss';
import { withElementTypeState } from '../AbstarctInfoElement/_type/InfoBlock-Element_type_state';
import { withElementTypeStore } from '../AbstarctInfoElement/_type/InfoBlock-Element_type_store';
import * as React from 'react';
// import { Button } from 'uielements/src/Button/Button';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { Layout } from '../Layout/InfoBlock-Layout';
import { CacheHelper } from 'utils/src/CacheHelper'
import { Translate } from 'localizations/Translate';
import icon from './avatar.svg';

export class BadgesPresenter extends React.Component<IBadgeProps, IBadgeState> {

  public render() {
    
    return (
      <Layout
        className={cnBadges()}
        left={
          <div className={'InfoBlock-Img'}>
            <Avatar
              className={cnBadges('Img')}
              imgUrl={icon}
              name={'asdasd'}
              size={64}
            />
          </div>
        }
        right={
          <div className={cnBadges('Data')}>
            <div className={cnInfo('DataName')}><Translate i18nKey='pryaniky.title.badges' /></div>
          </div>
        } />
    );
  }
}


export const Badges = connect<IBadgeStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(compose(
  withElementTypeState,
  withElementTypeStore
)(BadgesPresenter))