import { defaultNews } from 'News/redux/reducer/default'
import {TReducerWithThanks} from './reducer.d'
// import { TNewsReducerActions } from 'News/redux/actions/types'
import { TNewsReducerActions } from './actions.d'
import actions from './actionsType'


const thanksDefaultNews: TReducerWithThanks = {
    ...defaultNews,
    thanksReasons: undefined
}

export const thanksSubReducer = (state = thanksDefaultNews, action: TNewsReducerActions) => {
    switch (action.type) {
        case actions.SET_REASONS: {
            return {
                ...state,
                thanksReasons: action.payload
            }
        }
        default:
            return state;
    }
}