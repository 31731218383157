import React, { FC, memo, useCallback, useRef } from 'react'
import {
    cnClassName,
    Shop
} from './interfaces'
import './style.scss'
import { Icon, Spinner } from 'uielements/src'
import BaseRequests from 'utils/src/requests/requests.base'
import {
    // MagnifierZoom,
    // MagnifierPreview,
    // MagnifierContainer,
    SideBySideMagnifier
} from "react-image-magnifiers";

type IPresenterProps = Shop.PhotoViewer.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    currentImgUrl,
    currentImage,
    imgUrls,
    onChange,
    isLoading,
    images
}) => {
    const currentIndex = imgUrls.indexOf(currentImgUrl)
    const isExternal = currentIndex === -1
    const isFirst = currentIndex === 0
    const isLast = currentIndex === imgUrls.length - 1
    const currentFile = currentImage || images[currentIndex] || images[0]

    const imageSrc = currentFile?.previewUrl || imgUrls[0]
    const largeImageSrc = currentFile?.originalUrl || imgUrls[0]

    const onNext = () => {
        onChange(imgUrls[currentIndex + 1], images[currentIndex + 1])
    }

    const onPrev = () => {
        onChange(imgUrls[currentIndex - 1], images[currentIndex - 1])
    }

    const onHover = (currentImgUrl: string, currentImage: any) => () => {
        onChange(currentImgUrl, currentImage)
    }



    return <div className={cnClassName({}, [className])}>
        <div
            // style={{ backgroundImage: `url(${currentImgUrl})` }} 
            className={cnClassName('Slider')}>
            <SideBySideMagnifier
                className={cnClassName('ImageZoom')}
                // style={{ order: switchSides ? "1" : "0" }}
                imageSrc={imageSrc}
                largeImageSrc={largeImageSrc + `?auth=` + BaseRequests.getAuth}
                // alwaysInPlace={alwaysInPlace}
                overlayOpacity={0.5}
                // switchSides={switchSides}
                // zoomPosition="left"
                inPlaceMinBreakpoint={641}
                fillAvailableSpace={true}
                // fillAlignTop={fillAlignTop}
                fillGapTop={55}
                // fillGapRight={fillGapRight}
                // fillGapBottom={fillGapBottom}
                // fillGapLeft={fillGapLeft}
                zoomContainerBorder="1px solid #ccc"
                zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
            />
            {!isExternal && <>
                {!isFirst &&
                    <div
                        onClick={onPrev}
                        className={cnClassName('Slider-Back')}
                    >
                        <Icon icon='angle-left' />
                    </div>}
                {!isLast &&
                    <div
                        onClick={onNext}
                        className={cnClassName('Slider-Forward')}>
                        <Icon icon='angle-right' />
                    </div>}
            </>}
            {isLoading && <Spinner fontSize={64} />}
        </div>

        <div className={cnClassName('Items')}>
            {imgUrls.length && imgUrls.map((img, i) => (
                <div
                    className={img !== currentImgUrl ? cnClassName('Items-ItemNotActive') : ''}
                    onMouseUp={onHover(img, images[i])}
                    key={img}
                    style={{ backgroundImage: `url(${img})`, cursor: 'pointer' }}
                />
            ))}
        </div>
    </div >
}

export default memo(Presenter);
