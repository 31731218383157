import React, { FC, useEffect, useState, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import Tile from './Tile'
import manifestUsers from './Users/manifest'
import manifestCourses from './Courses/manifest'
// import manifestRatings from './Ratings/manifest'
import manifestGame from './Game/manifest'

type IPresenterProps = StatisticsFull.IPresenterProps
type IOwnProps = StatisticsFull.IOwnProps
type IDispatchProps = StatisticsFull.IDispatchProps
type IStateProps = StatisticsFull.IStateProps
type IState = StatisticsFull.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    currentUser
}) => {
    const tiles = [
        manifestUsers,
        manifestCourses,
        // manifestRatings,
        manifestGame
    ]
    if(!currentUser.baseData.isAdmin) return null

    return <div className={cnClassName({}, [className])}>
        {tiles.map((tile) => <Tile key={tile.id} value={tile}/>)}
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));
