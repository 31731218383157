import { SSurveyQuestion } from "utils/src/BaseTypes/survey";
import { GUID_EMPTY } from "utils/src/constants.prn";
import { ISurveyState } from "./interfaces";

export const validateAnswers = (question: SSurveyQuestion, answers: ISurveyState['surveyPassingAnswers']) => {
    if(!question.allowSkip) return true;
    if(!answers[question.id]) return false;
    switch(question.qtype) {
        case 'Slider': 
        case 'Numeric': 
            return answers[question.id].numericAnswer !== null && answers[question.id].numericAnswer !== undefined;
        case 'Text': 
        case 'Time': 
        case 'Date': 
            return !!answers[question.id].text;
        case 'Single':
            if(answers[question.id].isFree) {
                return !!answers[question.id].text;
            }
            return !!answers[question.id].answersIds.length;
        case 'Multi':
            if(answers[question.id].isFree) {
                return !!answers[question.id].text && (answers[question.id].answersIds.length || 0) >= question.minAnswerVariants;
            }
            return (answers[question.id].answersIds.length || 0) >= question.minAnswerVariants;
        case 'Sorting':
            return !!answers[question.id];
        case 'MatrixSingle':
            const answerRows = answers[question.id].rows;
            if(!answerRows) return true;
            return !answerRows.reduce((a, c) => a ? a : c.selectedAnswer === GUID_EMPTY, false);
        default: 
            return !!answers[question.id];
    }
};