import React, { FC, useEffect, memo, useState, useMemo } from 'react'
import { useRefedCallback } from 'utils/src/hooks'
import { ISkill } from 'redux/sagas/Skills/types'

type SkillsItemMethods = {
    confirmSkill: (userId: string, skillId: string, confirmedByMe: boolean) => void;
    deleteSkill: (userId: string, skillId: string) => void
    setShow: (skillId: string) => void
}

export const useSkillsItem = (skill: ISkill, userid: string, { confirmSkill, deleteSkill, setShow }: SkillsItemMethods) => {
    const [del, setDel] = useState(false)

    useEffect(() => {
        setDel(skill.confirmCount === 0)
    }, [skill])

    const skillId = skill.tag.id,
        confirmedByMe = skill.confirmedByMe,
        confirmCount = skill.confirmCount

    const onChangeConfirm = useRefedCallback(({ current }) => () => confirmSkill(current.userid, current.skillId, !current.confirmedByMe),
        { userid, skillId, confirmedByMe });

    const onCancleDelete = useRefedCallback(({ current }) => () => confirmSkill(current.userid, current.skillId, current.confirmCount === 0 ? true : current.confirmedByMe),
        { userid, skillId, confirmedByMe, confirmCount });

    const onDelete = useRefedCallback(({ current }) => () => deleteSkill(current.userid, current.skillId),
        { userid, skillId });

    const actionCallback = useRefedCallback(({ current }) => () => setShow(current.skillId),
        { skillId })

    return {
        del,
        onChangeConfirm,
        onCancleDelete,
        onDelete,
        actionCallback,
        setDel
    }
}
