import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { getOpenQuiz } from 'redux/actions/Quiz'

export interface IQuizWidgetItem extends IQuizWidgetStateProps, IQuizWidgetDispatchProps {
  
}

export const mapStateToProps = (state: IState) => {
  return {
    data: state.quiz.quizWidget.quizzesData[state.quiz.quizWidget.activeQuizIdx],
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getOpenQuiz
}, dispatch);

export type IQuizWidgetStateProps = ReturnType<typeof mapStateToProps>
export type IQuizWidgetDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnQuizWidgetItem = cn('QuizWidgetItem')