import React, { FC } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';
import actions from 'redux/actionsTypes/Groups';
// import * as utils from 'utils/src/utils';


export interface IOwnProps {
    groupPKID: string | number
    pid: keyof typeof actions
}

export interface IStateProps {
    status?: 'success' | 'process' | 'failed' | 'notStarted'
}
export interface IState { }
export type IWithGroupProcessing = IOwnProps & IStateProps

interface ILoadingProps {
    isLoading?: boolean
}
export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: any) => ({
    status: !state.groups.procedding[`${props.groupPKID}_${props.pid}`] ? 'notStarted' : state.groups.procedding[`${props.groupPKID}_${props.pid}`].status
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({

}, dispatch);

function withGroupProcessing<T extends ILoadingProps>(Component: React.ComponentType<T>) {
    const Wrapped: FC<IWithGroupProcessing> = ({ groupPKID, pid, status, ...props }) => {
        return <Component
            {...props as T}
            isLoading={status === 'process'}
        />
    }
    return connect<IStateProps, IDispatchProps, Omit<T, 'isLoading'> & IOwnProps, IState>(mapStateToProps, mapDispatchToProps)(Wrapped)
}



export default withGroupProcessing