import { IClassNameProps } from "@bem-react/core";
import { BoxProps } from "@material-ui/core";
import { Ref } from "react";
import { TColumn } from "utils/src/BaseTypes/widgets.types";
import { TColumnSimplified } from "../types";

export namespace NColumn {

    export interface Props extends IClassNameProps, BoxProps {
        column: TColumnSimplified;
        componentProps?: {[s: string]: any};
        ref?: Ref<any>;
        style?: {[s: string]: string};
    }
}

export namespace NSwipeLayout {

    export interface Props extends IClassNameProps {
        columns: TColumnSimplified[];
    }
}