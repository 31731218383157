import { cn } from '@bem-react/classname'
import { IStateType as IState } from 'redux/store';
import { bindActionCreators } from 'redux'
import { setActiveTab } from 'redux/actions/Search'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions'

export interface ISearchProps extends ISearchStateProps, ISearchDispatchProps{

}


export const mapStateToProps = (state: IState) => { 

  return {
    query: state.searchSettings.query,
    activeTab: state.search.activeTab,
    tabsR: state.search.tabs,
    context: state.widgets.contexts.common,
    networksLength: getAuthUser(state).baseData.networks.length
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  setActiveTab,
}, dispatch);

export type ISearchStateProps = ReturnType<typeof mapStateToProps>
export type ISearchDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnSearch = cn('Search')