/**
 * @packageDocumentation
 * @module Widget_Settings_statistic
 */
import { JFdictionary } from "../dictionary";

const { renderers } = JFdictionary;


export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.statistic,
      scope: "#/properties/data"
    },
  ]
}