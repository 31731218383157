import { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendAnswers as sendAnswersAction } from "redux/sagas/Surveys/actions";
import { getSurveyStateByKey } from "redux/sagas/Surveys/selectors";
import { SurveyPassingBaseProps } from "../SurveyPassing.index";

export const useSurveyPage = (props: SurveyPassingBaseProps) => {

    const dispatch = useDispatch();

    const data = useSelector(getSurveyStateByKey('surveyPassing', 'surveyPassingLoading'));

    const sendAnswers = useCallback((skip?: boolean) => {
        dispatch(sendAnswersAction({ preview: props.preview, skipAll: skip }));
        // with preview
    }, [props.preview]);

    const skipShow = useMemo(() => {
        // includes check for false because there is inverted flag
        return !data.surveyPassing ? true : !data.surveyPassing.questions.map(el => el.allowSkip).includes(true)
    }, [data.surveyPassing]);

    return {
        page: data.surveyPassing,
        loading: data.surveyPassingLoading,
        skipShow,
        sendAnswers
    };
};