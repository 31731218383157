/**
 * @packageDocumentation
 * @module AboutWidget
 */
import React, { FC, useState } from 'react';
import { IAboutProps, mapStateToProps, mapDispatchToProps } from './AboutWidget.index'
import { connect } from 'react-redux';
import { PostContentRender } from 'blocks/PostContentRender/PostContentRender';
import { AboutEdit } from './Edit/Edit'
import { AboutView } from './View/View'
import { Button, Icon } from 'uielements/src'
import './AboutWidget.scss'
import { Translate } from 'localizations/Translate';

export const AboutWidget_: FC<IAboutProps> = ( props ) => {
    const { user, uid, showControls, tag: Tag = 'div' } = props;
    const [edit, setEdit] = useState(false)

    if (!user) return null;
    if (!user.fullData) return null;
    return <Tag {...props} className={'AboutWidget'}>
        <div className={'AboutWidget-Header'}>
            <h4 className={'AboutWidget-Title'}>
                <Translate i18nKey="pryaniky.widgets.create.name.pryaniky/userAbout" />
            </h4>
            {showControls && <div className={'AboutWidget-Action'}>
                {!edit && <Button theme={'unstyled_center'} onClick={() => setEdit(!edit)}><Icon icon={'edit'} /></Button>}
            </div>}
        </div>
        <div className={'AboutWidget-Content'}>
            {edit ? <AboutEdit endEdit={() => setEdit(!edit)} uid={uid} /> : <AboutView uid={uid} />}
        </div>
    </Tag>
}

export const AboutWidget = connect(
    mapStateToProps,
    mapDispatchToProps
)(AboutWidget_)