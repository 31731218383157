import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { Filters } from '../Filters'
import i18n from '../../../localizations/i18n'
import { Selectize, Button, Select } from 'uielements/src'
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index'
import { InputMention } from 'blocks/InputMention/InputMention'
import './Filters_type_ideas.scss'
import * as utils from 'utils/src/utils'
import { } from 'query-string'
import { mapStateToProps, IFiltersStateProps, mapDispatchToProps, IFiltersDispatchProps, IFiltersProps, cnFilter } from './Filters_type_ideas.index'
import { Toggle } from 'uielements/src/Toggle/Toggle'
import { cnFilters } from '../Filters.index'
import { NewsViewSelector } from 'blocks/Timeline/NewsViewSelector/NewsViewSelector'

// const f: any[] = 

const FiltersIdeasPresenter: React.FC<IFiltersProps> = (props) => {

  const Tag = props.tag || 'div'

  const [data, setData] = useState(
    {
      name: i18n.t('pryaniky.filter.ideas.stages'),
      elements: [] as any,
    },
  )

 

  const [inputMention, setInputMention] = useState(
    {
      tags: undefined,
      author: undefined,
      user: undefined
    }
  )

  const [sortData, setSortData] = useState(
    {
      name: '',
      elements: [
        {
          id: '111',
          title: i18n.t('pryaniky.filter.sort.lastcommentdate'),
          type: 'order',
          value: 'lastcommentdate',
          sort: "down",
        },
        {
          id: '222',
          title: i18n.t('pryaniky.filter.sort.publicationdate'),
          type: 'order',
          value: 'publicationdate',
          sort: "down",
        },
        {
          id: '333',
          title: i18n.t('pryaniky.filter.sort.statuschangedate'),
          type: 'order',
          value: 'statuschangedate',
          sort: "down",
        },
        {
          id: '444',
          title: i18n.t('pryaniky.filter.sort.fund'),
          type: 'order',
          value: 'fund',
          sort: "down",
        },
        {
          id: '555',
          title: i18n.t('pryaniky.filter.sort.likescount'),
          type: 'order',
          value: 'likescount',
          sort: "down",
        },
      ],
      sortBy: [
        {
          id: '1',
          type: 'sort',
          title: i18n.t('pryaniky.filter.display.desc'),
          value: 'desc',
        },
        {
          id: '2',
          type: 'sort',
          title: i18n.t('pryaniky.filter.display.asc'),
          value: 'asc',
        },
      ],
    },
  )

  const [stateSelected, setStateSelected] = useState(
    {
      id: '222',
      title: i18n.t('pryaniky.filter.sort.publicationdate'),
      type: 'order',
      value: 'publicationdate',
    })

  const contextVariables = props.relations || ['common']

  const handleChangeSelect = (selected: ISelectizeItem) => {
    const { filter } = props.context
    const same = filter && filter.id === selected.id;
    props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { filter: !same ? selected : undefined, [selected.type]: !same ? selected.value : undefined } 
      return acc
    }, {} as { [s: string]: any }))
  }

  const checkedHandler = (type: 'moderation' | 'invested', data: boolean) => {
    props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { [type]: !data }
      return acc
    }, {} as { [s: string]: any }))
  }

  const handleChangeInputMention = (type: string, data: any) => {  
   
    let dataIds: any

    switch (type) {
      case 'tagIds':
        setInputMention({
          ...inputMention,
          tags: data
        })
        dataIds = data && data.map((item: any) => item.id)
        break
      case 'userIds':
        inputMention.user = data
        setInputMention({
          ...inputMention,
          user: data
        })
        dataIds = data && data.id
        break
      case 'authorIds':
        setInputMention({
          ...inputMention,
          author: data
        })
        dataIds = data && data.id
      default:
        break
    }

    props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { [type]: dataIds }
      return acc
    }, {} as { [s: string]: any }))
  }
  const setSort = (selected:any) => {
    setSortData({...sortData, elements:sortData.elements.map((el) => el.id===selected.id ? {...el, sort: selected.sort === 'down'?'up':'down'} : el )})
    
  }

  const changeSort = (selected: any)=>{
    setSort(selected)
    props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { sort: selected.sort === 'down' ?  'asc':'desc'}
      return acc
    }, {} as { [s: string]: any }))
  }
  const handleChangeSort = (selected: any) => {
    setStateSelected(selected)
    props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { [selected.type]: selected.value, ...(selected.sort ? { sort: selected.sort === 'up' ? 'asc':'desc'} : {}) }
      return acc
    }, {} as { [s: string]: any }))
  }

  // const handleChangeDropdown = (selected: any) => {
  
  //   setDropdown(selected)
  //   props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
  //     acc[conID] = { sort: selected.value }
  //     return acc
  //   }, {} as { [s: string]: any }))
  // }

  useEffect(() => {
    utils.API.news.ideas.getIdeasFilters()
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          
          const res = d.data.settings.statuses.map((el: any) => ({ id: el.id, title: el.name, type: 'status', value: el.id }));
          // d.data.settings.statuses.map((el: any) => ({ id: el.id, title: el.name, type: 'status', value: el.id }))
          setData({
            ...data,
            elements: [{ id: 'all', title: i18n.t('pryaniky.filter.ideas.stages.all'), type: 'status', value: undefined }, ...res]
           
          })
        } 
      })
  }, [])

  return (
    <>
      {/* 
  // @ts-ignore */}
      <Tag data={sortData} tag='div' context={'creativetasks'} relations={[]} {...props} className={cnFilters({}, [props.className])} >
        <div className='BottomLine'><NewsViewSelector /></div>
        <h3 className={cnFilter('TitleIdeas', ['BottomLine'])}>{i18n.t('pryaniky.filter.sort.title')}</h3>
        <Selectize onSortClick={changeSort}  onClick={handleChangeSort} active={stateSelected.id} name={sortData.name} elements={sortData.elements || []}  ></Selectize>

        {/* <h4 className={cnFilter('Title')}>{i18n.t('pryaniky.filter.display.title')}</h4>
        <Select
          className={cnFilter('Select')}
          selected={dropdown}
          options={sortData.sortBy}
          onChange={handleChangeDropdown}
        /> */}
       
      </Tag>
      {/* 
  // @ts-ignore */}
      <Tag data={data} tag='div' context={'ideas'} relations={[]} {...props} className={cnFilters({}, [props.className])} >
        <h3 className={cnFilter('TitleIdeas', ['BottomLine'])}>{i18n.t('pryaniky.filter.ideas.title')}</h3>

        <Selectize onClick={handleChangeSelect} active={props.context && props.context.filter ? props.context.filter.id : undefined} name={data.name} elements={data.elements || []} />


        <h4 className={cnFilter('Subtitle')}>{i18n.t('pryaniky.filter.tags')}</h4>
        <InputMention className={cnFilter('Tags', ['BottomLine'])} type='tags' additionalRequestOpts={{col:"ideas"}} placeholder={i18n.t('pryaniky.filter.tags.placeholder')} noMulti={false} onChange={handleChangeInputMention.bind(null, 'tagIds')} value={inputMention.tags} />

        <h4 className={cnFilter('Subtitle')}>{i18n.t('pryaniky.filter.ideas.responsible')}</h4>
        <InputMention className='BottomLine' type='users' icon={'user-group'} placeholder={i18n.t('pryaniky.filter.colleague')} noMulti={true} onChange={handleChangeInputMention.bind(null, 'userIds')} value={inputMention.user} />

        <h4 className={cnFilter('Subtitle')}>{i18n.t('pryaniky.filter.ideas.author')}</h4>
        <InputMention className='BottomLine' type='users' icon={'user-group'} placeholder={i18n.t('pryaniky.filter.colleague')} noMulti={true} onChange={handleChangeInputMention.bind(null, 'authorIds')} value={inputMention.author} />

        <div className={cnFilter('Toggle')}>
          <h4>{i18n.t('pryaniky.filter.supported')}</h4>
          <Toggle checked={props.context.invested} onChange={() => checkedHandler('invested', props.context.invested)} />
        </div>

        <div className={cnFilter('Toggle')}>
          <h4>{i18n.t('pryaniky.filter.moderation')}</h4>
          <Toggle checked={props.context.moderation} onChange={() => checkedHandler('moderation', props.context.moderation)} />
        </div>

      </Tag>
    </>
  )
}

export const FiltersIdeas = connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersProps>(
  mapStateToProps,
  mapDispatchToProps
)(FiltersIdeasPresenter)
