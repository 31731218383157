import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
import * as ACT from '../../../actions';
import * as SEL from '../../selectors'
import i18n from 'localizations/i18n';
import { toast } from 'react-toastify';
import { confirmProise } from 'uielements/src/Confirm/Confirm'
import { API } from 'utils/src/utils'

const handleContextActionRemovePreview = function* handleContextActionRemovePreview({ payload }: AT.AContextAction): any {
    const { action, id } = payload
    if (action === 'removePreview') {
        try {
            const request = yield* call(API.news.action, id, 4, '')
            // @ts-ignore
            const result = yield request.r
            if (result.error_code === 0) {
                yield* put(ACT.pathNews({
                    id,
                    linkPreviewImgUrl: null,
                    systemNewsActionHtml: ""
                }))
                toast.success(result.error_text);
            }
            else toast.error(result.error_text);
        } catch (error) {
            return error;
        }
    }
    yield;

    return 0;
}

export default handleContextActionRemovePreview