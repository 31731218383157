import React from 'react'
import { IReactionsProps, cnReactions, IReactionsItem } from './Reactions.index'
import { ReactionsItem } from './ReactionsItem/ReactionsItem'

import './Reactions.scss'

import like from 'assets/reactions/like.png'
import love from 'assets/reactions/love.png'
import haha from 'assets/reactions/haha.png'
import wow from 'assets/reactions/wow.png'
import sad from 'assets/reactions/sad.png'
import angry from 'assets/reactions/angry.png'

const imgItems: IReactionsItem[]  = [
    { image: like, action: 'like' , count: 0, selected: false, label: 'Понравилось' },
    { image: love, action: 'love', count: 0, selected: false, label: 'Любовь' },
    { image: haha, action: 'haha', count: 0, selected: true, label: 'Смех' },
    { image: wow, action: 'wow', count: 0, selected: false, label: 'Вау' },
    { image: sad, action: 'sad', count: 0, selected: false, label: 'Грусть' },
    { image: angry, action: 'angry', count: 0, selected: false, label: 'Злость' },
]

export const Reactions = ({items = imgItems, label}: IReactionsProps) => {

    const handleClick = (args: any) => {}
    

    return (
        <div className={cnReactions()}>
            {items.map(item => {
                return <ReactionsItem item={item} onSelect={handleClick}/>
            })}
        </div>
    )
}