import React, { FC, memo } from 'react'
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { orderIsDenied } from '../validation'
import i18n from 'localizations/i18n';
import * as utilsProj from 'utils.project/utils.project';
import { countMissingThanks } from '../utils'
import { Shop } from './OrderBlock.index';
import { WrapBox } from './OrderBlock.styled';
import { OrderBlockButton } from './OrderBlockButton';

type IOrderBlockProps = Shop.OrderBlock.IPresenterProps


const OrderBlock: FC<IOrderBlockProps> = ({
    children,
    disableOrder,
    characteristics,
    maxOrdersCount,
    maxOrdersPeriod,
    ordersCount,
    thanksPrice,
    myThanksCount,
    id,
    showDialog,
    currencyFormats,
    productIsAvailable,
    isGift,
}) => {

    const isNotCanBuy = orderIsDenied({ characteristics, maxOrdersCount, maxOrdersPeriod, ordersCount })
    const missingThanks = countMissingThanks(thanksPrice, myThanksCount)

    if (!productIsAvailable) {
        return <OrderBlockButton text={i18n.t("pryaniky.shop.product.isOutOfStock")}
            id={id}
            variant='text'
            disabled />
    }

    return <WrapBox>

        {disableOrder ? <>
            {missingThanks > 0 ? (
                <OrderBlockButton id={id} errorColor={true} variant='outlined'
                    disabled
                    text={i18n.t('missing') + ' ' + missingThanks + ' ' + utilsProj.getFormattedCurrencyName(currencyFormats, missingThanks, 'genitive')}
                />
            )
                :
                <Tooltip title={isNotCanBuy || ''} >
                    <OrderBlockButton text={i18n.t('pryaniky.shop.order')} id={id}
                        disabled={Boolean(isNotCanBuy)}
                        cb={showDialog} />
                </Tooltip>
            }
        </>
            :
            <>
                {/* {isGift && <OrderBlockButton variant='outlined' text={i18n.t('present')} id={id} />} */}
                <OrderBlockButton text={i18n.t('pryaniky.shop.item.open')} id={id} />
            </>
        }

    </WrapBox>
}

export default memo(OrderBlock);
