import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    IPresenterProps
} from './interfaces'
import './style.scss'
import { Header } from '../Header/Header'
import BodyRenderer from '../TestResultBody'
import { tC } from 'LMSModule/utils/i18n.adaptor'

export const Presenter: FC<IPresenterProps> = ({
    className,
    unitTimer,
    unitContent,
    sId,
    unit,
    unitResult,
    disableActions = false,
    detailsRender,
    isAbsolutelyCompleted = false,
    attempt,
    cangeAttempt,
    fullscreen = false,
    actions
}) => {
    const remainingTime = unitTimer && new Date(unitTimer.completeTime - unitTimer.startTime)

    return <div className={cnClassName({ fullscreen })}>
        <Header
            className={cnClassName('Header')}
            attemptNumber={unitResult.results.length}
            maxAttemptsCount={unit.maxAttemptsCount}
            remainingTime={remainingTime} />

        {unitResult.results.length === 0 ?
            <h3 className={cnClassName('NotStarted')}>{tC('notResults.1')}</h3> :
            <BodyRenderer {...{ unitResult, sid: sId, unit, unitContent, detailsRender, attempt, cangeAttempt, isAbsolutelyCompleted }} />}

        {(!disableActions) && actions}
    </div>
}

export default memo(Presenter);
