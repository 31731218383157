import { Reducer, AnyAction } from 'redux';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import { vacanciesReducer } from './reducer.vacancies';

const moduleName = 'vacancies';

const reducerMap = { [moduleName]: vacanciesReducer };

type VacanciesSettingsModule = {[key in keyof typeof reducerMap ]: ReturnType<typeof reducerMap[key]> };

export function getVacanciesSettingsModule(): ISagaModule<VacanciesSettingsModule>  {
    return {
        id: moduleName,
        reducerMap: reducerMap as {[s in keyof VacanciesSettingsModule]: Reducer<VacanciesSettingsModule[s], AnyAction>},
        sagas: [

        ],
    };
}