import React, { FC } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box, styled } from 'muicomponents/src';
import Stack from '@mui/material/Stack';

const Layout = styled(Box)({
    padding: '16px',
    marginBottom: '16px',
}) as typeof Box


const DataBlockSkeleton:FC = () => {
    return <Layout>
            <Stack spacing={1}>
                <Skeleton variant="text" height={42} />
                <Skeleton variant="text" height={24} />
                <Skeleton variant="text" height={24} />
                <Skeleton variant="text" height={24} />
                <Skeleton variant="text" height={24} />
                <Skeleton variant="text" height={24} />
            </Stack>
        </Layout>
};

export default DataBlockSkeleton;


