import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
// import { IMoodData } from 'utils/src/requests/models/api.tagscloud';
import { IStateType } from 'redux/store';



export interface IMoodProps extends IClassNameProps, IMoodOwnProps {}


export interface IMoodOwnProps {
  tag?: React.ComponentType | 'div';
  context: any;
  id: string;
  edit: boolean;
}

export const cnMood = cn('Mood');
export const cnMoodEdit = cn('MoodEdit');