import { IEditorProps } from './Editor.index'
import * as React from 'react'
import './Editor.scss';
import { TextareaMentionsEditor as TextareaMentions } from '../../../TextareaMentions/TextareaMentions'
import { Input } from 'uielements/src/Input/Input';
import { CheckboxInput} from 'uielements/src/CheckboxInput/CheckboxInput'
import i18n from '../../../../localizations/i18n';
import { AdditionalFieldsRender } from '../AdditionalFieldsRender/AdditionalFieldsRender';
import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'
import { input } from 'blocks/JsonFormsRenderers/Controls/Input';
import { InputMention } from 'blocks/InputMention/InputMention';
import { cn } from "@bem-react/classname";


export const cnEditorField = cn("EditorField");

export const Editor: React.FC<IEditorProps> = ({ children, data, normalFields, onChangeFields, onChangeText, onChangeTitle, onChangeAnonimous, onChangeTags }) => {
    return <div className={'Editor EditorIdeas'}>
        <Input
            onChange={onChangeTitle}
            className={'EditorIdeas-Title'}
            value={data.header}
            icon='bell'
            placeholder={i18n.t('pryaniky.createPost.idea.name')} />

        <PryanikyEditor
            onChange={onChangeText}
            value={data.text}
            disableToolbar={true}
            placeholder={i18n.t('pryaniky.createPost.idea.description')} 
            />
        {
            
            data.allowAnonimous && onChangeAnonimous &&
            <CheckboxInput
                className={cnEditorField("isAnonimus")}
                text={i18n.t("pryaniky.createPost.idea.isAnonimous")}
                checked={data.idea && data.idea.isAnonimous  || false}
                onChange={onChangeAnonimous}
            />
        }
        {/* {
            onChangeTags &&
            <InputMention 
                className={cnEditorField("Input")}
                type={'tags'}
                icon={'hash-tag'}
                value={data.tags}
                placeholder={"Выберите тег новости"}
                onChange={onChangeTags}
            />
        } */}
        <AdditionalFieldsRender data={normalFields} onChange={onChangeFields} />
        {children}

    </div>
}