import React, { useState, useRef, useEffect, useCallback } from 'react'
import { IEditorTypeNewsProps } from './news.index'
import { defaultWorkflowsNews } from '../../../Workflows.type'
import { baseValidate } from '../../../../Base.validate'
import { Editor } from '../../Editor'
import * as utils from 'utils/src/utils'
import { EditorState } from 'draft-js'
import { IField } from 'uielements/src/FieldRender/interfaces'
import {
    prepareFromServer
} from 'uielements/src/FieldRender'

import { WorkflowsValidator, workflowsValidate, workflowsHeaderValidate } from '../../../Workflows.validate'

export const EditorTypeNews: React.FC<IEditorTypeNewsProps> = ({ onChange, data, types }) => {

    const [autoShort, setAutoShort] = useState(true)
    const autoShortRef = useRef(autoShort)
    autoShortRef.current = autoShort
    let localData = data as any;
    
    useEffect(() => {
        localData = {
            ...utils.cloneObject(defaultWorkflowsNews),
            newstype: types.idNumber,
            header: types.displayName,
            // text: EditorState.createEmpty(),
            additionalFields: {
                additionalFieldsValues: prepareFromServer(types.additionalFields?.reduce((acc: any, category: any) => ([...acc, { ...category, fields: category.fields.filter((field: any) => !field.defaultFieldName) }]), []))
            }
        };
        onChange(localData, false);

    }, [types.idNumber !== data.newstype])
    

    const onChangeText = (text: string) => {
        // localData.text = text;
        // const bv = new WorkflowsValidator() 
        // bv.setRules(baseValidate)
        // bv.addData(localData)
        // onChange(localData, bv.validation().length === 0);
    }

    const onChangeHeader = (text: string) => {
        localData.header = text;
        const bv = new WorkflowsValidator()
        bv.setRules(workflowsHeaderValidate)
        bv.addData(localData)
        onChange(localData, bv.validation().length === 0);
    }

    const onChangeMain = useCallback((text: string) => {
        onChangeText(text)
    }, [localData])



    const onChangeDraft = (fields: IField.AdditionalField[]) => {
        localData.additionalFields.additionalFieldsValues = fields

        const bv = new WorkflowsValidator();
        bv.setRules(workflowsValidate);
        bv.addData(localData)
        onChange(localData, bv.validation().length === 0);
    }

    
    if (types.idNumber !== data.newstype) return null
    
    return <Editor
        postType={types}
        key={types.idNumber}
        additionalFields={localData.additionalFields.additionalFieldsValues}
        changeAdditional={onChangeDraft}
        allowRichEdit={types.newsRenderParams?.allowRichEdit}
        onChangeText={onChangeMain}
        onChangeHeader={onChangeHeader}
        autoShort={autoShort}
        setAutoShort={setAutoShort}
        data={localData}
    />
}