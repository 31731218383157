import Component, { Presenter } from './component';
import {
    ISubscribeUserOwnProps,
    ISubscribeUserPresenterProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = ISubscribeUserOwnProps;
export type IComponentPresenterProps = ISubscribeUserPresenterProps;


export default Component