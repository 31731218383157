import Component, { Presenter } from './component';
import {
    IAddNewsToTrackOwnProps,
    IAddNewsToTrackPresenterProps,
    cnClassName,
    IAddNewsToTrackResult as ResultType
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = IAddNewsToTrackOwnProps;
export type IComponentPresenterProps = IAddNewsToTrackPresenterProps;
export type IAddNewsToTrackResult = ResultType

export default Component