import { NewsCreateContext } from "News/creator/Creator/Creator.constants";
import { useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllowPostTypeById } from "redux/sagas/AppSettings/selectors";
import { getCurrentUserIsAdmin } from "utils/src/CommonRedux/base/selectors";
import { newsCreatorItemNewsChange } from "../../redux/actions.newsCreator";
import { getNewsCreateItemFields, getNewsCreateItemNewsFields } from "../../redux/selectors.newsCreate";
import { NewsCreateReducer } from "../../redux/types.newsCreator";
import { NBadges } from './Badges.interface';

export const useBadgesEdit = (id: keyof NewsCreateReducer) => {

    const dispatch = useDispatch();

    const { groupId, workflowId } = useContext(NewsCreateContext);

    const data = useSelector(getNewsCreateItemNewsFields(['text', 'users', 'badge', 'groups', 'draftRawState'])(id));

    const creatorFields = useSelector(getNewsCreateItemFields(['errors', 'clearEditor', 'selectedNews'])(id));

    const badgesParams = useSelector(getAllowPostTypeById(creatorFields.selectedNews || '', groupId, workflowId))?.renderParams as NBadges.RenderParams | undefined;

    const isAdmin = useSelector(getCurrentUserIsAdmin);

    const changeNews = useCallback((news: Partial<typeof data>) => {
        dispatch(newsCreatorItemNewsChange({ id, news }));
    }, []);

    return {
        data,
        isAdmin,
        badgesParams,
        ...creatorFields,
        changeNews
    };
};