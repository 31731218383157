export const SET_SURVEY = 'SET_SURVEY';
export const GET_SURVEY = 'GET_SURVEY';
export const CHANGE_ACTIVE_SURVEY_IDX = 'CHANGE_ACTIVE_SURVEY_IDX';
export const SET_OPEN_SURVEY = 'SET_OPEN_SURVEY';
export const GET_OPEN_SURVEY = 'GET_OPEN_SURVEY';
export const SET_STARTED_SURVEY = 'SET_STARTED_SURVEY';
export const SET_ACTIVE_QUESTION = 'SET_ACTIVE_QUESTION';
// export const NEXT_ACTIVE_QUESTION = 'NEXT_ACTIVE_QUESTION';
export const SET_ANSWER_ID = 'SET_ANSWER_ID';
export const SET_OTHER_ANSWER_ID = 'SET_OTHER_ANSWER_ID';
// export const SET_ANSWER_TEXT_ID = 'SET_ANSWER_TEXT_ID';
export const SET_IS_ANSWERED = 'SET_IS_ANSWERED';
export const SET_FINISHED_SURVEY = 'SET_FINISHED_SURVEY';
export const SET_RIGHT_ANSWER_COUNT = 'SET_RIGHT_ANSWER_COUNT';
export const RESET_SURVEY = 'RESET_SURVEY';
export const SET_LOADING_SURVEY = 'SET_LOADING_SURVEY';
export const SET_USER_RESULT = 'SET_USER_RESULT';
export const SET_IS_SHOW_RESULT = 'SET_IS_SHOW_RESULT';
export const SEND_ANSWER = 'SEND_ANSWER';
export const IS_LOADING_SURVEYS = 'IS_LOADING_SURVEYS';
export const SURVEY_SET_SURVEYS_PASSING_ANSWERS_IDS = 'SURVEY_SET_SURVEYS_PASSING_ANSWERS_IDS';
export const SURVEY_SET_SURVEYS_PASSING_RESULTS = 'SURVEY_SET_SURVEYS_PASSING_RESULTS';
export const SET_ROW_ANSWERS = 'SET_ROW_ANSWERS';



export const SURVEY_SET_STATE_BY_KEY = 'SURVEY_SET_STATE_BY_KEY';
export const SURVEY_CHANGE_STATE_BY_KEY = 'SURVEY_CHANGE_STATE_BY_KEY';

export const SURVEY_START = 'SURVEY_START';
export const SURVEY_SET_ANSWER = 'SURVEY_SET_ANSWER';
export const SURVEY_SEND_ANSWERS = 'SURVEY_SEND_ANSWERS';
