import Component, { SkillsListItem } from './SkillsListItem';
import {
    SkillsListItemInterface,
    cnClassName,
} from './SkillsListItem.interface';


export {
    cnClassName,
    SkillsListItem
}

export type IComponentProps = SkillsListItemInterface.IOwnProps;
export type IComponentPresenterProps = SkillsListItemInterface.IPresenterProps;


export default Component