import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    IPresenterProps,
    ITabsType
} from './interfaces'
import './style.scss'
import { Button } from 'uielements/src'

export const Presenter: FC<IPresenterProps> = ({
    className,
    onChange,
    selected,
    tabs
}) => {
    const selectTab = useCallback((val: ITabsType) => () => onChange(val), [])
    return <div className={cnClassName({}, [className])}>
        {tabs.map(tab =>
            <Button
                className={cnClassName('TabBtn', { selected: tab.value === selected.value })}
                theme={'unstyled_center'} onClick={selectTab(tab)}>{tab.content}</Button>)}
    </div >
}

export default memo(Presenter);
