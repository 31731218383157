import React, { FC, ReactNode } from 'react'
import { IButtonProps } from 'uielements/src/Button/Button.index'
import { Tooltip } from 'uielements/src/Tooltip/Tooltip'

interface IWithHelp {
    help?: ReactNode;
    helpHtml?: any;
    debug?: boolean;

    showDelay?: number;
    hideDelay?: number;

    disableHelp?: boolean;

    tooltipClassName?: string;
    tooltipNoPadding?: boolean;

    manual?: boolean;
    manualOpen?: boolean;
}

function withHelpTooltip<T extends IButtonProps>(Component: React.ComponentType<T>): FC<IWithHelp & T> {
    return ({
        help,
        helpHtml,
        debug = false,
        showDelay = 0,
        hideDelay = 0,
        children,
        disableHelp = false,
        tooltipNoPadding,
        tooltipClassName,
        manual = false,
        manualOpen = false,
        ...props
    }) => {
        const [showPopup, setShowPopup] = React.useState(false);
        const anchor = React.useRef<HTMLElement>(null);
        let showTid: any = null;
        let hideTid: any = null;
        const onMouseEnter = (event: React.PointerEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            props.onMouseEnter && props.onMouseEnter(event)
            if (hideTid) {
                clearTimeout(hideTid)
                hideTid = null
            }
            if (!showTid) showTid = setTimeout(() => {
                setShowPopup(true)
                showTid = null
            }, showDelay);
        }

        const onMouseLeave = (event: React.PointerEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            props.onMouseLeave && props.onMouseLeave(event)
            if (showTid) {
                clearTimeout(showTid)
                showTid = null
            }
            if (!hideTid) hideTid = setTimeout(() => {
                setShowPopup(debug)
                hideTid = null
            }, hideDelay);

        }
        return <Component
            {...props as T}
            forwardedRef={anchor}
            onPointerEnter={!manual && onMouseEnter}
            onPointerLeave={!manual && onMouseLeave}
        >
            {children}
            {(manual ? manualOpen : (showPopup && (help || helpHtml) && !disableHelp)) && <Tooltip className={tooltipClassName} noPadding={tooltipNoPadding} anchor={anchor}>
                {help}
                {helpHtml}
            </Tooltip>}
        </Component>
    }
}
export default withHelpTooltip