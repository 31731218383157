import { i18n } from 'localization';
import { Box, Divider, Typography } from 'muicomponents/src';
import React, { FC, ComponentProps } from 'react';
import { cnShopProduct } from '../ProductCardMui/ProductCardMui.index';

interface ITotalElemProps extends ComponentProps<typeof Box> {
  price: number | string
  priceCurrencyText: string
}

export const TotalElem: FC<ITotalElemProps> = ({ price, priceCurrencyText, className, ...props }: ITotalElemProps) => {
  return <Box {...props} className={cnShopProduct("Info-Total-Prise", [className])} >
    <Typography variant='subtitle2' fontWeight='500' >{i18n.t('pryaniky.shop.total')}:&nbsp;</Typography>
    <Typography variant='subtitle2' fontWeight='600'>{price} {priceCurrencyText}</Typography>
  </Box>
}