import { IUsersWidgetProps } from '../UsersWidget.index';

import { IDispatchProps } from 'redux/connector';

export interface IUsersWidgetTypeSubscriptionsProps extends IUsersWidgetProps, IUsersWidgetStateProps, IDispatchProps {
  tag?: React.ComponentType | 'div';
}

export interface IUsersWidgetTypeSubscriptionsState {
  text?: string;
}

export interface IUsersWidgetStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})