import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps } from "../../redux/connector";
// import i18n from '../../localizations/i18n';
import { widgets } from "i.widgets";
import initFunctions from 'pIntegratingFunctions';
import * as widgetsUtils from "widgetsUtils";

import { cnWidgets, IWidgetsProps, IWidgetsState, mapStateToProps, mapActionsToPropsPage } from './Widgets.index';
// import { IWidgetProps } from '../Widget/Widget.index';

import './Widgets.scss';
import { IWidget } from "i.widgets";
import { basename } from 'utils/src/utils';
import { map } from 'react-sortable-tree';
import { pageNames } from 'redux/pageNames';

// when rendering these pages component wouldn't ask server for widgets
// const ignoredPages = ['groups', 'users', 'badge', 'tag', 'news', 'rating', 'shop', 'orders']

declare global {
  interface Window {
    pryanikyApi?: any;
    PryanikyRpc: any;
    Pryaniky: any;
    PryanikyMessage: any;
    PryanikyMessages: any;
    pryaniky_integrate_id: string;
    pryaniky_integrate_widget: {
      id: string;
      win: Window
    }
  }
}

export class WidgetsPresenter extends React.Component<IWidgetsProps, IWidgetsState> {
  public el: HTMLElement | null;
  private papiint: any;
  private widgetint: any;

  constructor(props: IWidgetsProps) {
    super(props);
    this.state = {};
    const iframe_id = this.urlSearchObj.frame_id;
    window.pryaniky_integrate_id = iframe_id;
    const script = document.createElement('script');
    script.setAttribute('src', basename + '/PryanikyRpc.js');
    // const scriptv2 = document.createElement('script');
    // scriptv2.setAttribute('src', basename + '/integration.js');
    document.head.append(script);
    // document.head.append(scriptv2); 
    this.papiint = setInterval(() => {
      if (this.pryanikyApi) {
        this.checkHeight();
        this.widgetint = setInterval(this.checkHeight, 100);
        if (this.papiint) {
          clearInterval(this.papiint);
          this.papiint = undefined;
        }
      }
    }, 10);
    initFunctions();
  }

  public get pryanikyApi() {
    if (window.pryanikyApi && !window.pryanikyApi.INITED) {
      window.pryanikyApi.initWidget();
      window.pryanikyApi.INITED = true;
    }
    return window.pryanikyApi;
  }

  public checkHeight = () => {
    if (!(document.getElementById('Layout') as HTMLElement)) return ;
    const layoutHeight = (document.getElementById('Layout') as HTMLElement).clientHeight;
    const bodyHeight = document.body.clientHeight;
    if (this.pryanikyApi.remote && layoutHeight > bodyHeight) this.pryanikyApi.remote.setWidgetSize(0, layoutHeight);
  }

  public componentDidMount() {
    this.props.updateContext('common', {
      ...this.urlSearchObj,
      integrated: true
    }
    );
    this.getWidget();
  }

  private get urlSearchObj() {
    return this.props.location.search.substring(1).split('&').reduce((acc, cur) => {
      const cur_data = cur.split('=');
      acc[cur_data[0]] = cur_data[1];
      return acc;
    }, {} as any)
  }

  public componentWillUnmount() {
    clearInterval(this.widgetint);
  }

  public render() {
    // let layout = undefined;
    const { tag: TagName = 'div', children, className = '', layout } = this.props;
    const { id, type, subtype } = this.props.match.params;
    // if (structure) layout = structure.layout;
    const Component = widgets.components.layout;
    return (
      <TagName ref={(el: any) => this.el = el} className={cnWidgets({ type, id, design: this.urlSearchObj.design }, [className])}>
        {
          layout &&
          <Component key={layout.id} data-id={layout.id} type={layout.type.split('/')[1]} />
        }
        {children}
      </TagName>
    );
  }

  private getWidget() {
    const { getClientStructure } = this.props;
    const { id, subtype } = this.props.match.params;
    let { type } = this.props.match.params;
    if (type === 'virtualcurrency') type = 'virtcurrency';
    getClientStructure(pageNames.widgets + (id ? '/' + id : ''), { subtype, type });
  }

}

export const Widgets = connect(
  mapStateToProps,
  mapActionsToPropsPage
)(WidgetsPresenter)
