import React, { FC, useEffect, useState } from 'react'
import { cnUnits, mapDispatchToProps, mapStateToProps } from './Units.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Units.scss'
import TaskSelector from '../../../../components/TaskSelector'
import { objectArrayToMap } from 'utils/src/utils'
import { createResultNavItems } from 'LMSModule/utils/common.utils'
import {  } from 'LMSModule/utils/result.utils'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

export const UnitsPresenter: FC<any> = ({ className, active, units = [], currentFilters, userId, results, cid, sid, changeLocation }) => {
    const elements = createResultNavItems(units/*.filter((val: any) => val.unitType === 'test')*/.map((v: any) => v),
        objectArrayToMap(results, 'unitId'))


    const href = (unit: any) => changeLocation(`?tab=student&sid=${sid}&uid=${userId}&unit=${unit.id}`)
    return <div className={cnUnits({}, [className])}>
        <h4 className={cnUnits('Title')}>{tT('student_results.filters.units.1')}</h4>
        <TaskSelector
            className={cnUnits('UnitSelector')}
            onSelect={href}
            units={elements}
            active={[active]}
        />
    </div>
}

export const Units = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UnitsPresenter));
