import { Translate } from 'localizations/Translate';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'uielements/src';
import i18n from 'localizations/i18n';
import { cnNewsViewSelector, INewsViewSelectorProps, mapNewsViewSelectorStateToProps, mapNewsViewSelectorActionsToProps } from './NewsViewSelector.index';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { CustomSettings } from 'utils/src/CustomSettings';

import './NewsViewSelector.scss'
const NewsViewSelectorPresenter: React.FC<INewsViewSelectorProps> = ({ viewType, highlight = true, children, changeField, onChange = () => { } }) => {

  const setFullView = () => {
    changeField({
      field: 'viewType',
      value: 'full'
    })
  }

  React.useEffect(() => {
    if(CustomSettings.disableSetShortFilter()) return setFullView;
    changeField({
      field: 'viewType',
      value: 'full'
    })
    return setFullView;
  }, []);

  return (
    <div className={cnNewsViewSelector()}>
      <h4><Translate i18nKey={'pryaniky.news.view.type.title'} /></h4>
      <div>

        <Button main={highlight && viewType === 'full'} onClick={() => {
          onChange()
          changeField({
            field: 'viewType',
            value: 'full'
          })
        }}>
          <Tooltip
            className={'InfoIcon'}
            title={i18n.t('feed')}
          >
            <span><Icon icon={'wiki'}></Icon></span>
          </Tooltip>
        </Button>


          {/* <Button main={highlight && viewType === 'short'} onClick={() => {
            onChange()
            changeField({
              field: 'viewType',
              value: 'short'
            })
          }}>
             <Tooltip
            className={'InfoIcon'}
            title={i18n.t('list')}
          >
            <span><Icon icon={'bars'}></Icon></span>
            </Tooltip>
          </Button> */}

        {children}
      </div>
    </div>
  )
}

export const NewsViewSelector = connect(
  mapNewsViewSelectorStateToProps,
  mapNewsViewSelectorActionsToProps
)(NewsViewSelectorPresenter)