import { connect } from 'react-redux'
import React from 'react'
import { mapDispatchToProps, IDispatchProps } from 'redux/connector'
import { Page } from 'blocks/Page/Page'
import i18n from 'localizations/i18n'
import { setContext } from 'redux/actions/Widgets'
import { pageNames } from 'redux/pageNames'

interface IQuestsPageProps extends IActionsDispatch, IDispatchProps { }
interface IActionsDispatch {
  setContext: any
}

export const QuestsPagePresenter: React.FC<IQuestsPageProps> = props => {

  return <Page page={{ name: pageNames.quests, title: i18n.t('quests'), }} />
}

export const QuestsPage = connect(
  null,
  mapDispatchToProps({ setContext })
)(QuestsPagePresenter)

