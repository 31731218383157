import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
// import { ICourse } from '../mock';
import { loadCategories } from "../../redux/actions/LMS";

export interface ICategoriesProps extends IClassNameProps, ICategoriesStateProps, IDispatchProps, ICategoriesDispatchProps {
    tag?: 'div';
    activeId?: string[]
}

export interface ICategoriesState {

}

export interface ICategoriesStateProps {
    categories: any;
    cIds: string[]
}

export const mapStateToProps = (state: any) => ({
    categories: state.LMS.categories.entities.values,
    cIds: state.LMS.categories.result,
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadCategories,
}, dispatch);

export type ICategoriesDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnCategories = cn('Categories');