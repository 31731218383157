import Component, { Presenter } from './component';
import {
    IListPlaceholderOwnProps,
    IListPlaceholderProps,
    cnClassName
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = IListPlaceholderOwnProps;
export type IComponentPresenterProps = IListPlaceholderProps;


export default Component