import { Box, Typography } from 'muicomponents/src'
import { Link } from 'muicomponents/src/Link/Link'
import { styled } from 'muicomponents/src/mui/system';
import { PageCollectionSuggester as PageCollectionSuggesterMui, BaseSuggester as BaseSuggesterMui } from 'muicomponents/src/Suggester';
import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';


export const ContentBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "8px"
})
export const TypographyText = styled(Typography)({
    color: "#424242F",
    width: "49px",
    lineHeight: "14px",
    marginRight: "5px"
})
export const TypographyCurrency = styled(Typography)({
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)"
})
