import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
    SET_READED: '',
    SET_READED_ALL:'',
    SET_DATA: '',
    ADD_DATA: '',
    TOGGLE_IS_LOADING: ''
}


export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.NOTIFICATIONS)}), {} as typeof actions);