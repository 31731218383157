import React, { FC, useState, useEffect } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'

import FormControlO from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import Checkbox from '@material-ui/core/Checkbox';
import PaperO from '@material-ui/core/Paper';
import { markdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap'
import { controlStyle, peperStyle, controlLabelStyle } from './style'
// import { PryanikyEditor } from 'blocks/PryanikyEditor/_PryanikyEditor'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PryanikyEditor from 'uielements/src/PryanikyEditorV1'
import { styled } from '@material-ui/core/styles';
import { validator, fieldWithError } from '../unitValidation'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { videoUrls } from 'utils/src/videoUrls';
import { VideoButton } from 'uielements/src/Dialog/VideoDilog/VideoButton';

// const TextFieldHelped = fieldWithHelp(TextField)
const Paper = styled(PaperO)(peperStyle)
const FormControlLabelHelped = styled(fieldWithHelp(FormControlLabel))(controlLabelStyle)
const FormControlHelped = styled(fieldWithHelp(FormControlO))(controlStyle)
const FormControl = styled(FormControlO)(controlStyle)
// import i18n from 'localizations/i18n';


export const Presenter: FC<IPresenterProps> = ({
    className,
    unit,
    updateUnit,
    saveLesson,
    setUnitError,
    errors,
    maxFileSize
}) => {
    if (unit.unitType !== 'wiki') return <div>not supported unit type</div>
    const changeCheck = (property: string) => () => {
        updateUnit({ ...unit, [property]: !unit[property] })
    }
    const changeDraft = (v: any) => {
        updateUnit({ ...unit, draft: v })
    }
    const changeText = (property: string) => (e: any) => {
        setUnitError(validator(property, e.target.value))
        updateUnit({ ...unit, [property]: e.target.value })
    }

    return <div className={cnClassName({}, [className])}>
        <Paper elevation={0} square>
            <FormControl fullWidth >
                <TextField
                    label={tT('page_edit.name.1')}
                    error={fieldWithError(errors['name'])}
                    value={unit.name}
                    onChange={changeText('name')}
                />
            </FormControl>
            <div className={cnClassName("Video")}>
                <VideoButton className={cnClassName("Video")} url={videoUrls.lms.unitContent} type={'vimeo'} />
            </div>
            {/* <FormControl fullWidth >
                <TextField
                    label={tT('page_edit.description.1')}
                    value={unit.description}
                    multiline
                    onChange={changeText('description')}
                />
            </FormControl> */}
            <div>
                <FormControlLabelHelped
                    control={
                        <Checkbox
                            checked={unit.allowComments}
                            onChange={changeCheck('allowComments')}
                            name="allowComments"
                            color="primary"
                        />
                    }
                    label={tT('page_edit.allowComments.1')}
                    help={tT('page_edit.allowComments.help.1')}
                />
            </div>
            <FormControl fullWidth >
                <PryanikyEditor
                    maxFileSize={maxFileSize}
                    enableModules={{ ...markdownPreset, attachment: true }}
                    toolBarStyle={{ top: 108 }}
                    value={unit.draft}
                    setActionBtn={(v: any) => { }}
                    onChange={changeDraft}
                />
            </FormControl>
        </Paper>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
