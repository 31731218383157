import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, INotificationsStateProps, cnNotifications, INotificationsProps, INotificationsOwnProps, INotificationsActionsToProps, mapDispatchToProps } from './Notifications.index';
import { ListItemWithRouter } from './NotificationItem/Item'
import { IStateType as IState } from 'redux/store';
import { MenuButtonBox, MenuLoadingBox, MenuNotNotificationBox } from './Notifications.styled';
import { CircularProgress } from '@mui/material';
import { Button } from 'muicomponents/src';
import { Translate } from 'localization';
import { Check, Notifications as NotificationsIcon, Settings } from 'muicomponents/src/Icons';
import { IconButton } from 'muicomponents/src/IconButton';
import { useHistory } from 'react-router';

export type TFlexSettingsBox = {
  position?: 'sticky' | 'static',
}

const NotificationsPresenter: React.FC<INotificationsProps> = ({dataArray, isLoading, notificationCount, loadData, className, children, set_readed_all, currentUserId }) => {

  const history = useHistory();

  const setReadedAll = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (event) event.nativeEvent.stopImmediatePropagation();
    set_readed_all();
  }

  useEffect(() => {
    if (!dataArray.length) {
      loadData(0, 20);
    };
  }, [notificationCount]);

  const FlexSettingsBox = useCallback<React.FC<TFlexSettingsBox>>(({children, ...props}) => {
    return (
      <MenuButtonBox {...props} >
        {children}
        <IconButton onClick={() => {
          history.push(`/notifiSettings/${currentUserId}/common`);
        }}>
          <Settings />
        </IconButton>
      </MenuButtonBox>
    );
  }, [])


  return (
    
    <div className={cnNotifications({}, [className])}>

          {
            isLoading ? (
              <MenuLoadingBox>
                <CircularProgress size={24} />
              </MenuLoadingBox>
            ) : 
            dataArray.length ? (
              <>
                { dataArray.map((id: number) => <ListItemWithRouter key={id} id={id} /> ) }
                
                <FlexSettingsBox position='sticky'>
                  <Button className="Button-LikeRead" onClick={setReadedAll} sx={{ textTransform: 'none !important', justifyContent: 'start' }} endIcon={<Check />}>
                    <Translate i18nKey={'pryaniky.header.mark.like.read'}/>
                  </Button>
                </FlexSettingsBox>
              </>
            ) : 
            <FlexSettingsBox>
              <MenuNotNotificationBox>
                <NotificationsIcon sx={{color: '#bdbdbd'}} />
                <Translate i18nKey={'pryaniky.header.menu.noNotifications'}/>
              </MenuNotNotificationBox>
            </FlexSettingsBox>
          }
      {children}
    </div>
  )
};


export const Notifications = connect<INotificationsStateProps, INotificationsActionsToProps, INotificationsOwnProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsPresenter)

export default Notifications;