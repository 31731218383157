import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IPresenterProps = IOwnProps

export interface IState { }


export interface IOwnProps extends IClassNameProps {
    imgUrl: string;
    mainContent: React.ReactNode;
    additionalContent: React.ReactNode;
    text: string;
    comments: React.ReactNode;
    footer: React.ReactNode;
    actionContent?: React.ReactNode;
    files: any;
}

export const cnClassName = cn('LMSUserAnswer');