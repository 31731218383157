import React, { FC, memo } from 'react'
import { IAlbumProps, mapStateToProps, mapDispatchToProps } from './Album.index'
import { Button, Icon } from 'uielements/src'
import * as utilsProj from 'utils.project/utils.project';
import { connect } from 'react-redux';
import i18n from 'localizations/i18n';
import './Album.scss'

export const Album_: FC<IAlbumProps> = ({ album, aid, gid, deleteAlbum, showControls }) => {
    const remove = (e: any) => {
        utilsProj.confirm({ text: i18n.t('pryaniky.photoalbums.album.delete'), onConfirm: () => deleteAlbum(gid, aid) })
    }
    return <div className={'Album'} style={{ backgroundImage: `url(${album.previewUrl})` }}>
        {showControls && <div className={'Album-Actions'}>
            <Button theme={'unstyled_center'} onClick={remove}><Icon icon={'trash'} /></Button>
        </div>}

        <Button type={'rlink'} href={`/group/${gid}/albums/${aid}`} theme={'unstyled'} className={'Album-Info'}>
            <div>{album.name}</div>
            <div>{i18n.t('pryaniky.photoalbums.album.photocount', { oldData: album.itemsCount.toString() })}</div>
            <div>{(new Date(album.date)).toLocaleDateString()}</div>
        </Button>
    </div>
}

export const Album = connect(
    mapStateToProps,
    mapDispatchToProps
)(memo(Album_))