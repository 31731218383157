import React, { FC } from 'react';
import './UnitItem.scss';
import { Icon } from 'uielements/src';
import { cnUnitItem, mapDispatchToProps, mapStateToProps } from './UnitItem.index'
import { connect } from 'react-redux';
import { tS } from 'LMSModule/utils/i18n.adaptor'
import {TYPE_TEXTS} from 'LMSModule/utils/common.utils'
export const UnitItem_: FC<any> = ({
    unit,
    actived,
    isComplited,
    isSkipped,
    loadUnitsLogsInProcessing,
    transitionToUnit,
    completedSessionsCount,
    activedSessions,
    unstartedSessionsCount
}) => {
    let state = isComplited ? 'complited' : undefined;
    state = isSkipped ? 'skipped' : state;
    state = actived ? 'actived' : state;
    state = loadUnitsLogsInProcessing ? 'load' : state

    state = (completedSessionsCount > 0 && !activedSessions) ? 'complited' : state
    state = (completedSessionsCount == 0 && !activedSessions && unstartedSessionsCount > 1) ? 'needSelectSession' : state

    const jump = () => transitionToUnit(unit.id, unit.courseId)

    return <div
        className={cnUnitItem({ state })}
        onClick={jump}
        title={(state === 'needSelectSession') ? tS('needSelect.1') : undefined}>
        <div className={cnUnitItem('Icon', { state })}></div>
        <div className={cnUnitItem('Data')}>
            <div className={cnUnitItem('Name', { state }, ['Button_type_rlink'])}>{unit.name}</div>
            <div className={cnUnitItem('Type')}>{TYPE_TEXTS[unit.unitType] ? TYPE_TEXTS[unit.unitType] : tS('lesson.1')}</div>
        </div>
    </div>
}

export const UnitItem = connect(
    mapStateToProps,
    mapDispatchToProps
)(UnitItem_);