import * as React from 'react';
import WidgetPresenter from '../Widget';
import { IWidgetTypeUserLmsStatProps } from './Widget_type_userLmsStat.index';
import './Widget_type_userLmsStatNew.scss';
import { widgets } from "i.widgets";
import CourseStatusesWidgetNew from 'LMSModule/LMS/CourseStatusesWidgetNew/CourseStatusesWidgetNew';
import { cnWidget } from '../Widget.index';


const types = {
  common: CourseStatusesWidgetNew,
}

const type = 'UserLmsStatNew'
class WidgetTypeUserLmsStat extends WidgetPresenter<IWidgetTypeUserLmsStatProps> {

  public render() {
    if (!this.props.widget) return null;
    if (!this.props.wcontext.uId) return null;
    const Component = this.getComponent(types);
    return (
      <Component tag={widgets.components.common}
        {...this.props}
        userid={this.props.wcontext.uId}
        className={cnWidget({ type })}
        settings={this.props.widget.settings}
        context={this.props.wcontext}
      />
    );
  }
}

export default WidgetTypeUserLmsStat

