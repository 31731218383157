import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
// import {  } from 'redux/actions/LMSTeacherOffice'

export interface IItemProps extends IClassNameProps, IItemStateProps, IDispatchProps, RouteComponentProps, IItemDispatchProps {
    tag?: 'div';
    uid: string
    cid: string
    unitCount: number;
}

export interface IItemState {

}

export interface IItemStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    student: state.LMSTO.students.values[props.uid]
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export type IItemDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnItem = cn('TOStudentBasicItem');