import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';

export const notifiSettings = (): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.notifiSettings,
          }),
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  });
}