import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { resetSurvey } from 'redux/sagas/Surveys/actions';
import { useSelector } from 'react-redux';
import { getSurveySelectorDataByKey } from 'redux/sagas/Surveys/selectors';

export const useSurveyFinish = () => {

  const data = useSelector(getSurveySelectorDataByKey('result', 'openSurveyData'));

  const {
    openSurveyData,
    result,

    ...others
  } = data;

  return {
    result: result,
    adminResult: openSurveyData.result,
    dataSurveyPostNews: openSurveyData.postNews,
    allowMultiAttempts: openSurveyData.allowMultiAttempts,
    surveySessionId: openSurveyData.id
    
    
  };
};

export interface ISurveyFinish extends ISurveyFinishStateProps, ISurveyFinishDispatchProps {
  preview: boolean
}

export const mapStateToProps = (state: IState) => {
  const data = state.survey.survey.openSurveyData
  return {
    result: state.survey.survey.result?.result?.result,
    adminResult: data.result,
    dataSurveyPostNews: state.survey.survey.openSurveyData.postNews,
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  resetSurvey
}, dispatch);

export type ISurveyFinishStateProps = ReturnType<typeof mapStateToProps>
export type ISurveyFinishDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnSurveyFinish = cn('SurveyFinishMui')