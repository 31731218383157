import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { TColumnSimplified } from 'Widgets_v2/types';

export namespace NTabs {

  export type Tab = TColumnSimplified;

  export type Props = IClassNameProps & {

    /**
     * tabs array like widget columns
     */
    tabs: Tab[];

    /**
     * widget context
     */
    context: {[key: string]: any};

    /**
     * function for update widget context
     */
    updateContext?: (context: Props['context']) => void;

    /**
     * disable url forwarding
     */
    disableNavigate?: boolean;
  };

};

export const cnTabs = cn('TabsMui');