import React, { FC, useEffect, useState } from 'react'
import { cnTeacherProfile, mapDispatchToProps, mapStateToProps } from './TeacherProfile.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './TeacherProfile.scss'
import { Button } from 'uielements/src'
import { ButtonBox } from 'uielements/src/ButtonBox/ButtonBox'
import lms_header from 'assets/jpg/lms_header.jpg';
import { Tabs } from '../Tabs/Tabs'
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

export const TeacherProfilePresenter: FC<any> = ({ className, user }) => {
    const defaultTabs = [
        {
            value: 'courses',
            title: tT('header.tabs.courses.1'),
            id: 'courses'
        }, {
            value: 'moderation',
            title: tT('header.tabs.moderation.1'),
            id: 'moderation'
        }, {
            value: 'comments',
            title: tT('header.tabs.comments.1'),
            id: 'comments'
        }, 
        {
            value: 'statistics',
            title: tT('header.tabs.statistics.1'),
            id: 'statistics'
        },
        /*{
            value: 'tracks',
            title: tT('header.tabs.tracks.1'),
            id: 'tracks'
        },*/
    ];
    return <div className={cnTeacherProfile({}, [className])}>
        <div className={cnTeacherProfile('Section')}>
            <Avatar imgUrl={lms_header} size={64} className={cnTeacherProfile('Avatar')} />
            <div className={cnTeacherProfile('Info')}>
                <h3 className={cnTeacherProfile('Title')}>{tT('header.title.1')}</h3>

                {user.baseData.isAdmin && <ButtonBox orientation={'horizontal'} className={cnTeacherProfile('Actions')}>
                    <Button main type='rlink' href={'/TeacherOffice'} title={'Управление'} >{tT('header.TeacherOffice.1')}</Button>
                    <Button type='rlink' href={'/LMS'} title={'Обучение'} >{tT('header.LMS.1')}</Button>
                </ButtonBox>}
            </div>
        </div>


        <div className={cnTeacherProfile('Nav')}>
            <div className={cnTeacherProfile('Noop')}></div>
            <Tabs tabs={defaultTabs} />
            <div className={cnTeacherProfile('Noop')}></div>
        </div>
    </div>
}

export const TeacherProfile = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TeacherProfilePresenter));
