import { styled } from "muicomponents/src";
import { cnTaskListItemCell } from "../Item/TasksListItem.index";
import { ItemBox, ItemGridCell } from "../TasksList.styled";

export const StyledItemBox = styled(ItemBox)({
    height: 56,
    [`& .${cnTaskListItemCell('Header')}:last-of-type`]: {
        flexBasis: 80,
        minWidth: 80,
    }
}) as typeof ItemBox;

export const HeaderListCell = styled(ItemGridCell)({
    fontWeight: 500
}) as typeof ItemGridCell;