import React, { FC, memo, useState } from 'react'
import {
    cnClassName,
    IPresenterProps
} from './interfaces'
import './style.scss'
import { Icon } from 'uielements/src'
import { PostContentRender } from 'blocks/PostContentRender/PostContentRender'
import { formatDateSTD } from 'utils/src/utils.dates'
import { tC } from 'LMSModule/utils/i18n.adaptor'

export const Presenter: FC<IPresenterProps> = ({
    className,
    content,
    header,
    hideContent = false,
    isOldVersion = false,
    versionDate = (new Date()).toISOString()
}) => {
    const [show, setShow] = useState(!hideContent)
    return <div className={cnClassName({}, [className])}>
        <div onClick={() => hideContent && setShow(!show)} className={cnClassName('Header')}>
            <div className={cnClassName('Title')}>{header || tC('question.1')}</div>
            {isOldVersion && <div className={cnClassName('Version')}>{tC('version.1')}{formatDateSTD(versionDate + 'Z', true)}</div> }
            {hideContent && <div className={cnClassName('Control')}>
                <Icon icon={show ? 'chevron-down' : 'chevron-left'} />
            </div>}
        </div>
        {show && <div className={cnClassName('Section')}>
            <PostContentRender className={cnClassName('Content')} data={content} />
        </div>}
    </div>
}

export default memo(Presenter);
