import queryString from 'query-string';
import { IShop, ShopListRouteParams } from './interfaces';

export const prepareLocationSearchToFilters = (searchString: string): IShop['chosenFilters'] => {
    if(searchString.startsWith('?')) {
        searchString = searchString.slice(1);
    }
    // ?favorites=true&catFilters=67,34&minPrice=10&maxPrice=20&allowByPrice=true&characteristics=5211[2ad4bc2f-2462-4579-a018-7468f495e2f3]&sort=OrderByPrice_asc
    const baseUrlParams = queryString.parse(searchString, { arrayFormat: 'comma', parseNumbers: true, parseBooleans: true }) as ShopListRouteParams;
    if(typeof baseUrlParams.catFilters === 'number') {
        baseUrlParams.catFilters = [baseUrlParams.catFilters];
    }

    const baseCharacteristics: IShop['chosenFilters']['characteristics'] = {};
    if(baseUrlParams.characteristics) {
        let array = baseUrlParams.characteristics;
        if(typeof baseUrlParams.characteristics === 'string') {
            array = [baseUrlParams.characteristics];
        }
        for (const element of array) {
            const charIdRegEx = /^\d+/;
            const charItemIdRegEx = /\[.+-.+\]/g;
            const charId = parseInt(charIdRegEx.exec(element)?.[0] || '');
            const charItemId = charItemIdRegEx.exec(element)?.[0]?.replace(/[\[\]]/g, '');
            if(!isNaN(charId) && charItemId) {
            if(!baseCharacteristics[charId]) baseCharacteristics[charId] = [];
            baseCharacteristics[charId].push({
                color: "",
                displayName: "",
                id: charItemId,
                isDisabled: false
            });
            }
        }
        baseUrlParams.characteristics = [];
    }

    let baseSorting: IShop['chosenFilters']['sorting'] = {
        //OrderByRating: '',
        OrderByPopular: "",
        OrderByCreateAt: "",
        OrderByPrice: "",
    }
    if(baseUrlParams.sort && baseUrlParams.sort !== 'default_asc') {
        const [key, value] = baseUrlParams.sort.split('_');
        baseSorting = {
            ...baseSorting,
            [key]: value
        };
    }
    return {
        catFilters: baseUrlParams.catFilters || [],
        search: baseUrlParams.search || '',
        characteristics: baseCharacteristics || {},
        MinPrice: baseUrlParams.minPrice,
        MaxPrice: baseUrlParams.maxPrice,
        AllowToMeByPrice: baseUrlParams.allowByPrice || false,
        MyFavorite: baseUrlParams.favorites || false,
        sorting: baseSorting
    };
};