import * as utils from 'utils/src/utils';

export const participantAction = (type: string, newsId: string) => {
    /*this.setState({
        eventStatus: type
    });*/
    utils.API.news.action(newsId, 11, 'event_status;' + type + ';' + utils.getCookie('authUid'))
        .r
        .then((d: any) => {
            if (d.error_code === 0) {
                /*this.setState({
                    eventStatus: type,
                    eventParticipants: d.data.uevent.eventParticipants
                }, () => {
                    // this.checkWidth();
                });*/
            }
            else {
                // toast.error(d.error_text);
            }
        });
}