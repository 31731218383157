import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";

import * as utils from 'utils/src/utils';
// import * as widgetUtils from 'widgets/utils';
import { mapStateToProps, IWikiStateProps, cnWiki, IWikiProps, IWikiState } from './Wiki.index';
import './Wiki.scss';
import { Button, Icon } from 'uielements/src';
import { converter } from './converter'


import i18n from 'localizations/i18n';

import { Comments } from '../../LMSModule/LMS/Comments/Comments'
import { LikeAction } from './Likes/Likes'
import { Actions } from 'blocks/NewsView/Actions/Actions'


export class WikiPresenter extends React.Component<IWikiProps, IWikiState> {
    // public utils: any = utils;
    public el: Element | Text | null;

    public data?: any;

    constructor(props: IWikiProps) {
        super(props);

        this.state = {
            edit: false
        };
        this.data = this.props.data;
    }

    public componentDidMount() {
        if (document.querySelector('.Widget-Sortable')) (document.querySelector('.Widget-Sortable') as any).onpointerdown = undefined
    }

    public render() {
        const customClassNames = this.props.edit ? 'edit' : 'no_edit';
        const { tag: TagName = 'div', children, className = '' } = this.props;
        if (!this.props.data && !this.state.edit) {
            return (
                <TagName {...this.props} ref={el => this.el = el} className={cnWiki({}, [customClassNames, className])}>
                    {!this.props.edit && <Button onClick={() => { this.setState({ edit: true }) }} >
                        <Icon icon={'edit'} />
                    </Button>}
                </TagName>
            )
        }
        if (!this.state.edit) {
            return (

                <TagName {...this.props} ref={el => this.el = el} className={cnWiki({}, [customClassNames, className])}>
                    {this.props.isAdmin ? <>{!this.props.edit && <Button onClick={() => { this.setState({ edit: true }) }} >
                        <Icon icon={'edit'} />
                    </Button>}</> : null}
                    {converter(this.props.data)}

                    {(this.props.data.newsUid && utils.isValidGuid(this.props.data.newsUid)) &&
                        <Actions className={cnWiki('Actions')}>
                            <LikeAction className={cnWiki('Likes')} nid={this.props.data.newsUid} />
                        </Actions>}
                    {(this.props.data.newsUid && utils.isValidGuid(this.props.data.newsUid)) &&
                        <Comments className={cnWiki('Comments')} nid={this.props.data.newsUid} />}
                </TagName>

            )
        }
    }

    // private t(request: string, upper?: boolean, dataInText?: string) {
    //   return i18n.t(request, upper, dataInText).toString();
    // }


}


export const Wiki = connect<IWikiStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps({})
)(WikiPresenter)
//   compose(
//   withBemMod(cnWiki(), {})
// )(WikiPresenter))
