import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { Filters } from '../Filters'
import i18n from '../../../localizations/i18n'
import { Selectize, Button, Select } from 'uielements/src'
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index'
import { InputMention } from 'blocks/InputMention/InputMention'
import './Filters_type_creativetasks.scss'

import { mapStateToProps, IFiltersStateProps, mapDispatchToProps, IFiltersDispatchProps, IFiltersProps, cnFilter } from './Filters_type_creativetasks.index'
import { Toggle } from 'uielements/src/Toggle/Toggle'
import { cnFilters } from '../Filters.index'
import { Translate } from 'localizations/Translate'

const FiltersCreativeTasksPresenter: React.FC<IFiltersProps> = (props) => {

  const Tag = props.tag || 'div'

  const [data, setData] = useState(
    {
      name: i18n.t('pryaniky.filter.creativetasks.stages'),
      elements: [
        {
          id: '1',
          title: i18n.t('pryaniky.filter.creativetasks.all'),
          type: 'statusIds',
          value: undefined,
        },
        {
          id: '2',
          title: i18n.t('pryaniky.filter.creativetasks.active'),
          type: 'statusIds',
          value: 'active',
        },
        {
          id: '3',
          title: i18n.t('pryaniky.filter.creativetasks.expires'),
          type: 'statusIds',
          value: 'expires',
        },
        {
          id: '4',
          title: i18n.t('pryaniky.filter.creativetasks.finished'),
          type: 'statusIds',
          value: 'finished',
        },
        {
          id: '5',
          title: i18n.t('pryaniky.filter.creativetasks.canceled'),
          type: 'statusIds',
          value: 'canceled',
        },
      ],
    },
  )

  const [sortData, setSortData] = useState(
    {
      name: '',
      elements: [
        {
          id: '111',
          title: i18n.t('pryaniky.filter.sort.lastcommentdate'),
          type: 'order',
          value: 'lastcommentdate',
          sort: "down",
        },
        {
          id: '222',
          title: i18n.t('pryaniky.filter.sort.replycount'),
          type: 'order',
          value: 'replycount',
          sort: "down",
        },
        {
          id: '333',
          title: i18n.t('pryaniky.filter.sort.fund'),
          type: 'order',
          value: 'fund',
          sort: "down",
        },
      ],
    },
  )

  const [inputMention, setInputMention] = useState(
    {
      tags: undefined,
      author: undefined
    }
  )

  const [checked, setChecked] = useState(
    {
      moderation: false,
    }
  )

  
  const [stateSelected, setStateSelected] = useState(
    {
      id: '111',
      title: i18n.t('pryaniky.filter.sort.lastcommentdate'),
      type: 'order',
      value: 'lastcommentdate',
    })

  const contextVariables = props.relations || ['common']

  const handleChange = (selected: ISelectizeItem) => {
    const same = props.context.filter && props.context.filter.id === selected.id
    props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => { 
      acc[conID] = { filter: !same ? selected : undefined, [selected.type]: !same ? selected.value : undefined }
      return acc
    }, {} as { [s: string]: any }))
  }

  const setSort = (selected: any) => {
    setSortData({ ...sortData, elements: sortData.elements.map((el) => el.id === selected.id ? { ...el, sort: selected.sort === 'down' ? 'up' : 'down' } : el) })

  }

  const changeSort = (selected: any) => {
    setSort(selected)
    props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { sort: selected.sort === 'down' ?'asc':'desc'}
      return acc
    }, {} as { [s: string]: any }))
  }
  const handleChangeSort = (selected: any) => {
    setStateSelected(selected)
    props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { [selected.type]: selected.value, ...(selected.sort ? { sort: selected.sort === 'up' ? 'asc' : 'desc' } : {}) }
      return acc
    }, {} as { [s: string]: any }))
  }


  const checkedHandler = (type: string, data: boolean) => {
   
    switch (type) {
      case 'moderation':
        setChecked({
          ...checked,
          moderation: !data
        })
      default:
        break
    }

    props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { [type]: !data }
      return acc
    }, {} as { [s: string]: any }))
  }


  const handleChangeInputMention = (type: string, data: any) => {

    let dataIds: string[]

    switch (type) {
      case 'tagIds':
        setInputMention({
          ...inputMention,
          tags: data
        })
        dataIds = data && data.map((item: { id: string }) => item.id)
        break
      case 'authorIds':
        setInputMention({
          ...inputMention,
          author: data
        })
        dataIds = data && data.id
      default:
        break
    }

    props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { [type]: dataIds }
      return acc
    }, {} as { [s: string]: any }))
  }

  
  // const handleChangeDropdown = (selected: any) => {
  //   setSelected(selected)
  //   props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
  //     acc[conID] = { 'sort': selected.value }
  //     return acc
  //   }, {} as { [s: string]: any }))
  // }

  return (

    <>
      {/* 
  // @ts-ignore */}
      <Tag  {...props} className={cnFilters({}, [props.className])} >

        <h3 className={cnFilter('Title', ['BottomLine'])}>{i18n.t('pryaniky.filter.sort.title')}</h3>
        <Selectize onSortClick={changeSort} className='BottomLine' onClick={handleChangeSort} active={stateSelected.id} name={sortData.name} elements={sortData.elements || []} />
        {/* <h4 className={cnFilter('Title')}>{i18n.t('pryaniky.filter.display.title')}</h4>
        <Select 
          className={cnFilter('Select')}
          selected={selected}
          options={dropdown} 
          onChange={handleChangeDropdown}
          noDefault={true}
        /> */}
      
      </Tag>


      <Tag  {...props}  className={cnFilters({}, [props.className])} >

        <h3 className={cnFilter('Title', ['BottomLine'])}>{i18n.t('pryaniky.filter.creativetasks.title')}</h3>
        <Selectize onClick={handleChange} active={props.context && props.context.filter ? props.context.filter.id : undefined} name={data.name} elements={data.elements || []} />

        <h4 className={cnFilter('Subtitle')}>{i18n.t('pryaniky.filter.tags')}</h4>
        <InputMention className='BottomLine' type='tags' placeholder={i18n.t('pryaniky.filter.tags')} noMulti={false} onChange={handleChangeInputMention.bind(null, 'tagIds')} value={inputMention.tags} />

        <h4 className={cnFilter('Subtitle')}>{i18n.t('pryaniky.filter.creativetasks.author')}</h4>
        <InputMention className='BottomLine' type='users' icon={'user-group'} placeholder={i18n.t('pryaniky.filter.colleague')} noMulti={true} onChange={handleChangeInputMention.bind(null, 'authorIds')} value={inputMention.author} />

        <div className={cnFilter('Toggle')}>
          <h4>{i18n.t('pryaniky.filter.moderation')}</h4>
          <Toggle checked={checked.moderation} onChange={checkedHandler.bind(null, 'moderation', checked.moderation)} />
        </div>
        <div className={cnFilter('Bottom')}>
          <Button type='button' padding='lg' className={cnFilter('Button')}>
            <Translate i18nKey='pryaniky.filter.apply' />
        </Button>
        </div>
      </Tag>
    </>
  )
}

export const FiltersCreativeTasks = connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersProps>(
  mapStateToProps,
  mapDispatchToProps
)(FiltersCreativeTasksPresenter)