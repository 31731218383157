import { IStateType as IState } from 'redux/store';
import { bindActionCreators, Action, Dispatch } from "redux";
import { cn } from '@bem-react/classname';
// import { INewsViewType } from '../../../redux/actionsTypes/News';
// import { setNewViewType } from '../../../redux/actions/News';
import {
  getViewType
} from 'News/redux/saga/selectors'
import {
  changeField
} from 'News/redux/actions'

export interface INewsViewSelectorProps extends IMapNewsViewSelectorStateToProps, IMapNewsViewSelectorActionsToProps, INewsViewSelectorOwnProps { }

interface INewsViewSelectorOwnProps {
  highlight?: boolean;
  children?: any;
  onChange?: () => void;
}

export const mapNewsViewSelectorStateToProps = (state: IState) => ({
  viewType: getViewType(state as any)//'card'//state.news.newsViewType as INewsViewType
});

export type IMapNewsViewSelectorStateToProps = ReturnType<typeof mapNewsViewSelectorStateToProps>

export const mapNewsViewSelectorActionsToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  changeField
}, dispatch);

export type IMapNewsViewSelectorActionsToProps = ReturnType<typeof mapNewsViewSelectorActionsToProps>

export const cnNewsViewSelector = cn('NewsViewSelector');