import React, { FC, useState } from 'react'
import './Month.css'
import { Button, Icon } from 'uielements/src'

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const dayNames = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'cб']

export const getDaysInMonth = (date: Date) => {
    const wDate = new Date(date);
    wDate.setUTCDate(1)
    const days = [];
    while (wDate.getUTCMonth() === date.getUTCMonth()) {
        days.push(new Date(wDate));
        wDate.setUTCDate(wDate.getUTCDate() + 1);
    }
    return days;
}


export const cellRender = (date: Date, select: (date: number) => void, item?: Date, minDate?: Date) => {
    if (!item) return '';
    let className = 'CellItem'
    if (date.toDateString() === item.toDateString()) className += ' selected-date';
    const currentDate = new Date();
    if (item.toDateString() === currentDate.toDateString()) className += ' current-date';
    const disabled = minDate ? minDate.getTime() > item.getTime() : false
    if (disabled) className += ' disabled-date';
    return (<div className={className} onClick={() => !disabled && select(item.getUTCDate())}>{item.getUTCDate()}</div>)
}
export const Month: FC<any> = ({ className, date, select, upper, prev, next, minDate }) => {
    const days = getDaysInMonth(date)

    const dayMap: any[] = [];
    let w = dayMap.push({});
    days.map((val: Date, i: number) => {
        if (val.getUTCDay() === 1) w = dayMap.push({});
        dayMap[w - 1][val.getUTCDay()] = val
    })
    const wm = [1, 2, 3, 4, 5, 6, 0]
    return (
        <div className={className}>
            <div className='BtnBox'>
                <Button theme={'unstyled'} onClick={prev}><Icon icon='arrow-alt-left' /></Button>

                <Button className={'Calendar-Title'} theme={'unstyled'} onClick={upper}>{monthNames[date.getUTCMonth()]} {date.getUTCFullYear( )}</Button>

                <Button theme={'unstyled'} onClick={next}><Icon icon='arrow-alt-right' /></Button>
            </div>
            <table className='DateTable'>
                <thead>
                    <tr>
                        {wm.map((v: any, i: number) => <th key={i}>{dayNames[v]}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {dayMap.map((val: any, i: number) => <tr key={i}>
                        {wm.map((v: any, i: number) => <td key={i}>
                            {cellRender(date, select, val[v], minDate)}
                        </td>)}
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}