import { Box, Paper, styled, Button, Typography, TextField } from "muicomponents/src";
import { Avatar } from "muicomponents/src/Avatar";
import { FormControl } from "muicomponents/src/FormControl";

export const WrapBox = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    margin: '16px auto',
    maxWidth: '781px',
    '.MuiFormHelperText-root': {
        color: 'red',
    }
})) as typeof Paper

export const FieldsRenderBox = styled(Box)(({ theme }) => ({
    '.MuiInputAdornment-root': {
        '.MuiSvgIcon-root': {
            display: 'none',
        }
    },
    '.MuiInputAdornment-positionEnd': {
        '.MuiSvgIcon-root': {
            display: 'unset',
        }
    },
    '.MuiDivider-root': {
        display: 'none',
    }
})) as typeof Box

export const LineBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    margin: `${theme.spacing(2)} 0`,
    columnGap: theme.spacing(2),
    width: '100%',
    '@media (max-width: 600px)': {
        flexDirection: 'column',
        rowGap: theme.spacing(2),
    },
})) as typeof Box

export const FlexField = styled(TextField)(({ theme }) => ({
    flex: 1,
    /* '& label.Mui-focused': {
        color: 'orange',
    }, 

     '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#6F7E8C',
        },
        
    }, */
})) /* as typeof TextField */

//css += `.MuiOutlinedInput-root fieldset { border: 1px solid ${data[key]}; } ` ;


export const ActionsBox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(4),
    columnGap: theme.spacing(1),
})) as typeof LineBox

export const TopButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 'fit-content',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(1),
})) as typeof Button

export const ProfileAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(9),
    height: 'auto',
})) as typeof Avatar

export const HeadBox = styled(Box)(({ theme }) => ({
    display: 'flex', 
    columnGap: theme.spacing(2),
    marginBottom: theme.spacing(2), 
})) as typeof Box

export const BtnsBox = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    flex: 2,
})) as typeof Box

export const ImgLoaderBox = styled(Box)(({ theme }) => ({
    flex: 1,
    marginBottom: theme.spacing(1),
    height: theme.spacing(9),
    display: 'flex',
})) as typeof Box

export const CatTypography = styled(Typography)(({ theme }) => ({
    margin: `${theme.spacing(2)} 0`,
    fontWeight: '600',
    fontSize: '20px',
})) as typeof Typography

export const FlexControl = styled(FormControl)({
    flex: 1,
}) as typeof FormControl


