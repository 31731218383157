import { IDispatchProps } from 'redux/connector';
import { IDialog } from 'blocks/Dialogs/DialogWrapper.index';
import { cn } from '@bem-react/classname';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export type IItemEditDialogStateProps = ReturnType<typeof mapStateToProps>;

// export interface IItemEditDialogProps extends IItemEditDialogOwnProps, IDispatchProps, IItemEditDialogStateProps { } 

export interface IItemEditDialogOwnProps extends IDialog {
  type?: string;
  changeMenutem?: any;
  deleteMenuItem?: any;
}

export interface IItemEditDialogState {
  selected: any;
  onMobile: boolean;
}

export const mapStateToProps = (state: any, props: any) => {
  return {
    modal: state.modals[props.id],
    myThanksCount: getAuthUser(state).extData.mythanksCount || 0,
    currencyFormats: state.store.appSettings,
  }
};

export const cnItemEdit = cn('ItemEdit');