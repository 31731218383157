import React from 'react';
import { compose } from '@bem-react/core';
import { IOrdersStateProps, IOrdersProps } from './Orders.index';
import { IDispatchProps, mapDispatchToProps } from 'redux/connector';
import { connect } from 'react-redux';
import { Page } from '../Page/Page';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { pageNames } from 'redux/pageNames';

const OrdersPresenter: React.FC<IOrdersProps> = () => <Page page={{ name: pageNames.orders }} />;

const mapStateToProps = (state: any) => ({
  structure: state.store.structure,
  uid: getAuthUser(state).baseData.id,
});

export const Orders = connect<IOrdersStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps({})
  )(OrdersPresenter);
