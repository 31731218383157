import { IShopItemTimeline } from "utils/src/requests/models/api.shop"

export interface ICurrencyTimelineProps  {
  currentUserId: string
}

export interface TimelineItemNewProps {
  userSex: string
  data: {
      isDeleted: boolean
      value: number
      transferDate: string
      transactionModelType: string
      transactionType: string
      productData: IShopItemTimeline | null
      newsItem?: {
          permaLink: string
          user: { displayName: string }
          header: string
          componentRenderName: 'thanks' | "creativetasks" | 'news' | 'badges' | 'quizresult' | 'ideas' | 'achievements'
          creativeTask: { title: string }
          systemNewsActionHtml?: string
          comments?: {header: string, permaLink?: string}[]
          quizes?: Array<any>
      }
  }
};

export interface Itexts {
  header: string
  headerLink?: string
  text?: string
  textLink?: string
  rightSide?: boolean
  icon?: JSX.Element
  ablativeCurrency?: string
}