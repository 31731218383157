import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IMatrixProps extends IClassNameProps {
  tag?: 'div';
  data: {[s: string]: any}
  onChange(rId: string, aId: string): void;
  invalid?: boolean;
  errorMessage?: string;
  required?: boolean;
}

export interface IMatrixState {
  text?: string;
}

export const cnMatrix = cn('Matrix');