import { IClassNameProps } from '@bem-react/core';
import { IDispatchProps } from '../../redux/connector';
import { IWidgetSettings } from 'widgets/wiget_settings';
import { IWidgetProps } from 'blocks/Widget/Widget.index';
import { IStateType as IState } from 'redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { IStateType } from 'redux/store';
import { bindActionCreators } from 'redux';
import { useScrollListState } from 'utils/src/hooks';
import { getAdditionalFilters } from 'redux/search/selectors';


export interface IListProps extends IClassNameProps, IListOwnProps, TListStateProps, IWidgetProps, IDispatchProps {
  tag?: 'div' | React.ComponentType;
}

export interface IListOwnProps {
  context: IListContext;
  afterStateChangedCallback?: (state: ReturnType<typeof useScrollListState>) => void;
  afterGetDataCallback?: (state: any) => void;
  id?: string; // need to redux list indentifier;
}

export interface IListContext extends IWidgetSettings {
  cId?: string;
  aId?: string;
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export const mapStateToProps = (state: IState, props: IListOwnProps) => ({
  authUser: getAuthUser(state),
  uIsAdmin: getAuthUser(state).baseData.isAdmin,
});

export type TListStateProps = ReturnType<typeof mapStateToProps>

export type TMapDispatchProps = ReturnType<typeof mapDispatchToProps>

export const getQuerySearchSelector = (state: IStateType) => state.searchSettings.query;

export const getAdditionalFilterByName = <Name extends keyof ReturnType<typeof getAdditionalFilters> > (filterName: Name) => (state: IStateType) => getAdditionalFilters(state)[filterName];

export const getWikiFilter = (state: IStateType) => state.searchSettings.filters.find((filter) => filter.filterName === 'pages');

export const getFileFilter = (state: IStateType) => state.searchSettings.filters.find((filter) => filter.filterName === 'files');