import { ACTION } from 'utils/src/CommonRedux/actionsTypes';


let actions: {
    //saga
    
    // LOAD_EXPERTISE_RESULT: 'LOAD_EXPERTISE_RESULT',// загрузить результаты экспертной оценки
    SAVE_EXPERTS: 'SAVE_EXPERTS', // назначить экспертов
    SAVE_STATUS: 'SAVE_STATUS', // сохранить статус
    LOAD_FIELDS: 'LOAD_FIELDS', // загрузить поля перевода на статус
    SAVE_EXPERTISE: 'SAVE_EXPERTISE', // сохранить эекспертную оценку
    LOAD_EXPERTISE: 'LOAD_EXPERTISE', // загрузить эксперную оценку
    LOAD_INVESTORS_LIST: 'LOAD_INVESTORS_LIST', // загрузить список инвесторов
    SEARCH_INVESTORS: 'SEARCH_INVESTORS', // поиск в списке инвесторов
    SAVE_DISTRIBUTE: 'SAVE_DISTRIBUTE', // сохранить распределение фонда
    TOGGLE_PARTICIPATS: 'TOGGLE_PARTICIPATS', // изменить статус участия текущего пользователя
    TOGGLE_HEAD: 'TOGGLE_HEAD', // изменить статус возглавления текущего пользователя
    
    // //reducer


    //common
} = {
    //saga
    // LOAD_EXPERTISE_RESULT: 'LOAD_EXPERTISE_RESULT',// загрузить результаты экспертной оценки
    SAVE_EXPERTS: 'SAVE_EXPERTS', // назначить экспертов
    SAVE_STATUS: 'SAVE_STATUS', // сохранить статус
    LOAD_FIELDS: 'LOAD_FIELDS', // загрузить поля перевода на статус
    SAVE_EXPERTISE: 'SAVE_EXPERTISE', // сохранить эекспертную оценку
    LOAD_EXPERTISE: 'LOAD_EXPERTISE', // загрузить эксперную оценку
    LOAD_INVESTORS_LIST: 'LOAD_INVESTORS_LIST', // загрузить список инвесторов
    SEARCH_INVESTORS: 'SEARCH_INVESTORS', // поиск в списке инвесторов
    SAVE_DISTRIBUTE: 'SAVE_DISTRIBUTE', // сохранить распределение фонда
    TOGGLE_PARTICIPATS: 'TOGGLE_PARTICIPATS', // изменить статус участия текущего пользователя
    TOGGLE_HEAD: 'TOGGLE_HEAD', // изменить статус возглавления текущего пользователя

    // //reducer


    //common
}


export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, 'NEWS_IDEAS') }), {} as typeof actions);