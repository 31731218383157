import { styled } from 'muicomponents/src/mui/system';

export const CardWrapper = styled('div')({
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 16
});

export const EditorFrame = styled('iframe')({
    height: 700,
    width: '100%'
});