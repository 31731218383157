import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { loadFullUnit, saveStartUnitResult, startQuestion, setNextQuestion, transitionToQuestion } from "../../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';

export interface IQuestionProps extends IClassNameProps, IQuestionStateProps, IDispatchProps,
    RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
    uid: string;
    qid: string;
    // unitContent: any
}

export interface IQuestionState {
    // courses: ICourse[];
    // isFinished: boolean;
}

export interface IQuestionStateProps {
    store: any
    unitContent: any
}

export const mapStateToProps = (state: any, props: any) => ({
    questions: state.LMS.units[props.uid].normalQuestions
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    transitionToQuestion,
}, dispatch);

export const cnSkipedQuestion = cn('SkipedQuestion');