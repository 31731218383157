import { NewsCreateContext } from "News/creator/Creator/Creator.constants";
import { useChangeCreator } from "News/creator/hooks";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllowPostTypeById } from "redux/sagas/AppSettings/selectors";
import { newsCreatorItemNewsChange } from "../../redux/actions.newsCreator";
import { getNewsCreateItemFields, getNewsCreateItemNewsFields } from "../../redux/selectors.newsCreate";
import { NewsCreateReducer } from "../../redux/types.newsCreator";
import { NAchievements } from './Achievements.interface';

export const useAchievementsEdit = (id: keyof NewsCreateReducer) => {

    const dispatch = useDispatch();

    const { groupId, workflowId } = useContext(NewsCreateContext);

    const data = useSelector(getNewsCreateItemNewsFields(['text', 'achievement', 'draftRawState'])(id));

    const creatorFields = useSelector(getNewsCreateItemFields(['errors', 'clearEditor', 'selectedNews', 'hideSender'])(id));

    const achievementsParams = useSelector(getAllowPostTypeById(creatorFields.selectedNews || '', groupId, workflowId))?.renderParams as NAchievements.RenderParams | undefined;

    const changeNews = (news: Partial<typeof data>) => {
        dispatch(newsCreatorItemNewsChange({ id, news }));
    };
    
    const changeCreator = useChangeCreator(id);

    return {
        data,
        achievementsParams,
        ...creatorFields,
        changeNews,
        changeCreator
    };
};