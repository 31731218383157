import React, { FC, ComponentProps } from 'react';
import { styled } from 'muicomponents/src/mui/system';
import { Box } from 'muicomponents/src/Box/Box';
import { ChevronLeft, DragIndicator } from 'muicomponents/src/Icons/Icons';
import { ListItem, ListItemIcon } from 'muicomponents/src/ListItem/ListItem';
import { cnLeftMenuItem } from './LeftMenuItem.index';
import { Link } from 'muicomponents/src/Link/Link';
import { Button } from 'muicomponents/src/Button/Button';
import { IconButton } from 'muicomponents/src';

const SelectorStrokePr: FC<ComponentProps<typeof Box> & {
    bookmark: 'Off' | 'Admins' | 'AdminsHover' | 'On'
}> = ({
    bookmark,
    ...props
}) => {

    return (
        <Box {...props}>
            {
                (
                    bookmark === 'Admins' ||
                    bookmark === 'AdminsHover'
                ) &&
                <DragIndicator fontSize={'small'} />
            }
        </Box>
    )
};

export const SelectorStroke = styled(SelectorStrokePr)(({
    bookmark
}) => ({
    width: 16,
    position: 'absolute',
    cursor: 'grab',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    boxSising: 'border-box',
    borderLeft: bookmark === 'On' || bookmark ===  'AdminsHover' ? '2px solid #004E9C' : '0px solid transparent'
})) as typeof SelectorStrokePr;

export const StyledChevronLeft = styled(ChevronLeft)({
    transition: 'transform .2s ease-in-out'
}) as any as typeof ChevronLeft;

export const StyledListItem = styled(ListItem)({
    padding: 0,
    minHeight: 48,
    alignItems: 'stretch',
    [`&:hover .${cnLeftMenuItem('Favourite')}`]: {
        display: 'inherit'
    },
    [`&:hover .${cnLeftMenuItem('Edit')}`]: {
        display: 'inherit'
    }
}) as typeof ListItem;

export const StyledListItemIcon = styled(ListItemIcon)({
    minWidth: 48,
    padding: '8px',
    paddingRight: '0px',
    boxSizing: 'border-box'
}) as typeof ListItemIcon;

const textOverflowStyles = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexBasis: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    height: '100%',
    gap: 8,
    [`& .${cnLeftMenuItem('Name')}`]: {
        lineHeight: '1.3em',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        textOverflow: 'ellipsis'
    }
} as const;

export const StyledLink = styled(Link)({
    color: 'inherit',
    textDecoration: 'none',
    padding: '4px 16px',
    ...textOverflowStyles
}) as typeof Link;

export const StyledALink = styled('a')({
    color: 'inherit',
    textDecoration: 'none',
    padding: '4px 16px',
    ...textOverflowStyles
});

export const StyledBox = styled(Box)({
    padding: '4px 48px 4px 16px',
    ...textOverflowStyles
}) as typeof Box;

export const TooltipDiv = styled('div')({
    width: '100%',
    overflow: 'hidden'
});

export const StyledSettingsButton = styled(IconButton)({
    // minWidth: 'auto',
    display: 'none'
}) as any as typeof IconButton;

export const NotificationDot = styled('div')({
    position: 'absolute',
    right: 8,
    borderRadius: '50%',
    height: 7,
    width: 7,
    background: '#c11a1a',
    // marginBottom: 10,
    // position: opened ? 'absolute' : undefined,
    // margin-bottom: 0;
});