import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Button, Spinner, Icon } from 'uielements/src'
import { Avatar } from 'uielements/src/Avatar/Avatar';
import headerImg from './image.png'
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import { useLoadTrackByID } from 'LMSModule/utils/hooks/useLoadTrackByID'
import { xor } from 'lodash'
import { CheckboxInput } from 'uielements/src';


const filters = [
    'pages',
    'quizzes',
    'courses',
    'news',
]

export const Presenter: FC<IPresenterProps> = ({
    className,
    id,
    setActiveFilter,
    activeFiters
}) => {
    
    const [isLoading, track] = useLoadTrackByID(id)
    useEffect(() => {
    }, [])

    return <div className={cnClassName({ }, [className])}>
        <div className={cnClassName('RightActions')}>
            <Button
                className={cnClassName('BackLink')}
                theme={'unstyled_center'}
                type='rlink'
                href={'/LearningTracks'}><Icon icon={'arrow-left'} />{tTr('track_info.back')}</Button>
        </div>
        <div className={cnClassName('Content')}>
            <Avatar imgUrl={track?.imgUrl || headerImg} size={64} className={cnClassName('Avatar')} />
            <div className={cnClassName('Section')}>

                <div className={cnClassName('Info')}>
                    <h3 className={cnClassName('Title')}>{(!isLoading && track) && track.displayName}</h3>
                </div>
            </div>
        </div>
        <div className={cnClassName('Categories')}>
            <span>{tTr('header.catigories')}</span>
            {filters.map((filter) => {
                return (
                    <CheckboxInput
                        checked={activeFiters.includes(filter)}
                        text={tTr('filter.' + filter)}
                        onChange={() => setActiveFilter(xor(activeFiters, [filter]))}
                    />
                )
            })}
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
