import { styled } from 'muicomponents/src/mui/system';
import { CalendarPicker } from 'muicomponents/src/DatePicker/DatePickers.mui';
import { Box } from 'muicomponents/src/Box/Box';

export const AdventCalendarWrapper = styled('div')({
    
});

export const AdventCalendarDayBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: 'inherit',
    height: 36,
});

export const AdventCalendarDayWrapper = styled(Box)({
    position: 'relative',
    flex: 1,
    ['& .AdventCalendarDay-Edit']: {
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'none',
        minWidth: 'unset',
        padding: '4px 8px'
    },
    ['&:hover .AdventCalendarDay-Edit']: {
        display: 'flex'
    }
});

export const AdventCalendarHeader = styled('h4')({
    textAlign: 'center',
    margin: '16px 0px'
});

export const AdventCalendarPicker = styled(CalendarPicker)({
    width: 'auto',
    ['& .MuiDayPicker-weekDayLabel']: {
        flex: 1
    },
    ['& .MuiPickersCalendarHeader-root']: {
        display: 'none'
    },
    ['& .PrivatePickersSlideTransition-root']: {
        minHeight: 'auto'
    },
    ['& .MuiDayPicker-monthContainer']: {
        position: 'relative'
    }
}) as typeof CalendarPicker;