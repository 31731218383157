import React, { FC, useMemo, useEffect } from 'react';
import { AdventCalendarProps, cnAdventCalendar, cnAdventCalendarDay } from './AdventCalendar.index';
import { AdventCalendarDayWrapper, AdventCalendarWrapper, AdventCalendarDayBox, AdventCalendarPicker, AdventCalendarHeader } from './AdventCalendar.styled';
import { LocalizationProvider, AdapterMoment } from 'muicomponents/src/DatePicker/DatePickers.mui';
import moment, { Moment } from 'moment';
import { IClassNameProps } from '@bem-react/core';
import { useAdventCalendar, useAdventCalendarByWidgets, useAdventCalendarDay } from './AdventCalendar.hooks';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getAdventCalendarModule } from './redux/module';
import { Link } from 'muicomponents/src/Link/Link';
import { Button } from 'muicomponents/src/Button/Button';
import { Translate } from 'localizations/Translate';
import { CalendarSettingsDay } from './CalendarSettignsDay/CalendarSettignsDay';
import { Edit as EditIcon } from 'muicomponents/src/Icons';

const AdventCalendarDay: FC<IClassNameProps & {
    calendarId: Parameters<typeof useAdventCalendarDay>['0'],
    date: Moment,
    edit: boolean;
}> = ({
    calendarId,
    date,
    edit,
    className
}) => {

    const isoDate = useMemo(() => date.toISOString(), []);

    const startOfDate = useMemo(() => date.startOf('day'), []);
    const startOfToday = useMemo(() => moment().startOf('day'), []);

    const {
        day,
        changeDay
    } = useAdventCalendarDay(calendarId, isoDate);

    const isActive = useMemo(() => {
        const currentMonth = startOfToday.get('month');
        const dayMonth = startOfDate.get('month');
        return dayMonth === currentMonth && startOfDate.diff(startOfToday) <= 0
    }, []);

    const backgroundImage = useMemo(() => {
        if(!day?.isEmpty) {
            return `url(${day?.mainFile?.previewUrl})`;
        }
        return undefined;
    }, [ day?.isEmpty, day?.mainFile ]);

    const Linkcomponnet = useMemo(() => {
        if(!day?.isEmpty && day?.url && isActive) {
            return (props: any) => <Link {...props} href={day.url} />
        }
        return undefined;
    }, [ day?.isEmpty, day?.url, isActive ]);

    if(!day) return (
        <AdventCalendarDayWrapper className={cnAdventCalendarDay({}, [className])} sx={{ height: 'unset' }}>
        </AdventCalendarDayWrapper>
    )

    return (
        <AdventCalendarDayWrapper
            className={cnAdventCalendarDay({}, [className])}
        >
            <AdventCalendarDayBox
                component={Linkcomponnet}
                sx={{
                    backgroundImage,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: !isActive ? 'grayscale(100%)' : undefined,
                    color: !isActive ? '#9d9d9d': undefined
                }}
                onClick={(e) => {
                    edit && e.preventDefault();
                }}
            >
                {
                    !day.hideDefaultText &&
                    date.date()
                }
            </AdventCalendarDayBox>
            {
                edit &&
                <Button
                    variant={'contained'}
                    className={cnAdventCalendarDay('Edit')}
                    onClick={(e) => {
                        e.stopPropagation();
                        CalendarSettingsDay({ day })
                        .then(({ editedDay }) => {
                            changeDay(editedDay)
                        })
                        .catch(() => {});
                    }}
                >
                    <EditIcon />
                </Button>
            }
        </AdventCalendarDayWrapper>
    );
};

const AdventCalendarPr: FC<AdventCalendarProps> = ({
    id,
    edit
}) => {

    const date = useMemo(() => moment(), []);

    const {
        calendar,
        calendarName,
        change
    } = useAdventCalendarByWidgets(id.toString());

    if(!calendar && !edit) return null;

    if(!calendar && edit) return (
        <AdventCalendarWrapper className={cnAdventCalendar()} sx={{
            padding: '16px',
            widht: '100%',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <Button onClick={() => change()}>
                <Translate i18nKey={'pryaniky.adventcalendar.create'} />
            </Button>
        </AdventCalendarWrapper>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <AdventCalendarWrapper className={cnAdventCalendar()}>
                {
                    !!calendarName &&
                    <AdventCalendarHeader className={cnAdventCalendar('Header')}>
                        {calendarName}
                    </AdventCalendarHeader>
                }
                <AdventCalendarPicker
                    date={date}
                    onChange={() => {}}
                    renderDay={(date, selectedDays, pickersDayProps) => {
                        return (
                            <AdventCalendarDay key={pickersDayProps.key} calendarId={id} date={date} className={pickersDayProps.className} edit={edit} />
                        )
                    }}
                />
            </AdventCalendarWrapper>
        </LocalizationProvider>
    );
};

const AdventCalendarModule: typeof AdventCalendarPr = (props) => {
    return (
        <DynamicModuleLoader modules={[getAdventCalendarModule()]}>
            <AdventCalendarPr {...props} />
        </DynamicModuleLoader>
    )
}

export const AdventCalendar = AdventCalendarModule;