import actions from '../actionsTypes/TracksManage';
import {
    ILearningTrack,
    ILearningFile,
    ILearningUser
} from 'utils/src/requests/models/api.learningTracks'
import { union, xor } from 'lodash'
import {
    objectArrayToMap,
    // arrayMove
} from 'utils/src/utils'

export interface ILearningTracksReducer {
    tracks: ILearningTracks
    files: ITrackFiles
    users: ITrackUsers
    statUsers: ITrackStatUsers
    statMaterials: ITrackStatMaterials
}

export interface ILearningTracks {
    data: { [key: string]: ILearningTrack },
    keys: string[]
    selected: string[]
    isLoading: boolean
    isFinished: boolean
    count: number
}

export const defaultTracks = {
    data: {},
    keys: [],
    selected: [],
    isLoading: false,
    isFinished: false,
    count: 10
}

export interface ITrackFiles {
    values: { [key: string]: ILearningFile }
    keys: string[]
    isLoading: boolean
    isFinished: boolean
    count: number
    positionIsChanged: boolean
}

export const defaultFiles = {
    values: {},
    keys: [],
    isLoading: false,
    isFinished: false,
    count: 10,
    positionIsChanged: false
}

export interface ITrackUsers {
    values: { [key: string]: ILearningUser }
    keys: string[]
    isLoading: boolean
    isFinished: boolean
    count: number
}

export const defaultUsers = {
    values: {},
    keys: [],
    isLoading: false,
    isFinished: false,
    count: 10
}

export interface ITrackStatUsers {
    values: { [key: string]: any }
    keys: string[]
    isLoading: boolean
    isFinished: boolean
    count: number
}

export const defaultStatUsers = {
    values: {},
    keys: [],
    isLoading: false,
    isFinished: false,
    count: 10
}

export interface ITrackStatMaterials {
    values: { [key: string]: any }
    keys: string[]
    isLoading: boolean
    isFinished: boolean
    count: number
}

export const defaultStatMaterials = {
    values: {},
    keys: [],
    isLoading: false,
    isFinished: false,
    count: 10
}

const defaultLearningTracks: ILearningTracksReducer = {
    tracks: defaultTracks,
    files: defaultFiles,
    users: defaultUsers,
    statUsers: defaultStatUsers,
    statMaterials: defaultStatMaterials
}

export function learningTracksReducer(state = defaultLearningTracks, action: any) {
    switch (action.type) {
        case actions.SET_TRACKS: {
            const { data, keys } = action.payload
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    data: { ...state.tracks.data, ...data },
                    keys: action.clean ? keys : (action.toTop ? union(keys, state.tracks.keys) : union(state.tracks.keys, keys)),
                    isFinished: keys.length < state.tracks.count
                }
            }
        }

        case actions.CHANGE_TRACK: {
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    data: {
                        ...state.tracks.data,
                        [action.payload.id]: {
                            ...state.tracks.data[action.payload.id],
                            ...action.payload
                        }
                    },
                }
            }
        }

        case actions.DELETE_TRACK: {
            const { id } = action.payload
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    keys: state.tracks.keys.filter(trackId => !id.includes(trackId))
                }
            }
        }

        case actions.DELETE_FILE: {
            return {
                ...state,
                files: {
                    ...state.files,
                    keys: state.files.keys.filter(fileId => !action.payload.includes(fileId)),
                },
            }
        }

        case actions.SELECT_TRACK: {
            const { ids } = action.payload
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    selected: ids.length === state.tracks.keys.length ? (state.tracks.keys.length === state.tracks.selected.length ? [] : state.tracks.keys) : xor(state.tracks.selected, ids)
                }
            }
        }

        case actions.PREPEND_FILES:
        case actions.SET_FILES: {
            const { values, keys, trackId } = action.payload
            return {
                ...state,
                files: {
                    ...state.files,
                    values: { ...state.files.values, ...values },
                    keys: action.type === actions.PREPEND_FILES ? union(keys, state.files.keys) : union(state.files.keys, keys),
                    isFinished: keys.length < state.files.count
                }
            }
        }

        case actions.SET_USERS: {
            const { users, trackId } = action.payload
            const values = objectArrayToMap<any, any>(users, 'id')
            const keys = Object.keys(values)
            return {
                ...state,
                users: {
                    ...state.users,
                    values: { ...state.users.values, ...values },
                    keys: union(state.users.keys, keys),
                    isFinished: keys.length < state.users.count
                }
            }
        }

        case actions.SET_USERS_STAT: {
            const { users, trackId, clean = false} = action.payload
            const values = objectArrayToMap<any, any>(users, 'userModel.id')
            const keys = Object.keys(values)
            return {
                ...state,
                statUsers: {
                    ...state.statUsers,
                    values: { ...state.statUsers.values, ...values },
                    keys: clean ? keys : union(state.statUsers.keys, keys),
                    isFinished: keys.length < state.statUsers.count
                }
            }
        }

        case actions.SET_MATERIAL_STAT: {
            const { materials, trackId, clean = false } = action.payload
            const values = objectArrayToMap<any, any>(materials, 'news.objectId')
            const keys = Object.keys(values)
            return {
                ...state,
                statMaterials: {
                    ...state.statMaterials,
                    values: { ...state.statMaterials.values, ...values },
                    keys: clean ? keys : union(state.statMaterials.keys, keys),
                    isFinished: keys.length < state.statMaterials.count
                }
            }
        }

        case actions.DELETE_USERS: {
            return {
                ...state,
                users: {
                    ...state.users,
                    keys: state.users.keys.filter(userId => !action.payload.find((user: any) => user.id === userId))
                }
            }
        }

        case actions.CHANGE_FIELD: {
            const { root, field, value } = action.payload as { root: keyof ILearningTracksReducer, field: string, value: any }
            return {
                ...state,
                [root]: {
                    ...state[root],
                    [field]: value
                }
            }
        }

        case actions.RESET: {
            const root: keyof ILearningTracksReducer = action.payload
            return {
                ...state,
                [root]: defaultLearningTracks[root]
            }
        }

        default: return state
    }
}

