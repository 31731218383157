import { IWelcomesPropsType, IWelcomesStateType, mapStateToProps, TStateProps } from './Welcomes.index'
import * as React from 'react';
import { connect } from 'react-redux';
import './Welcomes.scss'
import { LinkLayout } from '../common/LinkLayout/LinkLayout'
import { withNewsContext } from 'News/contexts/news'
import TextRender from 'News/containers/TextRender'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import ContentLayout from 'News/components/ContentLayout'

export class WelcomesPresenter extends React.Component<IWelcomesPropsType & TStateProps, IWelcomesStateType>  {

    public render() {
        const { text, user, isSingle } = this.props;
        return (<NewsView className={'type-17'}>
            <ContentLayout>
                <TextRender />
            </ContentLayout>
            <LinkLayout linkPreviewImgUrl={user.imgUrl} systemNewsActionHtml={''}>
                <ul className="NewsView-Body_type_welcomes-InfoList">
                    <li className="NewsView-Body_type_welcomes-InfoItem">
                        <span className="NewsView-Body_type_welcomes-InfoTitle">{user.displayName}</span></li>
                    <li className="NewsView-Body_type_welcomes-InfoItem">
                        <span className="NewsView-Body_type_welcomes-InfoTitle">Должность:&nbsp;</span>{user.position}</li>
                    <li className="NewsView-Body_type_welcomes-InfoItem">
                        <span className="NewsView-Body_type_welcomes-InfoTitle">Подраздиление:&nbsp;</span>{user.division}</li>
                </ul>
            </LinkLayout>

        </NewsView>
        )
    }
}

export const Welcomes = withNewsContext(connect(
    mapStateToProps
)(WelcomesPresenter));

export const initWelcomes = () => ComponentInjector.getInstance().addNode('news_types', <Welcomes />, 'newuser')