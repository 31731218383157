import { Reducer } from "redux";
import { ADV_CAL_CHANGE_CALENDAR_DAY, ADV_CAL_SET_CALENDAR } from "./actions.types";
import { baseAdventCalendarReducer } from "./constants";
import { NAdventCalendar } from "./types";

export const AdvernCalendarReducer: Reducer<NAdventCalendar.Reducer, NAdventCalendar.Actions> = (state = baseAdventCalendarReducer, action) => {
    switch (action.type) {
        case ADV_CAL_SET_CALENDAR: {
            return {
                ...state,
                [action.payload.id]: action.payload.items
            };
        };
        case ADV_CAL_CHANGE_CALENDAR_DAY: {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    [action.payload.item.date]: {
                        ...state[action.payload.id][action.payload.item.date],
                        ...action.payload.item
                    },
                }
            }
        }
        default: {
            return state;
        };
    };
};