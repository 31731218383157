import React, { useEffect, useState, useCallback, FC, useMemo, forwardRef, ComponentProps } from 'react';
import { withTranslation } from 'react-i18next';
import { IShopCardProps, mapDispatchToProps, mapStateToProps, cnShopProduct } from './ProductCardMui.index';
import { withRouter, useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import i18n from 'localizations/i18n';
import { openShopMakeOrder } from 'blocks/Dialogs/Shop/ShopMakeOrder/ShopMakeOrder';
import * as utilsProj from 'utils.project/utils.project';
import { Characteristics as CharacteristicsTypes } from 'utils/src/requests/models/api.shop'
import { Icon, ListPlaceholder } from 'uielements/src';
import { SkeletonProductCard } from 'uielements/src/Skeleton';
import shopPlaceholder from 'assets/svg/shopPlaceholder.svg';
import { orderIsDenied } from '../validation'
import { countMissingThanks, getPriceData } from '../utils'
import PhotoViewerO from '../PhotoViewer/PhotoViewer.index'
import { CommentsRender } from 'News/containers/CommentsRender'
import { withIndicator, ILoadingProps } from 'utils/src/CommonRedux/LoadIndicator'
import { SHOP_CHECK_CHARACTERISTICS, SHOP_GET_SHOP_PRODUCT } from 'redux/shop/actions'
import { IShopItem } from 'utils/src/requests/models/api.shop';
import { createModal, IBodyProps } from 'utils/src/DialogCreator'
import DialogBody from 'uielements/src/MaterialElements/DialogParts/DialogBody'
import MDRender from 'uielements/src/CommonmarkRender'
import { Link } from 'muicomponents/src/Link/Link'
import { LoadingButton } from 'muicomponents/src/LoadingButton/LoadingButton'
import { ILoadingButton } from 'muicomponents/src/LoadingButton/LoadingButton.index'
import { Box, Button, CircularProgress, Divider, Paper, styled, Typography } from 'muicomponents/src';
import { PriceElem } from '../Item/PriceElem';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { IconButton } from 'muicomponents/src/IconButton';
import { API } from 'utils/src/utils'
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { Tooltip } from 'muicomponents/src/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Characteristics from '../Characteristics/Characteristics.index';
import { RatingShopMui } from './RatingShopMui/RatingShopMui';
import { CurrentCategory } from '../CurrentCategory/CurrentCategory';
import { Translate } from 'localizations/Translate';
import { TotalElem } from '../Item/TotalElem';
import { CountSelector } from '../CountSelector';
import * as utils from 'utils/src/utils';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { toast } from 'react-toastify';
import { currentUserMinusMyThanksCount } from 'utils/src/CommonRedux/users/actions';
import { getCurrencyNames } from 'utils/src/CommonRedux/base/selectors';
import { StyledCircularProgress } from 'muicomponents/src/FilePreviewUploader/FilePreviewUploader.styled';
import { Chip } from 'muicomponents/src/Chip/Chip'
import { contextAction } from 'News/redux/actions'
import { ShopListContextProvider, useShopListContext } from '../ShopList/ShopListMui.context';
import { Location } from 'history';
import { mainUrls } from 'utils/src';

const LayoutPaperStyle = {
  padding: '24px', maxWidth: '1094px', margin: '24px'
}

const InfoBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  '.CommonmarkRender-Paragraph': {
    fontWeight: '500',
  },
})

const SpinBtn = withIndicator<ILoadingButton & ILoadingProps>(
  forwardRef(({ isLoading, withError, ...props }, ref) => {
    return <LoadingButton {...props} loading={isLoading} ref={ref} />
  }),
  SHOP_CHECK_CHARACTERISTICS
)();
const PhotoViewer = withIndicator(PhotoViewerO, SHOP_CHECK_CHARACTERISTICS)()

const prepareRatingValue = (rating?: number) => {
  if (!rating) return 0
  if (rating === -1) return 0
  return rating
}

const isAnyCharacteristicChosen = (characteristics: CharacteristicsTypes.IItem[]) => {
  if (characteristics.length === 0) return true
  const selectedArr = characteristics.map((characteristic) => {
    return characteristic.tags.some(tag => tag.isSelected)
  })
  return selectedArr.some(item => item)
}

type IsFavouriteType = "addToFavorites.on" | "addToFavorites.off"


const DescriptionDialog: FC<{ disclaimer: string } & IBodyProps> = ({ handleAccept, handleClose, disclaimer }) => {
  return <DialogBody
    header={i18n.t('pryaniky.shop.product.dsc.dialog.header')}
    contentPadding={'normal'}
    acceptText={i18n.t('pryaniky.shop.product.dsc.dialog.accept')}
    closeText={i18n.t('pryaniky.shop.product.dsc.dialog.close')}
    onClose={handleClose}
  >
    <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
  </DialogBody>
}
const openDiscription = createModal(DescriptionDialog)


const ProductCardLayout: FC = ({ children }) => {

  const history = useHistory<{ from: Location }>();

  const onClickHandler: ComponentProps<typeof Button>['onClick'] = (e) => {
    if(history.location.state?.from && history.location.state.from.pathname === mainUrls.shop) {
      e.preventDefault();
      history.goBack();
    }    
  };

  return <>
    <Paper sx={LayoutPaperStyle}>
      <Button to={mainUrls.shop} variant='outlined' sx={{ textTransform: 'lowercase' }} size='small' onClick={onClickHandler}>
        <ArrowBackIcon sx={{ mr: 1, width: '20px' }} />
        {i18n.t('pryaniky.shop.product.backToShop')}
      </Button>
    </Paper>
    <Paper sx={LayoutPaperStyle} >
      {children}
    </Paper>
  </>
}

const ProductCardComments: React.FC<any> = ({ data }) => {
  const allowFeedback = data.news && data.news.allowReply && data.allowComments
  return <>
    {(data.news && data.allowComments) &&
      <CommentsRender id={data.news.id} className={cnShopProduct('Comments', { allowFeedback })} />}

    {(!allowFeedback && data.allowComments) &&
      <div className={cnShopProduct('CommentsDisabled')}>{i18n.t('pryaniky.shop.product.comments.disabled')}</div>}
  </>
}

const ProductPreloader: React.FC<IShopCardProps> = (props) => {
  const { data, ...p } = props

  // const {replace} = useHistory()
  // Загрузка товара
  useEffect(() => {
    if (`${data?.id}` !== p.match.params.id) p.getShopProduct(p.match.params.id);
  }, [p.match.params.id])

  if (props.isLoading) {
    return <ProductCardLayout>
      <SkeletonProductCard />
    </ProductCardLayout>
  }
  if (!data) {
    return <ProductCardLayout>
      <SkeletonProductCard />
    </ProductCardLayout>
  }
  // if(props.withError) {
  //   replace('/shop')
  // }
  return <ShopListContextProvider>
    <ProductCardPresenter {...p} data={data} />
  </ShopListContextProvider>
}

const ProductCardPresenter: React.FC<IShopCardProps & { data: IShopItem }> = ({
  data,
  setShopProduct,
  isHidePricesAndButtonBuy,
  additionalInformationBeforeOrder,
  maxOrdersCount,
  maxOrdersPeriod,
  ordersCount,
  userThanksCount,
  currencyFormats,
  contentRating,
  currentImgUrl,
  selectedCharsCount,
  checkCharacteristics,
  allowOrder,
  errorMessage,
  currentImage,
  news,
  isHideButtonBuy,
  ...props
}) => {
  const dispatch = useDispatch();
  
  const context = useShopListContext();

  const { characteristics = [], thanksPrice, id, minVirtCurrencyPayment, availableCount, isUnlimitedProduct } = data
  const onChangeChar = useCallback((value: CharacteristicsTypes.IItem[]) => {
    checkCharacteristics(id, value)
  }, [id])

  // const { price, priceCurrency } = getPriceData(data?.thanksPriceTxt || '');
  const [countToOrder, setCountToOrder] = useState<number | undefined>(1);

  // для расчета, хватает ли юзеру пряников, если цена товара завивит от характеристик: 
  // пока характеристики не выбраны юзером, сравниваем userThanksCount с минимальной ценой на товар, если выбраны характеристики, то уже с ценой на конкретный товар 
  const priceDependsOnChars = characteristics.some(char => char.usedForPrice) && minVirtCurrencyPayment > -1
  const areCharsTouched = isAnyCharacteristicChosen(characteristics)
  const priceForUserMoneyCount = (!priceDependsOnChars || areCharsTouched || minVirtCurrencyPayment === -1) ? thanksPrice : minVirtCurrencyPayment
  const priceByCount = !isNaN(priceForUserMoneyCount) ? (countToOrder || 0) * priceForUserMoneyCount : priceForUserMoneyCount;
  const missingThanks = countMissingThanks(priceByCount, userThanksCount);

  const isAmountValid = isUnlimitedProduct || availableCount
  const isNotCanBuy = orderIsDenied({ characteristics, maxOrdersCount, maxOrdersPeriod, ordersCount, allowOrder, errorMessage })
  const cannotBuyWhileChars = isNotCanBuy === 'Товар с выбранными параметрами закончился'

  async function openDescription() {
    if (data.disclaimer) {
      try {
        const result = await openDiscription({ disclaimer: data.disclaimer })
      } catch (error) {
        console.warn(error)
      }
    }
  }

  ///
  const isFavouriteFromNew: IsFavouriteType = news?.actions.includes("addToFavorites.on") ? "addToFavorites.on" : "addToFavorites.off"
  const isFavourite = news ? isFavouriteFromNew === "addToFavorites.off" : false
  async function addToFavourites() {
    if (!news?.id || !news) {
      console.warn('No news data')
      return
    }

    dispatch(contextAction({
      action: isFavouriteFromNew,
      id: news.id,
      disableConfirm: true,
      disableToast: true
    }))
  }
  ////

  const useCountSelector = useMemo(() => {
    return data.externalServiceType !== 'Giftery' && !data.isVirtualProduct;
  }, [data.externalServiceType]);

  const maxForCountSelector = useMemo(() => {
    return !isUnlimitedProduct
      ? selectedCharsCount || availableCount
      : 9999;
  }, [isUnlimitedProduct, selectedCharsCount, availableCount]);

  return <>
    <ProductCardLayout>

      {data.isActive ?
        <Box sx={{ display: 'flex', mb: '16px', position: "relative" }} className={cnShopProduct('Main')}>

          <PhotoViewer
            images={data.images}
            currentImage={currentImage}
            currentImgUrl={currentImgUrl}
            imgUrls={data.images.map(el => el.previewUrl)}
            onChange={(currentImgUrl, currentImage) => setShopProduct({ currentImgUrl, currentImage })}
            className={cnShopProduct('Photos')}
          />
          {data.isGift && <Chip label={<Translate i18nKey={`pryaniky.shop.isGift`} />}
            className={cnShopProduct("CanBeGiftedChip")}
            variant='filled'
            sx={{ position: "absolute", color: "#fff", background: "rgba(245, 0, 87, 1)", top: "8px", left: "8px", zIndex: "60" }}
          />}
          <InfoBox className={cnShopProduct("Info")}
            sx={{ marginLeft: '24px', width: '60%', }} >

            <Box className={cnShopProduct("Info-Header")} sx={{ order: 0 }} >

              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant='h6' fontWeight='600'>{data.name}</Typography>
                <Tooltip title={isFavourite ? i18n.t('pryaniky.post.actions.addToFavorites.off') : i18n.t('pryaniky.post.actions.addToFavorites.on')} >
                  <IconButton  size='large' color='primary' onClick={addToFavourites} >
                    {
                      isFavourite
                          ? <BookmarkIcon sx={{ height: '34px', width: '34px', }} />
                          : <BookmarkBorderIcon sx={{ height: '34px', width: '34px', }} />
                    }
                  </IconButton>
                </Tooltip>
              </Box>

              <MDRender className={cnShopProduct('MDRender')} disableEmojiOne disableShowMoreBtn textCut={false} source={data.desc} />

              {data.disclaimer &&
                <Box>
                  <Link
                    className={cnShopProduct("Info-DescMore")}
                    onClick={openDescription}>{i18n.t('pryaniky.shop.product.dsc.more')}<Icon icon={'question-circle'} /></Link>
                </Box>}

            </Box>

            {data.delivery && <>
              <Divider sx={{ order: 1 }} />
              <Box
                className={cnShopProduct("Info-Delivery")}
                sx={{ display: 'flex', alignItems: 'center', order: 2 }}
              >
                <Typography variant='subtitle2' fontSize='13px' fontWeight='600'>{i18n.t('pryaniky.shop.product.delivery')}</Typography>&nbsp;
                <Typography variant='subtitle2' fontSize='13px'>{data.delivery}</Typography>
              </Box>
            </>}

            {contentRating?.enable && data.news
              &&
              <>
                <Divider sx={{ order: 3 }} />
                <Box className={cnShopProduct("Info-Rating")} sx={{ order: 4 }} >
                  <RatingShopMui
                    className={cnShopProduct("Info-Stars")}
                    readOnly
                    value={prepareRatingValue(contentRating?.rating)}
                    rating={contentRating?.rating}
                    ratingCount={contentRating.count}
                  />
                </Box>
              </>
            }

            <Divider sx={{ order: 5 }} />
            <Box className={cnShopProduct("Info-CharacteristicsBox")} sx={{ order: 6 }}>
              <Characteristics characteristics={data.characteristics}
                onChange={onChangeChar} />
            </Box>

            <Box className={cnShopProduct("Info-Avaliable")} display='flex' alignItems='center' sx={{ order: 7 }}>
              <Typography variant='subtitle2' fontSize='13px' fontWeight='600'>{i18n.t('pryaniky.uielements.materialelements.TransferList.availableTitle')}: &nbsp; </Typography>
              <Typography variant='subtitle2' color='gray' fontSize='13px'>{data.isUnlimitedProduct ? i18n.t('pryaniky.shop.unlimited') : availableCount + i18n.t("pryaniky.shop.pc")}</Typography>
            </Box>
            {!isHidePricesAndButtonBuy
              &&
              <>
                <Divider sx={{ order: 8 }} />
                <Box className={cnShopProduct("Info-Order")} sx={{ order: 9, display: 'flex', alignItems: 'center', gap: '16px', flexWrap: 'wrap' }}>
                  <PriceElem
                    fromPriceText={priceDependsOnChars && !areCharsTouched
                      ? i18n.t('from.price')
                      : null}
                    price={priceForUserMoneyCount}
                    // const priceCurrencyText = utilsProj.getFormattedCurrencyName(currencyFormats, price, !characteristics.length ? 'nominative' : 'genitive');
                    priceCurrencyText={utilsProj.getFormattedCurrencyName(currencyFormats, priceForUserMoneyCount, !priceDependsOnChars || areCharsTouched ? 'nominative' : 'genitive')}
                  />
                  {
                    useCountSelector &&
                    <>
                      <Divider flexItem orientation={'vertical'} />
                      <CountSelector
                        className={cnShopProduct("Info-OrderCount")}
                        size={'small'}
                        value={countToOrder}
                        onChange={(e) => {
                          setCountToOrder(e.target.value);
                        }}
                        inputProps={{
                          max: maxForCountSelector
                        }}
                      />
                      <Divider flexItem orientation={'vertical'} />
                      <TotalElem
                        price={priceByCount}
                        priceCurrencyText={utilsProj.getFormattedCurrencyName(currencyFormats, priceByCount, 'nominative')}
                      />
                    </>
                  }
                </Box>
              </>
            }
            <Divider sx={{ order: 10 }} />

            <Box className={cnShopProduct('Info-SelectedAvaliable')} sx={{ order: 11 }}>
              {
                isUnlimitedProduct
                  ? <Translate i18nKey={'pryaniky.shop.unlimit'} />
                  : selectedCharsCount !== undefined
                    ? <>
                      <span>
                        <Translate i18nKey={'pryaniky.shop.avaliableCharsCount'} />
                        :&nbsp;
                      </span>
                      <Typography fontSize='14px' color={!selectedCharsCount ? 'error' : undefined} component={'b'} sx={{ fontWeight: 'bold' }}>
                        {selectedCharsCount}
                        <Translate i18nKey={'pryaniky.shop.pc'} />
                      </Typography>
                    </>
                    : <>
                      <span>
                        <Translate i18nKey={'pryaniky.shop.avaliableCount'} />
                        :&nbsp;
                        <Typography fontSize='14px' component={'b'} sx={{ fontWeight: 'bold' }}>
                          {availableCount}
                          <Translate i18nKey={'pryaniky.shop.pc'} />
                        </Typography>
                      </span>
                    </>
              }
            </Box>

            {!isHideButtonBuy && <Box className={cnShopProduct('Info-Actions')} sx={{ order: 12, display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'flex-start' }}>
              {isAmountValid ? <>
                {missingThanks > 0 ? (
                  <SpinBtn variant='outlined' size='large' disabled className={cnShopProduct('Info-Missing')}>
                    <Typography color='error'>
                      {i18n.t('missing')} {missingThanks} {utilsProj.getFormattedCurrencyName(currencyFormats, missingThanks, 'genitive')}
                    </Typography>
                  </SpinBtn>
                ) : (
                  <>
                    <Tooltip
                      className={'InfoIcon'}
                      title={isNotCanBuy || ''}
                    >
                      <span>
                        <SpinBtn
                          variant={(Boolean(isNotCanBuy) || !countToOrder) ? 'outlined' : (cannotBuyWhileChars ? 'outlined' : 'contained')}
                          className={cnShopProduct("Info-Order")}
                          size='large'
                          disabled={Boolean(isNotCanBuy) || !countToOrder || maxOrdersCount < 1}
                          onClick={() => {
                            openShopMakeOrder({
                              data,
                              preselectedUsersId: context.userId,
                              withCount: useCountSelector,
                              selectedCount: countToOrder,
                              maxCount: maxForCountSelector,
                              additionalInformationBeforeOrder
                            })
                              .then((value: any) => {
                                const { id, count, thanksPrice, ...params } = value;
                                utils.API.shop.orderV4(
                                  id,
                                  count,
                                  params
                                )
                                  .r.then((response: IBasicResponse) => {
                                    if (utils.checkResponseStatus(response)) {
                                      dispatch(currentUserMinusMyThanksCount(data.thanksPrice * value.count))
                                      toast.success(i18n.t('your order is successfully placed'));
                                    } else {
                                      toast.error(response.error_text);
                                    }
                                  });
                              })
                              .catch()
                          }}>
                          <Typography>
                            {cannotBuyWhileChars ?
                              i18n.t("pryaniky.shop.product.isOutOfStock") :
                              i18n.t('pryaniky.shop.product.order')
                            }
                          </Typography>
                        </SpinBtn>
                      </span>
                    </Tooltip>
                  </>
                )}
              </>
                :
                <SpinBtn disabled variant='outlined' size='large' className={cnShopProduct('NotAvailable')}>
                  {i18n.t("pryaniky.shop.product.isOutOfStock")}
                </SpinBtn>
              }
            </Box>
            }

          </InfoBox>
        </Box> :

        <ListPlaceholder
          type='shopClosed'
          imageSrc={shopPlaceholder}
          title={i18n.t('pryaniky.shop.product.notActive.title')}
          text='' />}

      <ProductCardComments data={data} />

    </ProductCardLayout>

    {!!data.categories.length &&
      <CurrentCategory
        id={id}
        categories={data.categories}
        myThanksCount={userThanksCount}
      />
    }

  </>
}

export const ProductCardMui = withIndicator(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(withRouter(ProductPreloader))), SHOP_GET_SHOP_PRODUCT)();