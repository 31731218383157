import React, { FC, useState, useEffect, useRef } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IScormUnitPresenterProps,
    IScormUnitOwnProps,
    IDispatchProps,
    IScormUnitStateProps,
    IScormUnitState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'

import FormControlO from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PaperO from '@material-ui/core/Paper';
import { markdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap'
import { controlStyle, peperStyle, controlLabelStyle } from './style'
// import { PryanikyEditor } from 'blocks/PryanikyEditor/_PryanikyEditor'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PryanikyEditor from 'uielements/src/PryanikyEditorV1'
import { styled } from '@material-ui/core/styles';
import { validator, fieldWithError } from '../unitValidation'
import UploadButton from 'uielements/src/MaterialElements/UploadButton'
import * as utils from 'utils/src/utils';
import uuid from 'uuid';
import constants from 'LMSModule/utils/scormAPI/constants'
import ScormAPI2004 from 'LMSModule/utils/scormAPI/scormAPI2004/scormAPI2004'
import ScormAPI12 from 'LMSModule/utils/scormAPI/scormAPI12/scormAPI12'
import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg'

// const TextFieldHelped = fieldWithHelp(TextField)
const Paper = styled(PaperO)(peperStyle)
const FormControlLabelHelped = styled(fieldWithHelp(FormControlLabel))(controlLabelStyle)
const FormControlHelped = styled(fieldWithHelp(FormControlO))(controlStyle)
const FormControl = styled(FormControlO)(controlStyle)
// import i18n from 'localizations/i18n';

class ScormAPI2004_Tester extends ScormAPI2004 {

    /**
     * 
     */
    public version = "1.0";

    private logger = (values: string[]) => console.log(values);


    constructor(logger: (values: string[]) => void) {
        super()
        this.logger = logger;
        this.apiLogLevel = 3
    }

    public Initialize() {
        this.logger(['initialize'])
        return super.Initialize();
    }

    public Terminate = () => {
        // this.logger(['SCORM Terminate'])
        console.log('SCORM Terminate')
        return super.Terminate();
    }

    public GetValue(CMIElement: any) {
        // this.logger(['SCORM GetValue', CMIElement])
        console.log('SCORM GetValue', CMIElement)
        return super.GetValue(CMIElement);
    }

    public SetValue(CMIElement: any, value: any) {
        this.logger(['SetValue', CMIElement, value])
        console.log('SCORM SetValue', CMIElement, value)
        return super.SetValue(CMIElement, value);
    }

    public Commit() {
        // this.logger(['SCORM Commit'])
        console.log('SCORM Commit')
        return super.Commit();
    }

    public GetLastError() {
        // this.logger(['SCORM GetLastError'])
        return super.GetLastError();
    }

    public GetErrorString(CMIErrorCode: any) {
        // this.logger(['SCORM GetErrorString', CMIErrorCode])
        return super.GetErrorString(CMIErrorCode);
    }

    public GetDiagnostic(CMIErrorCode: any) {
        // this.logger(['SCORM GetDiagnostic', CMIErrorCode])
        return super.GetDiagnostic(CMIErrorCode);
    }

    public setCMIValue = (CMIElement: any, value: any) => {
        // this.logger(['SCORM setCMIValue', CMIElement, value])
        console.log('SCORM setCMIValue', CMIElement, value)
        return super.setCMIValue(CMIElement, value);
    }

    public getCMIValue(CMIElement: any) {
        // this.logger(['SCORM getCMIValue', CMIElement])
        console.log('SCORM getCMIValue', CMIElement)
        return super.getCMIValue(CMIElement);
    }

    public reset() {
        // this.logger(['SCORM reset'])
        super.reset();
    }

}

class ScormAPI12_Tester extends ScormAPI12 {

    /**
     * 
     */
    public version = "1.0";

    private logger = (values: string[]) => console.log(values);


    constructor(logger: (values: string[]) => void) {
        super()
        this.logger = logger;
    }

    public LMSInitialize() {
        this.logger(['initialize'])
        return super.LMSInitialize();
    }

    public LMSFinish = () => {
        // this.logger(['SCORM Terminate'])
        console.log('SCORM LMSFinish')
        return super.LMSFinish();
    }

    public LMSGetValue(CMIElement: any) {
        // this.logger(['SCORM GetValue', CMIElement])
        console.log('SCORM LMSGetValue', CMIElement)
        return super.LMSGetValue(CMIElement);
    }

    public LMSSetValue(CMIElement: any, value: any) {
        this.logger(['SetValue', CMIElement, value])
        console.log('SCORM LMSSetValue', CMIElement, value)
        return super.LMSSetValue(CMIElement, value);
    }

    public LMSCommit() {
        // this.logger(['SCORM Commit'])
        console.log('SCORM LMSCommit')
        return super.LMSCommit();
    }

    public LMSGetLastError() {
        // this.logger(['SCORM GetLastError'])
        return super.LMSGetLastError();
    }

    public LMSGetErrorString(CMIErrorCode: any) {
        // this.logger(['SCORM GetErrorString', CMIErrorCode])
        return super.LMSGetErrorString(CMIErrorCode);
    }

    public LMSGetDiagnostic(CMIErrorCode: any) {
        // this.logger(['SCORM GetDiagnostic', CMIErrorCode])
        return super.LMSGetDiagnostic(CMIErrorCode);
    }

    public setCMIValue = (CMIElement: any, value: any) => {
        // this.logger(['SCORM setCMIValue', CMIElement, value])
        console.log('SCORM setCMIValue', CMIElement, value)
        return super.setCMIValue(CMIElement, value);
    }

    public getCMIValue(CMIElement: any) {
        // this.logger(['SCORM getCMIValue', CMIElement])
        console.log('SCORM getCMIValue', CMIElement)
        return super.getCMIValue(CMIElement);
    }

    public reset() {
        // this.logger(['SCORM reset'])
        super.reset();
    }

}

export const Presenter: FC<IScormUnitPresenterProps> = ({
    className,
    unit,
    updateUnit,
    saveLesson,
    setUnitError,
    errors,
    maxFileSize,
    currentUser
}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [scormMsg, setScormMsg] = useState<string[]>([])
    const [scormInit, setScormInit] = useState<boolean>(false)
    const [scormComplete, setScormComplete] = useState<boolean>(false)
    const [scormSuccess, setScormSuccess] = useState<boolean>(false)
    const [CMILog, setCMILog] = useState<{ [k: string]: string }>({})
    const logRef = useRef(CMILog)
    logRef.current = CMILog

    const setMsg = (val: string[]) => {
        switch (val[0]) {
            case 'SetValue':
                setCMILog({
                    ...logRef.current,
                    [val[1]]: val[2]
                })
                if (val[1] === 'cmi.completion_status') {
                    setScormComplete(val[2] === 'completed')
                }
                if (val[1] === 'cmi.success_status') {
                    setScormSuccess(val[2] === 'passed' || val[2] === 'failed')
                }
                break;
            case 'initialize':
                setScormInit(true)
                break;

            default:
                break;
        }
        // setUnitError({ initScorm: [] })
        setScormMsg(val)
    }
    // if (unit.unitType !== 'wiki') return <div>not supported unit type</div>
    const changeCheck = (property: string) => () => {
        updateUnit({ ...unit, [property]: !unit[property] })
    }
    const changeDraft = (v: any) => {
        updateUnit({ ...unit, draft: v })
    }
    const changeText = (property: string) => (e: any) => {
        // setUnitError({ ...validator(property, e.target.value), initScorm: scormMsg.includes('initialize') ? [] : ['not_init'] })
        updateUnit({ ...unit, [property]: e.target.value })
    }
    const changeAutoWidth = () => {
        const scormUnitWidth = unit.scormUnitWidth === -1 ? 0 : -1
        updateUnit({ ...unit, scormUnitWidth })
    }

    useEffect(() => {
        setScormInit(false)
        setScormComplete(false)
        setScormSuccess(false)
        // setUnitError({ initScorm: scormMsg.includes('initialize') ? [] : ['not_init'] })
        const api = unit.scormFileVersion === "scorm12" ? new ScormAPI12_Tester(setMsg) : new ScormAPI2004_Tester(setMsg);


        const apiKEY = unit.scormFileVersion === "scorm12" ? 'API' : 'API_1484_11';
        if (apiKEY === 'API_1484_11') {
            // (api as ScormAPI2004_Tester).Initialize();
            console.log((api as ScormAPI2004_Tester).setCMIValue('cmi.learner_name', currentUser.baseData.displayName));
            console.log((api as ScormAPI2004_Tester).GetLastError());
            (api as ScormAPI2004_Tester).setCMIValue('cmi.learner_id', currentUser.baseData.id);
            // (api as ScormAPI2004_Tester).currentState = 0;
        }
        // const api = new ScormAPI2004();
        (window as any)[apiKEY] = api

    }, [unit.id, unit.scormFileId, unit.scormFileVersion])

    const upload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;
        setLoading(true)
        for (const file of e.target.files) {
            utils.API.lms.uploadScorm(file).then((v: any) => {
                // console.log(v)
                updateUnit({ ...unit, scormIndexUrl: v.data.indexUrl, 'scormFileId': v.data.fileId, scormFileVersion: v.data.version })
                setLoading(false)
            })
            // (file as any).id = uuid.v1()
            // utils.API.files.upload(file, {
            //     loadStart: () => {
            //         setLoading(true)
            //     },
            //     loadProgress: () => { },
            //     loadFinish: (response, file) => {
            //         setLoading(false)
            //         for (const file of response.data) {
            //             console.log(file)
            //             updateUnit({ ...unit, 'scormFileId': file.id })
            //             // onChange([...value, file])
            //         }

            //     }
            // });
        }
    }

    return <div className={cnClassName({}, [className])}>
        <Paper elevation={0} square>

            <FormControl fullWidth >
                <TextField
                    label="Название"
                    error={fieldWithError(errors['name'])}
                    value={unit.name}
                    onChange={changeText('name')}
                />
            </FormControl>

            <FormControl fullWidth >
                <TextField
                    label="Описание"
                    value={unit.description}
                    multiline
                    onChange={changeText('description')}
                />
            </FormControl>

            <div>
                <FormControlLabelHelped
                    control={
                        <Checkbox
                            checked={unit.allowSkip}
                            onChange={changeCheck('allowSkip')}
                            name="allowSkip"
                            color="primary"
                        />
                    }
                    label={'Разрешить пропускать'}
                    help={'Разрешить пропускать'}
                />
            </div>

            <div className={cnClassName('allowComments')}>
                <FormControlLabelHelped
                    control={
                        <Checkbox
                            checked={unit.allowComments}
                            onChange={changeCheck('allowComments')}
                            name="allowComments"
                            color="primary"
                        />
                    }
                    label={'Разрешить комментарии'}
                    help={'Разрешить комментарии'}
                />
            </div>

            <div className={cnClassName('changeAutoWidth')}>
                <FormControlLabelHelped
                    control={
                        <Checkbox
                            checked={unit.scormUnitWidth === -1}
                            onChange={changeAutoWidth}
                            name="changeAutoWidth"
                            color="primary"
                        />
                    }
                    label={'Авто ширина'}
                    help={'Авто ширина'}
                />
            </div>

            <div className={cnClassName('changeSizes')}>
                <FormControl >
                    <TextField
                        type={'number'}
                        label="Ширина PX"
                        disabled={unit.scormUnitWidth === -1}
                        // error={fieldWithError(errors['name'])}
                        value={unit.scormUnitWidth}
                        onChange={changeText('scormUnitWidth')}
                    />
                </FormControl>

                <FormControlHelped style={{ margin: '12px 0' }} help={'Подберите оптимальную высоту окна с курсом так, что бы поля были минимальны или отсутствовали'}>
                    <TextField
                        type={'number'}
                        label="Высота PX"
                        // error={fieldWithError(errors['name'])}
                        value={unit.scormUnitHeight}
                        onChange={changeText('scormUnitHeight')}
                    />
                </FormControlHelped>

                {/* <FormControl >
                    <TextField
                        type={'number'}
                        label="Высота PX"
                        // error={fieldWithError(errors['name'])}
                        value={unit.scormUnitHeight}
                        onChange={changeText('scormUnitHeight')}
                    />
                </FormControl> */}
            </div>

            <div className={cnClassName('Upload')}>
                <UploadButton accept={'application/zip'} loading={loading} onChange={upload} >Загрузить курс</UploadButton>
            </div>

            {unit.scormFileId && <>
                <Paper elevation={3} square className={cnClassName('Preview')}>
                    <ErrorMsg
                        className={cnClassName('Message')}
                        type={scormInit ? 'success' : 'error'}
                        children={scormInit ? 'SCORM инициализирован' : 'Инициализация SCORM не произведена'}
                    />
                    {window.PRN_SERVICE.dev_features && <>
                        <ErrorMsg
                            className={cnClassName('Message')}
                            type={scormComplete ? 'success' : 'error'}
                            children={scormComplete ? 'SCORM завершен' : 'SCORM не завершен'}
                        />

                        <ErrorMsg
                            className={cnClassName('Message')}
                            type={scormSuccess ? 'success' : 'error'}
                            children={scormSuccess ? 'SCORM статус определён' : 'SCORM статус не определён'}
                        />
                    </>}
                </Paper>
                <iframe
                    key={unit.id}
                    // onLoad={onLoadFrame}
                    // ref={ref}
                    // src={'http://localhost:3000/test5/shared/launchpage.html'}
                    // src={'http://localhost:3000/test6/14-01-fall-2018/START.htm'}
                    // src={'http://localhost:3000/test7/10-01-spring-2020/START.htm'}
                    // src={'http://localhost:3000/test2/res/index.html'}
                    src={`${utils.API.base.requestDomain}/ru/Data/v3/LMS/Course/Unit/${unit.id}/Content/${unit.scormFileId}/${unit.scormIndexUrl || ''}`}
                    width={unit.scormUnitWidth === -1 ? '100%' : `${unit.scormUnitWidth}px`}

                    // height={'auto'}
                    height={`${(unit.scormUnitHeight)}px`}
                    className={cnClassName('Frame')}></iframe>
            </>
            }
        </Paper>
    </div>
}

export default connect<IScormUnitStateProps, IDispatchProps, IScormUnitOwnProps, IScormUnitState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
