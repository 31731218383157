import { connect } from 'react-redux';
import { mapStateToProps as mapStateToPropsList, IListState, cnList, mapActionsToProps } from "blocks/List/List.index";
import { mapDispatchToProps } from "redux/connector";
import { AbstractList } from '../../List';
import { IListTypeSettingsProps, IListTypeSettingsState } from './List_type_settings.index';

class ListTypeSettings extends AbstractList<IListTypeSettingsProps, IListTypeSettingsState>{
    public getData = () => {

    }

    public renderChildren = () => {

    }
}

export const List = connect<any, any, any, any>(
    mapStateToPropsList,
    mapDispatchToProps(mapActionsToProps)
)(ListTypeSettings);