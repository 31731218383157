import { Box, styled } from "muicomponents/src";

export const PageBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 16
}) as typeof Box;

export const PageHeaderBox = styled(Box)({
    
}) as typeof Box;

export const PageChildrenBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 16
}) as typeof Box;

export const PageWarningBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    gap: 16,
    border: '1px solid #2196F3',
    borderRadius: 4,
    alignSelf: 'stretch',
    flexGrow: 0,
    ['& span']: {
        color: '#305e83'
    }
}) as typeof Box;

export const PageActionsBox = styled(Box)({
    display: 'flex',
    gap: 12
}) as typeof Box;