import { objectArrayToMap } from 'utils/src/utils'
import { IKPIItem } from '../../types'
export const normalizeKPI = (list: IKPIItem[]) => {
    const values = objectArrayToMap(list, 'id')
    const keys = Object.keys(values)
    return {
        values,
        keys,
    }
}
