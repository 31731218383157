import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type ITaskUnitProps = ITaskUnitOwnProps

export interface ITaskUnitState { }

export interface ITaskUnitOwnProps extends IClassNameProps {
    course: any,
    unit: any,
    log: any,
    sId: string,
    unitContent: any,
    fullscreen?: boolean
}

export const cnClassName = cn('LMSTaskUnit');