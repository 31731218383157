import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import i18n from '../localizations/i18n';
import { CustomSettings } from 'utils/src';
import { PreviewParams } from 'utils/src/PreviewParams';
interface IWikiList {
    wikilist: string;
    filters: string;
}

export const wikilist = (settings?: {[key: string]: any}): IWidget<IColumn[]> => {
    const ids = guids<IWikiList>(['filters', 'wikilist']);
    return generateWidget<IColumn[]>({
        type: widgets.types.layout,
        data: [
            generateColumn({
                items: [
                    generateWidget({
                        type: widgets.types.list + '/wikilist',
                        settings,
                        styles: {
                            width: '100%',
                        },
                    }),
                ],
            }),
        ],
    });
}