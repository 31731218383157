import React from 'react';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { UserRatings } from 'blocks/UserRatings/UserRatings';
import { IWidgetTypeRatingsProps } from './Widget_type_userRatings.index';

const types = {
  common: UserRatings,
}

export default class WidgetTypeUserRatings extends WidgetPresenter<IWidgetTypeRatingsProps> {

  public render() {
    if (!this.props.widget) return null;
    const Component = this.getComponent(types);
    return <Component {...this.props} settings={this.props.widget.settings} context={this.props.wcontext} tag={widgets.components.common} />
  }
}
