import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { loadDirList } from 'redux/actions/Files'

export interface IMoveFilesListProps extends IClassNameProps, IMoveFilesListStateProps, IDispatchProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
}

export interface IMoveFilesListState {

}

export interface IMoveFilesListStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    folders: state.files.moveDialog.folders
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadDirList
}, dispatch);

export const cnMoveFilesList = cn('MoveFilesList');