import React, { useCallback, RefForwardingComponent, forwardRef, useEffect, useRef, createRef, ComponentProps, useImperativeHandle, useMemo } from "react";
import { styled } from 'muicomponents/src/mui/system';
import { getDataFromFrame, postDataToFrameAfterCall } from "utils/src";
import { useSelector } from "react-redux";
import { getAppDesignSettings, getAppSettings } from "utils/src/CommonRedux/base/selectors";



const editorActionsDictionary = {
    subset: {
        get: 'getFontsSubset',
        set: 'setFontsSubset'
    },
    allowedComponents: {
        get: 'getAllowedComponents',
        set: 'setAllowedComponents'
    },
    extendsTemplates: {
        get: 'getExtendTemplates',
        set: 'extendTemplates'
    },
    currentImage: {
        get: 'getCurrentImage',
        set: 'giveCurrentImage'
    },
    additionalStyles: {
        get: 'getAdditionalStyles',
        set: 'setAdditionalStyles'
    }
} as const;

export type TCanvaEditor = {
    id: string;
    frame: HTMLIFrameElement | null;
    getImage: () => Promise<File>;
    extendTemplates: ( templates: () => Promise<any[]> | any[] ) => void;
}

export const EditorFrame = styled('iframe')({
    height: 700,
    width: '100%'
});

const rusSusets = ["cyrillic", "cyrillic-ext"]

const CanvaEditorPr: RefForwardingComponent<TCanvaEditor, {
    id: string;
    onSaveImage: (file: File) => void;
    // forwardRef: Ref<CanvaEditor>
}> = ({
    id,
    onSaveImage
}, refProps) => {

    const frameRef = createRef<HTMLIFrameElement>();

    const {
        tariff
    } = useSelector(getAppSettings);

    const design = useSelector(getAppDesignSettings);

    useEffect(() => {
        const cssMatch = design.customCss.replace(/\n|\r/g, '').match(/\/\* KONVA EDITOR CSS \*\/.+\/\* KONVA EDITOR CSS \*\/+/g);
        if(cssMatch) {
            postDataToFrameAfterCall(frameRef.current || id, editorActionsDictionary.additionalStyles.get, editorActionsDictionary.additionalStyles.set, cssMatch[0])
            .then((d) => {
                if(d === 'success') {
                    console.log(`set additinal styles to ${cssMatch[0].toString()} success`);
                }
            })
            .catch(() => {});
        }
        postDataToFrameAfterCall(frameRef.current || id, editorActionsDictionary.subset.get, editorActionsDictionary.subset.set, rusSusets)
        .then((d) => {
            if(d === 'success') {
                console.log(`setFontsSubset to ${rusSusets.toString()} success`);
            }
        })
        .catch(() => {});
        postDataToFrameAfterCall(frameRef.current || id, editorActionsDictionary.allowedComponents.get, editorActionsDictionary.allowedComponents.set, [])
        .then((d) => {
            if(d === 'success') {
                console.log(`setAllowedComponents to ${[].toString()} success`);
            }
        })
        .catch(() => {});
    }, []);

    const extendTemplates = useCallback<TCanvaEditor['extendTemplates']>(( templates ) => {
        postDataToFrameAfterCall(frameRef.current || id, editorActionsDictionary.extendsTemplates.get, editorActionsDictionary.extendsTemplates.set, templates)
        .then((d) => {
            if(d === 'success') {
                console.log('extendTemplates success');
            }
        })
        .catch(() => {});
    }, [ id, frameRef.current ]);
    
    useImperativeHandle(refProps, () => ({
        id,
        frame: frameRef.current,
        getImage: async function () {
            const blob = await getDataFromFrame<Blob>(frameRef.current || id, editorActionsDictionary.currentImage.get, undefined, editorActionsDictionary.currentImage.set)
            return new File([blob], 'card.png', { type: blob.type });
        },
        extendTemplates
    }), [ id, frameRef.current, extendTemplates ]);
    
    const postEvent = (event: MessageEvent<any>) => {
        if(event.data.event === 'saveConvaImage') {
            const blob = event.data.data;
            if(!(blob instanceof Blob)) {
                console.error('DATA IS NOT VALID BLOB');
                return ;
            }
            const file = new File([blob], 'card.png', { type: blob.type });
            onSaveImage(file);
        }
    };

    useEffect(() => {
        window.addEventListener("message", postEvent);
        return () => {
            window.removeEventListener('message', postEvent);
        };
    }, [postEvent]);

    const url = useMemo(() => {
        if(tariff === 'MagistrPremice') {
            if((window as any).konvaEditorWithIndex) return `/Content/konva/index.html?frameId=${id}&fixed=true`;
            return `/Content/konva?frameId=${id}&fixed=true`;
        }
        return `https://editor.zykli.com?frameId=${id}&fixed=true`
    }, [tariff, id]);
    
    return (
        <EditorFrame ref={frameRef} id={id} src={url} />
        // <EditorFrame
        //     sx={{ background: 'url("https://ideasperi.v5.pryaniky.com/Img/UserProfiles/Preview/96m96/512cfceb-ece9-44eb-bbbe-ebb4f521ec11.png?v=144f9fc0-11a4-4494-9b41-d958776453fd")' }}
        //     ref={frameRef}
        //     id={id}
        //     src={`http://localhost:3001?frameId=${id}&fixed=true`}
        //     />
    );
};

export const CanvaEditor = forwardRef(CanvaEditorPr);