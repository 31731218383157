import { Box } from "muicomponents/src";
import React, { FC, useLayoutEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { getAppDesignSettings } from "utils/src/CommonRedux/base/selectors";
import { AppFooterProps } from "./AppFooter.index";

export const AppFooter: FC<AppFooterProps> = () => {

    const [root, setRoot] = useState<HTMLElement| null>();

    useLayoutEffect(() => {
        setRoot(document.getElementById('AppFooter'));
    }, []);

    // const root = useMemo(() => {
    //     return document.getElementById('AppFooter');
    // }, []);

    let {
        footerHtml
    } = useSelector(getAppDesignSettings);

    if(!root || !footerHtml) return null;

    return createPortal(
        <Box
            dangerouslySetInnerHTML={{ __html: footerHtml }}
        />,
        root
    );
};