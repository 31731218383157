import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { setAnswerId } from 'redux/actions/Quiz'

export interface IAnswerItem extends IAnswerItemStateProps, IAnswerItemDispatchProps {
  item: any
  highlightCorrectness?: boolean
  qtype: string
}

export const mapStateToProps = (state: IState) => {

  return {
    answersIds: state.quiz.quiz.answersIds,
    answered: state.quiz.quiz.answered,
    quizType: state.quiz.quiz.openQuizData.quizType,
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  setAnswerId
}, dispatch);

export type IAnswerItemStateProps = ReturnType<typeof mapStateToProps>
export type IAnswerItemDispatchProps = ReturnType<typeof mapDispatchToProps>


export const cnAnswerItem = cn('AnswerItem')