import React, { FC, useState } from "react"
import { Grid } from 'muicomponents/src/Grid';
import { useResizeHandler } from 'utils/src/hooks';
import { Box } from "muicomponents/src";
import { withResizeWatherProvider } from "muicomponents/src/ContainerResizeWatcher/ContainerResizeWatcher";
import { PhotoSkeleton } from "../Item/PhotoSkeleton";
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/system';


export const useShopGridWidth = (target: 'catalog' | 'shopFilters') => {
  const cardWidth = 265
  const [colsAmount, setColsAmount] = useState(4)
  const [gridWidth, setGridWidth] = useState(1000)

  const ref = useResizeHandler((el) => {
    const amount = Math.floor(el.clientWidth / cardWidth)
    setColsAmount(amount)
    const margins = target === 'catalog' ? amount - 1 : amount - 2
    setGridWidth(amount * cardWidth + margins * 24)
  }, false)

  return { ref, gridWidth, colsAmount }
}

const ResizeWathBox = withResizeWatherProvider(Box)
export const ListContainer = styled(ResizeWathBox)({
  display: 'flex', flexWrap: 'wrap', gap: '24px'
}) as typeof ResizeWathBox;

// export const ListContainer: FC<{}> = ({ children }) => {

//   const { ref, gridWidth, colsAmount } = useShopGridWidth('catalog')

//   return <Box sx={{ display: 'flex', }} ref={ref}  >
//     <Grid sx={{
//       background: 'transparent',
//       maxWidth: `${gridWidth}px`
//     }}
//       container spacing={3} columns={colsAmount} >
//       {children}
//     </Grid>
//   </Box>
// }


export const ShopCatalogSkeleton = () => {

  const items = new Array(18).fill('1')

  const { ref, gridWidth, colsAmount } = useShopGridWidth('catalog')

  return <Box sx={{ display: 'flex', }} ref={ref} >
    <Grid sx={{
      background: 'transparent',
      maxWidth: `${gridWidth}px`
    }}
      container spacing={3} columns={colsAmount} >
      {items.map((item, i) => (
        <Grid item sx={{/* height: '402px' */ }} xs={1} >
          <Box sx={{ borderBottom: '1px solid transparent', width: '255px', }} >
            <PhotoSkeleton />
            <Box p={2} >
              <Skeleton variant="text" />
              <Box display='flex' alignItems='center' >
                <Box>
                  <Skeleton variant="circular" height={40} width={40} sx={{ flex: 1, mr: '16px' }} />
                </Box>
                <Box flex={3}>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Box>
              </Box>
              <Skeleton variant="text" />
              <Skeleton variant="text" height={70} />
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
}