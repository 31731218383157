import { UeventNews } from './EventsType'
import { Rule, BaseValidator, validateArray, validateString, validateNumber } from '../Base.validate'
export const ueventValidate: Rule[] = [
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'uevent.title',
        value: 1,
        errorI18nKey: 'pryaniky.validate.news.uevent.title.min',
        // message: 'small title'
    },
    /*{
        type: 'string',
        method: 'STR_MAX',
        field: 'uevent.title',
        value: 40,
        message: 'big title'
    },*/
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'uevent.eventLocation',
        value: 3,
        errorI18nKey: 'pryaniky.validate.news.uevent.eventLocation.min',
        // message: 'small eventLocation'
    },
    {
        type: 'string',
        method: 'STR_MAX',
        field: 'uevent.eventLocation',
        value: 180,
        errorI18nKey: 'pryaniky.validate.news.uevent.eventLocation.max',
        // message: 'big eventLocation'
    },
]


export class EventsValidator extends BaseValidator<any> {
    protected otherValidate = (data: any, rule: Rule) => {
        switch (rule.field) {
            case 'uevent.title':
                return validateString(data.uevent.title, rule);
            case 'uevent.eventLocation':
                return validateString(data.uevent.eventLocation, rule);
            case 'uevent.maxParticipantsCount':
                return validateNumber(data.uevent.maxParticipantsCount, rule);
            /*case 'poll.answer.text':
                for (const answer of data.poll.answers) {
                    if (!validateString(answer.text, rule)) return false;
                }*/
        }
        return true;
    }
}
