import actions from 'redux/actionsTypes/Quiz';
import i18n from 'localizations/i18n'

export interface IQuizState {
  quizWidget: IQuizWidgetState
  quiz: IQuizPageState
}

interface IQuizWidgetState {
  quizzesData: any[]
  activeQuizIdx: number
}

interface IQuizPageState {
  activeQuizIdx: number
  openQuizData: any
  activeQuistionIdx: number
  started: boolean
  answersIds: string[]
  answered: boolean
  finished: boolean
  rightAnswerCount: number
  loading: boolean
  userAnswerLogId: string
  result: any
  isShowResult: boolean
  setServerAnswered:boolean
  rewardIsPosted:boolean
}

const defaultQuiz: IQuizState = {
  quizWidget: {
    quizzesData: [],
    activeQuizIdx: 0,
  },
  quiz: {
    activeQuizIdx: 0,
    openQuizData: {},
    activeQuistionIdx: 0,
    started: false,
    answersIds: [],
    answered: false,
    finished: false,
    rightAnswerCount: 0,
    loading: false,
    userAnswerLogId: '',
    result: {},
    isShowResult: false,
    setServerAnswered: false,
    rewardIsPosted:false
  },
};

export function quizReducer(state: IQuizState = defaultQuiz, action: any): IQuizState {
  switch (action.type) {
    case actions.SET_QUIZ:
      return {
        ...state,
        quizWidget: {
          ...state.quizWidget,
          quizzesData: action.payload,
        }
      }
    case actions.CHANGE_ACTIVE_QUIZ_IDX:
      return {
        ...state,
        quizWidget: {
          ...state.quizWidget,
          activeQuizIdx: action.payload,
        }
      }
    case actions.SET_OPEN_QUIZ:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          openQuizData: action.payload,
        }
      }
    case actions.SET_REWARD_IS_POSTED:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          rewardIsPosted: action.payload,
        }
      }
    case actions.SET_STARTED_QUIZ:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          started: action.payload,
        }
      }
    case actions.NEXT_ACTIVE_QUESTION:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          activeQuistionIdx: ++state.quiz.activeQuistionIdx,
          answersIds: [],
          answered: false,
        }
      }
    case actions.SET_ANSWER_ID: {
      const { id, single } = action.payload;
      const answersIds = state.quiz.answersIds;
      return {
        ...state,
        quiz: {
          ...state.quiz,
          answersIds: single ? [id] : answersIds.includes(id) ? answersIds.filter(answerId => answerId !== id) : [...answersIds, id],
        }
      }
    }
    case actions.SET_IS_ANSWERED:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          answered: action.payload,
        }
      }
    case actions.SET_SERVER_ANSWERED:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          setServerAnswered: action.payload,
        }
      }
    case actions.SET_FINISHED_QUIZ:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          finished: true,
          started: false,

        }
      }
    // case actions.SET_RIGHT_ANSWER_COUNT:
    //   return {
    //     ...state,
    //     quiz: {
    //       ...state.quiz,
    //       rightAnswerCount: state.quiz.rightAnswerCount + 1
    //     }
    //   }
    case actions.SET_LOADING_QUIZ:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          loading: action.payload
        }
      }
    case actions.SET_USER_RESULT:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          result: action.payload
        }
      }
    case actions.SET_IS_SHOW_RESULT:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          isShowResult: action.payload
        }
      }
    case actions.RESET_QUIZ:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          activeQuizIdx: 0,
          activeQuistionIdx: 0,
          started: false,
          answersIds: [],
          answered: false,
          finished: false,
          isShowResult: false,
          result: []
        },
      }
    default:
      return state
  }
}