import actions from './actions'
import { getLifeCycol } from 'utils/src/CommonRedux/LifeCycol/selectors'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions'
import initWidgetSetting from '../LMS/Widgets/CoursesMy/Settings'
import { moduleIsEnabled } from 'utils/src/CommonRedux/base/selectors'

export default (store: any) => {
    let unsubscribe = () => { }
    unsubscribe = store.subscribe(() => {
        const INIT_COMPLETED = getLifeCycol(store.getState()).INIT_COMPLETED
        if (INIT_COMPLETED) {
            unsubscribe()
            const currentUser = getAuthUser(store.getState());
            const learningtracks = moduleIsEnabled('learningtracks')(store.getState());
            if (currentUser.baseData.isAdmin) {
                actions(learningtracks)
                initWidgetSetting()
            }
        }
    })
}
