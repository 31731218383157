import { styled } from 'muicomponents/src/mui/system';
import { Box, Typography } from 'muicomponents/src';

export const PopoverFiltersTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: 20,
}) as typeof Typography;

export const PopoverFiltersActions = styled(Box)({
  display: 'flex',
  columnGap: 8,
  padding: '0 24px 16px'
}) as typeof Box;

export const PopoverCategoriesBox = styled(Box)({
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '300px',
  paddingLeft: '32px'
}) as typeof Box;