import React, { useState, useEffect } from 'react';
import { Spinner } from 'uielements/src';
import { API } from 'utils/src/utils';
import './NotificationSettings_GroupSettings.scss';
import { IGroups,  cnGroups, mapStateToProps } from './NotificationSettings_GroupSettings.index';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux'
import { GroupItem } from './GroupItem/NotificationsSettings_GroupItemSettings'

const GroupsP: React.FC<IGroups> = ({ networkId, parentNetworkId, userId }) => {

  const [groups, setGroups] = useState([])
  const [notificationsTemplate, setNotificationsTemplate] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {

    utils.API.groups.getNotificationsTemplate()
      .r.then((d: any) => {

        if (d.error_code === 0) {
          
          setNotificationsTemplate(d.data)

        }

      })

    if (!groups.length) {
      const id = utils.getCookie('authUid')
      setLoading(true)
      API.groups.byUser(id, "my", { network: networkId, loadUsers: true, includeUserSettings: true }).r.then((d: any) => {
        if (d.error_code === 0) {
          let data = d.data
          if (networkId !== parentNetworkId) {
            data = d.data.filter((item: any) => !item.showGroupInSubnetworks)
          }
          setGroups(data)
        }
        setLoading(false)
      })
    }
  }, [])

  return (
    <div className={cnGroups()}>
      {!loading ? groups.map((item: any) => {
        let imgUrl = item.imgId198x198 !== "00000000-0000-0000-0000-000000000000" ? item.imgUrl198x198 : undefined

        return (
          <GroupItem item={item} imgUrl={imgUrl} networkId={networkId || ''} userId={userId} notificationsTemplate={notificationsTemplate}/>
        )
      })
        : <Spinner className={cnGroups('Settings-Spinner')} />
      }
      {groups.length === 0 && !loading && <div className={cnGroups('Settings-Empty')}>Нет групп</div>}
    </div>
  )
}

export const Groups = connect(mapStateToProps, null)(GroupsP)