import Component, { Presenter } from './component';
import {
    StatisticsFull,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = StatisticsFull.CoursesList.IOwnProps;
export type IComponentPresenterProps = StatisticsFull.CoursesList.IPresenterProps;


export default Component