import { IIdeaFieldProps } from '../FieldProps';
import React from 'react';
import { InputMention } from 'blocks/InputMention/InputMention';
import { UsersSuggester } from 'muicomponents/src/Suggester/type/UsersSuggester'
export const ResponsibleForStatusUser: React.FC<IIdeaFieldProps> = ({ field, onChange = () => { } }) => {
    const changeUser = (ev: any, value: any) => {

        field.users = value;
        onChange(field);
    }


    return (
        <div>
            <UsersSuggester
                sx={{ margin: "5px 0 24px 24px" }}
                multiple
                onChange={changeUser}
                value={field?.users}
                placeholder={field.field.description}
            />
            {/* <InputMention
                onChange={changeUser}
                type={'users'}
                noMulti={false}
                // className={cnReplyForm('UserInput')}
                icon='user-add'
                value={field?.users}
                placeholder={field.field.description} /> */}
        </div>
    );
}
