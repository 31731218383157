import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {

} from '../../redux/actions'
import {

} from '../../redux/reducer/types'
import {
    getItemParamsById
} from '../../redux/saga/selectors'


///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace KPI {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Item {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps {
            id: string
            userId: string
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = KPI.Item.IOwnProps


export const mapStateToProps = (state: any, { id }: IOwnProps) => ({
    // ...getItemParamsById(id, 'meta')(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({

}, dispatch);

////////////

export const cnClassName = cn('KPIItem');