import * as React from 'react';
import * as utils from 'utils/src/utils';

// import { withBemMod } from '@bem-react/core';

import { cnWidget } from '../Widget.index';
// import { IWidgetTypeListProps } from './Widget_type_list.index';
// import { List } from 'blocks/List/List';

import './Widget_type_userGroups.scss';
import { default as WidgetPresenter } from '../Widget';

import { widgets } from "i.widgets";
import { IWidgetTypeUserGroupsProps } from './Widget_type_userGroups.index';
import { List as MyList } from 'blocks/List/_type/groups/List_type_groups_myGroups'
import { List } from 'blocks/List/_type/groups/List_type_groups'
const types = {
  // groups: require('blocks/List/_type/groups/List_type_groups').List,
  // groupsSelector: require('blocks/List/_type/groups/List_type_groups_selector').List,
}

const type = 'list';

export default class WidgetTypeUserGroupPresenter extends WidgetPresenter<IWidgetTypeUserGroupsProps> {

  public render() {
    if (!this.props.widget) return null;
    // const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    
    let Component = List as any;
    const isMy: boolean = [this.props.authUser.baseData.alias, this.props.authUser.baseData.id].indexOf(this.props.wcontext.uId) !== -1;
    if (isMy) Component = MyList as any;
    return <Component {...this.props} className={cnWidget({ type })} context={{ ...this.props.wcontext, groupsListFilter: 'my' }} contexts={this.relations.concat(this.props.widget.id)} />
  }

}

// export const WidgetTypeList = connect(
//   mapStateToProps,
//   // stateToProps,
//   mapDispatchToProps({})
// )(WidgetTypeListPresenter);

// export const withWidgetTypeList = withBemMod<IWidgetTypeListProps>(
//   cnWidget(),
//   { type }, 
//   (Presenter) => (
//     (props) => {
//       const { widget } = props;
//       if(widget) {
//         if(!props.context[widget.id]) props.context[widget.id] = {};
//       }
//       // const listData = Object.assign({}, props.pageData.list);
//       // delete props.pageData.list;
//       let subtype = utils.widgetSubtype(props.widget.type);
//       if(!subtype) subtype = 'common';
//       const Component = types[subtype];
//       if(!Component) return <Presenter {...props} hide />;
//       return <Component {...props} contexts={{
//         ...props.widget.relations,
//         id: widget.id
//       }} tag={Presenter} />
//     }
//   )
// );