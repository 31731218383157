import Component, { Presenter } from './component';
import {
    IOwnProps,
    IPresenterProps,
    cnClassName,
    ITabsType
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = IOwnProps;
export type IComponentPresenterProps = IPresenterProps;
export type ITabType = ITabsType


export default Component