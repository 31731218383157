import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface ITimepickerProps extends IClassNameProps {
  minuteStep?: number;
  value: string;
  onChange(time: string): void;
}

export interface ITimepickerState {
  text?: string;
  focus: boolean;
}

export const cnTimepicker = cn('Timepicker');