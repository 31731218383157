import React from 'react';
import { cn } from '@bem-react/classname';
import { Icon } from 'uielements/src/Icon/Icon';
import { tC } from 'LMSModule/utils/i18n.adaptor'

const cnDeadlines = cn('Deadlines');

interface IDeadlinesProps {
  start: string;
  end: string;
  noDeadlines: boolean;
  className?: string;
}

export const periodTextCreator = (session: any) => {
  let startDate = new Date(session.beginDate)
  let endDate = new Date(session.endDate)
  if (session.startType === 3 && session.log) {
    endDate = new Date(session.log.completeBeforeDate)
    startDate = new Date(session.log.completeBeforeDate)
    startDate.setDate(endDate.getDate() - session.log.shouldCompleteInDays)
  } else if (session.startType === 3 && !session.log) {

  }

  const startDateStr = new Date(startDate).toLocaleDateString('ru', { month: 'numeric', day: 'numeric' })
  const endDateStr = new Date(endDate).toLocaleDateString('ru', { month: 'numeric', day: 'numeric' })

  let period: string = session.startType === 0
    ? tC('period.anyTime.1')
    : tC('period.date.1', { startDateStr, endDateStr })
  period = session.startType === 3 && !session.log ? tC('period.periodDays.1', { periodDays: session.completeInDays }) : period
  return period
}

export const Deadlines: React.FC<IDeadlinesProps & any> = ({ session, className }) => {
  const period = periodTextCreator(session)
  return (
    <div className={cnDeadlines({}, [className])}>
      <Icon icon="calendar" className={cnDeadlines('Icon')} />
      {'\u00a0' + period}
    </div>
  );
};
