import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { } from '../../redux/actions/LMSTeacherOffice'

export interface ICourseCreateProps extends IClassNameProps, ICourseCreateStateProps, IDispatchProps, 
RouteComponentProps, ICourseCreateDispatchProps {
    tag?: 'div';
}

export interface ICourseCreateState {

}

export interface ICourseCreateStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({

})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export type ICourseCreateDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnCourseCreate = cn('CourseCreate');