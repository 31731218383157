import React from 'react';
import { cn } from '@bem-react/classname';
import './500.scss';
import NewServerError from 'assets/svg/NewServerError.svg';
import fourOhFourRed from 'assets/png/404.red.png';
import i18n from 'localizations/i18n';
import { Button } from 'uielements/src/Button/Button';
import { RouteProps, RouteComponentProps } from 'react-router';
import { Header } from 'blocks/HeaderMui/Header';
import { Background } from 'blocks/Background/Background';
import { Route } from 'react-router-dom';

const cnError500 = cn('Error500');

export const Error500: React.FC = () => {
  React.useEffect(() => {
    const background = document.querySelector('.Background');
    background && background.classList.add('Background_notFound');
    return () => void (background && background.classList.remove('Background_notFound'));
  });
  return (
    <article className={cnError500({}, ['Page'])}>
      <img src={NewServerError} className={cnError500('Image')} />
      <h3 className={cnError500('Title')}>{i18n.t('pryaniky.server.error')}</h3>
      <p className={cnError500('Text')}>
        {i18n.t('Пожалуйста, перезагрузите страницу позже.')}
      </p>
    </article>
  );
};

// interface INotFoundRouteProps extends RouteProps {
//   component: React.ComponentType<RouteComponentProps>;
// }

// export const NotFoundRoute = () => {
//   return(
//       <>
//         <Header />
//         <Background />
//         <NotFound />
//         <LeftMenu />
//       </>
// )};
