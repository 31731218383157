import React, { useState, useEffect, useCallback } from 'react';
import { cnReferral, IReferralProps, mapStateToProps } from './Referral.index';
import { getReferralTextRequest } from 'utils/src/requests/requests.referral';
import { connect } from 'react-redux';
import Button from 'uielements/src/MaterialElements/Button/Button';
import './Referral.scss';
import { referralModal } from './ReferralModal/ReferralModal';
import { Translate } from 'localizations/Translate';
import ReferralAva from './Referral.svg';
import IconMUI from '@material-ui/core/Icon';

const Presenter: React.FC<IReferralProps> = (props) => {

  const { tag: Tag = 'div', context } = props;

  const [referralText, setReferralText] = useState<string>('');

  const getReferralText = useCallback(() => {
    getReferralTextRequest().r.then(res => {
      setReferralText(res.data);
    });
  }, [])

  useEffect(() => {
    getReferralText();
  }, [])

  if (!referralText) return <></>;

  return (
    <Tag {...props} className={cnReferral({}, [props.className])}>
      <span className={cnReferral('Title')}>
        <Translate i18nKey='pryaniky.referral.widget.title' />
      </span>
      <div className={cnReferral('Content')}>
        <IconMUI color={"action"} className={cnReferral('Icon')} >
          <img src={ReferralAva} alt="" />
        </IconMUI>
        <span>{referralText}</span>
      </div>
      <Button className={cnReferral('ReferralBtn')} onClick={() => referralModal({})}>
        <Translate i18nKey='pryaniky.referral.widget.btn.invite.friend' />
      </Button>
    </Tag>
  )
}


export const Referral = connect(mapStateToProps, undefined)(React.memo(Presenter));