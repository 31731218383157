import { Reducer, AnyAction } from 'redux';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import { reducerLeftMenu } from './reducer';
import { menuSaga } from './saga';

const moduleName = 'leftMenu';

const reducerMap = { [moduleName]: reducerLeftMenu };

type ILeftMenuModule = {[key in keyof typeof reducerMap ]: ReturnType<typeof reducerMap[key]> };

export function getLeftMenuModule(): ISagaModule<ILeftMenuModule>  {
    return {
        id: moduleName,
        reducerMap: reducerMap as {[s in keyof ILeftMenuModule]: Reducer<ILeftMenuModule[s], AnyAction>},
        sagas: [
            menuSaga
        ],
    };
}