import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';
import { AnyAction } from 'redux';

let actions = {
  SET_QUIZ: '',
  CHANGE_ACTIVE_QUIZ_IDX: '',
  SET_OPEN_QUIZ: '',
  SET_STARTED_QUIZ: '',
  NEXT_ACTIVE_QUESTION: '',
  SET_ANSWER_ID: '',
  SET_IS_ANSWERED: '',
  SET_FINISHED_QUIZ: '',
  SET_RIGHT_ANSWER_COUNT: '',
  RESET_QUIZ: '',
  SET_LOADING_QUIZ: '',
  SET_USER_RESULT: '',
  SET_IS_SHOW_RESULT: '',
  SET_SERVER_ANSWERED: '',
  SET_REWARD_IS_POSTED: '',
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.QUIZ) }), {} as typeof actions);