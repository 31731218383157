import { Box, Paper, styled, Typography } from "muicomponents/src";
import { Avatar } from "muicomponents/src/Avatar";
import { LinearProgress } from "muicomponents/src/LinearProgress";

export const StyledPaper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(3),
  height: theme.spacing(21),
  overflow: 'hidden',
})) as typeof Paper

export const CountTypography = styled(Typography)({
  fontSize: '12px',
}) as typeof Typography

 export const BorderLinearProgress = styled(LinearProgress)({
   marginTop: '8px',
   height: 10,
   borderRadius: 5,
 }) as typeof LinearProgress

export const FlexBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  margin: '0',
}) as typeof Box

export const LineBox = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    border: '1px solid transparent',
    marginTop: theme.spacing(1),
  })) as typeof Box

export const HeaderFlexBox = styled(FlexBox)({
  justifyContent: 'space-between',
}) as typeof Box

export const MarkerAvatar = styled(Avatar)({
  position: 'absolute',
  top: '-12px',
  background: 'white',
  padding: '4px',
  boxSizing: 'border-box',
  zIndex: 1,
  width: '32px', 
  height: '32px',
  boxShadow: '0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
}) as typeof Avatar

