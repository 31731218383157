import React, { useEffect, useState } from 'react'
import { Dropdown, Button } from 'uielements/src'
import * as utils from 'utils/src/utils';
import { cnNetworkItem, INetworkItem} from './NotificationsSettings_NetworkItemSettings.index'
import './NotificationsSettings_NetworkItemSettings.scss'
import { toast } from 'react-toastify'
import i18n from 'localizations/i18n'
import queryString from 'query-string';

export const NetworkItem = ({ 
  item,
  notificationsTemplate,
  href,
  userId,
}: INetworkItem) => {

  const [notificationTemplateData, setNotificationTemplateData] = useState([])
  const [currentNotificationsTemplate, setCurrentNotificationsTemplate] = useState<any[]>([])

  const nitifiAction = (template: "all" | "common" | "none") => () => {

    let payload
    const currentTemplate = notificationsTemplate.map((item: any) => {
      if (item.name === template){
        payload = {
          networkTemplate: item.name,
          g: item.g
        }
      }
        return {
          title: item.displayName,
          buttonProps: { onClick: nitifiAction(item.name) },
          ...item
        }
    }).filter((item: any) => {
      return item.name === template
    })
    
    setCurrentNotificationsTemplate(currentTemplate)
    utils.API.notifications.settings.setNetworkTemplate(item.pkid, userId, item.id, payload ).r.then((d: any) => {
      if (d.error_code === 0) {
        toast.success(i18n.t('pryaniky.notifications.settings.template.toast.' + template))
      } else {
        toast.error(i18n.t('Ошибка!'))
      } 
    })
  }

  useEffect(() => {
    if (notificationsTemplate.length) {
      if (!notificationTemplateData.length) {
        setNotificationTemplateData(notificationsTemplate.map((item: any) => {
          return {
            title: item.displayName,
            buttonProps: { onClick: nitifiAction(item.name) },
            ...item
          }
        })
        )
      }
      
      setCurrentNotificationsTemplate(notificationTemplateData.filter((template: any) => item.notificationTemplate === template.name))
    }
  }, [notificationTemplateData, notificationsTemplate.length])

  
  
  return (
    <div className={cnNetworkItem()}>

      <div className={cnNetworkItem('Title')}>
        <span>{item.name}</span>
      </div>
      <div className={cnNetworkItem('Settings')}>
        <Dropdown
          className={cnNetworkItem('Settings-Dropdown')}
          text={currentNotificationsTemplate[0] && currentNotificationsTemplate[0].title || 'Пользовательские'}
          buttonProps={{ main: true }}
          withCaret
          options={notificationTemplateData}
        />
        <Button type='rlink' theme='unstyled' href={href}>Посмотреть детали</Button>
      </div>
    </div>
  )
}