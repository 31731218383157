import React from 'react';
import { IFiltersSubnetworksProps, IFiltersSubnetworksState, IFiltersSubnetworksStateToProps, mapFiltersSubnetworksStateToProps } from './Filters_type_subnetworks.index';
import { mapStateToProps, mapDispatchToProps, IFiltersStateProps, IFiltersDispatchProps, cnFilters } from '../Filters.index';
import { Selectize } from 'uielements/src';
import { ISelectizeItem } from "uielements/src/Selectize/Selectize.index";
import i18n from '../../../localizations/i18n';
import { IStateType as IState } from 'redux/store';
import { connect } from 'react-redux';
import * as utils from 'utils/src/utils';
import { filters } from 'utils/src/filters.prn';
import { Translate } from 'localizations/Translate';
import { withRouter, RouteComponentProps } from 'react-router';

const type = 'network';

class FilterSubnetworksPresenter extends React.Component<IFiltersSubnetworksProps, IFiltersSubnetworksState> {

  componentDidMount() {
    const { baseNetwork } = this.props
    // this.change({
    //   ...baseNetwork,
    //   id: baseNetwork.id,
    //   title: baseNetwork.name,
    //   type,
    //   value: baseNetwork.id,
    //   additionalValues: {
    //     roles: baseNetwork.roles
    //   }
    // })
  }

  private change = (selected: ISelectizeItem) => {
    const value: {[key: string]: any} = {
      [selected.type]: this.props.context[type] !== selected.value ? selected.value : undefined,
      ...(selected.additionalValues ? Object.keys(selected.additionalValues).reduce((a, c) => ({ ...a, [c]: selected.additionalValues[c] }), {} as Record<string, any>) : {})
    };
    this.props.history.push({
      search: `?${Object.entries(value).map(([ key, val ], idx) => `${idx > 0 ? '&' : ''}${key}=${val.toString()}`).join('')}`
    });
    this.props.changeContextv3(
      this.props.relations.reduce((acc: any, conID: any) => {
        return { 
          ...acc, 
          [conID]: value
        }
      }, {} as { [s: string]: any })
    );
  };

  public render() {
    const { tag: Tag = 'div', data, context, className, edit, networks, parentNetwork, currentNetworkName } = this.props;
    if (!edit && networks.length < 2) return null;
    const prepairedNetworks = [ ...networks ];
    if (
      (context.page === 'search' && context.activeTab === 'news') ||
      (context.page === 'tag' && (!context.tab || context.tab === 'publications'))
      ) {
      if (parentNetwork && !prepairedNetworks.filter((el: any) => el.id === parentNetwork.id).length) prepairedNetworks.unshift({ ...parentNetwork, title: Translate.t({ i18nKey: 'pryaniky.filter.subnetworks.crossnetwork' }) });
    }
    const currentNetwork = networks.find((n: any) => n.name === currentNetworkName)
    return (
  // @ts-ignore 
      <Tag data={data} tag='div' context={'polls'} relations={[]} {...this.props} className={cnFilters({}, [className])} >
        <h4 className={cnFilters('Subtitle')} children={`${i18n.t('pryaniky.filter.subnetworks.title')}:`} />
        <Selectize  onClick={this.change} elements={prepairedNetworks.map((el: any): ISelectizeItem => ({
          id: el.id,
          isHidden: el.isHidden,
          type,
          title: el.title,
          value: el.id,
          additionalValues: {
            roles: el.roles
          }
        }))} active={context[type] || currentNetwork.id || this.props.baseNetwork.id} />
      </Tag>
    )
  }
}

export const FilterSubnetworks = withRouter(
  connect<IFiltersSubnetworksStateToProps, IFiltersDispatchProps, IFiltersSubnetworksProps, IState>(
    mapFiltersSubnetworksStateToProps,
    mapDispatchToProps
  )(FilterSubnetworksPresenter)
)