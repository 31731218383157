import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget, IWidgetProps, cnWidgetMui } from '../Widget.index';
import { Attachments } from 'uielements/src/Attachments/Attachments'
import { IAttachmentsProps } from 'uielements/src/Attachments/Attachments.index'
import { Preview } from 'uielements/src/Preview/Preview'
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";

const type = 'file';



export default class WidgetTypeFilePresenter extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Tag = widgets.components.common
    if (!this.props.widget.data) return null;
    const files = [this.props.widget.data as IAttachmentsProps['files'][number]]
    return (
      <Tag {...this.props} className={cnWidgetMui({ type, subtype })} >
        <div style={{ padding: '24px' }}>
          <Preview
            files={files}
          />

          <Attachments
            disabledType={[]}
            files={files}
            state={'view'}
          />
        </div>
      </Tag>
    );
  }

}
