import { v1 as uuid } from 'uuid'

export interface IUserShort {
    id: string,
    displayName: string,
    eMail: string,
    alias: string,
    imgId: string,
    sex: string,
    imgUrl: string,
    position: string,
    division: string,
    phone: string,
    isFirstLogon: boolean,
    birthday: string,
    isDeleted: boolean
}

interface ITag {
    id: string,
    displayName: string,
    description: any,
    isDeleted: boolean,
    isActive: boolean,
    isMain: boolean,
    isDeletedFromNews: boolean,
    postWeight: number,
    popularity: number,
    replacedTags: any[],
    replacedTagsFullName: any,
    subscribedUsers: [],
    collections: string[]
}

export interface ISkill {
    confirmedBy: IUserShort[],
    confirmCount: number,
    confirmedByMe: boolean,
    onModeration: boolean,
    tag: ITag
}

export interface ISkillCollection {
    [key: string]: ISkill
}

export const createDefaultTag = (id: string = uuid()): ITag => ({
    id,
    displayName: "",
    description: null,
    isDeleted: false,
    isActive: true,
    isMain: false,
    isDeletedFromNews: false,
    postWeight: 0,
    popularity: 0,
    replacedTags: [],
    replacedTagsFullName: null,
    subscribedUsers: [],
    collections: [
        "endorsements"
    ]
})

export const createDefaultSkill = (id: string = uuid()): ISkill => ({
    confirmedBy: [],
    confirmCount: 0,
    confirmedByMe: false,
    onModeration: false,
    tag: createDefaultTag(id)
})