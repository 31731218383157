import React, { FC, useEffect, useState } from 'react'
import { IListSkipedProps, mapDispatchToProps, mapStateToProps, cnListSkiped } from './ListSkiped.index'
import './ListSkiped.scss'
import { connect } from 'react-redux';
import { Button } from 'uielements/src'
import { Question } from './Question/Question'
import { tS } from 'LMSModule/utils/i18n.adaptor'

const ListSkipedPresenter: FC<any> = ({ skiped, questions, completeTest, transitionToQuestion, uid, sendCompleteUnitTest, fullscreen }) => {
    // const allowComplite = skiped.reduce((acc: boolean, val: string) => (questions[val].allowSkip === false ? false : acc), true)

    return <div className={cnListSkiped({ fullscreen})}>
        <h3 className={cnListSkiped('Header')}>
            {tS('skipText.1')}
        </h3>

        <div className={cnListSkiped('List')}>
            {skiped.map((qid: string) => <Question qid={qid} uid={uid} />)}
        </div>
        <div className={cnListSkiped('Actions')}>
            <Button main
                className={cnListSkiped('Control')}
                onClick={() => transitionToQuestion(uid, skiped[0])}>
                {tS('answer.1')}
            </Button>
        </div>
    </div>
}

export const ListSkiped = connect(
    mapStateToProps,
    mapDispatchToProps
)(ListSkipedPresenter);