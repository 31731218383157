import { IListProps } from '../../List.index';
import { updateContext } from 'redux/actions/Widgets';
import { IDispatchProps } from '../../../../redux/connector';
import { filters } from 'utils/src/filters.prn';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { RouteComponentProps } from 'react-router'
export interface IDefaultRequestOption {
    search: string,

}

export interface IListUsersDefaultRequestProps extends IDefaultRequestOption {
    catFilter: string,
    excludeGroup: number,
    rid: string,
    gid: number,
    extended: boolean,
    count: number,

}

export interface IListTypeWikiProps extends IListProps, IDispatchProps, IListTypeWikiOwnProps, IListTypeWikimapStateToProps {
    requestOptions: any;
    tag?: 'div';
    wikiData: any;
    newsUid: string;
    searchPage?: boolean;
    coll: boolean
}

export interface IListTypeWikiOwnProps extends RouteComponentProps<{}> {

}

export interface IListTypeWikiStateProps {
    isAdmin: boolean;


}
export type IListTypeWikimapStateToProps = ReturnType<typeof listTypeWikiMapStateToProps>;

export const listTypeWikiMapStateToProps = (state: any, props: IListTypeWikiOwnProps) => {
    const pageId = state.widgets.structures[props.location?.pathname.substring(1)];

    return {
        layoutWikilist: pageId && state.widgets.pages[pageId],
    }
}
const fltrs = {
    ...filters,
    catFilter: 'catFilter',
    authors: 'authors',
    order: 'order',
    sort: 'sort',
    collectionAlias: 'collectionAlias',
    archived: 'archived',
    type: 'type',
    searchOnlyTitle: "searchOnlyTitle",


}


export type IFLTRS = typeof fltrs & { [s: string]: string };

export const avalibleFiltersWiki = Object.keys(fltrs).reduce((acc, cur) => acc[cur] === '' ? { ...acc, [cur]: cur } : acc, fltrs as IFLTRS);