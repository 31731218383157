import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { loadCourseById, loadStudentsStatus  } from '../../../redux/actions/LMSTeacherOffice'

export interface ICourseStatisticsProps extends IClassNameProps, ICourseStatisticsStateProps, IDispatchProps, RouteComponentProps, ICourseStatisticsDispatchProps {
    tag?: 'div';
}

export interface ICourseStatisticstState {

}

export interface ICourseStatisticsStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    studentesIds: state.LMSTO.courses.values[props.cid].studentesIds || [],
    courseUnits: state.LMSTO.courses.values[props.cid].courseUnits,
    source: state.LMSTO.courses.values[props.cid].source

})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadCourseById
}, dispatch);

export type ICourseStatisticsDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnCourseStatistics = cn('TOCourseStatistics');