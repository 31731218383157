import { IInfoProps } from '../AbstractInfoElement.index';

import { IDispatchProps } from '../../../../redux/connector';

export interface IUserTypeStateProps extends IInfoProps, IUserStateProps, IDispatchProps {
  tag?: 'div';
}

export interface IUserTypeStateState {
  text?: string;
}

export interface IUserStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})