import { connect } from 'react-redux';
import * as React from 'react';
import { store } from '../../redux/store';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps } from '@jsonforms/core';
import { Hidden, Typography } from "@material-ui/core";
import { JsonFormsDispatch } from '@jsonforms/react';
import { cnJFLayout } from './Layout.index';

import './Layout.scss';

const MyLayoutRenderer = (props: any) => {
  const { uischema, schema, path, visible, renderers } = props;

  // const layoutProps = {
  //   elements: uischema.elements,
  //   schema: schema,
  //   path: path,
  //   // direction: "column",
  //   visible: visible,
  //   uischema: uischema,
  //   renderers: renderers
  // };
  return (
    <Hidden xsUp= {!visible}>
      <div className={cnJFLayout({ horizontal: uischema.direction === 'horizontal', vertical: uischema.direction === 'vertical' || !uischema.direction })}>
        <Typography>{uischema.label}</Typography>
        {
          uischema.elements.map(function (child: any, index: any) {
            return (React.createElement('div', { item: true, key: path + "-" + index, xs: true },
              React.createElement(JsonFormsDispatch, { uischema: child, schema: schema, path: path, renderers: renderers })));
          })
        }
      </div>
    </Hidden>
  )
};

const LayoutRenderer = connect(mapStateToLayoutProps)(MyLayoutRenderer);

const myLayoutTester = rankWith(1000, uiTypeIs("Layout"));

store.dispatch(
  Actions.registerRenderer(
    myLayoutTester,
    LayoutRenderer
  )
);