import React, { FC, useEffect, useState, useMemo, useRef } from 'react'
import { IProps, mapDispatchToProps, mapStateToProps, cnFiles } from './Files.index'
import { connect } from 'react-redux';
import './Files.scss'
import { Item } from './Item/Item'
import { Dropzone } from 'uielements/src/Dropzone/Dropzone';
import { Button, CheckboxInput, Spinner, Icon } from 'uielements/src'
import * as utils from 'utils/src/utils'
import { NEW__useInfiniteScroll as useInfiniteScroll, useDebounce } from 'utils/src/hooks'
import i18n from 'localizations/i18n';
import { FileViewer } from 'uielements/src/FileViewer/FileViewer';
// import { SearchInput } from 'uielements/src';
import { Header } from './Header/Header'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string';
import { MoveFilesDialog } from 'blocks/Dialogs/Files/Move/MoveFiles'

let ref: any = null;
let uploaderReference: any = null;
const setUploaderReference = (ref: any) => uploaderReference = ref;


export const Files_: FC<IProps> = ({
    gid,
    loadList,
    files,
    uploadFiles,
    count,
    isFinished,
    isLoading,
    showControls,
    // parentId,
    filters,
    location
}) => {
    const [isShow, setShow] = useState<boolean>(false)
    const requestParams = queryString.parse(location.search) as any

    const more = () => {
        if (isLoading) return;
        if (isFinished) return;
        loadList(gid, count, filters, requestParams.folder)
    }

    const loadMore = useDebounce(more, 500, [count, requestParams.folder, filters]);

    const openFileWindow = () => uploaderReference && uploaderReference.open()

    const [fullScreen, setFullScreen] = useState<number>(-1)

    const onFilesDrop = (folder: string) => (acceptedFiles: any) => uploadFiles(gid, acceptedFiles, folder);

    const viewClose = () => setFullScreen(-1)
    const viewOpen = (idx: number) => () => setFullScreen(idx)

    useEffect(() => {
        loadList(gid, 0, filters, requestParams.folder)
    }, [requestParams.folder])

    const infiniteScroll = useInfiniteScroll({ loadMore, isLoading, isFinished })

    const viewFiles = files.map((val: any) => ({ ...val, action: val.previewAction, pdfPreviewUrl: val.pdfPreviewUrl || `/ru/Data/v3/Attachment/${val.id}/${val.urlname}`, editUrl:val.editUrl }))

    return <div ref={(e: any) => ref = e} className={cnFiles({}, [])}>
        <Header className={cnFiles("Header")} showMove={() => setShow(true)} showControls={showControls} openFileWindow={openFileWindow} gid={gid} />

        <MoveFilesDialog gid={gid} isShown={isShow} onClose={() => setShow(false)} />
        {fullScreen !== -1 && <FileViewer files={viewFiles} id={files[fullScreen].id} onClose={viewClose} />}

        <div className={cnFiles('Content')}>
            {files.map((file: any, idx) => <Item showMove={() => setShow(true)} key={file.id} showControls={showControls} file={file} viewOpen={viewOpen(idx)} />)}
        </div>

        {ref && <Dropzone
            reference={setUploaderReference}
            onDrop={onFilesDrop(requestParams.folder)}
            hoverElem={ref}
        />}

        <div className={cnFiles('MoreBox')}>
            {isLoading ? <Spinner /> : ((!isFinished && count !== 0) && <Button forwardedRef={infiniteScroll} onClick={more}>{i18n.t('pryaniky.files.more')}</Button>)}
        </div>
    </div>
}

export const Files = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Files_))