import Component, { Presenter } from './component';
import {
    LMSTO,
    cnClassName
} from './interfaces';

export {
    cnClassName,
    Presenter
}

export type IComponentProps = LMSTO.CommentsLent.CoursesFilter.IOwnProps;
export type IComponentPresenterProps = LMSTO.CommentsLent.CoursesFilter.IPresenterProps;


export default Component