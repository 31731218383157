import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from '../../redux/connector';
import { IWidgetProps } from '../Widget/Widget.index';
// import { IContext } from '../../store/vm';
import { IListItem } from '../ListRound/ListRound.index';

export interface IGroupsWidgetProps extends IClassNameProps, IGroupsWidgetStateProps, IDispatchProps, IWidgetProps {
  tag?: 'div';
  context: {[s: string]: any};
}

export interface IGroupsWidgetState {
  text?: string;
  items: IListItem[];
  noData: boolean;
}

export interface IGroupsWidgetStateProps {
  // store: any;
  // context: IContext;
}

export const mapStateToProps = (state: any) => ({
  // store: state.store,
  // context: state.store.context,
})

export const cnGroupsWidget = cn('GroupsWidget');