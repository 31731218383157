/**
 * @packageDocumentation
 * @module Widget_Settings_sliderlink
 */
import { GUID_EMPTY } from "utils/src/constants.prn";
import { emptyItem } from './uischema';

export default {
  virtualUser: {
    imgId: GUID_EMPTY,
    imgUrl: undefined,
    startText: '',
    isShuffle: false,
    elements: [
      emptyItem
    ],
  }
}