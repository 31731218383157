import { basePageCollectionWithChildCollections } from "utils/src";



export const getTabSelectedFolderByAlias = (alias: string) => {
    return {
        ...basePageCollectionWithChildCollections,
        alias: alias,
        name: alias
    };
};