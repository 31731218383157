import { ACTION } from 'utils/src/CommonRedux/actionsTypes';


let actions: {
    //saga
    CHANGE_STATUS: 'CHANGE_STATUS',
    GET_USER_STATUSES: 'GET_USER_STATUSES',
    GET_STATUS_FIELDS: 'GET_STATUS_FIELDS',
    CHANGE_RESPONSIBLE: 'CHANGE_RESPONSIBLE',
    //reducer

    //common
} = {
    //saga
    CHANGE_STATUS: 'CHANGE_STATUS',
    GET_USER_STATUSES: 'GET_USER_STATUSES',
    GET_STATUS_FIELDS: 'GET_STATUS_FIELDS',
    CHANGE_RESPONSIBLE: 'CHANGE_RESPONSIBLE',
    //reducer

    //common
}


export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, 'NEWS_EVENT') }), {} as typeof actions);