import { Box } from "@mui/material"
import { styled } from "@mui/system"
import { Paper } from "muicomponents/src"
import Badge from '@mui/material/Badge';

export const PaperContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(21),
    overflow: 'hidden',
})) as typeof Paper

export const ItemBox = styled(Box)({
    position: 'relative',
    margin: '4px 8px 4px 0px',
    width: 'fit-content'
}) as typeof Box

export const LineBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
}) as typeof Box

export const WrappingLineBox = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
}) as typeof Box

export const TooltipTitleBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
}) as typeof Box

export const TwoItemsBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    gap: "6px",
    gridGap: "6px",
    '& > div': {
        display: 'flex',
        height: theme.spacing(8),

        // marginBottom: theme.spacing(1),
        // marginTop: theme.spacing(1),

    }
})) as typeof Box

export const DeleteRound = styled(Paper)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    margin: 0,
    borderRadius: '50%',
    padding: '2px',
}) as typeof Paper

export const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 12,
        bottom: 10,
        background: '#fff',
    }
})) as typeof Badge



