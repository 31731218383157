import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { loadModerationAnswers } from '../../redux/actions/LMSTeacherOffice'

export interface IAnswerListProps extends IClassNameProps, IAnswerListStateProps, IDispatchProps, RouteComponentProps, IAnswerListDispatchProps {
    tag?: 'div';
    sessionId: string
    hideModeration?: boolean;
    listContainer?: string;
    noScroll?: boolean;
}

export interface IAnswerListState {

}

export interface IAnswerListStateProps {

}

export const mapStateToProps = (state: any, props: any) => {
    // if (!state.LMSTO.moderation) return {}
    return {
        units: state.LMSTO.moderation.units,
        questions: state.LMSTO.moderation.questions,
        answers: state.LMSTO.moderation.answers,
        users: state.LMSTO.users,
        answersIds: state.LMSTO.moderation.lists[props.listContainer || 'main'] || [],//answersIds,
        isLoading: state.LMSTO.moderation.isLoading,
        isFinished: state.LMSTO.moderation.isFinished
    }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadModerationAnswers
}, dispatch);

export type IAnswerListDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnAnswerList = cn('TOAnswerList');