import { ACTION } from 'utils/src/CommonRedux/actionsTypes';


let actions = {
    //saga
    LOAD_REASONS: 'LOAD_REASONS',

    //reducer
    SET_REASONS: 'SET_REASONS'

    //common
} as const


export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, 'NEWS_THANKS') }), {} as typeof actions);