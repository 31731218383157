import { NValidate } from 'utils/src/validate_v2';
import { NAchievements } from './AchievementsFor.interface';

export const AchievementsValidateRules: NValidate.ValidateRulesObject<NAchievements.Create> = {
    achievement: {
        notNull: {
            value: true
        }
    },
    text: {
        notNull: {
            value: true
        },
        min: {
            value: 0,
            // errorMessageKey: ''
        }
    },
};