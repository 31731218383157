import { IOrgchartDataSettings, ITreeElement } from "./interfaces"
import { IPositionElement } from 'utils/src/requests/models/admin.orgchart'

import {
    SET_STRUCTURE_ORGCHART_TO_REDUX,
    CHANGE_STRUCTURE_ORGCHART_DATA,
    SAVE_STRUCTURE_ORGCHART_DATA,
    ADD_NEW_POSITION_TO_UNIT,
    ADD_NEW_LEADER_POSITION_TO_UNIT,
    CHANGE_POSITION_ORGCHART_DATA,
    SAVE_EDIT_POSITION_TO_UNIT,
    DELETE_POSITION_FROMUNIT_DATA,
    SAVE_EDIT_LEADER_POSITION_TO_UNIT,
    ORGCHART_TOGGLE_DRAGGABLE,
    ORGCHART_DRAG_POSITION,
    ORGCHART_ADD_SUB_UNIT,
    ORGCHART_EDIT_UNIT,
    DELETE_STRUCTURE_ORGCHART_DATA,
    ORGCHART_SAVE_UNIT_POSITION_AFTER_DRAG,
    NEW_POSITION_DATA_KILL_AFTER_SAVE,
    NEW_UNIT_DATA_KILL_AFTER_SAVE,
    ORGCHART_CHANGE_SELECT,
    ORGCHART_SET_DRAGGING_UNIT,
    ORGCHART_SET_DRAGGING_UNIT_TO_POSITION,
    ORGCHART_SHOW_ONLY_OPEN_VACANCY,
    SET_UNIT_BY_TAG_ID,
    SET_ORGCHART_LOADING
} from "./actions"
import { IOrgchartDataActions } from './actions.interfaces'
import { IOrgchartData, IUnitsData } from 'utils/src/requests/models/admin.orgchart'
import { clone } from 'utils/src/utils'
import { v1 as uuid } from 'uuid'
import { forLibPrefix } from './constants'
import { includes } from "lodash"

export const newPositions = {
    employee: null,
    id: "",
    isVacancyOpen: false,
    subordinates: [],
    type: {
        description: "",
        name: "",
    },
    unitId: "",
    vacancy: {
        description: "",
        id: "",
        name: "",
        attachments: []
    }
}

export const newUnit: IUnitsData = {
    id: "",
    imgId: "",
    linkedGroup: null,
    displayName: "",
    parent: "",
    isNeedModeration: false,
    isDeleted: false,
    positionIds: [],
    rootPositionIds: [],
    subunits: null,
    position: 0,
    users: [],
    type: 'unit'
}

const baseState: IOrgchartDataSettings = {
    isFinished: false,
    isLoading: true,
    draggable: true,
    orgchartSettings: {} as IOrgchartData,
    units: {},
    initUnitsData: null,
    positions: {},
    initPositionData: null,
    newPositionsData: [],
    select: null,
    draggingUnit: null,
    showOnlyOpenVacancy: false,

}



export const orgchartReducer = (state = baseState, action: IOrgchartDataActions): IOrgchartDataSettings => {
    switch (action.type) {
        case SET_ORGCHART_LOADING:{
            return{
                ...state,
                isLoading: action.payload,
                isFinished: !action.payload
            }
        }
        case ORGCHART_SET_DRAGGING_UNIT: {
            return {
                ...state,
                draggingUnit: action.payload
            }
        }
        case SET_STRUCTURE_ORGCHART_TO_REDUX: {
            return {
                ...state,
                elementStructure: action.payload.structure,
                units: action.payload.units,
                initUnitsData: null,
                positions: action.payload.positions,
                initPositionData: null,
                newPositionsData: [],
                isFinished: action.payload.isFinished,
                isLoading: action.payload.isLoading
            }
        }
        case CHANGE_STRUCTURE_ORGCHART_DATA: {
            return {
                ...state,
                initUnitsData: {
                    ...state.initUnitsData,
                    ...action.payload
                }
            }
        }

        case SAVE_STRUCTURE_ORGCHART_DATA: {

            let unitCreate = state.initUnitsData ? state.initUnitsData : newUnit
            // если findeElem возвращает true значит это редактируемый юнит => не добавляем в структуру,
            //  если false, то созданный юнит =>надо добавить в структуру)
            const findeElem = Object.keys(state.units).findIndex((el: any) => el === unitCreate.id) !== -1
            // state.elementStructure
            let { elementStructure } = state;
            if (!findeElem && elementStructure) {
                const insertElement = (elStr: ITreeElement, el: IUnitsData) => {
                    if (elStr.id === forLibPrefix + el.parent) {
                        return {
                            ...elStr,
                            children: [
                                ...(elStr.children || []),
                                {
                                    id: forLibPrefix + el.id,
                                    position: (elStr.children || []).length
                                }
                            ]
                        }
                    } else {
                        elStr.children = elStr.children?.map(e => {
                            return insertElement(e, el);
                        });
                    }
                    return {
                        ...elStr
                    }
                }
                elementStructure = insertElement(elementStructure, unitCreate);

            }

            return {
                ...state,
                units: {
                    ...state.units,
                    [unitCreate.id]: unitCreate
                },
                positions: {
                    ...state.positions,
                    ...(state.initPositionData || {})
                },
                elementStructure,
            }

        }
        case NEW_POSITION_DATA_KILL_AFTER_SAVE: {
            return {
                ...state,
                newPositionsData: []
            }
        }
        case NEW_UNIT_DATA_KILL_AFTER_SAVE: {
            return {
                ...state,
                initUnitsData: null,
                initPositionData: null
            }
        }
        case DELETE_STRUCTURE_ORGCHART_DATA: {
            let { elementStructure } = state;
            const findeElem = Object.keys(state.units).findIndex((el: any) => el === action.payload) !== -1
            const id = forLibPrefix + action.payload;

            if (findeElem && elementStructure) {
                const insertElement = (elStr: ITreeElement, idToRemove: string) => {
                    if (elStr.id !== idToRemove) {
                        const searchIndex = elStr?.children && elStr.children.findIndex((el: any) => el.id === idToRemove) !== -1
                        if (searchIndex) {
                            let childrenFiltred = elStr?.children && elStr.children.filter((el: any) => el.id !== idToRemove);
                            return {
                                ...elStr,
                                children: childrenFiltred
                            }
                        } else {
                            elStr.children = elStr.children?.map(e => {
                                return insertElement(e, idToRemove);;
                            });
                        }

                    }

                    return {
                        ...elStr
                    }
                }
                elementStructure = insertElement(elementStructure, id);

            }
            return {
                ...state,
                // units,
                elementStructure
            }
        }
        case ADD_NEW_POSITION_TO_UNIT: {
            // state.initUnitsData =state.initUnitsData newUnit;
            // let postionsIdsArr = state.initUnitsData ? state.initUnitsData.positionIds : [];
            // postionsIdsArr.push(action.payload.positionId);
            // let postionsArr = state.newPositionsData ? state.newPositionsData : [];
            // postionsArr?.push(action.payload.position)
            return {
                ...state,
                initUnitsData: {
                    ...(state.initUnitsData || newUnit),
                    positionIds: [...(state.initUnitsData?.positionIds || []), action.payload.positionId]

                },
                initPositionData: {
                    ...state.initPositionData,
                    [action.payload.positionId]: action.payload.position
                },
                positions: {
                    ...state.positions,
                    [action.payload.positionId]: action.payload.position
                },
                newPositionsData: [
                    ...state.newPositionsData,
                    action.payload.position

                ]
            }
        }

        case ADD_NEW_LEADER_POSITION_TO_UNIT: {
            // let rootPostionsIdsArr = state.initUnitsData ? state.initUnitsData.rootPositionIds : [];
            // rootPostionsIdsArr.push(action.payload.rootPositionId);
            // let rootPostionsArr = state.newPositionsData ? state.newPositionsData : [];
            // rootPostionsArr?.push(action.payload.rootPositions)
            return {
                ...state,
                initUnitsData: {
                    ...(state.initUnitsData || newUnit),
                    rootPositionIds: [...(state.initUnitsData?.rootPositionIds || []), action.payload.rootPositionId],
                },
                initPositionData: {
                    ...state.initPositionData,
                    [action.payload.rootPositionId]: action.payload.rootPositions
                },
                positions: {
                    ...state.positions,
                    [action.payload.rootPositionId]: action.payload.rootPositions
                    // clone(state.initPositionData.positions)
                },
                newPositionsData: [
                    ...state.newPositionsData,
                    action.payload.rootPositions

                ]

            }
        }
        case DELETE_POSITION_FROMUNIT_DATA: {
            const positions = clone(state.positions)
            let postionsIdsArr = state.initUnitsData ? state.initUnitsData.positionIds : [];
            let rootPositionsIdsArr = state.initUnitsData ? state.initUnitsData.rootPositionIds : [];
            let delPosEmloyee = action.payload.position
            // let postionsArr = state.initUnitsData.units[action.payload.unitId].positions ? state.initUnitsData.units[action.payload.unitId].positions : [];
            let newPositionsArr = state.newPositionsData ? state.newPositionsData : [];
            if (positions[action.payload.id].employee !== null) {
                delPosEmloyee.employee = null;
                // меняем флаг isVacancyOpen на true, потому как позиция без юзера может существовать, только если ее вакансия открыта.
                delPosEmloyee.isVacancyOpen = true;
                delPosEmloyee.vacancy = delPosEmloyee.vacancy?  delPosEmloyee.vacancy : newPositions.vacancy;
                [...state.newPositionsData || [], delPosEmloyee ]
                // rootPositionsArr?.push(delPosEmloyee)
            } else if (positions[action.payload.id].employee === null) {
                delete positions[action.payload.id];
                postionsIdsArr = postionsIdsArr.filter(item => item !== action.payload.id)
                rootPositionsIdsArr = rootPositionsIdsArr.filter(item => item !== action.payload.id)

            }
            return {
                ...state,
                initUnitsData: {
                    ...(state.initUnitsData || newUnit),
                    positionIds: postionsIdsArr,
                    rootPositionIds: rootPositionsIdsArr,

                },
                positions: {
                    ...state.positions,
                    [action.payload.id]: action.payload.position
                },
                initPositionData: {
                    ...state.initPositionData,
                    [action.payload.id]: action.payload.position
                },
                newPositionsData: [
                    ...state.newPositionsData,
                    action.payload.position

                ]

            }
        }

        case SAVE_EDIT_POSITION_TO_UNIT: {
            return {
                ...state,
                initUnitsData: {
                    ...(state.initUnitsData || newUnit),
                },
                initPositionData: {
                    ...state.initPositionData,
                    [action.payload.positionId]: action.payload.position
                },
                positions: {
                    ...state.positions,
                    [action.payload.positionId]: action.payload.position
                },
                newPositionsData: [
                    ...state.newPositionsData,
                    action.payload.position

                ]
            }
        }

        case ORGCHART_ADD_SUB_UNIT: {
            const parentUnit = action.payload && action.payload.parent ? action.payload.parent : '';
            const newUnitId = uuid()
            return {
                ...state,
                initUnitsData: {
                    ...newUnit,
                    id: newUnitId,
                    parent: parentUnit

                }
            }
        }
        case ORGCHART_EDIT_UNIT: {
            const EditUnitId = action.payload.id
            const subPositions = state.units[EditUnitId].positionIds.map((posId: string) => state.positions[posId])
            const rootPositions = state.units[EditUnitId].rootPositionIds.map((posId: string) => state.positions[posId])

            const allUnitPositions = subPositions.concat(rootPositions)
            const allEditPositions = allUnitPositions.reduce((acc, position: any) => {
                return { ...acc, [position.id]: position };
            }, {} as { [key: string]: IPositionElement });

            return {
                ...state,
                initUnitsData: {
                    ...state.units[EditUnitId]
                },

                initPositionData: allEditPositions
            }
        }

        case SAVE_EDIT_LEADER_POSITION_TO_UNIT: {
            return {
                ...state,
                ...state,
                initUnitsData: {
                    ...(state.initUnitsData || newUnit),
                },
                initPositionData: {
                    ...state.initPositionData,
                    [action.payload.rootPositionId]: action.payload.rootPositions
                },
                positions: {
                    ...state.positions,
                    [action.payload.rootPositionId]: action.payload.rootPositions
                },
                newPositionsData: [
                    ...state.newPositionsData,
                    action.payload.rootPositions

                ]
            }
        }

        case CHANGE_POSITION_ORGCHART_DATA: {

            return {
                ...state,
                initPositionData: {
                    ...state.initPositionData,
                    [action.payload.id]: {
                        ...state.positions[action.payload.id],
                        ...action.payload
                    }
                }

            }
        }
        case ORGCHART_TOGGLE_DRAGGABLE: {
            return {
                ...state,
                draggable: typeof action.payload !== "undefined" ? action.payload : !state.draggable
            }
        }
        case ORGCHART_DRAG_POSITION: {
            return {
                ...state,
                units: {
                    ...state.units,
                    [action.payload.id]: {
                        ...state.units[action.payload.id],
                        ...action.payload
                    }
                },
                positions: {
                    ...state.positions,
                    [action.payload.positionData.id]: {
                        ...state.positions[action.payload.positionData.id],
                        unitId: action.payload.id
                    }
                }
            }
        }
        case ORGCHART_SAVE_UNIT_POSITION_AFTER_DRAG: {
            return {
                ...state,
                units: {
                    ...state.units,
                    [action.payload.id]: {
                        ...state.units[action.payload.id],
                        parent: action.payload.parent
                    }
                },
                draggingUnit: null
            }
        }

        case ORGCHART_SET_DRAGGING_UNIT_TO_POSITION: {
            if (!state.elementStructure) return state;
            let elementStructure = clone(state.elementStructure);
            const getDraggableElement = (el: typeof state.elementStructure): typeof state.elementStructure | null => {
                if (el?.id.replace(forLibPrefix, '') === action.payload.id) return el;
                return el?.children?.reduce((a, c) => a || getDraggableElement(c), null as ReturnType<typeof getDraggableElement>) || null
            }
            const draggableElement = getDraggableElement(elementStructure);
            if (!draggableElement) return state;
            const changeStucture = (el: typeof state.elementStructure) => {
                let find = false;
                if (!el) return;
                if (el.children?.map(e => e.id).includes(draggableElement.id)) {
                    el.children = el.children?.filter(el => el.id !== draggableElement.id).map((el, idx) => ({ ...el, position: idx }));
                }
                // if (el.id.replace(forLibPrefix, '') === action.payload.fromId) {
                //     el.children = el.children?.filter(el => el.id !== draggableElement.id).map((el, idx) => ({ ...el, position: idx }));
                // }
                if (el.id.replace(forLibPrefix, '') === action.payload.toId) {
                    if (!el.children) el.children = [];
                    el.children.splice(action.payload.position, 0, draggableElement);
                    el.children = el.children.map((el, idx) => ({ ...el, position: idx }));
                }
                if (!find) {
                    el.children?.forEach(el => changeStucture(el))
                }
            }
            changeStucture(elementStructure);
            return {
                ...state,
                elementStructure
            }
        }

        case ORGCHART_CHANGE_SELECT: {
            return {
                ...state,
                select: action.payload ? action.payload : null
            }
        }

        case ORGCHART_SHOW_ONLY_OPEN_VACANCY: {
            return {
                ...state,
                showOnlyOpenVacancy: action.payload
            }
        }
        case SET_UNIT_BY_TAG_ID: {
            const positions = action.payload.positions.reduce((acc, position) => {
                return { ...acc, [position.id]: position };
            }, {} as { [key: string]: IPositionElement });
            const parentId = state.initUnitsData?.parent
            // isNeedModeration: false, parent: parentId ? parentId : action.payload.unit.parent
            return {
                ...state,
                initUnitsData: { ...action.payload.unit,  parent: parentId, isNeedModeration: false},
                // initPositionData: 
                initPositionData:{  ...state.initPositionData,  ...(positions || {}) },
                newPositionsData: [...state.newPositionsData, ...(action.payload.positions || [])]
            }
        }
        default:
            return state

    }
}