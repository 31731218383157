import React from 'react';
import { cnWidget, cnWidgetMui, IWidgetProps } from '../Widget.index';
import './Widget_type_ratingmini.scss';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { QuestsWidget } from 'blocks/QuestsWidget/QuestsWidget';
import { RatingMini } from "./../../RatingMini/RatingMini";
import { CustomSettings } from 'utils/src';
import { PreviewParams } from 'utils/src/PreviewParams';

// export interface IQuestsWidget extends IClassNameProps,  ReturnType<typeof mapStateToProps> {
//   tag?: React.ComponentType<any> | string;
//   contexts?: string[];
//   // className: string;
// }

// export const cnQuestsWidget = cn('QuestsWidget');

// export const withWidgetTypeQuests = withBemMod<IWidgetProps>(cnWidget(), { type: 'quests' }, Presenter => props => {
//   return (
//     <Presenter {...props}>
//       <Quests />
//     </Presenter>
//   );
// });

const type = 'ratingmini';

export default class WidgetTypeRatingMiniPresenter extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    return <RatingMini {...this.props}
      className={cnWidgetMui({ type, subtype })}
      settings={this.props.wcontext} tag={widgets.components.common} />
    // <QuestsWidget {...this.props} className={cnWidget({ type, subtype })} contexts={this.relations.concat(this.props.widget.id)} tag={widgets.components.common} />
  }

}