// import { IBodyProps } from '../NewsEdit-Body.index';

// import { IDispatchProps } from 'redux/connector';

export interface IBodyTypePollsProps { // extends IBodyProps, IBodyStateProps, IDispatchProps {
  tag?: 'div';
}

export interface IBodyTypePollsState {
  text?: string;
}

export interface IBodyStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})