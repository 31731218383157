import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMSTO
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Avatar, Button, SplitButton, Icon } from 'uielements/src';
import { AuthorsList } from '../../../../components/AuthorsList/AuthorsList';

type IPresenterProps = LMSTO.TOCoursesListItem.IPresenterProps
type IOwnProps = LMSTO.TOCoursesListItem.IOwnProps
type IDispatchProps = LMSTO.TOCoursesListItem.IDispatchProps
type IStateProps = LMSTO.TOCoursesListItem.IStateProps
type IState = LMSTO.TOCoursesListItem.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    course
}) => {
    const actions: any[] = [
        {
            title: 'Delete',
            buttonProps: { onClick: {} },
        },
        {
            title: 'Archive',
            buttonProps: { onClick: () => { } },
        }
    ];
    return <div className={cnClassName({}, [className])}>
        <div>
            <Avatar
                className={cnClassName('Image')}
                imgUrl={course.largeImgUrl}
                size={64}
            />
        </div>
        <div className={cnClassName('Info')}>
            <div className={cnClassName('Title')}>{course.name}</div>

            <div className={cnClassName('StatOne')}>
                <div className={cnClassName('UnitsCount')}>Уроков: {course.courseUnits.length}</div>
                <div className={cnClassName('SessionCount')}>Потоков: {course.courseSessions.length}</div>
            </div>
            <AuthorsList size={32} authors={course.authors} className={cnClassName('AuthorsList')} limit={5} />
        </div>

        <div className={cnClassName('ActionBox')}>
            <SplitButton isOpen={() => { }} className={cnClassName('ActionMenu')}
                button={<Button target={'_blank'} theme={'unstyled_center'} className={cnClassName('ActionBtn')}>
                    <Icon icon={'folder'} />
                </Button>}
                options={actions} />
        </div>
        {/* <Button className={cnClassName('Start')} href={`/TeacherOffice/Course/${course.id}`} type="rlink">
                    Подробнее
            </Button> */}
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
