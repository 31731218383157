
import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { SettingsRemindFillProfileWidget } from '../RemindFillProfileWidget.index';
import { IBodyProps } from 'utils/src/DialogCreator'
export const cnSettingsfillProfileWidgetDialog = cn('SettingsfillProfileWidgetDialog');

export namespace NSettingsfillProfileWidgetDialog {

    export interface Props extends IClassNameProps, IBodyProps<any> {
        data?: SettingsRemindFillProfileWidget.Settings
        onChange: (value: { [key: string]: any }) => void;
    }
}
