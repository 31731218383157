import { Vacancy } from "utils/src/BaseTypes/vacancy";
import { VacanciesReducer } from "./reducer.vacancies.types";

export const emptyVacancy: Vacancy = {
    id: '',
    allowTemporaryEmployment: false,
    attachments: [],
    creationDate: null,
    changeDate: null,
    closeDate: null,
    code: '',
    description: '',
    driverLicence: '',
    employmentCondition: null,
    employmentType: 'FullEmployment',
    experience: 'NotSet',
    isHidden: false,
    languagesKnowledge: [],
    location: '',
    managerUser: null,
    mapLocation: '',
    maxSalary: null,
    minSalary: null,
    name: '',
    openDate: null,
    openedByUser: null,
    operatingMode: 'WeekendWork',
    other: '',
    requests: [],
    requirements: null,
    responsibilities: null,
    salaryCurrency: 'RUB',
    salaryType: 'Gross',
    sendNotifications: false,
    skills: [],
    socPosition: null,
    specializationName: '',
    suggestedTags: [],
    suggestedUsers: [],
    tags: [],
    whoCanRespond: 'ApplicantsFrom14YearsOld',
    workSchedule: 'FullDay',
    positionTags: null,
    divisionTags: null,
    requestCount: 0,
    unit: null,
    positionProfile: null
};

export const baseVacanciesReducer: VacanciesReducer = {
    list: []
};