import { ReactNode } from 'react';
import { CreativetasksendNews } from './Creativetasksend.type'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import {
    getNewsParamsById
} from 'News/redux/saga/selectors'
import { NewsContextProps } from 'News/contexts/news'
export interface ICreativetasksendPropsType extends NewsContextProps {
    // data: CreativetasksendNews;
    // presentRender: (child: ReactNode) => ReactNode;
}
export type TStateProps = ReturnType<typeof mapStateToProps>
export interface ICreativetasksendStateType {
    data: CreativetasksendNews;
}


const newsParams = [
    'text',
] as const

export const mapStateToProps = (state: any, { newsId }: ICreativetasksendPropsType) => ({
    userData: getCurrentUser(state),
    ...getNewsParamsById<CreativetasksendNews, typeof newsParams[number]>(newsId, newsParams)(state)
})

