import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { loadCoursesByCurrentUser } from '../../redux/actions/LMSTeacherOffice'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface ICoursesListProps extends IClassNameProps, ICoursesListStateProps, IDispatchProps, RouteComponentProps, ICoursesListDispatchProps {
    tag?: 'div';
}

export interface ICoursesListState {

}

export interface ICoursesListStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    user: getAuthUser(state),
    results: state.LMSTO.courses.results,
    isLoading: state.LMSTO.courses.isLoading,
    isFinished: state.LMSTO.courses.isFinished,
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadCoursesByCurrentUser
}, dispatch);

export type ICoursesListDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnCoursesList = cn('CoursesList');