import React, { FC } from 'react';
import './UnitsList.scss';
import { UnitItem } from '../UnitItem/UnitItem'
import { cnUnitsList, mapDispatchToProps, mapStateToProps } from './UnitsList.index'
import { isNextUnit } from '../../../redux/sagas/LMS/utils'
import { connect } from 'react-redux';
const zeroId = "00000000-0000-0000-0000-000000000000";
export const UnitsList_: FC<any> = ({ units, lastViewedUnit, className, lastCompletedUnitId = '0', completedSessions, currentSessionId, unstartedSessions }) => {
    // const activeUnitIndex = units.findIndex((unit: any) => unit.id === lastViewedUnit)
    return <div className={cnUnitsList({}, [className])}>
        {units.map((unit: any, i: number) =>
            <UnitItem
                unit={unit}
                completedSessionsCount={completedSessions.length}
                unstartedSessionsCount={unstartedSessions.length}
                activedSessions={currentSessionId !== zeroId}
                actived={isNextUnit(lastCompletedUnitId, unit.id, units)}
            /*actived={unit.id === lastViewedUnit}*/ />)}
    </div>
}

export const UnitsList = connect(
    mapStateToProps,
    mapDispatchToProps
)(UnitsList_);