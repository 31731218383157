import React, { FC, useEffect, useState } from 'react'
import { cnSessions, mapDispatchToProps, mapStateToProps } from './Sessions.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Sessions.scss'
// import { Button } from 'uielements/src';
import { Selectize, Spinner } from 'uielements/src';
import { linkCreator, zeroId } from 'LMSModule/utils/utils.lms'
import CourseTabs, { ITabType } from '../../../../components/Tabs'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'



export const SessionsPresenter: FC<any> = ({ className, active, sessions = [], loadStudentSessionsList, normalSessions, isLoading, currentFilters, uid, cid }) => {
    const TABS = [
        { value: 'active', content: tT('student_results.filters.session.tabs.active.1') },
        { value: 'archive', content: tT('student_results.filters.session.tabs.archive.1') }
    ]
    const [tab, setTab] = useState<ITabType>(TABS[0]);

    useEffect(() => {
        loadStudentSessionsList(cid, uid, tab.value === 'archive')
    }, [cid, uid, tab])

    const selectTab = (tab: ITabType) => setTab(tab)

    const elements = sessions.map((val: any) => {
        return {
            value: val.id,
            title: val.name,
            id: val.id
        }
    })
    return <div className={cnSessions({}, [className])}>
        {/* Sessions filter */}
        <div className={cnSessions('Title')}>
            <h4 className={cnSessions('Name')}>{tT('student_results.filters.session.1')}</h4>
            <CourseTabs className={cnSessions('Tabs')} onChange={selectTab} selected={tab} tabs={TABS} />
        </div>
        {isLoading ?
            <div className={cnSessions('Loader')}><Spinner /></div> :
            <Selectize
                // key={i} 
                onClick={(value) => { /*setModerationFilters({ 'filter.sessionIds': [value.value] })*/ }}
                active={[active]}
                rLink={linkCreator('sid', zeroId)}
                // name={'Потоки'}
                elements={elements}
                // multiple={true}
                children={[]} />
        }
    </div>
}

export const Sessions = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SessionsPresenter));
