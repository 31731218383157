import { NLeftMenuActions } from "./actions.intrefaces";
import * as LMActions from "./actions.types";

export const leftMenuMoveItem = (payload: NLeftMenuActions.MoveItem['payload']): NLeftMenuActions.Actions => ({
    type: LMActions.LM_MOVE_ITEM,
    payload
});

export const leftMenuSetBarItems = (payload: NLeftMenuActions.SetBarItems['payload']): NLeftMenuActions.Actions => ({
    type: LMActions.LM_SET_BAR_ITEMS,
    payload
});

export const leftMenuRemoveItem = (payload: NLeftMenuActions.RemoveItem['payload']): NLeftMenuActions.Actions => ({
    type: LMActions.LM_REMOVE_ITEM,
    payload
});

export const leftMenuSetBars = (payload: NLeftMenuActions.SetBars['payload']): NLeftMenuActions.Actions => ({
    type: LMActions.LM_SET_BARS,
    payload
});

export const leftMenuSetEdit = (payload: NLeftMenuActions.SetEdit['payload']): NLeftMenuActions.Actions => ({
    type: LMActions.LM_SET_EDIT,
    payload
});

export const leftMenuToggleFavourite = (payload: NLeftMenuActions.ToggleFavourite['payload']): NLeftMenuActions.Actions => ({
    type: LMActions.LM_TOGGLE_FAVOURITE,
    payload
});

export const leftMenuSortFavourite = (payload: NLeftMenuActions.SortFavourite['payload']): NLeftMenuActions.Actions => ({
    type: LMActions.LM_SORT_FAVOURITE,
    payload
});

export const leftMenuSetReducerField = (payload: NLeftMenuActions.SetReducerField['payload']): NLeftMenuActions.Actions => ({
    type: LMActions.LM_SET_REDUCER_FIELD,
    payload
});