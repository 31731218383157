/**
 * @packageDocumentation
 * @module ListItem
 */
import { IClassNameProps } from '@bem-react/core';
import { cnList } from '../List.index';

// import { IDispatchProps } from '../../../redux/connector';
import { IDropdownProps } from 'uielements/src/Dropdown/Dropdown.index';
import { IStateType as IState } from 'redux/store';
import { checkListItem } from 'redux/actions/Lists';
export interface IItemProps extends IClassNameProps, IItemOwnProps, IActionsListItem, IListItemStateProps {
  tag?: React.ComponentType<any> | string;
  style?: any;
  // data: {
  //   avatar?: React.ReactNode,
  //   info: React.ReactNode[][],
  //   sublevel?: number
  // };
}

export interface IItemOwnProps {
  type?: 'common' | 'tree';
  checked?: boolean;
  lId: string; // list id to get data from redux
  iId: string; // item id to get data from redux
  data_key?: string;
  actions?: IDropdownProps;
  onClick?(data_key: string): void;
}

export interface IItemState {
  text?: string;
}

export interface IActionsListItem {
  checkListItem: typeof checkListItem;
}

export const mapActionsToProps = {
  checkListItem
};

export interface IListItemStateProps {
  data?: {
    avatar?: React.ReactNode,
    info: React.ReactNode[][],
    sublevel?: number,
  };
  checked?: boolean;
  actions?: IDropdownProps;
}

export const mapStateToProps = (state: IState, props: IItemOwnProps) => {
  const list = state.lists[props.lId || 'alone'];
  return {
    data: list && list.items[props.iId] ? list.items[props.iId].view_data : undefined,
    checked: list && list.items[props.iId] ? list.items[props.iId].checked : undefined,
    actions: list && list.items[props.iId] ? list.items[props.iId].actions_data : undefined,
  }
}

export const cnItem = (...data: any) => {
  let name = 'Item';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnList(name, ...data)
}