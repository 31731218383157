import React from 'react';
import { Skeleton } from 'uielements/src';
import './LeaderboardItem-Skeleton.scss';

const randomIntInRange = (min: number, max: number) => Math.round(Math.random() * (max - min) + min);

export const LeaderboardItemSkeleton: React.FC = () => (
  <div className="LeaderboardItem-Skeleton">
    <Skeleton shape="circle" className="LeaderBoardItem-Skeleton-Avatar"/>
    <div style={{ flexGrow: 1 }} className="LeaderboardItem-Skeleton-Info">
      <Skeleton shape="line" size="sm" spacing="lg" style={{ width: randomIntInRange(50, 100) + '%' }} />
      <Skeleton shape="line" size="sm" spacing="lg" style={{ width: randomIntInRange(50, 100) + '%' }} />
    </div>
    <Skeleton shape="line" size="xl" style={{ flexGrow: 1 }} className="LeaderboardItem-Skeleton-Bar" />
  </div>
);
