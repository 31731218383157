import { connect } from 'react-redux'
import React, { useMemo } from 'react'
import { mapDispatchToProps, IDispatchProps } from 'redux/connector'
import { Page } from 'blocks/Page/Page'
import i18n from 'localizations/i18n'
import { setContext } from 'redux/actions/Widgets';
import { NewsCreator } from 'News/creator/Creator';
import { Translate } from 'localizations/Translate'
import { LanguageContext } from 'localization'

interface IDevelopPageProps extends IActionsDispatch, IDispatchProps { }
interface IActionsDispatch {
  setContext: any
}

export const DevelopPagePr: React.FC<IDevelopPageProps> = props => {

    const arr = useMemo(() => new Array(101).fill(0).map((_, i) => i + 1), []);

    return (
      <>
      <div>
      <Translate i18nKey={'selected'} />
      </div>
      <div>
      <Translate i18nKey={'selected'} context={LanguageContext.male} />
      </div>
      <div>
      <Translate i18nKey={'selected'} context={LanguageContext.female} />
      </div>
      <div>
      <Translate i18nKey={'selected'} count={1}/>
      </div>
      <div>
      <Translate i18nKey={'selected'} count={3}/>
      </div>
      <div>
      <Translate i18nKey={'created'} count={1}/>
      </div>
      <Translate i18nKey='createWith' values={{
        text: `${Translate.t({
            i18nKey: 'colleague',
            context: LanguageContext.dative
          }).toLowerCase()} щеночка`
      }} />
      <div>
        {
          arr.map(el => {
            return <div key={el}>
                <Translate
                  i18nKey={'createdWith'}
                  context={LanguageContext.male}
                  count={el}
                  values={{
                    text: `${el} ${Translate.t({
                      i18nKey: 'colleague',
                      count: el
                    }).toLowerCase()}`
                  }}
                />
              </div>
          })
        }
      </div>
      </>
        // <NewsCreator />
    );
}

export const DevelopPage = connect(
  null,
  mapDispatchToProps({ setContext })
)(DevelopPagePr)