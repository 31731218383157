/**
 * @packageDocumentation
 * @module Widget_Settings_timeline
 */
export default {
  name: '',
  countView: 5,
  group: [],
  users: [],
  hideIfNoData: false,
}