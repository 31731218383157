/**
 * @packageDocumentation
 * @module Widget_Settings_info
 */
export default {
  okText: '',
  isRequired: false,
  // for show menu license chackbox
  showMobileLicense: window.location.pathname.indexOf('/welcome'),
  mobileLicense: false
}