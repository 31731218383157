import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { getOpenQuiz, resetQuiz } from 'redux/actions/Quiz'

export interface IQuiz extends IQuizStateProps, IQuizDispatchProps {
  history: any,
  location: any,
  match: any
}



export const mapStateToProps = (state: IState) => {

  const data = state.quiz.quiz.openQuizData
  
  return {
    length: data.questions && data.questions.length,
    startDate: data.startDate,
    backgroundImgUrl: data.backgroundImgUrl,
    backgroundColorString: data.backgroundColorString,
    iconImgUrl: data.iconImgUrl,
    name: data.name,
    started: state.quiz.quiz.started,
    finished: state.quiz.quiz.finished,
    loading: state.quiz.quiz.loading,
    isShowResult: state.quiz.quiz.isShowResult,
    highlightCorrectness: data.highlightCorrectness,
    isActive: data.isActive,
    rewardIsPosted: state.quiz.quiz.rewardIsPosted
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getOpenQuiz,
  resetQuiz
}, dispatch);

export type IQuizStateProps = ReturnType<typeof mapStateToProps>
export type IQuizDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnQuiz = cn('Quiz')