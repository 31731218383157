import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";
// import i18n from '../../localizations/i18n';
// import { updateRequestOptions } from "../../redux/actions/News";
import { mapStateToProps, IGroupStateProps, cnGroup, IGroupProps, IGroupState } from './Group.index';
import './Group.scss';
import { Page } from '../Page/Page';
import { IWidget } from '../../i.widgets';
import { Redirect } from 'react-router';
// import i18n from 'localizations/i18n';
import { setContext } from 'redux/actions/Widgets';
import { pageNames } from 'redux/pageNames';

export class GroupPresenter extends React.Component<IGroupProps, IGroupState> {
  public redirect: string;
  public tabWidget: IWidget;

  public componentDidMount() {
    // if(this.props.setContext) this.props.setContext({
    //   paths: ['common'],
    //   innerPath: '',
    //   value: {
    //     tab: this.props.match.params.tab,
    //     gId: this.props.match.params.id
    //   }
    // })
  }
  // public componentWillUnmount() {
  //   if (this.props.setContext) this.props.setContext({
  //     paths: ['common'],
  //     innerPath: '',
  //     value: {}
  //   })
  //   // this.props.context.common = {};
  // }

  /**
   * componentDidUpdate
   * @param {IGroupProps} pp - previous props value
   */
  public componentDidUpdate(pp: IGroupProps) {
    // if (!utils.compareData(this.props.match.params.id, pp.match.params.id)) {
    //   if (this.props.setContext) this.props.setContext({
    //     paths: ['common'],
    //     innerPath: 'gId',
    //     value: this.props.match.params.id
    //   })
    // }
  }

  public render() {
    return (
      <React.Fragment>
        {
          this.redirect && <Redirect to={this.redirectTo()} />
        }
        <Page
          className={cnGroup({ id: this.props.match.params.id })}
          page={{
            name: pageNames.group + '.' + this.props.match.params.id,
            params: { id: this.props.match.params.id }
          }} />
      </React.Fragment>
    )
  }

  public redirectTo = () => {
    const url = this.redirect;
    this.redirect = '';
    return url;
  }
}


export const Group = connect<IGroupStateProps, IDispatchProps >(
  mapStateToProps, 
  mapDispatchToProps({
    // updateRequestOptions,
    setContext
  }),
  
)(GroupPresenter)
//   compose(
//   withBemMod(cnGroup(), {})
// )(GroupPresenter))
