import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Selectize, CheckboxInput } from 'uielements/src';
import { mapStateToProps, IFiltersStateProps, cnFilters, IFiltersProps, mapDispatchToProps } from './Filters.index';
import './Filters.scss';
import { linkCreator, zeroId } from 'LMSModule/utils/utils.lms';
import { tS, Ts } from 'LMSModule/utils/i18n.adaptor'

export const FiltersPresenter: React.FC<IFiltersProps & any> = ({
    tag: Tag = 'div', activeId = ['All'], mandatory, className }) => {
    const elements = [
        {
            value: 'All',
            title: tS('filters.All.1'),
            id: 'All'
        }, {
            value: 'NotStarted',
            title: tS('filters.NotStarted.1'),
            id: 'NotStarted'
        }, {
            value: 'InProgress',
            title: tS('filters.InProgress.1'),
            id: 'InProgress'
        }, {
            value: 'Completed',
            title: tS('filters.Completed.1'),
            id: 'Completed'
        }
    ];
    return (
        <Tag className={cnFilters({}, [className])}>
            <Selectize
                onClick={(value) => {  }}
                active={activeId}
                rLink={linkCreator('status', 'All')}
                name={tS('filters.1')}
                elements={elements}
                className={cnFilters('Status')}
                children={[]} />
            <Selectize
                className={cnFilters('Mandatory')}
                onClick={(value) => {  }}
                active={mandatory}
                rLink={linkCreator('isMandatory', 'false')}
                name={''}
                elements={[{
                    value: 'true',
                    title: tS('filters.Mandatory.1'),
                    id: 'true'
                }]}
                children={[]} />
        </Tag>
    );
};

export const Filters = connect(
    mapStateToProps,
    mapDispatchToProps
)(FiltersPresenter);
