import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
    //saga
    LOAD_TRACKS: 'LOAD_TRACKS', // получить список учебных планов
    CREATE_TRACK: 'CREATE_TRACK', // создать учебный план
    ADD_FILE: 'ADD_FILE', // добавить материал в учебный план
    LOAD_FILES: 'LOAD_FILES', // получить список материалов в плане
    LOAD_USERS: 'LOAD_USERS', // получить список пользователей подписаных на план
    ADD_USERS_TO_TRACK: 'ADD_USERS_TO_TRACK', // подписать пользователей на план
    MOVE_FILE: 'MOVE_FILE', // сортировка файлов в плане
    REMOVE_TRACK: 'REMOVE_TRACK', // отправить запрос на удаление плана
    LOAD_TRACK_BY_ID: 'LOAD_TRACK_BY_ID', // получить трек по ид
    UPDATE_TRACK: 'UPDATE_TRACK', // изменить учебный план
    REMOVE_FILE: 'REMOVE_FILE', // удалить файл
    REMOVE_USERS: 'REMOVE_USERS', //удалить пользователей и группы
    LOAD_USERS_STAT: 'LOAD_USERS_STAT', // получить статистику по пользователям
    LOAD_MATERIAL_STAT: 'LOAD_MATERIAL_STAT', // получить статистику по материалам
    SET_FILES_POSITIONS: 'SET_FILES_POSITIONS', // сохранение позиций материалов


    //reducer
    SET_USERS_STAT: 'SET_USERS_STAT', // добавить статистику по пользователям
    SET_MATERIAL_STAT: 'SET_MATERIAL_STAT', // добавить статистику по материалам
    DELETE_USERS: 'DELETE_USERS', //удалить пользователей и группы
    DELETE_FILE: 'DELETE_FILE', // удалить файл
    DELETE_TRACK: 'DELETE_TRACK', // удалить план из состояния
    SELECT_TRACK: 'SELECT_TRACK', // выбрать план
    SET_TRACKS: 'SET_TRACKS', //добавить список треков в стор
    CHANGE_TRACK: 'CHANGE_TRACK', // изменить трек
    SET_FILES: 'SET_FILES', // добавить список материалов в треке
    PREPEND_FILES: 'PREPEND_FILES', // добавить материалы в трек, в начало
    SET_USERS: 'SET_USERS', // добавить список пользователей в треке
    CHANGE_FIELD: 'CHANGE_FIELD', // изменить указаное поле
    RESET: 'RESET', // сбрасывает указаный объект
} as const


export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.TRACKS_MANAGE) }), {} as typeof actions);