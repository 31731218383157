import React from 'react';
import { cnWidget, IWidgetProps } from '../Widget.index';
import './Widget_type_quests.scss';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { IWidgetTypeCurrencyTimelineProps } from './Widget_type_currencyTimeline.index';
import { CurrencyTimeline } from 'blocks/CurrencyTimeline/CurrencyTimeline';

const type = 'currencyTimeline';

export default class WidgetTypeCurrencyTimeline extends WidgetPresenter<IWidgetTypeCurrencyTimelineProps> {

  public render() {

    if (!this.props.widget) return null;
    
    return <CurrencyTimeline currentUserId={this.props.wcontext.uId} />
  }

}