/**
 * @packageDocumentation
 * @module Widget_Settings_timeline
 */
import { JFdictionary } from "../dictionary";

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.suggester,
      componentType: 'tags',
      componentProps: {
        multiple: false,
        additionalRequestProps: {
          colType: 'BadgeCategories'
        },
      },
      title: 'pryaniky.widgets.settings.badgesList.category',
      scope: "#/properties/tag"
    }
  ]
}