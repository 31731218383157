import { Box, Typography } from 'muicomponents/src'
import { Link } from 'muicomponents/src/Link/Link'
import { styled } from 'muicomponents/src/mui/system';
import { PageCollectionSuggester as PageCollectionSuggesterMui, BaseSuggester as BaseSuggesterMui } from 'muicomponents/src/Suggester';
import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';

const defaultSuggesterStyle = {
  '& .MuiInputBase-root': {
    paddingRight: '39px !important'
  }
}
export const WidgetArticlesBox = styled(Box)({
  display: 'flex',
  flexDirection: "column",
  borderRadius: "4px",
  background: "#FFFFFF",
  boxShadow: " 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
  gap: "16px",
  padding: '24px',
  boxSizing: "border-box"
})
export const ListContentBox = styled(Box)({
  // maxHeight: "378px",
  display: "flex",
  gap: "16px"
}) as typeof Box;

export const CardStyled = styled(Card)({
  position: "relative",
  flex: '1',
  display: 'flex',
  // height: "378px",
  boxShadow: "none",
  background: "rgba(245, 245, 245, 1)",
  boxSizing: "border-box",
})

export const CardContentStyled = styled(CardContent)({
  padding: "8px 16px",
  boxSizing: "border-box",
  minHeight: 32,
  // minHeight: 130,
  "&:last-child": {
    paddingBottom: "16px"
  }
})

export const WidgetDialogBox = styled(Box)({
  display: 'flex',
  flexDirection: "column",
  gap: "16px",
  padding: '24px'
}) as typeof Box;

export const ActionBox = styled(Box)({
  display: "flex",
  height: "24px",
  // gap: "8px",
  bottom: "0",
  position: "relative",
  padding: "0px 16px 16px 16px",
  justifyContent:"space-between"
}) as typeof Box;

export const ActionContetBox = styled(Box)({
  padding: "4px 0",
  display: "flex",
  alignItems: "center"
}) as typeof Box;

export const TypographyArticles = styled(Typography)({
  fontWeight: "700"
}) as typeof Typography;
export const TypographyDateArticles = styled(Typography)({
  fontWeight: "400",
  fontSize: '12px',
  lineHeight: "166%",
  letterSpacing: "0.4px",
  color: "rgba(0, 0, 0, 0.38)",
}) as typeof Typography;

export const LinkStyled = styled(Link)({
  position: "relative",
  flex: '1 1',
  display: 'flex',
  textDecoration: "none",
  // height: "378px",
}) as typeof Link;


export const BaseSuggester = styled(BaseSuggesterMui)({
  ...defaultSuggesterStyle
}) as typeof BaseSuggesterMui;

export const PageCollection = styled(PageCollectionSuggesterMui)({
  ...defaultSuggesterStyle
}) as typeof PageCollectionSuggesterMui;