import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
// import { ICourse } from '../mock';
import { loadCourse } from "../../redux/actions/LMS";
// import { loadCommentRequest } from "redux/actions/News";
import { 
    loadNewsById,
    loadComments
} from 'News/redux/actions'
import {
    getNewsById
} from 'News/redux/saga/selectors'

import { RouteComponentProps } from 'react-router';
export interface ICommentsProps extends IClassNameProps, ICommentsStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
    avatarSize?: number;
}

export interface ICommentsState {

}

export interface ICommentsStateProps {
    // store: any
    // course: any
    news: any;
}

export const mapStateToProps = (state: any, props: any) => ({
    // store: state.store,
    // course: state.LMS.courses.entities.values[props.match.params.id],
    // cIds: state.LMS.courses.result,
    news: getNewsById(props.nid)(state)//state.news.news[props.nid]
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    // loadCourse,
    loadNewsById,
    loadComments
}, dispatch);

export const cnComments = cn('Comments');