import { Reducer, AnyAction } from 'redux';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import { newsCreateReducer } from './reducer.newsCreator';
import { newsCreatorSaga } from './saga.newsCreator';

const moduleName = 'newsCreate';

const reducerMap = { [moduleName]: newsCreateReducer };

type NewsCreateModule = {[key in keyof typeof reducerMap ]: ReturnType<typeof reducerMap[key]> };

export function getNewsCreateModule(): ISagaModule<NewsCreateModule>  {
    return {
        id: moduleName,
        reducerMap: reducerMap as {[s in keyof NewsCreateModule]: Reducer<NewsCreateModule[s], AnyAction>},
        sagas: [
            newsCreatorSaga
        ],
    };
}