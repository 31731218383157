import React from 'react';
import { connect } from 'react-redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { IStateType as IState } from 'redux/store';

const eventName = 'prn_analytic_event';

const serviceEvents = {
  buttonClick: 'buttonClick'
}

const serviceCustomEvent = 'prn_service_custom_event';

export const eventsTypes = {
  locationChange: 'locationChange',
  newsView: 'newsView',
  newsViewFull: 'newsViewFull',
  newsLike: 'newsLike',
  replyLike: 'replyLike',
  replyViewFull: 'replyViewFull',

  tabFocus: 'tabFocus',
  tabBlur: 'tabBlur',
  videoPlay: 'videoPlay',
  videoPause: 'videoPause'
}

// Object.keys(eventsTypes).forEach(ev_name => window.addEventListener((eventsTypes as any)[ev_name], printEvent));

export interface IAnalyticsProps extends ImapAnalyticStateToProps {}

export const mapAnalyticStateToProps = (state: IState) => ({
  user: getAuthUser(state)
})

export type ImapAnalyticStateToProps = ReturnType<typeof mapAnalyticStateToProps>

let focused = false;

export const analytic = new (
  class AnalyticBase {
    private create = (name: string, detail: any) => {
      if (!CustomEvent) {
        return new window.CustomEvent(name, {
          detail
        });
      }
      return new CustomEvent(name, {
        detail
      });
    }

    public push = (name: string, data: any) => {
      window.dispatchEvent(this.create(name, data));
    }
  }
)();

class AnalyticPresenter extends React.Component<IAnalyticsProps>{

  constructor(props: IAnalyticsProps) {
    super(props);
    Object.keys(serviceEvents).map(e => serviceEvents[e as keyof typeof serviceEvents]).forEach((e: any) => {
      window.addEventListener(e, this.catch_service_event);
    })
  }

  public componentDidMount() {
    window.onfocus = (...args: any) => {
      if (!focused) analytic.push(eventsTypes.tabFocus, {})
      focused = true;
    }

    window.onblur = (...args: any) => {
      let innerVideoFrameInFocus = false;
      Array.from(document.body.querySelectorAll('.VideoWrapper')).forEach(video => {
        if (video.getAttribute('isFocused')) innerVideoFrameInFocus = true
      })
      if (!innerVideoFrameInFocus) {
        analytic.push(eventsTypes.tabBlur, {})
        focused = false
      }
    }
  }

  private catch_service_event = (e: CustomEvent) => {
    const element = e.detail.handleEvent.currentTarget
  }

  public render = () => {
    return null
  }
}

// export const AnalyticPresenter = ({

// }) => {

//   React.useEffect(() => {
//     if (!(window as any).CustomEvent) (window as any).CustomEvent = CustomEvent;    
//   }, []);

//   return null;
// }

export const Analytic = connect(
  mapAnalyticStateToProps
)(AnalyticPresenter);


const printEvent = (e: any) => {
  // console.log('');
  console.log(e);
  // console.log(e.detail);
}

Object.keys(eventsTypes).forEach(ev_name => {
  // console.log('window.PRN_SERVICE.reporting', window.PRN_SERVICE.reporting);
  // console.log('ev_name', ev_name);
  if (window.PRN_SERVICE.reporting) {
    window.addEventListener((eventsTypes as any)[ev_name], printEvent);
  }
})