import React, { FC, useState } from 'react'
import { ICaledarProps } from './Calendar.index'
import { Month } from './Month'
import { Year } from './Year'
import { Years } from './Years'
import './Calendar.css'

// const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
//     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
// ];

export const Calendar: FC<ICaledarProps & any> = ({ value: date, onChange: setDate, className, minDate, ...props }) => {
    const [type, setType] = useState(0);
    date = !date || date === '' ? new Date() : new Date(date);
    const newDate = new Date(date);
    const cMinDate = new Date(minDate);
    cMinDate.setHours(12, 0, 0, 0);

    const setDay = (v: any) => {  
        newDate.setUTCDate(v)
        if (cMinDate > newDate) return setDate(cMinDate);
        setDate(newDate)
    }

    const setMonth = (v: any) => {
        newDate.setUTCMonth(v)
        if (cMinDate > newDate) return setDate(cMinDate);
        setDate(newDate)
    }
    const setYear = (v: any) => {
        newDate.setUTCFullYear(v)
        if (cMinDate > newDate) return setDate(cMinDate);
        setDate(newDate)
    }

    const upper = (l: number) => () => setType(l)

    const changeYear = (count: number) => () => {
        newDate.setFullYear(date.getFullYear() + count)
        if (cMinDate > newDate) return setDate(cMinDate);
        setDate(newDate)
    }

    const changeMonth = (count: number) => () => {
        const ycm = count < 0 ? 11 : 0;
        if (newDate.getUTCMonth() == (11 - ycm)) {
            newDate.setUTCFullYear(newDate.getUTCFullYear() + count, (count < 0 ? ycm : 0), 1);
        } else {
            newDate.setUTCFullYear(newDate.getUTCFullYear(), newDate.getUTCMonth() + count, 1);
        }
        if (cMinDate > newDate) return setDate(cMinDate);
        setDate(newDate)
    }

    const jumpToYear = (year: number) => {
        setYear(year); 
        setType(1)
    }
    const jumpToMonth = (month: number) => {
        setMonth(month); 
        setType(0)
    }


    const yearsRender = <Years className={`fade`} minDate={cMinDate} date={date} jumpTo={jumpToYear} prev={changeYear(-9)} next={changeYear(9)} />

    const yearRender = <Year className={`fade`} minDate={cMinDate} date={date} jumpTo={jumpToMonth} up={upper(2)} prev={changeYear(-1)} next={changeYear(1)} />;

    const monthRender = <Month className={`fade`} minDate={cMinDate} date={date} select={setDay} upper={upper(1)} prev={changeMonth(-1)} next={changeMonth(1)} />
    let render = monthRender
    switch (type) {
        case 0:
            render = monthRender;
            break;
        case 1:
            render = yearRender;
            break;
        case 2:
            render = yearsRender;
            break;

        default:
            render = monthRender;
            break;
    }
    return <div className={'CalendarInput ' + className}>
        {/*<div>{date.getDate()}.{monthNames[date.getMonth()]}.{date.getFullYear()}</div>*/}
        {render}
    </div>
}