import React, { FC, useEffect, useState } from 'react'
import { cnStudentResults, mapDispatchToProps, mapStateToProps } from './StudentResults.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './StudentResults.scss'
import { Button, Avatar } from 'uielements/src';
import { Sessions } from './Filters/Sessions/Sessions'
import { Units } from './Filters/Units/Units'
import { SummaryTable } from '../../components/SummaryTable/SummaryTable'
import { Chart } from '../../components/Chart/Chart'
import { Item } from './UnitsList/Item/Item'
import { UserMention } from 'uielements/src/UserMention/UserMention'
import { bindActionCreators } from 'redux';
import { List as AnswerList } from '../AnswerList/AnswerList'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { getModeratedAnswersCount, getTestCount, getWaitModerationCount } from 'LMSModule/utils/result.utils'

const UserAnswers: FC<any> = (props) => {
    return <AnswerList {...props} />
}
export const ConnectedUserAnswers = connect(
    (state: any, props: any) => {
        return ({
            units: state.LMSTO.moderation.units,
            questions: state.LMSTO.moderation.questions,
            answers: state.LMSTO.moderation.answers,
            users: state.LMSTO.users,
            answersIds: state.LMSTO.moderation.answersIds,
            filters: state.LMSTO.moderation.filters
        })
    },
    (dispatch: any) => bindActionCreators({}, dispatch)
)(UserAnswers);


export const StudentResultsPresenter: FC<any> = ({ className, cid, uid, sid, unit, units, location, studentSessions, results = {}, resultsIds = [], user = {} }) => {

    const TABS = [
        { value: 'results', content: tT('student_results.tabs.results.1') },
        { value: 'answers', content: tT('student_results.tabs.answers.1') }
    ]

    const testsCount = getTestCount(results.session.unitResults)

    const waitModerationCount = getWaitModerationCount(results.session.unitResults)

    const moderatedAnswersCount = getModeratedAnswersCount(results.session.unitResults)

    const rightPercent: number = results.session.rightAnswerPercentage;
    const wrongPercent: number = 100 - results.session.rightAnswerPercentage

    const firstUnitTest = results.session.unitResults.find((val: any) => val.unitType === 'test');
    let currentUnitId: any = null;
    if (!unit && !firstUnitTest) currentUnitId = null;
    else currentUnitId = unit || firstUnitTest.unitId;


    return <div className={cnStudentResults({}, [className])}>
        <div className={cnStudentResults('Aside')}>
            {<Sessions active={sid} cid={cid} uid={uid} />}
            {/*currentUnitId &&*/ <Units
                active={currentUnitId}
                cid={cid}
                sid={sid}
                userId={user.id}
                units={units}
                results={results.session.unitResults} />}
        </div>
        <div className={cnStudentResults('Section')}>

            <div className={cnStudentResults('Block', [cnStudentResults('Session')])}>
                <div className={cnStudentResults('Breadcrumbs')}><Button theme={'unstyled'} type={'rlink'} href={`?tab=users`}>{tT('student_results.students.1')}</Button> / {user.displayName}</div>

                <div className={cnStudentResults('Row')}>

                    <div className={cnStudentResults('Col')}>
                        <div className={cnStudentResults('Row')}>
                            <div className={cnStudentResults('Col', { noFlex: true })}>
                                <Avatar className={cnStudentResults('Avatar')} name={user.displayName} imgUrl={user.imgUrl} size={100} />
                            </div>
                            <div className={cnStudentResults('Col')}>
                                <div><UserMention className={cnStudentResults('UserMention')} name={user.displayName} id={user.id} /></div>
                                <div>{user.position}</div>
                                <div>{user.division}</div>
                            </div>
                        </div>
                    </div>

                    <div className={cnStudentResults('Col')}>
                        <div className={cnStudentResults('Row')}>
                            <div className={cnStudentResults('Col', { noFlex: true })}>
                                <Chart
                                    className={cnStudentResults('Chart')}
                                    waitModerationCount={waitModerationCount}
                                    moderatedAnswersCount={moderatedAnswersCount}
                                    rightPercent={rightPercent}
                                    wrongPercent={wrongPercent} />
                            </div>
                            <div className={cnStudentResults('Col')}>
                                <SummaryTable
                                    className={cnStudentResults('SummaryTable')}
                                    waitModerationCount={waitModerationCount}
                                    moderatedAnswersCount={moderatedAnswersCount}
                                    rightPercent={rightPercent}
                                    wrongPercent={wrongPercent} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {currentUnitId && <div className={cnStudentResults('Block')}>
                <div className={cnStudentResults('Row')}>
                    <div className={cnStudentResults('Col')}>
                        <Item key={currentUnitId + cid + sid} unit={results.session.unitResults.find((val: any) => val.unitId === currentUnitId)} sid={sid} cid={cid} userId={user.id} />
                    </div>
                </div>
            </div>}
        </div>


    </div>
}

export const StudentResults = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(StudentResultsPresenter));
