import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext } from '../../contexts/news'
import { Button } from 'uielements/src';
import openNews from 'blocks/Dialogs/News/DialogNewsView/DialogNewsView'
import MiniHeader from '../MiniHeader'
import { LinkFilter } from '../../utils/utils'

type IPresenterProps = News.HeadView.IPresenterProps
type IOwnProps = News.HeadView.IOwnProps
type IDispatchProps = News.HeadView.IDispatchProps
type IStateProps = News.HeadView.IStateProps
type IState = News.HeadView.IState


const noopFn = () => { }
const noopArray: any[] = []



export const Presenter: FC<IPresenterProps> = ({
    className,
    id
}) => {

    async function open() {
        try {
            const result = openNews({ newsId: id })
        } catch (e) { }
    }


    return <LinkFilter action={open}>

        {/* <Button type={'rlink'} theme={'unstyled'} href={`/news/${id}`} className={cnClassName({}, [className])}> */}
        <MiniHeader onClick={open} className={cnClassName('Header')} disableDate />

    </LinkFilter >
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));
