import React from 'react';
import { Box, Typography } from 'muicomponents/src';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import { Avatar } from 'muicomponents/src/Avatar';
import { Delete } from 'muicomponents/src/Icons';
import { ContentItemProps, cnHierarchyWidgetNew } from '../Widget_type_hierarchyNew.index';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export const ContentItem = ({ item, hierarchyName, onDelete }: ContentItemProps) => {
    const authUser: any = useSelector(getAuthUser);
    return (
        <Box className={cnHierarchyWidgetNew('Item')}>
            <Avatar
                sx={{
                    width: '48px',
                    height: '48px',
                }}
                srcSet={
                    item.imgUrl !== undefined
                        ? item.imgUrl
                        : `https://pryaniky.com/Img/UserProfiles/Preview/96m96/${item.id}.png`
                }
                alt={item.displayName}
            />
            <Box className={cnHierarchyWidgetNew('Item-Info')} sx={{ display: 'flex', gap: '8px', gridGap: '8px' }}>
                <Box sx={{ width: '90%' }}>
                    <UserMention
                        className={cnHierarchyWidgetNew('Item-Info-Name')}
                        id={`${item.id}`}
                        name={item.title || item.displayName}
                    />
                    <Typography
                        variant={'body2'}
                        sx={{
                            color: ' rgba(0, 0, 0, 0.60)',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {item.position}
                    </Typography>
                </Box>
                {authUser.baseData.isAdmin && hierarchyName && (
                    <IconButton
                        size={'small'}
                        className={cnHierarchyWidgetNew('Delete')}
                        onClick={() => onDelete(item)}
                    >
                        <Delete />
                    </IconButton>
                )}
            </Box>
        </Box>
    );
};
