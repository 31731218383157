import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    loadUnitsAttandance
} from '../../../redux/actions/LMSTeacherOffice'
import {
    getUnitsAttandance
} from '../../../redux/sagas/LMSTO/selectors'
///////////

export type IUnitsAttandanceProps = IUnitsAttandanceOwnProps & IUnitsAttandanceStateProps & IDispatchProps

export interface IUnitsAttandanceState { }

export interface IUnitsAttandanceOwnProps extends IClassNameProps {
    cid: string
}

export interface IUnitsAttandanceStateProps {
    unitsAttandance: any[]
    sessionsIds: string[]
    archiveSessions: string[]
    normalSessions: {[key: string]: any}
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IUnitsAttandanceOwnProps) => ({
    unitsAttandance: getUnitsAttandance(state),
    sessionsIds: state.LMSTO.courses.values[props.cid].activeSessions,
    archiveSessions: state.LMSTO.courses.values[props.cid].archiveSessions,
    normalSessions: state.LMSTO.sessions,
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IUnitsAttandanceOwnProps) => bindActionCreators({
    loadUnitsAttandance
}, dispatch);

////////////

export const cnClassName = cn('TOUnitsAttandance');