import React, { FC, useEffect, useState } from 'react'
import { IExamProps, cnExam } from './Exam.index'
import './Exam.scss'
import { TestInfo } from '../TestInfo/TestInfo'
import { Test } from '../Parts/Test/Test'
import { TestResult } from '../TestResult/TestResult'
import { ListSkiped } from '../ListSkiped/ListSkiped'
// import TaskInfo from '../TaskInfo'
import { withPreloader } from '../Preloader/Preloader'
import { TestInfo as Skelet } from '../../Skelets/TestInfo/TestInfo'
import { withUnitContentLoader } from 'LMSModule/utils/HOCs/withUnitContentLoader'
import { ETestStates } from 'LMSModule/utils/selectTestState'
import { useExam } from 'LMSModule/utils/hooks/useExam'



export const ExamPresenter: FC<any> = ({
    course,
    unit,
    log,
    sId,
    unitContent,
    fullscreen
}) => {
    const { skip, start, view } = useExam(sId, unit.id, log, unitContent)
    return <>
        {view === ETestStates.NOT_STARTED && <TestInfo fullscreen={fullscreen} log={log} course={course} unit={unit} unitContent={unitContent} start={start} skip={skip} />}
        {view === ETestStates.PROCESSING && <Test fullscreen={fullscreen} course={course} unit={unit} sId={sId} qId={unitContent.currentQuestion} />}
        {view === ETestStates.SKIPPED_LIST && <ListSkiped fullscreen={fullscreen} uid={unit.id} />}
        {view === ETestStates.RESULT && <TestResult cid={course.id} fullscreen={fullscreen} course={course} unit={unit} sId={sId} ulid={log.unitLogId} />}

    </>
}

const WithPreloader = withPreloader(ExamPresenter, ['unitContent', 'log'], [], <Skelet />)

export const Exam = withUnitContentLoader(WithPreloader);