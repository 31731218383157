import { IClassNameProps } from '@bem-react/core';
import { cnNewsView } from '../NewsView.index';

import { match } from 'react-router';

export interface ITopBadgeProps extends IClassNameProps {
  value?: string;
  left?: string;
  right?: string;
}

export interface ITopBadgeState {
  text?: string;
}

export const cnTopBadge = (...data: any) => {
  let name = 'TopBadge';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnNewsView(name, ...data)
}