import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace KPI {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace TableRow {

        export type IPresenterProps = IOwnProps

        export interface IOwnProps extends IClassNameProps {
            children?: React.ReactNode
            col1?: React.ReactNode
            col2?: React.ReactNode
            col3?: React.ReactNode
            col4?: React.ReactNode
            col5?: React.ReactNode
        }
    }
}
export const cnClassName = cn('KPIRow');