import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IBodyProps } from 'utils/src/DialogCreator';
import { IStateType } from 'redux/store';
import { 
    changeStructureDataOrgchart, 
    saveStructureDataOrgchart,
    newPositionDataKill,
    newUnitDataKill,
    getUnitByTagId
} from '../../../redux/sagas/orgchart/actions'
import { newUnit } from 'redux/sagas/orgchart/reducer'
import { 
    getOrgchartPositions, 
    getOrgchartState, 
    getOrgchartNewUnit,
    // getOrgchartChangedUnitSettings,
    getParentUnit
} from '../../../redux/sagas/orgchart/selectors'


export interface IUnitEditProps extends IClassNameProps, IUnitEditStateProps, IUnitEditDispatchProps, IUnitEditOwnProps {

}

export type IUnitEditStateProps = ReturnType<typeof mapUnitEditStateToProps>;

export interface IUnitEditOwnProps extends IBodyProps<any> {
    // id?:string;
    parentUnitId?: string | null;
    addNewUnit?: boolean;
    isShown?: boolean;
    addHeadOpen?: boolean;
    addSubOpen?: boolean;
    editUnit?: boolean;
    addVacancy?: boolean;
    addNewUnitFromHeader?: boolean;
}

export const mapUnitEditStateToProps = (state: IStateType, props: IUnitEditOwnProps) => {
    // const list = state.lists['alone'];
    return {
        // unitData: getOrgchartState(state).initUnitsData.units[props.id],
        // unitData: getOrgchartNewUnit(state),
        unitData: getOrgchartNewUnit(state),
        // unitData: getOrgchartNewUnit(state),
        parentUnitData: props.parentUnitId && getParentUnit(props.parentUnitId)(state),
      // postionIds:props.id && getOrgchartChangedPosition(props.id)(state).positionIds && getOrgchartChangedPosition(props.id)(state).positionIds.map((el => getOrgchartPositions(el)(state))),
    };
};

export type IUnitEditDispatchProps = ReturnType<typeof mapUnitEditDispatchToProps>

export const mapUnitEditDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    changeStructureDataOrgchart,
    saveStructureDataOrgchart,
    newUnitDataKill,
    newPositionDataKill,
    getUnitByTagId
}, dispatch)


export const cnUnitEdit = cn('UnitEdit');

