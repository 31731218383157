import React, { FC, useState, useMemo } from 'react';
import { ISkillsDialogEditProps, cnSkillsDialogEditDialog } from './EditDialog.index';
import i18n from 'localizations/i18n';
import { Translate } from 'localizations/Translate';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle';
import { DialogContent } from 'muicomponents/src/DialogParts/DialogContent';
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { Box, TextField, Divider } from 'muicomponents/src'
import FormControl from '@mui/material/FormControl';
import * as utils from 'utils/src/utils';
import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import { useResizeHandler } from 'utils/src/hooks'
import SoloAutocomplete from '@mui/material/Autocomplete';
import { IItem } from 'uielements/src/MaterialElements/withSuggester'
import { Chip } from 'muicomponents/src/Chip/Chip'
import { Link } from 'muicomponents/src/Link/Link'
import { Button } from 'muicomponents/src/Button/Button'
import { ISkill } from 'redux/sagas/Skills/types'
import { createDefaultSkill } from 'redux/sagas/Skills/types'
import { useSkillsItem } from '../../hooks'
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useRefedCallback } from 'utils/src/hooks'
import { SplitButton } from 'muicomponents/src/SplitButton/SplitButton'
import { ISplitButtonOption } from 'muicomponents/src/SplitButton/SplitButton.index'
import ButtonGroup from '@mui/material/ButtonGroup';
import { Tag } from 'muicomponents/src/Icons'
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { AvTimer } from 'muicomponents/src/Icons'
import { SkillsSuggester } from 'muicomponents/src/Suggester/type/SkillsSuggester';
import { SelectableObject } from 'utils/src/BaseTypes/selectableObject.types';
const Alert: FC<AlertProps> = (props) => <MuiAlert elevation={3} variant="standard" {...props} />;

const FormControlWithHelp = fieldWithHelp(FormControl)
const SkillsDialogEditPresenter: FC<ISkillsDialogEditProps> = ({
    handleAccept,
    handleClose,
    currentUser,
    onComplete,

    skills

}) => {
    const [elements, setElements] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [skillsArr, setSkillsArr] = useState<ISkill[]>(skills || []);
    const [newSkillsArr, setNewSkillsArr] = useState<ISkill[]>([]);
    const [deletedSkillsArr, setDeletedSkillsArr] = useState<ISkill[]>([]);
    const [showActionButton, setShowBtn] = useState(false)
    const [del, setDel] = useState({ confirm: false, skillId: "" })

    const [title, setTitle] = useState<SelectableObject | null>(null)

    const withError = useMemo(() => {
        return (Boolean(title?.displayName)) && !/^[\s0-9A-Za-zА-Яа-яЁё_]+$/gi.test(title?.displayName || "")
    }, [title])
    // const onChangeText = (
    //     event: React.ChangeEvent<{}>,
    //     value: string = ''
    // ) => {
    //     if (!event) return;
    //     setTitle(value)
    //     setLoading(true)
    //     const suggesterRequest = utils.API.suggesters.endorse(value)

    //     suggesterRequest.r.then((d: any) => {
    //         setLoading(false)
    //         if (utils.checkResponseStatus(d)) {
    //             setElements(d.data.map((item: any): IItem => item.displayName.replace(/_/gm, ' ')));
    //         }
    //     });
    // }

    const ref = useResizeHandler((el) => {
        if (el.clientWidth < 500) {
            setShowBtn(false)
        } else {
            setShowBtn(true)
        }
    })
    const createNewSkill = () => {
        if (!title) return;
        if (!Array.isArray(title)) {
            const skill = createDefaultSkill()
            skill.tag.displayName = title.displayName
            setSkillsArr([skill, ...skillsArr])
            setNewSkillsArr([skill, ...newSkillsArr])
            setTitle(null)
        }
    }
    const deletSkill = (skill: ISkill) => {
        setSkillsArr(skillsArr.filter((el) => skill.tag.id !== el.tag.id))
        setDeletedSkillsArr([skill, ...deletedSkillsArr])
    }
    return (
        <React.Fragment>
            <DialogTitle onClose={handleClose}>
                <Translate i18nKey='pryaniky.skills.edit.dialog.title' />
            </DialogTitle>
            <DialogContent sx={{ padding: "5px 24px 0 24px" }}>
                <Box ref={ref as any} sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} className={cnSkillsDialogEditDialog('InputBox')}>
                        <FormControlWithHelp fullWidth help={i18n.t("pryaniky.skills.create.input.help.1")} >
                            {/* <SoloAutocomplete
                                freeSolo
                                onInputChange={onChangeText}
                                loading={!withError && loading}
                                filterOptions={(items) => items}
                                value={title}
                                options={withError ? [] : elements}
                                renderInput={(params: any) => <TextField
                                    {...params}
                                    error={withError}
                                    InputProps={{
                                        startAdornment: <Tag />
                                    }}
                                    variant={"outlined"}
                                    helperText={withError && i18n.t("pryaniky.skills.create.input.error.1")}
                                    label={i18n.t("pryaniky.skills.create.input.1")} />}
                            /> */}
                            <SkillsSuggester
                                TextFieldProps={{
                                    label: <Translate i18nKey={"pryaniky.skills.create.input.1"} />,
                                    error: withError,
                                    helperText: withError && i18n.t("pryaniky.skills.create.input.error.1")
                                }}
                                // multiple

                                value={title}
                                onChange={(e, value) => {
                                    if (!Array.isArray(value)) {
                                        setTitle(value)
                                    }
                                }}
                                createItems={{
                                    allow: true,

                                }}
                            />
                        </FormControlWithHelp>
                        <Box sx={{ width: "20%" }}>
                            <Button variant='contained' disabled={withError}  onClick={() => createNewSkill()}>{i18n.t("pryaniky.skills.add.1")} </Button>
                        </Box>
                    </Box>
                </Box>
                <Box >
                    {skillsArr.map((skill) => <Box sx={{
                        width: "100%",
                    }}>
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "16px 0",

                        }}>
                            <Box sx={{ width: "80%", display: "flex", alignItems: "center" }}>
                                <Link
                                    href={`/tag/${skill.tag.id}/people?collectionType=skills`}
                                    className={cnSkillsDialogEditDialog('TagLink')}
                                    title={skill.tag.displayName}>
                                    <Chip key={skill.tag.id}
                                        sx={{ cursor: "pointer", color: "#fff", background: `${skill.onModeration ? "rgba(0, 0, 0, 0.26)" : ""}` }}
                                        className={cnSkillsDialogEditDialog(`SkillChip ${!skill.onModeration && "CustomStyle primaryColor3-bg"}`)}
                                        color="primary"
                                        variant='filled'
                                        label={skill.tag.displayName} />
                                </Link>
                                {skill.onModeration &&
                                    <Tooltip
                                        className={'InfoIcon'}

                                        title={i18n.t('pryaniky.skills.onModeration.help.1')}
                                    >
                                        <Box sx={{ display: "flex", alignItems: "center" }}><AvTimer sx={{ marginLeft: "5px", color: "rgba(0, 0, 0, 0.54)" }} /></Box>

                                    </Tooltip>}
                            </Box>
                            <Box sx={{ width: "20%" }}>
                                <Button variant='outlined' onClick={() => deletSkill(skill)}>{i18n.t("pryaniky.skills.delete.1")} </Button>
                            </Box>
                        </Box>
                        <Divider />
                    </Box>)}
                </Box>

            </DialogContent>
            <DialogActions
                acceptText={i18n.t('pryaniky.subnetworks.dialog.item.confirm')}
                closeText={i18n.t('pryaniky.cancel.dialog')}
                onAccept={() => handleAccept({ createArr: newSkillsArr, deleteArr: deletedSkillsArr })}
                onClose={handleClose}
            />
        </React.Fragment>
    );
}


export const DialogEdit = createModal(SkillsDialogEditPresenter, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});
