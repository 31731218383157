import React, { useState, useLayoutEffect } from 'react';
import Snowfall from 'react-snowfall';
import { useLocalStorage } from 'utils/src/hooks'

let timeout: NodeJS.Timeout;

export const SnowFlakes = ({ }) => {
  const [state] = useLocalStorage('show_snow', true)
  const [show, setShow] = useState(false);

  useLayoutEffect(() => {
    timeout = setTimeout(() => setShow(true), 10000);
    return () => {
      clearTimeout(timeout);
    }
  }, [])
  if (!state) return <></>
  if (!show) return <></>;

  return <Snowfall snowflakeCount={80} style={{ position: 'fixed' }} />
}