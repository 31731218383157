import {
    IDispatchProps,
    IDistributeFormOwnProps,
    IDistributeFormPresenterProps,
    IState,
    IStateProps,
    cnClassName,
    mapDispatchToProps,
    mapStateToProps
} from './DistributeForm.index'
import React, { memo } from 'react'
import './DistributeForm.scss';
import { EditorTypeNews } from '../../Creativetasksend/Editor/containers/news/news'
import { EditorForm } from '../../../EditorForm/EditorForm';
import i18n from '../../../../localizations/i18n'
import { Button, /*SplitButton,*/ ButtonBox } from 'uielements/src'
import withHelpTooltip from 'blocks/HOCs/Button/withHelpTooltip'
import withConfirmDialog from 'blocks/HOCs/Button/withConfirmDialog'
import { withNewsContext } from 'News/contexts/news'
import { connect } from 'react-redux';
import * as utilsProj from 'utils.project/utils.project'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { SplitButton } from 'muicomponents/src/SplitButton/SplitButton'
import { LoadingButton as MuiLoadingButton } from 'muicomponents/src/LoadingButton/LoadingButton'
import { LightTooltip } from 'muicomponents/src/Tooltip/Tooltip'

const LoadButton = withLoadSpin(Button)
const HelpLoadButton = withHelpTooltip(withConfirmDialog(LoadButton))
const LoadingButton = withConfirmDialog(MuiLoadingButton)

export const _DistributeForm: React.FC<IDistributeFormPresenterProps> = ({
    newsId,
    changeData,
    distributeCompetition,
    cancleCompetition,
    data,
    rewardedComments,
    rewardAll,
    cancleAllReward,
    isLoadingReply,
    creativeTask
}) => {
    const onSend = () => {
        distributeCompetition({
            newsId
        })
    }
    const onChangeFile = () => { }

    const onChange = (data: any) => {
        changeData({
            newsId,
            data
        })
    }
    const onCancle = () => {
        utilsProj.confirm({
            text: i18n.t('pryaniky.post.creativetasks.cancle'),
            onConfirm: () => { cancleCompetition({ newsId }) },
        });
    }

    const cancleOptions = [{
        id: 'onCancle', content: i18n.t('pryaniky.post.creativetasks.cancle.option')
        // title: i18n.t('pryaniky.post.creativetasks.cancle.option'),
        // buttonProps: { onClick: onCancle }
    }];

    const values = Object.keys(rewardedComments || {})
    const summary = values.reduce((acc, cur) => acc + rewardedComments[cur], 0)
    const fund = creativeTask.fund

    const notDist = values.length === 0

    return <div className={cnClassName({}, [])}>
        <h4 className={cnClassName("Title")}>{i18n.t('pryaniky.post.creativetasks.distribute_title')}</h4>
        <div className={cnClassName("Section")}>
            <div className={cnClassName("Stats")}>
                <div>{i18n.t('pryaniky.post.creativetasks.selected_count')} {values.length}</div>
                <div>{i18n.t('pryaniky.post.creativetasks.summury_reward')} {summary}</div>
                <div>{i18n.t('pryaniky.post.creativetasks.avaible_reward')} {fund}</div>
            </div>
            <div className={cnClassName("Actions")}>
                <ButtonBox>
                    <LoadButton isLoading={isLoadingReply} main onClick={() => rewardAll({ newsId })}>{i18n.t('pryaniky.post.creativetasks.rewardAllReply')}</LoadButton>
                    <LoadButton disabled={notDist} isLoading={isLoadingReply} onClick={() => cancleAllReward({ newsId })}>{i18n.t('pryaniky.post.creativetasks.cancleAllReward')}</LoadButton>
                </ButtonBox>
            </div>
        </div>
        <EditorForm
            files={[]}
            disableAttach
            onChangeFile={onChangeFile}
            save={onSend}
            validationErrors={[]}
            complie={(isValid) => <LightTooltip title={i18n.t('pryaniky.post.creativetasks.distribute_help')}>
                <SplitButton
                    enablePortal
                    tooltipZIndex={1401}
                    popperOptions={{placement:'top-start',strategy: 'fixed'}}
                    handleMenuItemClick={(_, item) => {
                        if (item.id === 'onCancle') onCancle()
                    }}
                    children={
                        <LoadingButton
                            // help={<div>{i18n.t('pryaniky.post.creativetasks.distribute_help')}</div>}
                            className={'ActionButtons-SaveBtn'}
                            // disableHelp={!notDist}
                            variant={'contained'}
                            disabled={isValid || notDist}
                            loading={isLoadingReply}
                            onConfirm={onSend}
                        >{i18n.t('pryaniky.post.creativetasks.distribute_title')}</LoadingButton>
                    }
                    options={cancleOptions} />
            </LightTooltip>}
        >
            <EditorTypeNews
                onChange={onChange}
                data={data as any}
            />
        </EditorForm>
    </div>
}


export const DistributeForm = withNewsContext(connect<IStateProps, IDispatchProps, IDistributeFormOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(_DistributeForm)));