import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
// import { updateRequestOptions } from "../../redux/actions/News";
import { IDispatchProps } from '../../redux/connector';
import { RouteComponentProps } from 'react-router';
import { setContext } from 'redux/actions/Widgets';

export interface IGroupProps extends IClassNameProps, IGroupStateProps, IDispatchProps, IActionsDispatch, RouteComponentProps<IGroupUrlParams> {
  tag?: 'div';
}

export interface IGroupUrlParams {
  id: string;
  tab?: string;
}

export interface IGroupState {
  text?: string;
}

export interface IActionsDispatch {
  updateRequestOptions?: any;
  setContext: typeof setContext;
  // setContext: typof setContext
}

export const mapActionsToProps: IActionsDispatch = {
  // updateRequestOptions,
  setContext
};

export interface IGroupStateProps {
  // store: any
  // context: IContext;
  edit: boolean;
  context: { [s: string]: any };
}

export const mapStateToProps = (state: any) => ({
  // store: state.store,
  // context: state.store.context,
  context: state.widgets.contexts,
  edit: state.edit,
  // requestOptions: state.news.requestOptions
})

export const cnGroup = cn('Group');