import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { IUserBirthday } from 'utils/src/requests/models/api.users';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions'

export interface IBirthdaysProps extends IClassNameProps, IBirthdaysStateProps, IDispatchProps, RouteComponentProps<{ month: string }> {
  tag?: 'div';
}

export interface IBirthdaysState {
  birthdays: IUserBirthday[];
  isLoading: boolean;
  isFinished: boolean;
}

export interface IBirthdayEvent {
  title: string;
  date: string;
  description: string;
}

export const mapStateToProps = (state: any) => ({
  currencyName: state.store.appSettings.currencyNameAccusativeSingular  as string,
  enabledThanks: state.store.appSettings && state.store.appSettings.enabledModules.thanks,
  currentUser: getAuthUser(state),
  language: getAuthUser(state).baseData.language
})

export type IBirthdaysStateProps = ReturnType<typeof mapStateToProps>

export const cnBirthdays = cn('Birthdays');