import React, { ComponentProps, FC, useRef, ComponentPropsWithoutRef, useEffect, useCallback, createRef, useState, useLayoutEffect } from 'react';
import {
  cnPagesMenuMui,
  NPagesMenuMui,
  MENU_HIDE_BREAKPOINT,
  mapWikiInfoStateToProps,
  mapWikiInfoDispatchToProps
} from './PagesMenuMui.index';
import { Slide, TransitionGroup } from 'muicomponents/src/Transitions/Transitions';
import './PagesMenuMui.scss'
import { ClickAwayListener } from 'muicomponents/src/ClickAwayListener';
import { Translate } from '../../../localizations/Translate';
import { Edit, History, Comment, Info, ArrowBack, ArrowForward, Favorite, FavoriteBorder, Visibility, ChatBubble, ChatBubbleOutline } from 'muicomponents/src/Icons';
import { MenuItem, MenuItemBuble, TransitionGroupBox, StyledBar } from "./PagesMenuMui.styled"
import { connect } from 'react-redux';
import { Button } from 'muicomponents/src/Button/Button'
import { IconButtonCounterUsers } from 'muicomponents/src/IconButtonCounter/IconButtonCounter'
import likersModal from 'blocks/Dialogs/News/Likers/NewsLikers'
import { Typography } from 'muicomponents/src/Typography/Typography'

export const PagesMenuMuiPresenter: FC<NPagesMenuMui.Props> = ({
  className,
  stayOpened,
  pageInfo,
  isEditable,
  likeWikiRequest,
  isWkilistModerator,
}) => {
  const [hide, setHide] = React.useState(false)
  const [liked, setLiked] = useState(false)
  const [likeCountNumber, setLikeCountNumber] = useState(0)

  const [opened, setOpened] = React.useState(stayOpened)
  useEffect(() => {
    setLiked(pageInfo?.isLiked)
    setLikeCountNumber(pageInfo?.news?.likeCount)
  }, [pageInfo?.isLiked, pageInfo?.news?.likeCount])
  const wikiMenu = [

    {
      content: <MenuItem className={cnPagesMenuMui("MenuItem-openCloseButton")}>{opened ? <ArrowForward color="primary" /> : <ArrowBack color="primary" />} </MenuItem >,
      action: 'openCloseButton',
    },

    {
      content: <MenuItem className={cnPagesMenuMui("MenuItem-info")}>
        <Info sx={{ marginRight: opened ? "14px" : "0px" }} color="primary" />
        {opened ? <Typography variant='body2'><Translate i18nKey={'pryaniky.wikipage.menu.info'} /> </Typography> : undefined}
      </MenuItem >,
      action: 'info'
    },
  ]


  // if (pageInfo?.allowComments && pageInfo?.commentType !== "BottomPanel") {
  if (pageInfo?.allowComments) {

    wikiMenu.splice(2, 0, {
      content: <MenuItem className={cnPagesMenuMui("MenuItem-allowComments")}>
        {pageInfo?.news?.commentsCount > 0 && <MenuItemBuble>{pageInfo?.news?.commentsCount}</MenuItemBuble>}
        {pageInfo?.news?.commentedByMe ? <ChatBubble sx={{ marginRight: opened ? "14px" : "0px" }} color="primary" /> :
          <ChatBubbleOutline sx={{ marginRight: opened ? "14px" : "0px" }} color="primary" />}
        {opened ? <Typography variant='body2'><Translate i18nKey={"pryaniky.wikipage.menu.comments"} /></Typography> : undefined}
      </MenuItem >,
      action: 'comments'
    })
  }
  if (isEditable) {
    wikiMenu.push({
      content: <MenuItem className={cnPagesMenuMui("MenuItem-wikiJornal")}>
        <History sx={{ marginRight: opened ? "14px" : "0px" }} color="primary" />
        {opened ? <Typography variant='body2'><Translate i18nKey={`pryaniky.wikipage.menu.wikiJornal`} /> </Typography> : undefined}
      </MenuItem >,
      action: 'wikiJornal'
    })
  }

  if (isEditable && pageInfo) {
    wikiMenu.push({
      content: <MenuItem className={cnPagesMenuMui("MenuItem-editWiki")}>
        <Edit sx={{ marginRight: opened ? "14px" : "0px" }} color="primary" />
        {opened ? <Typography variant='body2'><Translate i18nKey={`pryaniky.wikipage.menu.editWiki`} /> </Typography> : undefined}
      </MenuItem >,
      action: 'editWiki'
    })
  }
  const changeLike = (likeCount: number, liked: boolean) => {
    likeWikiRequest(pageInfo?.news.id, likeCount, liked)
    // setLiked(!liked)
  }
  const changeLikeCount = () => {
    setLiked(!liked)
    setLikeCountNumber(!liked ? likeCountNumber + 1 : likeCountNumber - 1)
  }

  const likeIcon = liked ? <Favorite sx={{ marginRight: opened ? "14px" : "0px" }} color="primary" /> : <FavoriteBorder sx={{ marginRight: opened ? "14px" : "0px" }} color="primary" />;

  if (pageInfo) {
    wikiMenu.push({
      content: <MenuItem className={cnPagesMenuMui("MenuItem-LikeWiki")}>

        {opened ? <IconButtonCounterUsers
          sx={{
            fontSize: '12px',
            border: "none",
            padding: 0,
            color: "#000",
            "&:hover": {
              border: "none",
              background: "inherit",
              color: "#000"
            }
            /*, marginRight: '8px' */
          }}
          // elements={likedByUsers}
          // size='small'

          newsId={pageInfo?.news?.id}
          variant='text'
          isActive={liked}
          onClickTooltip={() => likersModal({
            newsId: pageInfo?.news?.id,
          })}
          onClick={() => {
            changeLike(pageInfo?.news.likeCount, liked)

            changeLikeCount()

          }}
          title={Translate.t({ i18nKey: `pryaniky.wikipage.menu.like` })}>
          {likeIcon}
          <Typography color={"#000"} variant='body2'>
            <Translate i18nKey={`pryaniky.wikipage.menu.like`} />
          </Typography>
        </IconButtonCounterUsers> :
          <>
            {likeIcon}
          </>}
        {pageInfo?.news?.likeCount > 0 ? <MenuItemBuble>{likeCountNumber}</MenuItemBuble> : undefined}
        {/* {opened ?   : undefined} */}
        {/* {opened ? <Translate i18nKey={`pryaniky.wikipage.menu.like`} /> : undefined} */}
      </MenuItem >,
      action: 'like'
    },
      {
        content: <MenuItem className={cnPagesMenuMui("MenuItem-viewedCount")}>
          <MenuItemBuble>{pageInfo?.viewedCount}</MenuItemBuble>
          <Visibility sx={{ marginRight: opened ? "14px" : "0px" }} color={'action'} />
          {opened ? <Typography variant='body2'><Translate i18nKey={`pryaniky.wikipage.menu.viewedCount`} /></Typography> : undefined}
        </MenuItem >,
        action: 'viewed'
      }
    )
  }

  let bars = ["main"]
  const groupRef = createRef<HTMLDivElement>();
  const barsRef = useRef<{ [s: string]: HTMLDivElement }>({});

  // const checkWidth = useCallback(() => {
  //   if (document.body.clientWidth < MENU_HIDE_BREAKPOINT) {
  //     setHide(true);
  //   }
  //   else {
  //     setHide(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   checkWidth();
  //   window.addEventListener('resize', checkWidth);
  //   return () => {
  //     window.removeEventListener('resize', checkWidth);
  //   };
  // }, []);

  useEffect(() => {
    stayOpened && setOpened(stayOpened);
  }, [stayOpened]);

  const [overflow, setOverflow] = useState(false);

  const [isEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    const barsWidth = Object.typedKeys(barsRef.current || {}).reduce((a: number, c) => {
      const width = (barsRef.current || {})[c]?.clientWidth || 0;
      return a + width;
    }, 0);
    if (barsWidth >= document.body.clientWidth) {
      setOverflow(true);
    } else {
      if (overflow) setOverflow(false);
    }
  }, [bars.length, groupRef.current]);

  const handleClose = (close: boolean) => {
    setOpened(close)
  };

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (isEditOpen) {

        } else {
          const target = e.target as HTMLElement;
          setOpened(false)
        }

      }}

    >
      <TransitionGroup
        component={TransitionGroupBox}
        className={cnPagesMenuMui({ stayOpened, hide, opened }, [className])}
        sx={{
          maxWidth: hide ? 0 : stayOpened && !opened ? 300 : !opened ? 93 : undefined,
          overflowX: hide ? 'hidden' : opened && overflow ? 'auto' : undefined
        }}
        onClick={() => {
          if (opened) {
            return
          }
          else {
            setOpened(true)
          }
        }}
      >
        {
          /**
           * @todo remove bars array
           */
          bars.map((key, idx, arr) => {
            const id = key.toString();
            const ids = arr.map(el => el.toString());
            return (
              <Slide
                direction="left"
                key={id}
                mountOnEnter
                unmountOnExit
                onEnter={(el) => {
                  el.style.zIndex = '5';
                }}
                onEntered={(el) => {
                  el.style.zIndex = '';
                }}
                onExit={(el) => {
                  el.style.display = 'none';
                }}
              >
                <StyledBar
                  id={id.toString()}
                  zIndex={100 - idx}
                  path={ids.slice(0, idx + 1)}
                  stayOpened={stayOpened}
                  opened={opened}
                  pageInfo={pageInfo}
                  editOpen={(value) => setIsEditOpen(value)}
                  onClose={() => setOpened(false)}
                  items={wikiMenu}
                  isWkilistModerator={isWkilistModerator}
                  ref={(e: any) => {
                    barsRef.current[id.toString()] = e;
                  }}
                />
              </Slide>
            )
          })
        }

      </TransitionGroup>
    </ClickAwayListener>
  )
};


export const PagesMenuMui = connect(
  mapWikiInfoStateToProps,
  mapWikiInfoDispatchToProps
)(PagesMenuMuiPresenter)
// export const PagesMenuMui: FC<ComponentProps<typeof PagesMenuMuiPresenter>> = ({
//   ...props
// }) => {

//   return (
//     <DynamicModuleLoader modules={[getPagesMenuMuiModule()]}>
//       <PagesMenuMuiPresenter {...props} />
//     </DynamicModuleLoader>
//   );
// };