import React, { FC, useEffect, useState } from 'react'
import { IQuestionProps, mapDispatchToProps, mapStateToProps, cnSkipedQuestion } from './Question.index'
import './Question.scss'
import { connect } from 'react-redux';
import { Button } from 'uielements/src'
import QuestionLayout from '../../../components/Question'
import { tS } from 'LMSModule/utils/i18n.adaptor'

const QuestionPresenter: FC<any> = ({ questions, transitionToQuestion, uid, qid }) => {
    const allowSkip = questions[qid].allowSkip;
    return <div className={cnSkipedQuestion()}/* onClick={() => jumpToQuestion(uid, qid)}*/>
        <div className={cnSkipedQuestion('Header')} >
            <QuestionLayout
                hideContent={true}
                className={cnSkipedQuestion('Question')}
                content={questions[qid].text}
                header={questions[qid].header}
            />
        </div>
        <Button main className={cnSkipedQuestion('Start')} onClick={() => transitionToQuestion(uid, qid)}>{tS('start.1')}</Button>
    </div>
}

export const Question = connect(
    mapStateToProps,
    mapDispatchToProps
)(QuestionPresenter);