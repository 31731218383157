import React from 'react';
import * as utils from 'utils/src/utils';

import { cnWidget, cnWidgetMui, IWidgetProps } from '../Widget.index';

import './Widget_type_virtcurrency.scss';
import WidgetPresenter from '../Widget';
import { widgets } from 'i.widgets';

const types = {
  common: require('blocks/WidgetBlocks/MobileApp/MobileAppWidget').MobileAppWidget,
};

const type = 'mobileApp';

export default class WidgetTypeMobileAppPresenter extends WidgetPresenter<IWidgetProps> {
  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    return (
      <Component
        {...this.props}
        className={cnWidgetMui({ type, subtype })}
        contexts={this.relations.concat(this.props.widget.id)}
        settings={this.props.widget.settings}
        tag={widgets.components.common}
      />
    );
  }
}
