import React from 'react';
import i18n from '../../../localizations/i18n';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { PrnUserInput } from "../../PrnUserInput/PrnUserInput";
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';

// TextControl
export const text = (props: any) => {
  if (!props.visible) return null;
  // const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  return <UIElements.Textarea
    onChange={(ev: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => ev.currentTarget.value))}
    value={props.data[get_variable_name(props.uischema.scope)]} />
}
// end TextControl