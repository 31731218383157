import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { RouteComponentProps } from 'react-router';
import { IShopOrder } from 'utils/src/requests/models/api.shop';
import { IDispatchProps } from 'redux/connector';

export interface IOrdersListProps extends IClassNameProps {
  
}


export const cnOrders = cn('OrdersList');
