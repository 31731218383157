import { useDispatch, useSelector } from "react-redux";
import { FNewsPostType } from "utils/src/BaseTypes/news.types";
import { newsCreatorItemNewsChange } from "../../redux/actions.newsCreator";
import { getNewsCreateItemFields, getNewsCreateItemNewsFields } from "../../redux/selectors.newsCreate";
import { NewsCreateReducer, NewsPostTypeWithDraftRaw } from "../../redux/types.newsCreator";
import { NewsCreateContext } from "News/creator/Creator/Creator.constants";
import { useCallback, useContext } from "react";
import { NEvents } from "./Events.interface";
import { getAllowPostTypeById } from "redux/sagas/AppSettings/selectors";
import { DeepPartial, ExtractByParam } from "utils/src";
import { getCurrentUserIsAdmin } from "utils/src/CommonRedux/base/selectors";
import { useDebounce, useDidUpdateEffect, useReffedState } from "utils/src/hooks";

type EventType = ExtractByParam<NewsPostTypeWithDraftRaw, 'componentRenderName', 'events'>;
type FieldsToCreator = Extract<keyof EventType, 'header' | 'text' | 'expires' | 'uevent' | 'users' | 'groups' | 'draftRawState' | 'ishidden'>;

const EventsFields: FieldsToCreator[] = ['header', 'text', 'expires', 'uevent', 'users', 'groups', 'draftRawState', 'ishidden'];

export const useEventsEdit = (id: keyof NewsCreateReducer) => {

    const dispatch = useDispatch();

    const { groupId, workflowId } = useContext(NewsCreateContext);

    const data = useSelector(getNewsCreateItemNewsFields(EventsFields)(id)) as Pick<EventType, FieldsToCreator>;

    const creatorFields = useSelector(getNewsCreateItemFields(['errors', 'clearEditor', 'selectedNews'])(id));

    const renderParams = useSelector(getAllowPostTypeById(creatorFields.selectedNews || '', groupId, workflowId))?.renderParams as NEvents.RenderParams | undefined;

    const isAdmin = useSelector(getCurrentUserIsAdmin);

    const [dataState, setDataState, dataRef] = useReffedState(data);

    const setReduxNews = useDebounce((news: DeepPartial<typeof data>) => {
        dispatch(newsCreatorItemNewsChange({ id, news }));
    }, 150);

    const changeNews = useCallback((news: DeepPartial<typeof data>) => {
        // setDataState({
        //     ...dataRef.current,
        //     ...news as typeof dataRef['current'],
        //     uevent: {
        //         ...dataRef.current.uevent,
        //         ...news.uevent as typeof dataRef['current']['uevent']
        //     }
        // });
        // setReduxNews(news);
        dispatch(newsCreatorItemNewsChange({ id, news }));
    }, []);

    return {
        data,
        isAdmin,
        renderParams,
        ...creatorFields,
        changeNews
    };
};