import React from 'react'
import { cnSurveyWidgetItem, ISurveyWidgetItem, mapStateToProps, mapDispatchToProps } from './SurveyWidgetItem.index'
import { Button as PrnButton, Avatar } from 'uielements/src'
import i18n from 'localizations/i18n'
import { connect } from 'react-redux'
import { PostContentRender } from '../../PostContentRender/PostContentRender'
import './SurveyWidgetItem.scss'
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import Link from '@material-ui/core/Link';
import { Typography } from 'muicomponents/src/Typography/Typography'
import { styled } from 'muicomponents/src/mui/system';
import { Box, Button as MuiButton } from 'muicomponents/src'
import { Tooltip } from 'muicomponents/src/Tooltip'

const TextsTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden'
}) as typeof Typography;

export const TypographyName = styled(TextsTypography)({
  minHeight: "3em",
}) as typeof TextsTypography;

export const TypographyDesc = styled(TextsTypography)({
  minHeight: "2.86em",
  color: "#00000099"
}) as typeof TextsTypography;

const SurveyWidgetItemPresenter = ({ data, settings }: ISurveyWidgetItem) => {

  const trueShortText = data.textShort.substring(0, 97)

  return (
    <>
      {data && <div className={cnSurveyWidgetItem()}>
        <div className={cnSurveyWidgetItem('SurveyAvatar')}>
          {data.iconImgUrl &&
            <Avatar precentage={100} shape={'rectangle'}
              imgUrl={data.iconImgUrl}
            />
            // <img className={cnSurveyWidgetItem('Title-Image')} src={data.iconImgUrl}/>
          }
          {/* // <Avatar size={250} shape={'square'} className={cnSurveyWidgetItem('Title-Image')} imgUrl={data.iconImgUrl} />} */}
        </div>
        <div className={cnSurveyWidgetItem('Title')}>
          <Tooltip title={data.name} overflowOnly disableInteractive>
            <TypographyName variant="body1" className={cnSurveyWidgetItem('Title-Text')}>{data.name}</TypographyName>
          </Tooltip>
          {
            settings?.showDesc && 
            <Tooltip title={data.textShort} overflowOnly disableInteractive>
              <TypographyDesc variant='body2' className={cnSurveyWidgetItem('Title-Text')}>{data.textShort}</TypographyDesc>
            </Tooltip>
          }
          <MuiButton
            color='primary'
            className={cnSurveyWidgetItem('Title-GoToSurveyBTN')}
            variant={'contained'}
            href={`/survey/go/${data.id}`}
          >
            {i18n.t('pryaniky.quizwidget.goToQuiz')}
          </MuiButton>
         
        </div>

      </div>}
    </>
  )
}

export const SurveyWidgetItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyWidgetItemPresenter)