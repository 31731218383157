import { connect } from 'react-redux';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as utils from 'utils/src/utils';
import { stateToProps, mapDispatchToProps } from "../../redux/connector";
import { IWIntegarteProps, mapStateToProps } from './WIntegrate.index';
import { prepareWidget } from 'i.widgets';
import { store } from 'redux/store';
import { denormalize, normalize, schema } from 'normalizr';
import { integrationModes } from 'utils/src/constants.prn';

let timer: any;

class WIntegratePresenter extends React.Component<IWIntegarteProps, any> {
  public el: HTMLDivElement;
 
  constructor(props: IWIntegarteProps) {
    super(props); 
    this.setBaseData();
  }

  private setBaseData = (callback?: () => void) => {
    utils.API.users.byId(utils.getCookie('authUid'), { fullData: true, appSettings: true }).r.then((d: any) => {
      if (utils.checkResponseStatus(d)) {
        // const authUser = {
        //   baseData: d.data.baseData,
        //   extData: d.data.extData,
        //   profileData: d.data.profileData,
        // };
        const value = {
          // authUser,
          appSettings: d.data.appSettings,
          appDesign: d.data.appDesign,
          chatSettings: d.data.chat,
          disabledThanks: false,
          //  utils.getCookie('companyName').toLowerCase() === 'dns',
          auth: true,
          loading: false,
        };

        const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
        const normalizedUserInfoFields = normalize(d.data.profileData.userInfoFields, [field]);
        d.data.profileData.userInfoFields = normalizedUserInfoFields;
        const normalizedUserHeadFields = normalize(d.data.profileData.userHeadFields, [field]);
        d.data.profileData.userHeadFields = normalizedUserHeadFields;

        this.props.setAuthUser(d.data.baseData.id);
        this.props.changeUser(d.data.baseData.id, {
          baseData: d.data.baseData,
          extData: d.data.extData,
          profileData: d.data.profileData,
        });
        this.props.changeVm('', value);
        const integatedMode = utils.getCookie('integrationMode');
        if (window.self !== window.top && integatedMode === integrationModes.sharepoint) {
          timer = setInterval(() => {
            if (window.PryanikyMessage) {
              clearInterval(timer);
              window.PryanikyMessage.pushMessage({ type: 'set_base_store', store: value }, true);
              window.PryanikyMessage.pushMessage({ type: 'set_init' }, true);
            }
          }, 100);
        }
        if (callback) callback();
      }
    });
  };

  render() {
    return (
      <div>
        {
          this.props.auth &&
          this.props.widgets.map(el => {
            if(!el) return;
            store.dispatch({ type: 'ADD_WIDGET', payload: el.widget});
            return ReactDOM.createPortal(prepareWidget(el.widget), el.to);
          })
        }
      </div>
    )
  }
}

export const WIntegrate = connect<any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps({})
)(WIntegratePresenter)