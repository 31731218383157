
import { Grid } from 'muicomponents/src/Grid';
import { styled } from 'muicomponents/src/mui/system';
import { ButtonGroup } from 'muicomponents/src/ButtonGroup/ButtonGroup';
import { Box, Button } from 'muicomponents/src';

export const BoxRow = styled(Grid)(({
}) => ({
    display: 'flex',
    flexGrow: 1,
    position: 'relative'
})) as typeof Grid;

export const PositionGrid = styled(Grid)({
    ['& .Widget']: {
        minHeight: '100%'
    },
    ['& .WidgetMui']: {
        minHeight: '100%'
    }
}) as typeof Grid;

export const RowActions = styled(ButtonGroup)({
    width: '100%',
    background: '#fff'
}) as typeof ButtonGroup;

export const StyledAddWidgetButton = styled(Button)({
    zIndex: 1,
    background: '#fff',
    ['&:hover']: {
        background: '#fff',
    }
}) as typeof Button;