import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { SReplyMobileModel } from 'blocks/Replyes/Replyes.index';
import { ReactNode } from 'react';
import {
  getCommentById
} from 'News/redux/saga/selectors'
import {
  deleteReply,
  sendLikeReply,
  insertMention
} from 'News/redux/actions'

export interface IReplyProps extends IDispatchNews, IClassNameProps, ReturnType<typeof mapStateToProps>  {
  rewards?: boolean;
  avatarSize?: number;
  id: string;
  comment: SReplyMobileModel;
  newsID: string;
  fund: number;
  allowReply: boolean;
  additionalReplyActions?: (commentID: string, user: any) => ReactNode;
  setRewardComment: (id: string, count: number) => void;
  deleteRewardComment: (id: string) => void;
  onReply?: (val: string) => void;
}

export interface IReplyState {
  text?: string;
  edit: boolean;
  likedView: boolean;
}
export const mapStateToProps = (state: any, props: any) => ({
  comment: getCommentById(props.id)(state)
})

export interface IDispatchNews {
  sendLikeReply: typeof sendLikeReply;
  deleteReply: typeof deleteReply;
  insertMention: typeof insertMention
}

export const mapDispatchToProps = {
  sendLikeReply,
  deleteReply,
  insertMention,
};

export const cnReply = cn('Reply');