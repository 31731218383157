// import { Box, BoxProps } from '@material-ui/core';
import { Box } from 'muicomponents/src/Box/Box';
import { styled } from '@material-ui/core/styles';
import React, { ComponentProps } from 'react';

// const BoxRef = React.forwardRef<HTMLElement, BoxProps>((props, ref) => {
//     return <Box {...{ ...props, ref }} />
// });

export const BoxStyledNoRef = styled(Box)({
    position: 'relative',
    flex: 1,
    '&:empty': {
        display: 'none'
    }
});

export const BoxStyled = React.forwardRef<HTMLElement, ComponentProps<typeof Box>>((props, ref) => {
    return <BoxStyledNoRef {...{ ...props, ref }} />
});

export const BoxName = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 15,
    marginRight: 'auto',
    padding: '6px 15px',
    '&:empty': {
        display: 'none'
    }
});

export const BoxMenu = styled(Box)({
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 15,
    display: 'flex',
    alignItems: 'center',
    '&:empty': {
        display: 'none'
    }
});

export const BoxSliderSize = styled(Box)({
    position: 'absolute',
    top: 25,
    left: 0,
    zIndex: 15,
    marginRight: 'auto',
    padding: '6px 15px',
    '&:empty': {
        display: 'none'
    }
});