import * as React from 'react';
// import * as utils from 'utils/src/utils';
// import { withRouter } from "react-router"
import WidgetPresenter from '../Widget';
// import { withBemMod } from '@bem-react/core';
import { IWidgetTypeUserRatingProps } from './Widget_type_userRating.index';
import './Widget_type_userRating.scss';
import { BWContainer } from 'blocks/BadgeWidget/BWContainer'
// import { BWContainer } from 'blocks/BadgeWidgetNew/BWContainer'
import { widgets } from 'i.widgets';
// const type = 'album';

class WidgetTypeUserBadgesPresenter extends WidgetPresenter<IWidgetTypeUserRatingProps> {
  
  public render() {
    if (!this.props.widget) return null;
    
    // const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    if (!this.props.wcontext.uId) return <BWContainer {...this.props} widgetId={this.props.widget.id} isAdmin={this.props.authUser.baseData.isAdmin} settings={this.props.widget.settings} uid={this.props.authUser.baseData.id} tag={widgets.components.common} />
    return (
      // <div className={'Widget Widget_type_userRating'}>
      <BWContainer {...this.props} widgetId={this.props.widget.id} isAdmin={this.props.authUser.baseData.isAdmin} settings={this.props.widget.settings} uid={this.props.wcontext.uId} tag={widgets.components.common}/>
      // </div>
    );
  }
}

export default WidgetTypeUserBadgesPresenter