import React, { FC } from 'react'
import { 
    LMSTO,
    mapDispatchToProps,
    mapStateToProps,
    cnClassName 
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { Selectize } from 'uielements/src';
type IPresenterProps = LMSTO.StudentsList.UnitsFilter.IPresenterProps
type IStateProps = LMSTO.StudentsList.UnitsFilter.IStateProps
type IDispatchProps = LMSTO.StudentsList.UnitsFilter.IDispatchProps
type IOwnProps = LMSTO.StudentsList.UnitsFilter.IOwnProps
type IState = LMSTO.StudentsList.UnitsFilter.IState
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

export const Presenter: FC<IPresenterProps> = ({ className, units, currentFilters, setStudentsFilters }) => {

    const elements = units.map((unit: any) => {
        return {
            value: unit.id,
            title: unit.name,
            id: unit.id
        }
    })
    return <div className={cnClassName({}, [className])}>
        <Selectize
            onClick={(value) => { setStudentsFilters({ 'filter.completedUnits': currentFilters.includes(value.value as string) ? currentFilters.filter((val: any) => val !== value.value) : [...currentFilters, value.value] }) }}
            active={currentFilters}
            name={tT('student_list.filters.units.1')}
            elements={elements}
            multiple={true}
            children={[]} />
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
        mapStateToProps,
        mapDispatchToProps
    )(Presenter);
