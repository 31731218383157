import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { Characteristics as APICharacteristics } from 'utils/src/requests/models/api.shop'
///////////

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace Shop {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Characteristics {

        export type IPresenterProps = IOwnProps

        export interface IOwnProps extends IClassNameProps {
            children?: React.ReactNode
            characteristics: IItem[]

            showSelectedTitle?: boolean

            /**
             * использовать только для отображения
             */
            readonly?: boolean

            /**
             * состояние загрузки.
             */
            isLoading?: boolean

            /**
             * горизонтальный вариант
             */
            inline?: boolean

            /**
             * вернём массив IItem с изменёными isSelected у тегов
             */
            onChange?: (characteristics: IItem[]) => void
        }

        export type IValue = APICharacteristics.IValue

        export type IItem = APICharacteristics.IItem

        export type TItemTypes = APICharacteristics.TItemTypes
    }
}
export const cnClassName = cn('ProductCharacteristicsMui');