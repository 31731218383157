
import React from 'react';
import { cnWidget, cnWidgetMui, IWidgetProps } from '../../Widget.index';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../../Widget';
import { widgets } from "i.widgets";
import { IWidgetTypeMyTasksProps } from './Widget_type_MyTasks.index';
import { MyTasksPreseter } from 'blocks/Tasks/MyTasks/MyTasks'

const type = 'myTasks';

export default class WidgetTypeMyTasksPresenter extends WidgetPresenter<IWidgetTypeMyTasksProps> {

    public render() {
        if (!this.props.widget) return null
        const subtype = utils.widgetSubtype(this.props.widget.type);
        const Tag = widgets.components.common
        return <Tag {...this.props} className={cnWidgetMui({ type, subtype })}>
            <MyTasksPreseter  context={this.props.wcontext}/>
        </Tag>
    }
}