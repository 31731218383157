/**
 * @packageDocumentation
 * @module Widget_Settings_charts
 */
import { JFdictionary } from "../dictionary";

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.checkbox,
      label: "показывать только все изображения",
      scope: "#/properties/photoView"
    }
  ]
}