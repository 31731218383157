import { styled } from 'muicomponents/src/mui/system';
import { Box } from 'muicomponents/src/Box/Box';
import { Paper } from 'muicomponents/src/Paper';
import { List, ListItemButton } from 'muicomponents/src/ListItem/ListItem';

export const HistoryWrapper = styled(Box)({
    position: 'fixed',
    bottom: 0,
    zIndex: 1,
    width: 1170,
    padding: '16px 56px',
    boxSizing: 'border-box'
});

export const HistoryBox = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '50vh'
});

export const BoxHeader = styled('h4')({
    margin: '0 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});

export const HistoryList = styled(List)({
    overflow: 'auto'
});