import React, { useState, FC } from 'react'
import { CalendarEventLayout } from 'muicomponents/src/CalendarEventLayout/CalendarEventLayout'
import { Translate } from 'localizations/Translate';
import { addUserToArray, removeUserFromArray } from 'blocks/NewsTypes/NewsUtils'
import AccessAlarms from '@mui/icons-material/AccessAlarms';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip'
import Typography from '@mui/material/Typography'
import { Link } from 'muicomponents/src/Link/Link'
export const CommonEvent: FC<any> = ({
    event
}) => {
    return <>
        <AccessAlarms sx={{ color: `color-mix(in srgb, ${event.eventColor} 25%, white) !important`, marginRight: '8px' }} />
        <Tooltip overflowOnly title={`${event.title}`}>
            <Typography
                sx={{ fontSize: '14px', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '22px' }}
            ><Link sx={{
                // color: '#fff', 
                color: `color-mix(in srgb, ${event.eventColor} 25%, white) !important`,
                textDecoration: 'none'
            }} href={event.url}>{event.title}</Link></Typography>
        </Tooltip>
    </>
}