import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    IPresenterProps,
    ITabsType
} from './ContextMenu.index'
import './ContextMenu.scss'
import { Button, Icon } from 'uielements/src'
import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu';

export const Presenter: FC<IPresenterProps> = ({
    className,
    items
}) => {
    return <PopupMenu
        noFixed={true}
        noPadding={true}
        className={cnClassName({}, [className])}
        btnRender={(toggleOpen: any, ref: any, isOpen: boolean) =>
            <Button forwardedRef={ref} onClick={toggleOpen} className={cnClassName('ActionsToggle', { open: isOpen })} >
                <Icon icon={'ellipsis-v'} className={cnClassName('ActionsIcon')} />
            </Button>
        }
    >
        <ul className={cnClassName('ActionsList')}>
            {items.map((value, i) => <li key={i} className={cnClassName('ActionsItem')}>
                {value}
            </li>)}

        </ul>
    </PopupMenu>
}

export default memo(Presenter);
