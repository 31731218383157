import { IdeaFields, Field } from '../Fields/Types'
import { ReactNode } from 'react';
import { IdeaNews } from '../IdeaType'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';

export interface IListViewIdeasPropsType extends ReturnType<typeof mapStateToProps> {
    data: IdeaNews;
    presentRender: (child: ReactNode) => ReactNode;
}

export interface IListViewIdeasStateType {
    data: IdeaNews;
}

export const mapStateToProps = (state: any) => ({
    userData: getCurrentUser(state),
})

////////////////



