import React from 'react';
import { connect } from 'react-redux';
import { pageNames } from 'redux/pageNames';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { Page } from '../Page/Page';
import { IWelcomeProps, mapWelcomeStateToProps, mapWelcomeActionToProps } from './Welcome.index';

const WelcomePresenter: React.FC<IWelcomeProps> = ({ authUserId, context, updateContext }) => {

  React.useEffect(() => {
    if (!context.uId || context.uId === GUID_EMPTY)
      updateContext('common', {
        uId: authUserId
      });
    const layout = document.getElementById('Layout');
    layout?.classList.add('Layout_noMenu');
    return () => {
      const layout = document.getElementById('Layout');
      layout?.classList.remove('Layout_noMenu');
    };
  }, []);

  return (
    <Page page={{ name: pageNames.welcome }} />
  );
}

export const Welcome = connect(
  mapWelcomeStateToProps,
  mapWelcomeActionToProps
  )(WelcomePresenter);
