import { GraphicEditor, UsersSuggester } from 'muicomponents/src';
import { DraftInput,  } from 'muicomponents/src/DraftInput/DraftInput';
import React, { FC, useMemo } from 'react';
import { CardWrapper, EditorFrame } from './Card.styled';
import { Translate } from 'localizations/Translate';
import { union } from 'lodash';
import { useCard } from './Card.hooks';
import { LinearProgressWithLabel } from 'muicomponents/src/LinearProgress';
import { LoadingButton } from 'muicomponents/src/LoadingButton/LoadingButton';
import { CanvaEditor } from './Editor/Editor';
import { useSelector } from 'react-redux';
import { moduleIsEnabled } from 'utils/src/CommonRedux/base/selectors';

const rusSusets = ["cyrillic", "cyrillic-ext"];

/**
 * send card to user block
 * @param param0 
 * @returns 
 */
export const Card: FC<{}> = ({

}) => {

    const {
        users,
        setUsers,
        draftState,
        setDraftState,
        newUsers,
        file,
        changeFile,
        fileProgress,
        isFileLoading,
        send,
        sending,
        frameId,
        editorRef
    } = useCard();

    const cardsIsEnabled = useSelector(moduleIsEnabled('postcards'))

    return (
        <CardWrapper>
            <UsersSuggester
                multiple
                value={users as any}
                onChange={( event, value, reason, details ) => {
                    if(reason === 'createOption') {
                        newUsers.current = union([ ...newUsers.current, details?.option.id || '' ]);
                    }
                    if(Array.isArray(value)) setUsers(value);
                }}
                createItems={{
                    allow: true
                }}
                TextFieldProps={{
                    label: <Translate i18nKey={'pryaniky.card.users.title'} />
                }}
            />
            {
                fileProgress > 0 &&
                <LinearProgressWithLabel value={fileProgress} variant="determinate" />
            }
            <GraphicEditor
                id={frameId}
                ref={editorRef}
                singleTemplate
                avaliableElements={[]}
                fixed
                mode='cards'
                textSubsets={rusSusets}
                onSaveImage={(file) => {
                    if(file instanceof File) {
                        changeFile(file);
                    }
                }}
            />
            <DraftInput
                label={<Translate i18nKey='pryaniky.card.text' />}
                value={draftState}
                onChange={setDraftState}
            />
            <LoadingButton
                fullWidth
                variant={'contained'}
                loading={sending}
                onClick={() => send()}
            >
                <Translate i18nKey={'pryaniky.card.send'} />
            </LoadingButton>
        </CardWrapper>
    );
};