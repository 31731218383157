import { IEditorProps } from './Editor.index';
import * as React from 'react';
import './Editor.scss';
import { Icon } from 'uielements/src/Icon/Icon';
import { TextareaMentionsEditor as TextareaMentions } from '../../../TextareaMentions/TextareaMentions';
import { } from '../Polls.types';
import { } from '../Polls.sides';
import { Input } from 'uielements/src/Input/Input';
import { CheckboxInput } from 'uielements/src';
import { Button } from 'uielements/src/Button/Button';
import i18n from '../../../../localizations/i18n';
import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'
import { DateTimeInput } from 'blocks/PryanikyForms/DateTimeInput/DateTimeInput'
import { Translate } from 'localizations/Translate';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

export const Editor: React.FC<IEditorProps> = ({
  children,
  data,
  onChangeText,
  addNewAnswer,
  changeAnswerText,
  onChangeFreeAnswers,
  onChangeSecondAnswers,
  onChangeShowResultsBeforeVote,
  onChangeShowParticipants,
  onChangeDisableRevote,
  removeAnswer,
  expiresChange
}) => {
  return (
    <div className={'Editor EditorPolls'}>
      <PryanikyEditor
        // getClassRef={refGetter}
        //ref={forwardRef as any}
        // users={true}
        // tags={true}
        onChange={onChangeText}
        // className={''}
        // icon={'edit'}
        placeholder={i18n.t('pryaniky.createPost.poll.text')}
        value={data.text}
        disableToolbar={true}
      />
      <DateTimeInput
        className={'EditorPolls-Datepicker'}
        value={data.poll.expires}
        minDate={new Date()}
        showTimeInput
        onChange={expiresChange}
        icon={(onClick) => <Button {...{ onClick }}><Icon icon='calendar' /></Button>}
      />
      {data.poll.answers.map((answer: any, i: number) => {
        const icon = (index: number) => <span className="Input-Icon">{index + 1}.</span>;
        return (
          <Input
            key={i}
            placeholder={i18n.t('pryaniky.createPost.poll.answer')}
            className={'EditorPolls-AnswerInput NewsEdit-Body_type_polls-Poll-Answer-Input'}
            onChange={(e: any) => changeAnswerText(i, e)}
            icon={icon(i)}
            value={answer.text}>
            <Button
              className={'Button_removeAnswer'}
              onClick={() => removeAnswer(i)}
              theme={'unstyled'}
              children={<Icon icon="times" />}
            />
          </Input>
        );
      })}
      <Button
        className={'EditorPolls-AddNewAnswer'}
        onClick={addNewAnswer}
        theme={'unstyled'}
        children={
          <React.Fragment>
            <Icon icon="plus" />
            {i18n.t('pryaniky.createPost.poll.addAnswer')}
          </React.Fragment>
        }
      />
      <div className={'EditorPolls-Checkboxes'}>
        <CheckboxInput
          disabled={data.poll.maxCount !== 0}
          onChange={onChangeSecondAnswers}
          checked={data.poll.answerVariants === 100}
          padding="0"
        >
          <Translate i18nKey='pryaniky.createPost.poll.multipleAnswers' />
          <Tooltip  placement="right" title={i18n.t('pryaniky.createPost.poll.multipleAnswers.help')}>
            <span><Icon icon="question-circle"></Icon></span>
          </Tooltip>
        </CheckboxInput>

        <CheckboxInput
          onChange={onChangeFreeAnswers}
          checked={data.poll.freeAnswer}
          padding="0"
        // text={i18n.t('pryaniky.createPost.poll.freeAnswers')}
        >
          <Translate i18nKey='pryaniky.createPost.poll.freeAnswers' />
          <Tooltip  placement="right" title={i18n.t('pryaniky.createPost.poll.freeAnswers.help')}>
            <span><Icon icon="question-circle"></Icon></span>
          </Tooltip>
        </CheckboxInput>

        <CheckboxInput
          onChange={onChangeDisableRevote}
          checked={data.poll.disableRevote}
          padding="0"
        >
          <Translate i18nKey='pryaniky.createPost.poll.disableRevote' />
          <Tooltip  placement="right" title={i18n.t('pryaniky.createPost.poll.disableRevote.help')}>
            <span> <Icon icon="question-circle"></Icon></span>
          </Tooltip>
        </CheckboxInput>

        <CheckboxInput
          onChange={onChangeShowResultsBeforeVote}
          // checked={data.poll.showResultsBeforeVote === false}
          checked={data.poll.showResultsBeforeVote}
          padding="0"
        >
          <Translate i18nKey='pryaniky.createPost.poll.ShowResultsBeforeVote' />
          <Tooltip  placement="right" title={i18n.t('pryaniky.createPost.poll.ShowResultsBeforeVote.help')}>
            <span><Icon icon="question-circle"></Icon></span>
          </Tooltip>
        </CheckboxInput>


        <CheckboxInput
          onChange={onChangeShowParticipants}
          checked={data.poll.showParticipants}
          padding="0"
        >
          <Translate i18nKey='pryaniky.createPost.poll.isAnon' />
          <Tooltip  placement="right" title={i18n.t('pryaniky.createPost.poll.isAnon.help')}>
            <span><Icon icon="question-circle"></Icon></span>
          </Tooltip>
        </CheckboxInput>


        {/*<CheckboxInput
            // className={this.state.enableExpires ? 'enabledExpires' : ''} 
            // onChange={this.onChangeCheckBox.bind(this, 'enableExpires')} 
            checked={false}
        text={'enableExpires'} />*/}


        {/*
            data.poll.expires &&
            <Datepicker
                onChange={expiresChange}
                  // className={this.state.enableExpires ? 'enabledExpires' : ''}
                  locale={i18n.language}
                  value={post.poll.expires} />
        */}
      </div>
      {children}
    </div>
  );
};
