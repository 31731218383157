import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IInputMentionProps extends IClassNameProps {
  values?: any[];
  excludeMe: boolean;
  tag?: 'div';
  variable?: string;
  parent?: React.Component;
  icon?: React.ComponentClass | string;
  placeholder?: string;
  value?: { [s: string]: any };
  noMulti?: boolean;
  type: 'users' | 'tags' | 'ratings' | 'news' | 'groups' | 'calendars' | 'pagecollections';
  menuPosition?: 'top';
  onChange?(values: any): void;
  onClick?(event: React.ChangeEvent<any>): void;
  additionalRequestOpts?: { [s: string]: string };
  invalid?: boolean;
  errorMessage?: string;
  required?: boolean;
}

export interface IInputMentionState {
  text?: string;
  valuesOnLoading: boolean;
  options: any[];
  values: any[];
}

export const cnInputMention = cn('InputMention');