// import { Box, BoxProps, Grid, styled } from '@material-ui/core';
import React, { ComponentProps, FC, forwardRef } from 'react';
import { CustomSettings } from 'utils/src';
import ReactSortable from 'react-sortablejs';
import { WithEditProps } from './WidgetLayoutHorizontal.index';
import { Box, Button } from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';

export const gapRows = CustomSettings.baseGapBetweenWidgets() || 24;

export const gapColumns = CustomSettings.baseGapBetweenColumns() || 2;

export const SortableLayout = styled(ReactSortable)({
    display: 'flex',
}) as typeof ReactSortable;

/**
 * styles to rows array
 */
export const BoxLayout = styled('div')(({
}) => ({
    display: 'flex',
    flexDirection: 'column',
    '& > .Widget': {
        margin: 0
    },
    '& > .WidgetMui': {
        margin: 0
    }
}));

export const BoxRowGrid = styled('div')(({
}) => ({
    padding: 0,
    margin: '12px 0',
    '&:first-child': {
        marginTop: 0
    },
    '&:last-child': {
        marginBottom: 0
    },
}));