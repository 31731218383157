import { ManifestType } from '../types'
import img from './university.svg'

export const manifest: ManifestType = {
    id: 'courses',
    name: 'courses',
    title: 'Курсы',
    imgUrl: img,
}

export default manifest