import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnReplyForm, IReplyFormProps, IReplyFormState } from './Replyes-ReplyForm.index';
import { SReplyMobileModel } from '../Replyes.index';
import './Replyes-ReplyForm.scss';
import { Button } from 'uielements/src/Button/Button';
import uuid from 'uuid/v1';
import { toast } from 'react-toastify';
import { Icon } from 'uielements/src/Icon/Icon';
import { ReplyesContext } from '../Replyes.context';
import { defaultThanksInfo } from '../../NewsTypes/Thanks/Editor/containers/reply/reply.types'
export class ReplyForm extends React.PureComponent<IReplyFormProps, IReplyFormState> {
  public static contextType = ReplyesContext;
  public context!: React.ContextType<typeof ReplyesContext>;

  public dataToServer: any;
  public dropzone: any;
  public el: HTMLElement | null;
  public textareaMentions: HTMLInputElement;

  constructor(props: IReplyFormProps) {
    super(props);
    this.state = {
      text: '',
      open: false,
      isValidData: false,
      attachments: [],
      thanksInfo: { ...defaultThanksInfo, users: props.thankUsers.map(el => el.value || el.id) },
      selectedUsers: props.thankUsers.map(v => {
        v.userPhotoUrl = v.imgUrl;
        return v;
      })
    };
  }

  public componentDidMount() {
    this.context.appendFoo = (str: string) => {

      this.setState((s: IReplyFormState) => {

        s.text += str;

        return s;
      })
    };
  }
  public componentWillUnmount() {
    if (this.textareaMentions) {
      this.textareaMentions.removeEventListener('focus', this.inputFocus);
      this.textareaMentions.removeEventListener('blur', this.inputBlur);
    }
  }

  public render() {
    const { className } = this.props;
    return (
      <form ref={(el: any) => this.el = el} className={cnReplyForm({}, [className])} onSubmit={this.sendNewReply}>
        {this.props.isThank && <div className={cnReplyForm('Thanks')}>
          <Button theme={'unstyled'} className={cnReplyForm('CloseThank')} onClick={this.props.toggleThank}><Icon icon='times' /></Button>
        </div>}
      </form>
    )
  }

  private inputFocus = () => {
    this.setState({ open: true });
  }

  private inputBlur = (e: any) => {
    if (!this.el) return;
    if (this.state.text.length === 0 && !(this.el && this.el.contains(e.relatedTarget))) this.setState({ open: false });
  }

  private sendNewReply = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.text.length === 0) return;

    const data: SReplyMobileModel = {
      id: uuid(),
      text: this.state.text,
      header: '',
      liked: false,
      date: new Date(),
      changeDate: new Date(),
      actions: [],
      likeCount: 0,
      parentCommentId: null,
      user: this.props.data.user,
      attachments: this.state.attachments,
      type: 'default'
    }
    if (this.props.isThank) {
      data.type = 'thank'
      data.thanksInfo = this.state.thanksInfo as any;
    } else this.props.newComment(data);

    this.setState({ text: '', attachments: [], open: false })

    utils.API.reply.post(this.props.data.newsID, data)
      .r
      .then((d: any) => {
        if (d && d.error_code === 0) {
          if (this.props.isThank) {
            this.props.toggleThank();
            this.props.newComment(d.data);
          }
        }
        else toast.error(d.error_text);
      });
  }
}