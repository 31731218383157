import React, { FC, useEffect, memo, useCallback, useMemo, useState } from 'react'
import {
    cnClassName,
    News
} from './interfaces'
import { useDispatch, useSelector } from 'react-redux';
import './style.scss'
import { Button, Avatar } from 'uielements/src'
import withConfirmDialog from 'blocks/HOCs/Button/withConfirmDialog'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { joinUserToGroup } from 'redux/actions/Groups'
// import { joinUserToGroup } from 'redux/actionsTypes/Groups'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
import { useIsLoading, useIsError } from 'utils/src/CommonRedux/LoadIndicator/utils/hooks'
import { UserList } from 'uielements/src/UserList/UserList';
import { decodeEntities } from 'utils/src/utils'

type IPresenterProps = News.NewsBannerTypeGroup.IPresenterProps


const ConfirmButton = withConfirmDialog(withLoadSpin(Button))

const useJoinCurrentUserToGroup = (groupId: string) => {
    const [isSended, setSended] = useState(false)
    const currentUser = useSelector(getCurrentUser)
    const dispatch = useDispatch()
    const action = useMemo(() => joinUserToGroup(groupId, currentUser.baseData.id, true, `/group/${groupId}`), [groupId, currentUser.baseData.id])
    const pid = `${action.type}_${groupId}_${currentUser.baseData.id}`
    const isLoading = useIsLoading([pid])
    const { errors, isFailed } = useIsError([pid])

    const isComplete = useMemo(() => (!isLoading && !isFailed && isSended), [isLoading, isFailed, isSended])

    const callback = useCallback(() => {
        setSended(true)
        dispatch(action)
    }, [action])

    return { isLoading, isFailed, isComplete, errors, callback }
}


export const Presenter: FC<IPresenterProps> = ({
    banner
}) => {
    const { isLoading, isComplete, callback } = useJoinCurrentUserToGroup(banner.objectId)

    return <div className={cnClassName({}, [])}>
        <div className={cnClassName('AvatarBox')}>
            <Avatar className={cnClassName('Img')} size={140} imgUrl={banner.imgUrl} name={banner.title || ''} />
        </div>

        <div className={cnClassName('Content')}>
            <Button theme={'unstyled'} type={'rlink'} className={cnClassName('Title')} href={`/group/${banner.objectId}`}>{decodeEntities(banner.title || '')}</Button>


            <div className={cnClassName('Text')}>
                {decodeEntities(banner.text || '')}
            </div>
        </div>

        <div className={cnClassName('Bottom')}>
            <div className={cnClassName('UserListBox')}>
                <UserList
                    className={cnClassName('UserList')}
                    cut={false}
                    size={40}
                    autoHide={true}
                    underNext={true}
                    action={banner.participantsCount > (banner.participants || []).length}
                    actionHref={`/group/${banner.objectId}/colleagues`}
                    actionBtnContent={<span className="AnswersValue">+{banner.participantsCount}</span>}
                    data={banner.participants || []}
                    actionCallback={() => { }}
                />
            </div>
            {!isComplete && <ConfirmButton noBorder noBackground isLoading={isLoading} className={cnClassName('Action', ['primaryColor3-text'])} onConfirm={callback}>{banner.buttonText}</ConfirmButton>}
        </div>
    </div>
}

export const Banner = memo(Presenter);

export default Banner