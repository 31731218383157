import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { setModerationFilters } from '../../../../redux/actions/LMSTeacherOffice'

export interface IQuestionsProps extends IClassNameProps, IQuestionsStateProps, IDispatchProps, RouteComponentProps, IQuestionsDispatchProps {
    tag?: 'div';
}

export interface IQuestionsState {

}

export interface IQuestionsStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    // questionsIds: Object.keys(state.LMSTO.moderation.questions),
    // questions: state.LMSTO.moderation.questions,
    // currentFilters: state.LMSTO.moderation.filters['filter.filterByQuestionIds']
    // units: state.LMSTO.moderation.units,
    // ids: state.LMSTO.moderation.results
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    // setModerationFilters
}, dispatch);

export type IQuestionsDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnQuestions = cn('QuestionsFilter');