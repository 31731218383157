import React from 'react';
import { cnBannerTypeClassName, IBannerType } from './TypeBanner.index';
import MDRender from 'uielements/src/CommonmarkRender'
import { generateLinkData } from 'utils/src/utils';
import { Button } from 'uielements/src';
import './TypeBanner.scss'

const BannerTypePresenter: React.FC<IBannerType.NewsBannerTypeBanner.IBannerPresenterProps> = ({
  banner
}) => {
  const { href, target, type } = generateLinkData(banner.url || '');

  return (
    <Button noBorder noBackground target={target} type={type} href={href} className={cnBannerTypeClassName()} style={{
      backgroundImage: banner.imgUrl ? `url("${banner.imgUrl}")` : undefined
    }}>
      <MDRender source={banner.text || ''} />
    </Button>
  )
}

export const BannerType = React.memo(BannerTypePresenter);

export default BannerType