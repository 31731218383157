import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IDialogActionsPresenterProps = IDialogActionsOwnProps

export interface IDialogActionsState { }

export interface IDialogActionsOwnProps extends IClassNameProps {
    id?: string;
    onClose?: () => void;
    onAccept?: () => void;
    disableAccept?: boolean;
}

export const cnClassName = cn('ModalDialogActions');