/**
 * @packageDocumentation
 * @module Widget_Settings_ratingmini
 */
import { JFdictionary } from "../dictionary";
import i18n from "localizations/i18n";

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    rating: {
      type: types.object,
      placeholder: i18n.t('pryaniky.widgets.settings.placeholder.rating'),
      require: true
    },
    count: {
      type: types.number,
      min: 1,
      placeholder: i18n.t('pryaniky.widgets.settings.placeholder.usersCountLoad'),
    },
    type: {
      type: types.string,
      placeholder: i18n.t('pryaniky.widgets.settings.placeholder.type'),
      opts: [
        {
          title: i18n.t('pryaniky.widgets.settings.ratingmini.type.stroke'),
          value: 'stroke'
        },
        {
          title: i18n.t('pryaniky.widgets.settings.ratingmini.type.rounded'),
          value: 'rounded'
        }
      ]
    },
    miniview:{
      type: types.boolean
    }
  }
}
