import { ReactNode } from "react";
import { cn } from '@bem-react/classname';

export interface IStageBarProps {
    stage: ReactNode;
    date: ReactNode;
    controls: ReactNode;
    children?: ReactNode;
    inRepost?: boolean
}

export const cnStageBar = cn('StageBar');