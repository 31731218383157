import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { SearchInput } from 'uielements/src';
import { Button, Avatar } from 'uielements/src';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

type IPresenterProps = StatisticsFull.UsersListItem.IPresenterProps
type IOwnProps = StatisticsFull.UsersListItem.IOwnProps
type IDispatchProps = StatisticsFull.UsersListItem.IDispatchProps
type IStateProps = StatisticsFull.UsersListItem.IStateProps
type IState = StatisticsFull.UsersListItem.IState

const fieldRender = (field: any) => {
    if (field.field.fieldType === 10) {
        return field.tags.map((tag: any) => tag.displayName).join(', ')
    }
    return field.value || '-'
}

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const phoneField = {
    field: {
        additionalParam1: null,
        answers: [],
        category: "",
        defaultValue: "",
        description: "",
        disableExpression: "",
        displayMode: "",
        fieldType: 1,
        id: "phone",
        isHidden: false,
        isMandatory: false,
        isReadOnly: false,
        linkedCollection: null,
        linkedFieldStatus: null,
        mask: "",
        maxLength: 1024,
        name: "phone",
        position: 4,
        propertyName: "phone",
        rows: [],
        selectOnlyFromCollection: true,
        showInHead: true,
        showInUserList: true,
        showOnIdeaCardView: false,
        visibility: 0
    },
    fieldId: "phone",
    files: null,
    rowValues: {},
    tags: [],
    users: null,
    value: ""
}
const emailField = {
    field: {
        additionalParam1: null,
        answers: [],
        category: "",
        defaultValue: "",
        description: "",
        disableExpression: "",
        displayMode: "",
        fieldType: 1,
        id: "email",
        isHidden: false,
        isMandatory: false,
        isReadOnly: false,
        linkedCollection: null,
        linkedFieldStatus: null,
        mask: "",
        maxLength: 1024,
        name: "email",
        position: 4,
        propertyName: "email",
        rows: [],
        selectOnlyFromCollection: true,
        showInHead: true,
        showInUserList: true,
        showOnIdeaCardView: false,
        visibility: 0
    },
    fieldId: "email",
    files: null,
    rowValues: {},
    tags: [],
    users: null,
    value: ""
}
export const Presenter: FC<IPresenterProps> = ({
    className,
    complete,
    failed,
    inProgress,
    total,
    trainingLevel,
    user,
    userListFields = [],
    showMail,
    showPhone,
    selectedFields = []
}) => {
    const classes = useRowStyles();
    useEffect(() => {

    }, [])
    emailField.value = user.eMail
    phoneField.value = user.phone
    return <TableRow className={classes.root}>
        <TableCell>
            <Avatar className={cnClassName('Avatar')} size={56} name={user.displayName} imgId={user.imgId} imgUrl={user.imgUrl} />
        </TableCell>
        <TableCell>
            <div><UserMention className={cnClassName('Mention')} id={user.id} name={user.displayName} url={`/TeacherOffice/statistics/user/${user.id}`} /></div>
            <div>{user.position}</div>
            <div>{user.division}</div>
        </TableCell>
        <TableCell>
            {trainingLevel}%
        </TableCell>
        <TableCell>
            {total}
        </TableCell>
        <TableCell>{inProgress}</TableCell>
        <TableCell>{complete}</TableCell>
        <TableCell>{failed}</TableCell>
        {/* {showMail && <TableCell>{user.eMail}</TableCell>} */}
        {/* {showPhone && <TableCell>{user.phone}</TableCell>} */}
        {selectedFields?.map((field: any) => {
            const dataField = [...userListFields, emailField, phoneField].find((v: any) => v.fieldId === field.id)
            if (!dataField) return null
            return <TableCell
                key={field.id}
            >
                {fieldRender(dataField)}
            </TableCell>
        })}
    </TableRow>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));
