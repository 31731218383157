import {
    takeEvery,
    takeLeading,
    takeLatest,
    throttle,
    cancel,
    fork
} from 'redux-saga/effects';
import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';

import { routerActions, getLocation } from 'connected-react-router';

import {
    API,
} from 'utils/src/utils'

import {
    getCourseById,
    getCourseUnitIndexById,
    getUnitLogByUnitId,
    getCourseUnits,
    getSessionLogBySessionId,
    getLastViewedUnitId,
    getCourseUnitById,
    getSaveInProcessing,
    getFirstUnstartedSession,
    getUnstartedSessions,
    getCompletedSessions,
    getUnitContentById,
    getUnitQuestionById,
    getCompletedUnits,
    getNotCompletedUnits,
    getCourseLastUnit,
    getCurrentSessionLog,
    getSelectedAnswersIds,
    getAnswerById,
    getAnswersMap,
    getCurrentQuestionId,
    getNextQuestionId,
    getSkipedQuestionIds,
    getCompletedUnitQuestions,
    getQuestionIds,
    getNextNotCompletedQuestionId,
    getNotCompletedMondatoryUnits
} from '../LMS/selectors'

import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

import actionsLMS from '../../actionsTypes/LMS';
import actionsCOMMON from '../../actionsTypes/COMMON';

import {

    setUnitLogs,
    setSessionsLogs,

    loadCourseActionType,
    readCourse,
    _saveUnitResult,
    updateUnitLog,
} from '../../actions/LMS'

import {
    loadUnitsLogsActionType,
    loadUnitsLogs,
    setFullPlayedCourseActionType,
    loadAllScormParamsActionType
} from '../../actions/COMMON'

import {
    buildFlatUnitsLogs,
    buildFlatCourseSessions,
} from '../LMS/utils'

import uuid from 'uuid';

import {
    coursesSchema,
    denormalizeData,
    normalizeData,
    singleCourseSchema,
    testResultShema,
    testSchema,
    unitsLogsSchema
} from 'LMSModule/utils/normalize';

import queryString from 'query-string';


const zeroId = "00000000-0000-0000-0000-000000000000";

/**
 * загружает логи для юнитов в указаной сессии
 * @param action 
 */
export const handleLoadUnitsLogs = function* handleLoadUnitsLogs(action: loadUnitsLogsActionType) {
    try {
        const { sid } = action.payload
        if (sid === zeroId) {
            console.warn(`handleLoadUnitsLogs, err: session id`, sid)
            yield;
            return 0;
        }

        yield* put({ type: actionsLMS.START_LOAD_UNITS_LOGS })

        const request = yield* call(API.lms.getSession, sid)
        // @ts-ignore
        const result = yield request.r

        if (result && result.error_code !== 0) {
            switch (result.error_code) {
                default: {
                    // toast.error('getSession, unknown error:', result.error_code)
                    console.warn(`getSession, err: ${result.error_code}, server text:`, result.error_text)
                }
            }
        } else if (result && result.error_code === 0) {
            const { ids, unitsLogsMap } = buildFlatUnitsLogs(result.data.units)
            yield* put(setSessionsLogs({ [sid]: result.data.session }))
            yield* put(setUnitLogs(unitsLogsMap))
        } else {
            // toast.error('getSession, internal error');
            console.warn(`getSession, internal error`)
        }
        yield* put({ type: actionsLMS.COMPLETE_LOAD_UNITS_LOGS })
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * получает курс по ид
 * @param action 
 */
export const handleSetFullPlayedCourse = function* handleSetFullPlayedCourse(action: setFullPlayedCourseActionType) {
    try {
        const { courseId, serverModel } = action.payload

        const norm = buildFlatCourseSessions(serverModel)
        let sessions: any = {};
        norm.full = true
        sessions = { ...norm.normalSessions }
        const courseSessionId = norm.courseSessionId

        yield* put(readCourse({ ...norm, sessions }))

        if (courseSessionId !== zeroId) yield* call(handleLoadUnitsLogs, loadUnitsLogs(courseSessionId))
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * получение всех параметров скорма
 * @param action 
 */
export const handleLoadAllScormParams = function* handleLoadAllScormParams(action: loadAllScormParamsActionType) {
    try {
        const { unitId, cid, sid, logId } = action.payload
        let sessionLogId = logId
        if (!sessionLogId) {
            const sessionLog = yield* select(sid ? getSessionLogBySessionId(sid) : getCurrentSessionLog(cid))
            sessionLogId = sessionLog.log.id
        }

        const unitLog = yield* select(getUnitLogByUnitId(unitId))

        const request = yield* call(API.lms.getAllScormParams, sessionLogId as string, unitId)
        // @ts-ignore
        const result = yield request.r

        if (result && result.error_code !== 0) {
            switch (result.error_code) {
                default: {
                    // toast.error('loadCourse, unknown error:', d.error_code)
                    console.warn(`handleLoadAllScormParams, err: ${result.error_code}, server text:`, result.error_text)
                }
            }
        } else if (result && result.error_code === 0 && result.data) {
            const data = { ...unitLog, scormCMI: result.data }

            // отпраавляем изменения в редакс
            yield* put(updateUnitLog(unitId, data))
        } else {
            console.warn(`handleLoadAllScormParams, internal error`, result)
        }

    } catch (error) {
        console.warn(error)
    }
}

/**
 * module root saga
 */
const root = function* root() {
    yield takeLeading(actionsCOMMON.LOAD_UNITS_LOGS, handleLoadUnitsLogs)
    yield takeLeading(actionsCOMMON.LOAD_ALL_SCORM_PARAMS, handleLoadAllScormParams)
    yield takeLeading(actionsCOMMON.SET_FULL_PLAYED_COURSE, handleSetFullPlayedCourse)
};

/**
 * export root saga
 */
export default root;