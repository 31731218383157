import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'
import { i18n } from "localization";
import "./HeaderLeaderboard.scss";
import { useScrollListState, useDebouncedQueryState } from 'utils/src/hooks'
import { IHeaderLeaderboardProps, mapDispatchToProps, mapStateToProps, cnHeaderLeaderboard } from './HeaderLeaderboard.index'
import { withRouter } from 'react-router-dom'
import * as utils from "utils/src/utils";
import { styled } from '@mui/material/styles';
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// import {} from ''
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Button } from 'muicomponents/src/Button/Button'
import { Typography } from "muicomponents/src/Typography/Typography"
import { Avatar } from 'muicomponents/src/Avatar/Avatar';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { IconButton } from 'muicomponents/src/IconButton/IconButton'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { Popover } from 'muicomponents/src/Popover';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle/DialogTitle';
import { RadioGroupMui } from 'muicomponents/src/RadioGroup/RadioGroup';
import { FormControl } from 'muicomponents/src/FormControl/FormControl';
import FormLabel from '@mui/material/FormLabel';
import widgetsActions from 'redux/actionsTypes/Widgets';
import { useDispatch } from "react-redux";

export const HeaderLeaderboardPresenter: React.FC<IHeaderLeaderboardProps> = ({ widgetId, changeOrder, orderValueHeader, changeSearch, searchValueHeader, ratingValueToHeader, curUser, ...props }) => {

    const [filters, setFilters] = useState<any>([])
    const [categories, setCategories] = useState<any>([])
    const [popoverAnchor, setPopoverAnchor] = useState<{ top: number; left: number } | null>(null);


    const dispatch = useDispatch();



    // ----------------------функции для работы с поповером Popover ----------------------------->

    const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setCategories({
            // isClosed: d.isShopClosed,
            data: ratingValueToHeader ? ratingValueToHeader.filters.map((el: any) =>
            ({
                name: el.name, groups: el.groups.map((group: any) =>
                    ({ id: group.pkid, title: group.name, type: 'groupPKID', value: group.pkid }))
            })) : []
        })
        const rect = event.currentTarget.getBoundingClientRect();
        setPopoverAnchor({ top: rect.top + (rect.height / 2), left: rect.left + (rect.width / 2) });
    };

    const closePopover = () => {
        setPopoverAnchor(null);
    }


    // ---------------------- конец функций для работы с поповером Popover -----------------------------> 

    return (
        <Paper
            className={cnHeaderLeaderboard("")}
            sx={{ padding: '24px' }}
        >
            {ratingValueToHeader && <Box className={cnHeaderLeaderboard("InfoBox")} sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                <Avatar className={cnHeaderLeaderboard("InfoBox-RatingAvatar")} sx={{ width: 64, height: 64, marginRight: "16px" }} variant='circular' src={ratingValueToHeader.imgUrl} />
                <Box className={cnHeaderLeaderboard("InfoBox-RatingInformation")}>
                    <Typography className={cnHeaderLeaderboard("InfoBox-RatingInformation-Name")} sx={{ fontWeight: "500" }} variant='h6'>{ratingValueToHeader.name}</Typography>
                    <Typography className={cnHeaderLeaderboard("InfoBox-RatingInformation-Desc")} variant='body1'>{ratingValueToHeader.desc}</Typography>
                </Box>
            </Box>}

            {<Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                <SearchInput

                    label={i18n.t('search')}
                    value={searchValueHeader}
                    onChange={changeSearch}
                />
                {ratingValueToHeader?.filters.length > 0 && <IconButton className={cnHeaderLeaderboard("Filter")} sx={{ marginLeft: "16px" }} onClick={(event: React.MouseEvent<HTMLButtonElement>) => openPopover(event)} size="small">
                    <FilterAltIcon fontSize='large' color="primary" />
                </IconButton>}
                <Popover

                    className={cnHeaderLeaderboard("FilterPopover")}
                    // BackdropComponent={Fragment}
                    open={popoverAnchor ? true : false}
                    anchorReference="anchorPosition"
                    anchorPosition={popoverAnchor || { top: 0, left: 0 }}
                    onClose={closePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        sx: {
                            minWidth: 0,
                            display: 'flex',
                            flexDirection: 'column'
                        }
                    }}
                >

                    <Box className={cnHeaderLeaderboard("FilterPopover")}>
                        <DialogTitle onClose={() => closePopover()}>
                            {i18n.t("pryaniky.modal.leaderbord.filter.settings")}
                        </DialogTitle>
                        <Box sx={{ width: "600px", padding: "0 24px 16px 24px" }} className={cnHeaderLeaderboard("FilterPopover-Content")}>
                            {ratingValueToHeader?.filters && categories?.data?.map((el: any) => <FormControl
                                sx={{ marginBottom: "16px" }}>
                                {el?.name && <Tooltip
                                    title={el?.name.length > 46 ? el.name : ""}
                                >
                                    <Box sx={{ display: "flex" }}>
                                        <FormLabel
                                            id="demo-controlled-radio-buttons-group"
                                            sx={{
                                                width: "515px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}
                                            className={cnHeaderLeaderboard("FilterPopover-Content-Label")}
                                        >
                                            {el.name + ":"}
                                        </FormLabel>
                                        {el?.name.length > 46 && <Typography>  {el?.name && ":"}</Typography>}
                                    </Box>
                                </Tooltip>}

                                <RadioGroupMui
                                    className={cnHeaderLeaderboard("FilterPopover-Content-Filters")}
                                    items={el.groups || []}
                                    tooltipShow={46}
                                    cut={5}
                                    sx={{
                                        ".MuiFormControlLabel-root .MuiTypography-root ": {
                                            width: "515px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }
                                    }}
                                    size='small'
                                    onChange={(e, selectedEl: any) => {
                                        setFilters({ ...filters, gid: selectedEl })
                                    }}
                                    value={filters.gid || []}

                                />

                            </FormControl>
                            )}

                        </Box>

                        <Box sx={{
                            margin: "0 24px 16px 24px",
                            display: "flex",
                            justifyContent: "left"
                        }} className={cnHeaderLeaderboard("FilterPopover-Content-ActionButtons")}>
                            <Button
                                className={cnHeaderLeaderboard("CleanFilter")}
                                variant={'outlined'}
                                color={'primary'}
                                sx={{ marginRight: "5px" }}
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        gid: "",
                                    })
                                }}
                            >
                                <CleaningServicesIcon color={'primary'} />
                                {i18n.t("pryaniky.modal.leaderbord.filter.clean")}
                            </Button>
                            <Button
                                className={cnHeaderLeaderboard("CleanFilter")}
                                variant={'contained'}
                                disabled={(props as any).context.gid === filters.gid}
                                color={'primary'}
                                onClick={() => {
                                    dispatch({
                                        type: widgetsActions.UPDATE_CONTEXTS, payload: {
                                            [widgetId]: filters
                                        }

                                    });
                                    closePopover();
                                }}
                            >

                                {i18n.t("pryaniky.modal.leaderbord.filter.apply")}
                            </Button>
                        </Box>
                    </Box>
                </Popover>
            </Box>}

            <Button sx={{
                marginTop: '16px',
                fontWeight: "600",
                textTransform: "inherit",
                color: "#000000DE"
            }}
                className={cnHeaderLeaderboard("LeaderboardRatingsSort")}
                variant="text"
                onClick={() => changeOrder(!orderValueHeader)}>
                {orderValueHeader ? i18n.t('pryaniky.list.ratings.order.sortAsc') : i18n.t('pryaniky.list.ratings.order.sortDesc')}
                {orderValueHeader ? <ArrowUpwardIcon fontSize='small' sx={{ color: "#0000008A" }} /> : <ArrowDownwardIcon fontSize='small' sx={{ color: "#0000008A" }} />}
            </Button>
        </Paper>



    )
}

export const HeaderLeaderboard = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderLeaderboardPresenter)