import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    loadNewsList,
    loadWorkflowStatuses,
    reset,
    pathNews
} from '../../redux/actions'
import { getUserStatuses, changeStatus, getStatusFields } from 'blocks/NewsTypes/Workflows/redux/actions';
import {
    TViewType
} from '../../redux/reducer/types'
import {
    getNewsKeys,
    getNewsValue,
    getNewsParams,
    getWorkflowStatuses
} from '../../redux/saga/selectors'
import { INewsListRequestOpts } from 'utils/src/requests/models/api.news'
import queryString from 'query-string';

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace Kanban {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace KanbanTimeline {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps<{ id: string }>

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            params: INewsListRequestOpts
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

        export type IParams = { type: string };
    }
}
////////////
type IOwnProps = Kanban.KanbanTimeline.IOwnProps
type IStateProps = Kanban.KanbanTimeline.IStateProps

const newsParams = [
    'viewType', 'sending', 'statuses'
] as const


export const mapStateToProps = (state: any, props: IOwnProps) => ({
    ...getNewsParams(newsParams)(state),
    keys: getNewsKeys(props.params)(state),
    values: getNewsValue(state),
    statuses: getWorkflowStatuses(props.params.newsTypes!)(state), 
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadNewsList,
    loadWorkflowStatuses,
    reset,
    pathNews,
    getUserStatuses,
    changeStatus,
    getStatusFields
}, dispatch);

////////////

export const cnClassName = cn('Kanban');