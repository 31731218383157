import { Box, styled } from 'muicomponents/src';

export const StyledBox = styled(Box)(
    ({ theme }) => {
      /**
       * @rafactor marilisk
       * "max-width: 14%;" почему не calc(100% / 7)
       * с тем как есть теряем 2 процента ширины
       * max-width: 80%; - зачем?
       */
      return `
      @media (max-width: 1100px) {
        max-height: 250px;
      }
        & .MuiPickersCalendar-week {
          max-width: 100%;
          display: flex;
          justify-content: space-around;
      
          & > div {
            max-width: 14%;
      
            .MuiButtonBase-root {
              max-width: 90%;
      
              @media (max-width: 1100px) {
                padding: 10% 0;
                height: 90%;
              }
            }
  
            .MuiIconButton-label {
              max-width: 80%;
            }
          }
        }  
    `
    }
  ) as typeof Box;