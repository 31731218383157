import * as React from 'react';
import { IEditorTypeEditProps } from './edit.index';
import { NoticesNews } from '../../../Notices.type'
import { Editor } from '../../Editor'
import { NoticesValidator, noticesValidate } from '../../../Notices.validate'

export const EditorTypeEdit: React.FC<IEditorTypeEditProps> = ({ onChange: cbChange, data }) => {
    const localData = data as any;
    
    const onChange = (data: any) => {
        const vb = new NoticesValidator();
        vb.addData(data)
        vb.setRules(noticesValidate)
        cbChange(data, vb.validation().length === 0)
    }
    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData);
    }

    const onChangeTitle = (e: any) => {
        localData.header = e.target.value;
        onChange(localData);
    }
    return <Editor
        onChangeTitle={onChangeTitle}
        onChangeText={onChangeText}
        data={localData} />
}
