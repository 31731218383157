/**
 * @packageDocumentation
 * @module News_type_tasks_Task_type_DocumentReadBlock-Item
 */
import { IClassNameProps } from "@bem-react/core";
import { cn } from "@bem-react/classname";
import { TaskDocumentReadBlockFileItem } from "../TaskDocumentReadBlock.index";

export interface IDReadItemProps extends IClassNameProps {
  data: TaskDocumentReadBlockFileItem;
  onRemove?: (id: string) => void;
}

export interface IDReadItemState {}

export const cnDReadItem = cn('DReadItem');