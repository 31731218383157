import React, { FC, memo, useState, useCallback, useMemo, ReactNode } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext, NewsContextProps } from '../../../contexts/news'
import { Actions } from 'blocks/NewsView/Actions/Actions';
import { Like } from 'blocks/NewsView/Actions/Like/Like';
import { Thank } from 'blocks/NewsView/Actions/Thank/Thank';
import likersModal from 'blocks/Dialogs/News/Likers/NewsLikers';
import { Button, Icon, SplitButton } from 'uielements/src'
import { Bable } from 'blocks/Bable/Bable'
import { head, drop } from 'lodash'
import i18n from 'localizations/i18n'

type IPresenterProps = News.NewsActions.IPresenterProps
type IOwnProps = News.NewsActions.IOwnProps
type IDispatchProps = News.NewsActions.IDispatchProps
type IStateProps = News.NewsActions.IStateProps
type IState = News.NewsActions.IState

const noopObj: any = {}
export const Presenter: FC<IPresenterProps> = ({
    className,
    id,
    sendAction,
    actions,
    children,
    learningTrack
}) => {
    const onClick = useCallback((action) => sendAction(action), [id])

    const options = drop(actions).map(action => (
        {
            title: i18n.t('pryaniky.news.actions.' + action.action.type),
            buttonProps: {
                onClick: () => onClick(action.action)
            }
        }
    ))

    const first = head(actions)

    if (!first) return null;


    const buttonRender = ({
        PRN_TRACKS_USE_PagesV2_LEARNED: <Button main type='rlink' href={learningTrack?.url}>
            {first.icon && <Icon icon={first.icon} />}
            {i18n.t('pryaniky.news.actions.' + first.action.type)}
        </Button>,
        PRN_TRACKS_USE_Quizzes_LEARNED: <Button main type='rlink' href={learningTrack?.url}>
            {first.icon && <Icon icon={first.icon} />}
            {i18n.t('pryaniky.news.actions.' + first.action.type)}
        </Button>,
        PRN_TRACKS_USE_LMSCources_LEARNED: <Button main type='rlink' href={learningTrack?.url}>
            {first.icon && <Icon icon={first.icon} />}
            {i18n.t('pryaniky.news.actions.' + first.action.type)}
        </Button>,
    } as { [key: string]: ReactNode })[first.action.type]

    return <div className={cnClassName({}, [className])}>
        <SplitButton
            className={cnClassName('ActionMenu')}
            button={buttonRender || <Button main onClick={() => onClick(first.action)}>
                {first.icon && <Icon icon={first.icon} />}
                {i18n.t('pryaniky.news.actions.' + first.action.type)}
            </Button>}
            options={options} />
    </div>
}
export const Connected = connect<IStateProps, IDispatchProps, IOwnProps & NewsContextProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter))
export default withNewsContext(Connected, ['newsId']);

