import React, { FC, ReactNode, PropsWithChildren } from "react";
import { Divider, LoadingButton, Typography } from "muicomponents/src";
import { PageActionsBox, PageBox, PageChildrenBox, PageHeaderBox, PageWarningBox } from "./SurveyPage.styled";
import { Translate } from "localizations/Translate";
import { useSurveyPage } from "./SurveyPage.hooks";
import { cnSurveyPassing, SurveyPassingBaseProps } from "../SurveyPassing.index";
import { ErrorOutline } from 'muicomponents/src/Icons/Icons';


export const SurveyPage: FC<PropsWithChildren<SurveyPassingBaseProps>> = ({
    preview,
    children
}) => {

    const {
        page,
        loading,
        skipShow,
        sendAnswers
    } = useSurveyPage({ preview });

    if(!page) return null;

    return (
        <PageBox>
            {
                page.title &&
                <PageHeaderBox>
                    <Typography variant="h5">
                        {page.title}
                    </Typography>
                </PageHeaderBox>
            }
            <PageChildrenBox>
                {children}
            </PageChildrenBox>
            <Divider />
            
            <PageWarningBox className={cnSurveyPassing('Warning')}>
                <ErrorOutline sx={{ fontSize: 18.33 }} color="primary" />
                <span>
                    <Translate i18nKey={`pryaniky.survey.answer.item.warning${page.questions.length > 1 ? '.multi' : ''}`} />
                </span>
            </PageWarningBox>
            <PageActionsBox>
                <LoadingButton
                    variant="contained"
                    loading={loading}
                    onClick={() => sendAnswers()}
                >
                    {
                        page.isEnd
                        ? <Translate i18nKey={'pryaniky.quiz.end'} />
                        : <Translate i18nKey={'pryaniky.quiz.toAnswer'} />
                    }
                </LoadingButton>
            {
                skipShow &&
                <LoadingButton
                    variant="outlined"
                    loading={loading}
                    onClick={() => sendAnswers(true)}
                >
                    {
                        page.isEnd
                        ? <Translate i18nKey={'pryaniky.survey.skipAndEnd'} />
                        : <Translate i18nKey={'pryaniky.survey.next'} />
                    }
                </LoadingButton>
            }
            </PageActionsBox>
        </PageBox>
    );
};