import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import * as SEL from '../../selectors'
import {DialogFeedback} from 'blocks/Dialogs/FeedbackDialog/FeedbackDialog'

const handleContextActionShowFeedbackContact = function* handleContextActionShowFeedbackContact({ payload }: AT.AShowFeedbackContact) {
    const { action, id } = payload
    if (action === 'showFeedbackContact') {
        try {
            const news = yield* select(SEL.getNewsById(id))
            const feedback = news.feedback


            if(!feedback) return ;

            const result = yield* call(DialogFeedback, {
                feedback
            })

        } catch (error) {
            console.error(error)
        }
    }
    yield;

    return 0;
}

export default handleContextActionShowFeedbackContact