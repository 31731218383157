/**
 * @packageDocumentation
 * @module Filter_type_groups
 */
import React from 'react';
import { FiltersPresenter } from '../Filters';
import i18n from "../../../localizations/i18n";
import { IFiltersStateProps, IFiltersDispatchProps, IFiltersProps, mapStateToProps, mapDispatchToProps, cnFilters } from '../Filters.index';
import { IStateType as IState } from 'redux/store';
import { connect } from 'react-redux';
import { Selectize } from 'uielements/src';
import { userRoles } from 'utils/src/constants.prn';
import { Translate } from 'localizations/Translate';
import { routerActions } from 'connected-react-router'
import { Dispatch } from 'redux';
import { withRouter } from 'react-router';

const usersRole = (value?: string) => ({
  type: 'usersRole',
  value
})

/**
 * dv - default values of this filters if dv[key] === 'undefined' default is 'all'
 */
const dv: Record<string, string> = {
  groupsOrder: ''
}

/**
 * h - filters header object
 */
// const h: Record<string, string> = {
//   'usersCatFilter/usersRole/userBlock': 'pryaniky.filter.users.title'
// }

/**
 * f - data to this filters
 */
const f: any[][] = [ // all filters
  [
    { id: 'all', title: 'all', type: 'collectionType', value: '' },
    { id: 'skills', title: 'skills', type: 'collectionType', value: 'skills' },
    { id: 'interests', title: 'interests', type: 'collectionType', value: 'interests' },
  ]
]

class FiltersTypeTagCatPresenter extends FiltersPresenter {
  public defaulValues = { 'collectionType': '' };

  /**
   * prepare filters to render data like {}[][]
   */
  public data = f.map(fl => fl.map(el => ({
    id: el.id,
    type: el.type || 'collectionType',
    title: i18n.t(`pryaniky.filter.tagcat.${el.title}`),
    value: el.value,
    showProps: el.showProps
  })));

  public getDataFromContext = (type: string) => {
    if (type === 'roles') {
      return this.props.context[type] || this.props.authUser.baseData.roles || [];
    }
    if (type === 'networks') {
      return this.props.authUser.baseData.networks;
    }
    return this.props.context[type];
  }

  private onChange = (selected: any) => {
    (this.props as any).routerPush(selected.value ? `?collectionType=${selected.value}` : `?`)
    this.handleChange(selected)
  }

  /**
   * render - it is render)
   */
  public render() {
    const { tag: Tag = 'div', context, className, itemsView, authUser } = this.props;

    return (
      <Tag tag='div' {...this.props} className={cnFilters({}, [className])} >
        {
          this.data.map((fl, idx) => {
            const type = fl[0] && fl[0].type;
            // if (active === 'all' && dv[type] !== undefined) active = dv[type];
            const oldData = fl;
            fl = fl.filter(i => Object.keys(i.showProps || {}).reduce((a, k, idx) => {
              if (!a) return a;
              if (typeof (i.showProps[k]) === 'function') return i.showProps[k](this.getDataFromContext(k));
              if (i.showProps[k] !== itemsView[k as keyof typeof itemsView]) return false
              return a;
            }, true as boolean));
            let active = this.getActive(idx);
            if (active && !fl.map(e => e.id).includes(active) && !fl.map(e => e.value).includes(active)) {
              const defObj = fl.reduce((a: any, c) => a || ((this.defaulValues as any)[c.type] === c.value ? c : a), undefined);
              if (defObj) this.onChange(defObj);
            }
            // this.data[idx] = fl;
            // active = this.getActive(idx);
            // this.data[idx] = oldData;
            return <div className={cnFilters('Block')} >
              <h4 className={cnFilters('Subtitle')}>
                <Translate i18nKey={'pryaniky.filter.tagcat.title'} />
              </h4>
              <Selectize onClick={this.onChange} elements={fl} active={active} />
            </div>
          })
        }
      </Tag>
    )
  }
}


/**
 * connected to redux
 */
export const FiltersTypeTagCat = withRouter(
  connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersProps, IState>(
    mapStateToProps,
    (dispatch: Dispatch) => ({ ...mapDispatchToProps(dispatch), routerPush: (url: string) => dispatch(routerActions.push(url))})
  )(FiltersTypeTagCatPresenter)
);