// import {User, BaseNews, defaultBaseNews,  defaultUser, defaultGroup, Group} from '../BaseType'
import { IBaseNews, createDefaultNews } from 'News/types/baseNews'
export interface CommentsOnlyNews extends IBaseNews {
    newstype: 36;
}

export const defaultNewsNews: CommentsOnlyNews = {
    ...createDefaultNews(),
    newstype: 36,
}

export const createDefaultModel = (): CommentsOnlyNews => ({
    ...createDefaultNews(),
    newstype: 36,

})