// import { IImgLoaderProps } from '../ImgLoader.index';

// import { IDispatchProps } from 'src/redux/connector';

export interface IImgLoaderTypeProfileEditProps { // extends IImgLoaderProps, IImgLoaderStateProps, IDispatchProps {
  tag?: 'div';
}

export interface IImgLoaderTypeProfileEditState {
  text?: string;
}

export interface IImgLoaderStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})