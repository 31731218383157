import React, { FC } from 'react'
import { Skeleton } from 'uielements/src'
import './Filter.scss'
import { cn } from '@bem-react/classname';
export const cnFilter = cn('SkeletFilter');

export const Filter: FC<any> = ({ className }) => {

    return <div className={cnFilter({}, [className])}>
        <div className="Selectize LMSUnitList-Status">
            <Skeleton shape="line" size="lg" style={{ width: '200px' }} />
            <Skeleton shape="line" size={"sm"} style={{ width: '150px' }} />
            <Skeleton shape="line" size={"sm"} style={{ width: '150px' }} />
            <Skeleton shape="line" size={"sm"} style={{ width: '150px' }} />
            <Skeleton shape="line" size={"sm"} style={{ width: '150px' }} />
            <Skeleton shape="line" size={"sm"} style={{ width: '150px' }} />
        </div>
    </div>
}

