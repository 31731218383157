import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps, } from 'react-router';


///////////
export declare namespace StatisticsFull {
    export namespace SummaryInfo {
        export type IPresenterProps = IOwnProps

        export interface IOwnProps extends IClassNameProps {
            pieChart: any[]
            complete: number
            failed: number
            inProgress: number
            total: number
            trainingLevel: number
            isLoading?: boolean
        }
    }
}
////////////

type IOwnProps = StatisticsFull.SummaryInfo.IOwnProps

////////////

export const cnClassName = cn('LMSTOStatFullSummaryInfo');