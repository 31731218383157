import BaseApi from '../baseAPI/baseAPI'

const jsonFormatter = (obj: any) => {
    const returnObject: { [key: string]: any } = {}
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && key.startsWith("_")) {
            returnObject[key] = obj[key];
        }
    }
    return JSON.stringify(returnObject)
}


export declare namespace CMITypes {
    export type Completion_status = 'completed' | 'incomplete' | 'not attempted' | 'unknown';
    export type Credit = 'credit' | 'no-credit';
    export type Entry = "ab-initio" | "resume" | "";
    export type Exit = "time-out" | "suspend" | "logout" | "normal" | "" | void;
    export type Mode = "browse" | "normal" | "review";
    export type Success_status = "passed" | "failed" | "unknown";
    export type Time_limit_action = "exit,message" | "continue,message" | "exit,no message" | "continue,no message" | void;
}

export class InteractionsCorrectResponsesObject {
    private _pattern: "" // Data type changes based on interaction's type

    // private __children: string = ""
    /**
             * 
             */
    private API: BaseApi;

    /**
     * any array
     */
    // public childArray: any[] = []

    constructor(API: BaseApi) {
        this.API = API
    }

    // public get _count() {
    //     return String(this.childArray.length);
    // }

    // public set _count(_count) {
    //     this.API.throwSCORMError(404);
    // }

    // public get _children() {
    //     return this.__children;
    // }

    // public set _children(_children) {
    //     this.API.throwSCORMError(404);
    // }

    public get pattern() { return this._pattern; }
    public set pattern(pattern) { this._pattern = pattern; }

    public toJSON = jsonFormatter
}

export class InteractionsObjectivesObject {
    private _id: "" // SPM 4000 characters

    /**
             * 
             */
    private API: BaseApi;

    constructor(API: BaseApi) {
        this.API = API
    }

    public get id() { return this._id; }
    public set id(id) { this._id = id; }

    public toJSON = jsonFormatter
}

export class Score {
    /**
         * 
         */
    private API: BaseApi;

    /**
     * SPM 100 comments from learner
     */
    private __children: string = "caled,raw,min,max"

    /**
     * Data type: real (10,7). Range: -1.0 to 1.0
     */
    private _scaled: string = ""

    /**
     * Data type: real (10,7)
     */
    private _raw: string = ""

    /**
     * Data type: real (10,7)
     */
    private _min: string = ""

    /**
     * Data type: real (10,7)
     */
    private _max: string = ""


    /**
     * 
     */
    public toJSON = jsonFormatter


    constructor(API: BaseApi) {
        this.API = API
    }

    public get _children() {
        return this.__children;
    }

    public set _children(_children) {
        this.API.throwSCORMError(404);
    }


    public get scaled() {
        return this._scaled;
    }

    public set scaled(scaled) {
        this._scaled = scaled;
    }


    public get raw() {
        return this._raw;
    }

    public set raw(raw) {
        this._raw = raw;
    }


    public get min() {
        return this._min;
    }

    public set min(min) {
        this._min = min;
    }


    public get max() {
        return this._max;
    }

    public set max(max) {
        this._max = max;
    }
}



export class Objectives {
    /**
     * 
     */
    private API: BaseApi;

    /**
     * SPM 100 comments from learner
     */
    private __children: string = "id,score,success_status,completion_status,progress_measure,description"

    /**
     * any array
     */
    private childArray: any[] = []

    /**
     * 
     */
    public toJSON = jsonFormatter


    constructor(API: BaseApi) {
        this.API = API
    }


    public get _children() {
        return this.__children;
    }

    public set _children(_children) {
        this.API.throwSCORMError(404);
    }


    public get _count() {
        return String(this.childArray.length);
    }

    public set _count(_count) {
        this.API.throwSCORMError(404);
    }
}



export class LearnerPreference {
    /**
 * 
 */
    private API: BaseApi;

    /**
     * SPM 250 comments from learner
     */
    private __children: string = "audio_level,language,delivery_speed,audio_captioning"

    /**
     * Data type: real (10,7). Range: 0.0 to infinity
     */
    private _audio_level: string = "1"

    /**
     * SPM 250 characters
     */
    private _language: string = ""

    /**
     * Data type: real (10,7). Range: 0.0 to infinity
     */
    private _delivery_speed: string = "1"

    /**
     * Allowed values: "-1", "0", "1"
     */
    private _audio_captioning: "0"

    /**
     * 
     */
    public toJSON = jsonFormatter


    constructor(API: BaseApi) {
        this.API = API
    }

    public get _children() {
        return this.__children;
    }

    public set _children(_children) {
        this.API.throwSCORMError(404);
    }


    public get audio_level() {
        return this._audio_level;
    }

    public set audio_level(audio_level) {
        this._audio_level = audio_level;
    }


    public get language() {
        return this._language;
    }

    public set language(language) {
        this._language = language;
    }


    public get delivery_speed() {
        return this._delivery_speed;
    }

    public set delivery_speed(delivery_speed) {
        this._delivery_speed = delivery_speed;
    }


    public get audio_captioning() {
        return this._audio_captioning;
    }
    public set audio_captioning(audio_captioning) {
        this._audio_captioning = audio_captioning;
    }

}

export class InteractionsObject {
    /**
     * 
     */
    private API: BaseApi;

    private _id: string = "" // SPM 4000 characters

    private _type: string = "" // Allowed values: "true-false", "choice", "fill-in", "long-fill-in", "likert", "matching", "performance", "sequencing", "numeric", "other"

    private _timestamp: string = "" // Data type: time (second,10,0) accurate to one second

    private _weighting: string = "" // Data type: real (10,7)

    private _learner_response: string = "" // Data type changes based on interaction's type

    private _result: string = "" // Allowed values: "correct", "incorrect", "unanticipated", "neutral", real(10,7)

    private _latency: string = "" // Data type: timeinterval (second,10,2)

    private _description: string = "" // SPM 250 characters


    public objectives: Objectives

    public correct_responses: InteractionsCorrectResponses


    constructor(API: BaseApi) {
        this.API = API
        this.correct_responses = new InteractionsCorrectResponses(API)
        this.objectives = new Objectives(API)
    }


    public get id() {
        return this._id;
    }
    public set id(id) {
        this._id = id;
    }


    public get type() {
        return this._type;
    }
    public set type(type) {
        this._type = type;
    }


    public get timestamp() {
        return this._timestamp;
    }
    public set timestamp(timestamp) {
        this._timestamp = timestamp;
    }


    public get weighting() {
        return this._weighting;
    }
    public set weighting(weighting) {
        this._weighting = weighting;
    }


    public get learner_response() {
        return this._learner_response;
    }
    public set learner_response(learner_response) {
        this._learner_response = learner_response;
    }


    public get result() {
        return this._result;
    }
    public set result(result) {
        this._result = result;
    }


    public get latency() {
        return this._latency;
    }
    public set latency(latency) {
        this._latency = latency;
    }


    get description() {
        return this._description;
    }
    set description(description) {
        this._description = description;
    }
}


export class InteractionsCorrectResponses {
    private API: BaseApi;

    constructor(API: BaseApi) {
        this.API = API
    }
    // SPM changes based on interaction's type
    childArray: any[] = []
    get _count() { return String(this.childArray.length); }
    set _count(_count) { this.API.throwSCORMError(404); }
}


export class Interactions {
    /**
     * 
     */
    private API: BaseApi;

    /**
     * SPM 250 comments from learner
     */
    private __children: string = "id,type,objectives,timestamp,correct_responses,weighting,learner_response,result,latency,description"

    /**
     * any array
     */
    public childArray: any[] = []

    /**
     * 
     */
    public toJSON = jsonFormatter


    constructor(API: BaseApi) {
        this.API = API
    }


    public get _children() {
        return this.__children;
    }

    public set _children(_children) {
        this.API.throwSCORMError(404);
    }


    public get _count() {
        return String(this.childArray.length);
    }

    public set _count(_count) {
        this.API.throwSCORMError(404);
    }
}


export class CommentsFromLms {

    /**
     * 
     */
    private API: BaseApi;

    /**
     * SPM 100 comments from learner
     */
    private __children: string = "comment,location,timestamp"

    /**
     * any array
     */
    private childArray: any[] = []

    /**
     * 
     */
    public toJSON = jsonFormatter


    constructor(API: BaseApi) {
        this.API = API
    }


    public get _children() {
        return this.__children;
    }

    public set _children(_children) {
        this.API.throwSCORMError(404);
    }


    public get _count() {
        return String(this.childArray.length);
    }

    public set _count(_count) {
        this.API.throwSCORMError(404);
    }
}


/**
 * 
 */
export class CommentsFromLearner {
    /**
     * 
     */
    private API: BaseApi;

    /**
     * SPM 250 comments from learner
     */
    private __children: string = "comment,location,timestamp"

    /**
     * any array
     */
    private childArray: BaseApi[] = []

    /**
     * 
     */
    public toJSON = jsonFormatter


    constructor(API: BaseApi) {
        this.API = API
    }

    public get _children() {
        return this.__children;
    }

    public set _children(_children) {
        this.API.throwSCORMError(404);
    }


    public get _count() {
        return String(this.childArray.length);
    }

    public set _count(_count) {
        this.API.throwSCORMError(404);
    }
}



/**
 * CMI data model
 */
class CMI {
    /**
     * 
     */
    private API: BaseApi;

    /**
     * 
     */
    private __version: string = '1.0'

    /**
     * Allowed values: "completed", "incomplete", "not attempted", "unknown"
     */
    private _completion_status: CMITypes.Completion_status = "unknown"

    /**
     * Data type: real (10,7). Range: 0.0 to 1.0
     */
    private _completion_threshold: string = ''

    /**
     * Allowed values: "credit", "no-credit"
     */
    private _credit: CMITypes.Credit = "credit"

    /**
     * Allowed values: "ab-initio", "resume", ""
     */
    private _entry: CMITypes.Entry = ""

    /**
     * Allowed values: "time-out", "suspend", "logout", "normal", ""
     */
    private _exit: CMITypes.Exit = ""

    /**
     * SPM 4000 characters
     */
    private _launch_data: string = ""

    /**
     * SPM 4000 characters
     */
    private _learner_id: string = ""

    /**
     * SPM 250 characters
     */
    private _learner_name: string = ""

    /**
     * SPM 1000 characters
     */
    private _location: string = ""

    /**
     * Data type: timeinterval (second,10,2)
     */
    private _max_time_allowed: any = ""

    /**
     * Allowed values: "browse", "normal", "review"
     */
    private _mode: CMITypes.Mode = "normal"

    /**
     * Data type: real (10,7). Range: 0.0 to 1.0
     */
    private _progress_measure: string = ""

    /**
     * Data type: real (10,7). Range: -1.0 to 1.0
     */
    private _scaled_passing_score: string = ""

    /**
     * Data type: timeinterval (second,10,2)
     */
    private _session_time: string | void = ""

    /**
     * Allowed values: "passed", "failed", "unknown"
     */
    private _success_status: CMITypes.Success_status = "unknown"

    /**
     * SPM 64000 characters
     */
    private _suspend_data: string = ""

    /**
     * Allowed values: "exit,message", "continue,message", "exit,no message", "continue,no message"
     */
    private _time_limit_action: CMITypes.Time_limit_action = "continue,no message"

    /**
     * Data type: timeinterval (second,10,2)
     */
    private _total_time: string = "0"


    /**
     * 
     */
    public comments_from_learner: CommentsFromLearner;

    /**
     * 
     */
    public comments_from_lms: CommentsFromLms

    /**
     * 
     */
    public interactions: Interactions

    /**
     * 
     */
    public learner_preference: LearnerPreference

    /**
     * 
     */
    public objectives: Objectives

    /**
     * 
     */
    public score: Score

    /**
     * 
     */
    public toJSON = () => jsonFormatter(this)

    /**
     * 
     * @param API 
     */
    constructor(API: BaseApi) {
        this.API = API
        this.comments_from_learner = new CommentsFromLearner(this.API)
        this.comments_from_lms = new CommentsFromLms(this.API)
        this.interactions = new Interactions(this.API)
        this.learner_preference = new LearnerPreference(this.API)
        this.objectives = new Objectives(this.API)
        this.score = new Score(this.API)
    }


    public get _version() {
        return this.__version
    }

    public set _version(version: string) {
        this.API.throwSCORMError(404)
    }


    /**
     * Determine the completion_status of a cmi object
     */
    public getCompletionStatus = (): CMITypes.Completion_status => {
        let completion_status = this._completion_status;

        if (this._completion_threshold && this._progress_measure) {
            completion_status = this._progress_measure >= this._completion_threshold ? "completed" : "incomplete";
        } else if (this._completion_threshold) {
            completion_status = "unknown";
        }

        return completion_status;
    }

    public get completion_status() {
        return this.getCompletionStatus()
    }

    public set completion_status(completion_status) {
        this._completion_status = completion_status;
    }


    public get completion_threshold() {
        return this._completion_threshold
    }

    public set completion_threshold(completion_threshold) {
        this.API.isNotInitialized() ? this._completion_threshold = completion_threshold : this.API.throwSCORMError(404);
    }


    public get credit() {
        return this._credit
    }

    public set credit(credit) {
        this.API.isNotInitialized() ? this._credit = credit : this.API.throwSCORMError(404);
    }


    public get entry() {
        return this._entry;
    }

    public set entry(entry) {
        this.API.isNotInitialized() ? this._entry = entry : this.API.throwSCORMError(404);
    }

    private jsonString = true
    public get exit() {
        return (!this.jsonString) ? this.API.throwSCORMError(405) : this._exit;
    }

    public set exit(exit) {
        this._exit = exit;
    }


    public get launch_data() {
        return this._launch_data;
    }

    public set launch_data(launch_data) {
        this.API.isNotInitialized() ? this._launch_data = launch_data : this.API.throwSCORMError(404);
    }


    public get learner_id() {
        return this._learner_id;
    }

    public set learner_id(learner_id) {
        this.API.isNotInitialized() ? this._learner_id = learner_id : this.API.throwSCORMError(404);
    }


    public get learner_name() {
        return this._learner_name;
    }

    public set learner_name(learner_name) {
        this.API.isNotInitialized() ? this._learner_name = learner_name : this.API.throwSCORMError(404);
    }


    public get location() {
        return this._location;
    }

    public set location(location) {
        this._location = location;
    }


    public get max_time_allowed() {
        return this._max_time_allowed;
    }

    public set max_time_allowed(max_time_allowed) {
        this.API.isNotInitialized() ? this._max_time_allowed = max_time_allowed : this.API.throwSCORMError(404);
    }


    public get mode() {
        return this._mode;
    }

    public set mode(mode) {
        this.API.isNotInitialized() ? this._mode = mode : this.API.throwSCORMError(404);
    }


    public get progress_measure() {
        return this._progress_measure;
    }

    public set progress_measure(progress_measure) {
        this._progress_measure = progress_measure;
    }


    public get scaled_passing_score() {
        return this._scaled_passing_score;
    }

    public set scaled_passing_score(scaled_passing_score) {
        this.API.isNotInitialized() ? this._scaled_passing_score = scaled_passing_score : this.API.throwSCORMError(404);
    }


    public get session_time() {
        return (!this.jsonString) ? this.API.throwSCORMError(405) : this._session_time;
    }

    public set session_time(session_time) {
        this._session_time = session_time;
    }


    /**
     * Determine the success_status of a cmi object
     */
    public getSuccessStatus = (): CMITypes.Success_status => {
        let success_status = this._success_status;

        if (this._scaled_passing_score && this.score.scaled) {
            success_status = this.score.scaled >= this._scaled_passing_score ? "passed" : "failed";
        } else if (this._scaled_passing_score) {
            success_status = "unknown";
        }

        return success_status;
    }

    public get success_status() {
        return this.getSuccessStatus();
    }

    public set success_status(success_status) {
        this._success_status = success_status;
    }


    public get suspend_data() {
        return this._suspend_data;
    }

    public set suspend_data(suspend_data) {
        this._suspend_data = suspend_data;
    }


    public get time_limit_action() {
        return this._time_limit_action;
    }

    public set time_limit_action(time_limit_action) {
        this.API.isNotInitialized() ? this._time_limit_action = time_limit_action : this.API.throwSCORMError(404);
    }


    public get total_time() {
        return this._total_time
    }

    public set total_time(total_time) {
        this.API.isNotInitialized() ? this._total_time = total_time : this.API.throwSCORMError(404);
    }
}


export default CMI;