import { cn } from '@bem-react/classname'

export interface IReactionsProps {
    label?: string;
    items?: IReactionsItem[];
}

export interface IReactionsItem {
    image: string;
    action: string;
    label?: string;
    count: number;
    selected?: boolean;
}

export const cnReactions = cn('Reactions')