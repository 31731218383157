import { IRatingsWidgetProps } from '../RatingsWidget.index';

import { IDispatchProps } from 'redux/connector';

export interface IRatingsWidgetTypeCommonProps extends IRatingsWidgetProps, IRatingsWidgetStateProps, IDispatchProps {
  tag?: 'div';
}

export interface IRatingsWidgetTypeCommonState {
  text?: string;
}

export interface IRatingsWidgetStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})