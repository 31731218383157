import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { 
    loadFullUnit, 
    compliteSession, 
    sendResult, 
    loadUserResult 
} from "../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
export interface ISessionResultProps extends IClassNameProps, ISessionResultStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
    sId: string;
    result: any;
    // unitContent: any
}

export interface ISessionResultState {
    // courses: ICourse[];
    // isFinished: boolean;
}

export interface ISessionResultStateProps {
    // store: any
    // unitContent: any
}

export const mapStateToProps = (state: any, props: any) => ({
    // result: state.LMS.sessionResults[props.sId]
    // result: state.LMS.userSessionsResults[props.sId]
    // result: state.LMS.sessionResults[props.sId]
    // unitContent: state.LMS.units[props.unit.id],
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    // loadUnitContent,
    // compliteSession,
    // sendResult,
    // loadUserResult
}, dispatch);

export const cnSessionResult = cn('SessionResult');