import * as utils from 'utils/src/utils'
import { IStateType as IState } from 'redux/store';
import { ISliderItemAction } from 'utils/src/requests/models/api.slider'
import actions from 'redux/actionsTypes/Slider';

export const sliderInit = (payload: ISliderItemAction) => {
  return {
    type: actions.INIT,
    payload
  }
}

export const changeSlide = (payload: ISliderItemAction) => {
  return {
    type: actions.CHANGE_SLIDE,
    payload 
  }
}

export const incrementSlideItem = (payload?: ISliderItemAction) => {
  return {
    type: actions.INCREMENT_SLIDE_ITEM,
    payload
  }
}

export const mouseOver = (payload?: any) => {
  return {
    type: actions.MOUSE_OVER,
    payload
  }
}
