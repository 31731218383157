export interface ISessionModel {
    courseId: string,
    // "isPaused": true,
    beginDate?: string | Date,//"2019-12-18T10:26:38.540Z",
    endDate?: string | Date,//"2019-12-18T10:26:38.540Z",
    memberGroups: string[],
    memberUsers: string[],
    groupFullList: any[],
    userFullList: any[],
    obligatoryUsers: string[],
    obligatoryGroups: string[],
    startType: number,
    completeInDays: number,
    inviteUsers: boolean,
    // isMandatory: true,
    periodDays: number,
    id: string,
    courseSessionId: string,
    name: string,
    description: string,
    users: any[],
    authors: any[],
    totalUserCount: number,
    isPreview: boolean
}

export const sessionModelCreator = (): ISessionModel => {
    const endDate = new Date()
    endDate.setMonth(endDate.getMonth() + 1)
    return ({
        "courseId": "00000000-0000-0000-0000-000000000000",
        // "isPaused": true,
        "beginDate": (new Date()).toISOString(),//"2019-12-18T10:26:38.540Z",
        "endDate": endDate.toISOString(),//"2019-12-18T10:26:38.540Z",
        "memberGroups": [],
        "memberUsers": [],
        "groupFullList": [],
        "userFullList": [],
        obligatoryUsers: [],
        obligatoryGroups: [],
        "startType": 0,
        "completeInDays": 0,
        "inviteUsers": true,
        // "isMandatory": true,
        "periodDays": 0,
        "id": "00000000-0000-0000-0000-000000000000",
        "courseSessionId": "00000000-0000-0000-0000-000000000000",
        "name": "",
        "description": "",
        "users": [],
        "authors": [],
        "totalUserCount": 0,
        "isPreview": false
    })
}