import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { withRouter } from "react-router"
import WidgetPresenter from '../Widget';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeVacanciesProps } from './Widget_type_vacancies.index';
import './Widget_type_vacancies.scss';
import { RouteComponentProps } from 'react-router';
import { widgets } from 'i.widgets';
import queryString from 'query-string';
import { VacanciesList } from 'blocks/Vacancies/List/VacanciesList';

const type = 'Vacancies';

class WidgetTypeVacanciesPresenter extends WidgetPresenter<IWidgetTypeVacanciesProps & RouteComponentProps<{ fname: string, pid: string, aid: string }>> {
  public render() {
  
    if (!this.props.widget) return null;
    
    return (
      <widgets.components.common { ...this.props } className={cnWidget({ type })}>
        <VacanciesList />
      </widgets.components.common>
    )
  }
}

export default withRouter(WidgetTypeVacanciesPresenter)