import React, { FC, useState, useMemo } from 'react'
import {
    cnClassName,
    IAddNewsToTrackOwnProps,
    IAddNewsToTrackResult,
    IAddNewsToTrackPresenterProps,
    IAddNewsToTrackStateProps,
    IAddNewsToTrackDispatchProps,
    mapDispatchToProps,
    mapStateToProps
} from './interfaces'
import './style.scss'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator'
import DialogBody from '../../dialogs/modal/components/DialogBody'
import SingleAutocomplete from 'uielements/src/MaterialElements/SingleAutocomplete'
import withTrackSuggester from 'blocks/HOCs/SingleAutocomplete/withTrackSuggester'
import { connect } from 'react-redux';
import {
    Dialog,
    // DialogTitle,
    // DialogContent,
    // DialogActions,
    Select,
    Button,
    Icon,
    CheckboxInput,
    Textarea, Spinner
} from 'uielements/src';
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import MDNodeBulder from 'uielements/src/CommonmarkRender/MDNodeBulder'
import { cutText } from 'LMSModule/utils/common.utils'
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'

const TrackSelect = withTrackSuggester(SingleAutocomplete)

export const Presenter: FC<IAddNewsToTrackPresenterProps> = ({
    handleAccept,
    handleClose,
    className,
    newsId,
    // addFile,
    text,
    header,
    tags,
    ...other
}) => {
    const shortText = useMemo<string>(() => {
        const nb = new MDNodeBulder(text)
        return cutText(nb.getPlainText().join(' '), 30)
    }, [])

    const [tid, setTid] = useState<string | undefined>(undefined)
    const accept = () => {
        if (tid) {
            // addFile({
            //     newsUid: newsId,
            //     tagName: tid
            // })
            handleAccept({
                newsUid: newsId,
                tagName: tid
            })
        }
    }
    return <div>
        <DialogTitle onClose={handleClose}>
            {tTr('dialogs.add_news.title')}
        </DialogTitle>
        <div className={cnClassName({}, [className])}>
            <div>
                {shortText}
            </div>
            <div>
                {/* {tags.filter.displayName || ''} */}
            </div>
            <TrackSelect
                // defaultValue={tags[0]?.displayName || ''}
                defaultElements={tags.map((v: any) => ({
                    value: v.id,
                    title: v.displayName,
                    id: v.id
                }))}
                onSelect={(item) => setTid(item?.id)}
                label={tTr('dialogs.add_news.select_track.label')}
            />
        </div>
        <DialogActions
        acceptText={tTr('dialogs.add_news.confirm')}
        closeText={tTr('dialogs.add_news.close')}
        disableAccept={!tid}
        onAccept={accept}
        onClose={handleClose}/>
            {/* <Button main disabled={!tid} onClick={accept}>{tTr('dialogs.add_news.confirm')}</Button>
            <Button onClick={handleClose}>
                {tTr('dialogs.add_news.close')}
            </Button>
        </DialogActions> */}
    </div>
    /*return <DialogBody disableAccept={!tid} onAccept={accept} onClose={handleClose} header={"Add to learning track"}>
        <div className={cnClassName({}, [className])}>
            <div>
                {news.header} {news.id}
            </div>
            <TrackSelect
                onSelect={(item) => setTid(item?.id)}
                label={'Track'}
            />
        </div>
    </DialogBody>*/
}
const Connected = connect<IAddNewsToTrackStateProps, IAddNewsToTrackDispatchProps, IAddNewsToTrackOwnProps, any>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);

export default createModal<IAddNewsToTrackResult, IAddNewsToTrackOwnProps>(Presenter, {
    maxWidth: 'md',
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            overflowY: 'unset'
        }
    },
    TransitionComponent
});
