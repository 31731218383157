import React, { FC, memo, useEffect, useState } from 'react'
import {
    cnClassName,
    News
} from './NewsUrlSelector.index'
import './NewsUrlSelector.scss'
import FormControl from '@material-ui/core/FormControl';
import i18n from 'localizations/i18n'
import TextField from '@material-ui/core/TextField';
import { getNewsIdFromUrl } from 'News/utils/utils'
import { useLoadNewsById } from 'News/utils/hooks'
import NewsItem from 'News/containers/NewsItem'
import BaseView from 'News/containers/BaseView'
import LinearProgress from '@material-ui/core/LinearProgress';
import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg'
type IPresenterProps = News.NewsUrlSelector.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    onSelected
}) => {
    const [newsUrl, changeNewsUrl] = useState<string>('')
    const newsId = getNewsIdFromUrl(newsUrl)
    const [news, isLoading, { isFailed }] = useLoadNewsById(newsId)

    useEffect(() => {
        if (onSelected) onSelected(news?.newstype !== 16001 ? news : null)
    }, [news])

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Form')}>
            <FormControl fullWidth>
                <TextField
                    className={cnClassName('Input')}
                    label={i18n.t('pryaniky.newsurlselector.linktonews')}
                    value={newsUrl}
                    onChange={(e) => { changeNewsUrl(e.target.value) }}
                />
            </FormControl>
        </div>

        {isLoading && <LinearProgress className={cnClassName('Progress')} />}

        {news && <div className={cnClassName('NewsBox')}>
            <NewsItem id={news.id} ViewComponent={BaseView} repost />
        </div>}

        {(newsUrl && !newsId) && <ErrorMsg>{i18n.t('pryaniky.newsurlselector.urluncorrected')}</ErrorMsg>}
        {isFailed && <ErrorMsg>{i18n.t('pryaniky.newsurlselector.newsnotfound')}</ErrorMsg>}
    </div>
}

export default memo(Presenter);
