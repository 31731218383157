import React, { FC, useEffect, memo, useState, useMemo } from 'react'
import {
    cnClassName,
    SkillsListItemInterface
} from './SkillsListItem.interface'
import { Icon } from 'uielements/src'
import { UserList } from 'uielements/src/UserList/UserList';
// import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import i18n from 'localizations/i18n'
import { useRefedCallback } from 'utils/src/hooks'
import { ISkill } from 'redux/sagas/Skills/types'
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { Button } from 'muicomponents/src/Button/Button'
import { Link } from 'muicomponents/src/Link/Link'
import { SplitButton } from 'muicomponents/src/SplitButton/SplitButton'
import { ISplitButtonOption } from 'muicomponents/src/SplitButton/SplitButton.index'
import ButtonGroup from '@mui/material/ButtonGroup';
import { useResizeHandler } from 'utils/src/hooks'
import { ThumbUp, ThumbUpOffAlt, AvTimer } from 'muicomponents/src/Icons'
import { IconButton } from 'muicomponents/src/IconButton/IconButton'
import { Box } from 'muicomponents/src/Box/Box'
import { AvatarsTooltipList } from 'muicomponents/src/AvatarsTooltipList/AvatarsTooltipList';
import { Chip } from 'muicomponents/src/Chip/Chip'
import { useSkillsItem } from '../../hooks'
const Alert: FC<AlertProps> = (props) => <MuiAlert elevation={3} variant="standard" {...props} />;

type IPresenterProps = SkillsListItemInterface.IPresenterProps

export const SkillsListItem: FC<IPresenterProps> = ({
    className,
    skill,
    index,
    confirmSkill,
    userid,
    deleteSkill,
    showControls,
    setShow
}) => {

    const {
        del,
        actionCallback,
        onChangeConfirm,
        onCancleDelete,
        onDelete,
        setDel
    } = useSkillsItem(skill, userid, { confirmSkill, deleteSkill, setShow })

    const [showActionButton, setShowBtn] = useState(false)

    const ref = useResizeHandler((el) => {
        if (el.clientWidth < 500) {
            setShowBtn(false)
        } else {
            setShowBtn(true)
        }
    })

    const confirmBtr = useMemo(() => (
        <IconButton
            onClick={onChangeConfirm}
        >
            {skill.confirmedByMe ? <ThumbUp className={cnClassName("IconUp CustomStyle primaryColor3-text")} /> : <ThumbUpOffAlt className={cnClassName("IconDown CustomStyle primaryColor3-text")} />}

        </IconButton>
    ), [skill.confirmedByMe, showActionButton, skill.tag.id])


    return <Box sx={{ display: "flex", alignItems: "center" }} ref={ref as any} className={cnClassName({}, [className])}>
        {!del &&
            <>
                {/* <Box className={cnClassName('Index')}>
                    {skill.onModeration ?
                        <Tooltip
                            className={'InfoIcon'}
                            title={i18n.t('pryaniky.skills.onModeration.help.1')}
                        >
                            <span><Icon icon={'eye-crossed'} /></span>

                        </Tooltip> :
                        <span>{index}.</span>}
                </Box> */}
                <Box className={cnClassName('Title')} sx={{ width: "30%", display: "flex", alignItems: "center" }}>

                    <Link

                        href={`/tag/${skill.tag.id}/people?collectionType=skills`}
                        className={cnClassName('TagLink')}
                        title={skill.tag.displayName}>
                        <Chip key={skill.tag.id}
                            sx={{ cursor: "pointer", color: "#fff", background: `${skill.onModeration ? "rgba(0, 0, 0, 0.26)" : ""}` }}
                            className={cnClassName(`SkillChip ${!skill.onModeration && "CustomStyle primaryColor3-bg"}`)}

                            variant='filled'
                            label={skill.tag.displayName} />
                    </Link>
                    {skill.onModeration &&
                        <Tooltip
                            className={'InfoIcon'}

                            title={i18n.t('pryaniky.skills.onModeration.help.1')}
                        >
                            <Box sx={{ display: "flex", alignItems: "center" }}><AvTimer sx={{ marginLeft: "5px", color: "rgba(0, 0, 0, 0.54)" }} /></Box>

                        </Tooltip>}
                </Box>
            </>
        }
        <Box className={cnClassName('Users')} sx={{
            width: !del ? "65%" : "100%",
            ".MuiContainer-disableGutters": {
                justifyContent: "flex-end",
                display: "flex"
            }
        }}>
            {del ? <Box className={cnClassName('AlertCell')} >
                <Alert sx={{
                    alignItems: "center",
                    ".MuiAlert-message": {
                        width: "100%"
                    }
                }} severity="warning" className={cnClassName('Alert')}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} className={cnClassName('AlertContent', { small: !showActionButton })}>
                        <Box className={cnClassName('AlertText')}>{i18n.t("pryaniky.skills.confirmDelete.1", { title: skill.tag.displayName })}</Box>
                        <ButtonGroup variant="contained" >
                            <Button className={cnClassName('Btn')} size={'small'} variant={'outlined'} onClick={onDelete}>{i18n.t("pryaniky.skills.deleteBtn.on.1")}</Button>
                            <Button className={cnClassName('Btn')} size={'small'} variant={'contained'} onClick={onCancleDelete}>{i18n.t("pryaniky.skills.deleteBtn.off.1")}</Button>
                        </ButtonGroup>
                    </Box>
                </Alert>
            </Box> : skill?.confirmedBy?.length > 0 ? <AvatarsTooltipList items={skill.confirmedBy as any}
                count={8}
                size={40}
                showCounter={false}
            /> : undefined
            }
        </Box>

        {skill?.confirmedBy && < Box className={cnClassName('Action')} sx={{ width: "5%" }}>
            {confirmBtr}
        </Box>}

    </Box >
}

export default memo(SkillsListItem);
