import actions from '../actionsTypes';
import { ReducerAction, SagaAction } from './types'

//////////////////////////////////////////////////////////////////////////////////////////////////////

/********************************************************/
/******************** to saga ***************************/
/********************************************************/

/******************************* LOAD_KPI_FOR_USER *******************************/
/**
 * получить список kpi для указаного пользователя
 */
export const loadKPIForUser: SagaAction.CLoadKPIForUser = (payload) => ({
    type: actions.LOAD_KPI_FOR_USER,
    payload
})


/******************************* CHANGE_KPI_ITEM *******************************/
/**
 * изменить экземпляр kpi
 */
export const сhangeKPIItem: SagaAction.CChangeKPIItem = (payload) => ({
    type: actions.CHANGE_KPI_ITEM,
    payload
})

/******************************* ADD_ITEM *******************************/
/**
 * изменить экземпляр kpi
 */
export const createKPIItem: SagaAction.CAddKPIItem = (payload) => ({
    type: actions.CREATE_ITEM,
    payload
})


/**
 * изменить экземпляр kpi
 */
export const deleteKPIItem: SagaAction.CDeleteKPIItem = (payload) => ({
    type: actions.DELETE_KPI_ITEM,
    payload
})




/********************************************************/
/******************** to reducer ************************/
/********************************************************/


/******************************* SET_NEWS *******************************/
/**
 * получить список kpi для указаного пользователя
 */
export const setKPIList: ReducerAction.CSetKPIList = (payload) => ({
    type: actions.SET_KPI_LIST,
    payload
})

/******************************* PATH_KPI_ITEM *******************************/
/**
 * изменить значения экземпляра
 */
export const pathKPIItem: ReducerAction.CPathKPIItem = (payload) => ({
    type: actions.PATH_KPI_ITEM,
    payload
})

/******************************* ADD_KPI_ITEM *******************************/
/**
 * 
 */
export const addKPIItem: ReducerAction.CAddKPIItem = (payload) => ({
    type: actions.ADD_KPI_ITEM,
    payload
})

/******************************* DROP_KPI_ITEM *******************************/
/**
 * 
 */
export const dropKPIItem: ReducerAction.CDropKPIItem = (payload) => ({
    type: actions.DROP_KPI_ITEM,
    payload
})






/********************************************************* */
/************************* COMMON ************************ */

/********************************************************* */

/**
 * 
 */
export const changeField: ReducerAction.CChangeField = (payload) => ({
    type: actions.CHANGE_FIELD,
    payload
})
