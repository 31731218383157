import { cnInfoBlock } from '../InfoBlock.index';
import { IInfoProps } from '../AbstarctInfoElement/AbstractInfoElement.index';

export interface IBadgeProps extends IInfoProps {
  badgeId: string;
  currencyFormats: any | {}
}

export interface IBadgeState {
  text?: string;
}

export interface IBadgeStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store,
  currencyFormats: state.store.appSettings 
})

export const cnBadge = (...data: any) => {
  let name = 'Badge';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnInfoBlock(name, ...data)
}

export const cnInfo = cnInfoBlock;