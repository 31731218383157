import { textTransform } from '@mui/system';
import { styled, Box, Typography, Paper } from 'muicomponents/src'
import { ItemsListHeader } from 'muicomponents/src/ItemsListDialog/ItemsListHeader/ItemsListHeader'

export const SearchWrapper = styled(Box)({
  // display: 'flex',
  // columnGap: 24,
  margin: '24px 0'
}) as typeof Box;

export const MarginForBox = styled(Box)({
  margin: "24px"
}) as typeof Box;


export const SearchTitle = styled(Typography)({
  fontSize: 20,
  fontWeight: 700
}) as typeof Typography;

export const SearchContentBox = styled(Paper)({
  padding: 24,
  flex: '0 1 100%',
  height: '100%',
  ".CommonmarkRender_type_inline": {
    wordBreak: "break-word",
}
}) as typeof Paper;

export const SearchTabsBox = styled(Paper)({
  padding: 24,
  // position: 'sticky',
  // top: 80,
  // right: 0,
  height: '100%',
  flex: '0 0 315px',
  boxSizing: 'border-box'
}) as typeof Paper;

export const SerchWithFiltersBox = styled(Paper)({
  background: "#fff",
  padding: "16px",
  '.ItemsListHeader-SearchBox': {
    columnGap: "inherit",
    marginBottom: 0,
    alignItems: 'flex-start',
    ".MuiFormControl-root.MuiTextField-root": {
      marginBottom: 0,
    },
    ".MuiInputBase-root.MuiOutlinedInput-root": {
      borderRadius: "4px 0 0 4px"
    },
    ".MuiButtonBase-root.MuiButton-root": {
      borderRadius: "0 4px 4px 0 ",
      height: "2.51em;",
      minWidth: "200px",
      fontSize: "16px",
      justifyContent: "space-between",
      "div": {
        textTransform: "capitalize"
      }

    }
  },
  ".ItemsListHeader-HeaderBottomContent": {
    paddingBottom: 0,
    ".MuiInputBase-root.MuiOutlinedInput-root": {
      height: "35px",
      padding: "3px 8px",
      fontSize: "16px",
      textTransform: "inherit",
      minWidth: "200px"

    },
    ".MuiButtonBase-root.MuiButton-root": {
      height: "35px",
      padding: "3px 8px",
      fontSize: "16px",
      textTransform: "inherit"
    }
  }

}) as typeof Paper;


