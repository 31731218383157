
import React, { FC, useState } from 'react'
import { IRepostFormProps, IStateProps, mapStateToProps } from './RepostForm.index'
import './RepostForm.scss'
import { Button, Input, Icon, Dialog, CustomScrollbars, DialogContent, CheckboxInput } from 'uielements/src';
import { NewsEdit } from 'blocks/NewsEdit_new/NewsEdit'
import { defaultRepostNews } from 'blocks/NewsTypes/Repost/Repost.type'
import { v1 as uuid } from 'uuid';
import i18n from 'localizations/i18n';
import { connect } from 'react-redux';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator'
import NewsItem from 'News/containers/NewsItem'
import BaseView from 'News/containers/BaseView'
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'

// import { NewsView } from 'blocks/NewsView/BaseView/BaseView'

export const RepostFormPresenter: FC<IRepostFormProps & IStateProps> = ({
    user,
    newsId,
    currencyName,
    handleAccept,
    handleClose
}) => {
    const onClose = () => {

    }
    return (
        <div>
            <DialogTitle onClose={handleClose}>
                {/* <span className={'ReasonThanksSelector-Title'}> */}
                    Публикация репоста
                    {/* </span> */}
            </DialogTitle>
            <div className={'RepostForm'}>
                <NewsEdit
                    create
                    // disableAttach
                    successMsg={'Поделиться публикацией'}
                    failMsg={'Произошла ошибка'}
                    cancleEdit={handleClose}
                    compliteEdit={() => { handleAccept({}) }}
                    defaultData={{ ...defaultRepostNews, user: user.baseData, id: uuid(), parentGuid: newsId }}
                    type={'createRepost'} >
                    <div className={'RepostForm-RepostedNews'}>
                        <NewsItem id={newsId} ViewComponent={BaseView} />
                    </div>
                </NewsEdit>
            </div>
        </div>
    )
}



export const Connected = connect(mapStateToProps)(RepostFormPresenter);

export default createModal<any, IRepostFormProps>(Connected, {
    maxWidth: 'sm',
    PaperProps: {
        style: {
            backgroundColor: 'transparent',
            overflowY: 'hidden'
        }
    },
    TransitionComponent,
    scroll: 'body'
});