import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ISkill } from 'redux/sagas/Skills/types'
///////////

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace SkillsHeaderInterface {
    // eslint-disable-next-line @typescript-eslint/no-namespace

    export type IPresenterProps = IOwnProps

    export interface IState { }

    export interface IOwnProps extends IClassNameProps {
        showControls: boolean;
        showCreate: boolean;
        onComplete: (createdTitle?: string, createArr?: ISkill[]) => void;
        onDelete: (deleteArr: ISkill[]) => void;
        onCancle: () => void;
        withError: boolean;
        currentUser: any;
        skills: ISkill[]
    }
}

////////////

export const cnClassName = cn('SkillsHeaderMui');