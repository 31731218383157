import actions from 'redux/actionsTypes/Quiz'
import * as utils from 'utils/src/utils'
import { IStateType as IState } from 'redux/store';


// start quiz widget AC
export const setQuiz = (payload: string) => {
  return {
    type: actions.SET_QUIZ,
    payload
  }
}

export const changeActiveQuizIdx = (payload: number) => {
  return {
    type: actions.CHANGE_ACTIVE_QUIZ_IDX,
    payload
  }
}
// end quiz widget AC

// start quiz AC
export const setOpenQuiz = (payload: string) => {
  return {
    type: actions.SET_OPEN_QUIZ,
    payload
  }
}

export const setStartedQuiz = (payload: boolean) => {
  return {
    type: actions.SET_STARTED_QUIZ,
    payload
  }
}

export const nextActiveQuestion = () => {
  return {
    type: actions.NEXT_ACTIVE_QUESTION
  }
}

export const setAnswerId = (payload: { id: string, single: boolean }) => {
  return {
    type: actions.SET_ANSWER_ID,
    payload
  }
}

export const setIsAnswered = (payload: boolean) => {
  return {
    type: actions.SET_IS_ANSWERED,
    payload
  }
}

export const setFinishedQiuz = () => {
  return {
    type: actions.SET_FINISHED_QUIZ
  }
}

export const resetQuiz = () => {
  return {
    type: actions.RESET_QUIZ
  }
}

const setLoadingQuiz = (payload: boolean) => {
  return {
    type: actions.SET_LOADING_QUIZ,
    payload
  }
}

export const setUserResult = (payload: string) => {
  return {
    type: actions.SET_USER_RESULT,
    payload
  }
}

export const setIsShowResult = (payload: boolean) => {
  return {
    type: actions.SET_IS_SHOW_RESULT,
    payload
  }
}

const setServerAnswered = (payload: boolean) => {
  return {
    type: actions.SET_SERVER_ANSWERED,
    payload
  }
}
const setRewsrdIsPosted= (payload: boolean) => {
  return {
    type: actions.SET_REWARD_IS_POSTED,
    payload
  }
}

// end quiz AC

// start quiz widget requests
export function getQuiz() {
  return (dispatch: any) => {
    utils.API.quiz.getQuiz()
      .r
      .then((r: any) => {

        if (r.error_code === 0) {
          dispatch(setQuiz(r.data));
        }
      })
  }
}
// end quiz widget requests

// start quiz requests
export function getOpenQuiz(quizId: string) {
  return (dispatch: any) => {
    dispatch(setLoadingQuiz(true))
    utils.API.quiz.getOpenQuiz(quizId, { checkAttemptCount: true })
      .r
      .then((r: any) => {
        if (r.error_code === 0) {
          let results: any = {}
          r.data.results.map((result: any) => {
            results[result.score] = result
          })

          const data = {
            ...r.data,
            results
          }
          if (data.selectRandomQuestionCount > 0) {
            data.questions = data.questions.filter((question: any, idx: number) => idx < data.selectRandomQuestionCount && question)
          }
          dispatch(setOpenQuiz(data))
          dispatch(setRewsrdIsPosted(r.user_data.rewardIsPosted))
          dispatch(setLoadingQuiz(false))
        }
        if (r.error_code === 2000) dispatch(setOpenQuiz(r))
        dispatch(setLoadingQuiz(false))
      })
  }
}

export function sendAnswer(resultId: string) {
  return (dispatch: any, getState: () => IState) => {
    dispatch(setIsAnswered(true))
    dispatch(setServerAnswered(false))
    const quizState = getState().quiz.quiz
    const data = {
      id: quizState.openQuizData.questions[quizState.activeQuistionIdx].id, // quistionId
      quizId: quizState.openQuizData.id, // quizId
      model: {
        id: quizState.openQuizData.questions[quizState.activeQuistionIdx].id, //questionid
        answers: quizState.answersIds.map((id: string) => ({ id, isChecked: true }))
      }
    }

    utils.API.quiz.passingQuiz(resultId, data)
      .r.then((r: any) => {
        if (r.error_code === 0) {
          dispatch(setServerAnswered(true))
          if (r.data !== null) {
            dispatch(setUserResult(r.data))
            dispatch(setIsShowResult(true))
            // dispatch(setServerAnswered(false))
          }
        }
        if (r.error_code !== 0) {
          dispatch(setServerAnswered(false))
        }
      })
  }
}

// end quiz widget requests