// import { IClassNameProps } from '@bem-react/core';
// import { cn } from '@bem-react/classname';

// export interface IAuthorsListProps extends IClassNameProps{
//     tag?: 'div';
// }

// export interface IAuthorsListState {

// }

// export const cnAuthorsList = cn('AuthorsList');

import { cnAuthorsList as cAL, IAuthorsListProps as IALP, IAuthorsListState as IALS} from 'uielements/src/WikiParts/WikiAuthorList/WikiAuthorList.index'
export type IAuthorsListProps = IALP
export type IAuthorsListState = IALS
export const cnAuthorsList = cAL