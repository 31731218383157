import * as types from './actions.d' 
import actions from './actionsType'

export const participantAction: types.CParticipantAction = (payload) => ({
    type: actions.EVENT_PARTICIPANT,
    payload
})

export const loadParticipant: types.CLoadParticipant = (payload) => ({
    type: actions.LOAD_PARTICIPANT,
    payload
})

export const setMeeting: types.CSetMeeting = (payload) => ({
    type: actions.SET_MEETING,
    payload
})