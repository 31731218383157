import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { getLastCompletedUnitId, getCompletedSessions, getCourseById, getUnstartedSessions } from '../../../redux/sagas/LMS/selectors'
// import { ICourse } from '../mock';
// import { loadCategories } from "../../../redux/actions/LMS";

export interface IUnitsListProps extends IClassNameProps, IUnitsListStateProps, IDispatchProps, IUnitsListDispatchProps {
    tag?: 'div';
}

export interface ISearchState {

}

export interface IUnitsListStateProps {
    // categories: any;
    // cIds: string[]
}

export const mapStateToProps = (state: any, props: any) => ({
    lastCompletedUnitId: getLastCompletedUnitId(props.cid)(state),
    completedSessions: getCompletedSessions(props.cid)(state),
    currentSessionId: getCourseById(props.cid)(state).courseSessionId,
    unstartedSessions: getUnstartedSessions(props.cid)(state)
    // categories: state.LMS.categories.entities.values,
    // cIds: state.LMS.categories.result,
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    // loadCategories,
}, dispatch);

export type IUnitsListDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnUnitsList = cn('UnitsList');