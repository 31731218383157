import Component, { Presenter } from './component';
import {
    ITaskResultOwnProps,
    ITaskResultProps,
    cnClassName
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = ITaskResultOwnProps;
export type IComponentPresenterProps = ITaskResultProps;


export default Component