import { NoticesNews } from './Notices.type'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { NewsContextProps } from 'News/contexts/news'
import {
    getNewsParamsById
} from 'News/redux/saga/selectors'

export interface INoticesPropsType extends NewsContextProps {
}

export type TStateProps = ReturnType<typeof mapStateToProps>


const newsParams = [
    'text'
] as const

export const mapStateToProps = (state: any, { newsId }: INoticesPropsType) => ({
    userData: getCurrentUser(state),
    ...getNewsParamsById<NoticesNews, typeof newsParams[number]>(newsId, newsParams)(state)
})
