import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IItemProps extends IClassNameProps {
    tag?: 'div';
    author: any;
    size?: number;
    fixedToolTip?: boolean
}

export interface IItemState {
}

export const cnAuthorItem = cn('AuthorItem');