import { getGroupByPKID } from 'redux/sagas/Groups/selectors'
export interface IPhotoView extends IBasePhotoViewProps {

}

export interface IBasePhotoViewProps {
    aid: string;
    id: string;
    gid: string;
    fname?: string;
    open: boolean;
    onClose: () => void;
}

export interface IPhotoViewStateProps {
    count: number;
    showControls?: boolean;
    result: string[]
    files: any
    selected: string[]
    isFinished: boolean
    isLoading: boolean
    currentFile: any
}

const noopObj: any = {}
const noopArray: any[] = []

export const mapStateToProps = (state: any, props: IBasePhotoViewProps) => {
    let files = noopObj
    let result = noopArray
    let selected = noopArray
    let isFinished = false
    let isLoading = false
    let currentFile: any = undefined

    let count = 0;
    let showControls = false;
    if (props.aid in state.photoalbums.photos) {
        let { files, result, selected, isFinished, isLoading } = state.photoalbums.photos[props.aid];
        count = result.length;
        showControls = (props.gid in state.groups.groups) ? getGroupByPKID(props.gid)(state).isGroupAdmin : showControls

        currentFile = state.photoalbums.photos[props.aid].files[props.id];
    }
    
    
    return { count, files, selected, result, isFinished, isLoading, showControls, currentFile }
}