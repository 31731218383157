import React, { FC, useMemo } from 'react'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import i18n from 'localizations/i18n';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { Translate } from 'localizations/Translate';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as utils from 'utils/src/utils';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import { Button, CheckboxInput, Loading, Input as PryanikyInput } from 'uielements/src';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noEditblField: {
            textDecoration: "none",
            margin: "25px 0 0 0",
            borderBottom: "1px solid #999",
            width: "100%"
        },
        noEditblFieldSpan: {
            color: "#999",
            fontSize: "13px"
        },
        noEditblFieldP: {
            margin: "0"
        },
        formControl: {
            flex: '1 1 100%',
            marginTop: '16px',
        },
        noEditblFieldDivTags: {
            display: "flex",
        },
        noEditblFieldTags: {
            width: "fit-content",
            padding: "0 11px",
            margin: "0 5px 0 0",
            background: "#E3E3E3",
            borderRadius: "24px",
        },
    })
);


export const Interests: FC<any> = ({ field, fieldId, value, tags, onChangeFields, fieldsSet }) => {
    const classes = useStyles();
    return (
        field && field.isReadOnly ? 
            <HelpTooltip help={i18n.t('pryaniky.profile.only.Admin.helptooltip')} className={classes.noEditblField}>
                <span className={classes.noEditblFieldSpan}>
                    <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                </span>
                <div className={classes.noEditblFieldDivTags}>
                    {
                        Object.values(tags).map((tags: any) => <Button type="rlink" noBorder className={classes.noEditblFieldTags} href={"/tag/" + tags.id}>{"#" + tags.displayName}</Button>)
                    }
                </div>
            </HelpTooltip> : <FormControl key={fieldId} id={fieldId} component="fieldset" className={classes.formControl}>
                <Autocomplete
                    disabled={field.isReadOnly}
                    allowCreate={!field.selectOnlyFromCollection}
                    fullWidth
                    type={'tags'}
                    col={'profile'}
                    fieldId={fieldId}
                    label={<Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />}
                    value={tags}
                    onChange={onChangeFields(fieldId, 'tags', fieldsSet)}
                />
            </FormControl>
    )
}

export default Interests