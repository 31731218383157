import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { IWSettingsOnlyProps } from 'blocks/WSettings/WSettings.index'
import { IClassNameProps } from '@bem-react/core';
import React, { } from 'react'
import { EditorState, RawDraftContentState, convertFromRaw, convertToRaw } from 'draft-js'
export interface IOwnProps extends IWSettingsOnlyProps, IClassNameProps {
    setBadge: React.Dispatch<any>,
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
    pwd: string,
    state: {
        plainText: any;
        buttonText: any;
        id: any;
        img: IImage
        showImg : boolean
        hideAfterOpening?: boolean
    },
    badge: any,
    editorState: EditorState,
    setState: React.Dispatch<React.SetStateAction<TStateSecret>>,
    setPwd: React.Dispatch<React.SetStateAction<string>>
    secertResult: any
    isLoadingSecret: boolean
    showSecretData: boolean
}

export type TStateSecret = {
    plainText: any;
    buttonText: any;
    id: any;
    img: IImage
    showImg : boolean
    hideAfterOpening?: boolean
    noPassword?: boolean

}
export interface IImage {
    id: string | null
    originalUrl: string | null
    previewUrl?: string | null

}
////////////

export const cnClassName = cn('SecretWidgetSetting');