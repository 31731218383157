import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from '../../../redux/store';


export interface IDistributeTimer extends IDistributeTimeOwnProps, IDistributeTimerStateProps, IDistributeTimerDispatchProps {
    tag?: any
    settings?: any
    className?: string
    store?: any
    changeVm?: any,
    distibDate: string ,
    shouldByDistribute?: ()=>void;
}
export interface IDistributeTimeOwnProps{
    
}
export const mapStateToProps = (state: IState) => {

  return {
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export type IDistributeTimerStateProps = ReturnType<typeof mapStateToProps>
export type IDistributeTimerDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnDistributeTimer = cn('DistributeTimer')