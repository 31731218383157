import { cn } from "@bem-react/classname";
import { bindActionCreators, Action, Dispatch } from "redux"
import { IBodyProps } from 'utils/src/DialogCreator';
import actions from 'utils/src/CommonRedux/base/actionsTypes';
import { IFeedback } from '../../../News/types/baseNews'
export interface IFeedbackDialogProps extends IFeedbackDialogOwnProps, IBodyProps, IFeedbackDialogDispatchProps, IFeedbackDialogStateProps {
  

}
export interface IFeedbackDialogOwnProps { 
    feedback: IFeedback
};

export type IFeedbackDialogDispatchProps = ReturnType<typeof mapFeedbackDialogDispatchToProps>

export const mapFeedbackDialogDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    changeVm: (propPath: string, value: any) => dispatch({
        type: actions.CHANGE_STATE, payload: { propPath, value } 
      })
}, dispatch);

export type IFeedbackDialogStateProps = ReturnType<typeof mapFeedbackDialogStateToProps>;

export const mapFeedbackDialogStateToProps = () => ({});

export const cnFeedbackDialog = cn("FeedbackDialog");