// import {User, BaseNews, defaultBaseNews,  defaultUser, defaultGroup, Group} from '../BaseType'
import { IBaseNews, createDefaultNews } from 'News/types/baseNews'
export interface PagesV2News extends IBaseNews {
    newstype: 38;
    learningTrack: {
        imgUrl: null | string,
        name: string
        objectId: string
        type: string
        url: string
    }
}

export const defaultPagesV2News: PagesV2News = {
    ...createDefaultNews(),
    newstype: 38,
    learningTrack: {
        imgUrl: null,
        name: '',
        objectId: '',
        type: '',
        url: ''
    }
}

export const createDefaultModel = (): PagesV2News => ({
    ...createDefaultNews(),
    newstype: 38,
    learningTrack: {
        imgUrl: null,
        name: '',
        objectId: '',
        type: '',
        url: ''
    }
})