/**
 * @packageDocumentation
 * @module Widget_Settings
 */
import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
// import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";
// import i18n from '../../localizations/i18n';

import { mapStateToProps, cnWSettings, IWSettingsProps, IWSettingsStateProps, IWSettingsState, IWSettingsOnlyProps, mapDispatchToProps } from './WSettings.index';
import './WSettings.scss';
// import 'blocks/JsonFormsRenderers';
// import { withWSettingsTypeBadges } from './_type/WSettings_type_badges';
// import { withWSettingsTypeList } from './_type/WSettings_type_list';
// import { withWSettingsTypeBadges } from './_type/WSettings_type_badges';

import { IWidget } from 'i.widgets';
import { Actions } from '@jsonforms/core';
import { JsonFormsDispatch, JsonFormsReduxContext } from '@jsonforms/react';
import { store } from 'redux/store';
import * as UIElements from 'uielements/src';
import structures, { getStructure } from './structures/index';
import { additionalSchemaData } from './structures/timeline/schema';
import { rankWith, uiTypeIs, scopeEndsWith, update as JsonFromsDataUpdate, mapStateToLayoutProps } from '@jsonforms/core';
import { MaterialLayoutRenderer } from "@jsonforms/material-renderers";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Hidden,
  Typography
} from "@material-ui/core";

export class WSettingsPresenter extends React.Component<IWSettingsProps, IWSettingsState> {
  // public utils: any = utils;
  public el: Element | Text | null;

  constructor(props: IWSettingsProps) {
    super(props);
    require('blocks/JsonFormsRenderers');
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {};
    if (props.selected) this.setStructure();
  }



  public componentDidUpdate(pp: IWSettingsProps) {
    if (!utils.compareData(pp.selected, this.props.selected)) this.setStructure();
  }

  private setStructure = () => {
    let { schema } = this.props.selected;
    const structure = getStructure(schema);
    if (structure.prepareData) {
      const loader = getStructure('loader');
      store.dispatch(Actions.init(
        loader.data,
        loader.schema,
        loader.uischema
      ));
      structure.prepareData(this.props.data);
    } else {
      let data = this.props.data;
      if (schema === 'license') {
        if (!data) {
          data = {
            showMobileLicense: window.location.pathname.indexOf('/welcome')
          };
        } else {
          data.showMobileLicense = window.location.pathname.indexOf('/welcome');
        }
      }
      if (data) {
        store.dispatch(Actions.init(
          Object.keys(structure.data).reduce((acc, cur) => ({ ...acc, [cur]: data && data[cur] }), {} as any),
          structure.schema,
          structure.uischema
        ));
      } else {
        store.dispatch(Actions.init(structure.data, structure.schema, structure.uischema));
      }
    }
  }

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', children, className = '', onChange } = this.props;
    return (
      <TagName ref={el => this.el = el} className={cnWSettings({}, [customClassNames, className])}>
        <JsonFormsReduxContext>
          <JsonFormsDispatch onChange={onChange} />
        </JsonFormsReduxContext>
        {children}
      </TagName>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const WSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    // withWSettingsTypeBadges,
    // withWSettingsTypeList
    // withWSettingsTypeBadges
  )(WSettingsPresenter)
)