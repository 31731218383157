import { PreviewParams } from 'utils/src/PreviewParams'
import { put, takeLeading, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  call,
  select,
} from 'utils/src/saga.effects';

import { toast } from 'react-toastify';
import i18n from 'localizations/i18n';
import { IResponseWithData } from 'utils/src/requests/models/api.base';
import { Characteristics } from 'utils/src/requests/models/api.shop';


import {
  SHOP_GET_SHOP_PRODUCT,
  setShopProduct,
  SHOP_MAKE_SHOP_ORDER,
  SHOP_GET_SHOP_CAT_PRODUCTS,
  SHOP_GET_SHOP_CHARACTERISTICS,
  SHOP_CHECK_CHARACTERISTICS,
  setShopProducts,
  setShopLoading,
  clearShopCatProducts,
  pathOpenProduct,
  SHOP_SET_SHOP_CHOSEN_FILTERS,
  setShopFilters,
  SHOP_CLEAR_SHOP_FILTERS,
  resetFilters,
} from './actions';

import {
  IShopGetShopItemAction,
  IShopMakeShopOrderAction,
  IShopGetShopProductsAction,
  IShopCheckCharacteristics,
  IShopGetShopCharacteristicsAction,

} from './actions.interfaces';

import {
  IShopProductResponse,
  IShopProductsResponse
} from 'utils/src/requests/models/api.shop';

import {
  getProductRequest,
  order as makeOrderRequest,
  getProductsRequest,
  checkCharacteristics,
  getProductsRequestNewApi,
  getAllProductsCharacteristics,
} from 'utils/src/requests/requests.shop';

import { checkResponseStatus } from 'utils/src/utils';
import { IBasicResponse, IBasicResponseGeneric } from 'utils/src/requests/models/api.base';
import { Characteristics as CharacteristicsTypes } from 'utils/src/requests/models/api.shop'

import { currentUserMinusMyThanksCount } from 'utils/src/CommonRedux/users/actions';
import {
  appendNewsList,
  pathNews
} from 'News/redux/actions'
import * as SELNews from 'News/redux/saga/selectors'

import {
  normalizeNews
} from 'News/redux/saga/utils'

import { pickSelected, setSelectedTags, setUnselectedAll } from 'blocks/Shop/utils'

import { withSagaIndicator } from 'utils/src/CommonRedux/LoadIndicator'
import { getShopCategoryProducts, getShopProductSelector, selectChoosenFilters } from './selectors'
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { CustomSettings } from 'utils/src';
import { difference, flatMapDeep, flattenDeep, pick, toPairs } from 'lodash';
import { replace, push, LOCATION_CHANGE, LocationChangeAction, getLocation } from 'connected-react-router';
import queryString from 'query-string';
import { ConectedSessionLoader } from 'LMSModule/LMS/Single/Course';
import urls from 'routes/urls';
import { prepareLocationSearchToFilters } from './shop.utils';


const getShopProduct = withSagaIndicator(function* (action: IShopGetShopItemAction) {
  try {
    // yield put(setShopProduct({ loading: true }))
    const data: IShopProductResponse = yield* call(getProductRequest, action.payload);
    if (checkResponseStatus(data)) {

      if (data.data.news) {
        yield put(appendNewsList({
          ...normalizeNews([data.data.news]),
          group: 'shop_comment_news'
        }))
      }

      // const currentIndex = data.data.imgUrls.indexOf(data.data.mainImgUrl)
      const currentFile = data.data.images.find(item => {
        let guid = data.data.mainImgId && data.data.mainImgId !== GUID_EMPTY ? data.data.mainImgId : null;
        if (!guid) {
          const tails = data.data.mainImgUrl.split('/')
          const last = tails[tails.length - 1]
          guid = last.split('.')[0]
        }
        return item.id === guid
      }) || data.data.images[0]//data.data.images[currentIndex] || data.data.images[0]

      yield put(setShopProduct({
        data: data.data,
        currentImgUrl: currentFile.previewUrl,
        currentImage: currentFile,
        isHidePricesAndButtonBuy: data.isHidePricesAndButtonBuy,
        isHideButtonBuy: data.isHideButtonBuy,
        maxOrdersCount: data.maxOrdersCount,
        maxOrdersPeriod: data.maxOrdersPeriod,
        ordersCount: data.ordersCount,
        additionalInformationBeforeOrder: data.additionalInformationBeforeOrder,
        selectedCharsCount: undefined
      }));
    } else {
      if (data.data === null) {
        yield put(replace('/shop'))
        throw new Error("data_null");

      }
    }
    // yield put(setShopProduct({ loading: false }))

  } catch (e) {
    console.warn('get shop product from server error', e);
  }
}, SHOP_GET_SHOP_PRODUCT)

function* makeShopOrder(action: IShopMakeShopOrderAction): any {
  try {
    const { id, comment, presentFor, thanksPrice, characteristics, additionalFields } = action.payload;
    const request = yield* call(makeOrderRequest, id, { comment, presentFor, characteristics, additionalFields });
    const response: IBasicResponse = yield request.r;
    if (checkResponseStatus(response)) {
      yield put(currentUserMinusMyThanksCount(thanksPrice) as any);
      const product = (yield* select(getShopProductSelector)).data;
      if (!product) return;
      yield put(pathOpenProduct({
        key: 'characteristics',
        value: setUnselectedAll(product.characteristics)
      }))

      toast.success(i18n.t('pryaniky.shop.order.success'));
    } else {
      toast.error(response.error_text);
    }
  } catch (e) {
    console.warn('make shop order error', e);
  }
}


const getShopProducts = withSagaIndicator(function* (action: IShopGetShopProductsAction) {
  try {
    yield put(setShopLoading({ finished: false, loading: true }));
    // yield put(clearShopCatProducts());
    const oldData = (yield* select(getShopCategoryProducts))?.data || []
    const { count, skipCount } = action.payload
    const response: any = yield* call(getProductsRequestNewApi, action.payload, count, skipCount);
    const fullData = oldData.concat(response.data)
    if (checkResponseStatus(response)) {
      yield put(setShopProducts({
        data: fullData,
        filters: response.filters,
        isHidePricesAndButtonBuy: response.isHidePricesAndButtonBuy,
        isHideButtonBuy: response.isHideButtonBuy,
        ordersCount: response.ordersCount,
        maxOrdersCount: response.maxOrdersCount,
        maxOrdersPeriod: response.maxOrdersPeriod,
        additionalInformationBeforeOrder: response.additionalInformationBeforeOrder,
        isShopClosed: response.isShopClosed,
        shopClosedText: response.shopClosedText,
        allowToMeByPriceCount: response.allowToMeByPriceCount,
        myFavoritesCount: response.myFavoritesCount,
      }));

      yield put(setShopLoading({ finished: response.isFinished, loading: false }))
    }
  } catch (e) {
    console.warn('get shop products from server error', e);
  }
}, SHOP_GET_SHOP_CAT_PRODUCTS)

/**
 * получение всех характеристик всех товаров для фильтрации 
 * 
 * 
 */
const getShopCharacteristics = withSagaIndicator(function* () {
  try {
    //yield put(setShopLoading({ finished: false, loading: true }));
    const response: IResponseWithData<Characteristics.IItem[]> = yield* call(getAllProductsCharacteristics, true);
    if (checkResponseStatus(response)) {
      yield put(setShopProducts({
        shopCharacteristics: response.data,
      }));
      //yield put(setShopLoading({ finished: response.isFinished, loading: false }))
    }
  } catch (e) {
    console.warn('get shop characteristics from server error', e);
  }
}, SHOP_GET_SHOP_CHARACTERISTICS)


/**
 * оправляем на сервер выбранные характериски для проверки. 
 * в ответ получаем новый список характеристик с изменёным состоянием isDisabled e ntujd
 * @param param0 
 */
function* checkCharacteristicsSaga({ payload }: IShopCheckCharacteristics) {
  // try {
  const { id, characteristics } = payload
  const selectedOnly = pickSelected(characteristics)
  const nothingSelected = !flattenDeep(selectedOnly.map(el => el.tags)).length;
  yield put(pathOpenProduct({
    key: 'characteristics',
    value: characteristics
  }));

  const response: IBasicResponseGeneric<CharacteristicsTypes.ICheckResponse> = yield* call(checkCharacteristics, id, selectedOnly);

  if (checkResponseStatus(response)) {
    const value = setSelectedTags(response.data.characteristics, selectedOnly)
    yield put(pathOpenProduct({
      key: 'characteristics',
      value
    }))
    yield put(pathOpenProduct({
      key: 'thanksPrice',
      value: response.data.price
    }))
    if (response.data.priceTxt)
      yield put(pathOpenProduct({
        key: 'thanksPriceTxt',
        value: response.data.priceTxt
      }))
    yield put(setShopProduct({
      currentImgUrl: response.data.imgUrl,
      currentImage: response.data.image,
      allowOrder: response.data.allowOrder,
      errorMessage: response.data.errorMessage,
      selectedCharsCount: nothingSelected
        ? undefined
        : response.data.errorMessage === 'OutOfStock'
          ? 0
          : response.data.count
    }));
  } else {
    throw new Error('requestError');
  }
  // } catch (e) {
  // console.warn('checkCharacteristicsSaga', e);
  // }
}

const indicatedCheckCharacteristic = withSagaIndicator(checkCharacteristicsSaga, SHOP_CHECK_CHARACTERISTICS)

// ?favorites=true&catFilters=67,34&minPrice=10&maxPrice=20&allowByPrice=true&characteristics=5211[2ad4bc2f-2462-4579-a018-7468f495e2f3]&sort=OrderByPrice_asc
function* setChoosenFiltersToURl(action: ReturnType<typeof resetFilters> | ReturnType<typeof setShopFilters>) {
  const selectedFilters = yield* select(selectChoosenFilters);
  const objectForGenerate: {[key: string]: any} = {};
  if(selectedFilters.MyFavorite) {
    objectForGenerate.favorites = selectedFilters.MyFavorite;
  }
  if(selectedFilters.catFilters) {
    objectForGenerate.catFilters = selectedFilters.catFilters;
  }
  if(selectedFilters.MinPrice) {
    objectForGenerate.minPrice = selectedFilters.MinPrice;
  }
  if(selectedFilters.MaxPrice) {
    objectForGenerate.maxPrice = selectedFilters.MaxPrice;
  }
  if(selectedFilters.AllowToMeByPrice) {
    objectForGenerate.allowByPrice = selectedFilters.AllowToMeByPrice;
  }
  if(selectedFilters.characteristics) {
    objectForGenerate.characteristics = flatMapDeep(Object.typedKeys(selectedFilters.characteristics).map(cId => {
      return selectedFilters.characteristics[cId].map(el => `${cId}[${el.id}]`);
    }));
  }
  if(selectedFilters.sorting && Object.values(selectedFilters.sorting).filter(Boolean).length) {
    objectForGenerate.sort = toPairs(selectedFilters.sorting).reduce((a, [name, value]) => {
      if(a) return a;
      if(value) return `${name}_${value}`;
      return a;
    }, '');
  }
  const currentLocation = yield* select(getLocation);
  const currentLocationSearchObject = queryString.parse(currentLocation.search.substring(1), { arrayFormat: 'comma' });
  const othersKeys = difference(Object.typedKeys(currentLocationSearchObject), Object.typedKeys(objectForGenerate));

  // pick from current location filters keys and map to string
  const currentPrepairedString = queryString.stringify(pick(currentLocationSearchObject, Object.typedKeys(objectForGenerate)), { arrayFormat: 'comma', encode: false });
  let prepairedString = queryString.stringify(objectForGenerate, { arrayFormat: 'comma', encode: false });
  // push location search change if filters will change
  if(currentPrepairedString !== prepairedString) {
    if(othersKeys.length && action.type !== SHOP_CLEAR_SHOP_FILTERS) {
      prepairedString = queryString.stringify({ ...objectForGenerate, ...pick(currentLocationSearchObject, othersKeys)}, { arrayFormat: 'comma', encode: false });
    }
    yield put(push({
      search: prepairedString
    }));
  }
};

let fromPath = '';

function* setReactRouteChoosenFilters(action: LocationChangeAction) {
  try {
    if(
      action.payload.isFirstRendering
      || !action.payload.location.pathname.startsWith(urls.shop)
      || (
        fromPath.startsWith(urls.shop)
        && action.payload.location.pathname.startsWith(urls.shop)
        && action.payload.action !== 'POP'
      )
    ) {
      fromPath = action.payload.location.pathname;
      return;
    }
    fromPath = action.payload.location.pathname;
    const filters = prepareLocationSearchToFilters(action.payload.location.search);
    yield put(setShopFilters(Object.typedKeys(filters).map(key => (({
      key,
      value: filters[key]
    })))));
  } catch (e) {
    console.error(`setReactRouteChoosenFilters: `, e)
  }
}

const shop = function* shop() {
  yield takeLeading(SHOP_GET_SHOP_PRODUCT, getShopProduct);
  yield takeLeading(SHOP_MAKE_SHOP_ORDER, makeShopOrder);
  yield takeLeading(SHOP_GET_SHOP_CAT_PRODUCTS, getShopProducts);
  yield takeLeading(SHOP_GET_SHOP_CHARACTERISTICS, getShopCharacteristics);
  yield takeLeading(SHOP_CHECK_CHARACTERISTICS, indicatedCheckCharacteristic);
  yield takeLatest([SHOP_SET_SHOP_CHOSEN_FILTERS, SHOP_CLEAR_SHOP_FILTERS], setChoosenFiltersToURl);
  yield takeLatest(LOCATION_CHANGE, setReactRouteChoosenFilters);
}

export default shop;
