import { ILearningTracksReducer } from '../../reducers/TracksManage'
/**
 * 
 * declarate store selectors
 */
type stateType = { learningTracksReducer: ILearningTracksReducer }//затычка

/**
 * получить корневое состояние управления треками
 * @param state 
 */
export const getTracksManage = (state: stateType) =>
    state.learningTracksReducer;

/**
 * достать объект треков
 * @param state 
 */
export const getTracksObject = (state: stateType) =>
    getTracksManage(state).tracks

/**
* достать объект пользователей
* @param state 
*/
export const getUsersObject = (state: stateType) =>
    getTracksManage(state).users

/**
 * достать объекты треков
 * @param state 
 */
export const getTracksMap = (state: stateType) =>
    getTracksObject(state).data

/**
 * вернёт трек по указаному ид или undefined
 * @param id 
 */
export const getTrackById = (id: string) => (state: stateType) =>
    getTracksMap(state)[id]

/**
 * вернёт список id треков
 * @param state 
 */
export const getTracksIds = (state: stateType) =>
    getTracksObject(state).keys

/**
* вернёт список id выбраных треков
* @param state 
*/
export const getTracksSelected = (state: stateType) =>
    getTracksObject(state).selected


/**
* объект статистики по пользователям
* @param state 
*/
export const getStatUsersObject = (state: stateType) =>
    getTracksManage(state).statUsers


/**
* объект статистики по пользователям
* @param state 
*/
export const getStatMaterialsObject = (state: stateType) =>
    getTracksManage(state).statMaterials

    
/**
 * объект материалов
 * @param state 
 */
export const getFilesObject = (state: stateType) =>
    getTracksManage(state).files


/**
* достать объекты материалов
* @param state 
*/
export const getFilesMap = (state: stateType) =>
    getFilesObject(state).values

/**
 * вернёт список id материалов
 * @param state 
 */
export const getFilesIds = (state: stateType) =>
    getFilesObject(state).keys