/**
 * @packageDocumentation
 * @module Widget_Settings_sliderlink
 */
import { JFdictionary } from "../dictionary";
import { Translate } from './../../../../localizations/Translate'

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    src: {
      type: types.string,
      placeholder: Translate.t({ i18nKey: 'pryaniky.widgets.settings.placeholder.src'})
    },
    height: {
      type: types.number,
      placeholder: Translate.t({ i18nKey: 'pryaniky.widgets.settings.placeholder.height' }),
    },
    withAuth: {
      type: types.boolean
    },
  }
}
