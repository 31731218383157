export interface IReplyModel {
    actions: string[];
    attachments: any
    changeDate: string
    date: string
    header: string
    id: string
    likeCount: number
    liked: boolean
    parentCommentId: any
    permaLink: string
    text: string
    thanksInfo: any
    type: "default" | "thank"
    user: any
    contentRating?: null | number
    users?: any[]
    showLoadMore?: boolean
}

export const createDefaultReply = (): IReplyModel => ({
    id: '',
    text: '',
    header: '',
    liked: false,
    likeCount: 0,
    date: (new Date()).toISOString(),
    changeDate: (new Date()).toISOString(),
    user: {},
    attachments: [],
    actions: [],
    type: 'default',
    parentCommentId: null,
    permaLink: '',
    thanksInfo: null,
    contentRating: null
})