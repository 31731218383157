import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    loadNewsList,
    reset,
    setActiveNews,
    changeStatusField
} from '../../redux/actions'
import {
    TViewType
} from '../../redux/reducer/types'
import {
    getNewsKeys,
    getNewsParams,
    getNewsActiveByParams,
    getReadingState
} from '../../redux/saga/selectors'
import { INewsListRequestOpts } from 'utils/src/requests/models/api.news'
import queryString from 'query-string';
import { omit } from 'lodash'
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsSlider {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            params: INewsListRequestOpts;
            header: string
            hideNews?: boolean
            settings: {
                once?: boolean; // once time loading data
                viewType?: TViewType; // selected view type on widget settings,
                dateNow?: boolean;

            };
            context?: { [key: string]: any },
            widgetId?: string;
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.NewsSlider.IOwnProps
type IStateProps = News.NewsSlider.IStateProps

const newsParams = [
    'viewType', 'sending'
] as const


export const mapStateToProps = (state: any, props: IOwnProps) => ({
    ...getNewsParams(newsParams)(state),
    ...getReadingState(props.params)(state),
    keys: getNewsKeys(props.params)(state),
    active: getNewsActiveByParams(props.params)(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadNewsList,
    reset,
    setActiveNews,
    changeStatusField
}, dispatch);

////////////

export const cnClassName = cn('NewsSlider');
export const cnClassNameNew = cn('NewsSliderNew');
export const cnClassNameNoData = cn('NewsSliderNoData');