import * as React from 'react';
import { IEditorTypeEditProps } from './edit.index';
import { NewsNews } from '../../../News.type'
import { Editor } from '../../Editor'
import { BaseValidator, baseValidate } from '../../../../Base.validate'
export const EditorTypeEdit: React.FC<IEditorTypeEditProps> = ({ data, onChange, typeParams}) => {
    let localData = {...data};

    const onChangeText = (text: string) => {
        localData.text = text;
        const bv = new BaseValidator<any>()
        bv.setRules(baseValidate)
        bv.addData(localData)
        onChange(localData, bv.validation().length === 0);
    }

    return <Editor data={localData} onChangeText={onChangeText} allowRichEdit={typeParams?.newsRenderParams?.allowRichEdit} />

}