import { bindActionCreators } from 'redux';
import { loadList, uploadFiles, selectAllFiles, deleteFileMultiple } from "../../redux/actions/Files";
import { RouteComponentProps } from 'react-router';
import { cn } from '@bem-react/classname';
import { getGroupByPKID } from 'redux/sagas/Groups/selectors'
export interface IProps extends IBaseFileProps, IFileStateProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    gid: any;
}

export interface IBaseFileProps {
    gid: number;

}

export interface IFileStateProps {
    files: any[],
    count: number;
    isFinished: boolean;
    isLoading: boolean;
    // parentId?: boolean;
    selected: any[];
    filters: any;
    showControls: boolean;
}

export const mapStateToProps = (state: any, props: IBaseFileProps) => {
    let files = []
    let count = 0;
    let isFinished = false;
    let isLoading = false;
    let selected = [];
    let parentId: any = undefined;
    let filters = {};
    let showControls = false;
    if (props.gid in state.files.groups) {
        files = state.files.groups[props.gid].files.map((id: string) => state.files.files[id])
        count = files.length;
        selected = state.files.groups[props.gid].selected;
        // parentId = state.files.groups[props.gid].parentId;
        isFinished = state.files.groups[props.gid].isFinished;
        isLoading = state.files.groups[props.gid].isLoading;
        filters = state.files.filters
        showControls = (props.gid in state.groups.groups) ? getGroupByPKID(props.gid)(state).isGroupAdmin : showControls
    }
    return { files, count, selected, isFinished, isLoading, parentId, filters, showControls }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadList,
    uploadFiles,
    selectAllFiles,
    deleteFileMultiple
}, dispatch);

export const cnFiles = cn('Files');