import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps, } from 'react-router';
import {
    getUserStatInfoInfo
} from '../../../../redux/actions/LMSTeacherOffice'
import * as SEL from '../../../../redux/sagas/LMSTO/selectors'
import { ILoadingProps } from 'utils/src/CommonRedux/LoadIndicator'
///////////
export declare namespace StatisticsFull {
    export namespace User {
        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps, ILoadingProps, RouteComponentProps<{ id: string }> {
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////

type IOwnProps = StatisticsFull.User.IOwnProps

export const mapStateToProps = (state: any, { match }: IOwnProps) => ({
    settings: SEL.getFullStatisticSettings(state),
    user: SEL.getUserscoursesinfolistValueById(match.params.id)(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    getUserStatInfoInfo
}, dispatch);

////////////

export const cnClassName = cn('LMSTOStatFullUser');