import { bindActionCreators } from 'redux';
import { IStateType as IState } from 'redux/store';
import { cn } from '@bem-react/classname';
import {
  getCurrencyTimeline,
  changeCurrencyTimelineContext,
  resetDataCurrecyTimelline
} from 'redux/currencyTimeline/actions';
import { RouteComponentProps } from 'react-router-dom';
import { getUserByIdOrAlias } from 'utils/src/CommonRedux/users/selectors'

export interface ICurrencyTimelineProps extends IMapStateProps, IMapDispatchProps, RouteComponentProps<IParams> {
  currentUserId: string;
}

export const mapStateToProps = (state: IState, { match }: RouteComponentProps<IParams>) => {
  return {
    userId: getUserByIdOrAlias(match?.params?.id || '')(state)?.baseData?.id,
    data: state.currencyTimeline.data,
    finished: state.currencyTimeline.finished,
    loading: state.currencyTimeline.loading,
    context: state.currencyTimeline.context,
    userSex: getUserByIdOrAlias(match?.params?.id || '')(state)?.baseData?.sex,//state.users.users[match?.params?.id]?.baseData?.sex,
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getCurrencyTimeline,
  changeCurrencyTimelineContext,
  resetDataCurrecyTimelline
}, dispatch);

export interface IParams {
  id: string;
}

export type IMapStateProps = ReturnType<typeof mapStateToProps>;
export type IMapDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const cnCurrencyTimeline = cn('CurrencyTimeline');
