/**
 * @packageDocumentation
 * @module List_type_poll_answer_participants
 */
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from "../../../../redux/connector";

import { mapStateToProps, IListStateProps, IListProps, cnList, mapActionsToProps } from './../../List.index';
import { avalibleFilters } from './List_type_users.index';

import { ListUsersLikersPresenter } from './List_type_users_likers';
import { IListResponseWithData } from 'utils/src/requests/models/api.base';
import { IStateType as IState } from 'redux/store';

export class ListUsersAnswerParticipantsPresenter extends ListUsersLikersPresenter {
  public classMods: any = {
    answerParticipants: true
  }

  public static defaultProps: any = {
    requestOptions: {
      nId: '',
      aId: '',
      search: '',
      skipCount: 0,
      count: 20,
    }
  };

  public getData = () => {
    const { requestOptions, context, edit } = this.props;
    const { clearData, isLoading } = this.props;
    const { clearDataFromList, getPollAnswerParticipants, toggle } = this.props;
    const id = this.listId;
    if (edit || isLoading || !this.mounted) return;
    const opts = Object.assign({}, requestOptions);

    Object.keys(context).forEach((cKey: string) => {
      if (avalibleFilters[cKey]) opts[avalibleFilters[cKey]] = context[cKey];
    });

    // opts.nId = context.nId;
    // opts.aId = context.aId;

    if (this.searchString && this.searchString !== '') opts.search = this.searchString;
    toggle({
      id,
      data: {
        isLoading: true,
        isFinished: false
      }
    });
    if (clearData) clearDataFromList({ id, data: null });
    getPollAnswerParticipants.call(this, id, opts);
  }
}

export const List = connect<IListStateProps, IDispatchProps, IListProps, IState>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
)(ListUsersAnswerParticipantsPresenter)