import React from 'react';
import i18n from '../../../localizations/i18n';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';

// ButtonControl
export const button = (props: any) => {
  if (!props.visible) return null;
  const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  const onClick = props.uischema.onClick || function () { };
  return <UIElements.Button onClick={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), onClick))} children={props.uischema.label} />

  // <UIElements.Table
  //   onChange={(ev: React.ChangeEvent<HTMLInputElement>) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
  //     oldData[ev.currentTarget.name.split(';')[0]][ev.currentTarget.name.split(';')[1]] = ev.currentTarget.value;
  //     return oldData;
  //   }))}
  //   value={props.data[get_variable_name(props.uischema.scope)]} />
}
// end ButtonControl