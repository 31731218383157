import { } from '../Ideas.index'

export interface IDistributeRenderProps {
    ideaId: string;
    statusId: string;
    distributeMultiplicator: number;
    fund: number;
    save: () => void;
    cancle: () => void;
    currencyName?: string;
    currencyNamePlural?: string;
    max?: number;
}