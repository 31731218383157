import React, { FC, useEffect, useState } from 'react'
import { cnCourseCreate, mapDispatchToProps, mapStateToProps } from './CourseCreate.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './CourseCreate.scss'
import { } from 'uielements/src';
// import { TimePicker2 } from 'blocks/TimePicker2/TimePicker2';
import { Dialog, DialogActions, Select, Button, Icon, CheckboxInput, Textarea } from 'uielements/src'
import { TextInput } from 'blocks/PryanikyForms'
// import { PryanikyEditor } from 'blocks/PryanikyEditor/_PryanikyEditor'
// import { DateTimeInput } from 'blocks/PryanikyForms/DateTimeInput/DateTimeInput'
import { PrnUserInput } from 'blocks/PrnUserInput/PrnUserInput'
// import { Button } from 'uielements/src';

export const CourseCreatePresenter: FC<any> = ({ className }) => {

    return <div className={cnCourseCreate({}, ['Page', className])}>

        <TextInput className={cnCourseCreate('Name')}
            icon={<Icon icon={'fa-bell'} />}
            placeholder={'Название'}
            value={''} onChange={() => { }} />

        <Textarea icon={'edit'}
            className={cnCourseCreate('Description')}
            placeholder={'Текст описания'}
            onChange={() => { }}
            value={''} />

        <PrnUserInput
            className={cnCourseCreate('')}
            excludeMe={true}
            placeholder={'Укажите'}
            excludeUsers={[]}
            onChange={() => { }}
            value={[]} />

        {/* <PryanikyEditor
            // getClassRef={refGetter}
            //ref={forwardRef as any}
            // users={true}
            // tags={true}
            onChange={onChangeText}
            // className={''}
            // icon={'edit'}
            value={data.text}
            disableToolbar={true}
            placeholder={i18n.t('pryaniky.createPost.event.description')} /> */}
    </div>
}

export const CourseCreate = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CourseCreatePresenter));
