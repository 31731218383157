import { Typography, Box, Divider, Paper } from 'muicomponents/src';
import React, { FC, useEffect, useState } from 'react';
import * as utils from 'utils/src/utils';
import { IGroupsWidgetProps } from './GroupsWidgetNew.index';
import { Translate } from 'localization';
import { Link } from 'muicomponents/src/Link/Link';
import { ListItemAvatar, ListItemText } from '@mui/material';
import { ListItem } from 'muicomponents/src/ListItem/ListItem';
import { Avatar } from 'muicomponents/src/Avatar';
import { WidgetTypeSkeletonMUI } from 'blocks/Widget/_type/Widget_type_skeletonMUI';
import noDataImage from 'assets/svg/NoData.svg'
import { cn } from "@bem-react/classname";
import CardMedia from '@mui/material/CardMedia';
import EmptyWidget from 'assets/svg/EmptyWidget.svg';

export const cnUserGroupsWidgetNew = cn("UserGroupsWidgetNew");

export const GroupsWidgetNew: FC<IGroupsWidgetProps> = (props: IGroupsWidgetProps) => {

  const { context, authUser } = props

  const [data, setData] = useState<any[]>()
  const [loading, setLoading] = useState(false)

  const getData = () => {
    if (context.uId) {
      setLoading(true)
      utils.API.groups.byUser(context.uId, 'my')
        .r
        .then((d: any) => {
          if (utils.checkResponseStatus(d)) {
            const items = d.data.map((group: { [s: string]: any }) => {
              group.id = group.pkid;
              group.url = '/group/' + group.pkid;
              group.title = group.name;
              if (!utils.isEmptyGuid(group.imgId48x48)) group.imgUrl = group.imgUrl48x48;
              return group;
            });
            setData(items);
            setLoading(false)
          }
        });
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const isUserPageOwner = context.uId === authUser.profileData.userId

  const visibleData = isUserPageOwner ? data : data?.filter(group => group.visibilityType !== 1)

  if (loading) return <WidgetTypeSkeletonMUI />
  if ((!visibleData || !visibleData.length) && !props.settings?.hideIfNoData) return <Paper sx={{ p: 3 }}>
    <Typography fontWeight='700' color='primary' >
      {isUserPageOwner ? <Translate i18nKey={'pryaniky.filter.groups.activityDate.mygroups'} /> :
        <Translate i18nKey={'pryaniky.filter.groups.activityDate.groups.member'} />}
    </Typography>
    <CardMedia
                        component="svg"
                        className={cnUserGroupsWidgetNew("EmptyList-Avatar")}
                        sx={{
                            width: '100%',
                            height: "270px",
                            marginBottom: "12px"
                        }}
                        image={EmptyWidget}
                    />
    {/* <Avatar srcSet={noDataImage} variant='square' sx={{ width: 'auto', height: '100%' }} /> */}
    <Typography>
      <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
    </Typography>
  </Paper>
  if (!visibleData && props.settings?.hideIfNoData) return null
  if (!visibleData) return null

  let amount = 6  // max group number to display

  return (
    <Paper className={cnUserGroupsWidgetNew("")} sx={{ p: 3 }}>
      <Link href={`${window.location.origin}/user/${context.uId}/usergroups`}>
        <Typography fontWeight='700' mb='8px' >
          {isUserPageOwner ? <Translate i18nKey={'pryaniky.filter.groups.activityDate.mygroups'} /> :
            <Translate i18nKey={'pryaniky.filter.groups.activityDate.groups.member'} />}
        </Typography>
      </Link>

      {visibleData.map((group, i) => {
        if (i < amount) {
          return <Box key={i}>
            <ListItem alignItems="center" sx={{ height: '72px' }}>
              <ListItemAvatar>
                <Link href={group.url}>
                  <Avatar alt={group.name} src={group.imgUrl} />
                </Link>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Link href={group.url}>
                    <Typography variant='body2' color='primary' noWrap={true} >
                      {group.name}
                    </Typography>
                  </Link>
                }
                secondary={<>
                  <Typography variant='body2' fontSize='small' noWrap={true} color='GrayText' >
                    {group.description}
                  </Typography>
                  <Typography variant='body2' fontSize='small' color='GrayText' >
                    <Translate i18nKey='number of members' /> - {group.membersCount}
                  </Typography>
                </>}
              />
            </ListItem>
            <Divider sx={{ borderBottomWidth: '1px' }} variant="fullWidth" />
          </Box>
        } else return null
      })}

      {visibleData.length > amount &&
        <Box mt={1}>
          <Link href={`${window.location.origin}/user/${context.uId}/usergroups`}>
            <Typography variant='body2' fontSize='small' color='primary' >
              {isUserPageOwner ? <Translate i18nKey="pryaniky.list.groups.allMyGroup" /> : <Translate i18nKey="pryaniky.list.groups.allUserGroup" />
              }
            </Typography>
          </Link>
        </Box>
      }

    </Paper>
  );
};
