import { Box, Typography } from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';

export const ListItemBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  columnGap: 16,
  borderBottom: '1px solid #0000003B',
  padding: '12px 0'
}) as typeof Box;


export const ListItemDescription = styled(Typography)({
  fontSize: 14,
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
}) as typeof Typography;