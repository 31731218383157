import React from 'react';
import { cnWidget, cnWidgetMui, IWidgetProps } from '../../Widget.index';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../../Widget';
import { widgets } from "i.widgets";
import { RemindFillProfileWidgetPreseter } from 'blocks/RemindFillProfileWidget/RemindFillProfileWidget';
import { IWidgetRemindFillProfileProps } from './Widget_type_RemindFillProfile.index';


const type = 'remindFillProfile';

export default class WidgetRemindFillProfilePresenter extends WidgetPresenter<IWidgetRemindFillProfileProps> {

    public render() {
        if (!this.props.widget) return null
        const subtype = utils.widgetSubtype(this.props.widget.type);
        const Tag = widgets.components.common
        return <Tag {...this.props} className={cnWidgetMui({ type, subtype })}>
            <RemindFillProfileWidgetPreseter {...this.props} isEdit={this.props.edit} settings={this.props.widget.settings}  />
        
        </Tag>
    }
}