import {
    GetActions,
    GetCustomActions,
    PostActions,
    PostCustomActions
} from './actions';
import { useCallback, useEffect, useMemo, useRef } from 'react';

type frame = 'top' | string | HTMLIFrameElement;

type ActionType = {
    frame: frame;
    event: keyof typeof GetActions;
    data: any;
};

/**
 * get global widnow from frame
 * @param frame 
 * @returns 
 */
const getWindow = (frame: frame): Window | null => {
    let targetWindow: Window | null = null;
    if(typeof frame === 'string') {
        if(frame === 'top') {
            targetWindow = window.top;
        } else {
            targetWindow = (window.document.getElementById(frame) as HTMLIFrameElement | null)?.contentWindow || null;
        }
    } else {
        targetWindow = frame?.contentWindow;
    }
    return targetWindow;
};

type ResponseDataValues<D> = number | string | D[] | D;

type ActionCatch = keyof typeof GetActions | keyof typeof GetCustomActions;
type ActionTo = keyof typeof PostActions | keyof typeof PostCustomActions;

export function PostDataToFrame<T = any> (
    actionCatch: ActionCatch,
    actionTo: ActionTo,
    responseData: ((data: any, frameId: string) => ResponseDataValues<T>) | ResponseDataValues<T>
) {
    // type Rezult = 'success' | 'error';
    type Rezult = any;

    const acRef = useRef<AbortController | null>(null);

    const resolvePromise = useMemo<
        ( signal: AbortController['signal'] )
        => ( resolve: (value: Rezult | PromiseLike<Rezult>) => void, reject: (reason?: any) => void )
        => void
    >(() => 
    ( signal ) => 
    ( resolve, reject ) => {

        const abortHandler = () => {
            window.removeEventListener("message", resolvePostMessage);
            reject(new DOMException("Aborted", "AbortError"));
        }

        signal.addEventListener("abort", abortHandler);

        const resolvePostMessage = async function (event: MessageEvent<ActionType>) {
            if(event.data.event === actionCatch) {
                const targetWindow = getWindow(event.data.frame);
                if(!targetWindow) {
                    console.error(`PostPromise at action "${actionCatch}" frame error: didn't find target window`);
                    signal.removeEventListener("abort", abortHandler);
                    reject();
                }
                let data: ResponseDataValues<T>;
                if(typeof responseData === 'function') {
                    data = await (responseData as any)(event.data.data, event.data.frame);
                } else {
                    data = responseData;
                }
                targetWindow?.postMessage({
                    event: actionTo,
                    data
                }, '*');
                signal.removeEventListener("abort", abortHandler);
                resolve(event.data);
            }
        };

        window.addEventListener("message", resolvePostMessage);

    }, [responseData]);

    const promise = useMemo(() => () => {
        acRef.current?.abort();
        const ac = new AbortController();
        acRef.current = ac;
        return new Promise<Rezult>(resolvePromise(ac.signal));
    }, [resolvePromise]);

    return promise;
};