import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IBodyProps } from 'utils/src/DialogCreator';
import { ISkill } from 'redux/sagas/Skills/types'

export interface ISkillsDialogEditProps extends IClassNameProps, IBodyProps<{
  deleteArr: ISkill[];
  createArr: ISkill[]
}> {
  tag?: 'div';
  onComplete: (title: string) => void;
  currentUser: any;
  withError: boolean;
  skills: ISkill[]
}

export interface EditData {

}
export interface ISkillsDialogEditState { }

export interface ISkillsDialogEditStateProps { }



export const cnSkillsDialogEditDialog = cn('SkillsDialogEditDialog');