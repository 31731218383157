import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
import * as ACT from '../../../actions';
import * as SEL from '../../selectors'
import i18n from 'localizations/i18n';
import { toast } from 'react-toastify';
import { confirmProise } from 'uielements/src/Confirm/Confirm'
import { API } from 'utils/src/utils'

const handleContextActionTranslate = function* handleContextActionTranslate({ payload }: AT.AContextAction) {
    const { action, id } = payload
    if (action === 'translate') {
        try {
            const news = yield* select(SEL.getNewsById(id))

            const language = news.translatedTo ? undefined : 'en'

            const request = yield* call(API.news.byId, id, { language })
            // @ts-ignore
            const result = yield request.r
            if (result.error_code === 0) {

                yield* put(ACT.pathNews({
                    id,
                    text: (result.data[0] || { text: '' }).text,
                    translatedTo: language
                }))
            } else {
                toast.error('pryaniky.translate.error');
            }

        } catch (error) {
            return error;
        }
    }
    yield;

    return 0;
}

export default handleContextActionTranslate