import React from 'react';
import { ISecretPassDialogProps, cnSecretPassDialog } from './SecretPassDialog.index';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import Input from 'uielements/src/MaterialElements/Input/Input';
import {
  Select,
  Icon
} from 'uielements/src';
import { Translate } from '../../../localizations/Translate';
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import './SecretPassDialog.scss';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import i18n from 'localizations/i18n';

const SecretPassDialogPresenter: React.FC<ISecretPassDialogProps> = ({
  okBntText,
  cnclBntText,
  title = <Translate i18nKey={`pryaniky.dialog.secretPass.title`} />,
  handleAccept,
  handleClose
}) => {

  const [secretPass, setSecretPass] = React.useState('');

  return (
    <div className={cnSecretPassDialog()}>
      <DialogTitle onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent className={cnSecretPassDialog("Body")}>
        <Input
          multiline
          onChange={value => setSecretPass(value)}
          value={secretPass}
          placeholder={<Translate i18nKey={'pryaniky.dialog.secretPass.input.placeholder'} />}
        />
      </DialogContent>
      <DialogActions
        acceptText={i18n.t('send')}
        closeText={i18n.t('pryaniky.cancel.dialog')}
        // disableAccept={strengthPass.score === 0 || newPassword !== conPassword}
        onAccept={() => handleAccept(secretPass)}
        onClose={handleClose} />
     
    </div>
  )
}

export const SecretPassDialog = createModal(SecretPassDialogPresenter, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: '#fff',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});