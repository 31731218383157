import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IStateType as IState } from '../../redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { RouteComponentProps } from 'react-router'

export interface ILeaderboardProps extends ILeaderboardOwnProps, ILeaderboardStateProps, ILeaderboardDispatchProps {

}
export interface ILeaderboardOwnProps extends RouteComponentProps<IParams> {
    tag?: 'div';
}
export interface IParams {
    id: string
}

export type ILeaderboardStateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: IState) => {
    return {
        curUser: getAuthUser(state).baseData,
    };
};

export type ILeaderboardDispatchProps = ReturnType<typeof mapDispatchToProps>

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
}, dispatch)


export const cnLeaderboard = cn('LeaderboardMui');
