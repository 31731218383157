import { styled } from 'muicomponents/src/mui/system';
import { Box } from 'muicomponents/src/Box/Box';
import { Paper } from 'muicomponents/src/Paper';
import { List, ListItemButton } from 'muicomponents/src/ListItem/ListItem';

export const HistoryWrapper = styled(Box)({
    position: 'fixed',
    bottom: 0,
    zIndex: 1,
    width: 1170,
    padding: '16px 56px',
    boxSizing: 'border-box'
});