// import * as React from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { mapStateToProps, IUsersWidgetStateProps } from './../UsersWidget.index';
import { mapDispatchToProps, IDispatchProps } from "redux/connector";
import i18n from 'localizations/i18n';
// import { withBemMod } from '@bem-react/core';

// import { cnUsersWidget } from '../UsersWidget.index';
// import { IUsersWidgetTypeSubscriptionsProps } from './UsersWidget_type_subscriptions.index';
import './UsersWidget_type_subscriptions.scss';

import { UsersWidgetPresenter } from '../UsersWidget';
import { IUsersWidgetProps } from '../UsersWidget.index';

export class UsersSubscriptionsWidgetPresenter extends UsersWidgetPresenter {

  public getData = (props: IUsersWidgetProps) => {
    const { uid, wcontext } = props;
    utils.API.users.subscriptions(wcontext.uId ? wcontext.uId : uid)
      .r
      .then((d: any) => {
        if (!this.mounted) return;
        if (utils.checkResponseStatus(d)) {
          const items = d.data.map((user: { [s: string]: any }) => {
            user.title = user.displayName;
            user.url = '/user/' + user.id;
            /**
             * userPhotoUrl is old parameter 
             */
            if (!utils.isEmptyGuid(user.imgId)) user.imgUrl = user.userPhotoUrl || user.imgUrl;
            return user;
          });
          this.setState({
            items,
            noData: items.length === 0,
            title: i18n.t('pryaniky.widgets.settings.subscriptions.title')
          });
        }
      });
  }

}

export const UsersSubscriptionsWidget = connect<IUsersWidgetStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(UsersSubscriptionsWidgetPresenter)


// export const withUsersWidgetTypeSubscriptions = withBemMod<IUsersWidgetTypeSubscriptionsProps>(
//   cnUsersWidget(),
//   { type: 'subscriptions' },
//   (Presenter) => (
//     (props) => (
//       <Presenter {...props}/>
//     )
//   )
// );