import { Popover } from 'muicomponents/src/Popover'
import React, { useEffect, useState } from 'react'
import { PopoverCategoriesBox, PopoverFiltersActions, PopoverFiltersTitle } from './PopoverFilters.styled'
import { Translate } from 'localization';
import { Button, Box, Typography, FormControlLabel } from 'muicomponents/src';
import { CleaningServices } from 'muicomponents/src/Icons';
import { DialogTitle } from 'muicomponents/src/DialogParts';
import { RadioGroupMui } from 'muicomponents/src/RadioGroup/RadioGroup';
import { IBadgeCategory } from 'utils/src/requests/models/api.badges';
import { toast } from 'react-toastify';
import { getCategories as getCategoriesAPI  } from 'utils/src/requests/requests.badges';
import { Checkbox } from 'muicomponents/src/Checkbox/Checkbox';
import { IHeaderBadgesProps } from '../HeaderBadges/HeaderBadges.index';

export type TPropoverFiltersProps = {
  popoverAnchor: HTMLButtonElement | null,
  setPopoverAnchor: React.Dispatch<React.SetStateAction<TPropoverFiltersProps['popoverAnchor']>>,
  setCheckedCategories: IHeaderBadgesProps['setCheckedCategories'],
}

export type TTabs = {
  id: number,
  title: string,
  value: 'all' | 'category'
}

const tabs: TTabs[] = [
  {
    id: 1,
    title: Translate.t({i18nKey: 'pryaniky.badge.filters.display.all'}),
    value: 'all'
  },
  {
    id: 2,
    title: Translate.t({i18nKey: 'pryaniky.badge.filters.display.category'}),
    value: 'category'
  }
]

export const PropoverFilters:React.FC<TPropoverFiltersProps> = ({ popoverAnchor, setPopoverAnchor,  setCheckedCategories }) => {
  
  const [categories, setCategories] = useState<IBadgeCategory[]>([]);
  const [localCheckedCategories, setLocalCheckedCategories] = useState<string[]>([])
  const [activeTab, setActiveTab] = useState<TTabs['value']>('all');

  const closePopover = () => {
    setPopoverAnchor(null);
  };

  useEffect(() => {
    if (activeTab === 'all' && localCheckedCategories.length) {
      setLocalCheckedCategories([]);
      setCheckedCategories([]);
    };
  }, [activeTab])

  useEffect(() => {
    async function getCategories() {
      try {
        const response = await getCategoriesAPI().r;
        if (response.error_code !== 0) {
          throw response;
        } else {
          setCategories(response.data);
        }
      } catch (error) {
        toast.error(Translate.t({i18nKey: 'pryaniky.timeline.error.title'}))
      }
    }
    getCategories();
  }, []);
  
  return (
    <Popover
      open={Boolean(popoverAnchor)}
      anchorEl={popoverAnchor}
      onClose={closePopover}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      
      <DialogTitle onClose={closePopover} >
        <PopoverFiltersTitle>
          <Translate i18nKey={'pryaniky.badge.filters.title'} />
        </PopoverFiltersTitle>
      </DialogTitle>

      <Box sx={{padding: '0 24px 16px'}}>
        <Typography>
          <Translate i18nKey={'pryaniky.badge.filters.dispalyCategoty'} />
        </Typography>
  
        <RadioGroupMui 
          items={tabs}
          value={activeTab}
          onChange={(event, value) => setActiveTab(value as TTabs['value'])}
          sx={{display: 'flex', flexDirection: 'column'}}
          AdditionalFilters={
            activeTab === 'category' && <PopoverCategoriesBox>
              { categories.map((category, index) => {
                
                const isChecked = localCheckedCategories.includes(category.id);

                const selectedCategories = isChecked ? 
                localCheckedCategories.filter((id) => id !== category.id) :
                  [...localCheckedCategories, category.id];
                
                  return category.displayName && 
                  <FormControlLabel
                    key={category.id}
                    label={category.displayName}
                    control={ 
                      <Checkbox 
                        checked={isChecked}
                        onChange={() => setLocalCheckedCategories(selectedCategories)}
                      />
                    }
                  />
              })}
            </PopoverCategoriesBox>
          }
        />
      </Box>

      {activeTab === 'category' && (
        <PopoverFiltersActions>
          <Button 
            startIcon={<CleaningServices/>}
            variant={'outlined'}
            size={'small'}
            onClick={() => {
              setLocalCheckedCategories([]);
              setCheckedCategories([]);
              closePopover();
            }}
          >
            <Translate i18nKey={'pryaniky.badge.filters.clear'} />
          </Button>

          <Button 
            variant={'contained'}
            size={'small'}
            onClick={() => {
              setCheckedCategories(localCheckedCategories);
              closePopover();
            }}
          >
            <Translate i18nKey={'pryaniky.badge.filters.apply'} />
          </Button>
        </PopoverFiltersActions>
      )}

    </Popover>
  )
}