import React, { useState } from 'react';
import { Box, Typography } from 'muicomponents/src';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { IAddFormProps, cnHierarchyWidgetNew } from '../Widget_type_hierarchyNew.index';
import ClearIcon from '@mui/icons-material/Clear';
import { Translate } from 'localization';
import { UsersSuggester } from 'muicomponents/src/Suggester/type/UsersSuggester';
import AddIcon from '@mui/icons-material/Add';
import * as utils from 'utils/src/utils';

export const AddForm = ({ newUser, setNewUser, title, items, setItems, ...props }: IAddFormProps) => {
    const { context, settings } = props;
    const authUser: any = useSelector(getAuthUser);

    const head = settings?.headusers;
    const [added, setAdded] = useState(false);
    const add = () => {
        if (newUser && settings?.hierarchy.name) {
            const topId = head
                ? newUser.newUser.id
                : context.uId
                ? context.uId || context.currentUserId
                : authUser.baseData.id;
            const bottomId = head
                ? context.uId
                    ? context.uId || context.currentUserId
                    : authUser.baseData.id
                : newUser.newUser.id;
            utils.API.hierarchy.changeHierarchyAction(
                encodeURIComponent(settings?.hierarchy.name), // что бы слеши не ломали роутинг
                topId,
                bottomId,
                true
            );
            setItems([...items, { ...newUser.newUser, url: `/user/${newUser.newUser.id}` }]);
            setAdded(false);
            setNewUser({});
        } else {
            setAdded(false);
            setNewUser({});
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            {added ? (
                <form onSubmit={add}>
                    <Box display="flex" alignItems="center">
                        <UsersSuggester
                            multiple={false}
                            noAdornment
                            TextFieldProps={{
                                label: <Translate i18nKey={'pryaniky.hierarchy.select_user'} />,
                            }}
                            sx={{
                                width: '90%',
                                '.Mui-focused': {
                                    width: '90%',
                                },
                            }}
                            value={newUser?.newUser || []}
                            onChange={(event, newUser) => setNewUser({ newUser })}
                            disablePortal={true}
                            filterSelectedOptions
                            filterOptions={(options) => {
                                const selectedItemsIds = items.map((item: { id: any; [key: string]: any }) => item.id);
                                return options.filter((option) => !selectedItemsIds.includes(option.id));
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                newUser?.newUser ? add() : setAdded(false);
                            }}
                        >
                            {newUser?.newUser ? <AddIcon /> : <ClearIcon />}
                        </IconButton>
                    </Box>
                </form>
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography fontWeight="700" className={cnHierarchyWidgetNew('Title')}>
                        {title}
                    </Typography>
                    <IconButton onClick={() => setAdded(true)} size="small" color="primary">
                        <AddIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};
