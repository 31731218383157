/**
 * @packageDocumentation
 * @module Widget_Settings_hierarchy
 */
import data from './data';
import schema, { prepareData } from './schema';
import uischema from './uischema';

export default {
    data,
    schema,
    uischema, 
    prepareData
}