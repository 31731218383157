import React, { FC, useState, useEffect } from 'react';
import { IAlertWidgetProps } from './AlertWidget.index'
import { initWidgetAddition, initWidgetSettings } from './AlertWidgetSettingsDialog/AlertWidgetSettingsDialog'
import { TinyMCE } from 'blocks/TinyMCEWidget/TinyMCEWidget'
initWidgetAddition()
initWidgetSettings()

export const AlertWidget: FC<IAlertWidgetProps | any> = ({ contexts, className, isEditable, HTMLWidgetContentEdit, ...props }) => {

    // return <>
    // AlertWidget
    // </>
    return <>
        <TinyMCE
            {...props}
            className={className}
            contexts={contexts}
            // tag={widgets.components.common}
            isEditable={isEditable}
            isEdit={HTMLWidgetContentEdit}
        />
    </>

}