import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
  CREATE_PHOTOALBUM: '',
  READ_GROUP_ALBUMS: '',
  READ_ALBUM: '',
  ADD_PHOTOS: '',
  CHANGE_PHOTO: '',
  DELETE_PHOTOALBUM: '',
  DELETE_PHOTO: '',
  SELECT_PHOTO: '',
  SELECT_ALL_PHOTO: '',
  IS_LOADING_ALBUM: '',
  IS_LOADING_GROUP_ALBUM: '',
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.PHOTOALBUMS)}), {} as typeof actions);