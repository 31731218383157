import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { transitionToUnit, loadFullUnit } from "../../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';

export interface ITestProps extends IClassNameProps, ITestStateProps, IDispatchProps, RouteComponentProps, ITestDispatch {
    tag?: 'div';
    unit: any;
    sid: string;
    uid: string;
    // qid: string;
    // unitContent: any
}

export interface ITestState {
    // courses: ICourse[];
    // isFinished: boolean;
}

export interface ITestStateProps {
    // store: any
    // unitContent: any
}

export const mapStateToProps = (state: any, props: any) => ({
    // questions: state.LMS.units[props.uid].normalQuestions
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    jumpToUnit: transitionToUnit,
    loadFullUnit
}, dispatch);

export type ITestDispatch = ReturnType<typeof mapDispatchToProps>

export const cnSkipedTest = cn('SkipedTest');