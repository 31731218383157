import { ReactNode } from 'react';
import { ReplyType } from './Reply.type'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { replyViewFull } from '../../../News/redux/actions';


export interface INewsPropsType extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapReplyActionsToProps> {
    // data: ReplyType;
    id: string;
    text: string
    type: string
    header: string
    disableCut?: boolean
}

export interface INewsStateType {
    // data: ReplyType;
}

export const mapStateToProps = (state: any) => ({
    // userData: getCurrentUser(state),
})


export const mapReplyActionsToProps = (d: Dispatch<Action>) => bindActionCreators({
    replyViewFull
}, d);