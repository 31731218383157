import Component, { Presenter } from './component';
import {
    LMSTO,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = LMSTO.Comments.IOwnProps;
export type IComponentPresenterProps = LMSTO.Comments.IPresenterProps;


export default Component