import { IIdeaFieldProps } from '../FieldProps';
import React from 'react';
import { cn } from '@bem-react/classname';
import { Matrix } from '../../../../../Matrix/Matrix';
import './MatrixOnePerRow.scss'
import { GUID_EMPTY } from 'utils/src/constants.prn';

export const cnMatrixOnePerRow = cn('MatrixOnePerRow');
export const MatrixOnePerRow: React.FC<IIdeaFieldProps> = ({ field }) => {

    // затычка для визуализации заполненных rowValues но не заполненных selectedAnswer
    field.field.rows?.forEach(row => {
        if(
            (!row.selectedAnswer || row.selectedAnswer === GUID_EMPTY) &&
            field.rowValues && field.rowValues[row.id] && field.rowValues[row.id].toString() !== row.selectedAnswer
            ) {
            row.selectedAnswer = field.rowValues[row.id].toString();
        }
    });

    return (<Matrix data={field.field} className={cnMatrixOnePerRow('view')} onChange={()=>{}}/>);
}