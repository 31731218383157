import React, { FC, useState } from 'react'
import { IChangeMailProps } from './ChangeMail.index'
import './ChangeMail.scss'
import { Button, Input, ErrorMsg, Dialog, DialogTitle, DialogContent, Spinner } from 'uielements/src';
import { ActionButtons } from 'blocks/ActionButtons/ActionButtons'
import { TextInput } from 'blocks/PryanikyForms/TextInput/TextInput'
import * as utils from 'utils/src/utils'
import i18n from 'localizations/i18n';
import { toast } from 'react-toastify';

export const ChangeMail: FC<IChangeMailProps> = ({ openBtn, userId, currentUser }) => {
    const [showDialog, setShowDialog] = useState(false)
    const [newMail, setNewMail] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [request, setRequest] = useState(false)

    const onCloseDialog = () => setShowDialog(false);
    const openDialog = () => setShowDialog(true);

    let isReset = currentUser.baseData.isAdmin ? (userId !== currentUser.baseData.id) : false

    let wrongPassword = newMail === ''
    // wrongPassword = utils.
    // wrongPassword = wrongPassword ? true : newPassword !== conPassword;
    // wrongPassword = !isReset ? (wrongPassword ? true : oldPassword === '') : wrongPassword;
    // wrongPassword = wrongPassword ? true : request;


    const clearFields = () => {
        setNewMail('')
        setErrorMsg('')
    }

    const changeMail = () => {
        setRequest(true)
        utils.API.users.changeMail( userId, newMail ).r.then((d: any) => {
            if (d.error_code !== 0) {
                setErrorMsg(i18n.t('pryaniky.changeemail.error.' + d.error_code))
            } else {
                clearFields()
                toast.success(i18n.t('pryaniky.changeemail.error.' + d.error_code))
                onCloseDialog()
            }
            setRequest(false)
        })
    }

    const close = () => {
        clearFields()
        onCloseDialog()
    }

    return <>
        {openBtn ? openBtn(openDialog) : <Button onClick={openDialog}>Изменить e-mail</Button>}
        <div className={'ChangeEmail'}>
            <Dialog onClose={onCloseDialog} isShown={showDialog}>
                <DialogTitle onClose={onCloseDialog}>
                    Смена e-mail
            </DialogTitle>

                <DialogContent>
                    {request && <Spinner />}
                    {errorMsg !== '' && <ErrorMsg className={'ChangeEmail-Msg'}>{errorMsg}</ErrorMsg>}

                    <TextInput className={'ChangeMail-Input'} value={newMail} onChange={setNewMail} placeholder={'Новый e-mail'} />
        
                </DialogContent>

                <ActionButtons className={"ChangeMail-Actions"} saveDisabled={wrongPassword} save={changeMail} saveText={'Сохранить'} cancle={close} cancleText={'Закрыть'} />
            </Dialog>
        </div>
    </>
}