import * as utils from 'utils/src/utils';
import { fileModelConverter } from 'blocks/Photoalbums/Photoalbums.utils'
import { denormalize, normalize, schema } from 'normalizr';
import { createFile, createAlbum as cAlbum } from 'blocks/Photoalbums/Photoalbums.utils'
import { dark } from '@material-ui/core/styles/createPalette';
import actions from 'redux/actionsTypes/Photoalbums';

export const photosSchema = () => {
    const comment = new schema.Entity('files', {}, {
        idAttribute: 'id', processStrategy: (value, parent, key) => {
            return { ...value };
        }
    });
    return [comment];
}


export const normalizeData = (data: any) => {
    const normalizedData = normalize(data, photosSchema());
    return normalizedData;
}

export const denormalizeData = (fields: any) => {
    const denormalizedData = denormalize(fields.result, photosSchema(), fields.entities);
    return denormalizedData;
}

export const createPhotoalbum = (payload: any) => ({
    type: actions.CREATE_PHOTOALBUM,
    payload
})

export const readGroupAlbums = (payload: any) => ({
    type: actions.READ_GROUP_ALBUMS,
    payload
})

export const readAlbum = (payload: any) => ({
    type: actions.READ_ALBUM,
    payload
})

export const addPhotos = (payload: any) => ({
    type: actions.ADD_PHOTOS,
    payload
})

export const changePhoto = (payload: any) => ({
    type: actions.CHANGE_PHOTO,
    payload
})

export const deleteAlbumAction = (payload: any) => ({
    type: actions.DELETE_PHOTOALBUM,
    payload
})

export const deletePhotoAction = (payload: any) => ({
    type: actions.DELETE_PHOTO,
    payload
})

export const selectPhoto = (aid: string, fid: string) => ({
    type: actions.SELECT_PHOTO,
    payload: {
        aid, fid
    }
})

export const selectAllPhoto = (payload: string) => ({
    type: actions.SELECT_ALL_PHOTO,
    payload
})

export const deletePhotoMultiple = (aid: string, fids: string[]) => {
    return (dispatch: any) => {
        fids.forEach(fid => {
            dispatch(deletePhotoAction({ aid, fid }))
            utils.API.files.remove(fid).r.then((d: any) => {
                if (utils.checkResponseStatus(d)) {

                } else {
                }
            })
        })
    }
}

export const deletePhoto = (aid: string, fid: string) => {
    return (dispatch: any) => {
        dispatch(deletePhotoAction({ aid, fid }))
        utils.API.files.remove(fid).r.then((d: any) => {
            if (utils.checkResponseStatus(d)) {

            } else {
            }
        })
    }
}

export const deleteAlbum = (gid: number, aid: string) => {
    return (dispatch: any) => {
        dispatch(deleteAlbumAction({ aid, gid }))
        utils.API.photoalbums.remove(aid, {}).r.then((d: any) => {
            if (utils.checkResponseStatus(d)) {

            } else {
            }
        })
    }
}

export const createAlbum = (gid: number, name: string) => {
    return (dispatch: any) => {
        const album = cAlbum(gid, name);
        utils.API.photoalbums.create(gid, album).r.then((d: any) => {
            if (utils.checkResponseStatus(d)) {
                dispatch(createPhotoalbum({ album, gid }))
            } else {
            }
        })
    }
}

export const uploadPhotos = (aid: string, gid: number, photos: any[]) => {
    return (dispatch: any) => {
        photos.forEach((p: any) => {
            const currentFile = {...createFile('none', p.name), isUploading: false}
            const file = p;
            file.gId = gid;
            file.folderId = aid;
            utils.API.files.upload(file, {
                loadStart: (event, file) => {
                    const reader = new FileReader();
                    reader.onload = (ev: any) => {
                        currentFile.originalUrl = URL.createObjectURL(file)//ev.target.result
                        currentFile.isUploading = true;
                        const normalData = normalizeData([currentFile])
                        dispatch(addPhotos({
                            aid,
                            photos: {
                                result: normalData.result,
                                files: normalData.entities.files
                            }
                        }))
                    }
                    reader.readAsDataURL(file)
                },
                loadProgress: (event, file) => {
                    dispatch(changePhoto({
                        aid,
                        fid: currentFile.id,
                        file: currentFile
                    }))
                },
                loadFinish: (d, file) => {
                    dispatch(deletePhotoAction({ aid, fid: currentFile.id }))
                    dispatch(addPhotos({
                        aid,
                        photos: {
                            result: [d.data[0].id],
                            files: { [d.data[0].id]: { ...d.data[0], originalUrl: currentFile.originalUrl} }
                        }
                    }))
                },
                loadError: () => { }
            })
        })
    }
}

export const getAlbum = (aid: string, gid: number, from: number = 0) => {
    return (dispatch: any) => {
        dispatch({ type: actions.IS_LOADING_ALBUM, payload: { aid } })
        const request = utils.API.photoalbums.byId(from, 24, gid, aid);
        request.r.then((d: any) => {
            if (utils.checkResponseStatus(d)) {
                let photos: any[] = [];
                d.data.map((photo: any) => {
                    photos.push(fileModelConverter(photo))
                })
                const normalData = normalizeData(photos)
                dispatch(readAlbum({
                    aid,
                    photos: {
                        result: normalData.result,
                        files: normalData.entities.files,
                        selected: [],
                        isFinished: d.isFinished,
                        length: d.length,
                        isLoading: false
                    }
                }))
            } else {
            }
        });
    };
}

export const getPhoto = (aid: string, gid: number, from: number = 0) => {
    return (dispatch: any) => {
        
        dispatch({ type: actions.IS_LOADING_ALBUM, payload: { aid } })
        const request = utils.API.photoalbums.byGroupId(from, 24, gid, aid);
        request.r.then((d: any) => {
            if (utils.checkResponseStatus(d)) {
                let photos: any[] = [];
                d.data.map((photo: any) => {
                    photos.push(fileModelConverter(photo))
                })
                const normalData = normalizeData(photos)
                dispatch(readAlbum({
                    aid,
                    photos: {
                        result: normalData.result,
                        files: normalData.entities.files,
                        selected: [],
                        isFinished: d.isFinished,
                        length: d.length,
                        isLoading: false
                    }
                }))
            } else {
            }
        });
    };
}

export const loadList = (gid: number, from: number = 0) => {
    return (dispatch: any) => {
        dispatch({ type: actions.IS_LOADING_GROUP_ALBUM, payload: { gid } })
        const request = utils.API.photoalbums.list(from, 20, gid);
        request.r.then((d: any) => {
            if (utils.checkResponseStatus(d)) {
                let albums: any = {};
                let group: any = {
                    albums: [],
                    isLoading: false,
                    isFinished: d.isFinished,
                };
                d.data.map((album: any) => {
                    group.albums.push(album.id);
                    albums[album.id] = album;
                });
                dispatch(readGroupAlbums({ albums, group, gid }))
            } else {
            }
        });
    };
}