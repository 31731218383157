import { zeroId } from 'LMSModule/utils/utils.lms'
import { EditorState } from 'draft-js';

export const answerModelCreator = (id = zeroId) => (
    {
        "id": id,
        "text": "",
        "description": "",
        "isRightAnswer": false,
        "NumericMin": 0,
        "NumericMax": 10,
        "RightAnswerHighlight": false,
        "Score": 1,
        "freeAnswer": false,
        "selected": false,
        draftState: EditorState.createEmpty()
    }
)