import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ISkill } from 'redux/sagas/Skills/types'
///////////

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace SkillsListNew {
    // eslint-disable-next-line @typescript-eslint/no-namespace


    export type IPresenterProps = IOwnProps

    export interface IState { }

    export interface IOwnProps extends IClassNameProps {
        userid: string
        skills: ISkill[];
        confirmSkill: (userId: string, skillId: string, confirmedByMe: boolean) => void;
        deleteSkill: (userId: string, skillId: string) =>  void
        showControls: boolean;
        setShow: (skillId: string) => void
        isLoading: boolean
        isFinished: boolean
        create: boolean
    }
}

////////////

export const cnClassName = cn('SkillsList');