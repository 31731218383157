import { Box, Button, styled } from 'muicomponents/src';
import { Tooltip } from 'muicomponents/src/Tooltip';


export const WalletInfoBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}) as typeof Box

export const EmptyWalletInfoBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
}) as typeof Box

export const OpenDepositButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
})) as typeof Button

export const FirstInfoBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
}) as typeof Box

export const LightTooltip = styled(Tooltip)({
    '.MuiTooltip-tooltip': {
        background: '#fff',
        color: 'unset',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    }
}) as typeof Tooltip
