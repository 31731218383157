import * as React from 'react';
import { IEditorTypeNewsProps } from './news.index';
import { BadgesNewsCreate, defaultBadgesNewsCreate } from '../../../Badges.type'
import { Editor } from '../../Editor'
import { BadgeValidator, badgeValidate } from '../../../Badges.validate'
import * as utils from 'utils/src/utils';
import { EditorState } from 'draft-js';
import { baseValidate, Rule } from '../../../../Base.validate'

export const EditorTypeNews: React.FC<IEditorTypeNewsProps> = ({ onChange: cbChange, data, types }) => {
    let localData = data as any;
    if (data.newstype !== 0) {
        localData = { ...utils.cloneObject(defaultBadgesNewsCreate), text: EditorState.createEmpty() };
    } // else localData.uevent.startDateTime = new Date(localData.uevent.startDateTime);

    
    const onChange = (data: any) => {
        const vb = new BadgeValidator();
        const validBadge: Rule[] = [
            {
                type: 'array',
                method: 'ARR_MIN',
                field: 'badge.users',
                value: (localData.groups || []).length > 0 ? 0 : 1,
                errorI18nKey: 'pryaniky.validate.news.badge.users.min',
                // message: 'small users'
            },
            {
                type: 'array',
                method: 'ARR_MIN',
                field: 'badge.groups',
                value: 0,
                errorI18nKey: 'pryaniky.validate.news.badge.groups.min',
                // message: 'small groups'
            },
        ]
        vb.addData(data)
        vb.setRules([...validBadge, ...badgeValidate])
        cbChange(data, vb.validation().length === 0, vb.validation())
    }
    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData);
    }
    const onChangeUsers = (e: any) => {
        const usersArray = e.filter((val: any) => val.type === 'user')
        const groupsArray = e.filter((val: any) => val.type === 'group')

        localData.users = usersArray;
        localData.groups = groupsArray;

        onChange(localData);
    }
    const onChangeBadge = (data: any) => {
        localData.badge = data;
        onChange(localData)
    }
    return <Editor
        badgeList={types}
        onChangeBadge={onChangeBadge}
        onChangeUsers={onChangeUsers}
        onChangeText={onChangeText}
        data={localData} />
}
