import { IDispatchProps } from 'redux/connector';
import { IDialog } from 'blocks/Dialogs/DialogWrapper.index';
import { bindActionCreators, AnyAction, Dispatch } from "redux";
import { modalChangeData } from 'redux/actions/Modals';
import { setCalendarCalFilterColor } from 'redux/calendar/actions';
import { IBodyProps } from 'utils/src/DialogCreator';

export interface ISelectFilterColorDialogProps extends   IBodyProps,  ISelectFilterColorDialogStateProps,  ISelectFilterColorDialogDispatchProps {
  itemData: any
}

export type ISelectFilterColorDialogStateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: any) => ({})

export type ISelectFilterColorDialogDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  setCalendarCalFilterColor
}, dispatch);
