import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    IDialogTitlePresenterProps
} from './interfaces'
import './style.scss'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

export const Presenter: FC<IDialogTitlePresenterProps> = ({ children, onClose, className, ...other}) => {
    return <MuiDialogTitle disableTypography className={cnClassName({}, [className])} {...other}>
            <Typography className={cnClassName('Header')} variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={cnClassName('CloseBtn')} onClick={onClose}>
                    <CloseIcon className={cnClassName('CloseIcon')} />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
}

export default memo(Presenter);
