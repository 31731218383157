import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { ReactNode } from 'react';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { IBodyProps } from 'utils/src/DialogCreator';

export const cnSecretPassDialog = cn('SecretPassDialog');

export interface ISecretPassDialogProps extends IBodyProps<string>, ISecretPassDialogOwnProps, IClassNameProps, ImapSecretPassDialogStateToProps, ImapSecretPassDialogActionsToProps {

}

export interface ISecretPassDialogOwnProps {
  okBntText?: string | ReactNode;
  cnclBntText?: string | ReactNode;
  title?: string | ReactNode;
}

export const mapSecretPassDialogStateToProps = () => ({

});

export type ImapSecretPassDialogStateToProps = ReturnType<typeof mapSecretPassDialogStateToProps>;

export const mapSecretPassDialogActionsToProps = (d: Dispatch<Action>) => bindActionCreators({

}, d);

export type ImapSecretPassDialogActionsToProps = ReturnType<typeof mapSecretPassDialogActionsToProps>;