import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { ILearningTrack } from 'utils/src/requests/models/api.learningTracks'
import {
    removeTrack,
    selectTrack
} from '../../../redux/actions/TracksManage'
import {
    getTracksSelected
} from '../../../redux/sagas/TracksManage/selectors'
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace LMSTO {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace TOTracksListItem {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            data: ILearningTrack
            selected?: boolean
        }

        export interface IStateProps {
        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = LMSTO.TOTracksListItem.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    removeTrack,
    selectTrack
}, dispatch);

////////////

export const cnClassName = cn('TOTracksListItem');