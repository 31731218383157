import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from 'redux/connector';
import { IShopItem } from 'utils/src/requests/models/api.shop';
import { IStoreAppSetting } from 'models/store/appSettings';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface IShopWidgetProps extends IClassNameProps, IShopWidgetOwnProps, IShopWidgetStateProps {
  tag?: React.ComponentType<any> | string;
  contexts?: string[];
}
export interface IShopWidgetOwnProps {
  settings: any
}
export interface IShopWidgetState {
  products: IShopItem[];
  loading: boolean;
  presentFor?: { id: string };
  comment: string;
  selectedProduct: IShopItem;
  showModal: boolean;
  isHidePricesAndButtonBuy: boolean;
  isHideButtonBuy?: boolean;
  maxOrdersCount: number;
  maxOrdersPeriod: string;
  ordersCount: number;
  shopClosedText:string;
}

export interface IShopWidgetStateProps {
  currencyFormats: IStoreAppSetting;
  myThanksCount: number;
  edit:boolean;
}

export const mapStateToProps = (state: any) => ({
  edit: state.store.edit,
  currencyFormats: state.store.appSettings,
  myThanksCount: getAuthUser(state).extData.mythanksCount,
});

export const cnShopWidget = cn('ShopWidget');
