import React, { useState, useEffect, useRef, useMemo, FC, useCallback } from 'react';
import i18n from "../../../localizations/i18n";
import { mapStateToProps, mapDispatchToProps, IFiltersStateProps, IFiltersDispatchProps, IFiltersProps } from '../Filters.index';
import { IFiltersTimelineProps } from './Filters_type_timeline_h.index';
import { connect, useDispatch, useSelector } from 'react-redux';
import { IStateType as IState } from 'redux/store';

import { Translate } from 'localizations/Translate';


// import { AccordionMUI } from 'muicomponents/src/Accordion/Accordion'
import { Typography } from 'muicomponents/src/Typography/Typography'
import { Button } from 'muicomponents/src/Button/Button';
import { Checkbox } from 'muicomponents/src/Checkbox/Checkbox';
import { Chip } from 'muicomponents/src/Chip/Chip';
import { Box } from 'muicomponents/src/Box/Box';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import actions from 'redux/actionsTypes/Widgets';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';

import { cn } from '@bem-react/classname';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import { IItemsViewDat, ITimlineFilterData, onlyCat, useTimelineFilter } from './useTimelineFilter'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

const cnFilters = cn('FilterTimeline');

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  padding: '0 24px',
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0',
  },
  '& .MuiAccordionSummary-content': {
    margin: '14px 0',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '8px 24px',
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


// QUESTION в идеале типизировать
// подготавливаем filtr  для  всех компонентов 
const preparedFilters = (
  data: ITimlineFilterData[],
  enabledModules: { [s: string]: boolean },
  isAdmin: boolean) => data.filter((el: ITimlineFilterData) => {
    if (enabledModules[el.id] !== undefined && !enabledModules[el.id]) return false;
    if (el.adminOnly) return isAdmin
    return true;
  }).map((el: ITimlineFilterData) => ({
    title: <Translate i18nKey={el.title} />,
    value: el.value
  }));



export const TimelineFilter = ({
  context,
  // changeContextv3,
  relations,
  // enabledModules,
  // isAdmin,
}: {
  context: IFiltersTimelineProps['context']
  // isAdmin: boolean
  // enabledModules: { [s: string]: boolean }
  // changeContextv3: IFiltersTimelineProps['changeContextv3']
  relations: IFiltersTimelineProps['relations']
}) => {

  const enabledModules = useSelector((state: any) => state.store.appSettings.enabledModules) || {}
  const isAdmin = useSelector((state: any) => getAuthUser(state).baseData.isAdmin) || {}

  const dispath = useDispatch()
  const changeContextv3 = useCallback((payload: Array<{ [s: string]: any }>) => dispath({ type: actions.UPDATE_CONTEXTS, payload }), [dispath])

  const {
    handleDelete,
    setTypesListShown,
    typesListShown,
    defaultTimelineData,
    change,
    others,
    timlineData,
    setTimlineData,
    setOthers,
    categoriesArr,
    useMultyValues,
    defaultFilter
  } = useTimelineFilter({
    context,
    changeContextv3,
    relations
    // props
  })

  const pri = preparedFilters(defaultTimelineData, enabledModules, isAdmin)
  const radioItems = pri.filter(v => v.value !== "categories")
  const categories = pri.find(v => v.value === "categories")

  const radioValue = others[0]?.id === "categories" ? others[0]?.id : Array.isArray(context.filter) && context.filter[0] ? context.filter[0].id : context?.filter?.id || undefined


  return <MuiAccordion expanded={typesListShown} onChange={(_, expanded) => setTypesListShown(expanded)}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
    >
      <Stack className={cnFilters('ActiveTypes')} direction='row' flexWrap={'wrap'} gap={1}>
        <Typography variant='subtitle1' className={cnFilters('Title')}  ><Translate i18nKey={'pryaniky.filter.feed.title'} /></Typography>

        <>
          {
            Array.isArray(context?.filter) && context?.filter.map((chip) => <Chip
              label={<Translate i18nKey={chip.title} />}
              className={cnFilters('ActiveChip')}
              variant="outlined"
              onDelete={defaultFilter.value !== chip.value ? (() => handleDelete(chip.id)) : undefined}
            />)
          }
        </>
      </Stack>
    </AccordionSummary>
    <AccordionDetails>
      {typesListShown && <Box className={cnFilters('RadioGroup')}>


        <FormControl sx={{ marginLeft: '11px' }}>
          <RadioGroup
            row
            value={radioValue}
            onChange={(_, selectedEl) => {
              let finded = defaultTimelineData.find((el: any) => el.id === selectedEl)
              if (finded) {
                change([finded]);
                if (finded.value !== categories?.value)
                  setTypesListShown(false)
              }
            }}
          >
            {radioItems.map(item => <FormControlLabel value={item.value} control={<Radio />} label={item.title} />)}

            <Divider sx={{ flex: '1 1 100%', marginLeft: '-11px', marginTop: '8px', marginBottom: '8px' }} />

            {categories && <FormControlLabel value={categories.value} control={<Radio />} label={categories.title} />}

          </RadioGroup>
        </FormControl>

        <FormGroup sx={{ marginLeft: '27px', marginBottom: '8px' }} className={cnFilters('Categiries')}>
          {timlineData.map((el, index) => <FormControlLabel control={<Checkbox
            checked={el.isActive}
            onChange={(_, value: boolean) => {
              setTimlineData((prevState) => prevState.map((item) => item.id === el.id ? { ...item, isActive: value } : item))
              setOthers([onlyCat])
            }}
          />} label={<Translate i18nKey={el.title} />} />)}
        </FormGroup>

        <Button
          className={cnFilters('UseFilterBtn')}
          variant={'contained'}
          disabled={categoriesArr.length < 1}
          color={'primary'}
          size={'small'}
          onClick={() => {
            setTypesListShown(false)
            useMultyValues()
          }}>

          <Translate i18nKey={i18n.t('pryaniky.filter.feed.use')} />

        </Button>
      </Box>}
    </AccordionDetails>
  </MuiAccordion>
}


const FiltersTypeTimelineHPresenter: FC<IFiltersTimelineProps> = ({
  context,
  tag: Tag = 'div',
  enabledModules,
  itemsView,
  ...props
}) => {

  return (
    <>
      {/* // @ts-ignore  */}
      <Tag {...props} className={cnFilters({ type: 'timeline_h' }, [])} >

        <TimelineFilter
          // changeContextv3={props.changeContextv3}
          context={context}
          // enabledModules={enabledModules}
          // isAdmin={itemsView.isAdmin}
          relations={props.relations}
        />

      </Tag>
    </>
  )
}

export const FiltersTypeTimelineH = connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersTimelineProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(FiltersTypeTimelineHPresenter)