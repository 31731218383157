import React, { FC } from 'react'
import { IUnitsListProps, cnUnitsList } from './UnitsList.index'
import './UnitsList.scss'
import { Item } from './Item/Item'

export const UnitsList: FC<IUnitsListProps> = ({ className, units, sid }) => {

    return <div className={cnUnitsList({}, [className])}>
        {units.map((unit: any) =>
            <Item className={cnUnitsList('Item')} unit={unit} sid={sid} />
        )}
    </div>
}
