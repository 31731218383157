/**
 * @packageDocumentation
 * @module Widget_Settings_list
 */
export default {
  users: false,
  groups: false,
  groupsTree: false,
  badges: false,
  selectedUsers: [],
  selectedGroups: [],
  selectedBadges: []
}