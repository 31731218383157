import { i18n } from 'localization';
import { Box, Divider, Typography } from 'muicomponents/src';
import React, { FC, ComponentProps } from 'react';
import { cnShopProduct } from '../ProductCardMui/ProductCardMui.index';
import { YellowPurseBtn } from './YellowPurseBtn';
import { IClassNameProps } from '@bem-react/core';


interface IPriceElemProps extends ComponentProps<typeof Box> {
  fromPriceText: string | null
  price: number | string
  priceCurrencyText: string
}

export const PriceElem: FC<IPriceElemProps> = ({ fromPriceText, price, priceCurrencyText, className, ...props }: IPriceElemProps) => {
  return <Box {...props} className={cnShopProduct("Info-Prise", [className])} sx={{ display: 'flex', alignItems: 'center', ...props.sx }} >
    <YellowPurseBtn />
    <Box >
      <Typography variant='subtitle2' fontWeight='500' >{i18n.t('pryaniky.shop.price')}</Typography>
      <Typography variant='subtitle2' fontWeight='600'>{fromPriceText} {price} {priceCurrencyText}</Typography>
    </Box>
  </Box>
}