import React, { FC } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { SearchInput } from 'uielements/src';
// import { HelpTooltip } from 'blocks/HelpTooltip/HelpTooltip'
// import Item from './Item'
// import i18n from 'localizations/i18n';


export const Presenter: FC<IPresenterProps> = ({ className, search, isLoading = false, onChange }) => {
    return <div className={cnClassName({}, [className])}>
        <SearchInput
            loading={isLoading}
            value={search}
            className={cnClassName('Input')}
            onChange={onChange} />
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
