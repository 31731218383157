import React, { ComponentProps, FC } from "react";
import { ItemsListBody } from "muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody";
import { Box, Paper } from "muicomponents/src";

export const TasksBody: FC<ComponentProps<typeof ItemsListBody>> = ({
    children,
    ...props
}) => {
    return (
        <ItemsListBody {...props} BodyComponent={Box}>
            <Paper
                sx={{
                    minWidth: '100%',
                    width: 'min-content'
                }}
            >
                {children}
            </Paper>
        </ItemsListBody>
    );
};