import React, { FC, useEffect, useState, useMemo, ComponentProps, createContext, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { cnNewsCreator, NNewsCreator } from './Creator.index';
import './Creator.scss';
import {
    Box
} from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';
import { withComponentEnjector } from 'utils/src/ComponentInjector';
import { Tabs } from 'muicomponents/src/Tabs';
import { NewsSender } from '../Sender';
import { NewsCreateContext } from './Creator.constants';

import { useHistory, useLocation } from 'react-router';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { useInitNewsCreator, useNewsCreator } from './Creator.hooks';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getNewsCreateModule } from '../redux/module.newsCreator';
import { newsTypesIcons } from './newsTypesIcons';
import { SendNewsDialog } from './SendDialog/SendDialog';
import { Collapse } from 'muicomponents/src/Transitions/Transitions';
import { getAppDesignColors } from 'utils/src/CommonRedux/base/selectors';
import { setBrightnessToHSL } from 'utils/src/utils.colors';
import { toast } from 'react-toastify';
import { Translate } from 'localizations/Translate';

const StyledDrop = styled(Box)({
    // background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.84) 64.58%, #FFFFFF 100%)',
    background: 'linear-gradient(rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.84) 75%, rgb(255, 255, 256) 100%)',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
    pointerEvents: 'none'
}) as typeof Box;

export const TabsWrapper = styled('div')({
    display: 'flex',
    borderRadius: 4
});

const StyledTabs = styled(Tabs)({
    background: "#0066CC",
    borderRadius: "4px",
    color: "#fff",
    position: 'relative',
    [`& .MuiTabs-scrollButtons`]: {
        position: 'absolute',
        top: 0,
        height: '100%',
        background: 'inherit',
        zIndex: 1,
        "&.Mui-disabled": {
            display: 'none'
        },
        "&:first-child": {
            left: 0
        },
        "&:last-child": {
            right: 0
        }
    },
    [`& .MuiTabs-scroller`]: {
        borderLeft: "1px solid #0000004D",
        borderRight: "1px solid #0000004D"
    },
    '& .MuiTabs-indicator': {
        display: 'none'
    }
}) as typeof Tabs;

const TabPropsSX: NonNullable<ComponentProps<typeof Tabs>['TabProps']>['sx'] = {
    color: "#fff",
    [`&:nth-child(n + 2)`]: {
        boxSizing: 'border-box',
        borderLeft: "1px solid #0000004D"
    },
    [`&.Mui-selected`]: {
        color: "#fff",
        background: "#004E9C"
    }
};

const TabButtonModal = styled('div')({
    display: 'inline-flex',
    flexDirection: 'column',
    gap: 8,
    width: 116,
    minHeight: 116,
    alignItems: 'center',
    justifyContent: 'flex-start'
});

const TabButtonModalImg = styled('img')({
    width: 60,
    height: 60,
    objectFit: 'contain'
});

/**
 * component to select and send post type
 * @param param0
 * @returns 
 */
const NewsCreatorPresenter: FC<NNewsCreator.Props> = ({
    groupId,
    workflowId,
    newsType,
    getNode,
    className,
    creatorOptions,
    children
}) => {

    const hashDialogInited = useRef(false);

    const history = useHistory();

    const { hash } = useLocation();

    const {
        creatorId
    } = useInitNewsCreator();

    const {
        postTypes,
        selectedNews: currentType,
        clearEditor,
        showPostFormInDialog,
        selectPostType
    } = useNewsCreator(creatorId, groupId, workflowId, creatorOptions, newsType);

    const Node = useMemo(() => {
        const currentRenderName = postTypes?.find((el: any) => el.id === currentType)?.componentRenderName;
        return currentRenderName && getNode(`${currentRenderName}`) || null;
    }, [currentType]);

    const [ newsCreatorContext, setNewsCreatorContext ] = useState<ComponentProps<typeof NewsCreateContext['Provider']>['value']>({
        creatorId,
        groupId: groupId || -1,
        workflowId
    });

    useEffect(() => {
        setNewsCreatorContext({
            creatorId,
            groupId: groupId || -1,
            workflowId
        });
    }, [creatorId, groupId, workflowId]);

    // get tabs from postTypes
    const tabs = useMemo<ComponentProps<typeof StyledTabs>['tabs']>(() => {
        const allowedTabs = postTypes?.filter((el: any) => newsType ? el.id.includes(newsType) : true) || [];
        // const allowedTabs2 = postTypes?.filter((el: any) => newsType ? el.id === newsType : true) || [];
        // const allowedTabs = [ allowedTabs2[0], allowedTabs2[1] ].filter(Boolean) || [];
        if(showPostFormInDialog) {
            return allowedTabs.map((el: any) => {
                const icon = newsTypesIcons[el.componentRenderName as keyof typeof newsTypesIcons] || newsTypesIcons.news;
                return {
                    id: el.id,
                    label: <TabButtonModal className={'TabModal'}>
                        <TabButtonModalImg src={icon} />
                        {el.displayName}
                    </TabButtonModal>,
                    onClick: () => {
                        SendNewsDialog({ creatorId, newsType: el.id, groupId, workflowId }).then(({ success }) => {
                            if(success)
                                toast.success(<Translate i18nKey={'pryaniky.news.post.success'} />)
                        });
                    }
                }
            });
        }
        return allowedTabs.map((el: any) => ({
            id: el.id,
            label: el.displayName
        }));
    }, [ postTypes, showPostFormInDialog, newsType ]);

    // get active post id
    const activePostId = useMemo(() => {
        const finded = postTypes?.find((el: any) => el.id === currentType);
        if(!finded) return -1;
        return finded.id;
    }, [ currentType ]);

    /**
     * use effect to first render init post form if activePostId is defined
     */
    useEffect(() => {
        const currentTypeInHash = hash.slice(1);
        /**
         * if first render without hash then disable change activePostId open dialog
         */
        if(!currentTypeInHash) {
            hashDialogInited.current = true;
        }
        if(currentTypeInHash && tabs && tabs.length && activePostId !== -1 && !hashDialogInited.current && showPostFormInDialog) {
            SendNewsDialog({ creatorId, newsType: activePostId, groupId, workflowId });
            hashDialogInited.current = true;
        }
    }, [tabs, activePostId, showPostFormInDialog]);

    // clear current data model when clearEditor is true
    useEffect(() => {
        if(clearEditor) selectPostType(activePostId, true);
    }, [clearEditor]);

    const handleSelectPost: ComponentProps<typeof Tabs>['onChange'] = (event, id) => {
        // selectPostType(id);
        history.push({
            hash: id
        });
    };

    /**
     * if hash change then change active post
     */
    useDidUpdateEffect(() => {
        const currentTypeInHash = hash.slice(1);
        if(currentTypeInHash) {
            const postType = postTypes.find((type: any) => type.idNumber.toString() === currentTypeInHash || type.id === currentTypeInHash);
            postType && selectPostType(postType.id);
        } else {
            if(!showPostFormInDialog) selectPostType(newsType || postTypes[0].id);
        }
    }, [hash]);

    // if currentType is undefined then set current type is first element in postTypes
    useEffect(() => {
        if(!currentType && postTypes.length) {
            const currentTypeInHash = hash.slice(1);
            if(currentTypeInHash) {
                const postType = postTypes.find((type: any) => type.idNumber.toString() === currentTypeInHash || type.id === currentTypeInHash);
                postType && selectPostType(postType.id);
            } else {
                if(!showPostFormInDialog) selectPostType(newsType || postTypes[0].id);
            }
            // setActivePostId('polls');
        }
    }, [postTypes]);

    /** hide gradient and small size form */
    const [ clicked, setClicked ] = useState(showPostFormInDialog ? true : false);

    const collapsedSize = useMemo(() => {
        if((window as any).postFormCollapsedSize) return (window as any).postFormCollapsedSize;
        return 200;
    }, []);

    const colors = useSelector(getAppDesignColors);

    const bgColor = useMemo(() => {
        if(!colors?.primaryColor3) return '';
        return setBrightnessToHSL(colors?.primaryColor3, 90);
    }, [colors?.primaryColor3]);

    if(!tabs.length) return null;

    return (
        <Collapse
            className={cnNewsCreator({}, [className, `Selected-${activePostId}`])}
            sx={{
                p: 2,
                position: 'relative'
            }}
            in={clicked}
            onClick={() => {
                !clicked && setClicked(true);
            }}
            collapsedSize={collapsedSize}
        >
            {!showPostFormInDialog && !clicked && <StyledDrop />}
            <TabsWrapper
                className={cnNewsCreator('TabsWrapper')}
                sx={{
                    background: bgColor
                }}
            >
                <StyledTabs
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile={true}
                    // sx={TabsSX}
                    TabProps={{
                        sx: TabPropsSX
                    }}
                    className={cnNewsCreator('Tabs', ['primaryColor3-bg'])}
                    value={showPostFormInDialog ? undefined : activePostId}
                    tabs={tabs}
                    onChange={handleSelectPost}
                />
            </TabsWrapper>
            {/* {
                !Node && 'эта новость не готова, зайдите позже'
            } */}
            {
                !showPostFormInDialog && Node && 
                <NewsCreateContext.Provider value={newsCreatorContext}>
                    <NewsSender>
                        {Node}
                    </NewsSender>
                </NewsCreateContext.Provider>
            }
            {children}
        </Collapse>
    )
}

export const NewsCreatorModule: FC<ComponentProps<typeof NewsCreatorPresenter>> = ({
  ...props
}) => {
  return (
    <DynamicModuleLoader modules={[getNewsCreateModule()]}>
      <NewsCreatorPresenter {...props} />
    </DynamicModuleLoader>
  );
};

export const NewsCreator = withComponentEnjector<NNewsCreator.Props>(NewsCreatorModule, ['news_creator_mui5']);
// export const NewsCreator = connect(
//     mapNewsCreatorStateToProps,
//     mapNewsCreatorActionsToProps
// )(withComponentEnjector<NNewsCreator.Props>(NewsCreatorPresenter, ['news_creator_mui5']))