import { IAnswerLayoutProps } from './AnswerLayout.index'
import React, { memo } from 'react'
import './AnswerLayout.scss';

export const AnswerLayout: React.FC<IAnswerLayoutProps> = memo(({ width = '0px', children, ...props }) => {
    return <li className='AnswerLayout' {...props}>
        <div className='AnswerProgress' style={{ width }} />
        <div className='AnswerContent horizontalPadding'>
            {children}
        </div>
    </li>
})