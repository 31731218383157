import { v1 as uuid } from "uuid";
export interface IKPIField<T = any> {
    data: T
    isEditable: boolean
    isVisible: boolean
    type: "String" | "DateTime" | "Boolean" | "List`1"
}

export interface IKPIItem {
    description: IKPIField<string>
    dueDate: IKPIField<string>
    goal: IKPIField<string>
    id: string
    imgId: null | string
    imgUrl: null | string
    isComplete: IKPIField<boolean>
    isDeletable: boolean
    moderators: IKPIField<any[]>
    name: IKPIField<string>
    percentage: number
    status: "progress"
    type: IKPIField<string>

    meta?: {
        isCreating: boolean
        isEdit: boolean
    }
}

export type TItemHash = { [key: string]: IKPIItem }


export const createDefault = (): IKPIItem => ({
    description: {
        data: '',
        isEditable: true,
        isVisible: true,
        type: "String"
    },
    dueDate: {
        data: (new Date()).toISOString(),
        isEditable: true,
        isVisible: true,
        type: 'DateTime'
    },
    goal: {
        data: '',
        isEditable: true,
        isVisible: true,
        type: 'String'
    },
    id: uuid(),
    imgId: null,
    imgUrl: null,
    isComplete: {
        data: false,
        isEditable: false,
        isVisible: false,
        type: 'Boolean'
    },
    isDeletable: true,
    moderators: {
        data: [],
        isEditable: false,
        isVisible: false,
        type: 'List`1'
    },
    name: {
        data: '',
        isEditable: true,
        isVisible: true,
        type: 'String'
    },
    percentage: 0,
    status: "progress",
    type: {
        data: '',
        isEditable: false,
        isVisible: true,
        type: 'String'
    },

    meta: {
        isCreating: true,
        isEdit: true
    }
})