import React from 'react';
import i18n from 'localizations/i18n';
import { cn } from '@bem-react/classname';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { IBarProps, cnBar } from './MountainRaring-Bar.index';
// import Popover from 'react-tiny-popover';
import './MountainRating-Bar.scss';
import { IMountainRatingUserInfo } from '../MountainRating.index';
import { API, checkResponseStatus } from 'utils/src/utils';
import { IGroupFullInfo } from 'utils/src/requests/models/api.groups';
import { IUserBaseData } from 'utils/src/requests/models/api.users';
import { Spinner } from 'uielements/src';
import { getOffsetSum } from 'utils/src/utils';

const getCoords = (elem: HTMLElement) => {
  const box = elem.getBoundingClientRect();
  return Object.assign(getOffsetSum(elem), {
    h: box.height,
    wTop: box.top,
    wLeft: box.left,
    width: box.width,
    rect: box,
    sWidth: elem.scrollWidth,
    oWidth: elem.offsetWidth
  });
};

const cnPopover = cn('MountainRating-Popover');

// const root = document.getElementById('root') as HTMLDivElement;

export const Bar: React.FC<IBarProps> = ({ data, width, isMe, containerHeight, paddingTop, minHeight, type }) => {
  const [isOpenPopover, setIsOpenPopover] = React.useState(false);
  const [wrapperCord, setWrapperCord] = React.useState<any>(null);
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [popoverData, setPopoverData] = React.useState<any>(data);
  const [isLoading, setIsLoading] = React.useState(true);
  if (!data) {
    return (
      <div
        className={cnBar('Wrapper')}
        style={{
          minWidth: width + 2,
        }}
      />
    );
  }
  const avatarRadius = (width - 20) / 2;
  const positionAngle = (Math.PI * 45) / 180;
  const curFraction = parseFloat(data.percantage);
  const height = ((containerHeight - minHeight - paddingTop) * curFraction) / 100 + minHeight;
  const isGroupBar = typeof data.id === 'number';
  const showPopover = () => {
    setIsOpenPopover(true);
    if (JSON.stringify(popoverData) === JSON.stringify(data)) {
      const apiMethod = isGroupBar ? API.groups.byId : API.users.byId;
      const opts = isGroupBar ? void 0 : { fullData: false, include: 'base' };
      const mapResponseToState = isGroupBar ? mapGroupResponseToState : mapUserResponseToState;
      apiMethod(data.id, opts).r.then(response => {
        if (checkResponseStatus(response)) {
          setPopoverData({ ...data, ...mapResponseToState(response.data) });
          setIsLoading(false);
        }
      });
    }
  };
  let alingLeft = false;
  if (wrapperCord && (window.innerWidth / ( wrapperCord.wLeft) <= 2)) {
    alingLeft = true
  }
  const hidePopover = () => setIsOpenPopover(false);
  const barStyle = {
    height: (isOpenPopover ? '100%' : height),
    zIndex: (isOpenPopover ? 110 : 90),
    background: isOpenPopover ? (isMe ? 'rgb(251,184,0, 0.8)' : data.pos === '1' ? 'rgba(0, 157, 63, 0.8)' : 'rgba(255, 255, 255, 0.8)') : undefined,
    borderTopLeftRadius: (isOpenPopover ? (alingLeft ? 0 : width / 2) : width / 2),
    borderTopRightRadius: (isOpenPopover ? (alingLeft ? width / 2 : 0) : width / 2),
  }
  const getRef = (r: any) => {
    if(!r) return;
    const cord = getCoords(r);
    (!wrapperCord || cord.wLeft !== wrapperCord.wLeft) && setWrapperCord(cord)
  }
  return (
    <div ref={getRef} className={cnBar('Wrapper')} style={{ paddingTop }}>
      <div
        // title={data.displayName}
        className={cnBar({ first: data.pos === '1', me: isMe, open: isOpenPopover })}

        style={barStyle}>
        {/* <Popover
          padding={15}
          isOpen={isOpenPopover}
          content={PopoverContent(popoverData, isLoading, isGroupBar ? renderGroupInfo : renderUserInfo)}
          containerStyle={{ zIndex: '9999' }}
          position="bottom"
          contentDestination={root}> */}
        <div
          style={{ zIndex: (isOpenPopover ? 110 : 90), }}
          onMouseEnter={showPopover}
          onMouseLeave={hidePopover}
          className={cnBar('User')}
          onClick={() => isOpenModal ? setIsOpenModal(false) : setIsOpenModal(true)}

        >
          <Avatar imgUrl={data.imgUrl} size={avatarRadius * 2} className={cnBar('Avatar')} noBorder>
            <span
              style={{
                left: avatarRadius + avatarRadius * Math.cos(positionAngle) - 10,
                top: avatarRadius + avatarRadius * Math.sin(positionAngle) - 10,
              }}
              className={cnBar('Position')}
              dangerouslySetInnerHTML={{ __html: data.pos }}
            />
          </Avatar>
          <div className={cnBar('Name')}>{isMe && type === 'Common' ? i18n.t('me') : data.displayName}</div>
          <div className={cnBar('Value')} title={data.value}>
            {data.curValue}
          </div>
        </div>

        {/* </Popover> */}


        <div style={{
          right: alingLeft ? 71 : undefined,
          left: alingLeft ? undefined : 71,     
          borderTopRightRadius: alingLeft ? undefined : width / 2,
          borderTopLeftRadius: alingLeft ? width / 2 : undefined,
          width: isOpenPopover ? undefined : 0
        }} className={cnBar('BigData')} >

          <div className={cnBar('BidDataLayout')} >
            {PopoverContent(popoverData, isLoading, isGroupBar ? renderGroupInfo : renderUserInfo)}
          </div>
        </div>


      </div>

    </div>
  );
};

const PopoverContent = (
  data: IMountainRatingUserInfo,
  isLoading: boolean,
  renderInfo: (info: any) => React.ReactNode
) => {
  return (
    <div className={cnPopover()}>
      {/* <Avatar
        className={cnPopover('Avatar')}
        imgUrl={!isEmptyGuid(data.imgId) ? data.imgUrl : undefined}
        name={data.displayName}
        size={56}
        noBorderRadius
      /> */}
      <div className={cnPopover('Info')}>
        <h3 className={cnPopover('Name')}>{data.displayName}</h3>
        {isLoading ? <Spinner className={cnPopover('Splinner')} /> : renderInfo(data)}
      </div>
    </div>
  );
};

const mapGroupResponseToState = (data: IGroupFullInfo) => ({
  numOfUsers: data.userCount,
  description: data.description,
});
const mapUserResponseToState = (data: IUserBaseData) => ({
  email: data.eMail,
  phone: data.phone,
  city: data.region,
});

type UserContacts = ReturnType<typeof mapUserResponseToState>;

const renderUserInfo = (info: UserContacts & { position: string; division: string, label: string }) => {
  const description = [info.position, info.division].filter(Boolean).join('\n');
  return (
    <>
      {description && <p className={cnPopover('Description')}>{description}</p>}
      {(['email', 'phone', 'city'] as Array<keyof UserContacts>).map(
        key =>
          info[key] && (
            <p className={cnPopover('Contact')}>
              <span className={cnPopover('Contact-Label')}>
                {i18n.t(key)}:{'\u00a0'}
              </span>
              {info[key]}
            </p>
          )
      )}
      {info.label && <div dangerouslySetInnerHTML={{ __html: info.label }} />}
      {/* {info && <div dangerouslySetInnerHTML={{
        __html: `o количество выездных мероприятий: %oколичество выездных мероприятий%

o выполнение норматива по выездным мероприятиям 60% x60%: %выполнение норматива по выездным мероприятиям 60% x60%%

o AR (процент платящих ссуд от общего портфеля): %AR (процент платящих ссуд от общего портфеля)%

o 720+: %720+%

o 180-720: %180-720%

o % выполнения плана: %% выполнения плана%` }} />} */}
    </>
  );
};

const renderGroupInfo = (info: ReturnType<typeof mapGroupResponseToState>) => (
  <>
    {info.description && <p className={cnPopover('Description')}>{info.description}</p>}
    <p className={cnPopover('Contact')}>
      <span className={cnPopover('Contact-Label')}>
        {i18n.t('number of users')}:{'\u00a0'}
      </span>
      {info.numOfUsers}
    </p>
  </>
);
