/**
 * @packageDocumentation
 * @module Widget_Settings_info
 */
import { JFdictionary } from "../dictionary";

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  // direction: ,
  elements: [
    {
      type: renderers.select,
      scope: "#/properties/type",
    },
    {
      type: renderers.groupsInput,
      scope: "#/properties/selectedGroups",
      rule: {
        effect: "SHOW",
        condition: {
          type: "LEAF",
          scope: "#/properties/type",
          expectedValue: 'groups'
        }
      },
    },
    {
      type: renderers.tagsInput,
      scope: "#/properties/selectedBadges",
      rule: {
        effect: "SHOW",
        condition: {
          type: "LEAF",
          scope: "#/properties/type",
          expectedValue: 'badges'
        }
      },
    },
    {
      type: renderers.usersInput,
      scope: "#/properties/selectedUsers",
      rule: {
        effect: "SHOW",
        condition: {
          type: "LEAF",
          scope: "#/properties/type",
          expectedValue: 'users'
        }
      },
    }
  ]
}