import { cn } from '@bem-react/classname';

export interface ISelectProps {
    

    onChange: (items: any[]) => void;
    value: any[];

    variants: any[];

    htmlId?: string;
    className?: string
    
    excludeVariants?: any[];
    disabledVariants?: any[];
    excludeFilter?: (values: any[], selected: any[])  => any[]

    placeholder?: string;

    maxSelectCount?: number;

    multi?: boolean;

    children?: React.ReactNode;
    
    openToggler?: (open: boolean, setOpen: (value: boolean) => void) => React.ReactNode;

    onChangeFilter?: (value: string) => void;
    filterFunc?: (values: any[], filterStr: string) => any[];
    disasbleFilter?: boolean;
    filter?: (
        inputRef: React.RefObject<HTMLInputElement>,
        filterValue: string,
        filterChange: (value: string) => void,
        onFocus: () => void,
        onBlur: () => void
    ) => React.ReactNode;
    filterValue?: string;

    closeOnSelect?: boolean;
    
    
    icon?: React.ReactNode;
    
    items?: (values: any, onClick: (item: any) => () => void, focusIndex?: number, selected?: any[], disabledVariants?: any[]) => React.ReactNode;
    itemChip?: (item: any, remove: any, i?: number) => React.ReactNode;
}

export const cnSelect = cn('PrnSelect')