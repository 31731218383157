import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    loadTracksList    
} from '../../redux/actions/TracksUse'
import {
    ILearningTracks
} from '../../redux/reducers/TracksUse'
import {
    getTracksObject
} from '../../redux/sagas/TracksUse/selectors'
import {  } from 'utils/src/requests/models/api.learningTracks'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace LerningTrack {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace TrackList {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps //& RouteComponentProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {

        }

        export interface IStateProps extends ILearningTracks {

        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = LerningTrack.TrackList.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    ...getTracksObject(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadTracksList
}, dispatch);

////////////

export const cnClassName = cn('TrackUseList');