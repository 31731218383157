import {
  USERS_SET_USERS_HIERARCHY,
} from './actions';

import { IHierarchyActions } from './actions.interfaces'

import { IUserSettings, IUserSettingsList } from './interfaces'
import { isUndefined } from 'lodash';

import { IUserHierarchyData } from 'utils/src/requests/models/admin.userSettings'

//  const baseState: IUserSettings = {
//     userSettings: {} as IUserSettingsData,
//     listFields: {} as IUserSettingsList

//   }

const baseState: IUserSettings = {  
  usersHierarchy: [] as IUserHierarchyData[]
}

export const emptyHierarchy = {
  users: null,
  id: "",
  name: "",
  headname: "",
  subname: "",
  showHeadOnProfile: false,
  showSubOnProfile: false,
  pageUrl: "",
  totalCount: 0,
}
export const hierarchyReducer = (state = baseState, action: IHierarchyActions): IUserSettings => {
  switch (action.type) {
    case USERS_SET_USERS_HIERARCHY: {
      return {
        ...state,
        usersHierarchy: action.payload     
        

      }
    }
    default:
      return state

  }
}