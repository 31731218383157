import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import i18n from '../localizations/i18n';

interface IGroups {
  groups: string;
  filters: string;
}

export const groups = (): IWidget<IColumn[]> => {
  const ids = guids<IGroups>(['filters', 'groups']);
  return generateWidget<IColumn[]>({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            id: ids.groups,
            type: widgets.types.list + '/groups',
            // relations: [],
          }),
        ],
        styles: {
          width: '72%',
        },
      }),
      generateColumn({
        items: [
          generateWidget({
            id: ids.filters,
            type: `${widgets.types.filters}/groups`,
            // relations: [ids.groups],
            // data: [
            //   {
            //     name: i18n.t('pryaniky.filter.groups.title'),
            //     elements: [
            //       {
            //         id: 'all',
            //         title: i18n.t('pryaniky.filter.groups.all'),
            //         type: 'type',
            //         value: 'all',
            //       },
            //       {
            //         id: 'my',
            //         title: i18n.t('pryaniky.filter.groups.my'),
            //         type: 'type',
            //         value: 'my',
            //       },
            //       {
            //         id: 'archived',
            //         title: i18n.t('pryaniky.filter.groups.archived'),
            //         type: 'type',
            //         value: 'archived',
            //       },
            //     ],
            //   },
            // ],
          }),
        ],
        styles: {
          width: '28%',
        },
        mobileHidden: true,
      }),
    ],
  });
}