import { User, BaseNews, defaultBaseNews, defaultUser, defaultGroup } from '../BaseType'
// import { Group } from 'News/types/baseNews'

export interface NewsFeedback extends BaseNews {
    newstype: 10;
    feedback: IFeedbackInfo;
}

export interface IFeedbackInfo {
    comment: string;
    senderContact: string;
    senderId: string;
    senderName: string;
    value: number;
}

export const defaultNewsFeedback: NewsFeedback = {
    ...defaultBaseNews,
    newstype: 10,
    feedback: {
        comment: '',
        senderContact: '',
        senderId: '',
        senderName: '',
        value: 0
    }
}