import { IClassNameProps } from '@bem-react/core'
import { cn } from '@bem-react/classname'

import { Dispatch } from 'redux'
import actions from 'redux/actionsTypes/Widgets';

export interface IFiltersProps extends IClassNameProps, IFiltersStateProps, IFiltersDispatchProps {
  tag?: 'div'
  data: any[]
  context: any
  relations: string[]
  widgets?: any;
}

export const cnStatisticFilters = cn('StatisticFilters')

export interface IFiltersStateProps {
  edit: boolean
}

export type IFiltersDispatchProps = ReturnType<typeof mapDispatchToProps>

export const mapStateToProps = (state: any, props: IFiltersProps) => ({
  edit: state.store.edit,
})

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeContext: (paths: string[], innerPath: string, value: any) =>
    dispatch({ type: 'CHANGE_CONTEXT', payload: { paths, innerPath, value } }),
  changeContextv3: (payload: Array<{ [s: string]: any }>) =>
    dispatch({ type: actions.UPDATE_CONTEXTS, payload }),
})

export const cnFilter = cn('StatisticFilters')
