import actions from '../actionsTypes/LMSTO';
import { union } from 'lodash'

const zeroId = "00000000-0000-0000-0000-000000000000";

const pagenWorkCreator = (count: number = 10) => ({
    count,
    isLoading: true,
    isFinished: false
})

const createAllowTestPlay = () => ({
    cid: null,
    isLoading: false,
    isAllow: false,
    isTested: false,
})

interface IStatisticSetting {
    countArchive: boolean,
    isMandatory: boolean,
    endDate: string
    startDate: string,
    search: string,
    group?: number[]
    course?: string[]
    userProfileAdditionlFields?: any[]
}
const createStatisticSetting = (): IStatisticSetting => {
    const currentDate = new Date()
    const startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 6)
    return {
        countArchive: false,
        isMandatory: false,
        endDate: currentDate.toISOString(),
        startDate: startDate.toISOString(),
        search: '',
    }
}

export const defaultLMSTO: any = {
    courses: {
        values: {},
        results: [],
        count: 12,
        isLoading: false,
        isFinished: false
    },
    badgesList: [],
    unitsReplys: {
        values: {},
        keys: [],
        // count: 10,
        isLoading: false,
        isFinished: false,
        requestOptions: {
            // "filter.CourseId": undefined,
            // "filter.Count": 10,
            // 'filter.AuthorId': undefined,
            // "filter.SkipCount": 0,
            // 'filter.Search': undefined,
            // 'filter.UnitId': undefined
        }
    },
    categories: {
        entities: { values: {} },
        result: [],
    },
    units: {

    },
    unitsversions: {
        //unitId: [vaersionsDate]
    },
    questions: {

    },
    answers: {

    },
    sessions: {

    },
    loadWorker: {

    },
    students: {
        values: {},
        ids: [],
        courseId: zeroId,
        filters: {
            'filter.sessionIds': [],
            'filter.CompletePercentageMax': 100,
            'filter.CompletePercentageMin': 0,
            'filter.completedUnits': [],
            // 'filter.filterByUserIds': [],
            // 'filter.deadline': '',
            // 'filter.grade': true,
            'filter.status': 'all',
            // 'filter.includeUnitStat': true,
            'filter.search': '',
            'filter.getDeleted': false,
        },
        currentType: '',
        ...pagenWorkCreator()
    },
    sessionsWork: pagenWorkCreator(),
    moderation: {
        answers: {},
        units: {},
        questions: {},
        answersIds: [],
        courseId: zeroId,
        ...pagenWorkCreator(),
        filters: {
            'filter.sessionIds': [],
            'filter.filterByUserIds': [],
            'filter.filterByQuestionIds': [],
            // 'filter.isNeedModeration': true,
            'filter.filterByStatus': 'WaitModeration',
            'filter.search': '',
            // 'filter.skipCount': 0,
            // 'filter.count': 30
        },
        lists: {
            main: []
        }
    },
    users: {
    },
    requestsActivity: {

    },
    unitErrors: {

    },
    questionErrors: {

    },
    answerErrors: {

    },
    allowTestPlay: createAllowTestPlay(),
    statistic: {
        studentsstatus: {
            active: [],
            archive: []
        },
        testresults: [],
        unitsattendance: [],
        commentscount: []
    },
    statisticFull: {
        userscoursesinfohead: {
            trainingLevel: 0,
            total: 0,
            inProgress: 0,
            complete: 0,
            failed: 0,
            pieChart: []
        },
        userStatCoursesList: {
            values: {},
            keys: [],
            isFinished: false,
            count: 10,
            userId: null
        },
        coursesStatList: {
            values: {},
            keys: [],
            isFinished: false,
            count: 10
        },
        settings: createStatisticSetting(),
        userscoursesinfolist: {
            values: {},
            keys: [],
            isFinished: false,
            count: 10
        }
    }
};



export const updateUnit = (uid: string, payload: any, state: any) => ({
    units: {
        ...state.units,
        [uid]: {
            ...state.units[uid],
            ...payload
        }
    }
})

export function lmsTeacherOfficeReducer(state = defaultLMSTO, action: any) {
    switch (action.type) {
        case actions.SET_BADGES: {
            const { badges: badgesList } = action.payload
            return {
                ...state,
                badgesList
            }
        }
        case actions.APPEND_UNITS_REPLY: {
            const { values, keys, isFinished } = action.payload
            return {
                ...state,
                unitsReplys: {
                    ...state.unitsReplys,
                    values: { ...state.unitsReplys.values, ...values },
                    keys: union(state.unitsReplys.keys, keys),
                    isFinished
                }
            }
        }
        case actions.ADD_COURSES_STAT_LIST: {
            const { values, keys, clean = false } = action.payload
            return {
                ...state,
                statisticFull: {
                    ...state.statisticFull,
                    coursesStatList: {
                        ...state.statisticFull.coursesStatList,
                        values: { ...state.statisticFull.coursesStatList.values, ...values },
                        keys: clean ? keys : union(state.statisticFull.coursesStatList.keys, keys),
                        isFinished: keys.length < state.statisticFull.coursesStatList.count
                    }
                }
            }
        }
        case actions.ADD_USERS_COURSES_INFO_LIST: {
            const { values, keys, clean = false } = action.payload
            return {
                ...state,
                statisticFull: {
                    ...state.statisticFull,
                    userscoursesinfolist: {
                        ...state.statisticFull.userscoursesinfolist,
                        values: { ...state.statisticFull.userscoursesinfolist.values, ...values },
                        keys: clean ? keys : union(state.statisticFull.userscoursesinfolist.keys, keys),
                        isFinished: keys.length < state.statisticFull.userscoursesinfolist.count
                    }
                }
            }
        }
        case actions.ADD_USER_STAT_COURSES_LIST: {
            const { values, keys, userId, clean = false } = action.payload
            return {
                ...state,
                statisticFull: {
                    ...state.statisticFull,
                    userStatCoursesList: {
                        ...state.statisticFull.userStatCoursesList,
                        values: { ...state.statisticFull.userStatCoursesList.values, ...values },
                        keys: clean ? keys : union(state.statisticFull.userStatCoursesList.keys, keys),
                        isFinished: keys.length < state.statisticFull.userStatCoursesList.count,
                        userId
                    }
                }
            }
        }
        case actions.SET_USER_STAT_INFO: {
            return {
                ...state,
                statisticFull: {
                    ...state.statisticFull,
                    userscoursesinfolist: {
                        ...state.statisticFull.userscoursesinfolist,
                        values: {
                            ...state.statisticFull.userscoursesinfolist.values,
                            [action.payload.user.id]: action.payload,
                        },
                    }
                }
            }
        }
        case actions.PATH_USERS_COURSES_INFO_HEAD: {
            return {
                ...state,
                statisticFull: {
                    ...state.statisticFull,
                    userscoursesinfohead: {
                        ...state.statisticFull.userscoursesinfohead,
                        ...action.payload
                    }
                }
            }
        }
        case actions.PATH_FULL_STATISTIC_SETTING: {
            return {
                ...state,
                statisticFull: {
                    ...state.statisticFull,
                    settings: {
                        ...state.statisticFull.settings,
                        ...action.payload
                    }
                }
            }
        }
        case actions.CHANGE_UNITS_REPLY_FIELD: {
            const { key, value } = action.payload
            return {
                ...state,
                unitsReplys: {
                    ...state.unitsReplys,
                    [key]: value
                }
            }
        }
        case actions.SET_STAT_COMMENTS_COUNT: {
            return {
                ...state,
                statistic: {
                    ...state.statistic,
                    commentscount: action.payload.data
                }
            }
        }
        case actions.SET_UNITS_ATTENDANCE: {
            return {
                ...state,
                statistic: {
                    ...state.statistic,
                    unitsattendance: action.payload.data
                }
            }
        }
        case actions.SET_STUDENTS_STATUS: {
            return {
                ...state,
                statistic: {
                    ...state.statistic,
                    studentsstatus: action.payload.data
                }
            }
        }
        case actions.SET_TEST_RESULTS: {
            return {
                ...state,
                statistic: {
                    ...state.statistic,
                    testresults: action.payload.data
                }
            }
        }
        case actions.SET_ALLOW_PLAY: {
            return {
                ...state,
                allowTestPlay: {
                    ...state.allowTestPlay,
                    ...action.payload
                }
            }
        }
        case actions.SET_UNIT_VERSIONS: {
            const { uid, versions } = action.payload
            return {
                ...state,
                unitsversions: {
                    ...state.unitsversions,
                    [uid]: versions
                }
            }
        }
        case actions.UNIT_WITH_ERROR: {
            const { uid, errors } = action.payload
            return {
                ...state,
                unitErrors: {
                    ...state.unitErrors,
                    [uid]: errors ? {
                        ...state.unitErrors[uid],
                        ...errors
                    } : undefined
                }
            }
        }
        case actions.QUESTION_WITH_ERROR: {
            const { uid, errors, qid } = action.payload
            return {
                ...state,
                questionErrors: {
                    ...state.questionErrors,
                    [qid]: errors ? {
                        ...state.questionErrors[qid],
                        ...errors
                    } : undefined
                }
            }
        }
        case actions.ANSWER_WITH_ERROR: {
            const { uid, errors, qid, aid } = action.payload
            return {
                ...state,
                answerErrors: {
                    ...state.answerErrors,
                    [aid]: errors ? {
                        ...state.answerErrors[aid],
                        ...errors
                    } : undefined
                }
            }
        }
        case actions.SET_CATEGORIES: {
            return {
                ...state,
                categories: {
                    ...action.payload
                }
            }
        }
        case actions.ADD_COURSE: {
            return {
                ...state,
                courses: {
                    ...state.courses,
                    values: {
                        ...state.courses.values,
                        [action.payload.id]: action.payload
                    },
                    results: [action.payload.id, ...state.courses.results],

                }
            }
        }
        case actions.SET_AUTHOR_COURSES:
            return (() => {
                let isFinished = false;
                isFinished = action.payload.results.length < state.courses.count;
                if (action.payload.clear) isFinished = false;
                return {
                    ...defaultLMSTO,
                    sessions: { ...state.sessions },
                    // students: { ...state.students },
                    courses: {
                        ...state.courses,

                        values: {
                            ...state.courses.values,
                            ...action.payload.values
                        },
                        results: action.payload.clear ? action.payload.results : [...state.courses.results.filter((val: string) => action.payload.results.indexOf(val) === -1), ...action.payload.results],
                        // results: [...action.payload.results],
                        isLoading: false,
                        isFinished
                    }
                }
            })()
        case actions.SET_LOADING:
            return {
                ...state,
                courses: {
                    ...state.courses,
                    isLoading: true
                }
            }
        case actions.ADD_QUESTIONS:
            return (() => {
                return {
                    ...state,
                    questions: {
                        ...state.questions,
                        ...action.payload
                    }
                }
            })()
        case actions.SET_STUDENTS_LOADING:
            return {
                ...state,
                students: {
                    ...state.students,
                    isLoading: true
                }
            }
        case actions.SET_MODERATION_LOADING:
            return {
                ...state,
                moderation: {
                    ...state.moderation,
                    isLoading: true
                }
            }
        case actions.UPDATE_COURSE:
            return (() => {
                return {
                    ...state,
                    courses: {
                        ...state.courses,
                        values: {
                            ...state.courses.values,
                            [action.payload.id]: {
                                ...state.courses.values[action.payload.id],
                                ...action.payload
                            }
                        }
                    }
                }
            })()
        // case actions.CREATE_ANSWER: {
        //     return {
        //         ...state,
        //         answers: {
        //             ...state.answers,
        //             [action.payload.id]: action.payload
        //         }
        //     }
        // }
        case actions.ADD_ANSWERS:
            return (() => {
                return {
                    ...state,
                    answers: {
                        ...state.answers,
                        ...action.payload
                    }
                }
            })()
        case actions.UPDATE_ANSWERS: {
            const { answer, uid } = action.payload
            return {
                ...state,
                answers: {
                    ...state.answers,
                    [answer.id]: {
                        ...state.answers[answer.id],
                        ...answer
                    }
                },
                ...updateUnit(uid, { modifed: true }, state)
            }
        }
        case actions.SET_RADIO_ANSWERS: {
            const { answers, uid } = action.payload
            return {
                ...state,
                answers: {
                    ...state.answers,
                    ...answers
                },
                ...updateUnit(uid, { modifed: true }, state)
            }
        }
        case actions.ADD_QUESTIONS:
            return (() => {
                return {
                    ...state,
                    questions: {
                        ...state.questions,
                        ...action.payload
                    }
                }
            })()
        // case actions.CREATE_QUESTION: {
        //     return {
        //         ...state,
        //         questions: {
        //             ...state.questions,
        //             [action.payload.id]: action.payload
        //         }
        //     }
        // }
        case actions.UPDATE_QUESTIONS: {
            const { question, uid } = action.payload
            return {
                ...state,
                questions: {
                    ...state.questions,
                    [question.id]: {
                        ...state.questions[question.id],
                        ...question
                    }
                },
                ...updateUnit(uid, { modifed: true }, state)
            }
        }
        case actions.CREATE_UNIT_COMPLETE: {
            return {
                ...state,
                units: {
                    ...state.units,
                    [action.payload.id]: action.payload
                },
                courses: {
                    ...state.courses,
                    values: {
                        ...state.courses.values,
                        [action.payload.courseId]: {
                            ...state.courses.values[action.payload.courseId],
                            courseUnits: [...state.courses.values[action.payload.courseId].courseUnits, action.payload]
                        }
                    }
                }
            }
        }
        case actions.ADD_UNIT:
            return (() => {
                return {
                    ...state,
                    units: {
                        ...state.units,
                        [action.payload.id]: action.payload
                    }
                }
            })()
        case actions.SET_UNIT_MODIFED: {
            const { id, modifed } = action.payload
            return {
                ...state,
                units: {
                    ...state.units,
                    [id]: {
                        ...state.units[id],
                        modifed
                    }
                }
            }
        }
        case actions.UPDATE_UNIT:
            return (() => {
                return {
                    ...state,
                    units: {
                        ...state.units,
                        [action.payload.unit.id]: {
                            ...state.units[action.payload.id],
                            ...action.payload.unit,
                            modifed: action.payload.modifed,
                        }

                    }
                }
            })()
        case actions.SET_SESSION_ARCHIVED:
            return (() => {
                return {
                    ...state,
                    sessions: {
                        ...state.sessions,
                        [action.payload.sid]: {
                            ...state.sessions[action.payload.sid],
                            isArchived: action.payload.isArchived
                        }
                    },
                    courses: {
                        ...state.courses,
                        values: {
                            ...state.courses.values,
                            [action.payload.cid]: {
                                ...state.courses.values[action.payload.cid],
                                // normalSessions: {
                                //     ...state.courses.values[action.payload.cid].normalSessions,
                                //     [action.payload.sid]: {
                                //         ...state.courses.values[action.payload.cid].normalSessions[action.payload.sid],
                                //         isArchived: action.payload.isArchived
                                //     }
                                // },
                                // courseSessions: state.courses.values[action.payload.cid].courseSessions.filter((sid: string) => sid !== action.payload.sid),
                                activeSessions: state.courses.values[action.payload.cid].activeSessions.filter((sid: string) => sid !== action.payload.sid),
                            }
                        }
                    }
                }
            })()
        case actions.SET_STUDENTS_FILTERS: {
            return {
                ...state,
                students: {
                    ...state.students,
                    filters: {
                        ...state.students.filters,
                        ...action.payload
                    }
                },
            }
        }
        case actions.SET_STUDENTES:
        case actions.SET_USERS_IN_COURSE: {
            const studentesIds = state.students.ids

            const isFinished = action.payload.ids.length < state.students.count;
            return {
                ...state,
                students: {
                    ...state.students,
                    values: {
                        ...state.students.values,
                        ...action.payload.values
                    },
                    ids: action.payload.clean ? action.payload.ids : [...studentesIds, ...action.payload.ids],
                    isLoading: false,
                    isFinished: action.payload.clean ? false : isFinished,
                    currentType: action.type
                },
            }
        }
        case actions.SET_STUDENT_SESSIONS:
            return (() => {
                return {
                    ...state,
                    courses: {
                        ...state.courses,
                        values: {
                            ...state.courses.values,
                            [action.payload.cid]: {
                                ...state.courses.values[action.payload.cid],
                                studentesResults: {
                                    ...state.courses.values[action.payload.cid].studentesResults,
                                    [action.payload.uid]: {
                                        ...state.courses.values[action.payload.cid].studentesResults[action.payload.uid],
                                        sessions: action.payload.sessions
                                    }
                                }

                            }
                        }
                    }
                }
            })()
        case actions.SET_STUDENTS_RESULTS:
            const currentCourse = state.courses.values[action.payload.cid]
            return (() => {
                return {
                    ...state,
                    courses: {
                        ...state.courses,
                        values: {
                            ...state.courses.values,
                            [action.payload.cid]: {
                                ...currentCourse,
                                // resultsIds: {
                                //     ...state.courses.values[action.payload.cid].resultsIds,
                                //     [action.payload.uid]: action.payload.ids
                                // },
                                studentesResults: {
                                    ...currentCourse.studentesResults,
                                    [action.payload.uid]: {
                                        ...(currentCourse.studentesResults ? currentCourse.studentesResults[action.payload.uid] : {}),
                                        ...action.payload.values
                                    }
                                }

                            }
                        }
                    }
                }
            })()
        case actions.SET_SESSION_PAUSE:
            return (() => {
                return {
                    ...state,
                    sessions: {
                        ...state.sessions,
                        [action.payload.sid]: {
                            ...state.sessions[action.payload.sid],
                            isPause: action.payload.pause
                        }
                    }
                }
            })()
        case actions.ADD_SESSIONS:
            return (() => {
                const activeSessions = state.courses.values[action.payload.cid].activeSessions || []
                const archiveSessions = state.courses.values[action.payload.cid].archiveSessions || []

                let isFinished = false;
                isFinished = action.payload.archive
                    ? (action.payload.archiveSessions.length < state.sessionsWork.count)
                    : (action.payload.activeSessions.length < state.sessionsWork.count);
                // isFinished = action.payload.clear ? false : isFinished
                return {
                    ...state,
                    sessions: {
                        ...state.sessions,
                        ...action.payload.sessions
                    },
                    sessionsWork: {
                        ...state.sessionsWork,
                        isLoading: false,
                        isFinished
                    },
                    courses: {
                        ...state.courses,
                        values: {
                            ...state.courses.values,
                            [action.payload.cid]: {
                                ...state.courses.values[action.payload.cid],
                                activeSessions: [...activeSessions, ...action.payload.activeSessions],
                                activeSessionsFinished: action.payload.archive ? false : isFinished,
                                archiveSessions: [...archiveSessions, ...action.payload.archiveSessions],
                                archiveSessionsFinished: action.payload.archive ? isFinished : false,

                            }
                        }
                    }
                }
            })()
        case actions.SET_SESSIONS_LOADING:
            return {
                ...state,
                sessionsWork: {
                    ...state.sessionsWork,
                    isLoading: true
                }
            }
        case actions.ADD_SESSION:
            return (() => {
                const courseSessions = state.courses.values[action.payload.courseId].courseSessions
                const activeSessions = state.courses.values[action.payload.courseId].activeSessions || []
                return {
                    ...state,
                    sessions: {
                        ...state.sessions,
                        [action.payload.id]: action.payload
                    },
                    courses: {
                        ...state.courses,
                        values: {
                            ...state.courses.values,
                            [action.payload.courseId]: {
                                ...state.courses.values[action.payload.courseId],
                                normalSessions: {
                                    ...state.courses.values[action.payload.courseId].normalSessions,
                                    [action.payload.id]: action.payload
                                },
                                courseSessions: courseSessions.includes(action.payload.id) ? courseSessions : [action.payload.id, ...courseSessions],
                                activeSessions: [action.payload.id, ...activeSessions],

                            }
                        }
                    }
                }
            })()
        case actions.SET_SINGLE_COURSE:
            return {
                ...state,
                courses: {
                    ...state.courses,
                    values: {
                        ...state.courses.values,
                        ...action.payload.values
                    },
                    results: action.payload.update ? state.courses.results : [...action.payload.results]
                }
            }
        case actions.SET_MODERATINO_ANSWERS: {
            const isFinished = action.payload.answersIds.length < state.moderation.count;
            const container = action.payload.container || 'main'
            return {
                ...state,
                moderation: {
                    ...state.moderation,
                    answers: {
                        ...state.moderation.answers,
                        ...action.payload.answers,
                    },
                    units: {
                        ...state.moderation.units,
                        ...action.payload.units
                    },
                    questions: {
                        ...state.moderation.questions,
                        ...action.payload.questions
                    },
                    answersIds: action.payload.clear ? action.payload.answersIds : [...state.moderation.answersIds, ...action.payload.answersIds],
                    lists: {
                        ...state.moderation.lists,
                        [container]: action.payload.clear ? action.payload.answersIds : [...(state.moderation.lists[container] || []), ...action.payload.answersIds]
                    },
                    isLoading: false,
                    isFinished
                },

                users: {
                    ...state.users,
                    ...action.payload.users
                }
            }
        }
        case actions.SET_TO_USERS:
            return (() => {
                return {
                    ...state,
                    users: {
                        ...state.users,
                        ...action.payload
                    }
                }
            })()
        case actions.SET_MODERATION_FILTERS:
            return (() => {
                return {
                    ...state,
                    moderation: {
                        ...state.moderation,
                        filters: {
                            ...state.moderation.filters,
                            ...action.payload
                        }
                    }
                }
            })()
        case actions.SET_SESSION:
            return (() => {
                return {
                    ...state,
                    sessions: {
                        ...state.sessions,
                        [action.payload.id]: action.payload
                    }
                }
            })()
        case actions.SET_ANSWER_MODERATION_STATUS:
            return (() => {
                return {
                    ...state,
                    moderation: {
                        ...state.moderation,
                        answers: {
                            ...state.moderation.answers,
                            [action.payload.answerId]: {
                                ...state.moderation.answers[action.payload.answerId],
                                isRightAnswer: action.payload.status,
                                moderationStatus: action.payload.status ? "Approved" : "Disapproved"
                            }
                        }
                    }
                }
            })()
        case actions.REQUEST_BEGIN: {
            return {
                ...state,
                requestsActivity: {
                    ...state.requestsActivity,
                    [action.payload]: 'PENDING',
                    [`${action.payload}_error_msg`]: undefined
                }
            }
        }
        case actions.REQUEST_COMPLETE: {
            return {
                ...state,
                requestsActivity: {
                    ...state.requestsActivity,
                    [action.payload]: undefined,
                    [`${action.payload}_error_msg`]: undefined
                }
            }
        }
        case actions.REQUEST_ERROR: {
            return {
                ...state,
                requestsActivity: {
                    ...state.requestsActivity,
                    [action.payload.type || action.payload]: 'ERROR',
                    [`${action.payload.type || action.payload}_error_msg`]: action.payload.msg
                }
            }
        }


        default:
            return state;
    }
}
