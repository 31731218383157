import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import i18n from '../localizations/i18n';

export const external = (): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: 'pryaniky/external/testPackage',
            settings: {
              src: 'http://localhost:3001',
              height: 450
            }
          })
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  });
}