import React, { FC } from 'react';
import { IFilterProps } from '../index'
import { Translate } from 'localization';
import { TAdditionalFileFilter } from 'redux/search/interfaces';
import { FormControlLabel } from 'muicomponents/src';
import { Checkbox } from 'muicomponents/src/Checkbox/Checkbox'

export const FilterFiles: FC<IFilterProps<TAdditionalFileFilter>> = ({ filter, onChangeFilter, filterCleanButton }) => {
  
  return (
    <>
      <FormControlLabel 
        control={<Checkbox
          onChange={() => onChangeFilter((prev) => ({...prev, searchOnlyName: !filter.searchOnlyName}))} 
          checked={filter.searchOnlyName}
        />}
        label={Translate.t({i18nKey: 'pryaniky.list.wiki.search.in.name'})}
      />  
    </>
  )
};