import React, { FC, useEffect } from 'react'
import { ISessionResultProps, mapDispatchToProps, mapStateToProps, cnSessionResult } from './SessionResult.index'
import { Button } from 'uielements/src'
import { connect } from 'react-redux';
import './SessionResult.scss'
import { Chart } from '../../components/Chart/Chart';
import { TestList } from '../../components/TestList/TestList'
import { useSessionResult } from 'LMSModule/utils/hooks/useSessionResult'
import CourseSummary from '../../components/CourseSummary'
import { tS } from 'LMSModule/utils/i18n.adaptor'
import { withResultLoader } from 'LMSModule/utils/HOCs/withResultLoader'
import { withPreloader } from '../Preloader/Preloader'

export const SessionResultPresenter: FC<ISessionResultProps & any> = ({ cid, result, units }) => {
    const {
        failureTaskCount,
        successTaskCount,
        taskCount,
        waitTestCount
    } = useSessionResult(units, result.session.unitResults)

    return <div className={cnSessionResult()}>
        <div className={cnSessionResult('Header')}>
            <div className={cnSessionResult('HeaderColumn')}>
                <div className={cnSessionResult('Title')}>{tS('courseResult.2')}</div>
            </div>
        </div>
        <div className={cnSessionResult('Summary')}>
            <div className={cnSessionResult('SummaryColumn')}>
                {taskCount !== 0 ? <>
                    <CourseSummary
                        rightCount={successTaskCount}
                        wrongCount={failureTaskCount}
                        waitModerationCount={waitTestCount}
                    />

                    <Chart
                        className={cnSessionResult('Chart')}
                        waitModerationCount={waitTestCount}
                        rightPercent={successTaskCount}
                        wrongPercent={failureTaskCount}
                        showAll={true}
                    />
                </>
                    : <h2>{tS('courseComplete.1')}</h2>}
            </div>
        </div>
        <TestList
            units={units.filter((unit: any) => unit.unitType === 'test' || unit.unitType === 'task')}
            unitsResult={result.unitsResult}
        />
        <div className={cnSessionResult('Actions')}>
            <Button main className={cnSessionResult('Btn')} type={'rlink'} href={'/lms/' + cid}>{tS('return.1')}</Button>
        </div>
    </div>
}


export const SessionResult = withResultLoader(withPreloader(SessionResultPresenter, ['result'], []))/*connect(
    mapStateToProps,
    mapDispatchToProps
)(SessionResultPresenter);*/