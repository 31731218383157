import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IStateType as IState } from '../../redux/store';
import {
    getSecretSantaWidgetSettingsAction,
    getSecretSantaParticipantsList,
    setSSParticipantsListToRedux,
    secretSantaJoinAction,
    isUserParticipateAction,
    SSPastisipantsListToggle,
    getUserToGivePresent,
    presentSentAction,
    setUserDistributedToggleAction,
    secretSantaUnJoinAction,
    mainSecretSantaSettingsAction
} from '../../redux/sagas/SecretSanta/actions'
import {
    getSecretSantaWidgetSettings,
    getPartisipantsList,
    getSettings,
    getSecretSantaIsParticipate,
    getUserForPresentInfo,
    getIsPresentSend,

} from '../../redux/sagas/SecretSanta/selectors'
import { RouteComponentProps } from 'react-router'

export interface ISecretSantaPartProps extends IClassNameProps, ISecretSantaPartOwnProps, ISecretSantaPartStateProps, ISecretSantaPartDispatchProps {
    tag?: any;
    data: any;
    context?: any;
    relations?: string[];
    // isFinished: boolean;
    // isLoading: boolean;
    settings: any
    className?: string
    store?: any
    changeVm?: any

}
export interface ISecretSantaPartOwnProps extends RouteComponentProps<IParams> {

}
export interface IParams {
    id: string
}
export interface ISecretSantaPartState {
    text?: string;
    news?: any[];
    request?: boolean;
    fullList?: boolean;
    searchText: string;
    skipCount: number;
    distibuted: boolean | null;
    teaserEnded: boolean,
    updateData: boolean,
    registrEnded: boolean;
    showUser: boolean;
    peopleCount: string;
}

export type ISecretSantaPartStateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: IState, props: ISecretSantaPartOwnProps) => {
    return {
        secretSantaSettings: getSecretSantaWidgetSettings(props.match.params.id)(state),
        // secretSantaId:  getSecretSantaWidgetSettings(state).id || "",
        data: getPartisipantsList(props.match.params.id)(state),
        isFinished: getSettings(props.match.params.id)(state)?.isFinished,
        isLoading: getSettings(props.match.params.id)(state)?.isLoading,
        getParticipate: getSecretSantaIsParticipate(props.match.params.id)(state),
        userForPresent: getUserForPresentInfo(props.match.params.id)(state),
        isDistributLoading: getSettings(props.match.params.id)(state)?.isDistributLoading,
        isPresentSendData: getIsPresentSend(props.match.params.id)(state),
        allSecretSantaSettings: getSettings(props.match.params.id)(state),
        noRight:  getSettings(props.match.params.id)(state)?.noRight,


    };
};

export type ISecretSantaPartDispatchProps = ReturnType<typeof mapDispatchToProps>

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    getSecretSantaWidgetSettingsAction,
    getSecretSantaParticipantsList,
    setSSParticipantsListToRedux,
    secretSantaJoinAction,
    SSPastisipantsListToggle,
    isUserParticipateAction,
    getUserToGivePresent,
    presentSentAction,
    setUserDistributedToggleAction,
    secretSantaUnJoinAction,
    mainSecretSantaSettingsAction
}, dispatch)


export const cnSecretSantaPart = cn('SecretSantaPart');
