import { IClassNameProps } from '@bem-react/core'
import { cn } from '@bem-react/classname'

import { bindActionCreators, Dispatch } from 'redux'
import actions from 'redux/actionsTypes/Widgets';
import { getCurrentAllowPostTypes } from 'redux/sagas/AppSettings/selectors';
// import { SET_CONTEXT, UPDATE_CONTEXTS } from "redux/actions/Widgets";
import {
  changeField
} from 'News/redux/actions';
import {
  getViewType
} from 'News/redux/saga/selectors';

export interface IFiltersProps extends IClassNameProps, IFiltersStateProps, IFiltersDispatchProps {
  tag?: 'div'
  data: any[]
  context: IFilterContext
  relations: string[]
}

export interface IFiltersStateProps {
  edit: boolean
  renderParams?: { [s: string]: any };
  viewType: string;
}

export type IFiltersDispatchProps = ReturnType<typeof mapDispatchToProps>


export interface IFilterContext {
  timelineType?: string;
  oneNewsType: string;
  workflowId: string;
  search?: string;
}

export const mapStateToProps = (state: any, props: IFiltersProps) => {
  // const defaultViewType = getCurrentAllowPostTypes(-1)(state)?.find((el: any) => el.id === props.context.workflowId)?.renderParams?.defaultViewType
  return ({
    edit: state.store.edit,
    renderParams: getCurrentAllowPostTypes(-1, props.context.workflowId)(state)?.find((el: any) => el.id === props.context.workflowId)?.renderParams,
    viewType: getViewType(state as any)
  })
}

const changeContextv3 = (payload: Array<{ [s: string]: any }>) => ({
  type: actions.UPDATE_CONTEXTS,
  payload
})

export const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  changeContextv3,
  changeField
}, dispatch)

export const cnFilter = cn('FilterWorkflowsBar')
