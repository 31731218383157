import { ReplyType } from '../../../../Reply/Reply.type'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { Rule } from '../../../../Base.validate'
import { getCompanyPostTypeById } from 'redux/sagas/AppSettings/selectors'
export interface IEditorTypeNewsProps {
    data: ReplyType;
    onChange: (data: ReplyType, validate: boolean, errors: Rule[]) => void;
    // onChangeUsers: (val: any) => void;
    newsAuthor?: any;
    send?: () => void;
}
export const mapStateToProps = (state: any) => ({
    maxThanksValue: state.store.appSettings.maxThanksValue,
    maxThanksArgumentsCount: state.store.appSettings.maxThanksArgumentsCount,
    userData: getCurrentUser(state),
    thanksForAll: getCurrentUser(state).extData.thanksForAll,
    postType: getCompanyPostTypeById('thanks')(state)
})