import React, { memo } from 'react';
import { IEditorTypeNewsProps, mapStateToProps } from './news.index';
import { ThanksReplyType, createDefaultThanksReply } from '../../../ThanksReply.type'
import { ThanksValidator, thanksValidate } from '../../../ThanksReply.validate'
import { Editor } from '../../Editor';
import * as utils from 'utils/src/utils';
import { Rule } from '../../../../Base.validate'
import { baseValidate } from '../../../../Reply/Reply.validate'
import { EditorState, convertToRaw } from 'draft-js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { nameCount } from 'utils/src/utils'
// import { changeReasonModifier, changeUsersModifier } from 'blocks/NewsTypes/Thanks/Editor/modifiers'
import { useChangeCurrency } from '../../../hooks'


const vb = new ThanksValidator();

export const _EditorTypeNews: React.FC<IEditorTypeNewsProps & any> = ({
    onChange: cbChange,
    data,
    newsAuthor = {},
    maxThanksValue = 0,
    maxThanksArgumentsCount = 0,
    // thanksForAll = 0,
    send,
    postType
}) => {

    let localData = { ...data, users: data.users || [] } as ThanksReplyType;

    if (!data.thanksInfo) {

        localData = { ...createDefaultThanksReply(), text: EditorState.createEmpty() }
        localData.user = data.user;
        localData.users = data.users ? data.users.filter(({ id }: any) => data.user.id !== id) : [];
        localData.thanksInfo.thanksCount = 1//localData.users.length;
        localData.thanksInfo.users = localData.users.map((el: any) => el.value || el.id).filter((uId: string) => data.user.id !== uId);
    }

    const {
        onChangeCurrency,
        disableDown,
        disableUp,
        thanksCount,
        available,
        fullCount,
        fullCurrency,
        maxPerUser,
        maxThanks
    } = useChangeCurrency(localData, onChange)

    

    function onChange (data: ThanksReplyType) {
        // const maxThanks = (data.thanksInfo.users.length * maxThanksValue) > thanksForAll ? thanksForAll : (data.thanksInfo.users.length * maxThanksValue);
        const validThank: Rule[] = [
            {
                type: 'number',
                method: 'NUM_MIN',
                field: 'thanksCount',
                value: 1,//data.thanksInfo.users.length,
                errorI18nKey: 'pryaniky.validate.news.thanks.thanksCount.min',
                // message: 'Недостаточно вложений'
            },
            {
                type: 'number',
                method: 'NUM_MAX',
                field: 'thanksCount',
                value: data.thanksInfo.users.length === 0 ? maxThanksValue : maxPerUser,//maxThanks,
                errorI18nKey: 'pryaniky.validate.news.thanks.thanksCount.max',
                // message: 'Вложено слишком много'
            },
            {
                type: 'array',
                method: 'ARR_MAX',
                field: 'rewardReasons',
                value: maxThanksArgumentsCount,
                errorI18nKey: 'pryaniky.validate.news.thanks.rewardReasons.max',
                errorVariablesI18nKey: {
                    count: maxThanksArgumentsCount
                }
                // message: 'Выбрано слишком много причин'
            },
            {
                type: 'array',
                method: 'ARR_MAX',
                field: 'users',
                value: (fullCurrency / data.thanksInfo.thanksCount),
                errorI18nKey: 'pryaniky.validate.news.thanks.users.max',
                // message: 'Выбранно слишком много пользователей'
            },
            {
                type: 'string',
                method: 'STR_MIN',
                field: 'text',
                value: postType?.thanksRenderParams?.minTextLength || 5,
                errorI18nKey: 'pryaniky.validate.news.thanks.text.min',
                errorVariablesI18nKey: {
                    count: postType?.thanksRenderParams?.minTextLength || 5,
                    variable: nameCount(postType?.thanksRenderParams?.minTextLength || 5, 'pryaniky.validate.string.variable')
                }
            }
        ]
        data.thanksForAll = fullCurrency
        // data.usersCount = usersCount
        vb.addData(data)
        vb.setRules([...thanksValidate.filter(v => !(v.field === 'rewardReasons' && v.method === 'ARR_MAX')), ...validThank])
        cbChange({ ...data }, vb.validation().length === 0, vb.validation())
    }

    

    const changeUsers = (values: any) => {

        const newUser = values.filter((el: any) => newsAuthor.id !== (el.value || el.id));
        localData.thanksInfo.thanksCount = (localData.thanksInfo.thanksCount === 0 ? 1 : localData.thanksInfo.thanksCount);
        //(localData.thanksInfo.thanksCount === 0 ? 1 : localData.thanksInfo.thanksCount) / (localData.thanksInfo.users.length === 0 ? 1 : localData.thanksInfo.users.length) * newUser.length;
        localData.users = newUser
        localData.thanksInfo.users = !localData.users ? [] : localData.users.map((el: any) => el.value || el.id);

        onChange(localData);
        // onChangeUsers(values);
    }
    const onChangeReason = (values: any) => {
        localData.thanksInfo.thanksReasonIds = values ? values.map((v: any) => v.value) : [];
        onChange(localData);
    }

    const onChangeThanks = (val: number) => {
        localData.thanksInfo.thanksCount = val
        onChange(localData)
    }

    const onChangeText = (text: string) => {
        localData.text = text;
        // const bv = new BaseValidator<NewsNews>()
        // bv.setRules(baseValidate)
        // bv.addData(localData)
        onChange(localData);
    }

    return <Editor
        send={send}
        // maxThanksValue={maxThanksValue}
        maxThanksArgumentsCount={maxThanksArgumentsCount}
        // thanksForAll={thanksForAll}
        maxUsers={Math.floor(fullCurrency / localData.thanksInfo.thanksCount)}
        validation={vb.validation()}
        reasons={localData.thanksInfo.thanksReasonIds.map((v: any) => ({ value: v, label: v }) as any)}
        onChangeReason={onChangeReason}
        onChangeThanks={onChangeCurrency}//onChangeThanks}
        onChangeUsers={changeUsers}
        onChangeText={onChangeText}
        disabledVariants={[newsAuthor]}
        excludeUsers={[]/*[newsAuthor, localData.user]*/}
        users={localData.users || []}
        data={localData} 
        
        disableDown={disableDown}
        disableUp={disableUp}
        thanksCount={thanksCount}
        available={available}
        fullCount={fullCount}
        fullCurrency={fullCurrency}
        maxPerUser={maxPerUser}
        maxThanks={maxThanks}
        />
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch);
export const EditorTypeNews = connect<any, any>(
    mapStateToProps
)(memo(_EditorTypeNews)) as any;