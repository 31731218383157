import React, { FC } from 'react';
import { IBadge,cnProfileBadgeTabList  } from '../UserBadges.index';
import { Avatar } from 'muicomponents/src/Avatar';
import { Link } from 'muicomponents/src/Link/Link';
import { Box, Divider, Typography } from 'muicomponents/src';
import { ListItem as ListItemMui } from 'muicomponents/src/ListItem/ListItem';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn'
import { GridItemDescription, GridItemTitle } from '../GridItem/GridItem.styled';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { Button } from 'muicomponents/src'
import { Translate } from 'localization';

export const ListItem: FC<{ badge: IBadge, onRemove?: (arg: string) => void }> = ({ badge, onRemove }) => {
  return (
    <>
      <ListItemMui alignItems="center" sx={{ height: '72px' }} className={cnProfileBadgeTabList('ListItemBox')}>
        <ListItemAvatar>
          <Link href={badge.url}>
            <Avatar alt={badge.name} src={badge.badgeImgUrl || BaseRequests.getUrl(defaultBadgeIconUrl)} />
          </Link>
        </ListItemAvatar>
        <ListItemText
          primary={
            <GridItemTitle href={badge.url}>
              <Typography variant='body2'>
                <Tooltip title={badge?.name?.length > 75 ? badge.name : ""}>
                  <span>
                    {badge.name}
                  </span></Tooltip>
              </Typography>
            </GridItemTitle>
          }
          secondary={
            <GridItemDescription>
              <Tooltip title={badge?.description?.length > 160 ? badge.description : ""}>
                <span>{badge.description}</span>
              </Tooltip>
            </GridItemDescription>}
        // `${badge.description}`}
        />
        {onRemove &&
          <Button
            // sx={{ }}
            onClick={() => onRemove(badge.badgeUid)}
            variant={'outlined'}
            color={"primary"}
            className={cnProfileBadgeTabList('ListItemBox-DeleteButton')}
            children={<Translate i18nKey={'pryaniky.profile.badge.tab.list.badge.delete'} />}
          />}
      </ListItemMui>
      <Divider variant="fullWidth" light={true} />
    </>
  )
};
