import * as React from 'react';
import { useState } from 'react';
import { defaultBaseNews } from '../../../../BaseType'
import { IEditorTypeNewsProps } from './news.index';
import { IdeaNewsCreate, defaultIdeaNewsCreate } from '../../../IdeaType'
import { IdeasValidator, ideasValidate } from '../../../Ideas.validate'
import { Editor } from '../../Editor'
import { v1 as uuid } from 'uuid';
import * as utils from 'utils/src/utils';
import { normalizeData, denormalizeData} from '../../../Utils'
import { EditorState, convertToRaw } from 'draft-js';
import { is } from 'immutable';
import { AdditionalFieldsValue } from '../../../IdeaType';
//import { } from 'lodash'

const prepareDefaultFieldsData = (additionsalFields: AdditionalFieldsValue[]) => {
    return additionsalFields.map((c) => {
        c.v = c.v.map(field => {
            if(field.field.defaultValue) 
                switch (field.field.fieldType) {
                    case 'String':{
                        field.value = field.field.defaultValue;
                        break;
                    }
                    case 'Textarea': {
                        field.value = field.field.defaultValue;
                        break;
                    }
                }
            return field;
        })
        return c;
    });
}


export const EditorTypeNews: React.FC<IEditorTypeNewsProps> = ({ onChange: cbChange, data, types }) => {

    let localData = data as any;
    if (data.newstype !== 21) {
        const allowAnonimous = types && (types.ideaRenderParams?.postParams?.allowAnonimous || false);
        localData = { ...utils.cloneObject(defaultIdeaNewsCreate), text: EditorState.createEmpty(), allowAnonimous };
        if (types && types.ideaRenderParams) localData.idea.additionalFieldsValues = prepareDefaultFieldsData(utils.cloneObject(types.ideaRenderParams.additionalFieldsValues))
}

    const [normalFields, setNormalFields] = React.useState<any>(normalizeData(localData.idea.additionalFieldsValues))

    React.useEffect(() => {
        setNormalFields(normalizeData(localData.idea.additionalFieldsValues))
    }, [data.newstype]) 

    const onChange = (data: any) => {
        const vb = new IdeasValidator();
        vb.addData(data)
        vb.setRules(ideasValidate)
        cbChange(data, vb.validation().length === 0)
    }

    const onChangeTitle = (e: any) => {
        localData.header = e.target.value;
        onChange(localData);
    }
    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData);
    }
    const onChangeFields = (field: any) => {
        normalFields.entities.fields[field.fieldId] = field;
        localData.idea.additionalFieldsValues = denormalizeData(normalFields);
        onChange(localData);
        setNormalFields(normalFields);
    }
    const onChangeAnonimous = () =>{
        localData.idea.isAnonimous = !localData.idea.isAnonimous
      
        onChange(localData);  
    }

    const onChangeTags = (tags: string) =>{
        localData.tags = tags;
        onChange(localData);
    }



    return <Editor
        normalFields={normalFields}
        onChangeFields={onChangeFields}
        onChangeText={onChangeText}
        onChangeTitle={onChangeTitle}
        onChangeAnonimous={onChangeAnonimous}
        onChangeTags={onChangeTags}
        data={localData} 
        />
}
