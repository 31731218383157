import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';

// import i18n from '../../localizations/i18n';

import { cnMessages, IMessagesProps, IMessagesState, mapStateToProps, IChatListStateProps } from './Messages.index';

import './Messages.scss';
import { Spinner } from 'uielements/src';

export class MessagesPresenter extends React.Component<IMessagesProps, IMessagesState> {
  public utils: any = utils;
  public el: HTMLElement | null;
  public frame: HTMLIFrameElement | null;

  constructor(props: IMessagesProps) {
    super(props);
    this.state = {
      isAuth: false
    }
    
    window.addEventListener('message', (e) => {
      // if (e.origin && e.origin.indexOf('chat.pryaniky.com') !== -1) {
        if (e.data.eventName === "startup" && e.data.data) {
          this.chatLogin();
        }
      // }
    });
  }

  public componentDidMount() {
    if (this.el && this.el.parentElement) this.el.parentElement.classList.add('FullWidth');
    // if (this.props.token && this.props.token !== 'authData.token') this.chatLogin();
  }

  public componentDidUpdate(pp: IMessagesProps) {
    // if (this.props.token && this.props.token !== pp.token) this.chatLogin();
  }

  public chatLogin() {
    console.log('login!');
    this.frame && this.frame.contentWindow && this.frame.contentWindow.postMessage({
      event: 'login-with-token',
      loginToken: this.props.token
    }, '*');
    setTimeout(() => {
      this.setState({ isAuth: true });
    }, 1000);
    // setTimeout(() => {
    //   this.frame && this.frame.contentWindow && this.frame.contentWindow.postMessage({
    //     event: 'login-with-token',
    //     loginToken: this.props.token
    //   }, '*');
    //   this.setState({ isAuth: true })
    // }, 1000);
  }

  public componentWillUnmount() {
    if (this.el && this.el.parentElement) this.el.parentElement.classList.remove('FullWidth');
  }

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', className = '', chatSettings } = this.props;
    const { isAuth } = this.state;
    let link = '/' + (this.props.match.params.type || '')
    link += link !== '/' ? '/' + (this.props.match.params.id || '') : '';
    const chatUrl = chatSettings?.chatUrl || '';
    const domainUrl = chatUrl[chatUrl.length - 1] === '/' ? chatUrl.slice(0, chatUrl.length - 1) : chatUrl;
    return (
      <TagName ref={el => this.el = el} className={cnMessages({ isAuth }, [className, customClassNames])}>
        {
          !isAuth && <Spinner fontSize={40} />
        }
        <iframe
          id={'chatFrame'}
          className={'ChatFrame'}
          ref={(r: any) => {
            if (!r) return;
            this.frame = r;
          }}
          title='messages'
          style={{ border: 'none', width: '100%', height: 'calc(100vh - 72px)' }}
          // frameborder="0"
          src={domainUrl ? domainUrl + link  : '//' + this.props.siteUrl + '.chat.pryaniky.com' + link}
          allow="geolocation; microphone; camera"
        />
        {this.props.children}
      </TagName>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const Messages = connect<IChatListStateProps>(
  mapStateToProps
)((MessagesPresenter))
  //   compose(
  //     // withBemMod(cnMessages(), {})
  //     )(
  //       MessagesPresenter
  //   )
  // )
