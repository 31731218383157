import React, { useState } from 'react';
import { createModal, LeftDirectionTransitionComponent  } from 'utils/src/DialogCreator';
import { cnWorkflowsKanbanFilters, IWorkflowsKanbanFiltersProps } from './Filters.index';
import {
  DialogTitle,
  DialogActions,
  DialogContent,
} from 'uielements/src';
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import { Translate } from 'localizations/Translate';
import './Filters.scss';
import i18n from 'localizations/i18n';
import { Toggle } from 'uielements/src/Toggle/Toggle'
import Suggest from 'uielements/src/MaterialElements/Autocomplete';
import { FiltersWorkflows } from 'blocks/Filters/_type/Filters_type_workflows';

const WorkflowsKanbanFiltersPresenter: React.FC<IWorkflowsKanbanFiltersProps> = ({ checkedHandler, handleChangeInputMention, inputMention, handleClose, handleAccept, context }) => {

  const [filters, setFilters] = useState({...context, ...inputMention});

  const onChangeFilters = (filter: string, data: any) => {
    setFilters({ ...filters, [filter]: data });
    if (filter === 'moderation') {
      checkedHandler(data);
      return;
    }
    handleChangeInputMention.bind(null, `${filter}Ids`)(data);
  }

  return <div className={cnWorkflowsKanbanFilters({}, ['CustomStyle'])}>
    <DialogTitle onClose={handleClose}>
      <h4 className="my-1">
        <Translate i18nKey={`pryaniky.post.workflows.filters.title`} />
      </h4>
    </DialogTitle>
    
    <DialogContent className={cnWorkflowsKanbanFilters('Body')}>
      <h4 className={cnWorkflowsKanbanFilters('Subtitle')}>{i18n.t('pryaniky.filter.tags')}</h4>
      <Suggest className={cnWorkflowsKanbanFilters('Tags', ['BottomLine'])} type='tags' placeholder={i18n.t('pryaniky.filter.tags.placeholder')} onChange={(tags: any[]) => onChangeFilters('tag', tags)} value={filters.tag} />

      <h4 className={cnWorkflowsKanbanFilters('Subtitle')}>{i18n.t('pryaniky.filter.ideas.responsible')}</h4>
      <Suggest className='BottomLine' type='users' icon={'user-group'} placeholder={i18n.t('pryaniky.filter.colleague')} excludeMe={false} onChange={(users: any[]) => onChangeFilters('user', users)} value={filters.user} />
 
      <h4 className={cnWorkflowsKanbanFilters('Subtitle')}>{i18n.t('pryaniky.post.workflows.filters.author')}</h4>
      <Suggest className='BottomLine' type='users' icon={'user-group'} placeholder={i18n.t('pryaniky.filter.colleague')} excludeMe={false} onChange={(users: any[]) => onChangeFilters('author', users)} value={filters.author} />

      <div className={cnWorkflowsKanbanFilters('Toggle')}>
        <h4>{i18n.t('pryaniky.filter.moderation')}</h4>
        <Toggle checked={filters.moderation} onChange={(e: any) => onChangeFilters('moderation', e.target.checked)} />
      </div>
    </DialogContent>

    <DialogActions>
      <Button color='primary' onClick={handleClose}>
        <Translate i18nKey='cancel' />
      </Button>
    </DialogActions>
  </div>
}

export const filtersModal = createModal(WorkflowsKanbanFiltersPresenter, {
  maxWidth: 'xs',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  TransitionComponent: LeftDirectionTransitionComponent,
  className: 'Pane BackdropOffx Right',
  scroll: 'body'
});