import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget, IWidgetProps } from '../../Widget.index';
import WidgetPresenter from '../../Widget';
import { widgets } from "i.widgets";

const types = {
  common: require('blocks/WidgetPageComment/WidgetPageComment').PageCommentsWidget,
}

const type = 'pageCommentsWidget';



export default class WidgetTypeSecretsWidgetPresenter extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    return     <Component {...this.props} 
      updateWidget={this.props.updateWidget}
      editToggle={this.props.widget.HTMLWidgetContentEdit}
      className={cnWidget({ type, subtype })}
      context={this.props.wcontext}
      tag={widgets.components.common} />
  }

}