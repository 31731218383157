import * as React from 'react';
import * as utils from 'utils/src/utils';
import i18n from "../../../localizations/i18n";
import { mapStateToProps, mapDispatchToProps, IFiltersStateProps, IFiltersDispatchProps, cnFilters } from '../Filters.index';
import { IFiltersShopProps, IFiltersShopState } from "./Filters_type_shop_fix.index";
import { Selectize } from 'uielements/src';
import { connect } from 'react-redux';
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
import { IStateType as IState } from 'redux/store';

const f = [{ id: 'all', title: i18n.t('pryaniky.filter.wikilist.all'), type: 'shopCatFilter', value: undefined }]

class FiltersTypeShopPresenter extends React.Component<IFiltersShopProps, IFiltersShopState> {
  public state: IFiltersShopState = {
    isClosed: true,
    data: []
  }

  public componentDidMount() {
    this.getCategories();
  }

  private getCategories = () => utils.API.shop.getProductsCategories().r.then((d) => utils.checkResponseStatus(d) && this.setState({
    isClosed: d.isShopClosed,
    data: [...f, ...d.data.map((el: any) => ({ id: el.id.toString(), title: `${el.name} (${el.count})`, value: el.id, type: 'shopCatFilter' }))]
    // d.data.map(el => ({ id: el.id.toString(), title: `${el.name} (${el.count})`, value: el.id, type: 'shopCatFilter' }))
  }))

  private changeCategory = (selected: ISelectizeItem) => {
    if (!this.props.context.filter || this.props.context.filter.id !== selected.id) {
      this.props.changeContextv3(this.props.relations.reduce((acc: any, conID: any) => ({ ...acc, [conID]: { filter: selected, [selected.type]: selected.value } }), {} as { [s: string]: any }))
    } else if (this.props.context.filter.id === selected.id) {
      this.props.changeContextv3(this.props.relations.reduce((acc: any, conID: any) => ({ ...acc, [conID]: { filter: null, [selected.type]: '' } }), {} as { [s: string]: any }))
    }
  }
  

  public render() {
    const { tag: Tag = 'div', context, className } = this.props;
    const { data, isClosed } = this.state;
    if (isClosed || data.length === 0) return null;
    return (
  // @ts-ignore 
      <Tag data={data} tag='div' context={'polls'} relations={[]} {...this.props} className={cnFilters({}, [className])} >
        <h4 className={cnFilters('Subtitle')} children={`${i18n.t('categories')}:`}/>
        <Selectize onClick={this.changeCategory} elements={data} active={data.reduce((acc: string | undefined, cur: any) => context[cur.type] && context[cur.type] === cur.value ? cur.id : acc, undefined)}/>
      </Tag>
    )
  }
}

export const FiltersTypeShop = connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersShopProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(FiltersTypeShopPresenter)