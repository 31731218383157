import { ReactNode } from 'react';
import { WelcomesNews } from './Welcomes.type'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { NewsContextProps } from 'News/contexts/news'
import {
    getNewsParamsById
} from 'News/redux/saga/selectors'
export interface IWelcomesPropsType extends NewsContextProps {

}
export type TStateProps = ReturnType<typeof mapStateToProps>
export interface IWelcomesStateType {
}

const newsParams = [
    'id',
    'text',
    'user'
] as const

export const mapStateToProps = (state: any, { newsId }: IWelcomesPropsType) => ({
    userData: getCurrentUser(state),
    ...getNewsParamsById<WelcomesNews, typeof newsParams[number]>(newsId, newsParams)(state)
})

