/**
 * @packageDocumentation
 * @module Widget_SecretSantaWidgetList
 */
import data from './data';
import uischema from './uischema';
import schema from './schema';

export default {
  data,
  schema,
  uischema,

}