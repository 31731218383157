// import {User, BaseNews, defaultBaseNews,  defaultUser, defaultGroup, Group} from '../BaseType'
import { IBaseNews, createDefaultNews } from 'News/types/baseNews'
export interface LongreadNews extends IBaseNews {
    newstype: 40;
}

export const createDefaultLongreadNews = (): LongreadNews => ({
    ...createDefaultNews(),
    newstype: 40,
    additionalFields: {
        currentStatus: null,
        responsibleForStatus: null,
        showChangeStatusButton: false,
        additionalFieldsValues: []
    }
})

export const defaultLongreadNews: LongreadNews = {
    ...createDefaultNews(),
    newstype: 40,
    additionalFields: {
        currentStatus: null,
        responsibleForStatus: null,
        showChangeStatusButton: false,
        additionalFieldsValues: [
            {
                id: '',
                title: '',
                description: '',
                isCollapsed: false,
                fields: [
                    {
                        id: '',
                        name: 'Текст',
                        description: '',
                        type: 'WikiBlock',
                        data: {
                            blocks:[],
                            entityMap: {}
                        },
                        displayName: '',
                        isBaseField: true,
                        value: '',
                        isMandatory: false,
                        isHidden: false,
                        visibility: null,
                        isReadOnly: false,
                        maxLength: 100000,
                        position: 0,
                        displayMode: '',
                        linkedFieldStatus: null,
                        selectOnlyFromCollection: false,
                    }
                ]
            }
        ],
    }
}