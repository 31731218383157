import React, { FC, useEffect, useState } from 'react'
import { cnItem, mapDispatchToProps, mapStateToProps } from './Item.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Item.scss'
import { Button, Avatar } from 'uielements/src';
import { Progress } from '../../../components/Progress/Progress';
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SessionList from '../SessionList'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

const getFirstOrDefault = (array: any[], defaultValue: any = null) => {
    if (array.length > 0) return array[0]
    return defaultValue
}

export const ItemPresenter: FC<any> = ({ className, student, cid, unitCount }) => {
    if (!student.user) return <div className={cnItem({}, [className])}>USER NULL</div>
    const { logs, user, userId, completeUnitCount, totalUnitsCount } = student
    const firsLog = getFirstOrDefault(logs || [], { sessionId: '0', completePercentage: 0, totalUnitsCount: 0 })
    const currentStep = completeUnitCount;//Math.round(firsLog.completePercentage / (100 / unitCount))
    const link = `/TeacherOffice/Course/${cid}?tab=student&sid=${firsLog.sessionId}&uid=${userId}`;

    return <ExpansionPanel TransitionProps={{ unmountOnExit: true }} className={cnItem({}, [className])}>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Grid container alignItems="center">
                <Grid item>
                    <Avatar className={cnItem('Avatar')} size={56} imgUrl={user.userPhotoUrl} name={user.displayName} />
                </Grid>
                <Grid item xs={9}>
                    <div className={cnItem('Info')}>
                        <div className={cnItem('Top')}>
                            <div><Button type={logs ? 'rlink' : undefined} theme={'unstyled'} className={cnItem('Title')} href={logs ? link : undefined}>{user.displayName}</Button></div>
                            <div className={cnItem('Spacer')}></div>
                            <div className={cnItem('Users')}></div>
                            {logs && <div className={cnItem('Duration')}>{tT('student_list.item.sessions.1', { count: logs.length})}</div>}
                        </div>
                        <div className={cnItem('Bottom')}></div>
                        <Progress className={cnItem('Progress')} currentStep={currentStep} totalSteps={totalUnitsCount} />
                    </div>
                </Grid>
                <Grid item>
                    {logs && <div className={cnItem('Action')}>
                        <Button type={'rlink'} className={cnItem('Title')} href={link}>{tT('student_list.item.results.1')}</Button>
                    </div>}
                </Grid>
            </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <SessionList className={cnItem('SessionList')} cid={cid} logs={logs} userId={userId} unitCount={unitCount} />
        </ExpansionPanelDetails>
    </ExpansionPanel>
}

export const Item = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ItemPresenter));
