import { cn } from '@bem-react/classname';
import { bindActionCreators, Action, Dispatch } from "redux";
import { IStateType } from 'redux/store';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { IStoreAppSetting } from 'models/store/appSettings';
import { getShopProduct, setShopProduct, checkCharacteristics } from '../../../redux/shop/actions';
import { getShopProductSelector } from '../../../redux/shop/selectors';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

import { getNewsById } from 'News/redux/saga/selectors'
export interface IShopCardProps extends IShopCardOwnProps, IShopProductStateProps, IShopProductDispatchProps, WithTranslation, RouteComponentProps<IParams> { }

interface IShopCardOwnProps {
  onShowMore?: () => void
  isLoading?: boolean
  withError?: boolean
}

export interface IParams {
  id: string
}

type IShopProductStateProps = ReturnType<typeof mapStateToProps>;

const noopObj: any = {}

export const mapStateToProps = (state: IStateType, {match}: RouteComponentProps<IParams>) => {
  const product = getShopProductSelector(state)
  const data = product.data//`${product.data.id}` === match.params.id ? product.data : noopObj
  const news = getNewsById(data?.news.id || '')(state) 
  const { contentRating = null } = data?.news ? news : noopObj
  return {
    contentRating,
    data,
    news,
    allowOrder: product.allowOrder,
    errorMessage: product.errorMessage,
    currentImgUrl: product.currentImgUrl,
    currentImage: product.currentImage,
    selectedCharsCount: product.selectedCharsCount,
    isHidePricesAndButtonBuy: product.isHidePricesAndButtonBuy,
    isHideButtonBuy: product.isHideButtonBuy,
    maxOrdersCount: product.maxOrdersCount,
    maxOrdersPeriod: product.maxOrdersPeriod,
    ordersCount: product.ordersCount,
    additionalInformationBeforeOrder: product.additionalInformationBeforeOrder,
    userThanksCount: getAuthUser(state).extData.mythanksCount,
    currencyFormats: state.store.appSettings as IStoreAppSetting
  }
}

type IShopProductDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  getShopProduct,
  setShopProduct,
  checkCharacteristics
}, dispatch);

export const cnShopProduct = cn('ShopProductMui');


