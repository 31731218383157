import * as types from './actions.d' 
import actions from './actionsType'


export const changeStatus: types.CChangeStatus = payload => ({
    type: actions.CHANGE_STATUS,
    payload
})

export const getUserStatuses: types.CGetUserStatuses = payload => ({
    type: actions.GET_USER_STATUSES,
    payload
})

export const getStatusFields: types.CGetStatusFields = payload => ({
    type: actions.GET_STATUS_FIELDS,
    payload
})

export const changeResponsible: types.CChangeResponsible = payload => ({
    type: actions.CHANGE_RESPONSIBLE,
    payload
})