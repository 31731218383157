import React from 'react';


import { cnWidget, cnWidgetMui, IWidgetProps } from '../Widget.index';

import './Widget_type_quests.scss';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { SecretSantaWidget } from 'blocks/SecretSantaWidget/SecretSantaWidget';
import { IWidgetTypeSecretSantaProps } from './Widget_type_secret_santa.index';

const type = 'secretSanta';

export default class WidgetTypeSecretSantaPresenter extends WidgetPresenter<IWidgetTypeSecretSantaProps> {

  public render() {
    if (!this.props.widget) return null
    const subtype = utils.widgetSubtype(this.props.widget.type);
    return <SecretSantaWidget {...this.props} className={cnWidgetMui({type, subtype})} settings={this.props.wcontext} tag={widgets.components.common} />
  }
}