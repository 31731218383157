import React, { FC } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { } from 'uielements/src';
import Slider from '@material-ui/core/Slider';
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { useDependedState } from 'utils/src/hooks'

const marks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 25,
        label: '25%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 75,
        label: '75%',
    },
    {
        value: 100,
        label: '100%',
    },
];

export const Presenter: FC<IPresenterProps> = ({ className, setStudentsFilters, minValue, maxValue }) => {
    const [value, setValue] = useDependedState([minValue, maxValue], [minValue, maxValue])
    const onChange = (e: any, value: any) => {
        setStudentsFilters({
            'filter.CompletePercentageMin': value[0],
            'filter.CompletePercentageMax': value[1]
        })
    }
    return <div className={cnClassName({}, [className])}>
        <h4 className={cnClassName("Name")}>{tT('student_list.filters.progress.1')}</h4>
        <div className={cnClassName("SliderBox")}>
            <Slider
                value={value}
                // step={10}
                marks={marks}
                valueLabelDisplay="auto"
                onChangeCommitted={onChange}
                onChange={(e, val) => setValue(val as number[])}
                aria-labelledby="range-slider"
            // getAriaValueText={'valuetext'}
            />
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
