import Component, { SkillsHeader } from './SkillsHeader';
import {
    SkillsHeaderInterface,
    cnClassName,
} from './SkillsHeader.interface';


export {
    cnClassName,
    SkillsHeader
}

export type IComponentProps = SkillsHeaderInterface.IOwnProps;
export type IComponentPresenterProps = SkillsHeaderInterface.IPresenterProps;


export default Component