import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Icon } from 'uielements/src'
import { cnSurveyWidget, mapDispatchToProps, mapStateToProps, ISurveyWidget } from './SurveyWidget.index'
import { SurveyWidgetItem } from './SurveyWidgetItem/SurveyWidgetItem'
import i18n from 'localizations/i18n'
import './SurveyWidget.scss';
import { Translate } from 'localizations/Translate';
import noData from 'assets/svg/NoData.svg'
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { CircularProgress } from '@material-ui/core';
import { useWidgetContext } from 'Widgets_v2/context'
import { KeyboardArrowLeft, KeyboardArrowRight } from 'muicomponents/src/Icons'
import LoadButton from 'uielements/src/MaterialElements/ProgressButton'

import { Typography } from 'muicomponents/src'
import { CustomSettings } from 'utils/src';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { Box } from 'muicomponents/src'

const SurveyWidgetPresenter = (props: ISurveyWidget) => {
  const {
    tag: Tag = 'div',
    activSurveyIdx,
    dataLength,
    edit,
    changeActiveSurveyIdx,
    getMySurveysFromServer,
    settings,
    isLoading
  } = props;

  const {
    sendWidgetSignal
  } = useWidgetContext();

  // const count = ((dataLength > 0 ? (dataLength / 10) : 0) + 1) * 10
  const count = 10

  useEffect(() => {
    if (dataLength === 0) getMySurveysFromServer({ skipCount: dataLength || 0, count })
  }, [])

  useEffect(() => {
    sendWidgetSignal('dataUpdate');
  }, [dataLength, isLoading, activSurveyIdx]);

  let activeSurvey = activSurveyIdx

  // if (!edit && !dataLength) return null
  if (settings?.hideIfNoData && !dataLength) return <Tag className={cnSurveyWidget({})} {...{ ...props, isHidden: !dataLength }} />;
  if (!settings?.hideIfNoData && !dataLength && !isLoading) return <Tag {...props} className={cnSurveyWidget("EmptyItem")}>
    <div className={cnSurveyWidget('Header-Title')}>
      <Translate i18nKey={'pryaniky.surwey.widget.title'} />
    </div>
    <Avatar precentage={100} shape={'rectangle'}
      imgUrl={noData}
    />
    <span className={cnSurveyWidget('Header-Title-Text')}>  <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} /></span>
  </Tag>;



  return (
    <Tag {...props} className={cnSurveyWidget({}, [props.className])}>
      <div className={cnSurveyWidget('Header')}>
        <span className={cnSurveyWidget('Header-Title')}>{i18n.t('pryaniky.surwey.widget.title')}</span>
        {/* <div className={cnSurveyWidget('Navigate')}>
          <div className={cnSurveyWidget('Navigate-Item')} onClick={() => activSurveyIdx !== 0 && changeActiveSurveyIdx(--activeSurvey)}>
            <KeyboardArrowLeft color="primary" />

          </div>
          <span className={cnSurveyWidget('Header-Total')}>
            <span>{`${activSurveyIdx + 1}`}</span>
            <span className={cnSurveyWidget('Header-Total-Length')}>{`/${dataLength}`}</span>
          </span>
       
          <div className={cnSurveyWidget('Navigate-Item')} onClick={() => activSurveyIdx !== dataLength - 1 && changeActiveSurveyIdx(++activeSurvey)}>
            <KeyboardArrowRight color="primary" />

          </div>
        </div> */}
        <Box className={cnSurveyWidget('Navigation')}
          sx={{
            ".MuiButton-root:hover": {
              background: "inherit"
            },
            ".MuiButton-root": {
              minWidth: "24px",
              width: "24px",
              padding: "0px !important"
            }
          }}>
          <LoadButton className={cnSurveyWidget('Button')} onClick={() => activSurveyIdx !== 0 && changeActiveSurveyIdx(--activeSurvey)} >
            {/* <Icon icon={'chevron-left'} /> */}
            <KeyboardArrowLeft color="primary" />
          </LoadButton>
          <Box className={cnSurveyWidget('Indicator')} >
            {`${activSurveyIdx + 1}`}
            <span>{`/${dataLength}`}</span>
          </Box>
          <LoadButton isLoading={isLoading} className={cnSurveyWidget('Button')} onClick={() => activSurveyIdx !== dataLength - 1 && changeActiveSurveyIdx(++activeSurvey)}>
            {/* <Icon icon={'chevron-right'} /> */}
            <KeyboardArrowRight color="primary" />
          </LoadButton>
        </Box>
      </div>

      <div className={cnSurveyWidget('Survey')}>
        {isLoading ? <div className={cnSurveyWidget('Survey-Loading')}> <CircularProgress /></div> : <SurveyWidgetItem settings={settings} />}
      </div>

    </Tag>
  )
}

export const SurveyWidget = connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyWidgetPresenter)