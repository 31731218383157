import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IDispatchProps } from 'redux/connector';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';

export interface ICountSliderProps extends IClassNameProps, CountSliderStateProps, IDispatchProps {
  step?: number;
  minValue?: number;
  icon?: string;
  type?: 'modal';
  value: number;
  currency?: string;
  maxCount?: number;
  onChange(value: any): void;
  closeBtn?(): void;
  saveBtn?(value: number): void;
}

export type CountSliderStateProps = ReturnType<typeof mapStateToProps>

export interface ICountSliderState {
  text?: string;
  focus: boolean;
}

export const mapStateToProps = (state: any) => ({
  currencyName: state.store.appSettings.currencyNameNominativeSingular,
  currencyNamePlural: state.store.appSettings.currencyNameGenitivePlural,
  thanksForAll: getCurrentUser(state).extData.thanksForAll,
});

export const cnCountSlider = cn('CountSlider');