import React, { FC } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { Selectize, Button } from 'uielements/src';
import { linkCreator, zeroId } from 'LMSModule/utils/utils.lms'
import List, { ListProps, ListTypeMap } from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Item from './item'
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';

const SortableItem = SortableElement(Item);
const SortableList = SortableContainer<ListProps<React.ElementType<any>>>(List);

export const Presenter: FC<IPresenterProps> = ({ className, units, active, changeUnitPosition, cid, editOnlyLesson = false }) => {

    const availableUnits = editOnlyLesson ? units.filter((v: any) => v.unitType === 'wiki') : units

    const onSortEnd = ({ oldIndex, newIndex }: any) => changeUnitPosition({ oldIndex, newIndex, cid });
    return <div className={cnClassName({}, [])}>
        <SortableList
            useDragHandle
            component={'div'}
            onSortEnd={onSortEnd}>
            {units.map((val: any, i: number) => (
                <>
                    <SortableItem key={`unit-${val.id}`} index={i} unit={val} selected={active === val.id} />
                    <Divider key={`diviler-${val.id}`} variant="fullWidth" component="div" />
                </>
            ))}
        </SortableList>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
