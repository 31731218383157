import React from 'react'
import { EventProps } from 'react-big-calendar'
import { cnCalendarEvent } from './CustomEvent.index'
import './CustomEvent.scss'
import { CommonEvent } from './types/CommonEvent/CommonEvent'
import { BirthdayEvent } from './types/BirthdayEvent/BirthdayEvent'
import { LmsEvent } from './types/LmsEvent/LmsEvent'
import { WorkflowsEvent } from './types/WorkflowsEvent/WorkflowsEvent'
import Box from '@mui/material/Box'
import AccessAlarms from '@mui/icons-material/AccessAlarms';

const eventsTypesMap = {
  events: CommonEvent,
  birthday: BirthdayEvent,
  lms: LmsEvent,
  workflow: WorkflowsEvent,
  // wikipagenews: CommonEvent
}

export const CustomEvent: React.FC<EventProps> = (props: any) => {
  const event = props.event as any
  let href = event.url;

  const EventRender = event.componentRenderName in eventsTypesMap ? (eventsTypesMap as any)[event.componentRenderName] : () => <>type error: {event.componentRenderName}</>;
  return <Box sx={{
    display: 'flex',
    fontSize: '14px',
    paddingTop: '3px',
    paddingBottom: '3px',
    borderRadius: '4px',
    borderColor: `${event.eventBorderColor}`,
    backgroundColor: `${event.eventColor}`
  }}
  >
    <EventRender event={event} />
  </Box>
}