import { cn } from "@bem-react/classname";
import { IClassNameProps } from "@bem-react/core";
import { TWidget } from "utils/src/BaseTypes/widgets.types";

export type PageProps = IClassNameProps & {
    page: {
      name: string;
      data?: { [s: string]: any };
      params?: { [s: string]: any };
    },
    cbAfterGetData?: () => void;
    children?: any;
};

export const cnPage = cn('Page');