import { Box, Paper, styled } from "muicomponents/src";
import React, { ComponentProps, FC } from "react";
import { InfinityList } from "muicomponents/src/InfinityList";
import { useTasksListContext } from "../TasksList.context";
import { TaskModelColumn } from "utils/src";
import { Translate } from "localizations/Translate";
import { cnTaskListItemCell } from "../Item/TasksListItem.index";
import { ItemActionCell, ItemBox, ItemGridContent } from "../TasksList.styled";
import { HeaderListCell, StyledItemBox } from "./TasksListComponent.styled";

const StyledBox = styled(Box)({
    // display: 'grid'
}) as typeof Box;

const HeaderGridItem = styled('th')({

});

const GridCellContent = styled('div')({

});

export const TasksListComponent: FC<ComponentProps<typeof InfinityList>> = ({
    children,
    ...props
}) => {

    const context = useTasksListContext();

    return (
        <InfinityList {...props}
            ContentComponent={StyledBox}
        >
            <StyledItemBox
                disableRipple
                component={Box}
            >
                {
                    context.columns.filter((el: TaskModelColumn) => el.isVisible).map((col: TaskModelColumn, idx) => {
                        return <HeaderListCell key={idx} className={cnTaskListItemCell('Header', [col.name])}>
                            <ItemGridContent>
                                <Translate i18nKey={`pryaniky.task.field.${col.name}`} defaults={Translate.t({ i18nKey: `pryaniky.task.field.custom.${col.name}` })} />
                            </ItemGridContent>
                        </HeaderListCell>
                    })
                }
                <ItemActionCell
                    className={cnTaskListItemCell('Actions')}
                    component='span'
                />
            </StyledItemBox>
            {children}
        </InfinityList>
    );
};