import {
    addInvest
} from 'News/redux/actions'
import { bindActionCreators } from 'redux';

export interface IIdeaSupportProps extends ReturnType<typeof mapDispatchToProps> {
    newsId: string;
    valueChange: (value: number) => void;
    anchor: React.RefObject<HTMLElement>;
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    addInvest
}, dispatch);