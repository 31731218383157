import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    StatisticsFull,
} from './interfaces'
import { useSelector } from 'react-redux';
import './style.scss'
import { generateLinkData } from 'utils/src/utils'
import { BadgeIcon } from 'uielements/src/BadgeIcon/BadgeIcon'
import { getCurrencyNameByCount } from 'utils/src/CommonRedux/base/selectors'
import { tSt } from 'LMSModule/utils/i18n.adaptor'
import { Button, Avatar, Icon, HelpTooltip } from 'uielements/src';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Translate } from 'localizations/Translate';
import { formatDateSTD } from 'utils/src/utils.dates';
type IPresenterProps = StatisticsFull.GameListItem.IPresenterProps
const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});
export const Presenter: FC<IPresenterProps> = ({
    className,
    item
}) => {
    const classes = useRowStyles();
    const currency = useSelector(getCurrencyNameByCount(item.countIssusedCurrency))
    const linkProps = generateLinkData(item.referenceNews)
    return <TableRow className={classes.root}>
        <TableCell>
            <div className={cnClassName('UserBox')}>
                <Button type='link' theme="unstyled" padding="sm" target="_blank" href={`/administration/badge/${item.badge.pkid}/main`}>
                    <Avatar className={cnClassName('Avatar')} size={56} name={item.badge.name} imgId={item.badge.imgId180x180} imgUrl={item.badge.iconUrl} />
                </Button>
                <Button type='link' theme="unstyled" padding="sm" target="_blank" href={`/administration/badge/${item.badge.pkid}/main`}>
                    {item.badge.name} ({item.badge.pkid})
                </Button>
            </div>
        </TableCell>

        {/* {item.ruleId === null ? <TableCell>-</TableCell> : <TableCell>
            {item.titleRule === "manual" ? <HelpTooltip help={<Translate i18nKey={'pryaniky.statistics.title.badge.list.manual.rule.help'} />}>
                <Icon icon={'user-check'} />
            </HelpTooltip> : item.ruleId}
        </TableCell>} */}
        <TableCell className={cnClassName('RuleId')}>
            {item.eventId !== null ?
                item.eventId : <TableCell>-</TableCell>}
        </TableCell>
        <TableCell>
            {item.eventId !== null ?
                <Button type='link' theme='unstyled' padding="sm" target="_blank" href={`/TeacherOffice/Course/${item.courseUId}`}>
                    <Translate i18nKey={"pryaniky.statistics.title.badge.list.event.rule"} values={{ titleRule: item.titleRule }} />
                </Button> : item.titleRule
            }
        </TableCell>
        <TableCell>{item.countIssusedCurrency}</TableCell>
        {item.user &&
            <TableCell>
                <div className={cnClassName('UserBox')}>
                    <Button type='link' theme="unstyled" padding="sm" target="_blank" noAdminBasename href={`/user/${item.user.id}`}>
                        <Avatar className={cnClassName('Avatar')} size={56} name={item.user.displayName} imgId={item.user.imgId} imgUrl={item.user.imgUrl} />
                    </Button>
                    <UserMention className={cnClassName('Mention')} id={item.user.id} name={item.user.displayName} noAdminBasename url={`/user/${item.user.id}`} />
                </div>

            </TableCell>
        }
        <TableCell> {formatDateSTD(item.timeStamp, true, true)}</TableCell>

        <TableCell>
            <Button type='link' main padding="sm" target="_blank" href={item.referenceNews}>
                <Translate i18nKey={"pryaniky.statistics.title.badge.list.to.news"} />
            </Button>


        </TableCell>


    </TableRow>
    // return <div className={cnClassName({}, [className])}>
    //     <div className={cnClassName('BadgeIcon')}>
    //         <BadgeIcon
    //             size={52}
    //             shape={'circle'}
    //             key={item.badge.badgeUid}
    //             badgeImgUrl={item.badge.iconUrl}
    //             name={item.badge.name}
    //             badgeUid={item.badge.badgeUid}
    //             description={item.badge.description}
    //             imgId={item.badge.imgId180x180}
    //         />
    //     </div>

    //     <div>
    //         <UserMention
    //             className={cnClassName('UserAvatar')}
    //             id={item.user.id}
    //             avatar={{ size: 14, name: item.user.displayName, imgUrl: item.user.imgUrl, imgId: item.user.imgId }}
    //             name={item.user.displayName} />
    //     </div>

    //     <div>
    //         <UserMention className={cnClassName('UserName')} id={item.user.id} name={item.user.displayName} /> {tSt('item.issused')} "<b>{item.titleRule}</b>" и <b>{item.countIssusedCurrency} {currency}</b>, {formatDateSTD(item.timeStamp, true)}
    //     </div>

    //     <div className={cnClassName('Spacer')}/>

    //     <div className={cnClassName('Link')}>
    //         <Button {...linkProps} theme={'unstyled'}>{tSt('item.publication')}</Button>
    //     </div>

    // </div>
}

export default memo(Presenter);
