import { IBaseNews, ICreateBaseNews } from '../types/baseNews'
import { IReplyModel } from '../types/reply'
import { ButtonContextPropvider, IButtonContextPropviderProps } from 'uielements/src/Button/Button';
import React, { FC, memo, useCallback } from 'react'
import * as utils from 'utils/src/utils';
import { Translate } from 'localizations/Translate';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { formatDateSTD } from 'utils/src/utils.dates';

export function changeUsersGroups<T extends IBaseNews = IBaseNews>(selected: any[], model: T) {
    const users = selected.filter((val: any) => val.type === 'user')
    const groups = selected.filter((val: any) => val.type === 'group')

    return { ...model, groups, users }
}

export const lenUserCount = (news: IBaseNews) => {
    let usersCount = news.users?.length || 0
    usersCount += (news.groups || []).reduce((acc: number, cur: any) => {
        return acc + cur.membersCount
    }, 0)
    return usersCount
}

export const lenUserCountInReply = (news: IReplyModel) => {
    let usersCount = news.users?.filter((v: any) => v.type !== 'group').length || 0
    usersCount += (news.users || []).filter((v: any) => v.type === 'group').reduce((acc: number, cur: any) => {
        return acc + cur.membersCount
    }, 0)
    return usersCount
}

export const lenAllUserCurrency = (thanksForAllByCollection: any, thanksForAll: number) => {
    const keys = Object.keys(thanksForAllByCollection || {})
    const summary: number = thanksForAll + keys.reduce((accumulator: number, currentValue: string) => {
        return accumulator + thanksForAllByCollection[currentValue].value
    }, 0);
    return summary
}

export const getFieldsValues = (currentNews: IBaseNews) => currentNews.additionalFields?.additionalFieldsValues

/**
 * получить id новости из урла
 * @param url 
 */
export const getNewsIdFromUrl = (url: string) => {
    const regexp = /\/news\/([A-z0-9]{8}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{12})/
    const result = url.toLowerCase().match(regexp)
    return result?.[1]
}



export const LinkFilter: FC<{ action: () => void }> = memo(({
    children,
    action
}) => {
    const btnPropsFilter: IButtonContextPropviderProps['propsFilter'] = (props) => {
        const defaultOnClick = props['onClick'];

        return {
            ...props,
            onClick: (e) => {
                if (e.ctrlKey) {
                    defaultOnClick && defaultOnClick(e)
                } else {
                    e.stopPropagation()
                    e.preventDefault()
                    action()
                }
            }
        }
    }

    return <ButtonContextPropvider propsFilter={btnPropsFilter}>
        {children}
    </ButtonContextPropvider>
})


export const createNewsDate = (
    { publishAt, isDraft, changeDate, date }:
        { publishAt?: string | null, isDraft: boolean, changeDate?: string, date: string }
) => {
    const notPublished = isDraft && publishAt && (new Date(publishAt)) >= (new Date())

    const isChanget = changeDate !== date

    let dateStr = isChanget ?
        <Tooltip title={<><Translate i18nKey={`published_it`} />: {formatDateSTD(date, true)}</>}>
            <span><Translate i18nKey='edited' />: {formatDateSTD(changeDate || date, true)}</span>
        </Tooltip> : formatDateSTD(date, true);

    dateStr = isChanget ? dateStr : (notPublished ?
        <><Translate i18nKey='pryaniky.validate.news.published.planned' />{formatDateSTD(changeDate || date, true)}</> :
        dateStr)
    return dateStr
}



export const isNotPublished = (isDraft: boolean, publishAt?: string | null) =>
    isDraft && publishAt && (new Date(publishAt)) >= (new Date())
