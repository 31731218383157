import { useLocation } from "react-router";

type PathTypes = 'user' | 'group' | 'tag';

const PathTypeRegExp = /(user|group|tag)/;

const PathRegExp = /((user|group|tag)\/[\w-\.]+)\/?/;

export const typeOfPath = (): PathTypes | null => {
    const location = useLocation();
    const matchType = location.pathname.toLowerCase().match(PathTypeRegExp);
    return matchType ? matchType[1] as PathTypes : null;
};

export const useUserGroupTagPath = () => {
    const location = useLocation();
    const match = location.pathname.toLowerCase().match(PathRegExp);
    return match ? match[1] : null;
};