/**
* Based on the Scorm 2004 definitions from https://scorm.com
*
* Scorm 2004 Overview for Developers: https://scorm.com/scorm-explained/technical-scorm/scorm-2004-overview-for-developers/
* Run-Time Reference: http://scorm.com/scorm-explained/technical-scorm/run-time/run-time-reference/
* Scorm specification: https://adlnet.gov/research/scorm/scorm-2004-4th-edition/
* Testing requirements: https://adlnet.gov/assets/uploads/SCORM_2004_4ED_v1_1_TR_20090814.pdf
*
* SPM = Smallest Permitted Maximum
*/

import CMI,
{
    CommentsFromLearner,
    Score,
    CommentsFromLms,
    CMITypes,
    Interactions,
    LearnerPreference,
    Objectives,
    InteractionsCorrectResponsesObject,
    InteractionsObjectivesObject,
    InteractionsObject
} from './cmi'
import ADL from './adl'
import constants from '../constants'
import BaseAPI, { BaseAPITypes } from '../baseAPI/baseAPI'
import { cloneDeep } from 'lodash'

export type SetValueType = (CMIElement: BaseAPITypes.CMIElementType, value: any) => void

export interface ScormAPI2004Params {
    // setValueCb?: SetValueType
}

class ScormAPI2004 extends BaseAPI {

    /**
     * 
     */
    public version = "1.0";

    /**
     * 
     */
    public cmi: CMI;

    /**
     * 
     */
    public adl: ADL;


    constructor(param?: ScormAPI2004Params) {
        super()
        this.cmi = new CMI(this)
        this.adl = new ADL(this)
    }


    /**
     * @param Empty String
     * @returns {string} bool
     */
    public Initialize() {
        let returnValue = constants.SCORM_FALSE;

        if (this.isInitialized()) {
            this.throwSCORMError(103);
        } else if (this.isTerminated()) {
            this.throwSCORMError(104);
        } else {
            this.currentState = constants.STATE_INITIALIZED;
            this.lastErrorCode = 0;
            returnValue = constants.SCORM_TRUE;
            this.processListeners("Initialize");
        }

        this.apiLog("Initialize", null, "returned: " + returnValue, constants.LOG_LEVEL_INFO);
        this.clearSCORMError(returnValue);

        return returnValue;
    }

    /**
     * @param Empty String
     * @returns {string} bool
     */
    public Terminate() {
        let returnValue = constants.SCORM_FALSE;

        if (this.isNotInitialized()) {
            this.throwSCORMError(112);
        } else if (this.isTerminated()) {
            this.throwSCORMError(113);
        } else {
            this.currentState = constants.STATE_TERMINATED;
            this.lastErrorCode = 0;
            returnValue = constants.SCORM_TRUE;
            this.processListeners("Terminate");
        }

        this.apiLog("Terminate", null, "returned: " + returnValue, constants.LOG_LEVEL_INFO);
        this.clearSCORMError(returnValue);

        return returnValue;
    }


    /**
     * @param CMIElement
     * @returns {string}
     */
    public GetValue(CMIElement: BaseAPITypes.CMIElementType) {
        let returnValue = "";

        if (this.isNotInitialized()) {
            this.throwSCORMError(122);
        } else if (this.isTerminated()) {
            this.throwSCORMError(123);
        } else {
            this.lastErrorCode = 0;
            returnValue = this.getCMIValue(CMIElement);
            this.processListeners("GetValue", CMIElement);
        }

        this.apiLog("GetValue", CMIElement, ": returned: " + returnValue, constants.LOG_LEVEL_INFO);
        this.clearSCORMError(returnValue);

        return returnValue;
    }

    /**
     * @param CMIElement
     * @param value
     * @returns {string}
     */
    public SetValue(CMIElement: BaseAPITypes.CMIElementType, value: any) {
        let returnValue = "";

        if (this.isNotInitialized()) {
            this.throwSCORMError(132);
        } else if (this.isTerminated()) {
            this.throwSCORMError(133);
        } else {
            this.lastErrorCode = 0;
            returnValue = this.setCMIValue(CMIElement, value);
            this.processListeners("SetValue", CMIElement, value);
        }

        this.apiLog("SetValue", CMIElement, ": " + value + ": result: " + returnValue, constants.LOG_LEVEL_INFO);
        this.clearSCORMError(returnValue);

        return returnValue;
    }

    /**
     * Orders LMS to store all content parameters
     *
     * @returns {string} bool
     */
    public Commit() {
        let returnValue = constants.SCORM_FALSE;

        if (this.isNotInitialized()) {
            this.throwSCORMError(142);
        } else if (this.isTerminated()) {
            this.throwSCORMError(143);
        } else {
            this.lastErrorCode = 0;
            returnValue = constants.SCORM_TRUE;
            this.processListeners("Commit");
        }

        this.apiLog("Commit", null, "returned: " + returnValue, constants.LOG_LEVEL_INFO);
        this.clearSCORMError(returnValue);

        return returnValue;
    }

    /**
     * Returns last error code
     *
     * @returns {string}
     */
    public GetLastError() {
        let returnValue = String(this.lastErrorCode);

        this.processListeners("GetLastError");

        this.apiLog("GetLastError", null, "returned: " + returnValue, constants.LOG_LEVEL_INFO);

        return returnValue;
    }

    /**
     * Returns the errorNumber error description
     *
     * @param CMIErrorCode
     * @returns {string}
     */
    public GetErrorString(CMIErrorCode: BaseAPITypes.CMIErrorCodeType) {
        let returnValue = "";

        if (CMIErrorCode !== null && CMIErrorCode !== "") {
            returnValue = this.getLmsErrorMessageDetails(CMIErrorCode);
            this.processListeners("GetErrorString");
        }

        this.apiLog("GetErrorString", null, "returned: " + returnValue, constants.LOG_LEVEL_INFO);

        return returnValue;
    }

    /**
     * Returns a comprehensive description of the errorNumber error.
     *
     * @param CMIErrorCode
     * @returns {string}
     */
    public GetDiagnostic(CMIErrorCode: BaseAPITypes.CMIErrorCodeType) {
        let returnValue = "";

        if (CMIErrorCode !== null && CMIErrorCode !== "") {
            returnValue = this.getLmsErrorMessageDetails(CMIErrorCode, true);
            this.processListeners("GetDiagnostic");
        }

        this.apiLog("GetDiagnostic", null, "returned: " + returnValue, constants.LOG_LEVEL_INFO);

        return returnValue;
    }

    /**
     * Sets a value on the CMI Object
     *
     * @param CMIElement
     * @param value
     * @returns {string}
     */
    public setCMIValue(CMIElement: BaseAPITypes.CMIElementType, value: any) {
        if (!CMIElement || CMIElement === "") {
            return constants.SCORM_FALSE;
        }

        var structure = CMIElement.split(".");
        var refObject = this as { [key: string]: any };
        var returnValue = constants.SCORM_FALSE;

        for (var i = 0; i < structure.length; i++) {
            var attribute = structure[i];
            // console.log('!_!_!_!__!-- current attr', attribute)
            if (i === structure.length - 1) {
                if ((attribute.substr(0, 8) == "{target=") && (typeof refObject._isTargetValid == "function")) {
                    this.throwSCORMError(404);
                } else if (!(attribute in refObject)) {
                    this.throwSCORMError(401, "The data model element passed to SetValue (" + CMIElement + ") is not a valid SCORM data model element. 1");
                } else {
                    refObject[attribute] = value;
                    // console.log('!_!_!_!__!-- set current attr', attribute, value, cloneDeep(refObject))
                    if (this.lastErrorCode == 0) {
                        // console.log('!_!_!_!__!-- this.lastErrorCode == 0')
                        returnValue = constants.SCORM_TRUE;
                    }
                }
            } else {
                // console.error('cloneDeep(refObject)', attribute, cloneDeep(refObject))
                refObject = refObject[attribute];
                if (!refObject) {
                    this.throwSCORMError(401, "The data model element passed to SetValue (" + CMIElement + ") is not a valid SCORM data model element. 2, attribute: " + attribute + "; value: " + value + "");
                    break;
                }

                if ("childArray" in refObject) {
                    var index = parseInt(structure[i + 1], 10);
                    // console.log('!_!_!_!__!-- childArray index', index)
                    // SCO is trying to set an item on an array
                    if (!isNaN(index)) {
                        var item = refObject.childArray[index];

                        if (item) {
                            refObject = item;
                        } else {
                            var newChild;

                            if (CMIElement.indexOf("cmi.comments_from_learner") > -1) {
                                newChild = new CommentsFromLearner(this);
                            } else if (CMIElement.indexOf("cmi.comments_from_lms") > -1) {
                                newChild = new CommentsFromLms(this);
                            } else if (CMIElement.indexOf("cmi.objectives") > -1) {
                                newChild = new Objectives(this);
                            } else if (CMIElement.indexOf(".correct_responses") > -1 && attribute !== 'interactions') {
                                // console.error('ВОт тут дебил этот забебилелся в конец', attribute)
                                newChild = new InteractionsCorrectResponsesObject(this);
                            } else if (CMIElement.indexOf(".objectives") > -1) {
                                newChild = new InteractionsObjectivesObject(this);
                            } else if (CMIElement.indexOf("cmi.interactions") > -1) {
                                newChild = new InteractionsObject(this);
                            }

                            if (!newChild) {
                                // console.log('re[i + 1]', structure[i + 1])
                                this.throwSCORMError(401, "The data model element passed to SetValue (" + CMIElement + ") is not a valid SCORM data model element. 3");
                            } else {
                                const lastIndex = refObject.childArray.length - 1
                                const fillFrom = lastIndex === -1 ? 0 : lastIndex
                                const fillCount = index - refObject.childArray.length;
                                // console.log('fillFrom', fillFrom)
                                // console.log('fillCount', fillCount)

                                // console.log('refObject.childArray.length', refObject.childArray.length)
                                if (null === item) {
                                    refObject.childArray[index] = newChild
                                } else {
                                    if (index > fillFrom) {
                                        // console.log('fill nulls', fillFrom, fillCount);
                                        (new Array(fillCount)).fill('').forEach(element => {
                                            refObject.childArray.push(null);
                                        });
                                    }
                                    // console.log(';;;;;;;;push new')
                                    refObject.childArray.push(newChild);
                                }
                                // cloneDeep(refObject)
                                refObject = newChild;
                            }
                        }

                        // Have to update i value to skip the array position
                        i++;
                    }
                }
            }
        }

        if (returnValue === constants.SCORM_FALSE) {
            this.apiLog("SetValue", null, "There was an error setting the value for: " + CMIElement + ", value of: " + value, constants.LOG_LEVEL_WARNING);
        }

        return returnValue;
    }

    /**
     * Gets a value from the CMI Object
     *
     * @param CMIElement
     * @returns {*}
     */
    public getCMIValue(CMIElement: BaseAPITypes.CMIElementType) {
        if (!CMIElement || CMIElement === "") {
            return "";
        }

        var structure = CMIElement.split(".");
        var refObject = this as { [key: string]: any };

        for (var i = 0; i < structure.length; i++) {
            var attribute = structure[i];

            if ((attribute.substr(0, 8) == "{target=") && (typeof refObject._isTargetValid == "function")) {
                var target = attribute.substr(8, attribute.length - 9);
                return refObject._isTargetValid(target);
            } else if (!(attribute in refObject)) {
                this.throwSCORMError(401, "The data model element passed to GetValue (" + CMIElement + ") is not a valid SCORM data model element.");
                return "";
            }

            refObject = refObject[attribute];
        }

        return refObject || "";
    }

    /**
     * Returns the message that corresponds to errrorNumber.
     */
    public getLmsErrorMessageDetails(errorCode: BaseAPITypes.CMIErrorCodeType, detail?: any) {
        var basicMessage = "";
        var detailMessage = "";

        // Set error number to string since inconsistent from modules if string or number
        const errorNumber = String(errorCode);

        switch (errorNumber) {
            case "0":
                basicMessage = "No Error";
                detailMessage = "No error occurred, the previous API call was successful.";
                break;

            case "101":
                basicMessage = "General Exception";
                detailMessage = "No specific error code exists to describe the error. Use GetDiagnostic for more information.";
                break;

            case "102":
                basicMessage = "General Initialization Failure";
                detailMessage = "Call to Initialize failed for an unknown reason.";
                break;

            case "103":
                basicMessage = "Already Initialized";
                detailMessage = "Call to Initialize failed because Initialize was already called.";
                break;

            case "104":
                basicMessage = "Content Instance Terminated";
                detailMessage = "Call to Initialize failed because Terminate was already called.";
                break;

            case "111":
                basicMessage = "General Termination Failure";
                detailMessage = "Call to Terminate failed for an unknown reason.";
                break;

            case "112":
                basicMessage = "Termination Before Initialization";
                detailMessage = "Call to Terminate failed because it was made before the call to Initialize.";
                break;

            case "113":
                basicMessage = "Termination After Termination";
                detailMessage = "Call to Terminate failed because Terminate was already called.";
                break;

            case "122":
                basicMessage = "Retrieve Data Before Initialization";
                detailMessage = "Call to GetValue failed because it was made before the call to Initialize.";
                break;

            case "123":
                basicMessage = "Retrieve Data After Termination";
                detailMessage = "Call to GetValue failed because it was made after the call to Terminate.";
                break;

            case "132":
                basicMessage = "Store Data Before Initialization";
                detailMessage = "Call to SetValue failed because it was made before the call to Initialize.";
                break;

            case "133":
                basicMessage = "Store Data After Termination";
                detailMessage = "Call to SetValue failed because it was made after the call to Terminate.";
                break;

            case "142":
                basicMessage = "Commit Before Initialization";
                detailMessage = "Call to Commit failed because it was made before the call to Initialize.";
                break;

            case "143":
                basicMessage = "Commit After Termination";
                detailMessage = "Call to Commit failed because it was made after the call to Terminate.";
                break;

            case "201":
                basicMessage = "General Argument Error";
                detailMessage = "An invalid argument was passed to an API method (usually indicates that Initialize, Commit or Terminate did not receive the expected empty string argument.";
                break;

            case "301":
                basicMessage = "General Get Failure";
                detailMessage = "Indicates a failed GetValue call where no other specific error code is applicable. Use GetDiagnostic for more information.";
                break;

            case "351":
                basicMessage = "General Set Failure";
                detailMessage = "Indicates a failed SetValue call where no other specific error code is applicable. Use GetDiagnostic for more information.";
                break;

            case "391":
                basicMessage = "General Commit Failure";
                detailMessage = "Indicates a failed Commit call where no other specific error code is applicable. Use GetDiagnostic for more information.";
                break;

            case "401":
                basicMessage = "Undefined Data Model Element";
                detailMessage = "The data model element name passed to GetValue or SetValue is not a valid SCORM data model element.";
                break;

            case "402":
                basicMessage = "Unimplemented Data Model Element";
                detailMessage = "The data model element indicated in a call to GetValue or SetValue is valid, but was not implemented by this LMS. In SCORM 2004, this error would indicate an LMS that is not fully SCORM conformant.";
                break;

            case "403":
                basicMessage = "Data Model Element Value Not Initialized";
                detailMessage = "Attempt to read a data model element that has not been initialized by the LMS or through a SetValue call. This error condition is often reached during normal execution of a SCO.";
                break;

            case "404":
                basicMessage = "Data Model Element Is Read Only";
                detailMessage = "SetValue was called with a data model element that can only be read.";
                break;

            case "405":
                basicMessage = "Data Model Element Is Write Only";
                detailMessage = "GetValue was called on a data model element that can only be written to.";
                break;

            case "406":
                basicMessage = "Data Model Element Type Mismatch";
                detailMessage = "SetValue was called with a value that is not consistent with the data format of the supplied data model element.";
                break;

            case "407":
                basicMessage = "Data Model Element Value Out Of Range";
                detailMessage = "The numeric value supplied to a SetValue call is outside of the numeric range allowed for the supplied data model element.";
                break;

            case "408":
                basicMessage = "Data Model Dependency Not Established";
                detailMessage = "Some data model elements cannot be set until another data model element was set. This error condition indicates that the prerequisite element was not set before the dependent element.";
                break;

            default:
                basicMessage = "";
                detailMessage = "";
                break;
        }

        return detail ? detailMessage : basicMessage;
    }


    /**
     * Loads CMI data from a JSON object.
     */
    public loadFromJSON(json: { [key: string]: any }, CMIElement: BaseAPITypes.CMIErrorCodeType) {
        if (!this.isNotInitialized()) {
            console.error("loadFromJSON can only be called before the call to Initialize.");
            return;
        }

        CMIElement = CMIElement || "cmi";

        for (var key in json) {
            if (json.hasOwnProperty(key) && json[key]) {
                var currentCMIElement = CMIElement + "." + key;
                var value = json[key];

                if (value["childArray"]) {
                    for (var i = 0; i < value["childArray"].length; i++) {
                        this.loadFromJSON(value["childArray"][i], currentCMIElement + "." + i);
                    }
                } else if (value.constructor === Object) {
                    this.loadFromJSON(value, currentCMIElement);
                } else {
                    this.setCMIValue(currentCMIElement, value);
                }
            }
        }
    }

    /**
     * Reset the API to its initial state
     */
    public reset() {
        super.reset();

        // Data Model
        this.cmi = new CMI(this);
        this.adl = new ADL(this);
    }

}


export default ScormAPI2004