import { Button as MuiButton } from 'muicomponents/src/Button/Button'
import { styled } from 'muicomponents/src/mui/system';
import { Avatar } from 'muicomponents/src/Avatar/Avatar'
import { Box } from 'muicomponents/src/Box/Box'
import { Typography } from 'muicomponents/src/Typography/Typography'
import { Link } from 'muicomponents/src/Link';
import MDRender from 'muicomponents/src/CommonmarkRender';

export const defaultIconStyle = {
    color: "rgba(189, 189, 189, 1)",
};

export const WikiImageListButton = styled(Link)({
    marginRight: "16px",
}) as typeof Link;

export const WikiItemCard = styled(Box)({
    display: 'flex',
    flexDirection: 'column'
}) as typeof Box;

export const WikiNameBox = styled(Typography)({
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
}) as typeof Typography;

export const WikiDescriptionTypography = styled(Typography)({
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
}) as typeof Typography;

export const WikilItemDescription = styled(MDRender)({
    display: 'inline !important'
}) as any as typeof MDRender;

export const WikiImageCardButton = styled(MuiButton)({
    padding: "0",
    position: "relative",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    "&:hover": {
        backgroundColor: "inherit"
    }
}) as typeof MuiButton;

export const WikiNameButton = styled(Link)({
    overflowWrap: "anywhere",
    padding: "0",
    textTransform: "inherit",
    justifyContent: "left",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.15px"
}) as typeof Link;

export const WikiCardAvatar = styled(Avatar)({
    borderRadius: "4px 4px 0 0",
    height: "188px",
    width: "100%",
}) as typeof Avatar;


export const ActionBox = styled(Box)({
    display: "flex",
    height: "24px",
    gap: "8px",
    marginTop: 'auto'
}) as typeof Box;

export const ActionContetBox = styled(Box)({
    padding: "4px 0",
    display: "flex",
    alignItems: "center",
}) as typeof Box;

export const ItemContentBox = styled(Box)({
    flex: 1,
    padding: "12px 16px 16px 16px",
    minHeight: "134px",
    boxSizing: "border-box",
    display: 'flex',
    flexDirection: 'column'
}) as typeof Box;