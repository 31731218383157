import { IDialog } from "../../../DialogWrapper.index";
import { IStateType } from 'redux/store';
import { WithTranslation } from "react-i18next";
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { dialogsGetIntecompanyGroupRequests, confirmIntercompanyGroupRequest, declineIntercompanyGroupRequest } from '../../../../../redux/actions/Modals';
import { IClassNameProps } from '@bem-react/core';
import { IGroupIntercompanyRequest } from "utils/src/requests/models/api.intepcompany";
import { cn } from '@bem-react/classname';
import { IBodyProps } from 'utils/src/DialogCreator';

export const dialogId = "IntercompanyGroupRequests"
export interface IntercompanyGroupRequestsProps extends IBodyProps, IMapIntercompanyGroupRequestsStateToProps, IMapIntercompanyGroupRequestsDispatchToProps { }

export const mapIntercompanyGroupRequestsStateToProps = (state: IStateType, props: any) => ({
  id: dialogId,
  data: (state.modals[dialogId] || {}).data || {},
})

export type IMapIntercompanyGroupRequestsStateToProps = ReturnType<typeof mapIntercompanyGroupRequestsStateToProps>

export const mapIntercompanyGroupRequestsDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  dialogsGetIntecompanyGroupRequests,
  confirmIntercompanyGroupRequest,
  declineIntercompanyGroupRequest
}, dispatch);

export type IMapIntercompanyGroupRequestsDispatchToProps = ReturnType<typeof mapIntercompanyGroupRequestsDispatchToProps>

export const cnIntercompanyGroupRequest = cn('IntercompanyGroupRequests');

export interface IIntercompanyGroupRequestItemProps extends IClassNameProps {
  dt: IGroupIntercompanyRequest,
  action: (type: string, groupId: string) => void
}

export interface IItemCommentState {
  action: string;
  groupId: string;
  comment: string;
}