import * as React from 'react';
import { IImageLoaderPreviewProps, IImageLoaderPreviewState, cnImageLoaderPreview } from "./ImageLoader-Preview.index";
import './ImageLoader-Preview.scss';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Box,
 
} from 'muicomponents/src';

export const ImageLoaderPreview: React.FC<IImageLoaderPreviewProps> = ({ className, previewUrl, square }) => {
  return <Box className={cnImageLoaderPreview({ empty: !previewUrl, circle: !square }, [className])} style={{ backgroundImage: `url("${previewUrl}")` }}>
    {
      !previewUrl &&
      < AddCircleIcon color="action" />
    }
  </Box>
}