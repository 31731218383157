import React, { FC } from 'react'
import { Box, Button, TextField, Typography } from 'muicomponents/src';
import { i18n } from 'localization';
import { Popover } from 'muicomponents/src/Popover';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from 'muicomponents/src/IconButton';



interface IStatusPopoverProps {
  popoverId: string | undefined
  open: boolean
  anchorEl: HTMLButtonElement | null
  statusText: string
  handleStatusClose: () => void
  onChangeStatus: (e: any) => void
  saveStatus: (text: string) => void
}

export const StatusPopover: FC<IStatusPopoverProps> = ({
  popoverId,
  open,
  anchorEl,
  statusText,
  handleStatusClose,
  onChangeStatus,
  saveStatus,
}: IStatusPopoverProps) => {

  return <Popover
    open={open}
    anchorEl={anchorEl}
    onClose={handleStatusClose}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}

  >
    <Box p={2}>

      <Box display='flex' alignItems='center' justifyContent='space-between' mb={2} >
        <Typography variant='h6' >
          {i18n.t('pryaniky.profile.changeOrAddStatus')}
        </Typography>
        <IconButton onClick={handleStatusClose} color='primary'>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box>
        <TextField
          value={statusText}
          onChange={onChangeStatus}
          label={i18n.t('pryaniky.profile.userStatus')}
          multiline
          fullWidth
          minRows={4}
        />
      </Box>

      <Box mt={2}>

        <Button variant='contained' id='save-user-status'
          size='small'
          onClick={() => saveStatus(statusText) }
          sx={{ mr: 1 }}>
          {i18n.t('Save')}
        </Button>

        <Button variant='outlined' size='small' id='cancel-user-status' onClick={handleStatusClose}>
          {i18n.t('cancel')}
        </Button>
      </Box>

    </Box>

  </Popover>
}
