/**
 * @packageDocumentation
 * @module ListHeader
 */
import { IClassNameProps } from '@bem-react/core';
import { cnList } from '../List.index';
import { IDropdownProps } from 'uielements/src/Dropdown/Dropdown.index';
import { ISearchInputProps } from 'uielements/src/SearchInput/SearchInput';

import { IDispatchProps } from '../../../redux/connector';

export interface ICounterAll {
  title: string | React.ReactNode;
  count: number;
  action(): void;
  hideCheckbox?: boolean;
}

export interface ICounterSelected {
  title: string | React.ReactNode;
  count: number;
  action: IDropdownProps;
}

export interface ICounter {
  all?: ICounterAll;
  selected?: ICounterSelected;
}

export interface IHeaderProps extends IClassNameProps, IHeaderStateProps {
  tag?: React.ComponentType<any> | string;
  search?: ISearchInputProps;
  actions?: IDropdownProps;
  counter?: ICounter;
  type?: 'common';
  [s: string]: any
}

export interface IHeaderState {
  text?: string;
}

export interface IHeaderStateProps {
  // store: any
}

export const mapStateToProps = (state: any) => ({
  // store: state.store
})

export const cnHeader = (...data: any) => {
  let name = 'Header';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnList(name, ...data)
}