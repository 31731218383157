import Component, { Presenter, CommentsRender, WithLoader } from './component';
import {
    News,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter, 
    CommentsRender, 
    WithLoader
}

export type IComponentProps = News.CommentsRender.IOwnProps;
export type IComponentPresenterProps = News.CommentsRender.IPresenterProps;


export default Component