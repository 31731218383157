import React, { FC, useEffect, useState, ComponentProps } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import uuid from 'uuid';
import * as utils from 'utils/src/utils';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: theme.spacing(0.5),
            margin: 0,
        },
        chip: {
            margin: theme.spacing(0.5),
        },
    }),
);

export const Presenter: FC<IPresenterProps> = ({
    className,
    files,
    onChange
}) => {
    const classes = useStyles();
    const handleDelete = (value: any) => () => {
        onChange(files.filter((file) => file.id !== value.id));
    };
    return <Paper component={"ul" as ComponentProps<typeof Paper>['component']} className={classes.root}>
        {files.map((data) => {
            return (
                <li key={data.id}>
                    <Chip
                        // icon={icon}
                        label={data.name}
                        onDelete={handleDelete(data)}
                        className={classes.chip}
                    />
                </li>
            );
        })}
    </Paper>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
