import React  from 'react';
import { connect } from 'react-redux';
import i18n from '../../../localizations/i18n';
import { mapDispatchToProps, IDispatchProps } from '../../../redux/connector';
import { cn } from "@bem-react/classname";
import './RadioButtons.scss';
import { IRadioButtonsProps, IRadioButtonsState} from  './RadioButtons.index';
import Landing from '../../../assets/png/Landing.png';
import Page from '../../../assets/png/Page.png';

export const cnWikiCreateDialog = cn("WikiCreateDialog");


export class RadioButtonsPresenter extends React.Component<IRadioButtonsProps, IRadioButtonsState>{
    constructor(props: IRadioButtonsProps) {
        super(props);
        this.onChangeSelected = this.onChangeSelected.bind(this);
        // this.state = {
        //     // selected: 'page',
        //     // value: 'page'
        // };
    }
    
    onChangeSelected(e:any) {
        // this.setState({ selected: e.target.value });
        this.props.onChange(e)
    }

    render() {
        // let selected= this.state.selected;
        const { onChange, selected} = this.props
      
        return (
            <div className={cnWikiCreateDialog("PageType")}>
                <div className={cnWikiCreateDialog("Page1")}>
                <label>
                        <img className={cnWikiCreateDialog("Page")} src={Page} alt="Page"/>
                        <h3>{i18n.t("pryaniky.modal.wikipage.page")}</h3>             
                    <input type='radio' id='page' name='type' value='page'
                            checked={selected === 'page'} onChange={onChange} />
                            
                </label>
                </div >
                <div className={cnWikiCreateDialog("Page1")}>
                    <label>
                            <img className={cnWikiCreateDialog("Page")} src={Landing} alt="Landing" />
                        <h3>{i18n.t("pryaniky.modal.wikipage.landing")}</h3>
                        <input type='radio' id='landing' name='type' value='landing'
                            checked={selected === 'landing'} onChange={onChange} />
                    </label>
                </div> 
                {
                    window.PRN_SERVICE.dev_features &&
                    <div className={cnWikiCreateDialog("Page1")}>
                        <label>
                                <img style={{transform: 'rotate(180deg)'}} className={cnWikiCreateDialog("Page")} src={Landing} alt="Landing" />
                            <h3>{i18n.t("pryaniky.modal.wikipage.grid")}</h3>
                            <input type='radio' id='grid' name='type' value='grid'
                                checked={selected === 'grid'} onChange={onChange} />
                        </label>
                    </div> 
                }

            </div>
        );
    }
};

const onChange = () =>{
    
}
const mapStateToProps = (state: any) => ({
    
});
export const RadioButtons = connect<any,any>(
    mapStateToProps,
    mapDispatchToProps({})
)(RadioButtonsPresenter);

