import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TWidget } from "utils/src/BaseTypes/widgets.types";
import { addHandler, removeHandler, SignalHandler, useSignal } from 'utils/src/signals';

export type WidgetsSignalsTypes = 'dataUpdate' | 'edit' | 'open';
type WidgetsSignals = `${TWidget['id']}/${WidgetsSignalsTypes}`;
type SplitedSiglnals<Type extends string> = Type extends `${infer T}/${infer ST}`
    ? [T, ST]
    : string;
type SignalsState = {
    dataUpdate: number;
    edit: boolean;
    open: boolean;
};

export const usePositionSignals = (id: TWidget['id'] | null) => {
    const [
        state,
        setState
    ] = useState<SignalsState>({
        dataUpdate: 0,
        edit: false,
        open: false
    });
    const stateRef = useRef(state);
    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const signals = useMemo<WidgetsSignals[]>(() => {
        return [`${id}/dataUpdate`, `${id}/edit`, `${id}/open`]
    }, [id]);

    const handler = useCallback<SignalHandler>((a, v) => {
        if(!a || !id) return ;
        const action = a as WidgetsSignals;
        const splitedType = action.split('/') as SplitedSiglnals<WidgetsSignals>;
        const widgetId = splitedType[0];
        const actionType = splitedType[1];
        const newValue: Partial<typeof state> = {};
        switch(actionType) {
            case 'dataUpdate': {
                newValue.dataUpdate = stateRef.current.dataUpdate + 1;
                break;
            }
            case 'edit': {
                newValue.edit = v;
                break;
            }
            case 'open': {
                newValue.open = v;
                break;
            }
        }
        setState({
            ...stateRef.current,
            ...newValue
        });

    }, []);

    useEffect(() => {
        signals.forEach(signal => {
            addHandler(signal, handler)
        });
        return () => {
            signals.forEach(signal => {
                removeHandler(signal, handler)
            });
        };
    }, [signals]);

    return state;
};

