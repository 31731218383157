import { cn } from '@bem-react/classname';

export const cnAction = cn('Action');
export interface ILikeProps {
    liked: boolean;
    likeCount: number;
    onClick: (likeCount: number, liked: boolean) => void;
    newsId: string;
    replyId?: string;
    className?: string;
    buttonProps?: { [s: string]: string | boolean };
}