import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { RouteComponentProps } from 'react-router';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { addToAdditionalMenu, removeFromAdditionalMenu } from 'utils/src/CommonRedux/base/actions';
import actions from 'utils/src/CommonRedux/base/actionsTypes';
import {
  updateActivePage,
  setStructureFromWidget,
} from 'redux/actions/Widgets';
export interface IPagesNewProps extends IClassNameProps, IPagesNewState, IPagesNewStateProps, RouteComponentProps<{id:string}>, IPagesNewActionsToProps {
  tag?: 'div';
}

export interface IPagesNewState {
  text?: string;
  dt?: any;
  editShow: boolean;
}

export type IPagesNewStateProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: any) => ({
  authUser: getAuthUser(state),
  getLoadedPage: (name: string) => state.widgets.pages[state.widgets.structures[name]],
  isAdmin: getAuthUser(state).baseData.isAdmin,

  // currentUser: getAuthUser(state).baseData,
})

export const PagesNewActionsToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  addToAdditionalMenu,
  removeFromAdditionalMenu,
  updateActivePage,
  changeVm: (propPath: string, value: any) => ({ type: actions.CHANGE_STATE, payload: { propPath, value }})
}, dispatch);

export type IPagesNewActionsToProps = ReturnType<typeof PagesNewActionsToProps>

export const cnPagesNew = cn('PagesNew');