import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    SkillsListNew
} from './SkillsList.interface'
import i18n from 'localizations/i18n'
import SkillsListItem from './Item/SkillsListItem'
import { Box } from 'muicomponents/src/Box/Box'
import CardMedia from '@mui/material/CardMedia';
import EmptyWidget from 'assets/svg/EmptyWidget.svg';

type IPresenterProps = SkillsListNew.IPresenterProps

export const SkillList: FC<IPresenterProps> = ({
    className,
    skills,
    confirmSkill,
    userid,
    deleteSkill,
    showControls,
    setShow,
    isFinished,
    isLoading,
    create
}) => {
    return <Box className={cnClassName({}, [className])}>

        <Box>
            {(!isLoading && !skills.length) ?
                <div className={cnClassName('EmptyList')}>
                    <CardMedia
                        component="svg"
                        className={cnClassName("EmptyList-Avatar")}
                        sx={{
                            width: '100%',
                            height: "270px",
                            marginBottom: "12px"
                        }}
                        image={EmptyWidget}
                    />
                    {i18n.t("pryaniky.skills.placeholder.empty.title.1")}
                </div> :
                skills.map((skill, i) => <SkillsListItem
                    setShow={setShow}
                    showControls={showControls}
                    deleteSkill={deleteSkill}
                    key={skill.tag.id}
                    index={i + 1}
                    skill={skill}
                    confirmSkill={confirmSkill}
                    userid={userid}
                />
                )
            }
        </Box>
    </Box>

}

export default memo(SkillList);
