import { cn } from '@bem-react/classname';
import { IStateType as IState } from 'redux/store';
import { IUserBirthday } from 'utils/src/requests/models/api.users';
import { IStoreAppSetting } from 'models/store/appSettings';
import { IThanksReason } from 'utils/src/requests/models/api.system';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface IWidgetTypeBirthdaysProps extends ReturnType<typeof mapStateToProps> {
  contexts?: string[];
  tag?: React.ComponentType | 'div';
  className?: string;
  settings: {
    text:string
    usersCount: number;
    banner: IObject;
    showImg: boolean;
    hideIfNoData:boolean
  },
}
export interface IObject {
  aspect: number,
  elements: IImageObject[]
}
export interface IImageObject {
  imgId: string,
  imgUrl: string,
  href: string,
  imgName: string,

}

export const mapStateToProps = (state: IState) => ({
  currencyNames: state.store.appSettings as IStoreAppSetting,
  enabledThanks: state.store.appSettings && state.store.appSettings.enabledModules.thanks,
  currentUser: getAuthUser(state)
});

export const cnBirthdaysWidget = cn('BirthdayWidget');
