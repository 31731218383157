import { Box } from 'muicomponents/src/Box';
import { styled } from 'muicomponents/src/mui/system';

export const PageBox = styled('div')({
    margin: '24px 2%'
});

export const PageMenuBox = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
});