import React, { FC } from 'react'
import { Skeleton } from 'uielements/src'
import './TestInfo.scss'
import { cn } from '@bem-react/classname';
export const cnTestInfo = cn('SkeletTestInfo');

export const TestInfo: FC<any> = ({ className }) => {
    return <div className={cnTestInfo({}, [className])}>
        <div className="TestInfo">
            <h4 className="TestInfo-Title">первый</h4>
            <div className="TestInfo-Description">второй</div>
            <div className="TestInfo-PropertiesBox">
                <ul className="TestInfo-Properties">
                    <li className="TestInfo-PropertiesItem">
                        <div className="TestInfo-PropertiesContent">
                            <div className="TestInfo-PropertiesTitle"><Skeleton shape="line" size={"sm"} style={{ width: '200px' }} /></div>
                            <div className="TestInfo-PropertiesData"><Skeleton shape="line" size={"sm"} style={{ width: '100px' }} /></div>
                        </div>
                    </li>
                    <li className="TestInfo-PropertiesItem">
                        <div className="TestInfo-PropertiesContent">
                            <div className="TestInfo-PropertiesTitle"><Skeleton shape="line" size={"sm"} style={{ width: '200px' }} /></div>
                            <div className="TestInfo-PropertiesData"><Skeleton shape="line" size={"sm"} style={{ width: '100px' }} /></div>
                        </div>
                    </li>
                    <li className="TestInfo-PropertiesItem">
                        <div className="TestInfo-PropertiesContent">
                            <div className="TestInfo-PropertiesTitle"><Skeleton shape="line" size={"sm"} style={{ width: '200px' }} /></div>
                            <div className="TestInfo-PropertiesData"><Skeleton shape="line" size={"sm"} style={{ width: '100px' }} /></div>
                        </div>
                    </li>
                    <li className="TestInfo-PropertiesItem">
                        <div className="TestInfo-PropertiesContent">
                            <div className="TestInfo-PropertiesTitle"><Skeleton shape="line" size={"sm"} style={{ width: '200px' }} /></div>
                            <div className="TestInfo-PropertiesData"><Skeleton shape="line" size={"sm"} style={{ width: '100px' }} /></div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="TestInfo-Actions">
                <button className="Button Button_border Button_background Button_main Button_padding_sm Button_borderRadius TestInfo-ActionsBtn TestInfo-ActionsBtn_type_start">Начать тест</button>
                <button className="Button Button_theme_unstyled_center TestInfo-ActionsBtn TestInfo-ActionsBtn_type_skip">Пропустить</button>
            </div>
        </div>
    </div>
}

