import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDSStoreByName } from "redux/dataStorage/selectors";
import { useDidUpdateEffect } from "utils/src/hooks";
import { NLeftMenuReducer } from "../redux/interfaces";
import { getLeftMenuReducerField } from "../redux/selectors";
import i18n from 'localizations/i18n';
import { getCurrentUser } from "utils/src/CommonRedux/base/selectors";
import { intersection } from 'lodash';
import { leftTickLabelProps } from "@vx/axis/lib/axis/AxisLeft";
import { NonNullableParams, TValue } from "utils/src";
import { getParentChain } from "../LeftMenu.utils";
import { leftMenuSetReducerField } from "../redux/actions";
import { mainBarId } from "../redux/constants";

type SearchedDataItem = {
  breadcrumds: string[];
  items: string[];
}

export const useLeftMenuSearch = () => {

  const dispatch = useDispatch();

  const bars = useSelector(getLeftMenuReducerField('bars'));

  const [ loading, setLoading ] = useState(false);

  const [ search, setSearch ] = useState('');

  const [ searchedData, setSearchedData ] = useState<SearchedDataItem[]>([]);

  const barsItems = useSelector(getLeftMenuReducerField('barsItems'));
  const items = useSelector(getDSStoreByName('leftMenu'));
  const authUser = useSelector(getCurrentUser);

  useDidUpdateEffect(() => {
    setLoading(true);
    if(bars.length > 1) dispatch(leftMenuSetReducerField({ field: 'bars', value: [mainBarId] }));

    if(!search) {
      setSearchedData([]);
      setLoading(false);
      return ;
    };

    const getItemTitle = (id: NonNullable<TValue<typeof items>>['id']) => {
      const item = items[id];
      if(!item) return '';
      const title: string = item.blockType === 'user' ? authUser?.baseData?.displayName :
        item.url === '/shop' ? item.title :
        i18n.t(`pryaniky.custom.menu.item.${item.id}`, { defaultValue: item.title });
      return title;
    }

    const findedItems = Object.typedKeys(items).filter(id => {
      const title: string = getItemTitle(id.toString());
      const item = items[id];
      if(!item) return null;
      // get parrent items chain
      const parentsIds = getParentChain(items as NonNullableParams<typeof items>, item);
      // if one parrent is hidden then return true
      const parentsIsHidden = parentsIds.reduce((a, id) => a || items[id]?.isHidden || false, false);
      return !parentsIsHidden && item.renderType === 'link' && !item.isHidden && title && title.toLowerCase().includes(search.toLowerCase());
    }).map(el => el.toString());

    const barsByFindedItems = Object.typedKeys(barsItems).reduce((a, barId) => {
      const intersected = intersection(barsItems[barId], findedItems);
      if(!intersected.length) return a;
      return {
        ...a,
        [barId]: intersected
      };
    }, {} as {[s: string]: typeof findedItems});

    const barsBreadcrumbs = Object.typedKeys(barsByFindedItems).reduce((a, barId) => {
      if(barId === mainBarId) return { ...a, [barId]: [] };
      const title: string = getItemTitle(barId.toString());
      let path: string[] = [title];
      let parent = items[barId]?.parentId || '00000000-0000-0000-0000-000000000000';
      while(parent !== '00000000-0000-0000-0000-000000000000') {
        const title: string = getItemTitle(parent);
        const item = items[parent];
        path = [ title, ...path ];
        parent = item?.parentId || '00000000-0000-0000-0000-000000000000';
      }
      return {
        ...a,
        [barId]: path
      }
    }, {} as { [barId: string]: string[] });

    const findedWithPath = Object.typedKeys(barsBreadcrumbs).map(barId => {
      return {
        breadcrumds: barsBreadcrumbs[barId],
        items: barsByFindedItems[barId]
      }
    });

    setSearchedData(findedWithPath);

    setLoading(false);
  }, [search]);

  return {
    loading,
    search,
    setSearch,
    searchedData
  }
};