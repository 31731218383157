/**
 * @packageDocumentation
 * @module Widget_Settings_timeline
 */
export default {
  showFilter: false,
  mainTimeline: false,
  groupsTimeline: false,
  usersTimeline: false,
  tagsTimeline: false,
  selectedUsers: [],
  selectedTags: [],
  selectedGroups: [],
  selectedNews: ''
}