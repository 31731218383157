import BaseRequests from 'utils/src/requests/requests.base';
import { pathToFilesIcons } from 'utils/src/constants.prn';

export const profileIconsMap: string[] = [
    '3ds', '3gp', 'aac', 'ai', 'apk', 'asf', 'avi', 'bat', 'bmp', 'cab', 'cad', 'cdr', 'cpp', 'dat', 'dll',
    'dmp', 'doc', 'docx', 'esp', 'exe', 'file', 'fla', 'flv', 'gif', 'html', 'ico', 'id', 'iff', 'indd',
    'iso', 'jpg', 'midi', 'mov', 'mp3', 'mp4', 'mpg', 'obj', 'otf', 'pdf', 'php', 'png', 'ppt', 'pptx',
    'rar', 'raw', 'rss', 'sql', 'svg', 'tex', 'tif', 'tiff', 'ttf', 'txt', 'url', 'wav', 'xd', 'xlr', 'xls',
    'xlsx', 'xml', 'xxl', 'zip'
]

export const getProfileFileIcon = (type: string = '') => profileIconsMap.find((el) => el === type) ?
    BaseRequests.getUrl(`${pathToFilesIcons}/${type}.png`) :
    BaseRequests.getUrl(`${pathToFilesIcons}/file.png`)