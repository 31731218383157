import { Box, styled } from 'muicomponents/src';
import React, { FC } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const Layout = styled(Box)({
    padding: '16px',
    marginBottom: '16px',
}) as typeof Box


const AvatarBlockSkeleton:FC = () => {
    return <Box>
        <Layout height={324}>
            <Skeleton variant="rectangular" height={'100%'} />
        </Layout>
        <Layout>
            <Stack spacing={1}>
                <Skeleton variant="text" height={42} />
                <Skeleton variant="text" height={42} />
                <Skeleton variant="text" height={42} />
                <Skeleton variant="text" height={24} />
                <Skeleton variant="text" height={24} />
                <Skeleton variant="text" height={24} />
                <Skeleton variant="text" height={24} />
            </Stack>
        </Layout>
    </Box>
};

export default AvatarBlockSkeleton;


