import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IStoreAppSetting } from 'models/store/appSettings';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { bindActionCreators, Action, Dispatch } from 'redux';
import { getAvailableQuests } from 'redux/quests/actions';

export interface IQuestsWidget extends IClassNameProps,IQuestsWidgetOwnProps, IMapStateToProps, IMapDispatchToProps {
  tag?: React.ComponentType<any> | string;
  contexts?: string[];
  widgetId?: string;
}
export interface IQuestsWidgetOwnProps {
  settings: any
}

export const mapStateToProps = (state: any) => ({
  edit: state.store.edit,
  uid: getAuthUser(state).baseData.id as string,
  data: state.quests.availableQuests,
  // questsIds: Object.keys(state.quests.availableQuests || {}), // FIXME <-- ай-ай! Нужно убрать от сюда или использовать кэширующий селектор, так как, 
  // новый масив создаётся при любом изменение в redux(то есть, на ленте, например, во время скролла) и компонент вызывается и вычесляет всё, 
  // что в нем есть на каждом таком вызове
});

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  getAvailableQuests
}, dispatch);


type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type IMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export const cnQuestsWidget = cn('QuestsWidget');