import React, { FC, memo, useMemo } from 'react'
import {
    cnClassName,
    ITestInfoLayoutProps
} from './interfaces'
import './style.scss'
import { Button } from 'uielements/src'
import { withLoad } from '../ButtonWithLoad/ButtonWithLoad'
import { tS } from 'LMSModule/utils/i18n.adaptor'

const ButtonL = withLoad(Button)

export const Presenter: FC<ITestInfoLayoutProps> = ({
    className,
    children,
    name,
    description,
    fullscreen,
    start,
    allowSkip,
    skip,
    unitType = 'test'
}) => {


    return <div className={cnClassName({ fullscreen })}>
        <h4 className={cnClassName('Title')}>{name}</h4>
        <div className={cnClassName('Description')}>{description}</div>

        <div className={cnClassName('PropertiesBox')}>
            {children}
        </div>

        <div className={cnClassName('Actions')}>
            <ButtonL className={cnClassName('ActionsBtn', { type: 'start' })} main onClick={start}>{unitType === 'test' ? tS('start.3') : tS('start.task.3')}</ButtonL>
            {allowSkip && <ButtonL
                className={cnClassName('ActionsBtn', { type: 'skip' })}
                onClick={skip}
                theme={'unstyled_center'}>{tS('skip.3')}</ButtonL>}
        </div>

    </div>
}

export default memo(Presenter);
