import {
    getTestCount,
    getFullAnswersCount,
    getNotModerateTextAnswersCount,
    getSuccessTestsCount,
    getWaitTestCount,
    getAdditionalTests,
    getScormCount,
    getSuccessScormCount,
    getNotModerateTextOnlyUnitsCount
} from '../result.utils'

export const useSessionResult = (units: any, unitResults: any[]) => {
    let testsCount = getTestCount(units)

    const scormCount = getScormCount(units)

    let taskCount = testsCount + scormCount

    const waitTestCount = getWaitTestCount(unitResults)

    const successTestCount = getSuccessTestsCount(unitResults, units)

    const successScormCount = getSuccessScormCount(unitResults)

    const successTaskCount = successTestCount + successScormCount

    const additionalTests = getAdditionalTests(units, unitResults)

    const successAdditionalTestsCount = getSuccessTestsCount(additionalTests, units)

    const waitAdditionalTestCount = getWaitTestCount(additionalTests)

    const notModerateAnswersCount = getNotModerateTextAnswersCount(unitResults)

    const fullAnswersCount = getFullAnswersCount(unitResults)

    const textOnlyUnitsCount = getNotModerateTextOnlyUnitsCount(unitResults)

    taskCount = fullAnswersCount === notModerateAnswersCount ? (fullAnswersCount === 0 ? scormCount : 0) : taskCount

    const failureTaskCount = taskCount - waitTestCount - successTaskCount + (successAdditionalTestsCount - additionalTests.length + waitAdditionalTestCount) - textOnlyUnitsCount

    return {
        taskCount,
        successTaskCount,
        failureTaskCount,
        waitTestCount
    }
}