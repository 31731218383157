import React from 'react'
import { cnSliderLink, ISliderLinkItemProps } from './SliderLinkItem.index'
import { Button, Icon } from 'uielements/src';
import './SliderLinkItem.scss'

const linkTypesIndexOfUrl = [
  'http',
  '//'
]

export const SliderLinkItem: React.FC<ISliderLinkItemProps> = props => {

  const addImage = (e: any) => e.preventDefault()
  
  const { idx, imageNum, imgUrl, length, edit, className, active, type, href, openInNewTab, ...imgProps } = props
  return (
    <Button
      className={cnSliderLink('Image', {
        slide: idx === imageNum || idx + 1 === imageNum ? true : imageNum === 0 && idx === length - 1 ? true : false,
        edit,
        href: !!href
      }, [className])}
      style={{
        // backgroundImage: `url("${img.imgUrl}")`,
        left: `${!(idx === imageNum || idx + 1 === imageNum) ?
          (imageNum === 0 && idx === length - 1) ?
            length === 2 ? 100 : -100 :
            100 :
          (idx - imageNum) * 100}%`,
        zIndex: idx === imageNum ? 10 : 1
      }}
      theme='unstyled'
      noBorder
      type={type}
      href={href ? href : undefined}
      target={openInNewTab ? '_blank' : '_self'}
    >
      <img
        src={imgUrl}
        className={cnSliderLink('Image')}

        {...imgProps}
      />
    </Button>

  )

}