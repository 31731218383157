// import Component, { Presenter } from './component';
// import {
//     IOwnProps,
//     IPresenterProps,
//     cnClassName
// } from './interfaces';

import Component, { Presenter } from 'uielements/src/MaterialElements/DeleteButton/component'
import {
    IOwnProps,
    IPresenterProps,
    cnClassName
} from 'uielements/src/MaterialElements/DeleteButton/interfaces';

export {
    cnClassName,
    Presenter,
}

export type IComponentProps = IOwnProps;
export type IComponentPresenterProps = IPresenterProps;


export default Component