import React, { FC, useState, useRef } from 'react';
import { IACInputProps } from './AutoCompliteInput.index'
import './AutoCompliteInput.css'
import { InputLayout } from '../InputLayout/InputLayout'
import { LineInput } from '../LineInput/LineInput'
import { Saggester } from '../Saggester/Saggester'
import { renderItem } from '../renderers'
import { filterSearch } from '../filters'


export const AutoCompliteInput: FC<IACInputProps & any> = ({ onChange, value, items = renderItem, suggestions = [], ...props }) => {
    const [open, setOpen] = useState(false)
    const [focused, setFocused] = useState(false);
    const inputEl = useRef<HTMLInputElement>(null);

    const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value)
        setOpen(true)
    }

    const onSelectValue = (item: any) => {
        focusInput()
        onChange(item.content)
        setOpen(false)
    }

    const focusInput = () => inputEl.current && inputEl.current.focus();

    const filtredSuggestions = filterSearch(suggestions, value);
    return <div>
        <InputLayout
            // action={}
            focus={focused}
            icon={'icon'}
            suggester={open && <Saggester clickOutside={() => setOpen(false)} onSelect={onSelectValue} className={'Select-Suggester'}>
                {(onClick: (item: any) => () => void) => items(filtredSuggestions, onClick)}
            </Saggester>}
        >
            <LineInput
                ref={inputEl}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onChange={onChangeValue}
                value={value} />
        </InputLayout>
    </div>
}