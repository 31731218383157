import { Translate } from '../../../localizations/Translate';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'uielements/src';
import { cnWikiViewTypeSelector, IWikiViewTypeSelectorProps, mapWikiViewTypeSelectorStateToProps, mapWikiViewTypeSelectorActionsToProps } from './WikiViewTypeSelector.index';
import './WikiViewTypeSelector.scss'
const WikiViewTypeSelectorPresenter: React.FC<IWikiViewTypeSelectorProps> = ({ viewType }) => {

    const [wikiViewType, setWikiViewType] = React.useState('short')
    // React.useEffect(() => {
        // changeField({
        //     field: 'viewType',
        //     value: 'short'
        // })
        // return () => {
        //     changeField({
        //         field: 'viewType',
        //         value: 'full'
        //     })
        // }
    // }, []);

    return (
        <div className={cnWikiViewTypeSelector()}>
            <h4><Translate i18nKey={'pryaniky.news.view.type.title'} /></h4>
            <div>
                <Button main={viewType === 'full'} onClick={() => setWikiViewType('short')}>
                    <Icon icon={'wiki'}></Icon>
                </Button>
                <Button main={viewType === 'short'} onClick={() => setWikiViewType('full')}>
                    <Icon icon={'bars'}></Icon>
                </Button>
            </div>
        </div>
    )
}

export const WikiViewTypeSelector = connect(
    mapWikiViewTypeSelectorStateToProps,
    mapWikiViewTypeSelectorActionsToProps
)(WikiViewTypeSelectorPresenter)