import { NNewsCreateActions } from './actions.types.newsCreator';
import * as newsCreatorActions from './actionsTypes.newsCreator';

/**
 * init news create form
 * @param payload 
 * @returns 
 */
export const newsCreatorInit: NNewsCreateActions.ActionFunction<'NEWS_CREATOR_INIT'> = (payload) => ({
    type: newsCreatorActions.NEWS_CREATOR_INIT,
    payload
});

/**
 * update news create item model
 * @param payload 
 * @returns 
 */
export const newsCreatorItemChange: NNewsCreateActions.ActionFunction<'NEWS_CREATOR_ITEM_CHANGE'> = (payload) => ({
    type: newsCreatorActions.NEWS_CREATOR_ITEM_CHANGE,
    payload
});

/**
 * update news model to post
 * @param payload 
 * @returns 
 */
export const newsCreatorItemNewsChange: NNewsCreateActions.ActionFunction<'NEWS_CREATOR_ITEM_NEWS_CHANGE'> = (payload) => ({
    type: newsCreatorActions.NEWS_CREATOR_ITEM_NEWS_CHANGE,
    payload
});

/**
 * post filled news
 * @param payload 
 * @returns 
 */
export const newsCreatorPostNews: NNewsCreateActions.ActionFunction<'NEWS_CREATOR_POST_NEWS'> = (payload) => ({
    type: newsCreatorActions.NEWS_CREATOR_POST_NEWS,
    payload
});

/**
 * validate news object
 * @param payload 
 * @returns 
 */
export const newsCreatorValidateNews: NNewsCreateActions.ActionFunction<'NEWS_CREATOR_VALIDATE_NEWS'> = (payload) => ({
    type: newsCreatorActions.NEWS_CREATOR_VALIDATE_NEWS,
    payload
});