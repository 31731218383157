import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import i18n from '../localizations/i18n';

interface IUsers {
  users: string;
  filters: string;
}

export const users = (): IWidget<IColumn[]> => {
  const ids = guids<IUsers>(['users', 'filters']);
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            id: ids.users,
            type: widgets.types.list + '/users',
          }),
        ],
        styles: {
          width: '72%',
        },
      }),
      generateColumn({
        items: [
          generateWidget({
            id: ids.filters,
            type: widgets.types.filters + '/users',
            relations: [
              // ids.users
            ],
            data: [
              // {
              //   name: i18n.t('pryaniky.filter.users.title'),
              //   elements: [
              //     {
              //       id: 't1',
              //       title: i18n.t('pryaniky.filter.users.noninitialized'),
              //       type: 'type',
              //       value: 'notinitialized',
              //     },
              //     {
              //       id: 't2',
              //       title: i18n.t('pryaniky.filter.users.initialized'),
              //       type: 'type',
              //       value: 'initialized',
              //     },
              //     {
              //       id: 't3',
              //       title: i18n.t('pryaniky.filter.users.admins'),
              //       type: 'type',
              //       value: 'administrators',
              //     },
              //   ],
              // },
              // {
              //   name: 'groups',
              //   elements: 'filterGroupsdata',
              //   props: {
              //     loadOndidMount: true,
              //     children: [
              //       {
              //         type: 'button',
              //         props: {
              //           noBackground: true,
              //           noBorder: true,
              //           noBorderRadius: true,
              //           onClick: this.loadFilterData,
              //           children: 'loadMore'
              //         }
              //       }
              //     ]
              //   }
              // }
            ],
          }),
        ],
        styles: {
          width: '28%',
        },
        mobileHidden: true,
      }),
    ],
  });
}