
import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import { API } from 'utils/src/utils'

const handleContextActionDownloadParticipantList = function* handleContextActionDownloadParticipantList({ payload }: AT.AContextAction) {
    const { action, id } = payload
    if (action === 'downloadParticipantList') {
        try {
            window.open(`${API.base.requestDomain}/ru/Data/v3/news/action/${id}/DownloadParticipants`, "_blank"); 

        } catch (error) {
            console.error(error)
        }
    }
    yield;

    return 0;
}

export default handleContextActionDownloadParticipantList