import Component, { Presenter } from './component';
import {
    Creativetasks,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = Creativetasks.CountSlider.IOwnProps;
export type IComponentPresenterProps = Creativetasks.CountSlider.IPresenterProps;


export default Component