import { AdditionalFieldsValue } from '../IdeaType'
import { cn } from '@bem-react/classname';
import { IBodyProps } from 'utils/src/DialogCreator'; 

export const cnIdeasEvalution = cn('IdeasEvalution');

export interface IIdeaEvaluationtProps extends IBodyProps {
    ideaId: string;
    statusID: string;
    anchor: React.RefObject<HTMLElement>;
    onComplite: (experts: AdditionalFieldsValue[]) => void;
}