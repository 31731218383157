import React, { useState } from 'react';
import { cnQuestItem, IQuestItemProps, mapStateToProps, mapDispatchToProps } from './QuestItem.index';
import { Translate } from 'localizations/Translate';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { getFormattedCurrencyName } from 'utils.project/utils.project';
import { connect } from 'react-redux';
import './QuestItem.scss';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import IconMUI from '@material-ui/core/Icon';
import { Icon, Button, ProgressBar } from 'uielements/src';
import PurseImg from 'assets/svg/purse.svg';
import { Avatar } from 'muicomponents/src/Avatar/Avatar'
import { LinearProgress } from 'muicomponents/src/LinearProgress/LinearProgress'
import { GoalItem } from '../GoalItem/GoalItem';

const QuestItemPresenter: React.FC<IQuestItemProps> = ({
  data,
  standart,
  currencyFormats,
  listId,
}) => {

  const allCount: number = data.goals?.reduce((acc: number, goal) => (acc += goal.bonus || 1), 0);
  const completeCount: number = data.goals?.reduce((acc: number, goal) => (goal.isCompleteByMe ? acc += goal.bonus || 1 : acc), 0);
  const type = standart ? 'standart' : '';


  return (
    <>
      {(standart && data.isCompleteByMe) && <CheckCircleOutlineIcon style={{ width: '40px', height: '40px' }} className={cnQuestItem('Done')} />}
      <div className={cnQuestItem('', [type])}>

        <div className={cnQuestItem('Details', [type])}>

          <div className={cnQuestItem('Details-Header', [type])}>
            {/* <Avatar   sx={{ width: 40, height: 40 }} href={data.iconUrl} /> */}
            {/* <Avatar sx={{ width: standart ? "56px" : "40px" }} src={data.iconUrl} /> */}
            <Avatar sx={{ width: "40px" }} src={data.iconUrl} />
            <h3 className={cnQuestItem('Details-Title', [type])} title={data.name}>{data.name}</h3>
          </div>

          <div className={cnQuestItem('Progress', [type])}>

            <div className={cnQuestItem('Progress-Info', [type])}>
              <b className={cnQuestItem('Progress-Info-Text')}><Translate i18nKey='pryaniky.widgets.quests.bonusCount' /></b> {completeCount}
              <span>{`/${allCount}`}</span>
            </div>
            <LinearProgress
              variant="determinate" value={(completeCount / allCount) * 100}
              className={cnQuestItem('Progress-Line', { standart: !!type })}
              sx={{
                borderRadius: "8px",
                height: "12px",
                backgroundColor: "#4caf505c",
                ".MuiLinearProgress-bar":{
                  backgroundColor: "#4CAF50",
                  borderRadius: "8px",


                }
              }} />
            {/* <ProgressBar
              percent={(completeCount / allCount) * 100}
              className={cnQuestItem('Progress-Line', { standart: !!type })}
              strokeWidth={standart ? 24 : undefined}
            /> */}
          </div>

        </div>
        <div className={cnQuestItem('Goals', [type])}>
          {data.goals &&
            data.goals.map(goal => <GoalItem data={goal} type={type} questId={data.id} key={data.id} listId={listId} />)}
        </div>

        {(data.bonusCurrency > 0 || data.rewards.badge?.isActive) &&
          <div className={cnQuestItem('Rewards', [type])}>
            <h4>
              {Translate.t({ i18nKey: 'pryaniky.widgets.quests.reward' })}
            </h4>
            <div className={cnQuestItem('Rewards-Images', [type])}>
              {data.bonusCurrency > 0 &&
                <div className={cnQuestItem('Rewards-Item', ['currency', type])}>

                  <div className={cnQuestItem('Rewards-Item-Icon')}>

                    <AccountBalanceWalletIcon color={"action"} />

                  </div>
                  {/* <Avatar size={standart ? 52 : 28} imgUrl={PurseImg} /> */}
                  <span className={cnQuestItem('Rewards-Currency', [type])}>{`+${data.bonusCurrency}`}</span>
                </div>
              }

              {data.rewards.badge?.isActive &&
                <div>
                  {/* <Avatar size={standart ? 56 : 40} imgUrl={data.rewards?.badge?.iconUrl || undefined} /> */}
                  <Avatar sx={{ width: "40px" }} src={data.rewards?.badge?.iconUrl || undefined} />
                </div>
              }
            </div>

            <div className={cnQuestItem('Rewards-Info', [type])}>


              {data.bonusCurrency > 0 &&
                <div>
                  <span>+{data.bonusCurrency}</span>&nbsp;
                  {getFormattedCurrencyName(currencyFormats, data.bonusCurrency, 'nominative')}
                </div>}

              {data.rewards.badge && data.rewards.badge.badgeUid !== GUID_EMPTY &&
                <div>
                  <span>+1</span>
                  {Translate.t({ i18nKey: 'pryaniky.widgets.quests.badge' })}&nbsp;
                  <Button href={`/badge/${data.rewards.badge.badgeUid}`} type='rlink' theme='unstyled'>&laquo;{data.rewards.badge.displayName}&raquo;</Button>
                </div>
              }

            </div>
          </div>
        }

      </div>

    </>
  )
}

export const QuestItem = connect(mapStateToProps, mapDispatchToProps)(QuestItemPresenter);
