import { IBaseNews, createDefaultNews } from 'News/types/baseNews'

export interface CreativetasksendNews extends IBaseNews {
    newstype: 13;
}



export const defaultCreativetasksendNews: CreativetasksendNews = {
    ...createDefaultNews(),
    newstype: 13,
}

