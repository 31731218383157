import { mdToRaw, rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { NodeBulder } from 'uielements/src/CommonmarkRender'

/**
 * преобразует вики юнит для отправки на сервер
 * @param unit 
 */
export const convertDescriptionToMd = (description: EditorState): string => {
    const draftRaw = convertToRaw(description.getCurrentContent())
    //конвертируем рав в мапкдаун
    const markdownText = draftToMarkdown(draftRaw, rawToMd);

    return markdownText
}

/**
 * преобразует вики юнит для отправки на сервер
 * @param unit 
 */
export const convertDescriptionToDraft = (description: string): EditorState => {
    const NB = new NodeBulder(description || '')
    let editorState = EditorState.createWithContent(convertFromRaw(NB.getDraftRaw()));

    return editorState;
}


/**
 * корректно изменяет статус пользователя в группе: заявка отправлена/отменить заявку/покинуть/вступить
 * @param visibilityType 
 * @param isRequestAccessSent 
 * @param isMeInGroup 
 * @param isAdmin 
 */
export const changeMeInGroup = (visibilityType: number, isRequestAccessSent: boolean, isMeInGroup: boolean, isAdmin: boolean) => {
    const $return = {
        isRequestAccessSent,
        isMeInGroup,
        join: !isMeInGroup
    }
    if (!isAdmin && visibilityType === 1 && !isMeInGroup) {
        $return.isRequestAccessSent = !isRequestAccessSent;
        $return.join = $return.isRequestAccessSent;
    } else {
        $return.isMeInGroup = !isMeInGroup;
        $return.join = $return.isMeInGroup;
    }

    return $return;
}

/**
 * статусы участия в группе
 */
export enum EStatusInGroup {
    /**
     * в группе
     */
    in = 0,
    /**
     * заявка отправленна
     */
    requestSent = 1,
    /**
     * можно отправить заявку
     */
    requestAllow = 2,
    /**
     * не в группе
     */
    out = 3
}

export type TStatusParams = { visibilityType: number, isRequestAccessSent: boolean, isMeInGroup: boolean }
/**
 * определяет статус пользователя в группе
 * @param param0 
 * @param isAdmin 
 */
export const getStatusInGroup = ({ visibilityType, isRequestAccessSent, isMeInGroup }: TStatusParams, isAdmin: boolean): EStatusInGroup => {
    let status = EStatusInGroup.out
    if (!isAdmin && visibilityType === 1) status = isRequestAccessSent ? EStatusInGroup.requestSent : EStatusInGroup.requestAllow
    if (isMeInGroup) status = EStatusInGroup.in
    return status
}

/**
 * тексты для кнопки статуса участия в группу
 */
export const GroupActionsTexts = {
    /**
     * уже в группе - выйти
     */
    [EStatusInGroup.in]: 'pryaniky.group.actions.leave',
    /**
     * запрос отправлен - отменить запрос
     */
    [EStatusInGroup.requestSent]: 'pryaniky.group.actions.requestSent',
    /**
     * запрос ещё не отпрвлен - отправить запрос
     */
    [EStatusInGroup.requestAllow]: 'pryaniky.group.actions.sendRequest',
    /**
     * не в группе - вступить
     */
    [EStatusInGroup.out]: 'pryaniky.group.actions.join',
}

/**
 * возврощает i18n строку для кнопки смены статуса участия в группе
 * @param statusParams 
 * @param isAdmin 
 */
export const getGroupActionText = (statusParams: TStatusParams, isAdmin: boolean): string =>
    GroupActionsTexts[getStatusInGroup(statusParams, isAdmin)]