import React, { FC } from 'react'
import {
    LMSTO,
    mapDispatchToProps,
    mapStateToProps,
    cnClassName
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { Selectize } from 'uielements/src';
import { SearchSelectize } from 'uielements/src';
import withCourseSuggester from 'blocks/HOCs/SearchSelectize/withCourseSuggester'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

const CourseSelectize = withCourseSuggester(SearchSelectize)

type IPresenterProps = LMSTO.Moderation.CoursesFilter.IPresenterProps
type IStateProps = LMSTO.Moderation.CoursesFilter.IStateProps
type IDispatchProps = LMSTO.Moderation.CoursesFilter.IDispatchProps
type IOwnProps = LMSTO.Moderation.CoursesFilter.IOwnProps
type IState = LMSTO.Moderation.CoursesFilter.IState


export const Presenter: FC<IPresenterProps> = ({ className, currentData = [], setData }) => {

    const elements: any[] = []
    return <div className={cnClassName({}, [className])}>
        <CourseSelectize
            onClick={(v) => setData(v.map(v => v.id))}
            defaultElements={elements}
            name={tT('moderation.filters.course.1')}
            cut={10}
        // defaultElements={elements}
        />
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
