import Component, { Presenter } from './component';
import {
    IDialogActionsOwnProps,
    IDialogActionsPresenterProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = IDialogActionsOwnProps;
export type IComponentPresenterProps = IDialogActionsPresenterProps;


export default Component