import { IClassNameProps } from '@bem-react/core';
import { cnModal } from '../Modal.index';

export interface IFooterProps extends IClassNameProps {
  border: boolean;
  dataIsValid: boolean;
  okBtnText?: string; // text on "OK" button
  cancelBtnText?: string; // text on "cancel" button
  okBtnAction(ev: Event): void; // function on "OK" button click
  cancelBtnAction(ev: Event): void; // function on "cancel" button click
}

export interface IFooterState {
  text?: string;
}

export const cnFooter = (...data: any) => {
  let name = 'Footer';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnModal(name, ...data)
}