import React, { FC, useMemo } from 'react';
import { useWallet, useWallets } from './Wallets.hooks';
import * as MuiIcons from 'muicomponents/src/Icons';
import { Button } from 'muicomponents/src/Button/Button';
import { AccountBalanceWalletOutlined } from 'muicomponents/src/Icons';
import { ImageIcon } from 'muicomponents/src/Icons/ImageIcon';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { styled } from 'muicomponents/src/mui/system';
import { cloneDeep } from 'lodash';
import { cn } from '@bem-react/classname';
import { AddCurrency, CloseDeposit } from './AddCurrency/AddCurrency';
import { Translate } from 'localizations/Translate';
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip'
import { useSelector } from 'react-redux';
import { getCurrencyNameByCount } from 'utils/src/CommonRedux/base/selectors';
import moment from 'moment';

const cnWallets = cn('Wallets');
const cnWallet = cn('Wallet');

const WalletsBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '1px'
});

const WalletBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: 8
});

const WalletInfoBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'initial'
});

const OpenDepositButton = styled(Button)({
    padding: '0 4px',
    textTransform: 'none',
    marginLeft: -4
});

const CloseDepositButton = styled(Button)({
    padding: '0 4px',
    textTransform: 'none',
    marginLeft: -4
});

const HelpTooltipBox = styled('div')({
    disaply: 'flex'
});

export const Wallet: FC<{
    /**
     * user id
     */
    uid: string;
    /**
     * wallet id to render
     */
    walletId: NonNullable<ReturnType<typeof useWallets>['wallets']>[number];
}> = ({
    uid,
    walletId
}) => {

    const {
        wallet,
        allowActions,
        mythanksCount,
        changeCurrencyAtWallet
    } = useWallet(uid, walletId);

    const icon = useMemo(() => {
        if(!wallet) return null;
        if(wallet.iconUrl) return <ImageIcon className={cnWallet('Icon')} url={wallet.iconUrl} />;
        const MuiIcon = MuiIcons[wallet.icon as keyof typeof MuiIcons];
        if(MuiIcon) return <MuiIcon className={cnWallet('Icon')} />;
        return <AccountBalanceWalletOutlined className={cnWallet('Icon')} />;
    }, [wallet?.icon, wallet?.iconUrl]);

    const value = useMemo(() => {
        if(!allowActions) {
            return (
                <div className={cnWallet('Value')}>{wallet?.value}</div>
            )
        };
        switch(wallet?.type) {
            case 'deposit': {
                if(wallet.value === 0) {
                    return (
                        <Tooltip
                            title={!wallet.isActive ? <Translate i18nKey={'pryaniky.wallets.deposit.open.disabled'} /> : ''}
                        >
                            <OpenDepositButton
                                sx={{
                                    pointerEvents: `auto${!wallet.isActive ? ' !important' : ''}` 
                                }}
                                component={!wallet.isActive ? 'div' : undefined}
                                className={'OpenDeposit'}
                                variant={"contained"}
                                size={'small'}
                                disabled={mythanksCount === 0 || !wallet.isActive}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if(!wallet.isActive) return;
                                    AddCurrency({ uid, walletId })
                                }}
                            >
                                <Translate i18nKey={'pryaniky.wallets.deposit.open'} />
                            </OpenDepositButton>
                        </Tooltip>
                    )
                } else {
                    return (
                        <>
                            <div className={cnWallet('Value')}>{wallet.value}</div>
                            <CloseDepositButton
                                size={'small'}
                                className={'CloseDeposit'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    CloseDeposit({ uid, walletId });
                                }}
                            >
                                <Translate i18nKey={'pryaniky.wallets.deposit.close'} />
                            </CloseDepositButton>
                        </>
                    )
                }
            };
            case 'external': {
                return (
                    <div className={cnWallet('Value')}>{wallet.value}</div>
                )
            };
            default: {
                return (
                    <div onClick={(e) => {
                        e.stopPropagation();
                        AddCurrency({ uid, walletId });
                    }} className={cnWallet('Value')}>{wallet?.value}</div>
                )
            };
        };
    }, [wallet?.type, wallet?.value, allowActions]);

    const helpTooltip = useMemo(() => {
        switch(wallet?.type) {
            case 'deposit': {
                if(wallet.value !== 0) {
                    const date = moment(wallet.expires).format('L');
                    return (
                        <WalletBox>
                            {icon}
                            <WalletInfoBox className={cnWallet('InofoBox')}>
                                <Translate
                                    i18nKey={'pryaniky.wallets.deposit.info'}
                                    values={{
                                        walletName: wallet.name,
                                        percentage: wallet.percentage || '',
                                        date: date
                                    }}
                                    />
                            </WalletInfoBox>
                        </WalletBox>
                    )
                }
                return null
            };
            case 'external': {
                return (
                    <WalletBox>
                        {icon}
                        <WalletInfoBox className={cnWallet('InofoBox')}>
                            <div className={cnWallet('Name')}><b>{wallet.name}</b></div>
                            {wallet.value}
                        </WalletInfoBox>
                    </WalletBox>
                )
            };
            default: {
                return null;
            };
        };
    }, [wallet?.type, wallet?.value, wallet?.info, icon ]);

    if(!wallet) return null;

    return (
        <HelpTooltip
            help={
                helpTooltip
            }
        >
            <WalletBox
                className={cnWallet()}
                onClick={() => {
                    if(wallet?.actionUrl) window.open(wallet?.actionUrl, '_blank');
                }}
                sx={{
                    cursor: wallet?.actionUrl ? 'pointer' : undefined
                }}
            >
                {icon}
                <WalletInfoBox className={cnWallet('InofoBox')}>
                    <div className={cnWallet('Name')}><b>{wallet.name}</b></div>
                    {value}
                </WalletInfoBox>
            </WalletBox>
        </HelpTooltip>
    )
};

export const Wallets: FC<{
    /**
     * user id
     */
    uid: string
}> = ({
    uid
}) => {

    const {
        wallets
    } = useWallets(uid);

    if(!wallets) return null;

    return (
        <WalletsBox className={cnWallets()}>
        {
            wallets.map((walletId, idx) => {
                return (
                    <Wallet key={walletId} uid={uid} walletId={walletId} />
                )
            })
        }
        </WalletsBox>
    )
};