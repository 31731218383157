import * as React from 'react';
// import * as utils from 'utils/src/utils';
// import { withRouter } from "react-router"
import WidgetPresenter from '../Widget';
// import { withBemMod } from '@bem-react/core';
import { cnWidget, cnWidgetMui} from '../Widget.index';
import { IWidgetTypeCoursesMyProps } from './Widget_type_courses_my.index';
import './Widget_type_courses_my.scss';
import * as utils from 'utils/src/utils';
// import { CoursesMy } from '../../../LMSModule/LMS/Widgets/CoursesMy/CoursesMy'
import { CourseMyNew } from '../../../LMSModule/LMS/Widgets/CourseMyNew/CourseMyNew'
import { widgets } from 'i.widgets';
const type = 'coursesMy';
// const types = {
//   common: require('../../../LMSModule/LMS/Widgets/CoursesMy/CoursesMy').CoursesMy,
// }

class WidgetTypeCoursesMyPresenter extends WidgetPresenter<IWidgetTypeCoursesMyProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    const Tag = widgets.components.common
    return (
      <Tag {...this.props} className={cnWidgetMui({ type, subtype })}>
        <CourseMyNew settings={this.props.widget.settings} params={this.props.widget.data}/>
      </Tag>);
  }
}

export default WidgetTypeCoursesMyPresenter