import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IStateType } from 'redux/store';
import { cn } from '@bem-react/classname'
import { pathNews } from 'News/redux/actions'

import { selectCurrentStatus, selectUserStatuses, selectResponsibleForStatus, selectAllowchangeStatus } from '../redux/selectors';
import { getUserStatuses, changeStatus, getStatusFields, changeResponsible } from '../redux/actions';



export interface IActionsBoxProps extends IActionsBoxOwnProps, IMapStateToProps, IMapDispatchToProps {}

interface IActionsBoxOwnProps {
  newsId: string;
  workflowId: string;
}
 
export type IMapStateToProps = ReturnType<typeof mapStateToProps>;
export type IMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export const mapStateToProps = (state: IStateType, { newsId }: IActionsBoxOwnProps) => ({
  userData: getCurrentUser(state),
  currentStatus: selectCurrentStatus(newsId)(state),
  statuses: selectUserStatuses(newsId)(state),
  responsibleForStatus: selectResponsibleForStatus(newsId)(state),
  allowChangeStatus: selectAllowchangeStatus(newsId)(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  getUserStatuses,
  getStatusFields,
  changeStatus,
  pathNews,
  changeResponsible
}, dispatch)

export const className = cn('ActionsBox');
