import { IBodyProps } from 'utils/src/DialogCreator'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import {
    getNewsById
} from 'News/redux/saga/selectors'

export interface IRepostFormProps extends IBodyProps<any> {
    newsId: string
}

export type IStateProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: any, props: any) => ({
    currencyName: state.store.appSettings && state.store.appSettings.currencyNameAccusativeSingular,
    user: getCurrentUser(state),
    // news: getNewsById(props.newsId)(state)
});