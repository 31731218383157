import React, { FC } from 'react'
import { IQuestionListProps, cnQuestionList } from './QuestionList.index'
import './QuestionList.scss'
import { QuestionItem } from './Item/Item'

export const QuestionListPresenter: FC<IQuestionListProps> = ({
    className,
    normalQuestions,
    questionsTimers,
    questions,
    resultAnswersMap,
    resultAnswers,
    detailsRender,
    highlightCorrectness = false,
    attmptsAvailable,
    allowShow
}) => {

    return <div className={cnQuestionList({}, [className])}>
        {questions.map((qid: string) => {
            let show = normalQuestions[qid] && normalQuestions[qid].qtype === 2;
            show = show ? show : highlightCorrectness
            show = attmptsAvailable ? show : true
            show = allowShow ? true : show
            return (show && normalQuestions[qid]) ? <QuestionItem
                className={cnQuestionList('Item')}
                qid={qid}
                question={normalQuestions[qid]}
                questionTimer={questionsTimers && questionsTimers[qid]}
                text={normalQuestions[qid].header}
                qtype={normalQuestions[qid].qtype}
                isNeedModeration={normalQuestions[qid].isNeedModeration}
                answers={normalQuestions[qid].answers}
                answersMap={normalQuestions[qid].normalAnswers}
                resultAnswersMap={resultAnswersMap}
                detailsRender={detailsRender}
                resultAnswers={resultAnswers} /> : null
        })}
    </div>
}


export const QuestionList = QuestionListPresenter;