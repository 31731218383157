import { IReplyModel } from 'News/types/reply'

export interface User {
    id: string;
    displayName: string;
    eMail: string;
    imgId: string;
    sex: string;
    imgUrl: string;
    position: string;
    division: string;
    phone?: any;
    userPhotoUrl?: string;
    isDeleted?: boolean;
}

export const defaultUser: User = {
    "id": "",
    "displayName": "",
    "eMail": "",
    "imgId": "",
    "sex": "",
    "imgUrl": "",
    "position": "",
    "division": "",
    "phone": null
}
export interface ThanksInfoType {
    users: any[];
    thanksReasonIds: number[];
    thanksCount: number;
}

export interface ReplyType {
    id: string;
    type: string;
    text: any;
    header: string;
    liked: boolean;
    likeCount: number;
    date: Date;
    changeDate: Date;
    user: User;
    users?: User[];
    attachments?: any[];
    parentCommentId?: any;
    actions: string[];
    thanksInfo?: ThanksInfoType;
    contentRating?: null | number
    ratingEnabled?: boolean
}

export const createDefaultThanksInfo = (): ThanksInfoType => ({
    users: [],
    thanksReasonIds: [],
    thanksCount: 1
})

export const createDefaultReply = (): ReplyType => ({
    id: '',
    text: '',
    header: '',
    liked: false,
    likeCount: 0,
    date: new Date(),
    changeDate: new Date(),
    user: defaultUser,
    attachments: [],
    actions: [],
    type: 'default',
    ratingEnabled: false
    // thanksInfo: defaultThanksInfo
})