import React, { useState } from 'react';
import { Modifier, EditorState, AtomicBlockUtils, RichUtils } from 'draft-js'
// import { Saggester } from 'blocks/PryanikyForms/Saggester/Saggester'
// import { renderToggler, renderFilter, renderChip, renderItem } from 'blocks/PryanikyForms/renderers'
// import { items, itemChip } from 'blocks/PrnUserInput/renders'
import { Icon } from 'uielements/src'
// import PropTypes from 'prop-types';

// const propTypes = {
//   className: PropTypes.string,
//   children: PropTypes.node.isRequired,
//   entityKey: PropTypes.string,
//   getEditorState: PropTypes.func.isRequired,
// };
// let ref: any = null;
const Link = ({ children, className, entityKey, getEditorState, end, start, setOpen, ...props }: any) => {
  const [ref, setRef] = useState<any>(null);
  const entity = getEditorState()
    .getCurrentContent()
    .getEntity(entityKey);
  const entityData = entity ? entity.get('data') : undefined;
  const href = (entityData && entityData.url) || undefined;


  const onToggleItem = (user: any) => {
    const state = getEditorState();
    const selection = state.getSelection();
    const contentState = state.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity(
      "mention", '"SEGMENTED"', {
      mention: {
        ...user,
        isUser: true,
        // href: "/user/" + user.id,
        link: "/user/" + user.id,
        // url: "/user/" + user.id,
      }
    }
    );
    const lastEntityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newSelection = selection.merge({
      anchorOffset: start,
      focusOffset: end,
    });

    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      newSelection,
      lastEntityKey
    );


    const withLink = EditorState.push(
      state,
      contentStateWithLink,
      'apply-entity'
    );

    // debugger
    props.onChangeState(EditorState.acceptSelection(
      withLink,
      selection
    ))
    /*props.onChangeState(EditorState.forceSelection(
      withLink,
      selection
    ))*/

  }
  const getRef = (r: any) => {
    setRef(r);
    // ref && props.store.updatePortalClientRect(ref.getBoundingClientRect())
  }

  const onClick = () => {
    props.store.users = entity.getData().mention.users.array;
    ref && props.store.updatePortalClientRect(ref.getBoundingClientRect(), ref.offsetTop, ref);
    props.store.onToggleItem = onToggleItem;
    setOpen(true);
  }
  return (
    <span
      className={className}
      style={{ backgroundColor: '#f00', padding: '0 3px' }}
      title={href}
      ref={getRef}
    // href={href}
    // target={target}
    // rel="noopener noreferrer"
    >
      <span onClick={onClick}>{children}<Icon icon={'chevron-down'} /></span>
      
      {/*<span contentEditable={false}>{open && <Saggester clickOutside={() => setOpen(false)} onSelect={onToggleItem} className={'Select-Suggester'}>
        {(onClick: (id: any) => () => void) => items(entity.getData().mention.users.array, onClick)}
  </Saggester>}</span>*/}
    </span>
  );
};

// Link.propTypes = propTypes;
export default Link;
