import { useDispatch, useSelector } from "react-redux";
import { NDataStorage } from "./interfaces";
import { getDSStoreByName, getDSStoreItemByNameAndId } from "./selectors";
import { DSChangeItem, DSRemoveItem, DSSetItems } from "./actions";
import { NDataStorageActions } from "./actions.interfaces";
import { useCallback, useState } from "react";

export const useDSItem = <SN extends NDataStorage.StorageName> (storage: SN, id: NDataStorage.StorageItemKeys) => {

    const dispatch = useDispatch();

    const [ isEdited, setIsEdited ] = useState(false);

    const item = useSelector(getDSStoreItemByNameAndId(storage, id));

    const changeItem = useCallback((item: NDataStorageActions.ChangeItem<SN>['payload']['item']) => {
        setIsEdited(true);
        dispatch(DSChangeItem({ storage, id, item }));
    }, [storage, id]);

    const removeItem = useCallback(() => {
        setIsEdited(false);
        dispatch(DSRemoveItem({ storage, id }))
    }, [storage, id]);

    return {
        item,
        isEdited,
        setIsEdited,
        changeItem,
        removeItem
    };
};

export const useDSStorage = <SN extends NDataStorage.StorageName> (storageName: SN) => {

    const dispatch = useDispatch();

    // const storage = useSelector(getDSStoreByName(storageName));

    const addItems = useCallback((items: NDataStorageActions.SetItems<SN>['payload']['items']) => {
        dispatch(DSSetItems({
            storage: storageName,
            items
        }));
    }, []);
    
    return {
        addItems,
    };
};