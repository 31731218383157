import { styled, Button } from "muicomponents/src";
import { Box } from "muicomponents/src/Box/Box";

export const StyledSenderBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
}) as typeof Box;

export const StlyedPostNewsButton = styled(Button)({
    marginLeft: 'auto'
}) as typeof Button;