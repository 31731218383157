import { cn } from '@bem-react/classname';
import { IStateType as IState } from 'redux/store';
import { IUserBirthday } from 'utils/src/requests/models/api.users';
import { IStoreAppSetting } from 'models/store/appSettings';
import { updateWidget } from "../../redux/actions/Widgets";
import { bindActionCreators } from 'redux';

export interface IHtmlWidgetProps extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
    contexts?: string[];
    widget: any;
    tag?: React.ComponentType<any> | 'div';
    className?: string;
    editData?: boolean; 
}

export interface IHtmlWidgetState {
    editData: boolean;
    data: any;
    text: string;
}

export const mapStateToProps = (state: IState) => ({

});

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    updateWidget
}, dispatch);

export const cnHtmlWidget = cn('HtmlWidget');
