/* eslint-disable */
var localStorage = window.localStorage;

(function () { function e(t, n, r) { function s(o, u) { if (!n[o]) { if (!t[o]) { var a = typeof require == "function" && require; if (!u && a) return a(o, !0); if (i) return i(o, !0); var f = new Error("Cannot find module '" + o + "'"); throw f.code = "MODULE_NOT_FOUND", f } var l = n[o] = { exports: {} }; t[o][0].call(l.exports, function (e) { var n = t[o][1][e]; return s(n ? n : e) }, l, l.exports, e, t, n, r) } return n[o].exports } var i = typeof require == "function" && require; for (var o = 0; o < r.length; o++)s(r[o]); return s } return e })()({
    1: [function (require, module, exports) {
        var appUtils = window.appUtils || {};
        appUtils.ddp = require("ddp.js").default;
        window.appUtils = appUtils;

    }, { "ddp.js": 2 }], 2: [function (require, module, exports) {
        "use strict";

        Object.defineProperty(exports, "__esModule", {
            value: true
        });

        var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

        var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

        var _wolfy87Eventemitter = require("wolfy87-eventemitter");

        var _wolfy87Eventemitter2 = _interopRequireDefault(_wolfy87Eventemitter);

        var _queue = require("./queue");

        var _queue2 = _interopRequireDefault(_queue);

        var _socket = require("./socket");

        var _socket2 = _interopRequireDefault(_socket);

        var _utils = require("./utils");

        function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

        function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

        function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

        function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

        var DDP_VERSION = "1";
        var PUBLIC_EVENTS = [
            // Subscription messages
            "ready", "nosub", "added", "changed", "removed",
            // Method messages
            "result", "updated",
            // Error messages
            "error"];
        var DEFAULT_RECONNECT_INTERVAL = 10000;

        var DDP = function (_EventEmitter) {
            _inherits(DDP, _EventEmitter);

            _createClass(DDP, [{
                key: "emit",
                value: function emit() {
                    var _get2;

                    setTimeout((_get2 = _get(DDP.prototype.__proto__ || Object.getPrototypeOf(DDP.prototype), "emit", this)).bind.apply(_get2, [this].concat(Array.prototype.slice.call(arguments))), 0);
                }
            }]);

            function DDP(options) {
                _classCallCheck(this, DDP);

                var _this = _possibleConstructorReturn(this, (DDP.__proto__ || Object.getPrototypeOf(DDP)).call(this));

                _this.status = "disconnected";

                // Default `autoConnect` and `autoReconnect` to true
                _this.autoConnect = options.autoConnect !== false;
                _this.autoReconnect = options.autoReconnect !== false;
                _this.reconnectInterval = options.reconnectInterval || DEFAULT_RECONNECT_INTERVAL;
                _this.debug = options.debug !== true;

                _this.messageQueue = new _queue2.default(function (message) {
                    if (_this.status === "connected") {
                        _this.socket.send(message);
                        return true;
                    } else {
                        return false;
                    }
                });

                _this.socket = new _socket2.default(options.SocketConstructor, options.endpoint);

                _this.socket.on("open", function () {
                    // When the socket opens, send the `connect` message
                    // to establish the DDP connection
                    _this.socket.send({
                        msg: "connect",
                        version: DDP_VERSION,
                        support: [DDP_VERSION]
                    });
                });

                _this.socket.on("close", function () {
                    _this.status = "disconnected";
                    _this.messageQueue.empty();
                    _this.emit("disconnected");
                    if (_this.autoReconnect) {
                        // Schedule a reconnection
                        setTimeout(_this.socket.open.bind(_this.socket), _this.reconnectInterval);
                    }
                });

                _this.socket.on("message:in", function (message) {
                    if (message.msg === "connected") {
                        _this.status = "connected";
                        _this.messageQueue.process();
                        _this.emit("connected");
                    } else if (message.msg === "ping") {
                        // Reply with a `pong` message to prevent the server from
                        // closing the connection
                        _this.socket.send({ msg: "pong", id: message.id });
                    } else if ((0, _utils.contains)(PUBLIC_EVENTS, message.msg)) {
                        _this.emit(message.msg, message);
                    }
                });

                if (_this.autoConnect) {
                    _this.connect();
                }

                return _this;
            }

            _createClass(DDP, [{
                key: "connect",
                value: function connect() {
                    this.socket.open();
                }
            }, {
                key: "disconnect",
                value: function disconnect() {
                    /*
                    *   If `disconnect` is called, the caller likely doesn't want the
                    *   the instance to try to auto-reconnect. Therefore we set the
                    *   `autoReconnect` flag to false.
                    */
                    this.autoReconnect = false;
                    this.socket.close();
                }
            }, {
                key: "method",
                value: function method(name, params) {
                    var id = (0, _utils.uniqueId)();
                    this.messageQueue.push({
                        msg: "method",
                        id: id,
                        method: name,
                        params: params
                    });
                    return id;
                }
            }, {
                key: "sub",
                value: function sub(name, params) {
                    var id = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

                    id || (id = (0, _utils.uniqueId)());
                    this.messageQueue.push({
                        msg: "sub",
                        id: id,
                        name: name,
                        params: params
                    });
                    return id;
                }
            }, {
                key: "unsub",
                value: function unsub(id) {
                    this.messageQueue.push({
                        msg: "unsub",
                        id: id
                    });
                    return id;
                }
            }]);

            return DDP;
        }(_wolfy87Eventemitter2.default);

        exports.default = DDP;
    }, { "./queue": 3, "./socket": 4, "./utils": 5, "wolfy87-eventemitter": 6 }], 3: [function (require, module, exports) {
        "use strict";

        Object.defineProperty(exports, "__esModule", {
            value: true
        });

        var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

        function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

        var Queue = function () {

            /*
            *   As the name implies, `consumer` is the (sole) consumer of the queue.
            *   It gets called with each element of the queue and its return value
            *   serves as a ack, determining whether the element is removed or not from
            *   the queue, allowing then subsequent elements to be processed.
            */

            function Queue(consumer) {
                _classCallCheck(this, Queue);

                this.consumer = consumer;
                this.queue = [];
            }

            _createClass(Queue, [{
                key: "push",
                value: function push(element) {
                    this.queue.push(element);
                    this.process();
                }
            }, {
                key: "process",
                value: function process() {
                    if (this.queue.length !== 0) {
                        var ack = this.consumer(this.queue[0]);
                        if (ack) {
                            this.queue.shift();
                            this.process();
                        }
                    }
                }
            }, {
                key: "empty",
                value: function empty() {
                    this.queue = [];
                }
            }]);

            return Queue;
        }();

        exports.default = Queue;
    }, {}], 4: [function (require, module, exports) {
        "use strict";

        Object.defineProperty(exports, "__esModule", {
            value: true
        });

        var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

        var _wolfy87Eventemitter = require("wolfy87-eventemitter");

        var _wolfy87Eventemitter2 = _interopRequireDefault(_wolfy87Eventemitter);

        function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

        function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

        function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

        function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

        var Socket = function (_EventEmitter) {
            _inherits(Socket, _EventEmitter);

            function Socket(SocketConstructor, endpoint) {
                _classCallCheck(this, Socket);

                var _this = _possibleConstructorReturn(this, (Socket.__proto__ || Object.getPrototypeOf(Socket)).call(this));

                _this.SocketConstructor = SocketConstructor;
                _this.endpoint = endpoint;
                _this.rawSocket = null;
                return _this;
            }

            _createClass(Socket, [{
                key: "send",
                value: function send(object) {
                    var message = JSON.stringify(object);
                    this.rawSocket.send(message);
                    // Emit a copy of the object, as the listener might mutate it.
                    this.emit("message:out", JSON.parse(message));
                }
            }, {
                key: "open",
                value: function open() {
                    var _this2 = this;

                    /*
                    *   Makes `open` a no-op if there's already a `rawSocket`. This avoids
                    *   memory / socket leaks if `open` is called twice (e.g. by a user
                    *   calling `ddp.connect` twice) without properly disposing of the
                    *   socket connection. `rawSocket` gets automatically set to `null` only
                    *   when it goes into a closed or error state. This way `rawSocket` is
                    *   disposed of correctly: the socket connection is closed, and the
                    *   object can be garbage collected.
                    */
                    if (this.rawSocket) {
                        return;
                    }
                    this.rawSocket = new this.SocketConstructor(this.endpoint);

                    /*
                    *   Calls to `onopen` and `onclose` directly trigger the `open` and
                    *   `close` events on the `Socket` instance.
                    */
                    this.rawSocket.onopen = function () {
                        return _this2.emit("open");
                    };
                    this.rawSocket.onclose = function () {
                        _this2.rawSocket = null;
                        _this2.emit("close");
                    };
                    /*
                    *   Calls to `onerror` trigger the `close` event on the `Socket`
                    *   instance, and cause the `rawSocket` object to be disposed of.
                    *   Since it's not clear what conditions could cause the error and if
                    *   it's possible to recover from it, we prefer to always close the
                    *   connection (if it isn't already) and dispose of the socket object.
                    */
                    this.rawSocket.onerror = function () {
                        // It's not clear what the socket lifecycle is when errors occurr.
                        // Hence, to avoid the `close` event to be emitted twice, before
                        // manually closing the socket we de-register the `onclose`
                        // callback.
                        delete _this2.rawSocket.onclose;
                        // Safe to perform even if the socket is already closed
                        _this2.rawSocket.close();
                        _this2.rawSocket = null;
                        _this2.emit("close");
                    };
                    /*
                    *   Calls to `onmessage` trigger a `message:in` event on the `Socket`
                    *   instance only once the message (first parameter to `onmessage`) has
                    *   been successfully parsed into a javascript object.
                    */
                    this.rawSocket.onmessage = function (message) {
                        var object;
                        try {
                            object = JSON.parse(message.data);
                        } catch (ignore) {
                            // Simply ignore the malformed message and return
                            return;
                        }
                        // Outside the try-catch block as it must only catch JSON parsing
                        // errors, not errors that may occur inside a "message:in" event
                        // handler
                        _this2.emit("message:in", object);
                    };
                }
            }, {
                key: "close",
                value: function close() {
                    /*
                    *   Avoid throwing an error if `rawSocket === null`
                    */
                    if (this.rawSocket) {
                        this.rawSocket.close();
                    }
                }
            }]);

            return Socket;
        }(_wolfy87Eventemitter2.default);

        exports.default = Socket;
    }, { "wolfy87-eventemitter": 6 }], 5: [function (require, module, exports) {
        "use strict";

        Object.defineProperty(exports, "__esModule", {
            value: true
        });
        exports.uniqueId = uniqueId;
        exports.contains = contains;
        var i = 0;
        function uniqueId() {
            return (i++).toString();
        }

        function contains(array, element) {
            return array.indexOf(element) !== -1;
        }
    }, {}], 6: [function (require, module, exports) {
        /*!
         * EventEmitter v5.2.4 - git.io/ee
         * Unlicense - http://unlicense.org/
         * Oliver Caldwell - http://oli.me.uk/
         * @preserve
         */

        ; (function (exports) {
            'use strict';

            /**
             * Class for managing events.
             * Can be extended to provide event functionality in other classes.
             *
             * @class EventEmitter Manages event registering and emitting.
             */
            function EventEmitter() { }

            // Shortcuts to improve speed and size
            var proto = EventEmitter.prototype;
            var originalGlobalValue = exports.EventEmitter;

            /**
             * Finds the index of the listener for the event in its storage array.
             *
             * @param {Function[]} listeners Array of listeners to search through.
             * @param {Function} listener Method to look for.
             * @return {Number} Index of the specified listener, -1 if not found
             * @api private
             */
            function indexOfListener(listeners, listener) {
                var i = listeners.length;
                while (i--) {
                    if (listeners[i].listener === listener) {
                        return i;
                    }
                }

                return -1;
            }

            /**
             * Alias a method while keeping the context correct, to allow for overwriting of target method.
             *
             * @param {String} name The name of the target method.
             * @return {Function} The aliased method
             * @api private
             */
            function alias(name) {
                return function aliasClosure() {
                    return this[name].apply(this, arguments);
                };
            }

            /**
             * Returns the listener array for the specified event.
             * Will initialise the event object and listener arrays if required.
             * Will return an object if you use a regex search. The object contains keys for each matched event. So /ba[rz]/ might return an object containing bar and baz. But only if you have either defined them with defineEvent or added some listeners to them.
             * Each property in the object response is an array of listener functions.
             *
             * @param {String|RegExp} evt Name of the event to return the listeners from.
             * @return {Function[]|Object} All listener functions for the event.
             */
            proto.getListeners = function getListeners(evt) {
                var events = this._getEvents();
                var response;
                var key;

                // Return a concatenated array of all matching events if
                // the selector is a regular expression.
                if (evt instanceof RegExp) {
                    response = {};
                    for (key in events) {
                        if (events.hasOwnProperty(key) && evt.test(key)) {
                            response[key] = events[key];
                        }
                    }
                }
                else {
                    response = events[evt] || (events[evt] = []);
                }

                return response;
            };

            /**
             * Takes a list of listener objects and flattens it into a list of listener functions.
             *
             * @param {Object[]} listeners Raw listener objects.
             * @return {Function[]} Just the listener functions.
             */
            proto.flattenListeners = function flattenListeners(listeners) {
                var flatListeners = [];
                var i;

                for (i = 0; i < listeners.length; i += 1) {
                    flatListeners.push(listeners[i].listener);
                }

                return flatListeners;
            };

            /**
             * Fetches the requested listeners via getListeners but will always return the results inside an object. This is mainly for internal use but others may find it useful.
             *
             * @param {String|RegExp} evt Name of the event to return the listeners from.
             * @return {Object} All listener functions for an event in an object.
             */
            proto.getListenersAsObject = function getListenersAsObject(evt) {
                var listeners = this.getListeners(evt);
                var response;

                if (listeners instanceof Array) {
                    response = {};
                    response[evt] = listeners;
                }

                return response || listeners;
            };

            function isValidListener(listener) {
                if (typeof listener === 'function' || listener instanceof RegExp) {
                    return true
                } else if (listener && typeof listener === 'object') {
                    return isValidListener(listener.listener)
                } else {
                    return false
                }
            }

            /**
             * Adds a listener function to the specified event.
             * The listener will not be added if it is a duplicate.
             * If the listener returns true then it will be removed after it is called.
             * If you pass a regular expression as the event name then the listener will be added to all events that match it.
             *
             * @param {String|RegExp} evt Name of the event to attach the listener to.
             * @param {Function} listener Method to be called when the event is emitted. If the function returns true then it will be removed after calling.
             * @return {Object} Current instance of EventEmitter for chaining.
             */
            proto.addListener = function addListener(evt, listener) {
                if (!isValidListener(listener)) {
                    throw new TypeError('listener must be a function');
                }

                var listeners = this.getListenersAsObject(evt);
                var listenerIsWrapped = typeof listener === 'object';
                var key;

                for (key in listeners) {
                    if (listeners.hasOwnProperty(key) && indexOfListener(listeners[key], listener) === -1) {
                        listeners[key].push(listenerIsWrapped ? listener : {
                            listener: listener,
                            once: false
                        });
                    }
                }

                return this;
            };

            /**
             * Alias of addListener
             */
            proto.on = alias('addListener');

            /**
             * Semi-alias of addListener. It will add a listener that will be
             * automatically removed after its first execution.
             *
             * @param {String|RegExp} evt Name of the event to attach the listener to.
             * @param {Function} listener Method to be called when the event is emitted. If the function returns true then it will be removed after calling.
             * @return {Object} Current instance of EventEmitter for chaining.
             */
            proto.addOnceListener = function addOnceListener(evt, listener) {
                return this.addListener(evt, {
                    listener: listener,
                    once: true
                });
            };

            /**
             * Alias of addOnceListener.
             */
            proto.once = alias('addOnceListener');

            /**
             * Defines an event name. This is required if you want to use a regex to add a listener to multiple events at once. If you don't do this then how do you expect it to know what event to add to? Should it just add to every possible match for a regex? No. That is scary and bad.
             * You need to tell it what event names should be matched by a regex.
             *
             * @param {String} evt Name of the event to create.
             * @return {Object} Current instance of EventEmitter for chaining.
             */
            proto.defineEvent = function defineEvent(evt) {
                this.getListeners(evt);
                return this;
            };

            /**
             * Uses defineEvent to define multiple events.
             *
             * @param {String[]} evts An array of event names to define.
             * @return {Object} Current instance of EventEmitter for chaining.
             */
            proto.defineEvents = function defineEvents(evts) {
                for (var i = 0; i < evts.length; i += 1) {
                    this.defineEvent(evts[i]);
                }
                return this;
            };

            /**
             * Removes a listener function from the specified event.
             * When passed a regular expression as the event name, it will remove the listener from all events that match it.
             *
             * @param {String|RegExp} evt Name of the event to remove the listener from.
             * @param {Function} listener Method to remove from the event.
             * @return {Object} Current instance of EventEmitter for chaining.
             */
            proto.removeListener = function removeListener(evt, listener) {
                var listeners = this.getListenersAsObject(evt);
                var index;
                var key;

                for (key in listeners) {
                    if (listeners.hasOwnProperty(key)) {
                        index = indexOfListener(listeners[key], listener);

                        if (index !== -1) {
                            listeners[key].splice(index, 1);
                        }
                    }
                }

                return this;
            };

            /**
             * Alias of removeListener
             */
            proto.off = alias('removeListener');

            /**
             * Adds listeners in bulk using the manipulateListeners method.
             * If you pass an object as the first argument you can add to multiple events at once. The object should contain key value pairs of events and listeners or listener arrays. You can also pass it an event name and an array of listeners to be added.
             * You can also pass it a regular expression to add the array of listeners to all events that match it.
             * Yeah, this function does quite a bit. That's probably a bad thing.
             *
             * @param {String|Object|RegExp} evt An event name if you will pass an array of listeners next. An object if you wish to add to multiple events at once.
             * @param {Function[]} [listeners] An optional array of listener functions to add.
             * @return {Object} Current instance of EventEmitter for chaining.
             */
            proto.addListeners = function addListeners(evt, listeners) {
                // Pass through to manipulateListeners
                return this.manipulateListeners(false, evt, listeners);
            };

            /**
             * Removes listeners in bulk using the manipulateListeners method.
             * If you pass an object as the first argument you can remove from multiple events at once. The object should contain key value pairs of events and listeners or listener arrays.
             * You can also pass it an event name and an array of listeners to be removed.
             * You can also pass it a regular expression to remove the listeners from all events that match it.
             *
             * @param {String|Object|RegExp} evt An event name if you will pass an array of listeners next. An object if you wish to remove from multiple events at once.
             * @param {Function[]} [listeners] An optional array of listener functions to remove.
             * @return {Object} Current instance of EventEmitter for chaining.
             */
            proto.removeListeners = function removeListeners(evt, listeners) {
                // Pass through to manipulateListeners
                return this.manipulateListeners(true, evt, listeners);
            };

            /**
             * Edits listeners in bulk. The addListeners and removeListeners methods both use this to do their job. You should really use those instead, this is a little lower level.
             * The first argument will determine if the listeners are removed (true) or added (false).
             * If you pass an object as the second argument you can add/remove from multiple events at once. The object should contain key value pairs of events and listeners or listener arrays.
             * You can also pass it an event name and an array of listeners to be added/removed.
             * You can also pass it a regular expression to manipulate the listeners of all events that match it.
             *
             * @param {Boolean} remove True if you want to remove listeners, false if you want to add.
             * @param {String|Object|RegExp} evt An event name if you will pass an array of listeners next. An object if you wish to add/remove from multiple events at once.
             * @param {Function[]} [listeners] An optional array of listener functions to add/remove.
             * @return {Object} Current instance of EventEmitter for chaining.
             */
            proto.manipulateListeners = function manipulateListeners(remove, evt, listeners) {
                var i;
                var value;
                var single = remove ? this.removeListener : this.addListener;
                var multiple = remove ? this.removeListeners : this.addListeners;

                // If evt is an object then pass each of its properties to this method
                if (typeof evt === 'object' && !(evt instanceof RegExp)) {
                    for (i in evt) {
                        if (evt.hasOwnProperty(i) && (value = evt[i])) {
                            // Pass the single listener straight through to the singular method
                            if (typeof value === 'function') {
                                single.call(this, i, value);
                            }
                            else {
                                // Otherwise pass back to the multiple function
                                multiple.call(this, i, value);
                            }
                        }
                    }
                }
                else {
                    // So evt must be a string
                    // And listeners must be an array of listeners
                    // Loop over it and pass each one to the multiple method
                    i = listeners.length;
                    while (i--) {
                        single.call(this, evt, listeners[i]);
                    }
                }

                return this;
            };

            /**
             * Removes all listeners from a specified event.
             * If you do not specify an event then all listeners will be removed.
             * That means every event will be emptied.
             * You can also pass a regex to remove all events that match it.
             *
             * @param {String|RegExp} [evt] Optional name of the event to remove all listeners for. Will remove from every event if not passed.
             * @return {Object} Current instance of EventEmitter for chaining.
             */
            proto.removeEvent = function removeEvent(evt) {
                var type = typeof evt;
                var events = this._getEvents();
                var key;

                // Remove different things depending on the state of evt
                if (type === 'string') {
                    // Remove all listeners for the specified event
                    delete events[evt];
                }
                else if (evt instanceof RegExp) {
                    // Remove all events matching the regex.
                    for (key in events) {
                        if (events.hasOwnProperty(key) && evt.test(key)) {
                            delete events[key];
                        }
                    }
                }
                else {
                    // Remove all listeners in all events
                    delete this._events;
                }

                return this;
            };

            /**
             * Alias of removeEvent.
             *
             * Added to mirror the node API.
             */
            proto.removeAllListeners = alias('removeEvent');

            /**
             * Emits an event of your choice.
             * When emitted, every listener attached to that event will be executed.
             * If you pass the optional argument array then those arguments will be passed to every listener upon execution.
             * Because it uses `apply`, your array of arguments will be passed as if you wrote them out separately.
             * So they will not arrive within the array on the other side, they will be separate.
             * You can also pass a regular expression to emit to all events that match it.
             *
             * @param {String|RegExp} evt Name of the event to emit and execute listeners for.
             * @param {Array} [args] Optional array of arguments to be passed to each listener.
             * @return {Object} Current instance of EventEmitter for chaining.
             */
            proto.emitEvent = function emitEvent(evt, args) {
                var listenersMap = this.getListenersAsObject(evt);
                var listeners;
                var listener;
                var i;
                var key;
                var response;

                for (key in listenersMap) {
                    if (listenersMap.hasOwnProperty(key)) {
                        listeners = listenersMap[key].slice(0);

                        for (i = 0; i < listeners.length; i++) {
                            // If the listener returns true then it shall be removed from the event
                            // The function is executed either with a basic call or an apply if there is an args array
                            listener = listeners[i];

                            if (listener.once === true) {
                                this.removeListener(evt, listener.listener);
                            }

                            response = listener.listener.apply(this, args || []);

                            if (response === this._getOnceReturnValue()) {
                                this.removeListener(evt, listener.listener);
                            }
                        }
                    }
                }

                return this;
            };

            /**
             * Alias of emitEvent
             */
            proto.trigger = alias('emitEvent');

            /**
             * Subtly different from emitEvent in that it will pass its arguments on to the listeners, as opposed to taking a single array of arguments to pass on.
             * As with emitEvent, you can pass a regex in place of the event name to emit to all events that match it.
             *
             * @param {String|RegExp} evt Name of the event to emit and execute listeners for.
             * @param {...*} Optional additional arguments to be passed to each listener.
             * @return {Object} Current instance of EventEmitter for chaining.
             */
            proto.emit = function emit(evt) {
                var args = Array.prototype.slice.call(arguments, 1);
                return this.emitEvent(evt, args);
            };

            /**
             * Sets the current value to check against when executing listeners. If a
             * listeners return value matches the one set here then it will be removed
             * after execution. This value defaults to true.
             *
             * @param {*} value The new value to check for when executing listeners.
             * @return {Object} Current instance of EventEmitter for chaining.
             */
            proto.setOnceReturnValue = function setOnceReturnValue(value) {
                this._onceReturnValue = value;
                return this;
            };

            /**
             * Fetches the current value to check against when executing listeners. If
             * the listeners return value matches this one then it should be removed
             * automatically. It will return true by default.
             *
             * @return {*|Boolean} The current value to check for or the default, true.
             * @api private
             */
            proto._getOnceReturnValue = function _getOnceReturnValue() {
                if (this.hasOwnProperty('_onceReturnValue')) {
                    return this._onceReturnValue;
                }
                else {
                    return true;
                }
            };

            /**
             * Fetches the events object and creates one if required.
             *
             * @return {Object} The events storage object.
             * @api private
             */
            proto._getEvents = function _getEvents() {
                return this._events || (this._events = {});
            };

            /**
             * Reverts the global {@link EventEmitter} to its previous value and returns a reference to this version.
             *
             * @return {Function} Non conflicting EventEmitter class.
             */
            EventEmitter.noConflict = function noConflict() {
                exports.EventEmitter = originalGlobalValue;
                return EventEmitter;
            };

            // Expose the class either via AMD, CommonJS or the global object
            if (typeof define === 'function' && define.amd) {
                define(function () {
                    return EventEmitter;
                });
            }
            else if (typeof module === 'object' && module.exports) {
                module.exports = EventEmitter;
            }
            else {
                exports.EventEmitter = EventEmitter;
            }
        }(this || {}));

    }, {}]
}, {}, [1]);

export const ChatMicroClient = function (config) {
    this.localStorageVar = 'microChatData';
    this.ddp;
    this.auth = { id: 0, token: config.token || "", expiresAt: 0 };
    this.domain = config.domain || "localhost";
    this.endpoint = "ws" + (config.useSSL ? "s" : "") + "://" + this.domain + "/websocket";
    this.reqs = {};
    this.collections = {};
    this.messagesTs = {};
    this.channels = {};
    this.channelsUpdatedAt;
    this.channelsUnread = {};
    this.channelsUnreadAt;
    this.useLocalStorage = (config.useLocalStorage === true) && (localStorage != undefined);
    this.debugLevel = config.debugLevel || 0;

    this.bindChannelsList = (config.bindings && config.bindings.hasOwnProperty('chatList')) ? config.bindings.chatList : [];
    this.bindUnreadChannels = (config.bindings && config.bindings.hasOwnProperty('chatNewChannelCount')) ? config.bindings.chatNewChannelCount : 0;
    this.bindUnreadMessages = (config.bindings && config.bindings.hasOwnProperty('chatNewMessages')) ? config.bindings.chatNewMessages : 0;

    this.STATUS_ONLINE = 'online';
    this.STATUS_OFFLINE = 'offline';
    this.STATUS_AWAY = 'away';
    this.STATUS_BUSY = 'busy';

    this.CHANNEL_TYPE_DIRECT = 'd';
    this.CHANNEL_TYPE_PRIVATE = 'p';
    this.CHANNEL_TYPE_CHANNEL = 'c';

    this.init();
}

ChatMicroClient.prototype = {
    constructor: ChatMicroClient,
    init: function () {
        this.ddp = new appUtils.ddp({ endpoint: this.endpoint, SocketConstructor: WebSocket });
        this.ddp.on("connected", this.onConnected);
        this.ddp.on("disconnected", this.onDisconnected);
        this.ddp.on("ready", this.onReady);
        this.ddp.on("added", this.onAdded);
        this.ddp.on("changed", this.onChanged);
        this.ddp.on("updated", this.onUpdated);
        this.ddp.on("removed", this.onRemoved);
        this.ddp.on("nosub", this.onNoSub);
        this.ddp.on("result", this.onResult);
        this.ddp.client = this;

        if (this.useLocalStorage) this.load();
    },
    load: function () {
        var data = JSON.parse(localStorage.getItem(this.localStorageVar) || "{}");
        this.channelsUnread = data.channelsUnread || {};
        this.channelsUnreadAt = data.channelsUnreadAt || 0;
    },
    save: function () {
        var data = {
            channelsUnread: this.channelsUnread,
            channelsUnreadAt: this.channelsUnreadAt
        }
        localStorage.setItem(this.localStorageVar, JSON.stringify(data));
    },
    run: function () {
        this.authorize();
    },
    authorize: function () {
        this.reqs.auth = this.ddp.method("login", [{ "resume": this.auth.token }]);
    },
    subscribe: function () {
        this.reqs.subsToMessages = this.ddp.sub("stream-notify-user", [this.auth.id + "/message", false]);
        this.reqs.subsToNotification = this.ddp.sub("stream-notify-user", [this.auth.id + "/notification", false]);
        this.reqs.subsToRoomsChanged = this.ddp.sub("stream-notify-user", [this.auth.id + "/rooms-changed", false]);
        this.reqs.subsToSubsChanged = this.ddp.sub("stream-notify-user", [this.auth.id + "/subscriptions-changed", false]);
        this.reqs.subsToActiveUsers = this.ddp.sub("activeUsers");
    },
    getSubscriptions: function (ts) {
        this.reqs.getSubscriptions = ts ? this.ddp.method("subscriptions/get", [{ "$date": ts }]) : this.ddp.method("subscriptions/get");
    },
    getRooms: function (ts) {
        this.reqs.getRooms = ts ? this.ddp.method("rooms/get", [{ "$date": ts }]) : this.ddp.method("rooms/get");
    },
    onConnected: function () {
        this.client.log('connected to ', this.client.endpoint);
    },
    onDisconnected: function () {
        this.client.log('disconnected from ', this.client.endpoint);
    },
    onReady: function (message) {
        this.client.log("onReady", message);
        if (message.hasOwnProperty('error')) {
            this.client.reportError(message.error);
        }
    },
    onResult: function (message) {
        this.client.log("onResult", message);
        if (message.hasOwnProperty('error')) {
            this.client.reportError(message.error);
        } else if (this.client.reqs.hasOwnProperty('auth') && message.id === this.client.reqs.auth) {
            this.client.auth.id = message.result.id;
            this.client.auth.token = message.result.token;
            this.client.auth.expiresAt = message.result.tokenExpires.$date;

            this.client.log('logged in as ' + this.client.getUserName(this.client.auth.id));

            this.client.getRooms();
            this.client.getSubscriptions();
            this.client.subscribe();
        } else if (this.client.reqs.hasOwnProperty('getRooms') && message.id === this.client.reqs.getRooms) {
            for (var i = 0, si = message.result.length; i < si; i++) {
                var c = message.result[i];
                if (this.client.channels.hasOwnProperty(c._id)) {
                    this.client.channels[c._id].unread = c.unread || this.client.channels[c._id].unread;
                    this.client.channels[c._id].mentions = c.userMentions || this.client.channels[c._id].mentions;
                    this.client.channels[c._id].name = this.client.channels[c._id].name || c.name;
                    this.client.channels[c._id].fname = this.client.channels[c._id].fname || c.fname || c.name;
                    this.client.channels[c._id].alert = typeof (c.alert) != 'undefined' ? c.alert : this.client.channels[c._id].alert;
                } else {
                    this.client.channels[c._id] = {
                        id: c._id,
                        type: c.t,
                        name: c.name,
                        fname: c.fname || c.name,
                        ts: c._updatedAt ? c._updatedAt.$date : c.ts.$date,
                        unread: c.unread || 0,
                        mentions: c.userMentions || 0,
                        alert: c.alert || false
                    }
                }
                if (c.lastMessage) {
                    this.client.channels[c._id].lastMessage = {
                        id: c.lastMessage._id,
                        ts: c.lastMessage.ts.$date,
                        msg: c.lastMessage.msg,
                        u: c.lastMessage.u
                    }
                    this.client.channels[c._id].ts = c.lastMessage.ts.$date
                }
            }
            this.client.updateChannelsList();
        } else if (this.client.reqs.hasOwnProperty('getSubscriptions') && message.id === this.client.reqs.getSubscriptions) {
            for (var i = 0, si = message.result.length; i < si; i++) {
                var c = message.result[i];
                if (this.client.channels.hasOwnProperty(c.rid)) {
                    this.client.channels[c.rid].unread = c.unread;
                    this.client.channels[c.rid].mentions = c.userMentions;
                    this.client.channels[c.rid].alert = typeof (c.alert) != 'undefined' ? c.alert : this.client.channels[c.rid].alert;
                    this.client.channels[c.rid].name = this.client.channels[c.rid].name || c.name;
                    this.client.channels[c.rid].fname = this.client.channels[c.rid].fname || c.fname || c.name;
                } else {
                    this.client.channels[c.rid] = {
                        id: c.rid,
                        type: c.t,
                        name: c.name,
                        fname: c.fname || c.name,
                        ts: c._updatedAt ? c._updatedAt.$date : c.ts.$date,
                        unread: c.unread || 0,
                        mentions: c.userMentions || 0,
                        alert: c.alert || false
                    }
                }
            }
            this.client.updateChannelsList();
        }
    },
    onAdded: function (message) {
        this.client.log("onAdded", message);
        if (message.hasOwnProperty('error')) {
            this.client.reportError(message.error);
        } else {
            if (!this.client.collections.hasOwnProperty(message.collection)) {
                this.client.collections[message.collection] = {};
            }
            this.client.collections[message.collection][message.id] = message.fields;
            this.client.collections[message.collection][message.id]["id"] = message.id;
            if (message.collection === 'users') {
                this.client.onUsersUpdated.call(this, this.client.collections.users);
            }
        }
    },
    onChanged: function (message) {
        this.client.log("onChanged", message);
        if (message.hasOwnProperty('error')) {
            this.client.reportError(message.error);
        } else if (message.collection === 'stream-notify-user') {
            if (message.fields.eventName === this.client.auth.id + '/notification') {
                var data = message.fields.args[0];
                this.client.log("notification - data", data);
                if (this.client.channels.hasOwnProperty(data.payload.rid)) {
                    this.client.channels[data.payload.rid].lastMessage = {
                        id: data.payload._id,
                        ts: this.client.getMessageTs(data.payload._id),
                        msg: data.payload.message.msg,
                        text: data.text,
                        u: data.payload.sender
                    }
                    if (this.client.getMessageTs(data._id)) delete this.client.messagesTs[data._id];
                    this.client.updateChannelsList();
                }
                this.client.onNewMessage(message.fields.args[0])
            } else if (message.fields.eventName === this.client.auth.id + '/subscriptions-changed') {
                var reason = message.fields.args[0];
                var data = message.fields.args[1];
                this.client.log("subscriptions-changed - data", data);
                if (reason === "updated") {
                    if (this.client.channels.hasOwnProperty(data.rid)) {
                        this.client.channels[data.rid].unread = data.unread;
                        this.client.channels[data.rid].mentions = data.userMentions;
                        this.client.channels[data.rid].alert = data.alert;
                        //if (data.ts) this.client.channels[data.rid].ts = data.ts.$date;
                    }
                } else if (reason === "inserted") {
                    var c = data;
                    this.client.channels[c.rid] = {
                        id: c.rid,
                        type: c.t,
                        name: c.name,
                        fname: c.fname || c.name,
                        ts: c.ts.$date,
                        unread: c.unread || 1,
                        mentions: c.userMentions,
                        alert: c.alert || false
                    }
                    if (c.lastMessage) {
                        this.client.channels[c.rid].lastMessage = {
                            id: c.lastMessage._id,
                            ts: c.lastMessage.ts.$date,
                            msg: c.lastMessage.msg,
                            u: c.lastMessage.u
                        }
                        this.client.channels[c.rid].ts = c.lastMessage.ts.$date
                    }
                } else if (reason === "removed") {
                    if (this.client.channels.hasOwnProperty(data.rid)) {
                        delete this.client.channels[data.rid];
                    }
                }
                this.client.updateChannelsList();
            } else if (message.fields.eventName === this.client.auth.id + '/rooms-changed') {
                var data = message.fields.args[1];
                this.client.log("rooms-changed - data", data);
                if (data.hasOwnProperty('lastMessage') && this.client.channels.hasOwnProperty(data._id)) {
                    var lm = data.lastMessage;
                    if (this.client.wasMentioned(lm.mentions, this.client.auth.id) || data.t == 'd') {
                        this.client.messagesTs[lm._id] = lm.ts.$date;
                        this.client.channels[lm.rid].ts = lm.ts.$date;
                        if (lm.msg && this.client.channels[lm.rid].lastMessage) {
                            this.client.channels[lm.rid].lastMessage.msg = lm.msg;
                            this.client.channels[lm.rid].lastMessage.ts = lm.ts.$date;
                        }
                    } else if (lm.u._id == this.client.auth.id) {
                        this.client.channels[lm.rid].unread = 0;
                        this.client.channels[lm.rid].mentions = 0;
                        this.client.log("clean up unreads & mentions");
                    }
                    this.client.updateChannelsList();
                }
            }
        } else if (message.collection === 'users') {
            this.client.log("update users list", message);
            if (this.client.collections.users.hasOwnProperty(message.id)) {
                for (let field in message.fields) {
                    this.client.collections.users[message.id][field] = message.fields[field];
                }
            }
            if (message.id === this.client.auth.id) {
                if (message.fields && message.fields.hasOwnProperty('status')) {
                    this.client.onStatusChanged.call(this, message.fields.status);
                } else if (message.cleared && message.cleared.indexOf("status") != -1) {
                    this.client.onStatusChanged.call(this, 'offline');
                }
            }
            this.client.onUsersUpdated.call(this, this.client.collections.users);
        }
    },
    onRemoved: function (message) {
        this.client.log("onRemoved", message);
        if (message.hasOwnProperty('error')) {
            this.client.reportError(message.error);
        } else {
            if (this.client.collections.hasOwnProperty(message.collection)) {
                if (this.client.collections[message.collection].hasOwnProperty(message.id)) {
                    delete this.client.collections[message.collection][message.id];
                };
            }
            if (message.collection === 'users') {
                this.client.onUsersUpdated.call(this, this.client.collections.users);
            }
        }
    },
    setStatus: function (status) {
        this.reqs.setStatus = this.ddp.method("UserPresence:setDefaultStatus", [status]);
    },
    onUpdated: function (message) {
        this.client.log("onUpdated", message);
        if (message.hasOwnProperty('error')) {
            this.client.reportError(message.error);
        }
    },
    onNoSub: function (message) {
        this.client.log("onNoSub", message);
        if (message.hasOwnProperty('error')) {
            this.client.reportError(message.error);
        }
    },
    getUserName: function (id) {
        var userName = "";
        if (this.collections.hasOwnProperty('users') && this.collections.users.hasOwnProperty(id)) {
            userName = this.collections.users[id].username;
        }
        return userName;
    },
    getMessageTs: function (id) {
        return this.messagesTs.hasOwnProperty(id) ? this.messagesTs[id] : 0;
    },
    getChannelUrl: function (type, name) {
        if (type === this.CHANNEL_TYPE_DIRECT) {
            return '/direct/' + name;
        } else if (type === this.CHANNEL_TYPE_PRIVATE) {
            return '/group/' + name;
        } else {
            return '/channel/' + name;
        }
    },
    getChannelAvatar: function (type, name) {
        if (type === this.CHANNEL_TYPE_DIRECT) {
            return '/avatar/' + name;
        } else {
            return '/avatar/%40' + name;
        }
    },
    wasMentioned: function (mentions, id) {
        var res = false;
        for (var i = 0, si = mentions.length; i < si; i++) {
            if (id === mentions[i]._id) {
                res = true;
                break;
            }
        }
        return res;
    },
    updateChannelsList: function () {
        var arr = [];
        var unreadChannels = 0;
        for (var cid in this.channels) {
            this.channels[cid].url = this.getChannelUrl(this.channels[cid].type, this.channels[cid].name);
            this.channels[cid].avatar = this.getChannelAvatar(this.channels[cid].type, this.channels[cid].name);
            arr.push(this.channels[cid]);
            if (this.channels[cid].unread > (this.channelsUnread[cid] || 0)) unreadChannels++;
        }
        arr.sort(function (a, b) { var d = (b.unread - a.unread); return d ? d : (b.ts - a.ts); });
        this.bindChannelsList = arr;
        this.bindUnreadChannels = unreadChannels;
        this.bindUnreadMessages = 0;
        this.channelsUpdatedAt = Date.parse(new Date());
        this.onChannelsUpdated.call(this, this.bindChannelsList, this.bindUnreadChannels, this.bindUnreadMessages);
    },
    onChannelsUpdated: function (arr) {
        this.log("onChannelsUpdated", arr);
    },
    onChannelsUnread: function () {
    },
    onStatusChanged: function (status) {
        this.log("onStatusChanged", status);
    },
    onUsersUpdated: function (arr) {
    },
    onNewMessage: function (message) {
    },
    muteUnread: function () {
        for (var cid in this.channels) {
            this.channelsUnread[cid] = this.channels[cid].unread;
        }
        this.channelsUnreadAt = Date.parse(new Date());
        if (this.useLocalStorage) this.save();
        this.updateChannelsList();
    },
    reportError: function (error) {
        if (this.debugLevel) console.log("ERROR!", error);
    },
    log: function () {
        if (this.debugLevel) console.log.apply(null, arguments);
    },
    nope: function () {
    }
}

