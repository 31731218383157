import React, { FC, useEffect, SyntheticEvent, useMemo, useRef, memo, useCallback, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'localizations/i18n';
import { Box, Typography } from 'muicomponents/src';
import { styled } from '@mui/system';
import { YellowPurseBtn } from 'blocks/ShopMui/Item/YellowPurseBtn';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';


const RightBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    flex: 1,
}) as typeof Box

export const Wallet: FC<{}> = () => {
    const availableCurrency = useSelector(getAuthUser).extData.mythanksCount
    return <RightBox>
        <YellowPurseBtn />
        <Box>
            <Typography variant='body2'
                sx={{ textWrap: 'nowrap' }}
            // className={cnFiltersMui('headerAvailable')}
            >{i18n.t('pryaniky.list.shop.header.available')}</Typography>
            <Typography variant='body2' fontWeight='600' fontSize={'20px'}
            // className={cnFiltersMui('availableCurrency')} 
            >{availableCurrency}</Typography>
        </Box>
    </RightBox>
} 