import React, { FC, useState, useEffect } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'

import FormControlO from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import SoloAutocomplete from '@material-ui/lab/Autocomplete';
import { styled } from '@material-ui/core/styles';
import { controlStyle, peperStyle, controlLabelStyle } from './style'
import { zeroId } from 'LMSModule/utils/utils.lms'
import uuid from 'uuid/v1';
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { BadgeSelector } from 'uielements/src/BadgeSelector/BadgeSelector'
import { defaultBadge } from 'blocks/NewsTypes/Badges/Badges.type'
import { SkillsSuggester } from 'muicomponents/src/Suggester/type/SkillsSuggester';
import { TagsSuggester } from 'muicomponents/src';
import { useSelector } from 'react-redux';
import { getPrereleaseMode } from 'utils/src/CommonRedux/base/selectors';

const FormControl = styled(FormControlO)(controlStyle)
const FormControlLabelHelped = styled(fieldWithHelp(FormControlLabel))(controlLabelStyle)

export const Presenter: FC<IPresenterProps> = ({
    className,
    updateCourse,
    course,
    categories,
    catIds,
    fetchStatus,
    loadCategories,
    isCreate = false,
    loadBadges,
    badgesList
}) => {
    const prerelaseMode = useSelector(getPrereleaseMode);
    // const [currency, setCurrency] = useState(course.badgeIssueRule?.thanksCount || '')
    useEffect(() => {
        loadBadges()
        loadCategories()
    }, [])
    const changeText = (property: string) => (e: any) => {
        updateCourse({ ...course, [property]: e.target.value })
    }
    const changeArray = (property: string) => (value: any) => {
        updateCourse({ ...course, [property]: value })
    }
    const changeObject = (property: string) => (value: any) => {
        updateCourse({ ...course, [property]: value })
    }
    const changeCat = (event: any, value: any) => {
        updateCourse({ ...course, category: value, categoryId: catIds.find(id => categories[id].name === value) || zeroId })
    }
    const changeCheck = (property: string) => () => {
        updateCourse({ ...course, [property]: !course[property] })
    }
    const changeBadge = (badge: any) => {
        updateCourse({
            ...course,
            courseBadge: badge,
            badgeIssueRule: {
                "id": -1,
                "expanded": false,
                "pkid": -1,
                "ruleType": "AutomaticEvent",
                "bonus": 0,
                "rating": null,
                "thanksCount": 0,
                "event": {
                    "eventName": "lms_user_completed_session",
                    "eventType": "LMSCourse",
                    "eventParameters": {
                        "LMS_CourseId": course.id
                    }
                },
                "maxBadgesPerRule": 1,
                "users": null,
                "groups": null
            }
        })
    }

    const onChangeCurrency = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        // setCurrency(e.target.value)

        updateCourse({
            ...course,
            badgeIssueRule: {
                ...course.badgeIssueRule,
                thanksCount: e.target.value,
            }
        })
    }
    return <div className={cnClassName({}, [className])}>
        <FormControl fullWidth>
            <TextField
                label={tT('course_edit.course.name.1')}
                value={course.name}
                onChange={changeText('name')}
            />
        </FormControl>

        <FormControl fullWidth>
            <TextField
                label={tT('course_edit.course.description.1')}
                multiline
                value={course.description}
                onChange={changeText('description')}
            />
        </FormControl>

        <FormControl fullWidth>
            <Autocomplete
                fullWidth
                excludeMe={false}
                type={'users'}
                label={tT('course_edit.course.authors.1')}
                value={course.authors}
                onChange={changeArray('authors')}
            />
        </FormControl>

        <FormControl fullWidth>
            <SoloAutocomplete
                freeSolo
                onInputChange={changeCat}
                // disableClearable
                // onChange={changeCat}
                loading={fetchStatus === 'PENDING'}
                defaultValue={course.category || ''}
                options={catIds.filter(id => id !== zeroId).map(id => categories[id].name)}
                renderInput={(params: any) => <TextField {...params} label={tT('course_edit.course.category.1')} />}
            />
        </FormControl>

        {
            prerelaseMode &&
            <>
                <SkillsSuggester
                    value={course?.skills}
                    multiple
                    onChange={(e, value) => {
                        if(Array.isArray(value)) {
                            changeArray('skills')(value);
                        }
                    }}
                />
        
                <TagsSuggester
                    value={course?.searchTags}
                    multiple
                    onChange={(e, value) => {
                        if(Array.isArray(value)) {
                            changeArray('searchTags')(value);
                        }
                    }}
                />
            </>
        }

        <FormControl fullWidth >
            <BadgeSelector
                selectedBadge={course.courseBadge || defaultBadge}
                badgeList={{ badgeRenderParams: { badges: badgesList } }}
                onChange={changeBadge}
            />
        </FormControl>

        {course.courseBadge && <FormControl>
            <TextField
                label={tT('course_edit.course.virtcurrency.1')}
                type={'number'}
                value={course.badgeIssueRule?.thanksCount || 0}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={onChangeCurrency}
            />
        </FormControl>}


        {!isCreate && <div>
            <FormControlLabelHelped
                control={
                    <Checkbox
                        checked={course.isArchived}
                        onChange={changeCheck('isArchived')}
                        name="isArchived"
                        color="primary"
                    />
                }
                label={tT('course_edit.course.isArchived.1')}
                help={tT('course_edit.course.isArchived.title.1')}
            />
        </div>}
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
