/**
 * @packageDocumentation
 * @module ListAbstract
 */
import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import { mapDispatchToProps, IDispatchProps } from '../../redux/connector';
// import i18n from '../../localizations/i18n';

import {
  mapStateToProps,
  IListStateProps,
  cnList,
  IListProps,
  IListState
} from './List.index';
import './List.scss';
import { Redirect } from 'react-router';

const props_to_cut = ['changeVm', 'edit', 'dispatch', 'requestOptions']

export abstract class AbstractList<P extends IListProps, S extends IListState> extends React.Component<P, S | IListState> {
  public searchString: string;
  public request: any;
  public mounted: boolean;
  public redirectUrl: string;
  public type: string;
  public classMods: {[s: string]: any}

  constructor(props: P) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      selected: [],
      isFinished: false,
      isLoading: false,
      hideData: false,
      clearData: false,
      tab: undefined
    }
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
    this.clearStore();
  }

  public componentDidUpdate(pp: P, ps: any) {
    const { context, toggle, isLoading } = this.props;
    const { context: prevContext } = pp;
    if (
      (
        context.filter !== prevContext.filter
      )
      ||
      (
        (
          context.filter && prevContext.filter
        ) 
        && 
        context.filter.id !== prevContext.filter.id
      )
      ) {
      toggle({
        id: this.listId,
        data: {
          hideData: Boolean(context.filter),
          clearData: true,
          isFinished: false,
        }
      });
      // this.getData()
      setTimeout(() => this.getData(), 10);
    }
  }

  public render() {
    const { tag: TagName = 'div', children, className } = this.props;
    return (
      <TagName {...this.preparedProps} className={cnList({ type: this.type, ...this.classMods }, [className])}>
        {
          this.redirectUrl && this.redirectUrl !== '' && <Redirect to={this.redirect} />
        }
        {this.renderChildren()}
        {children}
      </TagName>
    );
  }

  protected clearStore = () => this.props.killList({id: this.id, data: undefined});

  protected get redirect(): string {
    const url = this.redirectUrl;
    this.redirectUrl = '';
    return url;
  }

  protected get dataVariable(): string {return this.state.hideData || this.state.filteredData.length || this.searchString ? 'filteredData' : 'data';}

  protected get listId(): string { return this.props.id as string || 'alone'; }
  
  protected get id(): string {return this.props.id as string || 'alone';} 

  protected abstract getData(resetOptions?: boolean): void;

  protected abstract renderChildren(): any

  private get preparedProps() {
    const props: any = Object.assign({}, this.props);
    props_to_cut.forEach(prop => delete props[prop])
    return props
  }
}

// export class ListPresenter extends AbstractList<IListProps, IListState> {
  // public static defaultProps = {
  //   dispatch: undefined,
  //   changeVm: undefined,
  //   edit: false,
  //   context: undefined,
  // };

  // constructor(props: IListProps) {
  //   super(props);
  //   this.state = {};
  // }

  // public render() {
  //   const customClassNames = 'rounded';
  //   const { tag: TagName = 'div', children, className = '' } = this.props;
  //   return (
  //     <TagName {...this.props} className={cnList({}, [customClassNames, className])}>
  //       {children}
  //     </TagName>
  //   );
  // }

//   public getData = () => []
// }

// export const List = connect<IListStateProps, IDispatchProps, IListProps>(
//   mapStateToProps,
//   mapDispatchToProps({})
// )(ListPresenter);
//   (compose(
//   withBemMod(cnList(), {})
// )(ListPresenter))
