import React, { useState, FC } from 'react'
import { CalendarEventLayout } from 'muicomponents/src/CalendarEventLayout/CalendarEventLayout'
import { getCalendarEventById } from 'utils/src/requests/requests.calendar'
import { useQuery } from 'utils/src/hooks'
import ViewComponent from 'muicomponents/src/FieldRender/viewComponent'
import AccessAlarms from '@mui/icons-material/AccessAlarms';
import { styled } from '@mui/material/styles';
import { workflows } from 'utils/src/requests/requests.news';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip'
import Typography from '@mui/material/Typography'
import { Link } from 'muicomponents/src/Link/Link'
// workflows.getUserStatuses
const Icon: any = styled(AccessAlarms)({
    color: '#C344BE'
})

export const WorkflowsEvent: FC<any> = ({ event }) => {
    return <>
        <AccessAlarms sx={{ color: `color-mix(in srgb, ${event.eventColor} 25%, white) !important`, marginRight: '8px' }} />
        <Tooltip overflowOnly title={`${event.title} - ${event.user.displayName}`}>
            <Typography
                sx={{ fontSize: '14px', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '22px' }}
            ><Link sx={{ color: `color-mix(in srgb, ${event.eventColor} 25%, white) !important`, textDecoration: 'none' }} href={event.url}>{event.title} - {event.user.displayName}</Link></Typography>
        </Tooltip>
    </>
}