import { IStateType } from 'redux/store';

export const getSecretSantaWidgetSettings = ( id: string)=>(state: IStateType) => getSettings(id)(state)?.widgetSettings;

export const getSecretSantaIsParticipate= ( id: string)=> (state: IStateType) => getSettings(id)(state)?.isParticipate;

export const getSecretSantaJoinInfo = ( id: string)=>(state: IStateType) => getSettings(id)(state)?.joinSecretSantaInfo;

export const getUserForPresentInfo = ( id: string)=>(state: IStateType) => getSettings(id)(state)?.userForPresent;

export const getIsPresentSend = ( id: string)=> (state: IStateType) => getSettings(id)(state)?.isPresentSend;

export const getPartisipantsList = ( id: string)=> (state: IStateType) =>getSettings(id)(state)?.participantsSantaList;

export const getSettings = ( id: string)=> (state: IStateType) => state.secretSantaWidget.widgetSettingsList[id] || undefined;
export const getListLengthSettings = (state: IStateType) => Object.keys(state.secretSantaWidget.widgetSettingsList).length ||undefined;
