import React, { FC, memo, useState, useCallback, useMemo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext, NewsContextProps } from '../../../../contexts/news'
import { Actions } from 'blocks/NewsView/Actions/Actions';
import { Like } from 'blocks/NewsView/Actions/Like/Like';
import { Thank } from 'blocks/NewsView/Actions/Thank/Thank';
import likersModal from 'blocks/Dialogs/News/Likers/NewsLikers';
import { Button } from 'uielements/src/Button/Button'//'uielements/src'
import { Icon } from 'uielements/src'
import { Bable } from 'blocks/Bable/Bable'
import ActionButton from '../../ActionButton'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import i18n from 'localizations/i18n';

type IPresenterProps = News.NewsActions.IPresenterProps
type IOwnProps = News.NewsActions.IOwnProps
type IDispatchProps = News.NewsActions.IDispatchProps
type IStateProps = News.NewsActions.IStateProps
type IState = News.NewsActions.IState

const isLikedClass = (liked: boolean) => liked ? 'heart-fill' : 'heart';
const noopObj: any = {}

export const Presenter: FC<IPresenterProps> = ({
    className,
    id,
    sendLikeNews,
    action,
    likeCount,
    liked
}) => {
    const onLike = useCallback(() => sendLikeNews({
        id, likeCount, liked
    }), [id, likeCount, liked])

    const onOpen = useCallback(() => likersModal({
        newsId: id,
        // replyId
    }), [id])

    const buttonProps = useMemo(() => ({ 'data-liked': liked }), [liked])

    return <ActionButton className={cnClassName('Like', [className])} action={action}>
        <Button theme='unstyled' onClick={onLike} {...buttonProps}>
            {window.PARAMS_change_news_like_icon ? <div className={cnClassName('customIcon', [isLikedClass(liked)])}/> : <Icon icon={isLikedClass(liked)} />}
            {i18n.t('like')}
        </Button>
        {
            likeCount !== 0 &&
            <Button theme='unstyled' className={'BtnLikeNum'} onClick={onOpen}>
                <Bable>{likeCount}</Bable>
            </Button>
        }
    </ActionButton>
}
export const Connected = connect<IStateProps, IDispatchProps, IOwnProps & NewsContextProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter))

export const Component = withNewsContext(Connected)
export default () => ComponentInjector.getInstance().addComponent('news_actions', Component, 'PRN_NEWS_LIKE_NEWS')

