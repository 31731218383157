import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ReactNode } from 'react';
///////////

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace TimelineHeader {

        export type IPresenterProps = IOwnProps

        export interface IOwnProps extends IClassNameProps {
            children?: ReactNode
            value: ReactNode
            isLoading?: boolean
            previous: () => void
            next: () => void
            disableNext?: boolean
            disablePrev?: boolean
            disableNavigation?: boolean
            totalCount?:number
            splitter?: ReactNode
        }
    }
}
export const cnClassName = cn('NewsTimelineHeader');