// import {User, BaseNews, defaultBaseNews,  defaultUser, defaultGroup, Group} from '../BaseType'
import { IBaseNews, createDefaultNews } from 'News/types/baseNews'
export interface AchievementsNews extends IBaseNews{
    newstype: 19;
}

export interface AchievementsNewsCreate extends AchievementsNews {
    achievement: Achievement;
}

export interface Achievement {
    id: number;
    isCompleteByMe: boolean;
    isFailedByMe: boolean;
    name: string;
    comment: string;
    popupdesc: string;
    infourl: string;
    pushed: boolean;
    type: number;
    bonus: number;
    popupShow: boolean;
    selected: boolean;
}

export const defaultAchievement: Achievement = {
    id: -1,
    isCompleteByMe: false,
    isFailedByMe: false,
    name: '',
    comment: '',
    popupdesc: '',
    infourl: '',
    pushed: false,
    type: 0,
    bonus: 0,
    popupShow: false,
    selected: false,
}


export const defaultAchievementsNews: AchievementsNews = {
    ...createDefaultNews(),
    newstype: 19,
}

export const defaultAchievementsNewsCreate: AchievementsNewsCreate = {
    ...defaultAchievementsNews,
    achievement: defaultAchievement
}