import { connect } from 'react-redux';
import React, { FC, useEffect } from 'react';
import {
    mapStateToProps, 
    // IButtonWithLoadStateProps, 
    // cnButtonWithLoad, 
    IButtonWithLoadProps, 
    mapDispatchToProps 
} from './ButtonWithLoad.index';
import './ButtonWithLoad.scss';
// import { zeroId } from 'LMSModule/utils/utils.lms';
import {tS} from 'LMSModule/utils/i18n.adaptor';

export const ButtonWithLoadPresenter: React.FC<IButtonWithLoadProps & any> = ({ isLoad, Component, disabled, ...props }) => {
    return <Component {...props} disabled={isLoad | disabled} title={isLoad && tS('loading.1')} />;
};

export const Connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ButtonWithLoadPresenter);

export const withLoad = (Component: React.ComponentType): FC<any> =>
    (props: any) => <Connected {...props} Component={Component} />


