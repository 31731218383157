import { GUID_EMPTY } from 'utils/src/constants.prn';
import { ItemBlockType, ItemRenderType } from 'utils/src/requests/models/api.menu';
import { IMenu } from './interfaces';

export const childrenFixIconBlocks = ['groups', 'wiki', 'tags'];

export const baseStructureItem: IMenu.StructureItem = {
  id: GUID_EMPTY,
  blockType: ItemBlockType.created,
  childrens: null
}

export const baseMenuItem: IMenu.Item = {
  id: GUID_EMPTY,
  actionType: null,
  addAuthData: false,
  blockType: ItemBlockType.created,
  i18nKey: '',
  icon: 'info',
  iconUrl: '',
  isDraggable: true,
  isEditable: true,
  isHidden: false,
  openInNewTab: false,
  parentId: GUID_EMPTY,
  renderType: ItemRenderType.link,
  showOnMobile: false,
  showOnlyForLanguages: null,
  title: '',
  url: '',
  groups: [],
  notViewedCount: 0,
  isAllowAddToFavorites: true,
  isInFavorites: false
}

export const baseService: IMenu.Service = {
  edit: false,
  edited: false,
  expanded: [],
  opened: false,
  hidden: false,
  backupStructure: [],
  loading: {
    menu: false,
    save: false
  }
}

export const baseReducer: IMenu.Reducer = {
  service: baseService,
  items: {},
  structure: []
}