import React, { useEffect } from 'react'
import { Avatar } from 'uielements/src'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { IQuiz, cnQuiz, mapDispatchToProps, mapStateToProps } from './Quiz.index'
import { QuizStart } from './QuizStart/QuizStart'
import { QuizPassing } from './QuizPassing/QuizPassing'
import { QuizFinish } from './QuizFinish/QuizFinish'
import i18n from 'localizations/i18n'
import './Quiz.scss'
import { QuizSkeleton } from 'blocks/Skeletons/Quiz/QuizSkeleton'
import { TypedListPlaceholder } from 'uielements/src'


const QuizPresenter = (props: IQuiz) => {

  const {
    getOpenQuiz,
    match, started,
    backgroundImgUrl,
    backgroundColorString,
    iconImgUrl,
    name,
    finished,
    loading,
    highlightCorrectness,
    isShowResult,
    rewardIsPosted,
    resetQuiz,
    isActive
  }: IQuiz = props

  useEffect(() => {
    resetQuiz()
    getOpenQuiz(match.params.id)
  }, [])

  let showHeader = Boolean(backgroundImgUrl)
  showHeader = Boolean(backgroundColorString) || showHeader

  return (
    <>
      {!loading ? <div className={`Widget ${cnQuiz()}`}>

        {isActive ? <>
          <div className={cnQuiz('Header')}>
            <div className={cnQuiz('Header-Title')}>
              <div className={cnQuiz('Header-Question')}>
                {name}
              </div>
            </div>
            {showHeader &&
              <div style={{ background: backgroundImgUrl ? `url("${backgroundImgUrl}") center` : backgroundColorString, backgroundSize: 'cover' }} className={cnQuiz('Header-Background')}></div>
            }
          </div>
    
          <div className={cnQuiz('Body')}>
            {(!started && !finished) && <QuizStart quizId={match.params.id} />}
            {started && <QuizPassing quizId={match.params.id} highlightCorrectness={highlightCorrectness} />}
            {finished && isShowResult && <QuizFinish />}
          </div>
        </>
          :
          <TypedListPlaceholder type='quiz' />
        }
      </div>

        :
        <QuizSkeleton />
      }
    </>
  )
}

export const Quiz = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QuizPresenter))
