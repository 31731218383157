import Component, { Presenter } from './component';
import {
    KPI,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = KPI.Main.IOwnProps;
export type IComponentPresenterProps = KPI.Main.IPresenterProps;


export default Component