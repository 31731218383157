import React from 'react';
import { cnSecretSantaDialog, ISecretSantaDialogData, ISecretSantaDialogProps, mapSecretSantaDispatchToProps, mapSecretSantaStateToProps } from './SecretSantaParticipate.index';
import {
    // DialogTitle,
    // DialogActions,
    // DialogContent,
    ErrorMsg,
    Spinner
} from 'uielements/src';
import i18n from 'localizations/i18n';
import { Translate } from '../../../localizations/Translate';
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import Input from 'uielements/src/MaterialElements/Input/Input';
import { InputAdornment } from '@material-ui/core';
import './SecretSantaParticipate.scss';
import LocationOn from '@material-ui/icons/LocationOn';
import Phone from '@material-ui/icons/Phone';
import { lightMarkdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap';
import PryanikyEditor from 'uielements/src/PryanikyEditorV1';
import { PryanikyEditorMarkdown } from 'uielements/src/PryanikyEditorV1/markdown';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { NodeBulder } from 'uielements/src/CommonmarkRender'
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { useDebounce } from 'utils/src/hooks';
import { rawToMd } from 'uielements/src/PryanikyEditorV1/converter/convertorConfigs';
import { connect } from 'react-redux'
import { HelpTooltip, Icon } from 'uielements/src/index';
import * as utils from 'utils/src/utils';
import MaskedInput from 'react-text-mask';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { markdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap';
import { DraftInput, useDraftEdit } from 'muicomponents/src/DraftInput/DraftInput';
import { AlertMessage } from 'muicomponents/src/AlertMessage/AlertMessage';
import { useDidUpdateEffect } from 'utils/src/hooks';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const SecretSantaDialogPresenter: React.FC<ISecretSantaDialogProps> = ({
    joinInfo,
    isPartitipant,
    secretSantaData,
    id,
    handleAccept,
    handleClose,
    secretSantaJoinInfoAction,
    secretSantaUnJoinAction,
    getMyInfo,
    isUserParticipateAction
}) => {
    const {
        state,
        draftState,
        setDraftState
    } = useDraftEdit(joinInfo?.deliveryComment || '');

    useDidUpdateEffect(() => {
    }, [draftState]);

    useDidUpdateEffect(() => {
        secretSantaJoinInfoAction({ data: { deliveryComment: state.text }, id })
        // chageDescriptionData
    }, [state]);

    if (joinInfo === undefined) return null
    return (
        <div className={cnSecretSantaDialog()}>
            <DialogTitle onClose={handleClose} className={cnSecretSantaDialog('Header')}>
                {/* <h4 className="my-1"> */}
                <Translate i18nKey={`pryaniky.secret.santa.dialog.title`} />
                {/* </h4> */}
            </DialogTitle>
            <DialogContent className={cnSecretSantaDialog('Body')}>
                <div className={cnSecretSantaDialog('Body-Items')}>

                    <Autocomplete
                        freeSolo
                        value={joinInfo.deliveryAddress || ""}
                        onInputChange={(_, delAdress) => secretSantaJoinInfoAction({ data: { deliveryAddress: delAdress || '' }, id })}
                        options={secretSantaData?.suggestedAddreses || []}
                        renderInput={(params) => <TextField
                            {...params}
                            variant="outlined"
                            label={Translate.t({ i18nKey: 'pryaniky.secret.santa.dialog.adresse' })}
                        />}
                    />

                    {/* <Input
                        type={'text'}
                        value={joinInfo.deliveryAddress || ""}
                        onChange={(delAdress) => secretSantaJoinInfoAction({ data: { deliveryAddress: delAdress }, id })}
                        variant="outlined"
                        placeholder={Translate.t({ i18nKey: 'pryaniky.secret.santa.dialog.adresse' })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <LocationOn />
                                </InputAdornment>
                            ),
                        }}

                    /> */}

                    <Input
                        type={'text'}
                        value={joinInfo.deliveryPhone || ""}
                        className={cnSecretSantaDialog('deliveryPhone')}
                        onChange={(delPhone) => secretSantaJoinInfoAction({ data: { deliveryPhone: delPhone }, id })}
                        placeholder={Translate.t({ i18nKey: 'pryaniky.secret.santa.dialog.phone' })}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Phone />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div className={cnSecretSantaDialog('Body-Letter')}>
                        {/* <form> */}
                        <DraftInput
                            sx={{
                                ".MuiInputLabel-outlined": {
                                    zIndex: '10'
                                }
                            }}
                            label={<Translate i18nKey={'pryaniky.secret.santa.dialog.letter'} />}
                            value={draftState}
                            enabledModules={{
                                header: false,
                                list: false,
                                bold: false,
                                italic: false,
                                undeline: false,
                                editLink: true,
                                hashTag: false,
                                userMantion: false,
                                emoji: false,
                                findUsers: false,
                                media: false,
                                video: false,
                            }}
                            onChange={setDraftState}
                            minHeight={'112'}
                        />
                        {/* </form> */}
                    </div>

                    {secretSantaData?.disclaimerText && <div className={cnSecretSantaDialog('Body-Letter')}>
                        <AlertMessage severity={'info'} variant='filled'>{secretSantaData?.disclaimerText}</AlertMessage>
                    </div>}
                </div>
            </DialogContent>
            <DialogActions
                acceptText={i18n.t('save')}
                closeText={i18n.t('cancel')}
                disableAccept={joinInfo.deliveryAddress === ""}// || joinInfo.deliveryPhone === ""}
                onAccept={() => handleAccept(joinInfo)}
                onClose={() => {
                    if (isPartitipant && secretSantaData) {
                        getMyInfo(id)
                    }
                    handleClose()
                }}

            />
        </div>
    )
}


export const SecretSantaModal = connect(
    mapSecretSantaStateToProps,
    mapSecretSantaDispatchToProps
)(SecretSantaDialogPresenter)

export const secretSantaDialog = createModal(SecretSantaModal, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: 'transparent',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});