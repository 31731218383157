import { bindActionCreators, Action, Dispatch } from "redux"
import { IBodyProps } from 'utils/src/DialogCreator';
import { cn } from '@bem-react/classname';
import { IComponentAcceptor } from 'utils/src/ComponentInjector'
import { IWSettingsOnlyProps } from '../../../../WSettings/WSettings.index';
import { getActiveStructure, getActiveStructureWidgets } from 'redux/actions/Widgets';
import { IWidget, IColumn } from 'i.widgets';
import { modalChangeDate } from 'redux/actions/Modals';

export type IWidgetsAddSettingsDialogStateProps = ReturnType<typeof WidgetsAddSettingsDialogMapStateToProps>;

export interface IWidgetsAddSettingsDialogProps extends IWidgetsAddSettingsDialogOwnProps, IComponentAcceptor<IWSettingsOnlyProps>, IWidgetsAddSettingsDialogDispatchProps, IWidgetsAddSettingsDialogStateProps {
    addToGrid?: any;
    columnId?: any;
    data: any;
    item: any;
}


export interface IWidgetsAddSettingsDialogOwnProps extends IBodyProps {
}

export interface IWidgetToSelect {
    schema: string;
    title: string;
    type: string;
    id: string;
}

export interface IWidgetsAddSettingsDialogState {
    data?: any;

}

export const WidgetsAddSettingsDialogMapStateToProps = (state: any, props: IWidgetsAddSettingsDialogOwnProps) => {
    return {
        enabledModules: state.store.appSettings && state.store.appSettings.enabledModules,
    };
};

export type IWidgetsAddSettingsDialogDispatchProps = ReturnType<typeof mapWidgetsAddSettingsDialogDispatchToProps>

export const mapWidgetsAddSettingsDialogDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    getActiveStructure,
    getActiveStructureWidgets,
    modalChangeDate
}, dispatch)


export const cnWidgetsAddSettingsDialog = cn('WidgetsAddSettingsDialog');