import Component, { Presenter } from './component';
import {
    LerningTrack,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = LerningTrack.ListItem.IOwnProps;
export type IComponentPresenterProps = LerningTrack.ListItem.IPresenterProps;


export default Component