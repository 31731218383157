import { bindActionCreators } from 'redux';
import { selectFile, deleteFile, moveFile, setFolder, renameFolder } from "../../../redux/actions/Files";
import { cn } from '@bem-react/classname';
import { RouteComponentProps } from 'react-router';
export interface IProps extends BaseItemProps, IItemtateProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    
}

export interface BaseItemProps {
    file: any;
    viewOpen?: () => void;
    showMove: () => void;
    showControls?: boolean;
}
export interface IItemtateProps {
    file: any;
    selected: boolean;
    activeMoveng: boolean;
}

export const mapStateToProps = (state: any, props: BaseItemProps) => {
    return {
        // file: state.photoalbums.photos[props.aid].files[props.fid],
        activeMoveng: state.files.groups[props.file.groupId].selected.length > 0,
        selected: state.files.groups[props.file.groupId].selected.find((fid: string) => fid === props.file.id)
    }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    deleteFile,
    selectFile,
    moveFile,
    setFolder,
    renameFolder
}, dispatch);

export const cnFileItem = cn('FileItem');