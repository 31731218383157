import React, {FC} from 'react'
import './Removed.scss'
import { ListPlaceholder } from 'uielements/src';
import feedPlaceholder from 'assets/svg/feedPlaceholder.svg';
import { ComponentInjector } from 'utils/src/ComponentInjector'
export const Removed: FC<any> = ({}) => (
    <div>
        <ListPlaceholder
            // key={uuid()}
            imageSrc={feedPlaceholder}
            // className={cnTimeline('Placeholder')}
            title={''}
            text={'К сожалению, данная новость была удалена'}
        />
    </div>
)

//16001


export const initRemoved = () => ComponentInjector.getInstance().addNode('news_types', <Removed />, 'removed_placeholder')