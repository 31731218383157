import React, { FC, useEffect, useState } from 'react'
import { cnTabs, mapDispatchToProps, mapStateToProps } from './Tabs.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Tabs.scss'
// import { Button } from 'uielements/src';
import { Link } from 'react-router-dom';
import { linkCreator, zeroId } from 'LMSModule/utils/utils.lms';
import queryString from 'query-string';
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'


const defaultSelected = 'users';
export const TabsPresenter: FC<any> = ({ className, location, tabs, defaultVal = defaultSelected }) => {
    tabs = tabs || [
        {
            value: 'users',
            title: tT('course_info.tabs.users.1'),
            id: 'users'
        }, {
            value: 'sessions',
            title: tT('course_info.tabs.sessions.1'),
            id: 'sessions'
        }, {
            value: 'answers',
            title: tT('course_info.tabs.answers.1'),
            id: 'answers'
        }, {
            value: 'discussion',
            title: tT('course_info.tabs.discussion.1'),
            id: 'discussion'
        },
        {
            value: 'statistic',
            title: tT('course_info.tabs.statistic.1'),
            id: 'statistic'
        },
        {
            value: 'edit',
            title: tT('course_info.tabs.edit.1'),
            id: 'edit'
        },
        {
            value: 'comments',
            title: tT('course_info.tabs.comments.1'),
            id: 'comments'
        }
    ];
    const requestParams = queryString.parse(location.search)
    const current = requestParams.tab;
    return <div className={cnTabs({}, [className])}>
        {tabs.map((tab: any) => <Link
            key={tab.value}
            to={`?tab=${tab.value}`}//{linkCreator('tab', defaultVal)((current === tab.value || (!current && defaultVal === tab.value)), tab, [current], false)}
            className={cnTabs('Button', { active: (current === tab.value || (!current && defaultVal === tab.value)) }, ['Button_type_rlink'])}
        >
            {tab.title}
        </Link>)}

    </div>
}

export const Tabs = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TabsPresenter));
