import { cnInfoBlock } from '../InfoBlock.index';
import { IInfoProps } from '../AbstarctInfoElement/AbstractInfoElement.index';
import { updateContext } from 'redux/actions/Widgets';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getGroupTitle } from 'redux/actions/Titles';
import { loadGroup } from 'redux/actions/Groups'

export interface IGroupProps extends IInfoProps, IActionsDispatch {
  groupId: string;
  gotGroupInfo: any;
}

export interface IGroupState {
  text?: string;
}

export interface IGroupStateProps {
  store: any;
  appSettings: any;
}

export const mapStateToProps = (state: any) => ({
  store: {
    ...state.store,
    authUser: getAuthUser(state)
  },
  appSettings: state.store.appSettings,
})

export interface IActionsDispatch {
  updateContext?: any;
  getGroupTitle?: any;
  loadGroup?: any;
}

export const mapActionsToProps: IActionsDispatch = {
  updateContext,
  getGroupTitle,
  loadGroup
};

export const cnGroup = (...data: any) => {
  let name = 'Group';
  if (typeof (data[0]) === 'string') {
    name += '-' + data[0];
    data = data.slice(1);
  }
  return cnInfoBlock(name, ...data)
}

export const cnInfo = cnInfoBlock;
