import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { withIndicator } from 'utils/src/CommonRedux/LoadIndicator'
import actionsTypes from '../../../../../redux/actionsTypes/LMSTO'
import { Button, Icon } from 'uielements/src';
import Item from './Item'
import ListPlaceholder from 'uielements/src/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import LinearProgress from '@material-ui/core/LinearProgress';
import { useScrollList } from 'LMSModule/utils/hooks'
import { useLazyQuery } from 'utils/src/hooks'
import { API } from 'utils/src/utils'
import { pick } from 'lodash'
import { tSt } from 'LMSModule/utils/i18n.adaptor'
import { SearchInput } from 'uielements/src';

const LoadButton = withLoadSpin(Button)

type IPresenterProps = StatisticsFull.UsersList.IPresenterProps
type IOwnProps = StatisticsFull.UsersList.IOwnProps
type IDispatchProps = StatisticsFull.UsersList.IDispatchProps
type IStateProps = StatisticsFull.UsersList.IStateProps
type IState = StatisticsFull.UsersList.IState



export const Presenter: FC<IPresenterProps> = ({
    className,
    getUserStatInfoList,
    isLoading = false,
    settings,
    pathFullStatisticSetting,
    keys,
    withError = false,
    isFinished,
    count,
    userGUID,
    // userId
}) => {

    const { loadMore, triggerRef } = useScrollList(
        isLoading,
        isFinished,
        withError,
        [settings, userGUID],
        (deps) => (clean = false) => {
            getUserStatInfoList({
                userId: deps.current.userGUID,
                skipCount: deps.current.keys.length,
                count: deps.current.count,
                params: pick(deps.current.settings, 'countArchive', 'isMandatory', 'endDate', 'startDate', 'search')
            }, clean)
        },
        { settings, keys, count, userGUID }
    )

    const onChangeSearch = (search: string) => {
        pathFullStatisticSetting({
            search
        })
    }

    const [getExcel, excelLoading] = useLazyQuery(() => API.lms.getCoursesCertainUserExcel(userGUID))

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Header')}>
            <div className={cnClassName('SearchBox')}>
                <SearchInput
                    loading={isLoading}
                    value={settings.search}
                    className={cnClassName('Search')}
                    onChange={onChangeSearch} />
            </div>
            <div className={cnClassName('Actions')}>
                <LoadButton noPadding isLoading={excelLoading} onClick={getExcel} className={cnClassName('ActionBtn')}>
                    <Icon icon={"file-excel"} />
                </LoadButton>
            </div>
        </div>

        <ListPlaceholder
            className={cnClassName('Placeholder')}
            isFinished={isFinished}
            isError={withError}
            textError={<Button
                className={cnClassName('ErrorTrigger')}
                onClick={loadMore}
            >
                {tSt('load.again')}
                {/* пробовать ещё */}
            </Button>}
            titleError={tSt('load.error.title')}//{'Ошибка получения данных'}
            isLoading={isLoading}
            itemsCount={keys.length}
            titleEmpty={tSt('load.empty.title')}//{'Пусто'}
            textEmpty={tSt('load.empty.text')}//{'Когда ни будь, что ни будь'}
            textLoad={tSt('load.holder.text')}//{'Загрузка...'}
        >
            {isLoading && <LinearProgress className={cnClassName('Progress')} />}

            <table className={cnClassName('Table', { isLoading })}>
                <colgroup>
                    <col span={1} className={cnClassName('Col1')} />
                    <col span={1} className={cnClassName('Col2')} />
                    <col span={1} className={cnClassName('Col4')} />
                    <col span={1} className={cnClassName('Col5')} />
                    <col span={1} className={cnClassName('Col6')} />
                    <col span={1} className={cnClassName('Col7')} />
                    <col span={1} className={cnClassName('Col8')} />
                </colgroup>
                <thead>
                    <tr>
                        <th>
                            {tSt('th.course.name')}
                            {/* Название курса */}
                        </th>
                        <th>
                            {tSt('th.teacher')}
                            {/* Преподаватель */}
                        </th>
                        <th>
                            {tSt('th.date.start')}
                            {/* Дата начала */}
                        </th>
                        <th>
                            {tSt('th.date.end')}
                            {/* Дата конца */}
                        </th>
                        <th>
                            {tSt('th.delay')}
                            {/* Срок прохождения */}
                        </th>
                        <th>
                            {tSt('th.status')}
                            {/* Статус прохождения */}
                        </th>
                        <th>
                            {tSt('th.progress')}
                            {/* Прогресс */}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {keys.map(id => <Item key={id} id={id} className={cnClassName('Row')} />)}
                </tbody>
            </table>

            {!isFinished && (
                <LoadButton
                    forwardedRef={triggerRef}
                    isLoading={isLoading}
                    className={cnClassName('Trigger')}
                    onClick={loadMore}
                >
                    {tSt('load.more')}
                    {/* {'Ещё'} */}
                </LoadButton>)}
        </ListPlaceholder>


    </div>
}

export default withIndicator(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter))), actionsTypes.GET_USER_STAT_LIST)();
