import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { withRouter } from "react-router"
import WidgetPresenter from '../Widget';
import { withBemMod } from '@bem-react/core';
import { Photoalbums } from 'blocks/Photoalbums/Photoalbums'
import { cnWidget } from '../Widget.index';
import { IWidgetTypeCalendarMiniProps } from './Widget_type_calendarmini.index';
import './Widget_type_calendarmini.scss';
import { RouteComponentProps } from 'react-router';
import { AlbumView } from 'blocks/Photoalbums/AlbumView/AlbumView'
import { PhotoView } from 'blocks/Photoalbums/PhotoView/PhotoView'
import { widgets } from 'i.widgets';
import queryString from 'query-string';
import { CalendarMini } from 'blocks/CalendarMini/CalendarMini';
const type = 'calendarmini';

class WidgetTypeCalendarMiniPr extends WidgetPresenter<IWidgetTypeCalendarMiniProps> {
  public render() {

    if (!this.props.widget) return null;
    //  console.log("this.props", this.props)
    return (
      <widgets.components.common {...this.props} className={cnWidget({ type })}>
        <CalendarMini {...this.props} isEdit={this.props.edit} settings={this.props.widget.settings} widgetContext={this.props.wcontext}/>
      </widgets.components.common>
    );
  }
}

export default WidgetTypeCalendarMiniPr