import { zeroId } from 'LMSModule/utils/utils.lms'
import { CreateTrackParams } from 'utils/src/requests/models/api.learningTracks'

export const trackModelCreator = (tagName = ''): CreateTrackParams => (
    {
        // tagId: uuid(),
        tagName,
        // tagUrl: 'testtrack',
        isActive: true,
        // timestamp: (new Date()).toISOString(),
        // isAnchorOnMenu: false,
        // publicationsCount: 0,
        // collectionId: 0,
    }
)