import Component, { Presenter } from './component';
import {
    StatisticsFull,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = StatisticsFull.StatView.IOwnProps;
export type IComponentPresenterProps = StatisticsFull.StatView.IPresenterProps;


export default Component