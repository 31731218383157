import { removeUserFromArray, addUserToArray } from '../NewsUtils'
import { Answer, Poll } from './Polls.types';
import * as utils from 'utils/src/utils';

export const lenMyAnswers = (answers: Answer[]) => {
    let num = 0;
    answers.forEach((val) => {
        if (val.answeredByMe) num++;
    });
    return num;
}

export const answerChange = (answer: Answer, poll: Poll, answers: Answer[], user: any) => {
    const cloneAnswers: Answer[] = utils.cloneObject(answers)

    let isNewMax = 0;//poll.maxCount;
    cloneAnswers.forEach((item) => {
        if (item.id === answer.id) {
            item.answeredByMe = !item.answeredByMe
            if (item.answeredByMe) {
                item.count += 1;
                item.participants = addUserToArray(item.participants, user)
            } else {
                item.count -= 1;
                item.participants = removeUserFromArray(item.participants, user)
            }
        } else if (poll.answerVariants === 1) {
            if (item.answeredByMe) item.count -= 1;
            item.answeredByMe = false;
            item.participants = removeUserFromArray(item.participants, user)
        }
        if (item.count > isNewMax) isNewMax = item.count;
    })

    return { answers: cloneAnswers, maxCount: isNewMax };
}