import { cnInfoBlock } from '../InfoBlock.index';

import { IInfoProps } from '../AbstarctInfoElement/AbstractInfoElement.index';

export interface ITagProps extends IInfoProps {
    tId: string;
}

export interface ITagState {
    text?: string;
}

export interface ITagStateProps {
    store: any
}

export const mapStateToProps = (state: any) => ({
    store: state.store
})

export const cnTag= (...data: any) => {
    let name = 'Tag';
    if (typeof (data[0]) === 'string') {
        name += '-' + data[0];
        data = data.slice(1);
    }
    return cnInfoBlock(name, ...data)
}

export const cnInfo = cnInfoBlock;