import { AlbumType } from './Album/Album.type'
import { IAttachmentType } from 'uielements/src/Preview/Preview.index'
import { API } from 'utils/src/utils';
import uuid from 'uuid/v1';
import file_jpg from 'assets/jpg/file.jpg';
export interface IPhotoType extends IAttachmentType {
    date: Date;
}

export const createImgUrl = (id: string, name: string) => {
    return `${API.base.requestDomain}/ru/Data/v3/Attachment/${id}/${name}`;
}

export const fileModelConverter = (src: AlbumType): IPhotoType => {
   
    return {
        id: src.id,
        originalUrl: `${API.base.requestDomain}/ru/Data/v3/Attachment/${src.id}/${src.name}`,
        thumbUrl: `${API.base.requestDomain}/ru/Data/v3/Attachment/${src.id}/${src.name}`,
        previewUrl: src.previewUrl,//`${API.base.requestDomain}/ru/Data/v3/Attachment/${src.id}/${src.name}`,
        pdfPreviewUrl: `${API.base.requestDomain}/ru/Data/v3/Attachment/${src.id}/${src.name}`,
        name: src.name,
        action: src.previewAction,
        previewAction: src.previewAction,
        size: 0,
        date: src.date
    };
}


export const createFile = (src: any, name: string, id: string = uuid(), action?: string) => {
    return {
        id,
        originalUrl: src,
        thumbUrl: src,
        previewUrl: src,
        pdfPreviewUrl: src,
        name: name,
        action: action || 'ImagePreview',
        previewAction: action || 'ImagePreview',
        size: 0
    }
}


export const createAlbum = (gid: number, name: string) => {
    return {
        date: (new Date).toISOString(),
        displayUserName: "",
        fileId: "00000000-0000-0000-0000-000000000000",
        groupId: gid,
        groupName: null,
        highlight: null,
        id: uuid(),
        isFolder: true,
        isLastVersion: false,
        itemsCount: 0,
        name,
        newsUid: [],
        parentFolder: "00000000-0000-0000-0000-000000000000",
        previewAction: "None",
        previewImgId: "00000000-0000-0000-0000-000000000000",
        previewUrl: file_jpg,
        urlname: null,
        userId: "00000000-0000-0000-0000-000000000000",
        version: 0,
        versions: null,
    }
}