import React, { FC, useEffect, useState } from 'react'
import { ITestProps, mapDispatchToProps, mapStateToProps, cnSkipedTest } from './Test.index'
import './Test.scss'
import { connect } from 'react-redux';
import { Spinner } from 'uielements/src'
import { tS } from 'LMSModule/utils/i18n.adaptor'

const TestPresenter: FC<any> = ({ unit, uid, sid, qid, jumpToUnit, loadFullUnit }) => {
    useEffect(()=> {
        !unit && loadFullUnit(uid)
    })
    if (!unit) return <div className={cnSkipedTest({load: true})} >
        <Spinner className={cnSkipedTest('Spinner')}/>
    </div>

    const allowSkip = unit.skipUnitType === 1;
    return <div className={cnSkipedTest()} onClick={() => { jumpToUnit(unit.id, unit.courseId)}}>
        <h4 className={cnSkipedTest('Header')} >{unit.name}</h4>
        <div className={cnSkipedTest('Required', { require: !allowSkip })} >{allowSkip ?
            <span>{tS('noMandatory.1')}</span> : <span>{tS('mandatory.1')}</span>}
        </div>
    </div>
}

export const Test = connect(
    mapStateToProps,
    mapDispatchToProps
)(TestPresenter);