import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { setContext, updateContext } from 'redux/actions/Widgets';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface IUserProps extends IClassNameProps, IUserStateProps, IDispatchProps, IActionsDispatch, RouteComponentProps<IUserUrlParams> {
  tag?: 'div';
}

export interface IUserUrlParams {
  id: string;
  tab?: string;
}

export interface IUserState {
  text?: string;
  data?: {[s: string]: any};
}

export interface IActionsDispatch {
  setContext?: any;
  updateContext?: any;
}

export const mapActionsToProps: IActionsDispatch = {
  setContext,
  updateContext,
};

// export interface IUserStateProps {
//   authUser: { [s: string]: any };
//   context: { [s: string]: any };
// }

export type IUserStateProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: any) => ({
  authUser: getAuthUser(state),
  context: state.widgets.contexts
})

export const cnUser = cn('User');