/**
 * @packageDocumentation
 * @module Widget_Settings_timeline
 */
import { JFdictionary } from "../dictionary";
import i18n from '../../../../localizations/i18n';

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.groupsInput,
      scope: "#/properties/group"
    },
    {
      type: renderers.number,
      scope: "#/properties/countView"
    },
    {
      type: renderers.input,
      scope: "#/properties/name"
    },
    {
      type: renderers.usersInput,
      scope: "#/properties/users"
    },
    {
      type: renderers.checkbox,
      label: i18n.t('pryaniky.widgets.settings.type.shop.noData'),
      scope: "#/properties/hideIfNoData"
    },
  ]
}