import actions from 'redux/actionsTypes/AppSettings';

/********************************************************/
/******************** to saga ***************************/
/********************************************************/

/************************* LOAD_COMPANY_POST_TYPES *******************************/
// export type loadCompanyPostTypesPayloadType = { groupPKID: number | string }
export type loadCompanyPostTypesActionType = { type: string/*, payload: loadCompanyPostTypesPayloadType*/ }
/**
 * запрашиваем доступные в компании типы публикаций
 */
export const loadCompanyPostTypes = (): loadCompanyPostTypesActionType => ({
    type: actions.LOAD_COMPANY_POST_TYPES,
})


/************************* LOAD_ALLOW_POST_TYPES *******************************/
export type loadAllowPostTypesPayloadType = { groupPKID: number | string, workflowId?: string, newsType?: string }
export type loadAllowPostTypesActionType = { type: string, payload: loadAllowPostTypesPayloadType }
/**
 * получить с сервера список доступных пользователю типов публикаций
 * * @param groupPKID
 */
export const loadAllowPostTypes = (groupPKID: number | string = -1, workflowId?: string, newsType?: string): loadAllowPostTypesActionType => ({
    type: actions.LOAD_ALLOW_POST_TYPES,
    payload: {
        groupPKID,
        workflowId,
        newsType
    }
})



/************************* GET_ALLOW_GROUP_TRANSFER *******************************/

export interface IgetAllowGroupsTransferAction {
    type: typeof actions.GET_ALLOW_GROUP_TRANSFER
}

export const getAllowGroupsTransfer = (): IgetAllowGroupsTransferAction => ({
    type: actions.GET_ALLOW_GROUP_TRANSFER
})




/***********************************************************/
/******************** to reducer ***************************/
/***********************************************************/

/************************ ADD_COMPANY_POST_TYPES ********************************/
export type addCompanyPostTypesPayloadType = { postTypes: any }
export type addCompanyPostTypesActionType = { type: string, payload: addCompanyPostTypesPayloadType }
/**
 * добавлет доступные типы публикаций в стор
 * @param postTypes
 */
export const addCompanyPostTypes = (postTypes: any): addCompanyPostTypesActionType => ({
    type: actions.ADD_COMPANY_POST_TYPES,
    payload: { postTypes }
})


/************************ ADD_COMPANY_POST_TYPES ********************************/
export type addAllowPostTypesPayloadType = { postTypes: any, groupPKID: number | string, workflowId?: string, newsType?: string }
export type addAllowPostTypesActionType = { type: string, payload: addAllowPostTypesPayloadType }
/**
 * поместить в стор список доступных пользователю типов публикаций
 * @param postTypes
 * @param groupPKID
 */
export const addAllowPostTypes = (postTypes: any, groupPKID: number | string = -1, workflowId?: string, newsType?: string): addAllowPostTypesActionType => ({
    type: actions.ADD_ALLOW_POST_TYPES,
    payload: { postTypes, groupPKID, workflowId, newsType }
})


export type setAllowPostErrorPayloadType = { error: any, groupPKID: number | string, workflowId?: string, newsType?: string } 
export type setAllowPostErrorActionType = { type: string, payload: setAllowPostErrorPayloadType | null }
/**
 * 
 * @param error
 */
export const setAllowPostError = (error: any, groupPKID: number | string = -1, workflowId?: string, newsType?: string): setAllowPostErrorActionType => ({
    type: actions.SET_COMPANY_POST_ERROR,
    payload: error ? ({ error, groupPKID, workflowId, newsType }) : null
})




/********************* SET_ALLOW_GROUP_TRANSFER ******************************/

export interface IsetAllowGroupsTransferAction {
    type: typeof actions.SET_ALLOW_GROUP_TRANSFER,
    payload: boolean
}

export const setAllowGroupsTransfer = (payload: boolean): IsetAllowGroupsTransferAction => ({
    type: actions.SET_ALLOW_GROUP_TRANSFER,
    payload
})