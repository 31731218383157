import { UeventNews } from '../EventsType'
import { IClassNameProps } from '@bem-react/core';
import { IStateType as IState } from 'redux/store';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
export interface IEditorProps extends IClassNameProps {
    data: any;
    expiresChange: (date: Date) => void;
    durationChange: (selected: any) => void;
    onChangeLocation: (e: any) => void;
    onChangeTitle: (e: any) => void;
    onChangeText: (text: string) => void
    onChangeUsers?: (users: any[]) => void;
    onChangeVideoConference?: (online: boolean) => void;
    onChangeIsHidden?: () => void
    onChangeEnableWaitList?: () => void
    calendarChange?: (selected: any) => void;
    onChangeAllowParticipate?: (selected: any) => void;
    onChangeMaxPartisipantsCount: (count: number | null) => void;
    allowCreateVideoConference?: boolean;
    groupId?: number
}

export const mapEditorStateToProps = (state: IState) => ({
    isAdmin: getCurrentUser(state).baseData.isAdmin
})

export type IMapEditorStateToProps = ReturnType<typeof mapEditorStateToProps>