import * as React from 'react';
import * as utils from 'utils/src/utils';
import {
    IHtmlWidgetProps,
    cnHtmlWidget,
    mapStateToProps,
    IHtmlWidgetState,
    mapDispatchToProps
} from './HtmlWidget.index';
// import { Editor } from '../Editor/Editor';
import { Button, Icon } from 'uielements/src/index'
import { PostContentRender } from '../PostContentRender/PostContentRender'
import i18n from '../../localizations/i18n';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { PryanikyEditor } from 'blocks/PryanikyEditor/_PryanikyEditor'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
// import { mdToRaw, rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
// import { draftToMarkdown, markdownToDraft } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import './HtmlWidget.scss';
import { convertToHTML, convertFromHTML } from 'draft-convert';

const fromHtml: any = {
    htmlToStyle: (nodeName: any, node: HTMLElement, currentStyle: any): any => {
        let ret: any = currentStyle;
        if (nodeName === 'span' && node.style.color) {
            ret = ret.add(`COLORED_${node.style.color}`);
        }
        if (node.classList && node.classList.contains(`alignment--center`)) ret = ret.add('center');
        if (node.classList && node.classList.contains(`alignment--left`)) ret = ret.add('left');
        if (node.classList && node.classList.contains(`alignment--right`)) ret = ret.add('right');
        if (node.classList && node.classList.contains(`alignment--justify`)) ret = ret.add('justify');
        return ret;
    },
    htmlToEntity: (nodeName: any, node: any, createEntity: any) => {
        if (nodeName === 'div' && node.classList && node.classList.contains(`imgBox`)) {
            let alignment = ''
            

            const imgNode = node.querySelector("img") || node;
            const imgNodes = node.querySelectorAll("img");
            const imgArray = Array.prototype.slice.call(imgNodes); 
            const images = imgArray.map((val: any) => {
                if (val.classList && val.classList.contains(`img-center`)) alignment = 'center';
                if (val.classList && val.classList.contains(`img-left`)) alignment = 'left';
                if (val.classList && val.classList.contains(`img-right`)) alignment = 'right';
                return {
                    src: val.src,
                    alt: val.getAttribute('alt'),
                    width: Number.parseInt(val.style.width),
                    link: val.getAttribute('data-href'),
                }
            })

            return createEntity(
                'IMAGE',
                'MUTABLE',
                {
                    // src: imgNode.src,
                    // alt: imgNode.getAttribute('alt'),
                    // width: Number.parseInt(node.style.width),
                    // link: imgNode.getAttribute('data-href'),
                    alignment,
                    images
                }
            )
        } /*else if (nodeName === 'img') {
            return createEntity(
                'IMAGE',
                'MUTABLE',
                {
                    src: node.src,
                    alt: node.getAttribute('alt'),
                    link: node.getAttribute('data-href'),
                    images: []
                }
            )
        }*/
        if (nodeName === 'a') {

            let imgLink = Array.prototype.slice.call(node.childNodes).find((val: any) => val.nodeName === 'IMG')
            if (imgLink) {
                imgLink.setAttribute('data-href', node.getAttribute('href'))
                return;
            }
            if (node.classList && node.classList.contains(`UserLink`)) {
                return createEntity(
                    'mention',
                    "SEGMENTED",
                    { mention: { link: node.href, isUser: true, guid: node.href.replace('/user/', ''), id: node.href.replace('/group/', '') } }
                )
            } else if (node.classList && node.classList.contains(`GroupLink`)) {
                return createEntity(
                    'mention',
                    "SEGMENTED",
                    { mention: { link: node.href, isGroup: true, guid: node.href.replace('/user/', ''), id: node.href.replace('/group/', '') } }
                )
            } else if (node.classList && node.classList.contains(`TagLink`)) {
                return createEntity(
                    '#mention',
                    "SEGMENTED",
                    { mention: { link: node.href, id: node.href.replace('/tags/', '') } }
                )
            }
            return createEntity(
                'LINK',
                'MUTABLE',
                { url: node.href }
            )
        }
    },
    htmlToBlock: (nodeName: any, node: any) => {
        if (nodeName === 'div' && node.classList && node.classList.contains(`imgBox`)) {
            return {
                type: 'atomic',
                data: {
                }
            };
        }
        if (nodeName === 'ol') {
        }
    }
}
class HtmlWidgetPresenter extends React.Component<any, IHtmlWidgetState> {
    public state = {
        editData: this.props.editData || false,
        // data: {},
        data: EditorState.createEmpty(),
        text: this.props.widget.errorLoading ? this.props.widget.errorLoadWidgetText : this.props.widget.data
    };

    private mounted = false;

    public componentWillUnmount() {
        this.mounted = false;
    }

    private onChangeText = (data: any) => {
        this.setState({ data })
    }

    private endEdit = () => {
        const text = convertToHTML({
            styleToHTML: (style: any) => {
                if (style.startsWith('COLORED_')) return <span style={{ color: style.replace('COLORED_', '') }} />;
            },
            entityToHTML: (entity, originalText) => {
                if (entity.type === "IMAGE") {
                    const alignCls = entity.data.alignment ? `img-${entity.data.alignment}` : ''

                    const Img = (val: any) => {
                        return <img
                            alt={val.alt}
                            title={val.alt}
                            src={val.src}
                            style={{ width: `${val.width}%` }}
                            data-href={val.link}
                            className={'img ' + alignCls} />
                    }
                    const Link = ({ val, children }: any) => {
                        return <a
                            href={val.link}
                            data-img={val.src}> {children}</a>
                    }
                    const ImgWrapper = ({ children }: any) => {
                        return <div
                            className={'imgBox '}
                            style={{ width: `${entity.data.width}%` }}>{children}</div>
                    }

                    return ImgWrapper({
                        children: entity.data.images.map((val: any) =>
                            val.link && val.link !== '' ? Link({ val, children: Img(val) }) : Img(val))
                    });
                }
                if (entity.type === 'LINK') {
                    return <a href={entity.data.url}>{originalText}</a>;
                }
                if (entity.type === "mention") {
                    return <a className={entity.data.mention.isUser ? 'UserLink' : 'GroupLink'} href={entity.data.mention.link}>{originalText}</a>;
                }
                if (entity.type === "#mention") {
                    return <a className={'TagLink'} href={entity.data.mention.link}>{originalText}</a>;
                }
                return originalText;
            },
            blockToHTML: (block: any) => {
                const classNames = block.inlineStyleRanges.map((val: any) => {
                    if (['center', 'left', 'right', 'justify'].includes(val.style)) return `alignment--${val.style}`
                    else return val.style;
                }).join(' ')
                if (block.type === "header-one") return <h1 className={classNames} />;
                if (block.type === "header-two") return <h2 className={classNames} />;
                if (block.type === "header-three") return <h3 className={classNames} />;
                if (block.type === "header-four") return <h4 className={classNames} />;
                if (block.type === "header-five") return <h5 className={classNames} />;
                if (block.type === "header-six") return <h6 className={classNames} />;
                if (block.type === "unstyled") return <p className={classNames} />;
                // if (block.type === "unordered-list-item") return <li className={classNames} />;
                // if (block.type === "ordered-list-item") return <li className={classNames} />;
                if (block.type === 'unordered-list-item') {
                    return {
                        element: <li className={classNames} />,
                        nest: <ul />
                    }
                }
                if (block.type === 'ordered-list-item') {
                    return {
                        element: <li className={classNames} />,
                        nest: <ol />
                    }
                }
                if (block.type === "atomic") {
                    return <div className={classNames} />;
                    /*return {
                        start: "<div>",
                        end: "</div>",
                        empty: ""
                    };*/
                }
                return <p className={classNames} />
            }
        })(this.state.data.getCurrentContent());
        this.setState((s: IHtmlWidgetState) => {
            s.editData = false;
            // s.text = text;
            return s;
        })

        this.props.updateWidget({
            ...this.props.widget,
            data: text
        })

        // this.props.changeVm('widgets.' + this.props['data-id']+'.data', this.state.data);
    }

    private toEdit = () => {
        this.setState((s: IHtmlWidgetState) => {
            s.editData = true;
            s.data = EditorState.createWithContent(convertFromHTML(fromHtml)(this.props.widget.errorLoading ? this.props.widget.errorLoadWidgetText : this.props.widget.data || ''));
            return s;
        })
    }

    public render() {
        const { tag: Tag = 'div', className, widget, edit, editContent, isEditable, isEdit , ...props } = this.props;
        const { editData, data, text } = this.state;
        if (isEdit || (isEditable && editData)) return <Tag className={cnHtmlWidget({}, [className])} {...props}>
            {/* {editContent !== undefined && <Button onClick={this.endEdit}><Icon icon={'edit'} /></Button>} */}
            <PryanikyEditor
                value={data}
                enabledAnvanced={true}
                setActionBtn={(v: any) => { }}
                onChange={this.onChangeText}
                additionalControls={<Button theme={'unstyled'} onClick={this.endEdit} ><Icon icon={'save'} /></Button>}
            />
            {/* <Editor
                onChange={this.onChangeText}
                value={data}
                // className={cnBody('Editor')}
                placeholder={i18n.t('announcement text')} /> */}
        </Tag>
        if (isEditable) return <Tag className={cnHtmlWidget({}, [className])} {...props}>
            {isEditable && <Button onClick={this.toEdit}><Icon icon={'edit'} /></Button>}
            <div dangerouslySetInnerHTML={{ __html: this.props.widget.errorLoading ? this.props.widget.errorLoadWidgetText : this.props.widget.data }}></div>
            {/* <PostContentRender data={this.props.widget.data} /> */}
        </Tag>
        return (
            <Tag className={cnHtmlWidget({}, [className])} {...props}>
                <div dangerouslySetInnerHTML={{ __html: this.props.widget.errorLoading ? this.props.widget.errorLoadWidgetText : this.props.widget.data }}></div>
                {/* <PostContentRender data={this.props.widget.data} /> */}
            </Tag>
        );
    }
}

export const HtmlWidget = connect(mapStateToProps, mapDispatchToProps)(HtmlWidgetPresenter);
