import React, {  } from 'react'
// import {
//     cnClassName,
//     IMoveToGroupOwnProps,
//     IMoveToGroupResult,
//     IMoveToGroupPresenterProps
// } from './interfaces'
import i18n from 'localizations/i18n';
import * as utils from 'utils/src/utils';
import { dialog } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { TDialogProps } from 'utils/src/DialogCreator'
import { GroupListItem } from 'muicomponents/src/ItemsListDialog/GroupListItem/GroupListItem'
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { Button } from 'muicomponents/src/Button/Button';



type ItemType = { id: string, displayName: string, pkid: number, name: string };
const mainTimeline = { id: '', pkid: -1, displayName: 'Основная лента', name: 'Основная лента' }
const createMoveToGroupDialog = () => {
    return ({ }: {}, containerProps?: TDialogProps) =>
        dialog<ItemType, { group: ItemType }>({
            method: async function (skipCount, { count, search }) {
                const response = await utils.API.groups.list(skipCount, count, { excludeSystemGroups: true, search })
                const result = await response.r as { data: ItemType[] } & IBasicResponse
                if (result.error_code !== 0) throw result
                return result.data.map((item) => ({ ...item, url: `/group/${item.pkid}` }))
            },
            middleware: ({ handleAccept, handleClose, listBodyProps, ...props }) => {
                return {
                    ...props,
                    listBodyProps: {
                        ...listBodyProps,
                        handleAccept: () => handleAccept({ group: props.select.selected[0] }),
                        disableAccept: !props.select.selected[0],
                        leftActions: <Button onClick={() => handleAccept({ group: mainTimeline })} variant={'outlined'}>В основную ленту</Button>,
                    }
                }
            },
            ItemComponent: GroupListItem,
            selectable: true,
            selectableSingle: true,
            listHeaderProps: {
                searchLabel: ''
            },
            listBodyProps: {
                header: i18n.t('pryaniky.post.actions.confirm.moveToGroup'),
                acceptText: i18n.t('move'),
                closeText: i18n.t('pryaniky.birthdays.modal.cancel')
            }
        }, containerProps)
}
export default createMoveToGroupDialog()