import actions from 'redux/actionsTypes/Titles';

export const getGroupTitle = (payload: any) => ({
  type: actions.GET_GROUP_TITLE,
  payload
});
/**
 * @todo перевесить на saga на загрузки пользователя  
 * @param payload 
 */
export const getUserTitle = (payload: any) => ({
  type: actions.GET_USER_TITLE,
  payload
});

export const getNewsTitle = (payload: any) => ({
  type: actions.GET_NEWS_TITLE,
  payload
});

// export const getGroupTitle = (payload: any) => ({
//   type: actions.GET_GROUP_NAME,
//   payload
// });