import actions from 'redux/actionsTypes/Photoalbums';
import { union } from 'lodash'
const defaultPhotoalbums: any = {
    albums: {},
    albumsIds: [],
    photos: {},
    selectedPhotos: [],
    groups: {}
};

export function photoalbumsReducer(state = defaultPhotoalbums, action: any) {
    switch (action.type) {
        case actions.CREATE_PHOTOALBUM:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.payload.album.id]: action.payload.album
                },
                groups: {
                    ...state.groups,
                    [action.payload.gid]: {
                        ...state.groups[action.payload.gid],
                        albums: [action.payload.album.id, ...state.groups[action.payload.gid].albums]
                    }
                }
            };
        case actions.DELETE_PHOTOALBUM:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    [action.payload.aid]: undefined
                },
                groups: {
                    ...state.groups,
                    [action.payload.gid]: {
                        ...state.groups[action.payload.gid],
                        albums: state.groups[action.payload.gid].albums.filter((val: string) => val !== action.payload.aid)
                    }
                }
            };
        case actions.CHANGE_PHOTO:
            return {
                ...state,
                photos: {
                    ...state.photos,
                    [action.payload.aid]: {
                        ...state.photos[action.payload.aid],
                        files: {
                            ...state.photos[action.payload.aid].files,
                            [action.payload.fid]: {
                                ...state.photos[action.payload.aid].files[action.payload.fid],
                                ...action.payload.file
                            }
                        },
                    }
                }
            }
        case actions.SELECT_PHOTO:
            return {
                ...state,
                photos: {
                    ...state.photos,
                    [action.payload.aid]: {
                        ...state.photos[action.payload.aid],
                        selected: !state.photos[action.payload.aid].selected.find((fid: string) => fid === action.payload.fid) ? [...state.photos[action.payload.aid].selected, action.payload.fid] : state.photos[action.payload.aid].selected.filter((fid: string) => fid !== action.payload.fid)
                    }
                }
            }
        case actions.SELECT_ALL_PHOTO:
            return {
                ...state,
                photos: {
                    ...state.photos,
                    [action.payload]: {
                        ...state.photos[action.payload],
                        selected: state.photos[action.payload].result.length === state.photos[action.payload].selected.length ? [] : [...state.photos[action.payload].result]
                    }
                }
            }
        case actions.DELETE_PHOTO:
            return {
                ...state,
                photos: {
                    ...state.photos,
                    [action.payload.aid]: {
                        files: {
                            ...state.photos[action.payload.aid].files,
                            [action.payload.fid]: undefined
                        },
                        result: state.photos[action.payload.aid].result.filter((val: string) => val !== action.payload.fid),
                        selected: state.photos[action.payload.aid].selected.filter((fid: string) => fid !== action.payload.fid)
                    }
                }
            }
        case actions.ADD_PHOTOS:
            return {
                ...state,
                photos: {
                    ...state.photos,
                    [action.payload.aid]: {
                        ...state.photos[action.payload.aid],
                        files: { ...action.payload.photos.files, ...state.photos[action.payload.aid].files },
                        result: [...action.payload.photos.result, ...state.photos[action.payload.aid].result]
                    }
                }
            }
        case actions.IS_LOADING_ALBUM:
            return {
                ...state,
                photos: {
                    ...state.photos,
                    [action.payload.aid]: {
                        files: {},
                        result: [],
                        selected: [],
                        isFinished: false,
                        length: 0,
                        ...state.photos[action.payload.aid],
                        isLoading: true
                    }
                }
            }
        case actions.READ_ALBUM:
            if (action.payload.aid in state.photos) {
                return {
                    ...state,
                    photos: {
                        ...state.photos,
                        [action.payload.aid]: {
                            ...state.photos[action.payload.aid],
                            files: { ...state.photos[action.payload.aid].files, ...action.payload.photos.files },
                            result: [...state.photos[action.payload.aid].result, ...action.payload.photos.result],
                            isFinished: action.payload.photos.isFinished,//action.payload.photos.isFinished,
                            length: action.payload.photos.length,
                            isLoading: action.payload.photos.isLoading
                        }
                    }
                }
            }
            else {
                return {
                    ...state,
                    photos: {
                        ...state.photos,
                        [action.payload.aid]: action.payload.photos
                    }
                };
            }
        case actions.IS_LOADING_GROUP_ALBUM:
            return {
                ...state,
                groups: {
                    ...state.groups,
                    [action.payload.gid]: {
                        albums: [],
                        isFinished: false,
                        ...state.groups[action.payload.gid],
                        isLoading: true
                    }
                }
            }
        case actions.READ_GROUP_ALBUMS:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    ...action.payload.albums
                },
                groups: {
                    ...state.groups,
                    [action.payload.gid]: {
                        ...state.groups[action.payload.gid],
                        ...action.payload.group,
                        albums: union([...state.groups[action.payload.gid].albums, ...action.payload.group.albums])
                    }
                }
            };

        default:
            return state;
    }
}
