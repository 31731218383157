import * as React from 'react';
import * as utils from 'utils/src/utils';
// import i18n from '../../../../localizations/i18n';
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';

import { withBemMod } from '@bem-react/core';

import { cnBody } from '../Modal-Body.index';
import { IBodyTypeImageCropProps, IBodyTypeImageCropState } from './Modal-Body_type_imageCrop.index';

import './Modal-Body_type_imageCrop.scss';
import { Avatar } from 'uielements/src/Avatar/Avatar';

class NewBody extends React.Component<IBodyTypeImageCropProps, IBodyTypeImageCropState> {
  public utils: any = utils;
  public el?: HTMLElement | null;
  public imageRef?: HTMLElement | null;
  public fileRealSize: {
    width: number;
    height: number;
  } = {
    width: 0,
    height: 0
  }
  public fileUrl: any;

  constructor(props: IBodyTypeImageCropProps) {
    super(props);
    this.functionBind = this.functionBind.bind(this);
    this.functionBind();
    this.state = {
      crop: {
        x: 0,
        y: 0,
        aspect: 1 / 1,
        width: 200,
      }
    }
  }

  public componentDidMount() {
    if(this.props.parent.state.data.file) {
      const asd = {
        target: {
          files: [this.props.parent.state.data.file]
        }
      }
      this.onSelectFile(asd);
    }
  }

  public render() {
    const customClassNames = '';
    const TagName = this.props.tag ? this.props.tag : 'div';
    return (
      <TagName ref={el => this.el = el}
       className={cnBody({}, (this.props.className ? this.props.className.split(' ') : []).concat(customClassNames.split(' ')))}>
        <div className={cnBody('Cropper')}>
        {
          !this.props.parent.state.data.file &&
          <div>
            <input className={cnBody('Cropper-Input')} type='file' onChange={this.onSelectFile} />
          </div>
        }
        {
          this.state.src &&
          <ReactCrop
            circularCrop={true}
            src={this.state.src}
            crop={this.state.crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        }
        {
          this.state.cropUrl &&
          <div className={cnBody('Cropper-Preview')}>
            <Avatar 
              className={cnBody('Cropper-Preview-Profile')}
              imgUrl={this.state.cropUrl}
              noBorderRadius={true}
              size={180}
            />
            <Avatar
              className={cnBody('Cropper-Preview-Author')}
              imgUrl={this.state.cropUrl}
              size={56} 
            />
            <Avatar
              className={cnBody('Cropper-Preview-Reply')}
              imgUrl={this.state.cropUrl}
              size={40} 
            />
          </div>
        }
        </div>
      </TagName>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }
  
  private functionBind() {
    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.getCroppedImg = this.getCroppedImg.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
  }
  
  private onImageLoaded(imageHTMLElem: any) {
    const classes: any[] = [];
    for (const className of imageHTMLElem.classList) {
      classes.push(className);
    }
    imageHTMLElem.className = '';
    this.fileRealSize.height = imageHTMLElem.clientHeight;
    this.fileRealSize.width = imageHTMLElem.clientWidth;
    classes.forEach(className => imageHTMLElem.classList.add(className));
    this.imageRef = imageHTMLElem;
  }

  private onCropChange(crop: any) {
    this.setState({ crop }, () => {
      // this.props.parent.setMargin();
    });
  }

  private getCroppedImg(image: any, crop: any, fileName: string) {
    const canvas = document.createElement('canvas');
    const realCrop: {[s: string]: any} = {
      x: crop.x,
      y: crop.y,
      aspect: crop.aspect,
      width: crop.width,
      height: crop.height
    }

    if(image.clientHeight !== this.fileRealSize.height && image.clientWidth !== this.fileRealSize.width) {
      const height = (crop.height * 100) / image.clientHeight;
      realCrop.height = (height * this.fileRealSize.height) / 100;
      const width = (crop.width * 100) / image.clientWidth;
      realCrop.width = (width * this.fileRealSize.width) / 100;
      const x = (crop.x * 100) / (image.clientWidth);
      realCrop.x = (x * this.fileRealSize.width) / 100;
      const y = (crop.y * 100) / (image.clientHeight);
      realCrop.y = (y * this.fileRealSize.height) / 100;
    }

    canvas.width = realCrop.width;
    canvas.height = realCrop.height;
    const ctx = canvas.getContext('2d');

    if(ctx) {
      ctx.drawImage(
        image,
        realCrop.x,
        realCrop.y,
        realCrop.width,
        realCrop.height,
        0,
        0,
        realCrop.width,
        realCrop.height,
      );
    }

    return new Promise((resolve: any, reject: any) => {
      canvas.toBlob(blob => {
        if(!blob) return;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({blob, fileUrl: this.fileUrl});
      }, 'image/png');
    });
  }

  private onCropComplete(crop: any) {
    if (crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.png',
      );
      croppedImageUrl.then((d: any) => {
        this.setState({ cropUrl: d.fileUrl }, () => {
          let fileName = 'newImg.png';
          if(this.props.parent.state.data.file) {
            const lastDot = this.props.parent.state.data.file.name.lastIndexOf('.');
            fileName = this.props.parent.state.data.file.name.substring(0, lastDot) + '-CROPED.png';
          }
          this.props.parent.state.data.cropedFile = new File([d.blob], fileName);
          // this.props.parent.setMargin();
        });
      })
    }
  }

  private onSelectFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result }, () => {
          // this.props.parent.setMargin();
        })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }
}

export const withBodyTypeImageCrop = withBemMod<any>(
  cnBody(),
  { type: 'imageCrop' }, 
  (Presenter) => (
    (props: IBodyTypeImageCropProps) => (
      <NewBody {...props} />
      // <Presenter {...props}/>
    )
  )
);