/**
 * @packageDocumentation
 * @module Widget_Settings_info
 */
import { JFdictionary } from "../dictionary";
import i18n from '../../../../localizations/i18n';

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    fullWidth: {
      type: types.boolean
    }
  }
}
