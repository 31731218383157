import { zeroId } from 'LMSModule/utils/utils.lms'

export const courseModelCreator = (id = zeroId) => ({
    "id": id,
    "courseSessionId": zeroId,
    "name": "",
    "imgId": zeroId,
    "largeImgId": zeroId,
    "largeImgUrl": "",
    "category": "",
    "unitsCount": 0,
    "categoryId": zeroId,
    "tags": "",
    // "eventpausetext": "string",
    // "noaccesstext": "string",
    "programme": "",
    "ticket": "",
    "tagsList": [],
    "description": "",
    "externalUrl": "",
    "bonus": 0,
    "lmsPlatformName": "",
    "isDeleted": true,
    "applicationId": zeroId,
    "externalCourseId": "",
    "courseUnits": [],
    "courseSessions": [],
    // "newsUid": "00000000-0000-0000-0000-000000000000",
    "isLiked": false,
    "likesCount": 0,
    "commentsCount": 0,
    "isArchived": false,
    "users": [],
    "authors": [],
    "CourseProgressPersentage": 0,
    "CourseAllUnits": 0,
    "CourseCompletedUnits": 0
})