import { Box, styled, Typography } from "muicomponents/src";
import { Avatar as AvatarMui } from 'muicomponents/src/Avatar';

export const SurveyBox = styled(Box)({
    marginTop: 24
}) as typeof Box;

export const SurveyHeaderBox = styled(Box)({
    display: 'flex',
    padding:"24px",
    margin: 24,
    alignItems: 'flex-start',
    gap: 16
}) as typeof Box;
export const SurveyPassingProgressBox = styled(Box)({
    width: "500px",
    maxWidth: "500px",
    marginTop: "12px",
    ".Box.LinearProgress": {
        height: "20px"
    }
}) as typeof Box;

export const SurveyBodyBox = styled(Box)({
    padding: 16
}) as typeof Box;

export const TypographyName = styled(Typography)({
    fontSize: 24,
    fontWeight: 'normal'
}) as typeof Typography;

export const TypographyText = styled(Typography)({
    fontSize: 16,
    fontWeight: 'normal'
}) as typeof Typography;

export const TypographyIsCompetedByMe = styled(Typography)({
    fontSize: 16,
    fontWeight: 600,
    marginTop: 16
}) as typeof Typography;