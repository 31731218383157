import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
export interface ISummaryTableProps extends IClassNameProps {
    rightPercent: number; 
    wrongPercent: number;
    waitModerationCount?: number;
    moderatedAnswersCount?: number;
}


export const cnSummaryTable = cn('ResultSummaryTable');