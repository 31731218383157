import { IStateType as IState } from 'redux/store';
import { IJournalDataSettings } from './interfaces'

/**
 * get cuurent journal state
 * @param { IState } state
 */
export const getJournalState = (state: IState) => state.journal

/**
 * return tabs redux state
 * @param { IState } state 
 */

export const getJournalData = (state: IState) => getJournalState(state).journalSettings
