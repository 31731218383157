import { compose } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'utils/src/utils';
// import i18n from '../../../localizations/i18n';

import { cnTags, ITagsProps, ITagsState } from './NewsView-Tags.index';
import './NewsView-Tags.scss';
import { Button } from 'uielements/src/Button/Button';

export class TagsPresenter extends React.Component<ITagsProps, ITagsState> {
  // public utils: any = utils;
  public el: HTMLElement | null;

  constructor(props: ITagsProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {};

  }

  public render() {
    const {className = '', tags, children} = this.props;
    // const {} = this.props;
    return (
      <div ref={el => this.el = el} className={cnTags({}, [className])}>
        {tags.map((value, index) => <Button theme='unstyled' key={index}>#{value.displayName}</Button>)}
        {children}
      </div>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const Tags = compose(
  // withBemMod(cnTags(), {})
)(TagsPresenter)
  