/**
 * @packageDocumentation
 * @module ListHeader
 */
import * as React from 'react';
// import * as utils from 'utils/src/utils';


import { withBemMod } from '@bem-react/core';
import { Button as MuiButton } from 'muicomponents/src/Button/Button'
import { cnHeader } from '../List-Header.index';
import { IHeaderTypeCommonProps } from './List-Header_type_common.index';
import './List-Header_type_common.scss';
import {
  SearchInput,
  Button,
  Dropdown,
  Icon,
  // Checkbox 
} from 'uielements/src';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';

// const PROPS_TO_DELETE = ['className', 'type', 'tag', 'store', 'changeVm', 'dispatch'];
const type = 'common';

export const withHeaderTypeCommon = withBemMod<any>(
  cnHeader(),
  { type },
  (Presenter) => (
    (props: IHeaderTypeCommonProps) => {
      const { tag: TagName = 'div', actions, search, counter, children } = props;
      return (
        <Presenter {...props} >
          {
            (search || actions) &&
            <div className={cnHeader('Item')}>
              {
                search &&
                <SearchInput className={cnHeader('Search', { single: !actions })} onChange={search.onChange} placeholder={search.placeholder} />
              }
              {
                actions &&
                (
                  (
                    actions.options.length === 1 && actions.options[0].buttonProps &&
                    <MuiButton
                      className={cnHeader('Actions')}
                      color={'primary'}
                      variant={'contained'}
                      size={'small'}
                      // noPadding
                      // type={actions.options[0].buttonProps.type}
                      href={actions.options[0].buttonProps.href}
                      onClick={actions.options[0].buttonProps.onClick}
                      children={actions.options[0].title} />
                  )
                  ||
                  (
                    actions.options.length > 1 &&
                    <Dropdown
                      className={cnHeader('Actions')}
                      text={actions.text}
                      buttonProps={{ main: true }}
                      withCaret
                      options={actions.options}
                    />
                  )
                )
              }
            </div>
          }
          {
            counter &&
            <div className={cnHeader('Item')}>
              {
                counter.all &&
                <Button className={cnHeader('Count', { text: true })} noBorderRadius noBackground onClick={!counter.all.hideCheckbox ? counter.all.action : undefined} noBorder>
                  {!counter.all.hideCheckbox && <Checkbox onChange={() => { }} value={counter.all.count === (counter.selected ? counter.selected.count : 0)} />}
                  <div>{counter.all.title}: {counter.all.count}</div>
                </Button>
              }
              {
                counter.selected && counter.selected.count > 0 &&
                (
                  (
                    counter.selected.action.options.length === 1 && counter.selected.action.options[0].buttonProps &&
                    <Button
                      className={cnHeader('Count', { actions: true })}
                      noBorderRadius={!counter.selected.action.options[0].buttonProps.main}
                      noBackground={!counter.selected.action.options[0].buttonProps.main}
                      noBorder={!counter.selected.action.options[0].buttonProps.main}
                      main={counter.selected.action.options[0].buttonProps.main}
                      onClick={counter.selected.action.options[0].buttonProps.onClick}
                      type={counter.selected.action.options[0].buttonProps.type}
                      href={counter.selected.action.options[0].buttonProps.href}
                    >
                      {counter.selected.action.options[0].title}: {counter.selected.count}
                    </Button>
                  )
                  ||
                  (
                    counter.selected.action.options.length > 1 &&
                    <Dropdown
                      className={cnHeader('Count', { actions: true })}
                      text={counter.selected.title}
                      withCaret
                      align="center"
                      options={counter.selected.action.options} />)
                )
              }
            </div>
          }

          {children && children}
        </Presenter>
      )
    }
  )
);