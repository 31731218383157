import { IStateType as IState } from 'redux/store';

/**
 * return all contexts at object
 * @param { IState } state
 */
export const getContexts = (state: IState) => state.widgets.contexts

/**
 * return context by windget id, if id undefined return common context
 * @param { string } id
 */
export const getContextById = (id?: string) => (state: IState) => getContexts(state)[id || 'common']