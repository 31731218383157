import moment from 'moment'
import {
    pathCreator
} from 'News/redux/actions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { createData } from '../NewsTypes/Events/Editor/containers/news/news'
import { createNewsModal } from 'blocks/Dialogs/News/Create/Create';

export const useOpenEventCreator = () => {
    const dispatch = useDispatch()
    return (date?: Date) => {
        dispatch(pathCreator({
            currentNews: createData(date)
        }))
        createNewsModal({
            oneNewsType: "events"
        })
    }
}

export const formatDate = (date: Date) => moment(new Date(date)).format().slice(0, 10)


export const isShowCreateButton = (date: any) => {
    const itemDate = new Date(date)
    const currentDate = new Date()
    currentDate.setHours(0)
    currentDate.setMinutes(0)
    currentDate.setSeconds(0)
    currentDate.setMilliseconds(0)
    itemDate.setHours(0)
    itemDate.setMinutes(0)
    itemDate.setSeconds(0)
    itemDate.setMilliseconds(0)
    if (itemDate >= currentDate) return true
    return false
}

export const isToDay = (date: any) => {
    const itemDate = new Date(date)
    const currentDate = new Date()
    currentDate.setHours(0)
    currentDate.setMinutes(0)
    currentDate.setSeconds(0)
    currentDate.setMilliseconds(0)
    itemDate.setHours(0)
    itemDate.setMinutes(0)
    itemDate.setSeconds(0)
    itemDate.setMilliseconds(0)
    if (itemDate.toISOString() === currentDate.toISOString()) {
        return true
    }
    return false
}