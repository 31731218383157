import React, { FC, useEffect, memo, useCallback } from 'react'
import {
    cnClassName,
    News
} from './interfaces'
// import { useDispatch, useSelector } from 'react-redux';
// import { routerActions } from 'connected-react-router';
import './style.scss'
import { Button, Avatar } from 'uielements/src'
import i18n from 'localizations/i18n';
import { generateLinkData, decodeEntities } from 'utils/src/utils'
type IPresenterProps = News.NewsBannerTypeOther.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    banner
}) => {
    useEffect(() => {

    }, [])

    const { href, target, type } = generateLinkData(banner.url || '')

    return <div className={cnClassName({}, [])}>
        <div className={cnClassName('Header')} >
            <Avatar className={cnClassName('Img')} size={140} imgUrl={banner.imgUrl} name={banner.title || ''} />
        </div>
        <div className={cnClassName('Content')}>
            <div className={cnClassName('Title')}>{decodeEntities(banner.title || '')}</div>
            {/* <div className={cnClassName('Text')}>{decodeEntities(banner.text || '')}</div> */}
            <div className={cnClassName('Text')}>
                <div> {banner.text}</div>
                <div> {banner.subtitle}</div>
            </div>
        </div>
        <div className={cnClassName('Bottom')}>
            <Button noBorder noBackground className={cnClassName('Action', ['primaryColor3-text'])} target={target} type={type} href={href}>{banner.buttonText}</Button>
        </div>
    </div>
}

export const Banner = memo(Presenter);

export default Banner