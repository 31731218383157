import actions from '../actionsTypes/News';
import * as types from './types'
import { IBaseNews } from '../../types/baseNews'
import queryString from 'query-string';
import { omit } from 'lodash'

//////////////////////////////////////////////////////////////////////////////////////////////////////

/********************************************************/
/******************** to saga ***************************/
/********************************************************/

/******************************* LOAD_NEWS *******************************/
/**
 * получить список планов для управления
 */
export const loadNewsList: types.CLoadNewsList = (payload) => ({
    type: actions.LOAD_NEWS,
    payload
})

/******************************* LOAD_NEWS *******************************/
/**
 * получить список планов для управления
 */
export const loadFullReplt: types.CLoadFullReply = (payload) => ({
    type: actions.LOAD_FULL_REPLY,
    payload
})

/******************************* LIKE_NEWS *******************************/
/**
 * лайкнуть новость
 */
export const sendLikeNews: types.CSendLikeNews = (payload) => ({
    type: actions.LIKE_NEWS,
    payload
})


/******************************* LOAD_COMMENTS *******************************/
/**
 * загрузить все комментарии
 */
export const loadComments: types.CLoadComments = (payload) => ({
    type: actions.LOAD_COMMENTS,
    payload
})


/******************************* DELETE_NEWS *******************************/
/**
 * удалить новость
 */
export const deleteNews: types.CDeleteNews = (payload) => ({
    type: actions.DELETE_NEWS,
    payload
})


/******************************* HIDE_NEWS *******************************/
/**
 * скрыть новость
 */
export const hideNews: types.CHideNews = (payload) => ({
    type: actions.HIDE_NEWS,
    payload
})


/******************************* ADD_TO_ANNOUNCEMENT *******************************/
/**
 * сдобавить нововсть в аннонсы
 */
export const addToAnnouncement: types.CAddToAnnouncement = (payload) => ({
    type: actions.ADD_TO_ANNOUNCEMENT,
    payload
})


/******************************* UPDATE_NEWS *******************************/
/**
 * сизменить новость
 */
export const updateNews: types.CUpdateNews = (payload, result = () => { }) => ({
    type: actions.UPDATE_NEWS,
    payload,
    result
})


/******************************* SEND_REPLY *******************************/
/**
 * отправить комментарий
 */
export const sendReply: types.CSendReply = (payload) => ({
    type: actions.SEND_REPLY,
    payload
})


/******************************* LOAD_NEWS_BY_ID *******************************/
/**
 * загрузить указанную новость
 */
export const loadNewsById: types.CLoadNewsById = (payload) => ({
    type: actions.LOAD_NEWS_BY_ID,
    payload
})


/******************************* DELETE_REPLY *******************************/
/**
 * удалить указанный комментарий
 */
export const deleteReply: types.CDeleteReply = (payload) => ({
    type: actions.DELETE_REPLY,
    payload
})


/******************************* DELETE_REPLY *******************************/
/**
 * лайкнуть комментарий
 */
export const sendLikeReply: types.CSendLikeReply = (payload) => ({
    type: actions.LIKE_REPLY,
    payload
})


/******************************* ADD_INVEST *******************************/
/**
 * интвестировать в новость
 */
export const addInvest: types.CAddInvest = (payload) => ({
    type: actions.ADD_INVEST,
    payload
})


/******************************* MOVE_TO_GROUP *******************************/
/**
 * переместить новость в группу
 */
export const moveToGroup: types.CMoveToGroup = (payload) => ({
    type: actions.MOVE_TO_GROUP,
    payload
})


/******************************* UPDATE_REPLY *******************************/
/**
 * редактировать комментарий
 */
export const updateReply: types.CUpdateReply = (payload) => ({
    type: actions.UPDATE_REPLY,
    payload
})


/******************************* SEND_NEWS *******************************/
/**
 * опубликовать новость
 */
export const sendNews: types.CSendNews = (payload, result = () => { }) => ({
    type: actions.SEND_NEWS,
    payload,
    result
})


/******************************* INSERT_MENTION *******************************/
/**
 * вставить упомянание
 */
export const insertMention: types.CInsertMention = (payload) => ({
    type: actions.INSERT_MENTION,
    payload
})


/******************************* CONTEXT_ACTION *******************************/
/**
 * действие из контекстного меню
 */
export const contextAction: types.CContextAction = (payload) => ({
    type: actions.CONTEXT_ACTION,
    payload
})


/******************************* CONTEXT_ACTION_CANCLE *******************************/
/**
 * действие из контекстного меню
 */
export const contextActionCancle: types.CContextActionCancle = (payload) => ({
    type: actions.CONTEXT_ACTION_CANCLE,
    payload
})

/******************************* LOAD_WORKFLOW_STATUSES *******************************/
/**
 * загрузка статусов заявки
 */
export const loadWorkflowStatuses: types.CLoadWorkflowStatuses = (payload) => ({
    type: actions.LOAD_WORKFLOW_STATUSES,
    payload
})


/******************************* REJECT_ACHIEVMENT *******************************/
/**
 * отклонить достижение
 */
export const rejectAchievement: types.CRejectAchievement = (payload) => ({
    type: actions.REJECT_ACHIEVMENT,
    payload
})
/******************************* submitForCorrection *******************************/
/**
 * отправить  достижение на доработку 
 */
export const submitForCorrection: types.CSubmitForCorrection = (payload) => ({
    type: actions.SUBMIT_FOR_CORRECTION,
    payload
})





/********************************************************/
/******************** to reducer ************************/
/********************************************************/

/******************************* SET_NEWS *******************************/

/**
 * добавить список новосте в стор
 */
export const setNewsList: types.CSetNewsList = (payload) => ({
    type: actions.SET_NEWS,
    payload
})


/******************************* UPGRADE_NEWS *******************************/
/**
 * добавить список новосте в стор
 */
export const upgradeNews: types.CUpgradeNews = (payload) => ({
    type: actions.UPGRADE_NEWS,
    payload
})


/******************************* PREPEND_NEWS *******************************/

/**
 * добавить новости в начало ленты
 */
export const prependNewsList: types.CPrependNewsList = (payload) => ({
    type: actions.PREPEND_NEWS,
    payload
})


/******************************* APPEND_NEWS *******************************/

/**
 * добавить нововсти в конец ленты и опеделить окончание(isFinished)
 */
export const appendNewsList: types.CAppendNewsList = (payload) => ({
    type: actions.APPEND_NEWS,
    payload
})


/******************************* ADD_COMMENTS *******************************/

/**
 * добавить комментарии в состояние
 */
export const addComments: types.CAddComments = (payload) => ({
    type: actions.ADD_COMMENTS,
    payload
})


/******************************* PREPEND_COMMENTS *******************************/

/**
 * добавить комментарии в начало
 */
export const prependComments: types.CPrependComments = (payload) => ({
    type: actions.PREPEND_COMMENTS,
    payload
})


/******************************* UNSET_NEWS *******************************/

/**
 * убрать новость из состояния
 */
export const unsetNews: types.CUnsetNews = (payload) => ({
    type: actions.UNSET_NEWS,
    payload
})


/******************************* TOGGLE_EDIT *******************************/

/**
 * переключить состояние редактирования
 */
export const toggleEdit: types.CToggleEdit = (payload) => ({
    type: actions.TOGGLE_EDIT,
    payload
})


/******************************* SET_UPDATE_ERROR *******************************/

/**
 * установить ошибку редактирования
 */
export const setUpdateError: types.CUpdateError = (payload) => ({
    type: actions.SET_UPDATE_ERROR,
    payload
})


/******************************* CHANGE_REPLY_FORM *******************************/

/**
 * изменить форму комментария
 */
export const changeReplyForm: types.CChangeReplyForm = (payload) => ({
    type: actions.CHANGE_REPLY_FORM,
    payload
})


/******************************* UNSET_REPLY *******************************/

/**
 * убрать комментарий
 */
export const unsetReply: types.CUnsetReply = (payload) => ({
    type: actions.UNSET_REPLY,
    payload
})



/******************************* TOGGLE_COMMENT_LOADING *******************************/

/**
 * 
 */
export const toggleLoadReply: types.CToggleLoadComments = (payload) => ({
    type: actions.TOGGLE_COMMENT_LOADING,
    payload
})


/******************************* TOGGLE_COMMENT_LOADING *******************************/

/**
 * 
 */
export const toggleSendReply: types.CToggleSendComments = (payload) => ({
    type: actions.TOGGLE_COMMENT_SENDING,
    payload
})


/******************************* SET_HEIGHTS *******************************/
/**
 * установить высоту новости
 */
export const setHeight: types.CSetHeight = (payload) => ({
    type: actions.SET_HEIGHTS,
    payload
})


/******************************* SET_CURRENT_RENDERED *******************************/
/**
 * рендерить ли указаную новость
 */
export const setRendered: types.CSetRendered = (payload) => ({
    type: actions.SET_CURRENT_RENDERED,
    payload
})






/**
 * изменить свойства новости
 */
export const pathNews: types.CPathNews = (payload) => ({
    type: actions.PATH_NEWS,
    payload
})

/**
 * изменить свойства комментария
 */
export const pathReply: types.CPathReply = (payload) => ({
    type: actions.PATH_REPLY,
    payload
})

/**
 * изменить свойства формы отправки
 */
export const pathCreator: types.CPathCreator = (payload) => ({
    type: actions.PATH_CREATOR,
    payload
})

/**
 * изменить свойства формы отправки
 */
export const pathCreatorNews: types.CPathCreatorNews = (payload) => ({
    type: actions.PATH_CREATOR_NEWS,
    payload
})

/**
 * валидирует заполненную форму отправки новости
 */
export const validateCreatorNews: types.CFrontValidateNews = () => ({
    type: actions.VALIDATE_CREATOR_NEWS
})



/**
 * 
 */
export const changeField: types.CChangeField = (payload) => ({
    type: actions.CHANGE_FIELD,
    payload
})

/**
 * 
 */
export const changeStatusField: types.CChangeStatusField = (payload) => ({
    type: actions.CHANGE_STATUS_FIELD,
    payload
})

/**
 * переключить состаяние выдимости формы благодарности
 */
export const toggleThank: types.CToggleThank = (payload) => ({
    type: actions.TOGGLE_THANK,
    payload
})


/******************************* RESET *******************************/

/**
 * 
 */
export const reset: types.CReset = (payload) => ({
    type: actions.RESET,
    payload
})




/**
 * установить новость как просмотренную
 */
export const setNewsIsReaded: types.NewsSetReaed = (payload) => ({
    type: actions.SET_READED,
    payload
})

/**
 * push to "read more" button in news
 */
export const newsViewFull: types.NewsViewFull = (payload) => ({
    type: actions.NEWS_VIEW_FULL,
    payload
})

/**
 * push to "read more" button in reply
 */
export const replyViewFull: types.ReplyViewFull = (payload) => ({
    type: actions.REPLY_VIEW_FULL,
    payload
})


/******************************* SET_ACTIVE_NEWS *******************************/
/**
 * используется только при content view
 */
export const setActiveNews: types.CNewsActiveNews = (params, id) => {
    const group = queryString.stringify(omit(params, ['count']))
    return {
        type: actions.ACTIVE_NEWS,
        payload: {
            id,
            params: group
        }
    }
}

/**
 * добавляет ид баннера в массив прочитанных
 * @param { id: string } payload - объект с ключем который равен ид баннера
 */
export const newsBannersSetReaded: types.CNewsBannersSetReaded = (payload) => ({
    type: actions.BANNERS_SET_READED,
    payload
})

/**
 * сбрасывает весь массив прочитанных баннеров
 */
export const newsBannersClearReaded: types.CNewsBannersClearReaded = () => ({
    type: actions.BANNERS_CLEAR_READED
})

/**
 * добавляет ид баннера в массив закрытых
 * @param { id: string } payload - объект с ключем который равен ид баннера
 */
export const newsBannersSetClosed: types.CNewsBannersSetClosed = (payload) => ({
    type: actions.BANNERS_SET_CLOSED,
    payload
})

/**
 * сбрасывает массив закрытых баннеров
 */
export const newsBannersClearClosed: types.CNewsBannersClearClosed = () => ({
    type: actions.BANNERS_CLEAR_CLOSED
})