import React, { FC } from 'react'
import { Box, Typography, styled } from 'muicomponents/src';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { IWalletProps } from './WalletLine.index';

const ListItemWithIcon = styled(Box)({
    margin: '8px auto',
    display: 'flex',
    height: '46px',
    alignItems: 'center',
    maxWidth: 'calc(100% - 38px)',
}) as typeof Box

const YellowIconButton = styled(IconButton)(({theme}) => ({
    background: '#FFB400',
    marginRight: theme.spacing(2),
    pointerEvents: 'none',
}))

export const WalletLine: FC<IWalletProps> = ({
    amount,
    icon,
    label
}) => {

    return (
        <ListItemWithIcon>
            <YellowIconButton>
                {icon}
            </YellowIconButton>
            <Box>
                <Typography variant='body2'>
                    {label}
                </Typography>
                <Typography variant='subtitle2' fontWeight='700'>
                    {amount}
                </Typography>
            </Box>
        </ListItemWithIcon>
    )
}

