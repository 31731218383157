import React, { FC, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CommentIcon from '@material-ui/icons/Comment';
import Avatar from '@material-ui/core/Avatar';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import IconButton from '@material-ui/core/IconButton';
import test from './img/test.svg'
import lesson from './img/lesson.svg'
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';
import { linkCreator, zeroId } from 'LMSModule/utils/utils.lms'
import { TYPE_ICONS } from 'LMSModule/utils/common.utils'

const DragHandle = SortableHandle(() => <IconButton><DragIndicatorIcon /></IconButton>);

export const Presenter: FC<IPresenterProps> = ({ className, unit, selectUnit, selected = false, editOnlyLesson = false }) => {

    const handleToggle = () => selectUnit(`?tab=edit&unitId=${unit.id}`)
    const labelId = `unit-list-label-${unit.id}`;
    return <ListItem selected={selected} ContainerComponent={'div' as any} role={undefined} dense button onClick={handleToggle}>
        <ListItemAvatar>
            <Avatar
                variant={'square'}
                alt={'Test'}
                src={TYPE_ICONS[unit.unitType] || lesson}
            />
        </ListItemAvatar>
        <ListItemText id={labelId} primary={unit.name} />
        {!editOnlyLesson && <ListItemSecondaryAction>
            <DragHandle />
        </ListItemSecondaryAction>}
    </ListItem>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
