import React, { FC, useEffect, memo, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { withIndicator } from 'utils/src/CommonRedux/LoadIndicator'
import actionsTypes from '../../../../redux/actionsTypes/LMSTO'
import { SearchInput } from 'uielements/src';
import { Button, Icon, HelpTooltip } from 'uielements/src';
import Item from './Item'
import { NEW__useInfiniteScroll as useInfiniteScroll, useRefedCallback } from 'utils/src/hooks';
import ListPlaceholder from 'uielements/src/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import LinearProgress from '@material-ui/core/LinearProgress';
import { useScrollList } from 'LMSModule/utils/hooks'
import { useLazyQuery } from 'LMSModule/utils/hooks/useLazyQuery'
import API from 'utils/src/requests/requests'
import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu'

import { xorBy } from 'lodash'
import { tSt } from 'LMSModule/utils/i18n.adaptor'
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

const LoadButton = withLoadSpin(Button)

type IPresenterProps = StatisticsFull.CoursesList.IPresenterProps
type IOwnProps = StatisticsFull.CoursesList.IOwnProps
type IDispatchProps = StatisticsFull.CoursesList.IDispatchProps
type IStateProps = StatisticsFull.CoursesList.IStateProps
type IState = StatisticsFull.CoursesList.IState

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 361,
    },
});



export const Presenter: FC<IPresenterProps> = ({
    className,
    getCoursesStatsList,
    isLoading = false,
    settings,
    pathFullStatisticSetting,
    keys,
    withError = false,
    isFinished,
    count,
    // fields
}) => {
    const classes = useStyles();
    const { loadMore, triggerRef } = useScrollList(
        isLoading,
        isFinished,
        withError,
        [settings],
        (deps) => (clean = false) => {
            getCoursesStatsList({
                skipCount: deps.current.keys.length,
                count: deps.current.count,
                search: deps.current.settings.search
            }, clean)
        },
        { settings, keys, count }
    )

    const onChangeSearch = (search: string) => {
        pathFullStatisticSetting({
            search
        })
    }
    // const onChangeFields = (userProfileAdditionlFields: any[]) => {
    //     pathFullStatisticSetting({
    //         userProfileAdditionlFields
    //     })
    // }

    const [getExcel, excelLoading] = useLazyQuery(() => API.lms.getCourseStatExcel())

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Header')}>
            <div className={cnClassName('Noop')}></div>
            <div className={cnClassName('SearchBox')}>
                <SearchInput
                    loading={isLoading}
                    value={settings.search}
                    className={cnClassName('Search')}
                    onChange={onChangeSearch} />
            </div>
            <div className={cnClassName('Noop')}></div>
            <div className={cnClassName('Actions')}>
                <LoadButton isLoading={excelLoading} noPadding onClick={getExcel} className={cnClassName('ActionBtn')}>
                    <Icon icon={"file-excel"} />
                </LoadButton>
            </div>
        </div>
        <ListPlaceholder
            className={cnClassName('Placeholder')}
            isFinished={isFinished}
            isError={withError}
            textError={<Button
                className={cnClassName('ErrorTrigger')}
                onClick={loadMore}
            >
                {tSt('load.again')}
                {/* пробовать ещё */}
            </Button>}
            titleError={tSt('load.error.title')}//{'Ошибка получения данных'}
            isLoading={isLoading}
            itemsCount={keys.length}
            titleEmpty={tSt('load.empty.title')}//{'Пусто'}
            textEmpty={tSt('load.empty.text')}//{'Когда ни будь, что ни будь'}
            textLoad={tSt('load.holder.text')}//{'Загрузка...'}
        >
            {isLoading && <LinearProgress className={cnClassName('Progress')} />}

            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>Курc</TableCell>
                            <TableCell>Преподователь</TableCell>
                            <TableCell className={cnClassName("Help")}>
                            <Tooltip title={'Общие число студентов на всех потоках курса'} ><span>Всего участников</span></Tooltip></TableCell>
                                {/* <HelpTooltip help={'Общие число студентов на всех потоках курса'} helpCursor underline>Всего участников</HelpTooltip> */}
                            <TableCell>Не приступили</TableCell>
                            <TableCell>В процессе</TableCell>
                            <TableCell>Завершили успешно</TableCell>
                            <TableCell>Завершили неуспешно</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {keys.map(id => <Item key={id} id={id} className={cnClassName('Row')} />)}
                    </TableBody>

                </Table>

                {!isFinished && (
                    <LoadButton
                        forwardedRef={triggerRef}
                        isLoading={isLoading}
                        className={cnClassName('Trigger')}
                        onClick={loadMore}
                    >
                        {tSt('load.more')}
                        {/* {'Ещё'} */}
                    </LoadButton>)}
            </TableContainer>
            {/* <table className={cnClassName('Table', { isLoading })}>
                <colgroup>
                    <col span={1} className={cnClassName('Col0')} />
                    <col span={1} className={cnClassName('Col1')} />
                    <col span={1} className={cnClassName('Col2')} />
                    <col span={1} className={cnClassName('Col3')} />
                    <col span={1} className={cnClassName('Col4')} />
                    <col span={1} className={cnClassName('Col5')} />
                    <col span={1} className={cnClassName('Col6')} />
                    <col span={1} className={cnClassName('Col7')} />
                </colgroup>
                <thead>
                    <tr>
                        <th></th>
                        <th>Курc/Поток</th>
                        <th>Преподователь</th>
                        <th>Всего участников</th>
                        <th>Не приступили</th>
                        <th>
                            {tSt('th.obuchenost')}
                            В процессе
                        </th>
                        <th>
                            {tSt('th.summury')}
                            Завершили успешно
                        </th>
                        <th>
                            {tSt('th.in_progress')}
                            Завершили неуспешно
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {keys.map(id => <Item key={id} id={id} className={cnClassName('Row')} />)}
                </tbody>
            </table> */}


        </ListPlaceholder>


    </div>
}

export default withIndicator(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter))), actionsTypes.GET_COURSES_STATS_LIST)();
