import React, { useMemo, useState } from 'react'
import { ItemsList } from 'muicomponents/src/ItemsListDialog/ItemsListDialog';
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem';
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import * as utils from 'utils/src/utils';
import { RadioGroup } from 'muicomponents/src/RadioGroup';
import { FormControlLabel, FormLabel } from 'muicomponents/src/FormControl';
import { Radio } from 'muicomponents/src/Radio';
import { Box, styled } from 'muicomponents/src';
import { Translate } from 'localization';
import { TContextTagBlock } from '../TagBlock.index';
import { defaultMiddleware, useDefaultStaticStates } from 'muicomponents/src/ItemsListDialog/defaultListState';
import { useScrollListState } from 'utils/src/hooks';
import { mapFromUserList } from 'muicomponents/src/FieldRender/mapUserListFields';
interface IListUsersProps {
  context: TContextTagBlock
};

type TRadioValue = '' | 'skills' | 'interests';

const RadioButtons = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  columnGap: 10,
  marginBottom: 10
})


export const ListUsers:React.FC<IListUsersProps> = ({ context }) => {
  const [collectionType, setCollectionType] = useState<TRadioValue>('');
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (!event) return;
    setCollectionType(value as unknown as TRadioValue);
  }

  return (
    <>
      <RadioButtons>
        <FormLabel sx={{color: '#000'}}>
          <Translate i18nKey={'pryaniky.timeline.show'}/>
        </FormLabel>
        <RadioGroup row value={collectionType} onChange={handleChange}>
          <FormControlLabel value={''} label={<Translate i18nKey={'pryaniky.filter.tagcat.all'}/>} control={<Radio />}/>
          <FormControlLabel value={'skills'} label={<Translate i18nKey={'pryaniky.filter.tagcat.skills'}/>} control={<Radio />}/>
          <FormControlLabel value={'interests'} label={<Translate i18nKey={'pryaniky.filter.tagcat.interests'}/>} control={<Radio />}/>
        </RadioGroup>
      </RadioButtons>

      <ItemsList
        disableQR
        listHeaderProps={{ disableSearch: true, disableActionsButton: true }}
        listBodyProps={{ joinBody: true, disableBorder: true, disablePadding: true }}
        ItemComponent={ UsersListItem }
        BodyComponent={ ItemsListBody }
        listStateHook={({ defaultSelected }, middlewareParams) => {
          const {
            search,
            debouncedSearch,
            changeSearch,
            tabsValue,
            onChangeTab
          } = useDefaultStaticStates(middlewareParams.tabsValue || middlewareParams.tabs?.[0]?.id)

          // получаем список пользователей и фильтруем их по типу выбранной коллекции 
          const state = useScrollListState(async function (skipCount, count) {
            const result = await utils.API.users.list({
              tagId: context.tId, 
              count, 
              skipCount,
              showcontext: 'bytag', 
              search: debouncedSearch,
              extended: true,
              collectionType: collectionType
            }).r

            if (result.error_code !== 0) throw result;
  
            return result.data.map((item) => ({
              ...item,
              url: `/user/${item.id}`,
              actions: (item.actions || []),
              fields: mapFromUserList(item.fields || [])
            }))
          }, [debouncedSearch, collectionType], {
            selectableSingle: middlewareParams.selectableSingle
          })

          return {
            middlewareParams,
            state,
            defaultSelected,
            search,
            tabsValue,
            changeSearch,
            onChangeTab,
            middleware: defaultMiddleware
          }
        }}
      />
    </>
  )
}