/**
 * @packageDocumentation
 * @module ListItem
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
// import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from '../../../redux/connector';
// import i18n from '../../../localizations/i18n';

import { mapStateToProps, IListItemStateProps, cnItem, IItemProps, IItemOwnProps, mapActionsToProps } from './List-Item.index';
import './List-Item.scss';
import { withItemTypeCommon } from './_type/List-Item_type_common';
import { IStateType as IState } from 'redux/store';

export const ItemPresenter: React.FC<IItemProps> = ({ tag: TagName = 'div', children, className, onClick, style }) => (
  <TagName onClick={onClick} className={cnItem({}, [className])} style={style}>
    {children}
  </TagName>
);

export const Item = connect<IListItemStateProps, IDispatchProps, IItemOwnProps, IState>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
)(
  compose(
    withItemTypeCommon,
    )(
      ItemPresenter
      )
  );

interface IListItemProps {
  avatar?: React.ReactElement;
  info: React.ReactNode[];
  className?: string;
  refForward: any
}

export const ListItem: React.FC<IListItemProps> = ({ avatar, info, className, children }) => {
  return (
    <div className={cnItem({}, [className])}>
      {avatar}
      <div className={cnItem('Info')}>

        {info.filter(Boolean).map((col, key) => (
          <div key={key} className={cnItem('Col', { span: info.length })}>
            {col}
          </div>
        ))}
      </div>
      {children}
    </div>)
}
// (
//   React.useEffect(() => {
//     return function() {

//     }
//   });

// const ref = React.createRef();

//   React.useEffect(() => {
//     const item = {}
//     checkItemWidth(item);
//     window.addEventListener('', () => {checkItemWidth(item)})
//     return function cleanup() {

//     };
//   });

  // <div className={cnItem({}, [className])}>
  //   {avatar}
  //   <div className={cnItem('Info')}>

  //   {info.filter(Boolean).map((col, key) => (
  //     <div key={key} className={cnItem('Col', { span: info.length })}>
  //       {col}
  //     </div>
  //   ))}
  //   </div>
  //   {children}
  // </div>
// );
