import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { INewsActionHeader } from '../../types/baseNews'
///////////

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace TagDrop {

        export type IPresenterProps = IOwnProps

        export interface IOwnProps extends IClassNameProps {
            label?: string
            type?: string
            value?: any
            onChange: (selected: any) => void
        }
    }
}
export const cnClassName = cn('NewsTagDrop');