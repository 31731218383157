import { IEventsPropsType, mapStateToProps, mapDispatchToProps, TStateProps } from '../Events.index'
import React, { FC, memo } from 'react';
import { connect } from 'react-redux';
import './SliderView.scss'
import { removeMarkDownLink } from '../Utils'
import { withNewsContext } from 'News/contexts/news'
import { cn } from '@bem-react/classname';
import MDRender from 'uielements/src/CommonmarkRender'
import { Button } from 'uielements/src/Button/Button'
import { makeDate, makeDuration } from '../Utils'
import openNews from 'blocks/Dialogs/News/DialogNewsView/DialogNewsView'
import i18n from '../../../../localizations/i18n';
import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const cnClassName = cn('SliderEvent');
export const cnClassNameD = cn('SliderEventDetailItem');
export const cnClassNameH = cn('SliderEventHeader');

const useHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'block',
            color: '#fff',
            marginLeft: '-1px',
            marginRight: '-1px',
            paddingBottom: '8px',
            textAlign: 'center'
        }
    }),
);


type TDivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
const DetailItem: FC<{ className?: string, value: string, title: string } & TDivProps> = ({ className, title, value, ...props }) => {
    return <div {...props} className={cnClassNameD({}, [className])}>
        <div className={cnClassNameD('Title')}>{title}:</div>
        <div className={cnClassNameD('Value')}>{value}</div>
    </div>
}

const Header: FC<{ className?: string, startDateTime: string } & ButtonBaseProps> = ({ className, startDateTime, ...props }) => {
    const classes = useHeaderStyles()
    const start = new Date(startDateTime)
    return <ButtonBase {...{ ...props, component: 'div' }} classes={classes} className={cnClassNameH({}, [className, 'primaryColor1-bg'])}>
        <div className={cnClassNameH('Date')}>{makeDate.dateNoWeekday(start)}</div>
        <div className={cnClassNameH('Day')}>{makeDate.weekday(start)}</div>
    </ButtonBase>
}


export const SliderViewPresenter: FC<IEventsPropsType & TStateProps> = ({
    text,
    id,
    uevent,
    header,
    attachments
}) => {
    const {
        startDateTime,
        eventLocation,
        duration,
    } = uevent;

    async function open() {
        try {
            const result = openNews({ newsId: id })
        } catch (e) { }
    }

    const photo = (attachments || []).find((file: any) => file.action === 'ImagePreview')
    const title = removeMarkDownLink(header)
    const start = new Date(startDateTime)
    return <div className={cnClassName({}, ['slider-type-23'])}>
        <Header onClick={open} className={cnClassName('Header')} startDateTime={startDateTime} />

        <div className={cnClassName('Content')}>
            <div className={cnClassName('Detail')}>
                <div className={cnClassName('Times')}>
                    <DetailItem className={cnClassName('DetailItem')} title={i18n.t('time')} value={makeDate.time(start)} />
                    <DetailItem className={cnClassName('DetailItem')} title={i18n.t('duration')} value={makeDuration(duration)} />
                </div>
                <div className={cnClassName('Location')}>
                    <DetailItem
                        className={cnClassName('DetailItem')}
                        title={i18n.t('location')}
                        value={eventLocation} />
                </div>
            </div>

            <div onClick={open} className={cnClassName('Title')} title={title}>{title}</div>

            <div className={cnClassName('Text')}>
                <MDRender
                    disableEmojiOne
                    className={cnClassName('Render')}
                    type={'textOnly'}
                    disableShowMoreBtn
                    textCut={true}
                    maxCharCount={90}
                    source={text}
                />
            </div>

            {photo && <img
                className={cnClassName('Preview')}
                src={photo.previewUrl}
                alt="img" />}

            <div className={cnClassName('Footer')}>
                <Button theme={'unstyled'} className={cnClassName('OpenBtn', ['primaryColor3-text'])} onClick={open}>{i18n.t('pryaniky.timeline.news.readfull')}</Button>
            </div>
        </div>
    </div>
}

export const SliderView = withNewsContext(connect(
    mapStateToProps,
    mapDispatchToProps
)(memo(SliderViewPresenter)));
