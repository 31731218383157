import React, { FC } from 'react'
import { Skeleton } from 'uielements/src'
import './CoursePassing.scss'
import { cn } from '@bem-react/classname';
import { CourseHeader } from '../CourseHeader/CourseHeader'
import { Lesson } from '../Lesson/Lesson'
import { Unit } from '../Unit/Unit'


export const cnCoursePassing = cn('SkeletCoursePassing');

export const CoursePassing: FC<any> = ({ className }) => {

    return <div className={cnCoursePassing({}, ['Page', className])}>
        <CourseHeader />

        <div className="CourseLayout-Section">
            <div className="CourseLayout-Aside">
                <div className="LMSResults-Aside">
                    <Lesson />
                </div>
            </div>
            <div className="CourseLayout-Content">
                <Unit />
            </div>
        </div>
    </div>
}

