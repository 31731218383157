import { IBaseRouteProps } from 'i.model';
import { cn } from '@bem-react/classname';

export interface IPwdForgotProps extends IBaseRouteProps {
  tag?: 'div';
}

export interface IPwdForgotState {
  text?: string;
  request: boolean;
  error: string;
  companyName: string;
  login: string;
  [s: string]: any;
}

export const cnPwdForgot = cn('PwdForgot');