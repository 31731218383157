import React, { FC } from 'react'
import { IHeaderProps, cnHeader } from './Header.index'
import './Header.scss'
import { tC } from 'LMSModule/utils/i18n.adaptor'

export const HeaderPresenter: FC<IHeaderProps> = ({ className, attemptNumber, maxAttemptsCount, titleText,  remainingTime }) => {

    return <div className={cnHeader({}, [className])}>
        <div className={cnHeader('HeaderColumn')}>
            <div className={cnHeader('Title')}>{titleText || tC('testResults.2')}</div>
            {(attemptNumber && maxAttemptsCount) && <div className={cnHeader('Attempts')}>{tC('attempts.1')}{attemptNumber}/{maxAttemptsCount}</div>}
        </div>
        <div className={cnHeader('HeaderColumn')}>

        </div>
    </div>
}


export const Header = HeaderPresenter;