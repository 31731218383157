import React, { FC, useState } from 'react'
import { IQuestionItemProps, cnQuestionItem } from './Item.index'
import './Item.scss'
import { Icon } from 'uielements/src'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip'
import { isRightAnswer, generateHelpText, getAnswersText, isWaitModeration } from 'LMSModule/utils/utils.lms'
import { tC } from 'LMSModule/utils/i18n.adaptor'
import ResultQuestionItem from '../../ResultQuestionItem'

export const QuestionItemPresenter: FC<IQuestionItemProps & any> = ({
    className,
    text,
    qtype,
    answers,
    answersMap,
    questionTimer,
    resultAnswersMap,
    resultAnswers,
    question,
    qid,
    detailsRender,
    isNeedModeration = false
}) => {

    if (!resultAnswersMap) return <li className={cnQuestionItem({}, [className])}>no answer</li>
    // const right = isRightAnswer(answers, answersMap, resultAnswers);
    const right = isRightAnswer(answers, (qtype === 2 && isNeedModeration ? resultAnswersMap : answersMap), resultAnswers);;

    const wait = isWaitModeration(answers, resultAnswersMap);

    const answersTexts = getAnswersText(answers, resultAnswersMap, answersMap)//.join(', ')

    const helpText = generateHelpText(wait, qtype, isNeedModeration)

    const isExpand = qtype === 2 && isNeedModeration && !!detailsRender

    const isInfo = (qtype === 2 && !isNeedModeration)

    return <ResultQuestionItem
        className={className}
        text={text}
        answersTexts={answersTexts}
        helpText={helpText}
        isExpand={isExpand}
        isInfo={isInfo}
        isRight={right}
        isWait={wait}
    >{detailsRender(question)}</ResultQuestionItem>
}


export const QuestionItem = QuestionItemPresenter;