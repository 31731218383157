import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { Characteristics as CharacteristicsTypes, IShopItemImageFile } from 'utils/src/requests/models/api.shop'
///////////

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace Shop {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace PhotoViewer {

        export type IPresenterProps = IOwnProps

        /**
         * @todo check doubled props like imgUrls & images or currentImgUrl & currentImage
         */
        export interface IOwnProps extends IClassNameProps {
            currentImgUrl: string
            currentImage?: IShopItemImageFile
            imgUrls: string[]
            images: IShopItemImageFile[]
            onChange: (currentImgUrl: string, currentImage: any) => void
            isLoading?: boolean
        }
    }
}
export const cnClassName = cn('PhotoViewerMui');