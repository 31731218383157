import { IClassNameProps } from '@bem-react/core';
import { cnNewsView } from '../NewsView.index';


export interface IHeaderDataType {
  id: string;
  newsType: string;
  text: string;
  user: any;
  group: any;
  date: string;
  timeSplitter: string;
  pollExpires: string;
}

export interface IHeaderProps extends IClassNameProps {
  data: IHeaderDataType;
}

export interface IHeaderState {
  text?: string;
}

export const cnHeader = (...data: any) => {
  let name = 'Header';
  if (typeof (data[0]) === 'string') {
    name += '-' + data[0];
    data = data.slice(1);
  }
  return cnNewsView(name, ...data)
}