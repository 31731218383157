import React from 'react';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { cnNewsCreateDialog, INewsCreateDialogProps } from './Create.index';
// import {
//   DialogTitle,
//   DialogActions,
//   DialogContent,
// } from 'uielements/src';
// import { Button } from 'uielements/src/MaterialElements/Button/Button';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle';
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent';
import { Translate } from 'localizations/Translate';
import './Create.scss';
import { NewsCreateForm } from 'blocks/NewsCreate/Form/NewsCreateForm';
import { NewsEdit } from 'blocks/NewsEdit_new/NewsEdit'
import i18n from 'localizations/i18n';

const NewsCreateDialogPresenter: React.FC<INewsCreateDialogProps> = ({
  oneNewsType,
  workflowId,
  gId,
  useEdit,
  defaultData,
  handleAccept,
  handleClose
}) => {

  return <div className={cnNewsCreateDialog({}, ['CustomStyle'])}>
    <DialogTitle onClose={handleClose} className={cnNewsCreateDialog('Header')}>
      {/* <h4 className="my-1"> */}
      <Translate i18nKey={`pryaniky.createPost.dialog.title`} />
      {/* </h4> */}
    </DialogTitle>

    <DialogContent className={cnNewsCreateDialog('Body')}>
      {useEdit
        ? <NewsEdit
          create
          successMsg={i18n.t('pryaniky.createPost.thanks.user.success')}
          failMsg={i18n.t('pryaniky.createPost.thanks.user.fail')}
          cancleEdit={handleClose}
          compliteEdit={() => handleAccept({ success: true })}
          defaultData={defaultData}
          type={'thankUser'} />
        : <NewsCreateForm
          activePostId={oneNewsType as any}
          className={cnNewsCreateDialog('NewsCreate')}
          gId={gId}
          key={'NewsCreate'}
          workflowId={workflowId}
          oneNewsType={oneNewsType}
          cancleEdit={handleClose}
          onSendComplete={(success: boolean) => success && handleAccept({ success })}
          hideActions={false}
        />}
    </DialogContent>

    {/* <DialogActions>
      <Button color='primary' onClick={handleClose}>
        <Translate i18nKey='cancel' />
      </Button>
    </DialogActions> */}
  </div>
}

export const createNewsModal = createModal(NewsCreateDialogPresenter, {
  maxWidth: 'md',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  TransitionComponent: TransitionComponent,//LeftDirectionTransitionComponent,
  className: 'Pane BackdropOff Right',
  scroll: 'body'
});