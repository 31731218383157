import React from 'react'
import { ITagBlockProps } from './TagBlock.index'
import { TagBlockInner } from './TagBlock.styled';
import { TagBlock as TagBlockInfo } from 'blocks/InfoBlock/TagBlock/InfoBlock-TagBlock';
import { TagTabs } from './TagTabs/TagTabs'

export const TagBlock: React.FC<ITagBlockProps> = ({ context }) => {

  return (
    <TagBlockInner>
      <TagBlockInfo tId={context.tId}/>

      <TagTabs context={context} />
    </TagBlockInner>
  )
}