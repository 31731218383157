import { widgets } from '../i.widgets';

/**
 * объект с базовыми настройками виджетов
 */
export const widgetsBaseSettings: { [key in keyof typeof widgets.types]?: any} = {
  grid: {
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    cols: { lg: 12, md: 10, sm: 8, xs: 1, xxs: 1 },
    layouts: { lg: [] }
  }
}