import { Box, styled, Typography } from 'muicomponents/src';
import { Avatar } from 'muicomponents/src/Avatar';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const BorderLinearProgress = styled(LinearProgress)(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    height: 10,
    borderRadius: 5,
  })) as typeof LinearProgress
  
type ProgressProps = {
  customColor: string
}
export const ColoredBorderLinearProgress = styled(BorderLinearProgress)<ProgressProps>(({ customColor }) => ({
  [`& .${linearProgressClasses.bar}`]: {
    background: customColor,
  },
}))

export const CountTypography = styled(Typography)({
  fontSize: '12px',
}) as typeof Typography

export const FlexBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  margin: '0',
}) as typeof Box

export const MarkerAvatar = styled(Avatar)({
  position: 'absolute',
  top: '-14px',
  background: 'white',
  padding: '4px',
  boxSizing: 'border-box',
  zIndex: 1,
  boxShadow: '0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
}) as typeof Avatar

export const ProgressBox = styled(Box)({
  position: 'relative',
}) as typeof Box