import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { TabsSettingsProps } from './TabsSettings.index';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle';
import { DialogContent } from 'muicomponents/src/DialogParts/DialogContent';
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions';
import { keyBy } from 'lodash';
import { TValue } from 'utils/src';

export const useTabSettings = (tab: TabsSettingsProps['tabs'][number]) => {

    const [ tabData, setTabData ] = useState(tab);
    const tabsDataRef = useRef(tabData);
    useEffect(() => {
        tabsDataRef.current = tabData;
    }, [tabData]);

    const onChange = useCallback((data: Partial<typeof tabData>) => {
        setTabData({
            ...tabsDataRef.current,
            ...data
        });
    }, []);

    return {
        tabData,
        onChange
    };
};

export const useTabsSettings = (tabs: TabsSettingsProps['tabs']) => {

    const [ list, setList ] = useState(tabs.map(el => el.id));
    const listRef = useRef(list);
    useEffect(() => {
        listRef.current = list;
    }, [list]);

    const [ tabsSettings, setTabsSettings ] = useState(keyBy(tabs, (it) => it.id));
    const tabsSettingsRef = useRef(tabsSettings);
    useEffect(() => {
        tabsSettingsRef.current = tabsSettings;
    }, [tabsSettings]);

    const editTab = useCallback((tab: TValue<typeof tabsSettings>) => {
        setTabsSettings({
            ...tabsSettingsRef.current,
            [tab.id]: tab
        });
    }, []);

    const removeTab = useCallback((id: typeof list[number]) => {
        setList(listRef.current.filter(el => el !== id));
    }, []);

    const sortTabs = useCallback((newList: typeof list) => {
        setList(newList);
    }, []);

    const addTab = useCallback((tab: TValue<typeof tabsSettings>) => {
        setTabsSettings({
            ...tabsSettingsRef.current,
            [tab.id]: tab
        });
        setList([ ...listRef.current, tab.id ]);
    }, []);
    
    return {
        list,
        tabsSettings,
        editTab,
        removeTab,
        sortTabs,
        addTab
    };
};

export const TabsSettingsPr: FC<TabsSettingsProps> = ({
    tabs,
    handleAccept,
    handleClose
}) => {

    const [ tabsSettings, setTabsSettings ] = useState(tabs);

    return (
        <>
            <DialogTitle
                onClose={handleClose}
            ></DialogTitle>
            <DialogContent></DialogContent>
            <DialogActions
                onAccept={() => {
                    handleAccept([]);
                }}
                onClose={handleClose}
                acceptText={'yes'}
                closeText={'nope'}
            />
        </>
    );
};

export const TabsSettings = createModal(TabsSettingsPr, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: '#fff',
    }
  },
  TransitionComponent,
  scroll: 'body'
});