import React from 'react';


import { cnWidget, cnWidgetMui,IWidgetProps } from '../Widget.index';

import './Widget_type_quests.scss';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { QuestsWidget } from 'blocks/QuestsWidget/QuestsWidget';


const type = 'quests';

export default class WidgetTypeQuestsPresenter extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
  
    return <QuestsWidget {...this.props} widgetId={this.props.widget.id} settings={this.props.widget.settings} className={cnWidgetMui({type})} contexts={this.relations.concat(this.props.widget.id)} tag={widgets.components.common} />
  }

}