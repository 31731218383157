import React, { FC, RefForwardingComponent, useState, useEffect, useMemo, useRef, useLayoutEffect, Fragment } from 'react';
import { NPagesMenuMuiBar, cnPagesMenuMuiBar } from './PagesMenuMuiBar.index';
import * as utils from 'utils/src/utils';
import { MenuItemBox, CustomDivider } from './PagesMenuMuiBar.styled'
import './PagesMenuMuiBar.scss';
import { PageComments } from './../PageCommentsNew/PageCommentsNew';
import { MenuInfoItem } from './MenuInfoItem/MenuInfoItem'
import { useDispatch, useSelector } from "react-redux";
import { likeWikiRequest } from "redux/actions/WikiList";
import { WikiEdit } from './EditWiki/EditWiki'
import { PageHistory } from './PageHistoryNew/PageHistoryNew'
import { WikiEditDialogPresenter } from 'blocks/Dialogs/WikiCreateNew/WikiEdit/WikiEditNew';


const PagesMenuMuiBarPresenter: RefForwardingComponent<any, NPagesMenuMuiBar.Props> = ({
    id,
    path,
    zIndex,
    stayOpened,
    opened,
    items,
    onClose,
    editOpen,
    // likeWikiRequest,
    pageInfo,
    isWkilistModerator,
    ...props
}, ref) => {
    const barGroupName = useMemo(() => 'menuBar', []);
    // const closeMenu2 = useMemo(() => {

    //     setShowInfo(false)
    //     setShowCom(false)
    //     setShowEdit(false)
    //     setShowJornal(false)
    //     if (!showEdit) onClose()
    // }, [opened]);

    const [showInfo, setShowInfo] = useState(false)
    const [showCom, setShowCom] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showJornal, setShowJornal] = useState(false)

    const closeMenu = () => {
        setShowInfo(false)
        setShowCom(false)
        setShowEdit(false)
        setShowJornal(false)
        onClose()

    }
    useEffect(() => {
        if (!opened) {
            setShowInfo(false)
            setShowCom(false)
            setShowEdit(false)
            setShowJornal(false)
            editOpen(false)

        }
    }, [opened])

    const clickAction = (action: string) => {
        switch (action) {
            case 'openCloseButton':

                closeMenu()
                editOpen(false)

                break;
            case 'info':
                setShowInfo(!showInfo)
                setShowCom(false)
                setShowEdit(false)
                setShowJornal(false)
                editOpen(false)

                break;
            case 'comments':
                // archivedPage(props.data)
                editOpen(false)

                setShowCom(!showCom)
                setShowInfo(false)
                setShowEdit(false)
                setShowJornal(false)
                break;
            case 'wikiJornal':
                // archivedPage(props.data)
                editOpen(false)

                setShowInfo(false)
                setShowCom(false)
                setShowEdit(false)
                setShowJornal(!showJornal)

                break;
            case 'editWiki':

                // archivedPage(props.data)
                setShowInfo(false)
                setShowCom(false)
                setShowEdit(!showEdit)
                setShowJornal(false)
                break;

            default:
                break;
        }
    }

    const barRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        if (!barRef.current) return;
        const layout = document.getElementById('Layout');
        layout!.style.paddingRight = barRef.current.clientWidth + 'px';
        const isMobile = utils.isMobile();
        if(isMobile) {
            // this need for react swipe to change swiper default width
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 50);
        }

    }, [barRef?.current?.clientWidth]);

    useEffect(() => {
        return () => {
            const layout = document.getElementById('Layout');
            layout!.style && (layout!.style.paddingRight = '0px');
        }
    }, []);


    return (
        // TODO change to box
        <div
            ref={(el) => {
                typeof ref === 'function'
                    ? ref(el)
                    : (ref as any)!.current = el;
                barRef.current = el;
            }}
            className={cnPagesMenuMuiBar({ id, stayOpened, opened }, [props.className, 'primaryColor1-bg'])}
        >


            {
                items.map((el: any) => {
                return <Fragment key={`key-${el.action}`} >
                    <MenuItemBox
                        className={cnPagesMenuMuiBar(`MenuActionButton ${el.action} ${el.action === "info" && showInfo ||
                            el.action === "editWiki" && showEdit ||
                            el.action === "comments" && showCom ||
                            el.action === "wikiJornal" && showJornal ? "active" : ""}`)}
                        onClick={() => clickAction(el.action)}>
                        {el.content}

                    </MenuItemBox>

                    {(showInfo && opened && el.action === "info") && <MenuInfoItem pageInfo={pageInfo} />}
                    {(showCom && el.action === 'comments' && opened) && <PageComments
                        isShown={showCom}
                        data={pageInfo?.news} />
                    }
                    {showJornal && el.action === 'wikiJornal' && pageInfo && opened && <PageHistory id={pageInfo?.id} />}
                    {
                        showEdit
                        && el.action === 'editWiki'
                        && pageInfo
                        && opened
                        && <WikiEdit
                            data={pageInfo}
                            closeWikiEdit={() => setShowEdit(false)}
                            isWkilistModerator={isWkilistModerator} 
                            isEdit={(value) => editOpen(value)}
                            id={pageInfo?.id}
                            context={pageInfo}
                        />
                    }

                    {opened && <CustomDivider />}
                </Fragment>})
            }


        </div>
    )
}

export const PagesMenuMuiBar = React.forwardRef(PagesMenuMuiBarPresenter);