import { Button, Checkbox, FormControlLabel } from 'muicomponents/src';
import { Box } from 'muicomponents/src/Box';
import { styled } from 'muicomponents/src/mui/system';
import { TreeItem, treeItemClasses } from 'muicomponents/src/Tree';

export const SOCOrgchartUnitsBox = styled(Box)({
    padding: 16,
    ['& > *:nth-child(n + 2)']: {
        marginTop: 8
    }
}) as typeof Box;

export const SOCOrgchartUnitsLabelBox = styled(Box)({
    display: 'flex',
    alignItems: 'flex-start'
}) as typeof Box;

export const SOCOrgchartUnitsLabelTextBox = styled('span')({
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    overflow: 'hidden'
});

export const StyledTreeItem = styled(TreeItem)({
    [`& .${treeItemClasses.content}`]: {
        alignItems: 'flex-start',
        padding: 4,
        [`& .${treeItemClasses.iconContainer}`]: {
            marginTop: 2,
            marginRight: 0
        }
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 8
    }
}) as typeof TreeItem;

export const StyledCheckbox = styled(Checkbox)({
    padding: 0,
    marginRight: 4,
    marginTop: 1
}) as typeof Checkbox;

export const StyledFormControlLabel = styled(FormControlLabel)({
    marginLeft: 0
}) as typeof FormControlLabel;

export const ApplyButton = styled(Button)({
    position: 'sticky',
    bottom: 4
}) as typeof Button;