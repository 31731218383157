import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
// import { IReferralData } from 'utils/src/requests/models/api.tagscloud';
import { IStateType } from 'redux/store';

export interface IReferralProps extends IClassNameProps, IReferralOwnProps, IMapStateToProps {}

export interface IReferralOwnProps {
  tag?: React.ComponentType | 'div';
  context: any;
}

export const mapStateToProps = (state: IStateType) => ({

})

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

export const cnReferral = cn('Referral');