import { NWidgetsActions } from "./actions.types";

export const WIDGETS_SET_DATA = 'WIDGETS_SET_DATA';
export const WIDGETS_UPDATE_DATA = 'WIDGETS_UPDATE_DATA';
export const WIDGETS_GET_DATA = 'WIDGETS_GET_DATA';
export const WIDGETS_ADD_WIDGET = 'WIDGETS_ADD_WIDGET';
export const WIDGETS_REMOVE_WIDGET = 'WIDGETS_REMOVE_WIDGET';
export const WIDGETS_DRAG_WIDGET = 'WIDGETS_DRAG_WIDGET';
export const WIDGETS_SET_WIDGET_SETTINGS = 'WIDGETS_SET_WIDGET_SETTINGS';
export const WIDGETS_UPDATE_WIDGET_SETTINGS = 'WIDGETS_UPDATE_WIDGET_SETTINGS';
export const WIDGETS_SET_ACTIVE_PAGE = 'WIDGETS_SET_ACTIVE_PAGE';
export const WIDGETS_SET_VIEW_TYPE = 'WIDGETS_SET_VIEW_TYPE';
export const WIDGETS_UPDATE_WIDGET = 'WIDGETS_UPDATE_WIDGET';
export const WIDGETS_SET_CONTEXT = 'WIDGETS_SET_CONTEXT';
export const WIDGETS_UPDATE_CONTEXT = 'WIDGETS_UPDATE_CONTEXT';
export const WIDGETS_SET_CONTEXTS = 'WIDGETS_SET_CONTEXTS';
export const WIDGETS_UPDATE_CONTEXTS = 'WIDGETS_UPDATE_CONTEXTS';

const action = <Action extends NWidgetsActions.Actions> (type: Action['type']) => (payload: Action['payload']) => ({
    type,
    payload
});

/**
 * set reducer data with REWRITE all old data
 */
export const widgetsSetData = action<NWidgetsActions.SetDataAction>(WIDGETS_SET_DATA);

/**
 * update reducer data with MERGE all old data
 */
export const widgetsUpdateData = action<NWidgetsActions.UpdateDataAction>(WIDGETS_UPDATE_DATA);

/**
 * add new widget to widget column
 */
export const widgetsAddWidget = action<NWidgetsActions.AddWidgetAction>(WIDGETS_ADD_WIDGET);

/**
 * remove widget from widget column
 */
export const widgetsRemoveWidget = action<NWidgetsActions.RemoveWidgetAction>(WIDGETS_REMOVE_WIDGET);

/**
 * drag widget action
 */
export const widgetsDragWidget = action<NWidgetsActions.DragWidget>(WIDGETS_DRAG_WIDGET);

/**
 * set widget settings with REPLACE logic
 */
export const widgetsSetSettings = action<NWidgetsActions.SetWidgetSettingsAction>(WIDGETS_SET_WIDGET_SETTINGS);

/**
 * update widget settings with merge logic
 */
export const widgetsUpdateSettings = action<NWidgetsActions.UpdateWidgetSettingsAction>(WIDGETS_UPDATE_WIDGET_SETTINGS);

/**
 * set view type of widgets
 */
export const widgetsSetViewType = action<NWidgetsActions.SetViewTypeAction>(WIDGETS_SET_VIEW_TYPE);

/**
 * update any widget data
 */
export const widgetsUpdateWidget = action<NWidgetsActions.UpdateWidgetAction>(WIDGETS_UPDATE_WIDGET);
