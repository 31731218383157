import React, { FC, useMemo } from 'react'
import FieldRender from '../FieldRender/FieldRender'


export const FieldsRender: FC<any> = ({ cat, fieldsCategoryes, fieldsSet, user, onChangeFields }) => {


    return <>
        {cat && <h4>{cat}</h4>}
        {fieldsCategoryes[cat].map((fieldId: any) => <FieldRender
            onChangeFields={onChangeFields}
            user={user}
            fieldsSet={fieldsSet}
            fieldId={fieldId}
        />)}
    </>

}

export default FieldsRender