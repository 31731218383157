import { cnInfoBlock } from '../InfoBlock.index';
import { IInfoProps } from '../AbstarctInfoElement/AbstractInfoElement.index';
import { updateContext } from 'redux/actions/Widgets';
import { getUser, setStatus, setUserStatus, subscribeByUser } from 'utils/src/CommonRedux/users/actions'
import { bindActionCreators } from 'redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getAppSettings } from 'utils/src/CommonRedux/base/selectors';

export interface IUserProps extends IBaseUserProps, IUserStateProps, ReturnType<typeof mapDispatchToProps> {//, IActionsDispatch {

}

export interface IBaseUserProps {
  userId: string;
  togleEdit: () => void;
}

export interface IUserState {
  text?: string;
}

export interface IUserStateProps {
  // store: any;
  enabledThanks: boolean;
  user: any;
  chatEnable: boolean;
  authUser: any
  currencyFormats: any
  birthdayFormat: string
}

export const mapStateToProps = (state: any, props: IBaseUserProps) => {
  let user = {};
  if (props.userId in state.users.users) {
    user = state.users.users[props.userId]
  } else if (props.userId in state.users.alias) {
    user = state.users.users[state.users.alias[props.userId]]
  }
  return {
    // store: {
    //   ...state.store,
    //   authUser: getAuthUser(state)
    // },
    authUser: getAuthUser(state),
    // i18nNS: state.i18n.namespace,
    enabledThanks: state.store.appSettings && state.store.appSettings.enabledModules.thanks,
    user,
    chatEnable: state.store.appSettings.enabledModules.chat,
    currencyFormats: state.store.appSettings,
    birthdayFormat: getAppSettings(state).birthdayFormat
  }
}

/*export interface IActionsDispatch {
  updateContext?: any;
  getUser: any
}*/

/*export const mapActionsToProps: IActionsDispatch = {
  updateContext,
  getUser
};*/
export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  updateContext,
  getUser,
  setStatus,
  subscribeByUser,
  setUserStatus
}, dispatch);

export const cnUser = (...data: any) => {
  let name = 'User';
  if (typeof (data[0]) === 'string') {
    name += '-' + data[0];
    data = data.slice(1);
  }
  return cnInfoBlock(name, ...data)
}

export const cnInfo = cnInfoBlock;
