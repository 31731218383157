import React from 'react';
import { Skeleton } from 'uielements/src';
import './ListItem-Skeleton.scss';

export const ListItemSkeleton: React.FC = () => (
  <div className="ListItem-Skeleton">
    <Skeleton shape="circle" style={{ marginRight: 12 }} />
    <div style={{ flexGrow: 1 }}>
      <Skeleton shape="line" style={{ width: '80%' }} />
      <Skeleton shape="line" style={{ height: 12 }} />
      <Skeleton shape="line" style={{ height: 12 }} />
    </div>
  </div>
);
