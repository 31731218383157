import { defaultNews } from 'News/redux/reducer/default'
import { TReducerWithCreativetasks } from './reducer.d'
// import { TNewsReducerActions } from 'News/redux/actions/types'
import { TNewsReducerActions } from './actions.d'
import actions from './actionsType'
import { omit } from 'lodash'

const сreativetasksDefaultNews: TReducerWithCreativetasks = {
    ...defaultNews,
    CTDistributeForms: {},
    СTRewardedComments: {}
}

export const сreativetasksSubReducer = (state = сreativetasksDefaultNews, action: TNewsReducerActions): TReducerWithCreativetasks => {
    switch (action.type) {

        case actions.CANCLE_ALL_REWARD: {
            const { newsId } = action.payload

            const rewardedComments = (state.СTRewardedComments?.[newsId] || {})
            const values = Object.values(rewardedComments || {})
            const summary = values.reduce((acc, cur) => acc + cur, 0)

            const oldFund = state.values[newsId].creativeTask.fund
            const fund = oldFund + summary
            return {
                ...state,
                values: {
                    ...state.values,
                    [newsId]: {
                        ...state.values[newsId],
                        creativeTask: {
                            ...state.values[newsId].creativeTask,
                            fund
                        }
                    }
                },
                СTRewardedComments: {
                    ...(state.СTRewardedComments || {}),
                    [newsId]: {}
                }
            }
        }

        case actions.PATH_REWARD: {
            const { data, newsId } = action.payload
            const rewardedComments = (state.СTRewardedComments?.[newsId] || {})
            return {
                ...state,
                СTRewardedComments: {
                    ...(state.СTRewardedComments || {}),
                    [newsId]: {
                        ...rewardedComments,
                        ...data
                    }
                }
            }
        }

        case actions.CHANGE_REWARD_REPLY: {
            const { data, newsId } = action.payload
            const { replyId, value } = data
            const rewardedComments = (state.СTRewardedComments?.[newsId] || {})
            const newRewardedComments = value === 0 ? omit(rewardedComments, [replyId]) : { ...rewardedComments, [replyId]: value }

            return {
                ...state,
                СTRewardedComments: {
                    ...(state.СTRewardedComments || {}),
                    [newsId]: {...newRewardedComments}
                }
            }
        }

        case actions.CHANGE_FORM: {
            const { newsId, data } = action.payload
            return {
                ...state,
                CTDistributeForms: {
                    ...state.CTDistributeForms,
                    [newsId]: {
                        ...(state.CTDistributeForms?.[newsId] || {}),
                        ...data
                    }
                }
            }
        }

        default:
            return state;
    }
}