// import { IClassNameProps } from '@bem-react/core'
import { cn } from '@bem-react/classname';
import { IDispatchProps, IStateProps } from '../../redux/connector';
import { RouteComponentProps } from 'react-router';
import { IStateType as IState } from 'redux/store';

/**
 * Global JsonForms
 */

export interface IJsonFormsProps extends IDispatchProps {}

export interface IJsonFormsState {
  placeholder?: string;
}

export const mapStateToProps = (state: IState) => ({
  auth: state.store.auth,
  edit: state.store.edit,
  loading: state.store.loading as boolean,
});

export type IJsonFormsStateProps = ReturnType<typeof mapStateToProps>;

export const cnJsonForms = cn('JsonForms');