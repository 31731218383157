import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Button, Spinner, SplitButton, Icon } from 'uielements/src'
import Search from './Filters/Search'
import uuid from 'uuid/v1';
import { CreateTrackDialog } from '../../dialogs/CreateTrack/CreateTrack'
import Item from './Item'
import ListPlaceholder from '../../components/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import Checkbox from '@material-ui/core/Checkbox';
import { ISplitButtonOption } from 'uielements/src/SplitButton/SplitButton.index'
import { IButtonProps } from 'uielements/src/Button/Button.index'
import withConfirmDialog, { IWithLoadSpin } from 'blocks/HOCs/Button/withConfirmDialog'
// import { TeacherProfile } from '../TeacherProfile/TeacherProfile'
import { TracksHeader } from 'LMSModule/components/TracksHeader/TracksHeader'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'


const LoadButton = withLoadSpin(Button)
const ConfirmBtn = withConfirmDialog<IButtonProps>(Button)

export const Presenter: FC<IPresenterProps> = ({
    className,
    loadTracksList,
    createTrack,
    count,
    isFinished,
    isLoading,
    data,
    keys,
    selected,
    selectTrack,
    removeTrack
}) => {
    const currentUser = useSelector(getCurrentUser)
    // const [create, setCreate] = useState(false)
    const [search, setSearch] = useState('')
    useEffect(() => {
        loadTracksList()
    }, [])
    useEffect(() => {
        loadTracksList({ search,clean: true })
    }, [search])
    const loadMore = () => loadTracksList({ count, skipCount: keys.length })
    const triggerRef = useInfiniteScroll({
        loadMore,
        isLoading,
        isFinished,
    });
    const onSelectAll = () => {
        selectTrack({ ids: keys })
    }
    const onRemoveTracks = () => {
        removeTrack({ id: selected })
    }
    const actions: ISplitButtonOption<IWithLoadSpin & IButtonProps>[] = [
        {
            title: tTr('item.context.delete'),
            buttonProps: {
                disabled: selected.length === 0,
                onClick: (e: any) => false,
                confirmBtnText: tTr('item.context.delete.confirmBtnText'),
                confirmTitle: tTr('item.context.delete.confirmTitle'),
                onConfirm: onRemoveTracks
            },
        },
    ];
    return <div className={cnClassName({}, ['Page', className])}>
        <TracksHeader className={cnClassName('Header')} currentUrl={'TracksManage'} showSwitch={currentUser.baseData.isAdmin} />
        {/* <div className={cnClassName('Aside')}>
            aside
        </div> */}

        <div className={cnClassName('Section')}>
            <div className={cnClassName('SearchBox')}>
                <div className={cnClassName('ActionsBox')}>
                    <Checkbox
                        className={cnClassName('Select')}
                        checked={selected.length === keys.length}
                        onChange={onSelectAll}
                        name="selectAllItem"
                        color="primary"
                    />
                    <SplitButton<IWithLoadSpin & IButtonProps> isOpen={() => { }} className={cnClassName('ActionMenu')}
                        // button={<Button target={'_blank'} theme={'unstyled_center'} className={cnClassName('ActionBtn')}>
                        //     <Icon icon={'cog'} />
                        // </Button>}
                        ItemComponent={ConfirmBtn}
                        options={actions} />
                </div>
                <Search className={cnClassName('Search')} isLoading={isLoading} search={search} onChange={setSearch} />
                <Button
                    main
                    className={cnClassName('CreateBtn')}
                    onClick={() => {
                        CreateTrackDialog({})
                    }}>{tTr('create.track')}</Button>
                {/* <CreateTrackDialog isShown={create} onClose={() => setCreate(false)} /> */}
            </div>

            <div className={cnClassName('List')}>
                <ListPlaceholder
                    className={cnClassName('Placeholder')}
                    isFinished={isFinished}
                    isLoading={isLoading}
                    itemsCount={keys.length}
                    titleEmpty={tTr('tracks.empty.title')}
                    textEmpty={tTr('tracks.empty.text')}
                    textLoad={tTr('tracks.load.text')}
                >
                    {keys.map(id => <Item selected={selected.includes(id)} data={data[id]} />)}
                    {!isFinished && (
                        <LoadButton
                            forwardedRef={triggerRef}
                            isLoading={isLoading}
                            className={cnClassName('Trigger')}
                            onClick={isLoading ? void 0 : loadMore}>
                            {tTr('tracks.more.triger')}
                        </LoadButton>
                    )}
                </ListPlaceholder>
            </div>
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
