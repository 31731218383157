import { cn } from "@bem-react/classname";
import { IDialog } from "../../DialogWrapper.index";
import { IDispatchProps } from "../../../../redux/connector";
// import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IBodyProps } from 'utils/src/DialogCreator';
import { IClassNameProps } from '@bem-react/core';
import { IWikiItem } from 'utils/src/requests/models/api.wikilist';
import { PageModelList, PageTypes } from "utils/src";

export interface IWikiEditProps extends IWikiEditOwnProps, IBodyProps, IWikiEditDispatchProps, IWikiEditStateProps {
    // isShown: boolean;
    // onClose: () => void;
    className?: string;
}
export interface IWikiEditOwnProps extends IDialog {
    sendDataForEditPage?: (data: any) => void;
    context?: any;
    id: string;
    updateItemList: (item: PageModelList, newItem: PageModelList) => void;
    // админом имеющим права на изменение всего кантента в любом типе викистраницы.папки является админ всего портала или Модератор Базы Знаний.
    isAdmin: boolean;
    parentFolderModerators?: any[];
    parentFolderViewers?: any[];
};

export type IWikiEditDispatchProps = ReturnType<typeof mapWikiEditDispatchToProps>

export const mapWikiEditDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export type IWikiEditStateProps = ReturnType<typeof mapWikiEditStateToProps>;

export const mapWikiEditStateToProps = () => ({});

export const cnWikiEditDialog = cn("WikiEditDialogMui");


