import { Status } from '../IdeaType';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { pathNews, addComments, upgradeNews } from 'News/redux/actions';

export interface ICommentProps extends ICommentOwnProps, IMapStateToProps {}

interface ICommentOwnProps {
    ideaId: string;
    status: Status
    save: () => void;
    cancle: () => void;
}


export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    pathNews,
    addComments,
    upgradeNews
}, dispatch)

type IMapStateToProps = ReturnType<typeof mapDispatchToProps>;