// import {User, BaseNews, defaultBaseNews,  defaultUser, defaultGroup, Group} from '../BaseType'
import { IBaseNews, createDefaultNews } from 'News/types/baseNews'
export interface LMSNewsNews extends IBaseNews {
    newstype: 5;
    learningTrack: {
        imgUrl: null | string,
        name: string
        objectId: string
        type: string
        url: string
    }
}

export const defaultNewsNews: LMSNewsNews = {
    ...createDefaultNews(),
    newstype: 5,
    learningTrack: {
        imgUrl: null,
        name: '',
        objectId: '',
        type: '',
        url: ''
    }
}

export const createDefaultModel = (): LMSNewsNews => ({
    ...createDefaultNews(),
    newstype: 5,
    learningTrack: {
        imgUrl: null,
        name: '',
        objectId: '',
        type: '',
        url: ''
    }
})