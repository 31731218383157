import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStartedSurvey, startSurvey as startSurveyAction } from 'redux/sagas/Surveys/actions';
import { getSurveyStateByKey } from 'redux/sagas/Surveys/selectors';
import { SurveyStartProps } from './SurveyStart.index';

export const useSurveyStart = (props: Pick<SurveyStartProps, 'preview'>) => {

  const dispatch = useDispatch();

  const data = useSelector(getSurveyStateByKey('surveyStartLoading'));

  const startSurvey = useCallback(() => {
    dispatch(startSurveyAction({
      preview: props.preview
    }));
    // dispatch(setStartedSurvey(true));
  }, [props.preview]);

  return {
    loading: data.surveyStartLoading,
    startSurvey
  }
};