import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { likeWikiRequest } from "redux/actions/WikiList";
import { bindActionCreators } from 'redux';

export const cnPagesMenuMui = cn('PagesMenuMui');

export const MENU_HIDE_BREAKPOINT = 768;

export namespace NPagesMenuMui {

  export interface Props extends IClassNameProps, IWikiDispatchProps, IWikiStateToProps {
    stayOpened?: boolean;
    pageInfo: any;
    isEditable: boolean;
    /**
  * флаг отвечающий является ли пользователь Модератором Базы знаний
  */
    isWkilistModerator: boolean
  }
}
export type IWikiDispatchProps = ReturnType<typeof mapWikiInfoDispatchToProps>;

export const mapWikiInfoDispatchToProps = (dispatch: any) => bindActionCreators({
  likeWikiRequest,
}, dispatch);
export type IWikiStateToProps = ReturnType<typeof mapWikiInfoStateToProps>;



export const mapWikiInfoStateToProps = (state: any, props: any) => ({
  // news: getNewsById(props.data.news)(state)//state.news.news[props.data.news],
  // news: getNewsById(props.pageInfo.news)(state)//state.news.news[props.data.news],
  // isAdmin: getAuthUser(state).baseData.isAdmin
});