import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeBannerProps } from './Widget_type_banner.index';
import { Banners } from '../../Banners/Banners';

import { default as WidgetPresenter } from '../Widget';

import { widgets } from "i.widgets";

// const types = {
//   common: require('blocks/Code/Code').Code,
// }

const type = 'banner';

/**
 * виджет всегда запрашивает данные по своему айдишнику, если данных нет, то не рендерится
 */
export default class WidgetTypeBannerPresenter extends WidgetPresenter<IWidgetTypeBannerProps> {

  public render() {
    if (!this.props.widget) return null;
    // const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    // const { campain, banner } = this.props.widget?.settings || {};
    // if(!campain) return null;
    return <widgets.components.common {...this.props} className={cnWidget({ type })} context={this.props.wcontext} relations={this.props.widget.relations}>
      <Banners
        {...this.props}
        settings={this.props?.widget?.settings ? this.props?.widget?.settings : undefined}
        widgetId={this.props.widget.id} />
    </widgets.components.common>
    // return <Component {...this.props} {...this.props.widget.settings} rounded className={cnWidget({ type })} context={this.props.wcontext} relations={this.props.widget.relations} data={this.props.widget.data} tag={widgets.components.common} />
  }

}
