import React, { FC, useState } from 'react'
import {
    cnClassName,
    ISubscribeUserOwnProps,
    ISubscribeUserResult,
    ISubscribeUserPresenterProps,
    ISubscribeUserStateProps,
    ISubscribeUserDispatchProps,
    mapDispatchToProps,
    mapStateToProps
} from './interfaces'
import './style.scss'
import { createModal, TransitionComponent } from '../../dialogs/modal'
import DialogBody from '../../dialogs/modal/components/DialogBody'
import SingleAutocomplete from 'uielements/src/MaterialElements/SingleAutocomplete'
import withTrackSuggester from 'blocks/HOCs/SingleAutocomplete/withTrackSuggester'
import { connect } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    Button,
    Icon,
    CheckboxInput,
    Textarea, Spinner
} from 'uielements/src';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import { tTr } from 'LMSModule/utils/i18n.adaptor'

const TrackSelect = withTrackSuggester(SingleAutocomplete)

export const Presenter: FC<ISubscribeUserPresenterProps> = ({
    handleAccept,
    handleClose,
    className,
    addUsersToTrack,
    trackId,
    ...other
}) => {
    const [users, setUsers] = useState<any[]>([])
    const accept = () => {
        addUsersToTrack({
            trackId,
            userIds: users.map((u: any) => u.id),
            users
        })
        handleAccept({
            users
        })
    }
    return <div>
        <DialogTitle onClose={handleClose}>
            <h4 className="my-1">{tTr('dialog.add_user.title')}</h4>
        </DialogTitle>
        <div className={cnClassName({}, [className])}>
            {/* <div>
                select user
            </div> */}
            <FormControl fullWidth>
                <Autocomplete
                    fullWidth
                    excludeMe={false}
                    type={'usersgroups'}
                    label={tTr('add_user.input.label')}
                    value={users}
                    onChange={setUsers}
                />
            </FormControl>
        </div>
        <DialogActions>
            <Button main disabled={users.length === 0} onClick={accept}>{tTr('dialog.add_user.confirm')}</Button>
            <Button onClick={handleClose}>
                {tTr('dialog.add_user.close')}
            </Button>
        </DialogActions>
    </div>
}
const Connected = connect<ISubscribeUserStateProps, ISubscribeUserDispatchProps, ISubscribeUserOwnProps, any>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);

export default createModal<ISubscribeUserResult, ISubscribeUserOwnProps>(Connected, {
    maxWidth: 'sm',
    PaperProps: {
        style: {
            backgroundColor: 'transparent',
            overflowY: 'unset'
        }
    },
    TransitionComponent
});
