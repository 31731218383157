import { useEffect } from "react";
import { GetActions, PostActions } from "./actions";
import { PostDataToFrame } from "./ExternalActions.hooks";

const rusSubsets = ["cyrillic", "cyrillic-ext"];

const errorEditorLog = (action: string, e: any) => {
    console.error(`external actions error at ${action} with error: ${e}`);
};

export const useEditorActions = () => {

    const postSubsetsPromise = PostDataToFrame(GetActions.getCompanySettings, PostActions.postCompanySettings, rusSubsets);
    useEffect(() => {
        postSubsetsPromise()
        .then((d) => {
            console.log('subsets sended');
        })
        .catch((e) => {
            errorEditorLog('postCompanySettings', e);
        });
    }, [postSubsetsPromise]);

    return null;
};