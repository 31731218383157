import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Button, Spinner } from 'uielements/src'
import { Sessions } from './Filters/Sessions/Sessions'
import { Status } from './Filters/Status/Status'
import { Questions } from './Filters/Questions/Questions'
import { Users } from './Filters/Users/Users'
import Courses from './Filters/Courses'
import { AnswerList } from '../AnswerList/AnswerList'

export const Presenter: FC<IPresenterProps> = ({
    className,
    courseId,
    filters,
    allowFilters = {
        Sessions: true,
        Users: true,
        Status: true,
        Questions: true
    },
    hardFilters = {},
    setModerationFilters,
    sessionsIds,
    normalSessions,
    questionsIds,
    questions,
    setModerationCid
}) => {
    // const [composeFilters, setFilters] = useState({ ...filters, ...hardFilters })
    // useEffect(() => {
    //     setFilters({ ...filters, ...hardFilters })
    // }, [filters, hardFilters])
    const composeFilters = Object.assign(filters, hardFilters)

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Aside')}>
            {setModerationCid && <Courses setData={(val) => { setModerationCid(val[0] ? val[0] : '') }} />}
            {(allowFilters.Sessions && courseId) && <Sessions {...{ setModerationFilters, sessionsIds, normalSessions, currentFilters: filters['filter.sessionIds'] }} cid={courseId} />}
            {allowFilters.Users && <Users {...{ currentFilters: filters['filter.filterByUserIds'], setModerationFilters }} />}
            {allowFilters.Status && <Status {...{ setModerationFilters, currentFilters: filters['filter.filterByStatus'] }} />}
            {allowFilters.Questions && <Questions {...{ setModerationFilters, questionsIds, questions, currentFilters: filters['filter.filterByQuestionIds'] }} />}
        </div>

        <div className={cnClassName('Section')}>
            <AnswerList courseId={courseId} filters={composeFilters} />
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
