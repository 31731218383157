import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IPostContentRenderProps extends IClassNameProps {
  data: string;
  maxLen: number;
  offsetLen: number;
  noCut?: boolean;
}

export interface IPostContentRenderState {
  text: string;
  isOpen: boolean;
}

export interface IPositionType {
  start: number;
  end: number;
  length: number;
  value: RegExpMatchArray;
}

export const cnPostContentRender = cn('PostContentRender');