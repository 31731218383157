import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { Button } from 'uielements/src';
import { withNewsContext } from '../../contexts/news'

import NewsHeader from '../NewsHeader'
import NewsActions from '../NewsActions'
import { Attachments } from 'uielements/src/Attachments/Attachments';
import { Preview } from 'uielements/src/Preview/Preview';
import * as utils from 'utils/src/utils';
import { LinkLayout } from 'blocks/NewsTypes/common/LinkLayout/LinkLayout';

type IPresenterProps = News.BaseView.IPresenterProps
type IOwnProps = News.BaseView.IOwnProps
type IDispatchProps = News.BaseView.IDispatchProps
type IStateProps = News.BaseView.IStateProps
type IState = News.BaseView.IState


export const Presenter: FC<IPresenterProps> = ({
    attachments,
    id,
    linkPreviewImgUrl,
    systemNewsActionHtml,
    children
}) => {

    useEffect(() => {

    }, [])

    return <div className={cnClassName({}, [])}>
        <div className={'NewsLayout'}>
            <NewsHeader />

                <div className={cnClassName('Content')}>
                    {children}
                </div>
            

            <LinkLayout {...{ linkPreviewImgUrl, systemNewsActionHtml, badgeId: '' }} />

            <Preview className={cnClassName('Preview', ['horizontalPadding'])} newsid={id} files={attachments ? attachments : []} />

            <Attachments className={cnClassName('Attachments', ['horizontalPadding'])} files={attachments ? attachments : []} onDelete={() => { }} state='view' />

            {/* <NewsActions /> */}
        </div>
    </div>
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));
