import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'

type IPresenterProps = StatisticsFull.Ratings.IPresenterProps
type IOwnProps = StatisticsFull.Ratings.IOwnProps
type IDispatchProps = StatisticsFull.Ratings.IDispatchProps
type IStateProps = StatisticsFull.Ratings.IStateProps
type IState = StatisticsFull.Ratings.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
}) => {

    return <div className={cnClassName({}, [className])}>
        статисика!
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));
