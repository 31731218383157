import {
    takeEvery,
    put,
    takeLeading,
    takeLatest,
    throttle,
    fork
} from 'redux-saga/effects';
import {
    call,
    select,
} from 'utils/src/saga.effects';

import actions from './actionsType'

import {
    pathNews,
    upgradeNews
} from 'News/redux/actions'

import {
    getNewsById
} from 'News/redux/saga/selectors'

import { selectUserStatuses, selectStatus } from './selectors';

import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'

import * as AT from './actions.d'

import {
    API,
    checkResponseStatus,
    getCookie,
    getErrorText
} from 'utils/src/utils'

import { workflows } from 'utils/src/requests/requests.news';

import { removeUserFromArray, addUserToArray, toggleUserInArray, userInArray } from 'blocks/NewsTypes/NewsUtils'
import { toast } from 'react-toastify';
import { request } from 'http';


function* getUserStatusesSaga({ payload }: AT.AGetUserStatuses) {
    try {
        const { workflowId, newsId, cb } = payload;
        const response: any = yield* call(workflows.getUserStatuses, newsId);
        if (checkResponseStatus(response)) {
            yield put(pathNews({ id: newsId, userStatuses: response.data }));
            if (cb) yield* call(cb);
        } else {
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.error(e);
    }
    yield;
    return 0;
}

function* getStatusFields({ payload }: AT.AGetStatusFields) {
    try {
        const { newsId, workflowId, statusId } = payload;
        // const news: any = yield* select(getNewsById(newsId));
        yield put(pathNews({ id: newsId, statusFieldsLoading: true }));
        const response: any = yield* call(workflows.getStatusFields, newsId, statusId);

        if (checkResponseStatus(response)) {
            yield put(pathNews({ id: newsId, statusFields: response.data.additionalFieldsValues, statusFieldsLoading: false }));
        } else {
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.error('get status fields error', e);
    }
}

function* changeStatusSaga({ payload }: AT.AChangeStatus) {
    try {
        const { newsId, statusId, data } = payload;

        const news: any = yield* select(getNewsById(newsId));
        const oldAdditionalFields = news.additionalFields;

        const status: any = yield* select(selectStatus(newsId, statusId));
        yield put(pathNews({ id: newsId, additionalFields: { ...news.additionalFields, currentStatus: status } }))

        const requestData: { [s: string]: any } = {};
        if (data) requestData.additionalFieldsValues = data;

        const response: any = yield* call(workflows.changeStatus, newsId, statusId, requestData);
        if (checkResponseStatus(response)) {
            const commentsKeys: string[] = [];
            const commentsValues: { [s: string]: any } = {};
            const values = { [response.data.id]: { ...news, additionalFields: response.data.additionalFields, comments: response.data.comments.map((el: any) => el.id) } };

            response.data.comments?.forEach((el: any) => {
                commentsKeys.push(el.id);
                commentsValues[el.id] = el;
            });

            yield put(upgradeNews({ values, keys: [response.data.id], commentsKeys, commentsValues }))
        } else {
            yield put(pathNews({ id: newsId, additionalFields: oldAdditionalFields }));
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.error(e);
    }
    yield;
    return 0;
}

function* changeResponsibleSaga({ payload }: AT.AChangeResponsible) {
    try {
        const { newsId, users } = payload;
        const news: any = yield* select(getNewsById(newsId));

        yield put(pathNews({ id: newsId, additionalFields: { ...news.additionalFields, responsibleForStatus: users } }));
        
        const response: any = yield* call(workflows.changeResponsible, newsId, users);
        if (!checkResponseStatus(response)) {
            toast.error(getErrorText(response));
            yield put(pathNews({ id: newsId, ...news }));
        }
    } catch (e) {

    }
}

/**
 * module root saga
 */
const root = function* root() {
    yield takeLatest(actions.GET_USER_STATUSES, getUserStatusesSaga)
    yield takeLatest(actions.GET_STATUS_FIELDS, getStatusFields)
    yield takeLeading(actions.CHANGE_STATUS, changeStatusSaga)
    yield takeLeading(actions.CHANGE_RESPONSIBLE, changeResponsibleSaga)
};

/**
 * export root saga
 */
export default root;