import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';


export interface IGroups {
  name?: string
  imgUrl?: string
  key?: string
  networkId?: string
  parentNetworkId?: string
  currentNetworkId?: string
  userId?: any
}

export const mapStateToProps = (state: any) => ({
  userId: getAuthUser(state).baseData.id

})

export type INotificationsSettingsGroupSettingsProps = ReturnType<typeof mapStateToProps>

export const cnGroups = cn('Groups');