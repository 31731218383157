import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IStateType as IState } from 'redux/store';
import { WithTranslation } from 'react-i18next';
import { getNotifiSettingsSelector } from 'redux/notifiSettings/selectors';
import { getNotifiSettings, setNotifiSettings, saveNotifiSettings, clearNotifiSettings } from 'redux/notifiSettings/actions';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';


export interface INotifiSettingTablesProps extends IMapStateToProps, IMapDispatchToProps, WithTranslation { }

export const mapStateToProps = (state: IState) => {
  return {
    settings: getNotifiSettingsSelector(state),
    authUser: getAuthUser(state)
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getNotifiSettings,
  setNotifiSettings,
  saveNotifiSettings,
  clearNotifiSettings
}, dispatch);

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type IMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export interface IParams {
  tab: string;
  uid: string;
  gid?: string;
  nid?: string;
}

export const cnNotifiSettingTable = cn('NotifiSettingTable');
