import React, { FC, useState } from 'react'
import { ISearchSelectizeProps } from 'uielements/src/SearchSelectize/SearchSelectize.index';
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';

type ExcludeKeys = 'active' | 'changeQuery' | 'loading' | 'query' | 'onClick' | 'resetElement' | 'elements'
interface IWithUserGroupProps extends Omit<ISearchSelectizeProps, ExcludeKeys> {
    onClick: (items: any[]) => void
    type?: 'users' | 'groups' | 'usersgroups'
    excludeMe?: boolean;
}

const withUserGroupsSuggester = (Component: React.ComponentType<ISearchSelectizeProps>): FC<IWithUserGroupProps> =>
    ({ onClick, type = 'users', excludeMe = false, ...props }) => {
        const resetElement = {
            value: 'All',
            title: i18n.t(`pryaniky.LMS.utils.users.all.1`),
            id: 'All'
        };

        const [query, setQuery] = useState<string>('')
        const [elements, setElements] = useState<ISelectizeItem[]>([])
        const [active, setActive] = useState<ISelectizeItem[]>([resetElement])
        const [loading, setLoading] = useState<boolean>(false)

        const changeQuery = (value: string) => {
            setQuery(value)
            setLoading(true)
            const request = utils.API.suggesters.search(type, value, -1, excludeMe);
            request.r.then((d: any) => {
                if (utils.checkResponseStatus(d)) {
                    setElements(d.data.map((item: any) => ({
                        value: item.id,
                        title: item.displayName,
                        id: item.id,
                        avatar: item.userPhotoUrl
                    })));
                }
            }).finally(() => {
                setLoading(false)
            });
        }

        const onClickItem = (items: ISelectizeItem[]) => {
            const withoutReset = items.filter(val => val.id !== resetElement.id)
            setActive(items)
            onClick(withoutReset)
        }
        return <Component
            {...props}
            loading={loading}
            query={query}
            active={active}
            changeQuery={changeQuery}
            onClick={onClickItem}
            resetElement={resetElement}
            elements={elements}
        />
    }

export default withUserGroupsSuggester