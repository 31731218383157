import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    deleteNews,
    hideNews,
    pathNews
} from '../../redux/actions'
import {
} from '../../redux/reducer/types'
import {
    getNewsParamsById,
    getNewsById
} from '../../redux/saga/selectors'
import { NewsContextProps } from '../../contexts/news'
import { ReactNode } from 'react'
// import {  } from 'utils/src/requests/models/api.learningTracks'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsHeader {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps, NewsContextProps {
            TextRender?: ReactNode
            actionsRender?: ReactNode
            showContextAlways?: boolean
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.NewsHeader.IOwnProps

const newsParams = [
    'actions',
    'user',
    'isNeedApprove',
    'isShowOnTop',
    'group',
    'changeDate',
    'date',
    'id',
    'newstype',
    'header',
    'thanks',
    'badge',
    'publishAt',
    'componentRenderName',
    'isDraft',
    "achievement"
] as const

export const mapStateToProps = (state: any, { newsId }: IOwnProps) => ({
    ...getNewsParamsById(newsId, newsParams)(state),
    // data: getNewsById(newsId)(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    deleteNews,
    hideNews,
    pathNews
}, dispatch);

////////////

export const cnClassName = cn('NewsHeader');