import React, { FC, useState, PureComponent } from 'react'
import { BadgeWidget } from './BadgeWidget'
import * as utils from 'utils/src/utils';
import {toast} from 'react-toastify'
/*const badgesState = {
    catsList: ['cat1', 'cat2'],
    cats: {
        'cat1': ['b1', 'b2', 'b3'],
        'cat2': ['b4', 'b5', 'b6'],
    },
    badges: { b1: {}, b2: {}, b3: {}, b4: {}, b5: {}, b6: {} },
    badgesList: ['b1', 'b2', 'b3', 'b4', 'b5', 'b6'],
    sortedBadgesList: ['b1', 'b2', 'b3', 'b4', 'b5']
}*/
const badgesState: any = {
    catsList: [],
    cats: {},
    badges: {},
    badgesList: [],
    sortedBadgesList: [],
    isLoading: false,
    isComplite: false
}

export class BWContainer extends PureComponent<any> {
    public state = badgesState;
    public render() {
        return <BadgeWidget
            {...this.props}
            isLoading={this.state.isLoading}
            isComplite={this.state.isComplite}
            sortedBadgesList={this.state.sortedBadgesList}
            cats={this.state.cats}
            catsList={this.state.catsList}
            badges={this.state.badges}
            uid={this.props.uid}
            removeBadge={this.props.isAdmin && this.removeBadge}
            loadBadges={this.loadBadges} />
    }

    private removeBadge = (uid: string, bid: string) => {
        const currentBadge = this.state.badges[bid]
        const badgeDicrimentCount = { ...currentBadge, count: currentBadge.count - 1 }
        if (badgeDicrimentCount.count <= 0) {
            const withOutBid = this.state.sortedBadgesList.filter((id: string) => id !== bid)
            const badgesListwithOutBid = this.state.badgesList.filter((id: string) => id !== bid)
            const catsWithOutBid = { ...this.state.cats}
            this.state.catsList.forEach((cat: string) => {
                catsWithOutBid[cat] = this.state.cats[cat].filter((id: string) => id !== bid)
            })

            this.setState({ sortedBadgesList: withOutBid, badgesList: badgesListwithOutBid, cats: catsWithOutBid })
        }

        this.setState({ badges: { ...this.state.badges, [bid]: badgeDicrimentCount } })

        utils.API.badges.removeBadgeFromUser({ uid, bid }).r.then((d: any) => {
            if (d && d.error_code !== 0) {
                console.warn('badge delete fail:', d)
                toast.error('Ошибка при удаление бейджа, возможно изменения не были сохранены')
            }
        })
    }

    private loadBadges = (uid: string) => {
        this.setState({ isLoading: true })
        utils.API.badges.byUser({ uid }).r.then((d: any) => {
            if (!d.data || d.error_code !== 0) {
                this.setState({
                    isLoading: false,
                    isComplite: true
                })
                return;
            }
            const catsList = Object.keys(d.data)
            const cats: any = {};
            const badgesList: any[] = [];
            const badges: any = {};

            catsList.forEach((val: any) => cats[val] = d.data[val].map((v: any) => {
                badges[v.badgeUid] = v;
                badgesList.push(v.badgeUid)
                return v.badgeUid;
            }));
            const sortedBadgesList = badgesList.sort((a: string, b: string) => {
                if ((new Date(badges[a].recieved)).getTime() < (new Date(badges[b].recieved)).getTime()) {
                    return 1;
                }
                if ((new Date(badges[a].recieved)).getTime() > (new Date(badges[b].recieved)).getTime()) {
                    return -1;
                }
                return 0;
            })

            this.setState({
                catsList,
                cats,
                badges,
                badgesList,
                sortedBadgesList: sortedBadgesList.slice(0, 6),
                isLoading: false,
                isComplite: true
            })
        })
    }
}

// export const BWContainer: FC<any> = ({ uid }) => {
//     const [badges, setBadges] = useState<any>(badgesState)
//     return <BadgeWidget isLoading={badges.isLoading} isComplite={badges.isComplite} sortedBadgesList={badges.sortedBadgesList} cats={badges.cats} catsList={badges.catsList} badges={badges.badges} uid={uid} loadBadges={loadBadges(setBadges)} />
// }