import { RefObject, useEffect, useState } from 'react';


export const useItemsCounter = (
    count: number,
    blockRef: RefObject<HTMLDivElement> | null, 
    size: number,
) => {

    const [itemsPerRow, setItemsPerRow] = useState(count)
    const [blockWidth, setBlockWidth] = useState(blockRef?.current?.clientWidth)

    const handleResize = () => {
        if (!blockRef?.current) return
        setBlockWidth(blockRef.current?.clientWidth)
        let maxChildrenInRow = 1
        maxChildrenInRow = Math.floor((blockRef.current?.clientWidth || 1) / (size + 8))
        setItemsPerRow(maxChildrenInRow)
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [count, blockRef?.current, blockWidth]);

    return itemsPerRow
}