import * as React from "react";
import * as utils from "utils/src/utils";
import * as utilsProj from 'utils.project/utils.project';
import { connect } from "react-redux";
import { AbstractList } from "blocks/List/List";
import { mapDispatchToProps } from "../../../../redux/connector";
import { IListQuestsDefaultProps, IListTypeQuestsProps, cnQuestModerate, avalibleFiltersQuestModerate } from "./List_type_quests_moderate.index";
import { mapStateToProps as mapStateToPropsList, IListState, mapActionsToProps } from "blocks/List/List.index";
import { Header } from "blocks/List/Header/List-Header";
import { ListLite, Avatar, Button, Loading } from "uielements/src";
import i18n from "../../../../localizations/i18n";
import { Item } from "blocks/List/Item/List-Item";
import "../quests/List_type_quests_moderate.scss";
import { toast } from "react-toastify";
import { IBasicResponse } from "utils/src/requests/models/api.base";
import { store } from "redux/store";
import actions from 'redux/actionsTypes/Lists';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import MDNodeBulder from 'uielements/src/CommonmarkRender/MDNodeBulder';
import StubListPlaceholder from '../../../../assets/svg/listPlaceholder.svg';
import { TypedListPlaceholder, Tabs } from 'uielements/src';
import { GUID_UNITS } from 'utils/src/constants.prn';
import MDRender from 'uielements/src/CommonmarkRender';
import { Attachments } from 'uielements/src/Attachments/Attachments';
import { formatDateSTD } from "utils/src/utils.dates";

const noopFn = () => { }
const noopArray: any[] = []

export class ListQuestsPresenter extends AbstractList<IListTypeQuestsProps, IListState> {

  public static defaultProps: IListQuestsDefaultProps = {
    requestOptions: {
      search: "",
      extended: true,
      tab: "quests",
      questId: GUID_UNITS,
      catFilter: 'waiting',
      uid: "",
      count: 20
    },
  };

  constructor(props: IListTypeQuestsProps) {
    super(props);
    this.prepareData = this.prepareData.bind(this);
  }

  public componentDidMount() {
    this.mounted = true;
    this.setState({ tab: 'quests' });
    // const initialFilter = this.props.widget.groupTypes || 'all'
    // this.props.updateContext('common', {
    //   filter: {
    //     id: initialFilter,
    //     type: 'type',
    //     value: initialFilter,
    //   },
    // });
    store.dispatch({
      type: actions.ADD_HEADER,
      payload: {
        id: this.id,
        data: [
          {
            id: "header",
            checked: false,
            view_data: {
              avatar: i18n.t("pryaniky.list.quests.moderate.user"),
              info: [
                [i18n.t("pryaniky.list.quests.moderate.question_title")],
                [i18n.t("pryaniky.list.quests.moderate.create_date")],
                [i18n.t("pryaniky.list.quests.moderate.comment")],
                [i18n.t("pryaniky.list.quests.moderate.action")]]
            }
          }
        ]
      }
    });
    this.getData();
  }

  public search = (text: string) => {
    const { toggle } = this.props;
    this.searchString = text;
    toggle({
      id: this.listId,
      data: {
        hideData: text.length !== 0,
        clearData: true,
        isFinished: false,

      }
    });
    this.getData();
  };

  public toggleTab = (tab: string) => {
    const { toggle } = this.props;
    this.setState({ tab });

    toggle({
      id: this.listId,
      data: {
        hideData: true,
        clearData: true,
        isFinished: false,
      }
    });
    this.props.clearDataFromList({ id: this.listId, data: null });
    const isAch = tab === 'achievements';
    this.getData(isAch);
  };

  public getData = (isAch?: boolean) => {
    const { requestOptions, context, edit } = this.props;
    const { clearData, isLoading } = this.props;
    const { clearDataFromList, getQuests, toggle } = this.props;
    const id = this.listId;

    const isAchievements = this.state.tab === 'achievements';
    if (edit || isLoading) return;
    const opts = Object.assign({}, requestOptions);

    opts.isAchievements = isAch !== undefined ? isAch : isAchievements;
    Object.keys(context).forEach((cKey: string) => {
      if (avalibleFiltersQuestModerate[cKey]) opts[avalibleFiltersQuestModerate[cKey]] = context[cKey];
    });

    // if (context.filter) {
    //   if (context.filter.type === "catFilter") opts.catFilter = context.filter.value;
    // }

    if (this.searchString && this.searchString !== "") opts.search = this.searchString;
    toggle({
      id: this.listId,
      data: {
        clearData: true,
        isLoading: true,
        isFinished: false
      }
    });
    if (clearData) clearDataFromList({ id, data: null });
    getQuests.call(this, id, opts);
  };

  public prepareData(el: any) {
    if (this.props.context.catFilter && this.props.context.catFilter === 'deleted') {
      el.checked = null;
    } else {
      el.checked = false;
    }

    el.view_data = {
      avatar: [
        <Button theme="unstyled" type="rlink" href={"/user/" + el.userId}>
          <Avatar
            imgUrl={
              "/Img/UserProfiles/Preview/96m96/" +
              el.userId +
              ".png?v=" + el.imgId
            }
            size={56}
            name={el.displayUserName}
          />
        </Button>,
        <p>{el.displayUserName}</p>
      ],
      info: [
        [
          <p>
            <strong>{i18n.t(`pryaniky.list.quests.moderate.${el.isAchievement ? 'achievement' : 'quest'}`)} </strong> {el.questName}{" "}
          </p>,
          <p>
            <strong>{i18n.t("pryaniky.list.quests.moderate.step")}</strong> {el.goalName}
          </p>,
          <p className="Bonus">
            <strong>{i18n.t("pryaniky.list.quests.moderate.bonus")} </strong>
            {el.bonus}{" "}
          </p>,
          <p title={el.approved ? i18n.t("pryaniky.list.quests.moderate.title.status_approved") : (el.processing ? i18n.t("pryaniky.list.quests.moderate.title.status_processing") : i18n.t("pryaniky.list.quests.moderate.title.status_moderate"))} className="Status">
            <strong>{i18n.t("pryaniky.list.quests.moderate.status")} </strong>
            {el.isDeleted ? i18n.t("pryaniky.list.quests.moderate.status_deleted") : el.approved ? i18n.t("pryaniky.list.quests.moderate.status_approved") : (el.processing ? i18n.t("pryaniky.list.quests.moderate.status_processing") : i18n.t("pryaniky.list.quests.moderate.status_moderate"))}
          </p>
        ],
        [formatDateSTD(el.timeStamp, true)],
        // [new MDNodeBulder(el.comment).getPlainText().join(' ')],
        [<div>
          <MDRender source={el.comment} />
          {el?.attachments && <span className={cnQuestModerate('Comment-Attachments')}>

            <Attachments
              className={cnQuestModerate('Attachments', ['horizontalPadding'])}
              disabledType={[]}
              files={el.attachments ? el.attachments : noopArray}
              onDelete={noopFn}
              state='view' />

          </span>}
        </div>],
        this.props.context.catFilter && this.props.context.catFilter === 'deleted' ?
          [] :
          [el.approved ?
            el.moderatorId === GUID_EMPTY ?
              <>
                <p className="Bonus">{i18n.t("pryaniky.list.quests.moderate.comleted", { date: formatDateSTD(el.approvedDate, true) })}</p>
                {el.goalType === 'ManualStep' &&
                  <Button
                    main
                    className={cnQuestModerate("actionButton")}
                    onClick={this.questSingleAction("disapprove", el.id)}
                  >
                    {i18n.t("pryaniky.list.quests.moderate.dissapprove")}
                  </Button>
                }
              </> :
              <>
                <p>{i18n.t("pryaniky.list.quests.moderate.comleted", { date: formatDateSTD(el.approvedDate, true) })}{el.moderatorDisplayName}</p>
                {el.goalType === 'ManualStep' &&
                  <Button
                    main
                    className={cnQuestModerate("actionButton")}
                    onClick={this.questSingleAction("disapprove", el.id)}
                  >
                    {i18n.t("pryaniky.list.quests.moderate.dissapprove")}
                  </Button>
                }
              </> : el.processing ? <Loading text={i18n.t("pryaniky.list.quests.moderate.status_processing")}></Loading> :
              [!el.approved ? (
                <Button
                  main
                  className={cnQuestModerate("actionButton")}
                  onClick={this.questSingleAction("approve", el.id)}
                >
                  {i18n.t("pryaniky.list.quests.moderate.confirm")}
                </Button>
              ) : <>
                {el.goalType === 'ManualStep' &&
                  <Button
                    main
                    className={cnQuestModerate("actionButton")}
                    onClick={this.questSingleAction("disapprove", el.id)}
                  >
                    {i18n.t("pryaniky.list.quests.moderate.dissapprove")}
                  </Button>
                }
              </>,
              <Button
                main
                className={cnQuestModerate("actionButton")}
                onClick={this.questSingleAction("delete", el.id)}>
                {i18n.t("pryaniky.list.quests.moderate.delete")}
              </Button>]

          ]
      ]
    }
      ;
  }

  public batchAction = (action: "delete" | "approve" | "disapprove", ids: string[]) => () => {
    utilsProj.confirm({
      text: i18n.t("pryaniky.list.quests.confirm"),
      onConfirm: () => {
        ids.forEach(id => {
          this.questActions(action, id)();
        });
      }
    });
  };

  public componentDidUpdate(pp: IListTypeQuestsProps, ps: any) {
    const { context: c, toggle, isLoading } = this.props;
    const { context: pc } = pp;

    if (
      Object.keys(avalibleFiltersQuestModerate).filter((fk) => c[fk] !== pc[fk] ? true : false).length
    ) {
      toggle({
        id: this.listId,
        data: {
          hideData: true,
          clearData: true,
          isFinished: false,
        }
      });
      // this.getData()
      setTimeout(() => this.getData(), 10);
    }
  }

  public renderChildren = () => {

    const { data, isFinished, isLoading, hideData, selected, context, isAdmin, requestOptions } = this.props;
    //  const selectedIds: string[] = selected.map(item => item);
    let counter;
    const tabds = [
      { title: i18n.t('pryaniky.list.quests.moderate.quests'), value: 'quests' },
      { title: i18n.t('pryaniky.list.quests.moderate.achievements'), value: 'achievements' },
    ]
    if (context.catFilter && context.catFilter !== 'deleted') {
      counter = {
        all: {
          title: i18n.t("pryaniky.list.quests.moderate.number"),
          count: data.length,
          action: () => this.props.checkAllListItem({ id: this.listId, data: null })
        },

        selected: {
          title: i18n.t("pryaniky.list.selected", { count: selected.length }),
          count: selected.length,
          action: {
            options: [
              {
                title: i18n.t("pryaniky.list.quests.moderate.confirm"),
                buttonProps: {
                  onClick: this.batchAction("approve", selected)
                }
              },
              {
                title: i18n.t("pryaniky.list.quests.moderate.delete", { count: selected.length }),
                buttonProps: {
                  onClick: this.batchAction("delete", selected)
                }
              },
              {
                title: i18n.t("pryaniky.list.quests.moderate.dissapprove", { count: selected.length }),
                buttonProps: {
                  onClick: this.batchAction("disapprove", selected)
                }
              }
            ]
          }
        }
      }
    }
    return (
      <>
        <Header

          className={"rounded-top"}
          type={"common"}
          search={{
            placeholder: i18n.t("pryaniky.list.quests.search"),
            onChange: this.search
          }}
          counter={counter}

        >
          <div className={cnQuestModerate('Tabs')}>
            <Tabs
              tabsList={tabds}
              selected={this.state.tab}
              onChange={this.toggleTab}

            />
          </div>
        </Header>
        <ListLite
          className={"rounded-bottom"}
          isFinished={isFinished}
          isLoading={isLoading}
          loadMore={this.getData}
        >
          {/* <div className={cnQuestModerate("Table-Top")}> <p>Пользователь</p> <p>Название Квеста</p> <p>Шаг</p> <p>Дата создания</p> <p>Комментарий</p> <p>Действие</p></div> */}
          {/* <Item
            lId={this.listId}
            iId={"header"}
            type={"common"}
            key={"header"}
            data_key={"header"}
            className={cnQuestModerate("Header")}
          /> */}
          {data.length !== 0 || isLoading ?
            data.map(el => (<Item lId={this.listId} iId={el} type={"common"} key={el} data_key={el} />)) :
            isAdmin ?
              <TypedListPlaceholder type='search' /> :
              <TypedListPlaceholder text={i18n.t('pryaniky.list.quests.list.empty')} />
          }
        </ListLite>

      </>
    );
  };

  private questSingleAction = (action: "approve" | "delete" | "disapprove", id: string) => (event?: any) => {
    if (event) event.stopPropagation();
    utilsProj.confirm({
      text: i18n.t("pryaniky.list.quests.confirm." + action),
      onConfirm: () => {
        this.questActions(action, id)();
      }
    });
  };

  private updateStatus = (id: string, delay = 5000) => {
    const statusResponse = utils.API.questsmoderate.checkStatus(id).r.then((response: any) => {
      let el = this.props.getListItem(this.id, id);
      el.processing = response.data[0].processing;
      this.prepareData(el);
      this.props.changeListItem({
        id: this.id,
        data: {
          item: el.id,
          data: el
        }
      });
      if (el.processing) {
        setTimeout(() => this.updateStatus(id, delay < 30000 ? delay + 5000 : delay), delay)
      } else {
        this.props.removeDataFromList({
          id: this.id,
          data: [id]
        });
      }
    });

  }

  private questActions = (action: "approve" | "delete" | "disapprove", id: string) => (event?: any) => {
    let response;
    let el = this.props.getListItem(this.id, id);
    if (action === "approve") {
      response = utils.API.questsmoderate.approve(id);
      el.processing = true;
      el.approvedDate = new Date();
    }
    if (action === "disapprove") {
      response = utils.API.questsmoderate.disapprove(id);
      el.approved = false;
    }
    if (action === "delete") {
      response = utils.API.questsmoderate.deleteQuest(id);
    }
    if (response) response.r.then((response: IBasicResponse) => {
      if (!response) toast.error(i18n.t("pryaniky.toast.error.server"));
      else if (response.error_code) toast.error(response.error_text);
      else {
        toast.success(i18n.t(`pryaniky.list.users.actions.success`));
        if (action === "delete") {
          this.props.removeDataFromList({
            id: this.id,
            data: [id]
          });
        } else {
          this.updateStatus(id)
          this.prepareData(el);
          this.props.changeListItem({
            id: this.id,
            data: {
              item: el.id,
              data: el
            }
          });
        }
      }
    });
  };
}
export const List = connect<any, any, any, any>(
  mapStateToPropsList,
  mapDispatchToProps(mapActionsToProps)
)(ListQuestsPresenter);
