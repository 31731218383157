import React, { FC, useState, useMemo } from 'react';
import i18n from 'localizations/i18n';
import * as utils from 'utils/src/utils'


import { dialog } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { defaultMiddleware, useDefaultStaticStates } from 'muicomponents/src/ItemsListDialog/defaultListState'
import { useScrollListState } from 'utils/src/hooks'
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem'
import { GroupListItem } from 'muicomponents/src/ItemsListDialog/GroupListItem/GroupListItem'
import { IBaseDataType } from 'muicomponents/src/ItemsListDialog/ItemsListDialog.index'
import { TDialogProps } from 'utils/src/DialogCreator'
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { TextFieldSelect } from 'muicomponents/src/TextFieldSelect/TextFieldSelect';
import { useLazyQueryEx, useFileUploader, useStateWithDebounce } from 'utils/src/hooks'
import { UploadButton } from 'muicomponents/src/UploadButton/UploadButton'
import { useSelector } from 'react-redux';
import { getCurrentUserNetworks, getCurrentUserBaseNetworks } from 'utils/src/CommonRedux/base/selectors'


async function invaitePromise(groupId: string | number, list: string[]) {
    const response = await utils.API.groups.inviteUsers(groupId, list, false)
    const result = await response.r
    if (result.error_code !== 0) throw result
    return result
}



const TABS_LIST = [
    {
        label: i18n.t('users'),
        id: 'users'
    },
    {
        label: i18n.t('groups'),
        id: 'groups'
    },
]


const createDialogUserInvite = () => {
    return ({
        isAdmin,
        gid
    }: {
        isAdmin: boolean;
        gid: string | number;
    }, containerProps?: TDialogProps) =>
        dialog({
            listStateHook: ({ defaultSelected }, middlewareParams) => {
                const {
                    search,
                    debouncedSearch,
                    changeSearch,
                    tabsValue,
                    onChangeTab
                } = useDefaultStaticStates(middlewareParams.tabsValue || middlewareParams.tabs?.[0]?.id)

                const networks = useSelector(getCurrentUserNetworks)
                // const baseNetworks = useSelector(getCurrentUserBaseNetworks)
                const [selectedNetwork, setSelectedNetwork] = useState('all')//baseNetworks.id)

                const state = useScrollListState(async function (skipCount, count) {
                    const network = selectedNetwork !== 'all' ? selectedNetwork : undefined
                    const response = await tabsValue === 'users' ?
                        utils.API.users.list({ count, skipCount, search: debouncedSearch, excludeGroup: gid, network }) :
                        utils.API.groups.list(skipCount, count, { search: debouncedSearch, network })

                    const result = await response.r as { data: IBaseDataType[] } & IBasicResponse
                    if (result.error_code !== 0) throw result
                    return result.data.map<IBaseDataType & { url?: string }>((item) => ({ ...item, url: tabsValue === 'users' ? `/user/${item.id}` : `/group/${item.id}` }))
                }, [debouncedSearch, tabsValue, selectedNetwork], {
                    defaultSelected,
                    selectableSingle: middlewareParams.selectableSingle
                })

                const { send, isLoading } = useLazyQueryEx(invaitePromise)
                const { isLoading: isFileLoading, onFileChange } = useFileUploader({
                    uploadOnAdd: true,
                    uploadUrl: `/SetData/v3/group/invite/${gid}/file`
                })

                const networksOptions = [{ title: i18n.t('pryaniky.selectors.networks.option.all'), value: 'all' }, ...networks.map((val: any) => ({ title: val.title, value: val.id }))]


                const { listBodyProps, listHeaderProps = {}, handleAccept } = middlewareParams
                return {
                    middlewareParams: {
                        ...middlewareParams,
                        primaryActionClick: (select, actions) => {
                            send(gid, select.selected.map(i => i.id)).then(() => {
                                actions.removeItems(select.selected)
                            }).catch((e) => console.error('error invaite users', e))
                        },
                        listHeaderProps: {
                            ...listHeaderProps,
                            disableActionsButton: isLoading,
                            actionButtonProps: {
                                ...listHeaderProps.actionButtonProps,
                                children: i18n.t('pryaniky.list.users.actions.invite'),
                            },
                        },
                        listBodyProps: {
                            ...listBodyProps,
                            handleAccept: undefined,//() => handleAccept({}),
                            leftActions: isAdmin ? <>
                                <UploadButton variant={'outlined'} loading={isFileLoading} onChangeInput={onFileChange}>{i18n.t('import')}</UploadButton>
                                <TextFieldSelect
                                    sx={{ marginLeft: '12px', minWidth: '120px' }}
                                    label={i18n.t('pryaniky.selectors.placeholder.networks')}
                                    size='small'
                                    options={networksOptions}
                                    onChange={(e) => setSelectedNetwork(e.target.value)}
                                    value={selectedNetwork}
                                />
                            </> : undefined
                        }
                    },

                    state,

                    defaultSelected,

                    search,
                    tabsValue,
                    changeSearch,
                    onChangeTab,

                    middleware: defaultMiddleware
                }
            },
            tabs: TABS_LIST,
            tabsValue: 'users',

            itemMiddleware: ({ actionButtonProps, actionSplitButtonProps, item, ...props }) => {
                const { send, isLoading } = useLazyQueryEx(invaitePromise)
                return {
                    ...props,
                    inProgress: isLoading,
                    ItemComponent: item.type === 'group' ? GroupListItem : UsersListItem,
                    actionButtonProps: {
                        ...actionButtonProps,
                        children: i18n.t('pryaniky.list.users.actions.invite'),
                    },
                    actionButtonClick() {
                        send(gid, [item.id]).then(() => {
                            props.actions.removeItems([item])
                        })

                    },
                    actionSplitButtonProps: {
                        ...actionSplitButtonProps,
                        variant: 'outlined'
                    },
                    item
                }
            },
            selectable: true,

            ItemComponent: UsersListItem,

            listBodyProps: {
                header: i18n.t('pryaniky.modal.inviteUser.title')
            }
        }, containerProps)
}

export default createDialogUserInvite()