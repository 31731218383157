import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace KPI {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Table {

        export type IPresenterProps = IOwnProps

        export interface IOwnProps extends IClassNameProps {
            children: React.ReactNode
        }
    }
}
export const cnClassName = cn('KPITable');