import * as utils from 'utils/src/utils';
import { toast } from 'react-toastify';
import actions from '../actionsTypes/COMMON';
import { action } from 'utils/src/requests/requests.news';
import uuid from 'uuid';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { routerActions } from 'connected-react-router';
import {
    coursesSchema,
    denormalizeData,
    normalizeData,
    singleCourseSchema,
    testResultShema,
    testSchema,
    unitsLogsSchema
} from 'LMSModule/utils/normalize';

const zeroId = "00000000-0000-0000-0000-000000000000";

//////////////////////////////////////////////////////////////////////////////////////////////////////

/********************************************************/
/******************** to saga ***************************/
/********************************************************/

/******************************* SET_FULL_PLAYED_COURSE *******************************/
export type setFullPlayedCoursePayloadType = { courseId: string, serverModel: any }
export type setFullPlayedCourseActionType = { type: string, payload: setFullPlayedCoursePayloadType }
/**
 * закинуть в редакс модель курса для прохождения
 */
export const setFullPlayedCourse = (courseId: string, serverModel: any): setFullPlayedCourseActionType => ({
    type: actions.SET_FULL_PLAYED_COURSE,
    payload: { serverModel, courseId }
})



/******************************** JOIN_TO_SESSION *************************/
export type loadUnitsLogsPayloadType = { sid: string }
export type loadUnitsLogsActionType = { type: string, payload: loadUnitsLogsPayloadType }
/**
 * активирует сессию для пользователя
 */
export const loadUnitsLogs = (sid: string): loadUnitsLogsActionType => ({
    type: actions.LOAD_UNITS_LOGS,
    payload: { sid }
})


/******************************** LOAD_ALL_SCORM_PARAMS *************************/
export type loadAllScormParamsPayloadType = { cid: string, unitId: string, sid?: string, logId?: string }
export type loadAllScormParamsActionType = { type: string, payload: loadAllScormParamsPayloadType }
/**
 * 
 */
export const loadAllScormParams = (cid: string, unitId: string, sid?: string, logId?: string): loadAllScormParamsActionType => ({
    type: actions.LOAD_ALL_SCORM_PARAMS,
    payload: { cid, unitId, sid, logId }
})