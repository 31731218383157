import { clone } from "lodash"
import { converAdditionalFieldValuesToAdditionalFieldValuesV1 } from "News/creator/types/Ideas/Ideas.sides"
import { useState } from "react"
import { changeUser } from "utils/src/CommonRedux/users/actions"
import {validate} from './ProfileEdit.validate'


interface IuseUpdateStoreArgs {
    customFieldsState: any
    user: any
    initBaseState: any
    dispatch: any
    userId: string
    avatarState: any
    setSystemFieldsErrors: (arg: { [key: string]: string }) => void
    mondatorySystemFields: string[]
    multiDivisionsAllowed: boolean
    multiPositionsAllowed: boolean
    customFieldsErrors: { [key: string]: object | undefined }
}

export const useUpdateStore = ({
    customFieldsState,
    user,
    initBaseState,
    dispatch,
    userId,
    avatarState,
    setSystemFieldsErrors,
    mondatorySystemFields,
    multiDivisionsAllowed,
    multiPositionsAllowed,
    customFieldsErrors,
}: IuseUpdateStoreArgs) => {
  

    const [wasStoreUpdated, setWasStoreUpdated] = useState(false)

    const updateStore = () => {

        const { errors, hasAdditionalFieldsErrors } = validate({
            mondatorySystemFields,
            multiDivisionsAllowed,
            multiPositionsAllowed,
            baseFieldsState: initBaseState,
            customFieldsErrors
        })
    
        if (hasAdditionalFieldsErrors || Object.keys(errors).length) {
            setSystemFieldsErrors(errors)
            return
        }

        const newArray = customFieldsState.reduce((acc: any, group: any) => {
            const normalised = group.fields.map((f: any) => {
                return converAdditionalFieldValuesToAdditionalFieldValuesV1(f)
            })
            acc = acc.concat(normalised)
            return acc
        }, [])

        let newEntities: any = {}
        newArray.forEach((elem: any) => {
            if ('fieldId' in elem) newEntities[elem.fieldId] = elem
        })

        const newEntitiesFieldsForHeadFields = clone(user.profileData.userHeadFields.entities.fields)
        user.profileData.userHeadFields.result.forEach((fieldId: string) => {
            newEntitiesFieldsForHeadFields[fieldId] = newEntities[fieldId]
        })

        const phone = initBaseState.phone.replace(' ', '').replace(' ', '')
        const sections = phone.replace('+', '').split(/[()]/);

        dispatch(changeUser(userId, {
            ...user,
            profileData: {
                ...user.profileData,
                userHeadFields: {
                    ...user.profileData.userHeadFields,
                    entities: !newEntitiesFieldsForHeadFields ? {} : {
                        ...user.profileData.userHeadFields.entities,
                        fields: {
                            ...user.profileData.userHeadFields.entities?.fields,
                            ...newEntitiesFieldsForHeadFields
                        },
                    }
                },
                userInfoFields: {
                    ...user.profileData.userInfoFields,
                    entities: {
                        ...user.profileData.userInfoFields.entities,
                        fields: {
                            ...user.profileData.userInfoFields.entities?.fields,
                            ...newEntities
                        },
                    }
                },
                middleName: initBaseState.middleName,
                lastName: initBaseState.lastName,
                firstName: initBaseState.firstName,
                showMyNewsByDefault: initBaseState.showMyNewsByDefault,
                isAdmin: initBaseState.isAdmin,
                country: sections[0],
                region: sections[1],
                phone: sections[2],
            },
            baseData: {
                ...user.baseData,
                positionTags: initBaseState.positionTags, position: null,
                divisionTags: initBaseState.divisionTags, division: null,
                language: initBaseState.language,
                eMail: initBaseState.eMail,
                sex: initBaseState.sex,
                isAdmin: initBaseState.isAdmin,
                phone,
                userLargePhotoUrl: avatarState.userLargePhotoUrl,
                largeImgId: avatarState.largeImgId,
                userPhotoUrl: avatarState.userPhotoUrl
            },
            extData: {
                ...user.extData,
                birthday: initBaseState.birthday,
            }
        }))
        setWasStoreUpdated(true)
    }

    return { updateStore, wasStoreUpdated }
}

