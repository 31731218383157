// import {User, BaseNews, defaultBaseNews,  defaultUser, defaultGroup, Group} from '../BaseType'
import { IBaseNews, createDefaultNews } from 'News/types/baseNews'
export interface NewsNews extends IBaseNews {
    newstype: 5;
}

export const defaultNewsNews: NewsNews = {
    ...createDefaultNews(),
    newstype: 5,
}

export const createDefaultModel = (): NewsNews => ({
    ...createDefaultNews(),
    newstype: 5,

})