import React, { FC, useState } from 'react';
import { NAdventCalendarReducer } from '../redux/types';
import { createModal, TransitionComponent, IBodyProps } from 'utils/src/DialogCreator';
import { DialogTitle, DialogContent, DialogActions } from 'muicomponents/src/DialogParts';
import { styled } from 'muicomponents/src/mui/system';
import { Translate } from 'localizations/Translate';
import { cn } from '@bem-react/classname';
import { getBaseCalendar } from '../redux/constants';
import { AdventCalendar } from 'utils/src/BaseTypes/adventCalendar.types';
import { TextField } from 'muicomponents/src/TextField';
import { DatePicker } from 'muicomponents/src/DatePicker';
import { Checkbox } from 'muicomponents/src/Checkbox';
import { FormControlLabel } from 'muicomponents/src/FormControl';
import { FilePreviewUploader } from 'muicomponents/src/FilePreviewUploader';


const cnCalendarSettingsDay = cn('CalendarSettingsDay');

type CalendarSettingsDayRezult = {
    editedDay: NAdventCalendarReducer.Day;
};

type CalendarSettingsDayProps = IBodyProps<CalendarSettingsDayRezult> & {
    day: NAdventCalendarReducer.Day
};

const StyledDialogContent = styled(DialogContent)({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 16,
    gap: 16
});

export const CalendarSettingsDayPr: FC<CalendarSettingsDayProps> = ({
    day,
    handleAccept,
    handleClose
}) => {

    const [ state, setState ] = useState(day);

    return (
        <div className={cnCalendarSettingsDay()}>
            <DialogTitle
                onClose={handleClose}
                sx={{
                    width: '100%'
                }}
            >
                <Translate i18nKey={'pryaniky.adventcalendar.day.settings.title'} />
            </DialogTitle>
            <StyledDialogContent className={cnCalendarSettingsDay('Content')}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={state.isEmpty}
                            onChange={() => {
                                setState({
                                    ...state,
                                    isEmpty: !state.isEmpty
                                });
                            }}
                        />
                    }
                    label={<Translate i18nKey={'pryaniky.adventcalendar.day.settings.isEmpty'} />}
                />
                <TextField
                    label={<Translate i18nKey={'pryaniky.adventcalendar.day.settings.url'} />}
                    value={state.url}
                    onChange={(e) => {
                        setState({
                            ...state,
                            url: e.target.value
                        });
                    }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={state.hideDefaultText}
                            onChange={() => {
                                setState({
                                    ...state,
                                    hideDefaultText: !state.hideDefaultText
                                });
                            }}
                        />
                    }
                    label={<Translate i18nKey={'pryaniky.adventcalendar.day.settings.hideDefaultText'} />}
                />
                <FilePreviewUploader
                    label={<Translate i18nKey={'pryaniky.adventcalendar.day.settings.mainFile'} />}
                    file={state.mainFile}
                    onFileChange={(file) => {
                        setState({
                            ...state,
                            mainFile: file
                        });
                    }}
                />
            </StyledDialogContent>
            <DialogActions
                className={cnCalendarSettingsDay('Actions')}
                onAccept={() => {
                    handleAccept({ 
                        editedDay: state
                    });
                }}
                acceptText={<Translate i18nKey={`save`} />}
                onClose={handleClose}
                closeText={<Translate i18nKey={`cancel`} />}
            >
            </DialogActions>

            </div>
    );
};

export const CalendarSettingsDay = createModal(CalendarSettingsDayPr, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});