import React, { FC } from 'react';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { AttachementHistoryProps } from './AttachementHistory.index';
import { DialogTitle, DialogContent, DialogActions } from 'muicomponents/src/DialogParts';
import { Button } from 'muicomponents/src/Button/Button';
import { useAttachementHistory } from './AttachementHistory.hooks';
import { Loading } from 'uielements/src/Loading/Loading';
import { Translate } from 'localizations/Translate';
import { List, ListItemButton, ListItemSecondaryAction, ListItem } from 'muicomponents/src/ListItem/ListItem';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { Download } from 'muicomponents/src/Icons';
import { CustomSettings } from 'utils/src';
import { NewsAttachV3 } from 'utils/src/BaseTypes/attachements.types';
import i18n from 'localizations/i18n';
import FileItem from '../../Files/FileItem/FileItem';

const VIEW_TYPES = ['ImagePreview', 'VideoPreview', 'DocumentPreview'];

const AttachementHistoryPr: FC<AttachementHistoryProps> = ({
    fileId,
    handleClose
}) => {

    const {
        data,
        loading
    } = useAttachementHistory(fileId);

    return (
        <>
            <DialogTitle
                onClose={handleClose}
            >
                <Translate i18nKey={'pryaniky.file.history.title'} />
            </DialogTitle>
            <DialogContent>
                {
                    loading &&
                    <Loading />
                }
                {
                    data?.versionHistory.map(file => {

                        const actions: any[] = [];
                
                        (file.actions as NewsAttachV3['actions'])?.forEach(el => {
                            switch (el.id) {
                                case 'delete': {
                                    // actions.push({
                                    //     content: i18n.t('pryaniky.files.item.delete'),//<OptionContent icon={'trash'} text={i18n.t('pryaniky.files.item.delete')}/>,
                                    //     action: 'delete',
                                    //     buttonProps: { onClick: onDelete },
                                    // });
                                    break;
                                };
                                case 'download': {
                                    if (!file.isFolder) {
                                        actions.push({
                                            content: i18n.t('pryaniky.files.download'),//<OptionContent icon="eye" text={i18n.t('pryaniky.files.view')} />,
                                            action: 'view',
                                            buttonProps: { type: 'link', href: file.originalUrl, download: true },
                                        });
                                    }
                                    break;
                                };
                                case 'open': {
                                    if (!file.isFolder) {
                                        actions.push({
                                            content: i18n.t('pryaniky.files.open'),//<OptionContent icon="eye" text={i18n.t('pryaniky.files.view')} />,
                                            action: 'view',
                                            buttonProps: { type: 'link', href: file.originalUrl, download: false },
                                        });
                                    }
                                    break;
                                };
                                case 'preview': {
                                    // if (VIEW_TYPES.includes(file.previewAction)) {
                                    //     actions.push({
                                    //         content: i18n.t('pryaniky.files.view'),//<OptionContent icon="eye" text={i18n.t('pryaniky.files.view')} />,
                                    //         action: 'view',
                                    //         buttonProps: { onClick: viewOpen },
                                    //     });
                                    // }
                                    break;
                                };
                                case 'editinoffice': {
                                    if(!file.isFolder && file.editUrl) {
                                        actions.push({
                                            content: i18n.t('pryaniky.files.item.editInOffice'),
                                            action: 'editInOffice',
                                            buttonProps: { type: 'link', href: file.editUrl, download: true },
                                        });
                                    }
                                    break;
                                };
                                case 'editinofficeonline': {
                                    if(!file.isFolder) {
                                        actions.push({
                                            content: i18n.t('pryaniky.files.item.editInOfficeOnline'),
                                            action: 'editInOffice',
                                            buttonProps: { type: 'link', href: el.data, target: '_blank' },
                                        });
                                    }
                                    break;
                                }
                            }
                        });
                        // } else {
                        //     if (!file.isFolder) {
                        //         actions.push({
                        //             content: i18n.t('pryaniky.files.open'),//<OptionContent icon="eye" text={i18n.t('pryaniky.files.view')} />,
                        //             action: 'view',
                        //             buttonProps: { type: 'link', href: file.originalUrl, download: false },
                        //         });

                        //         actions.push({
                        //             content: i18n.t('pryaniky.files.download'),//<OptionContent icon="eye" text={i18n.t('pryaniky.files.view')} />,
                        //             action: 'view',
                        //             buttonProps: { type: 'link', href: file.originalUrl, download: true },
                        //         });
                        //     }
                        // }

                        return (
                            <FileItem
                                actions={actions}
                                date={file.date}
                                displayUserName={file.displayUserName}
                                fid={file.id}
                                name={file.name}
                                onSelect={() => {}}
                                previewUrl={file.previewUrl}
                                selected={false}
                                urlname={file.urlname}
                            />
                        );
                    })
                }
            </DialogContent>
        </>
    );
};

export const AttachementHistoryDialog = createModal(AttachementHistoryPr, {
    maxWidth: 'md',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: 'transparent',
        }
    },
    TransitionComponent: TransitionComponent,//LeftDirectionTransitionComponent,
    scroll: 'body'
});