import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IProjectLogoProps extends IClassNameProps, ReturnType<typeof mapStateToProps> {}

export interface IProjectLogoState {
  text?: string;
}

export const mapStateToProps = (state: any) => ({
  companyName: state.store.appSettings.name,
  logoUrl: state.store.appSettings.titleLogo,
})

export const cnProjectLogo = cn('ProjectLogo');