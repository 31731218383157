import React, { FC, useEffect, SyntheticEvent, useMemo, useRef, memo, useCallback, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'localizations/i18n';
import { Box, Paper, Typography } from 'muicomponents/src';
import { clearShopCatProducts, getShopProducts, SHOP_CLEAR_SHOP_FILTERS } from 'redux/shop/actions';
import { ShopItem } from '../Item/Shop-Item';
import { ListContainer, ShopCatalogSkeleton } from './ListContainer';
import { NEW__useInfiniteScroll, useDebounce } from 'utils/src/hooks';
import { getShopCategoryProducts, getShopCategoryStatus } from 'redux/shop/selectors';
import { useShopRequestOptions } from './ShopListMui.hooks';
import { ItemsList } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody'
import { IStateType } from 'redux/store';
import { setShopFilters, resetFilters } from 'redux/shop/actions';
import { HeaderFilters } from './HeaderFilters'
import { Wallet } from './Wallet'
import { DefaultPlaceholder, ListPlaceholder } from 'muicomponents/src/ListPlaceholder/ListPlaceholder'
import { ShopListContextProvider } from './ShopListMui.context';


export interface IListTypeShopProps {
  authUser?: any
}





const NOOP_FN = () => { }
const NOOP_ASYNC_FN = async function () { }
const NOOP_ARRAY: any[] = []
const NOOP_SELECTED = {
  selected: [],
  addSelect: () => { },
  isSelected: () => false,
  removeSelect: () => { },
  togleSelect: () => { },
  selectAll: () => { },
  unselectAll: () => { },
  toggleAll: () => { },
  isAllSelected: false,
  setSelected: () => { }
}
const NOOP_ACTOINS = {
  removeItems: () => { },
  findItem: () => { },
  updateItem: () => { },
  appendItems: () => { },
  prependItems: () => { }
}

const count = 20




const ItemComponent = ({ item }: any) => <ShopItem
  key={item.id}
  data={item}
/>

export const ShopListMui: FC<IListTypeShopProps> = ({
  ...props
}: IListTypeShopProps) => {

  const dispatch = useDispatch()
  const products = useSelector(getShopCategoryProducts).data
  const { isLoading, isFinished } = useSelector(getShopCategoryStatus)
  const keys = Object.keys(products || {})
  const skipCount = keys.length
  const { requestOptions, chosenFilters } = useShopRequestOptions(count, skipCount)

  const loadMore = (clean = false) => {
    dispatch(getShopProducts({ ...requestOptions, skipCount: clean ? 0 : requestOptions.skipCount }))
    return new Promise<any>((r) => {
      r({})
    })
  }
  const loadMoreClean = () => {
    dispatch(clearShopCatProducts())
    return loadMore(true)
  }

  useEffect(() => {
    loadMoreClean()
  }, [chosenFilters])

  useEffect(() => {
    if (!products) loadMore()
  }, [])

  if (!products) return <ShopCatalogSkeleton />

  return <ShopListContextProvider>
    <ItemsList<any>

      BodyComponent={ItemsListBody}
      listBodyProps={{
        BodyComponent: Box
      }}
      listComponentProps={{
        PlaceholderComponent: (props) => <Paper><DefaultPlaceholder {...props} /></Paper>
      }}
      listStateHook={({ middleware = (p) => p }, middlewareParams) => {
        const search = useSelector((s: IStateType) => s.shop.chosenFilters.search)
        const [searchValue, setSearchValue] = useState(search)

        const changeSearch = (v: string) => {
          if (v.length === 1) return
          dispatch(setShopFilters({
            key: 'search',
            value: v
          }))
        }
        const throttledChange = useDebounce(
          changeSearch,
          3000
        )

        const handleSearch = (value: string) => {
          setSearchValue(value)
          throttledChange(value)
        }

        const { data, shopClosedText, isShopClosed } = useSelector(getShopCategoryProducts) as any
        const products = data
        const triggerRef = NEW__useInfiniteScroll({
          loadMore,
          isLoading,
          isFinished,
          isError: false,
        })

        const params: typeof middlewareParams = {
          ...middlewareParams,
          ItemComponent,
          listComponentProps: {
            ...middlewareParams.listComponentProps,
            ContentComponent: ListContainer
          },
          listHeaderProps: {
            SearchActions: <Wallet />,
            bottomContent: <HeaderFilters />
          },
          BodyComponent: isShopClosed ? (props) => <Paper><ListPlaceholder
            // {...props}
            isFinished={true}
            isLoading={false}
            itemsCount={0}
            titleEmpty={shopClosedText} /></Paper> : ItemsListBody
        }

        const state = {
          triggerRef,
          loadMore,
          loadMoreClean,
          values: Object.values(products),
          isLoading,
          isFinished,
          itemsCount: keys.length,
          isError: false,
          error: {
            withError: false
          },
          actions: NOOP_ACTOINS,
          select: NOOP_SELECTED
        }
        return {
          middlewareParams: {
            ...params,
            listHeaderProps: {
              ...params.listHeaderProps,
            }
          },
          tabsValue: params.tabsValue,
          middleware,
          changeSearch: handleSearch,
          defaultSelected: NOOP_ARRAY,
          onChangeTab: NOOP_FN,
          search: searchValue,
          state
        }

      }}
    />
  </ShopListContextProvider>
}






