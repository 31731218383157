import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps, } from 'react-router';
import { ManifestType } from '../types'

///////////
export declare namespace StatisticsFull {
    export namespace StatView {
        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps<{ id: string }>

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            value: ManifestType
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////

type IOwnProps = StatisticsFull.StatView.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({

})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({

}, dispatch);

////////////

export const cnClassName = cn('LMSTOStatView');