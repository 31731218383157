import { createDefaultPostNews } from '../../baseNews';
import { NThanks } from './Thanks.interface';

export const baseThanks: NThanks.Create = ({
    ...createDefaultPostNews(),
    rewardReasons: [],
    newstype: 4,
    users: [],
    thanks: {
        participants: []
    },
    componentRenderName: 'thanks'
})