import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { loadRequests, approveRequests } from 'redux/actions/Groups'
import { getGroupByPKID } from 'redux/sagas/Groups/selectors'
export interface IApproveDialog extends IClassNameProps, IApproveDialogStateProps, IDispatchProps, RouteComponentProps, IApproveDialogDispatchProps {
    tag?: 'div';
    onCloseDialog: () => void;
    showDialog: boolean
    pkid: number;
}

export interface IApproveDialogState {

}

export interface IApproveDialogStateProps {
    group: any
}

export const mapStateToProps = (state: any, props: any) => ({
    group: getGroupByPKID(props.pkid)(state)
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadRequests, approveRequests
}, dispatch);

export type IApproveDialogDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnApproveDialog = cn('ApproveGroupRequests');