import React, { FC, memo, useMemo } from 'react'
// import {
//     cnClassName,
//     IListPlaceholderProps
// } from './interfaces'
// import './style.scss'
// import { ListPlaceholder } from 'uielements/src';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import lmsPlaceholder from 'assets/svg/lmsPlaceholder.svg';
import ListPlaceholder, { IComponentProps } from 'uielements/src/ListPlaceholder'

export const Presenter: FC<IComponentProps> = (props) => {
    return <ListPlaceholder {...props} />
}

export default Presenter;
