import actions from '../actionsTypes';
import { ReducerAction } from '../actions/types'
import { union, xor, omit, values } from 'lodash'
import { IKPIReducer } from './types'
import { defaultKPI } from './default'


export function kpiReducer(state = defaultKPI, action: ReducerAction.TKPIReducerActions): IKPIReducer {
    switch (action.type) {

        case actions.SET_KPI_LIST: {
            const { keys, values, userId } = action.payload
            return {
                ...state,
                keys: {
                    ...state.keys,
                    [userId]: keys,//: union(state.keys, keys),
                },
                values: { ...state.values, ...values }
            }
        }

        case actions.ADD_KPI_ITEM: {
            const { userId, keys,values } = action.payload
            return {
                ...state,
                keys: {
                    ...state.keys,
                    [userId]: union(keys, state.keys[userId]),
                },
                values: { ...state.values, ...values }
            }
        }

        case actions.PATH_KPI_ITEM: {
            const { id, ...params } = action.payload
            return {
                ...state,
                values: {
                    ...state.values,
                    [id]: {
                        ...state.values[id],
                        ...params
                    }
                }
            }
        }

        case actions.DROP_KPI_ITEM: {
            const { id, userId } = action.payload
            return {
                ...state,
                keys: {
                    ...state.keys,
                    [userId]: state.keys[userId].filter((value) => value !== id)
                }
            }
        }

        case actions.CHANGE_FIELD: {
            const { field, value } = action.payload as any
            return {
                ...state,
                [field]: value
            }
        }

        default: {
            return [

                (state: IKPIReducer, action: ReducerAction.TKPIReducerActions) => state,
            ].reduce((prev, curr) => {
                return curr(prev, action)
            }, state)
        }
    }
}

