import { IButtonPopoverProps, cnLinkLayout } from './LinkLayout.index'
import React, { memo } from 'react'
import './LinkLayout.scss';
import star from './default-star.png';
import { getCookie } from 'utils/src/utils';
import MDRender from 'uielements/src/CommonmarkRender'
import { Avatar } from 'uielements/src';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn'

// const cutCount = 5000;

export const LinkLayoutPresenter: React.FC<IButtonPopoverProps> = ({ linkPreviewImgUrl, systemNewsActionHtml, newstype, children }) => {
    if (!systemNewsActionHtml) return null;
    let icon = star
    if (linkPreviewImgUrl) {
        icon = linkPreviewImgUrl.includes('base64') ? linkPreviewImgUrl : (linkPreviewImgUrl + `?auth=${getCookie('authAt')}`)
    }
    let first = systemNewsActionHtml.indexOf('[')
    let second = systemNewsActionHtml.indexOf(']')
    let name = systemNewsActionHtml.slice(first + 1, second)

    return (
        <div className={cnLinkLayout({}, ['systemHorizontalPadding'])}>
            {!linkPreviewImgUrl ?
                <Avatar
                    name={newstype === 0 ? undefined : name}
                    imgUrl={newstype === 0 ? BaseRequests.getUrl(defaultBadgeIconUrl) : undefined}
                    size={56} /> :
                <img
                    className={cnLinkLayout('Img')}
                    src={icon}
                />}
            <div className={cnLinkLayout('Text')}>
                <MDRender textCut={true} source={systemNewsActionHtml} />
                {children}
            </div>
        </div>
    );
}

export const LinkLayout = memo(LinkLayoutPresenter)