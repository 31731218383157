import test from '../components/TaskSelector/item/img/test.svg'
import lesson from '../components/TaskSelector/item/img/lesson.svg'
import React from 'react';
import { Us, tU, tC, Tc } from './i18n.adaptor'

export const cutText = (text: string, length: number) => {
    return text.length > length ? text.substring(0, 30) + '...' : text
}

/**
 * иконки состояний юнитов
 */
export const sicons: { [key: string]: string } = {
    notStarted: 'fa-lock',
    completed: 'check',
    current: 'play',
    skipped: 'arrow-to-right',
    allow: 'lock-open'
}

/**
 * типы юнитов
 */
export const TYPE_TEXTS: { [key: string]: React.ReactNode } = {
    test: <Us i18nKey='test.1' />,//'Задание',
    wiki: <Us i18nKey='lesson.1' />,//''Урок',
    task: <Us i18nKey='task.1' />,//''Урок',
    scorm: <Us i18nKey='scorm.1' />,//''Scorm'
}

/**
 * иконки типов юнитов
 */
export const TYPE_ICONS: { [key: string]: string } = {
    test: test,
    task: test,
    wiki: lesson,
    scorm: lesson
}

/**
 * список типов пропуска
 */
export const skipType: { [k: number]: React.ReactNode } = {
    0: <Tc i18nKey='skipType.0.1' />,//tC('skipType.0.1'),
    1: <Tc i18nKey='skipType.1.1' />,//tC('skipType.1.1'),
    2: <Tc i18nKey='skipType.2.1' />,//tC('skipType.2.1'),
}


export interface IUnitType {
    value?: any;
    title: string;
    typeImage: string;
    unitType: React.ReactNode;
    skipType?: React.ReactNode;
    id: string;
    icon: string;
    iconColor?: string;
}

/**
 * создание элемента для навигации
 * @param unit 
 * @param icon 
 */
export const createItem = (unit: any, icon: string): IUnitType => {
    return {
        value: unit.id,
        title: unit.name,
        typeImage: TYPE_ICONS[unit.unitType] ? TYPE_ICONS[unit.unitType] : lesson,
        unitType: TYPE_TEXTS[unit.unitType] ? TYPE_TEXTS[unit.unitType] : tU('lesson.1'),//'Урок',
        skipType: skipType[unit.skipUnitType] ? <>/{skipType[unit.skipUnitType]}</> : null,
        id: unit.id,
        icon
    }
}

/**
 * создаёт массив элементов для TaskSelector. Этап прохождения
 * @param units  массив юнитов
 * @param logs коллекция логов
 * @param currentUid текущий юнит
 */
export const createNavItem = (units: any[], logs: any, currentUid: string): IUnitType[] => {
    let allow = false
    return units.map((unit: any) => {
        // const unitStatus = logs[unit.id] ?
        //     (logs[unit.id].isCompleted ?
        //         'completed' : (currentUid === unit.id ?
        //             'current' : (logs[unit.id].isSkipped ?
        //                 'skipped' : (allow ? 'allow' : 'notStarted')))) : 'notStarted'
        const unitStatus = [
            logs[unit.id]?.isCompleted && 'completed',
            currentUid === unit.id && 'current',
            logs[unit.id]?.allowSkip && 'allow',
            logs[unit.id]?.isSkipped && 'allow',
            logs[unit.id] && 'notStarted',
        ].filter(Boolean)[0] || 'notStarted'

            allow = logs[unit.id] ? (logs[unit.id].isCompleted || logs[unit.id].isSkipped || logs[unit.id].allowSkip) : false;
        return createItem(unit, sicons[unitStatus])
    })
}

/**
 * создаёт массив элементов для TaskSelector. Этап результатов
 * @param units  массив юнитов
 */
export const createResultNavItems = (units: any[], unitsResults: any): IUnitType[] => {
    return units.map((unit: any) => {
        const rightPercent = Math.round(unitsResults[unit.id].rightAnswersCount / unitsResults[unit.id].questionsCount * 100)
        let icon = unitsResults[unit.id].waitModerationCount > 0 ?
            'clock' :
            (unit.unitType !== 'test' ? 'check' : (unit.passingMinScore <= rightPercent ? 'check' : 'times'));
        icon = unit.unitType === 'scorm' ? (unitsResults[unit.id].isCompletedSuccess ? 'check' : 'times') : icon
        if (unit.skipUnitType === 1) {
            icon = icon === 'times' ? 'arrow-to-right' : icon
        }

        return createItem(unit, icon)
    })
}

export const getCurrentUnitIndex = (courseUnits: any[], currentId: string) => courseUnits.findIndex((u: any) => currentId === u.id)

export const getIsFirst = (unitIndex: number) => {
    return unitIndex === 0;
}

export const getIsLast = (unitIndex: number, courseLength: number) => {
    return unitIndex === (courseLength - 1);
}

export const getUnitPosition = (courseUnits: any[], currentId: string) => {
    const unitIndex = getCurrentUnitIndex(courseUnits, currentId)
    const isFirst = getIsFirst(unitIndex);
    const isLast = getIsLast(unitIndex, courseUnits.length)
    return {
        unitIndex,
        isFirst,
        isLast
    }
}