import { IGroupFullInfo } from "utils/src/requests/models/api.groups";
import actions from 'redux/actionsTypes/Groups';

// export const gotGroupInfo = (info: IGroupFullInfo) => ({ type: actions.GOT_INFO, payload: info });

/********************************************************/
/******************** to saga ***************************/
/********************************************************/

/************************* LOAD_GROUP *******************************/
export type loadGroupPayloadType = { groupPKID: number | string }
export type loadGroupActionType = { type: string, payload: loadGroupPayloadType }
/**
 * запрашиваем группу с сервера
 * @param groupPKID 
 */
export const loadGroup = (groupPKID: number | string): loadGroupActionType => ({
    type: actions.LOAD_GROUP,
    payload: { groupPKID }
})

export const checkGroupContext = (groupPKID: number | string): loadGroupActionType => ({
    type: actions.CHECK_GROUP_CONTEXT,
    payload: { groupPKID }
})

/*********************** JOIN_USER_TO_GROUP *********************************/
export type joinUserToGroupPayloadType = { groupPKID: number | string, userId: string, join: boolean, goto?: string }
export type joinUserToGroupActionType = { type: string, payload: joinUserToGroupPayloadType }
/**
 * отправить запрос на присоединение пользователя к группе
 * @param groupPKID
 * @param userId
 * @param join
 */
export const joinUserToGroup = (groupPKID: string | number, userId: string, join: boolean, goto?: string): joinUserToGroupActionType => ({
    type: actions.JOIN_USER_TO_GROUP,
    payload: { groupPKID, userId, join, goto }
})


/*********************** CHAGE_MY_IN_GROUP *********************************/
export type changeMyInGroupPayloadType = { groupPKID: number | string }
export type changeMyInGroupActionType = { type: string, payload: changeMyInGroupPayloadType }
/**
 * изменяет состояние текущего пользователя в указаной группе.
 * @param groupPKID 
 */
export const changeMyInGroup = (groupPKID: string | number): changeMyInGroupActionType => ({
    type: actions.CHAGE_MY_IN_GROUP,
    payload: { groupPKID }
})

/************************* SET_NOTIFICATE_TEMPLATE *******************************/
export type setNotificationTemplatePayloadType = { groupPKID: number | string, template: string }
export type setNotificationTemplateActionType = { type: string, payload: setNotificationTemplatePayloadType }
/**
 * изменяет шаблон уведомлений для выбраной группы у текущего пользователя
 * @param groupPKID 
 * @param template 
 */
export const setNotificationTemplate = (groupPKID: string | number, template: string): setNotificationTemplateActionType => ({
    type: actions.SET_NOTIFICATE_TEMPLATE,
    payload: { groupPKID, template }
})

/************************* LOAD_SETTINGS *******************************/
export type loadSettingsPayloadType = { groupPKID: number | string }
export type loadSettingsActionType = { type: string, payload: loadSettingsPayloadType }
/**
 * получить с сервера настройки для указаной группы
 * @param groupPKID 
 * @param settings 
 */
export const loadSettings = (groupPKID: string | number): loadSettingsActionType => ({
    type: actions.LOAD_SETTINGS,
    payload: { groupPKID }
})

/************************* APPLY_NOTIFICATIONS_TEMPLATE *******************************/
export type applyTemplateToAllPayloadType = { groupPKID: number | string }
export type applyTemplateToAllActionType = { type: string, payload: loadSettingsPayloadType }
/**
 * применяет шаблон уведомлений ко всем учасникам группы
 * @param groupPKID 
 */
export const applyTemplateToAll = (groupPKID: string | number): applyTemplateToAllActionType => ({
    type: actions.APPLY_NOTIFICATIONS_TEMPLATE,
    payload: { groupPKID }
})

/************************* TEST_AUTO_INCLUDE *******************************/
export type testAutoIncludePayloadType = { groupPKID: number | string }
export type testAutoIncludeActionType = { type: string, payload: testAutoIncludePayloadType }
/**
 * проверка выражения автодобавления в группу
 * @param groupPKID 
 */
export const testAutoInclude = (groupPKID: string | number): testAutoIncludeActionType => ({
    type: actions.TEST_AUTO_INCLUDE,
    payload: { groupPKID }
})

/************************* SET_AUTO_INCLUDE *******************************/
export type setAutoIncludePayloadType = { groupPKID: number | string }
export type setAutoIncludeActionType = { type: string, payload: setAutoIncludePayloadType }
/**
 * производит принудительное влючение юзерв в группу по текущему условию
 * @param groupPKID 
 */
export const setAutoInclude = (groupPKID: string | number): setAutoIncludeActionType => ({
    type: actions.SET_AUTO_INCLUDE,
    payload: { groupPKID }
})

/************************* SEND_INTERCOMPANY_REQUEST *******************************/
export type IntercompanyRequestParams = { createTarget: string, content: string, attendance: string }
export type sendIntercompanyRequestPayloadType = { groupPKID: number | string, data: IntercompanyRequestParams }
export type sendIntercompanyRequestActionType = { type: string, payload: sendIntercompanyRequestPayloadType }
/**
 * отправляет заявку на перевод группы в статус межсетевой
 * @param groupPKID пкид группы, для которой будет отправлена заявка
 * @param {IntercompanyRequestParams} data контент заявки
 */
export const sendIntercompanyRequest = (groupPKID: string | number, data: IntercompanyRequestParams): sendIntercompanyRequestActionType => ({
    type: actions.SEND_INTERCOMPANY_REQUEST,
    payload: { groupPKID, data }
})

/************************* CANCLE_INTERCOMPANY_REQUEST *******************************/
export type cancleIntercompanyRequestPayloadType = { groupPKID: number | string, comment: string }
export type cancleIntercompanyRequestActionType = { type: string, payload: cancleIntercompanyRequestPayloadType }
/**
 * отменяет заявку на перевод группы в статус межсетевой
 * @param groupPKID пкид группы, заявка которой буде отменена
 * @param comment комментарий
 */
export const cancleIntercompanyRequest = (groupPKID: string | number, comment: string): cancleIntercompanyRequestActionType => ({
    type: actions.CANCLE_INTERCOMPANY_REQUEST,
    payload: { groupPKID, comment }
})

/************************* CANCLE_EDIT *******************************/
export type cancleEditPayloadType = { groupPKID: number | string }
export type cancleEditActionType = { type: string, payload: cancleEditPayloadType }
/**
 * отменить редактирование группы
 * @param groupPKID пкид группы
 */
export const cancleEdit = (groupPKID: string | number): cancleEditActionType => ({
    type: actions.CANCLE_EDIT,
    payload: { groupPKID }
})

/************************* APPLY_EDIT *******************************/
export type applyEditPayloadType = { groupPKID: number | string }
export type applyEditActionType = { type: string, payload: cancleEditPayloadType }
/**
 * применить изменения после редактирования
 * @param groupPKID пкид группы
 */
export const applyEdit = (groupPKID: string | number): applyEditActionType => ({
    type: actions.APPLY_EDIT,
    payload: { groupPKID }
})

/************************* LOAD_GROUP_REQUESTS *******************************/
export type loadRequestsPayloadType = { groupPKID: number | string }
export type loadRequestsActionType = { type: string, payload: loadRequestsPayloadType }
/**
 * получить список заявок на всупление в группу
 * @param groupPKID пкид группы
 */
export const loadRequests = (groupPKID: string | number): loadRequestsActionType => ({
    type: actions.LOAD_GROUP_REQUESTS,
    payload: { groupPKID }
})

/************************* APPROVE_REQUESTS *******************************/
export type approveRequestsPayloadType = { groupPKID: number | string, usersId: string[], join: boolean }
export type approveRequestsActionType = { type: string, payload: approveRequestsPayloadType }
/**
 * подтвердить или отклонить заявки на ступление в группу
 * @param groupPKID пкид группы
 * @param usersId массив ид пользователей
 * @param join подтвердить или откланить
 */
export const approveRequests = (groupPKID: string | number, usersId: string[], join: boolean): approveRequestsActionType => ({
    type: actions.APPROVE_REQUESTS,
    payload: { groupPKID, usersId, join }
})

/************************* UPLOAD_IMPORT_USERS_FILE *******************************/
export type uploadImportPayloadType = { groupPKID: number | string }
export type uploadImportActionType = { type: string, payload: uploadImportPayloadType }
/**
 * загрузить файл со списком пользователей для добавления в группу
 * @param groupPKID пкид группы
 */
export const uploadImport = (groupPKID: string | number): uploadImportActionType => ({
    type: actions.UPLOAD_IMPORT_USERS_FILE,
    payload: { groupPKID }
})



/***********************************************************/
/******************** to reducer ***************************/
/***********************************************************/

/************************ ADD_GROUP ********************************/
export type addGroupPayloadType = { groupPKID: number | string, group: IGroupFullInfo }
export type addGroupActionType = { type: string, payload: addGroupPayloadType }
/**
 * добавляет группу в стор
 * @param groupPKID 
 * @param group 
 */
export const addGroup = (groupPKID: number | string, group: IGroupFullInfo): addGroupActionType => ({
    type: actions.ADD_GROUP,
    payload: { groupPKID, group }
})

/************************ UPDATE_GROUP ********************************/
export type updateGroupPayloadType = { groupPKID: number | string, fields: { [key: string]: any } }
export type updateGroupActionType = { type: string, payload: updateGroupPayloadType }
/**
 * обновляет переданые свойства в объекте группы
 * @param groupPKID 
 * @param fields объект ключ-значение которые будут обновлены в группе
 */
export const updateGroup = (groupPKID: number | string, fields: { [key: string]: any }): updateGroupActionType => ({
    type: actions.UPDATE_GROUP,
    payload: { groupPKID, fields }
})

/************************ REMOVE_GROUP ********************************/
export type removeGroupPayloadType = { groupPKID: number | string }
export type removeGroupActionType = { type: string, payload: removeGroupPayloadType }
/**
 * удаляет объект группы из стора
 * @param groupPKID 
 */
export const removeGroup = (groupPKID: number | string): removeGroupActionType => ({
    type: actions.REMOVE_GROUP,
    payload: { groupPKID }
})

/************************ ADD_SETTINGS ********************************/
export type addSettingsPayloadType = { groupPKID: number | string, settings: any }
export type addSettingsActionType = { type: string, payload: addSettingsPayloadType }
/**
 * добавляет настройки группы в стор
 * @param groupPKID 
 * @param settings
 */
export const addSettings = (groupPKID: number | string, settings: any): addSettingsActionType => ({
    type: actions.ADD_SETTINGS,
    payload: { groupPKID, settings }
})

/************************ UPDATE_SETTINGS ********************************/
export type updateSettingsPayloadType = { groupPKID: number | string, fields: { [key: string]: any } }
export type updateSettingsActionType = { type: string, payload: updateSettingsPayloadType }
/**
 * обновляет переданые свойства в объекте настроек группы
 * @param groupPKID 
 * @param fields объект ключ-значение которые будут обновлены в настройках
 */
export const updateSettings = (groupPKID: number | string, fields: { [key: string]: any }): updateSettingsActionType => ({
    type: actions.UPDATE_SETTINGS,
    payload: { groupPKID, fields }
})

/************************ UPDATE_SETTINGS ********************************/
export type removeSettingsPayloadType = { groupPKID: number | string }
export type removeSettingsActionType = { type: string, payload: removeSettingsPayloadType }
/**
 * удаляет объект настроек группы из стора
 * @param groupPKID 
 */
export const removeSettings = (groupPKID: number | string): removeSettingsActionType => ({
    type: actions.REMOVE_SETTINGS,
    payload: { groupPKID }
})

/**************************** SET_PROCESSING ****************************************/
export type TProcessStatus = 'success' | 'process' | 'failed'
export type setProcessingPayloadType = { groupPKID: number | string, pid: keyof typeof actions, status: TProcessStatus }
export type setProcessingActionType = { type: string, payload: setProcessingPayloadType }
/**
 * устанавливает статус запроса
 * @param groupPKID ид групп
 * @param pid ид процесса, любая строка
 * @param status статус запроса
 */
export const setProcessing = (groupPKID: string | number, pid: keyof typeof actions, status: TProcessStatus): setProcessingActionType => ({
    type: actions.SET_PROCESSING,
    payload: { groupPKID, pid, status }
})

/**************************** SELECT_TAGS_AUTO ****************************************/
export type selectTagsAutoPayloadType = { groupPKID: number | string };
export type selectTagsAutoActionType = { type: string; payload: selectTagsAutoPayloadType };
export type selectTagsAutoCreatorType = (payload: selectTagsAutoPayloadType) => selectTagsAutoActionType;
export const selectTagsAuto: selectTagsAutoCreatorType = payload => ({
    type: actions.SELECT_TAGS_AUTO,
    payload
})