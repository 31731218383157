import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { RouteComponentProps } from 'react-router';
import { IShopOrder } from 'utils/src/requests/models/api.shop';
import { IDispatchProps } from 'redux/connector';

import { bindActionCreators, Action, Dispatch } from "redux"
import { IStateType as IState } from '../../../redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface IBadgeListTypeUsersProps extends ILeaderboardOwnProps, IClassNameProps { }

export interface ILeaderboardOwnProps extends RouteComponentProps<IParams> { 
    tag?: 'div';
}

export interface IParams {
    id: string
}
export type IBadgeUsersListStateProps = ReturnType<typeof mapBadgeUsersListStateToProps>;

export const mapBadgeUsersListStateToProps = (state: IState) => {
    return {
        curUser: getAuthUser(state).baseData,
    };
};

export type IBadgeUsersListDispatchProps = ReturnType<typeof mapBadgeUsersListDispatchProps>

export const mapBadgeUsersListDispatchProps = (dispatch: Dispatch<Action>) => bindActionCreators({
}, dispatch)
export const cnBadgeListTypeUsers = cn('BadgeListTypeUsers');

