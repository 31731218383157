/**
 * @packageDocumentation
 * @module Widget_Settings_timeline
 */
import { JFdictionary } from "../dictionary";
import i18n from '../../../../localizations/i18n';

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.input,
      scope: "#/properties/name"
    },
    // {
    //   type: renderers.tagsInput,
    //   scope: "#/properties/selectedTags"
    // },
    // {
    //   type: renderers.layout,
    //   elements: [
    //     {
    //       type: renderers.tagsInput,
    //       scope: "#/properties/selectedTags"
    //     },
    //   ]
    // },
    // {
    //   type: renderers.layout,
    //   elements: [
    //     {
    //       type: renderers.groupsInput,
    //       scope: "#/properties/selectedGroups"
    //     }
    //   ]
    // },
    // {
    //   type: renderers.select,
    //   scope: "#/properties/selectedNews"
    // },
    {
      type: renderers.number,
      scope: "#/properties/count"
    },
    {
      type: renderers.number,
      scope: "#/properties/range"
    },
    {
      type: renderers.checkbox,
      label: i18n.t('pryaniky.widgets.settings.type.shop.noData'),
      scope: "#/properties/hideIfNoData"
    },

  ]
}