import { Translate } from "localizations/Translate";
import React, { useMemo } from "react";
import { PageTypes } from "utils/src";

export const useWikiItemViewersTitle = (pageType: PageTypes) => {
    return useMemo(() => {
        switch (pageType) {
            case PageTypes.wikilist:
            case PageTypes.imageGallery:
            case PageTypes.videoGallery:
                return <Translate
                    i18nKey='pryaniky.wikilist.item.param.viewers'
                    values={{
                        type: Translate.t({
                            i18nKey: `pryaniky.wikilist.item.param.viewers.content`,
                            values: {
                                type: Translate.t({
                                    i18nKey: `pryaniky.wikilist.item.${pageType}.accusative`
                                }).toLowerCase()
                            }
                        })
                    }}
                />;
            default:
                return <Translate
                    i18nKey='pryaniky.wikilist.item.param.viewers'
                    values={{
                        type: Translate.t({
                            i18nKey: `pryaniky.wikilist.item.${pageType}.accusative`
                        }).toLowerCase()
                    }}
                />;
        }
    }, [pageType]);
};