import React, { FC, useEffect } from 'react'
import { cnAnswerList, mapDispatchToProps, mapStateToProps } from './AnswerList.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './AnswerList.scss'
import { Button } from 'uielements/src';
import { Answer } from '../Answer/Answer'
import { withPreloader } from '../../LMS/Preloader/Preloader'
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import QuestionRender from '../../components/Question'
import ListPlaceholder from '../../components/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

const LoadButton = withLoadSpin(Button)

export const List: FC<any> = ({ answersIds, answers, questions }) => {
    let prevQuestionId = '0';
    return answersIds.map((aid: string) => {
        const question = questions[answers[aid].questionId]
        const uniqVersionId = question.versionDate + '_' + answers[aid].questionId
        if (prevQuestionId !== uniqVersionId) {
            prevQuestionId = uniqVersionId
            return (<>
                <QuestionRender
                    key={uniqVersionId + aid}
                    className={cnAnswerList('Question')}
                    isOldVersion={!question.lastVersion}
                    versionDate={question.versionDate}
                    content={question.text}
                    header={question.header}
                />

                <Answer className={cnAnswerList('Answer')} key={aid} aid={aid} />
            </>)
        }
        return <Answer className={cnAnswerList('Answer')} key={aid} aid={aid} />
    })
}

export const AnswerListPresenter: FC<any> = ({
    className,
    match,
    units,
    answersIds,
    loadModerationAnswers,
    sessionId,
    questions,
    answers,
    users,
    courseId,
    filters,
    isLoading,
    isFinished,
    hideModeration,
    listContainer = 'main',
    noScroll = false
}) => {
    useEffect(() => {
        loadMore(true)
    }, [filters, courseId])
    const loadMore = (clean = false) => loadModerationAnswers(courseId, filters, clean, listContainer)
    const triggerRef = useInfiniteScroll({
        loadMore: loadMore,
        isLoading,
        isFinished,
    });

    let prevQuestionId = '0';

    return <div className={cnAnswerList({}, [className])}>
        <ListPlaceholder
            className={cnAnswerList('Placeholder')}
            isFinished={isFinished}
            isLoading={isLoading}
            itemsCount={answersIds.length}
            titleEmpty={tT('answer.list.empty.title.1')}
            textEmpty={tT('answer.list.empty.text.1')}
            textLoad={tT('answer.list.load.1')}
        >
            {answersIds.map((aid: string) => {
                const answer = answers[aid]
                const uniqVersionId = answer.quesionVersionDate + '_' + answer.questionId
                const question = questions[uniqVersionId]
                if (prevQuestionId !== uniqVersionId) {
                    prevQuestionId = uniqVersionId
                    return (<>
                        <QuestionRender
                            hideContent={true}
                            key={uniqVersionId + aid}
                            className={cnAnswerList('Question')}
                            isOldVersion={!question.lastVersion}
                            versionDate={question.versionDate}
                            content={question.text}
                            header={question.header}
                        />

                        <Answer hideModeration={hideModeration} className={cnAnswerList('Answer')} key={aid} aid={aid} />
                    </>)
                }
                return <Answer hideModeration={hideModeration} className={cnAnswerList('Answer')} key={aid} aid={aid} />
            })
            }
            {(!isFinished) && (
                <LoadButton
                    forwardedRef={triggerRef}
                    isLoading={isLoading}
                    className={cnAnswerList('Trigger')}
                    onClick={isLoading ? void 0 : loadMore}>
                    {tT('answer.list.more.1')}
                </LoadButton>)}
        </ListPlaceholder>
    </div>
}

export const AnswerList = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AnswerListPresenter));
