import React, { FC, useEffect } from 'react'
import { Box } from 'muicomponents/src';
import { clearShopCatProducts } from 'redux/shop/actions';
import InfinityList from 'muicomponents/src/InfinityList/InfinityList';
import { useDispatch, useSelector } from 'react-redux';
import { getShopCategoryProducts, getShopCategoryStatus } from 'redux/shop/selectors';
import { ListContainer } from '../ShopList/ListContainer';
import { ShopItem } from '../Item/Shop-Item';
import { IListRequestOptions } from 'utils/src/requests/models/api.base';
import { getShopProducts } from 'redux/shop/actions';
import { getCurrencyNames } from 'utils/src/CommonRedux/base/selectors';
import { cn } from '@bem-react/classname';
export const cnClassName = cn('MUIShopCurrentCategory');
interface ICurrentCategoryProps {
    categories: any
    id: number
    myThanksCount: number
}

export const CurrentCategory: FC<ICurrentCategoryProps> = ({
    categories,
    id,
    // myThanksCount,
}) => {

    const products = useSelector(getShopCategoryProducts).data
    const { isLoading, isFinished } = useSelector(getShopCategoryStatus)
    const dispatch = useDispatch()
    // const currencyFormats = useSelector(getCurrencyNames)

    const catIds = categories.map((cat: any) => cat.id)

    const requestOptions: IListRequestOptions = {
        skipCount: 0,
        count: 5,
        search: '',
        CatFilterIds: catIds,
        CharFilters: [],
        MyFavorite: false,
        OrderByRating: '',
        OrderByPopular: '',
        OrderByCreateAt: 'desc',
        OrderByPrice: '',
    }

    useEffect(() => {
        dispatch(clearShopCatProducts())
        dispatch(getShopProducts(requestOptions))
    }, [categories])

    if (!products) return null

    return <Box sx={{ margin: '24px' }}  className={cnClassName("CategoryComponent")}>
        <InfinityList
            isFinished
            isLoading={isLoading}
            itemsCount={products.length}
            ContentComponent={ListContainer}
        >
            {
                products.map((product, i) => {
                    if (product.id === id || i === 4) return null
                    else return <ShopItem              
                        key={product.id}
                        data={product}
                        // myThanksCount={myThanksCount}
                        // currencyFormats={currencyFormats}
                    />
                })
            }
        </InfinityList>
    </Box >
}


