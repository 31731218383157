import { pick } from 'lodash';
import { IStateType } from 'redux/store';
import { FNewsPostTypeUnion } from 'utils/src/BaseTypes/news.types';
import { baseNewsCreateReducer } from './base.newsCreator';
import { NewsCreateReducer } from './types.newsCreator';

type BaseReducer = { newsCreate: NewsCreateReducer };

/**
 * get full news create reducer
 * @param state 
 * @returns 
 */
export const getNewsCreatorState = <S extends BaseReducer = BaseReducer> (state: S) => state.newsCreate || baseNewsCreateReducer;

type ReducerSelectorParameters = Parameters<typeof getNewsCreatorState>;

/**
 * get news create item
 * @param id 
 * @returns 
 */
export const getNewsCreateItem = (id: keyof NewsCreateReducer) => (...args: ReducerSelectorParameters) => getNewsCreatorState(...args)[id];

type NewsCreateItemSelectorParameters = Parameters<typeof getNewsCreateItemNews>;

/**
 * get news craet item selected fields
 * @param id 
 * @param fields 
 * @returns 
 */
export const getNewsCreateItemFields = <Fields extends (keyof ReturnType<ReturnType<typeof getNewsCreateItem>>)[]> (fields: Fields) => (...itemArgs: NewsCreateItemSelectorParameters) => (...args: ReducerSelectorParameters) => {
    const news = getNewsCreateItem(...itemArgs)(...args);
    return pick(news, fields) as Pick<typeof news, (typeof fields)[number]>;
};

/**
 * get full news in news create item
 * @param id 
 * @returns 
 */
export const getNewsCreateItemNews = (id: keyof NewsCreateReducer) => (...args: ReducerSelectorParameters) => getNewsCreateItem(id)(...args).news;

type NewsCreateItemNewsSelectorParameters = Parameters<typeof getNewsCreateItemNews>;

/**
 * get selected fields from news object in news create item
 * @param id 
 * @param fields 
 * @returns 
 */
export const getNewsCreateItemNewsFields = <Fields extends (keyof ReturnType<ReturnType<typeof getNewsCreateItemNews>>)[]> (fields: Fields) => (...itemArgs: NewsCreateItemNewsSelectorParameters) => (...args: ReducerSelectorParameters) => {
    let news = getNewsCreateItemNews(...itemArgs)(...args);
    return pick(news, fields) as Pick<typeof news, (typeof fields)[number]>;
};