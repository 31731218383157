// import React, { FC, useState } from 'react';
// import { Avatar } from 'uielements/src/Avatar/Avatar';
// import { UserMention } from 'uielements/src/UserMention/UserMention';
// import './AuthorItem.scss';
// import { IItemProps, cnAuthorItem } from './AuthorItem.index'

// export const AuthorItem: FC<any> = ({ className, author }) => {
//     const [zIndex, setZIndex] = useState(0)
//     const mouseEnther = () => setZIndex(1)
//     const mouseLeave = () => setZIndex(0)
//     return (
//         <div className={cnAuthorItem({}, [className])} style={{ zIndex }} onMouseEnter={mouseEnther} onMouseLeave={mouseLeave}>
//             <UserMention id={author.id} name={author.name}
//                 avatar={{ size: 56, imgId: author.imgId, imgUrl: author.userPhotoUrl || author.imgUrl, name: author.displayName || author.name }}
//                 className={cnAuthorItem('Avatar')} />
//         </div>
//     );
// };


export { AuthorItem } from 'uielements/src/WikiParts/WikiAuthorList/AutorItem/AuthorItem'