import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { updateUnit, saveLesson, setUnitError } from '../../../redux/actions/LMSTeacherOffice'
import { getErrors } from '../selectors'
///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    unit: any;
    onChange: (unit: any) => void
}

export interface IStateProps {
    errors: any
    maxFileSize?: number
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    errors: getErrors(state, props.unit.id),
    maxFileSize: state.store.appSettings.maxFileSizeLimit,
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    updateUnit,
    saveLesson,
    setUnitError: (errors: any) => setUnitError(ownProps.unit.id, errors),
}, dispatch);

////////////

export const cnClassName = cn('TOCEAnswerInput');