import { INewsReducer, INewsCreator, INewsBanners, IGroupState } from '../reducer/types'
import { pick, Many, PropertyPath, omit } from 'lodash'
import { IBaseNews, ICreateBaseNews } from '../../types/baseNews'
import queryString from 'query-string';
import { INewsListRequestOpts } from 'utils/src/requests/models/api.news'
/**
 * 
 * declarate store selectors
 */
export type stateType = { newsReducer: INewsReducer }//затычка

/**
 * получить корневое состояние новостей
 * @param state 
 */
export function getNews<T extends INewsReducer>(state: stateType) {
    return state.newsReducer as T;
}

/**
 * вернёт указаные параметры
 * @param id 
 * @param params 
 */
export function getNewsParams<T extends INewsReducer, U extends keyof T>(...params: Array<Many<U>>) {
    return (state: stateType) => {
        const news = getNews(state) as T
        return pick(news, ...params);
    }
}

export function getReadingState(params?: INewsListRequestOpts) {
    return (state: stateType): IGroupState => {
        const group = params ? queryString.stringify(omit(params, ['count'])) : 'all'
        const status = getNews(state).groupsStates//(["groupsStates"])(state)
        return status[group] || { isFinished: false, isLoading: false, count: 10 };
    }
}

/**
 * вернёт тпи представления ленты
 * @param state 
 */
export const getViewType = (state: stateType) => getNews(state).viewType

/**
 * 
 * @param state 
 */
export const getCurrentRendered = (state: stateType) => {
    const groupKey = getCurrentGroup(state)
    return getNews(state).virtualize[groupKey]?.currnetRendered || {}
}

/**
 * нужно ли рендерить новость с указаным ID
 * @param id 
 */
export const isNeedRendered = (id: string) => (state: stateType) => getCurrentRendered(state)[id]

/***
 * список размеров новостей
 */
export const getHeights = (state: stateType) => {
    const groupKey = getCurrentGroup(state)
    return getNews(state).virtualize[groupKey]?.heights || {}
}

/**
 * получить высоту для указаной новости
 * @param id 
 */
export const getNewsHeight = (id: string) => (state: stateType) => getHeights(state)[id]

/**
 * происходит ли отправка комментария
 * @param newsId 
 */
export const isSendingComment = (newsId: string) =>
    (state: stateType) => {
        return getNews(state).commentsSending.includes(newsId)
    }

/**
* происходит ли загрузка комментариев
* @param newsId 
*/
export const isLoadingComments = (newsId: string) =>
    (state: stateType) => {
        return getNews(state).commentsLoading.includes(newsId)
    }

/**
 * происходит ли чейчас отправка
 * @param state 
 */
export const isSending = (state: stateType) => getNews(state).sending
export const quantitySearchResult = (state: stateType) => getNews(state).quantity_search_result

/**
 * вернёт последнию заполненую группу
 * @param state 
 */
export const getCurrentGroup = (state: stateType) => getNews(state).currentGroup

/**
 * вернёт объект с параметрами длля формы публикации
 * @param state 
 */
export const getCreator = (state: stateType) => getNews(state).newsCreator

/**
 * указаные параметры создавалки новостей
 * @param params 
 */
export function getCreatorParams<N extends IBaseNews, T extends INewsCreator<N> = INewsCreator<N>, U extends keyof T = keyof T>(...params: Array<Many<U>>) {
    return (state: stateType) => {
        const data = getCreator(state) as T
        return pick(data, ...params);
    }
}

/**
 * 
 * @param params 
 */
export function getCreatorNewsParams<T extends IBaseNews = IBaseNews, U extends keyof T = keyof T>(...params: Array<Many<U>>) {
    return (state: stateType) => {
        const data = getCreator(state).currentNews as T & ICreateBaseNews
        return pick(data, ...params);
    }
}

/**
 * вернёт параметр требования очистки
 * @param state 
 */
export const getClearCreator = (state: stateType) =>
    getCreator(state).clearEditor

/**
 * вернёт объект ошибки отправки или undefined
 * @param state 
 */
export const getSendError = (state: stateType) =>
    getCreator(state).sendError

/**
* получить значения новостей
* @param state 
*/
export const getNewsValue = (state: stateType) =>
    getNews(state).values;

const emptyArray: any[] = []

/**
 * вернёт список ключей
 * @param group 
 */
export const getNewsKeys = (params?: INewsListRequestOpts) =>
    (state: stateType) => {
        const group = params ? queryString.stringify(omit(params, ['count'])) : 'all'
        return getNews(state).groupedKeys[group] || emptyArray
    }

/**
 * получает активный ид по парамертам запроса
 * @param params 
 */
export const getNewsActiveByParams = (params: INewsListRequestOpts) =>
    (state: stateType) => {
        const group = queryString.stringify(omit(params, ['count']))
        return getNews(state).activeStatuses[group]
    }

/**
 * вернёт ИД последней новости в ленте или undefined если лента пуста
 * @param state 
 */
export const getLastNewsId = (group: string = 'all') =>
    (state: stateType): string | undefined => {
        const keys = getNews(state).groupedKeys[group] || emptyArray
        return keys[keys.length - 1]
    }

/**
* получить значения комментариев
* @param state 
*/
export const getCommentsValue = (state: stateType) =>
    getNews(state).commentsValues;

/**
* получить список ид всех комментарием
* @param state 
*/
export const getCommentsKeys = (state: stateType) =>
    getNews(state).commentsKeys;


/**
 * вернёт объект с формати для новых комментариев
 * @param state 
 */
export const getReplyForms = (state: stateType) =>
    getNews(state).commentsForms


/**
 * вернёт форму нового комментария для указаной новости
 * @param id 
 */
export const getReplyFormById = (id: string) =>
    (state: stateType) =>
        getReplyForms(state)[id]

/**
 * получить новость по её ID
 * @param id 
 */
export function getNewsById<T extends IBaseNews = IBaseNews>(id: string) {
    return (state: stateType) =>
        getNewsValue(state)[id] as T
}

/**
 * вернёт указаные параметры новости
 * @param id 
 * @param params 
 */
export function getNewsParamsById<T extends IBaseNews, U extends keyof T>(id: string, ...params: Array<Many<U>>) {
    return (state: stateType) => {
        const news = getNewsValue(state)[id] as T
        return pick(news, ...params);
    }
}

/**
* получить комментарий по его ID
* @param id 
*/
export const getCommentById = (id: string) =>
    (state: stateType) =>
        getCommentsValue(state)[id]

/**
 * находится ли указаная новость в состояние редактирования
 * @param id 
 */
export const isEditState = (id: string) =>
    (state: stateType) =>
        getNews(state).edit.includes(id)


/**
 * вернет объект с ошибками
 * @param state 
 */
export const getUpdateErrors = (state: stateType) =>
    getNews(state).updateErrors


/**
 * вернет ошибку для публикации
 * @param id 
 */
export const getUpdateErrorById = (id: string) =>
    (state: stateType) =>
        getUpdateErrors(state)[id]



/**
 * get is readed state by id
 * @param id 
 */
export const getNewsIsReaded = (state: stateType) => getNews(state).readed;


/**
 * get id thank reply enabled by id
 * @param id 
 */
export const isThankReplyEnabled = (id: string) => (state: stateType) => getNews(state).commentsForms[id]?.thankReplyEnabled;

export const getWorkflowStatuses = (workflowId: string) => (state: stateType) => state.newsReducer.statuses[workflowId];

export const getNewsBannersStateByKey = (key: keyof INewsBanners) => (state: stateType) => getNews(state).banners[key];