import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';


let actions = {
  /** deprecated */
  JUMP_TO_UNIT: '',//не используется, ни чего не делает
  /** */

  READ_CATEGORIES: '',
  READ_COURSES: '',
  READ_SINGLE_COURSE: '',
  READ_UNITS_CONTENT: '',
  READ_SESSION_RESULT: '',
  SET_NEXT_UNIT: '',
  SKIP_TEST: '',
  SET_SAVE_PROCESSING_TRUE: '',
  SET_SAVE_PROCESSING_FALSE: '',
  SET_NEXT_QUESTION: '',
  // SET_QUESTION_COMPLETE: '',
  COMPLETE_TEST: '',
  START_QUESTION: '',
  USE_TEST_ATTEMPTS: '',
  CHANGE_TIME: '',
  // SKIP_QUESTION: '',
  JUMP_TO_QUESTION: '',
  SELECT_ANSWER: '',
  TOGGLE_SESSION: '',
  SET_UNIT_RESULT: '',
  REMOVE_UNIT_RESULT: '',
  SET_LIKE_COURSE: '',
  SET_SESSION_TIMEOUT: '',
  CANCLE_SESSION: '',
  SET_VIEW_SESSION_RESULT: '',
  SET_LOADING: '',
  SET_UNITS_LOGS: '',
  SET_SESSIONS_LOGS: '',
  UPDATE_UNIT_LOG: '',
  USE_ATTEMPT_AFTER_COMPLETE: '',
  SET_USER_SESSION_RESULT: '',
  REMOVE_SKELETS: '',// удаляет скилеты

  /** to saga */
  CLOSE_SESSION: '', // оправить комплит для указанной сессии
  TRANSITION_TO_UNIT: '',// переход к юниту
  TRANSITION_TO_NEXT_UNIT: '',// переход к следующему юниту
  CLOSE_SESSION_ON_COMPLETE_MONDATORY: '',// выйти из сессии без завершения дополнительных юнитов
  EXIT_FROM_SESSION: '', // обязательная часть завершена, отправить на просмотр результатов
  JOIN_TO_SESSION: '', // присоедениться к сессии

  LOAD_SINGLE_COURSE: '', // загружает один курс
  SAVE_UNIT_RESULT: '', // сохранить результат юнита

  LOAD_USER_RESULTS: '', // получить результаты текущего пользователя в сессии
  LOAD_COURSES_STATUS: '', // получить статистику обучения студента
  SCORM_UNIT_COMPLETE: '', // установить состояние isCompleted для scorm юнита
  SCORM_UNIT_SUCCESS_STATUS: '', // скорм юнит завершен успешно
  SAVE_SCORM_PARAM: '', // сохранить CMI параметры скорм юнита

  LOAD_UNIT_CONTENT: '', // загрузить контент юнита
  SAVE_UNIT_TEST_COMPLETED: '', //устанавливает тест как сохранёный

  SAVE_QUESTION_COMPLETE: '',// завершает вопрос, отправляя результат на сервер
  TRANSITION_TO_QUESTION: '', // переход к указаному вопросу
  CONFIRM_QUESTION: '', // завершить вопрос
  SKIP_QUESTION: '', // пропустить вопрос

  UPLOAD_ANSWER_FILE: '', // загрузить файл для ответа

  /** to reducer */
  START_LOAD_UNITS_LOGS: '', // индикатор, что загрузка логов юнитов началась
  COMPLETE_LOAD_UNITS_LOGS: '', // индикатор, что загрузка логов юнитов завершилась
  SET_CURRENT_UNIT: '',// устанавливает текущий юнит, ккоторый будет видеть пользователь.
  SET_COURSES_STATUS: '', // кинуть в стор статистику обучения студента
  SET_CURRENT_QUESTION: '', // меняет текущий вопрос в сторе
  SET_QUESTION_COMPLETE: '', // назначает вопрос завершенным
  SET_COMPLETED_TEST: '', // устанавливает юнит тест как выполненый
  SET_SKIP_QUESTION: '', // установить вопрос пропущеным
  SET_SESSION_COMPLETE_MONATORY: '', // устанавить сессии признак завершенной обязательной части

  ADD_ANSWER_FILE: '', //добавить файл к ответу

}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.LMS) }), {} as typeof actions);