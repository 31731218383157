import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { updateUnit, saveLesson, setUnitError } from '../../../redux/actions/LMSTeacherOffice'
import { getErrors } from '../selectors'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
///////////

export type IScormUnitPresenterProps = IScormUnitOwnProps & IScormUnitStateProps & IDispatchProps

export interface IScormUnitState { }

export interface IScormUnitOwnProps extends IClassNameProps {
    unit: any;
    onChange: (unit: any) => void
}

export type IScormUnitStateProps = ReturnType<typeof mapStateToProps>

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IScormUnitOwnProps) => ({
    errors: getErrors(state, props.unit.id),
    maxFileSize: state.store.appSettings.maxFileSizeLimit as number,
    currentUser: getCurrentUser(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IScormUnitOwnProps) => bindActionCreators({
    updateUnit,
    saveLesson,
    setUnitError: (errors: any) => setUnitError(ownProps.unit.id, errors),
}, dispatch);

////////////

export const cnClassName = cn('TOCEScormUnit');