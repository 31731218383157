import React, { FC, memo, useMemo } from 'react'
import {
    cnClassName,
    ITaskInfoProps
} from './interfaces'
import './style.scss'
import TestInfoLayout from '../TestInfoLayout'
import TestInfoList from '../../components/TestInfoList'

export const Presenter: FC<ITaskInfoProps> = ({
    className,
    children,
    unitContent, 
    start, 
    skip, 
    fullscreen
}) => {
    return <TestInfoLayout
        description={unitContent.description}
        name={unitContent.name}
        skip={skip}
        start={start}
        allowSkip={unitContent.allowSkip}
        fullscreen={fullscreen}
        unitType={unitContent.unitType}
    >
        <TestInfoList
            isStart={true}
            unitType={unitContent.unitType}
            unitSkipType={unitContent.skipUnitType}
            questionsCount={unitContent.questions.length}
        />
    </TestInfoLayout>
}

export default memo(Presenter);
