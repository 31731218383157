import React from 'react';
import './Widget_type_ratingsMui.scss';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { RatingsWidgetMui } from 'blocks/RatingsWidgetMui/RatingsWidgetMui';
import { IWidgetTypeRatingsProps } from './Widget_type_ratingsMui.index';
import { cnWidget } from '../Widget.index';

const types = {
  common: RatingsWidgetMui,
}

const type = 'ratingsMui';

export default class WidgetTypeRatings extends WidgetPresenter<IWidgetTypeRatingsProps> {

  public render() {
    if (!this.props.widget) return null;
    const Component = this.getComponent(types);
    return <Component {...this.props} 
      settings={this.props.widget.settings} 
      context={this.props.wcontext} 
      tag={widgets.components.common}
      className={cnWidget({ type })}
       />
  }
}
