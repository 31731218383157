import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';
import * as utilsDep from 'utils.project/utils.project';
import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';
import { v1 as uuid } from 'uuid';

import { cnImgLoader, IImgLoaderProps, IImgLoaderState } from './ImgLoader.index';

import './ImgLoader.scss';
import { Dropzone } from 'uielements/src/Dropzone/Dropzone';
import { Button } from 'uielements/src/Button/Button';
import { Preview } from './Preview/ImgLoader-Preview';
import { toast } from 'react-toastify';
import { withImgLoaderTypeProfileEdit } from './_type/ImgLoader_type_profileEdit';
import { Translate } from 'localizations/Translate';

const isIE = /*@cc_on!@*/false || !!(document as any).documentMode;
export class ImgLoaderPresenter extends React.Component<IImgLoaderProps, IImgLoaderState> {
  public el: HTMLElement | null;
  public dropzone: any;
  public progress: any;

  constructor(props: IImgLoaderProps) {
    super(props);
    this.functionBind = this.functionBind.bind(this);
    this.functionBind();
    this.state = {};
  }

  public render() {
    const customClassNames = '';
    const TagName = this.props.tag ? this.props.tag : 'div';
    // const {} = this.props;
    return (
      <TagName ref={el => this.el = el} className={cnImgLoader({}, (this.props.className ? this.props.className.split(' ') : []).concat(customClassNames.split(' ')))}>
        <Preview imgUrl={this.props.previewUrl} className={cnImgLoader('Preview')} />
        <div className={cnImgLoader('Info')}>
          {
            this.props.header && <div children={this.props.header} />
          }
          {
            this.props.text && <div children={this.props.text} />
          }
          <Button className={cnImgLoader('Button ')} onClick={this.openDropzone}>
            <Translate i18nKey={'download'} />
          </Button>
        </div>
        <Dropzone
          reference={this.addDZtoref}
          onDrop={this.fileDrop}
          hoverElem={this}
        />
        {
          this.state.file && <div ref={c => this.progress = c} id={this.state.file.progressId} className={cnImgLoader('Progress')} />
        }
        {this.props.children}
      </TagName>
    )
  }

  private functionBind() {
    this.addDZtoref = this.addDZtoref.bind(this);
    this.openDropzone = this.openDropzone.bind(this);
    this.fileDrop = this.fileDrop.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.onLoadEnd = this.onLoadEnd.bind(this);
  }

  private addDZtoref(el: any) {
    this.dropzone = el;
  }

  private openDropzone() {
    if (this.dropzone) this.dropzone.open();
  }

  private uploadFile = (f: any) => {
    f.id = uuid();
    f.progressId = 'progress-' + f.id;
    f.title = f.name;

    const loadStart = (event: any, file: any) => {
      file.downloadStatus = 'processing';
      if (this.progress && this.progress.classList.contains('success')) {
        this.progress.classList.remove('success')
      }
    }

    const loadProgress = (event: any, file: any) => {
      const width = Math.ceil((event.loaded * 100) / event.total);
      if (this.progress) this.progress.style.width = width + '%';
    }

    const loadFinish = (response: any, file: any) => {
      if (utils.checkResponseStatus(response)) {
        if (this.progress) this.progress.classList.add('success');
        if (this.props.onLoadEnd) this.props.onLoadEnd(response.data[0]);
      } else {
        toast.error('something wrong');
      }
    }

    utils.API.files.upload(f, { loadStart, loadProgress, loadFinish });
    this.setState({ file: f });
  }

  private fileDrop(files: any) {
    this.setState({ file: undefined });
    let f = files[0];
    if (isIE) { // не кропаем, если ишак
      this.uploadFile(f)
    } else {
      this.cropImage(f)
        .then((d: any) => {
          f = d.file;
          this.uploadFile(f)
        });
    }

  }

  private cropImage(file: any) {
    return new Promise((resolve: any, reject: any) => {
      const okBtnAction = (data: { [s: string]: any }) => {
        resolve({ file: data.cropedFile });
      }

      utilsDep.modal({
        type: 'imageCrop',
        header: i18n.t('pryaniky.dialog.cropImg.header'),//'crop me',
        disableCloseOnClickOutside: true,
        data: {
          file
        },
        okBtnAction
      });
    });
  }

  private onLoadEnd() {
    // 
  }

}


export const ImgLoader = compose(
  withImgLoaderTypeProfileEdit
)(ImgLoaderPresenter)
