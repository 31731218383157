import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMSTO
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { Button } from 'uielements/src';
import { IButtonProps } from 'uielements/src/Button/Button.index'
import withConfirmDialog, { IWithLoadSpin } from 'blocks/HOCs/Button/withConfirmDialog'
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import { UserMention } from 'uielements/src/UserMention/UserMention';

const ConfirmBtn = withConfirmDialog<IButtonProps>(Button)

type IPresenterProps = LMSTO.TOTracksListItem.IPresenterProps
type IOwnProps = LMSTO.TOTracksListItem.IOwnProps
type IDispatchProps = LMSTO.TOTracksListItem.IDispatchProps
type IStateProps = LMSTO.TOTracksListItem.IStateProps
type IState = LMSTO.TOTracksListItem.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    data
}) => {
    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('ImgBox')}>
            <UserMention avatar={{
                ...data.userModel,
                className: cnClassName('UserAvatar'),
                size: 56
            }} className={cnClassName('UserMention')} name={data.userModel.displayName} id={data.userModel.id} />
        </div>
        <div className={cnClassName('Info')}>
            <div className={cnClassName('Title')}>
                <div><UserMention className={cnClassName('Mention')} id={data.userModel.id} name={data.userModel.displayName} url={`/user/${data.userModel.id}`} /></div>
                <div>{data.userModel.position}</div>
                <div>{data.userModel.division}</div>
            </div>

            <div className={cnClassName('StatOne')}>
                
            </div>
        </div>

        <div className={cnClassName('ActionBox')}>
            <div className={cnClassName('Progress')}>{tTr('item.user.progress')} {data.completedSuccess}({data.completedPercentage})</div>
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
