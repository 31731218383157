import { IClassNameProps } from '@bem-react/core';
import { cnCountSlider } from '../CountSlider.index';
import { IDispatchProps } from 'redux/connector';

export interface ISliderProps extends IClassNameProps, IDispatchProps {
  tag?: 'div';
  dot?: any;
  minCount?: number;
  maxCount?: number;
  value: number;
  step: number;
  onSliderMove(position: number): void;
}

export interface ISliderState {
  text?: string;
  // position: number;
}

export const cnSlider = (...data: any) => {
  let name = 'Slider';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnCountSlider(name, ...data)
}