import Component, { Presenter } from './component';
import {
    Kanban,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = Kanban.KanbanTimeline.IOwnProps;
export type IComponentPresenterProps = Kanban.KanbanTimeline.IPresenterProps;


export default Component