import { ReactNode } from 'react';
import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
export interface IHeaderLayoutProps extends IClassNameProps {
    left?: ReactNode;
    right?: ReactNode;
    children?: ReactNode;
    bottom?: ReactNode;

    compact?: boolean

    type?: 'post' | 'comment';
}
export const cnHeaderLayout = cn('HeaderLayout');