import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
  SET_DATA: '',
  SET_CONTEXT: '',
  SET_WIDGETS: '',
  ADD_WIDGET: '',
  REMOVE_WIDGET: '',
  UPDATE_WIDGET: '',
  DRAG_WIDGET: '',
  ADD_TAB_TO_WIDGET: '',
  CHANGE_WIDGET_DATA: '',
  CHANGE_WIDGET_SETTINGS: '',
  SET_STRUCTURE: '',
  SAVE_STRUCTURE_FROM_WIDGET: '',
  UPDATE_CONTEXT: '',
  BACKUP_PAGE: '',
  UPDATE_CONTEXTS: '',
  UPDATE_STRUCTURE: '',
  CHANGE_WIDGETS_VIEW_TYPE: '',
  SET_DRAGGING_ELEM: '',
  TOGGLE: '',
  REMOVE_PROP_FROM_CONTEXT: '',
  SET_ACTIVE_PAGE: '',
  UPDATE_ACTIVE_PAGE: ''
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.WIDGETS) }), {} as typeof actions);