/**
 * @packageDocumentation
 * @module Widget_Settings_quests
 */
import { JFdictionary } from "../dictionary";
import i18n from "localizations/i18n";

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    folder: {
      type: types.object
    },
  }
}
