import React, { FC, useEffect, memo, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMS
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { ComponentInjector } from 'utils/src/ComponentInjector'
import { tS, Ts } from 'LMSModule/utils/i18n.adaptor'

type IPresenterProps = LMS.WidgetSettings.IPresenterProps
type IOwnProps = LMS.WidgetSettings.IOwnProps
type IDispatchProps = LMS.WidgetSettings.IDispatchProps
type IStateProps = LMS.WidgetSettings.IStateProps
type IState = LMS.WidgetSettings.IState


export const Presenter: FC<IPresenterProps> = ({
    children,
    onChange,
    selected
}) => {

    const elements = [
        {
            value: 'All',
            title: tS('filters.All.1'),
            id: 'All'
        }, {
            value: 'NotStarted',
            title: tS('filters.NotStarted.1'),
            id: 'NotStarted'
        }, {
            value: 'NotFinished',
            title: tS('filters.NotFinished.1'),
            id: 'NotFinished'
        }, {
            value: 'InProgress',
            title: tS('filters.InProgress.1'),
            id: 'InProgress'
        }, {
            value: 'Completed',
            title: tS('filters.Completed.1'),
            id: 'Completed'
        }
    ];

    const [state, setState] = useState<any>({
        status: 'NotStarted',
        isMandatory: true,
        Shuffle: true,
        count: 3,
        hideIfNoData: false
    })

    useEffect(() => {
        onChange({
            data: { status: state.status, isMandatory: state.isMandatory, Shuffle: state.Shuffle, count: state.count },
            settings: { hideIfNoData: state.hideIfNoData }
        })
    }, [state])
    const changeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
        setState({
            ...state,
            status: event.target.value as string
        })
    }

    const changeCount = (e: any) => {
        setState({
            ...state,
            count: e.target.value as number
        })
    }

    const changeMandatory = () => setState({ ...state, isMandatory: !state.isMandatory })
    const changeShuffle = () => setState({ ...state, Shuffle: !state.Shuffle })
    const changeHideIfNoData = () => setState({ ...state, hideIfNoData: !state.hideIfNoData })
    // count: 3, isMandatory: true, Shuffle: true, status: 'NotStarted'
    return <div className={cnClassName({}, [])}>

        <FormControl fullWidth >
            <TextField
                label={tS('widget.setting.count.1')}
                InputProps={{ inputProps: { min: 1 } }}
                type={'number'}
                value={state.count}
                onChange={changeCount}
            />
        </FormControl>

        <FormControl fullWidth>
            <InputLabel id={'status'}>{tS('widget.setting.status.1')}</InputLabel>
            <Select
                labelId={'status'}
                id={`status-select`}
                value={state.status}
                onChange={changeStatus}
            >
                {elements.map(item => <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)}
            </Select>
        </FormControl>

        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={state.isMandatory}
                        onChange={changeMandatory}
                        name="isMandatory"
                        color="primary"
                    />
                }
                label={tS('widget.setting.isMandatory.1')}
            />
        </div>

        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={state.Shuffle}
                        onChange={changeShuffle}
                        name="Shuffle"
                        color="primary"
                    />
                }
                label={tS('widget.setting.Shuffle.1')}
            />
        </div>
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={state.hideIfNoData}
                        onChange={changeHideIfNoData}
                        name="Shuffle"
                        color="primary"
                    />
                }
                label={tS('widget.setting.HideIfNoData')}
            />
        </div>
    </div>
}

export const Connected = connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));


export const initWidgetSettings = () => ComponentInjector.getInstance().addComponent('WAddition', Connected, 'pryaniky/coursesMy')
export default initWidgetSettings