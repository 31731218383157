import { toggle } from "redux/actions/Lists";
import { cn } from '@bem-react/classname';

export interface IQuestModerate {
  toggle: typeof toggle;
}

export interface IQuestModerateProps {
  text: string;
  id ?: string; // need to redux list indentifier;
}

export const cnList_type_quests_moderate = cn('List_type_quests_moderate');