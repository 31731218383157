import React, { FC } from 'react'
import { Skeleton } from 'uielements/src'
import './Unit.scss'
import { cn } from '@bem-react/classname';
export const cnFilter = cn('SkeletUnit');

export const Unit: FC<any> = ({ className }) => {
    return <div className={cnFilter({}, [className])}>

    </div>
}

