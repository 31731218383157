import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
    SET_WIKI_PAGE: 'SET_WIKI_PAGE',
    GET_WIKI_LIST: 'GET_WIKI_LIST',
    UPDATE_WIKI:'UPDATE_WIKI'
}


export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.WIKILIST) }), {} as typeof actions);