import React from 'react';
import { cn } from '@bem-react/classname';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import './Admin.scss';
import { tC } from 'LMSModule/utils/i18n.adaptor'

const cnCourseAdmin = cn('CourseAdmin');

interface IAdminProps {
  mod?: string;
  admin: any;//ICourseAdmin;
  className?: string
}

export const Admin: React.FC<IAdminProps> = ({ admin, className }) => {
  return (
    <div className={cnCourseAdmin({}, [className])}>
      <Avatar className={cnCourseAdmin('Avatar')} size={56} imgUrl={admin.userPhotoUrl} />
      <div className={cnCourseAdmin('Info')}>
        <UserMention className={cnCourseAdmin('Name')} id={admin.id} name={admin.displayName} />
        <p className={cnCourseAdmin('Description')}>{ tC('author.1') }</p>
      </div>
    </div>
  );
};
