import React, { FC, useState, useMemo, useRef } from 'react'
import { IUserThankFormProps } from './UserThankForm.index'
import './UserThankForm.scss'
import { Button, Input, Icon, Dialog, DialogTitle, CustomScrollbars, DialogContent, CheckboxInput } from 'uielements/src';
import { NewsEdit } from 'blocks/NewsEdit_new/NewsEdit'
import { defaultThanksNews } from 'blocks/NewsTypes/Thanks/Thanks.type'
import { v1 as uuid } from 'uuid';
import i18n from 'localizations/i18n';
import { connect } from 'react-redux';
import { IStateType as IState } from 'redux/store';
import { DialogContext, IDialogContext } from 'utils/src/DialogCreator'


export const UserThankFormPresenter: FC<IUserThankFormProps & any> = ({ user, users, currencyName, button }) => {
    const [showDialog, setShowDialog] = React.useState(false);
    const ref = useRef(null)
    const onCloseDialog = (e?: any) => {
        if (e) e.stopPropagation();
        setShowDialog(false)
    };
    const openDialog = (e?: any) => {
        if (e) e.stopPropagation();
        setShowDialog(true)
    };

    const context = useMemo<IDialogContext>(() => {
        return {
            isDialog: true,
            fullScreen: false,
            open: false,
            dialogProps: {},
            handleClose: () => { },
            handleAccept: () => { },
            ref,
            paperRef: ref
        }
    }, [])

    return (
        <>
            <DialogContext.Provider value={context}>
                {button ? button(openDialog, currencyName) :
                    <Button theme={'unstyled_center'} title={i18n.t('give currency', { currencyName })} onClick={openDialog}><Icon icon={'give'} /></Button>}
                <Dialog onClose={onCloseDialog} isShown={showDialog}>
                    <DialogTitle onClose={onCloseDialog}>
                        <span className={'ReasonThanksSelector-Title'}>{i18n.t('pryaniky.createPost.thanks.user.title')}</span>
                    </DialogTitle>
                    <NewsEdit
                        create
                        successMsg={i18n.t('pryaniky.createPost.thanks.user.success')}
                        failMsg={i18n.t('pryaniky.createPost.thanks.user.fail')}
                        cancleEdit={onCloseDialog}
                        compliteEdit={() => { onCloseDialog() }}
                        defaultData={{ ...defaultThanksNews, user, users, id: uuid() }}
                        type={'thankUser'} />

                </Dialog>
            </DialogContext.Provider>
        </>
    )
}

const mapStateToProps = (state: IState) => ({
    currencyName: state.store.appSettings && state.store.appSettings.currencyNameAccusativeSingular,
});

export const UserThankForm = connect(mapStateToProps)(UserThankFormPresenter);