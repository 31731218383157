import { ManifestType } from '../types'
import img from './exportusersTables.svg'

export const manifest: ManifestType = {
    id: 'ratings',
    name: 'ratings',
    title: 'Рейтинги',
    imgUrl: img,
    disabled: true
}

export default manifest