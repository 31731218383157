import { Paper, styled } from 'muicomponents/src';
import React, { FC } from 'react';
import NewsTimeline from 'News/containers/Timeline'
import { ILastUserPostWidgetProps } from './LastUserPostWidget.index';

const StyledPaper = styled(Paper)({
  // костыль на период, пока в дев не выкатился отредизайненый компонент ленты
  '.NewsFullView': {
    border: 'none',
  },
  '.NewsTimeline-Item': {
    marginBottom: '0',
  },
}) as typeof Paper


export const LastUserPostWidget: FC<ILastUserPostWidgetProps> = (props: ILastUserPostWidgetProps) => {

  const { context, settings, tag } = props
  const Tag = tag || 'div'

  return (
    <Tag {...props} >
      <StyledPaper>
        <NewsTimeline
          isSingle={true}
          params={{
            newsTypes: 'my',
            count: 1,
            userId: context.uId,
          }}
          settings={{ ...settings }}
        />
      </StyledPaper>
    </Tag>
  );
};

