import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ReactNode } from 'react';
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    items: ReactNode[]
}

export interface ITabsType {
    value: string,
    content: React.ReactNode
}

export const cnClassName = cn('LMSContextMenu');