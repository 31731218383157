import { IAnswerListProps } from './AnswerList.index'
import React, { memo } from 'react'
import './AnswerList.scss';
import i18n from '../../../../localizations/i18n';

export const AnswerList: React.FC<IAnswerListProps> = memo(({ answers, children, additional }) => {
    return <ul className='AnswerList'>
        {answers.map((answer) => children(answer))}
        {additional}
    </ul>
})