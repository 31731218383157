import { BadgesNewsCreate } from './Badges.type'
import { Rule, BaseValidator, validateArray, validateString, validateNumber } from '../Base.validate'
export const badgeValidate: Rule[] = [
    // {
    //     type: 'array',
    //     method: 'ARR_MIN',
    //     field: 'badge.users',
    //     value: 1,
    //     message: 'small users'
    // },
    // {
    //     type: 'array',
    //     method: 'ARR_MIN',
    //     field: 'badge.groups',
    //     value: 1,
    //     message: 'small groups'
    // },
    // {
    //     type: 'array',
    //     method: 'ARR_MIN',
    //     field: 'badge.users',
    //     value: 1,
    //     message: 'small users'
    // },
    /*{
        type: 'array',
        method: 'ARR_MAX',
        field: 'badge.users',
        value: 3,
        message: 'big users'
    },*/
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'text',
        value: 1,
        errorI18nKey: 'pryaniky.validate.news.badge.text.min',
        // message: 'small thanksCount'
    },
    /*{
        type: 'string',
        method: 'STR_MAX',
        field: 'text',
        value: 250,
        message: 'big thanksCount'
    },*/
    {
        type: 'number',
        method: 'NUM_MIN',
        field: 'badge.id',
        value: 0,
        errorI18nKey: 'pryaniky.validate.news.badge.id.min',
        // message: 'no select badge'
    },
]

export const badgeEditValidate: Rule[] = [
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'text',
        value: 1,
        errorI18nKey: 'pryaniky.validate.news.badge.text.min',
        // message: 'small text'
    },
    /*{
        type: 'string',
        method: 'STR_MAX',
        field: 'text',
        value: 250,
        message: 'big text'
    },*/
]




export class BadgeValidator extends BaseValidator<any> {
    protected otherValidate = (data: any, rule: Rule) => {
        switch (rule.field) {
            case 'badge.users':
                return validateArray(data.users, rule);
            case 'badge.groups':
                return validateArray(data.groups || [], rule);
            case 'text':
                return validateString(data.text, rule);
            case 'badge.id':
                return validateNumber(data.badge.id, rule);
        }
        return true;
    }
}
