import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';

export const cnLeftMenu = cn('LeftMenuMui');

export const MENU_HIDE_BREAKPOINT = 703;

export namespace NLeftMenu {

  export interface Props extends IClassNameProps {
    stayOpened?: boolean;
  }
}