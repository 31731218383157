import React, { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import MDRender from 'uielements/src/CommonmarkRender'
import { useDidUpdateEffect, useReffedState } from 'utils/src/hooks';
import { IconButton, TextField, Box } from 'muicomponents/src';
import { Check, Close, Edit } from 'muicomponents/src/Icons';
import { styled } from 'muicomponents/src/mui/system';

const StyledTextField = styled(TextField)({
    margin: '-16.5px -14px',
    [`.MuiInputBase-root`]: {
        boxSizing: 'content-box'
    }
}) as any as typeof TextField;

const StyledBox = styled(Box)({
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1
}) as typeof Box;

const CommonmarkMenu: FC<{}> = ({
    children
}) => {
    return (
        <StyledBox>
            {children}
        </StyledBox>
    )
};

export const Commonmark: FC<{
    data: string;
    saveEdited: (newData: string) => void;
}> = ({
    data,
    saveEdited
}) => {

    const [textState, setTextState, textStateRef] = useReffedState(data);

    useLayoutEffect(() => {
        if(data !== textStateRef.current) {
            setTextState(data);
        }
    }, [data]);

    const [edit, setEdit] = useState(false);

    const dataRef = useRef(data);
    dataRef.current = data;

    return (
        <>
            <CommonmarkMenu>
            {
                edit
                ? <>
                    <IconButton
                        size={'small'}
                        onClick={() => {
                            saveEdited(textState);
                            setEdit(!edit);
                        }}
                    >
                        <Check fontSize='small' color="primary" />
                    </IconButton>
                    <IconButton
                        size={'small'}
                        onClick={() => {
                            setTextState(data);
                            setEdit(false);
                        }}
                    >
                        <Close fontSize='small' color="error" />
                    </IconButton>
                </>
                : <IconButton
                    size={'small'}
                    onClick={() => {
                        setEdit(true);
                    }}
                >
                    <Edit fontSize='small' color='primary' />
                </IconButton>
            }
            </CommonmarkMenu>
            {
                edit
                ? <StyledTextField
                    value={textState}
                    onChange={(e) => setTextState(e.target.value)}
                    multiline
                    fullWidth
                />
                : <MDRender source={textState} />
            }
        </>
    );
};