import { IListProps } from '../../List.index';
import { IDispatchProps } from 'redux/connector';
import { filters } from 'utils/src/filters.prn';
import { cn } from '@bem-react/classname';

export interface IDefaultRequestOption {
  search: string;
}

export interface IListUsersDefaultRequestProps extends IDefaultRequestOption {
  filter: string;
  uid: string;
  extended: boolean;
  count: number;
  skipCount: number;
}


export interface IListTypeQuestsProps extends IListProps, IDispatchProps {
  requestOptions: any;
  tag?: 'div';
}


const fltrs = {
  ...filters,
  filter: 'filter',
}

export type IFLTRS = typeof fltrs & { [s: string]: string };

export const avalibleFiltersQuests = Object.keys(fltrs).reduce((acc, cur) => acc[cur] === '' ? { ...acc, [cur]: cur } : acc, fltrs as IFLTRS);

export const cnQuestsList = cn('QuestsList');
