import * as types from './actions.interfaces'
import { ISurveyQuestionsResults } from './interfaces'

import {
    CHANGE_ACTIVE_SURVEY_IDX,
    SET_ACTIVE_QUESTION,
    // NEXT_ACTIVE_QUESTION,
    RESET_SURVEY,
    SET_ANSWER_ID,
    SET_OTHER_ANSWER_ID,
    SET_FINISHED_SURVEY,
    SET_IS_ANSWERED,
    SET_IS_SHOW_RESULT,
    SET_LOADING_SURVEY,
    SET_OPEN_SURVEY,
    GET_OPEN_SURVEY,
    SET_RIGHT_ANSWER_COUNT,
    SET_STARTED_SURVEY,
    SET_SURVEY,
    GET_SURVEY,
    SET_USER_RESULT,
    SEND_ANSWER,
    IS_LOADING_SURVEYS,
    SURVEY_SET_SURVEYS_PASSING_ANSWERS_IDS,
    SURVEY_SET_SURVEYS_PASSING_RESULTS,
    SET_ROW_ANSWERS,
    SURVEY_START,
    SURVEY_CHANGE_STATE_BY_KEY,
    SURVEY_SET_STATE_BY_KEY,
    SURVEY_SET_ANSWER,
    SURVEY_SEND_ANSWERS
    // SET_ANSWER_TEXT_ID
} from './actions.types'


export const getMySurveysFromServer: types.CGetSurvey = (payload) => ({ type: GET_SURVEY, payload })

// start survey widget AC
export const setSurvey: types.CSetSurvey = (payload) => ({
    type: SET_SURVEY,
    payload
})
export const isLoadingSurveys: types.CIsLoadingSurveys = (payload) => ({
    type: IS_LOADING_SURVEYS,
    payload
})

export const changeActiveSurveyIdx: types.CChangeActiveSurveyIdx = (payload) => ({ type: CHANGE_ACTIVE_SURVEY_IDX, payload })
// end survey widget AC)

// start survey AC
export const setOpenSurvey: types.CSetOpenSurvey = (payload) => ({ type: SET_OPEN_SURVEY, payload })
export const getOpenSurvey: types.CGetOpenSurvey = (payload: types.PGetOpenSurvey) => ({ type: GET_OPEN_SURVEY, payload })


export const setStartedSurvey: types.CSetStartedSurvey = (payload) => ({ type: SET_STARTED_SURVEY, payload })

export const setActiveQuestion: types.CSetAciveQuestion = (payload) => ({ type: SET_ACTIVE_QUESTION, payload });

/**
 * @deprecated
 * @returns 
 */
// export const nextActiveQuestion: types.CNextActiveQuestion = () => ({ type: NEXT_ACTIVE_QUESTION })

export const setAnswerId: types.CSetAnswerId = (payload: types.PSetAnswerId) => ({ type: SET_ANSWER_ID, payload })

export const setOtherAnswerId: types.CSetOtherAnswerId = (payload: types.PSetOtherAnswerId) => ({ type: SET_OTHER_ANSWER_ID, payload })

export const setRowAnswers: types.CRowAnswers = (payload: types.PRowAnswers) => ({ type: SET_ROW_ANSWERS, payload })

// export const setAnswertextId: types.CSetAnswerTextId = (payload: types.PSetAnswerTextId) => ({ type: SET_ANSWER_TEXT_ID, payload })

export const setIsAnswered: types.CSetIsAnswered = (payload: boolean) => ({ type: SET_IS_ANSWERED, payload })


export const setFinishedSurvey: types.CSetFinishedSurvey = () => ({ type: SET_FINISHED_SURVEY })


export const resetSurvey: types.CResetSurvey = () => ({ type: RESET_SURVEY })

export const setLoadingSurvey: types.CSetLoadingSurvey = (payload: boolean) => ({ type: SET_LOADING_SURVEY, payload })

export const setUserResult: types.CSetUserResult = (payload: string) => ({ type: SET_USER_RESULT, payload })

export const setIsShowResult: types.CSetIsShowResult = (payload: boolean) => ({ type: SET_IS_SHOW_RESULT, payload })

export const sendAnswer: types.CSendAnswer = (payload: types.PSendAnswer) => ({ type: SEND_ANSWER, payload })

export const setSurveyQuestionsResults: types.CSetSurveyQuestionsResultsAction = (payload: ISurveyQuestionsResults) => ({
    type: SURVEY_SET_SURVEYS_PASSING_RESULTS,
    payload
})

export const setSurveyAnswersIds: types.CSetSurveysAnswersIdsAction = (payload: types.PSetSurveysAnswersIdsAction) => ({ type: SURVEY_SET_SURVEYS_PASSING_ANSWERS_IDS, payload })





// API v4 actions

export const startSurvey: types.CStartSurvey = (payload) => ({ type: SURVEY_START, payload });
export const setAnswer: types.CSetAnswerSurvey = (payload) => ({ type: SURVEY_SET_ANSWER, payload });
export const sendAnswers: types.CSendAnswersSurvey = (payload) => ({ type: SURVEY_SEND_ANSWERS, payload });

export const setSurveyStateFieldByKey: types.CSetSurveyStateByKey = (payload) => ({ type: SURVEY_SET_STATE_BY_KEY, payload });
export const changeSurveyStateFieldByKey: types.CChangeSurveyStateByKey = (payload) => ({ type: SURVEY_CHANGE_STATE_BY_KEY, payload });