import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { NBlocks } from '../Blocks.index';

export const cnBlocksSettings = cn('BlocksSettings');

export namespace NBlocksSettings {

    export interface Props extends IClassNameProps {
        data?: {
            items?: NBlocks.Item[];
        }
        onChange: (value: {[key: string]: any}) => void;
    }
}