import { cn } from '@bem-react/classname';
import { NLeftMenuReducer } from 'blocks/LeftMenuMui/redux/interfaces';
import { IBodyProps } from 'utils/src/DialogCreator';

export const cnLeftMenuItemChange = cn('LeftMenuItemMuiChange');

export namespace NLeftMenuItemChange {

    export interface Props extends IBodyProps<NLeftMenuReducer.Item> {
        type: 'add' | 'change';
        data: NLeftMenuReducer.Item;
      };
};