import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
import * as ACT from '../../../actions';
import * as SEL from '../../selectors'
import i18n from 'localizations/i18n';
import { toast } from 'react-toastify';
import { confirmProise } from 'uielements/src/Confirm/Confirm'
import { API } from 'utils/src/utils'

const handleContextActionDelete = function* handleContextActionDelete({ payload }: AT.AContextAction) {
    const { action, id } = payload
    if (action === 'delete') {
        try {
            const answer = yield* call(confirmProise, {
                text: i18n.t('pryaniky.post.actions.confirm.delete')
            })

            if (answer === 'cancle') {
                yield* put(ACT.contextActionCancle({
                    id,
                    action
                }))
                return;
            }

            yield* put(ACT.deleteNews({ id }))
        } catch (error) {
            console.warn(error)
        }
    }
    yield;

    return 0;
}

export default handleContextActionDelete