// import { ChatMicroClient } from './mc.client'
import { changeStatus, updateUsers, changeUrl, updateListAndCount, changeUid, updateConfig } from '../../redux/actions/Chat'
import { store } from '../../redux/store'
import * as utils from 'utils/src/utils'
import { toast } from 'react-toastify';
import { ChatToast } from '../../blocks/Chat/ChatToast/ChatToast'
import * as React from 'react';

export class ChatService {

    public static init(): ChatService {
        if (!ChatService.singleton) {
            ChatService.singleton = new ChatService();
        }
        return ChatService.singleton;
    }

    private static singleton: ChatService | null;

    private config = {
        domain: 'chatUrl',                // домен чата
        useSSL: true,
        token: 'authData.token',        // токен авторизации
        useLocalStorage: true,        // использовать или нет локальное хранилище
        debugLevel: 0                 // 0 - дебаг выключен, 1 - дебаг включен
    };

    private chatSettings = {
        userId: '',
        chatUrl: '',
        useTLS: false,
        chatDomain: '',
        token: ''
    }

    private unsubscribe: any;

    private MicroChat: any | null;

    private channelsList: any[] = [];

    private status = 'offline'

    private chatNewChannelCount: number = 0;

    private unreadMessages: number = 0;

    private users: any = {};

    private constructor() {
        this.unsubscribe = store.subscribe(this.storeListener)
    }

    private storeListener = () => {
        const { chatSettings } = store.getState().store;
        if (!chatSettings) return;
        if (this.MicroChat) return;

        this.unsubscribe();


        this.chatSettings = chatSettings;

        store.dispatch(changeUrl(this.chatSettings.chatUrl));
        store.dispatch(changeUid(this.chatSettings.userId))

        utils.API.chat.getToken({}).r.then((d: any) => {
            if(d) {
            this.initMC(d.data)
            } else console.log('error: API.chat.getToken, no data, ', d)
        }).catch((r) => {
            console.error('error: API.chat.getToken - ', r)
        })
        
    }

    private initMC = (token: string) => {
        this.config.domain = this.chatSettings.chatDomain;
        this.config.useSSL = this.chatSettings.useTLS;
        this.config.token = token;//'VEYoDPr16RBITJvCad_FHZEN2R2wMOVSDL3yX544J2P';
        //this.config.token = 'cEH9t8vJb2CVeHjhG1T1kkooU-m7r5qFZq34OTL4DR9'//token;

        store.dispatch(updateConfig(this.config));

        const ChatMicroClient = require('./mc.client').ChatMicroClient;
        
        this.MicroChat = new ChatMicroClient(this.config);

        this.MicroChat.onStatusChanged = (status: string) => {
            this.status = status;
            store.dispatch(changeStatus(status))
        };

        this.MicroChat.onChannelsUpdated = (channelsList: any[], chatNewChannelCount: number, unreadMessages: number) => {
 
            this.chatNewChannelCount = chatNewChannelCount;
            this.channelsList = channelsList;
            this.unreadMessages = unreadMessages;
            store.dispatch(updateListAndCount({ chatNewChannelCount, channelsList }))
        };

        this.MicroChat.onNewMessage = (message: any) => {
            const channel = this.getChannel(message.payload.rid)
            // if (!channel) return;

            toast.info(<ChatToast msg={message.text} title={message.title} avatar={this.chatSettings.chatUrl + channel.avatar} />)
        }

        this.MicroChat.onUsersUpdated = (users: any) => {
            this.users = users;
            store.dispatch(updateUsers(users))
        }

        this.setCookie();

        this.MicroChat.run();

        this.muteUnread = this.MicroChat.muteUnread.bind(this.MicroChat);
    }

    private getChannel = (id: string) => {
        return this.channelsList.find((val: any) => val.id === id)
    }

    public getStatus = () => this.status;

    public setStatusOnline = () => this.MicroChat.setStatus('online');
    public setStatusOffline = () => this.MicroChat.setStatus('offline');
    public setStatusAway = () => this.MicroChat.setStatus('away');
    public setStatusBusy = () => this.MicroChat.setStatus('busy');

    public logOut = () => {
        utils.setCookie({ name: 'rc_token', value: '', props: { expires: 'Thu, 01 Jan 1970 00:00:00 UTC' } });
        utils.setCookie({ name: 'rc_uid', value: '', props: { expires: 'Thu, 01 Jan 1970 00:00:00 UTC' } });
        // document.cookie = "rc_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;samesite=None; secure";
        // document.cookie = "rc_uid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;samesite=None; secure";
        this.MicroChat = null;
        ChatService.singleton = null;
    }

    public muteUnread = () => { }

    private setCookie = () => {
        utils.setCookie({ name: 'rc_token', value: this.chatSettings.token, props: { domain: this.chatSettings.chatUrl } });
        utils.setCookie({ name: 'rc_uid', value: this.chatSettings.userId, props: { domain: this.chatSettings.chatUrl } });
        // document.cookie = "rc_token=" + this.chatSettings.token + ";domain=" + this.chatSettings.chatUrl + ";path=/;samesite=None; secure";
        // document.cookie = "rc_uid=" + this.chatSettings.userId + ";domain=" + this.chatSettings.chatUrl + ";path=/;samesite=None; secure";
    }

}
//  document.cookie = "rc_token=VEYoDPr16RBITJvCad_FHZEN2R2wMOVSDL3yX544J2P;domain=https://dreamteam.chat.pryaniky.com/;path=/";
// 