import React, { FC } from 'react'
import { cnClassName, ITrackStatWidgetProps } from './TrackStatWidget.index'
import './TrackStatWidget.scss'
import { getTrackStatistic } from 'utils/src/requests/requests.learningTracks'
import { useQuery } from 'utils/src/hooks'
import { useCurrentUserBase } from 'utils/src/CommonRedux/users/hooks'
import PieChart, { LabelProps, PieChartData } from 'react-minimal-pie-chart';
import { tTr } from 'LMSModule/utils/i18n.adaptor'

const colors: {[key: string]: string} = {
    'News': '#63A6FD',
    'PagesV2': '#F0C419',
    'Quizzes': '#89CA5E',
    'LMSCources': '#F97091',
}

export const TrackStatWidget: FC<ITrackStatWidgetProps> = ({ className, tagId }) => {
    const user = useCurrentUserBase()
    const [isLoading, result] = useQuery(() => getTrackStatistic(user.id, tagId, 10, 0), [user.id, tagId])

    return <div className={cnClassName({}, [className])}>
        {result?.data?.materials && result.data.materials.map((value: any) => {

            if (value.total === 0) return null;

            const data = [
                {
                    color: colors[value.type],
                    value: value.completedPercentage
                },
                {
                    color: '#D3D9E0',
                    value: 100 - value.completedPercentage
                },
            ]
            return <div className={cnClassName('Item')}>
                <div className={cnClassName('Title')}>{tTr('files_list.stats.' + value.type)}</div>
                <PieChart
                    className={cnClassName('Chart')}
                    data={data}
                    lineWidth={43}
                    labelPosition={0}

                    label={(val: LabelProps) => `${value.completedSuccess} / ${value.total}`}
                    labelStyle={{
                        fontSize: '16px',
                        // fontFamily: 'sans-serif',
                        fill: '#000',
                    }}
                />
            </div>
        })}
    </div>
}