import React, { createRef, FC, useState } from 'react';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { DialogTitle, DialogContent, DialogActions } from 'muicomponents/src/DialogParts';
import { FilesAddProps } from './FilesAdd.index';
import { useFileUploader } from 'utils/src/useFileUploader';
import { FilesList } from 'muicomponents/src/FilesList/FilesList';
import { UploadButton } from 'muicomponents/src/UploadButton/UploadButton';
import { Translate } from 'localizations/Translate';

const FilesAddPr: FC<FilesAddProps> = ({
    fileId,
    multiple,
    handleAccept,
    handleClose
}) => {

    const [loading, setLoading] = useState(false);

    const {
        files,
        onFileChange,
        removeFile,
        startUpload
    } = useFileUploader({
        useV4: true,
        fileId
    });

    const prepairedFiles = !multiple ? files.slice(0, 1) : files;

    return (
        <>
            <DialogTitle
                onClose={handleClose}
            >
                <Translate i18nKey={`pryaniky.file.upload.title${multiple ? '.multiple' : ''}`} />
            </DialogTitle>
            <DialogContent>
                <FilesList removeFile={removeFile} files={prepairedFiles} showProgressOnNewFiles={true} />
                <UploadButton onChangeInput={onFileChange} multiple={multiple}>
                    <Translate i18nKey={'pryaniky.file.upload.select'} />
                </UploadButton>
            </DialogContent>
            <DialogActions
                isLoading={loading}
                disableAccept={prepairedFiles.length === 0}
                acceptText={<Translate i18nKey={'pryaniky.file.upload.upload'} />}
                onAccept={() => {
                    setLoading(true);
                    startUpload(prepairedFiles).then(d => {
                        setLoading(true);
                        if (d)
                            handleAccept({ files: d.map(el => el.response?.data[0]).filter(el => el) });
                    });
                }}
            />
        </>
    )
};

export const FilesAdd = createModal(FilesAddPr, {
    maxWidth: 'md',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: 'transparent',
        }
    },
    TransitionComponent: TransitionComponent,//LeftDirectionTransitionComponent,
    scroll: 'body'
});