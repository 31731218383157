import { INewsPropsType, INewsStateType, mapStateToProps, mapDispatchToProps, TStateProps, TDispatchProps } from './Repost.index'
import * as React from 'react';
import { connect } from 'react-redux';
import './Repost.scss'
import { withNewsContext } from 'News/contexts/news'
import TextRender from 'News/containers/TextRender'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import ContentLayout from 'News/components/ContentLayout'
import NewsItem from 'News/containers/NewsItem'
import BaseView from 'News/containers/BaseView'

export class RepostPresenter extends React.Component<INewsPropsType & TStateProps & TDispatchProps, INewsStateType>  {
    componentDidMount() {
        if (!this.props.repost) this.props.loadNewsById({ id: this.props.parentGuid || '', network: this.props.parentNews?.appid })
    }

    public render() {
        const { text, parentGuid, isSingle } = this.props;
        return (<NewsView className={'type-31'}>
            <ContentLayout>
                <TextRender />
            </ContentLayout>
            <div className={'Repost-RepostedNews'}>
                {this.props.repost && <NewsItem id={parentGuid || ''} ViewComponent={BaseView} repost />}
            </div>
        </NewsView>
        )
    }
}

export const Repost = withNewsContext(connect(
    mapStateToProps,
    mapDispatchToProps
)(RepostPresenter));

export const initRepost = () => ComponentInjector.getInstance().addNode('news_types', <Repost />, 'repost')