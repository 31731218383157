import React, { FC, useEffect, memo, useRef } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import FullView from '../FullView'
import { withNewsContext } from '../../contexts/news'
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import { elemInViewport } from 'utils/src/utils';
import { useDebounce } from 'utils/src/hooks';

type IPresenterProps = News.NewsView.IPresenterProps
type IOwnProps = News.NewsView.IOwnProps
type IDispatchProps = News.NewsView.IDispatchProps
type IStateProps = News.NewsView.IStateProps
type IState = News.NewsView.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    ViewComponent,
    newsId,
    height,
    setHeight,
    isSingle,
    setRendered,
    needRendered,
    virtualize,
    readed,
    setNewsIsReaded,
    viewClassName,
    ViewRender,
    viewRenderProps
}) => {
    const id = newsId;

    const triggerRef = useRef<HTMLDivElement>(null);

    const heightRef = useRef(height)
    heightRef.current = height
    if (!isSingle && virtualize) {
        useEffect(() => {
            // запоминаем размер новости
            setHeight({
                id,
                height: (triggerRef.current?.clientHeight || 0)
            })

            // рендереть ли текущию новость
            const callback: IntersectionObserverCallback = entries => {
                entries.forEach(entry => {
                    setRendered({
                        id,
                        value: entry.isIntersecting
                    })
                });
            };

            const observer = new IntersectionObserver(callback, {
                rootMargin: (window.innerHeight * 2) + 'px',
                threshold: 0,
            });

            if (triggerRef.current) observer.observe(triggerRef.current);

            // typescript не знает про ResizeObserver...
            // если размер новости поменялся, то изменяем его в стейте
            const resizeObserver = new (window as any).ResizeObserver((entries: any) => {
                for (let entry of entries) {
                    const newHeight = entry.contentRect.height
                    if (newHeight !== heightRef.current) {
                        setHeight({
                            id,
                            height: (triggerRef.current?.clientHeight || 0)
                        })
                    }
                }
            });

            resizeObserver.observe(triggerRef.current);

            return () => {
                observer.disconnect();
                resizeObserver.unobserve(triggerRef.current);
            }
        }, []);
    }

    const View = ViewRender || ViewComponent || FullView

    let show = needRendered
    show = Boolean(height) ? show : true
    show = isSingle ? true : show
    show = virtualize ? show : true

    const style: React.CSSProperties = {}
    style.height = height && !show ? (height) : undefined

    const setReaded = useDebounce(() => triggerRef.current && !readed && elemInViewport(triggerRef.current) && setNewsIsReaded(id), 500, [readed]);

    useEffect(() => {
        setReaded();
    }, []); 

    useEffect(() => {
        document.addEventListener('scroll', setReaded);
        return () => {
            document.removeEventListener('scroll', setReaded);
        };
    }, [readed]);

    return <div ref={triggerRef} id={id} style={style} className={cnClassName({}, [className, viewClassName])}>
        {show && <View {...viewRenderProps}>
            {children}
        </View>}
    </div>
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withComponentEnjector<IPresenterProps>(memo(Presenter), ['news_view'])));

