import { Action } from 'redux';
import { PageModelList, TPage } from 'utils/src';

export const analyticWikiPageViewType = 'ANALYTIC_WIKI_PAGE_VIEW';
export type AnalyticWikiPageViewAction = Action<typeof analyticWikiPageViewType> & {
  payload: TPage | PageModelList
}

export const analyticWikiPageView = (payload: AnalyticWikiPageViewAction['payload']): AnalyticWikiPageViewAction => {
  return {
    type: analyticWikiPageViewType,
    payload
  }
}