import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { mapDispatchToProps, IDispatchProps } from 'redux/connector'
import { Page } from 'blocks/Page/Page'
import i18n from 'localizations/i18n'
import { setContext } from 'redux/actions/Widgets'
import { pageNames } from 'redux/pageNames'

interface ICreativeTasksProps extends IActionsDispatch, IDispatchProps {}
interface IActionsDispatch {
  setContext: any
}

export const CreativeTasksPresenter: React.FC<ICreativeTasksProps> = props => {

  return <Page page={{ name: pageNames.creativetasks, title: i18n.t('creativetasks'), }} />
}

export const CreativeTasks = connect(
  null,
  mapDispatchToProps({ setContext })
)(CreativeTasksPresenter)

