import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import { 
    loadUserSkills, 
    createUserSkill,
    confirmSkill,
    deleteSkill
} from 'redux/actions/Skills'
import { 
    getSkillsForUser,
    getSkills 
} from 'redux/sagas/Skills/selectors'
import { ISkillCollection } from 'redux/sagas/Skills/types'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions'

///////////

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace SkillsWidgetNew {
    // eslint-disable-next-line @typescript-eslint/no-namespace

    export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

    export interface IState { }

    export interface IOwnProps extends IClassNameProps {
        userid: string
    }

    export interface IStateProps {
        skills: ISkillCollection
        currentUser: any
        isLoading: boolean
        isFinished: boolean
      
    }

    export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

}

////////////

type IOwnProps = SkillsWidgetNew.IOwnProps

export const mapStateToProps = (state: any, { userid }: IOwnProps) => ({
    skills: getSkillsForUser(userid)(state),
    isLoading: getSkills(state).isLoading,
    isFinished: getSkills(state).isFinished,
    currentUser: getAuthUser(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadUserSkills,
    createUserSkill,
    confirmSkill,
    deleteSkill
}, dispatch);

////////////

export const cnClassName = cn('SkillsMui');