import React, { useState, FC } from 'react'
import { CalendarEventLayout } from 'muicomponents/src/CalendarEventLayout/CalendarEventLayout'

export const LmsEvent: FC<any> = ({ event }) => {


    return <CalendarEventLayout
        headerText={event.title}
        users={event.users}
        statusText={event.myStatus}
    />
}