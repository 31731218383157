import { ReactNode } from 'react';
import { NewsFeedback } from './Feedback.type'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { NewsContextProps } from 'blocks/NewsView/NewsView.context'

export interface IFeedbackPropsType extends ReturnType<typeof mapStateToProps>, NewsContextProps {
    data: NewsFeedback;
    presentRender: (child: ReactNode) => ReactNode;
}

export interface IFeedbackStateType {
    // data: NewsNews;
}

export const mapStateToProps = (state: any) => ({
    userData: getCurrentUser(state),
})
