import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
export interface IItemProps extends IClassNameProps {
    className?: string;
    text: string;
    questionTimer: any;
    resultAnswersMap: { [key: string]: any };
    resultAnswers: string[];
    answers: string[];
    answersMap: { [key: string]: any };
    qtype: number;
    isNeedModeration: boolean;
}

export interface IItemState {

}

export const cnItem = cn('ResultTestItem');