import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ITagsCloudData } from 'utils/src/requests/models/api.tagscloud';
import { IStateType } from 'redux/store';

export interface ITagsCloudProps extends IClassNameProps, ITagsCloudOwnProps, IMapStateToProps {}

export interface ITagsCloudOwnProps {
  tag?: React.ComponentType | 'div';
  context: any;
  id: string;
  data: ITagsCloudData;
  edit: boolean;
}

export const mapStateToProps = (state: IStateType) => {
  // const { primaryColor1, primaryColor2, primaryColor3, additionalColor1, additionalColor2 } = state.store.appSettings?.colors || {};
  return {
    styleSettings: state.store.appSettings?.colors || {},
    // corpColors: [primaryColor1, primaryColor2, primaryColor3, additionalColor1, additionalColor2],
  }
}

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

export const cnTagsCloud = cn('TagsCloud');