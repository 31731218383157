import utils from './video/utils';

export const matchesEntityType = (type: any) => type === 'LINK';

export default function strategy(contentBlock: any, cb: any, contentState: any) {
    const { isYoutube, isVimeo } = utils;
    if (!contentState) return;
    contentBlock.findEntityRanges((character: any) => {
        const entityKey = character.getEntity();
        if (entityKey !== null &&
            matchesEntityType(contentState.getEntity(entityKey).getType())) {
            const entityData = contentState.getEntity(entityKey).getData()
            if (isYoutube(entityData.href)) return true
            if (isYoutube(entityData.url)) return true
            if (isVimeo(entityData.href)) return true
            if (isVimeo(entityData.url)) return true
            else return false
        }
    }, cb);
}