import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ComponentType } from 'react'
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
pathCreator
} from '../../redux/actions'
import {
} from '../../redux/reducer/types'
import {
    getCreatorParams
} from '../../redux/saga/selectors'
import { IBaseNews } from '../../types/baseNews'
import { IComponentAcceptor } from 'utils/src/ComponentInjector'
import { loadAllowPostTypes } from 'redux/actions/AppSettings'
import { 
    getCurrentAllowPostTypes,
} from 'redux/sagas/AppSettings/selectors'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsCreator {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & IComponentAcceptor

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            groupId?: number;
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.NewsCreator.IOwnProps



export const mapStateToProps = (state: any, { groupId }: IOwnProps) => ({
    postTypes: getCurrentAllowPostTypes(groupId)(state),
    ...getCreatorParams('currentType')(state)
})


export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadAllowPostTypes,
    pathCreator
}, dispatch);

////////////

export const cnClassName = cn('NewsNewsItem');