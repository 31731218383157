import React, { FC, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import i18n from 'localizations/i18n';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
import './Test.scss';
import { ITestProps, mapDispatchToProps, mapStateToProps } from './Test.index'
import { connect } from 'react-redux';
import { Timer } from '../../Timer/Timer'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip'
import { PostContentRender } from 'blocks/PostContentRender/PostContentRender'
import { withLoad } from '../../ButtonWithLoad/ButtonWithLoad'
import QuestionRender from '../../../components/Question'
import { tS } from 'LMSModule/utils/i18n.adaptor'
import { AttachmentUploader } from 'uielements/src/AttachmentUploader/AttachmentUploader'
import { Attachments } from 'uielements/src/Attachments/Attachments'
import MDRender from 'uielements/src/CommonmarkRender'


const ButtonL = withLoad(Button)

const cnCourseTest = cn('CourseTest');

export const TestPresenter: FC<ITestProps & any> = ({
  confirmQuestion,
  saveQuestionComplete,
  transitionToQuestion,
  skipQuestion,
  fullscreen,
  // sendSkipQuestion,
  unit,
  // comlteteQuestion,
  // sendQuestionComplete,
  question,
  unitContent,
  // sendNextQuestion,
  selectAnswer,
  questionCount,
  completedQuestionCount,
  uploadAnswerFile
}) => {
  const [highlight, setHighlight] = useState(false)

  if (!question) return <div>no</div>

  const selectedAnswers = question.selectedAnswers ? question.selectedAnswers : [];
  const currentIndex = unitContent.questions.indexOf(question.id)

  const handleAnswerSelect = (id: string) => (value: string = '') => !highlight && selectAnswer({ uid: unit.id, aid: id, value })

  const handleUploadFile = (id: string) => (file: any) => uploadAnswerFile({ uid: unit.id, aid: id, file })

  const onSkipQuestion = () => {
    setHighlight(false);
    skipQuestion(unit.id, question.id)
  }
  const onNextQuestion = () => {
    setHighlight(false);
    // transitionToQuestion(unit.id)
    confirmQuestion(question.id, unit.id)
    // saveQuestionComplete(question.id, unit.id)
    // sendNextQuestion(question, unit.id, !unitContent.highlightCorrectness)
  }

  const questionComplite = () => {
    setHighlight(true);
    confirmQuestion(question.id, unit.id)
    // sendQuestionComplete(question.id, unit.id)
  }

  let allowSkip = (questionCount - completedQuestionCount) > 1;
  let helpText = tS('helpText.1')
  helpText = question.qtype === 2 ? tS('helpText.2') : helpText

  let nextOrComplete = unitContent.highlightCorrectness
  nextOrComplete = nextOrComplete ? (unitContent.questions.length > 1 && (questionCount - completedQuestionCount) > 0) : nextOrComplete
  nextOrComplete = !unitContent.highlightCorrectness ? (unitContent.questions.length > 1 && (questionCount - completedQuestionCount) > 1) : nextOrComplete;

  // question.answers.
  return (
    <div className={cnCourseTest({ fullscreen })}>
      <div className={cnCourseTest('Timer')}>
      </div>

      <QuestionRender
        className={cnCourseTest('Header')}
        content={question.text}
        header={<div className={cnCourseTest('QuestionTitle')}>
          <div className={cnCourseTest('QuestionCount')}>
            {tS('questionNumber.1', { current: (currentIndex + 1), all: unitContent.questions.length })}
          </div>
          <div className={cnCourseTest('QuestionHeader')}>{question.header}</div>
        </div>}
      />

      <div className={cnCourseTest('Answers')}>

        {question.qtype !== 2 && <h4 className={cnCourseTest('Choose')}>
          {tS('variants.1')}
        </h4>}

        {question.answers.map((answerId: any) => (<>
          <AnswerItem
            allowAttach={question.allowAttach}
            uploadAnswerFile={handleUploadFile(answerId)}
            type={question.qtype}
            key={answerId}
            highlight={highlight}
            answer={question.normalAnswers[answerId]}
            selected={selectedAnswers.includes(answerId)}
            onSelect={handleAnswerSelect(answerId)}
            name={question.id}
          />
        </>))}

      </div>
      <div className={cnCourseTest('Controls')}>
        {allowSkip ? <ButtonL main className={cnCourseTest('Control', { prev: true })} onClick={onSkipQuestion} disabled={highlight}>
          {tS('skip.1')}
        </ButtonL> :
          <div className={cnCourseTest('Spacer')}></div>
        }

        <HelpTooltip help={selectedAnswers.length === 0 && <div className={cnCourseTest('TooltipContent')}>{helpText}</div>} showDelay={1500} hideDelay={300}>
          {unitContent.highlightCorrectness && !highlight
            ? <ButtonL disabled={selectedAnswers.length === 0} className={cnCourseTest('Control', { submit: true })} main onClick={questionComplite}>
              {tS('select.1')}
            </ButtonL>
            : <ButtonL disabled={selectedAnswers.length === 0} main className={cnCourseTest('Control', { next: true })} onClick={onNextQuestion}>
              {nextOrComplete ? tS('next.2') : tS('complete.3')}
            </ButtonL>
          }
        </HelpTooltip>
      </div>
    </div>
  );
};

export const Test = connect(
  mapStateToProps,
  mapDispatchToProps
)(TestPresenter);

interface IAnswer {
  answer: any;
  name: string;
  selected: boolean;
  highlight: boolean;
  type: number;
  onSelect(value?: string): void;
  uploadAnswerFile: any;
  allowAttach: boolean;
}

const AnswerItem = ({ answer, onSelect, name, selected, highlight, type, uploadAnswerFile, allowAttach }: IAnswer) => {
  const [uploaderReference, setUploaderReference] = useState<any>(null)

  const sur = (ref: any) => !uploaderReference && setUploaderReference(ref)

  // const highlightColor = highlight ? (answer.isRightAnswer ? 'right' : (selected ? 'wrong' : undefined)) : undefined;
  const wrongAnswer = (selected ? 'wrong' : undefined)
  const notSelectedRightAnswer = (answer.isRightAnswer && !selected ? 'achtung' : undefined)
  const highlightColor = highlight ? (answer.isRightAnswer && selected ? 'right' : notSelectedRightAnswer || wrongAnswer) : undefined;
  if (type === 2) return (
    <div className={cnCourseTest('TextareaBox')}>
      <textarea
        placeholder={tS('placeholder.answer.1')}
        value={answer.value}
        className={cnCourseTest('Textarea')}
        onChange={(e: any) => onSelect(e.target.value)} />

      {allowAttach && <div className={cnCourseTest('FileSection')}>
        <Attachments className={cnCourseTest('Attachment')} files={answer.files || []} onDelete={() => { }} />
        <AttachmentUploader
          elem={null}
          onError={(e: string) => { }}
          attachments={answer.files || []}
          reference={sur}
          onChange={uploadAnswerFile} />
        <Button main className={cnCourseTest('UploadBtn')} onClick={() => uploaderReference && uploaderReference.open()}>{tS('button.upload.1')}</Button>
      </div>}

    </div>
  )
  let checkIcon = 'check-circle'
  let freeIcon = 'circle'
  if (type === 1) {
    checkIcon = 'check-square'
    freeIcon = 'square'
  }
  return (
    <label className={cnCourseTest('Label', { highlight: highlightColor })}>
      <input
        className={cnCourseTest('Input')}
        type="checkbox"
        name={name}
        onChange={() => onSelect()}
        value={answer.id}
        checked={selected}
      />
      <Icon icon={selected ? checkIcon : freeIcon} className={cnCourseTest('Checkbox', { selected })} />
      <div className={cnCourseTest('AnswerText')}><MDRender source={answer.text} textCut={false} /></div>
    </label>
  )
};
