import { connect, useSelector } from 'react-redux';
// import { compose } from '@bem-react/core';
import * as utils from 'utils/src/utils';
import { IDispatchProps } from "../../../redux/connector";
// import { AbstractInfoItem } from '../AbstarctInfoElement/AbstractInfoElement';
import { mapStateToProps, IUserStateProps, IUserProps, IUserState, mapDispatchToProps, cnUser, cnInfo } from './InfoBlock-User.index';
import './InfoBlock-User.scss';
import React, { FC, useState, useEffect, useMemo } from 'react'
import { Layout } from '../Layout/InfoBlock-Layout';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { Button as PrnBtn } from 'uielements/src/Button/Button';
import i18n from 'localizations/i18n';
import { Icon } from 'uielements/src/Icon/Icon';
import { UserThankForm } from 'blocks/PryanikyForms/UserThankForm/UserThankForm'
import { Link, useLocation } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import { setStatus } from 'utils/src/CommonRedux/users/actions';
import CurrencyTooltip from 'blocks/CurrencyTooltip'
import { Translate } from 'localizations/Translate';
import MDRender from 'uielements/src/CommonmarkRender'
// import { Attachments } from 'blocks/Attachments/Attachments'
import { FieldsRender } from './FieldsRender'
import moment from 'moment';
import { changePasswordDialog } from 'blocks/Dialogs/ChangePassword/ChangePassword';
import { QRCode } from 'uielements/src/QRCode/QRCode';
import { createMdLinks } from 'utils/src/utils'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip'
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { CustomSettings } from 'utils/src/CustomSettings';
import { Button as PrnButton } from 'uielements/src';
import { Wallets } from 'blocks/Wallets/Wallets';
import { ActivityBlock } from './ActivityBlock/ActivityBlock';
import { IconButton } from 'muicomponents/src/IconButton';
import { BusinessCardDialog } from './BusinessCardDialog';
import { Badge as BadgeIcon } from 'muicomponents/src/Icons';
import { moduleIsEnabled } from 'utils/src/CommonRedux/base/selectors';
import { isValidDate } from 'utils/src/utils.dates';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusBtn: {
      padding: '4px 10px 4px 10px',
      marginLeft: '-10px',
      textTransform: 'none',
      justifyContent: 'flex-start'
    },
    statusEditLine: {
      margin: '0 6px 0 6px'
    },
    mailLink: {
      textDecoration: 'none'
    }
  }),
);

const replaceDateCommand = (date: moment.Moment, str: string) => {
  const commands = [
    {
      command: '%fromNow%',
      action: () => `${(date.diff(new Date(), 'years') * -1)}`
    },
    {
      command: '%disabled%',
      action: () => ''
    }
  ]
  return commands.reduce((prev, val) => {
    return prev.replace(val.command, val.action())
  }, str)
}

const birthdayFormater = (birthday: string, birthdayFormat: string = 'D MMMM') => {
  const momentBirthday = moment(new Date(birthday))
  return replaceDateCommand(momentBirthday, momentBirthday.format(birthdayFormat || 'D MMMM'))
}


export const UserPresenter: FC<IUserProps> = ({
  user,
  chatEnable,
  authUser,
  enabledThanks,
  getUser,
  userId,
  setStatus,
  setUserStatus,
  togleEdit,
  subscribeByUser,
  currencyFormats,
  birthdayFormat
}) => {

  const { pathname } = useLocation();
  const businesscardsModuleIsActive = useSelector(moduleIsEnabled('businesscards'));


  
  const fieldsCategoryes = useMemo(() => !user.profileData ? {} : (user.profileData.userHeadFields.result as string[]).reduce<any>((a: any, c: string) => {
    const cat = user.profileData.userHeadFields.entities.fields[c].field.category
    a[cat] = [...(a[cat] || []), c]
    return a
  }, {}), [userId, user])
  const categoryesKeys = Object.keys(fieldsCategoryes).sort()

  const allowEdit = (user: any) => {
    if (authUser.baseData.isAdmin) return true;
    return (user.baseData.id === authUser.baseData.id);
  }

  const allowViewCurrency = (user: any) => {
    if (allowEdit(user)) return true;
    return user.baseData.isCurrencyShowsForAll
  }

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleStatusClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  }
  const handleStatusClose = () => {
    setAnchorEl(null);
  }

  const saveStatus = (text: string) => {
    setUserStatus(user.baseData.id, text)
    handleStatusClose();
  }
  const onChangeStatus = (e: any) => {
    setStatus(user.baseData.id, e.target.value);
  }

  const subscribe = () => {
    subscribeByUser(user)
  }

  useEffect(() => {
    getUser(userId)
  }, [userId])

  const showEditNotificationSettings = useMemo(() => {
    return !pathname.slice(1).startsWith('welcome');
  }, [pathname]);

  const showBusinessCardButton = useMemo(() => {
    const showOnlyUsers = CustomSettings.showBusinessCardButtonUsers();
    if(showOnlyUsers) {
      if(showOnlyUsers.includes(authUser.baseData?.id)) {
        return true;
      }
      return false;
    }
    return authUser.baseData?.isAdmin || user?.baseData?.id === authUser.baseData?.id;
  }, [authUser, user]);

  if (!user.baseData) {
    return null
  }
  if (!user.fullData) {
    return null
  }

  let status = user.baseData.isDeleted ? 'Пользователь удалён' : (user.baseData.statusText !== '' ? user.baseData.statusText : '')

  const onSaveStatus = (e: any) => {
    if (e.key === 'Enter') saveStatus(user.baseData.statusText)
  }
  const currencyName = currencyFormats.currencyNameNominativePlural;

  const formatedBirthday = birthdayFormater(user.extData.birthday, birthdayFormat)

  return <div className={'InfoBlock-User'}>
    <Layout
      left={
        <>
          <div className={'InfoBlock-Img'}>
            <Avatar
              shape="square"
              className={cnUser('Avatar')}
              imgId={user.baseData.largeImgId}
              imgUrl={user.baseData.userLargePhotoUrl}
              name={user.baseData.displayName}
              size={180}
            >
            <ActivityBlock sex={user.baseData.sex} lastActivityDate={user.profileData?.lastActivityDate || null} />
            </Avatar>
          </div>
          {
            (
              CustomSettings.showBusinessCardButton()
              || businesscardsModuleIsActive
            )
            && showBusinessCardButton
            &&
            <div className={cnInfo('BusinessCard')}>
              <PrnBtn
                main
                style={{ width: '100%' }}
                onClick={() => {
                  BusinessCardDialog({ id: userId }).then(d => {}).catch(e => console.error(e));
                }}
              >
                <Translate i18nKey={'businesscard.button.text'} />
              </PrnBtn>
            </div>
          }
          {allowViewCurrency(user) && <div className={'Profile-Currency'}>
            {/* 
  // @ts-ignore */}
            <CurrencyTooltip forSpend userId={user.baseData.id}>
              <>
                <Icon icon="wallet" className="Profile-CurrencyIcon" />
                <div className={cnUser('CurransyHelp')}>
                  <div>
                    <b>
                      <Translate i18nKey='pryaniky.widgets.virtcurrency.forSpend' />
                    </b>
                  </div>
                  {user.extData.mythanksCount}
                </div>
              </>
            </CurrencyTooltip>

            {/* <HelpTooltip
              className={cnUser("Help")}
              help={<div>
                <Icon icon="wallet" className="Profile-CurrencyIcon" />{i18n.t('pryaniky.widgets.virtcurrency.forSpend')}
                {user.extData.mythanksCount}
              </div>}>
              {user.extData.mythanksCount}
            </HelpTooltip> */}
            {/* <span className="Profile-CurrencyDivider" /> */}
            {/* 
  // @ts-ignore */}
            <CurrencyTooltip summaryRender={(summary: number) => <>
              <Icon icon="give" className="Profile-CurrencyIcon" />
              <div className={cnUser('CurransyHelp')}>
                <div>
                  <b>
                    <Translate i18nKey='pryaniky.widgets.virtcurrency.forGive' />
                  </b>
                </div>
                {summary}
              </div>
            </>} userId={user.baseData.id}>

            </CurrencyTooltip>
            <Wallets uid={userId} />
          </div>}
          {(user.baseData.id !== authUser.baseData.id) &&
            <div className={cnUser('SocialActions')}>
              {
                enabledThanks && <UserThankForm
                  users={[user.baseData]}
                  user={authUser.baseData}
                  button={(openDialog: () => void, currencyName: any) => {
                    return (
                      <PrnBtn main onClick={openDialog}>
                        <Translate i18nKey='pryaniky.profile.thanks' />
                      </PrnBtn>
                    )
                  }}
                />
              }
              <PrnBtn theme={'unstyled_center'}
                title={user.extData.isSubscribedByMe ? i18n.t('pryaniky.profile.unsubscribe') : i18n.t('pryaniky.profile.subscribe')}
                onClick={subscribe}>
                {
                  user.extData.isSubscribedByMe ?
                    <Translate i18nKey='pryaniky.profile.unsubscribe' /> :
                    <Translate i18nKey='pryaniky.profile.subscribe' />
                  // <Icon icon={'user-minus'} /> : <Icon icon={'user-plus'} />
                }
              </PrnBtn>
              {
                chatEnable &&
                <PrnBtn theme={'unstyled_center'} title={i18n.t('pryaniky.profile.message')}
                  type={'rlink'}
                  href={'/messages/direct/' + user.baseData.alias}>
                  {/* <Icon icon={'message'} /> */}
                  <Translate i18nKey='pryaniky.profile.message' />
                </PrnBtn>
              }
            </div>
          }

        </>
      }
      right={
        <div className={cnUser('Data')}>
          <div className={cnInfo('DataNameBox')}>
            <div className={cnInfo('DataName')}>
              {user.baseData.displayName}
            </div>
            <div className={cnInfo('QR')}>
              <QRCode uid={userId} userName={user.baseData.displayName} />
            </div>
          </div>
          {
            allowEdit(user) &&
            <div className={cnUser('Settings')}>
              <PrnButton theme='unstyled' type='rlink' href={`${window.location.pathname.replace(utils.basename, '')}?profileEdit=true`}>
                <Translate i18nKey={'pryaniky.profile.edit'} />
              </PrnButton>
              {
                showEditNotificationSettings &&
                <>
                  <div className={cnUser('Settings-Splitter')} />
                  <PrnButton theme='unstyled' type='rlink' href={`/notifiSettings/${user.baseData.id}/common`}>
                    <Translate i18nKey={'pryaniky.profile.notifisettings'} />
                  </PrnButton>
                </>
              }
            </div>
          }
          <div className={cnUser('Data-Status')}>
            <div id='StatusEditor'>
              <Tooltip title={i18n.t('pryaniky.profile.changeStatus.title')} >
                <span>
                  {
                    allowEdit(user) ?
                      <Button id='edit-user-status' aria-describedby={id}
                        onClick={ handleStatusClick} className={classes.statusBtn}>
                        {
                          user.baseData.statusText !== '' ? user.baseData.statusText : i18n.t('pryaniky.profile.changeStatus')
                        }
                      </Button>
                      :
                      <div>{status}</div>
                  }
                </span>
              </Tooltip>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleStatusClose}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
              >
                <TextField
                  autoFocus={true}
                  className={classes.statusEditLine}
                  value={user.baseData.statusText}
                  onChange={onChangeStatus}
                  margin="normal"
                  onKeyPress={onSaveStatus}
                />
                <Button id='save-user-status' onClick={() => { saveStatus(user.baseData.statusText) }}>{i18n.t('pryaniky.profile.save')}</Button>
              </Popover>
            </div>
          </div>
          <div className={cnUser('DataPosition') + ' ' + cnInfo('GrayText')}>{user.baseData.position}</div>
          <div className={cnUser('DataDivision') + ' ' + cnInfo('GrayText')}>{user.baseData.division}</div>
          <table className={cnUser('Fields')}>
            <colgroup>
              <col span={1} className={cnUser('Col1')} />
              <col span={1} className={cnUser('Col2')} />
            </colgroup>
            <tbody>
              {((user.extData.birthday !== "0001-01-01T00:00:00.000Z") && (formatedBirthday !== '')) && <tr className={cnUser('Fields-Item')}>
                <td className={cnInfo('GrayText')}>{i18n.t('pryaniky.profile.birthday') + ':'}</td>
                {/* <td>{new Date(user.extData.birthday).toLocaleString(localStorage.getItem('i18nextLng') || 'ru', { month: 'long', day: '2-digit' })}</td> */}
                <td>{formatedBirthday}</td>
              </tr>}
              <tr className={cnUser('Fields-Item')}>
                <td className={cnInfo('GrayText')}>{i18n.t('pryaniky.profile.phone') + ':'}</td>
                <td>{user.baseData.phone}</td>
              </tr>
              <tr className={cnUser('Fields-Item')}>
                <td className={cnInfo('GrayText')}>{i18n.t('pryaniky.profile.email') + ':'}</td>
                <td><a /*className={classes.mailLink}*/ href={'mailto:' + user.baseData.eMail}>{user.baseData.eMail}</a></td>
              </tr>
              {/* {allowEdit(user) && <tr className={cnUser('Fields-Item')}>
                <td className={cnInfo('GrayText')}>
                  <Button variant="outlined" className={cnInfo('PassButton')} onClick={() => changePasswordDialog({
                    userId: user?.baseData?.id || userId,
                    currentUser: authUser
                  })}>
                    <Translate i18nKey={'pryaniky.changepassword'} />
                  </Button></td>

              </tr>} */}
              {/*
                user.profileData.userHeadFields.map((e: { [s: string]: any }, i: number) => {
                  if (!e.propetryVisible || e.propertyValue === '') return null;
                  return <tr key={i} className={cnUser('Fields-Item')}>
                    <td>{e.propertyDisplayName}</td>
                    <td>{e.propertyValue}</td>
                  </tr>
                })*/
              }
              <FieldsRender
                categotyRender={(cat: string, fields: any, FieldRender: any) => <>
                  <tr className={cnUser('Fields-Item')}>
                    <td className={cnInfo('GrayText')}>
                      <h4>{cat}</h4>
                    </td>
                    <td></td>
                  </tr>
                  {fields.map((v: any) => <FieldRender field={v} />)}
                </>
                }
                fieldRender={(val: any) => {
                  const { field, value, tags, files, fieldId } = val;
                  if (!field) return null;
                  if (field.fieldType === 'Files') {
                    return null;
                  }
                  if (field.fieldType === 'Tags') {
                    if (tags.length === 0) return null;
                    return (
                      <tr key={fieldId} className={cnUser('Fields-Item')}>
                        <td className={cnInfo('GrayText')}>
                          <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />:
                        </td>
                        <td>{tags.map((tag: any, i: number) => {
                          const tagLink = field.additionalParam1 ? (field.additionalParam1 as string).replace('{id}', tag.id) : `/tag/${tag.id}`
                          return <>
                            <PrnBtn className={cnUser('TagLink')} theme={'unstyled'} key={tag.id} type="rlink" href={tagLink}>
                              {tag.displayName}
                            </PrnBtn>{(i < (tags.length - 1)) && <span className={cnUser('Seporator')}>,</span>}</>
                        })}</td>
                      </tr>
                    )
                  }
                  if (field.fieldType === 'Interests') {
                    if (tags.length === 0) return null;
                    return (
                      <tr key={fieldId} className={cnUser('Fields-Item')}>
                        <td className={cnInfo('GrayText')}>
                          <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />:
                        </td>
                        <td>{tags.map((tag: any, i: number) => {
                          const tagLink = field.additionalParam1 ? (field.additionalParam1 as string).replace('{id}', tag.id) : `/tag/${tag.id}/people?collectionType=interests`
                          return <>
                            <PrnBtn className={cnUser('TagLink')} theme={'unstyled'} key={tag.id} type="rlink" href={tagLink}>
                              {tag.displayName}
                            </PrnBtn>{(i < (tags.length - 1)) && <span className={cnUser('Seporator')}>,</span>}</>
                        })}</td>
                      </tr>
                    )
                  }
                  if (field.fieldType === 'String' && field.mask === "email") {
                    if (!value) return null
                    return (
                      <tr key={fieldId} className={cnUser('Fields-Item')}>
                        <td className={cnInfo('GrayText')}>
                          <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />:
                        </td>
                        <td>
                          <PrnBtn theme={'unstyled'} {...utils.generateLinkData(value)}>{value}</PrnBtn>
                        </td>
                      </tr>
                    )
                  }
                  if (field.fieldType === 'String' && field.mask === "telefon") {
                    if (!value || value === "+") return null
                    return (
                      <tr key={fieldId} className={cnUser('Fields-Item')}>
                        <td className={cnInfo('GrayText')}>
                          <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />:
                        </td>
                        <td>
                          {value}
                        </td>
                      </tr>
                    )
                  }
                  if (field.fieldType === 'String') {
                    if (!value) return null
                    const withLinks = createMdLinks(value)
                    return (
                      <tr key={fieldId} className={cnUser('Fields-Item')}>
                        <td className={cnInfo('GrayText')}>
                          <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />:
                        </td>
                        <td>
                        
                          {field.url?
                          <a target="_blank" href={field.url}>
                            <MDRender source={withLinks} />
                          </a>:
                          <MDRender source={withLinks} />}
                        </td>
                      </tr>
                    )
                  }
                  if (field.fieldType === 'Bool') {
                    if (!value || value === 'false') return null
                    return (
                      <tr key={fieldId} className={cnUser('Fields-Item')}>
                        <td className={cnInfo('GrayText')}>
                          <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                        </td>
                      </tr>
                    )
                  }
                  if (field.fieldType === 'Textarea') {
                    if (!value) return null
                    return (
                      <tr key={fieldId} className={cnUser('Fields-Item')}>
                        <td className={cnInfo('GrayText')}>
                          <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />:
                        </td>
                        <td><MDRender source={value} /></td>
                      </tr>
                    )
                  }


                  if (field.fieldType === 'DateTime') {
                    // const defaultMask = "dd/MM/yyyy"
                    if (!value) return null
                    if (!isValidDate(moment(value).toDate())) return null;
                    return <tr key={fieldId} className={cnUser('Fields-Item')}>
                      <td className={cnInfo('GrayText')}>
                        <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />:
                      </td>
                      <td>{moment(value).format('L LT')}</td>
                    </tr>;
                  }
                  if (field.fieldType === 'Date') {
                    // const defaultMask = "dd/MM/yyyy"
                    if (!value) return null
                    if (!isValidDate(moment(value).toDate())) return null;
                    return <tr key={fieldId} className={cnUser('Fields-Item')}>
                      <td className={cnInfo('GrayText')}>
                        <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />:
                      </td>
                      <td>{moment(value).format('L')}</td>
                    </tr>;
                  }
                  if (field.fieldType === 'AdditionalUserId' && !authUser.baseData.isAdmin) {
                    return null;
                  }
                  if (!value) return null;
                  return (
                    <tr key={fieldId} className={cnUser('Fields-Item')}>
                      <td className={cnInfo('GrayText')}>
                        <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />:
                      </td>
                      <td>{value}</td>
                    </tr>
                  );
                }}
                fields={user.profileData.userHeadFieldsOriginal} />
            </tbody>
          </table>
        </div>
      } />
  </div>
}

export const User = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPresenter)