

export interface AlbumType {
    date: Date;
    displayUserName: string;
    groupId: number;
    id: string;
    isFolder: boolean;
    isLastVersion: boolean;
    itemsCount: number;
    name: string;
    newsUid: string[];
    parentFolder: string;
    previewAction: string;
    previewImgId: string;
    previewUrl: string;
    urlname?: string;
    userId: string;
    version: number;
    versions: null;

    groupName?: string;
    highlight?: string;
}


export const defaultAlbum = {
    date: "2019-10-09T09:15:12.003Z",
    displayUserName: "",
    fileId: "00000000-0000-0000-0000-000000000000",
    groupId: 57719,
    groupName: null,
    highlight: null,
    id: "4c23f950-ea75-11e9-80a6-d7691726d6a2",
    isFolder: true,
    isLastVersion: false,
    itemsCount: 29,
    name: "fftftf",
    newsUid: [],
    parentFolder: "39262005-ee79-4358-8e84-96199397575f",
    previewAction: "None",
    previewImgId: "7757db76-3edf-4af3-a147-2a4c27a52190",
    previewUrl: "https://testrating34.pryaniky.com/Img/Attachments/Preview/1024c1024/7757db76-3edf-4af3-a147-2a4c27a52190.png",
    urlname: null,
    userId: "00000000-0000-0000-0000-000000000000",
    version: 0,
    versions: null,
}