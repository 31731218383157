import { v1 as uuid } from 'uuid';
import { AdventCalendar, AdventCalendarDay } from "utils/src/BaseTypes/adventCalendar.types";
import moment, { Moment } from 'moment';

export const generateDay = (date: AdventCalendarDay['date']): AdventCalendarDay => ({
    id: uuid(),
    hideDefaultText: false,
    isEmpty: false,
    url: '',
    date,
    mainFile: null
});

export var enumerateDaysBetweenDates = function(startDate: string, endDate: string) {

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');
    
    var dates = [
        currDate.clone().toDate()
    ];

    while(currDate.add(1, 'days').diff(lastDate) <= 0) {
        dates.push(currDate.clone().toDate());
    }

    return dates;
};

const dateFrom = moment().startOf('month').toISOString();

const dateTo = moment().endOf('month').add(3, 'days').toISOString();

export const AdventCalendarMock: AdventCalendar = {
    id: uuid(),
    dateFrom,
    dateTo,
    isDeleted: false,
    name: 'Я волна'
};

export const AdventCalendarDaysMock: AdventCalendarDay[] = enumerateDaysBetweenDates(AdventCalendarMock.dateFrom, AdventCalendarMock.dateTo).map(date => generateDay(date.toISOString())).map((el, idx) => {
    // if(idx % 4 === 0) {
    //     return {
    //         ...el,
    //         mainImgUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3Z1c17Djb9MCjSH8E9B7jHcvZdlVWHBTDJtoMNf_C&s',
    //         url: 'vk.com'
    //     };
    // }
    // if(idx % 5 === 0) {
    //     return {
    //         ...el,
    //         hideDefaultText: true,
    //         mainImgUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQ96FI4mDgRbxIUG5UYl9mr-tJ4NnDfNa-WLcGpQLm_A&s',
    //         url: '/calendar'
    //     };
    // }
    // if(idx % 2 === 0) {
    //     return { ...el, isEmpty: true};
    // }
    // if(idx % 7 === 0) {
    //     return { ...el, };
    // }
    return el;
});