import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IDialogContentPresenterProps = IDialogContentOwnProps

export interface IDialogContentState { }

export interface IDialogContentOwnProps extends IClassNameProps {
    id?: string;
    children?: React.ReactNode;
}

export const cnClassName = cn('ModalDialogContent');