import React, { FC, memo } from 'react';
import { IPhotoProps, mapStateToProps, mapDispatchToProps } from './Photo.index'
import { connect } from 'react-redux';
import * as utilsProj from 'utils.project/utils.project';
import * as utils from 'utils/src/utils'
import { Button, Icon, CheckboxInput, Spinner } from 'uielements/src'
import i18n from 'localizations/i18n';
import './Photo.scss'
import { domainForAuthAdd } from 'utils/src/debug';

export const Photo_: FC<IPhotoProps> = ({ file, onClick, deletePhoto, fid, aid, selectPhoto, selected, gid, showControls }) => {
    const onDelete = () => {
        utilsProj.confirm({ text: i18n.t('pryaniky.photoalbums.photo.delete'), onConfirm: () => deletePhoto(aid, file.id) })
    }
    const onSelect = () => selectPhoto(aid, fid)
    return <div className={'Photo' + (selected ? ' Photo_selected' : '')} style={{ backgroundImage: `url("${file.previewUrl + (domainForAuthAdd.reduce((acc, curr) => acc || window.location.hostname.indexOf(curr) >= 0, false) ? `?auth=${utils.getCookie('authAt')}` : '')}")` }}>
        {(!file.isUploading && showControls) && <div className={'Photo-Actions'}>
            <CheckboxInput checked={selected} onChange={onSelect}>{i18n.t('pryaniky.photoalbums.photo.select')}</CheckboxInput>
            <Button theme={'unstyled_center'} onClick={onDelete}><Icon icon={'trash'} /></Button>
        </div>}
       
        {!file.isUploading && <Button
            type={'rlink'}
            // href={`/group/${gid}/albums/${aid}/${file.id}/${file.name}`}
            href={`?aid=${aid}&fid=${file.id}&fin=${file.name}`}
            theme={'unstyled'}
            className={'Photo-Info'}>
            <div>{file.name}</div>
            <div>{(new Date(file.date)).toLocaleDateString()}</div>
        </Button>}

        {file.isUploading && <div className={'Photo-Uploading'}><Spinner /></div>}
    </div>
}

export const Photo = connect(
    mapStateToProps, mapDispatchToProps
)(memo(Photo_))