import * as React from 'react';
import { IEditorTypeNewsProps, mapStateToProps } from './news.index';
import { RepostNews, defaultRepostNews } from '../../../Repost.type'
import { BaseValidator, baseValidate } from '../../../../Base.validate'
import { Editor } from '../../Editor'
import * as utils from 'utils/src/utils';
import { EditorState } from 'draft-js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

export const _EditorTypeNews: React.FC<IEditorTypeNewsProps & any> = ({ onChange: cbChange, data, types, userData }) => {
    let localData = { ...data } as any;
    if (data.newstype !== 31) {
        localData = {
            ...utils.cloneObject(defaultRepostNews), text: EditorState.createEmpty(),
        }
    }

    const onChange = (data: any) => {
        const vb = new BaseValidator<any>()
        vb.addData(data)
        vb.setRules(baseValidate)
        cbChange(data, vb.validation().length === 0)
    }

    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData);
    }
    const onChangeGroup = (group: any[]) => {
        localData.group = group.length === 0 ? undefined : {
            pkid: group[0].pkid || group[0].id,
            imgId: group[0].imgId,
            imgUrl: group[0].imgUrl,
            name: group[0].displayName,
            displayName: group[0].displayName
        };
        onChange(localData);
    }
    const onChangeNetwork = (selected: any) => {
        localData.network = selected.value
        onChange({ ...localData });
    }
    return <Editor
        onChangeNetwork={onChangeNetwork}
        networks={userData.baseData.networks}
        onChangeText={onChangeText}
        onChangeGroup={onChangeGroup}
        data={localData} />
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch);
export const EditorTypeNews = connect<any, any>(
    mapStateToProps,
    mapDispatchToProps
)(_EditorTypeNews) as any;