import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    loadUsersStat,
    loadMaterialStat
} from '../../../redux/actions/TracksManage'
import {
    ILearningTracks
} from '../../../redux/reducers/TracksManage'
import {
    getTracksObject,
    getStatUsersObject
    // getTracksSelected
} from '../../../redux/sagas/TracksManage/selectors'
import { ILearningTrack } from 'utils/src/requests/models/api.learningTracks'
///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps<{ id: string }>

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    search?: string
}

export type IStateProps = ReturnType<typeof mapStateToProps>// extends ILearningTracks {}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////


export const mapStateToProps = (state: any, props: IOwnProps) => ({
    ...getStatUsersObject(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadUsersStat
}, dispatch);

////////////

export const cnClassName = cn('LMSTOTracksStatisticUsersList');