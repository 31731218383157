import * as React from 'react';
import { StickyTable } from 'uielements/src/StickyTable/StickyTable';
import { Button, Checkbox } from 'uielements/src';
import i18n from '../../../localizations/i18n';
import queryString from 'query-string';
import { API } from 'utils/src/utils';
import { toast } from 'react-toastify';
import './NotificationsSettings_Common.scss';
import { cnNotificationsSettingsCommon, INotificationsSettingsCommonProps, INotificationsSettingsCommonState, mapStateToProps } from './NotificationsSettings_Common.index';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux'
export class NotificationsSettingsCommon extends React.Component<
  INotificationsSettingsCommonProps,
  INotificationsSettingsCommonState
  > {

  constructor(props: INotificationsSettingsCommonProps) {
    super(props);
    this.state = {
      settings: {
        g: [],
        destinations: [],
        denyMailMe: false,
      }
    }
  }
  public componentDidMount() {
    this.loadSettings();
  }
  public render(){
    // const customClassNames = "";
    // const { tag: TagName = "div", children, className = "" } = this.props;
    const { settings } = this.state;
    const section = queryString.parse(window.location.search).section || "common";
    // const currentGid = queryString.parse(window.location.search).gid || "-1";
    const key = section === "groups" ? "nu" : "g";
    const tdata = !settings[key] ? [] : settings[key];
    return <div className="NotificationsBox">
      {tdata.map((item: any, index: number) => (
        <div className="NotificationsSettings-layout systemBorderColorGray systemBorder " key={index}>
          <h2>{item.displayName}</h2>
          <div className="NotificationsSettings-wrapper">
            <StickyTable
              columnsHeaders={settings.destinations.map((v: any) => v.value)}
              rowsHeaders={item.n.map((v: any) => v.displayName)}
              rows={this.prepareRows(item)}
            />
            <Button className={cnNotificationsSettingsCommon("saveButton")} onClick={this.saveSettings}>
              {i18n.t("save")}
            </Button>
          </div>
        </div>
      ))}
    </div>
  }
  private loadSettings = (gid = 0) => {
    // USERID
    const id = this.props.userId || utils.getCookie('authUid');
    API.notifications.settings.get(id, gid, this.props.store.authUser.baseData.baseNetwork.id).r.then(d => {
      this.setState({ settings: d.data }, () => {
     
      });
    });
  };
    private prepareRows = (item: any) => {
      return item.n.map((v: any) => ({
        cells: v.values.map((s: any, i: number) => (
          <Checkbox
            key={i}
            checked={s.value}
            onClick={() => {
              s.value = !s.value;
              this.setState({});
            }}
          />
        ))
      }));
    };
  private transformDataToOldGroup(data: any, uid: string) {
    return {
      uid: uid,
      groupId: data.groupId,
      g1: {
        groupId: data.groupId,
        displayName: data.displayName,
        selectedTemplateName: "custom", // data.selectedTemplateName,//"custom",
        nu: data.nu
      },
      auth: data.auth
    };
  }
  private saveSettings = () => {
    // utils.API.notifications.settings.setNetworkTemplate('0', id, currentElement.id, {
    //   networkTemplate: 'custom',
    //   g: settings.g
    // })
    //   .r.then((d: any) => {
    //     if (d.error_code === 0) {
    //       toast.success(i18n.t('Настройки'))
    //     } else {
    //       toast.error(i18n.t('Ошибка!'))
    //     }
    //   })
    const { currentGroup, section, settings } = this.state;
    // USERID
    const id = this.props.userId || utils.getCookie('authUid');
        // this.props.store.authUser.profileData.userId;
    const resultPromiss =
      section === "groups"
        ? API.notifications.settings.setGroup(currentGroup.pkid, id, this.transformDataToOldGroup(settings, id)) //this.state.data) :
        : API.notifications.settings.setNetworkTemplate('0', id, this.props.store.authUser.baseData.baseNetwork.id, {
          networkTemplate: 'common',
          g: settings.g
        })
        
        API.notifications.settings.set(id, settings);

    resultPromiss.r.then((d: any) => {
      if (d.error_code === 0) {
        toast.success(i18n.t("success"));
      } else {
        toast.error(i18n.t("server error"));
      }
    });
  };
  }

export default connect(
  mapStateToProps,
  null
)(NotificationsSettingsCommon);