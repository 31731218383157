import { INewsPropsType, mapStateToProps, TStateProps } from './News.index'
import React, { FC, memo } from 'react';
import { connect, useSelector, shallowEqual } from 'react-redux';
import './News.scss'
import { withNewsContext } from 'News/contexts/news'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import TextRender from 'News/containers/TextRender'
import ContentLayout from 'News/components/ContentLayout'
import { useLoadNewsById } from 'News/utils/hooks'
import {
    getNewsById,
    getNewsParamsById
} from 'News/redux/saga/selectors'
import { LMSNewsNews } from './News.type'
import NewsItem from 'News/containers/NewsItem'
import BaseView from 'News/containers/BaseView'
import ItemView from '../ItemView'

const newsParams = [
    'id',
    'learningTrack'
] as const
export const NewsPresenter: FC<INewsPropsType & TStateProps> = ({ newsId }) => {
    const params = useSelector(getNewsParamsById<LMSNewsNews, typeof newsParams[number]>(newsId, newsParams), shallowEqual)
    const [news, isLoading, { errors, isFailed }] = useLoadNewsById(params.learningTrack.objectId)
    return <NewsView className={'type-5'} ViewRender={ItemView}>
        {/* <ContentLayout>
            <TextRender />
        </ContentLayout> */}
        <div className={'Repost-RepostedNews'}>
            {news && <NewsItem id={params.learningTrack.objectId || ''} ViewComponent={BaseView} repost />}
        </div>
    </NewsView>
}

export const News = withNewsContext<INewsPropsType>(connect(
    mapStateToProps
)(memo(NewsPresenter)), ['newsId']);


export const initNews = () => {
    ComponentInjector.getInstance().addNode('news_types', <News />, 'LMSLearningTrack.News')
}