import Component, { Presenter } from './component';
import {
    IAllReportsOwnProps,
    IAllReportsProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = IAllReportsOwnProps;
export type IComponentPresenterProps = IAllReportsProps;


export default Component