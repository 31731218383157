import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "utils/src/CommonRedux/users/selectors";
import { getAppSettings, getCurrentUser } from "utils/src/CommonRedux/base/selectors";
import { MyWallet } from 'utils/src/BaseTypes/wallets.types';
import { changeUser } from "utils/src/CommonRedux/users/actions";

/**
 * 
 * @param uid user id
 * @returns 
 */
export const useWallets = (uid: string) => {

    const data = useSelector(getUserById(uid));

    const wallets = data?.extData?.wallets as MyWallet[] | undefined;

    return {
        wallets: wallets?.map(el => el.id)
    };
};

export const useWallet = (uid: string, walletId: MyWallet['id']) => {

    const dispatch = useDispatch();

    const data = useSelector(getUserById(uid));

    let mythanksCount = data?.extData?.mythanksCount as number || 0;

    const currentUser = useSelector(getCurrentUser);

    const allowActions = uid === currentUser?.baseData?.id;

    const wallets = data?.extData?.wallets as MyWallet[] || [];

    const wallet = wallets.find(el => el.id === walletId);

    const changeCurrencyAtWallet = (currencyCount: number, expires?: NonNullable<typeof wallet>['expires'], percentage?: NonNullable<typeof wallet>['percentage']) => {
        const updatedWallets = wallets?.map(wallet => {
            if(wallet.id === walletId) {
                return {
                    ...wallet,
                    value: wallet.value + currencyCount,
                    percentage,
                    expires: expires || null
                };
            }
            return wallet
        });
        dispatch(changeUser(uid, {
            ...data,
            extData: {
                ...data.extData,
                mythanksCount: data.extData.mythanksCount - currencyCount,
                wallets: updatedWallets
            }
        }));
    };

    return {
        wallet,
        allowActions,
        mythanksCount,
        changeCurrencyAtWallet
    };
};