import { toast } from "react-toastify";

// get actions to top frame
export enum GetActions {
    // register app
    registerFrame = 'registerFrame',

    // get autorized user
    getCurrentUser = 'getCurrentUser',
    
    // get company settings
    getCompanySettings = 'getCompanySettings',
    
    // get user data by user id
    getUserById = 'getUserById',

    // get group data by group id
    getGroupById = 'getGroupById',

    // get group members list by group id
    getGroupMembersByGroupId = 'getGroupMembersByGroupId',

    // get setted company styles
    getCompanyStyles = 'getCompanyStyles',

    // show dialog
    showDialog = 'showDialog',

    // get fonts subset
    getFontsSubset = 'getFontsSubset',

    // set frame height
    setFrameHeight = 'setFrameHeight',

    // get current widget context
    getContext = 'getContext',

    // history action for react or other redirect programly
    history = 'history',

    // push toast 
    toast = 'toast'
};

// get actions to current frame and post it to top frame
export enum GetCurrentFrameActions {
    
    // get dialog data when application run in dialog
    getDialogData = 'getDialogData'
};

export enum GetCustomActions {

    // get user auth
    getAuth = 'getAuth'
};

// answers from top frame after call GetActions
export enum PostActions {
    // register rezult
    postRegisterFinish = 'postRegisterFinish',
    
    // post autorized user
    postCurrentUser = 'postCurrentUser',
    
    // post company settings
    postCompanySettings = 'postCompanySettings',
    
    // post get user by id request rezult
    postUserById = 'postUserById',
    
    // post get group by id request rezult
    postGroupById = 'postGroupById',
    
    // post get group members list by group id rezult
    postGroupMembersByGroupId = 'postGroupMembersByGroupId',
    
    // post setted company styles
    postCompanyStyles = 'postCompanyStyles',
    
    // post dialog rezult, if dialog change something then post data after postDialogData action
    postResultDialog = 'postResultDialog',

    // get fonts subset to frame
    postFontsSubset = 'postFontsSubset',

    // set frame height finish
    setFrameHeightFinish = 'setFrameHeightFinish',

    // path new context data
    postContext = 'postContext',

    // response histroy if something wrong
    historyResponse = 'historyResponse',

    // push toast 
    toast = 'toast'
};

// post actions to top frame acter catch GetCurrentFrameActions
export enum PostCurrentFrameActions {
    
    // post dialog data after getDialogData action
    postDialogData = 'postDialogData'
};

export enum PostCustomActions {

    // post user auth
    postAuth = 'postAuth'
};