import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type ITaskResultProps = ITaskResultOwnProps

export interface ITaskResultState { }

export interface ITaskResultOwnProps extends IClassNameProps {
    cid: string
    fullscreen?: boolean
    course: any
    unit: any
    sid: string
    ulid: string
    result: any
    unitContent: any
    attemptAction?: any
}

export const cnClassName = cn('LMSTaskResult');