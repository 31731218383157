import * as React from 'react';
import * as utils from 'utils/src/utils';

// import { withBemMod } from '@bem-react/core';

// import { cnWidget } from '../Widget.index';
import { IWidgetTypeIframeProps } from './Widget_type_iframe.index';
import './Widget_type_iframe.scss';
// import { Info } from 'blocks/Info/Info';

import { default as WidgetPresenter } from '../Widget';
import { widgets } from "../../../i.widgets";
import { cnWidgetMui } from '../Widget.index';

export default class WidgetTypeIframePresenter extends WidgetPresenter<IWidgetTypeIframeProps> {

  public render() {
    if (!this.props.widget) return null;
    // const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    const frameId = `frame-${this.props.widget.id}`;
    const type = this.props.widget.type.replace(/pryaniky\//, '');
    let { settings } = this.props.widget;
    if (!settings || !settings.src) {
      console.error(`IFRAME WIDGET SETINGS ERROR! widgteId: ${this.props.widget.id}, settings: ${this.props.widget.settings}`);
      settings = {
        src: '/empty'
      }
    }
    let src: string = settings.src;
    if(src.includes('?')) {
      src = src.split('?').join(`?frameId=${frameId}&`);
    } else {
      src = src + `?frameId=${frameId}`;
    }
    if(settings.withAuth) {
      const authString = `authAt=${utils.getCookie('authAt')}&authUid=${utils.getCookie('authUid')}`;
      if (src.includes('?')) {
        src = src.split('?').join(`?${authString}&`);
      } else {
        src = src + `?${authString}`;
      }
    }
    const props: any = {
      height: '150px'
    };
    if (!isNaN(Number(settings.height)) && Number(settings.height) > 150) props.height = settings.height + 'px';
    const Tag = widgets.components.common;
    return <Tag {...this.props} className={cnWidgetMui({ type })}>
      <iframe id={frameId} src={src} width={'100%'} {...props} />
    </Tag>
    // <Component {...this.props} className={cnWidget({type, subtype})} context={this.props.wcontext} tag={widgets.components.common} />
  }

}

// export const withWidgetTypeInfo = withBemMod<IWidgetTypeIframeProps>(
//   cnWidget(),
//   { type: 'info' }, 
//   (Presenter) => (
//     (props) => (
//       <Presenter {...props} children={
//         <Info />
//       }/>
//     )
//   )
// );