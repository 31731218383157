import React from 'react';
import { cnWidget, IWidgetProps } from '../../Widget.index';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../../Widget';
import { widgets } from "i.widgets";
import './Widget_Type_RatingBadges.scss'
import { RatingBadgeWidget } from 'blocks/RatingBadgeWidget/RatingBadgeWidget';
import { IWidgetTypeRatingBadgeProps } from './Widget_Type_RatingBadges.index';

const type = 'ratingBadge';

export default class WidgetTypeRatingBadgePresenter extends WidgetPresenter<IWidgetTypeRatingBadgeProps> {

    public render() {
        if (!this.props.widget) return null
        const subtype = utils.widgetSubtype(this.props.widget.type);
        const Tag = widgets.components.common
        return <Tag {...this.props} className={cnWidget({ type, subtype })}>
            <RatingBadgeWidget {...this.props} className={cnWidget({ type, subtype })} settings={this.props.wcontext} tag={widgets.components.common} />
        </Tag>
    }
}