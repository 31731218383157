import React from 'react';
import { cnMountainRating, FilterOptions } from '../MountainRating.index';
import i18n from '../../../localizations/i18n';
import Select from 'react-select';
import { Icon } from 'uielements/src/Icon/Icon';

interface IFiltersProps {
  options: FilterOptions;
  value: any;
  onChange(option: any): void;
}

export const Filters: React.FC<IFiltersProps> = props => (
  <Select
    isClearable
    className={cnMountainRating('Select')}
    classNamePrefix="select"
    placeholder={i18n.t('select filter')}
    components={{
      IndicatorSeparator: null,
      DropdownIndicator,
      ClearIndicator,
    }}
    {...props}
  />
);

const ClearIndicator = (props: any) => {
  const {
    children = <Icon icon="times" className={cnMountainRating('Select-Icon', { clear: true })} />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      {children}
    </div>
  );
};

const DropdownIndicator = (props: any) => {
  const {
    children = <Icon icon="chevron-down" className={cnMountainRating('Select-Icon', { chevron: true })} />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      {children}
    </div>
  );
};
