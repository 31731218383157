
import { NIdeas } from './Ideas.interface';
import { FieldsEditor } from 'muicomponents/src/FieldRender';
import { clone, fromPairs } from 'lodash';
import { ComponentProps } from 'react';

// "Bool" | "DateTime" | "Int" | "MatrixOnePerRow" | "Multi" | "OfferResponsibleForStatusUser" | "ResponsibleForStatusUser" | "Single" | "String" | "Tags" | "Textarea" | "UniqueIdentifier"

type IdeasAdditionalFieldsValues = NonNullable<NIdeas.Create['idea']['additionalFieldsValues']>;

const fieldTypesSettings: { [key: string]: IdeasAdditionalFieldsValues[number]['v'][number] } = {

};

export const converAdditionalFieldsValuesV1ToAdditionalFieldsValues = (field: IdeasAdditionalFieldsValues[number]['v'][number]): ComponentProps<typeof FieldsEditor>['fields'][number]['fields'][number] => {
    fieldTypesSettings[field.fieldId] = clone(field);
    let data: any = null;
    let type: any = field.field.fieldType;
    switch(field.field.fieldType) {
        case "UserNotExpert":
        case "Bool": {
            data = field.value === 'true' ? true : false;
            break;
        };
        case "Date":
        case "DateTime":
        case "Int":
        case "MatrixOnePerRow":
        case "String":
        case "AdditionalUserId":
        case "Textarea": {
            data = field.value;
            break;
        };
        case "Files": {
            data = field.files
            break;
        };
        case "Multi": {
            data = field.field.answers;
            break;
        };
        case "OfferResponsibleForStatusUsers": {
            // check type
            data = field?.users && field?.users[0];
            break;
        };
        case "ResponsibleForStatusUser": {
            // check type
            data = field?.users;
            break;
        };
        case "Users": {
            // check type
            data = field?.users;
            break;
        };
        case "Single": {
            data = field.field.answers;
            break;
        };
        case "Tags":
        case "Interests": {
            data = field.tags;
            break;
        };
    };
    return {
        ...field.field,
        rows: field.field.rows as any,
        value: field.value,
        displayName: field.field.name,
        type,
        isBaseField: false,
        data
    };
};

export const converAdditionalValuesV1ToAdditionalValues = (values: IdeasAdditionalFieldsValues): ComponentProps<typeof FieldsEditor>['fields'] => {
    return values?.map((cat, idx) => {
        return {
            id: idx.toString(),
            description: '',
            isCollapsed: false,
            isExpansion: false,
            title: cat.k,
            fields: cat.v.map(converAdditionalFieldsValuesV1ToAdditionalFieldsValues).filter(field => field.type !== 'UniqueIdentifier')
        }
    });
};
export const converAdditionalValuesV1ToAdditionalValuesClosed = (values: IdeasAdditionalFieldsValues): ComponentProps<typeof FieldsEditor>['fields'] => {
    return values?.map((cat, idx) => {
        return {
            id: idx.toString(),
            description: '',
            isCollapsed: true,
            isExpansion: true,
            title: cat.k,
            fields: cat.v.map(converAdditionalFieldsValuesV1ToAdditionalFieldsValues).filter(field => field.type !== 'UniqueIdentifier')
        }
    });
};

export const converAdditionalFieldValuesToAdditionalFieldValuesV1 = (field: ComponentProps<typeof FieldsEditor>['fields'][number]['fields'][number]): IdeasAdditionalFieldsValues[number]['v'][number] => {
   
    const data = fieldTypesSettings[field.id];
    switch(field.type) {
        case "Bool": {
            data.value = field.data === true ? 'true' : '';
            break;
        };
        case "Date":
        case "DateTime":
        case "Int":
        case "String":
        case "AdditionalUserId":
        case "Textarea": {
            data.value = field.data || '';
            break;
        };
        case "Files": {
            data.files = field.data; 
            break;
        };
        case "MatrixOnePerRow": {
            data.field.rows = field.rows || []
            data.rowValues = fromPairs((field.rows || []).map(el => [el.id, el.selectedAnswer ? [el.selectedAnswer] : []]));
            break;
        }
        case "Multi": {
            data.field.answers = field.data;
            break;
        };
        case "OfferResponsibleForStatusUsers": {
            // check type
            data.users = field.data;
            break;
        };
        case "ResponsibleForStatusUser": {
            // check type
            data.users = field.data;
            break;
        };
        case "Users": {
            // check type
            data.users = field.data;
            break;
        };
        case "Single": {
            data.field.answers = field.data;
            break;
        };
        case "Tags":
        case "Interests": {
            data.tags = field.data;
            break;
        };
    };
    return data;
}

export const converAdditionalValuesToAdditionalValuesV1 = (value: ComponentProps<typeof FieldsEditor>['fields']): IdeasAdditionalFieldsValues => {
    return value.map(category => ({
        k: category.title,
        v: category.fields.map(converAdditionalFieldValuesToAdditionalFieldValuesV1)
    }));
};