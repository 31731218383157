import { connect } from 'react-redux';
import * as React from 'react';
import { mapDispatchToProps, IDispatchProps } from '../../redux/connector';
import { mapStateToProps, IGroupsStateProps, cnGroups, IGroupsProps } from './Groups.index';
import './Groups.scss';
import { Page } from '../Page/Page';
import { setContext } from 'redux/actions/Widgets';
import { pageNames } from 'redux/pageNames';

let q = true;

const GroupsPresenter: React.FC = ( _: any ) => {
  // const prp = (_ as IGroupsStateProps);
  // const [r, setR] = React.useState(false);
  // React.useEffect(() => {
  //   if (Object.keys(prp.context.common).length !== 0) {
  //     _.setContext({
  //       paths: ['common'],
  //       innerPath: '',
  //       value: {}
  //     })
  //   } else {
  //     setR(true);
  //   }
  // }, [ prp.context.common ])
  // if (!r) return null;
  return <Page page={{ name: pageNames.groups }} className={cnGroups()} />;
}

export const Groups = connect<IGroupsStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({
    setContext
  })
)(GroupsPresenter);
