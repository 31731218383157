import { Box, Button, Paper, Typography } from 'muicomponents/src';
import { Avatar } from 'muicomponents/src/Avatar';
import { styled } from 'muicomponents/src/mui/system';
import { Link } from 'muicomponents/src/Link/Link';

export const GridItemBox = styled(Paper)<{countColumns: number}>(({ countColumns }) => ({
  backgroundColor: '#fff',
  width: `calc(100% / ${countColumns} - ${(12 / countColumns) * (countColumns - 1)}px)`
}));

export const GridItemButton = styled(Button)({
  padding: "0",
  "&:hover": {
      backgroundColor: "#F5F5F5"
  },
  width: '100%',
  height: '190px',
  background: '#F5F5F5'
}) as typeof Button;

export const GridItemAvatar = styled(Avatar)({
  height: "128px",
  width: "128px",
}) as typeof Avatar;

export const GridItemContent = styled(Box)({
  padding: '12px 16px 20px'
}) as typeof Box;

export const GridItemCircleCount = styled(Box)({
  borderRadius: '100%',
  position: 'absolute',
  bottom: '0',
  right: '0',
  border: `1px solid #0066CC`,
  width: 26,
  height: 26,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F5F5F5'
}) as typeof Box;

export const GridItemDescription = styled(Typography)({
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  display: '-webkit-box',
  fontSize: 14,
  lineHeight: '18px'
}) as typeof Typography;

export const GridItemTitle = styled(Link)({
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  display: '-webkit-box',
  textDecoration: 'none',
  fontSize: 16
}) as typeof Link;
