import { bindActionCreators } from 'redux';
import { likeWikiRequest } from "redux/actions/WikiList";
import {
    getNewsById
} from 'News/redux/saga/selectors'
export interface IPageInfoProps extends IWikiDispatchProps, IWikiStateToProps {
    pageInfo: { [key: string]: any };
}
export type IWikiDispatchProps = ReturnType<typeof mapWikiInfoDispatchToProps>;

export const mapWikiInfoDispatchToProps = (dispatch: any) => bindActionCreators({
    likeWikiRequest,
}, dispatch); 
export type IWikiStateToProps = ReturnType<typeof mapWikiInfoStateToProps>;



export const mapWikiInfoStateToProps = (state: any, props: any) => ({
    // news: getNewsById(props.data.news)(state)//state.news.news[props.data.news],
    // news: getNewsById(props.pageInfo.news)(state)//state.news.news[props.data.news],
    // isAdmin: getAuthUser(state).baseData.isAdmin
});