import { connect } from 'react-redux';
import * as React from 'react';
import { withRouter } from 'react-router-dom'
import * as utils from 'utils/src/utils';
import {
  cnTimeline,
  ITimelineProps,
  ITimelineState,
  mapStateToProps,
  mapDispatchToProps,
  ITimelineDefaultProps,
} from './Timeline.index';
import { NewsCreator } from 'News/creator/Creator';


export class TimelinePresenter extends React.Component<ITimelineProps, ITimelineState> {
  public static defaultProps: ITimelineDefaultProps = {
    requestOptions: {
      newsTypes: 'all',
      skipCount: 0,
      count: 15,
      userId: '',
      groupId: -1,
      tagId: '',
      search: '',
      network: '',
      timelineType: 'default',
      viewType: 'default'
    },
  };


  public utils: any = utils;
  public el: Element;

  constructor(props: ITimelineProps) {
    super(props);
  }

  public get postOff() {
    return !!(
      this.props.context.postOff
      || this.props.context.uId
      || this.props.context.tId
      || this.props.context.timelineType === 'table'
      || this.props.context.timelineType === 'kanban'
      || this.props.context.timelineType === 'vote'
      || this.props.context.once
      || (
        /**
         * if content is disabled then disable it only if user is not admin and is not groud admin
         */
        this.props.context.disallowUserPublications
        && !this.props.context.isAdmin
        && !this.props.context.isGroupAdmin
      )
    )
  }

  public render() {
    let { tag: TagName = 'div', context, hideNews } = this.props;

    return <TagName {...this.props}>

      {!this.postOff && <NewsCreator groupId={context.gId} workflowId={context.workflowId} newsType={context.oneNewsType} />}

    </TagName>
  }

}

export const TimelineCreator = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelinePresenter));
