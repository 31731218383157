import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
// import { loadStudentSessionsList } from 'redux/actions/LMSTeacherOffice'
import { routerActions } from 'connected-react-router';
export interface IUnitsProps extends IClassNameProps, IUnitsStateProps, IDispatchProps, RouteComponentProps, IUnitsDispatchProps {
    tag?: 'div';
    sessions: any[];
    active: string;
}

export interface IUnitsState {

}

export interface IUnitsStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    // sessions: state.LMSTO.courses.values[props.cid].studentesResults[props.uid].sessions
    // sessionsIds: state.LMSTO.courses.values[props.cid].resultsIds[props.uid],
    // normalSessions: state.LMSTO.courses.values[props.cid].studentesResults[props.uid]
    // currentFilters: state.LMSTO.moderation.filters['filter.sessionIds']
    // units: state.LMSTO.moderation.units,
    // ids: state.LMSTO.moderation.results
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    // loadStudentSessionsList
    changeLocation: routerActions.push
}, dispatch);

export type IUnitsDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnUnits = cn('TOUnitsFilter');