import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    IVersionsListPresenterProps
} from './interfaces'
import './style.scss'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { formatDateSTD } from 'utils/src/utils.dates'
import { confirm } from 'utils.project/utils.project'
import { tC } from 'LMSModule/utils/i18n.adaptor'

export const Presenter: FC<IVersionsListPresenterProps> = ({
    className,
    versions = {},
    selectedVersion,
    onSelect,
    modifed
}) => {
    const versionsArray = Object.values(versions).reverse()
    const handleListItemClick = (version?: string) => () => {
        modifed ? confirm({
            onConfirm: () => onSelect(version),
        }) : onSelect(version)
    }
    const current = versionsArray.find(v => (new Date(selectedVersion).setMilliseconds(0)) === (new Date(v).setMilliseconds(0)))
    const isCurrent = current ? false : true
    return <ExpansionPanel className={cnClassName({}, [className])}>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Grid container alignItems="center">
                <Grid item xs={5}>
                    <Typography>{current ? tC('selectedVersion.1') : tC('previosVersion.1')}</Typography>
                </Grid>
                {current && <Grid item xs={7}>
                    <Typography>{formatDateSTD(current, true)}</Typography>
                </Grid>}
            </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>

            <div className={cnClassName('Container')}>
                <List dense={false} >
                    <ListItem
                        button
                        selected={isCurrent}
                        onClick={handleListItemClick()}
                    >
                        <ListItemIcon>
                            <FolderOpenIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={tC('currentVersion.primary.1')}
                            secondary={tC('currentVersion.secondary.1')}
                        />
                    </ListItem>
                    {versionsArray.map((version, i) => <ListItem
                        button
                        selected={(new Date(selectedVersion).setMilliseconds(0)) === (new Date(version).setMilliseconds(0))}
                        onClick={handleListItemClick(version)}
                        key={i}
                    >
                        <ListItemIcon>
                            <FolderOpenIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={formatDateSTD(version, true)}
                            secondary={tC('version.1', { data: versionsArray.length - i})}
                        />
                    </ListItem>)}
                </List>
            </div>

        </ExpansionPanelDetails>
    </ExpansionPanel>
}

export default memo(Presenter);
