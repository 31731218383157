import { ADV_CAL_CHANGE_CALENDAR_DAY, ADV_CAL_SET_CALENDAR, NAdventCalendarActions } from "./actions.types";

export const setAdventCalendar: NAdventCalendarActions.ActionFunction<'ADV_CAL_SET_CALENDAR'> = (payload) => ({
    type: ADV_CAL_SET_CALENDAR,
    payload
});

export const changeAdventCalendarDay: NAdventCalendarActions.ActionFunction<'ADV_CAL_CHANGE_CALENDAR_DAY'> = (payload) => ({
    type: ADV_CAL_CHANGE_CALENDAR_DAY,
    payload
});