import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMSTO
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { Avatar, Button, SplitButton, Icon } from 'uielements/src';
import { ISplitButtonOption } from 'uielements/src/SplitButton/SplitButton.index'
import { IButtonProps } from 'uielements/src/Button/Button.index'
// import { AuthorsList } from '../../../../components/AuthorsList/AuthorsList';
import withConfirmDialog, { IWithLoadSpin } from 'blocks/HOCs/Button/withConfirmDialog'
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import Checkbox from '@material-ui/core/Checkbox';

const ConfirmBtn = withConfirmDialog<IButtonProps>(Button)

type IPresenterProps = LMSTO.TOTracksListItem.IPresenterProps
type IOwnProps = LMSTO.TOTracksListItem.IOwnProps
type IDispatchProps = LMSTO.TOTracksListItem.IDispatchProps
type IStateProps = LMSTO.TOTracksListItem.IStateProps
type IState = LMSTO.TOTracksListItem.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    data,
    removeTrack,
    selectTrack,
    selected = false
}) => {
    const actions: ISplitButtonOption<IWithLoadSpin & IButtonProps>[] = [
        {
            title: tTr('item.context.delete'),
            buttonProps: {
                onClick: (e: any) => false,
                confirmBtnText: tTr('item.context.delete.confirmBtnText'),
                confirmTitle: tTr('item.context.delete.confirmTitle'),
                onConfirm: () => { removeTrack({ id: [data.tagId] }) }
            },
        },
        {
            title: tTr('item.context.users'),
            buttonProps: {
                type: 'rlink',
                href: `/TrackManage/${data.tagId}?tab=users`
            },
        },
        // {
        //     title: tTr('item.context.settings'),
        //     buttonProps: {
        //         type: 'rlink',
        //         href: `/TrackManage/${data.tagId}?tab=settings`
        //     },
        // }
    ];

    const onSelect = () => {
        selectTrack({ ids: [data.tagId] })
    }

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('SelectBox')}>
            <Checkbox
                className={cnClassName('Select')}
                checked={selected}
                onChange={onSelect}
                name="selectItem"
                color="primary"
            />
        </div>
        <div className={cnClassName('ImgBox')}>
            <Avatar
                className={cnClassName('Image')}
                imgUrl={data.imgUrl}
                size={64}
            />
        </div>
        <div className={cnClassName('Info')}>
            <div className={cnClassName('Title')}>
                <Button theme={'unstyled'} type={'rlink'} href={`/TrackManage/${data.tagId}`}>{data.tagName}</Button>
                {/* {data.tagName} */}
            </div>

            <div className={cnClassName('StatOne')}>
                <div className={cnClassName('PublicationsCount')}>{tTr('item.publicationsCount')} {data.publicationsCount}</div>
                <div className={cnClassName('UsersCount')}>{tTr('item.usersCount')} {data.usersCount}</div>
            </div>
            {/* <AuthorsList size={32} authors={course.authors} className={cnClassName('AuthorsList')} limit={5} /> */}
        </div>

        <div className={cnClassName('ActionBox')}>
            <SplitButton<IWithLoadSpin & IButtonProps> isOpen={() => { }} className={cnClassName('ActionMenu')}
                // button={<Button target={'_blank'} theme={'unstyled_center'} className={cnClassName('ActionBtn')}>
                //     <Icon icon={'cog'} />
                // </Button>}
                ItemComponent={ConfirmBtn}
                options={actions} />
        </div>
        {/* <Button className={cnClassName('Start')} href={`/TeacherOffice/Course/${course.id}`} type="rlink">
                    Подробнее
            </Button> */}
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
