import uuid from 'uuid/v1';

export const createFile = (gid: number, name: string) => {
    return {
        date: (new Date).toISOString(),
        displayUserName: "",
        fileId: "00000000-0000-0000-0000-000000000000",
        groupId: gid,
        groupName: null,
        highlight: null,
        id: uuid(),
        isFolder: false,
        isLastVersion: false,
        itemsCount: 0,
        name,
        newsUid: [],
        parentFolder: "00000000-0000-0000-0000-000000000000",
        previewAction: "None",
        previewImgId: "00000000-0000-0000-0000-000000000000",
        previewUrl: "00000000-0000-0000-0000-000000000000",
        urlname: null,
        userId: "00000000-0000-0000-0000-000000000000",
        version: 0,
        versions: null,
    }
}