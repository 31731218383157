// import React, { FC, useState } from 'react';
// import './WikiAuthorList.scss';
// import { IAuthorsListProps, cnAuthorsList } from './WikiAuthorList.index'
// import { AuthorItem } from './AutorItem/AuthorItem'
// // import { More } from './More/More'

// export const AuthorsList: FC<any> = ({ className, authors, limit = -1, more = false }) => {
//     const [show, setShow] = useState(false)

//     let shortList = limit !== -1 ? authors.slice(0, limit) : authors
//     if (show) shortList = authors
//     const showButton = authors.length > limit
//     return (
//         <div className={cnAuthorsList({}, [className])}>
//             <div className={cnAuthorsList('Wrapper')}>
//                 {shortList.map((author: any) => <AuthorItem className={cnAuthorsList('Author')} author={author} />)}
//                 {/* {(more && showButton) && <More className={cnAuthorsList('More')} onClick={() => setShow(!show)} />} */}
//             </div>
//         </div>
//     );
// };

export { AuthorsList } from 'uielements/src/WikiParts/WikiAuthorList/WikiAuthorList'