import React, { FC, useEffect, memo, useState } from 'react'
import {
    cnClassName,
    ISettingsDialogProps
} from './SettingsDialog.index'
import './SettingsDialog.scss'
import { createModal, defaultDialogProps } from 'utils/src/DialogCreator'
import Body from 'uielements/src/MaterialElements/DialogParts/DialogBody'
import Button from '@material-ui/core/Button';
import { ComponentInjector } from 'utils/src/ComponentInjector'
import { EditorState, RawDraftContentState, convertFromRaw, convertToRaw } from 'draft-js'
import { defaultBadge } from 'uielements/src/BadgeSelector/BadgeSelector.index'
import {
    API,
} from 'utils/src/utils'
import { useQuery, useLazyQuery } from 'utils/src/hooks'
import { v1 as uuid } from 'uuid';
import { Presenter } from '../Settings/Settings'
import { TStateSecret } from '../Settings/Settings.index'
import TextField from '@material-ui/core/TextField';
import { FormControl } from '@material-ui/core';
import { SecretSelect } from 'uielements/src/SecretSelect/SecretSelect'
import {
    ISecretsAPI
} from 'utils/src/requests/models/admin.secrets';
import { i18n } from "localization";
import { Alert, AlertTitle } from '@material-ui/lab';

enum EModes {
    /**
     * режим не выбран
     */
    NOT_SELECTED,

    /**
     * режим выбора существующих секретов
     */
    SELECT_SECRET,

    /**
     * режим создани нового серета
     */
    CREATE_SECRET
}

export const SettingsDialog: FC<ISettingsDialogProps> = ({
    children,
    className,
    handleAccept,
    handleClose,
    onChange,
    selected,
    data
}) => {
    const [mode, setMode] = useState<EModes>(EModes.NOT_SELECTED)
console.log('data', data)
    const [selectedSecret, setSelectedSecret] = useState<ISecretsAPI.SecretItem | null>(null)

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [pwd, setPwd] = useState<string>('')
    const [badge, setBadge] = useState<any>(defaultBadge)
    const [state, setState] = useState<TStateSecret>({
        plainText: data?.data?.plainText || '',
        buttonText: data?.data?.buttonText || '',
        id: data?.data?.id || uuid(),
        img: {
            id: data?.data?.img?.id !== "" ? data?.data?.img?.id : null,
            originalUrl: data?.data?.img?.originalUrl !== "" ? data?.data?.img?.originalUrl : null,
            previewUrl: data?.data?.img?.previewUrl !== "" ? data?.data?.img?.previewUrl : null
        },
        showImg: data?.data?.showImg,
        hideAfterOpening: data?.data?.hideAfterOpening,
        noPassword: !pwd
    })

    useEffect(() => {
        setState({
            ...state,
            noPassword: !pwd
        })
    }, [pwd])

    const [loadSecret, isLoadingSecret, secertResult, secretError] = useLazyQuery(() => API.pages.getWikiSecret(data?.data?.id || '', window.location.pathname.slice(1), pwd).r as any)

    const [sendSecret, isSendingSecret, saveResult, saveError] = useLazyQuery(() => API.pages.setWikiSecret(window.location.pathname.slice(1), {
        plainText: state.plainText,
        buttonText: state.buttonText,
        id: state.id,
        img: {
            id: state.img.id !== "" ? state.img.id : null,
            originalUrl: state.img.originalUrl !== "" ? state.img.originalUrl : null,
            previewUrl: state.img.previewUrl !== "" ? state.img.previewUrl : null
        },
        showImg: state.showImg,
        hideAfterOpening: state.hideAfterOpening,
        secret: {
            data: convertToRaw(editorState.getCurrentContent()),
            password: pwd,
            allowedAccess: [],
            badges: badge ? [badge] : [],
            markdownSecretText: ''
        }
    }).r)


    async function onAccept() {
        mode === EModes.CREATE_SECRET && await sendSecret()
        handleAccept({
            data: {
                ...(data?.data || {}),
                ...(mode === EModes.CREATE_SECRET ?
                    state :
                    {
                        plainText: selectedSecret!.plainText,
                        buttonText: selectedSecret!.buttonText,
                        id: selectedSecret!.id,
                        showImg: selectedSecret!.showImg,
                        img: selectedSecret!.img,
                        hideAfterOpening: selectedSecret!.hideAfterOpening,
                        noPassword: !pwd

                    }
                )
            }
        })
    }
    const update = (value: any) => {
        // onChange({
        //     ...data,
        //     ...value
        // })
    }

    let showSecretData = Boolean(secertResult?.data?.secret)
    showSecretData = data?.data?.id ? showSecretData : true
    showSecretData = mode === EModes.SELECT_SECRET ? Boolean(selectedSecret) : showSecretData

    const s = mode === EModes.CREATE_SECRET && !showSecretData

    const acceptAction = s ? loadSecret : onAccept

    const acceptText = s ? i18n.t(`pryaniky.editor.secrets.edit.open`) : i18n.t(`pryaniky.editor.secrets.edit.save`)

    const header = data?.data?.id ? i18n.t(`pryaniky.editor.secrets.edit.editsecretwidget`) : i18n.t(`pryaniky.editor.secrets.edit.createsecretwidget`)

    const editBtn = data?.data?.id ? i18n.t(`pryaniky.editor.secrets.edit.editsecret`) : i18n.t(`pryaniky.editor.secrets.edit.createsecret`)

    const selectBtn = data?.data?.id ? i18n.t(`pryaniky.editor.secrets.edit.selectanother`) : i18n.t(`pryaniky.editor.secrets.edit.seclectexist`)

    const isServerError = secretError || saveError

    return <Body
        header={header}
        onAccept={mode === EModes.NOT_SELECTED ? undefined : acceptAction}
        isLoading={s ? isLoadingSecret : isSendingSecret}
        disableAccept={s ? false : !showSecretData}
        onClose={handleClose}
        acceptText={acceptText}
        closeText={i18n.t(`pryaniky.editor.secrets.edit.close`)}
    >
        {isServerError &&
            <Alert severity="error" className={cnClassName('Alert')}>
                <AlertTitle>
                    {i18n.t(`pryaniky.editor.secrets.edit.error`)}
                </AlertTitle>
                <pre>
                    internal server error or connection error
                    secretError = {secretError}
                    saveError = {saveError}
                </pre>
            </Alert>
        }

        {mode === EModes.NOT_SELECTED && <FormControl fullWidth >
            <Button onClick={() => setMode(EModes.SELECT_SECRET)}>{selectBtn}</Button>
            <Button onClick={() => setMode(EModes.CREATE_SECRET)}>{editBtn}</Button>
        </FormControl>}

        {mode === EModes.SELECT_SECRET && <SecretSelect selectedSecret={selectedSecret} setSelectedSecret={setSelectedSecret} />}

        {mode === EModes.CREATE_SECRET && <>
            {!showSecretData ?
                <div className={cnClassName('Password')}>
                    <FormControl fullWidth >
                        <TextField
                            label={i18n.t(`pryaniky.editor.secrets.edit.password`)}
                            type={'password'}
                            InputProps={{ inputProps: { minlength: 1, maxlength: 100 } }}
                            value={pwd}
                            onChange={(e) => setPwd(e.target.value)}
                        />
                    </FormControl>
                    {secertResult?.error_code === 18001 &&
                        <Alert severity="error" className={cnClassName('Alert')}>
                            <AlertTitle>
                                {i18n.t(`pryaniky.editor.secrets.edit.error`)}
                            </AlertTitle>
                            {i18n.t(`pryaniky.editor.secrets.edit.wrongpassworderror`)}
                        </Alert>
                    }
                </div> :

                <Presenter
                    setValid={() => { }}
                    onChange={update}
                    selected={selected}
                    data={data?.data}
                    setEditorState={setEditorState}
                    setPwd={setPwd}
                    setBadge={setBadge}
                    setState={setState}
                    state={state}
                    pwd={pwd}
                    badge={badge}
                    editorState={editorState}
                    isLoadingSecret={isLoadingSecret}
                    secertResult={secertResult}
                    showSecretData={showSecretData}
                />}
        </>}
    </Body>
}


export const selectDialog = createModal(SettingsDialog, {
    ...defaultDialogProps,
    fullScreen: false,
    disableRouter: true
    // maxWidth: "sm"
})

export const initWidgetSettingsDialog = () => ComponentInjector.getInstance().addMethod('WSettings', selectDialog, 'pryaniky/secretwidget')
export const initWidgetAdditionDialog = () => ComponentInjector.getInstance().addMethod('WAddition', selectDialog, 'pryaniky/secretwidget')
