import React, { Component } from 'react';
import {
  cnMountainRating,
  mapStateToProps,
  IMountainRatingProps,
  IMountainRatingState,
  IMountainRatingUserInfo,
  typeofHandleScroll,
} from './MountainRating.index';
import { connect } from 'react-redux';
import { mapDispatchToProps } from 'redux/connector';
import { ILeaderboardResponse } from 'utils/src/requests/models/api.ratings';
import { ScrollBar } from './ScrollBar/MountainRating-ScrollBar';
import { Bar } from './Bar/MountainRating-Bar';
import { API, checkResponseStatus } from 'utils/src/utils';
import { toast } from 'react-toastify';
import i18n from 'localizations/i18n';
import { Filters } from './Filters/MountainRating-Filters';
import { Spinner } from 'uielements/src';
import { Background } from './Background/MountainRating-Background';
import './MountainRating.scss';

const desiredBarWidth = 60;

export class MountainRatingPresenter extends Component<IMountainRatingProps, IMountainRatingState> {
  public state: IMountainRatingState = {
    users: [],
    usersOffset: 0,
    usersPerPage: 0,
    isFinished: false,
    isLoading: false,
    backgroundLoading: true,
    filters: [],
  };

  private activeFilter: any;

  private fieldRef = React.createRef<HTMLDivElement>();
  // private activeFilter: number | undefined;

  public componentDidMount() {
    this.getData();
    this.setUsersPerPage();
    window.addEventListener('resize', this.setUsersPerPage);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.setUsersPerPage);
  }

  public componentDidUpdate() {
    if (
      this.state.users.length <= this.state.usersOffset + this.state.usersPerPage + 5 &&
      !this.state.isFinished &&
      !this.state.isLoading
    ) {
      this.getData();
    }
  }

  public render() {
    const { rating } = this.state;
    const fieldWidth: number = (this.fieldRef.current && this.fieldRef.current.clientWidth) || 0;
    const barWidth = Math.floor((fieldWidth - this.state.usersPerPage) / (this.state.usersPerPage + 1));
    const lastVisibleUserPos = this.state.usersOffset + this.state.usersPerPage;
    const visibleBars: Array<IMountainRatingUserInfo | undefined> = this.state.users.slice(
      this.state.usersOffset,
      lastVisibleUserPos
    );
    for (let i = visibleBars.length; i < this.state.usersPerPage; i++) {
      visibleBars.push(undefined);
    }
    const myPosition = this.state.users.findIndex((user: IMountainRatingUserInfo) => user.id === this.props.uid);
    const type = rating && rating.ratingType;
    if (lastVisibleUserPos - 1 < myPosition) {
      visibleBars[this.state.usersPerPage - 1] = this.state.users[myPosition];
    } else if (this.state.usersOffset > myPosition && visibleBars.length && myPosition > -1) {
      visibleBars[0] = this.state.users[myPosition];
    } else if (
      rating &&
      rating.name &&
      rating.iAmParticipant &&
      rating.ratingType === 'Common' &&
      !this.activeFilter &&
      myPosition === -1
    ) {
      visibleBars[this.state.usersPerPage - 1] = {
        id: this.props.uid,
        curValue: rating.curValue,
        value: rating.value,
        displayName: this.props.userName,
        pos: '<i class="Icon_chevron-double-right"></i>',
        percantage: rating.percantage,
        imgUrl: this.props.userPhoto,
        imgId: this.props.userPhotoId,
      };
    }
    const fieldHeight = (this.fieldRef.current && this.fieldRef.current.clientHeight) || 0;
    const minBarHeight = type === 'Group' ? 200 : 160;
    const rowHeight = Math.floor((fieldHeight - minBarHeight + 10) / 7);
    return (
      <div className={cnMountainRating()} tabIndex={0} onKeyDown={this.handleKeyDown} onWheel={this.handleWheel}>
        {this.state.isLoading && <Spinner fontSize={40} className={cnMountainRating('Spinner')} />}
        <div className={cnMountainRating('Field')} ref={this.fieldRef}>
          <Background />
          {rating && (
            <>
              <div className={cnMountainRating('Header')}>
                <h3 className={cnMountainRating('Title')}>{rating.name}</h3>
                {!!this.state.filters.length && (
                  <Filters options={this.state.filters} value={this.activeFilter} onChange={this.handleFilterChange} />
                )}
              </div>
              {/* <Scale maxValue={rating.maxValue || this.state.users[0].curValue} rowHeight={rowHeight} width={barWidth} /> */}
              <div
                className={cnMountainRating('Bars')}
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
                onTouchEnd={this.handleTouchEnd}>
                {visibleBars.map((bar, i) => (
                  <Bar
                    key={(bar && bar.id) || i}
                    data={bar}
                    width={barWidth}
                    containerHeight={fieldHeight}
                    isMe={!!(bar && bar.id === this.props.uid)}
                    type={type}
                    minHeight={minBarHeight}
                    paddingTop={rowHeight * 2}
                  />
                ))}
              </div>
            </>
          )}
        </div>
        {this.state.users.length > this.state.usersPerPage && (
          <ScrollBar
            itemsPerPage={this.state.usersPerPage}
            currentItem={this.state.usersOffset}
            totalItems={this.state.users.length}
            onScroll={this.handleScroll}
            width={barWidth * this.state.usersPerPage}
          />
        )}
      </div>
    );
  }

  private handleFilterChange = (option: any) => {
    this.activeFilter = option;
    this.getData(true);
  };
  private handleTouchStart = (e: React.TouchEvent) => {
    this.setState({ touchStartX: e.touches[0].clientX });
  };
  private handleTouchMove = (e: React.TouchEvent) => {
    if (this.state.touchStartX === undefined || !this.fieldRef.current) return;
    const diff = e.changedTouches[0].clientX - (this.state.touchStartX || 0);
    const switchWidth = this.fieldRef.current.clientWidth / this.state.usersPerPage;
    if (!(Math.abs(diff * this.state.usersPerPage) > switchWidth)) return;
    else {
      this.setState({ touchStartX: e.changedTouches[0].clientX });
      const value = Math.round(Math.abs((diff * this.state.usersPerPage) / switchWidth));
      const direction = diff < 0 ? 'right' : 'left';
      this.handleScroll({ direction, value });
    }
  };
  private handleTouchEnd = (e: React.TouchEvent) => {
    this.setState({ touchStartX: undefined });
  };

  // private setUsersPerPage = () => this.setState({usersPerPage: 10})

  private setUsersPerPage = () => {
    if (!this.fieldRef.current) return;
    this.setState({ usersPerPage: Math.floor((this.fieldRef.current.clientWidth - 150) / desiredBarWidth) });
  };

  private getData = (refresh?: boolean) => {
    this.setState({ isLoading: true });
    const opts: Record<string, any> = {
      rid: this.props.match.params.id,
      gid: this.activeFilter && this.activeFilter.value,
      count: refresh && this.state.users.length > 100 ? this.state.users.length : 100,
      skipCount: refresh ? 0 : this.state.users.length,
    };
    API.ratings.leaderboard(opts).r.then((response) => {
      if (!checkResponseStatus(response)) {
        toast.error(i18n.t('error_server'));
        this.setState({ isLoading: false });
      } else {
        const usersWithPos = response.data.users.map((user, i) => ({
          ...user,
          pos: ++i + (refresh ? 0 : this.state.users.length) + '',
        }));
        this.setState(p => ({
          users: refresh ? usersWithPos : p.users.concat(usersWithPos),
          rating: response.data.rating,
          usersOffset: refresh ? 0 : p.usersOffset,
          isFinished: response.isFinished,
          isLoading: false,
          filters: refresh
            ? p.filters
            : (response.data.rating.filters || []).map(filter => ({
                label: filter.name,
                options: filter.groups.map(group => ({ value: group.pkid, label: group.name })),
              })),
        }));
      }
    });
  };

  private handleWheel = (e: React.WheelEvent) => {
    if (e.deltaY !== 0) return;
    if (e.deltaX > 0) this.handleScroll({ direction: 'right', value: 1 });
    else if (e.deltaX < 0) this.handleScroll({ direction: 'left', value: 1 });
  };

  private handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 39) this.handleScroll({ direction: 'right', value: 1 });
    else if (e.keyCode === 37) this.handleScroll({ direction: 'left', value: 1 });
  };

  private handleScroll: typeofHandleScroll = to => {
    const { users, usersOffset, usersPerPage } = this.state;
    if (to.direction === 'right' && usersOffset < users.length - usersPerPage) {
      this.setState(p => ({
        usersOffset:
          p.usersOffset + (to.value - this.zeroConstraint(usersOffset + to.value + usersPerPage - users.length)),
      }));
    } else if (to.direction === 'left' && usersOffset > 0) {
      this.setState(p => ({
        usersOffset: p.usersOffset - (to.value - this.zeroConstraint(to.value - usersOffset)),
      }));
    }
  };

  private zeroConstraint = (v: number) => (v > 0 ? v : 0);
}

export const MountainRating = connect(
  mapStateToProps,
  mapDispatchToProps({})
)(MountainRatingPresenter);
