import { ISurveyState } from './interfaces'
export const surveyModel = {
    "questions": [
        {
            "id": "72650570-3666-11ec-9a3e-e5a740acaf39",
            "position": 0,
            "text": "Первый вопрос",
            "description": "",
            "qtype": "Single",
            "randomizeAnswerPositions": false,
            "randomizeQuestionPosition": false,
            "score": 1,
            "allowSkip": false,
            "tags": null,
            "answers": [
                {
                    "id": "777fefc0-3666-11ec-9a3e-e5a740acaf39",
                    "text": "Верный",
                    "answerCommentText": "",
                    "description": "",
                    "numericMin": 0,
                    "numericMax": 0,
                    "isRightAnswer": true,
                    "score": 1,
                    "position": 0,
                    "tags": "",
                    "numericAnswer": null,
                    "isChecked": false
                },
                {
                    "id": "7993dba0-3666-11ec-9a3e-e5a740acaf39",
                    "text": "Ты не угадал!",
                    "answerCommentText": "",
                    "description": "",
                    "numericMin": 0,
                    "numericMax": 0,
                    "isRightAnswer": false,
                    "score": 0,
                    "position": 1,
                    "tags": "",
                    "numericAnswer": null,
                    "isChecked": false
                }
            ],
            "hasRightAnswer": true
        }
    ],
    "groups": [
        {
            "pkid": 70100,
            "name": "Все пользователи",
            "alias": null,
            "systemGroupName": "all",
            "description": "System group all users",
            "descriptionPlainText": null,
            "isSystemGroup": true,
            "isHidden": true,
            "isDeleted": false,
            "isMeInGroup": false,
            "isOfficialGroup": false,
            "isRequestAccessSent": false,
            "isArchived": false,
            "defaultNotifyTemplate": "common",
            "visibilityType": 5,
            "childGroupCount": 0,
            "membersCount": 270,
            "imgId48x48": "00000000-0000-0000-0000-000000000000",
            "imgId198x198": "00000000-0000-0000-0000-000000000000",
            "imgUrl48x48": "/Img/Icon/96x96/transparent/cccccc/users.png",
            "imgUrl198x198": "/Img/Icon/198x198/transparent/cccccc/users.png",
            "actions": [
                "edit",
                "delete",
                "archive"
            ],
            "groupType": null,
            "isContentDisabled": false,
            "showGroupInSubnetworks": false,
            "url": "/group/70100",
            "roorIdGroup": 0,
            "id": "af7a4174-82f0-4383-a65b-77d7cc1300fe",
            "displayName": "Все пользователи",
            "type": "group",
            "imgId": "00000000-0000-0000-0000-000000000000",
            "imgUrl": "/Img/Icon/198x198/transparent/cccccc/users.png"
        }
    ],
    "users": [],
    "result": {
        "id": "8467ec10-3666-11ec-9a3e-e5a740acaf39",
        "quizId": "00000000-0000-0000-0000-000000000000",
        "userAnswerLogId": "00000000-0000-0000-0000-000000000000",
        "badges": [],
        "score": 0,
        "tags": "",
        "header": "",
        "text": "Ты не угадал - ты проиграл!",
        "allowPostNews": true,
        "allowRetryQuiz": false,
        "isActive": true,
        "badgeRules": []
    },
    "id": "9ba102a0-3665-11ec-9a3e-e5a740acaf39",
    "name": "Отдых на пляже (викторина)",
    "text": "",
    "textShort": "",
    "highlightCorrectness": true,
    "isActive": true,
    "startDate": "2021-10-25T21:00:00.000Z",
    "endDate": "2121-10-26T11:10:01.491Z",
    "score": 1,
    "randomizeMarkedQuestions": false,
    "iconImgId": "ad608649-d5b6-4ad5-93ae-0c9ff3f78d8b",
    "iconImgUrl": "https://ideasperi-develop.prerelease.pryaniky.com/Img/Attachments/Preview/96m96/ad608649-d5b6-4ad5-93ae-0c9ff3f78d8b.png",
    "backgroundImgId": "00000000-0000-0000-0000-000000000000",
    "backgroundImgUrl": null,
    "backgroundColor": 16777215,
    "textColor": 0,
    "selectRandomQuestionCount": -1,
    "textColorString": "#000000",
    "backgroundColorString": "#ffffff",
    "maxAttemptCount": 100,
    "surveyType": "Score",
    "addQuizButtonToNews": true,
    "quizButtonText": "",
    "userIds": [],
    "groupIds": [
        70100
    ]
}
export const defaultSurvey: ISurveyState = {
    surveyWidget: {
        surveyData: [],
        activeSurveyIdx: 0,
        isLoadingSurveys: false,
    },
    survey: {
        activeSurveyIdx: 0,
        openSurveyData: {},
        activeQuistionId: '',
        // activeQuistionIdx: 0,
        started: false,
        answersIds: [],
        textAnswers: "",
        attachments: [],
        rows: [],
        validMatrix: false,
        numericAnswer: "",
        isFree: false,
        answerDate: null,
        answered: false,
        finished: false,
        rightAnswerCount: 0,
        loading: false,
        userAnswerLogId: '',
        result: {},
        isShowResult: false,
        questionsResults: {
            questions: {},
            questionsIds: [],
            results: {},
            resultsIds: [],
            loading: false
        },
    },
    surveyStartLoading: false,
    surveyPassingLoading: false,
    surveyByPages: null,
    surveyPassing: null,
    firstQuestionError: null,
    surveyPassingAnswers: {},
    surveyRezult: null
};