import React, { FC, useEffect, memo, useState, useCallback } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News,
    IBanner
} from './interfaces'
import { connect, useDispatch, useSelector } from 'react-redux';
import './style.scss'
import { Button, Icon } from 'uielements/src'
import { withNewsContext } from 'News/contexts/news'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import { arrayRotate } from 'utils/src/utils'
import { useRefedCallback } from 'utils/src/hooks'
import Element from './Element'
import i18n from 'localizations/i18n';

type IPresenterProps = News.NewsBanner.IPresenterProps
type IOwnProps = News.NewsBanner.IOwnProps
type IDispatchProps = News.NewsBanner.IDispatchProps
type IStateProps = News.NewsBanner.IStateProps
type IState = News.NewsBanner.IState

const useBannerSlider = (banners: IBanner[], length: number = 3) => {
    const [index, setIndex] = useState(0)

    const itemCount = banners.length
    const showNav = itemCount > length

    const next = useRefedCallback((deps) => () => {
        const { current: { itemCount, index } } = deps
        setIndex(index === (itemCount - 1) ? 0 : (index + 1))
    }, { itemCount, index })

    const prev = useRefedCallback((deps) => () => {
        const { current: { itemCount, index } } = deps
        setIndex(index === 0 ? (itemCount - 1) : (index - 1))
    }, { itemCount, index })

    const array = !showNav ? banners : arrayRotate(banners, index, length)

    return [
        array,
        {
            index,
            showNav,
            next,
            prev
        }
    ] as const
}

export const Presenter: FC<IPresenterProps> = ({
    children,
    id,
    banners,
    closed,
    count = 3
}) => {

    const [array, { next, prev, showNav }] = useBannerSlider(banners.filter(banner => !closed.includes(banner.id)), count);

    return <div className={cnClassName({}, [])}>
        {/* <div className={cnClassName('Header')}>
            {i18n.t(`pryaniky.post.banners.header.title`)}
        </div> */}
        <div className={cnClassName('Content')}>
            <div className={cnClassName('NavBox', { side: 'left' })}>
                {showNav && <Button className={cnClassName('NavBtn', { side: 'left' })} theme={'unstyled'} onClick={prev}>
                    <Icon icon={'angle-left'} className={cnClassName('NavIcon')} />
                </Button>}
            </div>

            {array.map(banner => <Element key={banner.id} banner={banner} className={cnClassName('Banner')} />)}

            <div className={cnClassName('NavBox', { side: 'right' })}>
                {showNav && <Button className={cnClassName('NavBtn', { side: 'right' })} theme={'unstyled'} onClick={next}>
                    <Icon icon={'angle-right'} className={cnClassName('NavIcon')} />
                </Button>}
            </div>
        </div>
    </div>
}

export const BannerWithoutRedux = withNewsContext(memo(Presenter));

export const Banner = withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));

export default () => ComponentInjector.getInstance().addNode('news_types', <Banner />, 'slidernews')