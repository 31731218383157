import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { IStateType } from '../../../redux/store';
import { INewsListRequestOpts } from 'utils/src/requests/models/api.news';
import {
    loadNewsList,
    reset,
    setActiveNews
} from '../../redux/actions';
import {
    getNewsKeys,
    getNewsParams,
    getReadingState,
    getNewsActiveByParams
} from '../../redux/saga/selectors';
import queryString from 'query-string';

const newsParams = [
  'viewType', 'sending'
] as const


// export const mapStateToProps = (state: any, props: IOwnProps) => ({
//   ...getNewsParams(newsParams)(state),
//   keys: getNewsKeys(queryString.stringify(props.params))(state),
//   active: getNewsActiveByParams(queryString.stringify(props.params))(state)
// })

export const mapVoteStateToProps = (state: IStateType, props: IVoteView.OwnProps) => ({
  ...getNewsParams(newsParams)(state),
  ...getReadingState(props.params)(state),
  keys: getNewsKeys(props.params)(state),
});

export const mapVoteActionToProps = (d: Dispatch<Action>) => bindActionCreators({
  loadNewsList
}, d);

export namespace IVoteView {

  export type ImapVoteStateToProps = ReturnType<typeof mapVoteStateToProps>;

  export type ImapVoteActionToProps = ReturnType<typeof mapVoteActionToProps>;

  export interface OwnProps {
    params: INewsListRequestOpts;
  }

  export interface Props extends IClassNameProps, OwnProps, ImapVoteStateToProps, ImapVoteActionToProps {};

}

export const cnVoteView = cn('VoteView');