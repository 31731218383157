import React, { FC, useEffect, useMemo, useState, memo, useCallback, useRef } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import { createDefaultModel } from '../EventsType'
import NewsEditor from 'News/containers/NewsEditor'
import { useDebounce } from 'utils/src/hooks'
import { EditorState, RawDraftContentState, convertToRaw, convertFromRaw } from 'draft-js';
import uuid from 'uuid/v1';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
import { NodeBulder } from 'uielements/src/CommonmarkRender'
import PryanikyEditor from 'uielements/src/PryanikyEditorV1'
import { markdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap'
import { findMentions } from 'uielements/src/PryanikyEditorV1/MentionSelectPlugin/modifiers/findMentions'

type IPresenterProps = News.NewsCreator.IPresenterProps
type IOwnProps = News.NewsCreator.IOwnProps
type IDispatchProps = News.NewsCreator.IDispatchProps
type IStateProps = News.NewsCreator.IStateProps
type IState = News.NewsCreator.IState

const toolBarStyle = { top: 55 }
const noopFn = () => { }

export const Presenter: FC<IPresenterProps> = ({
    className,
    pathCreator,
    currentType,
    postType,
    currentNews,
    pathCreatorNews,
    sendNews,
    currentUser
}) => {
    const [actions, setActions] = useState<React.ReactNode>(<></>)
    const [state, setState] = useState<EditorState>(EditorState.createEmpty())
    const [search, setSearch] = useState(false)
    useEffect(() => {
        const defaultModel = createDefaultModel();
        pathCreatorNews({
            ...defaultModel,
            user: {
                id: currentUser.baseData.id
            }
        })
    }, [currentType])

    const ref = useRef(currentNews)
    ref.current = currentNews
    const stateRef = useRef(state)
    stateRef.current = state

    const onSubmit = useCallback(() => {
        const text = draftToMarkdown(convertToRaw(stateRef.current.getCurrentContent()), rawToMd);
        sendNews({ 
            ...ref.current, 
            id: uuid(),
            text
        })
    }, [])


    return <NewsEditor actions={actions} onSubmit={onSubmit} className={cnClassName({}, [className])}>
        event
    </NewsEditor>
}

export const Connected = connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));

export const initEventNews = () => ComponentInjector.getInstance().addNode('news_creator', <Connected />, 'events')