import { bindActionCreators } from 'redux';
import { loadList, uploadFiles, selectAllFiles, deleteFileMultiple, moveFile, createFolder } from "../../../redux/actions/Files";
import { cn } from '@bem-react/classname';
export interface IProps extends IBaseFileProps, IFileStateProps, ReturnType<typeof mapDispatchToProps> {
    gid: any;
}

export interface IBaseFileProps {
    gid: number;
    showControls?: boolean;
    openFileWindow: () => void;
    showMove:() => void
    className?: string;
}

export interface IFileStateProps {
    files: any[],
    count: number;
    isFinished: boolean;
    isLoading: boolean;
    selected: any[];
    currentFolder: string;
    filters: any;
}

export const mapStateToProps = (state: any, props: IBaseFileProps) => {
    let files = []
    let count = 0;
    let isFinished = false;
    let isLoading = false;
    let selected = [];
    let filters = {}
    let currentFolder: string = "00000000-0000-0000-0000-000000000000"
    if (props.gid in state.files.groups) {
        files = state.files.groups[props.gid].files.map((id: string) => state.files.files[id])
        count = files.filter((file: any) => !file.isBack).length;
        selected = state.files.groups[props.gid].selected;
        isFinished = state.files.groups[props.gid].isFinished;
        isLoading = state.files.groups[props.gid].isLoading;
        currentFolder = state.files.groups[props.gid].currentFolder || "00000000-0000-0000-0000-000000000000"
        filters = state.files.filters
    }
    return { files, count, selected, isFinished, isLoading, currentFolder, filters }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadList,
    uploadFiles,
    selectAllFiles,
    deleteFileMultiple,
    moveFile,
    createFolder
}, dispatch);

export const cnHeader = cn('FilesHeader');