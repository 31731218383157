import Component, { Presenter } from './component';
import {
    ITestInfoLayoutOwnProps,
    ITestInfoLayoutProps,
    cnClassName
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = ITestInfoLayoutOwnProps;
export type IComponentPresenterProps = ITestInfoLayoutProps;


export default Component