/**
 * @packageDocumentation
 * @module Widget_Settings_ratingmini
 */
import { JFdictionary } from "../dictionary";
import i18n from "localizations/i18n";
import { store } from "../../../../redux/store";
import {
    getSecretSantaWidgetSettingsAction
} from 'redux/sagas/SecretSanta/actions'
const { types } = JFdictionary

export let additionalSchemaData: any = {
  type: types.string,
  placeholder: i18n.t('pryaniky.widgets.settings.placeholder.hierarchy'),
  opts: [],
  data: [],
  require: true
}

/**
 * инкапсулируем все эти грязные делишки в сагу.
 * из WSettings просто дёргаем событие.
 * пока что такая, костальная реализация добавления события... очень много спагети
 * @param data 
 */
 const count = 100
 const skipCount = additionalSchemaData?.data.length || 0
 export const prepareData = (data: any) => {
  store.dispatch(getSecretSantaWidgetSettingsAction({count, skipCount}))
}

export const prepareOption = (title: string, value: string) => ({
  title,
  value,
})

export default {
  type: types.object,
  properties: {
      type: additionalSchemaData,
  }
}
