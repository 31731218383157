import { Dispatch, bindActionCreators, ActionCreatorsMapObject, AnyAction } from 'redux'; 
import { IStateType as IState } from 'redux/store';
import { MapStateToPropsFactory } from 'react-redux';
import actions from 'utils/src/CommonRedux/base/actionsTypes';


// import { connect } from 'react-redux';

export const stateToProps = (state: IState) => ({
  store: state.store,
  router: state.router,
});

export const mapDispatchToProps = <T = any>(functions: ActionCreatorsMapObject<T>) => (dispatch: Dispatch) => {
  return {
    dispatch,
    changeVm: (propPath: string, value: any) => {
      dispatch({
        type: actions.CHANGE_STATE, payload: { propPath, value } 
      });
    },
    ...bindActionCreators<ActionCreatorsMapObject<T>, ActionCreatorsMapObject<T>>(functions, dispatch),
  };
};

export type IStateProps = ReturnType<typeof stateToProps>;

export type IDispatchProps = ReturnType<ReturnType<typeof mapDispatchToProps>>;

// export interface IDispatchProps {
//   changeVm: (path: string, value: any) => void,
//   dispatch: Dispatch<AnyAction>
// }
