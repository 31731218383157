import React from 'react'
import './QuizStart.scss'
import { cnQuizStart, IQuizStart, mapStateToProps, mapDispatchToProps } from './QuizStart.index'
import { Button } from 'uielements/src/Button/Button'
// import { PostContentRender } from '../../PostContentRender/PostContentRender';
import { connect } from 'react-redux'
import i18n from 'localizations/i18n'
import MDRender from 'uielements/src/CommonmarkRender'
import { Box } from 'muicomponents/src'
import {
  ErrorOutline
} from 'muicomponents/src/Icons/Icons';
import { styled } from 'muicomponents/src/mui/system';

export const ErrorOutlineIcon = styled(ErrorOutline)({
  fontSize: 18.33,
  color: "#fff"

});
const QuizStartPresenter = ({ quizId, rewardIsPosted, text, setStartedQuiz }: IQuizStart) => {

  return (
    <div className={cnQuizStart()}>
      <MDRender source={text || ''} textCut={false}/>
      {rewardIsPosted&& <Box className={cnQuizStart('Warning')}>
              <ErrorOutlineIcon />
              <span>{i18n.t('pryaniky.quiz.retry.warning')}</span>
            </Box>}
      <Button
        type='button'
        main
        onClick={() => setStartedQuiz(true)} 
      >
        {i18n.t('pryaniky.quiz.startQuiz')}
      </Button>
    </div>
  )
}

export const QuizStart = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuizStartPresenter)