/**
 * @packageDocumentation
 * @module List_type_users
 */
import { IListProps } from '../../List.index';
import { filters } from 'utils/src/filters.prn';

import { IDispatchProps } from '../../../../redux/connector';
import { IBaseUser } from 'muicomponents/src/UsersListDialog/UsersListDialog.index';
import { UserListItemActionsButtonUnion } from 'utils/src';
import { IAdditionalField } from 'muicomponents/src/FieldRender';

export interface IDefaultRequestOption {
  search: string,
}

export interface IListUsersDefaultRequestProps extends IDefaultRequestOption {
  catFilter: string,
  excludeGroup: number,
  rid: string,
  gid: number,
  extended: boolean,
  count: number,
  skipCount: number
}

// export interface IListUsersDefaultProps {
//   requestOptions: IListUsersDefaultRequestProps;
// }

export interface IListTypeUsersProps extends IListProps, IDispatchProps {
  requestOptions: any;
  isGroupAdmin?: boolean
  tag?: 'div';
}

// export interface IListTypeUsersState {
//   text?: string;
// }

// export interface IListStateProps {
//   store: any
// }

// export const mapStateToProps = (state: any) => ({
//   store: state.store
// })

const fltrs = {
  ...filters,
  usersCatFilter: 'catFilter',
  userBlock: 'userBlock',
  usersRole: 'role',
  type: 'catFilter', // type
  gId: 'gid', // group id
  groupPKID: 'gid', // group id
  nId: 'nId', // news id
  aId: 'aId', // poll answer id
  bId: 'bid', // badge id
  rId: 'rid', // rating id
  cId: 'rId', // comment id
  tId: 'tagId', // comment id
  status: 'status', // event status
  collectionType: 'collectionType',
  shopAccess:"shopAccess",
  arfilter: 'filter', // filter to accessrequests
  units: 'units'
}

export type IFLTRS = typeof fltrs & { [s: string]: string };

export const avalibleFilters = Object.keys(fltrs).reduce((acc, cur) => acc[cur] === '' ? { ...acc, [cur]: cur } : acc, fltrs as IFLTRS);






// new types


export type TItemType = IBaseUser & { url: string, actions: string[], actionButtons: UserListItemActionsButtonUnion[], fields?: IAdditionalField[] }