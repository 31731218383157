import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import {
    sendLikeNews
} from 'News/redux/actions'
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';

export interface ILikeActionProps extends IClassNameProps, ILikeActionStateProps, IDispatchProps, RouteComponentProps, ILikeActionDispatchProps {
    tag?: 'div';
    nid: string;
}

export interface ILikeActionState {
}

export interface ILikeActionStateProps {
}

export const mapStateToProps = (state: any, props:any) => {
    if(!state.news.news[props.nid]) return {
        likesCount: 0,
        isLiked: false,
        noLoad: true
    }
    return {
        likesCount: state.news.news[props.nid].likeCount,
        isLiked: state.news.news[props.nid].liked,
        noLoad: false
    }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    sendLikeNews
}, dispatch);

export type ILikeActionDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnLikeAction = cn('LikeAction');