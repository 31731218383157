import { IBaseNews, createDefaultNews } from 'News/types/baseNews'
export interface QuestStepNews extends IBaseNews{
    newstype: 19;
}

export interface QuestStepNewsCreate extends QuestStepNews {
    questStep: QuestStep;
}

export interface QuestStep {
    id: number;
    isCompleteByMe: boolean;
    isFailedByMe: boolean;
    name: string;
    comment: string;
    popupdesc: string;
    infourl: string;
    pushed: boolean;
    type: number;
    bonus: number;
    popupShow: boolean;
    selected: boolean;
}

export const defaultQuestStep: QuestStep = {
    id: -1,
    isCompleteByMe: false,
    isFailedByMe: false,
    name: '',
    comment: '',
    popupdesc: '',
    infourl: '',
    pushed: false,
    type: 0,
    bonus: 0,
    popupShow: false,
    selected: false,
}


export const defaultQuestStepNews: QuestStepNews = {
    ...createDefaultNews(),
    newstype: 19,
}

export const defaultQuestStepNewsCreate: QuestStepNewsCreate = {
    ...defaultQuestStepNews,
    questStep: defaultQuestStep
}