import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';

export const board = (): IWidget<IColumn[]> => {
    return generateWidget<IColumn[]>({
        type: widgets.types.layout,
        data: [
            generateColumn({
                items: [
                    generateWidget({ type: widgets.types.ratingmini, }),
                    generateWidget({
                        type: widgets.types.layout,
                        data: [
                            generateColumn({
                                items: [
                                    generateWidget({ type: widgets.types.ratingmini, }),
                                    generateWidget({ type: widgets.types.ratingmini, }),
                                ],
                                styles: {
                                    width: '49%',
                                },
                            }),
                            generateColumn({
                                items: [
                                    generateWidget({ type: widgets.types.ratingmini, }),
                                    generateWidget({ type: widgets.types.ratingmini, }),
                                ],
                                styles: {
                                    width: '51%',
                                },
                            }),
                        ]
                    }),
                ],
                styles: {
                    width: '100%',
                },
            }),
        ],
    });
}