import * as serviceWorker from './serviceWorker';
import 'utils/src/debug';
// import './debugVariables';

import render from 'render';
import renderInDiv from 'render.in.div';
import renderInFrame from 'render.in.iframe';
import { store } from 'redux/store';
import actions from 'utils/src/CommonRedux/base/actionsTypes';
import { v1 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import INITLms from './LMSModule/init'
import INITNews from './News/init'
import { setPopState } from 'utils/src/onPopStateRegistry'
import { loadExternalModule } from 'utils/src/loadExternalModule'

(window as any).setAuthCookie = (cookie: string) => {
  cookie.split(';').forEach(e => document.cookie = e.trim())
};
(window as any).ptoast = (type: string, text: string) => (toast as any)[type](text);

store.subscribe(() => {
  if (store.getState().i18n.namespace !== (window as any).currentNamespace) (window as any).currentNamespace = store.getState().i18n.namespace;
});

INITNews(store)
INITLms(store);

loadExternalModule({
  app: 'main',
  id: 'tracksactions_lib',
  endpoint: 'tracksactions_lib',
  jsUrl: '/static/js/tracksactions.bundle.js',
  store,
  cssUrl: '/static/css/tracksactions.css',
  requireEnabled: 'learningtracks',
  adminOnly: true
})


const root = document.getElementById('pryaniky-root-DOM') as HTMLElement;
const rootFrame = document.getElementById('pryaniky-root-frame') as HTMLElement;

const l = document.createElement('link');
l.setAttribute('rel', 'icon');
l.setAttribute('type', 'image/png');
l.setAttribute('href', `/icon/32/favicon.png?v=${uuid()}`);
document.head.append(l);

if (root) {
  render();
} else
  if (rootFrame) {
    renderInFrame();
  } else {
    renderInDiv();
  }
serviceWorker.unregister();

setPopState('CHANGE_STATE', () => {
  store.dispatch({ type: actions.CHANGE_STATE, payload: { propPath: '', value: { edit: false } } })
})
// window.onpopstate = () => {
//   store.dispatch({ type: actions.CHANGE_STATE, payload: { propPath: '', value: { edit: false } } })
// }

//submodule hotfix #1