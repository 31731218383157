import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { setStudentsFilters } from '../../../../redux/actions/LMSTeacherOffice'

export interface IDeletedProps extends IClassNameProps, IDeletedStateProps, IDispatchProps, RouteComponentProps, IDeletedDispatchProps {
    tag?: 'div';
}

export interface IDeletedState {

}

export interface IDeletedStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    currentFilters: state.LMSTO.students.filters['filter.getDeleted']
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    setStudentsFilters
}, dispatch);

export type IDeletedDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnDeleted = cn('DeletedFilter');