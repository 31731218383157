import React, { FC, ComponentProps, Fragment, createRef, useCallback, useRef, useMemo } from 'react';
import { TextField } from 'muicomponents/src/TextField';
import { Slide } from 'muicomponents/src/Transitions/Transitions';
import { Divider } from 'muicomponents/src/Divider';
import { Breadcrumbs } from 'muicomponents/src/Breadcrumbs';
import { Box } from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';
import { useLeftMenuSearch } from './LeftMenuSearch.hooks';
import { Search, NavigateNext, Close } from 'muicomponents/src/Icons';
import { LeftMenuItem } from '../Item/LeftMenuItem';
import { Translate } from 'localizations/Translate';
import './LeftMenuSearch.scss';
import { cnLeftMenuSearch } from './LeftMenuSearch.index';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { useLeftMenuStore } from '../LeftMenu.hooks';
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput';
import { getAppDesignColors } from 'utils/src/CommonRedux/base/selectors';
import { useSelector } from 'react-redux';

const StyledBox = styled(Box)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
}) as typeof Box;

const StyledSearchInput = styled(SearchInput)({
    margin: 16
}) as typeof SearchInput;

const StyledBar = styled('div')({
    overflowY: 'scroll',
    marginRight: -17
});

const StyledDivider = styled(Divider)({
    margin: 16
}) as typeof Divider;

const StyledBreadcrumbs = styled(Breadcrumbs)({
    margin: '0 16px'
}) as typeof Breadcrumbs;

const StyledBreadcrumbsItem = styled('span')({
    fontSize: 10,
    lineHeight: '14px'
});

export const LeftMenuSearch: FC<any> = ({

}) => {

    const {
        opened
    } = useLeftMenuStore();

    const {
        search,
        setSearch,
        searchedData
    } = useLeftMenuSearch();

    const inputRef = useRef<HTMLInputElement>();

    const onClearSearch = useCallback(() => {
        if(!inputRef.current) {
            console.error('clear search error because textAreaRef is undefined');
        }
        setSearch('');
        inputRef.current?.focus();
    }, []);

    const colors = useSelector(getAppDesignColors);

    const inputStyles = useMemo(() => {
        return {
            ['& .MuiInputAdornment-root, & .MuiFormLabel-root']: {
                color: `${colors?.additionalColor1} !important`
            },
            ['& .MuiOutlinedInput-notchedOutline']: {
                borderColor: `${colors?.additionalColor1} !important`,
            }
        };
    }, [colors]);
 
    return <StyledBox
        className={cnLeftMenuSearch()}
        sx={{
            height: search.length ? '100%' : undefined
        }}
    >
        <StyledSearchInput
            size={'small'}
            inputRef={(input) => {
                inputRef.current = input;
            }}
            fullWidth={false}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            label={<Translate i18nKey={'pryaniky.leftMenu.search'} />}
            className={'additionalColor1-text'}
            adornmentClasses={{
                start: 'additionalColor1-text',
                end: 'additionalColor1-text'
            }}
            InputLabelProps={{
                className: 'additionalColor1-text',
            }}
            InputProps={{
                className: 'additionalColor1-text',
            }}
            sx={inputStyles}
        />
        <Slide
            key='serachSlide'
            direction="down"
            mountOnEnter
            unmountOnExit
            in={!!search.length}
            onEnter={(el) => {
                el.style.zIndex = '5';
            }}
            onEntered={(el) => {
                el.style.zIndex = '';
            }}
            onExit={(el) => {
                el.style.display = 'none';
            }}
            >
            <StyledBar>
                {
                    searchedData.map((el, idx) => {
                        return (
                            <Fragment key={idx}>
                                {
                                    idx > 0 && 
                                    <StyledDivider className={'additionalColor1-bg'} />
                                }
                                <StyledBreadcrumbs
                                    className={'additionalColor1-text'}
                                    separator={<NavigateNext fontSize="small" />}
                                >
                                    {
                                        el.breadcrumds.map((el, idx) => {
                                            return (
                                                <StyledBreadcrumbsItem key={idx}>
                                                    {el}
                                                </StyledBreadcrumbsItem>
                                            )
                                        })
                                    }
                                </StyledBreadcrumbs>
                                {
                                    el.items.map(id => {
                                        return (
                                            <LeftMenuItem
                                                key={id}
                                                id={id}
                                                path={[]}
                                            />
                                        )
                                    })
                                }
                            </Fragment>
                        )
                    })
                }
            </StyledBar>
        </Slide>
    </StyledBox>
}