import { useDispatch } from "react-redux";
import { useDialogContext } from "utils/src/DialogCreator";
import { newsCreatorItemChange, newsCreatorItemNewsChange } from "./redux/actions.newsCreator";
import { NewsCreateItem, NewsCreateReducer } from "./redux/types.newsCreator";

export const useDraftEditorStyle = () => {

    const { isDialog } = useDialogContext([ 'isDialog' ]);

    return {
        minHeight: '72px',
        stickyToolbarOffset: isDialog ? 0 : undefined
    }
}

export const useChangeCreator = (id: keyof NewsCreateReducer) => {

    const dispatch = useDispatch();

    const changeCreator = (item: Partial<NewsCreateItem>) => {
        dispatch(newsCreatorItemChange({ id, item }));
    };

    return changeCreator;
};