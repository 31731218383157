import { Translate } from "localizations/Translate";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentUserIsAdmin } from "utils/src/CommonRedux/base/selectors";
import { StyledBox, StyledListItem, StyledListItemIcon, StyledSettingsButton, TooltipDiv } from "../Item/LeftMenuItem.styled";
import { Settings, Check, Close, SettingsOutlined } from 'muicomponents/src/Icons';
import { useLeftMenuStore } from "../LeftMenu.hooks";
import { cnLeftMenuItem } from "../Item/LeftMenuItem.index";
import { Box } from "muicomponents/src/Box/Box";

export const MenuEdit: FC<{}> = ({}) => {

    const userIsAdmin = useSelector(getCurrentUserIsAdmin);

    const {
        edit,
        opened,
        setEdit,
        setSave,
        setOpened
    } = useLeftMenuStore();

    const [ showCancelText, setShowCancelText ] = useState(false);
    const [ confirmCancel, setConfirmCancel ] = useState(false);

    if(!userIsAdmin) return null;

    return (
        <StyledListItem
            sx={{
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px'
            }}
            className={cnLeftMenuItem({}, [cnLeftMenuItem('Edit'), 'additionalColor1-text'])}
            secondaryAction={
                edit &&
                <StyledSettingsButton
                    onMouseEnter={() => {
                        setShowCancelText(true);
                    }}
                    onMouseLeave={() => {
                        setShowCancelText(false);
                        setConfirmCancel(false);
                    }}
                    onClick={() => {
                        if(!confirmCancel) {
                            setConfirmCancel(true);
                        } else {
                            setShowCancelText(false);
                            setConfirmCancel(false);
                            setSave(null);
                            setEdit(false);
                        }
                    }}
                    className={'additionalColor1-text'}
                    color="primary"
                    sx={{
                        display: 'inline-flex'
                    }}
                >
                    <Close />
                </StyledSettingsButton>
            }
        >
            <TooltipDiv>
                <StyledBox
                    onClick={(e) => {
                        e.stopPropagation();
                        if(!opened) {
                            setOpened(true);
                            return ;
                        }
                        if(!edit) {
                            setEdit(true);
                        } else {
                            setSave(new Date().toISOString());
                            setEdit(false);
                        }
                    }}
                    sx={{
                        paddingRight: !opened ? '24px' : undefined,
                    }}
                >
                    <StyledListItemIcon className={'additionalColor1-text'}>
                        {
                            edit
                            ? <Check />
                            : <SettingsOutlined />
                        }
                    </StyledListItemIcon>
                    <Box
                        className={cnLeftMenuItem('Name')}
                        sx={showCancelText ? {
                            marginLeft: 'auto',
                            marginRight: '8px',
                            textTransform: 'capitalize',
                        } : undefined}
                    >
                        {
                            edit
                            ? (
                                showCancelText
                                ? (
                                    confirmCancel
                                    ? <><Translate i18nKey={'shure'} />?</>
                                    : <Translate i18nKey={'pryaniky.leftMenu.edit.cancel'} />
                                )
                                : <Translate i18nKey={'pryaniky.leftMenu.edit.save'} />
                            )
                            : <Translate i18nKey={'pryaniky.leftMenu.edit'} />
                        }
                    </Box>
                </StyledBox>
            </TooltipDiv>
        </StyledListItem>
    );
};