import Component, { Presenter } from './component';
import {
    ICommentsCountOwnProps,
    ICommentsCountProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = ICommentsCountOwnProps;
export type IComponentPresenterProps = ICommentsCountProps;


export default Component