import { connect } from 'react-redux';
import * as React from 'react';
import * as utilsDep from 'utils.project/utils.project';
import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
import { cnActions, IActionsProps, IActionsState, mapStateToProps } from './Reply-Actions.index';
import './Reply-Actions.scss';
import { ReplyesContext } from 'blocks/Replyes/Replyes.context';
import { Bable } from 'blocks/Bable/Bable';
export class ActionsPresenter extends React.PureComponent<IActionsProps, IActionsState> {
  public static contextType = ReplyesContext;
  public context!: React.ContextType<typeof ReplyesContext>;
  public el: HTMLElement | null;

  private sendind: boolean = false;

  constructor(props: IActionsProps) {
    super(props);
    this.state = {
      liked: this.props.like.liked,
      likeCount: this.props.like.likeCount,
      rewardCount: 0,
      rewardValue: 0,
      rewardOpen: false
    };
  }

  public render() {
    const { children, className, user, additionalReplyActions, commentID } = this.props;
    const { liked, likeCount } = this.state;
    const isLikedClass = () => {
      return liked ? 'heart-fill' : 'heart';
    }
    return (
      <div ref={el => this.el = el} className={cnActions({}, [className])}>

        <Button theme='unstyled' onClick={this.actionAnswer}>{i18n.t('reply')}</Button>
        <Button theme='unstyled' onClick={this.actionLike}><Icon icon={isLikedClass()} />{i18n.t('like')}</Button>
        {likeCount !== 0 && <Button theme='unstyled' className={cnActions('BtnLikeNum')} onClick={this.actionLikedBy}
        ><Bable>{likeCount}</Bable></Button>}

        {additionalReplyActions && additionalReplyActions(commentID, user)}

        {children}
      </div>
    )
  }

  private actionLikedBy = () => {
    const invite = (data: { [s: string]: any }) => {
      // 
    }
    utilsDep.modal({
      header: i18n.t('likers'),
      type: 'likers',
      data: {
        nId: this.props.newsID,
        rId: this.props.commentID
      },
      okBtnAction: invite,
      okBtnText: i18n.t('ok')
    })
  }

  private actionAnswer = () => {
    const { displayName, id } = this.props.user;
    const userMarkupToMentions = ' [' + displayName + '](/user/' + id + ') ';
    this.context.appendFoo(userMarkupToMentions);
    this.context.textareaMentions && this.context.textareaMentions.focus();
  }

  private actionLike = () => {
    if (this.sendind) return;
    this.setState((s: IActionsState) => {
      s.liked = !s.liked;
      s.likeCount += !s.liked ? -1 : +1;
      return s;
    }, () => this.likeRequest(this.state.liked));
  }

  private likeRequest(liked: boolean) {
    this.sendind = true;
    utils.API.reply.action(this.props.newsID, this.props.commentID, 22, liked)
      .r
      .then((d: any) => {
        this.sendind = false;
        if (d.error_code === 0 && d.error_text === 'OK') {

        }
      })
  }
}


export const Actions = connect<ReturnType<typeof mapStateToProps>>(mapStateToProps)(ActionsPresenter)