import { BaseNews } from '../../../../BaseType'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
// import { newsServerValidate } from 'redux/actions/News';
import { bindActionCreators, Action, Dispatch } from "redux";
import { Rule } from '../../../../Base.validate'
export interface IEditorTypeNewsProps {
    data: BaseNews;
    types?: any;
    onChange: (data: BaseNews, validate: boolean, errors: Rule[]) => void;
}
export const mapStateToProps = (state: any) => ({
    maxThanksValue: state.store.appSettings.maxThanksValue,
    maxThanksArgumentsCount: state.store.appSettings.maxThanksArgumentsCount,
    userData: getCurrentUser(state),
    thanksForAll: getCurrentUser(state).extData.thanksForAll
})

export const mapTanksNewsActionsToProps =  (dispatch: Dispatch<Action>) => bindActionCreators({
    // newsServerValidate
}, dispatch);