import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IPriorityNavTab } from "../PriorityNav/PriorityNav.index";

export interface IItemTabsPageProps extends IClassNameProps {
  tag?: 'div';
  className?: string;
  store?: any;
  changeVm?: any;
  tabs: {
    active?: IPriorityNavTab;
    elements: IPriorityNavTab[];
    onChange: (selected: any) => void;
  };
  headerElem?: {[s: string]: any};
}

export interface IItemTabsPageState {
  text?: string;
}

export const cnItemTabsPage = cn('ItemTabsPage');