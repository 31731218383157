import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IOption {
    value: string;
    title: string;
    view?: any;
}

export interface IMultiSelectProps extends IClassNameProps {
    className?: string;
    placeholder?: string;
    options: IOption[]; // option array
    selected: string[]; // values of selected options
    checkbox: boolean; // eneble checkbox in menu
    icon?: string;
    onChange(selected: string[]): void; // callback
    invalid?: boolean;
    errorMessage?: string;
    required?:boolean;
}

export interface IMultiSelectState {
    text?: string;
    open: boolean;
}

export const cnMultiSelect = cn('MultiSelect');