import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';

import {
    getMeeting
} from 'blocks/NewsTypes/Events/redux/selectors'
import {
    setMeeting
} from 'blocks/NewsTypes/Events/redux/actions'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions'
///////////

export type IMeetingViewPresenterProps = IMeetingViewOwnProps & IMeetingViewStateProps & IMeetingViewDispatchProps

export interface IMeetingViewState { }

export interface IMeetingViewOwnProps extends IClassNameProps {

    // onChange: (question: any) => void
}

export interface IMeetingViewStateProps {
    meeting?: any;
    currentUser: any;
}

export type IMeetingViewDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IMeetingViewOwnProps) => ({
    meeting: getMeeting(state),
    currentUser: getAuthUser(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IMeetingViewOwnProps) => bindActionCreators({
    setMeeting
}, dispatch);

////////////

export const cnClassName = cn('MeetingView');