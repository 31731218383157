import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { IBodyProps } from 'utils/src/DialogCreator';
import { IStateType } from '../../../../redux/store';
import { NSubnetworksSettingsAPI } from 'utils/src/requests/models/admin.subnetworks';

export interface IChangeNetworkRezult {

};

export interface IChangeNetworkProps extends ReturnType<typeof mapChangeNetworkStateToProps>, IClassNameProps, IBodyProps<IChangeNetworkRezult> {
    id: string;
};

export const mapChangeNetworkStateToProps = (state: IStateType) => ({
    networkSettings: state.store.appSettings?.networkSettings as NSubnetworksSettingsAPI.AppSettings | undefined,
});

export const cnChangeNetwork = cn('ChangeNetwork');