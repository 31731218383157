import Component, { Presenter } from './component';
import {
    ITaskInfoOwnProps,
    ITaskInfoProps,
    cnClassName
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = ITaskInfoOwnProps;
export type IComponentPresenterProps = ITaskInfoProps;


export default Component