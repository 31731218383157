import React from 'react';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { cnWidget, IWidgetProps } from '../Widget.index';
import { widgets } from "i.widgets";
import { FiltersStatistic } from 'blocks/Filters/_type/Filters_type_statistic';
import { Statistic } from 'blocks/Statistic/Statistic';

const type = 'statistic';

export default class WidgetTypeStatisticFilters extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);

    return (
      <FiltersStatistic {...this.props as any} id={this.props.widget.id} data={this.props.widget.settings?.data} relations={this.props.widget.relations} context={this.props.wcontext} className={cnWidget({ type, subtype })} tag={widgets.components.common} edit={this.props.edit} />
    );
  }
}