import React from 'react';
import { INoticesProps, INoticesState, INoticesStateToProps, INoticesOwnProps, INoticesActionsToProps, NoticesActions, mapStateToProps } from './Notices.index';
import { Page } from 'blocks/Page/Page';
import { connect } from 'react-redux';
import { IStateType as IState } from 'redux/store';
import { pageNames } from 'redux/pageNames';

class NoticesPresenter extends React.Component<INoticesProps, INoticesState> {

  public componentDidMount() {
    // this.props.setContext({
    //   paths: ['common'],
    //   innerPath: '',
    //   value: {
    //     newsType: 'notices',
    //     postOff: true,
    //   },
    // })
  }

  public componentWillUnmount() {
    // this.props.updateContext('common', {
    //   newsType: undefined,
    //   postOff: false,
    // })
  }

  public componentDidUpdate(pp: INoticesProps) {}

  /**
   * render
   */
  public render() {
    return (
      <Page page={{
        name: pageNames.notices
      }} />
    )
  }
}

export const Notices = connect < INoticesStateToProps, INoticesActionsToProps, INoticesOwnProps, IState>(
  mapStateToProps,
  NoticesActions
)(NoticesPresenter)