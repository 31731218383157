import Component, { Presenter } from './component';
import {
    SUPPORT,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = SUPPORT.IOwnProps;
export type IComponentPresenterProps = SUPPORT.IPresenterProps;


export default Component