import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IModalProps extends IClassNameProps {
  root: {[s: string]: any};
  noFooter?: boolean; // if need hide footer
  type: string; // modal type
  data: object;
  header?: string; // text to header
  okBtnText?: string; // text on "OK" button
  cancelBtnText?: string; // text on "cancel" button
  okBtnAction: (data: any) => void; // function on "OK" button click
  okBtnValidate: (data: any) => boolean;
  cancelBtnAction?: () => void; // function on "cancel" button click
  bodyProps?: Record<string, any>;
  disableCloseOnClickOutside?: boolean
}

export interface IModalState {
  text?: string;
  init: boolean;
  data: object;
  dataIsValid: boolean;
}

export const cnModal = cn('Modal');