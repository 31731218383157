import * as React from 'react';
// import * as utils from 'utils/src/utils';


import { withBemMod } from '@bem-react/core';

import { cnWidget } from '../Widget.index';
import { IWidgetTypeBadgesProps } from './Widget_type_badges.index';
import './Widget_type_badges.scss';

const type = 'badges';

/**
 * @deprecated
 * ?
 */
export const withWidgetTypeBadges = withBemMod<IWidgetTypeBadgesProps>(
  cnWidget(),
  { type }, 
  (Presenter) => (
    (props) => {
      if (!props.widget) return null;
      return <Presenter {...props} />
    }
  )
);