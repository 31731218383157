import React, { FC, useMemo } from "react";
import { ListLite } from "uielements";
import { useVacanciesList } from "./VacanciesList.hooks";
import { VacanciesListItemWrapper } from '../ListItem/VacanciesListItem';
import { VacanciesHeaderBox, VacanciesHeaderItemBox, VacanciesListBox } from "./VacanciesList.styled";
import { SearchInput } from "muicomponents/src/SearchInput/SearchInput";
import { Button } from "muicomponents/src/Button/Button";
import { Translate } from "localizations/Translate";
import urls from "routes/urls";
import { getCurrentUserIsAdmin } from "utils/src/CommonRedux/base/selectors";
import { useSelector } from "react-redux";
import { cnVacanciesList } from "./VacanciesList.Index";
import { VacancyFilter } from "utils/src/BaseTypes/vacancy";
import { FilterAlt } from "muicomponents/src/Icons";
import { CheckButtonSelector } from "muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/CheckButtonForm";

export const VacanciesList: FC<{}> = ({}) => {

    const {
        list,
        error,
        loading,
        isFinished,
        loadMore,
        search,
        setSearch,
        filter,
        setFilter
    } = useVacanciesList();

    const isAdmin = useSelector(getCurrentUserIsAdmin);

    const filterOptions = useMemo(() => {
        return Object.typedKeys(VacancyFilter).filter(e => isNaN(Number(e)) && e !== 'NotSet' && e !== 'All' && e !== 'ManagedByMe').map(e => {
            return {
                value: e,
                label: Translate.t({  i18nKey: `pryaniky.vacancies.list.filter.${e}`}),
            }
        });
    }, []);

    return (
        <>
            <VacanciesHeaderBox className={cnVacanciesList('Header')}>
                <VacanciesHeaderItemBox>
                    <SearchInput
                        label={<Translate i18nKey={'search'} />}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    {
                        isAdmin &&
                        <Button
                            variant={'contained'}
                            href={`/administration/${urls.vacancy}`}
                        >
                            <Translate i18nKey={'create'} />
                        </Button>
                    }
                </VacanciesHeaderItemBox>
                <CheckButtonSelector
                    icon={<FilterAlt />}
                    value={filter}
                    onChange={(newValue) => {
                        setFilter(newValue as typeof filter);
                    }}
                    options={filterOptions}
                    defaultText={Translate.t({ i18nKey: "pryaniky.vacancies.list.filter.title" })}
                />
            </VacanciesHeaderBox>
            <VacanciesListBox
                className={cnVacanciesList('Header')}
            >
                <ListLite
                    isFinished={isFinished}
                    isLoading={loading}
                    loadMore={loadMore}
                >
                    {
                        list.map(id => {
                            return (
                                <VacanciesListItemWrapper key={id} id={id} />
                            );
                        })
                    }
                </ListLite>
            </VacanciesListBox>
        </>
    );
};