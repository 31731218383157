import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { cnCodeTest } from "./CodeTest.index";
// import MonacoEditor, { ChangeHandler } from 'react-monaco-editor';
// import * as monaco from 'monaco-editor'
// or import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
// if shipping only a subset of the features & languages is desired

import './CodeTest.scss';

class CodeTestPresenter extends React.Component<any, any> {
  private language: string = 'typescript'

  constructor(props: any) {
    super(props);
    this.state = {
      code: `
alkjhdlkjahsdkjs
function(asd: string) {
}
class Asd {

}
`
    }
    
  }

  public render() {
    // const { state, onChange, language } = this;
    // const { code } = state;
    const options = {
      selectOnLineNumbers: true
    };
    return (
      <div className={cnCodeTest()}>
        {/* <MonacoEditor
          // width="800"
          height="600"
          language={language}
          theme="vs-dark"
          value={code}
          options={options}
          onChange={onChange}
          // editorDidMount={::this.editorDidMount}
        /> */}
      </div>
    )
  }

  // private onChange: ChangeHandler = (code, e) => {
  //   this.setState({ code });
  // }
}

export const CodeTest = CodeTestPresenter;