import { connect } from 'react-redux';
import i18n from 'localizations/i18n';
// import './UsersWidget_type_participants.scss';
import { Button } from 'uielements/src';
import { ListRound } from 'blocks/ListRound/ListRound';
import React from 'react';
import { cnUsersWidget } from '../UsersWidget.index';
import { IGroupFullInfo } from 'utils/src/requests/models/api.groups';
import {cnWidget} from 'blocks/Widget/Widget.index';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

interface IParticipantsProps extends ReturnType<typeof mapStateToProps>, IParticipantsOwnProps {}

interface IParticipantsOwnProps {
  ['data-id']: string;
  className?: string;
  tag?: any;
}

const mapStateToProps = (state: any, props: IParticipantsOwnProps) => ({
  context: state.store.context,
  uid: getAuthUser(state).baseData.id,
  widget: state.widgets.widgets[props['data-id'] || 'empty'],
  groupInfo: state.store[state.store.context] as IGroupFullInfo,
});

const GroupAdminsWidgetPresenter: React.FC<IParticipantsProps> = ({
  tag: Tag = 'div',
  className,
  widget,
  groupInfo,
  uid,
  ...props
}) => {
  if (!groupInfo) return null;
  const items = groupInfo.top10Admins.map(user => ({
    id: user.id,
    title: user.displayName,
    imgUrl: user.userPhotoUrl,
    url: '/user/' + user.id,
  }));
  return (
    <Tag
      {...props}
      className={cnUsersWidget({ type: 'participants' }, [className])}
      noData={!groupInfo || !groupInfo.top10Admins.length}>
      <h4 className={cnWidget('Title', ['mb-1 mt-0'])} children={i18n.t('pryaniky.widgets.users.admins.title')} />
      <ListRound items={items} cut size={56} />
    </Tag>
  );
};

export const UsersParticipantsWidget = connect(mapStateToProps)(GroupAdminsWidgetPresenter);
