import React from 'react';
import WidgetPresenter from '../Widget';
import { IWidgetTypeCurrencyTimeLineNewProps } from './Widget_type_currencyTimeLineNew.index';
import { CurrencyTimeLineNew } from 'blocks/CurrencyTimelineNew/CurrencyTimelineNew';


export default class WidgetTypeCurrencyTimeLineNew extends WidgetPresenter<IWidgetTypeCurrencyTimeLineNewProps> {

  public render() {
    if (!this.props.widget) return null;
    return <CurrencyTimeLineNew currentUserId={this.props.wcontext.uId} />
  }

}