import { ISecretSanta } from './interfaces';
import { ISecretSantaWidgetActions } from './actions.interfaces';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { IUserShort } from 'utils/src/requests/models/api.users';

import { v1 as uuid } from 'uuid';
import {
  SET_SECRET_SANTA_WIDGET_SETTINGS,
  SET_USER_SECRET_SANTA_PARTICIPATE,
  SECRET_SANTA_CHANGE_JOIN_INFO,
  SET_USER_TO_GIVE_PRESENT,
  SET_IS_PRESENT_SENT,
  SET_SECRET_SANTA_PARTICIPANTS_PAGE_LIST,
  SECRET_SANTA_PARTICIPANTS_PAGE_LIST_TOGGLE,
  SET_MY_INFO,
  SET_USER_DISTRIBUTED_TOGGLE,
  SET_NO_RIGHTS,
  IS_INFO_LOADING
} from './actions';
// import { } from './interfaces';
import {
  ISecretSantaDescriptionGift,
  IUserWhowIGavePresent,
  ISecretSantaParticipantsList,

} from 'utils/src/requests/models/admin.secretSanta';
import { title } from 'process';
import { ISecretSantaWidget } from './interfaces';

export const firstSicretSanta = {
  distributionDate: "",
  dateEnd: "",
  state: "Teaser",
  dateStart: "",
  description: "Давайте подарим подарки",
  creator: {},
  endRegistrationDate: new Date().toISOString(),
  isActive: false,
  isCompleted: false,
  isHideParticipants: false,
  possiblePartipicipants: [],
  startRegistrationDate: new Date().toISOString(),
  iconId: "",
  iconUrl: "",
  id: "",
  participantsCount: 0,
  displayName: "Тайный Санта"
}
export const item: ISecretSantaWidget = {
  widgetSettings: firstSicretSanta,
  isDistributFinished: false,
  isDistributLoading: false,
  isFinished: false,
  isLoading: false,
  isParticipate: false,
  noRight: undefined,
  isPresentSend: false,
  joinSecretSantaInfo: {
    deliveryAddress: '',
    deliveryComment: '',
    deliveryPhone: ''
  },
  participantsSantaList: [],
  userForPresent: null
};

const baseState: ISecretSanta = {

  widgetSettingsList:{

    // widgetSettings:     {
    //   distributionDate: "",
    // dateStart: "",
    // description: "Давайте подарим подарки",
    // creator: {},
    // endRegistrationDate: new Date().toISOString(),
    // isActive:false,
    // isCompleted:false,
    // isHideParticipants:false,
    // possiblePartipicipants:[],
    // startRegistrationDate: new Date().toISOString(),
    // iconId:"",
    // iconUrl: "",
    // id: uuid(),
    // participantsCount: 0,
    // displayName: "Тайный Санта"
    // },
    // isParticipate: null,
    // joinSecretSantaInfo: {
    //   deliveryAddress: "",
    //   deliveryComment: "",
    //   deliveryPhone: ""
    // },
    // userForPresent: null,
    // isPresentSend: null,
    // isFinished: false,
    // isLoading: false,
    // isDistributLoading:false,
    // isDistributFinished: false,
    // participantsSantaList: [] as ISecretSantaParticipantsList[]
  }

}

export const secretSantaWidgetReducer = (state = baseState, action: ISecretSantaWidgetActions): ISecretSanta => {
  switch (action.type) {
    case SET_SECRET_SANTA_WIDGET_SETTINGS: {
      const widgetSettingsListItem = state.widgetSettingsList[action.payload.id] || item;
      return {
        ...state,
        widgetSettingsList: {
          ...state.widgetSettingsList,
          [action.payload.id]: {
            ...widgetSettingsListItem,
            widgetSettings: action.payload.data
          }
        }
      }
    }
    case IS_INFO_LOADING: {
      const widgetSettingsListItem = state.widgetSettingsList[action.payload.id] || item;
      return {
        ...state,
        widgetSettingsList: {
          ...state.widgetSettingsList,
          [action.payload.id]: {
            ...widgetSettingsListItem,
            isLoading: action.payload.isLoading
          }
        }
      }
    }
    case SET_NO_RIGHTS: {
      const widgetSettingsListItem = state.widgetSettingsList[action.payload.id] || item;
      return {
        ...state,
        widgetSettingsList: {
          ...state.widgetSettingsList,
          [action.payload.id]: {
            ...widgetSettingsListItem,
            noRight: action.payload.noRights
          }
        }
      }
    }
    case SET_USER_SECRET_SANTA_PARTICIPATE: {
      const widgetSettingsListItem = state.widgetSettingsList[action.payload.id];
      if (!widgetSettingsListItem) return state;
      return {
        ...state,
        widgetSettingsList: {
          ...state.widgetSettingsList,
          [action.payload.id]: {
            ...widgetSettingsListItem,
            isParticipate: action.payload.data
          }
        }
        // isParticipate: action.payload
      }
    }
    case SET_IS_PRESENT_SENT: {
      const widgetSettingsListItem = state.widgetSettingsList[action.payload.id];
      if (!widgetSettingsListItem) return state;
      return {
        ...state,
        widgetSettingsList: {
          ...state.widgetSettingsList,
          [action.payload.id]: {
            ...widgetSettingsListItem,
            isPresentSend: action.payload.data
          }
        }
        // ...state.widgetSettingsList[action.payload.id],

      }
    }
    case SECRET_SANTA_CHANGE_JOIN_INFO: {
      const widgetSettingsListItem = state.widgetSettingsList[action.payload.id];
      if (!widgetSettingsListItem) return state;
      return {
        ...state,
        widgetSettingsList: {
          ...state.widgetSettingsList,
          [action.payload.id]: {
            ...widgetSettingsListItem,
            joinSecretSantaInfo: {
              ...state.widgetSettingsList[action.payload.id]?.joinSecretSantaInfo,
              ...action.payload.data
            }
          }
        }
      }
    }
    case SET_MY_INFO: {
      const widgetSettingsListItem = state.widgetSettingsList[action.payload.id];
      if (!widgetSettingsListItem) return state;
      return {
        ...state,
        widgetSettingsList: {
          ...state.widgetSettingsList,
          [action.payload.id]: {
            ...widgetSettingsListItem,
            joinSecretSantaInfo: {
              ...state.widgetSettingsList.joinSecretSantaInfo,
              ...action.payload.data
            }
          }
        }
      }
    }
    case SET_USER_TO_GIVE_PRESENT: {
      const widgetSettingsListItem = state.widgetSettingsList[action.payload.id];
      if (!widgetSettingsListItem) return state;
      return {
        ...state,
        widgetSettingsList: {
          ...state.widgetSettingsList,
          [action.payload.id]: {
            ...widgetSettingsListItem,
            userForPresent: action.payload.data
          }
        }
      }
    }
    case SET_SECRET_SANTA_PARTICIPANTS_PAGE_LIST: {
      const widgetSettingsListItem = state.widgetSettingsList[action.payload.id];
      if (!widgetSettingsListItem) return state;
      return {
        ...state,
        widgetSettingsList: {
          ...state.widgetSettingsList,
          [action.payload.id]: {
            ...widgetSettingsListItem,
            participantsSantaList: action.payload.data
          }
        }
        // participantsSantaList: [...state.participantsSantaList, ...action.payload]
      }
    }
    case SECRET_SANTA_PARTICIPANTS_PAGE_LIST_TOGGLE:
    case SET_USER_DISTRIBUTED_TOGGLE: {
      const widgetSettingsListItem = state.widgetSettingsList[action.payload.id];
      if (!widgetSettingsListItem) return state;
      return {
        ...state,
        widgetSettingsList: {
          ...state.widgetSettingsList,
          [action.payload.id]: {
            ...widgetSettingsListItem,
            [action.payload.data.variable]: action.payload.data.value !== undefined ? action.payload.data.value : !widgetSettingsListItem[action.payload.data.variable]
          }
        }
      }
    }
    // case SECRET_SANTA_PARTICIPANTS_PAGE_LIST_TOGGLE: {
    //   return {
    //     ...state,
    //     [action.payload.id]: {
    //       ...state.widgetSettingsList[action.payload.id],
    //     [action.payload.data.variable]: action.payload.data.value !== undefined ? action.payload.data.value : !state.widgetSettingsList[action.payload.id]![action.payload.data.variable]
    //     }
    //   }
    // }
    // case SET_USER_DISTRIBUTED_TOGGLE: {
    //   return {
    //     ...state,
    //     [action.payload.id]: {
    //       ...state.widgetSettingsList[action.payload.id],
    //     [action.payload.data.variable]: action.payload.data.value !== undefined ? action.payload.data.value : !state.widgetSettingsList[action.payload.id]![action.payload.data.variable]
    //     }
    //   }
    // }

    default:
      return state
  }

}
