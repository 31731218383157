import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
// import { ICourse } from '../mock';
import { loadCourse, loadFullUnit, loadUserResult } from "../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
export interface IResultsProps extends IClassNameProps, IResultsStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
}

export interface IResultsState {

}

export interface IResultsStateProps {
    course: any
}

export const mapStateToProps = (state: any, props: any) => ({
    // course: state.LMS.courses.entities.values[props.match.params.cid],
    // results: state.LMS.userSessionsResults[props.match.params.sid]
    // cIds: state.LMS.courses.result,
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    // loadCourse,
    // loadUserResult
    // loadUnitContent
}, dispatch);

export const cnResults = cn('LMSResults');