import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PieChart, { PieChartData } from 'react-minimal-pie-chart';
import { tS } from 'LMSModule/utils/i18n.adaptor'
import { Box, Tabs, Typography } from 'muicomponents/src';
import { loadCoursesStatus } from 'LMSModule/redux/actions/LMS';
import { getStudentCoursesStatus } from 'LMSModule/redux/sagas/LMS/selectors';
import { ICourseStatusesWidgetNewProps } from './CourseStatusesWidgetNew.index';
import { HalfPaper, WrapBox } from './CourseStatusesWidgetNew.styled';

const noopItem: PieChartData = {
    color: '#ccc',
    value: 1,
    title: 'noop',
    key: 'noop',
}

export const CourseStatusesWidgetNew: FC<ICourseStatusesWidgetNewProps> = (props) => {

    const Tag = props.tag || 'div'

    const { userid } = props
    const stat = useSelector(getStudentCoursesStatus(false, userid))
    const statMondatory = useSelector(getStudentCoursesStatus(true, userid))

    const dispatch = useDispatch()

    const [isActive, setActive] = useState(true)
    const [isActiveArchive, setActiveArchive] = useState(true)
    const [isActiveMondatory, setActiveMondatory] = useState(true)

    useEffect(() => {
        dispatch(loadCoursesStatus(userid))
    }, [])

    const data: PieChartData[] = stat.active.map((val: any) => ({
        ...val,
        title: val.label
    }))
    const archiveData: PieChartData[] = stat.archive.map((val: any) => ({
        ...val,
        title: val.label
    }))
    const dataMondatory: PieChartData[] = statMondatory.active.map((val: any) => ({
        ...val,
        title: val.label
    }))
    const archiveDataMondatory: PieChartData[] = statMondatory.archive.map((val: any) => ({
        ...val,
        title: val.label
    }))

    const summuryNo = data.map(v => v.value).reduce((p, c) => p + c, 0)  // сумма необязательных
    const summuryArchiveNo = archiveData.map(v => v.value).reduce((p, c) => p + c, 0) // сумма архивных необязательных 
    if (summuryNo === 0) data.push(noopItem)
    const summuryMondatory = dataMondatory.map(v => v.value).reduce((p, c) => p + c, 0) // сумма обязательных   
    const summuryArchiveMondatory = archiveDataMondatory.map(v => v.value).reduce((p, c) => p + c, 0) // сумма архивных обязательных 
    if (summuryMondatory === 0) dataMondatory.push(noopItem)

    const resultedNo = isActive ? summuryNo : summuryArchiveNo

    const summary = summuryNo + summuryMondatory
    if (summary === 0) return null

    return <Tag {...props} >
        <WrapBox>

            <HalfPaper>
                <Box>
                    <Typography fontWeight='700'>{tS('widget.stats.not_mondatory.1')}</Typography>
                    <Tabs
                        color='primary'
                        onChange={(e, value) => value === tS('widget.stats.type.active.1') ? setActiveArchive(true) : setActiveArchive(false)}
                        value={isActiveArchive ? tS('widget.stats.type.active.1') : tS('widget.stats.type.archive.1')}
                        tabs={[
                            {
                                id: tS('widget.stats.type.active.1'),
                                label: <Typography variant='body2' color='primary'>{tS('widget.stats.type.active.1')}</Typography>,
                            },
                            {
                                id: tS('widget.stats.type.archive.1'),
                                label: <Typography variant='body2' color='primary'>{tS('widget.stats.type.archive.1')}</Typography>,
                            },
                        ]}
                    />
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: 1, mr: '12px', alignSelf: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>{tS('widget.stats.label.all.1')}&nbsp;</Typography>
                            <Typography color='rgba(255, 180, 0, 1)' fontSize='20px'>{resultedNo}</Typography>
                        </Box>
                        {(isActiveArchive ? data : archiveData).map((val: any, i: number) => val.key === 'noop' ? null
                            : <Box key={val.key} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography>{tS('widget.stats.label.' + val.key)}&nbsp;</Typography>
                                <Typography color={val.color} fontSize='20px'>
                                    {val.value}
                                </Typography>
                            </Box>)}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <PieChart
                            data={isActiveArchive ? data : archiveData}
                            lineWidth={43}
                            labelPosition={0}
                        />
                    </Box>
                </Box>
            </HalfPaper>


            <HalfPaper>

                <Box>
                    <Typography fontWeight='700'>{tS('widget.stats.mondatory.1')}</Typography>
                    <Tabs
                        onChange={(e, value) => value === tS('widget.stats.type.active.1') ? setActiveMondatory(true) : setActiveMondatory(false)}
                        value={isActiveMondatory ? tS('widget.stats.type.active.1') : tS('widget.stats.type.archive.1')}
                        tabs={[
                            {
                                id: tS('widget.stats.type.active.1'),
                                label: <Typography variant='body2' color='primary'>{tS('widget.stats.type.active.1')}</Typography>,
                            },
                            {
                                id: tS('widget.stats.type.archive.1'),
                                label: <Typography variant='body2' color='primary'>{tS('widget.stats.type.archive.1')}</Typography>,
                            },
                        ]}
                    />
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: 1, mr: '16px', alignSelf: 'center', }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>{tS('widget.stats.label.all.2')}&nbsp;</Typography>
                            <Typography color='rgba(255, 180, 0, 1)' fontSize='20px'>{summuryMondatory}</Typography>
                        </Box>
                        {(isActiveMondatory ? dataMondatory : archiveDataMondatory).map((val: any, i: number) => val.key === 'noop' ? null
                            : <Box key={val.key}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>
                                        {tS('widget.stats.label.' + val.key)}&nbsp;
                                    </Typography>
                                    <Typography color={val.color} fontSize='20px'>{val.value}</Typography>
                                </Box>
                            </Box>)}
                    </Box>

                    <Box sx={{ flex: 1 }}>
                        <PieChart
                            data={isActiveMondatory ? dataMondatory : archiveDataMondatory}
                            lineWidth={43}
                            labelPosition={0}
                        />
                    </Box>

                </Box>
            </HalfPaper>
        </WrapBox>
    </Tag>
}

export default CourseStatusesWidgetNew