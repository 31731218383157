import React, { FC } from 'react';
import { Box, Tabs, Tab, Card, CardMedia, CardContent, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './Blocks.scss';
import { NBlocks, cnBlocks } from './Blocks.index';
import { Button } from 'uielements/src';
import { initWidgetAddition, initWidgetSettings } from './Settings/Settings';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';

initWidgetAddition();
initWidgetSettings();

const gap = 4;

const useTabsStyles = makeStyles({
    flexContainer: {
        gap: `${gap}px`
    }
});

const useTabStyles = makeStyles({
    root: {
        flex: 1,
        maxWidth: 'none',
        padding: 0,
        minWidth: `calc(16.73% - ${gap}px)`,
        opacity: 1,
        maxHeight: '267px',
        "&:before": {
            content: '""',
            float: 'left',
            display: 'block',
            paddingBottom: '100%'
        },
    },
    wrapper: {
        display: 'block',
        height: '100%'
    }
});

const Item: FC<NBlocks.Item> = ({
    id,
    imgUrl,
    text,
    url
}) => {

    const classes = useTabStyles();

    return (
        <Tab
            disableRipple
            className={classes.root}
            classes={{
                wrapper: classes.wrapper
            }}
            label={
                <Card variant="outlined">
                    <Button type={'link'} theme={'unstyled'} href={url}>
                        <CardMedia
                            image={imgUrl}
                        />
                        <CardContent>
                            <Tooltip title={text.length > 30 ? text : ""}>
                                <span>
                                    {text}
                                </span>
                            </Tooltip>
                            {/* <Typography gutterBottom variant="h5" component="h2">
                                Lizard
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                                across all continents except Antarctica
                            </Typography> */}
                        </CardContent>
                    </Button>
                </Card>
            }
        />
    )
}

export const Blocks: FC<NBlocks.Props> = ({
    className,
    data
}) => {

    const classes = useTabsStyles();

    return (
        <Tabs
            className={cnBlocks({}, [className])}
            classes={{
                flexContainer: classes.flexContainer
            }}
            variant={data.length > 6 ? "scrollable" : undefined}
            scrollButtons={data.length > 6 ? 'on' : 'off'}
        >
            {
                data.map(block => {
                    return (
                        <Item key={block.id} {...block} />
                    )
                })
            }
        </Tabs>
    );
}