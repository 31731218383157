import { cn } from '@bem-react/classname';
import { IStateType as IState } from 'redux/store';
import { IDispatchProps } from 'redux/connector';
import { saveDesign } from 'redux/actions';

export interface ICodeProps extends ICodeOwnProps, IDispatchProps, IActionsCode, ICodeStateProps {
  tag?: React.ComponentType;
}

export interface ICodeOwnProps {
  language?: string | 'html' | 'css' | 'javascript' | 'typescript';
  variable?: string;
  data?: string;
  theme?: string | 'vs' | 'vs-dark' | 'hc-dark';
  rounded?: boolean;
}

export interface ICodeState {
  code: string;
}
export interface IActionsCode {
  saveDesign: typeof saveDesign;
}
// export type IActionsLeftMenu = ReturnType<typeof mapStateToProps>

export const mapActionsToProps = {
  saveDesign
};

export type ICodeStateProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: IState, props: ICodeOwnProps) => ({
  value: props.variable ? state.store.appDesign[props.variable] : undefined
})

export const cnCode = cn('Code');