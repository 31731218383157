import React, { FC } from 'react';
import i18n from "../../../../localizations/i18n";
import { cnFiltersMui } from "./Filters_type_shopMui.index";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from 'muicomponents/src';
import { IStateType } from 'redux/store';
import { DownBox, FakeBtnBox, FilterBtnText, FiltersTypeShopSkeleton, ItemBox, PaperContainer, RightBox, UpperBox } from './Filters_type_shopMui.styled';
import { resetFilters } from 'redux/shop/actions';
import { YellowPurseBtn } from 'blocks/ShopMui/Item/YellowPurseBtn';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { useShopGridWidth } from 'blocks/ShopMui/ShopList/ListContainer';
import { Filters } from './Filters/Filters';
import { CategoryFilter } from './CategoryFilter/CategoryFilter';
import { SearchBar } from './SearchBar/SearchBar';
import Sorting from './Sorting/Sorting';
import AddToFavourite from './AddToFavourite/AddToFavourite';

export const FiltersTypeShopMui: FC = () => {

  const { ref, gridWidth } = useShopGridWidth('shopFilters')

  const isClosed = useSelector((s: IStateType) => s.shop.categoryProducts.isShopClosed)
  const dispatch = useDispatch()
  const availableCurrency = useSelector(getAuthUser).extData.mythanksCount

  const categories = useSelector((s: IStateType) => s.shop.categoryProducts.filters)

  const handleReset = () => {
    dispatch(resetFilters())
  }
return null
  if (isClosed) return <FiltersTypeShopSkeleton />

  return (
    <Box ref={ref}>
      <PaperContainer sx={{ maxWidth: `${gridWidth}px` }} >

        <UpperBox>
          <SearchBar />
          <RightBox>
            <YellowPurseBtn />
            <Box>
              <Typography variant='body2' className={cnFiltersMui('headerAvailable')}>{i18n.t('pryaniky.list.shop.header.available')}</Typography>
              <Typography variant='body2' fontWeight='600' className={cnFiltersMui('availableCurrency')} >{availableCurrency}</Typography>
            </Box>
          </RightBox>
        </UpperBox>

        <DownBox>

          <AddToFavourite />

          {!!categories &&
            <CategoryFilter categories={categories} />}

          <Sorting />

          <Filters />

          <ItemBox>
            <Button className={cnFiltersMui('resetButton')} variant='outlined' size='small' onClick={handleReset}
              startIcon={<CleaningServicesIcon htmlColor='GrayText' sx={{ mr: 1, flexShrink: 1, }} />}
            >

              <FakeBtnBox>
                <FilterBtnText color='ActiveCaption' noWrap variant='body2'>
                  {i18n.t('reset')}
                </FilterBtnText>
              </FakeBtnBox>
            </Button>
          </ItemBox>
        </DownBox>
      </PaperContainer>
    </Box>
  )
}
