import React, { useState, useEffect } from 'react';
import { cnPresentsNew, IPresentsProps } from './Presents.index';
import { getUserPresents, getUserShopPresents } from 'utils/src/requests/requests.shop';
import { IGetPresentsResponse, IPresentData } from 'utils/src/requests/models/api.shop';
import { Avatar, Button } from 'uielements/src';
import { PresentItemMui } from './PresentItem/PresentItemMui';
// import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu';
import './Presents.scss';
import { Typography } from 'muicomponents/src/Typography/Typography'
import { Box } from 'muicomponents/src'
import { withTranslation } from 'react-i18next';
import { Translate } from 'localizations/Translate';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import CardMedia from '@mui/material/CardMedia';
import EmptyWidget from 'assets/svg/EmptyWidget.svg';

export const Presents = withTranslation('translation')((props: IPresentsProps) => {

  const { tag: Tag = 'div', edit, className } = props;


  const params: {
    id: string;
    tab: string
  } = useParams();

  // console.log("params", params)
  const authUser = useSelector(getAuthUser)

  const [presents, setPresents] = useState<IPresentData[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const userId = params.id || authUser.baseData.id
  useEffect(() => {
    (async function () {
      setLoading(true)
      const response: IGetPresentsResponse = await getUserShopPresents(userId, true);
      setPresents(response.data);
      setLoading(false)
    })()
  }, [userId])

  return (
    <Tag {...props} className={cnPresentsNew('', [className])} >
      <Typography fontWeight='700' >
        <Translate i18nKey={'pryaniky.widgets.presents.title'} />
      </Typography>
      <Box className={cnPresentsNew(`Items `)}
        sx={{
          // background: "#f5f5f5"
          background: (presents && presents?.length < 0) ? "#f5f5f5" : "#fff"
        }}>
        {
          (!edit && !loading) && (presents && presents?.length > 0)
            ? presents?.map((present) => {
              return (
                <PresentItemMui item={present} size={64} />
              )
            })
            : <>
              <CardMedia
                component="svg"
                className={cnPresentsNew("EmptyList-Avatar")}
                sx={{
                  width: '100%',
                  height: "270px",
                  marginBottom: "16px"
                }}
                image={EmptyWidget}
              />
              <Typography>
                <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
              </Typography>
            </>
        }
      </Box>

    </Tag>
  )
})