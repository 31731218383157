import { IIdeasendPropsType, IIdeasendStateType, mapStateToProps, TStateProps } from './Ideasend.index'
import React, { FC, memo } from 'react';
import { connect } from 'react-redux';
import './Ideasend.scss'
import { } from './Utils'
import { withNewsContext } from 'News/contexts/news'
import TextRender from 'News/containers/TextRender'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import ContentLayout from 'News/components/ContentLayout'


export const IdeasendPresenter: FC<IIdeasendPropsType & TStateProps> = ({ text, isSingle }) => <NewsView className={'type-25'}>
    <ContentLayout>
        <TextRender />
    </ContentLayout>
</NewsView>

export const Ideasend = withNewsContext(connect(
    mapStateToProps
)(memo(IdeasendPresenter)));

export const initIdeasend = () => ComponentInjector.getInstance().addNode('news_types', <Ideasend />, 'ideawinners')