import React from 'react';
import { Skeleton } from 'uielements/src';
import './Widget_type_skeleton.scss';
import * as utils from 'utils/src/utils';

import { cnWidget } from '../Widget.index';
import './Widget_type_shop.scss';
import { IWidgetTypeSkeletonProps} from './Widget_type_skeleton.index';
import { IWidgetProps } from '../Widget.index';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";


const type = 'skeleton';

export default class WidgetTypeSkeletonPresenter extends WidgetPresenter<IWidgetProps> {

    // public render() {
    //     if (!this.props.widget) return null;
    //     const subtype = utils.widgetSubtype(this.props.widget.type);
    //     // const Component = this.getComponent(types);
    //     return <WidgetTypeSkeleton {...this.props} className={cnWidget({ type, subtype })} contexts={this.relations.concat(this.props.widget.id)} tag={widgets.components.common} />
    // }

}
 export const WidgetTypeSkeleton = (props: any) => {

        return <div className="Widget_Type_Skeleton">
            {/* <Skeleton shape="circle" style={{ marginRight: 12 }} /> */}
            <div style={{ flexGrow: 1 }}>
                <Skeleton shape="line" style={{ width: '80%' }} />
                <Skeleton shape="line" style={{ height: 12 }} />
                <Skeleton shape="line" style={{ height: 12 }} />
                <Skeleton shape="line" style={{ height: 12 }} />
                <Skeleton shape="line" style={{ height: 12 }} />
            </div>
                    
        </div>

};