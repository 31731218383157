import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets'
import { guids } from "./index"
import i18n from '../localizations/i18n'
import ideasLogo from 'assets/png/Ideas.png'
import { pageHeader } from './pageHeaders'

export const ideas = (): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({ 
        items: [
          generateWidget({
            type: widgets.types.info,
            data: pageHeader.ideas
          }),
          generateWidget({
            type: widgets.types.layout,
            data: [
              generateColumn({
                items: [
                  generateWidget({
                    type: widgets.types.timeline,
                  }),
                ],
                styles: {
                  width: '72%',
                },
              }),
              generateColumn({
                items: [
                  generateWidget({
                    type: widgets.types.filters + '/ideas',
                    relations: [],
                    data: [],
                  }),
                ],
                styles: {
                  width: '28%',
                },
              }),
            ],
          }),
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  })
}
