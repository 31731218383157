import { PollNews, Answer } from './Polls.types'
import { Rule, BaseValidator, validateArray, validateString } from '../Base.validate'
export const pollValidate: Rule[] = [
    {
        type: 'array',
        method: 'ARR_MIN',
        field: 'poll.answers',
        value: 2,
        errorI18nKey: 'pryaniky.validate.news.poll.answers.min',
        // message: 'small array'
    },
    /*{
        type: 'array',
        method: 'ARR_MAX',
        field: 'poll.answers',
        value: 20,
        message: 'big array'
    },*/
]

export const answerValidate: Rule[] = [
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'poll.answer.text',
        value: 1,
        errorI18nKey: 'pryaniky.validate.news.poll.answer.text.min',
        // message: 'small string'
    },
    /*{
        type: 'string',
        method: 'STR_MAX',
        field: 'poll.answer.text',
        value: 40,
        message: 'big strimg'
    },*/
]

export class PollValidator extends BaseValidator<any> {
    protected otherValidate = (data: any, rule: Rule) => {
        switch (rule.field) {
            case 'poll.answers':
                return validateArray<Answer>(data.poll.answers, rule);
            case 'poll.answer.text':
                for (const answer of data.poll.answers) {
                    if (!validateString(answer.text, rule)) return false;
                }
        }
        return true;
    }
}
