import * as React from 'react';
import { IEditorTypeNewsProps } from './news.index';
import { CreativeTaskNews, defaultCreativeTaskNews } from '../../../CreativetasksType'
import { Editor } from '../../Editor'
import { CreativeTaskNewsValidator, creativeTaskValidate } from '../../../CreativetasksType.validate'
import { baseValidate } from '../../../../Base.validate'
import * as utils from 'utils/src/utils';
import { EditorState } from 'draft-js';
import { defaultDate } from '../../../CreativetasksType';

export const EditorTypeNews: React.FC<IEditorTypeNewsProps> = ({ onChange: cbChange, data }) => {
    let localData = data as any;
    if (data.newstype !== 6) {
        localData = { ...utils.cloneObject(defaultCreativeTaskNews), text: EditorState.createEmpty() };;
    } //else localData.uevent.startDateTime = new Date(localData.uevent.startDateTime);

    const onChange = (data: any) => {
        const vb = new CreativeTaskNewsValidator();
        vb.addData(data)
        vb.setRules([...creativeTaskValidate, ...baseValidate])
        cbChange(data, vb.validation().length === 0, vb.validation())
    }

    const onChangeTitle = (e: any) => {
        localData.header = e.target.value;
        localData.creativeTask.title = e.target.value;
        onChange(localData);
    }
    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData);
    }
    const endDateChange = (date: Date) => {
        if (!date) date = defaultDate;
        localData.creativeTask.endDateTime = date;
        localData.expires = date;
        onChange(localData);
    }
    const onChangeThanks = (val: number) => {
        localData.creativeTask.fund = val
        localData.thanksCount = val
        onChange(localData)
    }
    return <Editor
        onChangeThanks={onChangeThanks}
        endDateChange={endDateChange}
        onChangeText={onChangeText}
        onChangeTitle={onChangeTitle}
        data={localData} />
}
