import * as React from 'react';
// import * as utils from 'utils/src/utils';
// import { withRouter } from "react-router"
import WidgetPresenter from '../Widget';
// import { withBemMod } from '@bem-react/core';
import { IWidgetTypeUserAboutProps } from './Widget_type_userAbout.index';
import './Widget_type_userAbout.scss';
import { AboutWidget } from 'blocks/AboutWidget/AboutWidget'
import { widgets } from 'i.widgets';
// const type = 'about';

class WidgetTypeUserAboutPresenter extends WidgetPresenter<IWidgetTypeUserAboutProps> {
  
  public render() {
    if (!this.props.widget) return null;
    
    // const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    if (!this.props.wcontext.uId) return null;
    return (
      // <widgets.components.common {...this.props}>
      <AboutWidget {...this.props} showControls={this.props.authUser.baseData.isAdmin || this.props.authUser.baseData.id === this.props.wcontext.uId} uid={this.props.wcontext.uId} tag={widgets.components.common}/>
      // </widgets.components.common>
    );
  }
}

export default WidgetTypeUserAboutPresenter