import React from 'react';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { cnWidget, cnWidgetMui, IWidgetProps } from '../Widget.index';
import { widgets } from "i.widgets";
import { Mood } from '../../Mood/Mood';

const type = 'mood';

export default class WidgetTypeMood extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);

    return (
      <Mood {...this.props as any} id={this.props.widget.id} context={this.props.wcontext} className={cnWidgetMui({ type, subtype })} tag={widgets.components.common} edit={this.props.edit} />
    );
  }
}