import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
// import { ICourse } from '../mock';
import { loadCourse, closeSession } from "../../redux/actions/LMS";
import { loadUnitsLogs } from "../../redux/actions/COMMON";
import { RouteComponentProps } from 'react-router';
export interface ICourseProps extends IClassNameProps, ICourseStateProps, IDispatchProps, RouteComponentProps, ICourseDispatchProps {
  tag?: 'div';
}

export interface ICourseState {

}

export interface ICourseStateProps {
  course: any
}

export const mapStateToProps = (state: any, props: any) => ({
  // course: state.LMS.courses.entities.values[props.cid || props.match.params.cid],
  sessionsLogs: state.LMS.sessionsLogs,
  // cIds: state.LMS.courses.result,
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  loadCourse,
  loadUnitsLogs,
  closeSession
  // loadUnitContent
}, dispatch);

export type ICourseDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnUnit = cn('Unit');