import { zeroId } from 'LMSModule/utils/utils.lms'

export const unitModelCreator = (id = zeroId) => ({
    "highlightCorrectness": false,
    "questions": [],
    "maxScore": 0,
    "skipUnitType": 0,
    "randomizeMarkedQuestions": false,
    "id": id,
    "name": "тест",
    "description": "описание",
    "unitType": "test",
    "allowSkip": true,
    "allowBack": false,
    "courseId": zeroId,
    "IsDeleted": false,
    "Position": 0,
    "maxAttemptsCount": 1,
    "maxTime": 0,
    "scoreCountMode": 0,
    "passingMinScore": 100,
    "isHidden": false,
    "isLastUnit": true,
    "imgId": null,
    "largeImgId": null,
    "shuffle": false,
    "groupFullList": [],
    "userFullList": [],
    "beginDate": null,
    "endDate": null,
    "startType": 0,
    "completeInDays": 0,
    "isPaused": false,
    "inviteUsers": false,
    "isMandatory": false,
    "category": "",
    "categoryId": zeroId
})

export const shortUnitModelCreator = (id = zeroId) => ({
    id: id,
    name: "New Test",
    description: "",
    unitType: "test",
    appPageId: null,
    page: null,
    navigatePageName: null,
    allowSkip: true,
    allowBack: false,
    courseId: zeroId,
    isDeleted: false,
    // position: 5,
    maxAttemptsCount: 1,
    maxTime: 0,
    scoreCountMode: 0,
    passingMinScore: 100,
    isHidden: false,
    isLastUnit: true,
    newsUid: null,
})