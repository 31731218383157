import { styled } from 'muicomponents/src/mui/system';
import { Box } from 'muicomponents/src/Box';
import { NotInterested, SyncLockOutlined } from 'muicomponents/src/Icons';
import { green, red, grey } from '@material-ui/core/colors';

export const AchiementThanksCountBox = styled(Box)({
    fontSize: 16,
    lineHeight: '24px'
});
export const AchiementOptionBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "8px"
});

export const AchiementNotInterested = styled(NotInterested)({
     color: red[500],
     fontSize: "18px"
});
export const AchiementLimited = styled(SyncLockOutlined)({
     color: red[500],
     fontSize: "18px"
});
