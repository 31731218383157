import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { NewsContextProps } from 'News/contexts/news'

export interface IThanksPropsType extends NewsContextProps {
}

export type TStateProps = ReturnType<typeof mapStateToProps>
export interface IThanksStateType {
}

export const mapStateToProps = (state: any) => ({
    userData: getCurrentUser(state),
})
