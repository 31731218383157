
import React, { FC, useState, useEffect, useMemo } from 'react';
import { IBadgeListTypeUsersProps, IBadgeUsersListDispatchProps, cnBadgeListTypeUsers, mapBadgeUsersListDispatchProps, mapBadgeUsersListStateToProps } from './BadgeListTypeUsers.index';
import { useDebouncedQueryState } from 'utils/src/hooks'
import { usersList, byId } from 'utils/src/requests/requests.badges'
import { Box } from 'muicomponents/src'
import * as utils from 'utils'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { ItemsList } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { IStateType as IState } from 'redux/store';
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem'
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { IBaseUser } from 'uielements/src/UsersList/UsersList.index'
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody'
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput'
import { CacheHelper } from 'utils/src/CacheHelper'
import { IItemsListHeaderProps } from 'muicomponents/src/ItemsListDialog/ItemsListDialog.index'
import { Typography } from "muicomponents/src/Typography/Typography"
import { Avatar } from 'muicomponents/src/Avatar/Avatar';
import { SearchBox, AvatarCustom, InfoBox, } from './BadgeListTypeUsers.styled'
import BadgesListEmpty from 'assets/png/BadgesListEmpty.png'
import { i18n, Translate } from 'localization';
import { getCurrencyNameByCount } from 'utils/src/CommonRedux/base/selectors'
import { useSelector } from 'react-redux';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn'

export const BadgeUsersListPresenter: FC<IBadgeListTypeUsersProps> = ({
  match,
  tag: TagName = 'div',
  ...props }) => {
  const [search, searchQuery, setSearch] = useDebouncedQueryState('search')
  const [bageInfo, setBageInfo] = useState<{ [key: string]: any }>()

  useEffect(() => {
    actionGetData(match.params.id)
  }, [])


  const actionGetData = (bId: string) => {
    let ignoreCache = false;

    CacheHelper.get('badge', `badgeinfo?bid=${bId}`)
      .then((value) => value && !ignoreCache && setBageInfo(utils.clone(value)))
      .catch(e => console.warn('CacheHelper, badge:', e))

    byId(bId)
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          ignoreCache = true;
          CacheHelper.set('badge', `badgeinfo?bid=${bId}`, utils.clone(d.data))
            .then((value) => console.log(value))
            .catch(e => console.warn('CacheHelper, badge:', e))

          setBageInfo(d.data);
        } else {
          // toast.error('something wrong')
        }
      });
  }

  const rewardRulesLength = bageInfo?.issueCurrency.length

  const sortRules = bageInfo?.issueCurrency?.sort(function (a: any, b: any) {
    if (a.key > b.key) {
      return 1;
    }
    if (a.key < b.key) {
      return -1;
    }

    // // a должно быть равным b
    return 0;
  }) || [];


  // проверяем равно ли первое значение назначеной в награду валюты последнему
  const currencyKeyValuesAreEqual = (firstValueIndex: number, secondValueIndex: number) => {
    return (bageInfo?.issueCurrency[firstValueIndex]?.key === bageInfo?.issueCurrency[secondValueIndex]?.key)
  }

  const moreThenOneButAreEquel = ((rewardRulesLength > 1 && currencyKeyValuesAreEqual(0, rewardRulesLength - 1)))

  const currency = useSelector(getCurrencyNameByCount(bageInfo?.issueCurrency[0]?.key))
  const currency2 = useSelector(getCurrencyNameByCount(bageInfo?.issueCurrency[1]?.key))
  const currency3 = useSelector(getCurrencyNameByCount(bageInfo?.issueCurrency[rewardRulesLength - 1]?.key))




  const HeaderBadgeUser: React.ComponentType<IItemsListHeaderProps<IBaseUser & { url: string; infoBage: { [key: string]: any } }>> = ({ changeSearchQuery, searchQuery, searchLabel, }) => {
    return (
      <Box>
        {bageInfo && <InfoBox>
          <AvatarCustom variant='circular' src={bageInfo.iconUrl || BaseRequests.getUrl(defaultBadgeIconUrl)} />
          <Box>
            <Typography sx={{ fontWeight: "600" }} variant='h6'>{bageInfo.name}</Typography>
            <Typography variant='body1' sx={{
              color: "#000", fontSize: "16px",

            }}>{bageInfo.description}</Typography>

            <Typography variant='body2' sx={{
              color: "rgba(0, 0, 0, 0.6)",
              marginBottom: "12px"

            }}>{bageInfo.howToReceiveDescription}</Typography>

            <Box sx={{ display: "flex" }}>
              <Typography variant='body2' sx={{
                color: "rgba(0, 0, 0, 0.87)",
                marginRight: "5px"
              }}>
                {Translate.t({ i18nKey: 'pryaniky.badge.bageInfo.rewardReason.name' })}</Typography>
              <Typography variant='subtitle2' sx={{ fontWeight: "600" }}>{bageInfo.rewardReason.name}</Typography>
            </Box>

            {(bageInfo?.issueCurrency && rewardRulesLength > 0) && <Box sx={{ display: "flex" }}>
              <Typography variant='body2' sx={{ marginRight: "5px" }}>{Translate.t({ i18nKey: 'pryaniky.badge.bageInfo.issueCurrency.key' })}</Typography>

              {/* если существует один вариант награды https://tracker.yandex.ru/COMMON-11351 */}
              {(rewardRulesLength === 1 || moreThenOneButAreEquel) &&
                <Typography variant='subtitle2' sx={{ fontWeight: "600" }}>
                  <Translate i18nKey='pryaniky.badge.bageInfo.issueCurrency'
                    values={{
                      min: bageInfo?.issueCurrency[0]?.key,
                      currencyName: currency
                    }} />
                </Typography>}

              {/* если два варианта награды https://tracker.yandex.ru/COMMON-11351*/}
              {(rewardRulesLength === 2 && !currencyKeyValuesAreEqual(0, 1)) &&
                <Typography variant='subtitle2' sx={{ fontWeight: "600" }}>
                  <Translate i18nKey='pryaniky.badge.bageInfo.issueCurrency2'
                    values={{
                      min: bageInfo?.issueCurrency[0]?.key,
                      max: bageInfo?.issueCurrency[1]?.key,
                      currencyName: currency2
                    }} />
                </Typography>}

              {/* если вариантов награды больше двух то показываем первую и последнии для отображения диапазона  https://tracker.yandex.ru/COMMON-11351*/}
              {(rewardRulesLength > 2 && !currencyKeyValuesAreEqual(0, rewardRulesLength - 1)) &&
                <Typography variant='subtitle2' sx={{ fontWeight: "600" }}>
                  <Translate i18nKey='pryaniky.badge.bageInfo.issueCurrency3'
                    values={{
                      min: bageInfo?.issueCurrency[0]?.key,
                      max: bageInfo?.issueCurrency[rewardRulesLength - 1]?.key,
                      currencyName: currency3
                    }} />
                </Typography>}
            </Box>}
          </Box>
        </InfoBox>}

        {
          <SearchBox>
            <SearchInput
              label={searchLabel}
              onChange={(e) => changeSearchQuery && changeSearchQuery(e.target.value)}
              value={searchQuery}
            />

          </SearchBox>
        }
      </Box >
    )
  }

  return <TagName className={cnBadgeListTypeUsers('')} {...props}> <ItemsList<IBaseUser & { url: string }>

    HeaderSecondaryComponent={HeaderBadgeUser}
    BodyComponent={ItemsListBody}
    ItemComponent={UsersListItem}
    listComponentProps={{
      imgEmpty: BadgesListEmpty,
      textEmpty: Translate.t({ i18nKey: 'pryaniky.badge.users.empty.text' }),


    }}

    method={async function (skipCount, { count, search }) {
      const response = await usersList({ bid: match.params.id, skipCount, count, search })
      const result = await response.r as any as { data: IBaseUser[] } & IBasicResponse
      return result.data.map((item) => ({ ...item, url: `/user/${item.id}` }))
    }}
  />
  </TagName>

}
export const BadgeUsersList = withRouter(connect(
  mapBadgeUsersListStateToProps,
  mapBadgeUsersListDispatchProps
)(BadgeUsersListPresenter))
