import { GUID_EMPTY } from 'utils/src/constants.prn';
import { createDefaultPostNews } from '../../baseNews';
import { NWorkflows } from './Workflows.interface';

export const baseWorkflows: NWorkflows.Create = {
    ...createDefaultPostNews(),
    componentRenderName: 'workflow',
    additionalFields: {
        additionalFieldsValues: [],
        comment: '',
        currentStatus: null,
        formId: GUID_EMPTY,
        responsibleForStatus: [],
        showChangeStatusButton: false
    }
};