import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IListItem {
  id: string | number;
  title: string;
  imgUrl: string;
  imgId?: string;
  url?: string;
  position?: string;
  description?: string
  membersCount?: number
  visibilityType?: number
}

export interface IListRoundProps extends IClassNameProps {
  items: IListItem[];
  cut: boolean;
  size?: number;
  count?: number;
  gap?: number;
  action?(): void;
  onDelete?: (item: IListItem) => void
  // activeAction: boolean;
}

export const cnListRound = cn('ListRound');