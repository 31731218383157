// import { User, BaseNews, defaultBaseNews, defaultUser, defaultGroup, Group } from '../BaseType'
import { IBaseNews, createDefaultNews } from 'News/types/baseNews'

export interface IdeasendNews extends IBaseNews {
    newstype: 25;
}



export const defaultIdeasendNews: IdeasendNews = {
    ...createDefaultNews(),
    newstype: 25,
}

