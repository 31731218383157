import { NValidate } from 'utils/src/validate_v2';
import { NNotices } from './Notices.interface';

export const NoticesValidateRules: NValidate.ValidateRulesObject<NNotices.Create> = {
    header: {
        notNull: {
            value: true
        },
        min: {
            value: 3
        }
    },
    text: {
        notNull: {
            value: true
        },
        min: {
            value: 5
        }
    },
};