import * as React from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from '../../../../redux/connector';

import { mapStateToProps, IListStateProps, IListProps, IListState, cnList, mapActionsToProps } from './../../List.index';
import { IListTypeShopProps, avalibleFiltersShop } from './List_type_shop.index';

import './List_type_shop.scss';
import { AbstractList } from '../../List';
import { IListResponseWithData } from 'utils/src/requests/models/api.base';
import { Header } from 'blocks/List/Header/List-Header';
import { ListLite, TypedListPlaceholder } from 'uielements/src';
import i18n from 'localizations/i18n';
import { Header as ShopHeader } from '../../../Shop/Header/Shop-Header';
import { Masonry } from 'uielements/src/Masonry/Masonry';
import { Item as ShopItem } from '../../../Shop/Item/Shop-Item';
import { toast } from 'react-toastify';
import { IShopItem } from 'utils/src/requests/models/api.shop';
import { IStateType as IState } from 'redux/store';

export class ListShopPresenter extends AbstractList<IListTypeShopProps, IListState> {
  public static defaultProps = {
    requestOptions: {
      skipCount: 0,
      count: 6,
      search: '',
    },
  };

  public type = 'shop';

  public search = (text: string) => {
    const { toggle } = this.props;
    this.searchString = text;
    toggle({
      id: this.listId,
      data: {
        hideData: text.length !== 0,
        clearData: true,
        isFinished: false
      }
    });
    setTimeout(this.getData, 10);
  };

  public filter = (filters: number[]) => {
    const { toggle } = this.props;
    this.props.requestOptions.catFilter = filters
    toggle({
      id: this.listId,
      data: {
        hideData: true,
        clearData: true,
        isFinished: false,
        isLoading: false
      }
    });
    setTimeout(this.getData, 100);
  };

  public getData = () => {
    const { requestOptions, context, edit } = this.props;
    const { clearData, isLoading } = this.props;
    const { clearDataFromList, getShopProducts, toggle } = this.props;
    const id = this.listId;
    if (edit || isLoading) return;
    const opts = Object.assign({}, requestOptions);
    if (this.searchString && this.searchString !== '') opts.search = this.searchString;

    Object.keys(context).forEach((cKey: string) => {
      if (avalibleFiltersShop[cKey]) opts[avalibleFiltersShop[cKey]] = context[cKey];
    });

    // if (context.filter) {
    //   if (context.filter.type === 'catFilter') opts.catFilter = context.filter.value;
    // }

    toggle({
      id: this.listId,
      data: {
        isLoading: true,
        isFinished: false
      }
    });
    if (clearData) clearDataFromList({ id, data: null });

    getShopProducts.call(this, id, opts);
  };

  public componentDidUpdate(pp: IListTypeShopProps) {
    const { id, props } = this;
    const { context: c, toggle, isLoading } = props;
    const { context: pc } = pp;
    if (Object.keys(avalibleFiltersShop).filter((fk) => c[fk] !== pc[fk] ? true : false).length) {
      toggle({
        id,
        data: {
          hideData: true,
          clearData: true,
          isFinished: false
        }
      });
      setTimeout(() => this.getData(), 10);
    }
  }

  public renderChildren = () => {
    // const { isFinished, isLoading } = this.state;
    // const data: any[] = this.state[this.dataVariable];
    const { data, isFinished, isLoading, additionalParams, context } = this.props;

    const filter = Object.keys(context).reduce((acc: string | undefined, cKey: string) => avalibleFiltersShop[cKey] && context[cKey], undefined);
    return (
      <React.Fragment>
        <ShopHeader
          className={'rounded'}
          searchFunction={this.search}
          placeholder={i18n.t('pryaniky.list.shop.search')}
          tag={Header}
          filters={additionalParams?.filters}
          onFilter={this.filter}
        />
        {
          additionalParams.isClosed ?
            <TypedListPlaceholder type={'shopClosed'} title={additionalParams.closedText} />
            :
            !data.length && isFinished ?
              <TypedListPlaceholder type={this.searchString ? 'search' : filter ? 'filter' : 'shop'} title={this.props.wcontext.filter && this.props.wcontext.filter.title} />
              :
              <ListLite
                tag={Masonry}
                tagAdditionalProps={{ breakPoints: [350, 700] }}
                skeleton="shop"
                skeletonCount={6}
                isFinished={isFinished}
                isLoading={isLoading}
                className='List-Wrap'
                loadMore={this.getData}>
                {data.map(el => (
                  <ShopItem
                    className={'rounded'}
                    key={el}
                    isHidePricesAndButtonBuy={additionalParams.isHidePricesAndButtonBuy}
                    isHideButtonBuy={additionalParams.isHideButtonBuy}
                    maxOrdersCount={additionalParams.maxOrdersCount}
                    maxOrdersPeriod={additionalParams.maxOrdersPeriod}
                    ordersCount={additionalParams.ordersCount}
                    additionalInformationBeforeOrder={additionalParams.additionalInformationBeforeOrder}
                    data={this.props.getListItem(this.listId, el)}
                  />
                ))}
              </ListLite>
        }
      </React.Fragment>
    );

  };
}

export const List = connect<IListStateProps, IDispatchProps, IListProps, IState>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
)(ListShopPresenter);
