import React, { FC, useState, memo, useEffect } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IStudentsStatusProps,
    IStudentsStatusOwnProps,
    IDispatchProps,
    IStudentsStatusStateProps,
    IStudentsStatusState
} from './interfaces'
import { connect, useSelector } from 'react-redux';
import './style.scss'
import FormControl from '@material-ui/core/FormControl';
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip'
import PieChart, { LabelProps } from 'react-minimal-pie-chart';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import { API } from 'utils/src/utils'
import SingleAutocomplete from 'uielements/src/MaterialElements/SingleAutocomplete'
import withSessionSuggester from 'blocks/HOCs/SingleAutocomplete/withSessionSuggester'
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
import Tabs, { ITabType } from 'uielements/src/TabsV2'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { useLazyQuery } from 'LMSModule/utils/hooks/useLazyQuery'
import { getSessionIdIfSingle, getSessionById } from 'LMSModule/redux/sagas/LMSTO/selectors'
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

const SessionSelect = withSessionSuggester(SingleAutocomplete)



export const Presenter: FC<IStudentsStatusProps> = ({
    className,
    cid,
    loadStudentsStatus,
    statuses,

}) => {
    const TABS: ITabType[] = [
        { value: 'active', content: tT('statistics.course_statistics.tabs.active.1') },
        { value: 'archive', content: tT('statistics.course_statistics.tabs.archive.1') }
    ]
    const [sid, setSid] = useState<string | undefined>(undefined)
    const [isArchived, setArchived] = useState(false)

    // const defaultSid = useSelector(getSessionIdIfSingle(cid))
    // const defaultSession = useSelector(getSessionById(defaultSid || ''))
    // const defaultSelected = defaultSession ? { title: defaultSession.name, value: defaultSession.id, id: defaultSession.id } : undefined
    // useEffect(() => {
    //     (defaultSid && !sid) && setSid(defaultSid)
    // }, [defaultSid])


    useEffect(() => {
        loadStudentsStatus(cid, sid)
    }, [sid, cid])
    const data = statuses.active.map((val: any) => ({
        ...val,
        title: tT(`statistics.course_statistics.title.${val.key}.1`)//val.label
    }))
    const archiveData = statuses.archive.map((val: any) => ({
        ...val,
        title: tT(`statistics.course_statistics.title.${val.key}.2`)//val.label
    }))
    const changeSelect = (item: ISelectizeItem | null) => {
        if (!item) setSid(undefined)
        else setSid(item.id)
    }
    const summary = data.map((v: any) => v.value).reduce((p: number, c: number) => p + c, 0)
    const archiveSummary = archiveData.map((v: any) => v.value).reduce((p: number, c: number) => p + c, 0)

    const [getExcel, excelLoading] = useLazyQuery(() => API.lms.studentsstatusexcel(cid, sid))

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Control')}>
            <Tabs
                className={cnClassName('Tabs')}
                size={'small'}
                onChange={(tab) => { tab.value === 'active' ? setArchived(false) : setArchived(true) }}
                selected={isArchived ? TABS[1] : TABS[0]}
                tabs={TABS}
            />
            <div className={cnClassName('Sessions')}>
                <FormControl fullWidth>
                    <SessionSelect
                        // value={defaultSelected}
                        archived={isArchived}
                        onSelect={changeSelect}
                        label={tT('statistics.course_statistics.select.session.1')}
                        courseId={cid}
                    />
                </FormControl>
                <Tooltip title={tT('statistics.reports.title.download.1')} >

                    <IconButton
                        // title={tT('statistics.reports.title.download.1')}
                        disabled={excelLoading}
                        onClick={getExcel}
                    // href={`https://${API.base.domain}/ru/Data/v3/statistics/lms/studentsstatusexcel?camelCase=true&courseId=${cid}${sid ? '&sessionId=' + sid : ''}`}
                    >
                        <DescriptionIcon />
                    </IconButton>
                </Tooltip>
            </div>

        </div>

        <div className={cnClassName('Data')}>

            <PieChart
                className={cnClassName('Chart')}
                data={(isArchived ? archiveData : data)}
                lineWidth={43}
                labelPosition={0}
                label={(val: LabelProps) => val.data.map(v => v.value).reduce((p, c) => p + c, 0)}
                labelStyle={{
                    fontSize: '25px',
                    // fontFamily: 'sans-serif',
                    fill: '#F0A70A',
                }}
            />

            <div className={cnClassName('Summary')}>
                <table className={cnClassName('Table')}>
                    <tbody>
                        <tr>
                            <td>
                                <div className={cnClassName('SummaryAnswers')}>{tT('statistics.course_statistics.title.1')}</div>
                            </td>
                            <td></td>
                        </tr>
                        {(isArchived ? archiveData : data).map((val: any, i: number) => <tr key={val.key}>
                            <td>
                                <div className={cnClassName('SummaryTitle')}>{val.title}</div>
                            </td>
                            <td>
                                <div
                                    className={cnClassName('Value')}
                                    style={{ color: val.color }}>
                                    <HelpTooltip help={<div>
                                        <span>{tT('statistics.course_statistics.in_archive.1')}</span>: <b>{archiveData[i].value}</b> {(archiveSummary || false) && <span>({Math.round(archiveData[i].value / archiveSummary * 100)}%)</span>}
                                    </div>}>
                                        <b>{val.value}</b> {(summary || false) && <span>({Math.round(val.value / summary * 100)}%)</span>}
                                    </HelpTooltip>
                                </div>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}

export default connect<IStudentsStatusStateProps, IDispatchProps, IStudentsStatusOwnProps, IStudentsStatusState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
