import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeCodeProps } from './Widget_type_code.index';
import './Widget_type_birthdays.scss';

import { default as WidgetPresenter } from '../Widget';

import { widgets } from "i.widgets";

const types = {
  common: require('blocks/Code/Code').Code,
}

const type = 'birthdays';

export default class WidgetTypeCodePresenter extends WidgetPresenter<IWidgetTypeCodeProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    return <Component {...this.props} {...this.props.widget.settings} rounded className={cnWidget({ type, subtype })} context={this.props.wcontext} relations={this.props.widget.relations} data={this.props.widget.data} tag={widgets.components.common} />
  }

}
