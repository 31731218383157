import {
    takeEvery,
    put,
    takeLeading,
    takeLatest,
    throttle,
    fork
} from 'redux-saga/effects';
import {
    call,
    select,
} from 'utils/src/saga.effects';

import actions from './actionsType'

import {
    pathNews,
    loadComments
} from 'News/redux/actions'

import {
    getNewsById,
    getCommentsValue,
    isLoadingComments
} from 'News/redux/saga/selectors'

import {
    handleLoadComments
} from 'News/redux/saga'

import {
    getDistributeFormById,
    getRewardedCommentsForNews
} from './selectors'

import {
    changeReward,
    pathReward,
    cancleAllReward
} from './actions'

import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'

import * as AT from './actions.d'

import { omit } from 'lodash'

import {
    API,
    getCookie
} from 'utils/src/utils'

import { rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { convertToRaw } from 'draft-js';


const handleRewardReplyAction = function* handleRewardReplyAction({ payload }: AT.ARewardReplyAction) {
    try {
        let { value, newsId, replyId } = payload

        const isLoad = yield* select(isLoadingComments(newsId))
        if (isLoad) return 0

        const news = yield* select(getNewsById(newsId)) as any;
        const rewardedComments = yield* select(getRewardedCommentsForNews(newsId))

        const { creativeTask } = news;
        const { fund } = creativeTask;
        const count = rewardedComments[replyId] || 0

        const newFund = fund + (count - value)

        if (0 > newFund) {
            value = count
        }

        yield put(pathNews({
            ...news,
            creativeTask: {
                ...creativeTask,
                fund: newFund
            }
        }))

        yield put(changeReward({
            newsId,
            data: { replyId, value }
        }))

    } catch (error) {
        console.warn(error)
    }
    yield;
    return 0;
}


const handleDistribute = function* handleDistribute({ payload }: AT.ADistributeAction) {
    try {
        const { newsId } = payload

        const news = yield* select(getNewsById(newsId)) as any;
        const distForm = yield* select(getDistributeFormById(newsId))
        const rewardedComments = yield* select(getRewardedCommentsForNews(newsId))


        const draftRaw = convertToRaw(distForm.text.getCurrentContent())
        //конвертируем рав в мапкдаун
        const comment = draftToMarkdown(draftRaw, rawToMd);

        const data = {
            comment,
            selectedGroupId: -1,
            data: Object.keys(rewardedComments).map((key) => ({
                replyUid: key,
                reasonList: [12],
                thanksCount: rewardedComments[key],
            })),
        };
        const request = yield* call(API.news.competitions.setEnd, newsId, data);
        // @ts-ignore
        const result = yield request.r

        if (result.error_code === 0) {
            yield put(pathNews({
                ...news,
                creativeTask: {
                    ...news.creativeTask,
                    state: 'Finished'
                }
            }))
        } else {
        }

    } catch (error) {
        console.warn(error)
    }
    yield;
    return 0;
}


const handleCancle = function* handleCancle({ payload }: AT.ACancleAction) {
    try {
        const { newsId } = payload

        const news = yield* select(getNewsById(newsId)) as any;

        const request = yield* call(API.news.competitions.setCancle, newsId)
        // @ts-ignore
        const result = yield request.r

        if (result.error_code === 0) {
            yield put(pathNews({
                ...news,
                creativeTask: {
                    ...news.creativeTask,
                    state: 'Canceled'
                }
            }))
        } else {

        }

    } catch (error) {
        console.warn(error)
    }
    yield;
    return 0;
}

/**
 * 
 * Если нету "наградных" комментариев:
 * 1. загрузит все комментарии(если не загружены)
 * 2. исключит из них комментарии автора конкурса
 * 3. распределит весь фонд по оставшися комментариям по формуле Math.floor(fund / keysWithoutAuthor.length)
 *
 *
 * Если есть "наградные" комментарии(уже кто то выбран в ручную):
 * 1. сбросит все награды
 * 2. выберет эти(которые были назначены) комментарии
 * 3. исключит из них автора публикации
 * 4. распределит весь фонд по оставшися комментариям по формуле Math.floor(fund / keysWithoutAuthor.length)
 *
 * @param param0 
 */
const handleRewardAll = function* handleRewardAll({ payload }: AT.ARewardAllAction) {
    try {
        const { newsId } = payload

        const rewardedComments = yield* select(getRewardedCommentsForNews(newsId))

        let keys = Object.keys(rewardedComments || {})
        if (keys.length === 0) {
            yield* call(handleLoadComments, loadComments({ commentsCount: 0, id: newsId, all: true }))
        } else {
            yield put(cancleAllReward({ newsId }))
        }

        const news = yield* select(getNewsById(newsId)) as any;

        const commentsValues = yield* select(getCommentsValue)

        const { creativeTask, comments = [], user } = news;
        const { fund } = creativeTask;

        const forReward: string[] = keys.length === 0 ? comments : keys

        const keysWithoutAuthor = forReward.filter(replyId => commentsValues[replyId].user.id !== user.id)

        const newRewarded = keysWithoutAuthor.reduce((acc: any, replyId: string) => {
            acc[replyId] = Math.floor(fund / keysWithoutAuthor.length)
            return acc
        }, {})

        const values = Object.values<number>(newRewarded || {})
        const summary = values.reduce((acc, cur) => acc + cur, 0)
        const newFund = fund - summary

        yield put(pathNews({
            ...news,
            creativeTask: {
                ...creativeTask,
                fund: newFund
            }
        }))

        yield put(pathReward({
            newsId,
            data: newRewarded
        }))

    } catch (error) {
        console.warn(error)
    }
    yield;
    return 0;
}

/**
 * module root saga
 */
const root = function* root() {
    yield takeLeading(actions.REWARD_REPLY, handleRewardReplyAction)
    yield takeLeading(actions.DISTRIBUTE_COMPETITION, handleDistribute)
    yield takeLeading(actions.CANCLE_COMPETITION, handleCancle)
    yield takeLeading(actions.REWARD_ALL, handleRewardAll)
};

/**
 * export root saga
 */
export default root;