import React, { useEffect } from 'react';
import { IVoteView, cnVoteView, mapVoteStateToProps, mapVoteActionToProps } from './Vote.index';
import { connect } from 'react-redux';
import './Vote.scss';
import queryString from 'query-string';
import actTypes from '../../redux/actionsTypes/News';
import { useIsLoading, useIsError } from 'utils/src/CommonRedux/LoadIndicator';
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import { VoteItem } from './Item';
import ListPlaceholder from 'uielements/src/ListPlaceholder'
import { Button } from 'uielements/src';
import { Translate } from 'localizations/Translate';
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin';
import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg';
import { Masonry } from 'uielements/src/Masonry/Masonry';
import { PostSkeleton } from 'blocks/Skeletons/Posts/Post-Skeleton';
import NewsItem from '../NewsItem'
import { withNewsContext, NewsContextProps } from '../../contexts/news'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import openNewsDialog from 'blocks/Dialogs/News/DialogNewsView/DialogNewsView'
import i18n from 'localizations/i18n';
import { useTimelineLoader } from 'News/utils/hooks'
// import { WorkflowGrid } from 'muicomponents/src/WorkflowGrid/WorkflowGrid'
const LoadButton = withLoadSpin(Button);

const NewsAction = withNewsContext(({ newsId }: NewsContextProps) => {
  async function edit() {
    try {
      await openNewsDialog({ newsId, editOnly: true })
    } catch (e) { }
  }
  return <Button theme="unstyled" className={'service-edit'} onClick={edit}>{i18n.t('pryaniky.post.actions.edit')}</Button>
})

const VoteViewPresenter: React.FC<IVoteView.Props> = ({
  keys,
  count,
  params,
  className,
  isFinished,
  loadNewsList
}) => {
  useEffect(() => {
    ComponentInjector.getInstance().addNode('news_service_actions', <NewsAction />, 'edit')
    return () => {
      ComponentInjector.getInstance().removeNode('news_service_actions', 'edit')
    }
  }, [])

  const { isFailed, isLoading, loadMore } = useTimelineLoader(params, keys, params.count)

  // проброшены параметры для того чтобы сделать более уникальными состояния выполнения саг
  // const isLoading = useIsLoading([actTypes.LOAD_NEWS + '-' + queryString.stringify(params)]);

  // const { isFailed } = useIsError([actTypes.LOAD_NEWS + '-' + queryString.stringify(params)]);

  // const loadMore = React.useCallback(() => loadNewsList({ params, count, skipCount: keys.length }), [params, keys.length]);

  // React.useEffect(() => {
  //   loadNewsList({ params, count: 10, skipCount: 0 })
  // }, [queryString.stringify(params)])
  // return <WorkflowGrid/>

  const triggerRef = useInfiniteScroll({
    loadMore,
    isLoading,
    isFinished
  }, { rootMargin: (window.innerHeight * 1) + 'px', threshold: 0 });

  return (
    <div className={cnVoteView({}, [className])}>
      <Translate i18nKey={`
        titleEmpty:pryaniky.timeline.notFound.title;
        textEmpty:pryaniky.timeline.notFound.title;
        titleError:pryaniky.timeline.error.title;
        textLoad:loading
        `}>
        <ListPlaceholder
          className={cnVoteView('Placeholder')}
          isFinished={isFinished}
          isLoading={isLoading}
          isError={isFailed}
          itemsCount={keys.length}
          loadNode={
            <Masonry
              breakPoints={[350, 700]}
            >
              {(new Array(3)).fill(<PostSkeleton />)}
            </Masonry>
          }
          // titleEmpty={i18n.t('pryaniky.timeline.notFound.title')}
          // textEmpty={i18n.t('pryaniky.timeline.notFound.text')}
          // titleError={i18n.t('pryaniky.timeline.error.title')}
          textError={
            <Button onClick={loadMore} disabled={isLoading}>
              <Translate i18nKey={'pryaniky.timeline.error.reload.button'} />
            </Button>
          }
        // textLoad={'load'}
        >
          <Masonry
            breakPoints={[350, 700]}
          >
            {
              keys.map((key) => {
                return <NewsItem
                  key={key}
                  id={key}
                  ViewComponent={VoteItem}
                />
              })
            }
          </Masonry>
          <div className={cnVoteView('More')}>
            {isFailed && <>
              <ErrorMsg type="error" className={cnVoteView('ErrorMsg')}>
                <Translate i18nKey={'pryaniky.timeline.error.message'} />
                {/* {i18n.t('pryaniky.timeline.error.message')} */}
              </ErrorMsg>
              <Button className={cnVoteView('Trigger')} onClick={loadMore} disabled={isLoading}>
                <Translate i18nKey={'pryaniky.timeline.error.reload.button'} />
              </Button>
            </>}
            {(!isFinished && !isFailed) && (
              <LoadButton
                forwardedRef={triggerRef}
                isLoading={isLoading}
                className={cnVoteView('Trigger')}
                onClick={isLoading ? void 0 : loadMore}>
                <Translate i18nKey={'more'} />
              </LoadButton>
            )}
          </div>
        </ListPlaceholder>
      </Translate>
    </div>
  )
};

export const VoteView = connect(
  mapVoteStateToProps,
  mapVoteActionToProps
)(VoteViewPresenter);