import React, {memo} from 'react';
import { Skeleton } from 'uielements/src';
import './Post-Skeleton.scss';

export const PostSkeleton = memo(() => (
  <div className="PostSkeleton">
    <div className="PostSkeleton-Header">
      <Skeleton shape="circle" />
      <div style={{ flexGrow: 1, marginLeft: 24 }}>
        <Skeleton shape="line" style={{ width: '60%' }} />
        <Skeleton size="xs" shape="line" />
      </div>
    </div>
    <Skeleton size="xs" shape="line" />
    <Skeleton size="xs" shape="line" style={{ width: '60%' }} />
    <Skeleton size="xs" shape="line" style={{ width: '80%' }} />
    <Skeleton size="xs" shape="line" style={{ width: '40%' }} />
    <hr className="PostSkeleton-Divider" />
  </div>
));
