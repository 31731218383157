import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IQuestionListProps extends IClassNameProps {
    normalQuestions: any;
    questions: string[];
    questionsTimers?: any;
    resultAnswersMap: { [key: string]: any };
    resultAnswers: string[];
    detailsRender?: (question: any) => React.ReactNode;
    highlightCorrectness?: boolean;
    attmptsAvailable: boolean
    allowShow?: boolean
}

export const cnQuestionList = cn('ResultQuestionList');