import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { nextActiveQuestion,sendAnswer, setFinishedQiuz } from 'redux/actions/Quiz'


export interface IQuizPassing extends IQuizPassingStateProps, IQuizPassingDispatchProps {
  quizId: string
  highlightCorrectness: boolean
} 

export const mapStateToProps = (state: IState) => {
  return {
    data: state.quiz.quiz.openQuizData.questions[state.quiz.quiz.activeQuistionIdx],
    totalQuestions: state.quiz.quiz.openQuizData.questions.length,
    activeQuizIdx: state.quiz.quiz.activeQuistionIdx,
    answersIds: state.quiz.quiz.answersIds,
    answered: state.quiz.quiz.answered,
    textColor: state.quiz.quiz.openQuizData.textColorString,
    setServerAnswered: state.quiz.quiz.setServerAnswered
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  nextActiveQuestion,
  sendAnswer,
  setFinishedQiuz
}, dispatch);

export type IQuizPassingStateProps = ReturnType<typeof mapStateToProps>
export type IQuizPassingDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnQuizPassing = cn('QuizPassing')