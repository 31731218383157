import React from 'react';
import { Button } from 'uielements/src';
import queryString from 'query-string';
import { API } from 'utils/src/utils';
import './NotificationsSettings_NetworkGroup.scss';
import { IGroupItem, INetworkItem, cnNotificationsSettingsNetworkGroup, INotificationsSettingsNetworkGroupProps, INotificationsSettingsNetworkGroupState, mapStateToProps, INotificationsSettingsNetworkGroupOwnProps, INotificationsSettingsNetworkGroupStateProps } from './NotificationsSettings_NetworkGroup.index';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import NotificationsSettingsGroup from './NotificationsSettings_Groupv2'
import { Groups } from './Groups/NotificationSettings_GroupSettings'
import { NetworkItem } from './NetworkItem/NotificationsSettings_NetworkItemSettings'


export class NotificationsSettingsNetworksPresenter extends React.Component<
  INotificationsSettingsNetworkGroupProps,
  INotificationsSettingsNetworkGroupState
  > {

  constructor(props: INotificationsSettingsNetworkGroupProps) {
    super(props);
    this.state = {
      showNetworksGroup: {},
      notificationTemplate: [],
      notificationNetworkTemplate: [],
      networksData: []
    }
  }

  componentDidMount() {
    this.getNatificationTemplatesList()
  }

  public render() {
    const { showNetworksGroup } = this.state;

    const networks = []
    const networksIds = this.props.networks.map((item: any) => {
      return item.id
    })    

    if (this.props.parentNetworkId && !networksIds.includes(this.props.parentNetworkId)) {
      networks.push({ name: 'Межсетевые группы', id: this.props.parentNetworkId })
    }

    networks.push(...this.props.networks)

    const setShowNetworkGroup = (id: string) => {

      this.setState({
        showNetworksGroup: {
          ...showNetworksGroup,
          [id]: !showNetworksGroup[id]
        }
      }, () => { })
    }

    if (networks.length === 1) {
      if (!this.state.showNetworksGroup[networks[0].id]) {
        this.setState({
          showNetworksGroup: {
            ...showNetworksGroup,
            [networks[0].id]: true
          }
        }, () => { })
      }
    }

    return (

      <div>

        {!queryString.parse(this.props.location.search).gid && !queryString.parse(this.props.location.search).networkId ? networks.map((item: any, i: number) => (
          <div className={cnNotificationsSettingsNetworkGroup('NetworkItemWrapper')}>
            {networks.length > 1 &&
              // USERID
              <NetworkItem userId={this.props.userId} item={item} notificationsTemplate={this.state.notificationTemplate} href={"?" + queryString.stringify({ section: 'groups', networkId: item.id })}/>
            }
            <div className={cnNotificationsSettingsNetworkGroup('Groups')}>
              {
                showNetworksGroup[item.id] && <Groups networkId={item.id} currentNetworkId={this.props.currentNetworkId} parentNetworkId={this.props.parentNetworkId} />
              }
            </div>

            {networks.length > 1 && 
            <Button
              onClick={() => setShowNetworkGroup(item.id)}
              className={cnNotificationsSettingsNetworkGroup('ShowGroups')}
              type='rlink'
              theme='unstyled'>
              {this.state.showNetworksGroup[item.id] ? 'Скрыть список групп' : 'Раскрыть список групп'}
            </Button>}
          </div>

        )) :
          <NotificationsSettingsGroup networks={networks} />
        }
      </div>
    )

  }

  private getNatificationTemplatesList() {
    API.groups.getNotificationsTemplate()
      .r.then((d: any) => {
        if (d.error_code === 0) {
          const data = d.data.map((item: any) => {
            return {
              title: item.displayName,
              ...item
            }
          })
          this.setState({ notificationTemplate: data })
        }

      })
  }
}

export const NotificationsSettingsNetworkGroup = withRouter(
  connect(
    mapStateToProps
  )
  (NotificationsSettingsNetworksPresenter)
)

export default NotificationsSettingsNetworkGroup;

