import React, { useMemo } from "react";
import { useGlobalStoreForWidget, useWidget } from "Widgets_v2/hooks";
import { NWidget } from "./Widget.index";
import { WidgetsTypes } from 'utils/src/widgets/types';
import queryString from 'query-string';
import { getCurrentUserIsAdmin } from "utils/src/CommonRedux/base/selectors";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";

// advent calendar
import { AdventCalendar } from 'blocks/AdventCalendar/AdventCalendar';

// albums
import { Photoalbums } from 'blocks/Photoalbums/Photoalbums';
import { AlbumView } from 'blocks/Photoalbums/AlbumView/AlbumView';
import { PhotoView } from 'blocks/Photoalbums/PhotoView/PhotoView';

// badges list
import { Badges } from 'blocks/Badges/Badges';

// banners
import { Banners } from 'blocks/Banners/Banners';

// birthdays
import { BirthdaysWidget } from 'blocks/BirthdaysWidget/BirthdaysWidget';

// blocks
import { Blocks } from 'blocks/Blocks/Blocks';

// calendars
import { Calendar } from 'blocks/Calendar/Calendar';
import { CalendarMini } from 'blocks/CalendarMini/CalendarMini';

// gift cards
import { Card } from 'blocks/Card/Card';

// charts

// code
import { Code } from 'blocks/Code/Code';

// courses my
import { CourseMyNew } from 'LMSModule/LMS/Widgets/CourseMyNew/CourseMyNew';

// currency timeline
import { CurrencyTimeline } from 'blocks/CurrencyTimeline/CurrencyTimeline';

// editor js
import { Wiki as EditorJsWiki } from 'blocks/Wiki/WikiEditorJs';

// file
import { Attachments } from 'uielements/src/Attachments/Attachments';
import { Preview } from 'uielements/src/Preview/Preview';

// files
import { Files } from 'blocks/Files/Files';

// filters
import { Filters } from 'blocks/Filters/Filters';
import { FiltersEvents } from 'blocks/Filters/_type/Filters_type_events';
/**
 * @deprecated
 */
import { a } from 'blocks/Filters/_type/Filters_type_polls';
import { FiltersCreativeTasks } from 'blocks/Filters/_type/Filters_type_creativetasks';
import { FiltersIdeas } from 'blocks/Filters/_type/Filters_type_ideas';
import { FiltersTypeShop } from 'blocks/Filters/_type/Filters_type_shop';
import { FiltersCalendar } from 'blocks/Filters/_type/Filters_type_calendar';
import { FiltersTypeTimeline } from 'blocks/Filters/_type/Filters_type_timeline';
import { FiltersTypeLeaderboard } from 'blocks/Filters/_type/Filters_type_leaderboard';
import { FilterSubnetworks } from 'blocks/Filters/_type/Filters_type_subnetworks';
import { FiltersTypeWikilist } from 'blocks/Filters/_type/Filters_type_wikilist';
import { FiltersTypeGroups } from 'blocks/Filters/_type/Filters_type_groups';
import { FiltersTypeUsers } from 'blocks/Filters/_type/Filters_type_users';
import { FiltersTypeAfter35 } from 'blocks/Filters/_type/Filters_type_after35';
import { FiltersTypeQuestModerate } from 'blocks/Filters/_type/Filters_type_questmoderate';
import { FiltersTypeQuests } from 'blocks/Filters/_type/Filters_type_quests';
import { FiltersTypeTagCat } from 'blocks/Filters/_type/Filters_type_tag_cat';
import { FiltersWorkflows } from 'blocks/Filters/_type/Filters_type_workflows';
import { FiltersWorkflowsBar } from 'blocks/Filters/_type/Filters_type_workflows_bar';

// groups
import { GroupsWidget } from 'blocks/GroupsWidget/GroupsWidget';

// info
import { InfoBlock } from 'blocks/InfoBlock/InfoBlock';

// journal
import { Journal } from 'blocks/Journal/Journal';

// kpi
import Main from 'KPIModule/containers/Main';

// licence
import { License } from 'blocks/License/License';

// lists
// main users list
import { List as ListUsers } from 'blocks/List/_type/users/List_type_users';
// main users list by admin
import { List as ListUsersSelector } from 'blocks/List/_type/users/List_type_users_selector';
// main groups list
import { List as ListGroups } from 'blocks/List/_type/groups/List_type_groups';
// main groups list by admin
import { List as ListGroupsSelector } from 'blocks/List/_type/groups/List_type_groups_selector';
import { List as ListGroupsTree } from 'blocks/List/_type/groups/List_type_groups_tree';
import { List as ListGroupsTreeSelector } from 'blocks/List/_type/groups/List_type_groups_tree_selector';

import { List as ListGroupUsersSelector } from 'blocks/List/_type/users/List_type_group_users_selector';
import { List as ListLeaderboard } from 'blocks/List/_type/users/List_type_users_leaderboard';
import { List as ListMyGroups } from 'blocks/List/_type/groups/List_type_groups_myGroups';
import { List as ListQuestsmoderate } from 'blocks/List/_type/quests/List_type_quests_moderate';
import { List as ListQuests } from 'blocks/List/_type/quests/List_type_quests';
import { ListWikiNew } from 'blocks/WikiListNew/List_type_wiki';
import { List as ListBadgeMembers } from 'blocks/List/_type/users/List_type_users_badge';
import { List as ListShop } from 'blocks/List/_type/shop/List_type_shop';
import { OrdersList } from 'blocks/OrdersList/OrdersList';



// widgets to set structure
import { WidgetLayout } from 'Widgets_v2/Layout';
import { WidgetLayoutHorizontal } from 'Widgets_v2/LayoutHorizontal';

// vacancies
import { Vacancy } from 'blocks/Vacancies/Vacancy/Vacancy';
import { VacanciesList } from 'blocks/Vacancies/List/VacanciesList';
import { getCookie } from "utils/src";

export const useWidgetComponent = (id: NWidget.Props['id'], props: Pick<NWidget.Props, 'className' | 'children'>) => {

    const {
        widget,
        type,
        context,
        settings,
        viewType,
    } = useWidget(id);

    const {
        edit
    } = useGlobalStoreForWidget();

    /**
     * need to move to other hook
     * use in album
     */
    const params = useParams<{ [key: string]: any }>();

    /**
     * need to move to other hook
     * use in album
     */
    const location = useLocation();

    /**
     * need to move to other hook
     * use in album
     */
    const history = useHistory();

    const isAdmin: boolean = useSelector(getCurrentUserIsAdmin);

    const {
        hide,
        isHidden
    } = widget as typeof widget & { hide?: boolean };// пока так, что бы сборка заработала

    const {
        className,
        children
    } = props;

    const Component = useMemo(() => {
        // if (
        //   this.props.authUser.baseData.isAdmin && (
        //     this.props.widget.type === widgets.types.list + '/users' || +
        //     this.props.widget.type === widgets.types.list + '/groups' || +
        //     this.props.widget.type === widgets.types.list + '/groupsTree' 
        //   )
        //   ) {
        //   return types[utils.widgetSubtype(this.props.widget.type + 'Selector') || 'common'] || types['common'];
        //   }
        // return types[utils.widgetSubtype(this.props.widget.type) || 'common'] || types['common'];
        switch (type) {

            case WidgetsTypes.adventCalendar: {
                return <AdventCalendar id={id} edit={edit} />
            }

            case WidgetsTypes.album: {
                const requestParams = queryString.parse(location.search)
                if (params.aid) {
                    return <>
                        <AlbumView aid={params.aid} gid={context.gId} />
                        {
                            requestParams.fid
                                ? <PhotoView
                                    gid={context.gId}
                                    aid={requestParams.aid as string}
                                    id={requestParams.fid as string}
                                    fname={requestParams.fin as string}
                                    open={true}
                                    onClose={() => { history.push(`/group/${context.gId}/albums/${params.aid}/`) }}
                                />
                                : null
                        }
                    </>
                }
                if (context.photoView) {
                    return <>
                        <AlbumView aid={params.aid || context.gId} gid={context.gId} photoView={context.photoView} />
                        {
                            requestParams.fid
                                ? <PhotoView
                                    gid={context.gId}
                                    aid={requestParams.aid as string}
                                    id={requestParams.fid as string}
                                    fname={requestParams.fin as string}
                                    open={true}
                                    onClose={() => { history.push(`?album=view`) }}
                                />
                                : null
                        }
                    </>
                }
                return <Photoalbums gid={context.gId} />
            }

            // all badges list
            case WidgetsTypes.badgesList: {
                return <Badges filter={(settings as any)?.tag?.id} />;
            }

            case WidgetsTypes.banner: {
                return <Banners
                    className={className}
                    settings={settings || undefined}
                    widgetId={id}
                />
            }

            case WidgetsTypes.birthdays: {
                return <BirthdaysWidget
                    settings={settings as any}
                />
            }

            case WidgetsTypes.blocks: {
                return <Blocks data={(settings as any)?.items || []} />
            }

            // big calendar
            case WidgetsTypes.calendar: {
                return <Calendar context={context} newsId={context.nId} />
            }

            // mini calendar
            case WidgetsTypes.calendarmini: {
                return <CalendarMini />;
            }

            case WidgetsTypes.card: {
                return <Card />;
            }

            /**
             * @todo refactor
             * remove get data logic from widget
             */
            // case WidgetsTypes.charts:
            // case WidgetsTypes["charts/area"]:
            // case WidgetsTypes["charts/bagel"]:
            // case WidgetsTypes["charts/bars"]:
            // case WidgetsTypes["charts/line"]:
            // case WidgetsTypes["charts/pie"]: {
            //     return <></>
            // }

            case WidgetsTypes.code: {
                return <Code {...settings} rounded data={widget.data as any} {...{} as any} />
            }

            /**
             * @todo refactor
             * remove get data logic from widget
             */
            case WidgetsTypes.context: {
                return <>
                    context:
                    <pre children={JSON.stringify(context, undefined, 2)} />
                </>
            }

            case WidgetsTypes.coursesMy: {
                return <CourseMyNew settings={settings} params={widget.data} />
            }

            case WidgetsTypes.currencyTimeline: {
                return <CurrencyTimeline currentUserId={context.uId} />
            }

            /**
             * @todo refactor
             * need remove save logic from widget
             * widget & updateWidget not use in EditorJsWiki
             */
            case WidgetsTypes.editorjs: {
                const saveData = (d: any) => {
                }
                const saveWidgetData = () => { }
                return <EditorJsWiki widget={undefined} updateWidget={() => { }} saveWidgetData={saveWidgetData} saveData={saveData} data={widget.data || undefined} />
            }

            case WidgetsTypes.file: {
                if (!widget.data) return null;
                return <div style={{ padding: '24px' }}>
                    <Preview
                        files={widget.data as any}
                    />

                    <Attachments
                        disabledType={[]}
                        files={widget.data as any}
                        state={'view'}
                    />
                </div>
            }

            case WidgetsTypes.files: {
                // '...{} as any' to props because typing in old filters is wrong
                return <Files gid={context.gId} />
            }

            case WidgetsTypes.filters: {
                // '...{} as any' to props because typing in old filters is wrong
                return <Filters context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/events"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersEvents context={context} relations={widget.relations} {...{} as any} />
            }

            /**
             * @deprecated
             */
            case WidgetsTypes["filters/polls"]: {
                return null;
            }

            case WidgetsTypes["filters/creativetasks"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersCreativeTasks context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/ideas"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersIdeas context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/shop"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersTypeShop context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/calendar"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersCalendar context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/timeline"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersTypeTimeline context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/leaderboard"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersTypeLeaderboard context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/subnetworks"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FilterSubnetworks context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/wikilist"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersTypeWikilist context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/groups"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersTypeGroups context={context} relations={widget.relations} {...{} as any} />
            }


            case WidgetsTypes['filters/users']: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersTypeUsers context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/after35"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersTypeAfter35 context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/questsmoderate"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersTypeQuestModerate context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/quests"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersTypeQuests context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/tagcat"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersTypeTagCat context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/workflows"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersWorkflows context={context} relations={widget.relations} {...{} as any} />
            }

            case WidgetsTypes["filters/workflowsBar"]: {
                // '...{} as any' to props because typing in old filters is wrong
                return <FiltersWorkflowsBar context={context} relations={widget.relations} {...{} as any} />
            }

            /**
             * @todo refactor
             * refactor all check logic
             */
            case WidgetsTypes.grid: {
                return <></>
            }


            /**
             * @todo refactor
             * remove widget props from GroupsWidget
             */
            case WidgetsTypes.groups: {
                return <GroupsWidget context={context} {...{} as any} />
            }

            /**
             * @todo refactor
             * remove tinymce block logic from widget
             */
            case WidgetsTypes.html: {
                return <></>
            }

            /**
             * @todo refactor
             * move src logic to other component
             */
            case WidgetsTypes.iframe: {
                const frameId = `frame-${widget.id}`;
                let prepairedSettings = settings as any;
                if (!settings || !(settings as any).src) {
                    console.error(`IFRAME WIDGET SETINGS ERROR! widgteId: ${widget.id}, settings: ${widget.settings}`);
                    prepairedSettings = {
                        src: '/empty'
                    }
                }
                let src: string = (settings as any).src;
                if (src.includes('?')) {
                    src = src.split('?').join(`?frameId=${frameId}`);
                } else {
                    src = src + `?frameId=${frameId}`;
                }
                if ((settings as any).withAuth) {
                    const authString = `authAt=${getCookie('authAt')}&authUid=${getCookie('authUid')}`;
                    if (src.includes('?')) {
                        src = src.split('?').join(`?${authString}&`);
                    } else {
                        src = src + `?${authString}`;
                    }
                }
                const frameProps: any = {
                    height: '150px'
                };
                if (!isNaN(Number((settings as any).height)) && Number((settings as any).height) > 150) frameProps.height = (settings as any).height + 'px';
                return <iframe id={frameId} src={src} width={'100%'} {...frameProps} />
            }

            /**
             * @todo check types withRouter
             */
            case WidgetsTypes.info: {
                return <InfoBlock settings={settings} context={context} {...{} as any} />
            }

            /**
             * @todo check Journal props
             */
            case WidgetsTypes.journal: {
                const jContext = { ...context, wId: widget.id, ...widget.settings };
                return <Journal context={jContext} />
            }

            case WidgetsTypes.kpi: {
                return <Main userId={context.uId} />
            }

            // base layout
            case WidgetsTypes['layout']: {
                return <WidgetLayout {...{ id, className, hide, isHidden, children }} />
            }

            // horizontal layout
            case WidgetsTypes['layout/horizontal']: {
                return <WidgetLayoutHorizontal {...{ id, className, hide, isHidden, children }} />
            }

            case WidgetsTypes.license: {
                return <License widget={widget as any} settings={settings as any} />
            }

            case WidgetsTypes.list: {
                return <></>;
            }

            // base users list
            case WidgetsTypes["list/users"]:
            case WidgetsTypes["list/usersSelector"]: {
                const ULC = isAdmin ? ListUsersSelector : ListUsers;
                // '...{} as any' to props because typing in old lists is wrong
                return <ULC id={`wid-${id}`} context={context} wcontext={context} {...{} as any} />
            }

            // base groups list
            case WidgetsTypes['list/groups']:
            case WidgetsTypes['list/groupsSelector']: {
                const GLC = isAdmin ? ListGroupsSelector : ListGroups;
                // '...{} as any' to props because typing in old lists is wrong
                return <GLC id={`wid-${id}`} context={context} wcontext={context} {...{} as any} />
            }

            case WidgetsTypes["list/groupsTree"]:
            case WidgetsTypes["list/groupsTreeSelector"]: {
                const GTLC = isAdmin ? ListGroupsTreeSelector : ListGroupsTree;
                // '...{} as any' to props because typing in old lists is wrong
                return <GTLC id={`wid-${id}`} context={context} wcontext={context} {...{} as any} />
            }

            case WidgetsTypes['list/groupUsersSelector']: {
                // '...{} as any' to props because typing in old lists is wrong
                return <ListGroupUsersSelector id={`wid-${id}`} context={context} {...{} as any} />
            }

            case WidgetsTypes["list/leaderboard"]: {
                // '...{} as any' to props because typing in old lists is wrong
                return <ListLeaderboard id={`wid-${id}`} context={context} {...{} as any} />
            }

            case WidgetsTypes["list/myGroups"]: {
                // '...{} as any' to props because typing in old lists is wrong
                return <ListMyGroups id={`wid-${id}`} context={context} {...{} as any} />
            }

            case WidgetsTypes["list/questsmoderate"]: {
                // '...{} as any' to props because typing in old lists is wrong
                return <ListQuestsmoderate id={`wid-${id}`} context={context} {...{} as any} />
            }

            case WidgetsTypes["list/quests"]: {
                // '...{} as any' to props because typing in old lists is wrong
                return <ListQuests id={`wid-${id}`} context={context} {...{} as any} />
            }

            case WidgetsTypes["list/wikilist"]: {
                // '...{} as any' to props because typing in old lists is wrong
                return <ListWikiNew id={`wid-${id}`} context={context} {...{} as any} />
            }

            case WidgetsTypes["list/badgeMembers"]: {
                // '...{} as any' to props because typing in old lists is wrong
                return <ListBadgeMembers id={`wid-${id}`} context={context} {...{} as any} />
            }

            case WidgetsTypes["list/shop"]: {
                // '...{} as any' to props because typing in old lists is wrong
                return <ListShop id={`wid-${id}`} context={context} {...{} as any} />
            }

            case WidgetsTypes["list/orders"]: {
                return <OrdersList />
            }


            /**
             * @stopedhere
             */








            case WidgetsTypes.vacancies:
                return <VacanciesList />;

            case WidgetsTypes.vacancy:
                return <Vacancy />;

            default:
                return null;
        }
    }, [type, settings, context]);

    return Component;
};