import React from 'react';
import { cn } from '@bem-react/classname';
import './NotFound.scss';
import fourOhFour from 'assets/svg/NewNotFound.svg';
import fourOhFourRed from 'assets/png/404.red.png';
import i18n from 'localizations/i18n';
import { Button } from 'uielements/src/Button/Button';
import { RouteProps, RouteComponentProps } from 'react-router';
import { Header } from 'blocks/HeaderMui/Header';
import { Background } from 'blocks/Background/Background';
import { Route } from 'react-router-dom';

const cnNotFound = cn('NotFound');

export const NotFound: React.FC = () => {
  React.useEffect(() => {
    const background = document.querySelector('.Background');
    background && background.classList.add('Background_notFound');
    return () => void (background && background.classList.remove('Background_notFound'));
  });
  return (
    <div className={cnNotFound({}, ['Page'])}>
      <img src={fourOhFour} className={cnNotFound('Image')} />
      <img src={fourOhFourRed} className={cnNotFound('ImageRed')} />
      <h3 className={cnNotFound('Title')}>{i18n.t('pryaniky.notFound.title')}</h3>
      <p className={cnNotFound('Text')}>
        {i18n.t('pryaniky.notFound.back') + " "}
        {i18n.t('pryaniky.notFound.link')}      
        {i18n.t('pryaniky.notFound.search')}
      </p>
      {/* <Button type="rlink" main padding='md' className={cnNotFound('Link')} href="/Dash">
      {i18n.t('pryaniky.notFound.link')}
      </Button> */}

    </div>
  );
};

// interface INotFoundRouteProps extends RouteProps {
//   component: React.ComponentType<RouteComponentProps>;
// }

// export const NotFoundRoute = () => {
//   return(
//       <>
//         <Header />
//         <Background />
//         <NotFound />
//         <LeftMenu />
//       </>
// )};
