/**
 * @packageDocumentation
 * @module Widget_Settings_virtualUser
 */
import { JFdictionary } from "../dictionary";
import { ITagsCloudData } from "utils/src/requests/models/api.tagscloud";

const { renderers } = JFdictionary;

export interface ITagsCloudItem extends ITagsCloudData {}


export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.tagsColleactionsInput,
      scope: "#/properties/tagsCloud"
    },
  ]
}