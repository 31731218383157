import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    rightPercent: number
    wrongPercent: number
    waitModerationCount?: number
    moderatedAnswersCount?: number
}

export const cnClassName = cn('TestSummary');