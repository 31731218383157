import React, { FC, useEffect, ReactText, ComponentProps } from 'react';
import { usePageHistory } from './PageHistoryNew.hooks';
import { HistoryBox, HistoryList } from './PageHistoryNew.styled';
import { ListItemButton } from 'muicomponents/src/ListItem/ListItem';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { Close } from 'muicomponents/src/Icons';
import { formatDateSTD } from 'utils/src/utils.dates';
import CommonmarkRender from 'uielements/src/CommonmarkRender';
// import { cnPageHistoryItemBox } from '../PagesMenuMuiBar.index'
import { Avatar } from 'uielements/src/index';
import { Translate } from 'localizations/Translate';
import * as utilsProj from 'utils.project/utils.project';
import i18n from 'localizations/i18n';
import { toast } from 'react-toastify';
import { store } from 'redux/store';
import { modalChangeDate } from 'redux/actions/Modals';
import * as utils from 'utils/src/utils';
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import ContextMenuComponent from 'uielements/src/ContextMenu/ContextMenu'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Popover } from 'muicomponents/src/Popover';
import './PageHistoryNew.scss';
import { background } from 'uielements/src/Chart';
import {
    Divider,
    Box,
    FormControlLabel,
    Checkbox,
    Button,
    FilesUploader,
} from 'muicomponents/src';
import { cn } from '@bem-react/classname';
import { CustomScrollbars } from 'uielements/src';

export const cnPageHistoryItemBox = cn("PageHistoryItemBox");

export const ContextMenuPresenter: FC<any> = ({ pageUrl, versionId }) => {

    const contextActions = [

        {
            content: <Translate i18nKey={'pryaniky.wikilist.item.goToVersion'} />,
            action: 'goToVersion'
        },

    ]
    const [popoverAnchor, setPopoverAnchor] = React.useState<{ top: number; left: number } | null>(null);

    const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const rect = event.currentTarget.getBoundingClientRect();
        setPopoverAnchor({ top: rect.top + (rect.height / 2), left: rect.left + (rect.width / 2) });
    };

    const closePopover = () => {
        setPopoverAnchor(null);
        // setRemoveShure(false);
    }

    const clickPopoverHandle: ComponentProps<typeof Popover>['onClick'] = (event) => {
        event?.stopPropagation();
        closePopover();
    };

    const closePopoverHandle: ComponentProps<typeof Popover>['onClose'] = (event) => {
        closePopover();
    };

    const openedPopover = Boolean(popoverAnchor);

    const clickAction = (action: string) => {
        switch (action) {
            case 'edit':

                break;


            default:
                break;
        }
    }


    return (
        <>
            <IconButton
                style={{ background: "#fff" }}
                size="small"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => openPopover(event)}
                color={"primary"}
            >
                <MoreVertIcon style={{ fontSize: "26px" }} />
            </IconButton>
            <Popover
                className={cnPageHistoryItemBox('ContextMenu')}
                BackdropComponent={React.Fragment}
                open={popoverAnchor ? true : false}
                // anchorEl={popoverAnchor}
                anchorReference="anchorPosition"
                anchorPosition={popoverAnchor || { top: 0, left: 0 }}
                onClick={clickPopoverHandle}
                onClose={closePopoverHandle}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                PaperProps={{
                    sx: {
                        minWidth: 0,
                        display: 'flex',
                        flexDirection: 'column'
                    }
                }}
            >
                {
                    contextActions.map((el: any) =>
                        <ListItemButton component={'a'} href={`${pageUrl}?version=${versionId}`} target='_blank' sx={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "143%",
                            letterSpacing: "0.15px",
                            color: "#000000",
                            textTransform: "inherit",
                            justifyContent: "left",
                            padding: "8px 16px"
                        }}>
                            {/* // sx={{ justifyContent: 'space-between' }}> */}
                            {el.content}
                        </ListItemButton>

                        // <Button sx={{
                        //     fontWeight: "400",
                        //     fontSize: "14px",
                        //     lineHeight: "143%",
                        //     letterSpacing: "0.15px",
                        //     color: "#000000",
                        //     textTransform: "inherit",
                        //     justifyContent: "left",
                        //     padding: "8px 16px"
                        // }}
                        // target='_blank'
                        //     className={cnPageHistoryItemBox('ContextActionButton')} 
                        //     // onClick={() => clickAction(el.action)}
                        //    href={`${pageUrl}?version=${versionId}`}
                        //     >
                        //     {el.content}
                        // </Button>
                    )

                }

            </Popover>
        </>
    )
}


export const ContextMenu = withComponentEnjector(ContextMenuPresenter, ['wiki_list_item'])










export const PageHistory: FC<{
    id: string
}> = ({
    id
}) => {

        const {
            pageUrl,
            versions,
        } = usePageHistory(id);

        return (
            <CustomScrollbars style={{ width: "100%", height: "100%", borderRadius: "8px" }}>
                <HistoryBox elevation={3} >

                    <HistoryList>

                        {
                            versions && versions.map(version => {
                                return (
                                    <Box className={cnPageHistoryItemBox('MenuItem-Content-ItemBox')}>
                                        <Box className={cnPageHistoryItemBox("Actions_Hedaer")}>
                                            <ContextMenu

                                                pageUrl={pageUrl}
                                                versionId={version.id}
                                            />

                                        </Box>
                                        <Avatar className={cnPageHistoryItemBox('Image-Icon')} size={40} imgUrl={version?.changedByUser?.imgUrl} imgId={version?.changedByUser?.imgId} name={version?.changedByUser?.displayName} />
                                        <Box className={cnPageHistoryItemBox('MenuItem-Content-Item-Name')}>
                                            <span> {formatDateSTD(version.createdAt)} </span>
                                            <Button sx={{ textTransform: "inherit", padding: 0, justifyContent: "left" }} variant='text' href={`/user/${version?.changedByUser?.id}`}>
                                                {version?.changedByUser?.displayName}
                                            </Button>
                                            {/* <ListItemButton component={'a'} href={`${pageUrl}?version=${version.id}`} target='_blank' sx={{ justifyContent: 'space-between' }}> */}
                                            <CommonmarkRender source={version.description} />
                                            {/* </ListItemButton> */}
                                        </Box>
                                    </Box>
                                )
                            })
                        }

                    </HistoryList>

                </HistoryBox>
            </CustomScrollbars>
        )
    };