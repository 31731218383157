import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from '../../redux/connector';
import { RouteComponentProps } from 'react-router';
// import { IContext } from '../../store/vm';
import { setContext } from 'redux/actions/Widgets';

export interface IBadgeProps extends IClassNameProps, IBadgeStateProps, IActionsDispatch, IDispatchProps, RouteComponentProps<IBadgeUrlParams> {
  tag?: 'div';
}

export interface IBadgeUrlParams {
  id: string;
}

export interface IBadgeState {
  text?: string;
}

export interface IBadgeStateProps {
  // store: any;
  // context: IContext;
  edit: boolean;
}

export const mapStateToProps = (state: any) => ({
  // store: state.store,
  // context: state.store.context,
  edit: state.edit
})

export interface IActionsDispatch {
  setContext?: any;
}

export const mapActionsToProps: IActionsDispatch = {
  setContext
};

export const cnBadge = cn('Badge');