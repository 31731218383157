import React, { Component } from 'react';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import 'draft-js-mention-plugin/lib/plugin.css';
import * as utils from 'utils/src/utils';
// export const tagMentionPlugin = createMentionPlugin({ mentionPrefix: '#', mentionTrigger: '#' });
// const { MentionSuggestions } = tagMentionPlugin;
import { createPortal } from 'react-dom';


export class TagSuggestionsPresenter extends Component<any, any> {
    private saggesterRoot: HTMLDivElement;
    private req: any = null;
    constructor(props: any) {
        super(props);
        if (!this.saggesterRoot) this.saggesterRoot = document.getElementById('Layout') as HTMLDivElement || document.body;
        this.state = {
            suggestions: [],
            // open: false
        }
    }

    private onAddMention = () => {
        // get the mention object selected
        this.setState({
            suggestions: [],
        });
    }

    private onSearchChange = ({ value }: any) => {
        if (this.req) this.req.ac.abort()
        this.req = utils.API.suggesters.search('tags', value)
        this.req.r
            .then((d: any) => {
                if (utils.checkResponseStatus(d)) {

                    const mentions = d.data.map((e: any) => {
                        return {
                            ...e,
                            name: e.displayName,
                            link: '/tag/' + e.id,
                            avatar: 'none',
                        }
                    });
                    this.setState({
                        suggestions: defaultSuggestionsFilter(value, mentions),
                    });
                } else {
                    // toast.error('something wrong');
                    this.setState({
                        suggestions: [],
                    });
                }
            });
    };

    public render() {
        const { MentionSuggestions } = this.props.plugin;
        return createPortal(
            <MentionSuggestions
                renderItem={(mention: any) => <div style={{ padding: '5px 24px' }}>{mention.displayName}</div>}
                open={this.props.open}
                onSearchChange={this.onSearchChange}
                suggestions={this.state.suggestions}
                onAddMention={this.onAddMention}
            // onOpenChange={(open: boolean) => { this.setState({ open }) }}
            />
            , this.saggesterRoot);
    }
}