/**
 * @packageDocumentation
 * @module ListItem
 */
import React, { useEffect, useRef, useCallback, useMemo } from 'react';
// import * as utils from 'utils/src/utils';


import { withBemMod } from '@bem-react/core';

import { cnItem } from '../List-Item.index';
import { IItemTypeCommonProps } from './List-Item_type_common.index';
import { Dropdown, 
  Icon, 
  // Checkbox, 
  Button } from 'uielements/src';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import './List-Item_type_common.scss';
import withConfirmDialog from 'blocks/HOCs/Button/withConfirmDialog'
import ContextMenuComponent from 'uielements/src/ContextMenu/ContextMenu'


const PROPS_TO_DELETE = ['onClick', 'type', 'tag', 'store', 'changeVm', 'dispatch'];
const type = 'common';

const CfmButton = withConfirmDialog(Button)

function checkItemWidth(itemRef?: any) {
  if (!itemRef) return;
  const item = itemRef.parentElement;
  if (!item) return;
  if (item.clientWidth < 500) item.classList.add('hide_second_data_part');
  if (item.clientWidth >= 500) item.classList.remove('hide_second_data_part');
}

export const withItemTypeCommon = withBemMod<any>(
  cnItem(),
  { type },
  (Presenter) => (
    (props: IItemTypeCommonProps) => {
      const ref = useRef<HTMLDivElement>(null);

      const resizeCallback = useCallback(() => { checkItemWidth(ref.current) }, [])
      useEffect(() => {
        checkItemWidth(ref.current);
        window.addEventListener('resize', resizeCallback);
        return () => {
          window.removeEventListener('resize', resizeCallback);
        }
      })
      const { tag: TagName = 'div', checked, data, actions, iId, lId } = props;

      if (!data) return null;

      let action = (actions?.options?.length || 0) === 1 ? <Button
        className={cnItem('Actions')}
        // confirmText={(actions?.options?.[0].buttonProps as any)?.confirmText}
        onClick={actions?.options?.[0].buttonProps?.onClick}
      >
        {actions?.options?.[0].title}
      </Button> : <></>;

      const contextActions = useMemo(() => (actions?.options || []).map((value, i) => ({
        content: value.title,
        buttonProps: { ...value.buttonProps, id: undefined },
        action: value.buttonProps?.title || `action-${i}`
      })), [actions?.options])

      action = (actions?.options?.length || 0) > 1 ?
        <ContextMenuComponent actions={contextActions}/>
        // <Dropdown
        //   className={cnItem('Actions')}
        //   withCaret
        //   text={<Icon icon='cog' />}
        //   options={actions?.options || []} /> 
        : action

      return (
        <Presenter {...props}
          className={props.className + ' ' + cnItem({ nochecked: checked === undefined, tree: Boolean(data.sublevel), sublevel: data.sublevel && data.sublevel > 1 ? data.sublevel : false })}
          onClick={checked !== undefined ? () => props.checkListItem({ id: lId, data: iId }) : undefined}
          style={{
            paddingLeft: data.sublevel ? 24 * data.sublevel + 'px' : '24px'
          }}>
          {
            typeof (checked) === 'boolean' &&
            <Checkbox onChange={()=>{}} value={checked} /> 
          }
          {
            <div className={cnItem('Avatar')}
            // style={{ marginLeft: data.sublevel && data.sublevel > 1 ? (12 * data.sublevel) + (12 * (data.sublevel - 1)) + 'px' : '' }}
            >{data.avatar}</div>
          }
          {
            data.info.map((dataCol, colKey, mass) => (
              <div
                key={colKey}
                className={cnItem('DataPart', { alone: mass.length === 1 })}>
                {
                  dataCol.map((dataItem, itemKey) => <div key={itemKey} children={dataItem} />)
                }
              </div>
            ))
          }
          {action}
          {/* {
            actions && actions.options.length > 0 &&
            <Dropdown
              className={cnItem('Actions')}
              withCaret
              text={<Icon icon='cog' />}
              options={actions.options} />
          } */}
          <div className={cnItem('RefDiv')} ref={ref}></div>
        </Presenter>
      )
    }
  )
);