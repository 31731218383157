import React, { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from 'uielements/src';
import i18n from 'localizations/i18n';
import { toast } from 'react-toastify';
import './MoveFiles.scss'
import * as utils from 'utils/src/utils'
import { IMoveFilesProps, mapDispatchToProps, mapStateToProps } from './MoveFiles.index'
import { connect } from 'react-redux';
import { List } from './List/List'

const rootDir = {
    name: 'root',
    id: "00000000-0000-0000-0000-000000000000",
    baseName: i18n.t('pryaniky.files.rootDir')
}

export const MoveFilesPresenter: FC<IMoveFilesProps & any> = ({ isShown, onClose, gid, moveFile, currentFolder = rootDir, selectedCount }) => {
    const onMove = () => {
        moveFile(gid, currentFolder.id)
        onClose()
    }
    return (
        <>
            <Dialog isShown={isShown} onClose={onClose}>
                <DialogTitle onClose={onClose}>
                    <h4 className="my-1">
                        {
                            selectedCount > 1 ?
                                i18n.t('pryaniky.files.dialog.moveTitle.multi', { count: selectedCount, dir: currentFolder.baseName }) :
                                i18n.t('pryaniky.files.dialog.moveTitle.once', { dir: currentFolder.baseName })
                        }
                    </h4>
                </DialogTitle>
                <div>
                    <List gid={gid} />
                </div>
                <DialogActions>
                    <Button onClick={onMove}>{i18n.t('pryaniky.files.move')}</Button>
                    <Button main onClick={onClose}>
                        {i18n.t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};


export const MoveFilesDialog = connect(
    mapStateToProps,
    mapDispatchToProps
)(MoveFilesPresenter);
