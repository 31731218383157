import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { /*loadUnitContent,*/ saveUnit, updateUnit, deleteUnit, resetUnit, loadUnit } from '../../../redux/actions/LMSTeacherOffice'
import { number } from 'blocks/JsonFormsRenderers/Controls/NumberInput';
import { routerActions } from 'connected-react-router';
///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    unitId: string;
    editOnlyLesson?: boolean;
    unitVersion?: string;
}

export interface IStateProps {
    unit: any
    isSaving?: string
    queryErrors?: string
    errorCount: number
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

const getErrorCount = (state: any, uid: string) => {
    let count = 0
    if (state.LMSTO.unitErrors[uid]) {
        const keys = Object.keys(state.LMSTO.unitErrors[uid])
        count += keys.reduce((acc: number, cur: string) => {
            return acc + (state.LMSTO.unitErrors[uid][cur] ? state.LMSTO.unitErrors[uid][cur] : []).length
        }, 0)
    }
    const qids = Object.keys(state.LMSTO.questionErrors)
    count += qids.reduce((acc: number, qid: string) => {
        return acc + Object.values(state.LMSTO.questionErrors[qid]).filter((v: any) => (typeof v !== 'undefined')).length
    }, 0)

    const aids = Object.keys(state.LMSTO.answerErrors)
    count += aids.reduce((acc: number, aid: string) => {
        return acc + Object.values(state.LMSTO.answerErrors[aid] || {}).filter((v: any) => (typeof v !== 'undefined')).length
    }, 0)

    return count
}

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    unit: state.LMSTO.units[props.unitId],
    isSaving: state.LMSTO.requestsActivity['saveUnit'] || state.LMSTO.requestsActivity['handleLoadUnit'],
    queryErrors: state.LMSTO.requestsActivity['saveUnit_error_msg'] || state.LMSTO.requestsActivity['handleLoadUnit_error_msg'],
    errorCount: getErrorCount(state, props.unitId)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    // loadUnitContent,
    loadUnit,
    updateUnit,
    saveUnit,
    deleteUnit,
    resetUnit,
    changeLocation: routerActions.push
}, dispatch);

////////////

export const cnClassName = cn('TOCEUnitsItem');