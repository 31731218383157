import { zeroId } from "LMSModule/utils/utils.lms"
import { isEmpty, indexOf } from 'lodash'
import { getQuestionsMap } from "../LMSTO/selectors"
/**
 * 
 * declarate store selectors
 */
type stateType = any//затычка

/**
 * статусы сессий
 */
export enum ESessionStatus {
    UNKNOWN = 0, // какая то ошибка, невозможное состояние
    NOT_STARTED = 1, // не начатая сессия, можно выполнить join
    STARTED = 2, // сессия активна, приступил(join), но ещё не начал проходит
    PROGRESS = 3, // пристпил(join) и уже начал выполнение
    COMPLETE_MONDATORY = 4, // завершена обязательная часть сессии, но остались не выполненые юниты
    ABSOLUTE_COMLETE = 5,// сессия завершена полностью, закончились все попытки
    COMPLETED_ALL = 6,// выполнены все юниты, но остались попытки
    BLOCKED = 7 // нельзя приступить к сессии, так как уже есть активная сессия
}


/***********************
 ********* ОБЩИЕ *******
 ************************/

/**
 * достаёт стейт ЛМС
 * @param state 
 */
export const getLMS = (state: stateType) =>
    state.LMS

/**
 * достаёт состояние процесса сохранения
 * @param state 
 */
export const getSaveInProcessing = (state: stateType) =>
    getLMS(state).saveInProcessing




/****************************
 ********* СТАТИСТИКА *******
 ****************************/

/**
* достаёт объект со статистиками
* @param state 
*/
export const getStatistic = (state: stateType) =>
    getLMS(state).statistic

/**
* достаёт статистику - статистика обучения студента
* @param state 
*/
const studentcoursesstatusNOOP = {
    active: [],
    archive: []
}
export const getStudentCoursesStatus = (isMandatory: boolean = false, uid: string = 'current') => (state: stateType) =>
    isMandatory ? (getStatistic(state)[uid]?.studentcoursesstatusMandatory || studentcoursesstatusNOOP) : (getStatistic(state)[uid]?.studentcoursesstatus || studentcoursesstatusNOOP)



/***********************
 ********* КУРСЫ *******
 ***********************/

/**
* достаёт список объектов крусов в формате key:value где key id курса
* @param state 
*/
export const getCoursesMap = (state: stateType) =>
    getLMS(state).courses.entities.values


/**
 * достаёт курс по id
 * @param cid id курса
 */
export const getCourseById = (cid: string) =>
    (state: stateType) =>
        getCoursesMap(state)[cid]



/***********************
 ********* ЮНИТЫ *******
 ***********************/

/**
 * получает короткую модель юнита из указаного курса по его ид
 * @param courseId ид курса из которого надо взять юнит
 * @param unitId ид юнита
 */
export const getCourseUnitById = (courseId: string, unitId: string) =>
    (state: stateType) =>
        getCourseUnits(courseId)(state).find((unit: any) => unit.id === unitId)


/**
 * достаёт список объектов юнитов(полных, с контентом) в формате key:value где key id юнита
 * @param state
 */
export const getUnisContentMap = (state: stateType) =>
    getLMS(state).units

/**
 * достаёт список(массив) юнитов в указаном курсе
 * @param courseId ид курса для которого надо получить список юнитов
 */
export const getCourseUnits = (courseId: string) =>
    (state: stateType) =>
        getCourseById(courseId)(state)?.courseUnits


/**
 * возвращает короткую модель последнего юнита в курсе
 * @param courseId ид курса из которого надо взять юнит
 */
export const getCourseLastUnit = (courseId: string) =>
    (state: stateType) => {
        const units = getCourseUnits(courseId)(state)
        return units[units.length - 1]
    }

/**
* возвращает короткую модель первого юнита в курсе
* @param courseId ид курса из которого надо взять юнит
*/
export const getCourseFirstUnit = (courseId: string) =>
    (state: stateType) => {
        const units = getCourseUnits(courseId)(state)
        return units[0]
    }

/**
 * возвращает массив из коротких моделей завершенных юнитов
 * @param courseId 
 */
export const getCompletedUnits = (courseId: string) =>
    (state: stateType) => {
        const unitsList = getCourseUnits(courseId)(state)
        const unitsLogs = getUnitsLogs(state)
        if (isEmpty(unitsLogs)) return []
        return unitsList.filter((unit: any) => {
            if (!unitsLogs[unit.id]) return false
            else if (unitsLogs[unit.id].isCompleted) return true
            else return false
        })
    }

/**
* возвращает массив из коротких моделей не завершенных юнитов
* @param courseId
*/
export const getNotCompletedUnits = (courseId: string) =>
    (state: stateType) => {
        const unitsList = getCourseUnits(courseId)(state)
        const unitsLogs = getUnitsLogs(state)
        if (isEmpty(unitsLogs)) return []
        return unitsList.filter((unit: any) => {
            if (!unitsLogs[unit.id]) return true
            else if (!unitsLogs[unit.id].isCompleted) return true
            else return false
        })
    }

/**
 * возвращает массив из коротких моделей обязательных для выполнения юнитов
 * @param courseId 
 */
export const getMondatoryUnits = (courseId: string) =>
    (state: stateType) => {
        const unitsList = getCourseUnits(courseId)(state)
        return unitsList.filter((unit: any) => unit.unitType !== 'test' || unit.skipUnitType !== 1)
    }

/**
 * возвращает завершенные обязательные юниты
 * @param courseId 
 */
export const getCompletedMondatoryUnits = (courseId: string) =>
    (state: stateType) => {
        const unitsList = getMondatoryUnits(courseId)(state)
        const unitsLogs = getUnitsLogs(state)
        if (isEmpty(unitsLogs)) return []
        return unitsList.filter((unit: any) => {
            if (!unitsLogs[unit.id]) return false
            else if (unitsLogs[unit.id].isCompleted) return true
            else return false
        })
    }

/**
* возвращает незавершенные обязательные юниты
* @param courseId 
*/
export const getNotCompletedMondatoryUnits = (courseId: string) =>
    (state: stateType) => {
        const unitsList = getMondatoryUnits(courseId)(state)
        const unitsLogs = getUnitsLogs(state)
        if (isEmpty(unitsLogs)) return []
        return unitsList.filter((unit: any) => {
            if (!unitsLogs[unit.id]) return true
            else if (!unitsLogs[unit.id].isCompleted) return true
            else return false
        })
    }


/**
 * получает полный юнит, с контентом по его ид. если его нету, то вернёт пустой объект
 * @param unitId 
 */
export const getUnitContentById = (unitId: string, version?: string) =>
    (state: stateType) =>
        getUnisContentMap(state)[version ? unitId + '_' + version : unitId] || {}


/**
* достаёт индекс в массиве юнитов в курсе для юнита с указаным ID
* @param unitId ид юнита для которого надо получить индекс
* @param courseId ид курса юнита
*/
export const getCourseUnitIndexById = (unitId: string, courseId: string) =>
    (state: stateType) =>
        getCourseUnits(courseId)(state).findIndex((unit: any) => unit.id === unitId)


/**
* возвращает последний завершенный или пропущеный юнит
* @param courseId 
*/
export const getLastCompletedUnitId = (courseId: string) =>
    (state: stateType) => {
        const unitsList = getCourseUnits(courseId)(state)
        const unitsLogs = getUnitsLogs(state)
        return unitsList.reduce((accumulator: any, currentValue: any) => {
            if (!unitsLogs[currentValue.id]) return accumulator
            else if (unitsLogs[currentValue.id].isCompleted || unitsLogs[currentValue.id].isSkipped) return currentValue.id
            else return accumulator
        }, unitsList[0])
    }

/**
* получает текущий юнит в указаной сессии
* @param sessionId 
*/
export const getLastViewedUnitId = (sessionId: string) =>
    (state: stateType) =>
        getSessionLogBySessionId(sessionId)(state).log.lastViewedUnit


/*************************
 ********* ВОПРОСЫ *******
 *************************/

/**
 * достаёт список объектов вопросов для указаного юнита в формате key:value где key id вопроса
 * @param unitId 
 */
export const getQuestionsMapForUnit = (unitId: string) =>
    (state: stateType) =>
        getUnitContentById(unitId)(state).normalQuestions

/**
 * достаёт объект вопроса с указаным ид из указаного юнита
 * @param unitId 
 * @param questionId 
 */
export const getUnitQuestionById = (unitId: string, questionId: string) =>
    (state: stateType) =>
        getQuestionsMapForUnit(unitId)(state)[questionId]

/**
 * возвращает упорядоченый массив с ответами
 * @param unitId 
 */
export const getQuestionsArray = (unitId: string) => (state: stateType) => {
    const questionsIds = getQuestionIds(unitId)(state)
    const questionsMap = getQuestionsMapForUnit(unitId)(state)
    return questionsIds.filter(qid => questionsMap[qid].isCompleted)
}

/**
 * возвращает массив ид завершенных вопросов
 * @param unitId 
 */
export const getCompletedQuestions = (unitId: string) =>
    (state: stateType): string[] => {
        const questionsIds = getQuestionIds(unitId)(state)
        const questionsMap = getQuestionsMapForUnit(unitId)(state)
        return questionsIds.filter(qid => questionsMap[qid].isCompleted)
    }

/**
 * возвращает число законченых вопросов в указаном юните
 * @param unitId 
 */
export const getCompletedUnitQuestions = (unitId: string) =>
    (state: stateType): number =>
        getCompletedQuestions(unitId)(state).length

/**
 * возвращает массив из ид ответов. упорядоченые.
 * @param unitId 
 */
export const getQuestionIds = (unitId: string) =>
    (state: stateType): string[] =>
        getUnitContentById(unitId)(state).questions || []

/**
* возвращает массив из ид пропущеных ответов.
* @param unitId 
*/
export const getSkipedQuestionIds = (unitId: string) =>
    (state: stateType): string[] => {
        const questionsIds = getQuestionIds(unitId)(state)
        const questionsMap = getQuestionsMapForUnit(unitId)(state)
        return questionsIds.filter(qid => questionsMap[qid].skiped)
    }

/**
 * возвращает текущий(на котором находится пользователь) вопрос
 * @param unitId 
 */
export const getCurrentQuestionId = (unitId: string) =>
    (state: stateType) =>
        getUnitContentById(unitId)(state).currentQuestion || zeroId

/**
 * возращает индекс указаного вопроса
 * @param unitId 
 * @param questionId 
 */
export const getQuestionIndexById = (unitId: string, questionId: string) =>
    (state: stateType) =>
        getQuestionIds(unitId)(state).indexOf(questionId)

/**
 * возвращает седующий за fromQuestionId вопрос или zeroId если такого нету
 * @param unitId 
 * @param fromQuestionId 
 */
export const getNextQuestionId = (unitId: string, fromQuestionId: string) =>
    (state: stateType): string => {
        const fromIndex = getQuestionIndexById(unitId, fromQuestionId)(state)
        if (-1 === fromIndex) return zeroId
        const targetIndex = fromIndex + 1;
        const question = getQuestionIds(unitId)(state)
        if (targetIndex >= question.length) return zeroId
        return question[targetIndex]

    }

/**
 * возвращает id следующего незавершенного вопроса
 * @param unitId 
 * @param fromQuestionId 
 */
export const getNextNotCompletedQuestionId = (unitId: string, fromQuestionId: string) =>
    (state: stateType) => {
        const fromIndex = getQuestionIndexById(unitId, fromQuestionId)(state)
        const questionsMap = getQuestionsMapForUnit(unitId)(state)
        const questionsIds = getQuestionIds(unitId)(state)
        return questionsIds.find((qid, index) => (fromIndex < index && !questionsMap[qid].isCompleted)) || zeroId
    }


/*************************
 ********* ОТВЕТЫ ********
 *************************/

/**
 * возвращает клоллекцию ответов на вопрос
 * @param unitId 
 * @param questionId 
 */
export const getAnswersMap = (unitId: string, questionId: string) =>
    (state: stateType) =>
        getUnitQuestionById(unitId, questionId)(state).normalAnswers || {}

/**
 * возвращает ответ по указаному ид
 * @param unitId 
 * @param questionId 
 * @param answerId 
 */
export const getAnswerById = (unitId: string, questionId: string, answerId: string) =>
    (state: stateType) =>
        getAnswersMap(unitId, questionId)(state)[answerId]

/**
 * возвращает список выбраных пользователем ответов
 * @param unitId 
 * @param questionId 
 */
export const getSelectedAnswersIds = (unitId: string, questionId: string) =>
    (state: stateType) =>
        getUnitQuestionById(unitId, questionId)(state).selectedAnswers || []


/*************************
 * ******* Результаты ****
 *************************/

export const getUserSessionsResults = (state: stateType) =>
    getLMS(state).userSessionsResults

/**
 * вернёт результыт указаной сессии
 * @param state 
 * @returns 
 */
export const getUserSessionsResultsById = (id: string) => (state: stateType) =>
    getUserSessionsResults(state)[id]


/**
 * вернёт список результатов юнитов(ключ-значение)
 * @param sessionId 
 * @returns 
 */
export const getUnitsResults = (sessionId: string) => (state: stateType) =>
    getUserSessionsResultsById(sessionId)(state)?.unitsResult



/**
 * вернёт результаты к юниту с указаным  ИД
 * @param sessionId 
 * @param unitId 
 * @returns 
 */
export const getUnitResultsById = (sessionId: string, unitId: string) => (state: stateType) =>
    getUnitsResults(sessionId)(state)?.[unitId]

/*************************
 ********* СЕССИИ ********
 *************************/

/**
 * возвращает первую неначатую сессию в курсе или undefined если таковых нет.
 * @param courseId ид курса, для которого вернуть сессию
 */
export const getFirstUnstartedSession = (courseId: string) =>
    (state: stateType) => {
        const course = getCourseById(courseId)(state)
        const firstSessionIn = course.courseSessions.find((sid: string) => {
            if (!course.normalSessions[sid].log) return true
            return false
        }) || '0'
        return course.normalSessions[firstSessionIn]
    }

/**
 * возвращает все доступные, неначатые сессии или пустой массив если таких нет
 * @param courseId ид курса, для которого надо вернуть сессии
 */
export const getUnstartedSessions = (courseId: string) =>
    (state: stateType) => {
        const course = getCourseById(courseId)(state)
        return course.courseSessions.filter((sid: string) => {
            if (!course.normalSessions[sid].log) return true
            return false
        }).map((sid: string) => course.normalSessions[sid])
    }

/**
 * возвращает все завершенные сессии(isCompleted=true), если нет таких, то пустой массив
 * @param courseId ид курса, для которого надо вернуть сессии
 */
export const getCompletedSessions = (courseId: string) =>
    (state: stateType) => {
        const course = getCourseById(courseId)(state)
        if (!course) return [];
        if (course.error_code) return [];
        return course.courseSessions.filter((sid: string) => {
            if (course.normalSessions[sid].log && course.normalSessions[sid].log.isCompleted) return true
            return false
        }).map((sid: string) => course.normalSessions[sid])
    }

/**
* достаёт коллекцию сессий
* @param state 
*/
export const getSessions = (state: stateType) =>
    getLMS(state).sessions

/**
 * достаёт сессию по ид
 * @param sessionId 
 */
export const getSessionById = (sessionId: string) =>
    (state: stateType) =>
        getSessions(state)[sessionId]

/**
 * завершенали сессия полностью
 * @param sessionId 
 */
export const getIsAbsolutelyCompleted = (sessionId: string) =>
    (state: stateType) => {
        const log = getSessionById(sessionId)(state)?.log || getSessionLogBySessionId(sessionId)(state)?.log
        return log?.isAbsolutelyCompleted
    }

    /**
 * здополнительные попытки
 * @param sessionId 
 */
export const getAdditionalAttempts = (sessionId: string) =>
(state: stateType) => {
    const log = getSessionById(sessionId)(state)?.log || getSessionLogBySessionId(sessionId)(state)?.log
    return log?.additionalAttempts || 0
}

/**
* возвращает статус указаной сессии
*/
export const getSessionStatus = (courseId: string, sessionId: string) =>
    (state: stateType): ESessionStatus => {
        const course = getCourseById(courseId)(state);
        const activeSessionId = course.courseSessionId;
        const sessionLog = getSessionLogBySessionId(sessionId)(state).log || {};
        const notActived = activeSessionId === zeroId
        const completedUnitsCount = getCompletedUnits(courseId)(state).length
        const unitsConut = getCourseUnits(courseId)(state).length
        // const notCompletedMondatory = getNotCompletedMondatoryUnits(courseId)(state)
        const comletedAll = completedUnitsCount === unitsConut

        // уже активна другая сессия
        if (!notActived
            && sessionId !== activeSessionId) return ESessionStatus.BLOCKED
        //полностью закончена
        else if (sessionLog.isAbsolutelyCompleted) return ESessionStatus.ABSOLUTE_COMLETE;
        // обязательная часть закончена
        else if (sessionLog.isMandatoryPartComplete
            && !comletedAll
            && !sessionLog.isAbsolutelyCompleted) return ESessionStatus.COMPLETE_MONDATORY;
        // завершены все юниты
        else if (sessionLog.isCompleted
            && comletedAll
            && !sessionLog.isAbsolutelyCompleted) return ESessionStatus.COMPLETED_ALL;
        // закончена
        else if (sessionLog.isCompleted
            && !sessionLog.isAbsolutelyCompleted) return ESessionStatus.COMPLETED_ALL;
        // не начата
        else if (!sessionLog.isCompleted
            && !sessionLog.isMandatoryPartComplete
            && sessionId !== activeSessionId
            && notActived) return ESessionStatus.NOT_STARTED
        // присоеденился, но ещё ничего не прошел      
        else if (sessionId === activeSessionId
            && completedUnitsCount === 0) return ESessionStatus.STARTED
        //в процессе
        else if (sessionId === activeSessionId
            && !sessionLog.isCompleted
            && completedUnitsCount > 0) return ESessionStatus.PROGRESS
        // неудалось определить статус
        return ESessionStatus.UNKNOWN
    }


/****************************
********* ЛОГИ ЮНИТОВ *******
*****************************/

/**
* достаёт коллекцию юнитлогов, с ключами - ид юнита
* @param state 
*/
export const getUnitsLogs = (state: stateType) =>
    getLMS(state).unitsLogs

/**
 * достаёт лог юнита по его id
 * @param unitId ид юнмита для которого надо получить лог
 */
export const getUnitLogByUnitId = (unitId: string) =>
    (state: stateType) =>
        getUnitsLogs(state)[unitId]


/*****************************
 ********* ЛОГИ СЕССИЙ *******
 *****************************/

/**
 * достаёт коллекцию логов сессий
 * @param state 
 */
export const getSessionsLogs = (state: stateType) =>
    getLMS(state).sessionsLogs

const emptyObject = {}

/**
 * достаёт достаёт лог сессии по ид сессии
 * @param sessionId 
 */
export const getSessionLogBySessionId = (sessionId: string) =>
    (state: stateType) =>
        getSessionsLogs(state)[sessionId] || emptyObject

/**
* получает лог текущей сессии по ид курса
* @param courseId
*/
export const getCurrentSessionLog = (courseId: string) =>
    (state: stateType) => {
        const currentCourse = getCourseById(courseId)(state)
        const currentSid = currentCourse.courseSessionId
        return getSessionLogBySessionId(currentSid)(state)
    }
