import React from 'react';


import { cnWidget,cnWidgetMui, IWidgetProps } from '../Widget.index';

import './Widget_type_quests.scss';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { QuizWidget } from 'blocks/QuizWidget/QuizWidget'
import { IWidgetTypeQuizProps } from './Widget_type_quiz.index'

const type = 'quiz';

export default class WidgetTypeQuizPresenter extends WidgetPresenter<IWidgetTypeQuizProps> {

  public render() {
    if (!this.props.widget) return null
    const subtype = utils.widgetSubtype(this.props.widget.type);
    return <QuizWidget {...this.props} className={cnWidgetMui({type, subtype})} context={this.props.widget} settings={this.props.wcontext} tag={widgets.components.common} />
  }

}