import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IBodyProps } from '../modal'
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    addFile
} from '../../redux/actions/TracksManage'

///////////

export type IAddCourseToTrackPresenterProps = IAddCourseToTrackOwnProps & IAddCourseToTrackStateProps & IAddCourseToTrackDispatchProps

export interface IAddCourseToTrackState { }

export interface IAddCourseToTrackOwnProps extends IClassNameProps, IBodyProps<IAddCourseToTrackResult> {
    data: any
}

export interface IAddCourseToTrackResult {
    cid: string
    tid: string
}

export interface IAddCourseToTrackStateProps {
}

export type IAddCourseToTrackDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////


export const mapStateToProps = (state: any, props: IAddCourseToTrackOwnProps) => ({

})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IAddCourseToTrackOwnProps) => bindActionCreators({
    // addFile
}, dispatch);

export const cnClassName = cn('LTAddCourseToTrack');