import { IIdeasPropsType, IIdeasStateType, mapStateToProps, TStateProps, mapDispatchToProps } from './Ideas.index'
import { Status } from './IdeaType'
import * as React from 'react';
import { StateSelector } from './StateSelector/StateSelector'
// import { AdditionalFieldsRender } from './AdditionalFieldsRender/AdditionalFieldsRender';
import { AdditionalContentRender } from './AdditionalContentRender/AdditionalContentRender';
import { ChangeStateRender } from './ChangeStateRender/ChangeStateRender'
import { ThanksSupport } from '../common/ThanksSupport/ThanksSupport';
import { AddExpert } from './AddExpert/AddExpert'
// import { IdeaEvaluation } from './IdeaEvaluation/IdeaEvaluation'
// import { ExpertEvalutionResults } from './ExpertEvalutionResults/ExpertEvalutionResults'
import { StageBar } from '../common/StageBar/StageBar';
import { TopBadge } from '../common/TopBadge/TopBadge';
import { ParticipantsBox } from './ParticipantsBox/ParticipantsBox';
import { cloneObject } from 'utils/src/utils';
import { connect } from 'react-redux';
import * as utils from 'utils/src/utils';
// import { Popover } from '../common/Popover/Popover'
import { Popover } from '@material-ui/core';
import i18n from '../../../localizations/i18n';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { withNewsContext } from 'News/contexts/news'
import TextRender from 'News/containers/TextRender'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import { IdeaNews } from './IdeaType'
import ContentLayout from 'News/components/ContentLayout'
import { SliderViewIdeas } from './SliderViewIdeas/SliderViewIdeas'
import './Ideas.scss'
import { DialogIdeaEvaluationNew } from './IdeasEvalutionNew/IdeaEvaluationNew'
import { DialogExpertEvalutionResultsNew } from './ExpertEvalutionResultsNew/ExpertEvalutionResultsNew'
import { Button } from 'muicomponents/src'
export class IdeasPresenter extends React.Component<IIdeasPropsType & TStateProps, IIdeasStateType>  {

    private stageContentRef = React.createRef<HTMLElement>();

    constructor(props: IIdeasPropsType & TStateProps) {
        super(props)

        const data = props.news as IdeaNews

        // стейт веринули так как не работает shortview
        this.state = {
            controlComponent: undefined,
            actionComponent: undefined,
            actionComponentOpened: false,
            data: cloneObject(data)
        }
    }

    public componentDidMount = () => {
        // const data = this.props.news as IdeaNews;
        // const { currentStatus } = data.idea;
        // if (currentStatus) this.setState({ controlComponent: this.actionRender(currentStatus) });
    }

    public render() {
        const { /*controlComponent,*/ actionComponent } = this.state;
        const data = this.props.news;
        const { idea, thanksCount, id, isNeedApprove } = data;
        const { currentStatus, statusChangeTarget, ideaInvestors, ideaParticipants, ideaHeads, additionalFieldsValues, responsibleForStatusView, showChangeStatusButton, showExpertiseButton, showExpertiseResultButton } = idea;
        const { currencyName, currencyNamePlural } = this.props
        const userCanMoveToStatusArr = idea.statusChangeTarget?.statuses?.map((statuses: any) => statuses.userCanMoveToStatus) || [];
        const userCanMoveToStatus = userCanMoveToStatusArr.findIndex((el: any) => el === true) !== -1 ? true : false;

        return (
            <NewsView className={'type-21'}>

                <div className={'Ideas-Layout'}>
                    <TopBadge
                        imgClass={thanksCount === 0 ? 'TopBadge-ImgIdea_on' : 'TopBadge-ImgIdea_off'}
                        left={thanksCount === 0 ? '' : i18n.t('pryaniky.post.ideas.fund')}
                        value={thanksCount === 0 ? '' : thanksCount}
                        right={thanksCount === 0 ? '' : (thanksCount === 1 ? currencyName : currencyNamePlural)}
                    />
                    <ContentLayout>

                        <TextRender />

                        <AdditionalContentRender data={additionalFieldsValues} />
                    </ContentLayout>
                </div>

                {
                    currentStatus &&
                    <StageBar
                        ref={this.stageContentRef}
                        stage={((statusChangeTarget?.statuses || []).length > 0 && userCanMoveToStatus) ?
                            showChangeStatusButton ? <StateSelector currentStatus={currentStatus} statusList={statusChangeTarget?.statuses || []} onChange={this.stageChange} /> : <div>{currentStatus.name}</div>
                            : <>{currentStatus.id > GUID_EMPTY ? <div>{currentStatus.name}</div> : <p>{i18n.t('pryaniky.post.ideas.noStep')}</p>}</>}
                        date={<span>{utils.parseDate(currentStatus.statusChangeDate !== null ? currentStatus.statusChangeDate : data.date)}</span>}
                        controls={this.actionRender()}>
                        {actionComponent}
                    </StageBar>
                }

                <ContentLayout>
                    <ParticipantsBox
                        // changeHeads={this.changeHeads}
                        // changeParticipants={this.changeParticipants}
                        changeResponsibleForStatusUser={this.changeResponsibleForStatusUser}
                        currentUser={this.props.userData.baseData}
                        ideaId={id}
                        responsibleForStatusUser={responsibleForStatusView || []}
                        ideaParticipants={ideaParticipants || []}
                        ideaHeads={ideaHeads || []}
                        ideaInvestors={ideaInvestors || []}
                    />
                </ContentLayout>
            </NewsView>
        )
    }
    private changeResponsibleForStatusUser = (array: any[]) => {
        // this.setState((s: IIdeasStateType) => {
        //     s.data.idea.responsibleForStatusUser = array;
        //     return s;
        // })
    }

    private isExpert = () => {
        const data = this.props.news as IdeaNews
        return data.idea.responsibleForStatusView?.find((user: any) =>
            user.id === this.props.userData.baseData.id);

    }

    private thanksCountChange = (value: number) => {
        if(this.props.thanksForAll < value) return ;
        this.props.pathNews({
            id: this.props.newsId,
            thanksCount: (this.props.news.thanksCount || 0) + value
        })
    }

    private expertsSelected = (experts: string[]) => { }

    private actionRender = (status?: Status): React.ReactNode => {
        // const { data } = this.state;
        const data = this.props.news;
        const { userData } = this.props;
        const { isExpertEvaluation, isAllowInvestment } = data.idea.currentStatus!//status;
        const { id, idea, isNeedApprove } = data;
        const { showExpertiseResultButton, ideaExperts, isIdeaModerator } = idea;
        return (
            <React.Fragment>
                {isAllowInvestment && !isNeedApprove && <ThanksSupport newsId={id} anchor={this.stageContentRef} valueChange={this.thanksCountChange} />}

                {/* {(isExpertEvaluation && this.isExpert()) && <IdeaEvaluationNew anchor={this.stageContentRef} ideaId={id} statusID={data.idea.currentStatus!.id} onComplite={() => { }} />} */}
                {(isExpertEvaluation && this.isExpert()) && <Button
                    variant='contained'
                    // className='CustomStyle additionalColor2-text'
                    sx={[
                        {
                            '&:hover': {
                                boxShadow: "none",
                            },
                        },
                        {
                            minWidth: "223px",
                            textTransform: "inherit",
                            padding: "4px 8px",
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#000",
                            boxShadow: "none",
                            borderRadius: "0px",
                        }]}
                    onClick={() => DialogIdeaEvaluationNew({
                        anchor: this.stageContentRef,
                        ideaId: id,
                        statusID: data.idea.currentStatus!.id,
                        onComplite: () => { },
                    }).then(() => { }).catch(() => { })} >{i18n.t('pryaniky.idea.evaluation')}</Button>}

                {(isExpertEvaluation && isIdeaModerator) && <AddExpert
                    anchor={this.stageContentRef}
                    experts={ideaExperts || []}
                    onComplite={this.expertsSelected}
                    ideaId={id}
                    statusID={data.idea.currentStatus!.id}
                />}

                {showExpertiseResultButton && <Button
                    // CustomStyle .additionalColor2-text
                    variant='contained'
                    className='Button Button_theme_support ButtonWithSpin'
                    sx={[
                        {
                            '&:hover': {
                                boxShadow: "none",
                            },
                        },
                        {
                            minWidth: "223px",
                            textTransform: "inherit",
                            padding: "4px 8px",
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#000",
                            boxShadow: "none",
                            borderRadius: "0px",
                        }]}
                    onClick={() => DialogExpertEvalutionResultsNew({
                        anchor: this.stageContentRef,
                        ideaId: id,
                        statusID: data.idea.currentStatus!.id,
                        showDeleteExpers: isIdeaModerator,
                    }).then(() => { }).catch(() => { })} >{i18n.t('pryaniky.idea.show.evaluation')} </Button>}
                {/* {showExpertiseResultButton && <ExpertEvalutionResultsNew anchor={this.stageContentRef} ideaId={id} statusID={data.idea.currentStatus!.id} showDeleteExpers={isIdeaModerator} />} */}
                {/* {showExpertiseResultButton && <ExpertEvalutionResultsNew anchor={this.stageContentRef} ideaId={id} statusID={data.idea.currentStatus!.id} showDeleteExpers={isIdeaModerator} />} */}
            </React.Fragment>
        )
    }

    private statusSaved = (status: Status) => {
        this.setState((s: IIdeasStateType) => {
            s.actionComponent = undefined;
            // s.controlComponent = this.actionRender(status);
            return s;
        });

        const currentStatus = {
            ...status,
            statusChangeDate: new Date().toISOString(),
            // isExpertEvaluation: true
        }

        this.props.pathNews({
            id: this.props.newsId,
            idea: {
                ...this.props.news.idea,
                showExpertiseResultButton: true,
                currentStatus
            }
        })
    }
    private cancleChangeStateRender = () => {
        const { currentStatus } = this.props.news.idea;
        if (currentStatus) this.setState({
            actionComponent: undefined,
            // controlComponent: this.actionRender(currentStatus) 
        });
    }

    private stageChange = (status: Status) => {
        if (!this.props.news.idea.currentStatus) return null;
        if (this.props.news.idea.currentStatus.id == status.id) return this.cancleChangeStateRender();
        this.setState({
            // controlComponent: undefined,
            actionComponent: <Popover
                open={true}
                anchorEl={this.stageContentRef.current}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <ChangeStateRender
                    distributeMultiplicator={status.distributeMultiplicator}
                    fund={this.props.news.thanksCount}
                    ideaId={this.props.news.id}
                    status={status}
                    save={this.statusSaved}
                    cancle={this.cancleChangeStateRender} />
            </Popover>
        });
    }

}

export const Ideas = withNewsContext(connect(
    mapStateToProps,
    mapDispatchToProps
)(IdeasPresenter));

export const initIdeas = () => {
    ComponentInjector.getInstance().addNode('news_types', <Ideas />, 'ideas');
    ComponentInjector.getInstance().addNode('slider_news_types', <SliderViewIdeas />, 'ideas');
}