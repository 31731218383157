import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { useParams, withRouter } from "react-router"
import WidgetPresenter from '../Widget';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeVacancyProps } from './Widget_type_vacancy.index';
import './Widget_type_vacancy.scss';
import { RouteComponentProps } from 'react-router';
import { widgets } from 'i.widgets';
import queryString from 'query-string';
// import { Vacancy } from 'blocks/Vacancy/Vacancy';
import { Vacancy } from 'blocks/Vacancies/Vacancy/Vacancy';

const type = 'Vacancy';

class WidgetTypeVacancyPresenter extends WidgetPresenter<IWidgetTypeVacancyProps & RouteComponentProps<{ fname: string, pid: string, aid: string }>> {
  public render() {

    if (!this.props.widget) return null;
    
    return (
      <widgets.components.common { ...this.props } className={cnWidget({ type })}>
        <Vacancy id={this.props.widget.settings?.id} />
      </widgets.components.common>
    )
  }
}

export default withRouter(WidgetTypeVacancyPresenter)