import React, { FC, useEffect, memo } from 'react';
import { IPhotoalbumsProps, mapDispatchToProps, mapStateToProps } from './Photoalbums.index'
import { connect } from 'react-redux';
import { Album } from './Album/Album';
import { CreateAlbum } from './CreateAlbum/CreateAlbum';
import './Photoalbums.scss'
import { Button, Spinner } from 'uielements/src'
import * as utils from 'utils/src/utils'
import i18n from 'localizations/i18n';
import { useDebounce, useRefedCallback, NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks'



export const Photoalbums_: FC<IPhotoalbumsProps> = ({ albums, gid, loadList, count, isFinished, isLoading, showControls }) => {
    const more = useRefedCallback((depsRef) => () => {
        const { isLoading, isFinished, gid, count } = depsRef.current
        if (isLoading) return;
        if (isFinished) return;
        loadList(gid, count)
    }, { isLoading, isFinished, gid, count })

    const loadMore = useDebounce(more, 500)

    const moreRef = useInfiniteScroll({
        loadMore, isLoading, isFinished
    })

    useEffect(()=> {
        loadList(gid);
    }, [gid])
    return <div className={'Photoalbums'}>
        <div className={'Photoalbums-Actions'}>
            {showControls && <CreateAlbum gid={gid} />}
        </div>
        <div className={'Photoalbums-List'}>
            {albums.map(album => <Album showControls={showControls} key={album.id} album={album} aid={album.id} gid={gid} />)}
        </div>

        <div className={'Photoalbums-MoreBox'}>
            {isLoading ? <div><Spinner /></div> : ((!isFinished && count !== 0) && <Button forwardedRef={moreRef} onClick={more}>{i18n.t('pryaniky.photoalbums.albums.more')}</Button>)}
        </div>
    </div>
}

export const Photoalbums = connect(
    mapStateToProps,
    mapDispatchToProps
)(memo(Photoalbums_))