import React, { Children, MouseEventHandler, useCallback } from 'react';
import { ILeftMenuItemProps, cnLeftMenuItem, mapLeftMenuItemStateToProps, mapLeftMenuItemActionsToProps, ILeftMenuItemLabelProps, IDraggingItem } from './LeftMenuItem.index';
import './LeftMenuItem.scss';
// import { TreeNode } from 'rc-tree';
import { connect } from 'react-redux';
import TreeItem from '@material-ui/lab/TreeItem';
import { useDrag, useDrop } from 'react-dnd';
import { Icon } from 'uielements/src';
import { baseMenuItem } from './../redux/constants';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { v1 as uuid } from 'uuid';
import { LeftMenuItemLinkLabel } from './label/link/link';
import { LeftMenuItemDropdownLabel } from './label/dropdown/dropdown';
import { makeStyles } from '@material-ui/core';
import { itemChangeModal } from './change/ItemChange';
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import { Translate } from 'localizations/Translate';
import { confirm } from './../../../utils.project/utils.project';
import { getI18nKey } from './label/utils';
import { childrenFixIconBlocks as blocksWithoutAdding } from './../redux/constants';
import { MENU_BREAKPOINT } from 'utils/src/utils';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { CustomSettings } from 'utils/src';
import { ItemRenderType } from 'utils/src/requests/models/api.menu';

const useStyles = makeStyles({
  group: {
    marginLeft: 0
  }
})

const Adder: React.FC<{
  type: 'before' | 'after'
  onClick: (e: any) => void;
}> = ({
  type,
  onClick
}) => {
    return (
      <div onClick={onClick} className={cnLeftMenuItem('Layout-Adder', { type })}>
        <Icon icon={'plus'} />
      </div>
    )
  }

const LeftMenuItemPresenter: React.FC<ILeftMenuItemProps> = ({
  path,
  isFirst,
  edit,
  data,
  parentData,
  authUserData,
  expanded,
  children,
  active,
  opened,
  openedMenu,
  menuAddItem,
  menuOpen,
  menuHidden,
  menuSetExpanded,
  menuMoveItem,
  menuUpdateItem,
  menuDeleteItem,
  menuSetExpandedAll
}) => {
  const classes = useStyles();

  const [dragBefore, setDragBefore] = React.useState(false);

  const draggingItem: IDraggingItem = {
    id: data.id,
    path,
    renderType: data.renderType,
    type: 'menuItem'
  }

  // [{ isDragging: undefined }, undefined];
  const [{ isDragging }, drag] = useDrag({
    item: draggingItem,
    begin: (monitor) => {
      if (data.renderType === ItemRenderType.dropdown) menuSetExpandedAll(false);
    },
    collect: monitor => {
      const isDragging = !!monitor.isDragging();
      if (isDragging && expanded) toggleItem();
      return {
        isDragging
      }
    }
  });

  // [{ isOver: undefined, canDrop: undefined }, undefined];
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'menuItem',
    hover: (draggableItem, monitor) => {
      const elCoords = document.getElementById(data.id)?.getBoundingClientRect();
      const mouseOffset = monitor.getClientOffset();
      if (!elCoords || !mouseOffset) return;
      try {
        if (mouseOffset.y > elCoords.top && mouseOffset.y < elCoords.top + (elCoords.height / 2)) setDragBefore(true);
        if (mouseOffset.y < elCoords.bottom && mouseOffset.y > elCoords.bottom - (elCoords.height / 2)) setDragBefore(false);
      } catch (error) {
        console.error('positioning error: ', error);
      }
    },
    canDrop: (i, monitor) => {
      /**
       * data.parentId === GUID_EMPTY - if first level
       * item.renderType === 'link' && path.length === 1 - if link and to second level
       */
      const item = i as IDraggingItem;
      if (data.parentId === GUID_EMPTY || item.renderType === ItemRenderType.link && path.length === 1) return true;
      return false;
    },
    drop: (item, monitor) => {
      if (canDrop && monitor.isOver({ shallow: true })) {
        menuMoveItem({ id: (item as any).id, moveTo: data.id, type: dragBefore ? 'before' : 'after' })
      }
    },
    collect: (monitor) => {
      return {
        isOver: !!monitor.isOver({ shallow: true }),
        canDrop: !!monitor.canDrop()
      }
    },
  });

  const checkStopAction = () => {
    if(!opened && !openedMenu) return true;
    return false;
  }

  const toggleItem = () => {
    // если меню не открыто и нет настройки что меню всегда должно быть открыто, то ничего не делаем
    if (checkStopAction()) return;
    switch(data.renderType) {
      case ItemRenderType.dropdown:
        menuSetExpanded(!expanded || edit ? [...path, data.id] : [...path]);
        break;
      case ItemRenderType.link:
        if (!edit) {
          menuOpen(false);
          if (document.body.clientWidth < MENU_BREAKPOINT) menuHidden(true);
        }
        menuSetExpandedAll(false);
        break;
    }
  }

  const addItem = (type: 'before' | 'after') => (e: MouseEvent) => {
    e.stopPropagation();
    itemChangeModal({
      type: 'add',
      data: { ...baseMenuItem, id: uuid(), parentId: path[path.length - 1] || GUID_EMPTY }
    }).then(item => {
      Translate.setResorseItem(getI18nKey(item), item.title);
      menuAddItem({ item, path, toItem: data.id, type });
    }).catch(err => console.error(err));
  }

  let Label: React.ComponentType<ILeftMenuItemLabelProps> | undefined = undefined;

  const onItemEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    itemChangeModal({
      type: 'change',
      data
    }).then(item => {
      Translate.setResorseItem(getI18nKey(data), item.title);
      menuUpdateItem(item);
    }).catch(err => console.error(err));
  }

  // const deleteItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   e.stopPropagation();
  //   confirm({
  //     text: Translate.t({ i18nKey: "pryaniky.menu.modal.delete.title", values: { oldData: Translate.t({ i18nKey: getI18nKey(data), defaults: data.title }) } }),
  //     onConfirm: () => {
  //       menuDeleteItem({ path, id: data.id })
  //     }
  //   });
  // }

  switch (data.renderType) {
    case 'dropdown':
      Label = LeftMenuItemDropdownLabel;
      break;
    case 'link':
      Label = LeftMenuItemLinkLabel;
      break;
  }

  // To nspk render user block like link
  // TODO refactor
  if (data.blockType === 'user' && CustomSettings.leftMenuLinkedUserBlock()) {
    data.url = `/user/${authUserData.id}`;
    Label = LeftMenuItemLinkLabel;
  }

  const draggable = edit && data.isDraggable;

  const editable = edit && data.isEditable;

  const disableInsertElement = parentData && blocksWithoutAdding.includes(parentData.blockType) || expanded && blocksWithoutAdding.includes(data.blockType);

  if (!edit && data.isHidden) return null;

  return (
    <div
      id={data.id}
      ref={edit ? drop : undefined}
      className={cnLeftMenuItem({}, [])}
    >
      <TreeItem
        className={classes.group}
        nodeId={data.id}
        label={
          <div className={cnLeftMenuItem('Layout', { isOver, expanded, dragBefore, draggable, canDrop })}>
            {!disableInsertElement && edit && isFirst && <Adder type={'before'} onClick={addItem('before')} />}
            <Tooltip title={<Translate i18nKey={`${data.url === '/shop' ? data.title : getI18nKey(data)}`} defaults={data.title}/>}
             placement="right" 
            //  placement="right-end"
            >
            <div
              className={cnLeftMenuItem('Body', { isDragging, active })}
              onClick={toggleItem}
              ref={draggable ? drag : undefined}>
              {draggable && <div className={cnLeftMenuItem('Dragster')} children={<Icon icon={'bars'} />} />}
              {Label && <Label onClick={(event) => {
                // если меню не открыто и нет настройки что меню всегда должно быть открыто, то ничего не делаем
                if (checkStopAction()) {
                  event.preventDefault();
                  return;
                }
              }} data={data} edit={edit} expanded={expanded} authUserData={authUserData} parentData={parentData} />}
            </div>
            </Tooltip>

            {
              editable &&
              <div className={cnLeftMenuItem('Actions')}>
                <Button size='small' color='primary' variant='contained' onClick={onItemEdit}>
                  <Icon icon={'edit'} />
                </Button>
              </div>
            }
            {!disableInsertElement && edit && <Adder type={'after'} onClick={addItem('after')} />}
          </div>
        }
      >
        {
          data.renderType === 'dropdown' && children
        }
      </TreeItem>
    </div>
  )
}

export const LeftMenuItem = connect(
  mapLeftMenuItemStateToProps,
  mapLeftMenuItemActionsToProps
)(LeftMenuItemPresenter)