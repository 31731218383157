import { widgetsReducer } from './reducer';

export const initialWidgetReducerState: ReturnType<typeof widgetsReducer> = {
    active: '',
    contexts: {
        common: {
            __parent: ''
        }
    },
    pages: {},
    structures: {},
    widgets: {},
    viewType: 'full'
};