import * as React from 'react';
// import * as utils from 'utils/src/utils';


import { withBemMod } from '@bem-react/core';

import { cnWidget } from '../Widget.index';
import { IWidgetTypeTextblockProps } from './Widget_type_textblock.index';
import './Widget_type_textblock.scss';

const type = 'textblock';

export const withWidgetTypeTextblock = withBemMod<IWidgetTypeTextblockProps>(
  cnWidget(),
  { type }, 
  (Presenter) => (
    (props) => {
      if (!props.widget) return null;
      const text = props.widget.serverData.content;
      return (
        <Presenter {...props}>
          <div dangerouslySetInnerHTML={{__html: text}} />
        </Presenter>
      )
    }
  )
);