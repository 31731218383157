import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { loadCourseById } from '../../redux/actions/LMSTeacherOffice'
export interface ICourseViewProps extends IClassNameProps, ICourseViewStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
}

export interface ICourseViewState {

}

export interface ICourseViewStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    course: state.LMSTO.courses.values[props.match.params.id],
    // results: state.LMSTO.courses.results
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadCourseById
}, dispatch);

export const cnCourseView= cn('CourseView');