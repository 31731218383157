import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { RouteComponentProps } from 'react-router';
import { IShopOrder } from 'utils/src/requests/models/api.shop';
import { IDispatchProps } from 'redux/connector';

export interface IOrdersProps extends IClassNameProps, IOrdersStateProps, IDispatchProps, RouteComponentProps {}

export interface IOrdersState {
  loading: boolean;
  fullList: boolean;
  data: IShopOrderItem[];
  searchQuery: string;
  allSelected: boolean;
}

export interface IShopOrderItem extends IShopOrder {
  checked: boolean;
}

export interface IOrdersStateProps {
  structure: any;
  uid: string;
}

export const cnOrders = cn('Orders');
