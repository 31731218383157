import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { createQuestion, updateUnit, setUnitError, deleteQuestion, loadUnit } from '../../../redux/actions/LMSTeacherOffice'
import { getErrors } from '../selectors'
import { routerActions } from 'connected-react-router';
///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    unit: any;
    onChange: (unit: any) => void
}

export interface IStateProps {
    errors: any
    versions: {[key: number]: string}
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    errors: getErrors(state, props.unit.id),
    versions: state.LMSTO.unitsversions[props.unit.id]
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    createQuestion,
    updateUnit,
    setUnitError: (errors: any) => setUnitError(ownProps.unit.id, errors),
    deleteQuestion,
    loadUnit,
    changeLocation: routerActions.push
}, dispatch);

////////////

export const cnClassName = cn('TOCETestEdit');