import React, { FC, useState, /*useRef*/ } from 'react';
import { IPasswordInputProps } from './PasswordInput.index'
import './PasswordInput.scss'
import { InputLayout } from '../InputLayout/InputLayout'
import { LineInput } from '../LineInput/LineInput'
import { Button, Icon } from 'uielements/src'

export const PasswordInput: FC<IPasswordInputProps> = ({ className, onChange, value, icon, action, placeholder, ...props }) => {
    const [focused, setFocused] = useState(false);
    const [show, setShow] = useState(false)
    // const ref = useRef<HTMLInputElement>(null)
    const onChangeValue = (e: any) => {
        onChange(e.target.value)
    }
    return <div>
        <InputLayout
            action={<Button tabIndex={-1} theme={'unstyled_center'} onClick={() => { setShow(!show) }}><Icon icon={show ? 'eye-crossed' : 'eye'} /></Button>}
            focus={focused}
            icon={icon}
        >
            <LineInput
                // ref={ref}
                className={className}
                placeholder={placeholder}
                onFocus={() => setFocused(true)}
                onBlur={() => { setFocused(false) }}
                type={show ? 'text' : 'password'}
                onChange={onChangeValue}
                value={value} />
        </InputLayout>
    </div>
}