import React from 'react';
import * as utils from "utils/src/utils";
import i18n from 'localizations/i18n';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from 'redux/connector';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import {
  DialogTitle,
  Textarea,
  DialogText,
  DialogActions,
  Button,
  cnDialog,
} from 'uielements/src';
import { InputMention } from 'blocks/InputMention/InputMention';
import { toast } from 'react-toastify';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { integrationModes } from 'utils/src/constants.prn';

type IMakeOrderDialogStateProps = ReturnType<typeof mapStateToProps>;

interface IShopDialogProps extends IShopDialogOwnProps, IDispatchProps, IMakeOrderDialogStateProps {}

interface IShopDialogOwnProps {
  id?: string,
  // isShown: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  // data: any;
}

interface IMakeOrderDialogState {
  data?: any;
  comment: string;
  presentFor?: any;
}

export class MakeOrderDialogPresenter extends React.Component<IShopDialogProps, IMakeOrderDialogState> {

  constructor(props: IShopDialogProps) {
    super(props);
    this.state = {
      comment: '',
    };
  }

  public render() {
    // сonst { props, onConfirm } = this;
    const { props, state, onConfirm, onCommentChange, onForChange } = this;
    const { onClose, data } = props;
    const { presentFor } = state;
    return (
      // <Dialog onClose={onClose} isShown={isShown} id={id}>
      <>
        <DialogTitle onClose={onClose}>{i18n.t('pryaniky.shop.dialogTitle')}</DialogTitle>
        <Textarea
          placeholder={i18n.t('pryaniky.shop.comment')}
          icon="edit"
          onChange={onCommentChange}
          className={cnDialog('Textarea')}
        />
        {data.isGift && (
          <>
            <DialogText>{i18n.t('pryaniky.shop.for', { text: data.name })}</DialogText>
            <InputMention
              onChange={onForChange}
              value={presentFor}
              type="users"
              icon="user-add"
              placeholder={i18n.t('pryaniky.shop.selectFor')}
            />
          </>
        )}
        <DialogActions>
          <Button main onClick={onConfirm} padding="lg">
            {i18n.t('pryaniky.shop.order')}
          </Button>
          <Button onClick={onClose} padding="lg">
            {i18n.t('pryaniky.shop.cancel')}
          </Button>
        </DialogActions>
      </>
      // </Dialog>
    )
  }

  private onCommentChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({comment: ev.target.value});

  private onForChange = (presentFor: any) => this.setState({ presentFor });

  private onConfirm = () => {
    utils.API.shop
      .order(this.props.data.id, {
        comment: this.state.comment,
        presentFor: this.state.presentFor ? this.state.presentFor.id : undefined,
        additionalFields: null
      })
      .r.then((response: IBasicResponse) => {
        if (utils.checkResponseStatus(response)) {
          const integatedMode = utils.getCookie('integrationMode');
          if (window.self !== window.top && integatedMode === integrationModes.sharepoint) {
            window.PryanikyMessage.pushMessage({ 
              type: 'push_message_to', 
              to: window.Pryaniky.init().from_frame_id, 
              msg: { 
                type: 'showToast', 
                condition: 'success',
                text: i18n.t('your order is successfully placed')
              }
            }, true);
            window.PryanikyMessage.pushMessage({ 
              type: 'push_message_to', 
              to: window.Pryaniky.init().from_frame_id, 
              msg: {
                type: 'changeUserThanksCount',
                count: this.props.myThanksCount - this.props.data.thanksPrice
              }}, true);
            setTimeout(this.props.onClose, 500);
          } else {
            this.props.changeVm('authUser.extData.mythanksCount', this.props.myThanksCount - this.props.data.thanksPrice);
            this.setState({ comment: '', presentFor: '' });
            this.props.onClose();
            toast.success(i18n.t('your order is successfully placed'));
          }
        } else {
          toast.error('server error');
        }
      });
  }
}

const mapStateToProps = (state: any, props: IShopDialogOwnProps) => {
  return {
    // data: state.widgets.contexts.common.dialogData,
    myThanksCount: getAuthUser(state).extData.mythanksCount || 0,
    currencyFormats: state.store.appSettings,
    data: props.id && state.modals[props.id] && state.modals[props.id].data || {},
  }
};

export const MakeOrderDialog = connect<IMakeOrderDialogStateProps, IDispatchProps, IShopDialogOwnProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(MakeOrderDialogPresenter);

// export const ShopDialog: React.FC<IShopDialogProps> = ({
//   onClose,
//   isShown,
//   onCommentChange,
//   onConfirm,
//   onForChange,
//   selectedProduct = {},
//   presentFor,
// }) => (
//   <Dialog onClose={onClose} isShown={isShown}>
//     <DialogTitle onClose={onClose}>{i18n.t('pryaniky.shop.dialogTitle')}</DialogTitle>
//     <Textarea
//       placeholder={i18n.t('pryaniky.shop.comment')}
//       icon="edit"
//       onChange={onCommentChange}
//       className={cnDialog('Textarea')}
//     />
//     {selectedProduct.isGift && (
//       <>
//         <DialogText>{i18n.t('pryaniky.shop.for', { text: selectedProduct.name })}</DialogText>
//         <InputMention
//           onChange={onForChange}
//           value={presentFor}
//           type="users"
//           icon="user-add"
//           placeholder={i18n.t('pryaniky.shop.selectFor')}
//         />
//       </>
//     )}
//     <DialogActions>
//       <Button main onClick={onConfirm} padding="lg">
//         {i18n.t('pryaniky.shop.order')}
//       </Button>
//       <Button onClick={onClose} padding="lg">
//         {i18n.t('pryaniky.shop.cancel')}
//       </Button>
//     </DialogActions>
//   </Dialog>
// );
