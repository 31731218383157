import { ILearningTracksReducer } from '../../reducers/TracksUse'
/**
 * 
 * declarate store selectors
 */
type stateType = { learningTrackUseReducer: ILearningTracksReducer }//затычка

/**
 * получить корневое состояние управления треками
 * @param state 
 */
export const getTracksManage = (state: stateType) =>
    state.learningTrackUseReducer;

/**
 * достать объект треков
 * @param state 
 */
export const getTracksObject = (state: stateType) =>
    getTracksManage(state).tracks

/**
 * достать объекты треков
 * @param state 
 */
export const getTracksMap = (state: stateType) =>
    getTracksObject(state).data

/**
 * вернёт список id треков
 * @param state 
 */
export const getTracksIds = (state: stateType) =>
    getTracksObject(state).keys


/**
 * объект материалов
 * @param state 
 */
export const getFilesObject = (state: stateType) =>
    getTracksManage(state).files


/**
* достать объекты материалов
* @param state 
*/
export const getFilesMap = (state: stateType) =>
    getFilesObject(state).values

/**
 * вернёт список id материалов
 * @param state 
 */
export const getFilesIds = (state: stateType) =>
    getFilesObject(state).keys