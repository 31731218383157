import React, { FC, useEffect, memo, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { withIndicator } from 'utils/src/CommonRedux/LoadIndicator'
import actionsTypes from '../../../../redux/actionsTypes/LMSTO'
import { SearchInput } from 'uielements/src';
import { Button, Icon, HelpTooltip } from 'uielements/src';
import { NEW__useInfiniteScroll as useInfiniteScroll, useRefedCallback } from 'utils/src/hooks';
import ListPlaceholder from 'uielements/src/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import LinearProgress from '@material-ui/core/LinearProgress';
import { useScrollListState } from 'utils/src/hooks'
import API from 'utils/src/requests/requests'
import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu'
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { xorWith, isEqual, unionWith, some, remove } from 'lodash'
import { tSt } from 'LMSModule/utils/i18n.adaptor'

import { pick, Many, PropertyPath } from 'lodash'
import Item from './Item'

const LoadButton = withLoadSpin(Button)

type IPresenterProps = StatisticsFull.GameList.IPresenterProps
type IOwnProps = StatisticsFull.GameList.IOwnProps
type IDispatchProps = StatisticsFull.GameList.IDispatchProps
type IStateProps = StatisticsFull.GameList.IStateProps
type IState = StatisticsFull.GameList.IState




export const Presenter: FC<IPresenterProps> = ({
    className,
}) => {
    const [search, setSearch] = useState('')

    const {
        loadMore,
        triggerRef,
        values,
        isLoading,
        isFinished,
        error: {
            withError,
        },
        // select: {
        //     addSelect,
        //     isSelected,
        //     removeSelect,
        //     selected,
        //     togleSelect
        // }
    } = useScrollListState(async function (skipCount) { return (await API.lms.getLmsBadgeSearch(search, skipCount, 10)).data }, [search])




    const count = values?.length || 0

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Header')}>
            <div className={cnClassName('Noop')}></div>
            <div className={cnClassName('SearchBox')}>
                <SearchInput
                    loading={isLoading}
                    value={search}
                    className={cnClassName('Search')}
                    onChange={setSearch} />
            </div>
            <div className={cnClassName('Noop')}></div>
            {/* <div className={cnClassName('Actions')}>
                <LoadButton isLoading={excelLoading} noPadding onClick={getExcel} className={cnClassName('ActionBtn')}>
                    <Icon icon={"file-excel"} />
                </LoadButton>
            </div> */}
        </div>
        <ListPlaceholder
            className={cnClassName('Placeholder')}
            isFinished={isFinished}
            isError={withError}
            textError={<Button
                className={cnClassName('ErrorTrigger')}
                onClick={loadMore}
            >
                {tSt('load.again')}
                {/* пробовать ещё */}
            </Button>}
            titleError={tSt('load.error.title')}//{'Ошибка получения данных'}
            isLoading={isLoading}
            itemsCount={count}
            titleEmpty={tSt('load.empty.title')}//{'Пусто'}
            textEmpty={tSt('load.empty.text')}//{'Когда ни будь, что ни будь'}
            textLoad={tSt('load.holder.text')}//{'Загрузка...'}
        >
            {isLoading && <LinearProgress className={cnClassName('Progress')} />}
            <TableContainer className={"classes.container"}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{tSt('th.badge')}</TableCell>
                            <TableCell>{tSt('th.rule_id')}</TableCell>
                            <TableCell>{tSt('th.rule_name')}</TableCell>
                            <TableCell>{tSt('th.thanks_count')}</TableCell>
                            <TableCell>{tSt('th.user')}</TableCell>
                            <TableCell>{tSt('th.timeStamp')}</TableCell>
                            <TableCell>{tSt('th.publocation_url')}</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {values?.map((item: any) => <Item key={item.badgeUid} item={item} />)}
                    </TableBody>
                </Table>
            </TableContainer>
            {!isFinished && (
                <LoadButton
                    forwardedRef={triggerRef}
                    isLoading={isLoading}
                    className={cnClassName('Trigger')}
                    onClick={loadMore}
                >
                    {tSt('load.more')}
                    {/* {'Ещё'} */}
                </LoadButton>)}

        </ListPlaceholder>


    </div>
}

export default withIndicator(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter))), actionsTypes.GET_COURSES_STATS_LIST)();
