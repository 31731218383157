import React, { FC, useState } from 'react'
import {
    cnClassName,
    IAddWikiToTrackOwnProps,
    IAddWikiToTrackResult,
    IAddWikiToTrackPresenterProps,
    IAddWikiToTrackStateProps,
    IAddWikiToTrackDispatchProps,
    mapDispatchToProps,
    mapStateToProps
} from './interfaces'
import './style.scss'
import { createModal, TransitionComponent } from '../../dialogs/modal'
import SingleAutocomplete from 'uielements/src/MaterialElements/SingleAutocomplete'
import withTrackSuggester from 'blocks/HOCs/SingleAutocomplete/withTrackSuggester'
import { connect } from 'react-redux';
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import {
    // DialogTitle,
    // DialogActions,
    Button
} from 'uielements/src';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
const TrackSelect = withTrackSuggester(SingleAutocomplete)

export const Presenter: FC<IAddWikiToTrackPresenterProps> = ({
    handleAccept,
    handleClose,
    className,
    data,
    ...other
}) => {
    const [tid, setTid] = useState<string | undefined>(undefined)
    
    const accept = () => {
        if (tid) {
            handleAccept({
                tid,
                wid: data.id || ''
            })
        }
    }
    return <div>
        <DialogTitle onClose={handleClose}>
            {tTr('dialogs.add_wiki.title')}
        </DialogTitle>
        <div className={cnClassName({}, [className])}>
            <div className={cnClassName('Title')}>
                {data.title}
            </div>
            <TrackSelect
                onSelect={(item) => setTid(item?.id)}
                label={tTr('dialogs.add_wiki.select_track.label')}
            />
        </div>
        <DialogActions 
        acceptText={tTr('dialogs.add_wiki.confirm')}
        closeText={tTr('dialogs.add_wiki.close')}
        disableAccept={!tid}
        onAccept={accept}
        onClose={handleClose}/>
            {/* <Button main disabled={!tid} onClick={accept}>{tTr('dialogs.add_wiki.confirm')}</Button>
            <Button onClick={handleClose}>
                {tTr('dialogs.add_wiki.close')}
            </Button>
        </DialogActions> */}
    </div>
}
const Connected = connect<IAddWikiToTrackStateProps, IAddWikiToTrackDispatchProps, IAddWikiToTrackOwnProps, any>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
export default createModal<IAddWikiToTrackResult, IAddWikiToTrackOwnProps>(Connected, { 
    maxWidth: 'md',
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            overflowY: 'unset'
        }
    },
    TransitionComponent
 });
