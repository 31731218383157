import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
  SET_AUTHOR_COURSES: '',
  SET_SESSION_ARCHIVED: '',
  SET_STUDENTES: '',
  SET_STUDENTS_RESULTS: '',
  SET_SESSION_PAUSE: '',
  ADD_SESSION: '',
  SET_SINGLE_COURSE: '',
  SET_MODERATINO_ANSWERS: '',
  SET_TO_USERS: '',
  SET_MODERATION_FILTERS: '',
  SET_ANSWER_MODERATION_STATUS: '',
  SET_LOADING: '',
  SET_STUDENT_SESSIONS: '',
  ADD_SESSIONS: '',
  SET_SESSIONS_LOADING: '',
  SET_SESSION: '',
  ADD_UNIT: '',
  UPDATE_UNIT: '',
  ADD_QUESTIONS: '',
  UPDATE_QUESTIONS: '',
  ADD_ANSWERS: '',
  UPDATE_ANSWERS: '',
  UPDATE_COURSE: '',
  SET_STUDENTS_LOADING: '',
  SET_STUDENTS_FILTERS: '',
  SET_MODERATION_LOADING: '',
  CREATE_ANSWER: '',
  CREATE_QUESTION: '',
  CREATE_UNIT: '',
  CREATE_UNIT_COMPLETE: '',
  CHANGE_RADIO_ANSWERS: '',
  SET_RADIO_ANSWERS: '',
  CHANGE_UNIT_POSITION: '',
  CREATE_COURSE: '',
  ADD_COURSE: '',
  REQUEST_BEGIN: '',
  REQUEST_COMPLETE: '',
  REQUEST_ERROR: '',
  LOAD_CATEGORIES: '',
  SET_CATEGORIES: '',
  SAVE_LESSON: '',
  THROTTLED_SEND_COURSE_TO_SERVER: '',
  DELETE_UNIT: '',
  SAVE_UNIT: '',
  SET_UNIT_MODIFED: '',
  LOAD_STUDENTS_SESSIONS: '',
  CHANGE_QUESTIONS_TYPE: '',
  UNIT_WITH_ERROR: '',
  CHANGE_CHECK_ANSWERS: '',
  LOAD_UNIT_CONTENT: '',// загрузить контент указаного юнита
  CONVERT_LEGACY_PAGE: '',// создать новый юнит типа wiki на основе контента срарого юнита page
  DELETE_QUESTION: '', // удаляет вопрос.
  RESET_UNIT_CHANGE: '', // отменяет не сохранёные изменения в юните.
  QUESTION_WITH_ERROR: '', // добавляет ошибку в вопросе
  DELETE_ANSWER: '', // удаляет вариант ответа
  ANSWER_WITH_ERROR: '', // добавляет ошибку в ответе
  SET_USERS_IN_COURSE: '', // добавить в стор юзеров котоым доступен курс

  /* to saga */
  LOAD_USERS_IN_COURSE: '',// загрузить юзеров которым доступен курс
  LOAD_UNIT_VERSIONS: '', // загрузить список весий юнита 
  ALLOW_PLAY_COURSE: '', // доступноле прохождение курса
  LOAD_STUDENTS_STATUS: '', // получить статистику состояний студентов на курсе
  LOAD_UNITS_ATTENDANCE: '', // получить статистику - ВЫПОЛНЕНИЕ ЗАДАНИЙ
  LOAD_STAT_COMMENTS_COUNT: '', // получить статистику - КОММЕНТАРИИ К УРОКАМ
  LOAD_UNIT_COMMENTS: '', // получение ленты комментариев к юнитам
  GET_USERS_COURSES_INFO_HEAD: 'GET_USERS_COURSES_INFO_HEAD',//
  GET_USERS_COURSES_INFO: 'GET_USERS_COURSES_INFO',//
  GET_USER_STAT_INFO: 'GET_USER_STAT_INFO',//
  GET_USER_STAT_LIST: 'GET_USER_STAT_LIST',//
  LOAD_BADGES: 'LOAD_BADGES',//
  GET_COURSES_STATS_LIST: 'GET_COURSES_STATS_LIST', // получить список курсов
  LOAD_TEST_RESULTS: 'LOAD_TEST_RESULTS', //
  

  /** to reducer */
  SET_UNIT_VERSIONS: '', // добавляет список версий юнита в стор
  SET_ALLOW_PLAY: '', // проверка доступа к курсу в процессе
  SET_STUDENTS_STATUS: '',// кинуть в стор статистику  состояний студентов на курсе
  SET_UNITS_ATTENDANCE: '', // кинуть в стор статистику - ВЫПОЛНЕНИЕ ЗАДАНИЙ
  SET_STAT_COMMENTS_COUNT: '', // кинуть в стор статистику - КОММЕНТАРИИ К УРОКАМ
  SET_UNIT_COMMENTS: '', // кинуть в стор комментарии к юнитам
  APPEND_UNITS_REPLY: '', // добавление в ленту комментариев
  CHANGE_UNITS_REPLY_FIELD: '', // изменяет указаное свойство в unitsReplys
  PATH_USERS_COURSES_INFO_HEAD: 'PATH_USERS_COURSES_INFO_HEAD',//
  PATH_FULL_STATISTIC_SETTING: 'PATH_FULL_STATISTIC_SETTING',//
  ADD_USERS_COURSES_INFO_LIST: 'ADD_USERS_COURSES_INFO_LIST',//
  SET_USER_STAT_INFO: 'SET_USER_STAT_INFO',//
  ADD_USER_STAT_COURSES_LIST: 'ADD_USER_STAT_COURSES_LIST',//
  ADD_COURSES_STAT_LIST: 'ADD_COURSES_STAT_LIST',//
  SET_BADGES: 'SET_BADGES',//
  SET_TEST_RESULTS: 'SET_TEST_RESULTS',//

} as const

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.LMSTO) }), {} as typeof actions);