import * as React from 'react';
// import * as utils from 'utils/src/utils';
// import { withRouter } from "react-router"
import WidgetPresenter from '../Widget';
// import { withBemMod } from '@bem-react/core';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeUserLmsStatProps } from './Widget_type_userLmsStat.index';
import StatWidget from '../../../LMSModule/LMS/CourseStatusesWidget'
import { widgets } from 'i.widgets';
import * as utils from 'utils/src/utils';

const type = 'UserLmsStat';

class WidgetTypeUserLmsStat extends WidgetPresenter<IWidgetTypeUserLmsStatProps> {

  public render() {
    if (!this.props.widget) return null;

    if (!this.props.wcontext.uId) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Tag = widgets.components.common
    return (
      <Tag {...this.props} className={cnWidget({ type, subtype })}>
        <StatWidget userid={this.props.wcontext.uId} />
      </Tag>
    );
  }
}

export default WidgetTypeUserLmsStat