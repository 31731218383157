import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace ReplyLayout {

        export type IPresenterProps = IOwnProps

        export interface IOwnProps extends IClassNameProps {
            children: React.ReactNode
            avatar: React.ReactNode
            title: React.ReactNode
            service: React.ReactNode
            actions: React.ReactNode
            additional?: React.ReactNode
        }
    }
}
export const cnClassName = cn('NewsReplyLayout');