import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ReactNode, Dispatch } from 'react';
import { 
  // likeNewsRequest, 
  // deleteNewsRequest, 
  // deleteNews, 
  // loadCommentRequest, 
  // updateNews, 
  // readNews 
} from "../../redux/actions/News";
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { BaseNews } from 'blocks/NewsTypes/BaseType'
export interface INewsViewProps extends ReturnType<typeof mapDispatchToProps>, IClassNameProps, ReturnType<typeof mapStateToProps>, RouteComponentProps<any> {
  data: BaseNews;//{[s: string]: any};
  newsId: string;
}

export interface INewsViewState {
  text?: string;
  // data: {[s: string]: any};
  thank: boolean;
  liked: boolean;
  edit: boolean;
  additionalReplyActions?: (commentID: string, user: any) => ReactNode;
  additionalReplyForm: () => ReactNode;
}

export const mapStateToProps = (state: any, props: any) => ({
  currentUser: getAuthUser(state).baseData,
  // comments: state.news.comments
  data: state.newsReducer.values[props.newsId],
  enabledThanks: state.store.appSettings && state.store.appSettings.enabledModules.thanks,
  // viewType: state.news.newsViewType
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  // likeNewsRequest,
  deleteNewsRequest: ()=>{},
  // deleteNews,
  // loadCommentRequest,
  // updateNews,
  // readNews:
}, dispatch);

export const cnNewsView = cn('NewsView');
