import React, { FC } from 'react';
import { IBadgesTooltipListProps } from './BadgesTooltipList.index';
import { LineBox, WrappingLineBox } from './../../styled';
import { useItemsCounter } from './useItemsCounter';
import { Container } from '@material-ui/core';
import { BadgeItemMui } from './BadgeItem/BadgeItemMui';


export const BadgesTooltipList: FC<IBadgesTooltipListProps> = ({
    count = 10,
    items,
    size = 64,
    onRemove,
    setVisibleBadges,
}) => {

    const blockRef = React.useRef<HTMLDivElement>(null);
    const itemsPerRow = useItemsCounter(count, blockRef, size)
    const visibleCount = (items.length < itemsPerRow * 2) ? items.length : itemsPerRow * 2;
    setVisibleBadges(visibleCount)

    return (
        <Container disableGutters={true} ref={blockRef}>
            {items.length > itemsPerRow * 2 ? <>
                <LineBox>
                    {items.slice(0, itemsPerRow).map((item) => (
                        <BadgeItemMui key={item.badgeUid}
                            item={item}
                            onRemove={onRemove}
                            size={size} />
                    ))}
                </LineBox>
                <LineBox>
                    {items.slice(itemsPerRow, itemsPerRow * 2).map((item) => (
                        <BadgeItemMui key={item.badgeUid}
                            size={size}
                            item={item}
                            onRemove={onRemove} />
                    ))}
                </LineBox>
            </>
                :
                <WrappingLineBox>
                    {items.map((item) => (
                        <BadgeItemMui key={item.badgeUid}
                            item={item}
                            size={size}
                            onRemove={onRemove} />
                    ))}
                </WrappingLineBox>
            }

        </Container>
    );
};


