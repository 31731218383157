// import { IBodyProps } from '../NewsEdit-Body.index';

// import { IDispatchProps } from 'redux/connector';


export interface IBodyTypeNewsProps { // extends IBodyProps, IBodyStateProps, IDispatchProps {
  tag?: 'div';
}

export interface IBodyTypeNewsState {
  text?: string;
}

export interface IBodyStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})