import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';

export interface IBadgeProps extends IClassNameProps, IBadgeStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
    className?: string;
}

export interface IBadgeState {

}

export interface IBadgeStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({

})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export const cnBadge = cn('LMSBadge');