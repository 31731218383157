import Component, { Presenter } from './component';
import {
    LerningTrack,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = LerningTrack.TrackList.IOwnProps;
export type IComponentPresenterProps = LerningTrack.TrackList.IPresenterProps;


export default Component