import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IStateType as IState } from 'redux/store';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { getNetworkGroupsSelector, getMotifiTemplatesSelector } from 'redux/notifiSettings/selectors';
import { getNotifiSettingsNetworkGroups, changeNotifiSettingsGroupTemplate, clearNotifiSettings } from 'redux/notifiSettings/actions';

export interface INotifiSettingsGroupsProps extends IMapStateToProps, IMapDispatchToProps, WithTranslation, RouteComponentProps<IParams>, INotifiSettingsGroupsOwnProps { }

interface INotifiSettingsGroupsOwnProps {
  networkId: string;
  isSingleNetwork?: boolean;
}

export const mapStateToProps = (state: IState, props: INotifiSettingsGroupsOwnProps) => {
  return {
    groups: getNetworkGroupsSelector(props.networkId)(state),
    parrentNetworkId: state.store.appSettings?.parentNetwork?.id || state.store?.appSettings?.appId,
    templates: getMotifiTemplatesSelector(state)
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getNotifiSettingsNetworkGroups,
  changeNotifiSettingsGroupTemplate,
  clearNotifiSettings
}, dispatch);

export interface IParams {
  tab: 'common' | 'groups' | 'group' | 'network';
  uid: string;
  gid?: string;
  nid?: string;
}

type IMapStateToProps = ReturnType<typeof mapStateToProps>;
type IMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;

export const cnNotifiSettingsGroups = cn('NotifiSettingsGroups');
