import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { PropsWithRef } from 'react';
import { NLeftMenu } from '../LeftMenu.index';

export const cnLeftMenuBar = cn('LeftMenuBarMui');

export namespace NLeftMenuBar {

  export interface Props extends PropsWithRef<IClassNameProps>, Pick<NLeftMenu.Props, 'stayOpened'> {
    id: string;
    path: string[];
    zIndex?: number;
  }
}