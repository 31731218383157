import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
export interface IChartProps extends IClassNameProps {
    rightPercent: number;
    wrongPercent: number;
    waitModerationCount?: number;
    moderatedAnswersCount?: number;
    showAll?: boolean;
}

export const cnChart = cn('ResultChart');