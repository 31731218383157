import * as React from 'react';
import { defaultBaseNews } from '../../../../BaseType'
import { IEditorTypeNewsProps } from './news.index';
import { PollNews, defaultPollNews, defaultAnswer } from '../../../Polls.types'
import { PollValidator, answerValidate, pollValidate } from '../../../Poll.validate'
import { baseValidate } from '../../../../Base.validate'
import { Editor } from '../../Editor'
import { v1 as uuid } from 'uuid';
import * as utils from 'utils/src/utils';
import { EditorState, convertToRaw } from 'draft-js';
import { defaultDate } from '../../../Polls.types';

export const EditorTypeNews: React.FC<IEditorTypeNewsProps> = ({ onChange: cbChange, data }) => {
    let localData = data as any;
    if (data.newstype !== 7) {
        localData = {
            ...utils.cloneObject(defaultPollNews), text: EditorState.createEmpty()
        }
    }

    const onChange = (data: any) => {
        const vb = new PollValidator();
        vb.addData(data)
        vb.setRules([...answerValidate, ...pollValidate, ...baseValidate])
        cbChange(data, vb.validation().length === 0, vb.validation())
    }


    const onChangeText = (val: string) => {
        localData.text = val;
        onChange(localData);
    }
    const addNewAnswer = () => {
        localData.poll.answers.push({ ...defaultAnswer, text: '', id: uuid() });
        onChange(localData);
    };
    const changeAnswerText = (index: number, value: any) => {
        localData.poll.answers[index].text = value.target.value;
        onChange(localData);
    }
    const removeAnswer = (index: number) => {
        localData.poll.answers.splice(index, 1);
        onChange(localData);
    }
    const onChangeFreeAnswers = (e: any) => {
        localData.poll.freeAnswer = !localData.poll.freeAnswer;
        onChange(localData);
    }

    const onChangeSecondAnswers = (e: any) => {
        // localData.poll.secondAnswers = !localData.poll.secondAnswers;
        localData.poll.answerVariants = localData.poll.answerVariants === 100 ? 1 : 100;
        onChange(localData);
    }

    const onChangeShowParticipants = () => {
        localData.poll.showParticipants = !localData.poll.showParticipants;
        onChange(localData);
    }
    const expiresChange = (date: any) => {
        if (!date) date = defaultDate;
        localData.poll.expires = date;
        localData.expires = date;
        onChange(localData);
    }

    const onChangeShowResultsBeforeVote = () => {
        localData.poll.showResultsBeforeVote = !localData.poll.showResultsBeforeVote;
        onChange(localData);
    }

    const onChangeDisableRevote = () => {
        localData.poll.disableRevote = !localData.poll.disableRevote;
        onChange(localData);
    }
    return <Editor
        onChangeDisableRevote={onChangeDisableRevote}
        expiresChange={expiresChange}
        addNewAnswer={addNewAnswer}
        changeAnswerText={changeAnswerText}
        removeAnswer={removeAnswer}
        onChangeFreeAnswers={onChangeFreeAnswers}
        onChangeSecondAnswers={onChangeSecondAnswers}
        onChangeShowParticipants={onChangeShowParticipants}
        onChangeText={onChangeText}
        onChangeShowResultsBeforeVote={onChangeShowResultsBeforeVote}
        data={localData} />
}
