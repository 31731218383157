import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    isAbsolutelyCompleted: boolean,
    attempt: any,
    cangeAttempt: any,
    unitContent: any,
    unitResult: any,
    detailsRender: any
    fullscreen?: boolean
    disableActions?: boolean
    actions?: React.ReactNode
}

export const cnClassName = cn('TaskResult');