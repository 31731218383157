import React, { FC, useEffect, useState } from 'react';
import './Widget_type_birthdays.scss';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { cnWidget, IWidgetProps, cnWidgetMui } from '../Widget.index';
import { widgets } from '../../../i.widgets';
// import { HtmlWidget } from 'blocks/HtmlWidget/HtmlWidget';
import { cn } from '@bem-react/classname'
import { loadExternalModule } from 'utils/src/loadExternalModule'
import { useInjector } from 'utils/src/ComponentInjector'
import { useSelector } from 'react-redux';
import './Widget_type_html.scss';
import { TinyMCE } from 'blocks/TinyMCEWidget/TinyMCEWidget'

export const cnHTMLWidget = cn('HTMLWidget')
const type = 'html';


// const Loader: FC<any> = ({ children }) => {
//   const [isLoading, setLoading] = useState(true)
//   useEffect(() => {
//     loadExternalModule({
//       app: 'main',
//       id: 'tintmcewidget_lib',
//       endpoint: 'tintmcewidget_lib',
//       jsUrl: '/static/js/tintmcewidget.bundle.js',
//       // store,
//       cssUrl: '/static/css/tintmcewidget.css',
//       // requireEnabled: 'learningtracks',
//       // adminOnly: true
//     }).then(() => {
//       setLoading(false)
//     }).catch(() => {
//       setLoading(false)
//     })
//   }, [])
//   if (isLoading) return <>Loading...</>
//   return <>{children}</>
// }

// const Widget: FC<any> = (props) => {
//   const textEditorColors = useSelector((state: any) => state.store?.appDesign?.colors?.textEditorColors as string[] | undefined)
//   const colorMap = textEditorColors ? textEditorColors.map(c => c.replace('#', '')).reduce<string[]>((a, c) => ([...a, c, '#' + c]), []) : undefined
//   const { getComponentById } = useInjector(['tinymce_widget'])
//   const Component = getComponentById('tinymce-widget')
//   if (Component) {
//     return <Component {...props} className={cnHTMLWidget({}, [props.className])} color_map={colorMap} />
//   }
//   return <></>
// }

export default class WidgetTypeMarkdownPresenter extends WidgetPresenter<IWidgetProps> {
  public render() {
    const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    return (
      <widgets.components.common {...this.props} className={cnWidget({ type })}>
          <TinyMCE
            {...this.props}
            className={cnWidgetMui({ type, subtype })}
            contexts={this.relations.concat(this.props.widget.id)}
            // tag={widgets.components.common}
            isEditable={this.props.widget.isEditable}
            isEdit={this.props.widget.HTMLWidgetContentEdit}
          />
      </widgets.components.common>
    );
  }
}
