import { IAddExpertProps } from './AddExpert.index';
import * as React from 'react'
import './AddExpert.scss'
import { InputMention } from 'blocks/InputMention/InputMention';
// import { setExperts } from '../IdeasSides'
import { ButtonPopover } from '../../common/ButtonPopover/ButtonPopover'
import { ActionButtons } from '../../../ActionButtons/ActionButtons';
import { FieldsHeader } from '../FieldsHeader/FieldsHeader';
import i18n from 'localizations/i18n';
import { useLazyExecutSaga, useExecutSaga } from 'utils/src/CommonRedux/LoadIndicator'
import { setExperts } from '../redux/actions'
import { useDependedState } from 'utils/src/hooks'
import {
    API
} from 'utils/src/utils'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { getNewsParamsById } from 'News/redux/saga/selectors'
import { pathNews } from 'News/redux/actions'
import { IdeaNews } from '../IdeaType'
import * as utilsProject from 'utils.project/utils.project'

export const AddExpert: React.FC<IAddExpertProps> = ({ ideaId, statusID, onComplite, experts, anchor }) => {
    const { idea } = useSelector(getNewsParamsById<IdeaNews, 'idea'>(ideaId, 'idea'))

    const responsibleForStatusView = idea.responsibleForStatusView || []

    const [values, setValue] = useDependedState<any[]>(responsibleForStatusView, [responsibleForStatusView]);

    const { dispatchAction, isLoading, result } = useLazyExecutSaga(() => setExperts({
        ideaId,
        experts: responsibleForStatusView,
        newExperts: values
    }), [ideaId, values, responsibleForStatusView])

    const onSave = () => {
        dispatchAction()
    }

    return (
        <ButtonPopover anchor={anchor} label={i18n.t('pryaniky.idea.add.expert')} isLoading={isLoading} accepted={onSave}>
            {(close, accepted) =>
                <div className={'AddExpert'}>
                    <FieldsHeader>{i18n.t('pryaniky.idea.select.experts')}:</FieldsHeader>
                    <InputMention
                        excludeMe={false}
                        onChange={(users: any[]) => setValue(users)}
                        type={'users'}
                        icon='user-add'
                        value={values}
                        placeholder={i18n.t('pryaniky.idea.select.experts')} />
                    <ActionButtons save={accepted} cancle={close} />
                </div>
            }
        </ButtonPopover>);
}