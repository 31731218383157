import { imageBlock, linkBlock, underline } from '../../RemarkablePlugins/imageBlock'
import videoUtils from './VideoPlugin/video/utils'
import { RawDraftContentBlock } from 'draft-js'
export { rawToMd } from 'uielements/src/PryanikyEditorV1/converter/convertorConfigs'
const videoParser = (url: string) => {
  // https://www.youtube.com/watch?v=holzBghWTlY
  // https://www.youtube.com/embed/ZW2wo2OtHSs?showinfo=1
  // <iframe width="560" height="315" src="https://www.youtube.com/embed/holzBghWTlY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  // https://vimeo.com/302312473
  var rez: any = {},
    videoId: any,
    lastIndex = 0,
    i;
  rez.src = url;
  rez.dataUrl = url;
  rez.previewUrl = '';
  rez.videoId = url;
  rez.type = 'prn'
  if (url.indexOf('youtube.com') !== -1) {
    if (url.indexOf('iframe') !== -1) {
      for (i = url.indexOf('embed') + 6; i < url.length; i++) {
        if (url[i] === '"') {
          lastIndex = i;
          break;
        }
      }
      videoId = url.substring(url.indexOf('embed') + 6, lastIndex + 1);
    } else
      if (url.indexOf('embed') !== -1) {
        for (i = url.indexOf('embed') + 6; i < url.length; i++) {
          if (url[i] === '?' || i === url.length - 1) {
            lastIndex = i;
            break;
          }
        }
        videoId = url.substring(url.indexOf('embed') + 6, lastIndex + 1);
      } else {
        for (i = url.indexOf('v=') + 2; i < url.length; i++) {
          if (url[i] === '&' || i === url.length - 1) {
            lastIndex = i;
            break;
          }
        }
        videoId = url.substring(url.indexOf('v=') + 2, lastIndex + 1);
      }
    if (videoId) rez.previewUrl = 'https://i1.ytimg.com/vi/' + videoId + '/0.jpg';
    rez.type = 'youtube';
    rez.videoId = videoId;
    rez.dataUrl = `https://www.youtube.com/embed/${videoId}?showinfo=0`
  } else if (url.indexOf('youtu.be') !== -1) {
    videoId = url.slice(url.indexOf('youtu.be/') + 9);
    if (videoId) {
      rez.previewUrl = 'https://i1.ytimg.com/vi/' + videoId + '/0.jpg';
      rez.type = 'youtube';
      rez.videoId = videoId;
      rez.dataUrl = `https://www.youtube.com/embed/${videoId}?showinfo=0`
    }

  }
  else
    if (url.indexOf('vimeo.com') !== -1) {
      videoId = url.substring(url.indexOf('vimeo.com') + 10).split('/')[0];
      if (videoId.indexOf('video') !== -1) videoId = videoId.substring(videoId.indexOf('video') + 6);
      if (videoId[videoId.length - 1] === '/') videoId = videoId.substring(0, videoId.length - 1);
      rez.url = 'vimeo';
      rez.previewUrl = url;
      rez.type = 'vimeo';
      // rez.previewUrl = 'https://vimeo.com/api/v2/video/' + videoId + '.xml';
      rez.dataUrl = `https://player.vimeo.com/video/${videoId}/`;
      rez.videoId = videoId;
      /*return new Promise(function(resolve, reject) {
        var x: any = new XMLHttpRequest();
        x.open("GET", 'https://vimeo.com/api/v2/video/' + videoId + '.xml', true);
        x.onreadystatechange = function () {
          if (x.readyState === 4 && x.status === 200)
          {
            var data = x.responseXML.firstChild.firstChild.children;
            var obj: any = {};
            obj.dataUrl = url;
            for(var index = 0; index < data.length; index++) {
              if(data[index].tagName === 'thumbnail_large') obj.previewUrl = data[index].textContent;
            }
            // if(videoId) obj.previewUrl = 'https://i1.ytimg.com/vi/' + videoId + '/0.jpg';
            obj.type = 'vimeo';
            resolve(obj );
          }
        };
        x.send();
      });*/
    }
    else
      if (url.indexOf('&%&') !== -1) {
        var data = url.split('&%&');
        rez.dataUrl = data[0];
        rez.previewUrl = data[1];
        rez.type = data[2];
      }
  return rez;
  /*return new Promise(function(resolve, reject) {
    resolve(rez);
    // setTimeout(() => resolve(rez), 1000);
  }); */
}

/**
 * deprecated
 */
export const _rawToMd = {
  preserveNewlines: true,
  styleItems: {
    "UNDERLINE": {
      open: () => {
        return '<u>';
      },

      close: () => {
        return '</u>';
      }
    },
    'unstyled': {
      open: function (block: RawDraftContentBlock) {
        const style = block.inlineStyleRanges.find(val => ['right', 'center', 'justify'].includes(val.style))
        if (style) {
          return `[align ${style.style}]`
        }
        return '';
      },

      close: function (block: RawDraftContentBlock) {
        const style = block.inlineStyleRanges.find(val => ['right', 'center', 'justify'].includes(val.style))
        if (style) {
          return `[/align]`
        }
        return '';
      }
    },
  },
  entityItems: {
    'IMAGE': {
      open: (entity: any) => {
        return ``;
      },

      close: (entity: any) => {
        const { src, ...other } = entity.data
        return `![${btoa(unescape(encodeURIComponent(JSON.stringify(other))))}](${src})`;;
      }
    },
    'mention': {
      open: (entity: any) => {
        return `[`;
      },

      close: (entity: any) => {
        const { link, name } = entity.data.mention
        return `](${link})`;
      }
    },
    '#mention': {
      open: (entity: any) => {
        return `[`;
      },

      close: (entity: any) => {
        const { link, name } = entity.data.mention
        return `](${link})`;
      }
    },
    "LINK": {
      open: function (entity: any) {
        const link = entity.data.href || entity.data.url
        if (videoUtils.isYoutube(link)) return '';
        return '[';
      },

      close: function (entity: any) {
        const link = entity.data.href || entity.data.url
        if (videoUtils.isYoutube(link)) {
          const data = videoParser(link);
          return `[![pryanikyVideo-${data.type}](${data.previewUrl})](${data.dataUrl} "pryanikyVideo-${data.type}-${data.videoId}")`;
        }
        return `](${entity.data.url || entity.data.href})`;
      }
    },
    "attachment-file": {
      open: function (entity: any) {
        return '';
      },

      close: function (entity: any) {
        return `[![pryanikyAttach-${entity.data.data.action}](${entity.data.data.previewUrl})](${entity.data.data.originalUrl} "pryanikyAttach-${entity.data.data.action}-${entity.data.data.id}")`//`[${entity.data.data.originalUrl}](${entity.data.data.originalUrl})`;
      }
    },
    'draft-js-video-plugin-video': {
      open: (entity: any) => {
        return ``;
      },

      close: (entity: any) => {
        const { src, isPryaniky, id, name, previewUrl, ...other } = entity.data.data;
        if (isPryaniky) {
          return `[![pryanikyVideo-prn](${previewUrl})](${src} "pryanikyVideo-prn-${id}/${name}")`;
        }
        const data: any = {}//videoParser(src);
        if (videoUtils.isYoutube(src)) {
          const youtube = videoUtils.getYoutubeSrc(src)
          data.previewUrl = 'https://i1.ytimg.com/vi/' + youtube.srcID + '/0.jpg';
          data.type = youtube.srcType;
          data.videoId = youtube.srcID;
          data.dataUrl = `https://www.youtube.com/embed/${youtube.srcID}?showinfo=0`
        }
        if (videoUtils.isVimeo(src)) {
          const vimeo = videoUtils.getVimeoSrc(src)
          data.url = 'vimeo';
          data.previewUrl = previewUrl || '/public/img/cinema_placeholder.jpg'//'vimeo';
          data.type = 'vimeo';
          // rez.previewUrl = 'https://vimeo.com/api/v2/video/' + videoId + '.xml';
          data.dataUrl = `https://player.vimeo.com/video/${vimeo.srcID}/`;
          data.videoId = vimeo.srcID;
        }
        // return `[pryanikyVideo-${data.type}](${data.dataUrl} "test-title")`;
        // return `[![pryanikyVideo-${data.type}](${data.previewUrl})](${data.dataUrl} "pryanikyVideo-${data.type}-${data.videoId}")`;
        return `[![pryanikyVideo-${data.type}](${data.previewUrl})](${data.dataUrl} "pryanikyVideo-${data.type}-${data.videoId}")`;
      }
    }
  }
}

export const mdToRaw = {
  remarkablePlugins: [imageBlock, linkBlock, underline],
  remarkablePreset: 'commonmark',
  remarkableOptions: {
    html: true
  },
  /*remarkableOptions: {
    disable: {
      inline: ['links', 'emphasis'],
      block: ['heading']
    },
    enable: {
      inline: ['links'],
      //block: 'table',
      //core: ['abbr']
    }
},*/
  blockStyles: {
    underline_open: 'UNDERLINE',
  },
  blockTypes: {
    // underline: function (item: any) {
    //   return {
    //     type: 'unstyled',
    //     text: '',
    //     entityRanges: [],
    //     inlineStyleRanges: []
    //   }; 
    // },
    // paragraph_open: function (item: any) {
    //   return {
    //     type: 'unstyled',
    //     text: '',
    //     entityRanges: [],
    //     inlineStyleRanges: []
    //   };
    // },
    image_open: (item: any) => {
      return {
        type: 'atomic',
        text: " ",
        inlineStyleRanges: [],
        entityRanges: []
      }
    },
    video_open: (item: any) => {
      return {
        type: 'atomic',
        text: " ",
        inlineStyleRanges: [],
        entityRanges: []
      }
    },
    // video_close: (item: any) => {
    //   return {
    //     type: 'atomic',
    //     text: " ",
    //     inlineStyleRanges: [],
    //     entityRanges: []
    //   }
    // }
    // link_open: (item: any) => {
    // },
    /*link_close: (item: any) => {
    }*/
    /*customCode_open: (item: any) => {
      return {
        type: 'atomic',
        text: " ",
        inlineStyleRanges: [],
        entityRanges: []
      }
}*/
  },

  blockEntities: {
    image_open: (item: any) => {
      let title = {}
      try {
        title = JSON.parse(item.title);
      } catch (e) {
        console.warn('error in JSON.parse, wrong json data in image item.title:', item.title)
      }
      return {
        type: "IMAGE",
        mutability: "IMMUTABLE",
        data: {
          src: item.src,
          ...title
        }
      };
    },
    video_open: (item: { title: string, src: string, alt: string }) => {

      let data = item.alt.split('-', 2)
      let type = 'prn'
      let id = ''
      let link = item.src.trim()
      if (videoUtils.isYoutube(link)) {
        const youtube = videoUtils.getYoutubeSrc(link)
        id = youtube.srcID
        type = 'youtube'
      }
      if (videoUtils.isVimeo(link)) {
        const vimeo = videoUtils.getVimeoSrc(link)
        id = vimeo.srcID
        type = 'vimeo'
      }
      // if (item.title) {
      //   data = item.title.split('-', 2)
      //   type = data[1];
      //   id = item.title.replace(data.join('-') + '-', '')
      // }
      let src = type === 'youtube' ? `https://www.youtube.com/watch?v=${id}` : `https://vimeo.com/${id}`;
      if (type === 'prn') src = id
      return {
        mutability: "IMMUTABLE",
        type: "draft-js-video-plugin-video",
        data: {
          data: {
            src,
            isPryaniky: type === 'prn',
          }
        }
      }
    },
    // video_close: (item: any) => {
    // },

    /*link_close: (item: any) => {
    },*/

    link_open: (item: any) => {
      if (item.href.toLowerCase().includes('user') || item.href.toLowerCase().includes('group')) {
        return {
          type: 'mention',
          mutability: "SEGMENTED",
          data: {
            mention: {
              url: item.href,
              href: item.href,
              link: item.href
            }
          }
        };
      }
      if (item.href.toLowerCase().includes('select-mention')) {
        let title = {}
        try {
          title = JSON.parse(item.title);
        } catch (e) {
          console.warn('error in JSON.parse, wrong json data in image item.title:', item.title)
        }
        return {
          type: 'USER_SELECT',
          mutability: "SEGMENTED",
          data: {
            mention: {
              url: item.href,
              href: item.href,
              link: item.href,
              users: title
            }
          }
        };
      }
      return {
        type: 'LINK',
        mutability: 'MUTABLE',
        data: {
          url: item.href,
          href: item.href
        }
      };
    }
    /*ARIMAGE_open: (item: any) => {
      return {
        type: 'IMAGE',
        mutability: 'IMMUTABLE',
        data: {
          ...JSON.parse(item.obj || {})
        }
      };
 
    }*/
  }
}