import React, { FC, useEffect, memo, useState, useCallback } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    KPI
} from './interfaces'
import { connect, useDispatch, useSelector } from 'react-redux';
import './style.scss'
import Edit from '../Edit'
import View from '../View'
import { useChangeEdit } from 'KPIModule/utils/hooks'

type IPresenterProps = KPI.Item.IPresenterProps
type IOwnProps = KPI.Item.IOwnProps
type IDispatchProps = KPI.Item.IDispatchProps
type IStateProps = KPI.Item.IStateProps
type IState = KPI.Item.IState



export const Presenter: FC<IPresenterProps> = ({
    id,
    userId
    // meta
}) => {
    const [edit, setEdit] = useChangeEdit(id)

    return edit ?
        <Edit key={'edit'} id={id} userId={userId} /> :
        <View key={'edit'} id={id} userId={userId} setEdit={() => setEdit(true)} />
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
