import * as React from 'react';
// import * as utils from 'utils/src/utils';
// import { withRouter } from "react-router"
import WidgetPresenter from '../Widget';
// import { withBemMod } from '@bem-react/core';
import { IWidgetTypeKPIProps } from './Widget_type_kpi.index'
import { cnWidget } from '../Widget.index';
import Main from 'KPIModule/containers/Main'
import { widgets } from 'i.widgets';
import * as utils from 'utils/src/utils';

import { IWidgetProps } from '../Widget.index';
import { match } from 'react-router';


const type = 'kpi';

class WidgetTypeKPI extends WidgetPresenter<IWidgetTypeKPIProps> {

  public render() {
    if (!this.props.widget) return null;

    if (!this.props.wcontext.uId) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Tag = widgets.components.common
    return (
      <Tag {...this.props} className={cnWidget({ type, subtype })}>
        <Main userId={this.props.wcontext.uId} />
      </Tag>
    );
  }
}

export default WidgetTypeKPI