import Component, { Presenter } from './component';
import {
   LMS,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = LMS.CourseStatusesWidget.IOwnProps;
export type IComponentPresenterProps = LMS.CourseStatusesWidget.IPresenterProps;


export default Component