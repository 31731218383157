import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { createCourse } from '../../redux/actions/LMSTeacherOffice'
import { IBodyProps } from 'utils/src/DialogCreator';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface ICreateSessionProps extends IClassNameProps,IBodyProps, ICreateSessionStateProps, IDispatchProps, RouteComponentProps, ICreateSessionDispatchProps {
    tag?: 'div';
}

export interface ICreateSessionState {

}

export interface ICreateSessionStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    fetchStatus: state.LMSTO.requestsActivity.createCourse,
    user: getAuthUser(state),

})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    createCourse
}, dispatch);

export type ICreateSessionDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnCreateCourse = cn('TOCreateCourse');