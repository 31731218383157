import { NAdventCalendarReducer } from "./types";

export const ADV_CAL_SET_CALENDAR = 'ADV_CAL_SET_CALENDAR';
export const ADV_CAL_CHANGE_CALENDAR_DAY = 'ADV_CAL_CHANGE_CALENDAR_DAY';

export namespace NAdventCalendarActions {

    type BasePayload = {
        id: keyof NAdventCalendarReducer.Reducer;
    };

    type SetCalendar = {
        type: typeof ADV_CAL_SET_CALENDAR;
        payload: BasePayload & {
            items: NAdventCalendarReducer.Example;
        };
    };

    type ChangeCalendarDay = {
        type: typeof ADV_CAL_CHANGE_CALENDAR_DAY;
        payload: BasePayload & {
            item: Partial<NAdventCalendarReducer.Day> & Pick<NonNullable<NAdventCalendarReducer.Day>, 'date'>;
        };
    };

    export type Action<Type extends Actions['type']> = Extract<Actions, { type: Type }>;

    export type ActionFunction<Type extends Actions['type']> = (payload: Extract<Actions, { type: Type }>['payload']) => Action<Type>;

    export type Actions =
        SetCalendar |
        ChangeCalendarDay;

};