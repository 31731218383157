import { Box, Typography, styled, Paper } from 'muicomponents/src';
import { Breadcrumbs } from 'muicomponents/src/Breadcrumbs';


export const ArrowBlock = styled(Box)({
    display: 'flex',
    marginBottom: '-14px',
}) as typeof Box

export const SeparatedBox = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),
})) as typeof Box

export const GrayTypography = styled(Typography)({
    fontSize: '16px',
}) as typeof Typography

export const BlackTypography = styled(Typography)({
    fontSize: '16px',
    '& a': {
        fontWeight: '400 !important',
        fontSize: '16px !important',
    }
}) as typeof Typography

export const NameTypography = styled(Typography)(({theme}) => ({
    fontSize: '20px',
    fontWeight: '500 !important',
    marginBottom: theme.spacing(2),
})) as typeof Typography

export const PaperWrap = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
})) as typeof Paper