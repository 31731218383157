import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { 
    saveCourse, 
    updateCourse,
    allowPlayCourse
} from '../../redux/actions/LMSTeacherOffice'
import {
    getAllowTestPlay,
    getCourseById
} from '../../redux/sagas/LMSTO/selectors'

export interface ICourseInfoProps extends IClassNameProps, ICourseInfoStateProps, IDispatchProps, RouteComponentProps, ICourseInfoDispatchProps {
    tag?: 'div';
}

export interface ICourseInfoState {

}

export interface ICourseInfoStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    course: getCourseById(props.cid)(state),
    allowPlay: getAllowTestPlay(state)
    // units: state.LMSTO.moderation.units,
    // ids: state.LMSTO.moderation.results
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    saveCourse,
    updateCourse,
    allowPlayCourse
}, dispatch);

export type ICourseInfoDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnCourseInfo = cn('CourseInfo');