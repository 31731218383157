import React, { FC, useState } from 'react';
import './More.scss';
import { IMoreProps, cnMore } from './More.index'

export const More: FC<any> = ({ className, onClick = ()=>{} }) => {

    return (
        <div className={cnMore({}, [className])} onClick={onClick}>
            <div className={cnMore('Dots')}>...</div>
        </div>
    );
};
