import React, { ComponentProps, ComponentType, FC, forwardRef, useEffect } from "react";
import { Button } from 'muicomponents/src/Button/Button';
import { NNewsCreator } from "../Creator.index";
import { DynamicModuleLoader } from "redux-dynamic-modules";
import { getNewsCreateModule } from "News/creator/redux/module.newsCreator";
import { withComponentEnjector } from "utils/src/ComponentInjector";
import { Translate } from "localizations/Translate";
import { useInitNewsCreator, useNewsCreator } from "../Creator.hooks";
import { SendNewsDialog } from "../SendDialog/SendDialog";
import { toast } from "react-toastify";
import { IconButton } from "muicomponents/src";

const CreatorButtonPr = forwardRef<HTMLButtonElement, NNewsCreator.Props & ComponentProps<typeof Button> & {
    iconButton?: boolean;
}>(({
    creatorId: creatorIdProp,
    groupId,
    newsType,
    workflowId,
    creatorOptions,
    children,
    variant = 'contained',
    getComponent,
    getMethod,
    getNode,
    getObject,
    injectionComponents,
    injectionMethods,
    injectionNodes,
    injectionObject,
    iconButton,
    ...props
}, ref) => {

    const {
        creatorId
    } = useInitNewsCreator(creatorIdProp);

    const Component = (iconButton ? IconButton : Button) as ComponentType<ComponentProps<typeof Button>>;

    return (
        <Component
            {...props}
            ref={ref}
            variant={variant}
            onClick={() => {
                if(props.href) return;
                SendNewsDialog({ creatorId, newsType, groupId, workflowId, creatorOptions }).then(({ success }) => {
                    if (success)
                        toast.success(<Translate i18nKey={'pryaniky.news.post.success'} />)
                });
            }}
        >
            {children ? children : <Translate i18nKey={'create'} />}
        </Component>
    );
});

const CreatorButtonWithModule = forwardRef<HTMLButtonElement, ComponentProps<typeof CreatorButtonPr>>(({
    ...props
}, ref) => {
    return (
        <DynamicModuleLoader modules={[getNewsCreateModule()]}>
            <CreatorButtonPr {...props} ref={ref} />
        </DynamicModuleLoader>
    );
});

export const NewsCreatorButton = withComponentEnjector<ComponentProps<typeof CreatorButtonWithModule>>(CreatorButtonWithModule, ['news_creator_mui5']);