import React, { useEffect, useState, useCallback, FC, useMemo } from 'react';
import { withTranslation, Trans, Translation } from 'react-i18next';
import { IShopCardProps, mapDispatchToProps, mapStateToProps, cnShopProduct } from './ProductCard.index';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import i18n from 'localizations/i18n';
import { Translate } from 'localizations/Translate';
import './ProductCard.scss';
import { ShopPurse } from '../ShopPurse.svg';
import { openShopMakeOrder } from 'blocks/Dialogs/Shop/ShopMakeOrder/ShopMakeOrder';
import * as utilsProj from 'utils.project/utils.project';
import { Characteristics as CharacteristicsTypes } from 'utils/src/requests/models/api.shop'
import { Icon, ListPlaceholder } from 'uielements/src';
import { SkeletonProductCard } from 'uielements/src/Skeleton';
import shopPlaceholder from 'assets/svg/shopPlaceholder.svg';
import Rating from 'uielements/src/MaterialElements/RatingWithValue'
import CharacteristicsO from 'uielements/src/Characteristics'
import { orderIsDenied } from '../validation'
import { countMissingThanks, getPriceData, changeLinks } from '../utils'
import PhotoViewerO from '../PhotoViewer'
import CurrentCategory from '../CurrentCategory'
import { CommentsRender } from 'News/containers/CommentsRender'
import { withIndicator, ILoadingProps } from 'utils/src/CommonRedux/LoadIndicator'
import { SHOP_CHECK_CHARACTERISTICS, SHOP_GET_SHOP_PRODUCT } from 'redux/shop/actions'
import { IShopItem } from 'utils/src/requests/models/api.shop';
import { createModal, IBodyProps } from 'utils/src/DialogCreator'
import DialogBody from 'uielements/src/MaterialElements/DialogParts/DialogBody'
import MDRender from 'uielements/src/CommonmarkRender'
import { Link } from 'muicomponents/src/Link/Link'
import { LoadingButton } from 'muicomponents/src/LoadingButton/LoadingButton'
import { ILoadingButton } from 'muicomponents/src/LoadingButton/LoadingButton.index'
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

const SpinBtn = withIndicator<ILoadingButton & ILoadingProps>(({ isLoading, withError, ...props }) => <LoadingButton {...props} loading={isLoading} />, SHOP_CHECK_CHARACTERISTICS)()
const Characteristics = withIndicator(CharacteristicsO, SHOP_CHECK_CHARACTERISTICS)(300)
const PhotoViewer = withIndicator(PhotoViewerO, SHOP_CHECK_CHARACTERISTICS)()

const prepareRatingValue = (rating?: number) => {
  if (!rating) return 0
  if (rating === -1) return 0
  return rating
}

const isAnyCharacteristicChosen = (characteristics: CharacteristicsTypes.IItem[]) => {
  if (characteristics.length === 0) return true
  const selectedArr = characteristics.map((characteristic) => {
    return characteristic.tags.some(tag => tag.isSelected)
  })
  return selectedArr.some(item => item)
}


const DescriptionDialog: FC<{ disclaimer: string } & IBodyProps> = ({ handleAccept, handleClose, disclaimer }) => {
  return <DialogBody
    header={i18n.t('pryaniky.shop.product.dsc.dialog.header')}
    contentPadding={'normal'}
    acceptText={i18n.t('pryaniky.shop.product.dsc.dialog.accept')}
    closeText={i18n.t('pryaniky.shop.product.dsc.dialog.close')}
    // onAccept={() => handleAccept({})}
    onClose={handleClose}
  >
    <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
  </DialogBody>
}
const openDiscription = createModal(DescriptionDialog)

const ProductCardLayout: React.FC<any> = ({ children }) => {
  return <div className={cnShopProduct('', ['Widget'])}>

    <div className={cnShopProduct('Back')}>
      <Link to='/shop' >
        <Icon icon='angle-left' />
        {i18n.t('pryaniky.shop.product.backToShop')}
      </Link>
    </div>

    {children}

  </div>
}

const ProductCardComments: React.FC<any> = ({ data }) => {
  const allowFeedback = data.news && data.news.allowReply && data.allowComments
  return <>
    {(data.news && data.allowComments) &&
      <CommentsRender id={data.news.id} className={cnShopProduct('Comments', { allowFeedback })} />}

    {(!allowFeedback && data.allowComments) &&
      <div className={cnShopProduct('CommentsDisabled')}>{i18n.t('pryaniky.shop.product.comments.disabled')}</div>}
  </>
}

const ProductPreloader: React.FC<IShopCardProps> = (props) => {
  const { data, ...p } = props
  // Загрузка товара
  useEffect(() => {
    if (`${data?.id}` !== p.match.params.id) p.getShopProduct(p.match.params.id);
  }, [p.match.params.id])

  if (props.isLoading) {
    return <ProductCardLayout>
      <SkeletonProductCard />
    </ProductCardLayout>
  }
  if (!data) {
    return <ProductCardLayout>
      <SkeletonProductCard />
    </ProductCardLayout>
  }
  return <ProductCardPresenter {...p} data={data} />
}

const ProductCardPresenter: React.FC<IShopCardProps & { data: IShopItem }> = ({
  data,
  // match,
  // getShopProduct,
  setShopProduct,
  isHidePricesAndButtonBuy,
  additionalInformationBeforeOrder,
  maxOrdersCount,
  maxOrdersPeriod,
  ordersCount,
  userThanksCount,
  currencyFormats,
  contentRating,
  currentImgUrl,
  checkCharacteristics,
  // isLoading,
  allowOrder,
  errorMessage,
  currentImage,
  isHideButtonBuy
}) => {

  const { characteristics = [], thanksPrice, id, minVirtCurrencyPayment, availableCount, isUnlimitedProduct } = data
  const onChangeChar = useCallback((value: CharacteristicsTypes.IItem[]) => {
    checkCharacteristics(id, value)
  }, [id])

  // для расчета, хватает ли юзеру пряников, если цена товара завивит от характеристик: 
  // пока характеристики не выбраны юзером, сравниваем userThanksCount с минимальной ценой на товар, если выбраны характеристики, то уже с ценой на конкретный товар 
  const priceDependsOnChars = characteristics.some(char => char.usedForPrice) && minVirtCurrencyPayment > -1
  const areCharsTouched = isAnyCharacteristicChosen(characteristics)
  const priceForUserMoneyCount = (!priceDependsOnChars || areCharsTouched || !minVirtCurrencyPayment) ? thanksPrice : minVirtCurrencyPayment
  const missingThanks = countMissingThanks(priceForUserMoneyCount, userThanksCount)

  const { price, priceCurrency } = getPriceData(data?.thanksPriceTxt || '')

  const isAmountValid = isUnlimitedProduct || availableCount


  const isNotCanBuy = orderIsDenied({ characteristics, maxOrdersCount, maxOrdersPeriod, ordersCount, allowOrder, errorMessage })

  async function openDescription() {
    if (data.disclaimer) {
      try {
        const result = await openDiscription({ disclaimer: data.disclaimer })
      } catch (error) {

      }
    }
  }

  const desc = data.desc//changeLinks(data.desc);

  return <>
    <ProductCardLayout>
      {data.isActive ?
        <div className={cnShopProduct('Main')}>

          <PhotoViewer
            images={data.images}
            currentImage={currentImage}
            currentImgUrl={currentImgUrl}
            imgUrls={data.images.map(el => el.previewUrl)}
            onChange={(currentImgUrl, currentImage) => setShopProduct({ currentImgUrl, currentImage })}
            className={cnShopProduct('Photos')}
          />

          <div className={cnShopProduct("Info")}>
            <div className={cnShopProduct("Info-Header")}>
              <span>{data.name}</span>
              <MDRender className={cnShopProduct('MDRender')} disableEmojiOne disableShowMoreBtn textCut={false} source={desc} />
              {/* <span>{data.desc}</span> */}
              {data.disclaimer &&
                <div>
                  <Link
                    // theme={'unstyled'}
                    className={cnShopProduct("Info-DescMore")}
                    onClick={openDescription}>{i18n.t('pryaniky.shop.product.dsc.more')}<Icon icon={'question-circle'} /></Link>
                </div>
              }
            </div>

            {contentRating?.enable && <div className={cnShopProduct("Info-Rating")}>
              <Rating
                className={cnShopProduct("Info-Stars")}
                readOnly
                value={prepareRatingValue(contentRating?.rating)}
              />
              <div className={cnShopProduct("Info-VoteCount")}>
                {contentRating?.rating === -1 ? i18n.t('pryaniky.shop.product.not_votes') : i18n.t('pryaniky.shop.product.vote.count', { count: contentRating.count })}
              </div>
            </div>}

            {data.delivery && <div className={cnShopProduct("Info-Delivery")}>
              <span>{i18n.t('pryaniky.shop.product.delivery')}</span>&nbsp;{data.delivery}
            </div>}

            <div className={cnShopProduct("Info-CharacteristicsBox")}>
              <Characteristics
                // showSelectedTitle
                className={cnShopProduct("Info-Characteristics")}
                characteristics={data.characteristics}
                onChange={onChangeChar} />
            </div>

            {!isHidePricesAndButtonBuy && <div className={cnShopProduct("Info-Prise")}>
              <ShopPurse size={36} padding={6} className={cnShopProduct("Info-Prise-Img")} />
              <span>{price}</span> {priceCurrency}
            </div>}

            {!isHideButtonBuy && <>
              {isAmountValid ? <>
                {missingThanks > 0 ? (
                  <div className={cnShopProduct('Info-Missing')}>
                    <Translate i18nKey='missing' /> <b>{missingThanks}</b> {utilsProj.getFormattedCurrencyName(currencyFormats, missingThanks, 'genitive')}
                  </div>
                ) : (
                  <Tooltip
                    className={'InfoIcon'}
                    title={isNotCanBuy || ''} >
                    <span>
                      <SpinBtn variant={'contained'} className={cnShopProduct("Info-Order")}
                        disabled={Boolean(isNotCanBuy)}
                        onClick={() => {
                          openShopMakeOrder({
                            data,
                            useRedux: true,
                            additionalInformationBeforeOrder
                          })
                            .then()
                            .catch()
                        }}>
                        {i18n.t('pryaniky.shop.product.order')}
                      </SpinBtn>
                    </span>
                  </Tooltip>
                )}
              </>
                :
                <div className={cnShopProduct('NotAvailable')}>
                  {i18n.t("pryaniky.shop.product.isOver")}
                </div>
              }
            </>
            }

          </div>
        </div> :

        <ListPlaceholder
          type='shopClosed'
          imageSrc={shopPlaceholder}
          title={i18n.t('pryaniky.shop.product.notActive.title')}
          text='' />}

      <ProductCardComments data={data} />

    </ProductCardLayout>

    <CurrentCategory
      id={`${id}`}
      categories={data.categories}
      isHidePricesAndButtonBuy={isHidePricesAndButtonBuy}
      isHideButtonBuy={isHideButtonBuy || false}
      maxOrdersCount={maxOrdersCount}
      maxOrdersPeriod={maxOrdersPeriod}
      additionalInformationBeforeOrder={additionalInformationBeforeOrder}
      ordersCount={ordersCount}
      className={cnShopProduct('CategoryProducts')}
    />


    {/* <ShopMakeOrder
      isShown={showDialog}
      onClose={() => setShowDialog(false)}
      data={data}
    /> */}
  </>

  // return (
  //   <>
  //     <div className={cnShopProduct('', ['Widget'])}>

  //       <div className={cnShopProduct('Back')}>
  //         <Button noBackground noBorder noPadding type='rlink' href='/shop' >
  //           <Icon icon='angle-left' />
  //           {i18n.t('pryaniky.shop.product.backToShop')}
  //         </Button>
  //       </div>

  //     </div>
  //   </>
  // )
}

export const ProductCard = withIndicator(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(withRouter(ProductPreloader))), SHOP_GET_SHOP_PRODUCT)();