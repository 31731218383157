import React, { FC, useCallback, useMemo } from 'react';
import { Button } from 'muicomponents/src/Button/Button';
import { TabsControlMuiProps } from './TabsControl.index'
import { StyledTabs, TabsControlActions } from './TabsControl.styled';
import { changeIsFirstLogonOnServer } from 'utils/src/CommonRedux/users/actions';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

const requiredKeys = ['license']

export const TabsControl: FC<TabsControlMuiProps> = ({
    updateContext,
    tabs,
    context,
    edit,
    ...props
}) => {

    const { pathname } = useLocation();
    const history = useHistory();

    const dispatch = useDispatch();

    const activeIdx = useMemo(() => {
        const activeIndex = tabs.findIndex(el => el.name === context.tab || el.id === context.tab);
        return activeIndex === -1 ? 0 : activeIndex;
    }, [tabs, context]);
    
    const lastItemActive = useMemo(() => {
        return activeIdx === tabs.length - 1;
    }, [tabs, activeIdx]);

    const activeNextButton = useMemo(() => {
        return requiredKeys.reduce((rez: boolean, rKey: string) => {
            if(!rez) return rez;
            return Object.keys(context).reduce((rezKey: boolean, key: string) => {
              if (!rezKey) return rezKey;
              return key.indexOf(rKey) === 0 ? context[key] : true
            }, true);
          }, true);
    }, [context]);

    const showPreviousButton = useMemo(() => {
        return activeIdx !== 0;
    }, [activeIdx]);

    const nextButtonText = useMemo(() => {
        if(lastItemActive) {
            return 'Finish'
        }
        return 'next'
    }, [lastItemActive]);

    const changeTab = useCallback((to: 'prev' | 'next') => () => {
        if(to === 'next' && lastItemActive && pathname.includes('/welcome')) {
            dispatch(changeIsFirstLogonOnServer(() => history.push('/dash')));
            return ;
        }
        // if not tabs length action next and not edit and disabled next button
        if(!tabs.length || to === 'next' && !edit && !activeNextButton) return ;
        let newActive = activeIdx;
        switch (to) {
            case 'next':
                newActive += 1;
                break;
            case 'prev':
                newActive -= 1;
                break;
        }
        if(newActive < 0) newActive = 0;
        if(newActive >= tabs.length) newActive = tabs.length - 1;
        updateContext?.({
            tab: tabs[newActive]?.id || undefined
        });
    }, [tabs, activeIdx, lastItemActive, edit, activeNextButton]);

    return (
        <>
            <StyledTabs
                {...props}
                tabs={tabs}
                context={context}
                disableNavigate
            />
            <TabsControlActions
                sx={{
                    justifyContent: !showPreviousButton ? 'end' : undefined
                }}
            >
                {
                    showPreviousButton &&
                    <Button
                        variant={'contained'}
                        onClick={changeTab('prev')}
                    >
                        prev
                    </Button>
                }
                <Button
                    variant={'contained'}
                    disabled={!edit && !activeNextButton}
                    onClick={changeTab('next')}
                >
                    {nextButtonText}
                </Button>
            </TabsControlActions>
        </>
    )
};