import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from '../../../redux/connector';
// import i18n from '../../../localizations/i18n';
import { AbstractInfoItem } from '../AbstarctInfoElement/AbstractInfoElement';
import { withElementTypeState } from '../AbstarctInfoElement/_type/InfoBlock-Element_type_state';
import { withElementTypeStore } from '../AbstarctInfoElement/_type/InfoBlock-Element_type_store';
import { withGroupEditFalse } from './_edit/InfoBlock-Group_edit_false';
import { withGroupEditTrue } from './_edit/InfoBlock-Group_edit_true';
import { mapStateToProps, IGroupStateProps, IGroupProps, IGroupState, mapActionsToProps } from './InfoBlock-Group.index';
// import { gotGroupInfo } from 'redux/actions/Group';
import './InfoBlock-Group.scss';

export class GroupPresenter extends AbstractInfoItem<IGroupProps, IGroupState> {
  public componentWillReceiveProps(np: IGroupProps) {
    if (!utils.compareData(this.props.groupId, np.groupId)) {
      this.actionGetData();
    }
  }

  protected actionGetData = () => {
    this.props.loadGroup(this.props.groupId)
    // utils.API.groups.settings(this.props.groupId)
    // utils.API.groups.byId(this.props.groupId).r.then((d: any) => {
    //   // if (utils.checkResponseStatus(d)) {
    //   this.props.setData(d.data);
    //   this.props.getGroupTitle(d.data.name)      
    //   // this.props.gotGroupInfo(d.data);
    //   this.props.updateContext('common', {
    //     isGroupAdmin: d.data.isGroupAdmin,
    //   })
    //   // } else {
    //   // toast.error('something wrong')
    //   // }
    // });
  };
}

export const Group = connect(
  mapStateToProps,
  mapDispatchToProps({ ...mapActionsToProps })
)(
  compose(
    // withBemMod(cnGroup(), {}),
    withElementTypeState,
    withElementTypeStore,
    withGroupEditFalse,
    withGroupEditTrue
  )(GroupPresenter)
);
