import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
// import i18n from '../localizations/i18n';
import { userTabs } from './__tabs';

interface IUser {
  info: string;
  tabs: string;
  timeline: string;
}

export const user = (props: { [s: string]: any }): IWidget<IColumn[]> => {
  const ids = guids<IUser>(['info', 'tabs', 'timeline']);
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            id: ids.info,
            type: widgets.types.info,
          }),
          generateWidget({
            id: ids.tabs,
            type: widgets.types.tabs,
            data: [
              userTabs.about(),
              userTabs.feed(),
              userTabs.subscriptions(),
              userTabs.reviews(),
              userTabs.rewards(),
              userTabs.currencyTimeline()
            ],
          }),
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  });
}