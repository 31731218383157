import { NLeftMenuReducer } from "./interfaces";
import { baseLeftMenuReducer } from './constants';

type BaseReducer = { leftMenu: NLeftMenuReducer.Reducer };

export const getLeftMenuReducer = <S extends BaseReducer = BaseReducer> (state: S) => state.leftMenu || baseLeftMenuReducer;

type ReducerSelectorParameters = Parameters<typeof getLeftMenuReducer>;

export const getLeftMenuReducerField = <Key extends keyof NLeftMenuReducer.Reducer> (key: Key) => (...args: ReducerSelectorParameters) => getLeftMenuReducer(...args)[key];

export const getBarItems = <Key extends keyof NLeftMenuReducer.Reducer['barsItems']> (key: Key) => (...args: ReducerSelectorParameters) => getLeftMenuReducerField('barsItems')(...args)[key];