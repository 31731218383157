export const changeUsersModifier = (users: any, model: any) => {
    const localData = { ...model }
    const usersArray = users.filter((val: any) => val.type === 'user')
    const groupsArray = users.filter((val: any) => val.type === 'group')
    // .map((val: any) => ({ ...val, id: val.pkid, guid: val.id}))
    // const newUser = !users ? [] : users.map((v: any) => v.data ? v.data : v);
    localData.thanksCount = (localData.thanksCount === 0 ? 1 : localData.thanksCount);//
    localData.users = usersArray;
    localData.groups = groupsArray;

    return localData;
}

export const changeReasonModifier = (values: any, model: any) => {
    const localData = { ...model }
    if (!values) localData.rewardReasons = [];
    else localData.rewardReasons = values.map((v: any) => {
        return {
            id: v.value,
            name: v.label,
            selected: true,
        }
    })
    return localData;
}