import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
// import { setNews} from '../../redux/actions/News'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { RouteComponentProps } from 'react-router';
import { getCurrentUserIsAdmin } from 'utils/src/CommonRedux/base/selectors';
export interface ITimelineDefaultProps {
  // baseRequestOptions: ITimelineRequestOptions;
  requestOptions: ITimelineRequestOptions;
}

export interface ITimelineRequestOptions {
  newsTypes: string;
  skipCount: number;
  count: number;
  userId: string;
  groupId: number;
  tagId: string;
  search: string;
  [s: string]: any;
}

export interface ITimelineProps extends IClassNameProps, ITimelineStateProps, ITimelineOwnProps, ITimelineDefaultProps, RouteComponentProps {
  widgetId?: string;
  relations?: string[]
  widget?: any
}

export interface ITimelineState {
  text?: string;
  news: any[];
  request: boolean;
  fullList: boolean;
}

export interface ITimelineStateProps extends IDispatchNews {
  // store: any
  // context: ITimelineContext;
  authUser: {[s: string]: any};
  // news: any;
  // newsIds: any[];
  // requestOptions: any;
  // relations: any;
  //comments: any;
}

interface ITimelineContext {
  gId?: number;
  uId?: string;
  filter?: ISelectizeItem;
  tId?: string;
  newsTypes?: string;
  oneNewsType?: string;
  timelineType?: string;
  isGroupAdmin?: string;
  disallowUserPublications?: boolean;
  [s: string]: any;
}

interface ITimelineOwnProps {
  tag?: React.ComponentType | 'div';
  hideNews?: boolean;
  context: ITimelineContext;
  // relations: any;
}
export interface IDispatchNews {
  setNews?: any;
}

export const mapDispatchToProps = {
  // setNews,
};

const groupSelector = (arr: any[], opts: any, data: any) => {
  if (opts.groupId === -1) return arr;
  return arr.filter((val) => {
    return data[val].group && (data[val].group.pkid === parseInt(opts.groupId))
  })
}

const timeSelector = (arr: any[], data: any) => {
  return arr.sort((a: any, b: any) => {
    return data[b].ts - data[a].ts;
  })
}


export const mapStateToProps = (state: any, ownProps: ITimelineOwnProps) => ({
  // store: state.store
  //changeVm: stat
  // context: prepareContext<ITimelineContext>(state.widgets.contexts, ownProps.relations),
  authUser: getAuthUser(state),
  isAdmin: getCurrentUserIsAdmin(state)
  // news: state.store.data.news,
  // news: state.news.news,
  // newsIds: state.news.newsIds,//timeSelector(groupSelector(state.news.newsIds, state.news.requestOptions, state.news.news), state.news.news),
  // requestOptions: state.news.requestOptions
  // comments: state.news.comments,
})


export const cnTimeline = cn('Timeline');