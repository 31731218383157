import React from 'react';
import './Widget_type_birthdays.scss';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { cnWidget, IWidgetProps } from '../Widget.index';
import { widgets } from '../../../i.widgets';
import { MarkdownWidget } from '../../MarkdownWidget/MarkdownWidget';

const type = 'markdown';

export default class WidgetTypeMarkdownPresenter extends WidgetPresenter<IWidgetProps> {
  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    return (
      <MarkdownWidget
        {...this.props}
        className={cnWidget({ type, subtype })}
        contexts={this.relations.concat(this.props.widget.id)}
        tag={widgets.components.common}
      />
    );
  }
}
