import React, { useState, FC } from 'react'
import { CalendarEventLayout } from 'muicomponents/src/CalendarEventLayout/CalendarEventLayout'
import CakeIcon from '@mui/icons-material/Cake';
import { styled } from '@mui/material/styles';

const Icon: any = styled(CakeIcon)({
    color: '#C344BE'
})
export const BirthdayEvent: FC<any> = ({ event }) => {


    return <CalendarEventLayout
        headerText={event.title}
        users={[event.user]}
        Icon={Icon}
        // details={[
        //     {
        //         keyData: 'start',
        //         value: new Date(event.start).toLocaleString('ru', {
        //             hour: 'numeric',
        //             minute: 'numeric',
        //         })

        //     }
        // ]}
    />
}