import i18n from 'localizations/i18n';
import { TDialogProps } from 'utils/src/DialogCreator'
import { dialog } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem'

const createThanksUsersDialog = () => {
  return ({
    participants,
    newstype
  }: {
    participants: any[],
    newstype: number | string
  }, containerProps?: TDialogProps) =>
    dialog({
      method: async function () {
        return participants.map((item: any) => ({ ...item, url: `/user/${item.id}` }))
      },
      ItemComponent: UsersListItem,
      listHeaderProps: {
        disableSearch: true
      },
      countPerPage: participants.length + 1,
      listBodyProps: {
        header: newstype === 4 ? i18n.t('pryaniky.modal.thanks.user') : i18n.t('pryaniky.modal.badge.user'),
      }
    }, containerProps)
}

export default createThanksUsersDialog()