import { RewardReplyes, CreativeTaskNews } from '../CreativetasksType'
import { NewsContextProps } from 'News/contexts/news'
import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    changeData,
    distributeCompetition,
    cancleCompetition,
    rewardAll,
    cancleAllReward
} from '../redux/actions'
import {
    getDistributeFormById,
    getRewardedCommentsForNews,
} from '../redux/selectors'
import {
    isLoadingComments,
    getNewsParamsById
} from 'News/redux/saga/selectors'


export type IDistributeFormPresenterProps = IDistributeFormOwnProps & IStateProps & IDispatchProps

export interface IState { }

export interface IDistributeFormOwnProps extends IClassNameProps, NewsContextProps {

}

export type IStateProps = ReturnType<typeof mapStateToProps>
// export interface IStateProps {
//     data: any
//     rewardedComments: RewardReplyes
//     isLoadingReply: boolean
// }

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

const newsParams = [
    'creativeTask',
] as const

export const mapStateToProps = (state: any, { newsId}: IDistributeFormOwnProps) => ({
    data: getDistributeFormById(newsId)(state),
    rewardedComments: getRewardedCommentsForNews(newsId)(state),
    isLoadingReply: isLoadingComments(newsId)(state),
    ...getNewsParamsById<CreativeTaskNews, typeof newsParams[number]>(newsId, newsParams)(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IDistributeFormOwnProps) => bindActionCreators({
    changeData,
    distributeCompetition,
    cancleCompetition,
    rewardAll,
    cancleAllReward
}, dispatch);

////////////

export const cnClassName = cn('DistributeForm');