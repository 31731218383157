/**
 * @packageDocumentation
 * @module Widget_Settings_quests
 */
 import Image from 'blocks/BirthdaysWidget/BirthdayIcon.svg'
 import { GUID_EMPTY } from "utils/src/constants.prn";
 
 export const emptyItem: any = {
  imgId: GUID_EMPTY,
  imgUrl: null,
  href: '',
  imgName: '',
  deleted: false,
  openInNewTab: false
}

export default {
  text:"",
  showImg: true,
  hideIfNoData: false,
  usersCount: 3,
  // img: Image,
  banner:  {
    aspect: 0,
    elements: [
      emptyItem
    ],
  }
}