import React, { useState } from 'react';
import { createModal, LeftDirectionTransitionComponent  } from 'utils/src/DialogCreator';
import { cnWorkflowsChangeResponsibleStatus, IWorkflowsChangeResponsibleStatusProps, mapStateToProps } from './ChangeResponsibleStatus.index';
import {
  DialogTitle,
  DialogActions,
  DialogContent,
} from 'uielements/src';
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import Suggest from 'uielements/src/MaterialElements/Autocomplete';
import FormControlO from '@material-ui/core/FormControl'
import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp';
import { Translate } from 'localizations/Translate';
import './ChangeResponsibleStatus.scss';
import { connect } from 'react-redux';

import { WorkflowsValidator, workflowsValidate } from 'blocks/NewsTypes/Workflows/Workflows.validate';


const FormControlHelped = fieldWithHelp(FormControlO)

const bv = new WorkflowsValidator();
bv.setRules(workflowsValidate);

const WorkflowsChangeResponsibleStatusPresenter: React.FC<IWorkflowsChangeResponsibleStatusProps> = ({ handleClose, handleAccept, data, currentStatus }) => {
  
  const [responsibleUsers, setResponsibleUsers] = useState(data || []);

  return <div className={cnWorkflowsChangeResponsibleStatus({}, ['CustomStyle'])}>
    <DialogTitle onClose={handleClose}>
      <h4 className="my-1">
        <Translate i18nKey={`pryaniky.post.workflows.changeResponsible`} />
      </h4>
    </DialogTitle>
    
    <DialogContent className={cnWorkflowsChangeResponsibleStatus('Body')}>
      {/* <FormControlHelped fullWidth help={currentStatus?.description}> */}
        <Suggest
          type='users'
          variant='outlined'
          label={Translate.t({ i18nKey: 'pryaniky.post.workflows.selectResponsible' })}
          value={responsibleUsers}
          onChange={setResponsibleUsers}
        />
      {/* </FormControlHelped> */}
      
    </DialogContent>

    <DialogActions className={cnWorkflowsChangeResponsibleStatus('Actions')}>
      <Button variant='contained' color='primary' onClick={() => handleAccept(responsibleUsers)}>
        <Translate i18nKey='save' />
      </Button>
      <Button color='primary' onClick={handleClose}>
        <Translate i18nKey='cancel' />
      </Button>
    </DialogActions>
  </div>
}

export const changeResponsibleStatusModal = createModal(connect(mapStateToProps, undefined)(WorkflowsChangeResponsibleStatusPresenter), {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  TransitionComponent: LeftDirectionTransitionComponent,
  className: 'Pane BackdropOff Right',
  scroll: 'body'
});