import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    IDialogBodyPresenterProps
} from './interfaces'
import './style.scss'
import DialogActions from '../DialogActions'
import DialogTitle from '../DialogTitle'
import DialogContent from '../DialogContent' 

export const Presenter: FC<IDialogBodyPresenterProps> = ({ 
    children, 
    className, 
    header,
    onAccept,
    onClose,
    disableAccept,
    ...other 
}) => {
    return <>
        <DialogTitle onClose={onClose} id="responsive-dialog-title">{header}</DialogTitle>
        <DialogContent>
                {children}
        </DialogContent>
        <DialogActions disableAccept={disableAccept} onAccept={onAccept} onClose={onClose}/>
    </>
}

export default memo(Presenter);
