import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IDispatchProps } from 'redux/connector';

export interface IDashProps extends IClassNameProps, IActionsDispatch, IDispatchProps {
  tag?: 'div';
}

export interface IDashState {
  text?: string;
}
export interface IActionsDispatch {
  updateRequestOptions?: any;
  updateContext?: any;
}

export const cnDash = cn('Dash');