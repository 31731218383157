import { cn } from '@bem-react/classname';
import { RouteComponentProps } from 'react-router';
import { IClassNameProps } from '@bem-react/core'

export interface IPwdResetProps extends RouteComponentProps<{ password: string; uId: string; }>, IClassNameProps {}

export interface IPwdResetState {
  request: boolean;
  password: string;
  newPassword: string;
  confirmNewPassword: string;
  error: string;
}

export const cnPwdReset = cn('PwdReset');