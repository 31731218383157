import React, { FC, memo, useState } from 'react'
import { cnClassName, Shop } from './interfaces'
import { Spinner } from 'uielements/src'
import BaseRequests from 'utils/src/requests/requests.base'
import { SideBySideMagnifier } from "react-image-magnifiers";
import { Box, Paper } from 'muicomponents/src'
import { Avatar } from 'muicomponents/src/Avatar'
import { IconButton } from 'muicomponents/src/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import placeholderImg from '../placeholder.svg'
type IPresenterProps = Shop.PhotoViewer.IPresenterProps

const IconBtnStyle = {
    position: 'absolute',
    cursor: 'pointer',
    width: '50px',
    height: '50px',

}
const MainImgStyle = {
    position: 'relative',
    zIndex: '60',
    backgroundSize: 'contain',
    backgroundRepeat: 'noRepeat',
    backgroundPosition: 'center center',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
        borderRadius: '4px',
    }
}

export const Presenter: FC<IPresenterProps> = ({
    className,
    currentImgUrl,
    currentImage,
    imgUrls,
    onChange,
    isLoading,
    images
}) => {
    //const currentIndex = imgUrls.indexOf(currentImgUrl)
    // const [currentIndex, setCurrentIndex] = useState(imgUrls.indexOf(currentImgUrl)) 
    const currentIndex = imgUrls.indexOf(currentImgUrl)
    const isExternal = currentIndex === -1
    const isFirst = currentIndex === 0
    const isLast = currentIndex === imgUrls.length - 1
    const currentFile = currentImage || images[currentIndex] || images[0]

    let imageSrc = currentFile?.previewUrl || imgUrls[0]
    let largeImageSrc = currentFile?.originalUrl || imgUrls[0]
    imageSrc = currentFile.id === "00000000-0000-0000-0000-000000000000" ? placeholderImg : imageSrc
    largeImageSrc = currentFile.id === "00000000-0000-0000-0000-000000000000" ? placeholderImg : largeImageSrc

    const onNext = () => {
        onChange(imgUrls[currentIndex + 1], images[currentIndex + 1])
    }

    const onPrev = () => {
        onChange(imgUrls[currentIndex - 1], images[currentIndex - 1])
    }

    const onHover = (currentImgUrl: string, currentImage: any) => () => {
        onChange(currentImgUrl, currentImage)
    }

    return <Box
        className={cnClassName({}, [className])}
        sx={{
            width: '40%',
            ['& .PhotoViewerMui-ImageZoom']: {
                flex: 1
            },
            ['& .PhotoViewerMui-ImageZoom > div > img + div']: {
                backgroundColor: "#fff"
            }
        }}
    >

        <Paper sx={MainImgStyle} className={cnClassName('Slider')}>

            <SideBySideMagnifier
                className={cnClassName('ImageZoom')}
                imageSrc={imageSrc}
                overlayBoxColor={'#fff'}
                largeImageSrc={largeImageSrc + `?auth=` + BaseRequests.getAuth}
                overlayOpacity={0.5}
                inPlaceMinBreakpoint={641}
                fillAvailableSpace={true}
                fillGapTop={55}
                zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
            />

            {!isExternal && <>
                {!isFirst &&
                    <IconButton
                        sx={{ ...IconBtnStyle, left: '5px' }}
                        onClick={onPrev}
                        className={cnClassName('Slider-Back')}
                    >
                        <ChevronLeftIcon />
                    </IconButton>}
                {!isLast &&
                    <IconButton
                        sx={{ ...IconBtnStyle, right: '5px' }}
                        onClick={onNext}
                        className={cnClassName('Slider-Forward')}>
                        <ChevronRightIcon />
                    </IconButton>}
            </>}
            {isLoading && <Spinner fontSize={64} />}

        </Paper>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 3 }} >
            {imgUrls.length > 1 && imgUrls.map((img, i) => <>
                <Avatar key={img} src={img}
                    variant='rounded'
                    onMouseUp={onHover(img, images[i])}
                    component={Paper}
                    sx={{
                        cursor: 'pointer', opacity: currentIndex !== i ? .7 : 1,
                        width: '96px', height: '96px',
                        mr: 3, mt: 3,
                    }}
                />
            </>
            )}
        </Box>
    </Box >
}

export default memo(Presenter);
