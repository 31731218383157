import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { SearchInput } from 'uielements/src';
import { Button, Avatar } from 'uielements/src';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import { formatDateSTD } from 'utils/src/utils.dates'
import { tSt } from 'LMSModule/utils/i18n.adaptor'

type IPresenterProps = StatisticsFull.UsersListItem.IPresenterProps
type IOwnProps = StatisticsFull.UsersListItem.IOwnProps
type IDispatchProps = StatisticsFull.UsersListItem.IDispatchProps
type IStateProps = StatisticsFull.UsersListItem.IStateProps
type IState = StatisticsFull.UsersListItem.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    courseId,
    sessionId,
    status,
    courseName,
    authorUser,
    sessionName,
    beginDate,
    endDate,
    courseTime,
    progress
}) => {
    useEffect(() => {

    }, [])
    return <tr className={cnClassName({}, [className])}>
        <td className={cnClassName('Col1')}><Button href={`/TeacherOffice/Course/${courseId}`} type={'rlink'} theme={'unstyled'}>{courseName}</Button></td>
        <td className={cnClassName('Col2')}>
            <div className={cnClassName('UserItem')}>
                {/* <Avatar className={cnClassName('UserAvatar')} size={56} name={authorUser.displayName} imgId={authorUser.imgId} imgUrl={authorUser.imgUrl} /> */}
                {/* <div className={cnClassName('UserData')}> */}
                <UserMention avatar={{
                    ...authorUser,
                    className: cnClassName('UserAvatar'),
                    size: 56
                }} className={cnClassName('UserMention')} name={authorUser.displayName} id={authorUser.id} />
                {/* <div className={cnClassName('UserType')}>Преподаватель</div> */}
                {/* </div> */}
            </div>
        </td>
        {/* <td className={cnClassName('Col3')}>{ }</td> */}
        <td className={cnClassName('Col4')}>{formatDateSTD(beginDate)}</td>
        <td className={cnClassName('Col5')}>{formatDateSTD(endDate)}</td>
        <td className={cnClassName('Col6')}>{courseTime}</td>
        <td className={cnClassName('Col7')}>{tSt('item.user.col.' + status)}</td>
        <td className={cnClassName('Col8')}>{progress}</td>
    </tr>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));
