import { bindActionCreators } from 'redux';
import { deletePhoto, selectPhoto } from "../../../redux/actions/Photoalbums";

export interface IPhotoProps extends IBasePhotoProps, IAlbumStateProps, ReturnType<typeof mapDispatchToProps>  {

}

export interface IBasePhotoProps {
    fid: string;
    aid: string;
    gid: string;
    onClick: () => void;
    showControls?: boolean;
}

export interface IAlbumStateProps {
    file: any;
    selected: boolean;
}

export const mapStateToProps = (state: any, props: IBasePhotoProps) => {
    return { 
        file: state.photoalbums.photos[props.aid].files[props.fid],
        selected: state.photoalbums.photos[props.aid].selected.find((fid: string) => fid === props.fid)
     }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    deletePhoto,
    selectPhoto
}, dispatch);