import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { sendApproveModeration } from '../../../../redux/actions/LMSTeacherOffice'

export interface IApproveProps extends IClassNameProps, IApproveStateProps, IDispatchProps, RouteComponentProps, IApproveDispatchProps {
    tag?: 'div';
}

export interface IApproveState {

}

export interface IApproveStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    status: state.LMSTO.moderation.answers[props.aid].moderationStatus,
    // courses: state.LMSTO.courses.values,
    // results: state.LMSTO.courses.results
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    sendApproveModeration
}, dispatch);

export type IApproveDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnApprove = cn('ApproveAction');