import React from 'react';
import i18n from '../../../localizations/i18n';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';

export const textInfo = (props: any) => {
  if (!props.visible) return null;
  // const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  return <div className='errorText'>
    {props.uischema.text}
  </div>
}
// end TextInfoControl