import React from 'react';
import { cn } from '@bem-react/classname';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import './PageHeader.scss';

const cnPageHeader = cn('PageHeader');

interface IPageHeaderProps {
  imgUrl: string;
  className?: string;
}
export const PageHeader: React.FC<IPageHeaderProps> = ({ imgUrl, children, className }) => {
  return (
    <section className={cnPageHeader({}, [className])}>
      <Avatar imgUrl={imgUrl} size={64} className={cnPageHeader('Avatar')} />
      <div className={cnPageHeader('Info')}>
        {children}
      </div>
    </section>
  );
};
