import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { SearchInput } from 'uielements/src';
import { mapStateToProps, ISearchStateProps, cnSearch, ISearchProps, mapDispatchToProps } from './Search.index';
import './Search.scss';
import { withRouter } from 'react-router-dom'
import queryString from 'query-string';

export const SearchPresenter: React.FC<ISearchProps & any> = ({
    tag: Tag = 'div', value, className, history, isLoading }) => {
    const onChange = (value: string) => {
        history.replace({
            ...history.location,
            search: queryString.stringify({ ...queryString.parse(history.location.search), search: value !== '' ? value : undefined })
        })
    }
    return (
        <SearchInput loading={isLoading} value={value} className={cnSearch({}, [className])} onChange={onChange} />
    );
};

export const Search = withRouter(SearchPresenter)/*connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SearchPresenter));*/
