import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box } from 'muicomponents/src';
import { WrapBox } from '../styled';


export const ProfileEditSkeleton = () => {

    const Item = () => {
        return <>
            <Box display='flex' alignItems='center' mb={4} columnGap={1} >
                <Skeleton variant="text" height={56} width={'33%'} sx={{ borderRadius: 1 }} />
                <Skeleton variant="text" height={56} width={'33%'} sx={{ borderRadius: 1 }} />
                <Skeleton variant="text" height={56} width={'33%'} sx={{ borderRadius: 1 }} />
            </Box>
        </>
    }

    const Line = () => {
        return <Skeleton variant="text" height={56} sx={{ borderRadius: 1, mb: 1 }} />
    }

    return <WrapBox sx={{ background: 'transparent', boxShadow: 'none' }} >
        <Box display='flex' alignItems='center' mb={4} >
            <Box width={'33%'}>
                <Skeleton variant="rectangular" height={72} width={72} sx={{ mr: 2, borderRadius: 1 }} />
            </Box>
            <Box width={'66%'}>
                <Skeleton variant="text" height={56} width={'33%'} sx={{ borderRadius: 1 }} />
                <Skeleton variant="text" height={56} width={'33%'} sx={{ borderRadius: 1 }} />
            </Box>
        </Box>
        <Item />
        <Item />
        <Skeleton variant="text" height={20} sx={{ borderRadius: 1, mt: 1, mb: 2 }} />
        <Line />
        <Skeleton variant="text" height={20} sx={{ borderRadius: 1, mt: 1, mb: 2 }} />
        <Line />
        <Line />
        <Line />
        <Line />
        <Line />
        <Skeleton variant="text" height={20} sx={{ borderRadius: 1, mt: 1, mb: 2 }} />
        <Line />
        <Line />
        <Line />

    </WrapBox>
};