import * as React from 'react';
import { defaultThanksInfo } from './reply.types'
import { IEditorTypeReplyProps } from './reply.index';
import { Editor } from '../../Editor'

export const EditorTypeReply: React.FC<IEditorTypeReplyProps> = ({ data, onChange, users, onChangeUsers }) => {
    let localData = data;
    const changeUsers = (values: any) => {
        localData.users = !values ? [] : values.map((el: any) => el.value || el.id);
        onChange(localData);
        onChangeUsers(values);
    }
    const onChangeReason = (values: any) => {
        localData.thanksReasonIds = values ? values.map((v: any) => v.value) : [];
        onChange(localData);
    }

    const onChangeThanks = (val: number) => {
        localData.thanksCount = val
        onChange(localData)
    }

    return <Editor
        thanksCount={localData.thanksCount}
        users={users}
        onChangeReason={onChangeReason}
        onChangeThanks={onChangeThanks}
        onChangeUsers={changeUsers} />

}