import { cn } from '@bem-react/classname';
import { IBodyProps } from 'utils/src/DialogCreator'
export interface IThanksUsersProps extends IThanksUsersOwnProps { }
export interface IThanksUsersOwnProps {
    participants: any[],
    newstype: number | string
}
export interface IThanksUsersData {
}
export interface IThanksUsersDialogData extends IBodyProps<IThanksUsersData>, IThanksUsersOwnProps {

}

export const cnThanksUsers = cn('ThanksUsersDialog');