import React, { FC, useEffect, useState } from 'react'
import { cnItem, mapDispatchToProps, mapStateToProps } from './Item.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Item.scss'
import { Button as SB, Icon, SplitButton } from 'uielements/src';
import { periodTextCreator } from '../../../components/Deadlines/Deadlines'
import { DialogCreateSession } from '../../../dialogs/CreateSession/CreateSession'
import { OptionContent } from 'uielements/src/Dropdown/Dropdown'
import withConfirm from '../../HOCs/buttonWithConfirm'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

const Button = withConfirm(SB)

export const ItemPresenter: FC<any> = ({ className, session, sendSessionStart, sendSessionStop, actionSesstionArchive, disableActions }) => {
    const [openDialog, setOpenDialog] = useState(false)
    const [higthligth, setHigthligth] = useState<boolean>(session.isNew)

    useEffect(() => {
        setTimeout(() => {
            setHigthligth(false)
        }, 10000);
    })
    return <div className={cnItem({ higthligth }, [className])}>
        <div className={cnItem('Data')}>
            <div>
                {
                    disableActions ?
                        <span className={cnItem('Title')}>{session.name}</span> :
                        <Button theme={'unstyled'} className={cnItem('Title')} onClick={() => DialogCreateSession({cid:session.courseId, sid: session?.id}).then(() => { }).catch(()=>{})}>{session.name}</Button>
                }
                {session.isPreview && <Icon icon={'flask'} />}
            </div>
            <div>{session.description}</div>
        </div>
        <div className={cnItem('Duration')}><Icon icon={'calendar-alt'} />{periodTextCreator(session)}</div>
        <div className={cnItem('Users')}>
            <div><Icon icon={'user'} />{session.totalUserCount ? <span>{tT('sessions_list.item.users.1', { count: session.totalUserCount })}</span> : <span>{tT('sessions_list.item.load.1')}</span>}</div>
            <div><Icon icon={'user'} />{tT('sessions_list.item.groups.1', { count: session.memberGroups.length })}</div>
        </div>
        <div className={cnItem('Action')}>
            {!disableActions &&
                <Button
                    main
                    title={tT('sessions_list.item.active.title.1')}
                confirmTitle={tT('sessions_list.item.active.confirm.1', { name: session.name})}
                    onConfirm={() => actionSesstionArchive(session.courseId, session.id, true)}>{tT('sessions_list.item.active.1')}</Button>
            }
        </div>

        {/* {openDialog && <CreateSessionDialog session={session} cid={session.courseId} isShown={openDialog} onClose={() => setOpenDialog(false)} />} */}
    </div>
}

export const Item = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ItemPresenter));
