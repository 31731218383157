import React from 'react';
import { Icon } from 'uielements/src'

interface IShopPurseProps {
  size: number;
  padding?: number;
  className?: string;
}

export const ShopPurse = ({ padding, size, className }: IShopPurseProps) => {
  return <div className={`Purse ${className}`} style={{ padding, width: size, height: size }}><Icon className={'Purse-Icon'} icon={'wallet'} /></div>
  // return (
  //   <svg className={`Purse ${className}`} style={{ padding, width: size, height: size }} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <g clip-path="url(#clip0)">
  //       <path className='Purse-Color' d="M26.773 9.70691L26.2513 10.0722L23.7847 6.54952C23.206 5.72311 22.099 5.52791 21.3143 6.07734L20.9219 6.35206L19.2736 3.99803C18.695 3.17161 17.6297 2.94719 16.8909 3.46448L16.8033 3.52585L4.5994 17.775C4.59522 17.7779 4.5898 17.788 4.58563 17.7909C3.89195 18.3637 3.7364 19.4118 4.28876 20.2007L13.8571 33.8658C14.4358 34.6922 15.5428 34.8874 16.3275 34.3379L32.9518 22.6975C33.7782 22.1188 33.9734 21.0118 33.3948 20.1854L30.8639 16.5708L31.3438 16.2348C31.6068 16.0506 31.6552 15.7555 31.474 15.4967L27.5139 9.84121C27.3298 9.57826 26.99 9.55493 26.773 9.70691ZM17.553 4.23244C17.7993 4.12221 18.2005 4.23319 18.443 4.57962L20.0884 6.92947L8.08036 15.3376L17.553 4.23244ZM32.5266 20.7933C32.7692 21.1397 32.7196 21.6285 32.3732 21.8711L15.7489 33.5115C15.4024 33.7541 14.9261 33.6335 14.6836 33.2871L5.11517 19.622C4.8726 19.2756 4.92221 18.7868 5.26863 18.5442L21.847 6.93591C22.1935 6.69334 22.6698 6.81391 22.9123 7.16033L25.3789 10.683L21.0716 13.6991C19.2434 14.9792 18.813 17.4203 20.0931 19.2484C21.3731 21.0766 23.856 21.4778 25.6424 20.2269L29.9957 17.1787L32.5266 20.7933ZM30.3269 15.7152L25.0638 19.4005C23.7573 20.3153 21.9094 19.9858 20.9975 18.6835C20.0857 17.3813 20.4123 15.5292 21.7145 14.6173L26.9777 10.932L30.3269 15.7152Z" fill="black" />
  //       <path className='Purse-Color' d="M22.9383 17.3867C23.1225 17.6497 23.4593 17.6688 23.6764 17.5168L24.0687 17.2421C24.2857 17.0901 24.38 16.7629 24.1988 16.5041C24.0147 16.2412 23.6779 16.222 23.4608 16.374L23.0727 16.6458C22.8097 16.8299 22.7571 17.1279 22.9383 17.3867Z" fill="black" />
  //     </g>
  //     <defs>
  //       <clipPath id="clip0">
  //         <rect width="24.9669" height="24.9669" fill="white" transform="translate(0.571289 14.8916) rotate(-35)" />
  //       </clipPath>
  //     </defs>
  //   </svg>

  // )
}