import Component, { Presenter } from './component';
import {
    IScormUnitOwnProps,
    IScormUnitPresenterProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = IScormUnitOwnProps;
export type IComponentPresenterProps = IScormUnitPresenterProps;


export default Component