import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ComponentType } from 'react'
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    pathCreator,
    pathCreatorNews
} from '../../redux/actions'
import {
} from '../../redux/reducer/types'
import {
    getCreatorParams,
    getCreatorNewsParams
} from '../../redux/saga/selectors'
import { IBaseNews } from '../../types/baseNews'
import { IComponentAcceptor } from 'utils/src/ComponentInjector'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsCreator {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & IComponentAcceptor

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            children: React.ReactNode
            onSubmit: () => void
            actions?: React.ReactNode
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.NewsCreator.IOwnProps



export const mapStateToProps = (state: any, { }: IOwnProps) => ({
    ...getCreatorParams('currentType', 'isValid', 'sendError', 'validFile')(state),
    ...getCreatorNewsParams('attachments', 'id')(state),
    maxFileSize: state.store.appSettings.maxFileSizeLimit as number,
    denyMIME: state.store.appSettings.denyMIME as string[],
})


export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    pathCreator,
    pathCreatorNews
}, dispatch);

////////////

export const cnClassName = cn('NewsEditor');