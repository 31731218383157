import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { withRouter } from "react-router"
import WidgetPresenter from '../Widget';
import { withBemMod } from '@bem-react/core';
// import { Photoalbums } from 'blocks/Photoalbums/Photoalbums'
import { cnWidget } from '../Widget.index';
import { IWidgetTypeFilesProps } from './Widget_type_files.index';
import './Widget_type_album.scss';
import { RouteComponentProps } from 'react-router';
import { Files } from 'blocks/Files/Files';
import { widgets } from 'i.widgets';
// import { PhotoView } from 'blocks/Photoalbums/PhotoView/PhotoView'
const type = 'files';

class WidgetTypeAlbumsPresenter extends WidgetPresenter<IWidgetTypeFilesProps & RouteComponentProps<{ fname: string, pid: string, aid: string }>> {
  public render() {
    if (!this.props.widget) return null;
    
    // const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    /*if (this.props.match.params.aid) return (
      <div>
        <AlbumView showControls={this.props.wcontext.isGroupAdmin} aid={this.props.match.params.aid} gid={this.props.wcontext.gId} />
         {
          this.props.match.params.pid ? <PhotoView
            showControls={this.props.wcontext.isGroupAdmin} 
            gid={this.props.wcontext.gId} 
            aid={this.props.match.params.aid}
            id={this.props.match.params.pid}
            fname={this.props.match.params.fname}
            open={true}
            onClose={() => { this.props.history.push(`/group/${this.props.wcontext.gId}/albums/${this.props.match.params.aid}/`)}} /> : null
        } 
      </div>
    )*/

    return (
      <widgets.components.common {...this.props}>
        <Files gid={this.props.wcontext.gId} />
      </widgets.components.common>
      // {/* <Photoalbums gid={this.props.wcontext.gId} showControls={this.props.wcontext.isGroupAdmin} /> */}
    );
  }
}

export default withRouter(WidgetTypeAlbumsPresenter)