import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from 'redux/connector';
import { IContext } from 'store/vm';
import { IRatingsData } from 'utils/src/requests/models/api.ratings';

export interface IRatingsWidgetProps extends IClassNameProps, IRatingsWidgetStateProps, IDispatchProps {
  tag?: 'div';
  // contexts?: string[];
  context: {[s: string]: any};
  settings?:{[s: string]: any};
}

export interface IRatingsWidgetState {
  text?: string;
  ratings: IRatingsData[];
  noData: boolean;
  width: number;
}

export interface IRatingsWidgetStateProps {
  // store: any;
  // context: IContext;
}

export const mapStateToProps = (state: any) => ({
  // store: state.store,
  // context: state.store.context,
})

export const cnRatingsWidget = cn('RatingsWidget');
export const cnClassNameNoData = cn('RatingsWidgetNoData');