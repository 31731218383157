import React, { FC, memo, useMemo } from 'react'
import {
    cnClassName,
    IPresenterProps
} from './interfaces'
import './style.scss'

import PryanikyEditor from 'uielements/src/PryanikyEditorV1'
import { markdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap'
import { EditorState, convertToRaw, convertFromRaw, Editor } from 'draft-js';
// import MultiDecorator from "draft-js-plugins-editor/lib/Editor/MultiDecorator";
// import { CompositeDecorator } from "draft-js";
// import createLinkifyPlugin from 'uielements/src/PryanikyEditorV1/draft-js-linkify-plugin/src';
// import createLinkPlugin from 'uielements/src/PryanikyEditorV1/draft-js-anchor-plugin/src';

import CompoundDecorator from 'uielements/src/PryanikyEditorV1/CompoundDecorator';

// const linkPlugin = createLinkPlugin({});
// const linkifyPlugin = createLinkifyPlugin();
// const listOfPlugins: any[] = [
//     linkPlugin,
//     linkifyPlugin,
// ]

// function getPluginDecoratorArray() {
//     let decorators: any[] = [];
//     let plugin: any;
//     // check each plugin that will be used in the editor for decorators
//     // (retrieve listOfPlugins however makes sense in your code)
//     for (plugin of listOfPlugins) {
//         if (plugin.decorators !== null && plugin.decorators !== undefined) {
//             // if the plugin has any decorators, add them to a list of all decorators from all plugins
//             decorators = decorators.concat(plugin.decorators);
//         }
//     }
//     return decorators;
// }

// function myFunctionForGrabbingAllPluginDecorators() {
//     return new MultiDecorator([new CompositeDecorator(getPluginDecoratorArray())]);
// }

export const Presenter: FC<IPresenterProps> = ({
    className,
    rawState,
    name,
    fullscreen
}) => {
    const draftState = useMemo(() => {
        return rawState.blocks ? EditorState.createWithContent(convertFromRaw(rawState), CompoundDecorator(undefined, true)) : EditorState.createEmpty()
    }, [rawState])
    return <div className={cnClassName({ fullscreen }, [className])}>
        <h3 className={cnClassName('Title')}>{name}</h3>
        <div className={cnClassName('Content')}>
            <PryanikyEditor
                enableModules={{ ...markdownPreset, attachment: true}}
                enabledAnvanced={true}
                disableToolbar={true}
                setActionBtn={() => { }}
                value={draftState}
                onChange={() => { }}
                viewOnly={true}
                />
        </div>
    </div>
}

export default memo(Presenter);
