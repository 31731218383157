import { Translate } from "localizations/Translate";
import { parse } from "query-string";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { DSSetItems } from "redux/dataStorage/actions";
import { checkResponseStatus, ResponseError } from "utils/src";
import { useDidUpdateEffect, useReffedState } from "utils/src/hooks";
import { getVacancyList } from "utils/src/requests/admin.vacancies";
import { VacanciesAppendToList, VacanciesSetList } from "../redux/actions.vacancies";
import { getVacanciesReducerFields } from "../redux/selectors.vacancies";
import { getVacancyShortMock } from "utils/src/mock/vacancies.mock";
import { useMockParam } from "utils/src/mock/hooks";
import { vacanciesI18nKey } from "localization";
import { useDefaultStaticStates } from "muicomponents/src/ItemsListDialog/defaultListState";
import { VacancyFilter } from "utils/src/BaseTypes/vacancy";

export const useVacanciesList = () => {

    const useMock = useMockParam();

    const dispatch = useDispatch();

    const {search, debouncedSearch, changeSearch} = useDefaultStaticStates();
    const searchRef = useRef(search);
    searchRef.current = search;
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ isFinished, setIsFinished ] = useState(false);
    const { list } = useSelector(getVacanciesReducerFields('list'));
    const listRef = useRef(list);
    listRef.current = list;
    const [filter, setFilter, filterRef] = useReffedState<keyof typeof VacancyFilter | undefined>(undefined);
    
    const loadMore = useCallback(async function(searchChanges?: boolean) {
        try {
            if(searchChanges) {
                dispatch(VacanciesSetList([]));
            }
            setLoading(true);
            setError(false);
            let response = await getVacancyList({
                filter: filterRef.current || '',
                skipCount: searchChanges ? 0 : listRef.current.length,
                count: 10,
                search: searchRef.current
            });
            if(useMock) {
                const data = [ getVacancyShortMock(), getVacancyShortMock(), getVacancyShortMock() ].filter(el => searchRef.current ? el.name?.toLowerCase().includes(searchRef.current.toLowerCase()) : true );
                response = {
                    data,
                    error_code: 0,
                    error_text: 'OK',
                    isFinished: data.length === 0
                };
            }
            if(checkResponseStatus(response)) {
                dispatch(DSSetItems({ storage: 'vacanciesShort', items: response.data.map(el => ({ id: el.id, item: el })) }));
                if(searchChanges) {
                    dispatch(VacanciesSetList(response.data.map(el => el.id)));
                } else {
                    dispatch(VacanciesAppendToList(response.data.map(el => el.id)));
                }
                setIsFinished(response.isFinished || false);
            } else {
                throw new ResponseError(response)
            }
        } catch(e) {
            if(e instanceof ResponseError) {
                setError(true);
            } else {
                console.error('get requested users error', e);
            }
        } finally {
            setLoading(false);
        }
    }, []);

    useLayoutEffect(() => {
        loadMore(true);
    }, [debouncedSearch, filter]);

    return {
        loading,
        error,
        isFinished,
        list,
        loadMore,
        search,
        setSearch: changeSearch,
        filter,
        setFilter
    };
};