import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import {

  isPresentSent,

} from '../../redux/sagas/SecretSanta/actions'
import {
  getIsPresentSend,
  getListLengthSettings
} from '../../redux/sagas/SecretSanta/selectors'

export interface ISecretSantaWidgetList extends ISSWidgetOwnProps, ISecretSantaWidgetListStateProps, ISecretSantaWidgetListDispatchProps {
  tag: any
  // settings: any
  className?: string
  store?: any
  changeVm?: any
}

export interface ISSWidgetOwnProps{
  settings: any

}

export const mapStateToProps = (state: IState, props:ISSWidgetOwnProps) => {
  
  return {
    dataLength:  getListLengthSettings(state),
    // isPresentSendData: getIsPresentSend(props.settings?.type?.id)(state),
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  isPresentSent
}, dispatch);

export type ISecretSantaWidgetListStateProps = ReturnType<typeof mapStateToProps>
export type ISecretSantaWidgetListDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnSecretSantaWidgetList = cn('SecretSantaWidgetList')