import { User, defaultUser } from '../BaseType'
import { ReplyType, ThanksInfoType, createDefaultThanksInfo, createDefaultReply } from '../Reply/Reply.type'

export interface RewardReason {
    id: string;
    name: string;
    selected: boolean;
}

export const defaultRewardReason: RewardReason = {
    id: "",
    name: "",
    selected: false
}

export interface ThanksReplyType extends ReplyType {
    thanksInfo: ThanksInfoType;
    users?: any
    thanksForAll?: number;
}

export const createDefaultThanksReply = (): ThanksReplyType => ({
    ...createDefaultReply(),
    thanksInfo: createDefaultThanksInfo()
})