import React from 'react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { IconButton } from 'muicomponents/src/IconButton';
import { cnFiltersMui } from 'blocks/Filters/_type/Filters_type_shopMui/Filters_type_shopMui.index';


export const YellowPurseBtn = () => {


    return <IconButton sx={{ background: '#FFB400', mr: 2, pointerEvents: 'none' }}
        className={cnFiltersMui('PurseBtn')}  >
        <AccountBalanceWalletIcon />
    </IconButton>
}