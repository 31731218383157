import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ComponentType } from 'react'
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    toggleEdit
} from '../../redux/actions'
import { TViewType } from '../../redux/reducer/types'
import {
    getNewsById,
    isEditState,
    getNewsParamsById,
} from '../../redux/saga/selectors'
import { IBaseNews } from '../../types/baseNews'
import { IComponentAcceptor } from 'utils/src/ComponentInjector'
import { viewTypesExluded } from '../Timeline/component';
// import {  } from 'utils/src/requests/models/api.learningTracks'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsItem {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & IComponentAcceptor

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            id: string
            single?: boolean
            // advancedNodeKey?: Exclude<TViewType, (typeof viewTypesExluded)[number]>
            ViewComponent?: ComponentType
            virtualize?: boolean
            repost?: boolean
            additional?: {[s: string]: any}
            nodeKeys?: string[]
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.NewsItem.IOwnProps

const newsParams = [
    'newstype',
    'componentRenderName',
    'additionalFields'
] as const

export const mapStateToProps = (state: any, { id }: IOwnProps) => ({
    ...getNewsParamsById(id, newsParams)(state),
    edit: isEditState(id)(state)
})


export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    toggleEdit
}, dispatch);

////////////

export const cnClassName = cn('NewsNewsItem');