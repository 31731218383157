// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import i18n from 'localizations/i18n';
import * as utils from 'utils/src/utils'
import { cnSuggesters, ISuggestersProps, ISuggestersState, mapStateToProps, mapDispatchToProps } from './Suggesters.index';
import { Icon, Button, Spinner } from "uielements/src";
import { Selector } from './Selector/Selector'
import './Suggesters.scss';
import { Item } from './Item/Item'
import { IRequest } from 'utils/src/requests/service';
import { toggleStringInArray, stringInArray } from './Suggesters.utils'
import { withRouter } from 'react-router-dom'
import { Redirect } from 'react-router';
import { connect } from 'react-redux'
import { getQuery } from 'redux/actions/Search';
import { Translate } from 'localizations/Translate';
import { isArray } from 'lodash'
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

/*
const sampleData = {
  imgUrl: 'url://url',
  title: 'Title',
  description: 'Description',
  guid: '00000000-0000-0000-0000-000000000000',
  type: 'user|group|news|tag',
  link: 'url',
  icon: 'icon'
}
*/

export class SuggestersPresenter extends React.PureComponent<ISuggestersProps, ISuggestersState> {
  public el: HTMLElement | null;

  private dSearch = utils.debounce((val: string) => this.search(val), 800);
  private prevQuery = {
    news: '',
    tags: '',
    groups: '',
    users: '',
    pages: ''
  };

  private newsRequest: IRequest | null = null;
  private pagesRequest: IRequest | null = null;
  private tagsRequest: IRequest | null = null;
  private usersRequest: IRequest | null = null;
  private groupRequest: IRequest | null = null;

  public constructor(props: ISuggestersProps) {
    super(props);
    this.state = {
      open: false,
      data: {},
      users: [],
      groups: [],
      tags: [],
      news: [],
      pages: [],
      activeTypes: ['users', 'groups', 'tags'],
      query: '',
      isRequest: 0
    }
  }

  private isRqst = () => {
    return [
      this.newsRequest,
      this.pagesRequest,
      this.tagsRequest,
      this.usersRequest,
      this.groupRequest
    ].some(v => Boolean(v))
  }

  componentDidMount() {
    const typesData = localStorage.getItem('searchActiveTypes')
    if (typesData) {
      const activeTypes = JSON.parse(typesData)
      this.setState({ ...this.state, activeTypes }, () => this.search(this.state.query || ''))
    }

    window.addEventListener('beforeunload', () => {
      const activeTypes = JSON.stringify(this.state.activeTypes)
      localStorage.setItem("searchActiveTypes", activeTypes)
    });
  }


  public render() {
    const { className = '', tag: TagName = 'div', getQuery } = this.props;
    const { open, data, users, tags, groups, news, activeTypes, query, pages, isRequest } = this.state;
    const allResults = [...users, ...groups, ...tags, ...news, ...pages];
    const counts = {
      users: users.length,
      groups: groups.length,
      tags: tags.length,
      news: news.length,
      pages: pages.length
    }

    const onGlobalSearch = (query: string) => {
      getQuery(query)
      this.props.history.push('/search')
      this.setState({ open: false, isRequest: 0 })
    }

    const onCloseSuggester = () => this.setState({ open: false });

    return (
      <TagName id={'DASHUPMENUSEARCH'} ref={el => this.el = el} className={cnSuggesters({ open }, [className])}>
        <form className={cnSuggesters('SearchBox', { open })} onSubmit={(e) => { e.preventDefault() }}>
          <input value={query} onChange={this.changeQuery} className={cnSuggesters('Input')} type={'text'} onFocus={this.open} onKeyUp={(e: React.KeyboardEvent) => e.keyCode === 13 ? onGlobalSearch(query) : null} />
          <div className={cnSuggesters('Actions')}>
            {this.isRqst() ? <Spinner fontSize={16} /> : (!query ? (
              <Button theme="unstyled" className={cnSuggesters('Button', { role: 'submit' })}>
                <Tooltip title={i18n.t('search')} >
                  <span><Icon icon="search" /></span>
                </Tooltip>
              </Button>
            ) : (
              <Button
                tag={'a'}
                theme="unstyled"
                className={cnSuggesters('Button', { role: 'cancel' })}
                onClick={this.cancelSearch}>
                <Tooltip title={i18n.t('clear')} >
                  <span>
                    <Icon icon="times" />
                  </span>
                </Tooltip>
              </Button>
            ))}
          </div>
        </form>
        <div className={cnSuggesters('Portal')}>
          {open && <div className={cnSuggesters('Dropdown')}>
            <Selector counts={counts} activeTypes={activeTypes} toggleType={this.toggleType} />

            <div className={cnSuggesters('Results')}>
              {allResults.length === 0 ? this.noopRender() : allResults.map((val: any) =>
                <>
                  <Item onCloseSuggester={onCloseSuggester} key={val} data={data[val]} />
                </>
              )}
            </div>
            <Button
              theme='unstyled'
              type='rlink'
              onClick={() => onGlobalSearch(query)}
              className={cnSuggesters('GlobalSearch')}
            >
              {i18n.t('pryaniky.suggesters.goToSearchPage')}
            </Button>
          </div>}
        </div>

        {this.props.children}
      </TagName>
    )
  }

  private noopRender = () => {
    const { isRequest, query, activeTypes } = this.state;
    let val: any = <Translate i18nKey='pryaniky.suggesters.noop' />;
    if (query === '') val = <Translate i18nKey='pryaniky.suggesters.emptyQuery' />;
    if (activeTypes.length === 0) val = <Translate i18nKey='pryaniky.suggesters.noActiveTabs' />;
    if (this.isRqst()) val = <div style={{ width: '100%', height: '70px', position: 'relative' }}><Spinner fontSize={16} /></div>;
    return <div className={cnSuggesters('Noop')}>{val}</div>;
  }

  private cancelSearch = () => {
    this.setState({ query: '' })
    this.close()
  }

  private toggleType = (type: string) => {
    const activeTypes = toggleStringInArray(this.state.activeTypes, type)
    this.setState({ ...this.state, activeTypes }, () => this.search(this.state.query || ''))




  }

  private handleClickOutside = (event: any) => {
    if (this.el && !this.el.contains(event.target)) this.close();
  };

  private open = () => {
    this.setState({ open: true })
    document.addEventListener('click', this.handleClickOutside, false);
  }

  private close = () => {
    this.setState({ open: false, isRequest: 0 })
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  private searchUsers = (str: string) => {
    if (this.prevQuery.users === str && this.state.users.length !== 0) return;
    this.prevQuery.users = str
    this.setState({ isRequest: this.state.isRequest + 1 })
    if (this.usersRequest) this.usersRequest.ac.abort()
    this.usersRequest = utils.API.suggesters.search('users', str)
    this.usersRequest.r.then((d: any) => {
      if (utils.checkResponseStatus(d)) {
        this.setState((prevState: ISuggestersState) => {
          const users = d.data.map((val: any) => {
            prevState.data[val.id] = {
              imgUrl: val.userPhotoUrl,
              title: val.displayName,
              description: [val.position, val.division].filter((v: any) => v !== '').join(', '),
              guid: val.id,
              type: 'user',
              link: `/user/${val.id}`,
              icon: 'user-alt',
              baseNetwork: val.baseNetwork
            }
            return val.id
          })
          prevState.users = users;
          return prevState;
        })
      }
      this.usersRequest = null
      this.setState({ isRequest: this.state.isRequest - 1 })

    }).finally(() => {
      this.usersRequest = null
    })
  }

  private searchGroups = (str: string) => {
    if (this.prevQuery.groups === str && this.state.groups.length !== 0) return;
    this.prevQuery.groups = str
    this.setState({ isRequest: this.state.isRequest + 1 })
    if (this.groupRequest) this.groupRequest.ac.abort()
    this.groupRequest = utils.API.suggesters.search('groups', str)
    this.groupRequest.r.then((d: any) => {
      if (utils.checkResponseStatus(d)) {
        this.setState((prevState: ISuggestersState) => {
          const groups = d.data.map((val: any) => {
            prevState.data[val.pkid] = {
              imgUrl: val.imgUrl,
              title: val.displayName,
              description: val.description,
              guid: val.pkid,
              type: 'group',
              link: `/group/${val.pkid}`,
              icon: 'users'
            }
            return val.pkid
          })
          prevState.groups = groups;
          return prevState;
        })
      }
      this.groupRequest = null
      this.setState({ isRequest: this.state.isRequest - 1 })

    }).finally(() => {
      this.groupRequest = null
    })
  }

  private searchTags = (str: string) => {
    if (this.prevQuery.tags === str && this.state.tags.length !== 0) return;
    this.prevQuery.tags = str
    this.setState({ isRequest: this.state.isRequest + 1 })
    if (this.tagsRequest) this.tagsRequest.ac.abort()

    this.tagsRequest = utils.API.suggesters.search('tags', str)
    this.tagsRequest.r.then((d: any) => {
      if (utils.checkResponseStatus(d)) {
        this.setState((prevState: ISuggestersState) => {
          const tags = d.data.map((val: any) => {
            prevState.data[val.id] = {
              imgUrl: '',
              title: val.displayName,
              description: '',
              guid: val.id,
              type: 'tags',
              link: `/tag/${val.id}`,
              icon: 'hash-tag',
              endorsements: (val.endorsements || []).map((el: any) => ({
                ...el,
                title: el.displayName,
                link: `/user/${el.id}`,
                type: 'user',
                icon: 'user-alt',
                description: [val.position, val.division].filter((v: any) => v !== '').join(', '),
              }))
            }
            return val.id
          })
          prevState.tags = tags;
          return prevState;
        })
      }
      this.tagsRequest = null
      this.setState({ isRequest: this.state.isRequest - 1 })

    }).finally(() => {
      this.tagsRequest = null
    })
  }

  private searchNews = (str: string) => {
    if (this.prevQuery.news === str && this.state.news.length !== 0) return;
    this.prevQuery.news = str
    this.setState({ isRequest: this.state.isRequest + 1 })
    if (this.newsRequest) this.newsRequest.ac.abort()

    this.newsRequest = utils.API.suggesters.search('news', str)
    this.newsRequest.r.then((d: any) => {
      if (utils.checkResponseStatus(d)) {
        this.setState((prevState: ISuggestersState) => {
          const news = d.data.map((val: any) => {
            prevState.data[val.id] = {
              imgUrl: val.user.userPhotoUrl,
              title: val.user.displayName,
              description: val.text,
              guid: val.id,
              type: 'news',
              link: `/news/${val.id}`,
              icon: 'newspaper'
            }
            return val.id
          })
          prevState.news = news;
          return prevState;
        })
      }
      this.newsRequest = null
      this.setState({ isRequest: this.state.isRequest - 1 })
    }).finally(() => {
      this.newsRequest = null
    })
  }
  private searchPages = (str: string) => {
    if (this.prevQuery.pages === str && this.state.pages.length !== 0) return;
    this.prevQuery.pages = str
    this.setState({ isRequest: this.state.isRequest + 1 })
    if (this.pagesRequest) this.pagesRequest.ac.abort()

    this.pagesRequest = utils.API.suggesters.search('pages', str)
    this.pagesRequest.r.then((d: any) => {
      if (utils.checkResponseStatus(d)) {
        this.setState((prevState: ISuggestersState) => {
          const pages = d.data.map((val: any) => {
            prevState.data[val.id] = {
              imgUrl: "",
              title: val.title,
              description: "",
              guid: val.id,
              type: val.type,
              link: val.url,
              icon: 'wiki'
            }
            return val.id
          })
          prevState.pages = pages;
          return prevState;
        })
      }
      this.pagesRequest = null
      this.setState({ isRequest: this.state.isRequest - 1 })
    }).finally(() => {
      this.pagesRequest = null
    })
  }
  private changeQuery = (e: any) => {
    if (this.newsRequest) this.newsRequest.ac.abort()
    if (this.pagesRequest) this.pagesRequest.ac.abort()
    if (this.tagsRequest) this.tagsRequest.ac.abort()
    if (this.groupRequest) this.groupRequest.ac.abort()
    if (this.usersRequest) this.usersRequest.ac.abort()
    this.setState({
      query: e.target.value,
      users: [],
      groups: [],
      tags: [],
      news: [],
      pages: [],
    })
    this.dSearch(e.target.value)
  }

  private search = (value: string) => {
    if (value === '') return;

    !stringInArray(this.state.activeTypes, 'users') ? this.searchUsers(value) : this.setState({ users: [] })
    !stringInArray(this.state.activeTypes, 'groups') ? this.searchGroups(value) : this.setState({ groups: [] })
    !stringInArray(this.state.activeTypes, 'tags') ? this.searchTags(value) : this.setState({ tags: [] })
    !stringInArray(this.state.activeTypes, 'news') ? this.searchNews(value) : this.setState({ news: [] })
    !stringInArray(this.state.activeTypes, 'pages') ? this.searchPages(value) : this.setState({ pages: [] })

  }
}


// export const Suggesters = withRouter(SuggestersPresenter);

export const Suggesters = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestersPresenter)
)

  //   compose(
  //     withBemMod(cnSuggesters(), {})
  //     )(
  //       SuggestersPresenter
  //   )
  // )
