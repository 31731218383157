import { IPageCommentsProps, cnPageComments, IPageCommentsState, mapStateToProps, mapDispatchToProps } from './PageCommentsNew.index';
import * as React from 'react'
import './PageCommentsNew.scss'
import { CustomScrollbars } from 'uielements/src';
import { Comments } from 'LMSModule/LMS/Comments/Comments';
import { connect } from 'react-redux';

class PageCommentsPresenter extends React.Component<IPageCommentsProps, IPageCommentsState> {
    public el: React.RefObject<HTMLDivElement> | null = React.createRef<HTMLDivElement>();
    public bodyContentRendererRef: React.RefObject<React.ComponentType> | null = React.createRef<React.ComponentType>();
    private _viewed: boolean = false;
    private platform: string;
    private mobile: boolean;
    private isInFrame =
        window.location.pathname.includes('/wv/') ||
        window.location.pathname.includes('/widgets/') ||
        window.location.pathname.includes('/Widgets/');


    constructor(props: IPageCommentsProps) {
        super(props);
        this.state = {
            w: '100%'
        }
    }


    public render() {
        const { id } = this.props.data;
        const { onClose, currentUser } = this.props;
        const comments = this.props.data.comments;
        const customClassNames = this.platform + ' ';
        if (!this.props.data) return null;
        return (
            <div className={cnPageComments("CommentsPageMenuLayout")} >
                <div className={cnPageComments("CommentsPageMenuLayout-Data")}>
                    <CustomScrollbars style={{ width: "100%", height: "100%", borderRadius: "8px" }}>
                        <div
                            id={cnPageComments()}
                            className={cnPageComments(
                                {
                                    wv: this.isInFrame,
                                    mobile: this.mobile,
                                    auth: true
                                },
                                [customClassNames]
                            )} >
                            <Comments className={cnPageComments("Comments")} nid={id} />
                        </div>
                    </CustomScrollbars>
                </div>
            </div>

        );
    }

}

export const PageComments = connect(
    mapStateToProps,
    mapDispatchToProps
)(PageCommentsPresenter)