import React, { FC, useEffect } from 'react';
import './List.scss'
import { IMoveFilesListProps, mapDispatchToProps, mapStateToProps } from './List.index'
import { connect } from 'react-redux';
import FolderItem from 'blocks/Files/FolderItem/FolderItem'

export const MoveFilesListPresenter: FC<IMoveFilesListProps & any> = ({ loadDirList, gid, folders }) => {
    useEffect(() => {
        loadDirList(gid)
    }, [])
    return <div>
        {folders.map((folder: any) => {
            return <FolderItem
                actions={[]}
                date={folder.date}
                fid={folder.id}
                key={folder.id}
                name={folder.name}
                onSelect={() => { }}
                activeMoveng={false}
                showControls={false}
                isBack={folder.isBack}
                move={() => { }}
                selected={false}
                clickItem={() => loadDirList(gid, 0, (folder.name === '..' ? folder.parentFolder : folder.id))}
            />
        })}
    </div>
};


export const List = connect(
    mapStateToProps,
    mapDispatchToProps
)(MoveFilesListPresenter);
