import { cn } from "@bem-react/classname";
import { IDispatchProps } from "redux/connector";
// import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators, Action, Dispatch } from "redux"

export interface IWikiEditProps extends IWikiEditOwnProps, IWikiEditDispatchProps, IWikiEditStateProps {
    // isShown: boolean;
    closeWikiEdit: () => void;
    isEdit: (value:boolean) => void;
    className?: string;
    data:any;
       /**
  * флаг отвечающий является ли пользователь Модератором Базы знаний или Админом
  */
       isWkilistModerator: boolean
}
export interface IWikiEditOwnProps{
    sendDataForEditPage?: (data: any) => void;
    context?: any;
    actualData?: Partial<IActualDataWiki>;
    id: string;

};

interface IActualDataWiki {
    collectionAlias: string,
    id: string,
    url: string,
    title: string,
    moderators: any[],
    tags: any[],
    collections: any[],
    type: string,
    titleImageId: string,
    description: string,
    hasСustomTitleImage: string,
    titleImageUrl: string,
    showInMainMenu: boolean,
    allowComments: boolean,
    defaultViewType: number,
    isArchived: boolean | undefined,
    positionInCollection: number|string,
    viewers: any[] | null,
    file: any,
    isDraft:boolean,
    isInheritingRootRights: boolean;
    isHideFooter: boolean;
}
export type IWikiEditDispatchProps = ReturnType<typeof mapWikiEditDispatchToProps>

export const mapWikiEditDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export type IWikiEditStateProps = ReturnType<typeof mapWikiEditStateToProps>;

export const mapWikiEditStateToProps = () => ({});

export const cnWikiEdit = cn("WikiEditMui");


