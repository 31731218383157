import { typeofHandleScroll } from '../MountainRating.index';
import {cn } from '@bem-react/classname';

export const cnScrollBar = cn('ScrollBar');

export interface IScrollBarProps {
  totalItems: number;
  currentItem: number;
  itemsPerPage: number;
  width: number;
  onScroll: typeofHandleScroll;
}

export interface IScrollBarState {
  dragStart?: number;
}