import React, { FC, useEffect, useState } from 'react'
import { cnQuestions, mapDispatchToProps, mapStateToProps } from './Questions.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Questions.scss'
import { Selectize } from 'uielements/src';
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

export const QuestionsPresenter: FC<any> = ({ className, questionsIds = [], questions, currentFilters, setModerationFilters }) => {
    let acti = Array.isArray(currentFilters) ? currentFilters : [currentFilters]
    const elements: ISelectizeItem[] = (questionsIds as string[]).map((id: string) => {
        return {
            value: questions[id].id,
            title: questions[id].header === "" ? questions[id].text.substring(0, 40) : questions[id].header,
            id: id
        }
    }).reduce<ISelectizeItem[]>((p, c) => p.find((v) => v.id === c.id) ? p : [...p, c], [])
    return <div className={cnQuestions({}, [className])}>
        {/* Questions filter */}
        <Selectize
            // key={i} 
            onClick={(value) => { setModerationFilters({ 'filter.filterByQuestionIds': acti.includes(value.value) ? acti.filter((val: any) => val !== value.value) : [value.value] }) }}
            active={acti}
            // rLink={linkCreator('cat', zeroId)}
            name={tT('moderation.filters.questions.1')}
            elements={elements}
            multiple={true}
            children={[]} />
    </div>
}

export const Questions = QuestionsPresenter/*connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(QuestionsPresenter));*/
