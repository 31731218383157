import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from '../../redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface IRatingBadgeWidget extends IRatingBadgeWidgetOwnProps, IRatingBadgeWidgetStateProps, IRatingBadgeWidgetDispatchProps {
    tag: any

    className?: string
    store?: any
    changeVm?: any
}
export interface IRatingBadgeWidgetOwnProps {
    settings: any,

}

export const mapStateToProps = (state: IState, props: IRatingBadgeWidgetOwnProps) => {
    return {
        currentUser: getAuthUser(state)
    }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export type IRatingBadgeWidgetStateProps = ReturnType<typeof mapStateToProps>
export type IRatingBadgeWidgetDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnRatingBadgeWidget = cn('RatingBadgeWidget')