import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';

export interface IPryanikyEditorProps extends IClassNameProps, IPryanikyEditorStateProps, ReturnType<typeof mapDispatchToProps> {
    value: any;
    placeholder?: string;
    noIcon?: boolean;
    disableToolbar?: boolean;
    autoFocus?: () => boolean;
    onChange: (val: any) => void;
    setActionBtn: (actionBtns: React.ReactNode) => void;
    onFocus: () => void;
    onBlur: () => void;
    enabledAnvanced?: boolean
    setError?: (isError: boolean, errorText: string) => void
    // enableCensure?: boolean
    readOnly?: boolean;
    additionalControls?: React.ReactNode;
    maxFileSize?: number;
    onComplete?: () => () => void
    locker?: (lock: boolean) => void
}

export interface IPryanikyEditorState {

}

export interface IPryanikyEditorStateProps {
    enableCensure?: boolean

}

export const mapStateToProps = (state: any) => ({
    enableCensure: state.store.appSettings.enabledModules.stopwords
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);
