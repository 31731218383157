import * as SignalR from '@aspnet/signalr';
import { getCookie } from "utils/src/utils";
import { store } from 'redux/store'

// setInterval(() => {
//    store.dispatch({ type: 'INCREMENT_NOTIFICATION' });
//  }, 15000);

export const signalRUrl = 'https://apigate.pryaniky.com/SignalRGateway';
// export const signalRUrl = 'http://192.168.31.159:10000';

const connection = new SignalR.HubConnectionBuilder()
  .withUrl(signalRUrl + '/userHub', {
    skipNegotiation: true,
    transport: SignalR.HttpTransportType.WebSockets
  })
  // .configureLogging(SignalR.LogLevel.Information)
  .build()

let registerTimer: any;
const register = () => {
  connection
    .invoke("register", getCookie('authAt')).then(() => {

    })
    .catch((err: any) => {
      return console.error(err.toString());
    });
}

/*connection.start()
  .then(() => {
    if (!getCookie('authAt')) {
      registerTimer = setInterval(() => {
        if (getCookie('authAt')) {
          register();
          clearInterval(registerTimer);
        }
      }, 500);
    } else {
      register();
    }
  })*/
  
connection.on("ReceiveNotification", (message: any) => {
  store.dispatch({ type: 'INCREMENT_NOTIFICATION' });
  // this.props.notifications.unshift(message);
  // this.props.changeVm(dataName, this.props.notifications);
});


export default connection;