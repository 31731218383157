import { ReactNode } from 'react';
import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface IPopoverProps extends IClassNameProps {
    children: ReactNode; 
    anchor?: HTMLElement | null;
    clickOutside?: (target: any) => void;
    clickInside?: (target: any) => void;
}

export const cnPopover = cn('Popover');