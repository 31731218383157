import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
// import { ICourse } from '../mock';
import { loadCourse, openCourse } from "../../redux/actions/LMS";
import { loadUnitsLogs } from "../../redux/actions/COMMON";
import { RouteComponentProps } from 'react-router';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions'
import { routerActions } from 'connected-react-router';
import { getCompletedSessions } from '../../redux/sagas/LMS/selectors'
export interface ICourseProps extends IClassNameProps, ICourseStateProps, IDispatchProps, RouteComponentProps, ICourseDispatchProps {
    tag?: 'div';
}

export interface ICourseState {

}

export interface ICourseStateProps {
    course: any
}

export const mapStateToProps = (state: any, props: any) => ({
    course: state.LMS.courses.entities.values[props.match.params.id],
    sessionsLogs: state.LMS.sessionsLogs,
    sessions: state.LMS.sessions,
    currentUser: getAuthUser(state),
    questionsIds: Object.keys(state.LMSTO.moderation.questions),
    questions: state.LMSTO.moderation.questions,
    completedSession: getCompletedSessions(props.match.params.id)(state)
    // cIds: state.LMS.courses.result,
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadCourse,
    loadUnitsLogs,
    openCourse,
    rPush: routerActions.push
}, dispatch);

export type ICourseDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnCourse = cn('Course');