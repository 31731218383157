import Component, { Presenter } from './component';
import {
    IAddCourseToTrackOwnProps,
    IAddCourseToTrackPresenterProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = IAddCourseToTrackOwnProps;
export type IComponentPresenterProps = IAddCourseToTrackPresenterProps;


export default Component