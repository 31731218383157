import { ICalendarActions, ICalendarSetEventsPayload, ICalendarSetCalFiltersPayload, ICalendarGetEventsPayload, ICalendarSetCalFilterColorPayload, ICalendarUpdateEventPayload } from './action.interfaces';
import { ICalendarWorkflowFiltersData } from 'utils/src/requests/models/api.calendar';

export const CALENDAR_GET_EVENTS = 'CALENDAR_GET_EVENTS';
export const CALENDAR_SET_EVENTS = 'CALENDAR_SET_EVENTS';
export const CALENDAR_SET_LOADING = 'CALENDAR_SET_LOADING';
export const CALENDAR_GET_CAL_FILTERS = 'CALENDAR_GET_CAL_FILTERS';
export const CALENDAR_SET_CAL_FILTERS = 'CALENDAR_SET_CAL_FILTERS';
export const CALENDAR_SET_CURRENT_VIEW = 'CALENDAR_SET_CURRENT_VIEW';
export const CALENDAR_SET_CURRENT_DATE = 'CALENDAR_SET_CURRENT_DATE';
export const CALENDAR_SET_CAL_FILTER_COLOR = 'CALENDAR_SET_CAL_FILTER_COLOR';
export const CALENDAR_UPDATE_EVENT = 'CALENDAR_UPDATE_EVENT';
export const CALENDAR_GET_WORKFLOW_FILTERS = 'CALENDAR_GET_WORKFLOW_FILTERS';
export const CALENDAR_SET_WORKFLOW_FILTERS = 'CALENDAR_SET_WORKFLOW_FILTERS';
export const CALENDAR_CLEAR_WORKFLOW_FILTERS = 'CALENDAR_CLEAR_WORKFLOW_FILTERS';

/*****************************************/
/*************** to reducer **************/
/*****************************************/

export const setCalendarEvents = (payload: ICalendarSetEventsPayload): ICalendarActions => ({
  type: CALENDAR_SET_EVENTS,
  payload
})

export const setCalendarLoading = (payload: boolean): ICalendarActions => ({
  type: CALENDAR_SET_LOADING,
  payload
})

export const setCalendarCalFilters = (payload: ICalendarSetCalFiltersPayload): ICalendarActions => ({
  type: CALENDAR_SET_CAL_FILTERS,
  payload
})

export const setCalendarCurrentView = (payload: string): ICalendarActions => ({
  type: CALENDAR_SET_CURRENT_VIEW,
  payload
})

export const setCalendarCurrentDate = (payload: string): ICalendarActions => ({
  type: CALENDAR_SET_CURRENT_DATE,
  payload
})

export const updateCalendarEvent = (payload: ICalendarUpdateEventPayload): ICalendarActions => ({
  type: CALENDAR_UPDATE_EVENT,
  payload
})


export const setCalendarWorkflowFilters = (payload: any): ICalendarActions => ({
  type: CALENDAR_SET_WORKFLOW_FILTERS,
  payload
})

export const clearCalendarWorkflowFilters = (): ICalendarActions => ({
  type: CALENDAR_CLEAR_WORKFLOW_FILTERS
})

/*****************************************/
/**************** to saga ****************/
/*****************************************/

export const getCalendarEvents = (payload: ICalendarGetEventsPayload): ICalendarActions => ({
  type: CALENDAR_GET_EVENTS,
  payload
})

export const getCalendarCalFilters = (): ICalendarActions => ({
  type: CALENDAR_GET_CAL_FILTERS
})

export const setCalendarCalFilterColor = (payload: ICalendarSetCalFilterColorPayload): ICalendarActions => ({
  type: CALENDAR_SET_CAL_FILTER_COLOR,
  payload
})


export const getCalendarWorkflowFilters = (payload: string): ICalendarActions => ({
  type: CALENDAR_GET_WORKFLOW_FILTERS,
  payload
})