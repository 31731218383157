import React, { FC } from 'react'
import './AlignControls.scss'
import { StyleButton } from '../StyleButton/StyleButton'
import { DropdownSelect } from '../DropdownSelect/DropdownSelect'
import { EditorState, Modifier } from 'draft-js';

export const styleWholeSelectedBlocksModifier = (editorState: any, style: any, removeStyles: any[] = []) => {

    let currentContent = editorState.getCurrentContent();
    let selection = editorState.getSelection();
    let focusBlock = currentContent.getBlockForKey(selection.getFocusKey());
    let anchorBlock = currentContent.getBlockForKey(selection.getAnchorKey());
    let selectionIsBackward = selection.getIsBackward();

    let changes = {
        anchorOffset: 0,
        focusOffset: focusBlock.getLength()
    }

    if (selectionIsBackward) {
        changes = {
            focusOffset: 0,
            anchorOffset: anchorBlock.getLength()
        }
    }
    let selectWholeBlocks = selection.merge(changes)
    let modifiedContent = Modifier.applyInlineStyle(currentContent, selectWholeBlocks, style);
    let finalContent = removeStyles.reduce(function (content, style) {
        return Modifier.removeInlineStyle(content, selectWholeBlocks, style);
    }, modifiedContent);
    return EditorState.push(editorState, finalContent, 'change-inline-style');
}

export const AlignControls: FC<any> = ({ onToggle, editorState }) => {

    const onSelect = (style: 'center' | 'left' | 'right' | 'justify') => () => {
        onToggle(styleWholeSelectedBlocksModifier(editorState, style, ['center', 'left', 'right', 'justify'].filter(s => s !== style)))
    }
    return <div>
        <DropdownSelect
            button={(open, toggle) => <StyleButton
                label={<i className="Icon Icon_align-center" />}
                onToggle={toggle}
                active={open}
            // style={type.style}
            />}
        >{
                (open, close) => <ul className={'Headers-List'} onClick={close}>
                    <li className={'Headers-ListItem'}>
                        <button className={'Headers-Btn'} onMouseDown={onSelect('center')}>
                            <i className="Icon Icon_align-center" />
                        </button>
                    </li>
                    <li className={'Headers-ListItem'}>
                        <button className={'Headers-Btn'} onMouseDown={onSelect('left')}>
                            <i className="Icon Icon_align-left" />
                        </button>
                    </li>
                    <li className={'Headers-ListItem'}>
                        <button className={'Headers-Btn'} onMouseDown={onSelect('right')}>
                            <i className="Icon Icon_align-right" />
                        </button>
                    </li>
                    <li className={'Headers-ListItem'}>
                        <button className={'Headers-Btn'} onMouseDown={onSelect('justify')}>
                            <i className="Icon Icon_align-justify" />
                        </button>
                    </li>
                </ul>
            }
        </DropdownSelect>
    </div>
}