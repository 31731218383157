import { IClassNameProps } from '@bem-react/core';
import { cnModal } from '../Modal.index';

export interface IHeaderProps extends IClassNameProps {
  text: string; // header text
  border: boolean;
  closeFunction?(ev: Event): void;
}

export interface IHeaderState {
  text?: string;
}

export const cnHeader = (...data: any) => {
  let name = 'Header';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnModal(name, ...data)
}