import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    sendLikeNews
} from '../../redux/actions'

import {
} from '../../redux/reducer/types'
import {
    getNewsParamsById
} from '../../redux/saga/selectors'
import { NewsContextProps } from '../../contexts/news'
import { IComponentAcceptor } from 'utils/src/ComponentInjector'
import { News as ABNews } from './ActionButton/interfaces'
// import {  } from 'utils/src/requests/models/api.learningTracks'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsActions {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & IComponentAcceptor<ABNews.NewsActions.IOwnProps>

        export interface IState { }

        export interface IOwnProps extends IClassNameProps, NewsContextProps {
            disabledAction?: string[]
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.NewsActions.IOwnProps

export const mapStateToProps = (state: any, { newsId }: IOwnProps) => ({
    enabledThanks: (state.store.appSettings?.enabledModules?.thanks as boolean),
    ...getNewsParamsById(newsId,
        // 'likeCount',
        // 'liked',
        'id',
        'statistics',
        // 'allowThanksReply',
        // 'allowReply',
        'actionButtons'
    )(state)
})


export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    sendLikeNews
}, dispatch);

////////////

export const cnClassName = cn('NewsActions');