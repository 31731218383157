import React, { FC } from 'react'
import { Skeleton } from 'uielements/src'
import './TestResults.scss'
import { cn } from '@bem-react/classname';
export const cnTestResult = cn('SkeletTestResult');
export const TestResult: FC<any> = ({ className }) => {

    return <div className={cnTestResult({}, [className])}>
        <div className="TestResult">
            <div className="ResultHeader TestResult-Header">
                <div className="ResultHeader-HeaderColumn">
                    <div className="ResultHeader-Title"><Skeleton shape="line" size="lg" style={{ width: '260px' }} /></div>
                    <div className="ResultHeader-Attempts"><Skeleton shape="line" size={"sm"} style={{ width: '100px' }} /></div>
                </div>
                <div className="ResultHeader-HeaderColumn"></div>
            </div>
            <div className="ResultBody">
                <div className="ResultBody-Summary">
                    <div className="ResultBody-SummaryColumn">
                        <ul className="TestInfo-Properties">
                            <li className="TestInfo-PropertiesItem">
                                <div className="TestInfo-PropertiesContent">
                                    <div className="TestInfo-PropertiesTitle"><Skeleton shape="line" size={"sm"} style={{ width: '200px' }} /></div>
                                    <div className="TestInfo-PropertiesData"><Skeleton shape="line" size={"sm"} style={{ width: '100px' }} /></div>
                                </div>
                            </li>
                            <li className="TestInfo-PropertiesItem">
                                <div className="TestInfo-PropertiesContent">
                                    <div className="TestInfo-PropertiesTitle"><Skeleton shape="line" size={"sm"} style={{ width: '200px' }} /></div>
                                    <div className="TestInfo-PropertiesData"><Skeleton shape="line" size={"sm"} style={{ width: '100px' }} /></div>
                                </div>
                            </li>
                            <li className="TestInfo-PropertiesItem">
                                <div className="TestInfo-PropertiesContent">
                                    <div className="TestInfo-PropertiesTitle"><Skeleton shape="line" size={"sm"} style={{ width: '200px' }} /></div>
                                    <div className="TestInfo-PropertiesData"><Skeleton shape="line" size={"sm"} style={{ width: '100px' }} /></div>
                                </div>
                            </li>
                            <li className="TestInfo-PropertiesItem">
                                <div className="TestInfo-PropertiesContent">
                                    <div className="TestInfo-PropertiesTitle"><Skeleton shape="line" size={"sm"} style={{ width: '200px' }} /></div>
                                    <div className="TestInfo-PropertiesData"><Skeleton shape="line" size={"sm"} style={{ width: '100px' }} /></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="ResultBody-SummaryColumn">
                        <table className="ResultSummaryTable ResultBody-SummaryTable">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="ResultSummaryTable-SummaryAnswers">
                                            <Skeleton shape="line" size={"sm"} style={{ width: '50px' }} />
                                        </div>
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="ResultSummaryTable-SummaryTitle">
                                            <Skeleton shape="line" size={"sm"} style={{ width: '70px' }} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="ResultSummaryTable-Value ResultSummaryTable-Value_type_success">
                                            <Skeleton shape="line" size={"sm"} style={{ width: '40px' }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="ResultSummaryTable-SummaryTitle">
                                            <Skeleton shape="line" size={"sm"} style={{ width: '70px' }} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="ResultSummaryTable-Value ResultSummaryTable-Value_type_wrong">
                                            <Skeleton shape="line" size={"sm"} style={{ width: '70px' }} />
                                            </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="ResultChart ResultBody-Chart">
                            <Skeleton shape="circle" size={'xxl'} />
                        </div>
                    </div>
                </div>
                <ul className="ResultQuestionList ResultBody-QuestionList">
                    <li className="ResultQuestionItem ResultQuestionList-Item">
                        <Skeleton shape="circle" size={'sm'} />
                        <div className="ResultQuestionItem-QuestionContent">
                            <div className="ResultQuestionItem-QuestionText">
                                <Skeleton shape="line" size={"sm"} style={{ width: '100px' }} />
                            </div>
                            <div className="ResultQuestionItem-Answers">
                                <Skeleton shape="line" size={"sm"} style={{ width: '70px' }} />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="ResultActions TestResult-Actions"></div>
        </div>
    </div>
}

