
import { cn } from '@bem-react/classname';
import { ISliderItemData } from 'utils/src/requests/models/api.slider'
import { HTMLAttributes } from 'react';

export interface ISliderLinkItemProps extends HTMLAttributes<HTMLImageElement> {
  idx: number,
  imageNum: number, 
  imgUrl: string, 
  length: number, 
  edit: boolean, 
  active?: any,
  type?: string
  href?: string
  openInNewTab?: boolean;
}


export const cnSliderLink = cn('SliderLink'); 