import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { resetQuiz } from 'redux/actions/Quiz'
import { IStoreAppSetting } from 'models/store/appSettings';

export interface IQuizFinish extends IQuizFinishStateProps, IQuizFinishDispatchProps {

}

export const mapStateToProps = (state: IState) => {
  const data = state.quiz.quiz
  return {
    result: data.result,
    currencyNames: state.store.appSettings as IStoreAppSetting,
    rewardIsPosted: data.rewardIsPosted
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  resetQuiz
}, dispatch);

export type IQuizFinishStateProps = ReturnType<typeof mapStateToProps>
export type IQuizFinishDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnQuizFinish = cn('QuizFinish')