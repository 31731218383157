import React, { FC } from 'react'
import { cn } from '@bem-react/classname';
// import * as utils from 'utils/src/utils';
import { IButtonProps } from 'uielements/src/Button/Button.index'
import { confirm } from 'utils.project/utils.project'

export interface IWithLoadSpin {
    confirmTitle?: string;
    confirmText?: string;
    acceptBtnText?: string;
    confirmBtnText?: string;
    cancleBtnText?: string;
    onConfirm?: (event: any) => void;
    onCancel?: () => void;
    onAccept?: () => void;
    /**
     * если вернуть true, то конфирм вызван не будет
     */
    onClick?: (e: any) => boolean | undefined;
}

function withConfirmDialog<T extends IButtonProps>(Component: React.ComponentType<T>): FC<IWithLoadSpin & T> {
    return ({ 
        confirmTitle, 
        onConfirm, 
        onClick, 
        onCancel,
        onAccept,
        confirmText,
        confirmBtnText,
        acceptBtnText,
        cancleBtnText,
        ...props 
    }) => {
        const onClickButton = (e: any) => {
            let disableConfirm: boolean | undefined = false
            if (onClick) {
                disableConfirm = onClick(e)
            }
            (onConfirm && !disableConfirm) && confirm({
                title: confirmTitle,
                text: confirmText,
                confirmBtnText,
                acceptBtnText,
                cancleBtnText,
                onConfirm,
                onAccept,
                onCancel
            })
        }
        return <Component
            {...props as T}
            onClick={onClickButton}
        />
    }
}
export default withConfirmDialog