import React from 'react';
import addVideo from './video/modifiers/addVideo';
import DefaultVideoComponent from './video/components/DefaultVideoComponent';
import * as types from './video/constants';
import { defaultTheme } from './theme';
import linkStrategy from './linkStrategy'
import videoUtils from './video/utils'
import { EditorState, convertFromRaw, convertToRaw, RichUtils, Modifier } from 'draft-js';


export const removeBlock = (key: any, length: any, editorState: any) => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const selectionOfAtomicBlock = selection.merge({
    anchorKey: key,
    anchorOffset: 0,
    focusKey: key,
    focusOffset: length,
  });

  // override the entity data and wipe it from the block. This prevents the data from being tacked onto another block
  const contentStateWithoutEntity = Modifier.applyEntity(contentState, selectionOfAtomicBlock, null);
  const editorStateWithoutEntity = EditorState.push(editorState, contentStateWithoutEntity, 'apply-entity');

  // now delete the block. Purge it from existance so that it may never harm your beautiful editor again
  const contentStateWithoutBlock = Modifier.removeRange(contentStateWithoutEntity, selectionOfAtomicBlock, 'backward');
  return EditorState.push(editorStateWithoutEntity, contentStateWithoutBlock, 'remove-range');
}


export const removeRenge = (from: number, to: number, blockKey: string, editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const selectionOfAtomicBlock = selection.merge({
    anchorKey: blockKey,
    anchorOffset: from,
    focusKey: blockKey,
    focusOffset: to,
  });

  // override the entity data and wipe it from the block. This prevents the data from being tacked onto another block
  const contentStateWithoutEntity = Modifier.applyEntity(contentState, selectionOfAtomicBlock, null);
  const editorStateWithoutEntity = EditorState.push(editorState, contentStateWithoutEntity, 'apply-entity');

  // now delete the block. Purge it from existance so that it may never harm your beautiful editor again
  const contentStateWithoutBlock = Modifier.removeRange(contentStateWithoutEntity, selectionOfAtomicBlock, 'backward');
  return EditorState.push(editorStateWithoutEntity, contentStateWithoutBlock, 'remove-range');
}

const videoPlugin = (config: any = {}) => {
  const theme = config.theme ? config.theme : defaultTheme;
  let Video = config.videoComponent || DefaultVideoComponent;
  if (config.decorator) {
    Video = config.decorator(Video);
  }
  const ThemedVideo = (props: any) => <Video {...props} theme={theme} />;
  return {
    blockRendererFn: (block: any, { getEditorState }: any) => {
      if (block.getType() === types.ATOMIC) {
        // TODO subject to change for draft-js next release
        const contentState = getEditorState().getCurrentContent();
        const enityKey = block.getEntityAt(0)
        if (!enityKey)
          return;
        const entity = contentState.getEntity(enityKey);
        const type = entity.getType();
        const { data } = entity.getData();
        if (type === types.VIDEOTYPE) {
          return {
            component: ThemedVideo,
            editable: false,
            props: {
              data,
            },
          };
        }
      }

      return null;
    },
    decorators: [
      {
        strategy: linkStrategy,
        component: (props: any) => {
          const editorState = props.getEditorState()

          const newEditorState = removeRenge(props.start, props.end, props.blockKey, editorState)//editorState//removeBlock(props.blockKey, props.end, editorState)

          if (videoUtils.isYoutube(props.decoratedText)) {
            const youtube = videoUtils.getYoutubeSrc(props.decoratedText)
            config.onChange(props.addVideo(newEditorState, {
              src: props.decoratedText,
              previewUrl: `https://www.youtube.com/embed/${youtube.srcID}?showinfo=0`,
              id: youtube.srcID
            }))
          }
          if (videoUtils.isVimeo(props.decoratedText)) {
            const vimeo = videoUtils.getVimeoSrc(props.decoratedText)
            fetch(`https://vimeo.com/api/oembed.json?url=${props.decoratedText}`)
              .then(response => response.json())
              .then((value: any) => {
                const thumbnailId = videoUtils.getVimeoThumbnailId(value.thumbnail_url)
                const previewUrl = `https://i.vimeocdn.com/video/${thumbnailId}_1280.png`
                config.onChange(props.addVideo(newEditorState, {
                  src: props.decoratedText,
                  previewUrl: previewUrl,
                  id: vimeo.srcID
                }))
              }).catch((e) => {
                config.onChange(props.addVideo(newEditorState, {
                  src: props.decoratedText,
                  id: vimeo.srcID
                }))
              })
          }
          if (videoUtils.isVideoFile(props.decoratedText)) {
            config.onChange(props.addVideo(newEditorState, {
              src: props.decoratedText,
              previewUrl: `/Content/img/playbutton.png`,
              id: props.decoratedText.replace('/','')
            }))
          }

          return <div>Загрузка...</div>
        },
        props: {
          addVideo
        }
      },
    ],
    addVideo,
    types,
  };
};

export default videoPlugin;
