import React, { FC } from 'react'
import {
    cnClassName,
    IPresenterProps,
    IOwnProps,
    IState
} from './interfaces'
import './style.scss'
import Item from './item'

export const Presenter: FC<IPresenterProps> = ({
    className,
    units,
    active,
    onSelect
}) => {

    return <div className={cnClassName({}, [className])}>
        {units.map((unit: any) => <Item itemClick={onSelect} key={unit.id} unit={unit} active={active.includes(unit.id)}/>)}
    </div>
}

export default Presenter;
