import Component, { SkillsNew } from './SkillsNew';
import {
    SkillsWidgetNew,
    cnClassName,
} from './SkillsNew.interface';


export {
    cnClassName,
    SkillsNew
}

export type IComponentProps = SkillsWidgetNew.IOwnProps;
export type IComponentPresenterProps = SkillsWidgetNew.IPresenterProps;


export default Component