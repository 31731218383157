import React, { FC, forwardRef } from 'react'
import { ILineInputProps } from './LineInput.index'
import './LineInput.css'

export const LineInputPresenter: FC<ILineInputProps> = ({ className, value, onChange, ...props }, forwardRef) => {
    return <input
        ref={forwardRef}
        type='text'
        className={`LineInput ${className}`}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)} {...props} />
}

export const LineInput = forwardRef<HTMLInputElement, ILineInputProps>(LineInputPresenter);