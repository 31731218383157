import { ReactNode } from 'react';
import { IdeasendNews } from './Ideasend.type'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { NewsContextProps } from 'News/contexts/news'
import {
    getNewsParamsById
} from 'News/redux/saga/selectors'
export interface IIdeasendPropsType extends NewsContextProps {
}

export type TStateProps = ReturnType<typeof mapStateToProps>

export interface IIdeasendStateType {
    data: IdeasendNews;
}

const newsParams = [
    'text',
] as const

export const mapStateToProps = (state: any, { newsId }: IIdeasendPropsType) => ({
    userData: getCurrentUser(state),
    ...getNewsParamsById<IdeasendNews, typeof newsParams[number]>(newsId, newsParams)(state)
})
