import React, { FC, useEffect, useState } from 'react'
import { cnStudentList, mapDispatchToProps, mapStateToProps } from './StudentList.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './StudentList.scss'
import { Button } from 'uielements/src';
import { Item } from './Item/Item'
import { BasicItem } from './BasicItem/Item'
import Search from './Filters/Search'
import { Sessions } from './Filters/Sessions/Sessions'
import { Status } from './Filters/Status/Status'
import Progress from './Filters/Progress'
import Units from './Filters/Units'
import CourseTabs, { ITabType } from '../../components/Tabs'
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import ListPlaceholder from '../../components/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { Deleted } from './Filters/Deleted/Deleted'

const LoadButton = withLoadSpin(Button)

export const StudentListPresenter: FC<any> = ({ className, cid, course, loadCourseStudents, studentesIds = [], isFinished, isLoading, filters, loadUsersInCourse }) => {
    const TABS = [
        { value: 'started', content: tT('student_list.tabs.started.1') },
        { value: 'all', content: tT('student_list.tabs.all.1') }
    ]
    const [tab, setTab] = useState<ITabType>(TABS[0]);

    useEffect(() => {
        loadMore(true)
    }, [filters])
    const loadMore = (clean = false) => tab.value === 'started' ? loadCourseStudents(cid, filters, clean) : loadUsersInCourse(cid, filters, clean)
    const triggerRef = useInfiniteScroll({
        loadMore,
        isLoading,
        isFinished,
    });

    const unitCount = course.courseUnits.length;

    const selectTab = (tab: ITabType) => {
        tab.value === 'started' ? loadCourseStudents(cid, filters, true) : loadUsersInCourse(cid, filters, true)
        setTab(tab)
    }
    // if (!studentesIds) return <div>NO DATA</div>

    return <div className={cnStudentList({}, [className])}>
        <div className={cnStudentList('Aside')}>
            {tab.value === 'started' ? <>
                <Status cid={cid} />
                <Progress />
                <Sessions cid={cid} />
                <Units cid={cid} />
            </> : <>
                    <Deleted cid={cid}/>
                </>}
        </div>
        <div className={cnStudentList('Section')}>
            <div className={cnStudentList('Header')}>
                {tab.value === 'started' && <Search isLoading={isLoading} className={cnStudentList('Search')} />}
            </div>
            <CourseTabs className={cnStudentList('Tabs')} onChange={selectTab} selected={tab} tabs={TABS} />

            <ListPlaceholder
                className={cnStudentList('Placeholder')}
                isFinished={isFinished}
                isLoading={isLoading}
                itemsCount={studentesIds.length}
                titleEmpty={tT('student_list.empty.title.1')}
                textEmpty={tab.value === 'started' ? tT('student_list.empty.text.started.1') : tT('student_list.empty.text.1')}
                textLoad={tT('student_list.load.text.1')}
            >
                <div className={cnStudentList('List')}>
                    {studentesIds.map((uid: string) =>
                        tab.value === 'started' ?
                            <Item key={uid} cid={cid} uid={uid} unitCount={unitCount} className={cnStudentList('Item')} /> :
                            <BasicItem key={uid} cid={cid} uid={uid} unitCount={unitCount} className={cnStudentList('Item')} />)}
                </div>

                {!isFinished && (
                    <LoadButton
                        forwardedRef={triggerRef}
                        isLoading={isLoading}
                        className={cnStudentList('Trigger')}
                        onClick={isLoading ? void 0 : () => loadMore()}
                    >
                        {tT('student_list.load.more.1')}
                    </LoadButton>)}
            </ListPlaceholder>
        </div>
    </div>
}

export const StudentList = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(StudentListPresenter));
