import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    updateTrack
} from '../../redux/actions/TracksManage'
import {

} from '../../redux/sagas/TracksManage/selectors'
import { ILearningTrack } from 'utils/src/requests/models/api.learningTracks'

///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps<{ id: string }>

export interface IState { }

export interface IOwnProps extends IClassNameProps {

}

export interface IStateProps {

}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////


export const mapStateToProps = (state: any, props: IOwnProps) => ({

})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    updateTrack
}, dispatch);

////////////

export const cnClassName = cn('TrackInfo');