import React, { FC, useContext, memo, ComponentType } from 'react';
import { keys, pick } from 'lodash';
import { NewsCreateContext, baseNewsCreateContext } from './Creator.constants';

type NewsCreateContextProps = typeof baseNewsCreateContext;

const defaultNewsCreateDeps = Object.typedKeys(baseNewsCreateContext);

export function useNewsCreateContext(deps: (keyof NewsCreateContextProps)[] = defaultNewsCreateDeps) {
    return pick(useContext(NewsCreateContext), deps);
};

export function withNewsCreateContext<P extends NewsCreateContextProps>(Component: ComponentType<P>, deps: (keyof NewsCreateContextProps)[] = defaultNewsCreateDeps) {
    const Wrapped: FC<Omit<P, keyof NewsCreateContextProps>> = (props) => { 
        const value = useNewsCreateContext(deps);
        return <Component {...{ ...props as any, ...value }} />;
    }
    return memo(Wrapped);
};