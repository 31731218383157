import { IEditorProps } from './Editor.index'
import React, { memo, useState } from 'react'
import './Editor.scss';
import { Avatar } from 'uielements/src/Avatar/Avatar'
import i18n from 'localizations/i18n';
import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'
import { isNull } from 'lodash'
import Rating from 'uielements/src/MaterialElements/RatingWithValue'


export const Editor: React.FC<IEditorProps> = memo(({
    children,
    data,
    onChangeText,
    avatarSize = 40,
    placeholder,
    send,
    onChangeRating,
    ...props
}) => {
    const [hover, setHover] = useState(-1);

    return <div className={'Reply-Editor'}>

        {data.user.userPhotoUrl && <Avatar
            imgUrl={data.user.userPhotoUrl}
            imgId={data.user.imgId}
            name={data.user.displayName}
            size={avatarSize} />}
        <div className={'Reply-Editor-EditorBox'}>
            {(data.ratingEnabled && onChangeRating) && <div className={'Reply-Editor-RatingBox'}>
                <Rating
                    name={'rating'}
                    precision={0.5}
                    title={''}
                    value={data.contentRating}
                    onChange={onChangeRating}
                />
            </div>}
            <PryanikyEditor
                onComplete={send}
                // getClassRef={refGetter}
                //ref={forwardRef as any}
                // users={true}
                // tags={true}
                noIcon={true}
                onChange={onChangeText}
                // className={''}
                // icon={'edit'}
                value={data.text}
                disableToolbar={true}
                placeholder={placeholder || i18n.t('leave a comment')}
            />
        </div>
        {children}
    </div>
})