import React, { FC, useEffect, useMemo, useState, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { ReplyContext, ReplyContextProps } from '../../contexts/reply'
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import ReplyView from '../ReplyView'

type IPresenterProps = News.ReplyItem.IPresenterProps
type IOwnProps = News.ReplyItem.IOwnProps
type IDispatchProps = News.ReplyItem.IDispatchProps
type IStateProps = News.ReplyItem.IStateProps
type IState = News.ReplyItem.IState


export const Presenter: FC<IPresenterProps> = ({
    newsId,
    id,
    reply,
    withRating = false
}) => {
    const [edit, setEdit] = useState(false)

    const context = useMemo<ReplyContextProps>(() => {
        return {
            reply,
            newsId,
            replyId: id,
            replyEdit: edit,
            setEdit,
            avatarSize: 40,
            withRating
        }
    }, [newsId, id, reply, edit, withRating])

    return <ReplyContext.Provider value={context}>
        <ReplyView/>
    </ReplyContext.Provider>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withComponentEnjector<IPresenterProps>(memo(Presenter), ['reply_types']));
