import React, { useEffect } from 'react';
import { IShopWidgetProps } from './ShopMuiMiniWidget.index';
import { currentUserMinusMyThanksCount } from 'utils/src/CommonRedux/users/actions'
import { bindActionCreators, Dispatch, Action } from 'redux';
import { ShopItem } from '../Item/Shop-Item';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencyNames } from 'utils/src/CommonRedux/base/selectors';
import { getShopCategoryProducts } from 'redux/shop/selectors';
import { useShopRequestOptions } from '../ShopList/ShopListMui.hooks';
import { getShopProducts } from 'redux/shop/actions';
import { Box, styled } from 'muicomponents/src';


export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: any) => bindActionCreators({
  currentUserMinusMyThanksCount
}, dispatch)

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  '.MuiPaper-root': {
    width: 'auto'
  }
})

export const ShopMuiMiniWidget = (props: IShopWidgetProps) => {

  const currencyFormats = useSelector(getCurrencyNames)
  const myThanksCount = props.authUser.extData.mythanksCount
  const products = useSelector(getShopCategoryProducts)?.data

  const { requestOptions } = useShopRequestOptions(5, 0)

  const dispatch = useDispatch()
  const loadProducts = () => {
    dispatch(getShopProducts(requestOptions))
  }

  useEffect(() => {
    loadProducts()
  }, [dispatch])

  if (!products) return null

  return <StyledBox>
    {
      products.map(product => {
        return <ShopItem
          key={product.id}
          data={product}
        />
      })
    }
  </StyledBox>
}

