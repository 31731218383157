import * as utils from 'utils/src/utils';
// import { fileModelConverter } from 'blocks/Photoalbums/Photoalbums.utils'
import { denormalize, normalize, schema } from 'normalizr';
import { createFile as cFile } from 'blocks/Files/Files.utils'
import actions from 'redux/actionsTypes/Files';
import uuid from 'uuid/v1';
const zeroID = "00000000-0000-0000-0000-000000000000"

/*export const photosSchema = () => {
    const comment = new schema.Entity('files', {}, {
        idAttribute: 'id', processStrategy: (value, parent, key) => {
            return { ...value };
        }
    });
    return [comment];
}


export const normalizeData = (data: any) => {
    const normalizedData = normalize(data, photosSchema());
    return normalizedData;
}

export const denormalizeData = (fields: any) => {
    const denormalizedData = denormalize(fields.result, photosSchema(), fields.entities);
    return denormalizedData;
}*/

export const trancuteFolders = () => ({
    type: actions.TRUNCATE_FOLDERS,
})

export const readFolders = (payload: any) => ({
    type: actions.READ_FOLDERS,
    payload
})

export const createFile = (payload: any) => ({
    type: actions.CREATE_FILE,
    payload
})

export const readGroupFiles = (payload: any) => ({
    type: actions.READ_GROUP_FILES,
    payload
})

export const changeFile = (fid: any, file: any) => ({
    type: actions.CHANGE_FILE,
    payload: {
        fid, file
    }
})

export const selectFile = (gid: string, fid: string) => ({
    type: actions.SELECT_FILE,
    payload: {
        gid, fid
    }
})

export const deleteFileAction = (gid: string, fid: string) => ({
    type: actions.DELETE_FILE,
    payload: {
        gid, fid
    }
})

export const selectAllFiles = (payload: string) => ({
    type: actions.SELECT_ALL_FILES,
    payload
})


export const truncateGroup = (gid: any) => ({
    type: actions.TRUNCATE_GROUP_FILES,
    payload: gid
})

export const setFolder = (gid: number, folderId: string) => ({
    type: actions.SET_FOLDER,
    payload: { folderId, gid }
})


export const setMoveFiles = (gid: number, folderId: string) => ({
    type: actions.SET_MOVE_FILES,
    payload: { gid, folderId }
})

export const setFileName = (fid: string, name: string) => ({
    type: actions.CHANGE_FILE,
    payload: {
        fid,
        file: {
            name
        }
    }
})



export const createFolder = (gid: number, name: string, parent?: string) => {
    return (dispatch: any, getStore: any) => {
        const store = getStore()
        const folder = store.files.groups[gid].currentFolder
        const currentFile = { ...cFile(gid, name), isFolder: true }
        dispatch(createFile({ file: currentFile, gid }))
        utils.API.files.createFolder(gid, currentFile.id, name, folder).r.then((d: any) => {

        })
    }
}

export const renameFolder = (gid: number, name: string, id: string) => {
    return (dispatch: any, getStore: any) => {
        dispatch(setFileName(id, name))
        utils.API.files.renameFolder(gid, name, id).r.then((d: any) => {

        })
    }
}

export const moveFile = (gid: number, folderId: string, force: boolean = false) => {
    return (dispatch: any, getStore: any) => {
        const store = getStore()
        const moveng = store.files.groups[gid].selected
        let moveTo = folderId
        if (!force) {
            const currentFile = store.files.files[folderId] || {}
            moveTo = (currentFile.isBack) ? currentFile.parentFolder : folderId
        }
        dispatch(setMoveFiles(gid, moveTo))
        moveng.forEach((fid: string) => {
            if (folderId !== fid) {
                if (store.files.groups[gid].currentFolder !== fid) {
                    utils.API.files.moveFile(fid, moveTo).r.then((d: any) => {

                    })
                }
            }
        })

    }
}

export const loadDirList = (gid: number, from: number = 0, folderId: string = zeroID) => {
    return (dispatch: any, getStore: any) => {
        dispatch(trancuteFolders())
        const request = utils.API.files.list(from, 20, { gid, folder: folderId, type: 'folder' });
        request.r.then((d: any) => {
            if (utils.checkResponseStatus(d)) {

                dispatch(readFolders({ folders: d.data, currentFolder: folderId }))
            } else {
            }
        });
    }
}

export const loadList = (gid: number, from: number = 0, filters: any = {}, folderId: string = zeroID) => {
    return (dispatch: any, getStore: any) => {
        const store = getStore()
        const folder = store.files.groups[gid] ? store.files.groups[gid].currentFolder : undefined;
        const currentSearch = store.files.filters.search;
        if (folderId !== folder) dispatch(setFolder(gid, folderId || zeroID))
        dispatch({ type: actions.IS_LOADING_GROUP_FILES, payload: { gid } })
        let skipCount = from;
        if (filters.search !== currentSearch) {
            skipCount = 0
            dispatch(truncateGroup(gid))
        }

        const request = utils.API.files.list(skipCount, 20, { gid, search: filters.search, folder: folderId });
        request.r.then((d: any) => {
            if (utils.checkResponseStatus(d)) {
                let files: any = {};
                let group: any = {
                    files: [],
                    // selected: [],
                    isLoading: false,
                    isFinished: d.data < 20,
                };
                let isBack = false;
                d.data.map((file: any) => {
                    isBack = file.name === '..'
                    group.files.push(file.id);
                    files[file.id] = { ...file, groupId: gid, isBack };
                });
                dispatch(readGroupFiles({ files, group, gid, filters, skipCount }))
            } else {
            }
        });
    };
}

export const uploadFiles = (gid: any, files: any[], folderId?: string) => {
    return (dispatch: any) => {
        files.forEach((p: any) => {
            const currentFile = { ...cFile(gid, p.name), isUploading: false }
            const file = p;
            file.gId = gid;
            file.folderId = folderId;
            utils.API.files.upload(file, {
                loadStart: (event, file) => {
                    currentFile.isUploading = true;
                    dispatch(createFile({
                        gid,
                        file: currentFile
                    }))
                },
                loadProgress: (event, file) => {
                    dispatch(changeFile(currentFile.id, currentFile))
                },
                loadFinish: (d, file) => {
                    dispatch(deleteFileAction(gid, currentFile.id))
                    dispatch(createFile({
                        gid,
                        file: { ...currentFile, ...((d.data && d.data[0]) || {}) }
                    }))
                },
                loadError: () => { }
            })
        })
    }
}


export const deleteFile = (gid: string, fid: string) => {
    return (dispatch: any) => {
        dispatch(deleteFileAction(gid, fid))
        utils.API.files.remove(fid).r.then((d: any) => {
            if (utils.checkResponseStatus(d)) {

            } else {
            }
        })
    }
}

export const deleteFileMultiple = (gid: string, fids: string[]) => {
    return (dispatch: any) => {
        fids.forEach(fid => {
            dispatch(deleteFileAction(gid, fid))
            utils.API.files.remove(fid).r.then((d: any) => {
                if (utils.checkResponseStatus(d)) {

                } else {
                }
            })
        })
    }
}