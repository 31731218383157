import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { transitionToUnit } from "../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';

export interface ILessonsListProps extends IClassNameProps, ILessonsListStateProps, IDispatchProps,
    RouteComponentProps, ILessonsListDispatchProps {
    tag?: 'div';

    // unitContent: any
}

export interface ILessonsListState {
    // courses: ICourse[];
    // isFinished: boolean;
}

export interface ILessonsListStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    logs: state.LMS.unitsLogs
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    jumpToUnit: transitionToUnit
}, dispatch);

export type ILessonsListDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnLessonsList = cn('LessonsList');