import {
  takeEvery,
  put,
  takeLeading,
  takeLatest,
  throttle,
  fork,
  all
} from 'redux-saga/effects';
import {
    call,
    select,
} from 'utils/src/saga.effects';

import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'

import { createDefault } from '../../types'

import {
  changeCurrentThanksCount
} from 'utils/src/CommonRedux/users/actions'

import { v1 as uuid } from 'uuid'

import actions from '../actionsTypes';


import * as ACT from '../actions';

import queryString from 'query-string';

import * as SEL from './selectors'


import { SagaAction } from '../actions/types';

import {
  normalizeKPI
} from './utils'

import {
  API,
  objectArrayToMap
} from 'utils/src/utils'

import { omit, xor } from 'lodash'

import i18n from 'localizations/i18n';

import { toast } from 'react-toastify';

import { confirmProise } from 'uielements/src/Confirm/Confirm'

const handleLoadKPIList = function* handleLoadKPIList({ payload }: SagaAction.ALoadKPIForUser) {
  try {
    const { userId } = payload

    yield put(ACT.changeField({
      field: 'isLoading',
      value: true
    }))

    const result = yield* call(API.kpi.getForUser, userId) as any;

    if (result.error_code === 0) {
      yield put(ACT.setKPIList({ ...normalizeKPI(result.data.data), userId }))
      yield put(ACT.changeField({ field: 'allowAdd', value: result.data.allowAdd }))

    } else {

    }

  } catch (error) {
    console.warn(error)

  }

  yield put(ACT.changeField({
    field: 'isLoading',
    value: false
  }))

  return 0;
}



const handleChangeKPIItem = function* handleChangeKPIItem({ payload }: SagaAction.AChangeKPIItem) {
  try {
    const { id, fields, userId } = payload

    yield put(ACT.pathKPIItem({
      ...fields,
      id,
      meta: {
        isCreating: false,
        isEdit: false
      }
    }))

    const item = yield* select(SEL.getItemById(id))

    const result = yield* call(API.kpi.setForUser, userId, omit(item, ['meta']) as any) as any;

    if (result.http_error) {
      toast.error('server error: ' + result.http_error)
    } else if (result.error_code !== 0) {
      toast.error(result.error_code)
      toast.error(result.error_text)
    }

  } catch (error) {
    console.warn(error)

  }

  return 0;
}

const handleAddKPIItem = function* handleAddKPIItem({ payload }: SagaAction.AAddKPIItem) {
  try {
    const item = createDefault()

    yield put(ACT.addKPIItem({
      ...normalizeKPI([item]),
      userId: payload
    }))

  } catch (error) {
    console.warn(error)

  }

  return 0;
}


const handleDeleteKPIItem = function* handleDeleteKPIItem({ payload }: SagaAction.ADeleteKPIItem) {
  try {
    const { id, userId } = payload

    yield put(ACT.dropKPIItem(payload))

    const result = yield* call(API.kpi.deletFromUser, id, userId)

    if (result.error_code !== 0) {
      toast.error(result.error_code)
      toast.error(result.error_text)
    }

  } catch (error) {
    console.warn(error)

  }

  return 0;
}


///////////




/**
 * module root saga
 */
const root = function* root() {
  yield takeLeading(actions.LOAD_KPI_FOR_USER, handleLoadKPIList)
  yield takeLeading(actions.CHANGE_KPI_ITEM, handleChangeKPIItem)
  yield takeLeading(actions.CREATE_ITEM, handleAddKPIItem)
  yield takeLeading(actions.DELETE_KPI_ITEM, handleDeleteKPIItem)

};

/**
 * export root saga
 */
export default root;