import { cn } from '@bem-react/classname';
import { IShopItem } from 'utils/src/requests/models/api.shop';

import { RouteComponentProps } from 'react-router';

export interface IShopProps extends RouteComponentProps {
  widgets: any;
  changeVm: (path: string, value: any) => void;
  currencyCount: number;
}

export interface IShopState {
  loading: boolean;
  isFinished: boolean;
  searchQuery: string;
  data: IShopItem[];
}

export const cnShop = cn('Shop');
