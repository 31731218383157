import { v1 as uuid } from 'uuid';
import {IWidgetData} from 'utils/src/requests/models/api.widgets';
import i18n from 'localizations/i18n';
import { GUID_EMPTY } from 'utils/src/constants.prn';

export interface ILayoutTypeProps {
  name: string;
  columns?: IColumn[];
}

export interface IColumnProps {
  items?: IWidget[];
  styles?: {[s: string]: any};
}

export interface ILayoutType extends ILayoutTypeProps {
  columns: IColumn[];
}

export interface IColumn extends IColumnProps {
  id: string;
  title: string | null;
  icon: string | null;
  items: IWidget[];
}

export interface IWidget {
  blockName?: string | null;
  colId?: number;
  data?: any | null;
  description?: string | null;
  displayName?: string;
  hasSettings?: boolean;
  id?: string;
  imgId?: string;
  loaded?: boolean;
  name?: string;
  noData?: boolean;
  pos?: number;
  sdata?: any | null;
  serverData?: any | null;
  userAccessFullList?: any | null;
  userAccessList?: any | null;
  userAdminFullList?: any | null;
  userEditFullList?: any | null;
  userEditList?: any | null;
  [s: string]: any;
}

export const generateLayoutType = (data: ILayoutTypeProps) => {
  const rez: ILayoutType = {
    name: data.name || 'one',
    columns: data.columns || []
  }
  return rez;
}

export const generateColumn = (data: IColumnProps) => {
  const rez: IColumn = {
    id: uuid(),
    title: null,
    icon: null,
    items: data.items || []
  }
  if(data.styles) rez.styles = data.styles;
  return rez;
}

export const generateWidget = (widgetProps: IWidget) => {
  let normalizedWidgetProps: IWidgetData = {
    blockName: widgetProps.blockName || null,
    colId: widgetProps.colId || 1,
    data: widgetProps.data || null,
    description: widgetProps.description || 'frontEndGeneratedWidget',
    displayName: widgetProps.displayName || 'GeneratedWidget',
    hasSettings: widgetProps.hasSettings || false,
    id: widgetProps.id || uuid(),
    imgId: widgetProps.imgId || GUID_EMPTY,
    loaded: widgetProps.loaded || false,
    name: widgetProps.name || 'pryaniky/emptyWidget',
    noData: widgetProps.noData || false,
    pos: widgetProps.pos || 1,
    sdata: widgetProps.sdata || null,
    serverData: widgetProps.serverData || null,
    userAccessFullList: widgetProps.userAccessFullList || null,
    userAccessList: widgetProps.userAccessList || null,
    userAdminFullList: widgetProps.userAdminFullList || null,
    userEditFullList: widgetProps.userEditFullList || null,
    userEditList: widgetProps.userEditList || null,
  };
  normalizedWidgetProps = {
    ...widgetProps,
    ...normalizedWidgetProps
  }
  return normalizedWidgetProps;
};

export const generateWidgetv2 = (widgetProps: IWidget) => {
  let normalizedWidgetProps = {
    id: widgetProps.id || uuid(),
    type: widgetProps.type || 'pryaniky/emptyWidget',
    title: widgetProps.title || 'GeneratedWidget',
    description: widgetProps.description || 'frontEndGeneratedWidget',
    iconUrl: widgetProps.iconUrl || null,
    data: widgetProps.data || null,
    settings: widgetProps.settings || undefined,
  };
  normalizedWidgetProps = {
    ...widgetProps,
    ...normalizedWidgetProps
  }
  return normalizedWidgetProps;
};


export const allWidgets = [
  generateWidgetv2({
    name: 'pryaniky/timeline',
    displayName: i18n.t('widgets.name.timeline')
  }),
  generateWidgetv2({
    name: 'pryaniky/virtcurrency',
    displayName: i18n.t('widgets.name.virtcurrency')
  }),
  generateWidgetv2({
    name: 'pryaniky/badges',
    displayName: i18n.t('widgets.name.badges')
  }),
  generateWidgetv2({
    name: 'pryaniky/filter',
    displayName: i18n.t('widgets.name.filter')
  }),
  generateWidgetv2({
    name: 'pryaniky/groups',
    displayName: i18n.t('widgets.name.groups')
  }),
  generateWidgetv2({
    name: 'pryaniky/list',
    displayName: i18n.t('widgets.name.list')
  }),
  generateWidgetv2({
    name: 'pryaniky/news',
    displayName: i18n.t('widgets.name.news')
  }),
  generateWidgetv2({
    name: 'pryaniky/quests',
    displayName: i18n.t('widgets.name.quests')
  }),
  generateWidgetv2({
    name: 'pryaniky/ratings',
    displayName: i18n.t('widgets.name.ratings')
  }),
  generateWidgetv2({
    name: 'pryaniky/shop',
    displayName: i18n.t('widgets.name.shop')
  }),
  generateWidgetv2({
    name: 'pryaniky/textblock',
    displayName: i18n.t('widgets.name.textblock')
  }),
  generateWidgetv2({
    name: 'pryaniky/users',
    displayName: i18n.t('widgets.name.users')
  })
]