import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { setStudentsFilters } from '../../../../redux/actions/LMSTeacherOffice'
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace LMSTO {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace CommentsLent {
        // eslint-disable-next-line @typescript-eslint/no-namespace
        export namespace UnitsFilter {

            export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

            export interface IState { }

            export interface IOwnProps extends IClassNameProps {
                currentData?: string[];
                units: any[]
                setData: (value: any) => void;
            }

            export interface IStateProps {
                
            }

            export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

            ////////////
        }
    }
}
////////////
export const mapStateToProps = (state: any, props: LMSTO.CommentsLent.UnitsFilter.IOwnProps) => ({

})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: LMSTO.CommentsLent.UnitsFilter.IOwnProps) => bindActionCreators({
    
}, dispatch);

export const cnClassName = cn('SessionsSearch');
// export default LMSTO