import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
// import i18n from '../localizations/i18n';
import { userTabs, userNewTabs } from './__tabs';

interface IUser {
  info: string;
  tabs: string;
  timeline: string;
}

export const userNew = (props: { [s: string]: any }): IWidget<IColumn[]> => {
  const ids = guids<IUser>(['info', 'tabs', 'timeline']);
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            id: ids.info,
            type: widgets.types.avatarBlock,
          }),
          generateWidget({
            type: widgets.types.users + '/subscribers',
          }),
          generateWidget({
            type: widgets.types.users + '/subscriptions',
          }),
          generateWidget({ 
            type: widgets.types.groupsNew,
          }),
        ],
        styles: {
          width: '33%',
        },
      }),
      generateColumn({
        items: [
          generateWidget({
            id: ids.tabs,
            type: widgets.types.tabs,
            data: [
              userNewTabs.about(),
              userTabs.feed(),
              userNewTabs.badges(),
              userNewTabs.ratings(),
              userNewTabs.usergroups(),
              userTabs.rewards(),
              userNewTabs.currencyTimeline(),
            ],
          }),
        ],
        styles: {
          width: '66%',
        }
      })
    ],
  });
}