import { ACTION } from 'utils/src/CommonRedux/actionsTypes';


let actions: {
    //saga
    EVENT_PARTICIPANT: 'EVENT_PARTICIPANT',
    LOAD_PARTICIPANT: 'LOAD_PARTICIPANT',
    //reducer
    SET_MEETING: 'SET_MEETING',

    //common
} = {
    //saga
    EVENT_PARTICIPANT: 'EVENT_PARTICIPANT',
    LOAD_PARTICIPANT: 'LOAD_PARTICIPANT',

    //reducer
    SET_MEETING: 'SET_MEETING',

    //common
}


export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, 'NEWS_EVENT') }), {} as typeof actions);