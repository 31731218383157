import { ReactNode } from 'react';
import { ITasksNews } from './Tasks.type';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { NewsContextProps } from 'News/contexts/news'
import {
  getNewsParamsById
} from 'News/redux/saga/selectors'
export interface ITasksProps extends NewsContextProps<ITasksNews> {

}

export type TStateProps = ReturnType<typeof mapStateToProps>
export interface ITasksState {
}

const newsParams = [
  'id',
  'forms',
  'text',
  'header',
  'expires'
] as const

export const mapStateToProps = (state: any, { newsId }: ITasksProps) => ({
  userData: getCurrentUser(state),
  ...getNewsParamsById<ITasksNews, typeof newsParams[number]>(newsId, newsParams)(state)
})


