import { PageTypes, TPage } from "utils/src";

export const getPageBaseType = (pageType: PageTypes = PageTypes.page) => {
    if(pageType.startsWith(PageTypes.wikilist)) return PageTypes.wikilist;
    if(pageType.startsWith(PageTypes.file)) return PageTypes.file;
    if(pageType.startsWith(PageTypes.page)) return PageTypes.page;
    if(pageType.startsWith(PageTypes.url)) return PageTypes.url;
    return pageType;
};

export const getWikiUrl = (page: TPage) => {
    const baseType = getPageBaseType(page.pageType?.type || page.type);
    let link = page.url;
    switch(baseType) {
        case PageTypes.page:
            link = "/pages/" + page.url
            break;
        case PageTypes.wikilist:
            link = "/wikilist/" + page.url
            break;
        case PageTypes.file:
            if(page.file) link = "/pages/files/" + page.file.id
            break;
    };
    return link;
};

export const getPageItemType = () => {
    
};