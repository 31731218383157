import React, { FC, useRef, useState, useEffect, useCallback } from 'react';
import { cnCourseLayout, ICourseLayoutProps } from './CourseLayout.index';
import './CourseLayout.scss';
import './CourseLayout.ie.scss';
import { CourseHeader } from '../CourseHeader/CourseHeader'
import { Button, Icon } from 'uielements/src'
import { tC, Tc } from 'LMSModule/utils/i18n.adaptor'

function exitFullscreen(elem: any) {
    if (elem.cancelFullScreen) {
        elem.cancelFullScreen();
    } else if (elem.mozCancelFullScreen) {
        elem.mozCancelFullScreen();
    } else if (elem.webkitExitFullscreen) {
        elem.webkitExitFullscreen();
    }
}

export const CourseLayout: React.FC<ICourseLayoutProps> = ({
    course,
    children,
    aside,
    middle,
    classNames = ['Page'],
    allowFullscreen = false,
    fullscreen = false,
    setFullscreen = (fullscreen) => { }
}) => {
    const contentRef = useRef<any>(null)

    const fullscreeHandler = useCallback((e: any) => {
        if (document.fullscreenElement) {
            setFullscreen(true)
        } else {
            setFullscreen(false)
        }
    }, [])

    useEffect(() => {
        contentRef.current?.addEventListener("fullscreenchange", fullscreeHandler);
        if (fullscreen)
            contentRef.current?.requestFullscreen && contentRef.current?.requestFullscreen()
        else
            contentRef.current && exitFullscreen(document)

        return () => {
            contentRef.current?.removeEventListener("fullscreenchange", fullscreeHandler)
        }
    }, [fullscreen])

    return <div className={cnCourseLayout({}, classNames)}>
        {course && <CourseHeader className={cnCourseLayout('Header')} course={course} />}

        {middle && <div className={cnCourseLayout('Middle')}>{middle}</div>}

        <div className={cnCourseLayout('Section')}>
            {aside && <div className={cnCourseLayout('Aside')}>
                {aside}
            </div>}
            <div ref={contentRef} className={cnCourseLayout('Content', [(fullscreen ? 'fullscreen' : 'normalscreen')])}>
                {allowFullscreen && <Button title={tC('fullscreen.1')} theme={'unstyled'} className={cnCourseLayout('FullscreenBtn', { fullscreen})} onClick={() => setFullscreen(!fullscreen)}>
                    {!fullscreen ? <Icon icon={'expand-arrows'} /> : <Icon icon={'expand'} />}
                </Button>}
                {children}
            </div>
        </div>
    </div>
};

