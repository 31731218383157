import { IClassNameProps } from '@bem-react/core';
import { IWidget } from 'i.widgets';
import { IDispatchProps } from 'redux/connector';
import { IStateType as IState } from 'redux/store';
import { rootTypeAction } from 'redux/actions';
import { setAuthUser } from 'utils/src/CommonRedux/base/actions';
import { changeUser } from 'utils/src/CommonRedux/users/actions';

export interface IWIntegarteWidgetItem {
  to: any;
  widget: IWidget;
}

export interface IWIntegarteProps extends IClassNameProps, IDispatchProps, ImapStateToProps, IWIntegrateActionsToProps {
  widgets: (IWIntegarteWidgetItem | null)[]
}

export interface IWIntegarteState {
  init: boolean;
}


type IWIntegrateActionsToProps = typeof WIntegrateActionsToProps;

export const WIntegrateActionsToProps = {
  rootTypeAction,
  setAuthUser,
  changeUser
}

export type ImapStateToProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: IState) => ({
  auth: state.store.auth
})