import React from 'react';
import { Skeleton } from 'uielements/src';
import './QuizSkeleton.scss';

export const QuizSkeleton = () => (
  <div className="QuizSkeleton">
    <div className="QuizSkeleton-Header">
      <div style={{ flexGrow: 1, marginLeft: 24 }}>
        <Skeleton shape="line" size="xs" style={{ width: '5%' }} />
        <Skeleton size="xs" shape="line" style={{ width: '7%', marginBottom: '24px' }} />
      </div>
    </div>
    <Skeleton size="xs" shape="square" style={{width: '100%', height: '155px', marginBottom: '24px'}} spacing="xl"/>
    <div className="QuizSkeleton-Body">
      <Skeleton shape="line" style={{ width: '20%', height: '24px', marginBottom: '24px' }} />
      
      <Skeleton size="xs" shape="line" style={{ width: '60%' }} />
      <Skeleton size="xs" shape="line" style={{ width: '70%' }} />
      <Skeleton size="xs" shape="line" style={{ width: '40%', marginBottom: '24px' }} />

      <Skeleton size="xs" shape="line" style={{ width: '146px', height: '35px' }} />
    </div>
  </div>
);
