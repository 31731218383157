import React, { useMemo, useState } from 'react';
import { Tabs } from 'muicomponents/src';
import { ITabTabsProps, TTab, TTabName } from './TagTabs.index';
import { TabsBox, TagTabsInner } from './TagTabs.styled';
import { Translate } from 'localization';
import { Timeline } from 'blocks/Timeline/Timeline';
import { ListUsers } from '../ListUsers/ListUsers';


export const TagTabs:React.FC<ITabTabsProps> = ({ context }) => {
  const [activeTab, setActiveTab] = useState<TTabName>('publications');

  const handleChange = (event: React.SyntheticEvent, value: string) => {
    if (!event) return;
    setActiveTab(value as unknown as TTabName);
  };

  const tabs = useMemo<TTab[]>(() => {
    return [
      {id: 'publications', label: <Translate i18nKey={'publications'}/> },
      {id: 'users', label: <Translate i18nKey={'users'}/> },
    ]
  }, []);

  return (
    <TagTabsInner>
      <TabsBox>
        <Tabs value={activeTab} onChange={handleChange} tabs={tabs} />
      </TabsBox>
      {
        activeTab === 'publications' ? 
        <Timeline context={context}/> : 
        <ListUsers context={context} />
      }
    </TagTabsInner>
  );
}