import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';

import ideaHistoryModal from 'blocks/Dialogs/IdeaHistory/IdeaHistory'

const handleContextActionHistory = function* handleContextActionHistory({ payload }: AT.AContextAction) {
    const { action, id } = payload
    if (action === 'history') {
        try {

            const result = yield* call(ideaHistoryModal, {
                newsId: id
            })

        } catch (error) {
            console.error(error)
        }
    }
    yield;

    return 0;
}

export default handleContextActionHistory