import { takeLeading, fork, takeLatest } from 'redux-saga/effects';
import * as LMActions from './actions.types';
import { NLeftMenuActions } from './actions.intrefaces';
import { leftMenuSetReducerField } from './actions';
import { getLeftMenuReducerField } from './selectors';
import { getDSStoreByName, getDSStoreItemByNameAndId } from "redux/dataStorage/selectors";
import { DSChangeItem, DSSetItems } from "redux/dataStorage/actions";
import { DS_SET_ITEMS } from "redux/dataStorage/actions.types";
import { NDataStorageActions } from "redux/dataStorage/actions.interfaces";
import { select, put, call } from 'utils/src/saga.effects';
import { LOCATION_CHANGE, LocationChangeAction, getLocation } from 'connected-react-router';
import { flatMapDeep, uniq } from 'lodash';
import { getParentChain } from '../LeftMenu.utils';
import { checkResponseStatus, NonNullableParams, ResponseError } from 'utils/src';
import { leftMenuStorageName, mainBarId } from './constants';
import { sagas as leftMenuAPI } from 'utils/src/requests/requests.menu';
import { toast } from 'react-toastify';
import { Translate } from 'localizations/Translate';

function* changeActive() {
  try {

    const location = yield* select(getLocation);
    const { pathname } = location;
    const menuItems = yield* select(getDSStoreByName('leftMenu'));
    type ReqMenuItems = NonNullableParams<typeof menuItems>;
    const mewnuItemLinks = Object.values(menuItems as ReqMenuItems).filter(el => el.renderType === 'link');
    // maybe more right use pathname.startsWith(el.url), need think about it
    const activeLinks = mewnuItemLinks.filter(el => el.url === pathname);
    const activeLinksIds = activeLinks.map(el => el.id);  

    const activeParents = uniq(flatMapDeep(activeLinks.map(item => getParentChain(menuItems as ReqMenuItems, item))));

    yield put(leftMenuSetReducerField({ field: 'activeItems', value: [ ...activeLinksIds, ...activeParents ] }));
    yield fork(changeNotViewedCount);

  } catch (error) {
    console.error('left menu "changeActive" errror: ', error);
  }
};

function* changeNotViewedCount() {
  try {

    const menuItems = yield* select(getDSStoreByName('leftMenu'));
    type ReqMenuItems = NonNullableParams<typeof menuItems>;
    const activeItems = yield* select(getLeftMenuReducerField('activeItems'));
    const linksItems = Object.values(menuItems as ReqMenuItems).filter(item => item?.renderType === 'link');

    const linkItemsToDropCount = linksItems.filter(el => activeItems.includes(el.id)).map(el => el.id);
    yield put(DSSetItems({ storage: 'leftMenu', items: linkItemsToDropCount.map(id => ({ id, item: { ...(menuItems as ReqMenuItems)[id], notViewedCount: 0 } })) }));

    const linksWithCount = linksItems.filter(el => !linkItemsToDropCount.includes(el.id) && el.notViewedCount > 0);
    const parentsWithLinkedChilds = uniq(flatMapDeep(linksWithCount.map(item => getParentChain(menuItems as ReqMenuItems, item))));
    yield put(leftMenuSetReducerField({ field: 'notificationsItems', value: parentsWithLinkedChilds }));

  } catch (error) {
    console.error('left menu "cacheNotViewedCount" errror: ', error);
  }
};

function* locationChanged(action: NDataStorageActions.SetItems | LocationChangeAction) {
  try {
    if(action.type === DS_SET_ITEMS && action.payload.storage === 'leftMenu' || action.type === LOCATION_CHANGE) {
      yield fork(changeActive);
    }
  } catch (error) {

  }
};

function* favouriteChangeSaga(action: NLeftMenuActions.ToggleFavourite) {
  try {
    const favouriteItemId = yield* select(getLeftMenuReducerField('favouriteItemId'));
    if(!favouriteItemId) return;
    const barsItems = yield* select(getLeftMenuReducerField('barsItems'));
    const toggledFavouriteItem = yield* select(getDSStoreItemByNameAndId(leftMenuStorageName, action.payload));
    if(!toggledFavouriteItem) return;
    yield* put(DSChangeItem({
      storage: leftMenuStorageName,
      id: toggledFavouriteItem.id,
      item: {
        ...toggledFavouriteItem,
        isInFavorites: barsItems[favouriteItemId]?.includes(toggledFavouriteItem.id)
      }
    }));
    yield* call(favouriteSave, action);
  } catch(error) {
    console.error('left menu "favouriteChangeSaga" errror: ', error);
  }
}

function* favouriteSave(action: NLeftMenuActions.ToggleFavourite | NLeftMenuActions.SortFavourite) {
  try {
    const favouriteItemId = yield* select(getLeftMenuReducerField('favouriteItemId'));
    const barsItems = yield* select(getLeftMenuReducerField('barsItems'));
    const items = yield* select(getDSStoreByName('leftMenu'));
    if(!favouriteItemId || !barsItems || !items) return;
    const getItemsToBar = (id: string) => {
        const item = items[id];
        if (item && item.renderType === 'dropdown') {
            const itemBar = barsItems[id] || [];
            item.children = itemBar.map(getItemsToBar);
        }
        return item as NonNullable<typeof item>;
    };
    const mainBar = (barsItems[mainBarId] || []).filter(el => el === favouriteItemId);
    const newMenu = mainBar.map(getItemsToBar);
    leftMenuAPI.set(newMenu, { onlyFavs: true })
    .then((d) => {
      if (!checkResponseStatus(d)) {
        toast.error(Translate.t({ i18nKey: 'pryaniky.leftMenu.save.favourites.error' }));
        throw new ResponseError(JSON.stringify(d))
      }
    })
    .catch(error => {
      console.error('left menu reqest to set favourite errror: ', JSON.stringify(error));
    });
  } catch(error) {
    console.error('left menu "favouriteSave" errror: ', JSON.stringify(error));
  }
}

export function* menuSaga() {
  yield takeLeading([LOCATION_CHANGE, DS_SET_ITEMS], locationChanged);
  yield takeLatest(LMActions.LM_TOGGLE_FAVOURITE, favouriteChangeSaga);
  yield takeLatest(LMActions.LM_SORT_FAVOURITE, favouriteSave);
};