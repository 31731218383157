import { IChatListItemProps } from './Item.index';
import * as React from 'react'
import './Item.scss'

export const ChatListItem: React.FC<IChatListItemProps> = ({ children }) => {
    return (
        <li className="ChatList-Item">
            {children}
        </li>
    );
}