/**
 * @packageDocumentation
 * @module Filter_type_groups
 */
import React from 'react';
import { FiltersPresenter } from '../Filters';
import i18n from "../../../localizations/i18n";
import { IFiltersStateProps, IFiltersDispatchProps, IFiltersProps, mapStateToProps, mapDispatchToProps, cnFilters } from '../Filters.index';
import { IStateType as IState } from 'redux/store';
import { connect } from 'react-redux';
import { Selectize } from 'uielements/src';
import { userRoles } from 'utils/src/constants.prn';
import { Translate } from 'localizations/Translate';
import { withRouter } from 'react-router';
import { userFilters, userFiltersHeaders } from './Filters_type_users.constants';

class FiltersTypeUsersPresenter extends FiltersPresenter {
  public defaulValues = {
    'usersCatFilter/usersRole/userBlock': '//'
  };

  /**
   * prepare filters to render data like {}[][]
   */
  public data = userFilters.map(fl => fl.map(el => ({
    id: el.id,
    type: el.type || 'usersCatFilter/usersRole/userBlock',
    title: i18n.t(`pryaniky.filter.users.${el.title}`),
    value: el.value,
    showProps: el.showProps,

    clear: el.clear,
  })));

  public getDataFromContext = (type: string) => {

    if (type === 'roles') {
      return this.props.context[type] || this.props.authUser.baseData.roles || [];
    }
    if (type === 'modules') {
      return this.props.context[type] || this.props.enabledModules || {};
    }
    if (type === 'networks') {
      return this.props.authUser.baseData.networks;
    }
    return this.props.context[type];
  }

  /**
   * render - it is render)
   */
  public render() {
    const { tag: Tag = 'div', context, className, itemsView, authUser } = this.props;
    return (
      <Tag tag='div' {...this.props} className={cnFilters({}, [className])} >
        {
          this.data.map((fl, idx) => {
            const type = fl[0] && fl[0].type;
            const oldData = fl;
            fl = fl.filter(i => Object.keys(i.showProps || {}).reduce((a, k, idx) => {
              if (!a) return a;
              if (typeof (i.showProps[k]) === 'function') return i.showProps[k](this.getDataFromContext(k));
              if (i.showProps[k] !== itemsView[k as keyof typeof itemsView]) return false
              return a;
            }, true as boolean));

            let active = this.getActive(idx);
            if (active && !fl.map(e => e.id).includes(active) && !fl.map(e => e.value).includes(active)) {
              const defObj = fl.reduce((a: any, c) => a || ((this.defaulValues as any)[c.type] === c.value ? c : a), undefined);
              if (defObj) this.handleChange(defObj);
            }
            if (!fl.length) return null;
            // this.data[idx] = fl;
            // active = this.getActive(idx);
            // this.data[idx] = oldData;
            return <div className={cnFilters('Block')} >
              {type && userFiltersHeaders[type] && <h4 className={cnFilters('Subtitle')}>
                <Translate i18nKey={userFiltersHeaders[type]} />:
              </h4>}
              <Selectize onClick={this.handleChange} elements={fl} active={active} />
            </div>
          })
        }
      </Tag>
    )
  }
}


/**
 * connected to redux
 */
export const FiltersTypeUsers = withRouter(
  connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersProps, IState>(
    mapStateToProps,
    mapDispatchToProps
  )(FiltersTypeUsersPresenter)
);