import React, { useState, useRef } from 'react';
import { IEditorTypeEditProps } from './edit.index';
import { LongreadNews } from '../../../Longread.type'
import { Editor } from '../../Editor'
import * as utils from 'utils/src/utils';
import { BaseValidator, baseValidate } from '../../../../Base.validate'
import { rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
import { IField } from 'uielements/src/FieldRender/interfaces'
import { useDebounce } from 'utils/src/hooks'
// import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown'
import {
    prepareFromServer
} from 'uielements/src/FieldRender'
import { EditorState, RawDraftContentState, convertToRaw, convertFromRaw } from 'draft-js';
import { NodeBulder } from 'uielements/src/CommonmarkRender'

const createShortDraft = (state: EditorState) => {
    const draftState = convertToRaw(state.getCurrentContent())
    const text = draftToMarkdown(draftState, rawToMd);
    const NB = new NodeBulder(text || '')
    const newRaw = NB.getDraftRaw()
    return EditorState.createWithContent(convertFromRaw(newRaw))
}

export const EditorTypeEdit: React.FC<IEditorTypeEditProps> = ({ data, onChange, typeParams }) => {
    const [autoShort, setAutoShort] = useState(false)
    const autoShortRef = useRef(autoShort)
    autoShortRef.current = autoShort
    let localData = { ...data };
    if (!data._editing) {
        // const NB = new NodeBulder( localData.text )//NodeBulder(isString(localData.text) ? localData.text : '')
        localData = {
            ...localData,
            // text: EditorState.createWithContent(convertFromRaw(NB.getDraftRaw())),
            additionalFields: {
                additionalFieldsValues: prepareFromServer(localData.additionalFields.additionalFieldsValues),
            },
            _editing: true
        };

    }
    const onChangeText = (text: string) => {
        localData.text = text;
        const bv = new BaseValidator<any>()
        bv.setRules(baseValidate)
        bv.addData(localData)
        onChange(localData, bv.validation().length === 0);
    }

    const onChangeMain = (text: string) => {
        // setAutoShort(false)
        onChangeText(text)
    }

    const changeMd = useDebounce((raw: EditorState) => {
        // const text = draftToMarkdown(raw, rawToMd);
        onChangeText(createShortDraft(raw) as any)
    }, 600)

    const onChangeDraft = (fields: IField.AdditionalField[]) => {
        // types
        localData.additionalFields.additionalFieldsValues = fields
        const emptyRaw = EditorState.createEmpty()

        const content = fields.reduce((ac, c) => ac ? ac : c.fields.reduce((af, f) => f.isBaseField && f.name === 'Text' && !af ? f.data : af, null), null)
        autoShortRef.current && changeMd(content || emptyRaw)
        // // localData.text = uuid();
        onChange(localData, true);
    }
    return <Editor
        additionalFields={localData.additionalFields.additionalFieldsValues}
        changeAdditional={onChangeDraft}
        // draftState={localData.additionalFields[0].additionalFieldsValues[0].draft || EditorState.createEmpty()}
        data={localData}
        autoShort={autoShort}
        setAutoShort={setAutoShort}
        onChangeText={onChangeText}
        allowRichEdit={typeParams?.newsRenderParams?.allowRichEdit} />

}