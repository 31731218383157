import React, { Component } from 'react';
import { IAddButtonProps } from './AddImage.index'
import './AddImage.css'
import { StyleButton } from '../StyleButton/StyleButton'
import uuid from 'uuid';
import * as utils from 'utils/src/utils';
import { DropdownSelect } from '../DropdownSelect/DropdownSelect'
import i18n from 'localizations/i18n';
import { TOptions } from 'i18next';

const ti = (key: string, options: TOptions | string = {}) => i18n.t(`pryaniky.editor.image.${key}`, options)

export class ImageAdd extends Component<IAddButtonProps> {
    // Start the popover closed
    state = {
        url: '',
        open: false,
        load: false
    };

    toggle = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    addImage = (fn: () => void) => () => {
        this.insertImg(this.state.url)
        fn()
    }

    insertImg = (url: string) => {
        const { editorState, onChange, modifier } = this.props;
        onChange(modifier(editorState, url));
        this.setState({ url: '' });
    }

    changeUrl = (evt: any) => {
        this.setState({ url: evt.target.value });
    }
    loadFinish = (response: { [s: string]: any }, file: any) => {
        
        this.setState({ load: false })
        for (const file of response.data) {
            this.insertImg(file.originalUrl)
        }

    }

    loadStart = () => {
        this.setState({ load: true })
    }

    changeFile = (fn: () => void) => (e: any) => {
        for (const file of e.target.files) {
            file.id = uuid.v1();
            utils.API.files.upload(file, { loadStart: () => { }, loadProgress: () => { }, loadFinish: (response, file) => { this.loadFinish(response, file); fn() } });
        }
    }

    render() {
        return (
            <div className={'addImage'}>
                <DropdownSelect
                    button={(open, toggle) => <StyleButton
                        active={open}
                        label={<i className="Icon icon-image-file" />}
                        // onToggle={this.toggle}
                        onToggle={toggle}
                        // style={''}
                    />}
                >
                    {(open, close) => !this.state.load ? <React.Fragment>
                        <div className={'addImage-byUrl'}>
                            <input
                                type="text"
                                placeholder={ti('input.link.placeholder')}
                                onChange={this.changeUrl}
                                className={'addImageInput'}
                                value={this.state.url}
                            />
                            <button disabled={this.state.url === ''} type="button" onClick={this.addImage(close)} className={'addImageConfirmButton'}>{ti('button.add')}</button>
                        </div>

                        <div className={'addImage-byFile'}>
                            <input type={'file'} onChange={this.changeFile(close)} accept={'image/png, image/gif, image/jpeg, image/jpg, image/bmp, image/x-icon'} />
                        </div>
                    </React.Fragment> : 'load'
                    }

                </DropdownSelect>

            </div>
        );
    }
}