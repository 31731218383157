import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import {
    newTestAttempts,
    transitionToNextUnit
} from "../../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
export interface IActionsProps extends IClassNameProps, IActionsStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
    className?: string;
    isLastUnit?: boolean;
    sid: string;
    unit: any;
    attemptAction?: () => void
    hideAttempt?: boolean
}

export interface IActionsState {

}

export interface IActionsStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({

})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    newTestAttempts,
    transitionToNextUnit
}, dispatch);

export const cnActions = cn('ResultActions');