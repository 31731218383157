import * as utils from 'utils/src/utils';
import { TUserscoursesinfoheadParams } from 'utils/src/requests/requests.lms';
import { denormalize, normalize, schema } from 'normalizr';
import { toast } from 'react-toastify'
import { createDefaultReply } from 'blocks/NewsTypes/Reply/Reply.type';
// import { defaultBaseNews } from '../../blocks/NewsTypes/BaseType'
// import turndownService from 'utils/src/turndown';
import {
    // newsSchema,
    // addNews 
} from 'redux/actions/News'
import {
    setNewsList
} from 'News/redux/actions'
import {
    normalizeNews
} from 'News/redux/saga/utils'
import {
    testSchema
} from 'LMSModule/utils/normalize';
import uuid from 'uuid/v1';
import actions from '../actionsTypes/LMSTO';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { type } from 'os';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { mdToRaw, rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
import { NodeBulder } from 'uielements/src/CommonmarkRender'
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
const zeroId = "00000000-0000-0000-0000-000000000000";

export const coursesSchema = () => {
    const sessions = new schema.Entity('normalSessions', {}, {
        idAttribute: 'id', processStrategy: (value, parent, key) => {
            return { ...value };
        }
    });

    const courses = new schema.Entity('values', {}, {
        idAttribute: 'id',
        processStrategy: (value, parent, key) => {
            const normalizedData = normalize(value.courseSessions || [], [sessions]);
            return {
                ...value,
                normalSessions: normalizedData.entities.normalSessions,
                courseSessions: normalizedData.result
            };
        }
    });
    return [courses];
}


export const moderationQuestionShema = () => {
    const questions = new schema.Entity('normalQuestions', {}, {
        idAttribute: 'id',
        /*processStrategy: (value, parent, key) => {
            const normalizedData = normalize(value.users || [], [users]);
            return {
                ...value,
                normalUsers: normalizedData.entities.normalUsers,
                users: normalizedData.result
            };
        },*/

    });
    return [questions];
}

export const moderationSchema = () => {
    const answers = new schema.Entity('normalAnswers', {}, {
        idAttribute: 'answerId',
        processStrategy: (value, parent, key) => {
            return { ...value };
        },

    });

    // let normalAnswers: any = {}

    const users = new schema.Entity('normalUsers', {}, {
        idAttribute: (value) => value.user.id,
        processStrategy: (value, parent, key) => {
            const normalizedData = normalize(value.answers || [], [answers]);
            // normalAnswers = { ...normalAnswers, ...normalizedData.entities.normalAnswers}
            return {
                ...value,
                normalAnswers: normalizedData.entities.normalAnswers,
                answers: normalizedData.result
            };
        },

    });

    const questions = new schema.Entity('normalQuestions', {}, {
        idAttribute: 'id',
        processStrategy: (value, parent, key) => {
            const normalizedData = normalize(value.users || [], [users]);
            return {
                ...value,
                normalUsers: normalizedData.entities.normalUsers,
                users: normalizedData.result
            };
        },

    });

    const units = new schema.Entity('normalUnits', {}, {
        idAttribute: 'id',
        processStrategy: (value, parent, key) => {
            const normalizedData = normalize(value.questions || [], [questions]);
            return {
                ...value,
                normalQuestions: normalizedData.entities.normalQuestions,
                questions: normalizedData.result
            };
        }
    });
    return [units];
}



export const singleCourseSchema = () => {
    const sessions = new schema.Entity('normalSessions', {}, {
        idAttribute: 'id', processStrategy: (value, parent, key) => {
            return { ...value };
        }
    });
    return { sessions }
}

export const courseStudentsSchema = () => {
    const students = new schema.Entity('normalStudents', {}, {
        idAttribute: 'userId', processStrategy: (value, parent, key) => {
            return { ...value };
        }
    });
    return [students]
}

export const sessionsSchema = () => {
    const sessions = new schema.Entity('normalSessions', {}, {
        idAttribute: 'id', processStrategy: (value, parent, key) => {
            return { ...value };
        }
    });
    return [sessions]
}

export const courseResutsSchema = () => {
    const students = new schema.Entity('normalResults', {}, {
        idAttribute: 'sessionId', processStrategy: (value, parent, key) => {
            return { ...value };
        }
    });
    return [students]
}

export const normalizeData = (data: any, schema: any = coursesSchema) => {
    const normalizedData = normalize(data, schema());
    return normalizedData;
}

/////////////////////////////////////////////
export const createAnswer = (qid: string, uid: string) => ({
    type: actions.CREATE_ANSWER,
    payload: {
        qid, uid
    }
})

export const createQuestion = (question: any, uid: string) => ({
    type: actions.CREATE_QUESTION,
    payload: {
        question,
        uid
    }
})


/********************************************************/
/******************** to saga ***************************/
/********************************************************/

/*********************** GET_USER_STAT_LIST ***********************************/
export type getUserStatInfoListPayloadType = { userId: string, params: any, skipCount: number, count: number }
export type getUserStatInfoListActionType = { type: typeof actions.GET_USER_STAT_LIST, payload: getUserStatInfoListPayloadType, clean: boolean }
/**

 */
export const getUserStatInfoList = (payload: getUserStatInfoListPayloadType, clean: boolean = false): getUserStatInfoListActionType => ({
    type: actions.GET_USER_STAT_LIST,
    payload,
    clean
})


/*********************** GET_USERS_COURSES_INFO ***********************************/
export type getCoursesStatsListPayloadType = any
export type getCoursesStatsListActionType = { type: typeof actions.GET_COURSES_STATS_LIST, payload: getCoursesStatsListPayloadType, clean: boolean }
/**

 */
export const getCoursesStatsList = (payload: getCoursesStatsListPayloadType, clean: boolean = false): getCoursesStatsListActionType => ({
    type: actions.GET_COURSES_STATS_LIST,
    payload,
    clean
})

/*********************** GET_USER_STAT_INFO ***********************************/
export type getUserStatInfoInfoPayloadType = { userId: string, params: any }
export type getUserStatInfoInfoActionType = { type: typeof actions.GET_USER_STAT_INFO, payload: getUserStatInfoInfoPayloadType }
/**

 */
export const getUserStatInfoInfo = (payload: getUserStatInfoInfoPayloadType): getUserStatInfoInfoActionType => ({
    type: actions.GET_USER_STAT_INFO,
    payload
})


/*********************** GET_USERS_COURSES_INFO_HEAD ***********************************/
export type getUserscoursesinfoheadPayloadType = TUserscoursesinfoheadParams
export type getUserscoursesinfoheadActionType = { type: typeof actions.GET_USERS_COURSES_INFO_HEAD, payload: getUserscoursesinfoheadPayloadType }
/**

 */
export const getUserscoursesinfohead = (payload: getUserscoursesinfoheadPayloadType): getUserscoursesinfoheadActionType => ({
    type: actions.GET_USERS_COURSES_INFO_HEAD,
    payload
})

/*********************** GET_USERS_COURSES_INFO ***********************************/
export type getUserscoursesinfoPayloadType = any
export type getUserscoursesinfoActionType = { type: typeof actions.GET_USERS_COURSES_INFO, payload: getUserscoursesinfoPayloadType, clean: boolean }
/**

 */
export const getUserscoursesinfo = (payload: getUserscoursesinfoPayloadType, clean: boolean = false): getUserscoursesinfoActionType => ({
    type: actions.GET_USERS_COURSES_INFO,
    payload,
    clean
})

/*********************** CREATE_UNIT ***********************************/
export type createUnitPayloadType = any
export type createUnitActionType = { type: string, payload: convertLegacyPagePayloadType }
/**
 * создаёт новый юнит в курсе
 * @param payload короткая модель юнита для добавления в курс
 */
export const createUnit = (payload: any): createUnitActionType => ({
    type: actions.CREATE_UNIT,
    payload
})

/*********************** LOAD_UNIT_CONTENT ***********************************/
export type loadUnitPayloadType = { uid: string, version?: string }
export type loadUnitActionType = { type: string, payload: loadUnitPayloadType }
/**
 * получает с сервера полную модель указаного юнита
 * @param uid ид юнита
 */
export const loadUnit = (uid: string, version?: string): loadUnitActionType => ({
    type: actions.LOAD_UNIT_CONTENT,
    payload: {
        uid, version
    }
})

/*********************** RESET_UNIT_CHANGE ***********************************/
export type resetUnitPayloadType = { uid: string }
export type resetUnitActionType = { type: string, payload: resetUnitPayloadType }
/**
 * перезагружает юнит с сервера для отмены внесенных в него изменений
 * @param uid ид юнита
 */
export const resetUnit = (uid: string): resetUnitActionType => ({
    type: actions.RESET_UNIT_CHANGE,
    payload: {
        uid
    }
})

/*********************** LOAD_UNIT_CONTENT ***********************************/
export type deleteQuestionPayloadType = { uid: string, qid: string }
export type deleteQuestionActionType = { type: string, payload: deleteQuestionPayloadType }
/**
 * удаляет вопрос в юните
 * @param uid ид юнита
 * @param qid ид вопроса
 */
export const deleteQuestion = (uid: string, qid: string): deleteQuestionActionType => ({
    type: actions.DELETE_QUESTION,
    payload: {
        uid, qid
    }
})

/*********************** DELETE_ANSWER ***********************************/
export type deleteAnswerPayloadType = { uid: string, qid: string, aid: string }
export type deleteAnswerActionType = { type: string, payload: deleteAnswerPayloadType }
/**
 * удаляет вариант ответа на вопрос
 * @param uid ид юнита
 * @param qid ид вопроса
 * @param aid ид варианта
 */
export const deleteAnswer = (uid: string, qid: string, aid: string): deleteAnswerActionType => ({
    type: actions.DELETE_ANSWER,
    payload: {
        uid, qid, aid
    }
})

/*********************** LOAD_USERS_IN_COURSE ***********************************/
export type loadUsersInCoursePayloadType = { cid: string, clean: boolean, params: { [key: string]: any } }
export type loadUsersInCourseActionType = { type: string, payload: loadUsersInCoursePayloadType }
/**
 * 
 */
export const loadUsersInCourse = (cid: string, params: { [key: string]: any } = {}, clean: boolean = false): loadUsersInCourseActionType => ({
    type: actions.LOAD_USERS_IN_COURSE,
    payload: {
        cid, clean, params
    }
})


/*********************** LOAD_UNIT_VERSIONS ***********************************/
export type loadUnitVersionsPayloadType = { uid: string }
export type loadUnitVersionsActionType = { type: string, payload: loadUnitVersionsPayloadType }
/**
 * получает с сервера список версий для указаного юнита
 * @param uid ид юнита
 */
export const loadUnitVersions = (uid: string): loadUnitVersionsActionType => ({
    type: actions.LOAD_UNIT_VERSIONS,
    payload: {
        uid
    }
})


/*********************** ALLOW_PLAY_COURSE ***********************************/
export type allowPlayCoursePayloadType = { cid: string }
export type allowPlayCourseActionType = { type: string, payload: allowPlayCoursePayloadType }
/**
 * 
 * @param cid ид курса
 */
export const allowPlayCourse = (cid: string): allowPlayCourseActionType => ({
    type: actions.ALLOW_PLAY_COURSE,
    payload: {
        cid
    }
})


/******************************** LOAD_TEST_RESULTS *************************/
export type loadTestResultsPayloadType = { sid: string }
export type loadTestResultsActionType = { type: string, payload: loadTestResultsPayloadType }
/**
 * получить статистику состояний студентов на курсе
 */
export const loadTestResults = (sid: string): loadTestResultsActionType => ({
    type: actions.LOAD_TEST_RESULTS,
    payload: { sid }
})


/******************************** LOAD_STUDENTS_STATUS *************************/
export type loadStudentsStatusPayloadType = { cid: string, sid?: string }
export type loadStudentsStatusActionType = { type: string, payload: loadStudentsStatusPayloadType }
/**
 * получить статистику состояний студентов на курсе
 */
export const loadStudentsStatus = (cid: string, sid?: string): loadStudentsStatusActionType => ({
    type: actions.LOAD_STUDENTS_STATUS,
    payload: { cid, sid }
})


/******************************** LOAD_UNITS_ATTENDANCE *************************/
export type loadUnitsAttandancePayloadType = { cid: string, sid?: string }
export type loadUnitsAttandanceActionType = { type: string, payload: loadUnitsAttandancePayloadType }
/**
 * 
 */
export const loadUnitsAttandance = (cid: string, sid?: string): loadUnitsAttandanceActionType => ({
    type: actions.LOAD_UNITS_ATTENDANCE,
    payload: { cid, sid }
})


/******************************** LOAD_STAT_COMMENTS_COUNT *************************/
export type loadStatCommentsCountPayloadType = { cid: string, sid?: string }
export type loadStatCommentsCountActionType = { type: string, payload: loadStatCommentsCountPayloadType }
/**
 * 
 */
export const loadStatCommentsCount = (cid: string, sid?: string): loadStatCommentsCountActionType => ({
    type: actions.LOAD_STAT_COMMENTS_COUNT,
    payload: { cid, sid }
})


/******************************** LOAD_UNIT_COMMENTS *************************/
export type loadUnitsCommentsPayloadType = { type: 'user' | 'admin', params: any }
export type loadUnitsCommentsActionType = { type: string, payload: loadUnitsCommentsPayloadType }
/**
 * 
 */
export const loadUnitsComments = (payload: loadUnitsCommentsPayloadType): loadUnitsCommentsActionType => ({
    type: actions.LOAD_UNIT_COMMENTS,
    payload
})

/******************************** LOAD_BADGES *************************/
export type loadBadgesActionType = { type: string }
/**
 * 
 */
export const loadBadges = () => ({
    type: actions.LOAD_BADGES
})




/***********************************************************/
/******************** to reducer ***************************/
/***********************************************************/

/*********************** SET_USER_STAT_INFO ***********************************/
export type setUserStatInfoInfoPayloadType = any
export type setUserStatInfoInfoActionType = { type: typeof actions.SET_USER_STAT_INFO, payload: setUserStatInfoInfoPayloadType }
/**

 */
export const setUserStatInfoInfo = (payload: setUserStatInfoInfoPayloadType): setUserStatInfoInfoActionType => ({
    type: actions.SET_USER_STAT_INFO,
    payload
})

/*********************** ADD_USER_STAT_COURSES_LIST ***********************************/
export type addUserStatCoursesListPayloadType = { keys: any[], values: any, userId: string, clean?: boolean }
export type addUserStatCoursesListActionType = { type: typeof actions.ADD_USER_STAT_COURSES_LIST, payload: addUserStatCoursesListPayloadType }
/**

 */
export const addUserStatCoursesList = (payload: addUserStatCoursesListPayloadType): addUserStatCoursesListActionType => ({
    type: actions.ADD_USER_STAT_COURSES_LIST,
    payload
})


/*********************** ADD_COURSES_STAT_LIST ***********************************/
export type addCoursesStatsListPayloadType = { keys: any[], values: any, clean?: boolean }
export type addCoursesStatsListActionType = { type: typeof actions.ADD_COURSES_STAT_LIST, payload: addCoursesStatsListPayloadType }
/**

 */
export const addCoursesStatsList = (payload: addCoursesStatsListPayloadType): addCoursesStatsListActionType => ({
    type: actions.ADD_COURSES_STAT_LIST,
    payload
})

/*********************** PATH_USERS_COURSES_INFO_HEAD ***********************************/
export type addUserscoursesinfolistPayloadType = { keys: any[], values: any, clean?: boolean }
export type addUserscoursesinfolistActionType = { type: typeof actions.ADD_USERS_COURSES_INFO_LIST, payload: addUserscoursesinfolistPayloadType }
/**

 */
export const addUserscoursesinfolist = (payload: addUserscoursesinfolistPayloadType): addUserscoursesinfolistActionType => ({
    type: actions.ADD_USERS_COURSES_INFO_LIST,
    payload
})


/*********************** PATH_USERS_COURSES_INFO_HEAD ***********************************/
export type pathUserscoursesinfoheadPayloadType = any
export type pathUserscoursesinfoheadActionType = { type: typeof actions.PATH_USERS_COURSES_INFO_HEAD, payload: pathUserscoursesinfoheadPayloadType }
/**

 */
export const pathUserscoursesinfohead = (payload: any): pathUserscoursesinfoheadActionType => ({
    type: actions.PATH_USERS_COURSES_INFO_HEAD,
    payload
})


/*********************** PATH_FULL_STATISTIC_SETTING ***********************************/
export type pathFullStatisticSettingPayloadType = any
export type pathFullStatisticSettingActionType = { type: typeof actions.PATH_FULL_STATISTIC_SETTING, payload: pathFullStatisticSettingPayloadType }
/**

 */
export const pathFullStatisticSetting = (payload: any): pathFullStatisticSettingActionType => ({
    type: actions.PATH_FULL_STATISTIC_SETTING,
    payload
})


/*********************** SET_USERS_IN_COURSE ***********************************/
export type setUsersInCoursePayloadType = { cid: string, ids: string[], values: { [key: string]: any }, clean: boolean }
export type setUsersInCourseActionType = { type: string, payload: setUsersInCoursePayloadType }
/**
 * 
 */
export const setUsersInCourse = (cid: string, ids: string[], values: { [key: string]: any }, clean: boolean = false): setUsersInCourseActionType => ({
    type: actions.SET_USERS_IN_COURSE,
    payload: {
        cid, ids, values, clean
    }
})


/*********************** SET_UNIT_VERSIONS ***********************************/
export type setUnitVersionsPayloadType = { uid: string, versions: string[] }
export type setUnitVersionsActionType = { type: string, payload: setUnitVersionsPayloadType }
/**
 * устанавить список юнитов в стор
 * @param uid ид юнита
 */
export const setUnitVersions = (uid: string, versions: string[]): setUnitVersionsActionType => ({
    type: actions.SET_UNIT_VERSIONS,
    payload: {
        uid, versions
    }
})


/*********************** SET_BADGES ***********************************/
export type setBadgesListPayloadType = { badges: string[] }
export type setBadgesListActionType = { type: string, payload: setBadgesListPayloadType }
/**
 * 
 */
export const setBadgesList = (badges: any[]): setBadgesListActionType => ({
    type: actions.SET_BADGES,
    payload: {
        badges
    }
})


/*********************** SET_UNITS_ATTENDANCE ***********************************/
export type setUnitsAttandancePayloadType = { data: any[] }
export type setUnitsAttandanceActionType = { type: string, payload: setUnitsAttandancePayloadType }
/**
 * 
 */
export const setUnitsAttandance = (data: any[]): setUnitsAttandanceActionType => ({
    type: actions.SET_UNITS_ATTENDANCE,
    payload: {
        data
    }
})


/*********************** SET_STAT_COMMENTS_COUNT ***********************************/
export type setStatCommentsCountPayloadType = { data: any[] }
export type setStatCommentsCountActionType = { type: string, payload: setStatCommentsCountPayloadType }
/**
 * 
 */
export const setStatCommentsCount = (data: any[]): setStatCommentsCountActionType => ({
    type: actions.SET_STAT_COMMENTS_COUNT,
    payload: {
        data
    }
})


/*********************** SET_TEST_RESULTS ***********************************/
export type setTestResultsPayloadType = { data: any[] }
export type setTestResultsActionType = { type: string, payload: setStudentsStatusPayloadType }
/**
 * 
 */
export const setTestResults = (data: any[]): setStudentsStatusActionType => ({
    type: actions.SET_TEST_RESULTS,
    payload: {
        data
    }
})


/*********************** SET_STUDENTS_STATUS ***********************************/
export type setStudentsStatusPayloadType = { data: any[] }
export type setStudentsStatusActionType = { type: string, payload: setStudentsStatusPayloadType }
/**
 * 
 */
export const setStudentsStatus = (data: any[]): setStudentsStatusActionType => ({
    type: actions.SET_STUDENTS_STATUS,
    payload: {
        data
    }
})


/*********************** SET_UNIT_COMMENTS ***********************************/
export type setUnitCommentsPayloadType = { data: any[] }
export type setUnitCommentsActionType = { type: string, payload: setUnitCommentsPayloadType }
/**
 * 
 */
export const setUnitComments = (data: any[]): setUnitCommentsActionType => ({
    type: actions.SET_UNIT_COMMENTS,
    payload: {
        data
    }
})


/*********************** SET_ALL_REPLY ***********************************/
export type setAllPeplyPayloadType = { values: { [key: string]: any }, keys: string[], isFinished: boolean }
export type setAllPeplyActionType = { type: string, payload: setAllPeplyPayloadType }
/**
 * 
 */
export const setAllPeply = (payload: setAllPeplyPayloadType): setAllPeplyActionType => ({
    type: actions.APPEND_UNITS_REPLY,
    payload
})


/*********************** CHANGE_UNITS_REPLY_FIELD ***********************************/
export type changeUnitsReplyPayloadType = { key: string, value: any }
export type changeUnitsReplyActionType = { type: string, payload: changeUnitsReplyPayloadType }
/**
 * 
 */
export const changeUnitsReply = (payload: changeUnitsReplyPayloadType): changeUnitsReplyActionType => ({
    type: actions.CHANGE_UNITS_REPLY_FIELD,
    payload
})

/////////////////////////////////////////////////////////////////////////////////////////////////


export const createCourse = (payload: any) => ({
    type: actions.CREATE_COURSE,
    payload
})

export const setAuthorCourses = (data: any) => ({
    type: actions.SET_AUTHOR_COURSES,
    payload: data
})

export const setCourse = (data: any) => ({
    type: actions.SET_SINGLE_COURSE,
    payload: data
})

export const setModerationAnswers = (data: any) => ({
    type: actions.SET_MODERATINO_ANSWERS,
    payload: data
})

export const setModerationFilters = (data: any) => ({
    type: actions.SET_MODERATION_FILTERS,
    payload: data
})

export const setModerationLoad = () => ({
    type: actions.SET_MODERATION_LOADING,
})

export const setStudentsFilters = (data: any) => ({
    type: actions.SET_STUDENTS_FILTERS,
    payload: data
})

export const setModerationStatus = (answerId: any, status: boolean) => ({
    type: actions.SET_ANSWER_MODERATION_STATUS,
    payload: {
        answerId,
        status,
    }
})

export const setSessionStart = (cid: string, sid: string) => ({
    type: actions.SET_SESSION_PAUSE,
    payload: {
        pause: false,
        cid,
        sid
    }
})

export const setSessionStop = (cid: string, sid: string) => ({
    type: actions.SET_SESSION_PAUSE,
    payload: {
        pause: true,
        cid,
        sid
    }
})

export const setSessionArchived = (cid: string, sid: string, isArchived: boolean) => ({
    type: actions.SET_SESSION_ARCHIVED,
    payload: {
        cid,
        sid,
        isArchived
    }
})

export const setStudentes = (cid: string, values: { [id: string]: any }, ids: string[], clean = false) => ({
    type: actions.SET_STUDENTES,
    payload: {
        cid,
        values,
        ids,
        clean
    }
})


export const setStudentesResults = (cid: string, values: any, uid: string) => ({
    type: actions.SET_STUDENTS_RESULTS,
    payload: {
        cid,
        values,
        uid
    }
})

export const setUsers = (payload: any) => ({
    type: actions.SET_TO_USERS,
    payload
})

export const setIsLoading = () => ({

})

export const setSessionsLoad = () => ({
    type: actions.SET_SESSIONS_LOADING,
})

export const setStudentsLoad = () => ({
    type: actions.SET_STUDENTS_LOADING,
})

/**
 * 
 * @param payload - session
 */
export const addSession = (payload: any) => ({
    type: actions.ADD_SESSION,
    payload
})

export const addSessions = (cid: string, sessions: any, activeSessions: string[], archiveSessions: string[], archive: boolean, clear: boolean) => ({
    type: actions.ADD_SESSIONS,
    payload: {
        cid,
        sessions,
        activeSessions,
        archiveSessions,
        archive,
        clear
    }
})


export const setSession = (payload: any) => ({
    type: actions.SET_SESSION,
    payload
})


export const setUserSessions = (cid: string, uid: string, sessions: any[]) => ({
    type: actions.SET_STUDENT_SESSIONS,
    payload: {
        cid, uid, sessions
    }
})

export const addCourse = (payload: any) => ({
    type: actions.ADD_COURSE,
    payload
})

export const addUnit = (payload: any) => ({
    type: actions.ADD_UNIT,
    payload
})

export const updateUnit = (unit: any, modifed = true) => ({
    type: actions.UPDATE_UNIT,
    payload: {
        unit,
        modifed
    }
})

export const setUnitModifed = (id: string, modifed: boolean) => ({
    type: actions.SET_UNIT_MODIFED,
    payload: {
        id, modifed
    }
})

export const addQuestions = (payload: any) => ({
    type: actions.ADD_QUESTIONS,
    payload
})

export const updateQuestion = (question: any, uid: string) => ({
    type: actions.UPDATE_QUESTIONS,
    payload: { question, uid }
})

export const changeQuestionType = (qtype: number, qid: string, uid: string) => ({
    type: actions.CHANGE_QUESTIONS_TYPE,
    payload: { qtype, qid, uid }
})

export const addAnswers = (payload: any) => ({
    type: actions.ADD_ANSWERS,
    payload
})

export const updateAnswer = (answer: any, uid: string) => ({
    type: actions.UPDATE_ANSWERS,
    payload: { answer, uid }
})

export type setRightAnswerPayloadType = { qid: string, aid: string, uid: string }
export const changeRadioAnswer = (payload: setRightAnswerPayloadType) => ({
    type: actions.CHANGE_RADIO_ANSWERS,
    payload
})

export const toggleCheckAnswer = (payload: setRightAnswerPayloadType) => ({
    type: actions.CHANGE_CHECK_ANSWERS,
    payload
})

export const setRadioAnswer = (answers: any, uid: string) => ({
    type: actions.SET_RADIO_ANSWERS,
    payload: { answers, uid }
})

export type changeUnitPositionType = { oldIndex: number, newIndex: number, cid: string, noTrot?: boolean }
export const changeUnitPosition = (payload: changeUnitPositionType) => ({
    type: actions.CHANGE_UNIT_POSITION,
    payload
})

export const updateCourse = (payload: any) => ({
    type: actions.UPDATE_COURSE,
    payload
})

export const loadCategories = () => ({
    type: actions.LOAD_CATEGORIES
})

export const setCategories = (data: any) => ({
    type: actions.SET_CATEGORIES,
    payload: normalizeData(data)
})

export const saveLesson = (payload: any) => ({
    type: actions.SAVE_LESSON,
    payload
})

export const throttledSendCourseToServer = (payload: any) => ({
    type: actions.THROTTLED_SEND_COURSE_TO_SERVER,
    payload: payload,
})

/**
 * 
 * @param unit
 */
export const deleteUnit = (unit: any) => ({
    type: actions.DELETE_UNIT,
    payload: unit,
})


export const saveUnit = (unitId: string) => ({
    type: actions.SAVE_UNIT,
    payload: unitId
})

export type loadStudentSessionsType = { cid: string, uid: string, archive: boolean }
export const loadStudentSessionsList = (cid: string, uid: string, archive: boolean = false) => ({
    type: actions.LOAD_STUDENTS_SESSIONS,
    payload: { cid, uid, archive }
})

export const setUnitError = (uid: string, errors: any) => ({
    type: actions.UNIT_WITH_ERROR,
    payload: {
        errors,
        uid
    }
})

export const setQuestionError = (uid: string, qid: string, errors: any) => ({
    type: actions.QUESTION_WITH_ERROR,
    payload: {
        errors,
        uid,
        qid
    }
})

export const setAnswerError = (uid: string, qid: string, aid: string, errors: any) => ({
    type: actions.ANSWER_WITH_ERROR,
    payload: {
        errors,
        uid,
        qid,
        aid
    }
})

/*********************** CONVERT_LEGACY_PAGE **************************/
export type convertLegacyPagePayloadType = { uid: string, cid: string, draftState: any }
export type convertLegacyPageActionType = { type: string, payload: convertLegacyPagePayloadType }
/**
 * создать в курсе cid новый юнит типа wiki с контентом на основе юнита uid устаревшего типа page
 * @param uid 
 * @param cid 
 */
export const convertLegacyPage = (uid: string, cid: string, draftState: any): convertLegacyPageActionType => ({
    type: actions.CONVERT_LEGACY_PAGE,
    payload: {
        uid, cid, draftState
    }
})

/////////////////////////////////////////////
let loadCoursesRequest: any = null
export const loadCoursesByCurrentUser = (params: any = {}, clear: boolean = false) => {
    return (dispatch: any, getState: any) => {
        const store: any = getState()
        if (loadCoursesRequest) loadCoursesRequest.ac.abort();
        const skipCount = store.LMSTO.courses.results.length

        const paramWithCount = {
            ...params,
            count: store.LMSTO.courses.count,
            skipCount: 0
        }
        if (!clear) {
            // paramWithCount.count = store.LMSTO.courses.count;
            paramWithCount.skipCount = skipCount
        }

        dispatch({ type: actions.SET_LOADING })

        const trimedSearch = (paramWithCount.search || '').trim()

        loadCoursesRequest = trimedSearch ?
            utils.API.lms.getCourseSearch(trimedSearch, { ...paramWithCount, search: undefined, mode: 1 }) :
            utils.API.lms.getManagedByMe({ ...paramWithCount, search: undefined })

        loadCoursesRequest.r.then((d: any) => {
            loadCoursesRequest = null
            if (d && d.error_code === 0) {
                const norml = normalizeData(d.data)
                dispatch(setAuthorCourses({ values: norml.entities.values, results: norml.result, clear }))
            }
        })

    }
}

export const loadCourseById = (cousreId: string, update: boolean = false) => {
    return (dispatch: any, getState: any) => {
        utils.API.lms.getManageCourse(cousreId).r.then((d: any) => {
            if (d && d.error_code === 0) {
                const norml = normalizeData([{ ...d.data, source: 'loadCourseById' }])
                dispatch(setCourse({ values: norml.entities.values, results: norml.result, update }))
                loadCourseSessions(cousreId)(dispatch, getState)
            }
        })
    }
}
let loadCourseSessionsRequest: any = null
export const loadCourseSessions = (cid: string, archive: boolean = false, clear: boolean = false) => {
    return (dispatch: any, getState: any) => {
        const store = getState()
        if (loadCourseSessionsRequest) return;//loadCoursesRequest.ac.abort();
        const currentList = store.LMSTO.courses.values[cid][archive ? 'archiveSessions' : 'activeSessions']
        const skipCount = currentList ? currentList.length : 0
        const params = {
            count: store.LMSTO.sessionsWork.count,
            skipCount
        }
        if (clear) {
            params.skipCount = 0
        }
        dispatch(setSessionsLoad())
        loadCourseSessionsRequest = utils.API.lms.getSessionsForCourse(cid, archive, params)
        loadCourseSessionsRequest.r.then((d: any) => {
            if (d && d.error_code === 0) {
                loadCourseSessionsRequest = null
                const normalSessions = normalize(d.data, sessionsSchema())
                const activeSessions = !archive ? normalSessions.result : []
                const archiveSessions = archive ? normalSessions.result : []
                dispatch(addSessions(cid, normalSessions.entities.normalSessions, activeSessions || [], archiveSessions || [], archive, clear))
            }
        })
    }
}

let loadModerationAnswersRequest: any = null
export const loadModerationAnswers = (cid: string = '', filters: any, clear: boolean = false, container?: string) => {
    return (dispatch: any, getState: any) => {
        if (loadModerationAnswersRequest) loadModerationAnswersRequest.ac.abort();

        const store = getState()
        const skipCount = store.LMSTO.moderation.answersIds.length
        const params = {
            ...filters,
            'filter.count': store.LMSTO.moderation.count,
            'filter.skipCount': skipCount
        }

        if (clear) {
            params['filter.skipCount'] = 0
        }

        dispatch(setModerationLoad())
        loadModerationAnswersRequest = cid === '' ? utils.API.lms.getAllModerationAnswers(params) : utils.API.lms.getModerationAnswers(cid, params)
        loadModerationAnswersRequest.r.then((d: any) => {
            loadModerationAnswersRequest = null
            if (d && d.error_code && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        // toast.error('loadModerationAnswers, unknown error:', d.error_code)
                        console.warn(`loadModerationAnswers, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {
                // const norml = normalizeData(d.data, moderationSchema)
                const questions: any = {};
                const answers: any = {};
                const users: any = {};
                // const news: any = {};
                const units: any = {};
                const newNews: any[] = [];
                // const usersUnit: any = {};

                const answersIds: string[] = d.data.map((answer: any) => {
                    answers[answer.id] = {
                        ...answer.answer,
                        newsUid: answer.news.id,
                        userId: answer.user.id,
                        unitId: answer.question.unitId,
                        questionId: answer.question.id,
                        quesionLastVersion: answer.question.lastVersion,
                        quesionVersionDate: answer.question.versionDate,
                    }
                    users[answer.user.id] = answer.user
                    // news[answer.news.id] = answer.news
                    newNews.push(answer.news)
                    questions[answer.question.versionDate + '_' + answer.question.id] = answer.question
                    units[answer.question.unitId] = {
                        id: answer.question.unitId,
                        name: answer.question.unitName
                    }
                    return answer.id
                })


                // const norm: any = normalizeData(newNews, newsSchema)
                // const forms: any = {};
                // const res = norm.result.map((id: any) => {
                //     const postDate = new Date(norm.entities.news[id].date);
                //     const ts = postDate.getTime();
                //     norm.entities.news[id].ts = ts;
                //     forms[id] = { ...createDefaultReply(), text: EditorState.createEmpty() }
                //     // norm.entities.news[id].newComment = defaultReply;
                //     return id
                // })
                dispatch(setNewsList({ ...normalizeNews(newNews), group: 'lms_answers' }))
                // dispatch(addNews({
                //     news: norm.entities.news || {},
                //     // comments: norm.entities.comments,
                //     commentForms: forms,
                //     newsIds: []
                // }))

                dispatch(setModerationAnswers({ units, answersIds, questions, answers, users, clear, container }))
            } else if (d && d.dom_error === 'AbortError') {
                console.log(`loadModerationAnswers aborted`)
            } else {
                toast.error('loadModerationAnswers, internal error');
                console.warn(`loadModerationAnswers, internal error`, d)
            }
        })
    }
}

export const loadUnitContent = (uid: string) => {
    return (dispatch: any, getState: any) => {
        const store = getState();

        const request = utils.API.lms.getUnitContent(uid)
        request.r.then((d: any) => {
            if (d && d.data) {

                if (d.data.unitType === 'test') {
                    const unit = { ...d.data }
                    let answers: any = {}
                    const normalQuestions: any = normalizeData(d.data.questions, testSchema)
                    unit.questions = normalQuestions.result
                    normalQuestions.result.forEach((qid: string) => {
                        answers = {
                            ...answers,
                            ...normalQuestions.entities.values[qid].normalAnswers
                        }
                        const nb = new NodeBulder(normalQuestions.entities.values[qid].text)
                        normalQuestions.entities.values[qid].draftState = EditorState.createWithContent(convertFromRaw(nb.getDraftRaw()))

                        delete normalQuestions.entities.values[qid].normalAnswers
                    });
                    dispatch(addAnswers(answers))
                    dispatch(addQuestions(normalQuestions.entities.values))
                    dispatch(addUnit(unit))

                } else if (d.data.unitType === 'wiki') {
                    let unit = { ...d.data }
                    let state = EditorState.createEmpty()
                    if (unit.wiki.data.blocks) {
                        state = EditorState.createWithContent(convertFromRaw(unit.wiki.data))
                        // unit = { ...unit, wiki: { ...unit.wiki, data: { ...unit.wiki.data, entityMap: {}, blocks: [] } } }
                    }
                    unit = { ...unit, draft: state }
                    dispatch(addUnit(unit))
                }
                else {
                    dispatch(addUnit(d.data))
                }
            }
            else {
                // dispatch(readCourses({ ...normalizeData(ddddd.data) }))
            }
        });
    };
}

export const _saveUnit = (unitId: string) => {
    return (dispatch: any, getState: any) => {
        const store = getState();
        const unit = store.LMSTO.units[unitId]
        if (unit.unitType === 'test') {
            const questions = store.LMSTO.questions;
            const answes = store.LMSTO.answers

            const quesionsArray = unit.questions.map((qid: string) => {
                const answersArray = questions[qid].answers.map((aid: string) => {
                    return answes[aid]
                })
                return { ...questions[qid], answers: answersArray }
            })
            const treedUnit = { ...unit, questions: quesionsArray }

            utils.API.lms.saveUnit(treedUnit).r.then((d: any) => {
                
            })
        } else {
            utils.API.lms.saveUnit(unit).r.then((d: any) => {
                
            })
        }

    }
}

export const saveCourse = (courseId: string) => {
    return (dispatch: any, getState: any) => {
        const store = getState();
        const course = store.LMSTO.courses.values[courseId]
        utils.API.lms.saveCourse(course).r.then((d: any) => {
        })
    }
}

export const sendApproveModeration = (answerId: string, approved: boolean = false, moderationText: string = '') => {
    return (dispatch: any, getState: any) => {
        // const state = getState()
        // const currentUserData = getAuthUser(state).baseData
        // const status = approved ? 2 : 1;
        dispatch(setModerationStatus(answerId, approved))
        utils.API.lms.approveAnswer(answerId, approved, moderationText).r.then((d: any) => {
        })
    }
}

export const createSession = (courseId: string, session: any, fromPreview: boolean = false) => {
    return (dispatch: any, getState: any) => {
        const sessionModel = { ...session }//utils.API.lms.sessionModelCreator()
        const isCreating = sessionModel.id === zeroId
        sessionModel.courseId = courseId;
        sessionModel.id = isCreating ? uuid() : sessionModel.id
        sessionModel.courseSessionId = sessionModel.id
        sessionModel.isNew = isCreating
        dispatch({ type: actions.SET_ALLOW_PLAY, payload: { cid: courseId, isLoading: true, isAllow: false, isTested: false } })
        // sessionModel.userFullList = undefined
        dispatch(isCreating ? addSession(sessionModel) : setSession(sessionModel))
        utils.API.lms.saveSession(sessionModel).r.then((d: any) => {
            if (d && d.error_code && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        toast.error('saveSession, unknown error:', d.error_code)
                        console.warn(`saveSession, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {
                fromPreview ? dispatch(allowPlayCourse(courseId)) : dispatch({ type: actions.SET_ALLOW_PLAY, payload: { cid: courseId, isLoading: false, isAllow: false, isTested: false } })
                dispatch(setSession(d.data))
            } else if (d && d.dom_error === 'AbortError') {
                console.log(`saveSession aborted`)
            } else {
                toast.error('saveSession, internal error');
                console.warn(`saveSession, internal error`, d)
            }
        })
    }
}


export const sendSessionStart = (cid: string, sid: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setSessionStart(cid, sid))
        utils.API.lms.sessionStart(sid).r.then((d: any) => {
        })
    }
}

export const sendSessionStop = (cid: string, sid: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setSessionStop(cid, sid))
        utils.API.lms.sessionStop(sid).r.then((d: any) => {
        })
    }
}

let getCourseStudentsRequest: any = null
export const loadCourseStudents = (cid: string, params: any, clean: boolean = false) => {
    return (dispatch: any, getState: any) => {
        if (getCourseStudentsRequest) getCourseStudentsRequest.ac.abort()
        const store = getState()
        const currentList = store.LMSTO.students.ids
        const skipCount = currentList ? currentList.length : 0
        const p = {
            ...params,
            'filter.count': store.LMSTO.students.count,
            'filter.skipCount': skipCount
        }
        if (clean) {
            p['filter.skipCount'] = 0
            dispatch(setStudentes(cid, {}, [], clean))
        }
        // dispatch(setStudentes(cid, {}, [], clean))
        dispatch(setStudentsLoad())
        getCourseStudentsRequest = utils.API.lms.getCourseStudents(cid, p)
        getCourseStudentsRequest.r.then((d: any) => {
            getCourseStudentsRequest = null
            if (d && d.error_code && d.error_code !== 0) {
                switch (d.error_code) {
                    default: {
                        toast.error('loadCourseStudents, unknown error:', d.error_code)
                        console.warn(`loadCourseStudents, err: ${d.error_code}, server text:`, d.error_text)
                    }
                }
            } else if (d && d.error_code === 0 && d.data) {
                const norml = utils.objectArrayToMap(d.data, 'userId');// normalizeData(d.data, courseStudentsSchema)

                dispatch(setStudentes(cid, norml, Object.keys(norml), clean))
            } else if (d && d.dom_error === 'AbortError') {
                console.log(`loadCourseStudents aborted`)
            } else {
                toast.error('loadCourseStudents, internal error');
                console.warn(`loadCourseStudents, internal error`, d)
            }
        })
    }
}

export const loadSession = (sid: string) => {
    return (dispatch: any, getState: any) => {
        utils.API.lms.getSession(sid).r.then((d: any) => {
            dispatch(setSession(d.data.session))
        })
    }
}

export const _loadStudentSessionsList = (cid: string, uid: string, archive: boolean = false) => {
    return (dispatch: any, getState: any) => {
        utils.API.lms.getSessionForUser(cid, uid, archive).r.then((d: any) => {
            dispatch(setUserSessions(cid, uid, d.data))
        })
    }
}

export const loadStudentResult = (cid: string, uid: string, sid: string) => {
    return (dispatch: any, getState: any) => {
        utils.API.lms.getUserResults(cid, uid, sid).r.then((d: any) => {
            if (d.data.length === 0) return 0;
            // const norml = normalizeData(d.data[0].sessions, courseResutsSchema)
            dispatch(setStudentesResults(cid, d.data[0], uid))
            dispatch(setUsers({ [d.data[0].user.id]: d.data[0].user }))


        })
        // utils.API.lms.getCourseStudents(cid, { 'filter.sessionIds': [sid], 'filter.filterByUserIds': [uid], 'filter.includeUnitStat': true }).r.then((d: any) => {
        //     const norml = normalizeData(d.data, courseResutsSchema)
        // })
    }
}

export const actionSesstionArchive = (cid: string, sid: string, isArchived: boolean) => {
    return (dispatch: any, getState: any) => {
        utils.API.lms.sessionArchivete(sid, isArchived).r.then((d: any) => {
            dispatch(setSessionArchived(cid, sid, isArchived))
        })
    }
}