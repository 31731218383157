import { cn } from '@bem-react/classname'; 
import { IStateType as IState } from 'redux/store';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { addToAdditionalMenu, removeFromAdditionalMenu } from 'utils/src/CommonRedux/base/actions';

export interface IAdditionalMenuProps extends IAdditionalMenuStateToProps, IAdditionalMenuActionsToProps {

};

export const AdditionalMenuStateToProps = (state: IState) => ({
  data: state.store.additionalMenu
});

export type IAdditionalMenuStateToProps = ReturnType<typeof AdditionalMenuStateToProps>;

export const AdditionalMenuActionsToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  addToAdditionalMenu,
  removeFromAdditionalMenu
}, dispatch);

export type IAdditionalMenuActionsToProps = ReturnType<typeof AdditionalMenuActionsToProps>;

export const cnAdditionalMenu = cn('AdditionalMenu');