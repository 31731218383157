import { IEditorProps } from './Editor.index'
import { useSelector } from 'react-redux'
import * as React from 'react'
import './Editor.scss';
import { BadgeSelector } from 'uielements/src/BadgeSelector/BadgeSelector'
import i18n from 'localizations/i18n';
import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'
import { PrnUserInput } from 'blocks/PrnUserInput/PrnUserInput';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';

export const Editor: React.FC<IEditorProps> = ({ children, data, onChangeUsers, onChangeText, onChangeBadge, badgeList }) => {
    const { users = [], groups = [] } = data
    const currentUser = useSelector(getCurrentUser)
    return <div className={'Editor EditorBadge'}>
        {onChangeUsers && <PrnUserInput
            onChange={onChangeUsers}
            className={'EditorBadge-InputMention'}
            value={[...users, ...groups]}
            type={currentUser.baseData.isAdmin ? 'usersgroups' : 'users'}
            excludeUsers={[]}
            excludeMe={true}
            placeholder={i18n.t('pryaniky.createPost.badge.colleagues')} />}

        <PryanikyEditor
            onChange={onChangeText}
            value={data.text}
            disableToolbar={true}
            placeholder={i18n.t('pryaniky.createPost.badge.text')} />

        {onChangeBadge && <BadgeSelector selectedBadge={data.badge} badgeList={badgeList} onChange={onChangeBadge} />}
        {children}
    </div>
}
