import React from 'react';
import { cn } from '@bem-react/classname';
import { IUserBirthday } from 'utils/src/requests/models/api.users';
import { Icon, Button } from 'uielements/src';
import { Avatar } from 'uielements/src';
import './Birthdays-Item.scss';
import { Link } from 'muicomponents/src/Link';
import { UserThankForm } from 'blocks/PryanikyForms/UserThankForm/UserThankForm'
import { Translate } from 'localizations/Translate';
import { Button as MuiButton } from 'muicomponents/src/Button/Button'

const cnBirthday = cn('Birthday');

interface ItemProps {
  language?: string;
  birthday: IUserBirthday;
  className?: string;
  currencyName: string;
  currentUser: any;
  enabledThanks: boolean;
}

export const Item: React.FC<ItemProps> = ({ birthday, className, currentUser, enabledThanks, language }) => {
  const date = new Date(birthday.birthday);
  const day = date.getDate();
  const month = date.toLocaleDateString(language || "ru", { day: 'numeric', month: 'long' }).replace(/\d+/, '').trim()
  const showButton = currentUser.baseData.id !== birthday.id && enabledThanks;
  return (
    <div className={cnBirthday()}>
      <div className={cnBirthday('Inner', {}, [className])}>
        <Link to={`/user/${birthday.id}`} className={cnBirthday('User')}>
          <div className={cnBirthday('Date')}>
            <Icon icon="cake" className={cnBirthday('Cake')} />
            <div className={cnBirthday('Day')}>{day}</div>
            <div className={cnBirthday('Month')}>{month}</div>
          </div>
          <Avatar imgId={birthday.imgId} imgUrl={birthday.imgUrl} size={56} name={birthday.displayName} />
          <div className={cnBirthday('Info')}>
            <h4 className={cnBirthday('Name')} title={birthday.displayName}>
              {birthday.displayName}
            </h4>
            <div className={cnBirthday('Job')} title={birthday.position}>{birthday.position}</div>
            <div className={cnBirthday('Job')} title={birthday.position}>{birthday.division}</div>
          </div>
        </Link>
        <div>
          {showButton && <UserThankForm users={[birthday]} user={birthday} button={
            (openDialog: any, currencyName: any) =>
              <MuiButton className={cnBirthday('User-Gift')} onClick={(e: any) => {
                // e.nativeEvent.stopImmediatePropagation()
                e.stopPropagation()
                e.preventDefault()
                openDialog()
              }} 
                variant={'contained'}
                color={'primary'}
                >
                <Translate i18nKey={'pryaniky.birthdays.give'} values={{ text: currencyName }} />
              </MuiButton>
          } />}
        </div>
      </div>
    </div>
  );
};
