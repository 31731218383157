import React from 'react';
import utils from 'uielements/src/PryanikyEditorV1/VideoPlugin/video/utils/index';
import { IVideoPluginProps } from './DefaultVideoComponent.index'
import { Video } from 'uielements/src'
import { CustomSettings } from 'utils/src';
// import { VideoWithEvts } from 'utils/src.project/events.prn';
const YOUTUBE_PREFIX = 'https://www.youtube.com/embed/';
const VIMEO_PREFIX = 'https://player.vimeo.com/video/';

const getSrc: (obj: any) => any = ({ src, isPryaniky = false, previewUrl = '' }) => {
  const { isYoutube, getYoutubeSrc, isVimeo, getVimeoSrc, isRutube, getRutubeSrc } = utils;
  if (isPryaniky) {
    return src;
  }
  if (isYoutube(src)) {
    const { srcID } = getYoutubeSrc(src);
    return `${YOUTUBE_PREFIX}${srcID}`;
  }
  if (isVimeo(src)) {
    const { srcID } = getVimeoSrc(src);
    return `${VIMEO_PREFIX}${srcID}`;
  }
  if (isRutube(src)) {
    const { srcID } = getRutubeSrc(src);
    return `https://rutube.ru/play/embed/${srcID}`;
  }
  return src;
};


const DefaultVideoCompoent: React.FC<IVideoPluginProps> = ({ blockProps, className = '', style, theme, ...props }) => {

  const src = getSrc(blockProps.data);
  if (blockProps.data.isPryaniky) {
    return (
      <div style={style}>
        {/* <div className={`${theme.iframeContainer} ${className}`}> */}
        <video className={'Video'} style={{ width: '100%', height: 'auto' }}
          controls={true}
          controlsList={CustomSettings.cancelDownloadVideo() && "nodownload" || undefined}
          onContextMenu={(e) => {
            if (CustomSettings.cancelDownloadVideo()) {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }
          }}
          poster={blockProps.data.previewUrl}>
          <source src={blockProps.data.src} />
        </video>
        {/* </div> */}
      </div>)
  }
  if (utils.isRutube(blockProps.data.src)) {
    return (<div style={style}>
      <div className={`${theme.iframeContainer} ${className}`}>
        <iframe
          className={theme.iframe}
          src={src}
          frameBorder="0" 
          allow="clipboard-write; autoplay" 
          // webkitAllowFullScreen 
          // mozallowfullscreen 
          allowFullScreen
        />
      </div>
    </div>
    )
  } else if (src) {
    return (
      <div style={style}>
        <div className={`${theme.iframeContainer} ${className}`}>
          <Video
            className={theme.iframe}
            title={src}
            url={src}
            width={'100%'}
            height={'100%'}
            controls={true}
          />
          {/* <iframe
            className={theme.iframe}
            src={src}
            frameBorder="0"
            allowFullScreen
          /> */}
        </div>
      </div>
    );
  }

  return <div className={theme.invalidVideoSrc}>invalid video source</div>;
};


export default DefaultVideoCompoent;
