import { IField } from 'uielements/src/FieldRender/interfaces'
import { EditorState } from 'draft-js';
import { AnyAction } from 'redux'
export interface ICreateBaseNews {
    draftState: EditorState
}

export interface IContentRating {
    enable: boolean //(ставится синхронно с флагом allowContentRating у товара).
    rating: number //Текущее значение рейтинга товара (среднее по всем оценка в комментариях)
    count: number
}

export interface IActionButtons {
    icon: string
    layout: 'left' | 'right'
    action: AnyAction
}

export interface INewsStatisticsItem {
    name: "web" | "mail",
    count: number;
}

export interface INewsStatistics {
    newsUid: IBaseNews['id'];
    lastNotifyDate: string;
    notifyByChannels: INewsStatisticsItem[];
    plannedNotifyByChannels: INewsStatisticsItem[];
    viewed: INewsStatisticsItem[];
    viewedUnique: INewsStatisticsItem[];
}

export interface IBaseNews {
    achievement?: { [key: string]: any }
    componentRenderName: string;
    id: string;
    text: any;
    isShowOnTop: boolean;
    isSubscribed: boolean;
    liked: boolean;
    isNeedApprove: boolean;
    // isFavorite: boolean;
    likeText: string;
    likeCount: number;
    linkPreviewImgUrl?: string;
    date: string;
    expires?: string;
    thanksCount: number;
    newstype: number;
    attachments?: any;
    user: User;
    users?: User[];
    groups?: Group[];
    allowReply: boolean;
    commentsCount: number;
    systemNewsActionHtml: string;
    header: string;
    tags?: any;
    comments: any[];
    group?: Group | null;
    actions: string[];
    feedback?: IFeedback;
    newsTypeMain?: string;
    isAnonimous?: boolean;
    parentGuid?: string;
    translatedTo?: string;
    allowThanksReply?: boolean;
    changeDate?: string;
    beginValidation?: boolean;
    fromCache?: boolean;
    parentNews?: any
    thanks?: any
    badge?: any
    actionHeader?: INewsActionHeader
    additionalFields: IAdditionalFields | null;
    actionButtons: IActionButtons[]
    timelineInfo?: INewsTimelineInfo
    contentRating?: IContentRating | null
    publishAt?: string | null
    statusFields?: IField.AdditionalField[]
    statusFieldsLoading?: boolean
    userStatuses?: IUserStatus[]
    isDraft?: boolean;
    statistics?: INewsStatistics | null;
    learningTrack?: any
}

export interface IFeedback {
    comment: string
    senderContact: string
    senderId: string | null
    senderName: string
    value: number
}
export interface IUserStatus {
    category: any;
    description: string;
    hasFields: boolean;
    id: string;
    isDisabled: boolean;
    name: string;
    isAllowChangeResponsibleUser?: boolean;
}

export interface IAdditionalFields {
    additionalFieldsValues: IField.AdditionalField[]
    currentStatus: any
    responsibleForStatus: any
    showChangeStatusButton: boolean
    formId?: string;
}

export interface INewsActionHeader {
    text: string
    type: string
    user: User
    textKey: string
    importance: string
}

export interface INewsTimelineInfo {
    value: number;
    transactionType: 'Earned' | 'Given';
    isDeleted: boolean;
}
export interface User {
    id: string;
    displayName: string;
    eMail: string;
    imgId: string;
    sex: string;
    imgUrl: string;
    position: string;
    division: string;
    phone?: any;
    userPhotoUrl?: string;
    isDeleted?: boolean;
}

export interface Group {
    pkid: number;
    name: string;
    alias?: string;
    imgId48x48?: string;
    imgId198x198?: string;
    imgUrl198x198?: string;
    imgUrl48x48?: string;
    imgId?: string;
    imgUrl?: string;
    isSystemGroup?: boolean;
    description?: string;
    isHidden?: boolean;
    isDeleted?: boolean;
    isArchived?: boolean;
    visibilityType?: number;
    childGroupCount?: number;
    displayName?: string;
    usersCount?: number;
}


export const defaultGroup: Group = {
    "pkid": -1,
    "name": "Основная лента",
    "alias": "",
    "imgId48x48": "00000000-0000-0000-0000-000000000000",
    "imgId198x198": "00000000-0000-0000-0000-000000000000",
    "imgUrl198x198": "00000000-0000-0000-0000-000000000000",
    "imgUrl48x48": "00000000-0000-0000-0000-000000000000",
    "isSystemGroup": false,
    "description": "",
    "isHidden": false,
    "isDeleted": false,
    "isArchived": false,
    "visibilityType": 0,
    "childGroupCount": 0,
    usersCount: 0
}

export const defaultUser: User = {
    "id": "",
    "displayName": "",
    "eMail": "",
    "imgId": "",
    "sex": "",
    "imgUrl": "",
    "position": "",
    "division": "",
    "phone": null
}

/**
 * default object template of BaseNews
 */
export const createDefaultNews = (): IBaseNews => ({
    id: "",
    text: "",
    isShowOnTop: false,
    isSubscribed: true,
    attachments: null,
    // "isFavorite": false,
    liked: false,
    isNeedApprove: false,
    likeText: "",
    likeCount: 0,
    date: (new Date()).toISOString(),
    thanksCount: 0,
    newstype: -1,
    user: defaultUser,
    allowReply: true,
    commentsCount: 0,
    systemNewsActionHtml: "",
    header: "",
    actions: [],
    componentRenderName: 'news',
    actionButtons: [],
    comments: [],
    // group: defaultGroup,
    group: null,
    additionalFields: null
})


export const createDefaultCreateNews = (): IBaseNews & ICreateBaseNews => ({
    ...createDefaultNews(),
    draftState: EditorState.createEmpty()
})