import { IFeedbackPropsType, IFeedbackStateType, mapStateToProps } from './Feedback.index'
import * as React from 'react';
import { PostContentRender } from '../../PostContentRender/PostContentRender';
import { Avatar } from 'uielements/src'
import { cloneObject } from 'utils/src/utils';
import { connect } from 'react-redux';
import i18n from 'localizations/i18n'
import smile from 'assets/smiles/smile.png'
import angry from 'assets/smiles/angry.png'
import './Feedback.scss'
import { withNewsContext } from 'blocks/NewsView/NewsView.context'
import TextRender from 'News/containers/TextRender'
export class FeedbackPresenter extends React.Component<IFeedbackPropsType, IFeedbackStateType>  {

  constructor(props: IFeedbackPropsType) {
    super(props)
    this.state = {
      // data: cloneObject(props.data),
    }
  }

  public render() {
    const { data } = this.props;
    const { text, feedback = {value: 0} } = data;

    let feedbackInfo: any
    switch (feedback.value) {
      case -1: {
        feedbackInfo = (
          <div className='FeedbackInfo'>
            <Avatar size={66} imgUrl={angry} />
            <span>{i18n.t('pryaniky.newsType.feedback.angry.text')}</span>
          </div>
        )
        break
      }
      case 1: {
        feedbackInfo = (
          <div className='FeedbackInfo'>
            <Avatar size={66} imgUrl={smile} />
            <span>{i18n.t('pryaniky.newsType.feedback.smile.text')}</span>
          </div>
        )
        break
      }
      case 0: {
        feedbackInfo = <></>
        break
      }
      default:
        feedbackInfo = <></>
        break
    }

    return (
      <React.Fragment>
        {this.props.presentRender(
          <React.Fragment>
            <TextRender />
            {feedbackInfo}
          </React.Fragment>)}
      </React.Fragment>
    )
  }
}

export const Feedback = withNewsContext(connect(
  mapStateToProps
)(FeedbackPresenter));
