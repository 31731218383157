import { IClassNameProps } from '@bem-react/core';
import { cnReply } from '../Reply.index';
import { ReactNode } from 'react';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface ILikeType {
  liked: boolean;
  likeCount: number;
}
export interface IActionsProps extends IClassNameProps, ReturnType<typeof mapStateToProps> {
  rewards?: boolean;
  additionalReplyActions?: (commentID: string, user: any) => ReactNode;
  like: ILikeType;
  newsID: string;
  commentID: string; 
  user: any;
  fund: number;
  setRewardComment: (id: string, count: number) => void;
  deleteRewardComment: (id: string) => void;
}

export interface IActionsState {
  text?: string;
  liked: boolean;
  likeCount: number;
  rewardCount: number;
  rewardOpen: boolean;
  rewardValue: number;
}

export const mapStateToProps = (state: any) => ({
  userData: getAuthUser(state).baseData,
});

export const cnActions = (...data: any) => {
  let name = 'Actions';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnReply(name, ...data)
}