import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface ICourseLayoutProps extends IClassNameProps {
    course?: any;
    aside?: any;
    middle?: any;
    classNames?: string[];
    fullscreen?: boolean;
    setFullscreen?: (fullescreen: boolean) => void
    allowFullscreen?: boolean
}

export interface ICourseLayoutState {

}



export const cnCourseLayout = cn('CourseLayout');