import { IIdeaFieldProps } from '../FieldProps';
import React, { useState } from 'react';
import { Input } from 'uielements/src/Input/Input';
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { Icon } from 'uielements/src';
import { cn } from "@bem-react/classname";
import './String.scss'
import i18n from 'localizations/i18n';
import { isMail, phoneMaskFunc } from 'utils/src/utils';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

export const cnIdeaFieldString = cn("IdeaFieldString");
// import { cloneObject } from 'utils/src/utils';

export const String: React.FC<IIdeaFieldProps> = ({ field, onChange = () => { } }) => {
    const [text, setText] = React.useState({ value: '', valid: true, error: i18n.t('') });

    const getFieldIcon = (mask: string): string => {
        let icon = 'font';
        if (mask === 'phoneru') icon = 'phone';
        if (mask === 'email') icon = 'at';
        return icon;
    }


    const onEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
        field.value = event.target.value
        event.target.onfocus = () => {
            setText({ value: field.value, valid: true, error: '' });
        }
        event.target.onblur = () => {
            if (field.field.isMandatory && field.value === '') {
                setText({ value: field.value, valid: false, error: i18n.t('поле не должно быть пустым') });
                return;
            }
            if (field.field.isMandatory && field.value === '' && field.field.mask === 'email' && !isMail(field.value)) {
                setText({ value: field.value, valid: false, error: i18n.t('введён некорректный email') });
                return;
            }
            setText({ value: field.value, valid: true, error: '' });
        }
        onChange(field);
    }



    return (
        <Input className={cnIdeaFieldString("String")} invalid={!text.valid} errorMessage={text.error} required={field.field.isMandatory} placeholder={field.field.name || field.field.description} value={field.value} onChange={onEdit} icon={getFieldIcon(field.field.mask)} >
            {field.field.description && <Tooltip
                className={cnIdeaFieldString("HelpTooltip")}
                // className={'InfoIcon'}
                title={field.field.description}
            >
                <span><Icon icon={'question-circle'} /></span>
            </Tooltip>}
        </Input>
    )
}