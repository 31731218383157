import React, { FC, useEffect, memo, useState, useMemo } from 'react'
import {
    cnClassName,
    Skills
} from './interfaces'
import './style.scss'
import { Icon } from 'uielements/src'
import { UserList } from 'uielements/src/UserList/UserList';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import i18n from 'localizations/i18n'
import { useRefedCallback } from 'utils/src/hooks'
import { ISkill } from 'redux/sagas/Skills/types'
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { Button } from 'muicomponents/src/Button/Button'
import { Link } from 'muicomponents/src/Link/Link'
import { SplitButton } from 'muicomponents/src/SplitButton/SplitButton'
import { ISplitButtonOption } from 'muicomponents/src/SplitButton/SplitButton.index'
import ButtonGroup from '@mui/material/ButtonGroup';
import { useResizeHandler } from 'utils/src/hooks'
const Alert: FC<AlertProps> = (props) => <MuiAlert elevation={3} variant="standard" {...props} />;

type IPresenterProps = Skills.IPresenterProps

type SkillsItemMethods = {
    confirmSkill: (userId: string, skillId: string, confirmedByMe: boolean) => void;
    deleteSkill: (userId: string, skillId: string) => void
    setShow: (skillId: string) => void
}
const useSkillsItem = (skill: ISkill, userid: string, { confirmSkill, deleteSkill, setShow }: SkillsItemMethods) => {
    const [del, setDel] = useState(false)

    useEffect(() => {
        setDel(skill.confirmCount === 0)
    }, [skill])

    const skillId = skill.tag.id,
        confirmedByMe = skill.confirmedByMe,
        confirmCount = skill.confirmCount

    const onChangeConfirm = useRefedCallback(({ current }) => () => confirmSkill(current.userid, current.skillId, !current.confirmedByMe),
        { userid, skillId, confirmedByMe });

    const onCancleDelete = useRefedCallback(({ current }) => () => confirmSkill(current.userid, current.skillId, current.confirmCount === 0 ? true : current.confirmedByMe),
        { userid, skillId, confirmedByMe, confirmCount });

    const onDelete = useRefedCallback(({ current }) => () => deleteSkill(current.userid, current.skillId),
        { userid, skillId });

    const actionCallback = useRefedCallback(({ current }) => () => setShow(current.skillId),
        { skillId })

    return {
        del,
        onChangeConfirm,
        onCancleDelete,
        onDelete,
        actionCallback,
        setDel
    }
}

export const Presenter: FC<IPresenterProps> = ({
    className,
    skill,
    index,
    confirmSkill,
    userid,
    deleteSkill,
    showControls,
    setShow
}) => {

    const {
        del,
        actionCallback,
        onChangeConfirm,
        onCancleDelete,
        onDelete,
        setDel
    } = useSkillsItem(skill, userid, { confirmSkill, deleteSkill, setShow })

    const [showActionButton, setShowBtn] = useState(false)

    const ref = useResizeHandler((el) => {
        if (el.clientWidth < 500) {
            setShowBtn(false)
        } else {
            setShowBtn(true)
        }
    })

    const confirmBtr = useMemo(() => showActionButton ? (<>
        <Button variant={skill.confirmedByMe ? 'contained' : 'outlined'} size={'small'}
            className={cnClassName('ConfirmBtn')}
            onClick={onChangeConfirm}>
            {skill.confirmedByMe ?
                <>
                    <span className={cnClassName('ConfirmText')} >{i18n.t("pryaniky.skills.ConfirmBtn.off.1")}</span>
                    <Icon className={cnClassName('ConfirmIcon')} icon={'times'} />
                </> :
                <>
                    <span className={cnClassName('ConfirmText')}>{i18n.t("pryaniky.skills.ConfirmBtn.on.1")}</span>
                    <Icon className={cnClassName('ConfirmIcon')} icon={'check'} />
                </>}
        </Button>
        <Button variant={'outlined'} size={'small'}
            className={cnClassName('ConfirmByBtn')}
            onClick={() => setShow(skill.tag.id)}>
            <Icon icon={'user-check'} />
        </Button>
    </>) : undefined, [skill.confirmedByMe, showActionButton, skill.tag.id])

    const options = useMemo(() => (showControls ?
        [
            !showActionButton && {
                id: 'onChangeConfirm',
                content: skill.confirmedByMe ? i18n.t("pryaniky.skills.ConfirmBtn.off.1") : i18n.t("pryaniky.skills.ConfirmBtn.on.1"),
            },
            !showActionButton && {
                id: 'user-check',
                content: <Icon icon={'user-check'} />//i18n.t("pryaniky.skills.delete.1"),
            },
            {
                id: 'delete',
                content: i18n.t("pryaniky.skills.delete.1"),
            }
        ].filter(Boolean) :
        []), [showActionButton, skill.confirmedByMe]) as ISplitButtonOption[]

    const handleClickItem = (item: any) => {
        if (item.id === 'delete') {
            setDel(true)
        } if (item.id === 'onChangeConfirm') {
            onChangeConfirm()
        } if (item.id === 'user-check') {
            setShow(skill.tag.id)
        }
    }

    return <tr ref={ref as any} className={cnClassName({}, [className])}>
        {!del &&
            <>
                <td className={cnClassName('Index')}>
                    {skill.onModeration ?
                        <Tooltip
                            className={'InfoIcon'}
                            title={i18n.t('pryaniky.skills.onModeration.help.1')}
                        >
                            <span><Icon icon={'eye-crossed'} /></span>

                        </Tooltip> :
                        <span>{index}.</span>}
                </td>
                <td className={cnClassName('Title')}>
                    <Link
                        // type={'rlink'}
                        href={`/tag/${skill.tag.id}/people?collectionType=skills`}
                        className={cnClassName('TagLink')}
                        // theme={'unstyled'}
                        title={skill.tag.displayName}>#{skill.tag.displayName}</Link>
                </td>
            </>
        }
        {!del ?
            <td className={cnClassName('Users')}>
                <UserList
                    className={cnClassName('UserList')}
                    cut={false}
                    autoHide={true}
                    underNext={true}
                    action={skill.confirmCount > 10}
                    actionBtnContent={<span className="AnswersValue">+{skill.confirmCount}</span>}
                    data={skill.confirmedBy}
                    actionCallback={actionCallback} />
            </td> :
            <td className={cnClassName('AlertCell')} colSpan={4}>
                <Alert severity="warning" className={cnClassName('Alert')}>
                    <div className={cnClassName('AlertContent', {small: !showActionButton})}>
                        <div className={cnClassName('AlertText')}>{i18n.t("pryaniky.skills.confirmDelete.1", { title: skill.tag.displayName })}</div>
                        <ButtonGroup variant="contained" >
                            <Button className={cnClassName('Btn')} size={'small'} variant={'outlined'} onClick={onDelete}>{i18n.t("pryaniky.skills.deleteBtn.on.1")}</Button>
                            <Button className={cnClassName('Btn')} size={'small'} variant={'contained'} onClick={onCancleDelete}>{i18n.t("pryaniky.skills.deleteBtn.off.1")}</Button>
                        </ButtonGroup>
                    </div>
                </Alert>
            </td>
        }
        {!del &&
            <td className={cnClassName('Action', {small: !showActionButton})}>
                <div className={cnClassName('Controls')}>
                    {showControls ? <SplitButton
                        className={cnClassName('ActionMenu')}
                        size={'small'}
                        options={options}
                        handleMenuItemClick={(e, i) => handleClickItem(i)}
                    >
                        {confirmBtr}
                    </SplitButton> :
                        confirmBtr
                    }
                </div>
            </td>
        }
    </tr>
}

export default memo(Presenter);
