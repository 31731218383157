import React, { useCallback, useMemo, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { cnRatingBadgeWidget, mapDispatchToProps, mapStateToProps, IRatingBadgeWidget } from './RatingBadgeWidget.index'
import i18n from 'localizations/i18n'
import { Translate } from 'localizations/Translate';
import * as utils from "utils/src/utils";
import { BorderLinearProgress, BadgeAvatar, StyledPaper, ProgressBox } from './RatingBadgeWidget.styled'
import { Box, Typography } from 'muicomponents/src'
import { Avatar } from 'muicomponents/src/Avatar/Avatar'
import { Slider } from 'muicomponents/src/Slider/Slider'
import { styled } from 'muicomponents/src/mui/system';
import Substract from './Subtract.svg'
import { IRatingValue } from 'utils/src/requests/models/api.ratings';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Tooltip from 'muicomponents/src/Tooltip/Tooltip'
import { LinearProgress } from 'muicomponents/src/LinearProgress/LinearProgress'


import { Link } from 'muicomponents/src/Link/Link'
import "./RatingBadgeWidget.scss"
import { Avatar as OldAvatar } from 'uielements/src/Avatar/Avatar';
import noData from 'assets/svg/NoData.svg'

const location = `url(${Substract})`

const RatingBadgeWidgetPresenter = (props: IRatingBadgeWidget) => {
    const { tag: Tag = 'div',
        settings,
        currentUser,

    } = props
    const [ratingData, setRatingData] = useState<IRatingValue>()
    const [loading, setLoading] = useState<boolean>(true)
    const [width, setWidth] = useState<null | number>(null);
    // uid:currentUser, rids: settings.rId.toString(), opts:{includeBadges:true}
    const userID = currentUser.id === settings.uId ? currentUser.id : settings.uId

    const getRating = () => {

        utils.API.ratings.byUser(userID, settings.rId?.toString(), { includeBadges: true })
            .r
            .then((d: any) => {

                if (utils.checkResponseStatus(d)) {
                    setLoading(false)
                    setRatingData(d.data[0]?.ratingValues[0])
                }
            }).catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getRating()
    }, [])

    const elRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        elRef.current && setWidth(elRef.current.clientWidth || 0);
    }, [elRef.current, ratingData]);

    if (settings?.hideIfNoData && !ratingData) return null;
    if (!settings?.hideIfNoData && !ratingData && !loading) return <StyledPaper className={cnRatingBadgeWidget("EmptyItem")}>
        <Box sx={{
            textTransform: "inherit",
            margin: "6.5px 0",
            fontSize: "16px",
            fontWeight: "700",
            color: "#000",
            lineHeight: "116%",

        }}
            className={cnRatingBadgeWidget('Header-Title')}>
            <Translate i18nKey={'pryaniky.widgets.create.name.pryaniky/ratingBadge'} />
        </Box>
        {/* <OldAvatar precentage={100} shape={'rectangle'}
            imgUrl={noData}
        /> */}


        <span className={cnRatingBadgeWidget('Header-Title-Text')}>  <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} /></span>
    </StyledPaper>;

    const sortBadges = ratingData?.badges && ratingData?.badges?.sort(function (a, b) {
        if (a.value > b.value) {
            return 1;
        }
        if (a.value < b.value) {
            return -1;
        }
        // a должно быть равным b
        return 0;
    }) || [];


    const percent = (value: number) => Number(value) === 0 ? 0 : Number(value) / Number(ratingData?.maxValue) * 100

    const closestRight = sortBadges && sortBadges?.filter(v => v.value > Number(ratingData?.curValue));

    return (
        loading ? <StyledPaper>
            <LinearProgress />
        </StyledPaper> : <StyledPaper className={cnRatingBadgeWidget("")}>

            <Box className={cnRatingBadgeWidget("ContentBox")} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between " }}>
                <Box className={cnRatingBadgeWidget("ContentBox-InfoBox")} sx={{ display: "flex", alignItems: "center", }}>
                    <Link href={`/rating/${ratingData?.id}`}>
                        <Avatar className={cnRatingBadgeWidget("ContentBox-InfoBox-BadgeAvatar")} sx={{ width: "40px", marginRight: "24px" }} src={ratingData?.imgUrl} />
                    </Link>
                    <Box >
                        <Link sx={{  textDecoration: "none", color: "#000" }} href={`/rating/${ratingData?.id}`}>
                            <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>{ratingData?.name}</Typography>
                        </Link>
                        <Box className={cnRatingBadgeWidget("ContentBox-InfoBox-ToTheNextRevardLeft")} sx={{  alignItems: "center", fontSize: "14px" }}>
                            <Typography variant='body2' ><Translate i18nKey={'pryaniky.rating.with.badges.left'} /></Typography>
                            <Typography variant='body2' sx={{ marginLeft: "5px", fontWeight: "600", }}>
                                {(closestRight && closestRight?.length && ratingData) && closestRight[0]?.value - ratingData?.curValue}
                            </Typography>
                        </Box>
                        <Box className={cnRatingBadgeWidget("ContentBox-InfoBox-CurrValueAndLeftValue")} sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
                            <Typography variant='body2'>{ratingData?.curValue}</Typography>
                            <Typography variant='body2' sx={{ color: "rgba(0, 0, 0, 0.6)", }}>/{ratingData?.maxValue}</Typography>
                        </Box>
                    </Box>
                </Box>



                {/* <Box sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
                    <Typography variant='body2'>{ratingData?.curValue}</Typography>
                    <Typography variant='body2' sx={{ color: "rgba(0, 0, 0, 0.6)", }}>/{ratingData?.maxValue}</Typography>
                </Box> */}

            </Box>
            <ProgressBox
                className={cnRatingBadgeWidget("ContentBox-ProgressBarBox")}
                ref={elRef}
                sx={{
                    ".RatingBadgeWidget-BadgeLocationBoxLast": {
                        ".RatingBadgeWidget-Location": {
                            left: width && `${width - 15}px!important`,
                            // left: `${100 - 1.2}%!important`,
                        },
                        ".RatingBadgeWidget-BadgeAvatar": {
                            // left: `${100 - 1.8}%!important`,
                            left: width && `${width - 20}px!important`,

                        },
                        ".RatingBadgeWidget-BadgeAvatarBig": {
                            left: width && `${width - 40}px!important`,
                            // left: `${100 - 2}%!important`,
                        }
                    }
                }}
            >

                <BorderLinearProgress
                    className={cnRatingBadgeWidget("ContentBox-ProgressBarBox-Progress")}
                    ref={elRef}
                    sx={{
                        backgroundColor: `#E0E0E0`,
                        '.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate, .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar2Indeterminate': {
                            backgroundColor: `${ratingData?.color}`
                        },

                    }}
                    // variant={!width ? "indeterminate" : "determinate"}
                    variant={"determinate"}

                    value={Number(ratingData?.curValue) === Number(ratingData?.maxValue) ? 100 : (width && width > 883  && Number(ratingData?.curValue) !== 0) ? percent(Number(ratingData?.curValue) )+ 0.5 :
                        (width && width > 400 && width < 883 && Number(ratingData?.curValue) !== 0) ? percent(Number(ratingData?.curValue)) + 1.2 : (width && width < 400 && Number(ratingData?.curValue) !== 0) ? percent(Number(ratingData?.curValue)) + 1.8 : percent(Number(ratingData?.curValue))
                    }

                />

                {(width && sortBadges && sortBadges.length > 0) && sortBadges.map((el, index) => {

                    const milesLeft = el.value > Number(ratingData?.curValue) ? el.value - Number(ratingData?.curValue) : el.value;


                    const rightFormOfWord = () => {

                        const lastNumberIndex = milesLeft?.toString().length - 1;
                        const lastElementToNumber = Number(milesLeft?.toString()[lastNumberIndex]);

                        const achivenmentForm = (milesLeft >= 10 && milesLeft <= 20) ? "миль" : lastElementToNumber === 1 ?
                            i18n.t('pryaniky.rating.with.badges.miles.0') : lastElementToNumber > 1 && lastElementToNumber < 5 ?
                                i18n.t('pryaniky.rating.with.badges.miles.1') : (lastElementToNumber >= 5 || lastElementToNumber === 0) ? i18n.t('pryaniky.rating.with.badges.miles') : i18n.t('pryaniky.rating.with.badges.miles')
                        return achivenmentForm

                    };

                    return (
                        <Box
                            className={cnRatingBadgeWidget(el.value === Number(ratingData?.maxValue) ? `BadgeLocationBoxLast` : "BadgeLocationBox")}>
                            <Box
                                sx={{

                                    width: "16px",
                                    height: "20px",
                                    "&:hover .Box.RatingBadgeWidget-BadgeAvatarBig.MuiBox-root": {
                                        opacity: "1!important"
                                    },

                                    " .Box.RatingBadgeWidget-BadgeAvatarBig.MuiBox-root:hover": {
                                        opacity: "0!important"
                                    },

                                }}
                            >
                                <Tooltip
                                    arrow
                                    sx={{ border: "none" }}
                                    placement={"top"}
                                    disableInteractive
                                    title={el.value <= Number(ratingData?.curValue) ?
                                        <Translate i18nKey={'pryaniky.rating.with.badges.tooltip'} values={{ badgeName: el.badges[0]?.displayName, past: milesLeft, count: rightFormOfWord() }} /> :
                                        <Translate i18nKey={'pryaniky.rating.with.badges.future.tooltip'} values={{ badgeName: el.badges[0]?.displayName, past: milesLeft, count: rightFormOfWord() }} />
                                    }>
                                    <LocationOnIcon
                                        className={cnRatingBadgeWidget(el.value === Number(ratingData?.maxValue) ? `Location` : "Location")}
                                        sx={{

                                            position: 'absolute',
                                            top: "-59px",
                                            boxSizing: "border-box",
                                            zIndex: "1",
                                            width: "16px",
                                            height: "104px",
                                            color: el.value <= Number(ratingData?.curValue) ? ratingData?.color+"!important" : "#9E9E9E!important",
                                            left: `${percent(el.value)}%`,




                                        }} />
                                </Tooltip>


                                <Box
                                    className={cnRatingBadgeWidget("BadgeAvatarBig")}
                                    sx={{
                                        position: 'absolute',
                                        top: "-68px",
                                        boxSizing: "border-box",
                                        zIndex: "1",
                                        width: "65px",
                                        height: "65px",
                                        color: el.value <= Number(ratingData?.curValue) ? ratingData?.color : "#9E9E9E",
                                        left: width && width > 883 ? `${percent(el.value) - 2}%` : (width && width > 400 && width < 883) ? `${percent(el.value) - 3}%` : `${percent(el.value) - 6}%`,
                                        // left: width && width > 883 ? `${percent(el.value) - 1}%` : (width && width > 400 && width < 883) ? `${percent(el.value) - 1.6}%` : `${percent(el.value) - 3.3}%`,
                                        backgroundImage: `url(${el.badges[0]?.iconUrl})`,
                                        opacity: "0",
                                        borderRadius: "50%",
                                        backgroundSize: "145%",
                                        backgroundPositionX: "center",
                                        backgroundPositionY: "center",
                                        filter: el.value <= Number(ratingData?.curValue) ? "grayscale(0)" : "grayscale(1)",

                                        // ".RatingBadgeWidget-BadgeLocationBoxLast": {
                                        //     "&": {
                                        //         left: `${percent(el.value) - 4.6}%`,
                                        //     }
                                        // }
                                    }} />

                            </Box>

                            <BadgeAvatar
                                className={cnRatingBadgeWidget("BadgeAvatar")}
                                sx={{
                                    backgroundImage: `url(${el.badges[0]?.iconUrl})`,
                                    // left: `${percent(el.value) - 0.6}%`,
                                    left: width && width > 883 ? `${percent(el.value) - 0.6}%` : (width && width > 400 && width < 883) ? `${percent(el.value) - 0.9}%` : `${percent(el.value) - 2.6}%`,

                                    filter: el.value <= Number(ratingData?.curValue) ? "grayscale(0)" : "grayscale(1)",
                                    // ".RatingBadgeWidget-BadgeLocationBoxLast": {
                                    //     "&": {
                                    //         left: `${percent(el.value) - 4.6}%`,
                                    //     }
                                    // }
                                }} />
                        </Box>)
                })}

            </ProgressBox>
        </StyledPaper >
    )
}

export const RatingBadgeWidget = connect(
    mapStateToProps,
    mapDispatchToProps
)(RatingBadgeWidgetPresenter)

