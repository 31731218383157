import React, { FC, memo, useCallback, useState } from 'react'
import {
    cnClassName,
    IPresenterProps,
    IOwnProps,
} from './interfaces'
import './style.scss'
import { controlStyle, peperStyle, controlLabelStyle } from './style'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import PaperO from '@material-ui/core/Paper';
import { styled } from '@material-ui/core/styles';
import {
    SortableHandle,
} from 'react-sortable-hoc';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import DeleteButton from '../../../components/DeleteButton'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

const Paper = styled(PaperO)(peperStyle)

const DragHandle = SortableHandle(() => <IconButton><DragIndicatorIcon /></IconButton>);

export const Presenter: FC<IPresenterProps> = ({
    className,
    qid,
    isNew = false,
    header,
    deleteQuestion,
    number,
    withAnswerError,
    errors,
    answersErrors,
    children
}) => {
    const [expanded, setExpanded] = useState(isNew)

    const onDelete = () => deleteQuestion(qid)

    let withErrors = withAnswerError
    withErrors = Object.values(errors).filter((v: any) => (typeof v !== 'undefined')).length !== 0 ? true : withErrors
    withErrors = answersErrors.length !== 0 ? true : withErrors

    return <ExpansionPanel TransitionProps={{ unmountOnExit: true }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Grid container alignItems="center">
                <Grid item xs={1}>
                    <DragHandle />
                </Grid>
                <Grid item xs={1}>
                    {withErrors ?
                        <ErrorOutlineOutlinedIcon color="secondary" />
                        :
                        <Typography>№{number}</Typography>
                    }
                </Grid>
                <Grid item xs={9}>
                    <Typography>{header}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <DeleteButton onConfirm={onDelete} />
                </Grid>
            </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>

            <div className={cnClassName({}, [className])}>

                <Paper /*variant="outlined"*/ square>
                    {children}
                </Paper>
            </div>

        </ExpansionPanelDetails>
    </ExpansionPanel>

}

export default memo(Presenter);
