import React from 'react';
import { cn } from "@bem-react/classname";

export interface IColumnChangerProps extends React.InputHTMLAttributes<HTMLDivElement> {
  onMove: (position: number) => void
}

export interface IColumnChangerState {

}

export const cnColumnChanger = cn('ColumnChanger');