import React, { FC, useState } from 'react'
import './Years.css'
import { Button, Icon } from 'uielements/src'

export const cellRender = (date: Date, select: (date: number) => void, yaer?: number, minDate?: Date) => {
    if (!yaer) return '';    
    let className = 'CellItem CellItem_years'
    if (date.getUTCFullYear() === yaer) className += ' selected-date';
    const currentDate = new Date();
    if (yaer === currentDate.getUTCFullYear()) className += ' current-date';
    const disabled = minDate ? minDate.getUTCFullYear() > yaer : false
    if (disabled) className += ' disabled-date';
    return (<div className={className} onClick={() => !disabled && select(yaer)}>{yaer}</div>)
}

export const Years: FC<any> = ({ className, jumpTo, prev, next, date, minDate, ...props }) => {
    const years: any = [
        [4, 3, 2],
        [1, 0, -1],
        [-2, -3, -4],
    ]
    const year = date.getFullYear();
    return (
        <div className={className}>
            <div className='BtnBox'>

                <Button theme={'unstyled'} onClick={prev}><Icon icon='arrow-alt-left'/></Button>

                <span className={'Calendar-Title'}>{date.getUTCFullYear() - 4} - {date.getUTCFullYear() + 4}</span>

                <Button theme={'unstyled'} onClick={next}><Icon icon='arrow-alt-right' /></Button>

            </div>
            <table>
                <tbody>
                    {years.map((val: any, i: number) => <tr key={i}>
                        {val.map((v: any, i: number) => <td key={i}>
                            {cellRender(date, jumpTo, year - v, minDate)}
                        </td>)}
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}
