import React, { FC, memo, useState, useCallback, useMemo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext } from '../../contexts/news'
import { Actions } from 'blocks/NewsView/Actions/Actions';
import { Like } from 'blocks/NewsView/Actions/Like/Like';
// import { NewsLikers } from 'blocks/Dialogs/News/Likers/NewsLikers';
import { Reply as ReplyAction } from 'blocks/NewsView/Actions/Reply/Reply'
import { withReplyContext } from '../../contexts/reply'
import { withComponentEnjector } from 'utils/src/ComponentInjector'

type IPresenterProps = News.ReplyActions.IPresenterProps
type IOwnProps = News.ReplyActions.IOwnProps
type IDispatchProps = News.ReplyActions.IDispatchProps
type IStateProps = News.ReplyActions.IStateProps
type IState = News.ReplyActions.IState

const noopObj: any = {}

export const Presenter: FC<IPresenterProps> = ({
    className,
    replyId,
    newsId,
    allowReply,
    reply,
    insertMention,
    sendLikeReply,
    injectionNodes
}) => {
    const { user, likeCount, liked } = reply


    const replyAnswer = useCallback(() => {
        const { displayName, id, userPhotoUrl, imgUrl } = user;
        insertMention({
            newsId,
            mention: {
                name: displayName,
                link: '/user/' + id,
                avatar: imgUrl || userPhotoUrl,
            }
        })
    }, [user, newsId])

    const actionLike = useCallback((likeCount: number, liked: boolean) => {
        sendLikeReply({
            likeCount,
            liked,
            newsId,
            replyId
        })
    }, [newsId, replyId])

    const buttonProps = useMemo(() => ({ 'data-liked': liked }), [liked])

    return <Actions className={cnClassName({}, [className])}>
        {allowReply && <ReplyAction onClick={replyAnswer} />}

        <Like
            newsId={newsId}
            replyId={replyId}
            buttonProps={buttonProps}
            likeCount={likeCount}
            liked={liked}
            onClick={actionLike}
        />

        {injectionNodes}
    </Actions>
}

export default withReplyContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withComponentEnjector(memo(Presenter), [], (props) => ['news_additional_reply_action_' + props.newsId, 'news_additional_reply_action'])));

