/**
 * @packageDocumentation
 * @module Widget_Settings_quests
 */
import { JFdictionary } from "../dictionary";
import i18n from "localizations/i18n";

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    showImg: {
      type: types.boolean
    },
    img: {
      type: types.object,
      // require: true
    },
   
  }
}
