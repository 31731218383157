import React, { FC, useEffect, useState } from 'react'
import { cnItem, mapDispatchToProps, mapStateToProps } from './Item.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Item.scss'
import { Button, Icon } from 'uielements/src';
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu';

export const ItemPresenter: FC<any> = ({ className, course }) => {
    return <div className={cnItem()}>
        <PopupMenu
            noFixed={true}
            noPadding={true}
            className={cnItem('ActionsMenu')}
            btnRender={(toggleOpen: any, ref: any, isOpen: boolean) =>
                <Button forwardedRef={ref} onClick={toggleOpen} className={cnItem('ActionsToggle', { open: isOpen })} >
                    <Icon icon={'ellipsis-v'} className={cnItem('ActionsIcon')} />
                </Button>
            }
        >
            <ul className={cnItem('ActionsList')}>

                {/* {this.props.injectionNodes.map((node, idx) => <li className={cnItem('ActionsItem')} key={idx}>{node}</li>)} */}
                {/* {this.props.injectionComponents.map((Component, idx) => <li className={cnItem('ActionsItem')} key={idx}>
                    <Component {...this.props} />
                </li>)} */}

                <li className={cnItem('ActionsItem')}>
                    <Button theme={'unstyled'}>Edit</Button>
                </li>

            </ul>
        </PopupMenu>
        <img src={course.largeImgUrl} className={cnItem('Image')} />
        <div className={cnItem('Info')}>
            <h3 className={cnItem('Title')}>{course.name}</h3>

            {/* <div className={cnItem('Section')}>
                <div className={cnItem('UnitCount')}>{courseunitCount} уроков</div>
                {session && <Deadlines session={session} className={cnItem('Deadlines')} />}
            </div>

            <Progress className={cnItem('Progress')} currentStep={course.courseCompletedUnits} totalSteps={course.unitsCount} />

            <AuthorsList authors={course.authors} className={cnItem('AuthorsList')} limit={5} /> */}
            {/* {course.authors.map((user: any) => <Admin className={cnItem('Admin')} admin={user} />)} */}
        </div>

        <div className={cnItem('ActionBox')}>
            <Button className={cnItem('Start')} href={`/TeacherOffice/Course/${course.id}`} type="rlink" main padding="md">
                {tT('course_list.card.item.open.1')}
            </Button>
        </div>
    </div>
}

export const Item = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ItemPresenter));
