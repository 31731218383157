import * as React from 'react';
import { IWidgetTypeInfoProps } from './Widget_type_infoData.index';
import { default as WidgetPresenter } from '../Widget';
import './Widget_type_BadgesNew.scss';
import { widgets } from "i.widgets";
import { BadgeWidgetMUIContainer } from 'blocks/BadgeWidgetMui/BadgeWidgetMuiContainer';
import { cnWidget } from '../Widget.index';

const types = {
  common: BadgeWidgetMUIContainer,
}

const type = 'BadgesNew'

export default class WidgetTypeInfoPresenter extends WidgetPresenter<IWidgetTypeInfoProps> {

  public render() {
    if (!this.props.widget) return null;
    const Component = this.getComponent(types);
    return <Component {...this.props}
      settings={this.props.widget.settings}
      context={this.props.wcontext}
      tag={widgets.components.common}
      className={cnWidget({ type })}
    />
  }
}
