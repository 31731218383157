import { Translate } from "localization";
import { Typography } from "muicomponents/src";
import React, { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import QrCodeIcon from '@mui/icons-material/QrCode';
import { getUserById } from "utils/src/CommonRedux/users/selectors";
import { WalletLine } from "./WalletLine/WalletLine";
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ChatIcon from '@mui/icons-material/Chat';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import CurrencyTooltip from "blocks/CurrencyTooltip";
import { DescriptionLine } from "./DescriptionLine/DescriptionLine";
import CakeIcon from '@mui/icons-material/Cake';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { getAppSettings, moduleIsEnabled } from "utils/src/CommonRedux/base/selectors";
import { userQRModal } from 'muicomponents/src/QRDialog/QRDialog';
import moment from "moment";
import { getUser, subscribeByUser } from "utils/src/CommonRedux/users/actions";
import { IStateType } from "redux/store";
import { push } from 'connected-react-router';
import { ThankForm } from "./ThankForm/ThankForm";
import { EditButton, IconWrapper, PaperBox, PaperMarginBox, QRButtton, StyledAvatar } from "./styled";
import AvatarBlockSkeleton from "./Skeleton/AvatarBlockSkeleton";
import { IAvatarBlockProps } from ".";
import { AskingHand } from "./ThankForm/AskingHand";
import { Link } from "muicomponents/src/Link/Link";
import { WalletsMui5 } from "blocks/WalletsNew/WalletsNew";
import { getAuthUser } from "utils/src/CommonRedux/base/actions";
import { BusinessCardDialog } from "blocks/InfoBlock/User/BusinessCardDialog";
import { CustomSettings } from "utils/src";
import { Badge as BadgeIcon } from "muicomponents/src/Icons";
import { Avatar } from "muicomponents/src/Avatar";
import { Box } from "muicomponents/src/Box";
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { ActivityBlock } from './ActivityBlock/ActivityBlock';
import { PhotoModerationBlock } from "./PhotoModerationBlock/PhotoModerationBlock";


const replaceDateCommand = (date: moment.Moment, str: string) => {
  const commands = [
    {
      command: '%fromNow%',
      action: () => `${(date.diff(new Date(), 'years') * -1)}`
    },
    {
      command: '%disabled%',
      action: () => ''
    }
  ]
  return commands.reduce((prev, val) => {
    return prev.replace(val.command, val.action())
  }, str)
}

const birthdayFormater = (birthday: string, birthdayFormat: string = 'D MMMM') => {
  if ((birthday === "0001-01-01T00:00:00.000Z" || birthday === "1900-01-01T00:00:00.000Z")) return ''
  const momentBirthday = moment(new Date(birthday))
  return replaceDateCommand(momentBirthday, momentBirthday.format(birthdayFormat || 'D MMMM'))
}

interface IUser {
  baseData: { [key: string]: any },

}

export const AvatarBlock: FC<IAvatarBlockProps> = ({ context }: IAvatarBlockProps) => {

  const dispatch = useDispatch()
  const userId = context.uId
  const user = useSelector(getUserById(userId, true))
  const authUser: IUser = useSelector(getAuthUser)
  const authUserId = authUser?.baseData.id
  const statusBadgesIds = user?.profileData.statusBadgesIds
  const statusBadges = user?.profileData.statusBadges
  const isUserPageOwner = userId === authUserId
  const isAuthUserAdmin = authUser?.baseData.isAdmin === true
  const isChatEnable = useSelector((state: IStateType) => state.store.appSettings!.enabledModules.chat)
  const enabledThanks = useSelector((state: IStateType) => state.store.appSettings!.enabledModules.thanks)
  const birthdayFormat = useSelector(getAppSettings).birthdayFormat
  const businesscardsModuleIsActive = useSelector(moduleIsEnabled('businesscards'));
  const isPhotoOnModeration = (isUserPageOwner || isAuthUserAdmin) && user?.profileData.userModeratedPhotoUrl;


  const StatusBadgesComponent = useMemo(() => {
    if (statusBadges && statusBadges.length > 0) {
      return <Box sx={{ display: "flex", flexDiraction: "column", position: "absolute", bottom: 0, left: "-12px" }}>
        {statusBadges?.filter((_: any, idx: number) => idx < 6).map((el: any, index: number) => {
          const zindex: number = 100 - index;
          return (<Tooltip title={el.name} placement="right">
            <span style={{ position: "absolute", bottom: `calc(32px*${index})`, zIndex: zindex }}>
              <Link href={`/badge/${el.badgeUid}`}>

                <Avatar sx={{ width: "40px", height: "40px", border: "2px solid #fff", cursor: "pointer", boxSizing:"border-box" }} srcSet={el.iconUrl} variant="circular" />
             
              </Link>
            </span>
          </Tooltip>)
        })}
      </Box>
    }
  }, [statusBadges])

  useEffect(() => {
    if (!user) {
      dispatch(getUser(userId))
    }
  }, [userId])

  // для удаления кастомной структуры страницы пользователя и возврата к дефолтной структуре. 
  // Нужно для тестирования редактирования структур страниц старого\нового дизайнов профиля
  /* const appPages = useSelector((state: IStateType) => state.widgets.pages)
  const pages = Object.keys(appPages)
  const page = pages[0] 
  const deleteStr = () => {
    utils.API.pages.deletePage("d3d3f6b0-7feb-11ee-b926-8175f578d617").r.then((d) => {
    })
  } */

  const userGroupedFileds = useMemo(() => {
    const fields = user?.baseData?.userListFieldsOriginal?.filter((el: any) => {
      return el.field.fieldType === 'String'
        && el.isGrouped
    });
    return fields || [];
  }, [user?.baseData?.userListFieldsOriginal]);

  const phonesGroupedFields = useMemo(() => {
    return userGroupedFileds.filter((el: any) => {
      return el.field.fieldType === 'String'
        && (
          el.field.mask === 'phone'
          || el.field.mask === 'telefon'
          || el.field.propertyName.includes('Phone')
        )
    });
  }, [userGroupedFileds]);

  const emailsGroupedFields = useMemo(() => {
    return userGroupedFileds.filter((el: any) => {
      return el.field.fieldType === 'String'
        && (
          el.field.mask === 'email'
          || el.field.propertyName.includes('Email')
        )
    });
  }, [userGroupedFileds]);

  if (!user) return <AvatarBlockSkeleton />

  const allowEdit = () => {
    if (isAuthUserAdmin) return true;
    return isUserPageOwner;
  }
  const allowViewCurrency = (user: any) => {
    if (allowEdit()) return true;
    return user.baseData.isCurrencyShowsForAll
  }
  const subscribe = () => {
    dispatch(subscribeByUser(user))
  }
  const formatedBirthday = birthdayFormater(user.extData.birthday, birthdayFormat);
  return <>
    <PaperBox>
      <Box sx={{ position: "relative" }}>
        <StyledAvatar srcSet={isPhotoOnModeration?user.profileData.userModeratedPhotoUrl: user.baseData.userLargePhotoUrl} variant="square" />
        <ActivityBlock sex={user.baseData.sex} lastActivityDate={user.profileData?.lastActivityDate || null} />
        <PhotoModerationBlock photoOnModeration={isPhotoOnModeration} />
        {StatusBadgesComponent}
      </Box>

      {allowViewCurrency(user) &&
        <>
          <WalletLine
            amount={user.extData.mythanksCount}
            icon={<AccountBalanceWalletIcon />}
            label={<Translate i18nKey='pryaniky.widgets.virtcurrency.forSpend' />}
          />
          <WalletLine
            amount={<CurrencyTooltip summaryRender={(summary: number) => <>{summary}</>} userId={user.baseData.id} />}
            icon={<AskingHand />}
            label={<Translate i18nKey='pryaniky.widgets.virtcurrency.forGive' />}
          />
        </>
      }

      {!isUserPageOwner &&
        <>

          {isChatEnable &&
            <EditButton variant='outlined' fullWidth
              href={'/messages/direct/' + user.baseData.alias} >
              <IconWrapper color='primary'>
                <ChatIcon />
              </IconWrapper>
              <Typography variant='subtitle1' color='primary'>
                <Translate i18nKey={'pryaniky.profile.message'} />
              </Typography>
            </EditButton>
          }

          <EditButton variant='outlined' fullWidth
            onClick={subscribe} >
            <IconWrapper color='primary'>
              <AddAlertIcon />
            </IconWrapper>
            <Typography variant='subtitle1'>
              {
                user.extData.isSubscribedByMe ?
                  <Translate i18nKey='pryaniky.profile.unsubscribe' /> :
                  <Translate i18nKey='pryaniky.profile.subscribe' />
              }
            </Typography>
          </EditButton>

          {
            enabledThanks &&
            <>
              <ThankForm
                targetUsers={user.baseData}
                user={user.baseData}
              />
            </>
          }
        </>
      }

      {
        formatedBirthday !== '' &&
        <DescriptionLine
          icon={<CakeIcon color='action' fontSize='medium' />}
          value={formatedBirthday}
        />
      }

      {
        !!phonesGroupedFields.length
        ? phonesGroupedFields.map((el: any) => {
          return el.groupValues?.map((it: any) => {
            if(!it) return null;
            return <DescriptionLine
              icon={<PhoneIcon color='action' />}
              value={<Link href={'tel:' + it.replace(/[()-\s]/g, '')}>{it}</Link>}
            />
          })
        })
        : user.baseData.phone
        && <DescriptionLine
          icon={<PhoneIcon color='action' />}
          value={<Link href={'tel:' + user.baseData.phone}>{user.baseData.phone}</Link>}
        />
      }

      {
        !!emailsGroupedFields.length
        ? emailsGroupedFields.map((el: any) => {
          return el.groupValues?.map((it: any) => {
            if(!it) return null;
            return <DescriptionLine
              icon={<MailIcon color='action' />}
              value={<Link href={'mailto:' + it}>{it}</Link>}
            />
          })
        })
        : user.baseData.eMail
        && <DescriptionLine
          icon={<MailIcon color='action' />}
          value={<Link href={'mailto:' + user.baseData.eMail}>{user.baseData.eMail}</Link>}
        />
      }

      {(isUserPageOwner || isAuthUserAdmin) &&
        <EditButton variant='outlined' fullWidth
          onClick={() => dispatch(push(`/profileEdit/${user.profileData.userId}`))} >
          <IconWrapper color='primary'>
            <SettingsIcon />
          </IconWrapper>
          <Typography variant='subtitle1'>
            <Translate i18nKey={'pryaniky.profile.edit'} />
          </Typography>
        </EditButton>
      }

    </PaperBox>

    <PaperMarginBox variant='elevation'>
      {
        (
          CustomSettings.showBusinessCardButton()
          || businesscardsModuleIsActive
        ) &&
        (
          isUserPageOwner ||
          isAuthUserAdmin
        ) &&
        <EditButton
          variant='outlined'
          fullWidth
          onClick={() => {
            BusinessCardDialog({ id: userId }).then(d => { }).catch(e => console.error(e));
          }}
        >
          <IconWrapper color='primary'>
            <BadgeIcon />
          </IconWrapper>
          <Typography variant='subtitle1'>
            <Translate i18nKey={'businesscard.button.text'} />
          </Typography>
        </EditButton>
      }
      <QRButtton variant='outlined' fullWidth
        onClick={() => userQRModal({ uid: userId, userName: user.baseData.displayName })} >
        <IconWrapper color='primary'>
          <QrCodeIcon />
        </IconWrapper>
        <Typography variant='subtitle1'>
          <Translate i18nKey={'pryaniky.profile.qrVisitCard'} />
        </Typography>
      </QRButtton>
    </PaperMarginBox>

    <WalletsMui5 uid={userId} />

  </>
};