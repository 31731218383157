import * as types from './actions.d'
import actions from './actionsType'

// to saga
export const rewardReply: types.CRewardReplyAction = (payload) => ({
    type: actions.REWARD_REPLY,
    payload
})


export const rewardAll: types.CRewardAllAction = (payload) => ({
    type: actions.REWARD_ALL,
    payload
})

export const distributeCompetition: types.CDistributeAction = (payload) => ({
    type: actions.DISTRIBUTE_COMPETITION,
    payload
})


export const cancleCompetition: types.CCancleAction = (payload) => ({
    type: actions.CANCLE_COMPETITION,
    payload
})


// to reducer
export const changeData: types.CChangeDataAction = (payload) => ({
    type: actions.CHANGE_FORM,
    payload
})

export const changeReward: types.CChangeRewardAction = (payload) => ({
    type: actions.CHANGE_REWARD_REPLY,
    payload
})

export const pathReward: types.CPathRewardAction = (payload) => ({
    type: actions.PATH_REWARD,
    payload
})

export const cancleAllReward: types.CCancleAllRewardAction = (payload) => ({
    type: actions.CANCLE_ALL_REWARD,
    payload
})