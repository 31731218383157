import React, { FC, useEffect } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import TestEditor from '../../UnitEdit/Test'
import TaskEditor from '../../UnitEdit/Task'
import PageEditor from '../../UnitEdit/Page'
import Scorm from '../../UnitEdit/Scorm'
import ISpringScorm12 from '../../UnitEdit/ISpringScorm12'
import LegacyPageEditor from '../../UnitEdit/LegacyPage'
import { Button as SB, Spinner, ErrorMsg } from 'uielements/src'
import { ButtonBox } from 'uielements/src/ButtonBox/ButtonBox'
import { confirm } from 'utils.project/utils.project'
import withConfirm from '../../HOCs/buttonWithConfirm'
import ListPlaceholder from '../../../components/ListPlaceholder'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

const Button = withConfirm(SB)

export const Presenter: FC<IPresenterProps> = ({
    className,
    unit,
    // loadUnitContent,
    loadUnit,
    unitId,
    saveUnit,
    updateUnit,
    deleteUnit,
    isSaving,
    errorCount,
    queryErrors,
    resetUnit,
    editOnlyLesson,
    unitVersion,
    changeLocation
}) => {
    useEffect(() => {
        loadUnit(unitId, unitVersion)
    }, [unitVersion, unitId])

    const onSave = () => {
        saveUnit(unitId)
    }
    const onDelete = () => deleteUnit(unit)

    const onChange = (unit: any) => updateUnit(unit)

    if (!unit) return <div className={cnClassName({}, [className])}>
        <ListPlaceholder
            className={cnClassName('Placeholder')}
            isFinished={false}
            isLoading={true}
            itemsCount={0}
            titleEmpty={''}
            textEmpty={''}
            textLoad={tT('course_edit.item.load.1')}
        />
    </div>

    const renders: any = {
        scorm: <Scorm unit={unit} onChange={onChange} />,
        wiki: <PageEditor unit={unit} onChange={onChange} />,
        test: <TestEditor unit={unit} onChange={onChange} />,
        task: <TaskEditor unit={unit} onChange={onChange} />,
        iSpring_scorm_12: <ISpringScorm12 unit={unit} onChange={onChange} />
    }

    const saveEnabled = (errorCount === 0) && unit.modifed

    const unitType: any = unit.unitType

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Toolbar')}>
            {isSaving === 'PENDING' && <Spinner />}
            <ButtonBox>
                <Button
                    main
                    title={tT('course_edit.item.save.title.1')}
                    disabled={!saveEnabled}
                    onClick={onSave}
                    className={cnClassName('Btn', { type: 'save' })}>{tT('course_edit.item.save.1')}</Button>
            </ButtonBox>
            {queryErrors && <ErrorMsg>{queryErrors}</ErrorMsg>}
            <ButtonBox>
                <Button
                    main
                    title={tT('course_edit.item.cancle.title.1')}
                    disabled={!unit.modifed}
                    confirmTitle={tT('course_edit.item.cancle.confirm.1')}
                    onConfirm={() => resetUnit(unit.id)}
                    className={cnClassName('Btn', { type: 'cancle' })}>{tT('course_edit.item.cancle.1')}</Button>
                {<Button
                    main
                    title={tT('course_edit.item.dalete.title.1')}
                    confirmTitle={tT('course_edit.item.dalete.confirm.1', { name: unit.name})}
                    confirmText={
                        editOnlyLesson ?
                            (
                                unitType === 'wiki' ?
                                    tT('course_edit.item.dalete.warn.test.1') :
                                    tT('course_edit.item.dalete.warn.wiki.1')
                            ) :
                            undefined
                    }
                    onConfirm={onDelete}
                    className={cnClassName('Btn', { type: 'delete' })}>{tT('course_edit.item.dalete.1')}</Button>}
            </ButtonBox>
        </div>
        {renders[unitType]}

    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
