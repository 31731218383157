import { styled } from 'muicomponents/src/mui/system';
import { Paper } from 'muicomponents/src/Paper';
import { List, ListItemButton } from 'muicomponents/src/ListItem/ListItem';
import { LinearProgress } from "muicomponents/src/LinearProgress";
import { Avatar } from "muicomponents/src/Avatar/Avatar";
import { Box, Typography, FormControlLabel } from 'muicomponents/src'

export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    // height: theme.spacing(21),
    overflow: 'hidden',
})) as typeof Paper


export const WidgetDialogBox = styled(Box)({
    display: 'flex',
    flexDirection: "column",
    gap: "16px",
    padding: '24px'
})
export const WidgetCurrencyBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    borderRadius: "50px",
    // background: "rgba(245, 0, 87, 0.08)",
    width: "fit-content",
    paddingRight: "12px"
})
export const WidgetWalletBox = styled(Box)({
    width: "40px",
    height: "40px",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "8px",
    borderRadius: "50px",
})
export const WidgetRewardInfoBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "5px"
})
export const WidgetRewardBox = styled(Box)({
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "8px"
})
export const WidgetItemFieldBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "12px",
    margin: "6px 0",
})


export const WidgetLinearProgress = styled(LinearProgress)({
    borderRadius: "8px",
    height: "12px",
    backgroundColor: "#4caf505c",
    marginBottom: "16px",
    ".MuiLinearProgress-bar": {
        backgroundColor: "#4CAF50",
        borderRadius: "8px",


    }
})

export const FormControlLabelStyled = styled(FormControlLabel)({
    marginLeft: 0
})