import * as React from 'react';
// import * as utils from 'utils/src/utils';


import { withBemMod } from '@bem-react/core';

import { cnBody } from '../Modal-Body.index';
import { IBodyTypeLinkToNewsProps } from './Modal-Body_type_linkToNews.index';
import './Modal-Body_type_linkToNews.scss';
import { Icon } from 'uielements/src/Icon/Icon';
import { Button } from 'uielements/src/Button/Button';


export const withBodyTypeLinkToNews = withBemMod<any>(
  cnBody(),
  { type: 'linkToNews' },
  (Presenter) => (
    (props: IBodyTypeLinkToNewsProps) => {
      const linkInput = React.createRef<HTMLInputElement>();
      const onInputClick = (e: any) => {
        const length = e.target.value.length;
        e.target.selectionStart = 0;
        e.target.selectionEnd = length;
      };
      const copyLink = () => {
        if (!linkInput.current) return;
        linkInput.current.select();
        document.execCommand('copy');
        // toast.success(this.t('notifications.link_copy_to_clipboard'));
      }
      const port = window.location.port ? ':' + window.location.port : '';
      return (
        <div className={cnBody({}, props.className && props.className.split(' '))}>
          <input ref={linkInput} onClick={onInputClick}
            className='Modal-Body_type_linkToNews-Item-Input'
            value={window.location.protocol + '//' + window.location.hostname + port + props.parent.props.url} readOnly={true} />
          <Button className='Modal-Body_type_linkToNews-Item-Button Modal-Body_type_linkToNews-Item-Button_copy' onClick={copyLink}>
            <Icon icon='copy' />
          </Button>
        </div>
      )
    }
  )
);