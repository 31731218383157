import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IInviteUserOptionalFields } from './OptionalFields/Invite-OptionalFields.index';
export interface IInviteProps extends IClassNameProps {
  tag?: 'div';
}

export const requiredFields: string[] = ['UserName'];

export interface IInviteState extends IInviteUserFields {
  showOptionalFileds: boolean;
  [s: string]: any;
}

export interface ITextFieldState {
  value: string | boolean;
  valid: boolean;
}
export interface IUserInfo {
  Comment?: string;
  UserName: string;
  ExternalUserId: string;
  FirstName?: string;
  LastName?: string;
  MiddleName?: string;
  Phone?: string;
  Position?: string;
  Division?: string;
  HasRole?: boolean;
  IsAdminFlag?: boolean;
  IsMan?: boolean;
  Password?: string;
  [s: string]: any;
}

export const inviteFieldTypes: IFieldTypes = {
  UserName: 'email',
  ExternalUserId: 'text',
  FirstName: 'text',
  LastName: 'text',
  MiddleName: 'text',
  Phone: 'phone',
  Password: 'password',
  PasswordConfirmation: 'password',
  Comment: 'textarea',
  Position: 'text',
  Division: 'text',
  HasRole: 'checkbox',
  IsAdminFlag: 'checkbox',
  IsMale: 'radio',
  IsFemale: 'radio',
};

export const fieldIcons: IFieldIcons = {
  Comment: 'edit',
  UserName: 'at-sign',
  ExternalUserId: 'user',
  FirstName: 'user',
  LastName: 'signature',
  MiddleName: 'signature',
  Phone: 'phone',
  Position: 'briefcase',
  Division: 'building',
  Password: 'key',
  PasswordConfirmation: 'key',
};

export const phoneMask: string = '+9-999-999-9999';

export interface IFieldIcons {
  [name: string]: string;
}

export interface IFieldTypes {
  [name: string]: string;
}

export interface IFieldLabels {
  [name: string]: string;
}
export interface IInviteUserFields extends IInviteUserOptionalFields {
  UserName: ITextFieldState;
}

export const cnInvite = cn('Invite');
