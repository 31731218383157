import { cn } from "@bem-react/classname";
import { IClassNameProps } from "@bem-react/core";

export type SliderLinkProps = IClassNameProps & {
    /**
     * id to get data from storage
     */
    id: string;
    
    /**
     * @todo need remove it
     * tag to rander wrapper
     */
    tag?: React.ComponentType | 'div';
};

export const cnSliderLink = cn('SliderLinkMui');