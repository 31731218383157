import { NValidate } from 'utils/src/validate_v2';
import { NPolls } from './Polls.interface';
import moment from 'moment';
import { Omit } from 'utils/src';

const startCurrentMinute = moment().seconds(0).milliseconds(0).toDate();

export const AnswerValidateRules: NValidate.ValidateRulesObject<NPolls.Create['poll']['answers'][number]> = {
    text: {
        notNull: {
            value: true
        }
    }
};

export const PollObjectValidateRules: NValidate.ValidateRulesObject<NPolls.Create['poll']> = {
    answers: {
        min: {
            value: 2
        }
    }
};

export const PollsValidateRules: NValidate.ValidateRulesObject<Omit<NPolls.Create, 'expires'> & { expires: Date }> = {
    text: {
        notNull: {
            value: true
        },
        min: {
            value: 0
        }
    },
    poll: {
        byRules: PollObjectValidateRules
    },
    expires: {
        notNull: {
            value: true
        },
        min: {
            value: startCurrentMinute
        }
    }
};