import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
    /* saga */
    LOAD_COMPANY_POST_TYPES: '',  // получить с сервера доступные в компании типы публикаций
    LOAD_ALLOW_POST_TYPES: '',  // получить с сервера список доступных пользователю типов публикаций

    /* reducer */
    ADD_COMPANY_POST_TYPES: '',  // поместить в стор доступные в компании типы публикаций
    ADD_ALLOW_POST_TYPES: '',  // поместить в стор список доступных пользователю типов публикаций
    SET_COMPANY_POST_ERROR: '',

    GET_ALLOW_GROUP_TRANSFER: '',
    SET_ALLOW_GROUP_TRANSFER: ''
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.APPSETTINGS) }), {} as typeof actions);