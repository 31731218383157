import * as React from 'react';
// import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';
import { TextareaMentions } from 'blocks/TextareaMentions/TextareaMentions';
import { withBemMod } from '@bem-react/core';
import { IBodyProps } from '../NewsEdit-Body.index';
import { cnBody } from '../NewsEdit-Body.index';
import { IBodyTypeNewsProps } from './NewsEdit-Body_type_news.index';
import './NewsEdit-Body_type_news.scss';
import { EditorTypeEdit } from '../../../NewsTypes/News/Editor/containers/edit/edit'
import * as utils from 'utils/src/utils';

export const withBodyTypeNews = withBemMod<IBodyTypeNewsProps, IBodyProps>(
  cnBody(),
  { type: 'news' },
  (Presenter) => (
    (props) => {
      const newPost: any = utils.cloneObject(props.post);
      return (
        <Presenter {...props}>
          {({ text, onChangeText }) => <EditorTypeEdit data={newPost} onChange={props.onChangePost} />
              /*<TextareaMentions
                // getClassRef={this.getTextareaMentions} 
                users={true}
                tags={true}
                onChange={onChangeText}
                // className={this.props.className} 
                icon={'edit'}
                value={text}
              placeholder={i18n.t('placeholders.textarea.news')} />*/
          }
        </Presenter>
      )
    }
  )
);