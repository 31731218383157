import { ManifestType } from '../types'
import img from './Group.svg'

export const manifest: ManifestType = {
    id: 'users',
    name: 'users',
    title: 'Пользователи',
    imgUrl: img,
}

export default manifest