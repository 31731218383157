import { IIdeaFieldProps} from '../FieldProps';
import React, {useState} from 'react';
import { Icon } from 'uielements/src/Icon/Icon';
import { DateTimeInput } from '../../../../PryanikyForms/DateTimeInput/DateTimeInput';
import { any } from 'prop-types';
import './DateTime.scss';
import i18n from 'localizations/i18n';
import { number } from 'blocks/JsonFormsRenderers/Controls/NumberInput';


export const DateTime: React.FC<IIdeaFieldProps> = ({ field, onChange = () => { }}) => {
    const [text, setText] = React.useState({ value: '', valid: true, error: i18n.t('') });

 
    // const compareDates = new Date(field.value) < new Date();
    const [value, setValue] = useState(field.value)
    const onEdit = (date: Date) => {
        field.value = date.toISOString();
        // const compareDates = new Date(field.value).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
        if (field.field.isMandatory && field.value === '') setText({ value: field.value, valid: false, error: i18n.t('выберите день, который еще не прошел') });
        else setText({ value: field.value, valid: true, error: '' });
        // setValue(field.value)
        onChange(field);
    }

    const date = field.value && field.value !== '' ? new Date(field.value) : new Date();
    React.useEffect(() => {
        onEdit(date)
    },[])
    // не восстанавливать, иначе поле пропадает после отправки
    // if(!field.value || field.value === '') return null;
    return <DateTimeInput
        invalid={!text.valid} 
        errorMessage={text.error} 
        required={field.field.isMandatory}
        value={date}
        className={'Fields-DateTimeInput'}
        onChange={onEdit}
        icon={(onClick) => <span {...{ onClick }}><Icon icon='calendar' /></span>}
        placeholder={field.field.name || field.field.description}
    />
    // <Datepicker value={date} onChange={onEdit} />;
}