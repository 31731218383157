import React, { FC, useEffect, useMemo, useState, useCallback } from 'react'
import { Button } from 'muicomponents/src/Button/Button'
import { Translate } from 'localization'
import { IRequest } from 'utils/src/requests/service';
import { getMyTasksCount } from 'utils/src/requests/requests.users';
import { checkResponseStatus } from 'utils/src';
import { Box, Typography, Divider } from 'muicomponents/src'
import CardMedia from '@mui/material/CardMedia';
import MyTasks from 'assets/svg/MyTasks.svg';
import { ContentBox, ContentBoxBaseline } from './MyTasks.styled'
import urls from 'routes/urls';
import {IMyTasksProps, cnMyTasks} from './MyTasks.index'



let request: IRequest;

export const MyTasksPreseter: FC<IMyTasksProps> = ({ context }) => {
    const [tasksCount, setTasksCount] = useState<number>(0)

    const currentUserId = context.uId || context.currentUserId

    const getTasksCount = useCallback(() => {
        // if (request) request.ac.abort();
        request = getMyTasksCount(currentUserId);
        return request.r.then((d: any) => {
            if (checkResponseStatus(d)) {
                return setTasksCount(d.data);
            } else {
                if (d.error_code === 404 || d.dom_error === 'AbortError') throw d;
            }
        });
    }, [])

    useEffect(() => {
        getTasksCount()
    }, [])

    return (
        <Box>
            <ContentBox>
                <CardMedia
                    sx={{
                        width: "48px",
                        height: "48px"
                    }}
                    component="svg"
                    className={cnMyTasks("MyTasksHeader-Avatar")}
                    image={MyTasks}
                />
                <Typography
                    variant="subtitle1"
                    className={cnMyTasks('MyTasksHeader-Title')}
                    children={<Translate i18nKey={'pryaniky.myTasks.title'} />}
                />
            </ContentBox>
            <ContentBox sx={{ justifyContent: "space-between" }}>
                <ContentBoxBaseline >
                    <Typography
                        variant="subtitle1"
                        className={cnMyTasks('MyTasksContent-CountText')}
                        children={<Translate i18nKey={'pryaniky.myTasks.countText'} />}
                    />
                    <Typography
                        variant="h4"
                        className={cnMyTasks('MyTasksContent-Count')}
                        children={tasksCount?.toString()}
                    />
                </ContentBoxBaseline>
                <Button
                    href={urls.tasks}
                    variant="contained">
                    <Translate i18nKey={'pryaniky.myTasks.moreInfo'} />
                </Button>
            </ContentBox>
        </Box>
    )
}
