import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    loadUsers,
    reset
} from '../../redux/actions/TracksManage'
import {
    ILearningTracks,
    ITrackUsers
} from '../../redux/reducers/TracksManage'
import {
    getUsersObject
} from '../../redux/sagas/TracksManage/selectors'
import {  } from 'utils/src/requests/models/api.learningTracks'

///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps<{ id: string }>

export interface IState { }

export interface IOwnProps extends IClassNameProps {

}

export interface IStateProps extends ITrackUsers {
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////


export const mapStateToProps = (state: any, props: IOwnProps) => ({
    ...getUsersObject(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadUsers,
    reset
}, dispatch);

////////////

export const cnClassName = cn('TrackUsers');