import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
// import { updateUnit } from 'redux/actions/LMSTeacherOffice'
import { routerActions } from 'connected-react-router';
///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    unit: any
    selected?: boolean;
    editOnlyLesson?: boolean;
}

export interface IStateProps {

}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IOwnProps) => ({

})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    selectUnit: routerActions.push
}, dispatch);

////////////

export const cnClassName = cn('TOCEUnitsListItem');