import { IClassNameProps } from '@bem-react/core';
import { cnNewsView } from '../NewsView.index';
import { SReplyMobileModel } from 'blocks/Replyes/Replyes.index';
import { match } from 'react-router';

export interface IActionsProps extends IClassNameProps {
  likeCount: number;
  thanksCount: number;
  liked: boolean;
  id: string;
  user: any;
  thankUsers: any[];
  thank: boolean;
  thankAct: () => void;
  thanksReply?: (r: SReplyMobileModel) => void;
}

export interface IActionsState {
  text?: string;
  liked: boolean;
  likeCount:  number;
  thanksCount: number;
}

export const cnActions = (...data: any) => {
  let name = 'Actions';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnNewsView(name, ...data)
}