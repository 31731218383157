import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { withBemMod } from '@bem-react/core';
import { cnBody } from '../Modal-Body.index';
import { IBodyTypeLikersProps } from './Modal-Body_type_likers.index';
import { List as Likers } from 'blocks/List/_type/users/List_type_users_likers';
import './Modal-Body_type_likers.scss';
const anyObj: any = {}
export const withBodyTypeLikers = withBemMod<any>(
  cnBody(),
  { type: 'likers' },
  (Presenter) =>
    class extends React.Component<IBodyTypeLikersProps, any> {
      public el: HTMLElement | null;

      public render() {
        const TagName = this.props.tag ? this.props.tag : 'div';
        return (
          <TagName ref={el => this.el = el} className={cnBody({ admin: this.props.parent.state.data.isAdmin }, [this.props.className])}>
            <Router>
              <Likers context={{
                nId: this.props.parent.state.data.nId,
                cId: this.props.parent.state.data.rId,
              }}
                {...anyObj}
              />
            </Router>
          </TagName>
        )
      }
    }
);