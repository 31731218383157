import React, { FC, useState, memo, useEffect } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IAllReportsProps,
    IAllReportsOwnProps,
    IDispatchProps,
    IAllReportsStateProps,
    IAllReportsState
} from './interfaces'
import { connect, useSelector } from 'react-redux';
import './style.scss'

import FormControl from '@material-ui/core/FormControl';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import { API } from 'utils/src/utils'
import { StickyTable } from 'uielements/src'
import SingleAutocomplete from 'uielements/src/MaterialElements/SingleAutocomplete'
import withSessionSuggester from 'blocks/HOCs/SingleAutocomplete/withSessionSuggester'
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
import Tabs, { ITabType } from 'uielements/src/TabsV2'
// import i18n from 'localizations/i18n';
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import Tooltip from 'uielements/src/MaterialElements/Tooltip'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { useLazyQuery } from 'LMSModule/utils/hooks/useLazyQuery'
import { getAppDesignColors } from 'utils/src/CommonRedux/base/selectors';
import { Translate } from 'localization';

const SessionSelect = withSessionSuggester(SingleAutocomplete)



export const Presenter: FC<IAllReportsProps> = ({
    className,
    cid
}) => {

    useEffect(() => {
    }, [cid])
    const TABS: ITabType[] = [
        { value: 'active', content: tT('statistics.reports.tabs.active.1') },
        { value: 'archive', content: tT('statistics.reports.tabs.archive.1') }
    ]
    const [reportSettings, setReportSettings] = useState<{ [key: string]: any }>({
        allcourseusersexcel: [],
        allcourseusersexcelSid: null,
        allusercoursesexcel: [],
        allusercoursesexcelSid: null,
    })
    const [sid, setSid] = useState<string | undefined>(undefined)
    const [sessionSelectError, setSessionSelectError] = useState(false)
    const [isArchived, setArchived] = useState(false)

    const appSettings = useSelector(getAppDesignColors)
    const activeColor = appSettings.primaryColor3

    const changeSetting = (property: string) => (value: any) => {
        setReportSettings({ ...reportSettings, [property]: value })
    }

    const changeSelect = (item: ISelectizeItem | null) => {
        if (!item) setSid(undefined)
        else setSid(item.id)
        setSessionSelectError(false)
    }

    const lightenSessionSelect = () => {
        setSessionSelectError(true)
    }

    const [allcourseusersexcelGet, allcourseusersexcelLoading] = useLazyQuery(() => API.lms.allcourseusersexcel(cid, sid, reportSettings.allcourseusersexcel[0]?.id))
    const [allusercoursesexcelGet, allusercoursesexcelLoading] = useLazyQuery(() => API.lms.allusercoursesexcel(cid, sid, reportSettings.allusercoursesexcel[0]?.id))
    const [commentscountexcelGet, commentscountexcelLoading] = useLazyQuery(() => API.lms.commentscountexcel(cid))
    const [studentsstatusexcelGet, studentsstatusexcelLoading] = useLazyQuery(() => API.lms.studentsstatusexcel(cid, sid))
    const [unitsattendanceexcelGet, unitsattendanceexcelLoading] = useLazyQuery(() => API.lms.unitsattendanceexcel(cid, sid))
    const [testattemptsexcelGet, testattemptsexcelLoading] = useLazyQuery(() => API.lms.testattemptsexcel(cid, sid))
    const [teststatusesexcelGet, teststatusesexcelLoading] = useLazyQuery(() => API.lms.teststatusesexcel(cid, sid))

    return <div className={cnClassName({}, [className])}>

        <div className={cnClassName('Control')}>
            <Tabs
                className={cnClassName('Tabs')}
                size={'small'}
                onChange={(tab) => { tab.value === 'active' ? setArchived(false) : setArchived(true) }}
                selected={isArchived ? TABS[1] : TABS[0]}
                tabs={TABS}
            />
            <div className={cnClassName('Sessions')}>
                <FormControl fullWidth>
                    <SessionSelect
                        archived={isArchived}
                        onSelect={changeSelect}
                        label={tT('statistics.reports.select.session.1')}
                        courseId={cid}
                        error={sessionSelectError}
                    />
                </FormControl>
                {sessionSelectError
                    &&
                    <span className={cnClassName('Error')}>
                        <Translate i18nKey='pryaniky.validate.notNull' />
                    </span>
                }
            </div>

        </div>
        <div className={cnClassName('Data')}>
            <StickyTable columnsHeaders={[
                <span>{tT('statistics.reports.report.1')}</span>,
                <span>{tT('statistics.reports.settings.1')}</span>,
                <span>{tT('statistics.reports.download.1')}</span>]} rows={[
                    {
                        cells: [
                            <span>{tT('statistics.reports.type.allcourseusersexcel.1')}</span>,
                            <div style={{ width: '400px' }}>
                                <Autocomplete
                                    fullWidth
                                    excludeMe={false}
                                    type={'groups'}
                                    label={tT('statistics.reports.select.groups.1')}
                                    value={reportSettings.allcourseusersexcel}
                                    onChange={(value: any[]) => {
                                        let group = value
                                        if (value.length > 0) {
                                            group = [value[value.length - 1]]
                                        }
                                        changeSetting('allcourseusersexcel')(group)
                                    }}
                                />

                            </div>,
                            (sid && reportSettings.allcourseusersexcel[0]) ?
                                <IconButton
                                    disabled={!(sid && reportSettings.allcourseusersexcel[0])}
                                    // disabled={excelLoading}
                                    onClick={!sid ? lightenSessionSelect : allcourseusersexcelGet}
                                    // target={'_blank'}
                                    title={tT('statistics.reports.title.download.1')}
                                // href={`${API.base.requestDomain}/ru/Data/v3/statistics/lms/allcourseusersexcel?camelCase=true&courseId=${cid}${sid ? '&filterSessionId=' + sid : ''}${reportSettings.allcourseusersexcel[0] ? '&groupId=' + reportSettings.allcourseusersexcel[0].id : ''}`}
                                >
                                    <DescriptionIcon htmlColor={sid ? activeColor : undefined} />
                                </IconButton>
                                :
                                <Tooltip title={tT('statistics.reports.title.select_group_session.1')} placement="top" arrow>
                                    <IconButton onClick={!sid ? lightenSessionSelect : allcourseusersexcelGet}>
                                        <DescriptionIcon htmlColor={sid ? activeColor : undefined} />
                                    </IconButton>
                                </Tooltip>
                        ]
                    },
                    {
                        cells: [
                            <span>{tT('statistics.reports.type.allusercoursesexcel.1')}</span>,
                            <div style={{ width: '400px' }}>
                                <Autocomplete
                                    fullWidth
                                    additionalRequestProps={{ module: 'lms', courseId: cid }}
                                    excludeMe={false}
                                    type={'users'}
                                    label={tT('statistics.reports.select.users.1')}
                                    value={reportSettings.allusercoursesexcel}
                                    onChange={(value: any[]) => {
                                        let user = value
                                        if (value.length > 0) {
                                            user = [value[value.length - 1]]
                                        }
                                        changeSetting('allusercoursesexcel')(user)
                                    }}
                                />
                            </div>,
                            <IconButton
                                // target={'_blank'}
                                disabled={allusercoursesexcelLoading}
                                onClick={!sid ? lightenSessionSelect : allusercoursesexcelGet}
                                title={tT('statistics.reports.title.download.1')}
                            // href={`${API.base.requestDomain}/ru/Data/v3/statistics/lms/allusercoursesexcel?camelCase=true&courseId=${cid}${sid ? '&filterSessionId=' + sid : ''}${reportSettings.allusercoursesexcel[0] ? '&userId =' + reportSettings.allusercoursesexcel[0].id : ''}`}
                            >
                                <DescriptionIcon htmlColor={sid ? activeColor : undefined} />
                            </IconButton>
                        ]
                    },
                    {
                        cells: [
                            <span>{tT('statistics.reports.type.commentscountexcel.1')}</span>,
                            <span></span>,
                            <IconButton
                                // target={'_blank'}
                                disabled={commentscountexcelLoading}
                                onClick={!sid ? lightenSessionSelect : commentscountexcelGet}
                                title={tT('statistics.reports.title.download.1')}
                            // href={`${API.base.requestDomain}/ru/Data/v3/statistics/lms/commentscountexcel?camelCase=true&courseId=${cid}`}
                            >
                                <DescriptionIcon htmlColor={sid ? activeColor : undefined} />
                            </IconButton>
                        ]
                    },
                    {
                        cells: [
                            <span>{tT('statistics.reports.type.studentsstatusexcel.1')}</span>,
                            <span></span>,
                            <IconButton
                                // target={'_blank'}
                                disabled={studentsstatusexcelLoading}
                                onClick={!sid ? lightenSessionSelect : studentsstatusexcelGet}
                                title={tT('statistics.reports.title.download.1')}
                            // href={`${API.base.requestDomain}/ru/Data/v3/statistics/lms/studentsstatusexcel?camelCase=true&courseId=${cid}${sid ? '&sessionId=' + sid : ''}`}
                            >
                                <DescriptionIcon htmlColor={sid ? activeColor : undefined} />
                            </IconButton>
                        ]
                    },
                    {
                        cells: [
                            <span>{tT('statistics.reports.type.unitsattendanceexcel.1')}</span>,
                            <span></span>,
                            <IconButton
                                title={tT('statistics.reports.title.download.1')}
                                disabled={unitsattendanceexcelLoading}
                                onClick={!sid ? lightenSessionSelect : unitsattendanceexcelGet}
                            // href={`https://${API.base.requestDomain}/ru/Data/v3/statistics/lms/unitsattendanceexcel?camelCase=true&courseId=${cid}${sid ? '&sessionId=' + sid : ''}`}
                            >
                                <DescriptionIcon htmlColor={sid ? activeColor : undefined} />
                            </IconButton>,
                        ]
                    },
                    {
                        cells: [
                            <span>{tT('statistics.reports.type.testattemptsexcel.1')}</span>,
                            <span></span>,
                            <IconButton
                                // target={'_blank'}
                                title={tT('statistics.reports.title.download.1')}
                                disabled={testattemptsexcelLoading}
                                onClick={!sid ? lightenSessionSelect : testattemptsexcelGet}
                            // href={`${API.base.requestDomain}/ru/Data/v3/statistics/lms/testattemptsexcel?camelCase=true&courseId=${cid}${sid ? '&sessionId=' + sid : ''}`}
                            >
                                <DescriptionIcon htmlColor={sid ? activeColor : undefined} />
                            </IconButton>
                        ]
                    },
                    {
                        cells: [
                            <span>{tT('statistics.reports.type.teststatusesexcel.1')}</span>,
                            <span></span>,
                            <IconButton
                                // target={'_blank'}
                                title={tT('statistics.reports.title.download.1')}
                                disabled={teststatusesexcelLoading}
                                onClick={!sid ? lightenSessionSelect : teststatusesexcelGet}
                            // href={`${API.base.requestDomain}/ru/Data/v3/statistics/lms/teststatusesexcel?camelCase=true&courseId=${cid}${sid ? '&sessionId=' + sid : ''}`}
                            >
                                <DescriptionIcon htmlColor={sid ? activeColor : undefined} />
                            </IconButton>
                        ]
                    },
                ]} />
        </div>
    </div>
}

export default connect<IAllReportsStateProps, IDispatchProps, IAllReportsOwnProps, IAllReportsState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
