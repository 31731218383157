import React, { FC, memo, useCallback, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { questionTypes } from './constants'
import { connect } from 'react-redux';
import './style.scss'

import FormControlO from '@material-ui/core/FormControl';
import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';
import { arrayMove, arrayMoveMutate } from 'utils/src/utils'
import AnswerInput from '../AnswerInput'
import { controlStyle, peperStyle, controlLabelStyle } from './style'
import { questionValidator, fieldWithError } from '../unitValidation'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import QuestionEditor from '../QuestionEditor'
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

const Alert: FC<AlertProps> = (props) => <MuiAlert elevation={3} variant="standard" {...props} />;

const FormControlLabelHelped = styled(fieldWithHelp(FormControlLabel))(controlLabelStyle)
const FormControl = styled(FormControlO)(controlStyle)


const SortableItem = SortableElement(AnswerInput);

const Sortable = SortableContainer((props: any) => <div {...props} />);

export const Presenter: FC<IPresenterProps> = ({
    className,
    question,
    createAnswer,
    updateQuestion,
    deleteQuestion,
    changeQuestionType,
    setQuestionError,
    number,
    uid,
    withAnswerError,
    errors,
    deleteAnswer: deleteAnswerAction,
    answersErrors,
    maxFileSize
}) => {
    const updateQ = useCallback((payload: any) => updateQuestion({ id: question.id, ...payload }, uid), [question])
    const changeText = (property: string) => (e: any) => {
        setQuestionError(questionValidator(property, e.target.value))
        updateQ({ [property]: e.target.value })
    }
    const changeObject = useCallback((property: string) => (val: any) => {
        updateQ({ [property]: val })
    }, [question])
    const changeType = (event: React.ChangeEvent<{ value: unknown }>) => {
        changeQuestionType(event.target.value as number, question.id, uid)
    }
    const addAnswer = () => createAnswer(question.id, uid)

    const changeCheck = (property: string) => () => {
        updateQ({ [property]: !question[property] })
    }
    const deleteAnswer = (aid: string) => {
        deleteAnswerAction(uid, question.id, aid)
    }
    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        updateQ({ answers: arrayMove(question.answers, oldIndex, newIndex) });
    };

    let withErrors = withAnswerError
    withErrors = Object.values(errors).filter((v: any) => (typeof v !== 'undefined')).length !== 0 ? true : withErrors
    withErrors = answersErrors.length !== 0 ? true : withErrors

    return <QuestionEditor
        deleteQuestion={deleteQuestion}
        number={number}
        qid={question.id}
        uid={uid}
        questionTypes={questionTypes}
    >
        {question.qtype === 2 &&
            <div>
                <Typography>{tT('question_edit.qtype.2.label.1')}</Typography>
                <div>
                    <FormControlLabelHelped
                        control={
                            <Checkbox
                                checked={question.isNeedModeration}
                                onChange={changeCheck('isNeedModeration')}
                                name="needModerationQuestions"
                                color="primary"
                            />
                        }
                        label={tT('question_edit.isNeedModeration.1')}
                        help={tT('question_edit.isNeedModeration.help.1')}
                    />
                </div>
                <div>
                    <FormControlLabelHelped
                        control={
                            <Checkbox
                                checked={question.allowAttach}
                                onChange={changeCheck('allowAttach')}
                                name="allowAttach"
                                color="primary"
                            />
                        }
                        label={tT('question_edit.allowAttach.1')}
                        help={tT('question_edit.allowAttach.help.1')}
                    />
                </div>
            </div>
        }

        {(question.qtype === 1 || question.qtype === 0) &&
            <>
                <Sortable onSortEnd={onSortEnd} useDragHandle>
                    {question.answers.map((aid: string, i: number) =>
                        <SortableItem
                            uid={uid}
                            index={i}
                            key={`answer-${aid}`}
                            radio={question.qtype === 0}
                            qid={question.id}
                            aid={aid}
                            deleteAnswer={deleteAnswer} />)}
                </Sortable>

                {withAnswerError && <Alert severity="error">{tT('question_edit.answer_error.1')}</Alert>}

                <FormControl fullWidth>
                    <Button color={'primary'} onClick={addAnswer} size={'small'}>{tT('question_edit.add.1')}</Button>
                </FormControl>
            </>
        }
    </QuestionEditor>

}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
