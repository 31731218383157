import { connect } from 'react-redux';
import * as React from 'react';
// import * as utils from 'src/utils/utils';
import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";
// import i18n from '../../localizations/i18n';

import { mapStateToProps, ISrtestStateProps, cnSrtest, ISrtestProps, ISrtestState } from './Srtest.index';
import './Srtest.scss';
import { Button } from 'uielements/src/Button/Button';
import { signalRUrl } from "services/SignalR/sr";


export class SrtestPresenter extends React.Component<ISrtestProps, ISrtestState> {
  // public utils: any = utils;
  public el: Element | Text | null;

  constructor(props: ISrtestProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {};
  }

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', children, className = '' } = this.props;
    return (
      <TagName ref={el => this.el = el} className={cnSrtest({}, [customClassNames, className])}>
        <Button onClick={this.sendNews} children={'sendNews'} />
        {children}
      </TagName>
    )
  }

  private sendNews = () => {
    fetch(signalRUrl + '/Orleans/SignalR/SendNews', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "userIds": ["6b48f589-3e3e-4721-972e-0f85d9dc0a2d"],
        "id": "e79748f7-8cd4-463c-849e-e3e0e050fa35",
        "text": "test debug etc...",
        "isShowOnTop": true,
        "isSubscribed": false,
        "liked": false,
        "isNeedApprove": false,
        "likeText": "",
        "likeCount": 0,
        "linkPreviewImgUrl": null,
        "date": "2019-07-16T13:24:05.853Z",
        "expires": null,
        "thanksCount": 0,
        "newstype": 21,
        "attachments": null,
        "user": {
          "id": "61929c23-2690-4cda-a320-5a5844a5349d",
          "displayName": "Александр Семин",
          "eMail": "as@pryaniky.ru",
          "imgId": "2002b03f-be0f-4084-a016-ad3ab2001870",
          "sex": "m",
          "imgUrl": "http://testrating34.pryaniky.com/Img/UserProfiles/Preview/96m96/61929c23-2690-4cda-a320-5a5844a5349d.png?v=2002b03f-be0f-4084-a016-ad3ab2001870",
          "position": "Разработчик frontend",
          "division": "Департамент разработки замечательных вещей",
          "phone": null
        },
        "poll": null,
        "uevent": null,
        "thanks": null,
        "creativeTask": null,
        "idea": {},
        "allowReply": true,
        "commentsCount": 0,
        "systemNewsActionHtml": "",
        "header": " [\\[test idea\\](/news/e79748f7-8cd4-463c-849e-e3e0e050fa35)](/news/e79748f7-8cd4-463c-849e-e3e0e050fa35) ",
        // "header": " [test idea\\](/news/e79748f7-8cd4-463c-849e-e3e0e050fa35)",
        "tags": null,
        "comments": null,
        "group": null,
        "actions": [
          "edit",
          "disableComments",
          "reportSpam",
          "delete",
          "moveToGroup",
          "addToAnnouncement",
          "subscribe",
          "disapprove",
          "translate",
          "hide"
        ]
      })
    })
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const Srtest = connect<ISrtestStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps({})
)(SrtestPresenter)