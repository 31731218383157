import React, { FC } from 'react'
import {
    LMSTO,
    mapDispatchToProps,
    mapStateToProps,
    cnClassName
} from './interfaces'
import { connect } from 'react-redux';
import { Button, Avatar } from 'uielements/src';
import { Progress } from '../../../components/Progress/Progress';
import './style.scss'
import { formatDateSTD } from 'utils/src/utils.dates'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

type IPresenterProps = LMSTO.StudentsList.SessionList.IPresenterProps
type IStateProps = LMSTO.StudentsList.SessionList.IStateProps
type IDispatchProps = LMSTO.StudentsList.SessionList.IDispatchProps
type IOwnProps = LMSTO.StudentsList.SessionList.IOwnProps
type IState = LMSTO.StudentsList.SessionList.IState

const getStatus = (log: any) => {
    if (log.isAbsolutelyCompleted) return 'isAbsolutely'
    if (log.isCompleted) return 'isCompleted'
    if (log.isMandatoryPartComplete) return 'isMandatory'
    return 'isProcess'
}

const getStatusText = (status: string) => {
    switch (status) {
        case 'isAbsolutely':
            return tT('student_list.item.status.isAbsolutely.1');
        case 'isCompleted':
            return tT('student_list.item.status.isCompleted.1');
        case 'isMandatory':
            return tT('student_list.item.status.isMandatory.1');
        default:
            return tT('student_list.item.status.isProgress.1');
    }
}

export const Presenter: FC<IPresenterProps> = ({ className, logs, cid, userId, unitCount }) => {
    return <div className={cnClassName({}, [className])}>
        <table className={cnClassName('SessionsTable')}>
            <thead className={cnClassName('SessionsHead')}>
                <tr className={cnClassName('SessionHeadRow')}>
                    <th className={cnClassName('SessionName')}>{tT('student_list.item.SessionName.1')}</th>

                    <th className={cnClassName('SessionStartDate')}>{tT('student_list.item.SessionStartDate.1')}</th>

                    <th className={cnClassName('SessionEndDate')}>{tT('student_list.item.SessionEndDate.1')}</th>

                    <th className={cnClassName('SessionStatus')}>{tT('student_list.item.SessionStatus.1')}</th>
                </tr>
            </thead>
            <tbody>
                {(logs || []).map((log: any) => <>
                    <tr key={'f-' + log.id} className={cnClassName('SessionSpacer')}>
                        <td colSpan={4} className={cnClassName('SessionSpacer')}></td>
                    </tr>
                    <tr key={'s-' + log.id} className={cnClassName('SessionItem')}>
                        <td className={cnClassName('SessionName')}>
                            <Button
                                theme={'unstyled'}
                                type={'rlink'}
                                className={cnClassName('Title')}
                                href={`/TeacherOffice/Course/${cid}?tab=student&sid=${log.sessionId}&uid=${userId}`}>{log.sessionName}</Button>
                        </td>

                        <td className={cnClassName('SessionStartDate')}>{formatDateSTD(log.startDate, true)}</td>

                        <td className={cnClassName('SessionEndDate')}>{log.endDate ? formatDateSTD(log.endDate, true) : '-'}</td>

                        <td className={cnClassName('SessionStatus', { status: getStatus(log) })}>{getStatusText(getStatus(log))}</td>
                    </tr>
                    <tr key={'p' + log.id} className={cnClassName('SessionProgress')}>
                        <td colSpan={4} className={cnClassName('SessionProgressCol')}>
                            <Progress className={cnClassName('Progress')} currentStep={log.completeUnitCount/*Math.round(log.completePercentage / (100 / unitCount))*/} totalSteps={log.totalUnitsCount} />
                        </td>
                    </tr>
                </>)}
            </tbody>
        </table>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
