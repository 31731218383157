import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { changeUnitPosition} from '../../../redux/actions/LMSTeacherOffice'
///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    units: any[]
    active?: string[] | string | null;
    cid: string;
    editOnlyLesson?: boolean;
}

export interface IStateProps {
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IOwnProps) => ({
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    changeUnitPosition
}, dispatch);

////////////

export const cnClassName = cn('TOCEUnitsList');