import React from 'react';
import { connect } from 'react-redux';
import { ICustomNodePositionsProps, cnCustomNodePositions, mapCustomNodePositionsStateToProps, mapCustomNodePositionsDispatchToProps } from './CostomNodePosition.index'
import { GUID_EMPTY } from 'utils/src/constants.prn'
import './CostomNodePosition.scss'
import { UserMention } from 'uielements/src/UserMention/UserMention'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip'
import { Tooltip, Button, Avatar, Icon } from 'uielements/src';
import { NastoychiiBelyiTooltip as MuiTooltip } from 'uielements/src/MaterialElements/Tooltip';
import { Bable } from 'blocks/Bable/Bable'
import { cn } from '@bem-react/classname';
import i18n from 'localizations/i18n';
import * as utils from 'utils.project/utils.project'
import { Attachments as AttachmentsView } from 'uielements/src/Attachments/Attachments';
import VacancyOpenNoUser from 'assets/svg/VacancyOpenNoUser.svg'
import FullTimeWorker from 'assets/svg/FullTimeWorker.svg'
import Temporary from 'assets/svg/Temporary.svg'
import HalfTimeWorker from 'assets/svg/HalfTimeWorker.svg'
import Intern from 'assets/svg/Intern.svg'
import Medicine from 'assets/svg/Medicine.svg'
import Baby from 'assets/svg/Baby.svg'
import { Translate } from 'localizations/Translate';
import { makeStyles } from '@material-ui/core/styles';
import buttonAdd from 'assets/svg/buttonAdd.svg'
import { Button as MuiButton } from 'muicomponents/src'
import { useOrgContext } from '../../context'

export const cnPositionTooltip = cn('PositionTooltip');
export const cnPositionTooltipUser = cn('PositionTooltipUser');
export const cnMenuTooltip = cn('MenuTooltip');



const useStyles = makeStyles((theme) => ({
    noMaxWidth: {
        maxWidth: 'none',
    },
}));

interface IPositionTooltipProps {
    position: any;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

const noopArray: any[] = [];

export const PositionTooltip: React.FC<IPositionTooltipProps> = ({ onMouseLeave, onMouseEnter, position }) => {
    const stopPropagation = (e: any) => {
        e.stopPropagation();
    }

    const { zoom } = useOrgContext()
    // 
    const copyTextFunction = (text: string) => {
        /* Get the text field */
        let copyText: HTMLInputElement | null = null;
        if (text === "Phone") {
            copyText = document.getElementById("Phone") as HTMLInputElement;
        }
        if (text === "Email") copyText = document.getElementById("Email") as HTMLInputElement;


        /* Select the text field */
        copyText?.select();
        copyText?.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        copyText && navigator.clipboard.writeText(copyText.value);
    }
    console.log('zoom', zoom, 1 + (1 - zoom))
    const noName = "открытая вакансия"
    return (
        <>
            {position.employee === null ? <div /*style={{ zoom: 1+(1 - zoom) }}*/ className={cnPositionTooltip('')}>
                <Avatar className={cnPositionTooltip('Img')} name={position.type.name !== "" ? i18n.t(`pryaniky.orgchart.positionType.${position.type.name}`) : noName} imgId={GUID_EMPTY} imgUrl={""} size={40} />
                <div className={cnPositionTooltip('Data')}>
                    <div className={cnPositionTooltip('Name')}>{i18n.t('pryaniky.orgchart.positions.user.vacancy.file')}</div>
                    {(position.vacancy && position.vacancy.description !== "") && <div className={cnPositionTooltip('Name-Vacancy')}>
                        {position.vacancy && position.vacancy.description}
                    </div>}
                    {(position.type.description && position.type.description !== "") && <div className={cnPositionTooltip('Description')}>

                        <img className={cnCustomNodePositions((position.type.name && position.type.name === '') ? 'Vacancy-TypeBadge-NoTypeIMG' : 'Vacancy-TypeBadge-TypeIMG')}
                            src={
                                position.type.name === "fullTimeEmployee" ? FullTimeWorker :
                                    position.type.name === "halfTimeWorker" ? HalfTimeWorker :
                                        position.type.name === "temporaryWorker" ? Temporary :
                                            position.type.name === "trainee" ? Intern :
                                                position.type.name === "decree" ? Baby :
                                                    position.type.name === "sickLeave" ? Medicine : undefined
                            } />
                        <span><Translate i18nKey={`pryaniky.orgchart.positionType.${position.type.description}`} /></span>
                    </div>}
                    {position.isVacancyOpen && <div className={cnPositionTooltip('VacancyOpen')}>
                        {position.vacancy?.attachments && <AttachmentsView className={cnPositionTooltip('Attachments', ['horizontalPadding'])}
                            files={position.vacancy?.attachments ? position.vacancy.attachments : noopArray}
                            onDelete={() => { }}
                            state='view' />}
                    </div>}
                </div>
            </div> :
                <div /*style={{ zoom: 1+(1 - zoom) }}*/ className={cnPositionTooltipUser('Popup')}>
                    <MuiButton
                        sx={{
                            '&:hover': {
                                background: "inherit!important"
                            }
                        }}
                        variant='text'
                        href={'/user/' + position.employee.id}
                        onClick={stopPropagation}
                        target="_blank"
                        className={cnPositionTooltipUser('Avatar')}>
                        <Avatar imgUrl={position.employee.userPhotoUrl || position.employee.imgUrl} imgId={position.employee.imgId} name={position.employee.displayName} size={130} shape="square" />
                    </MuiButton>
                    <div className={cnPositionTooltipUser('Info')}>
                        <MuiButton sx={{
                            textTransform: "initial",
                            textDecoration: "none",
                            padding: "0",
                            marginBottom: "8px",
                            '&:hover': {
                                background: "inherit!important"
                            }
                        }}
                            variant='text'
                            href={'/user/' + position.employee.id}
                            onClick={stopPropagation}
                            target="_blank"
                            className={cnPositionTooltipUser('Name')}>
                            {position.employee.displayName}
                        </MuiButton>
                        {/* {(position.network && position.length > 1) && <p className={cnPositionTooltipUser('Network')}>
                            <Icon icon="chart-network" className={cnPositionTooltipUser('NetworkIcon')} />
                            {position.network}
                        </p>} */}

                        {(position.type.description && position.type.description !== "") && <div className={cnPositionTooltipUser('Description')}>
                            <img className={cnCustomNodePositions(position.type.name === '' ? 'Vacancy-TypeBadge-NoTypeIMG' : 'Vacancy-TypeBadge-TypeIMG')}
                                src={
                                    position.type.name === "fullTimeEmployee" ? FullTimeWorker :
                                        position.type.name === "halfTimeWorker" ? HalfTimeWorker :
                                            position.type.name === "temporaryWorker" ? Temporary :
                                                position.type.name === "trainee" ? Intern :
                                                    position.type.name === "decree" ? Baby :
                                                        position.type.name === "sickLeave" ? Medicine : undefined
                                } />
                            <span><Translate i18nKey={`pryaniky.orgchart.positionType.${position.type.description}`} /></span>

                        </div>}
                        {position.employee.position && <p className={cnPositionTooltipUser('Position')}>{position.employee.position}</p>}
                        {position.employee.division && <p className={cnPositionTooltipUser('Division')}>{position.employee.division}</p>}
                        {(position.employee.birthday !== "0001-01-01T00:00:00.000Z") && <p className={cnPositionTooltipUser('Contact')}>
                            <span className={cnPositionTooltipUser('ContactName')}>{i18n.t('pryaniky.profile.birthday')}:</span>
                            {new Date(position.employee.birthday).toLocaleString(i18n.language, { month: 'long', day: '2-digit' })}
                        </p>}
                        <p className={cnPositionTooltipUser('Contact', { eMail: true })}>
                            <span className={cnPositionTooltipUser('ContactName')}>{i18n.t('pryaniky.profile.email')}:</span>
                            {/* <a href={'mailto:' + position.employee.eMail}>{position.employee.eMail}</a> */}
                            <input className={cnPositionTooltipUser('ContactName-HideInput')} type="text" value={position.employee.eMail} id="Email"></input>
                            <Button theme="unstyled" type="link" href={'mailto:' + position.employee.eMail}>
                                {position.employee.eMail}
                            </Button>
                            <Button className={cnPositionTooltipUser('CopyBTN')} theme="unstyled" onClick={() => copyTextFunction("Email")} >
                                <Icon icon="copy" />
                            </Button>

                        </p>
                        <p className={cnPositionTooltipUser('Contact')}>
                            <span className={cnPositionTooltipUser('ContactName')}>{i18n.t('pryaniky.profile.phone')}:</span>
                            <input className={cnPositionTooltipUser('ContactName-HideInput')} type="text" value={position.employee.phone} id="Phone"></input>
                            <Button
                                theme="unstyled"
                                type="link"
                                href={'tel:' + position.employee.phone}
                                children={position.employee.phone}
                            />
                            <Button className={cnPositionTooltipUser('CopyBTN')} theme="unstyled" onClick={() => copyTextFunction("Phone")} >
                                <Icon icon="copy" />
                            </Button>
                        </p>
                        {position.isVacancyOpen && <div className={cnPositionTooltip('VacancyOpen')}>
                            {i18n.t('pryaniky.orgchart.positions.user.vacancy.file')}
                            {position.vacancy?.attachments && <AttachmentsView className={cnPositionTooltip('Attachments', ['horizontalPadding'])}
                                files={position.vacancy?.attachments ? position.vacancy.attachments : noopArray}
                                onDelete={() => { }}
                                state='view' />}
                        </div>}
                    </div>
                </div>
            }
        </>
    )
}

const CustomNodePositionsPresenter: React.FC<ICustomNodePositionsProps> = ({
    data,
    dataEdit,
    unitId,
    removePosition,
    editPositions,
    uIsAdmin,
    onClickEdit,
    deletePositionFromUnit,
    changePositionDataOrgchart,
    getLast,
    howManyLeft,
    showMore,
    filterShowOnlyOpenVacancy
}) => {
    const tooltipClasses = useStyles();


    const [showPopup, setShowPopup] = React.useState(false);

    const popup = React.useRef<HTMLDivElement>(null);
    const actions = ["delete", "edit"]
    const togglePopup = () => {
        setShowPopup(!showPopup)
    }

    const onActionClick = (action: string) => {
        if (action === "delete") {
            utils.confirm({
                text: data && data.employee !== null ? i18n.t('pryaniky.orgchart.positions.user.delete') : i18n.t('pryaniky.orgchart.positions.user.position.delete'),
                title: data && data.employee !== null ? i18n.t('pryaniky.orgchart.positions.user.delete.title') : i18n.t('pryaniky.orgchart.positions.delete.title'),
                onConfirm: () => {
                    data && deletePositionFromUnit({ position: { ...data, employee: null }, id: data && data.id })
                }
            })
        }
        if (action === "edit") {
            onClickEdit && onClickEdit(data && data.id)
        }
    }
    // React.useEffect(() => {


    // }, [unitId])

    if (!data && !dataEdit) return null

    return (
        <div className={cnCustomNodePositions('')}>


            {!editPositions && <div className={cnCustomNodePositions('Vacancy-preBox')} >

                <div className={cnCustomNodePositions('Vacancy-ImgBox')}>
                    <MuiTooltip
                        className={cnCustomNodePositions('Tooltip')}
                        title={
                            <PositionTooltip
                                onMouseEnter={() => { }}
                                onMouseLeave={() => { }}
                                position={data} />
                        }
                        disableFocusListener

                        // leaveDelay={300}
                        interactive
                        PopperProps={{
                            disablePortal: false,
                            popperOptions: {
                                positionFixed: true,
                                modifiers: {
                                    preventOverflow: {
                                        enabled: true,
                                        boundariesElement: "window" // where "window" is the boundary
                                    }
                                }
                            }
                        }}
                        classes={{
                            tooltip: tooltipClasses.noMaxWidth
                        }}
                    >
                        <div>
                            <Button
                                theme={'unstyled'}
                                type={'rlink'}
                                href={data.employee ? '/user/' + data.employee.id : undefined}
                                className={'BadgeItem-ImgLink'}
                                onMouseEnter={() => setShowPopup(true)}
                                onMouseLeave={() => setShowPopup(false)}

                            >
                                {!filterShowOnlyOpenVacancy && data.employee && <Avatar ref={popup} className={cnCustomNodePositions(data.isVacancyOpen ? 'VacancyOpen' : 'Vacancy')} imgUrl={data.employee.userPhotoUrl || data.employee.imgUrl} imgId={data.employee.imgId} name={data.employee.displayName} size={36} />
                                }
                                {data.employee === null && data.isVacancyOpen &&
                                    <Avatar ref={popup} className={cnCustomNodePositions(data.isVacancyOpen ? 'VacancyOpen' : 'Vacancy')} imgUrl={VacancyOpenNoUser} name={i18n.t(`pryaniky.orgchart.positionType.${data.type.name}`)} size={36} />
                                    //  imgId={GUID_EMPTY}
                                }
                                {uIsAdmin && data.employee === null && !data.isVacancyOpen &&
                                    <Avatar ref={popup} className={cnCustomNodePositions(data.isVacancyOpen ? 'VacancyOpen' : 'Vacancy')} imgUrl={VacancyOpenNoUser} name={i18n.t(`pryaniky.orgchart.positionType.${data.type.name}`)} size={36} />
                                }


                            </Button>
                        </div>
                    </MuiTooltip>
                    {/* <HelpTooltip
                    // showOnHowerHelp
                    // anchor={popup}
                    help={<PositionTooltip
                        onMouseEnter={() => setShowPopup(true)}
                        onMouseLeave={() => setShowPopup(false)}
                        position={data} />}
                >
                    <Button
                        theme={'unstyled'}
                        type={'rlink'}
                        href={data.employee ? '/user/' + data.employee.id : undefined}
                        className={'BadgeItem-ImgLink'}
                        onMouseEnter={() => setShowPopup(true)}
                        onMouseLeave={() => setShowPopup(false)}

                    >
                        {!filterShowOnlyOpenVacancy && data.employee && <Avatar ref={popup} className={cnCustomNodePositions(data.isVacancyOpen ? 'VacancyOpen' : 'Vacancy')} imgUrl={data.employee.userPhotoUrl || data.employee.imgUrl} imgId={data.employee.imgId} name={data.employee.displayName} size={36} />}
                        {data.employee === null && data.isVacancyOpen &&
                            <Avatar ref={popup} className={cnCustomNodePositions(data.isVacancyOpen ? 'VacancyOpen' : 'Vacancy')} imgUrl={VacancyOpenNoUser} name={i18n.t(`pryaniky.orgchart.positionType.${data.type.name}`)} size={36} />
                            //  imgId={GUID_EMPTY}
                        }
                        {uIsAdmin && data.employee === null && !data.isVacancyOpen &&
                            <Avatar ref={popup} className={cnCustomNodePositions(data.isVacancyOpen ? 'VacancyOpen' : 'Vacancy')} imgUrl={VacancyOpenNoUser} name={i18n.t(`pryaniky.orgchart.positionType.${data.type.name}`)} size={36} />
                        }


                    </Button>

                </HelpTooltip> */}
                    {(uIsAdmin || data.employee !== null || data.isVacancyOpen) && <Bable className={cnCustomNodePositions(filterShowOnlyOpenVacancy && !data.isVacancyOpen ? 'Vacancy-TypeBadge-NoTypeIMG' : 'Vacancy-TypeBadge')}>
                        {/* !onlyVacancyOpen ? 'Vacancy-TypeBadge-onlyVacancy' : */}
                        <img className={cnCustomNodePositions(data.type.name === '' ? 'Vacancy-TypeBadge-NoTypeIMG' : 'Vacancy-TypeBadge-TypeIMG')}
                            src={
                                data.type.name === "fullTimeEmployee" ? FullTimeWorker :
                                    data.type.name === "halfTimeWorker" ? HalfTimeWorker :
                                        data.type.name === "temporaryWorker" ? Temporary :
                                            data.type.name === "trainee" ? Intern :
                                                data.type.name === "decree" ? Baby :
                                                    data.type.name === "sickLeave" ? Medicine : undefined
                            } />
                    </Bable>}
                </div>
                {getLast && howManyLeft !== 0 && <Button theme="unstyled" className={cnCustomNodePositions('VacancyButton')} onClick={() => showMore && showMore(0)}>
                    +{howManyLeft}
                </Button>}

            </div>}


            {(editPositions && dataEdit) && <div className={cnCustomNodePositions('Vacancy-ImgBoxEdit')}>


                <MuiTooltip
                    // showOnHowerHelp
                    // anchor={popup}
                    disableFocusListener
                    // leaveDelay={250}
                    interactive
                    PopperProps={{
                        disablePortal: true
                    }}
                    classes={{
                        tooltip: tooltipClasses.noMaxWidth
                    }}

                    title={<div>
                        <ul className={cnCustomNodePositions('ActionsMenu')}>
                            {actions.map((action) => (
                                <li className={cnCustomNodePositions('ActionsMenu-Item')} key={action} >
                                    <Button onClick={() => onActionClick(action)} className={cnCustomNodePositions('ActionsMenu-Item-ActionBTN')} theme="unstyled">
                                        {dataEdit.employee === null && action === "delete" ? <Translate i18nKey={`pryaniky.orgchart.service.actions.position.${action}`} /> : <Translate i18nKey={`pryaniky.orgchart.service.actions.${action}`} />}
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    </div>}
                >
                    <div
                        className={'BadgeItem-ImgLink'}
                        onMouseEnter={() => setShowPopup(true)}
                        onMouseLeave={() => setShowPopup(false)}
                    >
                        {dataEdit.employee && <Avatar ref={popup} className={cnCustomNodePositions(dataEdit.isVacancyOpen ? 'VacancyOpen' : 'Vacancy')} imgUrl={dataEdit.employee.userPhotoUrl || dataEdit.employee.imgUrl} imgId={dataEdit.employee.imgId} name={dataEdit.employee.displayName} size={42} />}
                        {dataEdit.employee === null && dataEdit.isVacancyOpen &&
                            <Avatar
                                ref={popup}
                                className={cnCustomNodePositions(dataEdit.isVacancyOpen ? 'VacancyOpen' : 'Vacancy')}
                                imgUrl={VacancyOpenNoUser}
                                name={i18n.t(`pryaniky.orgchart.positionType.${dataEdit.type.name}`)}
                                size={42} />}
                        {uIsAdmin && dataEdit.employee === null && !dataEdit.isVacancyOpen &&
                            <Avatar
                                ref={popup}
                                className={cnCustomNodePositions(dataEdit.isVacancyOpen ? 'VacancyOpen' : 'Vacancy')}
                                imgUrl={VacancyOpenNoUser}
                                name={i18n.t(`pryaniky.orgchart.positionType.${dataEdit.type.name}`)}
                                size={42} />}
                    </div>
                </MuiTooltip>

                {(dataEdit.employee === null || dataEdit.isVacancyOpen) && <Bable className={cnCustomNodePositions('Vacancy-TypeBadge')}>
                    <img className={cnCustomNodePositions(dataEdit.type.name === '' ? 'Vacancy-TypeBadge-NoTypeIMG' : 'Vacancy-TypeBadge-TypeIMG')}
                        src={
                            dataEdit.type.name === "fullTimeEmployee" ? FullTimeWorker :
                                dataEdit.type.name === "halfTimeWorker" ? HalfTimeWorker :
                                    dataEdit.type.name === "temporaryWorker" ? Temporary :
                                        dataEdit.type.name === "trainee" ? Intern :
                                            dataEdit.type.name === "decree" ? Baby :
                                                dataEdit.type.name === "sickLeave" ? Medicine : undefined
                        } />
                </Bable>}
            </div>}



        </div>


    )
}



export const CostomNodePosition = connect(
    mapCustomNodePositionsStateToProps,
    mapCustomNodePositionsDispatchToProps
)(CustomNodePositionsPresenter)