import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    getSessionLogBySessionId,
    getUnitLogByUnitId,
} from '../../../../../redux/sagas/LMS/selectors'
import {
    getUserSessionById
} from '../../../../../redux/sagas/LMSTO/selectors'
import {
    loadAllScormParams,
    loadUnitsLogs
} from '../../../../../redux/actions/COMMON'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace LMSTO {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace StudentResultsScorm {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            unit: any
            sid: string
            cid: string
            userId: string
            unitContent: any
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = LMSTO.StudentResultsScorm.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    sessionLog: getUserSessionById(props.cid, props.userId, props.sid)(state) as any,
    unitLog: getUnitLogByUnitId(props.unit.unitId)(state) as any
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadAllScormParams,
    loadUnitsLogs
}, dispatch);

////////////

export const cnClassName = cn('TOStudentResultsScorm');