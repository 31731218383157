/**
 * Based on the Scorm 1.2 definitions from https://scorm.com
 *
 * Scorm 1.2 Overview for Developers: https://scorm.com/scorm-explained/technical-scorm/scorm-12-overview-for-developers/
 * Run-Time Reference: http://scorm.com/scorm-explained/technical-scorm/run-time/run-time-reference/
 */

import CMI,
{
    ObjectivesObject,
    InteractionsObject,
    InteractionsObjectivesObject,
    InteractionsCorrectResponsesObject
} from './cmi'
import constants from '../constants'
import BaseAPI, { BaseAPITypes } from '../baseAPI/baseAPI'

export type SetValueType = (CMIElement: BaseAPITypes.CMIElementType, value: any) => void

export interface ScormAPI2004Params {
    // setValueCb?: SetValueType
}

class ScormAPI12 extends BaseAPI {

    /**
     * 
     */
    public version = "1.0";

    /**
     * 
     */
    public cmi: CMI;


    constructor(param?: ScormAPI2004Params) {
        super()
        this.cmi = new CMI(this)
    }


    /**
     * 12
     * @param Empty String
     * @returns {string} bool
     */
    public LMSInitialize() {
        let returnValue = constants.SCORM_FALSE;

        if (this.isInitialized()) {
            this.throwSCORMError(101, "LMS was already initialized!");
        } else if (this.isTerminated()) {
            this.throwSCORMError(101, "LMS is already finished!");
        } else {
            this.currentState = constants.STATE_INITIALIZED;
            returnValue = constants.SCORM_TRUE;
            this.processListeners("LMSInitialize");
        }

        this.apiLog("LMSInitialize", null, "returned: " + returnValue, constants.LOG_LEVEL_INFO);
        this.clearSCORMError(returnValue);

        return returnValue;
    }

    /**
     * 12
    * @param Empty String
    * @returns {string} bool
    */
    public LMSFinish() {
        let returnValue = constants.SCORM_FALSE;

        if (this.checkState()) {
            this.currentState = constants.STATE_TERMINATED;
            returnValue = constants.SCORM_TRUE;
            this.processListeners("LMSFinish");
        }

        this.apiLog("LMSFinish", null, "returned: " + returnValue, constants.LOG_LEVEL_INFO);
        this.clearSCORMError(returnValue);

        return returnValue;
    }

    /**
     * @param Empty String
     * @returns {string} bool
     */
    /*public Terminate() {
        let returnValue = constants.SCORM_FALSE;

        if (this.isNotInitialized()) {
            this.throwSCORMError(112);
        } else if (this.isTerminated()) {
            this.throwSCORMError(113);
        } else {
            this.currentState = constants.STATE_TERMINATED;
            this.lastErrorCode = 0;
            returnValue = constants.SCORM_TRUE;
            this.processListeners("Terminate");
        }

        this.apiLog("Terminate", null, "returned: " + returnValue, constants.LOG_LEVEL_INFO);
        this.clearSCORMError(returnValue);

        return returnValue;
    }*/


    /**
     * 12
     * @param CMIElement
     * @returns {string}
     */
    public LMSGetValue(CMIElement: BaseAPITypes.CMIElementType) {
        let returnValue: any = "";

        if (this.checkState()) {
            returnValue = this.getCMIValue(CMIElement);
            this.processListeners("LMSGetValue", CMIElement);
        }

        this.apiLog("LMSGetValue", CMIElement, ": returned: " + returnValue, constants.LOG_LEVEL_INFO);
        this.clearSCORMError(returnValue);

        return returnValue;
    }

    /**
     * 12
     * @param CMIElement
     * @param value
     * @returns {string}
     */
    public LMSSetValue(CMIElement: BaseAPITypes.CMIElementType, value: any) {
        let returnValue = "";

        if (this.checkState()) {
            returnValue = this.setCMIValue(CMIElement, value);
            this.processListeners("LMSSetValue", CMIElement, value);
        }

        this.apiLog("LMSSetValue", CMIElement, ": " + value + ": returned: " + returnValue, constants.LOG_LEVEL_INFO);
        this.clearSCORMError(returnValue);

        return returnValue;
    }

    /**
     * Orders LMS to store all content parameters
     *
     * @returns {string} bool
     */
    public LMSCommit() {
        let returnValue = constants.SCORM_FALSE;

        if (this.checkState()) {
            returnValue = constants.SCORM_TRUE;
            this.processListeners("LMSCommit");
        }

        this.apiLog("Commit", null, "returned: " + returnValue, constants.LOG_LEVEL_INFO);
        this.clearSCORMError(returnValue);

        return returnValue;
    }

    /**
     * 12
     * Returns last error code
     *
     * @returns {string}
     */
    public LMSGetLastError() {
        let returnValue = String(this.lastErrorCode);

        this.processListeners("LMSGetLastError");

        this.apiLog("LMSGetLastError", null, "returned: " + returnValue, constants.LOG_LEVEL_INFO);

        return returnValue;
    }

    /**
     * 12
     * Returns the errorNumber error description
     *
     * @param CMIErrorCode
     * @returns {string}
     */
    public LMSGetErrorString(CMIErrorCode: BaseAPITypes.CMIErrorCodeType) {
        let returnValue = "";

        if (CMIErrorCode !== null && CMIErrorCode !== "") {
            returnValue = this.getLmsErrorMessageDetails(CMIErrorCode);
            this.processListeners("LMSGetErrorString");
        }

        this.apiLog("LMSGetErrorString", null, "returned: " + returnValue, constants.LOG_LEVEL_INFO);

        return returnValue;
    }

    /**
     * 12
     * Returns a comprehensive description of the errorNumber error.
     *
     * @param CMIErrorCode
     * @returns {string}
     */
    public LMSGetDiagnostic(CMIErrorCode: BaseAPITypes.CMIErrorCodeType) {
        let returnValue = "";

        if (CMIErrorCode !== null && CMIErrorCode !== "") {
            returnValue = this.getLmsErrorMessageDetails(CMIErrorCode, true);
            this.processListeners("LMSGetDiagnostic");
        }

        this.apiLog("LMSGetDiagnostic", null, "returned: " + returnValue, constants.LOG_LEVEL_INFO);

        return returnValue;
    }

    /**
     * 12
     * Checks the LMS state and ensures it has been initialized
     * 
     */
    public checkState() {
        if (!this.isInitialized()) {
            this.throwSCORMError(301);
            return false;
        }

        return true;
    }

    /**
     * 12
     * Sets a value on the CMI Object
     *
     * @param CMIElement
     * @param value
     * @returns {string}
     */
    public setCMIValue(CMIElement: BaseAPITypes.CMIElementType, value: any) {
        if (!CMIElement || CMIElement === "") {
            return constants.SCORM_FALSE;
        }

        const structure = CMIElement.split(".");
        let refObject = this as { [key: string]: any };
        let returnValue = constants.SCORM_FALSE;

        for (var i = 0; i < structure.length; i++) {
            const attribute = structure[i];
            if (i === structure.length - 1) {
                if (!(attribute in refObject)) {
                    this.throwSCORMError(101, "1 setCMIValue did not find an element for: " + CMIElement + " / attribute: " + attribute);
                } else {
                    refObject[attribute] = value;
                    returnValue = constants.SCORM_TRUE;
                }
            } else {
                refObject = refObject[attribute];
                if (!refObject) {
                    this.throwSCORMError(101, "2 setCMIValue did not find an element for: " + CMIElement + " / attribute: " + attribute);
                    break;
                }

                // if (attribute in refObject) {
                if ("childArray" in refObject) {
                    const index = parseInt(structure[i + 1], 10);
                    // index = isNaN(index) ? 0 : index
                    // SCO is trying to set an item on an array
                    if (!isNaN(index)) {
                        var item = refObject.childArray[index];

                        if (item) {
                            refObject = item;
                        } else {
                            var newChild;

                            if (CMIElement.indexOf("cmi.objectives") > -1) {
                                newChild = new ObjectivesObject(this);
                            } else if (CMIElement.indexOf(".correct_responses") > -1 && attribute !== 'interactions') {
                                newChild = new InteractionsCorrectResponsesObject(this);
                            } else if (CMIElement.indexOf(".objectives") > -1) {
                                newChild = new InteractionsObjectivesObject(this);
                            } else if (CMIElement.indexOf("cmi.interactions") > -1) {
                                newChild = new InteractionsObject(this);
                            }

                            if (!newChild) {
                                this.throwSCORMError(101, "Cannot create new sub entity: " + CMIElement);
                            } else {
                                // refObject.childArray.push(newChild);
                                const lastIndex = refObject.childArray.length - 1
                                const fillFrom = lastIndex === -1 ? 0 : lastIndex
                                const fillCount = index - refObject.childArray.length;

                                if (null === item) {
                                    refObject.childArray[index] = newChild
                                } else {
                                    if (index > fillFrom) {
                                        (new Array(fillCount)).fill('').forEach(element => {
                                            refObject.childArray.push(null);
                                        });
                                    }
                                    refObject.childArray.push(newChild);
                                }
                                refObject = newChild;
                            }
                        }

                        // Have to update i value to skip the array position
                        i++;
                    }
                }
            }
        }

        if (returnValue === constants.SCORM_FALSE) {
            this.apiLog("LMSSetValue", null, "There was an error setting the value for: " + CMIElement + ", value of: " + value, constants.LOG_LEVEL_WARNING);
        }

        return returnValue;
    }

    /**
     * 12
     * Gets a value from the CMI Object
     *
     * @param CMIElement
     * @returns {*}
     */
    public getCMIValue(CMIElement: BaseAPITypes.CMIElementType) {
        if (!CMIElement || CMIElement === "") {
            return "";
        }

        const structure = CMIElement.split(".");
        let refObject = this as { [key: string]: any };
        let lastProperty: any = null
        for (var i = 0; i < structure.length; i++) {
            const lastProperty = structure[i];

            if (i === structure.length - 1) {
                if (!(lastProperty in refObject)) {
                    this.throwSCORMError(101, "getCMIValue did not find a value for: " + CMIElement);
                    return "";
                }
            }

            refObject = refObject[lastProperty];
        }

        if (refObject === null || refObject === undefined) {
            if (lastProperty === "_children") {
                this.throwSCORMError(202);
            } else if (lastProperty === "_count") {
                this.throwSCORMError(203);
            }
            return "";
        } else {
            return refObject;
        }
    }

    /**
     * 12
     * Returns the message that corresponds to errrorNumber.
     */
    public getLmsErrorMessageDetails(errorCode: BaseAPITypes.CMIErrorCodeType, detail?: any) {
        var basicMessage = "";
        var detailMessage = "";

        // Set error number to string since inconsistent from modules if string or number
        const errorNumber = String(errorCode);

        switch (errorNumber) {
            case "101":
                basicMessage = "General Exception";
                detailMessage = "No specific error code exists to describe the error. Use LMSGetDiagnostic for more information";
                break;

            case "201":
                basicMessage = "Invalid argument error";
                detailMessage = "Indicates that an argument represents an invalid data model element or is otherwise incorrect.";
                break;

            case "202":
                basicMessage = "Element cannot have children";
                detailMessage = "Indicates that LMSGetValue was called with a data model element name that ends in \"_children\" for a data model element that does not support the \"_children\" suffix.";
                break;

            case "203":
                basicMessage = "Element not an array - cannot have count";
                detailMessage = "Indicates that LMSGetValue was called with a data model element name that ends in \"_count\" for a data model element that does not support the \"_count\" suffix.";
                break;

            case "301":
                basicMessage = "Not initialized";
                detailMessage = "Indicates that an API call was made before the call to LMSInitialize.";
                break;

            case "401":
                basicMessage = "Not implemented error";
                detailMessage = "The data model element indicated in a call to LMSGetValue or LMSSetValue is valid, but was not implemented by this LMS. SCORM 1.2 defines a set of data model elements as being optional for an LMS to implement.";
                break;

            case "402":
                basicMessage = "Invalid set value, element is a keyword";
                detailMessage = "Indicates that LMSSetValue was called on a data model element that represents a keyword (elements that end in \"_children\" and \"_count\").";
                break;

            case "403":
                basicMessage = "Element is read only";
                detailMessage = "LMSSetValue was called with a data model element that can only be read.";
                break;

            case "404":
                basicMessage = "Element is write only";
                detailMessage = "LMSGetValue was called on a data model element that can only be written to.";
                break;

            case "405":
                basicMessage = "Incorrect Data Type";
                detailMessage = "LMSSetValue was called with a value that is not consistent with the data format of the supplied data model element.";
                break;

            default:
                basicMessage = "No Error";
                detailMessage = "No Error";
                break;
        }

        return detail ? detailMessage : basicMessage;
    }


    /**
     * Loads CMI data from a JSON object.
     */
    public loadFromJSON(json: { [key: string]: any }, CMIElement: BaseAPITypes.CMIErrorCodeType) {
        /*if (!this.isNotInitialized()) {
            console.error("loadFromJSON can only be called before the call to Initialize.");
            return;
        }

        CMIElement = CMIElement || "cmi";

        for (var key in json) {
            if (json.hasOwnProperty(key) && json[key]) {
                var currentCMIElement = CMIElement + "." + key;
                var value = json[key];

                if (value["childArray"]) {
                    for (var i = 0; i < value["childArray"].length; i++) {
                        this.loadFromJSON(value["childArray"][i], currentCMIElement + "." + i);
                    }
                } else if (value.constructor === Object) {
                    this.loadFromJSON(value, currentCMIElement);
                } else {
                    this.setCMIValue(currentCMIElement, value);
                }
            }
        }*/
    }

    /**
     * Reset the API to its initial state
     */
    public reset() {
        super.reset();

        // Data Model
        this.cmi = new CMI(this);
        // this.adl = new ADL(this);
    }

}


export default ScormAPI12