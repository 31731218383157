import { IClassNameProps } from '@bem-react/core';
import { IColumn, IWidget } from '../../i.widgets';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import {
  updateContext,
  removeWidget,
  updateWidget,
  addWidget,
  dragWidget,
  setStructureFromWidget,
  addTabToWidget,
  changeWidgetSettings,
  changeWidgetsViewType,
  setDraggingElem,
  removePropFromContext
} from '../../redux/actions/Widgets';
import { cn } from '@bem-react/classname';

export interface ILicenseSettings {
  isRequired: boolean;
  okText: string;
}

export interface ILicenseProps extends ILicenseOwnProps, ImapLicenseActionToProps {

}

export interface ILicenseOwnProps extends IClassNameProps {
  tag?: React.ComponentType<any>;
  widget: IWidget<IColumn | Record<string, any>>,
  settings: ILicenseSettings;
  edit?: boolean;
}

export const mapLicenseActionToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  updateContext,
  updateWidget,
  setStructureFromWidget,
  removePropFromContext
}, dispatch);

export type ImapLicenseActionToProps = ReturnType<typeof mapLicenseActionToProps>;

export const cnLicense = cn('License');