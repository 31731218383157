import { IStateType as IState } from 'redux/store';

/**
 * return shop product & loading
 * @param { IState } state
 */
export const getShopProductSelector = (state: IState) => state.shop.openShopProduct;

export const getShopCategoryProducts = (state: IState) => state.shop.categoryProducts;

export const getShopCategoryStatus = (state: IState) => ({
    isLoading: state.shop.categoryProducts.loading,
    isFinished: state.shop.categoryProducts.finished,
}) 

export const selectChoosenFilters = (state: IState) => state.shop.chosenFilters;