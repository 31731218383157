import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import PieChart, { LabelProps } from 'react-minimal-pie-chart';
import { Icon } from 'uielements/src';
import LinearProgress from '@material-ui/core/LinearProgress';
import { tSt } from 'LMSModule/utils/i18n.adaptor'
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';

import Tooltip from 'uielements/src/MaterialElements/Tooltip'

type IPresenterProps = StatisticsFull.SummaryInfo.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    pieChart,
    complete,
    failed,
    inProgress,
    total,
    trainingLevel,
    isLoading
}) => {
    return <div className={cnClassName({}, [className])} >
        <div className={cnClassName('ChartBox')}>
            {isLoading && <LinearProgress className={cnClassName('Progress')} />}
            <PieChart
                className={cnClassName('Chart')}
                data={pieChart}
                lineWidth={43}
                labelPosition={0}
                label={(val: LabelProps) => <foreignObject width={'54px'} height={'54px'} x={'20px'} y={'14px'}>
                    <Icon className={cnClassName('ChartIcon')} icon={'graduation-cap'} />
                </foreignObject>}
                labelStyle={{
                    fontSize: '25px',
                    fill: '#F0A70A',
                }}
            />
        </div>
        <div className={cnClassName('ValuesBox')}>
            <div className={cnClassName('Item')}>
                <div className={cnClassName('Title')}>
                    {tSt('info.trainingLevel.title')}
                    {/* Обученность */}
                </div>
                <div className={cnClassName('Value')}>
                    <span className={cnClassName('Higthlight')}>{trainingLevel}</span>
                    <span className={cnClassName('Percent')}>%</span>
                </div>
            </div>
            <div className={cnClassName('Item')}>
                <div className={cnClassName('Title')}>
                    {tSt('info.total.title')}
                    {/* Всего */}
                </div>
                <div className={cnClassName('Value')}>{total}</div>
            </div>
            <div className={cnClassName('Item')}>
                <div className={cnClassName('Title')}>
                    {tSt('info.inProgress.title')}
                    {/* В процессе */}
                </div>
                <div className={cnClassName('Value')}>{inProgress}</div>
            </div>
            <div className={cnClassName('Item')}>
                <div className={cnClassName('Title')}>
                    {tSt('info.complete.title')}
                    {/* Завершили */}
                </div>
                <div className={cnClassName('Value')}>{complete}</div>
            </div>
            <div className={cnClassName('Item')}>
                <div className={cnClassName('Title')}>
                    {tSt('info.failed.title')}
                    <Tooltip title={tSt('info.failed.help')} placement="top" arrow>
                        <HelpIcon className={cnClassName('HelpIcon')} color={'action'} />
                    </Tooltip>
                    {/* Не прошло */}
                </div>
                <div className={cnClassName('Value')}>{failed}</div>
            </div>
        </div>
    </div>
}

export default memo(Presenter);
