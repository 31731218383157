import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
  //saga
  LOAD_KPI_FOR_USER: 'LOAD_KPI_FOR_USER', // загрузить список кпи пользователя
  CHANGE_KPI_ITEM: 'CHANGE_KPI_ITEM', // изменить экземпляр
  CREATE_ITEM: 'ADD_ITEM', //добавление итема
  DELETE_KPI_ITEM: 'DELETE_KPI_ITEM',


  //reducer
  SET_KPI_LIST: 'SET_KPI_LIST', //
  PATH_KPI_ITEM: 'PATH_KPI_ITEM', //
  REMOVE_KPI_ITEM: 'REMOVE_KPI_ITEM', //
  ADD_KPI_ITEM: 'ADD_KPI_ITEM', //
  DROP_KPI_ITEM: 'DROP_KPI_ITEM', //

  //common
  CHANGE_FIELD: 'CHANGE_FIELD', // изменяет указаное свойство
  RESET: 'RESET', // сбрасывает указаное свойство

} as const



export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.KPI) }), {} as typeof actions);