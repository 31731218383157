import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IPriorityNavTab } from "blocks/PriorityNav/PriorityNav.index";

export interface INewsPostBlockProps extends IClassNameProps {
  gId: number;
  pushNews: (data: any) => void;
}

export interface INewsPostBlockState {
  text?: string;
  postTypes: IPriorityNavTab[];
  activePost?: IPriorityNavTab;
  hide: boolean;
}

export const cnNewsPostBlock = cn('NewsPostBlock');