import React, { FC, ComponentType } from 'react'
import { confirm } from 'utils.project/utils.project'


interface IWithConfirmProps {
    confirmTitle?: string;
    confirmText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    onClick?: (e: any) => void;
}

function withConfirm<P = any>(Component: ComponentType<P>): FC<IWithConfirmProps & P> {
    return ({ confirmTitle, onConfirm, onClick, onCancel, confirmText, ...props }) => {
        const onClickButton = (e: any) => {
            onClick && onClick(e)
            onConfirm && confirm({
                title: confirmTitle,
                text: confirmText,
                onConfirm,
                onCancel
            })
        }
        const componentProps = props as P
        return <Component
            {...componentProps}
            onClick={onClickButton}
        />
    }
}


export default withConfirm