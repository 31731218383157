import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    Icon,
} from 'uielements/src';
import withLoadSpin from 'uielements/src/HOCs/Button/withLoadSpin';
import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg';
import i18n from 'localizations/i18n';
import './CreateTrack.scss'
import { ICreateTrackProps, cnCreateTrack } from './CreateTrack.index'
import uuid from 'uuid/v1';
import { trackModelCreator } from 'LMSModule/utils/dataCreators/trackCreator'
import { ImageLoader } from 'blocks/ImageLoader/ImageLoader';
import { ILearningTrack } from 'utils/src/requests/models/api.learningTracks'
import { TextInput } from 'blocks/PryanikyForms'
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import { useLazyExecutSaga } from 'utils/src/CommonRedux/LoadIndicator'
import { useLazyQueryEx } from 'utils/src/hooks'
import {
    createTrack,
    setTracksList
} from '../../redux/actions/TracksManage'
import { useHistory } from 'react-router'
import { createModal } from 'utils/src/DialogCreator'
import DialogBody from 'uielements/src/MaterialElements/DialogParts/DialogBody'
import {
    API,
    objectArrayToMap,
    arrayMove
} from 'utils/src/utils'

const LoadBtn = withLoadSpin(Button)

export const CreateTrackPresenter: FC<ICreateTrackProps> = ({
    handleAccept,
    handleClose
}) => {
    const [track, setTrack] = useState(trackModelCreator())
    const { push } = useHistory()
    const { send, isLoading, error } = useLazyQueryEx(async function (arg: ReturnType<typeof trackModelCreator>) {
        return await (await API.learningTracks.setCreateTrack(arg)).r
    })
    const dispatch = useDispatch()

    const onChangeString = (property: "tagId" | "tagName" | "tagUrl") => (val: string) => {
        setTrack({ ...track, [property]: val })
    }

    const onLoadEnd = (img: any) => setTrack({ ...track, imgId: img.id, imgUrl: img.previewUrl })

    async function complete() {
        try {
            const result = await send(track)
            if (result) {
                const data = objectArrayToMap<ILearningTrack, ILearningTrack>((result as any), 'tagId')
                dispatch(setTracksList({ data, keys: Object.keys(data) }, true))
                push(`/TrackManage/${result[0].tagId}`)
                handleAccept({})
            }
        } catch (error) {
            console.error('error ', error)
        }
    }
    return <DialogBody
        header={tTr('dialogs.create_track.header.1')}
        isLoading={isLoading}
        onAccept={complete}
        disableAccept={track.tagName === ''}
        onClose={handleClose}
        acceptText={tTr('dialogs.create_track.create.1')}
        closeText={i18n.t('close')}
    >
        <ImageLoader
            className={''}
            cropper
            onLoaded={onLoadEnd}
            title={tTr('dialogs.create_track.image.1')}
            description={i18n.t('pryaniky.text.loadIconWithSize')}
        />

        <TextInput
            className={cnCreateTrack('Name')}
            icon={<Icon icon={'fa-bell'} />}
            placeholder={tTr('dialogs.create_track.name.1')}
            value={track.tagName}
            onChange={onChangeString('tagName')} />

        {error.isError && <ErrorMsg>
            {error.errorInfo}
        </ErrorMsg>}
    </DialogBody>
    // return (
    //     <>
    //         <Dialog isShown={isShown} onClose={onClose} className={cnCreateTrack({}, [])}>
    //             <DialogTitle onClose={onClose}>
    //                 <h4 className="my-1">{tTr('dialogs.create_track.header.1')}</h4>
    //             </DialogTitle>
    //             <div>
    //                 <ImageLoader
    //                     className={''}
    //                     cropper
    //                     onLoaded={onLoadEnd}
    //                     title={tTr('dialogs.create_track.image.1')}
    //                     description={i18n.t('pryaniky.text.loadIconWithSize')}
    //                 />

    //                 <TextInput
    //                     className={cnCreateTrack('Name')}
    //                     icon={<Icon icon={'fa-bell'} />}
    //                     placeholder={tTr('dialogs.create_track.name.1')}
    //                     value={track.tagName}
    //                     onChange={onChangeString('tagName')} />

    //                 {isFailed && <ErrorMsg>
    //                     {errors?.[0]?.error_text}
    //                 </ErrorMsg>}

    //             </div>
    //             <DialogActions>
    //                 <LoadBtn
    //                     isLoading={isLoading}
    //                     main
    //                     disabled={track.tagName === ''} onClick={complete}>{tTr('dialogs.create_track.create.1')}</LoadBtn>
    //                 <Button onClick={onClose}>
    //                     {i18n.t('close')}
    //                 </Button>
    //             </DialogActions>
    //         </Dialog>
    //     </>
    // );
};

export const CreateTrackDialog = createModal(CreateTrackPresenter)
