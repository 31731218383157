import React, { FC, useMemo, useState } from 'react';
import { VacancyProps } from './Vacancy.index';
import { Translate } from 'localizations/Translate';
import { Tabs } from 'muicomponents/src';
import { useVacancy, useVacancyData } from './Vacancy.hooks';
import { EditButton, HeadersBox, TopInfoBox, VacancyBox } from './Vacancy.styled';
import { generatePath, useParams } from 'react-router';
import moment from 'moment';
import { VacancyMainTab } from './tabs/main/VacancyMainTab';
import { useSelector } from 'react-redux';
import { getCurrentUser, getCurrentUserIsAdmin } from 'utils/src/CommonRedux/base/selectors';
import { VacancySuggestedUsers } from './tabs/suggested/VacancySuggestedUsers';
import { VacancyRequestedUsers } from './tabs/requested/VacancyRequestedUsers';
import { Loading } from 'uielements/src/Loading/Loading';
import { Check as CheckIcon } from 'muicomponents/src/Icons';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { Typography } from 'muicomponents/src/Typography';
import { Link } from 'muicomponents/src/Link/Link';
import { Edit } from 'muicomponents/src/Icons';
import urls from 'routes/urls';
import { adminbasename } from 'muicomponents/src/Button/Button.hooks';

const vacancyTabs = [
    {
        id: 'main',
        label: <Translate i18nKey={'pryaniky.vacancy.tab.main'} />
    },
    {
        id: 'requestedUsers',
        label: <Translate i18nKey={'pryaniky.vacancy.tab.requestedUsers'} />
    },
    {
        id: 'sudgestedUsers',
        label: <Translate i18nKey={'pryaniky.vacancy.tab.sudgestedUsers'} />
    }
];

export const Vacancy: FC<VacancyProps> = ({
    id: paramId
}) => {

    const currentUser = useSelector(getCurrentUser);
    const isAdmin = useSelector(getCurrentUserIsAdmin);

    const [activeTab, setActiveTab] = useState(vacancyTabs[0].id);
    
    let {
        id
    } = useParams<{id: string}>();

    if(paramId) id = paramId;

    const {
        item,
        currentUserRequestSended,
        isLoading
    } = useVacancy(id);
    
    const viewDate  = useMemo(() => {
        if(!item?.changeDate && !item?.creationDate) return null;
        const dates = [moment(item?.changeDate || 0).toDate().getTime(), moment(item?.creationDate || 0).toDate().getTime()];
        const hightestDate = Math.max(...dates);
        const withEdit = dates.findIndex((el) => el === hightestDate) === 0;
        return <>{moment(withEdit ? item?.changeDate : item?.creationDate).format('L HH:mm')}</>
    }, [item?.changeDate, item?.creationDate]);

    if(!id) {
      console.error(`widget vacancy doesn't have require param "id", check settings or url params`);
      return null;
    };

    const activeTabView = useMemo(() => {
        switch(activeTab) {
            case 'main': 
                return <VacancyMainTab id={id} />
            case 'requestedUsers':
                return <VacancyRequestedUsers id={id} />;
            case 'sudgestedUsers':
                return <VacancySuggestedUsers id={id} />;
            default:
                return null;
        }
    }, [activeTab, id]);

    const tabsView = useMemo(() => {
        if(!isAdmin) return null;
        return (
            <Tabs
                value={activeTab}
                tabs={vacancyTabs}
                onChange={(e, tabId) => {
                    setActiveTab(tabId);
                }}
            />
        );
    }, [activeTab, isAdmin]);

    const editLink = useMemo(() => {
        if(!isAdmin) return null;
        return <EditButton {...{ href: generatePath(`${adminbasename}${urls.vacancy}`, { id }) } as any}>
            <Edit />
        </EditButton>;
    }, [isAdmin, id]);

    if(isLoading) return (
        <VacancyBox>
            <Loading />
        </VacancyBox>
    );

    if(!item) return null;

    return (
        <VacancyBox>
            <Link to={urls.vacancies}>
                <Translate i18nKey={'pryaniky.vacancy.returntolist'} />
            </Link>
            {
                editLink
            }
            <HeadersBox
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <div>
                    {item.name}
                    <TopInfoBox>
                        {viewDate}
                    </TopInfoBox>
                </div>
                {
                currentUserRequestSended
                &&
                <Tooltip
                    title={<Translate i18nKey='pryaniky.vacancy.request.sended' />}
                >
                    <CheckIcon color={'success'} />
                </Tooltip>
                }
            </HeadersBox>
            {tabsView}
            {activeTabView}
        </VacancyBox>
    );
};