import React, { FC, memo, useCallback, useMemo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext, NewsContextProps } from '../../contexts/news'
import { Button, Avatar, Icon } from 'uielements/src';
import { Translate } from 'localizations/Translate';
import { CommentsLayout } from 'blocks/NewsTypes/common/CommentsLayout/CommentsLayout';
import { replyWithNewsContext as Reply } from 'blocks/Reply/Reply';
import { ReplyEditor } from 'blocks/ReplyEditor/ReplyEditor';
import { ReplySkeleton } from 'blocks/Skeletons/Replys/Reply-Skeleton';
import * as utils from 'utils/src/utils';
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import ReplyItem from '../ReplyItem'

type IPresenterProps = News.NewsComments.IPresenterProps
type IOwnProps = News.NewsComments.IOwnProps
type IDispatchProps = News.NewsComments.IDispatchProps
type IStateProps = News.NewsComments.IStateProps
type IState = News.NewsComments.IState


export const Presenter: FC<IPresenterProps> = ({
    className,
    newsId,
    loadComments,
    thankReplyEnabled,
    toggleThank,
    isLoading,
    isSending,
    getNode,
    user,
    comments,
    commentsCount,
    newstype,
    allowReply,
    thanks,
    contentRating,
    edit
}) => {
    const count = commentsCount - comments.length

    const onLoad = useCallback(() => loadComments({
        commentsCount, id: newsId
    }), [commentsCount, newsId])

    const valuesTranslate = useMemo(() => ({ count }), [count])

    return <CommentsLayout
        more={
            (comments && (comments.length < commentsCount) && !isLoading) && (
                <Button className={('MoreButton')} theme={'unstyled_center'} onClick={onLoad}>
                    <Translate i18nKey='pryaniky.post.comments.reveal' count={count} values={valuesTranslate} />
                </Button>)
        }
        form={
            <>
                {/**
             * форма комментария
             */}
                {(allowReply) && <ReplyEditor
                    withRating={contentRating?.enable}
                    newsAuthor={utils.newNewsType(newstype) === 'thanks' ? user : {}}
                    toggleThank={toggleThank}
                    isThanks={thankReplyEnabled}
                    users={utils.newNewsType(newstype) !== 'thanks' ? [user] : thanks?.participants}//todo - быдлокод
                    newsId={newsId}
                />}

                {/**
                 * дополнительная форма. Например конкурса
                 */}
                {getNode(newsId)}
            </>
        }
    >
        {isLoading && (new Array(10).fill(<ReplySkeleton />))}

        {comments && comments.map((commentId: string, i: number) => <ReplyItem key={commentId} newsId={newsId} id={commentId} withRating={contentRating?.enable} />)}

        {isSending && <ReplySkeleton />}
    </CommentsLayout>
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withComponentEnjector<IPresenterProps>(memo(Presenter), ['news_additional_reply_form'])));

