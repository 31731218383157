/**
 * @packageDocumentation
 * @module Widget_Settings_sliderlink
 */
import { JFdictionary } from "../dictionary";
import { GUID_EMPTY } from "utils/src/constants.prn";
import { IVirtualUserItemData } from "utils/src/requests/models/api.virtualuser";
import { v1 as uuid } from 'uuid';

const { renderers } = JFdictionary;

export interface IVirtualUserItem extends IVirtualUserItemData {
  deleted: false
}

export const emptyItem: IVirtualUserItem = {
  id: uuid(),
  text: '',
  deleted: false
}

export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.virtualUser,
      scope: "#/properties/virtualUser"
    },
  ]
}