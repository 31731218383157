import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets'

export const common = (): IWidget<IColumn[]> => {

  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({}),
    ],
  })
}