import React, { FC, memo } from 'react'
import {
    cnClassName,
    News
} from './interfaces'
import './style.scss'

type IPresenterProps = News.ContentLayout.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    slave,
    info
}) => {
    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Master')}>{children}</div>
        <div className={cnClassName('Slave')}>{slave}</div>
        <div className={cnClassName('Info')}>{info}</div>
    </div >
}

export default memo(Presenter);
