import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IDialogTitlePresenterProps = IDialogTitleOwnProps

export interface IDialogTitleState { }

export interface IDialogTitleOwnProps extends IClassNameProps {
    id?: string;
    onClose?: () => void;
    children?: React.ReactNode;
}

export const cnClassName = cn('ModalDialogTitle');