import * as React from 'react';
import {BaseNews,defaultBaseNews} from 'blocks/NewsTypes/BaseType'
export interface NewsContextProps {
    textAreaRef: any;
    onChangeComment: (val: string) => void;
    commentFormValue: string;
    textAreaRefGetter: (ref: any) => void;
    onReply: (val: string) => void;
    isSingle: () => boolean;
    isRepost: () => boolean;
    getNews: () => BaseNews
    
}

export const NewsContext = React.createContext<NewsContextProps>({ 
    textAreaRef: undefined,//React.createRef<HTMLInputElement>(), 
    onChangeComment: (val: string) => { }, 
    commentFormValue: '',
    textAreaRefGetter: (ref: any) => {},
    onReply: (val: string) => {},
    isSingle: () => false,
    isRepost: () => false,
    getNews: () => defaultBaseNews
})


export const withNewsContext = (Component: any) =>
    (props: any) => <NewsContext.Consumer>
        {value => <Component {...{ ...props, ...value }} />}
    </NewsContext.Consumer>

