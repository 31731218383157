import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeUserfieldsProps } from './Widget_type_userfields.index';
import './Widget_type_userfields.scss';

import { default as WidgetPresenter } from '../Widget';

import { widgets } from "i.widgets";
import { UserFields } from 'blocks/Userfields';

const type = 'userfields';

/**
 * показывает поля пользователя по выбранному типу и дает их редактировать
 */
export default class WidgetTypeUserfieldsPresenter extends WidgetPresenter<IWidgetTypeUserfieldsProps> {

  public render() {
    if (!this.props.widget) return null;
    const fieldsIds = this.props.widget.settings?.fieldsIds || [];
    return <widgets.components.common {...this.props} className={cnWidget({ type })} context={this.props.wcontext} relations={this.props.widget.relations}>
      <UserFields fieldsIds={fieldsIds} context={this.props.wcontext} />
    </widgets.components.common>
  }

}
