import { cn } from '@bem-react/classname';
import { IBodyProps } from 'utils/src/DialogCreator';

export const cnNewsCreateDialog = cn('NewsCreateDialog');

export interface NewsCreateDialogResponse {
  success: boolean;
}

export interface INewsCreateDialogProps extends IBodyProps<NewsCreateDialogResponse> {
  oneNewsType: string;
  workflowId?: string;
  gId?: number;
  useEdit?: boolean
  defaultData?: any
}