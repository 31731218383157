import { mapDispatchToProps, IDispatchProps } from 'redux/connector';
import { cn } from '@bem-react/classname';
import { IDialog } from 'blocks/Dialogs/DialogWrapper.index';
import { IBodyProps } from 'utils/src/DialogCreator';
export interface ICreateGroupDialogProps extends ICreateGroupDialogOwnProps { }

export interface ICreateGroupDialogOwnProps extends Partial<IBodyProps> { }

export interface ICreateGroupDialogState {
  creating: boolean;
  name: string;
  description: string;
  isHidden: boolean;
  isClosed: boolean;
  img: any;
  parentGroup: any;
  [x: string]: any;
}

// export type ICreateGroupDialogStateProps = ReturnType<typeof mapCreateGroupStateToProps>;

// export const mapCreateGroupStateToProps = (state: any, props: ICreateGroupDialogOwnProps) => {
//   return {
   
//   }
// };

export const cnCreateGroup = cn('CreateGroup');