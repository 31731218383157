import Component, { Presenter } from './component';
import {
    IAddWikiToTrackOwnProps,
    IAddWikiToTrackPresenterProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = IAddWikiToTrackOwnProps;
export type IComponentPresenterProps = IAddWikiToTrackPresenterProps;


export default Component