
import { ThanksReplyType } from './ThanksReply.type'
import { BaseValidator } from '../Reply/Reply.validate'
import { User } from '../BaseType'
import { Rule, validateArray, validateNumber } from '../Base.validate'
import { nameCount } from 'utils/src/utils'
// import { ReplyType} from '../Reply/Reply.type'
export const thanksValidate: Rule[] = [
    {
        type: 'array',
        method: 'ARR_MIN',
        field: 'rewardReasons',
        value: 1,
        errorI18nKey: 'pryaniky.validate.news.thanks.rewardReasons.min',
        // message: 'Нужно выбрать хотя бы одну причину для благодарности'
    },
    {
        type: 'array',
        method: 'ARR_MAX',
        field: 'rewardReasons',
        value: 4,
        errorI18nKey: 'pryaniky.validate.news.thanks.rewardReasons.max',
        errorVariablesI18nKey: {
            count: 4
        }
        // message: 'Слишком много причин для благодарности, максимум 4'
    },
    {
        type: 'array',
        method: 'ARR_MIN',
        field: 'users',
        value: 1,
        errorI18nKey: 'pryaniky.validate.news.thanks.users.min',
        // message: 'Нужно выбрать кого поблагодарить'
    },
    // {
    //     type: 'array',
    //     method: 'ARR_MAX',
    //     field: 'users',
    //     value: 4,
    //     message: 'Можно выбрать не более 4-х пользователей'
    // },
    {
        type: 'number',
        method: 'NUM_MIN',
        field: 'thanksCount',
        value: 1,
        // message: 'Нужно вложить больше'
        errorI18nKey: 'pryaniky.validate.news.thanks.thanksCount.min',
    },
    {
        type: 'number',
        method: 'NUM_MIN',
        field: 'thanksForAll',
        value: 1,
        // message: 'У Вас недостаточно средств',
        errorI18nKey: 'pryaniky.validate.news.thanks.thanksForAll.min',
        major: true,
    },
    /*{
        type: 'number',
        method: 'NUM_MAX',
        field: 'thanksCount',
        value: 20,
        message: 'big thanksCount'
    },*/
    // {
    //     type: 'string',
    //     method: 'STR_MIN',
    //     field: 'text',
    //     value: 5,
    //     errorI18nKey: 'pryaniky.validate.news.thanks.text.min',
    //     errorVariablesI18nKey: {
    //         count: 5,
    //         variable: nameCount(5, 'pryaniky.validate.string.variable')
    //     }
    //     // message: 'Добавить текст благодарности, не менее 5 символов'
    // },
    // {
    //     type: 'string',
    //     method: 'STR_MAX',
    //     field: 'text',
    //     value: 250,
    //     message: 'Текст благодарности должен быть не более 250 символов'
    // },
]

export class ThanksValidator extends BaseValidator<ThanksReplyType> {
    protected otherValidate = (data: ThanksReplyType, rule: Rule) => {
        switch (rule.field) {
            case 'rewardReasons':
                return validateArray<number>(data.thanksInfo.thanksReasonIds, rule);
            case 'users':
                return validateArray<User>(data.thanksInfo.users, rule);
            case 'thanksCount':
                return validateNumber(data.thanksInfo.thanksCount, rule);
            case 'thanksForAll':
                return validateNumber(data.thanksForAll || 0, rule);
        }
        return true;
    }
}
