import { FBadgesRenderParams } from 'utils/src/BaseTypes/news.badges';
import { FNewsPostType } from 'utils/src/BaseTypes/news.types';

export namespace NBadges {

    export type RenderParams = FBadgesRenderParams;

    export interface Create extends FNewsPostType<'badges'> {
        newstype: 0;
    }
}