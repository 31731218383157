import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IBodyProps } from '../modal'
import { BaseNews } from 'blocks/NewsTypes/BaseType'
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    addFile
} from '../../redux/actions/TracksManage'
import { getNewsParamsById } from 'News/redux/saga/selectors'
///////////

export type IAddNewsToTrackPresenterProps = IAddNewsToTrackOwnProps & IAddNewsToTrackStateProps & IAddNewsToTrackDispatchProps

export interface IAddNewsToTrackState { }

export interface IAddNewsToTrackOwnProps extends IClassNameProps, IBodyProps<IAddNewsToTrackResult> {
    newsId: string
    tags: any[]
    text: string,
    header: string
}

export interface IAddNewsToTrackResult {
    newsUid: string
    tagName: string
}


export type IAddNewsToTrackStateProps = ReturnType<typeof mapStateToProps>
export type IAddNewsToTrackDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////


export const mapStateToProps = (state: any, props: IAddNewsToTrackOwnProps) => ({
    // ...getNewsParamsById(props.newsId, 'text', 'header')(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IAddNewsToTrackOwnProps) => bindActionCreators({
    // addFile
}, dispatch);

export const cnClassName = cn('LTAddNewsToTrack');