import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from '../../../redux/store';
import {
  getSecretSantaWidgetSettingsAction,
  isUserParticipateAction,
  secretSantaJoinAction,
  getUserToGivePresent,
  isPresentSent,
  presentSentAction,
  setUserDistributedToggleAction,
  setIsPresentSentAction,
  secretSantaUnJoinAction,
  mainSecretSantaSettingsAction
} from '../../../redux/sagas/SecretSanta/actions'
import {
  getSecretSantaWidgetSettings,
  getSecretSantaIsParticipate,
  getUserForPresentInfo,
  getIsPresentSend,
  getSettings
} from '../../../redux/sagas/SecretSanta/selectors'

import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface ISecretSantaWidget extends ISecretSantaWidgetOwnProps, ISecretSantaWidgetStateProps, ISecretSantaWidgetDispatchProps {
  // tag: any

  className?: string
  store?: any
  changeVm?: any
}
export interface ISecretSantaWidgetOwnProps{
  settings: any
  isListWidget?:boolean
  removeItemIfPresentSend?:(data:any[])=>void
}

export const mapStateToProps = (state: IState, props: ISecretSantaWidgetOwnProps) => {

  return {
    data:  getSecretSantaWidgetSettings(props.settings?.type?.id)(state),
    isParticipate: getSecretSantaIsParticipate(props.settings?.type?.id)(state),
    userForPresent: getUserForPresentInfo(props.settings?.type?.id)(state),
    edit: state.store.edit,
    isPresentSendData: getIsPresentSend(props.settings?.type?.id)(state),
    uIsAdmin: getAuthUser(state).baseData.isAdmin,
    isDistributLoading: getSettings(props.settings?.type?.id)(state)?.isDistributLoading,
    isLoading: getSettings(props.settings?.type?.id)(state)?.isLoading,

  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getSecretSantaWidgetSettingsAction,
  isUserParticipateAction,
  secretSantaJoinAction,
  getUserToGivePresent,
  isPresentSent,
  presentSentAction,
  setUserDistributedToggleAction,
  setIsPresentSentAction,
  secretSantaUnJoinAction,
  mainSecretSantaSettingsAction
}, dispatch);

export type ISecretSantaWidgetStateProps = ReturnType<typeof mapStateToProps>
export type ISecretSantaWidgetDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnSecretSantaWidget = cn('SecretSantaWidget')