import React, { FC } from 'react';
import { Tabs } from '../TabsMui';
import { styled } from 'muicomponents/src/mui/system';
import { Box } from 'muicomponents/src/Box/Box';

export const StyledTabs = styled(Tabs)({
    display: 'block'
}) as typeof Tabs;

export const TabsControlActions = styled(Box)({
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between'
}) as typeof Box;