import React, { FC, useState, memo, useEffect } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    ICommentsCountProps,
    ICommentsCountOwnProps,
    IDispatchProps,
    ICommentsCountStateProps,
    ICommentsCountState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Chart from 'uielements/src/Chart';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import { API } from 'utils/src/utils'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { useLazyQuery } from 'LMSModule/utils/hooks/useLazyQuery'

export const Presenter: FC<ICommentsCountProps> = ({
    className,
    cid,
    loadStatCommentsCount,
    commentsCount,
    sessionsIds = [],
    archiveSessions = [],
    normalSessions = {}
}) => {
    useEffect(() => {
        loadStatCommentsCount(cid)
    }, [cid])
    
    const [hideZero, setHideZero] = useState(true)
    const [sort, setSort] = useState(true)

    const sortedData = !hideZero ? [...commentsCount] : commentsCount.filter((val: any) => val.value > 0)
    sort && sortedData.sort((a: any, b: any) => {
        if (a.value > b.value) {
            return 1;
        }
        if (a.value < b.value) {
            return -1;
        }
        return 0;
    });

    const [getExcel, excelLoading] = useLazyQuery(() => API.lms.commentscountexcel(cid))

    return <div className={cnClassName({}, [className])}>

        <div className={cnClassName('Control')}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={hideZero}
                        onChange={() => setHideZero(!hideZero)}
                        name="hideZero"
                        color="primary"
                    />
                }
                label={tT('statistics.comments_count.hide_zero_values.1')}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={sort}
                        onChange={() => setSort(!sort)}
                        name="sort"
                        color="primary"
                    />
                }
                label={tT('statistics.comments_count.sort.1')}
            />

            <IconButton
                title={tT('statistics.reports.title.download.1')}
                disabled={excelLoading}
                onClick={getExcel}
                // href={`https://${API.base.domain}/ru/Data/v3/statistics/lms/commentscountexcel?camelCase=true&courseId=${cid}`}
            >
                <DescriptionIcon />
            </IconButton>
        </div>

        <Chart className={cnClassName('Chart')} width={600} height={30 * sortedData.length} data={sortedData} />

    </div>
}

export default connect<ICommentsCountStateProps, IDispatchProps, ICommentsCountOwnProps, ICommentsCountState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
