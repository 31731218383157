import React, { FC, useState, useEffect } from 'react';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { NBlocks } from '../Blocks.index';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { v1 as uuid } from 'uuid';
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import Input from 'uielements/src/MaterialElements/Input/Input';
import { ImageLoader } from 'uielements/src';
import { Translate } from 'localizations/Translate';
import { cnBlocksSettings, NBlocksSettings } from './Settings.index';
import './Settings.scss';

const emptyItem: NBlocks.Item = {
    id: '',
    file: null,
    imgUrl: '',
    text: '',
    url: ''
}

export const BlocksSettings: FC<NBlocksSettings.Props> = ({
    data,
    onChange,
    ...props
}) => {

    const [ items, setItems ] = useState<NBlocks.Item[]>(data?.items && JSON.parse(JSON.stringify(data?.items)) || []);

    useEffect(() => {
        onChange({
            settings: {
                items
            }
        })
    }, [items]);

    const addItem = () => {
        setItems([ ...items, { ...emptyItem, id: uuid() } ]);
    }

    const changeItem = (id: string, value: Partial<NBlocks.Item>) => {
        setItems(items.map(el => {
            if(el.id === id) return {
                ...el,
                ...value
            }
            return el;
        }));
    }

    const deleteItem = (id: string) => {
        setItems(items.filter(el => el.id !== id));
    }

    return (
        <div className={cnBlocksSettings()}>
            {
                items.map((item, idx) => {
                    return (
                        <div className={cnBlocksSettings('Item')}>
                            <IconButton className={'ItemRemove'} onClick={() => deleteItem(item.id)}>
                                <Close />
                            </IconButton>
                            <ImageLoader
                                onLoaded={(file) => {
                                    changeItem(item.id, { file, imgUrl: file.originalUrl })
                                }}
                                squarePreview
                                defaultUrl={item.imgUrl}
                                title={
                                    <>
                                        <Translate i18nKey="pryaniky.widgets.settings.blocks.item.image.title" />
                                    </>
                                }
                                description={
                                    <>
                                        <div>
                                            <Translate i18nKey="pryaniky.widgets.settings.blocks.item.image.description" />
                                        </div>
                                        <div>
                                            <Translate i18nKey="pryaniky.widgets.settings.blocks.item.image.aspectratio" />
                                        </div>
                                    </>
                                }
                            />
                            <Input
                                placeholder={<Translate i18nKey="pryaniky.widgets.settings.blocks.item.text" />}
                                value={item.text}
                                onChange={(text) => changeItem(item.id, { text })}
                            />
                            <Input
                                placeholder={<Translate i18nKey="pryaniky.widgets.settings.blocks.item.url" />}
                                value={item.url}
                                onChange={(url) => changeItem(item.id, { url })}
                            />
                        </div>
                    )
                })
            }
            <Button className={cnBlocksSettings('Add')} onClick={addItem}>
                <Translate i18nKey="pryaniky.widgets.settings.blocks.item.add" />
            </Button>
        </div>
    )
}

export const initWidgetAddition = () => ComponentInjector.getInstance().addComponent('WAddition', BlocksSettings, 'pryaniky/blocks')
export const initWidgetSettings = () => ComponentInjector.getInstance().addComponent('WSettings', BlocksSettings, 'pryaniky/blocks')