import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IStateType } from 'redux/store';
import {
    changeStructureDataOrgchart,
    saveStructureDataOrgchart,
    changePositionDataOrgchart,
    addNewPositions,
    saveEditNewPositions,
    addNewLeaderPositions,
    saveLeaderEditNewPositions,
    newPositionDataKill
} from '../../../../redux/sagas/orgchart/actions'
import {
    getOrgchartPositions,
    // getOrgchartChangedUnitSettings,
    getOrgchartChangedPosition
} from '../../../../redux/sagas/orgchart/selectors'

export interface IPositionElement {
    employee:  null | IUserShort[],
    id: string,
    isVacancyOpen: boolean,
    subordinates: any[],
    type: ITypeInterface,
    unitId: string,
    vacancy: IVacancyInterface,
}
export interface IVacancyInterface {
    name: string,
    description: string,
    id: string,
    attachments: IUploadFile[],
}
export interface ITypeInterface {
    description: string,
    id?: string,
    name: string,
}
export interface IUploadFile {
    action: string;
    id: string;
    name: string;
    originalUrl: string;
    pdfPreviewUrl: string;
    editUrl?: string;
    previewUrl: string;
    size: number;
    thumbUrl: string;
}
export interface IUserShort {
    alias: string;
    baseNetwork: IBaseNetwork;
    birthday: string | null;
    displayName: string;
    division: string;
    eMail: string;
    id: string;
    imgId: string;
    imgUrl: string;
    isDeleted: boolean;
    isMan: boolean;
    phone: string;
    position: string;
    sex: string;
    status: string;
    statusText: string;
    type: string;
    userLargePhotoUrl: string;
    userPhotoUrl: string;
  }
  export interface IBaseNetwork {
    id: string;
    name: string;
    title: string;
  }
export interface IEditPositionsProps extends IClassNameProps, IEditPositionsStateProps, IEditPositionsDispatchProps, IEditPositionsOwnProps {

}

export type IEditPositionsStateProps = ReturnType<typeof mapEditPositionsStateToProps>;

export interface IEditPositionsOwnProps {
    leaderAddVacancy?: boolean;

    unitId: string;
    posId?: string;
    isShown: boolean;
    onClose: () => void;
    addLeader: boolean;
    // onClickEdit: () => void;
}

export const mapEditPositionsStateToProps = (state: IStateType, props: IEditPositionsOwnProps) => {

    return {
        postionData: props.posId && getOrgchartChangedPosition(props.posId)(state),
        // postionIds: getOrgchartChangedUnitSettings(props.unitId)(state).postionIds.map((el => getOrgchartPositions(el)(state))),
        // unitData: getOrgchartChangedUnitSettings(props.unitId)(state),


    };

};

export type IEditPositionsDispatchProps = ReturnType<typeof mapEditPositionsDispatchToProps>

export const mapEditPositionsDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    changePositionDataOrgchart,
    addNewPositions,
    saveEditNewPositions,
    saveLeaderEditNewPositions,
    addNewLeaderPositions,
    newPositionDataKill
}, dispatch)


export const cnEditPositions = cn('EditPositions');

