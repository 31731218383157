import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    getUserscoursesinfo,
    pathFullStatisticSetting
} from '../../../../redux/actions/LMSTeacherOffice'
import { ILoadingProps } from 'utils/src/CommonRedux/LoadIndicator'
import * as SEL from '../../../../redux/sagas/LMSTO/selectors'
import { getUserProfileAdditionlFields } from 'utils/src/CommonRedux/base/selectors'

///////////
export declare namespace StatisticsFull {
    export namespace UsersList {
        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps, ILoadingProps {

        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////

type IOwnProps = StatisticsFull.UsersList.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    settings: SEL.getFullStatisticSettings(state),
    fields: getUserProfileAdditionlFields(state) as any[],
    ...(SEL.getUserscoursesinfolistParams<any, any>('keys', 'isFinished', 'count')(state) as { keys: string[], isFinished: boolean, count: number })
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    getUserscoursesinfo,
    pathFullStatisticSetting
}, dispatch);

////////////

export const cnClassName = cn('LMSTOStatUsersList');