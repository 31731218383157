import React, { FC, useEffect, useMemo, useState, useCallback } from 'react'
import {
    WidgetArticlesBox,
    TypographyArticles,
    StyledTabs,
    TypographyDateArticles,
    BaseListItemBox,
    WidgetButtonBox
} from './WikiArticlesWidget.styled'
import { connect } from 'react-redux'
import { SettingsArticlesWidget, cnSettingsArticles } from './WikiArticlesWidget.index'
import {
    initWidgetSettings,
    initWidgetAddition,

} from './SettingsDialog/SettingsDialog'
import { Button } from 'muicomponents/src/Button/Button'
import { Translate } from 'localization'
import { IRequest } from 'utils/src/requests/service';
import { list } from 'utils/src/requests/requests.tags';
import { checkResponseStatus } from 'utils/src';
import { Box, Typography, Divider } from 'muicomponents/src'
import { getWikiPagesNew, getWikiPagesActualList } from 'utils/src/requests/requests.wikilist';
import { Link } from 'muicomponents/src/Link/Link';
import MDRender from 'muicomponents/src/CommonmarkRender';
import { TPage } from 'utils/src/BaseTypes/pages.types';
import * as utils from 'utils/src/utils';
import { useParams, useHistory } from 'react-router';
import noData from 'assets/svg/NoData.svg'
import emptyArticlesList from 'assets/png/emptyArticlesList.png'
import { Avatar } from 'muicomponents/src/Avatar';
import CircularProgress from '@mui/material/CircularProgress'
import CardMedia from '@mui/material/CardMedia';
import { formatDateNumbers } from 'utils/src/utils.dates'


// export function BodyComponent<DT extends IBaseDataType = any>({ children }: React.PropsWithChildren<IItemsListBodyProps<DT>>) {
//     return <Box sx={{ maxHeight: "350px", overflowX: "hidden", overflowY: "auto" }} className={cnSettingsArticles("")}>
//         {/* <CustomScrollbars style={{ width: "100%", height: "100%", borderRadius: "8px" }}> */}
//         {children}
//         {/* </CustomScrollbars> */}
//     </Box >

// }


export interface IItemProps {
    item: TPage
}
export const WikiListItem: FC<IItemProps> = ({ item }) => {

    return <Box className={cnSettingsArticles("ArticleItem")} sx={{
        marginBottom: "12px",
        "&:last-child": {
            marginBottom: 0
        }
    }} >

        <TypographyDateArticles>{formatDateNumbers(item.changeDate || item.creationDate, false, false)}</TypographyDateArticles>
        <Typography variant="subtitle2" fontWeight={"medium"}>
            <Link href={item.url} sx={{ textDecoration: 'none', fontSize: "14px" }}>
                {(item.title && <MDRender source={item.title} type={'inline'} countLineCut={2} />) || <Translate i18nKey={'pryaniky.wikilist.item.emptyName'} />}
            </Link>
        </Typography>

        <Box >
            <Typography variant='caption' sx={{ color: "rgba(0, 0, 0, 0.87)", minHeight: "40px" }}>
                <MDRender countLineCut={3} source={item.description || ''} type={'inline'} />
            </Typography>
        </Box>
    </Box>
}



initWidgetSettings();
initWidgetAddition()



const tabAll = { label: Translate.t({ i18nKey: 'pryaniky.widgets.article.tab.all' }), id: 'all' };
// const tabArchive = { label: Translate.t({ i18nKey: 'pryaniky.widgets.article.tab.archive' }), id: 'archive' }

let request: IRequest;

export const WikiArticlesPreseter: FC<SettingsArticlesWidget.Props> = ({ settings, isEdit }) => {

    if (!settings?.collection && isEdit) return <Box sx={{ padding: "24px" }}>
        <TypographyArticles>
            <Translate i18nKey='pryaniky.widgets.article.no.settings' />
        </TypographyArticles>
    </Box>
    if (!settings?.collection && !isEdit) {
        return null
    }
    const [tab, setTab] = useState("all")
    const [loading, setLoading] = useState(true)
    const [isFinished, setIsFinished] = useState(true)
    const [rubrics, setRubrics] = useState<any[]>([])
    const [articlesList, setArticlestList] = useState<any[] | undefined>(undefined)

    const history = useHistory();

    let tabs = useMemo(() => {
        if (rubrics && rubrics?.length > 0) {
            const artclesTabs = [tabAll, ...rubrics]?.map((el: any) => ({
                label: (el?.displayName || el?.label)?.replaceAll("_", " "),
                id: el?.id,

            }))
            return artclesTabs;
        } else {
            return [
                tabAll
            ]
        }
    }, [rubrics])

    const getCategories = useCallback((url: string,) => {
        // if (request) request.ac.abort();
        request = list(0, 20, { url });
        return request.r.then((d: any) => {
            if (checkResponseStatus(d)) {
                return setRubrics(d.data);
            } else {
                if (d.error_code === 404 || d.dom_error === 'AbortError') throw d;
            }
        });
    }, [])

    useEffect(() => {
        if (settings && settings.collection.alias) {
            getCategories(settings.collection.alias)
        }
    }, [])

    useEffect(() => {
        if (settings && settings.collection.alias) {
            getActualContent(settings.collection.alias, settings.count)
        }
    }, [tab])

    const getActualContent = useCallback((url: string, countItems: number) => {
        if (!settings?.collection?.alias) return;
        const FilterCat = (tab === "all") ? undefined : tab;

        request = getWikiPagesActualList({
            skipCount: 0,
            count: countItems,
            tagUids: FilterCat ? [FilterCat] : undefined,
            // archived: tab === "archive",
            alias: url,
             order: settings.sort || "changedate",
            sort: "desc"
        });
        return request.r.then((d: any) => {
            if (checkResponseStatus(d)) {
                setIsFinished(d.isFinished);
                setLoading(false)
                return setArticlestList(d.data);
            } else {
                setLoading(false)
                if (d.error_code === 404 || d.dom_error === 'AbortError') throw { d };
            }
        });
    }, [tab])

    const Content = useMemo(() => {
        return <Box sx={{ minHeight: "286px", height: "350px", maxHeight: "350px", overflowX: "hidden", overflowY: "auto" }} className={cnSettingsArticles("")}>
            {loading ?
                <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                    <CircularProgress />
                </Box> :
                articlesList?.map((el) => <WikiListItem item={el} />)}


        </Box >
    }, [articlesList])

    const linkToFolder = settings?.collection?.alias === "dash" ? "wikilist" : `wikilist/${settings?.collection?.alias}`

    if (settings?.hideIfNoData && articlesList?.length === 0 && tab === "all") return null
    if (!settings?.hideIfNoData && articlesList?.length === 0) return <WidgetArticlesBox sx={{ height: "466px", }}>
        <WidgetButtonBox
            sx={{ padding: "24px" }}
            className={cnSettingsArticles("Header-WidgetButtonBox-EmptyList")}>
            <Link href={`${window.location.origin}/${linkToFolder}`}>
                <TypographyArticles minHeight={32}>
                    <MDRender source={settings?.name} type={'inline'} countLineCut={1} />
                </TypographyArticles>

            </Link>
            <Link
                className={cnSettingsArticles("Header-ButtonToAll")}
                href={`${window.location.origin}/${linkToFolder}`}
                sx={{ textTransform: "uppercase" }}>
                <Translate i18nKey={'pryaniky.widgets.settings.type.allMaterials'} />
            </Link>
        </WidgetButtonBox>
        {!loading &&
            <StyledTabs
                tabs={tabs}
                value={tab}
                variant="scrollable"
                scrollButtons
                onChange={(event: React.SyntheticEvent<Element, Event>, tabName: string) => setTab(tabName)}
            />}
        <Divider sx={{ marginBottom: "8px" }} />
        {!loading && <Box
            className={cnSettingsArticles("")}
            sx={{

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "350px",
                maxHeight: "350px",
                overflowX: "hidden",
                overflowY: "auto",
                marginTop: "16px"
            }}>
            
            <CardMedia
                component="img"
                className={cnSettingsArticles("EmptyList-Avatar")}
                sx={{
                    width: 'auto',
                    height: "270px",
                    marginBottom: "16px"
                }}
                image={emptyArticlesList}
            />
            <Typography>
                <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
            </Typography>
        </Box>}
    </WidgetArticlesBox>


    return (
        <WidgetArticlesBox sx={{ height: "466px" }}>
            <WidgetButtonBox className={cnSettingsArticles("Header-WidgetButtonBox")}>
                <Link href={`${window.location.origin}/${linkToFolder}`}>
                    <TypographyArticles>
                        <MDRender source={settings?.name} type={'inline'} countLineCut={1} />
                    </TypographyArticles>

                </Link>
                <Link
                    className={cnSettingsArticles("Header-ButtonToAll")}
                    href={`${window.location.origin}/${linkToFolder}`}
                    sx={{ textTransform: "uppercase" }}>
                    <Translate i18nKey={'pryaniky.widgets.settings.type.allMaterials'} />
                </Link>
            </WidgetButtonBox>
            {!loading &&
                <StyledTabs
                    tabs={tabs}
                    value={tab}
                    variant="scrollable"
                    scrollButtons
                    onChange={(event: React.SyntheticEvent<Element, Event>, tabName: string) => setTab(tabName)}
                />}
            <Divider sx={{ marginBottom: "8px" }} />

            {Content}


            {/* {(!isFinished && settings?.collection.alias && !loading) && <Button
                variant="outlined"
                sx={{
                    width: "fit-content",
                    alignSelf: "end",
                    marginTop: "12px"
                }}
                onClick={() => history.push(`/wikilist/${settings?.collection.alias}`)}>
                <Translate i18nKey='pryaniky.widgets.article.goToAll' />
            </Button>} */}
        </WidgetArticlesBox>
    )
}

