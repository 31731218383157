import React, { FC, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import queryString from 'query-string';
import { StudentResults as Component } from './StudentResults'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { loadModerationAnswers, loadStudentSessionsList, loadStudentResult } from '../../redux/actions/LMSTeacherOffice'

export interface IStudentResultsProps extends IStudentResultsStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
}

export interface IStudentResultsState {

}

export interface IStudentResultsStateProps {

}

export const mapStateToProps = (state: any, props: any) => {
    const { sid, uid, unit } = queryString.parse(props.location.search)
    if (!state.LMSTO.courses.values[props.cid].studentesResults) return {
        results: {},
        resultsIds: [],
        noData: true,
        user: {}
    } 
    else if (!state.LMSTO.courses.values[props.cid].studentesResults[(uid as string)]) return {
        results: {},
        resultsIds: [],
        noData: true,
        user: {}
    }
    return ({
        noData: false
    })
}


export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadStudentResult, loadModerationAnswers, loadStudentSessionsList
}, dispatch);


export const StudentResultsDecorator: FC<any> = ({ className, cid, loadStudentResult, location, noData, user, resultsIds, results }) => {
    const { sid, uid, unit } = queryString.parse(location.search)
    useEffect(() => {
        loadStudentResult(cid, uid, sid)
    }, [cid, sid, uid])

    if (!noData) {
        
        return <Component {...{ cid, sid, uid, className, user, resultsIds, results, unit}}/>
    }
    else return <div>{tT('student_results.load.1')}</div>
}

export const StudentResults = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(StudentResultsDecorator));
