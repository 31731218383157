
export const lenMaxThankCount = (usersCount: number, maxThanksValue: number, thanksForAll: number) => {
    return (usersCount * maxThanksValue) > thanksForAll ? thanksForAll : (usersCount * maxThanksValue);
}

export const lenMaxPerUser = (usersCount: number, maxThanksValue: number, thanksForAll: number) => {
    const maxThanks = lenMaxThankCount(usersCount, maxThanksValue, thanksForAll)
    return Math.ceil(usersCount === 0 ? maxThanksValue : Math.min(maxThanksValue, maxThanks / usersCount));
}

export const lenFullCount = (usersCount: number, thanksCount: number) => {
    return usersCount * thanksCount
}

export const lenAvailableCurrency = (usersCount: number, fullCurrency: number, maxThanks: number, fullCount: number) => {
    return usersCount === 0 ? fullCurrency : maxThanks - fullCount;
}

export const isDisableUp = (usersCount: number, thankPerUser: number, maxPerUser: number, maxThanks: number, fullCount: number) => {
    return (thankPerUser >= maxPerUser) || ((maxThanks - fullCount) < usersCount)
}

export const isDisableDown = (thankPerUser: number, minPerUser: number) => {
    return thankPerUser <= minPerUser
}

export const lenMaxAllowUsers = (thanksCount: number, fullCurrency: number) => {
    return Math.floor(fullCurrency / thanksCount)
}


export const lenValues = (
    usersCount: number,
    maxThanksValue: number,
    fullCurrency: number,
    thanksCount: number
) => {
    const minPerUser = 1
    const maxThanks = lenMaxThankCount(usersCount, maxThanksValue, fullCurrency)
    const maxPerUser = lenMaxPerUser(usersCount, maxThanksValue, fullCurrency)
    const fullCount = lenFullCount(usersCount, thanksCount);
    const available = lenAvailableCurrency(usersCount, fullCurrency, maxThanks, fullCount)
    const disableUp = isDisableUp(usersCount, thanksCount, maxPerUser, maxThanks, fullCount)
    const disableDown = isDisableDown(thanksCount, minPerUser);
    return {
        maxThanks,
        maxPerUser,
        fullCount,
        available,
        disableUp,
        disableDown
    }
}