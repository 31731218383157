import React, { FC, memo } from 'react'
import {
    cnClassName,
    KPI
} from './interfaces'
import './style.scss'

type IPresenterProps = KPI.TableRow.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    col1,
    col2,
    col3,
    col4,
    col5
}) => {
    return <tr className={cnClassName({}, [className])}>
        <td>{col1}</td>
        <td>{col2}</td>
        <td>{col3}</td>
        <td>{col4}</td>
        <td>{col5}</td>
    </tr>
}

export default memo(Presenter);
