import { cn } from '@bem-react/classname';
// import { AnyAction, bindActionCreators, Dispatch } from 'redux';
// import { IStateType } from '../../../redux/store';
// import { getCreatorNewsParams, getCreatorParams } from '../../redux/saga/selectors';
// import { pathCreatorNews } from '../../redux/actions';
// import { NBadges } from './Badges.interface';
// import { NewsCreatorContext } from '../Creator/Creator.inteface';
// import { getAllowPostTypeById } from 'redux/sagas/AppSettings/selectors';

export const cnNewsTypeBadges = cn('NewsTypeBadges');

// export namespace NNewsTypeBadges {

//     export interface Props extends OwnProps, ReturnType<typeof mapNewsTypeBadgesStateToProps>, ReturnType<typeof mapNewsTypeBadgesActionsToProps> {

//     };

//     export interface OwnProps extends NewsCreatorContext {

//     };
// };

// export const mapNewsTypeBadgesStateToProps = (state: IStateType, props: NNewsTypeBadges.OwnProps) => {
//     const { currentType, errors, clearEditor } = getCreatorParams<NBadges.Create>('currentType', 'errors', 'clearEditor')(state);
//     return {
//         data: getCreatorNewsParams<NBadges.Create>('text', 'users', 'badge')(state),
//         badgesParams: getAllowPostTypeById(currentType || '', props.groupId, props.workflowId)(state)?.renderParams as NBadges.RenderParams | undefined,
//         errors,
//         clearEditor
//     }
// };

// export const mapNewsTypeBadgesActionsToProps = (d: Dispatch<AnyAction>) => bindActionCreators({
//     pathCreatorNews
// }, d);