import { Tabs } from 'muicomponents/src/Tabs';
import { styled } from 'muicomponents/src/mui/system';
import { ComponentProps } from 'react';

export const TabsBlock = styled('div')({
    padding: 16,
    borderRadius: 4,
    border: '1px solid #d3d9e0',
    background: '#fff',
});

export const TabsWrapper = styled('div')({
    display: 'flex',
    borderRadius: 4
});

export const TabWrapper = styled('div')({
    marginTop: 24
});

export const StyledTabs = styled(Tabs)({
    background: "#0066CC",
    borderRadius: "4px",
    color: "#fff",
    position: 'relative',
    [`& .MuiTabs-scrollButtons`]: {
        position: 'absolute',
        top: 0,
        height: '100%',
        background: 'inherit',
        zIndex: 1,
        "&.Mui-disabled": {
            display: 'none'
        },
        "&:first-child": {
            left: 0
        },
        "&:last-child": {
            right: 0
        }
    },
    [`& .MuiTabs-scroller`]: {
        borderLeft: "1px solid #0000004D",
        borderRight: "1px solid #0000004D"
    },
    '& .MuiTabs-indicator': {
        display: 'none'
    }
}) as typeof Tabs;

export const TabPropsSX: NonNullable<ComponentProps<typeof Tabs>['TabProps']>['sx'] = {
    color: "#fff",
    [`&:nth-child(n + 2)`]: {
        boxSizing: 'border-box',
        borderLeft: "1px solid #0000004D"
    },
    [`&.Mui-selected`]: {
        color: "#fff",
        background: "#004E9C"
    }
};