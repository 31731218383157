import { toast } from 'react-toastify';
import { store } from 'redux/store';
import actionsModals from 'redux/actionsTypes/Modals';
import actionsStore from 'utils/src/CommonRedux/base/actionsTypes';

declare global {
  interface Window {
    pryaniky_integrated: boolean;
    pryanikyApi?: any;
    PryanikyFunctions: any;
    PryanikySetBaseStore?: any;
    PryanikySetModalData?: any;
  }
}

window.pryaniky_integrated = window.self !== window.top;

export default function() {
  const root = window;
  root.PryanikyFunctions = {
    showToast: (msg: {condition: string, text: string}) => {
      if (msg) (toast as any)[msg.condition](msg.text);
    },
    changeUserThanksCount: (count: number) => {
      store.dispatch({
        type: actionsStore.CHANGE_STATE, payload: { propPath: 'authUser.extData.mythanksCount', value: count } });
    }
  }
}

window.PryanikySetModalData = (id: string, data: any) => {
  store.dispatch({
    type: actionsModals.CHANGE_DATA,
    payload: {
      id: id,
      data
    }
  });
}

window.PryanikySetBaseStore = (data: any) => {
  store.dispatch({ 
    type: actionsStore.CHANGE_STATE, 
    payload: { 
      propPath: '', 
      value: { 
        ...data, 
        loading: false, 
        auth: true 
      } 
    } 
  });
}