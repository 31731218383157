import React, { FC, useEffect, useState } from 'react'
import { cnApprove, mapDispatchToProps, mapStateToProps } from './Approve.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Approve.scss'
import { Button } from 'uielements/src';
import { tT } from 'LMSModule/utils/i18n.adaptor'

export const ApprovePresenter: FC<any> = ({ className, status, aid, sendApproveModeration }) => {

    return <Button
            className={cnApprove({ status: status !== 'Approved' ? 'n' : 'y' })}
            theme={'unstyled_center'}
            onClick={() => sendApproveModeration(aid, true, 'Approved')}>
        {tT('answer.approve.1')}
        </Button>
}

export const Approve = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ApprovePresenter));
