import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IDialogBodyPresenterProps = IDialogBodyOwnProps

export interface IDialogBodyState { }

export interface IDialogBodyOwnProps extends IClassNameProps {
    id?: string;
    children?: React.ReactNode;
    header?: React.ReactNode;
    onClose?: () => void;
    onAccept?: () => void;
    disableAccept?: boolean;
}

export const cnClassName = cn('ModalDialogBody');