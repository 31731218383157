import { Translate } from "localizations/Translate";
import { Box, styled } from "muicomponents/src";
import { useResizeProvider } from "muicomponents/src/ContainerResizeWatcher/ContainerResizeWatcher";
import { InfinityList } from "muicomponents/src/InfinityList";
import { defaultMiddleware, useDefaultStaticStates } from "muicomponents/src/ItemsListDialog/defaultListState";
import { ItemsListBody } from "muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody";
import { ItemsList } from "muicomponents/src/ItemsListDialog/ItemsListDialog";
import { UsersListItem } from "muicomponents/src/ItemsListDialog/UserListItem/UserListItem";
import { SearchInput } from "muicomponents/src/SearchInput/SearchInput";
import { UserListItem } from "muicomponents/src/UsersListDialog/UserListItem/UserListItem";
import React, { FC, useRef } from "react";
import { useSelector } from "react-redux";
import { generatePath, useParams } from "react-router";
import urls from "routes/urls";
import { getCurrentUser } from "utils/src/CommonRedux/base/selectors";
import { useScrollListState } from "utils/src/hooks";
import { useVacancyData } from "../../Vacancy.hooks";
import { VacancyProps } from "../../Vacancy.index";
import { useSuggestedVacancyUserList } from "./VacancySuggestedUsers.hooks";
import { VacancySuggestedUsersListItem } from "./VacancySuggestedUsersListItem";

const UsersListItemStyled = styled(UsersListItem)({
    padding: 0,
    background: 'transparent',
    border: 0,
    borderRadius: 0,
    boxShadow: 'none'
}) as typeof UsersListItem;

export const VacancySuggestedUsers: FC<VacancyProps> = ({
    id: paramId
}) => {

    const currentUser = useSelector(getCurrentUser);
    
    let {
        id
    } = useParams<{id: string}>();

    if(paramId) id = paramId;
    
    const {
        list,
        getList,
        error,
        finished,
        loading,
        search,
        changeSearch
    } = useSuggestedVacancyUserList(id);

    return (
        <>
            <InfinityList
                header={
                    <>
                        {/* <SearchInput
                            label={<Translate i18nKey='search' />}
                            value={search}
                            onChange={(e) => changeSearch(e.target.value)}
                            size={'small'}
                            loading={loading}
                        /> */}
                    </>
                }
                isFinished={finished}
                isLoading={loading}
                isError={error}
                itemsCount={list.length}
                loadMore={getList}
                textEmpty={<Translate i18nKey={`pryaniky.vacancy.suggested${!!search ? '.filtered' : ''}.empty`} />}
            >
                {
                    list.map(requestId => {
                        return (
                            <VacancySuggestedUsersListItem id={requestId} vacancyId={id} />
                        );
                    })
                }
            </InfinityList>
        </>
    );
};