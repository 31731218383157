import {
    objectArrayToMap
} from 'utils/src/utils'

import { mdToRaw, rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { NodeBulder } from 'uielements/src/CommonmarkRender'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { omit } from 'lodash';


/**
 * преобразует вики юнит для отправки на сервер
 * @param unit 
 */
export const buildSourcesWikiUnit = (unit: any) => {
    const draftRaw = convertToRaw(unit.draft.getCurrentContent())
    //конвертируем рав в мапкдаун
    const markdownText = draftToMarkdown(draftRaw, rawToMd);
    // обновляем юнит, удаляем стейт драфта
    return {
        ...unit, wiki: {
            ...unit.wiki, markdownText, data: { ...unit.wiki.data, ...draftRaw, markdownText }
        },
        draft: undefined
    }

}

/**
 * 
 * @param unit объект юнита 
 * @param answers коллекция ответов
 * @param questions коллекция вопросов
 */
export const buildTreedTestUnit = (unit: any, answers: any, questions: any) => {
    const quesionsArray = unit.questions.map((qid: string) => {
        const answersArray = questions[qid].answers.map((aid: string) => {
            const draftRaw = convertToRaw(answers[aid].draftState.getCurrentContent())
            //конвертируем рав в мапкдаун
            const text = draftToMarkdown(draftRaw, rawToMd);
            return omit({
                ...answers[aid],
                text
            }, 'draftState')
        })
        const draftRaw = convertToRaw(questions[qid].draftState.getCurrentContent())
        //конвертируем рав в мапкдаун
        const markdownText = draftToMarkdown(draftRaw, rawToMd);
        return { ...questions[qid], text: markdownText, answers: answersArray, draftState: undefined }
    })
    return { ...unit, questions: quesionsArray }
}


/**
 * НУЖНО ПРОВЕРИТЬ!
 * преобразует древовидный юнит типа тест в плоскую структуру
 * @param sourcesUnit исходный, древовидный юнитв
 */
export const buildFlatTestUnit = (testUnit: any) => {
    const unit = { ...testUnit }
    let answers: { [key: string]: any } = {}
    const questionMap = objectArrayToMap(testUnit.questions, 'id')
    unit.questions = Object.keys(questionMap)
    unit.questions.forEach((qid: string) => {
        const ans = questionMap[qid].answers.map((v: any) => {
            const nb = new NodeBulder(v.text)
            return {
                ...v,
                draftState: EditorState.createWithContent(convertFromRaw(nb.getDraftRaw()))
            }
        })
        const answerMap = objectArrayToMap(ans, 'id')
        answers = {
            ...answers,
            ...answerMap
        }
        const nb = new NodeBulder(questionMap[qid].text)
        questionMap[qid].draftState = EditorState.createWithContent(convertFromRaw(nb.getDraftRaw()))

        questionMap[qid].answers = Object.keys(answerMap)
    });

    return {
        unit, questions: questionMap, answers
    }
}


/**
 * проверяет вопрос на коректность установки флагов верности ответов.
 * @return boolean true вслучае если всё верно
 * @param question массив с вопросами
 * @param answers коллекция ответов
 */
export const checkQuestionVariants = (question: any, answers: any) => {
    const rightCount = question.answers.reduce((accumulator: any, aid: any) => {
        return accumulator + (answers[aid].isRightAnswer ? 1 : 0)
    }, 0)
    if (question.qtype === 0) {
        return rightCount === 1
    } else if (question.qtype === 1) {
        return rightCount >= 1
    }
    return true;
}

/**
 * проверяет массив вопросов на корректность установки вариантов ответов.
 * в случае если все вопросы корректны, то возврощает пустой массив.
 * иначе массив с id вопросов имеющих ошибки
 * @param questions массив вопросов
 * @param answers коллекция ответов
 */
export const checkQuestionsAnswers = (questions: any[], answers: any) => {
    const wrong = questions.filter((question: any) => !checkQuestionVariants(question, answers)).map((q: any) => q.id)
    return wrong
}