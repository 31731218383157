import { ICurrencyTimelineActions, IGetCurrencyTimelinePayload, ISetCurrencyTimelineLoadingFinishedPayload } from './actions.interfaces';
import { ICurrencyTimelineContext } from './interfaces';

export const RESET_DATA_CURRENCY_TIMELINE = 'RESET_DATA_CURRENCY_TIMELINE';
export const GET_CURRENCY_TIMELINE = 'GET_CURRENCY_TIMELINE';
export const SET_CURRENCY_TIMELINE = 'SET_CURRENCY_TIMELINE';
export const SET_CURRENCY_TIMELINE_LOADING_FINISHED = 'SET_CURRENCY_TIMELINE_LOADING_FINISHED';
export const CHANGE_CURRENCY_TIMELINE_CONTEXT = 'CHANGE_CURRENCY_TIMELINE_CONTEXT';


export const resetDataCurrecyTimelline = (): ICurrencyTimelineActions => ({
  type: RESET_DATA_CURRENCY_TIMELINE
})

export const getCurrencyTimeline = (payload: IGetCurrencyTimelinePayload): ICurrencyTimelineActions => ({
  type: GET_CURRENCY_TIMELINE,
  payload
})

export const setCurrencyTimeline = (payload: any): ICurrencyTimelineActions => ({
  type: SET_CURRENCY_TIMELINE,
  payload
})

export const setCurrencyTimelineLoadingFinished = (payload: ISetCurrencyTimelineLoadingFinishedPayload): ICurrencyTimelineActions => ({
  type: SET_CURRENCY_TIMELINE_LOADING_FINISHED,
  payload
})

export const changeCurrencyTimelineContext = (payload: Partial<ICurrencyTimelineContext>): ICurrencyTimelineActions => ({
  type: CHANGE_CURRENCY_TIMELINE_CONTEXT,
  payload
})