import { mapDispatchToProps, IDispatchProps } from '../../../../redux/connector';
import { cn } from '@bem-react/classname';
import { IDialog } from '../../DialogWrapper.index';

export interface IGroupUsersDialogProps extends IGroupUsersDialogOwnProps { }

export interface IGroupUsersDialogOwnProps extends IDialog { }

export interface IGroupUsersDialogState {
  creating: boolean;
  name: string;
  description: string;
  isHidden: boolean;
  isClosed: boolean;
  img: any;
  parentGroup: any;
  [x: string]: any;
}

// export type ICreateGroupDialogStateProps = ReturnType<typeof mapStateToProps>;

// export const mapStateToProps = (state: any, props: ICreateGroupDialogOwnProps) => {
//   return {
//     data: props.id && state.modals[props.id] && state.modals[props.id].data || {},
//   }
// };

export const cnGroupUsersDialog = cn('GroupUsersDialog');