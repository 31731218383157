import React, { FC, useEffect, memo, useCallback, ComponentProps, useMemo, useState } from 'react'
import {
    cnClassName,
    cnClassNameNoData,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withRouter } from 'react-router-dom'
import NewsItem from '../NewsItem'
import HeadView from 'News/containers/HeadView';
import { useTimelineLoader } from 'News/utils/hooks'
import TimelineHeader from '../../components/TimelineHeader'
import { MiniTimelineList } from '../../components/MiniTimelineList/MiniTimelineList'
import { Skeleton } from 'uielements/src';
import noDataImage from 'assets/svg/NoData.svg'
import { Avatar } from 'uielements/src/Avatar/Avatar';
import i18n from 'localizations/i18n';
import { useWidgetContext } from 'Widgets_v2/context';

type IPresenterProps = News.HeadList.IPresenterProps
type IOwnProps = News.HeadList.IOwnProps
type IDispatchProps = News.HeadList.IDispatchProps
type IStateProps = News.HeadList.IStateProps
type IState = News.HeadList.IState

export const viewTypesExluded = ['full', 'short', 'head'] as const;
const defaultCount = 5

export const PostSkeleton = memo(() => (
    <div className="PostSkeleton" style={{ borderRadius: 0, padding: '3px 24px', marginBottom: 0, border: 'none', borderBottom: '1px solid #d3d9e0' }}>
        <div className="PostSkeleton-Header">
            <Skeleton size={'sm'} shape="circle" />
            <div style={{ flexGrow: 1, marginLeft: 12 }}>
                <Skeleton shape="line" style={{ width: '50%' }} />
                <Skeleton size="xs" shape="line" style={{ width: '70%' }} />
            </div>
        </div>
    </div>
));

export const Presenter: FC<IPresenterProps> = ({
    className,
    count,
    isFinished,
    keys,
    params = { newsTypes: 'all' },
    viewType,
    sending,
    settings,
    // active,
    setActiveNews,
    context,
    hideNews,
    header
}) => {
    // return null


    if (settings?.viewType) viewType = settings?.viewType;

    const { sendWidgetSignal } = useWidgetContext();

    // const activeIndex = keys.indexOf(active)

    const [page, setPage] = useState(0)
    const tmpp = params.count || defaultCount
    const perPage = tmpp < 0 ? defaultCount : tmpp
    const next = page + perPage
    const prev = page - perPage
    const disablePrev = prev < 0
    let disableNext = isFinished && next >= keys.length
    const pagingLabel = `${page + 1}-${next - (disableNext ? next % keys.length : 0)}`
    const tmpn = (perPage - (keys.length - next))
    const needLoadAtNext = tmpn < 0 ? perPage : tmpn

    const { isFailed, isLoading, loadMore } = useTimelineLoader(params, keys, needLoadAtNext)//params.count)
    disableNext = isFailed || disableNext
    let disableNavigation = isFinished && keys.length === 0
    disableNavigation = isLoading && keys.length === 0 || disableNavigation

    const keysAtPage = keys.slice(page, page + perPage)

    // useEffect(() => {
    //     if (activeIndex === -1) return;
    //     const p = Math.ceil((activeIndex + 1) / count) - 1
    //     setPage(p * count)
    // }, [activeIndex])

    const nextPage = () => {
        if (disableNext) return;
        setPage(next)
    }
    const prevPage = () => {
        if (disablePrev) return;
        setPage(prev)
    }
    useEffect(() => {
        if (keys.length !== 0 && ((page + needLoadAtNext) >= keys.length) && !isFinished) !isFailed && loadMore()
    }, [page, keys.length, isLoading, isFinished])

    useEffect(() => {
        sendWidgetSignal('dataUpdate');
    }, [keys.length]);

    const maxViewValue = useMemo(() => {
        const max = page + (params.count || 0);
        return max > keys.length ? keys.length : max;
    }, [page, params.count, keys.length]);
    if (!context?.hideIfNoData && keys.length === 0) return <div className={cnClassNameNoData({}, [className])}>
        <div className={cnClassNameNoData('Header-Title')} children={header} />
        <div className={cnClassNameNoData('Header-noData')}> <Avatar precentage={100} shape={'rectangle'}
            imgUrl={noDataImage}
        />
        </div>
        <span className={cnClassNameNoData('Header-Title-Text')}> {i18n.t('pryaniky.widgets.settings.type.birthdays.noData.text')} </span>
    </div>;

    if (context?.hideIfNoData && hideNews && keys.length === 0) return <div className={cnClassName({}, [className])}></div>
    if (context?.hideIfNoData && !hideNews && keys.length === 0) return null

    return <div className={cnClassName({}, [className])}>
        <TimelineHeader
            className={cnClassName('Header')}
            disablePrev={disablePrev}
            disableNext={disableNext}
            next={nextPage}
            previous={prevPage}
            value={<>{page + 1}-{maxViewValue}</>}
            isLoading={isLoading}
            disableNavigation={disableNavigation}
            totalCount={keys.length}
        >
            {header}
        </TimelineHeader>
        <MiniTimelineList
            isFinished={isFinished}
            isLoading={isLoading}
            isError={isFailed}
            loadMore={loadMore}
            itemsCount={keys.length}
            loadNode={<div style={{ paddingBottom: '8px' }}>{(new Array(perPage)).fill(1).map((v, i) => <PostSkeleton key={i} />)}</div>}
        >
            {
                keysAtPage.map(id =>
                    <NewsItem
                        className={cnClassName('Item')}
                        key={id}
                        id={id}
                        ViewComponent={HeadView}
                    />
                )
            }
        </MiniTimelineList>

    </div>;
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));