import { IClassNameProps } from "@bem-react/core";
import { cn } from "@bem-react/classname";

export interface IImageLoaderPreviewProps extends IClassNameProps {
  previewUrl?: string;
  square?: boolean;
}

export interface IImageLoaderPreviewState {

}

export const cnImageLoaderPreview = cn('ImageLoaderPreviewMui');