import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from '../../redux/connector';
import { IContext } from '../../store/vm';

export interface IGroupsProps extends IClassNameProps, IGroupsStateProps, IDispatchProps {
  tag?: 'div';
}

export interface IGroupsState {
  text?: string;
}

export interface IGroupsStateProps {
  store: any;
  context: any;
}

export const mapStateToProps = (state: any) => ({
  store: state.store,
  context: state.widgets.contexts,
})

export const cnGroups = cn('Groups');