import React from 'react';
import { cnAdditionalMenu, IAdditionalMenuProps, AdditionalMenuActionsToProps, AdditionalMenuStateToProps } from './AdditionalMenu.index';
import './AdditionalMenu.scss';
import { connect } from 'react-redux';
import { IAdditionalMenuItem } from 'utils/src/CommonRedux/base/reducer';
import { Button, Icon } from 'uielements/src';

const topButton: IAdditionalMenuItem = {
  id: 'topButton',
  onClick: () => window.scrollTo(0, 0),
  children: <Icon icon='chevron-up' />,
  additionalProps: {
    id: "TOPBUTTON"
  }
}


const AdditionalMenuPresenter: React.FC<IAdditionalMenuProps> = ({ data, addToAdditionalMenu, removeFromAdditionalMenu }) => {

  React.useEffect(() => {
    // init top button;
    // const layout = document.getElementById('Layout');
    const scroll = document.body;
    let issh = data.findIndex(d => d.id === topButton.id) >= 0;
    // if (!scroll) return;
    document.addEventListener('scroll', () => {
      if (window.scrollY > 200 && !issh) {
        addToAdditionalMenu({ item: topButton });
        issh = true;
      } else if (window.scrollY < 100 && issh) {
        removeFromAdditionalMenu({ id: topButton.id });
        issh = false;
      }
    })
  }, []);

  return (
    <div className={cnAdditionalMenu("Wrapper Layout-Inner")}>
      <div className={cnAdditionalMenu()}>
        {
          data.map(b => <Button key={b.id} className={cnAdditionalMenu('Item')} type={b.type} href={b.href} onClick={b.onClick} children={b.children} {...b.additionalProps} />)
        }
      </div>
    </div >
  )
}

export const AdditionalMenu = connect(
  AdditionalMenuStateToProps,
  AdditionalMenuActionsToProps
)(AdditionalMenuPresenter);