import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';

////////////

export interface IItem {
    name: string;
    value: number;
}

export type ICollection = {[key: string]: IItem};

///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

export interface IState {}

export interface IOwnProps extends IClassNameProps { 
    userId: string;
    summaryRender?: (summary: number) => JSX.Element;
    forSpend?: boolean;
}

export interface IStateProps {
    thanksForAll: number
    thanksForSubordinate?: number
    thanksForAllByCollection: ICollection
    wallet: number
 }

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    thanksForAll: state.users.users[props.userId].extData.thanksForAll,
    thanksForAllByCollection: state.users.users[props.userId].extData.thanksForAllByCollection,
    thanksForSubordinate: state.users.users[props.userId].extData.thanksForSubordinate,
    wallet: state.users.users[props.userId].extData.mythanksCount
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({}, dispatch);

////////////

export const cnCurrencyTooltip = cn('CurrencyTooltip');