import React, { FC, memo, useCallback, useEffect } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext } from '../../contexts/news'

import { Attachments } from 'uielements/src/Attachments/Attachments';
import { Icon, Button } from 'uielements/src';
import { Preview } from 'uielements/src/Preview/Preview';
import * as utils from 'utils/src/utils';
import { LinkLayout } from 'blocks/NewsTypes/common/LinkLayout/LinkLayout';
import { NewsEdit } from 'blocks/NewsEdit_new/NewsEdit'
import { IBaseNews } from '../../types/baseNews'
import NewsHeader from '../NewsHeader'
import NewsActions from '../NewsActions'
import NewsComments from '../NewsComments'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'

type IPresenterProps = News.DialogView.IPresenterProps
type IOwnProps = News.DialogView.IOwnProps
type IDispatchProps = News.DialogView.IDispatchProps
type IStateProps = News.DialogView.IStateProps
type IState = News.DialogView.IState

const noopFn = () => { }
const noopArray: any[] = []

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    edit,
    toggleEdit,
    attachments,
    id,
    linkPreviewImgUrl,
    systemNewsActionHtml,
    newstype,
    componentRenderName,
    handleClose,
    handleAccept,
    editOnly = false
}) => {
    useEffect(() => {
        if(editOnly) toggleEdit(true)
        return () => {
            if(editOnly) toggleEdit(false)
        }
    }, [])


    const cancleEdit = useCallback(() => {
        toggleEdit(false)
        editOnly && handleClose()
    }, [editOnly])

    const completeEdit = (data: IBaseNews) => {
        editOnly && handleAccept(data)
    }

    const editType = componentRenderName === "workflow" ? "workflow" : utils.newNewsType(newstype)

    const editState = editOnly || edit

    return <DialogContent className={cnClassName({}, [className])}>
        <div className={'NewsLayout'}>
            <NewsHeader

                actionsRender={
                    <Button onClick={handleClose} className={'HeaderLayout-ActionButton'}>
                        <Icon icon='times' />
                    </Button>
                }
            />

            {editState
                ? <NewsEdit cancleEdit={cancleEdit} compliteEdit={completeEdit} newsId={id} type={editType} />
                : <div className={cnClassName('Content')}>
                    {children}
                </div>
            }

            {!editState && <LinkLayout linkPreviewImgUrl={linkPreviewImgUrl} systemNewsActionHtml={systemNewsActionHtml} />}

            {!editState && <Preview className={cnClassName('Preview', ['horizontalPadding'])} newsid={id} files={attachments ? attachments : noopArray} />}

            {!editState && <Attachments className={cnClassName('Attachments', ['horizontalPadding'])} files={attachments ? attachments : noopArray} onDelete={noopFn} state='view' />}

            {!editOnly && <NewsActions />}
        </div>
        {!editOnly && <NewsComments />}
    </DialogContent>
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));
