import React, { useEffect, useRef } from 'react'
import { NavigateAction, View } from 'react-big-calendar'
import { connect } from 'react-redux'
import { IToolbarProps, cnCustomToolbar, mapDispatchToProps, mapStateToProps } from './CustomToolbar.index'
import i18n from 'localizations/i18n'
import './CustomToolbar.scss'
import { Translate } from 'localizations/Translate'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useSearchParams } from 'utils/src/hooks'
import { DatePicker } from 'muicomponents/src/DatePicker/DatePicker'
import Box from '@mui/material/Box'
import {  useDidUpdateEffect } from 'utils/src/hooks';
import { CalendarSearchParams } from '../Calendar.index'

const CustomToolbarPresenter: React.FC<IToolbarProps> = props => {

  const { date, view, label, onNavigate, onView, setCalendarCurrentDate, setCalendarCurrentView, currentDate, loading, disableSearchParams } = props
  const calendarData = {
    currentDate,
    view
  }
  const calendarDataRef = useRef(calendarData);
  calendarDataRef.current = calendarData;

  const navigate = (action: NavigateAction) => onNavigate(action)
  const cNavigate = (action: NavigateAction, date: Date) => onNavigate(action, date)
  const changeView = (view: View) => onView(view)
  const [searchParams, setSearchParams] = useSearchParams<CalendarSearchParams>();
  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  useEffect(() => {
    if (currentDate.slice(0, 10) !== date.toISOString().slice(0, 10)) {
      setCalendarCurrentDate(date.toISOString())
    }
  }, [label]);

  /**
   * эффект, который смотрит изменение состояния параметров урла и если они меняются, то устанавливает состояние библиотеки
   */
  useDidUpdateEffect(() => {
    // если используем параметры урла
    if(!disableSearchParams) {
      if(
        // если значения вообще есть
        searchParams.defaultDate && searchParams.defaultView
        && (
          // если значения отличаются от текущих в бибилиотеке
          searchParams.defaultDate !== calendarDataRef.current.currentDate
          || searchParams.defaultView !== calendarDataRef.current.view
        )
      ) {
        // устанавливаем через cNavigate, что бы нормально отработали все состояния библиотеки
        cNavigate('DATE', new Date(searchParams.defaultDate));
        changeView(searchParams.defaultView);
      }
    }
  }, [searchParams.defaultDate, searchParams.defaultView, disableSearchParams]);

  const isFirst = useRef(true);

  /**
   * эффект, который смторит изменение состояний бибилиотеки и если они меняются устанавливает состояние параметров урла
   */
  useEffect(() => {
    // если используем параметры урла
    if(!disableSearchParams) {
      // если первый рендер
      if(isFirst.current) {
        isFirst.current = false;
        // если параметры не установлены переходим с заменой истории
        if(!searchParams.defaultDate && !searchParams.defaultView) {
          setSearchParams({
            defaultDate: currentDate,
            defaultView: view
          }, "replace");
        }
      } else {
        // если рендер не первый и параметры отличаются то переходим БЕЗ замены истории
        if(currentDate !== searchParamsRef.current.defaultDate || view !== searchParamsRef.current.defaultView) {
          setSearchParams({
            defaultDate: currentDate,
            defaultView: view
          });
        }
      }
    }
  }, [currentDate, view, disableSearchParams]);  

  useEffect(() => {
    setCalendarCurrentView(view)
  }, [view])

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: View,
  ) => {
    changeView(newView);
  };

  const handleChangeNavigate = (
    event: React.MouseEvent<HTMLElement>,
    newNav: NavigateAction,
  ) => {
    navigate(newNav);
  };

  return (
    <Box className={`${cnCustomToolbar()} ${view}`} sx={{ marginBottom: '12px' }}>

      <ToggleButtonGroup
        color="primary"
        // value={view}
        disabled={loading}
        exclusive
        size='small'
        onChange={handleChangeNavigate}
        aria-label="Platform"
      >
        <ToggleButton value="PREV" ><Translate i18nKey={'pryaniky.calendar.toolbar.back'} /></ToggleButton>
        <ToggleButton value="TODAY" ><Translate i18nKey={'pryaniky.calendar.toolbar.today'} /></ToggleButton>
        <ToggleButton value="NEXT" ><Translate i18nKey={'pryaniky.calendar.toolbar.forward'} /></ToggleButton>
      </ToggleButtonGroup>

      <DatePicker
        InputProps={{
          size: 'small'
        }}
        // size='small'
        label={''}
        disabled={loading}
        className={cnCustomToolbar('DateInput')}
        type={'date'}
        value={new Date(date)}
        onChange={(str, obj) => obj && cNavigate('DATE', new Date(obj))}
      />

      <ToggleButtonGroup
        size='small'
        color="primary"
        value={view}
        disabled={loading}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton value="day"><Translate i18nKey={'pryaniky.calendar.toolbar.day'} /></ToggleButton>
        <ToggleButton value="week"><Translate i18nKey={'pryaniky.calendar.toolbar.week'} /></ToggleButton>
        <ToggleButton value="month"><Translate i18nKey={'pryaniky.calendar.toolbar.month'} /></ToggleButton>
      </ToggleButtonGroup>

    </Box>
  )
}

const ConnectedCustomToolbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomToolbarPresenter)

export const CustomToolbar: React.FC<any> = (props) => <ConnectedCustomToolbar {...props} />