/**
 * @packageDocumentation
 * @module Widget_Settings_tagsCloud
 */

export default {
  data: {
    module: undefined,
    statistic: undefined,
    chart: undefined
  }
}