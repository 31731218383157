import React from 'react';
import i18n from '../../../localizations/i18n';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';

// SelectControl
export const select = (props: any) => {
  if (!props.visible) return null;
  const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};

  return <UIElements.Select
    // return <PryanikyForms.Select
    onChange={(selected: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => selected.value))}
    options={schema.opts}

    selected={schema.opts && schema.opts.filter((el: any) => {      
      if (props.data.hierarchy){
      return el.value.id === props.data[get_variable_name(props.uischema.scope)].id
    }else {
      return el.value === props.data[get_variable_name(props.uischema.scope)]
    }
    })[0]}
  // selected={props.datа[get_variable_name(props.uischema.scope)][0]}
  />
}
// end GroupsInputControl