import React from 'react';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../Widget';
import { cnWidget, IWidgetProps } from '../Widget.index';
import { widgets } from "i.widgets";
import { TagBlock } from 'blocks/TagBlock/TagBlock';

const type = 'tagBlock';

export default class WidgetTypeTagBlock extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
 
    return (
    <widgets.components.common {...this.props} className={cnWidget({ type, subtype })} >
      <TagBlock id={this.props.widget.id} context={this.props.wcontext} />
    </widgets.components.common>
    );
  }
}