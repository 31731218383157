import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { IStateType } from 'redux/store';
import { Action, Dispatch, bindActionCreators } from 'redux';
import { getMenuStrusture, getMenuService } from './redux/selectors';
import { menuAddItem, menuGetData, menuOpen, menuHidden, menuSetExpandedAll } from './redux/actions';

export const cnLeftMenu = cn('LeftMenu');

export interface ILeftMenuProps extends ILeftMenuOwnProps, IClassNameProps, ImapLeftMenuStateToProps, ImapLeftMenuActionsToProps {};

export interface ILeftMenuOwnProps {};

export const mapLeftMenuStateToProps = (state: IStateType, props: ILeftMenuOwnProps) => ({
  data: getMenuStrusture(state),
  expanded: getMenuService(state).expanded,
  opened: getMenuService(state).opened,
  hidden: getMenuService(state).hidden,
  edit: getMenuService(state).edit,
  openedMenu: state.store.appDesign?.showMenuOpened
});

export type ImapLeftMenuStateToProps = ReturnType<typeof mapLeftMenuStateToProps>;

export const mapLeftMenuActionsToProps = (d: Dispatch<Action>) => bindActionCreators({
  menuAddItem,
  menuGetData,
  menuOpen,
  menuHidden,
  menuSetExpandedAll
}, d);

export type ImapLeftMenuActionsToProps = ReturnType<typeof mapLeftMenuActionsToProps>;