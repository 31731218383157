import { IListProps, IListStateProps } from '../../List.index';
import { updateContext } from 'redux/actions/Widgets';
import { IStateType as IState } from 'redux/store';
import { filters } from 'utils/src/filters.prn';

export interface IListGroupsDefaultRequestProps {
  search: string;
  extended: boolean;
  groupListFilter: 'all' | 'my' |string;
  uid: string;
  [s: string]: any;
}

export interface IListGroupsDefaultProps {
  requestOptions: IListGroupsDefaultRequestProps;
}

export interface IListTypeGroupsProps extends IListProps, IListStateProps, IListGroupsDefaultProps {
  tag?: 'div';
  // updateContext: any;
}

export interface IActionsList {
  updateContext: typeof updateContext;
  // addDataToList: typeof addDataToList;
}
// export type IActionsList = ReturnType<typeof mapStateToProps>

export const mapActionsToProps = {
  updateContext,
  // addDataToList
};

// export type IStateProps =  ReturnType<typeof mapStateToProps>
// export const mapStateToProps = (state: IState, props: IListTypeGroupsProps) => ({
//   uid: getAuthUser(state).baseData.id,
//   edit: state.store.edit as boolean,
// })

const fltrs = {
  ...filters,
  // type: 'groupListFilter', // type
  groupsListFilter: 'groupListFilter', // type
  groupsAddFilter: 'addFilter',
  groupsOrder: 'order',
  uId: 'uid',
  filterTagId: 'filterTagId'
}

export type IFLTRS = typeof fltrs & { [s: string]: string };

export const avalibleFiltersGroups = Object.keys(fltrs).reduce((acc, cur) => acc[cur] === '' ? { ...acc, [cur]: cur } : acc, fltrs as IFLTRS);