import {
    objectArrayToMap
} from 'utils/src/utils'

/**
 * преобразует массив логов юнитов в плоскую модель
 * @param unitsLogs 
 */
export const buildFlatUnitsLogs = (unitsLogs: any[]) => {

    const unitsLogsMap = objectArrayToMap(unitsLogs, 'id')
    return {
        unitsLogsMap, ids: Object.keys(unitsLogsMap)
    }
}

/**
 * 
 */
export const buildFlatUnitResults = (userResults: any) => {
    const unitsResult: any = {};
    userResults.data[0].session.unitResults.forEach((val: any) => {
        const results = val.results ? val.results.map((r: any) => {
            let timeStamp = ''
            r.attempt.forEach((a: any) => {
                timeStamp = a.timeStamp || timeStamp
            })

            return {
                ...r,
                timeStamp
            }
        }) : val.results;
        unitsResult[val.unitId] = { ...val, results }
    })
    return { ...userResults.data[0], unitsResult }
}

/**
 * преобразует массив логов юнитов в плоскую модель
 * @param unitsLogs
 */
export const buildFlatCourseSessions = (course: any) => {
    const normalSessions = objectArrayToMap(course.courseSessions, 'id')
    return {
        ...course,
        courseSessions: Object.keys(normalSessions),
        normalSessions
    }
}

/**
 * проверяет, является ли targetUnitId следующим за currentUnitId
 * @param currentUnitId ид юнита относительно которого надо проверить
 * @param targetUnitId ид юнита который надо проверить
 * @param unitsList список юнитов в курсе
 */
export const isNextUnit = (currentUnitId: string, targetUnitId: string, unitsList: any[]) => {
    const currentIndex = unitsList.findIndex((unit: any) => unit.id === currentUnitId)
    const targetIndex = unitsList.findIndex((unit: any) => unit.id === targetUnitId)

    if ((currentIndex + 1) === targetIndex) return true
    return false
}

/**
 * выбор представления, которое должно показать юнит
 * @param isCompleted завершена ли сессия
 * @param unitType тип юнита, для которого нужно выбрать представление
 * @param isLastUnit последний ли это юнит
 */
export const selectView = (isCompleted: boolean, unitType: string, isLastUnit: boolean) => {
    let view = 'result';
    if (!isCompleted) {
        if (isLastUnit) view = 'skiped';
        else if (unitType === 'page') view = 'page';
        else if (unitType === 'wiki') view = 'wiki';
        else if (unitType === 'test') view = 'test';
        else if (unitType === 'scorm') view = 'scorm';
        else if (unitType === 'task') view = 'task';
    }
    return view
}

/**
 * проверяет, разрешеноли перемещение к targetUnit в текущий момент
 * @param targetIndex индекс юнита, для перемещения к которуму нужно произвести проверку
 * @param prevUnit объект юнита, который следует перед юнитом к которому надо переместиться
 * @param targetUnit объект юнита к которому надо переместиться
 */
export const isAllowTransitionToUnit = (targetIndex: number, prevUnit: any, targetUnit: any, isNext: boolean) => {
    if (isNext) return true

    let prevIsAccepted = targetIndex === 0;
    prevIsAccepted = prevIsAccepted ? prevIsAccepted : (targetIndex > 0) && (prevUnit.isSkipped || prevUnit.isCompleted);

    if (!prevIsAccepted) {
        console.warn('!prevIsAccepted', 'prevUnit', prevUnit, 'targetUnit', targetUnit)
        if (targetUnit.unitType === 'page' && !targetUnit.isCompleted) {
            console.warn('transition cancle.',
                'targetIndex:', targetIndex,
                'targetUnit:', targetUnit,
                `reason: targetUnit.unitType === 'page' && !targetUnit.isCompleted`)
            return false;
        }
        else if (targetUnit.unitType === 'wiki' && !targetUnit.isCompleted) {
            console.warn('transition cancle.',
                'targetIndex:', targetIndex,
                'targetUnit:', targetUnit,
                `reason: targetUnit.unitType === 'wiki' && !targetUnit.isCompleted`)
            return false;
        }
        else if (targetUnit.unitType === 'test' && !targetUnit.isSkipped && !targetUnit.isCompleted) {
            console.warn('transition cancle.',
                'targetIndex:', targetIndex,
                'targetUnit:', targetUnit,
                `reason: targetUnit.unitType === 'test' && !targetUnit.isSkipped && !targetUnit.isCompleted`)
            return false;
        }
        else if (targetUnit.unitType === 'scorm' && !targetUnit.isCompleted) {
            console.warn('transition cancle.',
                'targetIndex:', targetIndex,
                'targetUnit:', targetUnit,
                `reason: targetUnit.unitType === 'scorm' && !targetUnit.isCompleted`)
            return false;
        }
    }
    return true
}

/**
 * проверяет, разрешено ли перейти от теста
 * можно если:
 * 1. тест пропущен
 * 2. тест завершен
 * 3. цель - какой то из предыдущих юнитов
 * @param unit 
 * @param unitLog 
 * @param targetUnit 
 * @returns 
 */
export const allowTransitionFromTest = (unit: any, unitLog: any, targetUnit: any) =>
    unit.unitType === 'test' && !unitLog.isSkipped && !unitLog.isCompleted && unit.position < targetUnit.position

/**
 * проверяет, разрешено ли перейти от скорма
 * можно если:
 * 1. тест пропущен
 * 2. цель - какой то из предыдущих юнитов
 * @param unit
 * @param unitLog
 * @param targetUnit
 * @returns
 */
export const allowTransitionFromScorm = (unit: any, unitLog: any, targetUnit: any) =>
    unit.unitType === 'scorm' && !unitLog.isCompleted && unit.position < targetUnit.position

/**
 * проверяет, разрешен ли переход от unit к targetUnit
 * @param unit юнит от которого нужно перейти
 * @param unitLog лог юнита от которого нужно перейти
 * @param targetUnit юнит к которому нужно перейти
 */
export const isAllowTransitionFromUnit = (unit: any, unitLog: any, targetUnit: any) => {
    if(unitLog.allowSkip) {
        return true
    }
    if (allowTransitionFromTest(unit, unitLog, targetUnit)) {
        console.warn('transition cancle.',
            'unit:', unit,
            `reason: unit.unitType === 'test' && !unit.isSkipped && !unit.isCompleted && unit.position < targetUnit.position`)
        return false;
    }
    else if (allowTransitionFromScorm(unit, unitLog, targetUnit)) {
        console.warn('transition cancle.',
            'unit:', unit,
            `reason: targetUnit.unitType === 'scorm' && !targetUnit.isCompleted`)
        return false;
    }
    return true
}

/**
 * 
 */
export const getCompletedQuestionsCount = () => {

}