import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators } from 'redux'
import { IStateType as IState } from '../../redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export const cnSettingsRemindFillProfile = cn('SettingsRemindFillProfileWidget');

export namespace SettingsRemindFillProfileWidget  {


    export interface Settings { [s: string]: any; }
    export interface Props extends IClassNameProps {
        settings: Settings | null,
        isEdit:boolean
    }
}
