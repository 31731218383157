import { Characteristics, Presenter } from './Characteristics';
import {
    Shop,
    cnClassName,
} from './Characteristics.interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = Shop.Characteristics.IOwnProps;
export type IComponentPresenterProps = Shop.Characteristics.IPresenterProps;


export default Characteristics