import React, { FC, useContext, useState } from 'react';
import { cnNewsTypeWorkflows } from './Workflows.index';
import { connect } from 'react-redux';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import './Workflows.scss';
import { FieldsEditor } from 'muicomponents/src/FieldRender';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { useWorkflowsEdit } from './Workflows.hooks';
import { NewsCreateContext } from 'News/creator/Creator/Creator.constants';

const prepareI18nKey = (key: string) => `pryaniky.news.create.ideas.${key}`;

const NewsTypeWorkflowsPresenter: FC<{}> = ({
}) => {

    const { creatorId } = useContext(NewsCreateContext);

    const {
        data,
        errors,
        clearEditor,
        changeCreator,
        changeNews
    } = useWorkflowsEdit(creatorId);

    const {
        additionalFields
    } = data;

    const [fieldsErrors, setFieldsErrors] = useState<any>();

    useDidUpdateEffect(() => {
        changeCreator({
            errors: {
                ...errors,
                ...fieldsErrors
            }
        });
    }, [fieldsErrors]);

    if(!additionalFields) {
        console.error(`workflow "additionalFields" is undefined`);
        return null;
    }

    return (
        <>
            <FieldsEditor
                fields={additionalFields.additionalFieldsValues || []}
                onChange={(fields, newErrors) => {
                    changeNews({
                        additionalFields: {
                            ...additionalFields,
                            additionalFieldsValues: fields,
                        }
                    });
                    setFieldsErrors(newErrors);
                }}
                globalTagsCollection={'workflows'}
                errors={errors}
            />
        </>
    )
};

export const NewsTypeWorkflows = NewsTypeWorkflowsPresenter;
// export const NewsTypeWorkflows = connect(
//     mapNewsTypeWorkflowsStateToProps,
//     mapNewsTypeWorkflowsActionsToProps
// )(NewsTypeWorkflowsPresenter);

export const initNewsWorkflowsCreator = () => ComponentInjector.getInstance().addNode('news_creator_mui5', <NewsTypeWorkflows />, 'workflow')