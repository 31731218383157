import { ISearchActions, ISearchGetQuery, ISearchSetActiveTab, ISearchSetFilters, ISearchSetAdditionalFilter, ISearchClearAdditionalFilter } from './actions.interfaces';
export const GET_QUERY = 'GET_QUERY';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_ADDITIONAL_FILTERS = 'SET_ADDITIONAL_FILTERS';
export const CLEAR_ADDITIONAL_FILTERS = 'CLEAR_ADDITIONAL_FILTERS';


export const getQuery = (payload: ISearchGetQuery['payload']): ISearchActions => ({
  type: GET_QUERY,
  payload
});


export const setActiceTab = (payload: ISearchSetActiveTab['payload']): ISearchActions => ({
  type: SET_ACTIVE_TAB,
  payload
})


export const setFilters = (payload: ISearchSetFilters['payload']): ISearchActions => ({
  type: SET_FILTERS,
  payload
})


export const setAdditionalFilter = (filterName: ISearchSetAdditionalFilter['payload']['filterName'], value: ISearchSetAdditionalFilter['payload']['value']): ISearchActions => ({
  type: SET_ADDITIONAL_FILTERS,
  payload: {
    filterName,
    value
  }
})

export const clearAdditionalFilter = (filterName: ISearchClearAdditionalFilter['payload']['filterName']): ISearchActions => ({
  type: CLEAR_ADDITIONAL_FILTERS,
  payload: {
    filterName
  }
})