import { timedLoop } from "utils/src/utils";

export const noop = '';
export const isIE = /*@cc_on!@*/false || !!(document as any).documentMode;

function CustomEvent(event: string, params: any) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
}

if (!(window as any).CustomEvent) (window as any).CustomEvent = CustomEvent;

if (isIE) {
    require('./forIE.js');
    require('react-app-polyfill/ie11');
    require('react-app-polyfill/stable');
    require('proxy-polyfill');
    require('intersection-observer');
}
require('abortcontroller-polyfill/dist/polyfill-patch-fetch');


// append for IE
// Источник: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/append()/append().md
(function (arr) {
    arr.forEach(function (item) {
        if (item.hasOwnProperty('append')) {
            return;
        }
        Object.defineProperty(item, 'append', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function append() {
                var argArr = Array.prototype.slice.call(arguments),
                    docFrag = document.createDocumentFragment();

                argArr.forEach(function (argItem) {
                    var isNode = argItem instanceof Node;
                    docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                });

                this.appendChild(docFrag);
            }
        });
    });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);

if (!Object.values) {
    Object.values = function (obj: any) {
        return Object.keys(obj).reduce((a, c) => [...a, obj[c]], [] as any[]);
    }
}

if (!Object.entries) {
    Object.entries = function (obj: any) {
        var ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    };
}

declare global {
    interface ObjectConstructor {
        typedKeys<T>(obj: T): Array<keyof T>
    }

    interface Array<T> {
        indexOfAll(val: any): Array<number>;
        forEachTimed(time: number, ...args: Parameters<typeof Array.prototype.forEach>): void;
    }
}

Object.typedKeys = Object.keys as any;

Array.prototype.indexOfAll = function (val) {
    var indexes = [], i = -1;
    while ((i = this.indexOf(val, i + 1)) != -1) {
        indexes.push(i);
    }
    return indexes;
}

Array.prototype.forEachTimed = function (time: number, ...args: Parameters<typeof Array.prototype.forEach>) {
    timedLoop(this, time, args[0]);
}