
/**
 * @packageDocumentation
 * @module List_type_registrations
 */
import { IListProps } from '../../List.index';
import { filters } from 'utils/src/filters.prn';

import { IDispatchProps } from '../../../../redux/connector';

export interface IDefaultRequestOption {
  search: string,
}

export interface IListRegistrationsDefaultRequestProps extends IDefaultRequestOption {
  count: number,
  skipCount: number
}

export interface IListTypeRegistrationsProps extends IListProps, IDispatchProps {
  requestOptions: any;
}

const fltrs = {
  ...filters,
}

export type IFLTRS = typeof fltrs & { [s: string]: string };

export const avalibleFilters = Object.keys(fltrs).reduce((acc, cur) => acc[cur] === '' ? { ...acc, [cur]: cur } : acc, fltrs as IFLTRS);