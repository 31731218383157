import React, { Component } from 'react';
import './StyleButton.css'

export class StyleButton extends Component<any> {
    private onToggle = (e: any) => { };
    constructor(props: any) {
        super(props);
        this.onToggle = (e: any) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }
    render() {
        let className = 'PrnEditor-StyleButton';
        if (this.props.active) {
            className += ' PrnEditor-activeButton';
        }
        return (
            <button className={className} onMouseDown={this.onToggle}>
                {this.props.label}
            </button>
        );
    }
}
