import * as React from 'react';
import * as utils from 'utils/src/utils';
// import { withBemMod } from '@bem-react/core';
import { cnWidget } from '../Widget.index';
// import { IWidgetTypeListProps } from './Widget_type_list.index';
// import { List } from 'blocks/List/List';
import './Widget_type_list.scss';
import { default as WidgetPresenter } from '../Widget';
import { CustomSettings, PageCollectionWithChildCollections } from 'utils/src';
import { PreviewParams } from 'utils/src/PreviewParams';
import { widgets } from "i.widgets";
import { IWidgetTypeListProps } from './Widget_type_list.index';
import { OrdersList } from '../../OrdersList/OrdersList'
import { BadgeUsersList } from '../../BadgeLists/BadgeListTypeUsers/BadgeListTypeUsers'
import { LeaderboardList } from 'blocks/LeaderboardNew/Leaderboard_list_new'
import { ListWikiNew } from '../../WikiListNew/List_type_wiki'
import { ShopListMui } from 'blocks/ShopMui/ShopList/ShopListMui';
import { TasksList } from '../../Tasks/List';

const useMui5Badges = CustomSettings.useMui5Badges();

const types = {
  users: require('blocks/List/_type/users/List_type_users').List,
  usersSelector: require('blocks/List/_type/users/List_type_users_selector').List,
  groupUsersSelector: require('blocks/List/_type/users/List_type_group_users_selector').List,
  leaderboard: LeaderboardList,

  groups: require('blocks/List/_type/groups/List_type_groups').List,
  groupsTree: require('blocks/List/_type/groups/List_type_groups_tree').List,
  groupsSelector: require('blocks/List/_type/groups/List_type_groups_selector').List,
  groupsTreeSelector: require('blocks/List/_type/groups/List_type_groups_tree_selector').List,
  myGroups: require('blocks/List/_type/groups/List_type_groups_myGroups').List,
  questsmoderate: require('blocks/List/_type/quests/List_type_quests_moderate').List,
  quests: require('blocks/List/_type/quests/List_type_quests').List,
  badgeMembers: useMui5Badges ? BadgeUsersList : require('blocks/List/_type/users/List_type_users_badge').List,
  // badgeMembers: require('blocks/List/_type/users/List_type_users_badge').List,
  wikilist: ListWikiNew,
  // badgeMembers: require('blocks/List/_type/users/List_type_users_badge').List,
  shop: ShopListMui,
  shopMui: ShopListMui,
  orders: OrdersList,
  // registrations: require('blocks/List/_type/registration/List_type_registrations').List,
  tasks: TasksList
}

const type = 'list';

export type WikilistSettings = {
  folder?: PageCollectionWithChildCollections | null;
}

export default class WidgetTypeListPresenter extends WidgetPresenter<IWidgetTypeListProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    let Component = this.getComponent(types);
    const context = this.props.wcontext;
    if(subtype === 'wikilist') {
      if(this.props.widget.settings?.folder?.alias) {
        context.collectionAlias = this.props.widget.settings?.folder?.alias;
      }
    }
    return <widgets.components.common {...this.props} className={cnWidget({ type, subtype })} context={context} id={this.props.widget.id} contexts={this.relations.concat(this.props.widget.id)}>
      <Component {...this.props} context={context} id={this.props.widget.id} />
    </widgets.components.common>
  }

}