import { IClassNameProps } from '@bem-react/core';
import { cnModal } from '../Modal.index';

export interface IBodyProps extends IClassNameProps {
  tag?: 'div';
  className?: string;
  // store?: any;
  // changeVm?: any;
  type: string;
  // parent: React.Component<any, any>;
  // TODO resolre troble with React.Component<any, any>;
  parent: any;
  bodyProps?: Record<string, any>;
}

export interface IBodyState {
  text?: string;
}

export const cnBody = (...data: any) => {
  let name = 'Body';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnModal(name, ...data)
}