import React from 'react';
import { createPortal } from 'react-dom';
import { Button, Icon } from "uielements/src";
import WidgetPresenter from '../Widget';
import { IWidgetTypeTopButtonAboutProps, IWidgetTypeTopButtonAboutState } from "./Widget_type_topbutton.index";
import { widgets } from "../../../i.widgets";
import i18n from '../../../localizations/i18n';

export const TopButton: React.FC<{}> = () => {
  const [isShown, setIsShown] = React.useState(false);
  React.useEffect(() => {
    const layout = document.getElementById('Layout');
    const scroll = layout && layout.parentElement || null;
    let issh = isShown;
    if(!scroll) return;
    scroll.addEventListener('scroll', () => {
      if (scroll.scrollTop > 200 && !issh) {
        setIsShown(true);
        issh = true;
      } else if (scroll.scrollTop < 100 && issh) {
        setIsShown(false);
        issh = false;
      }
    })
  }, []);
  if (!isShown) return null;
  return <Button onClick={() => {
    const layout = document.getElementById('Layout');
    if (!layout || !layout.parentElement) return;
    layout.parentElement.scrollTop = 0;
  }} className='TopButton' children={<Icon icon='chevron-up' />} />
}

export default class WidgetTypeTopButtonPresenter extends WidgetPresenter<IWidgetTypeTopButtonAboutProps, IWidgetTypeTopButtonAboutState> {

  public state: IWidgetTypeTopButtonAboutState = {
    ...this.state,
    isShown: false
  }

  componentDidMount() {
    super.componentDidMount();
    // const layout = document.getElementById('Layout');
    // const scroll = layout && layout.parentElement || null;
    // if (!scroll) return;
    // scroll.addEventListener('scroll', () => {
    //   if (scroll.scrollTop > 100 && !this.state.isShown) {
    //     this.setState({ isShown: true })
    //   } else if (scroll.scrollTop < 100 && this.state.isShown) {
    //     this.setState({ isShown: false })
    //   }
    // })
  }

  public render() {
    if (!this.props.widget) return null;

    // const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    if ((this.props.edit && !(window as any).PRN_SERVICE.dev_features) || (!this.state.isShown && !this.props.edit)) return null;
    const button = createPortal(<TopButton />, document.getElementById('Layout') as HTMLDivElement);
    if (this.props.edit && (window as any).PRN_SERVICE.dev_features) return (
      <widgets.components.common {...this.props}>
        {i18n.t('pryaniky.widgets.create.name.pryaniky/topbutton')}
        {button}
      </widgets.components.common>
    );
    return button;
    // (
    
    //   <Button children={<Icon icon='chevron-top' />} />
    // );
  }
}