import React, { FC, useEffect, useState, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMSTO
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { Button, Spinner } from 'uielements/src'
import { Users } from './Filters/Users/Users'
import { CommentsLayout } from 'blocks/NewsTypes/common/CommentsLayout/CommentsLayout'
import { Reply } from 'blocks/Reply/Reply'
import { ReplySkeleton } from 'blocks/Skeletons/Replys/Reply-Skeleton'
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import Search from './Filters/Search'
import Units from './Filters/Units'
import Courses from './Filters/Courses'
import ListPlaceholder from '../../components/ListPlaceholder'
import { ReplyEditor } from 'blocks/ReplyEditor/ReplyEditor'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

const LoadButton = withLoadSpin(Button)

type IPresenterProps = LMSTO.Comments.IPresenterProps
type IOwnProps = LMSTO.Comments.IOwnProps
type IDispatchProps = LMSTO.Comments.IDispatchProps
type IStateProps = LMSTO.Comments.IStateProps
type IState = LMSTO.Comments.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    cid,
    loadUnitsComments,
    values,
    requestOptions,
    keys,
    isFinished,
    isLoading,
    course,
    type = 'admin',
    commentsKeys,
    changeUnitsReply
}) => {
    const loadMore = () => loadUnitsComments({ params: { 'filter.CourseId': cid, ...requestOptions }, type })

    useEffect(() => {
        loadUnitsComments({ params: { 'filter.CourseId': cid, ...requestOptions }, type })
    }, [])


    const triggerRef = useInfiniteScroll({
        loadMore,
        isLoading,
        isFinished,
    });

    const onFilterChange = (filter: string) => (value: any) => {
        loadUnitsComments({
            params: {
                'filter.CourseId': cid,
                ...requestOptions,
                [filter]: value,
            }, type
        })
    }

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Aside')}>
            {type === 'admin' && <Users setData={onFilterChange('filter.AuthorId')} />}

            {!course && <Courses
                setData={onFilterChange('filter.CourseId')}
            />}

            {course && <Units
                setData={onFilterChange('filter.UnitId')}
                units={course.courseUnits}
                currentData={requestOptions['filter.UnitId']} />}
        </div>

        <div className={cnClassName('Section')}>
            <Search
                search={requestOptions['filter.Search']}
                setData={onFilterChange('filter.Search')}
                isLoading={isLoading}
                className={cnClassName('Search')}
            />

            <ListPlaceholder
                className={cnClassName('Placeholder')}
                isFinished={isFinished}
                isLoading={isLoading}
                itemsCount={keys.length}
                titleEmpty={tT('comments.list.empty.title.1')}
                textEmpty={tT('comments.list.empty.text.1')}
                textLoad={tT('comments.list.load.1')}
            >
                <div className={cnClassName('List')}>
                    {keys.map((id, idx) =>
                        <div key={`${id}_${idx}`} className={cnClassName('Item')}>
                            <div className={cnClassName('Unit')}>
                                <h3 className={cnClassName('Header')}>{values[id]?.unitHeader}</h3>
                            </div>

                            <CommentsLayout
                                more={<div></div>
                                }
                                form={<></>}
                            >
                                {values[id]?.comment && <Reply
                                    id={id}
                                    fund={0}
                                    className=''
                                    setRewardComment={() => { }}
                                    deleteRewardComment={() => { }}
                                    allowReply={false}
                                    rewards={false}
                                    key={`${id}`}
                                    newsID={values[id]?.newsId} />}
                            </CommentsLayout>
                        </div>)}
                </div>
                {!isFinished && (
                    <LoadButton
                        forwardedRef={triggerRef}
                        isLoading={isLoading}
                        className={cnClassName('Trigger')}
                        onClick={isLoading ? void 0 : loadMore}>
                        {tT('comments.list.more.1')}
                    </LoadButton>)}
            </ListPlaceholder>
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));
