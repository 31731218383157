import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
  INIT: '',
  KILL: '',
  SHOW_TOGGLE: '',
  SET_DATA: '',
  CHANGE_DATA: '',
  GET_INTERCOMPANY_GROUPS_REQUESTS: '',
  CONFIRM_INTERCOMPANY_GROUPS_REQUESTS: '',
  DECLINE_INTERCOMPANY_GROUPS_REQUESTS: ''
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.MODAL)}), {} as typeof actions);