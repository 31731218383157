import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { IStateType } from 'redux/store';
import { getBadgesList } from 'utils/src/requests/requests.badges';

export const cnBadgesListItem = cn('BadgesListItem');

export namespace NBadgesListItem {

    type Data = Awaited<ReturnType<typeof getBadgesList>>['data'];

    export interface Props extends IClassNameProps, ReturnType<typeof mapBadgesListItemStatetoProps> {
        data: Data[number]['items'][number];
    }
}

export const mapBadgesListItemStatetoProps = (state: IStateType) => ({
    currencyFormats: state.store.appSettings as any || {}
});