import React, { createContext, FC, useContext, useMemo } from "react";
import { useLocation } from "react-router";
import { parse } from 'query-string';
import { ShopListPathStringParams } from "./ShopListMui.path";

type ShopListContext = {
  userId?: string;
};

const baseShopListContext: ShopListContext = {};

const ShopListContext = createContext(baseShopListContext);

export const ShopListContextProvider: FC<{}> = ({
  children
}) => {

  const location = useLocation();

  const params = useMemo(() => {
    return parse(location.search.substring(1), { arrayFormat: 'comma' }) as ShopListPathStringParams;
  }, [location.search]);

  const value = useMemo(() => {
    return { userId: params.userId }
  }, [params.userId]);

  return (
    <ShopListContext.Provider value={value}>
      {children}
    </ShopListContext.Provider>
  );
};

export const useShopListContext = () => {
  return useContext(ShopListContext);
};