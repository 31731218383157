/**
 * @packageDocumentation
 * @module Widget_Settings_common
 */
import { JFdictionary } from "../dictionary";

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  elements: []
}