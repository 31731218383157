/**
 * @packageDocumentation
 * @module Widget_Settings_hierarchy
 */

import React, { FC, useEffect, useState } from 'react'
import { JFdictionary } from "../dictionary";
import i18n from "../../../../localizations/i18n";
import { store } from "../../../../redux/store";
import {
    getUserHieararchyFromServer
} from 'redux/sagas/Hierarchy/actions'

const { types } = JFdictionary

export let additionalSchemaData: any = {
    type: types.string,
    placeholder: i18n.t('pryaniky.widgets.settings.placeholder.hierarchy'),
    opts: [],
    data: [],
    require: true
}

/**
 * если используешь подписку через store.subscribe, то обязательно нужно проверять, изменились ли нужные параметры.
 * и нужно забвать отписываться, если подписка больше ненужна.
 * коллбэк функции subscribe вызывается всегда, при любом изменение в стейте.
 */
// const hierarchySubscr = () => {
//     let unsubscribe = () => { }
//     // ответ subscribe - функция для отмены подписки
//     unsubscribe = store.subscribe(() => {
//         const state = store.getState()
//         additionalSchemaData.opts = state.hierarchy.usersHierarchy.map((e: any) => prepareOption(e.name, e));
//         //отменяем подписку
//         // но если отменить в данном случае - то в additionalSchemaData.opts окажется пустой массив. 
//         // Потому что вызвана она будет только один раз - после первого изменения в стейте. А если не отменить подписку - то будет additionalSchemaData.opts будет изменяться 
//         // вообще при любом изменение в стейте. Всегда.
//         // unsubscribe()
//     })

// }
/** 
 * неужели нужна сразу, при импорте файла? а usersHierarchy в это время точно есть?
 * 
 * а вообще я понял... это всё неправильно... не тут эта функция должна вызываться...
*/
// hierarchySubscr();

/**
 * инкапсулируем все эти грязные делишки в сагу.
 * из WSettings просто дёргаем событие.
 * пока что такая, костальная реализация добавления события... очень много спагети
 * @param data 
 */
export const prepareData = (data: any) => {
    store.dispatch(getUserHieararchyFromServer({ skipCount: 0, count: 30, data }))
}

export const prepareOption = (title: string, value: string) => ({
    title,
    value,
})

const relationsArray = [
    'subusers',
    'headusers',
]

export default {
    type: types.object,
    properties: {
        hierarchy: additionalSchemaData,
        headusers: {
            type: types.boolean,
            relations: relationsArray.filter((val) => val !== 'headusers'),
            require: true
        },
        subusers: {
            type: types.boolean,
            relations: relationsArray.filter((val) => val !== 'subusers'),
            require: true
        },
        hideIfNoData: {
            type: types.boolean
        },
        hierarcheListView: {
            type: types.boolean
        },
    }
}
