import React, { FC, memo, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    Creativetasks
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext } from 'News/contexts/news'
import { CountSlider } from 'blocks/CountSlider/CountSlider';

type IPresenterProps = Creativetasks.CountSlider.IPresenterProps
type IOwnProps = Creativetasks.CountSlider.IOwnProps
type IDispatchProps = Creativetasks.CountSlider.IDispatchProps
type IStateProps = Creativetasks.CountSlider.IStateProps
type IState = Creativetasks.CountSlider.IState


export const Presenter: FC<IPresenterProps> = ({
    className,
    count,
    value,
    accepted,
    setValue,
    close,
    fund
}) => <CountSlider
        maxCount={count + fund}
        onChange={(v: any) => setValue(Number.parseInt(v))}
        icon={'give'}
        minValue={0}
        value={value}
        type='modal'
        className='NewsThanksSupport'
        closeBtn={close}
        saveBtn={accepted}
    />

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));

