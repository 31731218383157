import React, { FC, memo } from 'react'
import {
    cnClassName,
    KPI
} from './interfaces'
import './style.scss'
import i18n from 'localizations/i18n'
type IPresenterProps = KPI.Table.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
}) => {
    return <table className={cnClassName({}, [className])}>
        <colgroup>
            <col span={1} className={cnClassName('ColCompleted')} />
            <col span={1} className={cnClassName('ColName')} />
            <col span={1} className={cnClassName('ColGoal')} />
            <col span={1} className={cnClassName('ColDueDate')} />
            <col span={1} className={cnClassName('ColActions')} />
        </colgroup>
        <thead>
            <tr>
                <th></th>
                <th>{i18n.t("pryaniky.kpi.head.name.label.1")}</th>
                <th>{i18n.t("pryaniky.kpi.head.goal.label.1")}</th>
                <th>{i18n.t("pryaniky.kpi.head.dueDate.label.1")}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {children}
        </tbody>
    </table>
}

export default memo(Presenter);
