import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { NewsContextProps } from '../../contexts/news'
import {
    getNewsParamsById
} from '../../redux/saga/selectors'
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace MiniHeader {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps, NewsContextProps {
            children?: React.ReactNode
            disableTextCut?: boolean
            disableDate?: boolean
            onClick?: () => void
            authorOnly?: boolean
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.MiniHeader.IOwnProps


export const mapStateToProps = (state: any, { newsId }: IOwnProps) => ({
    ...getNewsParamsById(newsId,
        'id',
        'newstype',
        // 'actionHeader',
        'componentRenderName',
        // 'actions',
        'user',
        'isNeedApprove',
        'isShowOnTop',
        'group',
        'changeDate',
        'date',
        // 'id',
        'newstype',
        'header',
        'publishAt',
        'componentRenderName',
        'isDraft'
    )(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({

}, dispatch);

////////////

export const cnClassName = cn('MiniHeader');