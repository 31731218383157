// import { IdeaNewsCreate, AdditionalFieldsValue } from './IdeaType'
// import { Row } from './Fields/Types'
import { Rule, BaseValidator, validateArray, validateString, validateNumber } from '../Base.validate'
// import { IdeaFields } from './Fields/Types';
import { GUID_EMPTY } from 'utils/src/constants.prn';

export const workflowsHeaderValidate: Rule[] = [
  {
    type: 'string',
    method: 'STR_MIN',
    field: 'header',
    value: 0,
    message: 'small header'
  }
]

export const workflowsValidate: Rule[] = [
  {
    type: 'string',
    method: 'STR_MIN',
    field: 'text',
    value: 5,
    message: 'small text'
  },
  /*{
      type: 'string',
      method: 'STR_MAX',
      field: 'text',
      value: 450,
      message: 'big text'
  },*/
  {
    type: 'string',
    method: 'STR_MIN',
    field: 'header',
    value: 3,
    message: 'small header'
  },
  /*{
      type: 'string',
      method: 'STR_MAX',
      field: 'header',
      value: 30,
      message: 'big header'
  },*/
]

const additionalFiledsTypesValueRules: { [s: string]: Rule } = {
  'Tags': {
    type: 'array',
    method: 'ARR_MIN',
    field: '', // для данного случая не обязателен
    value: 1,
    message: 'select one or more'
  },
  'Textarea': {
    type: 'string',
    method: 'STR_MIN',
    field: '', // для данного случая не обязателен
    value: 5,
    message: 'input 5 or more symbols'
  },
  'MatrixOnePerRow': {
    type: 'araay',
    method: 'NOT_NULL_GUID',
    field: '', // для данного случая не обязателен
    value: 0,
    message: 'answer to all rows'
  },
  "DateTime": {
    type: 'string',
    method: 'STR_MIN',
    field: '', // для данного случая не обязателен
    value: 1,
    message: 'choose date'
  },
  "Int": {
    type: 'string',
    method: 'STR_MIN',
    field: '', // для данного случая не обязателен
    value: 1,
    message: 'input number'
  },
  "Single": {
    type: 'string',
    method: 'STR_MIN',
    field: '', // для данного случая не обязателен
    value: 1,
    message: 'select one'
  },
  "Multi": {
    type: 'array',
    method: 'ARR_MIN',
    field: '', // для данного случая не обязателен
    value: 1,
    message: 'select one or more'
  },
  "String": {
    type: 'string',
    method: 'STR_MIN',
    field: '', // для данного случая не обязателен
    value: 1,
    message: 'input 5 or more symbols'
  },
  "StringEmail": {
    type: 'string',
    method: 'IS_EMAIL',
    field: '', // для данного случая не обязателен
    value: 1,
    message: 'enter correct email'
  },

  "preview": {
    type: '',
    method: '',
    field: '', 
    value: 1,
    message: ''
  },

  "Photo": {
    type: 'string',
    method: 'STR_MIN',
    field: '', // для данного случая не обязателен
    value: 1,
    message: 'select one'
  },

  "Files": {
    type: 'array',
    method: 'ARR_MIN',
    field: '', // для данного случая не обязателен
    value: 1,
    message: 'select one or more'
  },

  "ResponsibleForStatusUser": {
    type: 'array',
    method: 'ARR_MIN',
    field: '', // для данного случая не обязателен
    value: 1,
    message: 'select one or more'
  }
}

const isPreview = window.location.href.indexOf('settingsPreview=true') !== -1;

function matrixRowsValidate(data: any[], rule: Rule) { // Row
  return data.reduce((a, c) => !a || c.selectedAnswer === GUID_EMPTY ? false : a, true);
}

export class WorkflowsValidator extends BaseValidator<any> {

  public validation() {
    if (isPreview) return [additionalFiledsTypesValueRules.preview];
    super.setRules(workflowsHeaderValidate)
    const baseValidate =  super.validation();
    const adiditionalFieldsValidate = this.workflowsAdditionalFieldsValuesValidate(this.data.additionalFields.additionalFieldsValues);
    const invalide: Rule[] = [...baseValidate, ...adiditionalFieldsValidate];
    return window.SERVICE__DISABLE_VALIDATE ? [] : invalide;
  }

  public workflowsAdditionalFieldsValuesValidate = (fieldsValues: any[]) => { //AdditionalFieldsValue
    if (isPreview) return [additionalFiledsTypesValueRules.preview];
    if (!fieldsValues?.length) return [];
    const invalide: Rule[] = [];
    const v = fieldsValues
      .map(k => k.fields)
      .reduce((a, c) => [...a, ...c], [] as any[])
      .filter((i: any) => i.isMandatory); // IIdeaField
    v?.forEach((field: any) => {
      
      switch (field.type) {
        case 'Tags': {
          !validateArray((field.data || []), additionalFiledsTypesValueRules[field.type]) && invalide.push(additionalFiledsTypesValueRules[field.type]);
          break;
        }
        case 'Textarea': {
          !validateString((field.data || ''), additionalFiledsTypesValueRules[field.type]) && invalide.push(additionalFiledsTypesValueRules[field.type]);
          break;
        }
        case 'MatrixOnePerRow': {
          // !matrixRowsValidate(field.rows, additionalFiledsTypesValueRules[field.type]) && invalide.push(additionalFiledsTypesValueRules[field.type]);
          break;
        }
        case "DateTime": {
          // const compareDates = new Date(field.value).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);                    
          !validateString((field.data || ""), additionalFiledsTypesValueRules[field.type]) && invalide.push(additionalFiledsTypesValueRules[field.type]);
          break;
        }
        case "Int": {
          !validateString((field.data || ''), additionalFiledsTypesValueRules[field.type]) && invalide.push(additionalFiledsTypesValueRules[field.type]);
          break;
        }
        case "Single": {
          !validateString((field.data.find((el: any) => el.selected)?.text || ''), additionalFiledsTypesValueRules[field.type]) && invalide.push(additionalFiledsTypesValueRules[field.type]);
          break;
        }
        case 'Multi': {
          !validateArray((field.data?.filter((el: any) => el.selected) || []), additionalFiledsTypesValueRules[field.type]) && invalide.push(additionalFiledsTypesValueRules[field.type]);
          break;
        }
        case 'ResponsibleForStatusUser': {
          !validateArray((field.data || []), additionalFiledsTypesValueRules[field.type]) && invalide.push(additionalFiledsTypesValueRules[field.type]);
          break;
        }
        case 'String': {
          switch (field.mask) {
            case 'email': {
              !validateString((field.data || ''), additionalFiledsTypesValueRules['StringEmail']) && invalide.push(additionalFiledsTypesValueRules['StringEmail']);
              break;
            }
            default: {
              !validateString((field.data || ''), additionalFiledsTypesValueRules[field.type]) && invalide.push(additionalFiledsTypesValueRules[field.type]);
              break;
            }
          }
        }

        case 'Files': {
          !validateArray((field.data || []), additionalFiledsTypesValueRules[field.type]) && invalide.push(additionalFiledsTypesValueRules[field.type]);
          break;
        }

        case 'Photo': {
          !validateString((field.data?.previewUrl || ''), additionalFiledsTypesValueRules[field.type]) && invalide.push(additionalFiledsTypesValueRules[field.type]);
          break;
        }

      }
    })
    return window.SERVICE__DISABLE_VALIDATE ? [] : invalide;
  }
}
