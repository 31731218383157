import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { RouteComponentProps } from 'react-router';
export interface IMessagesProps extends IClassNameProps, IChatListStateProps, RouteComponentProps<any> {
  tag?: 'div';
}

export interface IMessagesState {
  isAuth: boolean;
}

export type IChatListStateProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: any) => ({
  token: state.chat.config.token,
  siteUrl: state.store.appSettings && state.store.appSettings.siteURL || '',
  chatSettings: state.store.chatSettings
})

export interface IMessagesState {
  text?: string;
}

export const cnMessages = cn('Messages');