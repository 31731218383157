/**
 * @packageDocumentation
 * @module List_type_badges_search
 */
import { IListProps } from '../../List.index';
import { filters } from 'utils/src/filters.prn';

import { IDispatchProps } from '../../../../redux/connector';

export interface IDefaultRequestOption {
  search: string,
}

export interface IListTypeBadgesSearchProps extends IListProps, IDispatchProps {
  requestOptions: any;
  tag?: 'div';
}

const fltrs = {
  ...filters,
  type: 'catFilter', // type
  search: 'search',
  network: 'network',
}

export type IFLTRS = typeof fltrs & { [s: string]: string };

export const avalibleFilters = Object.keys(fltrs).reduce((acc, cur) => acc[cur] === '' ? { ...acc, [cur]: cur } : acc, fltrs as IFLTRS);