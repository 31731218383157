import React from 'react'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

export const questionTypes = [
    {
        value: 0,
        title: <Tt i18nKey={'question_edit.questionTypes.0.1'} />,//'Один вариант из списка',
        id: 0,
    },
    {
        value: 1,
        title: <Tt i18nKey={'question_edit.questionTypes.1.1'} />,//'Несколько вариантов из списка',
        id: 1,
    },
    {
        value: 2,
        title: <Tt i18nKey={'question_edit.questionTypes.2.1'} />,//'Свободная форма ответа(текст)',
        id: 2,
    }
]