import {
    takeEvery,
    put,
    takeLeading,
    takeLatest,
    throttle,
    fork
} from 'redux-saga/effects';
import {
    call,
    select,
} from 'utils/src/saga.effects';

import actions from './actionsType'

import {
    pathNews
} from 'News/redux/actions'

import {
    getNewsById
} from 'News/redux/saga/selectors'

import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'

import * as AT from './actions.d'
import * as ACT from './actions'

import {
    API,
    getCookie
} from 'utils/src/utils'

import { removeUserFromArray, addUserToArray, toggleUserInArray, userInArray } from 'blocks/NewsTypes/NewsUtils'




const handleLoadReasons = function* handleLoadReasons({  }: AT.ALoadReasons) {
    try {

        const request = yield* call(API.system.reasons)
        // @ts-ignore
        const result = yield request.r

        if(result.error_code === 0) {
            const flat = result.data.reduce((acc: any, cur: any) => {
                return [...acc, ...cur.reasons.map((val: any) => ({ ...val, group: cur.name }))]
            }, [])
            yield put(ACT.setReasons(flat))
        }

    } catch (error) {
        console.warn(error)
    }
    yield;
    return 0;
}

/**
 * module root saga
 */
const root = function* root() {
    yield takeLeading(actions.LOAD_REASONS, handleLoadReasons)
};

/**
 * export root saga
 */
export default root;