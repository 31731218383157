import { AnswerList } from '../../LMSTeacherOffice/AnswerList/AnswerList'
import React, { FC, useEffect, useCallback } from 'react'

export const filters: any = {
    'filter.sessionIds': [],
    'filter.filterByUserIds': [],
    'filter.filterByQuestionIds': [],
    'filter.isNeedModeration': true,
    'filter.filterByStatus': ['All']
}

export const useAnswersRender = (userId: string, sessionId: string, courseId: string) => {
    return useCallback((question: any) => {
        filters['filter.sessionIds'] = [sessionId];
        filters['filter.filterByUserIds'] = [userId]
        filters['filter.filterByQuestionIds'] = [question.id]

        return <AnswerList hideModeration={true} courseId={courseId} listContainer={question.id} filters={filters} />
    }, [userId, sessionId, courseId]);
}
