import { cn } from '@bem-react/classname';
import { IBodyProps } from 'utils/src/DialogCreator';

export const cnHierarchyWidgetNew = cn('HierarchyWidgetNew');
export interface IUsersHierarchyWidgetProps {
    context: { [s: string]: any };
    settings?: any;
    tag?: React.ComponentType | 'div';
    countOfVisibleItemsInWidget?: number;
}

export interface ContentItemProps {
    item: any;
    hierarchyName: any;
    onDelete: (v: any) => void;
}

type commonStructureAndAddForm = {
    title: string;
    items: any[];
    setItems: (v: any) => void;
} & Omit<IUsersHierarchyWidgetProps, 'countOfVisibleItemsInWidget' | 'tag'>;

export type IAddFormProps = {
    newUser: any;
    setNewUser: (arg: any) => void;
} & commonStructureAndAddForm;

export type IStructureProps = {
    children?: React.ReactNode;
} & commonStructureAndAddForm;

export interface DialogBodyShowAllProps extends IBodyProps {
    children: React.ReactNode;
}

export type IContentItemListProps = {
    onDelete: (v: any) => void;
    countOfVisibleItemsInWidget: number;
} & IStructureProps;
