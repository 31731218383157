import React, { FC, useState, Component, ReactNode } from 'react';
import './DropdownSelect.scss';

interface Props {
    button: (open: boolean, toggleOpen: ()=> void) => ReactNode
    children: (open: boolean, toggleOpen: () => void) => ReactNode
}
export class DropdownSelect extends Component<Props, any>  {
    private selfRef: HTMLDivElement | null = null;

    public constructor(props: any) {
        super(props)

        this.state = {
            open: false
        }
    }

    public onOpen = () => {
        document.addEventListener('click', this.handleClickOutside, false);
        this.setState({ open: true })
    }

    public onClose = () => {
        document.removeEventListener('click', this.handleClickOutside, false);
        this.setState({ open: false })
    }

    private handleClickOutside = (event: any) => {
        if (this.selfRef && !this.selfRef.contains(event.target)) this.setState({ open: false });
    };

    private toggleOpen = () => {
        this.state.open ? this.onClose() : this.onOpen()
    }

    public render = () => {
        const { button, children } = this.props
        const { open } = this.state;
        return <div ref={(ref: any) => this.selfRef = ref} className={'DropdownSelect'}>
            <div className={'DropdownSelect-BtnBox'} /*onClick={this.toggleOpen}*/>
                {button(open, this.toggleOpen)}
            </div>
            {open && <div className={'DropdownSelect-Container'}>
                {children(open, this.onClose)}
            </div>}
        </div>
    }
}