import { IStateType } from "redux/store";
import { TColumn, TWidget, TWidgetsUnion, TWidgetByType } from "utils/src/BaseTypes/widgets.types";
import { baseColumn, baseWidget, settingsKeys, widgetTypesForManipulationWithChildWidgets } from "./constants";
import { v1 as uuid } from 'uuid';
import { TWidgetsUnionSimplified } from "./types";
import { WidgetsTypes } from "utils/src/widgets/types";

export const getWidgetRelations = (id: string, contexts: IStateType['widgets']['contexts']) => {
    // get callable widget context
    const onceContext = contexts[id];
    // define all relations
    const relations: (typeof id)[] = [id];
    let parent = onceContext.__parent;
    while (parent) {
        relations.unshift(parent);
        parent = contexts[parent].__parent;
    }
    return relations;
};

/**
 * generate new widget
 * @param params 
 * @returns 
 */
export const generateWidget = <Type extends keyof typeof WidgetsTypes>(type: Type, params: Partial<TWidgetByType<Type>>) => ({
    ...baseWidget,
    id: uuid(),
    ...params,
    type: WidgetsTypes[type]
}) as Extract<TWidgetsUnion, { type: typeof WidgetsTypes[Type]}>;

/**
 * generate new column
 * @param params 
 * @returns 
 */
export const generateColumn = (params: Partial<TColumn>): TColumn => ({
    ...baseColumn,
    id: uuid(),
    ...params,
});

export const checkTypeToCanManipulateWithChildWidgets = (type: TWidget['type'], disableLog?: boolean) => {
    // widget.type as any because type can be any type of widget
    const rez = widgetTypesForManipulationWithChildWidgets.includes(type as any);
    if(!rez && !disableLog) {
        console.error(`widget with type ${type} can't manipulate others widgets`);
    }
    return rez;
};

/**
 * prepare context data from widget settings value
 */
export const prepareContextFromSettings = (settingsData: TWidgetsUnionSimplified['settings']) => {
    if(!settingsData) return {};
    const settings = settingsData as {[key: string]: any};
    return Object.keys(settings).reduce((acc, cur) => {
        switch (cur) {
            case settingsKeys.rId: {
                return { ...acc, rId: settings[settingsKeys.rId].pkid || settings[settingsKeys.rId].id }
            }
            case settingsKeys.count: {
                return { ...acc, count: settings[settingsKeys.count] }
            }
            case settingsKeys.type: {
                return { ...acc, type: settings[settingsKeys.type] }
            }
            case settingsKeys.name: {
                return { ...acc, name: settings[settingsKeys.name] }
            }
            case settingsKeys.name: {
                return { ...acc, name: settings[settingsKeys.name] }
            }
            case settingsKeys.gId: {
                return {
                    ...acc,
                    gId: settings[settingsKeys.gId].pkid || settings[settingsKeys.gId].id,
                    group: settings[settingsKeys.gId]
                }
            }
            case settingsKeys.countView: {
                return { ...acc, countView: settings[settingsKeys.countView] }
            }
            case settingsKeys.photoView: {
                return { ...acc, photoView: settings[settingsKeys.photoView] }
            }
            case settingsKeys.miniview: {
                return { ...acc, miniview: settings[settingsKeys.miniview] }
            }
            default:
                return acc
        }
    }, {} as { [s: string]: any });
};

const getWidgetsFromStructure = () => {
    
};