import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import actions from 'redux/actionsTypes/Widgets';

import { IDispatchProps } from '../../redux/connector';
import { prepareContext } from 'utils/src/utils';
import { Dispatch } from 'redux';
import { IStateType as IState } from 'redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { RouteComponentProps } from 'react-router';

export interface IFiltersProps extends IFiltersOwnProps, IClassNameProps, IFiltersStateProps, IFiltersDispatchProps, RouteComponentProps<IFiltersRouteProps> {
}

export interface IFiltersOwnProps {
  tag?: 'div';
  data: any[];
  context: IFilterContext;
  relations: string[];
}

export interface IFiltersRouteProps {
  
}

export interface IFiltersState {
  text?: string;
}

// export interface IFiltersStateProps {
//   // store: any
//   edit: boolean;
//   enabledModules: {[s: string]: any};
// }

export interface IFilterContext {
  filter?: Record<string, any>;
  [s: string]: any;
}

export const mapStateToProps = (state: IState, props: IFiltersOwnProps) => ({
  // store: state.store
  authUser: getAuthUser(state),
  itemsView: {
    isAdmin: getAuthUser(state).baseData.isAdmin,
    isSubnetworks: state.store.appSettings && Boolean(state.store.appSettings.parentNetwork),
    enableAutoRegistration: (state.store.appSettings && (state.store.appSettings.enableAutoRegistration || state.store.appSettings.enableAutoRegistrationWithTrustedDomains)) as boolean
  },
  edit: state.store.edit,
  enabledModules: state.store.appSettings ? state.store.appSettings.enabledModules : {}
  // context: prepareContext<IFilterContext>(state.widgets.context, props.contexts),
});

export type IFiltersStateProps = ReturnType<typeof mapStateToProps>

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeContext: (paths: string[], innerPath: string, value: any) =>
    dispatch({ type: 'CHANGE_CONTEXT', payload: { paths, innerPath, value } }),
  changeContextv3: (payload: Array<{[s: string]: any}>) =>
    dispatch({ type: actions.UPDATE_CONTEXTS, payload})
});

export type IFiltersDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnFilters = cn('Filters');
