import React, { FC } from 'react'
import Box from '@mui/material/Box'
import { cnCalendarIcon } from './CalendarIcon.index'
import { Calendar } from 'blocks/NewsTypes/Events/Calendar/Calendar'
import { formatDate } from '../utils'

export const CalendarIcon: FC<{ element: string }> = ({ element }) => {
    return <Box className={cnCalendarIcon({}, [])}>
        <Calendar startDateTime={new Date(`${element}`)} variant={formatDate(new Date()) === element ? 'red' : 'gray'} />
    </Box>
}