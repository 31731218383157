import React, { ComponentProps, useEffect, useMemo, useState } from 'react'
import { Calendar as BigCalendar } from 'react-big-calendar'
import { connect, useSelector } from 'react-redux'
import 'moment/locale/ru'
import './Calendar.scss'
import { cnCalendar, mapDispatchToProps, mapStateToProps, ICalendarProps, CalendarSearchParams } from './Calendar.index'
import { EventsList } from './EventsList/EventsList'
import { openEventsDialog } from './ShowMoreEventsDialog/ShowMoreEventsDialog'
import { CustomToolbar } from './CustomToolbar/CustomToolbar'
import { CustomEvent } from './CustomEvent/CustomEvent'
import i18n from 'localizations/i18n'
import { useSearchParams } from 'utils/src/hooks'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Typography from '@mui/material/Typography'
import { IconButton } from 'muicomponents/src/IconButton/IconButton'
import { useOpenEventCreator, isShowCreateButton, isToDay } from './utils'
import { momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { NewsCreatorButton } from 'News/creator/Creator/CreatorButton/CreatorButton'
import { baseModels } from 'News/creator/Creator/Creator.hooks'
import { calendarNewsCreatorId } from './Calendar.constants'
import { useCalenderNewsCreatorOptions, useCanCalendarCreateEvent } from './Calendar.hooks'

const localizer = momentLocalizer(moment);

const CalendarPresenter: React.FC<ICalendarProps> = props => {
  const openCreator = useOpenEventCreator()

  const [searchParams] = useSearchParams<CalendarSearchParams>()

  const {
    defaultDate,
    defaultView
  } = searchParams

  const { context, calendarEvents, loading, getCalendarEvents, currentCalendarDate, calFilters, language } = props
  const [isFirstRequest, setIsFirstRequest] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      if (isFirstRequest) {
        setIsFirstRequest(false);
        getCalendarEvents({ filter: context.filter?.value, cIds: context?.cIds, statusIds: context?.statusIds, currentCalendarDate });
        return;
      }
      getCalendarEvents({ filter: context.filter?.value, cIds: context?.cIds, statusIds: context?.statusIds, currentCalendarDate })
    }, 100)

  }, [context.filter, context.cIds, currentCalendarDate.slice(0, 7)]);

  const canCreateEvent = useCanCalendarCreateEvent(context);

  CustomToolbar.defaultProps = { context }

  return (
    <>
      {
        <Box className={cnCalendar()}>
          <Box sx={{ position: 'relative', margin: '24px' }} className={cnCalendar('Main')}>
            <BigCalendar
              culture={language}
              tooltipAccessor={() => ""}
              popup={true}
              messages={{
                showMore: total => {
                  return i18n.t('pryaniky.calendar.moreEvents', { total })
                }
              }}
              defaultDate={defaultDate && typeof defaultDate === 'string' && new Date(defaultDate) || undefined}
              defaultView={defaultView as any}
              onShowMore={(events, date) => openEventsDialog({ events, date }).then(() => { }).catch(() => { })}
              popupOffset={10}
              localizer={localizer}
              events={calendarEvents}
              components={{
                toolbar: CustomToolbar,
                event: CustomEvent as any,
                month: {
                  dateHeader: ({ children, label, ...props }: any) => {
                    const showCreateButton = isShowCreateButton(props.date);

                    const creatorOptions = useCalenderNewsCreatorOptions(props.date);

                    return <Box sx={{
                      display: 'flex',
                      justifyContent: showCreateButton ? 'space-between' : 'flex-end',
                      '&:hover': {
                        '& .OpenCreatorBtn': {
                          opacity: 1
                        }
                      }
                    }}>
                      {
                        canCreateEvent &&
                        showCreateButton &&
                        <NewsCreatorButton
                          newsType='events'
                          iconButton
                          color='primary'
                          size='small'
                          className={'OpenCreatorBtn'}
                          creatorOptions={creatorOptions}
                          creatorId={calendarNewsCreatorId}
                          groupId={context.gId}
                          workflowId={context.workflowId}
                          sx={{
                            opacity: 0
                          }}
                        >
                          <AddCircleOutlineIcon fontSize='small' />
                        </NewsCreatorButton>
                      }
                      <Typography variant='body2' sx={{ marginLeft: 'auto', marginTop: '4px', marginRight: '2px' }}>{label}</Typography>
                    </Box>
                  },
                  // event: () => <>event</>,
                  // header: () => <>header</>
                },
                dateCellWrapper: ({ children, value, ...props }: any) => {
                  return <Box sx={{
                    flex: '1 0',
                    display: 'flex'
                  }} className={`rbc-day-bg${isToDay(value) ? ' rbc-today' : ''}`}>
                    {/* <AddCircleOutlineIcon onClick={openCreator} /> */}
                  </Box>
                }
              }}
            />
            {loading && <CircularProgress
              size={45}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />}
          </Box>
        </Box>
      }
      <EventsList groupId={context.gId} workflowId={context.workflowId} />
    </>
  )
}

export const Calendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarPresenter)