import React, { FC, useEffect, useState } from 'react'
import { cnCourseStatistics, mapDispatchToProps, mapStateToProps } from './CourseStatistics.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './CourseStatistics.scss'
import { Selectize } from 'uielements/src';
import StudentsStatus from '../StudentsStatus'
import UnitsAttandance from '../UnitsAttandance'
import CommentsCount from '../CommentsCount'
import AllReports from '../AllReports'
import { TestResults } from '../TestResults/TestResults'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

export const CourseStatisticsPresenter: FC<any> = ({ className, cid }) => {
    const STAT_ELEMENTS = [
        {
            value: 'studentsstatus',
            title: tT('statistics.type.studentsstatus.1'),
            id: 'studentsstatus'
        },
        {
            value: 'unitsattendance',
            title: tT('statistics.type.unitsattendance.1'),
            id: 'unitsattendance'
        },
        {
            value: 'commentscount',
            title: tT('statistics.type.commentscount.1'),
            id: 'commentscount'
        },
        {
            value: 'testresults',
            title: tT('statistics.type.testresults.1'),
            id: 'testresults'
        },
        {
            value: 'allreports',
            title: tT('statistics.type.allreports.1'),
            id: 'allreports'
        }
    ]

    const [stats, setStats] = useState<string>('studentsstatus')
    useEffect(() => {

    }, [])

    const renders: { [key: string]: React.ReactNode } = {
        studentsstatus: <StudentsStatus cid={cid} />,
        unitsattendance: <UnitsAttandance cid={cid} />,
        allreports: <AllReports cid={cid} />,
        commentscount: <CommentsCount cid={cid} />,
        testresults: <TestResults cid={cid} />
    }

    return <div className={cnCourseStatistics({}, [className])}>
        <div className={cnCourseStatistics('Aside')}>
            <Selectize
                onClick={(value) => setStats(value.id)}
                name={tT('statistics.types.1')}
                active={stats}
                elements={STAT_ELEMENTS}
                children={[]} />
        </div>
        <div className={cnCourseStatistics('Section')}>
            {renders[stats]}
        </div>
    </div>
}

export const CourseStatistics = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CourseStatisticsPresenter));
