import { IClassNameProps } from '@bem-react/core';
import { getBadgesList } from 'utils/src/requests/requests.badges';

export namespace NBadgesList {

    export interface Props extends IClassNameProps {
        /**
         * selected tag id in widget settings
         */
        filter: string;
    }

    export interface State {
        list: Awaited<ReturnType<typeof getBadgesList>>['data'];
    }
}

export namespace NBadgesItem {

    type Data = Awaited<ReturnType<typeof getBadgesList>>['data'];

    export interface Props {
        data: Data[number]['items'][number];
        countColumns?: number
    }
}