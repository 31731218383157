import { NEW__useInfiniteScroll as useInfiniteScroll, useRefedCallback } from 'utils/src/hooks';
import React, { FC, useEffect, memo } from 'react'

export const useScrollList = (
    isLoading: boolean,
    isFinished: boolean,
    withError: boolean,
    resetDeps: any[],
    loadMoreHook: (deps: any) => (clean?: boolean) => void,
    loadMoreDeps: any
) => {
    const loadMore = useRefedCallback<(clean?: boolean) => void, any>(loadMoreHook, loadMoreDeps)

    useEffect(() => {
        loadMore(true)
    }, resetDeps)

    const triggerRef = useInfiniteScroll({
        loadMore,
        isLoading,
        isFinished,
        isError: withError
    });

    return {
        triggerRef,
        loadMore
    }
}