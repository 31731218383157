import { Badges } from 'blocks/Badges/Badges';
import { WidgetsTypes } from 'utils/src/widgets/types';
import { GridSize } from "muicomponents/src/Grid";
import { TColumn, TWidget } from 'utils/src/BaseTypes/widgets.types';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { TWidgetsTypeToDragOthersWidget } from './types';
import { TuplifyUnion } from './utils.types';
import { PageTypes, TPage } from 'utils/src/BaseTypes/pages.types';
import { baseNews } from 'News/creator/baseNews';
import { generateWidget } from './utils';

export const actionsBlockDataId = 'actions';

export const sizingByCount: { [key: number]: number } = {
    4: 3,
    3: 4,
    2: 6,
    1: 12
};

/**
 * base empty widget
 */
export const baseWidget: TWidget = {
    id: '',
    allowChangeSettings: false,
    authorId: GUID_EMPTY,
    data: null,
    description: '',
    errorLoading: false,
    errorLoadWidgetText: '',
    files: null,
    iconUrl: null,
    interfaces: [],
    isDeletable: true,
    isEditable: true,
    isHidden: false,
    relations: ['', ''],
    settings: null,
    title: '',
    trace: null,
    type: 'pryaniky/info',
    version: null
};

export const defaultPage: TPage = {
    allowComments: false,
    authors: [],
    breadCrumbs: [],
    changeDate: '',
    collectionAlias: null,
    collections: [],
    context: {
        appId: '',
        currentPageId: '',
        currentUserId: '',
        url: ''
    },
    creationDate: '',
    defaultViewType: null,
    editable: true,
    enableTwoFactorAuth: false,
    file: null,
    hasCustomTitleImage: false,
    hasOtherBreadCrumbsBranches: false,
    icon: '',
    id: '',
    isArchived: false,
    isDraft: false,
    isEditable: true,
    isLiked: false,
    isTemplate: false,
    layout: generateWidget('layout', {
        data: []
    }),
    moderators: [],
    news: baseNews,
    newsUid: '',
    notAllowedToView: false,
    positionInCollection: -1,
    registerNewWidgets: true,
    showInMainMenu: false,
    tags: [],
    title: '',
    titleImageId: null,
    titleImageUrl: '',
    type: PageTypes.page,
    url: '',
    versionId: null,
    versions: [],
    viewers: null,
    newsCommentsCount: 0,
    newsCommented:false,
    newsLikeCount: 0,
    newsLiked:false,
    newsId:'',
    pageType: null

};

/**
 * base empty column
 */
export const baseColumn: TColumn = {
    accessPolicy: null,
    disableEdit: false,
    icon: '',
    iconFile: null,
    id: '',
    isHidden: false,
    items: [],
    mobileHidden: false,
    name: '',
    styles: null,
    title: ''
};


const keysWidgetsTypesForManipulationWithChildWidgets: TWidgetsTypeToDragOthersWidget[] = [
    'layout',
    'layout/horizontal',
    'tabs',
    'tabsControl',
    'grid'
];

export const widgetTypesForManipulationWithChildWidgets = keysWidgetsTypesForManipulationWithChildWidgets.map(type => {
    return WidgetsTypes[type];
});


/**
 * widgets settings keys for convert to context
 */
export const settingsKeys = {
    rId: 'rating',
    count: 'count',
    countView: 'countView',
    type: 'type',
    name: 'name',
    gId: 'group',
    photoView: 'photoView',
    miniview: 'miniview',
    once: 'once'
    // gIds: 'group',
}