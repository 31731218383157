import React, { FC } from 'react'
import { cnUsers, mapDispatchToProps, mapStateToProps } from './Users.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Users.scss'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import withUserGroupsSuggester from 'blocks/HOCs/SearchSelectize/withUserGroupSuggester'
import { SearchSelectize } from 'uielements/src';

const UserSelectize = withUserGroupsSuggester(SearchSelectize)

export const UsersPresenter: FC<any> = ({ className, currentFilters, setModerationFilters }) => {

    const onClick = (items: any[]) => {
        setModerationFilters({ 'filter.filterByUserIds': items.map((user: any) => user.id) })
    }

    return <div className={cnUsers({}, [className])}>
        <UserSelectize
            onClick={onClick}
            name={tT('moderation.filters.users.1')}
        />
    </div>
}

export const Users = UsersPresenter/*connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UsersPresenter));*/
