import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { toast } from 'react-toastify';
import i18n from 'localizations/i18n';

import { cnNewsPostBlock, INewsPostBlockProps, INewsPostBlockState } from './NewsPostBlock.index';
import { NewsCreate } from '../NewsCreate/NewsCreate'
import './NewsPostBlock.scss';

export class NewsPostBlockPresenter extends React.Component<INewsPostBlockProps, INewsPostBlockState> {
  public utils: any = utils;
  public el: HTMLElement | null;

  constructor(props: INewsPostBlockProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {
      postTypes: [],
      activePost: undefined,
      hide: false,
    };
  }

  public render() {
    return (
      <div ref={el => this.el = el} className={cnNewsPostBlock({ hide: this.state.hide }, [this.props.className])}>
        {/*<PriorityNav elements={this.state.postTypes} activeElem={this.state.activePost} onChange={this.onChangeActiveElem} />*/}
        <NewsCreate gId={this.props.gId} />

        {
          /*(1*1 === 2) && <NewsPost
            pushNews={this.props.pushNews}
            gId={this.props.gId}
            type={this.state.activePost ? this.state.activePost.id : undefined}
            typeData={this.state.activePost} />*/
        }
      </div>
    )
  }

  /*private functionBind() {
    this.onChangeActiveElem = this.onChangeActiveElem.bind(this);
    this.getPostTypes = this.getPostTypes.bind(this);
  }*/

  /*private onChangeActiveElem = (el: any) => {
    if (this.state.activePost && el.id !== this.state.activePost.id) {
      this.setState({ activePost: el });
    }
  }*/

  /*private getPostTypes() {
    const opts = {
      gId: this.props.gId
    }
    this.utils.API.news.postTypes(opts)
      .r
      .then((d: any) => {
        if (this.utils.checkResponseStatus(d)) {
          if (d.data.length > 0) {
            const postTypes: any[] = [];
            d.data.forEach((e: any) => {
              e.title = i18n.t('pryaniky.post.' + e.id);
              if (e.id !== '') postTypes.push(e);
            })
            this.setState({
              hide: postTypes.length === 0,
              postTypes,
              activePost: postTypes[0] || undefined
            });
          } else {
            this.setState({ hide: true });
          }
        } else {
          toast.error('pryaniky.toast.error.server');
        }
      });
  }*/

}


export const NewsPostBlock = NewsPostBlockPresenter;
  //   compose(
  //     withBemMod(cnNewsPostBlock(), {})
  //     )(
  //       NewsPostBlockPresenter
  //   )
  // )
