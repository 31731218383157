import { cn } from '@bem-react/classname'
import { IReactionsItem } from '../Reactions.index'

// export interface IReactionsItemProps {
//     label?: string;
//     items: IReactionsItem[];
// }

export interface IReactionsItemProps {
    item: IReactionsItem,
    onSelect: any,
}

export const cnReactionsItem = cn('ReactionsItem')