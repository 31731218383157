/**
 * @packageDocumentation
 * @module Rating_mini
 */
import { IClassNameProps } from "@bem-react/core";
import { cn } from '@bem-react/classname';

export interface IRatingMiniProps extends IRatingMiniOwnProps, IClassNameProps {}

export interface IRatingMiniOwnProps {
  settings: {
    rId: number | string;
    count?: number;
    type?: 'stroke' | 'rounded';
    miniview?: boolean;
  },
  tag?: React.ComponentType;
}

export interface IRatingMiniState {
  rating?: {[s: string]: any};
  isSmall: boolean;
  data: any[];
}

export const cnRatingMini = cn('RatingMini');