
import React, { FC, useState, useCallback, ReactNode, memo, useEffect, useMemo } from 'react'
import { IDialogNewsViewProps } from './DialogNewsView.index'
import './DialogNewsView.scss'
import { createModal, TransitionComponent, TDialogProps } from 'utils/src/DialogCreator'
import NewsItem from 'News/containers/NewsItem'
import { IBaseNews } from 'News/types/baseNews'
import DialogView from 'News/containers/DialogView'
import { PostSkeleton } from 'blocks/Skeletons/Posts/Post-Skeleton';
import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg'
import {
    useLazyLoadNewsById
} from 'News/utils/hooks'
import {
    useLocation,
    useParams,
    useHistory
} from "react-router-dom";
import { matchPath } from "react-router";
import { getLocationStateFrom } from 'utils.project/utils.project'
import urls from 'routes/urls'


type TDecoreatorProps = { className?: string, children?: ReactNode }
export const DialogNewsViewPresenter: FC<IDialogNewsViewProps> = ({
    newsId,
    editOnly,
    handleAccept,
    handleClose,
    RenderComponent = DialogView,
    disableSingle = false,
    preventClose
}) => {

    const location = useLocation();

    // load full news if dialog opened from search page
    const isFromSearchPage = useMemo(() => {
        if(location.state) {
            const from = getLocationStateFrom(location.state);
            const isFromSearchPage = matchPath(from.pathname, {
                path: urls.search,
                strict: true
            });
            return !!isFromSearchPage;
        }
        return false;
    }, [location.state]);

    const [
        news,
        isLoading,
        onLoad,
        { errors, isFailed }
    ] = useLazyLoadNewsById(newsId)

    const onClose = useCallback(() => {
        if (preventClose) preventClose(handleClose)
        else handleClose()
    }, [])

    useEffect(() => {
        if (!news || isFromSearchPage) onLoad();
    }, [])

    if (isLoading) return <PostSkeleton />
    if (isFailed) {
        console.error('load news error: ', errors)
        return <ErrorMsg onCancle={handleClose}>load news error</ErrorMsg>
    }

    const Decortor: FC<TDecoreatorProps> = memo((props) => <RenderComponent
        {...props}
        editOnly={editOnly}
        handleClose={onClose}
        handleAccept={handleAccept}
    />)
    return <NewsItem id={newsId} single={!disableSingle} ViewComponent={Decortor} />
}

export const defaultParams: TDialogProps = {
    maxWidth: 'md',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: 'transparent',
        }
    },
    TransitionComponent,
    scroll: 'body'//'paper'//
}

const openDialog = createModal<IBaseNews, IDialogNewsViewProps>(DialogNewsViewPresenter, defaultParams);

export const NewsDialogComponent: FC<{ from?: Location }> = ({ from }) => {
    const { id } = useParams<{ id?: string }>()
    const { goBack } = useHistory()
    const onClose: IDialogNewsViewProps['preventClose'] = (handleClose) => {
        handleClose()
        goBack()
    }
    useEffect(() => {
        id && openDialog({ newsId: id }, { ...defaultParams, preventClose: onClose })
    }, [id])
    return null
}

export default openDialog