import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { cnSecretSantaWidget, mapDispatchToProps, mapStateToProps, ISecretSantaWidget } from './SecretSantaWidgetItem.index'
import { Button, Avatar, Icon, Loading } from 'uielements/src'
import i18n from 'localizations/i18n'
import './SecretSantaWidgetItem.scss'
import { Translate } from 'localizations/Translate';
import { secretSantaDialog } from '../../Dialogs/SecretSantaDialog/SecretSantaParticipate';
import MDRender from 'uielements/src/CommonmarkRender';
import { DistributeTimer } from '../../SecretSantaWidget/DistributeTimer/DistributeTimer'
import * as utils from 'utils.project/utils.project'
import BaseRequests from 'utils/src/requests/requests.base';
import { Button as MuiButton } from 'uielements/src/MaterialElements/Button/Button';
import { useDidUpdateEffect } from 'utils/src/hooks'
import { CircularProgress } from '@material-ui/core';
import { Box } from "muicomponents/src"
let interval: NodeJS.Timeout;

const SecretSantaWidgetItemPresenter = (props: ISecretSantaWidget) => {
  const {
    // tag: Tag = 'div',
    getSecretSantaWidgetSettingsAction,
    isUserParticipateAction,
    secretSantaJoinAction,
    getUserToGivePresent,
    isPresentSent,
    presentSentAction,
    setUserDistributedToggleAction,
    setIsPresentSentAction,
    secretSantaUnJoinAction,
    mainSecretSantaSettingsAction,
    removeItemIfPresentSend,
    isPresentSendData,
    isParticipate,
    data,
    edit,
    userForPresent,
    uIsAdmin,
    isDistributLoading,
    settings,
    isListWidget,
    isLoading
  } = props

  const [updateData, setUpdateData] = useState<boolean>(false)
  const [showUser, setShowUser] = useState<null | boolean>(false)
  const [loading, setLoading] = useState<null | boolean>(false)
  const [partitipants, setPartitipants] = React.useState<string>("")

  useEffect(() => {
    !data && mainSecretSantaSettingsAction(settings?.type?.id)

  }, [data])


  useDidUpdateEffect(() => {
    if (updateData === false) {
      return;
    }

    setTimeout(() => {

      mainSecretSantaSettingsAction(settings?.type?.id);

      setUpdateData(false)

    }, 6000);

  }, [updateData])

  useEffect(() => {
    data && getRightFormOfWord(data.participantsCount)
  }, [data])

  useEffect(() => {

    clearInterval(interval);

  }, [isDistributLoading === false])

  useEffect(() => {
    if (data) {
      // это проверка на то что юзер не пришел ранее, если изначально его не было, то запускаем запрос юзера
      if (!userForPresent && isParticipate) {
        interval = setInterval(() => getUserToGivePresent({
          queryString: {
            drawId: data.id,

          }
        }), 60000);
      }

      (isParticipate && data.state === 'Distributed' && data.id) && isPresentSent({
        queryString: {
          drawId: data.id,

        }
      })
    }

  }, [(data && data.state === 'Distributed') && isParticipate])

  useEffect(() => {
    if (data) {
      if (isPresentSendData && removeItemIfPresentSend) {
        removeItemIfPresentSend([data])
      }
     
    }

  }, [(data && data.state === 'Distributed') && isPresentSendData])


  const msg = userForPresent && userForPresent.descriptionGift.deliveryComment.replace(/\<br\/?\>/g, '') || "";

  const descS = data && data.description.replace(/\<br\/?\>/g, '') || "";

  const isDistribute = useCallback(() => {
    if (!data) return;
    var today = new Date().valueOf()
    var distribution = new Date(data.distributionDate).valueOf()
    var teaserEnd = new Date(data.startRegistrationDate).valueOf()
    var registrationEnd = new Date(data.endRegistrationDate).valueOf()

    if ((teaserEnd < today) && data.state === "Teaser") {
      setUpdateData(true)
    };
    if ((registrationEnd < today) && data.state === "Participation") {
      setUpdateData(true)
    };

    if ((distribution < today) && data.state === "WaitingDistribution") {
      !userForPresent && setUserDistributedToggleAction({ data: { variable: "isDistributLoading", value: true }, id: settings?.type?.id })
      setUpdateData(true)
    };

  }, [data]);

  const getRightFormOfWord = (people: number) => {

    if (people > 21) {
      const wordLength = people.toString().length
      const getLastNumber = Number(people.toString()[wordLength - 1])
      if (getLastNumber > 4 || getLastNumber === 0 || getLastNumber === 1) {
        return setPartitipants("plural")
      } else if (getLastNumber > 1 && getLastNumber < 5) {
        return setPartitipants("akk")
      }
    } else if ((people > 4 && people < 21) || people === 0 || people === 1) {
      return setPartitipants("plural")
    } else if (people > 1 && people < 5) {
      return setPartitipants("akk")
    }

  }

  return (
    isLoading ? <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
      className={cnSecretSantaWidget()}>
      <CircularProgress />
    </Box> : (data && isPresentSendData === false) ? <div className={cnSecretSantaWidget()}>
      {!isListWidget ?
        !showUser &&
        <div className={cnSecretSantaWidget('Header-Title')}>
          <Button
            type='rlink'
            theme="unstyled"
            href={`/secretsanta/${settings?.type?.id}`}
            target="_blank"

          >
            <span>{data.displayName}</span>
          </Button>
        </div> : undefined}

      {showUser && <div className={cnSecretSantaWidget('Header-TitlePresent')}>
        <span><Translate i18nKey='pryaniky.secret.santa.widget.you.are.santa.for' /></span>
      </div>}
      <div className={cnSecretSantaWidget('Header')}>

        <div className={cnSecretSantaWidget('Header-Icon')}>
          <Button
            type='rlink'
            theme="unstyled"
            href={`/secretsanta/${settings?.type?.id}`}
            target="_blank"

          >
            <Avatar precentage={100} shape={'rectangle'}
              imgUrl={data.iconUrl ? data.iconUrl : BaseRequests.getUrl(`/Content/img/design/adminbase/secretSanta.svg`)}
            />
          </Button>
        </div>
        {/* ТАЙМЕР */}
        {(data.state === 'Teaser' || data.state === 'Participation' || data.state === "WaitingDistribution") && <div className={cnSecretSantaWidget('AdditionalInfo-Date')}>
          <div className={cnSecretSantaWidget('AdditionalInfo-Timer')}>
            <span className={cnSecretSantaWidget('AdditionalInfo-Timer-Span')}>
              {data.state === 'Teaser' && <Translate i18nKey='pryaniky.secret.santa.widget.teaser' />}
              {data.state === 'Participation' && <Translate i18nKey='pryaniky.secret.santa.widget.participation' />}
              {data.state === "WaitingDistribution" && <Translate i18nKey='pryaniky.secret.santa.widget.waitingDistribution' />}

            </span>
            {updateData && <CircularProgress size={15} />}
            {data.state === 'Teaser' && <DistributeTimer
              shouldByDistribute={isDistribute}
              distibDate={data.startRegistrationDate} />}
            {data.state === "Participation" && <DistributeTimer
              shouldByDistribute={isDistribute}
              distibDate={data.endRegistrationDate} />}
            {data.state === 'WaitingDistribution' && <DistributeTimer
              shouldByDistribute={isDistribute}
              distibDate={data.distributionDate} />}

          </div>

          {(data.state === 'Participation' || data.state === 'WaitingDistribution') && data.participantsCount > 0 && <Button
            type='rlink'
            theme="unstyled"
            href={`/secretsanta/${settings?.type?.id}`}
            target="_blank"

          >
            <div className={cnSecretSantaWidget('AdditionalInfo-Info')}>
              <span className={cnSecretSantaWidget('AdditionalInfo-Span')}>
                <Translate i18nKey='pryaniky.secret.santa.widget.participantsCount' />
              </span>
              <span className={cnSecretSantaWidget('AdditionalInfo-participantsCount')}>
                {data.participantsCount}

                {partitipants === "plural" && <Translate i18nKey='pryaniky.secret.santa.widget.participants.plural' />}
                {partitipants === "akk" && <Translate i18nKey='pryaniky.secret.santa.widget.participants.akk' />}
              </span>
            </div>
          </Button>}
        </div>}



      </div>

      <div className={cnSecretSantaWidget('AdditionalInfo')}>
        {(data.state === 'Teaser' || data.state === 'Participation') && <div className={cnSecretSantaWidget('AdditionalInfo-Description')}>
          <p><MDRender source={`“${descS}”`} /></p>
        </div>}

        {/* лоадинг до того как юзре будет действительно получен, должен срабатиывать в случае задержки от сервера */}
        {(data.state === 'Distributed' && isDistributLoading) &&
          <div className={cnSecretSantaWidget('AdditionalInfo-DestributionOver-Loading')}>
            <Loading text={i18n.t("pryaniky.secret.santa.widget.distributionDate.over.loading")} />
          </div>}

        {/* сообжение о том, что жеребъевка окончена появляется после того как юзер получен от сервера */}
        {(data.state === 'Distributed' && !isDistributLoading && !showUser) &&
          <div className={cnSecretSantaWidget('AdditionalInfo-DestributionOver')}>
            <Translate i18nKey='pryaniky.secret.santa.widget.distributionDate.over' />
          </div>}


      </div>


      {showUser && userForPresent && <div className={cnSecretSantaWidget('UserAdditionalInfo')}>
        <div className={cnSecretSantaWidget('UserAdditionalInfo-Description')}>
          <Button
            type='rlink'
            theme="unstyled"
            href={'/user/' + userForPresent.user.id}
            target="_blank"
            className={cnSecretSantaWidget('UserAdditionalInfo-Avatar')}>
            <Avatar size={32} shape={'circle'}
              imgUrl={userForPresent.user.userPhotoUrl}
            />
            <span>{userForPresent.user.displayName}</span>
          </Button>
        </div>
        <div className={cnSecretSantaWidget('UserAdditionalInfo-Description')}>
          {userForPresent.descriptionGift.deliveryComment && <> <MDRender source={`“${msg}”`} /></>}
        </div>
        <div className={cnSecretSantaWidget('UserAdditionalInfo-Description-Box')}>
          <div className={cnSecretSantaWidget('UserAdditionalInfo-Info')}>
            <span> <Translate i18nKey='pryaniky.secret.santa.widget.deliveryAddress' /></span>
            <p>{userForPresent.descriptionGift.deliveryAddress}</p>
          </div>

          {userForPresent.descriptionGift.deliveryPhone && <div className={cnSecretSantaWidget('UserAdditionalInfo-Info')}>
            <span> <Translate i18nKey='pryaniky.secret.santa.widget.deliveryPhone' /></span>
            <p>{userForPresent.descriptionGift.deliveryPhone}</p>
          </div>}
        </div>
      </div>}
      <div className={cnSecretSantaWidget('SaveButton')}>

        {/* кнопка которая покажет юзера, появляется после того как юзер получен от сервера */}
        {(data.state === 'Distributed' && isParticipate === true && !isDistributLoading && !showUser) &&
          <MuiButton className={cnSecretSantaWidget("SaveButton-Submit")}
            variant={'contained'}
            color={'primary'}
            size={'medium'} onClick={() => setShowUser(true)}>
            {i18n.t("pryaniky.secret.santa.widget.get.user.to.give.a.present")}
          </MuiButton>}

        {(data.state === 'Distributed' && showUser) &&
          <MuiButton className={cnSecretSantaWidget("SaveButton-Submit")}
            variant={'contained'}
            color={'primary'}
            size={'medium'}
            onClick={() => {
              utils.confirm({
                text: isListWidget ? i18n.t('pryaniky.secret.santa.widget.widget.list.would.hidden') : i18n.t('pryaniky.secret.santa.widget.widget.would.hidden'),
                title: i18n.t('pryaniky.secret.santa.widget.widget.would.hidden.title'),
                onConfirm: () => {
                  presentSentAction(settings?.type?.id)
                }
              })
            }}>
            {i18n.t("pryaniky.secret.santa.widget.present.send")}
          </MuiButton>}

        {(isParticipate !== null && !isParticipate && data.state === 'Participation') &&
          <MuiButton
            className={cnSecretSantaWidget("SaveButton-Submit")}
            variant={'contained'}
            color={'primary'}
            size={'medium'}
            onClick={() => secretSantaDialog({ isPartitipant: false, id: settings?.type?.id }).then(() => {
              secretSantaJoinAction(settings?.type?.id)
            }).catch(() => { })

            }>
            {i18n.t("pryaniky.secret.santa.widget.save")}
          </MuiButton>
        }
        {(isParticipate && data.state === 'Participation') && <MuiButton
          className={cnSecretSantaWidget("SaveButton-Submit")}
          variant={'contained'}
          color={'primary'}
          size={'medium'}
          onClick={() => secretSantaDialog({ isPartitipant: isParticipate, id: settings?.type?.id }).then(() => {
            secretSantaJoinAction(settings?.type?.id)
          }).catch(() => { })

          }>
            {/* <p><MDRender source={`“${descS}”`} /></p> */}
          {i18n.t("pryaniky.secret.santa.widget.edit")}
        </MuiButton>}
        {(isParticipate && data.state === 'Participation') && <MuiButton
          className={cnSecretSantaWidget('SaveButton-Unparticipate')}

          color={'default'}
          size={'medium'}
          // onClick={() => {
          //   secretSantaUnJoinAction()
          // }}
          onClick={() => {
            utils.confirm({
              text: i18n.t('pryaniky.secret.santa.widget.unjoin.text'),
              title: i18n.t('pryaniky.secret.santa.widget.unjoin.title'),
              onConfirm: () => {
                secretSantaUnJoinAction(settings?.type?.id)
              }
            })
          }}
        >
          <Translate i18nKey='pryaniky.secret.santa.unparticipate' />
        </MuiButton>}
      </div>
    </div> : null
  )
}

export const SecretSantaWidgetItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(SecretSantaWidgetItemPresenter)