import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import {
    // nextActiveQuestion,
    setFinishedSurvey,
    sendAnswer,
    setIsShowResult,
    setSurveyAnswersIds,
    setAnswerId,
    setRowAnswers,
    // setRowAnswerId
} from 'redux/sagas/Surveys/actions'
import { getSurveysQuestionsItemSelector } from 'redux/sagas/Surveys/selectors'


export interface ISurveyPassing extends ISurveyPassingStateProps, ISurveyPassingDispatchProps {
    surveyId: string
    highlightCorrectness: boolean
    preview: boolean
}

export const mapStateToProps = (state: IState) => {
    // const qId = state.survey.survey.openSurveyData.questions[state.survey.survey.activeQuistionIdx].id;
    /**
     * @todo refactoring
     */
    const data = state.survey.survey.openSurveyData.questions.find((el: any) => el.id === state.survey.survey.activeQuistionId);
    const activeSurveyIdx = state.survey.survey.openSurveyData.questions.findIndex((el: any) => el.id === state.survey.survey.activeQuistionId);
    return {
        data,
        // data: state.survey.survey.openSurveyData.questions[state.survey.survey.activeQuistionIdx],
        totalQuestions: state.survey.survey.openSurveyData.questions.length,
        activeSurveyIdx,
        // activeSurveyIdx: state.survey.survey.activeQuistionIdx,
        answersIds: state.survey.survey.answersIds,
        answered: state.survey.survey.answered,
        validMatrix: state.survey.survey.validMatrix,
        rows: state.survey.survey.rows,
        textColor: state.survey.survey.openSurveyData.textColorString,
        questionSortingData: getSurveysQuestionsItemSelector(state.survey.survey.activeQuistionId)(state)
    }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    // nextActiveQuestion,
    sendAnswer,
    setFinishedSurvey,
    setIsShowResult,
    setAnswerId,
    setSurveyAnswersIds,
    setRowAnswers
    // setRowAnswerId

}, dispatch);

export type ISurveyPassingStateProps = ReturnType<typeof mapStateToProps>
export type ISurveyPassingDispatchProps = ReturnType<typeof mapDispatchToProps>








export const cnSurveyPassing = cn('SurveyPassingMui');

export type SurveyPassingBaseProps = {
    preview?: boolean;
}

export type SurveyPassingProps = SurveyPassingBaseProps & {
};