import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { routerActions } from 'connected-react-router';
// import { ICourse } from '../mock';
// import { loadCategories } from "../../../redux/actions/LMS";

export interface IUnitListProps extends IClassNameProps, IUnitListStateProps, IDispatchProps, IUnitListDispatchProps {
    tag?: 'div';
    sid: string;
    cid: string;
    activeId?: string[];
}

export interface IUnitListState {

}

export interface IUnitListStateProps {
    // categories: any;
    // cIds: string[]
}

export const mapStateToProps = (state: any, props: any) => {
    const userSessionsResults = state.LMS.userSessionsResults[props.sid]
    return ({
        units: state.LMS.courses.entities.values[props.cid].courseUnits,
        unitsResults: userSessionsResults ? userSessionsResults.unitsResult : undefined
    })
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    changeLocation: routerActions.push
}, dispatch);

export type IUnitListDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnUnitList = cn('LMSUnitList');