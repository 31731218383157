import Component, { Presenter } from './component';
import {
    LMSTO,
    cnClassName
} from './interfaces';

export {
    cnClassName,
    Presenter
}

export type IComponentProps = LMSTO.StudentsList.UnitsFilter.IOwnProps;
export type IComponentPresenterProps = LMSTO.StudentsList.UnitsFilter.IPresenterProps;


export default Component