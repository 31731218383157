import { ReactNode } from 'react';
import { BaseNews } from '../NewsTypes/BaseType'
import { Rule } from '../NewsTypes/Base.validate'
import { IPriorityNavTab } from "blocks/PriorityNav/PriorityNav.index";
// import { createNewsRequest } from "../../redux/actions/News";
import {
    sendNews,
    pathCreator
} from 'News/redux/actions'
import {
    getClearCreator,
    getSendError,
    getCreator,
    isSending
} from 'News/redux/saga/selectors'
import { INewsCreator } from 'News/redux/reducer/types'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { loadAllowPostTypes } from '../../redux/actions/AppSettings'
import { getCurrentAllowPostTypes, getAllowPostError } from '../../redux/sagas/AppSettings/selectors'
import { cn } from '@bem-react/classname';
export const cnCreateNews = cn('CreateNews');

export interface INewsCreatePropsType extends IDispatchNews, ReturnType<typeof mapStateToProps> {
    gId: number;
    className?: string;
    oneNewsType?: string;
    workflowId?: string;
    onSendComplete?: (success: boolean) => void
    cancleEdit?: () => void;
}

export interface INewsCreateStateType {
    activePostId?: number;

    validationErrors: Rule[];

    gId: number;

    workflowId?: string;

    group: any | null
}

export const mapStateToProps = (state: any, props: any) => ({
    // userData: getAuthUser(state),
    // maxFileSize: state.store.appSettings.maxFileSizeLimit,
    // denyMIME: state.store.appSettings.denyMIME,
    showPostFormInDialog: state.store.appSettings.showPostFormInDialog,
    postTypes: getCurrentAllowPostTypes(props.gId, props.workflowId)(state),
    postError: getAllowPostError(state)
    // i18nNamespace: state.i18n.namespace,
    // ...getCreator(state),
    // sending: isSending(state)
    // clear: getClearCreator(state),
    // sendError: getSendError(state)
})

export interface IDispatchNews {
    // sendNews: typeof sendNews;
    loadAllowPostTypes: any;
    // pathCreator: typeof pathCreator;
}

export const mapDispatchToProps = {
    // sendNews,
    loadAllowPostTypes,
    // pathCreator
};
