import React, { FC } from 'react'
import { Skeleton } from 'uielements/src'
import './Course.scss'
import { cn } from '@bem-react/classname';
// import { CourseHeader } from '../CourseHeader/CourseHeader'

export const cnCourse = cn('SkeletCourseItem');

export const Course: FC<any> = ({ className }) => {

    return <div className="Courses-Item">
        <img src="/Img/Icon/400x400/transparent/cccccc/graduation-cap.png" className="Courses-Item-Image" />
        <div className="Courses-Item-Info">
            <h3 className="Courses-Item-Title"><Skeleton shape="line" size={"xl"} style={{ width: '130px' }} /></h3>
            <div className="Courses-Item-Section">
                <div className="Courses-Item-UnitCount"><Skeleton shape="line" size={"sm"} style={{ width: '80px' }} /></div>
                <div className="Deadlines Courses-Item-Deadlines">
                    <Skeleton shape="line" size={"sm"} style={{ width: '150px' }} />
                </div>
            </div>
            <div className="CourseProgress Courses-Item-Progress">
                <div className="CourseProgress-Bar">
                    <div className="CourseProgress-Item"></div>
                </div>
            </div>
            <div className="AuthorsList Courses-Item-AuthorsList">
                <div className="AuthorsList-Wrapper">
                    <div className="AuthorItem AuthorsList-Author" style={{ zIndex: 0 }}>
                        <a className="Button Button_theme_unstyled Button_type_rlink AuthorItem-Avatar" href="/user/563d8108-f44d-4300-b742-2b90f34cdd4f">
                            <Skeleton shape="circle" style={{ width: '56px', height: '56px' }} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="Courses-Item-ActionBox">
            <div className="Action-Like LikeAction Courses-Item-Like">
                <Skeleton shape="line" size={"sm"} style={{ width: '80px' }} />
            </div>
            <Skeleton className={'Courses-Item-Start'} shape="line" size={"xl"} style={{ width: '80px' }} />
        </div>
    </div>
}

