import actions from 'redux/actionsTypes/Lists';
import { IListActionsPayload } from '../actions/Lists';
import { IStateType as IState } from 'redux/store';
import { REDUSERS_NAMES } from 'utils/src/constants.prn';
import { union } from 'lodash';

interface IListsAction {
    type: string;
    payload: IListActionsPayload<any>
}

export interface ILists {
    [s: string]: IList,
}

export interface IList {
    items: {
        [s: string]: {
            [s: string]: any;
        },
    };
    data: string[];
    filteredData: string[];
    selected: string[];
    search: string;
    clearData: boolean;
    hideData: boolean;
    isLoading: boolean;
    isFinished: boolean;
    rootLevelElCounts: number;
    additionalParams?: any,
    [s: string]: any;
}

const defaultLists: ILists = {};
const defaultList: IList = {
    items: {},
    data: [],
    filteredData: [],
    selected: [],
    search: '',
    clearData: false,
    hideData: false,
    isLoading: false,
    isFinished: false,
    rootLevelElCounts: 0,
};

function getIdsForHideIncludeSublevel(item: IList, data: string): string[] {
    return Object.keys(item.items).reduce((acc, iId) => {
        if (item.items[iId].parentGroupId && item.items[iId].parentGroupId.toString() === data.toString()) {
            return [...acc, iId, ...(item.items[iId].tree_view.sublevelShow ? getIdsForHideIncludeSublevel(item, iId) : [])];
        }
        return acc;
    }, [] as string[]);
}

export function lists(state: ILists = defaultLists, action: IListsAction): ILists {
    switch (action.type) {

        case actions.TOGGLE: {
            const { id, data } = action.payload;
            let item = state[id];
            if (!item) item = defaultList;
            return {
                ...state,
                [id]: {
                    ...item,
                    ...data
                }
            }
        }

        case actions.SET_DATA: {
            const { id } = action.payload;
            const data = action.payload.data as any[];
            if (!state[id]) state[id] = defaultList;
            return {
                ...state,
                [id]: {
                    ...state[id],
                    data: [
                        ...data.reduce((acc, cur) => acc.concat(cur), [])
                    ],
                    items: {
                        ...data.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})
                    }
                }
            }
        }

        case actions.ADD_DATA: {
            const { id } = action.payload;
            let item = state[id];
            const data = action.payload.data as any[];
            if (!item) item = defaultList;
            const dataVariable = item.hideData || item.filteredData.length || item.search ? 'filteredData' : 'data';
            return {
                ...state,
                [id]: {
                    ...item,
                    [dataVariable]: union(item[dataVariable], data.reduce((acc, cur) => acc.concat(cur.id), [])),
                    items: {
                        ...item.items,
                        ...data.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})
                    }
                }
            }
        }

        case actions.ADD_HEADER: {
            const { id } = action.payload;
            let item = state[id];
            const data = action.payload.data as any[];
            if (!item) item = defaultList;
            // const dataVariable = item.hideData || item.filteredData.length || item.search ? 'filteredData' : 'data';
            return {
                ...state,
                [id]: {
                    ...item,
                    items: {
                        ...item.items,
                        ...data.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})
                    }
                }
            }
        }

        case actions.REMOVE_DATA: {
            const { id } = action.payload;
            let item = state[id];
            const data = action.payload.data.map((el: any) => el.toString()) as string[];
            const dataVariable = item.hideData || item.filteredData.length || item.search ? 'filteredData' : 'data';
            const additionalParams = state[id].additionalParams ? {
                ...state[id].additionalParams,
                count: state[id].additionalParams.count ? state[id].additionalParams.count - data.length : undefined
            } : undefined
            return {
                ...state,
                [id]: {
                    ...item,
                    [dataVariable]: state[id][dataVariable].filter(el => !data.includes(el.toString())),
                    selected: state[id].selected.filter(v => !data.includes(v.toString())),
                    additionalParams,
                    items: Object.keys(state[id].items).reduce((acc, cur) => {
                        if (!data.includes(cur.toString())) return { ...acc, [cur]: item.items[cur] }
                        return acc
                    }, {})
                }
            }
        }

        case actions.CLEAR_DATA: {
            const { id } = action.payload;
            let item = state[id];
            const data = action.payload.data as string;
            const dataVariable = item.hideData || item.filteredData.length || item.search ? 'filteredData' : 'data';
            return {
                ...state,
                [id]: {
                    ...state[id],
                    [dataVariable]: [],
                    // selected: []
                }
            }
        }

        case actions.KILL: {
            const { id } = action.payload;
            delete state[id];
            return state;
        }

        case actions.CHECK_ITEM: {
            const { id } = action.payload;
            const data = action.payload.data as string;
            return {
                ...state,
                [id]: {
                    ...state[id],
                    items: {
                        ...state[id].items,
                        [data]: {
                            ...state[id].items[data],
                            checked: !state[id].items[data].checked
                        }
                    },
                    selected: !state[id].items[data].checked ? state[id].selected.concat(data) : state[id].selected.filter((el) => data !== el),
                }
            }
        }

        case actions.CHECK_ALL_ITEMS: {
            const { id } = action.payload;
            let item = state[id];
            const dataVariable = item.hideData || item.filteredData.length || item.search ? 'filteredData' : 'data';
            const checked = state[id][dataVariable].length !== state[id].selected.length;
            Object.keys(state[id].items).forEach((key) => {
                state[id].items[key].checked = checked;
            });
            return {
                ...state,
                [id]: {
                    ...state[id],
                    selected: checked ? state[id][dataVariable] : [],
                }
            }
        }

        case actions.CHANGE_ITEM: {
            const { id } = action.payload;
            const data = action.payload.data as { item: string, data: any };
            return {
                ...state,
                [id]: {
                    ...state[id],
                    items: {
                        ...state[id].items,
                        [data.item]: {
                            ...state[id].items[data.item],
                            ...data.data
                        }
                    }
                }
            }
        }

        case actions.ADD_SUBITEMS: {
            const { id } = action.payload;
            let item = state[id];
            const data = action.payload.data as { parentId: string, items: any[] };
            const dataVariable = item.hideData || item.filteredData.length || item.search ? 'filteredData' : 'data';
            item[dataVariable].splice(item[dataVariable].findIndex((el) => el === data.parentId) + 1, 0, ...data.items.map((el) => el.id));
            return {
                ...state,
                [id]: {
                    ...item,
                    items: {
                        ...item.items,
                        ...data.items.reduce((acc, cur) => ({ ...acc, [cur.id]: { ...cur, parentGroupId: data.parentId } }), {})
                    }
                }
            }
        }

        case actions.REMOVE_SUBITEMS: {
            const { id } = action.payload;
            const item = state[id];
            const data = action.payload.data as string;
            const idsForHide = getIdsForHideIncludeSublevel(item, data);
            idsForHide.forEach(id => {
                if (item.items[id].checked) {
                    item.items[id].checked = false;
                    item.selected.splice(item.selected.findIndex(sId => sId === id), 1);
                }
            });
            const dataVariable = item.hideData || item.filteredData.length || item.search ? 'filteredData' : 'data';
            const idsIndexesForHide = idsForHide.map((id) => item[dataVariable].findIndex(dId => dId.toString() === id.toString()));
            const min = Math.min.apply(Math, idsIndexesForHide);
            const max = Math.max.apply(Math, idsIndexesForHide);
            item[dataVariable].splice(min, max - min === 0 ? 1 : max - min + 1);
            return {
                ...state,
                [id]: {
                    ...item
                }
            };
        }

        case actions.CHANGE_ROOT_EL_COUNT: {
            const { id } = action.payload;
            const item = state[id];
            const data = action.payload.data as number;
            // item.rootLevelElCounts = data;
            return {
                ...state,
                [id]: {
                    ...item,
                    rootLevelElCounts: data
                }
            }
        }

        case actions.SET_ADDITIONAL_DATA: {
            const { id } = action.payload;
            const item = state[id];
            const data = action.payload.data as any;
            return {
                ...state,
                [id]: {
                    ...item,
                    additionalParams: {
                        ...item.additionalParams,
                        ...data,
                    }
                }
            }
        }

        case actions.CLEAR_SELECTED_ITEMS: {
            const { id } = action.payload;
            return {
                ...state,
                [id]: {
                    ...state[id],
                    selected: [],
                }
            }
        }

        default:
            return state;
    }
}