import { IClassNameProps } from '@bem-react/core';
import { cnNewsView } from '../NewsView.index';

import { match } from 'react-router';

export interface ITagType {
  id: string;
  displayName: string;
}
export interface ITagsProps extends IClassNameProps {
  tag?: 'div';
  className?: string;
  store?: any;
  changeVm?: any;
  match?: match<{}>;
  tags: ITagType[];
}

export interface ITagsState {
  text?: string;
}

export const cnTags = (...data: any) => {
  let name = 'Tags';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnNewsView(name, ...data)
}

// ***********костыли для типов:-)
export const convertTags = (objArr: any[] = []) => {
  const temp: ITagType[] = [];
  if(objArr === null) return temp;
  objArr.forEach((value: any) => {
    temp.push({
      displayName: value.displayName,
      id: value.id
    });
  });
  return temp;
};