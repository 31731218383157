import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { loadFullUnit } from '../../../../redux/actions/LMS'

export interface IItemProps extends IClassNameProps, IItemStateProps, IDispatchProps, ReturnType<typeof mapDispatchToProps> {
    unit: any;
    sid: string;
    cid: string;
    userId: string;
}

export interface IItemStateProps {
    unitContent?: any
}

export const mapStateToProps = (state: any, props: any) => {
    return ({
        unitContent: state.LMS.units[props.unit.unitId],
    })
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadFullUnit
}, dispatch);

export const cnItem = cn('TOResultsUnitItem');