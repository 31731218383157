import { IStateType as IState } from 'redux/store';

export interface IDialog {
  id?: string;
  isShown: boolean;
  onClose: () => void;
  onConfirm?: (...args: any) => void;
  onChange?: (data: any) => void;
  data: any;
}

export interface IDialogProps extends IDialogOwnProps, ImapStateToProps {
  name: string;
}

export interface IDialogOwnProps {
  id: string;
  onClose: () => void;
  children: (props: any) => any;
  onConfirm?: (...args: any) => void;
}

export const mapStateToProps = (state: IState, props: IDialogOwnProps) => ({
  dataR: state.modals[props.id || ''],
});

export type ImapStateToProps = ReturnType<typeof mapStateToProps>