
import { cn } from '@bem-react/classname';
import { PageTypes } from 'utils/src';

export interface IPreListFiltersProps {
    context: any;
    widget: any;
    hasRights: boolean;
    isAdmin: boolean;
    isCollection: boolean;
    contentTypes: { id: PageTypes }[];
}

export const cnPreListFilters = cn('PreListFiltersNew');