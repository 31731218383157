import React, { FC, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'

import B from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import withConfirmDialog from 'blocks/HOCs/Button/withConfirmDialog'
import LinearProgress from '@material-ui/core/LinearProgress';
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import SplitBtn from './SplitBtn'
const Button = withConfirmDialog<any>(B as any)
// import i18n from 'localizations/i18n';

export const Presenter: FC<IPresenterProps> = ({ className, onCreateUnit, isLoading = false, editOnlyLesson = false }) => {
    const [create, setCreate] = useState('no')
    const [text, setText] = useState('')

    const changeText = (e: any) => setText(e.target.value)
    const toCreateMode = (type: string) => () => {
        setCreate(type)
    }
    const onClose = () => {
        setText('')
        setCreate('no')
    }
    const onCreate = () => {
        onCreateUnit(create, text)
        onClose()
    }

    const clickWiki = () => {
        !editOnlyLesson && toCreateMode('wiki')()
        return !editOnlyLesson
    }
    const clickTask = () => {
        !editOnlyLesson && toCreateMode('task')()
        return !editOnlyLesson
    }
    const clickTest = () => {
        !editOnlyLesson && toCreateMode('test')()
        return !editOnlyLesson
    }
    const clickScorm = () => {
        !editOnlyLesson && toCreateMode('scorm')()
        return !editOnlyLesson
    }

    const options = [
        {
            label: tT('course_edit.item.create.wiki.1'),
            id: 'wiki',
            handler: clickWiki,
            onConfirm: toCreateMode('wiki'),
            confirmText: tT('course_edit.item.create.wiki.confirm.1')
        },
        // {
        //     label: tT('course_edit.item.create.task.1'),
        //     id: 'task',
        //     handler: clickTask,
        //     onConfirm: toCreateMode('task'),
        //     confirmText: tT('course_edit.item.create.task.confirm.1')
        // },
        {
            label: tT('course_edit.item.create.test.1'),
            id: 'test',
            handler: clickTest,
            onConfirm: toCreateMode('test'),
            confirmText: tT('course_edit.item.create.test.confirm.1')
        },
    ]
        ; (window.PRN_SERVICE.dev_features) && options.push({
            label: tT('course_edit.item.create.task.1'),
            id: 'task',
            handler: clickTask,
            onConfirm: toCreateMode('task'),
            confirmText: tT('course_edit.item.create.task.confirm.1')
        })

        options.push({
            label: tT('course_edit.item.create.scorm.1'),
            id: 'scorm',
            handler: clickScorm,
            onConfirm: toCreateMode('scorm'),
            confirmText: tT('course_edit.item.create.scorm.confirm.1')
        })


    return <div className={cnClassName({}, [className])}>
        {isLoading ? <LinearProgress className={cnClassName('Progress')} /> :
            (create !== 'no' ?
                <FormControl fullWidth>
                    <Input
                        value={text}
                        onChange={changeText}
                        placeholder={tT('course_edit.item.create.1')}
                        autoFocus
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton disabled={text === ''} aria-label="create" onClick={onCreate}>
                                    <DoneIcon />
                                </IconButton>
                                <IconButton aria-label="close" onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl> : <SplitBtn options={options} />
                // <ButtonGroup fullWidth size="small" variant="text" color="primary" aria-label="outlined primary button group">
                //     <Button
                //         confirmText={tT('course_edit.item.create.test.confirm.1')}
                //         onConfirm={toCreateMode('test')}
                //         onClick={clickTest}
                //     >{tT('course_edit.item.create.test.1')}</Button>
                //     <Button
                //         confirmText={tT('course_edit.item.create.wiki.confirm.1')}
                //         onConfirm={toCreateMode('wiki')}
                //         onClick={clickWiki}
                //     >{tT('course_edit.item.create.wiki.1')}</Button>
                //     <Button
                //         confirmText={tT('course_edit.item.create.task.confirm.1')}
                //         onConfirm={toCreateMode('task')}
                //         onClick={clickTask}
                //     >{tT('course_edit.item.create.task.1')}</Button>
                //     {(window.PRN_SERVICE.dev_features || window.location.hostname.includes('nspk-demo')) && <Button
                //         confirmText={tT('course_edit.item.create.scorm.confirm.1')}
                //         onConfirm={toCreateMode('scorm')}
                //         onClick={clickScorm}
                //     >{tT('course_edit.item.create.scorm.1')}</Button>}
                // </ButtonGroup>
            )}
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
