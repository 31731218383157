import React from 'react';
import { connect } from 'react-redux';
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';
import { Actions, OwnPropsOfControl, RendererProps, ControlProps } from '@jsonforms/core';
import { Action, bindActionCreators, Dispatch } from 'redux';
import { Select as PrnSelect, ItemChip } from 'blocks/PryanikyForms';
import { Translate } from 'localizations/Translate';
import { Icon } from 'uielements';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';

interface IwidgetUserfieldsProps extends ControlProps, IwidgetUserfieldsOwnProps, ReturnType<typeof mapWidgetUserfieldsStateToProps>, ReturnType<typeof mapWidgetUserfieldsactionsToProps> {

}

interface IwidgetUserfieldsOwnProps {

}

const mapWidgetUserfieldsStateToProps = (state: any, props: IwidgetUserfieldsOwnProps) => ({
  // https://tracker.yandex.ru/COMMON-9591 убираем основные поля из саджестера 
  fields: state.store.appSettings?.userProfileAdditionlFields.filter((el:any)=> el.category!== "default.fields")
});

const mapWidgetUserfieldsactionsToProps = (d: Dispatch<Action>) => bindActionCreators({

}, d);

// WidgetUserfieldsSettingsControl
const widgetUserfieldsPresenter = ({ path, visible, data, fields, ...props }: IwidgetUserfieldsProps) => {

  const handleChange = ( value: any ) => {
    (props as any).dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => value));
  }

  const [] = React.useState();

  if (!visible) return null;

  const isMultiple: any = true;

  return <Autocomplete 
    multiple={isMultiple}
    options={fields}
    onChange={(e, values) => handleChange(values.map((el: any) => el.id))}
    getOptionLabel={(option: any) => option.name}
    renderInput={(params) => (
      <TextField
        {...params}
        variant="standard"
        label={<Translate i18nKey={'pryaniky.widgets.settings.useffields.select'} />}
      />
    )}
    disableCloseOnSelect={true}
    value={fields.filter((el: any) => data[get_variable_name(props.uischema.scope)].includes(el.id))}
    renderOption={
      (option, { selected }) => <Checkbox
        value={selected}
        onChange={() => {}}
        color="default"
        inputProps={{
            'aria-label': 'checkbox with default color',
        }}
        label={option.name}
      />
    }
  />
}

export const widgetUserfields = connect(
  mapWidgetUserfieldsStateToProps,
  mapWidgetUserfieldsactionsToProps
)(widgetUserfieldsPresenter);
// end WidgetUserfieldsSettingsControl