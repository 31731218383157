import * as React from 'react';
// import * as utils from 'src/utils/utils';


import { withBemMod } from '@bem-react/core';

import { cnRatingsWidget } from '../RatingsWidget.index';
import { IRatingsWidgetTypeMountainProps } from './RatingsWidget_type_mountain.index';
import './RatingsWidget_type_mountain.scss';


export const withRatingsWidgetTypeMountain = withBemMod<IRatingsWidgetTypeMountainProps>(
  cnRatingsWidget(),
  { type: 'mountain' }, 
  (Presenter) => (
    (props) => (
      <Presenter {...props}/>
    )
  )
);