import React from 'react';
import { connect } from 'react-redux';
import { IStateType } from 'redux/store';
import { mapStateToProps, mapDispatchToProps, INotificationItemProps, IStateToPropsNotificationItem, IDispatchPropsNotificationItem, INotificationItemOwnProps } from './Item.index';
import { withRouter } from 'react-router';
import { Avatar } from 'muicomponents/src/Avatar';
import { ListItemBox, TextCrop } from './Item.styled';
import { useLinkOrRouter } from 'muicomponents/src/Button/Button.hooks';


// const absUrlRegExp = new RegExp('^(?:[a-z+]+:)?//', 'i');

const ListItem: React.FC<INotificationItemProps> = ({ set_readed, item, history }) => {

    const linkParams = useLinkOrRouter({
        href: item.linkedUrl
    });

    const onItemClick = () => {
        set_readed(item.id.toString());
        if (item.linkedUrl) {
            if(linkParams.to) {
                history.push(item.linkedUrl);
            } else {
                window.location.assign(item.linkedUrl);
            }
        }
    }

    return (
        <ListItemBox onClick={onItemClick} sx={{
            backgroundColor: item.notViewed ? '#f7fbff' : '',
            justifyContent: 'start!important',
        }}>
            <Avatar src={item.user.imgUrl} />

            <div>
                {item.user.displayName}
                <TextCrop sx={{ whiteSpace: "pre-wrap" }} countLine={2}>{item.text}</TextCrop>
            </div>

        </ListItemBox>
    )
};

export const ListItemWithRouter = withRouter(
    connect<IStateToPropsNotificationItem, IDispatchPropsNotificationItem, INotificationItemOwnProps, IStateType>(
        mapStateToProps,
        mapDispatchToProps
    )(ListItem)
)