import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { loadStudentResult } from '../../redux/actions/LMSTeacherOffice'

export interface IStudentResultsProps extends IClassNameProps, IStudentResultsStateProps, IDispatchProps, RouteComponentProps, IStudentResultsDispatchProps {
    tag?: 'div';
}

export interface IStudentResultsState {

}

export interface IStudentResultsStateProps {

}

export const mapStateToProps = (state: any, props: any) => {
    return ({
        results: state.LMSTO.courses.values[props.cid].studentesResults[props.uid],
        units: state.LMSTO.courses.values[props.cid].courseUnits,
        // studentSessions: state.LMSTO.courses.values[props.cid].studentes[props.uid].sessions,
        // resultsIds: state.LMSTO.courses.values[props.cid].resultsIds[props.uid],
        // noData: false,
        user: state.LMSTO.users[props.uid]
        // sessionsIds: state.LMSTO.courses.values[props.cid].courseSessions,
        // course: state.LMSTO.courses.values[props.cid],
        // units: state.LMSTO.moderation.units,
        // ids: state.LMSTO.moderation.results
    })
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadStudentResult
}, dispatch);

export type IStudentResultsDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnStudentResults = cn('TOStudentResults');