import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import i18n from '../localizations/i18n';
import { CustomSettings } from 'utils/src';
import { PreviewParams } from 'utils/src/PreviewParams';

const useMui5Badges = PreviewParams.inst.getShowModule('useMui5Badges') || CustomSettings.useMui5Badges();

export const badges = (): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: useMui5Badges ? [
          generateWidget({
            type: widgets.types.badgesList,
          }),
        ] : [
          generateWidget({
            type: widgets.types.info,
            settings: {
              type: 'badges'
            }
          }),
          generateWidget({
            type: widgets.types.layout,
            data: [
              generateColumn({
                items: [
                  generateWidget({
                    type: widgets.types.badgesList,
                  }),
                ],
                styles: {
                  width: '100%',
                },
              }),
            ],
          }),
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  });
}