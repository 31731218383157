import * as React from 'react';
import ReactDOM from 'react-dom';
import * as utils from 'utils/src/utils';
import i18n from "../../../localizations/i18n";
import { mapStateToProps, mapDispatchToProps, IFiltersStateProps, IFiltersDispatchProps, cnFilters } from '../Filters.index';
import { IFiltersWikiOwnProps, IFiltersWikiState } from "./Filters_type_wikilist.index";
import { Selectize, Button, Icon } from 'uielements/src';
import { IShopProductCategoriesResponse } from "utils/src/requests/models/api.shop";
import './Filters_type_wikilist.scss';
import { connect } from 'react-redux';
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
import { IStateType as IState } from 'redux/store';
import { InputMention } from '../../InputMention/InputMention';
// import { FiltersPresenter } from '../Filters';
import { Translate } from 'localizations/Translate';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { Button as MuiButton } from 'muicomponents/src/';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
// React.Component < IFiltersWikiOwnProps, IFiltersWikiState >
class FiltersTypeWikiPresenter extends React.Component<IFiltersWikiOwnProps, IFiltersWikiState> {

    public state: IFiltersWikiState = {
        data: [],
        authors: [],
        sortFilters: [],
        collectionUrl: "dash"
        // viewType: 0
    }

    private sortFilt = [
        { id: 'abc', title: i18n.t('pryaniky.modal.wikipage.filter.abc'), value: 'abc', sort: "up" },
        { id: 'creationdate', title: i18n.t('pryaniky.modal.wikipage.filter.creationdate'), value: 'creationdate', sort: "down" },
        { id: 'changedate', title: i18n.t('pryaniky.modal.wikipage.filter.changedate'), value: 'changedate', sort: "down" },
        { id: 'position', title: i18n.t('pryaniky.modal.wikipage.filter.position'), value: 'position', sort: "up" },
    ]
    private activeFilters = [
        { id: 'all', title: i18n.t('pryaniky.modal.wikipage.filter.archived.all'), value: undefined, type: 'archived' },
        { id: 'active', title: i18n.t('pryaniky.modal.wikipage.filter.archived.false'), value: 'false', type: 'archived' },
        { id: 'archive', title: i18n.t('pryaniky.modal.wikipage.filter.archived.true'), value: 'true', type: 'archived' }
    ]
    private preparedFilters = () => this.setState({
        sortFilters: this.sortFilt.map(el => ({
            id: el.id,
            type: 'order',
            title: el.title,
            value: el.value,
            sort: el.sort,
        }))
    })


    private setSort = (selected: any) => {
        this.setState({ ...this.state, sortFilters: this.state.sortFilters.map((el) => el.id === selected.id ? { ...el, sort: selected.sort === 'down' ? 'up' : 'down' } : el) })

    }

    private changeSort = (selected: ISelectizeItem) => {
        const { context } = this.props;
        const same = context.filter && context.filter.id === selected.id;
        this.props.changeContextv3(this.contextVariables.reduce((acc: any, conID: any) => {
            acc[conID] = { sort: selected.sort === 'down' ? 'asc' : 'desc' }
            return acc
        }, {} as { [s: string]: any }));
        this.setSort(selected)
        // (!this.props.context.filter || this.props.context.filter.id !== selected.id) && this.props.changeContextv3(this.props.relations.reduce((acc: any, conID: any) => ({ ...acc, [conID]: { filter: selected, [selected.type]: selected.value } }), {} as { [s: string]: any }))
    }

    public handleChangeSort = (selected: ISelectizeItem) => {
        const { context } = this.props;
        // const { filter } = this.props.context;
        const same = context[selected.type] && context[selected.type] === selected.value;
        this.props.changeContextv3(this.contextVariables.reduce((acc: any, conID) => {
            acc[conID] = { [selected.type]: selected.value, ...(selected.sort ? { sort: selected.sort === 'up' ? 'asc' : 'desc' } : {}) }
            return acc
        }, {} as { [s: string]: any }));
        // this.setSort(selected)
    }
    //проверяем урл страницы и определям находимся ли мы в основной папке или в подпапке. 
    // из для основной папке доп параметр для запроса категорий url=dash, для подпапки url={url папки} COMMON-9495 
    public getCollectionUrl = () => {
        const collectionHref = window.location.href.indexOf("wikilist/");

        if (collectionHref !== -1) {
            const colUrl = window.location.href.slice(collectionHref + 9);
            this.state.collectionUrl = colUrl ? colUrl : "dash";

        }
    }

    public componentDidMount() {
        this.getCollectionUrl()
        this.getCategories();
        this.preparedFilters();


    }
    public contextVariables = this.props.relations.filter(el => el !== (this.props as any).widget?.id) || ['common']



    public handleChangeInputMention = (type: string, data: any) => {


        let dataIds: any[]
        if (type === "authors") {
            this.setState({ authors: data });
            dataIds = data && data.id
        }

        this.props.changeContextv3(this.contextVariables.reduce((acc: any, conID: any) => {
            acc[conID] = { [type]: dataIds }
            return acc
        }, {} as { [s: string]: any }))
    }

    private getCategories = () =>
        utils.API.tags.list(0, 20, { url: this.props.context.gId ? `group/${this.props.context.gId}` : this.state.collectionUrl })
            .r
            .then((d: any) => utils.checkResponseStatus(d) && this.setState({
                data: d.data.map((el: any) => ({ id: el.id.toString(), title: el.displayName, value: el.id, type: 'catFilter' }))
                // f.concat(d.data).map((el:any) => ({ id: el.id.toString(), title: el.displayName? el.displayName: "Все", value: el.id, type: 'catFilter' }))

            }))
    // public newCatwithAllF = Array.prototype.push.apply(f, this.state.data);


    public changeViewType = (type: number) => {
        if (type === 1) {
            // this.setState({ viewType: 1 });
            this.props.changeContextv3(this.contextVariables.reduce((acc: any, conID: any) => {
                acc[conID] = { viewType: 1 }
                return acc
            }, {} as { [s: string]: any }))
            window.localStorage.setItem('prn_local_state_wiki-view-type', JSON.stringify(1));
        }
        if (type === 0) {
            // this.setState({ viewType: 0 });
            this.props.changeContextv3(this.contextVariables.reduce((acc: any, conID: any) => {
                acc[conID] = { viewType: 0 }
                return acc
            }, {} as { [s: string]: any }))
            window.localStorage.setItem('prn_local_state_wiki-view-type', JSON.stringify(0));
        }


    }
    public handleChange = (selected: ISelectizeItem) => {
        const { context } = this.props;
        // const { filter } = this.props.context;
        const same = context[selected.type] && context[selected.type] === selected.value;
        this.props.changeContextv3(this.contextVariables.reduce((acc: any, conID) => {
            acc[conID] = { [selected.type]: !same ? selected.value : undefined }
            return acc
        }, {} as { [s: string]: any }))

    }
    // private changeCategory = (selected: ISelectizeItem) => (!this.props.context.filter || this.props.context.filter.id !== selected.id) && this.props.changeContextv3(this.props.relations.reduce((acc: any, conID: any) => ({ ...acc, [conID]: { filter: selected, [selected.type]: selected.value } }), {} as { [s: string]: any }))

    public render() {
        const { tag: Tag = 'div', context, className } = this.props;
        let { data, sortFilters } = this.state;
        data = [{ id: 'all', title: Translate.t({ i18nKey: 'pryaniky.filter.wikilist.all' }), type: 'catFilter', value: undefined }, ...data]
        const rawViewType = window.localStorage.getItem('prn_local_state_wiki-view-type');
        // Parse stored json or if none return initialValue
        const viewType = rawViewType ? JSON.parse(rawViewType) : context.viewType;
        return (
            <>
                {/* 
  // @ts-ignore */}
                <Tag data={data} tag='div' context={'wikilist'} relations={[]} {...this.props} className={cnFilters({}, [className])} >
                    <div className='ViewType BottomLine'>
                        <h4><Translate i18nKey={'pryaniky.news.view.type.title'} /></h4>
                        <div>
                            <MuiButton sx={{ padding: "4px", minWidth: "20px" }} variant={viewType === 0 ? "contained" : "outlined"} onClick={() => this.changeViewType(0)}>
                                <Tooltip
                                    className={'InfoIcon'}
                                    placement="top"

                                    title={i18n.t('pryaniky.modal.wikipage.choose.defaultViewType.full.help')}>
                                    {/* <span> */}
                                    <GridViewIcon  sx={{zIndex:1}}/>
                                    {/* </span> */}
                                </Tooltip>
                            </MuiButton>

                            <MuiButton sx={{ padding: "4px", minWidth: "20px" }} variant={viewType === 1 ? "contained" : "outlined"} onClick={() => this.changeViewType(1)}>
                                <Tooltip
                                    className={'InfoIcon'}
                                    placement="top"
                                    title={<Translate i18nKey={'pryaniky.modal.wikipage.viewType.list.help'} />}>
                                    {/* <span>  */}
                                    <ListIcon  sx={{zIndex:1}}/>
                                    {/* </span> */}
                                </Tooltip>
                            </MuiButton>

                        </div>
                    </div>
                    {/* <div className='BottomLine'><WikiViewTypeSelector /></div> */}
                    <h4 className={cnFilters('Subtitle')} children={`${i18n.t('pryaniky.modal.wikipage.categories.filter')}:`} />

                    <Selectize onClick={this.handleChange} elements={data} active={data.reduce((acc: string | undefined, cur: any) => context[cur.type] && context[cur.type] === cur.value ? cur.id : acc, undefined)} cut={5}>
                    </Selectize>

                </Tag>
                {/* 
// @ts-ignore */}
                {this.props.authUser.baseData.isAdmin && <Tag data={data} tag='div' context={'wikilist'} relations={[]} {...this.props} className={cnFilters({}, [className])} >
                    <h4 className={cnFilters('Subtitle')} children={`${i18n.t('pryaniky.modal.wikipage.filter.archived')}`} />
                    <Selectize onClick={this.handleChange} elements={this.activeFilters} active={context.archived as string | undefined} />
                </Tag>}

                {/* 
  // @ts-ignore */}
                <Tag data={data} tag='div' context={'wikilist'} relations={[]} {...this.props} className={cnFilters({}, [className])} >
                    <h4 className={cnFilters('Subtitle')} children={`${i18n.t('pryaniky.modal.wikipage.order.sort.filter')}:`} />

                    <Selectize onSortClick={this.changeSort} onClick={this.handleChangeSort}
                        elements={sortFilters}
                        active={sortFilters.reduce((acc: string | undefined, cur: any) => context[cur.type] && context[cur.type] === cur.value ? cur.id : acc, "abc")} >
                    </Selectize>

                </Tag>
                {/* 
  // @ts-ignore */}
                <Tag data={data} tag='div' context={'wikilist'} relations={[]} {...this.props} className={cnFilters({}, [className])} >
                    <h4 className={cnFilters('Subtitle')}>{`${i18n.t('pryaniky.modal.wikipage.author.filter')}:`}</h4>
                    <InputMention
                        additionalRequestOpts={{ groupid: this.props.context.gId }}
                        className='BottomLine'
                        type='users'
                        icon={'user-group'}
                        placeholder={i18n.t('pryaniky.filter.colleague')}
                        noMulti={true}
                        onChange={this.handleChangeInputMention.bind(null, 'authors')}
                        value={this.state.authors}
                    />

                </Tag>
            </>
        )
    }
}

export const FiltersTypeWikilist = connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersWikiOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(FiltersTypeWikiPresenter)