import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ComponentType } from 'react'
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    pathCreator,
    pathCreatorNews,
    sendNews
} from 'News/redux/actions'
import {

} from 'News/redux/reducer/types'
import {
    getCreatorParams,
    getCreatorNewsParams
} from 'News/redux/saga/selectors'
import { IBaseNews } from 'News/types/baseNews'
import { loadAllowPostTypes } from 'redux/actions/AppSettings'
import {
    getAllowPostTypeById
} from 'redux/sagas/AppSettings/selectors'
import { NewsNews } from '../News.type'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsCreator {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.NewsCreator.IOwnProps



export const mapStateToProps = (state: any, { }: IOwnProps) => ({
    ...getCreatorParams<NewsNews>('currentType', 'currentNews')(state),
    // ...getCreatorNewsParams<LongreadNews>('text','additionalFields')(state),
    postType: getAllowPostTypeById('news')(state),
    currentUser: getCurrentUser(state)
})


export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadAllowPostTypes,
    pathCreator,
    pathCreatorNews,
    sendNews
}, dispatch);

////////////

export const cnClassName = cn('NewsNewsItem');