import { denormalize, normalize, schema } from 'normalizr';
import { IBasicResponse } from 'utils/src/requests/models/api.base';

export const indeaFieldsSchema = () => {
    const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
    const categorys = new schema.Entity('categorys', { v: [field] }, { idAttribute: 'k' });

    return [categorys];
}

export const normalizeData = (data: any) => {
    const normalizedData = normalize(data, indeaFieldsSchema());
    return normalizedData;
}

export const denormalizeData = (fields: any) => {
    const denormalizedData = denormalize(fields.result, indeaFieldsSchema(), fields.entities);
    return denormalizedData;
}

export const statusFieldsExtractor = (response: IBasicResponse) => {
    if (response.error_code === 0) {
        const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
        const categorys = new schema.Entity('categorys', { v: [field] }, { idAttribute: 'k' });

        const normalizedData = normalize((response as any).data.fields, [categorys]);
        return normalizedData
    } else throw new Error('error_/Data/v3/news/ideas/statusForm/')
}
export const statusFieldsExtractorNew = (response: IBasicResponse) => {
    if (response.error_code === 0) {
     
        return (response as any).data.fields
    } else throw new Error('error_/Data/v3/news/ideas/statusForm/')
}

