import moment from "moment";
import { Box, Divider, Typography } from "muicomponents/src";
import { IListItemProps } from "muicomponents/src/ItemsListDialog/ItemsListDialog.index";
import { Tooltip } from "muicomponents/src/Tooltip";
import React, { ComponentProps, FC, Fragment, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { checkResponseStatus, expireColumnName, ResponseError, TaskModel, TaskModelAction, TaskModelColumn, TaskModelColumnType, TaskModelStatus } from "utils/src";
import { formatDateSTD } from "utils/src/utils.dates";
import { useTasksListContext } from "../TasksList.context";
import { ItemActionCell, ItemGridCell, ItemGridContent } from "../TasksList.styled";
import { cnTaskListItem, cnTaskListItemCell } from "./TasksListItem.index";
import { DispalyNameBox, ExpiredBox, StyledItemBox } from "./TasksListItem.styled";
import { HistoryToggleOff, CheckCircle, Info, Pending, MoreVertOutlined } from 'muicomponents/src/Icons';
import { SplitButton } from "muicomponents/src/SplitButton/SplitButton";
import { Translate } from "localizations/Translate";
import { removeTask, updateTaskField } from "utils/src/requests/requests.tasks";
import { toast } from "react-toastify";
import { UpdateTask } from "blocks/Tasks/Dialog/UpdateTask";
import { taskModelSimpleToTaskModel, taskModelToTaskModelSimple } from "utils/src/BaseConverters/tasks.converters";
import { confirm } from "utils.project/utils.project";
import { useReffedState } from "utils/src/hooks";
import { Link } from "muicomponents/src/Link/Link";

const daysCountToTaksRedBackground = 3;

export const TasksListItem: FC<IListItemProps<TaskModel>> = ({
    actions,
    item
}) => {

    const context = useTasksListContext();

    const diffDays = useMemo(() => {
        if(!item.dueDate.value || item.status === TaskModelStatus.completed) return null;
        const a = moment().startOf('day');
        const b = moment(item.dueDate.value).startOf('day');
        return b.diff(a, 'days');
    }, [item.dueDate.value]);

    const colorByDiffDays = useMemo(() => {
        return typeof diffDays === 'number' && diffDays < daysCountToTaksRedBackground;
    }, [diffDays]);

    const onActionClick = useCallback<NonNullable<ComponentProps<typeof SplitButton>['handleMenuItemClick']>>(async function(e, selected) {
        blockHide.current = false;
        const key = selected.id as any as keyof typeof TaskModelAction;
        switch (TaskModelAction[key]) {
            case TaskModelAction.complete:
            case TaskModelAction.setInProgress:
                try {
                    let value = TaskModelStatus.inProgress;
                    if(TaskModelAction[key] === TaskModelAction.complete) {
                        value = TaskModelStatus.completed;
                    }
                    const r1 = await updateTaskField({
                        id: item.id,
                        model: {
                            fieldName: 'status',
                            value
                        }
                    });
                    if(checkResponseStatus(r1)) {
                        actions.updateItem(item, {
                            ...item,
                            status: value
                        });
                        toast.success(<Translate i18nKey="pryaniky.task.action.result.success" />)
                    } else {
                        toast.error(<Translate i18nKey="pryaniky.error.default" />)
                        throw new ResponseError('Update task error')
                    }
                } catch (error) {
                    if(error instanceof ResponseError) {
                        console.error(error);
                    } else {
                        throw error
                    }
                }
                break;
            case TaskModelAction.edit:
                UpdateTask({
                    userId: context.userId,
                    initData: taskModelToTaskModelSimple(item)
                })
                .then(newData => {
                    const model = taskModelSimpleToTaskModel(newData, item);
                    actions.updateItem(item, {
                        ...item,
                        ...model
                    });
                })
                .catch(console.error)
                break;
            case TaskModelAction.remove:
                confirm({
                    text: <Translate i18nKey={'asking.removeWith'} values={{
                        text: Translate.t({ i18nKey: 'pryaniky.tasks.name.create' }).toLowerCase()
                    }} />,
                    onConfirm: async function() {
                        try {
                            const r1 = await removeTask({
                                taskId: item.id,
                                userId: context.userId
                            });
                            if(checkResponseStatus(r1)) {
                                actions.removeItems([item]);
                                toast.success(<Translate i18nKey="pryaniky.task.action.result.remove.success" />)
                            } else {
                                toast.error(<Translate i18nKey="pryaniky.error.default" />)
                                throw new ResponseError('Remove task error')
                            }
                        } catch (error) {
                            if(error instanceof ResponseError) {
                                console.error(error);
                            } else {
                                throw error
                            }
                        }
                    }
                })
                break;
        }
    }, [item, context.userId]);

    const itemActions = useMemo(() => {
        let actions = Object.typedKeys(TaskModelAction).filter(el => isNaN(Number(el)) && el !== 'setInProgress')
        switch(item.status) {
            case TaskModelStatus.completed:
                actions = actions.filter(el => el === 'remove')
            case TaskModelStatus.inProgress:
                actions = actions.filter(el => el !== 'setInProgress')
                break;
        }
        if(!item.isStatusChangable) {
            actions = actions.filter(el => el !== 'setInProgress' && el !== 'complete');
        }
        if(!item.isEditable) {
            actions = actions.filter(el => el !== 'edit');
        }
        if(!item.isDeletable) {
            actions = actions.filter(el => el !== 'remove');
        }
        return actions;
    }, [item.status, item.isDeletable, item.isEditable, item.isStatusChangable]);

    const [showActions, setShowActions, showActionsRef] = useReffedState(false);
    const blockHide = useRef(false);

    const onMouseEnter = useCallback(() => {
        setShowActions(true);
    }, []);
    const onMouseMove = useCallback(() => {
        if(showActionsRef.current) return;
        setShowActions(true);
    }, []);
    const onMouseLeave = useCallback(() => {
        if(blockHide.current) return;
        setShowActions(false);
    }, []);

    return (
        <>
            <Divider />
            <StyledItemBox
                disableRipple
                className={cnTaskListItem()}
                component={item.url ? Link : Box}
                sx={{
                    cursor: item.url ? undefined : 'auto',
                    background: colorByDiffDays ? '#F4433614' : undefined
                }}
                onMouseEnter={onMouseEnter}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                href={item.url}
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    const target = e.target as HTMLElement;
                    if(target.closest(`.${cnTaskListItemCell('Actions')}`) || !target.closest(`.${cnTaskListItem()}`)) {
                        e.preventDefault();
                    }
                }}
            >
                {
                    context.columns.filter((el: TaskModelColumn) => el.isVisible).map((col: TaskModelColumn, idx) => {
                        const field = item[col.name as keyof typeof item];
                        let value: ReactNode = field;
                        if(field && !Array.isArray(field) && typeof field === 'object') {
                            if(!field.isVisible) {
                                value = null;
                            } else {
                                value = field.value;
                            }
                        }
                        switch(col.type) {
                            case TaskModelColumnType.dateTime:
                                value = formatDateSTD(value as string, true, true);
                                break;
                        }
                        let TooltipTitle: ComponentProps<typeof Tooltip>['title'] = false;
                        switch (col.name) {
                            case 'displayName':
                                let statusIcon = item.status === TaskModelStatus.completed
                                    ? <CheckCircle color={'success'} />
                                    : item.status === TaskModelStatus.inProgress
                                    ? <Pending color={'info'} />
                                    : item.status === TaskModelStatus.notStarted || item.status === TaskModelStatus.deferred
                                    ? <HistoryToggleOff color={'warning'} />
                                    : null
                                if(typeof diffDays === 'number' && diffDays <= 0 && item.status !== TaskModelStatus.completed) {
                                    statusIcon = <Info color='error' />;
                                }
                                value = <DispalyNameBox>
                                    <span>
                                        {statusIcon}
                                    </span>
                                    <Tooltip disableInteractive overflowOnly title={<>{value}</>}>
                                        <ItemGridContent>
                                            {value || <>&mdash;</>}
                                        </ItemGridContent>
                                    </Tooltip>
                                </DispalyNameBox>
                                break;
                            case 'type':
                            case 'systemName':
                                value = <Tooltip disableInteractive overflowOnly title={<>{value}</>}>
                                    <ItemGridContent>
                                        {value || <>&mdash;</>}
                                    </ItemGridContent>
                                </Tooltip>
                                break;
                            case expireColumnName:
                                value = <ExpiredBox
                                        color={colorByDiffDays ? 'error' : undefined}
                                        component={colorByDiffDays ? 'b' : 'span'}
                                    >
                                        {diffDays}
                                    </ExpiredBox>;
                                break;
                            default:
                                break;
                        }
                        return <ItemGridCell
                            key={idx}
                            className={cnTaskListItemCell({}, [col.name])}
                        >
                            <ItemGridContent className={'ItemGridContent'}>
                                {value || <>&mdash;</>}
                            </ItemGridContent>
                        </ItemGridCell>
                    })
                }
                <ItemActionCell
                    className={cnTaskListItemCell('Actions')}
                    component='span'
                >
                    {
                        showActions &&
                        <SplitButton
                            variant='text'
                            icon={<MoreVertOutlined />}
                            size={'small'}
                            handleMenuItemClick={onActionClick}
                            options={itemActions.map(key => {
                                return {
                                    id: key,
                                    content: <Translate i18nKey={`pryaniky.task.action.${key}`} />
                                }
                            })}
                            onOpen={(value) => {
                                blockHide.current = value;
                            }}
                        />
                    }
                </ItemActionCell>
            </StyledItemBox>
        </>
    );
};