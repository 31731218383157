import React, { FC } from 'react'
import './ListControls.scss'
import { StyleButton } from '../StyleButton/StyleButton'
import { DropdownSelect } from '../DropdownSelect/DropdownSelect'
import {
    UnorderedListButton,
    OrderedListButton,
} from 'draft-js-buttons';

export const ListControls: FC<any> = ({ externalProps }) => {
    return <div>
        <DropdownSelect
            button={(open, toggle) => <StyleButton
                label={<i className="Icon icon-bullet-list" />}
                onToggle={toggle}
                active={open}
            // style={type.style}
            />}
        >{
                (open, close) => <ul className={'Headers-List'}>
                    <li className={'Headers-ListItem'}><UnorderedListButton {...externalProps} /></li>
                    <li className={'Headers-ListItem'}><OrderedListButton {...externalProps} /></li>
                </ul>
            }
        </DropdownSelect>
    </div>
}