import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { closeSession } from "../../redux/actions/LMS";
///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    cid: string;
    sid: string;
}

export interface IStateProps {
    logs: any
    units: any[]
    fullUnits: any
    absoluteComplete: boolean
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    logs: state.LMS.unitsLogs,
    units: state.LMS.courses.entities.values[props.cid].courseUnits,
    fullUnits: state.LMS.units,
    absoluteComplete: state.LMS.sessionsLogs[props.sid] ? state.LMS.sessionsLogs[props.sid].log.isAbsolutelyCompleted : false
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    closeSession
}, dispatch);

////////////

export const cnClassName = cn('ManualCompleteSection');