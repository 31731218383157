import { cn } from "@bem-react/classname";
import { IClassNameProps } from '@bem-react/core'

export interface ITasksInfoProps extends IClassNameProps {
  title: string;
  expires?: Date;
};

export interface ITasksInfoState {}

export const cnTasksInfo = cn('TasksInfo');