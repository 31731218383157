
import { useEffect, useState } from 'react'
import { getUnitById } from './utils.lms'
import { Us, tU } from './i18n.adaptor'

/**
 * возвращает массив только из тестов
 * @param unitResults массив результатов юнитов
 */
export const getUnitsTypeTest = (unitResultsOrUnits: any[]): any[] =>
    unitResultsOrUnits.filter((currentValue: any) => isTestTaskType(currentValue.unitType))

/**
* возвращает массив только из scorm
* @param unitResults массив результатов юнитов
*/
export const getUnitsTypeScorm = (unitResultsOrUnits: any[]): any[] =>
    unitResultsOrUnits.filter((currentValue: any) => currentValue.unitType === 'scorm')

/**
 * возвращает число успешно пройденых тестов в курсе.
 * @param unitResults 
 */
export const getSuccessTestsCount = (unitResults: any[], units: any[]): number => {
    return getUnitsTypeTest(unitResults).filter((unitResult: any) => {
        if (unitResult.waitModerationCount !== 0) return false
        const unit = getUnitById(units, unitResult.unitId)
        const notModerateCount = getNotModerateTextAnswersCountInUnit(unitResult)
        const rigthPercent = getRigthPercent(unitResult.rightAnswersCount, unitResult.questionsCount, notModerateCount)
        return isSuccessUnitResult(rigthPercent, unit.passingMinScore)
    }).length
}


/**
 * возвращает число успешно пройденых скорм курсов.
 * @param unitResults 
 */
export const getSuccessScormCount = (unitResults: any[]): number => {
    return getUnitsTypeScorm(unitResults).filter((unitResult: any) => {
        return unitResult.isCompletedSuccess
    }).length
}

/**
 * возвращает количество дополнительных(не обязательных) тестов
 * @param units 
 */
export const getAdditionalTestCount = (units: any[]) => getAdditionalTests(units).length

/**
* возвращает дополнительные тесты
* @param units 
*/
export const getAdditionalTests = (units: any[], unitResults?: any[]) =>
    (unitResults || units).filter((unit: any) =>
        isTestTaskType(unit.unitType) && getUnitById(units, (unitResults ? unit.unitId : unit.id)).skipUnitType === 1)

/**
* возвращает обязательные тесты
* @param units 
*/
export const getMondatryTests = (units: any[]) =>
    units.filter((unit: any) => isTestTaskType(unit.unitType) && unit.skipUnitType !== 1)

/**
 * возвращает число тестов имеющих ожидающие модерации ответы
 * @param unitResults 
 */
export const getWaitTestCount = (unitResults: any[]) => {
    return getUnitsTypeTest(unitResults).reduce((accumulator: number, currentValue: any) => (accumulator + (currentValue.waitModerationCount === 0 ? 0 : 1)), 0)
}

/**
 * считает процент верных ответов
 * @param rightAnswersCount 
 * @param questionsCount 
 */
export const getRigthPercent = (rightAnswersCount: number, questionsCount: number, notModerateCount: number) =>
    Math.round((rightAnswersCount - notModerateCount) / (questionsCount - notModerateCount) * 100)

/**
 * проверяет, достигнут ли минимальный балл.
 * @param rigthPercent 
 * @param passingMinScore 
 */
export const isSuccessUnitResult = (rigthPercent: number, passingMinScore: number) => rigthPercent >= passingMinScore

/**
 * является ли тип юнита заданием
 * (тесты и задания)
 * @param type unit type string
 * @returns 
 */
export const isTestTaskType = (type: string) => type === 'test' || type === 'task'

/**
 * возвращает кол-во тестов в массиве юнитов
 * @param units массив юнитов
 */
export const getTestCount = (units: any[]) =>
    units.reduce((accumulator: number, currentValue: any) => (accumulator + (isTestTaskType(currentValue.unitType) ? 1 : 0)), 0)

/**
* возвращает кол-во scorm курсов в массиве юнитов
* @param units массив юнитов
*/
export const getScormCount = (units: any[]) =>
    units.reduce((accumulator: number, currentValue: any) => (accumulator + (currentValue.unitType === 'scorm' ? 1 : 0)), 0)


/**
 * возвращает число ответов ожидающих модерацию во всем курсе
 * @param unitResults массив результатов юнитов
 */
export const getWaitModerationCount = (unitResults: any[]): number =>
    unitResults.reduce((accumulator: number, currentValue: any) =>
        (accumulator + (isTestTaskType(currentValue.unitType) ? currentValue.waitModerationCount : 0)), 0)

/**
 * возвращает число проверенных отетов во всем курсе
 * @param unitResults массив результатов юнитов
 */
export const getModeratedAnswersCount = (unitResults: any[]): number =>
    unitResults.reduce((accumulator: number, currentValue: any) =>
        (accumulator + (isTestTaskType(currentValue.unitType) ? currentValue.moderatedAnswersCount : 0)), 0)

/**
* возвращает число всех ответов в юните
* @param unitResults юнит
*/
export const getFullAnswersCountInUnit = (unitResult: any): number =>
    unitResult.results.reduce((accumulator: number, resultValue: any) => {
        return accumulator + resultValue.attempt.reduce((accumulator: number, attemptValue: any) => (accumulator + attemptValue.answers.length), 0)
    }, 0)


/**
 * возвращает число всех ответов во всем курсе
 * @param unitResults массив результатов юнитов
 */
export const getFullAnswersCount = (unitResults: any[]): number =>
    getUnitsTypeTest(unitResults).reduce((accumulator: number, currentValue: any) => {
        return accumulator + getFullAnswersCountInUnit(currentValue)
    }, 0)

/**
 * возвращает результат для указаного юнита
 * @param unitResults массив результатов юнитов
 * @param id ид юнита
 */
export const getUnitResultById = (unitResults: any[], id: string) => unitResults.find((result) => result.unitId === id)

/**
 * возврощает число текстовых ответов в юните не требующих проверки
 * @param unitResult 
 */
export const getNotModerateTextAnswersCountInUnit = (unitResult: any) => {
    return unitResult.results.reduce((accumulator: number, resultValue: any) => {
        return accumulator + resultValue.attempt.reduce((accumulator: number, attemptValue: any) => {
            return accumulator + attemptValue.answers.reduce((accumulator: number, answerValue: any) =>
                (accumulator + ((answerValue.moderationStatus === 'NotModerated' && answerValue.isFreeAnswer === true) ? 1 : 0)), 0)
        }, 0)
    }, 0)
}

/**
 * возврощает число текстовых ответов не требующих проверки во всем курсе
 * @param unitResults массив результатов юнитов
 */
export const getNotModerateTextAnswersCount = (unitResults: any[]): number =>
    getUnitsTypeTest(unitResults).reduce((accumulator: number, currentValue: any) => {
        return accumulator + getNotModerateTextAnswersCountInUnit(currentValue)
    }, 0)


/**
* возврощает кол-во юнитов в курсе состоящих только из свободных ответов не требующих проверки
* @param unitResults массив результатов юнитов
*/
export const getNotModerateTextOnlyUnitsCount = (unitResults: any[]): number =>
    getUnitsTypeTest(unitResults).reduce((accumulator: number, currentValue: any) => {
        return accumulator + (getFullAnswersCountInUnit(currentValue) <= getNotModerateTextAnswersCountInUnit(currentValue) ? 1 : 0)
    }, 0)

/**
 * возвращает число верных ответов во всем курсе
 * @param unitResults массив результатов юнитов
 */
export const getRightAnswersCount = (unitResults: any[]): number =>
    unitResults.reduce((accumulator: number, currentValue: any) =>
        (accumulator + (isTestTaskType(currentValue.unitType) ? currentValue.rightAnswersCount : 0)), 0)

/**
 * создаёт массив опций для поптыток
 * @param count количество опций
 */
export const createAttemptOptions = (count: number) =>
    (new Array<any>(count)).fill({}).map((val: any, idx: number) => ({ value: idx, title: tU('attempt.1', { number: idx + 1 })/*`попытка ${idx + 1}`*/ }))

/**
* сортируем попытке по дате. старые вначале
* @param count количество опций
*/
export const sortAttempt = (results: any[]) =>
    results
        .map((v: any) => ({ ...v, timeStamp: new Date(v.timeStamp) }))
        .sort((a, b) => a.timeStamp - b.timeStamp)


/**
 * создаёт коллекцию ключ-значение для попыток
 * @param attempt 
 */
export const cretateAnswersMap = (attempt: any) => {
    const resultAnswersMap: any = {}
    const resultAnswers: string[] = []

    attempt.forEach((v: any) => {
        const answersMap: any = {}
        v.answers.forEach((a: any) => {
            answersMap[a.answerId] = { ...a, userAnswerText: a.value, text: a.value };
            resultAnswers.push(a.answerId);
            resultAnswersMap[a.answerId] = { ...a, userAnswerText: a.value, text: a.value };
        })
        // questionsMap[v.qid] = { ...v, text: v.questionText, normalAnswers: answersMap, answers: v.answers.map((b: any) => b.answerId) };
    });

    return [resultAnswers, resultAnswersMap]
}

/**
 * возвращает текст резельтата для теста
 * @param wait 
 * @param right 
 * @param info 
 */
export const getTestResultText = (wait: boolean, right: boolean, info: boolean, notStarted: boolean = false, skipType: number = 0) => {
    let text = tU('testResultText.info.1')//'Спасибо за Ваш ответ'
    if (wait) text = tU('testResultText.wait.1')//'Ожидает проверки'
    else if (!info) text = right ? tU('testResultText.success.1') : tU('testResultText.fail.1')
    if (info && !wait && !right && notStarted && skipType === 1) text = tU('testResultText.not_completed.1')//'Не выполнен'
    return text
}

/** 
 * хук для переключения попыток
 * @param options список попыток-опций
 * @param deps зависимости
 */
export const useSetAttepmt = (options: any[], deps?: any[]) => {
    if (options.length === 0) return [{}, () => { }]
    const [state, setState] = useState<any>(options[options.length - 1])
    useEffect(() => {
        setState(options[options.length - 1])
    }, deps)
    const cangeAttempt = (val: any) => val && setState(val)
    const r = (options.length - 1) >= state.value ? state : options[options.length - 1]
    return [r, cangeAttempt]
}



/**
 * все ли вопросы относятся к типу 2
 * @param questions 
 * @param normalQuestions 
 * @returns 
 */
export const allowShowQuestions = (questions: string[], normalQuestions: any) => {
    return questions.every((qid) => normalQuestions[qid] && normalQuestions[qid].qtype === 2)
}

/**
 * показыватьле результаты теста
 * @param questions 
 * @param normalQuestions 
 * @param highlightCorrectness 
 * @param attmptsAvailable 
 * @param isAbsolutelyCompleted 
 * @returns 
 */
export const isAllowShow = (questions: string[], normalQuestions: any, highlightCorrectness: boolean, attmptsAvailable: boolean, isAbsolutelyCompleted: boolean) => {
    let allowShow = allowShowQuestions(questions, normalQuestions)
    allowShow = allowShow ? allowShow : highlightCorrectness
    allowShow = attmptsAvailable ? allowShow : true
    allowShow = isAbsolutelyCompleted ? true : allowShow
    return allowShow
}

/**
 * естьли доступные попытки
 * @param attemptNumber 
 * @param unitContent 
 * @returns 
 */
export const isAttmptsAvailable = (attemptNumber: number, unitContent: any) => attemptNumber < unitContent.maxAttemptsCount


/**
 * доступны ли результаты
 * @param unitResult 
 * @param attempt 
 * @returns 
 */
export const resultsAvaibled = (unitResult: any, attempt: any) => Boolean(unitResult.results[attempt.value])

/**
 * список ид вопросов в результате
 * @param unitResult 
 * @param attempt 
 * @returns 
 */
export const getResultAttemptQuestionsId = (unitResult: any, attempt: any) => unitResult.results[attempt.value].attempt.map((val: any) => val.qid)

/**
 * список ид вопросов в результате с проверкой доступности результатов
 * @param unitResult 
 * @param attempt 
 * @returns 
 */
export const getAttepmtQuestionsIds = (unitResult: any, attempt: any) => {
    const noResult = !resultsAvaibled(unitResult, attempt)
    return noResult ? [] : getResultAttemptQuestionsId(unitResult, attempt)
}


/**
 * скрыть ли попытки
 * @param results 
 * @param maxAttemptsCount 
 * @param isAbsolutelyCompleted 
 * @returns 
 */
export const isHideAttempt = (results: any[], maxAttemptsCount: number, isAbsolutelyCompleted: boolean, additionalAttemntCount: number = 0) => {
    let hideAttempt = !(results.length < (maxAttemptsCount + additionalAttemntCount))
    hideAttempt = isAbsolutelyCompleted ? true : hideAttempt
    return hideAttempt
}

/**
 * если ещё юниты
 * @param notCompletedUnits 
 * @returns 
 */
export const isLastUnit = (notCompletedUnits: any[]) => {
    return notCompletedUnits.length === 0
}