import { IEditorProps } from './Editor.index'
import React, { useEffect, useRef, useState, memo } from 'react'
import './Editor.scss';
import { Editor as ReplyEditor } from '../../Reply/Editor/Editor'
import { Editor as ThanksEditor } from '../../Thanks/Editor/Editor'
import i18n from 'localizations/i18n';

export const Editor: React.FC<IEditorProps> = memo(({
    maxUsers,
    validation = [],
    reasons = [],
    children,
    excludeUsers = [],
    data,
    onChangeText,
    onChangeReason,
    onChangeThanks,
    onChangeUsers,
    users,
    // maxThanksValue = 0,
    maxThanksArgumentsCount = 0,
    // thanksForAll = 0,
    disabledVariants = [],

    disableDown = false,
    disableUp = false,
    thanksCount = 0,
    available = 0,
    fullCount = 0,
    fullCurrency = 0,
    maxPerUser = 0,
    maxThanks = 0,
    send
}) => {
    const refContainer = useRef<any>(null);
    useEffect(() => {
        // в хроме, новом эдже, опере работает только при таймауте 100 и выше, был пренесен в ReplyEditor под функцию componentDidUpdate так как там работает быстрее
        // refContainer.current && refContainer.current.scrollIntoViewIfNeeded();
    }, [refContainer.current])
    const textValidation = validation.find((val: any) => val.field === 'text' && val.method === 'STR_MIN')

    const minLengthData = textValidation && data.text ? {
        need: textValidation.value,
        current: textValidation.value - data.text.getCurrentContent().getPlainText().trim().length
    } : {}

    return <div ref={refContainer} className={'Editor'}>
        <ThanksEditor
            // maxThanksValue={maxThanksValue}
            maxThanksArgumentsCount={maxThanksArgumentsCount}
            // thanksForAll={thanksForAll}
            maxUsers={maxUsers}
            validation={validation}
            reasons={reasons}
            onChangeReason={onChangeReason}
            onChangeThanks={onChangeThanks}
            onChangeUsers={onChangeUsers}
            users={users}
            excludeUsers={excludeUsers}
            disabledVariants={disabledVariants}

            disableDown={disableDown}
            disableUp={disableUp}
            thanksCount={thanksCount}
            available={available}
            fullCount={fullCount}
            fullCurrency={fullCurrency}
            maxPerUser={maxPerUser}
            maxThanks={maxThanks}
            
            // thanksCount={data.thanksInfo.thanksCount}
        />

        <ReplyEditor
            send={send}
            placeholder={i18n.t('pryaniky.reply.thanks.placeholder')}
            data={data}
            onChangeText={onChangeText}
        />
        {textValidation && data.text &&
            <div className={'EditorThanks-MinLength'}>{i18n.t('pryaniky.createPost.thanks.MinLength', minLengthData)}</div>
        }
        {children}
    </div>
})