import { IBaseNews, createDefaultNews } from 'News/types/baseNews'

export interface RepostNews extends IBaseNews {
    newstype: 31;
    parentGuid: string;
    network?: string;
    parentNews?: {
        appid: string,
        parentGuid: string
    }
}

export const defaultRepostNews: RepostNews = {
    ...createDefaultNews(),
    newstype: 31,
    parentGuid: '0',
}