import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    loadFiles,
    reset    
} from '../../redux/actions/TracksUse'
import {
    ITrackFiles
} from '../../redux/reducers/TracksUse'
import {
    getFilesObject
} from '../../redux/sagas/TracksUse/selectors'
// import {  } from 'utils/src/requests/models/api.learningTracks'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace LerningTrack {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace TrackView {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps<{ id: string }>

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {

        }

        export interface IStateProps extends ITrackFiles {

        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = LerningTrack.TrackView.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    ...getFilesObject(state),
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    loadFiles,
    reset 
}, dispatch);

////////////

export const cnClassName = cn('TrackView');