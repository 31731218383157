import { Box } from 'muicomponents/src/Box';
import { styled } from 'muicomponents/src/mui/system';

export const FilterTypeAchievementsBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: 16
});

export const FilterTypeAchievementsActionsBox = styled(Box)({
  display: 'flex',
  gap: 16,
  flexWrap: 'wrap'
}) as typeof Box;