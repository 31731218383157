import React, { FC, memo } from 'react'
import {
    cnClassName,
    News
} from './interfaces'
import './style.scss'
import { Actions } from 'blocks/NewsView/Actions/Actions'

type IPresenterProps = News.ReplyLayout.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    actions,
    avatar,
    service,
    title,
    additional
}) => {
    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Aside')}>
            {avatar}
        </div>

        <div className={cnClassName('Section')}>
            <div className={cnClassName('Header')}>
                <div className={cnClassName('Title')}>
                    {title}
                </div>
                <div className={cnClassName('Service')}>
                    {service}
                </div>
            </div>
            <div className={cnClassName('Additional')}>
                {additional}
            </div>
            <div className={cnClassName('Content')}>
                {children}
            </div>
            <div className={cnClassName('Footer')}>
                    {actions}
            </div>
        </div>

    </div >
}

export default memo(Presenter);
