import React, { FC, useCallback, memo, useMemo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    KPI
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import Row from '../../components/Row'
import { /*Button, */Icon/*, ButtonBox*/ } from 'uielements/src'
import Checkbox from '@material-ui/core/Checkbox';
import { formatDateSTD } from 'utils/src/utils.dates'
import MDRender from 'uielements/src/CommonmarkRender'
import { isAllowEdit } from '../../utils/utils'
// import withConfirmDialog from 'blocks/HOCs/Button/withConfirmDialog'
import i18n from 'localizations/i18n'
import { Button } from 'muicomponents/src/Button/Button'
import withConfirmDialog from 'muicomponents/src/Button/HOCs/withConfirmDialog'
import ButtonGroup from '@mui/material/ButtonGroup';

const ConfirmButton = withConfirmDialog(Button)

type IPresenterProps = KPI.Item.IPresenterProps
type IOwnProps = KPI.Item.IOwnProps
type IDispatchProps = KPI.Item.IDispatchProps
type IStateProps = KPI.Item.IStateProps
type IState = KPI.Item.IState


export const Presenter: FC<IPresenterProps> = ({
    children,
    dueDate,
    goal,
    id,
    isComplete,
    name,
    setEdit,
    deleteKPIItem,
    userId,
    isDeletable
}) => {
    const allowEdit = useMemo(() => isAllowEdit({
        dueDate,
        goal,
        isComplete,
        name
    }), [
        dueDate.isEditable,
        goal.isEditable,
        isComplete.isEditable,
        name.isEditable
    ])

    const onDelete = useCallback(() => deleteKPIItem({ id, userId }), [userId, id])
    return <Row
        className={cnClassName({}, [])}
        col1={isComplete.isVisible && <div className={cnClassName('Check')}>
            <Checkbox
                checked={isComplete.data}
                name="isCompletes"
                color="primary"
            />
        </div>}
        col2={name.isVisible && <MDRender className={cnClassName('Name')} source={name.data} />}
        col3={goal.isVisible && <MDRender className={cnClassName('Goal')} source={goal.data} />}
        col4={dueDate.isVisible && <div className={cnClassName('DueDate')}>{formatDateSTD(dueDate.data, false)}</div>}
        col5={
            <div className={cnClassName('Actions')}>
                <ButtonGroup variant="contained" className={cnClassName('ButtonBox')}>
                    {allowEdit && <Button
                        variant={'text'}
                        title={i18n.t("pryaniky.kpi.button.edit.title.1")}
                        onClick={setEdit}>
                        <Icon icon={'edit'} />
                    </Button>}
                    {isDeletable && <ConfirmButton
                        variant={'text'}
                        title={i18n.t("pryaniky.kpi.button.delete.title.1")}
                        confirmBtnText={i18n.t("pryaniky.kpi.confirm.button.delete.1")}
                        confirmTitle={i18n.t("pryaniky.kpi.confirm.title.delete.1")}
                        onConfirm={onDelete}>
                        <Icon icon={'times'} />
                    </ConfirmButton>}
                </ButtonGroup>
            </div>}
    />
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
