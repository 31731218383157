import { cn } from '@bem-react/classname';
import { IBodyProps } from 'utils/src/DialogCreator';

export const cnWorkflowsKanbanFilters = cn('WorkflowsKanbanFilters');

export interface IWorkflowsKanbanFiltersProps extends IBodyProps {
  context: any;
  checkedHandler: any;
  handleChangeInputMention: any;
  inputMention: any;
}