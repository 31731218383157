import { IGroupProps } from '../InfoBlock-Group.index';

import { IDispatchProps } from '../../../../redux/connector';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface IGroupEditFalseProps extends IGroupProps, IGroupStateProps, IDispatchProps {
  tag?: 'div';
}

export interface IGroupEditFalseState {
  text?: string;
}

export interface IGroupStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: {
    ...state.store,
    authUser: getAuthUser(state)
  }
})