import { FAchievementsRenderParams } from 'utils/src/BaseTypes/news.achievements.types';
import { FNewsPostType, PostRenderParams } from 'utils/src/BaseTypes/news.types';

export namespace NAchievements {

    export type RenderParams = FAchievementsRenderParams;

    export interface Create extends FNewsPostType<'achievements'> {
        achievement: FAchievementsRenderParams['achievements'][number]['goals'][number] | null;
        newstype: 19;
        componentRenderName: 'achievements';
    }
}