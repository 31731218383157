import { baseNewsCreateItem, baseNewsCreateReducer } from './base.newsCreator';
import { NewsCreateReducer } from './types.newsCreator';
import * as actionsTypesCreator from './actionsTypes.newsCreator';
import { clone } from 'lodash';
import { NNewsCreateActions } from './actions.types.newsCreator';

export function newsCreateReducer(state = baseNewsCreateReducer, action: NNewsCreateActions.Actions): NewsCreateReducer {
    switch (action.type) {

        case actionsTypesCreator.NEWS_CREATOR_INIT: {
            return {
                ...state,
                [action.payload]: clone(baseNewsCreateItem)
            };
        };

        case actionsTypesCreator.NEWS_CREATOR_ITEM_CHANGE: {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    ...action.payload.item
                }
            };
        };

        case actionsTypesCreator.NEWS_CREATOR_ITEM_NEWS_CHANGE: {
            const creatorItem = state[action.payload.id];
            const prepairedPayload = clone(action.payload.news);
            switch (creatorItem.news.componentRenderName) {
                case 'polls': {
                    prepairedPayload.poll = {
                        ...(creatorItem.news as any).poll,
                        ...prepairedPayload.poll
                    }
                    break;
                }
                case 'events': {
                    prepairedPayload.uevent = {
                        ...(creatorItem.news as any).uevent,
                        ...prepairedPayload.uevent
                    }
                    break;
                }
                case 'workflow': {
                    prepairedPayload.additionalFields = {
                        ...(creatorItem.news as any).additionalFields,
                        ...prepairedPayload.additionalFields
                    }
                }
                case 'ideas': {
                    prepairedPayload.idea = {
                        ...(creatorItem.news as any).idea,
                        ...prepairedPayload.idea
                    }
                }
            }
            return {
                ...state,
                [action.payload.id]: {
                    ...creatorItem,
                    news: {
                        ...creatorItem.news,
                        ...prepairedPayload as any
                    }
                }
            };
        };

        default:
            return state;
    }
};