import React, { FC, useEffect, useState } from 'react'
import { cnCourseEditor, mapDispatchToProps, mapStateToProps } from './CourseEditor.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './CourseEditor.scss'
// import { Button } from 'uielements/src';
import UnitsList from './UnitsList'
import queryString from 'query-string';
import UnitItem from './Item';
import uuid from 'uuid/v1';
import { ListPlaceholder } from 'uielements/src';
import feedPlaceholder from 'assets/svg/feedPlaceholder.svg';
import CreateButton from './CreateButton'
import { unitModelCreator, shortUnitModelCreator } from 'LMSModule/utils/dataCreators/unitCreator'
import ListPlaceholderFull from '../../components/ListPlaceholder'
import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { videoUrls } from 'utils/src/videoUrls';
import { VideoButton } from 'uielements/src/Dialog/VideoDilog/VideoButton';

export const CourseEditorPresenter: FC<any> = ({ className, course, cid, location, saveCourse, createUnit, activeSessions, isSaving }) => {
    const { unitId, unitVersion } = queryString.parse(location.search)
    const currentUnit = course.courseUnits.find((unit: any) => unit.id === unitId)

    const onSave = () => saveCourse(cid)

    const onCreateUnit = (type: string, name: string) => {
        const unit = shortUnitModelCreator(uuid())
        unit.courseId = cid
        unit.unitType = type
        unit.name = name
        createUnit(unit)
    }

    if (!activeSessions) return <div className={cnCourseEditor({}, [className])}>
        <div className={cnCourseEditor('Aside')}>
            <ListPlaceholderFull
                className={cnCourseEditor('Placeholder')}
                isFinished={false}
                isLoading={true}
                itemsCount={0}
                titleEmpty={''}
                textEmpty={''}
                textLoad={tT('course_edit.nav.load.1')}
            />
        </div>
        <div className={cnCourseEditor('Section')}>
            <VideoButton className={cnCourseEditor('Section-Video')} url={videoUrls.lms.courseEditor} type={'vimeo'} />
            <ListPlaceholderFull
                className={cnCourseEditor('Placeholder')}
                isFinished={false}
                isLoading={true}
                itemsCount={0}
                titleEmpty={''}
                textEmpty={''}
                textLoad={tT('course_edit.item.load.1')}
            />
        </div>
    </div>

    const editOnlyLesson = activeSessions.length !== 0

    return <div className={cnCourseEditor({}, [className])}>
        <div className={cnCourseEditor('Aside')}>
            {/* <button onClick={onSave}>Сохрфнить курс</button> */}
            {<CreateButton editOnlyLesson={editOnlyLesson} onCreateUnit={onCreateUnit} isLoading={isSaving} />}

            <UnitsList editOnlyLesson={editOnlyLesson} units={course.courseUnits} active={unitId} cid={cid} />
        </div>

        <div className={cnCourseEditor('Section')}>
            {(editOnlyLesson && currentUnit) && <ErrorMsg type={'warning'} className={cnCourseEditor('Message')}>
                {currentUnit.unitType === 'wiki' && <p>{tT('course_edit.wiki.warn.1')}</p>}
                {currentUnit.unitType === 'test' && <p>{tT('course_edit.test.warn.1')}</p>}
            </ErrorMsg>}

            <div className={cnCourseEditor('Content')}>
                {
                    currentUnit ?
                        <UnitItem editOnlyLesson={editOnlyLesson} unitId={currentUnit.id} unitVersion={unitVersion as (string | undefined)} /> :
                        <>
                         <VideoButton className={cnCourseEditor('Content-Video')} url={videoUrls.lms.courseEditor} type={'vimeo'} />
                            <ListPlaceholder
                                imageSrc={feedPlaceholder}
                                className={cnCourseEditor('Placeholder')}
                                title={tT('course_edit.item.empty.title.1')}
                                text={tT('course_edit.item.empty.text.1')}
                            />
                        </>
                }
            </div>
        </div>
    </div>
}

export const CourseEditor = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CourseEditorPresenter));
