import { IComponentAcceptor } from 'utils/src/ComponentInjector'
import { cn } from '@bem-react/classname';
import { PageModelList } from 'utils/src';


export const cnWikiContextMenu = cn('WikiContextMenu');

export interface IWikiItemActionProps {
    className?: string;
    data: PageModelList;
    id?: string;
    type?: string;
    url?: string;
    title?: string;
    moderators?: { [s: string]: any }[];
    authors?: { [s: string]: any }[];
    tags?: { [s: string]: any }[];
    titleImageId?: string;
    titleImageUrl?: string;
    newsId?: string;
    likeCount?: number;
    liked?: boolean;
    isAdmin: boolean;   
}
export interface IWikiItemProps extends IWikiItemActionProps, IComponentAcceptor<IWikiItemActionProps>  {
    onEdit: () => void
    btnRender?: (toggleOpen: () => void, ref: any, isOpen: boolean) => void
    updateItemList: (item: PageModelList, newItem: PageModelList) => void;
    removeItems: (items: PageModelList[]) => void;
    context: any;
};

