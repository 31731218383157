import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IBodyProps } from 'utils/src/DialogCreator';
import { SelectableObject } from 'utils/src/BaseTypes/selectableObject.types';
export interface ISkillsDialogCreateProps extends IClassNameProps, IBodyProps<{title:SelectableObject | null}> {
  tag?: 'div';
  onComplete: () => void;
  currentUser: any;
  withError: boolean;
}

export interface CreateData{
   
}
export interface ISkillsDialogCreateState { }

export interface ISkillsDialogCreateStateProps { }



export const cnSkillsDialogCreateDialog = cn('SkillsDialogCreateDialog');