import React, { FC, useEffect, useState, useMemo, ComponentProps, useContext, useRef, useCallback } from 'react';
import { createModal, TransitionComponent, IBodyProps, TDialogProps } from 'utils/src/DialogCreator'
import { DialogBody } from 'muicomponents/src/DialogParts/DialogBody/DialogBody'
import { Button } from 'muicomponents/src/Button/Button'
import { useDispatch, useSelector } from "react-redux";
import { getAllowPostTypeById } from "redux/sagas/AppSettings/selectors";
import { Stack } from '@mui/material';
import { BaseSuggester, Box } from 'muicomponents/src';
import { FAchievementsRenderParams } from 'utils/src/BaseTypes/news.achievements.types';
import { useFileUploader } from 'utils/src/useFileUploader';
import { useLazyQueryEx } from 'utils/src/hooks';
import { usersFromFileRequest } from 'utils/src/requests/admin.achievements';
import { achievementByModerator } from 'utils/src/requests/requests.news';

import i18n from 'localizations/i18n';

import { ItemsList } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody'
import { toast } from 'react-toastify';
import { isArray } from 'lodash';
import { basePostNews } from 'News/creator/baseNews';
import { InfinityListItem } from 'muicomponents/src/InfinityList/Item/ListItem'
import Divider from '@mui/material/Divider';

import { TooltipNews } from 'muicomponents/src/TooltipNews/TooltipNews';
import { Link } from 'muicomponents/src/Link/Link';

const UniqueUsersItem = ({ item, ...props }: any) => {

    return <>
        <InfinityListItem
            primaryText={item.accountName}
            secondaryText={item.description}
            component={'div'}
        />
        <Divider variant={'fullWidth'} component={'div'} />
    </>
}

const useSuggesterOptions = () => {
    const achievementsParams = useSelector(getAllowPostTypeById("achievements.moderation" || ''))?.renderParams;

    const suggesterOptions = useMemo(() => {
        const goals: (Required<NonNullable<typeof achievementsParams>>['achievements'][number]['goals'][number] & { group: string })[] = [];
        achievementsParams?.achievements?.forEach((group: any) => {
            group.goals.forEach((goal: any) => {
                goals.push({
                    ...goal,
                    group: group.name
                });
            });
        });
        return goals.map(el => ({
            ...el,
            displayName: el.name
        }));
    }, [achievementsParams?.achievements]);

    return suggesterOptions
}

const AchievementsLoader: FC<{} & IBodyProps> = ({ handleAccept, handleClose }) => {


    const { send, result, isLoading: isLoadingUFF } = useLazyQueryEx(usersFromFileRequest)

    const { openSelectFile, isLoading: isLoadingFile, files } = useFileUploader({
        uploadOnAdd: true,
        callbacks: {
            loadFinish(item, response) {
                if (response?.error_code === 0) {
                    send({ fileId: response.data[0].id })
                }
            },
        }
    })
    const { send: sendPost, result: postResult, isLoading: postIsPending } = useLazyQueryEx(achievementByModerator)

    const [selectedAvhievement, setAvhievement] = useState<any>(null)

    const suggesterOptions = useSuggesterOptions()

    async function sendAchievements() {
        if (files[0].response?.data[0].id) {
            try {
                const r = await sendPost({ fileId: files[0].response?.data[0].id }, { ...basePostNews, text: '-', newstype: 19, achievement: selectedAvhievement })
                toast.success(i18n.t('pryaniky.AchievementsLoader.toast.success', { count: r.length }))
                handleAccept({})
            } catch (error) {
                toast.error(i18n.t('pryaniky.AchievementsLoader.toast.error'))
            }

        }
    }

    return <DialogBody
        isLoading={isLoadingUFF || isLoadingFile || postIsPending}
        onAccept={sendAchievements}
        onClose={handleClose}
        header={i18n.t('pryaniky.AchievementsLoader.dialog.header')}
        disableAccept={!selectedAvhievement || !result}
    >
        <Stack spacing={2} sx={{ marginY: '12px' }}>
            <BaseSuggester
                test-id={'timeline-create-achievements-achievement'}
                options={suggesterOptions as any}
                value={selectedAvhievement as any}
                onChange={(_, v) => setAvhievement(v as any)}
            />

            <TooltipNews 
            newsParams={{
                showTooltip: true, 
                tooltip: i18n.t('pryaniky.AchievementsLoader.help.tooltip')
            }} 
            title={
                <a target="_blank" href={i18n.t('pryaniky.AchievementsLoader.help.link')}>{i18n.t('pryaniky.AchievementsLoader.help.title')}</a>
            
            } />
            {/* <Link target='_blank' href={i18n.t('pryaniky.AchievementsLoader.help.link')}>{i18n.t('pryaniky.AchievementsLoader.help.title')}</Link> */}

            <Button variant='contained' onClick={openSelectFile} disabled={isLoadingUFF || isLoadingFile}>{i18n.t('pryaniky.AchievementsLoader.button.upload')}</Button>


            {result && <ItemsList<any>
                listHeaderProps={{
                    disableSearch: true,

                }}
                listBodyProps={{
                    joinBody: true
                }}
                tabs={[
                    {
                        id: 'uniqueUsers',
                        label: i18n.t('pryaniky.AchievementsLoader.tab.uniqueUsers')//'uniqueUsers'
                    },
                    {
                        id: 'duplicateUsers',
                        label: i18n.t('pryaniky.AchievementsLoader.tab.duplicateUsers')//'duplicateUsers'
                    },
                    {
                        id: 'notExistUsers',
                        label: i18n.t('pryaniky.AchievementsLoader.tab.notExistUsers')//'notExistUsers'
                    }
                ]}
                method={async function (skipCount, { tabsValue = 'uniqueUsers' }) {
                    const r = result?.[tabsValue as 'uniqueUsers' | 'duplicateUsers' | 'notExistUsers'] || [];
                    return r.map(el => {
                        if(typeof el === 'string') {
                            return { id: el, accountName: el, type: 'accountName' }
                        }
                        return {
                            ...el.userInfo,
                            accountName: el.userData,
                            type: 'uniqueUsers'
                        }
                    });
                }}
                BodyComponent={ItemsListBody}
                ItemComponent={UniqueUsersItem}
            />}

        </Stack>
    </DialogBody>
}

export const openAchievementsLoader = createModal(AchievementsLoader, {
    maxWidth: 'md',
    fullWidth: true,
    PaperProps: {
        style: {
            overflowY: 'hidden',
        }
    },
    // TransitionComponent,
    // scroll: 'paper',
    // align: 'flex-start',
    // memMaximalHeight: true
    // fullHeight: true,
})

export const AchievementsLoaderWidget = () => {
    return <Stack>
        <Button onClick={() => openAchievementsLoader({})}>{i18n.t('pryaniky.AchievementsLoader.widget.open')}</Button>
    </Stack>
}