import { IStateType } from "redux/store";
/**
 * кэширующий селектор. 
 * для оптимизации числа вызовов и сложных вычислений в селекторах
 * https://github.com/devSchacht/translations/blob/master/articles/reselect-selector-library-for-redux/readme.md
 */
import { defaultMemoize, createSelectorCreator } from "reselect";
import { isEqual } from 'lodash';
import { getWidgetRelations } from './utils';
import { getWidgetsField as qwe } from './redux/selectors';

export const getWidgets = (state: IStateType) => state.widgets;

type GetWidgetParameters = Parameters<typeof getWidgets>;

export const getWidgetsFieldOld = <Key extends keyof ReturnType<typeof getWidgets>, > (field: Key) => (...args: GetWidgetParameters) => getWidgets(...args)[field];
export const getWidgetsField = qwe;

export const getWidgetById = (id: string) => (...args: GetWidgetParameters) => getWidgetsFieldOld('widgets')(...args)[id];


// замента стандартного сравнения( === ) на глубокое
const createSelector = createSelectorCreator(defaultMemoize, isEqual);

// const getWidgetsContexts = getWidgetsField('contexts');
const getWidgetsContexts = getWidgetsFieldOld('contexts');

// после всех манипуляций получается селектор, который меняет свой результат только в том случае, если реально поменялись входные данные
export const getWidgetContextById = (id: string) =>
    createSelector(
        [getWidgetsContexts],
        (contexts) => {
            const relations = getWidgetRelations(id, contexts);
            return Object.assign(relations.reduce((acc, relation) => Object.assign(acc, contexts[relation]), {}), {}) as (typeof contexts)[keyof typeof contexts];
        }
    );

// export const getWidgetContextById = (id: string) => (...args: GetWidgetParameters) => {
//     // get all contexts;
//     const contexts = getWidgetsField('contexts')(...args);
//     // get callable widget context
//     const onceContext = contexts[id];
//     // define all relations
//     const relations: (typeof id)[] = [id];
//     let parent = onceContext.__parent;
//     while (parent) {
//         relations.unshift(parent);
//         parent = contexts[parent].__parent;
//     }
//     const context = Object.assign(relations.reduce((acc, relation) => Object.assign(acc, contexts[relation]), {}), {}) as (typeof contexts)[keyof typeof contexts];
//     return context;
// }