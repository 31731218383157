/**
 * @packageDocumentation
 * @module News_type_tasks_Task_type_DocumentReadBlock
 */
import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

export interface ITaskDocumentReadBlockProps extends IClassNameProps {
  onChange?: (files: any[]) => void;
  onRemove?: () => void;
}

export interface TaskDocumentReadBlockFileItem {
  id: string;
  file: File;
  progress: number;
  status: string;
  name: string;
  response?: any;
}

export interface TaskDocumentReadBlockState {
  files: TaskDocumentReadBlockFileItem[];
}

export const cnTaskDocumentReadBlock = cn('TaskDocumentReadBlock');