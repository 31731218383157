import React, { useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStateType } from 'redux/store';
import { Box } from 'muicomponents/src';
import { useThrottle } from 'utils/src/hooks';
import { setShopFilters } from 'redux/shop/actions';
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput';
import { i18n } from 'localization';


export const SearchBar = () => {

  const dispatch = useDispatch()
  const search = useSelector((s: IStateType) => s.shop.chosenFilters.search)
  const [searchValue, setSearchValue] = useState(search)

  const changeSearch = (v: string) => {
    if (v.length === 1) return
    dispatch(setShopFilters({
      key: 'search',
      value: v
    }))
  }
  const throttledChange = useThrottle(
    changeSearch,
    3000
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    throttledChange(e.target.value)
  }

  return <Box sx={{ flex: 5, marginRight: '24px' }}>
    <SearchInput value={searchValue}
      label={i18n.t("pryaniky.list.shop.search")}
      onChange={handleChange}
    />
  </Box>
}