import React from 'react'
import { IAnswerItem, cnAnswerItem, mapStateToProps, mapDispatchToProps } from './AnswerItem.index'
import './AnswerItem.scss'
import { connect } from 'react-redux'
// import { PostContentRender } from '../../../PostContentRender/PostContentRender';
// import Radio from '@material-ui/core/Radio';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import Radio from 'uielements/src/MaterialElements/Radio';

export const AnswerItemPresenter = ({ item, answersIds, setAnswerId, answered, highlightCorrectness, quizType, qtype }: IAnswerItem) => {

  let cls;
  let showDescription = highlightCorrectness;

  if (answered && highlightCorrectness && quizType !== 'Tags') {
    if (item.isRightAnswer && answersIds.includes(item.id)) {
      cls = cnAnswerItem('Right');
    } else if (answersIds.includes(item.id)) {
      cls = cnAnswerItem('Wrong');
    } else if (item.isRightAnswer && answersIds.length) {
      cls = cnAnswerItem('Right');
    } else {
      showDescription = false;
    }
  }

  return (
    <div>
      {qtype === 'Single' ? 
        <Radio
          onChange={() => setAnswerId({ id: item.id, single: true })}
          checked={answersIds[0] === item.id}
          label={item.text}
          disabled={answered}
          size={'small'}
          className={cls}
        /> :
        <Checkbox
          value={answersIds.includes(item.id)}
          onChange={() => setAnswerId({ id: item.id, single: false })}
          disable={answered}
          label={item.text}
          size='small'
          className={cls}
        />
      }
      {showDescription && answered && <div className={cnAnswerItem('CommentText')}>
        {item.description}
      </div>}
    </div>
  )
}

export const AnswerItem = connect(
  mapStateToProps,
  mapDispatchToProps)
  (AnswerItemPresenter)