import { getAuthUser } from "utils/src/CommonRedux/base/actions";
import { bindActionCreators, Action, Dispatch } from "redux"
import { IBodyProps } from 'utils/src/DialogCreator';
export type IWithQRLoginDialogStateProps = ReturnType<typeof withQRLogiMmapStateToProps>;

export interface IWithQRLoginDialogProps extends IBodyProps, IWithQRLoginDialogOwnProps, IWithQRLoginDialogDispatchProps, IWithQRLoginDialogStateProps { }

export interface IWithQRLoginDialogOwnProps { }

export interface IWithQRLoginDialogState {
  data?: any;
  comment: string;
  presentFor?: any;
  utilsApi?: any;
  url?: string;
  isShownDownloadApp: boolean;
}

export const withQRLogiMmapStateToProps = (state: any, props: IWithQRLoginDialogOwnProps) => {
  return {}
};
export type IWithQRLoginDialogDispatchProps = ReturnType<typeof mapWithQRLoginDialogDispatchToProps>

export const mapWithQRLoginDialogDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({}, dispatch)