// import './forIE.js';
// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
// import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import './polyfills'
import 'intersection-observer';
import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { Layout } from './blocks/Layout/Layout';
import * as serviceWorker from './serviceWorker';
import { DndProvider } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';
import { HTML5Backend } from 'react-dnd-html5-backend'

import { ConnectedRouter } from 'connected-react-router';
import { store } from './redux/store';
import { history } from './redux/browserHistory';
import { useLocation } from "react-router-dom";

import './fonts.scss';
import './assets/fonts/pryaniky-icons/css/pryaniky-icons.css';
import './assets/fonts/editor-icons/css/prn-editor-icons.css';
import './index.scss';
import 'utils/src/themes/default.css';
import './material.scss';
import { Route, useHistory } from 'react-router';
import { IStateType as IState } from 'redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getCookie, LS, setCookie } from 'utils/src/utils';
import { isAllowOpenNewsDialog } from './utils.project/utils.project';
import MomentUtils from '@date-io/moment'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import ruLocale from 'date-fns/locale/ru'

import { StylesProvider } from '@material-ui/core/styles';//v4
import { StyledEngineProvider } from '@mui/material/styles';//v5
import { StylesProvider as StylesProviderMui5 } from '@mui/styles';


(window as any).store = store

function ScrollToTop() {
  const { pathname, state } = useLocation();

  useEffect(() => {
    const allowOpenNews = isAllowOpenNewsDialog(pathname, state)
    !allowOpenNews && window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const LocationChangeBaseStatusCheck = connect(
  (state: IState) => ({
    authUser: getAuthUser(state),
    appSettings: state.store.appSettings
  })
)(function ({ authUser, appSettings }: any) {
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (authUser && appSettings && authUser.baseData.isFirstLogon && appSettings.enabledModules.welcome && !pathname.includes('/welcome'))
      history.push('/welcome');
    if (authUser && !authUser.baseData.isFirstLogon && getCookie(`firstLogon${authUser.baseData.id}`) !== 'false')
      setCookie({ name: `firstLogon${authUser.baseData.id}`, value: 'false' });
    // document.cookie = `firstLogon${authUser.baseData.id}=false; path=/;samesite=None; secure`;
  }, [pathname]);
  return null;
});

export default function render() {

  ReactDOM.render(
    <StylesProvider injectFirst={true}>{/*для mui v4 injectFirst=true что бы стили вставлялись первыми*/}
        <StyledEngineProvider injectFirst={false}>{/*для mui v5 injectFirst=true что бы вставлялись в жопку и переопределяли в4*/}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <LocationChangeBaseStatusCheck />
                <ScrollToTop />
                <Route component={Layout} />
              </ConnectedRouter>
            </Provider>
          </DndProvider>
          </MuiPickersUtilsProvider>
        </StyledEngineProvider>
    </StylesProvider>,
    document.getElementById('pryaniky-root-DOM')
  );
}

serviceWorker.unregister();
