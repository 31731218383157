import * as React from 'react';
import { store } from 'redux/store';
import * as utils from 'utils/src/utils';
import { v1 as uuid } from 'uuid';
import modalActions from 'redux/actionsTypes/Modals'
import { modalShowToggle, modalChangeDate, modalKill, modalSetData } from 'redux/actions/Modals';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from 'redux/connector';
import { Dialog } from 'uielements/src';
import { IStateType as IState } from 'redux/store';
import { IDialog, IDialogProps, IDialogOwnProps, ImapStateToProps, mapStateToProps } from './DialogWrapper.index';
import { integrationModes } from 'utils/src/constants.prn';

export const DialogReduxPresenter: React.FC<IDialogProps> = (props) => {
  if (!props.dataR) return null;
  const integatedMode = utils.getCookie('integrationMode');
  if (integatedMode === integrationModes.sharepoint && window.self !== window.top && window.location.pathname.indexOf('/wdialogs') === -1) {
    if (!props.dataR.isShow) return null;
    const conf_fid = uuid();
    const close_fid = uuid();
    window.PryanikyRpc.init().functions[conf_fid] = props.onConfirm;
    window.PryanikyRpc.init().functions[close_fid] = props.onClose;
    window.PryanikyRpc.message.post({ 
      name: 'modal', 
      type: 'init',
      props: {
        name: props.name.replace('Presenter', ''),
        opts: {
          from_frame_id: utils.searchObject(window.location.href).frame_id, 
        },
        data: props.dataR.data,
        confirm_function_id: conf_fid,
        close_function_id: close_fid
      }
    }, 'top');
    return null;
  }
  return (
    <Dialog onClose={props.onClose} isShown={props.dataR.isShow} id={props.id}>
      {props.children(props.dataR)}
    </Dialog>
  )
}

export const DialogRedux = connect<ImapStateToProps, IDispatchProps, IDialogOwnProps, IState>(
  mapStateToProps,
  mapDispatchToProps({})
)(
  DialogReduxPresenter
)

export function wrapDialogToRedux<T extends IDialog>(Wrapped: React.ComponentType<T>): React.ComponentType<T> {
  return class extends React.Component<T> {
    private inited: boolean = false;
    private modalName: string = Wrapped.name || (Wrapped as any).WrappedComponent.name;
    private wrappedComponent: string = Wrapped.name || (Wrapped as any).WrappedComponent.name;
    public id: string = uuid();
    public rootDomId: string = `Dialog-${this.id}`;

    constructor(props: T) {
      super(props);
      if (props.id) this.id = props.id;
      if (!this.inited) {
        store.dispatch({
          type: modalActions.INIT,
          payload: {
            id: this.id,
            data: {
              data: this.prepareData(props.data),
              isShow: false
            }
          }
        });
        this.inited = true;
      }
      const root = document.createElement('div');
      root.id = this.rootDomId;
      const rootModal = document.getElementById('rootModal');
      if (rootModal && rootModal.parentElement) {
        rootModal.parentElement.insertBefore(root, rootModal);
      } else {
        (document.getElementById('Layout') || document.body).append(root);
      }
    }

    public componentDidUpdate(pp: T) {
      // this.prepareData();
      if (!utils.compareData(this.props.isShown, pp.isShown)) store.dispatch(modalShowToggle({ id: this.id, data: this.props.isShown }))
      if (!utils.compareData(this.props.data, pp.data)) store.dispatch(modalChangeDate({ id: this.id, data: this.prepareData(this.props.data) }))
    }

    public prepareData = (data: any) => {
      if (!data) return {};
      if (
        ((Wrapped as any).WrappedComponent && typeof (Wrapped as any).WrappedComponent.file === 'boolean' && (Wrapped as any).WrappedComponent.file) ||
        (typeof (Wrapped as any).file === 'boolean' && (Wrapped as any).file)
      ) {
        return data;
      }
      return utils.clone(data);
    }

    public componentWillUnmount() {
      store.dispatch(modalKill({ id: this.id }));
      const rootDom = document.getElementById(this.rootDomId);
      if(rootDom) rootDom.remove();
    }

    public render() {
      return <DialogRedux id={this.id} onConfirm={this.props.onConfirm} onClose={this.props.onClose} children={this.renderChildren} name={this.modalName} />
    }

    public renderChildren = (props2: any) => {
      const preparedProps = Object.keys(this.props).reduce((acc, cur) => {
        if (cur !== 'id' && cur !== 'data' && cur !== 'onConfirm' && cur !== 'onClose') return {...acc, [cur]: (this.props as any)[cur]};
        return acc;
      } , {} as any);
      return <Wrapped
        id={this.id}
        {...preparedProps}
        {...props2}
        onChange={this.onChange}
        onClose={this.onClose} 
        onConfirm={this.props.onConfirm ? (...args: any[]) => {
          if (args.length > 0 && !args[0].target && !args[0].type && !args[0].nativeEvent) {
            this.onConfirm.apply(null, args);
          } else {
            this.onConfirm(props2.data);
          }
          store.dispatch(modalChangeDate({ id: this.id, data: this.prepareData(props2.data) }))
          // this.startData = JSON.parse(JSON.stringify(props2.data));
        } : undefined} />
    }

    private onConfirm = (...args: any[]) => {
      this.props.onConfirm && this.props.onConfirm.apply(null, args);
      this.onClose();
    }

    private onClose = () => {
      store.dispatch(modalSetData({ id: this.id, data: this.prepareData(this.props.data) }));
      this.props.onClose();
    }

    private onChange = (data: any) => {
      store.dispatch(modalSetData({ id: this.id, data }));
    }

  }
}