import Component, { Presenter } from './component';
import {
    IStudentsStatusOwnProps,
    IStudentsStatusProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter
}

export type IComponentProps = IStudentsStatusOwnProps;
export type IComponentPresenterProps = IStudentsStatusProps;


export default Component