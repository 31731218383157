import * as React from 'react';
import * as utils from 'utils/src/utils';

// import { withBemMod } from '@bem-react/core';

// import { cnWidget } from '../Widget.index';
// import { IWidgetTypeNewsProps } from './Widget_type_news.index';
import WidgetPresenter from '../Widget';
import { cnWidget, IWidgetProps } from '../Widget.index';
import { widgets } from "i.widgets";

import './Widget_type_news.scss';
// import { NewsView } from 'blocks/NewsView/NewsView';
import { NewsWidget } from 'blocks/NewsWidget/NewsWidget';

const type = 'news';


export default class WidgetTypeNewsPresenter extends WidgetPresenter<IWidgetProps> {
  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    return (
      <NewsWidget {...this.props} className={cnWidget({ type, subtype })} newsId={this.props.wcontext.nId} tag={widgets.components.common} />
      // <NewsView newsId={this.props.wcontext.nId} />
    );
  }
}