import React from 'react';
import { cnImageCropper, IImageCropperProps } from './ImageCropper.index';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { Translate } from 'localization/src/Translate';
import { Button } from 'muicomponents/src/Button/Button';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { TextField } from 'muicomponents/src/TextField/TextField';
import { InputAdornment } from '@material-ui/core';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';
import { HelpOutline } from '@mui/icons-material';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from 'muicomponents/src/Tooltip';
import './ImageCropper.scss';
import { toast } from 'react-toastify';
import { API, getCookie } from 'utils/src/utils';
import { validatePassword } from 'utils/src/passwords';
import MUILinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle'
import { DialogContent } from 'muicomponents/src/DialogParts/DialogContent'
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions'
import i18n from 'localization/src/i18n';
import { CropperWithPreview } from 'muicomponents/src/Cropper/WithPreview/CropperWithPreview'
import { Cropper as CropperPr } from 'muicomponents/src/Cropper/Cropper';
import { StyledBox, StyledCropperPreview } from "muicomponents/src/Cropper/WithPreview/CropperWithPreview.styled";
import * as utils from 'utils/src/utils';
import { useFileUploader } from 'utils/src/hooks'
import { useDialogContext } from 'utils/src/DialogCreator';

export const ImageCropperPresenter: React.FC<IImageCropperProps> = ({
  handleClose,
  editOpen,
  onLoaded,
  // file
}) => {
  const [fileL, setFileL] = React.useState<File | null>(null);
  const [onCroped, setOnCroped] = React.useState<File | null>(null);
  const [loadProzes, setLoadProzes] = React.useState<any>({
    loadingStatus: 'unstart',
    loadingPropgress: 0
  });
  const [preview, setPreview] = React.useState<React.ComponentProps<typeof StyledCropperPreview>['previewUrl']>('');
  const { open, } = useDialogContext();
  React.useEffect(() => {
    return () => { editOpen && editOpen(false) }

  }, []);
  const fileLoadStart = (event: any, file: any) => {
    setLoadProzes({ loadingStatus: 'progress' });
  }

  const flieLoadError = (event: any, file: any) => {
    setLoadProzes({ loadingStatus: 'error' });
    setTimeout(() => {
      setLoadProzes({
        loadingStatus: 'unstart',
        loadingPropgress: 0
      });
    }, 2000);
  }

  const fileLoadProgress = (event: any, file: any) => {
    setLoadProzes({ loadingPropgress: Math.ceil((event.loaded * 100) / event.total) });
  }

  const fileLoadFinish = (response: any, file: any) => {
    if (utils.checkResponseStatus(response)) {
      setLoadProzes({ loadingStatus: 'finish' });
      setTimeout(() => {
        setLoadProzes({
          loadingStatus: 'unstart',
          loadingPropgress: 0
        });
      }, 2000);
      onLoaded(response.data[0]);
      handleClose()
    } else {
      toast.error('file loading fail');
    }
  }
  const fileLoad = (file: any, additionalData: any = {}) => {
    utils.API.files.upload(file, {
      loadStart: (event: any, file: any) => {
        file.width = additionalData.width;
        file.height = additionalData.height;
        file.aspect = additionalData.aspect;
        fileLoadStart(event, file)
      },
      loadError: flieLoadError,
      loadProgress: fileLoadProgress,
      loadFinish: fileLoadFinish
    });

  };

  return (
    <div>
      <DialogTitle onClose={handleClose}>

        <Translate i18nKey={'pryaniky.modal.imageCropper.title'} />

      </DialogTitle>
      <DialogContent className={cnImageCropper('')}>
        < CropperWithPreview file={fileL}
          aspect={14.5 / 10}
          width={240}
          height={150}
          circularCrop={false}
          onFileSelect={(file) => setFileL(file)}
          onCropped={(file, previewUrl) => setOnCroped(file)}
          previewSizes={[240, 120, 60]} showButton />

      </DialogContent>
      <DialogActions
        acceptText={i18n.t('save')}
        closeText={i18n.t('pryaniky.shop.cancel')}
        disableAccept={!fileL}
        onAccept={() => {
          fileLoad(onCroped);
        }}
        isLoading={loadProzes.loadingPropgress !== 0}
        onClose={handleClose} />

    </div>
  )
}

export const ImageCropperDialog = createModal(ImageCropperPresenter, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: "#fff",
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});