import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { createSession, loadSession } from '../../redux/actions/LMSTeacherOffice'
import { IBodyProps } from '../modal'

export interface ICreateSessionProps extends IClassNameProps, ICreateSessionStateProps, IBodyProps, RouteComponentProps, ICreateSessionDispatchProps {
    tag?: 'div';
}

export interface ICreateSessionState {

}

export interface ICreateSessionStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    session: state.LMSTO.sessions[props.sid],
    // sessionsIds: state.LMSTO.courses.values[props.cid].courseSessions,
    // course: state.LMSTO.courses.values[props.cid],
    // units: state.LMSTO.moderation.units,
    // ids: state.LMSTO.moderation.results
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    createSession,
    loadSession
}, dispatch);

export type ICreateSessionDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnCreateSession = cn('TOCreateSession');