import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IWidget, IColumn } from 'i.widgets';
import { getStructure, setStructure, backup, preparePageName } from 'redux/actions/Widgets'
// import { RouteComponentProps } from 'react-router';
// import { IDispatchProps, mapDispatchToProps } from 'redux/connector';
import i18n from 'localizations/i18n';
import { IStateType as IState } from 'redux/store';

export interface IOtherData extends Object {
  [s: string]: any;
}

export interface IPageProps extends IClassNameProps, IPageOwnProps, IPageStateProps, IActionsPage {
  tag?: 'div';
  // page: {
  //   name: string;
  //   title?: string;
  //   data?: { [s: string]: any };
  //   params?: { [s: string]: any };
  // },
}

export interface IPageOwnProps {
  // context: { [s: string]: any }
  page: {
    name: string;
    /**
     * @deprecated
     */
    title?: string;
    data?: { [s: string]: any };
    params?: { [s: string]: any };
  },
  cbAfterGetData?: () => void;
  children?: any;
}

export interface IPageState {
  text?: string;
  layout?: IWidget;
}


// export interface IPageStateProps  {
//   edit: boolean;
//   saving: boolean;
//   structure: {[s: string]: any};
//   // widgets: {[s: string]: any};
//   // router: any;
//   layout: IWidget<IColumn[]>,
//   title: string;
//   context: { [s: string]: any };
// }

export type IPageStateProps = ReturnType<typeof mapStateToProps>

export interface IActionsPage {
  getStructure?: any;
  setStructure?: any;
  backup?: any;
}

export const mapActionsToPropsPage = {
  getStructure,
  setStructure,
  backup,
};

export function getPageStructure(structures: any, pageName: string): {[s: string]: any} {
  pageName.split('.').forEach(el => {
    if (structures) structures = structures[el];
    if (!structures) structures = undefined;
  })
  return structures;
}

export const mapStateToProps = (state: IState, props: IPageOwnProps) => ({
  edit: state.store.edit,
  saving: state.store.saving,
  // structure: getPageStructure(state.store.structure, props.page.name),
  // widgets: state.store.widgets,
  // structure: getPageStructure(state.widgets.structures, props.page.name.split('.').join('/')),
  loading: state.widgets.togglers.loading,
  structure: state.widgets.pages[state.widgets.structures[preparePageName(props.page.name)]],
  layout: state.widgets.pages[state.widgets.structures[preparePageName(props.page.name)]] ? state.widgets.widgets[state.widgets.pages[state.widgets.structures[preparePageName(props.page.name)]].layout] : undefined,
  // widgets: state.widgets.widgets,
  // router: state.router,
  /**
   * @deprecated
   */
  title: state.appSettings && state.appSettings.title !== '' ? state.appSettings.title : i18n.t('project name'),
  context: state.widgets.contexts.common
})


export const cnPage = cn('Page');