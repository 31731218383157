import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { 
    updateAnswer, 
    changeRadioAnswer, 
    toggleCheckAnswer, 
    setAnswerError 
} from '../../../redux/actions/LMSTeacherOffice'
import { getAnswersErrors } from '../selectors'
///////////

export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    aid: string;
    qid: string;
    uid: string;
    deleteAnswer: (aid: string) => void
    radio?: boolean;
    // onChange: (question: any) => void
}

export interface IStateProps {
    answer: any;
    errors: any;
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IOwnProps) => ({
    answer: state.LMSTO.answers[props.aid],
    errors: getAnswersErrors(state, props.aid)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    updateAnswer,
    changeRadioAnswer,
    toggleCheckAnswer,
    setAnswerError
}, dispatch);

////////////

export const cnClassName = cn('TOCEAnswerInput');