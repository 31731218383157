import { IBaseNews, createDefaultNews } from 'News/types/baseNews'
import { v1 as uuid } from "uuid";
import { customFieldsTypesNamesNumbersDiff, CUSTOM_FIELDS_TYPES_NAMES } from 'utils/src/constants.prn';

export interface ITask {
  id: string;
  text: string;
  isCompleted: boolean;
  requireAttach: boolean;
  requireComment: boolean;
  count: number;
  position: number;
  participants: any[];
}

export interface ITaskNews {
  title: string;
  // tasks: ITask[];
  // showParticipants: boolean;
  expires: Date;
  // eventStatus: string;
  // eventLocation: string;
  // eventParticipants: any[];
  // eventParticipantsCount: number;
  // state: string;
}

export const defaultTasksNewsTask: ITask = {
  id: uuid(),
  text: '',
  isCompleted: false,
  requireAttach: false,
  requireComment: false,
  count: 0,
  position: 0,
  participants: []
}

export interface ITaskFromItem {
  id: string;
  propertyName: string;
  name: string;
  description: string;
  fieldType: string | number; // CustomFieldTypes const customFieldsTypesNamesNumbersDiff
  isMandatory: boolean;
  visibility: string;
  isReadOnly: boolean;
  showOnIdeaCardView: boolean;
  maxLength: number;
  position: number;
  defaultValue: string;
  mask: string;
  category: string;
  displayMode: string;
  additionalParam1: string;
  // linkedFieldStatus: SIdeaStatusSmall;
  disableExpression: string;
  answers: any[];
  rows: any[];
  // public IEnumerable < SCustomFieldAnswerVariant > answers
  // public IEnumerable < SCustomFieldRow > rows
  requireComment: boolean;
  requireAttach: boolean;
  attachments: any[],
}

export const defaultTaskForm: ITaskFromItem = {
  id: uuid(),
  propertyName: '',
  name: '',
  description: '',
  fieldType: CUSTOM_FIELDS_TYPES_NAMES.TaskCheckBlock,
  isMandatory: false,
  visibility: '',
  isReadOnly: false,
  showOnIdeaCardView: false,
  maxLength: 0,
  position: 0,
  defaultValue: '',
  mask: '',
  category: '',
  displayMode: '',
  additionalParam1: '',
  disableExpression: '',
  answers: [],
  rows: [],
  requireComment: false,
  requireAttach: false,
  attachments: [],
}

export const generateTaskFormItem = (data: Partial<ITaskFromItem>) => ({
  ...defaultTaskForm,
  ...data,
  id: uuid(),
})

export interface ITasksNews extends IBaseNews {
  task: ITaskNews;
  forms: ITaskFromItem[];
}

export const defaultTasksNews: ITasksNews = {
  ...createDefaultNews(),
  newstype: 8,
  task: {
    title: 'new',
    // tasks: [defaultTasksNewsTask],
    // showParticipants: false,
    expires: new Date(),
    // eventStatus: '',
    // eventLocation: '',
    // eventParticipants: [],
    // eventParticipantsCount: 0,
    // state: ''
  },
  forms: []
}