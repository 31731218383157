import actions from 'redux/actionsTypes/Groups'

const defaultGroup: any = {
  groups: {},
  settings: {},
  procedding: {}
}

export function groupsReducer(state = defaultGroup, action: any) {
  switch (action.type) {
    case actions.ADD_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.payload.groupPKID]: action.payload.settings
        }
      }
    }

    case actions.UPDATE_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.payload.groupPKID]: {
            ...state.settings[action.payload.groupPKID],
            ...action.payload.fields
          }
        }
      }
    }

    case actions.REMOVE_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.payload.groupPKID]: undefined
        }
      }
    }

    case actions.ADD_GROUP: {
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.groupPKID]: action.payload.group
        }
      }
    }

    case actions.UPDATE_GROUP: {
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.groupPKID]: {
            ...state.groups[action.payload.groupPKID],
            ...action.payload.fields
          }
        }
      }
    }

    case actions.REMOVE_GROUP: {
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.groupPKID]: undefined
        }
      }
    }

    case actions.SET_PROCESSING: {
      const { groupPKID, pid, status } = action.payload
      return {
        ...state,
        procedding: {
          ...state.procedding,
          [`${action.payload.groupPKID}_${action.payload.pid}`]: {
            groupPKID, pid, status
          }
        }
      }
    }

    default: return state
  }
}

