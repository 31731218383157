import React from 'react'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

let debug = process.env.REACT_APP_NODE_ENV !== "production";

type ISkipType = {
    value: number,
    title: React.ReactNode,
    id: number,
}
const skip: ISkipType[] = [];
skip.push({
    value: 0,
    title: <Tt i18nKey={'test_edit.skip_type.0.1'}/>,//'Обязательный',
    id: 0,
})
debug && skip.push({
    value: 1,
    title: <Tt i18nKey={'test_edit.skip_type.1.1'} />,//'Необязательный',
    id: 1,
})
skip.push({
    value: 2,
    title: <Tt i18nKey={'test_edit.skip_type.2.1'} />,//'Стоп-тест',
    id: 2,
})

export const skipTypes = skip;

export const scoreCountModeEnum: { [k: number]: React.ReactNode } = {
    0: <Tt i18nKey={'test_edit.scoreCountMode.0.1'} />,//'Лучший результат',
    // 1: 'Средний результат',
    3: <Tt i18nKey={'test_edit.scoreCountMode.3.1'} />,//'Последний результат',
}
export const сountModes = [
    {
        value: 0,
        title: scoreCountModeEnum[0],
        id: 0,
    },
    {
        value: 1,
        title: scoreCountModeEnum[1],
        id: 1,
    },
    {
        value: 3,
        title: scoreCountModeEnum[3],
        id: 3,
    }
]
