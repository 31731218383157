import { IQuests } from './interfaces';

import {
  QUESTS_SET_AVAILABLE_QUESTS,
  QUESTS_CHANGE_QUEST
} from './actions';
import { IQuestsActions } from './actions.interfaces';

const baseState: IQuests = {
  availableQuests: undefined
}

export const questsReducer = (state = baseState, action: IQuestsActions): IQuests => {
  switch (action.type) {

    case QUESTS_SET_AVAILABLE_QUESTS: {
      return {
        ...state,
        availableQuests: action.payload
      }
    }
      
    case QUESTS_CHANGE_QUEST: {
      const { questId, data } = action.payload;
      if (!state.availableQuests) return state;
      return {
        ...state,
        availableQuests: {
          ...state.availableQuests,
          [questId]: {
            ...(state.availableQuests[questId]),
            ...data
          }
        }
      }
    }
    

    default:
      return state;
  }
}
