import React from 'react';
import { IShopCancelItemProps, cnCTShopItem, mapStateToProps, mapDispatchToProps } from './CurrencyTimelineCancelItem.index';
import PurseImg from 'assets/svg/purse.svg';
import { getCurrencyNameCase } from 'utils/src/utils';
import { Translate } from 'localizations/Translate';
import { connect } from 'react-redux';
// import { Button } from 'uielements/src';
import './CurrencyTimelineCancelItem.scss';
import BaseRequests from 'utils/src/requests/requests.base';
import { formatDateSTD } from 'utils/src/utils.dates';

const Presenter: React.FC<IShopCancelItemProps> = ({
  currencyNames,
  userSex,
  data
}) => {

  const shopIcon = BaseRequests.getUrl(`/Content/img/design/adminbase/shop.svg`, {}, undefined, undefined, true);

  const { value, isDeleted, transferDate } = data;

  return (
    <div className={cnCTShopItem('', ['Widget'])}>
      <div className={cnCTShopItem('Header')}>
        <span><Translate i18nKey='pryaniky.timeline.return' /></span>
        <div className={cnCTShopItem('Header-Info', [isDeleted ? 'Deleted' : 'Order'])}>
          <img src={PurseImg} />
          <span>{value}</span>
        </div>
        <span>{currencyNames[getCurrencyNameCase(value, true)]}</span>
      </div>

      <div className={cnCTShopItem('Content')}>

        <img src={shopIcon} />

        <div>
          <div>
            <span><Translate i18nKey='pryaniky.timeline.cancel' /></span>
            <div>{formatDateSTD(transferDate, true)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const CurrencyTimelineCancelItem = connect(mapStateToProps, mapDispatchToProps)(Presenter)