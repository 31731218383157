import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    scoreCountMode?: any;
    passingMinScore?: any;
    attemptDate?: string;
    attemptsCount?: any;
    maxAttemptsCount?: any;
    questionsCount?: any;
    isStart?: any;
    passingScoreSuccess?: 'wait' | 'success' | 'fail';
    attemptsSuccess?: any;
    unitSkipType?: number;
    unitType?: 'test' | 'task'
}

export const cnClassName = cn('TestInfoList');