import React, { FC, useState } from 'react'
import { IReasonThanksSelectorProps } from './ReasonThanksSelector.index'
import './ReasonThanksSelector.scss'
import { Button, Input, Icon, Dialog, DialogTitle, CustomScrollbars, CheckboxInput } from 'uielements/src';

export const itemInArray = (items: any[], value: string) => !items.find((u: any) => u.value === value);

export const toggleItemInArray = (items: any[], item: any) =>
    itemInArray(items, item.value) ? addItemToArray(items, item) : removeItemFromArray(items, item);

export const addItemToArray = (items: any[], item: any) => itemInArray(items, item.value) ? [item, ...items] : [...items];

export const removeItemFromArray = (items: any[], item: any) => items.filter((u: any) => u.value !== item.value)


export const ReasonThanksSelector: FC<IReasonThanksSelectorProps> = (
    {
        reason,
        selectedReasons,
        onChangeReason,
        maxThanks,
        minThanks,
        onChangeThanks,
        thanksCount,
        usersCount
    }) => {
    const [showDialog, setShowDialog] = React.useState(false);

    const onCloseDialog = () => setShowDialog(false);
    const openDialog = () => setShowDialog(true);

    const thankPerUser = thanksCount / usersCount;
    const minPerUser = minThanks / usersCount;
    const maxPerUser = maxThanks / usersCount;

    const onChangeItem = (item: any) => () => {
        onChangeReason(toggleItemInArray(selectedReasons, item))
    }
    const onChange = (value: number) => {
        // const value = e.target.value;
        if (value < minPerUser) return onChangeThanks(0);
        if (value >= maxPerUser) return onChangeThanks(maxThanks);
        onChangeThanks(value * usersCount)
    }
    return <div>
        <span onClick={openDialog}>select reason and thanks</span>
        <Dialog onClose={onCloseDialog} isShown={showDialog}>
            <DialogTitle onClose={onCloseDialog}>
                {/*i18n.t('pryaniky.createPost.badge.modal.title')
                <SearchInput onChange={onSearchBadges} throttle={0} />*/}
            </DialogTitle>
            <div className={'ThanksInput'}>
                <Button
                    data-direction="down"
                    onClick={(e: any) => onChange(thankPerUser - 1)}
                    className={'Button-Down'}
                    main
                    children={<Icon icon={'chevron-down'} />}
                    disabled={thankPerUser <= minPerUser}
                />
                <Input placeholder={''} className={'Input'} onChange={(e: any) => onChange(e.target.value)} type="number" value={thankPerUser <= 0 ? '' : thankPerUser} />
                <Button
                    data-direction="up"
                    onClick={(e: any) => onChange(thankPerUser + 1)}
                    className={'Button-Up'}
                    main
                    children={<Icon icon={'chevron-up'} />}
                    disabled={thankPerUser >= maxPerUser}
                />
            </div>
            <div className={'Part'}>
                <span className={'CurrentCount'}>
                    {'спишется'}:
            <span>{thanksCount}</span>
                </span>
                <span className={'CurrentCount'}>
                    {'осталось'}:
            <span children={maxThanks - thanksCount} />
                </span>
            </div>
            {
                //JSON.stringify(reason)
            }
            <div className={'SelectReasonsBox'}>
                {reason.map((cat, i) => (
                    <div key={i}>
                        <div className={'Reasons-CategoryName'} children={cat.label + ':'} />
                        {cat.options.map((reason: any, idx: number) => (
                            <CheckboxInput
                                key={reason.id}
                                onChange={onChangeItem(reason)}
                                checked={!itemInArray(selectedReasons, reason.value)}
                                text={reason.label}
                                value={reason.value}
                            />
                        ))}
                    </div>
                )
                )}
            </div>
        </Dialog>
    </div>
}