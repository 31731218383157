import { IAdditionalBoxProps } from './AdditionalBox.index';
import * as React from 'react'
import './AdditionalBox.scss'

export const AdditionalBox: React.FC<IAdditionalBoxProps> = ({ headerText, children }) => {
    return (
        <div className="IdeaWant-Supporters">
            <h3 className="IdeaWant-Header">{headerText}</h3>
            {children}
        </div>
    );
}