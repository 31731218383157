import React, { useState, useCallback, useEffect } from 'react';
import { createModal, LeftDirectionTransitionComponent } from 'utils/src/DialogCreator';
import { cnReferralModal, IReferralModalProps } from './ReferralModal.index';
import {
  // DialogTitle,
  // DialogContent,
} from 'uielements/src';
import './ReferralModal.scss';
import TextField from '@material-ui/core/TextField'
import Button from 'uielements/src/MaterialElements/Button/Button';

import {
  getReferralInvitationRequest,
  sendInvitationRequest,
} from 'utils/src/requests/requests.referral';
import { IReferralInvitationData } from 'utils/src/requests/models/api.referral';
import { copyTextToClip } from 'utils/src/utils';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { toast } from 'react-toastify';
import { Tabs, Loading } from 'uielements/src';
import { Alert, AlertTitle } from '@material-ui/lab';
import { isEmail, getErrorText } from 'utils/src/utils';
import { Translate } from 'localizations/Translate';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import i18n from 'localizations/i18n';

const tabsItems = [
  { value: 'email', title: <Translate i18nKey='pryaniky.referral.widget.modal.tab.email' /> },
  { value: 'sms', title: <Translate i18nKey='pryaniky.referral.widget.modal.tab.sms' /> },
  { value: 'link', title: <Translate i18nKey='pryaniky.referral.widget.modal.tab.link' /> },
] as const;

type tabsIds = typeof tabsItems[number]['value'];

const ReferralModalPresenter: React.FC<IReferralModalProps> = ({ handleClose, handleAccept }) => {

  const [loading, setLoading] = useState<boolean>(true);
  const [info, setInfo] = useState<IReferralInvitationData | null>(null);
  const [tab, setTab] = useState<tabsIds>('email');
  const [adresses, setAdresses] = useState<string[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);

  const getReferralInfo = (tab: tabsIds) => {
    getReferralInvitationRequest(tab).r.then(res => {
      // мерджим данные, что бы не перезаписывать пустыми данными
      let mergedData = info || res.data;
      switch (tab) {
        case 'email':
          mergedData = {
            ...mergedData,
            email: res.data.email
          }
          break;
        case 'link':
          mergedData = {
            ...mergedData,
            code: res.data.code
          }
          break;
        case 'sms':
          mergedData = {
            ...mergedData,
            smsText: res.data.smsText
          }
          break;
        default:
          break;
      }
      setInfo(mergedData);
      setLoading(false);
    });
  };

  const sendInvitation = () => {
    sendInvitationRequest(tab, adresses).r.then(res => {
      if (res.error_code === 0) {
        handleClose();
        toast.success(<Translate i18nKey='pryaniky.referral.widget.modal.sended' />);
      } else {
        handleClose();
        toast.error(getErrorText(res));
      }
    })
  }

  useEffect(() => {
    if (tab !== 'link')
      getReferralInfo(tab);
  }, [tab]);

  const onCopyLink = () => {
    toast.success(<Translate i18nKey='pryaniky.toast.success.copyLink' />);
    copyTextToClip(info?.code?.urlCode || '')
  }

  const handleChangeAdresses = (e: React.ChangeEvent<HTMLInputElement>) => {
    const adresses = e.target.value.split(/[ ,;]+/);
    setAdresses(adresses);
    if (tab === 'email') setIsValid(!adresses.some((adr) => isEmail(adr) === false))
    if (tab === 'sms') setIsValid(!adresses.some((adr) => !(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(adr))))
  }

  const handleChangeTab = (tab: tabsIds) => {
    if (tab !== 'link') setLoading(true);
    setTab(tab);
    setAdresses([]);
    setIsValid(false);
  }


  return <div className={cnReferralModal({}, ['CustomStyle'])}>
    <DialogTitle onClose={handleClose}>
      <Translate i18nKey='pryaniky.referral.widget.modal.title' />
    </DialogTitle>

    <DialogContent className={cnReferralModal('Body')}>
      <Tabs tabsList={tabsItems} selected={tab} onChange={handleChangeTab} />
      {
        loading ?
          <Loading />
          :
          <>
            {
              // если тип вкладки не сслыка то показывает блоки для остальных типов, иначе показываем только блок с ссылкой и возможностью ее скопировать
              tab !== 'link' ?
                <>
                  <TextField
                    className={cnReferralModal('Adresses')}
                    label={<Translate i18nKey={`pryaniky.referral.widget.modal.adresses.${tab}`} />}
                    variant='outlined'
                    value={adresses.join(',')}
                    onChange={handleChangeAdresses}
                  />

                  {(!!adresses.length && !isValid) && <Alert className={cnReferralModal('Validation')} severity="warning">
                    <AlertTitle>
                      {tab === 'email' && <Translate i18nKey='pryaniky.referral.widget.modal.validation.email' />}
                      {tab === 'sms' && <Translate i18nKey='pryaniky.referral.widget.modal.validation.phone' />}
                    </AlertTitle>
                  </Alert>}

                  <div className={cnReferralModal('Text')}>
                    <span className={cnReferralModal('Text-Title')}>
                      <Translate i18nKey='pryaniky.referral.widget.modal.text' />
                    </span>
                    <div className={cnReferralModal('Text-Email')}>
                      {tab === 'sms' && info?.smsText}
                      {tab === 'email' && <div dangerouslySetInnerHTML={{ __html: info?.email?.textFull || '' }} />}
                    </div>
                  </div>
                  {/* <DialogActions
                    acceptText={i18n.t('pryaniky.referral.widget.modal.send')}
                    disableAccept={!isValid}
                    onAccept={sendInvitation}
                    closeText={i18n.t('pryaniky.referral.widget.modal.close')} /> */}
                  {/* <Button disabled={!isValid} onClick={sendInvitation}>
                    <Translate i18nKey='pryaniky.referral.widget.modal.send' />
                  </Button> */}
                </>
                :
                // если тип вкладки ссылка, то пока не будет нажата кнопка "Сгенерировать ссылку" ссылка показываться не будет
                <>
                  {
                    info?.code?.urlCode &&
                    <div className={cnReferralModal('Link')}>
                      <div className={cnReferralModal('Link-Copy')}>
                        <span>{info?.code?.urlCode}</span>
                        <FileCopyIcon onClick={onCopyLink} />
                      </div>
                    </div>
                  }
                  {/* <DialogActions
                    acceptText={i18n.t('pryaniky.referral.widget.modal.generate')}
                    // disableAccept={!isValid}
                    onAccept={() => getReferralInfo(tab)} /> */}
                  {/* <Button onClick={() => getReferralInfo(tab)}>
                    <Translate i18nKey={`pryaniky.referral.widget.modal.generate`} variablesI18nKeys={info?.code?.urlCode ? { more: 'pryaniky.referral.widget.modal.generateMore' } : undefined} />
                  </Button> */}
                </>
            }
          </>
      }
    </DialogContent>
    {tab !== 'link' ? <DialogActions
      acceptText={i18n.t('pryaniky.referral.widget.modal.send')}
      disableAccept={!isValid}
      onAccept={sendInvitation}
      closeText={i18n.t('pryaniky.referral.widget.modal.close')}
      onClose={handleClose} /> : 
      <DialogActions
      acceptText={<Translate i18nKey={`pryaniky.referral.widget.modal.generate`} variablesI18nKeys={info?.code?.urlCode ? { more: 'pryaniky.referral.widget.modal.generateMore' } : undefined} />}
      // disableAccept={!isValid}
      onAccept={() => getReferralInfo(tab)}
      closeText={i18n.t('pryaniky.referral.widget.modal.close')}  
      onClose={handleClose}/>}
  </div>
}

export const referralModal = createModal(ReferralModalPresenter, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: '#fff',
    }
  },
  TransitionComponent: LeftDirectionTransitionComponent,
  className: 'Pane',
  scroll: 'body'
});