import React, { FC, useEffect, memo, useState } from 'react'
import {
    cnClassName,
    Skills
} from './interfaces'
import './style.scss'
import SoloAutocomplete from '@material-ui/lab/Autocomplete';
import withSuggester, { IItem } from 'uielements/src/MaterialElements/withSuggester'
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { UserList } from 'uielements/src/UserList/UserList';
import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n'
import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import { useResizeHandler } from 'utils/src/hooks'

type IPresenterProps = Skills.IPresenterProps

const FormControlWithHelp = fieldWithHelp(FormControl)
export const Presenter: FC<IPresenterProps> = ({
    className,
    setTitle,
    title,
    onComplete,
    currentUser,
    withError
}) => {
    const [elements, setElements] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [showActionButton, setShowBtn] = useState(false)

    const ref = useResizeHandler((el) => {
        if (el.clientWidth < 500) {
            setShowBtn(false)
        } else {
            setShowBtn(true)
        }
    })

    const onChangeText = (
        event: React.ChangeEvent<{}>,
        value: string = ''
    ) => {
        if (!event) return;
        setTitle(value)
        setLoading(true)
        const suggesterRequest = utils.API.suggesters.endorse(value)

        suggesterRequest.r.then((d: any) => {
            setLoading(false)
            if (utils.checkResponseStatus(d)) {
                setElements(d.data.map((item: any): IItem => item.displayName.replace(/_/gm, ' ')));
            }
        });
    }

    useEffect(() => {
    }, [])

    const complete = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        onComplete()
    }

    return <form ref={ref as any} className={cnClassName({}, [className])} onSubmit={complete}>
        <div className={cnClassName('Index')}>#</div>
        <div className={cnClassName('InputBox')}>
            <FormControlWithHelp fullWidth help={i18n.t("pryaniky.skills.create.input.help.1")} >
                <SoloAutocomplete
                    freeSolo
                    onInputChange={onChangeText}
                    loading={!withError && loading}
                    filterOptions={(items) => items}
                    defaultValue={title}
                    options={withError ? [] : elements}
                    renderInput={(params: any) => <TextField 
                        {...params} 
                        error={withError} 
                        helperText={withError && i18n.t("pryaniky.skills.create.input.error.1")} 
                        label={i18n.t("pryaniky.skills.create.input.1")} />}
                />
            </FormControlWithHelp>
        </div>
        {/* <div className={cnClassName('Users')}>
            <UserList
                underNext={true}
                className={cnClassName('UserList')}
                cut={false}
                action={false}
                actionBtnContent={<span className="AnswersValue">1</span>}
                data={[currentUser.baseData]}
                actionCallback={() => { }} />
        </div> */}
        {showActionButton && <div className={cnClassName('Action')}>

        </div>}
    </form>
}

export default memo(Presenter);
