import { bindActionCreators } from 'redux';
import { IStateType as IState } from 'redux/store';
import { cn } from '@bem-react/classname';
import { getCurrencyTimeline, changeCurrencyTimelineContext } from 'redux/currencyTimeline/actions';
import { RouteComponentProps } from 'react-router-dom';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface IBlock extends IMapStateProps, IMapDispatchProps, RouteComponentProps<IParams> {
 
}

export const mapStateToProps = (state: IState, { match }: RouteComponentProps<IParams>) => {
  // delete
  const d = 'c0923625-e644-4692-9a9e-711a730643b1';
  return {
    data: state.currencyTimeline.data,
    uid: getAuthUser(state).baseData.id
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getCurrencyTimeline,
  changeCurrencyTimelineContext
}, dispatch);

export interface IParams {
  id: string;
}

export type IMapStateProps = ReturnType<typeof mapStateToProps>;
export type IMapDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const cnCurrencyTimeline = cn('CurrencyTimeline');
