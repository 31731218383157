import React, { FC } from 'react'
import { IItemProps, cnItem } from './Item.index'
import { withRouter } from 'react-router-dom'
import { Button } from './Item.styled';
import { Check } from 'muicomponents/src/Icons';

export const ItemPresenter: FC<IItemProps> = ({ className, network, isBase = false, isCurrent }) => {
    return (
        <li className={cnItem({ base: isBase }, [className])}>
            <Button
                href={`${window.location.origin}/sites/${network.name}/dash`}
                className={cnItem('Btn')}
                disabled={isCurrent}
                target={'_self'}
            >
                {network.title === '' ? network.name : network.title}
                { isCurrent && <Check /> }
            </Button>
        </li>
    )
}

export const Item = withRouter(ItemPresenter)