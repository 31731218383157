import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
///////////

export type ITaskInfoProps = ITaskInfoOwnProps

export interface ITaskInfoState { }

export interface ITaskInfoOwnProps extends IClassNameProps {
    unitContent: any,
    fullscreen?: boolean,
    start: () => void,
    skip: () => void
}

export const cnClassName = cn('LMSTaskInfo');