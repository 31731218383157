import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import { getAppSettings, getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
import * as ACT from '../../../actions';
import * as SEL from '../../selectors'
import i18n from 'localizations/i18n';
import { toast } from 'react-toastify';
import { confirmProise } from 'uielements/src/Confirm/Confirm'
import { API, basename } from 'utils/src/utils'

const handleContextActionRedirectSuggestNews = function* handleContextActionRedirectSuggestNews({ payload }: AT.AContextAction) {
    const { action, id } = payload
    if (action.includes('redirect.')) {
        try {
            const [_, param] = action.split('.')
            const as = yield* select(getAppSettings)
            window.open(as.globalParams['gs_newsaction_redirect.' + param].replace('{newsuid}',id))

        } catch (error) {

        }
    }
    yield;

    return 0;
}

export default handleContextActionRedirectSuggestNews