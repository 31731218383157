import React from 'react'
import { cnReactionsItem } from './ReactionsItem.index'
import { IReactionsItemProps } from './ReactionsItem.index'
import './ReactionsItem.scss'

export const ReactionsItem = ({ item, onSelect }: IReactionsItemProps) => {
    return (
        <div className={cnReactionsItem()} onClick={() => onSelect(item)}>
            <img src={item.image} className={item.selected ? cnReactionsItem('ImageSelected') : cnReactionsItem('Image')} />
            <div className={cnReactionsItem('Tooltip')}>{item.label}</div>
            {/* {
                item.count > 0 &&
                <span className={item.selected ? cnReactionsItem('CountSelected') : cnReactionsItem('Count')}>{item.count}</span>
            } */}
        </div>
    )
}