import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { IStatisticProps, cnStatistic, mapStateToProps } from './Statistic.index';
import { connect } from 'react-redux';
import { sub, format } from 'date-fns';
import { Tabs } from 'uielements/src';
import i18n from 'localization/src/i18n';
import { LinearProgress } from '@material-ui/core';
import _ from 'lodash';
import './Statistic.scss';

import { getStatisticsRequest } from 'utils/src/requests/admin.statistics';
import { charts, statisticSpecificProps, availableStatisticsView, statisticContextFilterItems, statisticFilterItems } from './constants';
import { Translate } from 'localizations/Translate';


/**
 * MAIN CHARTS DONE
 * quizzes
 * ideas
 * quests
 * virtualcurrency
 * achievements
 * forms
 * users
 * 
 * NOT DONE
 * lms
 */


const Presenter: React.FC<IStatisticProps> = (props) => {

  const { tag: Tag = 'div', data, edit, context } = props;
  const statProps = statisticSpecificProps?.[data.module]?.[data.statistic] || {};
  const statContextFilters = statisticContextFilterItems?.[data.module]?.[data.statistic] || [];
  const statFilters = statisticFilterItems?.[data.module]?.[data.statistic] || [];

  const dateNow = new Date();
  const [statisticData, setStatisticData] = useState<any>();
  const [tabs, setTabs] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const getRequestParams = () => {
    const beginDate = context.beginDate || (data.datesType === 'custom' ? data.beginDate : sub(dateNow, { [`${data.datesType}s`]: 1 }));
    const endDate = context.endDate || (data.datesType === 'custom' ? data.endDate : dateNow);
    const query = (context.postTypes?.length && context.postTypes?.map((el: any) => el.id)) || data.postTypes?.map((el: any) => el.id);
    const idRules = context.achievementGoals?.map((goal: any) => goal.id) || data.achievementGoals?.map((goal) => goal.id)
  
    const fullParams = {
      groupBy: context.groupBy,
      beginDate: beginDate && format(new Date(beginDate), 'yyyy-MM-dd'),
      endDate: endDate && format(new Date(endDate), 'yyyy-MM-dd'),
      questId: context.quests?.[0]?.id || data.quests?.[0]?.id,
      quizId: context.quizzes?.[0]?.id || data.quizzes?.[0]?.id,
      badgeId: context.quizzes?.[0]?.id || data.badges?.[0]?.id,
      templateId: context.workflows?.[0]?.id || data.workflows?.[0]?.id,
      idRules,
      activityVirtualUsers: context.activityVirtualUsers,
      query, // желательно поменять название на бэке
    }

    return _.pick(fullParams, statContextFilters);
  }

  const getMultiRequestParams = (item: any) => {
    const result: any = {};
    switch (data.module) {
      case 'achievements':
        result.achievementId = item.id;
        break;
      default:
        break;
    }

    return result
  }

  const getChartData = () => {
    let result = null;
    if (data.chart === 'pie') {
      result = statisticData?.[activeTab];
    }
    else {
      if (statProps.multiChart) result = statisticData?.[activeTab]?.items;
      else result = statisticData;
    }
    return result;
  }

  const requestParams = getRequestParams();
  const getStatisticData = useCallback(() => {
    setLoading(() => true);
    if (!statProps.multiRequest) {
      getStatisticsRequest(data.module, data.statistic, requestParams)
        .then((resp) => {
          setStatisticData(resp.data);
          if (statProps.multiChart) {
            setTabs(resp.data?.map((el: any, idx: number) => ({ title: el.label, value: idx })));
          }
        }).finally(() => {
          setLoading(() => false);
        })
    } else {
      const key: string = statProps.paramField;

      const itemsData = context[key] || data[key];

      for (let i = 0; i < itemsData.length; i++) {
        (function (item, idx) {
          getStatisticsRequest(data.module, data.statistic, getMultiRequestParams(item))
            .then((resp) => {
              setStatisticData((statisticData: any) => [...(statisticData || []), resp.data]);
              setTabs((tabs: any) => [...(tabs || []), { value: tabs.length, title: item.title }]);
            })
        })(itemsData[i], i)
      }
      setLoading(() => false);
    }
  }, [requestParams])

  useEffect(() => {
    getStatisticData();
  }, [JSON.stringify(requestParams)])


  const Chart = charts[data.chart];
  const chartData = getChartData();

  const chartProps = {
    ...availableStatisticsView[data.module][data.statistic].props,
    maxItems: context.maxItems || data.maxItems
  };

  // if (edit) return <Tag {...props} className={cnStatistic()}></Tag>

  return (
    <Tag {...props} className={cnStatistic('', { toChange: statFilters.includes(context.focusedFilter) })}>
      <div className={cnStatistic('Title')}>
        {data.title || i18n.t(`pryaniky.statistics.title.${data.module}.${data.statistic}`)}
      </div>

      {
        statProps.multiChart &&
        <Tabs
          tabsList={tabs || []}
          selected={activeTab}
          onChange={setActiveTab}
        />
      }

      <div className={cnStatistic('Chart')}>
        {
          loading ? <div>
            <LinearProgress />
          </div> :
            chartData ?
              <Chart
                {...{ ...chartProps, ...statProps }}
                data={chartData}
              /> :
              <div><Translate i18nKey='pryaniky.statistics.widget.nodata' /></div>
        }

      </div>
    </Tag>
  )
}


export const Statistic = connect(mapStateToProps, undefined)(React.memo(Presenter));