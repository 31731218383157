import React, {FC} from 'react'
import { OrderBtn } from './OrderBlock.styled'
import { Typography } from 'muicomponents/src'
import { useShopListContext } from '../ShopList/ShopListMui.context'
import { mainUrls } from 'utils/src'
import { appGeneratePath } from 'utils/src/utils.path'
import { useLocation } from 'react-router'


interface IOrderBlockButtonProps {
    text: string
    id: string | number
    variant?: 'contained' | 'outlined' | 'text'
    disabled?: boolean
    errorColor?: boolean
    cb?: () => void
}

export const OrderBlockButton: FC<IOrderBlockButtonProps> = ({
    text,
    id,
    variant = 'contained',
    disabled,
    errorColor = false,
    cb,
}: IOrderBlockButtonProps) => {

    const location = useLocation();
    
    const context = useShopListContext();

    return <OrderBtn variant={variant} fullWidth
        disabled={disabled}
        to={{
            pathname: appGeneratePath(mainUrls.shopProduct.id, { id }, { userId: context.userId }),
            state: {
                from: location
            } 
        }}
        onClick={() => cb && cb() }
    >
        <Typography color={errorColor ? 'error' : 'unset'} fontSize='small'>
            {text}
        </Typography>
    </OrderBtn>
}