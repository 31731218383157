import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions'

///////////

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace SUPPORT {
    // eslint-disable-next-line @typescript-eslint/no-namespace

    export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & RouteComponentProps

    export interface IState { }

    export interface IOwnProps extends IClassNameProps {
    }

    export interface IStateProps {
        currentUser: any
    }

    export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

}

////////////

type IOwnProps = SUPPORT.IOwnProps

export const mapStateToProps = (state: any, {  }: IOwnProps) => ({
    currentUser: getAuthUser(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({

}, dispatch);

////////////

export const cnClassName = cn('SUPPORT');