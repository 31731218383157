import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { IWSettingsOnlyProps } from 'blocks/WSettings/WSettings.index'
import { IClassNameProps } from '@bem-react/core';
import { IBodyProps } from 'utils/src/DialogCreator'

export interface ISettingsDialogResult {

}

export interface ISettingsDialogProps extends IClassNameProps, IWSettingsOnlyProps, IBodyProps<any> {

}
////////////

export const cnClassName = cn('SecretSettingDialog');