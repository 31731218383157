import * as React from 'react';
// import * as utils from 'utils/src/utils';


import { withBemMod } from '@bem-react/core';

import { cnBody } from '../Modal-Body.index';
import { IBodyTypeConfirmProps } from './Modal-Body_type_confirm.index';
import './Modal-Body_type_confirm.scss';


export const withBodyTypeConfirm = withBemMod<any>(
  cnBody(),
  { type: 'confirm' },
  (Presenter) => (
    (props: IBodyTypeConfirmProps) => (
      <div className={cnBody({}, props.className && props.className.split(' '))}>zx</div>
      // <Presenter {...props}>{props.parent.data.text}</Presenter>
    )
  )
);