import {
    takeEvery,
    takeLeading,
    takeLatest,
    throttle,
    cancel,
    fork
} from 'redux-saga/effects';
import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';


import { routerActions, getLocation } from 'connected-react-router';

import {
    API,
    objectArrayToMap
} from 'utils/src/utils'

import {
    ITrack,
    ILearningFile
} from 'utils/src/requests/models/api.learningTracks'

import {
    IBaseNews
} from 'News/types/baseNews'
import {
    getNewsById
} from 'News/redux/saga/selectors'

import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

import actions from '../../actionsTypes/TracksUse';

import {
    loadTracksListActionType,
    setTracksList,
    changeField,
    setFilesList,
    loadFilesActionType,
    sendNewsLearnedType
} from '../../actions/TracksUse'


import uuid from 'uuid';


import queryString from 'query-string';

import {
    appendNewsList,
    pathNews
} from 'News/redux/actions'
import * as SELNews from 'News/redux/saga/selectors'
// import * as ACTNews from 'News/redux/actions'


import {
    normalizeNews
} from 'News/redux/saga/utils'

const zeroId = "00000000-0000-0000-0000-000000000000";

/**
 * 
 * @param action 
 */
export const handleLoadTracksList = function* handleLoadTracksList(action: loadTracksListActionType): any {
    try {
        const { } = action.payload
        yield* put(changeField({
            field: 'isLoading',
            value: true,
            root: 'tracks'
        }))

        const currentUser = yield* select(getAuthUser)

        const request = yield* call(API.learningTracks.getUserTrackList, currentUser.baseData.id)
        const result = yield request.r

        if (result.error_code === 0) {
            const data = objectArrayToMap<ITrack, { tag: ITrack }>(result.data, 'tag.tagId', (value) => value.tag)
            yield* put(setTracksList({ data, keys: Object.keys(data) }))
        }
    } catch (error) {
        console.warn(error)
    }

    yield* put(changeField({
        field: 'isLoading',
        value: false,
        root: 'tracks'
    }))
    yield;
    return 0;
}


/**
 * 
 * @param action 
 */
export const handleLoadFiles = function* handleLoadFiles(action: loadFilesActionType): any {
    try {
        const { tags = [] } = action.payload
        yield* put(changeField({
            field: 'isLoading',
            value: true,
            root: 'files'
        }))
        const request = yield* call(API.learningTracks.getTrackFiles, action.payload)
        const result = yield request.r

        if (result.error_code === 0) {

            yield* put(appendNewsList({
                ...normalizeNews(result.data),
                group: 'lms_tracks_view_' + tags.join('_')
            }))
            const values = objectArrayToMap<ILearningFile, ILearningFile>(result.data, 'id')
            const keys = Object.keys(values)
            yield* put(setFilesList({ keys, values, trackId: tags.join('_') }))
        }
    } catch (error) {
        console.warn(error)
    }
    yield* put(changeField({
        field: 'isLoading',
        value: false,
        root: 'files'
    }))
    yield;
    return 0;
}


/**
 * 
 * @param action 
 */
export const handleNewsLearned = function* handleNewsLearned(action: sendNewsLearnedType): any {
    try {
        const { id, isComplete } = action.payload

        const news: IBaseNews = yield* select(SELNews.getNewsById(id))

        const request = yield* call(API.news.action, id, action.type, true)
        const result = yield request.r
        if (result.error_code === 0) {
            const actionButtons = news.actionButtons.filter(a => a.action.type !== action.type)
            yield* put(pathNews({
                id,
                actionButtons
            }))
        }
        else {
            // toast.error(result.error_text);
        }

    } catch (error) {
        console.warn(error)
    }

    yield;
    return 0;
}

export const handleGoToFile = function* handleGoToFile(action: any): any {
    try {
        const { id, isComplete } = action.payload
        const news = yield* select(getNewsById(id)) as any;

        // switch (news.learningTrack.type) {
        //     case 'LMSCources': {
        yield* put(routerActions.push(news.learningTrack.url))
        //     } break;
        //     case "Quizzes": {
        //         yield* put(routerActions.push(news.learningTrack.url))
        //     } break;
        //     case "PagesV2": {
        //         yield* put(routerActions.push(news.learningTrack.url))
        //     } break;
        // }

        // yield* put(routerActions.push(``))
    } catch (error) {
        console.warn(error)
    }

    yield;
    return 0;
}



/**
 * module root saga
 */
const root = function* root() {
    yield takeLeading(actions.LOAD_TRACKS, handleLoadTracksList)
    yield takeLeading(actions.LOAD_FILES, handleLoadFiles)
    yield takeLeading(actions.News_LEARNED, handleNewsLearned)
    yield takeLeading(actions.NEWS_LEARNED, handleNewsLearned)
    yield takeLeading(actions.PagesV2_LEARNED, handleGoToFile)
    yield takeLeading(actions.Quizzes_LEARNED, handleGoToFile)
    yield takeLeading(actions.LMSCources_LEARNED, handleGoToFile)
};

/**
 * export root saga
 */
export default root;