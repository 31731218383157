import { pick, Many, PropertyPath, first } from 'lodash'

/**
 * 
 * declarate store selectors
 */
type stateType = any//затычка

/**
 * достаёт стейт для кабинета препода
 * @param state 
 */
export const getLMSTO = (state: stateType) =>
    state.LMSTO;

/**
 * достаёт объект со статистиками
 * @param state 
 */
export const getStatistic = (state: stateType) =>
    getLMSTO(state).statistic

/**
 * 
 * @param state 
 * @returns 
 */
export const getStatisticFull = (state: stateType) =>
    getLMSTO(state).statisticFull

/**
 * 
 * @param state 
 * @returns 
 */
export const getFullStatisticSettings = (state: stateType) =>
    getStatisticFull(state).settings

/**
 * 
 * @param state 
 * @returns 
 */
export const getUserscoursesinfohead = (state: stateType) =>
    getStatisticFull(state).userscoursesinfohead

/**
* 
* @param state 
* @returns 
*/
export const getUserscoursesinfolist = (state: stateType) =>
    getStatisticFull(state).userscoursesinfolist

/**
* 
* @param state 
* @returns 
*/
export const getCoursesStatList = (state: stateType) =>
    getStatisticFull(state).coursesStatList

/**
* вернёт указаные параметры
* @param params 
*/
export function getCoursesStatListParams<T extends any, U extends keyof T>(...params: Array<Many<U>>) {
    return (state: stateType) => {
        const coursesStatList = getCoursesStatList(state) as T
        return pick(coursesStatList, ...params);
    }
}


/**
* вернёт указаные параметры
* @param params 
*/
export function getUserscoursesinfolistParams<T extends any, U extends keyof T>(...params: Array<Many<U>>) {
    return (state: stateType) => {
        const userscoursesinfolist = getUserscoursesinfolist(state) as T
        return pick(userscoursesinfolist, ...params);
    }
}

/**
* 
* @param params 
*/
export const getUserStatCoursesList = (state: stateType) =>
    getStatisticFull(state).userStatCoursesList


/**
* 
* @param params 
*/
export const getUserStatCoursesListValues = (state: stateType) =>
    getUserStatCoursesList(state).values


/**
* вернёт указаные параметры
* @param params 
*/
export function getUserStatCoursesListParams<T extends any, U extends keyof T>(...params: Array<Many<U>>) {
    return (state: stateType) => {
        const userStatCoursesList = getUserStatCoursesList(state) as T
        return pick(userStatCoursesList, ...params);
    }
}


/**
* 
* @param state 
* @returns 
*/
export const getUserStatCoursesListValueById = (id: string) => (state: stateType) =>
    getUserStatCoursesListValues(state)[id]

/**
* 
* @param state 
* @returns 
*/
export const getUserscoursesinfolistKeys = (state: stateType) =>
    getUserscoursesinfolist(state).keys

/**
* 
* @param state 
* @returns 
*/
export const getUserscoursesinfolistFinished = (state: stateType) =>
    getUserscoursesinfolist(state).isFinished

/**
* 
* @param state 
* @returns 
*/
export const getUserscoursesinfolistValues = (state: stateType) =>
    getUserscoursesinfolist(state).values

/**
* 
* @param state 
* @returns 
*/
export const getCoursesStatListValues = (state: stateType) =>
    getCoursesStatList(state).values

/**
* 
* @param state 
* @returns 
*/
export const getCoursesStatListValueById = (id: string) => (state: stateType) =>
    getCoursesStatListValues(state)[id]

/**
* 
* @param state 
* @returns 
*/
export const getUserscoursesinfolistValueById = (id: string) => (state: stateType) =>
    getUserscoursesinfolistValues(state)[id]


/**
 * достаёт статистику - КОММЕНТАРИИ К УРОКАМ
 * @param state 
 */
export const getStatCommentsCount = (state: stateType) =>
    getStatistic(state).commentscount

/**
* достаёт статистику - ГЛУБИНА ПРОХОЖДЕНИЯ КУРСА
* @param state 
*/
export const getUnitsAttandance = (state: stateType) =>
    getStatistic(state).unitsattendance

/**
 * комментарии к юнитам
 * @param state 
 */
export const getUnitsRelpys = (state: stateType) =>
    getLMSTO(state).unitsReplys

/**
 * достаёт статистику по статусам студентов
 * @param state 
 */
export const getStudentsStatus = (state: stateType) =>
    getStatistic(state).studentsstatus


/**
* достаёт статистику по прохождению юнитов
* @param state 
*/
export const getTestResults = (state: stateType) =>
    getStatistic(state).testresults

/**
 * 
 * @param state 
 */
export const getAllowTestPlay = (state: stateType) =>
    getLMSTO(state).allowTestPlay

/**
 * достаёт список объектов крусов в формате key:value где key id курса
 * @param state 
 */
export const getCoursesMap = (state: stateType) =>
    getLMSTO(state).courses.values

/**
* достаёт список объектов sessions в формате key:value где key id session
* @param state 
*/
export const getSessionsMap = (state: stateType) =>
    getLMSTO(state).sessions


/**
* достаёт Session по id
* @param sid id Session
*/
export const getSessionById = (sid: string) =>
    (state: stateType) =>
        getSessionsMap(state)[sid]

/**
 * вурнет ид сессии если она единственная активная
 * @param cid 
 */
export const getSessionIdIfSingle = (cid: string) =>
    (state: stateType) => {
        const active = getCourseById(cid)(state)?.activeSessions
        return active?.length === 1 ? first<string | undefined>(active) : undefined
    }



/**
 * достаёт список объектов полных юнитов в формате key:value где key - id юнита
 * @param state 
 */
export const getUnitsMap = (state: stateType) =>
    getLMSTO(state).units

/**
 * достаёт коллекцию ответов key:value где key - id ответа
 * @param state 
 */
export const getAnswersMap = (state: stateType) =>
    getLMSTO(state).answers

/**
 * достаёт коллекцию вопросов key:value где key - id вопроса
 * @param state 
 */
export const getQuestionsMap = (state: stateType) =>
    getLMSTO(state).questions

/**
 * достаёт курс по id
 * @param cid id курса
 */
export const getCourseById = (cid: string) =>
    (state: stateType) =>
        getCoursesMap(state)[cid]

/**
 * достаёт полный объект юнита по его id
 * @param uid id юнита
 */
export const getUnitById = (uid: string) =>
    (state: stateType) =>
        getUnitsMap(state)[uid]

/**
 * достёт индекс юнита в списке юнитов курса
 * @param uid 
 * @param cid 
 */
export const getUnitIndexById = (uid: string, cid: string) =>
    (state: stateType) =>
        getUnitsListByCourse(cid)(state).findIndex((u: any) => u.id === uid)

/**
 * достаёт список юнитов курса по ид курса
 * @param cid ид курса
 */
export const getUnitsListByCourse = (cid: string) =>
    (state: stateType) =>
        getCourseById(cid)(state).courseUnits

/**
 * достаёт вопрос по id вопроса
 * @param qid ид вопроса
 */
export const getQuestionById = (qid: string) =>
    (state: stateType) =>
        getQuestionsMap(state)[qid];

/**
 * достаёт вопросы по id юнита, возвращает в виде массива
 * @param uid id юнита, для которого нужно получить вопросы
 */
export const getQuestionByUnitId = (uid: string) =>
    (state: stateType) =>
        getUnitById(uid)(state).questions.map((qid: string) => getQuestionById(qid)(state))

/**
 * достаёт ответ по его id
 * @param aid id ответа
 */
export const getAnswerById = (aid: string) =>
    (state: stateType) =>
        getAnswersMap(state)[aid]

/**
 * достаёт массив ответов на вопрос по id вопроса
 * @param qid ид вопроса
 */
export const getAnswersByQuestionId = (qid: string) =>
    (state: stateType) =>
        getQuestionById(qid)(state).answers.map((aid: string) =>
            getAnswerById(aid)(state))

/**
 * достаёт объект информации о студентах
 * @param state 
 */
export const getStudents = (state: stateType) =>
    getLMSTO(state).students



/**
 * результаты студента в курсе
 */
export const getCourseUserStudentResults = (cid: string, uid: string) => (state: string) => {
    const course = getCourseById(cid)(state)
    return course?.studentesResults?.[uid]
}

/**
 * сессия студента в курсе
 * @param cid 
 * @param uid 
 * @param sid 
 */
export const getUserSessionById = (cid: string, uid: string, sid: string) =>
    (state: stateType) => {
        const results = getCourseUserStudentResults(cid, uid)(state)
        if (!results?.sessions) return undefined
        return results.sessions.find((session: any) => session.id === sid)
    }