import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IDispatchProps } from '../../redux/connector';

export interface ISrtestProps extends IClassNameProps, ISrtestStateProps, IDispatchProps {
  tag?: 'div';
}

export interface ISrtestState {
  text?: string;
}

export interface ISrtestStateProps {
  store: any
}

export const mapStateToProps = (state: any) => ({
  store: state.store
})

export const cnSrtest = cn('Srtest');