import { ICalendarBoxProps, cnCalendar } from './Calendar.index'
import React, { memo } from 'react'
import './Calendar.scss';
import { makeDate } from '../Utils'

export const Calendar: React.FC<ICalendarBoxProps> = memo(({ 
    startDateTime, 
    variant = 'red',
    ...props }) => {

    return <div className={cnCalendar({ variant }, [])} {...props}>
        <div className={cnCalendar('Day')}>{makeDate.day(startDateTime)}</div>
        <div className={cnCalendar('Month')}>{makeDate.month(startDateTime)}</div>
    </div>
})