import { ReactNode } from 'react';
import { BadgesNews } from './Badges.type'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { NewsContextProps } from 'News/contexts/news'
import {
    getNewsParamsById
} from 'News/redux/saga/selectors'
export interface IBadgesPropsType extends NewsContextProps {
}
export type TStateProps = ReturnType<typeof mapStateToProps>

export interface IBadgesStateType {

}

const newsParams = [
    'text',
] as const

export const mapStateToProps = (state: any, { newsId }: IBadgesPropsType) => ({
    userData: getCurrentUser(state),
    ...getNewsParamsById<BadgesNews, typeof newsParams[number]>(newsId, newsParams)(state)
})
