// import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IBaseRouteProps } from '../../i.model';
import { ActionCreator, AnyAction } from 'redux';

export interface ILoginProps extends IBaseRouteProps {
  setAPIDomain: ActionCreator<AnyAction>;
  action?: () => {};
  layout: React.Component<any, any>;
  setBaseData: (callback?: () => void) => void;
}

export interface ILoginState {
  text?: string;
  request: boolean;
  companyName: string;
  login: string;
  password: string;
  rememberMe: boolean;
  errorMessage: string | string[];
  [s: string]: any;
}

export interface ILoginData {
  companyName: string;
  login: string;
  password: string;
  rememberMe?: boolean;
}

export const cnLogin = cn('Login');