import { NewsContextProps } from 'News/contexts/news'
import { ReplyContextProps } from 'News/contexts/reply'
import { RewardReplyes } from '../CreativetasksType'
import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    rewardReply
} from '../redux/actions'
import {
    getCommentReward
} from '../redux/selectors'
import {
    getNewsById
} from 'News/redux/saga/selectors'

export type IRewardActionPresenterProps = IRewardActionOwnProps & IStateProps & IDispatchProps

export interface IState { }

export interface IRewardActionOwnProps extends IClassNameProps, ReplyContextProps {
}

export interface IStateProps {
    reward: number
    newsAuthor: any
    fundIsZero: boolean
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>


export const mapStateToProps = (state: any, { newsId, replyId}: IRewardActionOwnProps) => ({
    reward: getCommentReward(newsId, replyId)(state),
    newsAuthor: getNewsById(newsId)(state).user,
    fundIsZero: (getNewsById(newsId)(state) as any).creativeTask.fund === 0
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IRewardActionOwnProps) => bindActionCreators({
    rewardReply
}, dispatch);

////////////

export const cnClassName = cn('CTRewardAction');