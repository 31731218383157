import actions from '../actionsTypes/TracksManage';
import { CreateTrackParams } from 'utils/src/requests/models/api.learningTracks'
import { ILearningTrack, ILearningFile, GetManageListParams, GetTrackFilesParams, GetTrackUsersParams } from 'utils/src/requests/models/api.learningTracks'

const zeroId = "00000000-0000-0000-0000-000000000000";

//////////////////////////////////////////////////////////////////////////////////////////////////////

/********************************************************/
/******************** to saga ***************************/
/********************************************************/

/******************************* LOAD_TRACKS *******************************/
export type loadTracksListPayloadType = GetManageListParams & { clean?: boolean }
export type loadTracksListActionType = { type: string, payload: loadTracksListPayloadType }
/**
 * получить список планов для управления
 */
export const loadTracksList = (payload: loadTracksListPayloadType = {}): loadTracksListActionType => ({
    type: actions.LOAD_TRACKS,
    payload
})

/******************************* LOAD_TRACK_BY_ID *******************************/
export type loadTrackByIdPayloadType = { id: string }
export type loadTrackByIdActionType = { type: string, payload: loadTrackByIdPayloadType }
/**
 * получить список планов для управления
 */
export const loadTrackById = (payload: loadTrackByIdPayloadType): loadTrackByIdActionType => ({
    type: actions.LOAD_TRACK_BY_ID,
    payload
})


/******************************* CREATE_TRACK *******************************/
export type createTrackPayloadType = CreateTrackParams
export type createTrackActionType = { type: string, payload: createTrackPayloadType }
/**
 * создать план
 */
export const createTrack = (payload: createTrackPayloadType): createTrackActionType => ({
    type: actions.CREATE_TRACK,
    payload
})

/******************************* REMOVE_TRACK *******************************/
export type removeTrackPayloadType = { id: string[] }
export type removeTrackActionType = { type: string, payload: removeTrackPayloadType }
/**
 * отправить запрос на удаление плана
 */
export const removeTrack = (payload: removeTrackPayloadType): removeTrackActionType => ({
    type: actions.REMOVE_TRACK,
    payload
})


/******************************* ADD_FILE *******************************/
export type addFilePayloadType = { tagId: string, uid: string, type: string }
export type addFileActionType = { type: string, payload: addFilePayloadType }
/**
 * добавить новость в учбный план
 */
export const addFile = (payload: addFilePayloadType): addFileActionType => ({
    type: actions.ADD_FILE,
    payload
})


/******************************* LOAD_USERS *******************************/
export type loadUsersPayloadType = GetTrackUsersParams
export type loadUsersActionType = { type: string, payload: loadUsersPayloadType, groups?: boolean }
/**
 * получить список пользователей подписаных на план
 */
export const loadUsers = (payload: loadUsersPayloadType, groups?: boolean): loadUsersActionType => ({
    type: actions.LOAD_USERS,
    payload,
    groups
})


/******************************* LOAD_FILES *******************************/
export type loadFilesPayloadType = GetTrackFilesParams
export type loadFilesActionType = { type: string, payload: loadFilesPayloadType }
/**
 * добавить новость в учбный план
 */
export const loadFiles = (payload: loadFilesPayloadType): loadFilesActionType => ({
    type: actions.LOAD_FILES,
    payload
})


/******************************* ADD_USERS_TO_TRACK *******************************/
export type laddUsersToTrackPayloadType = { trackId: string, userIds: string[], users: any[] }
export type addUsersToTrackActionType = { type: string, payload: laddUsersToTrackPayloadType }
/**
 * подписать пользователей на план
 */
export const addUsersToTrack = (payload: laddUsersToTrackPayloadType): addUsersToTrackActionType => ({
    type: actions.ADD_USERS_TO_TRACK,
    payload
})


/******************************* MOVE_FILE *******************************/
export type moveFilePayloadType = { trackId: string, oldIndex: number, newIndex: number }
export type moveFileActionType = { type: string, payload: moveFilePayloadType }
/**
 * подписать пользователей на план
 */
export const moveFile = (payload: moveFilePayloadType): moveFileActionType => ({
    type: actions.MOVE_FILE,
    payload
})

/******************************* SET_FILES_POSITIONS *******************************/
export type setFilesPositionsPayloadType = { trackId: string, positions: string[] }
export type setFilesPositionsActionType = { type: string, payload: setFilesPositionsPayloadType }
/**
 * сохранить позиции материалов
 */
export const setFilesPositions = (payload: setFilesPositionsPayloadType): setFilesPositionsActionType => ({
    type: actions.SET_FILES_POSITIONS,
    payload
})

/******************************* UPDATE_TRACK *******************************/
export type updateTrackPayloadType = any
export type updateTrackActionType = { type: string, payload: updateTrackPayloadType }
/**
 * изменить трек
 */
export const updateTrack = (payload: updateTrackPayloadType): updateTrackActionType => ({
    type: actions.UPDATE_TRACK,
    payload
})


/******************************* REMOVE_FILE *******************************/
export type removeFilesPayloadType = string[]
export type removeFilesActionType = { type: string, payload: removeFilesPayloadType }
/**
 * удалить материалы из трека
 */
export const removeFiles = (payload: removeFilesPayloadType): removeFilesActionType => ({
    type: actions.REMOVE_FILE,
    payload
})


/******************************* REMOVE_USERS *******************************/
export type removeUsersPayloadType = { users: any[], tagName: string }
export type removeUsersActionType = { type: string, payload: removeUsersPayloadType }
/**
 * отписка пользователей от группы
 */
export const removeUsers = (payload: removeUsersPayloadType): removeUsersActionType => ({
    type: actions.REMOVE_USERS,
    payload
})


/******************************* LOAD_USERS_STAT *******************************/
export type loadUsersStatPayloadType = { tagId: string, count: number, skipCount: number, search?: string, clean?: boolean }
export type loadUsersStatActionType = { type: string, payload: loadUsersStatPayloadType }
/**
 * получить статистику по поьзователям
 */
export const loadUsersStat = (payload: loadUsersStatPayloadType): loadUsersStatActionType => ({
    type: actions.LOAD_USERS_STAT,
    payload
})



/******************************* LOAD_MATERIAL_STAT *******************************/
export type loadMaterialStatPayloadType = { tagId: string, count: number, skipCount: number, search?: string, clean?: boolean }
export type loadMaterialStatActionType = { type: string, payload: loadMaterialStatPayloadType }
/**
 * получить статистику по материалам
 */
export const loadMaterialStat = (payload: loadMaterialStatPayloadType): loadMaterialStatActionType => ({
    type: actions.LOAD_MATERIAL_STAT,
    payload
})








/********************************************************/
/******************** to reducer ************************/
/********************************************************/


/******************************* LOAD_USERS_STAT *******************************/
export type setUsersStatPayloadType = { tagId: string, users: any, clean?: boolean }
export type setUsersStatActionType = { type: string, payload: setUsersStatPayloadType }
/**
 * получить статистику по поьзователям
 */
export const setUsersStat = (payload: setUsersStatPayloadType): setUsersStatActionType => ({
    type: actions.SET_USERS_STAT,
    payload
})



/******************************* LOAD_USERS_STAT *******************************/
export type setMaterialStatPayloadType = { tagId: string, materials: any, clean?: boolean }
export type setMaterialStatActionType = { type: string, payload: setMaterialStatPayloadType }
/**
 * получить статистику по материалам
 */
export const setMaterialStat = (payload: setMaterialStatPayloadType): setMaterialStatActionType => ({
    type: actions.SET_MATERIAL_STAT,
    payload
})


/******************************* DELETE_TRACK *******************************/
export type deleteUsersPayloadType = any[]
export type deleteUsersActionType = { type: string, payload: deleteUsersPayloadType }
/**
 * удалить трек из состояния
 */
export const deleteUsers = (payload: deleteUsersPayloadType): deleteUsersActionType => ({
    type: actions.DELETE_USERS,
    payload
})


/******************************* DELETE_TRACK *******************************/
export type deleteTrackPayloadType = { id: string[] }
export type deleteTrackActionType = { type: string, payload: deleteTrackPayloadType }
/**
 * удалить трек из состояния
 */
export const deleteTrack = (payload: deleteTrackPayloadType): deleteTrackActionType => ({
    type: actions.DELETE_TRACK,
    payload
})


/******************************* DELETE_FILE *******************************/
export type deleteFilesPayloadType = string[]
export type deleteFilesActionType = { type: string, payload: deleteFilesPayloadType }
/**
 * удалить материал из состояния
 */
export const deleteFiles = (payload: deleteFilesPayloadType): deleteFilesActionType => ({
    type: actions.DELETE_FILE,
    payload
})


/******************************* CHANGE_TRACK *******************************/
export type chnageTrackPayloadType = any
export type chnageTrackActionType = { type: string, payload: chnageTrackPayloadType }
/**
 * изменить трек в состояние
 */
export const chnageTrack = (payload: chnageTrackPayloadType): chnageTrackActionType => ({
    type: actions.CHANGE_TRACK,
    payload
})


/******************************* SELECT_TRACK *******************************/
export type selectTrackPayloadType = { ids: string[] }
export type selectTrackActionType = { type: string, payload: selectTrackPayloadType }
/**
 * удалить трек из состояния
 */
export const selectTrack = (payload: selectTrackPayloadType): selectTrackActionType => ({
    type: actions.SELECT_TRACK,
    payload
})


/******************************* SET_TRACKS *******************************/
export type setTracksListPayloadType = { data: { [key: string]: ILearningTrack }, keys: string[] }
export type setTracksListActionType = { type: string, payload: setTracksListPayloadType, toTop?: boolean, clean?: boolean }
/**
 * добавить список треков в стор
 */
export const setTracksList = (payload: setTracksListPayloadType, toTop?: boolean, clean?: boolean): setTracksListActionType => ({
    type: actions.SET_TRACKS,
    payload,
    toTop,
    clean
})


/******************************* SET_FILES *******************************/
export type setFilesListPayloadType = { values: { [key: string]: ILearningFile }, keys: string[], trackId: string }
export type setFilesListActionType = { type: string, payload: setFilesListPayloadType }
/**
 * добавить список материалов в треке
 */
export const setFilesList = (payload: setFilesListPayloadType): setFilesListActionType => ({
    type: actions.SET_FILES,
    payload
})


/******************************* SET_FILES *******************************/
export type prependFilesListPayloadType = { values: { [key: string]: ILearningFile }, keys: string[], trackId: string }
export type prependFilesListActionType = { type: string, payload: prependFilesListPayloadType }
/**
 * добавить список материалов в трек, в начало
 */
export const prependFilesList = (payload: prependFilesListPayloadType): prependFilesListActionType => ({
    type: actions.PREPEND_FILES,
    payload
})


/******************************* SET_USERS *******************************/
export type setUsersListPayloadType = { users: any[], trackId: string }
export type setUsersListActionType = { type: string, payload: setUsersListPayloadType }
/**
 * добавить список пользователей в треке
 */
export const setUsersList = (payload: setUsersListPayloadType): setUsersListActionType => ({
    type: actions.SET_USERS,
    payload
})


/******************************* CHANGE_FIELD *******************************/
export type changeFieldPayloadType = { root: string, field: string, value: any }
export type changeFieldActionType = { type: string, payload: changeFieldPayloadType }
/**
 * 
 */
export const changeField = (payload: changeFieldPayloadType): changeFieldActionType => ({
    type: actions.CHANGE_FIELD,
    payload
})


/******************************* RESET *******************************/
export type resetPayloadType = string
export type resetActionType = { type: string, payload: resetPayloadType }
/**
 * 
 */
export const reset = (payload: string): resetActionType => ({
    type: actions.RESET,
    payload
})