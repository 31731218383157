import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget, cnWidgetMui } from '../Widget.index';
import { IWidgetTypeFiltersProps } from './Widget_type_filters.index';
import './Widget_type_birthdays.scss';

import { default as WidgetPresenter } from '../Widget';

import { widgets } from "i.widgets";

const types = {
  common: require('blocks/BirthdaysWidget/BirthdaysWidget').BirthdaysWidget,
}

const type = 'birthdays';

export default class WidgetTypeFiltersPresenter extends WidgetPresenter<IWidgetTypeFiltersProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    return <Component {...this.props} className={cnWidgetMui({ type, subtype })} context={this.props.wcontext} relations={this.props.widget.relations} settings={this.props.widget.settings} data={this.props.widget.data} tag={widgets.components.common} />
  }

}
