import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    IPresenterProps
} from './interfaces'
import './style.scss'
import { tC } from 'LMSModule/utils/i18n.adaptor'

export const Presenter: FC<IPresenterProps> = ({
    className,
    rightCount = 0,
    wrongCount = 0,
    waitModerationCount = 0
}) => {
    return <div className={cnClassName({}, [className])}>
        <table className={cnClassName('Table')}>
            <tbody>
                <tr>
                    <td>
                        <div className={cnClassName('SummaryAnswers')}>{tC('testResults.1')}</div>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <div className={cnClassName('SummaryTitle')}>{tC('success.1')}</div>
                    </td>
                    <td>
                        <div className={cnClassName('Value', { type: 'success' })}><b>{rightCount}</b></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className={cnClassName('SummaryTitle')}>{tC('wrong.1')}</div>
                    </td>
                    <td>
                        <div className={cnClassName('Value', { type: 'wrong' })}><b>{wrongCount}</b></div>
                    </td>
                </tr>
                {waitModerationCount !== 0 &&
                    <tr>
                        <td>
                            <div className={cnClassName('SummaryTitle')}>{tC('wait.1')}</div>
                        </td>
                        <td>
                            <div className={cnClassName('Value', { type: 'wait' })}><b>{waitModerationCount}</b></div>
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    </div >
}

export default memo(Presenter);
