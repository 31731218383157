import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IStateType as IState } from 'redux/store';
import { getJournalDataFromServer } from '../../redux/sagas/Journal/actions'
import { getJournalData, getJournalState } from '../../redux/sagas/Journal/selectors'

export interface IJournalProps extends IClassNameProps, IJournalStateProps, IJournalDispatchProps { 
    tag?: 'div';
    data: any;
    context?: any;
    relations?: string[];
    isFinished: boolean;
    isLoading: boolean;
}

export interface IJournalState {
    text?: string;
    news: any[];
    request: boolean;
    fullList: boolean;
}

export type IJournalStateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: IState) => {
    const list = state.lists['alone'];
    return {
        data: getJournalData(state),
        isFinished: getJournalState(state).isFinished,
        isLoading: getJournalState(state).isLoading,
    };
};

export type IJournalDispatchProps = ReturnType<typeof mapDispatchToProps>

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
    getJournalDataFromServer,
}, dispatch)


export const cnJournal = cn('Journal');
