import React, { FC, useState, MouseEvent } from 'react';
import { Box, Button, Typography } from 'muicomponents/src';
import { Avatar } from 'muicomponents/src/Avatar';
import withConfirmDialog from 'muicomponents/src/Button/HOCs/withConfirmDialog';
// import { IItemProps } from '../BadgesTooltipList.index';
import { DeleteRound, ItemBox, StyledBadge, TooltipTitleBox } from './styled';
import { LightMentionTooltip } from 'muicomponents/src/Tooltip';
import { Link } from 'muicomponents/src/Link/Link';
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import { IconButton } from 'muicomponents/src/IconButton';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn'
import { Translate } from 'localizations/Translate';
import { generatePath } from "react-router";
import urls from "routes/urls";
import { formatDateSTD } from 'utils/src/utils.dates';
import { Popover } from 'muicomponents/src/Popover';
import { IconButton } from 'muicomponents/src/IconButton/IconButton'
import { Close } from 'muicomponents/src/Icons'
import { IPresentData } from 'utils/src/requests/models/api.shop';
import { cn } from '@bem-react/classname';
import { withStyles } from '@mui/styles'//'@material-ui/core/styles';
import { mainUrls } from 'utils/src';

export const LightMentionTooltipStyled = withStyles((theme) => ({
    tooltip: {
        background: '#fff !important',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        maxWidth: "560px!important"
    }
}))(LightMentionTooltip);


export const cnPresentItemMui = cn('PresentItemMui');
export interface IItemProps {
    item: IPresentData
    onRemove?: (arg: string) => void
    size: number
}


export const PresentItemMui: FC<IItemProps> = ({ item, onRemove, /* onDelete, */ size }: IItemProps) => {
    // const [popoverAnchor, setPopoverAnchor] = useState<{ top: number; left: number } | null>(null);

    // // ----------------------функции для работы с поповером Popover ----------------------------->


    // const openPopover = (event: MouseEvent) => {
    //     event.stopPropagation();
    //     const rect = event.currentTarget.getBoundingClientRect();
    //     setPopoverAnchor({ top: rect.top + (rect.height / 2), left: rect.left + (rect.width / 2) });
    // };

    // const closePopover = () => {
    //     setPopoverAnchor(null);
    // }


    // // ---------------------- конец функций для работы с поповером Popover ----------------------------->
    return <LightMentionTooltipStyled
        title={
            // <Link href={item.url}>
            <Box
                className={cnPresentItemMui("PresentItemMuiPopoverBox")}
                sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    margin: "24px",
                    width: "560px",

                }}>

                {item?.users?.map((el) => <Box sx={{ marginRight: "19px", maxWidth: "512px" }}>
                    <TooltipTitleBox
                        className={cnPresentItemMui("PresentItemMuiPopoverBox-TooltipTitleBox")}
                        sx={{ justifyContent: "left" }}>

                        <Avatar src={el.orderFromUser.imgUrl} sx={{ mr: 1 }} />
                        <Box className={cnPresentItemMui("PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox")} >
                            <Box sx={{ ['> *'] : {display: 'inline'} }}>
                                <Typography
                                    color='ActiveCaption'
                                    variant='body2'
                                    className={cnPresentItemMui("PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-DisplayName")}>
                                    <Link href={generatePath(mainUrls.user.id, { id: el.orderFromUser.id })}>{el.orderFromUser.displayName}</Link>
                                </Typography>&nbsp;

                                <Typography color='GrayText' variant='body2'
                                    className={cnPresentItemMui("PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-Localization")}>
                                    {el.orderFromUser.sex === "f" ? <Translate i18nKey={'pryaniky.widgets.presents.fem'} /> : <Translate i18nKey={'pryaniky.widgets.presents.man'} />}
                                </Typography>&nbsp;

                                <Typography
                                    color='InfoText'
                                    variant='body2'
                                    fontWeight={"600"}
                                    className={cnPresentItemMui("PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-OrderProductName")}>
                                    {item.orderProductName}
                                </Typography>
                            </Box>
                            <Typography color='GrayText' variant='body2'
                                className={cnPresentItemMui("PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-Date")}>
                                {formatDateSTD(el.date, false)}
                            </Typography>
                        </Box>

                    </TooltipTitleBox>
                    <Typography variant='body2' color='InfoText'
                        className={cnPresentItemMui("PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-userComment")}>
                        {el.userComment}
                    </Typography>
                </Box>)}
            </Box>


        }
    >
        <ItemBox
            key={item.orderProductId}
            className={cnPresentItemMui("PresentItemMuiBox")} >
            {
                <Box>
                    <Box
                        sx={{
                            p: 0,
                            '&:hover': {
                                borderRadius: "50%",
                                background: "inherit!important"
                            }
                        }}
                    >
                        <StyledBadge
                            className={cnPresentItemMui("PresentItemMuiStyledBadge")}
                            badgeContent={item.count}
                            color="primary"
                            invisible={item.count === 1}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}>
                            <Avatar className={cnPresentItemMui("PresentItemMuiStyledBadge-Avatar")}
                                src={item.imgUrl || BaseRequests.getUrl(defaultBadgeIconUrl)}
                                alt={item.orderProductName}
                                sx={{ width: `${size}px`, height: `${size}px` }} />
                        </StyledBadge>
                    </Box>
                </Box>
            }

        </ItemBox>


    </LightMentionTooltipStyled>
}