import { bindActionCreators, Action, Dispatch } from "redux"
import { reset } from 'News/redux/actions';

export interface IWorkflowsProps extends IMapDispatchToProps { }

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  reset
}, dispatch)

type IMapDispatchToProps = ReturnType<typeof mapDispatchToProps> 

export interface IParams {
  type: string;
}

