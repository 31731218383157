import { cn } from "@bem-react/classname";
import { WidgetLayoutHorizantalSettings } from "utils/src/BaseTypes/widgets.settings";
import { TWidget } from "utils/src/BaseTypes/widgets.types";
import { NRow } from "../Section/Section.WidgetLayoutHorizontal.index";

export type PositionProps = {
    sectionId: keyof WidgetLayoutHorizantalSettings['sections'];
    widgetId: TWidget['id'] | null;
    addWidgetHandler: NRow.Props['addWidgetHandler'];
    remove: () => void;
    disableCollapse: boolean;
    collapsedSize?: 'auto' | number;
};

export const cnWidgetPosition = cn('WidgetPosition');