import * as React from 'react';
import { IEditorTypeNewsProps } from './news.index';
import { UeventNews, defaultUeventNews } from '../../../EventsType'
import { EventsValidator } from '../../../Events.validate'
import { baseValidate } from '../../../../Base.validate'
import { Editor } from '../../Editor';
import * as utils from 'utils/src/utils';
import { EditorState } from 'draft-js';
import { makeDefaultStartTime } from '../../../Utils'
import { Rule } from '../../../../Base.validate'

export const ueventValidate: Rule[] = [
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'uevent.title',
        value: 1,
        errorI18nKey: 'pryaniky.validate.news.uevent.title.min',
        // message: 'small title'
    },
    // {
    //     type: 'string',
    //     method: 'STR_MIN',
    //     field: 'text',
    //     value: 3,
    //     errorI18nKey: 'pryaniky.validate.news.base.text.min'
    // },
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'header',
        value: 0,
        errorI18nKey: 'pryaniky.validate.news.base.header.min'
    },
    {
        type: 'string',
        method: 'STR_MAX',
        field: 'uevent.eventLocation',
        value: 180,
        errorI18nKey: 'pryaniky.validate.news.uevent.eventLocation.max',
        // message: 'big eventLocation'
    },
]

export const EditorTypeNewsShvabe: React.FC<IEditorTypeNewsProps> = ({ onChange: cbChange, data, types, groupId }) => {
    let localData = data as any;
    if (data.newstype !== 23) {
        localData = { ...utils.cloneObject(defaultUeventNews), text: EditorState.createEmpty() };
        localData.uevent.startDateTime = makeDefaultStartTime();
        localData.expires = makeDefaultStartTime();

    }

    const onChange = (data: any) => {
        const vb = new EventsValidator();
        vb.addData(data)
        vb.setRules(ueventValidate)
        cbChange({ ...data }, vb.validation().length === 0, vb.validation())
    }

    const onChangeTitle = (e: any) => {
        localData.header = e.target.value;
        localData.uevent.title = e.target.value;
        onChange(localData);
    }
    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData);
    }
    const expiresChange = (date: Date) => {
        localData.uevent.startDateTime = date;
        localData.expires = date;
        onChange(localData);
    }
    const durationChange = (selected: any) => {
        localData.uevent.duration = selected.value;
        if (selected.value === 1440) {
            const dateZero = new Date(localData.uevent.startDateTime)
            dateZero.setHours(0, 0, 0, 0)
            localData.uevent.startDateTime = dateZero;
            localData.expires = dateZero;
        }
        onChange(localData);
    }
    const calendarChange = (selected: any) => {
        localData.uevent.calendar = selected.value;
        onChange(localData);
    }
    const onChangeLocation = (e: any) => {
        localData.uevent.eventLocation = e.target.value;
        onChange(localData);
    }
    const onChangeIsHidden = () => {
        localData.ishidden = !(localData.ishidden || false);
        onChange(localData);
    }
    const onChangeEnableWaitList = () => {
        localData.uevent.enableWaitList = !(localData.uevent.enableWaitList || false);
        onChange(localData);
    }
    const onChangeAllowParticipate = () => {
        localData.uevent.disallowParticipate = !(localData.uevent.disallowParticipate || false);
        if(localData.uevent.disallowParticipate) {
            localData.uevent.maxParticipantsCount = null;
        }
        onChange(localData);
    }
    const onChangeMaxPartisipantsCount = (count: number | null) => {
        localData.uevent.maxParticipantsCount = count;
        onChange(localData);
    }
    const onChangeUsers = (users: any[]) => {
        localData.groups = users.filter((val: any) => val.type === 'group')
        localData.users = users.filter((val: any) => val.type !== 'group');
        onChange(localData)
    }

    const onChangeVideoConference = (online: boolean) => {
        localData.uevent.meeting = {
            online,
            domain: '',
            meetId: '',
            url: ''
        }
        onChange(localData)
    }

    return <Editor
        durationChange={durationChange}
        expiresChange={expiresChange}
        onChangeLocation={onChangeLocation}
        onChangeText={onChangeText}
        onChangeTitle={onChangeTitle}
        onChangeUsers={onChangeUsers}
        onChangeIsHidden={onChangeIsHidden}
        onChangeEnableWaitList={onChangeEnableWaitList}
        onChangeVideoConference={onChangeVideoConference}
        calendarChange={calendarChange}
        onChangeAllowParticipate={onChangeAllowParticipate}
        allowCreateVideoConference={types.eventRenderParams.allowCreateVideoConference}
        groupId={groupId}
        data={localData}
        onChangeMaxPartisipantsCount={onChangeMaxPartisipantsCount}
        />
}
