// import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
// import { stateToProps, mapDispatchToProps } from '../../redux/connector';
// import i18n from '../../localizations/i18n';
import { CheckboxInput } from 'uielements/src';
import { cnMultiSelect, IMultiSelectProps, IMultiSelectState, IOption } from './MultiSelect.index';
import { Button } from 'uielements/src/Button/Button';
import './MultiSelect.scss';
import { Icon } from 'uielements/src/Icon/Icon';
// import i18n from 'localizations/i18n';
import { Bable } from '../Bable/Bable'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

export class MultiSelectPresenter extends React.Component<IMultiSelectProps, IMultiSelectState> {
    public static defaultProps = {
        checkbox: false
    }
    public el: HTMLElement | null;

    constructor(props: IMultiSelectProps) {
        super(props);
        this.state = {
            open: false
        };
    }

    public componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, false);
    }
    public componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, false);
    }

    public render() {
        const customClassNames = '';
        const { icon, className = '', options, selected, checkbox, errorMessage, required, invalid, ...multiSelectProps } = this.props;
        if (multiSelectProps.placeholder && required) multiSelectProps.placeholder += ' *';
        const { open } = this.state;
        return (
            <div
                ref={el => (this.el = el)}
                className={cnMultiSelect({ open }, [className, customClassNames])}>
                <div className={cnMultiSelect('Inner')} onClick={this.toggle}>

                    {icon && <Icon icon={icon} className={cnMultiSelect('Icon')} />}

                    {selected.length > 0 ?
                        this.getSelectedOptions().map(o =>
                            <Bable className={cnMultiSelect('Selected')}>
                                {o.title}
                                {!checkbox && <Button onClick={this.onChangeCreator(o.value)} theme='unstyled'><Icon icon={'times'} className={cnMultiSelect('Times')} /></Button>}
                            </Bable>) :
                        <span className={cnMultiSelect('Placeholder')}>{multiSelectProps.placeholder || 'placeholders.select.default'}</span>}

                    <Icon icon={open ? "chevron-down" : "chevron-left"} className={cnMultiSelect('Caret')} />

                </div>
                {errorMessage && invalid && <Tooltip
                    className={cnMultiSelect('HelpTooltip')}
                    title={errorMessage}>
                    <span><Icon icon="exclamation-circle" className={cnMultiSelect('ErrorIcon')} /></span>
                </Tooltip>
                }
                {open && (
                    <ul className={cnMultiSelect('Options')}>
                        {options.map((option: IOption, idx: number) =>
                            <li key={option.value}
                                data-idx={idx}
                                className={cnMultiSelect('OptionsItem')}>
                                {checkbox ?
                                    <CheckboxInput text={option.title} checked={this.isChecked(option.value)} onChange={this.onChangeCreator(option.value)} />
                                    :
                                    <div className={cnMultiSelect('Item') + (this.isChecked(option.value) ? ' checked' : '')} onClick={this.onChangeCreator(option.value)}>{option.title}</div>
                                }
                            </li>

                        )}
                    </ul>
                )}
            </div>
        );
    }

    private getSelectedOptions = () =>
        this.props.options.filter(o => this.props.selected.indexOf(o.value) !== -1);

    private toggle = () => {
        this.setState(p => ({ open: !p.open }));
    };

    private isChecked = (value: string) =>
        this.props.selected.indexOf(value) !== -1;

    private toggleCheck = (value: string) => {
        const arr = [...this.props.selected];
        const index = arr.indexOf(value);
        if (index !== -1) {
            arr.splice(index, 1);
        } else {
            arr.push(value);
        }
        return arr;
    }

    private onChangeCreator = (value: string) => () => {
        const selected = this.toggleCheck(value);
        this.props.onChange(selected);
    }

    private handleClickOutside = (event: any) => {
        if (this.state.open && this.el && !this.el.contains(event.target)) {
            this.setState({ open: false });
        }
    };
}

export const MultiSelect = MultiSelectPresenter; /*connect(
    stateToProps,
    mapDispatchToProps({})
)(MultiSelectPresenter)
// compose(withBemMod(cnSelect(), {}))(SelectPresenter));*/
