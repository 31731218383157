import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IBodyProps } from '../modal'
import { bindActionCreators, Dispatch, Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
    addUsersToTrack
} from '../../redux/actions/TracksManage'
import {
    ILearningTrack,
    ILearningFile,
    ILearningUser
} from 'utils/src/requests/models/api.learningTracks'
///////////

export type ISubscribeUserPresenterProps = ISubscribeUserOwnProps & ISubscribeUserStateProps & ISubscribeUserDispatchProps

export interface ISubscribeUserState { }

export interface ISubscribeUserOwnProps extends IClassNameProps, IBodyProps<ISubscribeUserResult> {
    trackId: string
}

export interface ISubscribeUserResult {
    users: ILearningUser[]
}

export interface ISubscribeUserStateProps {
}

export type ISubscribeUserDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////


export const mapStateToProps = (state: any, props: ISubscribeUserOwnProps) => ({

})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: ISubscribeUserOwnProps) => bindActionCreators({
    addUsersToTrack
}, dispatch);

export const cnClassName = cn('LTSubscribeUser');