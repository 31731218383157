import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    IPresenterProps
} from './interfaces'
import './style.scss'
import PublicationTitle from 'uielements/src/PublicationTitle'
import { Attachments } from 'uielements/src/Attachments/Attachments'
import { Preview } from 'uielements/src/Preview/Preview';

const noopFn = () => { }
const noopArray: any[] = []

export const Presenter: FC<IPresenterProps> = ({
    className,
    imgUrl,
    additionalContent,
    mainContent,
    text,
    comments,
    footer,
    actionContent,
    files
}) => {
    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('PaddingBox')}>
            <PublicationTitle
                className={cnClassName('Header')}
                imgUrl={imgUrl}
                mainContent={mainContent}
                additionalContent={additionalContent}
                actionContent={actionContent}
            />

            <div className={cnClassName('Content')}>
                {text}
            </div>

            <div className={cnClassName('AttachmentBox')} >
                <Preview className={cnClassName('Preview', ['horizontalPadding'])} files={files ? files : noopArray} />

                <Attachments state={'view'} className={cnClassName('Attachment')} files={files || []} onDelete={() => { }} />
            </div>

            {footer && <div className={cnClassName('Footer')}>
                {footer}
            </div>}
        </div>

        {comments}
    </div>
}

export default memo(Presenter);
