import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { setModerationFilters } from '../../../../redux/actions/LMSTeacherOffice'

export interface IStatusProps extends IClassNameProps, IStatusStateProps, IDispatchProps, RouteComponentProps, IStatusDispatchProps {
    tag?: 'div';
}

export interface IStatusState {

}

export interface IStatusStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    // currentFilters: state.LMSTO.moderation.filters['filter.filterByStatus']
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    // setModerationFilters
}, dispatch);

export type IStatusDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnStatus = cn('StatusFilter');