import { IJournalDataSettings } from "./interfaces"
import {
    JOURNAL_SET_JOURNAL_DATA, JOURNAL_TOGGLE,
} from "./actions"
import { IJournalDataActions } from './actions.interfaces'
import { IJournalData } from 'utils/src/requests/models/admin.journal'


const baseState: IJournalDataSettings = {
    journalSettings: [] as IJournalData[],
    isLoading: false,
    isFinished: false
}


export const journalReducer = (state = baseState, action: IJournalDataActions): IJournalDataSettings => {
    switch (action.type) {

        case JOURNAL_SET_JOURNAL_DATA: {
            return {
                ...state,
                journalSettings: [ ...state.journalSettings, ...action.payload] 
            }
        }
        case JOURNAL_TOGGLE: {
            return {
                ...state,
                [action.payload.variable]: action.payload.value !== undefined ? action.payload.value : !state[action.payload.variable]
            }
        }
        default:
            return state

    }
}