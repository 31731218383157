import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IBanner } from '../../interfaces';

export declare namespace IBannerType {

  export namespace NewsBannerTypeBanner {

    export type IBannerPresenterProps = IOwnProps & IClassNameProps

    export interface IState { }

    export interface IOwnProps {
      banner: IBanner
    }
  }
}

export const cnBannerTypeClassName = cn('NewsBannerTypeBanner');