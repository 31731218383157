import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    sendLikeNews
} from '../../../../redux/actions'

import {
    IActionButtons
} from '../../../../types/baseNews'
import {
    getNewsParamsById
} from '../../../../redux/saga/selectors'
import { NewsContextProps } from '../../../../contexts/news'
import { AnyAction } from 'redux'
import { News as ABNews} from '../../ActionButton/interfaces'
// import {  } from 'models/lib/api.learningTracks'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace NewsActions {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & NewsContextProps

        export interface IState { }

        export interface IOwnProps extends ABNews.NewsActions.IOwnProps {

        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.NewsActions.IOwnProps

export const mapStateToProps = (state: any, { newsId }: IOwnProps & NewsContextProps) => ({
    enabledThanks: (state.store.appSettings?.enabledModules?.thanks as boolean),
    ...getNewsParamsById(newsId,
        'id',
        'likeCount',
        'liked'
    )(state)
})


export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps & NewsContextProps) => bindActionCreators({
    sendLikeNews
}, dispatch);

////////////

export const cnClassName = cn('NewsActions');