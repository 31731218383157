import React, { FC } from "react";
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle';
import { DialogContent } from 'muicomponents/src/DialogParts/DialogContent';
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { IBodyProps } from 'utils/src/DialogCreator';
import { Grid, GridSize } from 'muicomponents/src/Grid';
import { IColumn, IWidget } from "i.widgets";
import { Box, Paper } from "muicomponents/src";
import { cloneDeep } from "lodash";
import { Translate } from "localizations/Translate";
import { WidgetLayoutHorizantalSettings } from "utils/src/BaseTypes/widgets.settings";
import { TWidgetLayoutHorizontal } from "utils/src/BaseTypes/widgets.types";

const sizesTemplates: {[length: number]: (number[])[]} = {
    1: [
        [ 12 ]
    ],
    2: [
        [ 3, 9 ],
        [ 6, 6 ],
        [ 9, 3 ]
    ],
    3: [
        [ 3, 3, 6 ],
        [ 3, 6, 3 ],
        [ 6, 3, 3 ]
    ],
    4: [
        [ 3, 3, 3, 3 ]
    ]
};

export type RowSizeChangerProps = IBodyProps<Rezult> & {
    items: WidgetLayoutHorizantalSettings['sections'][keyof WidgetLayoutHorizantalSettings['sections']];
};

type Rezult = {
    changed: RowSizeChangerProps['items'];
};

const RowSizeChangerPr: FC<RowSizeChangerProps> = ({
    handleAccept,
    handleClose,
    items
}) => {
    return (
        <>
            <DialogTitle>
                <Translate i18nKey='pryaniky.widget.line.layout.changer' />
            </DialogTitle>
            <DialogContent>
                {
                    items &&
                    sizesTemplates[items.length]?.map(el => {
                        return (
                            <Box
                                sx={{
                                    padding: '8px',
                                    ['&:hover']: {
                                        backgroundColor: 'rgb(0 0 0 / 5%)',
                                        cursor: 'pointer'
                                    }
                                }}
                                onClick={() => {
                                    const newSizes = items.map((it, idx) => {
                                        return {
                                            ...it,
                                            size: el[idx]
                                        }
                                    });
                                    // const newSizes = cloneDeep(sizes);
                                    // items.forEach((it, idx) => {
                                    //     const id = typeof it === 'object' ? it.id : it;
                                    //     newSizes[id] = el[idx];
                                    // });
                                    handleAccept({
                                        changed: newSizes
                                    })
                                }}
                            >
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    {
                                        el.map(it => {
                                            return (
                                                <Grid item xs={it}>
                                                    <Paper sx={{ backgroundColor: 'rgb(0 0 0 / 5%)', minHeight: '100px'}} />
                                                </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            </Box>
                        );
                    })
                }
            </DialogContent>
            <DialogActions
                onClose={handleClose}
            />
        </>
    );
};

export const RowSizeChanger = createModal(RowSizeChangerPr, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
      style: {
        backgroundColor: '#fff',
      }
    },
    TransitionComponent,
    scroll: 'body'
  });