import { TextField } from "muicomponents/src";
import { useDefaultStaticStates } from "muicomponents/src/ItemsListDialog/defaultListState";
import { ComponentProps, useCallback, useLayoutEffect, useRef, useState } from "react";
import { useDSStorage } from "redux/dataStorage/hooks";
import { checkResponseStatus, ResponseError } from "utils/src";
import { UserVacancyStatus, Vacancy, VacancyFilter } from "utils/src/BaseTypes/vacancy";
import { useDebounce, useDebouncedChange, useDidUpdateEffect, useReffedState, useStateWithDebounce } from "utils/src/hooks";
import { getRequestedVacancyUsers } from "utils/src/requests/admin.vacancies";

const listCount = 10;

export const useRequestedVacancyUserList = (id: Vacancy['id']) => {

    const { addItems } = useDSStorage('vacanciesRequests');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [finished, setFinished] = useState(false);
    const {search, debouncedSearch, changeSearch} = useDefaultStaticStates();
    const searchRef = useRef(search);
    searchRef.current = search;
    const [list, setList, listRef] = useReffedState<Vacancy['id'][]>([]);
    const [filter, setFilter, filterRef] = useReffedState<keyof typeof UserVacancyStatus | undefined>(undefined);

    const getList = useCallback(async function(searchChanged?: boolean) {
        try {
            setLoading(true);
            setError(false);
            const response = await getRequestedVacancyUsers({
                id,
                status: filterRef.current || '',
                count: 10,
                skipCount: searchChanged ? 0 : listRef.current.length,
                search: searchRef.current
            });
            if(checkResponseStatus(response)) {
                addItems(response.data.map(request => {
                    return {
                        id: request.id,
                        item: request
                    };
                }));
                if(searchChanged) {
                    setList(response.data.map(r => r.id));
                } else {
                    setList([ ...listRef.current, ...response.data.map(r => r.id) ]);
                }
                if(response.data.length < listCount) {
                    setFinished(true);
                }
            } else {
                throw new ResponseError(response)
            }
        } catch(e) {
            if(e instanceof ResponseError) {
                setError(true);
            } else {
                console.error('get requested users error', e);
            }
        } finally {
            setLoading(false);
        }
    }, []);
    
    useLayoutEffect(() => {
        getList(true);
    }, [id, filter, debouncedSearch]);

    return {
        loading,
        error,
        finished,
        list,
        filter,
        search,
        changeSearch,
        setFilter,
        getList
    };
};