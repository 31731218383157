import { IReplyProps } from './Reply.index'
import React, { memo } from 'react'
import './Reply.scss';
import i18n from '../../../../localizations/i18n';
import { Button } from 'uielements/src/Button/Button';

export const Reply: React.FC<IReplyProps> = memo(({ onClick }) => {
    return (
        <div className={'Action Actions-Reply'}>
            <Button theme='unstyled' onClick={onClick}>{i18n.t('reply')}</Button>
        </div>);
})
