import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Selectize, CheckboxInput } from 'uielements/src';
import { mapStateToProps, IUnitListStateProps, cnUnitList, IUnitListProps, mapDispatchToProps } from './UnitList.index';
import './UnitList.scss';
import { withPreloader } from '../../Preloader/Preloader'
import { Filter as Skelet } from '../../../Skelets/Filter/Filter'
import TaskSelector from '../../../components/TaskSelector'
import lesson from '../../../components/TaskSelector/item/img/lesson.svg'
import { createResultNavItems } from 'LMSModule/utils/common.utils'
import { tS } from 'LMSModule/utils/i18n.adaptor'

export const UnitListPresenter: React.FC<IUnitListProps & any> = ({
    tag: Tag = 'div', activeId = 'result', className, units, changeLocation, unitsResults, sid }) => {

    const elements = createResultNavItems(units, unitsResults)

    elements.push({
        title: tS('courseResult.1'),
        unitType: tS('result.1'),
        typeImage: lesson,
        icon: 'chart-pie',
        id: 'result',
        iconColor: ''
    })

    const href = (unit: any) => changeLocation(`?tab=results&sid=${sid}&unit=${unit.id}`)
    return (
        <Tag className={cnUnitList({}, [className])}>
            <h4 className={cnUnitList('Title')}>{tS('navigation.2')}</h4>
            <TaskSelector
                className={cnUnitList('UnitSelector')}
                onSelect={href}
                units={elements}
                active={[activeId]}
            />
        </Tag>
    );
};

const UnitListWithPreloader = withPreloader(UnitListPresenter, ['units', 'unitsResults'], [], <Skelet />)
export const UnitList = connect(
    mapStateToProps,
    mapDispatchToProps
)(UnitListWithPreloader);
