import * as utils from 'utils/src/utils'
import { IStateType as IState } from 'redux/store';
import actions from 'redux/actionsTypes/notification';
import actionsBase from 'utils/src/CommonRedux/base/actionsTypes';
import { INotificationsState } from '../reducers/Notifications';


export const set_readed = (payload: string) => ({
    type: actions.SET_READED,
    payload
})

export const set_readed_all = (payload?: any) => ({
    type: actions.SET_READED_ALL,
    payload
})

export const set_data = (payload: INotificationsState) => ({
    type: actions.SET_DATA,
    payload
}) 

export const add_data = (payload: INotificationsState) => ({
    type: actions.ADD_DATA,
    payload
}) 
export const toggle_is_loading = (payload: boolean) => ({
    type: actions.TOGGLE_IS_LOADING,
    payload
})

export const getNotificationCount = () => {
    return (dispatch: any) => {
        utils.API.notifications.getCount().r
        .then((value) => {
            if (value) dispatch({ type: actionsBase.SET_NOTIFICATION_COUNT, payload: value.data })
        });
    }
}

export const resetNotificationCount = () => {
    return (dispatch: any) => {
        utils.API.notifications.resetCount().r.then(() => dispatch({ type: actionsBase.RESET_NOTIFICATION }));
    }
}

export function loadData (skipCount: number, count:number ){
    return(dispatch: any, getState:() => IState) => {
        dispatch(toggle_is_loading(true))
        utils.API.notifications.get( skipCount, count )
            .r
            .then(d => {
                if (utils.checkResponseStatus(d)) { 
                    const data: any [] = d.data.map((el: any) => el.id);
                    const notifications: { [s: string]: any } = d.data.reduce((acc: any, cur: any) => ({...acc, [cur.id]: cur}), {});
                    const isLoading: boolean = d.data ? false : true;
                    dispatch(add_data({ data, notifications, isLoading}))
                }      
            })
    }
}