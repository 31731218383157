import React, { useState } from "react";
// import * as utils from "../../../utils";
import i18n from "../../../../localizations/i18n";
import { connect } from "react-redux";
import { mapDispatchToProps, IDispatchProps } from "../../../../redux/connector";
import { cn } from "@bem-react/classname";
// import { Button } from "uielements/src";
import { wrapDialogToRedux } from "../../../../blocks/Dialogs/DialogWrapper";
import {
  IWithQRLoginDialogDispatchProps,
  IWithQRLoginDialogStateProps,
  IWithQRLoginDialogOwnProps,
  IWithQRLoginDialogProps,
  IWithQRLoginDialogState,
  mapWithQRLoginDialogDispatchToProps,
  withQRLogiMmapStateToProps
} from "./QRLogin.index";
import "./QRLogin.scss";
import * as utils from "utils/src/utils";
import { DialogMobileApp } from '../DialogWithQR'
import { any } from "prop-types";
import { promised } from "q";
import { Timer } from "./Timer/Timer";
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { Link } from 'muicomponents/src/Link/Link'
import { Button } from 'muicomponents/src/Button/Button'
export const cnQRLogin = cn("QRLogin");

// export function getQRCode(){
//   utils.API.Mobile.pin();
// }


export class QRLoginPresenter extends React.Component<IWithQRLoginDialogProps, IWithQRLoginDialogState> {
  constructor(props: IWithQRLoginDialogProps) {
    super(props);
    this.state = {
      comment: "",
      utilsApi: "",
      url: "",
      isShownDownloadApp: false,
    };
  }



  public getImgUrl = () => {
    let url = "";
    url = "/Img/QR/Icon/200x200/" + `${encodeURIComponent(this.state.utilsApi)}` + ".png"
    return url;
  }

  public componentDidMount() {
    this.newCode();
  }

  public newCode = () => {
    let promise = utils.API.mobile.pin();
    promise.r.then((d: any) => {
      if (!utils.checkResponseStatus(d)) {
        console.log("error");
      } else {
        this.setState(
          {
            utilsApi: d.data
          }
        );
      }
    });
  }
  public render() {
    // сonst { props, onConfirm } = this;
    const { props, state } = this;
    const { handleAccept, handleClose } = props;
    const { presentFor, isShownDownloadApp } = state;
    return (
      <>
        <DialogTitle onClose={handleClose}>
          {i18n.t('pryaniky.user.qrcode.scan')}
        </DialogTitle>
        <DialogContent className={cnQRLogin("")}>
          <div className={cnQRLogin("Content")}>
            <div className={cnQRLogin("QrCode")}>
              <img src={this.getImgUrl()} alt="QR-code for" />
              <h1 className={cnQRLogin("Code")}> {this.state.utilsApi} </h1>
            </div>
            <div className={cnQRLogin("How-To")}>
              {i18n.t("pryaniky.widgets.mobileApp.login.text")}
              <Timer onClick={this.newCode} />
            </div>

            <div className={cnQRLogin("Warning")}>{i18n.t("pryaniky.widgets.mobileApp.login.warning")}</div>

            <Button sx={{ margin: '0 auto 15px' }} className={cnQRLogin("Submit")} onClick={() => {
              DialogMobileApp({}).then(() => { }).catch(() => { });
              handleClose()
            }}>
              {i18n.t("pryaniky.widgets.mobileApp.forget.to.download")}
            </Button>
          </div>
        </DialogContent>
      </>
    );
  }
  private openModal = () => this.setState({ isShownDownloadApp: true });
  private closeModal = () => this.setState({ isShownDownloadApp: false }, this.props.handleClose);
}

export const QRLogin = connect<IWithQRLoginDialogStateProps, IWithQRLoginDialogOwnProps, IWithQRLoginDialogOwnProps>(
  withQRLogiMmapStateToProps,
  mapWithQRLoginDialogDispatchToProps
)(QRLoginPresenter)

export const DialogMobileAppLogin = createModal(QRLogin, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});