import { ILikeProps, cnAction } from './Like.index'
import React, { memo, useCallback } from 'react'
import './Like.scss';
import i18n from 'localizations/i18n';
import { Button } from 'uielements/src/Button/Button';
import { Bable } from '../../../Bable/Bable'
import { Icon } from 'uielements/src/Icon/Icon'
import likersModal from 'blocks/Dialogs/News/Likers/NewsLikers';

const isLikedClass = (liked: boolean) => {
    return liked ? 'heart-fill' : 'heart';
}
export const Like: React.FC<ILikeProps> = memo(({
    newsId,
    replyId,
    liked,
    likeCount,
    onClick,
    className,
    buttonProps
}) => {
    const onLike = useCallback(() => {
        onClick(likeCount, liked);
    }, [likeCount, liked])

    const onOpen = useCallback(() => likersModal({
        newsId,
        replyId
    }), [newsId, replyId])

    return (
        <div className={cnAction('Like', [className])}>
            <Button theme='unstyled' onClick={onLike} {...buttonProps}>
                <Icon icon={isLikedClass(liked)} />
                {i18n.t('like')}
            </Button>
            {
                likeCount !== 0 &&
                <Button theme='unstyled' className={'BtnLikeNum'} onClick={onOpen}>
                    <Bable>{likeCount}</Bable>
                </Button>
            }
        </div>);
})
