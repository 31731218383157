import actions from 'redux/actionsTypes/Modals';

export interface IModalAction {
  type: string;
  payload: IModalActionPayload
}

export interface IModalActionPayload {
  id: string;
  data?: any
}

export interface IModal {
  id: string;
  isShow: boolean;
  data?: any;
  [s: string]: any;
}

export interface IModals {
  [s: string]: IModal
}

export const modals_default: IModals = {}
export const modal_default: IModal = {
  id: '0',
  isShow: false
}

export function modals(state: IModals = modals_default, action: IModalAction): IModals {
  const { type, payload } = action;
  let { id, data } = payload || { id: undefined, data: undefined };
  let item = state[id];
  if (!item) item = {
    ...modal_default,
    id
  }
  switch (type) {
    case actions.INIT: {
      return {
        ...state,
        [id]: {
          ...item,
          ...data
        }
      }
    }
    case actions.SHOW_TOGGLE: {
      return {
        ...state,
        [id]: {
          ...item,
          isShow: data !== undefined ? data : !item.isShow
        }
      }
    }
    case actions.SET_DATA: {
      return {
        ...state,
        [id]: {
          ...item,
          data
        }
      }
    }
    case actions.CHANGE_DATA: {
      return {
        ...state,
        [id]: {
          ...item,
          data: {
            ...item.data,
            ...data
          }
        }
      }
    }
    case actions.KILL: {
      delete state[id];
      return {
        ...state
      }
    }
    default:
      return state
  }
}