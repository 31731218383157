import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getCurrentAllowPostTypes } from '../../../redux/sagas/AppSettings/selectors';
import {
    getClearCreator,
    getSendError,
    getCreator,
    isSending
} from 'News/redux/saga/selectors';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import {
    sendNews,
    pathCreator
} from 'News/redux/actions';
import { loadAllowPostTypes } from '../../../redux/actions/AppSettings';
import { Rule } from '../../NewsTypes/Base.validate';
import { IComponentAcceptor } from 'utils/src/ComponentInjector';

export const cnNewsCreateForm = cn('NewsCreateForm');

export namespace NNewsCreateForm {

    export type OwnProps = {
        gId?: number;
        className?: string;
        oneNewsType?: string;
        workflowId?: string;
        onSendComplete?: (success: boolean) => void
        cancleEdit?: () => void;
        activePostId: number;
        hideActions?: boolean;
    };

    export interface State {
        validationErrors: Rule[];
    
        gId: number;
    
        workflowId?: string;
    
        group: any | null
    }

    export interface Props extends IClassNameProps, OwnProps, IComponentAcceptor, ReturnType<typeof mapNewsCreateFormStateToProps>, ReturnType<typeof mapNewsCreateFormActionToProps> {

    };
};

export const mapNewsCreateFormStateToProps = (state: any, props: NNewsCreateForm.OwnProps) => ({
    userData: getAuthUser(state),
    maxFileSize: state.store.appSettings.maxFileSizeLimit,
    denyMIME: state.store.appSettings.denyMIME,
    postTypes: getCurrentAllowPostTypes(props.gId, props.workflowId)(state),
    i18nNamespace: state.i18n.namespace,
    ...getCreator(state),
    sending: isSending(state)
});

export const mapNewsCreateFormActionToProps = (d: Dispatch<AnyAction>) => bindActionCreators({
    sendNews,
    loadAllowPostTypes,
    pathCreator
}, d);
