import { IClassNameProps } from "@bem-react/core";
import { cn } from "@bem-react/classname";
import { ITasksNews } from "../Tasks.type";

export interface ITasksEditorProps extends IClassNameProps {
  data: ITasksNews;
  onChangeTitle: (e: any) => void;
  onChangeText: (text: string) => void;
  addNewAnswer: (type: string | number) => void;
  changeAnswerText: (index: number, value: any) => void;
  removeAnswer: (index: number) => void;
  onChangeFiledFiles: (index: number, files: any[]) => void;
  toggleRequireAttach: (index: number) => void;
  toggleRequireComment: (index: number) => void;
  onChangeUsers: (users: any[]) => void;
}

export interface ITasksEditorState { }

export const cnTasksEditor = cn('TasksEditor');