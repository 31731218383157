import React, { FC } from 'react'
import { 
    LMSTO,
    mapDispatchToProps,
    mapStateToProps,
    cnClassName 
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
type IPresenterProps = LMSTO.StudentsList.Result.IPresenterProps
type IStateProps = LMSTO.StudentsList.Result.IStateProps
type IDispatchProps = LMSTO.StudentsList.Result.IDispatchProps
type IOwnProps = LMSTO.StudentsList.Result.IOwnProps
type IState = LMSTO.StudentsList.Result.IState
// import i18n from 'localizations/i18n';

export const Presenter: FC<IPresenterProps> = ({ className }) => {
    return <div className={cnClassName({}, [className])}>

    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
        mapStateToProps,
        mapDispatchToProps
    )(Presenter);
