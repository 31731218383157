/**
 * @packageDocumentation
 * @module List_type_badges
 */
import { IListProps } from '../../List.index';
import { filters } from 'utils/src/filters.prn';

import { IDispatchProps } from '../../../../redux/connector';

export interface IDefaultRequestOption {
  search: string,
}


export interface IListTypeBadgesProps extends IListProps, IDispatchProps {
  requestOptions: any;
  tag?: 'div';
}

const fltrs = {
  ...filters,
  type: 'catFilter', // type
  status: 'status', // event status
}

export type IFLTRS = typeof fltrs & { [s: string]: string };

export const avalibleFilters = Object.keys(fltrs).reduce((acc, cur) => acc[cur] === '' ? { ...acc, [cur]: cur } : acc, fltrs as IFLTRS);