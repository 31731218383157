import React, { FC, useEffect, useMemo, useState, memo, useCallback, useRef } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect, useDispatch, useSelector } from 'react-redux';
import './style.scss'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import { createDefaultModel, IThanksNews } from '../Thanks.type'
import NewsEditor from 'News/containers/NewsEditor'
import { useDebounce } from 'utils/src/hooks'
import { EditorState, RawDraftContentState, convertToRaw, convertFromRaw } from 'draft-js';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PryanikyEditor from 'uielements/src/PryanikyEditorV1'
import { markdownPreset } from 'uielements/src/PryanikyEditorV1/modulesMap'
import AutocompleteO from 'uielements/src/MaterialElements/Autocomplete'
import FormControlO from '@material-ui/core/FormControl';
import {
    useBaseEdits
} from 'News/utils/hooks'
import MentionAction from 'News/components/MentionDrop'
import TextField from '@material-ui/core/TextField';

import { ThanksInput2 as ThanksInput2O } from 'blocks/PryanikyForms/ThanksInput2/ThanksInput2'
import { ThanksCounter as ThanksCounterO } from 'blocks/PryanikyForms/ThanksCounter/ThanksCounter'

import {
    useChangeCurrency,
    useChangeReasons,
    useLoadReasons,
    useChangeThanksUsers
} from '../hooks'

import AutocompleteD from '@material-ui/lab/Autocomplete';
import { includes } from 'lodash'

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { Rule, TRules } from 'News/validation'

const MemoAutocompleteD = memo(AutocompleteD)
const FormControl = memo(FormControlO)
const Autocomplete = memo(AutocompleteO)
const ThanksInput2 = memo(ThanksInput2O)
const ThanksCounter = memo(ThanksCounterO)

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type IPresenterProps = News.NewsCreator.IPresenterProps
type IOwnProps = News.NewsCreator.IOwnProps
type IDispatchProps = News.NewsCreator.IDispatchProps
type IStateProps = News.NewsCreator.IStateProps
type IState = News.NewsCreator.IState

const toolBarStyle = { top: 55 }
const noopFn = () => { }

const rules: TRules = {
    'string/STR_MIN/draftState': {
        type: 'draft',
        method: 'STR_MIN',
        field: 'draftState',
        value: 30,
        errorI18nKey: 'pryaniky.validate.news.base.text.min'
    },
    'array/ARR_MIN/rewardReasons': {
        type: 'array',
        method: 'ARR_MIN',
        field: 'rewardReasons',
        value: 1,
        errorI18nKey: 'pryaniky.validate.news.base.text.min'
    }
}






export const Presenter: FC<IPresenterProps> = ({
    className,
    reasons
}) => {

    const {
        draftState,
        onFind,
        onMention,
        onSubmit,
        setDraft,
        currentNews
    } = useBaseEdits<IThanksNews>(createDefaultModel(), { validationRules: rules })

    const [users, setUsers, maxAllowUsers, usersCount] = useChangeThanksUsers(currentNews)
    const [rewardReasons, onChangeReason, disableReasons] = useChangeReasons(currentNews)
    const {
        onChangeCurrency,
        disableDown,
        disableUp,
        thanksCount,
        available,
        fullCount,
        fullCurrency
    } = useChangeCurrency(currentNews)


    return <NewsEditor
        actions={
            <>
                <MentionAction value={null} onChange={onMention} />
                <button key={'onFocusShort'} onClick={onFind}>{'find'}</button>
            </>
        }
        onSubmit={onSubmit}
        className={cnClassName({}, [className])}
    >
        <FormControl fullWidth>
            <Autocomplete
                fullWidth
                excludeMe={true}
                type={'usersgroups'}
                label={'course_edit.course.authors.1'}
                value={users}
                onChange={setUsers}
                getOptionDisabled={(option: any) => maxAllowUsers <= usersCount}
            />
        </FormControl>
        <div>
            <FormControl fullWidth>
                <MemoAutocompleteD
                    multiple
                    id="tags-standard"
                    options={(reasons || [])}
                    getOptionLabel={(option: any) => option.name}
                    // defaultValue={}
                    getOptionDisabled={(option: any) => disableReasons}
                    groupBy={(option: any) => option.group}
                    renderOption={(option: any, { selected }: any) => (
                        <>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                        </>
                    )}
                    onChange={onChangeReason as any}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Multiple values"
                            placeholder="Favorites"
                        />
                    )}
                />

            </FormControl>
        </div>
        <div>
            <PryanikyEditor
                maxFileSize={100}
                enableModules={markdownPreset}
                toolBarStyle={toolBarStyle}
                value={draftState}
                setActionBtn={noopFn}
                onChange={setDraft}
            />
        </div>
        <div>
            <FormControl fullWidth>
                <ThanksInput2
                    onChange={onChangeCurrency}
                    disableDown={disableDown}
                    disableUp={disableUp}
                    thankPerUser={thanksCount} />

                <ThanksCounter thanksForAll={fullCurrency} thanksCount={fullCount} available={available} />
            </FormControl>
        </div>

    </NewsEditor >
}

export const Connected = connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));

export const initNewsThanks = () => ComponentInjector.getInstance().addNode('news_creator', <Connected />, 'thanks')