import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { pageHeader } from './pageHeaders';


export const quests = (): IWidget<IColumn[]> => {

  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.info,
            data: pageHeader.quests
          }),

          generateWidget({
            type: widgets.types.layout,
            data: [
              generateColumn({
                items: [
                  generateWidget({
                    type: widgets.types.list + '/quests',
                  }),
                ],
                styles: {
                  width: '72%',
                },
              }),
              generateColumn({
                items: [
                  generateWidget({
                    type: widgets.types.filters + '/quests',
                  }),
                ],
                styles: {
                  width: '28%',
                },
              }),
            ],
          }),
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  })

  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.list + '/quests',
          }),
        ],
        styles: {
          width: '72%',
        },
      }),
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.filters + '/quests',
          }),
        ],
        styles: {
          width: '28%',
        },
      }),
    ],

  });
}