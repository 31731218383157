import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { IStateType } from 'redux/store';
import { IUsersWidgetProps } from '../UsersWidget.index';
export { cnUsersWidget, cnUsersWidgetCollegues } from '../UsersWidget.index';

export interface IUsersWidgetTypeListProps extends IUsersWidgetProps, IClassNameProps, ImapUsersWidgetTypeListStateToProps {
  // tag?: React.ComponentType | 'div';
};

export interface IUsersWidgetTypeListState {
  text?: string;
};

export const mapUsersWidgetTypeListStateToProps = (state: IStateType) => ({
  
});

export type ImapUsersWidgetTypeListStateToProps = ReturnType<typeof mapUsersWidgetTypeListStateToProps>;