import React, { FC, useState } from 'react'
import {
    cnClassName,
    IAddCourseToTrackOwnProps,
    IAddCourseToTrackResult,
    IAddCourseToTrackPresenterProps,
    IAddCourseToTrackStateProps,
    IAddCourseToTrackDispatchProps,
    mapDispatchToProps,
    mapStateToProps
} from './interfaces'
import './style.scss'
import { createModal, TransitionComponent } from '../../dialogs/modal'
// import DialogBody from '../../dialogs/modal/components/DialogBody'
import SingleAutocomplete from 'uielements/src/MaterialElements/SingleAutocomplete'
import withTrackSuggester from 'blocks/HOCs/SingleAutocomplete/withTrackSuggester'
import { connect } from 'react-redux';
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import {
    // DialogTitle,
    // DialogActions,
    Button
} from 'uielements/src';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'

const TrackSelect = withTrackSuggester(SingleAutocomplete)

export const Presenter: FC<IAddCourseToTrackPresenterProps> = ({
    handleAccept,
    handleClose,
    className,
    data,
    ...other
}) => {
    const [tid, setTid] = useState<string | undefined>(undefined)

    const accept = () => {
        if (tid) {
            handleAccept({
                tid,
                cid: data.id || ''
            })
        }
    }

    return <div>
        <DialogTitle onClose={handleClose}>
            {tTr('dialogs.add_course.title')}
        </DialogTitle>
        <div className={cnClassName({}, [className])}>
            <div>
                {data.title}
            </div>
            <TrackSelect
                onSelect={(item) => setTid(item?.id)}
                label={tTr('dialogs.add_course.select_track.label')}
            />
        </div>
        <DialogActions
        acceptText={tTr('dialogs.add_course.confirm')}
        closeText={tTr('dialogs.add_course.close')}
        disableAccept={!tid}
        onAccept={accept}
        onClose={handleClose}/>
            {/* <Button main disabled={!tid} onClick={accept}>{tTr('dialogs.add_course.confirm')}</Button>
            <Button onClick={handleClose}>
                {tTr('dialogs.add_course.close')}
            </Button>
        </DialogActions> */}
    </div>
}
const Connected = connect<IAddCourseToTrackStateProps, IAddCourseToTrackDispatchProps, IAddCourseToTrackOwnProps, any>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
export default createModal<IAddCourseToTrackResult, IAddCourseToTrackOwnProps>(Connected, { 
    maxWidth: 'md',
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            overflowY: 'unset'
        }
    },
    TransitionComponent
 });
