import { cn } from '@bem-react/classname'
import React, { FC, useEffect, useState } from 'react';
import { loadExternalModule } from 'utils/src/loadExternalModule'
import { useInjector } from 'utils/src/ComponentInjector'
import { useSelector } from 'react-redux';

export const cnHTMLWidget = cn('HTMLWidget')

export const Loader: FC<any> = ({ children }) => {
    const [isLoading, setLoading] = useState(true)
    useEffect(() => {
        loadExternalModule({
            app: 'main',
            id: 'tintmcewidget_lib',
            endpoint: 'tintmcewidget_lib',
            jsUrl: '/static/js/tintmcewidget.bundle.js',
            // store,
            cssUrl: '/static/css/tintmcewidget.css',
            // requireEnabled: 'learningtracks',
            // adminOnly: true
        }).then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [])
    if (isLoading) return <>Loading...</>
    return <>{children}</>
}

export const Widget: FC<any> = (props) => {
    const textEditorColors = useSelector((state: any) => state.store?.appDesign?.colors?.textEditorColors as string[] | undefined)
    const colorMap = textEditorColors ? textEditorColors.map(c => c.replace('#', '')).reduce<string[]>((a, c) => ([...a, c, '#' + c]), []) : undefined
    const { getComponentById } = useInjector(['tinymce_widget'])
    const Component = getComponentById('tinymce-widget')
    if (Component) {
        return <Component {...props} className={cnHTMLWidget({}, [props.className])} color_map={colorMap} />
    }
    return <></>
}

export const TinyMCE: FC<any> = ({ contexts, className, isEditable, HTMLWidgetContentEdit, ...props }) => {
    return <Loader>
        <Widget
            {...props}
            className={className}
            contexts={contexts}
            // tag={widgets.components.common}
            isEditable={isEditable}
            isEdit={HTMLWidgetContentEdit}
        />
    </Loader>
}