import React, { FC, useEffect, useState } from 'react'
import { cnItem, mapDispatchToProps, mapStateToProps } from './Item.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Item.scss'
import { Button, Avatar } from 'uielements/src';
import { UserMention } from 'uielements/src/UserMention/UserMention';

const getFirstOrDefault = (array: any[], defaultValue: any = null) => {
    if (array.length > 0) return array[0]
    return defaultValue
}

export const ItemPresenter: FC<any> = ({ className, student, cid, unitCount }) => {
    if (!student.user) return <div className={cnItem({}, [className])}>USER NULL</div>
    const { logs, user, userId, completeUnitCount } = student
    const firsLog = getFirstOrDefault(logs || [], { sessionId: '0', completePercentage: 0 })
    const currentStep = completeUnitCount;//Math.round(firsLog.completePercentage / (100 / unitCount))
    const link = `/TeacherOffice/Course/${cid}?tab=student&sid=${firsLog.sessionId}&uid=${userId}`;

    return <div className={cnItem({}, [className])}>
        <div className={cnItem('AvatarBox')}>
            <Avatar className={cnItem('Avatar')} size={56} imgUrl={user.userPhotoUrl} name={user.displayName} />
        </div>
        <div className={cnItem('Info')}>
            <div><UserMention className={cnItem('Title')} id={user.id} name={user.displayName} /></div>
            {/* <div><Button type={logs ? 'rlink' : undefined} theme={'unstyled'} className={cnItem('Title')} href={logs ? link : undefined}>{user.displayName}</Button></div> */}
            <div>{user.position}</div>
            <div>{user.division}</div>
        </div>
    </div>
}

export const BasicItem = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ItemPresenter));
