import React, { FC, useState, memo, useEffect } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IUnitsAttandanceProps,
    IUnitsAttandanceOwnProps,
    IDispatchProps,
    IUnitsAttandanceStateProps,
    IUnitsAttandanceState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import FormControl from '@material-ui/core/FormControl';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Chart from 'uielements/src/Chart';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import { API } from 'utils/src/utils'
import SingleAutocomplete from 'uielements/src/MaterialElements/SingleAutocomplete'
import withSessionSuggester from 'blocks/HOCs/SingleAutocomplete/withSessionSuggester'
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
import Tabs, { ITabType } from 'uielements/src/TabsV2'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { useLazyQuery } from 'LMSModule/utils/hooks/useLazyQuery'

const SessionSelect = withSessionSuggester(SingleAutocomplete)


export const Presenter: FC<IUnitsAttandanceProps> = ({
    className,
    cid,
    loadUnitsAttandance,
    unitsAttandance,
    sessionsIds = [],
    archiveSessions = [],
    normalSessions = {}
}) => {
    const TABS: ITabType[] = [
        { value: 'active', content: tT('statistics.units_attandance.tabs.active.1') },
        { value: 'archive', content: tT('statistics.units_attandance.tabs.archive.1') }
    ]
    const [sid, setSid] = useState<string | undefined>(undefined)
    const [hideZero, setHideZero] = useState(true)
    const [sort, setSort] = useState(true)
    const [isArchived, setArchived] = useState(false)

    useEffect(() => {
        loadUnitsAttandance(cid, sid)
    }, [sid, cid])

    const changeSelect = (item: ISelectizeItem | null) => {
        if (!item) setSid(undefined)
        else setSid(item.id)
    }

    const sortedData = !hideZero ? [...unitsAttandance] : unitsAttandance.filter((val: any) => val.value > 0)
    sort && sortedData.sort((a: any, b: any) => {
        if (a.value > b.value) {
            return 1;
        }
        if (a.value < b.value) {
            return -1;
        }
        return 0;
    });

    const [getExcel, excelLoading] = useLazyQuery(() => API.lms.unitsattendanceexcel(cid, sid))

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Control')}>
            <Tabs
                className={cnClassName('Tabs')}
                size={'small'}
                onChange={(tab) => { tab.value === 'active' ? setArchived(false) : setArchived(true) }}
                selected={isArchived ? TABS[1] : TABS[0]}
                tabs={TABS}
            />
            <div className={cnClassName('Sessions')}>
                <FormControl fullWidth>
                    <SessionSelect
                        archived={isArchived}
                        onSelect={changeSelect}
                        label={tT('statistics.units_attandance.select.session.1')}
                        courseId={cid}
                    />
                </FormControl>

                <IconButton
                    title={tT('statistics.reports.title.download.1')}
                    disabled={excelLoading}
                    onClick={getExcel}
                    // href={`https://${API.base.domain}/ru/Data/v3/statistics/lms/unitsattendanceexcel?camelCase=true&courseId=${cid}${sid ? '&sessionId=' + sid : ''}`}
                >
                    <DescriptionIcon />
                </IconButton>
            </div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={hideZero}
                        onChange={() => setHideZero(!hideZero)}
                        name="hideZero"
                        color="primary"
                    />
                }
                label={tT('statistics.units_attandance.label.hide_zero.1')}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={sort}
                        onChange={() => setSort(!sort)}
                        name="sort"
                        color="primary"
                    />
                }
                label={tT('statistics.units_attandance.label.sort.1')}
            />
        </div>

        <Chart className={cnClassName('Chart')} width={770} height={40 * sortedData.length + 140} data={sortedData} />
    </div>
}

export default connect<IUnitsAttandanceStateProps, IDispatchProps, IUnitsAttandanceOwnProps, IUnitsAttandanceState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
