import React from 'react';
import { Translate } from "localizations/Translate";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { checkResponseStatus } from "utils/src";
import { getDepositSettings } from "utils/src/requests/admin.wallets";
import { DepositCondition, DepositSettings } from 'utils/src/BaseTypes/wallets.types';
import { getCurrencyNameByCount } from 'utils/src/CommonRedux/base/selectors';
import { useSelector } from 'react-redux';

export const useDepositSettings = () => {

    const [loading, setLoading] = useState(false);

    const [settings, setSettings] = useState<DepositSettings | null>(null);

    useEffect(() => {
        setLoading(true);
        getDepositSettings().then(d => {
            if(checkResponseStatus(d)) {
                setSettings({
                    ...d.data,
                    conditions: d.data?.conditions?.filter((el: DepositCondition) => !el.disabled) || []
                });
            } else {
                toast.error(<Translate i18nKey={'pryaniky.wallets.setting.get.error'} />);
            }
            setLoading(false);
        });
    }, []);

    const currencyNameMinValue = useSelector(getCurrencyNameByCount(settings?.minValue || 0, true));

    return {
        loading,
        settings,
        currencyNameMinValue
    };
};