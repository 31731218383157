import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { loadVirtUsers } from 'utils/src/CommonRedux/users/actions'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions'
import { IUser } from 'utils/src/requests/models/api.users';
import { SUserModelShort } from 'utils/src/BaseTypes/user.types';

export interface IVirtUserSelectorProps extends IClassNameProps, IVirtUserSelectorStateProps, ReturnType<typeof mapDispatchToProps> {
    ignoreGetData?: boolean;
    tag?: 'div';
}

export interface IVirtUserSelectorState {

}

export interface IVisitUser {
    access_token: string,
    cookieName: string,
    cookieToken: string,
    ext_user_id: string,
    user: SUserModelShort
}

export interface IVirtUserSelectorStateProps {
    virtUsers: IVisitUser[],
    currentUser: IUser['user']
}

export const mapStateToProps = (state: any) => ({
    virtUsers: state.users.virtUsers,
    currentUser: getAuthUser(state)
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadVirtUsers
}, dispatch);

export const cnVirtUserSelector = cn('VirtUserSelector');