/**
 * @packageDocumentation
 * @module Widget_Settings_sliderlink
 */
import { GUID_EMPTY } from "utils/src/constants.prn";
import { emptyItem } from './uischema';

export default {
  slider: {
    aspect: 0,
    elements: [
      emptyItem
    ],
  }
  // aspect: '',
  // it1: {
  //   imgId: GUID_EMPTY,
  //   imgUrl: '',
  //   href: '',
  // }
}