import { cn } from '@bem-react/classname';
import { IBodyProps } from 'utils/src/DialogCreator';
import { selectStatusFields, selectStatusFieldsLoading, selectStatus } from 'blocks/NewsTypes/Workflows/redux/selectors';

export const cnWorkflowsChangeStatus = cn('WorkflowsChangeStatus');

export interface IWorkflowsChangeStatusProps extends IBodyProps, IWorkflowsChangeStatusOwnProps, IMapStateToProps { }

export interface IWorkflowsChangeStatusLoaderProps extends IBodyProps<any> {
  newsId: string;
  statusId: string;
  status: any
}
interface IWorkflowsChangeStatusOwnProps {
  newsId: string;
  statusId: string;
  onChangeFields: (statusFields: any) => void;
}

export const mapStateToProps = (state: any, {newsId, statusId}: IWorkflowsChangeStatusOwnProps) => ({
  data: selectStatusFields(newsId)(state),
  loading: selectStatusFieldsLoading(newsId)(state),
  status: selectStatus(newsId, statusId)(state),
})

type IMapStateToProps = ReturnType<typeof mapStateToProps>;