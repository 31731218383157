import React, { FC, useMemo, useState, useEffect } from 'react';
import { cnNewsCreateForm, NNewsCreateForm, mapNewsCreateFormStateToProps, mapNewsCreateFormActionToProps } from './NewsCreateForm.index';
import './NewsCreateForm.scss';
import { connect } from 'react-redux';
import i18n from '../../../localizations/i18n';
import { EditorForm } from '../../EditorForm/EditorForm';
import { Rule } from '../../NewsTypes/Base.validate';
import { BaseNews } from '../../NewsTypes/BaseType';
import * as utils from 'utils/src/utils';
import { v1 as uuid } from 'uuid';
import { defaultBaseNews, defaultGroup } from '../../NewsTypes/BaseType';
import { EditorState, convertToRaw } from 'draft-js';
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { rawToMd } from '../../PryanikyEditor/convertorConfigs';
import { prepareToServer } from 'uielements/src/FieldRender';

import { EditorTypeNews as Thanks } from '../../NewsTypes/Thanks/Editor/containers/news/news';
import { EditorTypeNews as Polls } from '../../NewsTypes/Polls/Editor/containers/news/news';
import { EditorTypeNews as Ideas } from '../../NewsTypes/Ideas/Editor/containers/news/news';
import { EditorTypeNews as Uevent } from '../../NewsTypes/Events/Editor/containers/news/news';
import { EditorTypeNews as CreativeTasks } from '../../NewsTypes/Creativetasks/Editor/containers/news/news';
import { EditorTypeNews as Badges } from '../../NewsTypes/Badges/Editor/containers/news/news';
import { EditorTypeNews as Notices } from '../../NewsTypes/Notices/Editor/containers/news/news';
import { EditorTypeNews as Achievements } from '../../NewsTypes/Achievements/Editor/containers/news/news';
import { EditorTypeNews as News } from '../../NewsTypes/News/Editor/containers/news/news';
import { EditorTypeNews as Tasks } from '../../NewsTypes/Tasks/Editor/containers/news/news';
import { EditorTypeNews as Repost } from '../../NewsTypes/Repost/Editor/containers/news/news';
import { EditorTypeNews as Longread } from '../../NewsTypes/Longread/Editor/containers/news/news';
import { EditorTypeNews as Feedback } from '../../NewsTypes/Feedback/Editor/containers/news/news';
import { EditorTypeNews as Workflows } from '../../NewsTypes/Workflows/Editor/containers/news/news';
import { isNumber } from 'lodash';

import { withComponentEnjector } from 'utils/src/ComponentInjector';

import { useDidUpdateEffect } from 'utils/src/hooks';
import { formatDateSTD } from 'utils/src/utils.dates';

const editorAlias: { [s: string]: React.FunctionComponent<any> } = {
    news: News,
    thanks: Thanks,
    polls: Polls,
    creativetasks: CreativeTasks,
    badges: Badges,
    achievements: Achievements,
    notices: Notices,
    ideas: Ideas,
    events: Uevent,
    tasks: Tasks,
    repost: Repost,
    feedback: Feedback,
    longread: Longread,
    expense: Longread,
    test: Workflows,
    workflow: Workflows,
}


const defaultError = {
    error_code: 0,
    error_text: '',
    id: ''
}

const NewsCreateFormPresenter: FC<NNewsCreateForm.Props> = ({
    activePostId: activePostIdProps,
    sending,
    maxFileSize,
    denyMIME,
    currentNews,
    isValid,
    validFile,
    clearEditor,
    gId,
    i18nNamespace,
    loadAllowPostTypes,
    pathCreator,
    postTypes: postTypesProps,
    sendNews,
    userData,
    workflowId,
    cancleEdit,
    children,
    onSendComplete,
    sendError = defaultError,
    oneNewsType,
    hideActions
    // getNode: getNodeFromInjector,
}) => {

    // on did mout loading post types
    useEffect(() => {
        loadAllowPostTypes(gId, workflowId);
    }, []);

    // if change workflowId then reloading post types from server
    useDidUpdateEffect(() => {
        loadAllowPostTypes(gId, workflowId);
    }, [workflowId]);

    const [state, setState] = useState<NNewsCreateForm.State>({
        validationErrors: [],
        gId: gId || -1,
        workflowId,
        group: null
    });

    const onChangeGroup = (group: any) => {
        setState({
            ...state,
            gId: group?.pkid || gId,
            group: group
        });
    };


    const onChangeFile = (files: any[], validate: boolean) => {
        const allCompleted = files.every((value) => value.downloadStatus === 'finish')
        pathCreator({
            currentNews: {
                ...currentNews,
                attachments: files
            },
            validFile: validate && allCompleted
        });
    };

    const onChange = (data: BaseNews, validate: boolean, errors: Rule[]) => {
        setState({
            ...state,
            validationErrors: errors
        });
        pathCreator({
            currentNews: data,
            isValid: validate,
            sendError: {
                error_code: 0,
                error_text: ''
            }
        });
    };

    const onChangePublishAt = (date: string | null) => {
        pathCreator({
            currentNews: {
                ...currentNews,
                publishAt: date
            }
        })
    };

    const sendNewsHandler = () => {
        const prepairedNews = {
            ...currentNews,
            user: utils.userToSelectableObbject(userData.baseData),
            id: uuid(),
            group: { ...defaultGroup, pkid: state.gId },
        };

        pathCreator({
            currentNews: prepairedNews
        });

        const text = prepairedNews.text && draftToMarkdown(convertToRaw(prepairedNews.text.getCurrentContent()), rawToMd);
        const additionalFieldsValues = prepareToServer(prepairedNews.additionalFields?.additionalFieldsValues || []);
        sendNews({
            ...prepairedNews,
            additionalFields: {
                ...prepairedNews.additionalFields,
                additionalFieldsValues,
            },
            text
        } as any, (success) => {
            if (success) {
                setState({
                    ...state,
                    gId: gId || -1,
                    group: null
                });
            }
            onSendComplete && onSendComplete(success)
        });
    };

    const postTypes = useMemo(() => {
        return postTypesProps
            .filter((el: any) => !oneNewsType ? true : oneNewsType === el.id)
            .filter((el: any) => el.enabled)
    }, [postTypesProps]);

    const activePostId = useMemo(() => {
        return isNumber(activePostIdProps) ? activePostIdProps : (postTypes.length !== 0 ? postTypes[0].idNumber : undefined)
    }, [activePostIdProps, postTypes]);

    if (activePostId === undefined) return null;

    const currentTypeItem = postTypes.find((el: any) => el.idNumber === activePostId)
    const currentRender = currentTypeItem.componentRenderName

    const CurrentType = editorAlias[currentRender] ? editorAlias[currentRender] : editorAlias['news'];


    let disableSend = !isValid;
    disableSend = validFile ? disableSend : !validFile;


    const { error_text, error_code } = sendError;

    return (
        <>
            {/* getNodeFromInjector(`${activePostId}`) || */}
            <EditorForm
                newsHeader={currentNews.header}
                className={cnNewsCreateForm()}
                isSending={sending}
                activePostId={activePostId}
                group={state.group}
                onChangeGroup={gId === -1 ? onChangeGroup : undefined}
                hideActions={hideActions !== undefined ? hideActions : true}
                files={currentNews.attachments || []}
                onChangeFile={onChangeFile}
                save={sendNewsHandler}
                cancle={cancleEdit}
                isValid={disableSend}
                denyMIME={denyMIME}
                maxFileSize={maxFileSize}
                postErrorText={error_text}
                postErrorCode={error_code}
                validationErrors={state.validationErrors}
                onChangePublishAt={userData.baseData.isAdmin ? onChangePublishAt : undefined}
                publishAt={currentNews.publishAt}
                saveText={currentNews.publishAt ?
                    (i18n.t('pryaniky.createPost.submit.publishAt') + formatDateSTD(currentNews.publishAt, true, true)) : undefined}
            >
                <CurrentType
                    groupId={gId}
                    key={activePostId}
                    onChange={onChange}
                    data={currentNews}
                    types={currentTypeItem} />
                {children}
            </EditorForm>
        </>
    )
};

export const NewsCreateForm = connect(
    mapNewsCreateFormStateToProps,
    mapNewsCreateFormActionToProps
)(
    withComponentEnjector<NNewsCreateForm.Props>(NewsCreateFormPresenter, ['news_creator'])
)