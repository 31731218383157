import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
  CREATE_FILE: '',
  READ_GROUP_FILES: '',
  READ_FOLDERS: '',
  CHANGE_FILE: '',
  SELECT_FILE: '',
  DELETE_FILE: '',
  SELECT_ALL_FILES: '',
  TRUNCATE_GROUP_FILES: '',
  TRUNCATE_FOLDERS: '',
  SET_FOLDER: '',
  SET_MOVE_FILES: '',
  IS_LOADING_GROUP_FILES: '',
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.FILES)}), {} as typeof actions);