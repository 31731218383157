import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { loadCourseSessions } from '../../redux/actions/LMSTeacherOffice'

export interface ISessionsListProps extends IClassNameProps, ISessionsListStateProps, IDispatchProps, RouteComponentProps, ISessionsListDispatchProps {
    tag?: 'div';
}

export interface ISessionsListState {

}

export interface ISessionsListStateProps {

}

export const mapStateToProps = (state: any, props: any) => ({
    activeSessions: state.LMSTO.courses.values[props.cid].activeSessions,
    archiveSessions: state.LMSTO.courses.values[props.cid].archiveSessions,
    isLoading: state.LMSTO.sessionsWork.isLoading,
    activeSessionsFinished: state.LMSTO.courses.values[props.cid].activeSessionsFinished,
    archiveSessionsFinished: state.LMSTO.courses.values[props.cid].archiveSessionsFinished,
    // course: state.LMSTO.courses.values[props.cid],
    // units: state.LMSTO.moderation.units,
    // ids: state.LMSTO.moderation.results
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadCourseSessions
}, dispatch);

export type ISessionsListDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnSessionsList = cn('TOSessionsList');