import React, { FC, useState, memo } from 'react'
import { IPhotoView, mapStateToProps, IPhotoViewStateProps } from './PhotoView.index'
import { connect } from 'react-redux';
import { FileViewer } from 'uielements/src/FileViewer/FileViewer';
import { Button, Icon } from 'uielements/src'
import { createImgUrl, createFile } from '../Photoalbums.utils'

const nextFile = (array: any[], id: string) => {
    const index = array.findIndex((val: any) => val.id === id);
    if (index < array.length - 1) {
        return array[index + 1]
    } else {
        return array[0];
    }
}
const prevFile = (array: any[], id: string) => {
    const index = array.findIndex((val: any) => val.id === id);
    if (index > 0) {
        return array[index - 1];
    } else {
        return array[array.length - 1];
    }
}

export const PhotoView_: FC<IPhotoView & IPhotoViewStateProps> = ({ files, result, gid, aid, count, onClose, id, open, fname = 'none', currentFile }) => {
    // const [currentId, setCurrentId] = useState(id);

    if (!open) return null;
    const farray: any[] = result.map((val: any) => files[val])
    
    if (!farray.find((val: any) => val.id === id)) {
        farray.push(createFile(createImgUrl(id, fname), fname, id, currentFile?.action))
    }
    const fnext = nextFile(farray, id)
    const fprev = prevFile(farray, id)
    const navRender = () => {
        return <>
            <Button
                type={'rlink'}
                // href={`/group/${gid}/albums/${aid}/${fprev.id}/${fprev.name}`}
                href={`?aid=${aid}&fid=${fprev.id}&fin=${fprev.name}`}
                theme={'unstyled'}
                className={'FileViewer-PrevBtn'}>
                <Icon icon="fa-arrow-left" />
            </Button>
            <span className={'FileViewer-Position'}>{farray.findIndex((val: any) => val.id === id) + 1}/{count}</span>
            <Button
                type={'rlink'}
                // href={`/group/${gid}/albums/${aid}/${fnext.id}/${fnext.name}`}
                href={`?aid=${aid}&fid=${fnext.id}&fin=${fnext.name}`}
                theme={'unstyled'}
                className={'FileViewer-NextBtn'}>
                <Icon icon="arrow-fa-right" />
            </Button>
        </>
    }
    return <FileViewer files={farray} onClose={onClose} id={id} navRender={navRender} />
}

export const PhotoView = connect<IPhotoViewStateProps, unknown, IPhotoView>(
    mapStateToProps
)(memo(PhotoView_))