import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    // loadStatCommentsCount
} from '../../../redux/actions/LMSTeacherOffice'
import {
    // getStatCommentsCount
} from '../../../redux/sagas/LMSTO/selectors'
///////////

export type IAllReportsProps = IAllReportsOwnProps & IAllReportsStateProps & IDispatchProps

export interface IAllReportsState { }

export interface IAllReportsOwnProps extends IClassNameProps {
    cid: string
}

export interface IAllReportsStateProps {
    sessionsIds: string[]
    archiveSessions: string[]
    normalSessions: {[key: string]: any}
}

export type IDispatchProps = ReturnType<typeof mapDispatchToProps>

////////////

export const mapStateToProps = (state: any, props: IAllReportsOwnProps) => ({
    // commentsCount: getStatCommentsCount(state),
    sessionsIds: state.LMSTO.courses.values[props.cid].activeSessions,
    archiveSessions: state.LMSTO.courses.values[props.cid].archiveSessions,
    normalSessions: state.LMSTO.sessions,
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IAllReportsOwnProps) => bindActionCreators({
    // loadStatCommentsCount
}, dispatch);

////////////

export const cnClassName = cn('TOAllReports');