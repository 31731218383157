import { IStateType as IState } from 'redux/store';

/**
 * return all modals as object
 * @param { IState } state
 */
export const getModals = (state: IState) => state.modals

/**
 * return modal object by id
 * @param { string } id
 */
export const getModalById = (id: string) => (state: IState) => getModals(state)[id]

/**
 * return modal data by id
 * @param { string } id
 */
export const getModalDataById = (id: string) => (state: IState) => getModals(state)[id].data