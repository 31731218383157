import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
// import { ICourse } from '../mock';
// import { loadCategories } from "../../../redux/actions/LMS";

export interface ISessionListProps extends IClassNameProps, ISessionListStateProps, IDispatchProps, ISessionListDispatchProps {
    tag?: 'div';
}

export interface ISearchState {

}

export interface ISessionListStateProps {
    // categories: any;
    // cIds: string[]
}

export const mapStateToProps = (state: any) => ({
    // categories: state.LMS.categories.entities.values,
    // cIds: state.LMS.categories.result,
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    // loadCategories,
}, dispatch);

export type ISessionListDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnSessionList = cn('SessionList');