
import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import { API } from 'utils/src/utils'

const handleContextActionDownloadThreadAttachments = function* handleContextActionDownloadThreadAttachments({ payload }: AT.AContextAction) {
    const { action, id } = payload
    
    if (action === 'downloadThreadAttachments') {
        try {
            window.open(`${API.base.requestDomain}/ru/Data/v3/files/download/news/${id}`, "_blank"); 

        } catch (error) {
            console.error(error)
        }
    }
    yield;

    return 0;
}

export default handleContextActionDownloadThreadAttachments