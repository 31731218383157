import { Rule } from '../../../../Base.validate'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';

export interface IEditorTypeUserProps {
    data: any;
    onChange: (data: any, validate: boolean, errors?: Rule[]) => void;
    maxThanksValue?: number;
    maxThanksArgumentsCount?: number;
    thanksForAll?: number;
    typeParams?: any;
}
export const mapStateToProps = (state: any) => ({
    maxThanksValue: state.store.appSettings.maxThanksValue,
    maxThanksArgumentsCount: state.store.appSettings.maxThanksArgumentsCount,
    userData: getCurrentUser(state),
    thanksForAll: getCurrentUser(state).extData.thanksForAll
})