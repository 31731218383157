import * as React from 'react';
import * as utils from 'utils/src/utils';

import WidgetPresenter from '../Widget';
import { cnWidget, IWidgetProps } from '../Widget.index';
import { widgets } from "i.widgets";

// import './Widget_type_calendar.scss';
// import { NewsView } from 'blocks/NewsView/NewsView';
import { FloatMessageWidget } from 'blocks/FloatMessageWidget/FloatMessageWidget'

const type = 'FloatMessageWidget';

export default class WidgetTypeCalendarPresenter extends WidgetPresenter<IWidgetProps> {
  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    let { tag: TagName = 'div' } = this.props;
    console.log('FloatMessageWidget', this.props)

    if (this.props.edit)
      return (
        <widgets.components.common {...this.props} className={cnWidget({ type })}>
          <FloatMessageWidget
        {...this.props}
        className={cnWidget({ type, subtype })}
        // className={cnWidgetMui({ type, subtype })}
        contexts={this.relations.concat(this.props.widget.id)}
        // tag={widgets.components.common}
        isEditable={this.props.widget.isEditable}
        isEdit={this.props.widget.HTMLWidgetContentEdit}
      />
        </widgets.components.common>
      );

    return (<FloatMessageWidget
        {...this.props}
        className={cnWidget({ type, subtype })}
        // className={cnWidgetMui({ type, subtype })}
        contexts={this.relations.concat(this.props.widget.id)}
        // tag={widgets.components.common}
        isEditable={this.props.widget.isEditable}
        isEdit={this.props.widget.HTMLWidgetContentEdit}
      />);
  }
}