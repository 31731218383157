export const LOADING_SET_STATUS = 'LOADING_SET_STATUS';

export type loadingsStatuses = '' | 'user' | 'localization';

export interface ILoadingReducer {
  status: loadingsStatuses;
}

export interface ILoadingSetStatusAction {
  type: typeof LOADING_SET_STATUS,
  payload: loadingsStatuses;
};

export type ILoadingActions = ILoadingSetStatusAction;

export const loadingSetStatus = (payload: loadingsStatuses): ILoadingActions => ({
  type: LOADING_SET_STATUS,
  payload
});