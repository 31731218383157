import { Box } from 'muicomponents/src/Box';
import { styled } from 'muicomponents/src/mui/system';
import { SortableList } from 'muicomponents/src/SortableList';

export const StyledSortableList = styled(SortableList)({

}) as typeof SortableList;

export const TabsSettingsItemBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: 8
}) as typeof Box;

export const TabsSettingsItemDataBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: 8
}) as typeof Box;

export const TabsSettingsItemChackboxLabel = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: 8
}) as typeof Box;