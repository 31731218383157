import * as React from 'react';
// import * as utils from 'utils/src/utils';
// import { withRouter } from "react-router"
import WidgetPresenter from '../Widget';
// import { withBemMod } from '@bem-react/core';
import { IWidgetTypeSkillsNewProps } from './Widget_type_SkillsNew.index'
import { cnWidget } from '../Widget.index';
import  SkillsNew  from 'blocks/SkillsNew/SkillsNew'
import { widgets } from 'i.widgets';
import * as utils from 'utils/src/utils';
import './Widget_type_SkillsNew.scss'
import { IWidgetProps } from '../Widget.index';
import { match } from 'react-router';


const type = 'skillsNew';

class WidgetTypeSkillsNew extends WidgetPresenter<IWidgetTypeSkillsNewProps> {

    public render() {
        if (!this.props.widget) return null;

        // if (!this.props.wcontext.uId) return null;
        const subtype = utils.widgetSubtype(this.props.widget.type);
        const Tag = widgets.components.common
        return (
            <Tag {...this.props} className={cnWidget({ type, subtype })}>
                <SkillsNew userid={this.props.wcontext.uId} />
            </Tag>
        );
    }
}

export default WidgetTypeSkillsNew