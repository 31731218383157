/**
 * @packageDocumentation
 * @module Widget_Settings_quests
 */
 import Image from 'blocks/WidgetBlocks/MobileApp/MobileApp.svg'
 import { GUID_EMPTY } from "utils/src/constants.prn";
 
 export const emptyItem: any = {
  imgId: GUID_EMPTY,
  imgUrl: Image,
  href: '',
  imgName: '',
  deleted: false,
  openInNewTab: false
}

export default {
  showImg: true,
  // img: Image,
  img:  {
    aspect: 0,
    elements: [
      emptyItem
    ],
  }
}