import React, { ComponentProps, FC } from 'react'
import { Box, Typography, styled } from 'muicomponents/src';

const ListItemWithIcon = styled(Box)({
    margin: '0 auto',
    display: 'flex',
    height: '46px',
    alignItems: 'center',
    maxWidth: 'calc(100% - 30px)',
    textDecoration: 'none',
    '& a': {
        textDecoration: 'none',
    }
}) as typeof Box;

interface IDescriptionLineProps extends ComponentProps<typeof ListItemWithIcon> {
    icon: JSX.Element
    value: string | JSX.Element
  }

export const DescriptionLine: FC<IDescriptionLineProps> = ({
    icon,
    value,
    ...props
}) => {

    return (
        <ListItemWithIcon
            {...props}
        >
            {icon}
            <Box>
                <Typography variant='body1' sx={{ pt: '4px', pl: '4px' }}>
                    {value}
                </Typography>
            </Box>
        </ListItemWithIcon>
    )
}

