import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { ReactNode } from 'react';
///////////


export interface IMiniTimelineListProps extends IClassNameProps {
    isFinished: boolean
    isLoading: boolean
    isError: boolean
    itemsCount: number
    loadMore: () => void
    loadNode?: ReactNode
}

export const cnClassName = cn('MiniTimelineList');