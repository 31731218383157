import Component, { SkillList } from './SkillsList';
import {
    SkillsListNew,
    cnClassName,
} from './SkillsList.interface';


export {
    cnClassName,
    SkillList
}

export type IComponentProps = SkillsListNew.IOwnProps;
export type IComponentPresenterProps = SkillsListNew.IPresenterProps;


export default Component