import { Avatar, Icon } from 'uielements/src';
import React from 'react';
import { Translate } from '../../../../../localizations/Translate';
import { LeftMenuItemLabel } from '../label';
import { ILeftMenuItemLabelProps, cnLeftMenuItem } from './../../LeftMenuItem.index';
import { getI18nKey } from './../utils';
import { childrenFixIconBlocks } from './../../../redux/constants';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

export const LeftMenuItemDropdownLabel: React.FC<ILeftMenuItemLabelProps> = ({
  data,
  parentData,
  authUserData,
  expanded, 
  ...otherProps
}) => {
  return (
    // <Translate i18nKey={`title:${data.url === '/shop' ? data.title : getI18nKey(data)}`} defaults={data.title}>
    <LeftMenuItemLabel renderType={data.renderType} blockType={data.blockType} notViewedCount={data.notViewedCount} {...otherProps}>
      {
        data.blockType === 'user'
          ?
          <Tooltip title={authUserData.displayName}>
          <span>
            <Avatar size={48} imgUrl={authUserData.userPhotoUrl} imgId={authUserData.imgId} name={authUserData.displayName} />
          </span>
           </Tooltip>
          :
          <Icon icon={data.icon || (childrenFixIconBlocks.includes(parentData?.blockType) && parentData?.icon) || 'info'} />
      }
      <div className={cnLeftMenuItem('Name')}>
        {
          data.blockType === 'user' ?
            authUserData.displayName
            :
            // title
            data.blockType === 'shop' ?
              data.title :
              <Translate i18nKey={getI18nKey(data)} defaults={data.title} />
        }
      </div>
      <Icon icon='chevron-left' className={`Chevron ${expanded ? 'expanded' : ''}`} />
    </LeftMenuItemLabel>
    // </Translate>
  )
}