import { generateWidget, generateColumn, widgets as allWidgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import i18n from '../localizations/i18n';

export const widgets = (props: { type: string; subtype: string }): IWidget<IColumn[]> => {
  return generateWidget({
    type: allWidgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type:
              allWidgets.types[props.type] + (props.subtype && props.subtype !== 'common' ? '/' + props.subtype : ''),
          }),
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  });
}