import { Pie, HorizontalStacks, BarGroups } from 'uielements/src/Charts_v2';
import i18n from 'localization/src/i18n';
import _ from 'lodash';


type StatisticType = 'achievements' | 'shop' | 'quests' | 'currency' | 'ideas' | 'quizzes' | 'forms' | 'badge';
type Item = { title: string; value: string };

export const statisticTypes: { [s: string]: string[] } = {
  achievements: ['usersstatus', 'dynamics'], // 'completesbyuser', 'userscompleted'
  // shop: ['ordersdynamic', 'ordersdynamicmoney', 'ordersdynamicpryaniks'], // 'alltospent', 'orderscount', 'spentcurrency', 'spentcurrencyrubles', 
  virtualcurrency: ['emissiondynamics', 'expirationdynamics', 'usingdynamics', 'ordersdynamic', 'ordersdynamicmoney', 'ordersdynamicpryaniks'], // (shop) 'alltospent', 'orderscount', 'spentcurrency', 'spentcurrencyrubles', 
  quests: ['usersstatus', 'userscompletedcount', 'stepsusersstatus'], //'cashsum', 
  ideas: ['ideasperstatuscount', 'dynamics'], // 'authorsrating', 'sortedrating', 'keyvalues'
  quizzes: ['resultsbyquiz', 'answersbyquiz/fronttest', 'countanswered'],
  forms: ['statusesdynamics'],
  badges: ['dynamics'], //'info', 'search'
  activity: ['users', 'visits'],
}


export const charts: any = {
  pie: Pie,
  horizontalStacks: HorizontalStacks,
  barGroups: BarGroups
}


const noMargin = { left: 0, right: 0, top: 0 };
/**
 * types - типы графиков.
 * У одной статистики может быть несколько отображений
 * props - будет передан в компонент (График)
 */
export const availableStatisticsView: { [s: string]: { [s: string]: any } } = {
  achievements: {
    usersstatus: {
      types: ['pie'],
      props: {},
    },
    dynamics: {
      types: ['barGroups'],
      props: {},
    }
  },

  quests: {
    usersstatus: {
      types: ['pie'],
      props: {},
    },
    userscompletedcount: {
      types: ['horizontalStacks'],
      props: { hideLegend: true, stackLabels: true, margin: { ...noMargin, top: 12 } }
    },
    stepsusersstatus: {
      types: ['horizontalStacks'],
      props: { hideLegend: true, stackLabels: true, margin: { ...noMargin, top: 12 } }
    },
  },

  virtualcurrency: {
    emissiondynamics: {
      types: ['barGroups'],
      props: { hideLegend: true, }
    },
    expirationdynamics: {
      types: ['barGroups'],
      props: { hideLegend: true, }
    },
    usingdynamics: {
      types: ['barGroups'],
      props: { hideLegend: true, }
    },
    ordersdynamic: {
      types: ['barGroups'],
      props: { hideLegend: true, }
    },
    ordersdynamicmoney: {
      types: ['barGroups'],
      props: { hideLegend: true, }
    },
    ordersdynamicpryaniks: {
      types: ['barGroups'],
      props: { hideLegend: true, },
    },
  },

  ideas: {
    ideasperstatuscount: {
      types: ['horizontalStacks'],
      props: { hideLegend: true, stackLabels: true, margin: { ...noMargin, top: 12 } }
    },
    dynamics: {
      types: ['barGroups'],
      props: {}
    },
  },

  quizzes: {
    countanswered: {
      types: ['pie'],
      props: {}
    },
    resultsbyquiz: {
      types: ['horizontalStacks'],
      props: { hideLegend: true, stackLabels: true, margin: { ...noMargin, top: 12 }, showUsers: true }
    },
    "answersbyquiz/fronttest": {
      types: ['horizontalStacks'],
      props: { hideLegend: true, stackLabels: true, margin: { ...noMargin, top: 12 }, showUsers: true, topLabels: true, showValues: true }
    }
  },

  forms: {
    statusesdynamics: {
      types: ['barGroups'],
      props: {}
    }
  },

  badges: {
    dynamics: {
      types: ['barGroups'],
      props: { hideLegend: true }
    }
  },

  activity: {
    users: {
      types: ['barGroups'],
      props: {},
    },
    visits: {
      types: ['barGroups'],
      props: {},
    }
  }
}

/**
 * используется для настройки виджета
 */
export const statisticFilters: { [s: string]: { [s: string]: any[] } } = {
  achievements: {
    usersstatus: [{ type: 'achievementGoals', multiple: false }],
    dynamics: [{ type: 'dates' }, { type: 'maxItems' }, { type: 'achievementGoals', multiple: true }, { type: 'onlyModeration' }],
  },

  quests: {
    usersstatus: [{ type: 'quests', multiple: false, required: true }],
    userscompletedcount: [{ type: 'quests', multiple: false, required: true }],
    stepsusersstatus: [{ type: 'quests', multiple: false, required: true }]
  },

  virtualcurrency: {
    emissiondynamics: [{ type: 'dates' }, { type: 'groupBy' }, { type: 'maxItems' }],
    expirationdynamics: [{ type: 'dates' }, { type: 'groupBy' }, { type: 'maxItems' }],
    usingdynamics: [{ type: 'dates' }, { type: 'groupBy' }, { type: 'maxItems' }, { type: 'activityVirtualUsers' }],
    ordersdynamic: [{ type: 'dates' }, { type: 'groupBy' }, { type: 'maxItems' }],
    ordersdynamicmoney: [{ type: 'dates' }, { type: 'maxItems' }],
    ordersdynamicpryaniks: [{ type: 'dates' }, { type: 'groupBy' }, { type: 'maxItems' }],
  },

  ideas: {
    ideasperstatuscount: [],
    dynamics: [{ type: 'dates' }, { type: 'maxItems' }],
  },

  quizzes: {
    countanswered: [{ type: 'quizzes', multiple: false, required: true }],
    resultsbyquiz: [{ type: 'quizzes', multiple: false, required: true }],
    "answersbyquiz/fronttest": [{ type: 'quizzes', multiple: false, required: true }],
  },

  forms: {
    statusesdynamics: [{ type: 'dates' }, { type: 'maxItems' }, { type: 'workflows', multiple: false, required: true }]
  },

  badges: {
    dynamics: [{ type: 'dates' }, { type: 'maxItems' }, { type: 'badges', multiple: false, required: false }]
  },

  activity: {
    users: [{ type: 'dates' }, { type: 'groupBy' }, { type: 'maxItems' }, { type: 'activityVirtualUsers' }, { type: 'postTypes', multiple: true, required: true }],
    visits: [{ type: 'dates' }, { type: 'groupBy' }, { type: 'maxItems' }, { type: 'activityVirtualUsers' }, { type: 'postTypes', multiple: true, required: true }],
  }
}

/**
 * Ключ - название фильтра
 * Знчение - массив названий параметров запроса
 */
const statisticContextFilters: any = {
  dates: ['beginDate', 'endDate'],
  groupBy: ['groupBy'],
  maxItems: ['maxItems'],
  activityVirtualUsers: ['activityVirtualUsers'],
  postTypes: ['query'],
  badges: ['badgeId'],
  quests: ['questId'],
  quizzes: ['quizId'],
  workflows: ['templateId'],
  achievementGoals: ['idRules'],
}

/**
 * multiChart - один запрос, несколько графиков
 * multiRequest - несколько запросов, несколько графиков
 * default - один запрос - один график
 */
export const statisticSpecificProps: { [s: string]: { [s: string]: any } } = {
  achievements: {
    usersstatus: { multiChart: true, multiRequest: true, paramField: 'achievementGoals' }
  },
  quizzes: {
    "answersbyquiz/fronttest": { multiChart: true }
  }
}


export const statisticFilterTypes: any[] = _.uniqBy(
  Object.keys(statisticFilters)
    .reduce((acc: any[], key) => ([
      ...acc,
      ...Object
        .keys(statisticFilters[key])
        .reduce((acc2: any[], stat) => [...acc2, ...statisticFilters[key][stat]], [])
    ]), []), (item) => item.type);

/**
 * Для каждого типа статистики массив типов фильтров
 * Возвращает объект типа {module: {statistic: []}}
 */
export const statisticContextFilterItems: any = Object.keys(statisticFilters)
  .reduce((acc: any, key) => ({
    ...acc,
    [key]: Object
      .keys(statisticFilters[key])
      .reduce((acc2: any, stat) => ({ ...acc2, [stat]: statisticFilters[key][stat].map((el) => statisticContextFilters[el.type]).flat() }), {})
  }), {})

export const statisticFilterItems: any = Object.keys(statisticFilters)
  .reduce((acc: any, key) => ({
    ...acc,
    [key]: Object
      .keys(statisticFilters[key])
      .reduce((acc2: any, stat) => ({ ...acc2, [stat]: statisticFilters[key][stat].map((el) => el.type)}), {})
  }), {})

export const statisticModulesItems: Item[] = Object.keys(statisticTypes).map((el) => ({ title: i18n.t(`pryaniky.statistics.title.${el}`), value: el }));

export const statisticItems: { [s: string]: Item[] } = Object
  .keys(statisticTypes)
  .reduce((acc, el) => ({ ...acc, [el]: statisticTypes[el].map(item => ({ title: i18n.t(`pryaniky.statistics.title.${el}.${item}`), value: item })) }), {});

export const statisticsViewItems: { [s: string]: { [s: string]: Item[] } } = Object
  .keys(availableStatisticsView)
  .reduce((acc, el) => ({
    ...acc, [el]: {
      ...acc[el], ...Object.keys(availableStatisticsView[el]).reduce((acc, item) => {
        return ({ ...acc, [item]: availableStatisticsView[el][item].types.map((type: string) => ({ title: i18n.t(`pryaniky.statistics.type.${type}`), value: type })) })
      }, {})
    }
  }), {} as { [s: string]: { [s: string]: Item[] } });
