import Component, { Presenter } from './component';
import {
    IDialogContentOwnProps,
    IDialogContentPresenterProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    Presenter,
}

export type IComponentProps = IDialogContentOwnProps;
export type IComponentPresenterProps = IDialogContentPresenterProps;


export default Component