import Component/*, { Presenter }*/ from './component';
import {
    IMoveToGroupOwnProps,
    IMoveToGroupPresenterProps,
    cnClassName,
} from './interfaces';


export {
    cnClassName,
    // Presenter,
}

export type IComponentProps = IMoveToGroupOwnProps;
export type IComponentPresenterProps = IMoveToGroupPresenterProps;


export default Component