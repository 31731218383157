import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import React from 'react';
import { Input } from 'uielements/src/Input/Input';
import i18n from 'localizations/i18n';
import { CSSTransition } from 'react-transition-group';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
import { toast } from 'react-toastify';
import { cloneObject, API } from 'utils/src/utils';

import {
  cnInvite,
  IInviteProps,
  IInviteState,
  inviteFieldTypes,
  IFieldLabels,
  ITextFieldState,
  fieldIcons,
  IUserInfo,
  requiredFields,
} from './Invite.index';

import './Invite.scss';
import { OptionalFields } from './OptionalFields/Invite-OptionalFields';

const initialState: IInviteState = {
  UserName: { value: '', valid: true },
  ExternalUserId: { value: '', valid: true },
  FirstName: { value: '', valid: true },
  LastName: { value: '', valid: true },
  MiddleName: { value: '', valid: true },
  Phone: { value: '', valid: true },
  Password: { value: '', valid: true },
  PasswordConfirmation: { value: '', valid: true },
  Comment: { value: '', valid: true },
  Position: { value: '', valid: true },
  Division: { value: '', valid: true },
  HasRole: { value: false, valid: true },
  IsAdminFlag: { value: false, valid: true },
  IsMale: { value: false, valid: true },
  IsFemale: { value: false, valid: true },
  showOptionalFileds: false,
};

const fieldLabels: IFieldLabels = {
  Comment: i18n.t('comment'),
  UserName: i18n.t('email'),
  ExternalUserId: i18n.t('user_login'),
  FirstName: i18n.t('name'),
  LastName: i18n.t('last_name'),
  MiddleName: i18n.t('middle_name'),
  Phone: i18n.t('phone'),
  Position: i18n.t('position'),
  Division: i18n.t('division'),
  HasRole: i18n.t('boss'),
  IsAdminFlag: i18n.t('admin'),
  IsMan: i18n.t('sex'),
  IsMale: i18n.t('male'),
  IsFemale: i18n.t('female'),
  Password: i18n.t('password'),
  PasswordConfirmation: i18n.t('password_confirm'),
};

export class InvitePresenter extends React.Component<IInviteProps, IInviteState> {
  public el: HTMLElement | null;

  constructor(props: IInviteProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = cloneObject(initialState);
  }

  public render() {
    const { UserName: email, showOptionalFileds, ...optionalFields } = this.state;
    const { UserName: emailFieldType, ...optionalFieldTypes } = inviteFieldTypes;
    const { UserName: emailLabel, ...optionaFieldLabels } = fieldLabels;
    const { UserName: emailIcon, ...optionalFieldIcons } = fieldIcons;
    const submitDisabled: boolean = Object.keys(this.state).reduce((disabled: boolean, field: string) => {
      if (this.state[field].valid !== undefined && !this.state[field].valid) return true;
      if (requiredFields.includes(field) && !this.state[field].value) return true;
      return disabled;
    }, false);
    return (
      <article className={cnInvite()}>
        <form className={cnInvite('Form')} onSubmit={this.handleSubmit}>
          <Input
            icon={emailIcon}
            placeholder={emailLabel}
            name="UserName"
            className={cnInvite('Input')}
            onChange={this.handleChange}
            value={email.value + ''}
            required={true}
            invalid={!email.valid}
          />
          <div className={cnInvite('SlideDown')}>
            <Button
              onClick={this.toggleOptionalFields}
              className={cnInvite('ToggleButton')}
              noBorder={true}
              noBorderRadius={true}
            >
              <Icon
                icon={this.state.showOptionalFileds ? 'angle-up' : 'angle-down'}
                className={cnInvite('ToggleIcon')}
              />
              {i18n.t('optional_info')}
            </Button>
            <CSSTransition
              in={showOptionalFileds}
              classNames={{
                enter: cnInvite('OptionalFields', { enter: true }),
                enterActive: cnInvite('OptionalFields', { entering: true }),
                enterDone: cnInvite('OptionalFields'),
                exit: cnInvite('OptionalFields', { exit: true }),
                exitActive: cnInvite('OptionalFields', { exiting: true }),
                exitDone: cnInvite('OptionalFields'),
              }}
              timeout={500}
              unmountOnExit={true}
            >
              <OptionalFields
                fields={optionalFields}
                labels={optionaFieldLabels}
                fieldTypes={optionalFieldTypes}
                icons={optionalFieldIcons}
                validate={this.validate}
                setField={this.setField}
                onChange={this.handleChange}
              />
            </CSSTransition>
          </div>
          <Button
            onClick={this.handleSubmit}
            type="submit"
            className={cnInvite('Submit')}
            disabled={submitDisabled}
            main
          >
            {i18n.t('invite')}
          </Button>
        </form>
      </article>
    );
  }

  private handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const data: IUserInfo = {
      UserName: this.state.UserName.value + '',
      ExternalUserId: this.state.ExternalUserId.value + '' || this.state.UserName.value + '',
    };
    for (const field in this.state) {
      if (field === 'PasswordConfirmation') continue;
      else if (field === 'IsMale' && this.state[field].value) data.IsMan = true;
      else if (field === 'IsFemale' && this.state[field].value) data.IsMan = false;
      else if (this.state[field].value && this.state[field].valid) data[field] = this.state[field].value;
    }
    this.sendData(data);
  };

  private sendData = (data: IUserInfo) => {

    API.users.invite(data).r.then((response: any) => {
      if (!response) toast.error('Something went wrong');
      else {
        this.setState(cloneObject(initialState));
      }
    });
  };

  private toggleOptionalFields = (e: React.MouseEvent) => {
    e.preventDefault();
    this.setState(p => ({ showOptionalFileds: !p.showOptionalFileds }));
  };

  private handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const valid = this.validate(name, value);
    this.setState({ [name]: { value, valid } });
  };

  private setField = (name: string, value: ITextFieldState) => {
    this.setState({ [name]: value });
  };

  private validate = (name: string, value: string): boolean => {
    if (value === '') return true;
    switch (name) {
      case 'UserName':
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        );
      case 'Phone':
        return /^\+\d-(\d{3})-(\d{3})-(\d{4})$/.test(value);
      case 'Password':
      case 'PasswrodConfirmation':
        return value.length > 6 && value.length < 16;
      default:
        return true;
    }
  };
}

export const Invite = InvitePresenter;
