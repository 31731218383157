import { cn } from '@bem-react/classname';
import { IDialog } from '../DialogWrapper.index';

export interface IImageCropperProps extends IImageCropperOwnProps {}

export interface IImageCropperOwnProps extends IDialog {}

export interface IImageCropperState {
  fileData?: any; 
  cropUrl?: string;
  file: any,
  crop: {
    x: number;
    y: number;
    aspect: number;
    width: number;
    height?: number;
  }
}

export const cnImageCropper = cn('ImageCropper');