import achievements from './Achievements.png';
import creativetasks from './Creativetasks.png';
import ideas from './Ideas.png';
import news from './News.png';
import polls from './Polls.png';
import thanks from './Thanks.png';
import badges from './Badges.png';
import events from './Events.png';
import workflow from './Workflow.png';

export const newsTypesIcons = {
    thanks,
    achievements,
    news,
    polls,
    creativetasks,
    ideas,
    events,
    badges,
    workflow
};

export default newsTypesIcons;