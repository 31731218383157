import { IDispatchProps } from '../../redux/connector';
import { RouteComponentProps } from 'react-router';
import { cn } from '@bem-react/classname';
import { IRatingValue } from 'utils/src/requests/models/api.ratings';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export const cnMountainRating = cn('MountainRating');

export interface IMountainRatingProps extends IMountainRatingStateProps, IDispatchProps, RouteComponentProps<{ id: 'string' }> {}

export interface IMountainRatingUserInfo {
  pos: string;
  id: string;
  value: string;
  curValue: number;
  displayName: string;
  imgUrl: string;
  imgId: string;
  percantage: string;
}

export interface IMountainRatingState {
  users: IMountainRatingUserInfo[];
  rating?: IRatingValue;
  usersOffset: number;
  usersPerPage: number;
  touchStartX?: number;
  filter?: number;
  isFinished: boolean;
  isLoading: boolean;
  backgroundLoading: boolean;
  filters: IFilterGroup[];
}

export type FilterOptions = IFilterGroup[];

interface IFilterGroup {
  label: string;
  options: IFilterOption[];
}

interface IFilterOption {
  label: string;
  value: number;
}

export type typeofHandleScroll = (to: { direction: 'left' | 'right'; value: number }) => void;

export const mapStateToProps = (state: any) => ({
  uid: getAuthUser(state).baseData.id as string,
  userName: getAuthUser(state).baseData.displayName as string,
  userPhoto: getAuthUser(state).baseData.userPhotoUrl as string,
  userPhotoId: getAuthUser(state).baseData.imgId as string,
});

export type IMountainRatingStateProps = ReturnType<typeof mapStateToProps>

