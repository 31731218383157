import moment from 'moment';
import React, { FC, useMemo, forwardRef, ComponentProps } from 'react';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { styled } from 'muicomponents/src/mui/system';
import { Box } from 'muicomponents/src/Box/Box';
import { Translate } from 'localizations/Translate';

const fiveMinAtMilliseconds = 300000;

// const BoxWithRef = forwardRef<any, ComponentProps<typeof Box>>((props, ref) => {
//     return <Box {...props} ref={ref} />
// });

const MainBox = styled('div')<{color?: string, borderColor?: string}>(({color ='#adb6c3', borderColor = '#fff'}) => ({
    border: `1px solid ${borderColor}`,
    borderRadius: 8,
    backgroundColor: color,
    position: 'absolute',
    bottom: "1px",
    right: "1px",
    padding: '0px 6px',
    fontSize: 12,
    color: '#fff',
    zIndex: 5
}));

export const ActivityBlock: FC<{
    sex: 'm' | 'f',
    lastActivityDate: string | null
}> = ({
    sex,
    lastActivityDate
}) => {
  
    const now = moment();

    const isActive = useMemo(() => {
        if(!lastActivityDate) return null;
        const date = moment(lastActivityDate);
        return now.toDate().getTime() - date.toDate().getTime() < fiveMinAtMilliseconds;
    }, [lastActivityDate, now]);

    const lastActivitiTooltipString = useMemo(() => {
        if(!lastActivityDate) return '';
        return <><Translate i18nKey={`came${sex === 'f' ? ' femin' : ''}`} />: {moment(lastActivityDate).format('L HH:mm')}</>;
    }, [lastActivityDate]);

    const lastActivitiString = useMemo(() => {
        if(!lastActivityDate) return null;
        const date = moment(lastActivityDate);
        const timesDiff = now.toDate().getTime() - date.toDate().getTime();
        const minutesDiff = timesDiff / 1000 / 60;
        if(minutesDiff > 60) return <Translate i18nKey={'more than one hour'} />;
        return <>{Math.round(minutesDiff)} <Translate i18nKey={'minutes_abb'} /></>;
    }, [lastActivityDate, now]);
  
    if(!lastActivityDate) return null;

    if (isActive) return (
        <MainBox className='UserActivityBlock' color='#4CAF50' borderColor='#3b873e'>
            <Translate i18nKey={'pryaniky.user.status.online'} />
        </MainBox>
    )
  
    return (
        <Tooltip
            title={lastActivitiTooltipString}
        >
            <MainBox className='UserActivityBlock'>
                {lastActivitiString}
            </MainBox>
        </Tooltip>
    );
};