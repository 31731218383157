import React, { FC, useEffect, useRef } from 'react';
import { ISaggesterProps } from './Saggester.index'
import './Saggester.css'
import { createPortal } from 'react-dom';
import { getOffsetSum, noop } from 'utils/src/utils';

let saggesterRoot: HTMLDivElement;// = document.getElementById('saggesterRoot') as HTMLDivElement;

const getCoords = (elem: HTMLElement) => {
    const box = elem.getBoundingClientRect();
    return Object.assign(getOffsetSum(elem), {
        h: box.height,
        wTop: box.top,
        wLeft: box.left,
        width: box.width,
    });
};

export const Saggester: FC<ISaggesterProps> = (
    { 
        children, 
        onSelect = ()=>{}, 
        className, 
        allowClickElement = React.createRef(), 
        clickOutside = () => { }, 
        width,
        fixed = false,
        anchor,
        left,
        top,
        style = {},
        ...props }) => {
    const selfRef = useRef<HTMLDivElement>(null);
    
    if (!saggesterRoot) saggesterRoot = document.getElementById('Layout') as HTMLDivElement || document.body;
    const handleClickOutside = (event: any) => {
        if (selfRef.current && !selfRef.current.contains(event.target)) {
            ((event.target.clientWidth !== 0) && event.target !== allowClickElement.current) && clickOutside(event.target)
      
        }
        // else clickInside(event.target)
    }

    useEffect(() => {
        
        document.addEventListener('click', handleClickOutside, false);
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
        }
    }, []);

    const onItemClick = (item: any) => (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onSelect(item)
    }
    if (saggesterRoot && anchor && anchor.current) {
        const cord = getCoords(anchor.current);
        const position = {
            left: fixed ? cord.wLeft : cord.left,
            top: fixed ? cord.wTop + cord.h + 5 : cord.top + cord.h + 5,
            transform: '',
            position: (fixed ? 'fixed' : 'absolute') as any,
        };
        /*if (window.innerHeight / (cord.wTop < 0 ? 0 : cord.wTop) <= 1.72) {
            position.top = fixed ? cord.wTop - 5 : cord.top - 5;
            position.transform += ' translateY(-100%)';
        }*/
        if (window.innerWidth / cord.wLeft <= 2) {
            position.left += cord.width;
            position.transform += ' translateX(-100%)';
        }
        return createPortal( 
  // @ts-ignore
            <div ref={selfRef} style={{ width, left, top, ...style, ...position }} className={'Saggester' + (className ? (' ' + className) : '')}>
                {children(onItemClick)}
            </div>,
            saggesterRoot
        );
    }
    return (
        <div ref={selfRef} className={'Saggester' + (className ? (' ' + className) : '')} style={{ width, left, top, ...style }}>
            {children(onItemClick)}
        </div>
    )
}