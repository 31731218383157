import React from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { IQuestData } from 'utils/src/requests/models/api.quests';
import i18n from 'localizations/i18n';
import { IQuestsWidget, cnQuestsWidget, mapStateToProps, mapDispatchToProps } from './QuestsWidget.index';
import './QuestsWidget.scss';
import { Icon } from 'uielements/src';
import { QuestItem } from './QuestItem/QuestItem';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import noData from 'assets/svg/NoData.svg'
import { Avatar } from 'uielements/src'
import { sendSignal } from 'utils/src/signals';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { useWidgetContext } from 'Widgets_v2/context'

const QuestsWidgetPresenter: React.FC<IQuestsWidget> = props => {

  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    props.getAvailableQuests({ uId: props.uid });
  }, []);

  const { tag: TagName = 'div', data, settings } = props;
  const questsIds = Object.keys(data || {})

  const { value: { widgetId }, sendWidgetSignal } = useWidgetContext();

  useDidUpdateEffect(() => {
    sendWidgetSignal('dataUpdate');
  }, [data, currentIndex]);

  if (!props.edit && settings?.hideIfNoData && (!questsIds.length)) return null;

  if (props.edit && !questsIds.length ) return <TagName {...props} className={cnQuestsWidget({}, [props.className])}></TagName>;
  if (!settings?.hideIfNoData && !questsIds.length) return <TagName {...props} className={cnQuestsWidget("Item", [props.className])}>
    <div className={cnQuestsWidget('HeaderEmpty-Title')}>
      {i18n.t('pryaniky.widgets.quests.title')}
    </div>
    <Avatar precentage={100} shape={'rectangle'}
      imgUrl={noData}
    />
    <span className={cnQuestsWidget('HeaderEmpty-Title-Text')}>{i18n.t('pryaniky.widgets.settings.type.shop.noData.text')}</span>
  </TagName>;


  const currentQuest = data[questsIds[currentIndex]];

  const numOfQuests = questsIds.length;

  const decrementIndex = () => currentIndex > 0 && setCurrentIndex(currentIndex - 1);
  const incrementIndex = () => currentIndex < numOfQuests - 1 && setCurrentIndex(currentIndex + 1);

  const additionalProps: { [s: string]: any } = {
    hide: false
  }

  return (
    <TagName {...props} {...additionalProps} className={cnQuestsWidget({}, [props.className])}>
      <div className={cnQuestsWidget('Header')}>
        <h4 className={cnQuestsWidget('Title')} children={i18n.t('pryaniky.widgets.quests.title')} />
        <div className={cnQuestsWidget('Pagination')}>
          <button
            className={cnQuestsWidget('PaginationControl')}
            disabled={currentIndex === 0}
            onClick={decrementIndex}
          >
            {/* <Icon icon="chevron-left" /> */}
            <KeyboardArrowLeftIcon color="primary" />

          </button>
          {currentIndex + 1}
          <span>
            {`/${numOfQuests}`}
          </span>
          <button
            className={cnQuestsWidget('PaginationControl')}
            onClick={incrementIndex}
            disabled={currentIndex === numOfQuests - 1}
          >
            {/* <Icon icon="chevron-right" /> */}
            <KeyboardArrowRightIcon color="primary" />

          </button>
        </div>
      </div>

      <QuestItem key={currentIndex} data={currentQuest} />

    </TagName>
  );


};

export const QuestsWidget = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestsWidgetPresenter);
