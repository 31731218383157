import { ITitlesActions } from './actions.interfaces';
import { TITLES_SET, TITLES_SET_ITEM } from './actions';
import { ITitlesReducer } from './interfaces';

const baseState: ITitlesReducer = [];

// резервируем первые три индекса для того что бы добавлять системные подуровни
export const lastReservedTitleIdx = 2;

export const TitlesReducer = (state = baseState, action: ITitlesActions): ITitlesReducer => {
  switch (action.type) {

    case TITLES_SET: 
      return [ ...action.payload ];

    case TITLES_SET_ITEM: 
      return [ ...state.slice(0, action.payload.position), action.payload.value, ...state.slice(action.payload.position + 1, state.length) ];
   
    default:
      return state

  }
}