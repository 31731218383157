import { Box, styled, Typography } from 'muicomponents/src';

export const SurveyFinishBox = styled(Box)({
  border: "1px solid #fff0",
  margin: 24
}) as typeof Box;

export const SurveyFinishTextTypography = styled(Typography)({
  ['& .Markdown']: {
    ['& p']: {
      fontSize: 16,
      lineHeight: 1
    }
  },
  ['& .CommonmarkRenderImgBlock-Expand']: {
    display: 'none',
    ['& .Icon_expand-arrows-alt']: {
      display: 'none',
    }
  }
}) as typeof Typography;

export const SurveyFinishBadgeBox = styled(Box)({
  
}) as typeof Box;

export const SurveyFinishBadgeDescriptionBox = styled(Box)({
  marginTop: 12,
  display: 'flex',
  alignItems: 'center'
}) as typeof Box;

export const SurveyFinishBadgeDescriptionTypography = styled(Typography)({
  maxWidth: 500,
  fontSize: 16,
  marginLeft: 12
}) as typeof Typography;

export const SurveyFinishBadgeButtonsBox = styled(Box)({
  display: 'flex',
  margin: '24px 0',
  flexDirection: 'column',
  alignItems: 'flex-start'
}) as typeof Box;

export const SurveyFinishWarningTypography = styled(Typography)({
  color: '#6d6c6c'
}) as typeof Typography;