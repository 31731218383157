import * as React from 'react';
import { IEditorTypeNewsProps } from './news.index';
import { NoticesNews, defaultNoticesNews } from '../../../Notices.type'
import { NoticesValidator, noticesValidate } from '../../../Notices.validate'
import { Editor } from '../../Editor'
import * as utils from 'utils/src/utils';
import { EditorState } from 'draft-js';

export const EditorTypeNews: React.FC<IEditorTypeNewsProps> = ({ onChange: cbChange, data, types }) => {
    let localData = data as any;
    if (data.newstype !== 22) {
        localData = { ...utils.cloneObject(defaultNoticesNews), text: EditorState.createEmpty() };
    } 

    const onChange = (data: any) => {
        const vb = new NoticesValidator();
        vb.addData(data)
        vb.setRules(noticesValidate)
        cbChange({...data}, vb.validation().length === 0, vb.validation())
    }

    const onChangeText = (text: string) => {
        localData.text = text;
        onChange(localData);
    }
    const onChangeUsers = (e: any) => {
        // localData.users = e ? e.map((el: any) => el.data) : [];
        onChange(localData);
    }
    const onChangeTitle = (e: any) => {
        localData.header = e.target.value;
        onChange(localData)
    }
    return <Editor
        onChangeTitle={onChangeTitle}
        onChangeUsers={onChangeUsers}
        onChangeText={onChangeText}
        data={localData} />
}
