import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    scormUnitComplete,
    scormUnitSuccessStatus,
} from '../../redux/actions/LMS'
import ScormAPI2004 from 'LMSModule/utils/scormAPI/scormAPI2004/scormAPI2004'
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace LMS {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace ScormResult {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends IClassNameProps {
            scormCMI: { [key: string]: string | number | boolean | null | undefined };
            fullscreen?: boolean
            unitContent?: any
            showScorm?: () => void
        }

        export interface IStateProps {

        }

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = LMS.ScormResult.IOwnProps

export const mapStateToProps = (state: any, props: IOwnProps) => ({

})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({

}, dispatch);

////////////

export const cnClassName = cn('LMSScormResult');