import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import DatePicker from 'uielements/src/MaterialElements/DatePicker/DatePicker'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isValidDate } from 'utils/src/utils.dates'
import { withIndicator } from 'utils/src/CommonRedux/LoadIndicator'
import actionsTypes from '../../../../redux/actionsTypes/LMSTO'
import LinearProgress from '@material-ui/core/LinearProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import FormControl from '@material-ui/core/FormControl';
import { tSt } from 'LMSModule/utils/i18n.adaptor'

type IPresenterProps = StatisticsFull.Settings.IPresenterProps
type IOwnProps = StatisticsFull.Settings.IOwnProps
type IDispatchProps = StatisticsFull.Settings.IDispatchProps
type IStateProps = StatisticsFull.Settings.IStateProps
type IState = StatisticsFull.Settings.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    pathFullStatisticSetting,
    countArchive,
    endDate,
    isMandatory,
    startDate,
    isLoading,
    courses,
    groups,
    disableGroups,
    disableCourses
}) => {
    const onChangeDate = (property: string) => (date: Date) => {
        if (isValidDate(date))
            pathFullStatisticSetting({
                [property]: date.toISOString()
            })
    }

    const onChangeMonadatory = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        pathFullStatisticSetting({
            isMandatory: value === 'true'
        })
    }

    const onChangeArchive = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        pathFullStatisticSetting({
            countArchive: checked
        })
    }

    const changeCourse = (items: any[]) => {
        pathFullStatisticSetting({
            courses: items//.map((item => item.id))
        })
    }

    const changeGroup = (items: any[]) => {
        pathFullStatisticSetting({
            groups: items//.map((item => item.pkid))
        })
    }

    return <div className={cnClassName({}, [className])} >
        <div className={cnClassName('Settings', { isLoading })}>
            <div className={cnClassName('Row')}>
                <DatePicker
                    className={cnClassName('StartDate')}
                    disabled={isLoading}
                    onChange={onChangeDate('startDate')}
                    value={startDate || new Date}
                    label={tSt('setting.date.picker.start')}//{'date'}
                />

                <DatePicker
                    className={cnClassName('EndDate')}
                    disabled={isLoading}
                    onChange={onChangeDate('endDate')}
                    value={endDate || new Date}
                    label={tSt('setting.date.picker.end')}//{'date'}
                />

                <RadioGroup className={cnClassName('RadioGroup')} value={isMandatory} onChange={onChangeMonadatory} row>
                    <FormControlLabel
                        disabled={isLoading}
                        value={true}
                        control={<Radio color="primary" />}
                        label={tSt('setting.mandatory.radio.true')} //"Обязательные"
                    />
                    <FormControlLabel
                        disabled={isLoading}
                        value={false}
                        control={<Radio color="primary" />}
                        label={tSt('setting.mandatory.radio.false')} //"Не обязательные"
                    />
                </RadioGroup>

                <FormControlLabel
                    className={cnClassName('Checkbox')}
                    disabled={isLoading}
                    control={
                        <Checkbox
                            checked={countArchive}
                            onChange={onChangeArchive}
                            name="countArchive"
                            color="primary"
                        />
                    }
                    label={tSt('setting.archive.checkbox')} //{'Архивные'}
                />
            </div>

            {!disableCourses && <div className={cnClassName('Row')}>
                <FormControl fullWidth>
                    <Autocomplete
                        disabled={isLoading}
                        // allowCreate={!field.selectOnlyFromCollection}
                        // fullWidth
                        type={'courses'}
                        // col={'profile'}
                        // fieldId={fieldId}
                        label={tSt('setting.select.courses')} //{'Курсы'}
                        value={courses}
                        onChange={changeCourse}
                    />
                </FormControl>
            </div>}
            {!disableGroups && <div className={cnClassName('Row')}>
                <FormControl fullWidth>
                    <Autocomplete
                        disabled={isLoading}
                        // allowCreate={!field.selectOnlyFromCollection}
                        // fullWidth
                        type={'groups'}
                        // col={'profile'}
                        // fieldId={fieldId}
                        label={tSt('setting.select.groups')} //{'Группы'}
                        value={groups}
                        onChange={changeGroup}
                    />
                </FormControl>
            </div>}
        </div>
        {isLoading && <LinearProgress className={cnClassName('Progress')} />}
    </div>
}

export default withIndicator(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter))), actionsTypes.GET_USERS_COURSES_INFO_HEAD, actionsTypes.GET_USER_STAT_INFO)();
