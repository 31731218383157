import * as React from 'react';
// import * as utils from 'utils/src/utils';
// import { withRouter } from "react-router"
import WidgetPresenter from '../Widget';
// import { withBemMod } from '@bem-react/core';
import { IWidgetTypeLMSTeacherOfficeProps } from './Widget_type_lmsTeacherOffice.index';
import './Widget_type_lmsTeacherOffice.scss';
import { LMSTeacherOffice } from '../../../LMSModule/LMSTeacherOffice/LMSTeacherOffice/LMSTeacherOffice'
// const type = 'about';

class WidgetTypeLMSTeacherOfficePresenter extends WidgetPresenter<IWidgetTypeLMSTeacherOfficeProps > {
  
  public render() {
    if (!this.props.widget) return null;
    
    // const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    if (!this.props.wcontext.uId) return null;
    return (
      // <widgets.components.common {...this.props}>
      <LMSTeacherOffice {...this.props}/>
      // </widgets.components.common>
    );
  }
}

export default WidgetTypeLMSTeacherOfficePresenter