import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Button } from 'uielements/src'
// import { LessonsList } from '../../LessonsList/LessonsList'
import { Comments } from '../Comments/Comments';
import { withPreloader } from '../Preloader/Preloader'
import { withLoad } from '../ButtonWithLoad/ButtonWithLoad'
import { Lesson as Skelet } from '../../Skelets/Lesson/Lesson'
import LessonNavigation from '../../components/LessonNavigation'
import ScormUnit from './component'
import { tS } from 'LMSModule/utils/i18n.adaptor'
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    loadFullUnit,
    saveUnitResult,
    toPrevUnit,
    transitionToNextUnit,
    scormUnitSaveParam
} from "../../redux/actions/LMS";
import {
    loadAllScormParams
} from '../../redux/actions/COMMON'
import ScormResult from '../ScormResult'

const cnClassName = cn('LMSScormUnitLoader');
const zeroId = "00000000-0000-0000-0000-000000000000";
const ButtonL = withLoad(Button)

const getCurrentUnitIndex = (courseUnits: any[], currentId: string) => courseUnits.findIndex((u: any) => currentId === u.id)


export const LessonPresenter: FC<any> = ({
    unit,
    next,
    prev,
    unitContent,
    course,
    log,
    disableActions = false,
    saveParam,
    scormCMI,
    fullscreen,
    forceResult = false
}) => {
    const [showResult, setShowResult] = useState(log.isCompleted)
    const unitIndex = getCurrentUnitIndex(course.courseUnits, unit.id)
    const isFirst = unitIndex === 0;
    const isLast = unitIndex === (course.courseUnits.length - 1);

    let isCompletedScorm = scormCMI["cmi.success_status"] && scormCMI["cmi.success_status"] !== "unknown";
    isCompletedScorm = scormCMI['cmi.completion_status'] && scormCMI['cmi.completion_status'] === 'completed'

    let allowNext = isCompletedScorm//unit.allowSkip
    // allowNext = allowNext || isCompletedScorm
    useEffect(() => {
        if (forceResult) setShowResult(true)
    }, [forceResult])
    const onClickResult = () => {
        setShowResult(!showResult)
    }

    // let showResult = log.isCompleted
    // showResult = forceResult //|| showResult
    return <div className={cnClassName({ fullscreen })}>

        {(showResult) ?
            <ScormResult
                showScorm={() => setShowResult(false)}
                fullscreen={fullscreen}
                unitContent={unitContent}
                scormCMI={scormCMI}
                className={cnClassName('Lesson')}
            /> :
            <ScormUnit
                fullscreen={fullscreen}
                unitLog={log}
                scormCMI={scormCMI}
                className={cnClassName('Lesson')}
                name={unitContent.name}
                unitContent={unitContent}
                saveParam={saveParam} />
        }

        <LessonNavigation
            className={cnClassName('Navigation', { fullscreen })}
            left={allowNext ? <ButtonL
                className={cnClassName('NavBtn')}
                theme={'unstyled_center'}
                onClick={onClickResult}>{showResult ? 'Курс' : 'Результат'}</ButtonL> : undefined}
            right={allowNext ? <ButtonL
                disabled={!allowNext}
                className={cnClassName('NavBtn')}
                theme={'unstyled_center'}
                onClick={next}>{(isLast ? tS('complete.1') : tS('next.1'))}</ButtonL> : undefined}
            unitIndex={unitIndex}
            unitsCount={course.unitsCount}
        />

        {(unitContent.newsUid && unitContent.newsUid !== zeroId && unitContent.allowComments) &&
            <Comments nid={unitContent.newsUid} className={cnClassName('Comments')} />}
    </div>
}

const WithPreloader = withPreloader(LessonPresenter, ['unitContent', 'scormCMI'], [], <Skelet />)



const Loader: FC<any> = (props) => {
    const {
        unitContent,
        loadFullUnit,
        sId,
        unit,
        course,
        saveUnitResult,
        log,
        toPrevUnit,
        transitionToNextUnit,
        scormUnitSaveParam,
        loadAllScormParams,
        scormCMI
    } = props

    const prevInProcess = () => {
        toPrevUnit(unit.courseId, unit.id)
    }

    const nextInProcess = () => {
        transitionToNextUnit(unit.courseId)
    }

    const saveParam = (skey: string, svalue: string,) => scormUnitSaveParam(unit.courseId, skey, svalue, unit.id, log.unitLogId)

    const { next = nextInProcess, prev = prevInProcess } = props

    useEffect(() => {
        !scormCMI && loadAllScormParams(unit.courseId, unit.id)
        !unitContent && loadFullUnit(unit.id)
    }, [sId, unit.id]);

    return <WithPreloader {...{ next, prev, ...props, saveParam, scormCMI }} />
}

export const ScormLoader = connect(
    (state: any, props: any) => ({
        unitContent: state.LMS.units[props.unit.id],
        log: state.LMS.unitsLogs[props.unit.id],
        scormCMI: state.LMS.unitsLogs[props.unit.id] ? state.LMS.unitsLogs[props.unit.id].scormCMI : null
    }),
    (dispatch: Dispatch<Action>, ownProps: any) => bindActionCreators({
        loadFullUnit,
        saveUnitResult,
        toPrevUnit,
        transitionToNextUnit,
        scormUnitSaveParam,
        loadAllScormParams
    }, dispatch)
)(Loader);