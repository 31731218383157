import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { Box, Typography } from 'muicomponents/src';
import Stack from '@mui/material/Stack';
import { IStructureProps, cnHierarchyWidgetNew } from '../Widget_type_hierarchyNew.index';
import { AddForm } from './AddForm';

export const Structure = ({ children, items, setItems, title, ...props }: IStructureProps) => {
    const { settings } = props;

    const hierarchyName = settings?.hierarchy.name;
    const authUser: any = useSelector(getAuthUser);

    const [newUser, setNewUser] = useState<any>();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', grid: '16px', gridGap: '16px' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                {authUser.baseData.isAdmin && hierarchyName ? (
                    <AddForm
                        {...props}
                        newUser={newUser}
                        setNewUser={setNewUser}
                        items={items}
                        setItems={setItems}
                        title={title}
                    />
                ) : (
                    <Typography fontWeight="700" className={cnHierarchyWidgetNew('Title')}>
                        {title}
                    </Typography>
                )}
            </Stack>
            {children}
        </Box>
    );
};
