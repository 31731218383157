import React from 'react'
import { Dialog, DialogTitle, DialogActions, Avatar, Button } from 'uielements/src'
import {cnCalendarModal, ICalendarModalUsers} from './CalendarModal.index'
import './CalendarModal.scss'
import { Translate } from 'localizations/Translate'

export const AllParticipants = (props: any) => {
  return (
    <Dialog onClose={props.onClose} isShown={props.isShown}>
      <DialogTitle onClose={props.onClose}>
        <Translate i18nKey={'pryaniky.calendar.modal.allParticipants'} />
      </DialogTitle>
      <div className={cnCalendarModal()}>
        {props.users.map((item: any, i: any) => {
          return (
            <div className={cnCalendarModal('Item')} key={i}>
              <Avatar imgUrl={item.imgUrl} name={item.title} className={''} size={40} />
              <p>{item.displayName}</p>
            </div>

          )
        })}
      </div>

      <DialogActions>
        <Button onClick={props.onClose} padding="lg">
          <Translate i18nKey={'pryaniky.calendar.modal.close'} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}