
import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import {SettingsCalendarMiniWidget } from '../CalendarMini.index';
import { IBodyProps } from 'utils/src/DialogCreator'
export const cnCalendarMiniSettingsDialog = cn('CalendarMiniSettingsDialog');

export namespace NCalendarMiniSettingsDialog {

    export interface Props extends IClassNameProps, IBodyProps<any> {
        data?: SettingsCalendarMiniWidget.Settings 
        onChange: (value: { [key: string]: any }) => void;
    }
}
