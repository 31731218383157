import React, { FC } from 'react'
import { IItemProps, cnItem } from './Item.index'
import { withRouter } from 'react-router-dom'
import { getCookie, setCookie } from 'utils/src/utils'
import { MenuTextCrop } from 'blocks/HeaderMui/UserMenu/UserMenu.styled'
import { Check, UserIsAdmin } from 'muicomponents/src/Icons'
import { Avatar } from 'muicomponents/src/Avatar'
import { ItemFlex, ItemIconsBox, ItemBox, ItemIcon } from './Item.styled'
import { Tooltip } from 'muicomponents/src/Tooltip'
import { Translate } from 'localizations/Translate'


const setCookieLocal = (uid: string, auth: string, cookieToken: string, cookieName: string, currentUser: any) => () => {
    const realUser = getCookie('prn_realuser')
    if (!realUser) {
        setCookie({ name: 'prn_realuser', value: currentUser.baseData.id });
    }
    const dev_company = getCookie('DEV_companyName')
    if (dev_company) {
        setCookie({ name: 'DEV_companyName', value: dev_company });
    }
    setCookie({ name: 'authUid', value: uid });
    setCookie({ name: 'authAt', value: auth });
    setCookie({ name: cookieName, value: cookieToken });
    window.location.assign(window.location.href)
}

export const ItemPresenter: FC<IItemProps> = ({ className, user, currentUser }) => {

    return (
        <ItemBox 
            className={cnItem({}, [className])} 
            onClick={setCookieLocal(user.user.id, user.access_token, user.cookieToken, user.cookieName, currentUser)}
            disabled={user.user.id === currentUser.baseData.id ? true : false }
        >
            <ItemFlex>
                <Avatar 
                    className={cnItem('Avatar')} 
                    src={user.user.userPhotoUrl!}
                />

                <div>
                    <div className={cnItem('Title')}>{user.user.displayName}</div>
                    <MenuTextCrop className={cnItem('Position')}>{user.user.position}</MenuTextCrop>
                    <MenuTextCrop className={cnItem('Division')}>{user.user.division}</MenuTextCrop>
                </div>
            </ItemFlex>

            <ItemIconsBox>
                {user.user.isAdmin && <Tooltip title={<Translate i18nKey='administrator'/>}><ItemIcon as={UserIsAdmin} /></Tooltip>}
                { user.user.id === currentUser.baseData.id && <ItemIcon as={Check} /> }
            </ItemIconsBox>
        </ItemBox>
    )
    
}

export const Item = withRouter(ItemPresenter)