import { bindActionCreators } from 'redux';
import { deleteAlbum } from "../../../redux/actions/Photoalbums";
import { AlbumType } from './Album.type'

export interface IAlbumProps extends IBaseAlbumProps, ReturnType<typeof mapDispatchToProps>  {

}
export interface IBaseAlbumProps {
    album: AlbumType
    aid: string;
    gid: number;
    showControls?: boolean;
}


export const mapStateToProps = (state: any, props: IBaseAlbumProps) => {
    return {}
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    deleteAlbum
}, dispatch)