import React, { FC, useEffect, useState } from 'react'
import { cnPort, mapDispatchToProps, mapStateToProps } from './Port.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Port.scss'
// import { Button } from 'uielements/src';
import queryString from 'query-string';
import { isArray } from 'util';

export const PortPresenter: FC<any> = ({ className, tabs, location }) => {
    const requestParams = queryString.parse(location.search)
    const current = isArray(requestParams.tab) ? 'default' : (requestParams.tab ? ((requestParams.tab in tabs) ? requestParams.tab : 'default') : 'default');
    return <div className={cnPort({}, [className])}>
        {
            tabs[current]
        }
    </div>
}

export const Port = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PortPresenter));
