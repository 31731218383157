import React, { FC, useRef, memo, useState, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { isObject } from 'lodash';
import { сhangeKPIItem, pathKPIItem, dropKPIItem } from '../redux/actions'
import { getItemParamsById } from '../redux/saga/selectors'

export const useChangeEdit = (id: string) => {
    const dispatch = useDispatch()
    const { meta = { isCreating: false, isEdit: false } } = useSelector(getItemParamsById(id, 'meta'))

    const setEdit = useCallback((isEdit: boolean) => {
        dispatch(pathKPIItem({
            id,
            meta: {
                isCreating: false,
                isEdit
            }
        }))
    }, [])

    return [
        meta.isEdit,
        setEdit
    ] as const
}

export function useChangeItem(id: string, userId: string) {
    const dispatch = useDispatch()
    const item = useSelector(getItemParamsById(id, 'isComplete', 'goal', 'name', 'dueDate', 'meta'), shallowEqual)

    const [state, setState] = useState({
        isComplete: item.isComplete.data,
        name: item.name.data,
        goal: item.goal.data,
        dueDate: item.dueDate.data
    })

    const ref = useRef(state)
    ref.current = state

    const onComplete = useCallback((accepted: boolean) => {
        const { meta = { isCreating: false, isEdit: false } } = item

        if (!accepted) {
            if (meta.isCreating) {
                dispatch(dropKPIItem({ id, userId }))
            } else {
                dispatch(pathKPIItem({
                    id,
                    meta: {
                        ...meta,
                        isEdit: false
                    }
                }))
            }
            return;
        }

        const keys: (keyof typeof ref.current)[] = Object.keys(ref.current) as any
        let fields = { ...item }

        keys.forEach(key => {
            const value = item[key]
            if (isObject(value)) {
                fields = {
                    ...fields,
                    [key]: {
                        ...value,
                        data: ref.current[key]
                    },
                    meta: {
                        ...meta,
                        isEdit: false
                    }
                }
            }
        })

        dispatch(сhangeKPIItem({
            fields,
            id,
            userId
        }))
    }, [item])



    return {
        onComplete,
        item,
        setState,
        state
    } as const
}