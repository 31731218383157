import React, { FC, useEffect, useState } from 'react'
import { cnStatus, mapDispatchToProps, mapStateToProps } from './Status.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Status.scss'
import { Selectize } from 'uielements/src';
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'


export const StatusPresenter: FC<any> = ({ className, setStudentsFilters, currentFilters }) => {
    const elements = [
        {
            value: 'all',
            title: tT('student_list.filters.status.all.1'),
            id: 'all'
        }, {
            value: 'absolutely',
            title: tT('student_list.filters.status.absolutely.1'),
            id: 'absolutely'
        }, {
            value: 'complete',
            title: tT('student_list.filters.status.complete.1'),
            id: 'complete'
        }, {
            value: 'inprogress',
            title: tT('student_list.filters.status.inprogress.1'),
            id: 'inprogress'
        }
    ];
    return <div className={cnStatus({}, [className])}>
        {/* Sessions filter */}
        <Selectize
            // key={i} 
            onClick={(value) => { setStudentsFilters({ 'filter.status': value.value }) }}
            active={currentFilters}
            // rLink={linkCreator('cat', zeroId)}
            name={tT('student_list.filters.status.1')}
            elements={elements}
            // multiple={true}
            children={[]} />
        {/* {sessionsIds.map((sid: string) => normalSessions[sid].name)} */}
    </div>
}

export const Status = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(StatusPresenter));
