/**
 * @packageDocumentation
 * @module List_type_badge_users
 */
import React, { FC } from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from '../../../../redux/connector';

import { mapStateToProps, IListStateProps, IListProps, mapActionsToProps } from './../../List.index';
import { IStateType as IState } from 'redux/store';
import { ItemsList } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem'
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody'
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { IBaseUser } from 'uielements/src/UsersList/UsersList.index'


const ListUsersBadgePresenter: FC<any> = ({ context }) => {
  // return <>reherere</>
  return <ItemsList<IBaseUser & { url: string }>
    BodyComponent={ItemsListBody}
    ItemComponent={UsersListItem}
    method={async function (skipCount, { count, search }) {
      const response = await utils.API.badges.usersList({ bid: context.bId, skipCount, count, search })
      const result = await response.r as any as { data: IBaseUser[] } & IBasicResponse
      return result.data.map((item) => ({ ...item, url: `/user/${item.id}` }))
    }}
  />
}

export const List = connect<IListStateProps, IDispatchProps, IListProps, IState>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
)(ListUsersBadgePresenter);
