import { createStore, IModuleStore, DynamicModuleLoader } from "redux-dynamic-modules";
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, StateFromReducersMapObject } from 'redux';
import { getSagaExtension, ISagaModule } from "redux-dynamic-modules-saga";
import { getThunkExtension } from "redux-dynamic-modules-thunk";
import { getBaseModule } from "./reduxBaseModule";
import { history } from './browserHistory';
import { getI18nModule } from 'utils/src/CommonRedux/i18n/reduxModule';

// используется для расширения типа стора динамичсекими модулями
type WithModuleStore<T extends () => ISagaModule<any>> = StateFromReducersMapObject<Required<ReturnType<T>>['reducerMap']>;

export const store = createStore(
  {
    initialState: {
      /** initial state */
    },
    enhancers: [
      // (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__,
      // sagaMiddleware
      // routerMiddleware(history), // for dispatching history actions
      // applyMiddleware(
      //   // _AIChallngeMiddleware,
      //   // eventMiddleware,
      //   // titleMiddleware,
      //   // , 
      //   sagaMiddleware,
      //   thunk
      //   // ... other middlewares ...
      // ),
      // compose(
      //   window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (data: any) => data
      // )
        /** enhancers to include */ 
    ], 
    extensions: [
      getSagaExtension(),
      getThunkExtension(),
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : (data: any) => data
      /** extensions to include i.e. getSagaExtension(), getThunkExtension() */
    ],
  },
  getBaseModule(),
  getI18nModule(),
);

export type IStateType = ReturnType<typeof store.getState>;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: any;
    storeBrowserHistory: typeof history;
    store: typeof store
  }
}
window.storeBrowserHistory = history;
window.store = store;