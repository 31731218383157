import React, { ComponentProps, FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useGlobalStoreForWidget, useWidget } from 'Widgets_v2/hooks';
import { NWidget } from 'Widgets_v2/Widget/Widget.index';
import { BoxLayout, BoxRowGrid } from './WidgetLayoutHorizontal.styled';
import {
  addWidget
} from 'redux/actions/Widgets';
import { Translate } from 'localizations/Translate';
import { cloneDeep, fromPairs, toPairs } from 'lodash';
import { confirmProise } from 'utils.project/utils.project';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { sizingByCount } from 'Widgets_v2/constants';
import { v1 as uuid } from 'uuid';
import { TWidget } from 'utils/src/BaseTypes/widgets.types';
import { generateColumn } from 'Widgets_v2/utils';
import { TSimplifiedWidgetByType } from 'Widgets_v2/types';
import { Row } from './Section';


export const WidgetLayoutHorizontal: FC<NWidget.Props> = ({
    id
}) => {

    const dispatch = useDispatch();

    const {
        edit
    } = useGlobalStoreForWidget();

    const {
        widget,
        changeWidget
    } = useWidget<TSimplifiedWidgetByType<'layout/horizontal'>>(id);
    
    const addNextColumn = (columnId: string) => () => {
        const clonedData = cloneDeep(widget.data || []);
        const newColumn = generateColumn({
            items: []
        });

        const indexToAdd = clonedData.findIndex(el => el.id === columnId);
        if(indexToAdd === -1) return ;
        clonedData.splice(indexToAdd + 1, 0, newColumn as any);
        
        changeWidget({
            data: clonedData,
            settings: {
                ...widget.settings,
                sections: {
                    ...widget.settings?.sections,
                    [newColumn.id]: []
                }
            }
        });
    };

    const removeColumn = (columnId: string) => () => {
        confirmProise({
            content: <Translate i18nKey='pryaniky.widget.line.remove.comfirm' />
        }).then((action) => {
            if(action === 'confirm') {
                const newColumns = widget.data?.filter(column => column.id !== columnId) || [];
                changeWidget({
                    data: newColumns,
                    settings: {
                        ...widget.settings,
                        sections: fromPairs(toPairs(widget.settings?.sections || {}).filter(([ id ]) => id !== columnId))
                    }
                });
            }
        })
    };

    /**
     * add widget to column
     * @param newWidget 
     */
    const addWidgetHandler = useCallback((columnId: string) => (newWidget: { [s: string]: any }, positionIndex: number) => {
        newWidget.colId = columnId;
        newWidget.relations = [ id, newWidget.id ];
        newWidget.append = true;
        dispatch(addWidget(newWidget));
        let newData = cloneDeep(widget.data || [])
        newData = newData.map(col => {
            if(col.id === columnId) {
                return {
                    ...col,
                    items: [ ...col.items, newWidget.id ]
                };
            }
            return col;
        });
        const newSections = fromPairs(toPairs(widget.settings.sections).map(([ id, positions ]) => {
            if(id === columnId) {
                if(positionIndex === positions.length) {
                    const newPositions = [
                        ...positions,
                        {
                            id: uuid(),
                            widgetId: newWidget.id,
                            size: 3
                        }
                    ].map((el, _, arr) => {
                        return {
                            ...el,
                            size: sizingByCount[arr.length]
                        }
                    });
                    return [ id, newPositions ];
                }
                return [ id, positions.map((position, idx) => {
                    if(idx === positionIndex) {
                        return {
                            ...position,
                            widgetId: newWidget.id
                        }
                    }
                    return {
                        ...position
                    }
                }) ];
            }
            return [ id, positions ];
        })) as typeof widget.settings.sections;
        changeWidget({
            data: newData,
            settings: {
                ...widget.settings,
                sections: newSections
            }
        });
    }, [id, widget]);

    const changeSection: ComponentProps<typeof Row>['changeSection'] = (columnId, section) => {
        changeWidget({
            settings: {
                ...widget.settings,
                sections: {
                    ...widget.settings.sections,
                    [columnId]: section
                }
            }
        });
    };

    const changeSectionBaseHeight: ComponentProps<typeof Row>['changeSectionBaseHeight'] = (columnId, newHeight) => {
        changeWidget({
            settings: {
                ...widget.settings,
                sectionsBaseHeight: {
                    ...widget.settings.sectionsBaseHeight,
                    [columnId]: newHeight
                }
            }
        });
    };

    const onDraged = (columnId: string) => (items: string[]) => {
        changeWidget({
            settings: {
                ...widget.settings,
                sections: {
                    ...widget.settings.sections,
                    [columnId]: items.map(posId => {
                        const sections = widget.settings.sections || {};
                        return sections[columnId]?.find(el => el.id === posId);
                    }).filter(el => el) as typeof widget.settings.sections[keyof typeof widget.settings.sections]
                }
            }
        });
    };

    /**
     * this did update watch on change widgets in columns and sync them with sections positions
     */
    useDidUpdateEffect(() => {
        const widgetWidgets = widget.data?.reduce((a, c) => {
            const columnWidgets = c.items.map(el => {
                return typeof el === 'object' ? (el as any).id : el
            });
            return [ ...a, ...columnWidgets ];
        }, [] as TWidget['id'][]) || [];
        let changed = false;
        const newSections = fromPairs(toPairs(widget.settings.sections).map(([ id, positions ]) => {
            return [ id, positions.map(position => {
                const widgetId = !widgetWidgets.includes(position.widgetId || '') ? null : position.widgetId;
                if(position.widgetId !== widgetId) changed = true;
                return {
                    ...position,
                    widgetId
                };
            }) ]
        })) as typeof widget.settings.sections;
        if(changed) {
            changeWidget({
                settings: {
                    ...widget.settings,
                    sections: newSections
                }
            });
        }
    }, [widget.data]);

    return (
        <BoxLayout>
            {
                widget.data?.map((column, idx, arr) => {
                    return (
                        <BoxRowGrid>
                            <Row
                                key={column.id}
                                row={column}
                                widgetId={id}
                                addNextColumn={addNextColumn(column.id)}
                                removeColumn={removeColumn(column.id)}
                                addWidgetHandler={addWidgetHandler(column.id)}
                                section={widget.settings?.sections[column.id]}
                                sectionBaseHeight={widget.settings?.sectionsBaseHeight?.[column.id]}
                                changeSection={changeSection}
                                changeSectionBaseHeight={changeSectionBaseHeight}
                                onDraged={onDraged(column.id)}
                                isLastSection={idx === arr.length - 1}
                            />
                        </BoxRowGrid>
                    )
                })
            }         
        </BoxLayout>
    )
}