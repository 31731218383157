import { zeroId } from 'LMSModule/utils/utils.lms'

export const wikiModelCreator = (id = zeroId) => (
    {
        markdownText: null,
        newsUid: "00000000-0000-0000-0000-000000000000",
        data: { 
            blocks: [], 
            entityMap: {} 
        },
        context: null,
        id: id,
        authorId: "00000000-0000-0000-0000-000000000000",
        type: "pryaniky/wiki",
        title: "",
        description: "",
        iconUrl: null,
        isEditable: true,
        allowChangeSettings: false,
        isHidden: false,
        errorLoading: false,
        errorLoadWidgetText: null,
        files: null,
        settings: null,
        relations: [id]
    }
)