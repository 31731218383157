import React, { ReactText } from 'react';
import { Translate } from '../../../localizations/Translate';
import * as utilsProj from 'utils.project/utils.project';
import i18n from '../../../localizations/i18n';
import { toast } from 'react-toastify';
import { store } from 'redux/store';
import { modalChangeDate } from 'redux/actions/Modals';
import {
    IWikiItemProps
} from './ContextMenu.index';
import * as utils from 'utils/src/utils';
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import ContextMenuComponent from 'uielements/src/ContextMenu/ContextMenu'


export class ContextMenuPresenter extends React.Component<IWikiItemProps>{
    constructor(props: any) {
        super(props);
    }

    private clickAction = (action: string) => {
        switch (action) {
            case 'edit':
                this.props.onEdit()
                break;
            case 'delete':
                this.deleteWikiPage()
                break;
            case 'archive':
                this.archivedPage()
                break;

            default:
                break;
        }
    }

    private deleteWikiPage = () => {
        const { data } = this.props;
        utilsProj.confirm({
            text: i18n.t("pryaniky.modal.wikipage.confirm.delete"),
            onConfirm: () =>

                utils.API.pages.deletePage(data.id)
                    .r
                    .then((response) => {
                        if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                        else if (response.error_code === 0) {
                            toast.success(i18n.t("pryaniky.modal.wikipage.deleted"));
                        }
                    })
        });

    }
    private sendDataForEditPage = (isArchived?: boolean) => {
        const { data } = this.props;
        utilsProj.confirm({
            text: data.isArchived ? i18n.t("pryaniky.modal.wikipage.confirm.unarchieve.edit") : i18n.t("pryaniky.modal.wikipage.confirm.archieve.edit"),

            onConfirm: () => {

                return utils.API.pages.getById(data.id)
                    .r
                    .then((response) => {
                        if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                        else if (response.error_code === 0) {

                            utils.API.pages.setSettings({
                                ...response.data,
                                type: data.type === "" ? "page" : data.type,
                                isArchived: isArchived !== undefined ? isArchived : data.isArchived,

                            })
                                .r
                                .then((response) => {

                                    if (!response) {
                                        toast.error(i18n.t('pryaniky.toast.error.server'));
                                    }
                                    else if (response.error_code === 0) {
                                        toast.success(i18n.t("edited.success"));
                                        // if (onConfirm) onConfirm(pdata);
                                        store.dispatch(modalChangeDate({ id: data.id || '', data: { ...data, isArchived: isArchived !== undefined ? isArchived : data.isArchived } }))
                                    }
                                });
                        }
                        else if (response.error_text) toast.error(response.error_text);

                    })
            },

        });

    }
    private archivedPage = () => {
        const { data } = this.props;
        this.sendDataForEditPage(!data.isArchived)
    }

    public render() {
        const { injectionNodes, injectionComponents, onEdit, btnRender, ...props } = this.props;

        const contextActions = [
            ...injectionNodes.map((node) => ({ content: node })),
            ...injectionComponents.map((Component) => ({ content: <Component {...props} /> })),
            {
                content: <Translate i18nKey={'pryaniky.wikilist.item.еdit'} />,
                action: 'edit'
            },
            {
                content: <Translate i18nKey={"pryaniky.modal.wikipage.delete"} />,
                action: 'delete'
            },
            {
                content: <Translate i18nKey={`pryaniky.modal.wikipage.${this.props.data.isArchived ? 'unarchive' : 'archive'}`} />,
                action: 'archive'
            },
        ]

        const contextActionsForModerator = [
            {
                content: <Translate i18nKey={'pryaniky.wikilist.item.еdit'} />,
                action: 'edit'
            },

        ]
        return <ContextMenuComponent
            btnRender={btnRender}
            onClick={this.clickAction}
            actions={props.isAdmin ? contextActions: contextActionsForModerator}
        />
    }



}


export const ContextMenu = withComponentEnjector(ContextMenuPresenter, ['wiki_list_item'])



