// import { User } from '../BaseType'
import { ReplyType } from './Reply.type'
import { Rule, validateString, validatePostText } from '../Base.validate'


export const baseValidate: Rule[] = [
    {
        type: 'string',
        method: 'STR_MIN',
        field: 'text',
        value: 1,
        message: 'small text'
    },
    {
        type: 'string',
        method: 'STR_MAX',
        field: 'text',
        value: 250000,
        message: 'big text'
    },
    // {
    //     type: 'string',
    //     method: 'STR_MIN',
    //     field: 'header',
    //     value: 0,
    //     message: 'small header'
    // },
    // {
    //     type: 'string',
    //     method: 'STR_MAX',
    //     field: 'header',
    //     value: 50,
    //     message: 'big header'
    // }
]

export class BaseValidator<T extends ReplyType> {
    private rules: Rule[] = [];
    // private fields: string[] = [];
    // private validators: Validator[] = [];
    private data: T;

    public constructor() {

    }

    /*public validate = () => {

    }

    public addValidator = () => {

    }

    public addField = () => {

    }*/

    public addData = (data: T) => {
        this.data = data
    }

    public setRules = (rules: Rule[]) => {
        this.rules = rules
    }

    public validation = () => {
        let invalide: Rule[] = []
        for (const rule of this.rules) {
            switch (rule.field) {
                case 'text':
                    !this.validateText(this.data, rule) && invalide.push(rule);
                    break;
                case 'header':
                    !this.validateHeader(this.data, rule) && invalide.push(rule);
                    break;
                default:
                    !this.otherValidate(this.data, rule) && invalide.push(rule);
            }
        }
        const major = invalide.find(v => v.major)
        if (major) invalide = [major]
        return window.SERVICE__DISABLE_VALIDATE ? [] : invalide;
    }

    private validateText = (data: ReplyType, rule: Rule) => {
        return validatePostText(data.text, rule)
    }

    private validateHeader = (data: ReplyType, rule: Rule) => {
        return validateString(data.header, rule)
    }


    protected otherValidate = (data: T, rule: Rule) => {
        return false
    }
}
