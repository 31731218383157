import React, { FC } from 'react'
import { ITestListProps, cnTestList } from './TestList.index'
import './TestList.scss'
import { Item } from './Item/Item'

export const TestListPresenter: FC<ITestListProps> = ({ className, units, unitsResult }) => {

    return <ul className={cnTestList({}, [className])}>
        {units.map((unit: any) =>
            <Item
                className={cnTestList('Item')}
                unit={unit}
                unitResult={unitsResult[unit.id]} />
        )}
    </ul>
}


export const TestList = TestListPresenter;