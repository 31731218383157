import { IDispatchProps } from "../../../redux/connector";
import { IDialog } from "../DialogWrapper.index";
import { getAuthUser } from "utils/src/CommonRedux/base/actions";
import { IBodyProps } from 'utils/src/DialogCreator';

export type IHandGoalDialogStateProps = ReturnType<typeof mapStateToProps>;

export interface IHandGoalDialogProps extends IHandGoalDialogOwnProps, IDispatchProps, IHandGoalDialogStateProps {
   
}

export interface IHandGoalDialogOwnProps extends IBodyProps {
    attachments?: any[];
    onChangeFile?: (files: any[], validate: boolean) => void;
    data?: any;
    goal: any;
    comment: string,
    text?: any,
    description: string

}

export interface IHandGoalDialogState {
    
   
}

export const mapStateToProps = (state: any, props: IHandGoalDialogOwnProps) => {
    return {
        myThanksCount: getAuthUser(state).extData.mythanksCount || 0,
        currencyFormats: state.store.appSettings
    };
};