import { IStateType as IState } from 'redux/store';
import { cn } from '@bem-react/classname';

export interface ILayoutLoadingProps extends ImapLayoutLoadingStateToProps {}

export const mapLayoutLoadingStateToProps = (state: IState) => ({
  status: state.loading.status,
});

export type ImapLayoutLoadingStateToProps = ReturnType<typeof mapLayoutLoadingStateToProps>;

export const cnLayoutLoading = cn('LayoutLoading');