import * as React from 'react';
import { IEditorTypeFeedbackProps } from './news.index';
import { NewsFeedback,defaultNewsFeedback } from '../../../Feedback.type'
import { BaseValidator, baseValidate } from '../../../../Base.validate'
import { Editor } from '../../Editor'
import * as utils from 'utils/src/utils';
import { EditorState } from 'draft-js';

export const EditorTypeNews: React.FC<IEditorTypeFeedbackProps> = ({ onChange, data, types, }) => {
    let localData = data as NewsFeedback;
    if (data.newstype !== 10) {
        localData = { ...utils.cloneObject(defaultNewsFeedback), text: EditorState.createEmpty()};
    } // else localData.uevent.startDateTime = new Date(localData.uevent.startDateTime);
    const onChangeText = (text: string) => {
        localData.text = text;
        const bv = new BaseValidator<NewsFeedback>()
        bv.setRules(baseValidate)
        bv.addData(localData)
        onChange(localData, bv.validation().length === 0);
    }
    return <Editor
        onChangeText={onChangeText}
        data={localData} />
}