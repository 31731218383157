/**
 * @packageDocumentation
 * @module Rating_mini
 */
import React from 'react';
import { IRatingMiniProps, IRatingMiniState, cnRatingMini } from './RatingMini.index';
import { Avatar, Button } from "uielements/src";
import { Item } from "uielements/src/RatingItem/Rating-Item";
import { UserMention } from 'uielements/src/UserMention/UserMention';
import * as utils from "utils/src/utils";
import './RatingMini.scss';
import i18n from 'localizations/i18n';
import { RenderSmall } from 'uielements/src'
import { CacheHelper } from 'utils/src/CacheHelper'
import { ProductSkeleton } from 'blocks/Skeletons/Shop/Product-Skeleton'
import { RenderSmallMui } from 'muicomponents/src/RatingMiniRenderSmallItem/RatingMiniRenderSmallItem'
import { LeaderboardListItem } from 'muicomponents/src/LeaderboardListItem/LeaderboardListItem'
import { CustomSettings } from 'utils/src';
import { PreviewParams } from 'utils/src/PreviewParams';
import { Link } from 'muicomponents/src/Link/Link'
import { generatePath } from 'react-router';
import urls from 'routes/urls';


class RatingMiniPresenter extends React.Component<IRatingMiniProps, IRatingMiniState> {
  public headerEl: HTMLDivElement | null = null;

  public state: IRatingMiniState = {
    data: [],
    isSmall: true,
  }

  constructor(props: IRatingMiniProps) {
    super(props);
    // this.headerEl = React.createRef<HTMLDivElement>();
  }

  /**
   * componentDidMount
   */
  public componentDidMount() {
    if (this.props.settings.rId) this.getData();
    window.addEventListener('resize', this.checkWidth);
  }

  /**
   * checkWidth
   */
  public checkWidth = () => this.headerEl && this.setState({ isSmall: this.headerEl.clientWidth < 350 });

  /**
   * componentDidUpdate
   */
  public componentDidUpdate(pp: IRatingMiniProps) {
    if (!utils.compareData(pp.settings, this.props.settings)) this.getData();
  }

  /**
   * name
   */
  public componentWillUnmount() {
    window.removeEventListener('resize', this.checkWidth);
  }

  /**
   * getData - get data to view, use rId props
   */
  public getData() {
    const cacheKey = `ratings.widget.leaderboard_${this.props.settings.rId}_${this.props.settings.count}`;
    let ignoreCache = false;
    CacheHelper.get('ratingsLeaderboard', cacheKey)
      .then((value) => value && !ignoreCache && this.setState({
        rating: value.data.rating,
        data: value.data.users
      }, () => {
        // this.checkWidth();
      }))
      .catch(e => console.warn('CacheHelper, userRating:', e))

    utils.API.ratings.leaderboard({ rid: this.props.settings.rId, count: this.props.settings.count })
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          ignoreCache = true;
          CacheHelper.set('ratingsLeaderboard', cacheKey, utils.clone(d))
            .then((value) => console.log(value))
            .catch(e => console.warn('CacheHelper, userRating:', e))

          this.setState({
            rating: d.data.rating,
            data: d.data.users
          }, () => {
            this.checkWidth();
          })
        }
      })
  }

  /**
   * render
   */
  public render() {
    const { tag: Tag = 'div', settings, className } = this.props;
    const { data, rating, isSmall } = this.state;
    const edit = (this.props as any).edit;
    if (!rating && !edit) return null;
    return (
      <Tag {...this.props} className={cnRatingMini({}, [className])}>
        {
          // !rating ? 
          // settings.rId :
          <>
            {
              <div className={cnRatingMini('Header', { isSmall })} ref={(e: any) => { this.headerEl = e }}>
                {
                  rating &&
                  <>
                    <div className={cnRatingMini('Header-Title')}>
                      {/* <Avatar shape={'circle'} className={cnRatingMini('Avatar')} imgId={rating.imgId} imgUrl={rating.imgUrl} name={rating.name} size={40} /> */}
                      <Link
                        sx={{ textDecoration: "none" }}
                        to={`/rating/${rating?.id}`}>
                        <span>
                          {rating.name}
                        </span>
                      </Link>
                    </div>
                  </>
                }
              </div>
            }
            {
              rating &&
              <div className={cnRatingMini('Items', { type: settings.type || 'stroke' })}>

                {
                  (!settings.type || settings.type === 'stroke') &&
                  data.map((el, idx) => {
                    el.idx = idx;
                    const percent: number = parseInt(el.percantage, 10);
                    return (
                      this.props.settings.miniview || isSmall ? <RenderSmallMui size={32}
                        id={el.id}
                        imgId={el.imgId}
                        imgUrl={el.imgUrl}
                        percent={percent}
                        value={el.value}
                        name={el.displayName}
                        ratingType={rating.ratingType}
                        color={rating.color || ''}
                        markerImgUrl={rating?.markerImgUrl}
                      /> : <LeaderboardListItem className={cnRatingMini('Item')}
                        data={el} color={rating.color || ''}
                        maxValue={rating.maxValue}
                        rating={rating} />)
                  })
                }

                {
                  settings.type === 'rounded' &&
                  data.map((el, idx) => {
                    el.idx = idx;
                    return <div className={cnRatingMini('Item', { rounded: true })} style={{ color: rating.color }}>
                      <UserMention id={el.id} name={el.displayName || el.name}
                        avatar={{ size: 56, imgId: el.imgId, name: el.displayName || el.name, imgUrl: el.userPhotoUrl || el.imgUrl }}
                      // className={cnRatingMini('Avatar')} 
                      />
                      {el.curValue}
                    </div>
                  })
                }
              </div>
            }
            <Link className={cnRatingMini('All CustomStyle primaryColor1-text')} sx={{ textDecoration: "none", marginTop:"16px" }} to={generatePath(urls.rating[1], { id: settings.rId })} children={i18n.t('pryaniky.widgets.ratingMini.allParticipants')} />
          </>
        }
      </Tag>
    )
  }
}

export const RatingMini = RatingMiniPresenter