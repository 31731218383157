import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export const cnMindMap = cn('MindMap');

export interface IMindeMapProps extends IClassNameProps, IMindMapOwnProps, IMindMapStateProps, IMindMapDispatchProps{
    data?: any;
    saveData: () => void;
    onChange(d: any): void;
    // updateWidget: (d: any) => void;
    // saveWidgetData(): void;
    widget: any;


}
export interface IMindMapOwnProps {
    tag?: React.ComponentType | 'div';
    contexts: any;
    edit: boolean;
}

export interface IMindMapData {
    topic: string,
    id: string,
    style: IMindeMApStyle,
    parent: null,
    tags: string[],
    icons: string[],
    hyperLink: string,
}

export interface IMindeMApStyle {
    fontSize: string,
    color: string,
    background: string
}


export const mapStateToProps = (state: IState) => {

    const data = state.survey.survey.openSurveyData
    
    return {
        isAdmin: getAuthUser(state).baseData.isAdmin
    }
  }
  
  export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
   
  }, dispatch);
  
  export type IMindMapStateProps = ReturnType<typeof mapStateToProps>
  export type IMindMapDispatchProps = ReturnType<typeof mapDispatchToProps>