import { IAchievementsPropsType, IAchievementsStateType, mapStateToProps, TStateProps } from './Achievements.index'
import React, { memo, FC } from 'react';
import { connect } from 'react-redux';
import './Achievements.scss'
import { withNewsContext } from 'News/contexts/news'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import ContentLayout from 'News/components/ContentLayout'
import TextRender from 'News/containers/TextRender'

export const AchievementsPresenter: FC<IAchievementsPropsType & TStateProps> = ({ isSingle, text }) => <NewsView className={'type-19'}>
    <ContentLayout>
        <TextRender />
    </ContentLayout>
</NewsView>

export const Achievements = withNewsContext(connect(
    mapStateToProps
)(memo(AchievementsPresenter)));

export const initAchievements = () => {
    ComponentInjector.getInstance().addNode('news_types', <Achievements />, 'achievements');
}