import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import './style.scss'
import { withNewsContext } from '../../contexts/news'

import { Attachments } from 'uielements/src/Attachments/Attachments';
import { Preview } from 'uielements/src/Preview/Preview';
import * as utils from 'utils/src/utils';
import { LinkLayout } from 'blocks/NewsTypes/common/LinkLayout/LinkLayout';

import NewsHeader from '../NewsHeader'
import NewsComments from '../NewsComments'
import TimelineInfo from '../../components/TimelineInfo'
import { INewsTimelineInfo } from 'News/types/baseNews';


type IPresenterProps = News.TimelineView.IPresenterProps
type IOwnProps = News.TimelineView.IOwnProps
type IDispatchProps = News.TimelineView.IDispatchProps
type IStateProps = News.TimelineView.IStateProps
type IState = News.TimelineView.IState
type IParams = News.TimelineView.IParams

const noopFn = () => { }
const noopArray: any[] = []

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    edit,
    toggleEdit,
    attachments,
    id,
    linkPreviewImgUrl,
    systemNewsActionHtml,
    newstype,
    additional
}) => {

    const { id: userId } = useParams<IParams>();

    const cancleEdit = useCallback(() => toggleEdit(false), [])

    return <div className={cnClassName({ timelineInfo: Boolean(additional)}, [className])}>
        {additional && <TimelineInfo userId={userId}  timelineInfo={additional as INewsTimelineInfo} />}
        <div className={cnClassName('Layout', ['NewsLayout'])}>
            <NewsHeader />

            <div className={cnClassName('Content')}>
                {children}
            </div>

            <LinkLayout linkPreviewImgUrl={linkPreviewImgUrl} systemNewsActionHtml={systemNewsActionHtml} />

            <Preview className={cnClassName('Preview', ['horizontalPadding'])} newsid={id} files={attachments ? attachments : noopArray} />

            <Attachments className={cnClassName('Attachments', ['horizontalPadding'])} files={attachments ? attachments : noopArray} onDelete={noopFn} state='view' />

        </div>
        <NewsComments />
    </div>
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));
