import { useDispatch, useSelector } from "react-redux";
import { IStateType } from "redux/store";
import { getCurrentUserIsAdmin } from "utils/src/CommonRedux/base/selectors";
import { PageVersion } from "./PageHistory.types";
import { addToAdditionalMenu, removeFromAdditionalMenu } from 'utils/src/CommonRedux/base/actions';
import React, { useCallback, useState } from "react";
import { PublishedWithChanges } from 'muicomponents/src/Icons';

export const usePageHistory = (id: string) => {

    const dispatch = useDispatch();

    const [ showList, setShowList ] = useState(false);

    const isAdmin = useSelector(getCurrentUserIsAdmin);

    const showButton = () => {
        isAdmin && dispatch(addToAdditionalMenu({
            item: {
                id: 'showVersions',
                onClick: () => setShowList(true),
                children: <PublishedWithChanges fontSize="small" sx={{ marginTop: '7px' }} />
            },
            position: 3
        }));
    };

    const hideButton = () => {
        dispatch(removeFromAdditionalMenu({ id: 'showVersions' }));
    };

    const pageData = useSelector((state: IStateType) => state.widgets.pages[id]);

    const pageUrl = pageData.url;

    const versions = pageData.versions as PageVersion[] | null;

    return {
        showButton,
        hideButton,
        pageUrl,
        versions,
        showList,
        setShowList
    };
};