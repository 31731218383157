import * as React from 'react';
import { IEditorTypeEditProps } from './edit.index';
import { NewsFeedback } from '../../../Feedback.type'
import { Editor } from '../../Editor'
import { BaseValidator, baseValidate } from '../../../../Base.validate'
export const EditorTypeEdit: React.FC<IEditorTypeEditProps> = ({data, onChange}) => {
    let localData = {...data};

    const onChangeText = (text: string) => {
        localData.text = text;
        const bv = new BaseValidator<NewsFeedback>()
        bv.setRules(baseValidate)
        bv.addData(localData)
        onChange(localData, bv.validation().length === 0);
    }

    return <Editor data={localData} onChangeText={onChangeText} />

}