import React, { FC, useState, useRef, useEffect } from 'react'
import { ISelectProps, cnSelect } from './Select.index'
import { Saggester } from '../Saggester/Saggester'
import './Select.scss'
import { InputLayout } from '../InputLayout/InputLayout'
import { renderFilter, renderChip, renderItem } from '../renderers'
import { filterRemoveValues } from '../filters'

export const toggleItem = (array: any[], item: any) => {
    return !array.find((value: any) => value === item) ? [...array, item] : array.filter((value: any) => value !== item)
}



export const Select: FC<ISelectProps> = (
    {
        multi = true,
        children,
        onChange,
        onChangeFilter = () => { },
        openToggler = () => null,//renderToggler,
        filterFunc = (variants: any[]) => variants,//filterSearch,
        filter = renderFilter,
        disasbleFilter = false,
        filterValue = '',
        variants,
        htmlId,
        className,
        excludeVariants = [],
        excludeFilter = filterRemoveValues,
        value,
        icon,
        items = renderItem,
        itemChip = renderChip,
        placeholder,
        maxSelectCount = -1,
        closeOnSelect = true,
        disabledVariants,
        ...props
    }) => {
    const [open, setOpen] = useState(false);
    const [focused, setFocused] = useState(false);
    const [focusIndex, setFocusIndex] = useState(0);
    const inputEl = useRef<HTMLInputElement>(null);
    const selfRef = useRef<HTMLDivElement>(null);


    useEffect(() => {

        document.addEventListener('click', handleClickOutside, false);
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
        }
    }, []);

    const onToggleItem = (item: any) => {
        const newValue = multi ? toggleItem(value, item) : [item]
        onChange(newValue)
        onChangeFilter('')
        focusInput();
        if (closeOnSelect) setOpen(false)
    }

    const removeItem = (item: any) => {
        const newValue = value.filter((v: any) => JSON.stringify(v) !== JSON.stringify(item))//excludeFilter(value, [item])
        onChange(newValue)
        onChangeFilter('')
        focusInput();
        setOpen(false)
    }

    const filterChange = (value: string) => {
        onChangeFilter(value)
        setOpen(true)
    }

    const focusInput = () => {
        inputEl.current && inputEl.current.focus();
        onChangeFilter('')
        setOpen(true)
    }

    const filteredValues = excludeFilter(filterFunc(variants, filterValue), [...value, ...excludeVariants])

    const showPlaceholder = (filterValue === '') && (!focused) && (value.length === 0)

    const allowSelect = (maxSelectCount === -1) || (value.length < maxSelectCount)

    const handleKeyDown = (e: any) => {
        switch (e.keyCode) {
            // case 39: // ArrowRight
            case 38: // ArrowUp
                // this.nextStep();
                setFocusIndex(focusIndex > 0 ? focusIndex - 1 : filteredValues.length - 1)
                e.preventDefault();
                // e.stopPropagation();
                break;
            // case 37: // ArrowLeft
            case 40: // ArrowDown
                // this.prevStep();
                setFocusIndex(focusIndex < filteredValues.length - 1 ? focusIndex + 1 : 0)
                e.preventDefault();
                e.stopPropagation();
                break;
            case 13: // enter
                onToggleItem(filteredValues[focusIndex])
                // e.preventDefault();
                break;
            default:
                return false;
        }
        return false;
    };

    const handleKeyOpen = (e: any) => {
        if (e.keyCode === 40) {
            setOpen(true)
        }
    }

    if (open) {
        document.addEventListener('keydown', handleKeyDown, { once: true })
    } else {
        (focusIndex !== 0) && setFocusIndex(0)
        focused && document.addEventListener('keydown', handleKeyOpen, { once: true })
        // document.removeEventListener('keydown', handleKeyDown);
    }
    const handleClickOutside = (event: any) => {
        if (selfRef.current && !selfRef.current.contains(event.target)) {
            ((event.target.clientWidth !== 0)/* && event.target !== allowClickElement.current*/) && setFocused(false)

        }
        // else clickInside(event.target)
    }
    return (
        <div ref={selfRef} id={htmlId} className={cnSelect({}, [className])}>
            <InputLayout
                action={openToggler(open, setOpen)}
                focus={focused}
                suggester={(open && allowSelect) && <Saggester clickOutside={() => setOpen(false)} onSelect={onToggleItem} className={cnSelect('Suggester')}>
                    {(onClick: (id: any) => () => void) => items(filteredValues, onClick, focusIndex, value, disabledVariants)}
                </Saggester>}
                icon={icon}
            >
                <div className={cnSelect('WorkElements')} onClick={() => { setOpen(!open); focusInput() }}>
                    {showPlaceholder && <div className={cnSelect('Placeholder')}>{placeholder}</div>}

                    {value.map((val: any, i: number) => itemChip(val, (e: any) => { e.stopPropagation(); removeItem(val) }, i))}

                    {(allowSelect && !disasbleFilter) && <div className={cnSelect('InputBox')}>
                        {filter(inputEl, filterValue, filterChange, () => { setFocused(true) }, () => { /*setFocused(false)*/ })}
                    </div>}
                </div>
            </InputLayout>
        </div>
    )
}