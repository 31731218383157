import * as React from 'react';
import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';

import { withBemMod } from '@bem-react/core';

import { cnBody } from '../Modal-Body.index';
import { IBodyTypeSelectWidgetProps, IBodyTypeSelectWidgetState } from './Modal-Body_type_selectWidget.index';
import './Modal-Body_type_selectWidget.scss';
import { cn } from '@bem-react/classname';
import { allWidgets } from 'widgetsUtils';
// import { newWidgets } from 'i.widgets';

const cnWidgetSelector = cn('WidgetSelector');

class NewBody extends React.Component<IBodyTypeSelectWidgetProps, IBodyTypeSelectWidgetState> {
  public utils: any = utils;
  public el: HTMLElement | null;

  constructor(props: IBodyTypeSelectWidgetProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {
    };
  }

  // public componentDidMount() {}

  public render() {
    const customClassNames = '';
    const TagName = this.props.tag ? this.props.tag : 'div';
    const { children } = this.props;
    return (
      <TagName ref={el => this.el = el} className={cnBody({}, (this.props.className ? this.props.className.split(' ') : []).concat(customClassNames.split(' ')))}>
        <div className={cnWidgetSelector()}>
          {
            allWidgets.map((widget: {[s: string]: any}, i: number) => {
              return <div key={i}>
                {widget.title}
              </div>
            })
          }
        {i18n.t('hi')}
        {children}
        </div>
      </TagName>
    )
  }

  // private functionBind() {}

}


export const withBodyTypeSelectWidget = withBemMod<any>(
  cnBody(),
  { type: 'selectWidget' }, 
  (Presenter) => (
    (props: IBodyTypeSelectWidgetProps) => (
      <NewBody {...props}/>
    )
  )
);