import {
    ISecretSantaWidgetActions,
    IIsUserParticipatePayload,
    ISecretSantaJoinPayload,
    IGetUserToGivePresentPayload,
    IIsPresentSentPayload,
    IGetSecretSantaParticipantsPageListPayload,
    IGetMyInfoPayload,
    IGetSecretSantaListFromServerPayload,
    ISetSecretSantaWidgetSettingsPayload,
    ISetUserParticipatePayload,
    ISetIsPresentPayload,
    ISecretSantaJoinInfoPayload,
    ISetMyInfoPayload,
    ISetUserToGivePresentPayload,
    ISetSSParticipantsPageListToReduxPayload,
    ISSParticipantsPageListTogglePayload,
    ISetUserDistributedTogglePayload,
    ISetIsInfoLoadingPayload

} from './actions.interfaces';

import {
    ISecretSantaListData,
    ISecretSantaDescriptionGift,
    ISecretSantaParticipantsList,
    IUserWhowIGavePresent
} from 'utils/src/requests/models/admin.secretSanta';

export const GET_SECRET_SANTA_WIDGET_SETTINGS = 'GET_SECRET_SANTA_WIDGET_SETTINGS';
export const SET_SECRET_SANTA_WIDGET_SETTINGS = 'SET_SECRET_SANTA_WIDGET_SETTINGS';
export const MAIN_SECRET_SANTA_SETTINGS = 'MAIN_SECRET_SANTA_SETTINGS'
export const IS_USER_SECRET_SANTA_PARTICIPATE = 'IS_USER_SECRET_SANTA_PARTICIPATE';
export const SET_USER_SECRET_SANTA_PARTICIPATE = 'SET_USER_SECRET_SANTA_PARTICIPATE';
export const SECRET_SANTA_JOIN = 'SECRET_SANTA_JOIN';
export const SECRET_SANTA_UN_JOIN = 'SECRET_SANTA_UN_JOIN';
export const SECRET_SANTA_CHANGE_JOIN_INFO = 'SECRET_SANTA_CHANGE_JOIN_INFO';
export const GET_USER_TO_GIVE_PRESENT = 'GET_USER_TO_GIVE_PRESENT';
export const SET_USER_TO_GIVE_PRESENT = 'SET_USER_TO_GIVE_PRESENT';
export const SET_USER_DISTRIBUTED_TOGGLE = 'SET_USER_DISTRIBUTED_TOGGLE';
export const SET_NO_RIGHTS = 'SET_NO_RIGHTS';
export const IS_INFO_LOADING = 'IS_INFO_LOADING';


export const GET_MY_INFO = 'GET_MY_INFO';
export const SET_MY_INFO = 'SET_MY_INFO';
export const PRESENT_SENT = 'PRESENT_SENT';
export const IS_PRESENT_SENT = 'IS_PRESENT_SENT';
export const SET_IS_PRESENT_SENT = 'SET_IS_PRESENT_SENT';

export const GET_SECRET_SANTA_PARTICIPANTS_PAGE_LIST = 'GET_SECRET_SANTA_PARTICIPANTS_PAGE_LIST';
export const SET_SECRET_SANTA_PARTICIPANTS_PAGE_LIST = 'SET_SECRET_SANTA_PARTICIPANTS_PAGE_LIST';
export const SECRET_SANTA_PARTICIPANTS_PAGE_LIST_TOGGLE = 'SECRET_SANTA_PARTICIPANTS_PAGE_LIST_TOGGLE';

export const getSecretSantaParticipantsList = (payload: IGetSecretSantaParticipantsPageListPayload): ISecretSantaWidgetActions => ({
    type: GET_SECRET_SANTA_PARTICIPANTS_PAGE_LIST,
    payload
})

/**
* set SEcret Santa Participants to redux
* @param { ISecretSantaParticipantsList } payload 
*/
export function setSSParticipantsListToRedux(payload: ISetSSParticipantsPageListToReduxPayload): ISecretSantaWidgetActions {
    return {
        type: SET_SECRET_SANTA_PARTICIPANTS_PAGE_LIST,
        payload
    }
}
export function setIsInfoLoading(payload: ISetIsInfoLoadingPayload): ISecretSantaWidgetActions {
    return {
        type: IS_INFO_LOADING,
        payload
    }
}
export function setNoRights(payload: {id:string, noRights:number|undefined}): ISecretSantaWidgetActions {
    return {
        type: SET_NO_RIGHTS,
        payload
    }
}


/**
 * set toggling variables
 * @param { 'isLoading' | 'isFinished' } variable
 * @param { boolean, undefined } value
 */
export function SSPastisipantsListToggle(payload:ISSParticipantsPageListTogglePayload) {
    return {
        type: SECRET_SANTA_PARTICIPANTS_PAGE_LIST_TOGGLE,
        payload
    }
}

export const getSecretSantaWidgetSettingsAction = (payload: IGetSecretSantaListFromServerPayload): ISecretSantaWidgetActions => ({
    type: GET_SECRET_SANTA_WIDGET_SETTINGS,
    payload

})
export const mainSecretSantaSettingsAction = (payload: string): ISecretSantaWidgetActions => ({
    type: MAIN_SECRET_SANTA_SETTINGS,
    payload

})

export const setSecretSantaWidgetSettings = (payload: ISetSecretSantaWidgetSettingsPayload): ISecretSantaWidgetActions => ({
    type: SET_SECRET_SANTA_WIDGET_SETTINGS,
    payload
})

export const getUserToGivePresent = (payload: IGetUserToGivePresentPayload): ISecretSantaWidgetActions => ({
    type: GET_USER_TO_GIVE_PRESENT,
    payload
})
export const setUserToGivePresent = (payload: ISetUserToGivePresentPayload): ISecretSantaWidgetActions => ({
    type: SET_USER_TO_GIVE_PRESENT,
    payload
})

/**
 * тут я тригерю флаг isLoading (нужно для понимания того получен ли юзер котором юудет санта 
 * отправлять подарок сразу после распределения) если юзер не получен isDistributLoading будет оставаться true 
 * пока юзер не придет, и на виджете будет отображаться сообщение о том что идет распределение 
 * @param { 'isLoading' | 'isFinished' } variable
 * @param { boolean, undefined } value
 */
export function setUserDistributedToggleAction(payload:ISetUserDistributedTogglePayload) {
    return {
        type: SET_USER_DISTRIBUTED_TOGGLE,
        payload
    }
}

// узнать собственные данные для возможности редоктирования
export const getMyInfo = (payload:string): ISecretSantaWidgetActions => ({
    type: GET_MY_INFO,
    payload
})
// записать в редакс собственные данные для возможности редоктирования
export const setMyInfo = (payload: ISetMyInfoPayload): ISecretSantaWidgetActions => ({
    type: SET_MY_INFO,
    payload
})
export const isUserParticipateAction = (payload: IIsUserParticipatePayload): ISecretSantaWidgetActions => ({
    type: IS_USER_SECRET_SANTA_PARTICIPATE,
    payload
})
// записывает в редакс учавствует данный юзер в санте или нет
export const setUserParticipateAction = (payload: ISetUserParticipatePayload): ISecretSantaWidgetActions => ({
    type: SET_USER_SECRET_SANTA_PARTICIPATE,
    payload
})
// записывает изменения в полях участника адрес, телефон, письмо Санте.
export const secretSantaJoinInfoAction = (payload: ISecretSantaJoinInfoPayload): ISecretSantaWidgetActions => ({
    type: SECRET_SANTA_CHANGE_JOIN_INFO,
    payload
})
// отправляет заявку на участие в санте
export const secretSantaJoinAction = ( payload: string): ISecretSantaWidgetActions => ({
    type: SECRET_SANTA_JOIN,
    payload

})
// отменяем участие в тайном санте
export const secretSantaUnJoinAction = (payload:string): ISecretSantaWidgetActions => ({
    type: SECRET_SANTA_UN_JOIN,
    payload

})
export const isPresentSent = (payload: IIsPresentSentPayload): ISecretSantaWidgetActions => ({
    type: IS_PRESENT_SENT,
    payload
})

// записывает в редакс отправил ли юзер подарок или нет(в зависимости от этого будем отображать или показывать виджет)
export const setIsPresentSentAction = (payload: ISetIsPresentPayload): ISecretSantaWidgetActions => ({
    type: SET_IS_PRESENT_SENT,
    payload
})

// отправляет подтверждение отправки подарка
export const presentSentAction = ( payload: string): ISecretSantaWidgetActions => ({
    type: PRESENT_SENT,
    payload

})


