import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { parse } from 'query-string';


export const appsDebug = (): IWidget<IColumn[]> => {
  const params = parse(window.location.search?.substring(1) || '');
  let src = params['url'] || '';
  if(!src) {
    src = `http://${params['host'] || 'localhost'}:${params['port'] || '3000'}`;
  }
  const withAuth = params['withAuth'] === 'true' ? true : false;
  let height = params['height'] || 600;
  if(typeof height !== 'number') {
    height = Number(height);
    if(isNaN(height)) {
      height = 600;
    }
  }
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.iframe,
            settings: {
              src,
              height,
              withAuth
            }
          }),
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  });
}