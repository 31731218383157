import { IStateType as IState } from 'redux/store';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { updateCalendarEvent } from 'redux/calendar/actions';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
export const cnEventsList = cn('EventsList');

export interface IEventItemProps {
  id: string;
}

export type IEventItemStateProps = ReturnType<typeof mapStateToProps>

export interface IUser {
  id: string
  displayName: string
  status: string
  imgUrl: string
}

export const mapStateToProps = (state: IState, props: IEventItemProps) => {

  return {
    event: state.calendar.events[props.id],
    user: getAuthUser(state).baseData
  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  updateCalendarEvent
}, dispatch);

