import {
    isDisableDown,
    isDisableUp,
    lenAvailableCurrency,
    lenFullCount,
    lenMaxPerUser,
    lenMaxThankCount,
    lenMaxAllowUsers,
    lenValues
} from '../helpers'
import {
    pathCreatorNews,
} from 'News/redux/actions'
import {
    lenUserCount,
    lenAllUserCurrency
} from 'News/utils/utils'
import {
    useChangeUsersGroups
} from 'News/utils/hooks'
import {
    ICreateBaseNews
} from 'News/types/baseNews'
import { IThanksNews } from '../Thanks.type'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import React, { FC, useEffect, useMemo, useState, memo, useCallback, useRef } from 'react'
import { loadReasons } from '../redux/actions'
import { getReasons } from '../redux/selectors'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
import { uniqBy } from 'lodash'

export const useChangeReasons = (news: IThanksNews) => {
    useLoadReasons()
    const maxThanksArgumentsCount = useSelector((state: any) => state.store.appSettings.maxThanksArgumentsCount, shallowEqual)
    const dispatch = useDispatch()
    const newsRef = useRef(news)
    newsRef.current = news
    const rewardReasons = news.rewardReasons || []
    const disabled = maxThanksArgumentsCount <= rewardReasons.length

    const onChange = useCallback((event: any,
        value: any[]) => {
        // const disabled = maxThanksArgumentsCount <= value.length
        // if (disabled) return;
        dispatch(pathCreatorNews({
            id: newsRef.current.id,
            rewardReasons: uniqBy(value, 'id')
        }))
    }, [maxThanksArgumentsCount])

    return [
        rewardReasons,
        onChange,
        disabled
    ] as const
}

export const useChangeCurrency = (news: IThanksNews, onChange?: (data: IThanksNews) => void) => {
    const dispatch = useDispatch()
    const maxThanksValue = useSelector((state: any) => state.store.appSettings.maxThanksValue, shallowEqual)
    const currentUser = useSelector(getCurrentUser, shallowEqual)
    const { thanksForAllByCollection, thanksForAll } = currentUser.extData
    const newsRef = useRef(news)
    newsRef.current = news

    const fullCurrency = lenAllUserCurrency(thanksForAllByCollection, thanksForAll)
    const usersCount = lenUserCount(news)

    const values = lenValues(usersCount, maxThanksValue, fullCurrency, news.thanksCount)

    const onChangeCurrency = useCallback((value: number) => {
        onChange ?
            onChange({
                ...newsRef.current,
                thanksCount: value
            }) :
            dispatch(pathCreatorNews({
                id: newsRef.current.id,
                thanksCount: value
            }))
    }, [])


    return {
        onChangeCurrency,
        thanksCount: news.thanksCount,
        fullCurrency,
        ...values
    } as const
}

export const useLoadReasons = () => {
    const dispatch = useDispatch()
    const reasons = useSelector(getReasons, shallowEqual)
    useEffect(() => {
        !reasons && dispatch(loadReasons())
    }, [])
}


export const useChangeThanksUsers = (news: IThanksNews & ICreateBaseNews) => {
    const currentUser = useSelector(getCurrentUser, shallowEqual)
    const { thanksForAllByCollection, thanksForAll } = currentUser.extData
    const fullCurrency = lenAllUserCurrency(thanksForAllByCollection, thanksForAll)
    const [users, setUsers] = useChangeUsersGroups(news)
    const usersCount = lenUserCount(news)
    const maxUsers = lenMaxAllowUsers(news.thanksCount, fullCurrency)

    return [
        users,
        setUsers,
        maxUsers,
        usersCount
    ] as const
}