import { constants } from 'utils/src/constants.prn';
import { ACTION } from 'utils/src/CommonRedux/actionsTypes';

let actions = {
    /* saga */
    LOAD_USER_SKILLS: '',  // получить с сервера навыки пользователя
    CREATE_USER_SKILL: '', //создание нового скила
    CONFIRM_SKILL: '', // подтвердить скилл
    DELETE_SKILL: '', // удалить скилл

    /* reducer */
    ADD_USER_SKILLS: '',  // поместить в стор навыки пользователя
    UPDATE_SKILL: '',  // изсменить состояние скилла
    DELETE_USER_SKILL: '', // удалить скилл
    SET_LOADING: '', // устанавливает статус загрузки
    SET_FINISHED: '', // устанавливает статус завршения пагинации

}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.SKILLS) }), {} as typeof actions);