import * as React from 'react';
import { IWidgetTypeInfoProps } from './Widget_type_infoData.index';
import './Widget_type_infoData.scss';
import { default as WidgetPresenter } from '../Widget';
import { widgets } from "i.widgets";
import { Data } from 'blocks/InfoBlock/UserNew/Data';

const types = {
  common: Data,
}


export default class WidgetTypeInfoPresenter extends WidgetPresenter<IWidgetTypeInfoProps> {

  public render() {
    if (!this.props.widget) return null;
    const Component = this.getComponent(types);
    return <widgets.components.common {...this.props}>
      <Component {...this.props} settings={this.props.widget.settings} context={this.props.wcontext} tag={widgets.components.common} />
    </widgets.components.common>
  }
}

