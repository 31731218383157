import React, { Component } from 'react';
import { defaultSuggestionsFilter } from '../MentionPlugin'
// import { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
// import 'draft-js-mention-plugin/lib/plugin.css';
import * as utils from 'utils/src/utils';
import { IMentionSuggestionsProps, mapStateToProps } from './MentionSuggestions.index'
import { connect } from 'react-redux';
// export const userMentionPlugin = createMentionPlugin();
// const { MentionSuggestions } = userMentionPlugin;
import { item } from 'blocks/PrnUserInput/renders'
import { createPortal } from 'react-dom';
export class MentionSuggestionsDefault extends Component<IMentionSuggestionsProps & any, any> {
    private saggesterRoot: HTMLDivElement;
    private req: any = null;

    constructor(props: any) {

        super(props);
        if (!this.saggesterRoot) this.saggesterRoot = document.getElementById('Layout') as HTMLDivElement || document.body;
        this.state = {
            suggestions: [],
            open: false
        }
    }

    private onAddMention = () => {
        // get the mention object selected
        this.setState({
            suggestions: [],
        });
    }

    private onSearchChange = ({ value }: any) => {
        if (value === '') return this.setState({
            suggestions: []//defaultSuggestionsFilter(value, []),
        });
        const type = this.props.currentUser.isAdmin ? 'usersgroups' : 'users';
        if (this.req) this.req.ac.abort()
        this.req = utils.API.suggesters.search(type, value.trim())

        this.req.r
            .then((d: any) => {
                if (utils.checkResponseStatus(d)) {
                    let data = d.data
                    // if (type === 'usersgroups') {
                    //     data = data.sort((a: any, b: any) => {
                    //         if (a.isGroup) {
                    //             return 1;
                    //         }
                    //         return -1;
                    //     })
                    // }

                    const mentions = data.map((e: any) => {
                        return {
                            ...e,
                            isUser: e.type !== 'group',
                            name: e.displayName,
                            link: (e.type === 'group' ? '/group/' + e.pkid : '/user/' + e.id),
                            avatar: e.userPhotoUrl,
                        }
                    });
                    this.setState({
                        suggestions: mentions//defaultSuggestionsFilter(value, mentions),
                    }, () => { this.props.onChange(true)});
                } else {
                    this.setState({
                        suggestions: []//defaultSuggestionsFilter(value, []),
                    });
                }
            }).catch((e: any) => this.setState({
                suggestions: []//defaultSuggestionsFilter(value, []),
            }));

    };

    public render() {
        const { MentionSuggestions } = this.props.plugin;
        return createPortal(
            <MentionSuggestions
                renderItem={item}
                open={this.props.open}
                onSearchChange={this.onSearchChange}
                suggestions={this.state.suggestions}
                onAddMention={this.onAddMention}
            //  onOpenChange={(open: boolean) => { this.setState({ open }) }}
            />
            , this.saggesterRoot);
    }
}

export const MentionSuggestionsPresenter = connect(
    mapStateToProps
)(MentionSuggestionsDefault);
