/**
 * @packageDocumentation
 * @module ListItem
 */
import { IItemProps } from '../List-Item.index';

import { IDispatchProps } from '../../../../redux/connector';

export interface IItemTypeCommonProps extends IItemProps, IItemStateProps, IDispatchProps {
  tag: React.ComponentType | 'div';
}

export interface IItemTypeCommonState {
  text?: string;
}

export interface IItemStateProps {
  // store: any
}

export const mapStateToProps = (state: any) => ({
  // store: state.store
})