import React, { FC } from 'react'
import { Skeleton } from 'uielements/src';
import { cn } from '@bem-react/classname';

export const cnInfoBlock = cn('InfoBlock');

const Skelet: FC = () => (
    <div className={cnInfoBlock('Skeleton')}>
        <Skeleton shape="circle" size="xxxl" />
        <div className={cnInfoBlock('Info')}>
            <Skeleton shape="line" size="xl" style={{ width: '60%' }} spacing="lg" />
            <Skeleton shape="line" spacing="lg" />
            <Skeleton shape="line" style={{ width: '80%' }} spacing="lg" />
        </div>
    </div>
)

export default Skelet