import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import { 
    loadFullUnit, 
    // completeTest, 
    saveStartUnitResult, 
    startQuestion, 
    setNextQuestion, 
    transitionToQuestion, 
    // sendCompleteUnitTest 
} from "../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
import { getSkipedQuestionIds } from '../../redux/sagas/LMS/selectors'

export interface IListSkipedProps extends IClassNameProps, IListSkipedStateProps, IDispatchProps,
    RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
    uid: string;
    // unitContent: any
}

export interface IListSkipedState {
    // courses: ICourse[];
    // isFinished: boolean;
}

export interface IListSkipedStateProps {
    store: any
    unitContent: any
}

export const mapStateToProps = (state: any, props: any) => ({
    store: state.store,
    unitContent: state.LMS.units[props.uid],
    skiped: getSkipedQuestionIds(props.uid)(state),
    questions: state.LMS.units[props.uid].normalQuestions
})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loadFullUnit,
    // sendCompleteUnitTest,
    saveStartUnitResult,
    startQuestion,
    setNextQuestion,
    transitionToQuestion,
    // completeTest
}, dispatch);

export const cnListSkiped = cn('ListSkiped');