import * as React from 'react';
import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';

import { withBemMod } from '@bem-react/core';

import { cnBody } from '../Modal-Body.index';
import { IBodyTypeCreateGroupProps, IBodyTypeCreateGroupState } from './Modal-Body_type_createGroup.index';

import './Modal-Body_type_createGroup.scss';
import { Input } from 'uielements/src/Input/Input';
import { InputMention } from 'blocks/InputMention/InputMention';
import { CheckboxInput } from 'uielements/src';
import { Textarea } from 'uielements/src/Textarea/Textarea';
import { ImgLoader } from 'blocks/ImgLoader/ImgLoader';

class NewBody extends React.Component<IBodyTypeCreateGroupProps, IBodyTypeCreateGroupState> {
  public utils: any = utils;
  public el: HTMLElement | null;

  constructor(props: IBodyTypeCreateGroupProps) {
    super(props);
    this.state = {
      name: '',
      description: '',
      parentGroup: undefined,
      isHidden: false,
      isClosed: false
    }

    this.props.parent.state.data = {
      name: '',
      description: '',
      imgId198x198: '',
      parentGroup: -1,
      isHidden: false,
      isArchived: false,
      isDeleted: false,
      isSystemGroup: false,
      childGroupCount: 0,
      visibilityType: 0
    }
  }

  public render() {
    const customClassNames = '';
    const TagName = this.props.tag ? this.props.tag : 'div';
    return (
      <TagName ref={el => this.el = el} className={cnBody({}, (this.props.className ? this.props.className.split(' ') : []).concat(customClassNames.split(' ')))}>
        <Input placeholder={i18n.t('pryaniky.modal.groupCreate.name')} value={this.state.name} onChange={this.onTextChange} name="name" />
        <Textarea placeholder={i18n.t('pryaniky.modal.groupCreate.description')} value={this.state.description} onChange={this.onTextChange} name="description" />
        <InputMention type='groups' value={this.state.parentGroup} placeholder={i18n.t('pryaniky.modal.groupCreate.parent')} noMulti={true} onChange={this.parentGroupChange} />
        <ImgLoader previewUrl={this.state.imgPreview} header={i18n.t('pryaniky.modal.groupCreate.avatar')} onLoadEnd={this.imgLoaded} text={i18n.t('pryaniky.modal.groupCreate.imageSize')} />
        <CheckboxInput checked={this.state.isHidden} name="isHidden" onChange={this.handleCheckboxChange} text={i18n.t('pryaniky.modal.groupCreate.isHidden')} />
        <CheckboxInput checked={this.state.isClosed} name="isClosed" onChange={this.handleCheckboxChange} text={i18n.t('pryaniky.modal.groupCreate.isPrivate')} />
      </TagName>
    )
  }

  private onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    
    this.setState({[name]: value}, () => {
      this.props.parent.state.data[name] = this.state[name];
      this.props.parent.validateData()
    });
  }

  private handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, checked} = e.currentTarget;
    this.setState({ [name]: checked }, () => {
      if (name === 'isHidden') this.props.parent.state.data.isHidden = this.state.isHidden;
      else if (name === 'isClosed') this.props.parent.state.data.visibilityType = +this.state.isClosed;
    });
  }

  private parentGroupChange = (selected: {[s: string]: any}) => {
    this.setState({parentGroup: selected}, () => {
      this.props.parent.state.data.rootGroup = this.state.parentGroup ? this.state.parentGroup.data.id : -1;
    });
  }

  private imgLoaded = (loadedImg: {[s: string]: any}) => {
    this.setState({imgPreview: loadedImg.previewUrl}, () => {
      this.props.parent.state.data.imgId198x198 = loadedImg.id;
    });
  }
}

export const withBodyTypeCreateGroup = withBemMod<any>(
  cnBody(),
  { type: 'createGroup' }, 
  (Presenter) => (
    (props: IBodyTypeCreateGroupProps) => (
      <NewBody {...props} />
    )
  )
);