import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IDispatchProps } from 'redux/connector';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { set_readed_all } from '../../redux/actions/Notification';
import { setEditType } from 'utils/src/CommonRedux/base/actions';
import { bindActionCreators } from 'redux';
import actions from 'utils/src/CommonRedux/base/actionsTypes';
import {
  menuHidden,
  menuEdit as oldMenuEdit,
  menuSetData as oldMenuSetData,
  menuUnsave as oldMenuUnsave
} from '../LeftMenu/redux/actions';
import { getMenuService } from '../LeftMenu/redux/selectors';

import { leftMenuSetEdit, leftMenuSetReducerField } from '../LeftMenuMui/redux/actions';
import { getLeftMenuReducerField } from '../LeftMenuMui/redux/selectors';
import { getLifeCycol } from 'utils/src/CommonRedux/LifeCycol/selectors';
import { getQuery, setActiveTab } from 'redux/actions/Search';
import { TSearchData } from 'utils/src/requests/models/admin.search';

export interface IHeaderProps extends IClassNameProps, HeaderStateProps, IHeaderOwnProps, IHeaderDispathProps {}

export interface IHeaderOwnProps {
  tag?: 'div';
}

export const mapStateToProps = (state: any) => ({
  edit: state.store.edit as boolean,
  isAdmin: getAuthUser(state).baseData.isAdmin as boolean,
  isEditable: state.store.isEditable as boolean,
  audioPlayList: state.store.audioPlayList,
  networks: getAuthUser(state).baseData.networks,
  homePage: state.store.appSettings?.homePage,
  titleLogo: state.store.appSettings?.titleLogo,
  showLogoInHeader: state.store.appSettings?.showLogoInHeader,
  chatEnable: state.store.appSettings.enabledModules.chat,
  virtUsers: state.users.virtUsers,
  oldMenuEditState: getMenuService(state).edit,
  activePage: state.widgets.active,
  menuEdit: getLeftMenuReducerField('edit')(state) as boolean,
  menuHide: getLeftMenuReducerField('hide')(state) as boolean,
  lifeCycleStructureFromServer: getLifeCycol(state).INIT_STRUCTURE_FROM_SERVER,
  notificationCount: state.store.notificationCount,
  filters: (state.store.appSettings.searchFilters as TSearchData[]).filter((item => item.showAtQuickSearch))
});

export type IHeaderDispathProps = ReturnType<typeof mapDispatchToProps>

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  set_readed_all,
  setEditType,
  menuHidden,
  oldMenuEdit,
  oldMenuSetData,
  oldMenuUnsave,
  leftMenuSetEdit,
  leftMenuSetReducerField,
  changeVm: (propPath: string, value: any) => ({ type: actions.CHANGE_STATE, payload: { propPath, value }}),
  getQuery,
  setActiveTab
}, dispatch);

export type HeaderStateProps = ReturnType<typeof mapStateToProps>

export interface IHeaderState {
  showSearch: boolean;
}

export const cnHeader = cn('Header');