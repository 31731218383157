import { Box, Paper, styled } from "muicomponents/src"


export const HalfPaper = styled(Paper)({
    padding: '16px',
    '&:first-child': {
        marginRight: '2%',
    },
}) as typeof Paper

export const WrapBox = styled(Box)({
    display: 'flex',
}) as typeof Box