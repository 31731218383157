import { cn } from '@bem-react/classname'
import { bindActionCreators } from 'redux'
import { IStateType as IState } from 'redux/store';
import { CalendarProps } from 'react-big-calendar'
import { getCalendarEvents } from 'redux/calendar/actions';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { ObjectDate, Omit } from 'utils/src';

export type CalendarSearchParams = {
  defaultDate: ObjectDate,
  defaultView: 'day' | 'week' | 'month'
}

export interface ICalendarProps extends Omit<CalendarProps, 'localizer'>, ICalendarStateProps, ICalendarDispatchProps {
  context: any
  newsId: any
  tag?: any
}

export const mapStateToProps = (state: IState) => {
  return {
    language: getAuthUser(state).baseData.language,
    calendarEvents: state.calendar.calendarEvents,
    loading: state.calendar.loading,
    currentCalendarDate: state.calendar.currentDate,
    calFilters: state.calendar.calendars
}}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({ 
  getCalendarEvents
}, dispatch);

export type ICalendarStateProps = ReturnType<typeof mapStateToProps>
export type ICalendarDispatchProps = ReturnType<typeof mapDispatchToProps>

export const cnCalendar = cn('CalendarP');

