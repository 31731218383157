export const matchesEntityType = (type: any) => type === 'USER_SELECT';

export default function strategy(contentBlock: any, cb: any, contentState: any) {
  if (!contentState) return;
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      matchesEntityType(contentState.getEntity(entityKey).getType())
    );
  }, cb);
}
