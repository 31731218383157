import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Dispatch, Action } from 'redux';
import {
    newsViewFull
} from '../../redux/actions'
import {
} from '../../redux/reducer/types'
import {
    getNewsParamsById
} from '../../redux/saga/selectors'
import { NewsContextProps } from '../../contexts/news'
import { getDefaultTextTruncateLength } from 'utils/src/CommonRedux/base/selectors'

///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace TextRender {

        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps

        export interface IState { }

        export interface IOwnProps extends Pick<NewsContextProps, 'isSingle' | 'newsId'> {

        }

        export type IStateProps = ReturnType<typeof mapStateToProps>

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>
    }
}
////////////
type IOwnProps = News.TextRender.IOwnProps

const newsParams = [
    'text',
] as const

export const mapStateToProps = (state: any, { newsId }: IOwnProps) => ({
    ...getNewsParamsById(newsId, newsParams)(state),
    defaultTextTruncateLength: getDefaultTextTruncateLength(state)
})

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) => bindActionCreators({
    newsViewFull
}, dispatch);

////////////

export const cnClassName = cn('NewsTextRender');