import React, { FC, useState, useEffect, useCallback } from 'react';
import {
    Dialog,
    // DialogTitle,
    // DialogContent,
    // DialogActions,
    Select,
    Button,
    Icon,
    CheckboxInput,
    Textarea, Spinner
} from 'uielements/src';
import i18n from 'localizations/i18n';
import { toast } from 'react-toastify';
import './CreateSession.scss'
import * as utils from 'utils/src/utils'
import { ICreateSessionProps, cnCreateSession, mapDispatchToProps, mapStateToProps } from './CreateSession.index'
import { connect } from 'react-redux';
import { TextInput } from 'blocks/PryanikyForms'
import { PrnUserInput } from 'blocks/PrnUserInput/PrnUserInput'
import { DateTimeInput } from 'blocks/PryanikyForms/DateTimeInput/DateTimeInput'
import { sessionModelCreator, ISessionModel } from 'LMSModule/utils/dataCreators/sessionCreator'
import { uniqBy } from 'lodash'
import { tU } from 'LMSModule/utils/i18n.adaptor'
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { createModal, TransitionComponent, IBodyProps } from 'utils/src/DialogCreator'


const zeroId = '00000000-0000-0000-0000-000000000000'



const sessionFormater = (session: ISessionModel) => (): ISessionModel => {
    const userFullList = session.userFullList.map(value => session.obligatoryUsers.includes(value.id) ? { ...value, isObligatory: true } : value)
    const groupFullList = session.groupFullList.map(value => session.obligatoryGroups.includes(/*value.id === zeroId ? */value.pkid /*: value.id*/) ? { ...value, isObligatory: true, isGroup: true, type: 'group' } : value)
    return { ...session, userFullList, groupFullList }
}
export const CreateSessionPresenter: FC<ICreateSessionProps & any> = ({
    isShown,
    handleAccept,
    handleClose,
    onClose,
    createSession,
    cid,
    session = sessionModelCreator(),
    loadSession,
    fromPreview = false
}) => {

    const TYPES: { [key: number]: any } = {
        0: { title: tU('dialogs.create_session.type.0.1'), value: 0 },
    }
    if (window.PRN_SERVICE.dev_features) {
        TYPES[2] = { title: tU('dialogs.create_session.type.2.1'), value: 2 }
        TYPES[3] = { title: tU('dialogs.create_session.type.3.1'), value: 3 }
    }
    const [s, setS] = useState<ISessionModel>(sessionFormater(session))
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (session.id === zeroId) return;
        let load = false
        if (session.memberUsers.length !== session.userFullList.length) load = true;
        if (session.memberGroups.length !== session.groupFullList.length) load = true;
        if (load && !loading) {
            setLoading(true)
            loadSession(session.id)
        } else {
            setLoading(false)
            setS(sessionFormater({ ...session }))
        }
    }, [session])

    const changeDescription = (e: any) => {
        setS({ ...s, description: e.target.value })
    }

    const chnageName = (e: any) => {
        setS({ ...s, name: e })
    }

    // const chnageMandatory = () => {
    //     setS({ ...s, isMandatory: !s.isMandatory })
    // }

    const chnagePreview = () => {
        setS({ ...s, isPreview: !s.isPreview })
    }

    const chnageStartType = (selected: any) => {
        setS({ ...s, startType: selected.value })
    }

    const obligatoryFullUsers = s.userFullList.filter((v) => v.isObligatory)
    const obligatoryFullGroups = s.groupFullList.filter((v) => v.isObligatory)
    const obligatoryFull = [...obligatoryFullUsers, ...obligatoryFullGroups].filter((v) => v)

    const fullUsers = s.userFullList.filter((v) => !v.isObligatory)
    const fullGroups = s.groupFullList.filter((v) => !v.isObligatory)
    const full = [...fullUsers, ...fullGroups].filter((v) => v)

    const changeUsers = (v: any) => {
        const selected = v.filter((v: any) => v !== null) as any[]


        const nonOldMondatory = selected.map((v) => ({ ...v, isObligatory: false }))
        const uniqValues = uniqBy([...nonOldMondatory, ...obligatoryFull], (v: any) => v.id)
        const groupFullList: any[] = uniqValues.filter((v) => v.type === 'group')
        const userFullList: any[] = uniqValues.filter((v) => v.type !== 'group')
        setS({ ...s, userFullList, groupFullList })
    }


    const changeObligatory = (v: any[]) => {
        const selected = v.filter((v: any) => v !== null)

        const oldMondatory = selected.map((v) => ({ ...v, isObligatory: true }))
        const uniqValues = uniqBy([...oldMondatory, ...full], (v: any) => v.id)
        const groupFullList: any[] = uniqValues.filter((v) => v.type === 'group')
        const userFullList: any[] = uniqValues.filter((v) => v.type !== 'group')
        setS({ ...s, userFullList, groupFullList })
    }

    const changeBeginDate = (value: Date) => {
        setS({ ...s, beginDate: value })
    }

    const changeEndDate = (value: Date) => {
        setS({ ...s, endDate: value })
    }

    const changePeriod = (value: string) => {
        setS({ ...s, completeInDays: parseInt(value) })
    }

    const onCreate = () => {
        const memberGroups: string[] = s.groupFullList.map((val: any) => val.pkid)
        const memberUsers: string[] = s.userFullList.map((val: any) => val.id)
        const obligatoryUsers: string[] = s.userFullList.filter((v: any) => v.isObligatory).map((val: any) => val.id)
        const obligatoryGroups: string[] = s.groupFullList.filter((v: any) => v.isObligatory).map((val: any) => val.pkid)
        createSession(cid, { ...s, memberUsers, memberGroups, obligatoryUsers, obligatoryGroups }, fromPreview)
        setS({ ...session })
        handleClose()
    }

    let validationFail = s.startType === 2 && new Date(s.beginDate || new Date()) > new Date(s.endDate || new Date());
    validationFail = s.name === '' ? true : validationFail;
    validationFail = s.startType === 3 ? s.completeInDays === 0 : validationFail;

    return (
        <>
            <div >
                <DialogTitle onClose={handleClose}>
                    {session.id === zeroId ? tU('dialogs.create_session.header.1') : tU('dialogs.create_session.header.2')}
                    {/* <p className="my-1">{i18n.t('pryaniky.modal.inviteUser.text')}</p> */}
                </DialogTitle>
                <div>
                    {loading && <Spinner />}
                    <TextInput
                        className={cnCreateSession('Name')}
                        icon={<Icon icon={'fa-bell'} />}
                        placeholder={tU('dialogs.create_session.name.1')}
                        value={s.name}
                        onChange={chnageName} />

                    <Textarea
                        icon={'edit'}
                        className={cnCreateSession('Description')}
                        placeholder={tU('dialogs.create_session.description.1')}
                        onChange={changeDescription}
                        value={s.description} />

                    <PrnUserInput
                        type={'usersgroups'}
                        className={cnCreateSession('Obligatory')}
                        placeholder={tU('dialogs.create_session.obligatoryFull.1')}
                        excludeUsers={obligatoryFull}
                        value={obligatoryFull}
                        onChange={changeObligatory} />

                    <PrnUserInput
                        type={'usersgroups'}
                        className={cnCreateSession('Users')}
                        placeholder={tU('dialogs.create_session.full.1')}
                        excludeUsers={full}
                        value={full}
                        onChange={changeUsers} />


                    {TYPES[s.startType] && <div className={cnCreateSession('Durations')}>
                        <Select
                            className={cnCreateSession('Type')}
                            selected={TYPES[s.startType]}
                            options={Object.values(TYPES)}
                            onChange={chnageStartType} />

                        <DurationRender
                            type={TYPES[s.startType]}
                            changeBeginDate={changeBeginDate}
                            changeEndDate={changeEndDate}
                            beginDate={s.beginDate}
                            endDate={s.endDate}
                            periodDays={s.completeInDays}
                            changePeriod={changePeriod}
                        />
                    </div>}

                    {/* <CheckboxInput text={'Обязательный'} checked={s.isMandatory} onClick={chnageMandatory} /> */}
                    <CheckboxInput  className={cnCreateSession('CheckboxInput')}  text={tU('dialogs.create_session.isPreview.1')} checked={s.isPreview} onClick={chnagePreview} />
                    {/* <CheckboxInput text={'Добавить в учебный план'} checked={false} /> */}
                </div>
                <DialogActions acceptText={tU('dialogs.create_session.save.1')}
                closeText={i18n.t('pryaniky.cancel.dialog')}
                disableAccept={validationFail}
                onAccept={onCreate}
                onClose={handleClose}/>
                    {/* <Button disabled={validationFail} main onClick={onCreate}>{tU('dialogs.create_session.save.1')}</Button>
                    <Button onClick={onClose}>
                        {i18n.t('close')}
                    </Button>
                </DialogActions> */}
            </div>
        </>
    );
};

const DurationRender: FC<any> = ({ type, changeBeginDate, changeEndDate, beginDate, endDate, periodDays, changePeriod }) => {

    switch (type.value) {
        case 0:
            return null;
        case 1:
            return null;
        case 2:
            return <div className={cnCreateSession('Periode')}>
                <DateTimeInput
                    // className={'EditorPolls-Datepicker'}
                    // icon={() => <span>Начало</span>}
                    minDate={new Date()}
                    value={new Date(beginDate)}
                    onChange={changeBeginDate}
                    action={(open, onClick) => <Button {...{ onClick }}><Icon icon='calendar' /></Button>}
                />
                <DateTimeInput
                    // className={'EditorPolls-Datepicker'}
                    // icon={() => <span>Окончание</span>}
                    minDate={new Date(beginDate)}
                    value={new Date(endDate)}
                    onChange={changeEndDate}
                    action={(open, onClick) => <Button {...{ onClick }}><Icon icon='calendar' /></Button>}
                />
            </div>;
        case 3:
            return <TextInput
                className={cnCreateSession('DurationDays')}
                type={'number'}
                onChange={changePeriod}
                value={periodDays}
                // icon={<Icon icon={'fa-bell'} />}
                placeholder={tU('dialogs.create_session.periodDays.1')}
            // value={s.name} onChange={chnageName} 
            />;
        default:
            return null
    }
}

export const CreateSessionDialog = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateSessionPresenter);


export const DialogCreateSession = createModal(CreateSessionDialog, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});