import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

///////////

export type IPresenterProps = IOwnProps

export interface IState { }

export interface IOwnProps extends IClassNameProps {
    children?: React.ReactNode
    qid: string;
    uid: string;
    number: number;
    header: string
    isNew?: boolean
    deleteQuestion: (qid: string) => void
    withAnswerError: boolean;
    errors: any;
    answersErrors: any[];
}



////////////

export const cnClassName = cn('TOCEQuestionLayout');