
import React from "react";
// import * as utils from "../../../utils";
import i18n from "../../../localizations/i18n";
import { connect } from "react-redux";
import AppStore from "../../../assets/svg/AppStore.svg";
import GoogleStore from "../../../assets/svg/GoogleStore.svg";
import { cn } from "@bem-react/classname";
import {
  Input,
  // DialogTitle,
  // Button,
  Icon,
  Dialog,
  TickTimer
} from "uielements/src";
import { DialogMobileAppLogin } from "./QRLogin/QRLogin";
import {
  IWithQRDialogStateProps,
  IWithQRDialogProps,
  IWithQRDialogOwnProps,
  IWithQRDialogState,
  IWithQRDialogDispatchProps,
  mapWithQRDialogDispatchToProps,
  withQRDialogMapStateToProps
} from "./DialogWithQR.index";
import "./DialogWithQR.scss";
import * as utils from 'utils/src/utils';
import InputMask from 'react-input-mask';
import { getErrorText } from 'utils/src/utils'
import { HelpTooltip } from 'uielements/src/index';
import { toast } from 'react-toastify';
import { API } from 'utils/src/utils';
import { Translate } from 'localizations/Translate';
import { InputAdornment } from '@material-ui/core';
import Phone from '@material-ui/icons/Phone';
// import Input from 'uielements/src/MaterialElements/Input/Input';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import { Link } from 'muicomponents/src/Link/Link'
import { Button } from 'muicomponents/src/Button/Button'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';

export const cnWithQRDialog = cn("WithQRDialog");

export class WithQRDialogPresenter extends React.Component<IWithQRDialogProps, IWithQRDialogState> {
  constructor(props: IWithQRDialogProps) {
    super(props);
    this.state = {
      isShownQRLogin: false,
      sendOnPhone: false,
      phone: { value: '', valid: true, error: '' },
      timer: false
    };
  }

  private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    API.system.sendAppLink(this.state.phone.value).r.then((d: any) => {
      if (d.error_code === 0) {
        this.setState({ timer: true })
        toast.success(i18n.t("pryaniky.massage.is.send"));
      } else {
        toast.error(i18n.t("server error"));
      }
    })
  };


  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const value = e.target.value;
    const value = e.currentTarget.value;
    const valid = /^\+\d-\d{3}-\d{3}-\d{4}/.test(value);
    this.setState({ phone: { value, valid, error: valid ? '' : i18n.t('pryaniky.inputs.phone.error') } });
  };


  public render() {
    // сonst { props, onConfirm } = this;
    const { props, state } = this;
    const { handleAccept, handleClose, children } = props;
    const { isShownQRLogin, phone, sendOnPhone, timer } = state;

    return (
      <>
        <DialogTitle onClose={handleClose}>
          {i18n.t("pryaniky.widgets.mobileApp.title2")}
        </DialogTitle>

        <DialogContent className={cnWithQRDialog("")}>
          <div className={cnWithQRDialog("Content")}>
            <div className={cnWithQRDialog("QRContent")}>
              <div className={cnWithQRDialog("QrCode")}>
                <img
                  className={cnWithQRDialog("QrCodeImg")}
                  src={`${utils.API.base.requestDomain}/Img/mobileapp/ios.png`}
                  alt="QR-code for"
                />
                <Link className={cnWithQRDialog("QrCode-StoreBtn")} target="_blank" href={"https://apps.apple.com/us/app/pryaniky-connect/id1449991805"}>
                  <img src={AppStore} className={cnWithQRDialog("QrCode-StoreBtn-IMG")} alt="AppStoreIcon" />

                </Link>
              </div>
              <div className={cnWithQRDialog("QrCode")}>
                <img
                  className={cnWithQRDialog("QrCodeImg")}
                  src={`${utils.API.base.requestDomain}/Img/mobileapp/android.png`}
                  alt="QR-code for"
                />
                <Link className={cnWithQRDialog("QrCode-StoreBtn")} target="_blank" href={"https://play.google.com/store/apps/details?id=com.pryaniky.app"}>
                  <img src={GoogleStore} className={cnWithQRDialog("QrCode-StoreBtn-IMG")} alt="GoogleStore" />
                </Link>
                {/* <img src={GoogleStore} alt="GoogleStore" /> */}
              </div>


            </div>
            <div className={cnWithQRDialog("QrCode")}>
              {(sendOnPhone && phone) && <div className={cnWithQRDialog("SendOnPhone")} >
                {timer ?
                  <TickTimer
                    count={120}
                    text={(tick) =>
                      <Translate i18nKey={'pryaniky.widgets.mobileApp.resendCodeTimer'}
                        values={{ count: tick }}
                        variablesI18nKeys={{ variable: utils.nameCount(tick, 'pryaniky.variables.names.seconds', 'up') }} />
                    }
                    end={() => this.setState({ timer: false })}
                  /> : <form className={cnWithQRDialog("Form")} onSubmit={this.handleSubmit}>
                    <div className={cnWithQRDialog("Text")}>
                      {i18n.t("pryaniky.widgets.mobileApp.get.link.on.phone")}
                      <HelpTooltip className='TooltipMargin' help={i18n.t("pryaniky.widgets.mobileApp.get.link.on.phone.help")}>
                        <Icon icon={'question-circle'} />
                      </HelpTooltip>
                    </div>
                    <InputMask
                      mask="+9-999-999-9999"
                      value={phone.value}
                      onChange={this.handleChange}>
                      {(inputProps: any) => (
                        <Input
                          {...inputProps}
                          //  icon="mobile"
                          name="phone"
                          type="tel"
                          className={cnWithQRDialog("Input")}
                          placeholder={i18n.t("pryaniky.widgets.mobileApp.placeholder")}
                          variant="outlined"
                          id={"phone"}
                          autoFocus
                          invalid={!phone.valid}
                          errorMessage={phone.error}
                        />
                      )}

                    </InputMask>
                    {/* {!phone.valid && phone.value!=="+_-___-___-____" && <span className={cnWithQRDialog("Error")}>{i18n.t(phone.error)} </span>} */}
                    <Button className={cnWithQRDialog("ActionBTN")} variant={'contained'} color={'primary'} disabled={!phone.value || !phone.valid}>
                      {i18n.t("pryaniky.widgets.mobileApp.send")}
                    </Button>

                  </form>}
              </div>}

              {!sendOnPhone && <Button sx={{ margin: '0 auto 15px'}} variant={'contained'} color={'primary'} className={cnWithQRDialog("Submit")} onClick={this.showSentOnPhone}>
                {i18n.t("pryaniky.widgets.mobileApp.or.send")}
              </Button>}
            </div>
            <Button sx={{ margin: '0 auto 15px' }} className={cnWithQRDialog("Submit")} onClick={() => {
              DialogMobileAppLogin({}).then(() => { }).catch(() => { });
              handleClose()
            }}>
              {i18n.t("pryaniky.widgets.mobileApp.logIn")}
            </Button>
          </div>
        </DialogContent>
      </>
    );
  }

  private openModal = () => this.setState({ isShownQRLogin: true });
  private closeModal = () => this.setState({ isShownQRLogin: false }, this.props.handleClose);
  private showSentOnPhone = () => this.setState({ sendOnPhone: true });

}

export const WithQRDialog = connect<IWithQRDialogStateProps, IWithQRDialogDispatchProps, IWithQRDialogOwnProps>(
  withQRDialogMapStateToProps,
  mapWithQRDialogDispatchToProps
)(WithQRDialogPresenter)


export const DialogMobileApp = createModal(WithQRDialog, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});