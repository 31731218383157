import React, { FC, memo, useCallback } from 'react'
import {
    cnClassName,
    IPresenterProps
} from './interfaces'
import './style.scss'
import { 
    createAttemptOptions, 
    cretateAnswersMap, 
    useSetAttepmt, 
    getNotModerateTextAnswersCount,
    resultsAvaibled,
    getAttepmtQuestionsIds
} from 'LMSModule/utils/result.utils'
import { Body } from '../../components/Body/Body'
import { Select } from 'uielements/src'

const getAssignmentQuestionCount = (questions: any[]) => {
    const freeCount = questions.filter((q: any) => q.isNeedModeration).length
    const testCount = questions.filter((q: any) => q.qtype !== 2).length
    return freeCount + testCount
}

const getAttemptVersion = (result: any) => {
    if (result.attempt.length > 0) {
        return result.attempt[0].timeStamp
    }
    return false
}



export const Presenter: FC<IPresenterProps> = ({
    className,
    unitResult,
    sid,
    unit,
    unitContent,
    detailsRender,
    attempt,
    cangeAttempt,
    isAbsolutelyCompleted
}) => {
    
    const options = createAttemptOptions(unitResult.results.length)

    // const [attempt, cangeAttempt] = useSetAttepmt(options, [sid, unit.id])

    const noResult = !resultsAvaibled(unitResult, attempt)

    // const version = getAttemptVersion(unitResult.results[attempt.value])

    const [resultAnswers, resultAnswersMap] = cretateAnswersMap(unitResult.results[attempt.value].attempt)

    const apttemptQuestions = getAttepmtQuestionsIds(unitResult, attempt)

    return noResult ?
        <div >
            <h3>{unitResult.name}</h3>
            <Select options={options} selected={attempt} onChange={cangeAttempt} />
        </div> :
        <Body
            // unitTimer={unitTimer}
            resultAnswersMap={resultAnswersMap}
            resultAnswers={resultAnswers}

            rightAnswersCount={unitResult.rightAnswersCount}
            questionsCount={unitResult.questionsCount}//getAssignmentQuestionCount(unitContent.questions.map((qid: string) => unitContent.normalQuestions[qid]))}
            notModerateAnswersCount={getNotModerateTextAnswersCount([unitResult])}
            // questionsCount={unitResult.questionsCount}

            normalQuestions={unitContent.normalQuestions}
            // questions={unitContent.questions}
            questions={apttemptQuestions}

            isAbsolutelyCompleted={isAbsolutelyCompleted}

            attemptNumber={unitResult.results.length}
            unitContent={unitContent}
            waitModerationCount={unitResult.waitModerationCount}
            moderatedAnswersCount={unitResult.moderatedAnswersCount}

            highlightCorrectness={unitContent.highlightCorrectness}

            attemptsList={options}
            selectedAttempt={attempt}
            cangeAttempt={cangeAttempt}

            detailsRender={detailsRender}
        />
}

export default memo(Presenter);
