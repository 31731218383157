import { cloneDeep } from 'lodash';
import { GridSize } from 'muicomponents/src/Grid';
import React, { DragEvent, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateWidget,
    setDraggingElem,
    changeWidgetsViewType,
    dragWidget
} from 'redux/actions/Widgets';
import { TColumn, TWidget } from 'utils/src/BaseTypes/widgets.types';
import { getStoreField } from 'utils/src/CommonRedux/base/selectors';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { actionsBlockDataId, sizingByCount } from './constants';
import { getWidgetById, getWidgetContextById, getWidgetsField } from './selectors';
import { WidgetsTypes } from 'utils/src/widgets/types';
import { checkTypeToCanManipulateWithChildWidgets } from './utils';
import { TSimplifiedWidgetByType, TWidgetsTypeToDragOthersWidget } from './types';
// import {
//   updateContext,
//   removeWidget,
//   updateWidget,
//   addWidget,
//   dragWidget,
//   setStructureFromWidget,
//   addTabToWidget,
//   changeWidgetSettings,
//   changeWidgetsViewType,
//   setDraggingElem
// } from 'redux/actions/Widgets';

export const useGlobalStoreForWidget = () => {
    const edit = useSelector(getStoreField('edit'));
    const editType = useSelector(getStoreField('editType'));

    return {
        edit,
        editType
    }    
}

export const useWidget = <WidgetType extends TSimplifiedWidgetByType<keyof typeof WidgetsTypes> = TSimplifiedWidgetByType<keyof typeof WidgetsTypes> > (id: string) => {

    const dispatch = useDispatch();

    const viewType = useSelector(getWidgetsField('viewType'));
    const draggingElem = useSelector(getWidgetsField('dragging'));

    const widget = useSelector(getWidgetById(id)) as WidgetType;
    const context = useSelector(getWidgetContextById(id));


    const changeWidget = (data: Partial<typeof widget>) => {
        /**
         * @todo fix as any
         */
        dispatch(updateWidget({
            ...widget,
            ...data
        } as any));
    };

    return {
        widget,
        type: widget?.type,
        settings: widget?.settings,
        context,
        viewType,
        draggingElem,
        changeWidget
    }
}

export const useWidgetDrag = (id: TWidget<any, any>['id']) => {

    const dispatch = useDispatch();

    const {
        widget,
        changeWidget
    } = useWidget<TSimplifiedWidgetByType<TWidgetsTypeToDragOthersWidget>>(id);

    // const widget = useSelector(getWidgetById(id)) as IWidget<any, any>;

    const onDragStart = (ev: any) => {
        dispatch(setDraggingElem(ev.item && (ev.item.getAttribute('data-id') || ev.item.getAttribute('grid-id')) || undefined));
        // dispatch(changeWidgetsViewType('small'));
    };

    const onDragEnd = () => {
        dispatch(setDraggingElem());
        dispatch(changeWidgetsViewType('full'));
    };

    const onWidgetAdd = () => {
        dispatch(setDraggingElem());
        dispatch(changeWidgetsViewType('full'));
    };

    const onDragged = (columnId: TColumn['id']) => (newItems: string[]) => {
        const newWidget = cloneDeep(widget);
        if (newWidget.data) newWidget.data = newWidget.data.map((column) => {
            if (column.id === columnId) {
                return {
                    ...column,
                    items: newItems.filter(el => el !== actionsBlockDataId)
                };
            }
            return column;
        });
        /**
         * @todo fix as any
         */
        dispatch(dragWidget(newWidget as any));
    };

    const dragChildWidget = () => {
        if(!checkTypeToCanManipulateWithChildWidgets(widget.type)) {
            return ;
        }
        
    };

    const addChildWidget = () => {
        if(!checkTypeToCanManipulateWithChildWidgets(widget.type)) {
            return ;
        }
        
    };

    const removeChildWidget = () => {
        if(!checkTypeToCanManipulateWithChildWidgets(widget.type)) {
            return ;
        }
        
    };

    return {
        onDragStart,
        onDragEnd,
        onWidgetAdd,
        onDragged
    };
};