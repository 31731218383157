import React, { FC, useEffect } from 'react'
import { ILessonProps, mapDispatchToProps, mapStateToProps, cnLesson } from './Lesson.index'
import './Lesson.scss'
import './Lesson.ie.scss'
import { PostContentRender } from 'blocks/PostContentRender/PostContentRender'
import { connect } from 'react-redux';
import { Button } from 'uielements/src'
// import { LessonsList } from '../../LessonsList/LessonsList'
import { Comments } from '../../Comments/Comments';
import { withPreloader } from '../../Preloader/Preloader'
import { withLoad } from '../../ButtonWithLoad/ButtonWithLoad'
import { Lesson as Skelet } from '../../../Skelets/Lesson/Lesson'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { toPrevUnit } from '../../../redux/actions/LMS';
import WikiLesson from '../../../components/Lesson'
import LessonNavigation from '../../../components/LessonNavigation'
import SpringScorm from '../../iSpringScorm'
import { tS } from 'LMSModule/utils/i18n.adaptor'
import { getUnitPosition } from '../../../utils/common.utils'
const zeroId = "00000000-0000-0000-0000-000000000000";
const ButtonL = withLoad(Button)


export const LessonPresenter: FC<any> = ({
    unit,
    next,
    prev,
    unitContent,
    course,
    disableActions = false,
    fullscreen = false,
}) => {

    const { isFirst, isLast, unitIndex } = getUnitPosition(course.courseUnits, unit.id)

    return <div className={cnLesson({ fullscreen })}>

        {unitContent.description === 'iSpring_scorm_12'
            ? <SpringScorm fullscreen={fullscreen} className={cnLesson('Lesson')} name={unitContent.name} rawState={unitContent.wiki.data} />
            : <WikiLesson fullscreen={fullscreen} className={cnLesson('Lesson')} name={unitContent.name} rawState={unitContent.wiki.data} />}

        <LessonNavigation
            className={cnLesson('Navigation', { fullscreen })}
            left={isFirst ? undefined : <ButtonL
                className={cnLesson('NavBtn')}
                theme={'unstyled_center'}
                onClick={prev}>{tS('prev.1')}</ButtonL>}
            right={<ButtonL
                className={cnLesson('NavBtn')}
                theme={'unstyled_center'}
                onClick={next}>{isLast ? tS('complete.1') : tS('next.1')}</ButtonL>}
            unitIndex={unitIndex}
            unitsCount={course.unitsCount}
        />

        {(unitContent.newsUid && unitContent.newsUid !== zeroId && unitContent.allowComments) && <Comments nid={unitContent.newsUid} className={cnLesson('Comments')} />}
    </div>
}

const WithPreloader = withPreloader(LessonPresenter, ['unitContent'], [], <Skelet />)



const Loader: FC<any> = (props) => {
    const { unitContent, loadFullUnit, sId, unit, course, saveUnitResult, log, toPrevUnit, transitionToNextUnit } = props

    const prevInProcess = () => {
        toPrevUnit(unit.courseId, unit.id)
    }

    const nextInProcess = () => {
        transitionToNextUnit(unit.courseId)
        /*saveUnitResult({
            courseId: unit.courseId,
            courseSessionId: sId,
            unitId: unit.id
        }, true, !log.isCompleted)*/
    }

    const { next = nextInProcess, prev = prevInProcess } = props

    useEffect(() => { !unitContent && loadFullUnit(unit.id) }, [sId, unit.id]);

    return <WithPreloader {...{ next, prev, ...props }} />
}

export const Lesson = connect(
    mapStateToProps,
    mapDispatchToProps
)(Loader);