import {
  menuEdit as oldMenuEdit,
  menuSetData as oldMenuSetData,
  menuUnsave as oldMenuUnsave
} from 'blocks/LeftMenu/redux/actions';
import { getMenuService } from 'blocks/LeftMenu/redux/selectors';
import { bindActionCreators } from 'redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import actions from 'utils/src/CommonRedux/base/actionsTypes';

export interface IRenderEditSwitcherProps extends TEditStateProps, TEditDispathProps, IRenderEditSwitcherOwnProps {}

export interface IRenderEditSwitcherOwnProps {
  isMobile: boolean;
}

export const mapStateToProps = (state: any) => ({
  edit: state.store.edit as boolean,
  isAdmin: getAuthUser(state).baseData.isAdmin as boolean,
  oldMenuEditState: getMenuService(state).edit,
});

export type TEditStateProps = ReturnType<typeof mapStateToProps>

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  oldMenuUnsave,
  oldMenuSetData,
  oldMenuEdit,
  changeVm: (propPath: string, value: any) => ({ type: actions.CHANGE_STATE, payload: { propPath, value }})
}, dispatch);

export type TEditDispathProps = ReturnType<typeof mapDispatchToProps>


