import React, { FC, useEffect, memo, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,
} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { withIndicator } from 'utils/src/CommonRedux/LoadIndicator'
import actionsTypes from '../../../../redux/actionsTypes/LMSTO'
import { SearchInput } from 'uielements/src';
import { Button, Icon } from 'uielements/src';
import Item from './Item'
import { NEW__useInfiniteScroll as useInfiniteScroll, useRefedCallback } from 'utils/src/hooks';
import ListPlaceholder from 'uielements/src/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import LinearProgress from '@material-ui/core/LinearProgress';
import { useScrollList } from 'LMSModule/utils/hooks'
import API from 'utils/src/requests/requests'
import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu'
import { FieldSelector } from './FieldSelector/FieldSelector'
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { xorBy } from 'lodash'
import { tSt } from 'LMSModule/utils/i18n.adaptor'

const LoadButton = withLoadSpin(Button)

type IPresenterProps = StatisticsFull.UsersList.IPresenterProps
type IOwnProps = StatisticsFull.UsersList.IOwnProps
type IDispatchProps = StatisticsFull.UsersList.IDispatchProps
type IStateProps = StatisticsFull.UsersList.IStateProps
type IState = StatisticsFull.UsersList.IState

const t = [{
    additionalParam1: null,
    answers: [],
    category: "",
    defaultValue: "",
    description: "",
    disableExpression: "",
    displayMode: "",
    fieldType: "email",
    id: "email",
    isHidden: false,
    isMandatory: false,
    isReadOnly: false,
    linkedCollection: null,
    linkedFieldStatus: null,
    mask: "",
    maxLength: 0,
    name: "E-Mail",
    position: 0,
    propertyName: "email",
    rows: [],
    selectOnlyFromCollection: false,
    showInHead: false,
    showInUserList: false,
    showOnIdeaCardView: false,
    visibility: null
}, {
    additionalParam1: null,
    answers: [],
    category: "",
    defaultValue: "",
    description: "",
    disableExpression: "",
    displayMode: "",
    fieldType: "phone",
    id: "phone",
    isHidden: false,
    isMandatory: false,
    isReadOnly: false,
    linkedCollection: null,
    linkedFieldStatus: null,
    mask: "",
    maxLength: 0,
    name: "Phone",
    position: 0,
    propertyName: "phone",
    rows: [],
    selectOnlyFromCollection: false,
    showInHead: false,
    showInUserList: false,
    showOnIdeaCardView: false,
    visibility: null
}];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 361,
    },
});

export const Presenter: FC<IPresenterProps> = ({
    className,
    getUserscoursesinfo,
    isLoading = false,
    settings,
    pathFullStatisticSetting,
    keys,
    withError = false,
    isFinished,
    count,
    fields
}) => {
    const classes = useStyles();
    const [showMail, setShowMail] = useState(false)
    const [showPhone, setShowPhone] = useState(false)

    const allowField = [...fields, ...t].filter((f: any) => {
        return ["Date", "Tags", "String", "Bool", "DateTime", "Textarea", "email", "phone"].includes(f?.fieldType)
    })
    const { loadMore, triggerRef } = useScrollList(
        isLoading,
        isFinished,
        withError,
        [settings],
        (deps) => (clean = false) => {
            getUserscoursesinfo({
                skipCount: deps.current.keys.length,
                count: deps.current.count,
                ...deps.current.settings
            }, clean)
        },
        { settings, keys, count }
    )

    const onChangeSearch = (search: string) => {
        pathFullStatisticSetting({
            search
        })
    }
    const onChangeFields = (userProfileAdditionlFields: any[]) => {
        // const allowField = userProfileAdditionlFields.filter((f: any) => {
        //     return ["Date", "Tags", "String", "Bool", "DateTime", "Textarea"].includes(f?.fieldType)
        // })
        const withMail = userProfileAdditionlFields.find((v: any) => v.fieldType === 'email')
        setShowMail(withMail)
        const withPhone = userProfileAdditionlFields.find((v: any) => v.fieldType === 'phone')
        setShowPhone(withPhone)
        pathFullStatisticSetting({
            userProfileAdditionlFields//: allowField
        })
    }

    const getExcel = () => {
        API.lms.getUserscoursesinfoExcel(settings)
    }

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Header')}>
            <div className={cnClassName('Noop')}>

            </div>
            <div className={cnClassName('SearchBox')}>
                <SearchInput
                    loading={isLoading}
                    value={settings.search}
                    className={cnClassName('Search')}
                    onChange={onChangeSearch} />
            </div>
            <div className={cnClassName('Actions')}>
                <Button noPadding onClick={getExcel} className={cnClassName('ActionBtn')}>
                    <Icon icon={"file-excel"} />
                </Button>
                {allowField.length > 1 && <PopupMenu
                    noFixed={true}
                    noPadding={true}
                    className={cnClassName({}, [])}
                    btnRender={(toggleOpen: any, ref: any, isOpen: boolean) =>
                        <Button disabled={isLoading} noPadding forwardedRef={ref} onClick={toggleOpen} className={cnClassName('Toggle', { open: isOpen })} >
                            <Icon icon={'ellipsis-v'} className={cnClassName('Icon')} />
                        </Button>
                    }
                >
                    <div className={cnClassName('List')}>
                        {!isLoading && <FieldSelector
                            fields={allowField}
                            selected={settings.userProfileAdditionlFields}
                            onChange={onChangeFields}
                        />}
                    </div>
                </PopupMenu>}
            </div>
        </div>
        <ListPlaceholder
            className={cnClassName('Placeholder')}
            isFinished={isFinished}
            isError={withError}
            textError={<Button
                className={cnClassName('ErrorTrigger')}
                onClick={loadMore}
            >
                {tSt('load.again')}
                {/* пробовать ещё */}
            </Button>}
            titleError={tSt('load.error.title')}//{'Ошибка получения данных'}
            isLoading={isLoading}
            itemsCount={keys.length}
            titleEmpty={tSt('load.empty.title')}//{'Пусто'}
            textEmpty={tSt('load.empty.text')}//{'Когда ни будь, что ни будь'}
            textLoad={tSt('load.holder.text')}//{'Загрузка...'}
        >
            {isLoading && <LinearProgress className={cnClassName('Progress')} />}

            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>{tSt('th.obuchenost')}</TableCell>
                            <TableCell>{tSt('th.summury')}</TableCell>
                            <TableCell>{tSt('th.in_progress')}</TableCell>
                            <TableCell>{tSt('th.completed')}</TableCell>
                            <TableCell>{tSt('th.not_completed')}</TableCell>
                            {/* {showMail && <TableCell>{'E-Mail'}</TableCell>} */}
                            {/* {showPhone && <TableCell>{'Phone'}</TableCell>} */}
                            {settings.userProfileAdditionlFields && settings.userProfileAdditionlFields.map((field: any) =>
                                <TableCell key={field.id} >{field.name}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {keys.map(id => <Item
                            key={id}
                            id={id}
                            selectedFields={settings.userProfileAdditionlFields}
                            showMail={showMail}
                            showPhone={showPhone}
                            className={cnClassName('Row')}
                        />)}
                    </TableBody>

                </Table>

                {!isFinished && (
                    <LoadButton
                        forwardedRef={triggerRef}
                        isLoading={isLoading}
                        className={cnClassName('Trigger')}
                        onClick={loadMore}
                    >
                        {tSt('load.more')}
                        {/* {'Ещё'} */}
                    </LoadButton>)}
            </TableContainer>
        </ListPlaceholder>
    </div>
}

export default withIndicator(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter))), actionsTypes.GET_USERS_COURSES_INFO)();
