import { NValidate,ValidateRulesObject, validate_v2 } from 'utils/src/validate_v2';
import { SettingsArticlesWidget } from '../WikiArticlesWidget.index';
import React, { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from "react";


export const validateField = (field: Partial<SettingsArticlesWidget.Settings>) => {
    let fullRules = ValidateFieldsObject;
    
    return validate_v2(field, fullRules);
};

export const ValidateFieldsObject: ValidateRulesObject<SettingsArticlesWidget.Settings> = {
    collection: {
        notNull: {
            value: true
        },
    },
};


const actualArticlesListErrorsBaseState: {
    collection: {[key: string]: any}|undefined;
   
} = {
    collection: undefined,

};

type ArticlesListErrorsState = {
    state: typeof actualArticlesListErrorsBaseState,
    setState: (newData: Partial<typeof actualArticlesListErrorsBaseState>) => void;
};



const ArticlesListErrors = createContext<ArticlesListErrorsState>({
    state: actualArticlesListErrorsBaseState,
    setState: () => {}
});

export const useArticlesListErrors = () => {
    return useContext(ArticlesListErrors);
};

export const ArticlesListErrorsProvider: FC<{}> = ({
    children
}) => {

    const [state, setS] = useState(actualArticlesListErrorsBaseState);
    const stateRef = useRef(state);
    stateRef.current = state;

    useEffect(() => {
        console.log('state', state);
    }, [state]);

    const setState = useCallback((newData: Partial<typeof state>) => {
        setS({
            ...stateRef.current,
            ...newData
        });
    }, [state, children]);

    return (
        <ArticlesListErrors.Provider value={{
            state,
            setState
        }}>
            {children}
        </ArticlesListErrors.Provider>
    );
};