import { put, takeLeading, takeLatest, takeEvery } from 'redux-saga/effects';

import {
    call,
    select,
} from 'utils/src/saga.effects';

import { checkResponseStatus, clone, getErrorText } from 'utils/src/utils'
import { v1 as uuid } from 'uuid';
import { toast } from 'react-toastify'
import i18n from '../../../localizations/i18n'
import { sub, format } from 'date-fns'
import { IBasicResponse } from 'utils/src/requests/models/api.base'
import {
  USERS_GET_USERS_HIERARCHY,
  setUserHieararchyToRedux,
  getUserHieararchyFromServer
} from './actions'
import {
  IGetUserHierarchyFromServerAction,
} from "./actions.interfaces"

import {
  IUserHierarchyRespons,
} from 'utils/src/requests/models/admin.userSettings'

import {
  getCompanyHierarchy,
} from 'utils/src/requests/admin.userSettings'

import { Actions, JsonSchema } from '@jsonforms/core';
import uischema from 'blocks/WSettings/structures/hierarchy/uischema'
import { JFdictionary } from 'blocks/WSettings/structures/dictionary'
const { types } = JFdictionary

function* getUserHierarchyFromServer(action: IGetUserHierarchyFromServerAction) {
  try {
    const { count, skipCount, data } = action.payload
    const response: IUserHierarchyRespons = yield* call(getCompanyHierarchy, skipCount, count)
    if (checkResponseStatus(response)) {
      const relationsArray = [
        'subusers',
        'headusers',
      ]

      // почему то не соответствует JsonSchema...
      const schema: any = {
        type: types.object,
        properties: {
          hierarchy: {
            type: types.string,
            placeholder: i18n.t('pryaniky.widgets.settings.placeholder.hierarchy'),
            opts: response.data.map((e: any) => ({
              title: e.name,
              value: e,
            })),
            data: [],
            require: true
          },
          headusers: {
            type: types.boolean,
            relations: relationsArray.filter((val) => val !== 'headusers'),
            require: true
          },
          subusers: {
            type: types.boolean,
            relations: relationsArray.filter((val) => val !== 'subusers'),
            require: true
          },
          hideIfNoData: {
            type: types.boolean
          },
          hierarcheListView: {
            type: types.boolean
          },
        },
      }
      const hierarchyData = {
        hierarchy: '',
        headusers: false,
        subusers: false,
        hideIfNoData: false,
        hierarcheListView: false,
      }
      if (data) {
        yield put(Actions.init(
          Object.keys(hierarchyData).reduce((acc, cur) => ({ ...acc, [cur]: data && data[cur] }), {} as any),
          schema,
          uischema
        ));
      } else {
        yield put(Actions.init(hierarchyData, schema, uischema));
      }
      yield put(setUserHieararchyToRedux(response.data))
    } else {
      toast.error(getErrorText(response));
    }
  } catch (e) {
    console.warn('get user settings error', e);
  }
}


const Hierarchy = function* Hierarchy() {
  yield takeLeading(USERS_GET_USERS_HIERARCHY, getUserHierarchyFromServer)
}

export default Hierarchy; 