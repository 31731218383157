import { bindActionCreators } from 'redux';
import { createFolder } from "../../../../redux/actions/Files";
import { cn } from '@bem-react/classname';
export interface IProps extends IBaseFileProps, IFileStateProps, ReturnType<typeof mapDispatchToProps> {
}

export interface IBaseFileProps {
    gid: number;
    className?: string;
}

export interface IFileStateProps {

}

export const mapStateToProps = (state: any, props: IBaseFileProps) => {
    return {  }
}

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    createFolder
}, dispatch);

export const cnCreateFolder = cn('CreateFolder');