import React from 'react';
import { IShopItemProps, cnCTShopItem, mapStateToProps, mapDispatchToProps } from './CurrencyTimelineItem.index';
import PurseImg from 'assets/svg/purse.svg';
import { getCurrencyNameCase } from 'utils/src/utils';
import { Translate } from 'localizations/Translate';
import { connect } from 'react-redux';
import { Link } from 'muicomponents/src/Link/Link'
// import { Button } from 'uielements/src';
import { Button } from 'uielements/src';
import MDRender from 'uielements/src/CommonmarkRender'
import './CurrencyTimelineItem.scss';
import { formatDateSTD } from 'utils/src/utils.dates';

const Presenter: React.FC<IShopItemProps> = ({
  currencyNames,
  userSex,
  data
}) => {

  const { value, isDeleted, transferDate, data: product } = data;

  if (!product) return <></>;

  return (
    <div className={cnCTShopItem('', ['Widget'])}>
      <div className={cnCTShopItem('Header')}>
        <span><Translate i18nKey={`pryaniky.timeline.spent.${userSex}`} /></span>
        <div className={cnCTShopItem('Header-Info', [isDeleted ? 'Deleted' : 'Order'])}>
          <img src={PurseImg} />
          <span>{value}</span>
        </div>
        <span>{currencyNames[getCurrencyNameCase(value, true)]}</span>
      </div>

      <div className={cnCTShopItem('Content')}>

        {product.imgUrl && <Link to={`/shop/product/${product.pkid}`}>
          <img src={product.imgUrl} />
        </Link>}

        <div>
          <div>
            <span><Translate i18nKey={`pryaniky.timeline.gained.${userSex}`} /></span>
            <div>{formatDateSTD(transferDate, true)}</div>
          </div>

          <div>
            <span>{product.name}</span>
            <div>
              <MDRender className={cnCTShopItem('MDRender')} disableEmojiOne disableShowMoreBtn textCut={true} source={product.description} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const CurrencyTimelineItem = connect(mapStateToProps, mapDispatchToProps)(Presenter)