import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators } from 'redux';
import { IDispatchProps } from 'redux/connector';
import {
    loadFullUnit,
    sendResult,
    setNextUnit,
    newTestAttempts,
    // loadUnitResult,
    loadUserResult
} from "../../redux/actions/LMS";
import { RouteComponentProps } from 'react-router';
import { 

} from '../../redux/sagas/LMS/selectors'

export interface ITestResultProps extends IClassNameProps, ITestResultStateProps, IDispatchProps, RouteComponentProps, ReturnType<typeof mapDispatchToProps> {
    tag?: 'div';
    sId: string;
    unit: any;
    ulid: string;
    disableActions?: boolean;
}

export interface ITestResultState {
}

export interface ITestResultStateProps {
    store: any
    unitContent: any
}

export const mapStateToProps = (state: any, props: any) => ({
    unitTimer: state.LMS.timers[props.unit.id],

})

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    // setNextUnit,
    newTestAttempts
}, dispatch);

export const cnTestResult = cn('TestResult');