import { IClassNameProps } from '@bem-react/core';
import { cnReply } from '../Reply.index';

import { match } from 'react-router';

export interface IServiceActionsProps extends IClassNameProps {
  actions: string[];
  id: string;
  newsID: string;
  changeVm?: (path: string, value: any) => void;
  removeReply(id: string): void;
  enableEdit(): void;
}

export interface IServiceActionsState {
  open: boolean;
}

export const cnServiceActions = (...data: any) => {
  let name = 'ServiceActions';
  if(typeof(data[0]) === 'string') {
    name +=  '-' + data[0];
    data = data.slice(1);
  }
  return cnReply(name, ...data)
}