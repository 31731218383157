import { Translate } from 'localizations/Translate';
import React, { FC } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { VacanciesList } from '../List/VacanciesList';
import { getVacanciesSettingsModule } from '../redux/module.vacancies';
import { Page } from 'blocks/Page/Page';
import { pageNames } from 'redux/pageNames';

const VacanciesPagePr: FC<{}> = ({

}) => {

    return (
        <Page page={{ name: pageNames.vacancies }} />
    )
};

export const VacanciesPage: FC<{}> = () => {
    return (
        <DynamicModuleLoader modules={[getVacanciesSettingsModule()]}>
            <VacanciesPagePr/>
        </DynamicModuleLoader>
    )
}